import React, { Component } from 'react'
import Compose from '../compose'
import Toolbar from '../toolbar'
import ToolbarButton from '../toolbarbutton'
import fetch from '../../shared/fetch'
import forumCommentDate from '../../forum/forum-full-page/forum-date'
import Spinner from '../../UI/spinner'
import window from 'global/window'
import { ChatProps } from './types'

import './messagelist.scss'

export default class MessageList extends Component<ChatProps> {
  state = {
    chatMsgs: [],
    isShowSpinner: false,
    newMessage: '',
    chatPartnerId: 0,
    loading: false,
    hasMore: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      chatMsgs: [],
      isShowSpinner: false,
      newMessage: '',
      chatPartnerId: 0,
      loading: false,
      hasMore: false,
    }

    this.scrollRequired = true;
  }

  scrollParentRef = null;
  lastScrollOffset = 0;
  lastScrollDirection = null;
  scrollRequired = true;

  componentDidUpdate(prevProps) {
    const { listUserId, chatMsgs, setChatPartnerId } = this.props;

    if (prevProps.chatMsgs.length !== chatMsgs.length) {
      this.setState({chatMsgs});
      this.scrollParentRef.addEventListener('scroll', this.handleScroll);
    }

    if (prevProps.listUserId !== listUserId) {
      setChatPartnerId(listUserId);
      this.getMessages();
      this.scrollRequired = true;
      this.lastScrollDirection = null;
    }

    if(this.scrollParentRef != null) {
      this.scrollParentRef.addEventListener('scroll', this.handleScroll);
    }

    this.scrollToBottom();
  }

  loadMoreMessages = () => {
    const { loading, hasMore, chatMsgs } = this.state;

    if (!loading && hasMore) {
      const lastMsgId = chatMsgs ? chatMsgs[0].messageId : null;
      this.getMessages(lastMsgId);
    }
  }

  handleScroll = (event) => {
    const parentCtrl =  document.getElementById('bubblesBottom');
    const targetCtrl = document.getElementById('chatPnl');

    if (parentCtrl && targetCtrl) {
      if (this.lastScrollOffset > parentCtrl?.scrollTop) {
        this.lastScrollDirection = 'UP';
        this.scrollRequired = false;
      } else if (this.lastScrollOffset < parentCtrl?.scrollTop) {
        this.lastScrollDirection = 'DOWN';
      }

      if (parentCtrl?.scrollTop == 0 && this.lastScrollOffset != 0) {
        this.loadMoreMessages();
      }

      this.lastScrollOffset = parentCtrl?.scrollTop;
    }
  }

  componentWillUnmount() {
    const { setInitialChatState } = this.props;
    setInitialChatState([]);

    if(this.scrollParentRef != null) {
      this.scrollParentRef.removeEventListener('scroll', this.handleScroll);
    }
  }

  getMessages = (startFromId = null) => {
    const { loading, chatMsgs } = this.state;
    const {userId, listUserId, isShowSpinner, setInitialChatState} = this.props;

    if (userId && listUserId && !loading) {
      this.setState({ loading: true });

      const body = JSON.stringify({
        userId,
        opponentId: listUserId,
        startFromId: startFromId,
      })
      fetch('/messenger/getAllMessagesMsngr', {
        method: 'POST',
        body,
      }).then(response => {
        if (!startFromId) {
          if (response.data.messages) {
            setInitialChatState(response.data.messages.sort((a, b) => a.messageId - b.messageId ));
          }
          
          this.setState({loading: false, hasMore: true });
        } else {
          const allMsgs = ([].concat(chatMsgs ? chatMsgs : [], response.data.messages)).sort((a, b) => {
            return a.messageId - b.messageId;
          });
          
          setInitialChatState(allMsgs);
          this.setState({loading: false, hasMore: response?.data?.messages?.length > 0 });
        }

        isShowSpinner();
      })
    }
  }

  scrollToBottom = () => {
    const parentCtrl = document.getElementById('bubblesBottom');
    const targetCtrl = document.getElementById('chatPnl');
/*
    console.log('debug: ', {
      targetCtrl: targetCtrl ? true : false,
      parentCtrl: parentCtrl ? true : false,
      scrollRequired: this.scrollRequired, 
      lastScrollDirection: this.lastScrollDirection, 
      lastScrollOffset: this.lastScrollOffset, 
      scrollTop: parentCtrl?.scrollTop, 
      debug: { scrollTop: parentCtrl.scrollTop, scrollHeight: parentCtrl.scrollHeight, scrollWidth: parentCtrl.scrollWidth }});
*/
    if(targetCtrl && parentCtrl && this.scrollRequired) {
      parentCtrl.scrollTo({
        top: targetCtrl.getBoundingClientRect() ? targetCtrl.getBoundingClientRect().height : 0,
        behavior: 'smooth',
      });
    }
  }

  addMessage = () => {
    const { userId, listUserId, setInitialMessengerState } = this.props
    const { newMessage } = this.state

    const body = JSON.stringify({
      userId: userId,
      partnerUserId: listUserId,
      message: newMessage,
    })

    window.socket.emit('SOCKET_NEW_MESSAGE', body);
  
    this.setState({ newMessage: ''});
    this.scrollRequired = true;

    fetch('/messenger/unread', {
      method: 'GET',
    }).then(response => {
      this.getMessages()
      setInitialMessengerState(response.data.messages)
    })
  }

  onCtrlEnterPress = e => {
    if (e.keyCode === 13 && (e.ctrlKey || e.metaKey)) {
      this.addMessage();
      
      e.preventDefault();
      this.setState({ newMessage: '' });
    }
  }

  urlify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    
    return text.replace(urlRegex, function(url) {
        let hyperlink = url;
        if(!hyperlink.match('^https?:\/\/')){
            hyperlink = 'http://' + hyperlink;
        }
      return '<a className="blue" href="' + url + '" rel="noopener" noreferrer>' + url + '</a>'

    }) 
  }

  render() {
    const { t, userFullName, userId, listUserId, chatMsgs, showSpinner } = this.props;
    const { newMessage } = this.state;

    return (
      <div className="message-list">
{/*        
        <Toolbar title={userFullName} rightItems={[
            <ToolbarButton key="info" icon="ion-ios-information-circle-outline" />,
            <ToolbarButton key="video" icon="ion-ios-videocam" />,
            <ToolbarButton key="phone" icon="ion-ios-call" />,
          ]}
        />
*/}
        <div className="message-list-container send-message-popup-container" id="bubblesBottom"  ref={(ref) => this.scrollParentRef = ref}>
          { 
            showSpinner ? <Spinner /> 
            : listUserId ? (
              <div id="chatPnl">
                { chatMsgs.map((msg: any, msgIndex: number) => {
                  const message = msg.contentReceived ? msg.contentReceived : msg.contentSend
                  return (
                    <div key={msg.messageId} className={`message-bubble ${msg.sendUserId == userId ? 'ta-right' : ''}`}>
                      <div className="sm-date"></div>
                      <div className="clear"></div>
                      <div className="sm-message">
                        <span className="bubble-date">{forumCommentDate(msg.dateTime)}</span>
                        {/* <p dangerouslySetInnerHTML={{ __html: this.urlify(message) }} /> */}
                        {message}
                       
                      </div>
                      <div className="clear"></div>
                    </div>
                  )
                }
                )}
              </div>
            )
            : <h1>{t('messenger.select.chat')}</h1>
          }
        </div>

        { listUserId ? 
          <div className="composebox-container">
            <Compose
              text={newMessage}
              onChange={e => this.setState({ newMessage: e.target.value })}
              onKeyDown={this.onCtrlEnterPress}
              placeholder={userFullName.length > 0 ? `${t('messenger.enter.message.for')} ${userFullName} ` : ''}
              sendMessage={() => this.addMessage()}
            />
          </div>
        : null }
      </div>
    )
  }
}
