import React, { FunctionComponent } from 'react'
import { Field } from 'redux-form'

import WidgetBase from '../../shared/components/widget-base'
import FormBase from '../../UI/field-base'
import { required, email } from '../../shared/form-validators'
import { IChangeEmailProps } from './types'

import './style.scss'

const ChangeEmail: FunctionComponent<IChangeEmailProps> = props => {
  const { t, handleSubmit, pristine, submitting, invalid, clearModal } = props

  return (
    <WidgetBase title={t('login.email.change.title')}>
      <div className="fa fa-times icon-close" onClick={clearModal} />
      <div className="edit-panel-form panel-block">
        <div className="edit-panel-input-block">
          <div className="edit-panel-email">
            <Field
              name="email"
              type="email"
              component={FormBase}
              label={t('login.email.change.enter')}
              validate={[required, email]}
            />
          </div>
          <button
            type="submit"
            className="button button-orange button-block"
            disabled={pristine || submitting || invalid}
            onClick={handleSubmit}
          >
            {t('change')}
          </button>
        </div>
        <div className="edit-panel-notification-block">
          <span className="meta">{t('email.rule.message.info')}</span>
        </div>
      </div>
    </WidgetBase>
  )
}

export default ChangeEmail
