import {
  SOCKET_LOGIN_STATUS,
  SOCKET_NEW_LOGIN_ATTEMPT,
  SOCKET_NEW_LOGIN_ATTEMPT_ALLOW,
  SOCKET_NEW_LOGIN_ATTEMPT_DENY,
  SOCKET_LOGIN_ATTEMPT_RESET,
  SOCKET_LOGIN_ALLOWED,
  SOCKET_LOGIN_DENIED,
  SOCKET_GOT_NEW_CARGO_ENTRY,
  SOCKET_REFRESH_SEARCH_FINISH,
  SET_INITIAL_MESSENGER_STATE,
  SET_INITIAL_CHAT_STATE,
  SET_CHAT_PARTENER_ID,
  SOCKET_NEW_MESSAGE_ARRIVED,
  CHAT_MARK_MESSAGES_AS_READ,
  GET_CONVERSATION_LIST,
  GET_CONVERSATION_LIST_ARRIVED,
  SOCKET_LOGOUT_FROM_ALL_DEVICES,
  CONVERSATION_SCROLL_TO_BOTTOM
} from './action-types'
import { logoutHereAfterLoginOnAnotherDevice } from './auth'
import { getSessionId } from '../utils'

export const reportLoginStatus = () => {
  return (dispatch, getState) => {
    const state = getState()
    const { auth } = state

    dispatch({
      type: SOCKET_LOGIN_STATUS,
      payload: {
        authenticated: !!auth.accessToken,
        username: auth.jwt.username || undefined,
        status: auth.status,
        userId: auth.jwt.userId || undefined,
        sessionId: getSessionId(),
      },
    })
  }
}

export const newLoginAttempt = payload => {
  return {
    type: SOCKET_NEW_LOGIN_ATTEMPT,
    payload,
  }
}

export const allowLoginOnAnotherDevice = payload => {
  return dispatch => {
    dispatch(logoutHereAfterLoginOnAnotherDevice())
    dispatch({
      type: SOCKET_NEW_LOGIN_ATTEMPT_ALLOW,
      payload,
    })
  }
}
export const conversationScrollToBottom = payload => {
  return {
    type: CONVERSATION_SCROLL_TO_BOTTOM,
    payload,
  }
}

export const denyLoginOnAnotherDevice = payload => {
  return {
    type: SOCKET_NEW_LOGIN_ATTEMPT_DENY,
    payload,
  }
}

export const newLoginAllowed = payload => {
  return {
    type: SOCKET_LOGIN_ALLOWED,
    payload,
  }
}

export const newLoginDenied = payload => {
  return {
    type: SOCKET_LOGIN_DENIED,
    payload,
  }
}
export const resetLoginSocket = () => {
  return {
    type: SOCKET_LOGIN_ATTEMPT_RESET,
  }
}
export const newCargoEntry = () => {
  return {
    type: SOCKET_GOT_NEW_CARGO_ENTRY,
  }
}

export const socketRefreshSearchFinish = () => {
  return dispatch => dispatch({ type: SOCKET_REFRESH_SEARCH_FINISH })
}

export const setInitialMessengerState = payload => {
  return dispatch => {
    dispatch({
      type: SET_INITIAL_MESSENGER_STATE,
      payload,
    })
  }
}

export const markMessagesAsRead = payload => {
  return dispatch => {
    dispatch({
      type: CHAT_MARK_MESSAGES_AS_READ,
      payload,
    })
  }
}

export const setInitialChatState = payload => {
  return dispatch => {
    dispatch({
      type: SET_INITIAL_CHAT_STATE,
      payload,
    })
  }
}

export const newMessageArrived = payload => {
  return (dispatch, getState) => {
    const state = getState()
    const { auth } = state

    payload['userId'] = auth.jwt.userId

    dispatch({
      type: SOCKET_NEW_MESSAGE_ARRIVED,
      payload,
    })
  }
}

export const getConversationListArrived = payload => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_CONVERSATION_LIST_ARRIVED,
      payload,
    })
  }
}

export const logoutFromAllDevices = () => {
  return (dispatch, getState) => {
    dispatch({
      type: SOCKET_LOGOUT_FROM_ALL_DEVICES,
    })
  }
}

export const getConversationList = payload => {
  return (dispatch, getState) => {

    dispatch({
      type: GET_CONVERSATION_LIST,
      payload,
    })
  }
}

export const setChatPartnerId = payload => {
  return dispatch => {
    dispatch({
      type: SET_CHAT_PARTENER_ID,
      payload,
    })
  }
}

export default {
  SOCKET_GET_LOGIN_STATUS: reportLoginStatus,
  SOCKET_NEW_LOGIN_ATTEMPT: newLoginAttempt,
  SOCKET_LOGIN_ALLOWED: newLoginAllowed,
  SOCKET_LOGIN_DENIED: newLoginDenied,
  SOCKET_GOT_NEW_CARGO_ENTRY: newCargoEntry,
  SOCKET_NEW_MESSAGE_ARRIVED: newMessageArrived,
  GET_CONVERSATION_LIST_ARRIVED: getConversationListArrived,
  GET_CONVERSATION_LIST: getConversationList,
  SET_CHAT_PARTENER_ID: setChatPartnerId,
  SOCKET_LOGOUT_FROM_ALL_DEVICES: logoutFromAllDevices

  // SOCKET_LOGIN_STATUS: reportLoginStatus,
  // SOCKET_NEW_LOGIN_ATTEMPT,
}
