import _ from 'lodash'
import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import window from 'global/window'
import moment from 'moment'
import reactHtmlParser from 'react-html-parser'
import { pencil } from 'react-icons-kit/fa/pencil'
import { times } from 'react-icons-kit/fa/times'
import { Redirect } from 'react-router-dom'
import Icon from 'react-icons-kit'
import Banner from '../../banner'
import config from '../../../config'
import { ModalConsumer } from '../../../context/modal-context.tsx'
import AddEdit from './add-edit-modal/add-edit'
import { convertImageUrlUuid } from '../../shared/utils'
import WidgetHeader from '../../shared/components/widget-header'
import WidgetBase from '../../shared/components/widget-base'
import { canDo } from '../../../store/utils'
import { VIDEO_PROVIDERS } from '../../../store/constants'

import './styles.scss'

const STYLE_BASE = 'news-item_'

export default class extends Component {
  state = {
    itemId: undefined,
  }

  componentDidMount() {
    const { itemId } = this.props
    this.refresh(itemId)
  }

  componentDidUpdate(prevProps) {
    const { itemId } = this.props

    if (itemId !== prevProps.itemId) {
      this.refresh(+itemId)
    }
  }

  clickEdit = () => {
    const { t, item } = this.props
    this.showModalComponent(<AddEdit onClose={this.clearModal} item={item} />, {
      title: t('news.edit.item'),
      className: 'modal-news-item',
      backdrop: true,
    })
  }

  clickDelete = () => {
    if (window.confirm('Do you want to delete this news?')) {
      const {
        deleteNews,
        item: { itemId },
      } = this.props
      deleteNews(itemId)
    }
  }

  refresh(itemId) {
    const { item, getItem } = this.props
    const { itemId: itemIdOld } = this.state
    const { loading = false } = item || {}
    if (itemId !== itemIdOld && !loading) {
      this.setState({ itemId })
      getItem({ itemId })
    }
  }

  getVideoHtml = url => {
    for (let j = 0; j < VIDEO_PROVIDERS.length; j++) {
      const p = VIDEO_PROVIDERS[j]
      const urls = _.isArray(p.url) ? p.url : [p.url]
      for (let i = 0; i < urls.length; i++) {
        const match = url.match(urls[i])
        if (match) {
          if (_.isFunction(p.html)) {
            return p.html(match)
          } else {
            break
          }
        }
      }
    }
    return `<div class="${STYLE_BASE}provider-unknown">Unknown video provider - ${url}</div>`
  }

  formatOEmbed = html => {
    const lenOEmbed = '</oembed>'.length
    let done = false
    do {
      const indexEnd = html.lastIndexOf('</oembed>')
      done = indexEnd === -1

      if (!done) {
        const indexStart = html.lastIndexOf('<oembed ', indexEnd)
        const indexMid = html.lastIndexOf('>', indexEnd)
        let videoHtml = ''
        let src = ''
        const i1 = html.indexOf('url=', indexStart)
        if (i1 !== -1) {
          let i2 = html.indexOf(' ', i1)
          if (i2 > indexMid || i2 < i1) {
            i2 = indexMid
          }
          src = _.trim(html.substring(i1 + 4, i2), `" '`)
          videoHtml = this.getVideoHtml(src)
        }
        
        html = `${html.substring(0, indexStart)}${videoHtml}${html.substring(indexEnd + lenOEmbed)}`
      }
    } while (!done)

    return html
  }

  formatLinks = html => {
    let done = false
    const attr = 'target="_blank"'
    let indexEnd = html.length
    do {
      indexEnd = html.lastIndexOf('</a>', indexEnd)
      done = indexEnd === -1

      if (!done) {
        const indexStart = html.lastIndexOf('<a ', indexEnd)
        const indexMid = html.indexOf('>', indexStart)
        const i1 = html.substring(indexStart, indexMid).indexOf('target=')
        if (i1 === -1) {
          html = `${html.substring(0, indexMid)} ${attr}${html.substring(indexMid)}`
          indexEnd += attr.length
        }
      }
    } while (!done)

    return html
  }

  showItem() {
    const { t, totalComments, item } = this.props
    const { title, count, date, image, video, mini, imageFolder, short } = item

    let body = convertImageUrlUuid(item.body || '', imageFolder)
    body = this.formatOEmbed(body)
    body = this.formatLinks(body)
    const content = reactHtmlParser(body)

    return (
      <Fragment>
        <div className="panel-header-links-wrapper news-item-container">
          <div className="panel-header-links">
            <div className="panel-header-links-left news-item-title">
              <h3 className="article-title-label">{title}</h3>
              <div className="panel-header-links-right l-left" style={{ float: 'right' }}>
                {/*<span href="#" className="icon is-active">
                  <i className="fa fa-bookmark" />
                </span>*/}
                <span href="#" className="icon">
                  <i className="fa fa-print" />
                </span>
              </div>
            </div>
            {/* TODO - add bookmarks and printing */}
            {/* <div className="panel-header-links-right l-left">
              <span href="#" className="icon is-active">
                <i className="fa fa-bookmark" />
              </span>
              <span href="#" className="icon">
                <i className="fa fa-print" />
              </span>
            </div> */}
          </div>
          <div className="panel-header-links">
            <div className="panel-header-links-left">
              <span className="meta meta-links">
                <span className={classNames(`${STYLE_BASE}date-time`)}>
                  <span className={classNames(`${STYLE_BASE}date`)}>
                    <i className="fa fa-calendar" />
                    {moment(date).format('DD.MM.YY')}
                  </span>
                  <span className={classNames(`${STYLE_BASE}time`)}>
                    <i className="fa fa-clock-o" />
                    <strong>{moment(date).format('HH:mm')}</strong>
                  </span>
                </span>
                <span>{`${t('views')}: ${count}`}</span>
                <span>{`${t('news.comments')}: ${totalComments}`}</span>
              </span>
              
            </div>
          </div>
          <div className="short-descrtiption">
            {short}
          </div>
        </div>
        <div className="panel-content-desc">
          <article>
            {video && (
              <div className="video-block news-video-block">{reactHtmlParser(this.getVideoHtml(video))}</div>
            )}
            {image && mini && (
              <div className={classNames('main-img', `${STYLE_BASE}main-img`)}>
                <img src={`${config.apiUrl}/common/file/${imageFolder}/${image}`} alt="title" />
              </div>
            )}
            {content}
          </article>
        </div>
      </Fragment>
    )
  }

  renderLinkButton = (icon, text, onClick) => {
    return (
      <div
        className={classNames({
          'menu-item': true,
          'has-text': !!text,
        })}
        onClick={onClick}
      >
        <div>
          <Icon icon={icon} size={12} />
          <span>{text}</span>
        </div>
      </div>
    )
  }

  renderWidgetHeader = () => {
    const { t, permissions } = this.props
    const canEdit = canDo(permissions, 'news', 'edit')
    const canDelete = canDo(permissions, 'news', 'delete')

    return (
      <WidgetHeader title={t('news.widget.title.list')}>
        <ModalConsumer>
          {({ showModalComponent, clearModal }) => {
            this.showModalComponent = showModalComponent
            this.clearModal = clearModal

            return (
              <Fragment>
                {canEdit && this.renderLinkButton(pencil, t('news.edit.item'), this.clickEdit)}
                {canDelete && this.renderLinkButton(times, t('delete'), this.clickDelete)}
              </Fragment>
            )
          }}
        </ModalConsumer>
      </WidgetHeader>
    )
  }

  render() {
    const { t, item } = this.props
    const { loading, mode } = item

    if (mode === 'deleted') {
      return <Redirect to="/news/list" />
    }

    return (
      <>
        <Banner bannerId={571} />
        <WidgetBase title={t('news.widget.title.item')} className="panel-static">
          {this.renderWidgetHeader()}
          <div className={classNames('panel-content', `${STYLE_BASE}wrapper`)}>
            {loading ? <div>loading</div> : this.showItem()}
          </div>
        </WidgetBase>
        <Banner bannerId={572} />
      </>
    )
  }
}
