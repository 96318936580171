import React, { Component } from 'react'
import Banner from '../../../banner'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import TooltipWrapper from '../../../UI/popover-wrapper'
import TooltipModal from '../../../search/table/tooltip-modal'
import moment from 'moment'
import Rating from '../../../UI/star-rating'
import { IContentClaimsProps, IContentClaimsState } from "./types"

import "./styles.scss"

export default class ContentClaims extends Component<IContentClaimsProps, IContentClaimsState> {

  state = {
    isOpen: false,
    itemData: {
      datePosted: "",
      createdDate: "",
      createdByFirstName: "",
      createdByLastName: "",
      createdByUserId: 0,
      createdByCompanyName: "",
      createdByCompanyId: 0,
      description: "",
      targetCompanyName: "",
      targetCompanyId: 0,
      recordType: "",
      response: [],

      reviewerName: "", // кто
      reviewerId: 0,
      reviewedName: "", // кому претензия
      reviewedId: 0,
      review: "",
      title: "",
      answerDate: "",
      answeredReview: "",
    },
  }

  componentDidMount() {
    const { itemData } = this.props
    // this.setState({ itemData })
  }

  render() {
    const { itemType, t, itemData, filterType } = this.props
    const { isOpen } = this.state

    const {
      datePosted,
      createdDate,
      createdByFirstName,
      createdByLastName,
      createdByUserId,
      createdByCompanyName,
      createdByCompanyId,
      description,
      targetCompanyName,
      targetCompanyId,
      recordType,
      response,

      reviewerName, // кто
      reviewerId,
      reviewedName, // кому претензия
      reviewedId,
      review,
      answerDate,
      answeredReview,
      grade,
      title,

      companyName,
      companyReviewed,
      userReviewed,
      userReviewedName
    } = itemData

    const isReviewItem = itemType === "reviews"
    const reviewTypeIsUSers = (recordType || filterType) === "USER"

    const itemDate = datePosted || createdDate
    const userOrCompanyLabel = reviewTypeIsUSers ? t('review.for.user.label') : t('review.for.company.label')
    const itemFor = isReviewItem ? userOrCompanyLabel : t('claim.for.label')

    const urlForReviews = reviewTypeIsUSers ? `/user/details/${reviewedId}` : `/company/item/${reviewedId}`

    const text = description || review

    const answer = answeredReview || response && response[0]?.comment

    const urlForAuthorReviews = reviewTypeIsUSers ? `/user/details/${reviewerId}` : `/company/item/${reviewerId}`

    return (

      <div className="panel-message panel-message-reviews single-item" >
        <div className="panel-message-header">
          <div className="panel-message-header-left">
            <span>{itemFor}</span>

            {isReviewItem ? <>
              {reviewTypeIsUSers ? <>
                <Link to={urlForReviews} className="name-link">
                  <TooltipWrapper
                    text={<TooltipModal companyId={!reviewTypeIsUSers && reviewedId} userId={reviewTypeIsUSers && reviewedId || userReviewed} />}
                    hover
                    innerClassName="full-tooltip"
                    disabled={false}
                    parentCustomClass="move-up-container"
                  >
                    {reviewedName || userReviewedName}
                  </TooltipWrapper>
                </Link>

                {createdByCompanyId &&
                  <Link to={`/company/item/${createdByCompanyId}`} className="name-link">
                    <TooltipWrapper
                      text={<TooltipModal companyId={createdByCompanyId} userId={0} />}
                      hover
                      innerClassName="full-tooltip"
                      disabled={false}
                      parentCustomClass="move-up-container"
                    >
                      {createdByCompanyName}
                    </TooltipWrapper>
                  </Link>
                }
              </>
                : <>
                  <Link to={`/company/item/${reviewedId || companyReviewed}`} className="name-link">
                    <TooltipWrapper
                      text={<TooltipModal companyId={reviewedId || companyReviewed} userId={0} />}
                      hover
                      innerClassName="full-tooltip"
                      disabled={false}
                      parentCustomClass="move-up-container"
                    >
                      {reviewedName || companyName}
                    </TooltipWrapper>
                  </Link>

                </>}
            </> : <>

              <Link to={`/company/item/${targetCompanyId}`} className="name-link">
                <TooltipWrapper
                  text={<TooltipModal companyId={targetCompanyId} userId={0} />}
                  hover
                  innerClassName="full-tooltip"
                  disabled={false}
                  parentCustomClass="move-up-container"
                >
                  {targetCompanyName}
                </TooltipWrapper>
              </Link>
            </>}

          </div>


          <div className="panel-message-header-right">
            <span>{t('author')}</span>
            {isReviewItem ? <>

              <Link to={urlForAuthorReviews} className="name-link">
                <TooltipWrapper
                  text={<TooltipModal companyId={!reviewTypeIsUSers && reviewerId} userId={reviewTypeIsUSers && reviewerId} />}
                  hover
                  innerClassName="full-tooltip"
                  disabled={false}
                  parentCustomClass="move-up-container"
                >
                  {reviewerName}
                </TooltipWrapper>
              </Link>

            </> : <>

              <Link to={`/company/item/${createdByCompanyId}`} className="name-link">
                <TooltipWrapper
                  text={<TooltipModal companyId={createdByCompanyId} userId={0} />}
                  hover
                  innerClassName="full-tooltip"
                  disabled={false}
                  parentCustomClass="move-up-container"
                >
                  {createdByCompanyName}
                </TooltipWrapper>
              </Link>
            </>}

            <span className="meta-message">{moment(itemDate).format("DD/MM/YYYY HH:mm")}</span>

          </div>
        </div>
        <div className="panel-message-title">
          {itemType === "reviews" && <Rating averageRating={grade} />}
          <p className="title">{title}</p>
        </div>
        <div className="panel-message-content">
          {text}
        </div>
        {answer && <div className="panel-message-reply">
          <span
            onClick={() => this.setState({ isOpen: !isOpen })}
            data-collapse="true"
            className={classNames('collapse-title', isOpen && 'is-active')}
          >{t('user.answer')}</span>
          <div className={classNames('collapsible-content', isOpen && 'is-opened')} >
            <div className="panel-message-gray">
              <p className="title-sm review-answer-date">
                {moment(answerDate).format('DD/MM/YYYY HH:mm')}
              </p>
              {answer}
            </div>
          </div>
        </div>}
      </div>
    )
  }
}
