import React, { Component } from 'react'
import classNames from 'classnames'

import { Field } from 'redux-form'
import { required, moreThanZero } from '../../../../../shared/form-validators'
import RenderNumber from '../../../../../shared/components/render-number'
import { CheckboxField } from '../../../../../UI/checkbox'

import VolumeFields from '../../../../../shared/components/volume-fields'

// import './styles.scss'

// const STYLE_BASE = 'car-load-data_'

export default class extends Component {
  render() {
    const {
      className,
      t,
      title = t('load.characteristics'),
      weightName,
      loadingTopName,
      loadingSideName,
      loadingRareName,
      isVisible = true,
      formName,
      isTransport,
      shippingType,
    } = this.props

    const validateProps = []
    if (isVisible) {
      validateProps.validate = [required]
    }
    return (
      <div
        className={classNames({
          'panel-content-row': true,
          [className]: !!className,
        })}
      >
        <h5 className="title-label">{title}</h5>
        {shippingType !== 'avia' && (
          <div className="panel-form-row">
            <label className="required-field">{t('loading.unloading')}</label>
            <div className="checkboxes-aligned clearfix">
              <div className="panel-form-col-md">
                <CheckboxField name={loadingTopName}>{t('cargo.loading.top')}</CheckboxField>
              </div>
              <div className="panel-form-col-md">
                <CheckboxField name={loadingSideName}>{t('cargo.loading.side')}</CheckboxField>
              </div>
              <div className="panel-form-col-md">
                <CheckboxField name={loadingRareName}>{t('cargo.loading.rare')}</CheckboxField>
              </div>
            </div>
          </div>
        )}
        <div className="panel-form-row required-field">
          <div className="panel-form-col-xs">
            <label className="required-field">{t('load.capacity')}</label>
            <div className="input-text">
              <Field
                name={weightName}
                component={RenderNumber}
                type="text"
                placeholder="00.00"
                unit="t"
                integerLimit={2}
                decimalLimit={2}
                validate={[moreThanZero, ...validateProps]}
              />
            </div>
          </div>
          <VolumeFields
            className="panel-form-col"
            fieldNameM3="volume.m3"
            fieldNameLdm="volume.ldm"
            fieldNamePal="volume.pal"
            isTransport={isTransport}
            formName={formName}
            validate={[moreThanZero, ...validateProps]}
            shippingType={shippingType}
          />
        </div>
      </div>
    )
  }
}
