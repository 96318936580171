import {
  SUMMARY_GET_INTRANATIONAL_START,
  SUMMARY_GET_INTERNATIONAL_START,
  SUMMARY_GET_INTRANATIONAL,
  SUMMARY_GET_INTERNATIONAL,
  SUMMARY_CHANGE_FAVORITE,
} from '../actions/action-types'

const initialState = {
  intranational: {
    data: [],
    loading: false,
  },
  international: {
    data: [],
    loading: false,
  },
}

export default (state = initialState, { type, payload }: any = {}) => {
  switch (type) {
    case SUMMARY_GET_INTRANATIONAL_START: {
      return {
        ...state,
        intranational: {
          data: state.intranational.data,
          loading: true,
        },
      }
    }
    case SUMMARY_GET_INTERNATIONAL_START: {
      return {
        ...state,
        international: {
          data: state.international.data,
          loading: true,
        },
      }
    }
    case SUMMARY_GET_INTRANATIONAL: {
      return {
        ...state,
        intranational: {
          data: payload,
          loading: false,
        },
      }
    }
    case SUMMARY_GET_INTERNATIONAL: {
      return {
        ...state,
        international: {
          data: payload,
          loading: false,
        },
      }
    }
    case SUMMARY_CHANGE_FAVORITE: {
      return {
        international: state.international.data.map(el => {
          if (el.countryId === payload) {
            el.isFavorite = !el.isFavorite
          }
          return el
        }),
        ...state,
      }
    }
    default:
      return state
  }
}
