import React, { Component } from 'react'
import News from '../../news/list-mini'
import Ads from '../list-mini'
import Table from './ads-table'
import Banner from '../../banner'
import { BANNER_SMALL_SIZE } from '../../../store/constants'
import './style.scss'

export default class extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-xl-12" id="my-ads-main-list">
          <div className="banner-row">
            <Banner size={BANNER_SMALL_SIZE} />
            <Banner size={BANNER_SMALL_SIZE} />
          </div>
          <Table />
        </div>
        <div className="col-xl-4">
          <div className="col-sm-8 col-xl-16">
            <News />
          </div>
          <div className="col-sm-8 col-xl-16">
            <Ads />
          </div>
        </div>
      </div>
    )
  }
}
