import React from 'react'
import FlexBand from 'flexband'
import './styles.scss'
import Rating from '../../UI/star-rating'
import fetch from '../../shared/fetch'
import { AddUserReviewState, AddUserReviewProps } from './types'
import { toastr } from 'react-redux-toastr'
import { Link } from 'react-router-dom'
import { TITLE_MAX, REVIEW_MAX, REVIEW_MIN } from '../constants'
import Captcha from '../../captcha'
import { canDo } from '../../../store/utils'

const initialState: AddUserReviewState = {
  grade: 0,
  title: '',
  review: '',
  captchaVerified: false
}

export default class AddUserReview extends React.Component<AddUserReviewProps, AddUserReviewState> {
  private textareaRef: React.RefObject<any>
  constructor(props) {
    super(props)

    this.state = initialState
  }

  componentDidMount() {
    const { permissions } = this.props
    if (canDo(permissions, 'captcha', 'skip')) {
      this.setState({ captchaVerified: true })
    }
  }

  reinitialize = () => {
    this.setState(initialState)
  }

  handleGrade = (grade: number) => {
    this.setState({ grade })
  }

  handleTitleChange = e => {
    const { value } = e.target
    value.length <= TITLE_MAX && this.setState({ title: value })
  }

  handleReviewChange = e => {
    const { value } = e.target

    value.length <= REVIEW_MAX && this.setState({ review: e.target.value })
  }

  handlePostClick = () => {
    const { refresh, reviewingUserId, answerFor, } = this.props
    const { grade, title, review } = this.state
    const updateTitle = title ? title : "isAnswer"

    fetch('/user/addNewReview', {
      method: 'POST',
      body: JSON.stringify({
        userReviewing: reviewingUserId,
        grade,
        title: updateTitle,
        review,
        answerFor: answerFor?.reviewId || undefined,
      }),
    })
      .then(res => {
        if (res.ok) {
          refresh()
          this.reinitialize()
        }
      })
      .catch(err => toastr.error(err.message))
  }

  render() {
    const { grade, title, review, captchaVerified } = this.state
    const {
      answerFor,
      resetResponse,
      t,
      quota: { reviewQuota, answerQuota },
      permissions
    } = this.props
    const isGrade = grade > 0 && reviewQuota > 0

    const quota = (answerFor && answerQuota <= 0) || (!answerFor && reviewQuota <= 0)

    const noTitleNoContent = review.length < REVIEW_MIN || !title
    const addReviewRules = !isGrade || quota || noTitleNoContent
    const isButtonActive = (answerFor.reviewId ? review.length < REVIEW_MIN : addReviewRules) 

    const showCaptcha = !canDo(permissions, 'captcha', 'skip')

    return (
      <div className="add-review">
        <div>
          <h5 className="title-secondary tt-u">{t('review.leave.feedback')}</h5>
          {!answerFor.reviewId && (
            <span className="secondary">
              {t('review.quota.text', {
                reviewQuota: reviewQuota >= 0 ? reviewQuota : 0,
              })}
            </span>
          )}
        </div>
        {answerFor.reviewId && answerQuota > 0 && (
          <FlexBand className="response-block">
            <span className="secondary">
              {' '}
              {t('review.answer.for')} "{answerFor.reviewTitle}"{' '}
            </span>
            <Link to="#" onClick={resetResponse}>
              {t('cancel')}
            </Link>
          </FlexBand>
        )}
        {!answerFor.reviewId && (
          <FlexBand direction="column">
            <span>{t('review.your.grade')}</span>
            <Rating editMode grade={grade} onClick={this.handleGrade} />
          </FlexBand>
        )}
        {!answerFor.reviewId && (
          <FlexBand direction="column">
            <FlexBand justify="space-between">
              <span> {t('review.add.title')} </span>
              <span className="counter">
                {title.length} / {TITLE_MAX}
              </span>
            </FlexBand>
            <input onChange={this.handleTitleChange} value={title} type="text" />
          </FlexBand>
        )}
        <FlexBand direction="column">
          <FlexBand justify="space-between">
            <span> {t('review')} </span>
            <span className="counter">
              {review.length} / {REVIEW_MAX}
            </span>
          </FlexBand>
          <textarea
            onChange={this.handleReviewChange}
            value={review}
            placeholder={t('review.add.area.ph')}
          />
        </FlexBand>

        {showCaptcha && (
          <Captcha
            verifyCallback={() => {
              this.setState({ captchaVerified: true });
            }}
            expiredCallback={() => this.setState({ captchaVerified: false })}
          />
        )}

        <FlexBand justify="flex-end">
          <button
            className="button button-orange"
            onClick={() => !isButtonActive ? this.handlePostClick() : {}}
            disabled={isButtonActive || !captchaVerified}
          >
            {t('review.publish')}
          </button>
        </FlexBand>
      </div>
    )
  }
}
