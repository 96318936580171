import { connect } from 'react-redux'
import { compose } from 'recompose'

import withTranslation from '../../../../globals/components/withTranslation'
import withPermissions from '../../../../globals/components/withPermissions'
import { loadCars, autoparkSetActiveTab } from '../../../../store/actions/transport'
import { getUserStatistics } from '../../../../store/actions'
import pick from 'lodash/pick'
import Component from './component'
import { IReduxStorage } from '../../../../store/reducers/types'
import { cargoSearch, transportSearch } from '../../../../store/actions'
import { withRouter } from 'react-router-dom'

export default compose(
  withTranslation,
  withPermissions,
  withRouter,
  connect(
    (state: IReduxStorage) => {
      return {
        myCarsList: state.transport.list,
        myCarsListTotal: state.transport.list.total,
        statistics: state.profile.statistics,
        userReviewQuota: state.profile.userReviewQuota,
        isAuth: !!state.auth.accessToken,
        globalActiveTab: state.company.globalActiveTab,
      }
    },
    {
      cargoSearch,
      loadCars,
      autoparkSetActiveTab,
      transportSearch,
      getUserStatistics,
    }
  )
)(Component)
