import React, { Component } from 'react'
import classNames from 'classnames'
import WidgetHeader from '../../../shared/components/widget-header'
import Filter from '../../../shared/components/widget-filter'
import moment from 'moment'
import fetch from '../../../shared/fetch'
import UserPhoto from '../../../user/user-photo'
import Pagination from '../../../UI/pagination'
import { ITEMS_ON_PAGE } from "../../../../store/utils"

import './styles.scss'

const STYLE_BASE = 'block-list-'

export default class EmployeeBlockList extends Component {
  state = {
    filterKey: "",
    companyBlockEmployeeList: {
      items: [],
      totalItems: null,
    },
    pageIndex: 0,
    pageSize: ITEMS_ON_PAGE,
  }

  componentDidMount() {
    this.fetchBlockedItems()
  }

  fetchBlockedItems = () => {
    const { companyInfo: { id } } = this.props
    const { pageIndex, pageSize, filterKey } = this.state
    fetch(`/company/${id}/block-stats`, {
      method: 'POST',
      body: JSON.stringify({
        pageIndex,
        pageSize,
        dateFrom: this.getDateFrom(filterKey)
      }),
    })
      .then(res => {
        if (res.ok) {
          this.setState({
            companyBlockEmployeeList: {
              items: res.d.items,
              totalItems: res.d.items[0] ? res.d.items[0].total : null,
            },
          })
        }
      })
  }

  changeFilterHandler = newFilterKey => {
    this.setState({ filterKey: newFilterKey }, () => {
      this.fetchBlockedItems()
    })
  }

  getDateFrom = (selectedKey) => {
    const today = moment()
    let dateFrom = null
    if (selectedKey === 'fromLastVisit') {
      dateFrom = today.subtract(10, "days")
    } else if (selectedKey === 'today') {
      dateFrom = today
    } else if (selectedKey === 'lastThreeDays') {
      dateFrom = today.subtract(3, "days")
    } else {
      return null
    }

    return moment(dateFrom).format("YYYY-MM-DD HH:mm:ss")
  }

  renderTablesItems = () => {
    const { t } = this.props
    const { companyBlockEmployeeList: { items } } = this.state
    const content = items.map((item, index) => {
      const {
        blockedByUserName,
        blockedByUserLastName,
        blockedDate,
        blockedByCompanyName,
        blockedCargo,
        blockedTransport,
        blockedUserId,
        blockedByPhoto
      } = item
      const comma = blockedCargo && blockedTransport ? "," : ""
      return (
        <tr key={index}>
          <td className="td-date">
            <span>{moment(blockedDate).format("DD/MM/YYYY")}</span>
          </td>
          <td className="td-user-image">
            <span>
              <span className="img-container">
                <UserPhoto customUserId={blockedUserId} photo={blockedByPhoto} gender={1} />
              </span>
              <a href="#" className="name">{blockedByUserName}{blockedByUserLastName}</a>
              {blockedByCompanyName && <a href="#" className="name">“{blockedByCompanyName}“</a>}
            </span>
          </td>
          <td className="td-last"><span>{t('blocked.orders')}: <b>{blockedCargo > 0 && t('blocked.cargo')}{comma} {blockedTransport > 0 && t('blocked.transport')}</b> </span>
          </td>
        </tr>
      )
    })

    return content
  }

  renderEmptyFields = () => {
    const { t } = this.props
    return (
      <div className="empty-filter-data">
        {t('employee.empty.filter')}
      </div>
    )
  }

  paginationClick = page => {
    this.setState({ pageIndex: page }, () => {
      this.fetchBlockedItems()
    })
  }

  renderTable = () => {
    const { pageIndex, companyBlockEmployeeList: { totalItems } } = this.state

    return (
      <>
        <div className="table-content">
          <table className="table table-striped-main table-collapsed">
            <tbody>
              {this.renderTablesItems()}
            </tbody>
          </table>
        </div>
        {totalItems > ITEMS_ON_PAGE && <Pagination
          onClick={this.paginationClick}
          total={totalItems}
          pageIndex={pageIndex}
          pageSize={ITEMS_ON_PAGE}
        />}
      </>
    )
  }

  render() {
    const { onClose, companyInfo: { companyName }, t } = this.props
    const { filterKey, companyBlockEmployeeList: { totalItems } } = this.state
    const dateFiltersOptions = [
      { value: 'today', name: t('ads.todays') },
      { value: 'lastThreeDays', name: t('ads.lastThreeDays') },
      { value: 'fromLastVisit', name: t('ads.lastVist') },
    ]

    const content = totalItems ? this.renderTable() : this.renderEmptyFields()
    return (
      <div className={classNames('panel panel-has-user', `${STYLE_BASE}wrapper`)}>

        <WidgetHeader className="panel-header">
          <div onClick={() => onClose()} className="fa fa-times" />
        </WidgetHeader>

        <div className="block-list-container">
          <div className="panel-content-row">
            <div className="block-list-modal-sub-title title-secondary tt-u">
              {t('users.who.added')} "{companyName}" {t('in.self.black.lists')}
            </div>
            <Filter
              items={dateFiltersOptions}
              emptyOption={"Все"}
              selectedKey={filterKey}
              onClick={this.changeFilterHandler}
            />

          </div>
          {content}
        </div>
      </div>
    )
  }
}
