import React, { Component } from 'react'

import classnames from 'classnames'

import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'

const MODAL_TRANSITION_MODE_OPENING = 'opening'
const MODAL_TRANSITION_MODE_CLOSING = 'closing'

export default class extends Component {

  state = {
    isOpen: false,
    transitionMode: undefined,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isOpen } = nextProps
    let transitionMode = undefined
    if (isOpen !== this.state.isOpen) {
      if (isOpen) {
        transitionMode = MODAL_TRANSITION_MODE_OPENING
      } else {
        transitionMode = MODAL_TRANSITION_MODE_CLOSING
      }

      this.setState({ isOpen, transitionMode })
    }
  }

  handleClose = e => {
    e.preventDefault()
    this.props.onClose()
  }

  handleToggle = event => {
    if (event.keyCode === 27) {
      // ESC clicked
      const { keyboard = false } = this.props.options || {}
      if (keyboard === true) {
        this.props.onClose()
      }
    } else {
      // backdrop clicked
      const { backdrop = false } = this.props.options || {}

      if (backdrop === true) {
        this.props.onClose()
      }
    }
  }

  onClick = event => {
    this.onCloseAll(event)
  }
  onKeyUp = event => {
    const { keyCode } = event
    if (keyCode) {
      this.onCloseAll(event)
    }
  }
  onCloseAll = () => {
    this.props.setActivePopup(undefined)
  }

  render() {
    const {
      // leave these props here to not be included in restProps
      isOpen,
      onClose,
      title,
      className,
      hideHeader = false,
      setActivePopup,
      // all the rest
      ...restProps
    } = this.props

    const modalClassName = classnames(className, {
      opening: this.state.transitionMode === MODAL_TRANSITION_MODE_OPENING,
      closing: this.state.transitionMode === MODAL_TRANSITION_MODE_CLOSING,
    })

    return (
      <Modal
        isOpen={this.state.isOpen}
        toggle={this.handleToggle}
        className={modalClassName}
        modalTransition={{ timeout: 300 }}
        backdropTransition={{ timeout: 0 }}
        {...restProps}
        onClick={this.onCloseAll}
        onKeyUp={this.onKeyUp}
      >
        {!hideHeader && <ModalHeader toggle={this.handleClose}>{title}</ModalHeader>}

        <ModalBody>{this.props.children}</ModalBody>
      </Modal>
    )
  }
}