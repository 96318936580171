import {
  PROFILE_GET_START,
  PROFILE_GET_SUCCESS,
  PROFILE_GET_FAILURE,
  PROFILE_RESET,
  PROFILE_GET_USER_RATING,
  PROFILE_GET_USER_ADD_REVIEW,
  PROFILE_SCROLL_TO_ONCOMMING_ITEMS,
  CAN_ADD
} from './action-types'
import fetch from '../../components/shared/fetch'

export const getProfile = (userId: number, cb) => {
  return dispatch => {
    dispatch({ type: PROFILE_GET_START })
    fetch(`/auth/getProfile/${userId}`)
      .then(res => {
        if (res.ok) {
          dispatch({ type: PROFILE_GET_SUCCESS, payload: res.profile })
          cb(res.profile)
        } else {
          dispatch({ type: PROFILE_GET_FAILURE, payload: res })
        }
      })
      .catch(err => dispatch({ type: PROFILE_GET_FAILURE, payload: err }))
  }
}

export const onCanAdd = (type) => {
  return dispatch => {
    const body = JSON.stringify({ type })

    fetch(`/auth/can-add`, {
      method: 'POST',
      body,
    }).then((res) => {
      dispatch({ type: CAN_ADD, payload: res.allow })
    })
  }

}

export const getGlobalUserRating = (payload) => {
  return dispatch => {
    dispatch({ type: PROFILE_GET_USER_RATING, payload })
  }
}
export const getGlobalCanUserAddReview = (payload) => {
  return dispatch => {
    dispatch({ type: PROFILE_GET_USER_ADD_REVIEW, payload })
  }
}

export const setScrollToOncommingItems = (payload) => {
  return dispatch => {
    dispatch({ type: PROFILE_SCROLL_TO_ONCOMMING_ITEMS, payload })
  }
}

export const resetProfile = () => {
  return { type: PROFILE_RESET }
}
