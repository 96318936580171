export const int14 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Convenția internațională privind armonizarea controalelor mărfurilor la frontiere din 21.10.1982 *)</h4><p></p>

<p></p><h4>PREAMBUL</h4><p></p>

<p>Părțile contractante,</p><br>

<p>dorind să contribuie la îmbunătățirea circulației internaționale a mărfurilor,</p><br>

<p>având în vedere necesitatea facilitării trecerii de mărfuri la frontiere.</p><br>

<p>constatând că măsurile de control la frontiere sunt realizate de diferite servicii de control,</p><br>

<p style="text-indent:2em;">recunoscând că condițiile în care sunt efectuate aceste controale pot fi armonizate în mare măsură, fără să se prejudicieze
 finalitatea, executarea și eficiența lor,</p><br>

<p>convinse că armonizarea controalelor la frontiere constituie un mijloc important pentru atingerea acestor obiective,</p>

<p style="text-indent:2em;"></p><h6>au convenit cele ce urmează:</h6><p></p>

<p style="font-weight:500;">CAPITOLUL I Dispoziții generale</p><br>

<p style="font-weight:500;">ARTICOLUL 1 Definiții</p>

<p style="text-indent:2em;"></p><h6>În sensul prezentei convenții, se înțelege prin:</h6><p></p>

<p style="text-indent:2em;">a) vamă - serviciul administrativ responsabil cu punerea în aplicare a legislației vamale și cu încasarea taxelor la import și la export
 și care răspunde și de aplicarea altor legi și regulamente privind, între altele, importul, tranzitul și exportul de mărfuri;</p><br>

<p style="text-indent:2em;">b) control vamal - ansamblul măsurilor luate în scopul respectării legilor și reglementărilor pe care vama are sarcina să le aplice;</p><br>

<p style="text-indent:2em;">c) inspecție medico-sanitară - inspecția efectuată pentru protecția vieții și sănătății persoanelor și animalelor, cu excepția inspecției
 veterinare;</p><br>

<p style="text-indent:2em;">d) inspecție veterinară - inspecția sanitară efectuată pe animale și pe produsele de origine animală în vederea protejării vieții și sănătății
 persoanelor și animalelor, precum și cea realizată asupra obiectelor sau mărfurilor care pot fi purtătoare de boli de animale;</p><br>

<p style="text-indent:2em;">e) inspecție fitosanitară - inspecția efectuată pentru a împiedica introducerea prin frontierele naționale și răspândirea de organisme
 dăunătoare pentru vegetale și produse vegetale;</p><br>

<p style="text-indent:2em;">f) control de conformitate cu standardele tehnice - controlul al cărui scop este de a verifica dacă mărfurile respectă cerințele minime
 ale standardelor internaționale sau naționale prevăzute de legile și reglementările aferente;</p><br>

<p style="text-indent:2em;">g) controlul calității - orice alt control decât cele menționate mai sus, prin care se verifică dacă mărfurile corespund definițiilor
 minime de calitate internaționale sau naționale, prevăzute în legile și reglementările aferente;</p><br>

<p style="text-indent:2em;">h) serviciul de control - orice serviciu însărcinat cu efectuarea tuturor sau a unora dintre controalele definite mai sus, a oricăror
 alte controale efectuate de obicei la importul, exportul sau tranzitul de mărfuri.</p><br>

<p style="font-weight:500;">ARTICOLUL 2 Obiectiv</p><br>

<p style="text-indent:2em;">În vederea facilitării circulației internaționale a mărfurilor, prezenta convenție vizează reducerea obligațiilor legate de îndeplinirea
 formalităților, numărul și durata controalelor, în principal printr-o coordonare națională și internațională a procedurilor de control
 și a modalităților de aplicare.</p><br>

<p style="font-weight:500;">ARTICOLUL 3 Domeniu de aplicare</p><br>

<p style="text-indent:2em;">1. Prezenta convenție se aplică tuturor bunurilor importate, exportate sau în tranzit, care traversează una sau mai multe frontiere maritime,
 aeriene sau terestre.</p><br>

<p>2. Prezenta convenție se aplică tuturor serviciilor de control ale părților contractante.</p><br>

<p style="font-weight:500;">CAPITOLUL II Armonizarea procedurilor</p><br>

<p style="font-weight:500;">ARTICOLUL 4 Coordonarea controalelor</p><br>

<p style="text-indent:2em;">Părțile contractante se angajează, în măsura posibilului, să organizeze în mod armonizat intervențiile serviciilor
 vamale și ale altor servicii de control.</p><br>

<p style="font-weight:500;">ARTICOLUL 5 Modul în care acționează serviciile</p>

<p style="text-indent:2em;"></p><h6>Pentru a asigura buna funcționare a serviciilor de control părțile contractante vor urmări, în măsura posibilului și în
 cadrul competențelor conferite prin legislația națională, să asigure:</h6><p></p>

<p>a) un personal calificat, în număr suficient, ținând seama de cerințele traficului;</p><br>

<p style="text-indent:2em;">b) materiale și instalații adecvate pentru controale, ținând seama de modul de transport, de bunurile de controlat și de
 cerințele traficului;</p><br>

<p style="text-indent:2em;">c) instrucțiuni oficiale pentru funcționarii acestor servicii, astfel încât ei să acționeze în conformitate cu acordurile
 și înțelegerile internaționale și cu dispozițiile naționale în vigoare.</p><br>

<p style="font-weight:500;">ARTICOLUL 6 Cooperare internațională</p><br>

<p style="text-indent:2em;">Părțile contractante se angajează să coopereze reciproc și, când este necesar, cu organismele internaționale competente,
 pentru a îndeplini obiectivele stabilite prin prezenta convenție și, dacă este cazul, pentru a încheia noi acorduri sau
 înțelegeri multilaterale ori bilaterale.</p><br>

<p style="font-weight:500;">ARTICOLUL 7 Cooperare între țările vecine</p>

<p style="text-indent:2em;"></p><h6>La trecerea unei frontiere comune părțile contractante interesate vor lua, atunci când este posibil, toate măsurile
 necesare pentru a facilita trecerea mărfurilor și, mai ales:</h6><p></p>

<p>a) vor organiza controale comune pentru mărfuri și documente, prin punerea în aplicare a unor instalații comune;</p>

<p style="text-indent:2em;"></p><h6>b) vor asigura realizarea unei concordanțe între:</h6><p></p>

<p> orele de program ale punctelor de trecere a frontierei;</p><br>

<p> serviciile de control care își exercită activitatea în acel loc;</p><br>

<p> categoriile de mărfuri, mijloacele de transport și regimurile internaționale de tranzit vamal care pot fi acceptate
 sau utilizate.</p><br>

<p style="font-weight:500;">ARTICOLUL 8 Schimbul de informații</p><br>

<p style="text-indent:2em;">Părțile contractante își comunică reciproc, la cerere, informațiile necesare pentru punerea în aplicare a prezentei
 convenții, în conformitate cu condițiile menționate în anexe.</p><br>

<p style="font-weight:500;">ARTICOLUL 9 Documente</p><br>

<p style="text-indent:2em;">1. Părțile contractante vor căuta să utilizeze, între ele și în relațiile cu organismele internaționale competente,
 documente aliniate la formularul-cadru al Organizației Națiunilor Unite.</p><br>

<p style="text-indent:2em;">2. Părțile contractante vor accepta documentele elaborate prin orice procedeu tehnic specific, dacă sunt conforme din
 punct de vedere al formei, autenticității și certificării cu reglementările oficiale, dacă sunt lizibile și
 inteligibile.</p><br>

<p style="text-indent:2em;">3. Părțile contractante vor urmări ca documentele necesare să fie întocmite și autentificate cu respectarea strictă
 a legislației în domeniu.</p><br>

<p style="font-weight:500;">CAPITOLUL III Dispoziții referitoare la tranzit</p><br>

<p style="font-weight:500;">ARTICOLUL 10 Mărfuri în tranzit</p><br>

<p style="text-indent:2em;">1. Părțile contractante vor aplica mărfurilor în tranzit, în măsura posibilului, o prelucrare simplă și rapidă,
 în special celor care circulă sub un regim internațional de tranzit vamal, limitând controalele numai pentru cazurile
 în care împrejurările sau riscurile reale le justifică. În plus, ele vor ține seama de situația țărilor fără ieșire la
 mare. Se va avea în vedere o prelungire a orelor de program pentru vămuire și de funcționare a unităților vamale existente,
 pentru vămuirea mărfurilor care circulă cu un regim internațional de tranzit vamal.</p><br>

<p style="text-indent:2em;">2. Se va urmări facilitarea la maximum a tranzitului de mărfuri transportate în containere sau în alte unități de încărcare
 care prezintă o securitate adecvată.</p><br>

<p style="font-weight:500;">CAPITOLUL IV Alte dispoziții</p><br>

<p style="font-weight:500;">ARTICOLUL 11 Ordine publică</p><br>

<p style="text-indent:2em;">1. Nici una dintre prevederile prezentei convenții nu constituie un obstacol la aplicarea interdicțiilor sau restricțiilor la import,
 export sau tranzit, impuse din considerente de ordine publică, în special de securitate publică, morală publică și sănătate publică,
 sau de protecție a mediului, patrimoniului cultural ori a proprietății industriale, comerciale și intelectuale.</p><br>

<p style="text-indent:2em;">2. Totuși, când este posibil, fără a prejudicia eficiența controalelor, pentru controalele la care se aplică măsurile menționate la 
paragraful 1 părțile contractante se vor strădui să aplice dispozițiile prezentei convenții, cu precădere cele care fac obiectul art
. 6-9.</p><br>

<p style="font-weight:500;">ARTICOLUL 12 Măsuri de urgență</p><br>

<p style="text-indent:2em;">1. Măsurile de urgență pe care părțile contractante le pot lua în anumite situații speciale trebuie să fie în concordanță cu cauzele
 care au impus adoptarea lor și trebuie suspendate sau abrogate când acestea dispar.</p><br>

<p style="text-indent:2em;">2. Când este posibil și fără a prejudicia eficiența măsurilor, părțile
 contractante vor publica dispozițiile privind aceste măsuri.</p><br>

<p style="font-weight:500;">ARTICOLUL 13 Anexe</p><br>

<p>1. Anexele la prezenta convenție fac parte integrantă din aceasta.</p><br>

<p style="text-indent:2em;">2. Anexe noi privind alte sectoare de control pot fi adăugate la prezenta convenție, în conformitate cu procedura menționată la art.
 22 sau 24.</p><br>

<p style="font-weight:500;">ARTICOLUL 14 Relații cu alte tratate</p><br>

<p style="text-indent:2em;">Sub rezerva prevederilor art. 6, prezenta convenție nu prejudiciază drepturile sau obligațiile ce rezultă din tratatele pe care părțile
 contractante la prezenta convenție le încheiaseră înainte de a deveni părți contractante la aceasta.</p><br>

<p style="font-weight:500;">ARTICOLUL 15</p><br>

<p style="text-indent:2em;">Prezenta convenție nu constituie un obstacol pentru aplicarea unor facilități mai mari, pe care două sau mai multe părți contractante ar
 dori să și le acorde, și nici pentru organizațiile regionale de integrare economică specificate la art. 16, care sunt părți contractante,
 în aplicarea legislației proprii la controalele efectuate la frontierele interne, fără să se reducă în vreun fel facilitățile care decurg
 din prezenta convenție.</p><br>

<p style="font-weight:500;">ARTICOLUL 16 Semnarea, ratificarea, acceptarea, aprobarea și aderarea</p><br>

<p style="text-indent:2em;">1. Prezenta convenție, depusă la Secretariatul General al Organizației Națiunilor Unite, este deschisă participării tuturor statelor și
 organizațiilor regionale de integrare economică formate din statele suverane care au competență să negocieze, să încheie și să aplice
 acorduri internaționale în domeniile acoperite de prezenta convenție.</p><br>

<p style="text-indent:2em;">2. În cadrul competențelor proprii organizațiile regionale de integrare economică specificate la paragraful 1 pot să își exercite
 drepturile în nume propriu și să se achite de responsabilitățile conferite de prezenta convenție statelor membre care sunt părți
 contractante la aceasta. În acest caz statele membre ale acestor organizații nu sunt abilitate să își exercite drepturile în mod
 individual, inclusiv dreptul la vot.</p>

<p style="text-indent:2em;"></p><h6>3. Statele și organizațiile regionale de integrare economică menționate mai sus
 pot deveni părți contractante la prezenta convenție:</h6><p></p>

<p>a) depunând un instrument de ratificare, acceptare sau aprobare, după ce în prealabil au semnat-o; sau</p><br>

<p>b) depunând un instrument de aderare.</p><br>

<p style="text-indent:2em;">4. Prezenta convenție va fi deschisă pentru semnare la Oficiul Națiunilor Unite de la Geneva, începând cu data de 1 aprilie
 1983 până la data de 31 martie 1984 inclusiv, pentru toate statele și organizațiile regionale de integrare economică
 specificate la paragraful 1.</p><br>

<p>5. De asemenea, prezenta convenție va fi deschisă pentru aderare începând cu data de 1 aprilie 1983.</p><br>

<p style="text-indent:2em;">6. Instrumentele de ratificare, acceptare, aprobare sau aderare vor fi depuse la Secretariatul General al Organizației
 Națiunilor Unite.</p><br>

<p style="font-weight:500;">ARTICOLUL 17 Intrarea în vigoare</p><br>

<p style="text-indent:2em;">1. Prezenta convenție va intra în vigoare la 3 luni după data la care 5 state și-au depus instrumentele de ratificare,
 acceptare, aprobare sau aderare.</p><br>

<p style="text-indent:2em;">2. După ce 5 state vor depune instrumentele de ratificare, de acceptare, de aprobare sau de aderare prezenta convenție va
 intra în vigoare, pentru alte părți contractante noi, după 3 luni de la data depunerii instrumentelor lor de ratificare,
 acceptare, aprobare sau aderare.</p><br>

<p style="text-indent:2em;">3. Orice instrument de ratificare, acceptare, aprobare sau aderare depus după intrarea în vigoare a unui amendament la prezenta
 convenție se consideră că este aplicat la textul modificat al prezentei convenții.</p><br>

<p style="text-indent:2em;">4. Un astfel de instrument, depus după acceptarea unui amendament în conformitate cu procedura prevăzută la art. 22, dar înainte
 de intrarea sa în vigoare, se consideră că este aplicat la textul modificat al prezentei convenții de la data intrării în
 vigoare a amendamentului.</p><br>

<p style="font-weight:500;">ARTICOLUL 18 Denunțarea</p><br>

<p style="text-indent:2em;">1. Orice parte contractantă poate să denunțe prezenta convenție printr-o notificare adresată secretarului general al 
Organizației Națiunilor Unite.</p><br>

<p>2. Denunțarea va intra în vigoare după 6 luni de la data la care secretarul general a primit notificarea.</p><br>

<p style="font-weight:500;">ARTICOLUL 19 Încetarea valabilității</p><br>

<p style="text-indent:2em;">În cazul în care, după intrarea în vigoare a prezentei convenții, pentru o perioadă de 12 luni consecutive, numărul statelor
 care sunt părți contractante a scăzut la mai puțin de 5, prezenta convenție își încetează valabilitatea la încheierea acestei
 perioade de 12 luni.</p><br>

<p style="font-weight:500;">ARTICOLUL 20 Soluționarea diferendelor</p><br>

<p style="text-indent:2em;">1. Orice diferend între două sau mai multe părți contractante cu privire la interpretarea sau aplicarea prezentei convenții
 va fi, pe cât posibil, soluționat pe calea negocierilor între părțile aflate în litigiu sau prin altă metodă.</p><br>

<p style="text-indent:2em;">2. Orice diferend între două sau mai multe părți contractante cu privire la interpretarea sau aplicarea prezentei convenții,
 care nu poate fi soluționat așa cum se indică la paragraful 1, la cererea uneia dintre părțile contractante va fi înaintat
 unei instanțe de arbitraj alcătuite după cum urmează: fiecare dintre părțile implicate în diferend va numi câte un arbitru,
 iar acești arbitri vor desemna un alt arbitru pentru funcția de președinte. În cazul în care, la 3 luni după formularea cererii,
 una dintre părți nu și-a numit un arbitru sau dacă arbitrii nu au putut să aleagă un președinte, oricare dintre aceste părți
 va putea să ceară secretarului general al Organizației Națiunilor Unite să numească un arbitru sau un președinte al instanței
 de arbitraj.</p><br>

<p style="text-indent:2em;">3. Decizia instanței de arbitraj, adoptată în conformitate cu prevederile paragrafului 2, va fi definitivă și obligatorie
 pentru părțile implicate în diferend.</p><br>

<p>4. Instanța de arbitraj își va elabora propriul regulament interior.</p><br>

<p style="text-indent:2em;">5. Instanța de arbitraj va lua deciziile cu majoritate de voturi și în baza tratatelor existente între părțile implicate în
 diferend și a regulilor generale de drept internațional.</p><br>

<p style="text-indent:2em;">6. Orice controversă care ar putea să apară între părțile implicate în diferend, cu privire la interpretarea sau executarea
 sentinței de arbitraj, va putea fi adusă de oricare dintre părți, spre soluționare, în fața instanței de arbitraj care a dat
 sentința.</p><br>

<p style="text-indent:2em;">7. Fiecare parte implicată în diferend suportă cheltuielile cu arbitrul propriu și cu reprezentanții săi în cadrul procedurii
 arbitrale; cheltuielile privind președinția și alte costuri sunt suportate în părți egale de părțile implicate 
 în diferend.</p><br>

<p style="font-weight:500;">ARTICOLUL 21 Rezerve</p><br>

<p style="text-indent:2em;">1. În momentul în care semnează, ratifică, acceptă sau aprobă prezenta convenție orice parte contractantă care va adera
 poate să declare că nu are obligația să respecte paragrafele 2-7 ale art. 20 din prezenta convenție.</p><br>

<p style="text-indent:2em;">Celelalte părți contractante nu vor avea obligația să respecte aceste paragrafe în raport cu orice altă parte contractantă
 care va formula o atare rezervă.</p><br>

<p style="text-indent:2em;">2. Orice parte contractantă care ar formula o rezervă în conformitate cu paragraful 1 va putea oricând să își retragă
 această rezervă printr-o notificare adresată secretarului general al Organizației Națiunilor Unite.</p><br>

<p>3. Nici o rezervă la prezenta convenție nu este permisă, cu excepția celor 
prevăzute la paragraful 1.</p><br>

<p style="font-weight:500;">ARTICOLUL 22 Procedura de amendare a convenției</p><br>

<p style="text-indent:2em;">1. Prezenta convenție, inclusiv anexele la aceasta, poate fi modificată la propunerea unei părți contractante după
 procedura prevăzută în prezentul articol.</p><br>

<p style="text-indent:2em;">2. Orice amendament propus la prezenta convenție va fi examinat de un comitet de gestiune format din toate părțile
 contractante în conformitate cu regulamentul interior care face obiectul anexei nr. 7. Orice amendament de acest
 fel, examinat sau elaborat în cursul reuniunii Comitetului de gestiune și adoptat de acesta, va fi comunicat de
 secretarul general al Organizației Națiunilor Unite părților contractante pentru acceptare.</p><br>

<p style="text-indent:2em;">3. Orice amendament propus, comunicat în conformitate cu prevederile paragrafului precedent, va intra în vigoare
 pentru toate părțile contractante la 3 luni după expirarea unei perioade de 12 luni de la data comunicării amendamentului
 propus, dacă în această perioadă nu s-a comunicat secretarului general al Organizației Națiunilor Unite nici o obiecție
 din partea vreunui stat-parte contractantă sau a vreunei organizații regionale de integrare economică europeană, ea
 însăși parte contractantă, care acționează în condițiile specificate la art. 16 paragraful 2 din prezenta convenție.</p><br>

<p style="text-indent:2em;">4. În cazul în care, în conformitate cu prevederile paragrafului 3, se formulează o obiecție la amendamentul propus,
 se consideră că acesta nu a fost acceptat și că nu va avea nici un efect.</p><br>

<p style="font-weight:500;">ARTICOLUL 23 Cereri, comunicări și obiecții</p><br>

<p style="text-indent:2em;">Secretarul general al Organizației Națiunilor Unite va informa toate părțile contractante și toate statele în legătură
 cu orice cerere, comunicare sau obiecție formulată în conformitate cu art. 22 și cu data intrării în vigoare a unui
 amendament.</p><br>

<p style="font-weight:500;">ARTICOLUL 24 Conferința de revizuire</p>

<p style="text-indent:2em;"></p><h6>După o perioadă de 5 ani de la intrarea în vigoare a convenției orice parte contractantă va putea, printr-o notificare
 adresată secretarului general al Organizației Națiunilor Unite, să ceară convocarea unei conferințe în scopul revizuirii
 prezentei convenții, indicând propunerile ce trebuie examinate la conferință. În acest caz:</h6><p></p>

<p style="text-indent:2em;">(i) secretarul general al Organizației Națiunilor Unite va comunica cererea tuturor părților contractante și le va solicita
 să prezinte, în termen de 3 luni, observațiile proprii față de propunerile formulate, precum și alte propuneri care ar dori
 să fie examinate la conferință;</p><br>

<p style="text-indent:2em;">(ii) secretarul general al Organizației Națiunilor Unite va comunica tuturor părților contractante și textele altor eventuale
 propuneri și va convoca o conferință de revizuire dacă, într-o perioadă de 6 luni de la data comunicării, a primit acordul pentru
 această convocare de la cel puțin o treime din numărul părților contractante;</p><br>

<p style="text-indent:2em;">(iii) cu toate acestea, dacă secretarul general al Organizației Națiunilor Unite apreciază că propunerea de revizuire poate fi considerată
 o propunere de amendament în sensul paragrafului 1 al art. 22, cu acordul părții contractante care a făcut propunerea,
 în locul procedurii de revizuire acesta poate să pună în aplicare procedura de amendare prevăzută la art. 22.</p><br>

<p style="font-weight:500;">ARTICOLUL 25 Notificări</p>

<p style="text-indent:2em;"></p><h6>În afară de notificările și comunicările prevăzute la art. 23 și 24 secretarul general al Organizației Națiunilor
 Unite va comunica tuturor statelor următoarele:</h6><p></p>

<p>a) semnările, ratificările, acceptările, aprobările și aderările în sensul art. 16;</p><br>

<p>b) datele de intrare în vigoare a prezentei convenții în conformitate cu prevederile art. 17;</p><br>

<p>c) denunțările în sensul art. 18;</p><br>

<p>d) încetarea valabilității prezentei convenții în sensul art. 19;</p><br>

<p>e) rezervele formulate în sensul art. 21.</p><br>

<p style="font-weight:500;">ARTICOLUL 26 Exemplarele certificate conforme</p><br>

<p style="text-indent:2em;">După data de 31 martie 1984 secretarul general al Organizației Națiunilor Unite va transmite două copii certificate conforme ale
 prezentei convenții fiecărei părți contractante și tuturor statelor care nu sunt părți contractante.</p><br>

<p style="text-indent:2em;">Întocmită la Geneva la 21 octombrie 1982, într-un singur exemplar original, în limbile engleză, spaniolă,
 franceză și rusă, toate textele având aceeași valabilitate.</p><br>

<p>Drept care, subsemnații, pe deplin autorizați, au semnat prezenta convenție.</p><br>

<p style="font-weight:500;">ANEXA Nr. 1
ARMONIZAREA controalelor vamale și a altor controale</p><br>

<p style="font-weight:500;">ARTICOLUL 1 Principii</p><br>

<p style="text-indent:2em;">1. Ținând seama de prezența vămii la toate punctele de trecere a frontierei și de caracterul general al
 intervenției ei, în măsura posibilului celelalte controale sunt armonizate cu controalele vamale.</p><br>

<p style="text-indent:2em;">2. În aplicarea acestui principiu este posibil ca, parțial sau total, aceste controale să se realizeze și
 în alte locuri decât la frontieră, cu condiția ca procedurile utilizate să contribuie la facilitarea
 circulației internaționale a mărfurilor.</p><br>

<p style="font-weight:500;">ARTICOLUL 2 Informații</p><br>

<p style="text-indent:2em;">1. Vama trebuie să fie informată în legătură cu prevederile legale sau reglementare care pot conduce la
 organizarea de controale, altele decât cele vamale.</p><br>

<p style="text-indent:2em;">2. Când se consideră că sunt necesare alte controale, vama va asigura informarea serviciilor interesate
 și va coopera cu acestea.</p><br>

<p style="font-weight:500;">ARTICOLUL 3 Organizarea controalelor</p><br>

<p style="text-indent:2em;">1. Când trebuie efectuate mai multe controale în același loc, serviciile competente vor lua toate măsurile
 necesare în vederea efectuării lor simultan, dacă este posibil, sau la intervale scurte de timp. Acestea
 vor urmări să coordoneze cerințele în materie de documente și informații.</p><br>

<p style="text-indent:2em;">2. În speță, serviciile competente vor lua toate măsurile ce se impun pentru ca personalul și instalațiile
 necesare să fie disponibile în locul în care se efectuează controalele.</p><br>

<p style="text-indent:2em;">3. Prin delegarea explicită a serviciilor competente vama va putea, în numele acestora, să realizeze toate
 sau o parte dintre controalele ce revin acestor servicii. În acest caz serviciile respective vor furniza
 vămii toate mijloacele necesare.</p><br>

<p style="font-weight:500;">ARTICOLUL 4 Rezultatul controalelor</p><br>

<p style="text-indent:2em;">1. În toate aspectele legate de punerea în aplicare a prezentei convenții serviciile de control și vama vor
 efectua un schimb de informații utile în cel mai scurt timp posibil, pentru a asigura eficiența
 controalelor.</p><br>

<p style="text-indent:2em;">2. Pe baza rezultatelor controalelor efectuate serviciul competent va decide ce prelucrare vor urma mărfurile
 și, dacă este necesar, va informa serviciile competente responsabile cu celelalte controale. În baza acestei
 decizii vama va atribui mărfurilor regimul vamal corespunzător.</p><br>

<p style="font-weight:500;">ANEXA Nr. 2
INSPECȚIA MEDICO-SANITARĂ</p><br>

<p style="font-weight:500;">ARTICOLUL 1 Principii</p><br>

<p style="text-indent:2em;">Oriunde s-ar efectua, inspecția medico-sanitară se realizează în conformitate cu principiile definite în prezenta
 convenție, în special în anexa nr. 1.</p><br>

<p style="font-weight:500;">ARTICOLUL 2 Informații</p>

<p style="text-indent:2em;"></p><h6>Fiecare parte contractantă va urmări ca orice persoană interesată să poată obține rapid informații
 referitoare la următoarele:</h6><p></p>

<p>mărfurile supuse inspecției medico-sanitare;</p><br>

<p>locurile în care mărfurile în cauză pot fi prezentate pentru inspecție;</p><br>

<p>prevederile legale și reglementare referitoare la inspecția medico-sanitară, ca și regulile de
 aplicare generală.</p><br>

<p style="font-weight:500;">ARTICOLUL 3 Organizarea controalelor</p><br>

<p style="text-indent:2em;">1. Serviciile de control vor urmări ca la punctele de frontieră deschise pentru inspecția medico-sanitară
 să fie disponibile instalațiile necesare.</p><br>

<p style="text-indent:2em;">2. Inspecția medico-sanitară poate fi realizată și în puncte situate în interiorul țării în măsura în care,
 conform unor documente justificative sau prin utilizarea mijloacelor de transport corespunzătoare, se demonstrează
 că mărfurile nu se pot deteriora și nici nu pot provoca o contaminare în timpul transportului.</p><br>

<p style="text-indent:2em;">3. În cadrul convențiilor în vigoare părțile contractante vor urmări să reducă, pe cât posibil, controalele fizice
 ale mărfurilor perisabile aflate în cursul transportului.</p><br>

<p style="text-indent:2em;">4. Când mărfurile trebuie stocate în așteptarea rezultatelor inspecției medico-sanitare, serviciile de control
 competente ale părților contractante vor urmări ca această depozitare să se realizeze în condiții care să permită
 conservarea bunurilor cu minimum de formalități vamale.</p><br>

<p style="font-weight:500;">ARTICOLUL 4 Mărfuri în tranzit</p><br>

<p style="text-indent:2em;">În cadrul convențiilor în vigoare părțile contractante vor renunța, pe cât posibil, la inspecția medico-sanitară
 a bunurilor aflate în tranzit, în măsura în care nu există riscul de contaminare.</p><br>

<p style="font-weight:500;">ARTICOLUL 5 Cooperare</p><br>

<p style="text-indent:2em;">1. Serviciile de inspecție medico-sanitară vor coopera cu serviciile omoloage ale celorlalte părți contractante
 în vederea accelerării trecerii bunurilor perisabile supuse inspecției medico-sanitare, în special prin schimb
 de informații utile.</p><br>

<p style="text-indent:2em;">2. Când un transport de mărfuri perisabile este interceptat în cursul inspecției medico-sanitare, serviciul
 responsabil va urmări să informeze cât mai curând posibil serviciul omolog din țara de export, indicând motivele
 interceptării și măsurile luate în legătură cu aceste mărfuri.</p><br>

<p style="font-weight:500;">ANEXA Nr. 3
INSPECȚIA VETERINARĂ</p><br>

<p style="font-weight:500;">ARTICOLUL 1 Principii</p><br>

<p style="text-indent:2em;">Oriunde s-ar efectua, inspecția veterinară se realizează în conformitate cu principiile definite în prezenta
 convenție, în special în anexa nr. 1.</p><br>

<p style="font-weight:500;">ARTICOLUL 2 Definiții</p><br>

<p style="text-indent:2em;">Inspecția veterinară definită în art. 1 lit. d) din prezenta convenție cuprinde, de asemenea, și inspectarea
 mijloacelor și condițiilor de transport al animalelor și al produselor animale. Ea include și inspecțiile privind
 calitatea, standardele și anumite reglementări, cum ar fi cele referitoare la speciile amenințate cu dispariția,
 care, pentru eficiență, sunt deseori asociate inspecției veterinare.</p><br>

<p style="font-weight:500;">ARTICOLUL 3 Informații</p>

<p style="text-indent:2em;"></p><h6>Fiecare parte contractantă va urmări ca orice persoană interesată să poată obține rapid informații referitoare la:</h6><p></p>

<p>mărfurile supuse unei inspecții medico-sanitare;</p><br>

<p>locurile în care mărfurile pot fi prezentate pentru inspecție;</p><br>

<p>bolile a căror declarare este obligatorie;</p><br>

<p>prevederile legale și reglementările referitoare la inspecția veterinară, precum și procedurile de aplicare generală.</p><br>

<p style="font-weight:500;">ARTICOLUL 4 Organizarea controalelor</p>

<p style="text-indent:2em;"></p><h6>1. Părțile contractante vor urmări:</h6><p></p>

<p style="text-indent:2em;"> să fie montate, când este nevoie sau posibil, instalațiile corespunzătoare pentru inspecția veterinară, în conformitate
 cu cerințele traficului;</p><br>

<p style="text-indent:2em;"> să faciliteze circulația mărfurilor, în special prin coordonarea programelor de lucru ale serviciilor veterinare și ale celor
 vamale și prin acceptarea efectuării formalităților în afara orelor de program, în cazul în care sosirea mărfurilor a fost
 anunțată în prealabil.</p><br>

<p style="text-indent:2em;">2. Inspecția produselor animale va putea fi efectuată și în puncte situate în interiorul țării, în măsura în care, conform unor
 documente justificative sau prin utilizarea mijloacelor de transport corespunzătoare, se demonstrează că produsele nu se pot
 deteriora și nici nu pot provoca o contaminare în timpul transportului.</p><br>

<p style="text-indent:2em;">3. În cadrul convențiilor în vigoare părțile contractante vor urmări să reducă pe cât posibil controalele fizice ale mărfurilor
 perisabile transportate.</p><br>

<p style="text-indent:2em;">4. Când mărfurile trebuie stocate în așteptarea rezultatelor inspecției medico-sanitare, serviciile de control competente ale
 părților contractante vor urmări ca această depozitare să se realizeze cu minimum de formalități vamale, în condiții în care
 să permită securitatea de carantină și conservarea bunurilor.</p><br>

<p style="font-weight:500;">ARTICOLUL 5 Mărfuri în tranzit</p><br>

<p style="text-indent:2em;">În cadrul convențiilor în vigoare părțile contractante vor renunța, pe cât posibil, la inspecția veterinară a produselor animale
 aflate în tranzit, în măsura în care nu există riscul de contaminare.</p><br>

<p style="font-weight:500;">ARTICOLUL 6 Cooperare</p><br>

<p style="text-indent:2em;">1. Serviciile de inspecție veterinară vor coopera cu serviciile omoloage ale celorlalte părți contractante în vederea accelerării
 trecerii mărfurilor supuse inspecției veterinare, în special prin schimb de informații utile.</p><br>

<p style="text-indent:2em;">2. Când un transport de mărfuri perisabile sau de animale vii este interceptat în cursul inspecției veterinare, serviciul
 responsabil va urmări să informeze, cât mai curând posibil, serviciul omolog din țara de export, indicând motivele
 interceptării și măsurile luate în legătură cu mărfurile.</p><br>

<p style="font-weight:500;">ANEXA Nr. 4</p><br>

<p style="font-weight:500;">INSPECȚIA FITOSANITARĂ</p><br>

<p style="font-weight:500;">ARTICOLUL 1 Principii</p><br>

<p style="text-indent:2em;">Oriunde s-ar efectua, inspecția fitosanitară se realizează în conformitate cu principiile definite în prezenta convenție,
 în special în anexa nr. 1.</p><br>

<p style="font-weight:500;">ARTICOLUL 2 Definiții</p><br>

<p style="text-indent:2em;">Inspecția fitosanitară definită în art. 1 lit. e) din prezenta convenție cuprinde, de asemenea, și inspectarea mijloacelor
 și condițiilor de transport al vegetalelor și produselor vegetale. Ea include și măsurile privind conservarea speciilor
 vegetale amenințate cu dispariția.</p><br>

<p style="font-weight:500;">ARTICOLUL 3 Informații</p>

<p style="text-indent:2em;"></p><h6>Fiecare parte contractantă va urmări ca orice persoană interesată să poată obține rapid informații referitoare la:</h6><p></p>

<p>mărfurile supuse unor condiții fitosanitare speciale;</p><br>

<p>locurile în care anumite plante sau produse vegetale pot fi prezentate pentru inspecție;</p><br>

<p style="text-indent:2em;">lista cuprinzând organismele dăunătoare vegetalelor și produselor vegetale pentru care sunt în vigoare interdicții
 sau restricții;</p><br>

<p>prevederile legale și reglementările referitoare la inspecția fitosanitară, precum și regulile de aplicare generală.</p><br>

<p style="font-weight:500;">ARTICOLUL 4 Organizarea controalelor</p>

<p style="text-indent:2em;"></p><h6>1. Părțile contractante vor urmări:</h6><p></p>

<p style="text-indent:2em;"> să fie montate, când este nevoie sau posibil, instalațiile corespunzătoare pentru inspecția fitosanitară, stocarea,
 dezinsecția și dezinfectarea, în conformitate cu cerințele traficului;</p><br>

<p style="text-indent:2em;"> să faciliteze circulația mărfurilor, în special prin coordonarea programelor de lucru ale serviciilor fitosanitare
 și ale celor vamale și prin acceptarea efectuării formalităților pentru mărfurile perisabile în afara orelor de program,
 în cazul în care sosirea acestora a fost anunțată în prealabil.</p><br>

<p style="text-indent:2em;">2. Inspecția fitosanitară a vegetalelor și produselor vegetale va putea fi efectuată și în puncte situate în interiorul
 țării în măsura în care, folosind mijloace de transport corespunzătoare, se poate demonstra că mărfurile nu vor provoca
 infestări în timpul transportului.</p><br>

<p style="text-indent:2em;">3. În cadrul convențiilor în vigoare, părțile contractante vor urmări să reducă, pe cât posibil, controalele fizice ale
 vegetalelor și produselor vegetale perisabile, aflate în cursul transportului.</p><br>

<p style="text-indent:2em;">4. Când mărfurile trebuie stocate în așteptarea rezultatelor inspecției fitosanitare, serviciile de control competente
 ale părților contractante vor urmări ca această depozitare să se realizeze cu minimum de formalități vamale, în condiții
 care să permită securitatea de carantină și conservarea mărfurilor.</p><br>

<p style="font-weight:500;">ARTICOLUL 5 Mărfuri în tranzit</p><br>

<p style="text-indent:2em;">În cadrul convențiilor în vigoare părțile contractante vor renunța, pe cât posibil, la inspecția fitosanitară a mărfurilor
 aflate în tranzit, în afara cazului în care această măsură este necesară pentru protecția propriei flore.</p><br>

<p style="font-weight:500;">ARTICOLUL 6 Cooperare</p><br>

<p style="text-indent:2em;">1. Serviciile fitosanitare vor coopera cu serviciile omoloage ale celorlalte părți contractante în vederea accelerării 
trecerii vegetalelor și produselor vegetale supuse inspecției fitosanitare, în special prin schimb de informații 
utile.</p><br>

<p style="text-indent:2em;">2. Când un transport de vegetale sau de produse vegetale este interceptat în cursul inspecției fitosanitare, serviciul 
responsabil va urmări să informeze cât mai curând posibil serviciul omolog din țara de export, indicând motivele 
interceptării și măsurile luate în legătură cu mărfurile.</p><br>

<p style="font-weight:500;">ANEXA Nr. 5
CONTROLUL DE CONFORMITATE CU STANDARDELE TEHNICE</p><br>

<p style="font-weight:500;">ARTICOLUL 1 Principii</p><br>

<p style="text-indent:2em;">Oriunde s-ar efectua, controlul de conformitate cu standardele tehnice prevăzute în prezenta convenție se realizează
 ținându-se seama de principiile definite în aceasta, în special în anexa nr. 1.</p><br>

<p style="font-weight:500;">ARTICOLUL 2 Informații</p>

<p style="text-indent:2em;"></p><h6>Fiecare parte contractantă va urmări ca orice persoană interesată să poată obține rapid
 informații referitoare la:</h6><p></p>

<p>standardele pe care le aplică;</p><br>

<p>locurile în care mărfurile pot fi prezentate pentru inspecție;</p><br>

<p style="text-indent:2em;">prevederile legale și reglementările referitoare la controlul de conformitate cu standardele tehnice, precum și
 regulile de aplicare generală.</p><br>

<p style="font-weight:500;">ARTICOLUL 3 Armonizarea standardelor</p><br>

<p style="text-indent:2em;">În absența standardelor internaționale părțile contractante care aplică standardele naționale vor urmări
 să le armonizeze pe calea acordurilor internaționale.</p><br>

<p style="font-weight:500;">ARTICOLUL 4 Organizarea controalelor</p>

<p style="font-weight:500;"></p><h6>1. Părțile contractante vor urmări:</h6><p></p>

<p style="text-indent:2em;"> să fie înființate, când este nevoie sau posibil, posturi de control de conformitate cu standardele tehnice, în
 funcție de cerințele traficului;</p><br>

<p style="text-indent:2em;"> să faciliteze circulația mărfurilor, în special prin coordonarea programelor de lucru ale serviciilor responsabile
 cu controlul de conformitate cu standardele tehnice și ale celor vamale și prin acceptarea efectuării formalităților
 pentru mărfurile perisabile în afara orelor de program, în cazul în care sosirea acestora a fost anunțată în prealabil.</p><br>

<p style="text-indent:2em;">2. Controlul de conformitate cu standardele tehnice va putea fi efectuat și în puncte situate în interiorul țării,
 în măsura în care se poate demonstra că mărfurile, și în special produsele perisabile, nu se pot altera în timpul
 transportului, mijloacele de transport fiind adecvate.</p><br>

<p style="text-indent:2em;">3. În cadrul convențiilor în vigoare părțile contractante vor urmări să reducă, pe cât posibil, controalele fizice ale
 mărfurilor perisabile supuse controlului de conformitate cu standardele tehnice.</p><br>

<p style="text-indent:2em;">4. Părțile contractante vor organiza controale de conformitate cu standardele tehnice prin armonizarea, când este posibil,
 a procedurilor folosite de serviciul responsabil cu aceste controale și, dacă este cazul, de servicii cu competență în
 alte tipuri de controale și inspecții.</p><br>

<p style="text-indent:2em;">5. În cazul mărfurilor perisabile reținute în așteptarea rezultatelor controlului de conformitate cu standardele
 tehnice serviciile de control competente ale părților contractante vor urmări ca această depozitare de mărfuri
 sau staționare de mijloace de transport să fie efectuată în condiții care să permită conservarea bunurilor cu
 minimum de formalități vamale.</p><br>

<p style="font-weight:500;">ARTICOLUL 5 Mărfuri în tranzit</p><br>

<p>Controlul de conformitate cu standardele tehnice nu se aplică în mod normal mărfurilor aflate în tranzit direct.</p><br>

<p style="font-weight:500;">ARTICOLUL 6 Cooperare</p><br>

<p style="text-indent:2em;">1. Serviciile responsabile cu controlul de conformitate cu standardele tehnice vor coopera cu serviciile omoloage ale
 celorlalte părți contractante, în vederea accelerării trecerii mărfurilor perisabile supuse controlului de conformitate
 cu standardele tehnice, în special prin schimb de informații utile.</p><br>

<p style="text-indent:2em;">2. Când un transport de mărfuri perisabile este interceptat în cursul controlului de conformitate cu standardele tehnice,
 serviciul responsabil va urmări să informeze cât mai curând posibil serviciul omolog din țara de export, indicând motivele
 interceptării și măsurile luate în legătură cu mărfurile.</p><br>

<p style="font-weight:500;">ANEXA Nr. 6
CONTROLUL CALITĂȚII</p><br>

<p style="font-weight:500;">ARTICOLUL 1 Principii</p><br>

<p style="text-indent:2em;">Oriunde s-ar efectua, controlul calității pentru mărfurile specificate în prezenta convenție se realizează în conformitate
 cu principiile definite în aceasta, în special în anexa nr. 1.</p><br>

<p style="font-weight:500;">ARTICOLUL 2 Informații</p>

<p style="text-indent:2em;"></p><h6>Fiecare parte contractantă va urmări ca orice persoană interesată să poată obține rapid 
informații referitoare la:</h6><p></p>

<p>locurile în care mărfurile pot fi prezentate pentru inspecție;</p><br>

<p>prevederile legale și reglementările referitoare la controlul calității, precum și regulile de aplicare generală.</p><br>

<p style="font-weight:500;">ARTICOLUL 3 Organizarea controalelor</p>

<p style="text-indent:2em;"></p><h6>1. Părțile contractante vor urmări:</h6><p></p>

<p> să fie montate, când este nevoie sau posibil, posturi de control al calității, în
 conformitate cu cerințele traficului;</p><br>

<p style="text-indent:2em;"> să faciliteze circulația mărfurilor, în special prin coordonarea programelor de lucru ale serviciului responsabil cu 
calitatea și ale celor vamale și prin acceptarea efectuării formalităților pentru mărfurile perisabile în afara orelor de program,
 în cazul în care sosirea acestora a fost anunțată în prealabil.</p><br>

<p style="text-indent:2em;">2. Controlul calității va putea fi efectuat și în puncte situate în interiorul țării, cu condiția ca procedurile utilizate să
 contribuie la facilitarea circulației internaționale a mărfurilor.</p><br>

<p style="text-indent:2em;">3. În cadrul convențiilor în vigoare părțile contractante vor urmări să reducă, pe cât posibil, controalele fizice ale
 mărfurilor perisabile supuse controlului calității.</p><br>

<p style="text-indent:2em;">4. Părțile contractante vor organiza controale de calitate prin armonizarea, când este posibil, a procedurilor folosite de
 serviciul responsabil cu aceste controale și, dacă este cazul, de serviciile cu competență în alte tipuri de controale și
 inspecții.</p><br>

<p style="font-weight:500;">ARTICOLUL 4 Mărfuri în tranzit</p><br>

<p>Controalele de calitate nu se aplică în mod normal mărfurilor aflate în tranzit direct.</p><br>

<p style="font-weight:500;">ARTICOLUL 5 Cooperare</p><br>

<p style="text-indent:2em;">1. Serviciile de control al calității vor coopera cu serviciile omoloage ale celorlalte părți contractante în vederea
 accelerării trecerii mărfurilor perisabile supuse controlului de calitate, în special prin schimb de informații utile.</p><br>

<p style="text-indent:2em;">2. Când un transport de mărfuri perisabile este interceptat în cursul controlului de calitate, serviciul responsabil
 va urmări să informeze cât mai curând posibil serviciul omolog din țara de export, indicând motivele interceptării
 și măsurile luate în legătură cu mărfurile.</p><br>

<p style="font-weight:500;">ANEXA Nr. 7
REGULAMENTUL INTERN</p><br>

<p>al Comitetului de gestiune menționat la art. 22 din prezenta convenție</p><br>

<p style="font-weight:500;">ARTICOLUL 1 Membri</p><br>

<p>Membrii Comitetului de gestiune sunt părțile contractante ale prezentei convenții.</p><br>

<p style="font-weight:500;">ARTICOLUL 2 Observatori</p><br>

<p style="text-indent:2em;">1. Comitetul de gestiune poate decide să invite administrațiile competente ale statelor care nu sunt părți contractante
 sau reprezentanți ai unor organizații internaționale care nu sunt părți contractante, pentru probleme ce prezintă interes
 direct pentru aceștia, să asiste la sesiuni în calitate de observatori.</p><br>

<p style="text-indent:2em;">2. Cu toate acestea, sub rezerva respectării prevederilor art. 1, organizațiile internaționale specificate la paragraful
 1, care au competență în problemele prevăzute în anexele la prezenta convenție, participă de drept la lucrările Comitetului
de gestiune în calitate de observatori.</p><br>

<p style="font-weight:500;">ARTICOLUL 3 Secretariat</p><br>

<p>Secretariatul Comitetului de gestiune este asigurat de secretarul executiv al
 Comisiei Economice pentru Europa.</p><br>

<p style="font-weight:500;">ARTICOLUL 4 Convocări</p>

<p style="text-indent:2em;"></p><h6>Secretarul executiv al Comisiei Economice pentru Europa convoacă comitetul:</h6><p></p>

<p>(i) după 2 ani de la data intrării în vigoare a convenției;</p><br>

<p>(ii) în continuare, la o dată fixată de Comitetul de gestiune, dar cel puțin o dată la 5 ani;</p><br>

<p>(iii) la cererea administrațiilor competente din cel puțin 5 state care sunt părți contractante.</p><br>

<p style="font-weight:500;">ARTICOLUL 5 Birou</p><br>

<p>Comitetul alege un președinte și un vicepreședinte cu ocazia fiecărei sesiuni.</p><br>

<p style="font-weight:500;">ARTICOLUL 6 Cvorum</p><br>

<p style="text-indent:2em;">Pentru luarea deciziilor este necesară realizarea unui cvorum de cel puțin o treime din numărul statelor care sunt părți 
contractante.</p><br>

<p style="font-weight:500;">ARTICOLUL 7 Decizii</p><br>

<p>(i) Propunerile sunt supuse la vot.</p><br>

<p>(ii) Fiecare stat care este parte contractantă, reprezentat la sesiune, dispune de un vot.</p><br>

<p style="text-indent:2em;">(iii) În cazul aplicării art. 16 paragraful 2 din convenție, organizațiile regionale de integrare economică, părți la convenție,
 nu dispun la votare decât de un număr de voturi egal cu totalul voturilor alocate statelor lor membre, care sunt, de asemenea,
 părți la convenție. În acest ultim caz aceste state membre nu își mai exercită dreptul lor de vot.</p><br>

<p style="text-indent:2em;">(iv) Sub rezerva dispozițiilor alin. (v) de mai jos propunerile sunt adoptate cu majoritatea simplă a membrilor prezenți și 
votanți, în conformitate cu condițiile specificate la alin. (ii) și (iii) de mai sus.</p><br>

<p style="text-indent:2em;">(v) Amendamentele la prezenta convenție sunt adoptate cu majoritatea a două treimi din numărul membrilor prezenți și votanți, 
în conformitate cu condițiile specificate la alin. (ii) și (iii) de mai sus.</p><br>

<p style="font-weight:500;">ARTICOLUL 8 Raport</p><br>

<p>Înainte de încheierea fiecărei sesiuni Comitetul de gestiune își aprobă raportul.</p><br>

<p style="font-weight:500;">ARTICOLUL 9 Dispoziții complementare</p><br>

<p style="text-indent:2em;">În lipsa unor dispoziții exprese din prezenta anexă se aplică Regulamentul interior al Comisiei Economice pentru Europa,
 în afara cazului în care Comitetul de gestiune nu hotărăște altfel.</p><br>
 
 <p><a href="https://lege5.ro/Gratuit/g44donrs/conventia-internationala-privind-armonizarea-controalelor-marfurilor-la-frontiere-din-21101982">Sursa</a></p>

</div>
`