import { eu1 } from "./pages-ro/eu1"
import { eu2 } from "./pages-ro/eu2"
import { eu3 } from "./pages-ro/eu3"
import { eu4 } from "./pages-ro/eu4"
import { eu5 } from "./pages-ro/eu5"
import { eu6 } from "./pages-ro/eu6"
import { eu7 } from "./pages-ro/eu7"
import { eu8 } from "./pages-ro/eu8"
import { eu9 } from "./pages-ro/eu9"
import { eu10 } from "./pages-ro/eu10"

export const contentEUro = [
    { key: "eu1", html: eu1 },
    { key: "eu2", html: eu2 },
    { key: "eu3", html: eu3 },
    { key: "eu4", html: eu4 },
    { key: "eu5", html: eu5 },
    { key: "eu6", html: eu6 },
    { key: "eu7", html: eu7 },
    { key: "eu8", html: eu8 },
    { key: "eu9", html: eu9 },
    { key: "eu10", html: eu10 },
]