import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reduxForm, formValueSelector } from 'redux-form'

import withTranslation from '../../../globals/components/withTranslation'

import Component from './component'

import { cargoSearch, transportSearch, setGlobalAutoUpdate } from '../../../store/actions'
import parseUrlParamsFromString from '../../shared/parseUrlParamsFromString'
import { CargoSearchFormModel } from '../../../store/models'
import { SearchFormBaseProps, SearchFormProps } from './types'
import { IReduxStorage } from '../../../store/reducers/types'

const formName = 'search'
const formSelector = formValueSelector(formName)

export default compose(
  withTranslation,
  connect(
    (state: IReduxStorage, props: SearchFormProps) => {
      const filterKey = formSelector(state, 'filterKey')
      const formData = formSelector(state, 'initialValues')
      const urlParams = parseUrlParamsFromString(window.location.search)
      const { from, to, block, filterKey: todayUrlParameter, sameCountryOnly } = urlParams
      const countriesFrom = from ? [parseInt(from)] : []
      const countriesTo = to ? [parseInt(to)] : []
      const search =
        props.searchType === 'transport' ? state.transport.search : state.cargo.get('search')
      const sortBy = search.get('sortBy')
      const pageSize = search.get('pageSize')
      const dateScope = search.get('dateScope')
      
      return {
        filterKey,
        langId: state.ui.langId,
        sortBy,
        pageSize,
        dateScope,
        todayUrlParameter,
        isAuthenticated: !!state.auth.accessToken,
        sameCountryOnly,
        formData,
        initialValues: {
          ...CargoSearchFormModel,
          countriesFrom,
          countriesTo,
          includingBlocked: !!block,
          sameCountryOnly
        },
      }
    },
    (dispatch: any, props: SearchFormBaseProps) => ({
      search: data =>
        dispatch(props.searchType === 'transport' ? transportSearch(data) : cargoSearch(data)),
      setGlobalAutoUpdate,
    })
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(Component) as React.ComponentType<SearchFormBaseProps>
