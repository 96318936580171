import React, { Component } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import { Field } from 'redux-form'
import uuid from 'uuid/v4'
import { subDays } from 'date-fns'
import moment from 'moment'

import Dropdown from '../../../UI/dropdown'
import dict from '../../../shared/dictionaries'
import { setFormDatePart, setFormDate, stopBubble } from '../../../shared/utils/form-utils'

import './styles.scss'

import 'moment/locale/ro'
import 'moment/locale/ru'
import 'moment/locale/en-gb'

const STYLE_BASE = 'date-selector-calendar_'

const currentYear = new Date().getFullYear()
const years = [
  { name: currentYear, value: currentYear },
  { name: currentYear + 1, value: currentYear + 1 },
]

export default class extends Component {
  guid = `calendar${uuid()}`
  constructor(props) {
    super(props)
    const date = new Date()
    const { shipOnDate, langId, disableOutdated } = props
    this.currentYear = date.getFullYear()
    this.currentMonth = date.getMonth() + 1
    this.currentDay = date.getDate()

    this.state = {
      monthDays: dict.monthDays(shipOnDate, disableOutdated ? this.currentDay : null),
      months: dict.monthsLimit(langId, disableOutdated ? this.currentMonth : null),
    }
  }

  componentDidMount() {
    const { currentLanguage } = this.props
    const language = currentLanguage === 'en' ? 'en-gb' : currentLanguage
    moment.locale(language)
  }

  componentDidUpdate(prevProps) {
    const { shipOnDate } = this.props

    if (shipOnDate !== prevProps.shipOnDate) {
      const dateIsBeforeToday = moment(shipOnDate).isBefore(moment().startOf('day'))
      if (dateIsBeforeToday) {
        this.onShipDateChange(new Date())
      }

      this.setMonths()
      setTimeout(() => {
        this.setMonthDays()
      }, 300)
    }
  }

  setMonths = () => {
    const { langId, year, disableOutdated } = this.props
    const disabling = disableOutdated && year === this.currentYear
    this.setState({
      months: dict.monthsLimit(langId, disabling ? this.currentMonth : null),
    })
  }

  setMonthDays() {
    const { shipOnDate, year, month, disableOutdated } = this.props
    const disabling = disableOutdated && year === this.currentYear && month === this.currentMonth
    this.setState({
      monthDays: dict.monthDays(shipOnDate, disabling ? this.currentDay : null),
    })
  }

  setShipDate = ({ month, year, day }) => {
    const { basePropName, change, shipOnDate } = this.props
    setFormDatePart(basePropName, shipOnDate, change, { month, year, day })
  }
  onShipDateChange = newDate => {
    const { basePropName, change, setActivePopup } = this.props
    setFormDate(basePropName, change, new Date(newDate))
    setActivePopup(null)
  }

  toggleShipDateCalendar = () => {
    const { disabled, activeGuid, setActivePopup } = this.props
    if (disabled) return
    const guid = activeGuid === this.guid ? undefined : this.guid
    setActivePopup(guid)
  }

  render() {
    const { t, activeGuid, shipOnDate, className, basePropName } = this.props
    const { monthDays, months } = this.state
    const showShipDateCalendar = activeGuid === this.guid
    return (
      <div
        className={classNames({
          [`${STYLE_BASE}wrapper`]: true,
          [className]: !!className,
        })}
      >
        <label className="required-field" htmlFor="day">
          {t('transportation.dates')}
        </label>
        <div className="select-container-float">
          <div className="select-sm">
            <Field
              name={`${basePropName}.day`}
              component={Dropdown}
              items={monthDays}
              style={{ width: '50px' }}
              onChange={(e, v) => {
                this.setShipDate({ day: v })
              }}
            />
          </div>
          <div className="select-lg">
            <Field
              name={`${basePropName}.month`}
              component={Dropdown}
              //items={dict.getMonths(langId)}
              items={months}
              style={{ width: '150px', marginLeft: '7px' }}
              onChange={(e, v) => {
                this.setShipDate({ month: v })
              }}
            />
          </div>
          <div className="select-md">
            <Field
              name={`${basePropName}.year`}
              component={Dropdown}
              items={years}
              style={{ width: '60px', marginLeft: '7px' }}
              onChange={(e, v) => {
                this.setShipDate({ year: v })
              }}
            />
          </div>
          <div className="input-custom-add select-add">
            <div className="select-sm">
              <Field
                name={`${basePropName}.range`}
                component={Dropdown}
                items={dict.rangeDays}
                style={{ width: '60px' }}
              />
            </div>
            <span className="sm">{t('days.lowerCase')}</span>
          </div>
        </div>
        <div
          className={classNames({
            'calendar-link input-date-hidden input-daterange': true,
            [`${STYLE_BASE}calendar-selector`]: true,
          })}
          onClick={stopBubble}
        >
          <Link to="#" onClick={this.toggleShipDateCalendar}>
            {t('choose.on.calendar')}
            <i className="ico ico-calendar" />
          </Link>
          {showShipDateCalendar && (
            <div className={`${STYLE_BASE}calendar-wrapper`}>
              <DatePicker
                inline
                selected={moment(shipOnDate)}
                onChange={this.onShipDateChange}
                minDate={subDays(new Date(), 0)}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
