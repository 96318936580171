import React from 'react'
import { Route, Switch } from 'react-router-dom'

import EditCompany from './edit'
import EditBranch from './edit-branch'
import CompanyConfirmation from './confirmation'
import List from './list/index.ts'
import UserAccess from '../user-access'
import Claims from './claims'
import EmployeeList from './employee-list'
import Item from './item'
import EmployeeEdit from './employee-edit'
import ProtectedRoute from '../../protectedRoute'
import CompanyVerification from './company-verification'
import JoiningTheCompany from './joining-the-company'
import Autopark from './autopark'

export default () => (
  <Switch>
    <ProtectedRoute
      path="/company/add"
      component={EditCompany}
      permissionScope="company"
      actionType="add"
    />
    <Route path="/company/joining-the-company" exact component={JoiningTheCompany} />
    <Route path="/company/company-verification" exact component={CompanyVerification} />
    <Route path="/company/autopark" exact component={Autopark} />
    <Route path="/company/add-branch" component={EditBranch} />
    <Route path="/company/confirmation" component={CompanyConfirmation} />
    <Route path="/company/item/:itemId/:openedTab?" component={Item} />
    <Route path="/company/edit/:itemId" component={EditCompany} />
    <Route path="/company/access/user/:userId" component={UserAccess} />
    <Route path="/company/rating/:filterKey" component={Claims} />
    <Route path="/company/employee-list" component={EmployeeList} />
    <Route path="/company/employee-edit/:userId" component={EmployeeEdit} />
    <Route path="/company/:filterKey?/:searchText?" component={List} />

  </Switch>
)
