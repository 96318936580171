import * as actionTypes from './action-types'
import fetch from '../../components/shared/fetch'
import { oops, getUrlParamsString, getBaseProps } from '../utils'
import { ReduxGetState } from '../reducers/types'

// ------------------------------------------
//
// ------------------------------------------
export const getItem = request => {
  const { itemId, searchType, isEdit } = request
  return (dispatch, getState) => {
    const state = getState()
    const current = searchType === 'cargo' ? state.cargo.get('item') : state.transport.item

    if (itemId === current.itemId && current.loading) return

    !isEdit &&
      dispatch({
        type: actionTypes[`${searchType.toUpperCase()}_ITEM_LOADING`],
        payload: { requestedAt: new Date(), itemId },
      })

    const lang = state.ui.currentLanguage

    let url = `/${searchType}/${itemId}/${lang}`

    if (isEdit) url += `/${isEdit}`

    return fetch(url)
      .then(json => {
        if (json.d.middlePoints) {
          json.d.middlePoints = JSON.parse(json.d.middlePoints)
        }
        dispatch({
          type: actionTypes[`${searchType.toUpperCase()}_ITEM_LOADED`],
          data: json.d,
          isEdit,
        })
        return json.d
      })
      .catch(error =>
        dispatch(oops(error, actionTypes[`${searchType.toUpperCase()}_ITEM_LOAD_ERROR`]))
      )
  }
}


export const setglobalActiveTab = (activeTab) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes['SET_GLOBAL_ACTIVE_TAB'],
      payload: activeTab,
    })
  }
}

export const setCompanyVerivicationData = (companyVerivicationData) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes['SET_COMPANY_VERIFICATION_DATA'],
      payload: companyVerivicationData,
    })
  }
}

export const getUnblockedItem = (itemId: number, searchType: SearchType) => {
  return (dispatch, getState: ReduxGetState) => {
    const state = getState()
    const paramsString = getUrlParamsString({
      ...getBaseProps(state),
      itemId,
      langId: state.ui.langId,
      userId: state.auth.userId || 0,
    })

    dispatch({
      type: actionTypes[`${searchType.toUpperCase()}_GET_UNBLOCKED_START`],
    })

    return fetch(`/${searchType}/getUnblockedItem?${paramsString}`)
      .then(response => {
        dispatch({
          type: actionTypes[`${searchType.toUpperCase()}_GET_UNBLOCKED_SUCCESS`],
          payload: response.data,
        })
      })
      .catch(err =>
        dispatch(oops(err, actionTypes[`${searchType.toUpperCase()}_GET_UNBLOCKED_FAILURE`]))
      )
  }
}

export const saveAsTemplate = ({ name, item }, searchType: SearchType, cb?) => {
  return (dispatch, getState: ReduxGetState) => {
    const state = getState()

    dispatch({ type: actionTypes[`${searchType.toUpperCase()}_TEMPLATE_SAVING`] })

    fetch(`/${searchType}/saveAsTemplate`, {
      method: 'POST',
      body: JSON.stringify({ name, item, langId: state.ui.langId }),
    })
      .then(json => {
        dispatch({
          type: actionTypes[`${searchType.toUpperCase()}_TEMPLATE_SAVED`],
          payload: json.d,
        })
        cb && cb()
      })
      .catch(error =>
        dispatch(oops(error, actionTypes[`${searchType.toUpperCase()}_TEMPLATE_SAVE_ERROR`]))
      )
  }
}

export const setGlobalAutoUpdate = value => {
  return {
    type: actionTypes.SET_GLOBAL_AUTOUPDATE,
    payload: value,
  }
}

export const getTemplates = (searchType: SearchType, cb?) => {
  return dispatch => {
    dispatch({ type: actionTypes[`${searchType.toUpperCase()}_TEMPLATES_LOADING`] })
    return fetch(`/${searchType}/getTemplates`)
      .then(json => {
        dispatch({
          type: actionTypes[`${searchType.toUpperCase()}_TEMPLATES_LOADED`],
          payload: json.d,
        })
        cb && cb()
      })
      .catch(error =>
        dispatch(oops(error, actionTypes[`${searchType.toUpperCase()}_TEMPLATES_LOAD_ERROR`]))
      )
  }
}

export const deleteTemplate = (templateId: number, searchType: SearchType) => {
  return dispatch => {
    dispatch({ type: actionTypes[`${searchType.toUpperCase()}_DELETE_TEMPLATE_START`] })

    return fetch(`/${searchType}/deleteTemplate/${templateId}`, {
      method: 'DELETE',
    })
      .then(response => {
        dispatch({
          type: actionTypes[`${searchType.toUpperCase()}_DELETE_TEMPLATE_SUCCESS`],
          payload: response.data,
        })
      })
      .catch(error =>
        dispatch(oops(error, actionTypes[`${searchType.toUpperCase()}_DELETE_TEMPLATE_ERROR`]))
      )
  }
}

export const setCompanyJoinRequests = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes['SET_COMPANY_JOIN_REQUESTS'],
      payload: data,
    })
  }
}

export * from './access'
export * from './auth'
export * from './user'

export * from './cargo'
export * from './forum'
export * from './transport'

export * from './modal-form'

export * from './ui'

export * from './socket'
