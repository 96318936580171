export const int17 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>ORDONANŢĂ DE URGENŢĂ
privind răspunderea de mediu cu referire la prevenirea şi repararea prejudiciului asupra mediului</h4><p></p>
 
<p style="text-indent:2em;"></p><h6>Având în vedere obligativitatea transpunerii legislaţiei comunitare, în Uniunea Europeană:</h6><p></p>

<p style="text-indent:2em;">având în vedere că obiectivul adoptării şi intrării în vigoare a Directivei nr.2004/35/CE privind răspunderea de mediu referitoare la prevenirea şi
 repararea prejudiciului adus mediului constă în stabilirea unui cadru legislativ unitar în acest domeniu, ce impune transpunerea integrală şi
 implementarea corectă a acestei directive,</p><br>

<p style="text-indent:2em;">ţinând cont de faptul că este necesară completarea cadrului legal existent cu un act normativ care să asigure o reglementare unitară şi
 distinctă a prejudiciului de mediu,</p><br>

<p style="text-indent:2em;">ţinând cont de faptul că menţinerea vidului legislativ în domeniul răspunderii de mediu poate avea consecinţe grave în domeniu prin
 faptul că nu există un cadru legal prin care operatorii sa fie obligaţi să adopte măsuri şi să pună în aplicare practici pentru a
 minimiza riscurile de daune sau să ia măsurile de reparare necesare în cazul producerii prejudiciului,</p><br>

<p style="text-indent:2em;">ţinând cont de faptul că transpunerea corectă a acestei directive impune adoptarea de acte normative subsecvente prin care să fie
 definite formele de garanţie financiară, inclusiv pentru cazurile de insolvenţă, şi măsurile pentru dezvoltarea ofertei de 
 instrumente financiare privind răspunderea în domeniul mediului, care să permită operatorilor utilizarea acestora în scopul
 garantării obligaţiilor ce le revin conform prezentei ordonanţe de urgenţă,</p><br>

<p>având în vedere că termenul limită pentru transpunerea susnumitei directive a fost 30 aprilie 2007,</p><br>

<p style="text-indent:2em;">ţinând cont de faptul că nerespectarea acestui termen a condus la notificarea privind netranspunerea în termen a directivei,
 declanşându-se procedura de infrigement împotriva României, potrivit art.226 din Tratatul Comunităţii Europene,</p><br>

<p style="text-indent:2em;">elemente care constituie o situaţie de urgenţă şi extraordinară,
în temeiul art.115 alin.(4) din Constituţia României, republicată,</p><br>

<p>Guvernul României adoptă prezenta ordonanţa de urgenţă.</p><br>

<p style="font-weight:500;">CAPITOLUL I
Dispoziţii generale</p><br>

<p style="font-weight:500;">SECŢIUNEA 1
Semnificaţia unor termeni</p><br>

<p style="text-indent:2em;">Art. 1. – Prezenta ordonanţă de urgenţă stabileşte cadrul de reglementare al răspunderii de mediu, bazată pe principiul 
„poluatorul plăteşte“, în scopul prevenirii şi reparării prejudiciului asupra mediului.</p>

<p style="text-indent:2em;"></p><h6>Art. 2. – În înţelesul prezentei legi, termenii şi expresiile de mai jos au următoarele semnificaţii :</h6><p></p>

<p style="text-indent:2em;">1. activitate profesională – orice activitate desfăşurată în cadrul unei activităţi economice, afaceri sau unei întreprinderi,
 indiferent de caracterul său privat sau public, profit sau nonprofit;</p><br>

<p style="text-indent:2em;">2. ameninţare iminentă cu un prejudiciu – o probabilitate suficientă de producere a unui prejudiciu asupra mediului în
 viitorul apropriat;</p><br>

<p style="text-indent:2em;">3. ape – apele de suprafaţă şi subterane, astfel cum au fost definite în Legea apelor nr.107/1996, cu modificările şi
 completările ulterioare;</p><br>

<p style="text-indent:2em;">4. costuri – costurile care sunt justificate de necesitatea de a asigura o implementare corectă şi eficientă a prezentei
 ordonanţe de urgenţă, inclusiv costurile evaluării prejudiciului asupra mediului, ale ameninţării iminente cu un astfel
 de prejudiciu, ale opţiunilor de acţiune, precum şi costurile administrative, judiciare, de punere în aplicare a prezentei
 ordonanţe de urgenţă, costurile colectării datelor şi alte costuri generale, costurile de monitorizare şi supraveghere a
 prezentei ordonanţe de urgenţă;</p><br>

<p style="text-indent:2em;">5. drept vătămat – orice drept fundamental prevăzut de Constituţie sau de lege, căruia i se aduce o atingere printr-un act
 administrativ;</p><br>

<p style="text-indent:2em;">6. emisie – evacuarea în mediu, ca rezultat al activităţilor umane, a unor substanţe, preparate, organisme sau microorganisme;</p><br>

<p style="text-indent:2em;">7. interes legitim privat – posibilitatea de a pretinde o anumită conduită în considerarea realizării unui drept subiectiv
 viitor şi previzibil, prefigurat;</p><br>

<p style="text-indent:2em;">8. interes legitim public – posibilitatea de a pretinde o anumită conduită în considerarea realizării unui drept 
fundamental care se exercită în colectiv ori, după caz, în considerarea apărării unui interes public;</p><br>

<p style="text-indent:2em;">9. măsuri preventive – orice măsuri luate ca răspuns la un eveniment, o acţiune sau o omisiune care a creat o 
ameninţare iminentă cu un prejudiciu asupra mediului, în scopul prevenirii sau diminuării prejudiciului;</p><br>

<p style="text-indent:2em;">10. măsuri reparatorii – orice acţiune sau un ansamblu de acţiuni, inclusiv măsuri de reducere a prejudiciului 
sau măsuri interimare menite să refacă, să reabiliteze sau să înlocuiască resursele naturale prejudiciate şi/sau 
serviciile deteriorate sau să furnizeze o alternativă echivalentă pentru aceste resurse sau servicii, în 
conformitate cu anexa nr.2;</p><br>

<p style="text-indent:2em;">11. operator – orice persoană fizică sau juridică de drept public sau privat care desfăşoară sau deţine controlul
 unei activităţi profesionale sau, în cazul în care legislaţia naţională prevede acest lucru, care a fost investită
 cu putere economică decisivă asupra funcţionării tehnice a unei astfel de activităţi, inclusiv deţinătorul unui act
 de reglementare pentru o astfel de activitate ori persoana care înregistrează sau notifică o astfel de activitate;</p><br>

<p style="text-indent:2em;">12. prejudiciu – o schimbare negativă măsurabilă a unei resurse naturale sau o deteriorare măsurabilă a unui serviciu
 legat de resursele naturale, care poate surveni direct sau indirect;</p>

<p style="text-indent:2em;"></p><h6>13. prejudiciul asupra mediului, inclusiv cel determinat de elementele aeropurtate, înseamnă:</h6><p></p>

<p style="text-indent:2em;">a) prejudiciul asupra speciilor şi habitatelor naturale protejate – orice prejudiciu care are efecte semnificative
 negative asupra atingerii sau menţinerii unei stări favorabile de conservare a unor astfel de habitate sau specii;
 caracterul semnificativ al acestor efecte se evaluează în raport cu starea iniţială, ţinând cont de criteriile prevăzute
 în anexa nr.1; prejudiciile aduse speciilor şi habitatelor naturale protejate nu includ efectele negative identificate anterior,
 care rezultă din acţiunile unui operator care a fost autorizat în mod expres de autorităţile competente în concordanţă cu prevederile
 legale în vigoare;</p><br>

<p style="text-indent:2em;">b) prejudiciul asupra apelor – orice prejudiciu care are efecte adverse semnificative asupra stării ecologice chimice şi/sau
 cantitative şi/sau potenţialului ecologic al apelor în cauză, astfel cum au fost definite în Legea nr.107/1996, cu modificările
 şi completările ulterioare, cu excepţia efectelor negative pentru care se aplică art. 27 din Legea nr.107/1996, cu modificările
 şi completările ulterioare;</p><br>

<p style="text-indent:2em;">c) prejudiciul asupra solului – orice contaminare a solului, care reprezintă un risc semnificativ pentru sănătatea umană, care este
 afectată negativ ca rezultat al introducerii directe sau indirecte a unor substanţe, preparate, organisme sau microorganisme în
 sol sau în subsol;</p><br>

<p style="text-indent:2em;">14. regenerare, inclusiv regenerarea naturală – în cazul apelor, speciilor şi habitatelor naturale protejate, înseamnă readucerea
 la starea iniţiala a resurselor naturale prejudiciate şi/sau a serviciilor deteriorate, iar în cazul prejudiciului asupra solului,
 înseamnă eliminarea oricărui risc semnificativ cu efect negativ asupra sănătăţii umane;</p><br>

<p>15. resurse naturale – speciile si habitatele naturale protejate, apele si solul;</p><br>

<p style="text-indent:2em;">16. servicii şi serviciile resurselor naturale – funcţiile asigurate de o resursă naturală în beneficiul altei resurse naturale
 sau al publicului;</p>

<p style="text-indent:2em;"></p><h6>17. specii şi habitate naturale protejate:</h6><p></p>

<p style="text-indent:2em;">a) speciile incluse în anexele nr.3, 4A şi 4B la Ordonanţa de urgenţă a Guvernului nr.236/2000 privind regimul ariilor naturale
 protejate, conservarea habitatelor naturale, a florei şi faunei sălbatice, aprobată cu modificări şi completări prin Legea nr
 .462/2001, cu modificările şi completările ulterioare;</p><br>

<p style="text-indent:2em;">b) habitatele speciilor de păsări migratoare sau a speciilor incluse în anexa nr.3, precum şi habitatele naturale enumerate în
 anexa nr.2 şi locurile de reproducere sau de odihnă ale speciilor prevăzute în anexele nr.4A şi 4B la Ordonanţa de urgenţă nr
 .236/2000, aprobată cu modificări şi completări prin Legea nr.462/2001, cu modificările şi completările ulterioare;</p><br>

<p style="text-indent:2em;">18. stare de conservare a unui habitat natural – totalitatea factorilor ce acţionează asupra unui habitat natural, precum şi
 asupra speciilor caracteristice acestuia şi care pot afecta, pe termen lung, distribuţia, structura şi funcţiile sale, precum
 şi supravieţuirea speciilor ce îi sunt caracteristice pe teritoriul naţional sau în arealul natural al acestui habitat, 
 după caz;</p>

<p style="text-indent:2em;"></p><h6>19. starea de conservare a unui habitat natural se consideră „favorabilă“ în cazul în care:</h6><p></p>

<p>a) arealul său natural şi suprafeţele pe care le acoperă în interiorul acestui areal sunt stabile sau în creştere;</p><br>

<p style="text-indent:2em;">b) structura şi funcţiile specifice necesare menţinerii sale pe termen lung există şi este posibilă existenţa acestora în 
viitorul previzibil;</p><br>

<p>c) speciile sale caracteristice se află într-o stare de conservare favorabilă, în conformitate cu definiţia de la pct. 21;</p><br>

<p style="text-indent:2em;">20. stare de conservare a unei specii – totalitatea factorilor care acţionează asupra speciei respective şi care pot afecta pe 
termen lung distribuţia şi abundenţa populaţiilor sale pe teritoriul naţional sau în arealul natural al speciei respective, 
după caz;</p>

<p style="text-indent:2em;"></p><h6>21. starea de conservare a unei specii se consideră „favorabilă“ în cazul în care:</h6><p></p>

<p style="text-indent:2em;">a) datele privind dinamica populaţiilor speciei respective indică faptul că aceasta se menţine şi are şanse să se menţină pe 
termen lung ca o componentă viabilă a habitatelor sale naturale;</p><br>

<p>b) arealul natural al speciei nu se reduce şi nu există riscul să se reducă în viitorul previzibil;</p><br>

<p style="text-indent:2em;">c) există, şi probabil va continua să existe, un habitat suficient de mare pentru ca populaţiile speciei să se menţină pe 
termen lung.</p><br>

<p style="font-weight:500;">SECŢIUNEA a 2-a
Domeniul de reglementare</p>

<p style="text-indent:2em;"></p><h6>Art. 3. – (1) Prezenta ordonanţă de urgenţă se aplică:</h6><p></p>

<p style="text-indent:2em;">a) prejudiciului asupra mediului, cauzat de orice tip de activitate profesională prevăzută în anexa nr.3, şi oricărei ameninţări 
iminente cu un astfel de prejudiciu determinate de oricare dintre aceste activităţi;</p><br>

<p style="text-indent:2em;">b) prejudiciului asupra speciilor şi habitatelor naturale protejate şi oricărei ameninţări iminente cu un astfel de prejudiciu 
cauzat de orice activitate profesională, alta decât cele prevăzute în anexa nr.3, ori de câte ori operatorul acţionează cu intenţie
 sau din culpă.</p><br>

<p style="text-indent:2em;">(2) Prezenta ordonanţă de urgenţă se aplică prejudiciului asupra mediului sau unei ameninţări iminente cu un astfel de prejudiciu, 
cauzate de poluarea cu caracter difuz, numai când se poate stabili o legătura de cauzalitate între prejudiciu şi activităţile 
operatorilor individuali.</p>

<p style="text-indent:2em;"></p><h6>(3) Prezenta ordonanţă de urgenţă se aplică fără
 a aduce atingere legislaţiei comunitare şi celei naţionale care:</h6><p></p>

<p>a) reglementează mai sever desfăşurarea oricăror activităţi ce intră sub incidenţa prezentei ordonanţe;</p><br>

<p>b) prevede reguli în cazul conflictelor de jurisdicţie.</p><br> 

<p style="text-indent:2em;">(4) Prezenta ordonanţă de urgenţă nu dă persoanelor fizice sau juridice de drept privat dreptul la compensaţie ca o consecinţă a 
prejudiciului asupra mediului sau a ameninţării iminente cu un astfel de prejudiciu. În aceste situaţii se aplică prevederile 
dreptului comun.</p>

<p style="text-indent:2em;"></p><h6>Art. 4. – (1) Prezenta ordonanţă de urgenţă nu se aplică prejudiciului asupra mediului sau ameninţării iminente cu un astfel 
de prejudiciu produs de:</h6><p></p>

<p>a) acţiuni cu caracter de conflict armat, ostilităţi, război civil sau insurecţie;</p><br>

<p>b) un fenomen natural având caracter excepţional, inevitabil şi insurmontabil.</p>

<p style="text-indent:2em;"></p><h6>(2) Prezenta ordonanţă de urgenţă nu se aplică:</h6><p></p>

<p style="text-indent:2em;">a) prejudiciului asupra mediului sau oricărei ameninţări iminente cu un astfel de prejudiciu care decurge dintr-un incident
 pentru care răspunderea sau compensarea este reglementată de una dintre convenţiile internaţionale prevăzute în anexa nr.4
 , inclusiv de orice amendamente ulterioare ale acestora, la care România este parte;</p><br>

<p style="text-indent:2em;">b) riscurilor nucleare sau prejudiciului asupra mediului ori ameninţării iminente cu un astfel de prejudiciu, care pot fi
 cauzate de activităţile care intră sub incidenţa Tratatului privind Comunitatea Europeana a Energiei Atomice sau sunt cauzate
 de un incident sau activitate pentru care răspunderea ori compensarea sunt reglementate de oricare din instrumentele 
 internaţionale prevăzute în anexa nr.5, inclusiv de orice amendamente ulterioare ale acestora, la care România este parte;</p><br>

<p style="text-indent:2em;">c) activităţilor al căror scop principal îl reprezintă apărarea naţională sau securitatea internaţională ori celor al căror
 unic scop îl reprezintă apărarea împotriva dezastrelor naturale;</p><br>

<p style="text-indent:2em;">d) utilizării în scopuri agricole a nămolului provenit din staţiile de epurare a apelor uzate urbane, tratat conform unui 
standard aprobat;</p><br>

<p>e) prejudiciului cauzat de o emisie, eveniment sau incident care a avut loc înainte de 30 aprilie 2007;</p><br>

<p style="text-indent:2em;">f) prejudiciului cauzat de o emisie, eveniment sau incident care are loc pe data sau după data de 30 aprilie 2007 şi a 
fost determinat de o activitate specifică ce a avut loc şi s-a terminat înainte de data de 30 aprilie 2007;</p><br>

<p style="text-indent:2em;">g) prejudiciului produs în urma unei emisii, a unui eveniment sau a unui incident, de la producerea căruia au trecut 
mai mult de 30 de ani.</p><br>

<p style="text-indent:2em;">(3) Prin derogare de la prevederile alin.(2) lit. a), regimul juridic stabilit de prezenta ordonanţă de urgenţă se aplică 
situaţiilor care intră sub incidenţa convenţiilor prevăzute în anexa nr.4 până în momentul la care România devine parte 
la acestea.</p><br> 

<p style="text-indent:2em;">Art. 5. – (1) Prezenta ordonanţă de urgenţă nu aduce atingere dreptului operatorului de a-şi limita răspunderea, conform 
legislaţiei care implementează Convenţia internaţională privind limitarea răspunderii pentru creanţe maritime (LLMC) din 1976,
 la care România a aderat prin Legea nr. 284/2006, inclusiv orice amendament ulterior la convenţie, sau Convenţia de la Strasbourg
 privind limitarea responsabilităţii în navigaţia pe căile navigabile interioare (CLNI) din 1988, inclusiv orice amendament ulterior
 la convenţie.</p><br> 

<p style="text-indent:2em;">(2) Prin derogare de la prevederile alin.(1), regimul juridic stabilit de prezenta ordonanţă de urgenţă se aplică situaţiilor 
care intră sub incidenţa Convenţiei de la Strasbourg privind limitarea responsabilităţii în navigaţia pe căile navigabile interioare
 (CLNI) din 1988, inclusiv orice amendament ulterior, până la data la care România devine parte la acesta.</p><br> 

<p style="font-weight:500;">SECŢIUNEA a 3-a
Competenţe</p><br>

<p style="text-indent:2em;">Art. 6. – (1) Agenţia judeţeană pentru protecţia mediului este autoritatea competentă pentru stabilirea şi luarea măsurilor 
preventive şi reparatorii, precum şi pentru evaluarea caracterului semnificativ al prejudiciului asupra mediului.</p>

<p style="text-indent:2em;"></p><h6>(2) La stabilirea măsurilor preventive agenţia judeţeană pentru protecţia mediului se consultă cu comisariatele judeţene ale 
Gărzii Naţionale de Mediu. În funcţie de factorul de mediu potenţial afectat, agenţia judeţeană pentru protecţia mediului se 
poate consulta şi cu următoarele autorităţi şi/sau instituţii:</h6><p></p>

<p>a) direcţiile bazinale de apă;</p><br>

<p>b) consiliile ştiinţifice organizate la nivelul ariilor naturale protejate;</p><br>

<p>c) oficiile de studii pedologice şi agrochimice judeţene;</p><br>

<p>d) inspectoratele teritoriale de regim silvic şi de vânătoare.</p><br> 

<p style="text-indent:2em;">(3) La evaluarea caracterului semnificativ al prejudiciului asupra mediului şi la stabilirea măsurilor reparatorii agenţia 
judeţeană pentru protecţia mediului consultă pe lângă autorităţile prevăzute la alin.(2), după caz, şi Agenţia Naţională 
pentru Protecţia Mediului.</p> 

<p style="text-indent:2em;"></p><h6>(4) Reprezentanţii autorităţilor şi/sau instituţiilor consultate au următoarele
 obligaţii:</h6><p></p>

<p style="text-indent:2em;">a) de a analiza toate informaţiile şi/sau documentele transmise acestora de către 
agenţia judeţeană pentru protecţia mediului;</p><br>

<p style="text-indent:2em;">b) de a transmite opiniile lor agenţiei judeţene pentru protecţia mediului, în termen de 24 de ore, în cazul măsurilor 
preventive, şi de 5 zile, în cazul măsurilor reparatorii, de la primirea informaţiilor şi/sau a documentelor prevăzute 
la lit.a).</p> 

<p style="text-indent:2em;"></p><h6>Art. 7 – (1) În îndeplinirea atribuţiilor prevăzute la art. 6 alin.(1), agenţia judeţeană pentru protecţia mediului 
poate:</h6><p></p>

<p style="text-indent:2em;">a) sa îndeplinească măsurile preventive sau reparatorii stabilite, cu respectarea prevederilor art. 11 lit. d), art. 12 alin.
 (1) şi art. 15 lit. e), respectiv ale art. 16 alin.(1), direct sau prin încheierea de contracte cu persoane fizice sau juridice,
 în conformitate cu prevederile Ordonanţei de urgenţă a Guvernului nr.34/2006 privind atribuirea contractelor de achiziţie publică,
 a contractelor de concesiune de lucrări publice şi a contractelor de concesiune de servicii, aprobată cu modificări şi completări
 prin Legea nr.337/2006, cu modificările şi completările ulterioare;</p><br>

<p>b) să dispună luarea măsurilor preventive sau reparatorii necesare pe proprietatea unei terţe părţi;</p><br>

<p style="text-indent:2em;">c) să solicite operatorului în cauză realizarea unei evaluări proprii şi furnizarea oricăror informaţii şi date necesare în 
situaţia producerii unui prejudiciu;</p><br>

<p style="text-indent:2em;">(2) Operatorii au obligaţia de a transmite agenţiei judeţene pentru protecţia mediului rezultatele evaluărilor, precum şi datele 
şi informaţiile solicitate de aceasta, în termen de 3 zile de la obţinerea lor.</p><br> 

<p style="text-indent:2em;">(3) Terţii au obligaţia de a permite realizarea de măsuri preventive sau reparatorii pe proprietăţile lor. Aceste măsuri nu trebuie 
să conducă, pe cât posibil, la scăderea valorii proprietăţii.</p><br> 

<p style="text-indent:2em;">Art.8. – (1) Deciziile conducătorului agenţiei judeţene pentru protecţia mediului privind stabilirea măsurilor preventive sau 
reparatorii se motivează în fapt şi în drept şi conţin informaţii asupra termenelor şi procedurii contencios administrative prevăzute
 de Legea contenciosului administrativ nr.554/2004, cu modificările ulterioare.</p><br> 

<p style="text-indent:2em;">(2) Deciziile prevăzute la alin.(1) se comunică operatorului în cauză în termen de 24 de ore de la luarea lor, atât pentru 
măsurile preventive, cât şi pentru măsurile reparatorii.</p><br> 

<p style="text-indent:2em;">Art. 9. – Autoritatea competentă pentru constatarea prejudiciului asupra mediului, a unei ameninţări iminente cu un astfel de 
prejudiciu, precum şi pentru identificarea operatorului responsabil este Garda Naţională de Mediu, prin comisariatele 
judeţene.</p><br> 

<p style="font-weight:500;">CAPITOLUL II
Măsurile preventive şi reparatorii</p><br>

<p style="font-weight:500;">SECŢIUNEA 1
Acţiuni preventive</p><br>

<p style="text-indent:2em;">Art. 10. – (1) În cazul unei ameninţări iminente cu un prejudiciu asupra mediului, operatorul este obligat să ia imediat măsurile 
preventive necesare şi, în termen de 2 ore de la luarea la cunoştinţă a apariţiei ameninţării, să informeze agenţia judeţeană pentru 
protecţia mediului şi comisariatul judeţean al Gărzii Naţionale de Mediu.</p> 

<p style="text-indent:2em;"></p><h6>(2) Informaţiile pe care operatorul este obligat să le aducă la cunoştinţă autorităţilor,
 conform prevederilor alin.(1), se referă la:</h6><p></p>

<p>a) datele de identificare ale operatorului;</p><br>

<p>b) momentul şi locul apariţiei ameninţării iminente;</p><br>

<p>c) elementele de mediu posibil a fi afectate;</p><br>

<p>d) măsurile demarate pentru prevenirea prejudiciului;</p><br>

<p>e) alte informaţii considerate relevante de operator.</p><br> 

<p style="text-indent:2em;">(3) Măsurile preventive prevăzute la alin.(1) trebuie să fie proporţionale cu ameninţarea iminentă şi să conducă la evitarea producerii prejudiciului
, luând în considerare principiul precauţiei în luarea deciziilor.</p><br> 

<p style="text-indent:2em;">(4) În termen de 1 oră de la finalizarea măsurilor preventive operatorul informează autorităţile prevăzute la alin.(1) despre măsurile întreprinse
 pentru prevenirea prejudiciului şi eficienţa acestora.</p> 

<p style="text-indent:2em;"></p><h6>(5) În cazul în care ameninţarea iminentă persistă în ciuda măsurilor preventive adoptate, operatorul informează, în termen de 6 ore de la 
momentul la care a constatat ineficienţa măsurilor luate, agenţia judeţeană pentru protecţia mediului şi comisariatul judeţean al Gărzii 
Naţionale de Mediu despre:</h6><p></p>

<p>a) măsurile întreprinse pentru prevenirea prejudiciului;</p><br>

<p>b) evoluţia situaţiei în urma aplicării măsurilor preventive;</p><br>

<p>c) alte măsuri suplimentare, după caz, care se iau pentru prevenirea înrăutăţirii situaţiei.</p> 

<p style="text-indent:2em;"></p><h6>Art.11. – În orice moment agenţia judeţeană pentru protecţia mediului are posibilitatea
 exercitării următoarelor atribuţii:</h6><p></p>

<p style="text-indent:2em;">a) să solicite operatorului să furnizeze informaţii despre orice ameninţare iminentă cu un prejudiciu asupra mediului sau despre orice caz 
suspect de ameninţare iminentă;</p><br>

<p>b) să solicite operatorului să ia măsurile preventive necesare;</p><br>

<p>c) să dea operatorului instrucţiuni despre măsurile preventive necesar a fi luate;</p><br>

<p>d) să ia măsurile preventive necesare.</p><br> 

<p style="text-indent:2em;">Art.12. – (1) Înainte de exercitarea atribuţiei prevăzute la art.11 lit.d), conducătorul agenţiei judeţene pentru protecţia mediului solicită 
operatorului să ia măsurile preventive.</p> 

<p style="text-indent:2em;"></p><h6>(2) Prin excepţie de la prevederile alin.(1), conducătorul agenţiei judeţene pentru protecţia mediului poate lua măsurile preventive necesare 
în situaţia în care operatorul:</h6><p></p>

<p>a) nu şi-a îndeplinit obligaţiile prevăzute la art.10 alin.(1) sau nu s-a conformat prevederilor art.11 lit. b) sau c);</p><br>

<p>b) nu poate fi identificat;</p><br>

<p>c) nu are obligaţia de a suporta costurile conform prezentei ordonanţe de urgenţă.</p><br> 

<p style="font-weight:500;">SECŢIUNEA a 2-a
Acţiuni reparatorii</p>

<p style="text-indent:2em;"></p><h6>Art. 13. – În cazul producerii unui prejudiciu asupra mediului, operatorul informează, în maxim 2 ore de la producerea prejudiciului, agenţia 
judeţeană pentru protecţia mediului şi comisariatul judeţean al Gărzii Naţionale de Mediu despre:</h6><p></p>

<p>a) datele de identificare ale operatorului;</p><br>

<p>b) momentul şi locul producerii prejudiciului adus mediului;</p><br>

<p>c) caracteristicile prejudiciului adus mediului;</p><br>

<p>d) cauzele care au generat prejudiciul;</p><br>

<p>e) elementele de mediu afectate;</p><br>

<p>f) măsurile demarate pentru prevenirea extinderii sau agravării prejudiciului adus mediului;</p><br>

<p>g) alte informaţii considerate relevante de operator.</p>

<p style="text-indent:2em;"></p><h6>Art. 14. – (1) Operatorul este obligat să:</h6><p></p>

<p style="text-indent:2em;">a) acţioneze imediat pentru a controla, izola, elimina sau, în caz contrar, pentru a gestiona poluanţii respectivi şi/sau orice alţi
 factori contaminanţi, în scopul limitării sau prevenirii extinderii prejudiciului asupra mediului şi a efectelor negative asupra 
 sănătăţii umane sau agravării deteriorării serviciilor.</p><br> 

<p>b) ia măsurile reparatorii necesare, conform art. 17–19.</p><br> 

<p style="text-indent:2em;">(2) Măsurile reparatorii prevăzute la alin.(1) trebuie să fie proporţionale cu prejudiciul cauzat şi să conducă la îndepărtarea efectelor
 prejudiciului, luând în considerare principiul precauţiei în luarea deciziilor.</p>

<p style="text-indent:2em;"></p><h6>Art. 15. – În orice moment conducătorul agenţiei judeţene pentru protecţia mediului
 are posibilitatea exercitării următoarelor atribuţii:</h6><p></p>

<p style="text-indent:2em;">a) să solicite operatorului să furnizeze informaţii suplimentare despre orice prejudiciu care s-a produs şi despre măsurile luate 
conform prevederilor art.13;</p><br>

<p style="text-indent:2em;">b) să acţioneze, să solicite operatorului să acţioneze sau să dea operatorului instrucţiuni pentru a controla, a izola, a elimina imediat 
sau, în caz contrar, pentru a gestiona poluanţii respectivi şi/sau alţi factori contaminanţi, în scopul limitării sau prevenirii extinderii 
prejudiciului asupra mediului şi a efectelor adverse asupra sănătăţii umane sau agravării deteriorării serviciilor;</p><br>

<p>c) să solicite operatorului să ia măsurile reparatorii necesare;</p><br>

<p>d) să indice operatorului sau să dea acestuia instrucţiuni despre măsurile reparatorii necesar a fi luate;</p><br>

<p>e) să ia măsurile reparatorii necesare.</p><br> 

<p style="text-indent:2em;">Art.16. – (1) Înainte de exercitarea atribuţiei prevăzute la art.15 lit.e), conducătorul agenţiei judeţene pentru protecţia mediului solicită 
operatorului să ia măsurile reparatorii necesare.</p> 

<p style="text-indent:2em;"></p><h6>(2) Agenţia judeţeană pentru protecţia mediului poate lua, în ultimă instanţă,
 măsurile reparatorii necesare, în situaţia în care operatorul:</h6><p></p>

<p>a) nu îşi îndeplineşte obligaţiile prevăzute la art. 13, art. 14 alin.(1) sau nu s-a
 conformat prevederilor art.15 lit. b), c) sau d);</p><br>

<p>b) nu poate fi identificat;</p><br>

<p>c) nu este obligat să suporte costurile conform prezentei ordonanţe de urgenţă.</p><br> 

<p style="text-indent:2em;">Art. 17. – (1) Operatorii identifică măsurile reparatorii posibile în conformitate cu anexa nr. 2 şi le transmit agenţiei judeţene pentru 
protecţia mediului spre aprobare, în termen de 15 de zile de la data producerii prejudiciului, cu excepţia cazului în care agenţia judeţeană 
pentru protecţia mediului a luat măsurile reparatorii respective conform prevederilor art.15 lit. e) si ale art. 16.</p><br> 

<p style="text-indent:2em;">(2) Agenţia judeţeană pentru protecţia mediului decide măsurile reparatorii care trebuie să fie implementate în conformitate cu anexa nr.2. 
În luarea deciziei agenţia judeţeană pentru protecţia mediului se consultă cu operatorul implicat.</p><br> 

<p style="text-indent:2em;">Art. 18. – (1) În termen de 5 zile de la expirarea termenului prevăzut la art.17 alin.(1) sau de la data luării la cunoştinţă, prin orice 
mijloace, despre prejudiciul adus mediului, agenţia judeţeană pentru protecţia mediului solicită, în scris, opinia asupra acestor măsuri 
persoanelor prevăzute la art.20 alin.(1) şi celor pe al căror teren urmează a se realiza măsurile reparatorii.</p><br> 

<p style="text-indent:2em;">(2) Persoanele prevăzute la art.20 alin. (1) pot transmite opiniile lor asupra măsurilor reparatorii considerate necesare, în termen de 
15 zile de la transmiterea solicitării.</p><br> 

<p style="text-indent:2em;">(3) Agenţia judeţeană pentru protecţia mediului este obligată să ia în considerare la luarea deciziei opiniile primite de la persoanele 
prevăzute la art. 20 alin. (1).</p><br> 

<p style="text-indent:2em;">(4) Conducătorul agenţiei judeţene pentru protecţia mediului emite decizia privind măsurile reparatorii şi/sau prioritizarea reparării 
prejudiciilor aduse mediului, în termen de 5 zile de la primirea opiniilor prevăzute la alin. (2).</p><br> 

<p style="text-indent:2em;">Art. 19. – (1) În situaţia în care s-au produs mai multe prejudicii asupra mediului, iar agenţia judeţeană pentru protecţia mediului 
nu poate asigura luarea, în acelaşi timp, a măsurilor reparatorii, aceasta este îndreptăţită să decidă care dintre prejudicii trebuie 
reparat cu prioritate.</p><br> 

<p style="text-indent:2em;">(2) În luarea deciziei agenţia judeţeană pentru protecţia mediului ia în considerare, printre altele, atât natura, dimensiunea şi 
gravitatea diferitelor prejudicii produse, cât şi posibilitatea regenerării naturale, acordând atenţie riscurilor pe care prejudiciul 
le reprezintă pentru sănătatea umană.</p><br> 

<p style="font-weight:500;">SECŢIUNEA a 3-a
Dreptul la acţiune</p>

<p style="text-indent:2em;"></p><h6>Art. 20. – (1) Orice persoană fizică sau juridică care este afectată sau posibil a fi afectată de un prejudiciu asupra mediului sau care 
se consideră vătămată într-un drept al său ori într-un interes legitim are dreptul:</h6><p></p>

<p style="text-indent:2em;">a) să transmită comisariatului judeţean al Gărzii Naţionale de Mediu orice observaţii referitoare la producerea unui prejudiciu asupra 
mediului sau a unei ameninţări iminente cu un astfel de prejudiciu;</p><br>

<p style="text-indent:2em;">b) să solicite agenţiei judeţene pentru protecţia mediului, în scris sau prin mijloace electronice de comunicare, să ia măsurile 
prevăzute de prezenta ordonanţă de urgenţă.</p><br> 

<p style="text-indent:2em;">(2) Se poate adresa comisariatului judeţean al Gărzii Naţionale de Mediu şi, respectiv, agenţiei judeţene pentru protecţia mediului 
şi orice organizaţie neguvernamentală care promovează protecţia mediului şi care îndeplineşte condiţiile cerute de legislaţia în vigoare, 
considerându-se că aceasta este vătămată într-un drept al său ori într-un interes legitim.</p><br> 

<p style="text-indent:2em;">Art. 21. – Solicitarea prevăzută la art. 20 alin. (1) lit. b) este însoţită de informaţiile şi datele relevante care susţin observaţiile 
transmise în legătură cu respectivul prejudiciu adus mediului.</p><br> 

<p style="text-indent:2em;">Art. 22. – (1) În situaţia în care solicitarea prevăzută la art. 20 alin. (1) lit. b) şi informaţiile care o însoţesc arată, într-o manieră
 plauzibilă, că există un prejudiciu asupra mediului, agenţia judeţeană pentru protecţia mediului o analizează şi cere, în scris, operatorului
 în cauză opinia sa asupra solicitării şi informaţiilor anexate, în termen de 5 zile de la primirea acestei solicitări.</p><br> 

<p style="text-indent:2em;">(2) Operatorul are la dispoziţie un termen de 5 zile de la primirea cererii din partea agenţiei judeţene pentru protecţia mediului 
pentru a transmite acesteia opinia sa.</p><br> 

<p style="text-indent:2em;">Art. 23. – (1) În termen de 15 zile de la transmiterea cererii către operator, agenţia judeţeană pentru protecţia mediului informează 
persoanele prevăzute la art.20 alin.(1) care au trimis comentarii, despre decizia de a acţiona
 sau de a refuza să acţioneze.</p><br> 

<p style="text-indent:2em;">(2) Decizia prevăzută la alin.(1) se motivează în fapt şi în drept şi conţine informaţii asupra termenelor şi procedurii contencios 
administrative prevăzute de Legea nr.554/2004, cu modificările ulterioare.</p><br> 

<p style="text-indent:2em;">Art. 24. – În cazul unei ameninţări iminente cu un prejudiciu, agenţia judeţeană pentru protecţia mediului informează persoanele 
prevăzute la art.20 alin.(1) care au trimis observaţii, numai după luarea măsurilor necesare
 conform prezentei ordonanţe de urgenţă.</p><br> 

<p style="text-indent:2em;">Art. 25. – (1) Persoanele prevăzute la art. 20 alin. (1) se pot adresa instanţei de contencios administrativ competente, pentru 
a ataca, din punct de vedere procedural sau substanţial, actele, deciziile sau omisiunile autorităţilor competente prevăzute de 
prezenta ordonanţă de urgenţă.</p><br> 

<p>(2) Soluţionarea cererii se realizează potrivit dispoziţiilor Legii nr.554/2004,
 cu modificările ulterioare.</p><br> 

<p style="font-weight:500;">CAPITOLUL III
Suportarea şi recuperarea costurilor</p><br>

<p style="font-weight:500;">SECŢIUNEA 1
Suportarea costurilor</p><br>

<p style="text-indent:2em;">Art. 26. – Operatorul suportă costurile acţiunilor preventive şi reparatorii, inclusiv în situaţia în care aceste costuri au 
fost efectuate de agenţia judeţeană pentru protecţia mediului.</p>

<p style="text-indent:2em;"></p><h6>Art. 27. – (1) Operatorul nu este obligat să suporte costul acţiunilor preventive sau reparatorii luate, când poate dovedi că 
prejudiciul adus mediului sau ameninţarea iminentă cu un asemenea prejudiciu:</h6><p></p>

<p>a) a fost cauzat de o terţă parte şi s-a produs chiar dacă au fost luate măsurile de siguranţă corespunzătoare;</p><br>

<p style="text-indent:2em;">b) s-a produs ca urmare a conformării cu o dispoziţie sau instrucţiune obligatorie emisă de o autoritate publică, alta decât un 
ordin sau instrucţiune eliberate ca urmare a unei emisii sau incident cauzat de activităţile operatorului.</p><br> 

<p style="text-indent:2em;">(2) În cazurile prevăzute la alin.(1) statul, prin reprezentanţii săi legali, ia măsurile corespunzătoare pentru a permite 
operatorului să recupereze costurile suportate.</p>

<p style="text-indent:2em;"></p><h6>Art. 28. – Prin excepţie de la prevederile art. 26, operatorul nu suportă costul acţiunilor reparatorii luate, dacă dovedeşte 
că nu a acţionat cu intenţie sau din culpă şi că prejudiciul asupra mediului a fost provocat de:</h6><p></p>

<p style="text-indent:2em;">a) o emisie sau un eveniment autorizat în mod special şi în deplină concordanţă cu condiţiile prevăzute de actul de reglementare 
eliberat conform normelor care implementează măsurile prevăzute în anexa nr.3, în vigoare
 la data emisiei sau evenimentului;</p><br>

<p style="text-indent:2em;">b) o emisie, o activitate sau orice modalitate de utilizare a unui produs în decursul unei activităţi, pentru care operatorul 
demonstrează că nu era posibil, conform stadiului cunoştinţelor tehnice şi ştiinţifice existente la data când emisia a fost 
eliberată sau când a avut loc activitatea, să producă un prejudiciu asupra mediului.</p><br> 

<p style="text-indent:2em;">Art. 29. – (1) Cu excepţia situaţiilor prevăzute la art. 27 şi 28, agenţia judeţeană pentru protecţia mediului recuperează 
costurile acţiunilor preventive şi reparatorii realizate conform prezentei ordonanţe de urgenţă de la operatorul care a cauzat 
prejudiciul sau ameninţarea iminentă cu prejudiciul.</p><br> 

<p style="text-indent:2em;">(2) În scopul garantării recuperării costurilor suportate, agenţia judeţeană pentru protecţia mediului instituie o ipotecă 
asupra bunurilor imobile ale operatorului şi o poprire asiguratorie, conform normelor legale în vigoare.</p><br> 

<p style="text-indent:2em;">(3) Înscrierea în cartea funciară a ipotecii şi instituirea popririi asiguratorii se realizează pe baza dispoziţiei 
conducătorului agenţiei judeţene pentru protecţia mediului care a stabilit măsurile preventive sau reparatorii luate.</p><br> 

<p style="text-indent:2em;">(4) Agenţia judeţeană pentru protecţia mediului poate decide să nu recupereze în totalitate costurile suportate, în cazul 
în care cheltuielile necesare în acest scop sunt mai mari decât suma recuperabilă sau când operatorul nu poate fi 
identificat.</p><br> 

<p style="text-indent:2em;">Art. 30. – Măsurile luate de agenţia judeţeană pentru protecţia mediului conform art.11, 12, 15 şi 16 nu afectează 
răspunderea operatorilor responsabili conform prevederilor prezentei ordonanţe de urgenţă şi nu aduce atingere prevederilor 
art. 87 şi 88 ale Tratatului privind instituirea Comunităţilor Europene, încheiat la Roma în 1957, cu amendamentele ulterioare,
 la care România a devenit parte prin Legea nr. 157 din 24 mai 2005 pentru ratificarea Tratatului privind aderarea Republicii 
 Bulgaria şi a României la Uniunea Europeană, semnat de România la Luxemburg la 25 aprilie 2005.</p><br> 

<p style="text-indent:2em;">Art. 31. – (1) În cazul în care prejudiciul asupra mediului sau ameninţarea iminentă cu un astfel de prejudiciu a fost cauzată 
de mai mulţi operatori, aceştia sunt obligaţi să suporte în mod solidar costurile măsurilor preventive sau reparatorii.</p><br> 

<p style="text-indent:2em;">(2) Efectele solidarităţii pasive, inclusiv alocarea costurilor între codebitori se realizează conform prevederilor legale 
în vigoare, luându-se în considerare dispoziţiile referitoare la divizarea răspunderii între producătorul şi utilizatorul 
unui produs. </p><br>

<p style="text-indent:2em;">(3) În cazul în care operatorul care a produs un prejudiciu asupra mediului sau o ameninţare iminentă cu un astfel de 
prejudiciu face parte dintr-un consorţiu sau o companie multinaţionala, el răspunde în mod solidar şi indivizibil cu respectivul 
consorţiu sau respectiva companie. </p><br>

<p style="text-indent:2em;">Art. 32. – (1) Agenţia judeţeană pentru protecţia mediului are dreptul să iniţieze împotriva operatorului sau, după caz, 
împotriva terţei persoane care a cauzat prejudiciul sau ameninţarea iminentă cu prejudiciul proceduri de recuperare a 
costurilor măsurilor preventive sau reparatorii adoptate conform prezentei ordonanţe de urgenţă. </p><br>

<p style="text-indent:2em;">(2) Dreptul agenţiei judeţene pentru protecţia mediului de a acţiona împotriva operatorului pentru recuperarea costurilor 
se prescrie în termen de 5 ani de la data când acele măsuri au fost realizate sau de la data când operatorul responsabil 
sau terţa persoană a fost identificată. </p><br>

<p style="font-weight:500;">CAPITOLUL IV
Instrumente financiare şi cooperarea cu statele membre ale Uniunii Europene</p><br>

<p style="font-weight:500;">SECŢIUNEA 1
Instrumente financiare</p><br>

<p style="text-indent:2em;">Art. 33. – (1) Definirea formelor de garanţie financiară, inclusiv pentru cazurile de insolvenţă, şi măsurile pentru dezvoltarea 
ofertei de instrumente financiare privind răspunderea în domeniul mediului, care să permită operatorilor utilizarea acestora în scopul
 garantării obligaţiilor ce le revin conform prezentei ordonanţe de urgenţă, se stabilesc prin hotărâre a Guvernului, la propunerea 
 autorităţilor publice centrale pentru protecţia mediului şi pentru finanţe publice, în termen de 12 luni de la intrarea în vigoare 
 a prezentei ordonanţe de urgenţă. </p>

<p style="text-indent:2em;"></p><h6>(2) La definirea formelor de garanţie financiară prevăzute la alin.(1) se iau în considerare următoarele:</h6><p></p>

<p style="text-indent:2em;">a) stabilirea garanţiei se face în funcţie de gradul de periculozitate al activităţii profesionale desfăşurate sau propuse a fi 
desfăşurată;</p><br>

<p style="text-indent:2em;">b) gradul de periculozitate al activităţii profesionale se stabileşte în funcţie de potenţialele prejudicii pe care le poate 
cauza asupra mediului;</p><br>

<p style="text-indent:2em;">c) potenţialele prejudicii se determină pe baza evaluărilor de impact asupra mediului şi/sau a evaluărilor de risc prezentate 
de operatori. </p><br>

<p style="text-indent:2em;">Art. 34 – Costurile măsurilor preventive sau reparatorii se alocă autorităţii publice centrale pentru protecţia mediului, prin 
hotărâre a Guvernului, din fondul de intervenţie la dispoziţia Guvernului pentru finanţarea acţiunilor de strictă urgenţă, 
în vederea prevenirii efectelor ameninţărilor iminente cu un prejudiciu şi/sau înlăturării efectelor produse de prejudiciul 
asupra mediului. </p><br>

<p style="font-weight:500;">SECŢIUNEA A 2-A
Cooperarea cu state membre ale Uniunii Europene</p><br>

<p style="text-indent:2em;">Art. 35 – (1) În situaţia în care prejudiciul asupra mediului afectează sau este posibil să afecteze alte state, agenţia 
judeţeană pentru protecţia mediului, prin intermediul autorităţii publice centrale pentru protecţia mediului, cooperează cu 
autorităţile competente pentru protecţia mediului din statele afectate sau potenţial afectate, inclusiv printr-un schimb 
corespunzător de informaţii, în scopul luării masurilor preventive şi, după caz, a măsurilor reparatorii a unui astfel de 
prejudiciu. </p><br>

<p style="text-indent:2em;">(2) Cooperarea dintre autorităţile centrale competente pentru protecţia mediului din România şi cele ale statelor afectate 
sau potenţial afectate se realizează inclusiv în baza tratatelor bilaterale în vigoare încheiate în domeniul protecţiei mediului 
şi gospodăririi apelor. </p><br>

<p style="text-indent:2em;">Art. 36 – (1) În situaţia în care prejudiciul asupra mediului s-a produs pe teritoriul României, agenţia judeţeană pentru 
protecţia mediului, prin intermediul autorităţii publice centrale pentru protecţia mediului, furnizează informaţii, în termen 
de 24 de ore de la luarea la cunoştinţă despre prejudiciul asupra mediului, autorităţilor competente pentru protecţia mediului 
din statele potenţial afectate.</p>

<p style="text-indent:2em;"></p><h6>(2) Informaţiile prevăzute la alin. (1) se referă la:</h6><p></p>

<p>a) momentul şi locul producerii prejudiciului adus mediului;</p><br>

<p>b) caracteristicile prejudiciului adus mediului;</p><br>

<p>c) elementele de mediu afectate;</p><br>

<p>d) măsurile luate pentru prevenirea extinderii sau agravării prejudiciului adus mediului;</p><br>

<p>e) alte informaţii considerate relevante. </p><br>

<p style="text-indent:2em;">Art. 37 – (1) În cazul în care comisariatele judeţene ale Gărzii Naţionale de Mediu constată un prejudiciu a cărui origine nu 
se situează pe teritoriul României, informează Comisia Europeană şi alte state interesate, prin intermediul autorităţii publice 
centrale pentru protecţia mediului, despre prejudiciul asupra mediului. </p>

<p style="text-indent:2em;"></p><h6>(2) Autoritatea publică centrală pentru protecţia mediului poate face:</h6><p></p>

<p>a) recomandări privind adoptarea măsurilor preventive sau reparatorii;</p><br>

<p>b) demersurile necesare pentru recuperarea costurilor suportate în legătură cu adoptarea măsurilor preventive sau reparatorii. </p><br>

<p style="text-indent:2em;">Art. 38 – Ministerul Afacerilor Externe sprijină demersurile autorităţii publice centrale pentru protecţia mediului prevăzute 
la art. 36 alin. (1) şi art. 37 alin. (2). </p><br>

<p style="font-weight:500;">CAPITOLUL V
Sancţiuni</p><br>

<p style="text-indent:2em;">Art. 39 – Este interzisă recuperarea dublă a costurilor în situaţia acţiunii concurente a autorităţilor competente şi a persoanei 
a cărei proprietate a fost afectată de prejudiciul asupra mediului. </p>

<p style="text-indent:2em;"></p><h6>Art. 40 – (1) Următoarele fapte constituie contravenţie şi se sancţionează după cum urmează:</h6><p></p>

<p>a) nerespectarea prevederilor art. 7 alin. (2) şi 10 alin.</p><br> 

<p>(5) cu amendă între 5.000 lei şi 10.000 lei pentru persoane fizice şi între 10.000 lei şi
 20.000 lei pentru persoane juridice;</p><br>

<p style="text-indent:2em;">b) nerespectarea prevederilor art.10 alin.(1) teza a doua, 13 şi 14 alin.(1) lit. a) cu amendă între 25.000 lei şi 40.000 lei pentru 
persoane fizice şi între 50.000 lei şi 80.000 lei pentru persoane juridice;</p><br>

<p style="text-indent:2em;">c) nerespectarea prevederilor art.7 alin.(3), 10 alin.(1) prima teză, 14 alin.(1) lit. b) şi 17 alin.(1) cu amendă între 40.000 lei 
şi 50.000 lei pentru persoane fizice şi între 80.000 lei şi 100.000 lei pentru persoane juridice;</p><br>

<p style="text-indent:2em;">d) nerespectarea prevederilor art.11 lit.a) – c) şi 15 lit.a) – d) cu amendă între 40.000 lei şi 50.000 lei pentru persoane fizice 
şi între 80.000 lei şi 100.000 lei pentru persoane juridice.</p><br> 

<p style="text-indent:2em;">(2) Constatarea contravenţiilor şi aplicarea sancţiunilor prevăzute la alin. (1) se fac de personalul împuternicit din cadrul 
Gărzii Naţionale de Mediu. </p><br>

<p style="text-indent:2em;">Art. 41 – (1) Dispoziţiile referitoare la contravenţii prevăzute la art. 40 se completează cu prevederile Ordonanţei Guvernului nr. 
2/2001 privind regimul juridic al contravenţiilor, aprobată cu modificări şi completări prin Legea nr. 180/2002, cu modificările şi 
completările ulterioare. </p><br>

<p style="text-indent:2em;">(2) Contravenientul poate achita pe loc sau în termen de cel mult 48 de ore de la data încheierii procesului-verbal ori, după caz, 
de la data comunicării acestuia, jumătate din minimul amenzii prevăzute în actul normativ, agentul constatator făcând menţiune despre 
această posibilitate în procesul-verbal de constatare şi sancţionare a contravenţiei. </p><br>

<p style="text-indent:2em;">Art. 42. – (1) Constituie infracţiuni faptele prevăzute la art. 40 dacă au pus în pericol viaţa, sănătatea sau integritatea corporală 
a uneia sau mai multor persoane şi se pedepsesc cu închisoare de la 1 la 3 ani sau cu amendă. </p><br>

<p style="text-indent:2em;">(2) Fapta prevăzută la art. 40 care a avut vreuna dintre urmările prevăzute în art. 182 din Codul penal se pedepseşte cu închisoare de 
la 2 la 10 ani dacă este săvârşită cu intenţie şi cu închisoare de la 3 luni la 2 ani sau cu
 amendă dacă a fost săvârşită din culpă. </p><br>

<p style="text-indent:2em;">(3) Fapta prevăzută la art. 40 care a avut ca urmare moartea uneia sau mai multor persoane se pedepseşte cu închisoare de la 10 la 25 
de ani dacă a fost săvârşită cu intenţie şi cu închisoare de la 1 la 5 ani dacă a fost săvârşită din culpă. </p><br>

<p style="text-indent:2em;">(4) Pentru faptele care constituie infracţiuni conform legii penale, comisarii Gărzii Naţionale de Mediu au obligaţia de a sesiza 
organele de urmărire penală. </p><br>

<p style="font-weight:500;">CAPITOLUL VI
Dispoziţii tranzitorii şi finale</p><br>

<p style="text-indent:2em;">Art. 43 – Prezenta ordonanţă de urgenţă nu aduce atingere prevederilor legislaţiei speciale din domeniul gospodăririi apelor,
 protecţiei speciilor, habitatelor naturale şi solului. </p><br>

<p style="text-indent:2em;">Art. 44. – Prezenta ordonanţă de urgenţă nu aduce atingere prevederilor legislaţiei privind recuperarea prejudiciului cauzat 
proprietăţii sau persoanei. </p><br>

<p style="text-indent:2em;">Art. 45 – (1) Agenţia Naţională pentru Protecţia Mediului elaborează până la 31 decembrie 2012 un raport privind implementarea 
prezentei ordonanţe, care va cuprinde informaţiile şi datele prevăzute în anexa nr. 6. </p><br>

<p style="text-indent:2em;">(2) Agenţia Naţională pentru Protecţia Mediului transmite autorităţii publice centrale pentru protecţia mediului raportul 
prevăzut la alin.(1), până la data de 31 ianuarie 2013. </p><br>

<p style="text-indent:2em;">(3) Autoritatea publică centrală pentru protecţia mediului transmite Comisiei Europene raportul prevăzut la alin.(1) până 
la data de 31 martie 2013. </p><br>

<p style="text-indent:2em;">Art. 46– Autoritatea centrală pentru protecţia mediului transmite Comisiei Europene actele normative naţionale care asigură 
conformarea cu prevederile Directivei 2004/35/CE privind răspunderea de mediu cu referire la măsurile preventive şi de 
reparare, împreună cu un tabel de concordanţă. </p><br>

<p>Art.47– Anexele nr.1–6 fac parte integrantă din prezenta ordonanţă de urgenţă. </p><br>

<p style="text-indent:2em;">Prezenta ordonanţă de urgenţă transpune prevederile Directivei Consiliului şi Parlamentului European 2004/35/CE privind 
răspunderea de mediu cu referire la măsurile preventive şi de reparare, publicată în Jurnalul Oficial al Uniunii Europene 
(JOUE) nr. L 143 din 30 aprilie 2004. </p><br>

<p style="text-indent:2em;">Nr. 68. Criteriile pentru stabilirea caracterului semnificativ al efectelor unui prejudiciu asupra speciilor protejate şi 
habitatelor naturale</p>
 
<p style="text-indent:2em;"></p><h6>Caracterul semnificativ al oricărui prejudiciu care are efecte negative asupra atingerii sau menţinerii stării favorabile 
de conservare a habitatelor sau speciilor trebuie evaluat în raport cu stadiul conservării la momentul producerii prejudiciului,
 cu serviciile furnizate de atractivitatea şi utilitatea pe care ele o oferă, şi capacitatea lor de regenerare naturală. Schimbările
 semnificative negative ale stării iniţiale se determină cu ajutorul datelor măsurabile, ca de exemplu:</h6><p></p>

<p>a) numărul de indivizi, densitatea lor sau suprafaţa pe care o ocupă;</p><br>

<p style="text-indent:2em;">b) rolul specific al indivizilor sau al suprafeţei prejudiciate în legătură cu conservarea speciilor sau a habitatelor, 
raritatea speciilor sau habitatului evaluată la nivel local, regional şi naţional, inclusiv la nivel comunitar;</p><br>

<p style="text-indent:2em;">c) capacitatea de răspândire a speciilor, conform dinamicii specifice acelor specii sau acelei populaţii, viabilitatea lor 
sau capacitatea de regenerare naturală a habitatului în funcţie de dinamica specifică a speciilor caracteristice sau 
populaţiilor lor;</p><br>

<p style="text-indent:2em;">d) capacitatea speciilor sau a habitatelor de a se reface, într-un timp scurt după producerea prejudiciului, fără nicio 
intervenţie cu excepţia unor măsuri de protecţie crescută, la o stare care tinde, exclusiv datorită dinamicii speciilor 
sau habitatului, să fie considerată echivalentă sau superioară stării iniţiale. </p><br>

<p>Prejudiciul care are un efect dovedit asupra sănătăţii umane trebuie considerat ca fiind prejudiciu semnificativ. </p>

<p style="text-indent:2em;"></p><h6>Nu se consideră ca prejudicii semnificative următoarele:</h6><p></p>

<p style="text-indent:2em;">(i) variaţiile negative care sunt mai mici decât fluctuaţiile naturale considerate normale în cazul speciilor sau 
habitatului respectiv;</p><br>

<p style="text-indent:2em;">(ii) variaţiile negative datorate unor cauze naturale sau rezultate dintr-o intervenţie legată de managementul normal 
al siturilor, astfel cum sunt specificate în bazele de date ale habitatelor, în documentele specifice sau aşa cum au fost 
realizate anterior de către proprietari sau operatori;</p><br>

<p style="text-indent:2em;">(iii) prejudiciul adus speciilor sau habitatelor pentru care s-a stabilit că se vor reface, într-o perioadă scurtă de 
timp şi fără intervenţie, fie la starea iniţială, fie la o stare care tinde, exclusiv datorită dinamicii speciilor sau 
habitatului, să fie considerată echivalentă sau superioară stării iniţiale. </p><br>

<p style="font-weight:500;">Repararea prejudiciului asupra mediului</p><br>
 
<p style="text-indent:2em;">Prezenta anexă stabileşte cadrul comun pentru alegerea celor mai potrivite măsuri care să asigure repararea 
prejudiciului asupra mediului. </p><br>

<p>1. Repararea prejudiciului asupra apelor sau a speciilor ori habitatelor naturale protejate</p>

<p style="text-indent:2em;"></p><h6>Repararea prejudiciului adus apelor sau speciilor ori habitatelor naturale protejate se realizează prin readucerea
 acestora la starea iniţială, printr-o reparare primară, complementară şi compensatorie, unde:</h6><p></p>

<p style="text-indent:2em;">a) Reparare „primară“ înseamnă orice măsură de remediere care readuce resursele naturale prejudiciate şi/sau 
serviciile afectate la starea iniţială sau la o stare apropiată de aceasta. </p><br>

<p style="text-indent:2em;">b) Reparare „complementară“ înseamnă orice măsură de remediere întreprinsă cu privire la resursele naturale şi/sau 
serviciile pentru a compensa faptul că repararea primară nu a condus la refacerea completă a resurselor naturale 
şi/sau a serviciilor prejudiciate;</p><br>

<p style="text-indent:2em;">c) Reparare „compensatorie” înseamnă orice măsură întreprinsă pentru a compensa pierderile interimare de resurse 
naturale şi/sau de servicii care au loc între data producerii prejudiciului şi momentul în care repararea primară 
îşi produce pe deplin efectul;</p><br>

<p style="text-indent:2em;">d) „Pierderi interimare“ înseamnă pierderi care rezultă din faptul că resursele naturale şi/sau serviciile 
prejudiciate nu-şi pot îndeplini funcţiile ecologice sau nu pot furniza servicii altor resurse naturale sau 
publicului până când îşi fac efectul măsurile primare sau complementare. Nu constau în compensarea financiară 
acordată publicului. </p><br>

<p style="text-indent:2em;">În cazul în care repararea primară nu conduce la readucerea mediului la starea iniţială, se iau măsuri de reparare 
complementară. În afară de aceasta, pentru a compensa pierderile interimare suferite se recurge la repararea 
compensatorie. </p><br>

<p style="text-indent:2em;">Repararea prejudiciului asupra apelor, speciilor sau a habitatelor naturale protejate implica, de asemenea, 
îndepărtarea oricărui risc semnificativ cu impact negativ asupra sănătăţii umane. </p><br>

<p style="font-weight:500;">1.1. Obiectivele reparării</p><br>

<p style="font-weight:500;">Scopul reparării primare</p><br>

<p style="text-indent:2em;">1.1.1. Scopul reparării primare este de a readuce resursele naturale şi/sau serviciile prejudiciate la starea 
iniţială sau la o stare apropiată de aceasta. </p><br>

<p style="font-weight:500;">Scopul reparării complementare</p><br>

<p style="text-indent:2em;">1.1.2. În cazul în care resursele naturale şi/sau serviciile prejudiciate nu revin la starea iniţială, se recurge 
la repararea complementară. Scopul reparării complementare este de a furniza un nivel al resurselor naturale şi/sau 
serviciilor inclusiv, după caz, într-un sit alternativ, similar cu cel care ar fi fost furnizat dacă situl prejudiciat 
ar fi revenit la starea sa iniţială. În cazul în care este posibil şi oportun, situl alternativ trebuie să fie legat 
geografic de situl prejudiciat, luând în considerare interesele populaţiei afectate. </p><br>

<p style="font-weight:500;">Scopul reparării compensatorii</p><br>

<p style="text-indent:2em;">1.1.3. Repararea compensatorie se întreprinde pentru a compensa pierderile interimare de resurse naturale şi de 
servicii până la refacerea acestora. Compensarea constă în îmbunătăţiri suplimentare aduse habitatelor naturale şi 
speciilor protejate sau apelor, fie în situl prejudiciat, fie într-un alt sit alternativ. Ea nu constă într-o 
compensare financiară acordată publicului. </p><br>

<p style="font-weight:500;">1.2. Identificarea măsurilor de reparare</p><br>

<p style="font-weight:500;">Identificarea măsurilor de reparare primară</p><br>

<p style="text-indent:2em;">1.2.1. Se iau în considerare opţiunile de acţiune menite să readucă direct resursele naturale şi serviciile la 
starea lor iniţială în ritm accelerat, ori cele de recuperare pe cale naturală. </p><br>

<p style="font-weight:500;">Identificarea măsurilor de reparare complementară şi compensatorie</p><br>

<p style="text-indent:2em;">1.2.2. La determinarea complexităţii măsurilor de reparare complementare şi compensatorii se utilizează în primul 
rând abordările vizând o echivalenţă „resursă la resursă” sau „serviciu la serviciu“. În cadrul acestor abordări, au 
prioritate acţiunile care furnizează resurse naturale şi/sau servicii de acelaşi tip, calitate şi cantitate cu cele 
prejudiciate. Când acest lucru nu este posibil, se furnizează resurse naturale şi/sau servicii alternative. De exemplu, 
o reducere a calităţii măsurilor poate fi compensată cu un număr mai mare de măsuri de reparare. </p><br>

<p style="text-indent:2em;">1.2.3. În cazul în care nu se poate utiliza prima variantă de abordare „resursă la resursă“ sau „serviciu la serviciu“, 
se folosesc tehnicile alternative de evaluare. Agenţia judeţeană pentru protecţia mediului în colaborare cu Agenţia 
Naţională pentru Protecţia Mediului pot stabili metoda care trebuie folosită, de exemplu, evaluarea monetară, pentru 
a determina amploarea măsurilor reparatorii complementare şi compensatorii necesare. În cazul în care se poate face o 
evaluare a pierderilor de resurse şi/sau servicii, însă evaluarea înlocuirii resurselor naturale şi/sau serviciilor nu 
se poate realiza într-un timp util sau la un cost rezonabil, Agenţia judeţeană pentru protecţia mediului în colaborare 
cu Agenţia Naţională pentru Protecţia Mediului pot alege măsurile reparatorii al căror cost este echivalent cu valoarea 
monetară estimată a resurselor naturale şi/sau a serviciilor pierdute. </p><br>

<p style="text-indent:2em;">Măsurile reparatorii complementare şi compensatorii se stabilesc astfel încât să furnizeze resurse naturale şi/sau servicii
 suplimentare ţinând seama de perioada de timp necesară şi de cerinţele de planificare a măsurilor reparatorii. De exemplu,
 cu cât este mai lungă perioada de revenire la starea iniţială, cu atât va fi mai mare numărul măsurilor reparatorii 
 compensatorii care vor fi luate, toate celelalte rămânând egale. </p><br>

<p style="font-weight:500;">1.3. Alegerea opţiunilor reparatorii</p>

<p style="text-indent:2em;"></p><h6>1.3.1. Opţiunile rezonabile reparatorii se evaluează folosind cele mai bune tehnologii disponibile, pe baza următoarelor
 criterii:</h6><p></p>

<p>a) efectul fiecărei opţiuni asupra sănătăţii şi securităţii populaţiei;</p><br>

<p>b) costul punerii în aplicare a opţiunii;</p><br>

<p>c) şansele de reuşită a fiecărei opţiuni;</p><br>

<p style="text-indent:2em;">d) măsura în care fiecare opţiune va preveni viitoare prejudicii şi va evita prejudicii colaterale care s-ar putea produce
 ca rezultat al punerii în aplicare a acestei opţiuni;</p><br>

<p>e) măsura în care fiecare opţiune are efecte favorabile
 asupra fiecărei componente a resursei naturale şi/sau serviciului;</p><br>

<p style="text-indent:2em;">f) măsura în care fiecare opţiune ţine cont de aspectele sociale, economice şi culturale relevante şi de alţi factori
 relevanţi specifici locului;</p><br>

<p>g) termenul necesar pentru ca refacerea prejudiciului asupra mediului să fie efectivă;</p><br>

<p>h) măsura în care fiecare opţiune conduce la refacerea zonei prejudiciate;</p><br>

<p>i) legătura geografică cu zona prejudiciată. </p><br>

<p style="text-indent:2em;">1.3.2. La evaluarea diferitelor opţiuni reparatorii identificate pot fi alese măsuri reparatorii primare care nu restabilesc
 în întregime starea iniţială a apelor sau a habitatelor naturale ori speciilor protejate care au fost prejudiciate, sau măsuri
 care produc o refacere mai lentă. Această decizie poate fi luată numai dacă resursele naturale şi/sau serviciile existente ale
 sitului primar ca urmare a deciziei, sunt compensate prin intensificarea acţiunilor complementare sau compensatorii în scopul
 furnizării unui nivel similar al resurselor şi/sau serviciilor, aşa cum existau. O astfel de situaţie este, de exemplu, când
 resursele naturale şi/sau serviciile echivalente pot fi furnizate altundeva, la un cost mai mic. Măsurile reparatorii suplimentare
 se stabilesc în conformitate cu regulile prevăzute la pct. 1.2.2. </p>

<p style="text-indent:2em;"></p><h6>1.3.3. Fără a aduce atingere regulilor stabilite la pct. 1.3.2. şi conform art.18 din ordonanţa de urgenţă, agenţia locală pentru
 protecţia mediului este abilitată să decidă că nu este necesar a se lua măsuri suplimentare reparatorii în cazul în care:</h6><p></p>

<p style="text-indent:2em;">(a) măsurile reparatorii deja luate garantează că nu mai există niciun risc semnificativ care să afecteze în mod negativ sănătatea
 umană, apele sau speciile şi habitatele naturale protejate, şi</p><br>

<p style="text-indent:2em;">(b) costul măsurilor reparatorii care trebuie luate pentru atingerea stării iniţiale sau a unui nivel similar ar fi
 disproporţionat în raport cu beneficiile de mediu aşteptate. </p><br>

<p style="font-weight:500;">2. Repararea prejudiciului asupra solului</p><br>

<p style="text-indent:2em;">Se iau măsurile necesare pentru a asigura, cel puţin, eliminarea, controlul, izolarea sau diminuarea poluanţilor relevanţi,
 astfel încât solul contaminat, ţinând cont de utilizarea lui actuală sau viitoare, aprobată la momentul prejudiciului, să
 nu mai prezinte vreun risc semnificativ cu impact negativ asupra sănătăţii umane. Prezenţa unor astfel de riscuri se evaluează
 prin proceduri de evaluare a riscului, având în vedere caracteristicile şi categoria de folosinţă a solului, tipul şi concentraţia
 substanţelor, preparatelor, organismelor sau microorganismelor periculoase, gradul de risc pe care acestea îl reprezintă şi posibilitatea
 de dispersie. Categoria de folosinţă a solului se stabileşte pe baza reglementărilor care privesc folosinţa solului sau a altor
 reglementări relevante, în vigoare la data producerii prejudiciului. </p><br>

<p style="text-indent:2em;">În cazul în care se schimbă categoria de folosinţă a solului, se iau toate măsurile necesare pentru a preveni orice efecte negative
 asupra sănătăţii umane. </p><br>

<p style="text-indent:2em;">În cazul în care nu există reglementări privind folosinţa solului sau alte reglementări relevante, categoria de folosinţă a zonei
 respective se determină în funcţie de caracteristica suprafeţei unde s-a produs prejudiciul, luând în considerare dezvoltarea sa
 previzionată. </p><br>

<p style="text-indent:2em;">Se ia în considerare opţiunea de regenerare pe cale naturală, opţiune în care nu are loc nicio intervenţie umană directă în
 procesul de regenerare. </p><br>

<p style="font-weight:500;">Activităţile pentru care răspunderea este obiectivă</p><br>
 
<p style="text-indent:2em;">1. Funcţionarea instalaţiilor care fac obiectul procedurii de autorizare integrată de mediu conform prevederilor Ordonanţei de urgenţă a
 Guvernului nr. 152/2005 privind prevenirea şi controlul integrat al poluării, aprobată cu modificări prin Legea nr. 84/2006. Aceasta înseamnă
 activităţile prevăzute în anexa nr.1 a Ordonanţei de urgenţă a Guvernului nr. 152/2005 aprobată cu modificări prin Legea nr. 84/2006, cu excepţia
 instalaţiilor sau părţi ale instalaţiilor utilizate pentru cercetare, dezvoltare şi testare de produse şi procese noi. </p><br>

<p style="text-indent:2em;">2. Activităţile de gestionare a deşeurilor, inclusiv colectarea, transportul, valorificarea şi eliminarea deşeurilor şi a deşeurilor periculoase
, precum şi inspecţia şi controlul acestor operaţiuni şi monitorizarea postînchidere a depozitelor de deşeuri, care fac obiectul autorizării sau
 înregistrării, potrivit prevederilor Ordonanţei de urgenţă a Guvernului nr. 78/2000 privind regimul deşeurilor, aprobată cu modificări prin Legea
 nr. 426/2001, cu completările ulterioare. </p><br>

<p style="text-indent:2em;">Activităţile de gestionare a deşeurilor presupun, între altele, operarea depozitelor de deşeuri conform Hotărârii Guvernului nr. 349/2005 privind
 depozitarea deşeurilor, precum şi operarea instalaţiilor de incinerare conform Hotărârii Guvernului nr. 128/2002 privind incinerarea deşeurilor,
 cu modificările si completările ulterioare. </p><br>

<p style="text-indent:2em;">3. Toate evacuările în apele interioare de suprafaţă care necesită o autorizare prealabilă conform Hotărârii Guvernului nr. 351/2005 privind
 aprobarea Programului de eliminare treptată a evacuărilor, emisiilor şi pierderilor de substanţe prioritar periculoase, cu modificările şi
 completările ulterioare. </p><br>

<p style="text-indent:2em;">4. Toate evacuările substanţelor în apele subterane, care necesită o autorizare prealabilă conform Hotărârii Guvernului nr. 351/2005 privind
 aprobarea Programului de eliminare treptată a evacuărilor, emisiilor şi pierderilor de substanţe prioritar periculoase, cu modificările şi
 completările ulterioare. </p><br>

<p style="text-indent:2em;">5. Evacuarea sau injectarea unor poluanţi în apele de suprafaţă sau subterane, care necesită un aviz, o autorizaţie sau o înregistrare pe baza
 unor reguli general obligatorii, conform Legii nr.107/1996, cu modificările şi completările ulterioare. </p><br>

<p style="text-indent:2em;">6. Prelevările de apă şi acumulările de apă de suprafaţă, care fac obiectul unei autorizări prealabile conform Legii nr.107/1996, cu modificările
 şi completările ulterioare. </p>

<p style="text-indent:2em;"></p><h6>7. Producerea, utilizarea, depozitarea, procesarea, încărcarea/umplerea recipienţilor, eliberarea
 în mediu şi transportul pe amplasament a:</h6><p></p>

<p style="text-indent:2em;">a) substanţelor şi preparatelor periculoase prevăzute la art. 7 din Ordonanţa de urgenţă a Guvernului nr. 200/2000 privind clasificarea,
 etichetarea şi ambalarea substanţelor şi preparatelor chimice periculoase, aprobată cu modificări prin Legea nr. 451/2001, cu modificările
 şi completările ulterioare;</p><br>

<p style="text-indent:2em;">b) produselor de protecţie a plantelor prevăzute la art. 3 din Hotărârea Guvernului nr. 1.559/2004 privind procedura de omologare a
 produselor de protecţie a plantelor în vederea plasării pe piaţă şi a utilizării lor pe teritoriul României, cu modificările şi
 completările ulterioare;</p><br>

<p style="text-indent:2em;">c) produselor biocide prevăzute la art. 10 lit. c) din Hotărârea Guvernului nr. 956/2005 privind plasarea pe piaţă a produselor biocide,
 cu modificările ulterioare. </p>

<p style="text-indent:2em;"></p><h6>8. Transportul rutier, feroviar, pe căi navigabile interioare, maritim sau aerian al mărfurilor
 periculoase sau poluante definite în:</h6><p></p>

<p style="text-indent:2em;">a) anexa la Hotărârea Guvernului nr. 1.374/2000 pentru aprobarea Normelor privind aplicarea etapizată, în traficul intern, a prevederilor
 Acordului European referitor la transportul rutier internaţional al mărfurilor periculoase – A.D.R., încheiat la Geneva la 30 septembrie
 1957, la care România a aderat prin Legea nr. 31/1994, cu modificările ulterioare, sau în</p><br>

<p style="text-indent:2em;">b) anexa la Ordonanţa Guvernului nr.49/1999 privind transportul mărfurilor periculoase pe calea ferată – RID, aprobată cu modificări prin
 Legea nr.788/2001, sau în</p><br>

<p style="text-indent:2em;">c) Ordinul Ministrului Transporturilor, Construcţiilor şi Turismului nr. 323/2006 pentru aprobarea sistemului de informare şi monitorizare
 a traficului navelor care intră/ies în/din apele naţionale navigabile ale României. </p><br>

<p style="text-indent:2em;">9. Orice utilizare în condiţii de izolare, inclusiv transportul microorganismelor modificate genetic conform prevederilor legislaţiei în
 domeniu privind utilizarea în condiţii de izolare a organismelor modificate genetic. </p><br>

<p style="text-indent:2em;">10. Orice introducere deliberată în mediu, orice transport sau orice introducere pe piaţă a organismelor modificate genetic, conform
 legislaţiei în domeniu privind introducerea deliberată în mediu şi pe piaţă a organismelor modificate genetic. </p><br>

<p style="text-indent:2em;">11. Transportul transfrontieră al deşeurilor în, înspre sau dinspre Uniunea Europeană, care necesită o autorizare prealabilă sau care
 este interzis conform Regulamentului Consiliului nr. 259/93/CEE privind supravegherea şi controlul transporturilor de deşeuri în,
 înspre şi dinspre Comunitatea Europeană. </p><br>

<p style="text-indent:2em;">Începând cu 12 iulie 2007, trimiterea la Regulamentul Consiliului nr. 259/93/CEE se consideră a fi făcută la Regulamentul Parlamentului
 European şi al Consiliului nr. 1.013/2006/CE. </p>
 
<p style="text-indent:2em;"></p><h6>Convenţiile internaţionale care reglementează răspunderea sau compensarea pentru incidentele
 din domeniul transporturilor:</h6><p></p>
 
<p style="text-indent:2em;">a) Convenţia internaţională privind răspunderea civilă pentru pagubele produse prin poluare cu hidrocarburi, încheiată la Londra la 27
 noiembrie 1992 la care România a aderat prin Ordonanţa Guvernului nr.15/2000 cu modificările ulterioare;</p><br>

<p style="text-indent:2em;">b) Convenţia internaţională din 27 noiembrie 1992 privind stabilirea unui Fond internaţional pentru compensarea daunelor provocate
 prin poluarea cu hidrocarburi;</p><br>

<p style="text-indent:2em;">c) Convenţia internaţională din 23 martie 2001 privind răspunderea civilă pentru pagubele produse prin poluarea cu combustibil de
 consum de la nave;</p><br>

<p style="text-indent:2em;">d) Convenţia internaţională din 3 mai 1996 privind răspunderea şi compensaţia pentru daune în legătură cu transportul pe mare al
 substanţelor periculoase şi toxice;</p><br>

<p style="text-indent:2em;">e) Convenţia din 10 octombrie 1989 privind răspunderea civilă pentru pagubele produse în timpul transportului pe şosea, cale
 ferată şi cu vase de navigaţie fluvială a mărfurilor periculoase.</p>

<p style="text-indent:2em;"></p><h6>Instrumentele internaţionale care reglementează răspunderea sau compensarea pentru incidentele din domeniul nuclear:</h6><p></p>
 
<p style="text-indent:2em;">a) Convenţia de la Paris din 29 iulie 1960 privind răspunderea civilă în domeniul energiei nucleare şi Convenţia Suplimentară
 de la Bruxelles din 31 ianuarie 1963;</p><br>

<p style="text-indent:2em;">b) Convenţia de la Viena din 21 mai 1963 privind răspunderea civilă pentru daune nucleare, la care România a aderat prin Legea
 nr. 106/1992 pentru aderarea României la Convenţia privind răspunderea civilă pentru daune nucleare şi la Protocolul comun
 referitor la aplicarea Convenţiei de la Viena şi a Convenţiei de la Paris;</p><br>

<p style="text-indent:2em;">c) Convenţia din 12 septembrie 1997 privind compensaţiile suplimentare pentru daunele nucleare ratificată de România prin Legea
 nr. 5/1999;</p><br>

<p style="text-indent:2em;">d) Protocolul Comun din 21 septembrie 1988 privind punerea în aplicare a Convenţiei de la Viena şi a Convenţiei de la Paris la
 care România a devenit parte prin Legea nr. 106/1992 pentru aderarea României la Convenţia privind răspunderea civilă pentru daune
 nucleare şi la Protocolul comun referitor la aplicarea Convenţiei de la Viena şi a Convenţiei de la Paris;</p><br>

<p style="text-indent:2em;">e) Convenţia de la Bruxelles din 17 decembrie 1971 privind răspunderea civilă în domeniul transportului maritim al
 materialului nuclear.</p><br> 
 
<p style="font-weight:500;">Informaţiile şi datele care se raportează Comisiei Europene</p>
 
<p style="text-indent:2em;"></p><h6>Raportul prevăzut la art. 44 alin.(1) din ordonanţa de urgenţă include o listă a cazurilor de prejudicii asupra mediului
 şi a cazurilor de răspundere conform prezentei legi, şi conţine următoarele informaţii şi date pentru fiecare caz în
 parte:</h6><p></p>

<p style="text-indent:2em;">1. Tipul de prejudiciu asupra mediului, data la care acesta s-a produs şi/sau a fost identificat şi data la care au fost
 iniţiate procedurile prevăzute de prezenta ordonanţă de urgenţă. </p><br>

<p style="text-indent:2em;">2. Codul de clasificare a activităţilor persoanei sau persoanelor juridice responsabile, conform Hotărârii Guvernului nr.
 656/1997 privind aprobarea Clasificării activităţilor din economia naţională – CAEN, cu modificările ulterioare. </p><br>

<p style="text-indent:2em;">3. Iniţierea, după caz, a procedurii de contencios administrativ fie de către părţile responsabile fie de către persoanele
 care au calitate procesuală activă conform prevederilor prezentei ordonanţe de urgenţă. Se specifică categoria reclamanţilor
 şi rezultatele procedurii. </p><br>

<p>4. Rezultatele procesului de reparare. </p><br>

<p style="text-indent:2em;">5. Data încheierii procedurii de contencios administrativ prevăzută la art. 8 alin. (1) şi art. 25 alin. (1) şi a procedurii
 de recuperare a costurilor, prevăzută la art. 32.</p>

<p style="text-indent:2em;"></p><h6>Autoritatea publică centrală pentru protecţia mediului poate solicita agenţiei judeţene pentru protecţia mediului includerea
 în raportul prevăzut la art. 44 a oricăror alte informaţii şi date pe care le consideră utile pentru a permite evaluarea
 corectă a aplicării prezentei ordonanţe de urgenţă, de exemplu:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) Costurile suportate pentru realizarea măsurilor preventive şi reparatorii,
 conform prezentei ordonanţe de urgenţă:</h6><p></p>

<p>(i)	plătite direct de către părţile responsabile, în cazul în care aceste
 informaţii sunt disponibile;</p><br>

<p>(ii) recuperate de la părţile responsabile;</p><br>

<p>(iii) nerecuperate de la părţile responsabile, cu indicarea motivelor pentru care aceste costuri nu au fost
 recuperate. </p><br>

<p style="text-indent:2em;">b) Rezultatele acţiunilor de promovare şi de punere în aplicare a instrumentelor financiare utilizate în conformitate
 cu prezenta ordonanţă de urgenţă. </p><br>

<p style="text-indent:2em;">c) O evaluare a costurilor administrative anuale suplimentare suportate de autorităţile publice pentru crearea şi punerea
 în funcţiune a structurilor administrative necesare pentru implementarea şi controlul aplicării prezentei ordonanţe de
 urgenţă. </p><br>
 
 <p><a href="https://lege5.ro/Gratuit/geydqobvgm/ordonanta-de-urgenta-nr-68-2007-privind-raspunderea-de-mediu-cu-referire-la-prevenirea-si-repararea-prejudiciului-asupra-mediului">Sursa</a></p>

</div>
`