import React from 'react'
import { Route, Switch } from 'react-router-dom'

import EnterEmail from './enter-email'
import NewPassword from './new-password'

export default () => {
  return (
    <Switch>
      <Route path="/auth/recover-password/:token" component={NewPassword} />
      <Route path="/auth/recover-password" component={EnterEmail} />
    </Switch>
  )
}
