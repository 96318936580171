import { compose } from 'recompose'
import { reduxForm } from 'redux-form'
import { addScrollBar } from "../../../store/utils"
import Component from './component'
import withTranslation from '../../../globals/components/withTranslation'
import { changePassword } from '../../../store/actions'

const initialValues = {
  oldPassword: undefined,
  password: undefined,
  password2: undefined,
}

export default compose(
  withTranslation,
  reduxForm({
    form: 'userChangePassword',
    initialValues,
    onSubmit: (values, dispatch, { clearModal }) => {
      dispatch(changePassword(values, () => {
        clearModal()
        addScrollBar()
      }))
    },
  })
)(Component)
