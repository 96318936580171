import _ from 'lodash'
import { getBaseProps } from '../utils'

import fetch from '../../components/shared/fetch'
import * as actionTypes from './action-types'
import { oops, queryInWords } from '../utils'
import { toastr } from 'react-redux-toastr'

interface ITransportSearchParams {
  query?: any
  pageIndex?: number
  pageSize?: number
  sortBy?: string
  dateScope?: string
  activeScope?: string
  log?: boolean
  filterKey?: string
  exactUserId?: number
  sameCountryOnly?: boolean
}

export const saveTransport = (item, cb) => {
  return dispatch => {
    dispatch({ type: actionTypes.TRANSPORT_ADD_SAVING })

    fetch('/transport/add', {
      method: 'POST',
      body: JSON.stringify({ item }),
    })
      .then(json => {
        dispatch({ type: actionTypes.TRANSPORT_ADD_SAVED, payload: json.data })
        cb && cb(json.data)
      })
      .catch(json => {
        dispatch({ type: actionTypes.TRANSPORT_ADD_ERROR })
        toastr.error(json.message)
      })
  }
}

export const saveAsTemplate = ({ name, item }, cb) => {
  return (dispatch, getState) => {
    const state = getState()

    dispatch({ type: actionTypes.TRANSPORT_TEMPLATE_SAVING })

    fetch('/transport/saveAsTemplate', {
      method: 'POST',
      body: JSON.stringify({ name, item, langId: state.ui.langId }),
    })
      .then(json => {
        dispatch({ type: actionTypes.TRANSPORT_TEMPLATE_SAVED, payload: json.d })
        cb && cb()
      })
      .catch(error => dispatch(oops(error, actionTypes.TRANSPORT_TEMPLATE_SAVE_ERROR)))
  }
}

export const getTemplates = cb => {
  return dispatch => {
    dispatch({ type: actionTypes.TRANSPORT_TEMPLATES_LOADING })
    return fetch('/transport/getTemplates')
      .then(json => {
        dispatch({ type: actionTypes.TRANSPORT_TEMPLATES_LOADED, payload: json.d })
        cb && cb()
      })
      .catch(error => dispatch(oops(error, actionTypes.TRANSPORT_TEMPLATES_LOAD_ERROR)))
  }
}

export function loadTransportLastSearch() {
  return (dispatch, getState) => {
    const state = getState()

    const { userId } = state.auth.jwt
    const payload = { userId }

    dispatch({ type: actionTypes.TRANSPORT_LAST_SEARCH_START })

    const data = {
      ...getBaseProps(state),
      ...payload,
    }

    return fetch('/transport/lastSearch', {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(json => dispatch({ type: actionTypes.TRANSPORT_LAST_SEARCH_SUCCESS, payload: json.d }))
      .catch(error => dispatch(oops(error, actionTypes.TRANSPORT_LAST_SEARCH_FAILURE)))
  }
}

export function transportSearch({
  query,
  pageIndex,
  pageSize,
  sortBy,
  dateScope,
  activeScope,
  log,
  filterKey,
  exactUserId,
  sameCountryOnly
}: ITransportSearchParams) {
  return async (dispatch, getState) => {
    const state = getState()
    const isAuthenticated = !!state.auth.accessToken
    const { cargo } = state
    const { userId } = state.auth.jwt
    const { langId } = state.ui
    const win: any = window

    query = query || {}
    sortBy = sortBy || cargo.getIn(['search', 'sortBy'])
    
    const queryStr = await queryInWords(query, langId)
    const payload = {
      userId,
      query,
      queryStr,
      pageIndex,
      pageSize,
      sortBy,
      dateScope,
      activeScope,
      autoupdate: JSON.parse(localStorage.getItem('autoupdate')) || true,
      log,
      socketId: win.socket.id,
      filterKey,
      exactUserId,
      sameCountryOnly
    }
    
    dispatch({ type: actionTypes.TRANSPORT_SEARCH_START, payload })
    
    const data = {
      ...getBaseProps(state),
      ...payload,
    }

    return fetch('/transport/search', {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(json => {
        dispatch({ type: actionTypes.TRANSPORT_SEARCH_SUCCESS, payload: json.d })
        if (isAuthenticated && log === true) {
          dispatch(loadTransportLastSearch())
        }

        return json.d
      })
      .catch(error => dispatch(oops(error, actionTypes.TRANSPORT_SEARCH_FAILURE)))
  }
}

export function deleteTransportLastSearch(searchId) {
  return (dispatch, getState) => {
    const state = getState()

    const { userId } = state.auth.jwt

    const data = {
      ...getBaseProps(state),
      userId,
      searchId,
    }

    return fetch('/transport/deleteSearch', {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(json =>
        dispatch({ type: actionTypes.TRANSPORT_DELETE_LAST_SEARCH_SUCCESS, payload: json.d })
      )
      .catch(err => dispatch(oops(err, actionTypes.TRANSPORT_DELETE_LAST_SEARCH_FAILURE)))
  }
}

export function transportAddFavourite(payload) {
  const { transportId, isItem } = payload
  return (dispatch, getState) => {
    const state = getState()
    const { userId } = state.auth.jwt

    dispatch({ type: actionTypes.TRANSPORT_ADD_FAVOURITE_START })
    return fetch('/transport/addFavourite', {
      method: 'POST',
      body: JSON.stringify({ ...getBaseProps(state), userId, transportId }),
    })
      .then(json => {
        const payload = json.d[0]
        if (isItem) payload.isItem = true
        dispatch({ type: actionTypes.TRANSPORT_ADD_FAVOURITE_SUCCESS, payload })
      })
      .catch(err => dispatch(oops(err, actionTypes.TRANSPORT_ADD_FAVOURITE_FAILURE)))
  }
}

export function transportDeleteFavourite(payload) {
  const { transportId, isItem } = payload
  return (dispatch, getState) => {
    const state = getState()
    const { userId } = state.auth.jwt

    dispatch({ type: actionTypes.TRANSPORT_DELETE_FAVOURITE_START })
    return fetch('/transport/deleteFavourite', {
      method: 'POST',
      body: JSON.stringify({ ...getBaseProps(state), userId, transportId }),
    })
      .then(json => {
        const payload = json.d
        if (isItem) payload.isItem = true
        dispatch({ type: actionTypes.TRANSPORT_DELETE_FAVOURITE_SUCCESS, payload })
      })
      .catch(err => dispatch(oops(err, actionTypes.TRANSPORT_DELETE_FAVOURITE_FAILURE)))
  }
}

function fetchCars({ pageIndex, pageSize, searchText, isCompanyCars, profileUserId }) {
  return fetch('/transport/autopark/list', {
    method: 'POST',
    body: JSON.stringify({
      pageIndex,
      pageSize,
      searchText,
      isCompanyCars,
      profileUserId,
    }),
  })
}
// ------------------------------------------
// newCar
// ------------------------------------------
export const newCar = () => {
  return dispatch => {
    dispatch({ type: actionTypes.TRANSPORT_CAR_NEW })
  }
}
// ------------------------------------------
// loadCars
// ------------------------------------------
export const loadCars = props => {
  const { pageIndex, pageSize, isCompanyCars, profileUserId } = props
  return dispatch => {
    props = _.defaults({
      pageIndex,
      pageSize,
      requestedAt: new Date(),
      searchText: '',
      isCompanyCars,
      profileUserId,
    })
    // set 'loading'
    dispatch({ type: actionTypes.TRANSPORT_CAR_LIST_LOADING, ...props })
    return fetchCars(props)
      .then(json => dispatch({ type: actionTypes.TRANSPORT_CAR_LIST_LOADED, payload: json.d }))
      .catch(error => dispatch(oops(error, actionTypes.TRANSPORT_CAR_LIST_LOAD_ERROR)))
  }
}

// -------------------------------------------------
// loadCar
// -------------------------------------------------
export const loadCar = (itemId, cb) => {
  return dispatch => {
    dispatch({ type: actionTypes.TRANSPORT_CAR_LOADING })
    fetch(`/transport/autopark/${itemId}`)
      .then(json => {
        
        let temperatureFrom = json.d.temperatureFrom
        let temperatureTo = json.d.temperatureTo

        if(json.d.temperatureFrom === "null"){
          temperatureFrom = 0
        }
        if(json.d.temperatureTo === "null"){
          temperatureTo = 0
        }

        const newData = {
          ...json.d,
          temperatureFrom,
          temperatureTo
        }

        dispatch({
          type: actionTypes.TRANSPORT_CAR_LOADED,
          data: newData,
        })
        cb && cb()
      })
      .catch(error => dispatch(oops(error, actionTypes.TRANSPORT_CAR_LOAD_ERROR)))
  }
}

// -------------------------------------------------
// saveCar
// -------------------------------------------------
export const saveCar = ({ item }, cb) => {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({ type: actionTypes.TRANSPORT_CAR_SAVING })
    const payload = {
      item,
      langId: state.ui.langId,
    }
    fetch('/transport/autopark/save', {
      method: 'POST',
      body: JSON.stringify(payload),
    })
      .then(json => {
        dispatch({
          type: actionTypes.TRANSPORT_CAR_SAVED,
          payload: json.d,
        })
        cb && cb()
      })
      .catch(error => dispatch(oops(error, actionTypes.TRANSPORT_CAR_SAVE_ERROR)))
  }
}

// ------------------------------------------
// delete car
// ------------------------------------------
export const deleteCar = (itemId, cb) => {
  //{ itemId, pageIndex, pageSize }) => {
  return dispatch => {
    // const state = getState()
    dispatch({ type: actionTypes.TRANSPORT_CAR_DELETING, requestedAt: new Date(), itemId })
    return fetch(`/transport/autopark/${itemId}`, { method: 'DELETE' })
      .then(json => {
        dispatch({ type: actionTypes.TRANSPORT_CAR_DELETED, data: json.d })
        cb && cb(itemId)
      })
      .catch(error => dispatch(oops(error, actionTypes.TRANSPORT_CAR_DELETE_ERROR)))
  }
}

export const autoparkSetActiveTab = (activeTab) => {
  return dispatch => {
    dispatch({ type: actionTypes.SET_ACTIVE_TAB, activeTab })
  }
}

export const changeActiveTransport = ({ transportId }) => {
  return dispatch => {
    dispatch({ type: actionTypes.TRANSPORT_CHANGE_ACTIVE, payload: { transportId } })
    return fetch('/transport/changeActiveTransport', {
      method: 'POST',
      body: JSON.stringify({
        transportId,
      }),
    }).then(() => {
      fetch(`/user/getStatistics/profile`).then(response => {
        dispatch({ type: actionTypes.USER_GET_STATISTICS, payload: response.data })
      })
    })
  }
}
