export const eu7 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Transport aerian</h4><p></p>

<p></p><h4>Strategia UE în domeniul aviației</h4><p></p>

<p style="font-weight:500;">CARE ESTE OBIECTIVUL ACESTEI COMUNICĂRI?</p><br>

<p style="text-indent:2em;">Comunicarea stabilește strategia UE în domeniul aviației, care este menită să asigure că sectorul aviației din UE rămâne competitiv pe plan global și contribuie la dezvoltarea
 economiei europene.</p>

<p style="text-indent:2em;"></p><h6>Aceasta este însoțită de un plan de acțiune orientativ, compus din:</h6><p></p>

<p>un act legislativ: propunerea de revizuire a normelor UE privind siguranța în domeniul aviației;</p><br>

<p>documente de orientare și o evaluare a legislației existente.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Priorități</p>

<p style="text-indent:2em;"></p><h6>Strategia are patru priorități principale:</h6><p></p>

<p style="text-indent:2em;"></p><h6>1. Să stabilească poziția de actor principal a UE în domeniul aviației mondiale, asigurând în același timp oportunități egale pentru toate companiile din UE:</h6><p></p>

<p>Strategia cuprinde planuri pentru acorduri în domeniul aviației cu țări și regiuni-cheie din întreaga lume.</p>

<p style="text-indent:2em;"></p><h6>2. Să suprime obstacolele în calea dezvoltării capacității aeriene, atât în spațiul aerian, cât și la sol:</h6><p></p>

<p style="text-indent:2em;">Strategia subliniază importanța finalizării proiectului Cerul unic european, prin utilizarea optimă a celor mai aglomerate aeroporturi și monitorizarea conectivității atât între
 țările UE, cât și între țările UE și țările din afara UE.</p>

<p style="text-indent:2em;"></p><h6>3. Să mențină standarde înalte ale UE:</h6><p></p>

<p style="text-indent:2em;">Strategia include măsuri de menținere a standardelor în materie de siguranță, securitate, mediu, chestiuni sociale și drepturile pasagerilor.</p><br>

<p style="text-indent:2em;">De asemenea, există planuri de a reduce povara pe care o reprezintă controalele de securitate și costurile prin tehnologii noi și o abordare bazată pe riscuri.</p>

<p style="text-indent:2em;"></p><h6>4. Să realizeze progrese în inovații, tehnologii noi și investiții:</h6><p></p>

<p style="text-indent:2em;">Strategia urmărește să valorifice pe deplin potențialul dronelor prin propunerea de legi care să garanteze siguranța și securitatea juridică pentru sector, abordând în același timp
 preocupări legate de viața privată, de protecția datelor, de mediu și de securitate.</p><br>

<p style="text-indent:2em;">De asemenea, UE va investi 430 de milioane EUR pe an în SESAR (Programul de cercetare privind managementul traficului aerian în cerul unic european) pentru îmbunătățirea
 managementului controlului traficului aerian.</p><br>

<p style="font-weight:500;">Zgomotul pe aeroporturile din UE</p><br>

<p style="text-indent:2em;">Având în vedere că se preconizează o intensificare a traficului aerian, Uniunea Europeană (UE) a convenit asupra unor norme noi privind modul în care autoritățile iau decizii
 de stabilire a unor restricții de operare referitoare la zgomot pe aeroporturile din UE, pentru a limita efectul deranjant al zgomotului produs de aeronave.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Regulamentul (UE) nr. 598/2014 al Parlamentului European și al Consiliului de stabilire a normelor și a procedurilor cu privire la introducerea restricțiilor de operare referitoare
 la zgomot pe aeroporturile din Uniune în cadrul unei abordări echilibrate și de abrogare a Directivei 2002/30/CE.</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Obiectivul Regulamentului (UE) nr. 598/2014 este de a îmbunătăți situația zgomotului în jurul aeroporturilor din UE, pentru a asigura o compatibilitate sporită între activitățile de
 transport aerian și zonele rezidențiale, în special în cazul zborurilor de noapte. Normele au la bază principiile abordării echilibrate a gestionării zgomotului, stabilită de Organizația
 Aviației Civile Internaționale (OACI), organismul Organizației Națiunilor Unite care se ocupă de aviația civilă internațională.</p><br>

<p style="text-indent:2em;">O restricție de operare poate lua diferite forme, cum ar fi stabilirea unei limite de zgomot sau de mișcare, introducerea unei norme de limitare a exploatării (interzicerea mișcărilor
 sau a operațiilor suplimentare în general sau ale unui anumit tip de aeronavă) sau adoptarea unei interdicții de circulație pentru o perioadă din noapte.</p><br>

<p style="font-weight:500;">Domeniul de aplicare</p><br>

<p style="text-indent:2em;">Normele se aplică numai în cazul aeroporturilor mai mari, cu mai mult de 50 000 de mișcări de aeronave civile pe an calendaristic. Acestea se referă la aeronavele civile, dar exclud
 mișcările din cadrul operațiunilor militare, vamale și de poliție. Stabilirea unor praguri de zgomot specifice ține însă de competența autorităților naționale și locale.</p><br>

<p style="font-weight:500;">Autoritățile competente</p><br>

<p style="text-indent:2em;">Fiecare țară a UE desemnează autorități competente responsabile cu procedurile de urmat în vederea adoptării restricțiilor de operare. Acestea trebuie să fie independente de
 orice parte care ar putea avea un conflict de interese.</p><br>

<p style="font-weight:500;">Dreptul de reexaminare</p><br>

<p style="text-indent:2em;">Înainte de a introduce restricții de operare, autoritățile competente trebuie să le trimită celorlalte state membre, Comisiei Europene și părților interesate un preaviz de
 șase luni. În decurs de trei luni de la primirea notificării, Comisia poate examina cazul. În cazul în care consideră că procedurile nu respectă normele, aceasta notifică
 autoritatea competentă, care, la rândul său, informează Comisia cu privire la măsurile pe care intenționează să le ia.</p><br>

<p style="font-weight:500;">Aspecte referitoare la sănătate</p><br>

<p style="text-indent:2em;">Trebuie să se țină seama de legislația UE referitoare la impacturile zgomotului asupra sănătății umane (Directiva 2002/49/CE) oricând se ia o decizie privind obiectivele de
 reducere a zgomotului.</p><br>

<p style="font-weight:500;">Informații privind performanțele acustice</p><br>

<p style="text-indent:2em;">Deciziile referitoare la restricții de operare trebuie luate pe baza datelor privind performanțele acustice ale aeronavelor individuale puse la dispoziție de către operatori.
 Aceste informații vor fi centralizate într-o bază de date și puse la dispoziția autorităților competente, a companiilor aeriene, a furnizorilor de servicii de navigație aeriană,
 a aeroporturilor și a utilizatorilor aeroporturilor.</p><br>

<p style="font-weight:500;">Evaluarea zgomotului și informarea rezidenților</p><br>

<p style="text-indent:2em;">Autoritățile competente trebuie să asigure monitorizarea regulată a nivelurilor de zgomot din aeroporturile pentru care sunt responsabile. Dacă din evaluarea acestora rezultă că
 restricțiile de operare ar putea fi o măsură de atenuare a zgomotului eficientă din punct de vedere a costurilor, trebuie organizat rapid un proces de consultare, iar părțile interesate
 au la dispoziție trei luni pentru a-și prezenta opiniile înainte de adoptarea restricțiilor.</p><br>

<p style="text-indent:2em;">De asemenea, autoritățile trebuie să se asigure că informațiile privind restricțiile de operare sunt disponibile prompt și gratuit rezidenților locali și autorităților locale.</p><br>

<p style="font-weight:500;">Scoaterea treptată din folosință a aeronavelor zgomotoase</p><br>

<p style="text-indent:2em;">Măsurile de atenuare a zgomotului pot include retragerea aeronavelor mai zgomotoase sau supunerea acestora la restricții suplimentare dintre cele permise de normele OACI. Autoritățile
vor decide în privința ratei anuale de reducere a numărului de mișcări ale aeronavelor respective pentru fiecare operator pe un anumit aeroport, această rată neputând depăși 25 %.</p><br>

<p style="font-weight:500;">Statistici privind transportul aerian de pasageri, mărfuri și poștă</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Pentru a formula politica UE în domeniul aviației, aceasta trebuie să dispună de date statistice comparabile, coerente, sincronizate și regulate privind amploarea și dezvoltarea
 transportului de pasageri, mărfuri și poștă pe calea aerului în cadrul UE. Prin urmare, Regulamentul (CE) nr. 437/2003 vizează stabilirea unei baze statistice solide în acest scop.</p><br>

<p style="text-indent:2em;">Acesta este completat de Regulamentul de punere în aplicare (CE) nr. 1358/2003, care conține informații metodologice importante, cum ar fi categoriile de aeroporturi pe care se
 bazează obligațiile de raportare, definițiile ulterioare și liniile directoare privind codificarea.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Obiectivul Regulamentului (CE) nr. 437/2003 este de a colecta informații fiabile, regulate, actualizate, armonizate și comparabile privind transportul aerian de pasageri, mărfuri
 și poștă. Colectarea datelor comune pe o bază comparabilă sau armonizată face posibilă furnizarea unui sistem integrat care să ofere informații fiabile, coerente și rapide.</p>

<p style="text-indent:2em;"></p><h6>Fiecare țară UE trebuie să colecteze date statistice privind:</h6><p></p>

<p>pasagerii;</p><br>

<p>mărfuri și poștă;</p><br>

<p>zboruri;</p><br>

<p>locuri disponibile pentru pasageri;</p><br>

<p>mișcări aeronave.</p><br>

<p style="text-indent:2em;">Fiecare țară UE trebuie, de asemenea, să colecteze toate datele specificate pentru toate aeroporturile UE de pe teritoriul său, cu peste 150.000 de pasageri pe an. Pentru aeroporturile
 cu pasageri între 15.000 și 150.000, trebuie colectate statistici mai puțin detaliate, în timp ce aeroporturile cu mai puțin de 15.000 de pasageri nu trebuie să raporteze.</p><br>

<p style="text-indent:2em;">Atunci când este posibil, colectarea datelor trebuie să se bazeze pe sursele disponibile, astfel încât să se reducă la minimum sarcina respondenților. În cazul în care o țară din UE
 solicită unui respondent să furnizeze informații, respondentul este obligat să furnizeze informații veridice și complete în termenul stabilit.</p><br>

<p style="text-indent:2em;">Colectarea datelor trebuie să se bazeze pe declarații complete, cu excepția cazului în care Comisia Europeană stabilește alte standarde de precizie. Țările UE trebuie să transmită la
 Eurostat datele rezultate din prelucrarea datelor, inclusiv datele declarate confidențiale.</p><br>

<p style="text-indent:2em;">La cererea Comisiei, țările UE trebuie să comunice toate informațiile referitoare la metodele utilizate în colectarea datelor, precum și orice modificări de fond ale acestor metode.</p><br>

<p style="text-indent:2em;">Regulamentul (CE) nr. 1358/2003 stabilește lista aeroporturilor UE (cu excepția celor care au doar trafic comercial ocazional).</p><br>

<p style="text-indent:2em;">Acesta conține informații metodologice foarte importante - cum ar fi categoriile de aeroporturi - pe baza cărora sunt stabilite obligațiile de raportare, precum și definiții suplimentare
și orientări de codificare.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTELE?</p><br>

<p>Regulamentul (CE) nr. 437/2003 se aplică de la 31 martie 2003.</p><br>

<p>Regulamentul (CE) nr. 1358/2003 se aplică de la 21 august 2003.</p><br>

<p>Operarea serviciilor aeriene: normele UE</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul stabilește normele comune pentru operarea serviciilor de transport aerian în UE, inclusiv acordarea de licențe transportatorilor aerieni din UE și transparența prețurilor.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p>Licențele de operare și închirierea de aeronave</p><br>

<p>Criteriile pentru acordarea și valabilitatea licențelor de operare ale companiilor aeriene din UE sunt armonizate.</p>

<p style="text-indent:2em;"></p><h6>Printre condițiile pentru acordarea și valabilitatea unei licențe de operare în UE se numără:</h6><p></p>

<p>deținerea unui certificat de operator aerian (COA) valabil, în care se menționează activitățile vizate de licența de operare;</p><br>

<p>proprietatea și controlul de către țările UE și resortisanții țărilor UE și îndeplinirea mai multor condiții financiare.</p><br>

<p>Neîndeplinirea condițiilor privind licența de operare poate să ducă, în cele din urmă, la suspendarea sau la retragerea licenței.</p><br>

<p style="text-indent:2em;">Este permisă închirierea aeronavelor înregistrate în UE. Cu toate acestea, închirierea aeronavelor din afara UE este posibilă numai în condiții speciale, de exemplu, pentru a răspunde
 unor nevoi sezoniere de capacitate. În acest caz, transportatorul aerian în cauză din UE trebuie să poată demonstra autorității competente relevante că aeronava respectă toate
 standardele de siguranță ale UE.</p><br>

<p style="font-weight:500;">Transparența prețurilor</p><br>

<p>Companiile aeriene au libertatea de a stabili prețul serviciilor aeriene pe care le prestează pe teritoriul UE.</p><br>

<p style="text-indent:2em;">Prețul final al serviciului aerian – împreună cu termenii și condițiile pentru transport – trebuie să fie publicat astfel încât să includă toate taxele, tarifele de aeroport, impunerile
 și tarifele suplimentare care nu pot fi evitate și sunt previzibile în momentul rezervării. În plus, transportatorii aerieni trebuie să ofere o defalcare a tuturor acestor elemente
 componente inevitabile și previzibile ale prețurilor, care alcătuiesc prețul final.</p><br>

<p style="text-indent:2em;">Suplimentele de preț opționale trebuie să fie comunicate în mod clar, transparent și lipsit de ambiguitate la începutul oricărei rezervări, iar acceptarea lor trebuie să se facă pe
 baza unei alegeri.</p><br>

<p style="text-indent:2em;">Este interzisă discriminarea prin preț pe criterii de naționalitate sau loc de reședință al clientului, precum și de sediu al agentului transportatorului aerian sau al unui alt
 vânzător de bilete.</p><br>

<p style="text-indent:2em;">Țările UE trebuie să asigure respectarea normelor privind prețurile; în caz de încălcare, sancțiunile trebuie să fie eficiente, proporționale și cu efect de descurajare.</p><br>

<p style="font-weight:500;">Obligațiile de serviciu public</p><br>

<p style="text-indent:2em;">O țară a UE poate să impună o obligație de serviciu public pentru o rută aeriană regulată între un aeroport din UE și un aeroport care deservește o zonă periferică sau în dezvoltare
 aflată pe teritoriul său sau pe o rută cu trafic scăzut către un aeroport de pe teritoriul său, dacă asigurarea unor servicii minime pe ruta respectivă este considerată esențială
 pentru dezvoltarea economică și socială a regiunii deservite de aeroport.</p><br>

<p>O țară a UE trebuie să evalueze necesitatea și caracterul adecvat al unei obligații de serviciu public preconizate.</p><br>

<p style="text-indent:2em;">În cazul în care niciun transportator aerian nu este interesat să opereze pe ruta pe care au fost impuse obligațiile, țara în cauză poate să restricționeze accesul la rută la un singur
 transportator aerian pentru o perioadă de până la patru ani și poate să compenseze pierderile operaționale ale acestuia care rezultă din obligația de serviciu public. Selectarea
 operatorului trebuie să se facă printr-o procedură publică de ofertare la nivelul UE.</p><br>

<p style="font-weight:500;">Repartizarea traficului aerian între aeroporturi</p>

<p style="text-indent:2em;"></p><h6>Țările UE pot reglementa repartizarea traficului aerian între aeroporturi, în special cu condiția ca:</h6><p></p>

<p>acestea să deservească același oraș sau aceeași conurbație;</p><br>

<p style="text-indent:2em;">acestea să fie deservite de o infrastructură de transport corespunzătoare și să fie legate între ele și cu orașul sau conurbația pe care o deservesc prin servicii de transport public
 frecvente, fiabile și eficiente;</p><br>

<p style="text-indent:2em;">decizia de reglementare a repartizării traficului aerian să respecte principiile proporționalității și ale transparenței și să fie bazată pe criterii obiective.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Regulamentul a intrat în vigoare la 1 noiembrie 2008.</p><br>

<p style="font-weight:500;">Asigurarea de răspundere a operatorilor de transport aerian</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul stabilește cerințele minime de asigurare a operatorilor de transport aerian și a operatorilor de aeronave pentru răspunderea față de pasageri, bagaje, mărfuri
 și terțe părți.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Domeniu de aplicare</p>

<p style="text-indent:2em;"></p><h6>Prezentul regulament se aplică tuturor operatorilor de transport aerian și tuturor operatorilor de aeronave care efectuează zboruri în cadrul, spre, din sau deasupra teritoriului
 unei țări a UE. Toți operatorii de transport aerian și toți operatorii de aeronave trebuie să fie asigurați, în special față de:</h6><p></p>

<p>pasageri;</p><br>

<p>bagaje;</p><br>

<p>mărfuri și părți terțe;</p><br>

<p style="text-indent:2em;">riscurile asociate cu răspunderea specifică activității aeriene (inclusiv actele de război, terorismul, deturnarea, actele de sabotaj, sechestrarea ilegală a aeronavei și
 rebeliunea civilă).</p>

<p style="text-indent:2em;"></p><h6>Regulamentul nu se aplică:</h6><p></p>

<p>aeronavelor de stat (aeronave militare, vamale sau de poliție);</p><br>

<p>aeronavelor cu o masă maximă la decolare (MMD)* mai mică de 20 kg;</p><br>

<p>aparatelor de zbor cu decolare propulsată de picior (inclusiv paraplanoare și deltaplane cu motor);</p><br>

<p>baloanelor captive;</p><br>

<p>zmeielor;</p><br>

<p>parașutelor (inclusiv parașutele ascensionale);</p><br>

<p style="text-indent:2em;">avioanelor, inclusiv planoarele, cu motor, cu o MMD mai mică de 500 kg și microlămpi, care sunt utilizate în scop necomercial sau pentru o instrucție de zbor locală care nu implică
 traversarea frontierelor internaționale (în măsura în care aceasta privește obligațiile de asigurare prevăzute în prezentul regulament pentru riscuri de război și terorism).

</p><p style="font-weight:500;">Respectarea regulamentului</p><br>

<p style="text-indent:2em;">Operatorii de transport aerian și, în cazul în care este necesar, operatorii de aeronave trebuie să facă dovada respectării regulamentului prin depunerea la autoritățile competente
 ale țării UE în cauză* a unui certificat de asigurare sau a altui document de asigurare valabil.</p><br>

<p style="font-weight:500;">Asigurarea privind răspunderea față de pasageri, bagaje și mărfuri</p><br>

<p style="text-indent:2em;">În ceea ce privește răspunderea față de pasageri, cuantumul minim al asigurării este de 250 000 DST* pe pasager. Cu toate acestea, în ceea ce privește operațiunile necomerciale ale
 aeronavelor cu o MMD de 2 700 kg sau mai puțin, țările UE pot fixa un nivel inferior al cuantumului minim al asigurării, dar acesta să nu fie mai mic de 100 000 DST pe pasager.</p><br>

<p style="text-indent:2em;">În ceea ce privește răspunderea pentru bagaje, cuantumul minim al asigurării trebuie să fie de 1 131 DST pe pasager pentru operațiunile comerciale.</p><br>

<p style="text-indent:2em;">În ceea ce privește răspunderea pentru mărfuri, cuantumul minim al asigurării trebuie să fie de 19 DST pe kilogram pentru operațiunile comerciale.</p><br>

<p style="font-weight:500;">Asigurarea privind răspunderea față de terțe părți</p><br>

<p>Cuantumul minim al asigurării pe accident și pe aeronavă depinde de MMD a aeronavei.</p><br>

<p style="font-weight:500;">Punerea în aplicare și sancțiuni</p><br>

<p style="text-indent:2em;">Țările UE trebuie să se asigure că operatorii de transport aerian și operatorii de aeronave respectă regulamentul. Orice sancțiuni în caz de încălcare trebuie să fie eficace,
 proporționale și disuasive.</p><br>

<p>Pentru operatorii de transport aerian din UE, sancțiunile pot include retragerea licenței de funcționare.</p><br>

<p style="text-indent:2em;">În ceea ce privește operatorii de transport aerian din afara UE și operatorii de aeronave care utilizează aeronave înregistrate în afara UE, sancțiunile pot include refuzarea dreptului
 de aterizare pe teritoriul unei țări a UE.</p><br>

<p style="text-indent:2em;">Dacă o țară a UE nu este mulțumită de respectarea condițiilor, aceasta trebuie să interzică decolarea unei aeronave până la prezentarea de către operatorul de transport aerian sau
 operatorul de aeronave în cauză a dovezilor existenței unei asigurări adecvate.</p><br>

<p style="text-indent:2em;">Pentru a lua în considerare inflația, Regulamentul (UE) nr. 285/2010 a revizuit plafoanele de compensații din Regulamentul (CE) nr. 785/2004.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Regulamentul se aplică începând cu 30 aprilie 2005.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="text-indent:2em;">Masa maximă la decolare: aceasta corespunde unei valori certificate specifice pentru toate tipurile de aeronave, fiind indicată în certificatul de navigabilitate a aeronavei.</p><br>

<p style="text-indent:2em;">Țara UE în cauză: țara UE care a acordat licența de funcționare operatorului de transport aerian din UE sau în care este înregistrată aeronava operatorului. În cazul operatorilor de
 transport aerian din afara UE și a operatorilor de aeronave care utilizează aeronave înregistrate în afara UE, aceasta înseamnă țara UE spre care sau din care se efectuează zborurile.</p><br>

<p style="text-indent:2em;">DST: un drept special de tragere sau o creanță virtuală asupra monedelor aflate în circulație liberă în țările membre ale Fondului Monetar Internațional (DST conform definiției Fondului
 Monetar Internațional).</p><br>

<p style="font-weight:500;">Sistemul european de nouă generație pentru gestionarea traficului aerian (SESAR)</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul înființează proiectul SESAR [de cercetare privind gestionarea traficului aerian (ATM) pe cerul unic european].</p><br>

<p style="text-indent:2em;">Obiectivul SESAR este de a îmbunătăți performanțele ATM modernizând și armonizând sistemele ATM prin definirea, dezvoltarea, validarea și punerea în aplicare a unor soluții tehnologice și
 operaționale inovatoare privind ATM.</p><br>

<p style="text-indent:2em;">Întreprinderea comună (IC) SESAR este un parteneriat între sectorul public și cel privat care se ocupă de faza de dezvoltare a proiectului SESAR. Aceasta a fost înființată în 2007 de 
Uniunea Europeană și Eurocontrol, Organizația Europeană pentru Siguranța Navigației Aeriene.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE PRIVIND IC SESAR</p><br>

<p style="text-indent:2em;">Aceasta este o întreprindere comună responsabilă de faza de dezvoltare a proiectului SESAR, componenta tehnologică (de cercetare și dezvoltare – CD) a cerului unic european (SES), un 
pachet de măsuri conceput pentru a întruni cerințele viitoare privind capacitatea și siguranța navigației aeriene.</p><br>

<p style="text-indent:2em;">Sarcina sa este să ofere UE, în conformitate cu Planul general ATM, o infrastructură deosebit de performantă pentru controlul traficului aerian până în 2035. Scopul este de a face posibil
 un transport aerian mai sigur, mai ecologic și mai eficient, adică efectuarea mai multor zboruri directe cu un consum redus de combustibil și cu mai puține întârzieri.</p><br>

<p style="text-indent:2em;">La inițiativă participă Comisia Europeană, Eurocontrol, prestatori de servicii de navigație aeriană, organizații ale pasagerilor aerieni, utilizatori ai spațiului aerian, autorități de 
reglementare, operatori aeroportuari, membri ai personalului companiilor aeriene, al aeroporturilor și de gestionare a traficului aerian, oameni de știință și producători de echipamente
 (aproximativ 3 000 de experți). IC este un organism al UE creat în acest scop în temeiul articolului 187 din Tratatul privind funcționarea Uniunii Europene.</p><br>

<p style="text-indent:2em;">Inițiativa a prezentat deja un plan general ATM (actualizat în 2012 și 2015), care definește conținutul, dezvoltarea și planurile de desfășurare pentru generația următoare de sisteme ATM.</p><br>

<p style="text-indent:2em;">IC gestionează procedurile de acordare de granturi membrilor, în conformitate cu normele de participare la programul-cadru de CD al UE Orizont 2020, în urma prelungirii, în 2014, a 
duratei de existență a inițiativei.</p>

<p style="text-indent:2em;"></p><h6>Printre realizările de până acum ale IC SESAR se numără:</h6><p></p>

<p style="text-indent:2em;">primul zbor cvadridimensional (3D + timp) din lume, având ca scop îmbunătățirea schimbului de informații privind traiectoria de la un capăt la celălalt;</p><br>

<p style="text-indent:2em;">un set de instrumente extins pentru sporirea siguranței pe pistele aeroporturilor și
libertatea de planificare/replanificare a rutei pentru a reduce zborul și emisiile generate de combustibili.</p>

<p style="text-indent:2em;"></p><h6>Începând din 2014, programul de cercetare și inovare (CI) al IC SESAR se concentrează asupra unor domenii precum:</h6><p></p>

<p>servicii optimizate ale rețelei ATM;</p><br>

<p>servicii de trafic aerian avansate;</p><br>

<p>operațiuni aeroportuare deosebit de performante;</p><br>

<p>o infrastructură aviatică avansată, integrată și raționalizată.</p><br>

<p style="font-weight:500;">Finanțarea</p><br>

<p style="text-indent:2em;">Contribuția UE pentru faza de dezvoltare a SESAR este de 700 de milioane EUR în cadrul programelor TEN-T și PC7, la care se adaugă 585 de milioane EUR în cadrul programului Orizont 
2020. Pentru a lua în considerare durata programului Orizont 2020, toate cererile de propuneri ale SESAR trebuie lansate până la 31 decembrie 2020.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Directiva a intrat în vigoare la 3 martie 2007.</p><br>

<p style="font-weight:500;">Cadrul pentru crearea Cerului unic european</p><br>

<p style="text-indent:2em;">Cerul unic european desemnează un set de măsuri menite să răspundă nevoilor viitoare în termeni de capacitate și de securitate aeriană. Acesta se aplică atât sectorului civil, 
cât și militar și face referire la aspectele normative, economice, de securitate, de mediu, tehnologice și instituționale ale aviației. Se urmărește renunțarea la un mod de 
organizare a gestionării traficului aerian care nu a cunoscut evoluții din anii 1960 și care este, în mare parte, responsabil pentru congestionarea actuală a traficului aerian.</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Prezentul regulament face parte dintr-un pachet legislativ cu privire la gestionarea traficului aerian menit să creeze un cer unic european începând cu 31 decembrie 2004. Obiectivul
 cerului unic european este de a asigura o utilizare optimă a spațiului aerian european, care să poată răspunde exigențelor tuturor utilizatorilor săi.</p><br>

<p style="text-indent:2em;">Pachetul legislativ cuprinde acest regulament-cadru și trei regulamente tehnice privind dispoziții referitoare la serviciile de navigație aeriană, organizarea și utilizarea spațiului
 aerian și interoperabilitatea rețelei europene de management al traficului aerian. Aceste regulamente sunt menite, în special, să îmbunătățească și să consolideze securitatea și să
 restructureze spațiul aerian în funcție de trafic și nu de frontierele naționale.</p><br>

<p style="text-indent:2em;">Obiectivul prezentului regulament este de a consolida normele actuale de securitate și eficiența globală a traficului aerian general în Europa, de a optimiza capacitățile, prin 
conformarea cu cerințele tuturor utilizatorilor spațiului aerian și reducerea la maxim a întârzierilor.</p><br>

<p style="font-weight:500;">Autoritățile naționale de supraveghere</p><br>

<p style="text-indent:2em;">Statele membre trebuie să numească sau să stabilească, împreună sau individual, unul sau mai multe organisme în calitate de autoritate națională de supraveghere care să își asume 
atribuțiile primite. Aceste autorități trebuie să fie independente de furnizorii de servicii de navigație aeriană.</p><br>

<p style="font-weight:500;">Comitetul pentru cerul unic</p><br>

<p style="text-indent:2em;">Comitetul pentru cerul unic este instituit la intrarea în vigoare a prezentului regulament pentru a asista Comisia în gestionarea cerului unic și a veghea la respectarea intereselor
 tuturor categoriilor de utilizatori ai spațiului aerian. Comitetul este compus din doi reprezentanți din fiecare țară membră UE și este prezidat de un reprezentant al Comisiei.</p><br>

<p style="font-weight:500;">Aspecte militare</p><br>

<p style="text-indent:2em;">Statele membre au adoptat o declarație de ansamblu referitoare la chestiunile militare legate de cerul unic european. Conform declarației, statele membre trebuie să intensifice 
cooperarea civil-militară dacă toate statele interesate consideră acest lucru necesar.</p><br>

<p style="font-weight:500;">Organul consultativ sectorial</p><br>

<p>Organul consultativ sectorial consiliază Comisia cu privire la punerea în aplicare a cerului unic european.</p><br>

<p style="text-indent:2em;">Acesta este compus din reprezentanți ai furnizorilor de servicii de navigație aeriană, ai asociațiilor utilizatorilor spațiului aerian, ai operatorilor din aeroporturi, ai 
industriei aeronautice și ai organismelor de reprezentare a personalului.</p><br>

<p style="font-weight:500;">Norme de punere în aplicare</p><br>

<p style="text-indent:2em;">Eurocontrol este asociat la elaborarea normelor de punere în aplicare care intră în domeniul său de competențe, pe baza mandatelor convenite cu Comitetul pentru cerul unic.</p><br>

<p style="font-weight:500;">Analiza performanțelor</p>

<p style="text-indent:2em;"></p><h6>Regulamentul de punere în aplicare (UE) nr. 390/2013 al Comisiei stabilește un sistem de evaluare a performanțelor care vizează îmbunătățirea performanțelor serviciilor de 
navigație aeriană și a funcțiilor rețelelor de pe cerul unic European. Acesta constă în:</h6><p></p>

<p>obiective europene de performanță în domenii-cheie precum siguranța, mediul, capacitatea și randamentul;</p><br>

<p>planuri naționale care prevăd obiective de performanță în vederea asigurării coerenței cu obiectivele europene;</p><br>

<p>evaluarea periodică și monitorizarea performanțelor serviciilor de navigație aeriană și a funcțiilor rețelei.</p><br>

<p style="font-weight:500;">Măsuri de salvgardare</p><br>

<p style="text-indent:2em;">În final, acest regulament nu împiedică un stat membru să aplice măsuri justificate pentru salvgardarea intereselor esențiale care țin de politica de securitate și de 
apărare.</p><br>

<p style="font-weight:500;">Cerul unic european - normele UE privind serviciile de navigație aeriană</p><br>

<p style="text-indent:2em;">Regulamentul stabilește cerințe pentru prestarea în condiții de siguranță și eficiență a serviciilor de navigație aeriană pentru traficul aerian general în Uniunea Europeană
 (UE) ca parte a inițiativei privind cerul unic european*. Este un regulament obligatoriu, aplicabil tuturor țărilor UE.</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul stabilește cerințe pentru prestarea în condiții de siguranță și eficiență a serviciilor de navigație aeriană pentru traficul aerian general în Uniunea Europeană 
(UE) ca parte a inițiativei privind cerul unic european*. Este un regulament obligatoriu, aplicabil tuturor țărilor UE.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Regulamentul subliniază procedurile de certificare a prestatorilor de servicii de navigație aeriană care trebuie adoptate de către autoritățile de supraveghere din țările UE. 
Certificatele, valabile în toate țările UE, specifică drepturile și obligațiile prestatorilor de servicii de navigație aeriană. Acestea includ accesul fără discriminare la 
servicii pentru utilizatorii spațiului aerian* și în special cu privire la siguranță și la posibilitatea de a oferi servicii altor prestatori, utilizatorilor spațiului aerian
 și aeroporturilor din UE.</p><br>

<p style="font-weight:500;">Cerințe pentru certificare</p>

<p style="text-indent:2em;"></p><h6>Acestea includ:</h6><p></p>

<p>— competență și aptitudine;</p><br>

<p>— sisteme pentru gestionarea securității și a calității;</p><br>

<p>— sisteme de raportare și capacitate financiară;</p><br>

<p>— răspundere, acoperirea riscurilor și siguranță;</p><br>

<p>— structură de proprietate și organizatorică, inclusiv prevenirea conflictelor de interese;</p><br>

<p>— resurse umane, inclusiv planuri de personal.</p><br>

<p style="font-weight:500;">Autoritățile naționale de supraveghere</p><br>

<p style="text-indent:2em;">Fiecare autoritate națională de supraveghere din țările UE este responsabilă pentru desemnarea unui deținător al certificatului pentru prestarea de servicii de trafic aerian 
în exclusivitate. De asemenea, autoritățile asigură conformitatea blocurilor spațiului aerian pe care le controlează. Aceasta se realizează în comun (sau prin acord) dacă 
blocul se extinde peste mai multe țări sau dacă un deținător de certificat operează în mai multe țări.</p><br>

<p style="text-indent:2em;">Dacă prestatorul nu îndeplinește cerințele, autoritățile în cauză pot lua măsuri, inclusiv revocarea certificatului. Autoritățile devin apoi responsabile pentru asigurarea 
continuității serviciului.</p><br>

<p style="font-weight:500;">Blocurile funcționale ale spațiului aerian</p><br>

<p style="text-indent:2em;">Țările UE și din afara UE, unde este cazul, trebuie să asigure prin acord reciproc punerea în aplicare a blocurilor funcționale ale spațiului aerian. Acestea trebuie să aibă 
capacitatea și eficiența necesare pentru a menține un înalt nivel de siguranță și un impact redus asupra mediului. Un coordonator al sistemului de blocuri funcționale ale spațiului 
aerian poate fi numit de către Comisia Europeană (CE) pentru a ajuta în cadrul acestui proces.</p><br>

<p>De asemenea, autoritățile naționale pot desemna un prestator exclusiv de servicii meteorologice.</p><br>

<p style="text-indent:2em;">Pentru traficul aerian general*, prestatorii de servicii de navigație aeriană, utilizatorii spațiului aerian și aeroporturile fac schimb de date operaționale în timp real doar pentru 
a răspunde necesităților operaționale. Se întocmesc aranjamente scrise aprobate pentru oficializarea aranjamentelor de lucru dintre prestatorii de servicii, precum și cu autoritățile 
militare.</p><br>

<p style="text-indent:2em;">Regulamentul solicită proceduri contabile deschise și stabilește orientări detaliate pentru stabilirea echitabilă și transparentă a tarifelor serviciilor de navigație aeriană pentru 
utilizatorii spațiului aerian.</p><br>

<p style="font-weight:500;">CÂND INTRĂ REGULAMENTUL ÎN VIGOARE?</p><br>

<p>Regulamentul a intrat în vigoare în 20 aprilie 2004 și a fost modificat în 2009.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="text-indent:2em;">Cerul unic european: o inițiativă prin care configurarea, gestionarea și reglementarea spațiului aerian sunt coordonate prin intermediul UE.</p><br>

<p>Utilizatorii spațiului aerian: operatorii de aeronave utilizate pentru traficul aerian general.</p><br>

<p style="text-indent:2em;">Traficul aerian general: zborurile efectuate conform normelor și procedurilor Organizației Aviației Civile Internaționale (OACI). Acestea pot include unele zboruri militare.</p><br>

<p style="font-weight:500;">Acordurile de reglementare a serviciilor aeriene dintre țările UE și țările din afara UE</p><br>

<p style="font-weight:500;">CARE ESTE OBIECTIVUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul stabilește o procedură pentru notificarea și autorizarea negocierilor purtate de țările Uniunii Europene (UE) cu țări din afara UE cu privire la acordurile bilaterale 
de reglementare a serviciilor aeriene (ASA).</p><br>

<p>Obiectivul regulamentului este de a asigura că acordurile existente respectă legislația UE.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Competența UE</p><br>

<p style="text-indent:2em;">În noiembrie 2002, Curtea de Justiție a Uniunii Europene a confirmat dreptul exclusiv al UE de a negocia, semna și încheia ASA internaționale cu țările din afara UE când acordurile 
se referă la chestiuni care sunt de competența exclusivă a UE.</p><br>

<p style="text-indent:2em;">La data adoptării hotărârii Curții, exista un număr mare de ASA care nu respectau legislația UE. De atunci, un număr mare dintre acestea au fost aduse în conformitate cu legislația UE.</p><br>

<p style="font-weight:500;">Obiectiv</p>

<p style="text-indent:2em;"></p><h6>Acele ASA care nu respectă legislația UE trebuie să fie modificate pentru:</h6><p></p>

<p>a se asigura securitatea juridică în astfel de acorduri pentru ambele părți;</p><br>

<p style="text-indent:2em;">a se garanta tuturor companiilor aeriene din UE dreptul de stabilire în cadrul UE, inclusiv accesul nediscriminatoriu pe piață la rutele dintre toate țările UE și țările din afara 
UE în care existau ASA.</p><br>

<p style="font-weight:500;">Modificarea unui ASA</p>

<p style="text-indent:2em;"></p><h6>Există două moduri de modificare a unui ASA:</h6><p></p>

<p style="text-indent:2em;">Acordurile orizontale – care acționează în numele țărilor UE în cauză care au ASA bilaterale cu o țară din afara UE, Comisia Europeană negociază un acord unic cu acea țară din afara UE.</p><br>

<p>Negocierile bilaterale – care modifică sau înlocuiesc fiecare ASA individual.</p><br>

<p style="font-weight:500;">Norme și procedură pentru negocieri bilaterale</p>

<p style="text-indent:2em;"></p><h6>O țară a UE poate iniția negocieri bilaterale pentru a modifica sau înlocui un ASA care este parțial de competența UE dacă respectă normele și procedura stabilite în regulament:</h6><p></p>

<p style="text-indent:2em;">negocierile să includă clauzele standard relevante, concepute în comun de țările UE și Comisie și să se respecte procedura de notificare;</p><br>

<p>țara notifică în scris intențiile sale Comisiei.</p><br>

<p style="text-indent:2em;">Dacă, în termen de 15 zile lucrătoare de la primirea notificării, Comisia stabilește că negocierile ar putea submina obiectivele negocierilor UE care se desfășoară deja cu țara din 
afara UE și/sau ar putea conduce la un acord incompatibil cu dreptul UE, Comisia trebuie să informeze țara UE în consecință.</p><br>

<p style="text-indent:2em;">O țară a UE nu poate încheia cu o țară din afara UE niciun acord nou care reduce numărul operatorilor de transport aerian din UE autorizați să furnizeze servicii între teritoriul 
său și țara în cauză.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Regulamentul a intrat în vigoare la 30 mai 2004.</p><br>

<p style="font-weight:500;">Acordurile UE-SUA în materie de aviație</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DECIZII?</p><br>

<p style="text-indent:2em;">Prin această decizie, Uniunea Europeană (UE) aprobă semnarea Acordului privind transportul aerian negociat cu Statele Unite ale Americii.</p><br>

<p>Acordul prevede o deschidere totală a rutelor transatlantice pentru companiile aeriene din UE și din SUA.</p><br>

<p style="text-indent:2em;">De asemenea, acesta cuprinde un mecanism pentru dezvoltarea ulterioară a acordului cu privire la aspecte precum proprietatea și controlul asupra companiilor aeriene.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p>Accesul la piață: drepturile de trafic și chestiunile comerciale/operaționale</p>

<p style="text-indent:2em;"></p><h6>Acordul permite companiilor aeriene din UE:</h6><p></p>

<p style="text-indent:2em;">să opereze zboruri către SUA din orice aeroport din UE, indiferent de locul de stabilire a acestora pe teritoriul UE (conceptul de „operator de transport comunitar”);</p><br>

<p style="text-indent:2em;">să opereze pe rute internaționale între UE și SUA (drepturile celei de a treia* și celei de a patra*libertăți) și pe rute din afara UE și SUA (drepturile celei de a cincea 
libertăți*) fără restricții privind numărul de zboruri sau tipul de aeronavă;</p><br>

<p style="text-indent:2em;">să opereze servicii de transport de mărfuri conform celei de a șaptea libertăți* (deși, pentru companiile aeriene din SUA, nu vor exista drepturi la servicii de transport 
exclusiv de mărfuri, conform celei de a șaptea libertăți, în plus față de cele acordate anterior de către opt țări ale UE);</p><br>

<p style="text-indent:2em;">să opereze zboruri limitate conform celei de a șaptea libertăți pentru servicii de transport de pasageri între SUA și orice punct din spațiul aerian comun european (SACE)* 
(deși companiilor aeriene din SUA nu li se acordă astfel de drepturi).</p><br>

<p style="text-indent:2em;">Acordul permite libera tarifare (deși operatorii de transport din SUA nu pot stabili prețuri pe rutele din interiorul UE) și cuprinde norme detaliate privind franciza și 
atribuirea unui nume de marcă pentru a le permite companiilor aeriene din UE să extindă prezența rețelei lor pe piața din SUA.</p><br>

<p style="text-indent:2em;">De asemenea, acesta permite partajarea nelimitată a codurilor (în cazul în care două sau mai multe companii aeriene pot partaja același zbor) și noi oportunități pentru 
companiile aeriene din UE de a furniza aeronave cu echipaj (în baza unui acord de închiriere cu echipaj) companiilor aeriene din SUA pe rute internaționale.</p><br>

<p style="font-weight:500;">Accesul la piață: proprietatea și controlul</p>

<p style="text-indent:2em;"></p><h6>Companiile aeriene din SUA garantează:</h6><p></p>

<p style="text-indent:2em;">deținerea unui procent admisibil de către resortisanții UE, incluzând posibilitatea de a depăși 50 % din numărul total de acțiuni;</p><br>

<p>examinarea rapidă și imparțială a tranzacțiilor care implică investiții din partea UE în companiile aeriene din SUA.</p>

<p style="text-indent:2em;"></p><h6>Companiile aeriene din UE garantează:</h6><p></p>

<p style="text-indent:2em;">Dreptul de a limita investițiile SUA în companiile aeriene din UE la 25 % din acțiunile cu drept de vot (reflectând sistemul SUA);</p><br>

<p>acceptarea de către SUA a oricărei companii aeriene din UE care este deținută sau controlată de cetățenii UE sau SACE.</p>

<p style="text-indent:2em;"></p><h6>Companiile aeriene din țările din afara UE garantează:</h6><p></p>

<p style="text-indent:2em;">acceptarea unilaterală de către SUA a proprietății și a controlului de către UE asupra oricărei companii aeriene din Spațiul Economic European, SACE și 18 țări africane.</p><br>

<p>Comitetul mixt instituit în temeiul acordului îndeplinește un rol în aspectele referitoare la proprietate și la control.</p><br>

<p style="font-weight:500;">Cooperarea în materie de reglementare</p><br>

<p>Acordul consolidează, de asemenea, cooperarea între cele două părți în domeniile următoare.</p><br>

<p style="text-indent:2em;">Securitate: colaborarea în vederea adoptării unor practici și a unor standarde compatibile și a reducerii semnificative a divergențelor de reglementare legate de măsurile 
de securitate.</p><br>

<p style="text-indent:2em;">Siguranță: îmbunătățirea consultării și a cooperării în cazul unor preocupări legate de siguranță exprimate de oricare dintre părți.</p><br>

<p style="text-indent:2em;">Politica în domeniul concurenței: angajamentul de a coopera la punerea în aplicare a regimurilor de concurență în cadrul acordurilor care au un impact asupra pieței transatlantice 
și de a promova abordări normative compatibile în acorduri.</p><br>

<p style="text-indent:2em;">Subvențiile de stat: recunoașterea faptului că acestea pot afecta capacitatea companiilor aeriene de a concura în mod loial și echitabil și necesitatea încheierii unor înțelegeri 
care să permită exprimarea preocupărilor legate de subvenții.</p><br>

<p style="text-indent:2em;">Mediul: recunoașterea importanței protejării mediului și a intențiilor de a îmbunătăți cooperarea tehnică pentru reducerea emisiilor în sectorul transporturilor aeriene și de a 
spori eficiența carburanților.</p><br>

<p style="text-indent:2em;">Acordul a inclus și o foaie de parcurs clară stabilind o listă neexhaustivă a „elementelor de interes prioritar” pentru negocierea unui acord în a doua etapă.</p><br>

<p style="font-weight:500;">Acordul în a doua etapă</p><br>

<p style="text-indent:2em;">Negocierile suplimentare lansate în 2008 între UE și SUA au dus la semnarea unui acord in a doua etapă în 2010. Acest protocol se bazează pe primul acord și cuprinde oportunități 
suplimentare privind investițiile și accesul la piață. De asemenea, acesta consolidează cadrul de cooperare în domenii de reglementare precum siguranța, securitatea, aspectele 
sociale și, în special, mediul, ambele părți convenind asupra unei declarații comune privind mediul.</p><br>

<p>Norvegia și Islanda au aderat la acord în 2011.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DECIZIA?</p><br>

<p style="text-indent:2em;">Decizia se aplică de la 25 aprilie 2007. Articolul 25 din Acordul privind transportul aerian referitor la aplicarea provizorie indică faptul că părțile convin să îl pună 
în aplicare de la 30 martie 2008.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="text-indent:2em;">Drepturile celei de a treia libertăți: în contextul serviciilor aeriene internaționale regulate, dreptul sau privilegiul acordat de o țară altei țări, de a debarca, pe teritoriul 
primei țări, trafic provenind din țara de origine a operatorului de transport.</p><br>

<p style="text-indent:2em;">Drepturile celei de a patra libertăți: în contextul serviciilor aeriene internaționale regulate, dreptul sau privilegiul acordat de o țară altei țări, de a îmbarca, pe teritoriul 
primei țări, trafic destinat țării de origine a operatorului de transport.</p><br>

<p style="text-indent:2em;">Drepturile celei de a cincea libertăți: în contextul serviciilor aeriene internaționale regulate, dreptul sau privilegiul acordat de o țară altei țări, de a debarca și de a îmbarca, 
pe teritoriul primei țări, trafic care provine dintr-o țară din afara UE sau care are ca destinație o astfel de țară.</p><br>

<p style="text-indent:2em;">Drepturile celei de a șaptea libertăți: în contextul serviciilor aeriene internaționale regulate, dreptul sau privilegiul acordat de o țară altei țări, de a transporta trafic între 
teritoriul țării care acordă acest drept și orice țară din afara UE. Aceasta nu impune ca serviciul să fie în corespondență cu vreun serviciu sau să fie o prelungire a vreunui 
serviciu care este destinat/care provine din țara de origine a operatorului de transport.</p><br>

<p style="text-indent:2em;">Spațiul aerian comun european: include țările UE, Albania, Bosnia și Herțegovina, fosta Republică Iugoslavă a Macedoniei, Islanda, Muntenegru, Norvegia, Serbia și Administrația 
Interimară ONU în Kosovo.</p><br>

<p style="font-weight:500;">Convenția de la Montreal privind răspunderea operatorilor de transport aerian</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTE CONVENȚII ȘI AL ACESTEI DECIZII?</p><br>

<p style="text-indent:2em;">Convenția are obiectivul de a introduce norme juridice uniforme de guvernare a răspunderii operatorilor de transport aerian în caz de daune provocate pasagerilor, bagajelor sau 
mărfurilor pe parcursul călătoriilor internaționale.</p><br>

<p>Decizia încheie convenția în numele Comunității Europene (în prezent UE).</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Convenția introduce principii și norme juridice cuprinzătoare, dintre care cele mai importante sunt:</h6><p></p>

<p>adoptarea principiului răspunderii civile nelimitate a operatorului de transport aerian în caz de vătămare corporală;</p> 

<p style="text-indent:2em;"></p><h6>clasificat pe două niveluri:</h6><p></p>

<p style="text-indent:2em;">un prim nivel de răspundere obiectivă a operatorului de transport pentru daune până la 100 000 DST (drepturi speciale de tragere definite de Fondul Monetar Internațional, 
adică aproximativ 120 000 EUR);</p><br>

<p style="text-indent:2em;">peste acest cuantum, un nivel secundar de răspundere bazat pe presupusa culpă a operatorului de transport, de care poate fi exonerat doar prin dovedirea faptului că accidentul 
nu s-a produs din vina sa (sarcina probei revine operatorului de transport);</p><br>

<p style="text-indent:2em;">recunoașterea principiului plăților în avans în caz de vătămare corporală, pentru a permite victimelor sau succesorilor acestora să își acopere necesitățile economice imediate;</p><br>

<p style="text-indent:2em;">posibilitatea victimei sau a succesorilor acesteia de a se adresa instanțelor din statul în care pasagerul își are reședința principală;</p><br>

<p style="text-indent:2em;">mărirea limitelor de răspundere a operatorului de transport aerian în caz de întârzieri și de deteriorare a bagajelor (întârziere, pierdere sau deteriorare);</p><br>

<p>modernizarea documentelor de transport (bilete și documente de transport aerian electronice);</p><br>

<p style="text-indent:2em;">clarificarea normelor care reglementează răspunderea transportatorului contractual (operatorul de transport aerian a cărui denumire sau cod apare pe biletul de avion) și a 
transportatorului efectiv (este posibil ca transportatorul care efectuează zborul să fie diferit de operatorul de transport aerian contractual);</p><br>

<p>instituirea pe scară largă a obligației de asigurare adecvată a operatorilor de transport aerian;</p><br>

<p style="text-indent:2em;">introducerea unei așa-numite clauze „regionale”, care să permită organizațiilor de integrare economică, cum ar fi UE, să adere la noua convenție.</p><br>

<p style="text-indent:2em;">UE a adoptat în 1997 Regulamentul (CE) nr. 2027/97 (privind răspunderea operatorilor de transport aerian în caz de accidente), care impune o răspundere nelimitată a operatorilor 
de transport aerian din UE în caz de deces sau vătămare a călătorilor. Regulamentul (CE) nr. 889/2002 modifică Regulamentul (CE) nr. 2027/97 și aplică normele Convenției de la Montreal 
tuturor zborurilor, interne sau internaționale, efectuate de operatorii de transport aerian din UE.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ CONVENȚIA ȘI DECIZIA?</p><br>

<p>Convenția de la Montreal este în vigoare din 4 noiembrie 2003. Decizia se aplică din 5 aprilie 2001.</p><br>

<p><a href="https://eur-lex.europa.eu/summary/chapter/transport/3205.html?root=3205">Sursa</a></p>

</div>
`