import React, { FunctionComponent } from 'react'
import Slider from 'react-slick'

import config from '../../../config'
import CarsContainer from '../autopark/cars-container'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './style.scss'

interface IModalSliderProps extends ComponentBaseProps {
  item: any
  canEdit: boolean
  imageFolder: string
  onClose: () => void
  refresh: any
}

const ModalSlider: FunctionComponent<IModalSliderProps> = props => {
  const { item, canEdit, imageFolder, onClose, refresh } = props
  const photos = item.photo || item.photos

  const isPlaceholderImage = photos.length === 1 && (photos[0].includes('/static/media/') || photos[0].includes('data:image/png'))

  return (
    <div className="transport-modal-content">
      <Slider draggable={false} infinite={false} speed={500} className="slick-slider">
        {photos.map((photo, id) => {
          const imagePath = isPlaceholderImage
            ? photo
            : `${(config as any).apiUrl}/common/file/${imageFolder}/${photo}`
          return (
            <div key={id}>
              <div className="transport-slider-block">
                <img src={imagePath} alt="photo" className="transport-slider-photo" />
              </div>
            </div>
          )
        })}
      </Slider>
      <div className="arrows-space" />
      <CarsContainer
        carList={[item]}
        canEdit={canEdit}
        total={1}
        onClose={onClose}
        refresh={refresh}
        imageFolder={imageFolder}
      />
    </div>
  )
}

export default ModalSlider
