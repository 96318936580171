export const int19 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>CONVENTIA INTERNATIONALA din 18 mai 1973 pentru simplificarea si armonizarea regimurilor vamale adoptata la Kyoto</h4><p></p>

<p></p><h4>PREAMBUL</h4><p></p>

<p style="text-indent:2em;"></p><h6>Pãrţile contractante la prezenta convenţie, întocmitã sub auspiciile Consiliului de Cooperare Vamalã, depunând eforturi pentru a elimina diferenţele dintre
	regimurile şi practicile vamale ale pãrţilor contractante, care pot obstrucţiona comerţul internaţional şi alte schimburi internaţionale, dorind sã aducã o
	contribuţie eficientã la dezvoltarea comerţului şi a acestor schimburi prin simplificarea şi armonizarea regimurilor şi a practicilor vamale şi prin favorizarea
	cooperãrii internaţionale,  constatând cã avantajele semnificative determinate de facilitarea comerţului internaţional pot fi obţinute fãrã a aduce atingere
	standardelor care reglementeazã în mod normal controlul vamal, recunoscând faptul cã simplificarea şi armonizarea se pot realiza în special prin aplicarea
	principiilor expuse în continuare:</h6><p></p>

<p style="text-indent:2em;">punerea în aplicare a unor programe în vederea modernizãrii constante a regimurilor şi practicilor vamale şi a ameliorãrii eficienţei şi randamentului acestora;</p><br>

<p>aplicarea regimurilor şi practicilor vamale în mod previzibil, coerent şi transparent;</p><br>

<p style="text-indent:2em;">punerea la dispoziţia pãrţilor interesate a tuturor informaţiilor necesare privind actele cu putere de lege, normele administrative, regimurile şi practicile vamale;</p><br>

<p style="text-indent:2em;">adoptarea tehnicilor moderne, cum ar fi sistemele de management al riscurilor şi controalele prin audit, precum şi utilizarea pe scarã cât mai largã a tehnologiei informaţiei;</p><br>

<p>cooperarea, dacã este cazul, cu celelalte autoritãţi naţionale, cu alte autoritãţi vamale şi cu mediile comerciale;</p><br>

<p>punerea în aplicare a standardelor internaţionale relevante;</p>

<p style="text-indent:2em;"></p><h6>deschiderea, pentru pãrţile vãtãmate, a unor cãi de recurs administrative şi judiciare uşor accesibile,
    convinse de faptul cã un instrument internaţional care reia obiectivele şi principiile de mai sus, pe care pãrţile contractante se angajeazã sã le punã în aplicare,
	poate determina un grad înalt de simplificare şi de armonizare a regimurilor şi practicilor vamale, ceea ce constituie unul dintre scopurile principale ale Consiliului
	de Cooperare Vamalã, aducând astfel o contribuţie majorã la facilitarea comerţului internaţional,convin dupã cum urmeazã:</h6><p></p>

<p style="font-weight:500;">CAP. I</p><br>

<p style="font-weight:500;">Definiţii</p><br>

<p style="font-weight:500;">ART. 1</p>

<p style="text-indent:2em;"></p><h6>În sensul prezentei convenţii, se definesc urmãtorii termeni:</h6><p></p>

<p style="text-indent:2em;">a) standard: o dispoziţie a cãrei punere în aplicare se considerã a fi necesarã pentru armonizarea şi simplificarea regimurilor şi practicilor vamale;</p><br>

<p>b) standard tranzitoriu: un standard din anexa generalã, pentru care se acordã un termen mai lung de punere în aplicare;</p><br>

<p style="text-indent:2em;">c) practicã recomandatã: o dispoziţie dintr-o anexã specificã, recunoscutã ca fiind un progres în calea armonizãrii şi simplificãrii regimurilor şi practicilor
	vamale şi a cãrei aplicare pe scarã cât mai largã este recomandatã;</p><br>

<p style="text-indent:2em;">d) legislaţie naţionalã: actele cu putere de lege, normele administrative şi alte mãsuri impuse de o autoritate competentã a unei pãrţi contractante şi aplicabile
	pe întregul teritoriu al pãrţii contractante în cauzã sau tratatele în vigoare care angajeazã partea respectivã;</p><br>

<p style="text-indent:2em;">e) anexã generalã: totalitatea dispoziţiilor aplicabile tuturor regimurilor şi practicilor vamale menţionate de prezenta convenţie;</p><br>

<p style="text-indent:2em;">f) anexã specificã: o serie de dispoziţii aplicabile unuia sau mai multor regimuri şi practici vamale menţionate de prezenta convenţie;</p><br>

<p style="text-indent:2em;">g) directive: o serie de explicaţii ale dispoziţiilor anexei generale, anexelor specifice şi ale capitolelor acestora, care indicã anumite linii de conduitã ce
	pot fi urmate pentru aplicarea standardelor, standardelor tranzitorii şi a practicilor recomandate şi care precizeazã practicile recomandate, precum şi exemple
	de facilitãţi mai mari recomandate;</p><br>

<p>h) comitet tehnic permanent: comitetul tehnic permanent al Consiliului;</p><br>

<p style="text-indent:2em;">i) Consiliu: organizaţia înfiinţatã prin Convenţia de instituire a unui Consiliu de Cooperare Vamalã, încheiatã la Bruxelles la 15 decembrie 1950;</p><br>

<p style="text-indent:2em;">j) uniune vamalã sau economicã: o uniune constituitã şi formatã din statele care au competenţa de a adopta propriile reglementãri obligatorii pentru aceste state
	în domeniile vizate de prezenta convenţie şi de a decide, conform procedurilor lor interne, semnarea sau ratificarea prezentei convenţii ori aderarea la aceasta.</p><br>

<p style="font-weight:500;">CAP. II</p><br>

<p style="font-weight:500;">Sfera de aplicare şi structura</p><br>

<p style="font-weight:500;">Sfera de aplicare a convenţiei</p><br>

<p style="font-weight:500;">ART. 2</p><br>

<p style="text-indent:2em;">Fiecare parte contractantã se angajeazã sã promoveze simplificarea şi armonizarea regimurilor vamale şi, în acest scop, sã se conformeze, în condiţiile prevãzute de prezenta
	convenţie, standardelor, standardelor tranzitorii şi practicilor recomandate care fac obiectul anexelor la prezenta convenţie. Cu toate acestea, fiecare parte contractantã 
	poate acorda facilitãţi mai mari decât cele prevãzute în convenţie, iar fiecãrei pãrţi contractante i se recomandã sã acorde astfel de facilitãţi în cea mai mare mãsurã posibilã.</p><br>

<p style="font-weight:500;">ART. 3</p><br>

<p style="text-indent:2em;">Dispoziţiile prezentei convenţii nu aduc atingere aplicãrii legislaţiei naţionale în ceea ce priveşte interdicţiile sau restricţiile privind mãrfurile supuse unui control vamal.</p><br>

<p style="font-weight:500;">Structura convenţiei</p><br>

<p style="font-weight:500;">ART. 4</p><br>

<p>(1) Convenţia conţine un cuprins, o anexã generalã şi anexe specifice.</p>

<p style="text-indent:2em;"></p><h6>(2) Anexa generalã şi fiecare anexã specificã la prezenta convenţie sunt formate din capitole care constituie o subdiviziune a anexei şi conţin:</h6><p></p>

<p>a) definiţii; şi</p><br>

<p>b) standarde, dintre care unele, cuprinse în anexa generalã, sunt tranzitorii.</p><br>

<p>(3) Fiecare anexã specificã conţine, de asemenea, practici recomandate.</p><br>

<p>(4) Fiecare anexã este însoţitã de directive ale cãror texte nu sunt obligatorii pentru pãrţile contractante.</p><br>
    
<p style="font-weight:500;">ART. 5</p><br>

<p style="text-indent:2em;">În sensul prezentei convenţii, anexele specifice şi capitolele acestora în vigoare pentru o parte contractantã se considerã ca fãcând parte integrantã din convenţie şi, în
	ceea ce priveşte partea contractantã vizatã, orice trimitere la convenţie se considerã ca fãcând trimitere, de asemenea, la aceste anexe şi capitole.</p><br>

<p style="font-weight:500;">CAP. III</p><br>

<p style="font-weight:500;">Administrarea convenţiei</p><br>

<p style="font-weight:500;">Comitetul de gestionare</p><br>

<p style="font-weight:500;">ART. 6</p><br>

<p style="text-indent:2em;">(1) Se instituie un comitet de gestionare pentru a examina punerea în aplicare a prezentei convenţii şi a studia orice mãsurã care are scopul de a asigura o interpretare şi
	o aplicare uniformã a acesteia, precum şi orice modificare propusã.</p><br>

<p>(2) Pãrţile contractante sunt membre ale Comitetului de gestionare.</p><br>

<p style="text-indent:2em;">(3) Administraţia competentã a oricãrei entitãţi care, în sensul art. 8, îndeplineşte condiţiile necesare pentru a deveni parte contractantã la prezenta convenţie sau a oricãrui
	membru al Organizaţiei Mondiale a Comerţului poate asista la şedinţele Comitetului de gestionare în calitate de observator. Statutul şi drepturile acestor observatori sunt
	definite printr-o decizie a Consiliului. Drepturile menţionate anterior nu se pot exercita înainte de intrarea în vigoare a deciziei.</p><br>

<p style="text-indent:2em;">(4) Comitetul de gestionare poate invita reprezentanţi ai organizaţiilor internaţionale, guvernamentale şi neguvernamentale sã asiste la şedinţele Comitetului de gestionare
	în calitate de observatori.</p>

<p style="text-indent:2em;"></p><h6>(5) Comitetul de gestionare:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) recomandã pãrţilor contractante:</h6><p></p>

<p>(i) modificãrile care trebuie aduse corpului prezentei convenţii;</p><br>

<p style="text-indent:2em;">(ii) modificãrile care trebuie aduse anexei generale, anexelor specifice şi capitolelor acestora, adãugarea unor capitole noi la anexa generalã; şi</p><br>

<p>(iii) adãugarea de noi anexe specifice şi de noi capitole la anexele specifice;</p><br>

<p style="text-indent:2em;">b) poate decide sã modifice practicile recomandate sau sã includã noi practici recomandate în anexele specifice ori în capitolele acestora, în conformitate cu art. 16;</p><br>

<p>c) planificã punerea în aplicare a dispoziţiilor prezentei convenţii în conformitate cu art. 13 alin. (4);</p><br>

<p>d) efectueazã revizuiri şi actualizãri ale directivelor;</p><br>

<p>e) ia în considerare orice altã problemã care este supusã atenţiei sale în legãturã cu prezenta convenţie;</p><br>
	
<p>f) informeazã comitetul tehnic permanent şi Consiliul cu privire la deciziile sale.</p><br>

<p style="text-indent:2em;">(6) Administraţiile competente ale pãrţilor contractante comunicã secretarului general al Consiliului propunerile menţionate la alin. (5) lit. a), b), c) sau d) şi motivele
	care le justificã, precum şi cererile de înscriere a punctelor pe ordinea de zi a şedinţelor Comitetului de gestionare. Secretarul general al Consiliului aduce propunerile
	de modificãri la cunoştinţa administraţiilor competente ale pãrţilor contractante şi a observatorilor menţionaţi la alin. (2), (3) şi (4).</p><br>

<p style="text-indent:2em;">(7) Comitetul de gestionare se reuneşte cel puţin o datã pe an. Comitetul îşi alege anual preşedintele şi vicepreşedintele. Secretarul general al Consiliului distribuie
	invitaţia şi proiectul de ordine de zi administraţiilor competente ale pãrţilor contractante şi observatorilor menţionaţi la alin. (2), (3) şi (4) cu cel puţin 6 sãptãmâni
	înainte de şedinţa Comitetului de gestionare.</p><br>

<p style="text-indent:2em;">(8) În cazul în care o decizie nu se poate lua prin consens, problemele înaintate Comitetului de gestionare se supun votului pãrţilor contractante prezente. Propunerile
	fãcute în conformitate cu alin. (5) lit. a), b) sau c) din prezentul articol se aprobã cu o majoritate de douã treimi din voturile exprimate. Comitetul de gestionare decide
	asupra tuturor celorlalte probleme cu majoritatea voturilor exprimate.</p><br>

<p style="text-indent:2em;">(9) În cazul aplicãrii art. 8 alin. (5) din prezenta convenţie, uniunile vamale sau economice care sunt pãrţi contractante dispun, în caz de vot, doar de un numãr de
	voturi egal cu numãrul total de voturi care pot fi acordate membrilor acestora care sunt pãrţi contractante.</p><br>

<p style="text-indent:2em;">(10) Comitetul de gestionare adoptã un raport înainte de închiderea şedinţei. Acest raport se transmite Consiliului, precum şi pãrţilor contractante şi observatorilor
	menţionaţi la alin. (2), (3) şi (4).</p><br>

<p style="text-indent:2em;">(11) În absenţa dispoziţiilor specifice la prezentul articol, se aplicã regulamentul de procedurã al Consiliului, cu excepţia cazului în care Comitetul de gestionare
	decide altfel.</p><br>

<p style="font-weight:500;">ART. 7</p>

<p style="text-indent:2em;"></p><h6>În sensul votului din cadrul Comitetului de gestionare, se voteazã separat pe fiecare anexã specificã şi
	fiecare capitol al unei anexe specifice:</h6><p></p>

<p style="text-indent:2em;">a) Fiecare parte contractantã are competenţa de a vota cu privire la aspecte legate de interpretarea, aplicarea şi modificarea cuprinsului şi anexei generale ale
	convenţiei.</p><br>

<p style="text-indent:2em;">b) În ceea ce priveşte problemele referitoare la o anexã specificã sau la un capitol al unei anexe specifice deja în vigoare, au competenţa de a vota numai pãrţile
	contractante care au acceptat anexa în cauzã sau capitolul în cauzã.</p><br>

<p style="text-indent:2em;">c) Fiecare parte contractantã are competenţa de a vota cu privire la proiecte de noi anexe specifice sau de noi capitole ale unei anexe specifice.</p><br>

<p style="font-weight:500;">CAP. IV</p><br>

<p style="font-weight:500;">Partea contractantã</p><br>

<p style="font-weight:500;">Ratificarea convenţiei</p><br>

<p style="font-weight:500;">ART. 8</p>

<p style="text-indent:2em;"></p><h6>(1) Orice membru al Consiliului sau orice membru al Organizaţiei Naţiunilor Unite ori al instituţiilor sale specializate poate deveni parte contractantã
	la prezenta convenţie:</h6><p></p>

<p>a) semnând-o, fãrã rezerva ratificãrii;</p><br>

<p>b) depunând un instrument de ratificare dupã semnarea sub rezerva ratificãrii; sau</p><br>

<p>c) aderând la aceasta.</p><br>

<p style="text-indent:2em;">(2) Prezenta convenţie este deschisã pentru semnare de cãtre membrii menţionaţi la alin. (1) pânã la 30 iunie 1974 la sediul Consiliului, la Bruxelles. Dupã
	aceastã datã, ea va fi deschisã pentru aderarea acestor membri.</p><br>

<p style="text-indent:2em;">(3) Fiecare parte contractantã precizeazã, în momentul semnãrii sau ratificãrii prezentei convenţii ori al aderãrii la aceasta, anexa sau anexele specifice ori
	capitolele acestora pe care le acceptã. Fiecare parte contractantã poate notifica ulterior depozitarul cu privire la acceptarea uneia sau mai multor alte anexe
	specifice ori capitole ale acestora.</p><br>

<p style="text-indent:2em;">(4) Pãrţile contractante care acceptã o nouã anexã specificã sau un nou capitol al unei anexe specifice notificã acest fapt depozitarului, în conformitate cu
	alin. (3) din prezentul articol.</p><br>

<p style="text-indent:2em;">(5) a) Orice uniune vamalã sau economicã poate deveni, în conformitate cu dispoziţiile alin. (1), (2) şi (3) din prezentul articol, parte contractantã la
	prezenta convenţie. Aceasta informeazã depozitarul cu privire la competenţa sa în raport cu domeniile vizate de prezenta convenţie. Ea informeazã, de asemenea,
	depozitarul cu privire la orice modificare semnificativã adusã sferei sale de competenţã.</p><br>

<p style="text-indent:2em;">b) Uniunile vamale sau economice pãrţi contractante la prezenta convenţie exercitã, pentru problemele ce ţin de competenţa lor, în nume propriu, drepturile şi
 se achitã de responsabilitãţile pe care le conferã prezenta convenţie membrilor acestor uniuni care sunt pãrţi contractante la prezenta convenţie. În asemenea
 cazuri, membrii acestor uniuni nu au competenţa de a exercita individual aceste drepturi, inclusiv dreptul la vot.</p><br>

<p style="font-weight:500;">ART. 9</p><br>

<p style="text-indent:2em;">(1) Orice parte contractantã care ratificã prezenta convenţie sau aderã la aceasta este angajatã prin modificãrile aduse prezentei convenţii, inclusiv
	la anexa generalã, intrate în vigoare la data depunerii instrumentului sãu de ratificare ori de aderare.</p><br>

<p style="text-indent:2em;">(2) Orice parte contractantã care acceptã o anexã specificã sau un capitol al acesteia este angajatã prin modificãrile aduse standardelor prevãzute în
	anexa specificã vizatã ori în capitolul vizat, intrate în vigoare la data la care partea contractantã notificã acceptarea depozitarului. Orice parte
	contractantã care acceptã o anexã specificã ori un capitol din aceasta este angajatã de modificãrile aduse practicilor recomandate prevãzute în anexa
	specificã ori capitolul în cauzã, care au intrat în vigoare la data la care partea contractantã notificã acceptarea depozitarului, cu excepţia cazului
	în care aceasta formuleazã rezerve, în conformitate cu art. 12, cu privire la una sau mai multe dintre
	practicile recomandate.</p><br>

<p style="font-weight:500;">Aplicarea convenţiei</p><br>

<p style="font-weight:500;">ART. 10</p><br>

<p style="text-indent:2em;">(1) Orice parte contractantã poate, în momentul semnãrii fãrã rezerva ratificãrii, al ratificãrii sau al aderãrii ori ulterior, sã notifice depozitarului
	cã prezenta convenţie se extinde asupra tuturor sau asupra anumitor teritorii ale cãror relaţii internaţionale se aflã în rãspunderea sa. Aceastã notificare
	intrã în vigoare la 3 luni de la data primirii de cãtre depozitar. Cu toate acestea, convenţia nu se poate aplica teritoriilor desemnate în notificare
	înainte sã fi intrat în vigoare pentru partea contractantã în cauzã.</p><br>

<p style="text-indent:2em;">(2) Orice parte contractantã care a notificat, în conformitate cu alin. (1) din prezentul articol, faptul cã prezenta convenţie se extinde asupra unui
	teritoriu ale cãrui relaţii internaţionale se aflã în responsabilitatea sa poate notifica depozitarului, în condiţiile prevãzute la art. 19, cã
	teritoriul în cauzã va înceta sã aplice convenţia.</p><br>

<p style="font-weight:500;">ART. 11</p><br>

<p style="text-indent:2em;">În sensul aplicãrii prezentei convenţii, uniunile vamale şi economice care sunt pãrţi contractante notificã secretarului general al Consiliului
	teritoriile care intrã în componenţa uniunii vamale sau economice, iar aceste teritorii trebuie considerate 
	ca fiind un singur teritoriu.</p><br>

<p style="font-weight:500;">Acceptarea dispoziţiilor şi formularea rezervelor</p><br>

<p style="font-weight:500;">ART. 12</p><br>

<p>(1) Anexa generalã angajeazã fiecare parte contractantã.</p><br>

<p style="text-indent:2em;">(2) O parte contractantã poate accepta una sau mai multe anexe specifice ori doar unul sau mai multe capitole dintr-o anexã specificã. O parte
	contractantã care acceptã o anexã specificã ori unul sau mai multe capitole din aceasta se angajeazã sã respecte toate standardele cuprinse în
	anexa ori în capitolele menţionate. O parte contractantã care acceptã o anexã specificã ori unul sau mai multe capitole din aceasta se angajeazã
	sã respecte toate practicile recomandate cuprinse în anexa ori capitolul sau capitolele menţionate, cu excepţia cazului în care partea contractantã
	notificã depozitarului, în momentul acceptãrii ori ulterior, practica sau practicile recomandate pentru care formuleazã rezerve, indicând diferenţele
	care existã între dispoziţiile din legislaţia naţionalã şi cele prevãzute în practica ori practicile recomandate în cauzã. Orice parte contractantã care
	a formulat rezerve poate sã le retragã în orice moment, total sau parţial, prin notificarea depozitarului, indicând data la care rezervele au fost
	retrase.</p><br>

<p style="text-indent:2em;">(3) Fiecare parte contractantã care este angajatã în temeiul unei anexe specifice sau al unuia ori mai multor capitole din aceasta examineazã
	posibilitatea de a renunţa la rezervele formulate cu privire la practicile recomandate, în conformitate cu alin. (2), şi notificã secretarului
	general al Consiliului rezultatele acestei examinãri la sfârşitul fiecãrei perioade de 3 ani începând cu intrarea în vigoare a prezentei convenţii
	pentru partea contractantã vizatã, precizând care sunt dispoziţiile din legislaţia naţionalã care se opun, în opinia sa, retragerii rezervelor
	formulate.</p><br>

<p style="font-weight:500;">Punerea în aplicare a dispoziţiilor</p><br>

<p style="font-weight:500;">ART. 13</p><br>

<p style="text-indent:2em;">(1) Fiecare parte contractantã pune în aplicare standardele din anexa generalã, precum şi anexele specifice sau capitolele din acestea pe care le-a
	acceptat, în termen de 36 de luni de la intrarea în vigoare a acestor anexe ori capitole pentru acea parte contractantã.</p><br>

<p style="text-indent:2em;">(2) Fiecare parte contractantã pune în aplicare standardele tranzitorii din anexa generalã în termen de 60 de luni de la intrarea în vigoare a anexei
	generale pentru acea parte contractantã.</p><br>

<p style="text-indent:2em;">(3) Fiecare parte contractantã pune în aplicare practicile recomandate din anexele specifice sau din capitolele acestora pe care le-a acceptat, în
	termen de 36 de luni de la intrarea în vigoare a anexelor specifice ori a capitolelor pentru acea parte contractantã, cu excepţia cazului în care s-au
	emis rezerve cu privire la una sau mai multe dintre aceste practici recomandate.</p><br>

<p style="text-indent:2em;">(4) a) În cazul în care perioada prevãzutã la alin. (1) sau (2) din prezentul articol s-ar putea dovedi, în practicã, insuficientã pentru o parte
	contractantã care doreşte sã punã în aplicare dispoziţiile anexei generale, partea contractantã în cauzã poate, înainte de sfârşitul perioadei menţionate
	la alin. (1) sau (2) din prezentul articol, sã solicite Comitetului de gestionare prelungirea acestei perioade. În momentul depunerii cererii sale, partea
	contractantã indicã dispoziţia sau dispoziţiile din anexa generalã pentru care se solicitã prelungirea
	termenului, precizând motivele acestei cereri.</p><br>

<p style="text-indent:2em;">b) În cazuri excepţionale, Comitetul de gestionare poate decide sã acorde prelungirea solicitatã. Orice decizie a Comitetului de gestionare de a acorda prelungirea
 va conţine o descriere a circumstanţelor excepţionale care au motivat decizia sa, iar acest termen nu va depãşi în niciun caz un an. La expirarea termenului acordat,
 partea contractantã informeazã depozitarul cu privire la intrarea în vigoare a dispoziţiilor pentru care
 s-a acordat prelungirea.</p><br>

<p style="font-weight:500;">Soluţionarea litigiilor</p><br>

<p style="font-weight:500;">ART. 14</p><br>

<p style="text-indent:2em;">(1) Orice litigiu între douã sau mai multe pãrţi contractante în ceea ce priveşte interpretarea ori aplicarea prezentei convenţii se soluţioneazã, în
	mãsura în care este posibil, pe calea negocierii directe între pãrţile vizate.</p><br>

<p style="text-indent:2em;">(2) Orice litigiu care nu se soluţioneazã pe calea negocierilor directe este înaintat de cãtre pãrţile contractante în cauzã Comitetului de gestionare
	care îl examineazã şi formuleazã recomandãri în vederea soluţionãrii litigiului.</p><br>

<p style="text-indent:2em;">(3) Pãrţile contractante în cauzã pot conveni în prealabil sã accepte recomandãrile
	Comitetului de gestionare şi sã le considere ca fiind obligatorii.</p><br>

<p style="font-weight:500;">Modificãri la convenţie</p><br>

<p style="font-weight:500;">ART. 15</p><br>

<p style="text-indent:2em;">(1) Textul oricãrei modificãri recomandate pãrţilor contractante de cãtre Comitetul de gestionare în conformitate cu art. 6 alin. (5) lit. a) pct. (i)
	şi (ii) este comunicat de cãtre secretarul general al Consiliului tuturor pãrţilor contractante şi membrilor Consiliului care nu sunt pãrţi
	contractante.</p><br>

<p style="text-indent:2em;">(2) Modificãrile aduse cuprinsului convenţiei intrã în vigoare pentru toate pãrţile contractante la 12 luni de la depunerea instrumentelor de acceptare
	de cãtre pãrţile contractante prezente la şedinţa Comitetului de gestionare în cadrul cãreia s-au recomandat modificãrile, cu condiţia ca niciuna dintre
	pãrţile contractante sã nu fi formulat obiecţii în termen de 12 luni de la data comunicãrii acestor modificãri.</p>

<p style="text-indent:2em;"></p><h6>(3) Orice modificare recomandatã în anexa generalã, în anexele specifice şi în capitolele acestora se considerã ca fiind acceptatã la 6 luni de la data
	comunicãrii recomandãrii de modificare pãrţilor contractante, cu excepţia cazului în care:</h6><p></p>

<p style="text-indent:2em;">a) s-a formulat o obiecţie de cãtre o parte contractantã sau, în cazul unei anexe specifice ori al unui capitol, de cãtre o parte contractantã care este
	angajatã în temeiul anexei specifice ori al capitolului în cauzã; sau</p><br>

<p style="text-indent:2em;">b) o parte contractantã informeazã secretarul general al Consiliului cã, deşi are intenţia sã accepte modificarea recomandatã, condiţiile necesare acestei
	acceptãri nu sunt încã îndeplinite.</p><br>

<p style="text-indent:2em;">(4) Atât timp cât o parte contractantã care a adresat comunicarea prevãzutã la alin. (3) lit. b) din prezentul articol nu şi-a notificat acceptarea secretarului
	general al Consiliului, aceasta poate, în termen de 18 luni de la expirarea termenului de 6 luni prevãzut la alin. (3), sã prezinte o obiecţie la
	modificarea recomandatã.</p><br>

<p style="text-indent:2em;">(5) În cazul în care se notificã o obiecţie la modificarea recomandatã în condiţiile prevãzute la alin. (3) lit. a) sau alin. (4) din prezentul articol,
 se considerã cã modificarea nu a fost acceptatã şi cã nu este aplicabilã.</p>

<p style="text-indent:2em;"></p><h6>(6) În cazul în care o parte contractantã a adresat o comunicare în sensul alin. (3) lit. b) din prezentul articol, se considerã cã modificarea a fost acceptatã
	la cea mai apropiatã dintre urmãtoarele date:</h6><p></p>

<p style="text-indent:2em;">a) data la care toate pãrţile contractante care au adresat o astfel de comunicare au notificat secretarului general al Consiliului acceptarea modificãrii
	recomandate, aceastã datã fiind, cu toate acestea, amânatã pânã la expirarea termenului de 6 luni menţionat la alin. (3), dacã toate acceptãrile au fost
	notificate anterior acestei expirãri;</p><br>

<p>b) data expirãrii termenului de 18 luni menţionat la alin. (4) din prezentul articol.</p><br>

<p style="text-indent:2em;">(7) Orice modificare consideratã acceptatã, privind anexa generalã sau anexele specifice şi capitolele acestora, intrã în vigoare la 6 luni de la data la care
	s-a considerat acceptatã ori, dacã modificarea recomandatã are un termen de intrare în vigoare diferit, la expirarea acestui termen, dupã data la care a fost
	consideratã acceptatã.</p><br>

<p style="text-indent:2em;">(8) Secretarul general al Consiliului notificã pãrţilor contractante la prezenta convenţie, cât mai curând posibil, orice obiecţie la modificarea recomandatã,
	formulatã în conformitate cu alin. (3) lit. a) din prezentul articol, precum şi orice comunicare adresatã în conformitate cu alin. (3) lit. b). El comunicã ulterior
	pãrţilor contractante dacã partea sau pãrţile contractante care au adresat o astfel de comunicare ridicã obiecţii faţã de modificarea recomandatã ori dacã o acceptã.</p><br>

<p style="font-weight:500;">ART. 16</p><br>

<p style="text-indent:2em;">(1) Indiferent de procedura de modificare prevãzutã la art. 15, Comitetul de gestionare poate, în conformitate cu art. 6, sã decidã modificarea oricãrei practici
	recomandate dintr-o anexã specificã sau dintr-un capitol al acesteia ori introducerea de noi practici recomandate. Secretarul general al Consiliului invitã fiecare
	parte contractantã sã ia parte la deliberãrile Comitetului de gestionare. Secretarul general al Consiliului comunicã textul oricãrei modificãri sau al oricãrei practici
	noi recomandate, adoptat în acest fel, pãrţilor contractante şi membrilor Consiliului care nu sunt pãrţi contractante la prezenta convenţie.</p><br>

<p style="text-indent:2em;">(2) Orice modificare sau adãugare de noi practici recomandate care a fãcut obiectul unei decizii în conformitate cu alin. (1) din prezentul articol intrã în vigoare
	la 6 luni de la efectuarea comunicãrii de cãtre secretarul general al Consiliului. Se considerã cã fiecare parte contractantã care este angajatã în temeiul unei anexe
	specifice sau al unui capitol al unei anexe specifice care face obiectul acestor modificãri ori adãugãri de noi practici recomandate a acceptat modificãrile sau noile
	practici recomandate, cu excepţia cazului în care formuleazã rezerve în condiţiile prevãzute la art. 12 din prezenta convenţie.</p><br>

<p style="font-weight:500;">Durata aderãrii</p><br>

<p style="font-weight:500;">ART. 17</p><br>

<p style="text-indent:2em;">(1) Prezenta convenţie se încheie pe o duratã nelimitatã. Cu toate acestea, orice parte contractantã poate sã o denunţe în orice moment dupã data intrãrii în vigoare
	prevãzutã la art. 18 din prezenta convenţie.</p><br>

<p>(2) Denunţarea se notificã printr-un instrument scris depus la depozitar.</p><br>

<p>(3) Denunţarea intrã în vigoare la 6 luni de la primirea de cãtre depozitar a instrumentului de denunţare.</p><br>

<p style="text-indent:2em;">(4) Dispoziţiile alin. (2) şi (3) din prezentul articol se aplicã, de asemenea, în ceea ce priveşte anexele specifice sau capitolele acestora, cu privire la care
	orice parte contractantã îşi poate retrage acceptarea în orice moment dupã data intrãrii lor în vigoare.</p><br>

<p style="text-indent:2em;">(5) Se va considera cã orice parte contractantã care îşi retrage acceptarea faţã de anexa generalã denunţã convenţia. În acest caz, dispoziţiile alin. (2) şi (3)
	se aplicã în egalã mãsurã.</p><br>

<p style="font-weight:500;">CAP. V</p><br>

<p style="font-weight:500;">Dispoziţii finale</p><br>

<p style="font-weight:500;">Intrarea în vigoare a convenţiei</p><br>

<p style="font-weight:500;">ART. 18</p><br>

<p style="text-indent:2em;">(1) Prezenta convenţie intrã în vigoare la 3 luni de la data la care cele 5 entitãţi menţionate la art. 8 alin. 
(1) şi (5) au semnat prezenta convenţie fãrã rezerva ratificãrii sau şi-au depus instrumentul de ratificare 
ori de aderare.</p><br>

<p style="text-indent:2em;">(2) Prezenta convenţie intrã în vigoare pentru orice parte contractantã la 3 luni dupã ce aceasta a devenit parte contractantã în conformitate cu
	dispoziţiile art. 8.</p><br>

<p style="text-indent:2em;">(3) Anexele specifice la prezenta convenţie sau capitolele lor intrã în
 vigoare la 3 luni dupã ce au fost acceptate de cãtre cele 5 pãrţi contractante.</p><br>

<p style="text-indent:2em;">(4) Dupã intrarea în vigoare a unei anexe specifice sau a unui capitol al acesteia în conformitate cu alin. (3) din prezentul articol, anexa specificã ori
	capitolul în cauzã intrã în vigoare pentru orice parte contractantã la 3 luni de la notificarea acceptãrii de cãtre partea contractantã. Cu toate acestea,
	anexele specifice sau capitolele nu intrã în vigoare pentru o parte contractantã decât dacã însãşi convenţia intrã în vigoare pentru partea contractantã în
	cauzã.</p><br>

<p style="font-weight:500;">Depozitarul convenţiei</p><br>

<p style="font-weight:500;">ART. 19</p><br>

<p style="text-indent:2em;">(1) Prezenta convenţie, toate semnãturile sub sau fãrã rezerva ratificãrii şi toate instrumentele de ratificare ori de aderare se depun la secretarul general al
	Consiliului.</p>

<p style="text-indent:2em;"></p><h6>(2) Depozitarul:</h6><p></p>

<p>a) primeşte textele originale ale prezentei convenţii şi asigurã pãstrarea lor;</p><br>

<p style="text-indent:2em;">b) face copii certificate pentru conformitate ale textelor originale ale prezentei convenţii şi le transmite pãrţilor contractante, membrilor Consiliului care nu sunt pãrţi
	contractante şi secretarului general al Organizaţiei Naţiunilor Unite;</p><br>

<p style="text-indent:2em;">c) primeşte toate semnãturile sub sau fãrã rezerva ratificãrii, ratificãrile ori aderãrile la prezenta convenţie, primeşte şi asigurã pãstrarea tuturor instrumentelor,
 notificãrilor şi comunicãrilor referitoare la prezenta convenţie;</p><br>

<p style="text-indent:2em;">d) examineazã dacã semnãtura sau orice instrument, notificare ori comunicare referitoare la prezenta convenţie este formulatã în mod corespunzãtor şi, dupã caz, aduce
	problema în atenţia pãrţii contractante în cauzã;</p>

<p style="text-indent:2em;"></p><h6>e) notificã pãrţilor contractante, membrilor Consiliului care nu sunt pãrţi contractante şi secretarului
	general al Organizaţiei Naţiunilor Unite:</h6><p></p>

<p>semnãturile, ratificãrile, aderãrile şi acceptãrile anexelor şi capitolelor menţionate la art. 8 din prezenta convenţie;</p><br>

<p style="text-indent:2em;">noile capitole din anexa generalã şi noile anexe specifice sau noile capitole ale acestora pe care Comitetul de gestionare decide sã le recomande în vederea
	includerii în prezenta convenţie;</p><br>

<p style="text-indent:2em;">data la care prezenta convenţie, anexa generalã şi fiecare anexã specificã sau capitol al acesteia intrã în vigoare în conformitate cu art. 18 din prezenta
	convenţie;</p><br>

<p>notificãrile primite în conformitate cu art. 8, 10, 11, 12 şi 13;</p><br>

<p>retragerea acceptãrii anexelor/capitolelor de cãtre pãrţile contractante;</p><br>

<p>denunţãrile primite în conformitate cu art. 17; şi</p><br>

<p>modificãrile acceptate în conformitate cu art. 15, precum şi data intrãrii lor în vigoare.</p><br>

<p style="text-indent:2em;">(3) În cazul în care apare o divergenţã între o parte contractantã şi depozitar în ceea ce priveşte îndeplinirea funcţiilor acestuia din urmã, depozitarul sau partea
	contractantã în cauzã trebuie sã aducã problema în atenţia celorlalte pãrţi contractante şi a semnatarilor ori, dupã caz, a Comitetului de gestionare sau Consiliului.</p><br>

<p style="font-weight:500;">Înregistrarea şi textele autentice</p><br>

<p style="font-weight:500;">ART. 20</p><br>

<p style="text-indent:2em;">În conformitate cu art. 102 din Carta Organizaţiei Naţiunilor Unite, prezenta convenţie va fi înregistratã la Secretariatul Organizaţiei Naţiunilor Unite,
	la cererea secretarului general al Consiliului.</p><br>

<p>Drept care subsemnaţii, deplin autorizaţi, am semnat prezenta convenţie.</p><br>

<p style="text-indent:2em;">Încheiatã la Kyoto la 18 mai 1973, în limbile francezã şi englezã, ambele texte fiind egal autentice, într-un singur exemplar, ce va fi depus pe lângã secretarul
	general al Consiliului, care va trimite copii certificate conforme ale acestuia tuturor entitãţilor vizate la art. 8 alin. (1) al prezentei convenţii.</p><br>
	
	<p><a href="http://legislatie.just.ro/Public/DetaliiDocumentAfis/124676">Sursa</a></p>

</div>
`