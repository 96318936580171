import { compose } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { addScrollBar } from "../../../store/utils"
import Component from './component'
import { IChangeEmailProps } from './types'
import withTranslation from '../../../globals/components/withTranslation'
import { changeEmail } from '../../../store/actions'

const initialValues = {
  email: undefined,
}

export default compose(
  withTranslation,
  connect(null, { changeEmail }),
  reduxForm({
    form: 'userChangeEmail',
    initialValues,
    onSubmit: (values, dispatch, props) => {
      const linkProps: any = props
      const { clearModal, changeEmail } = linkProps
      changeEmail(values).then(() => {
        clearModal()
        addScrollBar()
      })
    },
  })
)(Component as any) as React.ComponentClass<IChangeEmailProps>
