import thunk from 'redux-thunk'
import { createStore, compose, applyMiddleware } from 'redux'
// import window from 'global/window'

import rootReducer from './store/reducers' ///rootReducer'
import { middleware as idleMiddleware } from './idle/IdleRedux'
import socketIOReduxMiddleware from './socketIOReduxMiddleware'
// //const middleware = [thunk, idleMiddleware]

// // our redux store with dev tools
// const createStoreWithDevTools = compose(
//   applyMiddleware(...middleware),
//   window.devToolsExtension ? window.devToolsExtension() : f => f
// )(createStore)

// const store = createStoreWithDevTools(rootReducer)

// // enable hot reload for reducers
// if (module.hot) {
//   module.hot.accept('./rootReducer', () => {
//     const nextRootReducer = require('./rootReducer').default
//     store.replaceReducer(nextRootReducer)
//   })
// }

const composeEnhancers =
  process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose

const middleware = [socketIOReduxMiddleware, thunk, idleMiddleware]
const store = createStore(
  rootReducer,
  // compose(
  composeEnhancers(
    //applyMiddleware(thunk)
    applyMiddleware(...middleware)
  )
)

export default store
