import { compose } from 'recompose'
import { ForumModalCompliantProps } from './types'

import withPermissions from '../../../../../globals/components/withPermissions'
import withTranslation from '../../../../../globals/components/withTranslation'
import Component from './component'

export default compose(
  withPermissions,
  withTranslation
)(Component) as React.ComponentClass<ForumModalCompliantProps>
