import React, { Component } from 'react'
import classNames from 'classnames'
import { Field } from 'redux-form'
import FlexBand from 'flexband'

import Dropdown from '../../../../../UI/dropdown'
import { required, alphaNumeric } from '../../../../../shared/form-validators'
import dict from '../../../../../shared/dictionaries'
import FieldBase from '../../../../../UI/field-base'
import { TRACTOR_ID } from '../../../autopark-constants'
import classnames from 'classnames'

import './styles.scss'

export default class extends Component {
  render() {
    const { t } = this.props

    const {
      className,
      title = t('truck.data'),
      makeAndModelLabel,
      makeAndModelName,
      stateNumberName,
      bodyTypeName,
      yearName,
      ecoStandardName,
      isVisible = true,
      langId,
      makeAndModel,
      regNumber,
      carType,
      enteredValues
    } = this.props

    const bodyTypes = dict.getTransportTypes('auto')(langId)
    const rangeYears = dict.rangeYears(1950).reverse()
    const ecoStandards = dict.ecoStandards()
    const validateProps = []
    if (isVisible) {
      validateProps.validate = [required]
    }

    const customValidation = carType === TRACTOR_ID ? [] : isVisible ? [required] : []
    const finishValidation = validateProps.validate ? validateProps.validate : validateProps

    return (
      <div
        className={classNames({
          'panel-content-row': true,
          [className]: !!className,
        })}
      >
        <h5 className="title-label">{title}</h5>
        <div className="panel-form-row">
          <div className="row">
            <FlexBand style={{ width: '100%' }} wrap="wrap" justify="space-between" className={makeAndModelName}>
              <div className="manufacturer">
                <label className="required-field fl-left">{makeAndModelLabel}</label>
                <span className="col-gray length-countdown fl-right">
                  {`  `}
                  <span className="count">
                    {makeAndModel && makeAndModel.length > 0 ? makeAndModel.length : 0}
                  </span>
                  {` / `}
                  <span className="length">30</span>
                </span>
                <Field
                  type="text"
                  name={makeAndModelName}
                  component={FieldBase}
                  placeholder={`${t('example')}, MAN TGA MX 18.410 Farhgestell`}
                  maxLength={30}
                  validate={alphaNumeric}
                />
              </div>
              <div className="registration-number">
                <label>
                  {t('transport.registration.number')}
                  <span className="col-gray length-countdown fl-right">
                    {`  `}
                    <span className="count">
                      {regNumber && regNumber.length > 0 ? regNumber.length : 0}
                    </span>
                    {` / `}
                    <span className="length">12</span>
                  </span>
                </label>
                <Field
                  type="text"
                  name={stateNumberName}
                  component={FieldBase}
                  placeholder={`${t('example')}, CL AR 182`}
                  maxLength={12}
                  validate={[alphaNumeric]}
                />
              </div>
            </FlexBand>
          </div>
        </div>
        <div className="panel-form-row">
          <div className="row">
            <FlexBand justify="inherit">
              <div className={classnames('body-type', carType === TRACTOR_ID && 'hide-field')}>
                <label className="required-field">{t('body.type')}</label>
                <Field
                  name={bodyTypeName}
                  component={Dropdown}
                  items={bodyTypes}
                  placeholder={`${t('choose')}...`}
                  validate={customValidation}
                />
              </div>
              <div className="prod-year">
                <label className="required-field">{t('year-issue')}</label>
                <Field
                  name={yearName}
                  component={Dropdown}
                  items={rangeYears}
                  placeholder={`${t('choose')}...`}
                  {...finishValidation}
                />
              </div>
              <div className="eco-standard">
                {ecoStandardName && [
                  <label key={0}>{t('eco-standard')}</label>,
                  <Field
                    key={1}
                    name={ecoStandardName}
                    component={Dropdown}
                    items={ecoStandards}
                    placeholder={`${t('choose')}...`}
                  />,
                ]}
              </div>
            </FlexBand>
          </div>
        </div>
      </div>
    )
  }
}
