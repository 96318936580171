import { compose } from 'recompose'
import { reduxForm } from 'redux-form'
import withTranslation from '../../../../globals/components/withTranslation'
import Component from './component'
import { connect } from 'react-redux'
import { IReduxStorage } from '../../../../store/reducers/types'

const initialValues = { pageSize: 10 }

export default compose(
  withTranslation,
  connect((state: IReduxStorage) => {
    const {
      auth: { profile },
    } = state

    return { 
      profile: profile.get('data').toJS(),
      companyVerifiedInfo: state.company.companyVerifiedInfo,
     }
  }),
  reduxForm({
    initialValues,
    enableReinitialize: true,
    forceUnregisterOnUnmount: false,
  })
)(Component)
