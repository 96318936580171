export default [
  
  {
    name: 'Поиск грузов',
    type: 'item',
    href: '/cargo/search',
  },
  {
    name: 'Поиск транспорта',
    type: 'item',
    href: '/transport/search',
  },
  {
    name: 'Добавить груз',
    type: 'item',
    href: '/cargo/add',
    showLoginAlert: true
  },
  {
    name: 'Добавить транспорт',
    type: 'item',
    href: '/transport/add',
    showLoginAlert: true
  },
]
