import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { intlShape } from 'react-intl'

export class ScrollToTopOnUpdate extends PureComponent {
  static propTypes = {
    render: PropTypes.func.isRequired,
    delegate: PropTypes.object.isRequired, // eslint-disable-line
  }

  static contextTypes = {
    intl: intlShape,
  }

  componentDidUpdate({ delegate: { location: prevLocation } }) {
    const {
      delegate: { location },
    } = this.props

    if (location.pathname !== prevLocation.pathname) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { render, delegate } = this.props
    const { intl } = this.context
    return render({
      ...delegate,
      intl,
    })
  }
}

export const intlScrollToTopOnUpdateFactory = render => delegate => (
  <ScrollToTopOnUpdate render={render} delegate={delegate} />
)

export default intlScrollToTopOnUpdateFactory
