export const int5 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Sfatul expertului : Scrisoarea de trasura CMR</h4><p></p>

<p style="text-indent:2em;"></p><h6>Livrarea intracomunitara de bunuri este scutita de TVA pe baza prevederilor articolului 294 alin. (2) lit. a) din Codul fiscal, care arata ca:</h6><p></p>

<p style="text-indent:2em;"></p><h6>"(2) Sunt, de asemenea, scutite de taxa urmatoarele:</h6><p></p>

<p style="text-indent:2em;">a) livrarile intracomunitare de bunuri catre o persoana care ii comunica furnizorului un cod valabil de inregistrare in scopuri de TVA, atribuit de autoritatile fiscale din alt stat membru (...) "</p>

<p style="text-indent:2em;"></p><h6>Procedura efectiva a scutirii de TVA este detaliata de articolul 10 alin. (1) din OMFP nr. 103/2016, astfel:</h6><p></p>

<p style="text-indent:2em;"></p><h6>"(1) Scutirea de taxa pentru livrarile intracomunitare de bunuri prevazute la art. 294 alin. (2) lit. a) din Codul fiscal, cu exceptiile de la pct. 1 si 2 ale aceleiasi
 litere a), se justifica pe baza urmatoarelor documente:</h6><p></p>

<p style="text-indent:2em;">a) factura in care trebuie sa fie mentionat codul de inregistrare in scopuri de TVA atribuit cumparatorului in alt stat membru;</p><br>

<p>b) documentul care atesta ca bunurile au fost transportate din Romania in alt stat membru, si, dupa caz,</p><br>

<p>c) orice alte documente, cum ar fi: contractul/comanda de vanzare/cumparare, documentele de asigurare."</p>

<p style="text-indent:2em;"></p><h6>Prin documente de transport se inteleg documentele de transport international consacrate, respectiv:</h6><p></p>

<p>CMR, carnet TIR sau, dupa caz, documentul de tranzit vamal T1 pentru transportul rutier;</p><br>

<p style="text-indent:2em;">scrisoare de trasura sau documentul de tranzit vamal intracomunitar T1sau conosament sau conosament combinat pentru transportul feroviar.</p><br>

<p style="text-indent:2em;">C.M.R. este un acord international privind contractele de transport (provine din limba franceza: Convention relative au contrat de transport international de Marchandises par Route). Ca
 document, CMR-ul, denumit si scrisoare de trasura CMR, reglementeaza transportul marfurilor de orice fel cu autocamioane (containerele si suprastructurile modulare nefiind considerate vehicule),
 inclusiv raspunderea in cazul daunelor produse in timpul transportului.</p><br>

<p style="text-indent:2em;">El se foloseste atunci cand locul de incarcare si locul de descarcare sunt in tari diferite. Cel putin una dintre aceste tari trebuie sa fie membra CMR. Acest lucru este valabil indiferent
 de sediul sau cetatenia partilor. Acordul este valid in toate tarile membre ale Uniunii Europene, precum si in alte cateva tari</p>

<p style="text-indent:2em;"></p><h6>Documentul se intocmeste de catre expeditor in 3 exemplare, toate avand aceeasi valoare. Toate exemplarele trebuie dupa completare sa fie semnate atat de expeditor cat si de transportator.
 Exemplarele scrisorii de trasura au ca destinatie:</h6><p></p>

<p>primul exemplar revine expeditorului,</p><br>

<p>al doilea insoteste marfa pe toata durata transportului,</p><br>

<p>al treilea revine transportatorului.</p><br>

<p style="text-indent:2em;">Scrisoarea de trasura CMR cuprinde trei categorii de informatii, unele sunt obligatorii, altele sunt aditionale iar altele sunt facultative.</p>
 
<p style="text-indent:2em;"></p><h6>Informatiile obligatorii ale unei scrisori de trasura CMR sunt urmatoarele:</h6><p></p>

<p>a) locul si data intocmirii sale;</p><br>

<p>b) numele si adresa expeditorului;</p><br>

<p>c) numele si adresa transportatorului;</p><br>

<p>d) locul si data primirii marfii si locul prevazut pentru eliberarea acesteia;</p><br>

<p>e) numele si adresa destinatarului;</p><br>

<p style="text-indent:2em;">f) denumirea curenta a naturii marfii si felul ambalajului, iar pentru marfurile periculoase, denumirea lor general recunoscuta;</p><br>

<p>g) numarul coletelor, marcajele speciale si numerele lor;</p><br>

<p>h) greutatea bruta sau cantitatea astfel exprimata a marfii;</p><br>

<p style="text-indent:2em;">i) cheltuielile aferente transportului (pret de transport, cheltuieli accesorii, taxe de vama si alte cheltuieli survenite de la incheierea contractului si pana la eliberare);</p><br>

<p>j) instructiunile necesare pentru formalitatile de vama si altele;</p><br>

<p>k) indicatia ca transportul este supus regimului stabilit prin prezenta conventie si nici unei alte clauze contrare.</p>
 
<p style="text-indent:2em;"></p><h6>Informatiile aditionale, necesare insa nu obligatorii sunt urmatoarele:</h6><p></p>

<p>a) interzicerea transbordarii;</p><br>

<p>b) cheltuielile pe care expeditorul le ia asupra sa;</p><br>

<p>c) totalul sumelor ramburs de perceput la eliberarea marfii;</p><br>

<p>d) valoarea declarata a marfii si suma care reprezinta interesul special la eliberare;</p><br>

<p>e) instructiunile expeditorului catre transportator cu privire la asigurarea marfii;</p><br>

<p>f) termenul convenit in care transportul trebuie sa fie efectuat;</p><br>

<p>g) lista documentelor remise transportatorului.</p>
 
<p style="text-indent:2em;"></p><h6>In ceea ce priveste categoria informatiilor facultative, partile
 pot insera orice alta indicatie pe care ele o considera utila, cum ar fi:</h6><p></p>

<p>clauze privind penalitatile de intarziere la incarcare/descarcare,</p><br>

<p>penalitati de intarziere la plata facturii de transport,</p><br>

<p>clauze privind solutionarea litigiilor pe calea arbitrajului comercial, etc.</p><br>

<p><a href="https://contabilul.manager.ro/a/20721/sfatul-expertului-robert-gearba-scrisoarea-de-trasura-cmr.html">Sursa</a></p>

</div>
`