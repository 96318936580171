export const int5 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Правила пользования книжкой МДП (Carnet-TIR).</h4><p></p>

<p></p><h4>(приводятся на основании Приложений к Конвенции МДП 1975 г.)</h4><p></p>

<p></p><h4>А. Общие положения .</h4><p></p>

<p style="font-weight:500;">1. Выдача.</p><br>

<p style="text-indent:2em;">Книжка МДП выдается в стране отправления или в той стране, где держатель находится или имеет постоянное
 местопребывание.</p><br>
 
<p style="font-weight:500;">2. Язык.</p><br>

<p style="text-indent:2em;">Книжка МДП печатается на французском языке, за исключением лицевой стороны обложки, рубрики которой печатаются
 также на английском языке. "Правила пользования книжкой МДП" воспроизводятся на английском языке на станице 2
 этой обложки. Кроме того, могут быть добавлены дополнительные страницы с переводом печатного текста на другие
 языки. Книжки, используемые для операций МДП в пределах региональной системы гарантий, могут быть отпечатаны
 на любом официальном языке ООН, за исключением станицы 1 обложки, на которой рубрики печатаются на английском
 или французском языках.</p><br>
 
<p style="font-weight:500;">3. Срок действия.</p><br>

<p style="text-indent:2em;">Книжка МДП действительна до завершения операции МДП в таможне места назначения, если она оформлена в таможне
 места отправления в срок, установленный выдающим документ объединением.</p><br>
 
<p style="font-weight:500;">4. Число книжек.</p><br>

<p style="text-indent:2em;">Для состава транспортных средств (сцепленные транспортные средства) или для нескольких контейнеров, погруженных
 либо на одно транспортное средство, либо на состав транспортных средств, выдается одна книжка МДП
 (см. также ниже п.10d).</p><br>
 
<p style="font-weight:500;">5. Количество таможен места отправления и места назначения.</p><br>

<p style="text-indent:2em;">Маршруты перевозок с применением книжки МДП могут проходить через несколько таможен места отправления 
и назначения. Однако общее количество таможен места отправления и места назначения не может превышать четырех.
 Книжка МДП может быть предъявлена таможням места назначения только в том случае, если отметку о ее принятии сделали
 все таможни места отправления (см. также п.10е ниже).</p><br>
 
<p style="font-weight:500;">6. Количество листов.</p><br>

<p style="text-indent:2em;">Если маршрут перевозки проходит через одну таможню места отправления и одну таможню места назначения, то в книжке
 МДП должно быть по меньшей мере 2 листа для страны отправления, 2 листа для страны назначения и 2 листа для каждой
 другой страны, по территории которой производится перевозка. Для каждой дополнительной таможни места отправления
 (или назначения) требуются 2 дополнительных листа.</p><br>
 
<p style="font-weight:500;">7. Представление в таможнях.</p><br>

<p style="text-indent:2em;">Книжка МДП должна представляться вместе с дорожным транспортным средством, составом транспортных средств или
 контейнером(ами) в каждой таможне места отправления, в каждой промежуточной таможне и в каждой таможне места
 назначения. В последней таможне места отправления должностное лицо таможни ставит подпись и печать с датой
 под грузовым манифестом на всех отрывных листах, которые будут использованы на остальной 
 части маршрута.</p>
 
<p></p><h4>В. Способ заполнения книжки МДП.</h4><p></p>

<p style="font-weight:500;">8. Подчистки, помарки.</p><br>

<p style="text-indent:2em;">В книжке МДП не должно быть ни подчисток, ни помарок. Все исправления должны быть произведены путем вычеркивания
 ошибочных указаний и добавления, в случае необходимости, надлежащих указаний. Всякое изменение должно быть
 подтверждено сделавшим его лицом и удостоверено таможенными органами.</p><br>
 
<p style="font-weight:500;">9. Сведения, касающиеся регистрации.</p><br>

<p style="text-indent:2em;">Если положениями национального законодательства не предусматривается регистрация прицепов и полуприцепов, то
 вместо регистрационного номера следует указать опознавательный или заводской номер.</p>
 
<p style="text-indent:2em;"></p><h6>10. Манифест:</h6><p></p>

<p style="text-indent:2em;">а) манифест заполняется на языке страны отправления, если только таможенные органы не разрешают использование
 другого языка. Таможенные органы других стран, по территории которых осуществляется перевозка, сохраняют за
 собой право потребовать перевода манифеста на язык их страны. Во избежание задержек, которые могут быть вызваны
 этим требованием, перевозчикам рекомендуется иметь в своем распоряжении надлежащие переводы.</p><br>

<p style="text-indent:2em;">b) указания, занесенные в манифест, должны быть отпечатаны на машинке или гектографированы так, чтобы они были
 удобопечатаемы на всех листах. Листы, заполненные неразборчиво, не принимаются таможенными органами.</p><br>

<p style="text-indent:2em;">c) к отрывным листкам могут прилагаться дополнительные листки того же образца, что и манифест, или коммерческие
 документы с указанием всех сведений, которые приводятся в манифесте. В этом случае во все отрывные листки надлежит
 внести следующие сведения: число дополнительных листов, число и тип грузовых мест или предметов, а также общий вес
 брутто грузов, перечисленных в этих дополнительных листах.</p><br>

<p style="text-indent:2em;">d) если книжка МДП выдана на состав транспортных средств или на несколько контейнеров, в манифесте следует указать
 отдельно содержимое каждого транспортного средства или каждого контейнера. Этому указанию должен предшествовать
 регистрационный номер транспортного средства или опознавательный номер контейнера.</p><br>

<p style="text-indent:2em;">e) если маршрут перевозки проходит через несколько таможен отправления или назначения, то записи, касающиеся
 принятых для отправления грузов или грузов, предназначенных для каждой таможни, также должны быть внесены в
 манифест отдельно друг от друга.</p><br>

<p style="font-weight:500;">11. Упаковочные листы, фотографии, схемы и т.п.</p><br>

<p style="text-indent:2em;">Если для опознавания тяжеловесных или громоздких грузов таможенные органы требуют, чтобы подобные документы были
 приложены к книжке МДП ,последние должны быть заверены таможенными органами и прикреплены к странице 2 обложки
 книжки. Кроме того, на всех отрывных листках следует перечислить эти документы.</p><br>
 
<p style="font-weight:500;">12. Подпись.</p><br>

<p style="text-indent:2em;">На всех отрывных листках должны быть представлены дата и подпись держателя книжки МДП или его
 представителя.</p>
 
<p></p><h4>С. Дорожнотранспортные происшествия.</h4><p></p>

<p style="font-weight:500;">13.</p><br>

<p style="text-indent:2em;">Если в пути таможенные пломбы и печати были случайно повреждены или груз погиб или поврежден, перевозчик должен
 немедленно обратиться к таможенным органам, если таковые находятся поблизости, или, в противном случае, к другим
 компетентным органам страны, в которой он находится. Последние в возможно короткий срок составляют протокол,
 приведенный в книжке МДП.</p><br>
 
<p style="font-weight:500;">14.</p><br>

<p style="text-indent:2em;">Если в результате дорожнотранспортного происшествия необходимо перегрузить груз на другое транспортное средство или
 в другой контейнер, то такая перегрузка может быть произведена лишь в присутствии представителя одного из органов,
 указанных выше в п.13. Данный орган составляет протокол. Если в книжке МДП не упомянуты "тяжеловесные или громоздкие
 грузы", то транспортное средство и контейнер(-ы), на которое(-ый) грузы были перегружены, должно(должен) быть
 допущено(допущен) к перевозке грузов под таможенными печатями и пломбами. Однако при отсутствии допущенного к
 перевозке транспортного средства или контейнера грузы могут быть перегружены на недопущенное(-ый) транспортное
 средство или контейнер, если оно(он) является достаточно надежным. В этом последнем случае таможенные органы
 расположенных далее по маршруту стран решат, могут ли также и они допустить дальнейшую перевозку грузов в этом
 транспортном средстве или контейнере с применением книжки МДП.</p><br>
 
<p style="font-weight:500;">15.</p><br>

<p style="text-indent:2em;">В случае непосредственно угрожающей опасности, требующей немедленной выгрузки всего груза или его части, перевозчик
 может по собственному усмотрению принять необходимые меры, не испрашивая и не ожидая вмешательства органов, упомянутых
 выше в п.13. В таком случае он должен привести доказательства того, что он вынужден был действовать так в интересах
 сохранения транспортного средства(контейнера) или груза, и, немедленно по принятии срочных мер предупредительного
 характера, уведомить один из органов, упомянутых в п.13., для установления фактов, проверки груза, наложения пломб
 на транспортное средство или контейнер и составления протокола.</p><br>
 
<p style="font-weight:500;">16.</p><br>

<p>Протокол должен оставаться в книжке МДП до прибытия груза в таможню
 места назначения.</p><br>

<p style="font-weight:500;">17.</p><br>

<p style="text-indent:2em;">Объединениям рекомендуется предоставлять перевозчикам, помимо образца , включенного в саму книжку МДП,
 несколько бланков протокола, составленных на языке(ах) стран, через которые проходит маршрут
 перевозки.</p><br>

</div>
`