import _ from 'lodash'

import { render } from 'react-dom'
import document from 'global/document'

import './styles/css/main.css'

import appFactory from './globals/factories/appFactory'
import store from './store'

import { DEFAULT_LANG } from './store/constants'

import { hydrateFromLocalStorage, getProfile, refreshStart, loadLanguage } from './store/actions'

import { loadTranslation } from './store/actions/ui'
import { getSystemHydrateData } from './globals/sideEffects/localStorage'
import { getJwt } from './store/utils'
import { JwtModel } from './store/models.ts'
import './styles.scss'

const renderApp = () => render(appFactory(_.noop)({ store }), document.getElementById('root'))

const hydratedData = getSystemHydrateData()
const { culture = DEFAULT_LANG } = hydratedData

const actions = [
  // get culture from local storage
  hydrateFromLocalStorage(hydratedData),
]
// load default language if it differs from starting language
culture !== DEFAULT_LANG && actions.push(loadTranslation(DEFAULT_LANG))

let userId
const { accessToken } = hydratedData
if (accessToken) {
  const jwt = getJwt(accessToken, { ...JwtModel })
  userId = jwt.userId
}

// load starting language
actions.push(loadLanguage(culture))

// get new access token
actions.push(refreshStart())

Promise.all(actions.map(action => store.dispatch(action)))
  .then(() => {
    return userId ? store.dispatch(getProfile(userId)) : Promise.resolve()
  })
  .then(renderApp)
