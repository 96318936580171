import { AccessState } from './types'
import {
  ACCESS_GET_LIST,
  ACCESS_ADD_POINT,
  ACCESS_DELETE_POINT,
  ACCESS_CHANGE_GROUP,
  ACCESS_GET_USER_NAME,
} from '../actions/action-types'

const initialState = {
  accessPoints: [],
  accessGroups: [],
  userFullName: '',
}

export default (state: AccessState = initialState, { type, payload }: any = {}) => {
  switch (type) {
    case ACCESS_GET_LIST: {
      return {
        ...state,
        accessPoints: payload.accessPoints,
        accessGroups: payload.accessGroups,
      }
    }
    case ACCESS_ADD_POINT: {
      const accessPoints = state.accessPoints.map(access => {
        if (access.idAccess === payload.idAccess) {
          access.value = true
        }
        return access
      })
      return { ...state, accessPoints }
    }
    case ACCESS_DELETE_POINT: {
      const accessPoints = state.accessPoints.map(access => {
        if (access.idAccess === payload.idAccess) {
          access.value = false
        }
        return access
      })
      return { ...state, accessPoints }
    }

    case ACCESS_CHANGE_GROUP: {
      const accessGroups = state.accessGroups.map(group => {
        if (group.groupId === payload.groupId) {
          group.value = true
        } else {
          group.value = false
        }
        return group
      })
      return { ...state, accessGroups }
    }

    case ACCESS_GET_USER_NAME: {
      const userFullName = payload.fullName
      return { ...state, userFullName }
    }

    default:
      return state
  }
}
