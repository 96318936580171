import { connect } from 'react-redux'
import { compose } from 'recompose'

import withTranslation from '../../../../globals/components/withTranslation'

import Component from './component'

export default compose(
  withTranslation,
  connect(state => {
    return {
      langId: state.ui.langId,
    }
  })
)(Component)
