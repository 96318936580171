import React, { ChangeEvent } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import FlexBand from 'flexband'
import Icon from 'react-icons-kit'
import { ic_clear, ic_fullscreen, ic_fullscreen_exit, ic_place } from 'react-icons-kit/md'
import uuid from 'uuid/v4'
import Leaflet, { LeafletMouseEvent } from 'leaflet'
import * as esriLib from 'esri-leaflet-geocoder'
import { Field } from 'redux-form'
import RoutingService from '../../shared/routingService'
import dict from '../../shared/dictionaries'
import getCoords from '../../shared/utils/getCoords'
import { required } from '../../shared/form-validators'
import { toastr } from 'react-redux-toastr'
import 'mapbox-gl-leaflet'
import './styles.scss'
import { MapEvent, MapProps, MapState, Place } from './types'

const esri: any = esriLib

const DEFAULT_COORDS: LatLng = {
  lat: 47.0104529,
  lng: 28.86381019,
}

const ORS_API_KEY = '5b3ce3597851110001cf6248445945c97ec04aa3be04c19d065cb2ab'
const MAP_ID: { [key: string]: string; ru: string; en: string; ro: string } = {
  ru: 'b941ff5d-a071-4a05-ad50-a96bd099142e',
  en: '055c302a-4154-451e-af1a-8066fb1fff21',
  ro: 'd206eaa5-15a4-463f-9eb4-8e3e4f11134f',
}

// const TF_API_KEY = 'c296e32af5bc499c89c2c2d462ffea39'

export default class Map extends React.Component<MapProps, MapState> {
  token: string
  lang: Array<any>
  directionsService: RoutingService
  map: Leaflet.Map
  direction: any
  geocodeService: any
  countries: Array<any>
  timer: any

  constructor(props: MapProps) {
    super(props)
    this.state = {
      predictions: {
        from: null,
        to: null,
      },
      searchSuggestions: {
        from: [],
        to: [],
      },
      openSuggestions: false,
      directions: false,
      insertingItem: 'from',
      routeLength: 0,
      fullscreenMode: false,
      dragMode: false,
      hasInitPoints: false,
      readonlyCoordsSet: false,
      mapLoaded: false,
    }

    this.token = uuid()

    this.lang = []

    this.directionsService = new RoutingService()
    this.countries = []

    this.geocodeService = esri.geocodeService()
    this.map = null
  }

  findCity = async (
    searchEntity: LatLng | string | number,
    countryId: string | number,
    findBy: 'value' | 'coords'
  ) => {
    if (!searchEntity || !countryId) return

    if (findBy === 'coords') {
      searchEntity = (searchEntity as LatLng).lat + '/' + (searchEntity as LatLng).lng
    }

    let cities = dict.cities(this.props.langId, countryId)
    if (cities.loaded) {
      cities = cities.items
    } else {
      cities = await cities.handler.then(res => res.value || [])
    }

    return cities.find((x: any) => x[findBy] === searchEntity)
  }

  setInitPoints = async (): Promise<boolean> => {
    const { mapInitValues, mapPoints, handlePointLoadingPlace } = this.props

    if (mapInitValues && (mapInitValues.from || mapInitValues.to)) {
      for (const ins in mapInitValues) {
        const val: Place = mapInitValues[ins]
        if (!val || (val.city && val.city === mapPoints[ins].cityId)) {
          continue
        }
        if (ins === 'middlePoints') {
          const { middlePoints } = mapPoints
          for (const index in val) {
            if (!middlePoints[index]) {
              this.handleAddMiddlePoint()
            }
            this.createPlaceByCoords(val[index].coords, 'middlePoint-' + index)
            if (val[index].isLoadingPlace && !middlePoints[index].isLoadingPlace) {
              handlePointLoadingPlace(Number(index))
            }
          }
        }
        if (val.coords) {
          this.createPlaceByCoords(val.coords, ins)
        } else {
          const findCountry = (id: string | number) =>
            id && this.countries.find((x: any) => x.value === id)
          const country = findCountry(val.country)
          const city = await this.findCity(val.city, val.country, 'value')

          const cityCoords = city?.coords?.split('/')
          const countryCoords = country?.coords?.split('/')

          const coords: LatLng = city
            ? { lat: parseFloat(cityCoords[0]), lng: parseFloat(cityCoords[1]) }
            : country
              ? { lat: parseFloat(countryCoords[0]), lng: parseFloat(countryCoords[1]) }
              : null

          const place: Place = {
            country: country?.name ?? null,
            city: city?.name ?? null,
            coords,
          }

          const countryCity: string[] = [place.city as string, place.country as string]
          let countryCityString: string
          if (!countryCity[0] && !countryCity[1]) {
            countryCityString = ''
          } else if (!countryCity[0]) {
            countryCityString = countryCity[1]
          } else {
            countryCityString = countryCity.join(', ')
          }

          this.onClickMap(
            {
              ...place,
              value: countryCityString,
            },
            ins
          )
        }
      }
      return Promise.resolve(true)
    }
    return Promise.resolve(false)
  }

  componentDidMount() {
    new Promise((resolve, reject) => {
      const langs = dict.languages()

      if (langs.loaded) {
        this.lang = langs.items
        resolve(true)
      } else {
        langs.handler.then((items: any) => {
          this.lang = items.items
          resolve(true)
        })
      }
    })
      .then(() => {
        document.getElementById(`${this.token}_map-box`) && this.createMap()

        return new Promise(resolve => {
          const countries = dict.countries(this.props.langId)

          if (countries.loaded) {
            resolve(countries.items)
          } else {
            countries.handler.then((items: Array<any>) => {
              resolve(items)
            })
          }
        })
      })
      .then((countries: Array<any>) => {
        this.countries = countries
        return this.setInitPoints()
      })
      .then(hasInitPoints => {
        if (hasInitPoints || this.props.readonly) {
          const {
            mapPoints: {
              from: { coords: fromCoords },
              to: { coords: toCoords },
            },
          } = this.props

          if (fromCoords && toCoords) {
            this.fitMapView()
          } else {
            let counter = 0
            const interval = setInterval(() => {
              if (fromCoords && toCoords) {
                this.fitMapView()
                clearInterval(interval)
              }
              if (counter > 40) clearInterval(interval)
              counter++
            }, 300)
          }
        } else {
          this.map.locate()

          this.map.on('locationfound', (e: LeafletMouseEvent) => {
            this.map.setView(e.latlng, this.map.getZoom())
          })
        }
      })

    const { searchSuggestions } = this.state

    const setSearchSuggestions = () => {
      for (const ins in searchSuggestions) {
        const suggest: any[] = searchSuggestions[ins]
        if (suggest.length > 0) {
          suggest && this.onClickElement(suggest[0].text, ins)
        }
      }
    }

    window.addEventListener('click', (e: MapEvent) => {
      const { className } = e.target

      if (
        className &&
        typeof className !== 'object' &&
        !className.includes('paper-element') &&
        !className.includes('element-overflow')
      ) {
        setSearchSuggestions()
      }
    })

    window.addEventListener('keydown', e => {
      const { from, to } = searchSuggestions
      if (e.key === 'Enter' && (from[0] || to[0])) {
        setSearchSuggestions()
      }
    })
  }

  createMap = () => {
    const {
      mapPoints: {
        from: { marker: fromMarker },
        to: { marker: toMarker },
      },
      readonly,
    } = this.props

    this.map = Leaflet.map(`${this.token}_map-box`).setView(
      [DEFAULT_COORDS.lat, DEFAULT_COORDS.lng],
      11
    )
    this.map.options.minZoom = 5;
    this.map.options.maxZoom = 17;
    Leaflet.mapboxGL({
      // attribution:
      //   '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
      accessToken: 'pk.eyJ1IjoiYWRtbGV1IiwiYSI6ImNrdThkcm1kbjA3MGkycG16NGhuZ2s1bGUifQ.4QZ2thYZ_Cgcbg--yihkUw', //'not-needed',
      style: `https://api.maptiler.com/maps/${MAP_ID[this.getCurrentLang()]
        }/style.json?key=AXbwsykCJPXDsdnpfvhU`,
    }).addTo(this.map)

    this.setState({ mapLoaded: true }, () => {
      readonly && this.readonlySetPointMarkers()
    })

    if (fromMarker) {
      fromMarker.addTo(this.map)
    }

    if (toMarker) {
      toMarker.addTo(this.map)
    }

    const Fullscreen = Leaflet.Control.extend({
      onAdd: () => {
        return document.getElementById(`${this.token}_fs-button`)
      },
    })

    new Fullscreen({ position: 'topleft' }).addTo(this.map)

    if (!readonly) {
      const RouteButton = Leaflet.Control.extend({
        onAdd: () => {
          return document.getElementById(`${this.token}_set-directions`)
        },
      })

      new RouteButton({ position: 'topright' }).addTo(this.map)

      this.map.on('click', (e: LeafletMouseEvent) => {
        const { tagName, id } = e.originalEvent.target as HTMLElement

        if (
          tagName !== 'svg' &&
          tagName !== 'i' &&
          id !== `${this.token}_set-directions` &&
          id !== `${this.token}_fs-button` &&
          id !== `${this.token}_route_button` &&
          !this.state.dragMode
        ) {
          this.createPlaceByCoords(e.latlng)
        }
      })
    }
  }

  createPlaceByCoords = (coords: LatLng, insertTo?: string, fromDrag?: boolean) => {
    this.reverseGeocode(coords).then(async ({ LongLabel, CountryCode, City }) => {
      const countryData = this.countries.find(x => x.iso3 === CountryCode)
      const countryId = countryData ? countryData.value : null
      const cityCoords: LatLng = await this.getAddressCoords(City).catch(() => null)
      const cityId: number = await this.findCity(cityCoords, countryId, 'coords').then(
        res => res && res.value
      )

      this.onClickMap(
        {
          value: LongLabel,
          coords,
          country: CountryCode,
          countryId,
          cityId,
          city: cityCoords,
        },
        insertTo,
        fromDrag
      )
    })
  }

  readonlySetPointMarkers = () => {
    const {
      from: { coords: fromCoords },
      to: { coords: toCoords },
      middlePoints,
    } = this.props.mapPoints

    if (fromCoords && toCoords && this.state.mapLoaded) {
      this.setMarker(fromCoords, 'from')
      this.setMarker(toCoords, 'to')

      if (middlePoints.length > 0) {
        middlePoints.forEach((point, id) => {
          this.setMarker(point.coords, 'middlePoint-' + id)
        })
      }

      this.changeDirectionState()
      this.setState({ readonlyCoordsSet: true })
    }
  }

  componentDidUpdate(oldProps: MapProps) {
    const { readonly, mapInitValues, mapPoints } = this.props
    if (!_.isEqual(oldProps.mapInitValues, mapInitValues)) {
      this.setInitPoints()
    }

    if (readonly && !_.isEqual(oldProps.mapPoints, mapPoints) && !this.state.readonlyCoordsSet) {
      this.readonlySetPointMarkers()
    }
  }

  componentWillUnmount() {
    const {
      mapPoints: {
        from: { marker: fromMarker },
        to: { marker: toMarker },
        middlePoints,
      },
    } = this.props

    fromMarker && fromMarker.remove()
    toMarker && toMarker.remove()
    middlePoints &&
      middlePoints.map(middlePoint => {
        middlePoint.marker?.remove()
      })
  }

  getCurrentLang = (): string => {
    return this.lang.find((x: any) => x.value === this.props.langId).nameLo
  }

  /**
   * @param {Place} place Address and coordinate
   * @param {string} insertingItem Input to be filled with place data
   * @param {boolean} fromDrag Should be sent if function was invoked by marker dragging
   */
  onClickMap = (
    place: Place,
    insertingItem: string = this.state.insertingItem,
    fromDrag?: boolean
  ) => {
    const { onClick } = this.props
    const { searchSuggestions, directions } = this.state

    if (fromDrag) {
      directions && this.changeDirectionState(place.coords, insertingItem)

      onClick(place, insertingItem)

      return
    }

    onClick(place, insertingItem)

    if (insertingItem && !place.coords) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      if (place.value) {
        this.timer = setTimeout(() => {
          this.geocodeService
            .suggest()
            .text(place.value)
            .run((err, results) => {
              if (!err) {
                searchSuggestions[insertingItem] = results.suggestions
                this.setState({ searchSuggestions })
              }
            })
        }, 400)
      } else {
        const { searchSuggestions } = this.state

        searchSuggestions[insertingItem] = []

        this.setState({ searchSuggestions: { ...searchSuggestions, [insertingItem]: [] } })
      }
    }

    if (place.coords) {
      this.setMarker(place.coords, insertingItem)
    }

    if (!place.value) {
      this.removeMarker(insertingItem)
    }
  }

  getInsertingMarker = (insertingItem: string): Place => {
    if (!insertingItem) return
    const { mapPoints } = this.props
    const item = insertingItem.split('-')
    return item[0] !== 'middlePoint' ? mapPoints[insertingItem] : mapPoints.middlePoints[item[1]]
  }

  /**
   * Sets marker on map depending on what "insertedTo" param was given
   */
  setMarker = (clickCoords: LatLng, insertingItem: string) => {
    if (!insertingItem) {
      insertingItem = this.state.insertingItem
    }

    const { mapPoints, readonly } = this.props
    let markerLabelText: string

    if (insertingItem === 'from') {
      markerLabelText = 'A'
    } else if (insertingItem === 'to') {
      markerLabelText = 'B'
    } else {
      const item: string[] = insertingItem.split('-')
      if (mapPoints.middlePoints[item[1]].isLoadingPlace) {
        markerLabelText = 'B'
      } else {
        markerLabelText = 'middle'
      }
    }

    const point = this.getInsertingMarker(insertingItem)
    if (point.marker) {
      point.marker.setLatLng(clickCoords).addTo(this.map)
    } else {
      point.marker = Leaflet.marker([clickCoords.lat, clickCoords.lng], {
        title: markerLabelText,
        draggable: !readonly,
      }).addTo(this.map)

      this.setMarkerIcon(insertingItem, markerLabelText)

      if (!readonly) {
        point.marker.on('dragstart', () => {
          this.setState({ dragMode: true })
        })

        point.marker.on('dragend', () => {
          setTimeout(() => this.setState({ dragMode: false }), 500)
        })

        point.marker.on(
          'drag',
          _.debounce((e: LeafletMouseEvent) => {
            this.createPlaceByCoords(e.latlng, insertingItem, true)
          }, 500)
        )
      }
    }

    if (this.state.directions) {
      this.changeDirectionState(clickCoords, insertingItem)
    } else {
      this.map.setView(clickCoords, this.map.getZoom())
    }
  }

  setMarkerIcon = (insertingItem: string, markerType: string) => {
    const marker = this.getInsertingMarker(insertingItem).marker
    marker.setIcon(
      Leaflet.icon({
        iconUrl: `/images/map-markers/marker[${markerType}].png`,
        iconAnchor: [13, 30],
        iconSize: [30, 87],
      })
    )
  }

  removeMarker = (insertingItem: string) => {
    const marker = this.getInsertingMarker(insertingItem).marker
    marker && marker.remove()
    // const insItem = insertingItem.split('-')
    // insItem[0] === 'middlePoint' && this.props.mapPoints.middlePoints.splice(parseInt(insItem[1]), 1)

    if (this.state.directions) {
      this.removeDirections()
    }
  }

  handleDirectionsClick = () => {
    const { directions } = this.state

    if (!directions) {
      this.getDirections()
    } else {
      this.removeDirections()
    }
  }

  removeDirections = () => {
    this.setState({ directions: false, routeLength: 0 })
    this.direction && this.direction.remove()
  }

  fitMapView = (coords?: LatLng, insertingItem?: string) => {
    try {
      const {
        mapPoints: { from, to, middlePoints = [] },
      } = this.props

      const fromCoords = insertingItem === 'from' ? coords : from.coords
      const toCoords = insertingItem === 'to' ? coords : to.coords

      this.map.fitBounds(getCoords(fromCoords, toCoords, middlePoints, 'lat'))
    } catch (e) {
      console.error(e)
      return
    }
  }

  changeDirectionState = (coords?: LatLng, insertingItem?: string) => {
    let {
      mapPoints: {
        from: { coords: fromCoords },
        to: { coords: toCoords },
      },
    } = this.props

    const { t, setSingleCargoDistance, mapPoints, readonly } = this.props

    if (!(fromCoords.lat === toCoords.lat && fromCoords.lng === toCoords.lng)) {
      this.removeDirections()

      const middleCoords = []

      mapPoints.middlePoints.forEach(point => {
        if (point.coords) {
          const { lng, lat } = point.coords
          middleCoords.push([lng, lat])
        }
      })

      if (coords) {
        const insItemSplice = insertingItem.split('-')

        if (insItemSplice[0] === 'middlePoint') {
          middleCoords[insItemSplice[1]] = [coords.lng, coords.lat]
        } else {
          insertingItem === 'from'
            ? (fromCoords = coords)
            : insertingItem === 'to'
              ? (toCoords = coords)
              : null
        }
      }

      const coordinates = [
        [fromCoords.lng, fromCoords.lat],
        ...middleCoords,
        [toCoords.lng, toCoords.lat],
      ]

      this.directionsService
        .calculate(coordinates)
        .then(res => {
          this.direction = Leaflet.geoJSON(res).addTo(this.map)
          const distance = parseFloat(
            (res.features[0].properties.summary.distance / 1000).toFixed(2)
          )
          this.setState({
            routeLength: distance,
            directions: true,
          })

          readonly && setSingleCargoDistance(distance)
        })
        .catch(e => {
          if (e.status === 400) {
            toastr.error('', t('maps.directions.too.big.distance'))
          } else if (e.status === 404) {
            toastr.error('', t('maps.directions.not.found'))
          }
        })

      this.fitMapView(coords, insertingItem)
    } else {
      toastr.error('', t('maps.directions.calc.error'))
    }
  }

  getDirections = () => {
    const { from, to } = this.props.mapPoints

    if (from.coords && to.coords) {
      this.changeDirectionState()
    }
  }

  onFocusChange = insertingItem => {
    this.setState({ insertingItem })
  }

  getAddressCoords = (text: string): Promise<LatLng> => {
    return new Promise((resolve, reject) => {
      esri
        .geocode()
        .text(text)
        .run((err, results) => {
          if (!err) {
            if (results.results.length > 0) {
              resolve(results.results[0].latlng)
            } else {
              resolve(null)
            }
          } else {
            reject('Geocoding error!' + err)
          }
        })
    })
  }

  reverseGeocode = (coords: LatLng): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.geocodeService
        .reverse()
        .latlng(coords)
        .language('RU')
        .run((err, results) => {
          if (!err) {
            resolve({ ...results.address })
          } else {
            console.error(err)
            reject('Reverse geocoding error!')
          }
        })
    })
  }

  onClickElement = (value: string, insertTo: string) => {
    this.getPlaceObject(value, insertTo)
  }

  getPlaceObject = async (value: string, insertTo: string) => {
    if (!value) return
    const coords: LatLng = await this.getAddressCoords(value)

    const { CountryCode, City } = await this.reverseGeocode(coords)
    const countryId = this.countries?.find(x => x.iso3 === CountryCode)?.value
    const cityCoords: LatLng = await this.getAddressCoords(City).catch(() => null)
    const cityId: number = await this.findCity(cityCoords, countryId, 'coords').then(
      res => res && res.value
    )

    this.onClickMap(
      {
        value,
        coords,
        country: CountryCode,
        countryId,
        cityId,
        city: cityCoords || null,
      },
      insertTo
    )

    const { searchSuggestions } = this.state

    searchSuggestions[insertTo] = []

    this.setState({ searchSuggestions })
  }

  toggleFullscreenMode = () => {
    const { fullscreenMode } = this.state
    const map = document.getElementById(`${this.token}_map-box`)

    if (!fullscreenMode) {
      map.requestFullscreen()
    } else {
      document.exitFullscreen()
    }

    this.setState({ fullscreenMode: !fullscreenMode })
  }

  removeMiddlePoint = id => {
    const prevField = id > 0 ? `middlePoint-${id - 1}` : 'to'

    this.onClickMap({ value: '', coords: null }, `middlePoint-${id}`)
    this.onFocusChange(prevField)
    this.props.onMapRemoveMiddlePoint(id)
  }

  handleLoadingPointClick = (e: ChangeEvent, id: number) => {
    const insertingItem = 'middlePoint-' + id
    const target = e.target as HTMLInputElement
    if (this.getInsertingMarker(insertingItem).marker) {
      const markerType = target.checked ? 'B' : 'middle'

      this.setMarkerIcon(insertingItem, markerType)
    }

    this.props.handlePointLoadingPlace(id)
  }

  handleAddMiddlePoint = () => {
    this.props
      .onMapAddMiddlePoint()
      .then(id => {
        this.onFocusChange(`middlePoint-${id}`)
      })
      .catch(e => {
        console.error(e)
      })
  }

  render() {
    const { t, itemLabelFrom, itemLabelTo, mapPoints, readonly } = this.props
    const { searchSuggestions, directions, routeLength, fullscreenMode } = this.state

    return (
      <div className="panel-content-row google-maps">
        {!readonly && (
          <div className="panel-form-row" style={{ marginBottom: 10 }}>
            <div className="row">
              <div className="col-sm-8">
                <label htmlFor="i0" className="required-field">
                  {itemLabelFrom}
                </label>
                <div className="select-container">
                  <FlexBand wrap="nowrap">
                    <MapSelectButton
                      selected={this.state.insertingItem === 'from'}
                      onClick={() => this.onFocusChange('from')}
                    />
                    <div className="input-wrapper">
                      <Field
                        className="input-field"
                        component="input"
                        autoComplete="off"
                        type="text"
                        name="from.value"
                        id={`${this.token}address-from`}
                        onFocus={() => this.onFocusChange('from')}
                        onChange={e =>
                          this.onClickMap({ value: e.target.value, coords: null }, 'from')
                        }
                        validate={required}
                      />
                      <ClearInput
                        onClick={() => this.onClickMap({ value: '', coords: null }, 'from')}
                      />
                    </div>
                    <ResultsPaper
                      results={searchSuggestions.from}
                      paperFor="from"
                      onClick={this.onClickElement}
                    />
                  </FlexBand>
                </div>
              </div>
              <div className="col-sm-8">
                <label htmlFor="i01" className="required-field">
                  {itemLabelTo}
                </label>
                <div className="select-container">
                  <FlexBand wrap="nowrap">
                    <MapSelectButton
                      selected={this.state.insertingItem === 'to'}
                      onClick={() => this.onFocusChange('to')}
                    />
                    <div className="input-wrapper">
                      <Field
                        className="input-field"
                        component="input"
                        autoComplete="off"
                        type="text"
                        name="to.value"
                        id={`${this.token}address-to`}
                        onFocus={() => this.onFocusChange('to')}
                        onChange={e =>
                          this.onClickMap({ value: e.target.value, coords: null }, 'to')
                        }
                        validate={required}
                      />
                      <ClearInput
                        onClick={() => this.onClickMap({ value: '', coords: null }, 'to')}
                      />
                    </div>
                    <ResultsPaper
                      results={searchSuggestions.to}
                      paperFor="to"
                      onClick={this.onClickElement}
                    />
                  </FlexBand>
                </div>
              </div>
            </div>
            <div>
              {mapPoints.middlePoints.map((x, id) => (
                <div key={id} className="select-container" style={{ paddingTop: 6, width: '100%' }}>
                  <FlexBand wrap="nowrap" style={{ position: 'relative' }}>
                    <MapSelectButton
                      selected={this.state.insertingItem === `middlePoint-${id}`}
                      onClick={() => this.onFocusChange(`middlePoint-${id}`)}
                    />
                    <div className="input-wrapper" style={{ width: '44%' }}>
                      <input
                        className="input-field"
                        autoComplete="off"
                        type="text"
                        name={`middlePoint${id}.point`}
                        id={`${this.token}address-middlePoint${id}`}
                        onFocus={() => this.onFocusChange(`middlePoint-${id}`)}
                        onChange={e =>
                          this.onClickMap(
                            { value: e.target.value, coords: null },
                            `middlePoint-${id}`
                          )
                        }
                        value={x.value}
                      />
                      <ClearInput onClick={() => this.removeMiddlePoint(id)} />
                    </div>
                    {/* <input
                      type="checkbox"
                      id={`${this.token}_check_middlePoint${id}`}
                      checked={x.isLoadingPlace}
                      onChange={e => this.handleLoadingPointClick(e, id)}
                    />
                    <label
                      htmlFor={`${this.token}_check_middlePoint${id}`}
                      className="loading-place-checkbox"
                    >
                      {t('cargo.loading.unloading')}
                    </label> */}
                    <Link
                      to="#"
                      style={{ paddingTop: 8, paddingLeft: 6 }}
                      onClick={() => this.removeMiddlePoint(id)}
                    >
                      {t('remove')}
                    </Link>
                    <ResultsPaper
                      results={searchSuggestions[`middlePoint-${id}`]}
                      positionLeft={32}
                      positionTop={34}
                      paperFor={`middlePoint-${id}`}
                      onClick={this.onClickElement}
                    />
                  </FlexBand>
                </div>
              ))}
            </div>
            {mapPoints.middlePoints.length <= 10 && (
              <div style={{ paddingTop: 6 }}>
                <Link to="#" onClick={this.handleAddMiddlePoint}>
                  {t('cargo.add.middle.point')}
                </Link>
              </div>
            )}
          </div>
        )}
        <div className="transport-map" id={`${this.token}_map-box`} />
        <div
          className="fullscreen-button"
          id={`${this.token}_fs-button`}
          onClick={this.toggleFullscreenMode}
        >
          <Icon
            icon={fullscreenMode ? ic_fullscreen_exit : ic_fullscreen}
            className="fullscreen-icon"
          />
        </div>
        {!readonly && (
          <div
            className="directions-container"
            id={`${this.token}_set-directions`}
            onClick={this.handleDirectionsClick}
          >
            <div className="directions-button" id={`${this.token}_route_button`}>
              {!directions ? t('maps.get.directions') : t('maps.remove.directions')}
            </div>
            {directions && (
              <span className="distance-box">
                {t('maps.distance')}: {routeLength} {t('unit.distance.km')}
              </span>
            )}
          </div>
        )}
      </div>
    )
  }
}

export const ClearInput = props => {
  return (
    <div
      style={{ color: 'gray', cursor: 'pointer', display: 'inline-block' }}
      onClick={props.onClick}
    >
      <Icon icon={ic_clear} size={14} />
    </div>
  )
}

export const MapSelectButton = ({ selected, onClick }) => {
  return (
    <FlexBand
      className="select-button"
      justify="center"
      align="center"
      style={{ color: selected ? 'red' : 'gray' }}
      onClick={onClick}
    >
      <Icon icon={ic_place} size={18} />{' '}
    </FlexBand>
  )
}

export const ResultsPaper = ({
  results = [],
  onClick,
  paperFor,
  positionTop = null,
  positionLeft = null,
}) => {
  return results.length > 0 ? (
    <div className="map-paper" style={{ left: positionLeft, top: positionTop }}>
      {results.map((result, id) => (
        <div key={id} className="paper-element" onClick={() => onClick(result.text, paperFor)}>
          <span className="element-overflow">{result.text}</span>
        </div>
      ))}
    </div>
  ) : null
}
