import { connect } from 'react-redux'
import { compose } from 'recompose'
import { formValueSelector } from 'redux-form'

import withTranslation from '../../../../globals/components/withTranslation'
import withPermissions from '../../../../globals/components/withPermissions'

import { setActivePopup } from '../../../../store/actions/ui'

import Component from './component'

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state, props) => {
      const { formName, basePropName } = props
      const formSelector = formValueSelector(formName)
      return {
        activeGuid: state.ui.activePopupGuid,
        day: formSelector(state, `${basePropName}.day`),
        month: formSelector(state, `${basePropName}.month`),
        year: formSelector(state, `${basePropName}.year`),
      }
    },
    {
      setActivePopup,
    }
  )
)(Component)
