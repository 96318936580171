import { connect } from 'react-redux'
import { compose } from 'recompose'

// import withTranslation from '../../../../globals/components/withTranslation'
// import withPermissions from '../../../../globals/components/withPermissions'

import Component from './component'

// export default Component

export default compose(
  // withTranslation,
  // withPermissions,
  connect((state, props) => {
    const { itemId } = props.match.params

    return {
      itemId,
    }
  })
)(Component)
