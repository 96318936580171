const formNames = [] // for closure

const getFormNames = (fields, prefix) => {
  // eslint-disable-next-line no-unused-vars
  for (const i in fields) {
    const fieldName = prefix !== '' ? prefix + '.' + i : i
    if (typeof fields[i] === 'object') {
      getFormNames(fields[i], fieldName)
    } else {
      formNames.push(fieldName)
    }
  }

  return formNames
}

export default getFormNames
