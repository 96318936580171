import React, { Component } from 'react'
import Ads from '../list-mini'
import ItemInfo from './item-info'
import Banner from '../../banner'
import Forum from '../../forum'
import { BANNER_THIN_SIZE } from '../../../store/constants'

export default class extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-10 col-lg-9">
          <ItemInfo />
        </div>
        <div className="col-md-6 col-lg-7">
          <Ads />
          <Banner bannerId={577} />
          <Forum />
        </div>
      </div>
    )
  }
}
