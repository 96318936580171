//import _ from 'lodash'
import { fromJS } from 'immutable'
import * as actionTypes from '../actions/action-types'
import { baseProps, CargoSearchFormModel, CargoItemModel } from '../models'

const initialState = fromJS({
  lastSearch: fromJS({
    loading: false,
    loaded: false,
    totalItems: 0,
    items: [],
    error: undefined,
  }),
  search: fromJS({
    processing: false,
    refresh: false,
    totalItems: 0,
    pageSize: 20,
    pageIndex: 0,
    sortBy: 'date-add',
    dateScope: 'all', //'from-last-visit',
    items: [],
    queryStr: '',
    error: undefined,
  }),
  searchCounterOffer: fromJS({
    processing: false,
    totalItems: 0,
    pageSize: 20,
    pageIndex: 0,
    sortBy: 'date-add',
    dateScope: 'new',
    items: [],
    error: undefined,
  }),
  query: {
    ...CargoSearchFormModel,
  },
  item: fromJS({
    ...baseProps,
    ...CargoItemModel,
  }),
  edit: fromJS({}),
  templates: [],
  autoUpdate: false,
})

const changeFavourite = (state, cargoId, favStatus) => {
  let itemId
  let newSearch = state.get('search')
  const items = newSearch.get('items')
  if (items.size !== 0) {
    const item = items.find((x, id) => {
      itemId = id
      return x.get('itemId') == cargoId
    })
    const newItem = item.set('isFavourite', favStatus)
    newSearch = newSearch.set('items', items.set(itemId, newItem))
  }
  return state.set('search', newSearch)
}

export default (state = initialState, action) => {
  const { type, payload, error } = action
  switch (type) {
    case actionTypes.CARGO_SEARCH_START: {
      const { pageIndex, pageSize, sortBy, dateScope, query, sameCountryOnly } = payload
      const search = fromJS({
        processing: true,
        totalItems: 0,
        pageIndex,
        pageSize,
        sortBy,
        dateScope,
        error: undefined,
        sameCountryOnly
      })
      return state.mergeIn(['search'], search).set('query', query)
    }
  
    case actionTypes.SET_GLOBAL_AUTOUPDATE: {
      return state.set('autoUpdate', fromJS(payload))
    }

    case actionTypes.CARGO_SEARCH_SUCCESS: {
      const { items, pageSize, pageIndex, totalItems, queryStr } = payload
      const search = fromJS({
        processing: false,
        totalItems,
        pageSize,
        pageIndex,
        items,
        queryStr: queryStr || '',
        error: undefined,
      })
      return state.mergeIn(['search'], search)
    }
    case actionTypes.CARGO_SEARCH_FAILURE: {
      const search = fromJS({
        processing: false,
        totalItems: 0,
        items: [],
        error,
      })
      return state.mergeIn(['search'], search)
    }

    case actionTypes.CARGO_GET_UNBLOCKED_SUCCESS: {
      const search = state.get('search').toJS()

      for (const i in search.items) {
        if (search.items[i].itemId == payload.itemId) {
          search.items[i] = payload
        }
      }

      return state.set('search', fromJS(search))
    }

    case actionTypes.CARGO_LAST_SEARCH_START: {
      const lastSearch = fromJS({
        loading: true,
        loaded: false,
        totalItems: 0,
        error: undefined,
      })
      return state.mergeIn(['lastSearch'], lastSearch)
    }
    case actionTypes.CARGO_DELETE_LAST_SEARCH_SUCCESS:
    case actionTypes.CARGO_LAST_SEARCH_SUCCESS: {
      const { items, totalItems } = payload
      const lastSearch = fromJS({
        loading: false,
        loaded: true,
        totalItems,
        items,
        error: undefined,
      })
      return state.mergeIn(['lastSearch'], lastSearch)
    }
    case actionTypes.CARGO_LAST_SEARCH_FAILURE: {
      const lastSearch = fromJS({
        loading: false,
        loaded: false,
        totalItems: 0,
        items: [],
        error,
      })
      return state.mergeIn(['lastSearch'], lastSearch)
    }
    case actionTypes.CARGO_ADD_FAVOURITE_SUCCESS: {
      const { item_id, isItem } = payload
      const itemState = state.mergeIn(['item'], fromJS({ isFavourite: true }))
      return changeFavourite(isItem ? itemState : state, item_id, true)
    }
    case actionTypes.CARGO_DELETE_FAVOURITE_SUCCESS: {
      const { cargoId, isItem } = payload
      const itemState = state.mergeIn(['item'], fromJS({ isFavourite: false }))
      return changeFavourite(isItem ? itemState : state, cargoId, false)
    }
    case actionTypes.CARGO_ITEM_LOADING: {
      const { itemId, requestedAt } = payload
      return state.mergeIn('item', {
        requestedAt,
        loading: true,
        error: undefined,
        itemId,
      })
    }
    case actionTypes.CARGO_ITEM_LOADED: {
      const item = {
        ...action.data,
        error: undefined,
        loading: false,
      }
      return action.isEdit ? state.mergeDeep({ edit: item }) : state.mergeDeep({ item })
    }
    case actionTypes.CARGO_ITEM_LOAD_ERROR: {
      return state.mergeIn('item', {
        loading: false,
        error,
      })
    }

    case actionTypes.CARGO_TEMPLATE_SAVED: {
      return state.set('templates', payload)
    }
    case actionTypes.CARGO_DELETE_TEMPLATE_SUCCESS:
    case actionTypes.CARGO_TEMPLATES_LOADED: {
      return state.set('templates', payload)
    }

    case actionTypes.CARGO_CHANGE_ACTIVE: {
      const active = !state.getIn(['item', 'active'])
      const hasActive = state.get('item').has('active')
      const search = state.get('search').toJS()
      for (const i in search.items) {
        if (search.items[i].itemId === payload.cargoId) {
          search.items[i].active = !search.items[i].active
        }
      }
      if (!hasActive) {
        return state.set('search', fromJS(search))
      }
      return state.mergeIn(['item', 'active'], active).set('search', fromJS(search))
    }

    default:
      return state
  }
}
