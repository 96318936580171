import React, { Component } from 'react'
import NewsList from '../../news/list-mini'
import AdsList from '../../ads/list-mini'
import ForumList from '../../forum'
import EmployeeEditForm from './employee-edit-form'

export default class extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-10 col-xl-9">
          <EmployeeEditForm />
        </div>
        <div className="col-md-6 col-xl-7">
          <NewsList />
          <AdsList />
          <ForumList />
        </div>
      </div>
    )
  }
}
