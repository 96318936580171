import classnames from 'classnames'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { ModalConsumer, ModalContextState } from '../../../context/modal-context'
import React, { Fragment } from 'react'
import { AdsTableProps, AdsTableState, DelayTimer } from './types'
import Pagination from '../../UI/pagination'
import TooltipWrapper from '../../UI/popover-wrapper'
import TooltipModal from '../../search/table/tooltip-modal'
import './styles.scss'
import fetch from '../../shared/fetch'
import AdsModalContent from './ads-modal-content'
import Banner from '../../banner'
import ruWordNumeralEnding from '../../shared/ruWordNumeralEnding'
export default class AdsTable extends React.Component<AdsTableProps, AdsTableState> {
  modalConsumer: ModalContextState
  timerWorker: DelayTimer

  constructor(props) {
    super(props)
    this.state = {

      timers: [],
      itemId: 0,
      adsUserId: 0,
      isFavourite: 0,
      timeout: 0,
    }

    this.timerWorker = new DelayTimer({
      timerUpdate: (timerId, timeRemaining) => {
        const { timers } = this.state
        timers[timerId] = timeRemaining
        this.setState({ timers })

      },
      timerEnd: timerId => {
        const { timers } = this.state
        const { fetchAds, pageIndex, pageSize, dateString, filterKey } = this.props

        this.setState({ timers })
        fetchAds(pageIndex, pageSize, dateString, filterKey)
      },
    })
  }

  componentDidUpdate() {
    const { ads } = this.props

    ads.forEach(({ viewTimeout, id }) => {
      if (viewTimeout > 0) {
        this.timerWorker.setTimer(Number(id), null, viewTimeout)
      }
    })
  }

  componentWillUnmount() {
    this.timerWorker.cancelAllTimers()
  }


  singleAdsPageRedirect = (adId: number): void => this.props.history.push(`/ads/item/${adId}`)

  toggleAdFavourite = (adId: number, adIndex: number, adIsFavourite?: number): void => {
    const body = JSON.stringify({ adId, alreadyFavourite: adIsFavourite })

    fetch('/announcement/toggleFavourite', { method: 'POST', body }).then(_ => {
      this.props.ads[adIndex].is_favourite = adIsFavourite ? 0 : 1
      this.forceUpdate()
    })
  }

  onClickRow = (itemId: number, adsUserId: number, isFavourite, timeout: number) => {
    const { openIn } = this.props
    if (itemId) {
      if (openIn === 'window') {
        this.props.history.push(`/ads/item/${itemId}`)
        this.setState({ itemId: null })
      } else {
        this.setState({ itemId, adsUserId, isFavourite, timeout }, this.showItemModal)
      }
    }
  }

  refreshBlock = () => {
    const { refresh } = this.props
    const { clearModal } = this.modalConsumer
    refresh(0)
    clearModal()
  }

  showItemModal = () => {
    const { loggedUserId, permissions } = this.props
    const { itemId, adsUserId, isFavourite, timers, timeout } = this.state
    const { showModalComponent, clearModal } = this.modalConsumer
    const timer = timeout > 0 ? timers[itemId]?.toFixed(0) : 0
    showModalComponent(
      <AdsModalContent
        onClose={this.refreshBlock}
        itemId={itemId}
        adsUserId={adsUserId}
        loggedUserId={loggedUserId}
        {...{ permissions, timer, isFavourite }}
      />,
      {
        className: `transportads-modal`,
        hideHeader: true,
        skipLayout: true,
        backdrop: true,
      }
    )
  }

  contentVisible = (ad, hideCompanyName) => {
    return (
      <div className="author-container">
        <Link to={`/user/details/${ad.user_id}`}>
          <TooltipWrapper
            text={<TooltipModal companyId={0} userId={ad.user_id} />}
            hover
            innerClassName="full-tooltip"
            parentCustomClass="move-up-container"
            disabled={false}
          >
            <span>{`${ad.user_name || ""} ${ad.user_last_name || ""}`}</span>
          </TooltipWrapper>
        </Link>
        {Number(ad.company_id) > 0 && !hideCompanyName && (
          <Link to={`/company/item/${ad.company_id}`}>
            <TooltipWrapper
              text={
                <TooltipModal
                  companyId={ad.company_id}
                  companyName={ad.company_name && ad.company_name.toUpperCase()}
                  userId={0}
                />
              }
              hover
              innerClassName="full-tooltip"
              disabled={false}
            >
              <span
                className="has-icon"
                style={{ paddingLeft: ad.verified === 1 && 15 }}
              >
                {ad.verified === 1 && (
                  <span className="icon">
                    <i className="fa fa-check-circle"></i>
                  </span>
                )}
                {ad.company_name && ad.company_name.toUpperCase()}
              </span>
            </TooltipWrapper>
          </Link>
        )}
      </div>
    )
  }

  contentTimer = (itemId) => {
    const { t, langId } = this.props
    const { timers } = this.state
    const timeRemaining = timers[itemId]?.toFixed(0)
    return (

      <div>
        {t('time.ads.restriction.wait.first', { time: timeRemaining })}
        {` `}{ruWordNumeralEnding(
          t,
          parseInt(timeRemaining),
          'time.restriction.wait.second',
          langId
        )}
      </div>

    )
  }

  render() {
    const {
      t,
      isAuthenticated,
      ads,
      refresh,
      totalItems,
      pageIndex,
      pageSize,
      embeddable,
      loggedUserId,
      hideCompanyName = false,
    } = this.props

    return (
      <Fragment>
        <div>
          <table
            className={classnames({
              table: true,
              'table-ads': true,
              'table-link': true,
              'table-override-headers': embeddable,
            })}
          >
            <thead
              className={classnames({
                'table-transportads-header': true,
                'override-borders-header': embeddable,
              })}
            >
              <tr>
                <th className="th-date">{t('date')}</th>
                <th className="th-lg">{t('ads.title')}</th>
                {!embeddable && <th className="th-date">{t('author')}</th>}
                {embeddable && isAuthenticated && <th className="th-lg"></th>}
              </tr>
            </thead>
            <tbody
              className={classnames({
                'table-transportads-body': true,
                'override-borders': embeddable,
                'small-table': ads.length < 4,
              })}
            >
              {ads.length > 0 ? (
                ads.map((ad, index) => (
                  <Fragment key={ad.id}>
                    <tr className="transportads-line">
                      <td
                        className="td-date"
                        onClick={() => this.onClickRow(ad.id, ad.user_id, ad.is_favourite, ad.viewTimeout)}
                      >
                        <span>{moment(ad.date).format('DD.MM.YY')}</span>
                      </td>
                      <td
                        className="td-info"
                        onClick={() => this.onClickRow(ad.id, ad.user_id, ad.is_favourite, ad.viewTimeout)}
                      >
                        {embeddable ? (
                          <>
                            {ad.viewTimeout > 0 ?
                              this.contentTimer(ad.id) :
                              <span>{ad.title}</span>
                            }
                          </>
                        ) : (
                          <span>{ad.title}</span>
                        )}
                      </td>
                      {!embeddable && (
                        <td className="td-company">
                          <div className="ads-info-container">
                            {ad.viewTimeout > 0 ?
                              this.contentTimer(ad.id) :
                              this.contentVisible(ad, hideCompanyName)}

                            <div className="fav-container">
                              {isAuthenticated && (
                                <span
                                  className={classnames('favorite', ad.is_favourite && 'is-active')}
                                  onClick={() =>
                                    this.toggleAdFavourite(ad.id, index, ad.is_favourite)
                                  }
                                >
                                  <i className="fa fa-bookmark"></i>
                                </span>
                              )}
                            </div>
                          </div>
                        </td>
                      )}

                      {embeddable && (isAuthenticated || loggedUserId) && (
                        <td>
                          <div className="fav-container">
                            <span
                              className={classnames('favorite', ad.is_favourite && 'is-active')}
                              onClick={() => this.toggleAdFavourite(ad.id, index, ad.is_favourite)}
                            >
                              <i className="fa fa-bookmark"></i>
                            </span>
                          </div>
                        </td>
                      )}
                    </tr>
                    {((ads.length < 5 && index === ads.length - 1) || index === 4) &&
                      !embeddable && <AdvertisementTableRow />}
                  </Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    <h1 className="no-transportads-text">{t('ads.no.new')}</h1>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalItems === 0 && <Banner bannerId={574} />}
          <Pagination
            onClick={refresh}
            total={totalItems}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />
        </div>
        <ModalConsumer>
          {modalConsumer => {
            this.modalConsumer = modalConsumer
            return null
          }}
        </ModalConsumer>
      </Fragment>
    )
  }
}

const AdvertisementTableRow = () => (
  <tr className="tr-link">
    <td colSpan={8} className="td-lg">
      <Banner bannerId={574} />
    </td>
  </tr>
)
