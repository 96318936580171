export const int6 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>ЕВРОПЕЙСКАЯ ЭКОНОМИЧЕСКАЯ КОМИССИЯ ООН</h4><p></p>

<p></p><h4>РЕКОМЕНДАЦИЯ N 19</h4><p></p>

<p></p><h4>КЛАССИФИКАТОР ВИДОВ ТРАНСПОРТА</h4><p></p>

<p>(Женева, март 1981 года)</p><br>

<p style="text-indent:2em;">Рабочая группа по упрощению процедур международной торговли: рекомендует применять кодовые обозначения, описанные ниже, в тех
 случаях, когда имеется необходимость кодированного представления для указания вида транспорта для целей международной 
 торговли.</p>

<p style="text-indent:2em;"></p><h6>В работе тринадцатой сессии Рабочей группы приняли участие представители:</h6><p></p>

<p style="text-indent:2em;">Австрии, Бельгии, Болгарии, Венгрии, Германской Демократической Республики, Федеративной Республики Германии, Греции, Дании, Италии
, Канады, Нидерландов, Норвегии, Польши, Румынии, Соединенного Королевства Великобритании и Северной Ирландии, Соединенных Штатов Америки
, Союза Советских Социалистических Республик, Турции, Финляндии, Франции, Чехословакии, Швейцарии и Швеции. В соответствии с пунктом 11
 Положения о круге ведения Комиссии в работе сессии приняли участие представители Бангладеш,
 Кении и Японии.</p>

<p style="text-indent:2em;"></p><h6>На сессии присутствовали также представители следующих специализированных учреждений и других межправительственных и неправительственных
 организаций:</h6><p></p>

<p style="text-indent:2em;">Межправительственной морской консультативной организации (ИМКО), Генерального соглашения по тарифам и торговле (ГАТТ), Европейского экономического
 сообщества (ЕЭС), Совета таможенного сотрудничества (СТС), Центрального бюро международных железнодорожных перевозок (ЦБМЖП), Международной торговой
 палаты (МТП), Международной авиатранспортной ассоциации (ИАТА), Международного союза железных дорог (МСЖД), Международной организации по стандартизации
 (ИСО), Международной палаты по судоходству (МПС), Международной федерации транспортно - экспедиторских ассоциаций (ФИАТА) и Международного комитета
 железнодорожного транспорта (МКЖТ).</p><br>

<p style="font-weight:500;">I. ИСТОРИЯ ВОПРОСА</p><br>

<p style="text-indent:2em;">1. Во многих внешнеторговых документах требуется информация о видах транспорта, используемых для перевозки товаров. Необходимость в такой информации
 может быть вызвана причинами договорного характера, например, в договоре купли - продажи может быть оговорен определенный вид транспорта, или причинами
 оперативного характера, например, для инструкций по перевозке товаров или уведомления грузополучателя о том, каким видом транспорта прибудут его товары,
 и т.д. Во многих странах информация о виде транспорта требуется также для транспортной и таможенной статистики.</p><br>

<p style="text-indent:2em;">2. Транспортные средства, в частности, автотранспортные средства и железнодорожные вагоны часто обозначаются при помощи регистрационного номера. Такие
 регистрационные номера не содержат каких-либо элементов, указывающих, к какой категории относятся эти транспортные средства (хотя в некоторых случаях
 формат этого номера может предполагать определенную категорию транспортных средств). Поэтому в регистрационный номер необходимо вносить указание на такую
 категорию, которая, как правило, соответствует виду транспорта, в котором используются эти транспортные средства, например, грузовой автомобиль - дорога.
 В документах эта проблема традиционно решается либо с помощью предварительного напечатанного заголовка, либо с помощью записи естественным языком; при других
 методах обмена данными может потребоваться кодовое обозначение. В идеальном случае один и тот же код должен использоваться для представления вида транспорта
 и обозначения категории транспортных средств.</p><br>

<p style="text-indent:2em;">3. Необходимость представления этих сведений в кодированном виде была подтверждена рядом правительств и международных органов и организаций, в том числе
 Статистическим бюро Организации Объединенных Наций, группами экспертов ЕЭК по смешанным перевозкам и по статистике транспорта, Советом таможенного
 сотрудничества, Международным союзом железных дорог и Статистическим бюро европейских сообществ. Уже существует большое количество систем классификации,
 различных по своему совершенству и сложности в зависимости от того, где они используются.</p><br>

<p style="text-indent:2em;">4. Исходя из результатов исследования таких систем классификации и принимая во внимание точки зрения, высказанные вышеупомянутыми органами и организациями,
 представляется, что простая структура кодового обозначения, охватывающая основные виды транспорта, была бы достаточной для базового классификатора.</p><br>

<p style="text-indent:2em;">5. Однако следовало бы предусмотреть определенную степень гибкости применения, а также возможность подразделения основного кода путем добавления второй цифры.
 Было высказано предположение, что это потребуется, в частности, для решения проблем, связанных с требованием в некоторых странах указывать перевозки по внутренним
 водным путям отдельно от других водных перевозок. Представители этих стран предложили выделить для этих целей в пределах кодирования от 0 до 9 две отдельные цифры,
 т.е. одну цифру - для морского транспорта, а другую - для внутреннего водного транспорта, с тем чтобы использовать их в соответствии с критериями, предписанными
 национальными или региональными правилами.</p><br>

<p style="text-indent:2em;">6. С другой стороны, представители стран, в которых такое различие не проводится, заявили, что они не смогут согласиться на два различных кода для водного транспорта;
 кроме того, утверждалось, что орган, выдающий документ, в котором должен быть указан вид транспорта, может не знать или быть не в состоянии применить критерии,
 установленные такими национальными или региональными правилами. Более того, будущее логическое и иерархическое расширение базового классификатора было бы более
 затруднительным, если бы один из четырех основных видов транспорта (водный, железнодорожный, автомобильный и воздушный) представлялся иным образом по сравнению
 с другими. Исходя из этой точки зрения, было бы более логичным предусмотреть возможность подразделения на уровне второй цифры, что, вероятно, потребовалось бы
 сделать также и для других видов транспорта. (Нет никаких указаний на то, что для таких целей потребовалось бы более одной дополнительной цифры).</p><br>

<p style="text-indent:2em;">7. Для того, чтобы согласовать эти различные точки зрения в целях выработки общей рекомендации, необходимо было найти компромиссное решение, и по общему суждению,
 достичь этого можно было бы лучше всего в рамках основной одноразрядной системы классификации, обладающей определенными возможностями для расширения. В качестве
 возможной основы были рассмотрены многие существующие классификаторы с одноразрядным кодовым обозначением, однако не был найден ни один, который получил бы международное
 одобрение или имел бы преимущества, которые оправдывали бы его признание в качестве стандарта, рекомендованного ЕЭК.</p><br>

<p style="text-indent:2em;">8. Вследствие этого и сознавая потребность в согласованном в международном масштабе классификаторе для представления видов транспорта, а также категорий транспортных
 средств в целях сбора, передачи и обработки внешнеторговых данных, Рабочая группа по упрощению процедур международной торговли подготовила настоящую Рекомендацию как 
 часть своей задачи по разработке классификаторов для элементов данных, используемых во внешней торговле. Предпочтение было оказано цифровому классификатору, поскольку
 использование алфавитных кодов не дает никаких конкретных мнемонических или других преимуществ.</p><br>

<p style="font-weight:500;">II. НАЗНАЧЕНИЕ</p><br>

<p style="text-indent:2em;">9. Эта Рекомендация устанавливает классификатор с одноразрядным цифровым кодовым обозначением для представления видов транспорта и определения категорий транспортных
 средств, соответствующих тому виду транспорта, для которого они предназначены. Эта Рекомендация предусматривает также возможность добавления второго цифрового знака
 для любой дальнейшей разбивки, введение которой может потребоваться для той или иной страны или для другого конкретного использования.</p><br>

<p style="font-weight:500;">III. ОБЛАСТЬ ПРИМЕНЕНИЯ</p><br>

<p style="text-indent:2em;">10. Эта Рекомендация применяется во всех случаях, когда вид транспорта представляется в кодированном виде в документах, используемых во внешней торговле, и когда основная
 простая структура кодового обозначения является достаточной. Эти коды могут быть также использованы в качестве отдельной цифры, стоящей перед обозначением транспортных
 средств, и могут быть подразделены путем добавления второго факультативного цифрового знака.</p><br>

<p style="font-weight:500;">IV. ТЕРМИНОЛОГИЯ</p><br>

<p style="text-indent:2em;">11. Для целей настоящей Рекомендации применяются следующие определения: Вид транспорта: метод транспортировки, используемый для перевозки товаров. Транспортные средства:
 конкретное автотранспортное средство, судно или другой механизм, используемые для перевозки товаров или людей.</p><br>

<p style="font-weight:500;">VI. ПОЯСНИТЕЛЬНЫЕ ПРИМЕЧАНИЯ</p><br>

<p style="text-indent:2em;">1. Критерии применения До сего времени не удалось рекомендовать международные критерии для некоторых случаев, например, какой код использовать в том случае
, когда перевозка водным транспортом осуществляется частично по внутренним водным путям и частично морем, когда автотранспортные средства и железнодорожные
 вагоны перевозятся на паромах и т.д., хотя в отдельных случаях используется код "активного" транспортного средства, например, когда автомобиль перевозится
 на поезде, то указывается железнодорожный транспорт. Например, может оказаться, что для статистических целей подходящим является указание активного вида
 транспорта во время пересечения границы. При отсутствии согласованных международных критериев выпуск соответствующих инструкций и, в случае необходимости,
 доведения этих инструкций до сведения других заинтересованных органов, с тем чтобы обеспечить сопоставимость статистических данных т.д., падает на национальные
 или другие соответствующие власти.</p><br>

<p style="text-indent:2em;">2. Гибкость применения Структура классификатора, предлагаемая в данной Рекомендации, служит для того, чтобы обеспечить максимально возможную гибкость в
 отношении его применения на национальном уровне, не ставя под угрозу его пригодность для международного использования. Рекомендуемый одноразрядный код
 может служить, таким образом, основой для международной статистической отчетности и т.д., в то время как второй цифровой знак предназначается для национальных
 (или региональных) целей. Код 8 зарезервирован для того, чтобы вести учет внутреннего водного транспорта отдельно в рамках одноразрядной структуры кодового
 обозначения. Если внутренний водный транспорт не существует, или он не учитывается отдельно, или если любые другие конкретные коды не применяются (например,
 если почтовые отправления или смешанные перевозки не признаются в качестве видов транспорта для статистических целей или если не существует международной
 железнодорожной связи), тогда нет необходимости в том, чтобы эти коды 
 фигурировали в списке элементов, предназначенных для целей сбора статистических данных.</p><br>

<p style="text-indent:2em;">3. Использование второго цифрового знака Допускается, что во многих случаях необходимо будет осуществить дальнейшую разбивку основных кодовых обозначений,
 и в этих целях, по-видимому, может потребоваться введение второго цифрового знака. </p>
 
<p style="text-indent:2em;"></p><h6>Ниже приводятся примеры разбивки:</h6><p></p>

<p style="text-indent:2em;">Имеются примеры разбивки, когда во всех видах транспорта следует указывать, является ли то или иное транспортное средство иностранной или национальной
 регистрации, перевозятся ли грузы в контейнерах или нет и т.д. Однако в подобных случаях можно было бы рассмотреть другие решения: в первом случае -
 путем введения отдельного элемента данных "Национальная принадлежность транспортных средств", а во втором случае - путем указания об использовании
 контейнеров как одного из видов упаковки.</p>

<p style="text-indent:2em;"></p><h6>4. Использование кодов в целях обозначения категории транспортных средств Эти коды можно использовать в качестве указателя, стоящего перед конкретным
 обозначением того или иного транспортного средства, следующим образом:</h6><p></p>

<p style="text-indent:2em;">Код 1 (Морской транспорт): для судов - в качестве отдельной цифры перед названиями судов или кодами (например, 1-AMOUR, 1-ULCZ)</p><br>

<p style="text-indent:2em;">Код 2 (Железнодорожный транспорт): для железнодорожных вагонов - в качестве отдельной цифры перед номерами железнодорожных вагонов (например
, 2-01-85-1370315-9)</p><br>

<p style="text-indent:2em;">Код 3 (Автодорожный транспорт): для автомобилей - в качестве отдельной цифры перед регистрационными номерами автомобилей (например,
 3-СЕ 21201)</p><br>

<p style="text-indent:2em;">Код 4 (Воздушный транспорт): для самолетов - в качестве отдельной цифры перед номерами авиарейсов (например, 4-SR101)</p><br>

<p style="text-indent:2em;">Код 6 (Смешанные перевозки): в качестве отдельной цифры перед номерами контейнеров, когда контейнеры указываются в качестве транспортных
 единиц, аналогичных транспортным средствам (например, 6-EACU1234567)</p><br>
 
 <p><a href="https://www.transportir.by/mezhdunarodnoe-transportnoe-pravo/klassifikator-vidov-transporta.html">Источник</a></p>

</div>
`