export const int2 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Convenția vamală referitoare la transportul internațional al mărfurilor sub acoperirea carnetelor 
TIR (Convenția TIR)</h4><p></p>

<p>Partile Contractante,</p><br>

<p>Doritoare sa faciliteze transporturile internationale ale marfurilor cu vehicule rutiere,</p>

<p style="text-indent:2em;"></p><h6>Au convenit asupra celor ce urmeaza:</h6><p></p>

<p></p><h4>CAPITOLUL 1</h4><p></p>

<p style="font-weight:500;">Definitii</p><br>

<p style="font-weight:500;">ARTICOLUL 1</p>

<p style="text-indent:2em;"></p><h6>In sensul prezentei Conventii, se inteleg:</h6><p></p>

<p style="text-indent:2em;">a) prin "drepturi si taxe de import sau de export", nu numai drepturile vamale, ci si orice alte drepturi si taxe exigibile ca urmare a importului
 sau exportului;</p><br>

<p style="text-indent:2em;">b) prin "vehicul rutier", nu numai orice vehicul rutier cu motor, ci si orice remorca sau semiremorca conceputa pentru a fi atasata unui asemenea
 vehicul;</p><br>

<p>c) prin "container", un mijloc de transport (cadru, cisterna miscatoare sau alt mijloc asemanator);</p><br>

<p style="text-indent:2em;">i. avind un caracter permanent si fiind din acest punct de vedere suficient de rezistent pentru a permite folosirea sa repetata;</p><br>

<p style="text-indent:2em;">ii. special conceput pentru a facilita transportul marfurilor, fara descarcare si reincarcare, de catre unul sau mai multe mijloace de transport;</p><br>

<p style="text-indent:2em;">iii. prevazut cu dispozitive care sa-l faca usor de manipulat, in deosebi in timpul transbordarii dintr-un mijloc de transport in altul;</p><br>

<p>iv. conceput astfel incit sa fie usor de umplut si de golit, si</p><br>

<p>v. cu un volum interior de cel putin un metru cub;</p><br>

<p>termenul "container" nu cuprinde nici ambalajele uzuale, nici vehiculele;</p><br>

<p style="text-indent:2em;">d) prin "birou vamal de plecare", orice birou vamal interior sau de frontiera al unei Parti Contractante unde incepe, pentru toata incarcatura sau
 o parte a ei, transportul international prin vehicul rutier sub regimul prevazut de prezenta Conventie;</p><br>

<p style="text-indent:2em;">e) prin "birou vamal de destinatie", orice birou vamal interior sau de frontiera al unei Parti Contractante unde ia sfirsit, pentru toata incarcatura
 sau o parte a ei, transportul international prin vehicul rutier sub regimul prevazut de prezenta Conventie;</p><br>

<p style="text-indent:2em;">f) prin "birou vamal de trecere", orice birou vamal de frontiera al unei Parti Contractante, prin care vehiculul rutier nu face decit sa treaca in
 cursul unui transport international sub regimul prevazut de prezenta Conventie;</p><br>

<p>g) prin "persoana", in acelasi timp persoanele fizice si persoanele juridice;</p><br>

<p style="text-indent:2em;">h) prin "marfuri grele si voluminoase", orice obiect care, dupa parerea autoritatilor vamale ale biroului vamal de plecare, nu poate fi demontat usor
 pentru a fi transportat si</p><br>

<p>i. a carui greutate depaseste 7000 kg sau</p><br>

<p>ii. care are una din dimensiuni mai mare de 5 m sau</p><br>

<p>iii. care are doua din dimensiuni mai mari de 2 m sau</p><br>

<p>iv. care trebuie sa fie incarcat intr-o asemenea pozitie, incit inaltimea sa sa fie mai mare de 2 m.</p>

<p></p><h4>CAPITOLUL 2</h4><p></p>

<p style="font-weight:500;">Cimpul de aplicare</p><br>

<p style="font-weight:500;">ARTICOLUL 2</p><br>

<p style="text-indent:2em;">Prezenta Conventie priveste transportul marfurilor efectuat fara descarcare peste una sau mai multe frontiere de la un birou vamal de plecare al unei
 Parti Contractante pina la un birou vamal de destinatie al altei Parti Contractante sau al aceleiasi Parti Contractante in vehicule rutiere sau in
 containere incarcate pe aceste vehicule, chiar daca aceste vehicule sint incarcate pe un alt mijloc de transport pe o parte a traseului intre birourile
 de plecare si de destinatie.</p><br>

<p style="font-weight:500;">ARTICOLUL 3</p>

<p style="text-indent:2em;"></p><h6>Pentru a beneficia de dispozitiile prezentei Conventii:</h6><p></p>

<p style="text-indent:2em;">a) transporturile trebuie sa fie efectuate in conditiile indicate la Capitolul III de catre vehicule rutiere sau containere acceptate in prealabil;
 totusi, pe teritoriul Partilor Contractante care n-au formulat rezerve conform paragrafului 1 al articolului 45 al prezentei Conventii, ele pot de
 asemenea, cu exceptia cazurilor prevazute de paragraful 2 al acestui articol, sa fie efectuate de alte vehicule rutiere in conditiile indicate la
 Capitolul IV;</p><br>

<p style="text-indent:2em;">b) transporturile trebuie sa aiba loc sub garantia asociatiilor acceptate potrivit prevederilor articolului 5 si sub acoperirea unui document denumit
 carnet TIR.</p>

<p></p><h4>CAPITOLUL 3</h4><p></p>

<p>Dispozitii referitoare la transporturile in vehicule rutiere sigilate
sau in containere sigilate</p><br>

<p style="font-weight:500;">ARTICOLUL 4</p>

<p style="text-indent:2em;"></p><h6>Sub rezerva respectarii prevederilor prezentului Capitol si ale Capitolului V, marfurile transportate in vehicule rutiere sigilate sau in containere
 sigilate incarcate pe vehicule rutiere:</h6><p></p>

<p>a) nu vor fi supuse consemnarii drepturilor si taxelor de import sau de export la birourile vamale de trecere;</p><br>

<p>b) nu vor fi supuse, de regula, controlului vamal la aceste birouri.</p><br>

<p style="text-indent:2em;">Totusi, in vederea evitarii abuzurilor, autoritatile vamale vor putea, in mod exceptional si indeosebi atunci cind se banuieste o neregularitate, sa
 procedeze, la aceste birouri, la controale sumare sau detaliate ale marfurilor.</p><br>

<p style="font-weight:500;">ARTICOLUL 5</p><br>

<p style="text-indent:2em;">1. In conditiile si cu garantiile pe care ea le va determina, fiecare Parte Contractanta va putea abilita unele asociatii sa elibereze carnete TIR,
 fie direct, fie prin intermediul asociatiilor corespondente, si sa se constituie garante.</p><br>

<p style="text-indent:2em;">2. O asociatie nu va putea fi acceptata intr-o tara decit daca garantia sa se extinde si la raspunderile pe care le atrage asupra-si in aceasta tara
 ca urmare a operatiunilor efectuate sub acoperirea carnetelor TIR eliberate de catre asociatii straine afiliate la organizatia internationala la
 care ea insasi este afiliata.</p><br>

<p style="font-weight:500;">ARTICOLUL 6</p><br>

<p style="text-indent:2em;">1. Asociatia garanta se va angaja sa achite drepturile si taxele de import sau de export devenite exigibile, majorate, daca este cazul, cu dobinzile
 de intirziere si alte cheltuieli, precum si penalitatile pecuniare la care titularul carnetului TIR si persoanele care participa la efectuarea
 transportului sint expuse in virtutea legilor si regulamentelor vamale ale tarilor in care o infractiune ar fi comisa. Ea va fi obligata, impreuna
 si solidar cu persoanele care datoreaza sumele vizate mai sus, la plata acestor sume.</p><br>

<p style="text-indent:2em;">2. Faptul ca autoritatile vamale autoriza verificarea marfurilor in afara amplasamentelor unde se exercita in mod normal activitatea birourilor vamale
 de plecare sau de destinatie, nu diminueaza cu nimic responsabilitatea asociatiei garante.</p><br>

<p style="text-indent:2em;">3. Asociatia garanta nu va deveni responsabila fata de autoritatile unei tari decit incepind din momentul cind carnetul TIR va fi acceptat de catre
 autoritatile vamale ale acestei tari.</p><br>

<p style="text-indent:2em;">4. Responsabilitatea asociatiei garante se va extinde nu numai la marfurile enumerate pe carnetul TIR, ci si la marfurile care, desi nu sint enumerate
 pe acest carnet, s-ar gasi in partea sigilata a vehiculului rutier sau in containerul sigilat; ea nu se va extinde la nici o alta marfa.</p><br>

<p style="text-indent:2em;">5. Pentru a determina drepturile si taxele, precum si, atunci cind este cazul, penalitatile pecuniare prevazute in paragraful 1 al prezentului articol,
 indicatiile referitoare la marfurile care figureaza in carnetul TIR sint valabile pina la proba contrara.</p><br>

<p style="text-indent:2em;">6. Atunci cind autoritatile vamale ale unei tari vor da descarcare fara rezerva unui carnet TIR, ele nu vor mai putea reclama asociatiei garante plata
 sumelor prevazute in paragraful 1 al prezentului articol, afara de cazul cind certificatul prin care s-a dat descarcarea a fost obtinut in mod abuziv
 sau fraudulos.</p><br>

<p style="text-indent:2em;">7. In cazul cind nu s-a dat descarcare unui carnet TIR sau atunci cind s-a dat unui carnet TIR descarcare cu rezerve, autoritatile competente nu vor
 avea dreptul sa ceara asociatiei garante plata sumelor prevazute in paragraful 1 al prezentului articol daca, in termen de un an socotit de la data
 cind au luat in primire carnetul TIR, aceste autoritati nu au avizat asociatia despre faptul ca nu s-a dat descarcare carnetului sau ca i s-a dat
 descarcare cu rezerve. Aceasta dispozitie va fi aplicabila si in cazul cind descarcarea a fost obtinuta in mod abuziv sau fraudulos, dar atunci
 termenul va fi de doi ani.</p><br>

<p style="text-indent:2em;">8. Cererea de plata a sumelor prevazute in paragraful 1 al prezentului articol va fi adresata asociatiei garante in termen de trei ani socotit de la
 data cind aceasta asociatie a fost avizata de faptul ca nu s-a dat descarcare sau ca descarcarea s-a dat cu rezerve, sau de faptul ca descarcarea a
 fost obtinuta in mod abuziv sau fraudulos. Totusi, in ceea ce priveste cazurile care sint deferite justitiei in termenul mai sus-indicat de trei ani,
 cererea de plata va fi adresata in termen de un an socotit de la data cind hotarirea judecatoreasca a devenit executorie.</p><br>

<p style="text-indent:2em;">9. Pentru a achita sumele cerute, asociatia garanta va dispune de un termen de trei luni socotit de la data cererii de plata care i-a fost adresata.
 Asociatia va obtine rambursarea sumelor varsate daca, in cele douasprezece luni de la data cererii de plata, ea stabileste in fata autoritatilor
 vamale ca nici o neregularitate n-a fost comisa in legatura cu transportul in cauza.</p><br>

<p style="font-weight:500;">ARTICOLUL 7</p><br>

<p>1. Carnetul TIR va fi conform cu modelul care figureaza in anexa 1 a prezentei Conventii.</p><br>

<p style="text-indent:2em;">2. Se va intocmi un carnet TIR pentru fiecare vehicul rutier sau container. Acest carnet va fi valabil pentru un singur transport; el va contine
 numarul de foi detasabile de luare in primire si de descarcare necesare pentru transportul in cauza.</p><br>

<p style="font-weight:500;">ARTICOLUL 8</p>

<p style="text-indent:2em;"></p><h6>Transportul sub acoperirea unui carnet TIR va putea comporta mai multe birouri vamale de plecare si de destinatie, dar, afara de cazul cind exista
 autorizatia Partii Contractante sau a Partilor Contractante interesate:</h6><p></p>

<p>a) birourile vamale de plecare vor trebui sa fie situate in aceeasi tara,</p><br>

<p>b) birourile vamale de destinatie nu vor putea fi situate in mai mult de doua tari,</p><br>

<p>c) numarul total al birourilor vamale de plecare si de destinatie nu va putea depasi numarul de patru.</p><br>

<p style="font-weight:500;">ARTICOLUL 9</p><br>

<p style="text-indent:2em;">La biroul vamal de plecare, marfurile, vehiculul rutier si, daca este cazul, containerul, vor fi prezentate autoritatilor vamale in acelasi timp cu
 carnetul TIR, in scopul verificarii si aplicarii sigiliilor vamale.</p><br>

<p style="font-weight:500;">ARTICOLUL 10</p><br>

<p style="text-indent:2em;">Pentru parcursul pe teritoriul tarii lor, autoritatile vamale vor putea fixa un termen si cere ca vehiculul rutier sa urmeze un itinerariu
 determinat.</p><br>

<p style="font-weight:500;">ARTICOLUL 11</p><br>

<p style="text-indent:2em;">La fiecare birou vamal de trecere, ca si la birourile vamale de destinatie, vehiculul rutier sau containerul va fi prezentat autoritatilor vamale
 cu incarcatura sa si carnetul TIR aferent.</p><br>

<p style="font-weight:500;">ARTICOLUL 12</p><br>

<p style="text-indent:2em;">In afara de cazul cind ar proceda la controlul marfurilor in aplicarea prevederilor ultimei fraze a articolului 4, autoritatile vamale ale birourilor
 vamale de trecere ale fiecarei Parti Contractante vor respecta sigiliile aplicate de autoritatile vamale ale celorlalte Parti Contractante. Ele vor
 putea totusi adauga propriul lor sigiliu.</p><br>

<p style="font-weight:500;">ARTICOLUL 13</p>

<p style="text-indent:2em;"></p><h6>In vederea evitarii abuzurilor, autoritatile vamale vor putea, daca ele socotesc necesar:</h6><p></p>

<p>a) sa escorteze, in cazuri speciale, vehiculele rutiere pe teritoriul tarii lor, pe cheltuiala transportorului;</p><br>

<p>b) sa procedeze, in timpul transportului, la controlul vehiculelor rutiere sau al containerelor si al incarcaturii lor.</p><br>

<p>Controlul incarcaturii trebuie sa se faca numai in mod exceptional.</p><br>

<p style="font-weight:500;">ARTICOLUL 14</p><br>

<p style="text-indent:2em;">Daca, in timpul transportului sau la un birou vamal de trecere, autoritatile vamale procedeaza la controlul incarcaturii unui vehicul rutier sau al unui
 container, ele vor face mentiune pe foile detasabile ale carnetului TIR utilizate in tara lor si pe cotoarele corespunzatoare despre aplicarea noilor
 sigilii.</p><br>

<p style="font-weight:500;">ARTICOLUL 15</p><br>

<p style="text-indent:2em;">La sosirea la biroul vamal de destinatie, se va da descarcare, fara intirziere, carnetului TIR. Daca marfurile nu sint supuse imediat unui alt regim vamal,
 autoritatile vamale vor putea totusi sa-si rezerve dreptul de a subordona descarcarea carnetului conditiei ca o alta responsabilitate sa se substituie
 aceleia a asociatiei garante a zisului carnet.</p><br>

<p style="font-weight:500;">ARTICOLUL 16</p><br>

<p style="text-indent:2em;">Atunci cind s-a stabilit de catre autoritatile vamale ca marfurile care fac obiectul carnetului TIR au pierit prin forta majora, va fi acordata dispensa de
 plata a drepturilor si taxelor exigibile in mod normal.</p><br>

<p style="font-weight:500;">ARTICOLUL 17</p><br>

<p style="text-indent:2em;">1. Pentru a beneficia de dispozitiile prezentului Capitol, vehiculele rutiere sau containerele trebuie sa indeplineasca conditiile de constructie si de
 amenajare prevazute in anexa 3 a prezentei Conventii in ce priveste vehiculele rutiere, si in anexa 6 in ce priveste containerele.</p><br>

<p style="text-indent:2em;">2. Vehiculele rutiere si containerele vor trebui sa fie acceptate conform procedurilor prevazute in anexele 4 si 7 ale prezentei Conventii; certificatele
 de acceptare vor trebui sa fie conforme modelelor care figureaza in anexele 5 si 8.</p><br>

<p style="font-weight:500;">ARTICOLUL 18</p><br>

<p style="text-indent:2em;">1. Containerul utilizat sub acoperirea carnetului TIR nu va face obiectul unui document special, cu conditia sa se faca mentiune de caracteristicile sale
 si de valoarea sa in "Manifestul marfurilor" din carnetul TIR.</p><br>

<p style="text-indent:2em;">2. Dispozitiile paragrafului 1 al prezentului articol nu vor putea impiedica o Parte Contractanta sa ceara indeplinirea la biroul vamal de destinatie a
 formalitatilor prevazute de reglementarea sa nationala, sau sa ia masuri in vederea evitarii folosirii unui container pentru o noua expeditie de marfuri
 destinate a fi descarcate pe teritoriul sau.</p>

<p></p><h4>CAPITOLUL 4</h4><p></p>

<p style="font-weight:500;">Dispozitii referitoare la transporturile de marfuri grele sau voluminoase</p><br>

<p style="font-weight:500;">ARTICOLUL 19</p><br>

<p style="text-indent:2em;">1. Dispozitiile prezentului Capitol nu vor fi aplicabile decit transporturilor de marfuri grele sau voluminoase, definite in alineatul h al articolului 1
 al prezentei Conventii.</p>

<p style="text-indent:2em;"></p><h6>2. Beneficiul dispozitiilor prezentului Capitol nu va fi acordat decit daca, dupa parerea autoritatilor vamale ale biroului vamal de plecare:</h6><p></p>

<p style="text-indent:2em;">a) este posibil sa se identifice fara dificultate, datorita descrierii facute, marfurile grele sau voluminoase transportate, precum si, atunci cind este
 cazul, accesoriile transportate in acelasi timp, sau cind este posibil a le aplica semne de identificare sau a le sigila astfel incit sa se impiedice
 inlocuirea, in intregime sau in parte, a acestor marfuri sau accesorii cu altele ori sustragerea unor elemente ale lor;</p><br>

<p>b) vehiculul rutier nu are parti ascunse unde sa fie posibil a dosi marfuri.</p><br>

<p style="font-weight:500;">ARTICOLUL 20</p><br>

<p style="text-indent:2em;">Sub rezerva respectarii dispozitiilor prezentului Capitol si ale Capitolului V, marfurile grele sau voluminoase transportate sub acoperirea unui carnet TIR
 nu vor fi supuse la plata sau consemnarea drepturilor si taxelor de import sau de export la birourile vamale de trecere.</p><br>

<p style="font-weight:500;">ARTICOLUL 21</p><br>

<p style="text-indent:2em;">1. Dispozitiile articolului 5, articolului 6 (cu exceptia paragrafului 4) si ale articolelor 9, 10, 11, 15 si 16 ale prezentei Conventii se aplica transporturilor
 de marfuri grele sau voluminoase sub acoperirea unui carnet TIR.</p><br>

<p style="text-indent:2em;">2. Dispozitiile articolului 7 sint de asemenea aplicabile, dar carnetul TIR utilizat va trebui sa poarte pe coperta si pe toate foile detasabile indicatia
 "marfuri grele sau voluminoase" in caractere rosii foarte citete si in limba folosita pentru tiparirea carnetului.</p><br>

<p style="font-weight:500;">ARTICOLUL 22</p><br>

<p style="text-indent:2em;">Responsabilitatea asociatiei garante se va intinde nu numai asupra marfurilor enumerate in carnetul TIR, dar si asupra marfurilor care, desi nu sint enumerate
 in acest carnet, s-ar gasi pe platoul de incarcare sau printre marfurile enumerate in carnetul TIR.</p><br>

<p style="font-weight:500;">ARTICOLUL 23</p><br>

<p style="text-indent:2em;">Autoritatile vamale ale biroului vamal de plecare vor putea cere sa fie anexate la carnetul TIR, liste de ambalaje, fotografii, schite etc. ale marfurilor
 transportate. In acest caz, ele vor pune o viza pe aceste documente; un exemplar al acestora va fi atasat pe verso-ul paginii copertei carnetului TIR si
 toate manifestele carnetului TIR vor face mentiune despre aceste documente.</p><br>

<p style="font-weight:500;">ARTICOLUL 24</p><br>

<p style="text-indent:2em;">Un transport de marfuri grele sau voluminoase sub acoperirea carnetului TIR nu va putea sa comporte decit un singur birou vamal de plecare si un singur
 birou vamal de destinatie.</p><br>

<p style="font-weight:500;">ARTICOLUL 25</p><br>

<p style="text-indent:2em;">Daca autoritatile vamale ale birourilor vamale de trecere la intrare o cer, persoana care prezinta incarcatura la aceste birouri va fi obligata sa
 completeze descrierea marfurilor in manifestele carnetului TIR si sa semneze aceasta mentiune suplimentara.</p><br>

<p style="font-weight:500;">ARTICOLUL 26</p>

<p style="text-indent:2em;"></p><h6>Autoritatile vamale pot, daca ele considera util:</h6><p></p>

<p>a) sa controleze vehiculele si incarcaturile, atit la birourile vamale de trecere, cit si in timpul transportului;</p><br>

<p>b) sa escorteze vehiculele rutiere pe teritoriul tarii lor, pe cheltuiala transportorilor.</p><br>

<p style="font-weight:500;">ARTICOLUL 27</p><br>

<p style="text-indent:2em;">Autoritatile vamale ale birourilor vamale de trecere ale fiecareia din Partile Contractante vor respecta, pe cit posibil, semnele de identificare si
 sigiliile aplicate de autoritatile vamale ale altor Parti Contractante. Ele vor putea totodata sa adauge alte semne de identificare sau propriul
 lor sigiliu.</p><br>

<p style="font-weight:500;">ARTICOLUL 28</p><br>

<p style="text-indent:2em;">Daca, in timpul transportului sau la un birou vamal de trecere, autoritatile vamale procedind la controlul incarcaturii sint nevoite sa inlature
 semnele de identificare sau sa rupa sigiliile, ele vor face mentiune pe foile detasabile ale carnetului TIR utilizate in tara lor si pe cotoarele
 corespunzatoare despre noile semne de identificare si despre noile sigilii aplicate.</p>

<p></p><h4>CAPITOLUL 5</h4><p></p>

<p style="font-weight:500;">Dispozitii diverse</p><br>

<p style="font-weight:500;">ARTICOLUL 29</p><br>

<p style="text-indent:2em;">1. Fiecare Parte Contractanta va avea dreptul sa excluda, temporar sau cu titlu definitiv, de la beneficiul dispozitiilor prezentei Conventii,
 orice persoana culpabila de o infractiune grava la legile si regulamentele vamale aplicabile transporturilor internationale de marfuri cu 
 vehicule rutiere.</p><br>

<p style="text-indent:2em;">2. Aceasta excludere va fi imediat notificata autoritatilor vamale ale Partii Contractante pe teritoriul careia este stabilita sau domiciliaza
 persoana in cauza, precum si asociatiei garante a tarii in care s-a comis infractiunea.</p><br>

<p style="font-weight:500;">ARTICOLUL 30</p><br>

<p style="text-indent:2em;">Vor fi admise la beneficiul scutirii de drepturi si taxe de import si nu vor fi supuse la nici o prohibitie sau restrictie de import formularele
 carnetelor TIR expediate asociatiilor garante de catre asociatiile straine corespondente sau de catre organizatiile internationale.</p><br>

<p style="font-weight:500;">ARTICOLUL 31</p><br>

<p style="text-indent:2em;">Atunci cind un transport international de marfuri va fi efectuat sub acoperirea carnetului TIR de un vehicul rutier izolat sau de un grup de
 vehicule rutiere cuplate, o placa dreptunghiulara purtind inscriptia TIR si avind caracteristicile mentionate in anexa 9 a prezentei Conventii
 va fi asezata in fata, si una identica in spatele vehiculului sau grupului de vehicule. Aceste placi vor fi dispuse in asa fel incit sa fie bine
 vizibile; ele vor fi detasabile si vor trebui sa poata fi sigilate. Sigiliile vor fi aplicate de catre autoritatile vamale ale primului birou
 vamal de plecare si inlaturate de acelea ale ultimului birou vamal de destinatie.</p><br>

<p style="font-weight:500;">ARTICOLUL 32</p><br>

<p style="text-indent:2em;">Daca, in timpul transportului, un sigiliu aplicat de autoritatile vamale s-a rupt in alte cazuri decit cele prevazute in articolele 14 si 28
 sau daca marfurile au pierit sau au suferit stricaciuni fara ca un asemenea sigiliu sa fie rupt, va fi urmata procedura prevazuta in anexa 1
 a prezentei Conventii pentru folosirea carnetului TIR, fara a renunta la aplicarea eventuala a prevederilor legislatiilor nationale si se va
 dresa un proces verbal de constatare dupa modelul care figureaza in anexa 2 a prezentei Conventii.</p><br>

<p style="font-weight:500;">ARTICOLUL 33</p><br>

<p>Partile Contractante isi vor comunica modelele sigiliilor pe care le utilizeaza.</p><br>

<p style="font-weight:500;">ARTICOLUL 34</p><br>

<p style="text-indent:2em;">Fiecare Parte Contractanta va comunica celorlalte Parti Contractante lista birourilor vamale de plecare, de trecere si de destinatie pe care
 le va desemna pentru transporturile efectuate sub acoperirea carnetului TIR, distingind, daca este cazul, birourile care vor fi deschise numai
 pentru transporturile reglementate prin dispozitiile Capitolului III. Partile Contractante ale caror teritorii sint limitrofe se vor consulta
 pentru a fixa birourile de frontiera care sa fie inscrise pe aceste liste.</p><br>

<p style="font-weight:500;">ARTICOLUL 35</p><br>

<p style="text-indent:2em;">Pentru operatiunile vamale mentionate in prezenta Conventie, interventia personalului vamal nu va da loc la nici o retributie, cu exceptia
 cazurilor cind aceasta interventie va avea loc in afara zilelor, orelor si amplasamentelor normal prevazute pentru asemenea operatii.</p><br>

<p style="font-weight:500;">ARTICOLUL 36</p><br>

<p style="text-indent:2em;">Orice infractiune la dispozitiile prezentei Conventii va expune pe contravenient, in tara in care infractiunea a fost comisa, la sanctiunile
 prevazute de legislatia acestei tari.</p><br>

<p style="font-weight:500;">ARTICOLUL 37</p><br>

<p style="text-indent:2em;">Prevederile prezentei Conventii nu impiedica nici aplicarea restrictiilor si controlului derivind din reglementarea nationala si fundate pe
 consideratiuni de moralitate publica, securitate publica, igiena sau sanatate publica sau pe consideratiuni de ordin veterinar sau fitopatologic,
 nici perceperea de sume datorate pe baza acestor reglementari.</p><br>

<p style="font-weight:500;">ARTICOLUL 38</p><br>

<p style="text-indent:2em;">Nici o dispozitie a prezentei Conventii nu exclude dreptul pentru Partile Contractante care formeaza o uniune vamala sau economica de a adopta
 reguli speciale la plecare sau la destinatie pe teritoriile lor sau in tranzit prin acestea, cu conditia ca aceste reguli sa nu diminueze
 facilitatile prevazute de prezenta Conventie.</p>

<p></p><h4>CAPITOLUL 6</h4><p></p>

<p style="font-weight:500;">Dispozitii finale</p><br>

<p style="font-weight:500;">ARTICOLUL 39</p>

<p style="text-indent:2em;"></p><h6>1. Tarile membre ale Comisiei economice pentru Europa si tarile admise la Comisie cu titlu consultativ conform paragrafului 8 al mandatului
 acestei Comisii pot deveni Parti Contractante la prezenta Conventie:</h6><p></p>

<p>a) semnind-o,</p><br>

<p>b) ratificind-o dupa ce au semnat-o sub rezerva ratificarii, sau</p><br>

<p>c) aderind la ea.</p><br>

<p style="text-indent:2em;">2. Tarile susceptibile de a participa la unele din lucrarile Comisiei economice pentru Europa in aplicarea paragrafului 11 al mandatului
 acestei Comisii pot deveni Parti Contractante ale prezentei Conventii prin aderare dupa intrarea ei in vigoare.</p><br>

<p style="text-indent:2em;">3. Conventia va fi deschisa pentru semnare pina la 15 aprilie 1959 inclusiv. Dupa aceasta data, ea va fi deschisa pentru aderare.</p><br>

<p style="text-indent:2em;">4. Ratificarea sau aderarea va fi efectuata prin depunerea unui instrument la Secretarul General al Organizatiei Natiunilor Unite.</p><br>

<p style="font-weight:500;">ARTICOLUL 40</p><br>

<p style="text-indent:2em;">1. Prezenta Conventie va intra in vigoare in a 90-a zi dupa ce cinci tari mentionate in paragraful 1 al articolului 39 o vor semna fara
 rezerva ratificarii sau vor depune instrumentul lor de ratificare sau de aderare.</p><br>

<p style="text-indent:2em;">2. Pentru fiecare tara care o va ratifica sau va adera la ea dupa ce cinci tari au semnat-o fara rezerva ratificarii sau au depus instrumentul
 lor de ratificare sau de aderare, prezenta Conventie va intra in vigoare in a 90-a zi dupa depunerea instrumentului de ratificare sau
 aderare al zisei tari.</p><br>

<p style="font-weight:500;">ARTICOLUL 41</p><br>

<p style="text-indent:2em;">1. Fiecare Parte Contractanta va putea denunta prezenta Conventie prin notificare adresata Secretarului General al Organizatiei
 Natiunilor Unite.</p><br>

<p>2. Denuntarea isi va produce efect la 15 luni de la data la care Secretarul General a primit notificarea.</p><br>

<p style="text-indent:2em;">3. Validitatea carnetelor TIR eliberate inaintea datei la care denuntarea isi va produce efectul nu va fi afectata de aceasta denuntare
 si garantia asociatiilor va ramine efectiva.</p><br>

<p style="font-weight:500;">ARTICOLUL 42</p><br>

<p style="text-indent:2em;">Prezenta Conventie va inceta sa-si produca efectele daca, dupa intrarea sa in vigoare, numarul Partilor Contractante va fi inferior celui
 de cinci intr-o perioada oarecare de 12 luni consecutive.</p><br>

<p style="font-weight:500;">ARTICOLUL 43</p><br>

<p style="text-indent:2em;">1. Orice tara va putea, atunci cind va semna prezenta Conventie fara rezerva ratificarii sau in momentul depunerii instrumentului sau de
 ratificare sau de aderare sau in orice moment ulterior, sa declare, printr-o notificare adresata Secretarului General al Organizatiei Natiunilor Unite,
 ca prezenta Conventie va fi aplicabila tuturor sau unei parti a teritoriilor pe care ea le reprezinta pe plan international. Conventia va fi aplicabila
 teritoriului sau teritoriilor mentionate in notificare incepind cu cea de-a 90-a zi de la primirea acestei notificari de catre Secretarul General al
 Organizatiei Natiunilor Unite sau, daca la aceasta data Conventia nu a intrat inca in vigoare, de la data intrarii sale in vigoare.</p><br>

<p style="text-indent:2em;">2. Orice tara care va face conform paragrafului precedent o declaratie avind ca scop extinderea aplicabilitatii prezentei Conventii unui teritoriu
 pe care ea il reprezinta pe plan international, va putea, conform articolului 41, sa denunte Conventia in ceea ce priveste acest teritoriu.</p><br>

<p style="font-weight:500;">ARTICOLUL 44</p><br>

<p style="text-indent:2em;">1. Orice diferend intre doua sau mai multe Parti Contractante privind interpretarea sau aplicarea prezentei Conventii va fi rezolvat, pe cit posibil,
 pe cale de negociere intre partile in litigiu.</p><br>

<p style="text-indent:2em;">2. Orice diferend care nu va fi rezolvat pe cale de negociere va fi supus arbitrajului, daca una din Partile Contractante in litigiu cere aceasta,
 si va fi, in consecinta, trimis unuia sau mai multor arbitri alesi de comun acord de catre partile in litigiu. Daca, in cele trei luni datind de
 la cererea de arbitraj, Partile in litigiu nu ajung sa se inteleaga asupra alegerii unui arbitru sau a arbitrilor, oricare din aceste Parti va putea
 cere Secretarului General al Organizatiei Natiunilor Unite sa desemneze un arbitru unic, in fata caruia diferendul va fi trimis spre rezolvare.</p><br>

<p style="text-indent:2em;">3. Sentinta arbitrului sau a arbitrilor desemnati conform paragrafului precedent va fi obligatorie pentru Partile Contractante in litigiu.</p><br>

<p style="font-weight:500;">ARTICOLUL 45</p><br>

<p style="text-indent:2em;">1. Orice tara va putea, in momentul cind va semna sau va ratifica prezenta Conventie sau va adera la ea, sa declare sau, dupa ce a devenit Parte
 Contractanta la Conventie, sa notifice Secretarului General al Organizatiei Natiunilor Unite ca ea nu se considera legata de prevederile Capitolului
 IV al Conventiei; notificarile adresate Secretarului General vor produce efecte din a 90-a zi dupa ce ele au fost primite de Secretarul General.</p><br>

<p style="text-indent:2em;">2. Celelalte Parti Contractante nu vor fi obligate sa acorde beneficiul prevederilor Capitolului IV al prezentei Conventii persoanelor domiciliate sau
 stabilite pe teritoriul oricarei Parti Contractante care va formula o rezerva conform paragrafului 1 al prezentului articol.</p><br>

<p style="text-indent:2em;">3. Orice tara va putea, in momentul cind va semna sau va ratifica prezenta Conventie sau va adera la ea, sa declare ca nu se considera legata de
 paragrafele 2 si 3 ale articolului 44 al Conventiei. Celelalte Parti Contractante nu vor fi legate de aceste paragrafe fata de orice Parte Contractanta
 care va formula asemenea rezerva.</p><br>

<p style="text-indent:2em;">4. Orice Parte Contractanta care va formula o rezerva conform paragrafului 1 sau 3 al prezentului articol va putea in orice moment sa renunte la
 aceasta rezerva printr-o notificare adresata Secretarului General.</p><br>

<p style="text-indent:2em;">5. Cu exceptia rezervelor prevazute in paragrafele 1 si 3 ale prezentului articol, nici o rezerva la prezenta Conventie nu va fi admisa.</p><br>

<p style="font-weight:500;">ARTICOLUL 46</p><br>

<p style="text-indent:2em;">1. Dupa trei ani de la intrarea in vigoare a prezentei Conventii orice Parte Contractanta va putea, printr-o notificare adresata Secretarului General
 al Organizatiei Natiunilor Unite, sa ceara convocarea unei conferinte in scopul revizuirii prezentei Conventii. Secretarul General va notifica aceasta
 cerere tuturor Partilor Contractante si va convoca o conferinta de revizuire daca, intr-un termen de patru luni de la notificarea adresata de el, cel
 putin o treime din Partile Contractante ii comunica asentimentul lor la aceasta cerere.</p><br>

<p style="text-indent:2em;">2. Daca o conferinta va fi convocata conform paragrafului precedent, Secretarul General va aviza despre aceasta toate Partile Contractante si le va
 invita sa prezinte, intr-un termen de trei luni propunerile pe care ele ar dori sa le examineze conferinta. Secretarul General va comunica tuturor
 Partilor Contractante ordinea de zi provizorie a conferintei, ca si textul acestor propuneri, cu cel putin trei luni inainte de data deschiderii
 conferintei.</p><br>

<p style="text-indent:2em;">3. Secretarul General va invita la orice conferinta convocata conform prezentului articol toate tarile prevazute in paragraful 1 al articolului 39,
 ca si tarile care au devenit Parti Contractante in aplicarea paragrafului 2 al articolului 39.</p><br>

<p style="font-weight:500;">ARTICOLUL 47</p><br>

<p style="text-indent:2em;">1. Orice Parte Contractanta va putea propune unul sau mai multe amendamente la prezenta Conventie. Textul oricarui proiect de amendament va fi comunicat
 Secretarului General al Organizatiei Natiunilor Unite, care il va comunica tuturor Partilor Contractante si il va aduce la cunostinta tarilor vizate in
 paragraful 1 al articolului 39.</p><br>

<p style="text-indent:2em;">2. Orice proiect de amendament care va fi transmis conform paragrafului precedent va fi considerat acceptat daca nici o Parte Contractanta nu formuleaza
 obiectii in termen de trei luni socotit de la data la care Secretarul General va transmite proiectul de amendament.</p><br>

<p style="text-indent:2em;">3. Secretarul General va trimite cit mai curind posibil tuturor Partilor Contractante o notificare pentru a le face cunoscut daca s-a formulat o obiectie
 impotriva proiectului de amendament. Daca s-a formulat o obiectie impotriva proiectului de amendament, amendamentul va fi considerat ca nefiind acceptat
 si nu va avea nici un efect. In absenta oricarei obiectii, amendamentul va intra in vigoare pentru toate Partile Contractante la noua luni dupa expirarea 
termenului de trei luni prevazut in paragraful precedent.</p><br>

<p style="text-indent:2em;">4. Independent de procedura de amendare prevazuta in paragrafele 1, 2 si 3 ale prezentului articol, anexele*) la prezenta Conventie pot fi modificate prin
 acordul Administratiilor competente ale tuturor Partilor Contractante; acest acord va putea prevedea ca, pentru o perioada de tranzitie, vechile anexe vor
 ramine in vigoare, in intregime sau in parte, simultan cu noile anexe. Secretarul General va fixa data intrarii in vigoare a noilor texte care rezulta din
 aceste modificari.</p><br>

<p>*) Anexele vor fi difuzate separat.</p><br>

<p style="font-weight:500;">ARTICOLUL 48</p>

<p style="text-indent:2em;"></p><h6>In afara notificarilor prevazute in articolele 46 si 47, Secretarul General al Organizatiei Natiunilor Unite va notifica tarilor prevazute in paragraful 1
 al articolului 39, ca si tarilor care au devenit Parti Contractante in aplicarea paragrafului 2 al articolului 39:</h6><p></p>

<p>a) semnaturile, ratificarile si aderarile in virtutea articolului 39.</p><br>

<p>b) datele la care prezenta Conventie va intra in vigoare conform articolului 40,</p><br>

<p>c) denuntarile pe baza articolului 41,</p><br>

<p>d) incetarea efectelor prezentei Conventii conform articolului 42,</p><br>

<p>e) notificarile primite conform articolului 43,</p><br>

<p>f) declaratiile si notificarile primite conform paragrafelor 1, 3 si 4 ale articolului 45,</p><br>

<p>g) intrarea in vigoare a oricarui amendament conform articolului 47.</p><br>

<p style="font-weight:500;">ARTICOLUL 49</p><br>

<p style="text-indent:2em;">Din momentul in care o tara care este Parte Contractanta la Acordul referitor la aplicarea provizorie a proiectelor conventiilor internationale vamale asupra
 turismului, asupra vehiculelor rutiere comerciale si asupra transportului international al marfurilor pe sosele, de la Geneva, din 16 iunie 1949, va deveni
 Parte Contractanta la prezenta Conventie, ea va lua masurile prevazute in articolul IV al acestui Acord pentru a-l denunta in ceea ce priveste Proiectul
 Conventiei internationale vamale asupra transportului international al marfurilor pe sosele.</p><br>

<p style="font-weight:500;">ARTICOLUL 50</p><br>

<p style="text-indent:2em;">Protocolul de semnare a prezentei Conventii va avea aceeasi forta, valoare si durata ca si Conventia insasi, din care va fi considerat ca facind parte
 integranta.</p><br>

<p style="font-weight:500;">ARTICOLUL 51</p><br>

<p style="text-indent:2em;">Dupa 15 aprilie 1959, originalul prezentei Conventii va fi depus la Secretarul General al Organizatiei Natiunilor Unite, care va transmite copii certificate
 conform fiecarei tari prevazute in paragrafele 1 si 2 ale articolului 39.</p><br>

<p>Drept care subsemnatii, in mod legal autorizati pentru aceasta, au semnat prezenta Conventie.</p><br>

<p style="text-indent:2em;">Intocmita la Geneva, la 15 ianuarie 1959, intr-un singur exemplar, in limbile engleza si franceza, cele doua texte avind aceeasi valabilitate.</p><br>

<p><a href="https://lege5.ro/Gratuit/heztsnzv/conventia-vamala-referitoare-la-transportul-international-al-marfurilor-sub-acoperirea-carnetelor-tir-conventia-tir-din-15011959">Sursa</a></p>



</div>
`