import React, { Component } from 'react'
import { match } from 'react-router'

import ForumThemeItem from './forum-theme-item'
import ForumLastComments from './forum-last-comments'
import ForumNeedHelpItem from './forum-need-help-item'
import ForumAddCommentForm from './forum-add-comment-form'
import fetch from '../../../components/shared/fetch'
import ForumAddThemeModal from './forum-add-theme-modal'
import Spinner from '../../UI/spinner'
import ForumNoContent from './forum-no-content'
import Banner from '../../banner'
import { COMMENTS_ON_PAGE, WIDGET_LAST_COMMENTS_COUNT } from './forum-constants'
import WidgetHeader from '../../shared/components/widget-header'
import ForumCommentDate from './forum-date'
import classNames from 'classnames'
import { canDo, hideScrollBar, addScrollBar } from '../../../store/utils'
import ForumAllCommentsModal from './forum-all-comments-modal'

import './forum-full-page.scss'

interface ForumProps extends ComponentBaseProps {
  pagination: object
  themeTitle: string
  themeSubtitle: string
  isAuthenticated: boolean
  userId: number
  role: string
  permissions: string[]
  match: match<{ sectionId; themeId }>
  onCanAdd?: any
}

export default class ForumFullPage extends Component<ForumProps> {
  state = {
    pagination: {},
    themes: [],
    openSection: 0,
    searchText: '',
    showModal: false,
    sections: [],
    pageSize: WIDGET_LAST_COMMENTS_COUNT,
    pageIndex: 0,
    themeComments: [],
    commentsThemeTitle: '',
    showSpinner: true,
    themeLastComments: [],
    themeId: 0,
    themeTitle: '',
    sectionTitle: '',
    firstLoadSection: [],
    oneThemeId: 0,
    refreshAllCommentInCurrentTheme: 0,
    totalComments: 0,
    totalCount: 0,
    dateAdded: '',
    refreshLastComments: false,
    allThemes: [],
    quoteMessage: '',
    quoteCommentId: 0,
    toogleContentLastComment: true,
    username: '',
    companyName: '',
    currentActiveSection: 0,
    sectionByDefault: 0,
    myThemesSectionId: 0,
    allLastThemesSectionId: 0,
    userId: 0,
    showAllCommentsModal: false,
    dataObj: {},
  }

  componentDidMount() {
    this.reFetchAllInfoOnForum()
  }

  reFetchAllInfoOnForum = () => {
    const { pageSize, pageIndex } = this.state
    fetch(`/forum/getAllSections`).then(sections => {
      const { sectionId, themeId } = this.props.match.params
      const customSectionId = sectionId || sections.data.find(current => current.by_default === 1).id

      this.setState({ sections: sections.data }, () => {
        const result = sections.data.find(section => section.id == customSectionId, this)
        this.setState({ firstLoadSection: result })
      })

      if (customSectionId) {
        this.setState({ sectionByDefault: customSectionId, openSection: customSectionId }, () => {
          //this.fetchForumSections()
          this.fetchThemeForumSections(customSectionId, 1, false)
          this.fetchThemeForumSections(0, 10000000, true)
          // this.fetchThemeForumSections(0, -1, true).then(result => {
          //   if (themeId) {
          //     const theme = result.data.themes.find(theme => theme.id === themeId)
          //     theme &&
          //       this.setState({
          //         oneThemeId: themeId,
          //         themeTitle: theme.title,
          //         themeId,
          //         username: theme.user.username,
          //         userId: theme.user.userId,
          //         dateAdded: theme.dateAdded,
          //       })
          //   }
          // })
          this.fetchThemeComments(0, pageSize, pageIndex, 'themeLastComments')
        })
      }
    })
  }

  getSectionInfo = id => {
    const { sections } = this.state
    const result = sections.find(section => section.id == id, this)
    this.setState({ firstLoadSection: result })
  }

  fetchForumSections = (sectionId?: number) => {
    if (!sectionId) {
      sectionId = this.state.sectionByDefault
    }
    fetch(`/forum/getAllSections`).then(sections => {
      this.setState({ sections: sections.data }, () => {
        const result = sections.data.find(section => section.id == sectionId, this)
        this.setState({ firstLoadSection: result })
      })
      //this.fetchThemeForumSections(0, -1, true)
    })
  }

  fetchThemeComments = (
    themeId: number,
    pageSize: number,
    pageIndex: number,
    stateName: any
  ): void => {
    fetch(`/forum/getMessagesByTheme/${themeId}/${pageSize}/${pageIndex}`).then(comments => {
      this.setState({
        [stateName]: comments.data.messages,
        totalCount: comments.data.count,
        showSpinner: false,
      })
    })
  }

  onSearch = e => {
    const text = e.target.value
    this.setState({ searchText: text })
  }

  searchForum = () => {
    const { t } = this.props
    const { searchText } = this.state
    return (
      <div className="panel-content-row">
        <div className="search-container-secondary">
          <input
            type="text"
            placeholder={t('forum.search')}
            value={searchText}
            onChange={this.onSearch}
          />
          {!searchText && (
            <button type="submit">
              <span className="fa fa-search"></span>
            </button>
          )}
          {searchText && (
            <span className="fa fa-close" onClick={() => this.setState({ searchText: '' })}></span>
          )}
        </div>
      </div>
    )
  }

  removeScroll = () => {
    this.setState({ showModal: true }, () => {
      hideScrollBar()
    })
  }

  createThemeBlock = () => {
    const { t } = this.props
    return (
      <div className="link-right">
        <a data-popup-link="TWE" onClick={() => this.removeScroll()}>
          <span className="button button-orange">
            <i className="fa fa-plus"></i>
          </span>
          {t('forum.create.theme')}
        </a>
      </div>
    )
  }

  forumThemeBlockHeader = () => {
    const { t } = this.props
    return (
      <WidgetHeader title={t('forum.page.title')} className="panel-header">
        {this.createThemeBlock()}
      </WidgetHeader>
    )
  }

  openSubthemes = (id: number) => {
    const { openSection } = this.state
    const sameSection = openSection === id
    this.setState({
      openSection: sameSection ? null : id,
    })
  }

  searchText = (items: any, text: string) => {
    return items.filter(item => {
      return item.title.toLowerCase().indexOf(text.toLowerCase()) > -1
    })
  }

  renderThemesInSection = theme => {
    const {
      title,
      messageCount,
      dateAdded,
      id,
      section,
      user: { username },
    } = theme
    return (
      <div
        className="panel-content-row"
        key={id}
        onClick={() => this.showComment(id, title, section.title, dateAdded, username)}
      >
        <div className="icon col-bg ico"></div>
        <div className="text ellipsis"> {title} </div>
        <span className="number">
          ({messageCount}) {ForumCommentDate(dateAdded)}
        </span>
      </div>
    )
  }

  renderSelectOptions = () => {
    const { sections } = this.state

    const newArr = []
    const sectionsArr = []

    sections.map(({ id, title }) => {
      if (id != this.state.myThemesSectionId) {
        newArr.push({ id, title })
      }
    })
    const renderOptions = [
      ...sectionsArr.map(({ id, title }) => ({
        value: id,
        name: title,
      })),
    ]

    return renderOptions
  }

  forumThemeItem = () => {
    const { t, isAuthenticated, permissions, userId } = this.props
    const {
      openSection,
      searchText,
      sections,
      refreshLastComments,
      allThemes,
      sectionByDefault,
      allLastThemesSectionId,
      myThemesSectionId,
    } = this.state
    let renderForumThemes
    const newSectionsArr = []

    sections.map(({ id, title, description }) => {
      if (!isAuthenticated) {
        if (id != this.state.myThemesSectionId) {
          newSectionsArr.push({ id, title, description })
        }
      } else {
        newSectionsArr.push({ id, title, description })
      }
    })

    const openSectionId = openSection === 0 ? sectionByDefault : openSection
    const canDeteleForumTheme = canDo(permissions, 'forum', 'deleteTopic')

    if (searchText == '' || searchText == ' ') {
      renderForumThemes = newSectionsArr.map(theme => {
        const { id, title, description } = theme
        return (
          <ForumThemeItem
            openSubthemes={this.openSubthemes}
            openSection={openSectionId}
            sectionName={title}
            sectionId={id}
            themeTitle={description}
            closeModal={this.closeModal}
            sectionByDefault={sectionByDefault}
            canDeteleForumTheme={canDeteleForumTheme}
            key={id}
            id={id}
            sections={newSectionsArr}
            showComment={this.showComment}
            refreshLastComments={refreshLastComments}
            allLastThemesSectionId={allLastThemesSectionId}
            myThemesSectionId={myThemesSectionId}
            loggedUserId={userId}
          />
        )
      })
    } else {
      const visibleItems = this.searchText(allThemes, searchText)
      if (visibleItems.length == 0) {
        return <ForumNoContent title={t('forum.no.match')} />
      } else {
        renderForumThemes = visibleItems.map(oneTheme => {
          return this.renderThemesInSection(oneTheme)
        })
      }
    }

    return renderForumThemes
  }

  showComment = (themeId, themeTitle, sectionTitle, dateAdded, username = '', companyName = '') => {
    this.fetchThemeComments(themeId, COMMENTS_ON_PAGE, 0, 'themeComments')
    this.setState({ themeId, themeTitle, sectionTitle, dateAdded, username, companyName })
  }

  showAllCommentsModal = () => {
    this.setState({ showAllCommentsModal: true }, () => {
      hideScrollBar()
    })
  }

  renderAllComments = () => {
    const { t } = this.props
    return (
      <button
        type="button"
        className="button button-orange show-all-comments-modal"
        onClick={() => this.showAllCommentsModal()}
      >
        {t('forum.all.comments')}
      </button>
    )
  }

  forumNewsLetterBlockHeader = () => {
    const { t, permissions } = this.props
    const { toogleContentLastComment } = this.state

    const classRotate = toogleContentLastComment ? 'rotate' : ''
    const closeWidget = !toogleContentLastComment ? 'br-10' : ''
    const canSeeAllComments = canDo(permissions, 'forum', 'list')
    return (
      <WidgetHeader
        className={`panel-header last-comments-header ${closeWidget}`}
        onClick={() =>
          this.setState({ toogleContentLastComment: !this.state.toogleContentLastComment })
        }
      >
        <span className={`last-comments-title ${closeWidget}`}>{t('forum.last.comments')}</span>
        <div className="button-fa-wrapper">
          {canSeeAllComments && this.renderAllComments()}
          <span className={`fa fa-chevron-up ${classRotate}`} />
        </div>
      </WidgetHeader>
    )
  }

  renderComments = themeLastComments => {
    const { commentsThemeTitle } = this.state
    const { t } = this.props
    const renderThemeComment = themeLastComments.map((singleComment, index) => {
      return (
        <ForumLastComments
          singleComment={singleComment}
          commentsThemeTitle={commentsThemeTitle}
          key={index}
          closeModal={this.closeModal}
          showComment={this.showComment}
        />
      )
    })
    if (renderThemeComment.length === 0) {
      return <ForumNoContent title={t('forum.empty.theme')} />
    } else {
      return renderThemeComment
    }
  }

  fetchThemeForumSections = (sectionId: number, countThemes: number, getAllComments: boolean) =>
    fetch(`/forum/getAllThemes/${sectionId}/${countThemes}/0/0`).then(themes => {
      if (!getAllComments && themes.data.themes[0]) {
        const {
          id,
          title,
          dateAdded,
          section,
          user: { username, userId },
        } = themes.data.themes[0]
        this.setState(
          {
            oneThemeId: id,
            themeTitle: title,
            themeId: id,
            username,
            userId,
            oneThemeDaraAdded: dateAdded,
            sectionTitle: section.title,
          },
          () => {
            this.fetchThemeComments(id, COMMENTS_ON_PAGE, 0, 'themeComments')
          }
        )
      } else {
        this.setState({ allThemes: themes.data.themes })
      }
      return themes
    })

  resetCurrentLastPagePagination = () => {
    this.setState({ refreshLastComments: false })
  }

  forumNeedHelpBlockHeader = () => {
    const { userId, permissions } = this.props
    const {
      themeId,
      themeTitle,
      dateAdded,
      sectionTitle,
      username,
      companyName,
      firstLoadSection,
      themeComments,
      totalCount,
      refreshLastComments,
      currentActiveSection,
    } = this.state

    return (
      <ForumNeedHelpItem
        onLoadContentForSection={firstLoadSection}
        themeId={themeId}
        themeTitle={themeTitle}
        sectionTitle={sectionTitle}
        username={username}
        companyName={companyName}
        dateAdded={dateAdded}
        isAuthenticated={this.props.isAuthenticated}
        themeComments={themeComments}
        totalCount={totalCount}
        refreshLastComments={refreshLastComments}
        resetCurrentLastPagePagination={this.resetCurrentLastPagePagination}
        commentAuthor={userId}
        permissions={permissions}
        refreshLastCommentsAfterDeleteComment={this.refreshLastComments}
        quoteMessage={this.quoteMessage}
        currentActiveSection={currentActiveSection}
        onThemeMovedInOtherSextion={this.reFetchAllInfoOnForum}
      />
    )
  }

  quoteMessage = (quoteMessage, quoteCommentId) => {
    this.setState({ quoteMessage, quoteCommentId })
  }

  showQuoteBlock = () => {
    this.setState({ quoteMessage: '' })
  }

  closeModal = (refreshComments?: boolean, sectionId?: number) => {
    const { openSection, pageSize, pageIndex } = this.state
    const newOpenSectionId = sectionId > 0 ? sectionId : openSection
    this.setState({ showModal: false, openSection: newOpenSectionId, showAllCommentsModal: false })
    addScrollBar()
    if (newOpenSectionId > 0 && refreshComments) {
      this.fetchThemeForumSections(newOpenSectionId, 1, false)
      this.setState({ currentActiveSection: newOpenSectionId })
      this.fetchForumSections(newOpenSectionId)
      this.fetchThemeComments(0, pageSize, pageIndex, 'themeLastComments')
    } else {
      this.setState({ showAllCommentsModal: false, toogleContentLastComment: true })
    }
    this.refreshLastComments()
  }

  refreshLastComments = () => {
    const { oneThemeId, themeId, pageSize, pageIndex } = this.state
    const currentThemeId = !themeId ? oneThemeId : themeId
    this.setState({ refreshLastComments: true })
    this.fetchThemeComments(currentThemeId, COMMENTS_ON_PAGE, 0, 'themeComments')
    //this.fetchThemeComments(0, pageSize, pageIndex, 'themeLastComments')
  }

  renderLastCommentBlock = (showAdaptiveClassname = false) => {
    const { themeLastComments, showSpinner, toogleContentLastComment } = this.state
    return (
      <div
        className={classNames({
          'panel-table': true,
          panel: true,
          newslatter: true,
          'for-adaptive': showAdaptiveClassname,
        })}
      >
        {this.forumNewsLetterBlockHeader()}
        {showSpinner ? <Spinner /> : toogleContentLastComment && this.renderComments(themeLastComments)}
      </div>
    )
  }

  renderCustomSection = option => {
    const { t, permissions, userId } = this.props
    const { openSection, refreshLastComments, sectionByDefault, dataObj } = this.state

    const openSectionId = openSection === 0 ? sectionByDefault : openSection
    const canDeteleForumTheme = canDo(permissions, 'forum', 'deleteTopic')

    let optionalProps = {}
    if (option === 'myThemes') {
      optionalProps = {
        sectionName: t('forum.my.themes.title'),
        sectionId: -1,
        themeTitle: this.props.t('forum.my.themes.subtitle'),
        type: 'myThemes',
        key: -1,
        id: -1,
      }
    } else {
      optionalProps = {
        sectionName: t('forum.all.themes.title'),
        sectionId: -2,
        themeTitle: this.props.t('forum.all.themes.subtitle'),
        type: 'allThemes',
        key: -2,
        id: -2,
      }
    }

    return (
      <ForumThemeItem
        openSubthemes={this.openSubthemes}
        openSection={openSectionId}
        closeModal={this.closeModal}
        sectionByDefault={sectionByDefault}
        canDeteleForumTheme={canDeteleForumTheme}
        showComment={this.showComment}
        refreshLastComments={refreshLastComments}
        loggedUserId={userId}
        {...optionalProps}
      />
    )
  }

  render() {
    const {
      showModal,
      themeLastComments,
      oneThemeId,
      themeId,
      quoteMessage,
      toogleContentLastComment,
      quoteCommentId,
      showAllCommentsModal,
    } = this.state
    const { t, isAuthenticated, onCanAdd } = this.props
    const currentThemeId = !themeId ? oneThemeId : themeId
    const showContent = toogleContentLastComment ? true : false
    return (
      <div className="forum-page">
        {showAllCommentsModal && (
          <ForumAllCommentsModal
            closeModal={this.closeModal}
            showComment={this.showComment}
            refreshLastComments={this.refreshLastComments}
          />
        )}
        <ForumAddThemeModal
          showModal={showModal}
          onCanAdd={onCanAdd}
          closeModal={this.closeModal}
          t={t}
          isAuthenticated={isAuthenticated}
        />
        <div className="col-md-6 col-xl-7">
          <div data-move="panel-news" className="panel panel-news">
            {this.forumThemeBlockHeader()}
            {this.searchForum()}
            {this.renderCustomSection('allThemes')}
            {this.forumThemeItem()}
            {isAuthenticated && this.renderCustomSection('myThemes')}
          </div>
          <div className="panel-table panel newslatter last-comments">
            {this.forumNewsLetterBlockHeader()}
            {showContent && this.renderComments(themeLastComments)}
          </div>
        </div>

        <div className="col-md-10 col-xl-9 help-box">
          {this.forumNeedHelpBlockHeader()}
          <Banner bannerId={589} />
          {(themeLastComments.length > 0 && Number(currentThemeId) > 0) && <ForumAddCommentForm
            onCanAdd={onCanAdd}
            currentTheme={currentThemeId}
            isAuthenticated={isAuthenticated}
            refreshLastComments={this.refreshLastComments}
            quoteMessage={quoteMessage}
            quoteCommentId={quoteCommentId}
            showQuoteBlock={this.showQuoteBlock}
          />}
          {this.renderLastCommentBlock(true)}
        </div>
      </div>
    )
  }
}
