import React, { Component } from 'react'
import { Field, InjectedFormProps } from 'redux-form'

import WidgetBase from '../../../shared/components/widget-base'
import Dropdown from '../../../UI/dropdown'
import { required } from '../../../shared/form-validators'
import ProfileMini from '../../../user/profile-mini'
import dict from '../../../shared/dictionaries'
import fetch from '../../../shared/fetch'
import { canDo } from '../../../../store/utils'
import AdsPhoto from './ads-photo'
import Banner from '../../../banner'
import Captcha from '../../../captcha'
import { IAds, IProps } from './types'
import TooltipWrapper from '../../../UI/popover-wrapper'

import './styles.scss'

const STYLE_BASE: string = 'ads-add_'

export default class extends Component<InjectedFormProps<IAds> & IProps> {
  state = {
    subCategoriesDisable: true,
    adId: this.props.match.params.adId,
    updateAble: false,
    activeAds: 0,
    unactiveAds: 0,
    captchaVerified: false,
  }

  componentDidMount() {
    const { permissions, onCanAdd } = this.props
    const { adId } = this.state
    onCanAdd("ads")
    fetch(`/announcement/my/0/10/null/date`).then((ad) => {
      const item = ad.response[0];
      item && this.setState({ activeAds: item.user_active_ads, unactiveAds: item.user_unactive_ads })
    })
    if (adId) {
      this.props.getItem(adId, categoryId => this.categoryChangeHandler(categoryId))
      this.setState({ updateAble: true })
    }
    if (canDo(permissions, 'captcha', 'skip')) {
      this.setState({ captchaVerified: true })
    }
  }

  componentWillUnmount() {
    this.props.resetItem()
  }

  categoryChangeHandler = categoryId => {
    this.setState({ subCategoriesDisable: categoryId !== 437 })
  }

  submit = (event: React.FormEvent) => {
    event.preventDefault()
    const { handleSubmit, langId, adAuthor } = this.props

    handleSubmit(values => {
      const { updateAble, adId } = this.state
      const { title, notes, category, subcategory, dayInterval, photo, captchaKey } = values

      const body = JSON.stringify({
        captchaKey,
        adId,
        title,
        langId,
        photo: photo || [],
        description: notes !== '' ? notes : undefined,
        categoryId: category,
        subcategoryId: category === 437 ? subcategory : undefined,
        placementPeriod: dayInterval,
      })
      const method = updateAble ? 'PUT' : 'POST'
      const submitEndPoint = updateAble ? '/announcement/edit' : '/announcement/add'
      fetch(submitEndPoint, {
        method,
        body,
      }).then(res => this.props.history.push(`/ads/item/${res.id}`))
    })()
  }

  render() {
    const { subCategoriesDisable, updateAble, activeAds, unactiveAds, captchaVerified } = this.state
    const { t, langId, title = '', notes = '', adAuthor, permissions, userId, canAdd } = this.props
    const categories = dict.filterOptionsForAds(langId)
    const subCategories = dict.filterSubOptionsForAds(langId)
    const placementPeriod = [
      { name: t('days.5'), value: 5 },
      { name: t('days.10'), value: 10 },
      { name: t('days.15'), value: 15 },
    ]
    const showCaptcha = !canDo(permissions, 'captcha', 'skip')
    const canEdit = canDo(permissions, 'ad', 'edit')
    const folder = `announcement/${adAuthor || userId}`

    return (
      <form onSubmit={this.submit}>
        <WidgetBase
          title={updateAble ? t('ads.edit') : t('ads.addAds')}
          className={`${STYLE_BASE}wrapper`}
        >
          <div className="panel-body">
            <p>
              {t('ads.you')}{' '}
              <b>
                {activeAds} {t('active.my.ads')}
              </b>
              ,{' '}
              <span className="col-gray">
                {unactiveAds} {t('unactive.my.ads')}
              </span>{' '}
              {t('ads').toLowerCase()}
            </p>
            <div className="select-inputs">
              <div className="select-item">
                <label>
                  <b>{t('ads.choose.category')}</b>
                </label>
                <Field
                  name="category"
                  component={Dropdown}
                  items={categories}
                  placeholder={t('choose')}
                  validate={required}
                  onChange={this.categoryChangeHandler}
                />
              </div>
              <div className="select-item">
                <label>
                  <b>{t('ads.choose.subcategory')}</b>
                </label>
                <Field
                  name="subcategory"
                  component={Dropdown}
                  items={subCategories}
                  placeholder={t('choose')}
                  validate={!subCategoriesDisable ? required : null}
                  disabled={subCategoriesDisable}
                />
              </div>
              <div className="select-item">
                <label>
                  <b>{t('ads.terms')}</b>
                </label>
                <Field
                  name="dayInterval"
                  component={Dropdown}
                  items={placementPeriod}
                  validate={required}
                  placeholder={t('choose')}
                />
              </div>
            </div>
            <AdsPhoto folder={folder} />
            <div className="text-container">
              <span className="label fl-left">
                <b>{t('ads.full.title')}</b>
              </span>
              <span className="col-gray length-countdown fl-right">
                &nbsp;&nbsp;<span className="count">{title.length}</span>&nbsp;&#47;
                <span className="length">250</span>
              </span>
              <Field
                name="title"
                component="input"
                type="text"
                placeholder={t('validation.maxLength', { max: 250 })}
                maxLength={250}
                validate={required}
              />
            </div>
            <div className="text-container">
              <span className="label fl-left">
                <b>{t('ads.desciption')}</b>
              </span>
              <span className="col-gray length-countdown fl-right">
                &nbsp;&nbsp;<span className="count">{notes.length}</span>&nbsp;&#47;
                <span className="length">1500</span>
              </span>
              <Field
                name="notes"
                component="textarea"
                placeholder={t('validation.maxLength', { max: 1500 })}
                style={{ height: '100px', marginBottom: '10px' }}
                maxLength={1500}
              />
              <div className="captcha-position">
                {showCaptcha && (
                  <Captcha
                    verifyCallback={(key) => {
                      this.setState({ captchaVerified: true });
                      this.props.change('captchaKey', key);
                    }}
                    expiredCallback={() => this.setState({ captchaVerified: false })}
                  />
                )}
              </div>
            </div>
          </div>
        </WidgetBase>
        <Banner bannerId={579} />
        <ProfileMini />

        <div className="btns-template clearfix add-ads">
          <button
            className="button button-orange fl-right"
            id="add"
            type="submit"
            disabled={(updateAble && adAuthor !== userId && !canEdit) || !captchaVerified || !canAdd}
          >
            {updateAble ? t('save.changes') : t('add')}
          </button>
          {!canAdd && <TooltipWrapper text={t('ads.limit')} hover>
            <i className="fa fa-question-circle m-l-5" />
          </TooltipWrapper>}
        </div>
      </form>
    )
  }
}
