import { connect } from 'react-redux'
import { compose } from 'recompose'
import {
  reduxForm,
  // formValueSelector,
  // getFormSubmitErrors,
  // getFormSyncErrors,
} from 'redux-form'

import withTranslation from '../../../../../globals/components/withTranslation'

import Component from './component'
import { TemplateProps, TemplateBaseProps } from './types'
// import { saveAsTemplate } from '../../../../../store/actions/cargo'

const formName = 'save-as-template'
// const formSelector = formValueSelector(formName)

const initialValues = {
  name: undefined,
}

export default compose(
  withTranslation,
  connect(),
  reduxForm({
    form: formName,
    initialValues,
    onSubmit: (values: any, dispatch, props: TemplateProps) => {
      const { onSave } = props
      onSave && onSave(values)
    },
  })
)(Component) as React.ComponentClass<TemplateBaseProps>
