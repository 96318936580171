import React, { FunctionComponent } from 'react'
import dayjs from 'dayjs'
import FlexBand from 'flexband'

// import { addMessageLink } from '../../shared/utils'
import { ICompanyActionsProps } from './types'
import Pagination from '../../../UI/pagination'

import './style.scss'

const UserHistory: FunctionComponent<ICompanyActionsProps> = props => {
  const { t, totalItems, pageIndex, pageSize, refresh, actions } = props
 
  return (
    <div>
      {actions.length ? (
        <div>
          <div className="user_history">
            {actions.map((action, i) => (
              <div key={i} className="panel-content-row">
                <span className="meta">{dayjs(action.updateDate).format('DD.MM')}</span>
                <span className="text">{action.logMessage}</span>
              </div>
            ))}
          </div>
          <Pagination
            className="user-history-pagination"
            total={totalItems}
            pageIndex={pageIndex}
            pageSize={pageSize}
            chunks={4}
            onClick={refresh}
          />
        </div>
      ) : (
        <FlexBand className="table-no-entries" justify="center" align="center">
          {t('not.founda')}
        </FlexBand>
      )}
    </div>
  )
}

export default UserHistory
