import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import moment from 'moment'
import fetch from '../../shared/fetch'
import Spinner from '../../UI/spinner'
import Tabs from '../../UI/tabs'
import SideWidget from './side-widgets'
import Banner from '../../banner'
import { canDo, scrollToElement } from '../../../store/utils'
import { getDifferentHoursFromUtcTime } from '../../shared/utils'
import { CompanyItemProps, CompanyItemState, ProfileTabs } from './types'
import AdsTable from '../../ads/ads-table'
import dict from '../../shared/dictionaries'
import TooltipWrapper from '../../UI/popover-wrapper'
import config from '../../../config'
import verified from '../../../images/verified.png'
import SliderModal from '../../UI/slider-modal'
import { confirmAlert } from 'react-confirm-alert'
import Filter from '../../shared/components/widget-filter'
import SearchTable from '../../search/table'
import { fromJS } from 'immutable'
import CompanyReviewList from '../../reviews-company'
import CompanyClaims from '../company-claims'
import CarsContainer from '../../transport/autopark/cars-container'
import FlexBand from 'flexband'
import { changeWidgetFilter } from '../../shared/utility'
import Rating from '../../UI/star-rating'
import { ModalConsumer, ModalContextState } from '../../../context/modal-context'
import EmployeeBlockList from "./employee-block-list"
import DatePicker from '../../UI/date-picker'
import EmployeeItem from "./employee-item"
import { ALLOW_CLAIM, NOT_ALLOWED_CLAIM } from "../company-claims/constants"
import GtuGtc from "../../gtu-gtc-handler"
import CompanyHistory from "../history"
import { toastr } from 'react-redux-toastr'

import './styles.scss'

const SEARCH_PAGE_SIZE = 5

const initialState = (instance: CompanyProfileDetails, props): CompanyItemState => ({
  isLoading: true,
  activePhoto: "",
  approvedTypeAction: "",
  showApprovedModal: false,
  showReregistrationModal: false,
  isOpenModal: false,
  companyId: +props.match.params.itemId,
  businessTypesContent: [],
  companyCargoList: {
    totalItems: null,
    items: []
  },
  companyTransportList: {
    totalItems: 0,
    items: []
  },
  companyAutopark: {
    items: [],
    totalItems: 0
  },
  companyEmployeeList: {
    totalItems: 0,
    items: []
  },
  companyPastEmployeeList: {
    totalItems: 0,
    items: []
  },
  companyBlockEmployeeList: {
    totalItems: 0,
  },
  companyAdsList: {
    totalItems: 0,
    items: []
  },
  companyReviewsList: {
    totalCount: 0,
    currentPage: 0,
    reviews: []
  },
  companyClaimsList: {
    total: 0,
    items: []
  },
  companyInfo: {
    id: null,
    adminUserId: null,
    companyName: null,
    verified: 0,
    additionalInfo: null,
    dateRegistered: null,
    yearCreated: null,
    fiscalCode: null,
    businessTypeName: null,
    adminUserFirstName: null,
    adminUserLastName: null,
    ownerUsername: null,
    ownerLastName: null,
    zipCode: null,
    countryName: null,
    city: null,
    address: null,
    phone1: null,
    phone2: null,
    fax1: null,
    fax2: null,
    skype: null,
    viber: null,
    email1: null,
    email2: null,
    website: null,
    branches: [],
    verifiedAt: null,
    countryId: null,
    avgGrade: 0,
    reviewCount: 0,
    isCompanyAdmin: 0,
    allowClaim: "",
    gtc: null
  },
  categoryKey: 0,
  filterBar: {
    items: [
      {
        value: 'from-last-visit',
        name: props.t('ads.lastVist'),
      },
      {
        value: 'today',
        name: props.t('ads.todays'),
      },
      {
        value: 'last-3-days',
        name: props.t('ads.lastThreeDays'),
      },
      {
        value: 'all',
        name: props.t('all'),
      },
    ],
    selectedKey: 'all',
    onClick: filterKey => {
      changeWidgetFilter(instance, filterKey, () => {
        instance.refreshSearch()
      })
    },
  },
  refreshing: false,
  pageIndex: 0,
  pageSize: 5,
  businessTypeItems: null,
  afterPromiseState: [],
  currentTab: props.match?.params['openedTab'] || 'cargo',
  companyVerificationInfo: {},
  verificated: false,
  tabs: [
    {
      value: 'cargo',
      name: props.t('cargo'),
    },
    {
      value: 'transport',
      name: props.t('transport'),
    },
    {
      value: 'ads',
      name: props.t('ads.name'),
    },
    {
      value: 'employees',
      name: props.t('company.employees'),
    },
    {
      value: 'reviews',
      name: props.t('reviews'),
    },
    {
      value: 'claims',
      name: props.t('claims'),
    },
    {
      value: 'autopark',
      name: props.t('auto-park.header'),
    },
  ],
  inCompany: false,
  directorAddReviewQuota: {},
  showEditDatePanel: false,
  dateRegistered: null,
  showEditButton: false,
  employees: {
    list: []
  }
})

export default class CompanyProfileDetails extends PureComponent<CompanyItemProps, CompanyItemState> {
  modalConsumer: ModalContextState

  constructor(props) {
    super(props)
    this.state = initialState(this, props)
  }

  componentDidMount() {
    this.runCommonCompanyUpdates()
  }

  scrollToIfNeed = () => {
    const { match } = this.props
    setTimeout(() => {
      if (match?.params['openedTab'] === "reviews") {
        scrollToElement('addCompanyReview', 250)
      } else if (match?.params['openedTab'] === "claims") {
        scrollToElement('reviewsContainer', 250)
      }
    }, 1000);
  }

  componentDidUpdate(oldProps: CompanyItemProps) {
    const { itemId = null } = this.props.match?.params as any
    const { itemId: oldItemId = null } = oldProps.match?.params as any

    if ((itemId || oldItemId) && parseInt(itemId) !== parseInt(oldItemId)) {
      this.setState({ ...initialState(this, this.props) }, () => this.runCommonCompanyUpdates())
    }
  }

  runCommonCompanyUpdates = () => {
    this.refreshPageInfo()
    this.fetchCompanyInfo()
  }

  checkAndRenderAdminTabs = () => {
    const { tabs } = this.state
    const { profile, t } = this.props
    const isAdmin = profile?.accessStatus === 'Admin' || false

    const newTabsArr = []

    if (isAdmin) {
      newTabsArr.push(...tabs, {
        value: 'verification',
        name: t('verification.requests'),
      })

      this.setState({ tabs: newTabsArr }, () => {
        this.getCompanyVerificationInfo()
      })
    }
  }

  fetchCompanyInfo = () => {
    const { companyId } = this.state
    const { langId, profile, showUserCompanyInProcessContainer } = this.props

    const inCompany = Number(profile.statusId) !== 1 ? false : true

    fetch(`/company/item/${companyId}/${langId}`).then(res => {
      this.setState(
        {
          isLoading: false,
          companyInfo: res,
          dateRegistered: res.dateRegistered,
          businessTypeItems: dict.businessTypes(langId),
          inCompany
        },
        () => {
          const {
            businessTypeItems: { handler },
            companyInfo: { businessTypesId },
          } = this.state
          this.promiseResolved(handler, businessTypesId)
          showUserCompanyInProcessContainer(res.companyName, inCompany)
        }
      )
    })

    fetch(`/company/employees/${companyId}/2/0/100`).then(
      res => {
        if (res.ok) {
          this.setState({
            employees: {
              list: res.employees,
            },
          })
        }
      }
    )
  }

  refreshPageInfo = () => {
    const { profile } = this.props
    const onFetch = profile.firstName || profile.lastName
    this.getCompanyCargoInfo()
    this.getCompanyTransportInfo()
    this.getCompanyAdsInfo()
    this.getCompanyReviews()
    onFetch && this.getCompanyQuota()
    this.getEmployeesInfo()
    this.getAutoparkCars({})
    this.getClaimsInfo()
    this.scrollToIfNeed()
  }

  updateTabs = () => {
    const { t } = this.props
    const {
      companyCargoList,
      companyAutopark,
      companyEmployeeList,
      companyAdsList,
      companyReviewsList,
      companyTransportList,
      companyClaimsList
    } = this.state

    const tabsNewContent = [
      {
        value: 'cargo',
        name: `${t('cargo')} (${companyCargoList.totalItems || 0})`,
      },
      {
        value: 'transport',
        name: `${t('transport')} (${companyTransportList.totalItems || 0})`,
      },
      {
        value: 'ads',
        name: `${t('ads.name')} (${companyAdsList.totalItems || 0})`,
      },
      {
        value: 'employees',
        name: `${t('company.employees')} (${companyEmployeeList.totalItems || 0})`,
      },
      {
        value: 'reviews',
        name: `${t('reviews')} (${companyReviewsList.totalCount || 0})`,
      },
      {
        value: 'claims',
        name: `${t('claims')} (${companyClaimsList.total || 0})`,
      },
      {
        value: 'autopark',
        name: `${t('auto-park.header')} (${companyAutopark.totalItems || 0})`,
      },
    ]

    const newTabsArr = []
    newTabsArr.push(...tabsNewContent)
    this.setState({ tabs: newTabsArr }, () => {
      this.checkAndRenderAdminTabs()
    })
  }

  getCompanyQuota = () => {
    const { companyId } = this.state
    fetch(`/company/${companyId}/reviewQuota`).then(res => {
      if (res.ok) {
        this.setState({ directorAddReviewQuota: res.data })
      }
    })
  }

  getDateFrom = (selectedKey) => {
    const today = moment()
    let dateFrom = null
    if (selectedKey === 'from-last-visit') {
      dateFrom = today.subtract(10, "days")
    } else if (selectedKey === 'today') {
      dateFrom = today
    } else if (selectedKey === 'last-3-days') {
      dateFrom = today.subtract(3, "days")
    } else {
      return null
    }

    return moment(dateFrom).format("YYYY-MM-DD HH:mm:ss")
  }

  getLanguageLabel = (langId) => {
    let lang

    switch (langId) {
      case 1:
        lang = "ru"
        break;
      case 2:
        lang = "en"
        break;

      default:
        lang = "ro"
        break;
    }

    return lang
  }

  getCompanyCargoInfo = () => {
    const { langId } = this.props
    const { companyId, filterBar: { selectedKey } } = this.state

    const body = JSON.stringify({
      lang: this.getLanguageLabel(langId),
      dateFrom: this.getDateFrom(selectedKey),
      pageIndex: 1,
      pageSize: 20
    })

    fetch(`/company/${companyId}/cargos`, {
      method: 'POST',
      body,
    }).then((response) => {
      this.setState({ companyCargoList: response.d, refreshing: false })
    })
  }

  getCompanyTransportInfo = () => {
    const { langId } = this.props
    const { companyId, filterBar: { selectedKey } } = this.state

    const body = JSON.stringify({
      lang: this.getLanguageLabel(langId),
      dateFrom: this.getDateFrom(selectedKey),
      pageIndex: 1,
      pageSize: 20
    })

    fetch(`/company/${companyId}/transport`, {
      method: 'POST',
      body,
    }).then((response) => {
      this.setState({ companyTransportList: response.d, refreshing: false })
    })
  }

  getCompanyAdsInfo = () => {
    const { langId } = this.props
    const { companyId, categoryKey, pageSize, pageIndex } = this.state

    const body = JSON.stringify({
      lang: this.getLanguageLabel(langId),
      filterCategory: categoryKey || 436,
      pageIndex,
      pageSize
    })

    fetch(`/company/${companyId}/ads`, {
      method: 'POST',
      body,
    }).then((response) => {
      this.setState({ companyAdsList: response.d, refreshing: false })
    })
  }

  getCompanyReviews = () => {
    const { companyId, pageIndex, categoryKey, pageSize } = this.state
    return fetch(`/company/${companyId}/reviews?pageIndex=${pageIndex}&pageSize=${pageSize}&gradeFilter=${categoryKey}`).then(res => {
      this.setState({ companyReviewsList: res.data }, () => {
        this.updateTabs()
      })
    })
  }

  getEmployeesInfo = () => {
    const { companyId, pageIndex, pageSize } = this.state

    fetch(`/company/employees/${companyId}/2/${pageIndex}/${pageSize}`).then(
      res => {
        if (res.ok) {
          this.setState({
            companyEmployeeList: {
              totalItems: res.employees[0] ? res.employees[0].total : null,
              items: res.employees,
            },
          })
        }
      }
    )

    fetch(`/company/employees/${companyId}/3/${pageIndex}/${pageSize}`).then(
      res => {
        if (res.ok) {
          this.setState({
            companyPastEmployeeList: {
              totalItems: res.employees[0] ? res.employees[0].total : null,
              items: res.employees,
            },
          })
        }
      }
    )

    fetch(`/company/${companyId}/block-stats`, {
      method: 'POST',
      body: JSON.stringify({
        pageIndex,
        pageSize,
        dateFrom: null
      }),
    })
      .then(res => {
        if (res.ok) {
          this.setState({
            companyBlockEmployeeList: {
              totalItems: res.d.items[0] ? res.d.items[0].total : null,
            },
          })
        }
      })
  }

  getClaimsInfo = () => {
    const { companyId, pageIndex, categoryKey, pageSize } = this.state

    const body = JSON.stringify({
      claimType: categoryKey || 0,
      pageIndex,
      pageSize
    })

    fetch(`/company/${companyId}/claims`, {
      method: 'POST',
      body,
    }).then((response) => {
      this.setState({
        companyClaimsList: {
          items: response.d.items || [],
          total: response.d.total
        }
      })
    })
  }

  getAutoparkCars = ({ pageIndex = 0 }) => {
    const { companyId, pageSize } = this.state
    const body = JSON.stringify({
      pageIndex,
      pageSize
    })
    fetch(`/company/${companyId}/autopark`, {
      method: 'POST',
      body,
    }).then((companyAutopark) => {
      this.setState({ companyAutopark: companyAutopark.d }, () => {
        setTimeout(() => {
          this.updateTabs()
        }, 1000);
      })
    })
  }

  getCompanyVerificationInfo = () => {
    const { companyId } = this.state
    fetch(`/company/${companyId}/verification`).then(companyVerificationInfo => {
      this.setState({ companyVerificationInfo: companyVerificationInfo.data })
    })
  }

  tabChangeHandler = (tab: ProfileTabs) => {
    this.setState({ currentTab: tab, pageIndex: 0, }, () => this.refreshSearch())
  }

  sendJoinRequest = () => {
    const { companyId } = this.state

    fetch(`/company/join/${companyId}`).then(res => this.toggleInCompanyStatus(true))
  }

  cancelCompanyJoinRequest = () => {
    const { companyId: currentUserCompany } = this.props.profile
    const { companyId: currentPageComapny } = this.state
    fetch(`/company/cancel/${currentUserCompany || currentPageComapny}`).then(_ => {
      this.toggleInCompanyStatus(false)
      location.reload()
    })
  }

  toggleInCompanyStatus = (inCompany) => {
    const { companyInfo: { companyName }, companyId } = this.state
    const { showUserCompanyInProcessContainer } = this.props
    this.setState({ inCompany }, () => {
      showUserCompanyInProcessContainer(companyName, inCompany, companyId)
    })
  }

  promiseResolved = (arr, businessTypesId) => {
    Promise.resolve(arr).then(arrList => {
      const { items } = arrList
      this.setBusinessContent(items, businessTypesId)
    })
  }

  setBusinessContent = (libArr, businessTypesId) => {
    const businessNamesList = []
    businessTypesId.forEach(typeId => {
      libArr.forEach(library => {
        if (library.value === typeId) {
          businessNamesList.push({ businessName: library.name })
        }
      })
    })
    this.setState({
      businessTypesContent: businessNamesList,
    })
    return businessNamesList
  }

  addReviewForCompany = () => {
    this.setState({ currentTab: "reviews" }, () => {
      setTimeout(() => {
        scrollToElement('addCompanyReview')
      }, 500)
    })
  }

  addClaimForCompany = () => {
    this.setState({ currentTab: "claims" }, () => {
      setTimeout(() => {
        scrollToElement('reviewsContainer')
      }, 500)
    })
  }

  changeViewModal = photo => {
    this.setState({
      isOpenModal: !this.state.isOpenModal,
      activePhoto: photo
    })
  }

  renderCompanyVerificationData = () => {
    const { t } = this.props
    const { companyVerificationInfo } = this.state
    if (!companyVerificationInfo || companyVerificationInfo?.verifiedAt) {
      return <div className="no-verification-data">{t('no.requests')}</div>
    }

    const {
      createdOn,
      constituentDocumentDoc,
      selfie,
      ID,
      companyId,
      companyLicensesDocs,
      personalDataProcessingAgreement,
      employeesAuthenticityConfirmation,
      registrationAuthenticityConfirmation,
      isValid,
    } = companyVerificationInfo
    const folder = `company/${companyId}`
    const configUrl = (config as any).apiUrl

    let documentsLicenses
    if (companyLicensesDocs) {
      documentsLicenses = companyLicensesDocs.map((doc, index) => {
        const img = doc ? (
          doc?.fileName?.endsWith('.pdf') ?
            <a href={`${(config as any).apiUrl}/common/files/${doc?.uuid}`} target="_blank" rel="noopener noreferrer">
              <img src={`${(config as any).apiUrl}/common/file/pdf-icon-64.png`} />
            </a>
            :
            <img src={`${(config as any).apiUrl}/common/file/${folder}/${doc?.uuid}`}
              onClick={() => this.changeViewModal(`${folder}/${doc?.uuid}`)}
            />
        ) : (
          <b>{t('not.added')}</b>
        )

        return (
          <li key={index}>
            {t('licensy')} {++index} :{img}
          </li>
        )
      })
    }

    return (
      <div className="panel-body company-confirm company-iconed">

        {isValid === 1 ? (
          <h3 className="verification-title">
            {t('company.was.verified')}
            <img src={verified} alt="verified-company" />
            <b
              onClick={() => this.setStatusVerification(companyId, ID, false, "decline")}
              className="cancel-verification"
            >
              ({t('cancel')})
            </b>
          </h3>
        ) : (
          <h2>{t('verification.data')}</h2>
        )}
        <div className="panel-content was-requested">
          {t('date.of.receipt.of.the.application')}:{' '}
          <b>{moment(createdOn).add(getDifferentHoursFromUtcTime(), 'hours').format('DD/MM/YYYY HH:mm')}</b>
        </div>
        <ul>
          <li>
            {t('constituent.document.of.the.company')} :

            {constituentDocumentDoc?.fileName?.endsWith('.pdf') ?
              <a href={`${configUrl}/common/files/${constituentDocumentDoc?.uuid}`} target="_blank" rel="noopener noreferrer">
                <img src={`${configUrl}/common/file/pdf-icon-64.png`} className="max-width-50" />
              </a>
              :
              <img src={`${configUrl}/common/file/${folder}/${constituentDocumentDoc?.uuid}`} className="max-width-50"
                onClick={() => this.changeViewModal(`${folder}/${constituentDocumentDoc?.uuid}`)}
              />
            }
          </li>
          {documentsLicenses}
          <li>
            {t('Personal.photo')} :

            <img
              src={`${configUrl}/common/file/${folder}/${selfie}`}
              alt="image"
              className="max-width-50"
              onClick={() => this.changeViewModal(`${folder}/${selfie}`)}
            />
          </li>
          <li>
            {t('verified.checkbox.one.approve')}:
            <b>{registrationAuthenticityConfirmation > 0 ? t('approved') : t('not.approved')}</b>
          </li>
          <li>
            {t('verified.checkbox.two.approve')}:
            <b>{employeesAuthenticityConfirmation > 0 ? t('approved') : t('not.approved')}</b>
          </li>
          <li>
            {t('verified.checkbox.three.approve')}:
            <b>{personalDataProcessingAgreement > 0 ? t('approved') : t('not.approved')}</b>
          </li>
        </ul>
        {isValid !== 1 && (

          <div className="action-verification-block">
            <button
              type="button"
              className="button button-orange"
              onClick={() => this.setStatusVerification(companyId, ID, true, "approve")}
            >
              {t('confirm')}
            </button>

            <button
              type="button"
              className="button "
              onClick={() => this.setStatusVerification(companyId, ID, false, "decline")}
            >
              {t('cancel')}
            </button>
          </div>
        )}
      </div>
    )
  }

  setStatusVerification = (companyId, ID, isValid, decision) => {
    const { t } = this.props

    const alert = decision === "approve" ? t('confirm.verification') : t('refect.verification')

    confirmAlert({
      title: t("approve.decision"),
      message: (
        alert
      ) as any,
      buttons: [
        {
          label: "OK",
          onClick: () => {
            this.setState({ showApprovedModal: false })
            fetch(`/company/${companyId}/verification/${ID}`, {
              method: 'PUT',
              body: JSON.stringify({
                companyId,
                id: ID,
                isValid,
              }),
            }).then(response => {
              if (response.ok) {
                this.setState({ verificated: true }, () => {
                  this.getCompanyVerificationInfo()
                })
              }
            })
          },
        },
        {
          label: t('button.close'),
          onClick: () => this.setState({ showApprovedModal: false }),
        },
      ],
      onClickOutside: () => this.setState({ showApprovedModal: false }),
      onKeypressEscape: () => this.setState({ showApprovedModal: false }),
    })
  }

  renderCompanyCargoData = () => {
    const { t } = this.props
    const { currentTab, filterBar, companyCargoList, refreshing, companyId } = this.state

    const baseProps = {
      pageIndex: 0,
      pageSize: 20,
      totalItems: companyCargoList.totalItems,
      refresh: this.refreshSearch,
    }

    return (
      <div className="cargo-transport-list">
        <h5 className="title-secondary tt-u">{t(`${currentTab}.offers`)}</h5>
        <Filter {...filterBar} loadLanguages />
        <SearchTable
          {...baseProps}
          searchType={"cargo"}
          openIn="preview"
          processing={refreshing}
          items={fromJS(companyCargoList.items)}
          userId={companyId}
          exactUserId={companyId}
          notFoundText={t('not.found')}
          showDateIsShort
        />
      </div>
    )
  }

  changePageSizeHandler = (employeesType, employeesStatusId, newPageSize) => {
    this.setState({ pageSize: newPageSize }, () => this.getEmployeesInfo())
  }

  changePageHandler = (employeesType, employeesStatusId, newPageIndex, pageSize) => {
    this.setState({ pageIndex: newPageIndex }, () => this.getEmployeesInfo())
  }

  showBlockListModal = () => {
    const { companyInfo } = this.state
    const { showModalComponent, clearModal } = this.modalConsumer
    showModalComponent(
      <EmployeeBlockList
        onClose={clearModal}
        companyInfo={companyInfo}
      />,
      {
        className: `employee-block-list-modal`,
        skipLayout: true,
        backdrop: true,
      }
    )
  }

  renderEmployeesData = () => {
    const { t } = this.props
    const { companyEmployeeList, companyPastEmployeeList, companyBlockEmployeeList: { totalItems } } = this.state

    return (
      <div className="cargo-transport-list">
        <h5 className="title-secondary tt-u">{t('current.employes')} ({companyEmployeeList.totalItems || 0})</h5>
        {totalItems && <span className="in-block-list" onClick={() => this.showBlockListModal()}>
          {t('employee.in.block.lists')} <b >{totalItems} {t('employee.block.list')}</b>
        </span>}
        <div className="employee-items-content">
          {this.renderEmployesProfiles(companyEmployeeList.items, true)}
        </div>

        <h5 className="title-secondary tt-u">{t('employee.past')} ({companyPastEmployeeList.totalItems || 0})</h5>
        <div className="employee-items-content past-employee">
          {this.renderEmployesProfiles(companyPastEmployeeList.items, false)}
        </div>
      </div>
    )
  }
  renderEmployesProfiles = (items, showDeleteIcon) => {
    const { companyId } = this.state
    return items.map(({ userId, userGender }) => {
      return <EmployeeItem
        customUserId={+userId}
        key={userId}
        {...{ userGender }}
        {...{ showDeleteIcon }}
        {...{ companyId }}
        refreshInfo={this.getEmployeesInfo}
        showEmployeeInfo
      />
    })
  }


  renderClaimsData = () => {
    const { t, profile, userId } = this.props
    const { categoryKey, companyId, companyClaimsList, pageIndex, companyInfo: { allowClaim } } = this.state

    const claimsTypes = dict.claimsTypes(t)

    const baseProps = {
      pageIndex,
      pageSize: SEARCH_PAGE_SIZE,
      totalItems: companyClaimsList.total,
      refresh: this.refreshSearch,
    }

    return (
      <div className="cargo-transport-list">
        <h5 className="title-secondary tt-u">{t('claims.company.title')} ({companyClaimsList.total})</h5>
        <Filter
          items={claimsTypes}
          selectedKey={!categoryKey ? '' : categoryKey}
          emptyOption={t('company.all.claims')}
          onClick={this.handleFilterClick}
          loadLanguages
        />
        <CompanyClaims
          {...baseProps}
          {...{ profile }}
          {...{ claimsTypes }}
          {...{ allowClaim }}
          reviewingCompanyId={companyId}
          reviewingUserId={userId}
          reCheckShowAddReviewLink={this.getCompanyQuota}
          myCompanyId={Number(profile.companyId)}
          claims={companyClaimsList.items}
          {...{ categoryKey }}
          scrollToReviewForm={() => this.addReviewForCompany()}
          notFoundText={categoryKey ? t('claim.company.in.group.not.found.all') : t('claim.company.not.found.all')}
          reCalcCounter={this.reCalcAllReviewsContainers}
        />
      </div>
    )
  }

  renderAutoparkData = () => {
    const { t } = this.props
    const { companyAutopark, pageSize, companyId } = this.state

    return (
      <div className="cargo-transport-list">
        <h5 className="title-secondary tt-u">{t('company.autopark.dropdown')} ({companyAutopark.totalItems})</h5>
        {companyAutopark.items.length > 0 && <CarsContainer
          carList={companyAutopark.items}
          canEdit={false}
          total={companyAutopark.totalItems}
          refresh={this.getAutoparkCars}
          pageSize={pageSize}
          scrollToElemId={"tabsContent"}
          imageFolder={`auto-park/${companyId}`}
          showInUrlCurrentUserId={true}
        />}
      </div>
    )
  }

  refreshSearch = (pageIndex?: number, tab?: ProfileTabs) => {
    const { currentTab } = this.state
    this.setState({ refreshing: true })

    switch (currentTab) {
      case 'cargo':
        this.getCompanyCargoInfo()
        break
      case 'transport':
        this.getCompanyTransportInfo()
        break
      case 'ads':
        this.setState({ pageIndex }, () => {
          this.getCompanyAdsInfo()
        })
        break
      case 'reviews':
        this.getCompanyReviews()
        break
      case 'employees':
        this.getEmployeesInfo()
        break
      case 'claims':
        this.setState({ pageIndex }, () => {
          this.fetchCompanyInfo()
          this.getClaimsInfo()
        })
        break
      case 'autopark':
        this.getAutoparkCars({})
        break
      default:
        return false
    }
  }

  renderCompanyTransportContent = () => {
    const { t } = this.props
    const { currentTab, filterBar, companyTransportList, refreshing, companyId } = this.state

    const baseProps = {
      pageIndex: 0,
      pageSize: 20,
      totalItems: companyTransportList.totalItems,
      refresh: this.refreshSearch,
    }

    return (
      <div className="cargo-transport-list">
        <h5 className="title-secondary tt-u">{t(`${currentTab}.offers`)}</h5>
        <Filter {...filterBar} loadLanguages />
        <SearchTable
          {...baseProps}
          searchType={"transport"}
          openIn="preview"
          processing={refreshing}
          items={fromJS(companyTransportList.items)}
          userId={companyId}
          exactUserId={companyId}
          notFoundText={t('not.find')}
          showDateIsShort
        />
      </div>
    )
  }

  handleFilterClick = filterKey => {
    this.setState({ categoryKey: filterKey }, () => {
      this.refreshSearch()
    })
  }

  reCalcAllReviewsContainers = () => {
    this.fetchCompanyInfo()
    this.refreshPageInfo
  }

  renderCompanyReviews = () => {
    const { t, profile, userId } = this.props
    const { categoryKey, companyId, companyReviewsList } = this.state

    const reviewTypes = dict.reviewTypes(t)

    const baseProps = {
      pageIndex: 0,
      pageSize: SEARCH_PAGE_SIZE,
      totalItems: 0,
      refresh: this.refreshSearch,
    }

    return (
      <div className="cargo-transport-list">
        <h5 className="title-secondary tt-u">{t('review.company.title')}</h5>
        <Filter
          items={reviewTypes}
          selectedKey={!categoryKey ? '' : categoryKey}
          emptyOption={t('review.all')}
          onClick={this.handleFilterClick}
          loadLanguages
        />
        <CompanyReviewList
          {...baseProps}
          {...{ profile }}
          reviewingCompanyId={companyId}
          reviewingUserId={userId}
          reCheckShowAddReviewLink={this.getCompanyQuota}
          myCompanyId={Number(profile.companyId)}
          reviews={companyReviewsList.reviews}
          notFoundText={categoryKey ? t('review.not.found') : t('review.company.not.found.all')}
          reCalcCounter={this.reCalcAllReviewsContainers}
        />
      </div>
    )
  }

  renderCompanyAdsData = () => {
    const { t, langId } = this.props
    const { categoryKey, companyId, companyAdsList, pageIndex } = this.state
    const filterItems = dict.filterOptionsForAds(langId)

    const baseProps = {
      pageIndex,
      pageSize: SEARCH_PAGE_SIZE,
      totalItems: companyAdsList.totalItems,
      refresh: this.refreshSearch,
    }

    return (
      <div className="company-ads-content">
        <h5 className="title-secondary tt-u">{t('ads.name.tab.title')}</h5>
        <Filter
          items={filterItems}
          selectedKey={categoryKey === 0 || categoryKey === 436 ? '' : categoryKey}
          emptyOption={t('ads.allAds')}
          onClick={this.handleFilterClick}
          loadLanguages
        />
        <AdsTable
          {...baseProps}
          hideCompanyName={true}
          ads={companyAdsList.items}
          loggedUserId={companyId}
          openIn="preview"
          embeddable
        />
      </div>
    )
  }

  renderTabsContent = currentTab => {
    switch (currentTab) {
      case 'verification':
        return <div className="verification-content">{this.renderCompanyVerificationData()}</div>
      case 'cargo':
        return <div className="company-cargo-content">{this.renderCompanyCargoData()}</div>
      case 'ads':
        return <div className="company-ads-content">{this.renderCompanyAdsData()}</div>
      case 'transport':
        return <div className="company-transport-content">{this.renderCompanyTransportContent()}</div>
      case 'reviews':
        return <div className="company-reviews-content">{this.renderCompanyReviews()}</div>
      case 'employees':
        return <div className="company-employees-content">{this.renderEmployeesData()}</div>
      case 'claims':
        return <div className="company-claims-content">{this.renderClaimsData()}</div>
      case 'autopark':
        return <div className="company-autopark-content">{this.renderAutoparkData()}</div>

      default:
        return <p style={{ textAlign: 'center' }}>Work in progress</p>
    }
  }

  getWordEnding = (numberBase: number) => {
    const { langId } = this.props
    const lastDigit = numberBase % 10

    if (langId === 1) {
      if (lastDigit === 1) {
        return 'а'
      } else {
        return 'ов'
      }
    } else {
      if (lastDigit === 1) {
        return ''
      } else {
        return 's'
      }
    }
  }

  onChangeDate = (dateRegistered: Date) => {
    this.setState({ dateRegistered })
  }

  changeDatePanel = () => {
    const { showEditDatePanel } = this.state
    this.setState({ showEditDatePanel: !showEditDatePanel })
  }

  onCompanyChangeDateRegistration = (id, date) => {
    const { companyChangeDateRegistration } = this.props
    const { showEditDatePanel } = this.state
    this.setState({ showEditDatePanel: !showEditDatePanel })
    companyChangeDateRegistration(id, date)
  }

  renderTooltipText = (popoverText, editOrRequestLink, text) => {
    const { t } = this.props
    return (
      <TooltipWrapper text={popoverText} hover>
        <Link
          to="#"
          className={classNames({
            'additional-styles': true,
            'top-0': !editOrRequestLink,
          })}
        >
          <span >{text}</span>
        </Link>
      </TooltipWrapper>
    )
  }

  resendReregistraionEmail = () => {
    const { t } = this.props

    confirmAlert({
      title: t('resend.reregistererd.email'),
      message: (
        alert
      ) as any,
      buttons: [
        {
          label: "OK",
          onClick: () => {
            this.setState({ showReregistrationModal: false })
            fetch(`/auth/resend-reregistration-email`, {
              method: 'POST',
            }).then(() => {
              toastr.success(t('reregisration.email.was.sended'))
            })
          },
        },
        {
          label: t('button.close'),
          onClick: () => this.setState({ showReregistrationModal: false }),
        },
      ],
      onClickOutside: () => this.setState({ showReregistrationModal: false }),
      onKeypressEscape: () => this.setState({ showReregistrationModal: false }),
    })
  }

  render() {
    const {
      isLoading,
      companyInfo,
      companyId,
      tabs,
      currentTab,
      companyInfo: { branches, countryId, isClosed },
      businessTypesContent,
      directorAddReviewQuota,
      showEditDatePanel,
      dateRegistered,
      showEditButton,
      isOpenModal,
      activePhoto,
      employees
    } = this.state
    const { permissions, userId, profile, isAuthenticated, t, langId, showRequestInCompanyInProcess, isAuth } = this.props
    const isAdmin = canDo(permissions, 'admin')
    const canEdit = canDo(permissions, 'company', 'edit')

    let editOrRequestLink
    const isCurrentCompanyAdmin = companyInfo.adminUserId === userId

    if (isAdmin || canEdit || companyInfo.adminUserId === userId) {
      editOrRequestLink = (
        <Link to={`/company/edit/${companyId}`} className="">
          <i className="fa fa-pencil mobile-only"></i>
          <span>{t('company.edit')}</span>
        </Link>
      )
    } else if (
      +profile.companyId !== companyId &&
      profile.statusId !== '1' &&
      !profile.companyId &&
      isAuthenticated &&
      !showRequestInCompanyInProcess &&
      !isClosed
    ) {
      editOrRequestLink = (
        profile.isReregistered === "N" ?
          <>
            <TooltipWrapper text={t('you.have.not.yet.re.registered')} hover>
              <Link to="#" className="" onClick={() => this.resendReregistraionEmail()}>
                <i className="fa fa-envelope-square mobile-only"></i>
                <span>{t('company.send.request')}</span>
              </Link>
            </TooltipWrapper>

          </> :
          <>
            <Link to="#" className="" onClick={this.sendJoinRequest}>
              <i className="fa fa-envelope-square mobile-only"></i>
              <span>{t('company.send.request')}</span>
            </Link>
          </>
      )
    } else if ((showRequestInCompanyInProcess || profile.statusId === '1') || (+profile.companyId === companyId && profile.statusId !== '2')) {
      editOrRequestLink = (
        <Link to="#" className="" onClick={this.cancelCompanyJoinRequest}>
          <i className="fa fa-times mobile-only" />
          <span>{t('company.cancel.request')}</span>
        </Link>
      )
    }

    const countryList = dict.countries(langId)

    let coutryAbbr
    let coutryFullName

    if (countryList.items) {
      const coutryAbbrArr = countryList.items.find(
        oneCountry => oneCountry.value === Number(countryId)
      )
      if (coutryAbbrArr) {
        coutryAbbr = coutryAbbrArr.iso
        coutryFullName = coutryAbbrArr.name
      }
    }

    const canAddReview = Number(profile.isCompanyAdmin) > 0 && directorAddReviewQuota.reviewQuota > 0
    const canAddClaim = Number(profile.isCompanyAdmin)
    const canEditDate = canDo(permissions, 'dateRegistration', 'edit')
    const dateToday = moment().endOf('day')

    if (isLoading) return <Spinner />

    const gtugtcProps = {
      code: companyInfo.gtc,
      type: "company",
      id: companyInfo.id,
      refresh: () => this.fetchCompanyInfo()
    }

    return (
      <div className="row">
        <SliderModal
          photos={[activePhoto]}
          isOpen={isOpenModal}
          onExit={() => this.setState({ isOpenModal: false })}
          initialSlide={1}
          imageFolder={``}
        />
        <div className="col-lg-12">
          <span data-replace="panel-reg0"></span>
          <div data-move="panel-reg" className="panel panel-company company-page">
            <div
              className={classNames({
                'panel-company-header': true,
                'panel-company-top-header': true,
                'company-iconed': companyInfo.verified,
              })}
            >
              <span className="name">{companyInfo.companyName}</span>
              <div className={'additional-info'}>
                <div className="additional-info-container">
                  {coutryFullName && (
                    <span className="meta country">
                      <TooltipWrapper text={coutryFullName} hover>
                        ({coutryAbbr})
                      </TooltipWrapper>
                    </span>
                  )}

                  <GtuGtc {...gtugtcProps} />
                </div>
                <div className="additional-info-container-actions">
                  {editOrRequestLink}
                  {profile.isCompanyAdmin && !isCurrentCompanyAdmin ? (
                    <>
                      {Number(profile?.companyId) !== companyId && canAddClaim && <Link
                        to="#"
                        className={classNames({
                          'additional-styles': true,
                          'top-0': !editOrRequestLink,
                          'mt-2': true
                        })}
                      >
                        <i className="fa fa-times mobile-only" />
                        {profile.verifiedCompany > 0 && companyInfo.allowClaim === ALLOW_CLAIM ? (
                          <span onClick={() => this.addClaimForCompany()}>{t('company.claim.add')}</span>
                        ) : profile.verifiedCompany > 0 && companyInfo.allowClaim === NOT_ALLOWED_CLAIM ? (
                          this.renderTooltipText(t('add.claim.already.added'), editOrRequestLink, t('company.claim.add'))
                        ) : (
                          this.renderTooltipText(t('add.claim.for.verify.company'), editOrRequestLink, t('company.claim.add'))
                        )}

                      </Link>}
                      {Number(profile?.companyId) !== companyId && canAddReview && <Link
                        to="#"
                        className={classNames({
                          'additional-styles': true,
                          'top-0': !editOrRequestLink,
                        })}
                      >
                        <i className="fa fa-times mobile-only" />
                        {profile.verifiedCompany > 0 ? (
                          <span onClick={() => this.addReviewForCompany()}>{t('review.add')}</span>
                        ) : (
                          this.renderTooltipText(t('add.reviews.for.verify.company'), editOrRequestLink, t('review.add'))
                        )}
                      </Link>}
                    </>
                  ) : (
                    isAuth && <TooltipWrapper text={t('review.add.alert')} hover placement={'right'}>
                      <Link
                        to="#"
                        className={classNames({
                          'additional-styles': true,
                          'top-0': !editOrRequestLink,
                        })}
                      >
                        <span>{t('review.add')}</span>
                      </Link>
                    </TooltipWrapper>
                  )}
                </div>
              </div>
            </div>
            <div className="panel-company-header">
              <p className="meta-gray">
                {companyInfo.verified
                  ? `${t('company.verified.at')} ${moment(
                    new Date(companyInfo.verifiedAt)
                  ).format('DD.MM.YYYY')}`
                  : t('company.did.not.pass.verification')}
              </p>
              {companyInfo.additionalInfo && (
                <div className="panel-company-desc">
                  <ul>
                    <li>{companyInfo.additionalInfo}</li>
                  </ul>
                </div>
              )}
            </div>
            <div className="panel-company-footer profile-mini_wrapper company-page">
              <div className="panel-form-col user-data-column">
                <span className="meta">{t('registration.date')}</span>
                <span id="registration-date">
                  {showEditDatePanel ? (
                    <span className="date-registered-panel">
                      <DatePicker
                        initialValue={dateRegistered}
                        maxDate={dateToday}
                        onChangeDate={this.onChangeDate}
                        onBlur={() => this.setState({ showEditButton: true })}
                        onFocus={() => this.setState({ showEditButton: false })}
                      />
                      {showEditButton && (
                        <i
                          className="fa fa-check-square col-green"
                          onClick={() =>
                            this.onCompanyChangeDateRegistration(companyInfo.id, dateRegistered)
                          }
                        />
                      )}
                    </span>
                  ) : (
                    <span className="date-registered-line">
                      {new Date(dateRegistered).toLocaleDateString('ru-ru')}
                    </span>
                  )}
                  {canEditDate && (
                    <i className="fa fa-pencil col-blue" onClick={this.changeDatePanel} />
                  )}
                </span>

              </div>

              <div className="panel-form-col rating-container">

                <FlexBand direction="column">
                  <div className="rating-line-container">
                    <span className="meta">{t('company.grid.rating')}</span>
                    <Link to="#" key={1} onClick={() => this.tabChangeHandler('reviews')} >
                      {t('based.on.review(s)', {
                        reviewCount: companyInfo.reviewCount,
                        wordEnding: this.getWordEnding(companyInfo.reviewCount),
                      })}
                    </Link>
                  </div>
                  {companyInfo?.avgGrade
                    ? [
                      <div key={0} style={{ display: "flex" }}>
                        <Rating averageRating={companyInfo?.avgGrade} />{' '}
                        {companyInfo?.avgGrade.toFixed(2)}
                      </div>
                    ]
                    : t('not.enough.data')}
                </FlexBand>
              </div>

              <div className="panel-form-col">
                <span className="meta">
                  {t('statistics.black.list')} / {t('statistics.claims')}
                </span>
                {companyInfo.claimsCount === 0 ?
                  <span>{t('company.not.in.black.list')}</span> :
                  <span>{t('company.in.black.list')}</span>}
              </div>
            </div>
          </div>
          <Banner bannerId={567} />
          <Tabs tabItems={tabs} currentTab={currentTab} onTabClick={this.tabChangeHandler}>
            {this.renderTabsContent(currentTab)}
          </Tabs>
        </div>
        <div className="col-lg-4">
          <SideWidget
            companyInfo={companyInfo}
            title={t('company.info')}
            widgetType="aboutCompany"
            businessTypesContent={businessTypesContent}
            showCertificateModal={this.changeViewModal}
            employees={employees}
          />
          <SideWidget
            companyInfo={companyInfo}
            title={t('company.head.office')}
            widgetType="headOffice"
          />
          {branches.length > 0 && (
            <SideWidget companyInfo={companyInfo} title={t('branches')} widgetType="branches" />
          )}

          <CompanyHistory {...{ companyId }} />
        </div>
        <ModalConsumer>
          {modalConsumer => {
            this.modalConsumer = modalConsumer
            return null
          }}
        </ModalConsumer>
      </div>
    )
  }
}
