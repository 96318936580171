import { connect } from 'react-redux'
import { compose } from 'recompose'

import Component from './component'
import withTranslation from '../../../../globals/components/withTranslation'

export default compose(
  withTranslation,
  connect(state => {
    const loginAttempt = state.socket.loginAttempt
    const { payload, autoResolveIn, permissionStatus } = loginAttempt

    return {
      autoResolveIn,
      payload,
      permissionStatus,
    }
  })
)(Component)
