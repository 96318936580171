export const int4 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Типовая форма международной транспортной накладной (CMR).</h4><p></p>

<p></p><h4>Порядок заполнения</h4><p></p>

<p style="font-weight:500;">Графа 1</p><br>

<p style="text-indent:2em;">Указывается фирма - отправитель, её полный адрес, страна, город, индекс, улица, номер дома. Если товар отправляется в Россию 
по поручению контрактодержателя третьей фирмой, то указывается название этой фирмы и делается приписка: "по поручению". Например: "фирма
 "В" (отправитель) по поручению "фирмы "А" (контрактодержатель)</p><br>
 
<p style="font-weight:500;">Графа 2	</p><br>

<p>Указывается наименование фирмы-получателя, её полный адрес, страна, город, индекс, улица, номер дома.</p><br>

<p style="font-weight:500;">Графа 3	</p><br>

<p>Адрес места окончательной разгрузки товара (склад фирмы - получателя).</p><br>

<p style="font-weight:500;">Графа 4	</p><br>

<p>Адрес места погрузки товара и дата погрузки.</p><br>

<p style="font-weight:500;">Графа 5	</p><br>

<p style="text-indent:2em;">Вписываются номера инвойсов (счетов - фактур, счетов - профор
м), номер книжки МДП (TIR), если имеются номера сертификатов (ветеринарного
, фитосанитарного, соответствия и т.д.)</p><br>

<p style="font-weight:500;">Графа 6,7,8,9,10,11,12	</p><br>

<p>Указываются количества мест, род упаковки, наименование товаров, коды ТН ВЭД, вес брутто. f</p><br>

<p style="font-weight:500;">Графа 13</p><br>	

<p>Важная графа.</p><br> 

<p style="text-indent:2em;">Указывается таможенный орган получателя (таможня, таможенный пост и код таможенного поста)
, склад временного хранения или таможенный склад, его адрес и номер лицензии (желательно с датой окончания срока
 действия лицензии)</p><br>
 
<p style="font-weight:500;">Графа 15</p><br>	

<p>Указываются условия поставки по "Инкотермс - 2000"</p><br>

<p style="font-weight:500;">Графа 16,17</p><br>	

<p>Указывается наименование перевозчика, его адрес. В этой графе перевозчик
 ставит свою печать.</p><br>
 
<p style="font-weight:500;">Графа 21</p><br>	

<p>Дата заполнения СМR.</p><br>

<p style="font-weight:500;">Графа 24</p><br>	

<p>Для отметок получателя. Дата получения груза, его печать.</p><br>

<p style="font-weight:500;">Графа 25,26.</p><br>	

<p>Регистрационные номера тягача и прицепа.</p>

<p></p><h4>Комментарий к заполнению.</h4><p></p>

<p style="text-indent:2em;">В пункте 1 указываются реквизиты грузоотправителя (наименование, адрес, страна). Кроме
 этих данных перевозчик обязан записать номер телефона и фамилию контактного лица грузоотправителя на случай,
 если возникнут какие-либо вопросы в процессе перевозки (на таможне, у грузополучателя).</p><br>

<p style="text-indent:2em;">Пункт 2 содержит реквизиты грузополучателя (наименование, адрес, страна). Необходимо также иметь номер телефона
 грузополучателя для решения возникающих вопросов на таможне при въезде в страну, для поиска офиса получателя в
 случае плохого владения национальным языком получателя.</p><br>

<p style="text-indent:2em;">В пункте 3 указывается адрес места разгрузки. Если адрес места разгрузки совпадает с адресом грузополучателя, то
 у перевозчика не должно возникать особых вопросов. Но, как правило, у 50% отправителей адрес места разгрузки не
 совпадает с адресом грузополучателя. Это связано с тем, что груз направляется сразу на филиал, склад или магазин.
 В данном случае перевозчику необходимо иметь номер телефона места разгрузки, а если получатель и место разгрузки
 находятся в разных городах, то сразу же у грузоотправителя уточнить маршрут движения, выяснив, необходимо ли
 заезжать к грузополучателю или сразу ехать по адресу места разгрузки.</p><br>

<p>В пункте 4 указываются место и дата погрузки груза.</p>

<p style="text-indent:2em;"></p><h6>Пункт 5 содержит перечень прилагаемых документов. К ним относятся:</h6><p></p>

<p>счет-фактура (invoice);</p><br>

<p>отгрузочная спецификация;</p><br>

<p>сертификат качества, если грузы имеют промышленное происхождение;</p><br>

<p>ветеринарный сертификат, если грузы имеют животное происхождение;</p><br>

<p>карантинный сертификат, если грузы имеют растительное происхождение;</p><br>

<p>сертификат о происхождении (certificate of origin);</p><br>

<p>акт загрузки.</p><br>

<p>Описание этих документов приведено в параграфах 2 - 8.</p><br>

<p style="text-indent:2em;">В пункт 6 заносятся знаки и номера, обозначающие класс, подкласс перевозимых опасных грузов, классифицируемых
 по Конвенции ДОПОГ. Особенности перевозки опасных грузов рассмотрены в параграфе 2.</p><br>

<p style="text-indent:2em;">Пункт 7 содержит количество мест груза. Как правило, до 90% всех грузов, перевозимых автомобилями, находятся на
 поддонах и для перевозчика наиболее приемлемым является, если в пункте 7 будет указано количество поддонов. Это
 число легко проверить, определив количество рядов поддонов и умножив на два (в грузовом отделении в ряд становится
 два поддона).</p><br>

<p style="text-indent:2em;">В пункте 8 указывается род упаковки груза (коробки картонные, ящики деревянные, бочки металлические или пластмассовые,
 мешки холщовые или полиэтиленовые и т.д.). Наименование груза указывается в пункте 9. В пункте 10 указывается код груза
 по классификации. В пункте 11 указывается вес брутто в килограммах, т.е. вес груза с упаковкой, а в пункте 12 указывается
 объем, занимаемый грузом, в кубических метрах,</p><br>

<p style="text-indent:2em;">Пункт 13 - указания отправителя (таможенная и прочая обработка), в данном пункте содержатся реквизиты контракта купли
-продажи груза (номер и дата заключения контракта) и, если на вывоз товара потребуется лицензия или разрешение, указываются
 реквизиты этих документов.</p><br>

<p style="text-indent:2em;">На поле пункта 13 изображен конверт. Какой смысл этого изображения? Дело в том, что по одному экземпляру контракта
 купли-продажи товара, лицензии или разрешения на вывоз товара из страны должно быть предоставлено на таможне, где
 будет происходить таможенное оформление груза. Изображение конверта должно напомнить грузоотправителю и перевозчику
 , что указанные документы должны быть на таможне, и если они почему-либо не отправлены, то грузоотправитель передаст
 их в конверте через перевозчика.</p><br>

<p style="text-indent:2em;">В нижней части пункта 13 указана объявленная стоимость груза. В соответствии со статьей 23 (пункт 3) Конвенции КДПГ
 установлен предел ответственности перевозчика, ограничивающий сумму возмещения в пределах $12 за один килограмм недостающего
 веса брутто (8,33х1,46=$12,16). Однако пунктом 6 статьи 23 предусмотрено, что более значительное по своему размеру возмещение
 может быть потребовано с перевозчика в том случае, если в соответствии со статьями 24 и 26 была объявлена стоимость груза,
 В случае объявления стоимости груза, превышающей предел, указанный в пункте 3 статьи 23, объявленная стоимость заменяет этот
 предел. Конвенцией предусмотрено, что при объявлении стоимости груза, превышающей предел ответственности, перевозчику
 полагается дополнительный фрахт на оплату конвоя сопровождения.</p><br>

<p style="text-indent:2em;">В пункте 14 указывается государственный номер полуприцепа или контейнера в случае вывоза их из-за границы после
 времен&shy;ного пребывания там.</p><br>

<p style="text-indent:2em;">Например, если по каким-то обстоятельствам полуприцеп был временно оставлен за границей (ремонт в связи с ДТП или
 после диагностического контроля), то при вывозе его в страну регистра&shy;ции в пункте 14 CMR записывается
 государственный номер регистрации полуприцепа.</p><br>

<p style="text-indent:2em;">В пункте 15 указываются условия оплаты за товар по контракту купли-продажи. В этом пункте указывается международный
 термин, принятый по "Инкотермс-90".</p><br>

<p style="text-indent:2em;">Пункт 16 заполняется перевозчиком, как правило, с помощью штампа предприятия, содержащего реквизиты перевозчика
 (наименование, адрес, телефон, факс).</p><br>

<p style="text-indent:2em;">В пункте 17 заполняются реквизиты последующего перевозчика в случае осуществления перевозки несколькими
 перевозчиками.</p><br>

<p style="text-indent:2em;">По сравнению с условиями осуществления международных перевозок грузов в странах СНГ новым и обязательным предписанием
 является предоставление перевозчику права вносить в накладную оговорки, касающиеся внешнего состояния груза и его
 упаковки, Эти оговорки вносятся в пункт 18 накладной и, с целью единообразия, в виде рекомендации предлагаются
 следующие формулировки;</p>

<p style="text-indent:2em;"></p><h6>по автотранспортному средству:</h6><p></p>

<p>А) автомобиль без тента;</p><br>

<p>Б) по согласованию с отправителем;</p>

<p style="text-indent:2em;"></p><h6>по упаковке:</h6><p></p>

<p>А) груз без упаковки;</p><br>

<p>Б) упаковка повреждена;</p><br>

<p>В) упаковка не отвечает требованиям;</p>

<p style="text-indent:2em;"></p><h6>по количеству, маркировке грузовых мест:</h6><p></p>

<p style="text-indent:2em;"></p><h6>Проверить невозможно по причине:</h6><p></p>

<p>А) погрузка выполнена отправителем;</p><br>

<p>B) большого количества мест;</p><br>

<p>С) опечатан контейнер;</p>

<p style="text-indent:2em;"></p><h6>по принятым грузам:</h6><p></p>

<p>А) груз явно в плохом состоянии;</p><br>

<p>Б) груз поврежден;</p><br>

<p>В) груз подмочен;</p><br>

<p>Г) груз подморожен;</p><br>

<p>Д) груз не защищен от погодных условий и перевозится в таком состоянии под ответственность отправителя;</p>

<p style="text-indent:2em;"></p><h6>по обработке, погрузке, сохранности, разгрузке:</h6><p></p>

<p style="text-indent:2em;"></p><h6>Обработка, погрузка осуществляется:</h6><p></p>

<p>водителем при атмосферных условиях, способствующих повреждению груза;</p><br>

<p>по требованию отправителя.</p>

<p style="text-indent:2em;"></p><h6>Разгрузка осуществилась:</h6><p></p>

<p>получателем;</p><br>

<p>водителем при атмосферных условиях, способствующих повреждению груза;</p><br>

<p>по требованию получателя.</p><br>

<p style="text-indent:2em;">Если накладная не содержит специальных оговорок перевозчика, то до доказательства иного предполагается,
 что груз и его упаковка были внешне в надлежащем состоянии в момент принятия груза перевозчиком и что число
 грузовых мест, а также их маркировка и нумерация мест соответствовали указанному в накладной. В конвенции
 не указано, что оговорки перевозчика должны быть заверены грузоотправителем.</p><br>

<p style="text-indent:2em;">Оговорки перевозчика должны быть вписаны в первый экземпляр, остающийся у грузоотправителя, а так как страницы
 CMR изготовлены из самокопирующейся бумаги, то содержание оговорок отпечатается на всех страницах. Ни в коем 
 случае нельзя вписывать оговорки в оставшиеся экземпляры, если перевозчик не сделал их в первом экземпляре,
 оставшемся у грузоотправителя. В случае возникновения претензий грузоотправитель легко докажет непорядочность
 перевозчика, предъявив первый экземпляр CMR с чистым пунктом 18.</p><br>

<p style="text-indent:2em;">Пункт 19 может быть заполнен только отделом расчетов после завершения перевозки. Однако, в настоящее время
 размер фрахта устанавливается на договорных началах и поэтому, как правило, пункт 19 не заполняется.</p><br>

<p style="text-indent:2em;">Особые согласованные условия перевозки оговариваются в пункте 20 CMR. В этом пункте указывается температура
 в камере рефрижератора, при которой должен доставляться скоропортящийся груз. В этой же графе может быть указана
 согласованная с перевозчиком дата доставки груза. Перевозчик обязан знать, что в случае просрочки в доставке,
 если заявитель требования докажет, что просрочка нанесла ущерб, перевозчик обязан возместить ущерб, который не
 может превышать провозных платежей (статья 23, пункт 5).</p><br>

<p style="text-indent:2em;">В пункте 20 может быть также указано ограничение скорости движения при перевозке негабаритных, тяжеловесных и
 взрывоопасных грузов, а также запрещение перегрузки и мойки полуприцепа.</p><br>

<p>В пункте 21 указываются наименование населенного пункта, где составлялась CMR, и дата заполнения.</p><br>

<p style="text-indent:2em;">Фактическое время прибытия под погрузку и убытие из-под погрузки указываются в пункте 22 и заверяются
 штампом.</p><br>

<p style="text-indent:2em;">Номер путевого листа, фамилия водителя и штамп предприятия перевозчика указываются в пункте 23. Ставя свою
 подпись в пункте 23 накладной CMR, водитель должен знать, что он подписывает договор на перевозку, и обязан
 убедиться, что условия договора его устраивают.</p><br>

<p style="text-indent:2em;">Фактическое время и дата прибытия и убытия из-под разгрузки отмечаются в пункте 24. Отметка о получении груза
 делается на третьем и четвертом экземплярах CMR.</p><br>

<p style="text-indent:2em;">Обнаруженная при сдаче груза и при проверке количества мест и веса недостача, порча или повреждение груза
 удостоверяются соответствующими отметками во втором, третьем и четвертом экземплярах CMR. При этом
 составляется акт получения (разгрузки), в котором указывается количество недостающего (испорченного)
 груза. Акт подписывается комиссией в составе представителя таможни или эксперта торговой палаты,
 грузополучателя и перевозчика.</p><br>

<p style="text-indent:2em;">В пункте 25 отмечаются государственные номера тягача и полуприцепа, загрузившего груз, а 
в пункте 26 - их марки и модели.</p><br>

<p>Пункты 27, 28 и 29 оформляются при передаче CMR в отдел расчетов экспедитора</p><br>

<p><a href="http://6pl.ru/asmap/blankCMR.htm">Источник</a></p>

</div>
`