export const md8 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>ЗАКОН об утверждении Кодекса торгового мореплавания</h4><p></p>

<p></p><h4>КОДЕКС ТОРГОВОГО МОРЕПЛАВАНИЯ РЕСПУБЛИКИ МОЛДОВА</h4><p></p>

<p></p><h4>РАЗДЕЛ I ОБЩИЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 1. Понятие торгового мореплавания</p>

<p style="text-indent:2em;"></p><h6>Под торговым мореплаванием в настоящем кодексе понимается деятельность, связанная с использованием судов для:</h6><p></p>

<p>а) перевозки грузов, пассажиров, багажа и почты;</p><br>

<p>b) рыбного и иного морского промысла водных биологических ресурсов;</p><br>

<p>с) разведки и разработки минеральных ресурсов морского дна;</p><br>

<p>d) поисковых, спасательных и буксирных операций, лоцманской проводки;</p><br>

<p>е) подъема затонувшего имущества;</p><br>

<p>f) гидротехнических, технических, подводных и иных подобных работ;</p><br>

<p>g) других морехозяйственных целей;</p><br>

<p>h) санитарного, карантинного и другого контроля;</p><br>

<p>i) защиты и сохранения морской среды;</p><br>

<p>j) проведения морских научных исследований;</p><br>

<p>k) учебных целей;</p><br>

<p>l) спорта и отдыха.</p><br>

<p style="font-weight:500;">Статья 2. Законодательство о торговом мореплавании</p><br>

<p style="text-indent:2em;">Отношения, возникающие в области торгового мореплавания, регулируются настоящим кодексом и иными принимаемыми в соответствии с ним нормативными актами Республики Молдова.</p><br>

<p style="font-weight:500;">Статья 3. Сфера действия кодекса </p>

<p style="text-indent:2em;"></p><h6>(1) Положения настоящего кодекса распространяются на:</h6><p></p>

<p style="text-indent:2em;">а) морские суда - во время их плавания по морским и внутренним водным путям в той мере, в какой национальным законодательством или международными договорами,
 подписанными Республикой Молдова, не предусмотрено иное;</p><br>

<p style="text-indent:2em;">b) суда внутреннего плавания - во время их следования по морским и внутренним водным путям при перевозке грузов, пассажиров, багажа и почты с заходом в морские
 порты.</p><br>

<p style="text-indent:2em;">(2) Положения кодекса, за исключением предусмотренных в нем частных случаев, не распространяются на суда, плавающие под военно-морским флагом.</p><br>

<p style="text-indent:2em;">(3) К морским воинским перевозкам положения настоящего кодекса применяются в части, не предусмотренной специальным законодательством, регулирующим
 эти перевозки.</p><br>

<p style="font-weight:500;">Статья 4. Право на торговое мореплавание</p><br>

<p style="text-indent:2em;">Торговым мореплаванием могут заниматься юридические и физические лица, в чьих владении, пользовании и/или распоряжении находятся суда, при наличии в установленных
законодательством случаях лицензии на указанную деятельность, выданной компетентным органом.</p><br>

<p style="font-weight:500;">Статья 5. Государственное управление в области торгового мореплавания</p><br>

<p style="text-indent:2em;">(1) Государственное управление в области торгового мореплавания осуществляют центральный отраслевой орган и/или другие органы публичного управления в соответствии
с положениями настоящего кодекса, иных нормативных актов и международными договорами, подписанными Республикой Молдова.</p><br>

<p style="text-indent:2em;">(2) Центральный отраслевой орган выполняет свои функции во взаимодействии с другими органами публичного управления, соблюдая права субъектов предпринимательской
 деятельности, иных юридических и физических лиц.</p><br>

<p style="font-weight:500;">Статья 6. Государственный надзор за торговым мореплаванием</p>

<p style="text-indent:2em;"></p><h6>(1) В сферу государственного надзора за торговым мореплаванием, который осуществляется центральным отраслевым органом,
 входит надзор за:</h6><p></p>

<p style="text-indent:2em;">а) обеспечением безопасности мореплавания, охраны человеческой жизни на море, защиты и сохранения морской среды;</p><br>

<p>b) дипломированием специалистов морского транспорта;</p><br>

<p style="text-indent:2em;">с) государственной регистрацией морских судов, аварийно-спасательной и лоцманской службами, службой регулирования движения судов;</p><br>

<p>d) сертификацией судового оборудования;</p><br>

<p style="text-indent:2em;">е) соблюдением национального законодательства в области торгового мореплавания и требований международных договоров 
в этой области, подписанных Республикой Молдова.</p><br>

<p style="text-indent:2em;">(2) Государственный надзор за безопасностью плавания рыбопромысловых судов и выполнением требований подписанных Республикой Молдова международных договоров
 в области рыболовства возлагается на компетентный отраслевой орган.</p><br>

<p style="font-weight:500;">Статья 7. Технический надзор за судами</p><br>

<p style="text-indent:2em;">(1) Технический надзор за судами и их классификация производятся классификационными обществами, уполномоченными на это в порядке, установленном
 Правительством Республики Молдова.</p>

<p style="text-indent:2em;"></p><h6>(2) Технический надзор осуществляется за:</h6><p></p>

<p>а) пассажирскими, наливными судами, судами, предназначенными для перевозки опасных грузов, буксирами;</p><br>

<p>b) самоходными судами, не указанными в пункте а), с главными двигателями мощностью 55 кВт и более;</p><br>

<p>с) судами, не указанными в пунктах а) и b), валовой вместимостью 80 единиц и более.</p><br>

<p style="text-indent:2em;">(3) За судами, не подлежащими надзору со стороны классификационных обществ, технический надзор осуществляется в порядке, установленном Правительством
 Республики Молдова.</p><br>

<p style="text-indent:2em;">(4) Надзор за соблюдением на судах требований международных договоров, подписанных Республикой Молдова, в том числе требований, касающихся технического
 состояния судна, выполняется организациями, признанными Правительством Республики Молдова.</p><br>

<p style="font-weight:500;">Статья 8. Отвод земель и водных участков</p><br>

<p style="text-indent:2em;">(1) Отвод земель и участков водного пространства, выполнение любых работ в зоне морских путей и функционирования навигационного оборудования должны
 согласовываться с центральным отраслевым органом.</p><br>

<p style="text-indent:2em;">(2) Юридические и физические лица, нарушающие положение части (1), обязаны по требованию центрального отраслевого органа производить в указанный им
 срок за свой счет снос, перенос или реконструкцию зданий и сооружений, создающих помехи судоходству и действию навигационного оборудования.</p><br>

<p style="font-weight:500;">Статья 9. Правовое регулирование торгового мореплавания</p><br>

<p style="text-indent:2em;">(1) В соответствии с настоящим кодексом, иными нормативными актами и международными договорами, подписанными Республикой Молдова, центральный отраслевой
 орган в пределах своей компетенции разрабатывает и утверждает нормативные документы, которые относятся к торговому мореплаванию и обязательны для исполнения
 всеми юридическими и физическими лицами, участвующими в морских перевозках.</p><br>

<p style="text-indent:2em;">(2) Специальные нормативные акты, касающиеся эксплуатации рыболовных судов, разрабатываются и утверждаются компетентным отраслевым органом.</p><br>

<p style="font-weight:500;">Статья 10. Применение права в случае причинения судном вреда</p><br>

<p style="text-indent:2em;">Если судно причинило вред, не подпадающий под действие статей 302 и 320, возмещение убытков производится в соответствии с законодательством государства,
 в территориальных водах которого имело место происшествие или обстоятельство, послужившее основанием для предъявления требования о возмещении ущерба,
 если же ущерб был нанесен в открытом море, он возмещается в соответствии с законодательством государства, под флагом которого следовало судно.</p><br>

<p style="font-weight:500;">Статья 11. Применение иностранного законодательства</p><br>

<p style="text-indent:2em;">Включение в договоры, подписание которых предусмотрено настоящим кодексом, условий применения иностранного законодательства и обычаев торгового мореплавания
 допускается в случаях, когда стороны вправе отступать от требований настоящего кодекса.</p><br>

<p style="font-weight:500;">Статья 12. Приоритет международного права</p><br>

<p style="text-indent:2em;">Если в международных соглашениях, договорах, конвенциях, одной из сторон которых является Республика Молдова, содержатся нормы,
 отличающиеся от установленных настоящим кодексом, применяются нормы международных соглашений, договоров и конвенций.</p><br>

<p style="font-weight:500;">Статья 13. Рассмотрение имущественных споров</p><br>

<p style="text-indent:2em;">(1) Имущественные споры, возникающие при реализации договоров и других гражданско-правовых отношений, связанных с торговым мореплаванием,
 рассматриваются в судебных инстанциях Республики Молдова.</p><br>

<p style="text-indent:2em;">(2) Относящиеся к торговому мореплаванию имущественные споры, участниками которых являются иностранные юридические и физические лица,
 с согласия сторон могут передаваться на рассмотрение иностранным судебным инстанциям.</p><br>

<p style="font-weight:500;">Статья 14. Расчетная единица</p><br>

<p style="text-indent:2em;">(1) Расчетная единица, упоминаемая в статьях 183, 196, 313, 324 и 354, является единицей специальных прав заимствования, как она определена
 Международным валютным фондом.</p><br>

<p style="text-indent:2em;">(2) Суммы, указанные в статьях 183 и 196, переводятся в национальную валюту Республики Молдова в соответствии с курсом,
 определенным Национальным банком Молдовы на день вынесения судебного решения или на день, установленный по соглашению сторон.</p><br>

<p style="text-indent:2em;">(3) Суммы, указанные в статьях 313, 324 и 354, переводятся в национальную валюту Республики Молдова в соответствии с курсом,
 определенным Национальным банком Молдовы на день создания ограничительного фонда, если же ограничительный фонд не создается - на день осуществления платежа.</p><br>

<p style="font-weight:500;">Статья 15. Тарифы</p><br>

<p style="text-indent:2em;">Тарифы на морскую перевозку грузов, пассажиров, багажа и почты, а также правила их применения утверждаются в порядке, устанавливаемом Правительством.</p><br>

<p style="font-weight:500;">Статья 16. Коллизионные нормы</p>

<p style="text-indent:2em;"></p><h6>(1) Положения настоящего кодекса применяются следующим образом:</h6><p></p>

<p style="text-indent:2em;">а) содержащиеся в разделе II "Судно" (за исключением статьи 44) и в разделе III "Экипаж судна" - к судам, зарегистрированным в Республике Молдова;</p><br>

<p style="text-indent:2em;">b) содержащиеся в разделе IV "Морской порт" - к морским торговым портам Республики Молдова; содержащиеся в разделе IV: - в главе 3 "Лоцманская служба"
 - к отношениям, возникающим в связи с проводкой судов морскими лоцманами Республики Молдова при подходе к порту республики и в его акватории; - в главе
 6 "Затонувшее имущество" - к отношениям, возникающим в связи с имуществом, затонувшим во внутренних водах Республики Молдова;</p><br>

<p style="text-indent:2em;">с) содержащиеся в разделе IX "Чрезвычайные морские происшествия" в главе 7 "Морской протест" - в случаях, когда протест заявляется нотариусу или
 другому должностному лицу, которому законодательством Республики Молдова доверены нотариальные действия;</p><br>

<p style="text-indent:2em;">d) содержащиеся в разделе X "Ограничение ответственности судовладельца": - в главе 1 "Пределы ответственности судовладельца" - к судовладельцам,
 чьи суда плавают под флагом Республики Молдова. Пределы ответственности владельца и оператора иностранного ядерного судна определяются
 законодательством государства, под флагом которого плавает судно. Если же эти пределы ниже установленных настоящим кодексом, при рассмотрении
 спора о степени ответственности в судебной инстанции Республики Молдова применяются положения настоящего кодекса; - в главе 2 "Привилегированные требования"
 - в случаях, когда спор рассматривается в судебной инстанции Республики Молдова;</p><br>

<p style="text-indent:2em;">е) содержащиеся в разделе XI "Претензии и иски" - в случаях, когда соответствующие отношения подпадают под действие положений настоящего кодекса.</p><br>

<p style="text-indent:2em;">(2) Отношения, вытекающие из договоров о морской перевозке груза, о фрахтовании судна на время (тайм-чартер и бербоут-чартер), о лизинге судна,
 о морской буксировке, о морском страховании, о залоге (ипотеке) судна, регулируются законом, предусмотренным в соглашении сторон, а отношения,
 связанные с морской перевозкой пассажиров и круизами , - дополнительно правилами, приведенными в пассажирском билете.</p>

<p style="text-indent:2em;"></p><h6>(3) При отсутствии согласия сторон относительно применения права их отношения определяются в соответствии с законодательством государства,
 на территории которого учреждена и осуществляет основную деятельность (проживает) сторона, являющаяся:</h6><p></p>

<p>а) перевозчиком - согласно договору о морской перевозке груза или договору о круизе;</p><br>

<p>b) судовладельцем - согласно тайм-чартеру или бербоут-чартеру;</p><br>

<p>с) лизингодателем - согласно договору о лизинге судна;</p><br>

<p>d) владельцем буксирующего судна - согласно договору о морской буксировке;</p><br>

<p>е) страховщиком - согласно договору о морском страховании;</p><br>

<p>f) залогодателем - согласно договору о залоге (ипотеке) судна.</p>

<p></p><h4>РАЗДЕЛ II СУДНО</h4><p></p>

<p></p><h4>Глава 1 ОБЩИЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 17. Понятие судна</p><br>

<p style="text-indent:2em;">(1) В настоящем кодексе под судном понимается любое самоходное или несамоходное плавучее сооружение (за исключением плавающих платформ и систем сбора
 океанических данных), используемое в торговом мореплавании согласно статье 1.</p><br>

<p>(2) Положения настоящего кодекса применяются к судну с момента спуска его на воду после постройки.</p><br>

<p style="font-weight:500;">Статья 18. Понятие рыболовного судна</p><br>

<p style="text-indent:2em;">(1) Под рыболовным судном в настоящем кодексе понимается любое торговое судно, используемое для рыбного или иного морского промысла водных 
биологических ресурсов.</p><br>

<p>(2) Орудия рыбного промысла являются неотъемлемой частью рыболовного судна.</p><br>

<p style="font-weight:500;">Статья 19. Понятие ядерного судна</p><br>

<p style="text-indent:2em;">Под ядерным судном в настоящем кодексе понимается любое торговое судно, оборудованное автономной ядерной энергетической установкой.</p><br>

<p style="font-weight:500;">Статья 20. Формы собственности на судно</p><br>

<p style="text-indent:2em;">(1) В отношении судов Республики Молдова действуют все формы собственности с изъятиями, предусмотренными национальным законодательством.</p><br>

<p>(2) Ядерное судно может находиться только в государственной собственности.</p><br>

<p style="font-weight:500;">Статья 21. Момент возникновения права собствен-ности на судно</p><br>

<p style="text-indent:2em;">(1) Право собственности на судно (часть судна) возникает с момента регистрации судна в государственном судовом реестре Республики Молдова или в
 судовой книге Республики Молдова (далее - судовой реестр и судовая книга).</p><br>

<p style="text-indent:2em;">(2) Право собственности на строящееся судно возникает с момента регистрации этого судна в реестре строящихся судов.</p><br>

<p style="font-weight:500;">Статья 22. Понятия собственника судна и судовладельца</p><br>

<p style="text-indent:2em;">(1) Под собственником судна в настоящем кодексе понимается субъект права собственности или лицо, осуществляющее право собственности на
 судно в соответствии с законодательством о собственности.</p><br>

<p style="text-indent:2em;">(2) Под судовладельцем в настоящем кодексе понимается юридическое или физическое лицо, эксплуатирующее судно, независимо от того, является
 это лицо собственником судна или использует его на ином законном основании.</p><br>

<p style="font-weight:500;">Статья 23. Судебный иммунитет государственных судов Республики Молдова</p><br>

<p style="text-indent:2em;">На суда, находящиеся в государственной собственности Республики Молдова, не может быть наложен арест или обращено взыскание без согласия
 Правительства, если эти суда используются исключительно для несения государственной некоммерческой службы.</p><br>

<p style="font-weight:500;">Статья 24. Право собственности и другие реальные права на судно</p><br>

<p style="text-indent:2em;">(1) Право собственности и другие реальные права на судно, а также возникновение, изменение и прекращение этих прав определяются законодательством
 государства, под флагом которого плавает судно.</p><br>

<p style="text-indent:2em;">(2) Права на строящееся судно определяются законодательством государства, где судно принято к постройке или строится,
 если договором о постройке судна не предусмотрено иное.</p><br>

<p style="font-weight:500;">Статья 25. Права собственника судна</p><br>

<p style="text-indent:2em;">(1) Собственник вправе совершать в отношении своего судна любые действия, не противоречащие законодательству,
 в том числе передать свои права на владение, пользование и распоряжение судном другим лицам, передать судно в управление,
 продать судно или распорядиться им иным образом.</p><br>

<p style="text-indent:2em;">(2) Продажа другому государству, иностранному юридическому или физическому лицу судна, находящегося в государственной собственности,
 разрешается в порядке, установленном законодательством Республики Молдова.</p><br>

<p style="font-weight:500;">Статья 26. Идентификация судна</p><br>

<p>(1) Судно должно иметь название, которое присваивается ему собственником.</p><br>

<p style="text-indent:2em;">(2) Все суда, оснащенные аппаратурой связи, имеют позывной сигнал, а также в зависимости от уровня технической оснащенности 
- идентификационный номер станции спутниковой связи и/или номер избирательного позывного сигнала судовой радиостанции.</p><br>

<p style="text-indent:2em;">(3) Порядок присвоения судну названия, позывного сигнала, идентификационного номера станции спутниковой связи и номера
 избирательного позывного сигнала судовой радиостанции устанавливается центральным отраслевым органом.</p><br>

<p style="font-weight:500;">Статья 27. Выдача разрешения на плавание</p><br>

<p style="text-indent:2em;">Разрешение на плавание может быть выдано лишь после того, как будет установлено, что судно удовлетворяет тре-бованиям безопасности мореплавания,
 охраны человеческой жизни и окружающей среды, предусмотренным национальным законодательством и международными договорами, подписанными Республикой Молдова.</p><br>

<p style="font-weight:500;">Статья 28. Требования к судам внутреннего плавания, выходящим в море</p><br>

<p style="text-indent:2em;">Требования, которым должны отвечать суда внутреннего плавания Республики Молдова, выходящие в море,
 а также границы зон морского плавания для этих судов устанавливаются центральным отраслевым органом.</p><br>

<p style="font-weight:500;">Статья 29. Заход ядерного судна во внутренние воды Республики Молдова</p><br>

<p>Ядерное судно может зайти во внутренние воды Республики Молдова только с разрешения ее Правительства.</p>

<p></p><h4>Глава 2 ПРАВО ПЛАВАНИЯ ПОД ФЛАГОМ РЕСПУБЛИКИ МОЛДОВА. РЕГИСТРАЦИЯ СУДОВ</h4><p></p>

<p style="font-weight:500;">Статья 30. Право плавания под флагом Республики Молдова</p>

<p style="text-indent:2em;"></p><h6>Право плавания под флагом Республики Молдова предоставляется судам, зарегистрированным в судовом реестре и:</h6><p></p>

<p>a) находящимся в собственности у физических и юридических лиц Республики Молдова;</p><br>

<p>b) находящимся в собственности иностранных физических и юридических лиц;</p><br>

<p style="text-indent:2em;">c) эксплуатируемым на основании бербоут-чартера или договора о лизинге. [Ст.30 в редакции ZPC334/24.07.03, MO170/08.08.3 ст.713]</p><br>

<p style="font-weight:500;">Статья 30. Право плавания под флагом Республики Молдова</p>

<p style="text-indent:2em;"></p><h6>Право плавания под флагом Республики Молдова предоставляется судам резидентов Республики Молдова:</h6><p></p>

<p>а) находящимся в собственности у юридических и физических лиц;</p><br>

<p>b) эксплуатируемым на основании бербоут-чартера или договора о лизинге.</p><br>

<p style="font-weight:500;">Статья 31. Возникновение права плавания под флагом Республики Молдова</p><br>

<p style="text-indent:2em;">(1) Судно получает право плавания под флагом Республики Молдова с момента его регистрации в судовом реестре или в судовой книге. [Ст.31 ч.(2) и (3)
 введены ZPC334/24.07.03, MO170/08.08.03 ст.713, ч.(2)-(4) считать (4)-(6)]</p><br>

<p style="text-indent:2em;">(2) Судовладельцы, собственники судов и фрахтователи, зарегистрировавшие свои суда в судовом реестре, освобождаются от уплаты налогов
 и таможенной пошлины в соответствии с действующим законодательством.</p><br>

<p style="text-indent:2em;">(3) Ответственность за нарушение законодательных актов, регламентирующих международное судоходство, несут судовладельцы, собственники судов и фрахтователи,
 зарегистрировавшие свои суда в судовом реестре, в соответствии с действующим законодательством</p><br>

<p style="text-indent:2em;">(4) Приобретенное или построенное в другом государстве судно получает право плавания под флагом Республики Молдова с момента выдачи консулом
 Республики Молдова временного свидетельства, удостоверяющего это право. Такое свидетельство действительно до регистрации судна в судовом реестре
 или в судовой книге, но не более одного года.</p><br>

<p style="text-indent:2em;">(5) Судно, которое пользуется правом плавания под флагом Республики Молдова, имеет национальность государства и обязано плавать
 под национальным флагом Республики Молдова .</p><br>

<p style="text-indent:2em;">(6) Подъем на судне флага Республики Молдова при отсутствии права на это влечет за собой ответственность, предусмотренную законодательством.</p><br>

<p style="font-weight:500;">Статья 32. Государственная регистрация судов</p><br>

<p style="text-indent:2em;">(1) Суда Республики Молдова, технический надзор за которыми в соответствии со статьей 7 осуществляют классификационные общества,
 регистрируются в судовом реестре.</p><br>

<p>(2) Не подлежащие регистрации в судовом реестре суда Республики Молдова регистрируются в судовой книге.</p>

<p style="text-indent:2em;"></p><h6>(3) По заявлению фрахтователя судно, зафрахтованное по бербоут-чартеру, может быть зарегистрировано временно (на срок, не превышающий
 срока действия бербоут-чартера) в судовом реестре или в судовой книге, если на момент фрахтования оно:</h6><p></p>

<p>а) не внесено в судовой реестр другого государства или</p><br>

<p style="text-indent:2em;">b) внесено в судовой реестр другого государства, но право плавания под флагом этого государства приостановлено,
 что подтверждается судовладельцем, представляющим соотве тствующий сертификат.</p><br>

<p>(4) Порядок ведения судового реестра и судовой книги устанавливается центральным отраслевым органом.</p><br>

<p style="font-weight:500;">Статья 33. Условия регистрации</p><br>

<p style="text-indent:2em;">(1) Судно должно быть зарегистрировано в судовом реестре или в судовой книге только в одном морском порту
 Республики Молдова.</p><br>

<p style="text-indent:2em;">(2) По желанию собственника судна порт, в котором судно должно быть зарегистрировано, может быть изменен с соблюдением
 требования, изложенного в части (1).</p><br>

<p style="font-weight:500;">Статья 34. Судовой патент, судовой билет</p><br>

<p style="text-indent:2em;">(1) Регистрация судна в судовом реестре удостоверяется судовым патентом - свидетельством о праве плавания под флагом Республики Молдова,
 а регистрация в судовой книге - судовым билетом.</p><br>

<p style="text-indent:2em;">(2) За регистрацию судна в судовом реестре или в судовой книге, а также за внесение в них любых изменений взимается установленный сбор.</p><br>

<p style="font-weight:500;">Статья 35. Исключение из судовых реестров</p>

<p style="text-indent:2em;"></p><h6>(1) Судно исключается из судового реестра или судовой книги, если оно:</h6><p></p>

<p>а) признано непригодным к дальнейшей эксплуатации и не подлежит ремонту;</p><br>

<p>b) утратило право плавания под флагом Республики Молдова;</p><br>

<p>с) погибло или пропало без вести.</p><br>

<p style="text-indent:2em;">(2) Республикой Молдова не признается запись о регистрации ее судна в судовом реестре другого государства, если это судно не было
 исключено в установленном порядке из судового реестра или судовой книги.</p><br>

<p style="text-indent:2em;">(3) Судно, зарегистрированное в реестре судов иностранного государства, может быть зарегистрировано в судовом реестре или в судовой
 книге после исключения из реестра судов иностранного государства и представления удостоверяющего это свидетельства.</p><br>

<p style="font-weight:500;">Статья 36. Временная утрата права плавания под флагом Республики Молдова</p>

<p style="text-indent:2em;"></p><h6>В случае передачи собственником на основании бербоут-чартера судна Республики Молдова юридическому или физическому лицу другого
 государства это судно может временно утратить право плавания под флагом Республики Молдова, если:</h6><p></p>

<p>а) законодательство другого государства не запрещает смену флага;</p><br>

<p>b) собственник согласен на временный перевод судна под флаг другого государства;</p><br>

<p style="text-indent:2em;">с) залогодержатели зарегистрированных морских залогов (ипотек) судна согласны на его временный перевод под флаг другого государства.</p><br>

<p style="font-weight:500;">Статья 37. Информирование об изменении сведений, внесенных в судовой реестр или в судовую книгу</p><br>

<p style="text-indent:2em;">В случае любых изменений сведений о судне, внесенных в судовой реестр или в судовую книгу, собственник либо фрахтователь судна по
 бербоут-чартеру в течение двух недель должен сообщить об этих изменениях органу, которым судно было зарегистрировано.</p><br>

<p style="font-weight:500;">Статья 38. Ответственность за нарушение правил регистрации судна</p><br>

<p style="text-indent:2em;">Уклонение от регистрации судна, регистрация его обманным путем либо нарушение требования статьи 37 влекут за собой ответственность,
 предусмотренную законодательством.</p>

<p></p><h4>Глава 3 СУДОВЫЕ ДОКУМЕНТЫ</h4><p></p>

<p style="font-weight:500;">Статья 39. Основные судовые документы</p>

<p style="text-indent:2em;"></p><h6>(1) На борту судна должны быть следующие основные судовые документы:</h6><p></p>

<p>а) судовой патент;</p><br>

<p>b) свидетельство о праве собственности на судно;</p><br>

<p>с) свидетельство о годности к плаванию;</p><br>

<p>d) классификационное свидетельство;</p><br>

<p style="text-indent:2em;">е) мерительное свидетельство (для судов, подлежащих техническому надзору со стороны классификационных обществ);</p><br>

<p>f) свидетельство о грузовой марке (о наименьшей высоте надводного борта);</p><br>

<p>g) свидетельство о минимальном составе экипажа;</p><br>

<p>h) судовая роль;</p><br>

<p>i) список пассажиров, находящихся на судне;</p><br>

<p>j) пассажирское свидетельство, если судно перевозит более 12 пассажиров;</p><br>

<p>k) судовой журнал;</p><br>

<p>l) машинный журнал;</p><br>

<p>m) санитарный журнал;</p><br>

<p>n) судовое санитарное свидетельство;</p><br>

<p>о) лицензия на право пользования судовой радиостанцией, журнал радиосвязи (для судов, имеющих радиостанцию);</p><br>

<p>p) журнал регистрации операций по предотвращению загрязнения моря.</p><br>

<p style="text-indent:2em;">(2) Судовая роль, список пассажиров, находящихся на судне, судовой, машинный, санитарный журналы, журнал радиосвязи соответственно составляются
 и ведутся по форме и согласно правилам, устанавливаемым центральным отраслевым органом. При необходимости форма и правила составления
 и ведения указанных документов согласуются с заинтересованными органами.</p><br>

<p style="text-indent:2em;">(3) На судах, зарегистрированных в судовой книге, вместо документов, указанных в пунктах а) и b) части (1), должен быть судовой билет.</p><br>

<p style="text-indent:2em;">(4) На судах, выходящих в заграничное плавание, должны быть документы, предусмотренные международными договорами, одной из сторон которых
 является Республика Молдова.</p><br>

<p style="font-weight:500;">Статья 40. Документы рыболовных судов</p>

<p style="text-indent:2em;"></p><h6>На борту рыболовных судов кроме документов, перечисленных в статье 39, должно быть международное свидетельство о безопасности рыболовного судна.
 Однако это правило не относится к судам, используемым для:</h6><p></p>

<p>а) обработки и перевозки рыбы, других биологических ресурсов моря;</p><br>

<p>b) проведения научных исследований и обучения;</p><br>

<p>с) спорта и отдыха.</p><br>

<p style="font-weight:500;">Статья 41. Исключения по судовым документам</p><br>

<p style="text-indent:2em;">(1) Для судов, несущих специальную государственную службу, а также спортивных судов необязательно наличие мерительного свидетельства.
 Вместимость судна, несущего специальную государственную службу, может быть определена упрощенным способом с выдачей соответствующего удостоверения.</p><br>

<p style="text-indent:2em;">(2) С разрешения классификационных обществ на судах отдельных категорий может не быть мерительного свидетельства или свидетельства о грузовой марке.</p><br>

<p style="text-indent:2em;">(3) На судах, плавающих в портовых и прибрежных водах, могут не вестись машинный и санитарный журналы, если органом надзора за безопасностью мореплавания не предусмотрено иное.</p><br>

<p style="font-weight:500;">Статья 42. Выдача судовых документов</p><br>

<p style="text-indent:2em;">(1) Судовой патент и свидетельство о праве собственности на судно выдаются капитаном порта, в котором судно зарегистрировано в судовом реестре.
 Судовой билет выдается органом, зарегистрировавшим судно в судовой книге.</p><br>

<p style="text-indent:2em;">(2) Свидетельство о годности к плаванию, мерительное свидетельство, свидетельство о грузовой марке, пассажирское свидетельство, а также другие
 судовые документы, предусмотренные международными договорами по вопросам безопасности мореплавания, подписанными Республикой Молдова,
 выдаются классификационными обществами.</p><br>

<p>(3) Лицензия на право пользования судовой радиостанцией выдается центральным отраслевым органом.</p><br>

<p style="text-indent:2em;">(4) Порядок взимания сборов за выдачу судовых документов, указанных в статьях 39 и 40, устанавливается Правительством.</p><br>

<p style="font-weight:500;">Статья 43. Подлинность судовых документов</p><br>

<p style="text-indent:2em;">Документы, указанные в статье 39, должны храниться на судне в подлиннике, за исключением свидетельства о праве собственности на судно и судового билета ,
 копии которых должны быть удостоверены нотариально.</p><br>

<p style="font-weight:500;">Статья 44. Признание документов иностранных судов</p><br>

<p style="text-indent:2em;">(1) Признание свидетельства о годности к плаванию, мерительного свидетельства, свидетельства о грузовой марке, пассажирского свидетельства,
 лицензии на право пользования судовой радиостанцией, имеющихся на иностранных судах, заходящих в порты Республики Молдова, осуществляется в
 соответствии с международными договорами, одной из сторон которых является Республика Молдова.</p><br>

<p style="text-indent:2em;">(2) Иностранные суда, на борту которых нет документов, предусмотренных частью (1), при входе в порты Республики Молдова подвергаются обязательному
 освидетельствованию так же, как и суда, плавающие под флагом Республики Молдова.</p><br>

<p style="text-indent:2em;">(3) При входе в порт Республики Молдова любое иностранное судно, независимо от наличия соответствующих документов, в случае сомнения в том,
 что оно удовлетворяет требованиям безопасности мореплавания, может быть подвергнуто проверке в порядке технического надзора так же, как и
 суда, плавающие под флагом Республики Молдова.</p>

<p></p><h4>Глава 4 АРЕСТ СУДНА</h4><p></p>

<p style="font-weight:500;">Статья 45. Полномочия на арест</p><br>

<p style="text-indent:2em;">(1) В настоящем кодексе под арестом судна понимается любое задержание или ограничение в плавании судна, осуществляемое во время его
 нахождения в морском порту Республики Молдова, в целях обеспечения морских требований согласно статье 46. Арест судна не предусматривает
 применения мер по выполнению вступившего в силу судебного решения.</p><br>

<p>(2) Судно может быть арестовано только по морскому требованию.</p><br>

<p style="text-indent:2em;">(3) Арест судна и освобождение судна от ареста производятся на основании решения судебной инстанции Республики Молдова.</p>

<p style="text-indent:2em;"></p><h6>(4) Право судебной инстанции налагать арест на судно в соответствии с частью (3) не затрагивает права начальника и капитана морского
 порта на задержание судна в порядке, установленном статьями 84-86 и 95. Статья 46. Морское требование Морское требование означает любое
 требование, вытекающее из права собственности, других реальных прав на судно и предъявленное в связи с:</h6><p></p>

<p>а) причинением ущерба в результате утраты или повреждения имущества в период эксплуатации судна;</p><br>

<p style="text-indent:2em;">b) утратой жизни или нанесением вреда здоровью людей на море или на суше, непосредственно обусловленными эксплуатацией судна;</p><br>

<p>с) вознаграждением за проведение спасательных операций или договором о спасании;</p><br>

<p style="text-indent:2em;">d) предоставлением компенсации и выплатой иных сумм, причитающихся за устранение или попытку устранить угрозу причинения ущерба,
 принятие предупредительных мер;</p><br>

<p style="text-indent:2em;">е) подъемом на сушу, удалением или уничтожением ставшего обломками судна либо находившегося на его борту груза, а также вызванными
 этим расходами;</p><br>

<p>f) любым договором об использовании или фрахтовании судна;</p><br>

<p>g) любым договором о перевозке пассажиров или грузов;</p><br>

<p>h) утратой или повреждением груза, багажа во время либо вследствие перевозки;</p><br>

<p>i) общей аварией;</p><br>

<p>j) лоцманской проводкой судна;</p><br>

<p>k) буксировкой судна;</p><br>

<p style="text-indent:2em;">l) предоставлением для эксплуатации судна и его содержания продуктов питания, судовых материалов и запасов топлива, оборудования,
 контейнеров;</p><br>

<p>m) постройкой, ремонтом, реконструкцией или переоборудованием судна;</p><br>

<p>n) портовыми, канальными, иными сборами, уплачиваемыми на судоходных путях;</p><br>

<p style="text-indent:2em;">о) заработной платой, иными выплатами, причитающимися капитану, другим членам экипажа за исполнение ими служебных обязанностей на борту судна,
 расходами по репатриации и взносами социального страхования, уплачиваемыми от имени указанных лиц;</p><br>

<p>p) дисбурсментскими расходами;</p><br>

<p style="text-indent:2em;">q) выплатой страховой премии, включая взносы по взаимному страхованию, собственником судна или фрахтователем по бербоут-чартеру;</p><br>

<p style="text-indent:2em;">r) выплатой любого комиссионного, брокерского или агентского вознаграждения собственником судна или фрахтователем по бербоут-чартеру;</p><br>

<p>s) любым спором о праве собственности на судно или владения им;</p><br>

<p style="text-indent:2em;">t) любым спором между двумя или несколькими собственниками судна относительно его использования и распределения прибыли;</p><br>

<p>u) морским залогом (ипотекой) судна;</p><br>

<p>v) любым спором, обусловленным договором купли-продажи судна.</p><br>

<p style="font-weight:500;">Статья 47. Основания для ареста судна</p>

<p style="text-indent:2em;"></p><h6>(1) Судно, в отношении которого возникло морское требование, может быть арестовано только в случае, если такое требование:</h6><p></p>

<p>а) относится к числу привилегированных, указанных в пунктах а), с), d), е) или g) статьи 360;</p><br>

<p>b) основывается на зарегистрированном морском залоге (ипотеке) судна;</p><br>

<p>с) касается права собственности на судно или владения им;</p><br>

<p style="text-indent:2em;">d) не подпадает под действие пунктов а), b) или с) настоящей части, однако: - лицо, которому судно принадлежит на праве собственности на момент
 возникновения требования, несет ответственность по этому требованию и остается собственником судна при наложении ареста, или - фрахтователь по
 бербоут-чартеру несет ответственность по этому требованию и на момент начала процедуры, связанной с арестом, остается фрахтователем либо
 становится собственником судна.</p>

<p style="text-indent:2em;"></p><h6>(2) Любое другое судно или другие суда могут быть также арестованы, если на момент начала процедуры, связанной с их арестом, судно или суда находятся
 в собственности лица, которое несет ответственность по морскому требованию и которое на момент возникновения требования являлось:</h6><p></p>

<p>а) собственником судна, в отношении которого возникло морское требование, или</p><br>

<p>b) фрахтователем такого судна по бербоут-чартеру, тайм-чартеру или рейсовому чартеру.</p><br>

<p style="text-indent:2em;">(3) Правило, установленное частью (2), не применяется в отношении требования, касающегося права собственности на судно или владения им.</p><br>

<p style="font-weight:500;">Статья 48. Освобождение судна от ареста</p><br>

<p style="text-indent:2em;">(1) Судно освобождается от ареста по решению судебной инстанции при предоставлении достаточного обеспечения в приемлемой форме.</p><br>

<p style="text-indent:2em;">(2) При отсутствии согласия сторон форма и размер обеспечения устанавливаются судебной инстанцией. Размер обеспечения не должен превышать стоимости судна.</p><br>

<p style="text-indent:2em;">(3) Любая просьба об освобождении судна от ареста в связи с предоставлением обеспечения не означает признания ответственности,
 отказа от средств защиты или от права на ограничение ответственности.</p><br>

<p style="text-indent:2em;">(4) Лицо, предоставившее обеспечение в соответствии с частью (1), может в любое время обратиться в судебную инстанцию с заявлением об уменьшении размера,
 изменении формы или отмене обеспечения.</p><br>

<p style="font-weight:500;">Статья 49. Последующий арест</p>

<p style="text-indent:2em;"></p><h6>(1) Судно, которое освобождено от ареста или для предотвращения ареста которого предоставлено обеспечение, может быть арестовано
 вновь или по тому же морскому требованию, если:</h6><p></p>

<p style="text-indent:2em;">а) размер предоставленного обеспечения недостаточен при условии, что общий размер обеспечения не может превышать стоимости судна;</p><br>

<p style="text-indent:2em;">b) лицо, которое предоставило обеспечение, не в состоянии выполнить свои обязательства полностью либо частично;</p><br>

<p style="text-indent:2em;">с) судно освобождено от ареста или обеспечение, которое предоставлено, отменено: - по просьбе либо с согласия лица, заявившего требование
, при наличии к этому оснований; - поскольку заявитель не смог воспрепятствовать освобождению судна от ареста посредством принятия необходимых мер.</p>

<p style="text-indent:2em;"></p><h6>(2) Если судно освобождено от ареста или для предотвращения его ареста предоставлено обеспечение, любое другое судно,
 которое может быть арестовано на основании того же морского требования, подлежит аресту, если:</h6><p></p>

<p>а) размер полученного обеспечения по этому требованию недостаточен;</p><br>

<p>b) применяются правила, установленные пунктами b) и с) части (1).</p><br>

<p>(3) Положения настоящей статьи не применяются к незаконно освобожденному от ареста судну.</p><br>

<p style="font-weight:500;">Статья 50. Защита интересов собственника или фрахтователя арестованного судна</p><br>

<p style="text-indent:2em;">(1) Лицо, по требованию которого судно было арестовано, несет ответственность за все убытки, причиненные собственнику или фрахтователю судна
 по бербоут-чартеру в результате необоснованного ареста судна или предоставления чрезмерного обеспечения.</p><br>

<p style="text-indent:2em;">(2) Судебная инстанция может обязать лицо, заявившее требование о наложении ареста на судно или продлении срока уже наложенного ареста,
 предоставить обеспечение в размере и на условиях, определенных ею для любых убытков, которые могут быть причинены собственнику или фрахтователю
 судна по бербоут-чартеру вследствие ареста судна или предоставления чрезмерного обеспечения и за которые указанное лицо подлежит привлечению
 к ответственности.</p><br>

<p style="font-weight:500;">Статья 51. Государственные суда</p><br>

<p style="text-indent:2em;">Положения настоящей главы применяются также к судам, которые находятся в собственности государства и эксплуатируются им в коммерческих целях.</p>

<p></p><h4>РАЗДЕЛ III ЭКИПАЖ СУДНА</h4><p></p>

<p></p><h4>Глава 1 ОБЩИЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 52. Состав экипажа</p><br>

<p>(1) Экипаж судна включает командный состав и судовую команду.</p><br>

<p style="text-indent:2em;">(2) К командному составу относятся капитан, его помощники, судовые механики, электромеханики, радиоспециалисты, судовой врач.
 Судовладелец может ввести в командный состав и других специалистов.</p><br>

<p>(3) Судовая команда состоит из служащих на судне лиц, которые не относятся к командному составу.</p><br>

<p style="font-weight:500;">Статья 53. Минимальный состав экипажа</p><br>

<p style="text-indent:2em;">Минимальный состав экипажа судна, при котором разрешается выход в море, устанавливается центральным отраслевым органом в зависимости
 от типа, назначения судна и района плавания в соответствии с международными договорами, одной из сторон которых является Республика Молдова.</p><br>

<p style="font-weight:500;">Статья 54. Правовое положение экипажа</p><br>

<p style="text-indent:2em;">(1) Правовое положение экипажа судна, в том числе отношения между членами экипажа, связанные с эксплуатацией судна, определяются
 законодательством государства, под флагом которого плавает судно.</p><br>

<p style="text-indent:2em;">(2) Отношения между членами экипажа и судовладельцем регулируются законодательством государства, под флагом которого плавает судно,
 если договором, регламентирующим эти отношения, не предусмотрено иное.</p><br>

<p style="font-weight:500;">Статья 55. Требования к квалификации членов экипажа</p><br>

<p style="text-indent:2em;">(1) К занятию должностей на судне допускаются лица, имеющие соответствующие дипломы (квалификационные свидетельства) или подтверждения
 к ним, предусматриваемые положением о дипломировании членов экипажей морских судов, которое утверждается Правительством. Диплом
 (квалификационное свидетельство) выдается после прохождения аттестации в квалификационной комиссии.</p><br>

<p style="text-indent:2em;">(2) Диплом (квалификационное свидетельство) может быть изъят (изъято) на основании решения судебной инстанции.</p><br>

<p style="font-weight:500;">Статья 56. Требования к состоянию здоровья членов экипажа</p><br>

<p style="text-indent:2em;">К работе на судне допускаются лица, имеющие свидетельство о состоянии здоровья, удостоверяющее их пригодность к такой работе.</p><br>

<p style="font-weight:500;">Статья 57. Гражданство членов экипажа</p><br>

<p style="text-indent:2em;">(1) Членами экипажа судна, плавающего под флагом Республики Молдова, могут быть граждане любого государства.</p><br>

<p style="text-indent:2em;">(2) Капитаном судна, находящегося в государственной собственности Республики Молдова, должен быть гражданин Республики Молдова.</p><br>

<p style="font-weight:500;">Статья 58. Трудовые отношения на судне</p><br>

<p style="text-indent:2em;">(1) Порядок приема на работу и увольнения членов судового экипажа, их права и обязанности, условия и оплата труда на судне определяются
 законодательством Республики Молдова, включая настоящий кодекс, правилами службы на морских судах, коллективными и индивидуальными
 трудовыми договорами (контрактами).</p><br>

<p>(2) Правила службы на морских судах утверждаются центральным отраслевым органом.</p><br>

<p style="font-weight:500;">Статья 59. Обязанности судовладельца</p>

<p style="text-indent:2em;"></p><h6>(1) Судовладелец обеспечивает:</h6><p></p>

<p>а) безопасные условия труда на судне;</p><br>

<p>b) охрану здоровья членов экипажа;</p><br>

<p>с) снабжение продовольствием и питьевой водой;</p><br>

<p>d) наличие на судне спасательных средств;</p><br>

<p>е) наличие соответствующих служебных, жилищно-бытовых и иных помещений.</p><br>

<p style="text-indent:2em;">(2) Минимальные нормы предусмотренного в части (1) обеспечения не должны быть ниже требований стандартов, установленных международными договорами,
 одной из сторон которых является Республика Молдова.</p><br>

<p style="font-weight:500;">Статья 60. Репатриация членов экипажа</p>

<p style="text-indent:2em;"></p><h6>(1) Члены экипажа судна имеют право на репатриацию в случае:</h6><p></p>

<p>а) истечения срока действия трудового договора;</p><br>

<p>b) расторжения трудового договора по инициативе судовладельца;</p><br>

<p>с) отсутствия у судовладельца возможности выполнять договорные обязательства;</p><br>

<p>d) заболевания или получения травмы, требующих лечения вне судна;</p><br>

<p>е) кораблекрушения.</p><br>

<p style="text-indent:2em;">(2) Член экипажа репатриируется в порт, указанный в коллективном и индивидуальном трудовых договорах, а при отсутствии такого указания
 - в порт, где он был принят на работу.</p><br>

<p style="text-indent:2em;">(3) Судовладелец обязан организовать репатриацию члена экипажа и несет связанные с этим расходы - на выплату зарплаты и пособий, проезд,
 проживание, питание, провоз багажа, лечение моряка (до его транспортабельности).</p><br>

<p style="text-indent:2em;">(4) Если судовладелец не в состоянии организовать репатриацию члена экипажа и оплатить соответствующие расходы, его обязанности берет на
 себя Правительство. В дальнейшем понесенные затраты возмещаются судовладельцем.</p><br>

<p style="font-weight:500;">Статья 61. Личное имущество членов экипажа</p><br>

<p>(1) Члены экипажа вправе провозить на судне имущество личного пользования.</p><br>

<p style="text-indent:2em;">(2) В случае гибели или повреждения личного имущества членов экипажа вследствие морского происшествия судовладелец обязан возместить
 причиненный ущерб, исходя из действующих цен на предметы такого же рода и качества. Виновным в происшествии членам экипажа понесенный
 ущерб не возмещается.</p><br>

<p style="text-indent:2em;">(3) Провоз на судне имущества, не предназначенного для личного пользования, без разрешения судовладельца запрещается. Член экипажа,
 провозящий такое имущество, обязан возместить судовладельцу причиненный в связи с этим ущерб.</p>

<p></p><h4>Глава 2 КАПИТАН СУДНА</h4><p></p>

<p style="font-weight:500;">Статья 62. Обязанности капитана</p><br>

<p style="text-indent:2em;">(1) На капитана возлагаются: командование, управление судном во время плавания, принятие необходимых мер для обеспечения безопасности
 плавания, предотвращения загрязнения морской среды, для поддержания порядка на судне, предупреждения любого рода ущерба, который может
 быть причинен судну, находящимся на нем людям и грузу.</p><br>

<p style="text-indent:2em;">(2) При совершении сделок, связанных с нуждами судна, грузом или обеспечением плавания, а также при предъявлении исков, касающихся
 вверенного ему имущества, капитан в силу своего служебного положения считается представителем судовладельца и грузовладельцев, если
 на месте нет других их представителей.</p><br>

<p style="text-indent:2em;">(3) В случае морского происшествия, повлекшего за собой порчу, повреждение или утрату перевозимого груза либо багажа, получение травм
 людьми, в других случаях, вследствие которых судовладельцу могут быть предъявлены претензии, капитан обязан обеспечить документальное оформление
 происшествия в установленном порядке, а также произвести предварительное расследование.</p><br>

<p style="font-weight:500;">Статья 63. Обязанности по оказанию помощи терпящим кораблекрушение</p>

<p style="text-indent:2em;"></p><h6>(1) Капитан обязан, если это не представляет серьезного риска для судна, экипажа и пассажиров:</h6><p></p>

<p>а) оказывать помощь любому обнаруженному в море лицу, которому грозит гибель;</p><br>

<p style="text-indent:2em;">b) получив сигнал бедствия, направлять судно с максимальной скоростью к месту происшествия для оказания помощи находящимся в опасности людям.</p><br>

<p style="text-indent:2em;">(2) За неисполнение указанных в части (1) обязанностей капитан несет ответственность, предусмотренную законодательством.</p><br>

<p>(3) Судовладелец не несет ответственности за неисполнение капитаном обязанностей, указанных в части (1).</p><br>

<p style="font-weight:500;">Статья 64. Обязанности по оказанию помощи при столкновении судов</p><br>

<p style="text-indent:2em;">(1) Каждый из капитанов столкнувшихся судов обязан, если это можно сделать без серьезного риска для пассажиров, экипажа и судна,
 оказать помощь другому судну, его пассажирам и экипажу.</p><br>

<p style="text-indent:2em;">(2) Капитаны столкнувшихся судов обязаны по возможности сообщить друг другу название своего судна, названия порта его приписки,
 порта отправления и порта назначения либо ближайшего порта, в который зайдет судно.</p><br>

<p style="text-indent:2em;">(3) За неисполнение указанных в частях (1) и (2) обязанностей капитан несет ответственность, предусмотренную законодательством.</p><br>

<p style="text-indent:2em;">(4) Судовладелец не несет ответственности за неисполнение капитаном обязанностей, указанных в частях (1) и (2).</p><br>

<p style="font-weight:500;">Статья 65. Обязанности при необходимости в оказании неотложной медицинской помощи</p><br>

<p style="text-indent:2em;">Если кто-либо из членов экипажа или пассажиров нуждается в неотложной медицинской помощи, которая не может быть оказана при нахождении
 судна в море, капитан обязан привести судно в ближайший порт, известив об этом судовладельца.</p><br>

<p style="font-weight:500;">Статья 66. Обязанности капитана в случае опасности захвата судна</p><br>

<p style="text-indent:2em;">В случае возникновения военной опасности, пиратских действий капитан обязан принять все меры для спасения людей и недопущения захвата
 судна, находящихся на нем документов, грузов и другого имущества.</p><br>

<p style="font-weight:500;">Статья 67. Право капитана на ношение огнестрельного оружия</p><br>

<p style="text-indent:2em;">Капитан судна имеет право на ношение, хранение и применение огнестрельного табельного оружия для обеспечения личной безопасности
 и безопасности находящихся на судне людей и имущества.</p><br>

<p style="font-weight:500;">Статья 68. Действия капитана в случае опасности гибели судна</p><br>

<p style="text-indent:2em;">(1) Если, по мнению капитана, судну грозит неминуемая гибель, после принятия всех мер, направленных на спасение пассажиров,
 он разрешает экипажу покинуть судно. Капитан оставляет судно последним, сделав все зависящее от него для спасения судового,
 машинного журналов, журнала радиосвязи, карт рейса, документов, ценностей и кассовой наличности.</p><br>

<p style="text-indent:2em;">(2) В случае гибели судна капитан сохраняет права и обязанности в отношении экипажа, куда бы он ни был доставлен.</p><br>

<p style="font-weight:500;">Статья 69. Поддержание порядка на судне</p><br>

<p style="text-indent:2em;">(1) Распоряжения капитана в пределах его компетенции подлежат исполнению всеми находящимися на судне лицами.
 В случае неисполнения распоряжений капитана он вправе принять необходимые меры.</p><br>

<p style="text-indent:2em;">(2) Капитан имеет право применять меры поощрения и налагать дисциплинарные взыскания на членов экипажа, вплоть до
 отстранения от исполнения служебных обязанностей, в случаях и в порядке, предусмотренных законодательством.</p><br>

<p style="text-indent:2em;">(3) Капитан вправе изолировать лицо, действия которого хотя и не содержат признаков уголовно наказуемого деяния, но
 угрожают безопасности судна или находящихся на нем людей и имущества.</p><br>

<p style="text-indent:2em;">(4) В случае незаконной изоляции кого-либо из находящихся на судне лиц или иного превышения полномочий по поддержанию
 порядка капитан несет ответственность, установленную законодательством.</p><br>
 
<p style="font-weight:500;">Статья 70. Согласие капитана при приеме на работу членов экипажа</p><br>

<p>(1) Ни один из членов экипажа судна не может быть принят на работу без согласия капитана.</p><br>

<p style="text-indent:2em;">(2) Отказ капитана принять в состав экипажа лицо, направленное судовладельцем, должен быть обоснованным. Такой отказ
 оформляется письменно и передается судовладельцу и лицу, которому отказано в приеме на работу.</p><br>

<p style="font-weight:500;">Статья. 71. Осуществление капитаном действий по уголовному преследованию</p><br>

<p style="text-indent:2em;">(1) В случае совершения на судне, находящемся в плавании, уголовно наказуемого деяния капитан осуществляет действия
 по уголовному преследованию, руководствуясь уголовно-процессуальным законодательством Республики Молдова и
 сответствующими инструкциями.</p><br>

<p style="text-indent:2em;">(2) Капитан враве задержать лицо, подозреваемое в совершении уголовно наказуемого деяния, до передачи его компетентным
 органам в ближайшем порту. В случае необходимости капитан может направить задержанное лицо и материалы уголовного
 преследования в Республику Молдова на другом судне, плавающем под ее флагом.</p><br>

<p style="text-indent:2em;">3) Если во время пребывания судна в порту Республики Молдова на его борту было совершено наказуемое согласно уголовному
 законодательству Республики Молдова деяние, капитан обязан передать подозреваемое лицо компетентным органам. [Ст.71
 изменена ZP206/29.05.03; MO149/18.07.03 xaт.598]</p><br>

<p style="font-weight:500;">Статья. 71. Выполнение капитаном функций органа дознания</p><br>

<p style="text-indent:2em;">(1) В случае совершения на судне, находящемся в плавании, уголовно наказуемого деяния капитан выполняет функции органа
 дознания, руководствуясь уголовно-процессуальным законодательством Республики Молдова и сответствующими инструкциями.</p><br>

<p style="text-indent:2em;">(2) Капитан враве задержать лицо, подозреваемое в совершении уголовно наказуемого деяния, до передачи его компетентным
 органам в ближайшем порту. В случае необходимости капитан может направить задержанное лицо и материалы дознания в
 Республику Молдова на другом судне, плавающем под ее флагом.</p><br>

<p style="text-indent:2em;">(3) Если во время пребывания судна в порту Республики Молдова на его борту было совершено наказуемое согласно уголовному
 законодательству Республики Молдова деяние, капитан обязан передать подозреваемое лицо компетентным органам.</p><br>

<p style="font-weight:500;">Статья 72. Права капитана в случае недостатка продовольствия</p><br>

<p style="text-indent:2em;">В случае истощения на судне жизненных припасов, в том числе неприкосновенного запаса продовольствия, капитан вправе
 произвести реквизицию с целью равного распределения между находящимися на борту судна лицами имеющегося у них продовольствия,
 а также груза, который может быть использован для питания. О проведении реквизиции составляется акт. Стоимость реквизированных
 продуктов питания и груза возмещается судовладельцем.</p><br>

<p style="font-weight:500;">Статья 73. Права капитана в случае неотложной надобности в деньгах</p><br>

<p style="text-indent:2em;">(1) Если в ходе рейса возникла неотложная надобность в деньгах для продолжения плавания, и прежде всего для ремонта судна
 или содержания экипажа, но возможность или время для получения распоряжения судовладельца отсутствуют, капитан вправе продать часть
 вверенного ему имущества, не относящегося к необходимому для продолжения плавания. При этом он избирает способ приобретения денежных
 средств, наименее убыточный для судовладельца и грузовладельцев.</p><br>

<p style="text-indent:2em;">(2) Стоимость проданного груза должна быть возмещена его владельцу, за исключением случаев, когда понесенные убытки подпадают под
 признаки общей аварии или когда продажа была произведена только в целях спасения груза.</p><br>

<p style="font-weight:500;">Статья 74. Удостоверение факта рождения ребенка</p><br>

<p style="text-indent:2em;">Если во время рейса на судне родился ребенок, капитан обязан в присутствии двух свидетелей и судового врача составить акт и сделать
 соответствующую запись в судовом журнале. Составленный капитаном акт представляется в компетентный орган для получения свидетельства
 о рождении ребенка.</p><br>

<p style="font-weight:500;">Статья 75. Удостоверение завещания и факта смерти</p><br>

<p style="text-indent:2em;">(1) В случае составления находящимся на судне лицом завещания капитан обязан удостоверить его и сохранить до передачи
 компетентным органам.</p><br>

<p style="text-indent:2em;">(2) Если на судне кто-либо умер, капитан обязан в присутствии двух свидетелей, а при наличии врача или фельдшера - и
 в их присутствии составить акт о смерти, сделать соответствующую запись в судовом журнале, приложить к акту опись
 имеющегося на судне имущества умершего и обеспечить сохранность этого имущества до передачи родственникам или компетентным органам.</p><br>

<p style="text-indent:2em;">(3) Капитан судна обязан принять меры, необходимые для сохранения и отправки тела умершего на родину. При отсутствии
 такой возможности он организует похороны при заходе судна в ближайший порт. Если судно длительное время должно находиться
 в открытом море, а тело умершего не может быть сохранено, капитан имеет право предать тело морю согласно морским обычаям,
 с составлением об этом акта и внесением соответствующей записи в судовой журнал.</p><br>

<p style="font-weight:500;">Статья 76. Отношения с консульскими учреждениями</p><br>

<p style="text-indent:2em;">Отношения капитана судна и других членов экипажа с консульскими учреждениями определяются законодательством Республики Молдова 
и нормами международного права.</p>

<p></p><h4>РАЗДЕЛ IV МОРСКОЙ ПОРТ</h4><p></p>

<p></p><h4>Глава 1 ПРАВОВОЙ СТАТУС И ФУНКЦИИ МОРСКОГО ПОРТА</h4><p></p>

<p style="font-weight:500;">Статья 77. Понятие морского порта</p><br>

<p style="text-indent:2em;">(1) В настоящем кодексе под морским торговым портом понимается предприятие с любым видом собственности, любой организационно-правовой формы,
 располагающее комплексом береговых сооружений и предназначенное для обслуживания судов и пассажиров, выполнения операций с грузами, оказания
 других услуг на специально отведенных территории и акватории, которая находится в зоне морского судоходства.</p><br>

<p style="text-indent:2em;">(2) Под морским рыбным портом в настоящем кодексе понимается предприятие с любым видом собственности, любой организационно-правовой формы,
 располагающее комплексом береговых сооружений и предназначенное для обслуживания рыболовных судов.</p><br>

<p>(3) Морской порт является юридическим лицом.</p><br>

<p style="text-indent:2em;">(4) Правовой статус морского порта определяется настоящим кодексом, иными нормативными актами Республики Молдова и уставом порта.</p><br>

<p>(5) Об открытии морского порта Республики Молдова для захода иностранных судов заявляется официально.</p><br>

<p style="font-weight:500;">Статья 78. Территория и акватория морского порта</p><br>

<p style="text-indent:2em;">(1) Территорию морского порта составляют отведенные ему земли, а также искусственно созданные (намытые) земли.</p><br>

<p>(2) Акваторией порта считаются отведенные ему водные пространства.</p><br>

<p style="text-indent:2em;">(3) Земли морского порта, а также находящиеся на них сооружения (волноломы, ограждения, причалы), другие объекты, подъездные
 железнодорожные пути и автомобильные дороги, иное имущество, согласно законодательству являющиеся государственной собственностью,
 предоставляются порту в хозяйственное пользование или в аренду.</p><br>

<p style="text-indent:2em;">(4) Отвод земельных и водных участков морскому порту, а также изъятие их производятся в порядке, установленном законодательством.</p><br>

<p style="font-weight:500;">Статья 79. Функции морского порта по обеспечению безопасности мореплавания</p>

<p style="text-indent:2em;"></p><h6>(1) На морской порт возлагаются следующие функции по обеспечению безопасности мореплавания и поддержанию порядка в зоне порта:</h6><p></p>

<p>a) создание условий для безопасного движения в портовых водах, безопасной стоянки и обработки судов;</p><br>

<p>b) содержание в исправности гидротехнических сооружений;</p><br>

<p>c) содержание в надлежащем состоянии средств связи и электрорадионавигации, находящихся во владении порта;</p><br>

<p>d) содержание в исправности навигационных знаков, находящихся во владении порта;</p><br>

<p>e) поддержание объявленных глубин;</p><br>

<p>f) определение зон, в которых использование буксиров обязательно;</p><br>

<p style="text-indent:2em;">g) обеспечение чистоты и порядка в акватории, на территории порта, выполнение требований по охране окружающей среды;</p><br>

<p style="text-indent:2em;">h) принятие с судов нефтесодержащих и сточных вод, мусора, иных веществ, вредных для окружающей среды и здоровья человека;</p><br>

<p>i) оказание помощи на воде.</p><br>

<p style="text-indent:2em;">(2) Нормативные акты, регламентирующие порядок выполнения морским портом перечисленных в части (1) функций, начальник порта
 представляет на утверждение центральному отраслевому органу.</p><br>

<p style="font-weight:500;">Статья 80. Морская администрация порта</p><br>

<p>(1) Морская администрация порта создается центральным отраслевым органом.</p><br>

<p style="text-indent:2em;">(2) Морская администрация порта вправе в установленном законодательством порядке сдавать в аренду переданные ей в хозяйственное
 пользование портовые сооружения и другие объекты стивидорным, транспортно-экспедиторским и другим организациям для производства портовых операций.</p><br>

<p style="text-indent:2em;">(3) Морская администрация порта осуществляет наблюдение за технической эксплуатацией портовых сооружений, других объектов и
 обеспечивает их восстановление.</p><br>

<p style="font-weight:500;">Статья 81. Управление морским портом</p><br>

<p style="text-indent:2em;">(1) Органы управления морским портом, порядок их формирования, их функции определяются настоящим кодексом и уставом порта.</p><br>

<p style="text-indent:2em;">(2) Морским портом, находящимся в государственной собственности, управляет начальник порта, который одновременно является руководителем
 морской администрации порта. Начальник морского порта назначается центральным отраслевым органом.</p><br>

<p style="font-weight:500;">Статья 82. Полномочия начальника морского порта</p><br>

<p style="text-indent:2em;">(1) Начальник морского порта утверждает обязательные для исполнения в порту постановления, которые касаются безопасности движения судов,
 охраны грузов и имущества порта, проведения санитарных и противопожарных мероприятий в порту, защиты окружающей среды, захода судов в порт
 и выхода из него, а также свод портовых правил, издает распоряжения о задержании судов и грузов в случаях и в порядке, предусмотренных
 статьей 84.</p><br>

<p style="text-indent:2em;">(2) Если морской порт имеет акваторию, смежную с акваторией военного порта, порядок захода судов в морской порт и выхода из него
 устанавливается командованием военного порта по согласованию с начальником морского порта.</p><br>

<p style="font-weight:500;">Статья 83. Административная власть начальника морского порта</p><br>

<p style="text-indent:2em;">Начальник морского порта руководит хозяйственной деятельностью, имеет право приостанавливать перегрузочные работы, движение судов,
 если производство таких работ, состояние судов не отвечают требованиям правил и норм безопасности или не выполняются обязательные 
 для исполнения в порту постановления, указанные в статье 82.</p><br>

<p style="font-weight:500;">Статья 84. Задержание судов и грузов</p><br>

<p style="text-indent:2em;">(1) Судно или груз могут быть задержаны в морском порту начальником порта по просьбе лица, предъявившего требование в связи с общей аварией,
 действиями по спасанию, договором о морской перевозке груза, ущербом, нанесенным в результате столкновения судов, либо иным причиненным 
 ущербом или в связи с повреждением портовых сооружений, навигационного оборудования, до предоставления судовладельцем либо грузовладельцем
 достаточного обеспечения.</p><br>

<p style="text-indent:2em;">(2) Ответственность за убытки, обусловленные необоснованным задержанием судна или груза , несет лицо, по требованию которого было произведено
 задержание.</p><br>

<p style="font-weight:500;">Статья 85. Срок задержания судов и грузов</p><br>

<p style="text-indent:2em;">Распоряжение начальника порта о задержании судна или груза по основаниям, изложенным в статье 84, действительно в течение 72 часов.
 Если по истечении этого срока судебной инстанцией Республики Молдова не будет принято постановление о наложении ареста на судно или
 груз, они подлежат немедленному освобождению.</p><br>
 
 <p style="font-weight:500;">Статья 86. Судебный иммунитет иностранных судов</p><br>
 
 <p style="text-indent:2em;">Не подлежат аресту на основании требований имущественного характера иностранные суда, используемые только для несения государственной службы.
 Исключение составляют случаи,предусмотренные Гражданским процессуальным кодексом Республики Молдова.</p><br>

<p style="font-weight:500;">Статья 87. Предоставление помещений органам государственного надзора</p><br>

<p style="text-indent:2em;">На территории морского порта органам государственного надзора предоставляются специальные помещения и создаются необходимые для работы условия.</p><br>

<p style="font-weight:500;">Статья 88. Портовые сборы</p><br>

<p style="text-indent:2em;">В морском порту взимаются корабельный, причальный, якорный, канальный, маячный, грузовой, а также другие сборы в порядке,
 установленном законодательством. Часть поступлений от портовых сборов направляется на содержание портовой инспекции государственного
 надзора и морской администрации порта.</p><br>

<p style="font-weight:500;">Статья 89. Соблюдение портового режима</p><br>

<p style="text-indent:2em;">В морском порту обязательно соблюдение требований нормативных актов, касающихся обеспечения безопасности судоходства в зоне порта,
 таможенного, пограничного, санитарного (фитосанитарного) контроля, лоцманской проводки, буксировки судов, спасательных и судоподъемных работ,
 якорной стоянки и предоставления мест у причалов, погрузки и выгрузки грузов, посадки и высадки пассажиров, оказания других услуг, портовых сборов,
 а также предотвращения загрязнения окружающей среды.</p>

<p></p><h4>Глава 2 КАПИТАН ПОРТА</h4><p></p>

<p style="font-weight:500;">Статья 90. Портовая инспекция государственного надзора</p><br>

<p style="text-indent:2em;">(1) Государственный надзор за обеспечением безопасности мореплавания и поддержанием порядка в зоне морского порта осуществляет капитан порта,
 действующий в соответствии с положением о капитане морского порта, утверждаемым центральным отраслевым органом.</p><br>

<p>(2) В каждом морском порту создается инспекция государственного надзора, возглавляемая капитаном порта.</p><br>

<p style="text-indent:2em;">(3) Если морской торговый порт имеет акваторию, смежную с акваторией другого порта - рыбного или речного, создается единая инспекция 
государственного надзора во главе с капитаном торгового порта.</p><br>

<p style="font-weight:500;">Статья 91. Назначение капитана порта</p><br>

<p>(1) Капитан морского порта назначается центральным отраслевым органом.</p><br>

<p style="text-indent:2em;">(2) При выполнении своих функций, предусмотренных статьей 92, капитан морского порта подчиняется центральному отраслевому органу.</p><br>

<p style="font-weight:500;">Статья 92. Функции капитана порта</p>

<p style="text-indent:2em;"></p><h6>На капитана морского порта возлагаются следующие функции:</h6><p></p>

<p style="text-indent:2em;">a) надзор за соблюдением законодательства в области торгового мореплавания, за выполнением условий подписанных Республикой Молдова 
международных договоров в указанной области;</p><br>

<p>b) регистрация судов в судовом реестре и в судовой книге, выдача судовых документов;</p><br>

<p>c) выдача морякам дипломов (квалификационных свидетельств) и подтверждений к ним;</p><br>

<p>d) проверка судовых документов, дипломов (квалификационных свидетельств) и подтверждений к ним;</p><br>

<p style="text-indent:2em;">е) выдача удостоверений личности моряка лицам, входящим в состав судовых экипажей; [Ст.92 пкт.е) в редакции ЗП44XVI от 03.03.06, 
MO47-50/24.03.06 ст.208] [Ст.92 пкт.е) в редакции ЗП44-XVI от 03.03.06, MO47-50/24.03.06 ст.208]</p><br>

<p>e) выдача удостоверения (паспорта) моряка лицам, входящим в состав судовых экипажей;</p><br>

<p>f) надзор за соблюдением требований, касающихся захода судов в порт и выхода из него;</p><br>

<p>g) оформление документов на вход судов в порт и выход из него;</p><br>

<p>h) руководство лоцманской службой и службой регулирования движения судов;</p><br>

<p>i) контроль за ледокольной проводкой судов на подходах к порту и в пределах его акватории;</p><br>

<p style="text-indent:2em;">j) учет и расследование морских происшествий в соответствии с положением, утверждаемым центральным отраслевым органом;</p><br>

<p style="text-indent:2em;">k) выдача разрешений на подъем затонувшего имущества, а также на производство в пределах территории и акватории порта строительных,
 гидротехнических и иных работ.</p><br>

<p style="font-weight:500;">Статья 93. Распоряжения капитана порта</p><br>

<p style="text-indent:2em;">Распоряжения капитана порта по вопросам обеспечения безопасности мореплавания и поддержания порядка в зоне порта, относящиеся к его компетенции,
 обязательны для всех находящихся в пределах территории и акватории порта судов, юридических и физических лиц.</p><br>

<p style="font-weight:500;">Статья 94. Контроль за судами</p><br>

<p style="text-indent:2em;">(1) Контроль за судами, выходящими в море, осуществляет капитан порта или другой представитель портовой инспекции государственного надзора с целью
 проверки судовых документов, установления соответствия основных характеристик судна судовым документам, а также проверки выполнения требований по 
 укомплектованию судового экипажа. Правила контроля устанавливаются центральным отраслевым органом.</p><br>

<p style="text-indent:2em;">(2) В случае отсутствия судовых документов или наличия достаточных оснований для признания судна не удовлетворяющим требованиям безопасности мореплавания
 портовая инспекция государственного надзора вправе подвергнуть судно осмотру.</p><br>

<p style="text-indent:2em;">(3) Для проверки, устранены ли недостатки, препятствующие выдаче разрешения на выход судна из порта, портовая инспекция государственного надзора вправе
 произвести контрольный осмотр судна.</p><br>

<p style="font-weight:500;">Статья 95. Выдача разрешений на выход судов из порта</p><br>

<p>(1) Разрешение на выход судна из морского порта выдает капитан порта.</p>

<p style="text-indent:2em;"></p><h6>(2) Капитан порта может отказать в выдаче разрешения на выход судна из порта в случае:</h6><p></p>

<p style="text-indent:2em;">a) непригодности судна к плаванию, нарушения требований относительно загрузки, снабжения, укомплектования экипажа судна, а также при наличии других причин,
 создающих угрозу безопасности плавания, здоровью находящихся на судне людей или могущих привести к причинению вреда окружающей среде;</p><br>

<p>b) нарушения требований, касающихся судовых документов;</p><br>

<p>c) неуплаты установленных сборов, штрафов, невнесения других платежей;</p><br>

<p style="text-indent:2em;">d) принятия соответствующего решения санитарно-карантинной службой, инспекцией рыбоохраны, миграционной службой или пограничными, таможенными,
 другими уполномоченными на то государственными органами.</p><br>

<p style="text-indent:2em;">(3) Капитан порта вправе задержать судно по основаниям, указанным в части (2), до устранения обнаруженных недостатков или до внесения причитающихся
 с судна сборов, штрафов, других платежей. Если выявленные недостатки нельзя ликвидировать в порту, судну должна быть предоставлена возможность проследовать
 в другой порт (место), где может быть произведен ремонт. О задержании судна немедленно сообщается судовладельцу.</p><br>

<p style="text-indent:2em;">(4) Расходы, связанные с осуществлением капитаном порта прав, предоставленных ему настоящей статьей, несет судовладелец.</p>

<p></p><h4>Глава 3 ЛОЦМАНСКАЯ СЛУЖБА</h4><p></p>

<p style="font-weight:500;">Статья 96. Функции морской лоцманской службы</p><br>

<p style="text-indent:2em;">(1) В целях обеспечения безопасности мореплавания все суда на подходах к морскому порту и в акватории порта проводятся лоцманами.</p><br>

<p>(2) Положение о морской лоцманской службе утверждается центральным отраслевым органом.</p><br>

<p>(3) Лоцманская служба находится в подчинении у капитана порта.</p><br>

<p style="font-weight:500;">Статья 97. Обязательная и необязательная лоцманская проводка</p><br>

<p style="text-indent:2em;">(1) Центральный отраслевой орган устанавливает зоны, в которых лоцманская проводка обязательна или необязательна, определяет категории судов, освобождаемых
 от обязательной проводки, и доводит это до всеобщего сведения посредством издания нормативных актов, лоций, через официальные сообщения.</p><br>

<p style="text-indent:2em;">(2) В зонах, где лоцманская проводка обязательна, судно не может плавать без морского лоцмана, если оно не относится к категории судов,
 освобожденных от обязательной лоцманской проводки.</p><br>

<p style="text-indent:2em;">(3) В зонах, в которых лоцманская проводка необязательна, капитан имеет право взять на судно лоцмана, если сочтет это необходимым.</p>

<p style="text-indent:2em;"></p><h6>(4) В зонах, где лоцманская проводка необязательна, капитан порта вправе устанавливать обязательную лоцманскую проводку для судов:</h6><p></p>

<p style="text-indent:2em;">a) которые сами или грузы которых могут создавать угрозу нанесения ущерба окружающей среде. Категории таких судов указываются в обязательном
 для исполнения в порту постановлении;</p><br>

<p style="text-indent:2em;">b) которые имеют серьезные повреждения корпуса, механизмов или оборудования, что может существенно повлиять на безопасность их плавания в портовых водах.
 В таких случаях капитану судна вручается уведомление о том, что его судно должно следовать под проводкой лоцмана.</p><br>

<p style="font-weight:500;">Статья 98. Определение времени лоцманской проводки</p><br>

<p style="text-indent:2em;">(1) Время лоцманской проводки судна определяется капитаном порта и объявляется в обязательном для исполнения в порту постановлении.</p><br>

<p style="text-indent:2em;">(2) Капитан порта вправе запретить проводку судов, когда ей препятствуют погодные условия или стихийные явления, а также при других особых
 обстоятельствах, создающих опасность для судоходства.</p><br>

<p style="font-weight:500;">Статья 99. Лоцманский сбор</p><br>

<p style="text-indent:2em;">За услуги, оказываемые морским лоцманом, взимается лоцманский сбор. Его размер и порядок взимания устанавливаются центральным отраслевым органом.</p><br>

<p style="font-weight:500;">Статья 100. Квалификационные требования</p><br>

<p style="text-indent:2em;">Морским лоцманом может быть лицо, проживающее в Республике Молдова, имеющее диплом капитана и удовлетворяющее требованиям,
 установленным положением о морской лоцманской службе.</p><br>

<p style="font-weight:500;">Статья 101. Обязанности лоцмана</p>

<p style="text-indent:2em;"></p><h6>При проводке судов лоцман обязан:</h6><p></p>

<p>a) наблюдать за состоянием ограждения фарватера и береговых навигационных знаков;</p><br>

<p style="text-indent:2em;">b) немедленно сообщать капитану порта обо всех изменениях на фарватере, а также о любом происшествии в районе проводки;</p><br>

<p style="text-indent:2em;">c) при выявлении нарушения капитаном судна обязательного для исполнения в порту постановления, правил судоходства или других правил - требовать
 устранения нарушения, а в случае невыполнения требования немедленно сообщать об этом капитану порта.</p><br>

<p style="font-weight:500;">Статья 102. Направление лоцмана на судно</p><br>

<p style="text-indent:2em;">(1) После поступления заявки на проводку лоцманская служба направляет на судно морского лоцмана. О времени прибытия лоцмана сообщается капитану судна.</p><br>

<p>(2) Морского лоцмана, прибывшего на судно, может сопровождать стажер.</p><br>

<p style="font-weight:500;">Статья 103. Условия пребывания лоцмана на судне</p><br>

<p style="text-indent:2em;">Капитан обязан обеспечить оперативный и безопасный прием лоцмана на борт судна и безвозмездно предоставить ему отдельное помещение и питание.</p><br>

<p style="font-weight:500;">Статья 104. Лоцманская квитанция</p><br>

<p style="text-indent:2em;">Прибыв на судно, лоцман обязан представить лоцманскую квитанцию, в которую капитан вносит следующую информацию: название судна, флаг,
 под которым оно плавает, данные об осадке, длине, ширине, чистой вместимости судна, место и время приема лоцмана. По завершении лоцманской проводки капитан отмечает в квитанции его место и 
 время, при необходимости - записывает замечания относительно проводки. Внесенные в лоцманскую квитанцию информацию о судне и замечания капитан заверяет подписью.</p><br>

<p style="font-weight:500;">Статья 105. Ответственность за неточность информации о судне</p><br>

<p style="text-indent:2em;">За неточность внесенной в лоцманскую квитанцию информации, касающейся осадки, длины, ширины или чистой вместимости судна, капитан
 обязан уплатить штраф в размере, вдвое превышающем установленный лоцманский сбор. Кроме того, за необъявление данных о судне или
 объявление недостоверных данных капитан несет ответственность, предусмотренную законодательством.</p><br>

<p style="font-weight:500;">Статья 106. Правовой статус лоцмана на судне</p><br>

<p>(1) Присутствие лоцмана не снимает с капитана ответственности за управление судном.</p><br>

<p style="text-indent:2em;">(2) В случае оставления мостика капитан обязан указать лоцману лицо, ответственное за управление судном в его отсутствие.</p><br>

<p style="font-weight:500;">Статья 107. Оставление лоцманом судна</p><br>

<p style="text-indent:2em;">Лоцман не вправе без согласия капитана оставить судно до того, как оно станет на якорь, пришвартуется в безопасном месте либо выйдет
 в море, или до прибытия другого лоцмана.</p><br>

<p style="font-weight:500;">Статья 108. Отказ лоцмана от выполнени своих обязанностей</p><br>

<p style="text-indent:2em;">Если капитан, приняв на судно лоцмана, действует вопреки его рекомендациям, лоцман имеет право в присутствии третьего лица отказаться
 от дальнейшей проводки судна и потребовать внесения записи об этом в лоцманскую квитанцию. Однако лоцман обязан оставаться на мостике,
 и если капитану понадобятся сведения, необходимые для безопасного плавания, лоцман должен сообщить их. Если капитан потребует от лоцмана
 возобновления проводки судна, он обязан выполнить это требование.</p><br>

<p style="font-weight:500;">Статья 109. Отказ от лоцманских услуг</p><br>

<p style="text-indent:2em;">(1) При возникновении сомнения в правильности рекомендаций лоцмана капитан вправе отказаться от его услуг. В таких случаях в зоне,
 где лоцманская проводка обязательна, капитан, если это возможно, останавливает судно до прибытия другого лоцмана.</p><br>

<p>(2) Капитан, вызвавший лоцмана и отказавшийся от его услуг, обязан полностью уплатить лоцманский сбор.</p><br>

<p style="font-weight:500;">Статья 110. Дополнительное вознаграждение при задержке лоцмана</p><br>

<p style="text-indent:2em;">В случае задержки лоцмана на судне свыше двух часов из-за необходимости догрузки или разгрузки судна, неисправности судовых механизмов,
 нахождения судна в карантине или других обстоятельств, если они не обусловлены чрезвычайной ситуацией, капитан обязан выплатить 
 дополнительное вознаграждение в размере, установленном морской администрацией порта.</p><br>

<p style="font-weight:500;">Статья 111. Дополнительное вознаграждение при возвращении лоцмана к месту службы</p><br>

<p style="text-indent:2em;">Если лоцман и стажер были увезены проведенным судном за пределы обслуживаемой ими зоны, капитан обязан возместить расходы, связанные с
 возвращением их к месту службы (на проезд, проживание в гостинице, суточные), и, кроме того, выплатить вознаграждение, предусмотренное статьей 110.</p><br>

<p style="font-weight:500;">Статья 112. Ответственность за морские происшествия</p><br>

<p style="text-indent:2em;">Ответственность за морское происшествие, обусловленное действиями лоцмана, несет организация, работником которой он является.
 Эта ответственность может ограничиваться размерами специального фонда, образующегося из 10 процентов отчислений от лоцманских сборов,
 поступивших в календарном году, предшествовавшем году, в котором имело место морское происшествие.</p><br>

<p style="font-weight:500;">Статья 113. Уплата лоцманского сбора, штрафов, дополнительных вознаграждений</p><br>

<p style="text-indent:2em;">Лоцманский сбор, штрафы, дополнительные вознаграждения, предусмотренные настоящей главой, поступают в доход организации,
 которой подчинена лоцманская служба.</p>

<p></p><h4>Глава 4 СЛУЖБА РЕГУЛИРОВАНИЯ ДВИЖЕНИЯ СУДОВ</h4><p></p>

<p style="font-weight:500;">Статья 114. Понятие службы регулирования движения судов</p><br>

<p style="text-indent:2em;">(1) В районах интенсивного судоходства радиолокационное обслуживание судов осуществляется службами регулирования движения судов (далее
 - служба регулирования), которые создаются по согласованию с центральным отраслевым органом.</p><br>

<p style="text-indent:2em;">(2) Под радиолокационным обслуживанием судов понимаются регулирование движения судов, радарная проводка, информирование о движении судов,
 состоянии навигационного оборудования, гидрометеорологических условиях, а также о других факторах, влияющих на безопасность плавания, 
 оказание помощи судам при выполнении спасательных операций и ликвидации последствий морских происшествий.</p><br>

<p style="text-indent:2em;">(3) Функции службы регулирования, зона ее действия, порядок движения в зоне, перечень услуг, оказываемых службой, устанавливаются правилами,
 утверждаемыми центральным отраслевым органом. Информация об этом приводится в обязательном для исполнения в порту постановлении, в лоциях,
 в официальных сообщениях.</p><br>

<p style="font-weight:500;">Статья 115. Правовой статус службы регулирования</p><br>

<p>(1) Служба регулирования создается в соответствии с типовым положением.</p><br>

<p style="text-indent:2em;">(2) При создании региональной службы регулирования порядок ее образования и подчиненность устанавливаются по согласованию с центральным
 отраслевым органом.</p><br>

<p style="text-indent:2em;">(3) Правовой статус службы регулирования определяется настоящим кодексом, иными нормативными актами и положением о службе регулирования.</p><br>

<p style="font-weight:500;">Статья 116. Полномочия и ответственность службы регулирования</p><br>

<p style="text-indent:2em;">(1) В случаях, оговоренных в обязательном для исполнения в порту постановлении, служба регулирования передает на судно (по поступившему с
 него запросу) специальные распоряжения и необходимую информацию через лоцмана-оператора.</p><br>

<p style="text-indent:2em;">(2) Если капитаном судна, которое обслуживается лоцманом-оператором службы регулирования, допущено нарушение правил судоходства и/или
 обязательного для исполнения в порту постановления, лоцман-оператор обязан указать капитану на выявленное нарушение и потребовать его устранения.</p><br>

<p style="text-indent:2em;">(3) Служба регулирования несет материальную ответственность за ошибочные распоряжения и недостоверность переданной капитану судна информации.</p><br>

<p style="text-indent:2em;">(4) Обслуживание судна службой регулирования не снимает с капитана ответственности за безопасность мореплавания и не освобождает его от 
обязанности взять на судно лоцмана в районе, где лоцманская проводка обязательна.</p><br>

<p style="font-weight:500;">Статья 117. Лоцман-оператор</p><br>

<p style="text-indent:2em;">Лоцманом-оператором службы регулирования может быть гражданин Республики Молдова, удовлетворяющий требованиям, которые установлены положением
 о лоцмане-операторе, утверждаемом центральным отраслевым органом.</p><br>

<p style="font-weight:500;">Статья 118. Отказ лоцмана-оператора от оказания услуг судну</p><br>

<p style="text-indent:2em;">Если капитан судна не выполняет рекомендаций лоцмана-оператора, последний имеет право отказаться от продолжения радарной проводки. Однако лоцман
-оператор обязан оставаться на связи с судном, и если капитану понадобятся сведения, необходимые для безопасного плавания, лоцман-оператор должен
 их предоставить. Если капитан потребует, чтобы лоцман-оператор возобновил проводку судна, лоцман-оператор должен выполнить это требование.</p><br>

<p style="font-weight:500;">Статья 119. Ответственность за морские происшествия</p><br>

<p style="text-indent:2em;">Ответственность за морское происшествие, обусловленное действиями лоцмана-оператора, несет организация, в состав которой входит служба регулирования.
 Эта ответственность может ограничиваться размерами специального фонда, образующегося из 10 процентов отчислений от сборов за радарную проводку,
 поступивших в календарном году, предшествовавшем году, в котором имело место морское происшествие.</p><br>

<p style="font-weight:500;">Статья 120. Сбор за радарную проводку</p><br>

<p style="text-indent:2em;">(1) При оказании службой регулирования услуг взимается сбор за радарную проводку. Его размер и порядок взимания устанавливаются центральным отраслевым
 органом.</p><br>

<p style="text-indent:2em;">(2) Капитан, обратившийся к службе регулирования за услугами и впоследствии отказавшийся от них, обязан полностью уплатить полагающийся за затребованные
 услуги сбор.</p>

<p></p><h4>Глава 5 МОРСКОЕ АГЕНТИРОВАНИЕ</h4><p></p>

<p style="font-weight:500;">Статья 121. Агентирование судов</p><br>

<p style="text-indent:2em;">(1) В морских портах или вне их территории в качестве постоянных представителей судовладельцев действуют морские агенты - юридические лица,
 которые согласно договору о морском агентировании за вознаграждение обязуются оказывать услуги в области торгового мореплавания.</p><br>

<p style="text-indent:2em;">(2) При заключении договора от имени судовладельца морской агент с согласия судовладельца может действовать также в пользу другой
 договаривающейся стороны, если она его на то уполномочила.</p><br>

<p style="font-weight:500;">Статья 122. Функции и обязанности морского агента</p>

<p style="text-indent:2em;"></p><h6>(1) Морской агент:</h6><p></p>

<p>a) выполняет формальности, связанные с прибытием, пребыванием и выходом судна из порта;</p><br>

<p style="text-indent:2em;">b) содействует капитану судна в установлении контактов со службами порта и с органами местного публичного управления;</p><br>

<p>c) участвует в организации снабжения, обслуживания, ремонта судна в порту;</p><br>

<p>d) оформляет документы на судно и груз;</p><br>

<p>e) вносит по распоряжению судовладельца и капитана портовые сборы;</p><br>

<p>f) инкассирует суммы фрахта и иные причитающиеся судовладельцу по морским требованиям суммы;</p><br>

<p>g) привлекает грузы для морской перевозки;</p><br>

<p>h) экспедирует груз;</p><br>

<p>i) выполняет иные функции.</p>

<p style="text-indent:2em;"></p><h6>(2) Морской агент обязан:</h6><p></p>

<p style="text-indent:2em;">a) осуществлять свою деятельность в соответствии с интересами судовладельца и/или иного доверителя, а также с обычной практикой морского агентирования;</p><br>

<p>b) действовать в пределах своих полномочий;</p><br>

<p style="text-indent:2em;">c) не передавать своих функций другому лицу (субагенту), за исключением случаев, когда он уполномочен на это доверителем;</p><br>

<p>d) вести учет расходования средств.</p><br>

<p style="font-weight:500;">Статья 123. Обязанности судовладельца и/или иного доверителя</p>

<p style="text-indent:2em;"></p><h6>(1) Судовладелец и/или иной доверитель обязаны:</h6><p></p>

<p>a) предоставлять в распоряжение морского агента средства, необходимые для осуществления им своих функций;</p><br>

<p>b) возмещать морскому агенту любые расходы, произведенные им от их имени и с их согласия;</p><br>

<p style="text-indent:2em;">c) нести ответственность за последствия всех действий морского агента, совершенных в пределах его полномочий.</p><br>

<p style="text-indent:2em;">(2) В случае ограничения доверителем обычных полномочий морского агента любая сделка, совершенная последним с третьим лицом, действовавшим добросовестно,
 имеет силу и обязывает доверителя, если третьему лицу не было известно о таком ограничении.</p><br>

<p style="font-weight:500;">Статья 124. Прекращение действия договора о морском агентировании</p><br>

<p style="text-indent:2em;">(1) Действие договора о морском агентировании, заключенного на определенный срок, прекращается по истечении этого срока.</p><br>

<p style="text-indent:2em;">(2) Если договор о морском агентировании заключен на неопределенный срок, каждая из сторон вправе отказаться от него, известив об этом другую
 сторону не позднее чем за три месяца до дня расторжения договора.</p>

<p></p><h4>Глава 6 ЗАТОНУВШЕЕ ИМУЩЕСТВО</h4><p></p>

<p style="font-weight:500;">Статья 125. Сфера применения настоящей главы</p><br>

<p style="text-indent:2em;">(1) Положения настоящей главы применяются при подъеме, удалении, уничтожении имущества, затонувшего во внутренних водах Республики Молдова.</p><br>

<p style="text-indent:2em;">(2) Под затонувшим имуществом понимаются потерпевшие крушение суда, иные плавучие средства, их обломки, самолеты и их части, грузы, другие предметы,
 независимо от того, находятся они на плаву или под водой, опустились на дно либо выброшены на мелководье или на берег.</p>

<p style="text-indent:2em;"></p><h6>(3) Положения настоящей главы не применяются при:</h6><p></p>

<p>a) подъеме, удалении, уничтожении затонувшего военного имущества;</p><br>

<p>b) подъеме имущества, представляющего собой культурную, историческую или археологическую ценность.</p><br>

<p style="text-indent:2em;">(4) Подъем, удаление, уничтожение имущества, указанного в части (3), осуществляются в соответствии со специальным законодательством Республики Молдова.
 Если такое имущество находится в акватории морского порта, орган, ответственный за его подъем, удаление, уничтожение, должен согласовать свои действия 
 с портовой инспекцией государственного надзора.</p><br>

<p style="font-weight:500;">Статья 126. Право на затонувшее имущество</p><br>

<p style="text-indent:2em;">(1) Право на имущество, затонувшее во внутренних либо в территориальных водах того или иного государства, а также отношения, возникающие в связи с 
таким имуществом, регламентируются законодательством этого государства.</p><br>

<p style="text-indent:2em;">(2) В отношении судна, затонувшего в открытом море, а также в отношении находившихся на нем грузов и иного имущества применяется законодательство
 государства, под флагом которого плавало судно.</p><br>

<p style="font-weight:500;">Статья 127. Подъем имущества его собственником</p><br>

<p style="text-indent:2em;">(1) Если собственник затонувшего имущества намерен его поднять, он должен известить об этом инспекцию государственного надзора ближайшего порта
 в течение срока, не превышающего одного года со дня, когда имущество затонуло.</p><br>

<p style="text-indent:2em;">(2) Портовая инспекция государственного надзора по согласованию с центральным отраслевым органом устанавливает срок для подъема затонувшего имущества,
 порядок производства работ и сообщает об этом собственнику имущества.</p><br>

<p style="font-weight:500;">Статья 128. Подъем затонувшего опасного имущества</p><br>

<p style="text-indent:2em;">(1) В случаях, когда затонувшее имущество препятствует судоходству, морским промыслам, проведению гидротехнических и других работ, создает опасность для 
жизни либо здоровья людей или угрозу загрязнения окружающей среды, его собственник должен срочно известить о случившемся инспекцию государственного надзора
 ближайшего порта и поднять, удалить и/или уничтожить это имущество в установленный инспекцией срок.</p><br>

<p style="text-indent:2em;">(2) Если затонувшее имущество представляет собой непосредственную угрозу безопасности плавания, жизни либо здоровью людей или является источником 
загрязнения окружающей среды, но собственник не поднимает его в срок, установленный портовой инспекцией государственного надзора, последняя имеет
 право принять надлежащие меры для немедленного подъема затонувшего имущества за счет его собственника, а при необходимости - для удаления или 
 уничтожения опасного имущества.</p><br>

<p style="text-indent:2em;">(3) При невозможности установить собственника затонувшего опасного имущества портовая инспекция государственного надзора публикует информацию о
 сроках, определенных для подъема такого имущества. Если флаг затонувшего судна известен, портовая инспекция направляет соответствующее уведомление
 центральному отраслевому органу, который по дипломатическим каналам объявляет о случившемся государству, под чьим флагом плавало затонувшее судно.</p><br>

<p style="font-weight:500;">Статья 129. Право портовой инспекции государственного надзора на выбор судоподъемного предприятия</p><br>

<p style="text-indent:2em;">При наличии достаточных оснований портовая инспекция государственного надзора имеет право не разрешить собственнику подъем затонувшего имущества
 своими средствами или средствами избранного им судоподъемного предприятия. В таких случаях подъем затонувшего имущества за счет его собственника
 организует портовая инспекция.</p><br>

<p style="font-weight:500;">Статья 130. Утрата права на затонувшее имущество</p><br>

<p style="text-indent:2em;">Если собственник затонувшего имущества не делает заявления о его истребовании или не поднимает его в сроки, предусмотренные статьями 127 и 128,
 он утрачивает право на такое имущество.</p><br>
 
<p style="font-weight:500;">Статья 131. Случайно поднятое имущество</p><br>

<p style="text-indent:2em;">Случайно поднятое при осуществлении операций, связанных с торговым мореплаванием, затонувшее имущество должно быть передано в ближайший морской
 порт. При таком условии выплачивается вознаграждение в размере одной трети стоимости имущества.</p><br>

<p style="font-weight:500;">Статья 132. Истребование имущества, поднятого портовой инспекцией государственного надзора</p><br>

<p style="text-indent:2em;">(1) Имущество, поднятое портовой инспекцией государственного надзора в соответствии со статьей 128, как и случайно поднятое имущество, может
 быть истребовано в течение двух лет со дня его подъема. При этом собственник имущества обязан возместить портовой инспекции кроме стоимости 
 работ по подъему имущества и другие понесенные в связи с этим расходы и убытки.</p><br>

<p style="text-indent:2em;">(2) Если портовая инспекция государственного надзора из-за невозможности или нецелесообразности хранения поднятого имущества реализует его, 
то вырученная сумма, за вычетом всех расходов, связанных с подъемом, хранением и реализацией этого имущества, передается его собственнику. 
Если же средств, полученных от реализации поднятого имущества, недостаточно для покрытия расходов, понесенных портовой инспекцией, 
собственник имущества должен доплатить недостающую сумму.</p><br>

<p style="text-indent:2em;">(3) Отказ от затонувшего или поднятого имущества не освобождает его собственника от обязанности возместить портовой инспекции государственного
 надзора понесенные ею при подъеме такого имущества расходы и убытки в случаях, предусмотренных статьями 128, 129 и частями (1) и (2)
 настоящей статьи.</p>

<p></p><h4>РАЗДЕЛ V МОРСКАЯ ПЕРЕВОЗКА</h4><p></p>

<p></p><h4>Глава 1 ОБЩИЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 133. Организация морской перевозки грузов</p><br>

<p>(1) Условия морской перевозки грузов определяются договором.</p><br>

<p>(2) Для государственных нужд морская перевозка грузов осуществляется согласно законодательству.</p><br>

<p style="text-indent:2em;">(3) При осуществлении систематических перевозок перевозчик и грузовладелец имеют право заключить долгосрочный договор об организации морской
 перевозки грузов. Согласно такому договору перевозчик обязуется перевезти определенное количество грузов одним или более судами несколькими
 рейсами в течение определенного времени, а грузовладелец - предоставить для перевозки обусловленное количество грузов и уплатить фрахт. Договор
 об организации морской перевозки грузов может быть заключен с несколькими грузовладельцами.</p><br>

<p style="font-weight:500;">Статья 134. Морская перевозка транспортом общего пользования</p>

<p style="text-indent:2em;"></p><h6>(1) Организация, занимающаяся перевозкой грузов морским транспортом общего пользования:</h6><p></p>

<p style="text-indent:2em;">a) обязана принимать любой предложенный к перевозке груз, если на судне есть свободные площади, пригодные для перевозки, и груз может быть перевезен без
 нанесения повреждений ранее принятым к перевозке грузам;</p><br>

<p style="text-indent:2em;">b) не вправе оказывать предпочтение тому или иному грузовладельцу при приеме грузов и установлении условий перевозки, за исключением случаев перевозки
 срочных воинских грузов Министерства обороны и иных случаев, предусмотренных законодательством;</p><br>

<p>c) обязана публиковать тарифы и условия морской перевозки.</p><br>

<p style="text-indent:2em;">(2) Любое соглашение между организацией, осуществляющей перевозку грузов морским транспортом общего пользования, и грузовладельцем об освобождении от
 ответственности или уменьшении ответственности, которую согласно положениям настоящего кодекса перевозчик несет в случае утраты, недостачи, повреждения
 или просрочки в доставке груза, недействительно с момента его заключения.</p><br>

<p style="font-weight:500;">Статья 135. Запрещение приема грузов к перевозке</p><br>

<p style="text-indent:2em;">(1) В исключительных случаях прием грузов к перевозке в определенных направлениях может быть запрещен центральным отраслевым органом, который должен
 своевременно известить об этом Правительство и заинтересованные организации.</p><br>

<p style="text-indent:2em;">(2) При явлениях стихийного характера, морских происшествиях, вызвавших перерыв в морских перевозках, а также при объявлении карантина прием грузов
 может быть временно прекращен или ограничен распоряжением начальника порта. Об этом безотлагательно сообщается центральному отраслевому органу,
 который устанавливает сроки прекращения или ограничения приема грузов.</p><br>

<p style="text-indent:2em;">(3) Запрещение, временное прекращение, ограничение приема грузов могут быть введены для перевозок в определенных направлениях, из определенных
 портов и в определенные порты, о чем начальник порта должен срочно уведомить отправителей грузов, а при перевозке грузов в прямом смешанном или
 прямом водном сообщении - и организации транспорта других видов.</p><br>

<p style="font-weight:500;">Статья 136. Морская перевозка почты</p><br>

<p style="text-indent:2em;">(1) Подразделения центрального отраслевого органа имеют право перевозить почту на судах, плавающих под флагом Республики Молдова и
 совершающих регулярные рейсы.</p>

<p style="text-indent:2em;"></p><h6>(2) Морская перевозка почты может осуществляться:</h6><p></p>

<p>a) в сопровождении работников почтового оператора или</p><br>

<p>b) без сопровождения, путем сдачи почты в багажное отделение.</p><br>

<p style="text-indent:2em;">(3) На судах, перевозящих почту в сопровождении работников почтового оператора, выделяются специальные помещения для хранения и обработки
 почтовых отправлений, а также для отдыха сопровождающих.</p><br>

<p style="text-indent:2em;">(4) Перевозка почты на судах выполняется в соответствии со специальными правилами, утверждаемыми центральным отраслевым органом.</p><br>

<p style="text-indent:2em;">(5) За утрату, повреждение или уничтожение почты по вине перевозчика последний несет ответственность перед почтовым оператором.</p><br>

<p style="font-weight:500;">Статья 137. Международные морские перевозки</p><br>

<p style="text-indent:2em;">Перевозка грузов между портами Республики Молдова и портами других государств может осуществляться как молдавскими, так и иностранными судами.</p>

<p></p><h4>Глава 2 ДОГОВОР О МОРСКОЙ ПЕРЕВОЗКЕ ГРУЗА</h4><p></p>

<p style="font-weight:500;">Статья 138. Понятие и виды договора о морской перевозке груза</p><br>

<p style="text-indent:2em;">(1) Согласно договору о морской перевозке груза перевозчик обязуется доставить в порт назначения груз, который ему предоставил или предоставит
 отправитель, и передать его уполномоченному на получение груза лицу (получателю), а отправитель или фрахтователь обязуется внести за перевозку
 груза обусловленную плату (фрахт).</p>

<p style="text-indent:2em;"></p><h6>(2) Договор о морской перевозке груза может быть заключен:</h6><p></p>

<p style="text-indent:2em;">a) с условием предоставления для перевозки всего судна, его части или определенных судовых помещений (чартер) или</p><br>

<p>b) без такого условия.</p><br>

<p>(3) Фрахтователем является лицо, которое заключает с перевозчиком чартер.</p><br>

<p style="text-indent:2em;">(4) Отправителем является лицо, которое заключает договор о морской перевозке груза, указанный в пункте b) части (2), а также любое иное лицо,
 которое от своего имени сдает груз перевозчику.</p><br>

<p style="font-weight:500;">Статья 139. Доказательства заключения договора</p><br>

<p style="text-indent:2em;">Договор о морской перевозке груза оформляется письменно. Наличие и содержание такого договора могут подтверждаться чартером, коносаментом или другими документами.</p><br>

<p style="font-weight:500;">Статья 140. Соотношение чартера и коносамента</p><br>

<p style="text-indent:2em;">(1) Отношения между перевозчиком и получателем груза, не являющимся одной из сторон договора о перевозке, определяются коносаментом. Условия
 чартера обязательны для получателя груза, если в коносаменте содержится ссылка на них.</p><br>

<p style="text-indent:2em;">(2) К отношениям между перевозчиком груза и фрахтователем условия коносамента применяются в случае, если они не противоречат условиям чартера.</p><br>

<p style="font-weight:500;">Статья 141. Содержание чартера</p>

<p style="text-indent:2em;"></p><h6>(1) В чартере должны быть указаны:</h6><p></p>

<p>a) наименования сторон;</p><br>

<p>b) размер фрахта;</p><br>

<p>c) название судна;</p><br>

<p>d) род и вид груза;</p><br>

<p>e) место погрузки;</p><br>

<p style="text-indent:2em;">f) место назначения груза или направление, в котором следует судно. По соглашению сторон в чартер могут быть включены и иные условия,
 а также оговорки.</p><br>

<p>(2) Чартер подписывается перевозчиком груза и фрахтователем либо их представителями.</p><br>

<p style="font-weight:500;">Статья 142. Документы, подтверждающие прием груза</p><br>

<p style="text-indent:2em;">(1) После приема груза к перевозке по требованию отправителя перевозчик выдает ему коносамент, который является документом,
 подтверждающим прием груза.</p><br>

<p style="text-indent:2em;">(2) Коносамент составляется на основании подписанного отправителем документа, который должен включать данные, указанные в пунктах d)
 - h) части (1) статьи 143.</p><br>

<p style="text-indent:2em;">(3) Отправитель отвечает перед перевозчиком за все последствия, которые могут возникнуть в результате приведения недостоверных или
 неполных сведений в документе, указанном в части (2).</p><br>

<p style="text-indent:2em;">(4) Отправитель вправе потребовать от перевозчика выдачи вместо коносамента морской наклад ной или иного документа, подтверждающего
 получение груза для перевозки.</p><br>

<p style="font-weight:500;">Статья 143. Реквизиты коносамента</p>

<p style="text-indent:2em;"></p><h6>(1) В коносаменте в обязательном порядке приводятся следующие данные:</h6><p></p>

<p>a) название судна, если груз принят к перевозке на определенном судне;</p><br>

<p>b) наименование перевозчика и его местонахождение;</p><br>

<p>c) место и дата приема или погрузки груза;</p><br>

<p>d) наименование отправителя и его местонахождение;</p><br>

<p>e) место назначения груза, а при наличии чартера - место назначения или направление, в котором следует судно;</p><br>

<p style="text-indent:2em;">f) наименование получателя груза (именной коносамент) или указание на то, что коносамент выдан по "приказу отправителя" либо по "приказу получателя"
 (ордерный коносамент), или на то, что коносамент выдан на предъявителя (коносамент на предъявителя);</p><br>

<p style="text-indent:2em;">g) род и вид груза, имеющиеся на нем марки, число мест или предметов, масса груза или его количество, обозначенное иным образом, а в соответствующих
 случаях - данные о внешнем виде, состоянии и особых свойствах груза, о его тарировании и упаковке (все эти данные приводятся так, как они представлены отправителем);</p><br>

<p style="text-indent:2em;">h) размер фрахта и других причитающихся перевозчику платежей или указание на то, что фрахт должен быть уплачен согласно условиям чартера либо другого
 документа, или на то, что фрахт внесен полностью;</p><br>

<p>i) дата, время и место выдачи коносамента;</p><br>

<p>j) количество экземпляров (оригиналов) коносамента;</p><br>

<p>k) подпись и печать перевозчика или лица, действующего от его имени.</p><br>

<p style="text-indent:2em;">(2) Если при приеме к перевозке груза наливом, насыпью или навалом данные, предусмотренные пунктом g) части (1), не были проверены, перевозчик вправе
 включить в коносамент соответствующую оговорку. Такая же оговорка может быть сделана перевозчиком при перевозке других видов грузов.</p><br>

<p>(3) По соглашению сторон в коносамент могут быть включены и иные условия и оговорки.</p><br>

<p style="font-weight:500;">Статья 144. Количество экземпляров коносамента</p><br>

<p style="text-indent:2em;">(1) По желанию отправителя груза перевозчик может выдать ему несколько экземпляров (оригиналов) коносамента с указанием в каждом из них
 количества составленных экземпляров.</p><br>

<p style="text-indent:2em;">(2) После выдачи груза по одному из экземпляров остальные экземпляры коносамента теряют силу.</p><br>

<p style="font-weight:500;">Статья 145. Передача коносамента</p>

<p style="text-indent:2em;"></p><h6>Коносамент передается с соблюдением следующих правил:</h6><p></p>

<p style="text-indent:2em;">a) именной - по именным передаточным надписям или в иной форме, в соответствии с правилами, установленными для уступки долгового требования;</p><br>

<p>b) ордерный - по именным или бланковым передаточным надписям;</p><br>

<p>c) на предъявителя - посредством вручения.</p><br>

<p style="font-weight:500;">Статья 146. Право распоряжаться грузом</p><br>

<p style="text-indent:2em;">(1) Отправитель имеет право распоряжаться грузом до момента выдачи его получателю или передачи этого права получателю либо третьему лицу с
 уведомлением перевозчика о такой передаче.</p><br>

<p style="text-indent:2em;">(2) Отправитель вправе потребовать возвращения груза в месте его отправления до отхода судна, в промежуточном порту или выдачи груза не тому
 лицу, которое указано в коносаменте, при условии предъявления всех экземпляров коносамента или предоставления соответствующего обеспечения и
 соблюдения положений настоящего кодекса об отказе от договора о морской перевозке груза. Такое же право принадлежит и законному держателю всех
 выданных отправителю груза экземпляров коносамента.</p><br>

<p style="font-weight:500;">Статья 147. Тарирование, упаковка и маркировка груза</p><br>

<p style="text-indent:2em;">(1) Грузы, нуждающиеся в тарировании и упаковке для обеспечения их полной сохранности при перевозке и перевалке, должны предъявляться к перевозке в исправных таре и упаковке,
 соответствующих стандартам или техническим условиям. Указанные требования относятся и к предъявляемым отправителем контейнерам.</p><br>

<p style="text-indent:2em;">(2) Отправитель обязан надлежащим образом маркировать груз и представить перевозчику необходимые сведения о нем. Если груз требует особого обращения, отправитель должен
 проинформировать перевозчика о свойствах груза и порядке обращения с ним.</p><br>

<p style="font-weight:500;">Статья 148. Документы на груз</p><br>

<p style="text-indent:2em;">Отправитель должен своевременно передать перевозчику все документы на груз согласно портовым, таможенным, санитарным и иным административным требованиям и
 отвечает перед перевозчиком за убытки, причиненные из-за несвоевременной передачи или неправильного оформления этих документов.</p><br>

<p style="font-weight:500;">Статья 149. Мореходность судна</p><br>

<p style="text-indent:2em;">(1) Перевозчик обязан до начала рейса привести судно в мореходное состояние: обеспечить его техническую годность к плаванию, должным образом снарядить и снабдить
 всем необходимым, укомплектовать экипаж, привести трюмы и другие помещения, в которых перевозится груз, в состояние, обеспечивающее надлежащие прием, перевозку
 и сохранность груза.</p><br>

<p style="text-indent:2em;">(2) Перевозчик не несет ответственности за немореходное состояние судна, если докажет, что оно было обусловлено скрытыми недостатками судна, которые не могли быть
 обнаружены при проявлении им должной заботливости (далее - скрытые недостатки).</p><br>

<p>(3) Соглашение сторон о перевозке груза, противоречащее части (1), недействительно.</p><br>

<p style="font-weight:500;">Статья 150. Замена транспортного судна</p><br>

<p style="text-indent:2em;">Если груз должен быть перевезен на определенном судне, он может быть погружен на другое судно только с письменного согласия отправителя или фрахтователя,
 за исключением случаев перегрузки по технической необходимости, возникшей после начала погрузки.</p><br>

<p style="font-weight:500;">Статья 151. Расходы по погрузке</p><br>

<p style="text-indent:2em;">Отправитель или фрахтователь размещает груз вдоль борта судна за свой счет. Расходы по погрузке и укладке груза на судне несет перевозчик.</p><br>

<p style="font-weight:500;">Статья 152. Размещение груза на судне</p><br>

<p style="text-indent:2em;">(1) Груз размещается на судне по усмотрению капитана, но не может быть помещен на палубе без письменного согласия отправителя, за исключением грузов,
 перевозка которых на палубе допускается действующими правилами и нормами.</p><br>

<p style="text-indent:2em;">(2) Перевозчик несет ответственность за правильные размещение, крепление и сепарацию груза на борту судна. Указания перевозчика, касающиеся размещения,
 крепления и сепарации груза, обязательны для организаций и лиц, производящих грузовые работы.</p><br>

<p style="font-weight:500;">Статья 153. Уведомление о готовности судна к погрузке</p><br>

<p style="text-indent:2em;">(1) Если груз перевозится на основании чартера, перевозчик обязан подать судно в обусловленный договором срок. В противном случае фрахтователь вправе
отказаться от договора и потребовать возмещения убытков.</p><br>

<p style="text-indent:2em;">(2) При перевозке груза по чартеру перевозчик обязан письменно уведомить фрахтователя либо отправителя, если он указан фрахтователем в договоре,
 о том, что судно готово к погрузке или будет готово к определенному времени. Такое уведомление может быть подано только тогда, когда судно находится
 в порту погрузки или в обычном для этого порта месте ожидания.</p><br>

<p style="text-indent:2em;">(3) День и час подачи уведомления, предусмотренного частью (2), определяются сторонами по договоренности, а при отсутствии такой договоренности - в порядке,
 принятом в порту погрузки.</p><br>

<p style="text-indent:2em;">(4) Если судно не готово для приема груза ко времени, указанному в уведомлении, предусмотренном частью (2), такое уведомление считается неподанным,
 и перевозчик обязан возместить фрахтователю понесенные в связи с этим убытки.</p><br>

<p style="font-weight:500;">Статья 154. Сталийное время</p><br>

<p style="text-indent:2em;">(1) Сталийное время - срок, в течение которого перевозчик предоставляет фрахтователю судно и держит его под погрузкой, - определяется соглашением сторон,
 а при отсутствии такого соглашения - нормами, принятыми в порту погрузки.</p><br>

<p style="text-indent:2em;">(2) Сталийное время исчисляется в рабочих днях, часах и минутах, начиная со следующего дня после подачи уведомления о готовности судна к погрузке.
 В сталийное время не включается время, в течение которого погрузка не производилась по причинам, зависящим от перевозчика, или вследствие чрезвычайных
 обстоятельств, которые не могут быть предусмотрены и предотвращены, или из-за гидрометеорологических условий, угрожающих сохранности груза либо препятствующих
 безопасной погрузке. Время, в течение которого погрузка не производилась в связи с обстоятельствами, зависящими от фрахтователя, включается в сталийное время.</p><br>

<p style="text-indent:2em;">(3) Если погрузка началась до начала сталийного времени, фактически затраченное на нее время засчитывается в сталийное время.</p><br>

<p style="text-indent:2em;">(4) По соглашению сторон может быть установлено вознаграждение фрахтователю за окончание погрузки до истечения сталийного времени (диспач).
 При отсутствии такого соглашения размер диспача составляет половину платы за простой судна.</p><br>

<p style="font-weight:500;">Статья 155. Контрсталийное время</p><br>

<p style="text-indent:2em;">(1) По окончании сталийного времени стороны могут установить дополнительное время ожидания - контрсталийное время и размер платы, причитающейся
 перевозчику за простой судна в течение контрсталийного времени (демередж).</p><br>

<p style="text-indent:2em;">(2) Контрсталийное время исчисляется в календарных днях, часах и минутах, начиная с момента окончания сталийного времени. В контрсталийное время
 включаются воскресные дни и официально установленные дни праздников, время, объявленное в порту нерабочим, а также перерывы при погрузке,
 вызванные чрезвычайными обстоятельствами, которые не могут быть предусмотрены и предотвращены, или гидрометеорологическими условиями,
 угрожающими сохранности груза либо препятствующими безопасной погрузке. Время, в течение которого погрузка не производилась по причинам,
 зависящим от перевозчика, не включается в контрсталийное время.</p><br>

<p style="text-indent:2em;">(3) При отсутствии соглашения сторон продолжительность контрсталийного времени и размер демереджа определяются согласно срокам и ставкам,
 установленным в соответствующем порту. Если такие ставки не установлены, размер демереджа определяется исходя из расходов по содержанию судна
 и экипажа.</p><br>

<p style="font-weight:500;">Статья 156. Право перевозчика отправить судно в рейс по истечении контрсталийного времени</p><br>

<p style="text-indent:2em;">(1) Перевозчик имеет право по истечении контрсталийного времени отправить судно в плавание, даже если весь груз не погружен по причинам,
 не зависящим от него. При этом перевозчик сохраняет право на получение полного фрахта.</p><br>

<p style="text-indent:2em;">(2) В случае предоставления для перевозки груза всего судна перевозчик не вправе отказаться от приема груза, доставленного до окончания
 сталийного или контрсталийного времени, если стороны условились об этом, даже тогда, когда прием и укладка груза могут задержать судно
 сверх установленного срока.</p><br>

<p style="text-indent:2em;">(3) Если для перевозки груза предоставлено не все судно, перевозчик вправе до истечения сталийного или контрсталийного времени отказаться
 от приема груза, который из-за его предъявления с опозданием можно погрузить надлежащим образом и без вреда для остального груза не иначе
 как с задержкой судна. При этом перевозчик сохраняет право на получение полного фрахта.</p><br>

<p style="font-weight:500;">Статья 157. Удаление постороннего груза</p><br>

<p style="text-indent:2em;">Если зафрахтованы часть судна или отдельные судовые помещения и фрахтователь обнаружил в них посторонний груз, он может потребовать удаления
 этого груза в порту отправления, если же зафрахтовано все судно, - в любом порту захода. Если посторонний груз не был своевременно удален с
 борта судна, фрахтователь вправе потребовать соответствующего уменьшения фрахта, а также возмещения причиненных ему убытков.</p><br>

<p style="font-weight:500;">Статья 158. Удаление опасного груза</p><br>

<p style="text-indent:2em;">(1) Легко воспламеняющийся, взрывчатый или в ином отношении опасный по своей природе груз, сданный под неправильным или неполным наименованием,
 при приеме которого перевозчик не мог посредством наружного осмотра обнаружить его опасные свойства, может быть в любое время, в зависимости
 от обстоятельств, удален с судна либо уничтожен (обезврежен) перевозчиком без возмещения отправителю связанных с этим убытков.</p><br>

<p>(2) Отправитель отвечает за все убытки, причиненные в связи с перевозкой груза, указанного в части (1).</p><br>

<p style="text-indent:2em;">(3) Фрахт за перевозку груза, указанного в части (1), не возвращается. Если же фрахт не был уплачен, перевозчик может взыскать его полностью.</p><br>

<p style="text-indent:2em;">(4) Если опасный по своей природе груз был погружен с ведома и согласия перевозчика и в период перевозки стал представлять угрозу для судна,
 другого груза или находящихся на судне людей, перевозчик вправе поступить с ним в порядке, предусмотренном частью (1). При этом перевозчик не
 возмещает отправителю понесенных убытков, за исключением убытков по общей аварии, и имеет право на фрахт в размере, пропорциональном расстоянию,
 фактически пройденному судном с опасным грузом на борту.</p><br>

<p style="font-weight:500;">Статья 159. Отправление судна по требованию фрахтователя</p><br>

<p style="text-indent:2em;">При фрахтовании всего судна по требованию фрахтователя перевозчик обязан отправить судно в плавание, даже если не весь груз погружен.
 В этом случае перевозчик сохраняет право на полный фрахт, установленный договором.</p><br>

<p style="font-weight:500;">Статья 160. Отказ от договора</p>

<p style="text-indent:2em;"></p><h6>(1) Каждая из сторон вправе до отхода судна отказаться от договора о морской перевозке груза без возмещения другой стороне связанных
 с этим убытков в случае:</h6><p></p>

<p>a) объявления военных либо иных действий, при которых возникает опасность захвата судна и/или груза;</p><br>

<p>b) блокады порта отправления или порта назначения;</p><br>

<p>c) привлечения судна для специальных нужд государства;</p><br>

<p style="text-indent:2em;">d) задержания судна согласно распоряжению соответствующих властей по причинам, не зависящим от сторон договора;</p><br>

<p style="text-indent:2em;">e) запрещения соответствующими властями вывоза из порта отправления или ввоза в порт назначения груза, принятого к перевозке.</p><br>

<p style="text-indent:2em;">(2) Случаи, предусмотренные пунктами d) и e) части (1), не могут служить основанием для отказа от договора о морской перевозке груза
 без возмещения другой стороне убытков, если предвидится кратковременная задержка.</p><br>

<p>(3) В случаях, предусмотренных частью (1), перевозчик не несет расходов по выгрузке.</p><br>

<p style="text-indent:2em;">(4) При наступлении какого-либо из событий, перечисленных в части (1), каждая из сторон может отказаться от договора и во время рейса.
 В этом случае отправитель или фрахтователь возмещает перевозчику все понесенные им расходы по транспортировке груза, в том числе расходы
 по выгрузке, и уплачивает фрахт в размере, пропорциональном расстоянию, фактически пройденному судном с грузом на борту.</p><br>

<p style="font-weight:500;">Статья 161. Прекращение действия договора без отказа сторон</p>

<p style="text-indent:2em;"></p><h6>(1) Действие договора о морской перевозке груза прекращается без отказа сторон и без обязанности одной из них возместить другой
 обусловленные прекращением действия договора убытки, если до отхода судна из места погрузки вследствие обстоятельств,
 не зависящих от сторон:</h6><p></p>

<p>a) судно погибнет или будет захвачено;</p><br>

<p>b) судно будет признано негодным к плаванию;</p><br>

<p>c) погибнет конкретно определенный груз;</p><br>

<p style="text-indent:2em;">d) погибнет груз, определяемый родовыми признаками, после сдачи его для погрузки, а отправитель не успеет сдать вместо
 погибшего другой груз.</p><br>

<p style="text-indent:2em;">(2) Действие договора о морской перевозке груза прекращается по указанным в части (1) причинам и во время рейса.
 В этом случае перевозчику причитается фрахт в размере, пропорциональном фактической дальности перевозки, с учетом
 количества спасенного и сданного груза</p><br>

<p style="font-weight:500;">Статья 162. Препятствия для захода в порт</p><br>

<p style="text-indent:2em;">(1) Если вследствие запрещения соответствующих властей, стихийных явлений или по иным причинам, не зависящим от перевозчика,
 судно не может войти в порт назначения, перевозчик обязан немедленно уведомить об этом отправителя, или фрахтователя, или лицо,
 правомочное распоряжаться грузом, если оно известно.</p><br>

<p style="text-indent:2em;">(2) Если для перевозки груза было предоставлено все судно и в течение достаточного срока с момента отправки уведомления о том,
 что судно не может войти в порт назначения, не поступит распоряжение отправителя, или фрахтователя, или лица, правомочного распоряжаться грузом,
 относительно того, как поступить с этим грузом, капитан судна по своему усмотрению вправе оставить груз в одном из ближайших портов либо возвратить
 его в порт отправления, в зависимости от того, что, по мнению капитана, выгоднее для фрахтователя или лица, правомочного распоряжаться грузом.</p><br>

<p style="text-indent:2em;">(3) Если зафрахтована часть судна, капитан должен выгрузить груз, который не может быть доставлен в порт назначения, в другом порту в соответствии
 с распоряжением отправителя, или фрахтователя, или лица, правомочного распоряжаться грузом. При неполучении такого распоряжения в течение трех
 календарных дней с момента отправки уведомления, указанного в части (2), капитан имеет право оставить груз в одном из ближайших портов по своему
 усмотрению, сообщив об этом отправителю, или фрахтователю, или лицу, правомочному распоряжаться грузом. Капитан судна вправе поступить таким же
 образом и в случае, если выполнение полученного распоряжения может нанести ущерб интересам владельцев других находящихся на борту судна грузов.</p><br>

<p style="text-indent:2em;">(4) Перевозчик имеет право на возмещение расходов, связанных с ожиданием распоряжения отправителя, или фрахтователя, или лица, правомочного
 распоряжаться грузом, в течение достаточного срока, расходов по грузу, а также на фрахт в размере, пропорциональном фактической дальности
 перевозки груза.</p><br>

<p style="font-weight:500;">Статья 163. Срок и маршрут перевозки груза</p><br>

<p style="text-indent:2em;">(1) Перевозчик обязан доставить груз в определенный сторонами договора срок намеченным маршрутом. Если же срок и маршрут перевозки не установлены,
 он транспортирует груз обычным маршрутом в общепринятый срок.</p><br>

<p style="text-indent:2em;">(2) Не считается нарушением условий договора о морской перевозке груза любое отклонение судна от намеченного пути, совершенное в целях спасания
 на море людей, судов и грузов, а также иное обоснованное отклонение от маршрута, если оно не вызвано неправильными действиями перевозчика.</p><br>

<p style="font-weight:500;">Статья 164. Выгрузка груза</p><br>

<p style="text-indent:2em;">(1) При выгрузке груза в порту назначения применяются те же правила, что и при погрузке, установленные статьями 154 и 155.</p><br>

<p>(2) Расходы по выгрузке груза и размещению его вдоль борта судна несет перевозчик.</p><br>

<p style="font-weight:500;">Статья 165. Выдача груза</p>

<p style="text-indent:2em;"></p><h6>(1) Груз выдается в порту назначения:</h6><p></p>

<p style="text-indent:2em;">a) по именному коносаменту - получателю, указанному в коносаменте, или лицу, которому коносамент был передан по именной передаточной надписи
 либо в иной форме, с соблюдением правил, установленных для уступки долгового требования;</p><br>

<p style="text-indent:2em;">b) по ордерному коносаменту - отправителю или получателю, в зависимости от того, как составлен коносамент, - по "приказу отправителя" или по
 "приказу получателя", а при наличии в коносаменте передаточных надписей - лицу, указанному в последней из н епрерывного ряда передаточных надписей,
 или предъявителю коносамента с последней бланковой надписью;</p><br>

<p>c) по коносаменту на предъявителя - предъявителю коносамента.</p><br>

<p style="text-indent:2em;">(2) Если была выдана морская накладная или иной подобный документ, перевозчик может выдать груз только получателю,
 указанному в этом документе, либо получателю, указанному отправителем.</p><br>

<p style="font-weight:500;">Статья 166. Платежи, вносимые при получении груза. Право удерживания груза</p><br>

<p style="text-indent:2em;">(1) Получатель при выдаче ему груза обязан возместить перевозчику расходы, связанные с транспортировкой груза, внести плату за простой судна
 в порту выгрузки и, если это было предусмотрено в коносаменте или другом документе, на основании которого перевозился груз, - фрахт и плату
 за простой судна в порту погрузки, а в случае общей аварии - внести аварийный взнос или предоставить надлежащее обеспечение.</p><br>

<p style="text-indent:2em;">(2) Перевозчик может не выдавать груз до внесения платежей, указанных в части (1). Он сохраняет право удерживания груза и в случае сдачи его
 на склад, не принадлежащий получателю, при условии уведомления о таком праве владельца склада.</p><br>

<p style="text-indent:2em;">(3) После выдачи груза получателю перевозчик утрачивает право требования от отправителя или фрахтователя платежей, не внесенных получателем.
 Исключение составляют случаи, когда перевозчик не смог осуществить право удерживания груза по не зависящим от него причинам.</p><br>

<p style="font-weight:500;">Статья 167. Право залога груза</p><br>

<p style="text-indent:2em;">(1) Для обеспечения выполнения требования части (1) статьи 166 перевозчик имеет право заложить перевезенный груз.</p>

<p style="text-indent:2em;"></p><h6>(2) Право залога груза прекращается в случаях:</h6><p></p>

<p>a) выдачи груза получателю;</p><br>

<p>b) удовлетворения обеспеченных залогом требований перевозчика;</p><br>

<p>c) принятия перевозчиком иного надлежащего обеспечения.</p><br>

<p style="text-indent:2em;">(3) Перевозчик вправе в порядке, предусмотренном законодательством, продать груз, являющийся предметом залога, предварительно уведомив 
об этом отправителя или фрахтователя и получателя груза. В таком случае расходы перевозчика, указанные в части (1) статьи 166, возмещаются
 из суммы, вырученной от реализации груза, после оплаты судебных издержек и расходов по хранению и продаже груза.</p><br>

<p style="text-indent:2em;">(4) Если суммы, вырученной от реализации груза, недостаточно для возмещения расходов перевозчика, указанных в части (1) статьи 166,
 перевозчик вправе взыскать недостающую сумму с отправителя груза или фрахтователя.</p><br>

<p style="font-weight:500;">Статья 168. Осмотр и проверка количества груза</p><br>

<p style="text-indent:2em;">До выдачи груза его получатель и перевозчик имеют право потребовать осмотра или проверки количества груза. Связанные с этим расходы
 несет лицо, которое потребовало осмотра или проверки.</p><br>

<p style="font-weight:500;">Статья 169. Заявление об утрате, недостаче или повреждении груза</p><br>

<p style="text-indent:2em;">(1) Если при приеме груза, перевезенного по коносаменту, получатель письменно не заявил перевозчику о выявленных утрате, недостаче
 или повреждении груза, считается, если иное не будет доказано, что груз получен в соответствии с условиями коносамента.</p><br>

<p style="text-indent:2em;">(2) Если груз при его приеме был осмотрен или проверен получателем вместе с перевозчиком, получатель может не делать указанного
 в части (1) заявления.</p><br>

<p style="text-indent:2em;">(3) В случае, если утрату, недостачу или повреждение груза нельзя было обнаружить при обычном способе приема, соответствующее
 заявление перевозчику может быть сделано получателем в течение трех календарных дней после приема груза.</p><br>

<p>(4) Соглашение относительно приема груза, противоречащее настоящей статье, считается недействительным.</p><br>

<p style="font-weight:500;">Статья 170. Сдача невостребованного груза на хранение</p><br>

<p style="text-indent:2em;">(1) Если для перевозки груза было предоставлено не все судно и в порту назначения получатель не востребовал груз, не распорядился
 им или отказался от него, перевозчик вправе, уведомив об этом отправителя, сдать груз на хранение на склад или в иное надежное место.</p><br>

<p style="text-indent:2em;">(2) При предоставлении для перевозки груза всего судна выгрузка и сдача груза на хранение производятся капитаном судна лишь по
 истечении сталийного и контрсталийного времени, если за это время от отправителя, или фрахтователя, или лица, правомочного распоряжаться грузом,
 не поступит иное распоряжение. Время, затраченное на сдачу груза на хранение, рассматривается как простой судна.</p><br>

<p style="text-indent:2em;">(3) Если в течение двух месяцев со дня прихода судна в порт сданный на хранение груз не будет востребован и отправитель, или фрахтователь,
 или лицо, правомочное распоряжаться грузом, не уплатит перевозчику причитающихся сумм, перевозчик вправе продать груз. Невостребованный
 скоропортящийся груз может быть продан и до истечения указанного срока.</p><br>

<p style="text-indent:2em;">(4) В морском порту сроки и порядок хранения грузов до приема их получателями, а также порядок продажи не востребованных
 получателями грузов определяются правилами, установленными центральным отраслевым органом.</p><br>

<p style="font-weight:500;">Статья 171. Продажа невостребованного и сданного на хранение груза</p><br>

<p style="text-indent:2em;">(1) Сумма, вырученная от продажи груза, указанного в части (3) статьи 170, за вычетом суммы, причитающейся перевозчику,
 зачисляется на счет перевозчика для выдачи отправителю или фрахтователю. Если полученная от реализации груза сумма окажется недостаточной
 для покрытия причитающихся перевозчику платежей и расходов по хранению и продаже груза, перевозчик вправе взыскать недостающую сумму
 с отправителя или фрахтователя.</p><br>

<p style="text-indent:2em;">(2) Если в течение шести месяцев со дня продажи груза ни одно юридическое или физическое лицо не заявит о своих правах на соответствующую сумму,
 вырученную от продажи, эта сумма, за вычетом причитающихся перевозчику платежей, поступает в доход бюджета Республики Молдова.
 При продаже невостребованного груза без документов вырученная сумма обращается в доход перевозчика для покрытия убытков, понесенных
 вследствие удовлетворения требований, связанных с утратой груза.</p><br>

<p style="font-weight:500;">Статья 172. Внесение платежей при перевозке груза Все причитающиеся перевозчику платежи вносятся отправителем груза или фрахтователем.</p><br>

<p style="text-indent:2em;">В случаях, предусмотренных соглашением между отправителем груза или фрахтователем и перевозчиком, при условии включения этого соглашения в коносамент, оплата производится получателем груза.</p><br>

<p style="font-weight:500;">Статья 173. Размер фрахта</p><br>

<p style="text-indent:2em;">(1) Размер фрахта устанавливается соглашением сторон, при отсутствии такого соглашения - исчисляется исходя из ставок,
 применяемых в месте и во время погрузки груза.</p><br>

<p style="text-indent:2em;">(2) Если на судно погружен груз в количестве большем, чем предусмотрено договором о перевозке, размер фрахта соответственно
 увеличивается.</p><br>

<p style="text-indent:2em;">(3) Если вместо предусмотренного договором о перевозке на судно погружен другой груз, плата за транспортировку которого больше,
 чем установленная договором, вносится плата за перевозку фактически погруженного груза.</p><br>

<p style="text-indent:2em;">(4) В случае, если плата за перевозку фактически погруженного на судно груза меньше, чем плата за перевозку груза,
 предусмотренного договором, вносится фрахт, установленный договором.</p><br>

<p style="font-weight:500;">Статья 174. Обеспечение уплаты фрахта</p><br>

<p style="text-indent:2em;">Если стоимость погруженного на судно груза не покрывает размера фрахта и других издержек перевозчика по грузу,
 а отправитель или фрахтователь не внес фрахт полностью и не предоставил дополнительного обеспечения, перевозчик имеет
 право до отправления судна отказаться от договора и потребовать уплаты половины оговоренного фрахта, внесения платы за простой,
 если он имел место, а также возмещения других расходов, понесенных по грузу. В таких случаях выгрузка производится
 за счет отправителя груза или фрахтователя</p><br>

<p style="font-weight:500;">Статья 175. Уплата фрахта при отказе отправителя груза или фрахтователя от договора, если зафрахтовано все судно</p>

<p style="text-indent:2em;"></p><h6>(1) Если для перевозки груза предоставлено все судно, отправитель или фрахтователь вправе отказаться от договора, но обязан внести:</h6><p></p>

<p style="text-indent:2em;">a) половину полного фрахта, плату за неоправданный простой, если он имел место, и часть израсходованных до этого момента перевозчиком
 по грузу сумм, не включенных в сумму фрахта, если отказ от договора последовал до истечения сталийного или контрсталийного времени либо
 до отправления судна в плавание, в зависимости от того, какой из указанных моментов наступит ранее;</p><br>

<p style="text-indent:2em;">b) полный фрахт и другие суммы, указанные в пункте a), если отказ от договора последовал после одного из моментов, предусмотренных
 этим пунктом, и договор был заключен на один рейс;</p><br>

<p style="text-indent:2em;">c) полный фрахт за первый рейс и другие суммы, указанные в пункте a), а также половину фрахта за остальные рейсы, если отказ от договора
 последовал после одного из моментов, предусмотренных этим пунктом, и договор был заключен на несколько рейсов.</p><br>

<p style="text-indent:2em;">(2) При отказе отправителя или фрахтователя от договора о морской перевозке груза до выхода судна в рейс перевозчик обязан выдать
 груз даже в случае, если выгрузка может задержать судно более установленного срока.</p><br>

<p style="text-indent:2em;">(3) Отправитель или фрахтователь, отказавшийся от договора о морской перевозке груза во время рейса, вправе потребовать выдачи
 груза только в порту назначения, определенном договором, или в порту, в который судно зашло в силу необходимости.</p><br>

<p style="font-weight:500;">Статья 176. Уплата фрахта при отказе отправителя груза или фрахтователя от договора, если зафрахтована часть судна</p><br>

<p style="text-indent:2em;">(1) В случае фрахтования части судна отправитель груза или фрахтователь может отказаться от договора лишь при условии уплаты фрахта
 полностью, внесения платы за простой, если он имел место, и возмещения понесенных перевозчиком расходов по грузу, не включенных в сумму фрахта.</p><br>

<p style="text-indent:2em;">(2) По требованию отправителя или фрахтователя перевозчик обязан выдать груз до его доставки в порт назначения только
 в случае, если этим не будет нанесен ущерб интересам перевозчика и других отправителей грузов или фрахтователей.</p><br>

<p style="font-weight:500;">Статья 177. Уплата фрахта при утрате груза</p><br>

<p>(1) В случае утраты груза при перевозке фрахт не взимается, а предварительно внесенный фрахт возвращается.</p>

<p style="text-indent:2em;"></p><h6>(2) Если груз был утрачен, но впоследствии спасен, перевозчик имеет право на фрахт в размере, пропорциональном расстоянию,
 пройденному судном с грузом. В таких случаях при исчислении фрахта учитываются:</h6><p></p>

<p style="text-indent:2em;">a) соотношение части пути, пройденного судном с грузом, и протяженности всего пути, обусловленного договором;</p><br>

<p style="text-indent:2em;">b) соотношение расходов, затрат времени, труда, а также опасностей, связанных с пройденным расстоянием, и тех расходов,
 затрат, опасностей, которые обычно приходятся на оставшуюся часть пути.</p><br>

<p style="text-indent:2em;">(3) При гибели или повреждении груза вследствие его естественных свойств либо обстоятельств,
 зависящих от отправителя или фрахтователя, фрахт взимается полностью.</p><br>

<p style="font-weight:500;">Статья 178. Ответственность отправителя груза и фрахтователя</p><br>

<p style="text-indent:2em;">(1) Отправитель груза и фрахтователь обязаны возместить перевозчику убытки, понесенные по их вине
 или по вине лиц, за действия которых они отвечают.</p><br>

<p style="text-indent:2em;">(2) Отправитель, не являющийся одной из сторон договора о перевозке груза, отвечает за убытки,
 понесенные по его вине перевозчиком.</p><br>

<p style="font-weight:500;">Статья 179.Ответственность перевозчика за груз и основания для освобождения от такой ответственности</p><br>

<p style="text-indent:2em;">(1) Перевозчик отвечает за утрату, недостачу, повреждение груза, а также за просрочку в его доставке с
 момента принятия груза к перевозке до момента его выдачи.</p>

<p style="text-indent:2em;"></p><h6>(2) Перевозчик не несет ответственности за утрату, недостачу, повреждение принятого к перевозке груза,
 за просрочку в его доставке, если докажет, что они произошли вследствие:</h6><p></p>

<p>а) чрезвычайных обстоятельств, которые не могут быть предусмотрены и предотвращены;</p><br>

<p>b) опасностей, возникших не по его вине, и случайностей на море или в других судоходных водах;</p><br>

<p>c) пожара, возникшего не по его вине;</p><br>

<p>d) спасания на море людей, судов и грузов;</p><br>

<p style="text-indent:2em;">e) действий или распоряжений соответствующих властей (задержание, арест, карантин и т.д.) - при отсутствии его вины;</p><br>

<p>f) военных действий, террористических актов, народных волнений;</p><br>

<p>g) небрежности или упущений отправителя либо получателя груза;</p><br>

<p>h) скрытых недостатков судна;</p><br>

<p>i) скрытых недостатков груза, его природных свойств или естественной убыли;</p><br>

<p>j) скрытых недостатков тары и упаковки груза;</p><br>

<p>k) недостаточной или неясной маркировки груза;</p><br>

<p>l) действий по предотвращению загрязнения окружающей среды;</p><br>

<p>m) иных обстоятельств, возникших не по его вине, а также не по вине его работников или агентов.</p>

<p style="text-indent:2em;"></p><h6>(3) Перевозчик не отвечает за недостачу груза, прибывшего в порт назначения:</h6><p></p>

<p>a) в исправных грузовых помещениях, лихтерах, контейнерах с неповрежденными пломбами отправителя;</p><br>

<p>b) в целой и исправной таре без следов ее вскрытия в пути;</p><br>

<p style="text-indent:2em;">c) в сопровождении представителя отправителя или получателя, за исключением случаев, когда получатель докажет, что в недостаче груза виновен перевозчик.</p><br>

<p style="font-weight:500;">Статья 180. Навигационная ошибка</p><br>

<p style="text-indent:2em;">Перевозчик не несет ответственности за утрату, недостачу, повреждение груза, за просрочку в его доставке, если докажет,
 что они произошли вследствие действий или упущений капитана судна, членов экипажа либо лоцмана в процессе судовождения
 или управления судном.</p><br>

<p style="font-weight:500;">Статья 181. Возмещение ущерба, причиненного утратой, недостачей или повреждением груза</p>

<p style="text-indent:2em;"></p><h6>(1) За ущерб, причиненный в результате утраты, недостачи или повреждения груза, перевозчик несет ответственность в следующих размерах:</h6><p></p>

<p>a) при утрате или недостаче груза - в размере фактической стоимости утраченного или недостающего груза;</p><br>

<p>b) при повреждении груза - в размере суммы, на которую понизилась общая стоимость груза.</p><br>

<p style="text-indent:2em;">(2) Перевозчик возвращает также полученный им фрахт, если он не входит в стоимость утраченного или недостающего груза.</p><br>

<p style="font-weight:500;">Статья 182. Определение стоимости утраченного, недостающего или поврежденного груза</p><br>

<p style="text-indent:2em;">(1) Фактическая стоимость утраченного или поврежденного груза исчисляется исходя из его стоимости в порту назначения на день
 реального или предполагавшегося захода в него судна, а при невозможности установить ее - исходя из стоимости груза в порту
 отправления на день выхода из него судна, к которой прибавляются расходы по перевозке груза.</p><br>

<p style="text-indent:2em;">(2) Из суммы, подлежащей возмещению при утрате, недостаче или повреждении груза, вычитаются расходы по перевозке утраченного
 груза, его недостающей или поврежденной части (фрахт, пошлины и другие), которые должны были быть произведены владельцем груза,
 но вследствие утраты, недостачи или повреждения груза произведены не были.</p><br>

<p style="font-weight:500;">Статья 183. Ограничение ответственности перевозчика</p><br>

<p style="text-indent:2em;">(1) Если стоимость груза не была объявлена и не включена в коносамент, ответственность перевозчика не может превышать 666,67
 расчетной единицы за одно место или за другую единицу отгрузки либо двух расчетных единиц за один килограмм массы брутто утраченного,
 недостающего или поврежденного груза, в зависимости от того, какая сумма больше; при этом стоимость контейнеров,
 иных транспортных приспособлений отправителя груза или фрахтователя, которая определяется согласно статье 184, не учитывается.</p><br>

<p>(2) Ответственность перевозчика за просрочку в доставке груза не может превышать размера фрахта.</p><br>

<p style="text-indent:2em;">(3) Совокупная ответственность перевозчика на основании частей (1) и (2) не может превышать предела ответственности,
 который мог бы быть установлен согласно части (1) при полной утрате груза, в отношении которого возникла такая ответственность.</p><br>

<p style="text-indent:2em;">(4) Если в коносаменте указано число мест или других единиц отгрузки, помещенных в контейнер либо иное транспортное приспособление,
 количество мест или других единиц отгрузки принимается в соответствии с коносаментом. Если же в коносаменте нет такого указания,
 контейнер либо иное транспортное приспособление считаются одним местом или другой единицей отгрузки.</p><br>

<p style="text-indent:2em;">(5) Перевозчик не имеет права на ограничение ответственности, предусмотренное частями (1) и (2), если доказано, что утрата, недостача,
 повреждение груза или просрочка в его доставке стали результатом его действий или бездействия, совершенных умышленно,
 либо по самонадеянности, с сознанием возможности нанесения ущерба, либо по грубой неосторожности.</p><br>

<p style="text-indent:2em;">(6) Служащий или агент перевозчика не вправе воспользоваться положениями настоящей статьи, если доказано, что утрата,
 недостача, повреждение груза или просрочка в его доставке стали результатом действий или бездействия этого служащего или агента,
 совершенных умышленно, либо по самонадеянности, с сознанием возможности нанесения ущерба, либо по грубой неосторожности.</p><br>

<p style="font-weight:500;">Статья 184. Ответственность перевозчика за утрату, повреждение контейнера или иного транспортного приспособления</p>

<p style="text-indent:2em;"></p><h6>За утрату, повреждение контейнера или другого транспортного приспособления отправителя либо фрахтователя перевозчик несет ответственность в следующих размерах:</h6><p></p>

<p style="text-indent:2em;">a) за утрату контейнера или иного транспортного приспособления - в размере их фактической стоимости с учетом износа на момент утраты;</p><br>

<p style="text-indent:2em;">b) за повреждение контейнера или иного транспортного приспособления - в размере стоимости их ремонта в порту назначения,
 если ремонт не будет выполнен перевозчиком самостоятельно или за свой счет до момента их сдачи получателю.</p><br>

<p style="font-weight:500;">Статья 185. Недействительность соглашения об освобождении перевозчика от ответственности или ее ограничении</p><br>

<p style="text-indent:2em;">(1) Если перевозка груза осуществляется на основании коносамента, любое соглашение об освобождении перевозчика от ответственности
 или ее ограничении на иных условиях, чем это предусмотрено статьями 179 и 183, признается недействительным.</p><br>

<p style="text-indent:2em;">(2) Если коносамент выдан в соответствии с чартером, часть (1) применяется с момента, когда коносамент был передан третьему лицу.</p>

<p style="text-indent:2em;"></p><h6>(3) Действие части (1) не распространяется на соглашения, заключенные относительно:</h6><p></p>

<p style="text-indent:2em;">a) ответственности перевозчика с момента принятия груза до его погрузки на судно и после выгрузки груза до его сдачи;</p><br>

<p>b) перевозки грузов и живых животных на палубе;</p><br>

<p style="text-indent:2em;">c) перевозки грузов на особых условиях, когда род, вид и состояние груза либо обстоятельства, сроки и условия, при которых
 должна выполняться перевозка, оправдывают заключение специального соглашения, если при этом не выдается коносамент и условия
 перевозки включены в документ, не являющийся товарораспорядительным и содержащий указание на это.</p>

<p></p><h4>Глава 3 ДОГОВОР О МОРСКОЙ ПЕРЕВОЗКЕ ПАССАЖИРА</h4><p></p>

<p style="font-weight:500;">Статья 186. Понятие договора о морской перевозке пассажира</p><br>

<p style="text-indent:2em;">(1) В соответствии с договором о морской перевозке пассажира перевозчик обязуется перевезти в порт назначения пассажира и
 его каютный багаж, а также другой сданный пассажиром багаж и выдать его правомочному на получение багажа лицу. Со своей
 стороны, пассажир обязуется внести плату за проезд, а при сдаче багажа - и плату за его провоз.</p><br>

<p style="text-indent:2em;">(2) Перевозка пассажира охватывает период, на протяжении которого пассажир и его каютный багаж находятся на борту судна,
 время посадки и высадки, а также время, в течение которого пассажир и его каютный багаж доставляются водным путем с берега
 на судно либо с судна на берег, если стоимость такой перевозки включена в стоимость билета или если судно, используемое
 для этой перевозки, предоставлено перевозчиком.</p><br>

<p style="text-indent:2em;">(3) Перевозка багажа, который не является каютным, охватывает период с момента принятия багажа перевозчиком, его служащим
 или агентом на берегу или на борту судна до момента выдачи багажа в установленном порядке в порту назначения.</p><br>

<p style="font-weight:500;">Статья 187. Сфера применения настоящей главы</p>

<p style="text-indent:2em;"></p><h6>(1) Положения настоящей главы применяются при перевозке пассажиров и их багажа, если:</h6><p></p>

<p>a) судно плавает под флагом Республики Молдова;</p><br>

<p>b) договор о морской перевозке пассажира заключен на территории Республики Молдова;</p><br>

<p style="text-indent:2em;">c) в соответствии с договором место отправления судна или место назначения находится на территории Республики Молдова;</p><br>

<p>d) соглашением сторон не установлены иные условия перевозки.</p><br>

<p style="text-indent:2em;">(2) Соглашение сторон, ограничивающее права пассажира, предусмотренные настоящей главой, недействительно.</p><br>

<p style="font-weight:500;">Статья 188. Недействительность условий договора</p><br>

<p style="text-indent:2em;">Любое условие, включенное в договор о морской перевозке пассажира до происшествия, которое стало причиной смерти пассажира
 или нанесения вреда его здоровью, утраты или повреждения его багажа, имеющее целью освобождение перевозчика от ответственности
 перед пассажиром или установление меньшего, чем определенный статьей 196, предела ответственности, а также любое условие,
 оговаривающее освобождение перевозчика от бремени доказательства, являются недействительными с момента их формулирования.
 Но недействительность соответствующего условия не влечет за собой недействительности договора в части, которая основывается
 на положениях настоящей главы</p><br>

<p style="font-weight:500;">Статья 189. Доказательство заключения договора</p><br>

<p style="text-indent:2em;">Доказательством заключения договора о морской перевозке пассажира и оплаты пассажиром стоимости проезда служит выданный
 ему перевозчиком именной билет. Сдача перевозчику багажа удостоверяется багажной квитанцией.</p><br>

<p style="font-weight:500;">Статья 190. Отказ пассажира от договора</p><br>

<p style="text-indent:2em;">(1) Пассажир вправе отказаться от договора о морской перевозке в любое время до отхода судна, а после начала рейса
 - в любом порту, в который судно зайдет для посадки или высадки пассажиров.</p><br>

<p style="text-indent:2em;">(2) Уведомивший перевозчика об отказе от договора пассажир имеет право на возвращение внесенной платы за проезд
 и провоз багажа в порядке, размере и в сроки, установленные правилами морской перевозки пассажиров, утверждаемыми
 центральным отраслевым органом.</p>

<p style="text-indent:2em;"></p><h6>(3) Пассажиру возвращается вся внесенная им плата за проезд и провоз багажа, если он:</h6><p></p>

<p style="text-indent:2em;">a) отказался от договора: - не позднее срока, установленного правилами морской перевозки пассажиров, или - до
 отхода судна вследствие болезни (с предъявлением соответствующего документа), или - по причинам, зависящим от
 перевозчика;</p><br>

<p>b) не явился к отходу судна вследствие болезни.</p><br>

<p style="font-weight:500;">Статья 191. Основания для досрочного прекращения действия договора</p><br>

<p style="text-indent:2em;">(1) Перевозчик вправе отказаться от договора о морской перевозке пассажира при возникновении не зависящих от него обстоятельств,
 указанных в пунктах a) - d) части (1) статьи 160 и пунктах a) и b) части (1) статьи 161. При отказе перевозчика от договора до
 отхода судна пассажиру возвращается вся плата за проезд и провоз багажа, а при расторжении договора после начала рейса - часть
 указанной платы в размере, пропорциональном расстоянию, перевозка на которое не состоялась</p><br>

<p style="text-indent:2em;">(2) Перевозчик вправе задержать отход судна, изменить маршрут перевозки, место посадки и/или высадки пассажира, если это
 необходимо в связи с явлениями стихийного характера, неблагоприятными санитарно-эпидемиологическими условиями в порту отправления,
 порту назначения или на пути следования судна, а также в связи с другими явлениями и событиями, не зависящими от перевозчика и
 делающими невозможным выполнение договора о морской перевозке пассажира.</p><br>

<p style="text-indent:2em;">(3) В случаях, предусмотренных настоящей статьей, перевозчик обязан за свой счет доставить пассажира по его требованию в
 порт отправления либо возместить убытки, понесенные пассажиром вследствие прекращения действия договора.</p><br>

<p style="font-weight:500;">Статья 192. Права пассажира</p>

<p style="text-indent:2em;"></p><h6>(1) Пассажир имеет право в порядке, установленном правилами морской перевозки пассажиров:</h6><p></p>

<p>a) перевозить с собой детей бесплатно или на льготных условиях;</p><br>

<p>b) провозить каютный багаж в пределах установленных норм бесплатно;</p><br>

<p>c) сдавать на хранение багаж за плату.</p>

<p style="text-indent:2em;"></p><h6>(2) В случае утраты или повреждения багажа пассажир направляет письменное уведомление на имя перевозчика или его агента:</h6><p></p>

<p>a) при явном повреждении каютного багажа - до или во время высадки;</p><br>

<p>b) при явном повреждении сданного на хранение багажа - до или во время его выдачи;</p><br>

<p style="text-indent:2em;">c) при утрате сданного на хранение багажа или неявном его повреждении - в течение пятнадцати дней со дня высадки или
 со дня выдачи багажа либо с того дня, когда он должен был быть выдан.</p><br>

<p style="text-indent:2em;">(3) Если пассажир не направил перевозчику уведомления, указанного в части (2), то предполагается,
 поскольку не доказано противоположное, что пассажир получил свой багаж неповрежденным.</p><br>

<p style="text-indent:2em;">(4) Если состояние сданного на хранение багажа было совместно проверено сторонами в момент его получения,
 то письменного уведомления о повреждении не требуется.</p><br>

<p style="text-indent:2em;">(5) Багаж, сданный на хранение и не востребованный в течение трех месяцев со дня прихода судна в порт назначения,
 может быть продан в установленном порядке.</p><br>

<p style="font-weight:500;">Статья 193. Страхование пассажира</p><br>

<p style="text-indent:2em;">Пассажир, заключивший договор о морской перевозке, подлежит обязательному страхованию от несчастного случая в соответствии
 с законодательством Республики Молдова. Уплачиваемая пассажиром страховая премия входит в стоимость проездного билета.</p><br>

<p style="font-weight:500;">Статья 194. Мореходность судна</p><br>

<p style="text-indent:2em;">Перевозчик обязан до начала перевозки пассажиров привести судно в мореходное состояние: обеспечить безопасность плавания,
 надлежащим образом снарядить судно и снабдить его всем необходимым, укомплектовать экипаж - и содержать судно в таком
 состоянии во время перевозки.</p><br>

<p style="font-weight:500;">Статья 195. Ответственность перевозчика</p><br>

<p style="text-indent:2em;">(1) Перевозчик отвечает за ущерб, причиненный в результате смерти или нанесения вреда здоровью пассажира, утраты или повреждения
 его багажа, если происшествие, следствием которого стал этот ущерб, произошло во время морской перевозки пассажира по вине или
 из-за небрежности перевозчика, его служащих либо агентов, находившихся при исполнении служебных обязанностей.</p><br>

<p style="text-indent:2em;">(2) За утрату или повреждение каютного багажа перевозчик отвечает лишь в случае, если пассажир представит достаточные доказательства того,
 что они стали следствием умысла или неосторожности перевозчика.</p><br>

<p style="text-indent:2em;">(3) Перевозчик не несет ответственности за утрату или повреждение принадлежащих пассажиру денег, ценных бумаг, драгоценных металлов,
 изделий из них, украшений, произведений искусства или других ценных предметов, за исключением случаев, когда ценности были сданы ему
 на хранение и он обязался сохранить их в целости. В таких случаях перевозчик отвечает за доверенные ему ценности, но в пределах, 
 предусмотренных частью (4) статьи 196.</p><br>

<p style="text-indent:2em;">(4) Перевозчик освобождается от ответственности полностью или частично, если докажет, что вина, умысел или грубая неосторожность пассажира
 стали причиной либо способствовали его смерти или нанесению вреда здоровью, утрате или повреждению его багажа.</p><br>

<p style="font-weight:500;">Статья 196. Пределы ответственности перевозчика</p><br>

<p style="text-indent:2em;">(1) В случае смерти пассажира или нанесения вреда его здоровью ответственность перевозчика не должна превышать 175 000 расчетных
 единиц в отношении перевозки в целом.</p><br>

<p style="text-indent:2em;">(2) Ответственность перевозчика за утрату или повреждение каютного багажа пассажира не должна превышать 1 800 расчетных единиц в
 отношении перевозки в целом.</p><br>

<p style="text-indent:2em;">(3) При утрате или повреждении автомашины пассажира, включая весь багаж, находившийся в ней, ответственность перевозчика
 не должна превышать 10 000 расчетных единиц в отношении перевозки в целом.</p><br>

<p style="text-indent:2em;">(4) Ответственность перевозчика за утрату или повреждение не указанного в частях (2) и (3) багажа не должна превышать 2 700
 расчетных единиц на одного пассажира в отношении перевозки в целом.</p><br>

<p style="text-indent:2em;">(5) Перевозчик и пассажир могут составить специальное письменное соглашение, предусматривающее более высокие пределы ответственности,
 чем те, которые указаны в частях (1) - (4).</p><br>

<p style="text-indent:2em;">(6) Перевозчик, его служащий или агент не имеют права на ограничение ответственности, предусмотренное настоящей статьей,
 если доказано, что пассажиру нанесен ущерб в результате их действий или бездействия, совершенных умышленно,
 либо по самонадеянности, с сознанием возможности причинения ущерба, либо по грубой неосторожности.</p>

<p></p><h4>Глава 4 ДОГОВОР О КРУИЗЕ</h4><p></p>

<p style="font-weight:500;">Статья 197. Понятие договора о круизе</p><br>

<p style="text-indent:2em;">В соответствии с договором о круизе одна сторона - организатор круиза - обязуется устроить коллективное морское 
путешествие по определенной программе и предоставить его участнику соответствующие услуги (морская перевозка, питание,
 бытовое обслуживание, проведение экскурсий и др.), а другая сторона - участник круиза - внести за это установленную плату.</p><br>

<p style="font-weight:500;">Статья 198. Сфера применения настоящей главы</p><br>

<p>(1) Положения настоящей главы регулируют отношения между организатором и участником круиза.</p><br>

<p style="text-indent:2em;">(2) Соглашение сторон, ограничивающее права участника круиза, предусмотренные настоящей главой, недействительно.</p><br>

<p style="font-weight:500;">Статья 199. Доказательство заключения договора</p><br>

<p style="text-indent:2em;">Доказательством заключения договора о круизе является именная путевка или иной приравненный к ней документ, выданный организатором круиза.</p><br>

<p style="font-weight:500;">Статья 200. Мореходность судна</p><br>

<p style="text-indent:2em;">Организатор круиза обязан до его начала обеспечить приведение судна в состояние, предусмотренное статьей 194, и 
содержать судно в таком состоянии во время круиза.</p><br>

<p style="font-weight:500;">Статья 201. Отказ участника круиза от договора</p><br>

<p style="text-indent:2em;">(1) Участник круиза вправе в любое время до его начала отказаться от договора. В таком случае ему возвращается 
внесенная плата за круиз в порядке, размере и в сроки, установленные договором.</p><br>

<p style="text-indent:2em;">(2) Если организатор круиза не может предоставить его участнику место на судне, указанном в договоре, или, с 
согласия участника круиза, такое же место на другом судне, которое по своим характеристикам и комфортабельности
 не уступает первому, участник круиза вправе отказаться от договора и полностью получить внесенную плату за круиз.</p><br>

<p style="font-weight:500;">Статья 202. Отказ организатора круиза от договора</p><br>

<p style="text-indent:2em;">Организатор круиза вправе отказаться от договора до начала круиза при возникновении обстоятельств, указанных в пунктах 
a) - d) части (1) статьи 160. В таком случае плата за круиз возвращается его участнику полностью. Если упомянутые 
обстоятельства возникли после начала круиза и стали причиной его прекращения, организатор обязан возвратить участнику
 круиза плату за несостоявшуюся часть морского путешествия и по требованию участника круиза доставить его в порт отправления.</p><br>

<p style="font-weight:500;">Статья 203. Расходы, связанные с продлением срока круиза</p><br>

<p style="text-indent:2em;">Если из-за непредвиденных обстоятельств срок круиза продлевается, организатор морского путешествия несет все дополнительные
 расходы по предоставлению услуг участнику круиза.</p><br>

<p style="font-weight:500;">Статья 204. Ответственность организатора круиза</p><br>

<p style="text-indent:2em;">(1) Организатор круиза несет ответственность за ущерб, причиненный вследствие смерти или нанесения вреда здоровью
 участника круиза, утраты или повреждения его багажа, согласно статьям 192, 195 и 196.</p><br>

<p style="text-indent:2em;">(2) Организатор круиза, возместивший участнику круиза ущерб, причиненный перевозчиком или другим лицом, имеет право
 предъявить указанным лицам регрессные требования в размере выплаченного возмещения.</p>

<p></p><h4>РАЗДЕЛ VI ФРАХТОВАНИЕ СУДНА</h4><p></p>

<p></p><h4>Глава 1 ДОГОВОР О ФРАХТОВАНИИ СУДНА НА ВРЕМЯ</h4><p></p>

<p style="font-weight:500;">Статья 205. Основные понятия</p><br>

<p style="text-indent:2em;">(1) Согласно договору о фрахтовании судна на время, под которым в настоящем кодексе понимаются два вида договора - 
тайм-чартер и бербоут-чартер, судовладелец обязуется за обусловленную плату (фрахт) предоставить судно фрахтователю
 на определенный срок для перевозки грузов, пассажиров или иных целей, связанных с торговым море- плаванием.</p><br>

<p style="text-indent:2em;">(2) По условиям тайм-чартера судовладелец передает судно в пользование фрахтователю в мореходном состоянии - надлежащим
 образом снаряженным, с укомплектованным экипажем - для эксплуатации в целях, предусмотренных договором.</p><br>

<p style="text-indent:2em;">(3) По условиям бербоут-чартера судовладелец передает судно в пользование и владение фрахтователю не снаряженным, 
без экипажа для эксплуатации в целях, предусмотренных договором.</p><br>

<p style="font-weight:500;">Статья 206. Сфера применения настоящей главы</p><br>

<p style="text-indent:2em;">Положения настоящей главы регулируют отношения между судовладельцем и фрахтователем, возникающие на основании договора о фрахтовании
 судна на время, если соглашением сторон не установлено иное.</p><br>

<p style="font-weight:500;">Статья 207. Доказательства заключения договора</p><br>

<p style="text-indent:2em;">Договор о фрахтовании судна на время оформляется письменно. Наличие и содержание такого договора могут быть 
подтверждены только документально.</p><br>

<p style="font-weight:500;">Статья 208. Реквизиты договора</p>

<p style="text-indent:2em;"></p><h6>В договоре о фрахтовании судна на время должны быть указаны:</h6><p></p>

<p>а) наименования сторон;</p><br>

<p>b) цель фрахтования;</p><br>

<p>с) название судна;</p><br>

<p>d) технические и эксплуатационные параметры судна (грузоподъемность, грузовместимость, скорость и др.);</p><br>

<p>e) зона плавания;</p><br>

<p>f) фрахтовая ставка;</p><br>

<p>g) срок действия договора;</p><br>

<p>h) место приема и сдачи судна.</p><br>

<p style="font-weight:500;">Статья 209. Субфрахтование</p><br>

<p style="text-indent:2em;">(1) Фрахтователь может в пределах прав, предоставленных ему договором о фрахтовании судна на время, заключить от своего имени аналогичный договор
 с третьим лицом. Подписание такого договора - субфрахтование - не освобождает фрахтователя от обязанностей по выполнению договора, заключенного
 им ранее с судовладельцем.</p><br>

<p>(2) К договору о субфрахтовании судна на время применяются положения настоящей главы.</p><br>

<p style="font-weight:500;">Статья 210. Мореходность судна</p>

<p style="text-indent:2em;"></p><h6>(1) Судовладелец обязан привести судно в мореходное состояние к моменту передачи его фрахтователю:</h6><p></p>

<p style="text-indent:2em;">а) согласно тайм-чартеру - обеспечить годность судна (корпуса, главного двигателя, оборудования) к использованию для целей фрахтования, 
а также надлежащим образом снарядить судно и укомплектовать его экипаж;</p><br>

<p style="text-indent:2em;">b) согласно бербоут-чартеру - обеспечить годность судна (корпуса, главного двигателя, оборудования) к использованию для целей фрахтования.</p><br>

<p style="text-indent:2em;">(2) Судовладелец обязан в течение срока действия тайм-чартера поддерживать судно в мореходном состоянии, но не несет ответственности за 
его немореходное состояние, если докажет, что оно обусловлено скрытыми недостатками судна.</p><br>

<p style="text-indent:2em;">(3) Фрахтователь обязан в течение срока действия бербоут-чартера поддерживать судно в мореходном состоянии, однако устранение скрытых 
недостатков судна является обязанностью судовладельца.</p><br>

<p style="font-weight:500;">Статья 211. Условия эксплуатации зафрахтованного судна</p><br>

<p style="text-indent:2em;">(1) Фрахтователь обязан осуществлять эксплуатацию судна в соответствии с целями и условиями, определенными договором о 
фрахтовании судна на время.</p><br>

<p style="text-indent:2em;">(2) Согласно тайм-чартеру судовладелец несет расходы по страхованию судна и содержанию его экипажа. Фрахтователь несет расходы 
по приобретению бункерного топлива, оплачивает другие расходы и сборы, связанные с коммерческой эксплуатацией судна.</p><br>

<p style="text-indent:2em;">(3) По условиям бербоут-чартера фрахтователь несет расходы по страхованию судна и содержанию его экипажа, а также оплачивает р
асходы и сборы, связанные с эксплуатацией судна.</p><br>

<p style="text-indent:2em;">(4) По окончании срока действия договора о фрахтовании судна на время фрахтователь обязан возвратить судно его владельцу в том состоянии,
 в каком оно было им получено, с учетом нормального износа.</p><br>

<p style="font-weight:500;">Статья 212. Право фрахтователя на заключение договоров о морской перевозке грузов</p><br>

<p style="text-indent:2em;">Если судно зафрахтовано для перевозки грузов, фрахтователь вправе от своего имени заключать договоры о перевозке грузов, 
подписывать чартеры, выдавать коносаменты, иные перевозочные документы и несет ответственность по обязательствам, вытекающим из
 таких документов.</p><br>

<p style="font-weight:500;">Статья 213. Подчиненность экипажа судна</p><br>

<p style="text-indent:2em;">(1) При заключении тайм-чартера капитан и другие члены экипажа судна являются служащими судовладельца и подчиняются его распоряжениям,
 относящимся к судовождению, внутреннему распорядку на судне и составу экипажа. Для капитана и остальных членов экипажа обязательны 
 распоряжения фрахтователя, связанные с коммерческой эксплуатацией судна.</p><br>

<p style="text-indent:2em;">(2) В случае заключения бербоут-чартера фрахтователь комплектует экипаж судна новыми лицами или в соответствии с условиями договора 
принимает на службу прежний экипаж либо его часть. При этом капитан и другие члены экипажа становятся служащими фрахтователя и 
подчиняются ему во всех отношениях.</p><br>

<p style="font-weight:500;">Статья 214. Ответственность фрахтователя</p><br>

<p style="text-indent:2em;">Фрахтователь отвечает за убытки, причиненные вследствие спасания, повреждения или гибели судна, если не докажет, что они причинены не по его вине.</p><br>

<p style="font-weight:500;">Статья 215. Уплата фрахта</p><br>

<p style="text-indent:2em;">(1) Фрахтователь уплачивает судовладельцу фрахт в порядке и в сроки, предусмотренные договором о фрахтовании судна на время.</p><br>

<p style="text-indent:2em;">(2) Фрахтователь освобождается от внесения фрахта и оплаты расходов по содержанию судна за время, в течение которого судно было непригодно
 к эксплуатации вследствие немореходного состояния, за исключением случаев, когда судно пришло в такое состояние по вине фрахтователя.</p><br>

<p style="text-indent:2em;">(3) При задержке уплаты фрахта более чем на 14 календарных дней судовладелец имеет право без предупреждения изъять судно у фрахтователя
 и взыскать с него понесенные в связи с такой задержкой убытки.</p><br>

<p style="font-weight:500;">Статья 216. Уплата фрахта в случае гибели судна</p><br>

<p style="text-indent:2em;">В случае гибели судна подлежит внесению фрахт за его эксплуатацию по день гибели, если же этот день установить невозможно, 
уплачивается фрахт за использование судна по день получения последнего известия о нем.</p><br>

<p style="font-weight:500;">Статья 217. Вознаграждение за спасание на море</p><br>

<p style="text-indent:2em;">(1) Вознаграждение, причитающееся зафрахтованному на условиях тайм-чартера судну за спасание на море в период действия договора, 
распределяется поровну между судовладельцем и фрахтователем, за вычетом сумм, необходимых для возмещения расходов, понесенных при 
спасании, и доли, подлежащей выплате судовому экипажу.</p><br>

<p style="text-indent:2em;">(2) Вознаграждение, причитающееся зафрахтованному на условиях бербоут-чартера судну за спасание на море во время действия договора, 
за вычетом доли, которая должна быть выплачена судовому экипажу, принадлежит фрахтователю.</p>

<p></p><h4>Глава 2 ДОГОВОР О ЛИЗИНГЕ СУДНА</h4><p></p>

<p style="font-weight:500;">Статья 218. Понятие договора о лизинге судна</p><br>

<p style="text-indent:2em;">(1) В соответствии с договором о лизинге судна собственник (лизингодатель) обязуется передать фрахтователю (лизингополучателю) 
судно без экипажа для использования в целях торгового мореплавания за оговоренную плату (лизинговую плату) в течение определенного
 периода, по окончании которого лизингополучатель приобретает или вправе приобрести судно в собственность, уплатив разницу между 
 обусловленной договором стоимостью судна и лизинговой платой.</p><br>

<p style="text-indent:2em;">(2) Договор о лизинге молдавского государственного судна может быть заключен лишь с соблюдением положений статьи 25.</p><br>

<p style="font-weight:500;">Статья 219. Доказательства заключения договора</p><br>

<p style="text-indent:2em;">Договор о лизинге судна оформляется письменно. Наличие и содержание такого договора могут быть подтверждены только документально.</p><br>

<p style="font-weight:500;">Статья 220. Реквизиты договора</p>

<p style="text-indent:2em;"></p><h6>В договоре о лизинге судна должны быть указаны:</h6><p></p>

<p>а) наименования сторон;</p><br>

<p>b) цель договора;</p><br>

<p>с) название судна;</p><br>

<p style="text-indent:2em;">d) год постройки, класс, грузоподъемность и грузовместимость, мощность двигателей, скорость судна, расход топлива;</p><br>

<p>е) срок, по истечении которого к лизингополучателю переходит право собственности на судно;</p><br>

<p>f) размер и сроки внесения лизинговой платы;</p><br>

<p>g) место и время передачи судна лизингополучателю;</p><br>

<p>h) другие сведения.</p><br>

<p style="font-weight:500;">Статья 221. Мореходность судна</p><br>

<p style="text-indent:2em;">(1) Лизингодатель обязан передать судно лизингополучателю в состоянии, пригодном для использования судна в целях, предусмотренных 
договором о его лизинге.</p><br>

<p style="text-indent:2em;">(2) Лизингодатель несет ответственность за любые недостатки судна, не заявленные в момент его передачи лизингополучателю.</p><br>

<p style="text-indent:2em;">(3) Претензии по поводу неисправностей и скрытых недостатков судна могут быть предъявлены лизингополучателем в течение года со 
дня передачи ему судна.</p><br>

<p style="font-weight:500;">Статья 222. Эксплуатация судна лизингополучателем</p><br>

<p style="text-indent:2em;">(1) Лизингополучатель обязан поддерживать судно в исправном состоянии и нести расходы, связанные с его содержанием и ремонтом.</p><br>

<p style="text-indent:2em;">(2) Риск случайной гибели или случайного повреждения судна переходит к лизингополучателю одновременно с передачей ему судна.</p><br>

<p style="font-weight:500;">Статья 223. Лизинговая плата</p><br>

<p style="text-indent:2em;">(1) Лизинговая плата вносится лизингополучателем периодическими платежами.</p><br>

<p style="text-indent:2em;">(2) Лизингополучатель не освобождается от внесения лизинговой платы за время, в течение которого судно не использовалось им даже не 
по собственной вине, за исключением случаев, предусмотренных частью (2) статьи 221.</p><br>

<p style="text-indent:2em;">(3) В случае гибели судна или исключающего возможность его дальнейшего использования повреждения лизингополучатель обязан внести все 
платежи согласно договору о лизинге, если по условиям договора о страховании судна ему выплачивается страховое возмещение.</p><br>

<p style="font-weight:500;">Статья 224. Отказ от договора</p>

<p style="text-indent:2em;"></p><h6>(1) Лизингополучатель вправе отказаться от договора о лизинге судна и потребовать возмещения убытков, если:</h6><p></p>

<p>а) лизингодатель не передал ему судно в обусловленный договором срок;</p><br>

<p style="text-indent:2em;">b) вследствие недостатков судна, указанных в части (2) статьи 221, оно не может быть использовано в соответствии с договором.</p><br>

<p style="text-indent:2em;">(2) При отказе лизингополучателя от договора ему возвращается лизинговая плата, внесенная за период, в течение которого судно не 
могло быть им использовано.</p><br>

<p style="text-indent:2em;">(3) Лизингодатель вправе отказаться от договора о лизинге, потребовав возврата судна и возмещения убытков, если лизингополучатель не 
внес лизинговой платы в течение трех месяцев после наступления срока платежа.</p><br>

<p style="font-weight:500;">Статья 225. Возврат судна лизингодателю</p><br>

<p style="text-indent:2em;">(1) При возврате судна лизингодателю он имеет право на часть лизинговой платы, полагающуюся за период, в течение которого использовалось 
судно. Взносы, внесенные в счет оплаты обусловленной договором стоимости судна, подлежат возврату лизингополучателю.</p><br>

<p style="text-indent:2em;">(2) Лизингополучатель обязан вернуть лизингодателю судно в том состоянии, в каком он его получил, с учетом нормального износа. В случае 
ухудшения технического состояния судна лизингополучатель возмещает лизингодателю вызванные этим убытки.</p><br>

<p style="text-indent:2em;">(3) Произведенные лизингополучателем улучшения на судне, если они отделимы, могут быть изъяты им, если лизингодатель не согласится возместить их стоимость.</p>

<p></p><h4>Раздел VII МОРСКАЯ БУКСИРОВКА</h4><p></p>

<p></p><h4>Глава 1 ОБЩИЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 226. Понятие договора о морской буксировке</p><br>

<p style="text-indent:2em;">(1) Согласно договору о морской буксировке владелец одного судна-буксира обязуется за вознаграждение буксировать другое судно или иное 
плавучее сооружение на определенное расстояние.</p><br>

<p>(2) Договор о морской буксировке оформляется письменно.</p><br>

<p style="font-weight:500;">Статья 227. Мореходность судов</p><br>

<p style="text-indent:2em;">(1) Каждая из сторон договора о морской буксировке обязана заблаговременно привести свое судно (иное плавучее сооружение) в состояние, 
пригородное для выполнения им предусмотренных договором работ.</p><br>

<p style="text-indent:2em;">(2) Владельцы буксиров и буксируемых судов (иных плавучих сооружений) не несут ответственности за их скрытые недостатки.</p><br>

<p style="font-weight:500;">Статья 228. Морская буксировка в ледовых условиях</p><br>

<p style="text-indent:2em;">Владелец буксира не отвечает за ущерб, нанесенный буксируемому судну (иному плавучему сооружению) либо находившимся на его борту людям 
и имуществу при буксировке в ледовых условиях, если не доказано, что ущерб причинен по его вине.</p><br>

<p style="font-weight:500;">Статья 229. Ответственность владельца буксира</p><br>

<p style="text-indent:2em;">(1) Согласно договору о морской буксировке ответственность владельца буксира начинается с момента принятия судна (иного плавучего сооружения)
 на буксир в начальном пункте буксировки и оканчивается, когда буксируемое судно (иное плавучее сооружение) поставлено на якорь или пришвартовано
 в конечном пункте буксировки.</p><br>

<p style="text-indent:2em;">(2) Ответственность за ущерб, нанесенный буксируемому судну (иному плавучему сооружению) либо находившимся на его борту людям и имуществу, 
в случае, когда управлял буксировочной операцией капитан буксира, при отсутствии иного соглашения сторон несет владелец буксира, если не 
докажет, что ущерб причинен не по его вине.</p><br>

<p style="font-weight:500;">Статья 230. Ответственность владельца буксируемого судна</p><br>

<p style="text-indent:2em;">Ответственность за ущерб, причиненный буксиру или находившимся на нем людям и имуществу, в случае, когда руководил буксировочной операцией
 капитан буксируемого судна (иного плавучего сооружения), при отсутствии иного соглашения сторон несет владелец буксируемого судна (иного 
 плавучего сооружения), если не докажет, что ущерб причинен не по его вине.</p><br>

<p style="font-weight:500;">Статья 231. Помощь судну, потерпевшему аварию</p><br>

<p style="text-indent:2em;">В случае аварии на буксируемом судне (ином плавучем сооружении) по причине, не зависящей от буксира, капитан последнего обязан предоставить
 буксируемому судну (иному плавучему сооружению) спасательные средства и оказывать ему необходимую помощь до тех пор, пока пострадавшее судно
 (иное плавучее сооружение) будет в ней нуждаться. При этом возмещаются лишь расходы, понесенные в связи с выполнением спасательной операции.</p>

<p></p><h4>Глава 2 ПОРТОВАЯ БУКСИРОВКА</h4><p></p>

<p style="font-weight:500;">Статья 232. Понятие договора о портовой буксировке</p><br>

<p style="text-indent:2em;">(1) В соответствии с договором о портовой буксировке владелец буксира за вознаграждение осуществляет ввод в порт или вывод из порта судна 
(иного плавучего сооружения), обеспечивает выполнение им необходимых маневров, швартовных и других операций в акватории порта.</p><br>

<p>(2) Договор о портовой буксировке может быть заключен в устной форме.</p><br>

<p style="font-weight:500;">Статья 233. Управление портовой буксировкой</p><br>

<p style="text-indent:2em;">Портовой буксировкой управляет капитан буксируемого судна (иного плавучего сооружения), однако обязанности по руководству буксировочной 
операцией могут быть возложены и на капитана буксира, если это оговорено в письменном соглашении.</p><br>

<p style="font-weight:500;">Статья 234. Обязательная портовая буксировка</p><br>

<p style="text-indent:2em;">В целях обеспечения безопасности плавания начальник порта вправе установить обязательное использование буксиров для входа, выхода и 
передвижения судов в акватории порта, выполнения ими маневров, швартовных и иных операций, а также определить условия буксировки.</p>

<p></p><h4>Глава 3 МЕЖПОРТОВАЯ БУКСИРОВКА</h4><p></p>

<p style="font-weight:500;">Статья 235. Понятие договора о межпортовой буксировке</p><br>

<p style="text-indent:2em;">Согласно договору о межпортовой буксировке владелец буксира обязуется за вознаграждение буксировать другое судно (иное плавучее сооружение)
 из одного порта в другой.</p><br>

<p style="font-weight:500;">Статья 236. Форма и реквизиты договора</p><br>

<p>(1) Договор о межпортовой буксировке оформляется письменно.</p>

<p style="text-indent:2em;"></p><h6>(2) В договоре о межпортовой буксировке должны быть указаны:</h6><p></p>

<p>а) порт отправления;</p><br>

<p>b) порт назначения;</p><br>

<p>с) продолжительность буксировки;</p><br>

<p>d) права и обязанности сторон;</p><br>

<p>е) ответственность за нарушение договора;</p><br>

<p>f) особенности буксируемых объектов, могущие повли- ять на безопасность буксировки;</p><br>

<p>g) иные сведения, которые стороны считают необходимым включить в договор.</p><br>

<p style="font-weight:500;">Статья 237. Буксировка между портами Республики Молдова</p><br>

<p style="text-indent:2em;">Буксировка между портами Республики Молдова может осуществляться как молдавскими, так и иностранными судами.</p><br>

<p style="font-weight:500;">Статья 238. Буксировка, осуществляемая судами Республики Молдова</p><br>

<p style="text-indent:2em;">Буксировка между портами Республики Молдова, а также из иностранных портов или в иностранные порты, выполняемая судами, плавающими
 под флагом Республики Молдова, регулируется нормами настоящего кодекса.</p><br>

<p style="font-weight:500;">Статья 239. Управление межпортовой буксировкой</p><br>

<p style="text-indent:2em;">(1) Управление межпортовой буксировкой возлагается на капитана буксира, однако он не вправе отдавать распоряжения по поводу организации
 службы на буксируемом судне (ином плавучем сооружении).</p><br>

<p style="text-indent:2em;">(2) При использовании в буксировочной операции нескольких буксиров правовые отношения между капитанами судов, участвующих в операции, 
определяются договором о межпортовой буксировке.</p><br>

<p style="font-weight:500;">Статья 240. Отказ от договора</p><br>

<p style="text-indent:2em;">(1) В случае неготовности к буксировочной операции объекта буксировки в обусловленный договором срок владелец буксира, прибывшего в 
порт отправления, вправе отказаться от договора и потребовать возмещения понесенных расходов и упущенной выгоды.</p><br>

<p style="text-indent:2em;">(2) Если буксир прибыл в порт отправления в немореходном состоянии, не подготовленным к буксировке, владелец судна (иного плавучего 
сооружения), подлежащего буксировке, вправе отказаться от договора и возложить на владельца буксира все понесенные расходы.</p>

<p></p><h4>РАЗДЕЛ VIII МОРСКОЕ СТРАХОВАНИЕ</h4><p></p>

<p></p><h4>Глава 1 ДОГОВОР О МОРСКОМ СТРАХОВАНИИ</h4><p></p>

<p style="font-weight:500;">Статья 241. Понятие договора о морском страховании</p><br>

<p style="text-indent:2em;">В соответствии с договором о морском страховании страховщик обязуется за обусловленную плату (страховую премию) возместить страхователю 
или иному лицу, в пользу которого заключен договор (далее - выгодоприобретатель), понесенный при наступлении предусмотренных договором 
опасностей или случайностей (страхового случая) ущерб в размере страховой суммы или страхового возмещения.</p><br>

<p style="font-weight:500;">Статья 242. Сфера применения настоящей главы</p><br>

<p style="text-indent:2em;">Положения настоящей главы применяются в случаях, когда соглашением сторон не установлено иное. Соглашение сторон, противоречащее этим 
положениям, является недействительным.</p><br>

<p style="font-weight:500;">Статья 243. Доказательства заключения договора</p><br>

<p style="text-indent:2em;">Договор о морском страховании оформляется письменно. Наличие и содержание такого договора могут быть подтверждены только документально.</p><br>

<p style="font-weight:500;">Статья 244. Объект морского страхования</p><br>

<p style="text-indent:2em;">(1) Объектом морского страхования могут быть связанные с торговым мореплаванием имущество, любой имущественный интерес, как то: судно 
(в том числе строящееся), груз, фрахт, плата за проезд, плата за пользование судном, ожидаемая прибыль от перевозимого груза, морские 
требования, обеспечиваемые судном, грузом или фрахтом, заработная плата, иные выплаты капитану и другим членам судового экипажа, в том 
числе на репатриацию, гражданская ответственность судовладельца и морского перевозчика, а также риск, принятый на себя страховщиком 
(при перестраховании).</p><br>

<p>(2) Объект страхования должен быть указан в договоре о морском страховании.</p><br>

<p style="font-weight:500;">Статья 245. Информация о страховом риске</p><br>

<p style="text-indent:2em;">(1) При заключении договора о морском страховании страхователь обязан сообщить страховщику всю информацию, которой тот должен владеть 
в силу своих служебных обязанностей, об обстоятельствах, имеющих существенное значение для определения степени риска, а также иные сведения,
 запрошенные страховщиком. Страхователь освобождается от этой обязанности только в отношении общеизвестных сведений, а также сведений, которые 
 известны или должны быть известны страховщику.</p><br>

<p style="text-indent:2em;">(2) Страховщик вправе отказаться от договора, если страхователь не сообщил необходимые сведения или представил неверные данные.</p><br>

<p style="font-weight:500;">Статья 246. Страховой полис</p><br>

<p style="text-indent:2em;">Страховщик выдает страхователю страховой полис, страховой сертификат или иной подобный документ, подтверждающий заключение договора
 о морском страховании и содержащий его условия.</p><br>

<p style="font-weight:500;">Статья 247. Страховая премия</p><br>

<p style="text-indent:2em;">Страхователь обязан в обусловленный договором о морском страховании срок уплатить страховщику страховую премию. До ее уплаты указанный
 договор не вступает в силу, если в нем не предусмотрено иное.</p><br>

<p style="font-weight:500;">Статья 248. Заключение договора в пользу выгодоприобретателя</p><br>

<p style="text-indent:2em;">(1) Договор о морском страховании может быть заключен страхователем в свою пользу или в пользу выгодоприобретателя, 
указанного либо не указанного в договоре.</p><br>

<p style="text-indent:2em;">(2) В случае заключения договора о морском страховании в пользу выгодоприобретателя страхователь несет все обязанности по этому договору.
 Такие же обязанности несет и выгодоприобретатель, если он согласен, или поручил заключить договор, или лишь впоследствии выразил согласие
 на страхование.</p><br>

<p style="text-indent:2em;">(3) При страховании в пользу выгодоприобретателя страхователь пользуется всеми правами, предоставленными договором о морском страховании,
 без доверенности выгодоприобретателя.</p><br>

<p style="font-weight:500;">Статья 249. Предъявление полиса страховщику</p><br>

<p style="text-indent:2em;">При выплате страхового возмещения страховщик вправе потребовать предъявления страхового полиса или иного выданного ранее страхового документа.</p><br>

<p style="font-weight:500;">Статья 250. Страховая сумма</p><br>

<p style="text-indent:2em;">(1) При заключении договора о морском страховании страхователь обязан объявить сумму, в которую оценивает объект страхования,
 - страховую сумму.</p><br>

<p style="text-indent:2em;">(2) Если объявленная страховая сумма ниже фактической стоимости объекта страхования (далее - страховая стоимость), страховщик несет
 ответственность за убытки пропорционально отношению страховой суммы к страховой стоимости.</p><br>

<p style="text-indent:2em;">(3) Если страховая сумма, указанная в договоре о морском страховании, превышает страховую стоимость, такой договор является 
недействительным в отношении той части страховой суммы, которая превышает страховую стоимость.</p><br>

<p style="font-weight:500;">Статья 251. Двойное страхование</p><br>

<p style="text-indent:2em;">Если объект застрахован несколькими страховщиками на суммы, которые в общей сложности превышают его страховую стоимость, все 
страховщики несут ответственность лишь в пределах страховой стоимости, причем каждый из них - соразмерно страховой сумме, 
указанной в соответствующем договоре о морском страховании.</p><br>

<p style="font-weight:500;">Статья 252. Генеральный страховой полис</p><br>

<p style="text-indent:2em;">Посредством заключения специального соглашения - генерального страхового полиса могут быть застрахованы все грузы, которые 
страхователь получает или отправляет в течение определенного срока.</p><br>

<p style="font-weight:500;">Статья 253. Обязанности страхователя по генеральному страховому полису</p><br>

<p style="text-indent:2em;">(1) Страхователь обязан при отправке каждой из партий груза, указанного в генеральном страховом полисе, оперативно сообщать 
страховщику все необходимые сведения по их получении, в частности наименование судна, на котором перевозится груз, и его маршрут,
 размер страховой суммы. Страхователь не освобождается от этой обязанности, даже если получит соответствующие сведения после доставки
 груза в место назначения в неповрежденном состоянии.</p><br>

<p style="text-indent:2em;">(2) В случае несообщения или несвоевременного сообщения страхователем сведений, указанных в части (1), страховщик вправе отказать 
в возмещении убытков, понесенных страхователем при перевозке соответствующего груза. При этом за страховщиком сохраняется право на 
страховую премию, за исключением случаев, когда страхователь докажет, что несообщение или несвоевременное сообщение им необходимых 
сведений не может быть поставлено ему в вину.</p><br>

<p style="text-indent:2em;">(3) Страховщик вправе отказаться от страхования по генеральному полису, если страхователь умышленно сообщил сведения, указанные в части
 (1), несвоевременно, или не передал их, или намеренно неправильно обозначил род и вид груза либо страховую сумму. При этом за страховщиком
 сохраняется право на страховую премию, которую он получил бы в случае исполнения страхователем условий договора о морском страховании 
 в полном объеме.</p><br>

<p style="font-weight:500;">Статья 254. Приоритет страхового полиса перед генеральным страховым полисом</p><br>

<p style="text-indent:2em;">(1) По требованию страхователя страховщик обязан выдавать ему по некоторым из отправляемых партий груза, указанного в генеральном страховом
 полисе, отдельные страховые полисы или страховые сертификаты.</p><br>

<p style="text-indent:2em;">(2) При несоответствии содержания страхового полиса или страхового сертификата содержанию генерального страхового полиса приоритет остается
 за страховым полисом или страховым сертификатом.</p><br>

<p style="font-weight:500;">Статья 255. Отчуждение застрахованного груза</p><br>

<p style="text-indent:2em;">(1) В случае отчуждения застрахованного груза договор о морском страховании сохраняет силу, причем все вытекающие из него права и 
обязанности страхователя переходят к приобретателю груза.</p><br>

<p style="text-indent:2em;">(2) Если до отчуждения застрахованного груза страховая премия не была уплачена, обязанность уплатить ее несут как страхователь, 
так и приобретатель груза. Однако требование уплатить премию не имеет юридической силы в отношении держателя страхового полиса или
 другого страхового документа, в котором отсутствует указание на то, что премия не уплачена.</p><br>

<p style="font-weight:500;">Статья 256. Отчуждение застрахованного судна</p><br>

<p style="text-indent:2em;">В случае отчуждения застрахованного судна действие договора о морском страховании прекращается с момента отчуждения. При отчуждении
 судна во время рейса договор остается в силе до окончания рейса, но к приобретателю судна переходят все права и обязанности 
 страхователя.</p><br>

<p style="font-weight:500;">Статья 257. Исключение страхового риска или возникновение убытков до заключения договора</p><br>

<p style="text-indent:2em;">(1) Договор о морском страховании сохраняет силу и в случае, если к моменту его заключения риск возникновения убытков, подлежащих
 возмещению, миновал или эти убытки уже понесены. Однако если страховщик при заключении договора знал или должен был знать, что 
 возможность наступления страхового случая исключена, либо страхователь знал или должен был знать об уже возникших и подлежащих 
 возмещению страховщиком убытках, исполнение договора необязательно для стороны, которой не было известно об этих обстоятельствах.</p><br>

<p style="text-indent:2em;">(2) Страховая премия причитается страховщику, даже если исполнение договора о морском страховании для него необязательно.</p><br>

<p style="font-weight:500;">Статья 258. Убытки, обусловленные умыслом страхователя</p><br>

<p style="text-indent:2em;">Убытки, понесенные страхователем вследствие умысла или грубой неосторожности самого страхователя либо его представителя, не подлежат возмещению страховщиком.</p><br>

<p style="font-weight:500;">Статья 259. Убытки, обусловленные немореходным состоянием судна</p><br>

<p style="text-indent:2em;">(1) При страховании судна страховщик не несет ответственности за убытки, ставшие следствием того, что судно было отправлено в рейс
 в немореходном состоянии, если это состояние не было вызвано скрытыми недостатками судна.</p><br>

<p style="text-indent:2em;">(2) Не подлежат возмещению страховщиком убытки, понесенные вследствие морального или физического износа судна и его оснастки, а 
равно убытки, ставшие результатом погрузки на судно с ведома страхователя или его представителя, но без ведома страховщика 
взрывоопасных и самовозгорающихся веществ и предметов.</p><br>

<p style="font-weight:500;">Статья 260. Убытки, обусловленные естественными свойствами груза</p>

<p style="text-indent:2em;"></p><h6>В случаях страхования груза или ожидаемой прибыли страховщик не отвечает за убытки, если докажет, что они стали следствием:</h6><p></p>

<p style="text-indent:2em;">а) естественных свойств груза (внутренняя порча, убыль, ржавление, покрытие плесенью, утечка, поломка, самовозгорание и др.);</p><br>

<p>b) несоответствия упаковки груза предъявляемым требованиям.</p><br>

<p style="font-weight:500;">Статья 261. Освобождение страховщика от ответственности при страховании фрахта</p><br>

<p>При страховании фрахта страховщик освобождается от ответственности согласно статьям 258, 259 и 260.</p><br>

<p style="font-weight:500;">Статья 262. Ядерный ущерб</p><br>

<p style="text-indent:2em;">Страховщик не отвечает за ущерб, нанесенный вследствие ядерного взрыва, радиации или радиоактивного заражения, 
если иное не установлено настоящим кодексом.</p><br>

<p style="font-weight:500;">Статья 263. Убытки, обусловленные военными и иными действиями</p><br>

<p style="text-indent:2em;">Страховщик не несет ответственности за убытки, понесенные из-за военных действий и их последствий, пиратских действий, 
народных волнений, забастовок, а также из-за конфискации, реквизиции, ареста, уничтожения судна либо его груза по требованию
 соответствующих властей.</p><br>

<p style="font-weight:500;">Статья 264. Уведомление об изменении степени страхового риска</p><br>

<p style="text-indent:2em;">(1) Страхователь обязан немедленно, как только это станет ему известно, сообщить страховщику о любом существенном изменении,
 которое произошло с объектом страхования или в отношении объекта страхования (перегрузка, изменение способа перевозки, порта
 выгрузки, отклонение судна от намеченного или обычного пути, остановка на зимовку и др.).</p><br>

<p style="text-indent:2em;">(2) Изменения, указанные в части (1), увеличивающие степень страхового риска (за исключением тех, которые вызваны спасанием людей,
 судов или грузов), дают страховщику право пересмотреть условия договора о морском страховании или потребовать выплаты дополнительной
 страховой премии. Если страхователь не согласится на это, действие договора о морском страховании прекращается с момента наступления
 соответстующего изменения.</p><br>

<p style="font-weight:500;">Статья 265. Предотвращение или уменьшение размера убытков</p><br>

<p style="text-indent:2em;">(1) При наступлении страхового случая страхователь или выгодоприобретатель о бязан принять все зависящие от него меры для предотвращения
 или уменьшения размера убытков, а также немедленно известить о случившемся страховщика и строго следовать его указаниям, если они будут
 даны.</p><br>

<p style="text-indent:2em;">(2) Страховщик освобождается от ответственности за убытки, обусловленные тем, что страхователь или выгодоприобретатель умышленно
 либо по грубой неосторожности не принял мер для предотвращения или уменьшения размера убытков.</p><br>

<p style="font-weight:500;">Статья 266. Уплата взносов для покрытия убытков по общей аварии</p><br>

<p style="text-indent:2em;">По требованию страхователя или выгодоприобретателя страховщик обязан в пределах страховой суммы произвести уплату взносов для 
покрытия убытков по общей аварии.</p><br>

<p style="font-weight:500;">Статья 267. Защита интересов страховщика при общей аварии</p><br>

<p>При составлении диспаши страхователь обязан защищать интересы страховщика.</p><br>

<p style="font-weight:500;">Статья 268. Освобождение страховщика от обязательств по договору</p><br>

<p style="text-indent:2em;">(1) После наступления страхового случая страховщик имеет право, уплатив полную страховую сумму, освободить себя от других обязательств
 по договору о морском страховании. Уведомление о своем намерении воспользоваться этим правом он направляет страхователю или выгодоприобретателю
 в семидневный срок. Расходы, произведенные страхователем или выгодоприобретателем исключительно в целях предотвращения или уменьшения размера
 убытков до получения им указанного уведомления, возмещает страховщик.</p><br>

<p style="text-indent:2em;">(2) При уплате страховой суммы в случае, предусмотренном частью (1), страховщик не приобретает права на застрахованное имущество.</p><br>

<p style="font-weight:500;">Статья 269. Возмещение расходов страхователя или выгодоприобретателя</p>

<p style="text-indent:2em;"></p><h6>(1) Страховщик обязан возместить расходы, понесенные страхователем или выгодоприобретателем для:</h6><p></p>

<p>а) предотвращения или уменьшения размера убытков, за которые отвечает страховщик;</p><br>

<p>b) выполнения указаний страховщика в соответствии с частью (1) статьи 265;</p><br>

<p>с) установления наличия и определения размера убытков;</p><br>

<p>d) составления диспаши.</p><br>

<p style="text-indent:2em;">(2) Расходы, указанные в части (1), возмещаются пропорционально отношению страховой суммы к страховой стоимости.</p><br>

<p style="font-weight:500;">Статья 270. Ответственность страховщика сверх страховой суммы</p><br>

<p style="text-indent:2em;">(1) Страховщик отвечает за убытки в пределах страховой суммы, однако он обязан возместить расходы, указанные в статье 269, 
а также уплатить взносы для покрытия убытков по общей аварии даже в случае, если указанные расходы, взносы и сумма страхового
 возмещения в общей сложности могут превысить страховую сумму.</p><br>

<p style="text-indent:2em;">(2) Страховщик несет ответственность за убытки, причиненные в результате нескольких следующих друг за другом страховых случаев,
 даже если общая сумма таких убытков превысит страховую сумму.</p><br>

<p style="font-weight:500;">Статья 271. Пропажа судна без вести</p><br>

<p style="text-indent:2em;">(1) Судно считается пропавшим без вести, если о нем не поступило никаких сведений в течение достаточного срока, продолжительность
 которого зависит от конкретных обстоятельств. Срок, необходимый для признания судна пропавшим без вести, не может быть менее одного
 месяца и более трех месяцев со дня получения последнего известия о судне, в условиях военных действий он не может быть менее шести
 месяцев.</p><br>

<p>(2) В случае пропажи судна без вести страховщик несет ответственность в размере полной страховой суммы.</p><br>

<p style="text-indent:2em;">(3) Согласно договору о морском страховании судна на определенное время страховщик отвечает за пропажу судна без вести, если последнее
 известие о судне было получено до истечения срока действия договора и если страховщик не докажет, что судно пропало без вести по 
 истечении этого срока.</p><br>

<p style="font-weight:500;">Статья 272. Абандон</p>

<p style="text-indent:2em;"></p><h6>(1) Страхователь или выгодоприобретатель может заявить страховщику об абандоне - отказе от своих прав на застрахованное имущество
 и получить полную страховую сумму в случае:</h6><p></p>

<p>а) пропажи судна без вести;</p><br>

<p style="text-indent:2em;">b) экономической нецелесообразности восстановления или ремонта застрахованного судна (полная конструктивная гибель);</p><br>

<p style="text-indent:2em;">с) экономической нецелесообразности устранения повреждений застрахованного груза или его доставки в место назначения;</p><br>

<p>d) захвата судна и/или груза, застрахованных от такой опасности, если захват длится более двух месяцев;</p><br>

<p>е) полной фактической гибели судна и/или груза.</p><br>

<p>(2) Соглашение сторон, противоречащее части (1), недействительно.</p><br>

<p style="font-weight:500;">Статья 273. Переход к страховщику прав на застрахованное имущество при абандоне</p>

<p style="text-indent:2em;"></p><h6>(1) В случаях, предусмотренных статьей 272, к страховщику переходят:</h6><p></p>

<p>а) права на все застрахованное имущество - при страховании имущества в полной стоимости;</p><br>

<p style="text-indent:2em;">b) права на долю застрахованного имущества пропорционально отношению страховой суммы к страховой стоимости - при страховании 
имущества не в полной стоимости.</p><br>

<p>(2) Соглашение сторон, противоречащее части (1), недействительно.</p><br>

<p style="font-weight:500;">Статья 274. Заявление об абандоне</p><br>

<p style="text-indent:2em;">(1) Заявление об абандоне должно быть предъявлено страховщику в течение шести месяцев со дня окончания сроков или наступления 
обстоятельств, предусмотренных статьями 271 и 272.</p><br>

<p style="text-indent:2em;">(2) По истечении шестимесячного срока страхователь или выгодоприобретатель лишается права на абандон, но может потребовать возмещения
 убытков на общих основаниях.</p><br>

<p style="text-indent:2em;">(3) Заявление об абандоне не может быть сделано страхователем или выгодоприобретателем условно и не может быть взято обратно.</p><br>

<p>(4) Соглашение сторон, противоречащее положениям настоящей статьи, недействительно.</p><br>

<p style="font-weight:500;">Статья 275. Отмена абандона</p><br>

<p style="text-indent:2em;">Если после получения страхового возмещения страхователем или выгодоприобретателем выяснится, что судно не погибло либо захваченные
 судно и/или груз будут освобождены, страховщик может потребовать, чтобы страхователь или выгодоприобретатель, сохранив за собой имущество, 
 возвратил страховое возмещение, за вычетом той его части, которая соответствует реальному ущербу.</p><br>

<p style="font-weight:500;">Статья 276. Суброгация</p><br>

<p style="text-indent:2em;">(1) К страховщику, уплатившему страховое возмещение, переходит в пределах выплаченной суммы право предъявления требования, которое страхователь
 или выгодоприобретатель имеет к лицу, ответственному за причинение ущерба. Это право осуществляется страховщиком в порядке, установленном для
 лица, получившего страховое возмещение.</p><br>

<p style="text-indent:2em;">(2) Если страхователь или выгодоприобретатель отказался от своего права предъявления требования лицу, ответственному за причинение ущерба,
 или осуществление этого права стало невозможным по его вине, страховщик полностью или частично освобождается от уплаты страхового возмещения.</p><br>

<p style="font-weight:500;">Статья 277. Передача страховщику доказательств</p><br>

<p style="text-indent:2em;">В случаях, предусмотренных статьями 272, 273 и 276, страхователь или выгодоприобретатель обязан передать страховщику все документы и 
вещественные доказательства, а также сообщить ему все сведения, необходимые для осуществления страховщиком перешедших к нему прав.</p><br>

<p style="font-weight:500;">Статья 278. Получение возмещения от лица, ответственного за причиненный ущерб</p><br>

<p style="text-indent:2em;">Если страхователь или выгодоприобретатель получил возмещение от лица, ответственного за причиненный ущерб, страховщик оплачивает лишь 
разницу между суммой, подлежащей оплате по условиям договора о морском страховании, и суммой, полученной страхователем или выгодоприобретателем
 от указанного лица.</p>

<p></p><h4>Глава 2 ВЗАИМНОЕ СТРАХОВАНИЕ</h4><p></p>

<p style="font-weight:500;">Статья 279. Общества взаимного страхования</p><br>

<p style="text-indent:2em;">Судовладельцы и страховщики могут создавать национальные общества и/или быть членами международных обществ взаимного страхования.</p><br>

<p style="font-weight:500;">Статья 280. Компетенция обществ</p><br>

<p style="text-indent:2em;">(1) Общества взаимного страхования помимо осуществления страховой деятельности дают консультации по правовым и коммерческим вопросам,
 относящимся к практике торгового мореплавания.</p><br>

<p style="text-indent:2em;">(2) Компетенция национальных обществ взаимного страхования, их структура определяются уставом, утверждаемым в соответствии
 с законодательством.</p>

<p></p><h4>РАЗДЕЛ IX ЧРЕЗВЫЧАЙНЫЕ МОРСКИЕ ПРОИСШЕСТВИЯ</h4><p></p>

<p></p><h4>Глава 1 ОБЩАЯ АВАРИЯ</h4><p></p>

<p style="font-weight:500;">Статья 281. Понятие общей аварии</p><br>

<p style="text-indent:2em;">(1) Под общей аварией понимаются как акт общей аварии, так и убытки, ставшие следствием потерь или чрезвычайных расходов, понесенных
 намеренно и целесообразно - для спасания судна, находившегося на нем груза и сохранения фрахта, под которым в настоящей главе подразумевается
 также плата за перевозку пассажиров и их багажа.</p><br>

<p>(2) Общая авария распределяется соразмерно стоимости судна, груза и величине фрахта.</p><br>

<p style="font-weight:500;">Статья 282. Применение права при общей аварии</p><br>

<p style="text-indent:2em;">(1) Правоотношения при общей аварии регулируются законодательством государства, в порту которого судно закончило рейс после общей аварии,
 если соглашением сторон не предусмотрено иное.</p><br>

<p style="text-indent:2em;">(2) Если все лица, интересы которых затронуты общей аварией, принадлежат одному государству, применяется законодательство этого государства.</p><br>

<p style="text-indent:2em;">(3) Порядок распределения общей аварии, если оно производится в Республике Молдова, регулируется положениями настоящего кодекса.</p><br>

<p style="font-weight:500;">Статья 283. Сфера применения статей 284 - 292</p><br>

<p style="font-weight:500;">Статьи 284 - 292 применяются в случаях, когда соглашением сторон не предусмотрено иное.</p><br>

<p style="font-weight:500;">Статья 284. Убытки, признаваемые общей аварией</p>

<p style="text-indent:2em;"></p><h6>Общей аварией согласно признакам, указанным в статье 281, признаются:</h6><p></p>

<p style="text-indent:2em;">а) убытки, вызванные выбрасыванием за борт груза или оснастки судна, а также повреждением судна или груза при принятии мер для общего спасания,
 в частности понесенные вследствие проникновения воды в трюмы через открытые для выбрасывания груза люки или другие вырезанные для этого отверстия;</p><br>

<p style="text-indent:2em;">b) убытки, причиненные судну или грузу при тушении пожара, в том числе убытки, обусловленные произведенным с этой целью затоплением горящего судна;</p><br>

<p>с) убытки, вызванные намеренной посадкой судна на мель и снятием такого судна с мели;</p><br>

<p>d) убытки, понесенные вследствие повреждения двигателей, котлов, другого оборудования при снятии судна с мели;</p><br>

<p style="text-indent:2em;">е) убытки, связанные с наймом лихтеров, с перегрузкой груза, топлива или предметов снабжения с посаженного на мель судна на лихтеры, 
с обратной погрузкой с лихтеров на снятое с мели судно;</p><br>

<p style="text-indent:2em;">f) убытки, причиной которых стали повреждение или гибель груза, топлива либо предметов снабжения при их перемещении на судне, выгрузке, 
обратной погрузке и укладке, а также при хранении, в случаях, когда расходы по совершению этих операций относятся к общей аварии;</p><br>

<p style="text-indent:2em;">g) убытки, связанные с проведением спасательных операций, независимо от того, осуществлялось спасание на основании договора или без него;</p><br>

<p style="text-indent:2em;">h) потеря фрахта, вызванная утратой груза, в случаях, когда такая утрата возмещается в порядке распределения общей аварии; при этом из 
фрахта исключаются расходы, которые в целях его получения были бы произведены перевозчиком груза, но в связи с намеренной потерей произведены не были.</p><br>

<p style="font-weight:500;">Статья 285. Расходы, относимые к общей аварии</p>

<p style="text-indent:2em;"></p><h6>К общей аварии относятся:</h6><p></p>

<p style="text-indent:2em;">а) расходы, вызванные вынужденным заходом судна в укрытие или его возвращением в порт отправления вследствие несчастного случая либо другого
 чрезвычайного обстоятельства для обеспечения общей безопасности;</p><br>

<p style="text-indent:2em;">b) расходы, связанные с выходом судна с первоначальным грузом или с частью груза из безопасного места либо из порта отправления, 
в который судно вынуждено было возвратиться;</p><br>

<p style="text-indent:2em;">с) расходы по перемещению или выгрузке груза, топлива либо предметов снабжения в порту отправления, порту захода или в укрытии,
 произведенные в целях обеспечения общей безопасности или для получения возможности устранить судовые повреждения, вызванные несчастным
 случаем либо другими чрезвычайными обстоятельствами, если ремонт был необходим для безопасного продолжения рейса;</p><br>

<p style="text-indent:2em;">d) расходы по обратной укладке или погрузке груза, топлива либо предметов снабжения, перемещенных или выгруженных при обстоятельствах,
 указанных в пункте с), а также все расходы по хранению и страхованию. Однако если судно признано непригодным к плаванию или не продолжило рейс,
 расходы по хранению относятся к общей аварии до дня признания судна непригодным к плаванию или отказа от рейса либо до дня завершения выгрузки груза,
 если судно было признано непригодным к плаванию или отказалось от рейса до этого дня;</p><br>

<p style="text-indent:2em;">е) расходы по заработной плате и содержанию судового экипажа, на топливо и предметы снабжения, понесенные в связи с продлением рейса в результате
 захода судна в укрытие или возвращения его в порт отправления при обстоятельствах, указанных в пунктах а) и с). Однако если судно признано непригодным
 к плаванию или не продолжило рейс, такие расходы относятся к общей аварии до дня признания судна непригодным к плаванию или отказа от рейса либо до дня
 завершения выгрузки груза, если судно было признано непригодным к плаванию или отказалось от рейса до этого дня;</p><br>

<p style="text-indent:2em;">f) расходы по заработной плате и содержанию экипажа при задержке судна в интересах общей безопасности из-за несчастного случая или 
другого чрезвычайного обстоятельства либо для устранения повреждений, обусловленных таким обстоятельством, если устранение повреждений
 требовалось для безопасного продолжения рейса. Расходы на топливо, оснастку, портовые расходы, понесенные во время задержки судна, возмещаются
 в порядке распределения общей аварии, за исключением расходов, связанных с ремонтом, который не относится к общей аварии;</p><br>

<p style="text-indent:2em;">g) расходы, указанные в пунктах а) - f), вызванные необходимостью перехода судна из одного укрытия в другое из-за невозможности произвести 
ремонт в первом укрытии, а также расходы по такому переходу, включая затраты на временный ремонт и буксировку судна;</p><br>

<p style="text-indent:2em;">h) стоимость временного ремонта судна, произведенного в порту отправления, порту захода или в укрытии для обеспечения общей безопасности, а 
также стоимость временного устранения повреждений, отнесенных к общей аварии. Однако стоимость временного устранения случайных повреждений, 
необходимого только для завершения рейса, возмещается лишь в пределах тех предотвращенных расходов, которые были бы отнесены к общей аварии, 
если бы эти повреждения не были устранены;</p><br>

<p style="text-indent:2em;">i) все чрезвычайные расходы, произведенные вместо других расходов, которые были бы отнесены к общей аварии. Однако чрезвычайные расходы 
возмещаются лишь в пределах предотвращенных расходов, безотносительно к экономии, полученной кем-либо из лиц, интересы которых затронуты 
общей аварией, в результате такой замены.</p><br>

<p style="font-weight:500;">Статья 286. Распределение общей аварии</p><br>

<p style="text-indent:2em;">Общая авария распределяется в порядке, установленном статьей 281, и в случае, когда опасность, вызвавшая намеренные потери или чрезвычайные
 расходы, возникла по вине лица, имеющего имущественный интерес по отношению к судну, грузу или фрахту. Такое распределение не лишает остальных
 лиц, интересы которых затронуты общей аварией, права на взыскание с ответственного лица понесенных убытков, равно как не лишает это лицо 
 возможных средств защиты.</p><br>

<p style="font-weight:500;">Статья 287. Необъявленный или неправильно объявленный груз</p><br>

<p style="text-indent:2em;">(1) Убытки, понесенные вследствие повреждения или гибели грузов, погруженных на судно без ведома судовладельца либо его агентов, 
а также вследствие повреждения или гибели грузов, намеренно сданных для перевозки под неправильным наименованием, не распределяются
 в порядке, установленном статьей 281. Если эти грузы были спасены, их владельцы обязаны на общих основаниях вносить взносы для покрытия
 убытков по общей аварии.</p><br>

<p style="text-indent:2em;">(2) Владельцы грузов, объявленная стоимость которых при сдаче для перевозки была ниже их фактической стоимости, вносят взносы для 
покрытия убытков по общей аварии в соответствии с фактической стоимостью грузов, но возмещение убытков получают в соответствии с 
объявленной стоимостью грузов.</p><br>

<p style="font-weight:500;">Статья 288. Возмещение убытков, вызванных повреждением судна</p>

<p style="text-indent:2em;"></p><h6>(1) Размер убытков, вызванных повреждением судна, его машин, котлов или иного оборудования и подлежащих возмещению по общей аварии, определяется:</h6><p></p>

<p>а) в случае ремонта или замены частей судна - фактической стоимостью ремонта или замены;</p><br>

<p style="text-indent:2em;">b) в остальных случаях - суммой, на которую уменьшилась стоимость судна вследствие получения повреждения и которая не должна превышать оценочной стоимости ремонта.</p><br>

<p style="text-indent:2em;">(2) Если устаревшие материалы или части заменяются новыми на судне, которое эксплуатировалось более пятнадцати лет, при установлении 
стоимости ремонта, относимого к общей аварии, делается скидка "за новое вместо старого" в размере одной трети.</p><br>

<p style="text-indent:2em;">(3) В случае фактической или полной конструктивной гибели судна сумма, подлежащая возмещению по общей аварии, составляет разницу между
 оценочной стоимостью судна в неповрежденном состоянии, за вычетом из нее оценочной стоимости ремонта повреждений, которые не относятся
 к общей аварии, и стоимостью судна в поврежденном состоянии, которая могла бы быть определена чистой выручкой от продажи судна.</p><br>

<p style="font-weight:500;">Статья 289.Определение стоимости намеренно поврежденного или утраченного груза</p><br>

<p style="text-indent:2em;">(1) Размер убытков, понесенных вследствие намеренных повреждения или утраты груза и возмещаемых в порядке распределения общей аварии, 
устанавливается в соответствии со стоимостью груза на момент выгрузки, определяемой по счету, выставленному получателем, а при отсутствии
 счета - в соответствии со стоимостью груза на момент отгрузки. Стоимость груза на момент выгрузки включает расходы на страхование и фрахт,
 если фрахт не находится на риске грузовладельца.</p><br>

<p style="text-indent:2em;">(2) При продаже намеренно поврежденного груза размер убытков, подлежащих возмещению, определяется разностью между стоимостью груза в 
неповрежденном состоянии, которая устанавливается в соответствии с частью (1), и чистой выручкой от его продажи.</p><br>

<p style="font-weight:500;">Статья 290. Дополнительные расходы, возмещаемые по общей аварии</p><br>

<p style="text-indent:2em;">(1) На сумму расходов, возмещаемых по общей аварии (кроме расходов по заработной плате и содержанию экипажа, на топливо, оснастку и 
предметы снабжения, не замененные во время рейса), начисляются два процента, которые также относятся к расходам по общей аварии.</p><br>

<p style="text-indent:2em;">(2) К расходам по общей аварии относятся затраты, произведенные в целях получения необходимых на такие расходы средств путем залога 
судна, продажи грузов или страхования кредита.</p><br>

<p style="font-weight:500;">Статья 291. Начисление процентов на суммы, возмеща- емые по общей аварии</p><br>

<p style="text-indent:2em;">На сумму расходов и другие суммы, возмещаемые в порядке распределения общей аварии, начисляются семь процентов годовых с момента 
осуществления расходов до составления диспаши. Однако если в порядке распределения общей аварии были произведены какие-либо выплаты
 для составления диспаши, проценты на уплаченные суммы начисляются по день их уплаты.</p><br>

<p style="font-weight:500;">Статья 292. Определение контрибуционной стоимости имущества</p><br>

<p style="text-indent:2em;">(1) Контрибуционная стоимость, представляющая собой общую стоимость имущества (судна, груза и фрахта), соразмерно которой 
устанавливается размер взносов для покрытия убытков, возмещаемых в порядке распределения общей аварии, определяется в соответствии
 с положениями настоящей статьи на основании фактической чистой стоимости этого имущества на момент окончания рейса. К фактической
 стоимости прибавляется сумма, возмещаемая по общей аварии за намеренно утраченное имущество, если эта сумма в нее не включена. 
 При определении контрибуционной стоимости имущества не учитываются все дополнительные расходы, произведенные в отношении этого 
 имущества после акта общей аварии, за исключением расходов, которые относятся к общей аварии.</p><br>

<p style="text-indent:2em;">(2) Контрибуционная стоимость груза определяется его стоимостью на момент выгрузки, устанавливаемой на основании счета,
 предъявленного получателю, а при отсутствии счета - стоимостью груза на момент отгрузки. Стоимость груза включает расходы
 на страхование и фрахт, если фрахт не находится на риске грузовладельца, за вычетом суммы убытков, причиненных вследствие
 гибели или повреждения груза до или во время его выгрузки. В случае продажи груза вблизи от места назначения его контрибуционная
 стоимость определяется суммой чистой выручки от продажи с прибавлением к ней суммы, возмещаемой по общей аварии.</p><br>

<p style="text-indent:2em;">(3) Контрибуционная стоимость судна определяется без учета того обстоятельства, что судно в момент акта общей аварии было 
объектом фрахтования на условиях тайм-чартера или бербоут-чартера.</p><br>

<p style="text-indent:2em;">(4) При определении контрибуционной стоимости имущества из фрахта, находившегося на риске судовладельца, вычитаются произведенные
 в целях получения фрахта расходы (в том числе на заработную плату экипажу судна), которые не пришлось бы нести, если бы судно и 
 груз погибли при обстоятельствах, вызвавших общую аварию, и которые не могут быть отнесены к общей аварии.</p><br>

<p style="text-indent:2em;">(5) Багаж и личное имущество пассажиров, на которые не был выдан коносамент, не принимаются во внимание при определении контрибуционной
 стоимости имущества и размера взносов для покрытия убытков по общей аварии.</p><br>

<p style="font-weight:500;">Статья 293. Диспаша. Диспашеры</p><br>

<p style="text-indent:2em;">По заявлению заинтересованных лиц специалисты, обладающие знаниями и опытом в области морского права, - диспашеры
 устанавливают наличие общей аварии и составляют диспашу.</p><br>

<p style="font-weight:500;">Статья 294. Бремя доказательства</p><br>

<p style="text-indent:2em;">(1) Сторона, требующая распределения общей аварии, обязана доказать, что заявленные убытки или расходы должны быть признаны
 общей аварией.</p><br>

<p style="text-indent:2em;">(2) Все документы, на основании которых составляется диспаша, должны быть доступны для ознакомления с ними заинтересованных лиц.
 По требованию последних диспашер обязан выдавать им за плату заверенные копии таких документов.</p><br>

<p style="font-weight:500;">Статья 295. Сбор за составление диспаши</p><br>

<p style="text-indent:2em;">За составление диспаши взимается сбор, включаемый в нее и распределяемый между всеми лицами, интересы которых затронуты общей аварией,
 пропорционально понесенным ими потерям.</p><br>

<p style="font-weight:500;">Статья 296. Исправление и оспаривание диспаши</p><br>

<p style="text-indent:2em;">(1) Ошибки, обнаруженные в диспаше после ее регистрации в реестре диспаш, могут быть исправлены диспашером по собственной инициативе или по заявлению лиц,
 между которыми распределена общая авария, посредством составления дополнения к диспаше - аддендума, являющегося ее составной частью.</p><br>

<p style="text-indent:2em;">(2) В течение шести месяцев со дня получения диспаши или аддендума к ней заинтересованные лица могут оспорить диспашу в судебной инстанции,
 обязательно известив об этом диспашера и направив ему копию искового заявления.</p><br>

<p style="text-indent:2em;">(3) Диспашер вправе, а если потребуется - обязан принять участие в рассмотрении спора по диспаше в судебной инстанции и дать
 объяснения по существу дела.</p><br>

<p style="font-weight:500;">Статья 297. Исполнение диспаши</p><br>

<p style="text-indent:2em;">(1) Если диспаша не оспорена в срок, указанный в части (2) статьи 296, либо оспорена, но оставлена судебной инстанцией в силе,
 взыскание по ней производится в порядке, установленном законодательством.</p><br>

<p style="text-indent:2em;">(2) Взыскание по диспаше, которая изменена судебной инстанцией, также производится в порядке, установленном законодательством.</p><br>

<p style="font-weight:500;">Статья 298. Применение международных правовых норм</p><br>

<p style="text-indent:2em;">В случае необходимости диспашер при определении типа аварии, исчислении размеров общей аварии и составлении диспаши может руководствоваться
 международными правовыми нормами в области торгового мореплавания.</p>
 
<p></p><h4>Глава 2 ЧАСТНАЯ АВАРИЯ</h4><p></p>

<p style="font-weight:500;">Статья 299. Понятие частной аварии</p><br>

<p style="text-indent:2em;">Убытки и расходы, не подпадающие под признаки общей аварии согласно статьям 281, 284 и 285, а также указанные в статье 300,
 признаются частной аварией. Эти убытки и расходы несет тот, кто их потерпел, или тот, кто отвечает за их причинение.</p><br>

<p style="font-weight:500;">Статья 300. Убытки и расходы, не признаваемые общей аварией</p>

<p style="text-indent:2em;"></p><h6>Не признаются общей аварией даже при наличии признаков, указанных в статье 281:</h6><p></p>

<p style="text-indent:2em;">а) стоимость выброшенного за борт самовозгоревшегося груза и груза, перевозившегося на судне с нарушением правил и обычаев
 торгового мореплавания;</p><br>

<p>b) убытки, обусловленные воздействием дыма или нагревания при тушении пожара на судне;</p><br>

<p>с) убытки, нанесенные обрубанием и утратой вследствие морской опасности поврежденных частей судна;</p><br>

<p style="text-indent:2em;">d) расходы по перемещению или выгрузке груза, топлива либо предметов снабжения в порту отправления, порту захода или в укрытии,
 если повреждение судна было обнаружено в порту отправления или в порту захода, при условии, что в течение рейса не произошло 
 несчастного случая или не возникла другая чрезвычайная ситуация, связанная с повреждением, а также если такие расходы были произведены
 исключительно в целях переукладки смещенного во время рейса груза и такая переукладка не была вызвана необходимостью обеспечения общей
 безопасности;</p><br>

<p style="text-indent:2em;">е) убытки, причиненные форсированием работы двигателей, котлов, другого оборудования судна, находившегося на плаву;</p><br>

<p style="text-indent:2em;">f) расходы по заработной плате, содержанию судового экипажа, на топливо и предметы снабжения, понесенные во время устранения повреждения
 судна, даже если ремонт производился для безопасного продолжения рейса, в случаях, когда повреждение судна было обнаружено в порту 
 отправления или в порту захода, при условии, что в течение рейса не произошло несчастного случая или другого чрезвычайного происшествия,
 связанного с повреждением;</p><br>

<p style="text-indent:2em;">g) любые убытки и расходы, связанные с увеличением продолжительности рейса (вызванные простоем судна, изменением цен и другие).</p>

<p></p><h4>Глава 3 ВОЗМЕЩЕНИЕ УБЫТКОВ, ВЫЗВАННЫХ СТОЛКНОВЕНИЕМ СУДОВ</h4><p></p>

<p style="font-weight:500;">Статья 301. Понятие столкновения судов</p><br>

<p style="text-indent:2em;">В настоящей главе под столкновением судов понимаются как прямое столкновение, так и случаи, когда одно судно стало причиной повреждения
 другого судна либо находившегося на его борту груза, иного имущества или нанесения ущерба находившимся на судне людям при выполнении маневра
 либо вследствие несоблюдения правил судоходства, даже если столкновения судов как такового не произошло.</p><br>

<p style="font-weight:500;">Статья 302. Применение права при столкновении судов</p><br>

<p style="text-indent:2em;">(1) Отношения, связанные с возмещением убытков, вызванных столкновением судов во внутренних или территориальных водах, регулируются
 законодательством государства, в пределах которого произошло столкновение.</p><br>

<p style="text-indent:2em;">(2) Если столкновение судов произошло в открытом море и спор рассматривается в Республике Молдова, применяются статьи 303 - 308.</p><br>

<p style="text-indent:2em;">(3) Если столкнувшиеся суда плавают под флагом одного государства, применяется законодательство этого государства независимо от места столкновения.</p><br>

<p style="font-weight:500;">Статья 303. Сфера применения настоящей главы</p><br>

<p style="text-indent:2em;">(1) Положения настоящей главы применяются в случаях столкновения в морских или иных водах морских судов, а также морских судов и
 судов внутреннего плавания.</p><br>

<p style="text-indent:2em;">(2) Положения данной главы не влияют на предусмотренную договорами ответственность сторон, а также на право ограничить эту ответственность
 согласно настоящему кодексу.</p><br>

<p>(3) При столкновении судов оказание помощи осуществляется в соответствии с положениями статьи 64.</p><br>

<p style="font-weight:500;">Статья 304. Последствия столкновения при отсутствии вины сторон</p><br>

<p style="text-indent:2em;">Если столкновение судов произошло случайно или вследствие чрезвычайных обстоятельств, которые не могут быть предусмотрены и предотвращены,
 а также если невозможно установить причины столкновения, убытки несет потерпевшая сторона. Это положение применяется и в случаях,
 когда суда или одно из них в момент столкновения находились на якоре либо были закреплены иным способом.</p><br>

<p style="font-weight:500;">Статья 305. Столкновение по вине одной из сторон</p><br>

<p style="text-indent:2em;">Если столкновение произошло по вине одного из судов, убытки возмещаются стороной, виновной в столкновении.</p><br>

<p style="font-weight:500;">Статья 306. Столкновение по вине двух или нескольких сторон</p><br>

<p style="text-indent:2em;">(1) Если столкновение произошло по вине двух или нескольких судов, ответственность каждой из сторон определяется соразмерно степени ее вины.
 Если установить степень вины каждой из сторон невозможно, ответственность за убытки распределяется между сторонами поровну.</p><br>

<p style="text-indent:2em;">(2) Владельцы судов, виновных в столкновении, солидарно отвечают перед третьими лицами за убытки, связанные с утратой жизни или нанесением 
вреда здоровью людей, причем судовладелец, уплативший сумму бульшую, чем положено, имеет право на предъявление регрессного требования другим
 судовладельцам.</p><br>

<p style="font-weight:500;">Статья 307. Столкновение судов по вине лоцмана</p><br>

<p style="text-indent:2em;">Ответственность, установленная статьями 305 и 306, наступает и в случаях, когда столкновение судов произошло по вине лоцмана или лоцмана-оператора.</p><br>

<p style="font-weight:500;">Статья 308. Презумпция невиновности при столкновении судов</p><br>

<p>Ни одна из сторон, участвовавших в столкновении, не предполагается виновной, пока не будет доказана ее вина.</p>

<p></p><h4>Глава 4 ВОЗМЕЩЕНИЕ УЩЕРБА, ПРИЧИНЕННОГО ЗАГРЯЗНЕНИЕМ</h4><p></p>

<p style="font-weight:500;">Статья 309. Понятие ущерба, причиненного загрязнением</p><br>

<p style="text-indent:2em;">Под ущербом, причиненным загрязнением, в настоящем кодексе понимается вред, нанесенный утечкой или сбросом с судна сырой нефти, нефтепродуктов,
 других опасных для здоровья людей, для морской флоры и фауны веществ, загрязняющих окружающую среду. В этот ущерб включаются также стоимость 
 рациональных мер, принятых любым лицом после происшествия, которое могло вызвать либо вызвало загрязнение, с целью предотвращения или уменьшения
 размера ущерба, и убытки, обусловленные такими мерами.</p><br>

<p style="font-weight:500;">Статья 310. Ответственность собственника судна за заг- рязнение и освобождение от ответственноти</p><br>

<p style="text-indent:2em;">(1) Собственник судна несет ответственность за ущерб, причиненный загрязнением, за исключением случаев, предусмотренных частью (2).</p>

<p style="text-indent:2em;"></p><h6>(2) Собственник судна не отвечает за ущерб, причиненный загрязнением, если докажет, что он нанесен вследствие:</h6><p></p>

<p>а) чрезвычайных обстоятельств, которые не могут быть предусмотрены и предотвращены;</p><br>

<p>b) военных действий или народных волнений;</p><br>

<p>с) действий или бездействия третьих лиц с намерением причинить ущерб;</p><br>

<p style="text-indent:2em;">d) неисправности огней, других навигационных средств, обусловленной небрежностью или иными неправомерными действиями лиц, которые отвечают
 за содержание этих средств в порядке;</p><br>

<p style="text-indent:2em;">е) вынужденного сброса загрязняющих вод, отходов, иных вредных для окружающей среды веществ, не принятых портом.</p><br>

<p style="font-weight:500;">Статья 311. Загрязнение вследствие умысла или небрежности потерпевшего</p><br>

<p style="text-indent:2em;">Если собственник судна докажет, что ущерб, причиненный загрязнением, частично либо полностью обусловлен умыслом или небрежностью потерпевшего,
 то в зависимости от степени вины потерпевшего либо размер причитающегося ему возмещения должен быть уменьшен, либо в возмещении ему ущерба 
 должно быть отказано.</p><br>

<p style="font-weight:500;">Статья 312. Загрязнение несколькими судами</p><br>

<p style="text-indent:2em;">За причинение ущерба загрязнением несколькими судами собственники этих судов отвечают солидарно, если определить ответственность каждого в 
отдельности невозможно.</p><br>

<p style="font-weight:500;">Статья 313. Ограничение ответственности</p>

<p style="text-indent:2em;"></p><h6>(1) Ответственность собственника судна за ущерб, причиненный загрязнением в результате единичного выброса вредных веществ с его судна, может
 быть ограничена суммой, исчисляемой следующим образом:</h6><p></p>

<p>а) для судна вместимостью не более 5 000 единиц - три миллиона расчетных единиц;</p><br>

<p style="text-indent:2em;">b) для судна вместимостью более 5 000 единиц - три миллиона расчетных единиц с добавлением 420 расчетных единиц за каждую следующую единицу 
вместимости при условии, что общая сумма не будет превышать 59,7 миллиона расчетных единиц.</p><br>

<p style="text-indent:2em;">(2) Под вместимостью судна в настоящей статье понимается его чистая вместимость плюс объем машинного отделения.</p><br>

<p style="font-weight:500;">Статья 314. Фонд ограничения ответственности</p><br>

<p style="text-indent:2em;">(1) В целях ограничения ответственности за ущерб, причиненный загрязнением, в соответствии со статьей 313 собственник судна должен создать в
 судебной инстанции, где ему предъявлено требование о возмещении ущерба, ограничительный фонд, равный по сумме пределу его ответственности, 
 путем перечисления денег (депозита) или предоставления иного обеспечения, признаваемого судебной инстанцией приемлемым и достаточным.</p><br>

<p style="text-indent:2em;">(2) Если до распределения фонда ограничения ответственности собственник судна или страховщик возместил ущерб, причиненный загрязнением, 
он приобретает в пределах уплаченной суммы права, которые в соответствии с настоящей главой принадлежали бы лицу, получившему возмещение.</p><br>

<p style="text-indent:2em;">(3) Намеренные расходы и потери, поскольку они были целесообразны и осуществлены собственником судна добровольно, с целью избежать нанесения
 ущерба загрязнением или уменьшить размер такого ущерба, дают ему такие же права по отношению к фонду ограничения ответственности, 
 какие имеют другие кредиторы.</p><br>

<p style="font-weight:500;">Статья 315. Предъявление иска</p><br>

<p style="text-indent:2em;">(1) Иск о возмещении ущерба, причиненного загрязнением, может быть предъявлен собственнику судна только в соответствии с положениями 
настоящей главы.</p>

<p style="text-indent:2em;"></p><h6>(2) При условии соблюдения положения части (3) иск о возмещении ущерба, причиненного загрязнением, на основании настоящей главы или на 
иных основаниях не может быть предъявлен:</h6><p></p>

<p>а) работникам, в том числе членам экипажа, агентам собственника судна;</p><br>

<p>b) лоцману, любому другому лицу, которое, не являясь членом экипажа, выполняет работу на судне;</p><br>

<p>с) фрахтователю, в том числе фрахтователю по бербоут-чартеру;</p><br>

<p style="text-indent:2em;">d) любому лицу, выполнявшему спасательную операцию с согласия собственника судна или по указанию компетентных органов;</p><br>

<p>е) любому лицу, принимавшему предупредительные меры;</p><br>

<p style="text-indent:2em;">f) работникам и агентам лиц, указанных в пунктах с), d) и е), если ущерб не является результатом их действий или бездействия, совершенных
 умышленно или по грубой не-осторожности.</p><br>

<p style="text-indent:2em;">(3) Положения настоящей статьи не препятствуют предъявлению собственником судна регрессного иска третьим лицам.</p><br>

<p style="font-weight:500;">Статья 316. Обеспечение ответственности</p><br>

<p style="text-indent:2em;">(1) Собственник судна, перевозящего наливом в качестве груза более 2000 тонн нефти либо нефтепродуктов, должен застраховать или иным образом
 (получением банковской либо другой гарантии) обеспечить свою ответственность за ущерб, причиняемый загрязнением, на сумму, равную пределу его
 ответственности, согласно статье 313.</p><br>

<p style="text-indent:2em;">(2) Иск о возмещении ущерба, причиненного загрязнением нефтью или нефтепродуктами, может быть предъявлен непосредственно страховщику ответственности
 собственника судна или иному лицу, предоставившему обеспечение ответственности собственника судна. Лицо, которому предъявлен такой иск, вправе на 
 основании статей 313 и 314 ограничить свою ответственность и создать фонд, равный по сумме ее пределу, даже в случаях, когда согласно статье 352 
 его ответственность не может быть ограничена.</p><br>

<p style="text-indent:2em;">(3) Ответчик по иску, указанному в части (2), вправе выдвинуть против требований потерпевшего возражения, на которые мог бы сослаться собственник 
судна, за исключением ссылок на неплатежеспособность собственника судна или ликвидацию его предприятия.</p><br>

<p style="text-indent:2em;">(4) Ответчик по иску, указанному в части (2), может быть освобожден от ответственности, если докажет, что ущерб, причиненный загрязнением нефтью
 или нефтепродуктами, был умышленно нанесен собственником судна.</p><br>

<p style="text-indent:2em;">(5) По требованию ответчика по иску, указанному в части (2), собственник судна должен быть привлечен к участию в деле в качестве соответчика.</p><br>

<p style="font-weight:500;">Статья 317. Свидетельство об обеспечении ответственности</p><br>

<p style="text-indent:2em;">(1) На борту судна, перевозящего наливом в качестве груза более 2000 тонн нефти или нефтепродуктов, должно находиться свидетельство, удостоверяющее,
 что ответственность собственника судна за ущерб, причиняемый загрязнением нефтью или нефтепродуктами, обеспечена согласно статье 316.</p><br>

<p style="text-indent:2em;">(2) Порядок выдачи, проверки и признания свидетельства, указанного в части (1), устанавливается центральным отраслевым органом.</p><br>

<p style="font-weight:500;">Статья 318. Другие положения кодекса, применяемые при установлении ответственности за ущерб,

причиненный загрязнением</p><br>

<p style="text-indent:2em;">При установлении предусмотренной настоящей главой ответственности за ущерб, причиненный загрязнением, применяются также статьи 352, 355, части (2)
 и (3) статьи 356 и статьи 357 - 359.</p>

<p></p><h4>Глава 5 ВОЗМЕЩЕНИЕ ЯДЕРНОГО УЩЕРБА</h4><p></p>

<p style="font-weight:500;">Статья 319. Понятия ядерного инцидента и ядерного ущерба</p><br>

<p style="text-indent:2em;">(1) Ядерным инцидентом признаются происшествие или ряд происшествий, вызванных одной и той же причиной и приведших к нанесению ядерного ущерба.</p><br>

<p style="text-indent:2em;">(2) Ядерный ущерб включает ущерб, причиненный людям, имуществу или окружающей среде вследствие проявления радиоактивных свойств либо сочетания
 радиоактивных свойств с токсическими, взрывными и другими опасными свойствами ядерного топлива, радиоактивных веществ, находившихся на ядерном
 судне, или их отходов, а также расходы, произведенные в целях предотвращения такого ущерба либо уменьшения его размера.</p><br>

<p style="text-indent:2em;">(3) Если наряду с ядерным причинен иной ущерб, который не может быть отделен от ядерного, он тоже признается ядерным.</p><br>

<p style="font-weight:500;">Статья 320. Применение права при ядерном ущербе</p><br>

<p style="text-indent:2em;">(1) Положения настоящей главы применяются как в случае нанесения ядерного ущерба ядерным судном, находящимся во внутренних водах Республики Молдова,
 так и в случае причинения ядерного ущерба ядерным судном, плавающим под флагом Республики Молдова, независимо от того, где причинен ущерб.</p><br>

<p style="text-indent:2em;">(2) Положения настоящей главы не применяются при установлении ответственности оператора ядерного судна за ядерный ущерб, причиненный этому судну,
 его оборудованию, находившимся на его борту топливу и запасам продовольствия.</p><br>

<p style="font-weight:500;">Статья 321. Оператор ядерного судна. Его ответственность</p><br>

<p style="text-indent:2em;">(1) Оператором ядерного судна является лицо, организующее эксплуатацию такого судна на основании разрешения, выданного компетентным органом государства,
 под флагом которого плавает судно.</p><br>

<p style="text-indent:2em;">(2) Оператор ядерного судна несет ответственность за ядерный ущерб с момента поступления ядерного топлива, радиоактивных веществ на ядерное судно
 до момента передачи ядерного топлива, радиоактивных веществ и их отходов другому лицу, уполномоченному законом и отвечающему за ядерный ущерб,
 который может быть причинен.</p><br>

<p style="font-weight:500;">Статья 322. Освобождение оператора от ответственности</p><br>

<p style="text-indent:2em;">(1) Оператор ядерного судна не несет ответственности за ядерный ущерб, если докажет, что он причинен в результате военных действий, народных
 волнений или стал следствием чрезвычайных обстоятельств, которые не могут быть предусмотрены и предотвращены.</p><br>

<p style="text-indent:2em;">(2) Если оператор докажет, что ядерный ущерб причинен вследствие умысла потерпевшего, он освобождается от ответственности.</p><br>

<p style="font-weight:500;">Статья 323. Регрессный иск</p>

<p style="text-indent:2em;"></p><h6>(1) Оператор ядерного судна, возместивший ядерный ущерб, вправе предъявить регрессный иск лицу:</h6><p></p>

<p>а) умышленно причинившему ядерный ущерб;</p><br>

<p style="text-indent:2em;">b) осуществившему подъем затонувшего ядерного судна без согласия на это оператора или разрешения государства, под флагом которого плавало судно
, если ущерб стал следствием подъема судна;</p><br>

<p style="text-indent:2em;">с) принявшему на себя обязанность возместить ядерный ущерб по согласованию с оператором ядерного судна.</p><br>

<p style="text-indent:2em;">(2) В случаях, предусмотренных частью (1), регрессный иск может быть предъявлен также лицом, которое возместило потерпевшим ядерный ущерб в
 соответствии с частью (1) статьи 329.</p><br>

<p style="font-weight:500;">Статья 324. Ограничение ответственности оператора</p><br>

<p style="text-indent:2em;">(1) Ответственность оператора ядерного судна за ядерный ущерб, нанесенный в результате ядерного инцидента с судном, ограничивается суммой в 99,
75 млн. расчетных единиц, включая судебные издержки.</p><br>

<p style="text-indent:2em;">(2) Если после ядерного инцидента предъявляются требования о возмещении ущерба, причиненного людям и имуществу, часть суммы, указанной в части (1),
 в размере 69,825 млн. расчетных единиц предназначается исключительно для возмещения ущерба, нанесенного людям, а другая часть суммы в размере 29,925
 млн. расчетных единиц - для возмещения ущерба, причиненного имуществу. Если первой части суммы окажется недостаточно для оплаты требований о возмещении
 ущерба, нанесенного людям, неоплаченный остаток таких требований подлежит оплате из второй части суммы наряду с оплатой требований о возмещении ущерба,
 причиненного имуществу.</p><br>

<p style="text-indent:2em;">(3) Суммы, предназначенные для оплаты требований о возмещении ядерного ущерба, распределяются между истцами пропорционально их требованиям.</p><br>

<p style="font-weight:500;">Статья 325. Солидарная ответственность</p><br>

<p style="text-indent:2em;">(1) Операторы нескольких ядерных судов, виновные в нанесении ядерного ущерба, который не может быть объективно распределен между ними, 
несут солидарную ответственность, но каждый в пределах, установленных частью (1) статьи 324.</p><br>

<p style="text-indent:2em;">(2) Оператор ядерного судна, возместивший ущерб, указанный в части (1), имеет право потребовать от операторов других ядерных судов возмещения
 в размере, пропорциональном степени вины каждого из них, если же установить это невозможно, - в равных долях.</p><br>

<p style="font-weight:500;">Статья 326. Ответственность владельца ядерного судна</p><br>

<p style="text-indent:2em;">Если ядерное судно причинило ядерный ущерб и на момент инцидента разрешение, указанное в части (1) статьи 321, не было выдано, ответственность
 возлагается на владельца ядерного судна. В этом случае ограничение ответственности оператора ядерного судна на основании статьи 324 не 
 применяется.</p><br>

<p style="font-weight:500;">Статья 327. Возмещение ущерба, понесенного служащими оператора</p><br>

<p style="text-indent:2em;">(1) Если в результате ядерного инцидента служащим оператора ядерного судна при исполнении служебных обязанностей был причинен ущерб, 
оператор, который обязан уплачивать за них взносы социального страхования, несет ответственность согласно положениям настоящей главы.</p><br>

<p style="text-indent:2em;">(2) Выплата возмещения, пособий и/или пенсий социального страхования в связи с причинением ущерба, ставшего следствием ядерного инцидента,
 производится в порядке, предусмотренном законодательством Республики Молдова.</p><br>

<p style="font-weight:500;">Статья 328. Свидетельство об обеспечении ответственности оператора</p><br>

<p style="text-indent:2em;">(1) Ответственность оператора ядерного судна должна быть застрахована или обеспечена в финансовом отношении иным образом в размере,
 установленном частью (1) статьи 324.</p><br>

<p style="text-indent:2em;">(2) Страхование или иное финансовое обеспечение, предусмотренное частью (1), не требуется, если оператором судна является государство.
 Однако на борту такого судна должно быть свидетельство, выданное и зарегистрированное соответствующим государственным органом, удостоверяющее,
 что оператором судна является государство и что ответственность оператора гарантируется в размере, установленном частью (1) статьи 324. 
 Условия выдачи свидетельства устанавливает центральный отраслевой орган.</p><br>

<p style="font-weight:500;">Статья 329. Иск о возмещении ядерного ущерба</p><br>

<p style="text-indent:2em;">(1) Иск о возмещении ядерного ущерба может быть предъявлен непосредственно страховщику или иному лицу, предоставившему оператору ядерного
 судна финансовое обеспечение в соответствии с частью (1) статьи 328.</p><br>

<p style="text-indent:2em;">(2) По требованию ответчика по иску о возмещении ядерного ущерба оператор ядерного судна должен быть привлечен к участию в деле в 
качестве соответчика.</p><br>

<p style="font-weight:500;">Статья 330. Фонд ограничения ответственности</p><br>

<p style="text-indent:2em;">(1) В случаях, когда размер причиненного ядерного ущерба превышает предел ответственности оператора ядерного судна, установленный частью (1)
 статьи 324, по требованию оператора, истца или компетентного органа государства, выдавшего разрешение на эксплуатацию ядерного судна, в 
 судебной инстанции этого государства оператор создает фонд для обеспечения ограниченной ответственности в размере указанного предела путем
 внесения денежных сумм (депозита) или предоставления иного обеспечения, признаваемого судебной инстанцией приемлемым и достаточным.</p><br>

<p style="text-indent:2em;">(2) При создании и распределении фонда ограничения ответственности оператора ядерного судна применяются часть (3) статьи 356 и статья 357.</p><br>

<p style="font-weight:500;">Статья 331. Пределы ответственности оператора</p><br>

<p style="text-indent:2em;">При установлении ответственности оператора ядерного судна за ядерный ущерб применяются часть (2) статьи 354, статьи 355 и 359.</p>

<p></p><h4>Глава 6 СПАСАНИЕ СУДОВ И ДРУГОГО ИМУЩЕСТВА</h4><p></p>

<p style="font-weight:500;">Статья 332. Сфера применения настоящей главы</p><br>

<p style="text-indent:2em;">(1) Правила, установленные настоящей главой, применяются по отношению к спасанию находившихся в опасности морских судов, а также к 
спасанию морскими судами судов внутреннего плавания, любых плавающих, буксируемых объектов, независимо от того, в каких водах была 
оказана помощь по спасанию, в случаях, когда судебное разбирательство проводится в Республике Молдова.</p><br>

<p style="text-indent:2em;">(2) Положения настоящей главы не применяются по отношению к спасанию закрепленных или плавающих платформ, прибрежных буровых установок,
 если такие платформы и установки находятся в местах проведения работ по изысканию, разработке или эксплуатации минеральных ресурсов 
 морского дна.</p><br>

<p style="text-indent:2em;">(3) Ни одно из положений настоящей главы не может быть без согласия государства использовано в качестве основания для изъятия, ареста
 или задержания некоммерческих грузов, находящихся в собственности государства и обладающих при проведении спасательных операций согласно
 принципам международного права суверенным иммунитетом.</p><br>

<p style="font-weight:500;">Статья 333. Применение права при спасании</p><br>

<p style="text-indent:2em;">(1) Если спасавшее и спасенное суда плавают под флагом одного государства, независимо от места происшествия, вызвавшего необходимость 
спасания, применяется законодательство этого государства.</p><br>

<p style="text-indent:2em;">(2) При распределении вознаграждения за спасание между владельцем и экипажем, а также между членами экипажа спасавшего судна применяется
 законодательство государства, под флагом которого плавает это судно.</p><br>

<p style="font-weight:500;">Статья 334. Право на вознаграждение за спасание</p><br>

<p style="text-indent:2em;">(1) Давшие положительный результат действия по спасанию судна, находившихся на его борту грузов, иного имущества, а также по сохранению 
фрахта и платы за перевозку пассажиров, багажа либо иного имущества, как и действия по предотвращению нанесения вреда окружающей среде,
 дают право на получение вознаграждения. Если попытки спасания не дали положительного результата и не принесли материальной пользы, 
 право на вознаграждение утрачивается.</p><br>

<p>(2) За спасание людей вознаграждение не выплачивается.</p><br>

<p style="text-indent:2em;">(3) Лица, участвовавшие в спасании людей и одновременно оказавшие иные услуги, имеют право на вознаграждение, причитающееся за спасание
 судна или другого имущества либо за предотвращение или уменьшение вреда, наносимого окружающей среде.</p><br>

<p style="text-indent:2em;">(4) Вознаграждение выплачивается и в том случае, если судно, оказавшее услуги по спасанию, принадлежит владельцу спасенного судна.</p><br>

<p>(5) Вознаграждение за спасание предоставляется в виде денежных сумм, услуг или имущества.</p><br>

<p style="font-weight:500;">Статья 335. Запрет на спасание</p><br>

<p style="text-indent:2em;">Если услуги по спасанию судна или другого имущества, которое не находится и не находилось на борту судна, были предоставлены наперекор
 четкому запрету владельца или капитана судна либо владельца указанного имущества, вознаграждение за спасание не выплачивается.</p><br>

<p style="font-weight:500;">Статья 336. Размер вознаграждения</p><br>

<p style="text-indent:2em;">(1) Размер вознаграждения за спасание определяется соглашением сторон, а при его отсутствии - судебной инстанцией. Всякое соглашение о
 спасании, заключенное в момент и под влиянием опасности, по требованию одной из сторон может быть признано недействительным или изменено
 судебной инстанцией, если последняя признает, что условия соглашения несправедливы.</p><br>

<p style="text-indent:2em;">(2) Размер вознаграждения за спасание может быть уменьшен или в вознаграждении может быть отказано, если необходимость спасания была вызвана
 по вине спасателей или они совершили иные противоправные действия.</p><br>

<p style="font-weight:500;">Статья 337. Критерии, используемые при установлении размера вознаграждения</p>

<p style="text-indent:2em;"></p><h6>(1) Размер вознаграждения за спасание устанавливается с учетом:</h6><p></p>

<p>а) стоимости спасенного судна и другого имущества;</p><br>

<p>b) характера и степени опасности;</p><br>

<p style="text-indent:2em;">с) мастерства и усилий спасателей по спасанию людей, судна, другого имущества, по предотвращению или уменьшению вреда, наносимого окружающей среде;</p><br>

<p>d) степени достигнутого спасателями успеха;</p><br>

<p>е) затраченного спасателями времени, понесенных ими расходов и убытков;</p><br>

<p>f) оперативности в оказании услуг;</p><br>

<p style="text-indent:2em;">g) риска ответственности, которому подвергались спасатели, иных рисков, которым подвергались спасатели или их оборудование;</p><br>

<p>h) наличия и использования судов, а также оборудования, предназначенных для спасательных операций;</p><br>

<p>i) степени готовности, эффективности использования спасательного оборудования и его стоимости.</p><br>

<p style="text-indent:2em;">(2) Установленное вознаграждение выплачивается всеми лицами, интересы которых связаны со спасенными судном и другим имуществом, 
пропорционально стоимости этого имущества. Если вознаграждение выплачивается одним из указанных лиц, оно имеет право на предъявление
 регресcного иска другим заинтересованным лицам.</p><br>

<p style="font-weight:500;">Статья 338. Максимальный размер вознаграждения</p><br>

<p style="text-indent:2em;">(1) Размер вознаграждения за спасание, включая возмещаемые судебные расходы, не может превышать стоимости спасенных судна и другого имущества.</p><br>

<p>(2) Выплата вознаграждения за спасание осуществляется в той валюте, в какой оно получено.</p><br>

<p style="font-weight:500;">Статья 339. Распределение вознаграждения</p><br>

<p style="text-indent:2em;">(1) Распределение вознаграждения между лицами, участвовавшими в спасательной операции, производится в соответствии с договоренностью 
заинтересованных сторон, а при ее отсутствии - судебной инстанцией на основании критериев, предусмотренных статьей 337.</p><br>

<p style="text-indent:2em;">(2) Распределение вознаграждения между судовладельцем, членами экипажа и другими лицами, принимавшими участие в спасательной операции,
 осуществляется с учетом вклада каждого в конкретные действия по спасанию на основании договоренности заинтересованных сторон, а при 
 ее отсутствии - на основании решения судебной инстанции.</p><br>

<p style="text-indent:2em;">(3) Часть (2) не применяется, если спасательная операция проводилась судами, выполняющими такие операции в качестве профессиональной деятельности.</p><br>

<p style="font-weight:500;">Статья 340. Специальная компенсация</p><br>

<p style="text-indent:2em;">Если лицо, участвовавшее в спасании судна, которое само по себе или груз которого могли причинить вред окружающей среде, не получило 
вознаграждения, оно имеет право на получение от владельца судна специальной компенсации, равной понесенным расходам, которая может быть
 увеличена не более чем на 30 процентов от этих расходов. Однако если судебная инстанция с учетом критериев, приведенных в статье 337, 
 посчитает справедливым и целесообразным увеличить такую компенсацию, это увеличение не должно превышать 100 процентов от понесенных 
 спасателем расходов.</p><br>

<p style="font-weight:500;">Статья 341. Обязанности по предоставлению обеспечения</p><br>

<p style="text-indent:2em;">(1) По просьбе лица, принимавшего участие в спасании, лицо, ответственное за выплату вознаграждения или специальной компенсации, 
предоставляет надлежащее обеспечение требования спасателя, включая судебные расходы.</p><br>

<p style="text-indent:2em;">(2) Владелец спасенного судна должен использовать все возможности для гарантирования того, что владельцы грузов до выдачи последних
 предоставят достаточное обеспечение требования спасателя к ним, включая судебные расходы.</p><br>

<p style="text-indent:2em;">(3) Без согласия спасателя спасенные судно и другое имущество не могут быть перемещены из порта или места, куда они были доставлены
 после завершения спасательной операции, до тех пор, пока не будет предоставлено надлежащее обеспечение требования спасателя в отношении 
 судна и имущества.</p>

<p></p><h4>Глава 7 МОРСКОЙ ПРОТЕСТ</h4><p></p>

<p style="font-weight:500;">Статья 342. Оформление морского протеста</p><br>

<p style="text-indent:2em;">(1) Если во время плавания или стоянки судна имело место морское происшествие, которое может послужить основанием для предъявления 
судовладельцу имущественных требований, капитан судна в целях обеспечения доказательств для защиты прав и законных интересов судовладельца должен в установленном 
порядке оформить морской протест.</p><br>

<p style="text-indent:2em;">(2) В морском протесте должны быть изложены обстоятельства происшествия и причины, вызвавшие его, включая информацию о причиненном 
ущербе и мерах, принятых для предотвращения его нанесения или уменьшения размера.</p><br>

<p style="font-weight:500;">Статья 343. Предъявление протеста</p>

<p style="text-indent:2em;"></p><h6>Морской протест предъявляется:</h6><p></p>

<p style="text-indent:2em;">a) в порту Республики Молдова - нотариусу или иному должностному лицу, на которое законодательством Республики Молдова возложено 
совершение нотариальных действий;</p><br>

<p style="text-indent:2em;">b) в иностранном порту - консулу Республики Молдова или компетентному должностному лицу иностранного государства в порядке, 
установленном законодательством этого государства.</p><br>

<p style="font-weight:500;">Статья 344. Срок предъявления протеста</p>

<p style="text-indent:2em;"></p><h6>Морской протест предъявляется:</h6><p></p>

<p>a) в течение 24 часов с момента морского происшествия - если оно произошло в порту;</p><br>

<p style="text-indent:2em;">b) в течение 24 часов с момента прибытия судна или его капитана в ближайший порт после морского происшествия - если оно 
произошло во время плавания.</p><br>

<p style="font-weight:500;">Статья 345. Предъявление протеста с опозданием</p><br>

<p style="text-indent:2em;">(1) Если предъявить морской протест в сроки, установленные статьей 344, невозможно, причины этого должны быть указаны в 
морском протесте.</p><br>

<p style="text-indent:2em;">(2) При наличии оснований для предположения, что вследствие морского происшествия находившийся на судне груз получил повреждения,
 морской протест должен быть предъявлен до открытия люков. Выгрузка груза до предъявления морского протеста может быть начата лишь
 в случае крайней необходимости.</p><br>

<p style="font-weight:500;">Статья 346. Доказательства</p><br>

<p style="text-indent:2em;">(1) В подтверждение обстоятельств морского происшествия, изложенных в морском протесте, капитан судна обязан одновременно с 
предъявлением морского протеста либо в срок, не превышающий семи дней с момента прибытия в порт или со дня происшествия, 
если оно имело место в порту, представить для ознакомления нотариусу либо иному должностному лицу, указанному в статье 343, 
судовой журнал и заверенную своей подписью выписку из него.</p><br>

<p style="text-indent:2em;">(2) В случае утраты судового журнала в морском протесте должны быть изложены обстоятельства и причины утраты.</p><br>

<p style="font-weight:500;">Статья 347. Акт о морском протесте</p><br>

<p style="text-indent:2em;">Нотариус или иное должностное лицо, указанное в статье 343, на основании морского протеста, других представленных документов, 
включая судовой журнал, сведений, полученных при опросе капитана, а в случае необходимости - и других членов экипажа, 
составляет акт о морском протесте и заверяет его в установленном порядке.</p><br>

<p style="font-weight:500;">Статья 348. Составление актов о морском протесте консулами</p><br>

<p style="text-indent:2em;">Принятие морских протестов от капитанов иностранных судов и составление актов о морском протесте могут осуществляться консулами,
 представляющими свои государства в Республике Молдова, на условиях взаимности.</p>

<p></p><h4>РАЗДЕЛ X ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ СУДОВЛАДЕЛЬЦА</h4><p></p>

<p></p><h4>Глава 1 ПРЕДЕЛЫ ОТВЕТСТВЕННОСТИ СУДОВЛАДЕЛЬЦА</h4><p></p>

<p style="font-weight:500;">Статья 349. Сфера применения настоящей главы</p><br>

<p style="text-indent:2em;">Положения настоящей главы применяются при ограничении ответственности судовладельцев, а также распространяются на фрахтователей,
 являющихся перевозчиками, операторов судов и лиц, осуществляющих спасательные операции.</p><br>

<p style="font-weight:500;">Статья 350. Ответственность судовладельца</p><br>

<p style="text-indent:2em;">Судовладелец отвечает по своим обязательствам принадлежащим ему имуществом, на которое согласно законодательству может быть 
обращено взыскание.</p><br>

<p style="font-weight:500;">Статья 351. Ограничение ответственности</p>

<p style="text-indent:2em;"></p><h6>(1) Ответственность судовладельца ограничивается пределами, предусмотренными статьей 354, по требованиям, 
которые возникли вследствие:</h6><p></p>

<p style="text-indent:2em;">a) смерти или получения телесного повреждения лицом, находившимся на судне, утраты или причинения вреда находившемуся
 на борту судна имуществу;</p><br>

<p>b) нанесения ущерба лицам или имуществу, не находившимся на судне, в связи с эксплуатацией судна.</p><br>

<p style="text-indent:2em;">(2) Если ущерб, указанный в пункте b) части (1), причинен лицом, не находившимся на борту судна, судовладелец, ответственный
 за его действия и упущения, может ограничить свою ответственность при условии, что действия либо упущения этого лица были 
 связаны с судовождением или управлением судном, с погрузкой, перевозкой или выгрузкой груза, багажа, ручной клади, с посадкой
 или высадкой пассажиров.</p><br>

<p style="font-weight:500;">Статья 352. Умышленно причиненный ущерб</p><br>

<p style="text-indent:2em;">Ответственность судовладельца не может быть ограничена, если доказано, что причиненный ущерб стал следствием его собственных 
действий или бездействия, совершенных умышленно, или по самонадеянности, с сознанием возможности нанесения ущерба, 
или по грубой неосторожности.</p><br>

<p style="font-weight:500;">Статья 353. Исключения из правил об ограничении ответственности</p>

<p style="text-indent:2em;"></p><h6>Ответственность судовладельца не может быть ограничена на основании статьи 351 в случаях предъявления требований, касающихся:</h6><p></p>

<p style="text-indent:2em;">a) выплаты вознаграждения за спасание, в том числе специальной компенсации, и взносов для покрытия убытков по общей аварии;</p><br>

<p style="text-indent:2em;">b) возмещения ущерба, причиненного утратой жизни или нанесением вреда здоровью либо имуществу членов экипажа, других служащих 
судовладельца или спасателя, должностные обязанности которых связаны с судном, а также в случаях предъявления таких требований
 наследниками указанных лиц или гражданами, которые имеют право на получение от них содержания, если согласно законодательству,
 регулирующему отношения этих лиц с судовладельцем, его ответственность по их требованиям не может быть ограничена либо она 
 превышает пределы, предусмотренные статьей 354;</p><br>

<p>c) подъема или удаления затонувшего имущества;</p><br>

<p style="text-indent:2em;">d) возмещения ущерба, причиненного загрязнением, когда ответственность за нанесение такого ущерба регламентируется положениями
 главы 4 раздела IX;</p><br>

<p style="text-indent:2em;">e) возмещения ядерного ущерба, когда ответственность за его причинение регламентируется положениями главы 5 раздела IX.</p><br>

<p style="font-weight:500;">Статья 354. Пределы ответственности</p>

<p style="text-indent:2em;"></p><h6>(1) Ответственность лиц, указанных в статьях 349 и 358, может быть ограничена в следующих пределах:</h6><p></p>

<p style="text-indent:2em;"></p><h6>1) в отношении требований, связанных с утратой жизни или нанесением вреда здоровью:</h6><p></p>

<p>a) при вместимости судна не более 500 единиц - 333 000 расчетных единиц;</p>

<p style="text-indent:2em;"></p><h6>b) при вместимости судна более 500 единиц к сумме указанной в подпункте a) данного пункта, добавляется:</h6><p></p>

<p>- на каждую единицу от 501 до 3 000 единиц - 500 расчетных единиц;</p><br>

<p>- на каждую единицу от 3 001 до 30 000 единиц - 333 расчетные единицы;</p><br>

<p>- на каждую единицу от 30 001 до 70 000 единиц - 250 расчетных единиц;</p><br>

<p>на каждую единицу свыше 70 000 единиц - 167 расчетных единиц;</p>

<p style="text-indent:2em;"></p><h6>2) в отношении любых других требований:</h6><p></p>

<p>а) при вместимости судна не более 500 единиц - 167 000 расчетных единиц;</p>

<p style="text-indent:2em;"></p><h6>b) при вместимости судна более 500 единиц к сумме, указанной в подпункте а) данного пункта, добавляется:</h6><p></p>

<p>- на каждую единицу от 501 до 30 000 единиц - 167 расчетных единиц;</p><br>

<p>- на каждую единицу от 30 001 до 70 000 единиц - 125 расчетных единиц;</p><br>

<p>- на каждую единицу свыше 70 000 единиц - 83 расчетные единицы.</p><br>

<p style="text-indent:2em;">(2) Если сумма, исчисленная в связи с требованиями, обусловленными утратой жизни или нанесением вреда здоровью, недостаточна для 
полной оплаты таких требований, сумма, исчисленная в отношении любых других требований, используется для возмещения неоплаченного остатка,
 который покрывается наряду с пропорциональной оплатой требований, указанных в пункте 2) части (1).</p>

<p style="text-indent:2em;"></p><h6>(3) При ограничении ответственности:</h6><p></p>

<p style="text-indent:2em;">a) под вместимостью судов с механическим двигателем понимается чистая вместимость судна плюс объем машинного отделения, 
под вместимостью остальных судов - их чистая вместимость;</p><br>

<p style="text-indent:2em;">b) пределы ответственности лица, осуществлявшего спасательную операцию и действовавшего вне судна или только на судне, 
которое оно спасало, исчисляются исходя из вместимости судна в 1 500 единиц.</p><br>

<p style="text-indent:2em;">(4) Положения настоящей статьи применяются к совокупности всех требований, предъявляемых в связи с каким-либо одним 
морским происшествием судовладельцу или лицу, указанному в статье 358.</p><br>

<p style="font-weight:500;">Статья 355. Встречное требование</p><br>

<p style="text-indent:2em;">Если судовладелец, ответственность которого ограничивается на основании статьи 351, имеет право на встречное требование, 
вытекающее из одного и того же морского происшествия, ограничение ответственности применяется лишь в отношении разницы, 
образующейся в результате зачета сумм взаимных требований.</p><br>

<p style="font-weight:500;">Статья 356. Фонд ограничения ответственности</p><br>

<p style="text-indent:2em;">(1) В целях обеспечения своей ответственности судовладелец может создать ограничительный фонд в судебной инстанции, 
в которой ему предъявлено или может быть предъявлено требование.</p><br>

<p style="text-indent:2em;">(2) Фонд ограничения ответственности создается в размере суммы, исчисленной согласно статье 354, путем перечисления этой 
суммы (депозита) судебной инстанции либо предоставления иного обеспечения, признаваемого судебной инстанцией приемлемым и достаточным.</p><br>

<p style="text-indent:2em;">(3) Фонд ограничения ответственности предназначается только для оплаты требований, ответственность по которым может 
быть ограничена.</p><br>

<p style="text-indent:2em;">(4) Фонд не создается, если все лица, участвующие в споре об ответственности судовладельца, являются организациями, 
принадлежащими Республике Молдова, или ее гражданами.</p><br>

<p style="font-weight:500;">Статья 357. Распределение фонда</p><br>

<p style="text-indent:2em;">(1) Все вопросы, касающиеся распределения фонда, созданного в соответствии со статьей 356, вправе решать только судебная инстанция,
 в которой он создан.</p><br>

<p style="text-indent:2em;">(2) Фонд ограничения ответственности распределяется между предъявившими требования лицами пропорционально суммам таких требований,
 установленным судебной инстанцией.</p><br>

<p style="font-weight:500;">Статья 358. Передача права ограничения ответственности</p><br>

<p style="text-indent:2em;">Если какое-либо из требований, по которым ответственность судовладельца может быть ограничена, предъявлено непосредственно лицу,
 за действия и упущения которого судовладелец отвечает, это лицо может воспользоваться правом ограничения ответственности, 
 установленным для судовладельца, если не доказано, что ущерб причинен указанным лицом умышленно.</p><br>

<p style="font-weight:500;">Статья 359. Недействительность соглашений об ограничении ответственности</p><br>

<p style="text-indent:2em;">Соглашение, предусматривающее ограничение ответственности судовладельца, установленной настоящей главой, недействительно.</p>

<p></p><h4>Глава 2 ПРИВИЛЕГИРОВАННЫЕ ТРЕБОВАНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 360. Последовательность удовлетворения требований</p>

<p style="text-indent:2em;"></p><h6>Преимущественному удовлетворению перед другими требованиями, в том числе требованиями, обеспеченными морским залогом, 
подлежат в порядке, соответствующем перечислению:</h6><p></p>

<p style="text-indent:2em;">a) требования, вытекающие из трудовых правоотношений, требования о возмещении ущерба, причиненного утратой жизни или
 нанесением вреда здоровью, и после их полного удовлетворения - требования по социальному страхованию;</p><br>

<p>b) требования, связанные с нанесением ядерного ущерба и загрязнением морской среды;</p><br>

<p>c) требования о взимании канальных и портовых сборов;</p><br>

<p>d) требования о вознаграждении за спасание и об уплате взносов для покрытия убытков по общей аварии;</p><br>

<p style="text-indent:2em;">e) требования о возмещении убытков, обусловленных столкновением судов или иным морским происшествием, повреждением портовых
 сооружений, иного имущества, находящегося в порту, а также навигационного оборудования;</p><br>

<p style="text-indent:2em;">f) требования, возникшие вследствие действий капитана, совершенных в силу предоставленных ему прав с целью сохранения судна
 или продолжения рейса;</p><br>

<p>g) требования о возмещении убытков, понесенных в результате утраты или повреждения груза, багажа;</p><br>

<p>h) требования о внесении фрахта, других платежей, причитающихся за перевозку груза;</p><br>

<p>i) требования относительно оплаты оказанных в порту услуг.</p><br>

<p style="font-weight:500;">Статья 361. Средства для преимущественного удовлетворения требований</p>

<p style="text-indent:2em;"></p><h6>(1) Требования, указанные в пунктах a) - g) статьи 360, подлежат преимущественному удовлетворению из:</h6><p></p>

<p>a) стоимости судна;</p><br>

<p style="text-indent:2em;">b) фрахта и платы за провоз пассажиров и багажа, причитающихся за рейс, в ходе которого возникло основание для
 предъявления требования;</p><br>

<p style="text-indent:2em;">c) суммы взносов для покрытия убытков по общей аварии, полагающейся судовладельцу при распределении общей аварии;</p><br>

<p style="text-indent:2em;">d) возмещения, причитающегося судовладельцу за потерю фрахта, а также за причиненные судну и не устраненные повреждения;</p><br>

<p style="text-indent:2em;">e) вознаграждения, полагающегося судовладельцу за спасание, имевшее место до окончания рейса, за вычетом сумм, 
которые должны быть уплачены судовладельцем членам экипажа.</p>

<p style="text-indent:2em;"></p><h6>(2) Требования, указанные в пунктах c), d), f) и h) статьи 360, подлежат преимущественному удовлетворению из:</h6><p></p>

<p>a) стоимости груза, не сданного получателю;</p><br>

<p>b) возмещения за повреждение груза;</p><br>

<p style="text-indent:2em;">c) суммы взносов для покрытия убытков по общей аварии, полагающейся владельцу груза при распределении общей аварии.</p><br>

<p style="font-weight:500;">Статья 362. Пропорциональное удовлетворение требований</p><br>

<p style="text-indent:2em;">(1) Требования, приведенные в статье 360, удовлетворяются в порядке очередности и в пределах каждой очереди пропорционально
 их размеру. Однако требования, предусмотренные пунктами d) и f) указанной статьи, удовлетворяются в пределах этих очередей
 в порядке, обратном времени их возникновения.</p><br>

<p>(2) Требования, возникшие в связи с одним и тем же событием, считаются возникшими одновременно.</p><br>

<p style="font-weight:500;">Статья 363. Удовлетворение требований, относящихся к последнему рейсу</p><br>

<p style="text-indent:2em;">(1) Требования, относящиеся к последнему рейсу, удовлетворяются в первоочередном порядке, до аналогичных требований, 
связанных с предшествующими рейсами.</p><br>

<p style="text-indent:2em;">(2) Требования, приведенные в пункте a) статьи 360, относящиеся к нескольким рейсам, приравниваются к таким же 
требованиям по последнему рейсу.</p><br>

<p style="font-weight:500;">Статья 364. Срок действия привилегий</p><br>

<p style="text-indent:2em;">Право преимущественного удовлетворения требований прекращается по истечении одного года со дня их возникновения,
 за исключением требований, указанных в пункте f) статьи 360, - право их преимущественного удовлетворения прекращается
 по истечении шести месяцев со дня возникновения.</p>

<p></p><h4>Глава 3 ДОГОВОРЫ О МОРСКОМ ЗАЛОГЕ И МОРСКОЙ ИПОТЕКЕ СУДНА</h4><p></p>

<p style="font-weight:500;">Статья 365. Понятия договоров о морском залоге и морской ипотеке судна</p><br>

<p style="text-indent:2em;">(1) Согласно договору о морском залоге (далее - договор о залоге) судно, зарегистрированное в судовом реестре или в судовой книге,
 закладывается судовладельцем (залогодателем) с целью обеспечения требований, предъявленных кредитором (залогодержателем).</p><br>

<p style="text-indent:2em;">(2) В соответствии с договором о морской ипотеке (далее - договор об ипотеке) судно, зарегистрированное в судовом реестре или в
 судовой книге, закладывается его собственником (залогодателем) в целях получения денежных средств и обеспечения денежных долговых
 обязательств перед кредитором (залогодержателем).</p><br>

<p style="text-indent:2em;">(3) Залогодержателем может быть любое юридическое или физическое лицо, которому в соответствии с законодательством судно может 
быть отчуждено.</p><br>

<p style="text-indent:2em;">(4) Залог (ипотека) не означает отчуждения судна залогодержателю, равно как и залогодатель не может рассматриваться как лицо, 
утратившее право собственности на заложенное судно.</p><br>

<p style="font-weight:500;">Статья 366. Реквизиты договора</p>

<p style="text-indent:2em;"></p><h6>В договоре о залоге (ипотеке) судна, оформленном письменно, должны быть указаны:</h6><p></p>

<p>a) наименования и местонахождение сторон;</p><br>

<p>b) суть обеспеченных залогом (ипотекой) требований и срок их выполнения;</p><br>

<p>c) название, оценка судна и его местонахождение;</p><br>

<p>d) иные условия по соглашению сторон.</p><br>

<p style="font-weight:500;">Статья 367. Регистрация залога и ипотеки</p><br>

<p style="text-indent:2em;">(1) Залог судна должен быть зарегистрирован в едином реестре залогов в нотариальной конторе по месту регистрации судна, 
а также в судовом реестре или в судовой книге.</p><br>

<p>(2) Ипотека судна регистрируется в судовом реестре или в судовой книге.</p>

<p style="text-indent:2em;"></p><h6>(3) В единый реестр залогов, в судовой реестр или в судовую книгу вносятся все сведения, которые приводятся в заявлении 
о регистрации залога (ипотеки) судна:</h6><p></p>

<p>a) название, тип, тоннаж судна, место его регистрации, официальный номер;</p><br>

<p>b) наименование и адрес залогодержателя;</p><br>

<p>c) наименование и адрес залогодателя;</p><br>

<p>d) размер обеспечения залога (ипотеки) судна;</p><br>

<p>e) дата окончания действия договора о залоге (ипотеке) судна;</p><br>

<p>f) подтверждение наличия залога (ипотеки) судна.</p><br>

<p style="text-indent:2em;">(4) За регистрацию залога (ипотеки) судна, выдачу свидетельства о регистрации, за предоставление выписок из 
соответствующих реестров в установленном порядке взимается сбор.</p><br>

<p style="text-indent:2em;">(5) Залог (ипотека) судна регистрируется и осуществляется в соответствии с законодательством государства, 
в котором было зарегистрировано судно.</p><br>

<p style="font-weight:500;">Статья 368. Ведение книги записи залогов</p><br>

<p>(1) Залогодатель обязан вести книгу записи залогов в порядке, установленном законодательством.</p><br>

<p style="text-indent:2em;">(2) Залогодатель несет ответственность за несвоевременность внесения и недостоверность внесенных 
в книгу записи залогов сведений.</p><br>

<p style="font-weight:500;">Статья 369. Последующие залоги (ипотеки)</p><br>

<p style="text-indent:2em;">(1) Если предметом залога (ипотеки) становится заложенное (обеспеченное ипотекой) судно, залоговое право 
предшествующего залогодержателя сохраняет силу.</p><br>

<p style="text-indent:2em;">(2) Требования последующего залогодержателя удовлетворяются из стоимости судна после удовлетворения требований 
предшествующего залогодержателя.</p><br>

<p style="text-indent:2em;">(3) Залогодатель обязан сообщать каждому последующему залогодержателю обо всех предшествующих залогах (ипотеках) 
судна и возмещать убытки, причиненные неисполнением этой обязанности.</p><br>

<p style="font-weight:500;">Статья 370. Уступка и перевод долга</p><br>

<p style="text-indent:2em;">(1) Если залогодержатель уступает обеспеченные залогом требования другому лицу или залогодатель переводит долг, 
возникший из обеспеченных залогом обязательств, на другое лицо, залог сохраняет силу, если договором о залоге не предусмотрено иное.</p><br>

<p style="text-indent:2em;">(2) Залогодержатель вправе уступить ипотеку судна другому лицу только вместе с обеспеченными ею денежными 
долговыми обязательствами.</p><br>

<p style="text-indent:2em;">(3) В случаях, предусмотренных частями (1) и (2), в соответствующий реестр вносится запись об изменениях, 
относящихся к залогу (ипотеке) судна.</p><br>

<p style="text-indent:2em;">(4) При получении документа залогодержателем - об уступке требований или залогодателем - о переводе долга в 
книге записи залогов должна быть сделана соответствующая запись с указанием лица, к которому переходят все
 права залогодержателя или обязательства залогодателя.</p><br>

<p style="font-weight:500;">Статья 371. Поддержание предмета залога (ипотеки) в надлежащем состоянии</p><br>

<p style="text-indent:2em;">Залогодатель обязан поддерживать судно, обремененное залогом (обеспеченное ипотекой), в надлежащем состоянии. 
Если невыполнение этой обязанности залогодателем ведет к значительному обесцениванию судна, залогодержатель вправе
 потребовать принудительной продажи предмета залога (ипотеки), даже если срок исполнения обязательств не наступил.</p><br>

<p style="font-weight:500;">Статья 372. Право обращения взыскания на предмет залога (ипотеки)</p><br>

<p style="text-indent:2em;">(1) Залогодержатель приобретает право обратить взыскание на судно, обремененное залогом (обеспеченное ипотекой), 
если к наступлению срока исполнения обязательств они не будут исполнены, за исключением случаев, когда согласно 
законодательству или договору о залоге (ипотеке) такое право возникает позже или может быть осуществлено ранее.</p><br>

<p style="text-indent:2em;">(2) За счет судна, обремененного залогом (обеспеченного ипотекой), залогодержатель вправе удовлетворить все свои 
требования, включая проценты и убытки, причиненные просрочкой исполнения залогодателем своих обязательств. 
Возмещению подлежат также расходы залогодержателя по удовлетворению обеспеченных залогом (ипотекой) требований.</p><br>

<p style="font-weight:500;">Статья 373. Банкротство залогодателя</p><br>

<p style="text-indent:2em;">Банкротство залогодателя не ограничивает права залогодержателя на обращение взыскания на заложенное судно для 
его продажи, независимо от процедур, применяемых в отношении иного имущества обанкротившегося должника для 
удовлетворения требований кредиторов.</p><br>

<p style="font-weight:500;">Статья 374. Основания для обращения взыскания</p><br>

<p style="text-indent:2em;">(1) Обращение взыскания на заложенное судно производится по решению судебной инстанции, если законодательством
 не предусмотрено иное.</p><br>

<p style="text-indent:2em;">(2) Продажа судна, на которое обращается взыскание, осуществляется в соответствии с законодательством, 
если договором о залоге не предусмотрено иное.</p><br>

<p style="font-weight:500;">Статья 375. Принудительная продажа судна</p><br>

<p style="text-indent:2em;">(1) Лицо, предъявившее требование о принудительной продаже заложенного судна, должно представить в судебную 
инстанцию выписку из соответствующего реестра с приложением перечня наименований и адресов держателей 
зарегистрированных залогов по морским требованиям, указанным в статье 360.</p><br>

<p style="text-indent:2em;">(2) Сообщение о вынесении судебной инстанцией решения о продаже заложенного судна публикуется в одной из
 центральных газет в течение трех дней подряд.</p>

<p style="text-indent:2em;"></p><h6>(3) Сообщение, указанное в части (2), должно содержать:</h6><p></p>

<p>a) наименование судебной инстанции, вынесшей решение, дату принятия решения;</p><br>

<p>b) причины и основания продажи судна;</p><br>

<p>c) способ, время и место продажи;</p><br>

<p>d) информацию о судне;</p><br>

<p style="text-indent:2em;">e) обращение к кредиторам с предложением в течение 60 дней со дня опубликования сообщения заявить судебной
 инстанции о своих требованиях в отношении судна;</p><br>

<p>f) иные необходимые сведения.</p>

<p style="text-indent:2em;"></p><h6>(4) До продажи судна судебная инстанция должна в срок, не превышающий 30 дней, отправить извещение о времени
 и месте продажи заказным письмом с уведомлением о вручении:</h6><p></p>

<p>a) органу, зарегистрировавшему судно;</p><br>

<p>b) всем держателям зарегистрированных залогов по морским требованиям, указанным в статье 360, (ипотек).</p><br>

<p style="font-weight:500;">Статья 376. Последствия принудительной продажи судна</p>

<p style="text-indent:2em;"></p><h6>(1) В случае принудительной продажи судна право залога (ипотеки), за исключением тех залогов (ипотек), 
ответственность по которым с согласия залогодержателей взял на себя покупатель судна, других обременений 
прекращается при условии, что:</h6><p></p>

<p>a) на момент продажи судно находилось на территории Республики Молдова и</p><br>

<p style="text-indent:2em;">b) продажа его осуществлена в соответствии с законодательством Республики Молдова, в том числе со статьей 
375 настоящего кодекса.</p><br>

<p style="text-indent:2em;">(2) При соблюдении требований, предусмотренных частью (1), судебная инстанция по просьбе покупателя судна 
выдает ему сертификат, подтверждающий, что судно продано и не обременено залогами (не обеспечено ипотеками),
 за исключением тех, ответственность по которым с согласия залогодержателей взял на себя покупатель.</p><br>

<p style="text-indent:2em;">(3) При представлении сертификата, указанного в части (2), орган, зарегистрировавший судно, должен исключить 
из судового реестра или судовой книги все записи о залогах по морским требованиям (ипотеках), относящиеся к 
данному судну, за исключением записей о тех залогах (ипотеках), ответственность по которым взял на себя покупатель судна.</p><br>

<p style="text-indent:2em;">(4) Если покупателем судна является юридическое либо физическое лицо Республики Молдова, судно может быть 
зарегистрировано на имя покупателя.</p><br>

<p style="text-indent:2em;">(5) Если покупателем судна является иностранное юридическое либо физическое лицо, орган, зарегистрировавший 
судно, должен по заявлению такого покупателя выдать ему сертификат об исключении судна из судового реестра или
 судовой книги для его регистрации в иностранном реестре либо произвести соответствующую перерегистрацию судна
 на имя покупателя.</p><br>

<p style="font-weight:500;">Статья 377. Распределение суммы, вырученной от принудительной продажи судна</p><br>

<p style="text-indent:2em;">Расходы, связанные с арестом или задержанием судна и последующей его продажей, включая расходы на содержание 
судна с момента его ареста или задержания, на репатриацию экипажа, покрываются из средств, полученных от реализации 
судна. Остаток этих средств распределяется пропорционально размеру предъявленных требований в порядке очередности, 
предусмотренном статьей 369.</p><br>

<p style="font-weight:500;">Статья 378. Прекращение права залога (ипотеки) судна</p>

<p style="text-indent:2em;"></p><h6>(1) Право залога (ипотеки) судна прекращается при:</h6><p></p>

<p>a) исполнении обеспеченных залогом (ипотекой) обязательств;</p><br>

<p style="text-indent:2em;">b) гибели судна, за исключением случаев, когда залогодержатель в соответствии с договором о страховании 
может получить возмещение ущерба, нанесенного гибелью судна;</p><br>

<p>c) переходе прав на предмет залога (ипотеки) к залогодержателю;</p><br>

<p>d) принудительной продаже судна;</p><br>

<p>e) наличии соглашения залогодателя и залогодержателя;</p><br>

<p>f) иных обстоятельствах, предусмотренных законодательством.</p><br>

<p style="text-indent:2em;">(2) В случаях, предусмотренных частью (1), орган, зарегистрировавший залог (ипотеку) судна, при представлении
 доказательств прекращения права залога (ипотеки) этого судна вносит запись о прекращении указанного права в 
 соответствующий реестр.</p>

<p></p><h4>РАЗДЕЛ XI ПРЕТЕНЗИИ И ИСКИ</h4><p></p>

<p></p><h4>Глава 1 ОБЩИЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 379. Право предъявления претензий и исков</p>

<p style="text-indent:2em;"></p><h6>(1) Право предъявления претензий и исков имеют:</h6><p></p>

<p>a) фрахтователь - в случае неподачи судна или подачи его с опозданием;</p><br>

<p>b) получатель или отправитель - в случае утраты груза, при условии представления коносамента;</p><br>

<p style="text-indent:2em;">c) получатель или отправитель - в случае недостачи или повреждения груза, при условии представления коносамента,
 а также коммерческого акта или аналогичного документа, составленного по правилам, действующим в порту назначения;</p><br>

<p style="text-indent:2em;">d) получатель - в случае просрочки в доставке или задержки в выдаче груза, при условии представления коносамента;</p><br>

<p style="text-indent:2em;">e) предъявитель багажной квитанции - в случае утраты или просрочки в доставке багажа, предъявитель коммерческого акта
 - в случае недостачи или повреждения багажа;</p><br>

<p style="text-indent:2em;">f) фрахтователь судна, отправитель или получатель груза - в случае перебора провозных платежей, при условии представления коносамента.</p><br>

<p style="text-indent:2em;">(2) При предъявлении претензий, связанных с морской перевозкой грузов, которая осуществлялась по накладной или иному 
перевозочному документу, вместо коносамента представляется документ, на основании которого перевозился груз.</p><br>

<p style="text-indent:2em;">(3) Отсутствие коммерческого акта не лишает права предъявления претензий и исков, если будет доказано, что в 
составлении акта было отказано и этот отказ был обжалован.</p><br>

<p style="font-weight:500;">Статья 380. Передача права предъявления претензий и исков</p>

<p style="text-indent:2em;"></p><h6>(1) Передача другим юридическим или физическим лицам права предъявления претензий и исков допускается только 
в случаях его уступки:</h6><p></p>

<p>a) отправителем - получателю груза;</p><br>

<p>b) получателем - отправителю груза;</p><br>

<p>c) получателем или отправителем груза - транспортно-экспедитор-ской организации либо страховщику.</p><br>

<p style="text-indent:2em;">(2) Передача права предъявления претензий и исков удостоверяется записью о переуступке в коносаменте или 
ином перевозочном документе.</p><br>

<p style="font-weight:500;">Статья 381. Коммерческий акт</p><br>

<p style="text-indent:2em;">(1) Обстоятельства, которые могут служить основанием для привлечения к ответственности фрахтователя судна, перевозчика, 
отправителя, получателя груза, пассажира, удостоверяются коммерческими актами или аналогичными документами. В иностранном 
порту такие обстоятельства удостоверяются в соответствии с правилами, действующими в этом порту.</p>

<p style="text-indent:2em;"></p><h6>(2) Коммерческий акт составляется для удостоверения:</h6><p></p>

<p style="text-indent:2em;">a) несоответствия наименования, массы либо количества мест груза или багажа в натуре данным, приведенным 
в перевозочном документе;</p><br>

<p>b) повреждения груза, багажа;</p><br>

<p>c) обнаружения груза, багажа без документов или документов без груза, багажа;</p><br>

<p>d) возвращения перевозчику похищенного груза, багажа.</p><br>

<p style="text-indent:2em;">(3) Форма документов, указанных в части (1), порядок их составления, а также порядок удостоверения обстоятельств, не 
требующих составления документов, устанавливаются центральным отраслевым органом.</p><br>

<p style="font-weight:500;">Статья 382. Проценты за пользование чужими денежными средствами</p><br>

<p style="text-indent:2em;">(1) При удовлетворении требований, вытекающих из отношений, регулируемых настоящим кодексом, на выплачиваемую сумму 
начисляются проценты в размере средней ставки банковского процента, установленного в месте нахождения (жительства) кредитора.
 Проценты начисляются со дня предъявления письменного требования об уплате соответствующей суммы по день ее уплаты.</p><br>

<p style="text-indent:2em;">(2) Настоящая статья не применяется в случаях предъявления требований, касающихся возмещения убытков в порядке 
распределения общей аварии.</p>

<p></p><h4>Глава 2 ПРЕТЕНЗИИ</h4><p></p>

<p style="font-weight:500;">Статья 383. Предъявление претензий</p><br>

<p style="text-indent:2em;">(1) До предъявления перевозчику требований, связанных с морской перевозкой грузов, пассажиров и багажа, обязательно 
предъявление ему претензий.</p><br>

<p style="text-indent:2em;">(2) Претензии предъявляются перевозчику, который выполнял морскую перевозку или в соответствии с договором о морской 
перевозке должен был ее выполнить.</p><br>

<p style="text-indent:2em;">(3) Возникающие в связи с морской перевозкой пассажиров и багажа претензии могут быть предъявлены перевозчику, порту 
отправления или порту назначения - по усмотрению заявителя.</p><br>

<p style="font-weight:500;">Статья 384. Предъявление претензий при смешанных перевозках</p><br>

<p style="text-indent:2em;">(1) Претензии, возникающие при перевозке груза несколькими видами транспорта, предъявляются перевозчику, 
доставившему груз в конечный пункт.</p><br>

<p style="text-indent:2em;">(2) В случаях, когда конечным пунктом транспортировки груза является железнодорожная, автомобильная станция либо аэропорт, 
претензии предъявляются перевозчику в порядке, установленном для соответствующего вида транспорта.</p><br>

<p style="font-weight:500;">Статья 385. Подтверждение претензии</p><br>

<p style="text-indent:2em;">(1) К заявлению о претензии, которое должно быть оформлено письменно, прилагаются документы, подтверждающие право предъявления претензии.
 Перевозочные документы представляются только в подлиннике.</p><br>

<p style="text-indent:2em;">(2) К претензии по поводу утраты, недостачи или повреждения груза кроме документов, подтверждающих право предъявления претензии, 
прилагается документ, удостоверяющий стоимость отправленного груза.</p><br>

<p style="font-weight:500;">Статья 386. Срок для предъявления претензий</p><br>

<p style="text-indent:2em;">Претензии к перевозчику, вытекающие из договоров о морской перевозке груза, о морской перевозке пассажира, 
могут быть предъявлены в течение шести месяцев.</p><br>

<p style="font-weight:500;">Статья 387. Сроки рассмотрения претензий</p><br>

<p style="text-indent:2em;">(1) При морской перевозке перевозчик обязан рассмотреть предъявленную претензию в течение трех месяцев и уведомить 
заявителя о ее удовлетворении или отклонении. В случаях смешанной перевозки претензии рассматриваются в течение шести месяцев.</p><br>

<p style="text-indent:2em;">(2) Со дня предъявления претензии перевозчику течение срока исковой давности приостанавливается до получения ответа на претензию 
или окончания срока, установленного для дачи ответа.</p>

<p></p><h4>Глава 3 ИСКОВАЯ ДАВНОСТЬ</h4><p></p>

<p style="font-weight:500;">Статья 388. Срок исковой давности по требованиям, вытекающим из договора о морской перевозке груза</p>

<p style="text-indent:2em;"></p><h6>При предъявлении требований, вытекающих из договора о морской перевозке груза, в соответствии с законодательством применяется 
срок исковой давности, который исчисляется:</h6><p></p>

<p style="text-indent:2em;">a) по требованиям о возмещении ущерба, нанесенного утратой груза, - по истечении 30 дней со дня, когда груз должен был быть выдан, 
при смешанной перевозке - по истечении четырех месяцев со дня приема груза к перевозке;</p><br>

<p style="text-indent:2em;">b) по требованиям о возмещении убытков, причиненных недостачей или повреждением груза, просрочкой в доставке груза, о возврате перебора 
или взыскании недобора провозных платежей - со дня выдачи груза, если же груз не был выдан - со дня, когда он должен был быть выдан;</p><br>

<p style="text-indent:2em;">c) по требованиям о возмещении ущерба, нанесенного недостачей груза, образовавшейся при массовых перевозках однородных грузов наливом, 
навалом или насыпью, в случаях, предусмотренных соглашением сторон, - со дня подписания ими акта ежегодной инвентаризации;</p><br>

<p style="text-indent:2em;">d) по требованиям о возмещении убытков, обусловленных неподачей судна либо подачей его с опозданием, об оплате простоя судна, о выплате 
премий за досрочную погрузку либо выгрузку груза - со дня окончания месяца, следующего за тем, в котором началась или должна 
была начаться перевозка;</p><br>

<p style="text-indent:2em;">e) во всех остальных случаях - с того дня, когда произошло событие, послужившее основанием для предъявления требования.</p><br>

<p style="font-weight:500;">Статья 389. Срок исковой давности по иным требованиям</p>

<p style="text-indent:2em;"></p><h6>(1) Согласно законодательству срок исковой давности применяется при предъявлении требований, вытекающих из договоров о морской перевозке 
пассажира, о фрахтовании судна на время, о лизинге судна, о морской буксировке, об оказании лоцманских услуг, о морском страховании, 
а также требований, связанных со сделками, совершенными капитаном судна, с возмещением убытков, нанесенных столкновением судов, с 
осуществлением спасательных операций. Этот срок исчисляется:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) по требованиям, вытекающим из договора о морской перевозке пассажира:</h6><p></p>

<p style="text-indent:2em;">- в отношении пассажиров - со дня, когда пассажир покинул или должен был покинуть судно, если же требование предъявлено в связи 
с причинением во время перевозки здоровью пассажира вреда, ставшего причиной смерти, - со дня смерти пассажира, но по прошествии 
не более чем трех лет со дня, когда пассажир покинул судно;</p><br>

<p>- в отношении багажа - со дня его выдачи или со дня, когда он должен был быть выдан;</p><br>

<p style="text-indent:2em;">b) по требованиям, вытекающим из договоров о фрахтовании судна на время, о лизинге судна, о морской буксировке, об оказании лоцманских 
услуг, о морском страховании, а также требованиям, связанным со сделками, совершенными капитаном судна на основании статей 62, 72, 
73, - со дня возникновения права на иск;</p><br>

<p>c) по требованиям о возмещении убытков, нанесенных столкновением судов, - со дня столкновения судов;</p><br>

<p style="text-indent:2em;">d) по требованиям, связанным с проведением спасательных операций, - со дня окончания спасательной операции.</p><br>

<p style="text-indent:2em;">(2) В предусмотренных частью (2) статьи 306 случаях предъявления регрессных требований применяется годичный срок исковой давности, 
исчисляемый со дня уплаты соответствующей суммы.</p><br>

<p style="font-weight:500;">Статья 390. Срок исковой давности по требованиям о возмещении ущерба, причиненного загрязнением, и ядерного ущерба</p><br>

<p style="text-indent:2em;">(1) При предъявлении требований о возмещении ущерба, причиненного загрязнением, и ядерного ущерба согласно законодательству применяется
 срок исковой давности, который исчисляется со дня, когда потерпевший узнал или должен был узнать, что ему нанесен ущерб, но этот срок не
 должен быть более шести лет со дня происшествия, вызвавшего загрязнение, и более пятнадцати лет со дня происшествия, обусловившего ядерный
 ущерб.</p><br>

<p style="text-indent:2em;">(2) Если ответственность оператора ядерного судна согласно законодательству государства, где было выдано разрешение на эксплуатацию судна,
 застрахована или обеспечена в финансовом отношении иным образом на срок, превышающий пятнадцать лет, требование о возмещении ядерного ущерба
 погашается по истечении срока, на который ответственность была фактически обеспечена.</p><br>

<p style="font-weight:500;">Статья 391. Применение общих сроков исковой давности</p><br>

<p style="text-indent:2em;">В случаях предъявления требований, для которых сроки исковой давности настоящим кодексом не предусмотрены, применяются общие сроки 
исковой давности, установленные гражданским законодательством Республики Молдова, если подписанными ею международными договорами для
 таких требований не определены иные сроки.</p><br>

<p style="font-weight:500;">Статья 392. Приостановление течения срока исковой давности при общей аварии</p><br>

<p style="text-indent:2em;">Если исчисление суммы требования зависит от расчета убытков по общей аварии, течение срока исковой давности приостанавливается на период
 со дня вынесения диспашером постановления о наличии общей аварии до дня получения диспаши заинтересованным лицом.</p><br>

<p style="font-weight:500;">Статья 393. Продление срока исковой давности</p><br>

<p style="text-indent:2em;">Сроки исковой давности, установленные для требований, предусмотренных настоящим кодексом, продлеваются судебными инстанциями в случаях, 
когда судно, к которому относятся такие требования, не могло находиться в течение срока исковой давности во внутренних водах 
Республики Молдова.</p><br>

<p><a href="https://www.legis.md/cautare/getResults?doc_id=108662&amp;lang=ru">Источник</a></p>


</div>
`