import React from 'react'
import { Field } from 'redux-form'
import { Link } from 'react-router-dom'

import Dropdown from '../UI/dropdown'

import dict from '../shared/dictionaries'
import Widget from '../shared/components/widget'

import weights from '../../files/weights.pdf'
import dimensions from '../../files/dimensions.pdf'

export default props => {
  const { t, dataMoveForMobile } = props
  return (
    <Widget title={t('home.country.information')} dataMoveForMobile={dataMoveForMobile}>
      <div className="panel-content-row">
        <div className="select-row">
          <Field name="country" component={Dropdown} items={dict.countries} />
        </div>

        <Link to="#" className="link link-download">
          <span className="ico ico-download" />
          <span>{t('home.truck.restrictions')}</span>
        </Link>
        <Link to="#" className="link link-download">
          <span className="ico ico-download" />
          <span>{t('home.truck.weights.sizes')}</span>
        </Link>
        <Link to="#" className="link link-download">
          <span className="ico ico-download" />
          <span>{t('home.public.holidays')}</span>
        </Link>
      </div>
      <div className="panel-content-row">
        <div className="link">
          <a href={weights} download="weights">
            {t('home.table.truck.weights')}
          </a>
        </div>
        <div className="link">
          <a href={dimensions} download="dimensions">
            {t('home.table.truck.sizes')}
          </a>
        </div>
      </div>
    </Widget>
  )
}
