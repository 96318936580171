import _ from 'lodash'
import { fromJS } from 'immutable'

import {
  AUTH_MODAL_OPEN,
  AUTH_MODAL_CLOSE,
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  AUTH_LOGOUT,
  AUTH_LOGOUT_ONLY,
  SET_AUTH_REDIRECT_PATH,
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_FAILURE,
  AUTH_HYDRATE_FROM_LOCAL_STORAGE,
  AUTH_REGISTRATION_START,
  AUTH_REGISTRATION_SUCCESS,
  AUTH_REGISTRATION_FAILURE,
  AUTH_REGISTER_PROFILE_START,
  AUTH_REGISTER_PROFILE_SUCCESS,
  AUTH_REGISTER_PROFILE_FAILURE,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  AUTH_CHANGE_EMAIL_SUCCESS,
  USER_GET_PROFILE_START,
  USER_GET_PROFILE_SUCCESS,
  USER_GET_PROFILE_FAILURE,
  USER_SAVE_PROFILE_SUCCESS,
} from '../actions/action-types'
import { DEFAULT_LANG, DEFAULT_TIMEZONE, AUTH_MODES } from '../constants'
import { AuthState } from './types'
import { UserProfileModel, JwtModel } from '../models'
import { getJwt } from '../utils'

// ------------------------------------
//
// ------------------------------------
const initialState: AuthState = {
  userId: undefined,
  isOpen: false,
  mode: AUTH_MODES.LOGIN,

  error: undefined,
  loading: false,
  authRedirectPath: '/',

  requestedAt: undefined,
  jwt: { ...JwtModel },
  email: '',
  isMainDevice: false,
  // -------
  accessToken: undefined,
  sessionId: undefined,
  refreshToken: undefined,
  // -------
  clientId: undefined,
  culture: DEFAULT_LANG, // 'en-US',
  timezone: DEFAULT_TIMEZONE, //'Eastern Standard Time',
  drawerOpen: false,
  loggedOutReason: '',
  status: undefined,
  passwordRecovery: {
    status: undefined,
    error: undefined,
  },
  profile: fromJS({
    loaded: false,
    loading: false,
    data: { ...UserProfileModel },
  }),
}
// ------------------------------------
//
// ------------------------------------
const showModal = (state: AuthState, { mode }) => {
  return {
    ...state,
    isOpen: true,
    mode,
    error: undefined,
  }
}
const closeModal = (state: AuthState) => {
  return { ...state, isOpen: false }
}

const authStart = (state: AuthState, action) => {
  return {
    ...state,
    requestedAt: action.requestedAt,
    loading: true,
    error: undefined,
  }
}

const rehydrateState = (state: AuthState, data, status?: string) => {
  const { accessToken, profile, ...rest } = data
  let newState = {
    ...state,
    accessToken,
    ...rest,

    isOpen: false,
    error: undefined,
    loading: false,
    status,
  }
  if (accessToken) {
    newState = {
      ...newState,
      jwt: getJwt(accessToken),
    }
  }
  if (profile) {
    const { languages = '' } = profile
    profile.languages = languages ? languages.split(',').map(x => +x) : []
    profile.showEmail = !!profile.showEmail
    newState = {
      ...newState,
      profile: fromJS({
        loaded: true,
        loading: false,
        data: { ...UserProfileModel, ...profile },
      }),
    }
  }
  return newState
}

const authSuccess = (state: AuthState, { data, status }) => {
  return rehydrateState(state, data, status)
}

const authFailure = (state: AuthState, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  }
}

const authLogout = (state: AuthState, action: string, cb) => {
  cb && cb();
  return { ...initialState, loggedOutReason: action }
}

const setAuthRedirectPath = (state: AuthState, action) => {
  return { ...state, authRedirectPath: action.path }
}

const refreshSuccess = (state: AuthState, action) => {
  return authSuccess(state, action)
}
const refreshFailure = (state: AuthState, action) => {
  return authLogout(state, action, () => { window.location.href = '/' })
}

const hydrateState = (state: AuthState, { data = {} }) => {
  return _.isEmpty(data) ? state : rehydrateState(state, data)
}

const setProfile = (state: AuthState, profile: AuthState['profile']) => {
  // prepare some props
  const { languages } = profile
  profile.languages = languages ? languages.split(',').map(x => +x) : []
  profile.showEmail = !!profile.showEmail
  // new profile
  const data = { ...UserProfileModel, ...profile }
  const newProfile = state.profile
    .set('loading', false)
    .set('loaded', true)
    .set('data', fromJS(data))
  return { ...state, profile: newProfile }
}

const changeEmailSuccess = (state, { email }) => {
  return {
    ...state,
    jwt: { ...state.jwt, email },
    email,
  }
}

// ---------------------------------------
// reducer
// ---------------------------------------
export default (state = initialState, action: any = {}): AuthState => {
  const { type } = action

  if (type.indexOf('AUTH_RECOVER_PASSWORD_') !== -1) {
    const { error } = action
    return {
      ...state,
      passwordRecovery: {
        status: type,
        error,
      },
    }
  }

  switch (type) {
    case AUTH_HYDRATE_FROM_LOCAL_STORAGE:
      return hydrateState(state, action)

    case AUTH_MODAL_OPEN:
      return showModal(state, action)
    case AUTH_MODAL_CLOSE:
      return closeModal(state)

    case AUTH_REGISTRATION_START:
    case AUTH_REGISTRATION_FAILURE:
    case AUTH_REGISTRATION_SUCCESS:
      return state

    case AUTH_REGISTER_PROFILE_START:
    case AUTH_REGISTER_PROFILE_FAILURE:
      return state

    case AUTH_START:
      return authStart(state, action)

    case AUTH_SUCCESS:
    case AUTH_REGISTER_PROFILE_SUCCESS:
      return authSuccess(state, action)

    case AUTH_FAILURE:
      return authFailure(state, action)
    case AUTH_LOGOUT:
      return authLogout(state, action, () => { window.location.href = '/' })
    case AUTH_LOGOUT_ONLY:
      return authLogout(state, action, null)
    case SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action)
    case AUTH_REFRESH_SUCCESS:
      return refreshSuccess(state, action)
    case AUTH_REFRESH_FAILURE:
      return refreshFailure(state, action)

    case AUTH_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        profile: state.profile.mergeIn(['data', 'legacyPassword'], 0),
      }
    }

    case AUTH_CHANGE_EMAIL_SUCCESS: {
      return changeEmailSuccess(state, action)
    }

    case USER_GET_PROFILE_START: {
      return { ...state, profile: state.profile.set('loading', true) }
    }
    case USER_GET_PROFILE_FAILURE: {
      return { ...state, profile: state.profile.set('loading', false) }
    }
    case USER_SAVE_PROFILE_SUCCESS: {
      return setProfile(state, action.data.profile)
    }
    case USER_GET_PROFILE_SUCCESS: {
      return setProfile(state, action.payload.profile)
    }

    default:
      return state
  }
}
