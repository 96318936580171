import React, { Component, Fragment } from 'react'
import weights from '../../../files/weights.pdf'
import Temperature_mode_RU from '../../../files/RU/Temperature_mode_RU.pdf'
import Temperature_mode_RO from '../../../files/RO/Temperature_mode_RO.pdf'
import Temperature_mode_EN from '../../../files/EN/Temperature_mode_EN.pdf'

export default class TooltipForDownloadTemp extends Component {

    render() {
        const { t, langId } = this.props;
        console.log('langId', langId);
        return (
            <div>
                {t('cargo.temperature.mode.description')}
                <a href={langId === 1 ? Temperature_mode_RU : langId === 3 ? Temperature_mode_RO : Temperature_mode_EN} target="_blank" rel="noopener noreferrer">«{t('cargo.temperature.mode.description.link')}»</a>
            </div>
        );
    }
}