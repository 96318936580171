export const md13 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>ПРИКАЗ об утверждении Правил, касающихся использования 
системы транзита на территории Республики Молдова</h4><p></p>

<p></p><h4>ПРАВИЛА, касающиеся использования системы транзита на 
территории Республики Молдова</h4><p></p>

<p></p><h4>Глава 1 Общие положения</h4><p></p>

    <p style="font-weight:500;">1. Настоящие правила содержат положения об использовании системы транзита на территории Республики Молдова по процедуре T1 для транзитных товаров, перемещаемых железнодорожным либо автотранспортом, за исключением грузов,
	перевозимых под прикрытием книжки МДП.</p><br>

    <p style="font-weight:500;">2. Положения, содержащиеся в настоящих правилах, не распространяются на случаи транзита имущества, перево-зимого физическими лицами, которое в соответствии с действующим законодательством освобождено от уплаты таможенных платежей,
	имущества, являющегося предметом экспрессной доставки, получателем которого являются физические лица, и товаров, перевозимых в международных почтовых отправлениях.</p><br> 

    <p style="font-weight:500;">3. Процедура Т1 применяется ко всем товарам, находящимся в транзите, согласно пункту 1, соответственно на товары, не прошедшие таможенное оформление при въезде в страну,
	товары, прошедшие таможенное оформление при экспорте в пределах страны, товары, перемещаемые под таможенным режимом транзита между двумя таможенными органами (за исключением товаров,
	прошедших таможенное оформление при экспорте в пределах страны по процедурам – 1000 (окончательный экспорт), 2100 (временный экспорт в рамках операции по переработке вне таможенной территории),
	2148 (временный экспорт товаров в рамках операции по переработке вне таможенной территории, осуществленной после предварительного импорта замещающих продуктов того же стандарта), 2300 (временный
	экспорт с целью последующего возвращения товаров в прежнем состоянии),  независимо от природы и происхождения товаров.</p>

    <p style="text-indent:2em;"></p><h6>4. При применении положений настоящих правил используются следующие понятия:</h6><p></p> 

    <p style="text-indent:2em;">a) таможенный режим транзита – таможенный режим, при котором товары перемещаются по таможенной территории под таможенным надзором от одного таможенного органа к другому, без оплаты таможенных
	платежей на импорт и экспорт и без применения мер политической экономики, если законодательством не предусмотрено иное. Таможенный режим транзита может быть национальным, если, как минимум, один
	из таможенных органов, вовлеченных в процедуру транзита, является внутренним или международным, когда таможенные органы, вовлеченные в таможенный режим транзита, являются пограничными таможенными органами;</p><br>

    <p style="text-indent:2em;">b) таможенный орган отправления – таможенный пост/таможня, где начинается операция по транзиту с помещением товаров в режим транзита;</p><br> 

    <p style="text-indent:2em;">c) таможенный орган назначения – таможенный пост/таможня, куда товары, помещенные в режим транзита, должны быть доставлены для закрытия данного режима;</p><br> 

    <p style="text-indent:2em;">d) перевозчик – лицо, которое фактически перемещает товары через таможенную границу и которое несет ответственность за использование транспортного средства и предоставление товара в таможенный
	орган назначения в установленные сроки и в неизменном состоянии;</p><br>

    <p style="text-indent:2em;">e) главный ответственный – юридическое лицо, зарегистрированное таможенным органом, которое изъявляет желание осуществить транзитную операцию посредством подачи декларации о транзите, предусмотренной
	для этой цели, а также берет на себя ответственность и дает финансовую или другого типа гарантию, предусмотренную законодательством, за операцию транзита;</p><br>

    <p style="text-indent:2em;">f) транзитная декларация – односторонний документ в электронной форме, посредством которого главный ответственный изъявляет желание поместить товары в режим транзита по процедуре T1, в формате и структуре,
	установленных Приказом Таможенной службы № 346-O от 24.12.2009 „Об утверждении Технических правил о порядке печатания, использования и заполнения таможенной декларации” (далее - Технические правила), которая
	создается, структурируется, обрабатывается, хранится и передается пользователем таможенной информационной системы в таможенный орган посредством информационных процедур (без бумажного носителя) и на которой ставится
	усиленная квалифицированная электронная подпись, выданная в соответствии с Законом № 91 от 29.05.2014 г. „Об электронной подписи и электронном документе”.</p><br>

    <p style="text-indent:2em;">g) гарант – юридическое лицо, иное, чем главный ответственный, которое выдает письменное обязательство таможенным органам оплатить солидарно с главным ответственным стоимость таможенного обязательства в пределах той
	суммы, в которой это обязательство может возникнуть;</p><br> 

    <p style="text-indent:2em;">h) процедура T1 – процедура таможенного оформления транзитных операций, осуществленных согласно положениям настоящих Правил, Таможенного кодекса Республики Молдова, Постановления Правительства Республики Молдова №
	1140 от 2.11.2005 г. и другим нормативным актам;</p><br> 

    <p style="text-indent:2em;">i) транспортные средства – любые транспортные средства (не являющиеся объектом внешнеэкономических сделок), используемые для международных перевозок пассажиров и товаров,
	включая контейнеры и другое транспортное оборудование;</p><br> 

    <p style="text-indent:2em;">î) таможня, таможенный пост – подразделения таможенного органа, в которых могут выполняться, полностью или частично формальности, предусмотренные таможенными правилами.</p><br>

    <p style="text-indent:2em;">к) пользователь информационной системы – сотрудник таможни, юридическое лицо, уполномоченное использовать информационную процедуру декларирования товаров, и который может
	быть декларантом или таможенным брокером - пользователем информационной системы.</p><br>   

    <p style="font-weight:500;">5. Таможенные органы обязаны сообщать друг другу всю информацию в связи с применением транзитной системы.</p><br>

    <p style="text-indent:2em;">Таможни будут передавать отделу транзита Таможенной службы затребованные данные относительно операций по транзиту.</p><br> 

    <p style="font-weight:500;">6. Отдел транзита Таможенной службы будет осуществлять мониторинг операций по транзиту на территории Республики Молдова.</p><br>

    <p style="font-weight:500;">7. Применение данных правил осуществляется с соблюдением запретов или ограничений на импорт, экспорт или транзит товаров, установленных в соответствии с законом компетентными органами согласно
	соображениям безопасности и общественной морали, защиты общественного здоровья и жизни населения, животных и растений, защиты национального достояния художественного, исторического и археологического
	значения, а также в целях защиты индустриальной, торговой или интеллектуальной собственности.</p><br>

    <p style="font-weight:500;">8. Запрещенные товары или товары с ограниченным оборотом, транзит которых через Республику Молдова регламентируется отдельными актами, предусматривающими представление дополнительных документов, должны
	подвергаться проверке на предмет наличия этих документов в качестве приложений к транспортным документам. В противном случае транзитная операция не будет разрешена до представления необходимых документов.</p> 

    <p style="text-indent:2em;"></p><h6>9. Для осуществления транзитной операции главный ответственный должен:</h6><p></p> 

    <p style="text-indent:2em;">a) непосредственно или через своего представителя представить в таможенный орган  отправления транзитную декларацию с приложением всех необходимых документов для помещения товаров в режим транзита
	(например: накладные или другие документы, определяющие стоимость товаров, спецификации, транспортные документы, разрешающие документы);</p><br> 

    <p>b) соблюдать положения законодательства, регламентирующие режим транзита;</p><br> 

    <p style="text-indent:2em;">c) по требованию таможенных органов и в установленные им сроки представить документы и данные, необходимые для осуществления транзитных операций;</p><br>

    <p style="text-indent:2em;">d) оплатить таможенные платежи на импорт/экспорт в случае, предусмотренном ст. 12711 Таможенного кодекса.</p><br>

    <p style="font-weight:500;">10. Главный ответственный на каждый транспортный документ должен представить по одной транзитной декларации, в которой должны быть указаны все загруженные в транспортное средство товары, предназначенные
	к перевозке от одного таможенного органа отправления к одному таможенному органу назначения.</p><br> 

    <p style="text-indent:2em;">В случае транспортировки товаров железнодорожным транспортом, имеющих одного и того же получателя и представленные одновременно таможенному органу, подается одна декларация на весь состав с указанием общего
	количества вагонов (цистерн) и одного идентификационного номера.</p><br>    

    <p style="text-indent:2em;">Одно и то же транспортное средство может использоваться как для погрузки товаров для одного или более таможенных органов отправления, так и для разгрузки товаров в одном или более таможенных  органов назначения.</p><br> 

    <p style="font-weight:500;">11. Срок транзита определен в п. (2) ст. 42 Таможенного кодекса Республики Молдова.</p><br> 

    <p>Срок транзита начинается со дня утверждения транзитной операции таможенным органом отправления.</p>

<p></p><h4>Глава 2 Гарантии</h4><p></p>

    <p style="font-weight:500;">12. Для товаров, перевозимых транспортными средствами, главный ответственный должен представить таможенным органам гарантию, обеспечивающую оплату таможенных платежей в связи с импортом/экспортом, если транзитная
	операция не будет осуществлена в соответствии с данными положениями.</p> 

    <p style="text-indent:2em;"></p><h6>13. Гарантия может быть предоставлена следующим образом:</h6><p></p> 

    <p style="text-indent:2em;">a) посредством внесения соответствующей суммы денежных средств наличными или по перечислению на казначейский счет гарантий;</p><br> 

    <p style="text-indent:2em;">b) посредством представления в таможенный орган гарантийного письма, выданного банковским учреждением с юридическим адресом в Республике Молдова.</p> 

    <p style="text-indent:2em;"></p><h6>14. Гарантийное письмо, выданное банковским учреждением, должно содержать следующую информацию:</h6><p></p> 

    <p>а) наименование банковского учреждения, номера расчетного счета, код банка;</p><br> 

    <p>b) юридический адрес банковского учреждения;</p><br> 

    <p>с) сумма гарантии – равная или больше, чем сумма таможенных платежей;</p><br> 

    <p style="text-indent:2em;">d) фамилия и имя физического лица либо название экономического агента и полный адрес главного ответственного, за которого банковское учреждение гарантирует оплату;</p><br> 

    <p>е) срок действия гарантии.</p>

    <p style="text-indent:2em;"></p><h6>15. Гарантийное письмо обязательно должно содержать следующие записи:</h6><p></p> 

    <p style="text-indent:2em;">«Гарант обязуется совершить по первому требованию таможни оплату таможенного обязательства в течение 2 рабочих дней».</p><br>

    <p style="text-indent:2em;">Данная гарантия действительна со дня принятия ее таможенным органом отправления.</p><br> 

    <p style="font-weight:500;">16. Гарант освобождается от своих обязательств в случаях подтверждения транзитной операции таможенным органом назначения.</p> 

    <p style="text-indent:2em;"></p><h6>17.  Гарант не освобождается от своих обязательств в следующих случаях:</h6><p></p> 

    <p style="text-indent:2em;">a) если был письменно уведомлен таможенным органом о том, что режим транзит по гарантии не был завершен;</p><br>

    <p style="text-indent:2em;">b) что обязан или может быть обязан оплатить таможенные платежи по импорту/экспорту, которые подлежат оплате.</p>

    <p style="text-indent:2em;"></p><h6>18. Гарантия, представленная главным ответственным, может быть:</h6><p></p> 

    <p>a) Обособленная гарантия – покрывает одну и более транзитных операций.</p><br>

    <p style="text-indent:2em;">Обособленная гарантия представляется в таможенный орган отправления и должна полностью покрывать таможенные платежи по импорту/экспорту товаров, перемещаемых в режиме транзита.</p><br>

    <p style="text-indent:2em;">При оформлении обособленной гарантии для нескольких транзитных операций таможенный орган отправления ведет учет остатка по гарантии после каждой операции (остаток по гарантии будет уменьшаться после утверждения операции и
	пересчитываться после подтверждения ее совершения).</p><br> 

    <p style="text-indent:2em;">Оригинал обособленной гарантии хранится в таможенном органе отправления.</p><br>
	
    <p style="text-indent:2em;">После подтверждения транзитной операции таможенный орган отправления передает обособленную гарантию по разовой транзитной операции банку-эмитенту вместе с подтверждением выдачи гарантии.</p><br> 

    <p style="text-indent:2em;">Таможенный орган может отозвать свое решение о принятии гарантии в случае невыполнения условий ее выдачи и уведомить, соответственно, как главного ответственного, так и гаранта.</p><br>

    <p style="text-indent:2em;">b) Суммарная гарантия – покрывает несколько транзитных операций;</p><br> 

    <p style="text-indent:2em;">Суммарная гарантия представляет собой упрощенную форму гарантии, принятую и взятую на учет таможенным органом, которая используется для покрытия нескольких транзитных операций, без ведения таможенными органами учета размера
	остатка по другим гарантиям.</p><br>

    <p style="text-indent:2em;">Суммарная гарантия представляется в Таможенную службу на основании банковской гарантии или денежного депозита.</p> 

    <p style="text-indent:2em;"></p><h6>19. Таможенная служба разрешает использовать суммарную гарантию главным ответственным, которые выполняют следующие условия:</h6><p></p> 

    <p style="text-indent:2em;">a) часто используют режим транзита или известны таможенному органу как лица, способные соблюсти законные обязательства по данному режиму;</p><br> 

    <p style="text-indent:2em;">b) не допускают нарушений или отступлений от положений таможенного законодательства;</p><br> 

    <p style="text-indent:2em;">c) обладают системой учета, позволяющей таможенным органам осуществлять эффективный контроль;</p><br> 

    <p style="text-indent:2em;">В случае невыполнения этих условий обоснованный отказ в выдаче разрешения Таможенная служба передает заявителю.</p><br> 

    <p style="font-weight:500;">20. Главный ответственный использует суммарную гарантию, покрывающую референтную величину.</p><br> 

    <p style="text-indent:2em;">Референтная величина представляет собой сумму платежей по импорту/экспорту товаров, помещенных главным ответственным в режим транзита в течение не менее одной недели.</p> 

    <p style="text-indent:2em;"></p><h6>Отчетные элементы для вычисления референтной величины включают:</h6><p></p> 

    <p style="text-indent:2em;">a) транзитные операции, осуществленные на протяжении максимум 12 календарных месяцев, предшествующих дню подачи транзитной декларации, а также данные оценки объема транзитных операций, которые будут осуществлены;</p><br>
 
    <p style="text-indent:2em;">b) наибольшую сумму начисленных таможенных платежей по импорту/экспорту товаров по транзитным операциям, осуществленным главным ответственным.</p><br> 

    <p style="font-weight:500;">21. Главный ответственный обязан обеспечивать, чтобы суммы по транзитным операциям, с учетом операций, по которым не завершился режим транзита, не превышали референтную величину. В случае, если эта величина будет недостаточной
	для покрытия суммы транзитных операций, таможенный орган не разрешит начало транзитной операции.</p><br> 

    <p style="font-weight:500;">22.Таможенная служба ежегодно проверяет рефе-рентную величину на основании документов, представленных главным ответственным, и, в зависимости от объема транзитных операций в течение предыдущего года, корректирует эту 
	стоимость.</p>

<p></p><h4>Глава 3 Процедура транзита для перевозимых товаров (Процедура Т1)</h4><p></p>

<p></p><h4>Часть 1 Формальности в таможенном органе отправления</h4><p></p>

<p>Особенности заполнения и размещение таможенной транзитной декларации</p><br>

    <p style="font-weight:500;">23. Транзитная декларация и прилагаемые к ней документы представляются главным ответственным в таможенный орган отправления. </p><br>

    <p style="font-weight:500;">24. Транзитная декларация заполняется в соответствии с Техническими правилами с учетом специфики, предусмотренной для процедуры электронного декларирования.</p>

    <p style="text-indent:2em;"></p><h6>25. На транзитную декларацию ставится усиленная квалифицированная электронная подпись, выданная в соответствии с Законом № 91 от 29.05.2014 г. „Об электронной подписи и электронном документе”, в следующем порядке:</h6><p></p>

    <p>1) юридическое лицо подписывает заполненную и поданную от своего имени транзитную декларацию;</p><br>

    <p style="text-indent:2em;">2) таможенный брокер подписывает заполненную и поданную транзитную декларацию на основании непосредственного или косвенного представления.</p><br>

    <p style="font-weight:500;">26. К транзитной декларации прилагаются отсканированные копии сопроводительных (коммерческих, транспортных) документов, на основании которых заполнена соответствующая декларация. Прикрепление осуществляется путем выбора функции
	„ADD SCAN”. Копии (отсканированные) сопроводительных документов прикрепляются в формате „.jpg” или „.pdf”, с разрешением 300 dpi и размером не более 50 MБ.</p>

    <p style="text-indent:2em;"></p><h6>27. Заявитель осуществляет как минимум следующие операции с транзитной декларацией:</h6><p></p>

    <p>1) нажимает на кнопку “Размещение” транзитной декларации;</p><br>

    <p style="text-indent:2em;">2)  подписывает транзитную декларацию, заполненную и размещенную при помощи электронной подписи, выданной в соответствии с Законом № 91 от 29.05.2014 г. „Об электронной подписи и электронном документе”, 
	выбрав сертификат открытого ключа.</p><br>

    <p style="font-weight:500;">28. Размещение транзитной декларации осуществляется на сервере Таможенной службы, в Таможенной интегрированной информационной системе  „ASYCUDA World” на таможенном органе отправления, уполномоченному, согласно 
	Закону № 91 от 29.05.2014 г.„Об электронной подписи и электронном документе”, оформить соответствующую сделку.</p><br> 

<p style="text-indent:2em;">Транзитная декларация с прилагаемыми к ней документами, утвержденная органом отправления, получает регистрационный номер.</p><br>

    <p style="font-weight:500;">29. Подача транзитной декларации не освобождает перевозчика товаров от обязательства представления оригиналов документов, подтверждающих право на осуществление соответствующей перевозки (такие как: водительское удостоверение,
	технический паспорт транспортного средства, транспортные разрешения, многосторонние разрешения Европейской конференции министров транспорта (CEMT), разрешающие документы).</p><br>

    <p style="font-weight:500;">30. Для товаров, ввозимых в страну, транзитная декларация размещается до ввоза или одновременно со ввозом товаров на таможенную территорию Республики Молдова.</p><br> 

    <p style="font-weight:500;">31. Юридическое лицо или таможенный брокер может отозвать транзитную декларацию окончательно или для последующего внесения изменений даже после размещения на сервере таможенного органа, но не позднее момента начала
	таможенного контроля.</p>

<p></p><h4>Часть 2 Процедура помещения товаров под таможенный режим транзита (Процедура T1)</h4><p></p>

    <p style="font-weight:500;">32. После размещения транзитной декларации сотрудник таможни, ответственный за распределение, определяет в Таможенной интегрированной информационной системе 
„ASYCUDA World” транзитную декларацию по ее референтному номеру, указанному представителем экономического агента, таможенного брокера, перевозчика или по регистрационным номерам транспортного средства (прицеп, полуприцеп, вагон, контейнер),
 а также распределяет её сотруднику таможни.</p><br>

    <p style="text-indent:2em;">Таможенным органом назначения указывается код соответствующего таможенного органа, куда направляются товары.</p>

    <p style="text-indent:2em;"></p><h6>33. Сотрудник таможни, кому была распределена транзитная декларация, выполняет следующие действия:</h6><p></p>

    <p style="text-indent:2em;">a) определяет и просматривает транзитную декларацию и приложенные к ней документы, сопоставляет данные в декларации с приложенными документами;</p><br>

    <p style="text-indent:2em;">b) проверяет действительность гарантии, а также её размер, бронирует сумму гарантии таможенного обязательства, представленной для товаров, помещенных в режим транзита в рубрике 52 транзитной декларации в соответствующем порядке в
	таможенной информационной системе;</p><br>

    <p style="text-indent:2em;">c) вносит в информационные системы, используемые для интегрированного управления границами, необходимую информацию о транспортном средстве с товарами в режиме транзита;</p><br> 

    <p style="text-indent:2em;">d) регистрирует транзитную декларацию в Таможенной интегрированной информационной системе “ASYCUDA World” с указанием сертификата открытого ключа;</p><br>

    <p style="text-indent:2em;">e) заверяет посредством таможенных обеспечений транспортные и коммерческие документы, представленные перевозчиком, брокером или главным ответственным;</p><br>

    <p style="text-indent:2em;">f) при необходимости применения пломбы осуществляет физический контроль (проверяет объем/вес/количество, указанные в декларации) товара, осуществляет операции по пломбированию грузового отсека, по процедуре, установленной в п. 51-52
	Правил о применении таможенных назначений, предусмотренных Таможенным кодексом Республики Молдова, утвержденного Постановлением Правительства № 1140 от 2 ноября 2005 г.</p><br>  

    <p style="font-weight:500;">34. После осуществления, при необходимости, физического контроля товаров таможенный орган отправления вписывает в рубрику D “Проверено на таможне отправления” транзитной декларации результаты контроля, идентификационные номер
	а установленных пломб, а также срок, в течение которого товары должны быть представлены на таможенный орган назначения, в зависимости от используемого вида транспорта.</p>

    <p style="text-indent:2em;"></p><h6>35. По просьбе перевозчика, брокера или главного ответственного сотрудник таможни, которому была распределена транзитная декларация, обязан выполнить следующие действия:</h6><p></p>

    <p style="text-indent:2em;">a) распечатать на бумажном носителе транзитную декларацию, заверить соответствующую копию личной печатью и передать её заявителю;</p><br>

    <p style="text-indent:2em;">b) выдать заверенные копии/выписки документов, подтверждающие оплату дополнительных платежей по импорту, связанных с данной операцией.</p><br>

    <p style="font-weight:500;">36. Транзитная декларация может сопровождаться одним или более дополнительными формулярами. Они являются неотъемлемыми частями транзитной декларации, к которой приложены.</p><br> 

    <p style="font-weight:500;">37. Вместо дополнительных формуляров могут использоваться перечни грузов, заполненные согласно образцу, предусмотренному в приложении № 1к настоящим правилам. Они прилагаются к транзитной декларации и используются для любых типов
	транзитных операций, за исключением транзитных экспортных операций.</p><br> 

    <p style="font-weight:500;">38. Перечни грузов, заполненные и используемые согласно положениям, установленным в приложении № 2 к настоящим правилам, являются неотъемлемыми частями транзитной декларации.</p><br> 

    <p style="font-weight:500;">39. По просьбе перевозчика, брокера или главного ответственного сотрудник таможни, которому была распределена транзитная декларация, обязан распечатать на бумажном носителе перечень грузов, заверить соответствующую копию личной
	печатью и передать ее заявителю.</p>

<p></p><h4>Часть 3 Последовательная догрузка/перегрузка товаров (Процедура T1)</h4><p></p>

    <p style="text-indent:2em;"></p><h6>40. В случае использования транспортного средства для последовательной догрузки товарами в нескольких таможенных органах отправления следует поступить следующим образом:</h6><p></p> 

    <p style="text-indent:2em;">a) в каждом таможенном органе отправления, включая промежуточные, составляется транзитная декларация на ту поставку товара, которая отгружается в зоне его компетенции, а таможенным органом назначения является пограничный таможенный 
	орган, через который товары вывозятся с территории Республики Молдова. На всех транзитных декларациях, сопровождающих транспортное средство, указывается один и тот же таможенный орган назначения.</p><br>

    <p style="text-indent:2em;">b) срок транзита, предоставляемый первым таможенным органом отправления, в обязательном порядке должен быть не меньше максимального срока, установленного ст. 42 ч. (2) Таможенного Кодекса;</p><br> 

    <p style="text-indent:2em;">c) в промежуточных таможенных органах отправления снимаются ранее установленные пломбы в целях погрузки последующих партий товаров и устанавливаются другие пломбы (за исключением случаев, предусмотренных Таможенной службой),
	указываемые в разделе F “Виза компетентных органов” транзитной декларации (согласно Техническим правилам), которая сопровождает транспортное средство, а пломба, установленная предыдущим таможенным органом, считается недействительной;</p><br>

    <p style="text-indent:2em;">d) промежуточные таможенные органы отправления обязаны проставить в оформленных ими транзитных декларациях, в свободном месте под разделами 15 “Страна отправки/экспорта” и 17 “Страна назначения” (согласно Техническим правилам) номер
	и дату остальных транзитных деклараций, сопровождающих транспортное средство.</p><br> 

    <p style="font-weight:500;">41. В случае если на одном из промежуточных таможенных органов отправления транзитная операция продолжается с использованием книжки МДП, он берется на учет, а в рубрике №10 указывается общее количество товара, а также номера и
	даты предыдущих транзитных деклараций.</p><br> 

    <p style="text-indent:2em;">Новые пломбы, устанавливаемые таможенным органом, взявшим на учет книжку МДП, указываются и в разделе F “Виза компетентных органов” (согласно Техническим правилам) предыдущих транзитных деклараций.</p><br> 

    <p style="font-weight:500;">42. Товары, прошедшие таможенное оформление на экспорт, за исключением процедур, предусмотренных п. 3, настоящих Правил о применении системы транзита на территории Республики Молдова, на различных внутренних органах отправления и
	находящиеся в транзите до границы, могут быть представлены на другой внутренний таможенный орган отправления в одном транспортном средстве. В транзитных декларациях, в рубрике 53 „Таможня назначения и страна” (согласно Техническим
	правилам), таможней назначения указывается пограничный таможенный орган назначения, а срок транзита определяется в рамках, предусмотренных ст. 42 ч. (2) Таможенного кодекса.</p><br> 

    <p style="font-weight:500;">43. В целях временного хранения для перегруппировки и последующей погрузки товаров на новое транспортное средство перевозчик обращается в таможенный  орган с заявлением о перегрузке товаров в соответствии с положениями Приказа Таможенной
	службы № 131-O от 18.02.2013 г. „О некоторых аспектах процедуры транзита”.</p><br>

    <p style="text-indent:2em;">При перегрузке товаров принимается во внимание срок транзита товаров по транзитной декларации до таможенного органа назначения.</p><br>   

    <p style="font-weight:500;">44. При погрузке партий товаров на транспортное средство таможенный орган, где осуществляется перегрузка, делает отметки в электронном видео перегрузки, в разделе 55 “Перегрузки” (согласно Техническим правилам), на транзитных декларациях,
	оформленных для соответствующего транспортного средства до пограничного таможенного органа.  Одновременно, в свободных графах рубрики 15 „Страна отправления/экспорта” и 17 “Страна назначения” всех транзитных деклараций, таможенный орган, где
	осуществляется перегрузка, вписывает в электронном формате номер и дату каждой транзитной декларации, сопровождающей транспортное средство (согласно Техническим правилам).</p><br> 

    <p style="font-weight:500;">45. Транзитные операции обрабатываются посредством информационной системы с соблюдением кодификации, предусмотренной информационной системой.</p><br> 

    <p style="text-indent:2em;">Коды, используемые для автоматического ввода идентификационных данных главного ответственного (наименование, юридический адрес, таможенный орган в зоне деятельности которого функционирует, фамилия и имя представителя), присваиваются
	на основании номера его разрешения;</p><br> 

    <p style="text-indent:2em;">Коды, присваиваемые гарантийным документам для регистрации данных о гарантиях в модуле финансово-бухгалтерского учета, предусмотрены в рубрике 52 „Гарантии” Технических правил.</p><br> 

    <p style="font-weight:500;">46. Если процедура T1 следует за другим таможенным режимом, то в соответствующих разделах транзитной декларации следует указать предыдущие таможенные документы (руб.40 „Суммарная декларация/Предыдущий документ”
	согласно Техническим правилам).</p><br>
 
    <p style="font-weight:500;">47. В случаях, когда для транзитных товаров используется одно транспортное средство в целях последовательной разгрузки в нескольких таможенных органах назначения, промежуточные таможенные органы назначения, сорвавшие пломбы с целью
	обеспечения частичной разгрузки товаров, должны установить новые таможенные пломбы (за исключением случаев, предусмотренных Таможенной службой) и сделать соответствующие отметки о новых пломбах в разделе F „Виза компетентных органов”
	транзитных деклараций, сопровождающих транспортное средство (согласно Техническим правилам), а таможенная пломба, установленная предыдущим таможенным органом, считается недействительной.</p>

<p></p><h4>Часть 4 Особенности исправления и аннулирования транзитной декларации во время перевозки</h4><p></p>

    <p style="font-weight:500;">48. В случае необходимости внесения в транзитную декларацию событий и недостатков отметки относительно их вносятся в рубрику 56 „Другие инциденты во время перевозки” в порядке, установленном Приказом Таможенной службы № 131-O от 18.02.2013 г
	. „О некоторых аспектах процедуры транзита».</p><br>

    <p style="font-weight:500;">49. В случае, когда главный ответственный запрашивает, непосредственно или через своего представителя, возможность внесения изменений в транзитную декларацию (перегрузка, изменения маршрута) или аннулирования транзитной декларации,
	изменения могут быть внесены таможенным органом отправления до доставки товаров, подлежащих транзиту, в таможенный орган назначения.</p><br>

    <p style="font-weight:500;">50. Все изменения и дополнения, вносимые в транзитную декларацию, подписываются сертификатом открытого ключа сотрудником таможни, который их внес, или уполномоченным, ответственным в таможенном органе.</p>

<p></p><h4>Часть 5 Таможенные формальности в органе назначения</h4><p></p>

<p></p><h6>Особенности закрытия транзитной декларации</h6><p></p>

    <p style="font-weight:500;">51. Одновременно с представлением товаров таможенному органу назначения он осуществляет контроль и вписывает в рубрике I „Проверено таможней назначения (Транзит)” – Проверено в органе назначения транзитной декларации: дата прибытия,
	сведения о пломбах и результаты контроля (согласно Техническим правилам).</p> 

    <p style="text-indent:2em;"></p><h6>Пометки, внесенные таможенным органом назначения в разделе «Замечания», могут быть следующие:</h6><p></p> 

    <p>a)  „соответствует” – когда все в порядке;</p><br> 

    <p style="text-indent:2em;">b) „незначительное несоответствие” – когда установленное несоответствие не ведет к взысканиям импортных/экспортных таможенных платежей, к наложению штрафов или к другим санкциям;</p><br> 

    <p style="text-indent:2em;">c) „санкционируемое нарушение” – когда вследствие констатации несоответствия необходимо начать процедуру по делу о таможенном правонарушении;</p><br> 

    <p style="text-indent:2em;">d) по необходимости: „излишек, недостаток, природа товаров, тарифная классификация и другие„ – в случае обнаружения отклонений;</p><br> 

    <p style="text-indent:2em;">e) „таможенные пошлины и другие платежи по импорту/экспорту будут взысканы” – в случае, когда нарушение приводит к взысканию таможенных сборов и иных платежей по импорту/экспорту. Таможенный орган назначения информирует таможенный орган
	отправления о предпринятых мерах и направляет копии протокола констатации нарушений.</p><br>

    <p style="font-weight:500;">52. Гарантия, представленная главным ответственным для осуществления транзитной операции, не может быть использована для помещения товаров под таможенный надзор в склад временного хранения.</p><br> 

    <p style="font-weight:500;">53. Таможенный орган назначения, указанный в транзитной декларации, может быть заменен на другой таможенный орган, который автоматически становится таможенным органом назначения только в достаточно обоснованных случаях и с соблюдением
	Приказа Таможенной службы № 349-О от 19.07.2013 г. «О порядке изменения таможни назначения для товаров, помещенных под таможенный режим транзита».</p><br> 

    <p style="font-weight:500;">54. Новый таможенный орган назначения, который принимает на себя все обязанности, вписывает в рубрику 56 “Другие события в ходе перевозки” (согласно Техническим правилам) транзитной декларации пометку 
	„Товары представлены в таможенный орган”.</p><br> 

    <p style="font-weight:500;">55. При представлении товаров таможенному органу назначения главный ответственный или перевозчик может затребовать у таможенного органа назначения копию выписки транзитной декларации на бумажном носителе, которая должна содержать в рубрике
	I“Проверено на таможне назначения (Транзит)” – Проверено в таможенном органе назначения с пометками о результатах контроля, с подписью и личной печатью сотрудника таможни.</p><br> 

    <p style="font-weight:500;">56. Таможенный орган назначения обязан заверить данную копию как «соответствующую оригиналу», поставить на ней оттиск личной печати и сделать пометку „альтернативное доказательство”.</p><br> 

    <p style="font-weight:500;">57. Помимо копии транзитной декларации главный ответственный или перевозчик могут потребовать выдачи справки по форме, предусмотренной в приложении № 3 к настоящим правилам, которая может быть заполнена им предварительно.</p><br> 

    <p style="font-weight:500;">58. Таможенный орган назначения обязан выдать подписанную справку, заверенную личной печатью сотрудника таможни, и вписать в её верхнем левом углу номер и дату регистрации транзитной операции.</p><br> 

    <p style="font-weight:500;">59. В случае инициирования расследования, такая справка не служит законным подтверждением транзитной операции и не может служить дополнительным альтернативным доказательством завершения режима транзита.</p><br> 

    <p style="font-weight:500;">60. Процедура Т1 считается завершенной, когда товар представлен вместе с сопроводительными документами таможенному органу назначения в неизменном состоянии, за исключением естественного износа во время перевозки и хранения в надлежащих условиях
	, а результат контроля «соответствует» вносится в транзитную декларацию с применением электронной подписи.</p><br>

    <p style="font-weight:500;">61. Для случаев, кода таможенная декларация была подана до завершения таможенного режима транзита, он завершается после выполнения операции утверждения, которая помещает товары в другой таможенный режим или назначение. В случае автоматического 
	утверждения таможенной декларации таможенный режим транзита считается завершенным.</p><br> 
	
<p style="text-indent:2em;">При подтверждении в информационной системе транзитной операции таможенный орган назначения вписывает в обязательном порядке, в рубрику «Замечания» номер и дату регистрации таможенной декларации, обнаруженные нарушения при осуществлении 
контроля, после чего разрешает отправление транспортного средства с товарами.</p><br> 

    <p style="font-weight:500;">62. Процедура T1 считается завершенной, когда главный ответственный оплатил таможенные платежи импорта/экспорта, подлежащие уплате.</p><br>

    <p style="font-weight:500;">63. Таможенные органы отправления ведут мониторинг процедуры завершения транзитных операций на основании транзитных деклараций, в которых они указаны в качестве таможенных органов отправления.</p><br> 

    <p style="font-weight:500;">64. В целях обеспечения эффективного учета транзитных операций, проводимых на таможенной территории Республики Молдова согласно Приказу Таможенной службы № 544-O от 19.12.2012 г. ”О порядке отчетности относительно завершения транзитных операций”,
	таможни отправления обеспечат ежедневную отчетность (посредством назначения начальником таможни ответственных лиц) отделу транзита Таможенной службы относительно транзитных операций, не завершенных в срок, включая те, в которых транзитные декларации 
	подлежат аннулированию.</p>

<p></p><h4>Часть 6 События и нарушения в ходе перевозки</h4><p></p>

    <p style="text-indent:2em;"></p><h6>65. Во время перевозки могут иметь место следующие события:</h6><p></p> 

    <p>a) форс-мажорные обстоятельства или действие непреодолимой силы;</p><br> 

    <p>b) аварии, требующие перегрузки товаров на другое транспортное средство;</p><br> 

    <p>c) возникновение опасности, требующей немедленной, частичной или полной разгрузки товаров;</p><br> 

    <p>d) обрыв пломбы по причинам, не зависящим от воли перевозчика;</p><br> 

    <p>e) несоблюдение срока транзита по причине аварий и иных происшествий во время перевозки;</p><br> 

    <p>f) иные события.</p><br> 

    <p style="font-weight:500;">66. В случае наступления обстоятельств, указанных в п. 65, требующих перегрузки, товары, внесенные в транзитную декларацию, могут быть перегружены на другое транспортное средство под надзором таможенных органов в соответствии с Приказом
	Таможенной службы № 131-O от 18.02.2013 г. „О некоторых аспектах процедуры транзита”. В таких случаях ближайший таможенный орган заполняет соответствующим образом раздел 55 “Перегрузки” в электронном формате (согласно Техническим правилам).</p><br> 

   <p style="text-indent:2em;">В отношении событий, указанных в п. 65 a), b) и c), перевозчик может запросить от компетентных органов (полиции, таможенного органа, пожарников) составления протокола о констатации для представления таможенному органу назначения.</p><br> 

    <p style="text-indent:2em;">В случае необходимости применения таможенной пломбы проводит операции пломбирования грузового отдела по процедуре, установленной в п. 51-52 Положения о введении в действие таможенных назначений, предусмотренных Таможенным кодексом
	Республики Молдова, утвержденного Постановлением Правительства № 1140 от 2 ноября 2005 г.</p><br>

    <p style="font-weight:500;">67. Для обстоятельств, указанных в п. 65, вносятся записи в электронном формате относительно них в разделе 56 “Другие происшествия в ходе перевозки” – Другие происшествия в ходе перевозки по транзитной декларации.</p><br> 

    <p style="font-weight:500;">68. В случае наступления событий, ведущих к взысканию таможенных платежей по импорту/экспорту, таможенный орган назначения применяет общие положения о таможенном оформлении товаров, предусмотренные действующим законодательством,
	и уведомляет таможню отправления о предпринятых мерах.</p>    

    <p style="text-indent:2em;"></p><h6>69. В случае возникновения происшествий или нарушений в таможенных органах по следующим причинам:</h6><p></p> 

    <p>a) сбои в подаче электроэнергии;</p><br>

    <p>b) перезагрузка сервера Таможенной службы;</p><br>

    <p style="text-indent:2em;">c) технический дефект  в Таможенной интегрированной информационной системе “ASYCUDA World” – сотрудник таможни, которому была распределена соответствующая декларация, осуществляет  оформления транзитной декларации в соответствии
	с мануальной процедурой, установленной Таможенной службой.</p>

<p></p><h4>Глава 4 Процедуры последующего расследования  и контроля</h4><p></p>

<p>Взыскание задолженностей</p><br>

    <p style="font-weight:500;">70. Контроль транзитных деклараций может осуществляться отделом транзита Таможенной службы в целях проверки правильности транзитных операций.</p><br> 

    <p style="text-indent:2em;">Такой контроль проводится систематизированной выборкой по подтвержденным или еще незавершенным транзитным операциям, а по операциям находящимся в процедуре расследования, осуществляется полный контроль.</p><br> 

    <p style="font-weight:500;">71. Процедура расследования применяется в случае, когда режим/операция транзита не завершена/не подтверждена в соответствии с положениями п.п. 60, 61 и 62. Эта процедура инициируется незамедлительно, если транспортное средство представляется
	таможенному органу назначения с нарушением срока, указанного в п. 11 настоящих правил, или представлено с нарушенными/отсутствующими таможенными, а также и в случае представления с полным или частичным отсутствием товаров в процессе перевозки
	от таможенного органа отправления до таможенного органа назначения.</p><br> 

    <p style="font-weight:500;">72. Если в таможенном органе назначения обнаружено нарушение в рамках процедуры транзита, таможенный орган назначения в срок до 5 дней уведомляет орган отправления, отдел транзита Таможенной службы о констатированных фактах.</p><br> 

    <p style="font-weight:500;">73. Если в течение 30 дней со дня выдачи транзитной декларации таможенный орган отправления не получит доказательство завершения таможенного режима транзита, таможенный орган отправления инициирует процедуру расследования.</p><br> 

    <p style="font-weight:500;">74. Таможенный орган отправления, в случае инициирования процедуры расследования, обязан в письменном виде потребовать от главного ответственного в течение 5 дней представить информацию о завершении транзитной операции или доказательства того,
	что режим транзита был завершен.</p>

    <p style="text-indent:2em;"></p><h6>75. В течение максимум 10 дней со дня инициирования процедуры расследования главный ответственный обязан представить таможенному органу отправления один из следующих документов:</h6><p></p> 

    <p style="text-indent:2em;">a) выписку из транзитной декларации, заверенной органом назначения, выданную согласно положениям пунктов 55, 56;</p><br> 

    <p style="text-indent:2em;">b) копию таможенного документа (таможенной декларации или суммарной декларации) о помещении товаров в таможенный режим или под таможенный надзор, заверенную таможней назначения как „соответствующая оригиналу”;</p><br> 

    <p>c) справку, выданную согласно положениям п. 57;</p><br> 

    <p>d) любую другую информацию, относящуюся к транзитной операции в процедуре расследования.</p><br> 

    <p style="text-indent:2em;">В ситуациях, определенных буквами a) и b) настоящего пункта, режим транзита считается завершенным. Таможенный орган назначения, по требованию таможенного органа отправления, завершает режим таможенного транзита. В ситуациях,
	определенных буквами c) и d) настоящего пункта, таможенный орган назначения передает справку или полученные данные вместе с выпиской из экземпляра № 1 транзитной декларации в таможенный орган отправления.</p><br> 

    <p style="font-weight:500;">76. Если таможенный орган отправления не имеет информации о завершении процедуры Т1 и обнаруживается нарушение таможенных правил, он уведомляет управление по расследованию таможенных нарушений.</p><br>

    <p style="text-indent:2em;">Процедура расследования заканчивается взысканием таможенных платежей по импорту/экспорту, подлежащих оплате, и завершением режима транзита.</p><br>
	
	<p><a href="https://www.legis.md/cautare/getResults?doc_id=103647&amp;lang=ru">Источник</a></p>



</div>
`