import React from 'react'
import { ModalConsumer } from '../../../context/modal-context'
import fetch from '../../shared/fetch'

export default props => {
  const { t, history, itemId, getProfile, userId, verificationId, setCompanyVerivicationData } = props
  const closeCompany = ({ clearModal }) => {

    fetch(`/company/${itemId}/verification/${verificationId}`, {
      method: 'PUT',
      body: JSON.stringify({
        companyId: itemId,
        id: verificationId,
        isValid: false,
      }),
    })
    fetch(`/company/delete/${itemId}`, { method: 'DELETE' }).then(_ => {
      clearModal()
      setCompanyVerivicationData({})
      getProfile(userId, () => {
        history.push('/')
      })
    })
  }

  return (
    <div className="panel-content">
      <article>
        <h5>{t('company.cancel.what.it.means')}</h5>
        <p>{t('company.cancel.wont.be.deleted')}</p>
        <p>{t('company.cancel.all.employees.former')}</p>
        <p>{t('company.cancel.all.offers.deactivated')}</p>
        <p>{t('company.cancel.verification.canceled')}</p>
        <h5>{t('company.cancel.can.i.recover')}</h5>
        <p>{t('company.cancel.yes.you.can')}</p>
        <p>{t('company.cancel.verification.again')}</p>
      </article>
      <div className="ta-c">
        <ModalConsumer>
          {modalConsumer => (
            <button
              type="button"
              className="button button-orange"
              onClick={() => closeCompany(modalConsumer)}
            >
              {t('company.button.close')}
            </button>
          )}
        </ModalConsumer>
      </div>
    </div>
  )
}
