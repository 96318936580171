import withTranslation from '../../../globals/components/withTranslation'
import Component from './component'
import { SingleReviewProps } from './types'
import { compose } from 'recompose'
import withPermissions from '../../../globals/components/withPermissions'

export default compose(
  withTranslation,
  withPermissions
)(Component) as React.ComponentClass<SingleReviewProps>
