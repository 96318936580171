import { BUSE_ID, TRACTOR_ID, CARGO_LARGE_ID } from '../../transport/autopark/autopark-constants'
import noCarPhoto from '../../../images/truck.png'
import noCarPhotoLarge from '../../../images/truck-lg.png'
import noCarPhotoTeagach from '../../../images/teagach.png'
import noCarPhotoBuse from '../../../images/buse.png'

export const transportPlaceholder = (typeId?: number) => {
  if (typeId === BUSE_ID) {
    return noCarPhotoBuse
  } else if (typeId === TRACTOR_ID) {
    return noCarPhotoTeagach
  } else if (typeId === CARGO_LARGE_ID) {
    return noCarPhotoLarge
  }
  return noCarPhoto
}
