export const int12 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>AMBALAREA, ETICHETAREA SI MARCAREA MARFURILOR</h4><p></p>

<p style="text-indent:2em;">Ambalajul este definit ca fiind “un mijloc, sau ansamblu de mijloace, destinat sa cuprinda sau sa inveleasca un produs sau un ansamblu
 de produse, pentru a le asigura protectia temporara din punct de vedere fizic, chimic, mecanic, biologic, in scopul mentinerii calitatii
 si integritatii acestora in starea de livrare, in decursul manipularii, transportului, depozitarii si desfacerii-pana la consumare sau
 pana la expirarea termenului de garantie-precum si pentru inlesnirea acestor operatii”1.</p><br>

<p style="text-indent:2em;">Ambalarea este definita ca fiind “operatie, procedeu sau metoda, prin care se asigura,  cu ajutorul ambalajului, protectia temporara a
 produsului, in decursul manipularii, transportului, depozitarii, vanzarii, contribuind si la inlesnirea acestora,  pana la consumare
 sau pana la expirarea termenului de garantie).”1</p><br>

<p style="text-indent:2em;">In O.G. nr.32/29.08.1995, ambalajul destinat exclusive produselor alimentare este definit ca fiind un”material specific ca executie
 si natura, destinat ambalarii produselor alimentare in vederea asigurairii protectiei si utilizat pentru transportul, manipularea,
 depozitarea sau desfacerea acestora”.</p>

<p style="text-indent:2em;"></p><h6>Clasificarea ambalajelor se realizeaza dupa mai multe criterii2 si anume:</h6><p></p>

<p style="text-indent:2em;"> dupa natura materialului din care se confectioneaza: ambalaje de hartie, carton, lemn, material textil, 
sticla, metal, materiale plastice, materiale complexe, etc.;</p><br>

<p> dupa domeniul in care sunt utilizate: ambalaje de transport, de desfacere, de prezentare;</p><br>

<p> dupa sistemul de confectionare:  ambalaje fixe, demontabile, pliabile;</p><br>

<p> dupa  tipul de ambalaj:  cutii, lazi, flacoane, pungi, etc.</p><br>

<p style="text-indent:2em;"> dupa natura produsului ambalat: ambalaje pentru alimente, pentru produse industriale, pentru produse periculoase((toxice,
 inflamabile,etc.) ;</p><br>

<p> dupa modul de circulatie: ambalaje refolosibile, nerefolosibile.</p>

<p style="text-indent:2em;"></p><h6>Ambalajul nu mai este considerat un simplu obiect utilitar, conceptia despre ambalaj evoluand in stransa legatura cu dezvoltarea
 stiintei marfurilor si cu cerintele si protectia consumatorilor si a mediului. In acest sens, rolul important al ambalajului este
 subliniat de functiile sale si anume:</h6><p></p>

<p style="font-weight:500;">1. Functia de conservare si protectie a produsului si a mediului</p>

<p style="text-indent:2em;"></p><h6>Prin aceasta functie ambalajul are rolul de a proteja marfurile fata de actiunea unor factori externi, cum sunt:</h6><p></p>

<p>  factori fizici(temperatura, umiditate, lumina, socuri mecanice,etc);</p><br>

<p> factori chimici (acizi, agenti oxidanti, etc);</p><br>

<p> factori biologici (microorganisme existente in mediul inconjurator, etc.).</p><br>

<p style="text-indent:2em;">Materialul din care este confectionat ambalajul (in functie de natura produsului), forma constructive, modul de ambalare asigura
 indeplinirea functiei de conservare si protectie a produselor. Pentru protectia unor marfuri, se utilizeaza ambalajul primar (de 
 contact direct cu produsul), ambalajul secundar (care aduce o protectie suplimentara produsului si poate asigura si expunerea acestuia)
 si ambalajul tertial, care este ambalajul de transport sau colectiv (grupeaza mai multe ambalaje secundare de acelasi fel).</p><br>

<p>1. STAS 5845/1 -1986</p><br>

<p>2. Dictionar Explicativ de Marketing-Ed.Economica 2003</p><br>

<p style="text-indent:2em;">Totodata, ambalajul are si rolul de a proteja mediul de actiune toxica a unor produse ambalate: diferite substante chimice, 
gaze toxice, derivate din petrol etc.</p><br>

<p style="font-weight:500;">2. Functia de manipulare, depozitare si transport</p><br>

<p style="text-indent:2em;">Aceasta  functie presupune indeplinirea  unor cerinte de adaptare a ambalajelor la normele de transport, stabilirea rolului ambalajului
 in procesul  de  manipulare, depozitare,  transport si distributie, astfel incat, marfurile sa nu-si modifice forma si sa nu se deterioreze.</p><br>
 
<p>In indeplinirea acestei functii, un rol important il au actiunile de paletizare, containerizare si modulare.</p><br>

<p style="text-indent:2em;">Paletizarea este o metoda care permite manipularea, deplasarea si stivuirea usoara a unor marfuri grupate in unitati de incarcare,
 folosindu-se paletele si electrostivuitoarele.</p>     

<p style="text-indent:2em;"></p><h6>Principalele tipuri de palete sunt:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) palete plane de uz general care prezinta urmatoarele carateristici:</h6><p></p>

<p> sunt interschimbabile;</p><br>

<p> au dimensiuni de 800x1200mm;</p><br>

<p> sarcina dinamica:1000 kg (marfuri manipulate);</p><br>

<p> sarcina statica: 4000kg(marfuri stivuite)</p>

<p style="text-indent:2em;"></p><h6>b) palete de uz special si portuar care prezinta urmatoarele caracteristici:</h6><p></p>

<p> nu sunt interschimbabile;</p><br>

<p> au dimensiuni de 1200x1600mm si 1000x1800mm;</p><br>

<p> sarcina dinamica este de 2000kg;</p><br>

<p> sarcina  statica este de 8000 kg.</p><br>

<p>c) palete – lada, destinate produselor in vrac sau fara ambalajul intermediar</p><br>

<p>d) palete cu montanti, destinate incarcaturilor  formate din unitati mari de ambalaje</p><br>

<p style="text-indent:2em;">1- paleta cu o placa; 2 - paleta simpla; 3 - paleta reversibila; 4 - paleta cu baza perimetrica; 5 - paleta lada (box paleta);
 6 - paleta buncar (paleta-siloz).</p><br>

<p style="text-indent:2em;">Paleta de transport permite formarea incarcaturii unitare de produse, care pot fi ambalate sau nu.Utilizarea paletei este posibila
 cand marfurile sunt uniforme si prezinta stabilitate atat in timpul transportului, cat si al depozitarii (pe zi,
 anexa nr.1-tipuri de palete).</p><br>

<p style="text-indent:2em;">Containerul este un utilaj pentru transportul economic al marfurilor. El poate proteja integritatea calitativa si cantitativa
 a marfurilor, in conditiile eliminarii ambalajelor individuale si a manipularilor repetate. Se utilizeaza pentru marfuri textile
 , incaltaminte, cosmetice, produse din materiale plastice, lemn, metal, etc.</p><br>

<p style="text-indent:2em;">Modularea ambalajului consta in corelarea dimensiunilor ambalajului individual cu cele ale ambalajului de transport general,
 ale containerului, ale mijlocului de transport si cele ale spatiului de depozitare.</p><br>

<p style="text-indent:2em;">3. Functia de promavare a vanzarii produselor este functia prin care ambalajul apare ca interfata intre produs si consumator,
 avand nu numai rolul de a proteja marfa,  ci si acela de a stimula actul de cumparare si de a crea cumparatorului o atitudine 
 pozitiva fata de produs, furnizandu-i acestuia toate informatiile legate de pastrare, utilizare, data limita de vanzare. 
 Prin intermediul elementelor de marcare, existente pe ambalaj, cumparatorul afla care sunt caracteristicile  produselor, denumirea,
 provenienta, compozitia, precum si impactul asupra mediului inconjurator.</p><br>

<p style="text-indent:2em;">Pe etichete sau direct pe ambalaj (in functie de natura produsului si tipul de ambalare), consumatorul va trebui sa gaseasca toate
 mentiunile despre marfa stabilite ca fiind obligatorii, mentiuni suplimentare specifice fiecarei grupe merceologice, precum si
 mentiuni facultative cu caracter promotional (vezi capitolul-“Etichetarea marfurilor”).</p><br>        

<p style="text-indent:2em;">Ambalajul trebuie sa promoveze produsul si sa contribuie la intarirea “imaginii de marca” pentru produs, dar, sa nu induca
in eroare cumparatorii.</p>

<p style="text-indent:2em;"></p><h6>In comertul international de marfuri exista o serie de termeni consacrati privind anumite tipuri de ambalaje si anumite mentiuni
 care se fac pe acestea, cum ar fi:</h6><p></p>

<p> Continental packing-  C.P. :ambalaj pentru transport terestru ;trebuie sa</p><br>

<p>corespunda tuturor conditiilor de transport</p><br>

<p>terestru si conditiilor climatice din diferitele</p><br>

<p>zone geografice pe care le parcurge marfa;</p><br>

<p>Seawirthy packing  -S.W.P:ambalaj pentru transportul marfurilor pe mare ;</p><br>

<p>trebuie sa fie executat din materiale rezistente</p><br>

<p>adecvate transportului maritim;</p><br>

<p>Fit  packing- F.P.: ambalaj  mulat dupa forma si marimea produsului;</p><br>

<p>Machine paching- M.P. ambalaj executat cu masini speciale</p><br>

<p>(cutii,baloturi);</p><br>                                         

<p>Resistense controlled R.C. ambalaj caruia i se controleaza rezistenta ,iar</p><br>

<p>rezultatele se concretizeaza intr-o diagrama</p><br>

<p>conform normelor internationale de control</p><br>

<p>al ambalajelor;</p><br>

<p>Special packing  paid S.P.P. ambalaj special superior, mai scump, executat</p><br>

<p>la cererea cumparatorului;</p><br>

<p>Strict confidential packing ambalaj strict confidential(marcarea coletelor</p><br>

<p>S.C.P.    se face numai cu numere).</p><br>   

<p style="text-indent:2em;">Raspandirea la scara globala a modelelor de consum, internationalizarea cererii de bunuri, a
 condus la evolutia cererii si productiei de ambalaje in plan mondial.</p><br>

<p style="text-indent:2em;">In tarile puternic industrializate, tot mai multe organizatii se implica in perfectionarea
 tehnologiilor privind productia de ambalaje.</p><br>

<p style="text-indent:2em;">In Europa, actioneaza Federatia Europeana a Ambalajelor (EAF), a carei membra este si Romania,
 Federatia Europeana a Fabricantilor de Carton Ondulat (FECO), Asociatia Europeana a Fabricantilor de
 Cutii din Carton Compact (ASSCO) si alte organizatii. Acestea sunt in stransa legatura cu Organizatia
 Internationla de Standardizare (ISO), Comisia Economica pentru Europa de pe langa ONU (CEE), Biroul International
 de Containere (BIC) si alte organisme internationale, care activeaza in domeniul productiei si circulatiei ambalajelor.</p><br>

<p style="text-indent:2em;">Pentru a patrunde pe noi segmente de piata, producatorii de ambalaje devin tot mai competitivi, atat prin tehnologiile
 utilizate, cat si prin folosirea de noi materiale pentru confectionarea ambalajelor care sa raspunda exigentelor sporite
 ale consumatorilor si cerintelor, tot mai severe, legate de protejarea mediului ambiant.</p><br>

<p style="font-weight:500;">I. Materiale utilizate pentru confectionarea ambalajelor</p>

<p style="text-indent:2em;"></p><h6>Modalitatea de alegere a materialului, din care se confectioneaza ambalajele, depinde de urmatoarele conditii:</h6><p></p>

<p>caracteristicile merceologice ale produsului care trebuie ambalat;</p><br>

<p>tehnica de ambalare utilizata;</p><br>

<p>factorii de mediu care pot afecta ambalajul pe timpul manipularii transportului si depozitarii;</p><br>

<p>destinatia ambalajului;</p><br>

<p>modul de circulatie (refolosibile, nerefolosibile);</p><br>

<p>tipul pietelor in care circula produsele ambalate (interne sau externe).</p><br>

<p style="text-indent:2em;">In functie de aceste conditii, s-a stabilit ca materialele de baza, ce trebuie folosite pentru ambalaje, la majoritatea
 marfurilor, sunt: lemnul, materialele celulozice (hartie, carton, acetate de celuloza, etc), materiale textile, materiale
 plastice, materiale metalice, sticla, etc.</p><br>

<p style="font-weight:500;">1. Ambalaje din lemn</p><br>

<p style="text-indent:2em;">Ambalajul din lemn a fost multa vreme utilizat pentru ambalarea unei game foarte diversificate de produse. In ultimii
 ani, ponderea acestui tip de ambalaj a scazut considerabil, pana la 10-15% din totalul acestui tip de ambalaj, datorita
 reducerii exploatarii masei lemnoase si a diversificarii valorificarii ei pentru alte produse.</p><br>

<p style="text-indent:2em;">Esentele cel mai des utilizate pentru ambalaje sunt esentele de stejar, brad, dud, artar, frasin sau sunt utilizate materialele
 prefabricate (placile presate din fibra de lemn-PFL, placile presate din aschii de lemn-PAL, placajele sau furniturile). Lemnul
 este utilizat, mai ales, la confectionarea ambalajelor de transport.</p><br>

<p style="font-weight:500;">2. Ambalaje din materiale celulozice</p>

<p style="text-indent:2em;"></p><h6>Ambalajele confectionate din materiale celulozice se folosesc intr-un procent ridicat pentru o gama diversificata de produse
 (produse alimentare, produse textile, produse de uz casnic, etc). Materialele, cel mai des utilizate, din aceasta gama sunt:</h6><p></p>

<p>a) Hartia si cartonul sunt cel mai des utilizate materiale celulozice pentru
confectionarea ambalajelor de acest tip.</p>

<p style="text-indent:2em;"></p><h6> Hartia utilizata pentru confectionarea ambalajelor poate fi de 3 tipuri:</h6><p></p>

<p>Netratata (inferioara, superioara si obisnuita);</p><br>

<p>Tratata (cerata, metalizata sau acoperita cu polimeri);</p><br>

<p>Speciala (creponata, anticoroziva).</p>

<p style="text-indent:2em;"></p><h6> Cartonul utilizat la confectionarea ambalajelor poate fi de 3 tipuri:</h6><p></p>

<p style="text-indent:2em;">Duplex (alcatuit din minimum doua straturi diferite de material fibros unite prin presare in stare umeda), este folosit
 la ambalarea confectiilor, produselor alimentare, etc;</p><br>

<p style="text-indent:2em;">Triplex (format din minimum trei straturi diferite de material fibros unite prin presare in stare umeda) este folosit
 pentru confectionarea ambalajelor de transport, fiind rezistent la manipulare si la socurile mecanice;</p><br>

<p style="text-indent:2em;">Ondulat (format din 1-4 straturi diferite de material neted si 1-3 straturi de hartie inferioara sau superioara ondulata,
 unite intre ele prin adeziv) este folosit la confectionarea ambalajelor care trebuie sa reziste la presiuni exterioare
 si la socuri;</p><br>

<p style="text-indent:2em;">Mucavaua este un tip de carton inferior utilizat la confectionarea ambalajelor
fixe sau pliabile folosite pentru marfurile usoare.</p><br>

<p style="text-indent:2em;">b) Acetatul de celuloza si celofanul sunt utilizate pentru confectionarea ambalajelor folosite in agricultura
 si in industria alimentara.</p><br>

<p style="text-indent:2em;">1 – cutie cu clapa-capac;2 – cutie cu maner; 3 – cutie cu fereastra; 4 – cutie cu pereti dublii; 5 – cutie cu capac mobil;
 6 – cutie clopot; 7 – cutie cilindrica; 8 - lada telecopica cu clape;  9  – lada telecopica cu corp si capac; 10 – cutie 
 cu capac dublu; 11 – cutie cu sertar; 12 – cutie cu sertar din trei piese.</p><br>

<p style="font-weight:500;">3. Ambalaje din materiale textile</p><br>

<p style="text-indent:2em;">Ambalajele din materiale textile se folosesc intr-un proces limitat pentru fabricarea sacilor din material textil (utilizati
 pentru ambalarea produselor alimentare in vrac-cafea, zahar, cereale, orez, etc) si, pentru captusirea ambalajelor 
 confectionate din carton sau din lemn, destinate obiectelor fragile (sticlarie, obiecte din ceramica).</p><br>

<p style="font-weight:500;">4. Ambalaje din materiale plastice</p>

<p style="text-indent:2em;"></p><h6>Materialele plastice sunt folosite, pe scara larga, la confectionarea ambalajelor, deoarece prezinta multe
 avantaje in comparatie cu materialele traditionale, si anume:</h6><p></p>

<p>sunt mai ieftine decat materialele traditionale;</p><br>

<p>pot fi utilizate sau reciclabile pe cale naturala sau artificiala;</p><br>

<p>sunt apreciate pentru proprietatile lor igienico-sanitare, de calitate;</p><br>

<p>au o masa redusa;</p><br>

<p>pot fi opace sau transparente;</p><br>

<p>pot fi suple sau rigide.</p>
 
<p style="text-indent:2em;"></p><h6>Materialele plastice, cel mai des utilizate, pentru confectionarea ambalajelor sunt:</h6><p></p>

<p style="text-indent:2em;"></p><h6>Polietilena este cel mai des utilizata in industria ambalajelor, iar sortimentele
folosite sunt:</h6><p></p>

<p>polietilena de joasa presiune, folosita la confectionarea navetelor pentru
paine, recipientelor pana la 400Kg, etc;</p><br>

<p>polietilena de medie presiune, folosita la confectionarea ambalajelor
pentru dulciuri, produse de papetarie, etc;</p><br>

<p style="text-indent:2em;">polietilena de inalta presiune, folosita la confectionarea de folii, saci,
pungi, tacamuri de unica folosinta (pahare, farfurii, etc), dopuri, capace;</p><br>

<p>polietilena expandata, folosita pentru umpluturile antisoc la ambalare.</p>

<p style="text-indent:2em;"></p><h6>Polistirenul este folosit sub forma de:</h6><p></p>

<p>folii pentru ambalarea produselor alimentare, a produselor legumicole, a
medicamentelor, etc.;</p><br>

<p>polistiren expandat pentru ambalarea produselor din sticla, a produselor
electrocasnice, etc;</p><br>

<p>Ploriclorura de vinil (PVC) este utilizata pentru confectionarea ambalajelor
destinate alimentelor, medicamentelor, etc;</p><br>

<p>Polipropilena se foloseste la confectionarea navetelor, canistrelor, precum si a
foliilor si a firelor utilizate pentru ambalat.</p><br>

<p>Spumele poliuretanice sunt utilizate pentru protejarea produselor sensibile la socuri.</p><br>

<p style="font-weight:500;">5. Ambalajele din materiale metalice</p><br>

<p>Materialele metalice, cel mai des utilizate pentru confectionarea ambalajelor,</p>

<p style="text-indent:2em;"></p><h6>sunt: otelul, aluminiul, materialele combinate (metal combinat cu material plastic
 si carton) si tabla cositorita. Dintre materialele metalice, cel mai des utilizate
 sunt:</h6><p></p>

<p style="text-indent:2em;">Tabla din otel carbon neprotejata (din care se confectioneaza ambalaje
pentru produse chimice si butoaie pentru produse petroliere);</p><br>

<p>Tabla din otel carbon cromata (pentru ambalarea pestelui si a fructelor
congelate);</p><br>

<p>Tabla din otel carbon protejata (zincata sau cositorita pentru ambalarea
conservelor);</p><br>

<p style="text-indent:2em;">Aluminiu in benzi sau folii (pentru confectionarea ambalajelor de desfacere si
 de transport utilizate in industria alimentara, a medicamentelor, etc).</p><br>

<p style="font-weight:500;">6. Ambalajele din sticla</p><br>

<p style="text-indent:2em;">Aceste ambalaje se utilizeaza pentru produsele lichide si pastoase din domeniul
 industriei alimentare, farmaceutice, chimice, etc.</p>

<p style="text-indent:2em;"></p><h6>Din aceasta categorie fac parte:</h6><p></p>

<p style="text-indent:2em;"></p><h6>Buteliile sunt de diverse tipuri: butelii pentru bere, bauturi alcoolice, racoritoare,
lapte, apa plata si apa gazoasa, etc. Ele pot avea capacitate diferita, ex.: buteliile
 pentru bere au urmatoarele capacitati:</h6><p></p>

<p>A – 330 si 650 ml;</p><br>

<p>B – 330 si 500 ml;</p><br>

<p>C – 500 ml;</p><br>

<p>D – 330 ml.</p>

<p style="text-indent:2em;"></p><h6>Borcanele sunt de urmatoarele tipuri:</h6><p></p>

<p>C – pentru miere de albine, de 96 si 1500 ml;</p><br>

<p>D si E – pentru compoturi si produse din legume, de 500 si 750 ml (se fixeaza
 cu cleme metalice);</p><br>

<p>F – pentru dulceata, de 250, 500 si 750 ml;</p><br>

<p>G – pentru compoturi, de 500 si 750 ml;</p><br>

<p>H – pentru legume murate, de 0,85 l pana la 20 l.</p>

<p></p><h4>II.Tendinte actuale in utilizarea materialelor destinate confectionarii ambalajelor</h4><p></p>

<p style="text-indent:2em;">In prezent, materialele utilizate pentru fabricarea ambalajelor sunt evaluate in functie de gradul in care satisfac
 cerintele consumatorilor, dar, in egala masura, si dupa efectul pe care il exercita asupra relatiei deseuri solide
 -mediu ambiant. Deoarece majoritatea deseurilor de ambalaje nu sunt biodegradabile, ele ingreuneaza-foarte mult-epurarea
 naturala.</p><br>

<p style="text-indent:2em;">Productia actuala mondiala de ambalaje este de cca. 300 de miliarde de dolari anual, rezultand o crestere alarmanta a
 procentului de reziduuri solide urbane (RSU). Din totalitatea reziduurilor solide urbane, 40% revine deseurilor de ambalaje,
 fapt care-i obliga pe producatorii din industria ambalajelor sa gaseasca noi solutii de compatibilitate ambientala a materialelor
 utilizate.</p><br>

<p style="text-indent:2em;">Materialele plastice sunt considerate poluanti de ordin secundar, reprezentand 1% din greutatea RSU. Singurii contaminanti
 nocivi ai mediului sunt: polistirenul (PS), care produce reziduuri murdare si policlorura de vinil (PVC), care degaja vapori
 clorosi (acestia se combina cu vaporii de apa din atmosfera si formeaza acidul clorhidric, care corodeaza puternic constructiile
 metalice).</p><br>

<p style="text-indent:2em;">Materialele metalice pot produce o poluare efectiva formand solutii metalice. Aluminiul insa, poate fi reciclat la nesfarsit,
 pornind de la stadiul de deseu.</p><br>

<p>Materialele de sticla sunt, in general, inerte in solutii apoase, provocand doar o poluare estetica.</p><br>

<p style="text-indent:2em;">Materialele lemnoase nu prezinta un pericol in ceea ce priveste poluarea mediului, lemnul fiind considerat un
 material ecologic.</p><br>

<p style="text-indent:2em;">Materialele pentru ambalaje, care au la baza hartia si cartonul, nu cauzeaza poluarea mediului inconjurator. 
Incinerarea si eliminarea lor sunt considerate nepoluante. Exceptie fac compusii organoclorurati rezultati din albirea
 pastei de hartie sau a cernelurilor de imprimare, considerate toxice. De asemenea, unele procedee utilizate la obtinerea
 hartiei pure pot declansa ploile acide care afecteaza vegetatia.</p><br>

<p style="text-indent:2em;">Din aceste motive, producatorii de ambalaje au gasit noi solutii privind obtinerea unor materiale si confectii de
 ambalaje, mai putin poluante, precum si metode noi de valorificare si eliminare eficienta a ambalajelor uzate.</p>

<p style="text-indent:2em;"></p><h6>In domeniul materialelor celulozice1 s-au inregistrat urmatoarele progrese:</h6><p></p>

<p>hartia de ambalaj “Eco-Eco”: biodegradabila si care inlocuieste lemnul in obtinerea hartiei;</p><br>

<p>materialul “CELMAR”: este bazat pe solubilitatea celulozei in apa si pe o rapida si completa biodegradare in mediu;</p><br>

<p>banderola ecologica “Ecomasc”: reprezinta o alternativa la sistemul de inchidere prin benzi adezive din PVC sau PP;</p><br>

<p>“hartia care vine din mare”, obtinuta prin utilizarea biomasei de alge;</p><br>

<p style="text-indent:2em;"> ambalajele din carton ondulat: au masa mica si sunt utilizate la prezentarea produselor zaharoase, ciocolata,
 biscuiti si pot fi reutilizate si eliminate eficient;</p><br>

<p>ambalajele modulare din lemn: sunt usor reutilizabile,etc.</p>

<p style="text-indent:2em;"></p><h6>In domeniul sticlei, se remarca urmatoarele progrese:</h6><p></p>

<p>confectionarea ambalajului de tip “pierdut” (one-way);</p><br>

<p>aparitia sticlei usoare si ultra usoare;</p><br>

<p>aparitia sticlei antibacterie si a sticlei ondulate; etc.</p>

<p style="text-indent:2em;"></p><h6>In domeniul materialelor metalice se remarca:</h6><p></p>

<p>reducerea masei recipientilor din aluminiu pentru bauturi, cu cel putin 60%.</p>

<p style="text-indent:2em;"></p><h6>In domeniul materialelor plastice, se remarca urmatoarele progrese:</h6><p></p>

<p style="text-indent:2em;"> aparitia pe piata britanica a buteliiilor PET de 2 litri, pentru produsul Coca-Cola (butelii care au
 in compozitie 25% rasini reciclate);</p><br>

<p style="text-indent:2em;"> adaugarea in compozitia PET a polimerilor de cristale lichide (LCP) in proportie de 10% a permis dublarea
 proprietatilor tip “bariera” fata de oxigen si cresterea rezistentei mecanice de cca. 2,7 ori;</p><br>

<p>realizarea, in Italia, a materialului “bionole”, pe baza de polimeri alifatici
(biodegradabil in 2 pana la 4 luni);</p><br>

<p style="text-indent:2em;"> realizarea, in SUA si Canada, a unor materiale biodegradabile (se descompun in apa si CO2
 sub actiunea microorganismelor prezente in sol, aproximativ 3 ani);</p><br>

<p>realizarea, in Japonia, a produsului “Soafil”, polimer comestibil utilizat la ambalarea individuala
 a legumelor si fructelor;</p><br>

<p style="text-indent:2em;"> realizarea, in Suedia, a polietilenei autodegradabile, care se pulverizeaza in cateva saptamani de
 expunere la radiatiile solare;etc.</p>

<p style="text-indent:2em;"></p><h6>In domeniul materialelor complexe se remarca:</h6><p></p>

<p style="text-indent:2em;"></p><h6>1.Voicu, Oana Luminita-Etichetarea alimentelor in spatiul european, Ed.Bren, Buc.2003:</h6><p></p>

<p style="text-indent:2em;"> materialul numit “ceramis”, utilizat pentru ambalarea branzeturilor, produselor de patiserie, produse zaharoase
 (usor de reciclat, iar prin incinerare nu polueaza mediul);</p><br>

<p> materialul “metpolam” (otel acoperit cu polimeri), utilizat in Marea Britanie pentru ambalarea
 alimentelor pentru copii;</p><br>

<p> utilizarea foliilor extra subtiri din tabla de otel cositorita, folosite la obtinerea</p><br>

<p>capsulelor sub presiune ce inchid un ambalaj sau la realizarea de saci si pungi, in combinatie cu 
alte materiale;</p><br>

<p style="text-indent:2em;"> ambalajul aseptic utilizat pentru bauturi, cu avantaje ecologice imediate, deoarece se realizeaza mari
 economii de materiale si energie in tehnologia de obtinere, de umplere si de depozitare; reciclarea ambalajului
 aseptic se utilizeaza foarte usor;</p><br>

<p>aparitia materialelor cu proprietati de inalta bariera fata de apa, grasimi, uleiuri</p><br>

<p style="text-indent:2em;">si arome, de tipul “scotchban”: spre deosebire de laminate, care protejeaza hartia sau cartonul la suprafata,
 “scotchban”-ul patrunde in profunzimea materialului, asigurand o protectie maxima. Se foloseste la ambalarea
 produselor de carne, inghetata, biscuiti sau la ambalarea alimentelor agresive, etc. In paralel cu preocuparea
 pentru adoptarea de masuri privind reciclarea si degradarea anumitor tipuri de ambalaje, s-au elaborat diferite
 sistem de codificare pentru reciclarea ambalajelor, atat in statele Uniunii Europene, cat si in SUA. De exemplu,
 Societatea pentru industria materialelor plastice (SPI) din SUA a elaborat un sistem complet de codificare a
 materialelor plastice (utilizat si in Europa).     Sistemul de codificare SPI consta intr-o sageata inchisa a
 lui Mobius Loop, care se completeaza cu o abreviere sau cu un numar, in functie de tipul de material.</p>

<p style="text-indent:2em;"></p><h6>Elementele componente ale noului sistem de codificare pentru reciclarea 
ambalajului sunt urmatoarele:</h6><p></p>

<p>PET No 1 = Polyethilene-terephthalate (Polietilena tereftalat)</p><br>

<p>HDPE No 2 = High Density Polyethylene (Polietilena de inalta densitate)</p><br>

<p>V No 3 = Vinyl (including Polyvynilchloride – PVC)</p><br>

<p>LDPE No 4 = Low Density polyethylene (Polietilena de joasa densitate)</p><br>

<p>PP No 5 = Polypropylene (Polipropilena)</p><br>

<p>PS No 6 = Polystyrene (Polistiren)</p><br>

<p>OTHER No 7 = all other plastics including multi-layer materials (alte materiale plastice, inclusiv
 materiale multistratificate).</p><br>

<p style="text-indent:2em;">Atat in SUA, cat si-n Europa, o serie de organisme, asociatii, societati, au stabilit o legislatie
 ampla care face referiri concrete si obligatorii la masurile ce trebuie stabilite de guverne, in
 plan national si international, privind productia, utilizarea si depozitarea ambalajelor, astfel
 incat, sa nu afecteze sanatatea consumatorilor sau a mediului inconjurator . Prin standardele ISO
 3758/1996, SR EN 23.758 se stabilesc simbolurile grafice care se aplica, in mod obligatoriu, pe ambalaje,
 pentru a avertiza consumatorul asupra modului de utilizare, manevrare si transport, precum si simbolurile
 referitoare la protectia mediului inconjurator.</p>

<p></p><h4>III. Metode de ambalare</h4><p></p>

<p style="text-indent:2em;">Metodele de ambalare s-au perfectionat permanent, in stransa legatura cu relatia: produs-ambalaj-mediu inconjurator
 si cu tendintele de viitor, privind productia si circulatia marfurilor pe pietele interne si internationale.</p>

<p style="text-indent:2em;"></p><h6>Metodele si tehnicile de ambalare depind de caracteristicile merceologice ale produselor ce se ambaleaza in concordanta
 cu proprietatile materialelor din care se confectioneaza ambalajele. Tehnicile de ambalare cele mai cunoscute sunt:</h6><p></p>

<p style="text-indent:2em;">Ambalarea in recipienti de sticla, metal, in carton si material plastic se realizeaza
pe linii automatizate, iar ambalajele de sticla si metal se sterilizeaza, de regula, deoarece se folosesc la
ambalarea alimentelor si a medicamentelor;</p><br>

<p style="text-indent:2em;">Ambalarea in atmosfera modificata (atmosfera de CO2, N2, O2), folosita pentru
prelungirea timpului de pastrare a produselor, fara ajutorul conservantilor. Produsele sunt plasate intr-un
 ambalaj in care atmosfera din interior este modificata in raport cu gazelle folosite si vaporii de apa. Cele
 3 gaze utilizate in locul aerului (CO2, O2, N2) au proprietati necesare conservarii produselor alimentare.</p>
 
<p style="text-indent:2em;"></p><h6>Astfel:</h6><p></p>

<p>C2, fiind bacteriostatic si fungistatic, stagneaza sau elimina evolutia bacteriilor si a mucegaiurilor;</p><br>

<p>N2, inert si inodor, reduce oxidarea grasimilor;</p><br>

<p>O2, in amestec gazos, mentine culoarea naturala a produselor de carne;</p><br>

<p style="text-indent:2em;">Ambalarea cu aerosoli (cu agent propulsor – N2, CO2, butan, propan),folosita la pulverizarea lacurilor, vopselurilor, insecticidelor, dar, si unor creme de uz alimentar (frisca)
 sau creme cosmetice si farmaceutice. Recipientul (din metal, sticla sau material plastic) contine un gaz comprimant,
 lichefiat si este prevazut cu un dispozitiv care permite iesirea continutului din recipient, sub o forma modificata
 de spuma, pasta, lichid sau pudra.</p><br>

<p style="text-indent:2em;">Ambalarea celulara (sub forma de casete comprimate – utilizate, mai ales, in
industria farmaceutica), metoda avantajoasa, in plan economic, precum si in plan igienic si estetic;</p><br>

<p style="text-indent:2em;">Ambalarea cu strat “cocon” este aplicata, de regula, la aparatele foarte
sensibile (electronice, armament) sau pentru rezervele de alimente pe termen lung, asigurand o protectie a 
produselor cuprinsa intre 2 si 10 ani;</p><br>

<p style="text-indent:2em;">Ambalarea cu pelicule aderente se obtine prin aplicarea pe produs a unui strat
rezistent, impermeabil, din material peliculogen care, dupa uscare, protejeaza suprafata produsului (pentru
 produse alimentare, etc);</p><br>

<p style="text-indent:2em;">Ambalarea cu folii contractibile din produse polimere, termoplastice, a caror
proprietate principala este aceea de a se contracta sub influenta caldurii. Acest procedeu se utilizeaza in
 domeniul alimentar si cosmetic, prin ambalarea unor cantitati mici de produs, in alveole termoformante, acoperite
 cu folii transparente si, apoi, termosudante;</p><br>

<p style="text-indent:2em;">Ambalarea sub vid, se aplica produselor alimentare, sensibile la actiunea O2
din atmosfera. Se realizeaza cu ajutorul unei instalatii speciale, si presupune absenta aerului in interiorul
 ambalajului (se foloseste la ambalarea ermetica a produselor alimentare sub forma de pulbere: lapte praf,
 creme, cafea, etc);</p><br>

<p style="text-indent:2em;">Ambalarea in sistem Cryovac este o varianta a ambalarii sub vid si se aplica
pentru preparate alimentare din carne, peste, lapte, precum si pentru legumele si fructele proaspete. Se folosesc
 foliile Cryovac impermeabile, care se contracteaza in apa calda (90-980C), luand forma produsului ambalat;</p><br>

<p style="text-indent:2em;">Ambalarea tip “bibbipak” se foloseste pentru ambalarea produselor sensibile 
la socuri, prin utilizarea de lichid poliuretanic, care, devenind spongios, protejeaza produsele astfel ambalate;</p><br>

<p style="text-indent:2em;">Ambalarea aseptica este specifica produselor alimentare si medicamentelor
comercializate sub forma lichida sau solida. Tehnica de realizare a mediului aseptic, consta in sterilizarea produsului 
la temperature cuprinse intre 90 si 1200C sau la 135-1500C, timp de cateva secunde (in functie de natura produsului), 
urmata de o racire rapida. Scopul ambalarii aseptice consta in distrugerea sau inactivarea microorganismelor pentru o 
perioada de timp, astfel incat, alimentele isi pastreaza calitatile nutritive initiale si chiar, aroma si culoarea 
naturala;</p>

<p style="text-indent:2em;"></p><h6>Ambalarea colectiva si portionata consta in:</h6><p></p>

<p style="text-indent:2em;"> ambalarea colectiva este metoda prin care se grupeaza mai multe produse
intr-o unitate de vanzare (de ex., la zahar, faina, malai, etc), utilizandu-se ca materiale pentru ambalat, cartonul, 
hartia, foliile contractibile;</p><br>

<p style="text-indent:2em;"> ambalarea portionata este metoda prin care se ambaleaza o cantitate de
produs ce se consuma la o singura folosire; materialele utilizate sunt foliile termosudabile din aluminiu, hartiile 
metalizate, foliile contractibile, etc.</p>

<p style="text-indent:2em;"></p><h6>Modalitatile si tehnicile, prin care se realizeaza operatia de ambalare, depind de specificul marfii care se livreaza
 beneficiarului. In acest sens, exista 2 modalitati de ambalare:</h6><p></p>

<p>ambalarea volumetrica: consta in dozarea volumului cantitatii de lichid ce
urmeaza sa fie ambalat;</p><br>

<p style="text-indent:2em;">ambalarea masei de produs (pentru produsele solide): consta in dozarea
gravimetrica a cantitatii de produs ce urmeaza sa fie ambalat.</p><br>         

<p style="font-weight:500;">EXPERTIZA MARFURILOR AFLATE IN LITIGIU, DATORITA CONDITIILOR DE PASTRARE</p><br>

<p style="text-indent:2em;">Perioada de pastrare a marfurilor poate influenta, fundamental, caracteristicile calitative ale marfurilor, prin
 aparitia unor modificari fizice, chimice sau biochimice, cu implicatii asupra calitatii finale a produselor depozitate.</p>
 
<p style="text-indent:2em;"></p><h6>Aceste modificari apar datorita actiunii unor factori ce pot fi grupati in doua grupe mari, si anume:</h6><p></p>

<p style="text-indent:2em;"></p><h6>Factorii interni:</h6><p></p>

<p>structura si compozitia chimica a produselor;</p><br>

<p>proprietatile fizice generale (starea de agregare, proprietatile termice, electrice, etc);</p><br>

<p>proprietatile chimice (comportarea fata de mediile acide, bazice, agentii corozivi, umiditate,
 etc).</p>

<p style="text-indent:2em;"></p><h6>Factorii externi:</h6><p></p>

<p>fizico-mecanici (solicitari mecanice in timpul manipularii, compresiuni, socuri, etc);</p><br>

<p style="text-indent:2em;"> fizico-chimici (temperatura, umiditatea, circulatia aerului, umiditatea relativa a aerului
 din spatiile de pastrare, lumina solara, etc);</p><br>

<p>biologici (microorganismele: bacterii, mucegaiuri, precum si insecte, rozatoare, etc).</p>

<p style="text-indent:2em;"></p><h6>Calitatea marfurilor depozitate mai poate fi influentata si de existenta altor elemente:</h6><p></p>

<p>regimul depozitarii (igiena din spatiul de depozitare, respectarea vecinatatilor;</p><br>

<p style="text-indent:2em;">admise ale produselor, etc), cunoscand ca, asigurarea unui regim optim de pastrare
 conduce la mentinerea echilibrului intre actiunea si dependenta reciproca dintre 
 factorii interni si externi;</p><br>

<p>atmosfera specifica spatiilor de depozitare, respectiv temperatura, umiditatea;</p><br>

<p style="text-indent:2em;">relativa, gradul de puritate al aerului (concentratia in particule de praf, gaze toxice, microorganisme),
 precum si actiunea razelor solare.</p><br>

<p style="text-indent:2em;">Efectuarea expertizelor merceologice, ale marfurilor depreciate in timpul pastrarii, a demonstrat faptul ca,
 cel mai important rol in modificarea caracteristicilor calitative il au factorii de mediu ai spatiilor de
 depozitare, cei mai agresivi fiind temperatura, umiditatea relativa a aerului, circulatia aerului, compozitia
 chimica a aerului si radiatiile.</p><br>

<p>a) Actiunea temperaturii din mediul de pastrare asupra marfurilor</p><br>

<p style="font-weight:500;">MARCAREA MARFURILOR</p><br>

<p style="font-weight:500;">Conceptul de marca ( Legea nr. 84/15.04.1998 privind marcile si indicatiile</p><br>

<p style="text-indent:2em;">geografice, publicata in M.O. nr.161/23.04.1998) este definit astfel:”marca este un semn susceptibil
 de reprezentare grafica, servind la deosebirea produselor sau serviciilor unei persoane fizice sau
 juridice de cele apartinand altor persoane.”</p><br>

<p style="font-weight:500;">In literatura de specialitate, marca se mai defineste ca fiind” un semn distinctiv ce</p><br>

<p style="text-indent:2em;">poate fi reprezentat prin cuvinte, litere, cifre,  grafice sau desene, ori o combinatie de astfel de 
elemente menite sa confere identitate bunurilor, serviciilor sau intreprinderilor si sa asigure 
diferentierea lor de concurenta”.</p>

<p style="text-indent:2em;"></p><h6>Clasificarea marcilor se face dupa urmatoarele criterii:</h6><p></p>

<p style="text-indent:2em;"></p><h6>1) In functie de originea numelui de marca se utilizeaza:</h6><p></p>

<p>nume de persoane apartinand: inventatorului produsului, proprietarului</p><br>

<p style="text-indent:2em;">firmei producatoare, proprietarului magazinului in care se desface produsul,
 persoanei care la patentat, etc (Ford, Peugeout);</p><br>

<p>nume care isi au originea pornind de la tara sau regiunea in care produsul
 a fost inventat sau comercializat;</p><br>

<p>nume stiintifice;</p><br>

<p>nume provenind din asocieri sugestive de cuvinte;</p><br>

<p>nume care descriu produsul;</p><br>

<p>nume artificiale care nu au corespondent in realitate.</p>

<p style="text-indent:2em;"></p><h6>2)   In functie de rolul pe care il au in cadrul politicii de produs distingem
urmatoarele tipuri:</h6><p></p>

<p style="text-indent:2em;">marca-produs, ce presupune atribuirea unui nume de marca fiecarui
produs, constituindu-se intr-un element principal in pozitionarea acestuia;</p><br>

<p style="text-indent:2em;">marca–linie, presupune acoperirea unei linii de produse, folosind
pentru fiecare dintre componente, acelasi simbol de identificare;</p><br>

<p style="text-indent:2em;">marca-gama se atribuie unui ansamblu de produse omogene, astfel
incat, sa acopere intreaga gama de produse ale unei intreprinderi;</p><br>

<p>marca-umbrela presupune folosirea aceleasi marci pentru a identifica
un ansamblu de produse eterogene;</p><br>

<p style="text-indent:2em;">marca-garantie (marca de certificare) are rolul de a acoperi mai
multe game complexe de produse care, la randul lor, sunt identificate prin intermediul unor marci–produs,
marci-linie sau marci-gama.</p><br>

<p style="text-indent:2em;">Marca-garantie atesta faptul ca produsele, pentru care este folosita, sunt certificate de titularul marcii,
in ceea ce priveste calitatea si fiabilitatea, informand consumatorul despre originea marfii prin indicarea
marcii producatorului.</p><br>

<p>Marca-garantie grupeaza produse sau game de produse care dispun de marci specifice, in jurul marfii institutionale.</p><br>

<p style="text-indent:2em;">Marca-garantie are ca varianta marca-eticheta, prin care garanteaza produsele fabricate sau comercializate de
catre o intreprindere, alta decat detinatoarea marcii.</p><br>

<p style="font-weight:500;">O varianta particulara de marca o reprezinta grifa, aceasta fiind o reprezentare</p><br>

<p>grafica specifica, cu rol de semnatura a creatorului si care se atribuie, de regula, produselor unicat.</p>

<p style="text-indent:2em;"></p><h6>.3) In functie de titularul dreptului de marca exista:</h6><p></p>

<p>individuala, ce apartine unei persoane determinate, fizice sau juridice;</p><br>

<p>este foarte des folosita, de aceea formeaza obiectul principal al reglementarilor privind marcile in diverse legislatii;</p><br>

<p>marcile colective sunt marcile folosite in comun de mai multe</p><br>

<p style="text-indent:2em;">persoane juridice (asociatii de producatori, comercianti, diferite organisme profesionale) care nu desfasoara direct
 o activitate comerciala sau industriala, dar, care autorizeaza aplicarea marcilor colective, de obicei, alaturi
 de marca individuala, sub rezerva respectarii unor conditii de calitate, prestabilite; au rolul de a garanta
 calitatea sau originea produselor respective (in Romania, marcile colective se inregistreaza prin Oficiul de
 Stat pentru Inventii si Marci-OSIM);</p>

<p style="text-indent:2em;"></p><h6>4)In functie de obiectul si destinatia lor:</h6><p></p>

<p>marca de fabrica este semnul distinctiv adoptat de un producator;</p><br>

<p style="text-indent:2em;">pentru a da posibilitatea consumatorilor sa identifice si sa deosebeasca produsele sale de
produsele similare fabricate de alti producatori;</p><br>

<p style="text-indent:2em;">marca de comert este semnul distinctiv folosit de o intreprindere
distribuitoare pentru a evidentia anumite produse distribuite in propriile unitati comerciale;</p>

<p style="text-indent:2em;"></p><h6>marca de serviciu are rolul de a identifica prestarile de serviciu
executate de o anumita intreprindere, prezentand doua ipostaze:</h6><p></p>

<p>marca de serviciu atasata produselor ce intervin in serviciul prestat;</p><br>

<p>marca de serviciu care nu este legata de un produs anume;</p>

<p style="text-indent:2em;"></p><h6>5) In functie de natura semnelor folosite:</h6><p></p>

<p>marca verbala este alcatuita din semne scrise constand din nume,
denumiri, sloganuri, litere sau cifre;</p>

<p style="text-indent:2em;"></p><h6>marca figurativa cuprinde toate reprezentarile grafice susceptibile de
protectie (embleme, sigilii, culori, amprente, desene, viniete). Marcile figurative pot
fi, la randul lor, in functie de aspectul geometric, de 2 categorii:</h6><p></p>

<p>marci figurative bidimensionale (plane);</p><br>

<p>marci figurative tridimensionale (spatiale);</p><br>

<p>marca sonora sau auditiva este alcatuita din semnale sonore sau</p><br>

<p style="text-indent:2em;">melodii simple, compuse doar din cateva note sau din sunete repetate, caracteristice si usor de retinut;
 se folosesc ca marci de servicii (utilizate frecvent de posturile TV si radio), precum si marci de
 fabrica sau marci de comert.</p>

<p style="text-indent:2em;"></p><h6>6) In functie de numarul semnelor folosite:</h6><p></p>

<p style="text-indent:2em;">marca simpla, alcatuita dintr-unul din semnele care, potrivit legii, pot
fi protejate ca marca (o reprezentare grafica, un nume);</p>

<p style="text-indent:2em;"></p><h6>marca combinata reprezinta compozitii de semne diferite, fiind
clasificata, la randul ei, in:</h6><p></p>

<p style="text-indent:2em;">marca complexa: alcatuita dintr-un ansamblu de semne diferite,
fiecare dintre acestea fiind susceptibil de protectie (deoarece este distinctiv);</p><br>

<p>marca compusa: alcatuita din semne diferite, dintre care, doar unele
sunt susceptibile de protectie in mod separat.</p>

<p style="text-indent:2em;"></p><h6>7) In functie de natura normelor care reglementeaza marca:</h6><p></p>

<p style="text-indent:2em;">marca facultativa: dreptul de a decide aplicarea marfii pe un produs
depinde de vointa producatorului, comerciantului sau intreprinderii de servicii;</p><br>

<p style="text-indent:2em;">marca obligatorie se aplica la acele produse a caror fabricatie
constituie monopol de stat ( de ex. marca produselor de metale si pietre pretioase).</p>

<p style="text-indent:2em;"></p><h6>In practica functioneaza si alte tipuri de marci, cum sunt:</h6><p></p>

<p style="text-indent:2em;">marca defensiva asigura protectia altei marci, avand toate
caracteristicile figurative ale acesteia, cu exceptia unor modificari de detaliu, menite sa-i impiedice pe terti sa
depuna o marca foarte asemanatoare;</p><br>

<p style="text-indent:2em;">marca de rezerva: este o marca pe care titularul nu are intentia sa o
foloseasca in momentul inregistrarii, dar, isi rezerva dreptul de marca pentru situatia
lansarii unui nou produs pe piata;</p><br>

<p style="text-indent:2em;">marca notorie este marca foarte cunoscuta pe piata cu un mare
prestigiu pentru publicul consumator de produse sau servicii, avand marca respectiva;</p>

<p style="text-indent:2em;"></p><h6>marca generica:</h6><p></p> 

<p style="text-indent:2em;">desemneaza un nume de marca, ce are valoare de
substantiv comun si care si-a pierdut capacitatea de a asigura diferentierea unui produs de produsele concurente
(ex. scotch, frigidaire).</p><br>

<p style="font-weight:500;">Componentele care alcatuiesc marca</p>

<p style="text-indent:2em;"></p><h6>Marca este alcatuita din 3 categorii de componente:</h6><p></p>

<p style="text-indent:2em;"></p><h6>componente verbale – reprezentate de:</h6><p></p>

<p style="text-indent:2em;">numele de marca este elementul generic de identificare al unui
produs, al unei game de produse sau al unei organizatii si reprezinta principalul mijloc de comunicare;</p><br>

<p style="text-indent:2em;">denumirile asociate marcilor pot fi compuse dintr-un singur cuvant, dintr-o combinatie de cuvinte
sau din combinatii de cifre si cuvinte;</p><br>

<p style="text-indent:2em;">sigla reprezinta abrevierea numelui de marca prin folosirea initialelor in scopul facilitarii memorarii,
citirii si utilizarii acesteia.</p>

<p style="text-indent:2em;"></p><h6>Siglele sunt de doua feluri:</h6><p></p>

<p style="text-indent:2em;">sigle abrevieri, obtinute prin prescurtarea propriu-zisa a numelui si sigle
analogice, cand fiecarei initiale i se atribuie o anumita semnificatie;</p>

<p style="text-indent:2em;"></p><h6>componentele semifigurative si figurative sunt:</h6><p></p>

<p style="text-indent:2em;">Logotipul este o componenta care are in vedere reprezentarea vizuala a numelui de marca, astfel incat, sa-l puna cat mai bine in evidenta prin utilizarea unor elemente figurative (linii
drepte, curbe, incadrari, sublinieri), a unor caractere tipografice deosebite si a unor
culori sau asocieri de culori.</p>
 
<p style="text-indent:2em;"></p><h6>Logotipurile sunt de 4 feluri:</h6><p></p>

<p>logotipuri simple: presupun scrierea numelui de marca, utilizand
anumite caractere si o caligrafie originala;</p><br>

<p>logotipuri complexe: presupun incadrarea numelui de marca
intr-un simbol vizual (cerc, patrat, romb, etc);</p><br>

<p style="text-indent:2em;">logotipuri iconografice: presupun inscrierea numelui de marca, in
totalitate sau partial, intr-o imagine ce are legatura cu activitatea intreprinderii sau cu natura produselor;</p>

<p style="text-indent:2em;"></p><h6>siglotipuri:</h6><p></p> 

<p>presupun reprezentarea vizuala, simpla sau simbolica a
unei sigle.</p><br>

<p style="text-indent:2em;">Emblema este o componenta care presupune asocierea unei imagini grafice numelui de marca, fiind
reprezentata prin desene ce pot lua diferite forme (figuri geometrice,
obiecte, personaje reale sau imaginare, reprezentari din lumea animala sau vegetala) sau o culoare distinctiva.</p><br>

<p>Designul sau pictogramele sunt desene ce exprima, printr-un
singur efect de imagine, o idee sau un cuvant.</p><br>

<p style="font-weight:500;">Protectia marcilor</p><br>

<p style="text-indent:2em;">Uniunea Europeana a acordat o atentie deosebita stabilirii unor reguli comune
pentru tarile membre, de protectie a proprietatii industriale, inclusiv a marcilor.</p><br>

<p style="text-indent:2em;">In acest sens, s-au intreprins masuri de armonizare a legislatiilor nationale privind marcile nationale cu legislatia
 europeana, in vederea instituirii unei marci comunitare, astfel incat, piata comunitara sa fie ferita de importul produselor
 cu marci contrafacute.</p><br>          

<p style="text-indent:2em;">Reglementarile comunitare prevad posibilitatea ca agentii economici sa opteze intre marcile nationale si marca comunitara.
 Orice firma poate solicita inregistrarea unei marci in U.E., organismul abilitat in acest sens fiind OHIM (Oficiul de
 Armonizare pe Piata Interna).</p><br>

<p style="text-indent:2em;">Consiliul Europei acorda firmelor dreptul de a achizitiona marci comunitare care beneficiaza de protectie
 valabila pe intreg teritoriul comunitar.</p><br>

<p style="text-indent:2em;">Prin adoptarea Legii nr.84/1998 privind marcile si indicatiile geografice, Romania s-a aliniat la reglementarile
 internationale. Inregistrarea unei marci se face de catre organismul abilitat in acest sens (OSIM) si poate fi
 ceruta individual sau in comun de persoane fizice sau juridice, direct sau prin mandatar, cu domiciliul (cu sediul)
 in Romania. Potrivit reglementarilor din tara noastra, titularul marcii are dreptul de folosire exclusiva a acesteia,
 pentru produsele inregistrate, pe o perioada de 10 ani de la data depozitului national reglementar al marcii. Depozitul
 national reglementar al marcii asigura titularului un drept de prioritate din momentul inregistrarii la OSIM, fata de orice
 depozit ulterior, pentru aceeasi marca, aferenta aceluiasi produs. Inregistrarea marcii poate fi reinnoita dupa expirarea
 perioadei de 10 ani, fara a se aduce modificari marcii si fara a se extinde lista de produse. Titularul marcii are dreptul
 sa aplice sau sa ataseze marca inregistrata pe produs, pe ambalaj sau pe documentele ce insotesc produsele.</p><br>

<p style="text-indent:2em;">Dupa publicarea marcii, titularul poate cere sa fie interzise tertilor urmatoarele actiuni: aplicarea semnului pe produse
 sau pe ambalaje, comercializarea produselor sau oferirea lor, importul sau exportul produselor sub acest semn si utilizarea
 semnului pe documente sau pentru publicitate. Legislatia actuala, privind protectia consumatorilor, prevede sanctionarea
 actelor de contrafacere, imitare sau folosire, fara drept, a unei marci.</p> 

<p style="text-indent:2em;"></p><h6>Statele care apartin U.E. au semnat in 1986 Actul Unic European, prin care
organismele europene luau urmatoarele masuri legislative:</h6><p></p>

<p style="text-indent:2em;">RCEE (CEE) nr.3842/1986 interzice importul produselor contrafacute,
specificand conditiile de coexistenta a marcilor nationale cu cele ale comunitatii, precum si conditiile
de protectie a marcilor;</p>

<p style="text-indent:2em;"></p><h6>Directiva nr.89/104/CEE din 21.12.1988 realizeaza armonizarea unor
aspecte privind marcile nationale, ca de ex.:</h6><p></p>

<p>definirea semnelor care pot fi protejate ca marca;</p><br>

<p>drepturile acordate de marca;</p><br>

<p>motivele de respingere sau de anulare a marcilor;</p><br>

<p>marcile colective si de certificare, etc.;</p><br>

<p style="text-indent:2em;">RCEE nr.40/1994 a introdus marca comunitara. In tarile membre ale
UE s-a stabilit principiul de epuizare comunitara, prin care, in cazul comercializarii unor produse
 de catre titular sau cu acordul sau intr-un stat membru al UE, titularul nu se poate opune comercializarii
 acelor produse si in alte state care apartin UE.</p><br> 

<p>Directivele CEE au un rol complementar fata de legislatia de protectie a marcii, in interiorul fiecarui
 stat membru al UE.</p>

<p style="text-indent:2em;"></p><h6>In Romania, prin Legea nr.84/1998, constituie infractiune inregistrarea unor marci care:</h6><p></p>

<p style="text-indent:2em;">contrafac, imita sau folosesc fara drept o marca, in scopul inducerii in
eroare a publicului asupra calitatii produselor (serviciilor) la care se refera aceasta marca;</p><br>

<p style="text-indent:2em;">care pun in circulatie, fara drept, un produs ce poarta o marca identica
sau similara cu o marca inregistrata pentru produse identice sau similare si care prejudiciaza pe
 titularul marcii inregistrate anterior;</p><br>

<p style="text-indent:2em;">pun in circulatie produse ce poarta indicatii geografice care indica sau
sugereaza ca produsul in cauza este originar dintr-o regiune geografica, alta decat locul adevarat de origine,
 in scopul inducerii in eroare a publicului cu privire la originea produsului.</p><br>

<p style="font-weight:500;">Marcarea ecologica a produselor</p><br>

<p style="text-indent:2em;">In ultimele decenii, la nivel international, s-a acordat o atentie deosebita diminuarii impactului pe care
 il are fabricarea si utilizarea produselor si ambalajelor asupra mediului ambient. Preocuparile legate de starea mediului
 s-au concretizat in reglementari la nivel national, regional si international, din ce in ce mai severe, vizand urmatoarele
 obiective: reducerea rezidurilor provenite de la ambalaje, cresterea gradului de recuperare si valorificare a materialelor
 de ambalare uzate si planificarea zonei in care pot fi ingropate sau arse, in mediul liber, materialele respective. Tarile
 puternic dezvoltate din UE urmaresc promovarea unei politici ambientale eficiente, care sa monitorizeze gestionarea deseurilor
 de ambalaje aflate in continua crestere. Activitatea de reciclare reprezinta o modalitate de rezolvare a contradictiei dintre
 consumul suplimentar de resurse si caracterul limitat al resurselor. In acest scop, se impune importatorilor sau producatorilor
 europeni necesitatea de a organiza o retea de recuperare a ambalajelor sau de a adera la un organism acreditat, care sa actioneze
 in numele lor.</p><br>

<p style="text-indent:2em;">In UE exista, in prezent, peste 300 de directive, reglementari si decizii privind mediul, deseurile, evaluarea impactului
 lor asupra mediului, etc.</p><br>

<p style="text-indent:2em;">Directiva 74/442/CEE din 15.07.1975  stabileste o lista a deseurilor pe categorii (enumerate in anexa directivei), denumita
 “Catalog European al Deseurilor (CED)”, care se refera la deseurile destinate eliminarii sau valorificarii.</p><br>

<p style="text-indent:2em;">In 1994 se emite Directiva 94/1962/CEE privitoare la ambalaje si la deseurile de ambalaje, care a pus bazele cercetarii metodelor
 eficiente de tratare a rezidurilor de ambalaje sub raport ambiental si economic.</p>

<p style="text-indent:2em;"></p><h6>Organizatia Internationala de Standardizare (ISO) a elaborat Ghidul ISO 64-pentru introducerea aspectelor de mediu in standardele
 de produs. Acesta contine recomandari pentru protejarea mediului de catre intreprinderi, prin analiza ciclului de viata al produsului.
 Standardele ISO 14.000, destinate tinerii sub control a impactului produselor si serviciilor asupra mediului, se refera la urmatoarele
 elemente deosebit de importante:</h6><p></p>

<p>1. marcarea ecologica;</p><br>

<p>2. analiza ciclului de viata al produselor ( SR ISO 14.040-14.043).</p><br>

<p style="text-indent:2em;">La baza marcarii ecologice a produselor stau criteriile de performanta ecologica, specifice fiecarei categorii de produse,
 aceasta necesitand o abordare multicriteriala, prin examinarea ciclului de viata al produsului.</p><br>

<p style="text-indent:2em;">Marca ecologica are drept obiectiv certificarea, in fata consumatorului, a calitatii ecologice a ambalajului, a produsului,
 precum si a ansamblului produs-ambalaj.</p><br>

<p style="text-indent:2em;">Marca de produs ecologic exercita o presiune permanenta asupra industriei pentru realizarea unor produse nepoluate si, in acelasi
 timp, stimuleaza constiinta ecologica a cumparatorilor, privind un mediu curat, nepoluat.</p>

<p style="text-indent:2em;"></p><h6>In 1992, prin Directiva nr.880/1992/CEE a fost creata - oficial - eticheta ecologica europeana. Sistemul
 comunitar de marcare ecologica este voluntar si descentralizat si priveste urmatoarele:</h6><p></p>

<p>promovarea de produse, respectiv ambalaje cu impact ambiental pozitiv pe intregul lor ciclu de viata;</p><br>

<p style="text-indent:2em;">informarea corecta a consumatorilor din tarile membre ale UE asupra caracteristicilor ecologice ale produselor
 comercializate pe piata comunitara.</p>

<p style="text-indent:2em;"></p><h6>In fiecare din etapele ciclului de viata se recomanda luarea in considerare a urmatoarelor aspecte ambientale:</h6><p></p>

<p>cantitatea de deseuri rezultate;</p><br>

<p>contaminarea si degradarea solului;</p><br>

<p>contaminarea apei si a aerului;</p><br>

<p>nivelul zgomotului;</p><br>

<p>consumul de energie si resurse naturale;</p><br>

<p>efectul asupra ecosistemelor.</p><br>
      
<p>Analiza include si impactul ambalajului asupra mediului in etapele mentionate.</p><br>

<p style="text-indent:2em;">Acest sistem este aplicabil tuturor produselor, inclusiv celor importate in UE din terte tari. Produsele in cauza,
 trebuie sa corespunda reglementarilor UE, referitoare la protectia vietii si sanatatii consumatorilor, precum si la 
 protectia mediului inconjurator.</p><br>

<p style="text-indent:2em;">Eco-simbolul intrebuintat reprezinta o floare a carei corola este reprezentata prin simbolul cu stele al UE, in mijloc
 fiind plasata litera “Є”(Fig. 11.5 - e). Acest marcaj informeaza consumatorul asupra faptului ca produsul (ambalajul)
 exercita un impact redus asupra mediului pe intreg ciclul de viata. Dreptul de aplicare al eco-etichetei europene este
 atribuit producatorului pe o perioada de 3 ani, urmand ca, dupa expirarea lor, acesta sa isi reinnoiasca cererea. Pentru
 a permite distingerea ambalajelor reutilizabile de cele reciclabile si, pentru a face cunoscuta natura ambalajului sau a
 materialului de ambalare utilizat, s-a convenit, incepand cu 1994, folosirea unei marcari specifice pe ambalajul respectiv.
 Aceasta marcare consta in numerotarea de la 1 la 79 a materialelor de ambalare, astfel: de la 1-19 pentru materiale plastice;
 20-39 pentru hartie, carton; 40-49 pentru metal; 50-59 pentru lemn; 60-69 pentru textile; 70-79 pentru sticla. Se pot utiliza
 si abrevieri de tipul PP-polipropilena.</p>

<p style="text-indent:2em;"></p><h6>Pentru produsele agricole si alimentare, care sunt considerate produse ecologice, s-a emis Reglementarea nr.2092/1991 privind
 atribuirea etichetelor ecologice produselor, aplicabila in toate tarile membre ale UE. Documentul se refera la produsele vegetale
 procesate si neprocesate, produsele animale si are la baza cerintele Federatiei Ecologice a Producatorilor Agricoli din Germania
 (AGTN) si principiile ecologice dupa care sunt realizate produsele ecologice:</h6><p></p>

<p>interdictia de a folosi ingrasaminte chimice, hormonale sau alte substante cu rol de accelerare 
a cresterii animalelor;</p><br>

<p>protejarea solului prin folosirea numai a ingrasamintelor naturale;</p><br>

<p>introducerea unor reguli eficiente igienico-sanitare si de ingrijire medicala, pe parcursul
 cresterii animalelor.</p><br>

<p style="text-indent:2em;">Reglementarea nr.2092/1991 stabileste conditia ca un produs sa poarte denumirea de un produs organic, si anume: 95% din ingredientele sale agricole
 trebuie sa se conformeze cerintelor acestei reglementari. Acest lucru se inscrie pe eticheta produsului, folosindu-se textul “x% din ingredientele de
 origine agricola au fost produse in conformitate cu principiile produselor agricole”.</p><br>

<p style="text-indent:2em;">In alte cazuri, mentiunea de “ecologic” sau mentiuni similare, pot fi folosite numai in lista ingredientelor, nu si in denumire. Incepand cu 1
 ianuarie 1993 se introduce notiunea de “produs biologic” – acel aliment al carui mod de fabricatie corespunde regulilor comunitare pentru productia
 agricola biologica, garantata prin inspectii ale autoritatilor nationale.</p>

<p style="text-indent:2em;"></p><h6>Denumirea de “produs biologic” sau “produs al agriculturii biologice” presupune indeplinirea urmatoarelor conditii:</h6><p></p>

<p>nici un element chimic de sinteza nu a fost utilizat in fabricarea produsului;</p><br>

<p>utilizarea de ingrasaminte si pesticide chimice este strict limitata;</p><br>

<p style="text-indent:2em;">eticheta produsului trebuie sa contina indicatia referitoare la productia biologica si atestarea faptului ca
 produsul este supus proceselor de inspectie comunitara.</p><br>

<p style="text-indent:2em;">Aceste etichete au rolul de a apara producatorii agricoli din sistemul “ECO” impotriva concurentei, stiind ca produsele ecologice sunt cu
 30% mai scumpe decat restul produselor, datorita productivitatii mai scazute din agricultura ecologica.</p>

<p style="text-indent:2em;"></p><h6>Productia agroalimentara ecololgica trebuie sa respecte conditiile impuse de urmatoarele reglementari:</h6><p></p>

<p>Reglementarea CE nr.2092/1991 si amendamentele la aceasta;</p><br>

<p>Reglementarea CE 1804/1999;</p><br>

<p>Reglementarile Federatiei Internationale a Miscarilor pentru Agricultura Organica (IFOAM);</p><br>

<p style="text-indent:2em;">OUG privind produsele agro-alimentare ecologice nr.34/17.04.2000 (aprobata prin L.38/07.03.2001) – face
 referire la conceptul de “productie ecologica”.</p>

<p style="text-indent:2em;"></p><h6>Informarea consumatorului cu privire la produsele de origine vegetala si animala, preparate cu ingrediente
 de origine vegetala si/sau animala, obtinute prin aplicarea metodelor de productie ecologica, trebuie sa se
 faca foarte clar, inscriindu-se pe eticheta produselor ecologice urmatoarele elemente:</h6><p></p>

<p>numele si adresa producatorului sau ale prelucratorului;</p><br>

<p>denumirea produsului si metoda de productie ecologica utilizata;</p><br>

<p>numele si marca organismului de inspectie si certificare (Autoritatea Nationala a Produselor Ecologice – ANPE);</p><br>

<p>termenul minim de valabilitate;</p><br>

<p>conditiile de pastrare;</p><br>

<p>conditii de interdictie ale pastrarii produselor ecologice alaturi de alte produse;</p><br>

<p style="text-indent:2em;">sigla specifica, emisa si aplicata pe baza sistemului de certificare, prin care se atesta ca produsul
 indeplineste conditiile productiei ecologice.</p>

<p style="text-indent:2em;"></p><h6>Produsele, insotite de mentiunea “Agricultura Ecologica Ecorom-Sistem de control RO”, au fost supuse 
sistemului de inspectie si certificare, indeplinind urm. conditii:</h6><p></p>

<p>sunt obtinute prin metode de productie ecologica si sunt etichetate corespunzator;</p><br>

<p>au facut obiectul inspectiei pe tot parcursul ciclului de productie-preparare si
 comercializare;</p><br>

<p style="text-indent:2em;">au fost preparate/produse de operatori din sistemul agro-alimentar, supusi controlului de catre organismele
 de inspectie si certificare-acreditare;</p><br>

<p>produsele sunt ambalate si transportate la punctul de comercializare in ambalaje inchise.</p>

<p style="text-indent:2em;"></p><h6>Marcajele din fig.11.6 se pot aplica pe ambalajele de bauturi, fara alte explicatii semnificative,
 in urmatoarele variante:</h6><p></p> 
 
<p style="text-indent:2em;">a)“produs eco-taxat”, b)“produs fara eco-taxa”, c)“produs inregistrat si scutit de eco-taxa”. Pe unele produse apare marca “Info-mediu”,
 care atentioneaza publicul asupra unei portiuni din ambalaj, in care sunt prezentate caracteristicile de mediu ale produsului ambalat 
 (poate fi aplicat atat de producator, cat si de distribuitor). Marca “Ozone friendly” semnifica faptul ca produsul nu contine C.F.C.
 (gaz utilizat ca propulsor, despre care se considera ca distruge patura de ozon).</p><br>

<p style="font-weight:500;">Din anul 1995 s-a interzis utilizarea CFC in aerosoli (cu exceptia catorva aerosoli medicali).</p><br>

<p style="text-indent:2em;">Organizatia Internationala  de Standardizare (ISO) a emis standardele ISO 14.000 referitoare la marcarea ecologica
 a produselor si serviciilor, prin aplicarea carora se realizeaza evaluarea caracteristicilor ecologice ale produselor
 si informarea consumatorilor asupra caracteristicilor respective.</p>

<p style="text-indent:2em;"></p><h6>Conform acestor standarde, pot fi aplicate 3 tipuri de marcare ecologica:</h6><p></p>

<p style="text-indent:2em;">marcarea ecologica tip I, realizata de o terta parte (organism guvernamental
sau neguvernamental), prin care se stabilesc si criteriile de evaluare;</p><br>

<p style="text-indent:2em;">marcarea ecologica tip II, efectuata pe baza declaratiei pe propria raspundere
a producatorului, importatorului, distribuitorului sau altor parti interesate, realizata prin simboluri grafice
 mentionate pe ambalaj sau pe produs, ori un inscris care sa insoteasca produsul;</p><br>

<p>marcarea ecologica tip III, ce contine informatii cuantificabile despre produs,
realizate pe baza unor indici predeterminati.</p>

<p style="text-indent:2em;"></p><h6>Toate aceste tipuri de marcare ecologica au drept scop urmatoarele deziderate:</h6><p></p>

<p style="text-indent:2em;">evaluarea caracteristicilor produsului luand in considerare efectele posibile asupra mediului, in fiecare
 etapa a ciclului de viata;</p><br>

<p>utilizarea legislatiei in vigoare referitoare la metodele de testare a produselor autohtone si a celor de import;</p><br>

<p>asigurarea transparentei necesare privind caracteristicile produselor.</p><br>

<p style="text-indent:2em;">Standardele ISO 14.021 / 1999 – “Declaratii si etichetare de mediu” se refera la problema ambalajelor si a
 declaratiilor de mediu, aplicate voluntar pe produse, introducand obligativitatea prin care declaratiile de mediu
 nu trebuie sa induca in eroare consumatorii, prezentand informatii clare, precise si verificabile.</p><br>

<p style="text-indent:2em;">In anul 1990, 95 de societati comerciale din Germania au fondat compania “Duales System” (Sistemul Dual) care opereaza
 in paralel cu reteaua publica de colectare si triere a ambalajelor uzate. Sistemul Dual are ca scop finantarea si 
 colectarea ambalajelor, (inclusive a celor provenite din import), cu integrarea consumatorului in procedura de integrare
 stabilita de industrie. Pentru a fi acceptate in cadrul sistemului de colectare DS, ambalajele respective vor trebui sa 
 poarte, in mod obligatoriu, marca DER GRÜNE PUNKT (Punctul verde). Atribuirea”punctului verde” de catre “DS” se efectueaza
 in baza unui contract incheiat cu utilizatorul, prin care se impune o descriere detaliata a ambalajului (inclusiv compozitia),
 o declaratie exacta a datei limita de consum a produsului, o prezentare a volumului vanzarilor, greutatea ambalajului si tipul
 de material utilizat. Costul marcii depinde de toate aceste elemente si se regaseste in pretul de vanzare al marfii.</p><br>

<p style="text-indent:2em;">Peste 18.500 de companii (autorizate) platesc taxe de licenta pentru utilizarea “punctului verde”, 
finantand, astfel, colectarea, sortarea si reciclarea separata a ambalajelor.</p><br>

<p style="text-indent:2em;">Prin intermediul “Punctului verde” se intentioneaza promovarea managementului ecologic si
  managementului resurselor economice.</p><br>

<p style="font-weight:500;">Functiile marcilor</p><br>

<p style="font-weight:500;">1.Functia comerciala</p><br>

<p style="text-indent:2em;">Prestatorul de servicii (organizatia) va putea fi cunoscut printre consumatorii de marca, ce se manifesta ca un simbol
 viu al intregii activitati.In luarea deciziei de cumparare a unui produs, marca ofera cumparatorului un reper important,
 devenind astfel, pentru organizatie, un element essential al strategiei tehnologice si comerciale. Prin urmare, produsul
 este ceea ce organizatia produce, iar marca este ceea ce clientul cumpara.</p><br>

<p style="font-weight:500;">2.Functia de comunicare</p><br>

<p style="text-indent:2em;">Efectul produs de marca este unul publicitar, fiind utilizate diferite mijloace de publicitate (afise, postere, pliante,
 reviste, videoclipuri publicitare la radio si TV), pentru care se cheltuiesc sume mari in ce priveste produsele oferite spre
 valorificare. Astfel, se incearca creerea unui efect psihologic asupra cumparatorilor si un mit legat de o anumita marca.
 Informatiile cu privire la caracteristicile de calitate ale produselor, nu trebuie sa induca in eroare cumparatorii si sa
 fie corecte, intrucat, o publicitate neloiala poate prejudicial organizatiile si marcile lor.</p><br>

<p style="font-weight:500;">3.Functia de diferentiere</p><br>

<p style="text-indent:2em;">Indiferent de categoria careia ii apartine: de organizatie comerciala, de produs sau servicii, simpla sau complexa,
 figurativa sau sonora, individuala sau colectiva, marca este utilizata pentru diferentierea produselor sau serviciilor.</p><br>

<p style="text-indent:2em;">Marcile de produs contin anumite caracteristici ale calitatii ce fac diferentierea intre produse diferite ale ale aceleiasi
 organizatii (de ex. , marcile bauturilor racoritoare Coca-Cola, Fanta, Sprite ale firmei Coca-Cola se deosebesc intre ele,
 dar, difera fata de alte marci de bauturi racoritoare ca American Cola, Frutti fresh, produse de firma European Drinks).</p><br>

<p style="text-indent:2em;">In majoritatea cazurilor, marfurile sunt comparate de catre consumatori numai dupa marca, potrivit caracteristicilor de
 calitate ale produselor, neinteresandu-i firmele ce le fabrica.</p><br>

<p style="font-weight:500;">4.Functia de garantare a calitatii</p><br>

<p style="text-indent:2em;">Rolul marcii il constituie simbolul garantiei si calitatii, mai ales, daca sunt desemnate produse de varf ca realizare
 tehnologica. Nerespectarea acestor calitati este sanctionata de comparator prin abandonarea produsului.</p><br>

<p style="font-weight:500;">5.Functia de a indica originea sau provenienta produselor</p><br>

<p style="text-indent:2em;">Notiunea de indicatie geografica a fost introdusa dupa 1990, ca urmare a negocierilor internationale, 
servind la identificarea produsului originar al unei tari, regiuni sau localitati dintr-un stat , daca acea 
zona este caracterizata prin calitatea unui produs.</p><br>

<p style="text-indent:2em;">In special, aceste marci sunt folosite pentru produsele agroalimentare (branzeturi, cascavaluri, bauturi 
alcoolice, cafea, etc.).</p><br>

<p style="font-weight:500;">6.Functia de reclama</p><br>

<p style="text-indent:2em;">Este determinata de rolul marcii in personalizarea unui produs sau serviciu, combinatia de semne si culori,
 eleganta si distinctia oferita produsului.</p><br>

<p style="text-indent:2em;">La elaborarea marcii, pentru a indeplini aceasta functie, se apeleaza la specialisti, lingvisti, experti in
 comunicare, psihologi ce creeaza, testeaza si interpreteaza impactul si efectele muncii noi create.</p><br>

<p style="text-indent:2em;">Se stie ca vanzarile cresc, prin intermediul marcii, datorita publicitatii si reclamei, stimulandu-se, astfel,
 fabricarea de serie mare, diversificarea gamei de produse, ce duc la reducerea pretului de cost.</p><br>

<p style="text-indent:2em;">Daca cele sase functii ale marcii sunt percepute de consumatori, se considera ca suntem in prezenta unei marci
 de notorietate (complete). In caz contrar, este vorba despre marca superficiala.</p><br>

<p style="font-weight:500;">Caracteristicile marcii</p>

<p style="text-indent:2em;"></p><h6>Pentru a fi competitiva, o marca trebuie sa raspunda urm. cerinte:</h6><p></p>

<p>sa aiba o semnificatie legata de produs sau de activitatea pe care o reprezinta;</p><br>

<p>sa aiba o grafica usor de descifrat, moderna;</p><br>

<p>sa fie concise si usor de inteles, in cadrul marcilor compuse din mai multe cuvinte;</p><br>

<p>sa fie usor de pronuntat si de memorat;</p><br>

<p>sa aiba un caracter distinctiv, pentru a nu fi confundata cu alte marci;</p><br>

<p>sa poata fi utilizata pe plan international;</p><br>

<p style="text-indent:2em;">sa nu reprezinte steme, drapele, embleme de stat, sigilii oficiale, blazoane deja existente ( de ex.
 emblema UE nu se foloseste ca marca);</p><br>

<p>sa nu induca in eroare consumatorul asupra provenientei geografice sau a calitatii produselor.</p><br>

<p style="font-weight:500;">Codificarea marfurilor</p><br>

<p style="text-indent:2em;">Codul se defineste (cf. Dictionarului de Merceologie) ca fiind “o combinatie de elemente simbolice prin
 care se reprezinta o informatie”. Aceste elemnte pot fi litere (cod alfabetic), cifre (cod numeric) sau
 litere si cifre (cod alfa numeric).</p><br>

<p style="text-indent:2em;">Codificarea este definite ca fiind: “operatiunea de transpunere in cod a elementelor definitorii ale unei
 multimi de obiecte, servicii, fenomene, etc.”. Alaturi de marcare, codificarea reprezinta un instrument 
 important de promovare a marfurilor pe piata, prin evidentierea si regasirea automata in sistem informatizat
 a acestora.</p><br>

<p>Principalul obiectiv al codificarii, care determina si functia sa de baza, este identificarea elementelor
 unei multimi date.</p><br>

<p style="text-indent:2em;">O alta functie importanta a codificarii este functia de reprezentare a clasificarii elementelor, in cazul
 in care se preiau si semnificatii ale relatiilor existente intre elementele unei multimi date.</p><br>

<p style="font-weight:500;">Clasificarea codificarilor</p>

<p style="text-indent:2em;"></p><h6>Legislatia in vigoare propune 3 grupe de clasificari:</h6><p></p>

<p style="font-weight:500;">1. Clasificari sistematice (neflexibile)- utilizate in practica economica.</p><br>

<p style="text-indent:2em;">In cazul acestor clasificari, codificarea este dependenta de ordonarea multimii produselor pe categorii,
 suprapunandu-se functia de identificare a codului cu functia sa de reprezentare a clasificarii. Cea mai
 mare parte a clasificarilor sistematice elaborate sunt clasificari ierarhice, pe trepte (niveluri) de detaliere
 (agregare),intre care exista relatii de subordonare, adica, treptele superioare se obtin prin agregarea celor
 inferioare, derivate din ele. Gruparile (categoriile) de produse corespunzatoare acestor trepte se numesc,
 in mod diferentiat: clase, grupe, subgrupe, sectiuni, diviziuni, etc. Continutul acestor categorii difera
 semnificativ pentru acelasi nivel de agregare, de la o clasificare la alta, pentru aceleeasi produse. In
 merceologie, o clasificare sistematica are la baza clase (familii) de marfuri, grupe, subgrupe, articole
 si sortimente.</p>

<p style="text-indent:2em;"></p><h6>Clasa (familia):</h6><p></p> 

<p>cuprinde un grup de marfuri care au aceeasi origine si prezinta caracteristici similare;</p>

<p style="text-indent:2em;"></p><h6>Grupa:</h6><p></p> 

<p>reprezinta o selectie dintr-o clasa de marfuri cu aceeasi destinatie si tehnologie, avand caracteristici
 similare;</p>

<p style="text-indent:2em;"></p><h6>Subgrupa:</h6><p></p>

<p>este o diviziune a grupei in functie de material prima;</p>

<p style="text-indent:2em;"></p><h6>Articolul:</h6><p></p>

<p>este o unitate specifica; articolele se deosebesc intre ele prin mai multe
 proprietati lor;</p>

<p style="text-indent:2em;"></p><h6>Sortimentul:</h6><p></p>

<p>este o subdiviziune a articolului; sortimentele se deosebesc intre ele printr-o
 singura proprietate</p><br>

<p style="text-indent:2em;">Sistemul de codificare sistematica este eficient numai daca exista o structura unitara a criteriilor 
de clasificare si se stabileste un numar optim de nivele de detaliere, pentru a nu se ajunge la un cod 
de lungime prea mare. Avantajul acestor clasificari este ca se da posibilitatea de prelucrare electronica
 a incadrarii produsului, cu aplicabilitate universala.</p><br>    

<p style="text-indent:2em;">In practica economica, pentru delimitarea categoriilor corespunzatoare tteptelor superioare ale acestor
 clasificari, se utilizeaza, de regula, criterii generale, cum sunt: ramura industriala (ind.alimentara,
 ind.textila, ind.electronica, etc), gradul de prelucrare, natura materiilor prime, destinatia procesului
 tehnologic, etc. Pe treptele terminale se utilizeaza o mare diversitate de criterii de clasificare, unele
 dintre acestea reprezentand caracteristici de calitate, specifice diferitelor categorii de marfuri.</p><br>

<p style="font-weight:500;">2) Clasificarile nesistematice, sunt acele clasificari in care produsele se identifica, de regula,
 printr-un cod secvential, acordat in ordinea numerelor naturale.</p><br>

<p style="text-indent:2em;">Solutia prezinta avantajul unui cod de lungime mica, datorita utilizarii tuturor combinatiilor posibile 
ale caracterelor numerice; dezavantajul consta in faptul ca reprezentarea codificata nu poate sugera nici 
o informatie privind categoria din care face parte produsul (de ex., la nivel national este utilizata 
Clasificarea Unitara a Produselor si Serviciilor din Romania-CUPS).</p><br>

<p style="font-weight:500;">3)Clasificarile combinate folosesc un sistem de codificare ierarhizat-secvential, codul avand o zona de ordonare corespunzatoare clasificarii ierarhice, urmata de
 o zona secventiala.</p><br>
 
 <p style="text-indent:2em;">Aceste clasificari asigura ordonarea produselor pe un anumit numar de categorii omogene
 in cadrul carora se realizeaza, in continuare, o clasificare nesistematica a elementelor componente. Deci,
 in clasificarile combinate, functia de identificare a codului este combinata partial si cu functia de 
 reprezentare a clasificarii. Lungimea codului este cu atat mai mare cu cat clasificarea cuprinde un numar 
 mai mare de niveluri de detaliere. In aceasta varianta se urmareste gasirea unui raport optim intre zona de
 clasificare si zona secventiala a codului.</p><br>

<p style="font-weight:500;">Sisteme de codificare a marfurilor utilizate la nivel microeconomic</p>

<p style="text-indent:2em;"></p><h6>Clasificarile nesistematice si combinate au capatat o larga utilikzare la nivel microeconomic, in multe tari
 urmarindu-se, in principal, rezolvarea eficienta a codificarii marfurilor, potrivit cerintelor sistemelor informatice
 proprii ale intreprinderilor. In conditiile proliferarii unei mari diversitati de clasificari de acest fel, a devenit
 necesara gasirea unor solutii de uniformizare a lor, pe piata internationala, obiectiv atins prin elaborarea urmatoarelor
 sisteme:</h6><p></p>

<p>codul universal al produselor” (Universal Product Code UPC);</p><br>

<p>codul european al articolelor” (European Article Numbering-EAN).</p><br>

<p style="font-weight:500;">Atat sistemul UPC, cat si sistemul EAN utilizeaza codul cu bare.</p><br>

<p style="font-weight:500;">Codul cu bare. Elemente de definire.</p><br>

<p style="text-indent:2em;">Codul cu bare este o modalitate de reprezentare grafica a caracterelor numerice sau alfa numerice, prin
 alterarea unor bare de culoare inchisa, cu spatii albe de dimensiuni definite. Tehnologia codului cu bare
 se bazeaza pe recunoasterea acestor combinatii de bare si spatii, prin utilizarea unor echipamente informatice
 specializate. Tehnologia de aplicare a codului cu bare s-a dovedit de mare interes atat in productia bunurilor 
 de larg consum, cat si in comertul cu amanuntul, in prezent, reusind sa cuprinda aproape toate domeniile din 
 activitatea economica. Aceasta tehnologie a evoluat spectaculos, astfel incat, de la procedeul electrostatic de 
 citire a codurilor, simbolizate prin purtatorii de date cu cartele sau taloane perforate, s-a ajuns la lectura 
 magnetica si, in final, la metoda citirii optice, care sta la baza sistemelor perfectionate de astazi, 
 de culegere a informatiilor cuprinse in coduri.</p><br>

<p style="font-weight:500;">Tipuri de coduri cu bare</p>

<p style="text-indent:2em;"></p><h6>In functie de sistemul de codificare al informatiilor, exista 2 categorii de coduri de bare:</h6><p></p>

<p style="text-indent:2em;"></p><h6>Coduri liniare:</h6><p></p> 

<p>in care informatia este codificata pe o singura directie, de regula, pe orizontala;</p>

<p style="text-indent:2em;"></p><h6>Coduri bidimensionale:</h6><p></p> 

<p>in care informatia este codificata atat pe orizontala, cat si</p><br>

<p style="text-indent:2em;">pe verticala; codurile bidimensionale au o capacitate mare de cuprindere, comparativ cu cele liniare,
 cea mai performanta varianta fiind varianta matriceala. In practica economica, codurile liniare sunt mult
 mai raspandite decat cele bidimensionale (SUA utilizeaza codul bidimensional, denumit Maxi Code).</p>

<p style="text-indent:2em;"></p><h6>Codurile cu bare liniare sunt cele mai raspandite, fiind prezente pe etichetele ambalajelor individuale,
 secundare si tertiare (ambalajele de transport). In functie de caracteristicile sirului de caractere,
 pe care il codifica, exista mai multe tipuri de coduri liniare:</h6><p></p>

<p>coduri cu bare numerice, care pot reprezenta numai cifre (de ex. codurile cu EAN si UPC);</p><br>

<p>coduri alfa numerice, care pot reprezenta atat cifre, cat si litere (de ex. codul 39 si codul 128).</p>

<p style="text-indent:2em;"></p><h6>Indiferent de tipul lor, codurile cu bare liniare au aceeasi structura generala, caracterizata
 prin urmatoarele elemente:</h6><p></p>

<p style="text-indent:2em;"></p><h6>dimensiunea (modulul):</h6><p></p>

<p>reprezinta latimea barei sau a spatiului celui mai</p><br>

<p style="text-indent:2em;">ingust; este o caracteristica foarte importanta, de care depinde lungimea codului, siguranta de
 citire si, uneori, inaltimea codului;</p>

<p style="text-indent:2em;"></p><h6>zona libera (zona de liniste):</h6><p></p>

<p style="text-indent:2em;">este o zona de margine neimprimata, plasata la inceputul si la sfarsitul codului de bare care asigura o citire corecta prin evitarea erorilor datorate
 unor semne grafice aflate in vecinatatea codului; zona libera are o latime de minimum 10 ori dimensiunea
 codului;</p>

<p style="text-indent:2em;"></p><h6>elemente de start si de stop:</h6><p></p>

<p>sunt combinatii specifice de bare si spatii cu care</p><br>

<p style="text-indent:2em;">incepe, respectiv, se termina, codul cu bare. Aceste elemente permit echipamentului de citire-de codare
 sa identifice inceputul si sfarsitul codului, precum si sensul de citire;</p>

<p style="text-indent:2em;"></p><h6>codul in clar:</h6><p></p>

<p>reprezinta coresp</p><br>

<p style="text-indent:2em;">Elementele specifice functionarii sistemului de codificare cu codul de bare, constau in modalitatea de 
reprezentare grafica a literelor si cifrelor dintr-un sir numeric sau alfa numeric, metoda de imprimare,
 echipamentul de imprimare, cititorul optic si decodorul utilizat. Metoda de imprimare a codului cu bare
 se alege in functie de destinatia si tipul produsului, de gradul de informatizare al firmei si trebuie 
 sa permita citirea in diferite spatii si, totodata, mentinerea caracteristicilor codurilor.</p>

<p style="text-indent:2em;"></p><h6>Pentru a preveni distrugerea codului se acorda o importanta deosebita urmatoarelor aspecte: tehnologia de
 imprimare, cerneala si culorile utilizate, contrastul intre fond si ixes , materialul din care este confectionata
 eticheta, adezivul utilizat, pozitia etichetei pe ambalaj. Codul cu bare liniare poate fi aplicat direct pe ambalaj
 sau pe etichete preimprimante de catre producator sau ambalator sau, in alte situatii, imprimarea se face odata cu
 textul, marca si grafica ambalajului. Prin reglementarile actuale, in vederea imprimarii codului cu bare, etichetele
 trebuie sa indeplineasca urm. conditii:</h6><p></p>

<p>sa permita o aplicare rapida si comoda, care ixes lase urme sau zgarieturi pe suprafata ambalajului;</p><br>

<p>pentru a impiedica falsificarile, eticheta ixes poata fi reconstituita dupa desprindere;</p><br>

<p>adezivul etichetei sa permita aderenta la suprafata diferitelor materiale de ambalare;</p><br>

<p style="text-indent:2em;">materialul din care este confectionata eticheta, precum si cerneala de imprimare, sa reziste la actiunea
 factorilor de mediu.</p>

<p style="text-indent:2em;"></p><h6>Sistemul de citire a codurilor cu bare este reprezentat de un echipament electrooptic, care permite luminarea
 simbolului, masurarea parametrilor luminii reflectate si transformarea informatiilor in semnale care pot fi prelucrate
 de decodor. Cele mai utilizate sisteme de citire optica sunt:</h6><p></p>

<p style="text-indent:2em;"></p><h6>citirea de contact:</h6><p></p>

<p style="text-indent:2em;">presupune o distanta foarte mica intre cititorul optic si ixes , utilizandu-se creionul optic
 deplasat de operator de-a lungul codului ;</p>

<p style="text-indent:2em;"></p><h6>citirea fara contact:</h6><p></p> 

<p style="text-indent:2em;">permite citirea etichetelor reflectorizante de la aproximativ 10 metri distanta, cu ajutorul
 cititoarelor cu laser (scanner) ixes au mobile; scannerele fixe sunt conectate la un system de calcul (calculator,
 casa electronica de marcat) in punctele de vanzare.</p><br>

<p style="font-weight:500;">Codul universal al produselor (UPC)</p><br>

<p style="text-indent:2em;">Acest cod a fost infiintat in SUA de catre Consiliul pentru codificarea produselor in 1972. Sistemul UPC se bazeaza
 pe un cod cu 12 caractere numerice: prima cifra reprezinta o cheie a clasificarii (“Key number”) proprie UPC, urmatoarele
 5 cifre identifica producatorul, apoi, alte 5 cifre identifica produsul si ultima este cifra de control.</p><br>

<p>In prezent, cca.95% dintre produsele comercializate in SUA sunt codificate prin sistemul UPC.</p><br>

<p style="font-weight:500;">Codul european al articolelor (EAN)</p><br>

<p style="text-indent:2em;">Sistemul EAN este coordonat de “Asociatia europeana a codificarii articolelor” cu sediul la Bruxelles. Acest sistem
 se bazeaza pe un cod cu 13 caractere numerice, cu urmatoarea semnificatie: primele doua reprezinta tara de origine
 (de ex.</p><br>

<p style="text-indent:2em;">Belgia-54, Franta-30-37, Germania-40-42, Anglia-50, Japonia-49, etc.); urmatoarele 5 cifre identifica producatorul,
 alte 5 cifre identifica produsul, iar ultima este cifra de control.</p><br>

<p style="text-indent:2em;">Din anul 1981 functioneaza “Asociatia internationala a codificarii articolelor (PAN)”, care urmareste respectarea 
unor principii de baza in vederea asigurarii compatibilitatii sistemelor nationale de codificare ale produselor. 
Utilizand un cod nesemnificativ, usor de manipulare, sistemul EAN are o capacitate de cuprindere pentru un numar foarte 
mare de produse (cca.10 miliarde). Sistemul EAN a fost preluat de un numar foarte mare de tari de pe toate continentele, 
utilizarea lui prezentand o serie de avantaje, atat pentru producatori, cat si pentru comercianti, prin facilitarea urmaririi 
si gestionarii rapide a produselor, pe parcursul lantului de desfacere. Astfel, producatorii se pot informa rapid in legatura cu 
modificarile calitative, ce pot apare in distributia produselor, avand ca avantaj largirea sau restrangerea gamei sortimentale sau 
retragerea produselor greu vandabile.</p><br>

<p style="text-indent:2em;">Comerciantii sunt avantajati de sistemul EAN, deoarece se pot gestiona, mai eficient, stocurile de produse si se poate face 
reinnoirea operativa a stocurilor. In sistemul de desfacere al marfurilor, prin utilizarea caselor de marcat electronice (prevazute 
cu dispozitiv de citire optica a codului cu bare EAN), se asigura o inregistrare operativa, fara erori a datelor referitoare la 
produsele vandute. Utilizandu-se calculatoare interconectate la furnizor, se pot realiza comenzi automate ale produselor si se 
pot urmari rezultatele actiunilor promotionale. Transpunerea unui cod din clasificarea EAN 13 in sistemul de cod cu bare este 
prezentata in fig. 11.14.</p><br>

<p style="text-indent:2em;">Cumparatorii sunt avantajati de utilizarea sistemului EAN, deoarece se reduce foarte mult timpul de asteptare la casa; prin 
citirea automata a codurilor, pe bonul eliberat clientului sunt trecute in mod clar denumirea exacta si pretul pentru fiecare 
produs achizitionat, fiind exclusa posibilitatea aparitiei unei erori. Utilizarea sistemului EAN s-a extins in domeniul 
activitatilor administrative, bancare, codificarea publicatiilor, etc.</p><br>

<p style="text-indent:2em;">In Romania, problema codificarii produselor romanesti este abordata in contextual cerintelor de aliniere la standardele europene. 
Codificarea EAN a produselor sau articolelor, presupune o codificare la producator si o codificare interna la comerciant.</p>

<p style="text-indent:2em;"></p><h6>La producator se utilizeaza doua versiuni pentru aplicarea sistemului EAN:</h6><p></p>

<p>versiunea generala care rezerva 9 pozitii numerice pentru identificarea produsului;</p>

<p style="text-indent:2em;"></p><h6>versiunea scurta care rezerva 4 cifre, astfel incat codul va avea doar 8 caractere numerice si nu 13, ca in primul caz. Versiunea scurta (EAN 8) se foloseste pentru produsele de mici dimensiuni, la care 
ambalajul nu prezinta un spatiu sufficient pentru a se utilize versiunea EAN 13. In versiunea EAN 13, codificarea produselor 
se face sub forma unor spatii albe si negre insotite de sirul de litere sau cifre si litere, avand urmatoarea 
semnificatie:</h6><p></p>

<p>prima grupa identifica tara de origine (Romania are indicativul 594,</p><br>

<p>atribuit de Asociatia internationala de numerotare a articolelor – EAN);</p>

<p style="text-indent:2em;"></p><h6>a doua grupa de 9 cifre identifica produsul si este structurat in felul urmator:</h6><p></p>

<p>Codul National al Producatorului (CNP) atribuit de EAN Romania si alcatuit din 4 cifre;</p><br>

<p>Codul Interfata al Produsului (CIP) alcatuit din 5 cifre;</p><br>

<p>a treia grupa reprezinta cifra de control, calculate in functie de cele 12 cifre precedente.</p><br>

<p style="text-indent:2em;">Codul EAN 13 trebuie sa fie unic pentru fiecare articol si varianta de articol, comercializat in orice stat al lumii. 
Elementul care asigura unicitatea articolului este prefixul EAN – UCC (Uniform Code Council) format din grupele 1 si 2.</p><br>

<p style="text-indent:2em;">Sub licenta EAN international, in Romania, s-a stabilit structura codului numeric de identificare 
a produselor (gestionat de EAN Romania).</p><br>

<p style="text-indent:2em;">Asociatia Romana pentru Containetizare si Paletizare (ARCP) asigura unicitatea codurilor prin acordarea unei specificatii
 care are ca prefix codul international al Romaniei (stability conform STAS 12208/1990). Pentru ambalajele individuale se
 aplica simbolistica EAN 13, intr-un spatiu special destinat acestui cod, fara includerea altor informatii destinate consumatorului.
 Pe ambalajele collective (pachete, baxuri, cutii, etc.) se ataseaza o eticheta pe care este mentionata cifra zero, inaintea sirului 
 de 13 cifre ale codului si se face transpunerea in cod cu bare, folosind simbolistica “Cod 39”.</p><br>

<p style="text-indent:2em;">“Codul 39” este un cod de simbolizare alfa numerica, discontinuu in care fiecare caracter este alcatuit din 9 elemente (5 bare si 4
 spatii), iar cifra de control este facultativa. Sistemul de codificare EAN, pentru produsele aflate in circulatie, poate utiliza una
 din cele doua versiuni (generala sau scurta), existand o diferenta intre articolele vandute la bucata si articolele cu greutate variabila
 (preparate din carne, preparate din peste, branzeturi, carne de vita, porc, oaie, legume si fructe prospete, preambalate, s.a), vandute
 en-gros si en-detaile.</p><br>

<p style="text-indent:2em;">Codificarea produselor cu greutate variabila trebuie sa respecte reglementarile EAN Romania, prin care se aloca fiecarei categorii
 de produs un prefix si un cod national de produs. Pe eticheta acestor produse, trebuie sa se specifice masa neta (in kilograme) cu
 trei zecimale si cifra de control. Produselor cu greutate variabila nu li se pot repartiza coduri decat in momentul livrarii,
 inscriptionandu-se in cod, fie greutatea pachetului, fie pretul lui (stabilit prin inmultirea greutatii cu pretul unitar). Prefixul
 codului difera in functie de codificarea greutatii sau a pretului. Astfel, EAN Romania recomanda, pentru codificarea legumelor si
 fructelor proaspete, utilizarea prefixului “29” avand mentionat pretul, si a prefixului “289”, pentru codificarea ce contine
 greutatea pachetului.</p><br>

<p style="font-weight:500;">Alte tipuri de coduri cu bare</p>

<p style="text-indent:2em;"></p><h6>Pe langa sistemele EAN si UPC, in practica economica se folosesc si alte tipuri de coduri cu bare, care permit codificarea 
informatiilor numerice sau alfa numerice si care pot fi grupate in sisteme de simbolizare continue si discontinue. De ex.:</h6><p></p>

<p style="text-indent:2em;">Codul 93 este un sistem de simbolizare continuu, in care fiecare caracter este reprezentant prin 9 module, repartizate pe 
3 bare si spatii. Codul are doua cifre de control (CK) care asigura obtinerea unei densitati mari de caractere. Acest cod 
permite reprezentarea celor 128 de caractere ale normei ASC II.</p><br>

<p>Codul 128 este un sistem de simbolizare continuu, utilizat pentru reprezentarea caracterelor ASC II.</p><br>

<p style="text-indent:2em;">Fiecare caracter este simbolizat printr-un ansamblu de 11 module repartizate pe 3 bare si 3 spatii. Codul are o cifra 
de control, asigurand obtinerea unei densitati mari de caractere.</p><br>

<p style="text-indent:2em;">Codul CODABAR reprezinta un sistem de simbolizare discontinuu, care permite reprezentarea a 16 caractere (10 caractere 
numerice si 6 caractere speciale).</p><br> 

<p>Fiecare caracter este simbolizat cu ajutorul a 7 elemente (4 bare si 3 spatii).</p><br>

<p style="text-indent:2em;">O varianta a codului de bare o reprezinta Codul CALRA, realizat de japonezi, descifrabil in sistem optic, mult mai ieftin 
decat sistemul EAN. Acest cod poate fi aplicat si pe etichete speciale, direct la locul de vanzare; este format din siruri 
de patrate, fiecare fiind divizat in alte 4 patrate, carora le corespunde un numar: 1,2,4 sau 8.</p><br>

<p style="text-indent:2em;">Prin innegrirea numerelor respective se obtin 10 cifre de la 0 la 9, la care se adauga inca 6 litere, de la a) la f), 
obtinandu-se mai multe semne decat la codurile liniare si, deci, un volum superior de informatii.</p><br>

<p style="text-indent:2em;">Un cod foarte performant este considerat Codul “DATA MATRIX”, deoarece raspunde cerintelor actuale de codificare a unui 
numar tot mai mare de informatii, intr-un spatiu cat mai restrans. Prin comparatie cu codul cu bare, care poate contine de 
la 8 la 22 de caractere numerice, intr-un spatiu de 25 mm, codul “DATA MATRIX” poate contine 500 de caractere intr-un spatiu 
de cca. 1,3 mm. Ca aspect, codul “DATA MATRIX” se aseamana cu o tabla de sah, fiind format din patrate minuscule, de aceeasi dimensiune, 
ce simplifica imprimarea si citirea lui. Aplicat direct pe ambalaj, el poate ocupa un spatiu cuprins intre 1 cm si cateva zecimi de cm, 
iar citirea lui se poate realiza sub un anumit unghi de rotatie cu ajutorul unei camere de luat vederi bidimensionale.</p><br>

<p><a href="http://www.creeaza.com/afaceri/comert/merceologie/AMBALAREA-ETICHETAREA-SI-MARCA428.php">Sursa</a></p>

</div>
`