import React from 'react'
import classnames from 'classnames'
import isFunction from 'lodash/isFunction'
import { Link } from 'react-router-dom'

import HeaderMenu from './header-menu'
import './styles.scss'

const STYLE_BASE = 'widget-header_'

export default class extends React.Component {
  static displayName = 'WidgetHeader'
  // componentDidMount() {
  //   // this.refreshItems(this.props.items)
  // }

  renderMenu = () => {
    const { menuRenderer, menuItems = [], onMenuSelected } = this.props
    if (isFunction(menuRenderer)) {
      return menuRenderer()
    }
    return <HeaderMenu items={menuItems} onSelected={onMenuSelected} />
  }

  render() {
    const { children, className, title = '', titleLink, onClick } = this.props

    return (
      <div
        className={classnames({
          'panel-header': true,
          [`${STYLE_BASE}wrapper`]: true,
          [className]: !!className,
        })}
        onClick={onClick}
      >
        <h4>{titleLink ? <Link to={titleLink}>{title}</Link> : title}</h4>
        {children || this.renderMenu()}
      </div>
    )
  }
}
