import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ListPage from './list-page'
import LegislationContent from './content'
import ArticlePage from './article-page'

export default () => {
  // render() {
  return (
    <Switch>
      <Route path="/useful/legislation" exact component={ListPage} />
      <Route path="/useful/legislation/:area/:key" exact component={LegislationContent} />
      <Route path="useful/legislation/:lang/:area/:article" component={ArticlePage} />
    </Switch>
  )
}
