import { connect } from 'react-redux'
import { compose } from 'recompose'

import Component from './component'
import { ISummaryTableProps } from './types'
import { IReduxStorage } from '../../../store/reducers/types'
import { getSummaryTable, changeSummaryFavorite } from '../../../store/actions/summary'
import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state: IReduxStorage) => ({
      summary: state.summary,
      isAuthenticated: !!state.auth.accessToken,
    }),
    {
      getSummaryTable,
      changeSummaryFavorite,
    }
  )
)(Component) as React.ComponentClass<ISummaryTableProps>
