import io from 'socket.io-client'
import window from 'global/window'

import config from './config'
import {
  SOCKET_GET_LOGIN_STATUS,
  SOCKET_NEW_LOGIN_ATTEMPT,
  SOCKET_LOGIN_ALLOWED,
  SOCKET_LOGIN_DENIED,
  SOCKET_GOT_NEW_CARGO_ENTRY,
  SOCKET_NEW_MESSAGE_ARRIVED,
  GET_CONVERSATION_LIST_ARRIVED,
  GET_CONVERSATION_LIST,
  SOCKET_LOGOUT_FROM_ALL_DEVICES
  // SOCKET_NEW_LOGIN_ATTEMPT_ALLOW,
  // SOCKET_NEW_LOGIN_ATTEMPT_DENY,
} from './store/actions/action-types'
import socketActions from './store/actions/socket'
import observedObject from './observedObject'

const ioEvents = [
  SOCKET_GET_LOGIN_STATUS,
  SOCKET_NEW_LOGIN_ATTEMPT,
  SOCKET_LOGIN_ALLOWED,
  SOCKET_LOGIN_DENIED,
  SOCKET_GOT_NEW_CARGO_ENTRY,
  SOCKET_NEW_MESSAGE_ARRIVED,
  GET_CONVERSATION_LIST_ARRIVED,
  GET_CONVERSATION_LIST,
  SOCKET_LOGOUT_FROM_ALL_DEVICES
  // SOCKET_NEW_LOGIN_ATTEMPT_ALLOW,
  // SOCKET_NEW_LOGIN_ATTEMPT_DENY,
]

let socketInited = false
if (!window.socket) {
  window.socket = io(config.ioUrl)
  window.socket.on('connect', () => observedObject.emit('socketInited'))
  // window.socket = io(config.ioUrl) //, { transports: ["websocket", "polling"], }) //, { transports: ['polling', 'websocket'] })
  // // , {
  // //     pingInterval: 3000,
  // //     pingTimeout: 10000,
  // //     transports: ["websocket", "polling"],
  // // })

}

export const initSocket = store => {
  ioEvents.forEach(event => {
    window.socket.on(event, payload => {
      socketActions[event] && store.dispatch(socketActions[event](payload))
    })
  })
  socketInited = true
}

export default store => next => {
  if (!socketInited) {
    initSocket(store)
  }
  
  return action => next(action)
}
