import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import Icon from 'react-icons-kit'

import {
  MENU_DROPDOWN,
  MENU_DROPDOWN_INLINE,
  MENU_CHECKBOX,
  MENU_CHECKBOXES,
  MENU_SWITCH,
} from '../../../../../store/constants'
import DropdownBase, { DropdownName, DropdownPanel } from '../../../../UI/dropdown-base'
import Dropdown from '../../../../UI/dropdown'
import Switch from '../../../../UI/switch'

import './styles.scss'

const STYLE_BASE = 'widget-header-menu_'

export default class extends Component {
  toggleDropdown = guid => {
    const newGuid = this.props.activeGuid === guid ? undefined : guid
    this.props.setActivePopup(newGuid)
  }

  closeDropdown = () => this.props.setActivePopup(undefined)

  renderDropdownItem = item => {
    const { type, text, value, checked, onClick } = item

    switch (type) {
      case MENU_CHECKBOX:
        return (
          <div
            className={classNames({
              'checkbox-row': type === MENU_CHECKBOX,
            })}
          >
            <input type="checkbox" name={name} value={checked} />
            <label htmlFor={name} onClick={() => onClick(!checked)}>
              {text}
            </label>
          </div>
        )
      default:
        return (
          <div
            className={classNames({
              [`${STYLE_BASE}item`]: true,
            })}
          >
            <label htmlFor={name} onClick={() => onClick(value)}>
              {text}
            </label>
          </div>
        )
    }
  }

  renderItemComponent = item => {
    const { className, activeGuid } = this.props
    const { icon, iconSize = 12, type, guid, text = '', dropdown, inline = true, onClick } = item

    const isExpanded = activeGuid === guid

    switch (type) {
      case MENU_SWITCH: {
        const { isOn } = item

        return (
          <Switch
            className={classNames({
              [`${STYLE_BASE}wrapper`]: true,
              'menu-item': inline,
              'has-text': inline && !!text,
              [className]: !!className,
            })}
            isOn={isOn}
            label={text + '--' + isOn}
            onClick={onClick}
          />
        )
      }
      case MENU_DROPDOWN: {
        const { items, title, subType } = dropdown || {}
        const label = items[0] && items[0].text

        return (
          <DropdownBase
            className={classNames({
              [`${STYLE_BASE}wrapper`]: true,
              'menu-item': inline,
              'has-dropdown': true,
              'has-text': inline && !!text,
              'is-opened': isExpanded,
              'chosen-container chosen-container-single chosen-container-single-nosearch dropdown_wrapper': !inline,
              [className]: !!className,
            })}
          >
            <DropdownName
              inline={inline}
              icon={icon}
              iconSize={iconSize}
              text={text}
              onClick={() => this.toggleDropdown(guid)}
            >
              <Link
                className={classNames({
                  'chosen-single': true,
                })}
                to="#"
                onClick={() => this.toggleDropdown(guid)}
              >
                <span>{label}</span>
                <div>
                  <b />
                </div>
              </Link>
            </DropdownName>

            <DropdownPanel>
              <div
                className={classNames({
                  dropdown: true,
                  'dropdown-checkbox': subType === MENU_CHECKBOXES,
                })}
              >
                {title && <h5>{`${title}:`}</h5>}
                {items.map((menuItem, j2) => (
                  <Fragment key={j2}>{this.renderDropdownItem(menuItem)}</Fragment>
                ))}
              </div>
            </DropdownPanel>
          </DropdownBase>
        )
      }
      case MENU_DROPDOWN_INLINE: {
        const { items, className } = dropdown || {}
        return (
          <Dropdown
            item={items}
            className={className}
          />
        )
      }
      default:
        return (
          <div
            className={classNames({
              'menu-item': true,
              'has-text': !!text,
              [className]: !!className,
            })}
            onClick={onClick}
          >
            <div>
              {icon && <Icon icon={icon} size={iconSize} />}
              {text && <span>{text}</span>}
            </div>
          </div>
        )
    }
  }

  render() {
    const { items, isAuthenticated } = this.props

    return items
      .filter(({ auth }) => !auth || (auth && isAuthenticated))
      .map((item, index) => <Fragment key={index}>{this.renderItemComponent(item)}</Fragment>)
  }
}
