import React, { Component } from 'react'
import { match } from 'react-router'
import { Field } from 'redux-form'
import Dropdown from '../../UI/dropdown'
import Widget from '../../shared/components/widget'
import Ads from '../../ads'
import SiteStat from '../../site-stat'
import BuySellShort from '../../buy-sell/short'
import classNames from 'classnames'
import ContentClaims from "./content-claims"
import ContentReviews from "./content-reviews"
import dict from '../../shared/dictionaries'

import "./styles.scss"

export interface IClaimsProps {
  t: any
  match: match<{ filterKey }>
}

export interface IClaimsState {
  activeTab: string
  itemsFilter: any
  pageSize,
  activeFilter: string
}

export default class Claims extends Component<IClaimsProps, IClaimsState> {

  constructor(props) {
    super(props)

    this.state = {
      activeTab: this.props.match.params.filterKey || "reviews",
      activeFilter: "allReviews",
      pageSize: 10,
      itemsFilter: [
        {
          id: "allReviews",
          name: "review.all",
        },
        {
          id: "usersReviews",
          name: "review.all.for.users",
        },
        {
          id: "companiesReviews",
          name: "review.all.for.companies",
        },
      ]
    }
  }

  renderSelectOptions = () => {
    const { t } = this.props
    const { itemsFilter } = this.state
    const newArr = []
    itemsFilter.forEach(({ id, name }) => {
      newArr.push({ id, title: t(name) })
    })

    const renderOptions = [
      ...newArr.map(({ id, title }) => ({
        value: id,
        name: title,
      })),
    ]

    return renderOptions
  }

  changeReviewsFilter = (activeFilter) => {
    this.setState({
      activeFilter
    })

  }

  changeNumberOfItems = (pageSize): void => {
    this.setState({
      pageSize
    })
  }

  setPlaceholder = (setPlaceholder) => {
    const { t } = this.props
    let text

    switch (setPlaceholder) {
      case "usersReviews":
        text = t('review.all.for.users')
        break;
      case "companiesReviews":
        text = t('review.all.for.companies')
        break;
      default:
        text = t('review.all')
        break;
    }

    return text
  }

  render() {
    const { t } = this.props
    const { activeTab, pageSize, activeFilter } = this.state
    const optionalProps = {
      t,
      pageSize,
      activeFilter
    }
    const tabContent =
      activeTab === "reviews" ?
        <ContentReviews
          {...optionalProps}
        /> :
        <ContentClaims {...optionalProps} />

    return (
      <div className="row claims">
        <div className="col-md-12">

          <div data-move="panel-message" className="panel panel-reviews" id="latter-reviews-claims">
            <Widget title={t('reviews.claims.page')} />
            <div className="panel-body">
              <div className="panel-filter no-margin no-border">
                <div data-tab="#tab1" className="filter-wrapper filter-nav clearfix tabs-list">
                  <ul>
                    <li
                      className={classNames({
                        'active': activeTab === "reviews",
                      })}
                    >
                      <span title={t('last.reviews')} onClick={() => this.setState({ activeTab: "reviews" })}>
                        {t('last.reviews')}
                      </span>
                    </li>
                    <li
                      className={classNames({
                        'active': activeTab === "claims",
                      })}
                    >
                      <span title={t('last.claims')} onClick={() => this.setState({ activeTab: "claims" })}>
                        {t('last.claims')}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="filter-container filter-container-reviews">
                  <div className="filter-block">
                    <div className="select-container">
                      {activeTab === "reviews" && <Field
                        name="activeFilter"
                        component={Dropdown}
                        items={this.renderSelectOptions()}
                        className="page-quantity-dropdown"
                        placeholder={this.setPlaceholder(activeFilter)}
                        onChange={this.changeReviewsFilter}
                      />}
                    </div>
                  </div>
                  <div className="filter-block filter-block-sm">
                    <label htmlFor="or3">{t('ads.quantity')}:</label>
                    <div className="select-container">
                      <Field
                        name="pageSize"
                        component={Dropdown}
                        items={dict.pageSizes}
                        className="page-quantity-dropdown"
                        placeholder={pageSize}
                        onChange={this.changeNumberOfItems}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {tabContent}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <SiteStat />
          <BuySellShort />
          <Ads />
        </div>
      </div>
    )
  }
}
