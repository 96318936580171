import React, { Component, Fragment } from 'react'

import Widget from '../../../../shared/components/widget'
import UserHistory from '../../../../user-actions/history'
import fetch from '../../../../shared/fetch'
import { canDo } from '../../../../../store/utils'
import { getLanguageLowercaseName } from "../../../../../store/utils"
import { IUserLogsProps, IUserLogsState } from './types'

export default class extends Component<IUserLogsProps, IUserLogsState> {
  constructor(props) {
    super(props)
    const { userId, match } = props
    this.state = {
      actions: [],
      pageIndex: 0,
      userId: parseInt(match?.params['userId']) || userId,
      userFound: false,
    }
  }

  componentDidMount() {
    this.checkUserAndRedresh()
  }

  componentDidUpdate(oldProps: IUserLogsProps) {
    const { userId = null } = this.props.match?.params
    const { userId: oldUserId = null } = oldProps.match?.params

    if ((userId || oldUserId) && parseInt(userId) !== parseInt(oldUserId)) {
      this.setState({ userId: userId || this.props.userId }, () => this.checkUserAndRedresh())
    }
  }

  checkUserAndRedresh = () => {
    const { userId } = this.state

    fetch(`/user/checkUserExists?userId=${userId}`).then(res => {
      if (res.ok) {
        this.setState({ userFound: true })
        //const canViewLogs = this.checkViewLogsPermissions()
        this.refreshUserLogs()
      }
    })
  }

  refreshUserLogs = () => {
    const { langId } = this.props
    const { pageIndex, userId } = this.state
    //const showFullHistoryLogs = this.checkViewLogsPermissions(true)
    const lang = getLanguageLowercaseName(langId)
    const paramsString = `${lang}/1/${pageIndex}/10/${userId}/true`

    fetch(`/user/getLogs/${paramsString}`).then(res => {
      this.setState({ actions: res.data })
    })
  }

  refreshHistory = pageIndex => {
    this.setState({ pageIndex }, () => {
      this.refreshUserLogs()
    })
  }

  // checkViewLogsPermissions = (viewFullHistory: boolean = false) => {
  //   const { permissions, userId } = this.props
  //   const { userId: exactUserId } = this.state

  //   const isExactUser = +exactUserId === userId
  //   const canViewHistoryLogs = canDo(permissions, 'historyLogs', 'view')
  //   const canViewFullHistoryLogs = canDo(permissions, 'fullHistoryLogs', 'view')

  //   return isExactUser || (!viewFullHistory && canViewHistoryLogs) || canViewFullHistoryLogs
  // }

  render() {
    const { t } = this.props
    const { actions, pageIndex, userFound } = this.state
    //const canViewLogs = this.checkViewLogsPermissions()

    const baseProps = {
      pageIndex,
      pageSize: 10,
      totalItems: actions[0]?.total || 0,
      refresh: this.refreshHistory,
    }

    return (
      <Fragment>
         <Widget title={t('user.history')}>
            <UserHistory {...baseProps} actions={actions} />
          </Widget>
      </Fragment>
    )
  }
}
