import React, { Fragment } from 'react'
import { fromJS } from 'immutable'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import config from '../../../../config'
import ProfileMini from '../../profile-mini'
import Tabs from '../../../UI/tabs'
import { changeWidgetFilter } from '../../../shared/utility'
import Filter from '../../../shared/components/widget-filter'
import SearchTable from '../../../search/table'
import { ProfileDetailsProps, ProfileDetailsState, ProfileTabs } from './types'
import { getUrlParamsString } from '../../../../store/utils'
import AdsTable from '../../../ads/ads-table'
import fetch from '../../../shared/fetch'
import dict from '../../../shared/dictionaries'
import { transportPlaceholder } from '../../../shared/utils/imagePlaceholder'
import ReviewList from '../../../reviews'
import observedObject from '../../../../observedObject'
import BlockList from '../../../block-list'
import UserActions from '../../../user-actions/actions'
import Banner from '../../../banner'
import Pagination from '../../../UI/pagination'
import { scrollToElement } from '../../../../store/utils'
import TooltipWrapper from '../../../UI/popover-wrapper'
import { ModalConsumer, ModalContextState } from '../../../../context/modal-context'
import ItemModal from '../../../transport/modal-item'
import noCarPhoto from '../../../../images/truck.png'
import Spinner from '../../../UI/spinner'

import './styles.scss'

const SEARCH_PAGE_SIZE = 5

const initialState = (instance: ProfileDetails, props): ProfileDetailsState => ({
  userFound: true,
  currentTab: props.match?.params['openedTab'] || 'cargo',
  userBlock: {
    cargoBlocked: false,
    transportBlocked: false,
  },
  userId: props.match?.params['userId'] || props.userId,
  totalAds: 0,
  totalCargo: 0,
  totalTransport: 0,
  totalBlockListItems: 0,
  totalReviews: {},
  openMore: false,
  tabs: [
    {
      value: 'cargo',
      name: props.t('cargo'),
    },
    {
      value: 'transport',
      name: props.t('transport'),
    },
    {
      value: 'ads',
      name: props.t('ads.name'),
    },
    {
      value: 'blockList',
      name: props.t('block.list'),
    },
    {
      value: 'reviews',
      name: props.t('reviews'),
    },
    {
      value: 'autopark',
      name: props.t('user.autopark'),
    },
  ],
  searchItems: {
    items: [],
    totalItems: 0,
    currentPage: 0,
  },
  refreshing: true,
  categoryKey: 0,
  pageIndex: 0,
  pageSize: 10,
  isFetching: true,
  bodyTypes: [],
  filterBar: {
    items: [
      {
        value: 'from-last-visit',
        name: props.t('ads.lastVist'),
      },
      {
        value: 'today',
        name: props.t('ads.todays'),
      },
      {
        value: 'last-3-days',
        name: props.t('ads.lastThreeDays'),
      },
      {
        value: 'all',
        name: props.t('all'),
      },
    ],
    selectedKey: 'all',
    onClick: filterKey => {
      changeWidgetFilter(instance, filterKey, () => {
        instance.refreshSearch()
      })
    },
  },
})

export default class ProfileDetails extends React.Component<
  ProfileDetailsProps,
  ProfileDetailsState
> {
  addReviewRef: any
  modalConsumer: ModalContextState
  constructor(props: ProfileDetailsProps) {
    super(props)
    this.state = initialState(this, props)
    this.addReviewRef = React.createRef()
  }

  componentDidMount() {
    const { autoparkSetActiveTab } = this.props
    this.refreshPageInfo()
    this.checkUserAndRefresh()

    autoparkSetActiveTab('myPark')
    this.scrollToIfNeed()
  }

  refreshPageInfo = async () => {
    this.getTotalCargo()
    this.getTotalTransport()
    this.getTotalAds()
    this.getTotalBlockList()
    this.getTotalAutoparkCars()
    this.getTotalReviews()
  }

  scrollToIfNeed = () => {
    const { match } = this.props
    setTimeout(() => {
      if (match?.params['openedTab'] === "reviews") {
        scrollToElement('reviewsContainer', 100)
      }
    }, 1000);
  }

  UNSAFE_componentWillUpdate() {
    const { userReviewQuota } = this.props
    const { totalReviews } = this.state
    if (totalReviews !== userReviewQuota.reviewCount && totalReviews != 0) {
      this.setState({ totalReviews: userReviewQuota.reviewCount }, () => this.renderTabs())
    }
  }

  renderTabs = (lastTab = false) => {
    const { t, myCarsListTotal } = this.props
    const { totalAds, totalCargo, totalTransport, totalBlockListItems, totalReviews } = this.state

    const tabs = [
      {
        value: 'cargo',
        name: `${t('cargo')} (${totalCargo || 0})`,
      },
      {
        value: 'transport',
        name: `${t('transport')} (${totalTransport || 0})`,
      },
      {
        value: 'ads',
        name: `${t('ads.name')} (${totalAds || 0})`,
      },
      {
        value: 'blockList',
        name: `${t('block.list')} (${totalBlockListItems || 0})`,
      },
      {
        value: 'reviews',
        name: `${t('reviews')} (${totalReviews || 0})`,
      },
      {
        value: 'autopark',
        name: `${t('user.autopark')} (${myCarsListTotal || 0})`,
      },
    ]
    this.setState({ tabs }, () => {
      this.addLogsTab(lastTab)
    })
  }

  getTotalCargo = () => {
    const { cargoSearch } = this.props
    const { filterBar, userId } = this.state
    const totalCargo = cargoSearch({
      exactUserId: userId,
      dateScope: filterBar.selectedKey,
      pageIndex: 0,
      pageSize: SEARCH_PAGE_SIZE,
    })
    this.promiseResolvedForCargo(totalCargo)
  }

  getTotalBlockList = () => {
    const { userId, pageIndex } = this.state
    const paramsString = getUrlParamsString({
      userId,
      pageIndex,
      pageSize: SEARCH_PAGE_SIZE,
    })

    return fetch(`/user/getBlockList?${paramsString}`).then(res => {
      this.setState({ totalBlockListItems: res.data.length },
        () => {
          this.renderTabs()
        })
    })
  }

  promiseResolvedForCargo = arr => {
    Promise.resolve(arr).then(arrList => {
      this.setState(
        {
          totalCargo: arrList.totalItems,
        },
        () => {
          this.renderTabs()
        }
      )
    })
  }

  getTotalTransport = () => {
    const { transportSearch } = this.props
    const { filterBar, userId } = this.state
    const totalTransport = transportSearch({
      exactUserId: userId,
      dateScope: filterBar.selectedKey,
      pageIndex: 0,
      pageSize: SEARCH_PAGE_SIZE,
    })
    this.promiseResolvedForTransport(totalTransport)
  }

  promiseResolvedForTransport = arr => {

    Promise.resolve(arr).then(arrList => {
      this.setState({
        totalTransport: arrList.totalItems,
      },
        () => {
          this.renderTabs()
        })
    })
  }

  getTotalAds = () => {
    const { langId } = this.props
    const { userId } = this.state
    const paramsString = getUrlParamsString({
      page_index: 0,
      page_size: 10,
      tree_type_id: 436,
      days_interval: '',
      active: 1,
      lang_id: langId,
      exact_user_id: userId,
      user_id: userId,
    })

    const getAdsEndpoint = `/announcement/list?${paramsString}`
    fetch(getAdsEndpoint).then(ads => {
      this.setState({ totalAds: ads[0]?.total },
        () => {
          this.renderTabs()
        })
    })
  }

  getTotalAutoparkCars = () => {
    const { loadCars } = this.props
    const { pageSize, pageIndex, userId } = this.state

    const isCompanyCars = 0
    const searchText = ''
    const profileUserId = userId // it's need because in service getCars already is variable userId
    loadCars({ pageIndex, pageSize, searchText, isCompanyCars, profileUserId })
  }

  getTotalReviews = () => {
    const { userId, pageIndex } = this.state
    const paramsString = getUrlParamsString({
      userId,
      pageIndex,
      pageSize: SEARCH_PAGE_SIZE,
      gradeFilter: 0,
    })

    return fetch(`/user/getUserReviews?${paramsString}`).then(res => {
      const { totalCount } = res.data
      this.setState({ totalReviews: totalCount },
        () => {
          this.renderTabs(true)
        })
    })
  }

  checkUserAndRefresh = () => {
    const { isAuth } = this.props
    const { userId } = this.state
    fetch(`/user/checkUserExists?userId=${userId}`).then(res => {
      if (res.ok) {
        isAuth && this.checkUserBlocked()
        this.refreshSearch()
        this.addLogsTab()
      } else {
        this.setState({ userFound: false })
      }
    })
  }

  addLogsTab = (lastTab = false) => {
    const { t } = this.props
    const { tabs } = this.state
    const canViewActionLogs = true

    if (canViewActionLogs) {
      this.setState({ tabs: [...tabs, { value: 'userActions', name: t('user.actions') }] })
    }
    lastTab && this.setState({ isFetching: false })
  }

  checkUserBlocked = () => {
    fetch(`/user/checkUserBlocked/${this.state.userId}`).then(res => {
      this.setState({ userBlock: res.data || { transportBlocked: false, cargoBlocked: false } })
    })
  }

  componentDidUpdate(oldProps: ProfileDetailsProps) {
    const { userId = null } = this.props.match?.params as any
    const { userId: oldUserId = null } = oldProps.match?.params as any

    if ((userId || oldUserId) && parseInt(userId) !== parseInt(oldUserId)) {
      this.setState({ ...initialState(this, this.props) }, () => this.checkUserAndRefresh())
    }
  }

  fetchAds = ({ pageIndex, pageSize }) => {
    const { categoryKey, userId: exact_user_id } = this.state
    const { langId, userId } = this.props
    const paramsString = getUrlParamsString({
      page_index: pageIndex,
      page_size: pageSize,
      tree_type_id: categoryKey || 436,
      days_interval: '',
      active: 1,
      lang_id: langId,
      exact_user_id,
      user_id: userId || 0,
    })
    const getAdsEndpoint = `/announcement/list?${paramsString}`

    return fetch(getAdsEndpoint).then(ads => {
      return {
        totalItems: ads[0]?.total || 0,
        items: ads,
      }
    })
  }

  refreshReviews = ({ pageIndex }) => {
    const { userId, categoryKey } = this.state
    const paramsString = getUrlParamsString({
      userId,
      pageIndex,
      pageSize: SEARCH_PAGE_SIZE,
      gradeFilter: categoryKey || 0,
    })

    return fetch(`/user/getUserReviews?${paramsString}`).then(res => {
      const { reviews, totalCount } = res.data

      return {
        totalItems: totalCount,
        items: reviews,
      }
    })
  }

  refreshBlockList = ({ pageIndex }) => {
    const { userId } = this.state
    const paramsString = getUrlParamsString({
      userId,
      pageIndex,
      pageSize: SEARCH_PAGE_SIZE,
    })

    return fetch(`/user/getBlockList?${paramsString}`).then(res => {
      return {
        totalItems: 1,
        items: res.data,
      }
    })
  }

  getCategoryForUrl = (cat) => {
    let url = ""

    switch (cat) {
      case "userBlock":
        url = "USER_BLOCK"
        break;
      case "userReview":
        url = "USER_REVIEW"
        break;
      case "companyReview":
        url = "COMPANY_REVIEW"
        break;
      default:
        url = "ALL"
        break;
    }

    return url
  }

  refreshUserActions = ({ pageIndex }) => {
    const { langId } = this.props
    const { userId, categoryKey } = this.state

    const category = this.getCategoryForUrl(categoryKey)
    const paramsString = `/user/${userId}/activity/${langId}/${category}/${SEARCH_PAGE_SIZE}/${pageIndex}`

    return fetch(`${paramsString}`).then(res => {
      const { data } = res
      return {
        totalItems: data[0]?.totalCount || 0,
        items: data,
      }
    })
  }

  paginationClick = page => {
    this.setState({ pageIndex: page }, () => {
      this.getAutoparkCars()
    })
  }

  getAutoparkCars = () => {
    const { loadCars, langId } = this.props
    const { pageSize, pageIndex, userId } = this.state

    const { loaded, handler, items } = dict.getTransportTypes('auto')(langId)
    if (loaded) {
      this.setState({ bodyTypes: items })
    } else {
      handler.then(response => {
        this.setState({ bodyTypes: response.items })
      })
    }

    const isCompanyCars = 0
    const searchText = ''
    const profileUserId = userId // it's need because in service getCars already is variable userId
    loadCars({ pageIndex, pageSize, searchText, isCompanyCars, profileUserId })
  }

  refreshSearch = (pageIndex?: number, tab?: ProfileTabs) => {
    const { currentTab, filterBar, userId } = this.state
    this.setState({ refreshing: true })
    if (!tab) tab = currentTab

    const { cargoSearch, transportSearch } = this.props
    let search

    switch (tab) {
      case 'cargo':
        search = cargoSearch
        break
      case 'transport':
        search = transportSearch
        break
      case 'ads':
        search = this.fetchAds
        break
      case 'reviews':
        search = this.refreshReviews
        break
      case 'blockList':
        search = this.refreshBlockList
        break
      case 'userActions':
        search = this.refreshUserActions
        break
      case 'autopark':
        this.getAutoparkCars()
        break
    }

    search &&
      search({
        exactUserId: userId,
        dateScope: filterBar.selectedKey,
        pageIndex: pageIndex >= 0 ? pageIndex : 0,
        pageSize: SEARCH_PAGE_SIZE,
      })
        .then(res => {
          if (res) {
            this.setState(
              {
                refreshing: false,
                searchItems: {
                  totalItems: res.totalItems,
                  items: res.items,
                  currentPage: pageIndex >= 0 ? pageIndex : 0,
                },
              },
              () => {
                if (currentTab === 'cargo') {
                  this.setState({ totalCargo: res.totalItems }, () => this.renderTabs())
                }
                if (currentTab === 'transport') {
                  this.setState({ totalTransport: res.totalItems }, () => this.renderTabs())
                }
              }
            )
          }
        })
        .catch(err => {
          console.error(err)
          this.setState({ refreshing: false })
        })
  }

  handleFilterClick = filterKey => {
    this.setState({ categoryKey: filterKey }, () => {
      this.refreshSearch()
    })
  }

  renderBaseInfo = car => {
    const { bodyTypes } = this.state
    const bodyType = bodyTypes.find(x => x.value === car.body_type_id)

    return (
      <div className="truck-item-row bordered">
        <div className="row">
          <div className="col-xs-9">
            <span className="truck-name">{car.make_and_model}</span>
          </div>
          <div className="col-xs-7">
            <span className="truck-number">{car.gos_nomer}</span>
          </div>
        </div>
        <p className="meta-gray truck-meta-aligned">
          <span>{car.year}</span>
          <span>{bodyType ? bodyType.name : null}</span>
          {car.eco_standard_id >= 0 && <span>Euro-{car.eco_standard_id}</span>}
        </p>
      </div>
    )
  }

  emptyCarListContent = () => {
    return <h2>Автопарк пустой</h2>
  }

  showItemModal = (item: any, edit: boolean, image = noCarPhoto) => {
    const { showModalComponent, clearModal } = this.modalConsumer

    if (!item.photo.length) {
      item.photo[0] = image
    }

    showModalComponent(
      <ItemModal
        item={item}
        onClose={clearModal}
        canEdit={edit}
        imageFolder={`auto-park/${item.user_id}`}
        refresh={this.refreshSearch}
      />,
      {
        className: `transport-modal`,
        skipLayout: true,
        backdrop: true,
      }
    )
  }

  renderAutoparkTabContent = (myCarsList, canEdit = false, total: number, callback?) => {
    const { t, userId } = this.props
    const { pageIndex, pageSize } = this.state
    const carTypes = dict.carTypes(t)
    const ownershipTypes = dict.ownershipTypesCars(t)
    const hasTrailer = carTypeId => [2102, 2103].includes(carTypeId)

    let myCars
    if (myCarsList.items) {
      myCars = myCarsList.items.filter(oneCar => oneCar.isCarCompany !== 1)
    } else {
      myCars = myCarsList
    }
    const totalCars = !total ? myCarsList.total : total

    return (
      <Fragment>
        <div className="panel-body car-list-container">
          {myCars.length > 0
            ? myCars.map(item => {
              const { photo } = item
              const carType = carTypes.find(x => x.value === item.car_type_id)
              const ownershipType = ownershipTypes.find(x => x.value === item.ownership_type_id)
              const placeholderImage = transportPlaceholder(item.car_type_id)
              const edit = userId === item.user_id
              const isPlaceholderImage =
                !photo.length || (photo.length === 1 && photo[0].includes('/static/media/'))

              return (
                <div className="truck-item" key={item.id}>
                  <div className="truck-item-top row">
                    <div className="col-sm-10 col-sm-offset-2">
                      <p className="name-main">
                        {carType && carType.name}
                        <u>{ownershipType && ownershipType.name}</u>
                      </p>
                    </div>
                    {edit && (
                      <div className="col-sm-4 ta-r">
                        <Link
                          to={`/transport/autopark/${item.id}`}
                          className="icon icon-edit"
                          onClick={() => callback && callback()}
                        >
                          <i className="fa fa-pencil" />
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="truck-item-main row">
                    <div className="truck-item-block col-sm-4 col-md-2">
                      <div
                        className="img-container"
                        onClick={() => this.showItemModal(item, edit, placeholderImage)}
                      >
                        {!isPlaceholderImage ? (
                          <div
                            className="car-img-container"
                            style={{
                              backgroundImage: `url('${(config as any).apiUrl
                                }/common/file/auto-park/${item.user_id}/${photo[0]}')`,
                            }}
                          />
                        ) : (
                          <div
                            className="car-img-container"
                            style={{
                              backgroundImage: `url('${placeholderImage}')`,
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="truck-item-block col-sm-12 col-md-6">
                      {this.renderBaseInfo(item.car)}
                      {hasTrailer(item.car_type_id) && this.renderBaseInfo(item.trailer)}
                    </div>
                    <div className="truck-item-block col-sm-6 col-md-4 col-lg-3 col-xl-4">
                      <div className="truck-item-row">
                        <span className="label">{t('autopark.upload.download')}</span>
                        <p className="truck-meta-aligned">
                          {item.is_loading_top && <span>{t('autopark.up')}</span>}
                          {item.is_loading_side && <span>{t('autopark.side')}</span>}
                          {item.is_loading_rare && <span>{t('autopark.back')}</span>}
                        </p>
                      </div>
                      <div className="truck-item-row">
                        <span className="label">{t('autopark.carrying.capacity')}</span>
                        <p className="truck-meta-aligned">
                          <span>{item.weight_value ? `${item.weight_value} t` : '-'}</span>
                        </p>

                        <div className="truck-item-row additional-options">
                          {item.is_oversized && (
                            <span>
                              <i className="ico ico-weight" />
                              {t('cargo.oversize')}
                            </span>
                          )}
                          {item.is_grouping && (
                            <span>
                              <i className="fa fa-th-large" />
                              {t('cargo.grouping')}
                            </span>
                          )}
                        </div>


                      </div>
                    </div>
                    <div className="truck-item-block col-sm-6 col-md-4 col-lg-5 col-xl-4">
                      <div className="truck-item-row">
                        <span className="label">{t('autopark.volume')}</span>
                        <p className="truck-meta-aligned">

                          {(item.volume_m3 || item.volume_m3 === 0) && (
                            <span key="m3">
                              {item.volume_m3} {t('autopark.m3')}
                            </span>
                          )}
                          {(item.volume_ldm || item.volume_ldm === 0) && (
                            <span key="ldm">
                              {item.volume_ldm} {t('autopark.ldm')}
                            </span>
                          )}
                          {(item.volume_pal || item.volume_pal === 0) && (
                            <span key="pal">
                              {item.volume_pal} {t('autopark.p')}
                            </span>
                          )}
                        </p>
                      </div>
                      <div className="truck-item-row clearfix">
                        <div className="fl-left">
                          {item.is_adr && (
                            <Fragment>
                              <span className="label">
                                {t('autopark.adr')}
                                <i className="fa fa-fire" />
                              </span>
                              <p className="truck-meta-aligned">
                                <span>
                                  {item.adr_class} {t('autopark.class.above')}
                                </span>
                              </p>
                            </Fragment>
                          )}
                        </div>
                        <div
                          className={classNames({
                            'fl-right': item.is_adr,
                            'no-margin': true,
                          })}
                        >
                          {item.is_climate_control && (
                            <Fragment>
                              <span className="label">
                                {t('autopark.temp.mode')}
                                <i className="fa fa-snowflake-o" />
                              </span>
                              <p className="truck-meta-aligned">
                                <span>
                                  {t('autopark.from')} {item.temperature_from > 0 && '+'} {item.temperature_from || 0}{'\u2103 '}
                                  {t('autopark.to')} {item.temperature_to > 0 && '+'}
                                  {item.temperature_to || 0}{'\u2103 '}
                                </span>
                              </p>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="truck-item-bottom">
                    <div className="row">
                      <div className="col-md-11 col-xl-12">
                        <p className="meta-gray truck-meta-aligned">
                          {item.is_tir && <span>{t('autopark.tir')}</span>}
                          {item.is_cmr && <span>{t('autopark.cmr')}</span>}
                          {item.is_declaration && <span>{t('cargo.doc.custom')}</span>}
                          {item.is_custom && <span>{t('under.t1.declaration')}</span>}
                          {item.is_cmr_insurance && <span>{t('cargo.insurance.cmr')}</span>}
                          {item.is_sanitary && <span>{t('autopark.sanitary.certificate')}</span>}
                          {item.is_adr && <span>{t('autopark.licensy')}</span>}
                        </p>
                      </div>
                      <div className="col-md-5 col-xl-4">
                        <div className="clearfix">
                          <div className="has-tooltip fl-left">
                            <span className="col-blue">
                              {item.notes && (
                                <TooltipWrapper text={item.notes} hover>
                                  <i className="fa fa-info-circle col-blue" />
                                  {t('autopark.additional.info')}
                                </TooltipWrapper>
                              )}
                            </span>
                          </div>
                          <div className="fl-right">
                            {item.is_gps && (
                              <span>
                                <i className="ico ico-target"></i>&nbsp;{t('autopark.is.gps')}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
            : this.emptyCarListContent()}
        </div>
        {totalCars > 10 && (
          <Pagination
            onClick={this.paginationClick}
            total={totalCars}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />
        )}
      </Fragment>
    )
  }

  renderActiveComponent = () => {
    const {
      currentTab,
      refreshing,
      searchItems,
      filterBar,
      userId: exactUserId,
      categoryKey,
    } = this.state
    const { t, userId, langId, myCarsList } = this.props

    const notFoundText =
      userId == exactUserId ? t(`${currentTab}.not.found.owner`) : t(`${currentTab}.not.found.user`)

    const baseProps = {
      pageIndex: searchItems.currentPage,
      pageSize: SEARCH_PAGE_SIZE,
      totalItems: searchItems.totalItems,
      refresh: this.refreshSearch,
    }

    const canViewActionLogs = true

    if (currentTab === 'cargo' || currentTab === 'transport') {
      return (
        <div className="cargo-transport-list">
          <h5 className="title-secondary tt-u">{t(`${currentTab}.offers`)}</h5>
          <Filter {...filterBar} loadLanguages />
          <SearchTable
            {...baseProps}
            searchType={currentTab}
            openIn="preview"
            processing={refreshing}
            items={fromJS(searchItems.items)}
            userId={userId}
            exactUserId={exactUserId}
            notFoundText={notFoundText}
            showDateIsShort
          />
        </div>
      )
    } else if (currentTab === 'ads') {
      const filterItems = dict.filterOptionsForAds(langId)

      return (
        <div className="cargo-transport-list">
          <h5 className="title-secondary tt-u">{t(`ads.user`)}</h5>
          <Filter
            items={filterItems}
            selectedKey={categoryKey === 0 || categoryKey === 436 ? '' : categoryKey}
            emptyOption={t('ads.allAds')}
            onClick={this.handleFilterClick}
            loadLanguages
          />
          <AdsTable
            {...baseProps}
            embeddable
            ads={searchItems.items}
            loggedUserId={userId}
            openIn="preview"
          />
        </div>
      )
    } else if (currentTab === 'reviews') {
      const reviewTypes = dict.reviewTypes(t)

      return (
        <div className="cargo-transport-list">
          <h5 className="title-secondary tt-u">{t('review.title')}</h5>
          <Filter
            items={reviewTypes}
            selectedKey={!categoryKey ? '' : categoryKey}
            emptyOption={t('review.all')}
            loadLanguages
            onClick={this.handleFilterClick}
          />
          <ReviewList
            {...baseProps}
            reviewingUserId={exactUserId}
            reviews={searchItems.items}
            notFoundText={categoryKey ? t('review.not.found') : t('review.not.found.all')}
          />
        </div>
      )
    } else if (currentTab === 'blockList') {
      return (
        <div className="cargo-transport-list">
          <h5 className="title-secondary tt-u">{t('block.list')}</h5>
          <BlockList {...baseProps} entityId={exactUserId} blocks={searchItems.items} />
        </div>
      )
    } else if (currentTab === 'autopark' && myCarsList) {
      return this.renderAutoparkTabContent(myCarsList, true, myCarsList.length)
    } else if (currentTab === 'userActions' && canViewActionLogs) {
      const filterItems = dict.userActionsFilter(t)

      return (
        <div className="cargo-transport-list">
          <h5 className="title-secondary tt-u">{t('user.actions')}</h5>
          <Filter
            items={filterItems}
            selectedKey={categoryKey ? categoryKey : ''}
            emptyOption={t('all')}
            onClick={this.handleFilterClick}
            loadLanguages
          />
          <UserActions {...baseProps} actions={searchItems.items} />
        </div>
      )
    }

    return
  }

  handleChangeCurrentTab = (tab: ProfileTabs) => {
    if (this.state.currentTab !== tab) {
      this.setState(
        {
          categoryKey: 0,
          searchItems: {
            currentPage: 0,
            totalItems: 0,
            items: [],
          },
        },
        () => {
          this.setState({ currentTab: tab }, () => {
            this.refreshSearch(0, tab)
          })
        }
      )
    }
  }

  handleAddReviewClick = () => {
    this.handleChangeCurrentTab('reviews')
    setTimeout(() => observedObject.emit('focus', 'addReview'), 400) // workaround(kostyl) behind render timings
  }

  handleBlockChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { userId: exactUserId, userBlock } = this.state
    const { userId } = this.props
    const { checked, id } = e.target

    const newUserBlock = {
      ...userBlock,
      [id]: checked,
    }
    exactUserId !== userId &&
      fetch('/user/changeBlockStatus', {
        method: 'POST',
        body: JSON.stringify({
          blockingUserId: exactUserId,
          ...newUserBlock,
        }),
      })
      .then(res => {
        if (res.ok) {
          this.state.currentTab === 'blockList' && this.refreshSearch()
          this.setState({
            userBlock: newUserBlock,
          })
        }
      })
      .catch(e => console.error(e))
  }

  renderTabsContent = (tabs, currentTab, showcontent = false) => {
    return (
      <Tabs tabItems={tabs} onTabClick={this.handleChangeCurrentTab} currentTab={currentTab}>
        {showcontent && this.renderActiveComponent()}
      </Tabs>
    )
  }

  render() {
    const {
      tabs,
      currentTab,
      userId,
      userFound,
      userBlock: { cargoBlocked, transportBlocked },
      openMore,
      isFetching,
    } = this.state
    const { t } = this.props

    if (!userFound) {
      return <h4>{t('user.not.found')}</h4>
    }

    return (
      <div>
        <ProfileMini
          customUserId={userId}
          showFullInfo
          openReviews={() => this.handleChangeCurrentTab('reviews')}
          focusAddReview={this.handleAddReviewClick}
          cargoBlocked={cargoBlocked}
          transportBlocked={transportBlocked}
          onBlockChange={this.handleBlockChange}
          showActionIcons
        />
        <Banner bannerId={565} />
        <div className="user-entities">
          {!isFetching && <div className="additional-block">
            <button
              aria-controls="menu"
              type="button"
              className="nav__dropdown-toggle more-tabs-button priority-nav-is-visible"
              onClick={() => this.setState({ openMore: !this.state.openMore })}
            >
              {t('more.lowercase')}
            </button>
            <div
              className={classNames(
                'nav__dropdown priority-nav__dropdown show additional-tabs',
                openMore && 'show-tabs'
              )}
            >
              {tabs && this.renderTabsContent(tabs, currentTab)}
            </div>
          </div>}

          <div className="main-tabs">
            {isFetching ? <Spinner /> : tabs && this.renderTabsContent(tabs, currentTab, true)}
          </div>
        </div>
        <ModalConsumer>
          {modalConsumer => {
            this.modalConsumer = modalConsumer
            return null
          }}
        </ModalConsumer>
      </div>
    )
  }
}
