import { connect } from 'react-redux'
import { compose } from 'recompose'

import withTranslation from '../../../globals/components/withTranslation'
import { loginAllowed } from '../../../store/actions/auth'

import Component from './component'

export default compose(
  withTranslation,
  connect(null, {
    loginAllowed,
  })
)(Component)
