export const md18 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>ПЕРЕЧЕНЬ документов, необходимых для получения фитосанитарных сертификатов на экспорт
и фитосанитарных карантинных разрешений на импорт (транзит)</h4><p></p>

<p style="text-indent:2em;"></p><h6>1. Для получения фитосанитарного сертификата на экспорт:</h6><p></p> 

<p>- заявление физического или юридического лица для получения фитосанитарного сертификата на экспорт;</p><br>

<p>- фитосанитарное карантинное разрешение на импорт страны-импортера;</p><br>

<p>- акт фитосанитарного досмотра места отгрузки товара;</p><br>

<p>- сертификат происхождения груза;</p><br>

<p>- налоговая или товарно-транспортная накладная на соответствующий груз.</p>

<p style="text-indent:2em;"></p><h6>Кроме того, для получения фитосанитарного сертификата на экспорт семенного и посадочного материала дополнительно необходимы следующие документы:</h6><p></p> 

<p>- лицензия на право производства и реализации семян или посадочного материала;</p><br>

<p>- сертификат качества для семян и посадочного материала, предназначенных для экспорта;</p><br>

<p>- свидетельство карантинной экспертизы подкарантинного груза, выданное карантинной лабораторией.</p>

<p style="text-indent:2em;"></p><h6>2. Для получения фитосанитарного карантинного разрешения на импорт (транзит):</h6><p></p> 

<p style="text-indent:2em;">- заявление физического или юридического лица на получение фитосанитарного карантинного разрешения на импорт (транзит);</p><br>

<p>- акт фитосанитарного досмотра места разгрузки груза.</p><br>

<p><a href="http://lex.justice.md/viewdoc.php?action=view&amp;view=doc&amp;id=315204&amp;lang=2">Источник</a></p>



</div>
`