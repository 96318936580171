import React from 'react'
import classnames from 'classnames'
import WidgetHeader from '../widget-header'

export default class WidgetBase extends React.Component {
  // componentDidMount() {
  //   // this.refreshItems(this.props.items)
  // }

  render() {
    const {
      title = '',
      showHeader = true,
      headerMenu,
      headerMenuItems,
      className,
      children,
      linksTo: { title: titleLink } = {},
      dataMoveForMobile = "",
      ...restProps
    } = this.props

    const headerChildren = []
    const knownChildren = []
    const restChildren = []
    React.Children.toArray(children).forEach(child => {
      if (child.type.displayName === 'WidgetHeader') {
        headerChildren.push(child)
      } else if (['WidgetFilter', 'WidgetSettings'].includes(child.type.displayName)) {
        knownChildren.push(child)
      } else {
        restChildren.push(child)
      }
    })

    if (headerChildren.length === 0 && showHeader) {
      headerChildren.push(
        <WidgetHeader
          key="header"
          title={title}
          titleLink={titleLink}
          menuItems={headerMenuItems}
          menuRenderer={headerMenu}
        />
      )
    }

    return (
      <div className={classnames('panel', className)} {...restProps} data-move={dataMoveForMobile} >
        {headerChildren}

        <div className="panel-body">
          {knownChildren}

          {restChildren}
        </div>
      </div>
    )
  }
}
