export const eu9 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Transport inteligent şi navigaţie prin satelit</h4><p></p>

<p style="font-weight:500;">Sisteme inteligente de transport</p><br>

<p>Transport fluvial sigur și eficient: serviciile de informații fluviale</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva stabilește norme privind utilizarea serviciilor de informații fluviale (RIS)* armonizate. Aceste norme sunt concepute pentru a se garanta siguranța, eficiența navigației interioare și
 respectul pentru mediu în UE. Normele se aplică canalelor, râurilor, lacurilor și porturilor care permit traficul navelor între 1 000 și 1 500 de tone.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Serviciile de informații fluviale cuprind:</h6><p></p>

<p>informații geografice, hidrologice și administrative privind căile navigabile („informații privind pasele de navigație”);</p><br>

<p style="text-indent:2em;">informații care afectează deciziile imediate, pe termen mediu și lung privind navigația („informații tactice și strategice privind traficul”);</p><br>

<p>acțiuni în cazul unui accident („sprijin pentru atenuarea catastrofelor”);</p><br>

<p>statistici, servicii vamale, redevențe de cale navigabilă și taxe portuare.</p>

<p style="text-indent:2em;"></p><h6>Țările UE trebuie:</h6><p></p>

<p>să asigure ca aplicarea RIS să fie eficientă, să poată fi extinsă și să poată interacționa cu alte sisteme;</p><br>

<p>să furnizeze tuturor utilizatorilor RIS datele relevante privind navigația și planificarea curselor;</p><br>

<p>să stabilească centre RIS în conformitate cu necesitățile regionale;</p><br>

<p>să desemneze autoritățile care să supravegheze aplicarea RIS și schimbul internațional de date.</p><br>

<p style="text-indent:2em;">Comisia Europeană este responsabilă pentru definirea, până la termenele stabilite, a orientărilor tehnice pentru planificarea, punerea în aplicare și exploatarea operațională a RIS.</p>
 
<p style="text-indent:2em;"></p><h6>Printre acestea se numără:</h6><p></p>

<p>un sistem de afișare a hărților electronice și de informare;</p><br>

<p>rapoarte electronice transmise de nave;</p><br>

<p>notificări către comandanții navelor;</p><br>

<p>sisteme de reperare și de urmărire a navelor;</p><br>

<p>compatibilitatea echipamentelor RIS.</p><br>

<p>Comisia, asistată de un comitet de experți naționali, a dezvoltat cerințe tehnice pentru RIS.</p><br>

<p style="font-weight:500;">Program de acțiune</p><br>

<p style="text-indent:2em;">În 2006, Comisia a prezentat o comunicare privind un program de acțiune european integrat pentru transportul pe căi navigabile interioare. Cunoscut sub denumirea de Naiades (Navigation and
 inland waterway action and development in Europe), programul a fost actualizat în 2013 (Naiades II) și s-au stabilit obiective specifice până în anul 2020. Comunicarea Comisiei cu privire
 la Naiades II are ca scop crearea condițiilor pentru ca transportul pe căile navigabile interioare să devină un mijloc de transport de calitate.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p style="text-indent:2em;">Directiva a intrat în vigoare la 20 octombrie 2005. Țările UE au trebuit să o încorporeze în legislația națională până la 20 octombrie 2007.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p style="text-indent:2em;">UE dispune de peste 37 000 km de canale și râuri care fac legătura dintre orașe și zone economice cheie. Aceste căi navigabile interioare aduc o contribuție majoră unui sistem durabil de
 transport, reducând congestia și poluarea pe drumurile UE.</p><br>

<p style="font-weight:500;">Portalul serviciilor de informații fluviale</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="text-indent:2em;">* Servicii de informații fluviale (RIS): acestea sprijină gestionarea traficului și transportului pe căile navigabile interioare și, ori de câte ori este posibil din punct de vedere tehnic,
 coordonarea cu alte moduri de transport.</p><br>

<p style="font-weight:500;">Implementarea sistemelor de transport inteligente în Europa</p><br>

<p style="text-indent:2em;">Sistemele de transport inteligente (STI) sunt preconizate să contribuie la crearea unui sistem de transport mai curat, mai sigur și mai eficient în întreaga Uniune Europeană (UE).</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Directiva 2010/40/UE a Parlamentului European și a Consiliului din 7 iulie 2010 privind cadrul pentru implementarea sistemelor de transport inteligente în domeniul transportului rutier 
și pentru interfețele cu alte moduri de transport.</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Sistemele de transport inteligente (STI) sunt preconizate să contribuie la crearea unui sistem de transport mai curat, mai sigur și mai eficient în întreaga Uniune Europeană (UE).</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva dorește să încurajeze dezvoltarea unor tehnologii inovatoare de transport menite să creeze Sisteme de transport inteligente (STI). Acest lucru va fi posibil prin introducerea
 unor standarde și specificații UE comune. Scopul acestora este de a stabili servicii STI interoperabile* și eficiente, lăsând țările UE să decidă fiecare în ce sisteme să investească.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">STI acoperă o gamă largă de servicii</p><br>

<p style="text-indent:2em;">Directiva se adresează aplicațiilor și serviciilor legate de Sisteme de transport inteligente (STI) din sectorul rutier al UE, precum și modului în care aceste aplicații comunică 
cu alte moduri de transport.</p><br>

<p style="text-indent:2em;">STI sunt sisteme în cadrul cărora se aplică tehnologii ale informațiilor și comunicațiilor în domeniul transportului rutier, inclusiv infrastructură, vehicule și utilizatori, 
în gestionarea traficului și gestionarea mobilității.</p>

<p style="text-indent:2em;"></p><h6>Următoarele domenii sunt identificate ca domenii prioritare pentru elaborarea și utilizarea de specificații și standarde:</h6><p></p>

<p style="text-indent:2em;">utilizarea optimă a datelor privind condițiile rutiere, de trafic și de deplasare, de ex. se oferă utilizatorilor posibilitatea de planificare a călătoriilor;</p><br>

<p style="text-indent:2em;">servicii STI privind continuitatea traficului și gestionarea mărfurilor (adică servicii care nu sunt întrerupte la trecerea graniței de către camioane);</p><br>

<p style="text-indent:2em;">aplicații STI privind siguranța și securitatea rutieră (de ex. alerte de risc de vizibilitate redusă sau de prezență a oamenilor, a animalelor și a deșeurilor pe carosabil);</p><br>

<p style="text-indent:2em;">conectarea vehiculului la infrastructura de transport, adică echiparea vehiculelor cu sisteme care permit schimbul de date sau de informații.</p>

<p style="text-indent:2em;"></p><h6>În cadrul acestor domenii prioritare se disting 6 măsuri prioritare care se axează pe:</h6><p></p>

<p style="text-indent:2em;">1. servicii de informare la nivelul UE privind călătoriile multimodale (pentru călătorii care implică mijloace variate de transport, de ex. tren și vapor);</p><br>

<p>2.servicii de informare în timp real cu privire la trafic, la nivelul UE;</p><br>

<p>3.modalitatea de a oferi gratuit utilizatorilor informații despre trafic referitoare la siguranța rutieră;</p><br>

<p>4.disponibilitate armonizată a unui serviciu eCall interoperabil la nivelul UE;</p><br>

<p>5.servicii de informare referitoare la spații de parcare sigure pentru camioane și vehicule comerciale;</p><br>

<p>6.servicii de rezervare de spații de parcare sigure pentru camioane și vehicule comerciale.</p><br>

<p style="text-indent:2em;">În ce privește implementarea aplicațiilor și a serviciilor STI, țările UE trebuie să ia măsurile necesare pentru a se asigura că sunt aplicate specificațiile aferente adoptate de Comisie.</p><br>

<p style="text-indent:2em;">Fiecare țară a UE își păstrează dreptul de a decide cu privire la implementarea unor astfel de aplicații și servicii pe teritoriul său.</p><br>

<p style="font-weight:500;">Serviciul eCall destinat raportării accidentelor rutiere</p><br>

<p style="text-indent:2em;">Un exemplu pentru una din cele 6 măsuri prioritare îl reprezintă un serviciu eCall implementat la nivelul UE. Se depun în prezent eforturi de introducere în UE a unui serviciu eCall 
pentru accidente rutiere. Ideea principală este aceea că numărul 112 eCall apelează automat numărul unic de urgență în Europa 112 în eventualitatea unui accident grav de circulație și 
comunică locația unui vehicul către serviciile de asistență în cazuri de urgență. Ulterior se configurează o legătură telefonică la centrul corespunzător de asistență pentru urgențe 
(denumite și Centre de răspuns pentru situații de urgență - CRSU) în timp ce detaliile accidentului sunt transmise serviciilor de salvare, incluzând ora incidentului, locația exactă 
a vehiculului accidentat și direcția de deplasare. Statele UE au convenit să implementeze pe teritoriul lor infrastructura eCall CRSU, necesară pentru primirea și gestionarea adecvată 
a tuturor apelurilor „eCalls”, până cel târziu la data de 1 octombrie 2017.</p><br>

<p style="font-weight:500;">Eforturi pentru acțiunile prioritare în desfășurare</p><br>

<p style="text-indent:2em;">Un raport adoptat în 2014 privind implementarea Directivei STI prevede faptul că 3 seturi de specificații au fost adoptate (eCall, informații universale minime despre trafic, referitoare 
la siguranța rutieră și servicii informaționale pentru locuri de parcare sigure și securizate).</p><br>

<p style="text-indent:2em;">Specificațiile pentru sistemele de informații rutiere în timp real la nivelul UE au fost adoptate în decembrie 2014, iar în 2015 se lucrează pentru realizarea serviciilor de informare la 
nivelul UE privind deplasarea multimodală. În ce privește ultima măsură prioritară - servicii de rezervare a locurilor de parcare sigure și securizate pentru camioane și vehicule comerciale
 - nu sunt planificate acțiuni suplimentare în viitorul apropiat.</p><br>

<p style="font-weight:500;">CÂND SE APLICĂ PREZENTA DIRECTIVĂ?</p><br>

<p>Începând cu 26 august 2010.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE AI ACTULUI</p><br>

<p style="text-indent:2em;">* Interoperabilitate: capacitatea sistemelor și a proceselor comerciale subiacente de a face schimb de date și a distribui informații și cunoștințe.</p><br>

<p>Sistemul european de nouă generație pentru gestionarea traficului aerian (SESAR)</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul înființează proiectul SESAR [de cercetare privind gestionarea traficului aerian (ATM) pe cerul unic european].</p><br>

<p style="text-indent:2em;">Obiectivul SESAR este de a îmbunătăți performanțele ATM modernizând și armonizând sistemele ATM prin definirea, dezvoltarea, validarea și punerea în aplicare a unor soluții tehnologice și
 operaționale inovatoare privind ATM.</p><br>

<p style="text-indent:2em;">Întreprinderea comună (IC) SESAR este un parteneriat între sectorul public și cel privat care se ocupă de faza de dezvoltare a proiectului SESAR. Aceasta a fost înființată în 2007 de Uniunea
 Europeană și Eurocontrol, Organizația Europeană pentru Siguranța Navigației Aeriene.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE PRIVIND IC SESAR</p><br>

<p style="text-indent:2em;">Aceasta este o întreprindere comună responsabilă de faza de dezvoltare a proiectului SESAR, componenta tehnologică (de cercetare și dezvoltare – CD) a cerului unic european (SES), un pachet
 de măsuri conceput pentru a întruni cerințele viitoare privind capacitatea și siguranța navigației aeriene.</p><br>

<p style="text-indent:2em;">Sarcina sa este să ofere UE, în conformitate cu Planul general ATM, o infrastructură deosebit de performantă pentru controlul traficului aerian până în 2035. Scopul este de a face posibil un
 transport aerian mai sigur, mai ecologic și mai eficient, adică efectuarea mai multor zboruri directe cu un consum redus de combustibil și cu mai puține întârzieri.</p><br>

<p style="text-indent:2em;">La inițiativă participă Comisia Europeană, Eurocontrol, prestatori de servicii de navigație aeriană, organizații ale pasagerilor aerieni, utilizatori ai spațiului aerian, autorități de
 reglementare, operatori aeroportuari, membri ai personalului companiilor aeriene, al aeroporturilor și de gestionare a traficului aerian, oameni de știință și producători de echipamente
 (aproximativ 3 000 de experți). IC este un organism al UE creat în acest scop în temeiul articolului 187 din Tratatul privind funcționarea Uniunii Europene.</p><br>

<p style="text-indent:2em;">Inițiativa a prezentat deja un plan general ATM (actualizat în 2012 și 2015), care definește conținutul, dezvoltarea și planurile de desfășurare pentru generația următoare de sisteme ATM.</p><br>

<p style="text-indent:2em;">IC gestionează procedurile de acordare de granturi membrilor, în conformitate cu normele de participare la programul-cadru de CD al UE Orizont 2020, în urma prelungirii, în 2014, a duratei
 de existență a inițiativei.</p>

<p style="text-indent:2em;"></p><h6>Printre realizările de până acum ale IC SESAR se numără:</h6><p></p>

<p style="text-indent:2em;">primul zbor cvadridimensional (3D + timp) din lume, având ca scop îmbunătățirea schimbului de informații privind traiectoria de la un capăt la celălalt;</p><br>

<p style="text-indent:2em;">un set de instrumente extins pentru sporirea siguranței pe pistele aeroporturilor și
libertatea de planificare/replanificare a rutei pentru a reduce zborul și emisiile generate de combustibili.</p>

<p style="text-indent:2em;"></p><h6>Începând din 2014, programul de cercetare și inovare (CI) al IC SESAR se concentrează asupra unor domenii precum:</h6><p></p>

<p>servicii optimizate ale rețelei ATM;</p><br>

<p>servicii de trafic aerian avansate;</p><br>

<p>operațiuni aeroportuare deosebit de performante;</p><br>

<p>o infrastructură aviatică avansată, integrată și raționalizată.</p><br>

<p style="font-weight:500;">Finanțarea</p><br>

<p style="text-indent:2em;">Contribuția UE pentru faza de dezvoltare a SESAR este de 700 de milioane EUR în cadrul programelor TEN-T și PC7, la care se adaugă 585 de milioane EUR în cadrul programului Orizont 2020. 
Pentru a lua în considerare durata programului Orizont 2020, toate cererile de propuneri ale SESAR trebuie lansate până la 31 decembrie 2020.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Directiva a intrat în vigoare la 3 martie 2007.</p><br>

<p style="font-weight:500;">Sistemul UE de informații și schimb de informații maritime</p><br>

<p style="text-indent:2em;">Acest act legislativ al Uniunii Europene (UE) instituie un sistem de monitorizare și de schimb de informații privind traficul navelor maritime. Obiectivul acestuia este de a spori siguranța 
maritimă, securitatea portuară și maritimă, protecția mediului și pregătirea pentru situații de poluare. De asemenea, sistemul permite schimbul și partajarea de informații suplimentare care 
să faciliteze eficiența traficului și a transportului maritim.</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Acest act legislativ al Uniunii Europene (UE) instituie un sistem de monitorizare și de schimb de informații privind traficul navelor maritime. Obiectivul acestuia este de a spori siguranța 
maritimă, securitatea portuară și maritimă, protecția mediului și pregătirea pentru situații de poluare. De asemenea, sistemul permite schimbul și partajarea de informații suplimentare care 
să faciliteze eficiența traficului și a transportului maritim.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva instituie un sistem de monitorizare a apelor și a coastelor Europei - supraveghere maritimă și cunoașterea situației activităților desfășurate pe mare (a pozițiilor navelor) - 
care să sprijine țările UE în ceea ce privește sarcinile lor operaționale.</p><br>

<p style="text-indent:2em;">Pentru a spori cunoașterea situației în domeniul maritim și a oferi autorităților soluții adaptate, actul legislativ a fost modificat prin Directiva Comisiei 2014/100/UE. Aceasta permite 
integrarea informațiilor colectate și transmise prin sistemul UE de schimb de informații maritime [SafeSeaNet (SSN)] cu datele provenite din alte sisteme de monitorizare și de urmărire ale 
UE, cum ar fi CleanSeaNet, precum și din sisteme externe [de exemplu, sistemele de identificare automată prin satelit (AIS)].</p><br>

<p style="text-indent:2em;">Directiva stabilește responsabilitățile țărilor UE, ale autorităților maritime, ale transportatorilor, ale operatorilor navelor și ale căpitanilor de nave.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Sunt vizate toate navele cu un tonaj brut de 300 sau mai mare, indiferent dacă transportă sau nu mărfuri periculoase, cu excepția:</h6><p></p>

<p>navelor de război;</p><br>

<p>vaselor de pescuit, ambarcațiunilor tradiționale și celor de agrement având o lungime mai mică de 45 de metri;</p><br>

<p>buncărelor mai mici de 1 000 de tone.</p><br>

<p style="font-weight:500;">Cerințe impuse operatorilor de nave care se îndreaptă spre un port al UE</p><br>

<p style="text-indent:2em;">Aceștia trebuie să notifice anumite informații (identificarea navei, numărul total al persoanelor de la bord, portul de destinație, ora de sosire estimată etc.) ghișeului național unic 
(începând cu data de 1 iunie 2015).</p><br>

<p style="font-weight:500;">Echipamentele și instalațiile</p>

<p style="text-indent:2em;"></p><h6>Navele care fac o escală într-un port al unei țări din UE trebuie să fie dotate cu:</h6><p></p>

<p>un sistem de identificare automată;</p><br>

<p style="text-indent:2em;">un sistem de înregistrare a datelor de voiaj (VDR sau „cutie neagră”) pentru a facilita efectuarea anchetelor în urma producerii accidentelor.</p><br>

<p style="text-indent:2em;">Țările din UE și din Spațiul Economic European (SEE) trebuie să aibă receptoare AIS și să conecteze ghișeul unic național/SSN național la sistemul SSN central.</p><br>

<p style="font-weight:500;">Mărfurile periculoase sau poluante de la bordul navelor</p><br>

<p>Înainte de a prelua la bord asemenea mărfuri, transportatorii trebuie să le declare operatorului.</p><br>

<p style="text-indent:2em;">De asemenea, operatorul, agentul sau căpitanul navei trebuie să notifice autorității competente informații generale, cum ar fi identificarea navei și informațiile furnizate de către 
transportator.</p><br>

<p style="font-weight:500;">Monitorizarea navelor potențial periculoase și intervenția în caz de incidente și accidente</p>

<p style="text-indent:2em;"></p><h6>Autoritățile trebuie să informeze țările UE în cauză dacă sunt anunțate cu privire la nave:</h6><p></p>

<p>care au fost implicate în incidente sau accidente pe mare;</p><br>

<p>care nu au respectat cerințele de notificare și raportare;</p><br>

<p>care au deversat în mod deliberat substanțe poluante sau</p><br>

<p>cărora li s-a refuzat accesul în porturi</p>

<p style="text-indent:2em;"></p><h6>Căpitanul unei nave trebuie să raporteze imediat:</h6><p></p>

<p>orice incident sau accident care afectează siguranța navei;</p><br>

<p>orice incident sau accident care compromite siguranța navigației;</p><br>

<p>orice situație de natură să determine poluarea apelor sau a țărmului unei țări a UE;</p><br>

<p>orice pată de produse poluante si containere sau colete care se observă plutind în derivă pe mare.</p><br>

<p style="font-weight:500;">Locuri de refugiu</p><br>

<p style="text-indent:2em;">Toate țările UE/SEE trebuie să întocmească planuri pentru a găzdui navele care necesită asistență în locuri de refugiu. De asemenea, acestea trebuie să se reunească periodic pentru a face schimb
 de experiență și a lua măsuri de îmbunătățire comune.</p><br>

<p style="font-weight:500;">Conformitatea</p><br>

<p style="text-indent:2em;">Țările UE trebuie să verifice funcționarea sistemelor proprii de informare și să introducă un sistem de sancțiuni financiare care să descurajeze nerespectarea cerințelor directivei.</p><br>

<p style="font-weight:500;">Gestionarea</p><br>

<p style="text-indent:2em;">Sistemul este dezvoltat și gestionat de Comisia Europeană și de țările UE/SEE. Agenția Europeană pentru Siguranță Maritimă răspunde de funcționarea tehnică a acestuia.</p><br>

<p style="font-weight:500;">Navigaţia prin satelit</p><br>

<p>Cele două sisteme de radionavigație prin satelit ale Europei își continuă dezvoltarea</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul stabilește normele privind gestionarea și finanțarea celor două sisteme de radionavigație prin satelit ale UE, Galileo și EGNOS (Serviciul european geostaționar mixt de navigare). 
Fiecare set de infrastructură cuprinde sateliți și o rețea de stații terestre.</p><br>

<p style="text-indent:2em;">Obiectivul regulamentului este de a răspunde nevoilor celor două programe, în special în ceea ce privește guvernanța și securitatea, de a asigura buna gestiune financiară a acestora și de a 
promova utilizarea lor.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Galileo</p><br>

<p style="text-indent:2em;">urmărește să configureze și să opereze prima infrastructură globală de radionavigație prin satelit și localizare (sistemul furnizează date de radionavigație, oră și locație) concepută special în 
scopuri civile, care poate fi utilizată de către o varietate de actori publici și privați din Europa și din întreaga lume. Noul sistem este conceput astfel încât să funcționeze independent de alte 
sisteme existente – precum sistemul de poziționare globală (GPS) al Statelor Unite sau sistemul Glonass al Rusiei – sau care ar putea fi create;</p><br>

<p style="text-indent:2em;">este conceput astfel încât să fie interoperabil cu alte constelații globale de radionavigație prin satelit. Această interoperabilitate le va permite producătorilor să realizeze echipamente 
terminale care să proceseze mai multe constelații.</p>

<p style="text-indent:2em;"></p><h6>Decizia de punere în aplicare (UE) 2016/413 stabilește poziționarea diferitelor componente terestre care compun infrastructura Galileo, inclusiv:</h6><p></p>

<p>două centre de control – în Germania și Italia;</p><br>

<p>un centru de monitorizare a securității împărțit în două locații – în Franța și în Regatul Unit;</p><br>

<p>un centru de referință – în Țările de Jos.</p><br>

<p style="text-indent:2em;">În lumina negocierilor privind Brexit, Decizia de punere în aplicare (UE) 2018/115 modifică Decizia (UE) 2016/413, lansând procesul de transfer al centrului de monitorizare a securității din 
Regatul Unit în Spania.</p><br>

<p style="font-weight:500;">EGNOS</p><br>

<p style="text-indent:2em;">urmărește îmbunătățirea calității semnalelor deschise recepționate de la sistemele globale de radionavigație prin satelit existente (GNSS), precum și a semnalelor recepționate de la serviciul deschis
 oferit de sistemul Galileo atunci când acesta va fi disponibil;</p><br>

<p style="text-indent:2em;">oferă anumite aplicații sofisticate esențiale din punct de vedere al siguranței, cum ar fi cele pentru ghidarea aeronavelor atât pe verticală, cât și pe orizontală în timpul abordurilor de aterizare 
sau navigarea ambarcațiunilor prin canalele înguste.</p><br>

<p style="font-weight:500;">Finanțarea</p><br>

<p style="text-indent:2em;">În perioada 2014-2020, UE pune la dispoziție finanțări de aproximativ 7 miliarde EUR pentru activități conexe. Comisia Europeană deține responsabilitatea generală asupra celor două programe și 
gestionează fondurile.</p>

<p style="text-indent:2em;"></p><h6>Finanțarea se folosește pentru:</h6><p></p>

<p style="text-indent:2em;">finalizarea fazei de desfășurare a programului Galileo până în 31 decembrie 2020 (de exemplu, construirea și protejarea infrastructurii din spațiu și de la sol);</p><br>

<p style="text-indent:2em;">faza de exploatare a programului Galileo (de exemplu, gestionarea, întreținerea, îmbunătățirea și protejarea infrastructurii din spațiu și de la sol);</p><br>

<p style="text-indent:2em;">faza de exploatare a programului EGNOS (de exemplu, gestionarea, întreținerea, îmbunătățirea și protejarea infrastructurii din spațiu și de la sol);</p><br>

<p>gestionarea și monitorizarea programelor Galileo și EGNOS.</p><br>

<p style="font-weight:500;">Raport privind punerea în aplicare</p><br>

<p style="text-indent:2em;">În 2017, Comisia a publicat un raport privind punerea în aplicare a programelor Galileo și EGNOS. Concluzia raportului este că Galileo și EGNOS au îndeplinit toate etapele care erau prevăzute pentru
 perioada în cauză și se fac progrese în direcția realizării tuturor obiectivelor de punere în aplicare a programului prevăzute pentru 2020.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Se aplică de la miercuri, 1 ianuarie 2014.</p><br>

<p style="font-weight:500;">GSA - agenția responsabilă de sistemele globale de navigație prin satelit europene</p><br>

<p style="text-indent:2em;">Uniunea Europeană (UE) a instituit o agenție însărcinată cu maximizarea randamentului investițiilor europene în sisteme globale de navigație prin satelit (GNSS) în ceea ce privește beneficiile pentru 
utilizatori, creșterea economică și competitivitatea.</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Uniunea Europeană (UE) a instituit o agenție însărcinată cu maximizarea randamentului investițiilor europene în sisteme globale de navigație prin satelit (GNSS) în ceea ce privește beneficiile pentru 
utilizatori, creșterea economică și competitivitatea.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul (UE) nr. 912/2010 instituie Agenția Sistemului Global de Navigație prin Satelit (GNSS) European, modificând denumirea și atribuțiile fostei Autorități Europene de Supraveghere a GNSS.</p><br>

<p style="text-indent:2em;">Actul pune în aplicare acordurile de guvernanță publică privind programele GNSS pentru perioada 2014-2020, stabilite prin Regulamentul (UE) nr. 1285/2013. Agenția nu mai are funcția de a asigura gestionarea 
intereselor publice privind programele GNSS (această funcție fiind preluată de Comisia Europeană).</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Agenția GNSS este un organism care funcționează la nivelul UE și este responsabil de:</h6><p></p>

<p>acreditarea de securitate (*) pentru sistemele sale globale de navigație prin satelit;</p><br>

<p>supravegherea funcționării Centrului de monitorizare a securității Galileo;</p><br>

<p style="text-indent:2em;">contribuția la promovarea și comercializarea serviciilor GNSS și îndeplinirea altor sarcini, delegate de Comisie, referitoare la punerea în aplicare a programelor Galileo și EGNOS, inclusiv 
gestionarea programelor.</p><br>

<p style="font-weight:500;">Structura</p>

<p style="text-indent:2em;"></p><h6>Agenția este formată din trei organisme:</h6><p></p>

<p style="text-indent:2em;">1.Consiliul de administrație: acesta are responsabilitatea de a se asigura că agenția își îndeplinește sarcinile, fiind responsabil și de aspecte care țin de resurse și de buget în ceea ce privește 
acreditarea de securitate;</p><br>

<p style="text-indent:2em;">2.directorul executiv: acesta este numit de Consiliul de administrație și este responsabil de gestionarea și de reprezentarea agenției;</p>

<p style="text-indent:2em;"></p><h6>3.Consiliul de acreditare de securitate: acesta este responsabil de acreditarea de securitate, stabilind conformitatea sistemelor cu cerințele de securitate înainte de orice decizie de programare, 
cum ar fi:</h6><p></p>

<p>aprobarea strategiei de acreditare de securitate;</p><br>

<p>lansarea de sateliți;</p><br>

<p>autorizarea exploatării sistemelor și a stațiilor terestre (*).</p><br>

<p style="font-weight:500;">Independența activităților de acreditare a sistemelor de securitate</p>

<p style="text-indent:2em;"></p><h6>Regulamentul (UE) nr. 912/2010 a fost modificat prin Regulamentul (UE) nr. 512/2014 în vederea adaptării la noua structură de gestionare pentru GNSS European, stabilită prin Regulamentul (UE) nr. 
1285/2013. Modificările introduse asigură independența activităților legate de acreditarea sistemelor de securitate, precum și separarea dintre acestea și celelalte activități ale agenției. Printre 
aceste modificări se numără:</h6><p></p>

<p>competențe sporite pentru Consiliul de acreditare de securitate;</p><br>

<p style="text-indent:2em;">Consiliul de acreditare de securitate, nu Consiliul de administrație, este responsabil de pregătirea și aprobarea operațiunilor din cadrul programelor agenției legate de activitățile de acreditare 
de securitate a sistemului;</p><br>

<p style="text-indent:2em;">președintele Consiliului de acreditare de securitate va gestiona activitățile de acreditare de securitate și va asigura punerea acestora în aplicare.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Începând cu 9 noiembrie 2010.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="text-indent:2em;">(*) Acreditare de securitate: verificarea conformității în cadrul normelor și al reglementărilor de securitate aplicabile întocmite de Consiliu și de Comisie. În legătură cu principiile generale privind 
acreditarea de securitate, responsabilitățile și sarcinile, consultați Capitolul III din Regulamentul (UE) nr. 912/2010.</p><br>

<p style="text-indent:2em;">(*) Stații terestre: unități la sol dotate cu echipament pentru recepția sau pentru recepția și transmisia de semnale de la sau la sateliți de comunicații.</p><br>

<p style="font-weight:500;">Politica spaţială europeană</p><br>

<p style="font-weight:500;">Programul UE pentru supravegherea și urmărirea spațială</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Sateliții constituie structura principală pentru numeroase aplicații și servicii esențiale economiei, precum și pentru securitate și bunăstarea publicului. Aceștia sunt supuși însă riscului de coliziune cu 
deșeurile spațiale*, care au devenit cea mai gravă amenințare la adresa activităților spațiale.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DECIZII?</p><br>

<p style="text-indent:2em;">Decizia instituie cadrul de sprijin pentru supravegherea și urmărirea spațială, cu scopul de a proteja sateliții împotriva deșeurilor spațiale.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Ce este activitatea de supraveghere și urmărire spațială (SST)?</p><br>

<p style="text-indent:2em;">SST identifică și monitorizează sateliții și deșeurile spațiale mai ales prin senzori precum telescoape și radare terestre. În prezent nu există o capacitate de SST la nivel european; operatorii de sateliți 
și de lansare depind de datele din Statele Unite (SUA) pentru alertele anticoliziune.</p><br>

<p style="font-weight:500;">Obiective</p><br>

<p style="text-indent:2em;">Principalul obiectiv al cadrului pentru SST este de a sprijini dezvoltarea unui serviciu SST european. Acest serviciu ar trebui să fie bazat pe o rețea construită în jurul senzorilor SST existenți în țările UE. 
Rețeaua poate fi susținută de tehnologie și informații furnizate de SUA.</p>

<p style="text-indent:2em;"></p><h6>De asemenea, cadrul are următoarele obiective specifice:</h6><p></p>

<p style="text-indent:2em;">evaluarea și reducerea riscurilor de coliziune dintre sateliți și deșeurile spațiale și urmărirea mișcărilor acestora în momentul identificării unui risc potențial de coliziune; acest lucru permite planificarea 
și aplicarea unor măsuri de prevenire a coliziunii;</p><br>

<p>reducerea riscurilor legate de lansarea vehiculelor spațiale europene;</p><br>

<p style="text-indent:2em;">supravegherea reintrării necontrolate a navelor spațiale sau a deșeurilor spațiale în atmosfera terestră și trimiterea unor alerte timpurii mai precise și mai eficiente, cu scopul de a reduce riscurile 
potențiale aferente;</p><br>

<p>eforturi de prevenire a proliferării deșeurilor spațiale.</p><br>

<p>Cine poate utiliza aceste servicii?</p><br>

<p>Acestea ar trebui să fie disponibile operatorilor și autorităților din domeniul public, comercial, civil și militar.</p><br>

<p style="font-weight:500;">Progresele înregistrate</p><br>

<p style="text-indent:2em;">Până în iulie 2018, Comisia va prezenta un raport privind punerea în aplicare, cuprinzând progresele înregistrate în ceea ce privește atingerea obiectivelor deciziei.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p>* Deșeuri spațiale: diferite obiecte artificiale, cum ar fi sateliți vechi și fragmente de rachete, aflate pe orbita Pământului.</p><br>

<p><a href="https://eur-lex.europa.eu/summary/chapter/transport/3209.html?root=3209">Sursa</a></p>



</div>
`