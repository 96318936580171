import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import TooltipWrapper from '../../../UI/popover-wrapper'
import TooltipModal from '../../../search/table/tooltip-modal'
import { SingleClaimProps } from './types'
import fetch from '../../../shared/fetch'
import { toastr } from 'react-redux-toastr'
import { canDo } from '../../../../store/utils'
import classNames from 'classnames'

import './styles.scss'
export default class SingleClaim extends React.Component<SingleClaimProps> {
  state = {
    delClaimID: 0,
    isFetching: false,
  }

  renderDelimitedMessage = (message: string) => {
    return message.split('\n').map((part, id) => (
      <p key={id} style={{ wordBreak: 'break-word' }}>
        {part}
      </p>
    ))
  }

  deleteReview = () => {
    const {
      currentDeleteReviewId,
      refreshData,
      deleteClaimType,
      claimResponseId,
      delClaimID,
      t,
    } = this.props

    const baseUrl = `/claim/${currentDeleteReviewId}`
    const url =
      deleteClaimType === 'deleteClaim' ? baseUrl : `${baseUrl}/response/${claimResponseId}`
    const text =
      deleteClaimType === 'deleteClaim' ? t('claim.was.removed') : t('claim.answer.was.removed')
    fetch(url, {
      method: 'DELETE',
    }).then(() => {
      refreshData(text)
      delClaimID(0, 0, '', 0)
    })
  }

  renderDeleteReviewPopup = (t, resetDeleteReviewId) => {
    const { deleteClaimType } = this.props
    const { isFetching } = this.state
    return (
      <div className="popup-remove-comment">
        <div className="remove-container">
          <h1>
            {deleteClaimType !== 'deleteClaim'
              ? t('claim.delete.answer.note')
              : t('claim.delete.note')}
            ?
          </h1>
          <button
            type="button"
            className="button button-orange"
            onClick={() => this.deleteReview()}
          >
            {t('delete')}
          </button>
          <button
            type="button"
            disabled={isFetching}
            onClick={() => resetDeleteReviewId()}
            className="button button-orange button-blue"
          >
            {t('button.close')}
          </button>
          <div className="remove-note review-note">
            {deleteClaimType !== 'deleteClaim' ? t('claim.answer.note') : t('claim.note')}
          </div>
        </div>
      </div>
    )
  }

  renderDeleteClaimBtn = (
    reviewId,
    reviewerUserId = 0,
    deleteClaimType,
    claimResponseId = 0,
    answerDate = null
  ) => {
    const { delClaimID, t } = this.props
    return (
      <div className="delete-claim-container">
        <Link
          to="#"
          className="link-message"
          onClick={() => delClaimID(reviewId, reviewerUserId, deleteClaimType, claimResponseId)}
        >
          {deleteClaimType === 'deleteClaim' ? t('claim.delete') : t('review.answer.delete')}
        </Link>
        <span>{answerDate && moment(answerDate).format('DD/MM/YYYY HH:mm')}</span>
      </div>
    )
  }

  render() {
    const {
      t,
      claim: {
        claimId,
        description,
        createdByUserId,
        createdByFirstName,
        createdByLastName,
        createdDate,
        createdByCompanyId,
        createdByCompanyName,
        response,
        title,
      },
      reviewingCompanyId,
      setResponsing,
      currentDeleteReviewId,
      resetDeleteReviewId,
      permissions,
      isOwnerCompanyId,
    } = this.props

    const canDeleteClaims = canDo(permissions, 'profile', 'deleteClaim')
    const answerDate = response[0] ? response[0].createdDate : null

    return (
      <div className="panel-content-row claim-content">
        {currentDeleteReviewId === claimId && this.renderDeleteReviewPopup(t, resetDeleteReviewId)}

        <div className="panel-message">
          <p className="title">{title}</p>
          <div className="panel-message-header">
            <div
              className="claim-author"
              dangerouslySetInnerHTML={{
                __html: t('claim.author'),
              }}
            ></div>
            <TooltipWrapper
              text={<TooltipModal companyId={0} userId={createdByUserId} />}
              hover
              innerClassName="full-tooltip"
              disabled={false}
              parentCustomClass="move-up-container"
            >
              <Link to={`/user/details/${createdByUserId}`} className="name">
                {createdByFirstName} {createdByLastName}
              </Link>
            </TooltipWrapper>

            {createdByCompanyId && (
              <TooltipWrapper
                text={<TooltipModal companyId={createdByCompanyId} userId={0} />}
                hover
                innerClassName="full-tooltip"
                disabled={false}
                parentCustomClass="move-up-container"
              >
                <Link to={`/company/item/${createdByCompanyId}`} className="name">
                  {` `}({createdByCompanyName})
                </Link>
              </TooltipWrapper>
            )}
            <span className="meta-gray">{moment(createdDate).format('DD/MM/YYYY HH:mm')}</span>
          </div>
          <div className="panel-message-content">{description}</div>

          {canDeleteClaims ? (
            this.renderDeleteClaimBtn(
              claimId,
              Number(createdByUserId),
              'deleteClaim',
              0,
              answerDate
            )
          ) : (
            response[0] && <span className="delete-claim-container">
              {moment(response[0].createdDate).format('DD/MM/YYYY HH:mm')}
            </span>
          )}
          {response[0] && (
            <>
              <div className="panel-message-gray review-asnwer">
                <div className="claim-answer">{t('claim.answer')}</div>
                <p
                  className={classNames({
                    'title-sm': true,
                    'review-answer-date': true,
                    'remove-m-b': canDeleteClaims,
                  })}
                ></p>
                {response[0].comment}
              </div>
              {canDeleteClaims &&
                this.renderDeleteClaimBtn(
                  claimId,
                  Number(createdByUserId),
                  'deleteAnswer',
                  response[0].id
                )}
            </>
          )}
        </div>

        {!response[0] && Number(isOwnerCompanyId) === Number(reviewingCompanyId) && (
          <Link
            to="#"
            className="link-message"
            onClick={() => setResponsing({ claimId: Number(claimId), title })}
          >
            {t('review.answer')}
          </Link>
        )}
      </div>
    )
  }
}
