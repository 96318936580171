import React from 'react'
import classNames from 'classnames'
// import classes from './styles.css'

const spinner = ({ className = '' }) => (
  <div
    className={classNames({
      spinner: true,
      [className]: !!className,
    })}
  >
    <svg height="100%" viewBox="0 0 32 32" width="100%">
      <circle
        cx="16"
        cy="16"
        fill="none"
        r="14"
        strokeWidth="4"
        style={{ stroke: 'rgb(241, 113, 73)', opacity: 0.2 }}
      />
      <circle
        cx="16"
        cy="16"
        fill="none"
        r="14"
        strokeWidth="4"
        style={{ stroke: 'rgb(241, 113, 73)', strokeDasharray: 80, strokeDashoffset: 60 }}
      />
    </svg>
  </div>
)

export default spinner
