import React, { Component } from 'react'
import './conversationsearch.scss'

interface RenderPropsState {
  searchValue: string,
}

interface ComponentProps {
  t: any,
  searchCallback: any,
}

export default class ConversationSearch extends Component<ComponentProps, RenderPropsState> {
  searchDelayTimer: NodeJS.Timeout

  constructor(props) {
    super(props)

    this.state = {
      searchValue: '',
    }
  }

  onChange = (str) => {
    this.setState({searchValue: str})

    if (this.searchDelayTimer) {
      clearTimeout(this.searchDelayTimer)
    }

    this.searchDelayTimer = setTimeout(() => {
      if (this.props.searchCallback) {
        this.props.searchCallback(str)
      }
    }, 1000)
  }

  render() {
    const { t } = this.props
    const { searchValue } = this.state

    return (
      <div className="conversation-search">
        <input
          type="search"
          className="conversation-search-input"
          placeholder={t('messenger.search.messages')}
          value={searchValue}
          onChange={e => { this.onChange(e.target.value)}}
        />
      </div>
    )
  }
}
