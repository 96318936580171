import React from 'react'
import Ads from '../ads'
import SiteStat from '../site-stat'
import BuySellShort from '../buy-sell/short'
import Widget from '../shared/components/widget'

export default () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <Widget title="404 / Страница не найдена">
          <div className="panel-content-row">Страница не найдена</div>
        </Widget>
      </div>
      <div className="col-md-4">
        <SiteStat />
        <BuySellShort />
        <Ads />
      </div>
    </div>
  )
}
