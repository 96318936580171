export const int11 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>ЕВРОПЕЙСКАЯ ЭКОНОМИЧЕСКАЯ КОМИССИЯ ООН</h4><p></p>

<p></p><h4>РЕКОМЕНДАЦИЯ N 21/Rev.1</h4><p></p>

<p></p><h4>КОДЫ ДЛЯ ВИДОВ ГРУЗА, УПАКОВКИ И МАТЕРИАЛА УПАКОВКИ
(С ДОПОЛНИТЕЛЬНЫМИ КОДАМИ ДЛЯ НАИМЕНОВАНИЙ УПАКОВКИ)</h4><p></p>

<p></p><h4>(Женева, август 1994 года)</h4><p></p>

<p style="text-indent:2em;">Рабочая группа по упрощению процедур международной торговли, являющаяся вспомогательным органом Европейской экономической комиссии Организации
 Объединенных Наций, приняла решение включить в свою программу работы на 1976 год проект, предусматривающий разработку точных определений
 различных типов и методов упаковки для последующего создания кодов для наименований упаковки, наиболее часто используемых в торговле. Целью
 являлось увязывание документации с товарами и упрощение идентификации товаров, а также других операций по обработке груза во время
 транспортировки.</p><br>

<p style="text-indent:2em;">Учитывая, что другие международные органы, такие, как Комитет ЕЭК ООН по внутреннему транспорту, Европейское экономическое сообщество (ЕЭС),
 Международная палата по судоходству (МПС) и Международный союз железных дорог (МСЖД), также проявляли большой интерес к этому вопросу и вели
 по этой теме соответствующую работу, Рабочая группа в 1981 году предложила секретариатам всех заинтересованных международных организаций
 рассмотреть различные концепции и совместно согласовать различные коды. После обстоятельных консультаций и большой совместной работы как 
 на национальном, так и международном уровнях окончательный проект в 1986 году был передан на рассмотрение Рабочей группе, которая на своей
 двадцать третьей сессии в марте 1986 года решила принять следующую Рекомендацию.</p><br>

<p style="text-indent:2em;">На своей тридцать девятой сессии в марте 1994 года Рабочая группа решила принять предложение, представленное делегацией Канады в документе
 TRADE/WP.4/R.895, о включении в Рекомендацию в качестве дополнительного приложения кодов для упаковок, используемых на перевозки опасных
 грузов, и внести в нее соответствующие изменения.</p><br>

<p style="font-weight:500;">РЕКОМЕНДАЦИЯ</p>

<p style="text-indent:2em;"></p><h6>Рабочая группа по упрощению процедур международной торговли:</h6><p></p>

<p style="text-indent:2em;">принимая во внимание быстрые и все ускоряющиеся темпы ввода в действие новых видов транспорта и средств обработки информации, а также необходимость
 скорейшего принятия процедур торговли, соответствующих этим новым видам и средствам,</p><br>

<p style="text-indent:2em;">отмечая существующую необходимость согласования имеющейся терминологии и кодов, используемых в процедурах международной торговли для
 описания и представления различных видов груза, упаковки и материала упаковки,</p><br>

<p style="text-indent:2em;">рекомендует правительствам и организациям, ответственным за соответствующие национальные законодательные нормы и практические действия,
 связанные с движением товаров в международной торговле, оказывать содействие деятельности по упрощению процедур международной торговли
 путем рассмотрения кодов, представленных в данной Рекомендации, с целью включения их в эти законодательные нормы и использования в
 практической деятельности;</p><br>

<p style="text-indent:2em;">рекомендует организациям, ответственным за разработку международных документов, в которых используются коды, такие же, как и в настоящей
 Рекомендации, рассматривать вопрос о проведении любых таких кодов в соответствие с представленными в настоящей Рекомендации при рассмотрении
 имеющихся или при подготовке новых международных положений;</p><br>

<p style="text-indent:2em;">рекомендует участникам международной торговли надлежащим образом использовать цифровые коды, представленные в настоящей Рекомендации, если
 этого требуют торговые процедуры для описания различных видов груза, упаковки и материала упаковки;</p><br>

<p style="text-indent:2em;">рекомендует участникам международной торговли надлежащим образом использовать дополнительные буквенные коды, представленные в настоящей
 Рекомендации, если этого требуют торговые процедуры для описания наименования упаковок;</p><br>

<p style="text-indent:2em;">предлагает правительствам и заинтересованным международным организациям уведомить Исполнительного секретаря Европейской экономической комиссии
 о том, в какой степени они способны обеспечить согласованность соответствующих кодов, за которые они несут ответственность, или сообщить
 причины, не позволяющие им осуществить это.</p><br>

<p style="text-indent:2em;">На тридцать девятой сессии Рабочей группы присутствовали представители Австрии, Бельгии, Болгарии, Венгрии, Германии, Греции, Дании, Израиля
, Исландии, Ирландии, Испании, Италии, Канады, Мальты, Нидерландов, Норвегии, Польши, Российской Федерации, Румынии, Словакии, Словении,
 Соединенного Королевства Великобритании и Северной Ирландии, Соединенных Штатов Америки, Турции, Финляндии, Франции, Хорватии, Чешской 
 Республики, Швейцарии, Швеции и Эстонии. В соответствии с положениями статьи 11 круга ведения Комиссии участие принимали также представители
 Австралии, Бразилии, Габона, Кореи, Нигерии, Новой Зеландии, Сенегала и Японии.</p><br>

<p>На сессии присутствовали также представители Европейского союза (ЕС).</p><br>

<p style="text-indent:2em;">Присутствовали также представители секретариата Конференции Организации Объединенных Наций по торговле и развитию (ЮНКТАД), Комиссии
 Организации Объединенных Наций по международному торговому праву (ЮНСИТРАЛ) и Международного центра торговли ЮНКТАД / ГАТТ (МЦТ), а 
 также представители следующих межправительственных организаций: Всемирного почтового союза (ВПС), Европейской ассоциации свободной
 торговли (ЕАСТ), Центрального управления международных железнодорожных перевозок (ОСТИ) и Совета таможенного сотрудничества (СТС).
 Были представлены следующие неправительственные организации: Международный комитет железнодорожного транспорта (МКЖТ), Международная
 авиатранспортная ассоциация (ИАТА), Международная ассоциация по кодированию потребительских товаров (ЕАН), Международная конференция
 перевозчиков экспресс-грузов (МКПЭГ), Международный союз автомобильного транспорта (МСАТ), Международная торговая палата (МТП),
 Международная организация по стандартизации (ИСО), Международный союз железных дорог (МСЖД), Общество Всемирной межбанковской дальней
 связи для передачи финансовой информации (СВИФТ), Союз портовых администраций Северной Америки (СПАСА). По приглашению секретариата
 присутствовали также представители Тайваньского комитета по ЭДИФАКТ, СИТПРОЗА (Комитет по упрощению торговли Южноафриканской Республики
 ) и Международной федерации инспекционных учреждений (МФИУ).</p>

<p></p><h4>КОДЫ ДЛЯ ВИДОВ ГРУЗА, УПАКОВКИ И МАТЕРИАЛА УПАКОВКИ (С ДОПОЛНИТЕЛЬНЫМИ КОДАМИ 
ДЛЯ НАИМЕНОВАНИЙ УПАКОВОК)</h4><p></p>

<p style="font-weight:500;">I. ИСТОРИЯ ВОПРОСА</p><br>

<p style="text-indent:2em;">1. Международная торговля предполагает движение товаров через границы государств. По определенным причинам во время перевозки необходимо
 описывать и идентифицировать такие товары. Важную роль здесь играет идентифицирующая ("отгрузочная") маркировка, и в равной степени
 полезным может оказаться описание характера товара. Однако вид, в котором товары представлены для транспортировки, в значительной мере
 облегчает идентификацию товара, а также имеет особое значение при осуществлении погрузочно-разгрузочных операций, планировании и
 статистическом учете таких операций и, кроме того, представляет собой основу для установления ставок фрахта и тарифов на операции
 по обработке груза.</p><br>

<p style="text-indent:2em;">2. Согласование терминологии и концепций для описания и идентификации товаров и грузов при их транспортировке было признано в качестве
 одного из вопросов, требующих уделения внимания в рамках международной деятельности по упрощению процедур торговли. Признано также,
 что весьма ценная работа была уже проведена операторами одного вида транспортных перевозок и некоторыми официальными регулирующими
 органами, ответственными за нормы санитарии и правила техники безопасности при транспортировке определенных видов товаров (например,
 пищевых продуктов, растений, лекарств, опасных грузов и опасных отходов). Однако эти усилия носили разрозненный характер, и поэтому
 в настоящее время существует целый ряд несогласованных терминов и кодов, касающихся грузов, упаковок и других видов внешнего оформления
 товаров при транспортировке и связанных с ней операциях. Отсутствие такого рода унификации вызывало определенные трудности как при
 последовательных операциях по перевозке грузов различными видами транспорта, так и при отправке и упаковке грузов, а также при составлении 
 статистических данных по международной торговле и транспорту.</p><br>

<p style="text-indent:2em;">3. Компьютеризация транспортных процедур еще более усилила потребности в согласовании. Унификация данных является необходимым
 предварительным условием для обмена информацией между торговыми партнерами и другими участниками торговли как на частном, так и на
 государственном уровнях для снижения количества документации при взаимном обмене данными за счет использования автоматических
 средств, а также для упрощения связанных с документацией процедур.</p><br>

<p style="text-indent:2em;">4. В 1976 году Рабочая группа ЕЭК ООН по упрощению процедур международной торговли приняла решение включить в программу работы
 новый пункт, касающийся разработки кодов упаковок с основной целью увязки документов с грузовой отправкой. В это же время
 Международный союз железных дорог (МСЖД) совместно с Организацией сотрудничества железных дорог (ОСЖД) занимался разработкой
 кодов упаковок, необходимых для железнодорожного транспорта, а Международная палата по судоходству (МПС) - для морского транспорта.
 МСЖД и МПС предложили принять участие в работе по данному новому пункту в качестве содокладчиков. Целью являлась унификация системы
 кодирования, поскольку предполагалось, что она будет представлять значительный общий интерес, в частности в плане упрощения торговли.
 Данное предложение с удовлетворением было встречено Рабочей группой.</p><br>

<p style="text-indent:2em;">5. В задачу содокладчиков входило составление списка наименований различных видов упаковок и их синонимов, рассмотрение значений
 детализированных описаний, подготовка диаграмм для более легкого определения. Всеобъемлющий доклад был передан Рабочей группе в
 1981 году (TRADE/WP.4/R.140); он содержит анализ и методологию, разработанные для четырехзначной цифровой системы с тремя уровнями,
 в которой первый знак обозначает "грузовые единицы", второй и третий знаки - 57 установленных видов упаковки и четвертый знак
 - материал упаковки. В рамках такой гибкой структуры можно было бы провести дальнейшее согласование в международном масштабе.</p><br>

<p style="text-indent:2em;">6. В 1977 году Комитет ЕЭК по внутреннему транспорту одобрил выдвинутое на тридцать первой сессии Группы экспертов по статистике
 транспорта предложение о том, чтобы Классификацию грузов для европейской транспортной статистики (КЕТС) привести в соответствие 
 с современными требованиями. Одной из задач целевой группы, созданной для проведения такой работы, являлось "изучение возможностей
 включения в КЕТС характеристик, касающихся перевозимых грузов".</p><br>

<p style="text-indent:2em;">7. Правительства Бельгии и Нидерландов совместно занялись изучением данного вопроса и в апреле 1979 года представили совместный
 документ (TRANS/GE.6/R.21), в котором они рекомендовали для характеристики операций по обработке грузов на четырех видах транспорта
 (морском, внутреннем водном транспорте, железнодорожном и автомобильном) использовать одноцифровую классификацию, не связанную с
 КЕТС и относящуюся к "форме внешнего представления упаковки". Европейское экономическое сообщество в 1981 году представило проект 
 классификации грузов с использованием одноцифрового кода, применимого для всех видов транспорта (TRANS/GE.6/R.36).</p><br>

<p style="text-indent:2em;">8. Отдел морской перевозки ЮНКТАД в 1979 году разработал одноцифровой "общий код упаковки", а также двухцифровой "детализированный
 код" упаковки для "Справочника о единообразной системе портовой статистики и показателях производительности".</p><br>

<p style="text-indent:2em;">9. Рабочая группа по упрощению процедур международной торговли понимала, что и другие международные органы, такие, как Совет
 таможенного сотрудничества (СТС) и региональные экономические группы сильно заинтересованы в проекте разработки кодов.
 Секретариат ЕЭК обязался представить доклад о работе и определить, какие организации будут участвовать в изучении вопроса
 согласования различных концепций (TRADE/WP.4/R.202). Секретариат ЕЭК обратился к секретариатам заинтересованных международных
 организаций с предложением объединить усилия для достижения в будущем оптимального согласования классификаций и, если это
 возможно, кодов. С 1981 по 1985 год в Женеве было проведено пять таких межсекретариатских совещаний, обслуживание которых
 обеспечивал Отдел торговли ЕЭК ООН и которые проводились под председательством представителя Статистического бюро Европейских
 сообществ.</p>

<p style="text-indent:2em;"></p><h6>10. На первом совещании были рассмотрены цели различных кодов и принято решение о том, что эти коды должны охватывать все
 виды грузов, перевозимых любыми видами транспорта, и классифицировать их в соответствии с наружными покрытиями или упаковкой.
 На данном совещании были также согласованы первые пять общих категорий для классификации грузов с применением однозначного кода.
 На втором совещании (сентябрь 1982 года) были изучены основополагающие принципы и практические проблемы (синонимы, комбинированные
 упаковки, трудности, связанные с опасными грузами, и т.д.). Было решено:</h6><p></p>

<p>1) определить те термины, которые являются предпочтительными;</p><br>

<p style="text-indent:2em;">2) предусмотреть простые коды, определяющие одну упаковку (например, коды ЕЭК и ЮНКТАД), и сложные коды для комбинированных упаковок
 (МСЖД/МПС код);</p><br>

<p style="text-indent:2em;">3) исключить ссылку на опасные грузы (поскольку опасность отражает свойство самих грузов, а не их упаковки, и может также
 относиться к неупакованным массовым грузам). Третье совещание (июнь 1984 года) пришло к выводу о том, что "форма" упаковки должна
 являться основным критерием при классификации видов упаковки и что первый знак кода мог бы использоваться в качестве однозначного
 кода для видов упаковок. Коды были распределены по девяти видам груза, девяти видам упаковки (расположенным в порядке убывания в
 соответствии с частотой использования) и восьми видам материала упаковки. На четвертом межсекретариатском совещании (февраль 1985
 года) с учетом полученных замечаний было принято решение о более последовательном использовании критерия "формы" упаковки для
 видов упаковки. Была предложена дальнейшая разбивка видов упаковки в соответствии с "размерами".</p><br>

<p style="text-indent:2em;">11. На последнем совещании (ноябрь 1985 года) был подготовлен проект рекомендации в виде структуризованной системы цифрового
 кодирования грузовых единиц (однозначный код), видов упаковки (однозначный или, как вариант, двузначный код) и материалов
 упаковки (однозначный код). Секретариат ЕЭК Организации Объединенных Наций подготовил дополнительные двузначные буквенные
 коды для обозначения наиболее часто используемых названий упаковок. К словесным описаниям были добавлены графические символы,
 позволяющие визуально представить себе виды упаковок, обозначаемых данными кодами.</p><br>

<p style="text-indent:2em;">12. После проведения дополнительных обстоятельных консультаций на национальном и международном уровнях настоящая Рекомендация
 была принята на двадцать третьей сессии Рабочей группы ЕЭК по упрощению процедур международной торговли в марте 1986 года.</p><br>

<p style="font-weight:500;">II. СФЕРА ДЕЙСТВИЯ</p><br>

<p style="text-indent:2em;">13. Настоящая Рекомендация устанавливает систему цифровых кодов для обозначения видов груза, упаковки и материала упаковки в
 торговле, на транспорте и в других областях деятельности, связанных с международной торговлей. Рекомендация также устанавливает
 дополнительные буквенные коды для обозначения наименований упаковок.</p><br>

<p style="text-indent:2em;">14. На своей тридцать девятой сессии в марте 1994 года Рабочая группа решила принять предложение, представленное делегацией 
Канады в документе TRADE/WP.4/R.895, о включении в Рекомендацию в качестве дополнительного приложения коды для упаковок, используемые
 для перевозки опасных грузов, и внести в нее соответствующие изменения.</p><br>

<p style="font-weight:500;">III. ОБЛАСТЬ ПРИМЕНЕНИЯ</p><br>

<p style="text-indent:2em;">15. Система кодов и коды, предусмотренные в настоящей Рекомендации, предназначаются для использования при обмене данными между
 участниками международной торговли путем применения методов автоматического обмена информацией, а также в других целях, например
 для дополнения или замены словесных описаний, существующих в документах по международной торговле. Где это необходимо и
 желательно, коды могут использоваться и для целей другой экономической деятельности.</p><br>

<p style="font-weight:500;">IV. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</p>

<p style="text-indent:2em;"></p><h6>16. Для цели настоящей Рекомендации применяются следующие определения:</h6><p></p>

<p style="text-indent:2em;"></p><h6>"Груз":</h6><p></p>

<p>Товары, перевозимые на борту судна или другим видом транспорта.</p><br>

<p style="text-indent:2em;">Примечание. Груз может состоять из жидких или твердых материалов или веществ без какой-либо упаковки (например, массовый груз)
 или из разрозненных единиц неупакованного товара; упаковок; укрупненных грузов (на поддонах или в контейнерах); или товаров,
 погруженных на транспортные единицы и перевозимых с помощью активных видов транспорта.</p>

<p style="text-indent:2em;"></p><h6>"Вид груза":</h6><p></p>

<p style="text-indent:2em;">Классификация груза, перевозимого или предназначенного для перевозки на каком-либо транспортном средстве,
 основанная на его общем внешнем виде.</p>

<p style="text-indent:2em;"></p><h6>"Грузовое место":</h6><p></p>

<p style="text-indent:2em;">Конечный продукт упаковочной операции в том виде, в каком он подготовлен для транспортировки и состоит из
 собственно упаковки (резервуар, контейнер) и находящегося в ней груза.</p><br>

<p style="text-indent:2em;">Примечание. Термин "грузовое место" включает все применяемые для упаковки приспособления, и в частности держатели,
 используемые в качестве внешней или внутренней оболочки товаров, держатели, с помощью которых товары накручиваются,
 наматываются или связываются (в отличие от тех, что определены в международных конвенциях), и резервуары. Данный 
 термин исключает средства транспортировки и транспортное оборудование, как-то поддоны и контейнеры.</p>

<p style="text-indent:2em;"></p><h6>"Упаковка":</h6><p></p>

<p style="text-indent:2em;">Материалы и компоненты, используемые при любых упаковочных операциях для упаковки, хранения и защиты готовых
 изделий или материалов во время транспортировки.</p>

<p style="text-indent:2em;"></p><h6>"Вид упаковки":</h6><p></p> 

<p>Форма или конфигурация грузового места в том виде, в каком оно представлено для транспортировки.</p><br>

<p style="font-weight:500;">V. СПРАВОЧНЫЙ МАТЕРИАЛ</p>

<p style="text-indent:2em;"></p><h6>17. При подготовке настоящей Рекомендации
 учитывались следующие международные документы и соглашения:</h6><p></p>

<p style="text-indent:2em;"></p><h6>UN/ECE/TRANS/GE.6/R.36, 1981 год:</h6><p></p> 

<p>Возможности разработки классификации характеристик транспортной обработки грузов в
 соответствии с КЕТС</p>

<p style="text-indent:2em;"></p><h6>Статистическая комиссия ООН:</h6><p></p> 

<p style="text-indent:2em;">Рекомендация о единообразной системе увязки потоков товаров и погрузочных документов
 (двадцатая сессия, 1979 год)</p>

<p style="text-indent:2em;"></p><h6>UNCTAD/SHIP/185/Rev.1:</h6><p></p> 

<p style="text-indent:2em;">Справочник о единообразной системе портовой статистики и показателях производительности,
 1979 год, второе издание, 1983 год</p>

<p style="text-indent:2em;"></p><h6>UN/ECE/FAL/Рекомендация N 19: </h6><p></p>

<p>Классификатор видов транспорта, 1981 год</p>

<p style="text-indent:2em;"></p><h6>UN/ECE/FAL/Рекомендация N 20: </h6><p></p>

<p>Коды для единиц измерения, используемых в международной торговле, 1985 год</p><br>

<p style="text-indent:2em;">Европейская конвенция о таможенном режиме, применяемом к поддонам, используемым в международных перевозках
, Женева, 1956 год</p>

<p style="text-indent:2em;"></p><h6>ОЭСР: </h6><p></p>

<p>Рекомендации по международной стандартизации упаковки фруктов и овощей</p><br>

<p>Таможенная конвенция о временном ввозе упаковочных материалов, Брюссель, 1960 год</p><br>

<p>Таможенная конвенция, касающаяся контейнеров, Женева, 1956 год</p><br>

<p>Таможенная конвенция, касающаяся контейнеров, Женева, 1972 год</p>

<p style="text-indent:2em;"></p><h6>ИСО ТС 122: </h6><p></p>

<p>Упаковка, проект предложения 5988</p>

<p style="text-indent:2em;"></p><h6>ИСО 3676-1983:</h6><p></p>

<p>Упаковка. Размеры грузовой единицы. Габариты</p>

<p style="text-indent:2em;"></p><h6>ИАТА, 1982 год: </h6><p></p>

<p>Коды переработки особых грузов</p><br>

<p>Рекомендации Организации Объединенных Наций по перевозке опасных грузов, ST/SG/AC
.10/1/Rev.8, 1993 год</p><br>

<p>18. В качестве справочного материала используется также</p>

<p style="text-indent:2em;"></p><h6>Справочник элементов внешнеторговых данных ООН (СЭВДООН), в который включены следующие элементы данных,
 относящихся к настоящей Рекомендации:</h6><p></p>

<p>7064 Тип упаковки</p>

<p style="text-indent:2em;"></p><h6>Описание: </h6><p></p>

<p>Описание формы, в которой представлены товары</p><br>

<p>7065 Тип упаковки, кодом</p>

<p style="text-indent:2em;"></p><h6>Представление: </h6><p></p>

<p>n..4; а2</p><br>

<p style="font-weight:500;">VI. СТРУКТУРА И ПРЕДСТАВЛЕНИЕ СИСТЕМЫ КОДОВ И САМИХ КОДОВ</p><br>

<p style="font-weight:500;">A. Структура системы цифрового кода</p>

<p style="text-indent:2em;"></p><h6>19. В настоящей Рекомендации предусматриваются цифровые коды для:</h6><p></p>

<p>a) видов груза (однозначный код) - указывает характеристики транспортной обработки груза;</p><br>

<p style="text-indent:2em;">b) вида упаковки (двузначный код с факультативной второй цифрой) - относится к упаковкам и (если расширить
 значение данного понятия, чтобы охватить им все товары) товарам, перевозимым без упаковки в контейнерах,
 вагонах, судах и т.д.;</p><br>

<p style="text-indent:2em;">c) материала упаковок (однозначный код) - означает вид любого материала (сталь, дерево, ткань, бумага,
 пластмасса и т.д.), используемого для упаковки.</p><br>

<p style="font-weight:500;">B. Факультативные буквенные коды</p><br>

<p style="text-indent:2em;">20. Дополнительные буквенные коды предусмотрены для обозначения наиболее часто встречающихся наименований
 упаковки в торговле и транспорте. Эти наименования перечисляются в Приложениях V и VI в алфавитном порядке
 наименований и буквенных кодов с их состоящими из двух букв обозначениями и соответствующими кодами.</p><br>

<p style="font-weight:500;">C. Представление кодов</p>

<p style="text-indent:2em;"></p><h6>21. В приложениях к настоящей Рекомендации представлены следующие различные коды:</h6><p></p>

<p style="text-indent:2em;"></p><h6>Приложение I: </h6><p></p>

<p>Основная система цифрового однозначного кода</p>

<p style="text-indent:2em;"></p><h6>Приложение II: </h6><p></p>

<p>Однозначный код вида груза: описание с пиктографическими символами</p>

<p style="text-indent:2em;"></p><h6>Приложение III: </h6><p></p>

<p>Таблица кодов вида груза, кодов вида упаковки и кодов материала упаковки</p>

<p style="text-indent:2em;"></p><h6>Приложение IV: </h6><p></p>

<p style="text-indent:2em;">Код вида упаковки: двузначные коды (факультативно однозначные), пиктографические символы,
 описания и общепринятые наименования</p>

<p style="text-indent:2em;"></p><h6>Приложение V: </h6><p></p>

<p style="text-indent:2em;">Кодовые обозначения наименований видов упаковки, используемой в международной торговле (в 
алфавитном порядке наименований)</p>

<p style="text-indent:2em;"></p><h6>Приложение VI: </h6><p></p>

<p style="text-indent:2em;">Кодовые обозначения наименований видов упаковки, используемой в международной торговле (в 
алфавитном порядке буквенных кодов)</p>

<p style="text-indent:2em;"></p><h6>Приложение VII: </h6><p></p>

<p>Код для обозначения видов упаковки, используемой для перевозки опасных грузов.</p><br>

<p style="font-weight:500;">VII. ПРАВИЛА ПРИМЕНЕНИЯ</p><br>

<p style="text-indent:2em;">22. Трехзначные цифровые коды (вид груза, вид упаковки, материал упаковки) могут использоваться независимо 
или комбинированно с одним или двумя другими. Код материала упаковки особенно удобен при пользовании в 
комбинации с кодом вида упаковки.</p><br>

<p>23. Цифровые коды могут использоваться на однозначном уровне (Приложение I).</p>

<p style="text-indent:2em;"></p><h6>24. Каждый код может использоваться для простого 
однозначного применения. При таком применении:</h6><p></p>

<p style="text-indent:2em;">a) код вида груза может использоваться для описания лишь самой наружной видимой оболочки груза во время 
перевозки, которая указывает на соответствующий способ его обработки. (Статистиками в области транспорта 
это применение было обозначено как "первый уровень формы представления");</p><br>

<p style="text-indent:2em;">b) код вида упаковки может использоваться (например, производителем) для описания только "непосредственно 
упаковки или обертки товаров, которые покупатель обычно приобретает вместе с товаром в розничной продаже"; 
точно так же этот код может быть использован (например, экспортером или грузоотправителем) для описания лишь 
"самой наружной упаковки или обертки товаров, которую импортер, оптовый или розничный покупатель обычно 
приобретает вместе с товаром";</p><br>

<p style="text-indent:2em;">c) код материала упаковки может применяться для обозначения лишь материала, обычно используемого для такой 
упаковки, которая должна описываться в соответствии с кодом вида упаковки.</p><br>

<p style="text-indent:2em;">25. Коды для вида груза и вида упаковки могут применяться в комбинации с другими кодами, такими, как код вида 
транспорта (ЕЭК ООН Рекомендация N 19).</p><br>

<p style="text-indent:2em;">26. Коды для видов упаковки (на однозначном уровне) и наименований упаковки (двухбуквенный код) могут 
использоваться в комбинации с элементами данных, устанавливающими единицу измерения, для указания точных 
размеров упаковки, например "5KGM", "25KGM" или "50KGM" - для упаковки для сухих грузов, или "70CLT", "1LTR", 
"5LTR" - для упаковки для жидких грузов (ЕЭК ООН Рекомендация N 20).</p><br>

<p style="text-indent:2em;">27. В другом случае коды вида упаковки могут использоваться на двузначном уровне. Двузначный код вида 
упаковки имеет иерархическую структуру: первая цифра обозначает прежде всего форму упаковки, а (факультативная) 
вторая, главным образом, - размер упаковок одинаковой формы.</p><br>

<p style="text-indent:2em;">28. Система цифрового кода носит общий характер и охватывает все существующие и все возможные виды груза, 
упаковок и материалов упаковок на однозначном и двузначном уровнях.</p><br>

<p style="text-indent:2em;">29. В качестве альтернативы могут использоваться коды наименований упаковки. Эти дополнительные двухбуквенные 
коды охватывают существующие в настоящее время и наиболее часто употребляемые наименования упаковок на английском, 
французском и русском языках. В ходе процедуры обновления могут появиться новые наименования упаковок и коды.</p><br>

<p style="font-weight:500;">Правила расширенного применения</p>

<p style="text-indent:2em;"></p><h6>30. Каждый код посредством увеличения числа знаков может использоваться для более сложного многозначного 
применения. В этом случае несколько знаков для каждого кода (цифрового или буквенного) могут использоваться 
одновременно в качестве сгруппированных элементов данных (соответствующих нескольким уровням перевозимых 
грузовых единиц или нескольким уровням отгруженных упаковок, одновременно помещенных одна в другую), для того 
чтобы:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) код вида груза мог использоваться для последовательного обозначения двух, трех и более уровней груза начиная 
с самого наружного груза; например, грузовик с контейнером, "содержащим" мешки с кофе на поддонах, кодируется:</h6><p></p>
 
<p>6, 2, 4, 9;</p>

<p style="text-indent:2em;"></p><h6>b) код вида упаковки мог использоваться для последовательного описания двух, трех и более уровней упаковки 
начиная с самой наружной упаковки; например, большая коробка, содержащая картонную коробку с кулями чая или 
пакетики с чаем, кодируется:</h6><p></p>

<p>2, 2, 6 (в однозначном коде) или</p><br>

<p>24, 22, 61 (в двузначном коде), или</p><br>

<p>BX, CN, SA (в двухбуквенном коде);</p><br>

<p style="text-indent:2em;">c) код материала упаковки мог использоваться для последовательного описания и в том же порядке материала(ов), 
используемого(ых) для каждых двух, трех или более уровней упаковки, которые должны описываться в соответствии 
с кодом вида упаковки.</p><br>

<p style="font-weight:500;">VIII. ВЫБОР МЕЖДУ ЦИФРОВЫМИ И БУКВЕННЫМИ КОДАМИ</p><br>

<p style="text-indent:2em;">31. Пользователи могут выбирать между структуризованными цифровыми кодами и буквенными кодами. Цифровые коды 
могут оказаться более предпочтительными для АОД, поскольку они имеют соответствующую структуру, в то время как 
буквенные коды дают больше возможностей в плане комбинирования. В торговых документах виды упаковки описываются 
главным образом для того, чтобы можно было идентифицировать грузы при перевозке и обработке во время транспортных 
операций, а также для целей пограничного контроля; в этой связи часто предпочтение отдается коротким буквенным кодам, 
поскольку их легче запомнить, особенно в тех случаях, когда они мнемонически связаны с наименованием вида 
упаковки.</p>

<p style="text-indent:2em;"></p><h6>32. При выборе системы кодирования предприниматели могли бы пользоваться следующими ориентирами:</h6><p></p>

<p style="text-indent:2em;">Существует ли de jure обязательная система кодирования, которая должна использоваться в зависимости 
от характера груза?</p><br>

<p>Существует ли de facto обязательная система кодирования для видов транспорта?</p><br>

<p>Какие коды требуют органы пограничного контроля при смене вида транспорта?</p><br>

<p>Поставляются ли грузы какому-либо клиенту в стране, где практически не используется латинский 
алфавит?</p><br>

<p style="text-indent:2em;">Каким кодом отдает предпочтение торговый партнер, используя их в своей (компьютеризованной) системе 
административного управления?</p><br>

<p style="font-weight:500;">IX. ПОЛОЖЕНИЯ, КАСАЮЩИЕСЯ ОБНОВЛЕНИЯ ДАННЫХ</p><br>

<p style="text-indent:2em;">33. Предложения об обновлении прилагаемых к настоящей Рекомендации списков кодов должны направляться 
Рабочей группе через Отдел торговли ЕЭК или Специальную программу ЮНКТАД по упрощению торговых процедур 
и документации (ФАЛПРО). Рабочая группа рассмотрит эти предложения на одной из своих очередных сессий.</p><br>

<p style="text-indent:2em;">34. Если будет принято решение внести какие-либо изменения в список кодов, Секретариат ЕЭК выпустит 
дополнение с указанием внесенных поправок или сам пересмотренный перечень кодов.</p><br>

<p><a href="https://www.transportir.ru/mezhdunarodnoe-transportnoe-pravo/kody-dlya-vidov-gruza-upakovki-i-materiala-upakovki.html">Источник</a></p>

</div>
`