import window from 'global/window'

import {
  SOCKET_LOGIN_STATUS,
  SOCKET_NEW_LOGIN_ATTEMPT,
  SOCKET_NEW_LOGIN_ATTEMPT_ALLOW,
  SOCKET_NEW_LOGIN_ATTEMPT_DENY,
  SOCKET_LOGIN_ATTEMPT_RESET,
  SOCKET_LOGIN_ALLOWED,
  SOCKET_LOGIN_DENIED,
  SOCKET_GOT_NEW_CARGO_ENTRY,
  SOCKET_REFRESH_SEARCH_FINISH,
  SET_INITIAL_MESSENGER_STATE,
  SET_INITIAL_CHAT_STATE,
  SET_CHAT_PARTENER_ID,
  SOCKET_NEW_MESSAGE,
  SOCKET_NEW_MESSAGE_ARRIVED,
  CHAT_MARK_MESSAGES_AS_READ,
  GET_CONVERSATION_LIST,
  GET_CONVERSATION_LIST_ARRIVED,
  SOCKET_LOGOUT_FROM_ALL_DEVICES,
  CONVERSATION_SCROLL_TO_BOTTOM
} from '../actions/action-types'

import {
  SOCKET_NEW_LOGIN_ATTEMPT_THRESHOLD,
  LOGIN_PERMISSION_STATUS_ALLOWED,
  LOGIN_PERMISSION_STATUS_DENIED,
} from '../constants'
import { SocketState } from './types'

const initialState: SocketState = {
  loginAttempt: {
    status: undefined,
    openAskPermission: false,
    active: false,
    autoResolveIn: SOCKET_NEW_LOGIN_ATTEMPT_THRESHOLD,
    payload: undefined,
    permissionStatus: undefined,
    remoteAddresses: [
    ],
  },
  searchRefresh: false,
  unreadMsgs: [],
  myConversationList: [],
  chatMsgs: [],
  chatPartnerId: 0,
  logoutFromAllDevices: false,
  needToScrollInMiniMessenger: false,
}

export default (state = initialState, { type, payload }: any = {}) => {

  switch (type) {
    case SOCKET_LOGIN_STATUS: {
      window.socket.emit(type, payload)
      return state
    }
    case SOCKET_LOGOUT_FROM_ALL_DEVICES: {
      return {
        ...state,
        logoutFromAllDevices: !state.logoutFromAllDevices
      }
    }
    case SOCKET_NEW_LOGIN_ATTEMPT: {
      const loginAttempt = {
        ...state.loginAttempt,
        status: type,
        openAskPermission: true,
        payload,
        permissionStatus: undefined,
      }

      const sound = new Audio('http://localhost:7000/api/common/file/audio/warning-sound.mp3')

      sound.play()
      return { ...state, loginAttempt }
    }
    case SOCKET_GOT_NEW_CARGO_ENTRY: {
      if (!document.hasFocus()) {
        const sound = new Audio('http://localhost:7000/api/common/file/audio/warning-sound.mp3')

        sound.play()

        return { ...state, searchRefresh: true }
      }

      return state
    }
    case SOCKET_REFRESH_SEARCH_FINISH: {
      return { ...state, searchRefresh: false }
    }
    case SOCKET_NEW_LOGIN_ATTEMPT_DENY:
    case SOCKET_NEW_LOGIN_ATTEMPT_ALLOW: {
      const loginAttempt = {
        ...state.loginAttempt,
        openAskPermission: false,
        payload,
      }
      window.socket.emit(type, payload)

      return { ...state, loginAttempt }
    }
    case SOCKET_LOGIN_ALLOWED: {
      const loginAttempt = {
        ...state.loginAttempt,
        permissionStatus: LOGIN_PERMISSION_STATUS_ALLOWED,
        payload,
      }

      return { ...state, loginAttempt }
    }
    case SOCKET_LOGIN_DENIED: {
      const loginAttempt = {
        ...state.loginAttempt,
        permissionStatus: LOGIN_PERMISSION_STATUS_DENIED,
        payload,
      }

      return { ...state, loginAttempt }
    }
    case SOCKET_LOGIN_ATTEMPT_RESET: {
      return { ...initialState }
    }

    case SOCKET_NEW_MESSAGE: {
      window.socket.emit(type, payload)

      return state
    }

    case GET_CONVERSATION_LIST: {
      window.socket.emit(type, payload)
      return state
    }

    case GET_CONVERSATION_LIST_ARRIVED: {

      return {
        ...state,
        myConversationList: payload,
      }
    }

    case CONVERSATION_SCROLL_TO_BOTTOM: {
      return {
        ...state,
        needToScrollInMiniMessenger: false,
      }
    }

    case SOCKET_NEW_MESSAGE_ARRIVED: {
      const unread = state.unreadMsgs
      const currentChatMsgs = state.chatMsgs

      window.socket.emit(GET_CONVERSATION_LIST, payload.chatPartnerId)
      window.socket.emit(GET_CONVERSATION_LIST, payload.userId)

      if (payload.userId === payload.receivedUserId) {
        unread.push(payload)
      }

      const sendInActiveConv = Math.abs(state.chatPartnerId) === Math.abs(payload.receivedUserId) || Math.abs(state.chatPartnerId) === Math.abs(payload.sendUserId)
      const currentActiveTwoUsers = payload.userId == payload.receivedUserId || payload.userId == payload.sendUserId

      if (sendInActiveConv && currentActiveTwoUsers) {
        currentChatMsgs.push(payload)
      }

      return {
        ...state,
        unreadMsgs: unread,
        chatMsgs: currentChatMsgs, 
        needToScrollInMiniMessenger: true,
      }
    }

    case SET_INITIAL_MESSENGER_STATE:
      return {
        ...state,
        unreadMsgs: payload
      }

    case CHAT_MARK_MESSAGES_AS_READ: {
      const msgsIds: any[] = payload ? payload.map(item => item.messageId) : [];

      if (msgsIds.length > 0) {
        return {
          ...state,
          unreadMsgs: state.unreadMsgs.filter(item => !msgsIds.includes(item.messageId))
        }
      } else {
        return { ...state }
      }
    }

    case SET_INITIAL_CHAT_STATE:
      return {
        ...state,
        chatMsgs: payload
      }

    case SET_CHAT_PARTENER_ID:
      return {
        ...state,
        chatPartnerId: payload,
      }

    default:
      return state
  }
}
