import { connect } from 'react-redux'
import { compose } from 'recompose'
import withTranslation from '../../../../globals/components/withTranslation'
import Component from './component'
import {
  allowLoginOnAnotherDevice,
  denyLoginOnAnotherDevice,
} from '../../../../store/actions/socket'

export default compose(
  withTranslation,
  connect(
    state => {
      const loginAttempt = state.socket.loginAttempt
      const { openAskPermission, payload, autoResolveIn } = loginAttempt
      return {
        isOpen: openAskPermission,
        autoResolveIn,
        payload,
      }
    },
    {
      allowLoginOnAnotherDevice,
      denyLoginOnAnotherDevice,
    }
  )
)(Component)
