import React, { Component } from 'react'
import Widget from '../../shared/components/widget'
import News from '../../news/list-mini'
import Ads from '../../ads/list-mini'
import Forum from '../../forum'
import pass from '../../../images/pass.png'
import pass2 from '../../../images/pass2.png'

import "./styles.scss"

export default class extends Component {
  render() {
    const { t } = this.props

    return (
      <div className="row">
        <div className="col-md-10">
          <Widget title={t("about.join.in.company")}>

            <div
              dangerouslySetInnerHTML={{
                __html: t('company.join.in.company')
              }} />


            <div className="join-img-container">
              <img src={pass} alt="banner" />
              <img src={pass2} alt="banner" />

            </div>
            <div className="panel-content-row join-verification-company">
              <div
                dangerouslySetInnerHTML={{
                  __html: t('company.join.in.company.bottom')
                }} />
            </div>
          </Widget>
        </div>
        <div className="col-md-6">
          <News />
          <Ads />
          <Forum />
        </div>
      </div>
    )
  }
}