export const int11 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Acordul european privind marile căi navigabile de importanță internațională (A.G.N.) din 19.01.1996 *</h4><p></p>

<p>Părțile contractante,</p><br>

<p>conștiente de necesitatea facilitării și dezvoltării transportului internațional pe căi navigabile în Europa,</p><br>

<p style="text-indent:2em;">cunoscând că se așteaptă o creștere a transporturilor internaționale de mărfuri ca rezultat al dezvoltării comerțului internațional,</p><br>

<p style="text-indent:2em;">evidențiind rolul transportului pe căile navigabile care, în comparație cu alte moduri de transport interior, prezintă avantaje economice și ecologice
și dispune de o capacitate excedentară în ceea ce privește infrastructura și navele, fiind astfel în măsură să reducă costurile sociale și impactul
 negativ asupra mediului ale transporturilor interioare în ansamblul lor,</p><br>

<p style="text-indent:2em;">convinse că, pentru a face transportul internațional pe căile navigabile în Europa mai eficace și mai atractiv pentru clienți, este esențială 
constituirea unui cadru juridic care să stabilească un plan coordonat de dezvoltare și de construire a unei rețele de căi navigabile de importanță
 internațională, pe baza parametrilor conveniți pentru infrastructură și exploatare,</p>

<p style="text-indent:2em;"></p><h6>au convenit următoarele:</h6><p></p>

<p style="font-weight:500;">ARTICOLUL 1 Desemnarea rețelei</p><br>

<p style="text-indent:2em;">Părțile contractante adoptă dispozițiile prezentului acord sub forma unui plan coordonat de dezvoltare și de construcție a unei rețele de căi 
navigabile, denumită în continuare rețeaua de căi navigabile de importanță internațională sau rețeaua de căi navigabile E, pe care intenționează
 să o realizeze în cadrul programelor lor corespunzătoare. Rețeaua de căi navigabile E este constituită din căile navigabile și porturile de 
 importanță internațională, care sunt menționate în anexele nr. I și II la prezentul acord.</p><br>

<p style="font-weight:500;">ARTICOLUL 2 Caracteristicile tehnice și de exploatare ale rețelei</p><br>

<p style="text-indent:2em;">Rețeaua căilor navigabile de importanță internațională menționată în primul articol va fi conform caracteristicilor enunțate în anexa nr. III 
la prezentul acord sau va fi aliniată la dispozițiile anexei menționate în urma unor lucrări de ameliorare viitoare.</p><br>

<p style="font-weight:500;">ARTICOLUL 3 Anexele</p><br>

<p>Anexele la prezentul acord fac parte integrantă din acord.</p><br>

<p style="font-weight:500;">ARTICOLUL 4 Desemnarea depozitarului</p><br>

<p>Secretarul general al Organizației Națiunilor Unite este depozitarul acordului.</p><br>

<p style="font-weight:500;">ARTICOLUL 5 Semnarea</p><br>

<p style="text-indent:2em;">1. Prezentul acord va fi deschis spre semnare la Oficiul Națiunilor Unite de la Geneva statelor care sunt membre ale Comisiei Economice pentru 
Europa a Organizației Națiunilor Unite sau care au statut consultativ pe lângă aceasta, conform paragrafelor 8 și 11 din Termenii de referință 
ai Comisiei Economice pentru Europa a Organizației Națiunilor Unite, de la 1 octombrie 1996 până la 30 septembrie 1997.</p><br>

<p>2. Aceste semnături vor fi supuse ratificării, acceptării sau aprobării.</p><br>

<p style="font-weight:500;">ARTICOLUL 6 Ratificare, acceptare sau aprobare</p><br>

<p>1. Prezentul acord este supus ratificării, acceptării sau aprobării, conform paragrafului 2 al art. 5.</p><br>

<p style="text-indent:2em;">2. Ratificarea, acceptarea sau aprobarea își va produce efectele prin depunerea unui instrument la secretarul general al Organizației 
Națiunilor Unite.</p><br>

<p style="font-weight:500;">ARTICOLUL 7 Aderare</p><br>

<p style="text-indent:2em;">1. Prezentul acord va fi deschis spre aderare oricărui stat vizat la paragraful
 1 al art. 5, începând cu data de 1 octombrie 1996.</p><br>

<p style="text-indent:2em;">2. Aderarea își va produce efectele prin depunerea unui instrument de aderare
 la secretarul general al Organizației Națiunilor Unite.</p><br>

<p style="font-weight:500;">ARTICOLUL 8 Intrarea în vigoare</p><br>

<p style="text-indent:2em;">1. Prezentul acord va intra în vigoare la 90 de zile de la data la care guvernele a cinci state vor fi depus un instrument de ratificare, 
de acceptare, de aprobare sau de aderare, cu condiția ca una sau mai multe căi navigabile de importanță internațională să lege în mod 
neîntrerupt cel puțin trei dintre aceste state.</p><br>

<p style="text-indent:2em;">2. Dacă această condiție nu este îndeplinită, acordul va intra în vigoare la 90 de zile de la data depunerii instrumentului de ratificare, 
de acceptare, de aprobare sau de aderare, care va fi permis satisfacerea acestei condiții.</p><br>

<p style="text-indent:2em;">3. Pentru fiecare stat care va depune un instrument de ratificare, de acceptare, de aprobare sau de aderare de la începutul perioadei de 90 
de zile specificate la paragrafele 1 și 2 ale prezentului articol, acordul va intra în vigoare la 90 de zile de la data depunerii 
instrumentului respectiv.</p><br>

<p style="font-weight:500;">ARTICOLUL 9 Limitele de aplicare a acordului</p><br>

<p style="text-indent:2em;">1. Nici o dispoziție a prezentului acord nu va fi interpretată ca interzicând unei părți contractante să ia măsurile, compatibile cu dispozițiile 
Cartei Națiunilor Unite și limitate la exigențele situației, pe care le consideră necesare
 pentru securitatea sa interioară sau exterioară.</p><br>

<p>2. Aceste măsuri, care trebuie să fie temporare, sunt imediat notificate depozitarului;
 trebuie să se precizeze natura lor.</p><br>

<p style="font-weight:500;">ARTICOLUL 10 Rezolvarea diferendelor</p><br>

<p style="text-indent:2em;">1. Orice diferend între două sau mai multe părți contractante referitor la interpretarea sau la aplicarea prezentului acord, pe care părțile în 
litigiu nu l-au putut rezolva pe calea negocierilor sau în alt mod, este supus arbitrajului dacă oricare dintre părțile în litigiu o cere și, în 
acest scop, va fi supus pentru analizare și decizie unuia sau mai multor arbitri aleși
 de comun acord de părțile în litigiu.</p><br>

<p style="text-indent:2em;">Dacă în decurs de 3 luni de la data solicitării arbitrajului părțile în litigiu nu ajung la o înțelegere asupra alegerii unui arbitru sau a unor 
arbitri, oricare din aceste părți poate să solicite secretarului general al Organizației Națiunilor Unite să desemneze un arbitru unic căruia îi 
este supus diferendul pentru decizie.</p><br>

<p style="text-indent:2em;">2. Sentința arbitrului sau a arbitrilor desemnați conform paragrafului 1 al acestui articol va avea caracter obligatoriu pentru părțile 
contractante în litigiu.</p><br>

<p style="font-weight:500;">ARTICOLUL 11 Rezerve</p><br>

<p style="text-indent:2em;">Orice stat poate, în momenul în care semnează prezentul acord sau își depune instrumentul de ratificare, de acceptare, de aprobare sau de aderare, 
să notifice depozitarului că nu se consideră legat prin art. 10 din prezentul acord.</p><br>

<p style="font-weight:500;">ARTICOLUL 12 Procedura de amendare a prezentului acord</p><br>

<p>1. Prezentul acord poate fi amendat conform procedurii definite în prezentul articol,
 sub rezerva dispozițiilor art. 13 și 14.</p><br>

<p style="text-indent:2em;">2. La solicitarea unei părți contractante, orice amendament la prezentul acord va fi analizat de grupul de lucru principal pentru transporturi 
pe căi navigabile al Comisiei Economice pentru Europa a Organizației Națiunilor Unite.</p><br>

<p style="text-indent:2em;">3. În cazul în care este adoptat cu o majoritate de două treimi din numărul părților contractante prezente și votante, amendamentul va fi comunicat 
de către secretarul general al Organizației Națiunilor Unite pentru acceptare tuturor părților contractante.</p><br>

<p style="text-indent:2em;">4. Orice propunere de amendament, care a fost comunicată conform dispozițiilor paragrafului 3 al prezentului articol, va intra în vigoare pentru 
toate părțile contractante la 3 luni de la expirarea unei perioade de 12 luni de la data comunicării sale, cu condiția ca în această perioadă de 
12 luni să nu fi fost notificată secretarului general al Organizației Națiunilor Unite nici o obiecție la amendamentul propus de către un stat 
care este parte contractantă.</p><br>

<p style="text-indent:2em;">5. Dacă a fost notificată o obiecție la amendamentul propus, conform dispozițiilor paragrafului 4 al prezentului articol, amendamentul este 
considerat respins și lipsit de efect.</p><br>

<p style="font-weight:500;">ARTICOLUL 13 Procedura de amendare a anexelor nr. I și II</p><br>

<p>1. Anexele nr. I și II la prezentul acord pot fi amendate în conformitate cu
 procedura definită în prezentul articol.</p><br>

<p style="text-indent:2em;">2. La solicitarea unei părți contractante, orice amendament propus la anexele nr. I și II de către această parte va fi examinat de grupul de 
lucru principal pentru transporturi pe căi navigabile al Comisiei Economice pentru
 Europa a Organizației Națiunilor Unite.</p><br>

<p style="text-indent:2em;">3. Dacă amendamentul propus este adoptat de majoritatea părților contractante prezente și votante, acesta va fi comunicat pentru acceptare 
părților contractante direct interesate de către secretarul general al Organizației Națiunilor Unite. În sensul prezentului articol, o parte 
contractantă va fi considerată direct interesată dacă, în cazul includerii unei noi căi navigabile sau a unui port de importanță internațională 
sau, în cazul modificării acestora, teritoriul său este traversat de către această cale navigabilă sau portul în cauză este situat pe teritoriul 
menționat.</p><br>

<p style="text-indent:2em;">4. Orice amendament propus, comunicat conform dispozițiilor paragrafelor 2 și 3 ale prezentului articol, va fi considerat acceptat dacă, 
într-o perioadă de 6 luni de la data comunicării sale de către depozitar, nici una dintre părțile contractante direct interesate nu a notificat 
secretarului general al Organizației Națiunilor Unite obiecții la amendamentul propus.</p><br>

<p style="text-indent:2em;">5. Orice amendament astfel acceptat va fi comunicat de către secretarul general al Organizației Națiunilor Unite tuturor părților contractante 
și va intra în vigoare la 3 luni de la data comunicării sale de către depozitar.</p><br>

<p style="text-indent:2em;">6. Dacă o obiecție la amendamentul propus a fost notificată, conform paragrafului 4 al prezentului articol, amendamentul va fi considerat 
respins și lipsit de efect.</p><br>

<p style="text-indent:2em;">7. Depozitarul va fi informat fără întârziere de către Secretariatul Comisiei Economice pentru Europa a Organizației Națiunilor Unite asupra
 părților contractante care sunt direct interesate de o propunere de amendament.</p><br>

<p style="font-weight:500;">ARTICOLUL 14 Procedura de amendare a anexei nr. III</p><br>

<p>1. Anexa nr. III la prezentul acord poate fi amendată conform procedurii definite în prezentul articol.</p><br>

<p style="text-indent:2em;">2. La cererea uneia dintre părțile contractante, orice amendament propus de către aceasta la anexa nr. III la prezentul acord va fi 
examinat de grupul principal de lucru pentru transporturi pe căi navigabile al Comisiei Economice pentru Europa a Organizației 
Națiunilor Unite.</p><br>

<p style="text-indent:2em;">3. Dacă amendamentul propus este adoptat de către majoritatea părților contractante prezente și votante, acesta va fi comunicat spre 
acceptare tuturor părților contractante de către secretarul general al Organizației Națiunilor Unite.</p><br>

<p style="text-indent:2em;">4. Orice amendament propus, comunicat conform dispozițiilor paragrafului 3 al prezentului articol, este considerat acceptat, cu excepția 
cazului în care, într-o perioadă de 6 luni de la data comunicării sale, o cincime sau mai mult din părțile contractante au notificat 
secretarului general al Organizației Națiunilor Unite obiecțiile lor la această propunere de amendament.</p><br>

<p style="text-indent:2em;">5. Orice amendament acceptat conform paragrafului 4 al prezentului articol va fi comunicat de către secretarul general al Organizației 
Națiunilor Unite tuturor părților contractante și va intra în vigoare la 3 luni de la data comunicării sale pentru toate părțile contractante, 
cu excepția celor care, înainte de data intrării în vigoare, au notificat deja secretarului general al Organizației Națiunilor Unite obiecțiile lor 
la amendamentul propus într-un termen de 6 luni de la data comunicării sale, conform paragrafului 4
 al prezentului articol.</p><br>

<p style="text-indent:2em;">6. Dacă o cincime sau mai mult din părțile contractante au notificat o obiecție la amendamentul propus, conform paragrafului 4 al prezentului 
articol, amendamentul va fi considerat respins și lipsit de efect.</p><br>

<p style="font-weight:500;">ARTICOLUL 15 Denunțarea</p><br>

<p style="text-indent:2em;">1. Orice parte contractantă poate denunța acest acord printr-o notificare scrisă, adresată secretarului general al Organizației Națiunilor
 Unite.</p><br>

<p style="text-indent:2em;">2. Denunțarea intră în vigoare la un an de la data primirii notificării
 de către secretarul general al Organizației Națiunilor Unite.</p><br>

<p style="font-weight:500;">ARTICOLUL 16 Încetarea</p><br>

<p style="text-indent:2em;">Dacă, după intrarea în vigoare a acestui acord, numărul statelor care sunt părți contractante se reduce la mai puțin de cinci (5) într-un interval 
de 12 luni consecutive, prezentul acord își va înceta valabilitatea la 12 luni de la data la care cel de-al cincilea stat a încetat să mai fie parte
 contractantă.</p><br>

<p style="font-weight:500;">ARTICOLUL 17 Notificări și comunicări ale depozitarului</p><br>

<p style="text-indent:2em;">În afara notificărilor și comunicărilor menționate în prezentul acord, funcțiile de depozitar ale secretarului general al Organizației Națiunilor 
Unite sunt cele definite în partea a VII-a a Convenției de la Viena privind dreptul
 tratatelor, încheiată la 23 mai 1969.</p><br>

<p style="font-weight:500;">ARTICOLUL 18 Texte autentice</p><br>

<p style="text-indent:2em;">Originalul acestui acord, redactat în limbile engleză, franceză și rusă, toate textele fiind egal autentice, va fi depozitat pe lângă 
secretarul general al Organizației Națiunilor Unite.</p><br>

<p>Drept pentru care, subsemnații, având depline puteri, au semnat prezentul acord.</p><br>

<p>Încheiat la Geneva la 19 ianuarie 1996.</p>

<p></p><h4>ANEXA Nr. I</h4><p></p>

<p></p><h4>CĂI NAVIGABILE DE IMPORTANȚĂ INTERNAȚIONALĂ</h4><p></p>

<p style="font-weight:500;">Numerotarea căilor navigabile de importanță internațională</p><br>

<p style="text-indent:2em;">1. Toate căile navigabile de importanță internațională (căi navigabile E) sunt identificate prin litera E, urmată de un număr format 
din două, patru sau șase cifre.</p><br>

<p style="text-indent:2em;">2. Arterele principale ale rețelei de căi navigabile E au numere formate din două cifre, în timp ce ramurile lor primare și secundare 
au numere formate din patru, respectiv șase cifre.</p><br>

<p style="text-indent:2em;">3. Arterele navigabile principale sensibil orientate nordsud, care deservesc porturi maritime și leagă între ele bazine maritime, au 
numerele 10, 20, 30, 40 și 50, în ordine crescătoare de la vest la est.</p><br>

<p style="text-indent:2em;">4. Arterele navigabile principale sensibil orientate vestest, care intersectează cel puțin trei artere principale, așa cum sunt menționate 
la paragraful 3 anterior, au numerele 60, 70, 80 și 90, în ordine crescătoare de la nord la sud.</p><br>

<p style="text-indent:2em;">5. Celelalte căi navigabile principale au numere formate din două cifre, cuprinse între numerele a două artere principale, așa cum au 
fost definite la paragrafele 3 și 4 de mai sus, între care sunt situate.</p><br>

<p style="text-indent:2em;">6. În cazul ramurilor primare sau secundare, primele două sau patru cifre corespund arterelor de rang mai mare ale rețelei de căi 
navigabile, celelalte două cifre corespund numărului ramurii în cauză, în ordine crescătoare de la începutul până la capătul arterei
 de rang mai mare, conform tabelului de mai jos. Numerele pare sunt utilizate pentru ramurile situate pe partea dreaptă, iar numerele
 impare, pentru cele situate pe partea stângă.</p><br>

<p style="font-weight:500;">Lista căilor navigabile de importanță internațională</p><br>

<p style="font-weight:500;">NUMĂRUL CĂII NAVIGABILE E</p><br>

<p>Artere principale	Alte căi navigabile principale	Ramuri	DESCRIEREA PARCURSULUI*)</p><br>

<p>1	2	3	4</p><br>

<p style="text-indent:2em;">E 01		Dunkerque-Douai-Valenciennes-Conde-Pommeroeul-Mons-Charleroi-Namur-Liege-Maastricht, Moerdijk până la Roterdam/Europoort 
prin râurile Meuse, Kiel, Noord și Nieuwe Maas</p><br>

<p>E 01-02	Meuse de la Namur la Givet</p><br>

<p>E 01-04	Canal Liege-Vise</p><br>

<p>E 01-04-01	Canal de Monsin</p><br>

<p>E 01-01	Kwaadmechelen-Dessel-Bocholt-Nederweert-Wessem-canal Meuse</p><br>

<p>E 01-06	Canal St. Andries</p><br>

<p>E 01-03	Zuid-Willemsvaart de la Maas până la Hertogenbosch</p><br>

<p>E 02 Zeebrugge-Brugge-Deinze-Kortrijk-Lille-Bauvin</p><br>

<p>E 02-02	Canalul Brugge-Ostende</p><br>

<p>E 02-02-01	Canalul Plassendale-Nieupoort</p><br>

<p>E 02-04	Canalul Leie-Roeselare</p><br>

<p>E 03 Gorinchem-Moerdijk-Terneuzen-Gent-prin Nieuwe Merwede, legătura Terneuzen-Gent și Canalul circular Gent</p><br>

<p style="text-indent:2em;">E 04 Vlissingen-Antwerpen-Rupel-Bruxelles-Seneffe prin Westerschelde, Boven-Zeeschelde, Rupel, Canalul Bruxelles-Rupel 
și CanalulCharleroi-Bruxelles</p><br>

<p style="text-indent:2em;">E 05 [Compiegne-Escaut]-Valenciennes-Conde-Tunai-Gent-Dendermode-Antwerpen-Hasselt-Genk-Liege prin Oise, 
legătura Seine-Nord, Hault, Escaut, Bovenschelde, Canalul circular Gent, Boven-Zeeschelde și Albert</p><br>

<p>E 05-02	Peronnes-Pommeroeul prin Canalul Nimy-Blaton-Personnes</p><br>

<p>E 05-01	Canalul Bossuit-Kortrijk</p><br>

<p>E 05-04	Blaton-Ath-Aalst-Dendermonde prin Canalul Blaton-Ath și Dender</p><br>

<p>E 05-06	Viersel-Duffel - gura râului Rupel prin Netekanaal, Beneden-Nete și Rupel</p><br>

<p>E 06 Antwerpen-Schelde-conexiune-Rhin</p><br>

<p>E 07 Canalul circular Gent-Merendree-Eeklo, prin Canalul Gent-Oostende-[Maldegem-Zeebrugge]</p><br>

<p style="text-indent:2em;">E 10 Roterdam/Europoort-Lobith prin Oude Maas, Merwede și Waal, Rhin [Niffer, Mulhouse, 
Besancon-St. Symphorien]-Lyon-Marseille-Fos</p><br>

<p>E 10-01	Canalul Wesel-Datteln, Canalul Datteln-Hamm</p><br>

<p>E 10-03	Canalul Rin-Herne</p><br>

<p>E 10-05	Ruhr</p><br>

<p>E 10-07	Neckar în aval de Plochingen</p><br>

<p>E 10-09	Rhinul de la Niffer la Rheinfelden</p><br>

<p>E 10-02	[Saone-Moselle]</p><br>

<p>E 10-04	Legătura Ron-Sete</p><br>

<p>E 10-06	Rhone și canalul St. Louis: Barcarain-Fos</p><br>

<p>E 11 Ijmuiden-Tiel prin canalul Mării Nordului și canalul Amsterdam-Rin</p><br>

<p>E 11-01	Zaan</p><br>

<p>E 12 Heumen-Nijmegen-Arnhem-Zwolle-Marea Wadden prin Canalul Maas Waal, Waal, Nederrrijn, UJssel, IJssellmeer</p><br>

<p>E 12-02	Zwolle-Meppel prin Zwarte Water și Meppeldiep</p><br>

<p>E 12-04	Ketelmeer-Zwartsluis prin Ramsdiep</p><br>

<p>E 13 Marea Nordului-Emden-Dortmund prin Ems și Canalul Dortmund-Ems</p><br>

<p>E 14 Weser de la Marea Nordului la Minden prin Bremerhaven și Breme</p><br>

<p style="text-indent:2em;">E 15 Amsterdam-Lemmer-Groningen-Delfzijl-Emden-Dorpen-Oldenburg-Elsfleth prin Ijsselmeer, Canalul Prinses Margariet,
 Canalul Van Starkenbourg, Canalul Ems, Ems, Canalul Dortmund-Ems, Canalul Kusten și Hunte</p><br>

<p>E 15-01	Canal van Harinxma de la Fonejacht la Harlingen</p><br>

<p>E 20 Elba de la Marea Nordului prin Hambourg, Magdebourg, Usti-nad-Labem, Melnik și Pardubice-[legătura Elba-Dunăre]</p><br>

<p>E 20-02	Canal lateral al Elbei</p><br>

<p>E 20-04	Saale până la Leipzig</p><br>

<p>E 20-06	Vltava: Melnik-Praga-Slapy</p><br>

<p>E 21 Trave de la Marea Baltică la Elba prin canalul Elba-Lubeck</p><br>

<p>E 30 Swinoujscie-Szczecin-Oder de la Szczecin la Kozle prin Wroclaw [legătura Oder-Dunăre)</p><br>

<p>E 30-01	Canal Gliwice</p><br>

<p>E 31 Szczecin-Westoder-Hohensaat calea navigabilă Friedrichsthaler</p><br>

<p>E 40 [Vistula de la Gdansk la Varșovia-Brest]-Pinsk-Nipru până la Herson prin Kiev</p><br>

<p>E 40-02	Pivdenny Buh până la Nikolaiev</p><br>

<p>E 41 Klaipeda-Golful Kurshskiy-Nemunas-Kaunas</p><br>

<p style="text-indent:2em;">E 50 Saint-Petersburg, prin calea navigabilă Volga-Baltică până la Vytegra-Rybinsk-Volga de
 la Rybinsk la Astrakhan prin Nijny Novgorod, Kazan și Volvograd</p><br>

<p>E 50-02	Rybinsk-Moscova</p><br>

<p>E 50-02-02	Volga de la Dubna la Tver</p><br>

<p>E 50-01	Kama de la vărsare la Solikamsk</p><br>

<p style="text-indent:2em;">E 60 Parcurs costier de la Gibraltar spre nord urmând litoralul Portugaliei, Spaniei, Franței, Belgiei, Olandei și Germaniei, prin canalul Kiel, urmând litoralul Germaniei,
 Poloniei, Lituaniei, Estoniei și Rusiei până la calea navigabilă Saint-Petersbourg-Calea navigabilă Volga-Baltică, canal Marea Albă-Marea Baltică urmând litoralul Mării Albe
 până la Arkhangelsk, precum și căile navigabile accesibile doar prin acest itinerar</p><br>
 
<p>E 60-02	Guadalquivir până la Seville</p><br>

<p>E 60-04	Douro până la frontiera între Portugalia și Spania</p><br>

<p>E 60-06	Gironde și Garonne până la Castets-en-Dorthe</p><br>

<p>E 60-08	Loara până la Nantes</p><br>

<p>E 60-01	Parcurs costier urmând litoralul vestic al Marii Britanii până la Liverpool inclusiv canalul Manchester-Liverpool</p><br>

<p>E 60-03	Parcurs costier urmând litoralul estic al Marii Britanii inclusiv râul Humber</p><br>

<p>E 60-10	De la parcursul costier al Mării Wadden până la Harlingen</p><br>

<p>E 60-12	De la parcursul costier al Ems-Dollard</p><br>

<p>E 60-05	Parcurs costier urmând litoralul vestic al Danemarcei și al Norvegiei</p><br>

<p>E 60-07	Parcurs costier urmând litoralul vestic al Suediei până la fluviul Gota</p><br>

<p>E 60-09	Parcurs costier urmând litoralul estic al Suediei inclusiv lacul Malaren</p><br>

<p>E 60-14	Stralsund-Peenemunde-Wolgast-Szczecin</p><br>

<p>E 60-11	Parcurs costier până în Finlanda, apoi până la Savonlinna-Iisalmi prin Canalul de Saimaa</p><br>

<p>E 60-11-02	De la ramura E 60-11 până la Joensuu-Nurmes</p><br>

<p>E 61 Peena în aval de Anklam</p><br>

<p style="text-indent:2em;">E 70 Europort/Rotterdam cu Arnhem prin Lek și Benedenrijn-Zutphen-Enschede-[canal Twente-Mittelland]-Bergeshovede-Minden Magdebourg-Berlin
-Hohensaaten-Kostrzyn-Bydgoszcz-Elblag-Vislinskiy-Kaliningrad-Pregola, Deima, Golful Kurshskiy-Klaipeda</p><br>

<p>E 70-01	Ijssel-ul olandez de la Krimpen la Gouda</p><br>

<p>E 70-03	Zij până la Almelo</p><br>

<p>E 70-02	Ramura canalului Mittelland până la Osnabruck</p><br>

<p>E 70-04	Ramura canalului Mittelland până la Hanovra-Linden</p><br>

<p>E 70-06	Ramura canalului Mittelland până la Hildesheim</p><br>

<p>E 70-08	Ramura canalului Mittelland până la Salzgitter</p><br>

<p>E 70-05	Canalul râului Havel</p><br>

<p>E 70-10	Spree</p><br>

<p>E 70-12	Canalul Berlin-Spandau</p><br>

<p>E 71 Canalul râului Teltow, canalul de legătură Britzer și calea navigabilă Oder-Spree</p><br>

<p>E 71-02	Potsdamer Havel</p><br>

<p>E 71-04	Canalul râului Teltow, secțiunea estică</p><br>

<p>E 71-06	Calea navigabilă Dahme în aval de Konigs Wusterhausen</p><br>

<p style="text-indent:2em;">E 80 Le Havre-Conflans prin canalul Le Havre Tancarville, Sena și Oisse-[Compiegne-Toul], Moselle până la Coblence,
 Rhin până la Mayence, Main până la Bamberg, canal Main-Dunăre, Dunărea de la Kelheim la Sulina</p><br>
 
<p>E 80-02	Sena de la Tancarville până la estuar</p><br>

<p>E 80-04	Sena de la Conflans la Nogent</p><br>

<p>E 80-06	Sarre până la Sarrebruck</p><br>

<p>E 80-05	Drave până la Osijek</p><br>

<p>E 80-10	[Canalul Dunăre-Sava de la Vucovar la Samac]</p><br>

<p>E 80-01	Tisa până la Szeged</p><br>

<p>E 80-01-02	Bega până la Timișoara</p><br>

<p>E 80-12	Save până la Sisak</p><br>

<p>E 80-03	Olt până la Slatina</p><br>

<p>E 80-05	Canal Dunăre-București</p><br>

<p>E 80-14	Canal Dunăre-Marea Neagră</p><br>

<p>E 80-14-01	Canal Poarta Albă-Năvodari</p><br>

<p>E 80-07	Prutul până la Ungheni</p><br>

<p>E 80-09	Brațul Chilia al Dunării-</p><br>

<p>E 80-16	Brațul Sf. Gheorghe al Dunării-</p><br>

<p>E 81 Vah de la vărsare până la Zilina și [legătura Vah-Oder]</p><br>

<p style="text-indent:2em;">E 90 Parcurs costier pornind de la Gibraltar spre sud urmând litoralul Spaniei, Franței, Italiei, Greciei, Turciei,
 Bulgariei, României, Ukrainei, urmând litoralul sudic al Crimeei până la Marea Azov, prin Don până la Rostov-Kalach-Volvograd
 -Astrahan, precum și căile navigabile accesibile doar prin acest itinerar</p><br>
 
<p>E 90-01	Parcurs costier în Adriatica până la Trieste</p><br>

<p>E 90-03	Nistru de la Belgorod Dnestrovskiy la Bender</p><br>

<p>E 90-02	Parcurs costier în Marea Neagră</p><br>

<p>E 90-05	Parcurs costier în Marea Caspică</p><br>

<p style="text-indent:2em;">E 91 [Canalul Milano-Po], râul Po de la Cremona la Volta Grimana, Canalul Po-Brondolo și canalul lateral
 de la Veneta până la Monfalcone-Trieste</p><br>
 
<p>E 91-02	Râul Po de la Conca di Cremona la casale Monferrato</p><br>

<p>E 91-04	Calea navigabilă de la Ferrara la portul Garibaldi</p><br>

<p>E 91-06	Râul Po Grande de la Volta Grimana până la gurile sale</p><br>

<p>E 91-01	Manova-Volta Grimana prin calea navigabilă Fissero-Tartaro-Canalbianco</p><br>

<p>E 91-08	Râul Po di-Levante de la Canalul Po-Brondolo până la Marea Adriatică</p><br>

<p>E 91-03	Canalul Padova Veneția</p><br>

<p style="text-indent:2em;">*) Porțiunile de căi navigabile E care nu există în prezent, dar care figurează în planuri
 corespunzătoare de dezvoltare a infrastructurilor sunt indicate între paranteze pătrate.</p>

<p></p><h4>ANEXA Nr. II PORTURI DE NAVIGAȚIE INTERIOARĂ DE IMPORTANȚĂ INTERNAȚIONALĂ</h4><p></p>

<p style="font-weight:500;">Numerotarea porturilor de navigație interioară
de importanță internațională</p><br>

<p style="text-indent:2em;">Fiecărui port de navigație interioară de importanță internațională (port E) îi este atribuit un număr compus din
 numărul căii navigabile pe care este situat, urmat de o cratimă și de un număr din două cifre desemnând portul
 de pe această cale navigabilă și mergând crescător de la vest la est și de la nord la sud, ansamblul numărului
 fiind precedat de litera P. Porturile private aparținând unei întreprinderi particulare sunt indicate printr-un
 asterix (*).</p><br>

<p style="font-weight:500;">Lista porturilor de navigație interioară de importanță internațională</p><br>

<p>P 01-01	Dunkerque (canal Dunkerque-Valencien, 20,5 km)</p><br>

<p>P 01-02	Charleroi (Sambre, 38,8 km)</p><br>

<p>P 01-03	Namur (Meuse, 46,3 km)</p><br>

<p>P 01-04	Liege (Meuse, 113,7 km)</p><br>

<p>P 01-05	Maastricht (Maas, 4,5 km)</p><br>

<p>P 01-06	Stein (Maas, 21,9 km)</p><br>

<p>P 01-07	Born (Maas, 29,7 km)</p><br>

<p>P 01-08	Maasbracht (Maas, 41,8 km)</p><br>

<p>P 01-09	Roermond (Maas, 74,3 km)</p><br>

<p>P 01-10	Oss (Maas, 159,1 km)</p><br>

<p>P 01-11	Dordrecht (Mervede, 974,4 km)</p><br>

<p>P 01-12	Zwijndrecht (Oude Maas, 980,6 km)</p><br>

<p>P 01-13	Vlaardingen (Nieuwe Waterweg, 1010,5 km)</p><br>

<p>P 01-14	Maassluis (Nieuwe Waterweg, 1018,7 km)</p><br>

<p>P 01-01-01	Overpelt (Kanaal Bocholt-Herentals, 14,8 km)</p><br>

<p>P 01-03-01	's-Hertogenbosch (Zuid-Willemsvaart, 4,0 km)</p><br>

<p>P 02-01	Zeebrugge (Marea Nordului)</p><br>

<p>P 02-02	Aalter (Canal Ostende-Brugge-Gent, 22,5 km)</p><br>

<p>P 02-03	Lille (Deule, 42,0 km)</p><br>

<p>P 02-02-01	Ostende (Marea Nordului)</p><br>

<p>P 02-04-01	Roeselare (canal Leie-Roeselare, 0,5 km)</p><br>

<p>P 02-04-02	Izegem (canal Leie-Roeselare, 6,4 km)</p><br>

<p>P 03-01	Moerdijk (Hollands Diep)</p><br>

<p>P 03-02	Terneuzen (canal Terneuzen-Gent, 32,5 km)</p><br>

<p>P 03-03	Zelzate (canal Terneuzen-Gent, 19,6 km)</p><br>

<p>P 03-04	Gent (canal Terneuzen-Gent, 4,6 km)</p><br>

<p>P 04-01	Vlissingen (Westerschelde)</p><br>

<p>P 04-02	Beveren (Beneden Zeeschelde, 22,9 km)</p><br>

<p>P 04-03	Ruisbroek (canal Bruxelles-Rupel, 58,8 km)</p><br>

<p>P 04-04	Grimbergen (canal Bruxelles-Rupel, 12,2 km)</p><br>

<p>P 04-05	Bruxelles (canal Bruxelles-Rupel, 62,0 km)</p><br>

<p>P 05-01	Avelgem (Bovenschelde, 35,7 km)</p><br>

<p>P 05-02	Melle (Boven Zeeschelde, 9,9 km)</p><br>

<p>P 05-03	Meerhout (Albertkanaal, 80,7 km)</p><br>

<p>P 05-04	Ham (Albertkanaal, 73,7 km)</p><br>

<p>P 05-05	Hasselt (Albertkanaal, 51,5 km)</p><br>

<p>P 05-06	Genk (Albertkanaal, 42,9 km)</p><br>

<p>P 05-04-01	Aalst (Dender, 53,7 km)</p><br>

<p>P 06-01	Antwerpen (Schelde, 102,9 km)</p><br>

<p>P 06-02	Bergen op Zoom (Schelde-Rijn Verbinding, 1031,8 km)</p><br>

<p>P 10-01	Rotterdam (Nieuwe Meuse, 1002,5 km)</p><br>

<p>P 10-02	Albasserdam (Noord, 981,1 km)</p><br>

<p>P 10-03	Tiel (Waal, 914,6 km)</p><br>

<p>P 10-04	Emmerich (Rin, 852,0 km)</p><br>

<p>P 10-05	Wesel (Rin, 814,0 km)</p><br>

<p>P 10-06	Rheinberg-Ossenberg*) (Rin, 806,0 km)</p><br>

<p>P 10-07	Orsoy (Rin, 794,0 km)</p><br>

<p>P 10-08	Walsum-Nordhafen*) (Rin, 793,0 km)</p><br>

<p>P 10-09	Walsum-Sud*) (Rin, 791,0 km)</p><br>

<p>P 10-10	Schwelgern*) (Rin, 790,0 km)</p><br>

<p>P 10-11	Homberg, Sachtleben*) (Rin, 774,0 km)</p><br>

<p>P 10-12	Duisburg-Ruhrort Hafen (Rin, 774,0 km)</p><br>

<p>P 10-13	Krefeld (Rin, 762,0 km)</p><br>

<p>P 10-14	Dusseldorf (Rin, 743,0 km)</p><br>

<p>P 10-15	Neuss (Rin, 740,0 km)</p><br>

<p>P 10-16	Sturzelberg*) (Rin, 726,0 km)</p><br>

<p>P 10-17	Leverkusen*) (Rin, 699,0 km)</p><br>

<p>P 10-18	Koln (Rin, 688,0 km)</p><br>

<p>P 10-19	Wesseling-Godorf*) (Rin, 672,0 km)</p><br>

<p>P 10-20	Bonn (Rin, 658,0 km)</p><br>

<p>P 10-21	Andernach (Rin, 612,0 km)</p><br>

<p>P 10-22	Neuwied (Rin, 606,0 km)</p><br>

<p>P 10-23	Bendorf (Rin, 599,0 km)</p><br>

<p>P 10-24	Koblenz (Rin, 596,0 km)</p><br>

<p>P 10-25	Bingen (Rin, 572,0 km)</p><br>

<p>P 10-26	Wiesbaden (Rin, 500,0 km)</p><br>

<p>P 10-27	Mainz (Rin, 500,0 km)</p><br>

<p>P 10-28	Gernsheim (Rin, 462,0 km)</p><br>

<p>P 10-29	Worms (Rin, 444,0 km)</p><br>

<p>P 10-30	Mannheim (Rin, 424,0 km)</p><br>

<p>P 10-31	Ludwigshafen (Rin, 420,0 km)</p><br>

<p>P 10-32	Speyer (Rin, 400,0 km)</p><br>

<p>P 10-33	Germersheim (Rin, 385,0 km)</p><br>

<p>P 10-34	Worth (Rin, 366,0 km)</p><br>

<p>P 10-35	Karlsruhe (Rin, 360,0 km)</p><br>

<p>P 10-36	Kehl (Rin, 297,0 km)</p><br>

<p>P 10-37	Strasbourg (Rin, 296,0 km)</p><br>

<p>P 10-38	Breisach (Rin, 226,0 km)</p><br>

<p>P 10-39	Colmar-Neuf Brisach (Rin, 225,8 km)</p><br>

<p>P 10-40	Mulhouse-Ottmarsheim (Marele canal al Alsaciei, 21,0 km)</p><br>

<p>P 10-41	Fort Louis Stattmatten (Marele canal al Alsaciei, 322,0 km)</p><br>

<p>P 10-42	Insula Napoleon (canal Ron-Rin, 37,6 km)</p><br>

<p>P 10-43	Mulhouse (canal Ron-Rin, 31,0 km)</p><br>

<p>P 10-44	Aproport (Chalon, Maron, Villefrache-sur-Saone) (Saone, 230,0 km, 296,0 km și 335,0 km, respectiv)</p><br>

<p>P 10-45	Lyon (Saone, 375,0 km)</p><br>

<p>P 10-46	Marseille-Fos (canal Marseille-Ron, 0,0 km)</p><br>

<p>P 10-01-01	Rhein-Lippe-Hafen*) (Wesel-Datteln-Canal, 1,0 km)</p><br>

<p>P 10-01-02	Marl Huls-AG*) (Wesel-Datteln-Canal, 38,0 km)</p><br>

<p>P 10-01-03	August Victoria*) (Wesel-Datteln-Canal, 39,0 km)</p><br>

<p>P 10-01-04	Lunen (Datteln-Hamm-Canal, 11,0 km)</p><br>

<p>P 10-01-05	Berkamen*) (Datteln-Hamm-Canal, 22,0 km)</p><br>

<p>P 10-01-06	Hamm (Datteln-Hamm-Canal, 34,0 km)</p><br>

<p>P 10-01-07	Schmehausen*) (Datteln-Hamm-Canal, 47,0 km)</p><br>

<p>P 10-03-01	Essen (Rin-Herne-Canal, 16,0 km)</p><br>

<p>P 10-03-02	Coelln-Neuessen*) (Rin-Herne-Canal, 17,0 km)</p><br>

<p>P 10-03-03	Ruhr-Oel*) (Rin-Herne-Canal, 22,0 km)</p><br>

<p>P 10-03-04	Gelsenkirchen (Rin-Herne Canal, 24,0 km)</p><br>

<p>P 10-03-05	Wanne-Eikel (Rin-Herne-Canal, 32,0 km)</p><br>

<p>P 10-05-01	Mulheim (Ruhr, 8,0 km)</p><br>

<p>P 10-07-01	Heilbronn (Neckar, 110,0 km)</p><br>

<p>P 10-07-02	Stuttgart (Neckar, 186,0 km)</p><br>

<p>P 10-07-03	Plochingen (Neckar, 200,0 km)</p><br>

<p>P 10-09-01	Huningue (Rin, 168,4 km)</p><br>

<p>P 10-09-02	Rheinhafen beider Basel (Rin, 159, 38-169, 95 km)</p><br>

<p>P 10-04-01	Sete (canal Ron-Sete, 96,0 km)</p><br>

<p>P 10-06-01	Fos (golful Fos, partea maritimă)</p><br>

<p>P 11-01	IJmond (Noordzeekanal, 4,7 km)</p><br>

<p>P 11-02	Zaanstad (Zaan, 1,4 km)</p><br>

<p>P 11-03	Amsterdam (Noordzeekanal, 20,6 km)</p><br>

<p>P 11-04	Utrecht (Amsterdam-Rijnkanal, 35,0 km)</p><br>

<p>P 11-01-01	Zaetam (Zaan, 2,0 km)</p><br>

<p>P 12-01	Nijmegen (Waal, 884,6 km)</p><br>

<p>P 12-02	Arnhem (Nederrijn, 885,8 km)</p><br>

<p>P 12-03	Zwolle (IJssel, 980,7 km)</p><br>

<p>P 12-02-01	Meppel (Meppelerdiep, 10,5 km)</p><br>

<p>P 13-01	Emslet*) (Dortmund-Ems-Kanal, 151,0 km)</p><br>

<p>P 13-02	Munster (Dortmund-Ems-Kanal, 68,0 km)</p><br>

<p>P 13-03	Dortmund (Dortmund-Ems-Kanal, 1,0 km)</p><br>

<p>P 14-01	Bremerhaven (Weser, 66-68 km)</p><br>

<p>P 14-02	Nordenham (Weser, 54-64 km)</p><br>

<p>P 14-03	Brake (Weser, 41,0 km)</p><br>

<p>P 14-04	Bremen (Weser, 4,0-8,0 km)</p><br>

<p>P 15-01	Lelystad (IJsselmeer)</p><br>

<p>P 15-02	Lemmer (Pr. Margarietkanal, 90,5 km)</p><br>

<p>P 15-03	Groningen (Starkenborghkanal, 7,0 km)</p><br>

<p>P 15-04	Emden (Ems, 41,0 km)</p><br>

<p>P 15-05	Leer (Ems, 14,0 km)</p><br>

<p>P 15-06	Oldenburg*) (Hunte, 0,0-5,0 km)</p><br>

<p>P 15-01-01	Leenwarden (Haringsmakanal, 23,7 km)</p><br>

<p>P 20-01	Cuxhaven (Elba, 724,0 km)1)</p><br>

<p>P 20-02	Brunsbuttel (Elbehafen, 693,0 km)1)</p><br>

<p>P 20-03	Butzfleet* (Elba, 668,0 km)1)</p><br>

<p>P 20-04	Hamburg (Elba, 618,0-639,0 km)1)</p><br>

<p>P 20-05	Lauenburg (Elba, 568,0 km)1)</p><br>

<p>P 20-06	Tangermunde (Elba, 388,0 km)1)</p><br>

<p>P 20-07	Kieswerk Rogatz*) (Elba, 354,0 km)1)</p><br>

<p>P 20-08	Magdeburger Hafen (Elba, 330,0 și 333,0 km)1)</p><br>

<p>P 20-09	Schonebeck (Elba, 315,0 km)1)</p><br>

<p>P 20-10	Aken (Elba, 277,0 km)1)</p><br>

<p>P 20-11	Torgau (Elba, 154,0 km)1)</p><br>

<p>P 20-12	Kieswerk Muhlberg*) (Elba, 125,0 km)1)</p><br>

<p>P 20-13	Riesa (Elba, 109,0 km)1)</p><br>

<p>P 20-14	Dresde (Elba, 57 și 61 km)1)</p><br>

<p>P 20-15	Decin (Elba, 98,2 și 94,2 km)1)</p><br>

<p>P 20-16	Usti nad Labem (Elba, 75,3 și 72,5 km)1)</p><br>

<p>P 20-17	Melnik (Elba, 3,0 km)1)</p><br>

<p>P 20-18	Chvaletice (Elba, 101,5 km)1)</p>

<p style="text-indent:2em;"></p><h6>1) Distanțele relative ale porturilor situate pe Elba sunt măsurate astfel:</h6><p></p>

<p style="text-indent:2em;">în Germania, pornind de la frontiera germano-cehă; în Republica Cehia, începând de la joncțiunea fluviilor Elba și Valtava la Melnik</p><br>
 
<p>P 20-04-01	Halle-Trotha (Saale, 86,0 km)</p><br>

<p>P 20-06-01	Praga (Vltava, 46,5 și 55,5 km)</p><br>

<p>P 21-01	Lubeck (Trave, 2,0-8,0 km)</p><br>

<p>P 30-01	Swinoujscie (Marea Baltica - gura Oder-ului)</p><br>

<p>P 30-02	Szczecin (Oder, 741,0 km)</p><br>

<p>P 30-03	Kostrzyn (Oder, 617,0 km)</p><br>

<p>P 30-04	Wroclaw (Oder, 255,0 km)</p><br>

<p>P 30-05	Kozle (Oder, 96,0 km)</p><br>

<p>P 30-01-01	Gliwice (canal Gliwicki, 41,0 km)</p><br>

<p>P 40-01	Gdansk (Marea Baltică, gura Vistulei)</p><br>

<p>P 40-02	Bydgoszcz (Vistula, 772,3 km și Brda, 2,0 km)</p><br>

<p>P 40-03	Varșovia (Vistula, 520,0 km și canal Zeran, 2,0 km)</p><br>

<p>P 40-04	Chernigov (Nipru, 1070,0 km)</p><br>

<p>P 40-05	Kiev (Nipru, 856,0 km)</p><br>

<p>P 40-06	Cherkassy (Nipru, 653,0 km)</p><br>

<p>P 40-07	Kremenchug (Nipru, 541,0 km)</p><br>

<p>P 40-08	Dneprodzerzhinsk (Nipru, 429,0 km)</p><br>

<p>P 40-09	Dnepropetrovsk (Nipru, 393,0 km)</p><br>

<p>P 40-10	Zaporozhie (Nipru, 308,0 km)</p><br>

<p>P 40-11	Novaja Kakhovka (Nipru, 96,0 km)</p><br>

<p>P 40-12	Kherson (Nipru, 28,0 km)</p><br>

<p>P 40-02-01	Nikolaev (Yuzhniy Bug, 95,0 km)</p><br>

<p>P 41-01	Klaipeda port fluvial (golful Kurshskiy)</p><br>

<p>P 41-02	Neringa port fluvial (golful Kurshskiy)</p><br>

<p>P 41-03	Jurbarkas (Niemen, 126,0 km)</p><br>

<p>P 41-04	Kaunas (Niemen, 219,0 km)</p><br>

<p>P 50-01	St. Petersburg port maritim (Neva, 1355,0 km)2)</p><br>

<p>P 50-02	St. Petersburg port fluvial (Neva, 1343,0 km)2)</p><br>

<p>P 50-03	Podporozhie (cale navigabilă Volga-M. Baltică, 1003,0 km)2)</p><br>

<p>P 50-04	Cherepovets (cale navigabilă Volga-M. Baltică, 498,0 km)2)</p><br>

<p>P 50-05	Yaroslavl (Volga, 478,0 km)2)</p><br>

<p>P 50-06	Nizhniy Novgorod (Volga, 865,0 km)2)</p><br>

<p>P 50-07	Kazan (Volga, 12,71 km)2)</p><br>

<p>P 50-08	Ulianovsk (Volga, 1499,0 km)2)</p><br>

<p>P 50-09	Samara (Volga, 1704,0 km)2)</p><br>

<p>P 50-10	Saratov (Volga, 2133,0 km)2)</p><br>

<p>P 50-11	Volvograd (Volga, 2518,0 km)2)</p><br>

<p>P 50-12	Astrakhan (Volga, 3009,0 km)2)</p><br>

<p>P 50-02-01	Port Nord al Moscovei (Canal imeni Moskvy), 0,0 km)2)</p><br>

<p>P 50-02-02-01	Tver (Volga, 237,0 km)2)</p><br>

<p>P 50-01-01	Perm (Kama, 2227,0 km)2)</p><br>

<p>P 60-01	Scheveningen (Marea Nordului)</p><br>

<p>P 60-02	Den Helder (Marea Nordului)</p><br>

<p>P 60-03	Brunsbuttel (Canal Kiel, 2,0-5,0 km)</p><br>

<p>P 60-04	Rendsburg (Canal Kiel, 62,0 km)</p><br>

<p>P 60-05	Kiel (Canal Kiel, 96,0 km)</p><br>

<p>P 60-06	Flensburg</p><br>

<p>P 60-07	Wismar</p><br>

<p>P 60-08	Rostock</p><br>

<p>P 60-09	Stralsund</p><br>

<p>P 60-10	Greifswald</p><br>

<p>P 60-11	Sventoji (Marea Baltica)</p><br>

<p>P 60-12	Vyborg (golful Vyborg)</p><br>

<p>P 60-13	Petrozavodsk (lac Onega, 967,0 km)2)</p><br>

<p style="font-weight:500;">2) Distanța începând din portul Nord al Moscovei.</p><br>

<p>P 60-14	Arkhangelsk port maritim (gura Severnaja Dvina)</p><br>

<p>P 60-15	Arkhangelsk port fluvial (gura Severnaja Dvina)</p><br>

<p>P 60-02-01	Seville (Guadalquivir, 80,0 km)</p><br>

<p>P 60-04-01	Douro (Douro, 5,0 km)</p><br>

<p>P 60-04-02	Sardoura (Douro, 49,0 km)</p><br>

<p>P 60-04-03	Regua-Lamego (Douro, 101,0 km)</p><br>

<p>P 60-06-01	Bordeaux (Gironde și Garonne, 359,0 km)</p><br>

<p>P 60-08-01	Nantes (Loire, 645,0 km)</p><br>

<p>P 60-10-01	Harlingen (Marea Wadden)</p><br>

<p>P 60-12-01	Delfzijl (Marea Wadden)</p><br>

<p>P 60-11-01	Mustola (39,0 km de la gura canalului Saimaa)</p><br>

<p>P 60-11-02	Kaukas*) (52,0 km de la gura canalului Saimaa)</p><br>

<p>P 60-11-03	Rapasaari*) (52,0 km de la gura canalului Saimaa)</p><br>

<p>P 60-11-04	Joutseno*) (67,0 km de la gura canalului Saimaa)</p><br>

<p>P 60-11-05	Vuoksi*) (85,0 km de la gura canalului Saimaa)</p><br>

<p>P 60-11-06	Varkaus (Port Taipale, 270,0 km de la gura canalului Saimaa)</p><br>

<p>P 60-11-07	Varkaus (Port Kosulanniemi*), 270,0 km de la gura canalului Saimaa)</p><br>

<p>P 60-11-08	Varkaus (Port d'Akonniemi, 270,0 km de la gura canalului Saimaa)</p><br>

<p>P 60-11-09	Kuopio (352,0 km de la gura canalului Saimaa)</p><br>

<p>P 60-11-02-01	Puhos*) (311,0 km de la gura canalului Saimaa)</p><br>

<p>P 60-11-02-02	Joensuu (346,0 km de la gura canalului Saimaa)</p><br>

<p>P 61-01	Anklam (Peene, 95,0 km)</p><br>

<p>P 70-01	Wageningen (Neder-Rin, 903,2 km)</p><br>

<p>P 70-02	Enschede (Twentekanaal, 49,8 km)</p><br>

<p>P 70-03	Ibbenburen (Mittelletkanal, 5,0 km)</p><br>

<p>P 70-04	Minden (Mittelletkanal, 100,0-104,0 km)</p><br>

<p>P 70-05	Hanovre (Mittelletkanal, 155,0-159,0 km)</p><br>

<p>P 70-06	Mehrum* (Mittelletkanal, 190,0 km)</p><br>

<p>P 70-07	Braunschweig (Mittelletkanal, 220,0 km)</p><br>

<p>P 70-08	Braunschweig/Thune* (Mittelletkanal, 223,0 km)</p><br>

<p>P 70-09	Haldensleben (Mittelletkanal, 301,0 km)</p><br>

<p>P 70-10	Niegripp*) (Elbe-Havel-Kanal, 330,0 km)</p><br>

<p>P 70-11	Brandebourg*) (curs navigabil inferior Havel, 60,0 km)</p><br>

<p>P 70-12	Brandebourg (curs navigabil inferior Havel, 57,0 km)</p><br>

<p>P 70-13	Deponie Deetz*) (curs navigabil inferior Havel, 40,0 km)</p><br>

<p>P 70-14	Spandau port Sud (curs navigabil inferior Havel, 2,0 km)</p><br>

<p>P 70-15	Elblag (golful Wisliany)</p><br>

<p>P 70-16	Kaliningrad port maritim (Pregola, 8,0 km)</p><br>

<p>P 70-17	Kaliningrad port fluvial (Pregola, 9,0 km)</p><br>

<p>P 70-01-01	Gouda (Holletsche IJssel, 1,4 km)</p><br>

<p>P 70-03-01	Hengelo (Twentekanaal, 45,1 km)</p><br>

<p>P 70-03-02	Almelo (Zijkanaal, 17,6 km)</p><br>

<p>P 70-02-01	Osnabruck (Stichkanal, 13,0 km)</p><br>

<p>P 70-04-01	Hannover-Linden (Stichkanal, 11,0 km)</p><br>

<p>P 70-06-01	Hildesheim (Stichkanal, 15,0 km)</p><br>

<p>P 70-05-01	Salzgitter (Stichkanal, 15,0 km)</p><br>

<p>P 70-10-01	Terminal de mărfuri*) (brațul Spree la 0,0 km)</p><br>

<p>P 70-10-02	Nonnendamm (Spree, 2,0 km)</p><br>

<p>P 70-10-03	Centrala electrică Reuter*) (Spree, 3,0 km)</p><br>

<p>P 70-10-04	Centrala electrica Charlottenburg*) (Spree 8,0 km)</p><br>

<p>P 70-10-05	Berlin-Port Vest (Westhafenkanal, 3,0 km)</p><br>

<p>P 70-10-06	Berlin-Port Est (Spree, 21,0 km)</p><br>

<p>P 70-10-07	Centrala termică Klingenberg (Spree, 25,0 km)</p><br>

<p>P 70-12-01	Centrala Moabit*) (Berlin-Spandauer Schiffahrtskanal, 9,0 km)</p><br>

<p>P 71-01	Terminal de mărfuri Teltowkanal*) (Teltowkanal, 31,0-34,0 km)</p><br>

<p>P 71-02	Terminal de mărfuri d'Oberschoneweide (Spree-Oder-Wasserstrabe, 28,0-29,9 km)</p><br>

<p>P 71-03	Eisenhuttenstadt EKO*) (Spree-Oder-Wasserstrabe, 122,0 km)</p><br>

<p>P 71-04	Eisenhuttenstadt (Spree-Oder-Wasserstrabe, 124,0 km)</p><br>

<p>P 71-02-01	Potsdam (Potsdamer Havel, 3,0 km)</p><br>

<p>P 71-06-01	Niederlehme*) (Dahme-Wasserstrabe, 8,0 km)</p><br>

<p>P 71-06-02	Konigs Wusterhausen (Dahme-Wasserstrabe, 8,0 km)</p><br>

<p>P 80-01	Havre (canal Havre-Tancarville, 20,0 km)</p><br>

<p>P 80-02	Rouen (Sena, 242,0 km)</p><br>

<p>P 80-03	Conflans (Sena, 239,0 km)</p><br>

<p>P 80-04	Frouard (Moselle, 346,5 km)</p><br>

<p>P 80-05	Metz (Moselle, 297,0-294,0 km)</p><br>

<p>P 80-06	Mondelange-Richemont (Moselle, 279,5-277,9 km)</p><br>

<p>P 80-07	Thionville-Illange (Moselle, 271,9-270,1 km)</p><br>

<p>P 80-08	Mertert (Moselle, 208,0 km)</p><br>

<p>P 80-09	Trier (Moselle, 184,0 km)</p><br>

<p>P 80-10	Bingen (Rin, 527,0 km)</p><br>

<p>P 80-11	Wiesbaden (Rin, 500,0 km)</p><br>

<p>P 80-12	Mayence (Rin, 500,0 km)</p><br>

<p>P 80-13	Florsheim*) (Main, 9,0 km)</p><br>

<p>P 80-14	Raunheim*) (Main, 14,0 km)</p><br>

<p>P 80-15	Hatersheim*) (Main, 17,0 km)</p><br>

<p>P 80-16	Kelsterbach*) (Main, 19,0 km)</p><br>

<p>P 80-17	Francfort*) (Main, 22,0-29,0 km)</p><br>

<p>P 80-18	Francfort (Main, 31,0-37,0 km)</p><br>

<p>P 80-19	Offenbach (Main, 40,0 km)</p><br>

<p>P 80-20	Hanau (Main, 56,0-60,0 km)</p><br>

<p>P 80-21	Grosskrotzenburg*) (Main, 62,0 km)</p><br>

<p>P 80-22	Stockstadt (Main, 82,0 km)</p><br>

<p>P 80-23	Aschaffenburg (Main, 83,0 km)</p><br>

<p>P 80-24	Triefenstein*) (Main, 173,0 km)</p><br>

<p>P 80-25	Karlstadt*) (Main, 227,0 km)</p><br>

<p>P 80-26	Wurzburg (Main, 246,0-251,0 km)</p><br>

<p>P 80-27	Schweinfurt (Main, 330,0 km)</p><br>

<p>P 80-28	Bamberg (Canalul Main-Dunăre, 3,0 km)</p><br>

<p>P 80-29	Erlangen (Canalul Main-Dunăre, 46,0 km)</p><br>

<p>P 80-30	Nurnberg (Canalul Main-Dunăre, 72,0 km)</p><br>

<p>P 80-31	Regensburg (Dunăre, 2370,0-2378,0 km)</p><br>

<p>P 80-32	Deggendorf*) (Dunăre, 2281,0-2284,0 km)</p><br>

<p>P 80-33	Linz (Dunăre, 2128,2-2130,6 km)</p><br>

<p>P 80-34	Linz-Voest*) (Dunăre, 2127,2 km)</p><br>

<p>P 80-35	Enns-Ensdorf (Dunăre, 2111,8 km)</p><br>

<p>P 80-36	Krems (Dunăre, 2001,5 km)</p><br>

<p>P 80-37	Viena (Dunăre, 1916,8-1920,2 km)</p><br>

<p>P 80-38	Bratislava (Dunăre, 1867,0 km)</p><br>

<p>P 80-39	Gyor-Gonyu (Dunăre, 1807,0 km)</p><br>

<p>P 80-40	Komarno (Dunăre, 1767,1 km)</p><br>

<p>P 80-41	Sturovo (Dunăre, 1722,0 km)</p><br>

<p>P 80-42	Budapesta (Dunăre, 1640,0 km)</p><br>

<p>P 80-43	Szazhalombatta (Dunăre, 1618,8 km)</p><br>

<p>P 80-44	Dunaujvaros (Dunăre, 1579,0 km)</p><br>

<p>P 80-45	Dunafoldvar (Dunăre, 1563,0 km)</p><br>

<p>P 80-46	Baja (Dunăre, 1480,0 km)</p><br>

<p>P 80-47	Vukovar (Dunăre, 1333,1 km)</p><br>

<p>P 80-48	Beograd (Dunăre, 1170,0 km)</p><br>

<p>P 80-49	Smeredevo (Dunăre, 1116,3 km)</p><br>

<p>P 80-50	Orsova (Dunăre, 954,0 km)</p><br>

<p>P 80-51	Turnu Severin (Dunăre, 931,0 km)</p><br>

<p>P 80-52	Prahovo (Dunăre, 861,0 km)</p><br>

<p>P 80-53	Lom (Dunăre, 743,0 km)</p><br>

<p>P 80-54	Turnu Măgurele (Dunăre, 597,0 km)</p><br>

<p>P 80-55	Svistov (Dunăre, 554,0 km)</p><br>

<p>P 80-56	Ruse (Dunăre, 495,0 km)</p><br>

<p>P 80-57	Giurgiu (Dunăre, 493,0 km)</p><br>

<p>P 80-58	Oltenița (Dunăre, 430,0 km)</p><br>

<p>P 80-59	Călărași (Dunăre, 370,5 km)</p><br>

<p>P 80-60	Brăila (Dunăre, 172,0-168,5 km)</p><br>

<p>P 80-61	Galați (Dunăre, 157,0-145,4 km)</p><br>

<p>P 80-62	Giurgiulești (Dunăre, 133)3)</p><br>

<p style="font-weight:500;">3) Construcție prevăzută</p><br>

<p>P 80-63	Reni (Dunăre, 128,0 km)</p><br>

<p>P 80-64	Tulcea (Dunăre, 73,5-70,0 km)</p>

<p style="text-indent:2em;"></p><h6>P 80-04-01	Port autonom Paris:</h6><p></p>

<p>Gennevilliers (Sena, 194,7 km)</p><br>

<p>Bonneuil-Vigneux (Sena, 169,7 km)</p><br>

<p>Evry (Sena, 137,8 km)</p><br>

<p>Melun (Sena, 110,0 km)</p><br>

<p>Limay-Porcheville (Sena, 109,0 km)</p><br>

<p>Montereau (Sena, 67,4 km)</p><br>

<p>Nanterre (Sena, 39,4 km)</p><br>

<p>Bruyeres-sur-Oise (Oise, 96,9 km)</p><br>

<p>St. Ouen-l'Aumone (Oise, 119,2 km)</p><br>

<p>Lagny (Marne, 149,8 km)</p><br>

<p>P 80-06-01	Dillingen (Sarre, 59,0 km)</p><br>

<p>P 80-08-02	Osijek (Drave, 14 0 km)</p><br>

<p>P 80-01-01	Szeged (Tisa, 170,0 km)</p><br>

<p>P 80-14-01	Cernavodă (canal Dunăre-Marea Neagră, 00,0 km)</p><br>

<p>P 80-14-02	Medgidia (canal Dunăre-Marea Neagră, 27,5 km)</p><br>

<p>P 80-14-03	Constanța (canal Dunăre-Marea Neagră, 64,0 km)</p><br>

<p>P 80-09-01	Ismail (brațul Dunării-Chilia, 93,0 km)</p><br>

<p>P 80-09-02	Kilia (brațul Dunării-Chilia, 47,0 km)</p><br>

<p>&gt;P 80-09-03	Ust-Dunajsk (brațul Dunării-Chilia, 1,0 km)</p><br>

<p>P 90-01	Taganrog (golful Taganrog)</p><br>

<p>P 90-02	Eysk (golful Taganrog)</p><br>

<p>P 90-03	Azov (Don, 3126,0 km)2)</p><br>

<p>P 90-04	Rostov (Don, 3092,0 km)2)</p><br>

<p>P 90-05	Ust-Donetsk (Don, 2955,0 km)2)</p><br>

<p>P 90-03-01	Belgorod Dnestrovskiy (gura Nistrului)</p><br>

<p>P 90-03-02	Bender (Nistru, 228,0 km)</p><br>

<p>P 91-01	Terminal Milan (Milan-canal du Po, 0,0 km)4)</p><br>

<p>P 91-02	Lodi (Milan-canal du Po, 20,0 km de Milan)4)</p><br>

<p>P 91-03	Pizzighettone (Milan-canal du Po, 40,0 km de Milan)4)</p><br>

<p>P 91-04	Cremona (Po, 55,0 km de Milan)</p><br>

<p>P 91-05	Emilia Centrale (Po, 145,0 de Milan)4)</p><br>

<p>P 91-06	Ferrara (Po, 270,0 km de Milan)</p><br>

<p>P 91-07	Porto Tolle (Po, 350,0 km de Milan)</p><br>

<p>P 91-02-01	Garibaldi (cale navigabilă de Ferrara, 80,0 km de Ferrara)</p><br>

<p>P 92-01	Adria (cale laterală de Veneta, 10,0 km de Volta Grimana)</p><br>

<p>P 92-02	Chioggia (cale laterală de Veneta, 30,0 km de Volta Grimana)</p><br>

<p>P 92-03	Marghera (cale laterală de Veneta, 65,0 km de Volta Grimana)</p><br>

<p>P 92-04	Nogaro (cale laterală de Veneta, 120,0 km de Volta Grimana)</p><br>

<p>P 92-05	Monfalcone (cale laterală de Veneta, 175,0 km de Volta Grimana)</p><br>

<p>P 92-01-01	Mantova (cale navigabilă Mantova-Volta Grimana, 0,0 km)</p><br>

<p>P 92-01-02	Legnago (cale navigabilă Mantova-Volta Grimana, 65,0 km)4)</p><br>

<p>P 92-01-03	Rovigo (cale navigabilă Mantova-Volta Grimana, 140,0 km)4)</p><br>

<p>P 92-01-04	Conca di Volta Grimana (Mantova-Volta Grimana Waterway, 170,0 km)</p><br>

<p style="font-weight:500;">4) În construcție sau în previziune</p>

<p></p><h4>ANEXA Nr. III
CARACTERISTICILE TEHNICE ȘI DE EXPLOATARE ALE CĂILOR NAVIGABILE
DE IMPORTANȚĂ INTERNAȚIONALĂ</h4><p></p>

<p style="text-indent:2em;"></p><h6>a) Caracteristici tehnice ale căilor navigabile E:</h6><p></p>

<p style="text-indent:2em;">Principalele caracteristici tehnice ale căilor navigabile E vor fi conforme cu clasificarea căilor navigabile interioare
 europene din tabelul nr. 1.</p>

<p style="text-indent:2em;"></p><h6>În vederea evaluării diferitelor căi navigabile E, se iau ca bază caracteristicile claselor IV și VII, ținându-se seama de
 următoarele principii:</h6><p></p>

<p style="text-indent:2em;">(i) clasa căii navigabile este determinată de dimensiunile orizontale ale automotoarelor, barjelor și convoaielor împinse și,
 în special, prin dimensiunea principală tipizată, adică traversa sau lățimea lor;</p><br>

<p style="text-indent:2em;">(ii) doar căile navigabile care corespund cel puțin condițiilor fundamentale ale clasei IV (dimensiuni minime ale navelor: 85
 m x 9,5 m) pot fi considerate căi navigabile E. Limitările privind pescajul (mai mic de 2,5 m) și înălțimea liberă minimă sub
 poduri (mai mică de 5,25 m) nu pot fi admise decât pentru căile navigabile existente și cu titlu de excepție;</p><br>

<p style="text-indent:2em;">(iii) atunci când se modernizează căi navigabile din clasa IV (precum și căi navigabile regionale mai mici), se recomandă ca
 ele să corespundă cel puțin parametrilor clasei Va;</p><br>

<p style="text-indent:2em;">(iv) totodată, noile căi navigabile E ar trebui să corespundă cel puțin condițiilor clasei Vb. În acest sens, ar trebui
 garantat un pescaj minim de 2,8 m;</p><br>

<p style="text-indent:2em;">(v) atunci când se modernizează căi navigabile existente și când se construiesc noi căi, ar trebui să se ia întotdeauna
 în calcul o creștere a dimensiunii navelor și convoaielor;</p><br>

<p style="text-indent:2em;">(vi) în vederea îmbunătățirii eficacității transportului containerizat, trebuie avută în vedere garantarea celei mai mari
 înălțimi libere sub poduri, conform notei nr. 4 din tabel*);</p><br>

<p style="text-indent:2em;">*) Dacă totuși ponderea containerelor goale transportate pe fiecare navă depășește 50%, ar fi bine să se prevadă aducerea
 înălțimii libere minime sub poduri la o valoare superioară celei care este indicată în nota nr. 4 și la o înălțime liberă
 minimă la poduri care să fie mai mare decât cea care este indicată în nota nr. 4.</p><br>

<p style="text-indent:2em;">(vii) căile navigabile pe care se prevede un trafic containerizat sau Ro-Ro important ar trebui să satisfacă cel puțin
 condițiile clasei Vb. S-ar putea, de asemenea, prevedea o creștere între 7 și 10% a lățimii de 11,4 m a navelor care
 navighează exclusiv pe căi navigabile de clasa Va și inferioară acesteia, pentru a ține seama de evoluția viitoare a
 dimensiunilor containerelor și pentru a facilita transportul remorcilor;</p><br>

<p style="text-indent:2em;">(viii) pe căile navigabile cu nivel variabil de apă, valoarea recomandată a pescajului trebuie să corespundă celui atins
 sau depășit în medie timp de 240 zile/an (sau 60% din perioada de navigație). Valoarea înălțimii libere sub poduri recomandată
 (5,25 m, 7 m sau 9,10 m) trebuie să fie menținută pentru nivelul de navigație cel mai înalt posibil și fezabil din punct de
 vedere economic;</p><br>

<p style="text-indent:2em;">(ix) trebuie să fie menținute norme uniforme de clasă, de pescaj și de înălțime liberă sub poduri pe orice cale navigabilă
 sau cel puțin pe porțiunile importante ale lungimii sale;</p><br>

<p>(x) în măsura în care este posibil, parametrii căilor navigabile adiacente trebuie să fie identici sau similari;</p><br>

<p style="text-indent:2em;">(xi) cel mai mare pescaj (4,5 m) și cea mai mare înălțime liberă minimă sub poduri (9,10 m) trebuie să fie menținute pe
 toate porțiunile rețelei caresuntînmod direct racordate rutelor costiere;</p><br>

<p style="text-indent:2em;">(xii) trebuie menținută o înălțime liberă minimă sub poduri de 7 m pe căile navigabile care leagă porturi maritime importante
 de interiorul țării și care sunt adaptate unui transport eficient de containere și traficului fluvio-maritim;</p><br>

<p style="text-indent:2em;">(xiii) rutele costiere enumerate în anexa nr. I de mai sus vizează menținerea continuității rețelei de căi navigabile E în
 întreaga Europă și sunt destinate să fie utilizate, în termenii prezentului acord, de nave de transport fluvio-maritime,
 ale căror dimensiuni trebuie, în măsura în care este posibil și fezabil din punct de vedere economic, să satisfacă 
 prevederile privind automotoarele adaptate navigației pe căile navigabile interioare de clasa Va și VIb.</p>

<p style="text-indent:2em;"></p><h6>Se consideră că, pentru ca o cale navigabilă să permită desfășurarea traficului containerizat, aceasta trebuie să
 respecte următoarele cerințe minimale:</h6><p></p>

<p style="text-indent:2em;"> navele de navigație interioară cu o lățime de 11,4 m și cu lungime de aproximativ 110 m trebuie să poată transporta
 containere pe minimum 3 rânduri înălțime; dacă nu este cazul, ar trebui permisă o lungime maximă de 185 m pentru convoaiele
 împinse, caz în care acestea vor trebui să poată transporta containere pe 2 rânduri înălțime.</p><br>

<p>privind clasificarea căilor navigabile interioare de importanță internațională.</p>

<p style="text-indent:2em;"></p><h6>Note la tabel:</h6><p></p>

<p style="text-indent:2em;">1. Prima cifră corespunde condițiilor generale actuale, a doua ține cont de evoluția viitoare și, în cazuri
 speciale, de condițiile actuale.</p><br>

<p style="text-indent:2em;">2. Ținând seama de o marjă de siguranță de aproximativ 0,30 m între punctul cel mai ridicat al suprastructurii
 navei sau al încărcăturii sale și un pod.</p><br>

<p>3. Se ține seama de evoluția viitoare eventuală a transportului Ro-Ro containerizat și a navigației
 fluvio-maritime.</p>

<p style="text-indent:2em;"></p><h6>4. Înălțime verificată pentru transportul containerelor:</h6><p></p>

<p>5,25 m pentru navele care transportă containere pe două rânduri;</p><br>

<p>7,00 m pentru navele care transportă containere pe trei rânduri;</p><br>

<p>9,10 m pentru navele care transportă containere pe patru rânduri;</p><br>

<p>50% din containere pot fi goale; în caz contrar, trebuie recurs la balast.</p><br>

<p style="text-indent:2em;">5. Câteva căi navigabile existente pot fi considerate ca aparținând clasei IV din cauza lungimii maxime
 autorizate pentru nave și convoaie cu toate că ele permit o lățime maximă de 11,4 m și un pescaj maxim de 4,00 m.</p><br>

<p>6. Valoarea pescajului pentru o cale navigabilă specială trebuie determinată în funcție de condițiile locale speciale.</p><br>

<p style="text-indent:2em;">7. Uneori pe anumite secțiuni ale căilor navigabile de clasa VII pot fi utilizate convoaie compuse dintr-un număr
 mai mare de barje. În acest caz dimensiunile orizontale pot depăși valorile indicate în tabel.</p><br>

<p style="font-weight:500;">b) Condiții de exploatare a căilor navigabile E</p>

<p style="text-indent:2em;"></p><h6>Căile navigabile E trebuie să satisfacă următoarele condiții de exploatare fundamentale ca să permită funcționarea
 fiabilă transportului internațional:</h6><p></p>

<p>(i) navigația trebuie asigurată pe toată perioada de navigație, cu excepția întreruperilor, menționate mai jos;</p><br>

<p style="text-indent:2em;">(ii) perioada de navigație nu poate fi mai scurtă de 365 de zile decât în regiunile cu condiții climatice severe unde
 este imposibilă menținerea șenalului liber de gheață pe timpul iernii și unde, deci, trebuie întreruptă navigația. În
 aceste cazuri, ar trebui fixate datele de deschidere și de închidere a navigației. Durata întreruperilor datorate
 fenomenelor naturale cum ar fi îngheț, inundații etc. ar trebui să fie limitată la strictul necesar, folosind măsurile
 tehnice și de organizare corespunzătoare;</p><br>

<p style="text-indent:2em;">(iii) durata întreruperilor cauzate de întreținerea periodică a ecluzelor și a altor lucrări hidraulice ar trebui să
 fie limitată la minimul necesar. Utilizatorii căii navigabile pe care este prevăzut să se efectueze lucrări de întreținere
 trebuie să fie informați cu privire la datele și durata întreruperii prevăzute. În cazul deteriorării neprevăzute a unei
 ecluze sau a altor lucrări hidraulice sau în alte cazuri de forță majoră, se vor face eforturi în vederea limitării la 
 strictul necesar a duratei întreruperilor, luând toate măsurile corespunzătoare în vederea remedierii acestei situații;</p><br>

<p style="text-indent:2em;">(iv) nu este admisă nici un fel de întrerupere datorată apelor mici. Sunt în schimb acceptabile restricții rezonabile
 privind pescajul admisibil pe căile navigabile cu nivel variabil al apei. Trebuie menținut totuși, în orice împrejurare,
 un pescaj minim de 1,20 m, iar pescajul caracteristic sau recomandat ar trebui să fie menținut sau depășit pe o perioadă 
 de 240 zile/an. În regiunile menționate la alin. (ii) de mai sus, pescajul minim de 1,20 m ar trebui să fie menținut pe 
 o durată de 60% din perioada de navigație medie;</p><br>

<p style="text-indent:2em;">(v) orarele de funcționare a ecluzelor, a podurilor mobile și altor lucrări de infrastructură trebuie să fie astfel 
întocmite încât navigația să fie asigurată 24 de ore din 24 în zilele lucrătoare, în măsura în care acest lucru este 
fezabil din punct de vedere economic.</p><br>

<p>Se pot admite excepții în anumite cazuri speciale pentru motive de organizare și/sau tehnice.</p><br>

<p style="text-indent:2em;">Trebuie, de asemenea, să fie asigurate orare rezonabile de funcționare, care să permită navigația în zilele de 
sărbătoare și la sfârșit de săptămână.</p><br>

<p style="font-weight:500;">c) Caracteristici tehnice și de exploatare ale porturilor E</p>

<p style="text-indent:2em;"></p><h6>Rețeaua de căi navigabile E este completată de un sistem de porturi interioare de importanță internațională. 
Fiecare port E trebuie să îndeplinească caracteristicile tehnice și de exploatare de mai jos:</h6><p></p>

<p>(i) trebuie să fie situat pe o cale navigabilă E;</p><br>

<p>(ii) trebuie să poată primi nave sau convoaie împinse utilizate pe această cale navigabilă, conform clasei acesteia;</p><br>

<p style="text-indent:2em;">(iii) trebuie să fie deservit de căi rutiere sau feroviare de mare importanță, de preferință care fac parte din rețeaua 
de drumuri internaționale și de linii feroviare internaționale, stabilită prin Acordul european privind marile linii 
internaționale rutiere - AGR, Acordul european privind marile linii internaționale de căi ferate - AGC și Acordul 
european privind marile linii de transport internațional combinat și instalațiile conexe - AGTC;</p><br>

<p>(iv) trebuie să poată opera un volum anual de mărfuri de cel puțin 0,5 milioane tone;</p><br>

<p>(v) trebuie să ofere condiții satisfăcătoare pentru dezvoltarea unei zone portuare industriale;</p><br>

<p>(vi) trebuie să permită manipularea containerelor tipizate (în afara cazului în care este vorba de un port-vrac 
specializat);</p><br>

<p>(vii) trebuie să ofere toate serviciile necesare operațiunilor curente ale transportului internațional;</p><br>

<p><a href="https://lege5.ro/Gratuit/geydemjtgy/acordul-european-privind-marile-cai-navigabile-de-importanta-internationala-agn-din-19011996">Sursa</a></p>

</div>
`