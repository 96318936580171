import React, { Component } from 'react'
import { DESCRIPTION_MAX_LENGTH } from '../../../components/forum/forum-full-page/forum-constants'
import './compose.scss'

interface ComposeInterface extends ComponentBaseProps {
  onKeyDown: any
  onChange: any
  text: string
  placeholder: string
  sendMessage: any
}

export default class Compose extends Component<ComposeInterface> {
  render() {
    const { onKeyDown, onChange, text, placeholder, sendMessage, t } = this.props

    const disabled = text.length <= 0 || text.length > DESCRIPTION_MAX_LENGTH
    const error = text.length > DESCRIPTION_MAX_LENGTH ? 'error-text' : ''
    
    return (
      <div className="compose">
        <textarea
          onKeyDown={onKeyDown}
          onChange={onChange}
          placeholder={placeholder}
          className="compose-textarea"
          value={text}
        />
        <div className="message-length">
          <span className={error}>{text.length}</span> / {DESCRIPTION_MAX_LENGTH}
        </div>

        <button
          type="button"
          disabled={disabled}
          className="button button-orange"
          onClick={() => sendMessage()}
        >
          {t('messenger.send')}
        </button>
      </div>
    )
  }
}
