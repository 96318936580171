import _ from 'lodash'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reduxForm, formValueSelector, getFormValues } from 'redux-form'

import withTranslation from '../../../../../globals/components/withTranslation'
import withPermissions from '../../../../../globals/components/withPermissions'

import Component from './component'
import { newCar, loadCar, saveCar } from '../../../../../store/actions/transport'
import { onCanAdd } from '../../../../../store/actions/profile'

const formName = 'add-car-to-autopark'
const formSelector = formValueSelector(formName)

export default compose(
  withTranslation,
  withPermissions,
  connect(
    state => {
      const { car, activeTab } = state.transport
      const canAdd = state.profile.canAdd
      return {
        initialValues: car.data,
        car: _.omit(car, ['data']),
        carType: formSelector(state, 'carType'),
        ownershipType: formSelector(state, 'ownershipType'),
        notes: formSelector(state, 'notes'),
        isLoadingSide: formSelector(state, 'isLoadingSide'),
        isLoadingTop: formSelector(state, 'isLoadingTop'),
        isLoadingRare: formSelector(state, 'isLoadingRare'),
        activeTab,
        canAdd,
        enteredValues: getFormValues(formName)(state),
      }
    },
    {
      newCar,
      loadCar,
      saveCar,
      onCanAdd
    }
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(Component)
