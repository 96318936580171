import React, { Component } from 'react'
// import { connect } from "react-redux"
// import { compose, withHandlers } from "recompose"
// import {
//   Link, //NavLink, Redirect
// } from "react-router-dom"
import classNames from 'classnames'

import { Field } from 'redux-form'
import WidgetBase from '../../shared/components/widget-base'
import {
  required,
  minPasswordLength,
  maxPasswordLength,
  matchToField,
} from '../../shared/form-validators'
import FieldBase from '../../UI/field-base'
import './remind-password.scss'

const STYLE_BASE = 'remind-password_'
export default class extends Component {
  constructor(props) {
    super(props)

    this.matchPassword = this.validatePasswords()
  }

  validatePasswords = () => {
    const { t } = this.props
    return matchToField('password', t('login.password.mismatch'))
  }

  render() {
    const {
      t,
      // handleSubmit,
      pristine,
      // reset,
      submitting,
      invalid,
      // onLoginMode,
    } = this.props

    return (
      <div className="row">
        <div className="col-md-offset-2 col-md-12 col-lg-offset-3 col-lg-10 col-xl-offset-4 col-xl-8">
          <WidgetBase title={t('password.recovery')}>
            {/* <div className="panel">
                <div className="panel-header">
                    <h4>восстановление пароля</h4>
                </div> */}
            <div className="panel-body">
              <div className="panel-content">
                <div className="panel-content-row">
                  <div className={classNames('panel-block', `${STYLE_BASE}wrapper`)}>
                    {/* <form className="panel-form"> */}
                    <div className="panel-form-row">
                      <div className="panel-block-inline">
                        <div>
                          <Field
                            name="password"
                            type="password"
                            component={FieldBase}
                            label={t('login.password.new')}
                            placeholder={t('login.password.new.placeholder')}
                            validate={[required, minPasswordLength, maxPasswordLength]}
                          />
                        </div>
                        <div>
                          <p className="meta">{t('password.rule.message.info')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="panel-form-row">
                      <div className="panel-block-inline">
                        <div>
                          <Field
                            name="password2"
                            type="password"
                            component={FieldBase}
                            label={t('login.password.repeat')}
                            placeholder={t('login.password.repeat.placeholder')}
                            validate={[required, this.matchPassword]}
                          />
                        </div>
                        <div />
                      </div>
                    </div>
                    <div className="panel-form-row">
                      <div className="panel-block-inline command-buttons">
                        <button
                          className="button button-orange"
                          disabled={pristine || submitting || invalid}
                          type="button"
                        >
                          {t('login.password.change')}
                        </button>
                      </div>
                    </div>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </WidgetBase>
        </div>
      </div>
    )
  }
}
