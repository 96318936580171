import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'

import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'
import { AdsTableBaseProps } from './types'

import Component from './component'

export default compose(
  withPermissions,
  withTranslation,
  withRouter
)(Component) as React.ComponentClass<AdsTableBaseProps>
