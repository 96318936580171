import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import TooltipWrapper from '../../../UI/popover-wrapper'
import dict from '../../../../components/shared/dictionaries'

interface BussinesTypesProps extends ComponentBaseProps, PermissionInjectProps {
  itemId: number
  businessTypes: any
  typesList: any
  langId: number
  searchBusinnesType: any
}

export default class BussinesTypes extends Component<BussinesTypesProps> {
  state = {
    bussinesTypeItems: null,
    businessTypesContent: [],
    companyBusinessItems: [],
  }
  componentDidMount() {
    const { langId, typesList } = this.props

    this.setState(
      {
        bussinesTypeItems: dict.businessTypes(langId),
      },
      () => {
        const {
          bussinesTypeItems: { handler },
        } = this.state
        if (typesList) {
          const companyBusinessItems = typesList.split(',')
          this.setState({ companyBusinessItems })
          this.promiseResolved(handler, companyBusinessItems)
        } else {
          this.promiseResolvedSetInState(handler)
        }
      }
    )
  }

  promiseResolvedSetInState = arr => {
    Promise.resolve(arr).then(arrList => {
      const { items } = arrList
      this.setState({ companyBusinessItems: items })
    })
  }

  promiseResolved = (arr, companyBusinessItems) => {
    Promise.resolve(arr).then(arrList => {
      const { items } = arrList
      this.setBussinesContent(items, companyBusinessItems)
    })
  }

  setBussinesContent = (libArr, companyBusinessItems) => {
    const businessNamesList = []
    companyBusinessItems.forEach(typeId => {
      libArr.forEach(library => {
        if (Number(library.value) === Number(typeId)) {
          businessNamesList.push({ bussinesName: library.name, bussinesId: typeId })
        }
      })
    })
    this.setState({
      businessTypesContent: businessNamesList,
    })
    return businessNamesList
  }

  renderBussinesContent = (businessTypesContent, showOnlyFirst: boolean = false) => {
    const content = businessTypesContent.map((line, i) => {
      if (showOnlyFirst) {
        if (i === 0) {
          return (
            <div key={i} className="one-buss-type">
              {line.bussinesName}
            </div>
          )
        }
      } else {
        return (
          <div key={i} className="one-buss-type">
            {line.bussinesName}
          </div>
        )
      }
    })
    return content
  }

  renderTooltip = businessTypesContent => {
    return (
      <TooltipWrapper text={this.renderBussinesContent(businessTypesContent)} hover>
        {this.renderBussinesContent(businessTypesContent, true)}
      </TooltipWrapper>
    )
  }

  showCommonSearchValue = () => {
    const { searchBusinnesType } = this.props
    const { businessTypesContent, companyBusinessItems } = this.state

    let bussinesName

    if (businessTypesContent.length === 0) {
      bussinesName = companyBusinessItems.map(el => {
        if (Number(el.value) === searchBusinnesType) {
          return el.name
        }
      })
    } else {
      bussinesName = businessTypesContent.map(el => {
        if (Number(el.bussinesId) === searchBusinnesType) {
          return (
            <TooltipWrapper key={el.bussinesId} text={this.renderBussinesContent(businessTypesContent)} hover>
              {el.bussinesName}
            </TooltipWrapper>
          )
        }
      })
    }

    return bussinesName
  }

  render() {
    const { t, itemId, businessTypes, searchBusinnesType } = this.props
    const { businessTypesContent } = this.state

    const showOldText = !businessTypes
      ? this.renderBussinesContent(businessTypesContent, true)
      : businessTypes

    const content =
      businessTypesContent.length > 1 ? this.renderTooltip(businessTypesContent) : showOldText

    const searchBussType = searchBusinnesType ? this.showCommonSearchValue() : content

    return (
      <div>
        <span className="mobile-prefix">{t('company.grid.business') + ':'}</span>
        <Link to={`/company/item/${itemId}`} target="_blank">
          {searchBussType}
        </Link>
      </div>
    )
  }
}
