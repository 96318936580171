import React, { Component } from 'react'
// import isArray from 'lodash/isArray'
import classNames from 'classnames'
// import { Link } from "react-router-dom"

export default class extends Component {
  static displayName = 'WidgetSettings'
  // constructor(props){
  //   super(props)
  //   this.state = {
  //     options: []
  //   }
  // }

  // // componentWillReceiveProps(nextProps) {
  // //   if (this.props.items !== nextProps.items) {
  // //     this.refreshItems(nextProps.items)
  // //   }
  // // }

  componentDidMount() {
    // this.refreshItems(this.props.items)
  }
  // componentDidUpdate(prevProps, prevState) {
  //   // if (this.props.items !== prevProps.items) {
  //   //   this.refreshItems(this.props.items)
  //   // }
  // }

  // refreshItems(data) {
  //   if (data) {
  //     if (isArray(data)) {
  //       this.setOptions(data)
  //     } else if (data.type === 'dictionary') {
  //       const { loading, loaded, handler, items } = data
  //       this.setState({ loading, loaded })

  //       if (loading) {
  //         handler.then(x => {
  //           const { loading, loaded, items: options = [], error } = x
  //           this.setOptions(options, { loading, loaded, error })
  //         })
  //       }
  //       else if (loaded) {
  //         this.setOptions(items, { loading, loaded })
  //       }
  //     }
  //   }
  //   else{
  //     this.setState({ loading: false, loaded: false, options: [] })
  //   }
  // }

  // setOptions = (options, props) => {
  //   options = options.filter(x => !!x) // filter out all undefined
  //   if (this.props.emptyOption) {
  //     options = [{ value: '', name: this.props.emptyOption }, ...options]
  //   }
  //   this.setState({ options, ...props })
  // }

  render() {
    const { className, children } = this.props
    // const { options } = this.state

    return (
      <div
        className={classNames({
          'panel-settings': true,
          [className]: !!className,
        })}
      >
        {children}
      </div>
    )
  }
}
