import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import TooltipWrapper from '../../UI/popover-wrapper'
import TooltipModal from '../../search/table/tooltip-modal'
import { SingleReviewProps } from './types'
import Rating from '../../UI/star-rating'
import fetch from '../../shared/fetch'
import { toastr } from 'react-redux-toastr'
import { canDo } from '../../../store/utils'
import classNames from 'classnames'

import './styles.scss'
export default class SingleReview extends React.Component<SingleReviewProps> {
  state = {
    delReviewID: 0,
  }

  renderDelimitedMessage = (message: string) => {
    return message.split('\n').map((part, id) => (
      <p key={id} style={{ wordBreak: 'break-word' }}>
        {part}
      </p>
    ))
  }

  deleteReview = () => {
    const { currentDeleteReviewId, refreshData, currentAuthorOrAdminUserId } = this.props
    fetch('/user/deleteReview', {
      method: 'POST',
      body: JSON.stringify({
        currentDeleteReviewId,
        currentAuthorOrAdminUserId
      }),
    })
      .then(res => {
        if (res.ok) {
          refreshData()
        }
      })
      .catch(err => toastr.error(err.message))
  }

  renderDeleteReviewPopup = (t, resetDeleteReviewId) => {
    return (
      <div className="popup-remove-comment">
        <div className="remove-container">
          <h1>{t('remove.review.note')}</h1>
          <button
            type="button"
            className="button button-orange"
            onClick={() => this.deleteReview()}
          >
            {t('delete')}
          </button>
          <button
            type="button"
            onClick={() => resetDeleteReviewId()}
            className="button button-orange button-blue"
          >
            {t('button.close')}
          </button>
          <div className="remove-note review-note">{t('review.note')}</div>
        </div>
      </div>
    )
  }

  renderDeleteReviewBtn = (reviewId, reviewerUserId = 0) => {
    const { delReviewID, t } = this.props
    return (
      <Link to="#" className="link-message" onClick={() => delReviewID(reviewId, reviewerUserId)}>
        {t('review.delete')}
      </Link>
    )
  }

  render() {
    const {
      t,
      review: { reviewId, grade, reviewer, answerFor, datePosted },
      reviewingUserId,
      userId,
      setResponsing,
      delReviewID,
      currentDeleteReviewId,
      resetDeleteReviewId,
      permissions,
    } = this.props
    
    const canDeleteReviews = canDo(permissions, 'profile', 'deleteReview')
    return (
      <div className="panel-content-row review-content">
        {currentDeleteReviewId === reviewId && this.renderDeleteReviewPopup(t, resetDeleteReviewId)}

        {reviewer && (
          <div className="panel-message">
            <p className="title">{reviewer.reviewTitle}</p>
            <div className="panel-message-header">
              <Rating averageRating={grade} />

              <TooltipWrapper
                text={<TooltipModal companyId={0} userId={reviewer.userId} />}
                hover
                innerClassName="full-tooltip"
                disabled={false}
                parentCustomClass="move-up-container"
              >
                <Link to={`/user/details/${reviewer.userId}`} className="name">
                  {reviewer.username}
                </Link>
              </TooltipWrapper>
              <span className="meta-gray">{moment(datePosted).format('DD/MM/YYYY HH:mm')}</span>
            </div>
            <div className="panel-message-content">{reviewer.reviewBody}</div>
            {answerFor?.answeredReview && (
              <div className="panel-message-gray review-asnwer">
                <p className="title-sm review-answer-date">
                  {moment(answerFor?.answerDate).format('DD/MM/YYYY HH:mm')}
                </p>
                {answerFor?.answeredReview}
              </div>
            )}
          </div>
        )}

        {canDeleteReviews
          ? this.renderDeleteReviewBtn(reviewId, reviewer.userId)
          : userId == reviewer.userId && this.renderDeleteReviewBtn(reviewId, userId)}

        {Number(reviewingUserId) === userId && (
          <Link
            to="#"
            className="link-message"
            onClick={() =>
              answerFor?.answeredReview
                ? delReviewID(reviewId, userId)
                : setResponsing({ reviewId, reviewTitle: reviewer.reviewTitle })
            }
          >
            {answerFor?.answeredReview ? t('review.answer.delete') : t('review.answer')}
          </Link>
        )}
      </div>
    )
  }
}
