import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import dayjs from 'dayjs'

import fetch from '../shared/fetch'
import { changeWidgetFilter } from '../shared/utility'
import WidgetList from '../shared/components/widget-list'

interface ISiteStatProps extends ComponentBaseProps, PermissionInjectProps {
  lastLogin: string
  langId?: any
}

interface ISiteStatState {
  items: IItem[]
  filterBar: any
}

interface IItem {
  icon: string
  name: string
  total: number
  description: string
}

export default class extends Component<ISiteStatProps, ISiteStatState> {
  constructor(props) {
    super(props)

    this.state = {
      items: [],
      filterBar: {},
    }
  }

  componentDidMount() {
    this.setFilterBar(() => {
      this.update()
    })
  }

  componentDidUpdate(prevProps) {
    const { langId } = this.props
    if ((this.props.isAuthenticated !== prevProps.isAuthenticated) || langId !== prevProps.langId) {
      this.setFilterBar(() => {
        this.update()
      })
    }
  }

  update = () => {
    const { lastLogin } = this.props
    const { selectedKey } = this.state.filterBar
    const dateFormat = dayjs(lastLogin).format('YYYY-MM-DD  HH:mm:ss.000')
    const  paramUrl = `filterKey=${selectedKey}`

    const items = [
      {
        icon: 'ico ico-cargo',
        name: 'cargo',
        total: 0,
        description: 'cargo',
        url: `/cargo/search?${paramUrl}`
      },
      {
        icon: 'fa fa-truck',
        name: 'transport',
        total: 0,
        description: 'transport',
        url: `/transport/search?${paramUrl}`
      },
      {
        icon: 'fa fa-bullhorn',
        name: 'statistics.news',
        total: 0,
        description: 'news',
        url: '/news/list'
      },
      {
        icon: 'fa fa-file-text',
        name: 'ads',
        total: 0,
        description: 'ads',
        url: '/ads/list/436'
      },
      {
        icon: 'ico ico-user',
        name: 'users',
        total: 0,
        description: 'users',
        url: '/company/users'
      },
      {
        icon: 'ico ico-user',
        name: 'companies',
        total: 0,
        description: 'companies',
        url: '/company/items'
      },
    ]

    fetch(`/common/getSiteStatistics/${selectedKey}/${dateFormat}`).then(result => {

      items.forEach(item => {
        item.total = result.data[item.description] || 0
      })

      this.setState({ items })
    })
  }

  setFilterBar = (callback?) => {
    const { t, isAuthenticated } = this.props

    const filterBar = {
      items: [
        {
          value: 'today',
          name: 'summary.today',
        },
        {
          value: 'all',
          name: 'total',
        },
      ],
      selectedKey: isAuthenticated ? 'from-last-visit' : 'all',
      onClick: filterKey => {
        changeWidgetFilter(this, filterKey, () => {
          this.update()
        })
      },
    }

    if (isAuthenticated) {
      filterBar.items.unshift({
        value: 'from-last-visit',
        name: 'ads.lastVist',
      })
    }

    this.setState({ filterBar }, () => callback && callback())
  }

  renderRow = ({ name, icon, total, url }, index) => {
    const { t } = this.props
    return (
      <div key={index} className="panel-content-row">
        <span className={classnames('icon col-bg', icon)} />
        <Link to={url} className="text">
          {t(name)}
        </Link>
        <span className="number">{total}</span>
      </div>
    )
  }

  renderRows = items => {
    return items.map((item, index) => this.renderRow(item, index))
  }

  render() {
    const { t, dataMoveForMobile } = this.props
    const { items, filterBar } = this.state

    return (
      <WidgetList
        title={t('statistics.site')}
        items={items}
        filterBar={filterBar}
        renderRows={this.renderRows}
        dataMoveForMobile={dataMoveForMobile}
      />
    )
  }
}
