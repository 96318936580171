import React from 'react'
import classNames from 'classnames'
import { Redirect } from 'react-router-dom'

import Spinner from '../../UI/spinner'
import Widget from '../../shared/components/widget'
import { PROFILE_MODE_EDIT } from '../../../store/constants'
import ProfileForm from '../profile-form'

import './profile.scss'

const STYLE_BASE = 'profile_'

export default ({ isAuthenticated, loading, userId, username, email, t }) => {
  const onSaved = () => {
    //props.history.push('/')
  }

  if (!isAuthenticated) {
    return <Redirect to="/" />
  }

  return (
    <Widget title={t('profile.editing')} className={classNames(`${STYLE_BASE}wrapper`)}>
      {loading ? (
        <div className={classNames(`${STYLE_BASE}info`)}>
          <Spinner />
        </div>
      ) : (
        <ProfileForm
          mode={PROFILE_MODE_EDIT}
          onSaved={onSaved}
          userId={userId}
          username={username}
          email={email}
        />
      )}
    </Widget>
  )
}
