import { connect } from 'react-redux'
import { compose } from 'recompose'
import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'
import { getGlobalUserRating, getGlobalCanUserAddReview } from '../../../store/actions/profile'
import Component from './component'

import { IReduxStorage } from '../../../store/reducers/types'

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state: IReduxStorage) => {
     
      return { 
        isAuth: !!state.auth.accessToken,
        langId: state.ui.langId,
      }
    },
    {
      getGlobalUserRating,
      getGlobalCanUserAddReview
    }
  )
)(Component) as React.ComponentClass