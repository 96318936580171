export const int4 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Cum se completeaza un CMR</h4><p></p>

<p style="text-indent:2em;">Uniunea Transportului Rutier International (IRU) a revizuit modelul scrisorii de trasura CMR cu prilejul adunarii generale de luna trecuta.</p><br>

<p style="text-indent:2em;">Modificarile se refera la forma documentului stabilita in 1976 si au scopul de a valorifica experienta celor trei decenii de folosinta a primei variante si de a tine pasul
 cu evolutia practicilor din transport. IRU a facut si precizari despre modul in care se recomanda a fi completata noua scrisoare CMR de catre transportator si partenerii sai
 pentru a-si proteja cat mai bine interesele, iar Asociatia Romana pentru Transport Rutier International (ARTRI) a publicat un document care reuneste aceste recomandari. Potrivit
 informatiilor furnizate de ARTRI, scrisoarea CMR este tiparita in 4 exemplare:– rosu (exemplarul pentru expeditorul marfii);</p><br>
 
<p>albastru (exemplarul pentru destinatar care insoteste marfa pe toata perioada transportului);</p><br>

<p>verde (exemplarul pentru transportator);</p><br>

<p>negru (exemplarul rezervat demersurilor administrative).</p><br>

<p style="text-indent:2em;">In mod normal, toate aceste exemplare trebuie sa fie completate identic, dar este posibil ca in momentul completarii scrisorii CMR sa nu fie cunoscute toate informatiile
necesare (de exemplu date referitoare la transportatorii succesivi).</p><br>

<p>Aceste date pot fi adaugate mai tarziu, doar in exemplarele disponibile.</p>

<p style="text-indent:2em;"></p><h6>Se recomanda completarea casutelor de pe formular dupa cum urmeaza:</h6><p></p>

<p><b>Campul 1</b> – Numele si adresa completa a expeditorului marfii.</p><br>

<p><b>Campul 2</b> – Numele si adresa completa a destinatarului marfii.</p><br>

<p style="text-indent:2em;"><b>Campul 3</b> – Data si locul preluarii marfii de catre transportator. Se recomanda precizarea orei la care vehiculul a sosit la locul incarcarii, precum si ora plecarii. Aceste mentiuni 
sunt utile in cazul imobilizarii prelungite a vehiculului, din vina expeditorului, pentru eventualele plati suplimentare cerute de transportator.</p><br>

<p style="text-indent:2em;"><b>Campul 4</b> – Locul prevazut pentru livrarea marfii. Se recomanda sa se solicite expeditorului comunicarea orei exacte de deschidere a depozitului/depozitelor unde trebuie livrata marfa. O 
asemenea precizare poate permite evitarea unei asteptari inutile si costisitoare a transportatorului in cazul in care acesta ajunge la destinatie cand depozitul este inchis.</p><br>

<p style="text-indent:2em;"><b>Campul 5</b> – Instructiuni speciale ale expeditorului marfii. Daca este necesar, expeditorul poate preciza anumite instructiuni referitoare la formalitatile vamale,
 interdictia de transbordare a marfii, asigurarea marfii etc.</p><br>
 
<p><b>Campul 6</b> – Numele, adresa exacta a transportatorului si alte referinte considerate necesare.</p><br>

<p style="text-indent:2em;"><b>Campul 7</b> – Transportator(i) succesiv(i). Casuta este destinata cazurilor in care transportarea marfii este efectuata de catre mai multi transportatori care se succeda.
 Se recomanda completarea in momentul in care transportatorul succesiv preia marfa, macar pe copia CMR care va ramane la destinatarul marfii (exemplarul albastru) si pe
 copia destinata procedurilor administrative (exemplarul negru). Daca se stie ce transportatori succesivi vor fi implicati in transport, este important ca exemplarul pentru
 destinatar al scrisorii (cel albastru) sa contina macar indicatii despre ultimul transportator, cel care va aduce marfa la destinatie. Transportatorul succesiv trebuie sa scrie
 data si sa semneze in casuta (rubricile „marchandise reçue et acceptée“ – marfa livrata si acceptata, „date“ – data). El poate de asemenea sa faca observatii privind numarul de
 colete, marcarea si numerotarea acestora, starea aparenta a marfii si a ambalajului acesteia in momentul preluarii. In cazul in care spatiul din casuta e insuficient, observatiile
 pot fi inscrise in alt loc (de exemplu pe spatele formularului CMR). Aceste observatii trebuie validate de catre transportatorul care a efectuat partea precedenta din operatiunea
 de transport.</p><br>
 
<p style="text-indent:2em;"><b>Campul 8</b> – Observatii ale transportatorului in momentul preluarii marfii (numarul coletelor, marcarea si numerotarea acestora, starea aparenta a marfii si a ambalajului etc). Acestea
 trebuie validate de catre expeditorul marfii, altfel nu vor fi luate in considerare.</p><br>
 
<p style="text-indent:2em;"><b>Campul 9</b> – Lista documentelor predate transportatorului de catre expeditorul marfii (de exmplu: „packing list“ – lista coletelor, certificatele de origine ale marfurilor etc).</p><br>

<p style="text-indent:2em;"><b>Campurile 10-15</b> cuprind descrierea uzuala a marfurilor transportate, inclusiv, daca este cazul, indicatiile speciale privind marfurile periculoase.</p><br>

<p style="text-indent:2em;"><b>Campul 16</b> – Conventiile speciale stabilite intre expeditorul marfii si transportator (valoarea declarata a marfii si suma reprezentand un interes deosebit la livrare, termenul convenit
 pentru livrare, posibilitatea de a folosi vehicule deschise si fara prelata, utilizarea paletilor, indicarea persoanei care raspunde de incarcare, amarare si descarcare, acceptarea
 transportarii cu feribotul, jurisdictia aplicabila etc).</p><br>
 
<p style="text-indent:2em;"><b>Campul 17</b> – Indicatii privitoare la valoarea diferitelor plati referitoare la contractul de transport stabilit si a partii care trebuie sa efectueze plata. Daca transportatorul
 nu vrea sa precizeze valoarea exacta a sumelor datorate, este recomandat sa precizeze macar cine trebuie sa efectueze plata.</p><br>
 
<p style="text-indent:2em;"><b>Campul 18</b> – Precizari utile altor parti sau autoritatilor competente. Poate fi vorba despre numarul de inmatriculare al vehiculului, sarcina sa utila, greutatea neta a marfii, 
numarul carnetului TIR sau al oricarui alt document vamal utilizat pe perioada transportului etc.</p><br>

<p style="text-indent:2em;"><b>Campul 19</b> – Indicarea sumei rambursate de catre destinatar si care trebuie incasata de catre transportator la livrarea marfii. Conform Conventiei CMR (art. 21) transportatorul
 este obligat sa plateasca expeditorului marfii exact suma pe care o are de platit destinatarul, daca aceasta suma nu a fost incasata la primirea marfii.</p><br>
 
<p><b>Campul 20</b> – Referinta obligatorie la valorile predominante ale dispozitiile Conventiei CMR.</p><br>

<p><b>Campul 21</b> – Locul si data intocmirii scrisorii CMR.</p><br>

<p><b>Campul 22</b> – Semnatura sau timbrul expeditorului marfii.</p><br>

<p><b>Campul 23</b> – Semnatura sau timbrul transportatorului.</p><br>

<p style="text-indent:2em;"><b>Campul 24</b> – Semnatura si timbrul destinatarului confirmand livrarea marfii, indicarea locului, a datei si a orei de sosire a vehiculului la locul livrarii precum si ora plecarii
 acestuia dupa descarcare. Aceste precizari sunt utile in cazul imobilizarii prelungite a vehiculului de catre destinatarul marfii, pentru eventualele plati suplimentare cerute de
 transportator.</p><br>
 
<p style="text-indent:2em;">Scrisoarea de trasura CMR mai contine rubrica „Partie non contractuelle“ – Parte ne-contractanta, rezervata observatiilor transportatorului (despre birouri vamale de utilizat,
 distante de parcurs etc, adica indicatii utile, dar care nu antreneaza obligatii contractuale pentru transportatorul in cauza).</p><br>
 
 <p><a href="https://cmr.transportator.info/cum-se-completeaza-un-cmr/">Sursa</a></p>



</div>
`