import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import moment from 'moment'
import FlexBand from 'flexband'

import Spinner from '../../UI/spinner'
import TooltipWrapper from '../../UI/popover-wrapper'
import WidgetBase from '../../shared/components/widget-base'
import { canDo, addScrollBar } from '../../../store/utils'
import { formatDDMMYYYY } from '../../../store/constants'
import ProfileMini from '../../user/profile-mini'
import dict from '../../shared/dictionaries'
import RoutingService from '../../shared/routingService'
import Map from '../../UI/openstreetmaps-widget'
import CounterOffers from '../counter-offers'
import { DelayTimer } from '../table/types'
import ruWordNumeralEnding from '../../shared/ruWordNumeralEnding'
import { transportPlaceholder } from '../../shared/utils/imagePlaceholder'
import ItemModal from '../../transport/modal-item'
import getCoords from '../../shared/utils/getCoords'
import { ModalConsumer, ModalContextState } from '../../../context/modal-context'
import Banner from '../../banner'
import config from '../../../config'
import { SearchItemProps, SearchItemState } from './types'
import { getDateCreateInMinutes } from "../../../store/utils"

import './styles.scss'

const STYLE_BASE = 'cargo-item_'

export default class extends Component<SearchItemProps, SearchItemState> {
  transportTypes: any[]
  transportTypesHandler: any
  containerTypes: any
  timerWorker: DelayTimer
  routingService: RoutingService
  modalConsumer: ModalContextState
  constructor(props: SearchItemProps) {
    super(props)

    this.state = {
      itemId: undefined,
      distance: 0,
      timeRemaining: 0,
      config: {},
      reserveCarPhotoPath: "",
      pathToShowPhotoInSlider: "transport",
      isChangingStatus: false,
      loadedItem: {},
      toggleFavorite: false,
    }

    this.timerWorker = new DelayTimer({
      timerUpdate: (timerId, timeRemaining) => {
        this.setState({ timeRemaining })
      },
      timerEnd: () => {
        this.refresh(this.props.itemId)
      },
    })

    this.transportTypes = []
    this.transportTypesHandler = dict.transportTypesAuto(props.langId).handler
    this.containerTypes = dict.getTransportTypes("container")(props.langId)
    this.routingService = new RoutingService()
  }

  componentDidMount() {
    this.setState({ config })
    this.transportTypesHandler.then(result => {
      this.transportTypes = result.items || []
      this.refresh(this.props.itemId)
    })
  }
  componentDidUpdate(prevProps) {
    const { itemId } = this.props
    if (this.props.itemId !== prevProps.itemId) {
      this.refresh(+itemId)
    }
  }

  componentWillUnmount() {
    this.timerWorker.cancelAllTimers()
  }

  onShowChanginStatus = () => {
    setTimeout(() => {
      this.setState({
        isChangingStatus: false,
      })
    }, 1000);
  }

  clickChangeActive = () => {
    const { changeActiveItem, itemId, onCanAdd, searchType } = this.props
    this.setState({
      isChangingStatus: true
    }, () => {
      changeActiveItem({ itemId }).then(() => {
        this.onGetItem(+itemId)
        onCanAdd(searchType)
        this.onShowChanginStatus()
      })
    })

  }

  onGetItem = (itemId) => {
    const { isModalView, getItem } = this.props
    getItem({ itemId }).then(item => {
      this.setState({ loadedItem: item })
      const { refDate, viewTimeout, from, to, middlePoints } = item
      this.setState({
        toggleFavorite: item.isFavourite
      })
      if (viewTimeout > 0) {
        const newDateCreatedMinute = getDateCreateInMinutes(refDate)
        this.timerWorker.setTimer(Number(itemId), newDateCreatedMinute, viewTimeout)
      }
      if (isModalView && from.coords && to.coords) {
        const coords = getCoords(from.coords, to.coords, middlePoints, 'lng')

        this.routingService
          .getDistance(coords)
          .then(distance => this.setState({ distance }))
          .catch(e => console.log(e))
      }
    })
  }

  refresh(itemId) {
    const { onCanAdd, searchType, isAuth } = this.props

    const { loading = false } = this.props.item || {}
    if (itemId !== this.state.itemId && !loading) {
      this.setState({ itemId })
      isAuth && onCanAdd(searchType)
      this.onGetItem(itemId)
    }
  }

  signedNumber = v => (v > 0 && this.props.searchType === 'cargo' ? `+${v}` : `${v || 0}`)

  changeFavorite = item => {
    const { addFavourite, deleteFavourite } = this.props
    const { toggleFavorite } = this.state
    if (!toggleFavorite) {
      addFavourite({ entityId: item.itemId, isItem: true })
    } else {
      deleteFavourite({ entityId: item.itemId, isItem: true })
    }
    this.setState({
      toggleFavorite: !toggleFavorite
    })
  }

  getVolume = (m3: string, ldm: string, pal: string) => {
    if (!m3 && !ldm && !pal) {
      return '-'
    }
    return [
      m3 && (
        <span key="m3">
          {m3}m<sup>3</sup>
          {(ldm || pal) && ','}
        </span>
      ),
      ldm && (
        <span key="ldm">
          {ldm} LDM{pal && ','}
        </span>
      ),
      pal && <span key="pal">{pal} PAL</span>,
    ]
  }
  getPaymentTypes = price => {
    const { t } = this.props

    const arr = []
    if (price?.crg_isCash || price?.is_cash) arr.push(t('cargo.cash'))
    if (price?.crg_isNonCash || price?.is_non_cash) arr.push(t('cargo.non.cash'))
    if (price?.crg_isCombo || price?.is_combo) arr.push(t('cargo.combo'))
    if (price?.crg_isOnLoading || price?.is_on_loading) arr.push(t('cargo.on.loading'))
    if (price?.crg_isOnUnloading || price?.is_on_unloading) arr.push(t('cargo.on.unloading'))
    if (price?.crg_isUponDocument || price?.is_by_docs) arr.push(t('cargo.upon.document'))
    if (price?.crg_isPrepayment || price?.is_prepayed) arr.push(t('cargo.prepayment'))
    if (price?.crg_isDeferred || price?.is_deffered) arr.push(t('cargo.deferred'))
    return arr.length ? arr.join(', ') : '-'
  }
  getLoading = cargo => {
    const { t } = this.props
    const loading = []
    cargo.loadingTop && loading.push(t('cargo.loading.top').toLowerCase())
    cargo.loadingSide && loading.push(t('cargo.loading.side').toLowerCase())
    cargo.loadingRare && loading.push(t('cargo.loading.rare').toLowerCase())
    return loading.length ? loading.join(', ') : '-'
  }
  getDocuments = (cargo, type) => {
    const { t } = this.props
    const documents = []
    cargo.crg_tir && documents.push('TIR')
    cargo.crg_cmr && documents.push('CMR')
    cargo.crg_declaration ? type === "transport" ? documents.push(t('cargo.doc.custom')) : documents.push(t('cargo.on.declaration')) : null
    cargo.crg_custom_control && documents.push(t('under.t1.declaration'))
    cargo.crg_cmr_insurance && documents.push(t('cargo.insurance.cmr'))
    return documents.length ? documents.join(' / ') : '-'
  }
  getOversizeGrouping = cargo => {
    const { t } = this.props
    const addressClass = cargo.crg_adr_class || cargo.transport_adr_class
    const miscs = []
    cargo.crg_grouping && miscs.push(t('cargo.grouping'))
    //cargo.crg_adr && miscs.push(`ADR${addressClass ? `-${addressClass}` : ''}`)
    cargo.crg_oversized && miscs.push(t('cargo.oversize'))
    cargo.crg_fragile && miscs.push(t('cargo.fragile'))

    return miscs.length ? miscs.join(' / ') : '-'
  }
  getRecurring = entity => {
    const { t } = this.props
    const recurring = entity.transport_recurring || entity.car_recurring
    return t(dict.truckRecurings.find(x => x.value === recurring)?.name) || '-'
  }

  setSingleCargoDistance = distance => {
    this.setState({ distance })
  }

  getTransportTypes(types: number[] | number, shippingType: string) {
    if (!types) return

    const transportTypes = []
    if (typeof types === 'object') {
      types.forEach(item => {
        const typesObj = shippingType === "container" ? this.containerTypes.items : this.transportTypes
        const trTypeObj = typesObj.find(trType => item == trType.value)

        if (trTypeObj && trTypeObj.value) {
          transportTypes.push(trTypeObj.name)
        }
      })
    } else {
      transportTypes.push(this.transportTypes.find(trType => types == trType.value))
    }

    return transportTypes.join(', ')
  }

  getShortAddress = address => {
    if (!address) return
    const array = address.split(', ')
    if (!array[1]) return
    return `${array[0]}, ${array[1]}`
  }

  showItemModal = (item: any, edit: boolean, placeholder, pathToShowPhotoInSlider) => {
    const { showModalComponent, clearModal } = this.modalConsumer

    const filteredPhotos = item.photos.filter(photo => photo)

    if (!filteredPhotos.length) {
      item.photos = [placeholder]
    } else {
      item.photos = filteredPhotos
    }

    showModalComponent(
      <ItemModal
        item={item}
        onClose={clearModal}
        canEdit={edit}
        imageFolder={`${pathToShowPhotoInSlider}/${item.userId}`}
        refresh={() => { return true }}
      />,
      {
        className: `transport-modal`,
        skipLayout: true,
        backdrop: true,
      }
    )
  }

  clearModal = () => {
    const { clearModal } = this.modalConsumer
    addScrollBar()
    clearModal()
  }

  renderCargoContent = (searchType, entity, t, item) => {
    const { volume_m3, volume_ldm, volume_pal } = entity

    const { transportTypes, transportNumber, shippingType } = item

    return (
      <div className="border-top">
        <div className="panel-offer-content-row">
          <div className="panel-offer-content-col">
            <span className="meta">{t(`${searchType}.type`)}</span>
            <span className="name">{`${entity.name || '-'}`}</span>
          </div>
          <div className="panel-offer-content-col panel-offer-content-col-sm">
            <span className="meta">{t('cargo.weight')}</span>
            <span className="name">{`${entity.weight + entity.weightUnit || '-'}`}</span>
          </div>
          <div className="panel-offer-content-col">
            <span className="meta">{t('cargo.volume')}</span>
            <span className={classNames('name', `${STYLE_BASE}volumn-wrapper`)}>
              {this.getVolume(volume_m3, volume_ldm, volume_pal)}
            </span>
          </div>
          {entity.temperature && <div className="panel-offer-content-col">
            <span className="meta">{t('cargo.temperature.mode')}</span>
            {entity.temperature ? (<span className="name">
              {t('autopark.from')} {entity.temperature.from > 0 && '+'} {entity.temperature.from || 0}{'\u2103 '}
              {t('autopark.to')} {entity.temperature.to > 0 && '+'}
              {entity.temperature.to || 0}{'\u2103 '}
            </span>) : '-'}
          </div>}
        </div>
        <div className="panel-offer-content-row">
          <div className="panel-offer-content-col">
            <span className="meta">{t('cargo.shipping.type')}</span>
            <span className="name">{t(shippingType)}</span>
          </div>
         {shippingType !== 'avia' &&  <div className="panel-offer-content-col panel-offer-content-col-sm">
            <span className="meta">{t(shippingType === 'auto' ? 'cargo.transport.number' : 'transport.container.number')} </span>
            <span className="name">{transportNumber || '-'}</span>
          </div>}
          <div className="panel-offer-content-col">
            <span className="meta">{t('cargo.regularity')}</span>
            <span className="name">{this.getRecurring(entity)}</span>
          </div>
          {searchType === 'cargo' && shippingType !== 'avia' && (
            <div className="panel-offer-content-col panel-offer-content-col-clear">
              <span className="meta">{t(shippingType === 'auto' ? 'transport.required.type' : 'container.required.type')} </span>
              <span className="name">{this.getTransportTypes(transportTypes, shippingType) || '-'}</span>
            </div>
          )}
          <div className="panel-offer-content-col panel-offer-content-col-clear">
            <span className="meta">{t('cargo.loading')}</span>
            <span className="name">{this.getLoading(entity)}</span>
          </div>
        </div>
      </div>
    )
  }

  handleImageError = e => {
    const { item: _item, searchType, } = this.props
    const { config } = this.state
    const item = searchType === 'transport' ? _item : _item.toJS()
    const {
      userId: authorId,
      photos,
      car,
    } = item

    // UUID - universally unique identifier
    const photoUUID = photos && photos.find(photo => photo)
    const placeholderImage = transportPlaceholder(car?.car_type_id)
    const photoPath = photoUUID
      ? `url(${config.apiUrl}/common/file/auto-park/${authorId}/${photoUUID})`
      : `url(${placeholderImage})`

    this.setState({ reserveCarPhotoPath: photoPath, pathToShowPhotoInSlider: "auto-park" })
  }

  renderTransportContent = () => {
    const { t, item: _item, searchType, langId } = this.props
    const { config, reserveCarPhotoPath, pathToShowPhotoInSlider, timeRemaining } = this.state
    const item = searchType === 'transport' ? _item : _item.toJS()
    const {
      [searchType]: entity = {},
      transportNumber,
      userId: authorId,
      shippingType,
      photos,
      car,
      trailer,
    } = item
   
    const ownershipTypesDict = dict.ownershipTypesCars(t)
    const ownership = ownershipTypesDict.find(o => o.value === entity.ownershipType)
    const ownType = ownership?.name

    const carTypes = dict.carTypes(t)
    const itemCarType = carTypes.find(type => type.value === car?.car_type_id)
    // UUID - universally unique identifier
    const photoUUID = photos && photos.find(photo => photo)
    const placeholderImage = transportPlaceholder(car?.car_type_id)

    const imgFullPath = authorId && photoUUID 
        ? `${config.apiUrl}/common/file/transport/${authorId}/${photoUUID}`
        : placeholderImage

    const photoPath = authorId && photoUUID
      ? `url(${config.apiUrl}/common/file/transport/${authorId}/${photoUUID})`
      : `url(${placeholderImage})`

    const { volume_m3, volume_ldm, volume_pal } = entity

    let carType = ''
    let carTrailer = ''
    let carTrailerInfo

    if (itemCarType) {
      const firstWord = itemCarType?.name.split(' ')
      if (firstWord) {
        carType = firstWord[0]
      }
      if (firstWord && firstWord[1]) {
        carTrailer = `${firstWord[1]}  ${firstWord[2]}`
      }
      let itemTrailerBodyType = { name: '' }

      const bodyTypes = dict.getTransportTypes(shippingType)(langId)
      if (bodyTypes) {
        itemTrailerBodyType = bodyTypes.items.find(type => type.value === trailer?.body_type_id)
      }

      carTrailerInfo = shippingType !== "container" && carTrailer && `${carTrailer} (${itemTrailerBodyType?.name})`
    }

    return (
      <div className="row transport-data">
       {!timeRemaining && shippingType === 'auto' && <div className="col-sm-4">
          <img src={imgFullPath} alt={"Transport Car"} className="img-trigger-to-show-car-photo-from-autopark" onError={this.handleImageError} />
          <div
            className="img-container transport-container"
            data-popup-link="one-transport"
            style={{ backgroundImage: reserveCarPhotoPath ? reserveCarPhotoPath : photoPath }}
            onClick={() => this.showItemModal(item, false, placeholderImage, pathToShowPhotoInSlider)}
          />
        </div>}
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-4">
              <span className="meta">{t('cargo.shipping.type')}</span>
              <span className="name">{t(shippingType)}</span>
            </div>
            <div className="col-sm-12">
              <span className="meta">{shippingType !== 'container' ? t('transport.nodel.view') : t('transport.container.view')}</span>
              <span className="name">
                {shippingType !== 'container' ? `${carType} (${entity.name || '-'}) ${carTrailerInfo || ""}`
                  : this.getTransportTypes(entity.incoterms?.split(','), shippingType) || '-'}
              </span>
            </div>
          </div>
          <div className="row">
           {shippingType !== 'container' && <div className="col-sm-4">
              <span className="meta">{t('eco-standard')}</span>
              <span className="name">{shippingType !== 'container' && (entity.ecoStandard || entity.ecoStandard === 0) ? `Euro -${entity.ecoStandard}` : "-"}</span>
            </div>}
            <div className="col-sm-6">
              <span className="meta">{t('loading.unloading')}</span>
              <span className="name">{this.getLoading(entity)}</span>
            </div>
            {entity.temperature && <div className="col-sm-6">
              <span className="meta">{t('cargo.temperature.mode')}</span>
              <span className="name">
                {entity.temperature ? (<span className="name">
                  {t('autopark.from')}{' '}
                  {entity.temperature.from !== "null" ? entity.temperature.from || 0 : ' 0'}{'\u2103 '}
                  {t('autopark.to')}{' '}
                  {entity.temperature.to !== "null" ? entity.temperature.to || 0 : ' 0'}{'\u2103 '}
                </span>) : '-'}
              </span>
            </div>}
          </div>
          <div className="row">
            {ownType && <div className="col-sm-4">
              <span className="meta">{t('car.ownership')}</span>
              <span className="name">{ownType || '-'}</span>
            </div>}
            <div className="col-sm-6">
              <span className="meta">{t('total.lifting.capacity')}</span>
              <span className="name">{`${entity.weight ? entity.weight || 0 : '-'}`}{entity.weightUnit}</span>
            </div>
            <div className="col-sm-6">
              <span className="meta">{t('cargo.volume')}</span>
              <span className={classNames('name', `${STYLE_BASE}volumn-wrapper`)}>
                {this.getVolume(volume_m3, volume_ldm, volume_pal)}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <span className="meta">{t(shippingType === 'auto' ? 'cargo.transport.number' : 'transport.container.number')} </span>
              <span className="name">{transportNumber || '-'}</span>
            </div>
            <div className="col-sm-6">
              <span className="meta">{t('cargo.regularity')}</span>
              <span className="name">{this.getRecurring(entity)}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  onClose = () => {
    const { onClose } = this.props
    addScrollBar()
    onClose()
  }

  showPriceTypeText = (type, t) => {
    let text

    switch (type) {
      case 2:
        text = dict.priceTypes.find(item => item.value === 2)?.name || "-"
        break;

      default:
        text = dict.priceTypes.find(item => item.value === 3)?.name || "-"
        break;
    }

    return t(text)
  }

  showItem() {
    const {
      t,
      userId,
      permissions,
      isModalView = false,
      itemId,
      searchType,
      onClose,
      langId,
      isAuthenticated,
      canAdd,
      showDateIsShort = false
    } = this.props
    const { distance, timeRemaining, isChangingStatus, loadedItem: item, toggleFavorite } = this.state

    // if (!item.active && !isModalView) return
    const {
      from = {},
      to = {},
      [searchType]: entity = {},
      userId: authorId,
      insertedOn,
      middlePoints,
      active,
    } = item
    const isAuthor = userId === Number(authorId)
    const canEdit = isAuthor || canDo(permissions, searchType, 'edit')
    const canDeactivate = isAuthor || canDo(permissions, searchType, 'delete')
    const { price, priceType, priceRecurring } = entity
    const distanceUnit = t('unit.distance.km')

    const incoTypes = dict.incotermsTypes(langId)
    const incotermsType = (incoTypes && incoTypes.items || []).find(el => el.value === item.cargo?.incoterms)

    const itemContent =
      searchType === 'cargo'
        ? this.renderCargoContent(searchType, entity, t, item)
        : this.renderTransportContent()
    const recurringLabel = dict.priceRecurrings.find(item => item.value === priceRecurring)?.name || ""
    const docsLabel = searchType === "cargo" ? t('required.documents') : t('available.documents')

    return (
      <Fragment>
        <WidgetBase
          title={`${t(`${searchType}.item.title`)} (#${itemId})`}
          headerMenu={() => {
            if (isModalView) {
              return (
                <div className="modal-close-button" onClick={() => this.onClose()}>
                  <i className="fa fa-times modal-close-icon" />
                </div>
              )
            }
          }}
          className="panel-static"
        >
          <FlexBand
            className="panel-header-menu cargo-transport-actions"
            justify="space-between"
            alignItems="center"
          >
            <FlexBand className="panel-header-menu-item" justify="flex-start">
              <span className="meta">
                {`${t('added')}: `}
                <i className="fa fa-clock-o" />
                <span> {moment(insertedOn).format('HH:mm')} </span>
                <i className="fa fa-calendar" />
                <span> {moment(insertedOn).format(formatDDMMYYYY)} </span>
              </span>
              <span className="meta" style={{margin: "0px 10px"}}>{`${t('views')}: ${item.numViews}`}</span>
              <Link to="#" className="icon icon-print" style={{marginRight: "10px"}}>
                <i className="fa fa-print" />
              </Link>
              {isAuthenticated && (
                <span
                  className={`${toggleFavorite ? 'favorite-active-color' : 'favorite-color'
                    } favorite`}
                  onClick={() => this.changeFavorite(item)}
                >
                  <i className="fa fa-bookmark" />
                </span>
              )}
            </FlexBand>
            <FlexBand justify="space-between" className="actions-panel">
              {canDeactivate && (
                <Link
                  key={0}
                  to="#"
                  className={classNames({
                    'col-red': active,
                    'col-green': !active,
                    'action-el': true,
                    'disabled-styles': !canAdd && !active,
                    'is-changing-status': isChangingStatus
                  })}
                  onClick={() => (canAdd || active) && this.clickChangeActive()}
                >
                  {isChangingStatus ? <Spinner /> : (
                    <>
                      {!canAdd && !active && <TooltipWrapper text={t(`${searchType}.limit`)} hover>
                        <i className="fa fa-question-circle" style={{ marginRight: 3 }} />
                      </TooltipWrapper>}
                      {active ? t('deactivate') : t('activate')}
                    </>
                  )}
                </Link>
              )}
              {canEdit && (
                <Link
                  key={2}
                  to={`/${searchType}/edit/${itemId}`}
                  className="action-el"
                  onClick={onClose}
                >
                  {t('edit')}
                </Link>
              )}
              {isModalView && (
                <Link to={`/${searchType}/${itemId}`} target="_blank" className="action-el">
                  {t('cargo.open.new.page')}
                </Link>
              )}
            </FlexBand>
          </FlexBand>

          <div className="panel-offer">
            <div className="panel-offer-header">
              <div className="panel-header-flex-block additional-km">
                <div className="panel-address">
                  <span className="name">
                    <span className="country-abbr" title={from.countryName}>
                      ({from.countryAbbr}){' '}
                    </span>
                    <TooltipWrapper text={from.address} hover disabled={!from.address}>
                      <span className="city-block">
                        <div className="city-name">{from.city || ''}</div>
                        {from.radius && (
                          <div className="city-add-km">
                            +{from.radius} {t('unit.distance.km')}
                          </div>
                        )}
                      </span>
                    </TooltipWrapper>
                  </span>
                  <span className="address-meta">{this.getShortAddress(from.address)}</span>
                </div>
                <span className="meta">
                  {distance > 0 && this.state.distance + ' ' + distanceUnit}
                </span>
                <div className="panel-address">
                  <span className="name fl-start">
                    <span className="country-abbr" title={to.countryName}>
                      ({to.countryAbbr}){' '}
                    </span>
                    <TooltipWrapper text={to.address} hover disabled={!to.address}>
                      <span className="city-block">
                        <div className="city-name">{to.city || ''}</div>
                        {to.radius && (
                          <div className="city-add-km">
                            +{to.radius} {t('unit.distance.km')}
                          </div>
                        )}
                      </span>
                    </TooltipWrapper>
                  </span>
                  <span className="address-meta">{this.getShortAddress(to.address)}</span>
                </div>
              </div>
            </div>
            <div className="panel-offer-content">
              <FlexBand>
                {!isModalView && (
                  <div className={`${STYLE_BASE}map`}>
                    <div className="map-block">
                      <Map
                        readonly
                        mapPoints={{
                          from: {
                            coords: from.coords,
                          },
                          to: {
                            coords: to.coords,
                          },
                          middlePoints: middlePoints ? middlePoints : [],
                        }}
                        setSingleCargoDistance={this.setSingleCargoDistance}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={classNames({
                    'cargo-info ': true,
                    'cargo-info-modal': isModalView,
                  })}
                >
                  <div data-fix-col="true">
                    {middlePoints && middlePoints[0] && (
                      <div className="panel-offer-content-row">
                        <span className="meta middle-points">{t('cargo.middle.points')}</span>
                        {middlePoints.map((point, index) => {
                          return (
                            <div key={`middlePoint${index}`} className="middle-points">
                              {index + 1}) {point.value}
                            </div>
                          )
                        })}
                      </div>
                    )}
                    <div className="panel-offer-content-row border-bottom">
                      <div className="panel-offer-content-col">
                        <span className="meta">{t('cargo.loading.period')}</span>
                        <span className="name">
                          {`${moment(from.date).format('DD.MM.YYYY')} - 
                          ${moment(to.date).format('DD.MM.YYYY')}`}
                        </span>
                      </div>
                    </div>
                    {itemContent}
                    <div className="panel-offer-content-row additional-class">
                      {!(item.shippingType === "container" && searchType === 'transport') &&
                        <div className="panel-offer-content-col">
                          <span className="meta">{item.shippingType === "container" ? "Incoterms® 2020" : docsLabel}</span>
                          <span className="name">{item.shippingType === "container" ? incotermsType && incotermsType.name || '-' : this.getDocuments(entity, searchType)}</span>
                        </div>}
                      <div className="panel-offer-content-col panel-offer-content-col-clear">
                        <span className="meta">{t('other')}</span>
                        <span className="name">
                          {entity.crg_adr && (
                            <div className="adr-container">
                              <span>
                                <i className="fa fa-fire"></i> ADR
                                {searchType === 'cargo' ? `(${entity.crg_adr_class})` : `(${entity.transport_adr_class})`}
                              </span>
                            </div>
                          )}
                          {entity.crg_grouping && (
                            <div className="adr-container">
                              <span>
                                <i className="fa fa-th-large"></i> {t('cargo.grouping')}
                              </span>
                            </div>
                          )}
                          {entity.crg_oversized && (
                            <div className="adr-container">
                              <span>
                                <i className="ico ico-weight"></i> {t('cargo.oversize')}
                              </span>
                            </div>
                          )}
                          {entity.crg_fragile && (
                            <div className="adr-container">
                              <span>
                                <i className="fa fa-glass"></i> {t('cargo.fragile')}
                              </span>
                            </div>
                          )}
                          {/* {this.getOversizeGrouping(entity)} */}
                        </span>
                      </div>

                      <div className="fl-right">
                        {!timeRemaining && item.car_notes && <span className="col-blue car-notes">
                          <TooltipWrapper text={item.car_notes} hover>
                            <i className="fa fa-info-circle col-blue" />
                            {t('autopark.additional.info')}
                          </TooltipWrapper>
                        </span>}
                        {entity.is_gps && (
                          <span>
                            <i className="ico ico-target"></i>
                            &nbsp;{item.shippingType === 'auto' ? t('autopark.is.gps') : t('is.gps.container')}
                          </span>
                        )}

                      </div>
                    </div>
                    {item.shippingType !== "container" && <div className="panel-offer-content-row panel-offer-content-row-break delivery-container-info">
                      {searchType === 'cargo' && [
                        <div key="0" className="panel-offer-content-col panel-offer-content-col-sm delivery-price" >
                          {priceType === 1 && <span className="price">{`${entity.price || '-'}`}</span>}
                          {priceType !== 1 && <span className="price">{this.showPriceTypeText(priceType, t)}</span>}
                          {price && priceType === 1 && <span className="meta">~N USD/km</span>}
                          {price && (
                            <span className="meta">
                              {t(recurringLabel)}
                            </span>
                          )}
                        </div>,
                        // <div key="1" className="panel-offer-content-col ta-c">
                        //   <Link to="/useful/distance-calculation" target="_blank">
                        //     {t('cargo.calculate.fuel.costs')}
                        //   </Link>
                        // </div>,
                      ]}
                      <div className="panel-offer-content-col ">
                        <span className="meta">{t('cargo.payment.method')}</span>
                        <span className="name">{this.getPaymentTypes(item.price)}</span>
                      </div>
                    </div>}
                    {/* {cargo.is_gps && miscs.push(t('autopark.is.gps'))} */}
                    {!timeRemaining ? (
                      <div className="panel-offer-content-row">
                        <div className="panel-offer-content-col">
                          <span className="meta">{t('additionalInfo')}</span>
                          <span className="name">{`${entity.info || '-'}`}</span>
                        </div>
                      </div>
                    ) : null}
                    {timeRemaining ? (
                      <p className="panel-timer">
                        {t('time.restriction.wait.first', { time: timeRemaining.toFixed(0) })}
                        {` `}{ruWordNumeralEnding(
                          t,
                          timeRemaining,
                          'time.restriction.wait.second',
                          langId
                        )}
                      </p>
                    ) : null}
                    {isModalView && <Banner bannerId={searchType === 'cargo' ? 538 : 544} />}
                    {!isModalView && <Banner bannerId={searchType === 'cargo' ? 540 : 546} />}
                    {!timeRemaining && <ProfileMini
                      customUserId={Number(authorId) || null}
                      closeModal={onClose}
                      showFullInfo
                    />}
                    {!isModalView && !timeRemaining && <Banner bannerId={searchType === 'cargo' ? 541 : 547} />}
                  </div>
                </div>
              </FlexBand>
            </div>
          </div>
        </WidgetBase>
        {!isModalView && item.active && (Number(authorId) === Number(userId)) && (
          <Fragment>
            <CounterOffers
              {...{ item }}
              {...{ showDateIsShort }}
              {...{ searchType }}
              shippingType={item.shippingType}
              bannerId={searchType === 'cargo' ? 542 : 548} />
          </Fragment>
        )}
        <ModalConsumer>
          {modalConsumer => {
            this.modalConsumer = modalConsumer
            return null
          }}
        </ModalConsumer>
      </Fragment>
    )
  }

  render() {
    const {
      t,
      item: { loading, error },
    } = this.props
    return (
      <div className={classNames('panel-content', `${STYLE_BASE}wrapper`)}>
        {loading ? (
          <Spinner className={classNames(`${STYLE_BASE}spinner`)} />
        ) : error ? (
          <div className={classNames(`${STYLE_BASE}error`)}>{error}</div>
        ) : (
          this.showItem()
        )}
      </div>
    )
  }
}
