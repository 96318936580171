import React from 'react'
import { Switch, Route } from 'react-router-dom'

import ProtectedRoute from '../../protectedRoute'
import Registration from './registration'
import RegistrationProfile from './registration/profile'
import RegistrationSuccess from './registration/success'
import RemindPassword from './remind-password'
import RecoverPassword from './recover-password'
import ChangePassword from './change-password'
import AutoLogin from './login-auto'
import Reregistration from './reregistration'
import changeEmail from './change-email/confirmation'

export default function AuthRouter() {
  return (
    <Switch>
      <ProtectedRoute path="/auth/re(re)?gistration/success" component={RegistrationSuccess} />
      <ProtectedRoute
        path="/auth/registration/:token"
        component={RegistrationProfile}
        withoutAuth
      />
      <Route path="/auth/reregistration/:token" exact component={Reregistration} />
      <Route path="/auth/change-email/:token" exact component={changeEmail} />
      <ProtectedRoute path="/auth/registration" component={Registration} withoutAuth />

      <ProtectedRoute path="/auth/change-password" component={ChangePassword} withoutAuth />
      <ProtectedRoute path="/auth/recover-password" component={RecoverPassword} withoutAuth />

      <ProtectedRoute path="/auth/remind-password" component={RemindPassword} withoutAuth />
      <ProtectedRoute path="/auth/:token" component={AutoLogin} withoutAuth />
    </Switch>
  )
}
