import { HEADERTOP_REFRESH, HEADERTOP_DDL_OPEN, HEADERTOP_DDL_CLOSE } from '../actions/action-types'
import { HeaderTopState } from './types'

// ---------------------------------------
// store
// ---------------------------------------
const initialState: HeaderTopState = {
  items: [],
  openedKey: undefined,
}

// ---------------------------------------
const refresh = (state: HeaderTopState) => {
  return { ...state, openedKey: undefined }
}
const openDropdown = (state: HeaderTopState, { key }) => {
  return { ...state, openedKey: key }
}
const closeDropdown = (state: HeaderTopState) => {
  return { ...state, openedKey: undefined }
}

// ---------------------------------------
// reducer
// ---------------------------------------
export default (state = initialState, action) => {
  switch (action.type) {
    case HEADERTOP_REFRESH:
      return refresh(state)
    case HEADERTOP_DDL_OPEN:
      return openDropdown(state, action)
    case HEADERTOP_DDL_CLOSE:
      return closeDropdown(state)

    default:
      return state
  }
}
