import React, { FunctionComponent } from 'react'
import moment from 'moment'
import FlexBand from 'flexband'

import { IUserActionsProps, ILog } from './types'
import Pagination from '../../UI/pagination'
// import { addMessageLink } from '../../shared/utils'

import './styles.scss'

const UserActions: FunctionComponent<IUserActionsProps> = props => {
  const { t, totalItems, pageIndex, pageSize, refresh, actions } = props

  return (
    <div>
      {actions?.length ? (
        <div>
          <table className="table table-striped-main table-collapsed user_actions">
            <tbody>
              {actions.map((action: ILog, i) => (
                <tr key={i} className="block-line">
                  <td className="td-date">
                    <span>{moment(action.date).format('DD.MM')}</span>
                  </td>
                  <td>
                    <span>{action.logMessage}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            total={totalItems}
            pageIndex={pageIndex}
            pageSize={pageSize}
            onClick={refresh}
          />
        </div>
      ) : (
        <FlexBand className="table-no-entries" justify="center" align="center">
          {t('not.found')}
        </FlexBand>
      )}
    </div>
  )
}

export default UserActions
