import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import ListPage from './list-page'
import NewsPage from './news-page'

export default function NewsRoutes() {
  return (
    <Switch>
      <Route path="/news/list" component={ListPage} />
      <Route path="/news/:itemId" component={NewsPage} />
      <Redirect to="/news/list" />
    </Switch>
  )
}
