import { md1 } from "./pages-ru/md1"
import { md2 } from "./pages-ru/md2"
import { md3 } from "./pages-ru/md3"
import { md4 } from "./pages-ru/md4"
import { md5 } from "./pages-ru/md5"
import { md6 } from "./pages-ru/md6"
import { md7 } from "./pages-ru/md7"
import { md8 } from "./pages-ru/md8"
import { md9 } from "./pages-ru/md9"
import { md10 } from "./pages-ru/md10"
import { md11 } from "./pages-ru/md11"
import { md12 } from "./pages-ru/md12"
import { md13 } from "./pages-ru/md13"
import { md14 } from "./pages-ru/md14"
import { md15 } from "./pages-ru/md15"
import { md16 } from "./pages-ru/md16"
import { md17 } from "./pages-ru/md17"
import { md18 } from "./pages-ru/md18"
import { md19 } from "./pages-ru/md19"
import { md20 } from "./pages-ru/md20"

export const contentMDru = [
    { key: "md1", html: md1 },
    { key: "md2", html: md2 },
    { key: "md3", html: md3 },
    { key: "md4", html: md4 },
    { key: "md5", html: md5 },
    { key: "md6", html: md6 },
    { key: "md7", html: md7 },
    { key: "md8", html: md8 },
    { key: "md9", html: md9 },
    { key: "md10", html: md10 },
    { key: "md11", html: md11 },
    { key: "md12", html: md12 },
    { key: "md13", html: md13 },
    { key: "md14", html: md14 },
    { key: "md15", html: md15 },
    { key: "md16", html: md16 },
    { key: "md17", html: md17 },
    { key: "md18", html: md18 },
    { key: "md19", html: md19 },
    { key: "md20", html: md20 }
]