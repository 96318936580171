import React from 'react'

interface RatingProps {
  averageRating?: number
  editMode?: boolean
  grade?: number
  onClick?: (grade: number) => void
}

interface RatingState {
  hovered: number
}

export default class Rating extends React.Component<RatingProps, RatingState> {
  constructor(props) {
    super(props)

    this.state = {
      hovered: 0,
    }
  }

  renderRating = () => {
    const { averageRating } = this.props
    const stars = []

    for (let i = 1; i <= 5; i++) {
      let spanClassName = ''
      if (i <= averageRating) {
        spanClassName = 'fa fa-star'
      } else if (i - 0.5 <= averageRating) {
        spanClassName = 'fa fa-star fa-star-half'
      } else {
        spanClassName = 'fa fa-star fa-star-empty'
      }

      stars.push(<span key={i} className={spanClassName} />)
    }

    return stars
  }

  handleStarHover = (hovered: number) => {
    this.setState({ hovered })
  }

  renderEdit = () => {
    const { hovered } = this.state
    const { grade, onClick } = this.props
    const stars = []
    for (let i = 1; i <= 5; i++) {
      let spanClassName = ''

      if (hovered >= i || (hovered === 0 && grade >= i)) {
        spanClassName = 'fa fa-star'
      } else {
        spanClassName = 'fa fa-star fa-star-empty'
      }

      stars.push(
        <span
          key={i}
          className={spanClassName}
          onMouseEnter={() => this.handleStarHover(i)}
          onMouseLeave={() => this.handleStarHover(0)}
          onClick={() => onClick(i)}
        />
      )
    }

    return stars
  }

  render() {
    const { editMode } = this.props
    return <span className="stars-block">{editMode ? this.renderEdit() : this.renderRating()}</span>
  }
}
