export const int18 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Convenția europeană privind protecția animalelor în transport internațional din 13.12.1968 *</h4><p></p>

<p style="text-indent:2em;"></p><h6>Statele membre ale Consiliului Europei, semnatare ale prezentei convenții,considerînd că scopul Consiliului Europei este acela de a realiza o mai mare unitate între membrii săi, în
 vederea salvgardării și promovării idealurilor și principiilor care constituie patrimoniul lor comun, convinse că exigențele transportului internațional al animalelor nu sînt incompatibile cu starea de confort a acestora,
animate de dorința de a evita, în măsura posibilului, orice suferință a animalelor transportate, considerînd că un progres în acest domeniu poale fi obținut prin adoptarea unor dispoziții comune în materie de transport internațional de animale,
au căzut de acord asupra următoarelor:</h6><p></p>

<p></p><h4>CAPITOLUL I</h4><p></p>

<p style="font-weight:500;">ARTICOLUL 1</p><br>

<p style="text-indent:2em;">1. Fiecare dintre părțile contractante va pune în aplicare dispozițiile privitoare la transportul internațional de animale conținute în prezenta convenție.</p><br>

<p style="text-indent:2em;">2. În termenii prezentei convenții, prin transport internațional se înțelege orice expediție care presupune trecerea unei frontiere, excluzînd totuși micul trafic de frontieră.</p><br>

<p style="text-indent:2em;">3. Autoritățile competente din țara expeditoare vor decide dacă transportul se face conform dispozițiilor din prezenta convenție. Totuși, țările de destinație sau de tranzit pot contesta 
condițiile în care s-a desfășurat transportul pe baza dispozițiilor din prezenta convenție. Transportul nu poate fi însă întrerupt decît dacă o astfel de măsură este indispensabilă pentru 
starea de confort a animalelor transportate.</p><br>

<p style="text-indent:2em;">4. Fiecare dintre părțile contractante va lua măsurile necesare pentru a reduce la minimum suferința animalelor, în caz de grevă sau în oricare caz de forță majoră care împiedică aplicarea
 strictă pe propriul teritoriu a prezentei convenții. În acest scop, ele se vor inspira din principiile enunțate în aceasta.</p><br>

<p style="font-weight:500;">ARTICOLUL 2</p>

<p style="text-indent:2em;"></p><h6>Prezenta convenție se aplică transporturilor internaționale de:</h6><p></p>

<p>a) solipede domestice și animale domestice din specia bovină, ovină, caprină și porcină (cap. II);</p><br>

<p>b) păsări și iepuri domestici (cap. III);</p><br>

<p>c) cîini și pisici domestice (cap. IV);</p><br>

<p>d) alte mamifere și păsări (cap. V);</p><br>

<p>e) animale cu sînge rece (cap. VI).</p>

<p></p><h4>CAPITOLUL II Solipede domestice și animale domestice din specia bovină,
ovină, caprină și porcină</h4><p></p>

<p style="font-weight:500;">A. Dispoziții generale</p><br>

<p style="font-weight:500;">ARTICOLUL 3</p><br>

<p style="text-indent:2em;">1. Înainte de încărcarea lor în vederea unui transport internațional, animalele trebuie să fie verificate de către un medic veterinar autorizat din țara exportatoare, care să se asigure că 
sînt apte de a călători. Prin medic veterinar autorizat se înțelege un medic veterinar desemnat de autoritatea competentă în aplicarea dispozițiilor prezentei convenții.</p><br>

<p>2. Încărcarea trebuie efectuată conform condițiilor aprobate de medicul veterinar autorizat.</p><br>

<p style="text-indent:2em;">3. Medicul veterinar autorizat eliberează un certificat în care sînt consemnate identificarea animalelor, dacă sînt apte pentru a călători și, dacă este posibil, înmatricularea mijlocului
 de transport și tipul de vehicul.</p><br>

<p style="text-indent:2em;">4. În unele cazuri determinate prin înțelegere între părțile contractante interesate este posibil ca dispozițiile prezentului articol să nu fie aplicate.</p><br>

<p style="font-weight:500;">ARTICOLUL 4</p><br>

<p style="text-indent:2em;">Animalele care trebuie să fete în perioada corespunzătoare transportului sau care au fătat cu mai puțin de 48 de ore în urmă pot fi considerate apte de drum.</p><br>

<p style="font-weight:500;">ARTICOLUL 5</p><br>

<p style="text-indent:2em;">Medicul veterinar autorizat din țara exportatoare, din țara de tranzit sau din țara importatoare poate prescrie o perioadă de odihnă în locul pe care îl desemnează, timp în care animalele
 vor primi îngrijirile necesare.</p><br>

<p style="font-weight:500;">ARTICOLUL 6</p><br>

<p style="text-indent:2em;">1. Animalele trebuie să dispună de suficient spațiu și trebuie, în cazul absenței unor indicații speciale contrare, să se poată culca.</p><br>

<p style="text-indent:2em;">2. Mijloacele de transport sau ambalajul trebuie concepute astfel încît să protejeze animalele împotriva intemperiilor și diferențelor mari de climă. Ventilația și cubajul de aer trebuie 
adaptate la condițiile de transport și la specia de animale transportate.</p><br>

<p style="text-indent:2em;">3. Ambalajele (lăzi, cuști etc.) care servesc la transportul animalelor trebuie să fie prevăzute cu un simbol care să indice prezența animalelor vii și cu un semn care să indice poziția în
 care animalele se găsesc în picioare. Trebuie să fie ușor de curățat și să asigure securitatea animalelor. Trebuie să permită, de asemenea, examinarea animalelor și îngrijirea lor și să fie 
 astfel așezate încît să nu jeneze circulația aerului. Pe parcursul transportului și în timpul manipulărilor, ambalajele trebuie ținute totdeauna în poziție verticală, fiind interzisă expunerea 
 la zguduituri sau la lovituri violente.</p><br>

<p style="text-indent:2em;">4. Pe parcursul transportului, animalele trebuie adăpate și alimentate corespunzător la intervale convenabile. Aceste intervale nu trebuie să depășească 24 de ore; perioada de 24 de ore poate
 fi totuși prelungită dacă transportul ajunge la locul de destinație cu o întîrziere rezonabilă.</p><br>

<p style="text-indent:2em;">5. Solipedele trebuie să fie prevăzute cu un căpăstru în timpul transportului. Această dispoziție nu se aplică în mod obligatoriu animalelor nedresate.</p><br>

<p style="text-indent:2em;">6. Cînd animalele sînt legate, legăturile utilizate trebuie să aibă o astfel de rezistență încît să nu se rupă în condiții normale de transport; aceste legături trebuie să aibă o lungime 
suficientă pentru a permite animalelor să se așeze, să se hrănească și să se adape. Bovinele nu vor fi legate de coarne.</p><br>

<p>7. Solipedele care nu călătoresc în boxe individuale nu trebuie să aibă copitele din spate potcovite.</p><br>

<p style="text-indent:2em;">8. Taurii în vîrstă de peste 18 luni vor fi de preferință legați; ei vor avea un anou nazal care va fi utilizat numai pentru a-i manevra.</p><br>

<p style="font-weight:500;">ARTICOLUL 7</p><br>

<p style="text-indent:2em;">1. Cînd animalele de diverse specii sînt transportate în același mijloc de transport, ele vor fi separate pe specii. De asemenea, vor fi prevăzute măsuri speciale pentru evitarea inconveniențelor
 care ar putea rezulta din prezența, în cadrul aceluiași transport, a speciilor ostile în mod natural unele față de altele. Cînd încărcătura aceluiași transport este compusă din animale de vîrstă
 diferite, adulții vor fi separați de tineri; totuși, această restricție nu se aplică femelelor care călătoresc împreună cu puii pe care-i alăptează. În ce privește bovinele, solipedele și porcinele,
 masculii adulți necastrați vor fi separați de femele; de asemenea, vierii vor fi ținuți despărțiți unii de alții, ca și armăsarii.</p><br>

<p>2. În compartimentele în care se găsesc animale nu se va depozita marfă care să dăuneze acestora.</p><br>

<p style="font-weight:500;">ARTICOLUL 8</p><br>

<p style="text-indent:2em;">Pentru încărcarea și descărcarea animalelor se vor folosi echipamente corespunzătoare, ca punți, rampe sau pasarele. Aceste echipamente vor fi dotate cu un planșeu neglisant și, dacă este necesar,
 cu o protecție laterală. Animalele nu vor fi ridicate ținîndu-le de cap, de coarne sau labe în timpul încărcării sau descărcării.</p><br>

<p style="font-weight:500;">ARTICOLUL 9</p><br>

<p style="text-indent:2em;">Planșeul mijloacelor de transport sau al ambalajelor trebuie să fie suficient de solid pentru a rezista greutății animalelor transportate. El nu trebuie să fie alunecos sau să prezinte denivelări.
 El trebuie să fie acoperit cu un așternut suficient de gros pentru a absorbi dejecțiile în cazul în care acesta nu poate fi înlocuit cu o altă metodă care să prezinte cel puțin aceleași avantaje.</p><br>

<p style="font-weight:500;">ARTICOLUL 10</p>

<p style="text-indent:2em;"></p><h6>Pentru a asigura în timpul transportului îngrijirile necesare, animalele vor fi însoțite, cu excepția următoarelor cazuri:</h6><p></p>

<p>a) cînd animalele sînt predate pentru transport în cuști închise;</p><br>

<p>b) transportatorul preia sarcinile însoțitorului;</p><br>

<p>c) expeditorul a însărcinat un mandatar să acorde îngrijiri animalelor în locurile de oprire.</p><br>

<p style="font-weight:500;">ARTICOLUL 11</p><br>

<p style="text-indent:2em;">1. Reprezentantul sau mandatarul expeditorului este obligat să aibă grijă de animale, să le hrănească, să le adape și, dacă este cazul, să le mulgă.</p><br>

<p>2. Vacile lactante vor fi mulse la intervale care să nu depășească 12 ore.</p><br>

<p style="text-indent:2em;">3. Pentru a putea asigura aceste îngrijiri, însoțitorul va avea la dispoziție, în caz de necesitate, un mijloc de iluminat corespunzător.</p><br>

<p style="font-weight:500;">ARTICOLUL 12</p><br>

<p style="text-indent:2em;">Animalele bolnave sau rănite pe parcursul transportului vor primi cît mai repede posibil îngrijirile unui medic veterinar și, dacă este necesară sacrificarea, acesta o va face încercînd să
 provoace cît mai puțină suferință.</p><br>

<p style="font-weight:500;">ARTICOLUL 13</p><br>

<p style="text-indent:2em;">Animalele nu vor fi încărcate decît în mijloace de transport sau ambalaje perfect curate. Cadavrele, bălegarul și dejecțiile vor fi îndepărtate cît mai repede posibil.</p><br>

<p style="font-weight:500;">ARTICOLUL 14</p><br>

<p>Animalele trebuie pornite la drum cît mai repede posibil, întârzierile trebuind să fie reduse la minimum.</p><br>

<p style="font-weight:500;">ARTICOLUL 15</p><br>

<p style="text-indent:2em;">Pentru accelerarea formalităților de import sau tranzit, orice transport de animale va fi anunțat imediat la postul de control. Pentru aceste formalități, se va acorda prioritate transportului
 de animale.</p><br>

<p style="font-weight:500;">ARTICOLUL 16</p><br>

<p style="text-indent:2em;">Posturile unde se efectuează controlul sanitar și unde există un trafic important și regulat de animale trebuie să fie amenajate astfel încît să permită odihna, hrănirea și adăparea animalelor.</p><br>

<p style="font-weight:500;">B. Dispoziții speciale pentru transportul pe căile ferate</p><br>

<p style="font-weight:500;">ARTICOLUL 17</p><br>

<p style="text-indent:2em;">Toate vagoanele care servesc transportului de animale vor fi prevăzute cu un simbol care să indice prezența animalelor vii. În lipsa unor vagoane specializate pentru transportul animalelor,
 vagoanele folosite vor fi acoperite, apte să circule cu mare viteză și prevăzute cu orificii de aerisire suficient de mari. Orificiile vor fi astfel concepute încît animalele să nu poată ieși
 și să fie în deplină siguranță. Pereții interiori ai acestor vagoane trebuie să fie din lemn sau din alt material corespunzător, lipsit de asperități și prevăzut cu anouri sau bare de arimaj
 așezate la o înălțime convenabilă.</p><br>

<p style="font-weight:500;">ARTICOLUL 18</p><br>

<p>Solipedele vor fi legate fie de-a lungul aceluiași perete, fie vizavi. Totuși, animalele tinere și nedresate nu vor fi legate.</p><br>

<p style="font-weight:500;">ARTICOLUL 19</p><br>

<p>Animalele mari vor fi astfel dispuse în vagoane încît să permită însoțitorului să circule printre ele.</p><br>

<p style="font-weight:500;">ARTICOLUL 20</p><br>

<p style="text-indent:2em;">Cînd se face separarea animalelor, conform art. 7, aceasta se poate realiza fie legînd animalele în locuri separate din vagon, dacă suprafața acestuia o permite, fie folosind bariere
 corespunzătoare.</p><br>

<p style="font-weight:500;">ARTICOLUL 21</p><br>

<p style="text-indent:2em;">În momentul formării trenurilor sau al oricăror alte manevre ale vagoanelor, vor fi luate toate măsurile pentru a evita acostajul violent al vagoanelor care transportă animalele.</p><br>

<p style="font-weight:500;">C. Dispoziții speciale pentru transportul pe șosea</p><br>

<p style="font-weight:500;">ARTICOLUL 22</p><br>

<p style="text-indent:2em;">Vehiculele vor fi amenajate astfel încît animalele să nu poată ieși și să fie în deplină siguranță; de asemenea, trebuie să fie prevăzute cu un acoperiș care să ferească animalele
 de intemperii.</p><br>

<p style="font-weight:500;">ARTICOLUL 23</p><br>

<p style="text-indent:2em;">Vor fi instalate dispozitive de atașare în vehiculele folosite pentru transportul animalelor mari care, în mod normal, trebuie legate. Cînd se impune compartimentarea vehiculelor,
 aceasta se va face folosind îngrădituri rezistente.</p><br>

<p style="font-weight:500;">ARTICOLUL 24</p><br>

<p>Vehiculul trebuie să aibă o rampă în condițiile prevăzute la art. 8.</p><br>

<p style="font-weight:500;">D. Dispoziții speciale pentru transportul pe apă</p><br>

<p style="font-weight:500;">ARTICOLUL 25</p><br>

<p style="text-indent:2em;">Echipamentul navelor trebuie să permită transportul animalelor fără ca acestea să fie expuse la răni sau suferințe evitabile.</p><br>

<p style="font-weight:500;">ARTICOLUL 26</p><br>

<p style="text-indent:2em;">Animalele nu trebuie transportate pe punți deschise, ci numai în ambalaje bine fixate sau în locuri anume desemnate de autoritatea competentă, care asigură protecție față de apa
 mării și de intemperii.</p><br>

<p style="font-weight:500;">ARTICOLUL 27</p><br>

<p>Animalele vor fi legate sau așezate corespunzător în țarcuri sau cuști.</p><br>

<p style="font-weight:500;">ARTICOLUL 28</p><br>

<p style="text-indent:2em;">Vor fi amenajate treceri corespunzătoare pentru a permite accesul la țarcurile sau cuștile în care se găsesc animalele. Va fi prevăzut și un dispozitiv de iluminat.</p><br>

<p style="font-weight:500;">ARTICOLUL 29</p><br>

<p>Numărul de însoțitori trebuie să fie suficient, în funcție de numărul animalelor transportate și de durata transportului.</p><br>

<p style="font-weight:500;">ARTICOLUL 30</p><br>

<p>Toate părțile navei ocupate de animale vor fi prevăzute cu dispozitive de scurgere a apei și păstrate în stare de curățenie.</p><br>

<p style="font-weight:500;">ARTICOLUL 31</p><br>

<p style="text-indent:2em;">Un instrument de un tip acceptat de autoritatea competentă trebuie să se găsească la bord pentru a servi la sacrificarea animalelor în caz de necesitate.</p><br>

<p style="font-weight:500;">ARTICOLUL 32</p><br>

<p style="text-indent:2em;">Navele care servesc transportului de animale vor fi dotate, înainte de plecare, cu rezerve de apă potabilă și alimente suficiente după aprecierea expeditorului, atît în funcție de specia
 și numărul animalelor transportate, cît și cu durata transportului.</p><br>

<p style="font-weight:500;">ARTICOLUL 33</p><br>

<p style="text-indent:2em;">Vor fi luate măsuri pentru izolarea pe parcursul transportului a animalelor bolnave sau rănite și, la nevoie, pentru acordarea primelor îngrijiri.</p><br>

<p style="font-weight:500;">ARTICOLUL 34</p><br>

<p style="text-indent:2em;">Dispozițiile art. 25-33 nu se aplică transportului de animale efectuat în vehicule feroviare sau rutiere încărcate pe feriboturi sau nave asemănătoare.</p><br>

<p style="font-weight:500;">E. Dispoziții speciale pentru transportul pe calea aerului</p><br>

<p style="font-weight:500;">ARTICOLUL 35</p><br>

<p style="text-indent:2em;">Animalele vor fi dispuse în cuști sau boxe la care se pretează specia transportată. Pot fi acordate derogări cu condiția efectuării unor amenajări corespunzătoare pentru legarea
 animalelor.</p><br>

<p style="font-weight:500;">ARTICOLUL 36</p><br>

<p style="text-indent:2em;">Vor fi luate măsuri pentru evitarea temperaturilor prea ridicate sau prea scăzute, ținînd cont de specie. De asemenea, vor fi evitate variațiile mari de presiune.</p><br>

<p style="font-weight:500;">ARTICOLUL 37</p><br>

<p style="text-indent:2em;">La bordul avioanelor trebuie să se găsească un instrument de un tip acceptat de autoritatea competentă, pentru sacrificarea animalelor în caz de necesitate.</p>

<p></p><h4>CAPITOLUL III Păsări și iepuri domestici</h4><p></p>

<p style="font-weight:500;">ARTICOLUL 38</p><br>

<p style="text-indent:2em;">Dispozițiile articolelor cuprinse în cap. I se aplică "mutatis mutandis" transportului păsărilor și iepurilor domestici și anume: art. 6 paragrafele 1-3, art. 7, 13-17,
 21, 22, 25-30, 32, 34-36.</p><br>

<p style="font-weight:500;">ARTICOLUL 39</p><br>

<p style="text-indent:2em;">1. Animalele bolnave sau rănite nu vor fi considerate apte pentru călătorie. Cele care se rănesc sau se îmbolnăvesc în timpul transportului vor primi imediat îngrijirile necesare,
 fiind supuse, dacă este cazul, unui examen de specialitate.</p><br>

<p style="text-indent:2em;">2. Cînd animalele sînt încărcate în cuști suprapuse sau într-un vehicul cu mai multe etaje, vor fi luate măsurile necesare pentru a preveni căderea dejecțiilor pe animalele
 așezate pe nivelurile inferioare.</p>

<p style="text-indent:2em;"></p><h6>3. Hrana și apa trebuie să le stea la dispoziție în cantități suficiente, cu excepția următoarelor cazuri:</h6><p></p>

<p>a) transporturi care durează mai puțin de 12 ore;</p><br>

<p style="text-indent:2em;">b) transporturi care durează mai puțin de 24 de ore, cînd este vorba de păsărele de toate speciile, cu condiția ca transportul să ia sfîrșit în cele 72 ore care urmează după
 ecloziune.</p>

<p></p><h4>CAPITOLUL IV Cîini și pisici domestice</h4><p></p>

<p style="font-weight:500;">ARTICOLUL 40</p><br>

<p style="text-indent:2em;">1. Dispozițiile prezentului capitol se aplică transportului cîinilor și pisicilor domestice, cu excepția celor care sînt însoțiți de către proprietar sau un reprezentant al
 acestuia.</p><br>

<p style="text-indent:2em;">2. Următoarele dispoziții ale cap. II se aplică "mutatis mutandis" transportului cîinilor și pisicilor: art. 4, art. 6 paragrafele 1-3, art. 7, 9, 10, 11 paragrafele 1 și 3, art.
 12-17, 20-23, 25-29 și 31-37.</p><br>

<p style="font-weight:500;">ARTICOLUL 41</p><br>

<p style="text-indent:2em;">Animalele transportate vor fi hrănite la intervale care să nu depășească 24 de ore și adăpate la intervale de pînă la 12 ore. Însoțitorii vor avea asupra lor instrucțiuni redactate
 în mod clar cu privire la hrănirea animalelor. Femelele în călduri vor fi separate de masculi.</p>

<p></p><h4>CAPITOLUL V Alte mamifere și păsări</h4><p></p>

<p style="font-weight:500;">ARTICOLUL 42</p><br>

<p style="text-indent:2em;">1. Dispozițiile prezentului capitol se aplică transportului mamiferelor și păsărilor care nu sînt vizate în capitolele precedente.</p><br>

<p style="text-indent:2em;">2. Următoarele dispoziții ale cap. II se aplică "mutatis mutandis" transportului speciilor menționate în acest capitol: art. 4, 5, art. 6 paragrafele 1-3, art. 7-10, art. 11
 paragrafele 1 și 3, art. 12-17, 20-37.</p><br>

<p style="font-weight:500;">ARTICOLUL 43</p><br>

<p style="text-indent:2em;">Animalele trebuie transportate numai în vehicule sau cuști corespunzătoare, pe care se va lipi un anunț, dacă este cazul, prin care se va menționa că este vorba de animale sălbatice,
 sperioase sau periculoase. De asemenea, animalele vor fi însoțite de instrucțiuni, redactate cu claritate, cu privire la hrănirea și îngrijirea lor.</p><br>

<p style="font-weight:500;">ARTICOLUL 44</p><br>

<p>Cervidele nu vor fi transportate în perioada cînd își refac materialul cornos, decît dacă se iau măsuri speciale.</p><br>

<p style="font-weight:500;">ARTICOLUL 45</p><br>

<p>Vor fi acordate îngrijiri animalelor vizate în prezentul capitol conform instrucțiunilor de la art. 43.</p>

<p></p><h4>CAPITOLUL VI Animale cu sînge rece</h4><p></p>

<p style="font-weight:500;">ARTICOLUL 46</p><br>

<p style="text-indent:2em;">Animalele cu sînge rece trebuie transportate în cuști corespunzătoare, ținîndu-se seama și de necesitățile relative de spațiu, ventilație, temperatură, aprovizionare cu apă și oxigenare.
 După asigurarea acestor condiții transportul trebuie început imediat.</p>

<p></p><h4>CAPITOLUL VII Rezolvarea diferendelor</h4><p></p>

<p style="font-weight:500;">ARTICOLUL 47</p><br>

<p style="text-indent:2em;">1. În caz de contestație cu privire la interpretarea sau aplicarea dispozițiilor prezentei convenții, autoritățile competente ale părților contractante interesate vor proceda la consultații
 reciproce. Fiecare dintre părțile contractante va notifica secretarului general al Consiliului Europei numele și adresele autorităților sale competente.</p><br>

<p style="text-indent:2em;">2. Dacă diferendul nu a putut fi reglementat pe această cale, va fi supus, la cererea uneia dintre părțile implicate în diferend, unui arbitraj. Fiecare parte desemnează un arbitru, iar cei
 doi arbitri, astfel aleși, desemnează un superarbitru. Dacă una din cele două părți implicate în diferend nu și-a desemnat arbitrul în cele trei luni care urmează cererii de arbitraj, acesta
 va fi numit la cererea celeilalte părți implicate în diferend de către președintele Curții Europene pentru Drepturile Omului. În cazul în care acesta este conațional cu una dintre părți,
 această funcție va fi asigurată de către vicepreședintele curții sau, dacă acesta este și el conațional cu una dintre părți, de către cel mai vechi dintre judecătorii curții care nu este
 conațional cu nici una dintre părți. Se va proceda în același fel dacă arbitrii nu se pun de acord cu privire la alegerea unui superarbitru.</p><br>

<p style="text-indent:2em;">În caz de diferend între două părți contractante, dintre care una este un stat membru al Comunității Economice Europene, ea însăși parte contractantă, cealaltă parte contractantă adresează
 cererea de arbitraj atît către acest stat membru, cît și către comunitate, care îi vor notifica în decurs de trei luni de la primirea cererii, dacă statul membru sau comunitatea, sau statul
 membru și comunitatea împreună, se constituie ca parte în diferend. În lipsa unei asemenea notificări în intervalul amintit, statul membru și comunitatea vor fi considerate ca fiind una și
 aceeași parte la diferend pentru aplicarea dispozițiilor ce guvernează constituirea și procedura tribunalului arbitral. Se procedează în același fel cînd statul membru și comunitatea se
 constituie împreună ca părți în diferend.</p><br>

<p style="text-indent:2em;">3. Tribunalul arbitral își va fixa procedura. Deciziile sale vor fi luate cu majoritate de voturi. Sentința sa, care se va baza pe prezenta convenție, este definitivă.</p>

<p></p><h4>CAPITOLUL VIII Dispoziții finale</h4><p></p>

<p style="font-weight:500;">ARTICOLUL 48</p><br>

<p style="text-indent:2em;">1. Prezenta convenție este deschisă semnării de către statele membre în Consiliul Europei. Ea va fi ratificată sau acceptată. Instrumentele de ratificare sau de acceptare vor fi depuse pe
 lîngă secretarul general al Consiliului Europei.</p><br>

<p style="text-indent:2em;">2. Convenția va intra în vigoare la șase luni de la data depunerii celui de-al patrulea instrument de ratificare sau de acceptare.</p><br>

<p style="text-indent:2em;">3. Aceasta va intra în vigoare pentru toate statele semnatare care o vor ratifica sau accepta ulterior, la șase luni de la data depunerii instrumentelor de ratificare sau de acceptare.</p><br>

<p style="text-indent:2em;">4. Comunitatea Economică Europeană poate deveni parte contractantă la prezenta convenție prin semnătură. Convenția va intra în vigoare pentru comunitate la șase luni de la data semnării.</p><br>

<p style="font-weight:500;">ARTICOLUL 49</p><br>

<p style="text-indent:2em;">1. După intrarea în vigoare a prezentei convenții, Comitetul miniștrilor Consiliului Europei va putea invita orice stat nemembru al consiliului să adere la prezenta convenție.</p><br>

<p style="text-indent:2em;">2. Aderarea va fi exprimată prin depunerea pe lîngă secretarul general al Consiliului Europei a unui instrument de aderare, care va intra în vigoare la șase luni de la data depunerii.</p><br>

<p style="font-weight:500;">ARTICOLUL 50</p><br>

<p style="text-indent:2em;">1. Orice parte contractantă poate, în momentul semnării sau în momentul depunerii instrumentului său de ratificare, de acceptare sau de aderare, să desemneze teritoriul sau teritoriile
 la care se aplică prezenta convenție.</p><br>

<p style="text-indent:2em;">2. Orice parte contractantă poate, în momentul depunerii instrumentării sale de ratificare, de acceptare sau de aderare, sau în orice alt moment ulterior, să extindă aplicarea prezentei
 convenții prin declarația adresată secretarului general al Consiliului Europei la orice alt teritoriu desemnat în declarație și pentru care ea asigură relațiile internaționale sau este
 abilitată să le stipuleze.</p><br>

<p style="text-indent:2em;">3. Orice declarație făcută în virtutea paragrafului precedent va putea fi retrasă de pe orice teritoriu desemnat în această declarație, în condițiile prevăzute în art. 51
 din prezenta convenție.</p><br>

<p style="font-weight:500;">ARTICOLUL 51</p><br>

<p>1. Prezenta convenție va rămîne în vigoare fără limită de durată.</p><br>

<p style="text-indent:2em;">2. Orice parte contractantă va putea, în ceea ce o privește, să denunțe prezenta convenție adresînd o notificare secretarului general al Consiliului Europei.</p><br>

<p>3. Denunțarea va intra în vigoare la șase luni de la data primirii notificării din partea secretarului general.</p><br>

<p style="font-weight:500;">ARTICOLUL 52</p>

<p style="text-indent:2em;"></p><h6>Secretarul general al Consiliului Europei va notifica statelor membre ale consiliului și oricărei părți contractante nemembre a consiliului:</h6><p></p>

<p>a) orice semnătură;</p><br>

<p>b) depunerea oricărei instrumentări de ratificare, de acceptare sau aderare;</p><br>

<p>c) orice dată de intrare în vigoare a prezentei convenții conform art. 48;</p><br>

<p>d) orice declarație primită în aplicarea dispozițiilor paragrafelor 2 și 3 din art. 50;</p><br>

<p>e) orice notificare primită în aplicarea dispozițiilor art. 51 și data la care denunțarea va intra în vigoare;</p><br>

<p>f) orice notificare primită în aplicarea dispozițiilor paragrafului 1 al art. 47.</p><br>

<p>În consecință, subsemnații, autorizați pentru aceasta au semnat prezenta convenție.</p><br>

<p style="text-indent:2em;">Încheiată la Paris, la data de 13 decembrie 1968, în limbile franceză și engleză, ambele texte avînd aceeași valabilitate, într-un singur exemplar care va fi depus în arhivele Consiliului
Europei. Secretarul general al Consiliului Europei va expedia copii autentificate fiecărui stat semnatar și aderenților.</p><br>

<p><a href="https://lege5.ro/Gratuit/g43taoju/conventia-europeana-privind-protectia-animalelor-in-transport-international-din-13121968?d=2017-07-21">Sursa</a></p>

</div>
`