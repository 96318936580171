export const md3 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Гражданский Кодекс РМ .</h4><p></p>

<p></p><h4>Глава XVI</h4><p></p>

<p></p><h4>ТРАНСПОРТНАЯ ЭКСПЕДИЦИЯ</h4><p></p>

<p style="font-weight:500;">Статья 1525. Договор транспортной экспедиции</p><br>

<p style="text-indent:2em;">(1) По договору транспортной экспедиции одна сторона (экспедитор) обязуется за счет и от имени другой стороны
 (клиента) или от своего имени организовать перевозку вещей, заключив договор перевозки и совершая иные необходимые
 действия для осуществления перевозки, а клиент обязуется уплатить обусловленное вознаграждение (комиссионные).</p><br>

<p style="text-indent:2em;">(2) Договор транспортной экспедиции заключается в письменной форме. Клиент должен выдать экспедитору доверенность,
  если это необходимо для исполнения договорных обязанностей.</p><br>

<p style="text-indent:2em;">(3) К отношениям, вытекающим из договора транспортной экспедиции, применяются соответствующим образом положения
  о поручении или, при необходимости, о комиссии, если настоящей главой не предусмотрено иное.</p><br>

<p style="text-indent:2em;">(4) Положения настоящей главы применяются и в случае, когда в соответствии с условиями договора перевозки
 обязанности экспедитора в смысле части (1) исполняются перевозчиком.</p><br>

<p style="font-weight:500;">Статья 1526. Усердие экспедитора</p><br>

<p style="text-indent:2em;">(1) Экспедитор должен организовать перевозку, в частности в отношении выбора вида транспорта и маршрута,
  перевозчика, заключить необходимые договоры перевозки, хранения и транспортной экспедиции, предоставить
  необходимую информацию и дать необходимые указания для исполнения этих договоров, действуя в каждом случае
  с усердием хорошего экспедитора.</p><br>

<p style="text-indent:2em;">(2) Экспедитор может обязаться договором транспортной экспедиции выполнять другие относящиеся к перевозке услуги,
  такие как охрана и упаковка вещей, маркировка вещей и обеспечение таможенных процедур, включая заключение
  необходимых для этого договоров.</p><br>

<p style="text-indent:2em;">(3) Экспедитор заключает договоры от своего имени, а если прямо уполномочен клиентом – от имени клиента.</p><br>

<p style="text-indent:2em;">(4) При выполнении своих обязанностей экспедитор должен учитывать интересы клиента и следовать его указаниям.</p><br>

<p style="font-weight:500;">Статья 1527. Обязанности клиента</p><br>

<p style="text-indent:2em;">(1) По требованию экспедитора клиент должен своевременно предоставить информацию в отношении вещи, сведения,
 необходимые для составления документов перевозки и совершения таможенных и других процедур. Клиент обязан
 передать экспедитору документы, необходимые для удостоверения точности этих сведений.</p><br>

<p style="text-indent:2em;">(2) В отношении опасных вещей клиент должен обратить внимание экспедитора на точный характер опасности и
 в случае необходимости предупредить о необходимых мерах предосторожности.</p><br>

<p style="text-indent:2em;">(3) Вещи, об опасности которых не был проинформирован экспедитор, могут быть выгружены, уничтожены или
 обезврежены в любой момент, в любом месте и без выплаты какого бы то ни было возмещения.</p><br>

<p style="text-indent:2em;">(4) Если характер вещей требует этого, клиент обязан упаковать их таким образом, чтобы они соответствовали
 требованиям перевозки.</p><br>

<p style="text-indent:2em;">(5) Если для идентификации вещи необходимы определенные знаки, они должны быть нанесены таким
  образом, чтобы сохранились вплоть до доставки вещи.</p><br>
  
<p style="text-indent:2em;">(6) Клиент отвечает за убытки, причиненные экспедитору невыполнением обязанностей, предусмотренных 
настоящей статьей, за исключением случая, когда экспедитором не представлены замечания относительно 
отсутствия или недостатков упаковки или маркировки в соответствии с частями (4) и (5), хотя они были 
очевидны и он знал о них в момент принятия вещи.</p><br>

<p style="font-weight:500;">Статья 1528. Проверка содержания вещи или ее доставка экспедитором или может потребовать доставки вещи перевозчику</p><br>

 <p style="text-indent:2em;">Клиент может потребовать проверки содержания грузовых мест (грузоединиц) в момент принятия их 
 экспедитором или может потребовать доставки вещи перевозчику взамен внесения специальной платы.</p><br>

<p style="font-weight:500;">Статья 1529. Обязанность страхования вещи</p><br>

<p style="text-indent:2em;">Экспедитор обязан страховать вещь лишь в случае получения такого указания от клиента. При отсутствии
 особых указаний экспедитор обязан страховать вещь на обычных условиях со страховщиком, выбранным 
 им.</p><br>

<p style="font-weight:500;">Статья 1530. Определение состояния вещи при доставке</p><br>

 <p style="text-indent:2em;">Если состояние вещи определено в отсутствие сторон, доставка вещи получателю оправдывает презумпцию 
 того, что вещь получена им без утрат или повреждений, за исключением ситуации, когда получатель заявил
 в отношении лица, осуществившего доставку, замечания, в которых указал характер ущерба. Если утраты или
 ущерб могут быть установлены при наружном осмотре, замечание должно быть заявлено не позднее чем в момент
 доставки, а если утраты и ущерб незаметны при наружном осмотре, – не позднее чем на третий день со дня 
 доставки.</p><br>

<p style="font-weight:500;">Статья 1531. Применение положений о перевозке</p><br>

<p style="text-indent:2em;">Если получатель не принял вещь в пункте назначения либо если вещь не может быть доставлена по другим 
причинам, права и обязанности экспедитора определяются в соответствии с нормами, применяемыми к 
договору перевозки.</p><br>

<p style="font-weight:500;">Статья 1532. Право экспедитора осуществить перевозку своими силами</p><br>

<p style="text-indent:2em;">(1) Если не установлено иное, экспедитор уполномочен осуществить перевозку самостоятельно. Полномочие 
 существует лишь в той мере, в которой оно соответствует обязанности учитывать права и интересы клиента.</p><br>

<p style="text-indent:2em;">(2) В случае осуществления экспедитором права, указанного в части (1), он обладает одновременно 
  правами и обязанностями перевозчика.</p><br>

<p style="font-weight:500;">Статья 1533. Ответственность экспедитора</p><br>

<p style="text-indent:2em;">(1) Экспедитор обязан возместить ущерб, причиненный утратой или повреждением вещей, перевозка которых 
 организована им. Положения статей 1449, 1455, 1460 и 1461 применяются соответствующим образом.</p><br>

<p style="text-indent:2em;">(2) Экспедитор обязан возместить ущерб, не подпадающий под действие положений части (1) настоящей 
  статьи, если он нарушил обязанности, предусмотренные частями (1) или (2) статьи 1526. Экспедитор не 
  несет ответственность, если докажет, что ущерб был бы неизбежен даже при условии проявления должной 
  осмотрительности.</p><br>

<p style="text-indent:2em;">(3) Если поведение клиента или специфические недостатки вещей способствовали причинению ущерба, 
 обязанность возместить ущерб и размер возмещения зависят от того, в какой степени эти обстоятельства
 способствовали ущербу.</p><br>

<p style="text-indent:2em;">(4) Положения статьи 1463 применяются к претензиям, возникающим в связи с договорами 
 транспортной экспедиции.</p><br>

<p style="font-weight:500;">Статья 1534. Право клиента на замещение</p><br>

<p style="text-indent:2em;">В случае если экспедитор обязался на основе договора транспортной экспедиции заключить договор
  перевозки и выполнить иные действия, необходимые для осуществления перевозки от своего имени и за
  счет клиента, а нанятое экспедитором третье лицо нарушило обязательства, клиент вправе, путем 
  письменного уведомления экспедитора и третьего лица, заместить экспедитора в его правах в отношении
  третьего лица.</p><br>

<p style="font-weight:500;">Статья 1535. Причинение убытков третьим лицом</p><br>

<p style="text-indent:2em;">Если убытки причинены третьим лицом, участвовавшим в исполнении договора, экспедитор по требованию
  клиента обязан передать ему свои требования к третьему лицу, за исключением случая принятия на себя
  на основе особого соглашения исполнения этих требований за счет и на риск клиента.</p><br>

<p style="font-weight:500;">Статья 1536. Выплата вознаграждения</p><br>

<p style="text-indent:2em;">Вознаграждение по договору транспортной экспедиции подлежит истребованию с момента сдачи вещи перевозчику.</p><br>

<p><a href="https://www.legis.md/cautare/getResults?doc_id=120080&amp;lang=ru#">Источник</a></p>


</div>
`