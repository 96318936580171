import React, { Component } from 'react'
// import { Link } from "react-router-dom"
// import { Field } from "redux-form"

import ModalForm from '../../../modals/modal-form'
// import { SOCKET_NEW_LOGIN_ATTEMPT_THRESHOLD } from '../../../../store/constants'
// import { describeArc } from '../../../shared/utils/svg'
// import { MODE_TIME_REMAINED } from '../../../../store/constants'
import Timer from '../../../timer'

// const TIMER_RADIUS = 40
// const TIMER_TICKNESS_BAGEL = 6

export default class extends Component {
  // state = {
  //   awaiting: true,
  // }

  closeModal = () => {
    const { closeModal } = this.props
    closeModal && closeModal()
    //ModalForm.close('loginForm')
  }

  allow = () => {
    const { payload, allowLoginOnAnotherDevice } = this.props
    const body = JSON.stringify({
      userId: payload.userId,
    })
    window.socket.emit('SOCKET_LOGOUT_FROM_ALL_DEVICES', body);
    // this.stopTimer()
    allowLoginOnAnotherDevice(payload)
  }
  deny = () => {
    const { payload, denyLoginOnAnotherDevice } = this.props
    // this.stopTimer()
    denyLoginOnAnotherDevice(payload)
  }

  render() {
    const { isOpen, autoResolveIn, t } = this.props

    const modalOptions = {
      hideHeader: true,
      className: 'side-login-guard',
    }

    return (
      <ModalForm isOpen={isOpen} onClose={this.closeModal} {...modalOptions}>
        <div className="guard-body">
          <h2>{t('login.other.device')}:</h2>
          <br />
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {t('via')}
            {isOpen && <Timer autoResolveIn={autoResolveIn} onFinish={this.allow} />}
            {t('seconds')}
          </div>
          {t('login.other.device.approve')}
          <br />
          <br />
          <br />
          <div>{t('login.approve')}</div>
          <button
            className="button button-orange button-centered"
            onClick={this.allow}
            type="button"
          >
            {t('yes.allow')}
          </button>
          <button
            className="button button-orange button-centered"
            onClick={this.deny}
            type="button"
          >
            {t('no.not.allow')}
          </button>
        </div>
      </ModalForm>
    )
  }
}
