import React, { Component } from 'react'
import CKEditor from '@ckeditor/ckeditor5-react'

import config from '../../../config'
import { convertImageUrlUuid } from '../../shared/utils'

import './editor.scss'

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat'
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder'
import CKFinderUploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter'
// import Clipboard from '@ckeditor/ckeditor5-clipboard/src/clipboard'
import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight'
import Image from '@ckeditor/ckeditor5-image/src/image'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload'
import Link from '@ckeditor/ckeditor5-link/src/link'
import List from '@ckeditor/ckeditor5-list/src/list'
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import UploadAdapter from '@ckeditor/ckeditor5-upload/src/filerepository'

import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import Code from '@ckeditor/ckeditor5-basic-styles/src/code'
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript'

import { VIDEO_PROVIDERS } from '../../../store/constants'
// import DropdownButtons from './plugins/dropdown'

// const STYLE_BASE = 'editor_'

const editorConfig = {
  // // plugins: [Alignment, Highlight, ...ClassicEditor.builtinPlugins],
  // // plugins: [...ClassicEditor.builtinPlugins, Alignment],
  plugins: [
    Alignment,
    Autoformat,
    BlockQuote,
    CKFinder,
    CKFinderUploadAdapter,
    EasyImage,
    Essentials,
    Heading,
    Highlight,
    Image,
    ImageCaption,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    PasteFromOffice,
    Table,
    TableToolbar,
    UploadAdapter,

    Bold,
    Italic,
    Underline,
    Strikethrough,
    Code,
    Subscript,
    Superscript,

    // DropdownButtons,
  ],
  alignment: {
    options: ['left', 'right', 'center', 'justify'],
  },
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
      // { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
    ],
  },
  highlight: {
    options: [
      {
        model: 'greenMarker',
        class: 'marker-green',
        title: 'Green marker',
        color: 'var(--ck-highlight-marker-green)',
        type: 'marker',
      },
      {
        model: 'yellowMarker',
        class: 'marker-yellow',
        title: 'Yellow marker',
        color: 'var(--ck-highlight-marker-yellow)',
        type: 'marker',
      },
      {
        model: 'redPen',
        class: 'pen-red',
        title: 'Red pen',
        color: 'var(--ck-highlight-pen-red)',
        type: 'pen',
      },
    ],
  },
  image: {
    // You need to configure the image toolbar, too, so it uses the new style buttons.
    toolbar: [
      'imageTextAlternative',
      '|',
      'imageStyle:alignLeft',
      'imageStyle:full',
      'imageStyle:alignCenter',
      // 'imageStyle:side',
      'imageStyle:alignRight',
    ],
    styles: [
      // This option is equal to a situation where no style is applied.
      'full',
      // 'side',
      // This represents an image aligned to the left.
      'alignLeft',
      // This represents an image aligned to the right.
      'alignRight',
      'alignCenter',
    ],
    // toolbar: ['imageTextAlternative', '|', 'imageStyle:full', 'imageStyle:side']
  },
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
  },
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'code',
    // 'subscript',
    // 'superscript',
    'highlight',
    '|',
    'imageUpload',
    'insertTable',
    'mediaEmbed',
    '|',
    'link',
    'bulletedList',
    'numberedList',
    'blockQuote',
    'alignment',
    '|',
    'undo',
    'redo',
  ],
  language: 'ru',
  ckfinder: {
    uploadUrl: `${config.apiUrl}/common/upload/temp`,
  },
  mediaEmbed: {
    //   // see https://ckeditor.com/docs/ckeditor5/latest/api/module_media-embed_mediaembed-MediaEmbedConfig.html#member-extraProviders
    providers: VIDEO_PROVIDERS,
  },
}

export default class EditorBase extends Component {
  constructor(props) {
    super(props)
    const {
      input: { value },
      imageFolder,
    } = props

    this.state = {
      editorText: convertImageUrlUuid(value, imageFolder),
    }
  }

  onChange = (event, editor) => {
    const { input: { onChange } = {} } = this.props
    const convertedHtml = convertImageUrlUuid(editor.getData())
    onChange && onChange(convertedHtml)
  }

  render() {
    const { uploadFolder = 'temp' } = this.props
    const { editorText } = this.state

    editorConfig.ckfinder = {
      uploadUrl: `${config.apiUrl}/common/upload/${uploadFolder}`,
    }

    return (
      <CKEditor
        editor={ClassicEditor}
        data={editorText}
        config={editorConfig}
        onChange={this.onChange}
      />
    )
  }
}
