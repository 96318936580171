export default [
    
    {
      name: 'Căutare mărfuri',
      type: 'item',
      href: '/cargo/search',
    },
    {
      name: 'Căutare transport',
      type: 'item',
      href: '/transport/search',
    },
    {
      name: 'Adăugați marfа',
      type: 'item',
      href: '/cargo/add',
      showLoginAlert: true
    },
    {
      name: 'Adăugați transportul',
      type: 'item',
      href: '/transport/add',
      showLoginAlert: true
    },
  ]