import { connect } from 'react-redux'
import { compose } from 'recompose'

import withTranslation from '../../globals/components/withTranslation'
import { setActivePopup } from '../../store/actions/ui'
import { getUserStatistics } from '../../store/actions'
import { IReduxStorage } from '../../store/reducers/types'

import Component from './component'
import withPermissions from '../../globals/components/withPermissions'

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state: IReduxStorage) => {
      const profile = state.auth.profile.get('data')
      return {
        activeGuid: state.ui.activePopupGuid,
        statistics: state.profile.statistics,
        firstName: profile.get('firstName'),
        lastName: profile.get('lastName'),
        companyId: profile.get('companyId'),
        userCompanyName: profile.get('userCompanyName'),
        isCompanyAdmin: profile.get('isCompanyAdmin'),
      }
    },
    {
      setActivePopup,
      getUserStatistics,
    }
  )
)(Component)
