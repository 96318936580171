import { connect } from 'react-redux'
import { compose } from 'recompose'

import withTranslation from '../../../../globals/components/withTranslation'
import { setActivePopup } from '../../../../store/actions/ui'
import {
  getUserStatistics,
  setCompanyVerivicationData,
  setInitialMessengerState,
  setCompanyJoinRequests
} from '../../../../store/actions'
import { autoparkSetActiveTab } from '../../../../store/actions/transport'
import Component from './component'
import withPermissions from '../../../../globals/components/withPermissions'
import { IReduxStorage } from '../../../../store/reducers/types'
import { showTutorialJoinCompanyPopup } from "../../../../store/actions/company"
import {
  logout
} from '../../../../store/actions'

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state: IReduxStorage) => {
      const profile = state.auth.profile.get('data')
      return {
        activeGuid: state.ui.activePopupGuid,
        statistics: state.profile.statistics,
        profileGtu: state.profile.profile.gtu,
        companyVerifiedInfo: state.company.companyVerifiedInfo,
        profile: profile.toJS(),
        firstName: profile.get('firstName'),
        lastName: profile.get('lastName'),
        companyId: profile.get('companyId'),
        userCompanyName: profile.get('userCompanyName'),
        isCompanyAdmin: profile.get('isCompanyAdmin'),
        accessStatus: profile.get('accessStatus'),
        accessStatusExpires: profile.get('accessStatusExpires'),
        unreadMsgs: state.socket.unreadMsgs,
        languagesIsFetching: state.ui.languagesIsFetching,
        showRequestInCompanyInProcess: state.company.showRequestInCompanyInProcess,
        requestCompanyName: state.company.requestCompanyName,
        requestCompanyId: state.company.requestCompanyId,
        logoutFromAllDevices: state.socket.logoutFromAllDevices,
        companyJoinRequests: state.company.companyJoinRequests,
      }
    },
    {
      setActivePopup,
      getUserStatistics,
      setCompanyVerivicationData,
      setInitialMessengerState,
      autoparkSetActiveTab,
      showTutorialJoinCompanyPopup,
      setCompanyJoinRequests,
      logout
    }
  )
)(Component)
