import React from 'react'

import { CheckboxField } from '../../../../../UI/checkbox'

import './styles.scss'

export default ({ t }) => {
  return (
    <div key={4} className="panel-content-row">
      <div className="panel-form-row">
        <span className="label">{t('available.documents')} ({t('optional')})</span>
        <div className="checkboxes-aligned clearfix">
          <div className="panel-form-col">
            <CheckboxField name="isTir">TIR</CheckboxField>
          </div>
          <div className="panel-form-col">
            <CheckboxField name="isCmr">CMR</CheckboxField>
          </div>
          
          <div className="panel-form-col">
            <CheckboxField name="isCustom">{t('cargo.doc.custom')}</CheckboxField>
          </div>

          <div className="panel-form-col">
            <CheckboxField name="isDeclaration">{t('under.t1.declaration')}</CheckboxField>
          </div>

          <div className="panel-form-col">
            <CheckboxField name="isSanitary">{t('cargo.insurance.cmr')}</CheckboxField>
          </div>
          
        </div>
      </div>
    </div>
  )
}
