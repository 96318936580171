import React, { Component } from 'react'
import { match } from 'react-router'
import { confirmAlert } from 'react-confirm-alert'

import { toastr } from 'react-redux-toastr'
import {
  getAccessList,
  getUserFullName,
  addAccessPoint,
  deleteAccessPoint,
  setAccessGroup,
} from '../../store/actions/access'
import { AccessPoint, AccessGroup } from '../../store/reducers/types'
import Widget from '../shared/components/widget'
import Filter from '../shared/components/widget-filter'
import WidgetList from '../shared/components/widget-list'
import AddAdminNotice from '../admin-notice/add'
import fetch from '../shared/fetch'
import AccessDates from "./access-dates"
import classNames from 'classnames'

import './styles.scss'

interface FilterItem {
  name: string
  value: string
}

interface AccessPointsState {
  userId: number // my id
  selectedKey: string
  filterItems: FilterItem[]
  selectedUserInfo: {
    activeStat: string
  }
}

interface AccessPointsProps extends PermissionInjectProps, ComponentBaseProps {
  match: match<{ userId }>
  getAccessList: typeof getAccessList
  getUserFullName: typeof getUserFullName
  deleteAccessPoint: typeof deleteAccessPoint
  addAccessPoint: typeof addAccessPoint
  setAccessGroup: typeof setAccessGroup
  accessPoints: AccessPoint[]
  accessGroups: AccessGroup[]
  userFullName: string
}

const BLOCKED = "B"
const DELETED = "D"

export default class UserAccess extends Component<AccessPointsProps, AccessPointsState> {
  constructor(props) {
    super(props)
    this.state = {
      userId: this.props.match.params.userId,
      selectedKey: 'accessPoints',
      filterItems: [
        { name: props.t('access.points'), value: 'accessPoints' },
        { name: props.t('access.groups'), value: 'accessGroups' },
      ],
      selectedUserInfo: {
        activeStat: ""
      }
    }
  }

  componentDidMount() {
    const { getAccessList, getUserFullName } = this.props
    const { userId } = this.state
    this.props.getAccessList(userId)
    this.props.getUserFullName(userId)
    getAccessList(userId)
    getUserFullName(userId)
    this.getUserBlockedInfo(userId)

  }

  getUserBlockedInfo = (userId) => {
    fetch(`/auth/getProfile/${userId}`).then(res => {
      this.setState({ selectedUserInfo: res.profile })
    })
  }

  refresh = () => {
    const { getAccessList } = this.props
    const { userId } = this.state
    getAccessList(userId)
  }

  handleSubmit = (idAccess, value, dateBeginStruct, dateEndStruct) => {
    const { deleteAccessPoint, addAccessPoint, setAccessGroup } = this.props
    const { selectedKey, userId } = this.state

    if (selectedKey === 'accessPoints' && value) {
      deleteAccessPoint({
        userId,
        idAccess,
      })
    } else if (selectedKey === 'accessPoints') {
      addAccessPoint({
        userId,
        idAccess,
      })
    } else if (selectedKey === 'accessGroups') {
      setAccessGroup({
        userId,
        idAccess,
        dateBeginStruct,
        dateEndStruct,
      })
    }
  }

  clickFilter = key => {
    this.setState({ selectedKey: key })
  }

  renderRows = items => {
    const { t } = this.props
    return (
      <div>
        {items.map((item, index) => (
          <div key={index} className="panel-content-row">
            {item.name}
          </div>
        ))}
        <div className="panel-content-row">
          <button type="button" className="button button-orange button-centered">
            {t('access.add.new.group')}
          </button>
        </div>
      </div>
    )
  }

  onAccessChange = access => {
    const { t } = this.props
    const { idAccess, groupId, value } = access

    if (idAccess === 1) {
      confirmAlert({
        title: t('access.admin'),
        message: t('access.admin.verification'),
        buttons: [
          {
            label: t('yes'),
            onClick: () => this.handleSubmit(idAccess, value, null, null),
          },
          {
            label: t('no'),
            onClick: () => { return true; },
          },
        ],
      })
    } else {
      this.handleSubmit(idAccess || groupId, value, null, null)
    }
  }

  onBlockUnblock = (userId) => {
    const { t } = this.props
    const { selectedUserInfo: { activeStat } } = this.state

    const isBlocked = activeStat === BLOCKED || activeStat === DELETED

    const alertMessage = isBlocked ? t('was.unblocked') : t('was.blocked')
    const status = isBlocked ? false : true

    return fetch(`/user/${userId}/block/${status}`, {
      method: 'POST',
    }).then(() => {
      toastr.success(t('user.was.succefully.blocked.unblocked', { alertMessage }), {
        timeOut: 4000,
        transitionIn: 'bounceInDown', //'fadeIn',
        transitionOut: 'bounceOutUp', //'fadeOut',
        position: 'top-left',
        progressBar: true,
      })
      this.getUserBlockedInfo(userId)
    })

  }

  blockUnblockUser = (userId) => {
    const { t } = this.props
    const { selectedUserInfo: { activeStat } } = this.state
    const isBlocked = activeStat === BLOCKED || activeStat === DELETED
    const alertTitle = isBlocked ? t('unblock') : t('block')
    const alertMessage = isBlocked ? t('unblocking') : t('blocking')
    confirmAlert({
      title: `${alertTitle} ${t('to.user')}`,
      message: t('are.you.sure.block.unblock', { alertMessage }),
      buttons: [
        {
          label: t('yes'),
          onClick: () => this.onBlockUnblock(userId),
        },
        {
          label: t('no'),
          onClick: () => { return true; },
        },
      ],
    })
  }

  render() {
    const { selectedKey, filterItems, userId, selectedUserInfo: { activeStat } } = this.state
    const { t, permissions, accessPoints, accessGroups, userFullName } = this.props
    const access = { accessPoints, accessGroups }
    const isBlocked = activeStat === BLOCKED || activeStat === DELETED
    const buttonLabel = isBlocked ? t('unblock.user') : t('block.user')
    // const refresh = 123
    return (
      permissions.some(el => el === 'admin') && (
        <div className="row">
          <div className="col-md-10">
          <Widget title={`${t('access.user')}: ${userFullName || ''}(id:${userId})`}>
              <Filter
                items={filterItems}
                selectedKey={selectedKey}
                name="filterKey"
                loadLanguages
                onClick={this.clickFilter}
              />
              {access[selectedKey].map((access, index) => {

                const handleSubmit = this.handleSubmit

                return (
                  <div key={index}>
                    <AccessDates
                      {...{ access }}
                      {...{ selectedKey }}
                      {...{ handleSubmit }}
                    />
                  </div>
                )

                {/* <div className="tr-card" >
                    <div className="access-point-description">
                      <div>{(access.idAccess || access.groupId) + '. ' + access.name}</div>
                      <div className="input-container">
                        <input
                          type={selectedKey === 'accessPoints' ? 'checkbox' : 'radio'}
                          name={access.name}
                          value={access.name}
                          id={`access_${access.name}`}
                          checked={access.value}
                          onChange={() => this.onAccessChange(access)}
                        />
                        <label htmlFor={`access_${access.name}`} className="loading-place-checkbox" />
                      </div>
                    </div>
                    {selectedKey === "accessGroups" && <div className="access-dates">
                      <div className="banner-line-description">
                        <div className="date-label">{t('ads.banner.date.from')}</div>
                        <div className="banner-input-field date-field">
                          <DatePicker
                            initialValue={dateBegin}
                            onChangeDate={this.onChangeDateFrom}
                          // disabled={bannerEditModalData?.bannerId}
                          />
                        </div>
                      </div>
                      <div className="banner-line-description">
                        <div className="date-label">{t('ads.banner.date.to')}</div>
                        <div className="banner-input-field date-field">
                          <DatePicker
                            initialValue={dateEnd}
                            onChangeDate={this.onChangeDateTo}
                          // disabled={bannerEditModalData?.bannerId}
                          />
                        </div>
                      </div>
                    </div>}

                  </div> */}










              })}
            </Widget>
          </div>
          <div className="col-md-6">
            <WidgetList
              title={t('access.groups')}
              items={access.accessGroups}
              renderRows={items => this.renderRows(items)}
            />
            <AddAdminNotice />

            <div className="user-action-buttons-block">
              {isBlocked && <div className="alert-status">
                {t('user.is.blocked')}
              </div>}
              <button
                type="button"
                className="button button-orange"
                onClick={() => this.blockUnblockUser(userId)}
              >
                {buttonLabel}
              </button>
            </div>

          </div>
        </div>
      )
    )
  }
}
