import React, { Fragment } from 'react'
import Pagination from '../../../UI/pagination'
import { SHOW_ADS_MIN_ITEMS } from "../../utils"
import classnames from 'classnames'
import Banner from '../../../banner'

export default props => {
  const { items, renderRows, pagination, classNames, paginationClassName, bannerId, needToShowAdsNearPagination = false } = props

  const {
    totalItems = 0,
    pageIndex = 0,
    pageSize = 20,
    goto = index => {
      console.log('go to page index ', index)
    },
  } = pagination || {}

  const handlePageChanging = index => {
    index >= 0 && goto(index)
  }

  return (
    <Fragment>
      <table className={classnames('table', 'table-cargo', classNames)}>
        <tbody>{renderRows(items)}</tbody>
      </table>
      {totalItems <= SHOW_ADS_MIN_ITEMS && <div style={{ marginBottom: 20 }}>
        <Banner {...{ bannerId }} />
      </div>}
      <Pagination
        total={totalItems}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onClick={handlePageChanging}
        className={paginationClassName}
      />
    </Fragment>
  )
}
