import React, { Component } from 'react'
import fetch from '../shared/fetch'
import WidgetList from '../shared/components/widget-list'

interface ForumProps extends ComponentBaseProps {
  isAuthenticated: boolean
}

export default class News extends Component<ForumProps> {
  state = {
    items: [],
    sections: [],
    selectedKey: "",
  }

  componentDidMount() {
    this.fetchForumSections().then(() => {
      this.fetchThemes()
    })
  }

  fetchForumSections = () =>
    fetch('/forum/getAllSections').then(_sections => {
      const sections = _sections.data.map(({ id, title }) => {

        return {
          value: parseInt(id),
          name: title[0]?.toUpperCase() + title.slice(1).toLowerCase(),
        }
      })
      this.setState({ sections })
    })

  fetchThemes = (filterKey = this.state.selectedKey) => {
    fetch(`/forum/getAllThemes/${filterKey || 0}/5/0/0`).then(themes => {
      const items = themes.data.themes.map(({ dateAdded, title, id, section }) => ({
        date: dateAdded,
        text: title,
        id,
        sectionId: section.id
      }))
      this.setState({ items })
    })
  }

  render() {
    const { sections, selectedKey, items } = this.state
    const { t } = this.props

    const filterBar = {
      items: sections,
      emptyOption: t("all"),
      selectedKey,
      onClick: selectedKey => {
        this.setState({ selectedKey }, () => {
          this.fetchThemes(selectedKey)
        })
      },
    }

    return (
      <WidgetList
        title={t('forum.last.on.forum')}
        items={items}
        filterBar={filterBar}
        linksTo={{ title: '/forum', item: `/forum` }}
        insertItemSectionIdInUrl={true}
        loadLanguages
        dataMoveForMobile={"panel-forum"}
      />
    )
  }
}
