// import Component from './component'

// export default Component

import { compose, pure } from 'recompose'
import withTranslation from '../../../globals/components/withTranslation'
import Component from './component'

export default compose(
  withTranslation,
  // withPermissions,
  pure
)(Component)
