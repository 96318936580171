export const md4 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>ПРАВИЛА предоставления транспортно-экспедиционных услуг</h4><p></p>

<p></p><h4>I. Общие положения</h4><p></p>

<p style="font-weight:500;">1. Настоящие правила разработаны Министерством транспорта и связи в соответствии со статьей 2 и статьей 54 Кодекса автомобильного транспорта (Органический закон N 116-ХIV от 29.07.98 г., 
Официальный монитор, 1998 г., N 90-91, ст.581) и определяют порядок предоставления транспортно-экспидиционных услуг в Республике Молдова.</p><br>

<p style="font-weight:500;">2. Под предоставлением транспортно-экспедиционных услуг понимается транспортно-экспедиционное обслуживание в качестве производственной деятельности, которая состоит в организации перевозки 
груза,обеспечении его отправки и получения, выполнении или организации выполнения других операций, связанных со всеми этапами перевозки.</p>

<p style="text-indent:2em;"></p><h6>3. Определения:</h6><p></p>

<p style="text-indent:2em;">экспедитор - юридическое или физическое лицо, заключившее договор на оказание заказчику транспортно-экспедиционных услуг;</p><br>

<p style="text-indent:2em;">заказчик - юридическое или физическое лицо,заключившее с экспедитором договор на оказание транспортно-экспедиционных услуг;</p><br>

<p style="text-indent:2em;">перевозчик - любое физическое или юридическое лицо, осуществляющее перевозку грузов своими собственными транспортными средствами (фактический или действительный перевозчик), или любое физическое или юридическое
 лицо,выполняющее перевозку грузов посредством возложения на себя обязательств перевозчика по доставке груза получателю (договорной перевозчик). Экспедитор может выступать договорным перевозчиком;</p><br>

<p>груз - любое имущество, предъявляемое к перевозке;</p><br>

<p style="text-indent:2em;">поручение - документ,выдаваемый заказчиком экспедитору на организацию перевозки и экспедиторское обслуживание заказчика.</p>

<p></p><h4>II. Организация транспортно-экспедиционного обслуживания</h4><p></p>

<p style="font-weight:500;">4. Транспортно-экспедиционное обслуживание осуществляется на основании заключенного между заказчиком и экспедитором транспортно-экспедиционного договора.</p><br>

<p>Договор заключается в письменной форме в соответствии с законодательством Республики Молдова.</p>

<p style="text-indent:2em;"></p><h6>5. Виды транспортно-экспедиционных услуг:</h6><p></p>

<p>- упаковка (распаковка),маркировка,пакетирование, сортировка и хранение грузов;</p><br>

<p>- взвешивание грузов (или) транспортных средств;</p><br>

<p>- выполнение погрузочно-разгрузочных работ;</p><br>

<p style="text-indent:2em;">- закрепление,укрытие и увязка грузов,предоставление необходимых для этих целей приспособлений и материалов;</p><br>

<p>- выбор оптимальной схемы перевозки грузов по условиям заказчика;</p><br>

<p style="text-indent:2em;">- прием грузов со склада (терминала) заказчика,от перевозчика, доставка и сдача груза на склад (терминал), перевозчику для доставки по назначению;</p><br>

<p>- организация перевозки груза от места отправления до места назначения;</p><br>

<p>- оформление перевозочных документов;</p><br>

<p>- сопровождение и охрана груза в пути;</p><br>

<p>- осуществление расчетов с перевозчиком за выполнение перевозки груза;</p><br>

<p>- страхование груза;</p><br>

<p style="text-indent:2em;">- при международных перевозках выполнение таможенных процедур, обеспечение проставления визы в паспорте;</p><br>

<p>- ведение учета и отчетности при перевозке груза;</p><br>

<p>- расчет рациональной загрузки транспортных средств, схем размещения и крепления груза;</p><br>

<p style="text-indent:2em;">- уведомление об отправлении, местонахождении и прибытии груза в места назначения (диспозиция продвижения груза);</p><br>

<p>- инфоромация о видах услуг,тарифах и режимах работы других экспедиторов и иная оперативная информация;</p><br>

<p>- консультация по вопросам,связанным с транспортно-экспедиционным обслуживанием;</p><br>

<p>- иные услуги, связанные с перевозкой груза.</p><br>

<p style="font-weight:500;">6. При осуществлении транспортно-экспедиционного обслуживания имущества в рамках заключенного договора заказчиком выдается поручение на экспедирование.</p><br>

<p style="font-weight:500;">7. Наряду с договором или поручением на экспедирование перевозчик и экспедитор оформляют транспортные документы в соответствии с законодательством Республики
 Молдова и международными договорами или соглашениями (товарно-транспортные накладные, международные транспортные накладные(CMR),коносаменты и т.д.).</p><br>

<p style="font-weight:500;">8. Расчеты между экспедитором и заказчиком за оказание услуг производятся на основании транспортно-экспедиционного договора.</p><br>

<p style="font-weight:500;">9. Способ и маршрут перевозки осуществляются на основе поручения заказчика на экспедирование. В случае, если поручение неполное, или не соответствует договору,
 экспедитор имеет право на риск и за счет заказчика действовать так, как он считает подходящим в сложившейся обстановке.</p><br>

<p style="font-weight:500;">10. Страхование грузов может осуществляться экспедитором только по письменному поручению заказчика. При этом страхование производится с учетом исключений и
 страховых полисов страховых компаний или гарантов,принимающих на себя риски.</p><br>

<p style="font-weight:500;">11. Грузы,которые могут нанести ущерб другим грузам (опасные грузы), а также скоропортящиеся грузы принимаются экспедитором только по письменному поручению.</p><br>

<p style="text-indent:2em;">Если такие грузы передаются экспедитору без специальных указаний, заказчик принимает на себя ответственность за все убытки, которые могут возникнуть в связи с перевозкой и перевалкой
 этих грузов.</p><br>

<p style="font-weight:500;">12. Транспортно-экспедиционные услуги, оказываемые населению на территории Республики Молдова, осуществляются на основе заказа, который подтверждается
 квитанцией об оплате.</p><br>

<p style="text-indent:2em;">Транспортно-экспедиционные услуги, оказываемые населению, включают любые действия по погрузке, разгрузке, экспедированию, упаковке и перевозке имущества (бытовые предметы, багаж
 и другое имущество).</p><br>

<p style="text-indent:2em;">Квитанция является документом первичного учета, специального режима с защитными знаками, серией и номером, устанавливаемых централизованно.</p>

<p></p><h4>III. Транспортно-экспедиционный договор</h4><p></p>

<p style="font-weight:500;">13. По договору экспедитор обязуется за оплату от имени и за счет заказчика выполнить или организовать выполнение услуг, связанных с перевозкой груза.</p>

<p style="text-indent:2em;"></p><h6>14. Договор должен содержать следующие условия:</h6><p></p>

<p>- наименование и адреса сторон;</p><br>

<p>- предмет договора;</p><br>

<p>- права и обязательства экспедитора;</p><br>

<p>- права и обязательства заказчика;</p><br>

<p>- расчеты за перевозку;</p><br>

<p>- ответственность сторон.</p><br>

<p>В договор по соглашению сторон могут включаться и иные условия.</p><br>

<p style="text-indent:2em;">Примерный вариант договора прилагается к настоящим правилам (Приложение 1) и носит рекомендательный характер.</p>

<p></p><h4>IV. Поручение на экспедирование</h4><p></p>

<p style="font-weight:500;">15. Поручением на экспедирование является документ, выдаваемый заказчиком экспедитору на организацию перевозки и экспедиторское обслуживание заказчика.
 Экспедиторское поручение выдается как в рамках заключенного договора, так и может быть разовым.</p><br>

<p style="font-weight:500;">16. В том случае, если поручение на экспедирование выдается заказчиком в рамках подписанного между заказчиком и экспедитором договора и наличия согласованных
 ставок, экспедитор принимает такое поручение к исполнению немедленно по его получении.</p><br>

<p style="font-weight:500;">17. Если поручение на экспедирование выдается заказчиком в качестве отдельного задания, необходимо подтверждение экспедитора на исполнение такого поручения.
 Подтвержденное экспедитором поручение рассматривается как заключенный договор на оказание транспортно-экспедиционных услуг.</p><br>

<p style="font-weight:500;">18. Поручение на экспедирование выдается в письменной форме.</p><br>

<p style="font-weight:500;">19. При необходимости исполнения срочного поручения на экспедирование экспедитор может принять поручение устно по телефону при условии обязательного оформления
 в дальнейшем в десятидневный срок этого поручения в письменном виде.До предоставления экспедитору письменного подтверждения заказчик несет все риски, связанные с неправильной или неполной
 передачей первоначального устного поручения.</p><br>

<p style="font-weight:500;">20. В случае, если после получения письменного подтверждения поручения, переданного по телефону, обнаруживается несоответствие между устным и письменным поручением,
 экспедитор обязан немедленно информировать об этом заказчика и запросить уточнение поручения.</p><br>

<p style="font-weight:500;">21. Изменения и дополнения поручений на экспедирование должны производиться только в письменном виде.</p><br>

<p style="font-weight:500;">22. Выданное экспедитору поручение должно содержать все данные,необходимые для надлежащего его выполнения.</p><br>

<p style="font-weight:500;">23. Примерное содержание поручения на экспедирование прилагается (Приложение 2) и носит рекомендательный характер.</p>

<p></p><h4>V. Права и обязанности экспедитора</h4><p></p>

<p style="text-indent:2em;"></p><h6>24. Экспедитор и м е е т п р а в о:</h6><p></p>

<p style="text-indent:2em;">- требовать от заказчика возмещения всех расходов, возникших у него при исполнении поручения,а также согласованную оплату услуг;</p><br>

<p>- привлекать третьих лиц для выполнения поручения;</p><br>

<p>- осуществлять перевозку груза собственным транспортом. В этом случае заключается и договор перевозки;</p><br>

<p style="text-indent:2em;">- выбирать по своему усмотрению перевозчиков и определять транспортные средства и маршруты перевозки, если иное не предусмотрено транспортно-экспедиционным договором;</p><br>

<p style="text-indent:2em;">- оказывать услуги, связанные с ввозом, вывозом и транзитным перемещением грузов через таможенную границу Республики Молдова, в соответствии с таможенным законодательством республики;</p><br>

<p style="text-indent:2em;">- оставлять за собой груз и относящиеся к нему документы в качестве залога на причитающуюся сумму, включая расходы по складированию и хранению, и компенсацию всех понесенных им расходов;</p><br>

<p>- осуществлять транспортно-экспедиционные услуги, указанные в пункте 5.</p>

<p style="text-indent:2em;"></p><h6>25. Экспедитор о б я з а н:</h6><p></p>

<p>- выполнять принятый заказ добросовестно и с соблюдением интересов заказчика;</p><br>

<p style="text-indent:2em;">- точно следовать указаниям заказчика. Если данные указания мешают экономичной и безопасной доставке груза, экспедитор должен немедленно обратить на это внимание заказчика.
 Если заказчик повторяет свое указание, экспедитор выполняет поручение с отнесением всех рисков на заказчика;</p><br>

<p style="text-indent:2em;">- незамедлительно информировать заказчика о невозможности исполнения поручения по не зависящим от него причинам;</p><br>

<p style="text-indent:2em;">- оформлять в установленном порядке соответствующие акты в случае недостачи (излишков), повреждения грузов или упаковки;</p><br>

<p style="text-indent:2em;">- обеспечить своевременную подачу заказчику транспортных средств в исправном состоянии и пригодных для данной перевозки в согласованные с заказчиком сроки.</p>

<p></p><h4>VI. Права и обязанности заказчика</h4><p></p>

<p style="text-indent:2em;"></p><h6>26. Заказчик и м е е т п р а в о:</h6><p></p>

<p>- требовать выполнения принятого заказа добросовестно и в установленный срок;</p><br>

<p style="text-indent:2em;">- отозвать ранее выданное экспедитору поручение с обязательным возмещением экспедитору фактических расходов, связанных с исполнением поручения.</p><br>

<p style="font-weight:500;">27. Заказчик принимает на себя все расходы и затраты, связанные с действиями экспедитора в интересах заказчика в непредвиденных обстоятельствах,
 оговоренных в транспортно-экспедиционном договоре.</p>

<p style="text-indent:2em;"></p><h6>28. Заказчик о б я з а н:</h6><p></p>

<p>- выдать экспедитору поручение на экспедирование;</p><br>

<p>- предъявлять груз к перевозке в указанных объемах и в согласованные с экспедитором сроки;</p><br>

<p style="text-indent:2em;">- сообщать экспедитору всю необходимую для осуществления перевозки информацию о грузе и своевременно предоставлять в распоряжение экспедитора все документы, требуемые для выполнения
 поручения. Указать особые свойства груза, вследствие которых может быть нанесен вред другим грузам, людям, окружающей среде, а также обращать внимание на подверженность груза
 быстрой порче и разложению;</p><br>

<p style="text-indent:2em;">- гарантировать правильность и полноту предоставляемых экспедитору данных о грузах,передаваемых экспедитору, обращая особое внимание на свойства,касающиеся общей природы груза,
 его маркировки, веса, объема, количества и, если требуется,на степень опасности груза;</p><br>

<p style="text-indent:2em;">- возместить экспедитору все затраты и расходы, понесенные им в связи с исполнением поручения на экспедирование груза;</p><br>

<p style="text-indent:2em;">- возместить экспедитору все предъявляемые ему иски, проистекающие из общей аварии, и предоставить по требованию экспедитора гарантии,обеспечивающие удовлетворение таких исков;</p><br>

<p>- уплатить экспедитороу согласованную сумму в соответствии с транспортно-экспедиционным договором.</p>

<p></p><h4>VII. Ответственность сторон</h4><p></p>

<p style="font-weight:500;">29. Экспедитор несет ответственность за ущерб, причиненный заказчику, в связи с утратой или повреждением груза, а также за задержку в доставке груза,
 если обстоятельства,вызвавшие эту утрату, повреждение или задержку, имели место во время нахождения груза в его ведении.</p>

<p style="text-indent:2em;"></p><h6>30. Экспедитор не несет ответственности за:</h6><p></p>

<p style="text-indent:2em;">- ценности или опасные грузы (скоропортящиеся грузы), если они не заявлены экспедитору во время заключения договора;</p><br>

<p style="text-indent:2em;">- убытки, проистекающие из-за задержки в доставке, если такая ответственность не выражена четко в письменной форме;</p><br>

<p style="text-indent:2em;">- внутритарную недостачу содержимого грузовых мест, принятых (переданных) в исправной таре;</p><br>

<p style="text-indent:2em;">- убытки в результате хищения грузов на транспортных средствах (кроме собственного автотранспорта),если иное не оговорено в договоре на оказание транспортно-экспедиционных услуг;</p><br>

<p>- возможные последствия неполных данных,указанных в поручении.</p>

<p style="text-indent:2em;"></p><h6>31. Заказчик несет материальную ответственность за убытки, причиненные экспедитору:</h6><p></p>

<p>- ненадлежащим выполнением условий договора;</p><br>

<p>- непредъявлением груза к перевозке - в размере штрафных санкций;</p><br>

<p style="text-indent:2em;">- действиями, приведшими к простою транспортных средств, сверх времени оговоренного транспортно-экспедиционным договором.</p><br>

<p style="font-weight:500;">32. Экспедитор и заказчик могут оговаривать и другие права, обязанности и ответственности сторон в заключаемом между собой договорке,
 не противоречащие законодательству и настоящим Правилам.</p>

<p></p><h4>VIII. Дополнительные условия</h4><p></p>

<p style="font-weight:500;">33. Эспедиторы, объединенные в ассоциации (союзы) вправе разрабатывать с учетом настоящих правил основные правила экспедирования для реализации
 уставных целей этих ассоциаций, в том числе с учетом рекомендаций международных организаций.</p>

<p style="text-indent:2em;"></p><h6>Приложение 1</h6><p></p>

<p></p><h4>ТРАНСПОРТНО-ЭКСПЕДИЦИОННЫЙ ДОГОВОР</h4><p></p>

<p>"______"___________20__ a.</p>

<p>______________________________________________________</p>

<p>(наименование субъекта хозяйствования, осуществляющего</p>

<p>____________________________________________________</p>

<p>транспортно-экспедиционные услуги)</p>
    
<p>в лице _____________________________________________</p>

<p>(должность,фамилия,имя и отчество)</p>

<p>действующего на основании __________________________</p>

<p>(устава, положения)</p>

<p>именуемое в дальнейшем Экспедитор, с одной стороны и</p>

<p>Заказчик ____________________________________________</p>

<p>(наименование заказчика)</p>

<p>в лице ______________________________________________</p>

<p>(должность,фамилия,имя и отчество)</p>

<p>действующего на основании ___________________________</p>

<p>(устава, положения)</p>

<p style="text-indent:2em;"></p><h6>с другой стороны, заключили настоящий договор о нижеследующем:</h6><p></p>

<p></p><h4>I. ПРЕДМЕТ ДОГОВОРА</h4><p></p>

<p style="text-indent:2em;"></p><h6>1. Заказчик передает,а экспедитор принимает на себя:</h6><p></p>

<p>организацию перевозок ______________________________</p>
	
<p>вид груза, вес в кг)</p>

<p style="text-indent:2em;"></p><h6>выполнение следующих транспортно-экспедиционных услуг:</h6><p></p>
<p>______________________________________________________</p>

<p></p><h4>II. ОБЯЗАННОСТИ ЗАКАЗЧИКА И ЭКСПЕДИТОРА</h4><p></p>

<p style="text-indent:2em;">2. Обязанности заказчика и экспедитора устанавливаются в соответствии с \"Правилами транспортно-экспедиционных услуг\",
 утвержденными Министерством транспорта и связи 9 декабря 1999 года.</p>

<p style="text-indent:2em;"></p><h6>3. В дополнение к п.2 стороны определились:</h6><p></p>

<p>_______________________________________</p>
<p>_______________________________________</p>
<p>_______________________________________</p>

<p></p><h4>III. РАСЧЕТЫ ЗА ПЕРЕВОЗКУ</h4><p></p>

<p style="text-indent:2em;">4. Все виды услуг оплачиваются заказчиком по предварительно согласованным ставкам, указанным в приложениях к данному договору.</p>

<p style="text-indent:2em;"></p><h6>5. По взаимной договоренности сторон расчеты за выполняемые услуги производятся заказчиком:</h6><p></p>

<p>- путем предварительной оплаты из расчета предполагаемых объемов работ;</p><br>

<p>- путем внесения денежных сумм наличными в кассу экспедитора;</p><br>

<p>- путем оплаты счетов экспедитора в течение______ дней со дня их выставления заказчику.</p><br>

<p style="text-indent:2em;">6. Если оплата по счету не производится в установленный срок, заказчик выплачивает экспедитору пеню в размере_______% от суммы неоплаты за каждый день просрочки.</p>

<p></p><h4>IV. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h4><p></p>

<p>(сроки доставки, режим работы, тариф за оказание транспортных и экспедиционных услуг и т.д.)</p>

<p>____________________________________________________________________</p>
<p>____________________________________________________________________</p>
<p>____________________________________________________________________</p>
<p>____________________________________________________________________</p>
<p>____________________________________________________________________</p>
<p>____________________________________________________________________</p>

<p></p><h4>V. ОТВЕТСТВЕННОСТЬ СТОРОН И ПОРЯДОК РАССМОТРЕНИЯ СПОРОВ</h4><p></p>

<p style="text-indent:2em;">7. Сторона,нарушившая обязательства по договору, возмещает другой стороне причиненные в результате этого убытки.</p><br>

<p style="text-indent:2em;">8. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по договору,если оно явилось следствием обстоятельств непреодолимой силы (форс-мажорные обстоятельства),
 а именно: пожаров, наводнения, землетрясения и других природных неординарных потрясений, а также забастовок, народных волнений, войн, вооруженных конфликтов, криминальных действий со стороны третьих лиц,
 политических решений.</p><br>

<p style="text-indent:2em;">9. Споры, возникшие при исполнении данного договора, стороны решают в претензионном порядке, исковые требования рассматриваются судебной инстанцией по месту нахождения экспедитора.</p>

<p></p><h4>VI. СРОК ДЕЙСТВИЯ ДОГОВОРА</h4><p></p>

<p style="text-indent:2em;">10. Срок действия настоящего договора устанавливается с_______ по __________ и может быть изменен или расторгнут по обоюдному согласию, а при разногласиях в судебном порядке.</p><br>

<p>11. Настоящий договор составлен в ____ экземплярах, каждый из которых имеет равную юридическую силу.</p>

<p></p><h4>VII. БАНКОВСКИЕ РЕКВИЗИТЫ И ЮРИДИЧЕСКИЕ АДРЕСА СТОРОН</h4><p></p>

<p>Экспедитор________________________________________Заказчик</p>

<p style="text-indent:2em;"></p><h6>Приложение 2</h6><p></p>

<p></p><h4>ПОРУЧЕНИЕ НА ЭКСПЕДИРОВАНИЕ</h4><p></p>

<p>1. Грузоотправитель (наименование, адрес,телефон, страна)</p>

<p>1а. Адрес погрузки Дата \"___\"___________год____ Прибытие под погрузку____час</p>

<p>2. Грузополучатель (наименование, адрес,телефон, страна)</p>

<p>2а. Адрес разгрузки</p>

<p>3.Способ перевозки (авиа,авто).</p>

<p>Тип подвижного состава (ж.д.,море)</p>

<p>4. Количество | Наименование  |  мест   | груза</p>
 
<p>6.Вес | 7.Вес | 8.Объем,(брутто),кг | (нетто),кг | м3</p>

<p>9. Таможня экспортера</p>

<p>9а. Погранпереход</p>

<p>10. Таможня импортера</p> 

<p>10а. Погранпереход</p>

<p>11. Ставка за услуги</p>

<p>12. Инструкция по транспортной обработке</p>

<p>13. Особые условия перевозки</p>

<p>Подпись М.П. Дата \"____\"__________год_____</p>

<p style="text-indent:2em;"></p><h6>Приложение 3</h6><p></p>

<p style="text-indent:2em;"></p><h6>Перечень нормативных актов, утративших силу в связи с принятием Правил предоставления транспортно-экспедиционных услуг:</h6><p></p>

<p style="text-indent:2em;">1. Раздел 60 Правил перевозок грузов автомобильным транспортом, утвержденных Министерством автомобильного транспорта МССР 22 июня 1972 года (г.Кишинев, Картя Молдовеняскэ,1982).</p><br>

<p style="text-indent:2em;">2. Правила транспортно-экспедиционного обслуживания граждан Молдавской ССР,утвержденные Министерством автомобильного транспорта МССР 31 августа 1971 года (г.Кишинев, Картя Молдовеняскэ, 1982 г.).</p><br>

<p><a href="https://www.legis.md/cautare/getResults?doc_id=79475&amp;lang=ru">Источник</a></p>


</div>
`