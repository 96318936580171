import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import {
  reduxForm,
  formValueSelector,
  getFormValues,
  // getFormSubmitErrors,
  getFormSyncErrors,
} from 'redux-form'

import withTranslation from '../../../../globals/components/withTranslation'
import withPermissions from '../../../../globals/components/withPermissions'

import Component from './component'
import dict from '../../../shared/dictionaries'
import {
  saveTransport,
  saveAsTemplate,
  getTemplates,
  deleteTemplate,
  getItem,
  getUserStatistics
} from '../../../../store/actions'
import { TransportItemModel } from '../../../../store/models'
import { AddTransportProps } from './types'
import { IReduxStorage } from '../../../../store/reducers/types'
import { onCanAdd } from '../../../../store/actions/profile'

const formName = 'addTransport'
const formSelector = formValueSelector(formName)

const initialValues = {
  ...TransportItemModel,
  filterKey: 'choose',
  price: {
    type: dict.priceTypes[0].value,
    value: undefined,
    currency: undefined, //dict.currencies.items[0] && dict.currencies.items[0].value,
    recurring: dict.priceRecurrings[0].value,
  },
}

export default compose(
  withPermissions,
  withTranslation,
  connect(
    (state: IReduxStorage) => {
      const profile = state.auth.profile.get('data')

      const langId = state.ui.langId
      const currencies = dict.currencies(langId).items
      initialValues.price.currency = currencies && currencies[0].value

      const template = formSelector(state, 'template')
      const { templates } = state.transport

      initialValues.price.currency = currencies && currencies[0].value

      let initialValuesNew
      if (template) {
        const tmpl = templates.find(x => x.id == template)
        if (tmpl) {
          initialValuesNew = { ...initialValues, ...JSON.parse(tmpl.json), template }
        }
      }

      return {
        initialValues: initialValuesNew,
        isAuthenticated: !!state.auth.accessToken,
        fromCoords: formSelector(state, 'from.coords'),
        toCoords: formSelector(state, 'to.coords'),
        filterKey: formSelector(state, 'filterKey'),
        fromCountryId: Number(formSelector(state, 'from.countryId')),
        toCountryId: Number(formSelector(state, 'to.countryId')),
        fromCityId: Number(formSelector(state, 'from.cityId')),
        toCityId: Number(formSelector(state, 'to.cityId')),
        middlePoints: formSelector(state, 'middlePoints') || {},
        langId: state.ui.langId,
        submitErrors: getFormSyncErrors(formName)(state),
        transportShippingType: formSelector(state, 'transport.shippingType'),
        isLoadingSide: formSelector(state, 'transport.isLoadingSide'),
        isLoadingTop: formSelector(state, 'transport.isLoadingTop'),
        isLoadingRare: formSelector(state, 'transport.isLoadingRare'),
        photo: profile.get('photo'),
        priceType: formSelector(state, 'price.type'),
        shippingType: formSelector(state, 'shippingType'),
        shipOnDate: formSelector(state, 'shipOn.date'),
        carType: formSelector(state, 'carType'),
        template: formSelector(state, 'template'),
        enteredValues: getFormValues(formName)(state),
        templates,
        notes: formSelector(state, 'notes'),
        currentTransport: state.transport.edit,
        canAdd: state.profile.canAdd,
      }
    },
    (dispatch: any) => ({
      saveTransport: (item, cb) => dispatch(saveTransport(item, cb)),
      saveAsTemplate: ({ name, item }, searchType, cb) =>
        dispatch(saveAsTemplate({ name, item }, searchType, cb)),
      getTemplates: (searchType, cb) => dispatch(getTemplates(searchType, cb)),
      deleteTemplate: (templateId, searchType) => dispatch(deleteTemplate(templateId, searchType)),
      getItem: ({ itemId, isEdit }) =>
        dispatch(getItem({ itemId, searchType: 'transport', isEdit })),
      onCanAdd:  (type) => dispatch(onCanAdd(type)),
      getUserStatistics:  (type) => dispatch(getUserStatistics(type)),
    })
  ),
  withHandlers({
    save: (props: AddTransportProps) => (values, cb) => {
      props.saveTransport && props.saveTransport(values, cb)
    },
  }),
  reduxForm({
    form: formName,
    initialValues,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    shouldValidate: () => true,
  })
)(Component)
