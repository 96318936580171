import * as actionTypes from '../actions/action-types'
import { baseProps } from '../models'
import { CompanyState } from './types'

const baseEmptyItem = {
  langId: 1,
  itemId: undefined,
  title: undefined,
  short: undefined,
  body: '',
  priority: '1',
  category: undefined,
  published: false,
  image: undefined,
  video: undefined,
  files: [],
}

const baseItem = {
  itemId: undefined,
  langId: undefined,
  treeTypeId: undefined,
  title: undefined,
  body: undefined,
  date: undefined,
  published: undefined,
  image: undefined,
  short: undefined,
  order: undefined,
  userName: undefined,
  count: undefined,
  mode: undefined,
  ...baseProps,
}

const initialState: CompanyState = {
  list: {
    langId: undefined,
    total: 0,
    pageIndex: 0,
    pageSize: 10,
    filterKey: undefined,
    searchText: undefined,
    items: [],
    ...baseProps,
  },
  globalActiveTab: "",
  item: {
    ...baseItem,
  },
  editModal: {
    isOpen: false,
    item: baseEmptyItem,
  },
  companyVerifiedInfo: {},
  showTutorialPopup: false,
  showRequestInCompanyInProcess: false,
  requestCompanyName: "",
  requestCompanyId: "",
  companyJoinRequests: null
}

function getListProps({
  itemId,
  languages,
  langId,
  total = 0,
  pageIndex = 0,
  pageSize = 10,
  items = [],
}) {
  return {
    itemId,
    languages,
    langId,
    total,
    pageIndex,
    pageSize,
    items,
  }
}

// ------------------------------------------
// news items
// ------------------------------------------
const listStartLoading = (state: CompanyState, action) => {
  const {
    requestedAt,
    langId,
    pageIndex,
    pageSize,
    filterKey,
  } = action

  const list = {
    ...state.list,
    langId,
    pageIndex,
    pageSize,
    filterKey,

    requestedAt,
    loading: true,
    error: undefined,
  }

  return {
    ...state,
    list,
  }
}
const listLoaded = (state: CompanyState, action) => {
  const props = getListProps(action.data)
  const list = {
    ...state.list,
    error: undefined,
    loading: false,
    ...props,
  }

  return { ...state, list }
}
const listLoadError = (state: CompanyState, action) => {
  const list = {
    ...state.list,
    error: action.error,
    loading: false,
    items: [],
  }

  return { ...state, list }
}

// ------------------------------------------
// single news item
// ------------------------------------------
const itemStartLoading = (state: CompanyState, action) => {
  const { requestedAt, itemId } = action
  const item = {
    ...baseItem,
    itemId,
    requestedAt,
    loading: true,
  }
  return { ...state, item }
}
const itemLoaded = (state: CompanyState, action) => {
  const item = {
    ...state.item,
    ...action.data,
    error: undefined,
    loading: false,
  }

  return { ...state, item }
}
const itemLoadError = (state: CompanyState, action) => {
  const item = {
    ...state.item,
    error: action.error,
    loading: false,
  }

  return { ...state, item }
}

// ---------------------------------------
// reducer
// ---------------------------------------
export default (state = initialState, action: any = {}): CompanyState => {
  const { type } = action
  switch (type) {
    case actionTypes.COMPANY_LIST_LOADING:
      return listStartLoading(state, action)
    case actionTypes.COMPANY_LIST_LOADED:
      return listLoaded(state, action)
    case actionTypes.COMPANY_LIST_LOAD_ERROR:
      return listLoadError(state, action)
    case actionTypes.COMPANY_LIST_SET_PARAMS: {
      const {
        payload: { scope, params },
      } = action
      if (scope === 'list') {
        return { ...state, list: { ...state.list, ...params } }
      }

      return state
    }

    case actionTypes.COMPANY_ITEM_LOADING:
      return itemStartLoading(state, action)
    case actionTypes.COMPANY_ITEM_LOADED:
      return itemLoaded(state, action)
    case actionTypes.COMPANY_ITEM_LOAD_ERROR:
      return itemLoadError(state, action)
    case actionTypes.COMPANY_SAVE_START:
      return state
    case actionTypes.COMPANY_SAVED:
      return state
    case actionTypes.COMPANY_SAVE_ERROR:
      return state
    case actionTypes.SET_COMPANY_VERIFICATION_DATA:
      return { ...state, companyVerifiedInfo: action.payload }
    case actionTypes.SET_COMPANY_JOIN_REQUESTS:
      return { ...state, companyJoinRequests: action.payload }

    case actionTypes.SET_GLOBAL_ACTIVE_TAB:
      return {
        ...state,
        globalActiveTab: action.payload
      }
    case actionTypes.COMPANY_DELETING:
      return { ...state, item: { ...state.item, mode: 'deleting' } }
    case actionTypes.COMPANY_DELETING:
      return { ...state, item: { ...state.item, mode: 'deleting' } }
    case actionTypes.COMPANY_DELETED:
      return { ...state, item: { ...state.item, mode: 'deleted' } }
    case actionTypes.COMPANY_DELETE_ERROR:
      return { ...state, item: { ...state.item, mode: undefined } }
    case actionTypes.COMPANY_TUTORIAL_POPUP:
      return { ...state, showTutorialPopup: !state.showTutorialPopup }
    case actionTypes.SHOW_USER_COMPANY_IN_PROCESS:
      return {
        ...state,
        showRequestInCompanyInProcess: action.payload.inCompanyState,
        requestCompanyName: action.payload.companyName,
        requestCompanyId: action.payload.companyId
      }

    default:
      return state
  }
}
