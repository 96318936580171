const ruWordNumeralEnding = (
  t: ComponentBaseProps['t'],
  num: number,
  stringBase: string,
  langId: number
) => {
  const lastDigit = num % 10
  const lastTwoDigits = num % 100

  if (langId == 1) {
    if ((lastTwoDigits >= 10 && lastTwoDigits <= 20) || lastDigit >= 5 || lastDigit === 0) {
      return t(`${stringBase}.5th+`)
    } else if (lastDigit == 1) {
      return t(`${stringBase}.1st`)
    } else if (lastDigit > 1 && lastDigit < 5) {
      return t(`${stringBase}.2nd-4th`)
    }
  } else {
    if (lastDigit == 1) {
      return t(`${stringBase}.single`)
    } else {
      return t(stringBase)
    }
  }
}

export default ruWordNumeralEnding
