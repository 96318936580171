import {
  MODALFORM_SHOW_REQUEST,
  MODALFORM_CLOSE_REQUEST,
  MODALFORM_REFRESH_LAYOUT,
} from './action-types'

export const showForm = (formLayout, options) => {
  return {
    type: MODALFORM_SHOW_REQUEST,
    formLayout,
    options,
  }
}

export const closeForm = () => {
  return {
    type: MODALFORM_CLOSE_REQUEST,
  }
}

export const refreshLayout = formLayout => {
  return {
    type: MODALFORM_REFRESH_LAYOUT,
    formLayout,
  }
}
