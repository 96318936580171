import { RouteChildrenProps } from 'react-router-dom'

export interface AdsTableState {
  timers: any,
  itemId: number,
  adsUserId: number,
  isFavourite: number,
  timeout: number
}
export interface AdsTableProps
  extends ComponentBaseProps,
  PermissionInjectProps,
  RouteChildrenProps,
  AdsTableBaseProps { }

export interface AdsTableBaseProps extends PaginationProps {
  ads: any[]
  refresh: (pageIndex: number) => void
  embeddable?: boolean
  openIn?: string
  loggedUserId: number
  hideCompanyName?: boolean
  dateString?: string
  fetchAds?: any
  filterKey?: any
}


interface IDelayTimer {
  readonly incrementFunc: (timerId: number, timeRemaining: number) => void
  readonly timerEndFunc: (timerId: number) => void
  readonly timers: NodeJS.Timeout[]
}

interface IDelayTimerParams {
  timerUpdate: IDelayTimer['incrementFunc']
  timerEnd: IDelayTimer['timerEndFunc']
}

// TODO: Replace it anywhere else
export class DelayTimer implements IDelayTimer {
  incrementFunc: IDelayTimer['incrementFunc']
  timerEndFunc: IDelayTimer['timerEndFunc']
  timers: NodeJS.Timeout[] = []
  constructor({ timerUpdate, timerEnd }: IDelayTimerParams) {
    this.incrementFunc = timerUpdate
    this.timerEndFunc = timerEnd
  }

  setTimer(timerId: number, updateDate: number, timeout: number) {

    let updatedTimeOut = timeout
    if (!this.timers[timerId]) {
      this.timers[timerId] = setInterval(() => {

        if (updatedTimeOut === 0) {
          clearInterval(this.timers[timerId])
          this.timerEndFunc(timerId)
        } else {
          updatedTimeOut = updatedTimeOut - 1
          this.incrementFunc(timerId, updatedTimeOut)
        }
      }, 1000 * 60)

      this.incrementFunc(timerId, timeout)
    }
    return timeout
  }

  cancelAllTimers = () => {
    this.timers.forEach(timer => {
      clearInterval(timer)
    })
  }
}
