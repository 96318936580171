export const int26 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Международная конвенция об унификации некоторых правил о коносаменте (Гаагские правила)
(Брюссель, 25 августа 1924 г.)
(с изменениями от 23 февраля 1968 г.)</h4><p></p>

<p style="font-weight:500;">Статья 1</p><br>

<p style="text-indent:2em;">См. также Протокол от 21 декабря 1979 г. об изменении Международной конвенции об 
унификации некоторых правил о коносаменте от 25 августа 1924 года, измененной 
протоколом от 23 февраля 1968 года</p>

<p style="text-indent:2em;"></p><h6>В настоящей Конвенции следующие термины употребляются в указанном ниже значении:</h6><p></p>

<p style="text-indent:2em;">а) "Перевозчик" включает собственника судна или фрахтователя, являющихся стороной 
в договоре перевозки с отправителем;</p><br>

<p style="text-indent:2em;">b) "Договор перевозки" применяется исключительно к договору перевозки, удостоверенному 
коносаментом или любым подобным ему документом, являющимся основанием для морской перевозки грузов; он применяется также к коносаменту или подобному ему документу, выданному на основании чартера, 
с того момента, когда такой коносамент или документ регулирует отношения между перевозчиком и 
держателем этого коносамента или документа;</p><br>

<p style="text-indent:2em;">с) "Грузы" включают предметы, товары, грузы и изделия любого рода, за исключением живых животных
 и груза, который объявлен по договору перевозки как перевозимый на палубе и действительно 
 перевозится таким образом;</p><br>

<p>d) "Судно" означает любое судно, используемое для морской перевозки грузов;</p><br>

<p>е) "Перевозка грузов" охватывает период с момента погрузки грузов на борт судна до их 
выгрузки с судна.</p><br>

<p style="font-weight:500;">Статья 2</p><br>

<p style="text-indent:2em;">Без ущерба для положений статьи 6, по каждому договору морской перевозки грузов перевозчик в 
отношении погрузки, обработки, укладки, перевозки, хранения грузов, заботы о них и их выгрузки 
несет обязанности и ответственность и имеет права, включая права на освобождение от ответственности, 
указанные ниже.</p><br>

<p style="font-weight:500;">Статья 3</p>

<p style="text-indent:2em;"></p><h6>1. Перевозчик обязан перед рейсом и в начале его проявить разумную заботливость 
о том, чтобы:</h6><p></p>

<p>а) привести судно в мореходное состояние;</p><br>

<p>b) надлежащим образом укомплектовать людьми, снарядить и снабдить судно;</p><br>

<p style="text-indent:2em;">с) приспособить и привести в состояние, пригодное для приема, перевозки и сохранения грузов 
трюмы рефрижераторные и холодильные помещения и все другие части судна, в которых перевозятся 
грузы.</p><br>

<p style="text-indent:2em;">2. Без ущерба для положений статьи 4, перевозчик должен надлежаще и старательно грузить, обрабатывать, 
укладывать, перевозить, хранить перевозимые грузы,, заботиться о них и выгружать их.</p>

<p style="text-indent:2em;"></p><h6>3. Получив грузы и приняв их в свое ведение, перевозчик, капитан или агент перевозки должен по 
требованию отправителя выдать отправителю коносамент, содержащий в числе прочих данных:</h6><p></p>

<p style="text-indent:2em;">а) основные марки, необходимые для идентификации груза, как они сообщены отправителем письменно 
перед тем, как погрузка такого груза началась, при условии, что эти марки нанесены штампом или ясно 
указаны иным способом на грузах, если грузы не упакованы, либо на ящиках или в упаковке, в которых 
грузы находятся, таким образом, чтобы марки оставались достаточно разборчивыми до окончания рейса;</p><br>

<p style="text-indent:2em;">b) число мест или предметов либо количество или вес, в зависимости от обстоятельств и в соответствии 
с тем, как они письменно указаны отправителем;</p><br>

<p>с) внешний вид и видимое состояние груза.</p><br>

<p style="text-indent:2em;">Однако ни перевозчик, ни капитан, ни агент перевозки не обязан объявлять или указывать в коносаменте 
марки, число мест, количество или вес, которые как он имеет серьезное основание подозревать, не точно 
соответствуют грузу, в действительности принятому им, или которые он не имеет возможности проверить 
разумными средствами.</p><br>

<p style="text-indent:2em;">4. Такой коносамент создает презумпцию, если не будет доказано иное, приема перевозчиком грузов, как 
они в нем описаны в соответствии с пунктами "а", "b" и "с" пункта 3. Однако доказывание противоположного 
не допускается, когда коносамент передан третьему добросовестному держателю.</p><br>

<p style="text-indent:2em;">5. Считается, что отправитель гарантировал перевозчику на момент погрузки точность марок, числа мест, 
количества и веса груза, как они им указаны, и отправитель обязан возместить перевозчику все потери, 
убытки и расходы, возникшие вследствие или явившиеся результатом неточности этих данных. Право перевозчика 
на такое возмещение никоим образом не ограничивает его ответственность и его обязанности по договору 
перевозки перед любым лицом, иным чем отправитель.</p><br>

<p style="text-indent:2em;">6. Если только уведомление о потерях или убытках с указанием общим образом их характера не направлено 
письменно перевозчику или его агенту в порту выгрузки до или во время выдачи грузов и их передачи на 
попечение лица, которому грузы должны быть сданы по договору перевозки, такая выдача грузов, если не 
будет доказано иное, создает презумпцию из сдачи перевозчиком в соответствии с тем, как они описаны в 
коносаменте.</p><br>

<p style="text-indent:2em;">Если потери или убытки не очевидны, уведомление должно быть направлено в течение трех дней с момента 
сдачи грузов.</p><br>

<p style="text-indent:2em;">Письменное уведомление не требуется, если состояние груза в момент его получения было установлено 
совместно.</p><br>

<p style="text-indent:2em;">С соблюдением положений пункта 6-бис, перевозчик и судно в любом случае освобождаются от всякой 
ответственности в отношении грузов, какова бы она ни была, если только иск не предъявлен в течение 
года после их сдачи или даты, когда они должны были быть сданы. Этот срок может быть, однако, продлен 
соглашением, заключенным сторонами после того, как возникло основание для иска. В случае действительных 
или предполагаемых потерь или убытков перевозчик и получатель обязаны предоставлять друг другу все разумные 
возможности для проверки груза и подсчета числа мест.</p><br>

<p style="text-indent:2em;">6-бис. Регрессный иск может быть предъявлен даже по истечении срока, предусмотренного в предыдущем 
пункте, если он предъявляется в срок, предоставленный по закону суда, рассматривающего дело. Однако 
этот срок должен быть не менее трех месяцев со дня, когда лицо, предъявляющее регрессный иск, удовлетворило 
требование или получило уведомление о возбуждении процесса по иску к нему самому.</p><br>

<p style="text-indent:2em;">7. После того как грузы погружены, коносамент, который должен быть выдан отправителю перевозчиком, 
капитаном или агентом перевозчика должен быть, если это требует отправитель, "бортовым" коносаментом, 
при условии, что отправитель, если он до этого получил какой-либо товарораспорядительный документ на 
тот же груз, возвратит этот документ на выдачу "бортового" коносамента. Однако, по усмотрению перевозчика, 
в таком товарораспорядительном документе могут быть в порту погрузки сданы перевозчиком, капитаном или 
агентом перевозчика отметки о назначении судна или судов, на которые грузы погружены, о дате или датах 
отправки, и, будучи таким образом помещенным, этот документ, если он содержит данные, указанные в пункте 
3 статьи 3, должен считаться для целей этой статьи "бортовым"" коносаментом.</p><br>

<p style="text-indent:2em;">8. Любая оговорка, условие или соглашение в договоре перевозки, освобождающее перевозчика или судно от 
ответственности за потери или убытки, касающиеся груза, возникшие вследствие небрежности, вины или неисполнения 
обязанностей и обязательств, предусмотренных в настоящей статье, или уменьшающие такую ответственность иначе, 
чем это предусмотрено настоящей Конвенцией, считаются ничтожными, недействительными и неимеющими силы. Условие 
об уступке страхового возмещения перевозчику или любое подобное условие должны рассматриваться как освобождающие 
перевозчика от ответственности.</p><br>

<p style="font-weight:500;">Статья 4</p><br>

<p style="text-indent:2em;">1. Ни перевозчик, ни судно не отвечают за потери или убытки, возникшие вследствие или явившиеся результатом 
немореходности, если только эта немореходность не вызвана отсутствием разумной заботливости со стороны перевозчика 
о том, чтобы сделать судно мореходным или обеспечить его надлежащее укомплектование экипажем, оборудованием и 
снабжением или приспособить и привести трюмы, рефрижераторные и холодильные помещения и все другие части судна, 
в которых перевозят грузы, в состояние, пригодное для приема, перевозки и хранения груза, в соответствии с 
положениями пункта 1 статьи 3. Всякий раз, когда потери или убытки возникли вследствие немореходности, бремя 
доказывания того, что касается проявления разумной заботливости, возлагается на перевозчика или на других лиц, 
требующих освобождения от ответственности, предусмотренного настоящей статьей.</p>

<p style="text-indent:2em;"></p><h6>2. Ни перевозчик, ни судно не отвечает за потери или убытки возникшие вследствие или явившиеся результатом:</h6><p></p>

<p style="text-indent:2em;">а) действий, небрежности или упущения капитана, члена экипажа, лоцмана или служащих перевозчика в судовождении
 или управлении судном;</p><br>

<p>b) пожара, если только он не возник вследствие действий или вины перевозчика;</p><br>

<p>с) рисков, опасностей или случайностей на море или в других судоходных водах;</p><br>

<p>d) непреодолимой силы;</p><br>

<p>e) военных действий;</p><br>

<p>f) действий антиобщественных элементов;</p><br>

<p>g) ареста или задержания властями, правителями или народом либо наложения судебного ареста;</p><br>

<p>h) карантинных ограничений;</p><br>

<p>i) действий или упущений отправителя или собственника грузов, его агента или представителя;</p><br>

<p style="text-indent:2em;">j) забастовок или локаутов либо приостановления или задержки работ по каким-либо причинам полностью или
 частично;</p><br>

<p>k) восстаний или народных волнений;</p><br>

<p>l) спасания или попытки спасания жизней либо имущества на море;</p><br>

<p style="text-indent:2em;">m) потери объема или веса либо всякой другой потери или повреждения, возникших из-за скрытых недостатков, 
особой природы груза или свойственных грузу дефектов;</p><br>

<p>n) недостаточность упаковки;</p><br>

<p>о) неполноты или недостатков маркировки;</p><br>

<p>р) скрытых недостатков, которые нельзя обнаружить при проявлении разумной заботливости;</p><br>

<p style="text-indent:2em;">q) всяких прочих причин, возникших не из-за действий и не по вине перевозчика и не из-за действий и не по вине 
агентов или служащих перевозчика; однако бремя доказывания возлагается на лицо, требующее такого освобождения от
 ответственности, и оно должно доказать, что ни действия или личная вина перевозчика, ни действия или 
 вина агентов или служащих перевозчика не соответствовали потерям или убыткам.</p><br>

<p style="text-indent:2em;">3. Отправитель не отвечает за понесенные перевозчиком или судном потери, убытки, возникшие вследствие 
или явившиеся результатом любой причины, если при этом не имели места действия, вина или 
небрежность отправителя, его агентов или служащих.</p><br>

<p style="text-indent:2em;">4. Никакая девиация для спасения или попытки спасти жизни либо имущество на море и никакая 
разумная девиация не считаются нарушением настоящей Конвенции или договора перевозки, и перевозчик
 не несет ответственности за любые возникшие в результате этого потери или убытки.</p><br>

<p style="text-indent:2em;">5. а) Если характер и стоимость груза не были объявлены отправителем до погрузки и внесены в 
коносамент, ни перевозчик, ни судно ни в коем случае не отвечают за любые потери или повреждения,
 причиненные грузу или связанные с ним, в сумме, превышающей 666,67 расчетных единиц за 
 место или единицу либо 2 расчетных единицы за один килограмм веса брутто утраченного 
 или поврежденного груза, в зависимости от того, какая сумма выше.</p><br>

<p style="text-indent:2em;">b) Общая сумма, подлежащая возмещению, исчисляется исходя из стоимости груза в том месте и в тот день
, где и когда он был или должен быть выгружен с судна в соответствии с договором.</p><br>

<p style="text-indent:2em;">Стоимость груза определяется по цене на товарной бирже либо, если нет такой цены, - 
по существующей рыночной цене, а если нет ни той, ни другой цены, - исходя из обычной стоимости
 грузов того же рода и качества.</p><br>

<p style="text-indent:2em;">с) Когда для объединения грузов используется контейнер, поддон или подобное транспортное 
приспособление, количество мест или единиц груза, перечисленных в коносаменте в качестве 
упакованных в такое транспортное приспособление, считается для целей настоящего пункта 
количеством мест или единиц груза. За исключением указанного случая, такое транспортное 
приспособление считается местом или единицей груза.</p><br>

<p style="text-indent:2em;">d) Расчетная единица, упомянутая в настоящей статье, является единицей "специального права 
заимствования", как она определена Международным валютным фондом. Суммы, указанные в подпункте
 "а" настоящего пункта, переводятся в национальную валюту на основе стоимости этой валюты 
 на дату, определяемую по закону суда, рассматривающего дело. Стоимость в единицах 
 "специального права заимствования" национальной валюты государства, которое является 
 членом Международного валютного фонда, исчисляется в соответствии с методом определения 
 стоимости, применяемым Международным валютным фондом на соответствующую дату для его 
 собственных операций и расчетов. Стоимость в единицах "специального права заимствования" 
 национальной валюты государства, которое не является членом Международного валютного 
 фонда, исчисляется способом, установленным этим государством.</p>

<p style="text-indent:2em;"></p><h6>Тем не менее государство, которое не является членом Международного валютного фонда и 
законодательство которого не позволяет применять положения, содержащиеся в предыдущих 
фразах, может во время ратификации Протокола 1979 г. или присоединения к нему или в 
любое время после этого заявить, что пределы ответственности, предусмотренные в 
настоящей Конвенции и применяемые на его территории, устанавливаются следующим 
образом:</h6><p></p>

<p style="text-indent:2em;">i) в отношении суммы в 666,67 расчетных единиц, указанной в подпункте "а" пункта 5 
настоящей статьи, - 10000 валютных единиц;</p><br>

<p style="text-indent:2em;">ii) в отношении суммы в 2 расчетных единицы, указанной в подпункте "а" пункта 5 
настоящей статьи, - 30 валютных единиц.</p><br>

<p style="text-indent:2em;">Валютная единица, упомянутая в предыдущей фразе, соответствует 65,5 миллиграмма 
золота 900-й пробы. Перевод сумм, указанных в этой фразе, в национальную валюту 
осуществляется согласно законодательству соответствующего государства.</p><br>

<p style="text-indent:2em;">Исчисление и перевод, упомянутые в предыдущих фразах, осуществляются таким образом, 
чтобы выразить в национальной валюте государства, насколько это возможно, ту же 
реальную ценность сумм, указанных в подпункте "а" пункта 5 настоящей статьи, какая 
выражена там в расчетных единицах.</p><br>

<p style="text-indent:2em;">Государства сообщают депозитарию способ исчисления или, в соответствующем случае, 
результат перевода во время сдачи на хранение документа о ратификации или 
присоединении и затем каждый раз, когда происходит изменение в этом способе 
исчисления или в стоимости их национальной валюты по отношению к расчетной или 
валютной единице.</p><br>

<p style="text-indent:2em;">е) Ни перевозчик, ни судно не вправе воспользоваться ограничением ответственности, 
предусмотренным в настоящем пункте, если доказано, что ущерб явился результатом 
действия или упущения перевозчика, совершенных с намерением причинить ущерб либо
 по самонадеятельности и с сознанием возможности причинения ущерба.</p><br>

<p style="text-indent:2em;">f) Объявление, упомянутое в подпункте "а" настоящего пункта, если оно внесено в
 коносамент, при отсутствии доказательств противного создает соответствующую 
 презумпцию, но не связывает перевозчика, который может его оспорить.</p><br>

<p style="text-indent:2em;">g) По соглашению между перевозчиком, капитаном или агентом перевозчика и отправителя 
могут быть установлены иные максимальные суммы, чем те, которые указаны в подпункте 
"а" настоящего пункта, при условии, что согласованная таким образом максимальная сумма
 не менее соответствующего максимума, указанного в этом подпункте.</p><br>

<p style="text-indent:2em;">h) Ни перевозчик, ни судно ни в коем случае не отвечают за утрату или повреждение, 
причиненные грузам или связанные с ними, если отправитель заведомо ложно объявил в 
коносаменте их характер или стоимость.</p><br>

<p style="text-indent:2em;">6. Грузы, легко воспламеняющиеся, взрывчатые или опасные, на погрузку которых 
перевозчик, капитан или агент перевозчика, зная об их природе или их характере, 
не согласился бы, могут быть в любое время до разгрузки выгружены с судна в любом
 месте либо уничтожены или обезврежены перевозчиком без компенсации, и отправитель
 таких грузов несет ответственность за все убытки и издержки, прямо или косвенно 
 возникшие вследствие или явившиеся результатом их погрузки. Если какой-либо из таких 
 грузов, погруженный с ведома и согласия перевозчика, станет опасным для судна или груза, 
 он может быть таким же образом выгружен с судна, уничтожен или обезврежен перевозчиком без
 ответственности со стороны перевозчика, за исключением случая общей аварии,
 если она произойдет.</p><br>

<p style="font-weight:500;">Статья 4-бис</p><br>

<p style="text-indent:2em;">1. Положения об освобождении от ответственности и ее пределах, предусмотренные настоящей
 Конвенцией, применяются к любому иску к перевозчику по поводу утраты или повреждения 
 грузов, на которые распространяются договор перевозки, независимо от того, основан иск
 на договоре или на причинении вреда.</p><br>

<p style="text-indent:2em;">2. Если такой иск предъявлен к служащему или агенту перевозчика и такой служащий или агент
 не являются независимым подрядчиком, этот служащий или агент вправе воспользоваться 
 положениями об освобождении от ответственности и ее пределах, на которые согласно 
 настоящей Конвенции вправе ссылаться перевозчик.</p><br>

<p style="text-indent:2em;">3. Суммы, возмещенные перевозчиком и такими служащими и агентами, ни в коем случае не
 должны в совокупности превышать предел, предусмотренный настоящей Конвенцией.</p><br>

<p style="text-indent:2em;">4. Однако, служащий или агент перевозчика не вправе воспользоваться положениями настоящей
 статьи, если доказано, что ущерб явился результатом действия или упущения этого служащего 
 или агента, совершенных с намерением причинить ущерб либо по самонадеянности, с сознанием 
 возможности причинения ущерба.</p><br>

<p style="font-weight:500;">Статья 5</p><br>

<p style="text-indent:2em;">Перевозчик вправе отказаться от предусмотренных настоящей Конвенцией всех или некоторых своих
 прав, и в том числе от прав на освобождение от ответственности, или увеличить свою 
 ответственность и обязательства, предусмотренные настоящей Конвенцией, при условии, 
 что такой отказ или увеличение будут включены в коносамент, выданный отправителю. 
 Никакие положения настоящей Конвенции не применяются к чартерам, но если коносаменты 
 выданы в случае предоставления судна к чартеру, они подчиняются условиям настоящей 
 Конвенции. Ничто в настоящих правилах не препятствует внесению в коносамент какого-
 либо правомерного положения, касающегося общей аварии.</p><br>

<p style="font-weight:500;">Статья 6</p><br>

<p style="text-indent:2em;">Несмотря на положения предшествующих статей, перевозчик, капитан или агент перевозчика 
и отправитель вправе вступать в отношении любых определенных грузов в любое соглашение 
на любых условиях касательно обязанностей и ответственности перевозчика на такие грузы,
 а также прав перевозчика в отношении таких грузов, включая права на освобождение от 
 ответственности, или касательно его обязательств по обеспечению мореходности судна, 
 поскольку условие об этом не противоречит публичному порядку, или касательно стараний 
 и заботливости его служащих или агентов в отношении погрузки, обработки, укладки, 
 перевозки, хранения, выгрузки перевозимых морем грузов, при условии, что в этом случае
 не был или не будет выдан никакой коносамент и что согласованные условия включены в 
 расписку, которая не является товарораспорядительным документом и содержит отметку 
 об этом.</p><br>

<p style="text-indent:2em;">Любое заключенное таким образом соглашение имеет в полной мере законную силу. Имеется
 в виду, однако, что настоящая статья применяется не к обычным коммерческим отправкам 
 грузов, осуществляемым в процессе обычной торговли, а лишь к таким отправкам, когда 
 характер и состояние имущества, подлежащего перевозке, либо обстоятельства, сроки и
 условия, при которых должна выполняться перевозка, таковы, что оправдывают особое 
 соглашение.</p><br>

<p style="font-weight:500;">Статья 7</p><br>

<p style="text-indent:2em;">Никакие положения настоящей Конвенции не возбраняют перевозчику или отправителю включать
 в договор положения, условия, оговорки и исключения в отношении обязанностей и ответственности
 перевозчика или судна за потерю или убытки, происшедшие с грузами или касающиеся их хранения,
 заботы о них и их обработки до погрузки и, соответственно, после выгрузки с судна, на котором 
 грузы перевозятся морем.</p><br>

<p style="font-weight:500;">Статья 8</p><br>

<p style="text-indent:2em;">Положения настоящей Конвенции не затрагивают ни прав, ни обязательств переводчика, 
вытекающих из любого действующего в настоящее время закона в отношении ограничения 
ответственности собственников морских судов.</p><br>

<p style="font-weight:500;">Статья 9</p><br>

<p style="text-indent:2em;">Настоящая Конвенция не наносит ущерба положениям любой международной конвенции или 
национального закона, регулирующих ответственность за ядерный ущерб.</p><br>

<p style="font-weight:500;">Статья 10</p><br>

<p style="text-indent:2em;">Положения настоящей Конвенции применяются по всякому коносаменту, относящемуся 
к перевозке грузов между портами двух разных государств, если: а) коносамент выдан 
в Договаривающемся государстве, либо b) перевозка осуществляется из порта, 
находящегося в Договаривающемся государстве, либо</p><br>

<p style="text-indent:2em;">с) договор, содержащийся в коносаменте или удостоверенный им, предусматривает, что 
правила настоящей Конвенции или вводящего их в действие законодательства какого-либо
 государства применяются к этому договору, какова бы ни была национальность судна, 
 перевозчика, отправителя, получателя или любого другого заинтересованного лица.</p><br>

<p style="text-indent:2em;">Каждое Договаривающееся государство применяет к указанным выше коносаментам положения 
настоящей Конвенции.</p><br>

<p style="text-indent:2em;">Настоящая статья не наносит ущерба праву Договаривающегося государства применять 
правила настоящей Конвенции к коносаментам, не указанным в предыдущих пунктах.</p><br>

<p style="font-weight:500;">Статья 11</p><br>

<p style="text-indent:2em;">По истечении не более двух лет, считая со дня подписания Конвенции, правительство 
Бельгии, вступив в сношения с правительствами Высоких Договаривающихся Сторон, изъявивших
 готовность ратифицировать Конвенцию, должно решить, следует ли ввести ее в действие. 
 Ратификационные грамоты будут сданы на хранение в Брюсселе в день, установленный общим
 соглашением указанных правительств. Первое депонирование ратификационных грамот будет 
 зафиксировано протоколом, подписанным представителями участвующих в нем государств и 
 министром иностранных дел Бельгии.</p><br>

<p style="text-indent:2em;">В последующем, сдача на хранение ратификационных грамот осуществляется посредством письменного
 уведомления, адресованного правительству Бельгии и сопровождаемого ратификационной грамотой.</p><br>

<p style="text-indent:2em;">Должным образом заверенные копии протокола о первом депонировании ратификационных грамот, 
уведомлений, упомянутых в предыдущем абзаце, а также сопровождающих их ратификационных грамот,
 немедленно направляются правительством Бельгии по дипломатическим каналам государствам,
 подписавшим настоящую Конвенцию или присоединившимся к ней. В случаях, предусмотренных в
 предыдущем абзаце, названное правительство одновременно сообщает дату получения им уведомления.</p><br>

<p style="font-weight:500;">Статья 12</p><br>

<p style="text-indent:2em;">Государства, не подписавшие настоящую Конвенцию, могут присоединиться к ней независимо от
 того, были ли они представлены на Международной конференции в Брюсселе.</p><br>

<p style="text-indent:2em;">Государство, желающее присоединиться к Конвенции, письменно уведомляет о своем намерении 
правительство Бельгии, препровождая ему документ о присоединении, который хранится в 
архивах указанного правительства.</p><br>

<p style="text-indent:2em;">Правительство Бельгии немедленно направляет всем государствам, подписавшим Конвенцию или 
присоединившимся к ней, должным образом заверенную копию уведомления, а также документа о
 присоединении, указывая при этом на дату получения им уведомления.</p><br>

<p style="font-weight:500;">Статья 13</p><br>

<p style="text-indent:2em;">Высокие Договаривающиеся Стороны могут во время подписания, сдачи на хранение
 ратификационных грамот или присоединения заявить, что принятие ими настоящей 
 Конвенции не относится ко всем или к некоторым анонимным доминионам, колониям, 
 заморским владениям, протекторатам или территориям, находящимся под их суверенитетом 
 или властью. Точно так же они могут впоследствии присоединиться отдельно от имени того 
 или иного из автономных доминионов, колоний, заморских владений, протекторатов или 
 территорий, исключенных их первоначальным заявлением. Они могут также денонсировать 
 настоящую Конвенцию, в соответствии с ее положениями, отдельно для одного или нескольких
 автономных доминионов, колоний, заморских владений, протекторатов или территорий, 
 находящихся под их суверенитетом или властью.</p><br>

<p style="font-weight:500;">Статья 14</p><br>

<p style="text-indent:2em;">В отношении государств, которые примут участие в первом депонировании ратификационных 
грамот, настоящая Конвенция вступит в силу через год со дня подписания протокола об этом
 депонировании.</p><br>

<p style="text-indent:2em;">Что касается государств, ратифицировавших Конвенцию позднее или присоединившихся к ней, 
а также случаев, когда Конвенция вводится в действие позднее согласно ст. 13, то Конвенция 
вступает в силу через шесть месяцев после получения правительством Бельгии уведомлений,
 предусмотренных в абзаце 2 статьи 11 и абзаце 2 статьи 12.</p><br>

<p style="font-weight:500;">Статья 15</p><br>

<p style="text-indent:2em;">В случае желания одного из Договаривающихся государств денонсировать настоящую Конвенцию
 необходимо письменно уведомить о денонсации правительство Бельгии, которое немедленно 
 препроводит всем другим государствам должным образом заверенную копию этого уведомления,
 указав при этом дату его получения.</p><br>

<p style="text-indent:2em;">Денонсация вступает в силу только в отношении государства, которое уведомлено о ней, 
и по истечение года со дня получения уведомления правительством Бельгии.</p><br>

<p style="font-weight:500;">Статья 16</p><br>

<p style="text-indent:2em;">Каждое Договаривающееся государство вправе требовать созыва новой конференции с
 целью найти улучшения, которые могут быть внесены в настоящую Конвенцию.</p><br>

<p style="text-indent:2em;">Государство, которое воспользуется этой возможность, уведомляет один год о своем
 намерении другие государства через правительство Бельгии, которое возьмет на себя
 созыв конференции.</p><br>

<p>Совершено в Брюсселе, в одном экземпляре, 25 августа 1924 г.</p><br>

<p>/подписи/</p><br>

<p style="font-weight:500;">Протокол подписания</p><br>

<p style="text-indent:2em;">При подписании Международной Конвенции об унификации некоторых правил о коносаменте
 нижеподписавшиеся полномочные представители приняли настоящий протокол, который имеет
 те же силу и значение, как если бы его положения были включены в текст Конвенции, к
 которой он относится.</p><br>

<p style="text-indent:2em;">Высокие Договаривающиеся стороны могут привести в действие настоящую Конвенцию, либо
 придав ей силу закона, либо включив в свое национальное законодательство правила,
 принятые Конвенцией, в форме, соответствующей этому законодательству.</p>

<p style="text-indent:2em;"></p><h6>Они определенно сохраняют за собой право:</h6><p></p>

<p style="text-indent:2em;">1) установить, что в случаях, предусмотренных подпунктами "с" - "р" пункта 2 статьи
 4 держатель коносамента может доказать наличие личной вины перевозчика или вины его
 служащих, не охватываемой подпунктом "а";</p><br>

<p style="text-indent:2em;">2) применять в отношении национального каботажного плавания статью 6 ко всем грузам 
без учета ограничений, установленных в последнем абзаце этой статьи.</p><br>

<p>Совершено в Брюсселе, в одном экземпляре, 25 августа 1924 г.</p><br>

<p>/подписи/</p><br>

<p>Определения используемые в чартерах терминов, относящихся к сталии, 1980 г.</p><br>

<p style="text-indent:2em;">Изданные в декабре 1980 г. совместно с Балтийской и международной морской конференцией
 (БИМКО, Копенгаген), Международным морским комитетом (ММК, Антверпен), Федерацией 
 национальных ассоциаций судовых брокеров и агентов (ФОНАСБА, Лондон) и Генеральным 
 советом британского судоходства (Лондон).</p><br>

<p style="font-weight:500;">Преамбула</p><br>

<p style="text-indent:2em;">Следующие далее определения применяются к словам и фразам, употребляемым в чартере; они
 не применяются только в тех пределах, в которых какое-либо определение или его часть 
 несовместимы с каким-либо другим ясно выраженным положением чартера. Сами слова, использованные
 в этих определениях, должны истолковываться в соответствии с определениями, данными им здесь. 
 Слова или фразы, которые представляют собой просто варианты или альтернативные формы слов или фраз,
 определенных ниже, истолковываются в соответствии с данным определением (например, "нотификация о 
 готовности судна", "нотик о готовности").</p><br>

<p style="font-weight:500;">Перечень определений</p><br>

<p>1. "Порт" (port)</p><br>

<p>2. "Безопасный порт" (safe port)</p><br>

<p>3. "Причал" (berth)</p><br>

<p>4. "Безопасный причал" (safe berth)</p><br>

<p>5. "Доступный по прибытии" или "всегда доступный" (reachable on arrival or always accessible)</p><br>

<p>6. "Сталия" (laytime)</p><br>

<p>7. "Обычная быстрота" (customary despatch)</p><br>

<p>8. "На люк в сутки" (per hatch per day)</p><br>

<p style="text-indent:2em;">9. "На рабочий люк в сутки" или "на пригодный для работы люк в сутки" (per working hatch per day
 or per workable hacth per day)</p><br>

<p>10. "Так быстро, как судно может принимать/сдавать" (as fast as the vessel can receive/deliver)</p><br>

<p>11. "Сутки" (day)</p><br>

<p>12. "Чистый день" или "чистые дни" (clear day or clear days)</p><br>

<p>13. "Праздник" (holiday)</p><br>

<p>14. "Рабочие дни" (working day)</p><br>

<p>15. "Текущие (календарные) дни" или "последовательные дни" (running days or consecutive days)</p><br>

<p>16. "Погожий рабочий день" (weather working day)</p><br>

<p>17. "Погожий рабочий день из 24 последовательных часов" (weather working day of 24 consecutive
 hours)</p><br>

<p style="font-weight:500;">Протокол об изменении Международной конвенции об унификации некоторых правил о коносаменте от 25 
августа 1924 года, измененной протоколом от 23 февраля 1968 года *</p><br>

<p>Протокол</p><br>

<p>21 декабря 1979 г.</p><br>

<p style="text-indent:2em;">* Россия присоединилась к настоящему Протоколу на основании Федерального закона от 06 января 1999 г.
 N 17-ФЗ "О присоединении Российской Федерации к Протоколу об изменении Международной конвенции об 
 унификации некоторых правил о коносаменте от 25 августа 1924 года, измененной Протоколом от 23 февраля
 1968 года"</p>

<p style="text-indent:2em;"></p><h6>Договаривающиеся Стороны настоящего Протокола,являясь сторонами Международной конвенции об унификации некоторых правил о коносаменте, принятой в
 Брюсселе 25 августа 1924 года, с поправками, внесенными Протоколом о ее изменении, принятым в Брюсселе
 23 февраля 1968 года,согласились о нижеследующем:</h6><p></p>

<p style="font-weight:500;">Статья I</p><br>

<p style="text-indent:2em;">Для целей настоящего Протокола "Конвенция" означает Международную конвенцию об унификации некоторых
 правил о коносаменте и Протокол ее подписания, принятые в Брюсселе 25 августа 1924 года, с поправками
 к ней, содержащимися в Протоколе, принятом в Брюсселе 23 февраля 1968 года.</p><br>

<p style="font-weight:500;">Статья II</p>

<p style="text-indent:2em;"></p><h6>1. В статье 4 Конвенции подпункта "а" пункта 5 заменяется следующим текстом:</h6><p></p>

<p style="text-indent:2em;">"5. а) Если характер и стоимость груза не были объявлены отправителем до погрузки и внесены в коносамент,
 ни перевозчик, ни судно ни в коем случае не отвечают за любые потери или повреждения, причиненные грузу
 или связанные с ним, в сумме, превышающей 666,67 расчетных единиц за место или единицу либо 2 расчетных
 единицы за один килограмм веса брутто утраченного или поврежденного груза, в зависимости от того, какая
 сумма выше.</p>

<p style="text-indent:2em;"></p><h6>2. В статье 4 Конвенции подпункт "d" пункта 5 заменяется следующим текстом:</h6><p></p>

<p style="text-indent:2em;">d) Расчетная единица, упомянутая в настоящей статье, является единицей "специального права заимствования",
 как она определена Международным валютным фондом. Суммы, указанные в подпункте "а" настоящего пункта,
 переводятся в национальную валюту на основе стоимости этой валюты на дату, определяемую по закону суда,
 рассматривающего дело. Стоимость в единицах "специального права заимствования" национальной валюты государства,
 которое является членом Международного валютного фонда, исчисляется в соответствии с методом
 определения стоимости, применяемым Международным валютным фондом на соответствующую дату для его собственных
 операций и расчетов. Стоимость в единицах "специального права заимствования" национальной валюты
 государства, которое не является членом Международного валютного фонда, исчисляется способом, установленным
 этим государством.</p>

<p style="text-indent:2em;"></p><h6>Тем не менее государство, которое не является членом Международного валютного фонда и законодательство которого
 не позволяет применять положения, содержащиеся в предыдущих фразах, может во время ратификации Протокола
 1979 г. или присоединения к нему или в любое время после этого заявить, что пределы ответственности,
 предусмотренные в настоящей Конвенции и применяемые на его территории, устанавливаются следующим
 образом:</h6><p></p>

<p style="text-indent:2em;">i) в отношении суммы в 666,67 расчетных единиц, указанных в подпункте "а" пункта 5 настоящей
 статьи - 10000 валютных единиц;</p><br>

<p style="text-indent:2em;">ii) в отношении суммы в 2 расчетных единицы, указанной в подпункте "а" пункта 5 настоящей
 статьи - 30 валютных единиц.</p><br>

<p style="text-indent:2em;">Валютная единица, упомянутая в предыдущей фразе, соответствует 65,5 миллиграмма золота
 900-пробы. Перевод сумм, указанных в этой фразе, в национальную валюту осуществляется
 согласно законодательству соответствующего государства.</p><br>

<p style="text-indent:2em;">Исчисления и перевод, упомянутые в предыдущих фразах, осуществляются таким образом, чтобы
 выразить в национальной валюте государства, насколько это возможно, ту же реальную ценность
 сумм, указанных в подпункте "а" пункта 5 настоящей статьи, какая выражена там в расчетных
 единицах.</p><br>

<p style="text-indent:2em;">Государства сообщают депозитарию способ исчисления или, в соответствующем случае, результат перевода
 во время сдачи на хранение документа о ратификации или присоединении и затем каждый раз, когда
 происходит изменение в этом способе исчисления или в стоимости их национальной валюты по
 отношению к расчетной или валютной единице.</p><br>

<p style="font-weight:500;">Статья III</p><br>

<p style="text-indent:2em;">Любой спор между Договаривающимися Сторонами касающейся толкования или применения настоящего
 Протокола, который не может быть урегулирован путем переговоров, передается по просьбе 
 одной из них на рассмотрение арбитража. Если в течение шести месяцев со дня
 просьбы об арбитражном разбирательстве Стороны не в состоянии договориться
 об организации арбитража, любая из этих Сторон может передать спор в 
 Международный Суд, направив просьбу об этом в соответствии 
 со Статутом Суда.</p><br>

<p style="font-weight:500;">Статья IV</p><br>

<p style="text-indent:2em;">1. Каждая Договаривающаяся Сторона может во время подписания или ратификации
 настоящего Протокола или присоединения к нему заявить, что она 
 не считает себя связанной статьей III.</p><br>

<p style="text-indent:2em;">2. Любая Договаривающаяся Сторона, сделавшая оговорку в соответствии 
с пунктом 1, может в любое время снять эту оговорку посредством уведомления, 
направленного правительству Бельгии.</p><br>

<p style="font-weight:500;">Статья V</p><br>

<p style="text-indent:2em;">Настоящий Протокол открыт для подписания государствами, которые подписали Конвенцию 
от 25 августа 1924 года или Протокол от 23 февраля 1968 
года либо являются Сторонами Конвенции.</p><br>

<p style="font-weight:500;">Статья VI</p><br>

<p>1. Настоящий Протокол подлежит ратификации.</p><br>

<p style="text-indent:2em;">2. Ратификация настоящего Протокола государством, которое не является 
Стороной Конвенции, равносильна ратификации Конвенции.</p><br>

<p>3. Ратификационные грамоты сдаются на хранение правительству 
Бельгии.</p><br>

<p style="font-weight:500;">Статья VII</p><br>

<p>1. Государства, не указанные в статье V, могут присоединиться 
к настоящему Протоколу.</p><br>

<p>2. Присоединение к настоящему Протоколу равносильно 
присоединению к Конвенции.</p><br>

<p>3. Документы о присоединении сдаются на хранение 
правительству Бельгии.</p><br>

<p style="font-weight:500;">Статья VIII</p><br>

<p style="text-indent:2em;">1. Настоящий Протокол вступает в силу по истечении трех месяцев со дня 
сдачи на хранение пяти документов о ратификации или присоединении.</p><br>

<p style="text-indent:2em;">2. Для каждого государства, которое ратифицирует настоящий Протокол или 
присоединяется к нему после сдачи на хранение пятого документа о 
ратификации или присоединении, настоящий Протокол вступит в силу 
через три месяца после сдачи им на хранение такого документа.</p><br>

<p style="font-weight:500;">Статья IX</p><br>

<p style="text-indent:2em;">1. Каждая Договаривающаяся Сторона может денонсировать настоящий 
Протокол посредством уведомления, направленного правительству
 Бельгии.</p><br>

<p>2. Денонсация вступает в силу через один год со дня получения 
уведомления правительством Бельгии.</p><br>

<p style="font-weight:500;">Статья X</p><br>

<p style="text-indent:2em;">1. Каждое государство при подписании Протокола, его ратификации 
или присоединении к нему или в любое время после этого может 
путем письменного уведомления правительства Бельгии 
объявить, к каким из территорий, за международные 
отношения которых оно ответственно, применяется настоящий Протокол.
 Протокол распространяется на названные в таком уведомлении территории 
 через три месяца со дня его получения правительством Бельгии, но не ранее 
 дня вступления в силу Протокола в отношении данного государства.</p><br>

<p>2. Указанное распространение относится также к Конвенции, если она еще 
не применяется к таким территориям.</p><br>

<p style="text-indent:2em;">3. Любая Договаривающаяся Сторона, которая сделала заявление на основании
 пункта 1 настоящей статьи, может в любое время после этого объявить путем
 уведомления, направленного правительству Бельгии, что Протокол перестает
 распространяться на такие территории. Эта денонсация вступает в силу через
 один год со дня получения уведомления правительством Бельгии.</p><br>

<p style="font-weight:500;">Статья XI</p>

<p style="text-indent:2em;"></p><h6>Правительство Бельгии уведомляет государства, подписавшие Протокол и
 присоединившиеся к нему:</h6><p></p>

<p>1) о подписании, ратификации и присоединении, имевших место в
 соответствии со статьями V, VI и VII;</p><br>

<p>2) о дате вступления настоящего Протокола в силу в соответствии
 со статьей VIII;</p><br>

<p>3) об уведомлениях по поводу территориальной сферы применения в 
соответствии со статьей X;</p><br>

<p>4) о заявлениях и сообщениях, сделанных в соответствии со
 статьей II;</p><br>

<p>5) о заявлениях, сделанных в соответствии со статьей IV;</p><br>

<p>6) об уведомлениях о денонсации, полученных в соответствии 
со статьей IX.</p><br>

<p><a href="http://docs.cntd.ru/document/1900173">Источник</a></p>

</div>
`