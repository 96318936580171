import React from 'react'
import dict from '../../../shared/dictionaries'
import "./styles.scss"

export default class CompanyInfo extends React.Component {

  render() {
    const {
      companyInfo:{
        companyName,
        countryName,
        yearCreated,
        ownershipTypeId,
        ownerUsername,
        ownerLastName,
        adminUserName,
        adminUserLastName,
        fiscalCode,
      },
      langId,
      t
    } = this.props

    const ownershipTypesDict = dict.ownershipTypes(langId)
    const ownership = ownershipTypesDict.items ? ownershipTypesDict.items.find(o => o.value === Number(ownershipTypeId)) : null 
    const ownType = ownership?.name

    return (
      <div className="panel-tab">
        <div className="panel-content">
          <div className="panel-content-table">
            <table className="table table-striped-main table-company-info table-centered table-link table-lg table-responsive">
              <tr>
                <td>
                  <span>{t('verification.company.name')}</span>
                </td>
                <td>{companyName}</td>
              </tr>
              <tr>
                <td>
                  <span>{t('verification.company.country')}</span>
                </td>
                <td>{countryName}</td>
              </tr>
              <tr>
                <td>
                  <span>{t('verification.company.year')}</span>
                </td>
                <td>{yearCreated}</td>
              </tr>
              <tr>
                <td>
                  <span>{t('verification.company.form')}</span>
                </td>
                <td>{ownType}</td>
              </tr>
              <tr>
                <td>
                  <span>{t('verification.company.fisc.code')}</span>
                </td>
                <td>{fiscalCode}</td>
              </tr>
              <tr >
                <td>
                  <span>{t('verification.company.director')}</span>
                </td>
                <td>{adminUserName} {adminUserLastName}</td>
              </tr>
              <tr className="tr-rounded-up">
                <td>
                  <span>{t('verification.company.admin')}</span>
                </td>
                <td>{ownerUsername} { ownerLastName}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    )
  }
}
