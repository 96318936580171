import React, { Component } from 'react'

export default class RoFaq extends Component {
    render() {

        return (
            <div>
                <div className="panel-content-row">
                    <h5>Ce este Transport.md?</h5>
                    <p className="text-indent">
                        <strong>"Transport.md"</strong> este cel mai vechi sistem informațional pentru
                        transportul internațional de mărfuri din sud-estul Europei, existent din 1999.
                        Sistemul permite schimbul de informații între proprietarii de mărfuri și companiile
                        de transport în timp real. Ajută pe unii să găsească mijloacele de transport pentru
                        a-și expedia mărfurile, iar pe alții - să găsească mărfuri pentru mijloacele lor de
                        transport disponibile. Furnizează știri de pe piața transporturilor de mărfuri,
                        informații despre participanții de pe piață,  oferă oportunitatea de a fi un
                        participant activ în această piață, folosind diverse servicii utile. Sistemul
                        permite utilizatorilor să lucreze pe platformă{' '}
                        <strong>contra plată sau fără plată și</strong>{' '}
                        <strong>chiar nefiind înregistrați!</strong> Utilizatorul decide singur în ce statut
                        să lucreze.
                    </p>
                    <br />
                    <p className="text-indent">
                        După ce v-ați familiarizat cu informațiile prezentate în această secțiune, vă
                        recomandăm să vizitați paginile
                        <strong> "Termenii de utilizare ai site-ului"</strong>,{' '}
                        <strong>"Politica de confidențialitate"</strong> și{' '}
                        <strong>"Oportunitățile pentru utilizatori și prețuri"</strong>, pe care le puteți
                        găsi în meniul de sus sau de jos al site-ului. Acest lucru vă va ajuta să înțelegeți
                        mai bine regulile sistemului și să decideți care este statutul potrivit pentru a
                        lucra pe acest site cu succes.
                    </p>
                    <h5>De ce să mă înregistrez? </h5>
                    <p className="text-indent">
                        &nbsp; Sistemul permite utilizarea sa și fără înregistrare în calitate de{' '}
                        <strong>"Vizitator"</strong>. În acest caz, utilizatorul poate vedea doar o parte
                        din informații. Unele informații sunt disponibile nu imediat, ci cu o întârziere. El
                        nu are posibilitatea de a posta informațiile proprii pe site.
                    </p>
                    <br />
                    <p className="text-indent">
                        {' '}
                        Parcurgând o procedură simplă în secțiunea <strong>"ÎNREGISTRARE"</strong>,
                        participantul devine un utilizator înregistrat, ceea ce îi permite să posteze în
                        sistem oferte referitoare la mărfuri și/sau transport disponibil, să publice
                        anunțuri, să participe la forum și să aibă acces la multe alte opțiuni utile ale
                        site-ului. De asemenea un utilizator înregistrat are posibilitatea fie să se alăture
                        unei companii alese, fie să își creeze propria companie și să invite alți colegi în
                        ea. Înregistrarea pe site este <strong>gratuită</strong>. &nbsp;
                    </p>
                    <h5>Cum să mă înregistrezi pe site? Ce parolă să aleg?</h5>
                    <p className="text-indent">
                        &nbsp; Un utilizator neînregistrat poate găsi secțiunea "
                        <strong>Înregistrare</strong>" în colțul din dreapta sus al site-ului. Înregistrarea
                        în două etape pe site începe cu completarea formularului primar de înregistrare
                        (numele de utilizator, parolă, adresă de e-mail). Se recomandă alegerea unei parole
                        complexe: un cuvânt care nu există, cu caractere speciale, cifre, litere mari și
                        mici - o astfel de parolă va fi greu de spart. Este necesar să completați toate
                        câmpurile obligatorii. Acestea sunt marcate cu o steluță roșie. &nbsp;
                    </p>
                    <br />
                    <p className="text-indent">
                        În timpul completării formularului de înregistrare utilizatorul trebuie să fie atent
                        la instrucțiunile care însoțesc fiecare câmp și să le urmeze. Nerespectarea
                        indicațiilor poate împiedica finalizarea cu succes a procesului de înregistrare.
                        Este important de menționat că{' '}
                        <strong>
                            numele de utilizator, parola și adresa de e-mail trebuie să fie unice
                        </strong>{' '}
                        și nu pot coincide cu date similare ale altor utilizatori din înregistrările
                        existente. Este obligatoriu să furnizați o adresă de e-mail reală. Fără aceasta,
                        înregistrarea reușită pe site este imposibilă.
                    </p>
                    <br />
                    <p className="text-indent">
                        În partea de jos a formularului, utilizatorul trebuie să citească și să confirme
                        acordul său cu <strong>"Termenii de utilizare ai site-ului"</strong> și{' '}
                        <strong>"Politica de confidențialitate"</strong>. Aceasta este o acțiune
                        obligatorie.
                    </p>
                    <br />
                    <p className="text-indent">
                        După apăsarea butonului <strong>"Înregistrează-te"</strong>, utilizatorul va primi
                        instrucțiuni pe adresa sa de e-mail, pe care trebuie să le urmeze pentru a finaliza
                        procedura de înregistrare în etapa următoare. În timpul acestui pas, utilizatorul
                        trebuie să completeze toate câmpurile obligatorii marcate cu o steluță roșie.
                    </p>
                    <br />
                    <p className="text-indent">
                        După finalizarea cu succes a procedurii de înregistrare, participantul devine un
                        utilizator înregistrat ca <strong>persoană fizică</strong>. El va primi un număr
                        personal în formatul <strong>GTU XXXXX</strong>.  Doar după aceasta va putea adăuga
                        informațiile sale în diferitele secțiuni ale site-ului, să se alăture companiei
                        alese sau să-și creeze propria companie și, dacă dorește, să invite alți colegi
                        dintre utilizatorii înregistrați pe site.
                    </p>
                    <br />
                    <p className="text-indent">
                        <strong>Notă:</strong> dacă ați fost utilizator înregistrat în versiunea anterioară
                        a site-ului, apăsați butonul <strong>"INTRĂ"</strong>. Autentificați-vă cu numele de
                        utilizator și parola dvs., apoi urmați procedura de reînregistrare pentru a continua
                        lucrul în noua versiune a site-ului cu contul dvs. existent, păstrând toate
                        informațiile pe care le-ați postat anterior pe site. Trebuie să aveți în vedere că
                        procedura de reînregistrare va fi limitată în timp.
                    </p>
                    <h5>Am uitat parola pentru a accesa site-ul. Ce să fac?</h5>
                    <p className="text-indent">
                        &nbsp; Dacă ați uitat parola dvs. de înregistrare, apăsați butonul{' '}
                        <strong>"INTRĂ"</strong>. Apoi apăsați <strong>"Ai uitat parola?"</strong>. Se va
                        deschide un formular <strong>"RECUPERAREA PAROLEI UITATE"</strong>. Introduceți
                        adresa dvs. de e-mail, pe care ați furnizat-o la înregistrare și apăsați{' '}
                        <strong>"Trimite"</strong>. Veți primi un e-mail de la{' '}
                        <a href="mailto:support@transport.md">
                            <strong>support@transport.md</strong>
                        </a>{' '}
                        la adresa respectivă. Urmați instrucțiunile din acel mesaj. &nbsp;
                    </p>
                    <h5>Cum să editez datele de înregistrare?</h5>
                    <strong> </strong>
                    <p className="text-indent">
                        După ce un utilizator devine înregistrat pe site, el poate să editeze setările
                        înregistrării sale, inclusiv și câmpul <strong>"Parolă"</strong>.  Pentru aceasta,
                        în profilul personal în colțul din dreapta sus al site-ului apăsați butonul
                        <strong> "Editare".</strong> În formularul <strong>„EDITARE PROFIL”</strong> care
                        apare, puteți edita câmpurile care necesită modificări și apoi să apăsați{' '}
                        <strong>"Salvează"</strong>.  Trebuie să aveți în vedere că utilizatorul nu va putea
                        niciodată să modifice câmpul <strong>"Utilizator"</strong>.
                    </p>
                    <p className="text-indent">
                        <strong>Notă:</strong> Dacă ați trecut printr-un proces de verificare alături de
                        compania dvs. (sau aderați la o companie deja verificată), atât în timpul perioadei
                        de valabilitate a verificării cât și după expirarea acesteia, suspendarea sau
                        retragerea acesteia de către administrația site-ului, precum și după o posibilă
                        ieșire a utilizatorului din companie, un utilizator înregistrat nu va putea
                        niciodată să schimbe (cu excepția cazurilor confirmate documentar){' '}
                        <strong>numele și prenumele</strong> în setările de înregistrare. Confirmarea
                        documentară înseamnă prezentarea unui scan (în unele cazuri și a originalului) al
                        documentului care confirmă schimbarea numelui sau prenumelui, de exemplu, în cazul
                        căsătoriei. Pentru mai multe informații despre ce înseamnă verificarea unei
                        companii, puteți consulta secțiunea <strong>"Verificarea companiei"</strong> din
                        meniul de sus sau de jos al site-ului.
                    </p>
                    <h5>Cum poate un utilizator înregistrat să adere la compania aleasă pe site?</h5>
                    <p className="text-indent">
                        &nbsp; Un utilizator nu poate trimite o cerere de aderare la o companie dacă este
                        deja angajat într-o altă companie. De asemenea, un utilizator nu poate trimite
                        cereri de aderare la două sau mai multe companii simultan.
                    </p>
                    <br />
                    <p className="text-indent">
                        Pentru a adera la compania aleasă, utilizatorul trebuie să acceseze butonul{' '}
                        <strong>"ADERĂ LA  COMPANIE"</strong> din profilul său personal. Sistemul îl va
                        direcționa către secțiunea <strong>"Companii și utilizatori"</strong>. În fereastra
                        de căutare din subsecțiunea <strong>"Toate companiile"</strong>, utilizatorul
                        trebuie să introducă numele companiei la care dorește să adere și să apese pe
                        pictograma <strong>"Căutare"</strong>. În lista rezultatelor căutării, utilizatorul
                        trebuie să găsească compania potrivită și să deschidă profilul acesteia. În profilul
                        companiei, trebuie să apese pe <strong>"Aderă la companie"</strong>. Cererea de
                        aderare va fi trimisă imediat administratorului profilului companiei. În profilul
                        personal al utilizatorului, va apărea mențiunea:{' '}
                        <strong>
                            "Cererea de aderare la compania (numele companiei) a fost trimisă pentru a fi
                            examinată de către Administratorul companiei"
                        </strong>
                        . În cazul în care utilizatorul decide să anuleze cererea sa de aderare la companie,
                        trebuie să apese pe inscripția <strong>"Anulare cerere"</strong>. Cererea va fi
                        anulată. Administratorul companiei poate să respingă sau să accepte cererea
                        utilizatorului.
                    </p>
                    <br />
                    <p className="text-indent">
                        Pentru a accepta un utilizator în companie sau pentru a respinge cererea,
                        administratorul companiei trebuie să apese butonul{' '}
                        <strong>"ADAUGĂ / EDITEAZĂ ANGAJAT"</strong>. Apoi, în formularul{' '}
                        <strong>"CERERI DE ADERARE LA COMPANIE"</strong>, trebuie să selecteze utilizatorul
                        pe care dorește să-l accepte în companie și să apese pe pictograma{' '}
                        <strong>"Acceptă"</strong>. După finalizarea cu succes a procedurii de acceptare,
                        utilizatorul devine angajatul companiei. Administratorul companiei trebuie să apese
                        pe pictograma <strong>"Refuză"</strong> dacă dorește să respingă cererea de aderare
                        a utilizatorului în companie.
                    </p>
                    <br />
                    <p className="text-indent">
                        Administratorul companiei are doar un anumit interval de timp pentru a lua decizia
                        de a accepta sau respinge cererea de aderare a utilizatorului în companie, după
                        expirarea căruia cererea este considerată anulată.
                    </p>
                    <h5>Cum poate un angajat al unei companii să o părăsească?</h5>
                    <p className="text-indent">
                        &nbsp; Un angajat al companiei care dorește să o părăsească trebuie să se adreseze
                        cu această solicitare administratorului profilului companiei.  Pentru a exclude un
                        utilizator din rândul angajaților companiei, administratorul companiei trebuie să
                        acceseze butonul <strong>"ADAUGĂ / EDITEAZĂ ANGAJAT"</strong>  din profilul său
                        personal. Apoi, în secțiunea <strong>"SETĂRILE DE ACCES ALE ANGAJAȚILOR"</strong>,
                        selectează angajatul pe care dorește  să-l excludă și apăsă pe pictograma{' '}
                        <strong>"Editare"</strong>.  Ulterior, în formularul{' '}
                        <strong>"EDITEAZĂ ANGAJATUL"</strong> care se deschide, trebuie să apese pe butonul{' '}
                        <strong>"Șterge angajatul"</strong>. Imediat după aceasta, utilizatorul nu va mai fi
                        angajat al companiei și, dacă dorește, poate deveni angajat al unei alte companii
                        sau poate crea propria companie.
                    </p>
                    <br />
                    <p className="text-indent">
                        Dacă în timpul angajării la companie, administratorul companiei a înlocuit datele de
                        contact ale angajatului cu datele corporative, după ce angajatul părăsește compania,
                        datele sale de contact originale, pe care le-a furnizat înainte de a adera la
                        companie, vor fi restabilite în profilul său personal.
                    </p>
                    <br />
                    <p className="text-indent">
                        În cazul în care apar neînțelegeri între angajat și administratorul companiei cu
                        privire la părăsirea companiei, oricare dintre părți poate să se adreseze
                        Administrației Site-ului pentru a le rezolva. După analizarea argumentelor
                        prezentate de ambele părți, Administrația Site-ului are dreptul să excludă
                        utilizatorul din lista angajaților companiei pe cont propriu.
                    </p>
                    <h5>Cum să creez propria companie pe site?</h5>
                    <p className="text-indent">
                        &nbsp; Un utilizator nu poate crea propria companie în timp ce este angajat la o
                        altă companie. De asemenea, el nu poate crea propria companie dacă a  trimis o
                        cerere de aderare la o altă companie și această cerere este în curs de examinare de
                        către administratorul acelei companii.
                    </p>
                    <br />
                    <p className="text-indent">
                        Pentru a crea propria companie, un utilizator înregistrat trebuie să apese butonul{' '}
                        <strong>"CREAZĂ COMPANIE"</strong> din profilul său personal. Apoi, trebuie
                        completate câmpurile propuse în secțiunea respectivă, respectând cu exactitate
                        indicațiile oferite pentru fiecare câmp sau alegând din listele propuse. Este
                        esențial să completați toate câmpurile obligatorii. Acestea sunt marcate cu o stea
                        roșie.
                    </p>
                    <br />
                    <p className="text-indent">
                        După ce ați completat corect formularul, apasați butonul <strong>"Salvează"</strong>
                        . După finalizarea cu succes a procedurii, compania va fi creată. Aceasta va primi
                        un număr personal de format <strong>GTСYYYYY</strong>. Utilizatorul care a creat
                        compania va primi statutul de{' '}
                        <strong>administrator al profilului companiei </strong>
                        (administratorul companiei).{' '}
                        <strong>Într-o companie poate exista doar un singur administrator. </strong>
                        Administratorul poate transfera acest statut oricărui alt angajat activ al
                        companiei. Acest statut permite administratorului să recruteze (sau să concedieze)
                        angajați în companie, să modifice anumite date de înregistrare ale angajaților
                        existenți, să inițieze procedura de verificare a companiei, să editeze datele
                        companiei, să scrie opinii și să depună plângeri împotriva altor companii pentru a
                        le adăuga în <strong>"Lista neagră a companiilor"</strong>, să adauge informații despre vehiculele
                        companiei în secțiunea <strong>"Parcul auto al companiei"</strong> și să închidă
                        compania.
                    </p>
                    <br />
                    <h5>Cum să editez parametrii înregistrării companiei mele?</h5>
                    <p className="text-indent">
                        &nbsp; Pentru a edita parametrii înregistrării companiei, administratorul companiei
                        trebuie să acceseze butonul <strong>"EDITARE COMPANIE"</strong> din profilul său
                        personal. Ulterior, trebuie să efectueze modificările în câmpurile care necesită
                        editare și să apese butonul <strong>"Salvează"</strong>.
                    </p>
                    <br />
                    <p className="text-indent">
                        <strong>Notă:</strong> Administratorul unei companii verificate, atât în perioada de
                        valabilitate a verificării, cât și după expirarea acesteia, suspendarea sau
                        revocarea de către administrarea site-ului, nu va putea niciodată să modifice (cu
                        excepția cazurilor confirmate documentar) următorii parametri ai înregistrării
                        companiei:{' '}
                        <strong>
                            denumirea, țara de înregistrare, anul înființării, forma de proprietate, codul
                            fiscal, scanările documentelor de fondatre ale companiei.
                        </strong>{' '}
                        Prin confirmare documentară se înțelege furnizarea unei scanări (în unele cazuri și
                        a originalului) a documentului care confirmă astfel de modificări.
                    </p>
                    <br />
                    <h5>
                        Înlocuirea datelor de înregistrare ale angajaților companiei cu date corporative de
                        către administratorul companiei.
                    </h5>
                    <p className="text-indent">
                        Administratorul companiei poate gestiona datele de înregistrare ale angajaților săi,
                        le poate atribui  funcții în cadrul companiei. Pe durata aderării lor la companie,
                        administratorul poate, de asemenea, să înlocuiască datele de contact ale angajaților
                        cu cele corporative Atâta timp cât un angajat face parte din companie, doar datele
                        indicate de administrator vor fi vizibile celorlalți utilizatori ai site-ului. Dacă
                        un angajat părăsește compania, utilizatorii vor vedea din nou în profilul său
                        personal datele specificate la înregistrare înainte de a se alătura companiei.
                    </p>
                    <br />
                    <p className="text-indent">
                        Pentru a înlocui datele de contact ale unui angajat cu cele corporative,
                        administratorul companiei trebuie să apese butonul „
                        <strong>ADĂUGA / EDITEAZĂ ANGAJAT</strong>” din profilul său personal. Apoi, în
                        secțiunea „<strong>SETĂRILE DE ACCES ALE ANGAJAȚILOR</strong>”, selectează
                        angajatul, al cărui contact dorește să îl modifice și apasă pe pictograma „
                        <strong>Editare</strong>”. După aceea, trebuie să modifice acele câmpuri care
                        necesită editare și să apese butonul „<strong>Salvează</strong>”.
                    </p>
                    <br />
                    <h5>
                        Cum poate administratorul companiei să transfere statutul de administrator unui alt
                        angajat al companiei?
                    </h5>
                    <p className="text-indent">
                        După cum s-a menționat mai sus, în profilul companiei (administratorului companiei)
                        poate exista doar un singur angajat ca administrator. . Administratorul companiei
                        poate transfera statutul de administrator către oricare alt angajat activ al
                        companiei.  Pentru a face acest lucru, administratorul companiei trebuie să apese
                        butonul <strong>"EDITARE COMPANIE" </strong>din profilul său personal. . În câmpul{' '}
                        <strong>"Director / Administrator al profilului companiei"</strong>, din lista
                        derulantă cu toți angajații companiei, trebuie să selecteze angajatul căruia dorește
                        să îi transfere statutul de administrator. De asemenea, s-ar putea să fie necesară o
                        modificare și în câmpul <strong>„Funcția în companie”</strong>. După aceea, trebuie
                        să apese butonul <strong>"Salvează"</strong>.
                    </p>
                    <br />
                    <h5>Cum să închid o companie pe site? Ce înseamnă închiderea unei companii?</h5>
                    <p className="text-indent">
                        &nbsp; Pentru a închide o companie pe site, administratorul companiei trebuie să
                        acceseze butonul <strong>"EDITARE COMPANIE"</strong> din profilul său personal și
                        apoi să apese butonul <strong>"Închide compania"</strong> din formularul care se
                        deschide.
                    </p>
                    <br />
                    <p className="text-indent">
                        Compania nu va fi ștearsă, dar i se va atribui statutul de{' '}
                        <strong>"Inactivă"</strong>. Toți angajații companiei vor fi transferați în
                        categoria foștilor angajați. Toate ofertele companiei vor fi dezactivate.
                        Verificarea companiei va fi anulată.
                    </p>
                    <br />
                    <p className="text-indent">
                        Este posibil să se restaureze înregistrarea unei companii pe site dacă aceasta
                        decide să-și reia activitatea?  Da, ultimul administrator al companiei va putea să
                        restaureze înregistrarea companiei dacă nu este angajat în alta. Verificarea va
                        trebui să fie reluată.
                    </p>
                    <br />
                    <p className="text-indent">
                        Cu privire la procedura de restaurare a înregistrării companiei, este necesar să
                        contactați Administrația Site-ului folosind datele lor de contact, pe care le puteți
                        găsi pe pagina principală a site-ului.
                    </p>
                    <br />
                    <h5>
                        Ce este verificarea unei companii? Cum poate o companie să treacă prin procesul de
                        verificare?
                    </h5>
                    <p className="text-indent">
                        &nbsp; Verificarea este un proces voluntar de confirmare a faptului că o companie și
                        angajații săi sunt participanți reali pe piața transportului de mărfuri. În timpul
                        verificării, sunt examinate documentele fondatoare, licențele, codul fiscal, datele
                        de baza ale angajaților companiei și alte informații relevante.
                    </p>
                    <br />
                    <p className="text-indent">
                        Dorința de a fi verificată poate indica onestitatea și transparența companiei față
                        de ceilalți participanți pe piața transportului de mărfuri. Având în vedere că
                        utilizatorii și companiile care lucrează pe site încheie relații contractuale și își
                        asumă o responsabilitate juridică și financiară semnificativă față de partenerii
                        săi, prezența unei verificări sporește încrederea celorlalți participanți de pe site
                        în companie. Pentru mai multe detalii despre ce înseamnă verificarea unei companii
                        puteți consulta secțiunea <strong>"Verificarea companiei"</strong> din meniul de sus
                        sau de jos al site-ului.
                    </p>
                    <br />
                    <p className="text-indent">
                        Procesul de verificare este inițiat de către administratorul profilului companiei
                        (administratorul companiei) de pe site,{' '}
                        <strong>
                            care își asumă responsabilitatea pentru veridicitatea datelor referitoare la
                            companie și angajații săi
                        </strong>
                        . Procedura de verificare poate fi inițiată de administrator prin accesarea
                        secțiunii <strong>"TRECEȚI PRIN VERIFICARE"</strong> din profilul său personal. După
                        ce s-a familiarizat cu descrierea și instrucțiunile pentru efectuarea verificării
                        din formularul deschis, administratorul poate începe completarea formularului de
                        cerere pentru verificare. După trimiterea cererii și confirmarea datelor prezentate
                        de către administrația  site-ului, companiei i se atribuie statutul de{' '}
                        <strong>"companie verificată"</strong>. Administrația site-ului are dreptul să
                        refuze atribuirea statutului de <strong>"companie verificată"</strong> în cazul în
                        care nu sunt furnizate toate informațiile necesare sau există îndoieli cu privire la
                        veridicitatea acestora.
                    </p>
                    <br />
                    <h5>
                        Cum pot găsi pe site utilizatorul sau compania înregistrată și cum pot accesa "Lista
                        neagră a companiilor"?
                    </h5>
                    <p className="text-indent">
                        &nbsp; Doar utilizatorii înregistrați pe site au acces complet la secțiunea{' '}
                        <strong>"Companii și utilizatori"</strong>, care include subsecțiunile{' '}
                        <strong>"Toate companiile"</strong>, <strong>"Toți utilizatorii"</strong> și{' '}
                        <strong>"Lista neagră a companiilor"</strong>. Utilizatorii neînregistrați au doar
                        acces parțial la subsecțiunile menționate anterior. Cu toate acestea, subsecțiunile{' '}
                        <strong>"Opinii și plângeri"</strong>,{' '}
                        <strong>"Aderarea utilizatorului la companie"</strong>,{' '}
                        <strong>"Verificarea companiei"</strong> și <strong>"Parcul auto"</strong> din
                        secțiunea <strong>"Companii și utilizatori"</strong> sunt accesibile în întregime
                        atât pentru utilizatorii înregistrați, cât și pentru cei neînregistrați.
                    </p>
                    <br />
                    <p className="text-indent">
                        &nbsp; Căutarea utilizatorilor sau companiilor poate fi efectuată în două moduri:
                        prin accesarea secțiunii <strong>"Companii și utilizatori"</strong> din meniul de
                        sus sau de jos al site-ului sau introducând direct datele legate de utilizator sau
                        companie în fereastra de căutare, situată în partea dreaptă a meniului de sus al
                        site-ului. În primul caz, după deschiderea secțiunii{' '}
                        <strong>"Companii și utilizatori"</strong>, trebuie să selectați fila
                        corespunzătoare <strong>"Toate companiile"</strong>,{' '}
                        <strong>"Lista neagră a companiilor"</strong> sau{' '}
                        <strong>"Toți utilizatorii"</strong>. Apoi, în fereastra de căutare, introduceți
                        datele utilizatorului sau companiei.  În al doilea caz, înainte de a începe
                        căutarea, trebuie să selectați baza de date în care să căutați: utilizatori sau
                        companii. Este important să acordați atenție faptului că pentru o căutare de succes
                        contează dacă destinatarul a folosit litere din alfabetul latin sau chirilic în
                        scrierea numelui, prenumelui sau denumirii companiei sale.
                    </p>
                    <br />
                    <p className="text-indent">
                        Căutarea poate fi realizată după următoarele criterii: după numele sau prenumele
                        utilizatorului, după denumirea companiei, numerele de telefon, adresele de e-mail,
                        ID-ul utilizatorului (<strong>GTUXXXXX</strong>) sau ID-ul companiei (
                        <strong>GTCYYYYY</strong>). Este important de menționat că căutarea poate fi
                        efectuată atât după un criteriu complet, cât și după o parte a acestuia. De exemplu,
                        după o parte din numele de familie sau denumirea companiei, numărul de telefon,
                        adresa de e-mail, ID-ul utilizatorului sau ID-ul companiei.
                    </p>
                    <br />
                    <p className="text-indent">
                        Pentru comoditatea utilizatorilor rezultatele căutării pot fi optimizate. Pentru
                        aceasta puteți utiliza selecții suplimentare din rezultatele propuse: după țară,
                        după tipul activității, doar companiile verificate, precum și pentru a sorta
                        rezultatele căutării într-un anumit mod și pentru a determina numărul de rezultate
                        afișate pe pagină.
                    </p>
                    <br />
                    <h5>
                        Ce este un rating? Cum pot lăsa o opinie pentru utilizatorul sau compania
                        înregistrată? Cum pot trimite o cerere (plângere) către Administrația Site-ului
                        pentru a include o companie în "Lista neagră a companiilor"?
                    </h5>
                    <p className="text-indent">
                        Sistemul oferă posibilitatea unui utilizator înregistrat, care este angajat al unei
                        companii cu verificare validă, să lase opinia sa despre activitatea profesională a
                        oricărui alt utilizator înregistrat pe site. Respectiv, administratorul unei
                        companii cu verificare validă poate scrie  în numele companiei o opinie despre
                        activitatea profesională a oricărei alte companii înregistrate pe site. Fiecare
                        opinia trebuie să fie însoțit de acordarea unei evaluări obiective de la{' '}
                        <strong>5</strong> la <strong>1</strong>, corespunzătoare criteriilor{' '}
                        <strong>"Foarte bine"</strong>, <strong>"Bine"</strong>, <strong>"Normal"</strong>,{' '}
                        <strong>"Slab"</strong> sau <strong>"Foarte slab"</strong>.
                    </p>
                    <br />
                    <p className="text-indent">
                        &nbsp; Sistemul realizează o evaluare automată (profilare) a utilizatorilor și
                        companiilor înregistrate. Procedura de profilare are loc în conformitate cu
                        următoarea regulă de formare a ratingului: suma evaluărilor acordate este împărțită
                        la numărul acestor evaluări. Rezultatul este prezentat sub formă de sumă acumulată.
                        Rezultatul profilării este disponibil pentru Utilizatorii Sistemului în profilul
                        utilizatorului sau al companiei.
                    </p>
                    <br />
                    <p className="text-indent">
                        &nbsp; Pentru a lăsa opinia (comentariu) despre un utilizator sau o companie
                        înregistrată , trebuie să deschideți profilul utilizatorului sau companiei
                        respective. În profilul deschis, apăsați opțiunea <strong>"Adăugă opinia"</strong>.
                        Puteți găsi profilul utilizatorului sau al companiei dorite consultând secțiunea{' '}
                        <strong>
                            "Cum să găsești un utilizator sau o companie înregistrată pe site sau să vezi
                            "Lista neagră a companiilor"?"
                        </strong>{' '}
                        pe această pagină a site-ului.
                    </p>
                    <br />
                    <p className="text-indent">
                        Opinia trebuie să fie editate doar pe baza{' '}
                        <strong>propriei experiențe practice de interacțiune profesională</strong> cu
                        utilizatorul sau compania. Opinia trebuie să respecte cerințele Termenilor de
                        utilizare a site-ului. Opinia postată apare pe site{' '}
                        <strong>în regim de timp real</strong>. Utilizatorul sau compania despre care s-a
                        scris opinia au posibilitatea să răspundă
                        <strong> cu argumente</strong>  la acesta, iar răspunsul trebuie, de asemenea, să
                        respecte cerințele Regulilor.
                    </p>
                    <br />
                    <p className="text-indent">
                        &nbsp; Sistemul oferă posibilitatea administratorului unei companii cu o verificare
                        validă  să trimită, în numele companiei, o cerere (plângere) Administrației
                        Site-ului pentru a include o altă companie înregistrată pe site în{' '}
                        <strong>"Lista neagră a companiilor"</strong>, în legătură cu activitatea
                        profesională nesatisfăcătoare a acesteia.
                    </p>
                    <br />
                    <p className="text-indent">
                        Pentru a trimite o cerere(plângere) pentru includerea unei companii în{' '}
                        <strong>"Lista neagră a companiilor"</strong>, trebuie să deschideți profilul acelei
                        companii. În profilul deschis, apăsați opțiunea <strong>"Adăugă plângere"</strong>.
                        Când completati o cerere, trebuie să indicați la ce categorie se referă această
                        plângere: <strong>"Incidente de plată"</strong>,{' '}
                        <strong>"Incidente de transport"</strong>, <strong>"Incidente de muncă"</strong> sau{' '}
                        <strong>"Altele"</strong>. Puteți găsi profilul companiei dorite consultând
                        secțiunea{' '}
                        <strong>
                            "Cum să găsești un utilizator sau o companie înregistrată pe site sau să vezi
                            "Lista neagră a companiilor"?"
                        </strong>{' '}
                        pe această pagină a site-ului.
                    </p>
                    <br />
                    <p className="text-indent">
                        &nbsp; Cererea trebuie să conțină o{' '}
                        <strong>
                            descriere obiectivă și argumentată a propriei experiențe de interacțiune
                            profesională{' '}
                        </strong>
                        cu compania și <strong>fapte concrete</strong> care să confirme  activitatea
                        profesională nesatisfăcătoare a acesteia. Cererea trebuie să se conformeze
                        cerințelor Termenilor de utilizare ai site-ului.
                    </p>
                    <br />
                    <p className="text-indent">
                        &nbsp; Cererea este trimisă pentru examinare la Administrația Site-ului și{' '}
                        <strong>nu apare pe site în regim de timp real</strong>. Administrația Site-ului are
                        dreptul să solicite companiei care a făcut cererea copii (scanări) ale documentelor
                        care să confirme faptele de activitate profesională nesatisfăcătoare ale părții
                        opuse, să solicite rescrierea cererii în cazul în care aceasta nu respectă Termenii
                        sau să o respingă în cazul în care din documentele prezentate nu rezultă că partea
                        opusă nu și-a îndeplinit obligațiile profesionale. Însă în caz că  există motive să
                        se creadă că cererea corespunde cerințelor sale, Administrația Site-ului se
                        adresează părții opuse, ascultă argumentele sale cu privire la conținutul plângerii
                        și, în caz de necesitate, solicită prezentarea de copii (scanări) ale documentelor
                        care contrazic faptele expuse în cerere. După examinarea tuturor argumentelor
                        prezentate de către părți, Administrația Site-ului emite o decizie
                        <strong>finală</strong> cu privire la cerere și o șterge sau o activează, făcând
                        plângera vizibilă tuturor utilizatorilor site-ului.
                    </p>
                    <br />
                    <p className="text-indent">
                        &nbsp; Compania care a fost inclusă în <strong>"Lista neagră a companiilor"</strong>{' '}
                        are posibilitatea să scrie un răspuns referitor la esența plangeriii. Răspunsul
                        trebuie să fie <strong>obiectiv și concret</strong> și să respecte cerințele
                        Termenilor.
                    </p>
                    <br />
                    <p className="text-indent">
                        &nbsp; Compania care a fost inclusă în <strong>"Lista neagră a companiilor"</strong>{' '}
                        va fi eliminată din aceasta de către Administrația Site-ului dacă cerințele
                        formulate în cerere sunt îndeplinite sau în urma deciziei instanței judecătorești se
                        constată că cerințele, fie direct, fie indirect, sunt nefondate. De asemenea,
                        Administrația Site-ului va elimina compania din{' '}
                        <strong>"Lista neagră a companiilor"</strong> la cererea scrisă a companiei care a
                        adăugat-o în această listă.
                    </p>
                    <br />
                    <p className="text-indent">
                        &nbsp; Detalii despre cum să lăsați un comentariu despre un utilizator sau o
                        companie, sau cum să trimiteți o cerere (plângere) pentru includerea unei companii
                        în <strong>"Lista neagră a companiilor"</strong> pot fi găsite consultând secțiunea{' '}
                        <strong>
                            "4.4. Reguli de adăugare a comentariilor despre utilizatori și companii,
                            includerea companiilor în "Lista neagră a companiilor"
                        </strong>{' '}
                        din <strong>"Termenii de utilizare ai site-ului"</strong>.
                    </p>
                    <br />
                    &nbsp;
                    <h5>Cum să caut mărfuri sau transport pe site?</h5>
                    <p className="text-indent">
                        &nbsp; Căutarea de mărfuri sau transport este disponibilă atât pentru utilizatorii
                        înregistrați, cât și pentru cei neînregistrați. Pentru aceasta, trebuie să utilizați
                        secțiunea <strong>"CĂUTARE MĂRFURI"</strong> sau, respectiv,{' '}
                        <strong>"CĂUTARE TRANSPORT"</strong> din partea de sus a site-ului. În formularul
                        deschis, trebuie să setați parametrii de căutare, selectând din listele oferite,
                        introducând, unde este necesar, date și valori numerice și, dacă este necesar,
                        bifând criteriile suplimentare de căutare. Trebuie avut în vedere că în listele
                        propuse de țări se găsesc numai acele țări pentru care există solicitări de mărfuri
                        sau transport în bazele de date ale site-ului. Dacă utilizatorul nu a setat niciun
                        criteriu de căutare și nu a apăsat butonul <strong>"Aplică"</strong>, i se va oferi
                        o listă a tuturor ofertelor disponibile în prezent pentru mărfuri sau transport.
                    </p>
                    <br />
                    <p className="text-indent">
                        În partea dreaptă a paginii, utilizatorul înregistrat are o secțiune{' '}
                        <strong>"CĂUTĂRILE MELE RECENTE"</strong>, unde parametrii căutărilor anterioare
                        sunt arhivați automat. Dând click pe oricare dintre ele formularul din partea stângă
                        a paginii se va completata automat cu datele din acea căutare. Va trebui doar să
                        actualizați datele și/sau unii parametri și criterii de căutare. Această opțiune va
                        permite utilizatorului înregistrat să economisească timp la completarea formularului
                        de căutare.
                    </p>
                    <br />
                    <p className="text-indent">
                        După apăsarea butonului <strong>"Aplică"</strong>, mai jos, în secțiunea{' '}
                        <strong>"OFERTE GĂSITE"</strong>, utilizatorului i se va afișa o listă completă a
                        mărfurilor sau transportului disponibile în bazele de date ale site-ului la momentul
                        respectiv, care corespund criteriilor de căutare stabilite. Trebuie să se acorde
                        atenție faptului că în acea secțiune există și parametri și opțiuni de sortare care
                        îi vor permite utilizatorului să "optimizeze" lista de oferte afișate.
                    </p>
                    <br />
                    <p className="text-indent">
                        Căutarea mărfurilor sau a transportului poate fi efectuată și prin secțiunea{' '}
                        <strong>"STATISTICA SITE-ULUI"</strong>, situată în partea dreaptă a paginii
                        principale. Aici, utilizatorului i se oferă posibilitatea de a vizualiza toate
                        ofertele disponibile pe site pentru anumite intervale de timp:{' '}
                        <strong>"Total"</strong>, <strong>"Astăzi"</strong> și{' '}
                        <strong>"De la ultima vizită"</strong>.
                    </p>
                    <br />
                    <p className="text-indent">
                        Căutarea mărfurilor sau a transportului poate fi, de asemenea, realizată prin
                        secțiunea <strong>"TABELUL REZUMATIV DE OFERTE"</strong>, care poate fi găsită
                        în partea de jos a paginii principale, în meniul de sus sau de jos al site-ului.
                        Aici utilizatorului i se oferă posibilitatea de a vizualiza toate ofertele
                        disponibile pe site după anumite țări și pentru anumite perioade de timp:{' '}
                        <strong>"Total"</strong> sau <strong>"Astăzi"</strong>. Ofertele pot fi vizualizate
                        separat atât pentru transport internațional, cât și pentru transportul național. În
                        această secțiune, se poate seta, de asemenea, afișarea doar a țărilor selectate de
                        utilizator. Pentru aceasta, este necesar să apăsați pe{' '}
                        <strong>"Oportunutăți selectate"</strong> și după ce ați marcat țările dorite, să
                        apăsați pe <strong>"Revină"</strong>.
                    </p>
                    <br />
                    <p className="text-indent">
                        Dacă dorește, utilizatorul poate opta să vizualizeze oferte de mărfuri sau transport
                        și de la utilizatorii blocați, bifând căsuța corespunzătoare. Prin "utilizatori
                        blocați" se înțelege utilizatorii pe care el i-a adăugat anterior în lista sa de
                        blocare. Mai multe detalii despre aceasta, puteți citi în secțiunea{' '}
                        <strong>
                            "Ce este lista de blocare? Cum să adăugați oferte de mărfuri și/sau transport de
                            la un anumit utilizator în lista de blocare?"
                        </strong>{' '}
                        mai jos pe această pagină.
                    </p>
                    <br />
                    <p className="text-indent">
                        Utilizatorul decide ce metodă de căutare îi este mai convenabilă. După ce a găsit în
                        listă poziția de interes, utilizatorul trebuie să dea click pe aceasta pentru a
                        vedea în formularul deschis detaliile ofertei și datele de contact ale autorului.
                        Trebuie să aveți în vedere că nu toți utilizatorii vor avea acces la datele de
                        contact ale autorului în orice moment. Unii le vor vedea cu o întârziere în timp.
                        Aceasta depinde de statutul utilizatorului pe site și de condițiile specificate în
                        secțiunea <strong>"Oportunitățile pentru utilizatori și prețuri"</strong>, care poate
                        fi găsită în meniul de sus sau de jos al site-ului. &nbsp;
                    </p>
                    <br />
                    <h5>Cum să adaug pe site marfa mea sau transportul meu disponibil ?</h5>
                    <p className="text-indent">
                        &nbsp; Orice utilizator înregistrat pe site poate posta ofertă de marfă sau
                        transport imediat după o înregistrare reușită. Persoanele care nu sunt utilizatori
                        înregistrați pe site nu pot adăuga mărfurile sau transporturile lor.
                    </p>
                    <br />
                    <p className="text-indent">
                        Cererea este creată de utilizatorul înregistrat prin completarea unui formular
                        special în secțiunile <strong>"Adăugați marfa"</strong> sau{' '}
                        <strong>"Adăugați transportul"</strong> și trebuie să conțină exclusiv o ofertă privind
                        necesitatea transportului de mărfuri pe un anumit traseu sau o ofertă privind
                        disponibilitatea unui transport să deplaseze mărfuri într-o anumită direcție.
                    </p>
                    <br />
                    <p className="text-indent">
                        Numărul maxim de cereri active permise pentru postare, ce vizeaza mărfurile și transportul,
                        este determinat în funcție de statutul utilizatorului pe site și de termenii specificați în secțiunea{' '}
                        <strong>"Oportunitățile pentru utilizatori și prețuri"</strong>, care poate fi găsită
                        în meniul de sus sau de jos al site-ului.
                    </p>
                    <br />
                    <p className="text-indent">
                        Pentru a posta o cerere cu o ofertă privind necesitatea de a transporta mărfuri pe
                        un anumit traseu sau o ofertă privind disponibilitatea unui transport liber,
                        pregătit să transporte mărfuri în anumite direcții, utilizatorul înregistrat trebuie
                        să completeze toate câmpurile obligatorii, marcate cu o steluță roșie, în formularul
                        de adăugare din secțiunea corespunzătoare a site-ului{' '}
                        <strong>("Adăugați marfa"</strong> sau <strong>"Adăugați transportul"</strong>). Câmpurile
                        opționale sunt completate la discreția utilizatorului. Pentru comoditatea
                        utilizatorului, adăugarea unei cereri poate fi realizată atât folosind opțiunea{' '}
                        <strong>"Introduceți manual / selectați pe hartă"</strong>, cât și opțiunea{' '}
                        <strong>"Selectați din listă"</strong>.
                    </p>
                    <br />
                    <p className="text-indent">
                        Dacă nevoia de transport pentru marfa adăugată sau disponibilitatea transportului
                        liber adăugat este regulată, utilizatorul poate folosi opțiunea „
                        <strong>Salvați ca șablon</strong>”. Acest lucru va permite, la adăugările
                        ulterioare ale acelelor mărfuri sau transportului, să evitați completarea repetată a
                        tuturor câmpurilor, folosind opțiunea „<strong>Completați după șablon</strong>”,
                        setând doar data actuală de disponibilitate a mărfurilor sau transportului. După
                        completarea câmpurilor, apăsați butonul „<strong>Adăugă</strong>”.
                    </p>
                    <br />
                    <p className="text-indent">
                        Imediat după adăugarea cu succes a marfei de către utilizator, acesta va fi
                        redirecționat automat către pagina <strong>"OFERTE DE MARFĂ (#No)"</strong>, unde vor
                        fi afișați toți parametrii mărfurilor adăugat de el.
                    </p>
                    <br />
                    <p className="text-indent">
                        Similar, imediat după adăugarea cu succes a transportului, utilizatorului i se va
                        deschide automat pagina <strong>"OFERTE DE TRANSPORT (#No)"</strong>, unde vor fi
                        afișați toți parametrii transportului adăugat de el.
                    </p>
                    <br />
                    <p className="text-indent">
                        La această etapă, pe oricare dintre aceste pagini, utilizatorul poate face
                        modificări la parametrii mărfurilor sau transportului său, dacă identifică o eroare.
                        Pentru asta, trebuie să apăsați pe <strong>"Editează"</strong> din colțul dreapta
                        sus al paginii, să corectați parametrii greșit introduși și să apăsați{' '}
                        <strong>"Salvează"</strong>.
                    </p>
                    <br />
                    <p className="text-indent">
                        Cererile adăugate cu succes ajung <strong>în regim de  timp real</strong> în bazele
                        de date ale site-ului pentru mărfuri care necesită transport sau, respectiv, în
                        bazele de date pentru transport disponibil pentru mărfuri, devenind accesibile
                        pentru vizualizare de către alți utilizatori ai site-ului. Cererea adăugată va fi
                        ștearsă automat din bazele de date ale site-ului la data expirării acesteia,
                        indicată la depunere. Dacă cererea a fost închisă înainte de termenele specificate
                        sau nu mai este actuală, utilizatorul înregistrat este obligat să o șteargă (sau să
                        o dezactiveze) pe cont propriu. Cererile dezactivate nu mai sunt vizibile pentru
                        alți utilizatori ai site-ului, dar rămân în profilul utilizatorului ca cereri
                        neactive pentru mărfuri sau transport și pot fi reactivate în cazul în care devin
                        din nou relevante, prin stabilirea unei noi date sau editarea altor detalii.. Pentru
                        a reactiva o cerere, utilizatorul trebuie să apese în profilul său pe butonul{' '}
                        <strong>"Mărfurile mele"</strong> sau <strong>"Transportul meu"</strong>, să
                        găsească cererea dezactivată în listele oferite, să o deschidă și să o actualizeze,
                        apăsând pe <strong>"Editează"</strong> și, după efectuarea modificărilor, pe{' '}
                        <strong>"Salvează"</strong>.
                    </p>
                    <br />
                    <p className="text-indent">
                        Responsabilitatea pentru autenticitatea informațiilor furnizate în cerere o poartă
                        utilizatorul care a postat respectiva cerere. Completarea formularului cererii
                        trebuie să fie efectuată strict conform destinațiilor câmpurilor. Este interzisă
                        publicarea de informații care nu se referă la parametrii și caracteristicile
                        mărfurilor sau transportului, sau a mesajelor cu caracter publicitar. În
                        subsecțiunea <strong>"Informații suplimentare"</strong>, se poate specifica doar
                        acea informație pentru care nu sunt prevăzute câmpuri speciale în formularul de
                        depunere a cererii.
                    </p>
                    <br />
                    <h5>Ce înseamnă oferte de transport compatibile pentru marfa mea?</h5>
                    <p className="text-indent">
                        &nbsp; Această opțiune este propusă pentru a reduce timpul de căutare și pentru
                        comoditatea utilizatorului. Pentru marfa postată de el pe site, este organizată o
                        selecție de oferte pentru transportul liber compatibil. Selecția se face pe baza
                        unor parametri specifici de potrivire cu ofertele existente la momentul respectiv în
                        bazele de date pentru transport, postate de alți utilizatori.
                    </p>
                    <br />
                    <p className="text-indent">
                        În mod implicit, prin parametrii specifici de potrivire se înțelege: țara de
                        localizare a transportului, țara aleasă ca destinație a transportului și intervalul
                        de timp în care transportul este disponibil pentru încărcare, care corespund
                        respectiv țării unde este localizată marfa, țara de descărcare a mărfurilor și
                        intervalul de timp în care marfa este disponibilă pentru încărcare.
                    </p>
                    <br />
                    <p className="text-indent">
                        Unde și cum puteți vedea aceste oferte compatibile de transport? Imediat după
                        adăugarea cu succes a mărfurilor de către utilizator, acestuia i se va deschide
                        automat pagina <strong>"OFERTE DE MĂRFĂ (#No)"</strong>, unde vor fi afișați toți
                        parametrii mărfurilor tocmai introduse de el. În partea de jos a paginii
                        utilizatorul poate vedea subsecțiunea
                        <strong>"OFERTE DE TRANSPORT COMPATIBILE PENTRU MARFA UTILIZATORULUI"</strong>, unde
                        va putea să se familiarizeze cu ele.
                    </p>
                    <br />
                    <p className="text-indent">
                        În cazul în care numărul de coincidențe propuse este prea mare, utilizatorul poate
                        seta criterii suplimentare de potrivire, ceea ce poate duce la reducerea
                        rezultatelor și timpului necesar pentru a căuta informații relevante. Pentru aceasta
                        trebuie să faceți clic pe <strong>"Setarea criteriilor"</strong> și să bifați toți
                        sau unii dintre parametrii suplimentari de potrivire. Aceștia includ:{' '}
                        <strong>"Tip(uri) de transport"</strong>, <strong>"Capacitate de încărcare"</strong>
                        , <strong>"Regim de temperatură"</strong>, <strong>"În grupaj"</strong>,{' '}
                        <strong>"ADR"</strong>, <strong>"Agabaritic"</strong>.
                    </p>
                    <br />
                    <p className="text-indent">
                        Cu oferte compatibile de transport pentru marfa dată și pentru alte mărfuri ale
                        sale, utilizatorul poate să le vadă în profilul său personal sau în subsecțiunea{' '}
                        <strong>"Statistica mea"</strong>.
                    </p>
                    <br />
                    <h5>Ce înseamnă oferte de marfă compatibile pentru transportul  meu?</h5>
                    <p className="text-indent">
                        Această opțiune, la fel ca și cea anterioară, a fost propusă pentru a reduce timpul
                        de căutare și pentru a facilita activitatea utilizatorului. Pentru transportul liber
                        postat pe site de către acesta, se organizează o selecție de oferte compatibile cu
                        mărfurile sale. Selecția se face în funcție de anumiți parametri de potrivire cu
                        ofertele disponibile în prezent în bazele de date cu privire la mărfuri, propuse de
                        alți utilizatori.
                    </p>
                    <br />
                    <p className="text-indent">
                        Implicit, prin parametrii specifici de potrivire se înțelege țara în care se află
                        transportul, țara de direcție preferată a transportului și intervalul de timp în
                        care vehiculul este pregătit pentru încărcare, care corespund cu țara în care se
                        află marfa, țara de descărcare a mărfurilor și intervalul de timp în care marfa este
                        pregătită pentru încărcare.
                    </p>
                    <br />
                    <p className="text-indent">
                        Unde și cum puteți vedea aceste oferte de mărfuri compatibile? Imediat după
                        adăugarea cu succes a transportului de către utilizator, acestuia i se va deschide
                        automat pagina <strong>"OFERTE DE TRANSPORT (#No)"</strong> unde vor fi afișați toți
                        parametrii transportului tocmai introdus de el. În partea de jos a paginii,
                        utilizatorul va putea vedea subsecțiunea{' '}
                        <strong>"OFERTE DE MARFĂ COMPATIBILE PENTRU TRANSPORUL UTILIZATORULUI"</strong>,
                        unde poate să le examineze
                    </p>
                    <br />
                    <p className="text-indent">
                        În cazul în care numărul de coincidențe  este prea mare, utilizatorul poate seta
                        criterii suplimentare de potrivire, ceea ce poate duce la reducerea rezultatelor și
                        timpului necesar pentru a căuta informații relevante. Pentru aceasta, trebuie să
                        facă clic pe <strong>"Setarea criteriilor"</strong> și să bifeze toți sau unii
                        parametri suplimentari de potrivire. Aceștia includ:{' '}
                        <strong>"Tip(uri) de transport"</strong>, <strong>"Greutatea mărfurilor"</strong>,{' '}
                        <strong>"Regim de temperatură"</strong>, <strong>"În grupaj"</strong>,{' '}
                        <strong>"ADR"</strong>, <strong>"Agabaritic"</strong>.
                    </p>
                    <br />
                    <p className="text-indent">
                        De asemenea utilizatorul poate consulta ofertele de mărfuri compatibile, atât pentru
                        acest transport cât și pentru alte vehicule ale sale în profilul său personal sau în
                        subsecțiunea <strong>"Statistica mea"</strong>.
                    </p>
                    <br />
                    <h5>Vizualizarea anunțurilor. Cum să adaug pe site anunțul meu?</h5>
                    <p className="text-indent">
                        &nbsp; Pentru a vizualiza anunțurile disponibile, accesați secțiunea {' '}
                        <strong>"Anunțuri"</strong>, pe care o puteți găsi în meniul de sus sau de jos al
                        site-ului, precum și pe alte pagini. În funcție de statutul utilizatorului pe site,
                        textele complete ale anunțurilor pot fi disponibile nu imediat, ci cu o anumita
                        întârziere în timp, conform condițiilor prezentate în secțiunea {' '}
                        <strong>"Oportunitațile pentru utilizatori și prețuri"</strong>, care poate fi găsită
                        în meniul de sus sau de jos al site-ului.
                    </p>
                    <br />
                    <p className="text-indent">
                        Dacă un utilizator nu dorește să parcurgă lista tuturor anunțurilor de pe site,
                        poate limita această listă, trecând la filele <strong>"Transporturi"</strong>,{' '}
                        <strong>"Cumpăr - Vând"</strong>, <strong>"Ofer Lucru"</strong>,{' '}
                        <strong>"Caut de Lucru"</strong>, <strong>"Altele"</strong>, și de asemenea
                        utilizând filele temporale <strong>"De la ultima vizită"</strong>,{' '}
                        <strong>"De azi"</strong>, <strong>"Din ultimele 3 zile"</strong>.
                    </p>
                    <br />
                    <p className="text-indent">
                        Adăugarea anunțurilor în secțiunea <strong>"Anunțuri"</strong> poate fi făcută doar
                        de un utilizator înregistrat, imediat după înregistrarea cu succes pe site. Numărul
                        permis de anunțuri active este reglementat de statutul utilizatorului pe Site.
                        Pentru a adăuga un anunț, utilizatorul trebuie să acceseze secțiunea{' '}
                        <strong>"Anunțuri"</strong>. Apoi faceți clic pe <strong>"Adaugă anunț"</strong>.
                        Puteți adăuga și un anunț vizualizând un anumit anunț al altui utilizator, făcând
                        clic pe butonul <strong>"Adaugă anunțul tău"</strong>. După completarea tuturor
                        câmpurilor obligatorii ale formularului, selectând datele din listele derulante și
                        adăugând, dacă doriți, fotografii, apăsați <strong>"Adaugă"</strong>. După adăugarea
                        cu succes a anunțului, acesta intră imediat în lista generală a anunțurilor din
                        secțiunea <strong>"Anunțuri"</strong> și devine disponibil pentru alți utilizatori
                        ai site-ului. Cu privire la tematica și regulile de publicare a anunțurilor, vă
                        recomandăm să consultați secțiunile <strong>4.2.</strong> și <strong>4.5.</strong>{' '}
                        din <strong>"Termenii de utilizare ai site-ului"</strong>.
                    </p>
                    <br />
                    <h5>Ce informații sunt publicate în secțiunea "Știri"?</h5>
                    <p className="text-indent">
                        Știrile publicate pe site sunt direct sau indirect legate de domeniul transportului
                        de mărfuri. Accentul principal în știri se pune pe informațiile care ajută
                        operatorii de transport să fie la curent cu situația de pe piața transporturilor de
                        mărfuri internaționale: restricțiile de circulație ale vehiculelor de transport de
                        marfă pe autostrăzile internaționale din Europa și CSI, comunicările de la
                        structurile naționale și internaționale autorizate să reglementeze domeniul
                        transporturilor de mărfuri, condițiile de drum pe continent în timpul sezonului de
                        iarnă, informații legate de situația punctelor de trecere a frontierei, incidentele
                        de transport, informații analitice, comunicări de la administrația site-ului și alte
                        informații utile.
                    </p>
                    <br />
                    <p className="text-indent">
                        Utilizatorilor înregistrați li se oferă posibilitatea de a adăuga un comentariu la
                        orice știre. Pentru aceasta, trebuie să scrieți mesajul dvs. cu privire la știrea
                        citită în subsecțiunea <strong>"ADAUGĂ COMENTARIU"</strong> din partea de jos a
                        paginii. Apoi apăsați <strong>"Trimite"</strong>.
                    </p>
                    <br />
                    <h5>Cum se utilizează subsecțiunea "Marcajele mele"?</h5>
                    <p className="text-indent">
                        &nbsp; Utilizatorilor înregistrați li se oferă pentru comoditatea lor subsecțiunea{' '}
                        <strong>"Marcajele mele"</strong>, care se găsește în secțiunea{' '}
                        <strong>"STATISTICA MEA"</strong> pe pagina principală a site-ului.{' '}
                        <strong>"Marcajele mele"</strong> permit colectarea și arhivarea informațiilor utile
                        găsite de către utilizator pe site, la care ulterior întotdeauna poate avea acces
                        rapid. Informațiile colectate și arhivate includ cereri specifice de mărfuri sau
                        transport, anunțuri publicate de alți utilizatori ai site-ului, precum și știri
                        publicate de Administrația Site-ului.
                    </p>
                    <br />
                    <p className="text-indent">
                        Pentru a adăuga în <strong>"Marcajele mele"</strong> o cerere de transport/marfă, un
                        anunț sau o știre care vă interesează, trebuie să apăsați pe steagul portocaliu care
                        o însoțește. După aceasta, culoarea steagului se va schimba în verde - adăugarea în{' '}
                        <strong>"Marcajele mele"</strong> a fost realizată cu succes. Orice informații
                        adăugate în această subsecțiune vor fi întotdeauna ușor accesibile utilizatorului
                        atunci când are nevoie de a le revizui din nou. Utilizatorii au, de asemenea,
                        posibilitatea de a șterge din subsecțiunea <strong>"Marcajele mele"</strong>{' '}
                        informațiile care au devenit neactuale pentru ei. &nbsp;
                    </p>
                    <br />
                    <h5>Posibilitatea de schimb de mesaje între utilizatori (Messenger, chat).</h5>
                    <p className="text-indent">
                        &nbsp; Utilizatorilor înregistrați pe site li se oferă posibilitatea de a schimba
                        mesaje scrise instantaneu (Messenger, chat). Pentru a trimite un mesaj
                        destinatarului dorit, trebuie să-l găsiți pe site. Trebuie remarcat faptul că pentru
                        o căutare reușită este important dacă destinatarul a folosit litere latine sau
                        chirilice în scrierea numelui și prenumelui său.
                    </p>
                    <br />
                    <p className="text-indent">
                        Există mai multe modalități de căutare. De exemplu, deschideți secțiunea{' '}
                        <strong>"Companii și utilizatori"</strong> din meniul superior sau inferior al
                        site-ului. În fereastra de căutare a subsecțiunii{' '}
                        <strong>"Toți utilizatorii"</strong>, trebuie să introduceți numele destinatarului,
                        căruia doriți să îi scrieți și să apăsați pe pictograma <strong>"Caută"</strong>. În
                        lista de rezultate a căutării, găsiți utilizatorul dorit și deschideți profilul său.
                        În profilul care se deschide, apăsați pe pictograma{' '}
                        <strong>"Trimiteți un mesaj"</strong>. În partea de jos a blocului deschis{' '}
                        <strong>"SCHIMB DE MESAJE"</strong>, introduceți textul mesajului și apăsați{' '}
                        <strong>"Trimite"</strong>. Destinatarul va primi imediat acest mesaj.
                    </p>
                    <br />
                    <p className="text-indent">
                        De asemenea puteți căuta un destinatar știind numele companiei pentru care lucrează
                        sau a lucrat. Pentru asta deschideți secțiunea{' '}
                        <strong>"Companii și utilizatori"</strong>. În fereastra de căutare a subsecțiunii{' '}
                        <strong>"Toate companiile"</strong>, introduceți numele sau chiar o parte din numele
                        acestei companii și apăsați pe pictograma <strong>"Caută"</strong>. Trebuie remarcat
                        faptul că, pentru o căutare reușită contează dacă literele latine sau chirilice au
                        fost folosite la înregistrarea companiei pe site. În lista de rezultate a căutării,
                        găsiți compania dorită și deschideți profilul său. În profilul care se deschide,
                        accesați fila <strong>"Angajați"</strong>. În listele deschise{' '}
                        <strong>"Angajați actuali"</strong> sau <strong>"Foști angajați"</strong>, găsiți
                        destinatarul corespondenței dvs. și apăsați pe <strong>"Începeți chatul"</strong>
                    </p>
                    <br />
                    <p className="text-indent">
                        . Fiecare utilizator înregistrat pe site primește imediat după înregistrare un număr
                        personal în formatul <strong>GTUXXXXX</strong>. Acesta este întotdeauna afișat în
                        profilul oricărui utilizator, puțin mai la dreapta numelui și prenumelui. Cunoscând
                        acest număr, puteți să-l introduceți în fereastra de căutare din meniul superior al
                        site-ului și să găsiți astfel utilizatorul. Deschizându-i profilul puteți trimite un
                        mesaj. De asemenea trebuie de menționat că această fereastră de căutare din meniul
                        superior al site-ului poate fi folosită și pentru alte căutări ale utilizatorilor și
                        companiilor.
                    </p>
                    <br />
                    <p className="text-indent">
                        În toate cererile de marfă sau transport, în anunțurile publicate, în subiectele
                        create sau în comentariile la subiectele din secțiunea <strong>"Forum"</strong>, în
                        comentariile la știri publicate de utilizatorii înregistrați, este indicat autorul.
                        Deschizându-i profilul, puteți trimite întotdeauna un mesaj scris.
                    </p>
                    <br />
                    <p className="text-indent">
                        După ce ați trimis un mesaj cuiva sau ați primit unul de la cineva, acel destinatar
                        va fi salvat pentru totdeauna în Messenger-ul utilizatorului. Pictograma Messenger
                        se află în partea de sus a site-ului, la stânga avatarului utilizatorului. Un clic
                        pe aceasta va deschide blocul <strong>"SCHIMB DE MESAJE"</strong>. Apariția de noi
                        mesaje de la alți utilizatori va fi semnalată de un steag portocaliu pe fundalul
                        pictogramei Messenger, indicând numărul de mesaje noi.
                    </p>
                    <br />
                    <h5>
                        Ce este lista de blocare? Cum puteți adăuga oferte de marfă și/sau de transport de
                        la un anumit utilizator în lista de blocare?
                    </h5>
                    <p className="text-indent">
                        Opțiunea <strong>"Lista de blocare"</strong> permite utilizatorului înregistrat pe
                        site să oprească (blocheze) afișarea ofertelor de marfă și/sau transport de la un
                        anumit utilizator. Această opțiune poate fi solicitată dacă se presupune că autorul
                        ofertelor este un partener necinstit cu un rating scăzut, informațiile sale sunt
                        adesea depășite, experiența anterioară de colaborare cu el a fost negativă sau din
                        orice alte motive pe care utilizatorul le consideră relevante. Cu o abordare
                        corectă, această opțiune poate reduce semnificativ cantitatea de informații
                        vizualizate și poate economisi timpul și nervii utilizatorului.
                    </p>
                    <br />
                    <p className="text-indent">
                        Pentru a opri (bloca) afișarea ofertelor de marfă și/sau de transport de la un
                        anumit utilizator, trebuie să deschideți profilul acestui utilizator. Profilul
                        utilizatorului îl puteți găsi consultând secțiunea{' '}
                        <strong>
                            "Cum să găsiți pe site un utilizator sau o companie dorită și să vizualizați
                            "Lista neagră a companiilor"?"
                        </strong>{' '}
                        pe această pagină a site-ului. În profilul deschis, apăsați opțiunea{' '}
                        <strong>"Lista de blocare"</strong>, care poate fi găsită în colțul dreapta-sus al
                        cartei de vizită a utilizatorului. În fereastra care apare, intitulată{' '}
                        <strong>"Adăugați în lista de blocare"</strong>, va fi afișat un text care spune:
                        "Dacă nu doriți să vedeți în continuare ofertele de marfă și/sau de transport de la
                        acest utilizator, blocați afișarea lor. Dacă este necesar, puteți oricând să opriți
                        blocările stabilite anterior". Apoi, trebuie să bifați opțiunile{' '}
                        <strong>"Oferte de marfă"</strong> și/sau <strong>"Oferte de transport"</strong>.
                        Ulterior, dacă dezactivații aceste opțiuni, afișarea ofertelor de marfă și/sau de
                        transport va fi restabilită, blocările stabilite anterior vor fi deblocate.
                    </p>
                    <br />
                    <h5>
                        Ce înseamnă "parcul auto personal"? Ce înseamnă "parcul auto al companiei"? Cum se
                        adaugă vehicule în parcul auto?
                    </h5>
                    <p className="text-indent">
                        Oricărui utilizator înregistrat i se oferă posibilitatea să-și creeze pe site-ul
                        nostru propria secțiune denumită <strong>"Parcul Auto Personal"</strong>. În această
                        secțiune puteți să plasați, să stocați și, la nevoie, să editați sau să ștergeți
                        informații despre vehiculele de transport de marfă pe care le dețineți. De ce este
                        necesar acest lucru, care sunt avantajele? Atunci când adăugați pe site o cerere
                        pentru un vehicul de transport disponibil, pregătit să transporte mărfuri pe o
                        anumită rută, puteți "atașa" la cerere datele unui anumit vehicul, preluate din
                        secțiunea <strong>"Parcul Auto Personal"</strong>. Acest lucru va reduce
                        semnificativ timpul necesar completării multor câmpuri ale cererii, oferind
                        potențialilor parteneri o imagine precisă și detaliată asupra caracteristicilor
                        tehnice și cantitative ale vehiculului propus de utilizator. Informațiile despre
                        toate vehiculele deținute de utilizator vor fi afișate în profilul său personal, în
                        secțiunea <strong>"Parcul Auto Personal"</strong>, și vor fi accesibile pentru
                        vizualizare de către toți utilizatorii site-ului.
                    </p>
                    <br />
                    <p className="text-indent">
                        Oricărui utilizator înregistrat care devine membru al unei companii sau care a creat
                        propria sa companie pe site i se oferă posibilitatea de a accesa secțiunea{' '}
                        <strong>"Parcul auto al companiei"</strong>. Adăugarea, editarea și ștergerea
                        înregistrărilor din această secțiune va fi disponibilă doar{' '}
                        <strong>administratorului companiei</strong>. Beneficiile utilizării acestei
                        secțiuni sunt similare cu cele descrise mai sus în secțiunea{' '}
                        <strong>"Parcul auto personal"</strong>. Atunci când adăugați o cerere pentru un
                        vehicul de transport disponibil pe site, pregătit să transporte mărfuri pe un anumit
                        traseu, oricare membru al companiei poate "atașa" datele despre un anumit vehicul,
                        luate din secțiunea <strong>"Parcul auto al companiei"</strong>.
                    </p>
                    <br />
                    <p className="text-indent">
                        Pentru informații detaliate despre cum un utilizator înregistrat sau un
                        administrator al companiei poate adăuga un vehicul în{' '}
                        <strong>"Parcul auto personal"</strong> sau în{' '}
                        <strong>"Parcul auto al companiei"</strong>, și cum să "atașeze" un vehicul din
                        secțiunea <strong>"Parcul auto personal"</strong> sau din{' '}
                        <strong>"Parcul auto al companiei"</strong> la o cerere pentru un vehicul de
                        transport disponibil, sunt expuse în detalii în subsecțiunea{' '}
                        <strong>"Parcul auto"</strong> din secțiunea{' '}
                        <strong>"Companii și utilizatori"</strong> accesibilă în meniul de sus sau de jos al
                        site-ului.
                    </p>
                    <br />
                    <h5>Regulile de funcționare ale secțiunii "Forum".</h5>
                    <p className="text-indent">
                        &nbsp; Regulile de funcționare ale secțiunii <strong>"Forum"</strong> sunt detaliat
                        descrise în secțiunea <strong>"4.6. Regulile Forumului"</strong> din <strong>"Termrnii de
                            utilizare ai site-ului"</strong>, cu care vă puteți familiariza în meniul superior sau
                        inferior al site-ului.
                    </p>
                    <br />
                    <h5>Cum să folosiți blocul "CALCULARE DISTANȚĂ"?</h5>
                    <p className="text-indent">
                        &nbsp; Blocul <strong>"CALCULARE DISTANȚĂ"</strong>, situat pe pagina principală în
                        partea dreaptă, permite atât utilizatorilor înregistrați cât și celor neînregistrați
                        să determine rapid distanța în kilometri între două localități. Pentru aceasta, în
                        câmpul <strong>"Din oraș"</strong> trebuie să introduceți numele primei localități
                        și în câmpul <strong>"În oraș"</strong> să introduceți numele celei de-a doua
                        localități. După aceasta apăsați butonul <strong>"Calculează"</strong>. Dacă unul
                        sau ambele orașe sunt introduse incorect sau numele orașului (orașelor) este
                        necunoscut, blocul va afișa un mesaj special, evidențiat în roșu. În acest caz,
                        verificați corectitudinea scrierii numelui orașului (orașelor) sau încercați să
                        introduceți numele lor într-o altă limbă, de exemplu, în engleză. De asemenea,
                        puteți încerca să introduceți în câmpul de căutare numele unui oraș vecin mai mare.
                    </p>
                    <br />
                    <p className="text-indent">
                        Un alt mod prin care un utilizator înregistrat poate determina distanța dintre două
                        localități este să utilizeze secțiunile site-ului <strong>"Adăugați marfa"</strong>{' '}
                        sau <strong>"Adăugați transportul"</strong>. Pentru aceasta, în una dintre aceste
                        secțiuni, utilizați opțiunea{' '}
                        <strong>"Introduceți manual / selectați pe hartă"</strong>. După introducerea
                        numelor ambelor localități, apăsați <strong>"Stabilește traseul"</strong>. Pe lângă
                        rezultatul final - distanța dintre localități - puteți vedea și traseul stabilit pe
                        hartă între ele. Conveniența acestei opțiuni constă și în faptul că numele
                        localităților pot fi introduse și prin apăsarea pe denumirea lor de pe hartă.
                    </p>
                    <br />
                    <h5>Ar trebui să achiziționez un pachet cu plată?</h5>
                    <p className="text-indent">
                        &nbsp; Pentru ca utilizatorul să poată răspunde corect la această întrebare, trebuie
                        să viziteze secțiunea „<strong>Oportunitățile utilizatorilor și prețuri</strong> ”,
                        pe care o puteți găsi în meniul de sus sau de jos al site-ului. În această secțiune
                        sunt prezentate într-un tabel detalii despre posibilitățile atât ale utilizatorilor
                        fără pachet, cât și ale celor care au achiziționat pachetul „<strong>Gold</strong>”.
                        Consultând informațiile prezentate, utilizatorul va putea înțelege mai bine regulile
                        actuale ale sistemului și să decidă asupra alegerii statutului personal pentru a
                        lucra cu succes pe site. În cazul în care apar întrebări, vă recomandăm să
                        contactați Administrația Site-ului. &nbsp;
                    </p>
                    <br />
                    <h5>Ce este un "pachet corporativ"?</h5>
                    <p className="text-indent">
                        &nbsp; Prin „pachet corporativ” se înțelege posibilitatea ca o companie verificată
                        să achiziționeze un anumit număr de pachete <strong>"Gold light"</strong>, care nu
                        depășește numărul total de angajați activi ai companiei. Care este avantajul
                        achiziționării pachetului <strong>"Gold light"</strong>? Acest pachet, în ceea ce
                        privește funcționalitatea sa, este practic identic cu pachetul{' '}
                        <strong>"Gold"</strong>, dar costul său este semnificativ mai mic.
                    </p>
                    <br />
                    <p className="text-indent">
                        Pachetul <strong>"Gold light"</strong> poate fi achiziționat doar de către angajații
                        (administratorul) unei companii care are o verificare activă. Aceste pachete pot fi
                        achiziționate doar după ce compania a achiziționat cel puțin un pachet{' '}
                        <strong>"Gold"</strong>. Acestea sunt achiziționate în perioada de valabilitate a
                        pachetului <strong>"Gold"</strong>, sunt valabile numai în timpul valabilității
                        pachetului <strong>"Gold"</strong> și doar cât timp utilizatorul este angajat al
                        companiei, și cât timp companiei nu i-a expirat termenul de verificare sau nu a fost
                        privată de acesta de către administrația site-ului pentru încălcări. De asemenea,
                        posibilitatea achiziționării unor astfel de pachete nu este disponibilă atunci când
                        în companie intră un nou angajat care a achiziționat un pachet{' '}
                        <strong>"Gold" </strong>
                        anterior.
                    </p>
                    <br />
                    <p className="text-indent">
                        Pentru a vă familiariza cu posibilitățile pachetelor contra plată, trebuie să
                        vizitați secțiunea <strong>"Oportunitățile utilizatorilor și prețuri" </strong>pe
                        care o puteți găsi în meniul de sus sau de jos al site-ului. Aceasta vă va permite
                        să înțelegeți mai bine regulile actuale ale sistemului și să decideți asupra
                        alegerii statutului dvs. pentru a lucra cu succes pe site.
                    </p>
                    <br />
                    <h5>
                        Ce sunt fișierele cookies?
                    </h5>
                    <p className="text-indent">
                        Fișierele cookies sunt mici fișiere text pe care un
                        browser (de exemplu, Google Chrome, Internet Explorer, Firefox, Safari etc.) le
                        salvează pe dispozitivul utilizatorului (pe calculator, telefon mobil, tabletă) în
                        momentul vizitării unui site web, cu scopul de a identifica browserul și informația
                        sau setările salvate în acesta.
                    </p>
                    <br />
                    <p className="text-indent">
                        <strong>Fișierele Cookies nu sunt viruși și nici programe.</strong>  Ele nu pot
                        instala ceea ce nu doriți pe dispozitivul dvs. sau accesa informații din dosarele
                        dispozitivului dvs. De obicei, sunt utilizate pentru a îmbunătăți interacțiunea cu
                        utilizatorul, urmărirea navigării acestuia pe site, memorarea datelor de
                        autentificare, înregistrarea timpului de vizită, istoricul vizitelor, preferințele
                        și așa mai departe. Astfel, cu ajutorul fișierelor cookies, site-ul are capacitatea
                        de a salva setările individuale ale utilizatorului, de a-l recunoaște și de a
                        reacționa în consecință. Utilizarea fișierelor cookies poate fi blocată sau
                        limitată, dar fără fișierele cookies, utilizarea completă a tuturor funcțiilor
                        site-ului ar fi imposibilă.
                    </p>
                    <br />
                </div>
            </div>
        )
    }
}
