export const md1 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>КОДЕКС автомобильного транспорта</h4><p></p>

<p style="text-indent:2em;">Целью настоящего кодекса является регулирование деятельности в области автомобильного транспорта и определение прав, обязанностей и ответственности транспортных агентов,
 оказывающих автотранспортные услуги в Республике Молдова, а также физических и юридических лиц, пользующихся такими услугами.</p>

<p></p><h4>Раздел I
ОБЩИЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">Ст. 1. – (1) Настоящий кодекс устанавливает правовые основы организации и осуществления автотранспортных перевозок грузов и пассажиров,
 а также сопутствующей автотранспортным перевозкам деятельности на территории Республики Молдова, с соблюдением условий безопасности и
 качества, принципов свободной конкуренции и мер по охране окружающей среды, прав и законных интересов физических и юридических лиц.</p><br>
 
<p style="text-indent:2em;">(2) Настоящий кодекс определяет права, обязанности и ответственность публичных органов, ответственных за организацию автотранспортных
 перевозок, а также физических и юридических лиц, осуществляющих такую деятельность.</p><br>
 
<p style="text-indent:2em;">(3) В части, не регулируемой настоящим кодексом, порядок запроса, выдачи, приостановления и отзыва разрешительных документов,
 предусмотренных настоящим кодексом для автотранспортных операторов и для предприятий, осуществляющих сопутствующие автотранспортным
 перевозкам виды деятельности, определяется Законом о регулировании предпринимательской деятельности путем 
 разрешения № 160/2011.</p>
 
<p style="text-indent:2em;"></p><h6>Ст. 2. – Если прямо не предусмотрено иное, действие настоящего кодекса применяется в отношении всех предприятий, зарегистрированных
 на территории Республики Молдова и осуществляющих деятельность автотранспортного оператора и/или сопутствующую автотранспортным
 перевозкам деятельность, а также иностранных автотранспортных операторов в соответствии с двусторонними соглашениями,
 международными конвенциями и соглашениями, стороной которых является Республика Молдова, за исключением:</h6><p></p>
 
<p style="text-indent:2em;">a) физических лиц, осуществляющих автотранспортные перевозки собственными дорожными транспортными средствами
 в личных целях;</p>
 
<p style="text-indent:2em;"></p><h6>b) предприятий, осуществляющих деятельность по автотранспортным перевозкам в национальном сообщении с
 использованием только:</h6><p></p>
 
<p>– дорожных транспортных средств, предельная допустимая скорость которых не превышает 40 км/ч;</p><br>

<p style="text-indent:2em;">– дорожных транспортных средств, используемых службами вооруженных сил, службами гражданской защиты, пожарными службами и силами,
 ответственными за поддержание общественного порядка, или арендованных ими без водителя, когда перевозка относится к функциям этих
 служб и осуществляется под их контролем;</p><br>
 
<p style="text-indent:2em;">– дорожных транспортных средств, используемых при чрезвычайных ситуациях или в спасательных операциях, в том числе
 для некоммерческой перевозки гуманитарной помощи;</p><br>
 
<p>– специальных дорожных транспортных средств медицинского назначения;</p><br>

<p style="text-indent:2em;">– специальных аварийно-ремонтных дорожных транспортных средств, действующих в радиусе 100 км от основного
 местонахождения;</p><br>
 
<p style="text-indent:2em;">– транспортных средств для дорожных испытаний в целях технологического улучшения, ремонта или содержания,
 а также новых или переоборудованных дорожных транспортных средств, не введенных еще в эксплуатацию;</p><br>
 
<p style="text-indent:2em;">– транспортных средств или составов транспортных средств, предельная допустимая общая масса которых не
 превышает 3,5 тонны, используемых для автотранспортных перевозок грузов в личных целях;</p><br>
 
<p style="text-indent:2em;">– коммерческих автомобилей исторического характера, используемых для перевозки пассажиров или грузов 
в личных целях;</p><br>

<p style="text-indent:2em;">– транспортных средств сельскохозяйственного назначения и транспортных средств, предназначенных
 для лесного хозяйства.</p>
 
<p style="text-indent:2em;"></p><h6>Ст. 3. – Настоящий кодекс является правовой основой для разработки центральным отраслевым органом 
публичного управления в области автотранспортных перевозок и утверждения Правительством следующих
 нормативных актов, относящихся к деятельности по автотранспортным перевозкам:</h6><p></p>
 
<p>a) Правила автотранспортных перевозок пассажиров и багажа;</p><br>

<p>b) Правила автотранспортных перевозок грузов;</p><br>

<p>c) Правила автотранспортных перевозок скоропортящихся и особо скоропортящихся грузов;</p><br>

<p>d) Правила автотранспортных перевозок опасных грузов;</p><br>

<p style="text-indent:2em;">e) Положение об осуществлении автотранспортных перевозок грузов, вес и/или габариты
 которых превышают установленные пределы, по автомобильным дорогам общего пользования;</p><br>
 
<p>f) Положение об автовокзалах;</p><br>

<p>g) Положение о периодическом техническом осмотре дорожных транспортных средств;</p><br>

<p>h) Положение об осуществлении автосервисной деятельности;</p><br>

<p>i) Правила установки, ремонта и проверки тахографов и ограничителей скорости;</p><br>

<p style="text-indent:2em;">j) Правила выдачи, обмена, замены и обновления тахографических карточек, 
скачивания и хранения данных с тахографов и тахографических карточек;</p>

<p style="text-indent:2em;"></p><h6>Ст. 4. – В целях настоящего кодекса автотранспортные перевозки классифицируются следующим образом:</h6><p></p>

<p style="text-indent:2em;"></p><h6>1) в зависимости от коммерческой классификации деятельности:</h6><p></p>

<p>a) автотранспортные перевозки на платной основе;</p><br>

<p>b) автотранспортные перевозки за собственный счет;</p><br>

<p>c) автотранспортные перевозки в личных целях;</p>

<p style="text-indent:2em;"></p><h6>2) в зависимости от обслуживаемой географической зоны:</h6><p></p>

<p style="text-indent:2em;">а) автотранспортные перевозки в национальном сообщении, в том числе местные, муниципальные, районные и межрайонные;</p><br>

<p>b) международные автотранспортные перевозки;</p><br>

<p>c) приграничные автотранспортные перевозки;</p>

<p style="text-indent:2em;"></p><h6>3) в зависимости от объекта перевозки:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) автотранспортные перевозки грузов:</h6><p></p>

<p>– автотранспортные перевозки общих грузов;</p><br>

<p>– автотранспортные перевозки скоропортящихся и особо скоропортящихся грузов;</p><br>

<p>– автотранспортные перевозки опасных грузов;</p><br>

<p>– автотранспортные перевозки отходов;</p><br>

<p>– автотранспортные перевозки живого скота;</p><br>

<p>– автотранспортные перевозки негабаритных грузов;</p>

<p style="text-indent:2em;"></p><h6>b) автотранспортные перевозки пассажиров:</h6><p></p>

<p>– автотранспортные перевозки пассажиров по регулярным маршрутам;</p><br>

<p>– автотранспортные перевозки пассажиров по специальным регулярным маршрутам;</p><br>

<p>– нерегулярные автотранспортные перевозки пассажиров, в том числе туристические;</p><br>

<p>– таксомоторные автотранспортные перевозки.</p>

<p style="text-indent:2em;"></p><h6>Ст. 5. – (1) В целях настоящего кодекса следующие понятия используются в значении:</h6><p></p>

<p style="text-indent:2em;">сопутствующая автотранспортным перевозкам деятельность – деятельность, 
осуществляемая в связи с автотранспортными перевозками;</p><br>

<p style="text-indent:2em;">деятельность по автотранспортным перевозкам – совокупность транспортных операций, непосредственно обеспечивающих перемещение грузов или пассажиров с использованием
 дорожных транспортных средств на определенные расстояния и на заранее установленных условиях;</p><br>

<p style="text-indent:2em;">таксометр – контрольно-кассовое оборудование с фискальной памятью, с дополнительными функциями учета времени и пройденного расстояния, автоматического расчета
 согласно запрограммированным тарифам и постоянного указания причитающейся к оплате суммы, на которую в конце рейса клиенту выдается кассовый чек на
 бумажном носителе и/или в электронной форме. Порядок выдачи и вручения кассового чека на бумажном носителе и/или в электронной форме утверждается
 Правительством;</p><br>

<p style="text-indent:2em;">представительное профильное профессиональное объединение – общественное объединение, состоящее из представителей юридических лиц,
 осуществляющих деятельность в области автотранспортных перевозок грузов или в области автотранспортных перевозок пассажиров или 
 в обеих областях, члены которого оказали в течение одного года как минимум в одной из указанных областей услуги в объеме более 20
 процентов общего объема подобных услуг по стране;</p><br>

<p style="text-indent:2em;">автобус – дорожное транспортное средство, сконструированное и оборудованное для перевозки пассажиров и используемое в этих целях,
 имеющее более 9 сидячих мест, включая место водителя;</p><br>
 
<p style="text-indent:2em;">легковой автомобиль – дорожное транспортное средство, имеющее не менее четырех колес, с максимальной конструктивной скоростью
 свыше 25 км/ч, сконструированное и оборудованное для перевозки пассажиров, имеющее не более 9 сидячих мест, включая место водителя;</p><br>
 
<p style="text-indent:2em;">разрешение на сопутствующую автотранспортным перевозкам деятельность – документ, удостоверяющий право предприятия осуществлять
 на период действия документа сопутствующую автотранспортным перевозкам деятельность;</p><br>
 
<p style="text-indent:2em;">разрешение на автотранспортные перевозки пассажиров по регулярным маршрутам – документ, удостоверяющий право автотранспортного
 оператора осуществлять на период действия документа автотранспортные перевозки пассажиров на платной основе по регулярным 
 маршрутам по определенному пути следования, согласно установленному графику движения и программе автотранспортных перевозок;</p><br>
 
<p style="text-indent:2em;">разрешение на нерегулярные автотранспортные перевозки пассажиров – документ, удостоверяющий право автотранспортного оператора
 осуществлять нерегулярные автотранспортные перевозки пассажиров на платной основе;</p><br>
 
<p style="text-indent:2em;">разрешение на нелиберализованные нерегулярные автотранспортные перевозки – разрешение на осуществление нелиберализованных
 международных автотранспортных перевозок пассажиров в соответствии с требованиями Соглашения «INTERBUS» и Резолюции ЕКМТ
 95/3;</p><br>
 
<p style="text-indent:2em;">разрешение на международные автотранспортные перевозки – документ, удостоверяющий право автотранспортного оператора осуществлять
 на период действия документа на определенных условиях одну или более транспортных перевозок транзитом или в конечный пункт
 назначения на территории другого государства;</p><br>
 
<p style="text-indent:2em;">двустороннее разрешение – разрешение на автотранспортную перевозку на территорию/с территории государства, выдавшего разрешение,
 с территории/на территорию Республики Молдова;</p><br>
 
<p style="text-indent:2em;">транзитное разрешение – разрешение на транзитный проезд через территорию государства, выдавшего разрешение, на территорию
 другого государства;</p><br>
 
<p style="text-indent:2em;">двустороннее/транзитное разрешение – разрешение на двустороннюю или транзитную автотранспортную перевозку;</p><br>

<p style="text-indent:2em;">двустороннее/транзитное разрешение на перевозку скоропортящихся грузов – разрешение на двустороннюю или транзитную 
автотранспортную перевозку, на основании которого разрешается перевозка из Республики Молдова исключительно
 скоропортящихся продуктов, происходящих из Республики Молдова;</p><br>
 
<p style="text-indent:2em;">разрешение третьей страны – разрешение на автотранспортную перевозку на территорию государства/с территории государства, 
выдавшего разрешение, с территории государства/на территорию государства, иного, чем государство, в котором 
зарегистрировано дорожное транспортное средство;</p><br>

<p style="text-indent:2em;">универсальное разрешение – разрешение на двустороннюю, транзитную автотранспортную перевозку или автотранспортную перевозку
 в третьи страны;</p><br>
 
<p style="text-indent:2em;">однократное разрешение – разрешение, позволяющее в установленный период времени осуществить одну автотранспортную перевозку 
с использованием автотранспортной инфраструктуры государства, выдавшего разрешение;</p><br>

<p style="text-indent:2em;">многократное разрешение – разрешение, позволяющее в установленный период времени осуществить не менее двух 
автотранспортных перевозок с использованием автотранспортной инфраструктуры государства, выдавшего разрешение;</p><br>

<p style="text-indent:2em;">одностороннее разрешение – разрешение на транзитный проезд в один конец через территорию государства, 
выдавшего разрешение;</p><br>

<p style="text-indent:2em;">многостороннее разрешение – разрешение, действительное для автотранспортных перевозок на территорию или с 
территории третьего государства, иного, чем государство, в котором зарегистрировано дорожное транспортное 
средство, а также двусторонних и транзитных международных автотранспортных перевозок по территории государств-
членов, подписавших соответствующее соглашение или конвенцию;</p><br>

<p style="text-indent:2em;">разрешения I категории – разрешения с ограничением по количеству, наличие которых полностью обеспечивает спрос 
на следующие 20 календарных дней; анализ проводится путем экстраполяции данных о выдаче разрешений;</p><br>

<p style="text-indent:2em;">разрешения II категории – разрешения с ограничением по количеству, наличие которых неполностью обеспечивает
 спрос на следующие 20 календарных дней; анализ проводится путем экстраполяции данных о выдаче разрешений;</p><br>
 
<p style="text-indent:2em;">разрешения III категории – разрешения с ограничением по количеству, составляющие до 3 процентов общего количества
 разрешений каждого вида, но не менее одного разрешения, выдаваемые в соответствии с настоящим кодексом;</p><br>
 
<p style="text-indent:2em;">разрешения без ограничения по количеству – разрешения, изготовленные типографским способом по заказу Национального
 агентства автомобильного транспорта на основании анализа существующего спроса, которым присваиваются серия и 
 уникальный порядковый номер;</p><br>
 
<p style="text-indent:2em;">разрешение на въезд без груза – разрешение на въезд порожняком на территорию государства, выдавшего разрешение,
 для загрузки и выезда в государство, в котором зарегистрировано дорожное транспортное средство;</p><br>
 
<p style="text-indent:2em;">разрешение на маятниковые рейсы – разрешение на перевозку групп пассажиров на протяжении нескольких рейсов в 
оба конца, когда первый обратный рейс и последний рейс в пункт назначения осуществляются без пассажиров;</p><br>

<p style="text-indent:2em;">разрешение ОЧЭС – разрешение, действительное для одного рейса в оба конца, во время которого пересекаются транзитом
 территории государств–членов Организации черноморского экономического сотрудничества (ОЧЭС), и/или для двусторонних,
 транзитных автотранспортных перевозок либо перевозок на территорию/с территории третьей страны согласно Руководству
 пользователя разрешений ОЧЭС;</p><br>
 
<p style="text-indent:2em;">разрешение ЕКМТ – многостороннее разрешение на международные автотранспортные перевозки грузов, которое предоставляет
 автотранспортному оператору право на осуществление в течение определенного периода неограниченного количества 
 перевозок между территориями государств-членов ЕКМТ в соответствии с положениями резолюций ЕКМТ, а также право
 на транзит через территорию одного или нескольких государств-членов ЕКМТ;</p><br>
 
<p>годовое разрешение ЕКМТ – разрешение ЕКМТ, действительное в течение календарного года;</p><br>

<p>краткосрочное разрешение ЕКМТ – разрешение ЕКМТ, действительное в течение
 30 календарных дней;</p>

<p style="text-indent:2em;"></p><h6>недействительное разрешение ЕКМТ – разрешение ЕКМТ (годовое или краткосрочное), которое:</h6><p></p>

<p>a) объявлено недействительным в установленном настоящим кодексом порядке;</p><br>

<p>b) было объявлено утерянным, а затем найдено;</p><br>

<p style="text-indent:2em;">c) не содержит обязательных отметок, касающихся наименования и полного адреса автотранспортного оператора, подписи и печати выдавшего разрешение 
органа, даты начала и окончания срока действия разрешения ЕКМТ, даты выдачи разрешения ЕКМТ, ограничений, установленных 
государствами–членами ЕКМТ;</p>

<p style="text-indent:2em;"></p><h6>d) не было выдано согласно порядку и процедурам, установленным настоящим кодексом, или выдано с их нарушением:</h6><p></p>

<p style="text-indent:2em;">разрешение ТРАСЕКА – разрешение, позволяющее осуществить один рейс в оба конца, с грузом или без груза, в двустороннем, 
транзитном режиме либо на территорию/с территории третьей страны по территории государств-участниц Системы 
многосторонних разрешений ТРАСЕКА;</p><br>

<p style="text-indent:2em;">автовокзал – специально отведенное и обустроенное пространство, оборудованное для стоянки автобусов на перронах, посадки или 
высадки пассажиров, а также для предоставления условий и услуг для автобусов и 
ожидающих лиц;</p><br>

<p style="text-indent:2em;">билет – кассовый чек, выданный контрольно-кассовым оборудованием с фискальной памятью на бумажном носителе и/или электронной
 форме, или документ строгой отчетности с указанием фиксированной цены, изготовленный типографским или электронным способом,
 подтверждающий оплату проезда и право лица на проезд, заключение договора о перевозке между 
 автотранспортным оператором/предприятием и лицом, а также факт обязательного страхования 
 лица;</p><br>
 
<p style="text-indent:2em;">багажный билет – кассовый чек, выданный контрольно-кассовым оборудованием с фискальной памятью на бумажном 
носителе и/или электронной форме, или документ строгой отчетности с указанием фиксированной цены, изготовленный
 типографским или электронным способом, подтверждающий оплату перевозки багажа и прием багажа для перевозки,
 а также факт обязательного страхования багажа;</p><br>
 
<p style="text-indent:2em;">билетно-учетная ведомость – документ строгой отчетности, отражающий количество проданных билетов и поступившие
 суммы и содержащий диаграммы регулярных маршрутов;</p>
 
<p style="text-indent:2em;"></p><h6>делимые грузы – материалы навалом, в том числе в виде порошка, гранул или кусков, жидкие и полужидкие 
материалы, имеющие следующее происхождение:</h6><p></p>

<p> полученные в процессе производства и рекуперации черных и цветных металлов;</p><br>

<p> различные отходы строительной деятельности;</p><br>

<p> полученные вследствие эксплуатации леса или складирования древесины;</p><br>

<p> полученные при добыче ископаемых, карьерной выработке, добыче гравия;</p><br>

<p style="text-indent:2em;"> полученные в результате деятельности бетонных станций, асфальтные смеси, строительные добавки и связующие материалы;</p><br>

<p style="text-indent:2em;">каботаж – осуществляемая на платной основе иностранным автотранспортным оператором автотранспортная перевозка грузов
 или пассажиров между по крайней мере одним местом погрузки/посадки и одним местом выгрузки/высадки, по
 обстоятельствам, расположенными на территории Республики Молдова. Перемещение дорожного транспортного
 средства без груза или пассажиров между двумя последовательными операциями по международным
 автотранспортным перевозкам не считается каботажем;</p><br>
 
<p style="text-indent:2em;">бортовой журнал – документ, являющийся неотъемлемой частью разрешения ЕКМТ, заполняемый на имя автотранспортного
 оператора, на страницах которого фиксируются в хронологическом порядке этапы осуществления каждой операции
 по автотранспортной перевозке груза между пунктами погрузки и разгрузки, а также этапы каждой операции по
 автотранспортной перевозке без груза;</p><br>
 
<p style="text-indent:2em;">книжка с путевыми листами (CFP) – документ, в который вписываются фамилии перевозимых лиц, позволяющий
 автотранспортным операторам осуществлять нерегулярные международные автотранспортные перевозки пассажиров
 (для государств, не присоединившихся к Соглашению INTERBUS);</p><br>
 
<p style="text-indent:2em;">книжка INTERBUS – документ, в который вписываются фамилии перевозимых лиц, позволяющий автотранспортным
 операторам осуществлять нерегулярные международные автотранспортные перевозки пассажиров (для
 государств–членов Соглашения INTERBUS);</p><br>
 
<p style="text-indent:2em;">начальный/конечный пункт маршрута – пункт отправления или пункт назначения маршрута, место посадки/высадки
 пассажиров, перевозимых на платной основе по регулярным маршрутам или
 специальным регулярным маршрутам;</p><br>
 
<p style="text-indent:2em;">категория разрешения – разрешения одного и того же вида, выданные одним и тем же государством,
 классифицированные в зависимости от норм Евро в отношении загрязняющих выбросов, установленных
 для дорожных транспортных средств, для которых может использоваться разрешение соответствующей
 категории;</p><br>
 
<p style="text-indent:2em;">сертификат ЕКМТ соответствия автотранспортных средств и прицепов условиям технического контроля
 – документ, подтверждающий соответствие автотранспортных средств и прицепов техническим
 требованиям согласно резолюциям ЕКМТ, выданный для каждого дорожного транспортного 
 средства в отдельности и хранящийся на его борту;</p><br>
 
<p style="text-indent:2em;">сертификат ЕКМТ соответствия прицепа или полуприцепа условиям безопасности – документ,
 подтверждающий соответствие прицепа или полуприцепа требованиям безопасности согласно
 резолюциям ЕКМТ;</p><br>
 
<p style="text-indent:2em;">сертификат соответствия ЕКМТ категории А – документ, подтверждающий соответствие автотранспортного
 средства техническим нормам относительно загрязняющих и звуковых выбросов согласно
 резолюциям ЕКМТ;</p><br>
 
<p style="text-indent:2em;">сертификат соответствия ЕКМТ категории В – документ, подтверждающий соответствие автотранспортного
 средства требованиям безопасности (правилам безопасности) согласно резолюциям ЕКМТ;</p><br>
 
<p style="text-indent:2em;">экипажное вождение – ситуация, когда в течение периода вождения между двумя последовательными
 периодами ежедневного отдыха или между периодом ежедневного отдыха и периодом еженедельного
 отдыха на борту транспортного средства находятся не менее двух водителей автотранспортного
 средства для обеспечения вождения. В течение первого часа экипажного вождения присутствие
 второго или нескольких водителей автотранспортного средства является необязательным, однако
 является обязательным в течение остального периода;</p><br>
 
<p style="text-indent:2em;">Европейская конференция министров транспорта (ЕКМТ) – межправительственная организация,
 созданная 17 октября 1953 года в Брюсселе, к которой Республика Молдова присоединилась
 24 апреля 1996 года;</p><br>
 
<p style="text-indent:2em;">Международный транспортный форум (МТФ) – межправительственная организация, преемник прав
 и обязанностей Европейской конференции министров транспорта, созданная Советом министров
 ЕКМТ в ходе сессии на уровне министров 17–18 мая 2006 года, состоявшейся в Дублине;</p><br>
 
<p style="text-indent:2em;">контингент разрешений ЕКМТ – предоставленное государству-члену ЕКМТ количество разрешений ЕКМТ,
 установленное Группой по автомобильному транспорту и утвержденное министрами государств
 -членов ЕКМТ;</p><br>
 
<p style="text-indent:2em;">критерии МТФ – критерии распределения разрешений ЕКМТ, устанавливаемые ежегодно в ходе
 Международного транспортного форума;</p><br>
 
<p style="text-indent:2em;">рейс – путь следования автобуса в рамках одной операции по перевозке пассажиров из
 пункта отправления до пункта назначения и обратно;</p><br>
 
<p>продолжительность вождения – зарегистрированная деятельность по вождению;</p><br>

<p style="text-indent:2em;">ежедневная продолжительность вождения – общая продолжительность вождения с момента окончания
 периода ежедневного отдыха до начала следующего периода ежедневного отдыха или между
 периодом ежедневного отдыха и периодом еженедельного отдыха;</p><br>
 
<p style="text-indent:2em;">еженедельная продолжительность вождения – общая продолжительность вождения,
 накопившаяся в течение одной недели;</p><br>
 
<p style="text-indent:2em;">путевой лист – документ первичного учета строгой отчетности, имеющий защитные знаки,
 серию и централизованно присвоенный номер, специальной формы в зависимости от вида
 перевозки;</p><br>
 
<p style="text-indent:2em;">график движения – документ, содержащий данные о времени, месте и маршруте, по которому
 осуществляются автотранспортные перевозки пассажиров;</p><br>
 
<p style="text-indent:2em;">путь следования – установленная в графике движения трасса, по которой осуществляется
 автотранспортная перевозка;</p><br>
 
<p style="text-indent:2em;">грубое нарушение – нарушение порядка использования и представления отчета об использовании
 разрешений ЕКМТ, за которое настоящим кодексом предусмотрен отзыв разрешений ЕКМТ;</p><br>
 
<p style="text-indent:2em;">предприятие – любое физическое или юридическое лицо, преследующее цель получения прибыли или
 не преследующее такой цели, выполняющее автотранспортные перевозки пассажиров или сопутствующую
 автотранспортным перевозкам деятельность, либо любое физическое или юридическое лицо,
 выполняющее автотранспортные перевозки грузов в коммерческих целях;</p><br>
 
<p style="text-indent:2em;">контрольный список – документ, содержащий фамилии перевозимых лиц и другие данные, относящиеся
 к осуществлению нерегулярных автотранспортных перевозок в национальном сообщении;</p><br>
 
<p style="text-indent:2em;">менеджер по автотранспортным перевозкам – нанятое предприятием физическое лицо или, в случае
 индивидуального предприятия – соответствующее лицо, которое руководит постоянно и фактически
 деятельностью по автотранспортным перевозкам данного предприятия и которое в совокупности
 отвечает условиям наличия хорошей репутации и профессиональной компетенции;</p><br>
 
<p style="text-indent:2em;">автотранспортный оператор – любое предприятие, осуществляющее автотранспортные перевозки
 на платной основе дорожными транспортными средствами, являющимися собственностью 
 предприятия или приобретенными им в финансовый лизинг либо арендованными, 
 зарегистрированное в установленном порядке;</p><br>
 
<p style="text-indent:2em;">отечественный автотранспортный оператор – автотранспортный оператор с местонахождением или
 местожительством в Республике Молдова;</p><br>
 
<p style="text-indent:2em;">авторизованный автотранспортный оператор – автотранспортный оператор, зарегистрированный 
в установленном порядке в области автотранспортных перевозок на платной основе, или
 хозяйствующий субъект, осуществляющий автотранспортные перевозки за собственный счет;</p><br>
 
<p style="text-indent:2em;">иностранный автотранспортный оператор – автотранспортный оператор с местонахождением или
 местожительством за рубежом, обладающий разрешением на международные автотранспортные
 перевозки;</p><br>
 
<p style="text-indent:2em;">оператор информационной системы «e-Autorizaţie transport» – Национальное агентство 
автомобильного транспорта, которое обеспечивает в соответствии с законом выдачу 
однократных разрешений, распределенных электронным способом;</p><br>

<p style="text-indent:2em;">представительная патронатная организация – общественное объединение, зарегистрированное
 в соответствии с положениями законодательства о патронатах, или соответствующая федерация,
 члены которой осуществляют деятельность преимущественно в области автотранспортных перевозок
 грузов или в области автотранспортных перевозок пассажиров, которые в предыдущем году 
 оказали совместно более 10 процентов общего объема услуг в одной из указанных областей;</p><br>
 
<p style="text-indent:2em;">избранный автопарк – совокупность автотранспортных средств, принадлежащих авторизованному
 автотранспортному оператору, зарегистрированных в информационной системе «e-Autorizaţie
 transport» и соответствующих условиям, предписанным определенным видом разрешения;</p><br>
 
<p style="text-indent:2em;">представительная профсоюзная организация – добровольное объединение работников, 
осуществляющих деятельность в области автотранспортных перевозок пассажиров и/или
 автотранспортных перевозок грузов, созданное в одной из организационных форм,
 предусмотренных законодательством о профсоюзах (первичная организация, объединение,
 федерация, конфедерация), имеющее в своем составе не менее 500 членов – работников
 в области автотранспортных перевозок пассажиров и/или грузов;</p><br>
 
<p style="text-indent:2em;">перерыв – любой период времени, предназначенный исключительно для отдыха, в течение
 которого водитель автотранспортного средства не имеет право осуществлять вождение
 или выполнять другие виды работ;</p><br>
 
<p style="text-indent:2em;">период вождения – совокупная продолжительность вождения с момента, когда водитель
 автотранспортного средства сел за руль после периода отдыха или перерыва, до момента,
 когда начинается период отдыха или перерыва; период вождения может быть непрерывным
 или дробным;</p><br>
 
<p style="text-indent:2em;">период ежедневного отдыха – часть суток, в течение которой водитель автотранспортного
 средства может свободно располагать своим временем; период ежедневного отдыха может 
 быть нормальным или сокращенным;</p><br>
 
<p style="text-indent:2em;">нормальный период ежедневного отдыха – любой период отдыха продолжительностью не менее 
11 часов. Нормальный период ежедневного отдыха может предоставляться двумя частями, из 
которых первая – непрерывный период продолжительностью не менее 3 часов и вторая – 
непрерывный период продолжительностью не менее 9 часов;</p><br>

<p style="text-indent:2em;">сокращенный период ежедневного отдыха – любой период отдыха продолжительностью не
 менее 9 часов, однако менее 11 часов;</p><br>
 
<p style="text-indent:2em;">период еженедельного отдыха – еженедельный период, в течение которого водитель
 автотранспортного средства может свободно располагать своим временем; период
 еженедельного отдыха может быть нормальным или сокращенным;</p><br>
 
<p>&gt;нормальный период еженедельного отдыха – любой период отдыха продолжительностью
 не менее 45 часов;</p><br>
 
<p style="text-indent:2em;">сокращенный период еженедельного отдыха – любой период отдыха продолжительностью
 менее 45 часов, который может быть сокращен до непрерывного периода
 продолжительностью не менее 24 часа;</p><br>
 
<p style="text-indent:2em;">программа автотранспортных перевозок – программа, определяющая необходимые
 маршруты и рейсы, графики движения, начальные/конечные пункты маршрутов, 
 автовокзалы, общественные остановки, а также необходимое количество 
 транспортных средств, их категории комфортности и минимальную вместимость
 в целях осуществления автотранспортной перевозки пассажиров на платной 
 основе по регулярным маршрутам;</p><br>
 
<p style="text-indent:2em;">единая компьютеризированная программа – единая система продажи проездных 
билетов на автотранспортную перевозку пассажиров на платной основе по 
регулярным маршрутам, являющаяся частью системы интегрированного 
менеджмента;</p><br>

<p style="text-indent:2em;">отдых – любой непрерывный период времени, в течение которого водитель 
автотранспортного средства может свободно распоряжаться своим временем;</p><br>

<p style="text-indent:2em;">переоборудование – вид автосервисной деятельности, направленной на изменение
 конструктивных, функциональных или качественных параметров дорожных транспортных
 средств и/или используемых в них деталей по сравнению с освидетельствованным 
 типом, включая деятельность по комплектованию/дооборудованию кузова транспортных
 средств и тюнинг;</p><br>
 
<p style="text-indent:2em;">маршрут – путь следования, который обеспечивает связь между несколькими населенными
 пунктами и/или объектами, находящимися между начальным и конечным пунктами маршрута
 , по которому осуществляется автотранспортная перевозка пассажиров на платной основе
 по регулярным или специальным регулярным маршрутам;</p><br>
 
<p style="text-indent:2em;">накладная CMR – транспортный документ, регулируемый Конвенцией о договоре международной 
перевозки грузов автомобильным транспортом, принятой 19 мая 1956 года в Женеве, к которой
 Республика Молдова присоединилась 2 марта 1993 года;</p><br>
 
<p style="text-indent:2em;">информационная система «e-Autorizaţie transport» – информационная система, позволяющая
 осуществлять запрос и обработку данных, распределение и выдачу разрешений на 
 автотранспортные перевозки;</p><br>
 
<p style="text-indent:2em;">система интегрированного менеджмента – электронная система управления порядком 
осуществления автотранспортных перевозок в соответствии с установленными техническими
 требованиями;</p><br>
 
<p style="text-indent:2em;">электронная система взвешивания в движении – система прошедших поверку измерительных
 установок и приборов, которые установлены или встроены в проезжую часть дороги в 
 указанных центральным отраслевым органом местах, подключены в режиме он-лайн к 
 серверу органа, уполномоченного осуществлять контроль соблюдения предельно 
 допустимой массы дорожных транспортных средств;</p><br>
 
<p style="text-indent:2em;">общественная остановка – пункт на маршруте, обустроенный соответствующим образом,
 обозначенный дорожным знаком в виде панно, на котором вывешен график остановки 
 дорожных транспортных средств для посадки/высадки перевозимых лиц;</p><br>
 
<p style="text-indent:2em;">автотранспортная перевозка – операция по перевозке, осуществляемая дорожными 
транспортными средствами, по перемещению грузов или пассажиров, в том числе 
когда дорожное транспортное средство на определенном участке пути перевозится
 в свою очередь другим транспортным средством или когда дорожные транспортные
 средства перемещаются без груза;</p><br>
 
<p style="text-indent:2em;">незаконная автотранспортная перевозка – операция по автотранспортной перевозке,
осуществляемая физическим или юридическим лицом, не зарегистрированным в установленном настоящим
 кодексом порядке для осуществления автотранспортных перевозок, либо лицом, зарегистрированным в
 Реестре автотранспортных операторов, но не получившим разрешение на автотранспортные перевозки
 пассажиров по регулярным маршрутам, действительное для маршрута, на котором работает, или нерегулярная
 автотранспортная перевозка пассажиров, осуществляемая в отсутствие, по обстоятельствам, книжки INTERBUS
 , разрешения на нелиберализованные нерегулярные автотранспортные перевозки, контрольного списка. 
 Осуществление таксомоторной автотранспортной перевозки пассажиров по установленным таксистом маршрутам, 
 которые выходят за пределы населенного пункта, в котором зарегистрировано предприятие, также считается 
 незаконной автотранспортной перевозкой;</p><br>
 
<p style="text-indent:2em;">автотранспортная перевозка на платной основе – операция по автотранспортной перевозке грузов и/или
 пассажиров, осуществляемая за плату путем взимания тарифа или эквивалента натурой или услугами;</p><br>
 
<p style="text-indent:2em;">автотранспортная перевозка за собственный счет – операция по автотранспортной перевозке, осуществляемая
 физическим или юридическим лицом в некоммерческих целях и без цели получения прибыли;</p><br>
 
<p style="text-indent:2em;">автотранспортная перевозка в личных целях – операция по автотранспортной перевозке, не являющаяся 
автотранспортной перевозкой на платной основе и/или автотранспортной перевозкой за 
собственный счет;</p><br>

<p style="text-indent:2em;">автотранспортная перевозка пассажиров по регулярным маршрутам – услуга по автотранспортной перевозке
 пассажиров на платной основе, обеспечивающая перевозку лиц, на основе индивидуальных проездных билетов,
 согласно графикам движения, по установленным маршрутам, при которой посадка/высадка перевозимых лиц
 осуществляется в предварительно установленных местах остановки;</p><br>
 
<p style="text-indent:2em;">автотранспортная перевозка пассажиров по специальным регулярным маршрутам – услуга по автотранспортной
 перевозке пассажиров на платной основе, осуществляемая по заявке заказчика, когда обеспечивается 
 перевозка только определенных категорий пассажиров между населенными пунктами в той части, в которой
 эта перевозка осуществляется на условиях автотранспортных перевозок пассажиров по регулярным 
 маршрутам;</p><br>
 
<p style="text-indent:2em;">нерегулярная автотранспортная перевозка пассажиров – услуга по автотранспортной перевозке, отличная
 от автотранспортной перевозки пассажиров по регулярным маршрутам и автотранспортной перевозки 
 пассажиров по специальным регулярным маршрутам, осуществляемая на основании договора и 
 характеризующаяся в основном тем, что обеспечивает перевозку групп пассажиров, 
 составленных предварительно по инициативе клиента или автотранспортного оператора;</p><br>
 
<p style="text-indent:2em;">негабаритная автотранспортная перевозка – операция по автотранспортной перевозке, осуществляемая с 
превышением предельно допустимых массы и/или габаритов;</p><br>

<p style="text-indent:2em;">автотранспортная перевозка в национальном сообщении – операция по автотранспортной перевозке,
 осуществляемая между двумя населенными пунктами, расположенными на территории Республики Молдова,
 без выезда за пределы государства;</p><br>
 
<p style="text-indent:2em;">международная автотранспортная перевозка – операция по автотранспортной перевозке, осуществляемая между
 населенным пунктом отправления и населенным пунктом назначения, расположенными на территории двух 
 различных государств, с осуществлением или без осуществления транзита одного или более других 
 государств;</p><br>
 
<p style="text-indent:2em;">автотранспортная перевозка в местном сообщении – операция по автотранспортной перевозке, 
осуществляемая в пределах административно-территориальной зоны одного населенного пункта;</p><br>

<p style="text-indent:2em;">автотранспортная перевозка в районном сообщении – операция по автотранспортной перевозке, 
осуществляемая между двумя населенными пунктами, расположенными на территории одного района,
 без выезда за пределы района;</p><br>
 
<p style="text-indent:2em;">автотранспортная перевозка в межрайонном сообщении – операция по автотранспортной перевозке, 
осуществляемая между двумя населенными пунктами, расположенными на территории двух различных 
районов, в том числе между муниципием и районом, с осуществлением или без осуществления транзита
 одного или более других районов/муниципиев;</p><br>
 
<p style="text-indent:2em;">автотранспортная перевозка в муниципальном сообщении – операция по автотранспортной перевозке,
 осуществляемая между двумя населенными пунктами, расположенными в пределах административно-
 территориальной зоны муниципия;</p><br>
 
<p style="text-indent:2em;">автотранспортная перевозка в приграничном сообщении – операция по автотранспортной перевозке, 
осуществляемая из любого населенного пункта страны до населенного пункта, расположенного на 
территории соседнего государства на расстоянии не более 50 км от границы, если двусторонними 
соглашениями не предусмотрено иное;</p><br>

<p style="text-indent:2em;">автотранспортная перевозка посылок – операция по автотранспортной перевозке посылок 
дорожными транспортными средствами;</p><br>

<p style="text-indent:2em;">перевозка электрическим транспортом – услуга по автотранспортной перевозке пассажиров, 
осуществляемая безрельсовым транспортным средством с электротягой;</p><br>

<p style="text-indent:2em;">либерализованные перевозки – категория автотранспортных перевозок, осуществление 
которых не предполагает наличие разрешения, однако не освобождает от уплаты других 
сборов, связанных с данной категорией автотранспортных перевозок;</p><br>

<p style="text-indent:2em;">повторяющиеся перевозки – автотранспортные перевозки, осуществляемые исключительно 
между двумя третьими государствами вне государства регистрации дорожного транспортного 
средства;</p>

<p style="text-indent:2em;"></p><h6>дорожное транспортное средство – самоходная или несамоходная механическая система, 
предназначенная для передвижения по автомобильным дорогам общего пользования. В целях 
настоящего кодекса автотранспортные средства, прицепы, полуприцепы и тягачи считаются 
дорожными транспортными средствами и определяются следующим образом:</h6><p></p>

<p style="text-indent:2em;">a) автотранспортное средство – самоходное дорожное транспортное средство на колесной,
 гусеничной или скользящей подвеске, предназначенное для автотранспортной перевозки 
 пассажиров или грузов либо для производства работ;</p><br>
 
<p style="text-indent:2em;">b) прицеп – дорожное транспортное средство, не оборудованное двигателем, спроектированное
 и сконструированное для его буксировки автотранспортным средством;</p><br>
 
<p style="text-indent:2em;">c) полуприцеп – буксируемое дорожное транспортное средство, спроектированное для сцепления
 с тягачом или с буксируемой рамой таким образом, чтобы значительная вертикальная нагрузка
 приходилась на тягач или буксируемую раму;</p><br>
 
<p style="text-indent:2em;">d) тягач – дорожное транспортное средство, спроектированное и сконструированное исключительно
 или в основном для тяги прицепов/полуприцепов.</p><br>
 
<p style="text-indent:2em;">(2) В целях настоящего кодекса состав автотранспортных средств, состоящий из автотранспортного
 средства и прицепа/полуприцепа, и состав автотранспортных средств, состоящий из тягача и 
 прицепа/полуприцепа, считаются дорожными транспортными средствами.</p>

<p></p><h4>Раздел II
УПРАВЛЕНИЕ АВТОТРАНСПОРТНЫМИ ПЕРЕВОЗКАМИ</h4><p></p>

<p style="font-weight:500;">Ст. 6. – (1) Управление автотранспортными перевозками осуществляется путем государственного
 управления и менеджмента автотранспортных операторов, предприятий, осуществляющих сопутствующую
 автотранспортным перевозкам деятельность, а также профильных профессиональных объединений,
 представительных патронатных и представительных профсоюзных организаций в данной области.</p>
 
<p style="text-indent:2em;"></p><h6>(2) Управление автотранспортными перевозками осуществляется с соблюдением следующих принципов:</h6><p></p>

<p>a) поощрение лояльной конкуренции между автотранспортными операторами;</p><br>

<p style="text-indent:2em;">b) гарантия равноправного и недискриминационного доступа автотранспортных операторов к рынку
 автотранспортных услуг;</p><br>
 
<p>c) гарантия соблюдения прав и интересов потребителей автотранспортных услуг;</p><br>

<p>d) решение экономических, социальных и природоохранных проблем населенных пунктов;</p><br>

<p style="text-indent:2em;">e) эффективное управление имуществом, принадлежащим автотранспортным системам, являющимся
 собственностью административно-территориальных единиц;</p><br>
 
<p style="text-indent:2em;">f) эффективное использование публичных фондов в деятельности по управлению или выполнению
 автотранспортных перевозок в местном сообщении;</p><br>
 
<p style="text-indent:2em;">g) передвижение в условиях безопасности и комфорта, в том числе путем страхования
 рисков перевозки грузов и пассажиров;</p><br>
 
<p>h) финансовая самостоятельность или независимость автотранспортных операторов;</p><br>

<p style="text-indent:2em;">i) поддержка экономического развития населенных пунктов путем установления современной
 транспортной инфраструктуры;</p><br>
 
<p style="text-indent:2em;">j) удовлетворение потребностей населения и хозяйствующих субъектов в перемещении по
 территории административно-территориальных единиц посредством предоставления
 качественных услуг в соответствии с нормативными документами;</p><br>
 
<p style="text-indent:2em;">k) равноправный и свободный доступ лиц к приобретению проездных билетов на услуги по 
автотранспортным перевозкам пассажиров по регулярным маршрутам;</p><br>

<p style="text-indent:2em;">l) консультирование с представительными профильными профессиональными объединениями и
 представительными патронатными организациями автотранспортных операторов, а также 
 потребителями услуг по автотранспортным перевозкам в целях разработки политик и 
 стратегий развития автотранспортных перевозок и сопутствующей деятельности;</p><br>
 
<p style="text-indent:2em;">m) внедрение информационных технологий в процессы организации и контроля деятельности по 
автотранспортным перевозкам и/или сопутствующей автотранспортным перевозкам деятельности
 в целях повышения эффективности управления в данной области и сведения к минимуму участия
 человеческого фактора.</p><br>
 
<p style="text-indent:2em;">(3) Публичное управление в области автотранспортных перевозок осуществляется центральными 
отраслевыми органами публичного управления в области транспорта, местными органами публичного
 управления и другими уполномоченными законом органами в пределах компетенции.</p><br>
 
<p style="text-indent:2em;">(4) Органом, который разрабатывает и продвигает документы публичных политик Правительства и 
национальные стратегии развития в области автотранспортных перевозок, является созданное в 
соответствии с законом Министерство экономики и инфраструктуры (далее – центральный 
отраслевой орган).</p><br>

<p style="text-indent:2em;">(5) Ежегодно до 31 марта приказом министра экономики и инфраструктуры создается Консультативный
 совет в области автотранспортных перевозок, в состав которого входят по одному делегированному
 представителю от каждой представительной патронатной организации, по одному делегированному 
 представителю от каждой представительной профсоюзной организации в данной области, по одному 
 сотруднику центрального отраслевого органа и Национального агентства автомобильного транспорта.
 Председатель и секретарь Консультативного совета избираются на заседании совета из числа 
 его членов.</p><br>
 
<p style="text-indent:2em;">(6) Центральный отраслевой орган консультируется с Консультативным советом в целях разработки 
или принятия актов, касающихся политик в области автотранспортных перевозок.</p>

<p style="text-indent:2em;"></p><h6>Ст. 7. – (1) Центральный отраслевой орган имеет следующие основные полномочия в области 
автотранспортных перевозок:</h6><p></p>

<p style="text-indent:2em;">a) разрабатывает, продвигает и мониторирует политику государства и национальные стратегии развития
 в области автотранспортных перевозок;</p><br>
 
<p style="text-indent:2em;">b) разрабатывает программы устойчивого развития и модернизации национальной системы 
автотранспортных перевозок, безопасности автотранспортных перевозок и охраны окружающей 
среды и мониторирует их реализацию;</p><br>

<p style="text-indent:2em;">c) разрабатывает проекты законодательных и нормативных актов в области автотранспортных
 перевозок и сопутствующей автотранспортным перевозкам деятельности;</p><br>
 
<p style="text-indent:2em;">d) обеспечивает мониторинг соблюдения национальных и международных регулирующих документов
 в области автотранспортных перевозок;</p><br>
 
<p style="text-indent:2em;">e) обеспечивает разработку специализированных исследований в области автотранспортных
 перевозок, необходимых для разработки публичных политик;</p><br>
 
<p style="text-indent:2em;">f) инициирует и ведет от имени Правительства переговоры по заключению международных 
договоров и представляет Правительству в установленном порядке предложения по присоединению
 Республики Молдова к международным договорам, двусторонним или многосторонним соглашениям 
 по областям компетенции или денонсированию таковых;</p><br>
 
<p>g) мониторирует внедрение положений международных договоров в области автотранспортных
 перевозок;</p><br>
 
<p style="text-indent:2em;">h) представляет интересы государства и осуществляет право членства в международных 
организациях на основании конвенций и соглашений, стороной которых является Республика 
Молдова, и развивает отношения сотрудничества с аналогичными органами и организациями других
 государств и заинтересованными международными организациями;</p><br>
 
<p style="text-indent:2em;">i) организует создание, осуществление и документирование совместных комиссий и рабочих 
групп, деятельность которых регулируется двусторонними и многосторонними соглашениями в 
области автотранспортных перевозок;</p><br>

<p style="text-indent:2em;">j) создает комиссию по распределению многосторонних разрешений Европейской конференции 
министров транспорта (ЕКМТ) и утверждает протоколы заседаний комиссии;</p><br>

<p style="text-indent:2em;">k) управляет государственной собственностью в области автотранспортных перевозок, 
находящейся в его ведении.</p>

<p style="text-indent:2em;"></p><h6>(2) Центральный отраслевой орган имеет следующие специальные полномочия в области 
автотранспортных перевозок:</h6><p></p>

<p style="text-indent:2em;">a) проверяет реализацию принципов формирования и изменения местных, муниципальных 
и районных программ автотранспортных перевозок;</p><br>

<p style="text-indent:2em;">b) разрабатывает и утверждает межрайонную программу автотранспортных перевозок и 
международную программу автотранспортных перевозок;</p><br>

<p style="text-indent:2em;">c) выдает в 20-дневный срок заключения по предложенным изменениям в районных 
программах автотранспортных перевозок в целях обеспечения их согласования с межрайонными
 программами автотранспортных перевозок;</p><br>
 
<p style="text-indent:2em;">d) выдает разрешения на деятельность по международным автотранспортным перевозкам пассажиров
 на платной основе по регулярным маршрутам согласно положениям действующего законодательства,
 международных соглашений и конвенций, стороной которых является Республика Молдова;</p><br>
 
<p style="text-indent:2em;">e) обеспечивает реализацию системы сбора данных и информации об автотранспортных перевозках 
грузов и пассажиров;</p><br>

<p style="text-indent:2em;">f) разрабатывает и утверждает обязательные минимальные требования к программам 
автотранспортных перевозок.</p>

<p style="text-indent:2em;"></p><h6>(3) Центральный отраслевой орган вправе:</h6><p></p>

<p style="text-indent:2em;">a) делегировать при необходимости некоторые функции подведомственным административным 
органам, за исключением функций по проведению государственной политики, а также публичным 
учреждениям, в которых выступает в качестве учредителя;</p><br>

<p>b) вводить временные ограничения на изменение программ автотранспортных перевозок.</p><br>

<p style="font-weight:500;">Ст. 8. – (1) Органом, который обеспечивает внедрение документов публичных политик и 
национальных стратегий развития в области автотранспортных перевозок, осуществляет 
контроль и надзор за соблюдением автотранспортными операторами и предприятиями, 
осуществляющими сопутствующую автотранспортным перевозкам деятельность, 
национального и международного законодательства в данной области, является 
Национальное агентство автомобильного транспорта (далее – Агентство).</p><br>

<p style="text-indent:2em;">(2) Агентство является административным органом, подчиненным центральному отраслевому 
органу. Положение об организации и функционировании Агентства, а также его структура и
 предельная численность утверждаются Правительством.</p><br>
 
<p style="text-indent:2em;">(3) Бюджет Агентства разрабатывается, утверждается и администрируется в соответствии с 
принципами, правилами и процедурами, установленными Законом о публичных финансах и бюджетно-
налоговой ответственности № 181/2014. Сборы за выдачу разрешений на автотранспортные перевозки
 в национальном и международном сообщении перечисляются хозяйствующими субъектами в установленном
 Министерством финансов порядке в государственный бюджет.</p>
 
<p style="text-indent:2em;"></p><h6>Ст. 9. – (1) Основными функциями Агентства являются:</h6><p></p>

<p style="text-indent:2em;">a) надзор и контроль за соблюдением отечественными и иностранными автотранспортными операторами
 на территории Республики Молдова положений национального законодательства и международных 
 соглашений в области автотранспортных перевозок, стороной которых является Республика 
 Молдова;</p><br>
 
<p style="text-indent:2em;">b) выдача разрешений на деятельность по автотранспортным перевозкам или сопутствующую 
автотранспортным перевозкам деятельность, предусмотренных настоящим кодексом.</p>

<p style="text-indent:2em;"></p><h6>(2) Агентство имеет следующие основные полномочия:</h6><p></p>

<p style="text-indent:2em;">a) наложение правонарушительных санкций в пределах своей компетенции согласно 
положениям законодательства;</p><br>

<p style="text-indent:2em;">b) осуществление мониторинга правильности применения тарифов на услуги по автотранспортным
 перевозкам пассажиров по регулярным маршрутам в национальном сообщении и в деятельности
 автовокзалов;</p><br>
 
<p style="text-indent:2em;">c) внедрение процесса заказа, получения, оформления и выдачи многосторонних разрешений
 ЕКМТ, используемых отечественными транспортными операторами, и составление отчетов для
 их представления Секретариату ЕКМТ;</p><br>
 
<p style="text-indent:2em;">d) внедрение процесса издания единых международных разрешений и осуществления обмена 
ими с зарубежными странами в соответствии с двусторонними соглашениями, а также их 
выдача автотранспортным операторам Республики Молдова;</p><br>

<p style="text-indent:2em;">e) проверка и систематизация информации об использовании автотранспортными операторами 
единых международных разрешений;</p><br>

<p style="text-indent:2em;">f) допуск отечественных автотранспортных операторов к осуществлению международных 
нерегулярных автотранспортных перевозок пассажиров согласно требованиям Соглашения 
о международных нерегулярных перевозках пассажиров автобусами (Соглашение INTERBUS), 
выдача книжек INTERBUS и ведение соответствующего реестра;</p><br>

<p style="text-indent:2em;">g) выдача книжек с путевыми листами (CFP) для зарубежных стран, не присоединившихся 
к Соглашению INTERBUS;</p><br>

<p>h) участие в работе совместных комиссий в пределах компетенции;</p><br>

<p>i) внедрение положений протоколов заседаний совместных комиссий и мониторирование
 этого процесса;</p><br>
 
<p>k) осуществление деятельности по аудиту дорожной безопасности.</p>

<p style="text-indent:2em;"></p><h6>Ст. 10. – (1) Агентство в пределах своей компетенции имеет право:</h6><p></p>

<p style="text-indent:2em;">a) выдавать, приостанавливать или отзывать, частично или полностью,
 разрешения в предусмотренном законом порядке;</p><br>
 
<p style="text-indent:2em;">b) иметь доступ к документам, относящимся к деятельности в области 
автотранспортных перевозок, предусмотренным статьей 48, обеспечивая 
их конфиденциальность, в соответствии с действующим законодательством;</p><br>

<p style="text-indent:2em;">c) запрашивать и получать от автотранспортных операторов и/или от предприятий,
 осуществляющих сопутствующую автотранспортным перевозкам деятельность, 
 информацию, необходимую для выполнения своих полномочий;</p><br>
 
<p style="text-indent:2em;">d) требовать в соответствии с настоящим кодексом от автотранспортных операторов 
и/или от предприятий, осуществляющих сопутствующую автотранспортным перевозкам 
деятельность, исполнения своих решений;</p><br>

<p>e) издавать предписания по устранению выявленных в ходе проверок нарушений.</p><br>

<p style="text-indent:2em;">(2) Запрос Агентством информации и документов, указанных в части (1), должен 
быть мотивированным и осуществляться в рамках полномочий, предоставленных Агентству
 в соответствии с действующим законодательством.</p><br>
 
<p style="text-indent:2em;">(3) Агентство сотрудничает с аналогичными органами других государств, в том 
числе путем заключения двусторонних соглашений о сотрудничестве, обмена опытом
 и профильной информацией.</p><br>
 
<p style="text-indent:2em;">(4) В целях выполнения положений настоящего кодекса Агентство сотрудничает 
с органами по защите конкуренции и защите прав потребителей, в частности, путем
 взаимного предоставления информации, необходимой для применения положений законодательства 
 о защите конкуренции и законодательства о защите прав потребителей в области автотранспортных 
 перевозок грузов и пассажиров, а также сопутствующей автотранспортным 
 перевозкам деятельности в целях соблюдения хозяйствующими 
 субъектами нормативных документов, а также сотрудничает с
 другими центральными и местными органами публичной власти.</p><br>
 
<p style="text-indent:2em;">(5) Агентство вправе оказывать услуги. Методика расчета тарифов на услуги,
 оказываемые Агентством, номенклатура услуг и размер тарифов на оказываемые
 им услуги утверждаются Правительством.</p><br>
 
<p style="font-weight:500;">Ст. 11. – (1) Агентством руководит директор, имеющий двух заместителей.</p><br>

<p style="text-indent:2em;">(6) Директор и заместители директора Агентства назначаются на государственную должность
 и освобождаются от государственной должности или увольняются с нее в соответствии 
 с законом министром.</p><br>
 
<p style="text-indent:2em;">(7) В Агентстве работают государственные служащие в соответствии с Законом о государственной
 должности и статусе государственного служащего № 158/2008 и другие категории работников, 
 деятельность которых регулируется нормативной базой в области труда.</p><br>
 
<p style="text-indent:2em;">(9) Ежегодно до 30 марта Агентство представляет центральному отраслевому органу отчет 
о своей деятельности, который публикуется в Официальном мониторе Республики Молдова.</p><br>

<p style="font-weight:500;">Ст. 12. – (1) В целях осуществления функций, предусмотренных настоящим кодексом, местные,
 муниципальные и районные советы создают в соответствии с действующим законодательством 
 комиссии по организации автотранспортных перевозок пассажиров по регулярным маршрутам
 и утверждают положения о функционировании этих комиссий в соответствии с положениями 
 настоящего кодекса и других нормативных актов. Принятие решений об организации 
 автотранспортных перевозок пассажиров по регулярным маршрутам может быть отнесено 
 к обязанностям соответствующих комиссий в соответствии с положениями об их 
 функционировании или эти решения утверждаются комиссиями в зависимости от 
 полномочий, делегированных соответствующим комиссиям советами.</p><br>
 
<p style="text-indent:2em;">(2) Органы местного публичного управления разрабатывают и утверждают после согласования 
с центральным отраслевым органом среднесрочные и долгосрочные стратегии развития и
 модернизации автотранспортных перевозок с учетом планов градостроительства и 
 обустройства территорий, программ социально-экономического развития населенных 
 пунктов и потребностей населения в перевозках.</p><br>
 
<p style="text-indent:2em;">(3) Районные советы сотрудничают с местными и муниципальными советами для 
обеспечения и развития автотранспортных перевозок пассажиров на платной основе
 по регулярным маршрутам в районном сообщении и их стыковку с аналогичными 
 автотранспортными перевозками пассажиров на платной основе по регулярным 
 маршрутам в местном/муниципальном сообщении.</p><br>
 
<p style="text-indent:2em;">(4) Местные, муниципальные и районные советы обязаны обеспечивать равноправное
 и недискриминационное отношение, на основе конкуренции и прозрачности, ко 
 всем автотранспортным операторам.</p>
 
<p style="text-indent:2em;"></p><h6>Ст. 13. – Районные советы в связи с необходимостью организации автотранспортных
 перевозок пассажиров на платной основе по регулярным маршрутам в районном 
 сообщении:</h6><p></p>
 
<p style="text-indent:2em;">a) утверждают и обновляют районные программы автотранспортных перевозок после 
получения положительного заключения центрального отраслевого органа в соответствии
 с потребностями населения в автотранспортных перевозках;</p><br>
 
<p style="text-indent:2em;">b) имеют право предоставлять в пределах соответствующих административно-территориальной
 единиц транспортные льготы определенным категориям лиц, обеспечивая при этом
 источники компенсации выпадающих доходов;</p><br>
 
<p style="text-indent:2em;">c) выдают разрешения на автотранспортные перевозки пассажиров на платной основе
 по регулярным маршрутам в районном сообщении и мониторируют осуществление
 данных перевозок.</p>
 
<p style="text-indent:2em;"></p><h6>Ст. 14. – Местные и муниципальные советы в связи с необходимостью организации
 автотранспортных перевозок пассажиров на платной основе по регулярным маршрутам
 в местном сообщении:</h6><p></p>
 
<p style="text-indent:2em;">a) имеют право устанавливать субсидии из местного и/или муниципального бюджета,
 по обстоятельствам, для покрытия разницы между заявленными автотранспортным 
 оператором затратами на услуги по автотранспортным перевозкам пассажиров по 
 регулярным маршрутам в местном сообщении и суммами, действительно поступившими
 после осуществления перевозок;</p><br>
 
<p style="text-indent:2em;">b) имеют право предоставлять в пределах соответствующих административно-территориальных
 единиц транспортные льготы определенным категориям лиц, обеспечивая при этом источники
 компенсации выпадающих доходов;</p><br>
 
<p style="text-indent:2em;">c) разрабатывают, утверждают и обновляют согласно компетенции местные программы
 автотранспортных перевозок с учетом потребностей населения в автотранспортных
 перевозках;</p><br>
 
<p style="text-indent:2em;">d) выдают разрешения на автотранспортные перевозки пассажиров на платной основе
 по регулярным маршрутам в местном и муниципальном сообщении, по обстоятельствам,
 и мониторируют осуществление данных перевозок;</p><br>
 
<p>e) организуют перевозку электрическим транспортом;</p><br>

<p>f) разрабатывают и утверждают планы городской мобильности;</p><br>

<p style="text-indent:2em;">g) утверждают тарифы на автотранспортные перевозки пассажиров на платной основе по 
регулярным маршрутам в местном и/или муниципальном сообщении.</p>

<p style="text-indent:2em;"></p><h6>Ст. 15. – Органы местного публичного управления в отношениях с автотранспортными 
операторами, осуществляющими автотранспортные перевозки пассажиров на платной основе
 в местном, муниципальном или районном сообщении, обладают следующими 
 компетенциями:</h6><p></p>
 
<p style="text-indent:2em;">a) организуют в соответствии с действующим законодательством совещания с 
автотранспортными операторами с целью определения необходимых мер для 
разрешения возникающих проблем;</p><br>

<p style="text-indent:2em;">b) периодически проверяют порядок осуществления автотранспортных перевозок 
пассажиров на платной основе по регулярным маршрутам в местном, муниципальном 
или районном сообщении, согласно компетенции;</p><br>

<p>c) при установлении нарушений информируют Агентство в целях отзыва выданных 
разрешений;</p><br>

<p style="text-indent:2em;">d) направляют компетентным органам обоснованные запросы об аннулировании 
регистрационного, разрешительного или уведомительного режима.</p>

<p></p><h4>Раздел III
ДОПУСК К ДЕЯТЕЛЬНОСТИ ПО
АВТОТРАНСПОРТНЫМ ПЕРЕВОЗКАМ</h4><p></p>

<p></p><h4>Глава 1. Автотранспортные перевозки
на платной основе</h4><p></p>

<p style="text-indent:2em;"></p><h6>Ст. 16. – Предприятие, зарегистрированное в установленном порядке в Республике
 Молдова, намеренное осуществлять деятельность автотранспортного оператора, 
 включается в Реестр автотранспортных операторов если отвечает в совокупности 
 следующим условиям:</h6><p></p>
 
<p>a) наличие материально-технической базы;</p><br>

<p>b) наличие хорошей репутации;</p><br>

<p>c) наличие финансовой состоятельности;</p><br>

<p>d) наличие профессиональной компетенции.</p>

<p style="text-indent:2em;"></p><h6>Ст. 17. – Условие наличия материально-технической базы считается выполненным,
 если предприятие отвечает в совокупности следующим требованиям:</h6><p></p>
 
<p style="text-indent:2em;">a) имеет офисное помещение, в котором осуществляются технологические операции,
 осуществляется руководство транспортной деятельностью, хранятся рабочие документы, 
 в частности бухгалтерские документы, документы по управлению персоналом, а также 
 документы, предусмотренные статьей 48, к которым должен быть обеспечен доступ 
 органов контроля для проверки соблюдения действующего законодательства. 
 Предприятие может иметь несколько филиалов, в которых могут храниться документы
 об операционной и технологической деятельности за текущий и предыдущий кварталы.
 Агентство должно быть уведомлено о каждом из этих филиалов не позднее чем в течение
 десяти дней со дня начала деятельности в помещении филиала, с указанием почтового
 адреса и видов деятельности, которые будут осуществляться и согласовываться из 
 этого филиала, а также графика работы;</p><br>
 
<p>b) владеет хотя бы одним дорожным транспортным средством;</p><br>

<p style="text-indent:2em;">c) обладает на праве собственности или на условиях финансового лизинга не менее
 чем одной третью дорожных транспортных средств, которыми располагает предприятие,
 однако не менее одной единицы;</p><br>
 
<p style="text-indent:2em;">d) осуществляет техническое обслуживание дорожных транспортных средств, которыми обладает, 
постоянно и эффективно, с использованием соответствующего оборудования и технических установок
 в автосервисной мастерской, в соответствии с настоящим кодексом и Положением об осуществлении
 автосервисной деятельности.</p>
 
<p style="text-indent:2em;"></p><h6>Ст. 18. – (1) Условие наличия хорошей репутации предприятия считается выполненным, если менеджер
 автотранспортных перевозок или предприятие отвечает в совокупности следующим требованиям:</h6><p></p>
 
<p style="text-indent:2em;">a) не привлекался/не привлекалось к уголовной ответственности окончательным и вступившим в
 законную силу судебным решением за экономические преступления и/или преступления против
 собственности, за торговлю людьми и/или наркотическими или психотропными веществами, а
 также за преступления в области автотранспортных перевозок;</p><br>
 
<p style="text-indent:2em;">b) не привлекался/не привлекалось к правонарушительной ответственности трижды в течение
 12 последовательных месяцев за любое из правонарушений, предусмотренных частью (3);</p><br>
 
<p style="text-indent:2em;">c) не был лишен/не было лишено права заниматься определенной деятельностью или занимать 
определенные должности согласно окончательному судебному решению.</p><br>

<p style="text-indent:2em;">(2) Условие хорошей репутации считается невыполненным до истечения срока давности привлечения
 к ответственности за правонарушение, погашения или снятия судимости.</p>
 
<p style="text-indent:2em;"></p><h6>(3) В целях настоящего кодекса считаются правонарушениями, обусловливающими невыполнение 
условия наличия хорошей репутации в области автотранспортных перевозок, следующие 
нарушения:</h6><p></p>

<p style="text-indent:2em;">a) превышение на 25 процентов или более максимальных пределов шестидневного или 
двухнедельного периода вождения;</p><br>

<p style="text-indent:2em;">b) превышение на протяжении рабочего дня максимальных пределов периода вождения на 50 
процентов или более, без перерыва или без непрерывного периода отдыха не менее 4,5 
часов;</p><br>

<p style="text-indent:2em;">c) отсутствие тахографа и/или ограничителя скорости или использование мошеннического оборудования,
 способного изменить данные регистрирующей аппаратуры и/или прибора ограничителя скорости, либо 
 фальсификация тахографических карточек или показателей тахографов и/или тахографической 
 карточки водителя;</p><br>
 
<p style="text-indent:2em;">d) перевозка опасных грузов, перевозка которых запрещена или перевозка которых осуществляется в 
условиях запрещенной или неутвержденной изоляции или без обозначения перевозящего транспортного 
средства соответствующим знаком опасного груза, создавая непосредственную угрозу безопасности 
дорожного движения;</p><br>

<p style="text-indent:2em;">e) осуществление перевозки дорожным транспортным средством, имеющим серьезные повреждения тормозной 
системы, рулевого механизма, колес/шин, подвески или шасси, могущие создать непосредственную угрозу 
безопасности дорожного движения, выявленные в соответствующем порядке на станции периодического 
технического осмотра;</p><br>

<p style="text-indent:2em;">f) перевозка пассажиров или грузов предприятием в отсутствие разрешений и документов, обязательных
 для выполняемого вида перевозки;</p><br>
 
<p style="text-indent:2em;">g) перевозка грузов с превышением хотя бы на 20 процентов общего предельного разрешенного веса для
 автомобилей с полезной допустимой нагрузкой, превышающей 12 тонн, или хотя бы на 25 процентов для
 автомобилей с полезной допустимой нагрузкой, не превышающей 12 тонн.</p><br>
 
<p style="font-weight:500;">Ст.19. – (1) Условие наличия финансовой состоятельности считается выполненным, если предприятие в
 любой момент текущего финансового года может выполнить свои финансовые обязательства.</p>
 
<p style="text-indent:2em;"></p><h6>(2) Для автотранспортных операторов международных перевозок условие наличия финансовой состоятельности
 считается выполненным, если операторы могут доказать уполномоченным контрольным органам по запросу 
 таковых, что располагают уставным и дополнительным капиталом, финансовыми резервами и совокупностью
 иммобилизованных активов в размере:</h6><p></p>
 
<p style="text-indent:2em;">a) как минимум 9000 евро на первое дорожное транспортное средство и как минимум 5000 евро на каждое
 последующее дорожное транспортное средство, на которые запрашивается регистрация в установленном 
 порядке, подтвержденная выпиской из Реестра автотранспортных операторов, – для автотранспортных 
 операторов, осуществляющих международные автотранспортные перевозки грузов с использованием 
 многосторонних разрешений ЕКМТ и международные нерегулярные автотранспортные перевозки 
 пассажиров на основании книжек INTERBUS.</p><br>
 
<p style="text-indent:2em;">b) как минимум 2000 евро на первое дорожное транспортное средство и как минимум 1000 евро на
 каждое последующее дорожное транспортное средство, на которые запрашивается регистрация в 
 установленном порядке, подтвержденная выпиской из Реестра автотранспортных операторов,  –
 для автотранспортных операторов международных перевозок, за исключением предусмотренных 
 пунктом a).</p><br>
 
<p style="text-indent:2em;">(3) Для автотранспортных операторов международных перевозок условие наличия финансовой 
состоятельности доказывается на основании годовых отчетов или последнего бухгалтерского
 баланса, зарегистрированного в органах статистики, либо на основании сертификата, выданного
 одним или несколькими банками или финансовыми учреждениями, в том числе страховыми обществами,
 предоставляющими за данное предприятие личную и солидарную гарантию сумм, установленных в 
 пунктах a) и b) части (2).</p><br>
 
<p style="text-indent:2em;">(4) Эквивалент указанных в пунктах a) и b) части (2) сумм в национальной валюте устанавливается
 ежегодно по валютному курсу, опубликованному Национальным банком Молдовы в первый рабочий день
 октября. Этот курс применяется на протяжении следующего календарного года с 1 января.</p><br>
 
<p style="font-weight:500;">Ст. 20. – (1) Условие наличия профессиональной компетенции предприятия считается выполненным,
 если менеджер предприятия по автотранспортным перевозкам обладает сертификатом профессиональной
 компетенции, выданным в соответствии со статьей 43.</p><br>
 
<p style="text-indent:2em;">(2) Сертификат профессиональной компетенции является подтверждением установленного и необходимого
 уровня знаний и выдается после сдачи обязательного письменного экзамена.</p><br>
 
<p style="font-weight:500;">Ст. 21. – (1) Предприятие, которое намеревается осуществлять деятельность автотранспортного оператора,
 уведомляет Агентство о назначении хотя бы одного физического лица на должность менеджера по 
 автотранспортным перевозкам, отвечающего требованиям наличия профессиональной компетенции и 
 хорошей репутации.</p><br>
 
<p style="text-indent:2em;">(2) Менеджер по автотранспортным перевозкам должен фактически и постоянно руководить автотранспортной
 деятельностью предприятия, быть работником предприятия и получать заработную плату на
 этом предприятии.</p><br>
 
<p style="text-indent:2em;">(3) При выполнении своих функций менеджер по автотранспортным перевозкам обязан и несет ответственность
 за обеспечение соблюдения действующего законодательства при осуществлении операций по 
 автотранспортным перевозкам.</p><br>
 
<p style="text-indent:2em;">(4) В случае, когда лицо, назначенное на должность менеджера по автотранспортным перевозкам,
 не исполняет более эту должность или не отвечает более условиям наличия профессиональной компетенции
 и хорошей репутации, предприятие обязано найти ему замену в течение 30 дней. В этот период условия
 наличия профессиональной компетенции и хорошей репутации 
 считаются выполненными.</p>

<p></p><h4>Глава 2
ОФИЦИАЛЬНАЯ РЕГИСТРАЦИЯ АВТОТРАНСПОРТНЫХ ОПЕРАТОРОВ</h4><p></p>

<p style="font-weight:500;">Ст. 22. – (1) Деятельность по автотранспортным перевозкам пассажиров на платной основе осуществляется при
 условии уведомления и предварительной регистрации.</p><br>
 
<p style="text-indent:2em;">(2) Автотранспортные операторы вправе приступить к деятельности по автотранспортным перевозкам по 
истечении 10 рабочих дней со дня уведомления.</p><br>

<p style="font-weight:500;">Ст.23. – (1) Официальная регистрация автотранспортного оператора осуществляется на основе уведомления, 
представленного Агентству автотранспортным оператором не позднее 10 рабочих дней до начала деятельности
 по автотранспортным перевозкам. Уведомление должно содержать идентификационные данные оператора, вид 
 автотранспортных перевозок, которые он намерен осуществлять. Посредством уведомления автотранспортный 
 оператор заявляет под собственную ответственность о соблюдении всех требований, установленных настоящим 
 кодексом для осуществления указанного вида автотранспортных перевозок, и, при необходимости, требует 
 проведения Агентством внезапного контроля для удостоверения заявленных возможностей и соответствия 
 установленным требованиям. Способ и форма уведомления устанавливаются Правительством с соблюдением 
 положений настоящего кодекса.</p>
 
<p style="text-indent:2em;"></p><h6>(2) К уведомлению прилагаются оригиналы или заверенные подписью автотранспортного оператора копии 
(с последующим предъявлением оригиналов для сверки) следующих документов:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) документы лица, назначенного менеджером по автотранспортным перевозкам, а именно:</h6><p></p>

<p style="text-indent:2em;"> индивидуальный трудовой договор/документ, из которого следует, что указанное лицо назначено 
на должность менеджера по автотранспортным перевозкам;</p><br>

<p> действительный сертификат профессиональной компетенции;</p><br>

<p style="text-indent:2em;"> декларация под собственную ответственность о выполнении условия наличия хорошей репутации
 согласно статье 18;</p><br>
 
<p style="text-indent:2em;">b) декларация под собственную ответственность о выполнении условия финансовой состоятельности, в 
применимых случаях, – для дорожных транспортных средств, на которые запрашивается выписка из 
Реестра автотранспортных операторов;</p><br>

<p style="text-indent:2em;">c) документ о праве собственности или договор найма помещения, служащего постоянно действующим офисом,
 согласно пункту a) статьи 17;</p><br>
 
<p style="text-indent:2em;">d) свидетельства о регистрации дорожных транспортных средств, принадлежащих предприятию на правах 
собственности, имущественного найма или финансового лизинга и используемых им, на которые запрашивается
 выписка из Реестра автотранспортных операторов.</p><br>
 
<p style="text-indent:2em;">(3) Агентство обязано принять и зарегистрировать уведомление, незамедлительно выдав подтверждение 
получения уведомления или завизировав второй экземпляр уведомления подписью представителя Агентства,
 ответственного за прием уведомлений, с указанием даты. При высокой степени риска автотранспортного 
 оператора или в случае представления недостоверной информации, требующей дополнительной проверки, 
 Агентство в срок, не превышающий 10 рабочих дней, вправе осуществить контрольное посещение в соответствии
 с Законом о государственном контроле предпринимательской деятельности № 131/2012, по завершении которого 
 выдает протокол контроля, с подтверждением соответствия требованиям настоящего кодекса или с предписанием
 устранения выявленных несоответствий.</p><br>
 
<p style="text-indent:2em;">(4) Сообщение недостоверных или неполных данных влечет признание уведомления недействительным и, 
соответственно, признание деятельности по автотранспортным перевозкам незаконной. В случае признания 
недействительными уведомления или последующей регистрации Агентство в течение пяти рабочих дней с момента
 установления факта недействительности информирует об этом в письменной форме физическое или юридическое 
 лицо, подавшее уведомление.</p><br>
 
<p style="text-indent:2em;">(5) Уведомление считается выполненным со дня подтверждения его Агентством. В течение 10 рабочих дней с
 момента уведомления Агентство вносит лицо, подавшее уведомление, в Реестр автотранспортных операторов 
 и извещает его о факте регистрации.</p><br>
 
<p style="text-indent:2em;">(6) В случае если Агентство не инициировало контроль по факту уведомления и/или не выдало протокол 
контроля в течение 10 рабочих дней со дня подачи уведомления, вступает в силу принцип молчаливого согласия,
 а экземпляр уведомления с подтверждением получения уведомления до даты выдачи Агентством в установленном 
 порядке протокола контроля или выписки из Реестра заменяет собой протокол контроля или выписку
 из Реестра.</p><br>
 
<p style="text-indent:2em;">(7) По требованию зарегистрированного лица Агентство обязано предоставить бесплатно в письменной форме
 в течение трех рабочих дней со дня получения соответствующего запроса выписку из Реестра автотранспортных
 операторов и по одному экземпляру на каждое дорожное транспортное средство, указанное в уведомлении. 
 Заявки на получение выписок из Реестра передаются как первичным уведомлением, так и при информировании
 о последующих изменениях.</p><br>
 
<p style="text-indent:2em;">(7,1) В случае услуг по таксомоторной автотранспортной перевозке пассажиров выписка из Реестра автотранспортных
 операторов предприятию выдается бесплатно не позднее трех рабочих дней после получения соответствующего запроса.
 В случае транспортных средств, указанных в уведомлении, выписки выдаются после их оснащения в соответствии с 
 положениями статьи 81.</p><br>
 
<p style="text-indent:2em;">(8) После начала осуществления деятельности автотранспортные операторы обязаны информировать Агентство о 
любых изменениях данных, содержащихся в уведомлении или в сопутствующих документах, не позднее 10 дней со 
дня возникновения изменений.</p><br>

<p>дополнен согласно ЗП272 от 29.11.18 МО 462-466 от 12.12.18 ст. 776</p><br>

<p style="font-weight:500;">Ст.25. – (1) Регистрация автотранспортных операторов осуществляется на неограниченный период, без 
взимания платы и с условием постоянного соблюдения требований настоящего кодекса.</p><br>

<p style="text-indent:2em;">(2) В регистрацию вносятся изменения в соответствии с информацией, передаваемой оператором
 соответствующим уведомлением.</p><br>
 
<p style="text-indent:2em;">(3) Для того чтобы использовать другие автотранспортные средства помимо исходно указанных, а также
 для получения предусмотренных настоящим кодексом разрешений на автотранспортные перевозки оператор
 обязан зарегистрировать автотранспортные средства и прицепы, которые он предполагает дополнительно
 использовать.</p><br>
 
<p style="text-indent:2em;">(4) Для регистрации в Реестре автотранспортных операторов, в том числе в информационной системе 
«e-Autorizaţie transport» автотранспортного средства, прицепа или автобуса, которые автотранспортный
 оператор предполагает дополнительно использовать, к соответствующему уведомлению прилагается 
 свидетельство о регистрации дорожного транспортного средства (оригинал или заверенная подписью 
 автотранспортного оператора копия).</p>
 
<p style="text-indent:2em;"></p><h6>(5) Если оператор намеревается запросить разрешения на автотранспортные перевозки, в дополнение 
к предусмотренным частью (4) необходимо представить следующие документы:</h6><p></p>

<p style="text-indent:2em;">a) сертификаты ЕКМТ соответствия категорий A и B в оригинале или в виде заверенной подписью 
автотранспортного оператора копии;</p><br>

<p style="text-indent:2em;">b) сертификат ЕКМТ соответствия автотранспортного средства и прицепа условиям технического контроля
 в оригинале или в виде заверенных подписью автотранспортного оператора копий;</p><br>
 
<p style="text-indent:2em;">c) для прицепов – сертификат ЕКМТ соответствия автотранспортного средства и прицепа условиям 
технического контроля и сертификат ЕКМТ соответствия прицепа или полуприцепа условиям
 безопасности.</p><br>
 
<p style="font-weight:500;">Ст.26. – Утеря или повреждение выписки из Реестра автотранспортных операторов не влечет утраты
 права, вытекающего из уведомления и подтвержденного официальной регистрацией. Для осуществления
 деятельности по автотранспортным перевозкам, подлежащей уведомлению и регистрации, наличие подтверждений
 на бумажном носителе необязательно, факт уведомления может быть заявлен автотранспортным оператором в
 свободной форме.</p>
 
<p style="text-indent:2em;"></p><h6>Ст. 27. – (1) Официальная регистрация предоставляет ее обладателю право осуществлять без получения 
дополнительного разрешения следующие виды деятельности по автотранспортным перевозкам в национальном
 сообщении:</h6><p></p>
 
<p>a) автотранспортные перевозки грузов на платной основе;</p><br>

<p>b) нерегулярные автотранспортные перевозки пассажиров на платной основе;</p><br>

<p>c) таксомоторные автотранспортные перевозки пассажиров на платной основе.</p><br>

<p style="text-indent:2em;">В то же время обладатель лицензии может требовать предоставления права на деятельность по 
автотранспортным перевозкам пассажиров по регулярным маршрутам с условием получения 
разрешения.</p>

<p style="text-indent:2em;"></p><h6>(2) Официальная регистрация предоставляет ее обладателю право осуществлять без получения 
дополнительного разрешения, если двусторонними и/или многосторонними международными договорами 
и соглашениями не предусмотрено иное, следующие виды деятельности по международным 
автотранспортным перевозкам:</h6><p></p>

<p>a) автотранспортные перевозки грузов на платной основе;</p><br>

<p>b) нерегулярные автотранспортные перевозки пассажиров на платной основе;</p><br>

<p>c) таксомоторные автотранспортные перевозки пассажиров;</p><br>

<p>d) перевозка посылок.</p><br>

<p style="text-indent:2em;">В то же время обладатель лицензии может требовать предоставления права на деятельность по автотранспортным перевозкам
пассажиров по регулярным маршрутам с условием получения разрешения.</p><br>
 
<p style="text-indent:2em;">(3) Официальная регистрация предоставляет ее обладателю право осуществлять автотранспортные
перевозки за собственный счет.</p><br>
 
<p style="font-weight:500;">Ст.28. – (1) Приостановление регистрации или исключение из Реестра автотранспортных операторов
 инициируется Агентством в соответствии с Законом об основных принципах регулирования
 предпринимательской деятельности № 235/2006 согласно процедуре, применяемой в случае
 приостановления или отзыва разрешительных документов. Приостановление регистрации
 автотранспортных операторов или их исключение из Реестра инициируется или применяется
 Агентством в рамках государственного контроля в качестве ограничительной меры в порядке
 и пределах, установленных Законом о государственном контроле предпринимательской деятельности
 № 131/2012.</p><br>
 
<p style="text-indent:2em;">(2) По просьбе автотранспортного оператора или в случае его исключения Агентством государственных
 услуг, а также в случае подтверждения недействительности уведомления Национальное агентство 
 автомобильного транспорта приостанавливает регистрацию автотранспортного оператора или исключает
 его из Реестра автотранспортных операторов без обращения в судебную инстанцию.</p><br>
 
<p style="text-indent:2em;">(3) Агентство обязано в течение трех рабочих дней проинформировать в письменной форме физическое
 или юридическое лицо о приостановлении регистрации в Реестре автотранспортных операторов или 
 об исключении из него.</p>
 
<p style="text-indent:2em;"></p><h6>(4) В случаях, отличных от предусмотренных частью (2), исключение из Реестра может применяться в
 соответствии с частью (1), только если автотранспортный оператор совершил грубые нарушения
 обязательств, прямо установленных законодательством, и эти нарушения не поддаются устранению
 в короткий срок без прекращения или приостановления деятельности и влекут или могут повлечь
 причинение значительного, прямого, неминуемого и непосредственного ущерба другим хозяйствующим
 субъектам или потребителям. Приостановление регистрации или исключение из Реестра может
 осуществляться в прямо установленных настоящим кодексом случаях, а также в дополнительных
 следующих случаях:</h6><p></p>

<p style="text-indent:2em;">a) установление окончательным судебным решением в соответствии с законодательством факта 
вовлечения автотранспортного оператора/предприятия в организацию и осуществление контрабандной
деятельности;</p><br>

<p style="text-indent:2em;">b) совершение в течение 12 последовательных месяцев установленных предписаниями Агентства
более пяти нарушений правил перевозок посылок;</p><br>

<p style="text-indent:2em;">c) совершение в течение 12 последовательных месяцев установленных предписаниями
Агентства более пяти нарушений положений статей 81, 82 и 84.</p><br>

<p style="text-indent:2em;">(5) У автотранспортных операторов, исключенных из Реестра автотранспортных операторов,
отзываются также разрешения, выданные в соответствии с процедурой выдачи разрешений.
Эти операторы не могут включаться в Реестр в течение шести месяцев с момента 
исключения.</p><br>

<p style="font-weight:500;">Ст.29. – В случае установления, что условие финансовой состоятельности в применимых случаях
более не выполняется в отношении всех автотранспортных средств, на которые выданы выписки из
Реестра автотранспортных операторов, автотранспортный оператор обязан не позднее чем в
30-дневный срок со дня констатации этого возвратить Агентству выписки из Реестра в количестве
, равном количеству автотранспортных средств, в отношении которых это условие более не
выполняется. В противном случае Агентство инициирует процедуру приостановления регистрации
в соответствии с Законом об основных принципах регулирования предпринимательской деятельности
№ 235/2006.</p>

<p style="text-indent:2em;"></p><h6>Ст. 30. – (1) Если установлено, что одно или более требований, предусмотренных статьей 16
настоящего кодекса, более не выполняются, Агентство издает регулятивные предписания с
установлением срока для исправления ситуации в следующем порядке:</h6><p></p>

<p style="text-indent:2em;">a) срок до двух месяцев – для найма нового менеджера по автотранспортным перевозкам в
случае смерти или физической неспособности менеджера по автотранспортным перевозкам или
в случае, когда менеджер по автотранспортным перевозкам более не отвечает условиям наличия
хорошей репутации или профессиональной компетенции;</p><br>

<p style="text-indent:2em;">b) срок не более двух месяцев – для предоставления предприятием доказательств наличия
постоянно действующего офиса;</p><br>

<p style="text-indent:2em;">c) срок не более двух месяцев – в случае невыполнения условия наличия финансовой
состоятельности для того, чтобы доказать, что соответствующее условие будет снова 
выполняться постоянно.</p><br>

<p style="text-indent:2em;">Максимальный срок, предоставляемый для устранения нарушений, не может превышать в
совокупности шесть месяцев.</p><br>

<p style="text-indent:2em;">(2) В течение сроков, указанных в части (1), автотранспортный оператор обязан выполнять
деятельность в соответствии с действующим законодательством.</p><br>

<p style="text-indent:2em;">(3) В случае установления нарушений условий осуществления деятельности, предусмотренных 
настоящим кодексом, и невозможности предоставления нового срока для их устранения Агентство
инициирует процедуру приостановления регистрации в порядке, установленном статьей 28.</p><br>

<p style="text-indent:2em;">(4) В период приостановления регистрации автотранспортный оператор не вправе осуществлять
деятельность по автотранспортным перевозкам.</p><br>

<p style="font-weight:500;">Ст.31. – (1) Агентство дополняет и обновляет Реестр автотранспортных операторов (далее – Реестр),
который является открытым и доступ к которому могут получить и ознакомиться с ним в электронной 
форме и бесплатно все автотранспортные операторы, а также органы публичной 
власти.</p><br>

<p style="text-indent:2em;">(2) Реестр создается и ведется в электронной форме на базе информационной системы «e-Autorizaţie
transport».</p><br>

<p style="text-indent:2em;">(3) В целях гарантирования конфиденциальности персональных данных в рамках осуществления деятельности
по приему уведомлений, ведению Реестра и обеспечению доступа к нему Агентство утверждает 
организационно-технические меры по защите персональных данных в соответствии с положениями 
Закона о защите персональных данных № 133/2011.</p>

<p></p><h4>Глава 2.1 ПОРЯДОК ВЫДАЧИ РАЗРЕШЕНИЙ НА АВТОТРАНСПОРТНЫЕ ПЕРЕВОЗКИ</h4><p></p>

<p style="font-weight:500;">Ст.31.1. – Разрешения на автотранспортные перевозки выдаются посредством информационной
системы «e-Autorizaţie transport» автотранспортным операторам, зарегистрированным в ней,
за установленную плату и в пределах имеющегося количества разрешений, с соблюдением
установленных принципов распределения.</p>

<p style="text-indent:2em;"></p><h6>Ст.31.2. – Разрешения на автотранспортные перевозки выдаются:</h6><p></p>

<p style="text-indent:2em;">a) автотранспортным операторам, внесенным в Реестр, осуществляющим операции 
по автотранспортным перевозкам на территории другого государства дорожными 
транспортными средствами, зарегистрированными в Республике Молдова;</p><br>

<p style="text-indent:2em;">b) автотранспортным операторам, осуществляющим автотранспортные перевозки
пассажиров на территории Республики Молдова;</p><br>

<p>c) физическим лицам – в соответствующих случаях.</p><br>

<p style="font-weight:500;">Ст.313. – Агентство обеспечивает посредством информационной системы «e-Autorizaţie 
transport» учет бланков разрешений на автотранспортные перевозки.</p><br>

<p style="font-weight:500;">Ст.314. – Разрешения на международные автотранспортные перевозки грузов могут предписывать
ограничения по использованию дорожных транспортных средств, не соответствующих 
определенным экологическим нормам.</p><br>

<p style="font-weight:500;">Ст.315. – Разрешения, предписывающие экологические ограничения, могут использоваться 
и для дорожных транспортных средств более высокой экологической
категории.</p>

<p></p><h4>Часть 1</h4><p></p>

<p>Администрирование информационной
системы «e-Autorizaţie transport»</p>

<p style="text-indent:2em;"></p><h6>Ст.316. – Для того чтобы пользоваться услугами, предоставляемыми Агентством,
автотранспортные операторы, а также физические лица должны пройти следующие
этапы:</h6><p></p>

<p style="text-indent:2em;">a) регистрация автотранспортных операторов в Реестре на базе информационной
системы «e-Autorizaţie transport»;</p><br>

<p style="text-indent:2em;">b) регистрация дорожного транспортного средства в Реестре на базе информационной
системы «e-Autorizaţie transport»;</p><br>

<p>c) подача заявки с указанием вида и количества запрашиваемых разрешений;</p><br>

<p style="text-indent:2em;">d) внесение платы за запрашиваемые разрешения в течение 72 часов с момента
уведомления о принятии решения о предоставлении разрешений;</p><br>

<p>e) возврат разрешений, в том числе ранее выданных Агентством;</p><br>

<p style="text-indent:2em;">f) явка в единое окно Агентства для получения запрошенных разрешений в течение пяти рабочих дней
с момента внесения платы за них.</p><br>

<p style="font-weight:500;">Ст.317. – (1) Разрешения с ограничением по количеству Агентство получает на основе взаимности 
в соответствии с международными соглашениями, одной из сторон которых является Республика 
Молдова.</p><br>

<p style="text-indent:2em;">(2) Бланки разрешений без ограничений по количеству печатаются Агентством на основании изучения
заявок, поступивших в течение предшествующих периодов.</p><br>

<p style="font-weight:500;">Ст.318. – Полученные Агентством разрешения с ограничением по количеству регистрируются в течение
24 часов в информационной системе «e-Autorizaţie transport» в целях обеспечения их электронного
распределения.</p><br>

<p style="font-weight:500;">Ст.319. – До 15 декабря каждого календарного года решением центрального отраслевого органа 
на основании изучения заявок за период отчетного года утверждается перечень разрешений II
категории на очередной год, а также перечень разрешений, подлежащих возврату Агентству с 
приложением накладной CMR. Перечень разрешений II категории изменяется (путем дополнения 
или исключения) в случае, когда количество разрешений I категории составляет 15 
процентов.</p>

<p style="text-indent:2em;"></p><h6>Ст.3110. – Разрешения III категории выдаются в следующих случаях:</h6><p></p>

<p style="text-indent:2em;">a) при предъявлении автотранспортным оператором накладной CMR в открытой форме на 
автотранспортные перевозки, для которых запрашивается определенный вид 
разрешений;</p><br>

<p style="text-indent:2em;">b) при импорте дорожных транспортных средств с представлением документов, подтверждающих
покупку (счет-фактура/договор купли-продажи, платежное поручение), в том числе 
физическими лицами;</p><br>

<p style="text-indent:2em;">c) с целью возврата в Республику Молдова дорожных транспортных средств, заблокированных
на территории других государств, при истечении срока сертификата ЕКМТ;</p><br>

<p style="text-indent:2em;">d) в случае необоснованной остановки дорожных транспортных средств на территории других
государств контролирующими органами, после установления обстоятельств на основании
подтвердительных документов, в том числе в случае физических лиц.</p>

<p style="text-indent:2em;"></p><h6>Ст.3111. – Управление информацией в информационной системе «e-Autorizaţie transport»
осуществляется уполномоченным Агентством лицом, ответственным за:</h6><p></p>

<p>a) утверждение регистрации автотранспортных операторов и физических
лиц в системе;</p><br>

<p>b) утверждение регистрации дорожных транспортных средств в системе;</p><br>

<p>c) изменение данных в системе;</p><br>

<p>d) исключение автотранспортных операторов и дорожных транспортных средств.</p><br>

<p style="font-weight:500;">Ст.3112. – (1) Обработка персональных данных при выдаче и использовании разрешений на
автотранспортные перевозки осуществляется в соответствии с положениями законодательства,
регулирующего защиту персональных данных.</p><br>

<p style="text-indent:2em;">(2) При возникновении инцидентов безопасности в информационных системах учета администратор
системы принимает меры по выявлению источника возникновения инцидента, анализирует и
устраняет причины инцидента безопасности с информированием в течение 24 часов с момента
возникновения инцидента безопасности Национального центра по защите персональных данных.
В то же время в ходе проверок, проводимых Национальным центром по защите персональных
данных, ответственные лица обязаны оказывать необходимую поддержку и предоставлять доступ
к соответствующей предмету проверки информации.</p>

<p></p><h4>Часть 2</h4><p></p>

<p style="text-indent:2em;">Однократные и многократные разрешения на международные
автотранспортные перевозки грузов</p><br>

<p style="font-weight:500;">Ст.3113. – При подаче заявления на получение однократных и многократных разрешений на международные
автотранспортные перевозки грузов определяются экологические категории и категории безопасности
внесенных в Реестр дорожных транспортных средств автотранспортных операторов.</p><br>

<p style="font-weight:500;">Ст.3114. – Определенного вида и определенной категории разрешения на международные автотранспортные
перевозки грузов распределяются автотранспортным операторам пропорционально избранному
автопарку каждого из них.</p><br>

<p style="font-weight:500;">Ст.3115. – (1) Дорожные транспортные средства, для которых запрашиваются разрешения на
международные автотранспортные перевозки грузов, должны иметь сертификат ЕКМТ соответствия
автотранспортных средств и прицепов условиям технического контроля, за исключением тягачей
категории Евро 0, Евро 1, и действительный отчет о периодическом техническом осмотре дорожного
транспортного средства.</p><br>

<p style="text-indent:2em;">(2) При подаче запроса на получение однократных разрешений, действующих на территории 
государств–членов ЕКМТ, избранный автопарк автотранспортных операторов, которым были
распределены разрешения ЕКМТ, уменьшается на количество автотранспортных средств,
эквивалентное количеству полученных разрешений ЕКМТ, действующих на территории соответствующих
государств. Это положение не применяется в случае запроса на выдачу однократных разрешений
для государств, для которых разрешения ЕКМТ предусматривают определенные ограничения,
в том числе для транзитных государств.</p>

<p style="text-indent:2em;"></p><h6>Ст.3116. – (1) Для каждого соответствующего условиям автотранспортного средства выдается не
более двух разрешений того же вида на международные автотранспортные перевозки грузов для
каждого государства, за исключением:</h6><p></p>

<p style="text-indent:2em;">a) разрешений третьей страны, которые выдаются по одному разрешению для каждого 
автотранспортного средства;</p>

<p style="text-indent:2em;"></p><h6>b) разрешений II категории, которые выдаются следующим образом:</h6><p></p>

<p style="text-indent:2em;">одно разрешение – для автотранспортных операторов, обладающих 1–3 автотранспортными
средствами;</p><br>

<p style="text-indent:2em;">по одному разрешению на каждые 3 автотранспортные средства, но не более 15 разрешений
для автотранспортных операторов, обладающих более чем 3 автотранспортными
средствами.</p><br>

<p style="text-indent:2em;">(2) Разрешения третьей страны для России, Греции и Турции выдаются только для автотранспортных средств,
соответствующих техническим нормам и нормам безопасности Eвро III, IV, V, VI, EEV или более,
общей массой более 12 тонн.</p><br>

<p style="text-indent:2em;">(3) Разрешения II категории, а также двусторонние/транзитные разрешения для Италии выдаются для всех
автотранспортных средств и прицепов грузоподъемностью более 3,5 тонны или общей массой более 
6 тонн.</p><br>

<p style="font-weight:500;">Ст.3117. – (1) Агентство обнародует на внешнем портале информационной системы «e-Autorizaţie 
transport» имеющееся по состоянию на текущий день количество разрешений на международные 
автотранспортные перевозки грузов по государствам и видам, а также перечень выданных 
разрешений с указанием наименования автотранспортных операторов, которым выданы 
разрешения.</p><br>

<p style="text-indent:2em;">(2) Автотранспортный оператор, зарегистрированный в информационной системе «e-Autorizaţie 
transport», на основе личных идентификационных данных в любой момент получает доступ на
ее внешнем портале к базе данных о собственном автопарке, доступных разрешениях, поданных
заявках и сроках их действия, а также обо всех выданных разрешениях.</p><br>

<p style="font-weight:500;">Ст.3118. – (1) Заявки на получение разрешений на международные автотранспортные перевозки
грузов автоматически регистрируются в информационной системе «e-Autorizaţie transport» в
хронологическом порядке, с указанием даты и времени принятия.</p><br>

<p style="text-indent:2em;">(2) Для получения разрешений III категории автотранспортный оператор подает в соответствии
со статьей 316 заявку о выдаче разрешений в единое окно Агентства.</p><br>

<p style="font-weight:500;">Ст.3119. – (1) Разрешения на международные автотранспортные перевозки грузов выдаются с
указанием наименования автотранспортного оператора и подлежат использованию
исключительно им.</p><br>

<p style="text-indent:2em;">(2) В двусторонних/транзитных разрешениях для Италии указываются наименование автотранспортного
оператора, а также регистрационный номер автотранспортного средства.</p><br>

<p style="text-indent:2em;">(3) Разрешения на международные автотранспортные перевозки грузов, которые выдаются с
указанием регистрационного номера автотранспортного средства, подлежат использованию
для соответствующих автотранспортных средств или – с согласия Агентства – для некоторых
автотранспортных средств подобной или более высокой категории того же автотранспортного
оператора.</p><br>

<p style="text-indent:2em;">(4) При необходимости изменения регистрационного номера автотранспортного средства
в разрешении на международные автотранспортные перевозки грузов автотранспортный
оператор подает в единое окно Агентства заявление о внесении изменений.</p><br>

<p style="text-indent:2em;">(5) Если заявление об изменении регистрационного номера принято до 12 часов 00
минут, решение об утверждении или отклонении изменений выносится в тот же день. 
Если заявление принято после 12 часов 00 минут, решение выносится не позднее 11 
часов 00 минут следующего рабочего дня.</p><br>

<p style="font-weight:500;">Ст.3120. – В случае утери, кражи или уничтожения разрешения на международные
автотранспортные перевозки грузов автотранспортный оператор в десятидневный 
срок письменно уведомляет об этом Агентство, с последующим представлением в 20
-дневный срок соответствующих доказательств, выданных компетентными органами (в
случае документов, выданных иностранными компетентными органами, представляется
также перевод документа на румынский язык, выполненный авторизованным переводчиком)
. До момента предъявления доказательств разрешения остаются зарегистрированными
на имя автотранспортного оператора.</p><br>

<p style="font-weight:500;">Ст.3121. – Автотранспортный оператор обязан приступить к использованию разрешения
на международные автотранспортные перевозки грузов в течение 30 дней с момента
его выдачи.</p><br>

<p style="font-weight:500;">Ст.3122. – Разрешения на международные автотранспортные перевозки грузов подлежат
возврату Агентству в течение 90 дней с момента их выдачи, а к видам разрешений,
установленным согласно статье 319 решением центрального отраслевого органа, 
прилагаются также подтвердительные документы (копия накладной CMR).</p><br>

<p style="font-weight:500;">Ст.3123. – (1) Запрещается использование разрешений на международные автотранспортные
перевозки грузов для осуществления иного вида перевозок, кроме предусмотренных 
в разрешении.</p><br>

<p style="text-indent:2em;">(2) Запрещается использование разрешений, объявленных утерянными, украденными,
уничтоженными, а также подложных разрешений.</p><br>

<p style="text-indent:2em;">(3) Запрещается использование разрешений с повреждениями или изменениями,
произведенными автотранспортным оператором.</p>

<p style="text-indent:2em;"></p><h6>Ст.3124. – (1) За передачу однократного или многократного разрешения на
автотранспортные перевозки грузов другому автотранспортному оператору
задействованным в этом автотранспортным операторам с момента констатации
нарушения приостанавливается регистрация в информационной системе «e
-Autorizaţie transport» в следующем порядке:</h6><p></p>

<p>a) за первое нарушение (одно разрешение) – на срок 1 месяц;</p><br>

<p style="text-indent:2em;">b) за повторные нарушения в течение одного и того же календарного года
– на срок 3 месяца.</p>

<p style="text-indent:2em;"></p><h6>(2) За использование без согласия Агентства разрешений на международные
автотранспортные перевозки грузов, которые выданы с указанием 
регистрационного номера дорожного транспортного средства, для 
автотранспортного средства, иного чем предусмотренное в разрешении, 
автотранспортному оператору с момента констатации нарушения приостанавливается
регистрация в информационной системе «e-Autorizaţie transport» на
использование данного вида разрешений в следующем порядке:</h6><p></p>

<p>a) за первое нарушение – на срок 1 месяц;</p><br>

<p>b) за повторные нарушения – на срок 3 месяца.</p>

<p style="text-indent:2em;"></p><h6>(3) За использование разрешений на международные автотранспортные
перевозки грузов для осуществления иного вида перевозок, кроме
предусмотренных разрешением, в отношении автотранспортного оператора
применяются с момента констатации нарушения следующие санкции:</h6><p></p>

<p>a) за первое нарушение – предупреждение;</p><br>

<p style="text-indent:2em;">b) за второе нарушение – приостановление регистрации в информационной
системе «e-Autorizaţie transport» на срок 1 месяц;</p><br>

<p style="text-indent:2em;">c) за последующие нарушения – приостановление регистрации в информационной
системе «e-Autorizaţie transport» на срок 3 месяца.</p>

<p style="text-indent:2em;"></p><h6>(4) В случае повторного уведомления об утере, краже, уничтожении однократных
и/или многократных разрешений на международные автотранспортные перевозки
грузов или использования утерянных, украденных, уничтоженных, поврежденных
и/или измененных разрешений, а также в случае их невозврата в установленные
сроки или их использования после истечения установленного срока рассчитанный
при распределении избранный автопарк автотранспортного оператора на следующие
12 месяцев с момента констатации уменьшается для данного вида разрешений в 
следующем порядке:</h6><p></p>

<p>a) за второе нарушение – на 5 процентов количества дорожных транспортных
средств;</p><br>

<p>b) за третье нарушение – на 10 процентов количества дорожных транспортных
средств;</p><br>

<p>c) за четвертое нарушение – на 20 процентов количества дорожных транспортных
средств;</p><br>

<p>d) за пятое нарушение – на 30 процентов количества дорожных транспортных
средств.</p><br>

<p style="text-indent:2em;">(5) В случае невозврата в установленные сроки однократных и/или многократных
разрешений на международные автотранспортные перевозки грузов автотранспортному
оператору не выдаются разрешения того же вида.</p><br>

<p style="text-indent:2em;">(6) В случае использования подложных однократных и/или многократных разрешений на
международные автотранспортные перевозки грузов автотранспортному оператору приостанавливается
регистрация в информационной системе «e-Autorizaţie transport» на срок один месяц с момента
констатации.</p><br>

<p style="text-indent:2em;">(7) Для вида разрешений на международные автотранспортные перевозки грузов с исчерпанным 
на момент выявления нарушения запасом меры наказания применяются с момента пополнения 
запаса этим видом разрешений.</p><br>

<p style="text-indent:2em;">(8) Не допускается возврат разрешений на международные однократные и/или многократные
автотранспортные перевозки грузов, не использованных в течение срока их действия.</p><br>

<p style="font-weight:500;">Ст.3125. – (1) Меры наказания, которые влекут приостановление действия или отзыв 
выданного разрешения, применяются судебной инстанцией в соответствии с положениями
Закона о регулировании предпринимательской деятельности путем разрешения № 160/2011.</p><br>

<p style="text-indent:2em;">(2) Меры наказания, которые не влекут приостановление действия или отзыв имеющегося
разрешения или официальной регистрации, применяются Агентством. В случае несогласия
с применяемой мерой наказания автотранспортный оператор вправе обжаловать ее в
судебном порядке в соответствии с процедурами, установленными действующим 
законодательством.</p><br>

<p style="text-indent:2em;">(3) Приостановление регистрации в Реестре и в информационной системе 
«e-Autorizaţie transport» инициируется и применяется в соответствии 
со статьей 28.</p>

<p></p><h4>Часть 3</h4><p></p>

<p>Разрешения ЕКМТ</p><br>

<p style="font-weight:500;">Ст.3126. – (1) Разрешения ЕКМТ присваиваются Республике Молдова ежегодно согласно критериям МТФ
в целях упрощения международных автотранспортных перевозок грузов и более эффективного 
использования автотранспортных средств.</p><br>

<p style="text-indent:2em;">(2) Разрешения ЕКМТ могут использоваться только для въезда в государство-член ЕКМТ/выезда 
из государства-члена ЕКМТ и/или пересечения их транзитом в рамках международной 
автотранспортной перевозки грузов.</p><br>

<p style="text-indent:2em;">(3) В период действия разрешения ЕКМТ автотранспортный оператор вправе осуществить неограниченное
количество рейсов, соблюдая условие относительно того, чтобы количество последовательных рейсов 
с грузом за пределы Республики Молдова не превышало трех, если резолюцией ЕКМТ не предусмотрено
иное.</p><br>

<p style="text-indent:2em;">(4) После осуществления автотранспортным средством не более трех рейсов с грузом за пределы 
Республики Молдова автотранспортный оператор обязан обеспечить его возвращение на территорию 
Республики Молдова для осуществления автотранспортной операции (погрузка и/или разгрузка). 
Рейсы порожняком за пределы Республики Молдова не учитываются.</p><br>

<p style="text-indent:2em;">(5) Разрешения ЕКМТ позволяют осуществлять автотранспортные перевозки с соблюдением условия,
при котором первый рейс с грузом осуществляется между государством пребывания (член ЕКМТ) 
и другим государством-членом.</p>

<p style="text-indent:2em;"></p><h6>Ст.3127. – Разрешение ЕКМТ не может использоваться:</h6><p></p>

<p style="text-indent:2em;">a) для осуществления автотранспортной перевозки грузов между государством-членом 
ЕКМТ и государством, не являющимся членом ЕКМТ;</p><br>

<p style="text-indent:2em;">b) для осуществления автотранспортной перевозки грузов между двумя пунктами 
погрузки-разгрузки, находящимися на территории одного и того же государства, 
кроме Республики Молдова (каботажные перевозки);</p><br>

<p style="text-indent:2em;">c) для осуществления более трех последовательных рейсов с грузом между 
государствами-членами ЕКМТ без заезда на территорию Республики Молдова.</p>

<p style="text-indent:2em;"></p><h6>Ст.3128. – Разрешение ЕКМТ:</h6><p></p>

<p style="text-indent:2em;">a) выдается автотранспортному оператору и действительно для любого его
автотранспортного средства, которое соответствует условиям использования,
установленным соответствующим разрешением ЕКМТ, с обеспечением выполнения
технических условий, установленных для категории соответствующего разрешения
ЕКМТ или для более высокой категории. Одно разрешение ЕКМТ не может использоваться
одновременно для нескольких автотранспортных средств;</p><br>

<p style="text-indent:2em;">b) может использоваться для зарегистрированных в Республике Молдова автотранспортных
средств, арендованных без водителя, эксплуатируемых автотранспортным оператором–
обладателем разрешения ЕКМТ;</p>

<p style="text-indent:2em;"></p><h6>c) может использоваться для арендованных или приобретенных на условиях финансового
лизинга автотранспортных средств, зарегистрированных в Республике Молдова, 
эксплуатируемых автотранспортным оператором-обладателем разрешения ЕКМТ (арендатор).
В этом случае автотранспортное средство должно находиться в исключительном распоряжении
арендатора и за его рулем должен находиться работник арендатора. В случае отсутствия
отметки «аренда» в свидетельстве о регистрации автотранспортных средств таковые
должны иметь на борту заверенные печатью и подписью автотранспортного оператора
копии следующих документов:</h6><p></p>

<p style="text-indent:2em;"> договор аренды или лизинга, содержащий полные данные об арендодателе и арендаторе,
дату заключения и срок действия договора, идентификационные данные автотранспортного
средства, не ограничивающиеся регистрационным номером. Договор должен быть переведен
по меньшей мере на один из следующих языков: английский, французский или немецкий;</p><br>

<p style="text-indent:2em;"> индивидуальный трудовой договор с водителем, содержащий полные данные об арендаторе
и водителе, дату заключения и срок действия договора. Договор должен быть переведен по
меньшей мере на один из следующих языков: английский, французский или немецкий;</p><br>

<p style="text-indent:2em;">d) может использоваться начиная с места погрузки и до места разгрузки грузов или на 
протяжении всего рейса – в случае порожнего дорожного транспортного средства;</p><br>

<p style="text-indent:2em;">e) не может передаваться другому автотранспортному оператору или использоваться 
другим автотранспортным оператором, кроме того, кому оно выдано;</p><br>

<p style="text-indent:2em;">f) действительно только при наличии бортового журнала, в котором указываются наименование
автотранспортного оператора и номер соответствующего разрешения ЕКМТ и содержится в 
хронологическом порядке информация о транспортных операциях, осуществленных с
использованием данного разрешения;</p><br>

<p style="text-indent:2em;">g) не заменяет специальное разрешение, выдаваемое для перевозки грузов, масса и/или
размеры которых превышают предельно допустимые значения, предусмотренные в 
приложении 3 к Закону об автомобильных дорогах № 509/1995 и в Постановлении
Правительства № 1073/2007 об утверждении Положения о порядке выдачи разрешения
, контроле и осуществлении по дорогам общего пользования перевозок, масса и/или
размеры которых превышают предельно допустимые значения, или разрешение для перевозки 
специальных грузов (опасных грузов);</p><br>

<p style="text-indent:2em;">h) должно сопровождаться сертификатами о выполнении технических условий, установленных
для категории соответствующего разрешения ЕКМТ – «Евро IV безопасный», «Евро V 
безопасный», «Евро VI безопасный», EEV, должно храниться на борту автотранспортного
средства на протяжении всего рейса и предъявляться по требованию компетентных 
контрольных органов, иначе признается недействительным;</p><br>

<p>i) не может использоваться только в рамках повторяющихся перевозок;</p><br>

<p style="text-indent:2em;">j) может использоваться на территории государства-члена ЕКМТ, в котором запрещено использование разрешений
ЕКМТ, с условием наличия однократного разрешения, предусмотренного двусторонними соглашениями, допускающего
пересечение этих государств транзитом. В этом случае разрешение ЕКМТ должно находиться на борту от пункта
погрузки до пункта разгрузки.</p><br>

<p style="font-weight:500;">Ст.3129. – Для предусмотренных резолюцией ЕКМТ либерализованных перевозок нет необходимости в
разрешении ЕКМТ.</p>

<p style="text-indent:2em;"></p><h6>Ст.3130. – (1) Комиссия ЕКМТ создается приказом центрального отраслевого органа ежегодно до последнего
рабочего дня второй декады сентября и выполняет следующие функции:</h6><p></p>

<p style="text-indent:2em;">a) утверждает перечень заявок, поданных автотранспортными операторами для участия в распределении 
разрешений ЕКМТ, в целях их соответствия условиям распределения и обеспечивает опубликование до 20 
ноября на внешнем портале Агентства списка автотранспортных операторов, допущенных к участию в 
распределении разрешений ЕКМТ;</p><br>

<p style="text-indent:2em;">b) рассматривает в соответствии с настоящим кодексом поданные автотранспортными операторами жалобы 
по вопросам, касающимся условий допуска к распределению разрешений ЕКМТ, а также жалобы, поданные на 
всех этапах распределения разрешений.</p>

<p style="text-indent:2em;"></p><h6>(2) Комиссия ЕКМТ формируется на принципе паритета между частным и публичным секторами в 
следующем составе:</h6><p></p>

<p style="text-indent:2em;">a) 50 процентов – представители центрального отраслевого органа, один из которых является 
председателем Комиссии, и представители Агентства, все с правом голоса;</p><br>

<p style="text-indent:2em;">b) 50 процентов – представители представительных профильных профессиональных объединений 
(по одному представителю от каждого объединения), все с правом голоса;</p><br>

<p>c) секретарь Комиссии ЕКМТ, назначенный Агентством, без права голоса.</p><br>

<p style="text-indent:2em;">Количество членов Комиссии ЕКМТ устанавливается в зависимости от числа представителей
представительных профильных профессиональных объединений.</p>

<p style="text-indent:2em;"></p><h6>(3) Секретарь Комиссии ЕКМТ, назначенный директором Агентства, выполняет следующие 
функции:</h6><p></p>

<p>a) обеспечивает опубликование окончательных списков выданных разрешений;</p><br>

<p style="text-indent:2em;">b) принимает жалобы и направляет их Комиссии ЕКМТ для разрешения с обоснованными 
предложениями об их удовлетворении /отклонении;</p><br>

<p>c) составляет и оформляет протоколы заседаний Комиссии ЕКМТ;</p><br>

<p style="text-indent:2em;">d) обеспечивает опубликование до 20 ноября на внешнем портале Агентства списка 
автотранспортных операторов, допущенных к участию в распределении разрешений ЕКМТ
, списка допущенных дорожных транспортных средств (с указанием регистрационного номера
и идентификационного номера (VIN)), протоколов заседаний Комиссии ЕКМТ и приказа 
центрального отраслевого органа об их утверждении.</p><br>

<p style="text-indent:2em;">(4) Заседание Комиссии ЕКМТ считается правомочным при условии присутствия простого
большинства членов, имеющих право голоса.</p><br>

<p style="text-indent:2em;">(5) Решение Комиссии ЕКМТ принимается простым большинством голосов членов, 
присутствующих на заседании. В случае равенства голосов голос председателя 
Комиссии является решающим.</p><br>

<p style="text-indent:2em;">(6) Заседание Комиссии ЕКМТ оформляется протоколом, который подписывается 
присутствующими на заседании членами Комиссии и утверждается в течение трех
дней приказом центрального отраслевого органа.</p><br>

<p style="font-weight:500;">Ст.3131. – (1) Разрешения ЕКМТ распределяются избранным автотранспортным 
операторам посредством информационной системы «e-Autorizaţie transport».</p>

<p style="text-indent:2em;"></p><h6>(2) Избранными автотранспортными операторами признаются операторы, отвечающие
в совокупности следующим требованиям:</h6><p></p>

<p>a) имеют местонахождение или местожительство в Республике Молдова;</p><br>

<p style="text-indent:2em;">b) официально внесены в Реестр при распределении разрешений ЕКМТ, в том
числе для осуществления международных автотранспортных перевозок груза;</p><br>

<p style="text-indent:2em;">c) обладают на праве собственности, на условиях финансового лизинга и/или
аренды хотя бы одним избранным автотранспортным средством с предельно допустимой
общей массой свыше 6 тонн, которое отвечает техническим нормам и требованиям безопасности
для категории классификации согласно соответствующей категории разрешений ЕКМТ. Допускаются
к распределению разрешений ЕКМТ только автотранспортные средства, зарегистрированные в
Республике Молдова;</p><br>

<p style="text-indent:2em;">d) не выявлено использование ими подложных разрешений или допущение более двух грубых
нарушений порядка использования разрешений ЕКМТ в течение 365 дней подряд, предшествующих 
дню распределения разрешений ЕКМТ, в том числе на территории государств, где были использованы
разрешения ЕКМТ, и/или в других случаях, установленных настоящим кодексом;</p>

<p style="text-indent:2em;"></p><h6>e) эффективно использовали разрешения ЕКМТ в течение текущего календарного года. Эффективным
использованием разрешений ЕКМТ является соблюдение следующих условий, по которым следует
отчитываться перед Агентством:</h6><p></p>

<p style="text-indent:2em;"> выполнение в среднем хотя бы двух рейсов с грузом в течение месяца по каждому распределенному
разрешению ЕКМТ, за исключением января и февраля;</p><br>

<p style="text-indent:2em;"> проезд в среднем не менее 3000 км в месяц, кроме января и февраля, с грузом за пределами
Республики Молдова или по территории Республики Молдова, когда операция по автотранспортной
перевозке, осуществляемая на основании разрешения ЕКМТ, начинается, заканчивается на территории
Республики Молдова или осуществляется транзитом через ее территорию.</p><br>

<p style="font-weight:500;">Ст.3132. – (1) Заявки и документы на участие в распределении разрешений ЕКМТ подаются автотранспортным
оператором посредством информационной системы «e-Autorizaţie transport» начиная с последнего рабочего
дня второй декады сентября и до последнего рабочего дня октября.</p><br>

<p style="text-indent:2em;">(2) Пакеты документов, поданные автотранспортными операторами, регистрируются в информационной системе
«e-Autorizaţie transport» в хронологическом порядке поступления.</p><br>

<p style="text-indent:2em;">(3) Автотранспортный оператор отвечает за подлинность всех документов, включенных в пакет документов для 
участия в конкурсе по распределению разрешений ЕКМТ.</p><br>

<p style="text-indent:2em;">(4) При обнаружении в пакете документов недостоверных данных соответствующий автотранспортный оператор
по решению Комиссии ЕКМТ исключается из списка избранных автотранспортных операторов.</p><br>

<p style="font-weight:500;">Ст.3133. – (1) Распределение разрешений ЕКМТ осуществляется электронным способом посредством информационной
системы «e-Autorizaţie transport» в соответствии с количеством и видом разрешений ЕКМТ, 
предоставленных Республике Молдова на очередной календарный год, на основании заявок 
избранных автотранспортных операторов и с учетом избранного автопарка.</p><br>

<p style="text-indent:2em;">(2) Если количество заявленных разрешений ЕКМТ больше количества разрешений ЕКМТ соответствующего 
вида, распределение осуществляется в математическом порядке, прямо пропорционально 
избранному автопарку, в соответствии с заявками, поданными посредством 
информационной системы «e-Autorizaţie transport», в 
зависимости от количества разрешений ЕКМТ, 
запрашиваемых каждым автотранспортным оператором.</p><br>

<p style="text-indent:2em;">(3) Если количество запрашиваемых разрешений ЕКМТ меньше количества разрешений ЕКМТ
соответствующего вида, распределение осуществляется посредством информационной
системы «e-Autorizaţie transport» в зависимости от количества избранных 
автотранспортных средств, а излишек переводится в резерв.</p><br>

<p style="text-indent:2em;">(4) Распределение разрешений ЕКМТ должно осуществляться до 15 декабря текущего года. 
Комиссия ЕКМТ направляет на утверждение в центральный отраслевой орган список
автотранспортных операторов, которым распределены разрешения ЕКМТ, как
основание для последующей выдачи разрешений.</p>

<p style="text-indent:2em;"></p><h6>(5) Распределение разрешений ЕКМТ без ограничения по количеству осуществляется с 
учетом количества разрешений ЕКМТ, распределенных каждому автотранспортному
оператору, и пожелания, выраженного заявителем в момент подачи 
заявки:</h6><p></p>

<p style="text-indent:2em;">a) в случае если запрашиваемых разрешений ЕКМТ без ограничения по количеству 
для каждого государства, накладывающего ограничения, больше, чем разрешений
ЕКМТ без ограничения по количеству из контингента разрешений ЕКМТ, 
действительных в соответствующем году, распределение 
осуществляется в математическом порядке путем проведения 
электронной процедуры посредством информационной системы 
«e-Autorizaţie transport» для каждого государства, 
накладывающего ограничения, в зависимости от числа разрешений 
ЕКМТ, распределенных каждому автотранспортному оператору;</p><br>

<p style="text-indent:2em;">b) в случае автотранспортных операторов, запрашивающих меньше разрешений ЕКМТ без
ограничения по количеству, чем автопарк, включенный для распределения, 
количество разрешений ЕКМТ без ограничения по количеству для 
каждого государства, накладывающего ограничения, равно 
числу разрешений, полученных другими автотранспортными 
операторами для подобного автопарка;</p><br>

<p style="text-indent:2em;">c) в случае если количество запрашиваемых разрешений ЕКМТ без 
ограничения по количеству для каждого государства, 
накладывающего ограничения, меньше количества 
разрешений ЕКМТ из контингента разрешений ЕКМТ, 
действительных в соответствующем году, распределение 
осуществляется напрямую, в зависимости от количества 
распределенных разрешений ЕКМТ.</p><br>

<p style="text-indent:2em;">Разрешения ЕКМТ без ограничения по количеству распределяются 
автотранспортным операторам, осуществляющим 
непрерывную деятельность в области международных 
автотранспортных перевозок грузов в последние три 
года и осуществившим перевозку груза в одну из стран, 
для которых установлен запрет и запрашивается разрешение.</p><br>

<p style="text-indent:2em;">(6) Разрешения ЕКМТ без ограничения по количеству, оставшиеся нераспределенными, 
выдаются по мере поступления последующих заявок на выдачу разрешений ЕКМТ, 
поданных в течение года действия разрешения ЕКМТ, в соответствии с 
указанной процедурой.</p><br>

<p style="font-weight:500;">Ст.3134. – (1) Разрешения ЕКМТ выдаются автотранспортным операторам за плату, 
размер и срок внесения которой устанавливается в Перечне разрешительных 
документов, утвержденном Законом о регулировании предпринимательской 
деятельности путем разрешения № 160/2011.</p><br>

<p style="text-indent:2em;">(2) Распределенные разрешения ЕКМТ, не полученные в течение одного месяца 
с момента утверждения списка автотранспортных операторов, которым 
распределены разрешения ЕКМТ, отзываются и перераспределяются 
другим автотранспортным операторам, которые не получали 
разрешения ЕКМТ, в хронологическом порядке регистрации 
в информационной системе «e-Autorizaţie transport».</p><br>

<p style="text-indent:2em;">(3) Разрешения ЕКМТ, распределенные из числа отозванных разрешений ЕКМТ, 
выдаются автотранспортным операторам, не получившим разрешения 
ЕКМТ, в хронологическом порядке регистрации в информационной 
системе «e-Autorizaţie transport» за плату, установленную в 
Перечне разрешительных документов, утвержденном Законом о 
регулировании предпринимательской деятельности путем 
разрешения № 160/2011.</p><br>

<p style="font-weight:500;">Ст.3135. – (1) Автотранспортный оператор обязан иметь бортовой 
журнал для каждого распределенного разрешения ЕКМТ на 
протяжении всего срока его действия.</p><br>

<p style="text-indent:2em;">(2) Разрешение ЕКМТ действительно только при его 
сопровождении соответствующим бортовым журналом.</p><br>

<p style="text-indent:2em;">(3) На обложке бортового журнала указываются номер соответствующего 
разрешения ЕКМТ, имя и адрес владельца разрешения.</p><br>

<p style="text-indent:2em;">(4) Бортовой журнал не может быть передан одним автотранспортным 
оператором другому.</p><br>

<p style="text-indent:2em;">(5) В случае краткосрочного разрешения ЕКМТ бортовой журнал, 
сопровождающий соответствующее разрешение, имеет пять 
пронумерованных страниц зеленого цвета.</p><br>

<p style="text-indent:2em;">(6) В случае годового разрешения ЕКМТ бортовой журнал, 
сопровождающий соответствующее разрешение, имеет 52 пронумерованные 
страницы, каждая из которых содержит лист-оригинал зеленого 
цвета и самокопирующийся лист.</p><br>

<p style="text-indent:2em;">(7) На каждом листе бортового журнала необходимо указать в специально 
отведенном месте в правом верхнем углу номер разрешения ЕКМТ, 
соответствующего бортовому журналу.</p><br>

<p style="text-indent:2em;">(8) Бортовой журнал имеет тот же номер, что и сопровождаемое им разрешение ЕКМТ. 
Соответствующий номер проставляется автотранспортным оператором как в 
соответствующем разделе обложки бортового журнала, так и в специально 
отведенном месте в правом верхнем углу обложки.</p><br>

<p style="text-indent:2em;">(9) Автотранспортный оператор может запросить дополнительный бортовой 
журнал только тогда, когда доказано заполнение хотя бы 50 страниц 
ранее выданного бортового журнала – в случае годового разрешения 
ЕКМТ и соответственно 4 листов – в случае краткосрочного разрешения 
ЕКМТ. Несоблюдение этого условия влечет признание соответствующего 
разрешения ЕКМТ недействительным.</p><br>

<p style="text-indent:2em;">(10) Для получения дополнительного бортового журнала автотранспортный 
оператор подает в Агентство заявку с приложением копии последнего 
использованного листа ранее выданного бортового журнала.</p><br>

<p style="text-indent:2em;">(11) Дополнительно выданный бортовой журнал имеет тот же номер, 
что и сопровождаемое им разрешение ЕКМТ.</p><br>

<p style="text-indent:2em;">(12) Регистрация осуществленных рейсов производится таким образом, 
чтобы можно было идентифицировать в хронологическом порядке каждый 
рейс с грузом между пунктами погрузки и разгрузки, а также 
каждый рейс без груза с пересечением границы государства–члена 
ЕКМТ. В бортовом журнале могут отмечаться и транзитные пункты, 
без того чтобы это было обязательным.</p>

<p style="text-indent:2em;"></p><h6>(13) Бортовой журнал должен быть заполнен до начала любого рейса с грузом между 
пунктами погрузки и разгрузки и рейса без груза с пересечением границы 
государства–члена ЕКМТ. Каждый рейс с грузом или без груза 
регистрируется в одной строке с указанием следующей 
информации на листах бортового журнала:</h6><p></p>

<p>a) сведения о пункте отправления соответствующего рейса;</p><br>

<p>b) сведения о пункте прибытия соответствующего рейса;</p><br>

<p style="text-indent:2em;">с) транзитные пункты, которые могут записываться без проставления 
печати для каждого транзитного государства.</p><br>

<p style="text-indent:2em;">В соответствующей колонке листа бортового журнала указываются регистрационный
номер автотранспортного средства, осуществляющего соответствующий 
рейс на основании разрешения ЕКМТ, и страна регистрации 
(Республика Молдова).</p><br>

<p style="text-indent:2em;">(14) В случае если во время одного рейса погрузка или разгрузка грузов 
осуществляется в разных местах, соответствующие этапы необходимо 
указать в колонках 1, 2, 3, 5 и 6 бортового журнала, отметив их
знаком «+».</p><br>

<p style="text-indent:2em;">(15) Исправление записи – слова или цифры – на листах бортового журнала 
осуществляется путем зачеркивания таким образом, чтобы первоначальная 
запись оставалась читаемой.</p><br>

<p style="text-indent:2em;">(16) Контролирующие органы не должны требовать проставления на листах 
бортового журнала печатей для каждого государства, пересеченного транзитом
во время соответствующего рейса.</p><br>

<p style="text-indent:2em;">(17) В случае если рейс начинается на основании краткосрочного разрешения 
ЕКМТ, срок которого истекает во время осуществления рейса, и продолжается 
на основании другого разрешения ЕКМТ, выданного на последующий период, оба 
разрешения должны находиться на борту автотранспортного средства на 
протяжении всего рейса. В этом случае бортовой журнал, 
сопровождающий разрешение ЕКМТ, на основании которого 
заканчивается рейс, должен содержать информацию обо всем 
рейсе, а в колонке «Примечания» указывается номер разрешения 
ЕКМТ, на основании которого рейс начат.</p><br>

<p style="text-indent:2em;">(18) Заполненные листы бортового журнала (листы-оригиналы зеленого цвета)
должны храниться в бортовом журнале до истечения срока действия 
соответствующего разрешения ЕКМТ. Копии этих листов 
(самокопирующиеся листы) отрываются и передаются Агентству 
в течение 14 дней со дня окончания каждого календарного месяца 
в случае годового разрешения ЕКМТ или в течение 14 дней со дня 
истечения срока действия в случае краткосрочного разрешения ЕКМТ.
На основании данных, содержащихся на соответствующих листах, 
автотранспортный оператор составляет месячный отчет об использовании 
краткосрочных/годовых разрешений ЕКМТ.</p><br>

<p style="text-indent:2em;">(19) Отчет представляется Агентству вместе с копиями заполненных листов 
бортового журнала. Продление срока представления отчета для конкретного 
автотранспортного средства разрешается только в случае нахождения 
соответствующего автотранспортного средства, на борту которого 
находится разрешение ЕКМТ, за пределами Республики Молдова на 
все установленное для представления отчета время и только с 
уведомлением Агентства об этих обстоятельствах. Впоследствии 
представляются соответствующие подтверждения, но не позднее 
15 дней со дня истечения установленного для представления 
отчета срока.</p><br>

<p style="text-indent:2em;">(20) Самокопирующиеся листы бортового журнала, переданные транспортными операторами,
хранятся в офисе Агентства в течение одного календарного года после истечения 
срока действия соответствующих разрешений ЕКМТ. Автотранспортные операторы 
должны также хранить в своем офисе на протяжении всего следующего 
календарного года бортовые журналы с листами-оригиналами зеленого
цвета, соответствующие разрешениям ЕКМТ, выданным в предыдущем 
году. Автотранспортные операторы представляют Агентству по его 
требованию бортовые журналы, являющиеся объектом обязательного 
хранения в течение указанного выше периода.</p><br>

<p style="text-indent:2em;">(21) В случае рейсов, пункт отправления/назначения которых расположен 
на территории Республики Молдова, в обязательном порядке указывается
соответствующий пункт, а также то, что данный пункт находится на 
территории Республики Молдова.</p><br>

<p style="text-indent:2em;">(22) При определении количества отчетных рейсов учитываются все 
выполненные рейсы, независимо от того, находится пункт погрузки/разгрузки
на территории Республики Молдова или нет.</p><br>

<p style="text-indent:2em;">(23) В случае, когда рейс начинается в одном месяце, а заканчивается в следующем,
данный рейс включается в отчет за тот месяц, в котором рейс заканчивается.</p><br>

<p style="text-indent:2em;">(24) В случае неясностей, возникших во время контроля, помимо соответствующих копий
автотранспортные операторы представляют контролирующим органам по их требованию 
бортовые журналы ЕКМТ, которые содержат оригиналы листов с записями о 
выполненных рейсах в период действия разрешения ЕКМТ, к которому
они относятся.</p><br>

<p style="text-indent:2em;">(25) Агентство обрабатывает информацию, содержащуюся в бортовых журналах,
составляет и представляет статистику, запрашиваемую Секретариатом МТФ с
местонахождением в Париже, в установленные последним порядке и сроки.</p>

<p style="text-indent:2em;"></p><h6>Ст.3136. – (1) Разрешения ЕКМТ отзываются на основании решения Комиссии ЕКМТ,
утвержденного приказом центрального отраслевого органа, без возвращения 
автотранспортным операторам внесенной платы в следующих случаях:</h6><p></p>

<p style="text-indent:2em;">a) исключение автотранспортного оператора из Реестра или приостановление 
его регистрации на основании окончательного судебного решения или, по 
обстоятельствам, окончательного решения Агентства;</p><br>

<p style="text-indent:2em;">b) использование автотранспортных средств, на которые не были поданы 
уведомления для официальной регистрации. В этом случае разрешения 
отзываются пропорционально количеству зарегистрированных автотранспортных 
средств;</p><br>

<p style="text-indent:2em;">c) использование разрешения ЕКМТ иным автотранспортным оператором, кроме 
оператора, которому оно распределено. Дополнительно автотранспортный 
оператор не допускается к участию в конкурсе по распределению 
разрешений ЕКМТ на очередной год;</p><br>

<p style="text-indent:2em;">d) использование разрешения ЕКМТ для транспортной операции, осуществляемой 
автотранспортным средством категории ниже указанной в соответствующем 
разрешении ЕКМТ. В этом случае отзывается по одному разрешению за 
каждое установленное нарушение;</p><br>

<p style="text-indent:2em;">e) разрешение ЕКМТ не содержит один из следующих обязательных элементов: 
наименование и полный адрес автотранспортного оператора, подпись и печать 
выдавшего разрешение органа, дату выдачи разрешения ЕКМТ, данные о начале 
и окончании срока действия разрешения ЕКМТ, ограничения, установленные 
странами-членами ЕКМТ, печать и подпись владельца соответствующего разрешения 
ЕКМТ. В этом случае отзывается только разрешение, в котором выявлены нарушения;</p><br>

<p style="text-indent:2em;">f) разрешение ЕКМТ не сопровождается заполненным соответствующим образом бортовым 
журналом. В этом случае отзывается только разрешение, которое не сопровождается 
бортовым журналом;</p><br>

<p style="text-indent:2em;">g) автотранспортный оператор не представил дважды или более месячный отчет об 
использовании краткосрочных/годовых разрешений ЕКМТ или не представил его в 
семидневный срок хотя бы один раз после получения письменного уведомления, 
направленного Агентством заказным письмом. В этом случае отзывается только 
разрешение ЕКМТ, за которое не представлен месячный отчет об его 
использовании;</p><br>

<p style="text-indent:2em;">h) разрешение ЕКМТ не сопровождается сертификатами соответствия ЕКМТ категорий A и B,
выданными заводом-изготовителем для дорожных транспортных средств, соответствующих 
резолюции ЕКМТ, сертификатом ЕКМТ соответствия прицепа или полуприцепа техническим 
условиям безопасности и сертификатом ЕКМТ соответствия автотранспортного средства и 
прицепа условиям технического контроля. В этом случае отзывается только разрешение,
в котором обнаружены нарушения;</p><br>

<p style="text-indent:2em;">i) установлено, что автотранспортный оператор осуществляет деятельность на основании 
разрешения ЕКМТ, объявленного утерянным или украденным. Дополнительно автотранспортному 
оператору запрещается участие в конкурсе по распределению разрешений ЕКМТ на очередной год
для автотранспортного средства с соответствующим разрешением, за исключением случая смены 
владельца автотранспортного средства с включением последнего в пакет документов автотранспортного
оператора, иного чем участвовавший в распределении разрешений в текущем году;</p><br>

<p style="text-indent:2em;">j) в течение предшествующего квартала разрешение ЕКМТ не использовалось в соответствии с установленными
критериями эффективности. В этом случае изымается только 
неэффективно использованное разрешение;</p><br>

<p style="text-indent:2em;">k) используемое разрешение ЕКМТ повреждено в такой степени, что идентификационные элементы нечитабельны.
Такое разрешение может быть заменено из имеющегося у Агентства резерва другим разрешением ЕКМТ, 
действительным на оставшийся срок действия отозванного разрешения ЕКМТ;</p>

<p style="text-indent:2em;"></p><h6>l) использование подложного разрешения ЕКМТ или другого подложного сопроводительного документа, подтвержденное
компетентными органами. В этом случае:</h6><p></p>

<p style="text-indent:2em;"> при первом выявлении отзываются 50 процентов разрешений ЕКМТ, выданных соответствующему автотранспортному
оператору на текущий год, с изъятием подложного разрешения. Дополнительно соответствующий автотранспортный 
оператор не допускается к участию в конкурсе по распределению разрешений ЕКМТ на очередной год;</p><br>

<p style="text-indent:2em;"> при повторном выявлении подложных разрешений ЕКМТ отзываются все разрешения ЕКМТ, выданные соответствующему 
автотранспортному оператору на текущий год, с изъятием подложного разрешения. Дополнительно соответствующий 
автотранспортный оператор не допускается на два последующих года к участию в конкурсе по распределению разрешений
ЕКМТ;</p><br>

<p style="text-indent:2em;">m) повторное осуществление более трех последовательных рейсов с грузом между государствами-членами ЕКМТ без въезда на
территорию Республики Молдова. В этом случае отзывается только разрешение, при использовании которого допущено
нарушение;</p><br>

<p style="text-indent:2em;">n) осуществление каботажных перевозок. В этом случае отзывается только разрешение, при использовании которого
допущено нарушение;</p><br>

<p style="text-indent:2em;">o) использование разрешения ЕКМТ только в рамках повторяющихся перевозок. В этом случае отзывается только разрешение,
при использовании которого допущено нарушение.</p><br>

<p style="text-indent:2em;">(2) В случае удержания разрешений ЕКМТ компетентными органами за нарушения, предусмотренные настоящим кодексом, 
таковые направляются Агентству для их рассмотрения Комиссией ЕКМТ.</p><br>

<p style="text-indent:2em;">(3) Комиссия ЕКМТ уведомляет автотранспортного оператора в письменной форме посредством информационной системы 
«e-Autorizaţie transport» о месте и времени рассмотрения случая нарушения правил использования разрешений ЕКМТ.</p><br>

<p style="text-indent:2em;">(4) Автотранспортный оператор вправе присутствовать на заседании Комиссии ЕКМТ и представлять письменные 
объяснения по каждому случаю. Комиссия ЕКМТ принимает свое решение в отсутствие автотранспортного оператора,
а секретарь Комиссии ЕКМТ в трехдневный срок в письменной форме доводит до его сведения принятое Комиссией 
решение.</p><br>

<p style="text-indent:2em;">(5) Для подтверждения решения Комиссии ЕКМТ Агентство не позднее трех дней обращается в судебную инстанцию 
в соответствии с положениями части (3) статьи 17 Закона об основных принципах регулирования предпринимательской
деятельности № 235/2006.</p><br>

<p style="text-indent:2em;">(6) В случае принятия решения об отзыве разрешений ЕКМТ автотранспортные операторы обязаны в течение 30
календарных дней со дня принятия соответствующего решения возвратить разрешения Агентству.</p><br>

<p style="text-indent:2em;">(7) В случае невозврата разрешений ЕКМТ в установленный срок автотранспортный оператор не допускается 
к участию в конкурсе по распределению разрешений ЕКМТ на очередной год. Агентство объявляет недействительными 
отозванные, но не возвращенные ему автотранспортным оператором разрешения ЕКМТ.</p><br>

<p style="text-indent:2em;">(8) Автотранспортный оператор в течение 30 календарных дней вправе обжаловать решение или постановление
об отзыве разрешения ЕКМТ в предусмотренном законом порядке.</p>

<p style="text-indent:2em;"></p><h6>(9) В случае подачи жалобы в Агентство после ее принятия производятся следующие действия:</h6><p></p>

<p style="text-indent:2em;">a) в течение пяти календарных дней со дня принятия жалобы Агентство созывает заседание 
Комиссии ЕКМТ;</p><br>

<p style="text-indent:2em;">b) Комиссия ЕКМТ рассматривает жалобу автотранспортного оператора, обстоятельства отзыва разрешения
ЕКМТ и предлагает Агентству удовлетворить или отклонить жалобу;</p><br>

<p style="text-indent:2em;">c) предложение Комиссии ЕКМТ по жалобе автотранспортного оператора утверждается простым большинством
голосов присутствующих членов Комиссии с правом голоса и вступает в силу сразу после утверждения, то
есть с момента объявления решения;</p><br>

<p style="text-indent:2em;">d) решение о принятии, удовлетворении или отклонении жалобы автотранспортного оператора принимается 
Комиссией ЕКМТ, утверждается приказом и вступает в силу с момента подписания с последующим 
опубликованием на официальных веб-страницах центрального отраслевого органа и Агентства;</p><br>

<p style="text-indent:2em;">e) в случае несогласия автотранспортного оператора с решением Комиссии ЕКМТ оператор вправе 
обжаловать приказ, которым решение утверждено, в компетентную судебную инстанцию.</p><br>

<p style="text-indent:2em;">(10) Отозванные разрешения ЕКМТ регистрируются в информационной системе «e-Autorizaţie transport» 
и предлагаются для распределения автотранспортным операторам согласно ранее установленной 
процедуре.</p>

<p></p><h4>Часть 5</h4><p></p>

<p>Книжка с путевыми листами (КПЛ)</p><br>

<p style="font-weight:500;">Ст.3145. – (1) Для осуществления автотранспортных перевозок за собственный счет
физическим лицам, которые владеют автотранспортными средствами вместимостью 6–9 мест,
и/или субъектам, осуществляющим такие перевозки, выдается книжка с путевыми листами
(КПЛ).</p><br>

<p style="text-indent:2em;">(2) Физическим лицам и субъектам, которые осуществляют автотранспортные перевозки за 
собственный счет, при импорте автотранспортных средств выдается книжка с путевыми 
листами (КПЛ).</p><br>

<p style="text-indent:2em;">(3) При выдаче в книжке с путевыми листами (КПЛ) указываются данные заказчика, и 
она подлежит использованию исключительно этим заказчиком.</p><br>

<p>(4) Книжка с путевыми листами (КПЛ) выдается на один рейс в оба конца и не 
подлежит передаче другому лицу.</p><br>

<p style="text-indent:2em;">(5) Допускается одновременная выдача двух книжек с путевыми листами (КПЛ),
но не более 12 штук в течение одного календарного года.</p><br>

<p style="text-indent:2em;">(6) В случае утери, кражи или уничтожения книжки с путевыми листами (КПЛ) 
физическое лицо/субъект в 30-дневный срок письменно информирует об этом Агентство 
с приложением доказательства, выданного компетентными органами (в случае документов,
выданных иностранными компетентными органами, представляется также перевод документа 
на румынский язык, выполненный авторизованным переводчиком).</p><br>

<p style="text-indent:2em;">(7) Запрещается использование книжек с путевыми листами (КПЛ), объявленных утерянными, 
украденными, уничтоженными, или подложных книжек. Книжки с путевыми листами (КПЛ), 
объявленные утерянными, украденными или уничтоженными, не подлежат замене.</p><br>

<p style="text-indent:2em;">(8) Использованная книжка с путевыми листами (КПЛ) подлежит возврату Агентству в течение
90 дней с момента их получения. В случае невозврата использованной книжки с путевыми
листами (КПЛ) новые книжки не выдаются.</p><br>

<p style="text-indent:2em;">(9) Запрещается использование книжки с путевыми листами (КПЛ), имеющей повреждения или 
содержащей изменения.</p><br>

<p style="text-indent:2em;">(10) Запрещается использование книжки с путевыми листами (КПЛ) для осуществления платных 
автотранспортных перевозок.</p><br>

<p style="font-weight:500;">Ст.3146. – (1) Физическому лицу, использующему книжку с путевыми листами (КПЛ) для 
автотранспортного средства, вместимость которого превышает 9 мест, приостанавливается
регистрация в информационной системе «e-Autorizaţie transport» сроком на 30 дней с 
момента установления факта.</p><br>

<p style="text-indent:2em;">(2) За использование физическим лицом/субъектом книжки с путевыми листами (КПЛ) не 
по назначению запрещается выдача ему других книжек с путевыми листами (КПЛ) сроком на
180 дней с момента установления факта.</p>

<p style="text-indent:2em;"></p><h6>(3) За передачу книжки с путевыми листами (КПЛ) другому физическому лицу/субъекту 
виновным сторонам приостанавливается регистрация в информационной системе «e-Autorizaţie
transport» с момента установления нарушения в следующем порядке:</h6><p></p>

<p>a) за первое нарушение (одна книжка с путевыми листами (КПЛ)) – на срок 3 месяца;</p><br>

<p>b) за повторные нарушения в течение одного календарного года – на срок 6 месяцев.</p><br>

<p style="text-indent:2em;">(4) За использование книжки с путевыми листами (КПЛ), объявленной утерянной, украденной, 
уничтоженной, или с повреждениями/изменениями физическому лицу/субъекту приостанавливается 
регистрация в информационной системе «e-Autorizaţie transport» на срок 60 дней с момента 
выявления нарушения. За использование подложной книжки с путевыми листами (КПЛ) физическому
лицу/субъекту приостанавливается регистрация в информационной системе «e-Autorizaţie 
transport» на срок 1 календарный год.</p>

<p style="text-indent:2em;"></p><h6>(5) За использование книжки с путевыми листами (КПЛ) для осуществления автотранспортных
перевозок на платной основе физическому лицу/субъекту приостанавливается регистрация в 
информационной системе «e-Autorizaţie transport» с момента выявления нарушения в следующем
порядке:</h6><p></p>

<p>a) за первое нарушение – на срок 180 дней;</p><br>

<p>b) за повторное нарушение – на срок 1 год.</p><br>

<p style="text-indent:2em;">(6) Приостановление регистрации в Реестре и в информационной системе «e-Autorizaţie 
transport» инициируется и применяется в соответствии со статьей 28.</p>

<p></p><h4>Глава 4.Автотранспортные перевозки
за собственный счет</h4><p></p>

<p style="text-indent:2em;"></p><h6>Ст. 42. – (1) Автотранспортные перевозки за собственный счет представляют собой операции по автотранспортным перевозкам,
производимые без взимания тарифа либо эквивалента в натуре или услугах, и осуществляются на следующих условиях:</h6><p></p>

<p style="text-indent:2em;">a) они являются второстепенной или дополнительной деятельностью по отношению к основной деятельности предприятия;</p><br>

<p style="text-indent:2em;">b) перевозимые товары являются собственностью предприятия, либо были проданы, приобретены или арендованы, либо
были произведены, извлечены, переработаны или отремонтированы им;</p><br>

<p style="text-indent:2em;">c) целью операции по автотранспортной перевозке является перевозка товаров или лиц к 
предприятию или от предприятия либо между его рабочими точками;</p><br>

<p style="text-indent:2em;">d) перевозимые лица являются работниками предприятия, при этом целью перевозки являются интересы
собственной деятельности предприятия;</p><br>

<p style="text-indent:2em;">e) предприятие использует дорожные транспортные средства, которыми оно обладает на правах собственности,
финансового лизинга или имущественного найма, либо, в случае перевозки товаров – транспортные средства, 
взятые внаем без водителя;</p><br>

<p style="text-indent:2em;">f) управление транспортными средствами, используемыми для таких перевозок, осуществляется водителями,
являющимися работниками предприятия.</p><br>

<p style="text-indent:2em;">(2) Автотранспортные перевозки грузов и пассажиров за собственный счет в национальном и/или 
международном сообщении подпадают под упрощенный уведомительный режим.</p><br>

<p style="text-indent:2em;">(21) Под упрощенный уведомительный режим подпадают предприятия, осуществляющие автотранспортные
перевозки за собственный счет дорожными транспортными средствами, сконструированными и оборудованными
для перевозки пассажиров на сидениях и стоя, имеющими более 9 сидячих мест, включая место водителя, и
дорожными транспортными средствами, предельная допустимая общая масса которых превышает 3,5 тонны,
используемыми для автотранспортных перевозок грузов.</p>

<p style="text-indent:2em;"></p><h6>(3) Для осуществления автотранспортной перевозки за собственный счет в соответствии с частью (1)
			предприятие должно быть зарегистрировано в установленном порядке в Республике Молдова и подать в 
			Агентство уведомление об осуществлении автотранспортных перевозок за собственный счет по форме и 
			с содержанием, утвержденными центральным отраслевым органом, вместе со следующими документами:</h6><p></p>
			
<p>b) перечень дорожных транспортных средств, которыми обладает или пользуется предприятие;</p><br>
			
<p>с) копии свидетельств о регистрации дорожных транспортных средств.</p>
			
<p style="text-indent:2em;"></p><h6>(4) Подача уведомления и документов в Агентство для получения права пользования 
			уведомительным режимом осуществляется следующим образом:</h6><p></p>
			
<p style="text-indent:2em;">a) путем подачи лично менеджером по автотранспортным перевозкам или уполномоченным
			представителем предприятия, под роспись;</p><br>
			
<p>b) заказным письмом с уведомлением о вручении;</p><br>
			
<p>c) в электронном виде с использованием электронной подписи.</p><br>
			
<p style="text-indent:2em;">(5) Считается датой подачи, по обстоятельствам, дата регистрации в журнале входящей 
			корреспонденции Агентства или дата подтверждения вручения почтового отправления.</p><br>
			
<p style="text-indent:2em;">(6) В течение пяти рабочих дней с момента подачи уведомления Агентство без каких-либо условий
			и бесплатно выдает выписку из Реестра, которая подтверждает подачу уведомления, запись о нем 
			в Государственном регистре предприятий, осуществляющих деятельность по автотранспортным 
			перевозкам за собственный счет, а также предусматривает условия осуществления 
			деятельности.</p>
			

<p></p><h4>Глава 5. Профессиональная аттестация менеджеров
по автотранспортным перевозкам и водителей</h4><p></p>

<p style="text-indent:2em;"></p><h6>Ст. 43. – (1) Для осуществления деятельности в качестве менеджера по автотранспортным
			перевозкам в сфере автотранспортных перевозок на платной основе заявитель должен
			отвечать следующим условиям:</h6><p></p>
			
<p>a) обладать действительным сертификатом профессиональной компетенции;</p><br>
			
<p>b) представить подтверждение, что является работником автотранспортного оператора.</p>
			
<p style="text-indent:2em;"></p><h6>(2) Для осуществления деятельности в качестве водителя в сфере автотранспортных
			перевозок на платной основе заявитель должен отвечать следующим условиям:</h6><p></p>
			
<p style="text-indent:2em;">a) обладать действительным водительским удостоверением соответственно категории 
			(подкатегории) транспортного средства, выданным на его имя компетентными органами 
			Республики Молдова или стран-подписантов Венской конвенции о дорожном движении от 8
			ноября 1968 года. Для осуществления международных автотранспортных перевозок, 
			автотранспортных перевозок пассажиров или перевозок негабаритных и опасных грузов 
			заявитель должен иметь стаж работы не менее трех лет в качестве водителя 
			автотранспортного средства;</p><br>
			
<p>b) обладать действительным сертификатом профессиональной компетенции;</p><br>
			
<p>c) представить подтверждение, что является работником автотранспортного оператора;</p><br>
			
<p>d) быть годным с медицинской и психофизиологической точек зрения.</p><br>
			
<p style="text-indent:2em;">(3) Водители автотранспортных средств обязаны проходить медицинское и наркологическое освидетельствование
			(с выдачей консультативных заключений) один раз в два года.</p><br>
			
<p style="text-indent:2em;">(4) Сертификат профессиональной компетенции выдается Агентством после прохождения курсов начальной подготовки
			в аккредитованных и зарегистрированных центрах профессионального обучения, повышения квалификации и профессиональной 
			аттестации, с целью осуществления деятельности в качестве менеджера по автотранспортным перевозкам или деятельности в 
			качестве водителя по автотранспортным перевозкам на платной основе и сдачи оценочного теста с множественными вариантами
			ответов.</p><br>
			
<p style="text-indent:2em;">(4.1) Сертификат профессиональной компетенции водителя может быть получен на основании водительского удостоверения соответственно
			категории (подкатегории) транспортного средства и свидетельства об окончании соответствующих курсов по осуществлению 
			автотранспортных перевозок в национальном и/или международном сообщении. Наличие сертификата профессиональной
			компетенции для международных автотранспортных перевозок позволяет также его обладателю осуществлять
			автотранспортные перевозки в национальном сообщении.</p><br>
			
<p style="text-indent:2em;">(5) По истечении срока действия сертификата профессиональной компетенции выдача нового сертификата производится
			после прохождения курсов повышения квалификации и сдачи оценочного теста с
			множественными вариантами ответов.</p><br>
			
<p style="text-indent:2em;">(6) Тесты, проводимые согласно частям (4) и (5), осуществляются при участии представителей Агентства.
			Неучастие последних в проведении тестов в случае, когда Агентство было информировано о месте, дате и
			времени их проведения, не влечет аннулирование результатов тестов.</p><br>
			
<p style="text-indent:2em;">(7) Кандидат на должность менеджера по автотранспортным перевозкам, подтверждающий обладание дипломом 
			о высшем образовании в области автомобильного транспорта, освобождается от необходимости прохождения курса
			начальной подготовки и повышения квалификации. В течение до трех лет после получения высшего специального
			образования в области автотранспортных перевозок сертификат профессиональной компетенции выдается
			Агентством без посещения курсов начальной подготовки и без сдачи экзамена. В этом случае
			заявитель представляет Агентству копии удостоверения личности и диплома об образовании.</p><br>
			
<p style="text-indent:2em;">(7.1) Лица, предусмотренные частью (7), на основании заявления могут сдать оценочный тест с множественными 
			вариантами ответов в любом аккредитованном и зарегистрированном центре профессионального обучения, повышения
			квалификации и профессиональной аттестации.</p><br>
			
<p style="text-indent:2em;">(8) Тесты с множественными вариантами ответов, разработанные и утвержденные центрами профессионального обучения,
			повышения квалификации и профессиональной аттестации на основе программ обучения, считаются пройденными в случае
			получения не менее 75 процентов максимального количества баллов.</p><br>
			
<p style="text-indent:2em;">(9) В случае несдачи теста с множественными вариантами ответов менеджер по автотранспортным перевозкам или водитель 
			имеют право на повторную сдачу теста в срок не более 14 дней.</p><br>
			
<p style="text-indent:2em;">(10) В случае несдачи повторного теста менеджер по автотранспортным перевозкам или водитель может попросить о новой
			сдаче теста только после посещения нового курса профессионального обучения.</p><br>
			
<p style="text-indent:2em;">(11) Сертификат профессиональной компетенции удерживается инспекторами Агентства и аннулируется в случае совершения
			водителем в течение 12 последовательных месяцев более пяти правонарушений, касающихся режима автотранспортных
			перевозок.</p>
			
<p style="text-indent:2em;"></p><h6>(12) Кандидаты на должность менеджера по автотранспортным перевозкам допускаются к сдаче теста с множественными
			вариантами ответов или, по обстоятельствам, к профессиональному обучению в случае,
			если они обладают:</h6><p></p>
			
<p style="text-indent:2em;">a) дипломом о высшем образовании в области права, экономики или в технической области либо дипломом об окончании
			профильного автотранспортного учреждения послесреднего и послесреднего нетретичного профессионально-технического 
			образования;</p><br>
			
<p style="text-indent:2em;">b) действительным свидетельством (F 086/e) о медицинском соответствии и наркологическим консультативным
			заключением.</p><br>
			
<p style="font-weight:500;">Ст. 44. – (1) Условия профессионального обучения менеджеров по автотранспортным перевозкам или водителей
			в целях получения соответствующего международным стандартам в данной области сертификата
			профессиональной компетенции, образец которого установлен приказом центрального
			отраслевого органа, необходимого для осуществления деятельности в качестве
			менеджера по автотранспортным перевозкам или водителя по автотранспортным 
			перевозкам, устанавливаются центральным отраслевым органом.</p><br>
			
<p style="text-indent:2em;">(2) Профессиональная подготовка обеспечивается аккредитованными и зарегистрированными
			центрами профессионального обучения, повышения квалификации и профессиональной 
			аттестации на основании программ обучения, утвержденных центральным отраслевым 
			органом и Министерством образования, культуры и исследований.</p><br>
			
<p style="font-weight:500;">Ст. 45. – (1) Сертификат профессиональной компетенции выдается сроком на
			три года.</p><br>
			
<p style="text-indent:2em;">(2) Центры профессионального обучения, повышения квалификации и профессиональной
			аттестации ведут строгий учет лиц, прошедших курсы обучения и/или сдавших экзамены,
			с информированием Агентства в установленной им форме.</p>

<p></p><h4>Раздел IV</h4><p></p>

<p></p><h4>ОСУЩЕСТВЛЕНИЕ АВТОТРАНСПОРТНЫХ ПЕРЕВОЗОК</h4><p></p>

<p></p><h4>Глава 1. Общие положения и условия</h4><p></p>

<p style="font-weight:500;">Ст. 46. – (1) Автотранспортные перевозки пассажиров могут осуществляться только при соблюдении
			положений настоящего кодекса, действующих правил в области автотранспортных перевозок, а также
			международных соглашений и конвенций, стороной которых является Республика Молдова.</p><br>
			
<p style="text-indent:2em;">(2) Автотранспортные перевозки грузов и пассажиров на платной основе могут осуществляться только
			транспортными средствами, предназначенными для соответствующих видов перевозок, оснащенными
			тахографами и ограничителями скорости, за исключением дорожных транспортных средств,
			зарегистрированных для осуществления таксомоторных автотранспортных перевозок, которые
			оснащаются таксометрами.</p><br>
			
<p style="text-indent:2em;">(3) Исключение в отношении оснащения тахографами составляют дорожные транспортные средства,
			осуществляющие автотранспортные перевозки пассажиров по регулярным маршрутам на расстояние 
			до 50 км.</p><br>
			
<p style="text-indent:2em;">(4) Транспортные средства могут осуществлять автотранспортные перевозки в соответствии с настоящим
			кодексом, только если они зарегистрированы и их техническое состояние соответствует техническим 
			нормам по безопасности дорожного движения и охране окружающей среды.</p><br>
			
<p style="text-indent:2em;">(5) Парковка автобусов, используемых для перевозки пассажиров, а также дорожных транспортных средств,
			предельно допустимая общая масса которых превышает 3,5 тонны, используемых для автотранспортных
			перевозок грузов, разрешена только в специально отведенных для этих целей местах. Запрещается
			стоянка и парковка таковых на автомобильных дорогах общего пользования и на прилегающих
			к ним участках на территории населенного пункта.</p><br>
			
<p style="text-indent:2em;">(6) Национальное агентство автомобильного транспорта обеспечивает контроль автотранспортных
			перевозок грузов и пассажиров иностранными автотранспортными операторами на территории 
			Республики Молдова (в качестве транзитной страны или страны назначения), который может
			осуществляться только на основании единых международных двусторонних или многосторонних 
			разрешений на автотранспортные перевозки грузов или пассажиров, если международные договоры, 
			стороной которых является Республика Молдова, предусматривают обязательность таковых.</p><br>
			
<p style="text-indent:2em;">(7) Разрешается въезд/выезд на территорию/с территории Республики Молдова (как страны транзита 
			или назначения) иностранным автотранспортным операторам только на основании однократных международных
			двусторонних или многосторонних разрешений на автотранспортную перевозку грузов или пассажиров, если
			международные договоры, стороной которых является Республика Молдова, предусматривают их обязательность,
			при этом проверка соответствующих разрешений осуществляется Таможенной службой и Пограничной полицией в
			соответствии с предусмотренными частями (2) и (3) статьи 1522 полномочиями. Разрешается въезд/выезд на
			территорию/с территории Республики Молдова (как страны транзита или назначения) иностранным автотранспортным
			операторам, зарегистрированным в государствах, с которыми Республика Молдова не подписала двусторонние 
			или многосторонние соглашения, на основании разрешений на автотранспортную перевозку 
			грузов или пассажиров с уплатой дорожных сборов, выданных при пересечении границы.</p><br>
			
<p style="font-weight:500;">Ст. 47. – (1) Автотранспортные перевозки в каботажном режиме запрещены на территории Республики
			Молдова для иностранных перевозчиков, если двусторонними или, по обстоятельствам, международными
			соглашениями и конвенциями, стороной которых является Республика Молдова, 
			не предусмотрено иное.</p><br>
			
<p style="text-indent:2em;">(11) При установлении факта осуществления иностранным автотранспортным оператором автотранспортных
			перевозок в каботажном режиме Агентство отзывает разрешение на автотранспортные перевозки 
			пассажиров по регулярным маршрутам, если международными договорами, стороной которых 
			является Республика Молдова, не предусмотрено иное.</p>
			
<p style="text-indent:2em;"></p><h6>(2) В отступление от положений части (1) разрешение на каботаж выдается на следующие услуги:</h6><p></p>
			
<p style="text-indent:2em;">a) автотранспортные перевозки пассажиров по специальным регулярным маршрутам, при условии,
			что они являются объектом соглашения, заключенного между организатором и автотранспортным
			оператором;</p><br>
			
<p style="text-indent:2em;">b) автотранспортные перевозки пассажиров по регулярным маршрутам, предоставляемые иностранным
			автотранспортным оператором в рамках международных автотранспортных перевозок пассажиров по
			регулярным маршрутам, осуществляемых в соответствии с положениями настоящего кодекса и
			двусторонних соглашений.</p>
			
<p style="text-indent:2em;"></p><h6>(3) Если положениями двусторонних соглашений, стороной которых является Республика Молдова
			, не предусмотрено иное, осуществление каботажных операций, предусмотренных в части (2),
			подпадает под действие национального законодательства в отношении:</h6><p></p>
			
<p>a) условий, регулирующих договор об автотранспортной перевозке;</p><br>
			
<p>b) допустимых общей массы и габаритов дорожных транспортных средств.</p>
			
<p style="text-indent:2em;"></p><h6>Ст. 48. – Автотранспортный оператор, авторизованный на основе официальной регистрации, 
			обязан иметь в своем офисе, в зависимости от видов осуществляемых перевозок, 
			следующие документы:</h6><p></p>
			
<p>a) накладные (CMR) за текущий год деятельности;</p><br>
			
<p style="text-indent:2em;">b) контрольный документ INTERBUS, в оригинале, и путевые листы для используемого 
			контрольного документа INTERBUS за текущий год деятельности (книжка INTERBUS);</p><br>
			
<p style="text-indent:2em;">c) контрольный документ, предусмотренный двусторонними соглашениями для международных 
			нерегулярных автотранспортных перевозок пассажиров на платной основе, в случае государств,
			не подписавших Соглашение INTERBUS, за текущий год деятельности (книжка с путевыми 
			листами (CFP));</p><br>
			
<p style="text-indent:2em;">d) платежный документ, контрольный лист, в оригинале, и путевые листы, в случае нерегулярной
			автотранспортной перевозки пассажиров в национальном сообщении, на текущий год;</p><br>
			
<p style="text-indent:2em;">e) договор страхования перевозимых пассажиров и их багажа в соответствии с действующим 
			законодательством;</p><br>
			
<p style="text-indent:2em;">f) свидетельства о регистрации дорожных транспортных средств, в копиях, и документы в 
			оригинале, подтверждающие обладание транспортными средствами на любых условиях;</p><br>
			
<p style="text-indent:2em;">g) действительные медицинские и наркологические консультативные заключения на менеджера 
			по автотранспортным перевозкам и работающих водителей;</p><br>
			
<p style="text-indent:2em;">h) все документы, подтверждающие текущее соблюдение условий, послуживших основанием для 
			официальной регистрации или для получения разрешений в рамках разрешительного режима;</p><br>
			
<p style="text-indent:2em;">i) транспортно-экспедиционная ведомость и бланк разрешения на перевозку, в случае 
			автотранспортной перевозки опасных отходов, на текущий и прошедший годы;</p><br>
			
<p style="text-indent:2em;">j) документы, подтверждающие наем менеджера по автотранспортным перевозкам и водителей 
			на условиях, предусмотренных законодательством;</p><br>
			
<p style="text-indent:2em;">k) договор об обладании на любых других условиях, кроме собственности, в случае, 
			когда дорожное транспортное средство не является собственностью, в соответствии 
			с действующим законодательством;</p><br>
			
<p style="text-indent:2em;">l) договор, заключенный с заказчиком автотранспортной перевозки, в случае 
			автотранспортных перевозок пассажиров по специальным регулярным маршрутам;</p><br>
			
<p>m) журналы учета ежедневного технического контроля транспортных средств;</p><br>
			
<p>n) билетно-учетные ведомости и путевые листы;</p>
			
<p style="text-indent:2em;"></p><h6>Ст. 49. – Автотранспортные операторы/предприятия, осуществляющие операции
			по автотранспортным перевозкам, обязаны:</h6><p></p>
			
<p style="text-indent:2em;">a) руководствоваться настоящим кодексом и действующими правилами для 
			каждого вида осуществляемой автотранспортной перевозки;</p><br>
			
<p style="text-indent:2em;">b) обеспечивать наличие на борту транспортного средства документов, на 
			основании которых разрешено осуществление деятельности, в зависимости от 
			вида автотранспортной перевозки;</p><br>
			
<p style="text-indent:2em;">c) обеспечивать подачу и отправление в рейс транспортных средств в надлежащем 
			техническом и санитарном состоянии, обеспечивать перевозку пассажиров в 
			условиях безопасности и комфорта, согласно утвержденному графику и 
			действующим в данной области правилам;</p><br>
			
<p style="text-indent:2em;">d) при наличии в населенном пункте нескольких автовокзалов заключать договор 
			с одним из них в целях предоставления услуг по автотранспортным перевозкам 
			пассажиров по регулярным маршрутам, закрепленным в установленном порядке,
			и незамедлительно уведомлять Агентство о заключенном договоре;</p><br>
			
<p style="text-indent:2em;">e) заключать со страховыми организациями договоры об обязательном страховании 
			перевозимых лиц и багажа в соответствии с действующим законодательством;</p><br>
			
<p style="text-indent:2em;">f) формировать экипажи водителей, обладающих сертификатами профессиональной компетенции,
			выданными в соответствии с настоящим кодексом;</p><br>
			
<p style="text-indent:2em;">g) обеспечивать непрерывное профессиональное обучение и повышение квалификации менеджера
			по автотранспортным перевозкам и водителей;</p><br>
			
<p style="text-indent:2em;">h) иметь в наличии и применять программу технического обслуживания автотранспортных средств,
			согласно требованиям и рекомендациям производителей;</p><br>
			
<p style="text-indent:2em;">i) обеспечивать проведение медицинского осмотра водителя до начала автотранспортной перевозки
			на основе нового путевого листа. Критерии, по которым водители подвергаются медицинскому
			осмотру, утверждаются инструкцией центрального отраслевого органа после консультаций
			с Министерством здравоохранения, труда и социальной защиты; обеспечивать проведение
			общей технической проверки каждого дорожного транспортного средства до начала
			автотранспортной перевозки на основе нового путевого листа, за исключением
			дорожных транспортных средств, действующих на международных маршрутах
			, техническая проверка которых осуществляется перед каждым выходом в рейс.
			Критерии технической проверки автотранспортных средств утверждаются
			инструкцией центрального отраслевого органа.</p><br>
			
<p style="text-indent:2em;">Путевой лист для автотранспортной перевозки грузов в национальном сообщении
			действителен в течение 48 часов с момента выдачи.</p><br>
			
 <p style="text-indent:2em;">Путевой лист для автотранспортной перевозки пассажиров в национальном сообщении 
			действителен в течение 36 часов (за исключением таксомоторных автотранспортных 
			перевозок, действительность путевого листа которых не может превышать 24 часов).</p><br>
			
<p style="text-indent:2em;">Путевой лист для автотранспортной перевозки грузов и пассажиров в международном
			сообщении действителен до завершения рейса в оба конца;</p><br>
			
<p>j) соблюдать действующие правила по осуществлению периодического технического осмотра;</p><br>
			
<p style="text-indent:2em;">k) в период между двумя периодическими техническими осмотрами обеспечивать содержание
			дорожных транспортных средств в надлежащем техническом состоянии в целях соответствия
			техническим нормам;</p><br>
			
<p style="text-indent:2em;">l) устанавливать с помощью предприятий, получивших на то разрешение, тахографы и ограничители
			скорости утвержденной модели на дорожные транспортные средства, для которых они обязательны;</p><br>
			
<p style="text-indent:2em;">m) не допускать отправления в рейс дорожных транспортных средств с поврежденным тахографом
			или ограничителем скорости, не опечатанных или без достаточного количества 
			регистрационных карточек для всего пути следования дорожного транспортного
			средства при осуществлении автотранспортной перевозки;</p><br>
			
<p>n) информировать водителей и мобильных работников о правилах в области
			автотранспортных перевозок;</p><br>
			
<p style="text-indent:2em;">о) проверять на регистрационных карточках или на используемых тахографических
			карточках водителей порядок соблюдения ими положений действующего законодательства
			о периодах вождения и отдыха и не допускать каких-либо действий неуполномоченных
			лиц в отношении тахографов и ограничителей скорости;</p><br>
			
<p style="text-indent:2em;">p) осуществлять автотранспортные перевозки только при соблюдении предельно допустимой
			общей массы и/или пределов загрузки, предусмотренных заводом-изготовителем
			используемого транспортного средства и/или действующими стандартами;</p><br>
			
<p style="text-indent:2em;">q) соблюдать правила и процедуры, установленные для дорожных транспортных средств
			или грузов, вес и/или габариты которых превышают допустимые пределы;</p><br>
			
<p style="text-indent:2em;">r) соблюдать и применять действующие правила автотранспортных перевозок опасных и
			скоропортящихся грузов;</p><br>
			
<p style="text-indent:2em;">s) обеспечивать посадку пассажиров в населенных пунктах, в которых имеются автовокзалы,
			только на территории автовокзалов, кроме автотранспортной перевозки в местном и 
			муниципальном сообщении, с соблюдением положений настоящего кодекса, а в населенных
			пунктах, в которых не имеется автовокзалов, – только на общественных остановках,
			а в случае отсутствия таковых (при условии включения населенного пункта
			в график движения) – в местах, обеспечивающих безопасность перевозимых лиц;</p><br>
			
<p style="text-indent:2em;">t) обеспечивать автотранспортные средства, осуществляющие автотранспортные
			перевозки пассажиров по регулярным маршрутам, указателями маршрута по образцу,
			установленному центральным отраслевым органом публичного управления в области
			автотранспортных перевозок.</p>

<p></p><h4>Глава 2</h4><p></p>

<p></p><h4>АВТОТРАНСПОРТНЫЕ ПЕРЕВОЗКИ ГРУЗОВ И ПОСЫЛОК</h4><p></p>

<p style="font-weight:500;">Ст. 50. – (1) Автотранспортные операторы организовывают свою деятельность самостоятельно, начиная со спроса и предложения услуг по автотранспортной перевозке грузов.</p><br>
<p style="text-indent:2em;">(2) Автотранспортные операторы принимают к перевозке груз на основании договоров об автотранспортных перевозках, заключенных с грузоотправителями, грузополучателями
			или их законными представителями. Они обеспечивают перевозку грузов, предусмотренных договором, за исключением случаев стихийных бедствий, катастроф,
			аварий и форс-мажорных обстоятельств.</p><br>
<p>(3) Автотранспортные операторы могут заключать договоры о перевозке грузов с любым грузополучателем.</p><br>
<p style="font-weight:500;">Ст. 51. – (1) Перевозка скоропортящихся грузов осуществляется в порядке и в случаях, предусмотренных Правилами автотранспортных перевозок скоропортящихся и
			особо скоропортящихся грузов и международными соглашениями и конвенциями, стороной которых является Республика Молдова.</p><br>
<p style="text-indent:2em;">(2) Опасные грузы принимаются к перевозке автотранспортным оператором в порядке и в случаях, предусмотренных Правилами автотранспортных перевозок опасных
			грузов и международными соглашениями и конвенциями, стороной которых является Республика Молдова.</p><br>
<p style="text-indent:2em;">(3) Сверхтяжелые и негабаритные грузы принимаются к перевозке автотранспортным оператором в порядке и в случаях, предусмотренных Положением об осуществлении
			перевозок грузов, вес и/или габариты которых превышают установленные пределы, по автомобильным дорогам общего пользования.</p><br>
<p style="font-weight:500;">Ст. 52. – Перевозка однородных грузов от одного и того же грузоотправителя одному и тому же грузополучателю на одно и то же расстояние может оформляться одним 
			грузосопроводительным документом на весь объем выполненных транспортным средством работ во время одного рейса в порядке, установленном Правилами автотранспортных
			перевозок грузов.</p><br>
<p style="font-weight:500;">Ст. 53. – Заказчик перевозки грузов обязан предоставить в распоряжение автотранспортного оператора необходимые сопроводительные документы для
			представленных к перевозке грузов.</p><br>
<p style="font-weight:500;">Ст. 54. – Использование дорожного транспортного средства с почасовой оплатой регистрируется грузоотправителем и/или грузополучателем в
			грузосопроводительных документах, с указанием пройденного пути и времени нахождения дорожного транспортного средства в распоряжении
			грузоотправителя и/или грузополучателя.</p><br>
<p style="font-weight:500;">Ст. 55. – Экипаж дорожного транспортного средства обязан проверить соответствие количества грузов, цельности упаковки, декларируемой
			в документах, штабелирование и крепление грузов в дорожном транспортном средстве требованиям дорожной безопасности. Грузоотправитель
			по требованию водителя обязан устранить выявленные недостатки в штабелировании и креплении грузов. Если требования об устранении
			выявленных недостатков не выполнены, водитель транспортного средства обязан отметить этот факт во всех экземплярах сопроводительных
			документов и отказаться от выполнения автотранспортной операции.</p><br>
 <p style="font-weight:500;">Ст. 56. – (1) Вес груза считается правильно рассчитанным, если разница между весом в пункте отправления и весом в пункте назначения
			не превышает пределы, установленные стандартами естественной убыли для соответствующей категории груза.</p><br>
<p style="text-indent:2em;">(2) Если в пункте назначения при проверке веса, объема и/или состояния грузов установлены утрата, порча и/или повреждение грузов или
			если эти обстоятельства указаны в акте, составленном во время перевозки, автотранспортный оператор обязан определить объем утерянных,
			испорченных и/или поврежденных грузов.</p><br>
<p style="text-indent:2em;">(3) В случае, когда необходима экспертиза перевозимых грузов, автотранспортный оператор, по собственной инициативе или по требованию
			грузополучателя, приглашает соответствующих экспертов.</p><br>
<p style="text-indent:2em;">(4) Грузополучатель вправе пригласить эксперта, уведомив автотранспортного оператора относительно даты и времени проведения
			экспертизы.</p><br>
<p style="font-weight:500;">Ст. 57. – (1) Сопровождение грузов может организовываться грузоотправителем и/или грузополучателем, а также автотранспортным
			оператором согласно положениям заключенного договора и действующего законодательства.</p><br>
<p>(2) Сопровождение опасных грузов организуется в соответствии с действующим законодательством.</p>
<p style="text-indent:2em;"></p><h6>Ст. 58. – Для осуществления автотранспортных перевозок грузов на платной основе в национальном сообщении автотранспортный оператор
			и/или водитель должны обеспечить обязательное наличие на борту дорожного транспортного средства:</h6><p></p>
<p>a) выписки из Реестра;</p><br>
<p>b) товарно-транспортной накладной;</p><br>
<p style="text-indent:2em;">с) действительного сертификата профессиональной компетенции водителя для осуществления автотранспортных перевозок грузов в национальном или международном сообщении;</p><br>
<p>d) путевого листа, предусмотренного Правилами автотранспортных перевозок грузов образца.</p>
<p style="text-indent:2em;"></p><h6>Ст. 59. – Для осуществления автотранспортных перевозок грузов на платной основе в международном сообщении автотранспортный оператор и/или водитель должны обеспечить наличие на борту дорожного транспортного средства:</h6><p></p>
<p>a) выписки из Реестра;</p><br>
<p style="text-indent:2em;">b) товарно-транспортной накладной CMR, предусмотренной Конвенцией о договоре международной дорожной перевозки грузов (КДПГ), к которой Республика Молдова присоединилась Постановлением Парламента № 1318-XII от 2 марта 1993 года.</p><br>
<p style="text-indent:2em;">c) действительного сертификата профессиональной компетенции водителя для осуществления автотранспортных перевозок грузов в международном сообщении;</p><br>
<p style="text-indent:2em;">d) единых международных двусторонних или многосторонних разрешений на автотранспортную перевозку грузов в случае, когда международные соглашения предусматривают их обязательность.</p><br>
<p style="font-weight:500;">Ст. 60. – (1) В случае автотранспортных перевозок грузов в международном сообщении, осуществляемых иностранным автотранспортным оператором и/или водителем,
			разрешения на международные автотранспортные перевозки, при осуществлении которых Республика Молдова является страной транзита или назначения, выдаются в порядке,
			установленном действующими правилами и международными соглашениями.</p><br>
<p style="text-indent:2em;">(2) Разрешение на международные автотранспортные перевозки, при осуществлении которых Республика Молдова является страной транзита или назначения, образец которого
 предусмотрен Правилами автотранспортных перевозок грузов, действительно только в оригинале.</p>
<p style="text-indent:2em;"></p><h6>Ст. 61. – Для осуществления автотранспортных перевозок грузов за собственный счет предприятие и/или водитель должны обеспечить наличие на борту дорожного транспортного средства:</h6><p></p>
<p style="text-indent:2em;">b) товарно-транспортной накладной CMR для международных автотранспортных перевозок грузов за собственный счет;</p><br>
<p style="text-indent:2em;">c) действительного сертификата профессиональной компетенции водителя для осуществления автотранспортных перевозок грузов в национальном и/или международном сообщении, по обстоятельствам;</p><br>
<p style="text-indent:2em;">d) единых международных двусторонних или многосторонних разрешений на автотранспортную перевозку грузов в случае, когда международные соглашения предусматривают их обязательность для
 международных автотранспортных перевозок грузов за собственный счет.</p>
<p style="text-indent:2em;"></p><h6>Ст. 62. – Для осуществления автотранспортных перевозок опасных грузов автотранспортный оператор и/или водитель либо предприятие и/или водитель обязаны дополнительно обеспечить
			наличие на борту дорожного транспортного средства:</h6><p></p>
<p style="text-indent:2em;">a) сертификата допуска транспортных средств, перевозящих опасные грузы, образец которого предусмотрен Правилами автотранспортных перевозок опасных грузов;</p><br>
<p>b) сертификата профессиональной компетенции водителя, предусмотренного для перевозки опасных грузов;</p><br>
<p style="text-indent:2em;">c) документации на перевозку опасных грузов, предусмотренной специальными правилами автотранспортных перевозок опасных грузов;</p><br>
<p style="text-indent:2em;">d) письменных инструкций для водителя, предусмотренных специальными правилами автотранспортных перевозок опасных грузов.</p><br>
<p style="font-weight:500;">Ст.621. – (1) Запрещаются автотранспортные перевозки по автомобильным дорогам общего пользования, осуществляемые зарегистрированными в Республике Молдова или в других государствах 
дорожными транспортными средствами, с превышением предельно допустимых общей массы, нагрузки на ось и/или габаритов, предусмотренных Законом об автомобильных дорогах.</p><br>
<p style="text-indent:2em;">(2) В отступление от положений части (1) автотранспортные перевозки по автомобильным дорогам общего пользования зарегистрированными в Республике Молдова или в других
			государствах дорожными транспортными средствами с превышением предельно допустимых общей массы, нагрузки на ось и/или габаритов могут осуществляться только на основании
 специального разрешения, выданного Агентством, на условиях, предусмотренных Положением об осуществлении автотранспортных перевозок грузов, вес и/или габариты которых превышают установленные пределы,
 по автомобильным дорогам общего пользования, сугубо для перевозки неделимых грузов или в случае, когда дорожное транспортное средство конструктивно превышает предусмотренные предельно допустимые массу и/или габариты.</p><br>
<p style="text-indent:2em;">(3) Для получения специального разрешения заявитель подает заявление образца, предусмотренного Положением о порядке выдачи разрешения, контроле и осуществлении по дорогам общего
			пользования перевозок, масса и/или размеры которых превышают предельно допустимые значения, с внесением соответствующей платы. Для выдачи разрешения Агентство посредством механизма
			единого окна проводит без привлечения заявителя все необходимые согласования и получает разрешения в Министерстве внутренних дел и, при необходимости, в других ведомствах.</p><br>
<p style="text-indent:2em;">(4) В части, не урегулированной настоящим кодексом, получение специального разрешения хозяйствующими субъектами или его отзыв осуществляются в соответствии с положениями Закона 
			о регулировании предпринимательской деятельности путем разрешения № 160/2011.</p>
<p style="text-indent:2em;"></p><h6>Ст.622. – (1) Хозяйствующие субъекты, поставляющие делимые грузы (поставщики делимых грузов) по сети автомобильных дорог общего пользования дорожными транспортными средствами
			общей массой, равной или превышающей 12 тонн, обязаны:</h6><p></p>
<p style="text-indent:2em;">a) располагать в местах погрузки оборудованием для взвешивания делимых грузов и/или дорожных транспортных средств, используемых для перевозки данных грузов;</p><br>
<p style="text-indent:2em;">b) выдавать весовой талон с печатью и подписью на каждое дорожное транспортное средство, используемое для перевозки делимых грузов, отправляемое из собственных мест
			погрузки;</p><br>
<p>c) вести учет весовых талонов и бухгалтерских документов на поставленные делимые грузы;</p><br>
<p style="text-indent:2em;">d) загружать используемые для перевозки делимых грузов дорожные транспортные средства таким образом, чтобы общая масса дорожного транспортного средства не превышала
			предельно допустимую общую массу, соответствующую участку автомобильной дороги общего пользования, к которому оборудован подъезд к месту погрузки;</p><br>
<p style="text-indent:2em;">e) обеспечивать условия для очистки колес дорожных транспортных средств до их выезда на автомобильную дорогу общего пользования;</p><br>
<p style="text-indent:2em;">f) не допускать к выезду из места погрузки на автомобильную дорогу общего пользования дорожных транспортных средств, из которых высыпаются делимые грузы или производные от них отходы.</p>
<p style="text-indent:2em;"></p><h6>(2) Получатели делимых грузов, поставляемых по сети автомобильных дорог общего пользования дорожными транспортными средствами общей массой, равной или превышающей 12 тонн, обязаны:</h6><p></p>
<p style="text-indent:2em;">a) не допускать к месту разгрузки используемые для перевозки делимых грузов дорожные транспортные средства без выданного поставщиком весового талона или, по обстоятельствам,
			с превышением предельно допустимой общей массы, соответствующей участку автомобильной дороги общего пользования, к которому оборудован подъезд к месту разгрузки, либо дорожные
			транспортные средства, к которым применена мера приостановления права их использования;</p><br>
<p style="text-indent:2em;">b) предоставлять представителям контролирующих органов учетную ведомость весовых талонов на полученные делимые грузы, а также доказательство ее соответствия бухгалтерским
			документам на полученные делимые грузы;</p><br>
<p style="text-indent:2em;">c) обеспечивать условия для очистки колес дорожных транспортных средств до их выезда на дорогу общего пользования.</p>
<p style="text-indent:2em;"></p><h6>(3) При осуществлении автотранспортной перевозки делимых грузов дорожными транспортными средствами общей массой, равной или превышающей 12 тонн, водитель обязан:</h6><p></p>
<p style="text-indent:2em;">a) иметь на борту транспортного средства и представлять представителям контролирующих органов весовой талон;</p><br>
<p style="text-indent:2em;">b) не управлять транспортным средством, груженным не накрытыми брезентом делимыми грузами, или транспортным средством, из которого высыпаются делимые грузы или производные от них отходы.</p>

<p></p><h4>Раздел V</h4><p></p>

<p></p><h4>СОПУТСТВУЮЩАЯ АВТОТРАНСПОРТНЫМ
ПЕРЕВОЗКАМ ДЕЯТЕЛЬНОСТЬ</h4><p></p>

<p></p><h4>Глава 1.</h4><p></p>

<p></p><h4>Общие положения</h4><p></p>

<p style="text-indent:2em;"></p><h6>Ст. 85. – Сопутствующими автотранспортным перевозкам видами деятельности являются:</h6><p></p>
<p>a) автовокзальная деятельность;</p><br>
<p>b) автосервисная деятельность;</p><br>
<p>c) деятельность по монтажу, ремонту и проверке тахографов и ограничителей скорости;</p><br>
<p style="text-indent:2em;">d) деятельность по взвешиванию дорожных транспортных средств в целях выдачи сертификата международного взвешивания дорожного транспортного средства
			согласно образцу, представленному в приложении 2 к настоящему кодексу;</p><br>
<p>e) деятельность по периодическому техническому осмотру.</p>

<p></p><h4>Глава 3.</h4><p></p> 

<p></p><h4>Автосервисная деятельность</h4><p></p>

<p style="font-weight:500;">Ст. 93. – (1) Положения настоящей главы применяются ко всем предприятиям, осуществляющим деятельность, которая может повлиять
			на конструктивные, функциональные и качественные параметры дорожных транспортных средств.</p><br>
<p>(2) Автосервисная деятельность может осуществляться только в специализированных мастерских.</p><br>
<p style="text-indent:2em;">(3) Технические требования к мастерским, предусмотренным частью (2), устанавливаются в Положении об осуществлении автосервисной деятельности.</p>
<p style="text-indent:2em;"></p><h6>Ст. 94. – (1) Виды автосервисной деятельности включают:</h6><p></p>
<p style="text-indent:2em;"></p><h6>a) ремонт, содержание и/или регулировку механических узлов:</h6><p></p>
<p>двигатель и прилегающие узлы;</p><br>
<p>трансмиссия и ее составляющие;</p><br>
<p>ходовая система и ее составляющие;</p><br>
<p>рулевое управление и его составляющие;</p><br>
<p>тормозная система и ее составляющие;</p>
<p style="text-indent:2em;"></p><h6>b) ремонт, содержание, регулировку электрического и/или электронного оборудования:</h6><p></p>
<p>электрооборудование для запуска двигателя, зарядки аккумуляторных батарей и его составные части;</p><br>
<p>электроустановка для освещения и сигнализации и ее составляющие;</p><br>
<p>система электронного управления двигателем и/или системами дорожного транспортного средства;</p>
<p style="text-indent:2em;"></p><h6>c) ремонт структуры кузова и/или замена шасси и/или кузова:</h6><p></p>
<p>d) ремонт автомобильных шин;</p><br>
<p>e) разборка снятых с эксплуатации транспортных средств и их составляющих;</p><br>
<p>f) переоборудование автотранспортных средств;</p><br>
<p>g) работы по содержанию.</p><br>
<p style="text-indent:2em;">(2) Виды деятельности, предусмотренные частью (1), могут осуществляться только в случае предварительной регистрации в Реестре на основании
			уведомления в соответствии с процедурой, предусмотренной главой 2 раздела III.</p><br>
<p style="text-indent:2em;">(3) Для осуществления видов деятельности, предусмотренных частью (1) настоящей статьи, автосервисные мастерские должны соответствовать
			предусмотренным статьей 96 требованиям, уведомлять Агентство в течение 15 дней об осуществляемых видах деятельности с указанием адреса и имени
			менеджера, ответственного за автосервисную деятельность, а также о любых последующих изменениях в этих данных.</p><br>
<p style="text-indent:2em;">(4) Осуществление автосервисной деятельности с нарушением положений частей (2) и (3) влечет ответственность за правонарушения.</p>
<p style="text-indent:2em;"></p><h6> Ст. 96. – Автосервисная деятельность может осуществляться предприятиями, которые отвечают в совокупности следующим условиям:</h6><p></p>
<p>a) наличие хорошей репутации и профессиональной компетенции;</p><br>
<p>b) наличие технического оснащения согласно Положению об осуществлении автосервисной деятельности;</p>
<p style="text-indent:2em;"></p><h6>c) наличие подходящих помещений для:</h6><p></p>
<p style="text-indent:2em;"> размещения дорожных транспортных средств и/или изделий, подлежащих ремонту, восстановлению и/или регулировке и/или уже отремонтированных,
			восстановленных и/или отрегулированных;</p><br>
<p style="text-indent:2em;"> хранения и учета материалов и запасных частей, необходимых для выполнения ремонта или восстановления дорожных транспортных средств;</p><br>
<p style="text-indent:2em;"> проведения работ по ремонту, содержанию, восстановлению и/или функциональной регулировке дорожных транспортных средств;</p>
<p style="text-indent:2em;"></p><h6>Ст. 97. – Условие профессиональной компетенции предприятия, которое осуществляет виды деятельности, предусмотренные в пунктах e) и f) части (1)
			статьи 94, считается выполненным, если менеджер, ответственный за деятельность автосервисной мастерской, в зависимости от предоставляемых услуг:</h6><p></p>
<p style="text-indent:2em;">a) обладает действительным сертификатом профессиональной компетенции, выданным в порядке, предусмотренном в статье 43, по образцу,
			предусмотренному в Положении об осуществлении автосервисной деятельности;</p><br>
<p>b) имеет квалификацию инженера или техника в области автомобильного транспорта;</p><br>
<p style="text-indent:2em;">c) имеет трудовой стаж не менее двух лет в сфере ремонта и технического обслуживания дорожных транспортных средств.</p><br>
<p style="font-weight:500;">Ст. 98. – (1) Лица с высшим образованием в автотехнической области и со стажем работы более пяти лет в данной области могут руководить мастерскими,
			в которых осуществляется деятельность, которая может повлиять на конструктивные, функциональные и качественные параметры дорожных транспортных средств,
			на основании сертификата профессиональной компетенции, без прохождения теста с множественными вариантами ответов.</p><br>
<p>(2) Срок действия сертификата профессиональной компетенции составляет три года.</p><br>
<p style="text-indent:2em;">(3) Лицо, чей сертификат профессиональной компетенции трижды аннулировался, не имеет права руководить автосервисной деятельностью в течение одного
			года.</p><br>
<p style="font-weight:500;">Ст. 99. – Условия профессионального обучения менеджера, ответственного за деятельность автосервисной мастерской, в целях получения сертификата 
			профессиональной компетенции устанавливаются центральным отраслевым органом и утверждаются Министерством образования, культуры и исследований.</p>
<p style="text-indent:2em;"></p><h6>Ст. 100. – (1) В целях официальной регистрации на осуществление автосервисной деятельности, предусмотренной пунктами e) и f) части (1) статьи 94,
			предприятие, зарегистрированное в установленном порядке в Республике Молдова, подает в Агентство на каждую мастерскую регистрационный пакет, содержащий:</h6><p></p>
<p>a) уведомление;</p><br>
<p style="text-indent:2em;">b) копию сертификата профессиональной компетенции менеджера, ответственного за деятельность автосервисной мастерской;</p><br>
<p style="text-indent:2em;">c) декларацию под собственную ответственность о выполнении условия наличия хорошей репутации в соответствии со статьей 18;</p><br>
<p>d) перечень приборов и оборудования, которыми оснащена мастерская;</p><br>
<p style="text-indent:2em;">e) копии свидетельств о метрологической поверке оборудования и средств измерения и копии свидетельств о метрологическом освидетельствовании 
			стендов и испытательного оборудования;</p><br>
<p>f) схему мастерской в масштабе 1:100, с расположением приборов и оборудования мастерской;</p><br>
<p style="text-indent:2em;">(2) Официальная регистрация предприятий, намеренных осуществлять иные виды автосервисной деятельности, нежели предусмотренные пунктами e) и f)
			части (1) статьи 94, производится только на основании уведомления, в котором указаны вид выполняемой деятельности (в соответствии со статьей 94)
			, адрес предприятия и имя менеджера, ответственного за автосервисную деятельность.</p>
<p style="text-indent:2em;"></p><h6>Ст.102. – Регистрация может быть полностью или частично приостановлена в соответствии со статьей 28 только в отношении видов деятельности, 
			предусмотренных пунктами e) и f) части (1) статьи 94, на срок до 30 дней в случае:</h6><p></p>
<p>a) несоблюдения одного из условий, послуживших основанием для регистрации;</p><br>
<p>b) осуществления незарегистрированной деятельности;</p><br>
<p style="text-indent:2em;">с) установления Агентством и назначения им наказания окончательным решением в соответствии с законом за нарушение более трех раз в течение 12
			последовательных месяцев требований Положения об осуществлении автосервисной деятельности.</p>
<p style="text-indent:2em;"></p><h6>Ст.103. – (1) Предприятие может быть исключено из Реестра в соответствии со статьей 28 только в связи с видами деятельности, предусмотренными 
			пунктами e) и f) части (1) статьи 94, в случае, если:</h6><p></p>
<p style="text-indent:2em;">a) выявленные недостатки, послужившие основанием для частичного или полного приостановления регистрации, не устранены в сроки, установленные в
			решении о приостановлении;</p><br>
<p>b) регистрация приостанавливалась три раза в течение 12 последовательных месяцев.</p>
<p style="text-indent:2em;"></p><h6>(2) Предприятие может быть исключено из Реестра в соответствии со статьей 28 в связи со всеми видами деятельности, предусмотренными статьей 
			94, в случае, если:</h6><p></p>
<p style="text-indent:2em;">a) предприятие представило в момент уведомления подложные документы или документы, содержащие ложные сведения;</p><br>
<p>b) предприятие подало заявление о прекращении автосервисной деятельности;</p><br>
<p>c) предприятие прекратило свою деятельность.</p><br>
<p style="text-indent:2em;">(3) После исключения из Реестра предприятие может быть зарегистрировано только по истечении шестимесячного периода, в течение которого оно также
			не может запрашивать регистрацию другой мастерской для осуществления автосервисной деятельности.</p><br>
<p style="font-weight:500;">Ст. 104. – При ремонте, конструктивном изменении или переоборудовании дорожных транспортных средств, в случае вмешательства в системы, связанные
			с безопасностью движения, охраной окружающей среды, энергоэффективностью, авторизованные предприятия могут использовать только оборудование, компоненты,
			технические единицы, запасные части и расходные материалы, освидетельствованные или сертифицированные, по обстоятельствам, согласно действующему 
			законодательству.</p><br>
<p style="font-weight:500;">Ст. 105. – Предприятия, осуществляющие виды деятельности, предусмотренные статьей 94, обязаны выдавать по требованию заказчика гарантийные талоны на 
			выполненные работы, предусматривающие, если это необходимо, и гарантийные сроки на использованные компоненты – новые либо восстановленные, в 
			соответствии с законодательством.</p>
<p style="text-indent:2em;"></p><h6>Ст. 106. – Предприятия, зарегистрированные для осуществления видов деятельности по разборке снятых с эксплуатации транспортных средств, должны иметь
			дополнительные помещениями для хранения, в том числе временного, этих транспортных средств перед разборкой и для изделий и расходных материалов, 
			полученных после разборки, отвечающие следующим условиям:</h6><p></p>
<p style="text-indent:2em;">a) деление зоны приемки снятых с эксплуатации транспортных средств на две отдельные зоны: зона приема от собственников снятых с эксплуатации транспортных
			средств и зона временного хранения до разборки;</p><br>
<p style="text-indent:2em;">b) наличие водонепроницаемых помещений в зоне осуществления разборки и обезвреживания транспортного средства;</p><br>
<p>c) наличие соответствующих контейнеров для складирования аккумуляторных батарей;</p><br>
<p style="text-indent:2em;">d) наличие емкостей для раздельного хранения жидкостей со снятых с эксплуатации транспортных средств: топливо, моторное масло, масло из коробки передач, 
			трансмиссионное масло, гидравлическое масло, охлаждающие жидкости, антифриз, тормозная жидкость, жидкости системы кондиционирования воздуха и любые другие
			жидкости, содержащиеся в снятом с эксплуатации транспортном средстве;</p><br>
<p>e) наличие помещений для складирования отработанных шин.</p><br>
<p style="font-weight:500;">Ст. 107. – (1) Зарегистрированные предприятия, осуществляющие деятельность по разборке снятых с эксплуатации транспортных средств, обязаны выдавать на имя
			собственника разобранного транспортного средства свидетельство об уничтожении транспортного средства.</p><br>
<p style="text-indent:2em;">(2) Запрещается продажа с целью повторного использования систем, оборудования, составляющих, технических единиц и запасных частей, ведущего к нарушению 
			требований дорожной безопасности, установленных центральным отраслевым органом.</p>
<p style="text-indent:2em;"></p><h6>Ст. 108. – (1) Предприятия, зарегистрированные для осуществления деятельности по разборке снятых с эксплуатации дорожных транспортных средств, обязаны:</h6><p></p>
<p style="text-indent:2em;">a) обеспечивать строгий учет всех дорожных транспортных средств, для осуществления деятельности с которыми было зарегистрировано предприятие;</p><br>
<p>b) обеспечивать учет режима обработки кузовов/шасси, оставшихся после разборки;</p><br>
<p style="text-indent:2em;">c) позволять осуществление контроля уполномоченными органами и предоставлять им запрошенную информацию.</p><br>
<p>(2) Учет, предусмотренный в пунктах a) и b) части (1), ведется в двух отдельных журналах.</p><br>
<p style="text-indent:2em;">(3) Предприятия обязаны представить в течение 10 дней со дня разборки дорожного транспортного средства соответствующую информацию держателю Государственного
			регистра транспорта в целях исключения данного дорожного транспортного средства из регистра.</p><br>
<p style="text-indent:2em;">(4) Регистрация для осуществления деятельности по разборке снятых с эксплуатации дорожных транспортных средств выдается при условии соблюдения специальных
			условий по реутилизации, переработке или использованию частей, компонентов и расходных материалов, остающихся после разборки, в целях охраны окружающей среды, 
			обеспечения дорожной безопасности и энергосбережения, по обстоятельствам.</p><br>
<p style="font-weight:500;">Ст. 109. – Деятельность по переоборудованию дорожных транспортных средств осуществляется только на основе технологических карт на операции по переоборудованию,
			на которые запрашивается регистрация в соответствии с действующим законодательством.</p>

<p></p><h4>Глава 4.</h4><p></p> 

<p></p><h4>Деятельность по установке, ремонту и
проверке тахографов и ограничителей скорости</h4><p></p>

<p style="font-weight:500;">Ст. 110. – (1) Операции по установке, ремонту и/или проверке тахографов и/или ограничителей скорости могут осуществлять только предприятия, зарегистрированные
			Агентством в соответствии с положениями настоящего кодекса и осуществляющие деятельность на основании Положения об условиях установки, ремонта и проверки
			тахографов и ограничителей скорости.</p><br>
<p style="text-indent:2em;">(2) Допускаются к установке и использованию на территории Республики Молдова только тахографы, на которые имеются типовые сертификаты осмотра согласно действующему
			законодательству или которые были освидетельствованы в другой стране согласно процедурам освидетельствования, предусмотренным Европейским соглашением, касающимся 
			работы экипажей транспортных средств, производящих международные автомобильные перевозки (ЕСТР).</p><br>
<p style="text-indent:2em;">(3) Допускаются к установке и использованию на территории Республики Молдова только ограничители скорости, на которые имеются типовые сертификаты осмотра согласно 
			действующему законодательству или которые были освидетельствованы в другой стране согласно процедурам освидетельствования, предусмотренным Регламентом CEE-ONU № 89.</p><br>
<p style="text-indent:2em;">(4) Установленные на дорожном транспортном средстве тахографы и ограничители скорости должны проходить периодическую проверку один раз в два года в официально 
			зарегистрированных в Реестре мастерских на территории Республики Молдова.</p>
<p style="text-indent:2em;"></p><h6>Ст. 111. – В целях регистрации деятельности по установке, ремонту и проверке тахографов и ограничителей скорости предприятие, зарегистрированное в установленном 
			порядке в Республике Молдова, представляет в Агентство на каждую мастерскую пакет документов, содержащий:</h6><p></p>
<p>a) уведомление;</p><br>
<p style="text-indent:2em;">b) декларацию под собственную ответственность о выполнении условий наличия хорошей репутации в соответствии со статьей 18;</p><br>
<p style="text-indent:2em;">c) копию сертификатов профессиональной компетенции менеджера и экспертов, непосредственно задействованных в работах по установке, ремонту и/или проверке тахографов
			и/или ограничителей скорости, подтверждающих прохождение курсов обучения и повышения квалификации в центрах профессионального обучения, повышения квалификации и
			профессиональной аттестации по осуществлению деятельности по установке, ремонту и/или проверке тахографов и ограничителей скорости;</p><br>
<p>d) копии свидетельств о метрологической поверке используемых средств измерения;</p><br>
<p>e) копию схемы места ведения работ (мастерская и трек) в масштабе 1:100;</p><br>
<p style="font-weight:500;">Ст.112. – Уведомление и регистрация в Реестре деятельности по установке, ремонту и проверке тахографов и ограничителей скорости осуществляются в соответствии с
			положениями главы 2 раздела III бесплатно на неограниченный период времени.</p><br>
<p style="font-weight:500;">Ст. 114. – (1) Регистрируется деятельность по установке, ремонту и проверке тахографов и ограничителей скорости и на отдельные типы тахографов, по обстоятельствам.
			Агентство указывает в выписке из Реестра вид пломбы, которую мастерская ставит на тахографы.</p><br>
<p style="text-indent:2em;">(2) Зарегистрированные предприятия выдают клиентам гарантийный талон на тахографы и/или ограничители скорости, установленные мастерской, и/или на выполненные работы.</p><br>
<p style="font-weight:500;">Ст.115. – (1) Регистрация деятельности по установке, ремонту и проверке тахографов и ограничителей скорости может быть приостановлена частично или полностью в
			соответствии со статьей 28 на срок до 30 дней в случае повторного установления в течение 12 последовательных месяцев нарушения не менее одного из условий, на
			основании которых выполнена регистрация и осуществляется деятельность.</p>
<p style="text-indent:2em;"></p><h6>(2) Предприятие, выполняющее установку, ремонт и проверку тахографов и ограничителей скорости, может быть исключено из Реестра в соответствии со статьей 28 в случае, если:</h6><p></p>
<p style="text-indent:2em;">a) выявленные недостатки, послужившие основанием для частичного или полного приостановления регистрации, не устранены в сроки, установленные в решении о приостановлении;</p><br>
<p>b) регистрация приостанавливалась три раза в течение 12 последовательных месяцев;</p><br>
<p style="text-indent:2em;">c) предприятие представило в момент уведомления подложные документы или документы, содержащие ложные сведения;</p><br>
<p style="text-indent:2em;">d) предприятие подало заявление о прекращении деятельности по установке, ремонту и проверке тахографов и ограничителей скорости;</p><br>
<p>e) предприятие прекратило свою деятельность.</p><br>
<p style="text-indent:2em;">(3) После исключения из Реестра предприятие может быть зарегистрировано только по истечении шестимесячного периода, в течение которого оно также не может запрашивать
			регистрацию другой мастерской для осуществления деятельности по установке, ремонту и проверке тахографов и ограничителей скорости.</p><br>
<p style="font-weight:500;">Ст. 116. – В случае обновления, повреждения, плохого функционирования, утраты или кражи тахографической карточки, выданной зарегистрированным предприятиям, в течение
			15 рабочих дней со дня подачи письменного заявления выдается новая тахографическая карточка. Если новая тахографическая карточка выдается в целях замены истекшей, новая
			карточка сохраняет кодовый номер мастерской с увеличением порядкового номера на одну единицу.</p><br>
<p style="font-weight:500;">Ст. 117. – В период деятельности предприятие проверяется работниками Агентства на предмет порядка осуществления проверки тахографов и ограничителей скорости, в том
			числе путем контрольных проверок по месту осуществления работ.</p>

<p></p><h4>Глава. 5.</h4><p></p> 

<p></p><h4>Деятельность по взвешиванию дорожных
транспортных средств в целях выдачи международного
сертификата взвешивания дорожного транспортного средства</h4><p></p>

<p style="font-weight:500;">Ст. 118. – (1) Международный сертификат взвешивания дорожного транспортного средства вводится в соответствии с Международной конвенцией о согласовании условий 
			проведения контроля грузов на границах, принятой в Женеве 21 октября 1982 года, к которой Республика Молдова присоединилась Законом № 215-XVI от 23 октября 2008 
			года, в целях упрощения процедуры прохождения границы и, в частности, для избежания повторных процедур взвешивания грузовых дорожных транспортных средств, передвигающихся по территории договаривающихся сторон.</p><br>
<p style="text-indent:2em;">(2) Международный сертификат взвешивания дорожного транспортного средства выдается авторизованными Агентством предприятиями, располагающими специально
			обустроенными для этих целей станциями взвешивания.</p><br>
<p style="text-indent:2em;">(3) Использование международного сертификата взвешивания дорожных транспортных средств автотранспортными операторами является факультативным.</p>
<p style="text-indent:2em;"></p><h6>Ст. 119. – Разрешение на деятельность по взвешиванию дорожных транспортных средств в целях выдачи международного сертификата взвешивания дорожных транспортных
			средств выдается на пятилетний срок предприятиям, которые отвечают в совокупности следующим условиям:</h6><p></p>
<p>a) наличие хорошей репутации и профессиональной компетенции;</p><br>
<p>b) наличие подходящих помещений.</p>
<p style="text-indent:2em;"></p><h6>Ст. 120. – В целях получения разрешения предприятие, зарегистрированное в установленном порядке в Республике Молдова, представляет в Агентство на каждую 
			станцию по взвешиванию пакет документов, содержащий:</h6><p></p>
<p>a) заявление в установленной Агентством форме;</p><br>
<p style="text-indent:2em;">b) декларацию под собственную ответственность о выполнении условия наличия хорошей репутации в соответствии с статьей 18;</p><br>
<p style="text-indent:2em;">c) копии сертификатов профессиональной компетенции менеджера и экспертов, осуществляющих взвешивание, подтверждающих прохождение курсов обучения и повышения 
			квалификации в центрах профессионального обучения, повышения квалификации и профессиональной аттестации для осуществления деятельности по взвешиванию;</p><br>
<p>d) перечень приборов и оборудования из оснащения станции;</p><br>
<p style="text-indent:2em;">e) копии действительных свидетельств о метрологической поверке оборудования и средств измерения, а также копии свидетельств о метрологическом освидетельствовании
			стендов и испытательного оборудования;</p><br>
<p style="text-indent:2em;">f) копию схемы станции в масштабе 1:100, с указанием расположения приборов и оборудования, стоянки и прилегающих территорий.</p>
<p style="text-indent:2em;"></p><h6>Ст. 121. – Станции взвешивания должны быть оснащены приборами и оборудованием, соответствующими:</h6><p></p>
<p style="text-indent:2em;">a) рекомендациям МОЗМ R 76 «Неавтоматическое оборудование для взвешивания», класс точности III или выше; или
</p><p style="text-indent:2em;">b) рекомендациям МОЗМ R 134 «Автоматическое оборудование для взвешивания автотранспортных средств в движении», класс точности II или выше. В случае измерения веса,
			приходящегося на отдельную ось, допускаются более высокие значения погрешности.</p><br>
<p style="font-weight:500;">Ст. 122. – (1) Агентство рассматривает заявление на получение разрешения на деятельность по взвешиванию дорожных транспортных средств в целях выдачи международного
			сертификата взвешивания дорожных транспортных средств в течение трех рабочих дней и в зависимости от выполнения необходимых условий выдает разрешение или сообщает в
			письменном виде о мотивированном отказе.</p><br>
<p style="text-indent:2em;">(2) В случае выявления несоответствий в поданных документах Агентство уведомляет об этом заявителя в течение одного дня.</p><br>
<p style="text-indent:2em;">(3) В случае несогласия с решением Агентства заявитель в 30-дневный срок со дня сообщения ему решения вправе подать предварительное заявление с последующим обращением
			в судебную инстанцию в соответствии с действующим законодательством.</p><br>
<p style="text-indent:2em;">(4) За 30 дней до истечения срока действия разрешения предприятие обращается в Агентство с заявлением о продлении срока действия разрешения.</p><br>
<p style="text-indent:2em;">(5) После выдачи разрешения Агентство включает соответствующее предприятие в график плановых проверок на следующий квартал на предмет соответствия условиям получения
			разрешения.</p><br>
<p style="font-weight:500;">Ст. 123. – (1) Разрешение на деятельность по взвешиванию дорожных транспортных средств выдается на пятилетний срок.</p><br>
<p style="text-indent:2em;">(2) Образец разрешения на деятельность по взвешиванию дорожных транспортных средств и международного сертификата взвешивания дорожного транспортного средства 
			предусмотрены в приложениях 2 и 4 к настоящему кодексу.</p><br>
<p style="text-indent:2em;">(3) В период действия разрешения его обладатель вправе испрашивать прекращение деятельности, ограничение либо расширение сферы действия разрешения.</p><br>
<p>(4) Разрешение с истекшим сроком действия является недействительным.</p><br>
<p style="font-weight:500;">Ст. 124. – (1) Разрешение может быть приостановлено Агентством на срок до 30 дней в случае повторного установления в течение 12 последовательных месяцев нарушения
			условий, на основании которых выдано разрешение.</p>
<p style="text-indent:2em;"></p><h6>(2) Разрешение отзывается Агентством в случае, если:</h6><p></p>
<p style="text-indent:2em;">a) выявленные недостатки, послужившие основанием для приостановления разрешения, не были устранены в сроки, установленные в решении о приостановлении разрешения;</p><br>
<p style="text-indent:2em;">b) предприятие представило на момент запрашивания разрешения подложные документы или документы, содержавшие ложные сведения;</p><br>
<p>c) международный сертификат взвешивания дорожного транспортного средства содержит ложные сведения;</p><br>
<p style="text-indent:2em;">d) предприятие подало заявление о прекращении деятельности по взвешиванию дорожных транспортных средств в целях выдачи международного сертификата взвешивания 
			дорожных транспортных средств;</p><br>
<p>e) предприятие прекратило свою деятельность;</p><br>
<p>f) действие разрешения приостанавливалось три раза в течение 12 последовательных месяцев.</p><br>
<p style="text-indent:2em;">(3) В случае отзыва разрешения на деятельность по взвешиванию дорожных транспортных средств в целях выдачи международного сертификата взвешивания дорожных транспортных
			средств предприятие может запросить выдачу другого разрешения только по истечении шестимесячного периода, в течение которого оно не может запрашивать разрешение на
			другую станцию взвешивания.</p><br>
<p style="text-indent:2em;">(4) Агентство приостанавливает или отзывает разрешение на деятельность по взвешиванию дорожных транспортных средств в целях выдачи международного сертификата взвешивания
			дорожных транспортных средств в соответствии с положениями Закона об основных принципах регулирования предпринимательской деятельности.</p>
<p></p><h4>Глава 6.</h4><p></p> 

<p></p><h4>Деятельность по периодическому
техническому осмотру</h4><p></p>

<p style="font-weight:500;">Ст. 125. – (1) Периодический технический осмотр транспортных средств осуществляется на специализированных станциях технического осмотра, получивших разрешение Агентства в порядке, установленном настоящим кодексом.</p><br>
<p style="text-indent:2em;">(2) Станции, получившие разрешение на осуществление периодического технического осмотра, не вправе уступать разрешение на осуществление данной деятельности кому бы то ни было.</p><br>
<p style="text-indent:2em;">(3) В деятельности станций технического осмотра допускается использование только прошедших метрологическую поверку измерительных приборов, а также специального оборудования,
			предусмотренного техническими регламентами и методологией предоставления услуг по периодическому техническому осмотру транспортных средств.</p><br>
<p style="text-indent:2em;">(4) К деятельности по периодическому техническому осмотру допускается только персонал, прошедший подготовку и профессиональную аттестацию в центрах профессионального обучения, 
			повышения квалификации и профессиональной аттестации, авторизованных Агентством.</p>
<p style="text-indent:2em;"></p><h6>(5) Зарегистрированные в Республике Молдова автотранспортные средства подлежат периодическому техническому осмотру при соблюдении следующей периодичности:</h6><p></p>
<p style="text-indent:2em;">a) один раз в шесть месяцев – автотранспортные средства, используемые для автотранспортной перевозки пассажиров, а также автотранспортные средства, используемые для
			перевозки опасных грузов;</p><br>
<p>b) один раз в год – другие виды автотранспортных средств.</p><br>
<p style="text-indent:2em;">(6) Данные о транспортных средствах, представленных к периодическому техническому осмотру, подлежат ежедневному обобщению с их последующей передачей Агентству в
			электронной форме.</p><br>
<p style="text-indent:2em;">(7) Образец отчета о периодическом техническом осмотре и соответствующего талона, выдаваемого на прошедшие осмотр транспортные средства, устанавливаются в Положении
			о периодическом техническом осмотре дорожных транспортных средств.</p>
<p style="text-indent:2em;"></p><h6>Ст. 126. – (1) Разрешение на деятельность по периодическому техническому осмотру выдается предприятиям, отвечающим в совокупности следующим условиям:</h6><p></p>
<p>a) наличие хорошей репутации;</p><br>
<p>b) наличие материально-технической базы;</p><br>
<p>c) наличие профессиональной компетенции.</p>
<p style="text-indent:2em;"></p><h6>(2) Условие наличия хорошей репутации считается выполненным, если работающие на предприятии менеджеры и эксперты по техническому осмотру:</h6><p></p>
<p style="text-indent:2em;">a) не были осуждены за преступления, несовместимые с осуществляемой деятельностью, и не были лишены права занимать определенные должности окончательным судебным решением;</p><br>
<p style="text-indent:2em;">b) не подвергались наказанию за повторное совершение в течение календарного года правонарушений, касающихся трудовых прав, условий оплаты труда и трудоустройства,
			или правонарушений, посягающих на транспортный режим.</p><br>
<p style="text-indent:2em;">Условие наличия хорошей репутации считается невыполненным, по обстоятельствам, до истечения срока давности ответственности за правонарушения, до погашения судимости,
			до снятия судимости.</p>
<p style="text-indent:2em;"></p><h6>(3) Условие наличия материально-технической базы считается выполненным, если предприятие представит доказательства того, что располагает исключительно и постоянно,
			на праве собственности либо на основе договора имущественного найма соответствующими помещениями, в которых обеспечен свободный доступ транспортных средств к диагностическим
			стендам, а также обладает на праве собственности или на условиях финансового лизинга или имущественного найма следующим техническим оснащением и оборудованием:</h6><p></p>
<p style="text-indent:2em;">a) осмотровая канава соответствующей глубины с осветительным оборудованием (портативный фонарь с питанием на 12 или 24 В), оснащенная скользящими платформами для
			проверки люфта в соединениях, обеспечивающими линейные движения не менее чем в четырех направлениях;</p><br>
<p>b) устройство для отвода отработавших газов;</p><br>
<p>c) роликовый стенд для проверки тормозных систем;</p>
<p style="text-indent:2em;"></p><h6>d) четырехкомпонентный газоанализатор (для СО и λ) с точностью не менее:</h6><p></p>
<p>± 0,06% для СО;</p><br>
<p>± 0,5% для СО2;</p><br>
<p>± 0,1% для О2;</p><br>
<p>± 12 ppm для НС;</p><br>
<p style="text-indent:2em;">e) дымомер, предназначенный для измерения дымности отработавших газов дизельных двигателей, с точностью не менее ± 0,2 м-1;</p><br>
<p>f) прибор для проверки фар с нивелиром;</p><br>
<p>g) манометр для измерения давления в шинах с точностью до ± 0,25 атм.;</p><br>
<p>h) устройство для измерения глубины рисунка протектора шин с точностью ± 0,1 мм;</p><br>
<p style="text-indent:2em;">i) весы для измерения массы (отдельные или совмещенные со стендом для проверки тормозных систем) с точностью не менее ± 2% измеряемого значения;</p><br>
<p style="text-indent:2em;">j) прибор для проверки амортизаторов, снабженный генератором колебаний частоты 15 Гц, для линий периодического технического осмотра легковых автомобилей;</p><br>
<p>k) прибор для замера суммарного люфта рулевого управления с точностью до ± 0,5o;</p><br>
<p>l) прибор для проверки тормозной жидкости;</p><br>
<p style="text-indent:2em;">m) прибор для измерения уровня шума (за исключением станций, получивших разрешение на проведение периодического технического осмотра транспортных средств с общей
			допустимой массой до 3,5 тонны включительно);</p><br>
<p>n) стенд для контроля углов установки управляемых колес;</p><br>
<p style="text-indent:2em;">o) видеокамера, подключенная к локальной компьютерной сети, для фиксации информации о транспортных средствах, проходящих периодический технический осмотр;</p><br>
<p style="text-indent:2em;">p) компьютерное оборудование, подключенное к единой программе учета периодического технического осмотра, позволяющее осуществлять мониторинг технологического процесса
 и обмен данными с Агентством.</p><br>
<p style="text-indent:2em;">(4) Условие наличия профессиональной компетенции считается выполненным, если заявитель представит доказательства того, что у него в штатах на основе трудовых договоров
			работают в качестве менеджеров и экспертов лица, имеющие высшее или среднее специальное образование по специальности инженера или технолога автотранспорта и обладающие 
			сертификатом профессиональной компетенции, а эксперты, кроме того, водительским удостоверением соответственно категориям дорожных транспортных средств, для которых
			предусматривается деятельность по периодическому техническому осмотру.</p><br>
<p style="font-weight:500;">Ст. 127. – (1) Сертификаты профессиональной компетенции менеджера и эксперта по техническому осмотру выдаются Агентством после прохождения курсов подготовки и сдачи 
			оценочного теста с множественными вариантами ответов, в соответствии со статьей 43, в центрах профессионального обучения, повышения квалификации и профессиональной
			аттестации, авторизованных Агентством.</p><br>
<p style="text-indent:2em;">(2) Аттестация персонала, осуществляющего периодический технический осмотр, согласно положениям нормативных актов Европейской конференции министров транспорта (ЕКМТ),
			Европейского соглашения о международной дорожной перевозке опасных грузов (ДОПОГ) и Соглашения о международных перевозках скоропортящихся пищевых продуктов и о специальных
			транспортных средствах, предназначенных для этих перевозок (СПС), осуществляется при условии окончания дополнительного специального курса подготовки в центрах профессионального
			обучения, повышения квалификации и профессиональной аттестации, авторизованных Агентством, с последующей сдачей оценочного теста с множественными вариантами ответов оценочной 
			комиссии, в состав которой входит представитель Агентства.</p><br>
<p>(3) В случае несдачи оценочного теста соискатель имеет право на повторное тестирование в течение 14 дней.</p><br>
<p style="text-indent:2em;">(4) В случае несдачи повторного теста соискатель имеет право на новое тестирование лишь после прохождения нового курса профессиональной подготовки.</p><br>
<p style="text-indent:2em;">(5) Образец сертификата профессиональной компетенции менеджера и эксперта по техническому осмотру предусмотрен в Положении о периодическом техническом осмотре дорожных
			транспортных средств. Срок действия сертификата составляет три года.</p>
<p style="text-indent:2em;"></p><h6>Ст. 128. – (1) Лицо, запрашивающее выдачу либо обновление сертификата менеджера по периодическому техническому осмотру, должно соответствовать следующим требованиям:</h6><p></p>
<p style="text-indent:2em;">a) иметь диплом о высшем или среднем специальном образовании по специальности инженер или технолог автотранспорта;</p><br>
<p style="text-indent:2em;">b) иметь трудовой стаж не менее пяти лет в области ремонта, технического осмотра или технического обслуживания дорожных транспортных средств;</p><br>
<p style="text-indent:2em;">c) пройти курс начальной подготовки или, по обстоятельствам, периодической переподготовки менеджеров по периодическому техническому осмотру и сдать оценочный тест с
			множественными вариантами ответов.</p>
<p style="text-indent:2em;"></p><h6>(2) Лицо, запрашивающее выдачу либо обновление сертификата эксперта по техническому осмотру, должно соответствовать следующим требованиям:</h6><p></p>
<p style="text-indent:2em;">a) иметь диплом о высшем или среднем специальном образовании по специальности инженер или технолог автотранспорта;</p><br>
<p style="text-indent:2em;">b) иметь трудовой стаж не менее двух лет в области ремонта или технического обслуживания дорожных транспортных средств;</p><br>
<p style="text-indent:2em;">c) пройти курс начальной подготовки или, по обстоятельствам, периодической переподготовки экспертов по техническому осмотру и сдать оценочный тест с множественными
			вариантами ответов;</p><br>
<p style="text-indent:2em;">d) владеть действительным водительским удостоверением соответственно виду деятельности, осуществляемому станцией технического осмотра.</p><br>
<p style="text-indent:2em;">(3) Подготовка и профессиональная аттестация менеджеров и экспертов по периодическому техническому осмотру дорожных транспортных средств в центрах профессионального
			обучения, повышения квалификации и профессиональной аттестации, авторизованных Агентством, организуются на основе учебных программ и методологических норм, установленных
			центральным отраслевым органом.</p>
<p style="text-indent:2em;"></p><h6>Ст. 129. – (1) В зависимости от назначения и предельно допустимой общей массы транспортных средств, проходящих периодический технический осмотр, разрешение на
			деятельность по периодическому техническому осмотру включает следующие виды деятельности:</h6><p></p>
<p style="text-indent:2em;">a) проведение периодического технического осмотра транспортных средств с предельно допустимой общей массой до 3,5 тонны включительно;</p><br>
<p style="text-indent:2em;">b) проведение периодического технического осмотра дорожных транспортных средств, в том числе с предельно допустимой общей массой свыше 3,5 тонны;</p><br>
<p style="text-indent:2em;">c) проведение периодического технического осмотра дорожных транспортных средств согласно требованиям нормативных актов Европейской конференции министров 
			транспорта (ЕКМТ);</p><br>
<p style="text-indent:2em;">d) проведение периодического технического осмотра дорожных транспортных средств согласно условиям Европейского соглашения о международной дорожной перевозке 
			опасных грузов (ДОПОГ);</p><br>
<p style="text-indent:2em;">e) проведение периодического технического осмотра дорожных транспортных средств согласно условиям Соглашения о международных перевозках скоропортящихся пищевых 
			продуктов и о специальных транспортных средствах, предназначенных для этих перевозок (СПС);</p><br>
<p style="text-indent:2em;">f) проведение периодического технического осмотра дорожных транспортных средств в соответствии с требованиями Соглашения INTERBUS;</p><br>
<p>g) проведение классификации автобусов по категориям комфортности.</p><br>
<p style="text-indent:2em;">(2) Разрешение на деятельность по периодическому техническому осмотру содержит указание предусмотренных в части (1) вида/видов деятельности, на которые станция 
			технического осмотра получила разрешение.</p>
<p style="text-indent:2em;"></p><h6>Ст. 130. – В целях получения разрешения на деятельность по периодическому техническому осмотру предприятие, зарегистрированное в установленном порядке в Республике Молдова,
			представляет в Агентство на каждое из подразделений станции технического осмотра пакет документов, содержащий:</h6><p></p>
<p>a) заявление в установленной Агентством форме;</p><br>
<p>c) документы на право владения или пользования зданием;</p><br>
<p>d) декларацию под собственную ответственность о выполнении условия наличия хорошей репутации;</p><br>
<p style="text-indent:2em;">e) копии сертификатов профессиональной компетенции менеджера, ответственного за деятельность станции, и персонала, осуществляющего периодический технический осмотр;</p><br>
<p>f) перечень приборов и оборудования, которыми оснащена станция;</p><br>
<p style="text-indent:2em;">g) копии действительных свидетельств о метрологической поверке оборудования и средств измерения, а также копии свидетельств о метрологическом освидетельствовании 
			стендов и испытательного оборудования;</p><br>
<p style="text-indent:2em;">h) схему станции с указанием размеров и расположения приборов и оборудования, которыми оснащена станция, стоянки и прилегающих территорий.</p><br>
<p style="font-weight:500;">Ст. 131. – (1) Агентство рассматривает заявление на получение разрешения на деятельность по периодическому техническому осмотру в течение трех рабочих дней и в 
			зависимости от выполнения необходимых условий выдает разрешение или сообщает в письменном виде о мотивированном отказе.</p><br>
<p style="text-indent:2em;">(2) В случае выявления несоответствий в поданных документах Агентство уведомляет об этом заявителя в течение одного дня с указанием способа устранения несоответствий.</p><br>
<p style="text-indent:2em;">(3) За 30 дней до истечения срока действия разрешения на деятельность по периодическому техническому осмотру предприятие обращается в Агентство с заявлением о 
			продлении срока действия разрешения.</p><br>
<p style="text-indent:2em;">(4) После выдачи разрешения Агентство включает соответствующее предприятие в график плановых проверок на следующий квартал на предмет соответствия условиям 
			получения разрешения.</p><br>
<p style="font-weight:500;">Ст. 132. – (1) Разрешение на деятельность по периодическому техническому осмотру выдается сроком на пять лет.</p><br>
<p style="text-indent:2em;">(2) Образец разрешения на деятельность по периодическому техническому осмотру устанавливается в Положении о периодическом техническом осмотре дорожных 
			транспортных средств.</p><br>
<p style="text-indent:2em;">(3) В период действия разрешения его обладатель вправе испрашивать прекращение деятельности, ограничение либо расширение сферы действия разрешения.</p><br>
<p>(4) Разрешение с истекшим сроком действия является недействительным.</p>
<p style="text-indent:2em;"></p><h6>Ст. 133. – Станция технического осмотра, обладающая разрешением на деятельность по периодическому техническому осмотру, обязана:</h6><p></p>
<p style="text-indent:2em;">a) допускать проведение периодического технического осмотра в отношении только тех категорий транспортных средств и осуществление тех видов деятельности, 
			которые включены в разрешение на деятельность по периодическому техническому осмотру;</p><br>
<p style="text-indent:2em;">b) допускать к проведению периодического технического осмотра только экспертов, прошедших профессиональную аттестацию соответственно категории транспортного 
			средства, подвергаемого техническому осмотру, и запрашиваемому виду деятельности;</p><br>
<p style="text-indent:2em;">c) соблюдать технические регламенты и методологию предоставления услуг по периодическому техническому осмотру дорожных транспортных средств, предусмотренные в 
			Положении о периодическом техническом осмотре дорожных транспортных средств;</p><br>
<p style="text-indent:2em;">d) проводить оценку соответствия технического состояния дорожного транспортного средства только при представлении его на диагностическую линию и прохождении им 
			всех испытаний с выявлением показателей, соответствующих параметрам, установленным техническими регламентами и методологией предоставления услуг по периодическому
			техническому осмотру дорожных транспортных средств;</p><br>
<p style="text-indent:2em;">e) использовать для оценки соответствия технического состояния транспортного средства только приборы, прошедшие метрологическую поверку, а также специальное 
			оборудование, предусмотренное техническими регламентами и методологией предоставления услуг по периодическому техническому осмотру;</p><br>
<p style="text-indent:2em;">f) указывать в отчете о периодическом техническом осмотре все выявленные недостатки, по причине которых дорожное транспортное средство не прошло периодический
			технический осмотр, а также не допускать указания в этом отчете технических недостатков, которых дорожное транспортное средство не имеет;</p><br>
<p style="text-indent:2em;">g) своевременно передавать Агентству полные и достоверные данные о деятельности по периодическому техническому осмотру;</p><br>
<p style="text-indent:2em;">h) не допускать к составлению отчета о периодическом техническом осмотре иных лиц, кроме аттестованного эксперта по техническому осмотру, которому доверена 
			печать для заверения отчета;</p><br>
<p style="text-indent:2em;">i) собирать и хранить в архиве в течение трех лет дела на прошедшие периодический технический осмотр транспортные средства, содержащие минимальный перечень 
			необходимых документов, предусмотренный методологией предоставления услуг по периодическому техническому осмотру;</p><br>
<p style="text-indent:2em;">j) в срок не более 10 дней уведомлять Агентство об изменении данных, указанных в документах, приложенных к заявлению о выдаче разрешения, а также об изменении
			условий, послуживших основанием для его выдачи.</p><br>
<p style="font-weight:500;">Ст. 134. – (1) Агентство приостанавливает, ограничивает либо отзывает разрешение на деятельность по периодическому техническому осмотру и/или аннулирует сертификат
			профессиональной компетенции эксперта по техническому осмотру, по обстоятельствам.</p><br>
<p style="text-indent:2em;">(2) В случае выявления повторных в течение года нарушений норм проведения периодического технического осмотра действие разрешения приостанавливается на срок 30 дней.</p>
<p style="text-indent:2em;"></p><h6>При каждом дополнительном нарушении, выявленном в течение года после первого приостановления, действие разрешения приостанавливается на срок 60 дней. Нарушением 
			считается:</h6><p></p>
<p style="text-indent:2em;">a) несоблюдение методологии предоставления услуг по периодическому техническому осмотру дорожных транспортных средств;</p><br>
<p style="text-indent:2em;">b) передача Агентству неполных, недостоверных или ложных сведений о деятельности по периодическому техническому осмотру;</p><br>
<p style="text-indent:2em;">c) неуказание в отчете о периодическом техническом осмотре полного перечня выявленных недостатков, по причине которых дорожное транспортное средство не прошло 
			периодический технический осмотр;</p><br>
<p style="text-indent:2em;">d) использование измерительного прибора или оборудования с истекшим сроком действия свидетельства о метрологической поверке либо при отсутствии указанного 
			свидетельства;</p><br>
<p style="text-indent:2em;">e) составление отчета о периодическом техническом осмотре иным лицом, кроме аттестованного эксперта по техническому осмотру, которому доверена печать для 
			наложения на отчет;</p><br>
<p style="text-indent:2em;">f) указание в отчете о периодическом техническом осмотре технических недостатков, не обнаруженных на транспортном средстве;</p><br>
<p style="text-indent:2em;">g) отсутствие необходимого минимального перечня документов в деле прошедшего периодический технический осмотр транспортного средства;</p><br>
<p style="text-indent:2em;">h) совершение экспертом по техническому осмотру нарушения, приведшего к аннулированию Агентством его сертификата профессиональной компетенции.</p><br>
<p style="text-indent:2em;">(3) Действие разрешения на деятельность по периодическому техническому осмотру ограничивается в отношении некоторых категорий транспортных средств и/или 
			некоторых видов деятельности, указанных в разрешении, на период, в течение которого необходимые для проведения технического осмотра условия, послужившие 
			основанием для выдачи разрешения, не выполняются.</p>
<p style="text-indent:2em;"></p><h6>(4) Разрешение отзывается:</h6><p></p>
<p style="text-indent:2em;">1) на основании заявления предприятия о прекращении деятельности по периодическому техническому осмотру;</p><br>
<p style="text-indent:2em;">2) если предприятие представило на момент запрашивания разрешения подложные документы или документы, содержавшие ложные сведения;</p><br>
<p>3) при несоблюдении условий, послуживших основанием для выдачи разрешения;</p>
<p style="text-indent:2em;"></p><h6>4) в случае повторного совершения в течение года грубых нарушений норм проведения периодического технического осмотра, установленных изданными Агентством 
			предписаниями, заключающихся в:</h6><p></p>
<p style="text-indent:2em;">a) проведении оценки соответствия технического состояния дорожного транспортного средства в отсутствие такового;</p><br>
<p style="text-indent:2em;">b) признании надлежащим технического состояния дорожного транспортного средства, не соответствующего техническим требованиям;</p><br>
<p style="text-indent:2em;">c) проведении оценки соответствия технического состояния дорожного транспортного средства в период, когда действие разрешения на соответствующий вид 
			деятельности приостановлено или ограничено;</p><br>
<p style="text-indent:2em;">d) проведении оценки соответствия технического состояния дорожного транспортного средства в период, когда измерительные приборы или оборудование, необходимые 
			для проведения периодического технического осмотра, не удовлетворяют требованиям технических регламентов, либо в отсутствие приборов и оборудования, необходимых 
			для проведения периодического технического осмотра;</p><br>
<p style="text-indent:2em;">e) проведении периодического технического осмотра дорожных транспортных средств категорий, не предусмотренных разрешением на деятельность по периодическому 
			техническому осмотру, либо экспертом по техническому осмотру, который не аттестован соответственно запрашиваемому виду деятельности или у которого аннулирован 
			сертификат профессиональной компетенции.</p><br>
<p style="text-indent:2em;">(5) Агентство инициирует процедуру приостановления, ограничения или отзыва разрешения на деятельность по периодическому техническому осмотру в соответствии с 
			положениями Закона о регулировании предпринимательской деятельности путем разрешения № 160/2011.</p>
<p style="text-indent:2em;"></p><h6>Ст. 135. – (1) Сертификат профессиональной компетенции эксперта по техническому осмотру аннулируется Агентством в порядке, предусмотренном частью (1) статьи 134,
			в следующих случаях:</h6><p></p>
<p style="text-indent:2em;">a) повторное совершение в течение года одного из нарушений норм проведения периодического технического осмотра, предусмотренных в части (2) статьи 134;</p><br>
<p style="text-indent:2em;">b) совершение одного из грубых нарушений норм проведения периодического технического осмотра, предусмотренных пунктом 4) части (4) статьи 134.</p><br>
<p style="text-indent:2em;">(2) Решение об аннулировании сертификата профессиональной компетенции может быть обжаловано экспертом по техническому осмотру в Агентство, которое рассматривает
			вопрос в семидневный срок со дня подачи жалобы.</p><br>
<p style="text-indent:2em;">(3) В случае аннулирования сертификата профессиональной компетенции получение нового сертификата допускается только по истечении 60-дневного периода со дня 
			аннулирования первичного сертификата и осуществляется в порядке, предусмотренном статьей 127.</p><br>
<p style="text-indent:2em;">(4) Лицо, сертификат профессиональной компетенции которого трижды аннулировался, может быть допущено к деятельности эксперта по техническому осмотру на станциях
			технического осмотра лишь по истечении двухлетнего срока.</p><br>
<p style="font-weight:500;">Ст. 136. – (1) В случае отзыва разрешения на деятельность по периодическому техническому отчету предприятие может запросить выдачу другого разрешения только по 
			истечении шестимесячного периода, в течение которого оно не может запрашивать разрешение на другую станцию технического осмотра.</p><br>
<p style="text-indent:2em;">(2) Станция технического осмотра, разрешение на деятельность по периодическому техническому учету которой было отозвано трижды, вправе запросить выдачу нового 
			разрешения только по истечении двухлетнего срока.</p>

<p></p><h4>Раздел VI</h4><p></p>

<p></p><h4>ВРЕМЯ РАБОТЫ И ОТДЫХА ВОДИТЕЛЕЙ</h4><p></p>

<p style="font-weight:500;">Ст. 137. – (1) В целях предупреждения причин возникновения дорожно-транспортных происшествий предприятия/автотранспортные операторы, осуществляющие автотранспортные 
			перевозки, обязаны принимать необходимые меры по изучению, применению и соблюдению требований действующих регламентов в сфере дорожной безопасности и требований, 
			предъявляемых к дорожным транспортным средствам и лицам, ответственным за дорожную безопасность.</p><br>
<p style="text-indent:2em;">(2) В целях настоящего кодекса лицами, ответственными за дорожную безопасность, являются водители и менеджер по автотранспортным перевозкам.</p>
<p style="text-indent:2em;"></p><h6>Ст. 138. – (1) Положения настоящего раздела применяются к автотранспортным перевозкам:</h6><p></p>
<p style="text-indent:2em;">a) грузов, осуществляемым дорожными транспортными средствами, в том числе транспортными средствами с прицепом или полуприцепом, предельно допустимая общая масса 
			которых превышает 3,5 тонны;</p><br>
<p style="text-indent:2em;">b) пассажиров, осуществляемым дорожными транспортными средствами, которые сконструированы или оборудованы для перевозки более девяти человек, включая водителя, 
			и которые предназначены для этих целей, а также таксомоторными транспортными средствами.</p>
<p style="text-indent:2em;"></p><h6>(2) Положения Соглашения ЕСТР применяются вместо положений настоящего раздела к операциям по международным перевозкам, осуществляемым частично за пределы 
			Республики Молдова и/или Европейского Союза, для:</h6><p></p>
<p style="text-indent:2em;">a) транспортных средств, зарегистрированных в Европейском Союзе или в государствах, являющихся сторонами ЕСТР, на протяжении всего пути следования;</p><br>
<p style="text-indent:2em;">b) транспортных средств, зарегистрированных на территории государства, не являющегося стороной ЕСТР, только для той части пути следования, которая проходит по 
			территории Европейского Союза или государств, являющихся сторонами EСTР.</p>
<p style="text-indent:2em;"></p><h6>(3) Действие настоящего раздела не распространяется на автотранспортные перевозки, осуществляемые:</h6><p></p>
<p>a) транспортными средствами, указанными в пунктах a) и b) статьи 2 настоящего кодекса;</p><br>
<p style="text-indent:2em;">b) транспортными средствами, используемыми для перевозки пассажиров по регулярным маршрутам на расстояние не более 50 км;</p><br>
<p style="text-indent:2em;">с) транспортными средствами или составом транспортных средств с предельно допустимой общей массой, не превышающей 7,5 тонны, используемыми для автотранспортной 
			перевозки грузов в некоммерческих целях.</p><br>
<p style="font-weight:500;">Ст. 139. – (1) Ежедневное время вождения не должно превышать 9 часов. Ежедневное время вождения может быть продлено до 10 часов, но не более двух раз в течение недели.</p><br>
<p>(2) Еженедельное время вождения не должно превышать 56 часов.</p><br>
<p>(3) Общее суммарное время вождения не должно превышать 90 часов в течение двух последовательных недель.</p><br>
<p style="text-indent:2em;">(4) Ежедневное и еженедельное время вождения должно включать в себя все время вождения на территории Республики Молдова или другого государства.</p><br>
<p style="font-weight:500;">Ст. 140. – (1) После периода вождения в четыре с половиной часа водитель должен сделать перерыв непрерывной продолжительностью не менее 45 минут, за исключением 
			случая, когда начинается период отдыха.</p><br>
<p style="text-indent:2em;">(2) Этот перерыв может быть заменен перерывом продолжительностью не менее 15 минут с последующим перерывом не менее 30 минут, при этом соответствующие перерывы 
			должны включаться в период вождения таким образом, чтобы соблюдались положения части (1).</p><br>
<p style="font-weight:500;">Ст. 141. – (1) Водитель должен соблюдать периоды ежедневного и еженедельного отдыха.</p><br>
<p style="text-indent:2em;">(2) В течение каждого периода продолжительностью 24 часа после окончания предыдущего периода ежедневного или еженедельного отдыха водитель должен воспользоваться 
			новым периодом для ежедневного отдыха.</p><br>
<p style="text-indent:2em;">(3) Если период ежедневного отдыха, приходящийся на указанный 24-часовой период, составляет не менее 9 часов, но менее 11 часов, соответствующий период ежедневного
			отдыха считается сокращенным периодом ежедневного отдыха.</p><br>
<p style="text-indent:2em;">(4) Период ежедневного отдыха может быть продлен с тем, чтобы достичь нормального периода еженедельного отдыха или сокращенного периода еженедельного отдыха.</p><br>
<p style="text-indent:2em;">(5) Водитель не может иметь более трех сокращенных периодов ежедневного отдыха между двумя периодами еженедельного отдыха.</p><br>
<p style="text-indent:2em;">(6) В отступление от положений части (2) в случае, когда транспортное средство управляется экипажем, водитель должен воспользоваться новым периодом ежедневного отдыха
			продолжительностью не менее 9 часов в течение 30 часов после периода ежедневного или еженедельного отдыха.</p>
<p style="text-indent:2em;"></p><h6>(7) В течение двух последовательных недель водитель должен иметь не менее:</h6><p></p>
<p>a) двух периодов нормального еженедельного отдыха; или</p><br>
<p style="text-indent:2em;">b) одного периода нормального еженедельного отдыха и одного периода сокращенного еженедельного отдыха продолжительностью не менее 24 часов. Сокращение компенсируется
			эквивалентным периодом отдыха в целом до завершения третьей недели, следующей после указанных недель. Период еженедельного отдыха начинается до завершения шести 24-
			часовых периодов отдыха с момента предыдущего периода еженедельного отдыха.</p><br>
<p style="text-indent:2em;">(8) Любой период ежедневного отдыха, предоставленный в качестве компенсации за сокращенный еженедельный отдых, должен добавляться к другому периоду отдыха, 
			составляющему не менее 9 часов.</p><br>
<p style="text-indent:2em;">(9) По желанию водителя периоды ежедневного отдыха и периоды еженедельного сокращенного отдыха, при нахождении далеко от базы стоянки транспортного средства, могут
			использоваться для отдыха на борту дорожного транспортного средства, при условии, что оно оборудовано соответствующим спальным отсеком и транспортное средство находится
			на стоянке.</p><br>
<p style="text-indent:2em;">(10) Период еженедельного отдыха, начинающийся на одной неделе и продолжающийся на следующей, может быть отнесен к любой из этих недель, но только не к обеим сразу.</p><br>
<p style="font-weight:500;">Ст. 142. – Запрещается вознаграждение работающих по найму водителей, даже в форме бонусов или премий, в зависимости от пройденного расстояния и количества перевезенных
			грузов, за исключением случаев, когда подобное вознаграждение не ставит под угрозу дорожную безопасность.</p><br>
<p style="font-weight:500;">Ст. 143. – (1) Предприятия и автотранспортные операторы организовывают работу водителей таким образом, чтобы они соблюдали периоды вождения, перерыва и отдыха.</p><br>
<p style="text-indent:2em;">(2) Предприятия и автотранспортные операторы дают соответствующие указания водителям и проводят периодические проверки в целях обеспечения соблюдения периодов вождения, 
			перерыва и отдыха.</p><br>
<p style="text-indent:2em;">(3) Предприятие и автотранспортный оператор несут ответственность за нарушения, допущенные водителями предприятия/автотранспортного оператора, даже если эти нарушения 
			допущены на территории другого государства.</p><br>
<p style="font-weight:500;">Ст. 144. – (1) До вступления в силу положений, касающихся обязательного оснащения автотранспортных средств тахографами, учет времени работы и отдыха осуществляется каждым
			членом экипажа вручную на путевом листе с использованием соответствующих графических символов и указанием периодов вождения и отдыха.</p><br>
<p style="text-indent:2em;">(2) Члены экипажа должны иметь всегда при себе и представлять для проверки карточки водителя за текущую неделю и последний день предыдущей недели, на протяжении которой
			осуществлялось вождение.</p><br>
<p style="text-indent:2em;">(3) Автотранспортные операторы хранят карточки водителей, заполненные согласно части (1), за период не менее чем 12 последовательных месяцев после последней записи и 
			представляют их по требованию контролирующих субъектов.</p>

<p></p><h4>Раздел VII</h4><p></p>

<p></p><h4>ТАРИФЫ</h4><p></p>

<p style="font-weight:500;">Ст. 145. – (1) При осуществлении автотранспортных перевозок пассажиров по регулярным маршрутам, нерегулярных перевозок, перевозок за собственный счет и перевозок грузов,
			а также сопутствующей автотранспортным перевозкам деятельности, за исключением автовокзальной деятельности и деятельности по периодическому техническому отчету, тарифы
			устанавливаются произвольно, на основании спроса и предложения.</p><br>
<p style="text-indent:2em;">(2) Тарифы на автотранспортные перевозки пассажиров по регулярным маршрутам в районном и межрайонном сообщении, на автовокзальные услуги, на услуги станций технического 
			осмотра устанавливаются центральным отраслевым органом, а тарифы на автотранспортные перевозки пассажиров по регулярным маршрутам в местном или муниципальном сообщении
			устанавливаются местными или муниципальными советами, по обстоятельствам, согласно утвержденной Правительством методологии.</p><br>
<p style="font-weight:500;">Ст. 146. – Выдача разрешений осуществляется на основании тарифов, установленных в Перечне разрешительных документов, выдаваемых органами–эмитентами физическим и 
			юридическим лицам для осуществления предпринимательской деятельности.</p>

<p></p><h4>Раздел VIII</h4><p></p>

<p></p><h4>КОНТРОЛЬ</h4><p></p>

<p style="font-weight:500;">Ст. 147. – Агентство является органом, обеспечивающим мониторинг и контроль выполнения и соблюдения национальных и международных правил автотранспортных перевозок.</p><br>
<p style="font-weight:500;">Ст. 148. – (1) В целях настоящего кодекса мониторинг и контроль автотранспортных перевозок и сопутствующей автотранспортным перевозкам деятельности осуществляется 
			в условиях дорожного движения при осуществлении перевозок и/или в офисах предприятий/автотранспортных операторов в соответствии с Законом о государственном контроле
			предпринимательской деятельности уполномоченными на то инспекторами на основе решений руководства Агентства о контроле. Акты или другие записи о документировании
			контроля не заменяют протокола контроля, предусмотренного Законом о государственном контроле предпринимательской деятельности № 131/2012, и прилагаются к нему.</p><br>
<p style="text-indent:2em;">(1.1) Участие представителей Агентства в экзаменах по профессиональной аттестации, проводимых в соответствии со статьей 43, не считается государственным контролем.</p><br>
<p style="text-indent:2em;">(2) Инспекторы Агентства уполномочены осуществлять контроль за соблюдением правил в области автотранспортных перевозок и налагать санкции за правонарушения в данной
			области, предусмотренные Кодексом о правонарушениях.</p><br>
<p style="text-indent:2em;">(3) При исполнении своих полномочий по мониторингу и контролю инспекторы Агентства обязаны носить служебную форму со специальными знаками отличия, а в случае контроля
			в условиях дорожного движения – также и защитное обмундирование.</p>
<p style="text-indent:2em;"></p><h6>(4) Осуществляемый Агентством контроль касается:</h6><p></p>
<p style="text-indent:2em;">а) предпринимательской деятельности по автотранспортным перевозкам и сопутствующей автотранспортным перевозкам деятельности;</p><br>
<p style="text-indent:2em;">b) пресечения в сотрудничестве с Национальной патрульной инспекцией незаконных автотранспортных перевозок.</p><br>
<p style="text-indent:2em;">(5) Решение Агентства о контроле, касающемся предпринимательской деятельности по автотранспортным перевозкам и сопутствующей автотранспортным перевозкам деятельности,
			должно прямо содержать основание, сроки, предмет контроля и не распространяется, под санкцией недействительности, на другие обстоятельства и субъекты, не являющиеся 
			предметом соответствующего решения о контроле.</p><br>
<p style="text-indent:2em;">(6) Решение Агентства о контроле, касающемся пресечения незаконных автотранспортных перевозок, должно прямо содержать предмет контроля. В ходе контроля инспекторы 
			Агентства не вправе предпринимать действия, превышающие предмет решения о контроле, а также устанавливать другие правонарушения.</p><br>
<p style="text-indent:2em;">(7) Участие представителей Агентства в проведении тестов согласно части (6) статьи 43 не считается государственным контролем.</p><br>
<p style="font-weight:500;">Ст. 149. – (1) При исполнении полномочий по инспектированию и контролю, которыми они наделены, инспекторы Агентства имеют право проводить проверки в условиях дорожного
			движения в местах, где согласно положениям действующего законодательства разрешена стоянка или парковка, в местах посадки/высадки пассажиров в отношении всех дорожных
			транспортных средств, осуществляющих автотранспортные перевозки пассажиров или грузов, в том числе зарегистрированных в других государствах, в местах, в которых 
			осуществляется сопутствующая автотранспортным перевозкам деятельность.</p><br>
<p style="font-weight:500;">Ст. 150. – (1) Инспектор Агентства имеет право останавливать передвигающиеся по автомобильным дорогам общего пользования и выполняющие автотранспортные перевозки транспортные
			средства, на которые распространяются его контрольные полномочия.</p><br>
<p style="text-indent:2em;">(2) Инспекторы Агентства должны оснащаться соответствующим образом и размещаться таким образом, чтобы участники дорожного движения легко их замечали и узнавали при подаче
			ими сигналов остановки.</p><br>
<p style="text-indent:2em;">(3) Сигналы подаются в дневное время рукой или светоотражающим жезлом, а в ночное время – только светящимися или светоотражающими жезлами, сходными с теми, что имеются на
			оснащении дорожной полиции.</p><br>
<p style="text-indent:2em;">(4) Отказ остановиться на сигнал инспектора Агентства и уклонение от проведения контроля влекут наложение наказания в соответствии с положениями Кодекса о правонарушениях.</p><br>
<p style="font-weight:500;">Ст.151. – (1) Проверка соблюдения на автомобильных дорогах общего пользования предельно допустимых общей массы, нагрузки на ось и/или габаритов дорожных транспортных 
			средств осуществляется инспекторами Агентства.</p><br>
<p style="text-indent:2em;">(2) Проверка соблюдения на автомобильных дорогах общего пользования предельно допустимых общей массы, нагрузки на ось и/или габаритов дорожных транспортных средств может
			осуществляться в том числе через электронную систему взвешивания автотранспортных средств в движении.</p><br>
<p style="text-indent:2em;">(3) Если в результате взвешивания не установлено превышение предельно допустимых общей массы дорожного транспортного средства и нагрузки на ось, инспектор Агентства 
			вручает водителю взвешенного транспортного средства весовой талон с подписью и печатью инспектора.</p><br>
<p style="text-indent:2em;">(4) В случае установления факта превышения предельно допустимых общей массы, нагрузки на ось и/или габаритов инспектор Агентства составляет протокол о констатации 
			правонарушения, к которому прилагает подписанный им и заверенный печатью весовой талон.</p><br>
<p style="text-indent:2em;">(5) За превышение предельно допустимых общей массы, нагрузки на ось и/или габаритов правонарушитель уплачивает помимо штрафа за правонарушение эквивалент суммы сборов,
			предусмотренных Налоговым кодексом. Инспектор Агентства отмечает в протоколе о констатации правонарушения обязанность уплаты этих сборов, исчисленных в соответствии с
			законодательством.</p><br>
<p style="text-indent:2em;">(6) При задержании дорожного транспортного средства с соблюдением условий безопасности одновременно с наложением штрафа за превышение предельно допустимых общей массы,
			нагрузки на ось и/или габаритов дорожного транспортного средства инспекторы Агентства применяют меру приостановления, предусмотренную статьей 149, вплоть до снижения 
			правонарушителем общей массы дорожного транспортного средства до допустимых пределов и уплаты указанных в протоколе штрафа/сборов.</p><br>
<p style="text-indent:2em;">(7) Если в месте установления правонарушения нет соответствующих условий для задержания транспортного средства с соблюдением условий дорожной безопасности, перемещение 
			такового к ближайшему месту стоянки и снижение общей массы дорожного транспортного средства до допустимых пределов осуществляются за счет правонарушителя.</p><br>
<p style="text-indent:2em;">(8) На период задержания как транспортное средство, так и груз находятся под юридической ответственностью правонарушителя.</p><br>
<p style="text-indent:2em;">(9) В порядке исключения задержанию не подлежат дорожные транспортные средства, перевозящие живых животных и скоропортящиеся или требующие соблюдения температурного 
			режима товары, а также транспортные средства, зарегистрированные в других государствах.</p><br>
<p style="font-weight:500;">Ст. 152. – (1) Контроль соблюдения положений об ограничении скорости, заданной ограничителем скорости, о периодах вождения, перерыва и отдыха водителей, осуществляющих
			автотранспортные перевозки, производится инспекторами Агентства как в условиях дорожного движения, так и в офисах автотранспортных операторов.</p>
<p style="text-indent:2em;"></p><h6>(2) В рамках деятельности по контролю соблюдения положений о периодах вождения, перерыва и отдыха водителей должны соблюдаться следующие минимальные контрольные требования:</h6><p></p>
<p style="text-indent:2em;">a) ежегодно проверяется не менее 1 процента общего годового количества дней, отработанных отечественными водителями, на которых распространяются положения 
			настоящего кодекса;</p><br>
<p style="text-indent:2em;">b) не менее 15 процентов общего количества ежегодно проверяемых дней, отработанных отечественными водителями, проверяется в условиях дорожного движения и не
			менее 25 процентов – в офисах автотранспортных предприятий.</p><br>
<p style="font-weight:500;">Статья 1521. Обжалование процедуры или результатов контроля
            Обжалование действий, бездействия и изданных в рамках процедуры контроля административных актов, осуществляется в соответствии с положениями Закона о 
			государственном контроле предпринимательской деятельности № 131/2012.</p><br>
<p style="font-weight:500;">Ст.1522. – (1) Агентство осуществляет контроль соблюдения автотранспортными операторами на территории Республики Молдова национальных и международных положений
			в области автомобильного транспорта.</p>
<p style="text-indent:2em;"></p><h6>(2) Таможенная служба осуществляет в пунктах пропуска через государственную границу на основе анализа риска контроль в отношении:</h6><p></p>
<p style="text-indent:2em;">a) наличия на борту транспортных средств разрешений на автотранспортные перевозки грузов, если двусторонние и многосторонние соглашения предусматривают их 
			обязательность в случае иностранных транспортных операторов. Сотрудник таможенных органов регистрирует номер и серию разрешения в информационной системе 
Таможенной службы и проставляет на разрешении штамп;</p><br>
<p style="text-indent:2em;">b) уплаты сбора за пользование автомобильными дорогами (виньетки) грузовыми транспортными средствами при выезде из Республики Молдова в случае иностранных
			транспортных операторов;</p><br>
<p style="text-indent:2em;">c) соблюдения транспортными средствами допустимых значений общей массы, допустимой весовой нагрузки на ось и/или допустимых для транспортных средств
			габаритных размеров.</p>
<p style="text-indent:2em;"></p><h6>(3) Пограничная полиция осуществляет в пунктах пропуска через государственную границу, в случае иностранных транспортных операторов, контроль в 
			отношении:</h6><p></p>
<p style="text-indent:2em;">a) наличия на борту транспортных средств разрешений на автотранспортные перевозки пассажиров по регулярным маршрутам или разрешений на нерегулярные 
			автотранспортные перевозки пассажиров, если двусторонние и многосторонние соглашения предусматривают их обязательность;</p><br>
<p style="text-indent:2em;">b) уплаты сбора за пользование автомобильными дорогами (виньетки) транспортными средствами, за исключением указанных в части (2), при выезде из Республики Молдова.</p>

<p></p><h4>Раздел IX</h4><p></p>

<p></p><h4>ЗАКЛЮЧИТЕЛЬНЫЕ И ПЕРЕХОДНЫЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="text-indent:2em;"></p><h6>Ст. 153. – (1) Настоящий кодекс вступает в силу по истечении одного месяца со дня опубликования, за следующими исключениями:</h6><p></p>
<p style="text-indent:2em;"></p><h6>a) положения, касающиеся обязательного оснащения ограничителями скорости дорожных транспортных средств, зарегистрированных впервые в Республике Молдова до
			вступления в силу настоящего кодекса, вводятся в действие следующим образом:</h6><p></p>
<p style="text-indent:2em;"> для дорожных транспортных средств, осуществляющих автотранспортные перевозки в национальном сообщении, – по истечении четырех лет со дня вступления в
			силу настоящего кодекса;</p><br>
<p style="text-indent:2em;"> для дорожных транспортных средств, осуществляющих автотранспортные перевозки в международном сообщении, – по истечении трех лет со дня вступления в
			силу настоящего кодекса;</p><br>
<p style="text-indent:2em;"> для дорожных транспортных средств, осуществляющих автотранспортные перевозки в национальном сообщении, – с 1 января 2022 года;
            утратил силу/дополнен согласно ЗП272 от 29.11.18 МО 462-466 от 12.12.18 ст. 776</p><br>
<p style="text-indent:2em;">b) положения, касающиеся обязательного оснащения тахографами дорожных транспортных средств, зарегистрированных впервые в Республике Молдова до вступления
			в силу настоящего кодекса, вводятся в действие по истечении пяти лет со дня вступления в силу настоящего кодекса;</p>
<p style="text-indent:2em;"></p><h6>с) положения, касающиеся обязательного обладания автотранспортными операторами не менее 1/3 дорожных транспортных средств, которыми располагает предприятие
			на правах собственности или на условиях финансового лизинга, вводятся в действие в следующем порядке:</h6><p></p>
<p style="text-indent:2em;"> с 1 января 2017 года не менее 20 процентов дорожных транспортных средств, которыми располагает предприятие, должны быть их собственностью или предприятие
			должно обладать ими на условиях финансового лизинга;</p><br>
<p style="text-indent:2em;"> с 1 января 2018 года не менее 1/3 дорожных транспортных средств, которыми располагает предприятие, должны быть их собственностью или предприятие должно
			обладать ими на условиях финансового лизинга;</p><br>
<p>d) положения пункта с) статьи 16 и положения статьи 19 вводятся в действие с 1 января 2018 года;</p><br>
<p style="text-indent:2em;">e) лицензирование автотранспортных операторов, осуществляющих грузовые автотранспортные перевозки в международном сообщении, является обязательным с 1
			января 2015 года, а автотранспортных операторов, осуществляющих деятельность в национальном сообщении, – с 1 января 2016 года;</p><br>
<p>f) лицензирование автовокзальной деятельности является обязательным с 1 января 2018 года;</p><br>
<p style="text-indent:2em;">g) выдача разрешений на автосервисную деятельность, деятельность по установке, ремонту и проверке тахографов и ограничителей скорости и деятельность по
			взвешиванию дорожных транспортных средств в целях выдачи международного сертификата взвешивания дорожного транспортного средства является обязательной с
			1 января 2015 года;</p><br>
<p style="text-indent:2em;">i) положения, касающиеся обязательной классификации автобусов по категориям комфортности, вводятся в действие с 1 июня 2018 года;</p><br>
<p style="text-indent:2em;">j) положения, касающиеся обязательного оснащения глобальными системами позиционирования (GPS) транспортных средств, используемых для автотранспортных
			перевозок пассажиров по регулярным маршрутам, вводятся в действие с 1 января 2022 года;
            заменен согласно ЗП272 от 29.11.18 МО 462-466 от 12.12.18 ст. 776</p><br>
<p>l) положения части (6) статьи 151 вводятся в действие с 1 июля 2016 года;</p><br>
<p style="text-indent:2em;">m) положения, касающиеся обязательного использования исключительно информационной системы «e-Autorizaţie transport» в процессе распределения разрешений
			на автотранспортные перевозки и отчетности по ним, вступают в силу с 1 января 2018 года, за исключением положений, касающихся распределения разрешений
			ЕКМТ, которые вступают в силу с 1 сентября 2018 года;</p><br>
<p style="text-indent:2em;">n) в месячный срок после признания утратившими силу законодательных положений, регулирующих выдачу лицензии на автотранспортные перевозки на платной основе,
			Агентство государственных услуг должно направить Национальному агентству автомобильного транспорта информацию о лицензированных автотранспортных операторах
			и дорожных транспортных средствах, включенных в лицензию на автотранспортные перевозки на платной основе;</p><br>
<p style="text-indent:2em;">o) в трехмесячный срок после признания утратившими силу законодательных положений, регулирующих выдачу лицензии на автотранспортные перевозки на платной основе,
			Национальное агентство автомобильного транспорта на основании информации, представленной Агентством государственных услуг, должна включить автотранспортных операторов,
			обладающих действительной лицензией и заверенными копиями лицензии на автотранспортные перевозки, в Реестр автотранспортных операторов;</p><br>
<p style="text-indent:2em;">p) в течение одного года с момента признания утратившими силу законодательных положений, служащих основанием для выдачи лицензии на автотранспортные перевозки
			 на платной основе, Национальное агентство автомобильного транспорта должно произвести бесплатную замену заверенных копий лицензии на автотранспортные перевозки
			 на платной основе выписками из Реестра автотранспортных операторов.</p><br>
<p style="text-indent:2em;">(2) Разрешения на деятельность по маршруту, выданные в соответствии с Постановлением Правительства о Правилах перевозки пассажиров и багажа автомобильным 
			транспортом № 854 от 28 июля 2006 года, действительны до утверждения программ автотранспортных перевозок.</p><br>
<p style="text-indent:2em;">(3) Лицензии на виды деятельности, предусмотренные в Законе о регулировании предпринимательской деятельности путем лицензирования № 451-XV от 30 июля 2001 года,
			действительны до истечения срока, на который они были выданы, а их обладатели обязаны соблюдать положения настоящего кодекса.</p><br>
<p style="text-indent:2em;">(4) С момента опубликования настоящего кодекса и до утверждения новых программ автотранспортных перевозок, разработанных и утвержденных в соответствии с настоящим 
			кодексом, запрещается производство любых изменений сети регулярных маршрутов в национальном сообщении, существующей на момент опубликования.</p><br>
<p style="text-indent:2em;">(5) Программы автотранспортных перевозок пассажиров в местном, муниципальном и районном сообщении, а также в межрайонном и международном сообщении утверждаются в
			соответствии с положениями настоящего кодекса до 31 декабря 2014 года.</p><br>
<p style="text-indent:2em;">(6) Программы автотранспортных перевозок пассажиров в районном сообщении, а также программы автотранспортных перевозок пассажиров в межрайонном и международном
			сообщении, утвержденные в соответствии с частью (5), должны включать как сеть регулярных маршрутов, существующую на момент вступления в силу настоящего кодекса, 
			так и новые маршруты, введенные центральным отраслевым органом в соответствии со статьей 36 настоящего кодекса.</p><br>
<p style="text-indent:2em;">(7) Положения частей (4), (5) и (6) статьи 37 и статьи 38 настоящего кодекса не применяются к маршрутам, введенным в соответствии с частью (6).</p><br>
<p style="text-indent:2em;">(8) Разрешения на регулярные автотранспортные перевозки пассажиров и разрешения на международные автотранспортные перевозки пассажиров согласно программам автотранспортных
			перевозок, утвержденным в соответствии с положениями частей (5), (6) и (7), выдаются Агентством автотранспортным операторам, которым было предоставлено право обслуживания
			маршрутов/рейсов, в пределах заверенных копий лицензий на автотранспортные перевозки, которыми они обладают, и подтверждения обладания дорожными транспортными средствами
			необходимой вместимости и категории комфортности вне конкурса независимо от маршрута движения.</p><br>
<p style="text-indent:2em;">(9) Начиная с 1 января 2020 года к осуществлению автотранспортных перевозок будут допущены только автобусы, соответствующие по меньшей мере нормам Евро 1.</p><br>
<p style="text-indent:2em;">(10) Выданные ранее другими учреждениями разрешения действительны до истечения срока их действия, а их обладатели обязаны соблюдать положения настоящего кодекса.</p>
<p style="text-indent:2em;"></p><h6>Ст. 154. (1) Правительству до 1 июня 2017 года:</h6><p></p>
<p style="text-indent:2em;">a) представить Парламенту предложения по приведению действующего законодательства в соответствие с настоящим кодексом;</p><br>
<p>b) привести свои нормативные акты в соответствие с настоящим кодексом;</p><br>
<p>c) обеспечить разработку нормативных актов, регулирующих применение настоящего кодекса.</p><br>
<p style="text-indent:2em;">(2) Со дня вступления в силу настоящего кодекса признать утратившим силу Закон об утверждении Кодекса автомобильного транспорта № 116-XIV от 29 июля 1998 года.</p><br>

<p><a href="https://www.legis.md/cautare/getResults?doc_id=122882&amp;lang=ru">Источник</a></p>


</div>
`