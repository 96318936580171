import _ from 'lodash'
import {
  UI_ACTIVE_POPUP_GUID,
  UI_CHANGE_LANGUAGE_START,
  UI_CHANGE_LANGUAGE_END,
  UI_TRANSLATION_LOADING,
  UI_TRANSLATION_LOADED,
  UI_SET_AVAILABLE_LANGUAGES,
  UI_CHANGE_LANGUAGE_FETCHING
} from '../actions/action-types'
import { DEFAULT_LANG } from '../constants'

const initialState = {
  activePopupGuid: undefined,
  currentLanguage: DEFAULT_LANG,
  langId: 1,
  loadingTranslation: false,
  translation: {},
  baseMessages: {},
  languages: [],
  languagesIsFetching: false,
}

function setActivePopup(state, { guid }) {
  return { ...state, activePopupGuid: guid }
}

function changeLanguageStart(state, { payload }) {
  const { lang, langId } = payload
  return {
    ...state,
    currentLanguage: lang.toLowerCase(),
    langId,
  }
}
function changeLanguageEnd(state, { payload }) {
  const { lang, langId } = payload
  return {
    ...state,
    currentLanguage: lang.toLowerCase(),
    langId,
  }
}

function translationLoaded(state, action) {
  const { payload } = action
  const lang = payload.lang.toLowerCase()
  const translation = { ...state.translation, [lang]: action.translation }
  let { baseMessages } = state
  if (_.isEmpty(baseMessages)) {
    baseMessages = _.mapValues(action.translation, (value, key) => ({
      id: key,
      defaultMessage: value,
    }))
  }

  return {
    ...state,
    loadingTranslation: false,
    translation,
    baseMessages,
  }
}

export default (state = initialState, action: any = {}) => {
  const { type } = action
  switch (type) {
    case UI_ACTIVE_POPUP_GUID:
      return setActivePopup(state, action)

    case UI_CHANGE_LANGUAGE_START:
      return changeLanguageStart(state, action)
    case UI_CHANGE_LANGUAGE_END:
      return changeLanguageEnd(state, action)

    case UI_TRANSLATION_LOADING: {
      return { ...state, loadingTranslation: true }
    }
    case UI_CHANGE_LANGUAGE_FETCHING: {
      return {
        ...state,
        languagesIsFetching: !state.languagesIsFetching,
      }
    }
    
    case UI_TRANSLATION_LOADED:
      return translationLoaded(state, action)

    case UI_SET_AVAILABLE_LANGUAGES: {
      const { payload } = action
      return { ...state, languages: payload || [] }
    }
    
    default:
      return state
  }
}
