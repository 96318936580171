import { connect } from 'react-redux'
import { compose } from 'recompose'
import withTranslation from '../../../globals/components/withTranslation'
import withPermission from '../../../globals/components/withPermissions'
import { IReduxStorage } from '../../../store/reducers/types'
import { companyChangeDateRegistration, showUserCompanyInProcessContainer } from '../../../store/actions/company'

import Component from './component'

export default compose(
  withTranslation,
  withPermission,
  connect((state: IReduxStorage) => {
    const {
      auth: { profile },
      ui: { langId },
    } = state

    return {
      profile: profile.get('data').toJS(),
      langId,
      isAuth: !!state.auth.accessToken,
      showRequestInCompanyInProcess: state.company.showRequestInCompanyInProcess,
    }
  }, {
    companyChangeDateRegistration,
    showUserCompanyInProcessContainer
  })
)(Component)
