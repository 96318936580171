export const AUTH_MODAL_OPEN = 'AUTH_MODAL_OPEN'
export const AUTH_MODAL_CLOSE = 'AUTH_MODAL_CLOSE'
export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_LOGOUT_ONLY = 'AUTH_LOGOUT_ONLY'
export const AUTH_REFRESH_SUCCESS = 'AUTH_REFRESH_SUCCESS'
export const AUTH_REFRESH_FAILURE = 'AUTH_REFRESH_FAILURE'
export const AUTH_HYDRATE_FROM_LOCAL_STORAGE = 'AUTH_HYDRATE_FROM_LOCAL_STORAGE'
export const AUTH_OWNER_PERMISSION_AWAITING = 'AUTH_OWNER_PERMISSION_AWAITING'
export const AUTH_OWNER_PERMISSION_ACCEPTED = 'AUTH_OWNER_PERMISSION_ACCEPTED'
export const AUTH_OWNER_PERMISSION_REJECTED = 'AUTH_OWNER_PERMISSION_REJECTED'
// registration
export const AUTH_REGISTRATION_START = 'AUTH_REGISTRATION_START'
export const AUTH_REGISTRATION_SUCCESS = 'AUTH_REGISTRATION_SUCCESS'
export const AUTH_REGISTRATION_FAILURE = 'AUTH_REGISTRATION_FAILURE'
export const AUTH_REGISTER_PROFILE_START = 'AUTH_REGISTER_PROFILE_START'
export const AUTH_REGISTER_PROFILE_SUCCESS = 'AUTH_REGISTER_PROFILE_SUCCESS'
export const AUTH_REGISTER_PROFILE_FAILURE = 'AUTH_REGISTER_PROFILE_FAILURE'
// recover password
export const AUTH_RECOVER_PASSWORD_SEND_EMAIL_START = 'AUTH_RECOVER_PASSWORD_SEND_EMAIL_START'
export const AUTH_RECOVER_PASSWORD_SEND_EMAIL_SUCCESS = 'AUTH_RECOVER_PASSWORD_SEND_EMAIL_SUCCESS'
export const AUTH_RECOVER_PASSWORD_SEND_EMAIL_FAILURE = 'AUTH_RECOVER_PASSWORD_SEND_EMAIL_FAILURE'
export const SET_GLOBAL_ACTIVE_TAB = "SET_GLOBAL_ACTIVE_TAB"

export const AUTH_RECOVER_PASSWORD_SET_NEW_VALUE_START = 'AUTH_RECOVER_PASSWORD_SET_NEW_VALUE_START'
export const AUTH_RECOVER_PASSWORD_SET_NEW_VALUE_SUCCESS =
  'AUTH_RECOVER_PASSWORD_SET_NEW_VALUE_SUCCESS'
export const AUTH_RECOVER_PASSWORD_SET_NEW_VALUE_FAILURE =
  'AUTH_RECOVER_PASSWORD_SET_NEW_VALUE_FAILURE'

  export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB' 
export const CAN_ADD = "CAN_ADD"
// remind password
export const AUTH_REMIND_PASSWORD_START = 'AUTH_REMIND_PASSWORD_START'
export const AUTH_REMIND_PASSWORD_SUCCESS = 'AUTH_REMIND_PASSWORD_SUCCESS'
export const AUTH_REMIND_PASSWORD_FAILURE = 'AUTH_REMIND_PASSWORD_FAILURE'
// change password
export const AUTH_CHANGE_PASSWORD_START = 'AUTH_CHANGE_PASSWORD_START'
export const AUTH_CHANGE_PASSWORD_SUCCESS = 'AUTH_CHANGE_PASSWORD_SUCCESS'
export const AUTH_CHANGE_PASSWORD_FAILURE = 'AUTH_CHANGE_PASSWORD_FAILURE'

//action to scroll
export const PROFILE_SCROLL_TO_ONCOMMING_ITEMS = "PROFILE_SCROLL_TO_ONCOMMING_ITEMS"

export const AUTH_CHANGE_EMAIL_SUCCESS = 'AUTH_CHANGE_EMAIL_SUCCESS'

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH'

export const MODAL_SHOW_REQUEST = 'MODAL_SHOW_REQUEST'
export const MODAL_CLOSE_REQUEST = 'MODAL_CLOSE_REQUEST'
export const MODAL_REFRESH_LAYOUT = 'MODAL_REFRESH_LAYOUT'

export const MODALFORM_SHOW_REQUEST = 'MODALFORM_SHOW_REQUEST'
export const MODALFORM_CLOSE_REQUEST = 'MODALFORM_CLOSE_REQUEST'
export const MODALFORM_REFRESH_LAYOUT = 'MODALFORM_REFRESH_LAYOUT'

export const HEADERTOP_REFRESH = 'HEADERTOP_REFRESH'
export const HEADERTOP_DDL_OPEN = 'HEADERTOP_DDL_OPEN'
export const HEADERTOP_DDL_CLOSE = 'HEADERTOP_DDL_CLOSE'

export const CARGO_SEARCH_START = 'CARGO_SEARCH_START'
export const CARGO_SEARCH_SUCCESS = 'CARGO_SEARCH_SUCCESS'
export const CARGO_SEARCH_FAILURE = 'CARGO_SEARCH_FAILURE'

export const CARGO_GET_UNBLOCKED_START = 'CARGO_GET_UNBLOCKED_START'
export const CARGO_GET_UNBLOCKED_SUCCESS = 'CARGO_GET_UNBLOCKED_SUCCESS'
export const CARGO_GET_UNBLOCKED_FAILURE = 'CARGO_GET_UNBLOCKED_FAILURE'

export const CARGO_LAST_SEARCH_START = 'CARGO_LAST_SEARCH_START'
export const CARGO_LAST_SEARCH_SUCCESS = 'CARGO_LAST_SEARCH_SUCCESS'
export const CARGO_LAST_SEARCH_FAILURE = 'CARGO_LAST_SEARCH_FAILURE'

export const CARGO_DELETE_LAST_SEARCH_START = 'CARGO_LAST_SEARCH_START'
export const CARGO_DELETE_LAST_SEARCH_SUCCESS = 'CARGO_LAST_SEARCH_SUCCESS'
export const CARGO_DELETE_LAST_SEARCH_FAILURE = 'CARGO_LAST_SEARCH_FAILURE'

export const CARGO_ADD_FAVOURITE_START = 'CARGO_ADD_FAVOURITE_START'
export const CARGO_ADD_FAVOURITE_SUCCESS = 'CARGO_ADD_FAVOURITE_SUCCESS'
export const CARGO_ADD_FAVOURITE_FAILURE = 'CARGO_ADD_FAVOURITE_FAILURE'

export const CARGO_DELETE_FAVOURITE_START = 'CARGO_DELETE_FAVOURITE_START'
export const CARGO_DELETE_FAVOURITE_SUCCESS = 'CARGO_DELETE_FAVOURITE_SUCCESS'
export const CARGO_DELETE_FAVOURITE_FAILURE = 'CARGO_DELETE_FAVOURITE_START'

export const CARGO_ADD = 'CARGO_ADD'
export const CARGO_ADDED = 'CARGO_ADDED'
export const CARGO_ADD_ERROR = 'CARGO_ADD_ERROR'

export const CARGO_CHANGE_ACTIVE = 'CARGO_CHANGE_ACTIVE'

export const CARGO_ITEM_LOADING = 'CARGO_ITEM_LOADING'
export const CARGO_ITEM_LOADED = 'CARGO_ITEM_LOADED'
export const CARGO_ITEM_LOAD_ERROR = 'CARGO_ITEM_LOAD_ERROR'

export const CARGO_TEMPLATE_SAVING = 'CARGO_TEMPLATE_SAVING'
export const CARGO_TEMPLATE_SAVED = 'CARGO_TEMPLATE_SAVED'
export const CARGO_TEMPLATE_SAVE_ERROR = 'CARGO_TEMPLATE_SAVE_ERROR'

export const CARGO_TEMPLATES_LOADING = 'CARGO_TEMPLATES_LOADING'
export const CARGO_TEMPLATES_LOADED = 'CARGO_TEMPLATES_LOADED'
export const CARGO_TEMPLATES_LOAD_ERROR = 'CARGO_TEMPLATES_LOAD_ERROR'

export const CARGO_DELETE_TEMPLATE_START = 'CARGO_DELETE_TEMPLATE_START'
export const CARGO_DELETE_TEMPLATE_SUCCESS = 'CARGO_DELETE_TEMPLATE_SUCCESS'
export const CARGO_DELETE_TEMPLATE_ERROR = 'CARGO_DELETE_TEMPLATE_ERROR'

export const USERS_REQUEST = 'USERS_REQUEST'
export const USERS_RECEIVE = 'USERS_RECEIVE'
export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST'
export const USER_PROFILE_RECEIVE = 'USER_PROFILE_RECEIVE'

export const USER_SAVE_PROFILE_START = 'USER_SAVE_PROFILE_START'
export const USER_SAVE_PROFILE_SUCCESS = 'USER_SAVE_PROFILE_SUCCESS'
export const USER_SAVE_PROFILE_FAILURE = 'USER_SAVE_PROFILE_FAILURE'

export const USER_GET_PROFILE_START = 'USER_GET_PROFILE_START'
export const USER_GET_PROFILE_SUCCESS = 'USER_GET_PROFILE_SUCCESS'
export const USER_GET_PROFILE_FAILURE = 'USER_GET_PROFILE_FAILURE'

export const USER_GET_STATISTICS = 'USER_GET_STATISTICS'
export const USER_CHANGE_DATE_REGISTRATION = 'USER_CHANGE_DATE_REGISTRATION'

// Socket.io
export const SOCKET_GET_LOGIN_STATUS = 'SOCKET_GET_LOGIN_STATUS'
export const SOCKET_LOGIN_STATUS = 'SOCKET_LOGIN_STATUS'
export const SOCKET_NEW_LOGIN_ATTEMPT = 'SOCKET_NEW_LOGIN_ATTEMPT'
export const SOCKET_NEW_LOGIN_ATTEMPT_ALLOW = 'SOCKET_NEW_LOGIN_ATTEMPT_ALLOW'
export const SOCKET_NEW_LOGIN_ATTEMPT_DENY = 'SOCKET_NEW_LOGIN_ATTEMPT_DENY'
export const SOCKET_LOGIN_ATTEMPT_RESET = 'SOCKET_LOGIN_ATTEMPT_RESET'
export const SOCKET_LOGIN_ALLOWED = 'SOCKET_LOGIN_ALLOWED'
export const SOCKET_LOGIN_DENIED = 'SOCKET_LOGIN_DENIED'
export const SOCKET_GOT_NEW_CARGO_ENTRY = 'SOCKET_GOT_NEW_CARGO_ENTRY'
export const SOCKET_REFRESH_SEARCH_FINISH = 'SOCKET_REFRESH_SEARCH_FINISH'

export const SET_CHAT_PARTENER_ID = 'SET_CHAT_PARTENER_ID'
export const SET_INITIAL_MESSENGER_STATE = 'SET_INITIAL_MESSENGER_STATE'
export const SET_INITIAL_CHAT_STATE = 'SET_INITIAL_CHAT_STATE'
export const SOCKET_NEW_MESSAGE = 'SOCKET_NEW_MESSAGE'
export const SOCKET_NEW_MESSAGE_ARRIVED = 'SOCKET_NEW_MESSAGE_ARRIVED'
export const CHAT_MARK_MESSAGES_AS_READ = 'CHAT_MARK_MESSAGES_AS_READ'
export const GET_CONVERSATION_LIST = 'GET_CONVERSATION_LIST'
export const CONVERSATION_SCROLL_TO_BOTTOM = 'CONVERSATION_SCROLL_TO_BOTTOM'
export const GET_CONVERSATION_LIST_ARRIVED = 'GET_CONVERSATION_LIST_ARRIVED'
export const SOCKET_LOGOUT_FROM_ALL_DEVICES = 'SOCKET_LOGOUT_FROM_ALL_DEVICES'

export const UI_ACTIVE_POPUP_GUID = 'UI_ACTIVE_POPUP_GUID'
export const UI_CHANGE_LANGUAGE_START = 'UI_CHANGE_LANGUAGE_START'
export const UI_CHANGE_LANGUAGE_END = 'UI_CHANGE_LANGUAGE_END'
export const UI_TRANSLATION_LOADED = 'UI_TRANSLATION_LOADED'
export const UI_TRANSLATION_LOADING = 'UI_TRANSLATION_LOADING'
export const UI_SET_AVAILABLE_LANGUAGES = 'UI_SET_AVAILABLE_LANGUAGES'
export const UI_CHANGE_LANGUAGE_FETCHING = 'UI_CHANGE_LANGUAGE_FETCHING'
export const NEWS_LIST_LOADING = 'NEWS_LIST_LOADING'
export const NEWS_LIST_LOADED = 'NEWS_LIST_LOADED'
export const NEWS_LIST_LOAD_ERROR = 'NEWS_LIST_LOAD_ERROR'
export const NEWS_LIST_MINI_LOADING = 'NEWS_LIST_MINI_LOADING'
export const NEWS_LIST_MINI_LOADED = 'NEWS_LIST_MINI_LOADED'
export const NEWS_LIST_MINI_LOAD_ERROR = 'NEWS_LIST_MINI_LOAD_ERROR'
export const NEWS_LIST_SET_PARAMS = 'NEWS_LIST_SET_PARAMS'
export const NEWS_ITEM_LOADING = 'NEWS_ITEM_LOADING'
export const NEWS_ITEM_LOADED = 'NEWS_ITEM_LOADED'
export const NEWS_ITEM_LOAD_ERROR = 'NEWS_ITEM_LOAD_ERROR'
export const NEWS_DELETING = 'NEWS_DELETING'
export const NEWS_DELETED = 'NEWS_DELETED'
export const NEWS_DELETE_ERROR = 'NEWS_DELETE_ERROR'
export const NEWS_COMMENTS_LOADING = 'NEWS_COMMENTS_LOADING'
export const NEWS_COMMENTS_LOADED = 'NEWS_COMMENTS_LOADED'
export const NEWS_COMMENTS_LOAD_ERROR = 'NEWS_COMMENTS_LOAD_ERROR'
export const NEWS_COMMENT_ADDING = 'NEWS_COMMENT_ADDING'
export const NEWS_COMMENT_ADDED = 'NEWS_COMMENT_ADDED'
export const NEWS_COMMENT_ADD_ERROR = 'NEWS_COMMENT_ADD_ERROR'
export const NEWS_COMMENT_DELETING = 'NEWS_COMMENT_DELETING'
export const NEWS_COMMENT_DELETED = 'NEWS_COMMENT_DELETED'
export const NEWS_COMMENT_DELETE_ERROR = 'NEWS_COMMENT_DELETE_ERROR'

export const NEWS_PUBLISH_START = 'NEWS_PUBLISH_START'
export const NEWS_PUBLISH_FINISH = 'NEWS_PUBLISH_FINISH'
export const NEWS_PUBLISH_ERROR = 'NEWS_PUBLISH_ERROR'

export const NEWS_SET_EDITING_ITEM = 'NEWS_SET_EDITING_ITEM'
export const NEWS_MODAL_SHOW = 'NEWS_MODAL_SHOW'
export const NEWS_MODAL_HIDE = 'NEWS_MODAL_HIDE'
export const NEWS_SAVE_START = 'NEWS_SAVE_START'
export const NEWS_SAVED = 'NEWS_SAVED'
export const NEWS_SAVE_ERROR = 'NEWS_SAVE_ERROR'

export const NEWS_SWITCH_LAYOUT = 'NEWS_SWITCH_LAYOUT'

export const HTML_LOADING = 'HTML_LOADING'
export const HTML_LOADED = 'HTML_LOADED'
export const HTML_LOAD_ERROR = 'HTML_LOAD_ERROR'

export const BASE_LIST_LOADING = 'BASE_LIST_LOADING'
export const BASE_LIST_LOADED = 'BASE_LIST_LOADED'
export const BASE_LIST_LOAD_ERROR = 'BASE_LIST_LOAD_ERROR'
export const BASE_ITEM_LOADING = 'BASE_ITEM_LOADING'
export const BASE_ITEM_LOADED = 'BASE_ITEM_LOADED'
export const BASE_ITEM_LOAD_ERROR = 'BASE_ITEM_LOAD_ERROR'

export const TRANSPORT_CAR_NEW = 'TRANSPORT_CAR_NEW'
export const TRANSPORT_CAR_LOADING = 'TRANSPORT_CAR_LOADING'
export const TRANSPORT_CAR_LOADED = 'TRANSPORT_CAR_LOADED'
export const TRANSPORT_CAR_LOAD_ERROR = 'TRANSPORT_CAR_LOAD_ERROR'

export const TRANSPORT_CAR_SAVING = 'TRANSPORT_CAR_SAVING'
export const TRANSPORT_CAR_SAVED = 'TRANSPORT_CAR_SAVED'
export const TRANSPORT_CAR_SAVE_ERROR = 'TRANSPORT_CAR_SAVE_ERROR'

export const TRANSPORT_CAR_LIST_LOADING = 'TRANSPORT_CAR_LIST_LOADING'
export const TRANSPORT_CAR_LIST_LOADED = 'TRANSPORT_CAR_LIST_LOADED'
export const TRANSPORT_CAR_LIST_LOAD_ERROR = 'TRANSPORT_CAR_LIST_LOAD_ERROR'

export const TRANSPORT_CAR_DELETING = 'TRANSPORT_CAR_DELETING'
export const TRANSPORT_CAR_DELETED = 'TRANSPORT_CAR_DELETED'
export const TRANSPORT_CAR_DELETE_ERROR = 'TRANSPORT_CAR_DELETE_ERROR'

export const TRANSPORT_ADD_SAVING = 'TRANSPORT_ADD_SAVING'
export const TRANSPORT_ADD_SAVED = 'TRANSPORT_ADD_SAVED'
export const TRANSPORT_ADD_ERROR = 'TRANSPORT_ADD_ERROR'

export const TRANSPORT_CHANGE_ACTIVE = 'TRANSPORT_CHANGE_ACTIVE'

export const TRANSPORT_SEARCH_START = 'TRANSPORT_SEARCH_START'
export const TRANSPORT_SEARCH_SUCCESS = 'TRANSPORT_SEARCH_SUCCESS'
export const TRANSPORT_SEARCH_FAILURE = 'TRANSPORT_SEARCH_FAILURE'

export const TRANSPORT_GET_UNBLOCKED_START = 'TRANSPORT_GET_UNBLOCKED_START'
export const TRANSPORT_GET_UNBLOCKED_SUCCESS = 'TRANSPORT_GET_UNBLOCKED_SUCCESS'
export const TRANSPORT_GET_UNBLOCKED_FAILURE = 'TRANSPORT_GET_UNBLOCKED_FAILURE'

export const TRANSPORT_LAST_SEARCH_START = 'TRANSPORT_LAST_SEARCH_START'
export const TRANSPORT_LAST_SEARCH_SUCCESS = 'TRANSPORT_LAST_SEARCH_SUCCESS'
export const TRANSPORT_LAST_SEARCH_FAILURE = 'TRANSPORT_LAST_SEARCH_FAILURE'
export const TRANSPORT_DELETE_LAST_SEARCH_SUCCESS = 'TRANSPORT_DELETE_LAST_SEARCH_SUCCESS'
export const TRANSPORT_DELETE_LAST_SEARCH_FAILURE = 'TRANSPORT_DELETE_LAST_SEARCH_SUCCESS'

export const TRANSPORT_ADD_FAVOURITE_START = 'TRANSPORT_ADD_FAVOURITE_START'
export const TRANSPORT_ADD_FAVOURITE_SUCCESS = 'TRANSPORT_ADD_FAVOURITE_SUCCESS'
export const TRANSPORT_ADD_FAVOURITE_FAILURE = 'TRANSPORT_ADD_FAVOURITE_START'
export const TRANSPORT_DELETE_FAVOURITE_START = 'TRANSPORT_DELETE_FAVOURITE_START'
export const TRANSPORT_DELETE_FAVOURITE_SUCCESS = 'TRANSPORT_DELETE_FAVOURITE_SUCCESS'
export const TRANSPORT_DELETE_FAVOURITE_FAILURE = 'TRANSPORT_DELETE_FAVOURITE_START'

export const TRANSPORT_ITEM_LOADING = 'TRANSPORT_ITEM_LOADING'
export const TRANSPORT_ITEM_LOADED = 'TRANSPORT_ITEM_LOADED'
export const TRANSPORT_ITEM_LOAD_ERROR = 'CARGO_ITEM_LOAD_ERROR'

export const TRANSPORT_TEMPLATE_SAVING = 'TRANSPORT_TEMPLATE_SAVING'
export const TRANSPORT_TEMPLATE_SAVED = 'TRANSPORT_TEMPLATE_SAVED'
export const TRANSPORT_TEMPLATE_SAVE_ERROR = 'TRANSPORT_TEMPLATE_SAVE_ERROR'
export const TRANSPORT_TEMPLATES_LOADING = 'TRANSPORT_TEMPLATES_LOADING'
export const TRANSPORT_TEMPLATES_LOADED = 'TRANSPORT_TEMPLATES_LOADED'
export const TRANSPORT_TEMPLATES_LOAD_ERROR = 'TRANSPORT_TEMPLATES_LOAD_ERROR'

export const TRANSPORT_DELETE_TEMPLATE_START = 'TRANSPORT_DELETE_TEMPLATE_START'
export const TRANSPORT_DELETE_TEMPLATE_SUCCESS = 'TRANSPORT_DELETE_TEMPLATE_SUCCESS'
export const TRANSPORT_DELETE_TEMPLATE_ERROR = 'TRANSPORT_DELETE_TEMPLATE_ERROR'

export const COMPANY_LIST_LOADING = 'COMPANY_LIST_LOADING'
export const COMPANY_LIST_LOADED = 'COMPANY_LIST_LOADED'
export const COMPANY_LIST_LOAD_ERROR = 'COMPANY_LIST_LOAD_ERROR'
export const COMPANY_LIST_SET_PARAMS = 'COMPANY_LIST_SET_PARAMS'
export const COMPANY_ITEM_LOADING = 'COMPANY_ITEM_LOADING'
export const COMPANY_ITEM_LOADED = 'COMPANY_ITEM_LOADED'
export const COMPANY_ITEM_LOAD_ERROR = 'COMPANY_ITEM_LOAD_ERROR'
export const COMPANY_SAVE_START = 'COMPANY_SAVE_START'
export const COMPANY_SAVED = 'COMPANY_SAVED'
export const COMPANY_SAVE_ERROR = 'COMPANY_SAVE_ERROR'
export const COMPANY_DELETING = 'COMPANY_DELETING'
export const COMPANY_DELETED = 'COMPANY_DELETED'
export const COMPANY_DELETE_ERROR = 'COMPANY_DELETE_ERROR'
export const COMPANY_TUTORIAL_POPUP = 'COMPANY_TUTORIAL_POPUP'
export const SET_COMPANY_VERIFICATION_DATA = 'SET_COMPANY_VERIFICATION_DATA'
export const SET_COMPANY_JOIN_REQUESTS = 'SET_COMPANY_JOIN_REQUESTS'

// ads
export const GET_INITIAL_VALUES = 'GET_INITIAL_VALUES'
export const RESET_INITIAL_VALUES = 'RESET_INITIAL_VALUES'

// access
export const ACCESS_GET_LIST = 'ACCESS_GET_LIST'
export const ACCESS_GET_USER_NAME = 'ACCESS_GET_USER_NAME'
export const ACCESS_ADD_POINT = 'ACCESS_ADD_POINT'
export const ACCESS_DELETE_POINT = 'ACCESS_DELETE_POINT'
export const ACCESS_CHANGE_GROUP = 'ACCESS_CHANGE_GROUP'
export const SET_GLOBAL_AUTOUPDATE = 'SET_GLOBAL_AUTOUPDATE'
//forum
export const GET_FORUM_THEMES = 'GET_FORUM_THEMES'

//profile
export const PROFILE_GET_START = 'PROFILE_GET_START'
export const PROFILE_GET_SUCCESS = 'PROFILE_GET_SUCCESS'
export const PROFILE_GET_FAILURE = 'PROFILE_GET_FAILURE'
export const PROFILE_RESET = 'PROFILE_RESET'
export const PROFILE_GET_USER_RATING = 'PROFILE_GET_USER_RATING'
export const PROFILE_GET_USER_ADD_REVIEW = 'PROFILE_GET_USER_ADD_REVIEW'
export const SHOW_USER_COMPANY_IN_PROCESS = 'SHOW_USER_COMPANY_IN_PROCESS'

//summary
export const SUMMARY_GET_INTRANATIONAL = 'SUMMARY_GET_INTRANATIONAL'
export const SUMMARY_GET_INTERNATIONAL = 'SUMMARY_GET_INTERNATIONAL'
export const SUMMARY_CHANGE_FAVORITE = 'SUMMARY_CHANGE_FAVORITE'
export const SUMMARY_GET_INTRANATIONAL_START = 'SUMMARY_GET_INTRANATIONAL_START'
export const SUMMARY_GET_INTERNATIONAL_START = 'SUMMARY_GET_INTERNATIONAL_START'
