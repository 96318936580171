export const md11 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>ПРИКАЗ об установлении цен на разрешения для международного дорожного транспорта</h4><p></p>

<p style="text-indent:2em;"></p><h6>1. Установить начиная с 1 января 2006 г. стоимость одного одноразового разрешения для международного автотранспорта:</h6><p></p> 

<p>- в размере 10 евро для Беларуси и Украины (с оплатой дорожных сборов);</p>

<p style="text-indent:2em;"></p><h6>- в размере 30 евро для следующих стран:</h6><p></p> 

<p style="text-indent:2em;">Австрии, Азербайджана, Бельгии, Беларуси (без оплаты дорожных сборов), Болгарии, Чехии, Хорватии, Дании, Испании, Швеции, Эстонии, Финляндии, Франции, Германии, Армении, Греции, Италии, Казахстана,
 Киргизии, Литвы, Латвии, Люксембурга, Македонии, Швейцарии, Польши, Румынии, России, Словакии, Словении, Турции, Украины (без оплаты дорожных сборов), Венгрии, Узбекистана и одноразовых разрешений
 Республики Молдова для иностранного транспорта, выполняющего двусторонние или транзитные перевозки по территории Республики Молдова;</p><br>

<p style="text-indent:2em;">- в размере 150 евро - одноразовое разрешение Республики Молдова для иностранного транспорта, выполняющего перевозку товаров в/из третьих стран в/из Республики Молдова.</p><br>

<p style="font-weight:500;">2. Оплата стоимости разрешения бенефициаром для международного автотранспорта выполняется согласно коду бенефициара, казначейскому счету на прибыль (районные фискальные органы) и местному статистическому
 коду, где внесен вклад и взят на учет территориальным фискальным органом (код места жительства владельца лицензии).</p><br>

<p style="font-weight:500;">3. Аннулировать приказ Министерства транспорта и коммуникаций № 292 от 27 декабря 2002 г.</p><br>

<p style="font-weight:500;">4. Контроль за выполнением настоящего приказа осуществляется генеральным директором I. S. "AMTAI" Андреем Руснак.</p><br>

<p><a href="https://www.legis.md/cautare/getResults?doc_id=43006&amp;lang=ru">Источник</a></p>



</div>
`