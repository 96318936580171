import isNil from 'lodash/isNil'

import fetch from '../../components/shared/fetch'
import { getBaseProps } from '../utils'
import { oops, queryInWords } from '../utils'
import observedObject from '../../observedObject'

import * as actionTypes from './action-types'

const win: any = window

interface ICargoSearchParams {
  query?: IEntitySearchQuery
  pageIndex?: number
  pageSize?: number
  sortBy?: string
  dateScope?: string
  activeScope?: string
  log?: boolean
  filterKey?: string
  exactUserId?: number
  sameCountryOnly?: boolean
}

// -------------------------------------------------
//
// -------------------------------------------------
export const addCargo = ({ item }, cb) => {
  return (dispatch, getState) => {
    const state = getState()
    return fetch('/cargo/add', {
      method: 'POST',
      body: JSON.stringify({ item, langId: state.ui.langId }),
    })
      .then(json => {
        dispatch({ type: actionTypes.CARGO_ADDED, payload: json.d })
        cb && cb(json)
      })
      .catch(error => dispatch(oops(error, actionTypes.CARGO_ADD_ERROR)))
  }
}

export function cargoNotificationSubscribe(query) {
  return dispatch => {
    return fetch('/cargo/cargoNotificationSubscribe', {
      method: 'POST',
      body: JSON.stringify({ socketId: win.socket.id, query }),
    })
  }
}

export function loadCargoLastSearch() {
  return (dispatch, getState) => {
    const state = getState()

    const { userId } = state.auth.jwt
    const payload = { userId }

    dispatch({ type: actionTypes.CARGO_LAST_SEARCH_START })

    const data = {
      ...getBaseProps(state),
      ...payload,
    }

    return fetch('/cargo/lastSearch', {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(json => dispatch({ type: actionTypes.CARGO_LAST_SEARCH_SUCCESS, payload: json.d }))
      .catch(error => dispatch(oops(error, actionTypes.CARGO_LAST_SEARCH_FAILURE)))
  }
}
// -------------------------------------------------
//
// -------------------------------------------------
export function cargoSearch({
  query,
  pageIndex,
  pageSize,
  sortBy,
  dateScope,
  activeScope,
  log,
  filterKey,
  exactUserId,
  sameCountryOnly
}: ICargoSearchParams) {
  return async (dispatch, getState) => {
    const state = getState()
    const isAuthenticated = !!state.auth.accessToken
    const { cargo } = state
    const { userId } = state.auth.jwt
    const { langId } = state.ui

    query = query || {}
    sortBy = sortBy || cargo.getIn(['search', 'sortBy'])

    const queryStr = await queryInWords(query, langId)
    const payload = {
      userId,
      query,
      queryStr,
      pageIndex,
      pageSize,
      sortBy,
      dateScope,
      activeScope,
      autoupdate: JSON.parse(localStorage.getItem('autoupdate')) || true,
      log,
      filterKey,
      socketId: win.socket.id || null,
      exactUserId,
      sameCountryOnly
    }
  
    dispatch({ type: actionTypes.CARGO_SEARCH_START, payload })

    if (!payload.socketId) {
      win.socket.id
        ? (payload.socketId = win.socket.id)
        : observedObject.on('socketInited', cargoNotificationSubscribe(query))
    }

    const data = {
      ...getBaseProps(state),
      ...payload,
    }

    return fetch('/cargo/search', {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(json => {
        dispatch({ type: actionTypes.CARGO_SEARCH_SUCCESS, payload: json.d })
        if (isAuthenticated && log === true) {
          dispatch(loadCargoLastSearch())
        }

        return json.d
      })
      .catch(error => dispatch(oops(error, actionTypes.CARGO_SEARCH_FAILURE)))
  }
}


export function cargoAddFavourite(payload) {
  const { cargoId, isItem } = payload
  return (dispatch, getState) => {
    const state = getState()
    const { userId } = state.auth.jwt

    dispatch({ type: actionTypes.CARGO_ADD_FAVOURITE_START })
    return fetch('/cargo/addFavourite', {
      method: 'POST',
      body: JSON.stringify({ ...getBaseProps(state), userId, cargoId }),
    })
      .then(json => {
        const payload = json.d[0]
        if (isItem) payload.isItem = true
        dispatch({ type: actionTypes.CARGO_ADD_FAVOURITE_SUCCESS, payload })
      })
      .catch(err => dispatch(oops(err, actionTypes.CARGO_ADD_FAVOURITE_FAILURE)))
  }
}

export function cargoDeleteFavourite(payload) {
  const { cargoId, isItem } = payload
  return (dispatch, getState) => {
    const state = getState()
    const { userId } = state.auth.jwt

    dispatch({ type: actionTypes.CARGO_DELETE_FAVOURITE_START })
    return fetch('/cargo/deleteFavourite', {
      method: 'POST',
      body: JSON.stringify({ ...getBaseProps(state), userId, cargoId }),
    })
      .then(json => {
        const payload = json.d
        if (isItem) payload.isItem = true
        dispatch({ type: actionTypes.CARGO_DELETE_FAVOURITE_SUCCESS, payload })
      })
      .catch(err => dispatch(oops(err, actionTypes.CARGO_DELETE_FAVOURITE_FAILURE)))
  }
}

export function cargoNotificationUnsubscribe() {
  return dispatch => {
    return fetch('/cargo/cargoNotificationUnsubscribe', {
      method: 'POST',
      body: JSON.stringify({ socketId: win.socket.id }),
    })
  }
}


export function deleteCargoLastSearch(searchId) {
  return (dispatch, getState) => {
    const state = getState()

    const { userId } = state.auth.jwt

    const data = {
      ...getBaseProps(state),
      userId,
      searchId,
    }

    return fetch('/cargo/deleteSearch', {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(json =>
        dispatch({ type: actionTypes.CARGO_DELETE_LAST_SEARCH_SUCCESS, payload: json.d })
      )
      .catch(err => dispatch(oops(err, actionTypes.CARGO_DELETE_LAST_SEARCH_FAILURE)))
  }
}

// ------------------------------------------
//
// ------------------------------------------
export const getCargo = ({ itemId }) => {
  return (dispatch, getState) => {
    const state = getState()
    const current = state.cargo.get('item')
    if (itemId === current.itemId && current.loading) return

    dispatch({ type: actionTypes.CARGO_ITEM_LOADING, payload: { requestedAt: new Date(), itemId } })

    const lang = state.ui.currentLanguage
    const url = `/cargo/${itemId}/${lang}`
    return fetch(url)
      .then(json => dispatch({ type: actionTypes.CARGO_ITEM_LOADED, data: json.d }))
      .catch(error => dispatch(oops(error, actionTypes.CARGO_ITEM_LOAD_ERROR)))
  }
}

export const changeActiveCargo = ({ cargoId }) => {
  return dispatch => {
    dispatch({ type: actionTypes.CARGO_CHANGE_ACTIVE, payload: { cargoId } })
    return fetch('/cargo/changeActiveCargo', {
      method: 'POST',
      body: JSON.stringify({
        cargoId,
      }),
    }).then(() => {
      fetch(`/user/getStatistics/profile`).then(response => {
        dispatch({ type: actionTypes.USER_GET_STATISTICS, payload: response.data })
      })
    })
  }
}
