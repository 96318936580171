import { GET_INITIAL_VALUES, RESET_INITIAL_VALUES } from '../actions/action-types'

const initialState = {
  item: {},
}
export default (state = initialState, { type, payload }: any = {}) => {
  switch (type) {
    case GET_INITIAL_VALUES: {
      return {
        ...state,
        item: payload,
      }
    }
    case RESET_INITIAL_VALUES: {
      return {
        ...state,
        item: payload,
      }
    }
    default:
      return state
  }
}
