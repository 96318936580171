import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Widget from '../shared/components/widget'
import { getUrlParamsString } from '../../store/utils'
import fetch from '../shared/fetch'

import './styles.scss'
// import { COMPANY_LIST_LOAD_ERROR } from '../../store/actions/action-types'

interface ItemValue {
  value: string | number
  class: string[]
}

interface TableItem {
  name: string
  values: ItemValue[]
  class: string[]
}

interface Table {
  names: string[]
  items: TableItem[]
}

interface MyStatisticsState {
  reviewCount: number
  tables: Table[]
  cargo: number
  rating: number
  cargoTotal: number
  userId: number
  oncomingTransport: number
  transport: number
  transportTotal: number
  oncomingCargo: number
  ads: number
  adsTotal: number
  autopark: number
  autoparkTotal: number
  companyAutoparkTotalNew: number
  companyAutopark: number
  blockListCount: number
  averageRating: number
  companiesReviewedByMe: number
  meBlockedByUsers: number
  meReviewedByUsers: number
  myCompanyReviewedByUsers: number
  usersBlockedByMe: number
  usersReviewedByMe: number

  averageRatingLast10Days: number
  companiesReviewedByMeLast10Days: number
  meBlockedByUsersLast10Days: number
  meReviewedByUsersLast10Days: number
  myCompanyReviewedByUsersLast10Days: number
  usersBlockedByMeLast10Days: number
  usersReviewedByMeLast10Days: number

}
export interface IStatistics {
  cargo: number
  cargoTotal: number
  oncomingTransport: number
  transport: number
  transportTotal: number
  oncomingCargo: number
  ads: number
  adsTotal: number
  autopark: number
  autoparkTotal: number
  companyAutoparkTotalNew: number
  companyAutopark: number
}

interface MyStatisticProps extends ComponentBaseProps, PermissionInjectProps {
  statistics: IStatistics
  companyId: number
}

export default class MyStatistics extends Component<MyStatisticProps, MyStatisticsState> {
  constructor(props) {
    super(props)

    this.state = {
      tables: [],
      cargo: 0,
      rating: 0,
      reviewCount: 0,
      cargoTotal: 0,
      userId: 0,
      oncomingTransport: 0,
      transport: 0,
      transportTotal: 0,
      oncomingCargo: 0,
      ads: 0,
      adsTotal: 0,
      autopark: 0,
      autoparkTotal: 0,
      blockListCount: 0,
      companyAutoparkTotalNew: 0,
      companyAutopark: 0,
      averageRating: 0,
      companiesReviewedByMe: 0,
      meBlockedByUsers: 0,
      meReviewedByUsers: 0,
      myCompanyReviewedByUsers: 0,
      usersBlockedByMe: 0,
      usersReviewedByMe: 0,
      averageRatingLast10Days: 0,
      companiesReviewedByMeLast10Days: 0,
      meBlockedByUsersLast10Days: 0,
      meReviewedByUsersLast10Days: 0,
      myCompanyReviewedByUsersLast10Days: 0,
      usersBlockedByMeLast10Days: 0,
      usersReviewedByMeLast10Days: 0,

    }
  }

  componentDidMount() {
    const { statistics } = this.props
    const { userId } = this.state
    this.getUserRating(userId)
    this.getUserBlockListCounter(userId)
    this.setWidgetStatistic(statistics, userId)
  }

  componentDidUpdate(prevProps) {
    const { statistics, userId } = this.props
    if (prevProps.statistics !== statistics) {
      this.setWidgetStatistic(statistics, userId)
    }
  }

  setWidgetStatistic = (statistics, userId) => {
    const {
      cargo,
      cargoTotal,
      oncomingTransport,
      transport,
      transportTotal,
      oncomingCargo,
      ads,
      adsTotal,
      autopark = 0,
      autoparkTotal = 0,
      companyAutoparkTotalNew = 0,
      companyAutopark = 0,


      averageRating,
      companiesReviewedByMe,
      meBlockedByUsers,
      meReviewedByUsers,
      myCompanyReviewedByUsers,
      usersBlockedByMe,
      usersReviewedByMe,
      averageRatingLast10Days,
      companiesReviewedByMeLast10Days,
      meBlockedByUsersLast10Days,
      meReviewedByUsersLast10Days,
      myCompanyReviewedByUsersLast10Days,
      usersBlockedByMeLast10Days,
      usersReviewedByMeLast10Days,
    } = statistics

    this.setState({
      tables: [],
      cargo,
      rating: 0,
      reviewCount: 0,
      cargoTotal,
      userId,
      oncomingTransport,
      transport,
      transportTotal,
      oncomingCargo,
      ads,
      adsTotal,
      autopark,
      autoparkTotal,
      companyAutoparkTotalNew,
      companyAutopark,
      blockListCount: 0,

      averageRating,
      companiesReviewedByMe,
      meBlockedByUsers,
      meReviewedByUsers,
      myCompanyReviewedByUsers,
      usersBlockedByMe,
      usersReviewedByMe,
      averageRatingLast10Days,
      companiesReviewedByMeLast10Days,
      meBlockedByUsersLast10Days,
      meReviewedByUsersLast10Days,
      myCompanyReviewedByUsersLast10Days,
      usersBlockedByMeLast10Days,
      usersReviewedByMeLast10Days,

    })
  }

  getUserBlockListCounter = userId => {
    const paramsString = getUrlParamsString({
      userId,
      pageIndex: 0,
      pageSize: 100,
    })

    return fetch(`/user/getBlockList?${paramsString}`).then(res => {
      this.setState({ blockListCount: res.data.length })
    })
  }

  getUserRating = userId => {
    fetch(`/user/getUserRating?userId=${userId}`)
      .then(res => {
        const { rating, reviewCount } = res.data
        this.setState({ rating, reviewCount })
      })
      .catch(e => console.error(e))
  }

  widgetData = () => {
    const { t, companyId, userId } = this.props
    const {
      rating,
      reviewCount,
      cargo,
      cargoTotal,
      oncomingTransport,
      transport,
      transportTotal,
      oncomingCargo,
      ads,
      adsTotal,
      autopark,
      autoparkTotal,
      companyAutoparkTotalNew,
      companyAutopark,
      blockListCount,

      averageRating,
      companiesReviewedByMe,
      meBlockedByUsers,
      meReviewedByUsers,
      myCompanyReviewedByUsers,
      usersBlockedByMe,
      usersReviewedByMe,

      averageRatingLast10Days,
      companiesReviewedByMeLast10Days,
      meBlockedByUsersLast10Days,
      meReviewedByUsersLast10Days,
      myCompanyReviewedByUsersLast10Days,
      usersBlockedByMeLast10Days,
      usersReviewedByMeLast10Days,
    } = this.state

    const updatedUsersReviewedByMeLast10Days = usersReviewedByMeLast10Days ? usersReviewedByMeLast10Days : 0

    const widgetData = {
      tables: [
        {
          names: ['', t('statistics.activ'), t('statistics.total')],
          items: [
            {
              name: t('statistics.my.cargo'),
              link: "/cargo/offers",
              values: [
                { value: cargo, class: [] },
                { value: cargoTotal, class: [] },
              ],
              class: [],
            },
            {
              name: t('statistics.oncoming.transport'),
              link: "/cargo/offers",
              values: [
                { value: "", class: [] },
                { value: oncomingTransport, class: [] },
              ],
              class: ['meta', 'no-border'],
            },
            {
              name: t('statistics.my.transport'),
              link: "/transport/offers",
              values: [
                { value: transport, class: [] },
                { value: transportTotal, class: [] },
              ],
              class: [],
            },
            {
              name: t('statistics.oncoming.cargo'),
              link: "/transport/offers",
              values: [
                { value: "", class: [] },
                { value: oncomingCargo, class: [] },
              ],
              class: ['meta', 'no-border'],
            },
            {
              name: t('statistics.my.ads'),
              link: "/ads/my-ads",
              values: [
                { value: ads, class: [] },
                { value: adsTotal, class: [] },
              ],
              class: [],
            },
          ],
        },
        {
          names: [
            '',
            t('cargo'),
            t('statistics.transport'),
            t('statistics.ads'),
            t('statistics.news'),
          ],
          items: [
            {
              name: t('statistics.my.bookmarks'),
              link: "/",
              values: [
                { value: "-", class: [] },
                { value: "-", class: [] },
                { value: "-", class: [] },
                { value: "-", class: [] },
              ],
              class: [],
            },
          ],
        },
        {
          names: ['', t('statistics.last.10.days'), t('statistics.total')],
          items: [
            {
              name: t('statistics.users.blocklist'),
              link: "/",
              values: [
                { value: usersBlockedByMeLast10Days, class: [] },
                { value: usersBlockedByMe, class: [] },
              ],
              class: [],
            },
            {
              name: t('statistics.i.blocklists'),
              link: "/",
              values: [
                { value: meBlockedByUsersLast10Days, class: [] },
                { value: meBlockedByUsers, class: [] },
              ],
              class: ['no-border'],
            },
            {
              name: t('statistics.my.rating'),
              link: `/user/details/${userId}`,
              values: [
                { value: averageRatingLast10Days, class: ['col-green'] },
                { value: averageRating, class: [] },
              ],
              class: [],
            },
            {
              name: t('statistics.reviews.on.me'),
              link: `/user/details/${userId}`,
              values: [
                { value: meReviewedByUsersLast10Days, class: [] },
                { value: meReviewedByUsers, class: [] },
              ],
              class: ['no-border'],
            },
            {
              name: t('statistics.reviews.on.my.company'),
              link: companyId ? `/company/item/${companyId}` : "/",
              values: [
                { value: companyId ? myCompanyReviewedByUsersLast10Days : "-", class: [] },
                { value: companyId ? myCompanyReviewedByUsers : "-", class: [] },
              ],
              class: ['no-border'],
            },
            {
              name: t('statistics.my.reviews.on.users'),
              link: "",
              values: [
                { value: `${updatedUsersReviewedByMeLast10Days}`, class: [] },
                { value: usersReviewedByMe, class: [] },
              ],
              class: ['no-border'],
            },
            {
              name: t('statistics.my.reviews.on.companies'),
              link: "/",
              values: [
                { value: companiesReviewedByMeLast10Days, class: [] },
                { value: companiesReviewedByMe || 0, class: [] },
              ],
              class: ['no-border'],
            },
            {
              name: t('statistics.black.list'),
              link: "/",
              values: [
                { value: "-", class: [] },
                { value: "-", class: [] },
              ],
              class: ['meta'],
            },
            {
              name: t('statistics.my.company.claims.to.companies'),
              link: "/",
              values: [
                { value: "-", class: ['col-red'] },
                { value: "-", class: ['col-red'] },
              ],
              class: ['no-border'],
            },
            {
              name: t('statistics.claims.to.my.company'),
              link: "/",
              values: [
                { value: "-", class: [] },
                { value: "-", class: [] },
              ],
              class: ['no-border'],
            },
            {
              name: t('statistics.my.autopark'),
              link: "/transport/autopark",
              values: [
                { value: autopark  || 0, class: [] },
                { value: autoparkTotal || 0, class: [] },
              ],
              class: [],
            },
            {
              name: t('statistics.my.company.autopark'),
              link: "/transport/autopark",
              values: [
                { value: companyAutopark || 0, class: [] },
                { value: companyAutoparkTotalNew || 0, class: [] },
              ],
              class: ['no-border'],
            },
            {
              name: t('statistics.my.distance.calculations'),
              link: "/",
              values: [
                { value: "-", class: [] },
                { value: "-", class: [] },
              ],
              class: ['no-border'],
            },
          ],
        },
      ],
    }
    return widgetData
  }

  render() {
    const { t, dataMoveForMobile } = this.props
    const widgetData = this.widgetData()
    return (
      <Widget title={t('statistics.my')} dataMoveForMobile={dataMoveForMobile}>
        {(widgetData.tables || []).map((table, index) => (
          <table className="table-simple" key={'a' + index}>
            <thead>
              <tr>
                {table.names.map((name, index) => (
                  <th key={'b' + index}>{name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {(table.items || []).map((item, index) => (
                <tr key={'c' + index} className={item.class.join(' ')}>
                  <td>
                    <Link to={item.link}>
                      {item.name}
                    </Link>
                  </td>
                  {(item.values || []).map((value, index) => (
                    <td key={'d' + index} className={value.class.join(' ')}>
                      {value.value === "" ? "" : value.value || 0}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </Widget>
    )
  }
}
