import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import classNames from 'classnames'
import { ModalConsumer, ModalContextState } from '../../../../context/modal-context'
import TooltipWrapper from '../../../UI/popover-wrapper'
import Pagination from '../../../UI/pagination'
import config from '../../../../config'
import dict from '../../../shared/dictionaries'
import { transportPlaceholder } from '../../../shared/utils/imagePlaceholder'
import ItemModal from '../../modal-item'
import { scrollToElement } from '../../../../store/utils'

import './styles.scss'

export default class extends Component<any, any> {
  modalConsumer: ModalContextState

  constructor(props) {
    super(props)

    this.state = {
      bodyTypes: [],
      pageIndex: 0,
      confirmItemId: null,
    }
  }

  componentDidMount() {
    const { langId } = this.props
    const { loaded, handler, items } = dict.getTransportTypes('auto')(langId)
    if (loaded) {
      this.setState({ bodyTypes: items })
    } else {
      handler.then(response => {
        this.setState({ bodyTypes: response.items })
      })
    }
  }

  showItemModal = (item: any, edit: boolean, placeholder) => {
    const { imageFolder, showInUrlCurrentUserId = false } = this.props
    const { showModalComponent, clearModal } = this.modalConsumer

    if (item.photo?.length === 0) {
      item.photo[0] = placeholder
    }

    const folder = showInUrlCurrentUserId ? `auto-park/${item.user_id}` : `${imageFolder}`

    showModalComponent(
      <ItemModal
        item={item}
        onClose={clearModal}
        canEdit={edit}
        imageFolder={folder}
        refresh={this.props.refresh}
      />,
      {
        className: `transport-modal`,
        skipLayout: true,
        backdrop: true,
      }
    )
  }

  renderBaseInfo = (car, typeId?: any) => {
    const { bodyTypes } = this.state
    const bodyType = bodyTypes.find(x => x.value === car.body_type_id)

    return (
      <div className="truck-item-row bordered">
        <div className="row">
          <div className="col-xs-9">
            <span className="truck-name">{car.make_and_model}</span>
          </div>
          <div className="col-xs-7">
            <span className="truck-number">{car.gos_nomer}</span>
          </div>
        </div>
        <p className="meta-gray truck-meta-aligned">
          <span>{car.year}</span>
          <span>{bodyType && typeId !== 2103 ? bodyType.name : null}</span>
          {(car.eco_standard_id === 0 ||  car.eco_standard_id > 0) && <span>Euro-{car.eco_standard_id}</span>}
        </p>
      </div>
    )
  }

  paginationClick = pageIndex => {
    const { scrollToElemId = false } = this.props
    scrollToElemId && scrollToElement(scrollToElemId)
    this.setState({ pageIndex }, () => {
      this.props.refresh({ pageIndex })
    })
  }

  clickDelete = (item, callback) => {
    const { t, deleteCar, refresh, onCanAdd } = this.props
    this.setState({ confirmItemId: item.id })
    confirmAlert({
      title: t('delete.confirm'),
      message: (
        <span>
          {t('delete.sure')}
          <br />
          {t('delete.cannot.undone')}
        </span>
      ) as any,
      buttons: [
        {
          label: t('delete'),
          onClick: () => {
            deleteCar(this.state.confirmItemId, () => {
              this.hideConfirm()
              this.setState({ pageIndex: 0 })
              onCanAdd("autopark")
              refresh()
              callback && callback()
            })
          },
        },
        {
          label: t('button.close'),
          onClick: this.hideConfirm,
        },
      ],
      onClickOutside: this.hideConfirm,
      onKeypressEscape: this.hideConfirm,
    })
  }
  hideConfirm = () => {
    this.setState({ confirmItemId: undefined })
  }

  render() {
    const {
      t,
      langId,
      pageSize,
      carList,
      canEdit = false,
      total,
      onClose,
      imageFolder,
      showInUrlCurrentUserId = false
    } = this.props
    const { pageIndex } = this.state
    const carTypes = dict.carTypes(t)
    const ownershipTypesCars = dict.ownershipTypesCars(t)
    const hasTrailer = carTypeId => [2102, 2103].includes(carTypeId)
    return (
      <Fragment>
        <div className="panel-body car-list-container">
          {carList.map(item => {
            const transport = item.transport || {}
            const photo = item.photo || item.photos
            const isOversized = item.is_oversized
            const isGrouping = item.is_grouping
            const isCustom = item.is_custom || transport.crg_custom_control
            const typeId = item.car_type_id || item.car.car_type_id
            const ownershipId = item.ownership_type_id || transport.ownershipType
            const isLoadingTop = item.is_loading_top || transport.loadingTop
            const isLoadingRare = item.is_loading_rare || transport.loadingRare
            const isLoadingSide = item.is_loading_side || transport.loadingSide
            const weight = item.weight_value || transport.weight
            const volumeM3 = item.volume_m3 || transport.volume_m3
            const volumeLdm = item.volume_ldm || transport.volume_ldm
            const volumePal = item.volume_pal || transport.volume_pal
            const isClimateControl = item.is_climate_control || transport.temperature
            const temperatureFrom = item.temperature_from || transport.temperature?.from
            const temperatureTo = item.temperature_to || transport.temperature?.to
            const adrClass = item.adr_class || transport.transport_adr_class
            const isTir = item.is_tir || transport.crg_tir
            const isCmr = item.is_cmr || transport.crg_cmr
            const isAdr = item.is_adr || transport.crg_adr
            const isDeclaration = item.is_declaration || transport.crg_declaration
            const isSanitary = item.is_sanitary || transport.crg_cmr_insurance
            const notes = item.notes || transport.info
            const isGps = item.is_gps || transport.is_gps

            const carType = carTypes.find(x => x.value === typeId)
            const ownershipType = ownershipTypesCars.find(x => x.value === ownershipId)
            const placeholderImage = transportPlaceholder(carType.value)
            const isPlaceholderImage =
              !photo.length || (photo.length === 1 && photo[0].includes('/static/media/') || photo[0].includes('data:image/png'))

            const folder = showInUrlCurrentUserId ? `auto-park/${item.user_id}` : `${imageFolder}`

            return (
              <div className="truck-item" key={item.id}>
                <div className="truck-item-top row">
                  <div className="col-sm-10 col-sm-offset-2">
                    <p className="name-main">
                      {carType && carType.name}
                      <u>{ownershipType && ownershipType.name}</u>
                    </p>
                  </div>

                  {canEdit && (
                    <div className="col-sm-4 ta-r">
                      <Link
                        to={`/transport/autopark/${item.id}`}
                        className="icon icon-edit"
                        onClick={() => onClose && onClose()}
                      >
                        <i className="fa fa-pencil" />
                      </Link>
                      <Link
                        to="#"
                        className="icon icon-remove col-red"
                        onClick={() => this.clickDelete(item, onClose)}
                      >
                        <i className="fa fa-trash" />
                      </Link>
                    </div>
                  )}
                </div>
                <div className="truck-item-main row">
                  <div className="truck-item-block col-sm-4 col-md-2">
                    <div
                      className="img-container"
                      onClick={() => this.showItemModal(item, canEdit, placeholderImage)}
                    >
                      {!isPlaceholderImage ? (
                        <div
                          className="car-img-container"
                          style={{
                            backgroundImage: `url(${(config as any).apiUrl
                              }/common/file/${folder}/${photo[0]})`,
                          }}
                        />
                      ) : (
                        <div
                          className="car-img-container"
                          style={{
                            backgroundImage: `url('${placeholderImage}')`,
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="truck-item-block col-sm-12 col-md-6">
                    {this.renderBaseInfo(item.car, typeId)}
                    {hasTrailer(typeId) && this.renderBaseInfo(item.trailer)}
                  </div>
                  <div className="truck-item-block col-sm-6 col-md-4 col-lg-3 col-xl-4">
                    <div className="truck-item-row">
                      <span className="label">{t('autopark.upload.download')}</span>
                      <p className="truck-meta-aligned">
                        {isLoadingTop && <span>{t('autopark.up')}</span>}
                        {isLoadingSide && <span>{t('autopark.side')}</span>}
                        {isLoadingRare && <span>{t('autopark.back')}</span>}
                      </p>
                    </div>
                    <div className="truck-item-row">
                      <span className="label">{t('autopark.carrying.capacity')}</span>
                      <p className="truck-meta-aligned">
                        <span>{weight ? `${weight} t` : '-'}</span>
                      </p>

                      <div className="truck-item-row additional-options">
                        {isOversized && (
                          <span>
                            <i className="ico ico-weight" />
                            {t('cargo.oversize')}
                          </span>
                        )}
                        {isGrouping && (
                          <span>
                            <i className="fa fa-th-large" />
                            {t('cargo.grouping')}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="truck-item-block col-sm-6 col-md-4 col-lg-5 col-xl-4">
                    <div className="truck-item-row">
                      <span className="label">{t('autopark.volume')}</span>
                      <p className="truck-meta-aligned">

                        {(volumeM3 || volumeM3 === 0) && (
                          <span key="m3">
                            {volumeM3} {t('autopark.m3')}
                          </span>
                        )}
                        {(volumeLdm || volumeLdm === 0) && (
                          <span key="ldm">
                            {volumeLdm} {t('autopark.ldm')}
                          </span>
                        )}
                        {(volumePal || volumePal === 0) && (
                          <span key="pal">
                            {volumePal} {t('autopark.p')}
                          </span>
                        )}

                      </p>
                    </div>
                    <div className="truck-item-row clearfix">
                      {isAdr && (
                        <div className="fl-left">
                          <span className="label">
                            {t('autopark.adr')}
                            <i className="fa fa-fire" />
                          </span>
                          <p className="truck-meta-aligned">
                            <span>
                              {adrClass} {t('autopark.class.above')}
                            </span>
                          </p>
                        </div>
                      )}
                      <div
                        className={classNames({
                          'fl-right': isAdr,
                          'no-margin': true,
                        })}
                      >
                        {isClimateControl && (
                          <Fragment>
                            <span className="label">
                              {t('autopark.temp.mode')}
                              <i className="fa fa-snowflake-o" />
                            </span>
                            <p className="truck-meta-aligned">
                              <span>
                                {isClimateControl ? (<span className="name">
                                  {t('autopark.from')}{' '}
                                  {temperatureFrom !== "null" ? temperatureFrom || 0 : ' 0'}{'\u2103 '}
                                  {t('autopark.to')}{' '}
                                  {temperatureTo !== "null" ? temperatureTo || 0 : ' 0'}{'\u2103 '}
                                </span>) : '-'}
                              </span>
                            </p>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="truck-item-bottom">
                  <div className="row">
                    <div className="col-md-11 col-xl-12">
                      <p className="meta-gray truck-meta-aligned">
                        {isTir && <span>{t('autopark.tir')}</span>}
                        {isCmr && <span>{t('autopark.cmr')}</span>}
                        {isCustom && <span>{t('cargo.doc.custom')}</span>}
                        {isDeclaration && <span>{t('under.t1.declaration')}</span>}
                        {item.cmr_insurance && <span>{t('cargo.insurance.cmr')}</span>}
                        {isSanitary && <span>{t('autopark.sanitary.certificate')}</span>}

                        {isAdr && <span>{t('autopark.licensy')}</span>}
                      </p>
                    </div>
                    <div className="col-md-5 col-xl-4">
                      <div className="clearfix">
                        <div className="has-tooltip fl-left">
                          <span className="col-blue">
                            {notes && (
                              <TooltipWrapper text={notes} hover>
                                <i className="fa fa-info-circle col-blue" />
                                {t('autopark.additional.info')}
                              </TooltipWrapper>
                            )}
                          </span>
                        </div>
                        <div className="fl-right">
                          {isGps && (
                            <span>
                              <i className="ico ico-target"></i>&nbsp;{t('autopark.is.gps')}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        {total > pageSize && (
          <Pagination
            onClick={this.paginationClick}
            total={total}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />
        )}
        <ModalConsumer>
          {modalConsumer => {
            this.modalConsumer = modalConsumer
            return null
          }}
        </ModalConsumer>
      </Fragment>
    )
  }
}
