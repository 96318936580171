import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Legislation from '../legislation'
import Geomap from './geo-map'
import DistanceCalculation from './distance-calculation'
import TrafficRestrictions from './traffic-restrictions'
import PermissibleWeightsAndSizes from './permissible-weights-and-sizes'
import PublicHolidays from './public-holidays'
import CurrencyConverter from './currency-converter'
import SchengenCalculator from './schengen-calculator'
import FuelPrices from './fuel-prices'
import Useful from './usefull'
import LegislationContent from '../legislation/content'

export default function UsefulRoutes() {
  return (
    <Switch>
      <Route path="/useful/map" exact component={Geomap} />
      <Route path="/useful/legislation" exact component={Legislation} />
      <Route path="/useful/distance-calculation" exact component={DistanceCalculation} />
      <Route path="/useful/traffic-restrictions" exact component={TrafficRestrictions} />
      <Route
        path="/useful/permissible-weights-and-sizes"
        exact
        component={PermissibleWeightsAndSizes}
      />
      <Route path="/useful/public-holidays" exact component={PublicHolidays} />
      <Route path="/useful/currency-converter" exact component={CurrencyConverter} />
      <Route path="/useful/schengen-calculator" exact component={SchengenCalculator} />
      <Route path="/useful/fuel-prices" exact component={FuelPrices} />
      <Route path="/useful/legislation/:area/:key" exact component={LegislationContent} />
      {<Route path="/useful" exact component={Useful} />}
    </Switch>
  )
}
