import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import TooltipWrapper from '../../../UI/popover-wrapper'
import fetch from '../../../../components/shared/fetch'
import { DESCRIPTION_MAX_LENGTH } from '../forum-constants'
import LoginLink from '../../../navigation/login-link'
import WidgetHeader from '../../../shared/components/widget-header'
import { canDo } from '../../../../store/utils'
import { ForumAddCommentFormProps } from './types'
import Captcha from '../../../captcha'

import './forum-add-comment-form.scss'

export default class ForumAddCommentForm extends Component<ForumAddCommentFormProps> {
  state = {
    comment: '',
    quoteMessage: '',
    showQuoteBlock: false,
    quoteCommentId: '',
    captchaVerified: false,
  }

  componentDidMount() {
    const { permissions } = this.props
    if (canDo(permissions, 'captcha', 'skip')) {
      this.setState({ captchaVerified: true })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.quoteMessage !== prevProps.quoteMessage) {
      const element = document.getElementById('addCommentBlock')
      const bodyRect = document.body.getBoundingClientRect()
      const elemRect = element.getBoundingClientRect()
      const needScrollToMyDivInPX = elemRect.top - bodyRect.top
      if (this.props.quoteMessage.length !== 0) {
        window.scrollTo({
          top: needScrollToMyDivInPX,
          behavior: 'smooth',
        })
      }
      this.setState({
        quoteMessage: this.props.quoteMessage,
        quoteCommentId: this.props.quoteCommentId,
      })
    }
  }

  renderQuoteBlock = () => {
    const { showQuoteBlock, t } = this.props
    const { quoteMessage } = this.state
    return (
      <div className="qoute-message">
        {t('forum.answer.to')} <span className="quote-message">"{quoteMessage}"</span>
        <button
          type="button"
          className="remove-quote button button-orange button-blue"
          onClick={() => showQuoteBlock(false)}
        >
          {t('forum.close')}
        </button>
      </div>
    )
  }

  renderAddCommentButton = () => {
    const { isAuthenticated, t, permissions } = this.props
    const { comment, quoteMessage, captchaVerified } = this.state
    const disabled = comment.length === 0 || comment.length > DESCRIPTION_MAX_LENGTH //|| !captchaVerified
    const errorComments = comment.length > DESCRIPTION_MAX_LENGTH ? 'error' : ''
    const errorClasses = !isAuthenticated ? 'textarea-disabled' : errorComments
    const submitAction = quoteMessage ? this.onAddQuote : this.onSubmit
    const text = <div dangerouslySetInnerHTML={{ __html: t('forum.tooltip') }} />

    const showCaptcha = !canDo(permissions, 'captcha', 'skip')

    return (
      <div>
        <div className="panel-content-row" style={{ textAlign: 'right', margin: '-6px -13px' }}>
          <label style={{ cursor: 'pointer' }} htmlFor="ch2">
            <TooltipWrapper {...{ text }} hover>
              <i className="fa fa-question-circle col-blue tooltip-trigger"></i>
            </TooltipWrapper>
            <Link to="/about-us/terms-of-use" target="_blank">
              {t('forum.rules')}
            </Link>
          </label>
        </div>
        {quoteMessage.length > 0 && this.renderQuoteBlock()}

        <span className="label fl-left">{t('forum.your.comment')}:</span>
        <span className="col-gray length-countdown fl-right">
          <span className={classNames('count', errorComments && 'error-length')}>
            {comment.length}
          </span>
          /<span className="length">{DESCRIPTION_MAX_LENGTH}</span>
        </span>
        <textarea
          placeholder={`${t('forum.no.more')} ${DESCRIPTION_MAX_LENGTH} ${t('forum.characters')}`}
          className={classNames(errorClasses, showCaptcha && "no-mb")}
          disabled={!isAuthenticated}
          value={comment}
          onChange={e => this.setState({ comment: e.target.value })}
        />
{/* 
        {showCaptcha && (
          <Captcha
            verifyCallback={() => {
              this.setState({ captchaVerified: true });
            }}
            expiredCallback={() => this.setState({ captchaVerified: false })}
          />
        )} */}

        <button
          type="button"
          className="button button-orange"
          disabled={disabled}
          onClick={submitAction}
        >
          {t('forum.add')}
        </button>
      </div>
    )
  }

  renderNotAuthenticated = () => {
    const { t } = this.props
    return (
      <div className="panel-search-wrapper centered-container">
        <div className="panel-search-log centered-item">
          <span className="fa fa-clock-o" />
          <p className="panel-search-text">{t('forum.only.auth')}</p>
          <div className="panel-search-links">
            <LoginLink> {t('login.enter.2')} </LoginLink>
            {t('or')}
            <Link to="/auth/registration"> {t('login.register')} </Link>
          </div>
          <br />
          {t('login.family')}
        </div>
      </div>
    )
  }

  renderNotPermissed = () => {
    const { t } = this.props
    return (
      <div className="panel-search-wrapper centered-container">
        <div className="panel-search-log centered-item">
          <span className="fa fa-clock-o" />
          <p className="panel-search-text">{t('not.enough.rights')}</p>
        </div>
      </div>
    )
  }

  onSubmit = () => {
    const { comment } = this.state
    const { currentTheme, refreshLastComments, showQuoteBlock } = this.props
    const body = JSON.stringify({
      themeId: currentTheme,
      comment: comment,
    })
    this.requestAfterAddComment(body, refreshLastComments, showQuoteBlock)
  }

  onAddQuote = () => {
    const { comment, quoteCommentId } = this.state
    const { currentTheme, refreshLastComments, showQuoteBlock } = this.props
    const body = JSON.stringify({
      themeId: currentTheme,
      comment: comment,
      answerFor: quoteCommentId,
    })
    this.requestAfterAddComment(body, refreshLastComments, showQuoteBlock)
  }

  requestAfterAddComment = (body, refreshLastComments, showQuoteBlock) => {
    fetch('/forum/addNewComment', {
      method: 'POST',
      body,
    }).then(() => {
      this.setState({ comment: '' })
      showQuoteBlock(false)
      refreshLastComments()
    })
  }

  renderFormWithValidation = () => {
    const { isAuthenticated, permissions } = this.props
    const canAddTheme = canDo(permissions, 'forum', 'addComments')

    if (!isAuthenticated) return this.renderNotAuthenticated()
    if (!canAddTheme) return this.renderNotPermissed()
    return this.renderAddCommentButton()
  }

  render() {
    const { t } = this.props
    return (
      <div className="panel no-print" id="addCommentBlock">
        <WidgetHeader title={t('forum.add.comment')} className="panel-header" />
        <div className="panel-body">
          <div className="panel-content">
            <div className="panel-comments">
              <div className="panel-content-row">
                <div className="panel-comment panel-comment-add">
                  <form className="panel-form-row clearfix">{this.renderFormWithValidation()}</form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
