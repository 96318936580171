import React, { Component } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'

import WidgetHeader from '../../../shared/components/widget-header'
import TooltipWrapper from '../../../UI/popover-wrapper'
import { loadCars } from '../../../../store/actions/transport'
import CarsContainer from '../cars-container'

import './styles.scss'
import 'react-confirm-alert/src/react-confirm-alert.css'

const STYLE_BASE = 'auto-park-list_'

interface IAutoparkListState {
  confirmItemId: number
  bodyTypes: any[]
  pageSize: number
  pageIndex: number
  isCompanyCars: number
  activeTab: string
}

interface IAutoparkListProps extends ComponentBaseProps, PermissionInjectProps {
  pageSize: number
  searchText: string
  isCompanyAdmin: boolean
  list: any[]
  companyId: number
  loadCars: typeof loadCars
  activeTab: string
  autoparkSetActiveTab: any
  onCanAdd: any
  canAdd: boolean
}

export default class extends Component<IAutoparkListProps, IAutoparkListState> {
  constructor(props) {
    super(props)

    this.state = {
      confirmItemId: undefined,
      bodyTypes: [],
      pageSize: 10,
      pageIndex: 0,
      isCompanyCars: 0,
      activeTab: 'myPark',
    }
  }

  componentDidMount() {
    const { activeTab, onCanAdd } = this.props
    this.refresh({ pageIndex: 0, activeTab })
    const onCannAddValue = activeTab === "myPark" ? "autopark" : "company-autopark"
    onCanAdd(onCannAddValue)
  }

  componentDidUpdate(prevProps) {
    const { activeTab, onCanAdd } = this.props
    if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
      this.refresh({ pageIndex: 0 })
    }
    const onCannAddValue = activeTab === "myPark" ? "autopark" : "company-autopark"
    if (activeTab !== prevProps.activeTab) {
      this.setState({ activeTab }, () => {
        onCanAdd(onCannAddValue)
        this.refresh({ pageIndex: 0 })
      })
    }
  }

  refresh = (params?: any) => {
    const { pageSize, searchText, loadCars, userId, activeTab } = this.props
    const { pageIndex } = this.state
    const profileUserId = userId
    const isCompanyCars = activeTab === "myPark" ? 0 : 1
    loadCars({ pageIndex, pageSize, searchText, isCompanyCars, profileUserId, ...params })
  }

  setActiveTab = activeTab => {
    const { autoparkSetActiveTab } = this.props
    const isCompanyCars = activeTab === 'companyPark' ? 1 : 0
    autoparkSetActiveTab(activeTab)
    this.setState({ isCompanyCars, pageIndex: 0 }, () => {
      this.refresh()
    })
  }

  renderCompanyPark = (list) => {
    const { isCompanyAdmin, userId, pageSize, onCanAdd } = this.props
    const { items, total } = list
    return (
      <CarsContainer
        carList={items}
        canEdit={isCompanyAdmin}
        refresh={this.refresh}
        imageFolder={`auto-park/${userId}`}
        {...{ total }}
        {...{ pageSize }}
        {...{ onCanAdd }}
      />
    )
  }

  renderMyPark = (list) => {
    const { userId, pageSize, onCanAdd } = this.props
    const { items, total } = list
    const carList = items.filter(oneCar => oneCar.isCarCompany !== 1)
    return (
      <CarsContainer
        canEdit={true}
        refresh={this.refresh}
        imageFolder={`auto-park/${userId}`}
        {...{ onCanAdd }}
        {...{ carList }}
        {...{ pageSize }}
        {...{ total }}
      />
    )
  }

  showBtnForCompanyUser = (activeTab, addCarButtonLabel) => {
    const { t } = this.props
    if (activeTab === 'companyPark') {
      return (
        <TooltipWrapper text={t('autopark.rights')} hover>
          <Link to="#">
            <span className="button button-orange">
              <i className="fa fa-plus" />
            </span>
            {addCarButtonLabel}
          </Link>
        </TooltipWrapper>
      )
    } else {
      return (
        <Link to="/transport/autopark/add">
          <span className="button button-orange">
            <i className="fa fa-plus" />
          </span>
          {addCarButtonLabel}
        </Link>
      )
    }
  }

  render() {
    const { t, list, companyId, isCompanyAdmin, activeTab, canAdd } = this.props
    const addCarButtonLabel =
      activeTab === 'companyPark'
        ? t('auto-park.add-company-car')
        : t('auto-park.add-car-in-my-list')
    return (
      <div className="panel autopark-car-list">
        <WidgetHeader title={t('auto-park.header')} className={classNames(`${STYLE_BASE}header`)}>
          <div className="link-right">
            {isCompanyAdmin ? (
              !canAdd ? (
                <>
                  <TooltipWrapper text={t('autopark.limit')} hover>
                    <i className="fa fa-question-circle m-l-5" />
                  </TooltipWrapper>
                  {addCarButtonLabel}
                </>
              ) : (
                <>
                  <Link to="/transport/autopark/add">
                    <span className="button button-orange">
                      <i className="fa fa-plus" />
                    </span>
                    {addCarButtonLabel}
                  </Link>
                </>
              )
            ) : (
              canAdd ? this.showBtnForCompanyUser(activeTab, addCarButtonLabel) : (
                <>
                  <TooltipWrapper text={t('autopark.limit')} hover>
                    <i className="fa fa-question-circle m-l-5" />
                  </TooltipWrapper>
                  {addCarButtonLabel}
                </>
              )
            )}
          </div>
        </WidgetHeader>
        <div className="panel-filter">
          <div className="filter-wrapper filter-nav clearfix tabs-list priority-nav">
            <ul>
              {companyId && (
                <li
                  className={classNames({
                    active: activeTab === 'companyPark',
                    'tab-filter': true,
                  })}
                >
                  <Link
                    to="#"
                    title={t('statistics.my.company.autopark')}
                    onClick={() => this.setActiveTab('companyPark')}
                  >
                    {t('statistics.my.company.autopark')}
                  </Link>
                </li>
              )}
              <li
                className={classNames({
                  active: activeTab === 'myPark',
                  'tab-filter': true,
                })}
              >
                <Link
                  to="#"
                  title={t('statistics.my.autopark')}
                  onClick={() => this.setActiveTab('myPark')}
                >
                  {t('statistics.my.autopark')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {activeTab === 'companyPark' ? this.renderCompanyPark(list) : this.renderMyPark(list)}
      </div>
    )
  }
}
