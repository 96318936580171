import React, { Component } from 'react'
import './alert-banner.scss'

interface AlertBannerProps extends ComponentBaseProps {
  text: string
}

export default class AlertBanner extends Component<AlertBannerProps> {
  render() {
    const { text } = this.props

    return (
      <div className="col-sm col-lg-16 alert-banner">
        <div data-move="panel-warning" className="panel panel-warning">
          <div className="panel-body">
            <h4>
              <i className="fa fa-exclamation-triangle"></i>
              {text}
            </h4>
          </div>
        </div>
      </div>
    )
  }
}
