export const int1 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Regimul TIR</h4><p></p>

<p style="font-weight:500;">1. Generalități</p><br>

<p style="text-indent:2em;">Carnetul TIR este un document de tranzit vamal care facilitează comerţul internaţional şi transportul rutier internaţional, garantând plata taxelor vamale pentru mărfurile
 transportate sub regim TIR, în condițiile prevăzute în Convenţia TIR.</p><br>

<p>Carnetul TIR poate fi deţinut de orice societate care a fost admisă la Regimul vamal TIR conform Procedura admitere.</p><br>

<p>Fiecare carnet TIR are un număr de referință unic și poate avea 4, 6, 14 sau 20 voleți.</p><br>

<p style="text-indent:2em;">Data de valabilitate a unui carnet TIR este stabilită și înscrisă de către asociație în rubrica 1 de pe copertă, fiind ultima dată la care acesta poate fi prezentat la vama de plecare.</p><br>

<p>Odată ce transportul TIR a fost încheiat, carnetul TIR se va returna la asociație conform procedurilor în vigoare.</p><br>

<p style="text-indent:2em;">Un carnet TIR poate fi utilizat numai pentru un singur transport TIR, fiind necesari câte doi voleți pentru fiecare țară non-UE tranzitată.</p>

<p style="text-indent:2em;"></p><h6>De ex.: Pentru un transport din România în Turcia, având o singură încărcare și o singură descărcare, sunt necesari 4 voleți, astfel:</h6><p></p>

<p>un volet pentru vama de plecare din România</p><br>
<p>un volet pentru vama de ieșire din UE</p><br>
<p>un volet pentru vama de intrare în Turcia</p><br>
<p>un volet pentru vama de descărcare din Turcia</p><br>

<p style="text-indent:2em;">Transporturile efectuate sub acoperirea unui carnet TIR pot comporta mai multe birouri vamale de plecare şi de destinaţie, dar numărul total de birouri vamale de
 plecare şi de destinaţie nu va putea fi mai mare de 4.</p><br>

<p style="font-weight:500;">2. Completarea carnetului TIR</p><br>

<p style="text-indent:2em;">Carnetul TIR nu va comporta nici ștersături, nici scriere suprapusă. Orice rectificare va trebui efectuată bifând indicațiile eronate și adăugându-le pe cele
 corecte și certificată de către autoritățile vamale.</p><br>

<p style="text-indent:2em;">Broșura atașată (format PDF) Completarea carnetului TIR conține instrucțiuni utile pentru Titularii de carnete referitoare la modalitatea în care trebuiesc înscrise datele în Carnetele TIR.</p><br>

<p>Un model de Carnet TIR este prezentat în Anexa 1 la Convenția TIR.</p><br>

<p style="font-weight:500;">3. Mărfuri grele sau voluminoase</p><br>

<p style="text-indent:2em;">Conform Convenției TIR, prin Mărfuri grele sau voluminoase se înțeleg orice produse grele sau voluminoase care, datorită greutăţii, dimensiunilor sau naturii
 lor, nu pot fi transportate într-un vehicul rutier închis sau într-un container închis.</p><br>

<p>Pentru vehiculele care transportă mărfuri grele sau voluminoase nu se solicită certificat de agreere.</p><br>

<p style="text-indent:2em;">În cazul transportului de mărfuri grele sau voluminoase, titularul solicită asociației emiterea de carnete TIR denumite uneori, impropriu, OPEN TIR. Acestea
 sunt carnete TIR obișnuite, având înscrisă pe copertă și pe toți voleții, mențiunea "Marchandises Pondéreuses ou Volumineuses" în limba franceză sau "Heavy
 or Bulky Goods" în engleză („Mărfuri grele sau voluminoase").</p><br>

<p style="text-indent:2em;">Atunci când un Carnet TIR este emis pentru a acoperi mărfuri grele sau voluminoase (fără sigiliu) cu ștampila respectivă, el nu poate fi utilizat pentru transportul mărfurilor obișnuite.</p><br>

<p style="font-weight:500;">4. Mărfuri interzise</p>

<p style="text-indent:2em;"></p><h6>Transportul anumitor mărfuri este complet interzis sub acoperirea carnetului TIR :</h6><p></p>

<p>alcool și produse derivate, cu excepția berii sau a vinului (codurile HS 22.07..10 și 22.08)</p><br>

<p>tutun și produse derivate, cu excepția tutunului brut (codurile HS 24.02.10, 24.02.20, 24.03.11 și 24.03.19)</p><br>

<p style="font-weight:500;">5. Incidente sau accidente</p><br>

<p style="text-indent:2em;">Dacă în timpul transportului se întâmplă ca, dintr-o cauză fortuită, un sigiliu vamal să fie rupt sau ca mărfurile să fie deteriorate, transportatorul se va adresa
 imediat autorităților vamale cele mai apropiate sau, dacă acest lucru nu este posibil, altor autorități competente ale țării în care se află. Acestea vor întocmi
 în cel mai scurt timp procesul-verbal de constatare din carnetul TIR.</p><br>

<p style="text-indent:2em;">În caz de accident necesitând transbordarea pe un alt vehicul, această transbordare nu se poate efectua decât în prezența autorităților competente. Acestea vor întocmi
 procesul-verbal de constatare din carnetul TIR.</p><br>

<p style="font-weight:500;">6. În cazul pierderii/reținerii/furtului/distrugerii Carnetului TIR</p><br>

<p style="text-indent:2em;">În cazul pierderii/furtului/distrugerii sau reținerii unui Carnet TIR de către o autoritate oficială (vamă, poliție), Titularul carnetului TIR respectiv trebuie să informeze
 imediat asociația prin completarea declaraţiei-tip („Declaraţie de întocmit de către Titular"), formular atașat ce poate fi descărcat.</p><br>

<p style="text-indent:2em;">Formularul corect completat se remite asociației însoțit de actele oficiale de constatare şi documentele aferente transportului efectuat sub acoperirea carnetului TIR în cauză,
 precum și o scurtă descriere a circumstanțelor.</p><br>
 
 <p><a href="http://www.artri.net/carnetul-tir_160.html">Sursa</a></p>



</div>
`