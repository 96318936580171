import withTranslation from '../../../globals/components/withTranslation'
import Component from './component'
import { SearchTableProps } from './types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { IReduxStorage } from '../../../store/reducers/types'
import {
  getUnblockedItem,
  changeActiveTransport,
  changeActiveCargo,
  getUserStatistics,
} from '../../../store/actions'
import { onCanAdd } from '../../../store/actions/profile'
import { setScrollToOncommingItems } from '../../../store/actions/profile'

export default compose(
  withTranslation,
  connect(
    (state: IReduxStorage) => ({
      langId: state.ui.langId,
      languages: state.ui.languages,
      canAdd: state.profile.canAdd,
      isAuth: !!state.auth.accessToken,
    }),
    (dispatch: any, props: SearchTableProps) => {
      const { searchType } = props
      const isCargo = searchType === 'cargo'
      return {
        getUnblockedItem: itemId => dispatch(getUnblockedItem(itemId, searchType)),
        changeActiveItem: itemId =>
          dispatch(
            isCargo
              ? changeActiveCargo({ cargoId: itemId })
              : changeActiveTransport({ transportId: itemId })
          ),
        getUserStatistics: type => dispatch(getUserStatistics(type)),
        setScrollToOncommingItems: (bool) => dispatch(setScrollToOncommingItems(bool)),
        onCanAdd: (type) => dispatch(onCanAdd(type))
      }
    }
  )
)(Component) as React.ComponentClass<SearchTableProps>
