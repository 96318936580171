import {
  ACCESS_GET_LIST,
  ACCESS_GET_USER_NAME,
  ACCESS_ADD_POINT,
  ACCESS_DELETE_POINT,
  ACCESS_CHANGE_GROUP,
} from './action-types'

import fetch from '../../components/shared/fetch'

export const getAccessList = userId => {
  return async dispatch => {
    fetch('/user/getUserAccess', {
      method: 'POST',
      body: JSON.stringify({ userId }),
    }).then(json => dispatch({ type: ACCESS_GET_LIST, payload: json.d }))
  }
}

export const addAccessPoint = payload => {
  const { userId, idAccess } = payload
  return dispatch => {
    fetch('/user/setAccessPoint', {
      method: 'POST',
      body: JSON.stringify({
        userId,
        idAccess,
        dateEnd: '3000-01-01 00:00:0',
      }),
    }).then(() => {
      dispatch({ type: ACCESS_ADD_POINT, payload })
    })
  }
}

export const deleteAccessPoint = payload => {
  const { userId, idAccess } = payload
  return dispatch => {
    fetch('/user/removeUserAccessPoint', {
      method: 'POST',
      body: JSON.stringify({
        userId,
        idAccess,
      }),
    }).then(() => {
      dispatch({ type: ACCESS_DELETE_POINT, payload })
    })
  }
}

export const setAccessGroup = payload => {
  const { userId, idAccess: groupId, dateBeginStruct, dateEndStruct } = payload
  return dispatch => {
    fetch('/user/setAccessGroup', {
      method: 'POST',
      body: JSON.stringify({
        userId,
        groupId,
        dateBeginStruct,
        dateEndStruct,        
      }),
    }).then(() => {
      getAccessList(userId)(dispatch)
      dispatch({ type: ACCESS_CHANGE_GROUP, payload: { userId, groupId } })
    })
  }
}

export const getUserFullName = userId => {
  return dispath => {
    fetch('/user/getUserName', {
      method: 'POST',
      body: JSON.stringify({ userId }),
    }).then(json => {
      dispath({ type: ACCESS_GET_USER_NAME, payload: json.d })
    })
  }
}
