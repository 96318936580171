import React, { Component } from 'react'
import Widget from '../../shared/components/widget'
import Ads from '../../ads'
import SiteStat from '../../site-stat'
import BuySellShort from '../../buy-sell/short'
import RoAutopark from "./ro-autopark"

import './styles.scss'

export default class extends Component {
  render() {
    const { t, langId } = this.props

    const otherLangs = () => {
      return (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: t('company.autopark'),
            }}
          />
        </>
      )
    }
    const content = langId === 3 ? <RoAutopark /> : otherLangs()

    return (
      <div className="row">
        <div className="col-md-12">
          <Widget title={t('about.autopark')}>{content}</Widget>
        </div>
        <div className="col-md-4">
          <SiteStat />
          <BuySellShort />
          <Ads />
        </div>
      </div>
    )
  }
}
