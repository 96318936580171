export const eu10 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Dimensiunea internaţională şi extinderea</h4><p></p>

<p></p><h4>Cooperarea cu ţările terţe</h4><p></p>

<p></p><h4>Acordurile de reglementare a serviciilor aeriene dintre țările UE și țările din afara UE</h4><p></p>

<p style="font-weight:500;">CARE ESTE OBIECTIVUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul stabilește o procedură pentru notificarea și autorizarea negocierilor purtate de țările Uniunii Europene (UE) cu țări din afara UE cu privire la acordurile bilaterale de
reglementare a serviciilor aeriene (ASA).</p><br>

<p>Obiectivul regulamentului este de a asigura că acordurile existente respectă legislația UE.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Competența UE</p><br>

<p style="text-indent:2em;">În noiembrie 2002, Curtea de Justiție a Uniunii Europene a confirmat dreptul exclusiv al UE de a negocia, semna și încheia ASA internaționale cu țările din afara UE când acordurile
se referă la chestiuni care sunt de competența exclusivă a UE.</p><br>

<p style="text-indent:2em;">La data adoptării hotărârii Curții, exista un număr mare de ASA care nu respectau legislația UE. De atunci, un număr mare dintre acestea au fost aduse în conformitate cu legislația UE.</p><br>

<p style="font-weight:500;">Obiectiv</p>

<p style="text-indent:2em;"></p><h6>Acele ASA care nu respectă legislația UE trebuie să fie modificate pentru:</h6><p></p>

<p>a se asigura securitatea juridică în astfel de acorduri pentru ambele părți;</p><br>

<p style="text-indent:2em;">a se garanta tuturor companiilor aeriene din UE dreptul de stabilire în cadrul UE, inclusiv accesul nediscriminatoriu pe piață la rutele dintre toate țările UE și țările din afara UE
în care existau ASA.</p><br>

<p style="font-weight:500;">Modificarea unui ASA</p>

<p style="text-indent:2em;"></p><h6>Există două moduri de modificare a unui ASA:</h6><p></p>

<p style="text-indent:2em;">Acordurile orizontale – care acționează în numele țărilor UE în cauză care au ASA bilaterale cu o țară din afara UE, Comisia Europeană negociază un acord unic cu acea țară din afara UE.</p><br>

<p>Negocierile bilaterale – care modifică sau înlocuiesc fiecare ASA individual.</p><br>

<p style="font-weight:500;">Norme și procedură pentru negocieri bilaterale</p>

<p style="text-indent:2em;"></p><h6>O țară a UE poate iniția negocieri bilaterale pentru a modifica sau înlocui un ASA care este parțial de competența UE dacă respectă normele și procedura stabilite în regulament:</h6><p></p>

<p style="text-indent:2em;">negocierile să includă clauzele standard relevante, concepute în comun de țările UE și Comisie și să se respecte procedura de notificare;</p><br>

<p>țara notifică în scris intențiile sale Comisiei.</p><br>

<p style="text-indent:2em;">Dacă, în termen de 15 zile lucrătoare de la primirea notificării, Comisia stabilește că negocierile ar putea submina obiectivele negocierilor UE care se desfășoară deja cu țara din afara UE și/sau ar 
putea conduce la un acord incompatibil cu dreptul UE, Comisia trebuie să informeze țara UE în consecință.</p><br>

<p style="text-indent:2em;">O țară a UE nu poate încheia cu o țară din afara UE niciun acord nou care reduce numărul operatorilor de transport aerian din UE autorizați să furnizeze servicii între teritoriul său și țara în cauză.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Regulamentul a intrat în vigoare la 30 mai 2004.</p><br>

<p style="font-weight:500;">Protocolul privind transporturile conform Convenției alpine</p><br>

<p style="text-indent:2em;">Convenția alpină este un acord-cadru în vederea protecției și dezvoltării durabile a regiunii alpine și vizează conservarea și protejarea masivului (din perspectivă de mediu, economică și socială), 
prin aplicarea principiilor prevenirii, poluatorul plătește și al cooperării între statele membre. Textul regrupează nouă protocoale, fiecare acoperind un sector diferit. Prezenta decizie vizează 
aplicarea Protocolului privind aplicarea Convenției alpine în domeniul transporturilor de către Uniunea Europeană.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Decizia 2007/799/CE a Consiliului din 12 octombrie 2006 privind semnarea, în numele Comunității Europene, a Protocolului privind aplicarea Convenției alpine în domeniul transporturilor 
(Protocolul privind transporturile).</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Prin Decizia 96/191/CE a Consiliului, Uniunea Europeană (UE) a încheiat Convenția privind protecția Alpilor. Aceasta vizează salvgardarea ecosistemului natural și promovarea dezvoltării durabile a 
Alpilor, promovând totodată interesele economice și culturale ale populațiilor. Convenția alpină dispune de un comitet permanent, alcătuit din reprezentanți ai părților contractante.</p><br>

<p style="text-indent:2em;">Semnarea acestui protocol de către UE are, de asemenea, scopul de a transmite un semnal politic tuturor părților implicate, invitând părțile contractante să îl semneze și să îl ratifice.</p><br>

<p style="font-weight:500;">Obiective</p>

<p style="text-indent:2em;"></p><h6>Obiectivele protocolului sunt următoarele:</h6><p></p>

<p>reducerea riscurilor și a pericolelor proprii transportului intraalpin;</p><br>

<p style="text-indent:2em;">contribuirea la dezvoltarea habitatelor și a spațiilor economice grație unei politici a transporturilor armonizată între diferitele țări și care integrează diferitele moduri de transport (rutier, feroviar etc.);</p><br>

<p style="text-indent:2em;">contribuirea la reducerea pericolului asupra biodiversității spațiului alpin, precum și asupra patrimoniului natural și cultural al acestuia;</p><br>

<p style="text-indent:2em;">asigurarea circulației la costuri suportabile din punct de vedere economic, printr-o creștere a eficacității și a durabilității sistemelor de transport.</p>

<p style="text-indent:2em;"></p><h6>Părțile contractante ale Convenției se angajează, astfel, să ia în considerare, în administrarea teritoriului alpin, riscurile și pericolele asociate circulației, cum ar fi poluarea (fonică sau chimică)
și siguranța persoanelor și a bunurilor. În paralel, semnatarii trebuie:</h6><p></p>

<p>să sporească rentabilitatea sectorului transporturilor;</p><br>

<p>să optimizeze utilizarea infrastructurilor existente;</p><br>

<p>să ia în considerare problematica transporturilor în evaluarea și în punerea în aplicare a altor politici;</p><br>

<p>să implice colectivitățile teritoriale în luarea deciziei.</p><br>

<p style="font-weight:500;">Măsuri și strategii specifice</p>

<p style="text-indent:2em;"></p><h6>Convenția alpină definește, de asemenea, un anumit număr de măsuri și strategii specifice. Toate vizează promovarea unei administrări raționale și sigure a transporturilor, grație 
principiilor următoare:</h6><p></p>

<p>o bună coordonare a diferitelor moduri și mijloace de transport;
utilizarea intermodalității;</p><br>

<p>transferul traficului către modurile de transport mai nepoluante;</p><br>

<p>protecția căilor de comunicație împotriva riscurilor naturale;</p><br>

<p>protecția persoanelor și a mediului înconjurător;</p><br>

<p>reducerea progresivă a emisiilor de substanțe nocive și a emisiilor fonice;</p><br>

<p>crearea și dezvoltarea unor transporturi publice ușor de utilizat și adaptate mediului înconjurător;</p><br>

<p>utilizarea studiilor de impact pentru proiectele planificate și consultarea părților interesate.</p>

<p style="text-indent:2em;"></p><h6>Convenția definește, în plus, principii particulare pentru diferitele moduri de transport:</h6><p></p>

<p style="text-indent:2em;">o consolidare a transportului feroviar prin îmbunătățirea infrastructurilor, optimizarea exploatării acestora, precum și utilizarea intermodalității pentru transportul de mărfuri;</p><br>

<p>o utilizare sporită a capacităților de navigație fluvială și maritimă;</p><br>

<p>în domeniul transportului rutier, semnatarii trebuie să evite construcția de noi drumuri de mare capacitate.</p> 

<p style="text-indent:2em;"></p><h6>Proiectele pot fi totuși realizate în condițiile minimalizării impactului asupra mediului înconjurător:</h6><p></p>

<p style="text-indent:2em;">efectele dăunătoare ale transportului aerian trebuie să fie reduse în măsura posibilului. Activitățile aeriene recreative nemotorizate trebuie, de asemenea, să fie limitate.</p><br>

<p style="font-weight:500;">Principiul poluatorul plătește</p><br>

<p style="text-indent:2em;">Textul invită părțile contractante în egală măsură să aplice principiul poluatorul plătește, precum și să stabilească un sistem de calcul care să permită integrarea costurilor de mediu în 
evaluarea costului global al infrastructurilor.</p><br>

<p style="text-indent:2em;">Semnatarii trebuie să raporteze în mod regulat Comitetului permanent cu privire la măsurile luate în temeiul protocolului. Comitetul întocmește un raport de constatare a respectării protocolului.</p><br>

<p><a href="https://eur-lex.europa.eu/summary/chapter/transport/3210.html?root=3210">Sursa</a></p>



</div>
`