import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'
import { compose } from 'recompose'
// import { injectIntl } from 'react-intl'

import Component from './component'

import withTranslation from '../../../globals/components/withTranslation'
import { getItem, deleteNews, showEditModal, hideEditModal } from '../../../store/actions/news'

export default compose(
  withTranslation,
  connect(
    state => {
      const totalComments = state.news.comments.total
      const { permissions = [] } = state.auth.jwt || {}
      const { item } = state.news
      return {
        isAuthenticated: !!state.auth.accessToken,
        item,
        // mode: item.mode,
        totalComments,
        permissions,
      }
    },
    {
      getItem,
      showEditModal,
      hideEditModal,
      deleteNews,
    }
  )
)(Component)
