import { connect } from 'react-redux'
import { compose } from 'recompose'

import withTranslation from '../../../../globals/components/withTranslation'
import { formValueSelector } from 'redux-form'

import Component from './component'

export default compose(
  withTranslation,
  connect((state, props) => {
    const { formName, fieldNameM3, fieldNameLdm, fieldNamePal } = props
    const selector = formValueSelector(formName)

    return {
      m3Value: selector(state, fieldNameM3),
      ldmValue: selector(state, fieldNameLdm),
      palValue: selector(state, fieldNamePal),
    }
  })
)(Component)
