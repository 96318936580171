import React, { Component } from 'react'
import fetch from '../../../../components/shared/fetch'
import { TITLE_MAX_LENGTH, DESCRIPTION_MAX_LENGTH, TITLE_MIN_LENGTH } from '../forum-constants'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Field } from 'redux-form'
import Dropdown from '../../../UI/dropdown'
import LoginLink from '../../../navigation/login-link'
import { canDo } from '../../../../store/utils'
import TooltipWrapper from '../../../UI/popover-wrapper'
import Captcha from '../../../captcha'

import './forum-add-theme-modal.scss'

interface IAddThemeModalProps extends ComponentBaseProps, PermissionInjectProps {
  closeModal: any
  showModal?: boolean
  onCanAdd?: any
}

export default class ForumAddThemeModal extends Component<IAddThemeModalProps> {
  state = {
    sections: [],
    postTitle: '',
    postDescription: '',
    sectionId: 0,
    error: false,
    captchaVerified: false,
  }

  componentDidMount() {
    const { permissions } = this.props
    this.fetchForumSections()
    if (canDo(permissions, 'captcha', 'skip')) {
      this.setState({ captchaVerified: true })
    }
  }

  fetchForumSections = () => {
    fetch(`/forum/getAllSections`).then(sections => {
      this.setState({ sections: sections.data })
    })
  }

  renderSelectOptions = () => {
    const { sections } = this.state
    const newArr = []
    sections.forEach(({ id, title, showTheme }) => {
      if (showTheme !== 0) {
        newArr.push({ id, title })
      }
    })

    const renderOptions = [
      ...newArr.map(({ id, title }) => ({
        value: id,
        name: title,
      })),
    ]

    return renderOptions
  }

  setStateFunc = (stateName, e) => {
    this.setState({ [stateName]: e.target.value })
  }

  onSubmit = () => {
    const { closeModal } = this.props
    const { postTitle, postDescription, sectionId } = this.state
    const body = JSON.stringify({
      title: postTitle,
      message: postDescription,
      sectionId,
    })

    this.setState({ postTitle: '', postDescription: '' })
    fetch('/forum/addTheme', {
      method: 'POST',
      body,
    }).then(() => {
      closeModal(true, sectionId)
    })
  }

  moveInToStateThisValue = sectionId => {
    this.setState({ sectionId })
  }

  renderNotAuthForm = () => {
    const { t } = this.props
    return (
      <div className="panel-search-wrapper centered-container">
        <div className="panel-search-log centered-item">
          <span className="fa fa-clock-o" />
          <p className="panel-search-text">{t('forum.add.themes.only.auth')}</p>
          <div className="panel-search-links">
            <LoginLink> {t('login.enter.2')} </LoginLink>
            {t('or')}
            <Link to="/auth/registration"> {t('login.register')} </Link>
          </div>
          <br />
          {t('login.family')}
        </div>
      </div>
    )
  }

  renderNotPermisForm = () => {
    const { t } = this.props
    return (
      <div className="panel-search-wrapper centered-container">
        <div className="panel-search-log centered-item">
          <span className="fa fa-clock-o" />
          <p className="panel-search-text">{t('not.enough.rights')}</p>
        </div>
      </div>
    )
  }

  renderAddThemeForm = () => {
    const { t, permissions } = this.props
    const { postTitle, postDescription, sectionId, captchaVerified } = this.state

    const error =
      postTitle.length > TITLE_MAX_LENGTH || postDescription.length > DESCRIPTION_MAX_LENGTH
    const errorPostTitle = postTitle.length > TITLE_MAX_LENGTH ? 'error' : ''
    const errorPostDescription = postDescription.length > DESCRIPTION_MAX_LENGTH ? 'error' : ''
    const disabled = postTitle && postDescription && sectionId !== 0 && !error && postTitle.length >= TITLE_MIN_LENGTH && captchaVerified

    const showCaptcha = !canDo(permissions, 'captcha', 'skip')

    return (
      <form className="panel add-theme-modal">
        <div className="panel-body">
          <div className="panel-content-row">
            <div className="col-md-4">
              <h5 className="title-gray">{t('forum.new.theme')}</h5>
            </div>
            <div className="col-md-12 col-lg-17">
              <div className="panel-form-row row sections-list">
                <span className="label fl-left">{t('forum.section')}</span>
                <Field
                  onChange={this.moveInToStateThisValue}
                  className="add-theme-select"
                  name="add-theme"
                  component={Dropdown}
                  emptyOption={''}
                  items={this.renderSelectOptions()}
                  placeholder={'Выберите раздел'}
                />
              </div>
              <div className="panel-form-row row">
                <span className="label fl-left modal-title">{t('forum.title')}</span>
                <span className="col-gray length-countdown fl-right">
                  <span className={classNames('count', errorPostTitle && 'error-length')}>
                    {postTitle.length}
                  </span>
                  /<span className="length">{TITLE_MAX_LENGTH}</span>
                </span>
                <input
                  type="text"
                  value={postTitle}
                  className={errorPostTitle}
                  placeholder={t("add.theme.placeholder")}
                  onChange={e => this.setStateFunc('postTitle', e)}
                />
              </div>

              <div className="panel-form-row row">
                <span className="label fl-left">{t('forum.your.message')}</span>
                <span className="col-gray length-countdown fl-right">
                  <span className={classNames('count', errorPostDescription && 'error-length')}>
                    {postDescription.length}
                  </span>
                  /<span className="length">{DESCRIPTION_MAX_LENGTH}</span>
                </span>
                <textarea
                  value={postDescription}
                  placeholder={`${t('forum.no.more')} ${DESCRIPTION_MAX_LENGTH} ${t(
                    'forum.characters'
                  )}`}
                  className={errorPostDescription}
                  onChange={e => this.setStateFunc('postDescription', e)}
                />
              </div>

              <div className="panel-form-row panel-form-row-lg row">
                {showCaptcha && (
                  <Captcha
                    verifyCallback={() => {
                      this.setState({ captchaVerified: true });
                    }}
                    expiredCallback={() => this.setState({ captchaVerified: false })}
                  />
                )}
                <div className="col-sm-4 col-lg-30 remove-padding">
                  <button
                    type="button"
                    onClick={this.onSubmit}
                    disabled={!disabled}
                    className="button button-orange button-block"
                  >
                    {t('forum.create')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }

  renderFormWithValidation = () => {
    const { isAuthenticated, permissions } = this.props
    const canAddTheme = canDo(permissions, 'forum', 'addTopic')

    if (!isAuthenticated) return this.renderNotAuthForm()
    if (!canAddTheme) return this.renderNotPermisForm()
    return this.renderAddThemeForm()
  }

  render() {
    const { closeModal, showModal, t } = this.props
    const { sections } = this.state
    const showPopup = showModal ? 'show' : null

    const text = <div dangerouslySetInnerHTML={{ __html: t('forum.tooltip') }} />
    return (
      <div data-popup="TWE" className={`popup-overlay ${showPopup}`}>
        <div className="overlay-to-close" onClick={closeModal} />
        <div className="popup popup-truck">
          <div className="fa fa-times icon-close" onClick={closeModal}></div>
          <div className="popup-body">
            <div className="truck-item">
              {sections.length > 0 ? this.renderFormWithValidation() :
                <div className="empty-themes-modal">
                  {t('empty.sections')}
                </div>
              }
              <div className="truck-item-bottom">
                <div className="row">
                  <div className="fl-right">
                    <span>
                      <div className="panel-content-row">
                        <TooltipWrapper {...{ text }} hover>
                          <i className="fa fa-question-circle col-blue tooltip-trigger"></i>
                        </TooltipWrapper>

                        <Link to="/about-us/terms-of-use" target="_blank">
                          {t('forum.rules')}
                        </Link>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
