import _ from 'lodash'
import React, { Component, Fragment } from 'react'
//import { Link, Redirect } from "react-router-dom"
import { Link } from 'react-router-dom'
import { Field } from 'redux-form'
import classNames from 'classnames'

import ModalForm from '../../modals/modal-form'
import { changeWidgetFilter } from '../../shared/utility'
import WidgetFilter from '../../shared/components/widget-filter'
import WidgetBase from '../../shared/components/widget-base'
import WidgetSettings from '../../shared/components/widget-settings'
import FilterFieldRender from '../../UI/filter-field'

// import dict from '../../../shared/dictionaries'
// import FilterFieldRender from "../../../navigation/filter-links"
import Dropdown from '../../UI/dropdown'
import Checkbox from '../../UI/checkbox'
import CargoItem from '../item'
import { CounterOffersProps, CounterOffersState } from './types'

import CargoTable from '../../search/table'
import Spinner from '../../UI/spinner'
import './styles.scss'
import dict from '../../shared/dictionaries'
import moment from 'moment'

const dictOpenIn = [
  { name: 'Предпросмотр', value: 'preview' },
  { name: 'В новом окне', value: 'window' },
]

const STYLE_BASE = 'cargo-counteroffers_'

const settingCheckboxes = [
  {
    name: 'isCountries',
    text: 'filter.country.from.country.to',
    disabled: true,
  },
  {
    name: 'isCarryingCapacity',
    text: 'total.lifting.capacity',
  },
  {
    name: 'isAdr',
    text: 'autopark.adr',
    icon: 'fa fa-fire',
  },
  {
    name: 'isDates',
    text: 'filter.date.from.date.to',
    disabled: true,
  },
  {
    name: 'isClimateControl',
    text: 'cargo.temperature.mode',
    icon: 'fa fa-snowflake-o',
  },
  {
    name: 'isOversized',
    text: 'no.gabarit',
    icon: 'ico ico-weight',
  },
  {
    name: 'isTransportType',
    text: 'transports.types',
    disabled: true,
  },
  {
    name: 'isGrouping',
    text: 'in.gruppaj',
    icon: 'fa fa-th-large',
  },
  {
    name: 'isOnlyProvedCompanies',
    text: 'only.from.verificated.companies',
  },
  {
    name: 'isOnlyFromMyCountry',
    text: 'only.from.my.contries',
  },
]

export default class CargoCounterOffers extends Component<CounterOffersProps, CounterOffersState> {
  constructor(props) {
    super(props)

    this.state = {
      showSettings: false,
      showItem: false,
      queryStrCustom: {},
      filterBar: {
        items: [
          {
            value: 'new',
            name: 'Новые',
          },
          {
            value: 'all',
            name: 'Всего',
          },
        ],
        selectedKey: props.initialValues.filterKey,
        onClick: filterKey => {
          changeWidgetFilter(this, filterKey)
          this.refresh(0, props.initialValues.filterKey)
        },
      },
      itemId: undefined,
    }
  }

  componentDidMount() {
   // const { queryStr } = this.props
    const { filterBar: { selectedKey } } = this.state

    setTimeout(() => {
      //this.setState({ queryStrCustom: queryStr }, () => {
        this.refresh(0, selectedKey)
    //  })
    }, 1000);

  }

  componentDidUpdate(oldProps: CounterOffersProps) {
    const { userId, refreshing, socketRefreshSearchFinish, query, queryStr } = this.props
    if (!oldProps.userId && userId) {
      this.refresh()
    }

    if (queryStr !== oldProps.queryStr) {
      this.setState({ queryStrCustom: queryStr })
    }

    if (!_.isEqual(query, oldProps.query)) {
      this.refresh()
    }

    if (!oldProps.refreshing && refreshing) {
      this.refresh()
      socketRefreshSearchFinish()
    }
  }
  refresh = (pageIndex?: number, dateScope?: string) => {
    const {
      item: { from, to },
      item,
      query,
      searchType,
      shippingType
    } = this.props
    const { sortBy, pageSize } = query
    const sendQuery: IEntitySearchQuery = {}

    for (const i in query) {
      switch (i) {
        case 'isCountries':
          if (query[i] === true) {
            sendQuery.countriesFrom = [from.countryId]
            sendQuery.countriesTo = [to.countryId]
          }
          break
        case 'isTransportType':
          if (query[i] === true) {
            sendQuery.transportType = item.transportTypes
          }
          break
        case 'isCarryingCapacity':
          if (query[i] === true) {
            sendQuery.carryingCapacity = item[searchType].weight
          }
          break
        case 'isDates':
          if (query[i] === true) {
            sendQuery.dateLoadingFrom = moment(from.date).format('YYYY-MM-DD')
            sendQuery.dateLoadingTo = moment(to.date).format('YYYY-MM-DD')
          }
          break
        default:
          sendQuery[i] = query[i]
      }
    }
    // shippingType
    sendQuery.filterKey = shippingType
    this.props.search({ query: sendQuery, pageIndex, dateScope, sortBy, pageSize })
  }

  toggleSettings = () => {
    this.setState(prevState => ({ showSettings: !prevState.showSettings }))
  }

  showItem() {
    const {
      processing,
      items,
      pageIndex,
      pageSize,
      totalItems,
      addFavourite,
      deleteFavourite,
      userId,
      notificationUnsubscribe,
      searchType,
      query,
      bannerId,
      showDateIsShort
    } = this.props

    return (
      <div className="panel-content-table panel-content-table-cargo">
        <CargoTable
          userId={userId}
          openIn={query.openIn}
          items={items}
          searchType={searchType === 'cargo' ? 'transport' : 'cargo'}
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalItems={totalItems}
          addFavourite={addFavourite}
          deleteFavourite={deleteFavourite}
          processing={processing}
          notificationUnsubscribe={notificationUnsubscribe}
          refresh={this.refresh}
          {...{ showDateIsShort }}
          {...{ bannerId }}
        />
      </div>
    )
  }

  // ------------------------------
  // render
  // ------------------------------
  render() {
    const { t, loading, error, searchType, items } = this.props
    const { itemId, showSettings, queryStrCustom } = this.state

    const dictSortBy = dict.cargoSortBy(t)
    const filterItems = dict.shippingTypes()

    const title = searchType === 'cargo' ? t('cargo.item.counter-offers') : t('transport.item.counter-offers')
    if(items?.toJS().length === 0) return true;
    return (
      <Fragment>
        <WidgetBase
          title={title}
          className={classNames({
            'panel-table panel-table-md': true,
            [`${STYLE_BASE}wrapper`]: true,
          })}
        >
          <div className="see-only-for-you">
            {t('see.only.for.you')}
          </div>
          <WidgetSettings>
            {/* TODO: change layout from bootstrap to flexbox */}
            <div
              data-collapse="true"
              id="inCommingItems"
              className={classNames({
                'panel-settings-header': true,
                'is-active': showSettings,
              })}
            >
              <div className="row">
                <div className="col-md-12">
                  <span className="text-gray criteries-container">
                    {queryStrCustom && t('selected.criteries') + queryStrCustom}
                  </span>
                </div>
                <div className="col-md-4">
                  <button type="button" className="link btn-link" onClick={this.toggleSettings}>
                    <i className="fa fa-cog" />
                    {t('config.criteries')}
                  </button>
                </div>
              </div>
            </div>
            <div
              className={classNames({
                'panel-settings-content collapsible-content': true,
                opened: showSettings,
              })}
            >
              <p className="title-md">{t('select.incomming.params')}:</p>
              <Field
                name="filterKey"
                component={FilterFieldRender}
                items={filterItems}
                hideBorders
                loadLanguages
              />
              <div className="checkboxes-settings clearfix">
                {settingCheckboxes.map(({ name, text, icon, disabled = false }, index) => (
                  <div
                    className={classNames({
                      'col-sm-8': true,
                      'col-md-4 col-lg-3': index < settingCheckboxes.length - 1,
                      'col-md-8 col-lg-4': index === settingCheckboxes.length - 1,
                      'opacity-0-3': disabled,
                    })}
                    key={name}
                  >
                    <div className="input-container">
                      <Field name={name} component={Checkbox} props={{ disabled }}>
                        {t(text)} {icon && <i className={icon} />}
                      </Field>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </WidgetSettings>

          <WidgetFilter {...this.state.filterBar} extended loadLanguages>
            <div className="filter-container">
              <div className="filter-block" style={{ zIndex: 4 }}>
                <label htmlFor="or1">{t('when.click.open')}:</label>
                <div className="select-container">
                  <Field name="openIn" component={Dropdown} items={dictOpenIn} />
                </div>
              </div>
              <div className="filter-block">
                <label htmlFor="or2">{t('sort')}:</label>
                <div className="select-container">
                  <Field name="sortBy" component={Dropdown} items={dictSortBy} />
                </div>
              </div>
              <div className="filter-block">
                <Field name="showHidden" component={Checkbox}>
                  {t('cargo.show.hidden')}
                </Field>
              </div>
            </div>
          </WidgetFilter>

          <div className={classNames('panel-content', `${STYLE_BASE}wrapper`)}>
            {loading ? (
              <Spinner className={classNames(`${STYLE_BASE}spinner`)} />
            ) : error ? (
              <div className={classNames(`${STYLE_BASE}error`)}>{error}</div>
            ) : (
              this.showItem()
            )}
          </div>
        </WidgetBase>
        <ModalForm
          className={`${STYLE_BASE}item-in-modal`}
          title={`${t('cargo.item.title')} (#${itemId})`}
        >
          <CargoItem itemId={itemId} isModalView searchType={searchType} showDateIsShort />
        </ModalForm>
      </Fragment>
    )
  }
}
