import React, { Component } from 'react'
import fetch from '../../../../components/shared/fetch'
import { COMMENTS_ON_PAGE } from '../forum-constants'
import Pagination from '../../../UI/pagination'
import WidgetHeader from '../../../shared/components/widget-header'
import Spinner from '../../../UI/spinner'
import ForumLastComments from '../forum-last-comments'
import { canDo } from '../../../../store/utils'
import { ForumAllCommentsModalProps } from './types'

import './forum-all-comments-modal.scss'

export default class ForumAllCommentsModal extends Component<ForumAllCommentsModalProps> {
  state = {
    allComments: [],
    showSpinner: true,
    totalComments: 0,
    currentPage: 0,
  }

  componentDidMount() {
    this.fetchThemeComments(0, COMMENTS_ON_PAGE, 0)
  }

  fetchThemeComments = (
    themeId: number = 0,
    pageSize: number = COMMENTS_ON_PAGE,
    pageIndex: number = 0
  ): void => {
    fetch(`/forum/getMessagesByTheme/${themeId}/${pageSize}/${pageIndex}`).then(comments => {
      this.setState({
        allComments: comments.data.messages,
        totalComments: comments.data.count,
        showSpinner: false,
      })
    })
  }

  paginationClick = page => {
    this.setState({ currentPage: page })
    this.fetchThemeComments(0, COMMENTS_ON_PAGE, page)
  }

  renderPaginationWrapper = () => {
    return <div className="all-comment-pagination">{this.renderPagination()}</div>
  }

  renderPagination = () => {
    const { currentPage, totalComments } = this.state
    return (
      <Pagination
        onClick={this.paginationClick}
        total={totalComments}
        pageIndex={currentPage}
        pageSize={COMMENTS_ON_PAGE}
      />
    )
  }

  refreshAllComments = () => {
    this.fetchThemeComments()
    this.props.refreshLastComments()
  }

  renderCommentContent = (allComments, closeModal) => {
    const { permissions, showComment } = this.props
    const canDeleteComments = canDo(permissions, 'forum', 'deleteComments')
    const comments = allComments.map((singleComment, index) => {
      return (
        <ForumLastComments
          singleComment={singleComment}
          key={index}
          closeModal={closeModal}
          showComment={showComment}
          showHideFullComment={false}
          showDeleteCommentIcon={canDeleteComments}
          refreshLastCommentsAfterDeleteComment={this.refreshAllComments}
        />
      )
    })
    return comments
  }

  render() {
    const { closeModal, t } = this.props
    const { showSpinner, allComments } = this.state

    return (
      <div className="all-comments-modal-overlay">
        <div className="all-comments-modal-overlay-to-close" onClick={() => closeModal(false, 0)} />
        <div className="all-comments-modal-content">
          {showSpinner && <Spinner />}
          <WidgetHeader title={t('forum.all.comments.on.forum')} className={`panel-header`}>
            <div className="fa fa-times icon-close" onClick={() => closeModal(false, 0)}></div>
          </WidgetHeader>
          <div className="all-comments-content">
            {this.renderPaginationWrapper()}
            {this.renderCommentContent(allComments, closeModal)}
            {this.renderPaginationWrapper()}
          </div>
        </div>
      </div>
    )
  }
}
