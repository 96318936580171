/*

0 = -
1 = +
2 = +*  (с ограничениями для сотрудника)
3 = +** (с ограничениями)
4 = + (до 3 активных)
5 = + (до 5 активных)
6 = + (до 25 активных)
7 = + (до 50 активных)
8 = + (до 100 активных)
9 = + (не более 1 активного)

*/


export const priceData = {
    usersAndPrices: [
        {
            possibilityLabel: "table.1.line.1",
            permissions: {
                guest: 1,
                userWithoutCompanyStandart: 0,
                userWithoutCompanyGold: 0,
                userWithCompanyStandart: 0,
                userWithCompanyGold: 0,
                userWithVerificatedCompanyStandart: 0,
                userWithVerificatedCompanyGold: 0,
                userWithVerificatedCompanyGoldLight: 0,
            }
        },
        {
            possibilityLabel: "table.1.line.2",
            permissions: {
                guest: 0,
                userWithoutCompanyStandart: 1,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 2,
                userWithCompanyGold: 2,
                userWithVerificatedCompanyStandart: 2,
                userWithVerificatedCompanyGold: 2,
                userWithVerificatedCompanyGoldLight: 2,
            }
        },
        {
            possibilityLabel: "table.1.line.3",
            permissions: {
                guest: 1,
                userWithoutCompanyStandart: 1,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 1,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 1,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.4",
            permissions: {
                guest: 3,
                userWithoutCompanyStandart: 3,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 3,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 3,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.5",
            permissions: {
                guest: 0,
                userWithoutCompanyStandart: 0,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 0,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 0,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.6",
            permissions: {
                guest: 0,
                userWithoutCompanyStandart: 0,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 0,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 0,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.7",
            permissions: {
                guest: 1,
                userWithoutCompanyStandart: 1,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 1,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 1,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.8",
            permissions: {
                guest: 3,
                userWithoutCompanyStandart: 3,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 3,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 3,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.9",
            permissions: {
                guest: 1,
                userWithoutCompanyStandart: 1,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 1,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 1,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.10",
            permissions: {
                guest: 3,
                userWithoutCompanyStandart: 3,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 3,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 3,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.11",
            permissions: {
                guest: 3,
                userWithoutCompanyStandart: 3,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 3,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 3,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.12",
            permissions: {
                guest: 1,
                userWithoutCompanyStandart: 1,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 1,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 1,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.13",
            permissions: {
                guest: 1,
                userWithoutCompanyStandart: 1,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 1,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 1,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.14",
            permissions: {
                guest: 0,
                userWithoutCompanyStandart: 4,
                userWithoutCompanyGold: 8,
                userWithCompanyStandart: 4,
                userWithCompanyGold: 8,
                userWithVerificatedCompanyStandart: 4,
                userWithVerificatedCompanyGold: 8,
                userWithVerificatedCompanyGoldLight: 6,
            }
        },
        {
            possibilityLabel: "table.1.line.15",
            permissions: {
                guest: 0,
                userWithoutCompanyStandart: 5,
                userWithoutCompanyGold: 8,
                userWithCompanyStandart: 5,
                userWithCompanyGold: 8,
                userWithVerificatedCompanyStandart: 5,
                userWithVerificatedCompanyGold: 8,
                userWithVerificatedCompanyGoldLight: 7,
            }
        },
        {
            possibilityLabel: "table.1.line.16",
            permissions: {
                guest: 0,
                userWithoutCompanyStandart: 1,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 1,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 1,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.17",
            permissions: {
                guest: 0,
                userWithoutCompanyStandart: 5,
                userWithoutCompanyGold: 5,
                userWithCompanyStandart: 5,
                userWithCompanyGold: 5,
                userWithVerificatedCompanyStandart: 5,
                userWithVerificatedCompanyGold: 5,
                userWithVerificatedCompanyGoldLight: 5,
            }
        },
        {
            possibilityLabel: "table.1.line.18",
            permissions: {
                guest: 0,
                userWithoutCompanyStandart: 1,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 1,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 1,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.19",
            permissions: {
                guest: 0,
                userWithoutCompanyStandart: 0,
                userWithoutCompanyGold: 0,
                userWithCompanyStandart: 0,
                userWithCompanyGold: 0,
                userWithVerificatedCompanyStandart: 1,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.20",
            permissions: {
                guest: 0,
                userWithoutCompanyStandart: 1,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 1,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 1,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.21",
            permissions: {
                guest: 0,
                userWithoutCompanyStandart: 1,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 1,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 1,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.22",
            permissions: {
                guest: 0,
                userWithoutCompanyStandart: 9,
                userWithoutCompanyGold: 5,
                userWithCompanyStandart: 9,
                userWithCompanyGold: 5,
                userWithVerificatedCompanyStandart: 9,
                userWithVerificatedCompanyGold: 5,
                userWithVerificatedCompanyGoldLight: 5,
            }
        },
        {
            possibilityLabel: "table.1.line.23",
            permissions: {
                guest: 0,
                userWithoutCompanyStandart: 1,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 1,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 1,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.24",
            permissions: {
                guest: 0,
                userWithoutCompanyStandart: 1,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 1,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 1,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.25",
            permissions: {
                guest: 1,
                userWithoutCompanyStandart: 1,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 1,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 1,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.1.line.26",
            permissions: {
                guest: 0,
                userWithoutCompanyStandart: 1,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 0,
                userWithCompanyGold: 0,
                userWithVerificatedCompanyStandart: 0,
                userWithVerificatedCompanyGold: 0,
                userWithVerificatedCompanyGoldLight: 0,
            }
        },
        {
            possibilityLabel: "table.1.line.27",
            permissions: {
                guest: 0,
                userWithoutCompanyStandart: 1,
                userWithoutCompanyGold: 1,
                userWithCompanyStandart: 0,
                userWithCompanyGold: 0,
                userWithVerificatedCompanyStandart: 0,
                userWithVerificatedCompanyGold: 0,
                userWithVerificatedCompanyGoldLight: 0,
            }
        },
        {
            possibilityLabel: "table.1.line.28",
            permissions: {
                guest: 0,
                userWithoutCompanyStandart: 0,
                userWithoutCompanyGold: 0,
                userWithCompanyStandart: 1,
                userWithCompanyGold: 1,
                userWithVerificatedCompanyStandart: 1,
                userWithVerificatedCompanyGold: 1,
                userWithVerificatedCompanyGoldLight: 1,
            }
        },
    ],
    companyAdminPermissions: [
        {
            possibilityLabel: "table.2.line.1",
            permissions: {
                companyAdminStandart: 1,
                companyAdminGold: 1,
                companyAdminVerificatedStandart: 1,
                companyAdminVerificatedGold: 1,
                companyAdminVerificatedGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.2.line.2",
            permissions: {
                companyAdminStandart: 1,
                companyAdminGold: 1,
                companyAdminVerificatedStandart: 1,
                companyAdminVerificatedGold: 1,
                companyAdminVerificatedGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.2.line.3",
            permissions: {
                companyAdminStandart: 1,
                companyAdminGold: 1,
                companyAdminVerificatedStandart: 1,
                companyAdminVerificatedGold: 1,
                companyAdminVerificatedGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.2.line.4",
            permissions: {
                companyAdminStandart: 1,
                companyAdminGold: 1,
                companyAdminVerificatedStandart: 1,
                companyAdminVerificatedGold: 1,
                companyAdminVerificatedGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.2.line.5",
            permissions: {
                companyAdminStandart: 0,
                companyAdminGold: 0,
                companyAdminVerificatedStandart: 1,
                companyAdminVerificatedGold: 1,
                companyAdminVerificatedGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.2.line.6",
            permissions: {
                companyAdminStandart: 1,
                companyAdminGold: 1,
                companyAdminVerificatedStandart: 1,
                companyAdminVerificatedGold: 1,
                companyAdminVerificatedGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.2.line.7",
            permissions: {
                companyAdminStandart: 0,
                companyAdminGold: 0,
                companyAdminVerificatedStandart: 1,
                companyAdminVerificatedGold: 1,
                companyAdminVerificatedGoldLight: 1,
            }
        },
        {
            possibilityLabel: "table.2.line.8",
            permissions: {
                companyAdminStandart: 5,
                companyAdminGold: 5,
                companyAdminVerificatedStandart: 5,
                companyAdminVerificatedGold: 8,
                companyAdminVerificatedGoldLight: 8,
            }
        },
        {
            possibilityLabel: "table.2.line.9",
            permissions: {
                companyAdminStandart: 1,
                companyAdminGold: 1,
                companyAdminVerificatedStandart: 0,
                companyAdminVerificatedGold: 0,
                companyAdminVerificatedGoldLight: 0,
            }
        },
        {
            possibilityLabel: "table.2.line.10",
            permissions: {
                companyAdminStandart: 1,
                companyAdminGold: 1,
                companyAdminVerificatedStandart: 1,
                companyAdminVerificatedGold: 1,
                companyAdminVerificatedGoldLight: 1,
            }
        },
    ],
    accountTypePrices: [
        {
            accountLabel: "prices.paket.gold",
            styleColor: "style-4",
            permissions: {
                userWithoutCompany12monts: "<b>145</b> €",
                userWithoutCompany6monts: "<b>85</b> €",
                userWithoutCompany3monts: "<b>55</b> €",
                userWithCompany12monts: "<b>145</b> €",
                userWithCompany6monts: "<b>85</b> €",
                userWithCompany3monts: "<b>55</b> €",
                userWitVerificatedCompany12monts: "<b>145</b> €",
                userWitVerificatedCompany6monts: "<b>85</b> €",
                userWitVerificatedCompany3monts: "<b>55</b> €",
            }
        },
        {
            accountLabel: "prices.paket.gold.light",
            styleColor: "style-4-1",
            permissions: {
                userWithoutCompany12monts: "-",
                userWithoutCompany6monts: "-",
                userWithoutCompany3monts: "-",
                userWithCompany12monts: "-",
                userWithCompany6monts: "-",
                userWithCompany3monts: "-",
                userWitVerificatedCompany12monts: "<b>50</b> €",
                userWitVerificatedCompany6monts: "<b>30</b> €",
                userWitVerificatedCompany3monts: "<b>20</b> €",
            }
        }
    ]
}