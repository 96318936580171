import { connect } from 'react-redux'
import { compose } from 'recompose'
import Component from './component'
import withTranslation from '../../../../globals/components/withTranslation'
import { loginSuccess } from '../../../../store/actions'

export default compose(
  withTranslation,
  connect(null, {
    loginSuccess
  })
)(Component)
