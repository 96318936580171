export const int2 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>КОНВЕНЦИЯ О ДОГОВОРЕ МЕЖДУНАРОДНОЙ ДОРОЖНОЙ ПЕРЕВОЗКИ ГРУЗОВ (CMR)</h4><p></p>

<p style="text-indent:2em;"></p><h6>Договаривающиеся стороны, признав желательность внесения единообразия в условия договора международной перевозки грузов по дорогам и,
 в частности, в условия, касающиеся требуемых для таких перевозок документов и ответственности перевозчика,
 согласились о нижеследующем:</h6><p></p>

<p></p><h4>Глава I. ОБЛАСТЬ ПРИМЕНЕНИЯ КОНВЕНЦИИ</h4><p></p>

<p></p><h4>Глава II. ЛИЦА, ЗА КОТОРЫХ НЕСЕТ ОТВЕТСТВЕННОСТЬ ПЕРЕВОЗЧИК</h4><p></p>

<p></p><h4>Глава III. ЗАКЛЮЧЕНИЕ И ИСПОЛНЕНИЕ ДОГОВОРА</h4><p></p>

<p></p><h4>Глава IV. ОТВЕТСТВЕННОСТЬ ПЕРЕВОЗЧИКА</h4><p></p>

<p></p><h4>Глава V. РЕКЛАМАЦИИ И ИСКИ</h4><p></p>

<p></p><h4>Глава VI. ПОЛОЖЕНИЯ, КАСАЮЩИЕСЯ ПЕРЕВОЗКИ</h4><p></p>

<p></p><h4>Глава VII. НЕДЕЙСТВИТЕЛЬНОСТЬ УСЛОВИЙ КОНТРАКТА</h4><p></p>

<p></p><h4>Глава VIII. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h4><p></p>

<p></p><h4>Глава I. ОБЛАСТЬ ПРИМЕНЕНИЯ КОНВЕНЦИИ</h4><p></p>

<p style="font-weight:500;">Статья 1</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция применяется ко всякому договору дорожной перевозки грузов за вознаграждение посредством транспортных средств, когда
 место погрузки груза и место доставки груза, указанные в контракте, находятся на территории двух различных стран, из которых, по крайней
 мере, одна является участницей Конвенции. Применение Конвенции не зависит от местожительства и национальности заключающих договор
 сторон.</p><br>

<p style="text-indent:2em;">2. При применении настоящей Конвенции под "транспортным средством" следует понимать автомобили, автомобили с полуприцепами, прицепы 
и полуприцепы так, как они определяются в статье 4 Конвенции о дорожном движении от 19 сентября 1949 года.</p><br>

<p style="text-indent:2em;">3. Настоящая Конвенция применяется также в том случае, если перевозки, входящие в область ее применения, производятся государствами
 или правительственными учреждениями или организациями.</p>

<p style="text-indent:2em;"></p><h6>4. Настоящая Конвенция не применяется:</h6><p></p>

<p>к перевозкам, производимым согласно международным почтовым конвенциям;</p><br>

<p>к перевозкам покойников;</p><br>

<p>к перевозкам обстановки и мебели при переездах.</p><br>

<p style="text-indent:2em;">5. Договаривающиеся стороны запрещают вносить изменения в настоящую Конвенцию путем частных соглашений, заключенных между двумя
 или несколькими Договаривающимися сторонами, за исключением отмены ее применения к их пограничным перевозкам или разрешения использования
 при перевозках, производимых исключительно на их территории, накладных, устанавливающих право собственности на груз.</p><br>

<p style="font-weight:500;">Статья 2</p><br>

<p style="text-indent:2em;">1. Если на части перевозки транспортное средство, содержащее груз, перевозится по морю, железной дороге, внутреннему водному пути
 или воздушным транспортом без перегрузки, за исключением случая, предусмотренного статьей 14, настоящая Конвенция применяется ко
 всей перевозке в целом. Однако, если будет доказано, что потеря груза, его повреждение или задержка доставки произошли во время
 перевозки, произведенной одним из видов транспорта, кроме дорожного, и не были вызваны действием или упущением дорожного перевозчика,
 а были вызваны фактом, который мог произойти только во время и по причине перевозки, произведенной не дорожным транспортом, ответственность
 дорожного перевозчика определяется не настоящей Конвенцией, а теми положениями, которыми определялась бы ответственность любого не дорожного
 перевозчика при заключении между ним и отправителем контракта на перевозку груза согласно обязательным положениям закона, касающегося перевозки
 грузов любым видом транспорта, кроме дорожного. Тем не менее в случае отсутствия таких положений ответственность дорожного перевозчика определяется
 настоящей Конвенцией.</p><br>

<p style="text-indent:2em;">2. В том случае, когда перевозчик, производящий дорожные перевозки, одновременно производит перевозки и иным видом транспорта, его
 ответственность определяется также пунктом 1, как если бы его функции дорожного перевозчика и функция перевозчика, производящего перевозки
 не дорожным видом транспорта, осуществлялись бы двумя различными лицами.</p>

<p></p><h4>Глава II. ЛИЦА, ЗА КОТОРЫХ НЕСЕТ ОТВЕТСТВЕННОСТЬ ПЕРЕВОЗЧИК</h4><p></p>

<p style="font-weight:500;">Статья 3</p><br>

<p style="text-indent:2em;">При применении настоящей Конвенции перевозчик отвечает как за свои собственные действия и упущения, так и за действия и упущения своих
 агентов и всех других лиц, к услугам которых он прибегает для осуществления перевозки, когда эти агенты или лица действуют в рамках
 возложенных на них обязанностей.</p>

<p></p><h4>Глава III. ЗАКЛЮЧЕНИЕ И ИСПОЛНЕНИЕ ДОГОВОРА ПЕРЕВОЗКИ</h4><p></p>

<p style="font-weight:500;">Статья 4</p><br>

<p style="text-indent:2em;">Договор перевозки устанавливается накладной. Отсутствие, неправильность или потеря накладной не отражаются ни на существовании, ни на
 действительности договора перевозки, к которому и в этом случае применяются постановления настоящей Конвенции.</p><br>

<p style="font-weight:500;">Статья 5</p><br>

<p style="text-indent:2em;">1. Накладная составляется в трех оригиналах, подписанных отправителем и перевозчиком, причем эти подписи могут быть отпечатаны типографским
 способом или заменены штемпелями отправителя и перевозчика, если это допускается законодательством страны, в которой составлена накладная.
 Первый экземпляр накладной передается отправителю, второй сопровождает груз, а третий остается у перевозчика.</p><br>

<p style="text-indent:2em;">2. В том случае, когда подлежащий перевозке груз должен быть погружен на различные автомобили или же когда речь идет о различного рода
 грузах или о различных партиях грузов, отправитель или перевозчик имеет право требовать составления такого количества накладных, которое
 соответствует количеству используемых автомобилей или количеству подлежащих перевозке разных грузов или партий грузов.</p><br>

<p style="font-weight:500;">Статья 6</p>

<p style="text-indent:2em;"></p><h6>1. Накладная должна содержать следующие сведения:</h6><p></p>

<p>место и дата ее составления;</p><br>

<p>имя и адрес отправителя;</p><br>

<p>имя и адрес транспортного агента;</p><br>

<p>место и дата принятия груза к перевозке и место его</p><br>

<p>доставки;</p><br>

<p>имя и адрес получателя;</p><br>

<p style="text-indent:2em;">принятое обозначение характера груза и тип его упаковки и, в случае перевозки опасных грузов, их обычно признанное обозначение;</p><br>

<p>число грузовых мест, их особая разметка и номера;</p><br>

<p>вес груза брутто или выраженное в других единицах измерения количество груза;</p><br>

<p style="text-indent:2em;">связанные с перевозкой расходы (стоимость перевозки, дополнительные расходы, таможенные пошлины и сборы, а также прочие издержки с
 момента заключения договора до сдачи груза);</p><br>

<p style="text-indent:2em;">инструкции, требуемые для выполнения таможенных формальностей, и другие;</p><br>

<p style="text-indent:2em;">указание, что перевозка производится независимо от всякой оговорки, согласно требованиям, установленным настоящей Конвенцией.</p>

<p style="text-indent:2em;"></p><h6>2. В случае необходимости накладная должна также содержать следующие указания:</h6><p></p>

<p>запрещение перегрузки груза;</p><br>

<p>расходы, которые отправитель принимает на свой счет;</p><br>

<p>сумма наложенного на груз платежа, подлежащего возмещению при сдаче груза;</p><br>

<p>заявление стоимости груза и сумма дополнительной ценности его при доставке;</p><br>

<p>инструкции отправителя перевозчику относительно страхования груза;</p><br>

<p>дополнительный срок выполнения перевозки;</p><br>

<p>перечень документов, переданных перевозчику.</p><br>

<p style="text-indent:2em;">3. Договаривающиеся стороны могут внести в накладную любое иное указание, которое
 будет ими признано необходимым.</p><br>

<p style="font-weight:500;">Статья 7</p>

<p style="text-indent:2em;"></p><h6>1. Отправитель несет ответственность за все издержки перевозки и убытки,
 причиненные ему вследствие неточности или недостаточности:</h6><p></p>

<p>указаний, приведенных в подпунктах b), d), e), f), g), h),</p><br>

<p>пункта 1 статьи 6;</p><br>

<p>указаний, приведенных в пункте 2 статьи 6;</p><br>

<p style="text-indent:2em;">всех иных указаний или инструкций, которые даются отправителем для составления накладной или для включения в нее.</p><br>

<p style="text-indent:2em;">2. Если по просьбе отправителя перевозчик вносит в накладную указания, приведенные в пункте 1 настоящей статьи, признается, поскольку не
 доказано обратное, что это им сделано от имени и за счет отправителя.</p><br>

<p style="text-indent:2em;">3. Если накладная не содержит указаний, предусмотренных в пункте 1 k) статьи 6, перевозчик отвечает за все расходы и за все убытки, которые
 могут быть причинены правомочному в отношении груза лицу вследствие такого упущения.</p><br>

<p style="font-weight:500;">Статья 8</p>

<p style="text-indent:2em;"></p><h6>При принятии груза перевозчик обязан проверить:</h6><p></p>

<p style="text-indent:2em;">o точность записей, сделанных в
 накладной относительно числа грузовых мест, а также их маркировки и номеров;</p><br>

<p>o внешнее состояние груза и его упаковки.</p><br>

<p style="text-indent:2em;">Если перевозчик не имеет достаточной возможности проверить правильность записей, упомянутых в пункте 1 a) настоящей статьи, он должен
 вписать в накладную обоснованные оговорки. Он должен также обосновать все сделанные им оговорки, касающиеся внешнего состояния груза и
 его упаковки. Эти оговорки не имеют обязательной силы для отправителя, если последний намеренно не указал в накладной, что он их
 принимает.</p><br>

<p style="text-indent:2em;">Отправитель имеет право требовать проверки перевозчиком веса брутто или количества груза, выраженного в других единицах измерения.
 Он может также требовать проверки содержимого грузовых мест. Перевозчик может требовать возмещения расходов, связанных с проверкой.
 Результаты проверок вносятся в накладную.</p><br>

<p style="font-weight:500;">Статья 9</p><br>

<p style="text-indent:2em;">1. Накладная, если не доказано противного, имеет силу договора
 относительно его условий и удовлетворения принятия груза перевозчиком.</p><br>

<p style="text-indent:2em;">2. При отсутствии в накладной обоснованных перевозчиком оговорок имеется презумпция, что груз и его упаковка были внешне в исправном
 состоянии в момент принятия груза перевозчиком и что число грузовых мест, а также их маркировка и номера соответствовали указаниям
 накладной.</p><br>

<p style="font-weight:500;">Статья 10</p><br>

<p style="text-indent:2em;">Отправитель несет ответственность перед перевозчиком за ущерб и повреждения, причиненные лицам, оборудованию и другим грузам, а также
 за любые расходы, которые могут быть вызваны поврежденной упаковкой груза, если только при видимом или известном перевозчику в момент
 принятия груза повреждении перевозчиком не было сделано относительно этого надлежащих оговорок.</p><br>

<p style="font-weight:500;">Статья 11</p><br>

<p style="text-indent:2em;">1. Отправитель обязан до доставки груза присоединить к накладной или предоставить в распоряжение перевозчика необходимые документы и
 сообщить все требуемые сведения для выполнения таможенных и иных формальностей.</p><br>

<p style="text-indent:2em;">2. Проверка правильности и полноты этих документов не лежит на обязанности перевозчика. Отправитель ответственен перед перевозчиком
 за всякий ущерб, который может быть причинен отсутствием, недостаточностью или неправильностью этих документов и сведений, за
 исключением случаев вины перевозчика.</p><br>

<p style="text-indent:2em;">3. Перевозчик несет ответственность на тех же основаниях, что и комиссионер, за последствия потери или неправильного использования
 документов, упомянутых в накладной, приложенных к ней или врученных ему; сумма причитающегося с него возмещения не должна, однако,
 превышать ту, которая подлежала бы уплате в случае потери груза.</p><br>

<p style="font-weight:500;">Статья 12</p><br>

<p style="text-indent:2em;">1. Отправитель имеет право распоряжаться грузом, в частности, требовать от перевозчика прекращения перевозки, изменения места
, предусмотренного для доставки груза, или доставки груза не тому получателю, который указан
 в накладной.</p><br>

<p style="text-indent:2em;">2. Отправитель теряет это право с того момента, когда второй экземпляр накладной передан получателю или когда последний осуществляет
 свои права, предусмотренные в пункте 1 статьи 13; с этого момента перевозчик должен 
 руководствоваться указаниями получателя груза.</p><br>

<p style="text-indent:2em;">3. Однако право распоряжения грузом принадлежит получателю с момента составления накладной, если в накладной отправителем сделано
 такого рода указание.</p><br>

<p style="text-indent:2em;">4. Если, осуществляя свое право распоряжения грузом, получатель дает указание сдать груз другому лицу, последнее не вправе назначить
 других получателей.</p>

<p style="text-indent:2em;"></p><h6>5. Право распоряжения грузом осуществляется при следующих условиях:</h6><p></p>

<p style="text-indent:2em;">отправитель или в случае, указанном в пункте 3 настоящей статьи, - получатель, желающий осуществить это право, должен представить
 первый экземпляр накладной, в которую должны быть внесены новые инструкции, данные перевозчику, а также возместить перевозку расходы
 и ущерб, вызванные выполнением этих инструкций;</p><br>

<p style="text-indent:2em;">выполнение этих инструкций должно быть возможным в тот момент, когда их получает лицо, которое должно их выполнить; оно не должно
 нарушать хода нормальной работы предприятия перевозчика и не должно наносить ущерба отправителям или получателям других грузов;</p><br>

<p>упомянутые инструкции не должны ни в коем случае приводить к разбивке грузов.</p><br>

<p style="text-indent:2em;">6. Если перевозчик не может выполнить полученные им инструкции по причине указанных в пункте 5 b) положений, он должен немедленно
 сообщить об этом лицу, которым были даны инструкции.</p><br>

<p style="text-indent:2em;">7. Перевозчик, не выполнивший инструкций, которые были ему даны в условиях, указанных в настоящей статье, или подчинившийся таким
 инструкциям, не потребовав представления ему первого экземпляра накладной, несет ответственность перед правомочным по договору
 лицом за понесенный таким образом ущерб.</p><br>

<p style="font-weight:500;">Статья 13</p><br>

<p style="text-indent:2em;">1. По прибытии груза на место, предусмотренное для его доставки, получатель имеет право требовать передачи ему второго экземпляра
 накладной и сдачи ему груза, причем им выдается соответствующая расписка в принятии. Если установлена потеря груза или если груз
 не прибыл по истечении срока, предусмотренного в статье 19, получатель может требовать от своего имени от перевозчика удовлетворения,
 ссылаясь на права, обеспеченные ему договором перевозки.</p><br>

<p style="text-indent:2em;">2. Получатель, осуществляющий права, предоставляемые ему согласно пункту 1 настоящей статьи, обязан погасить возникшие на основании
 накладной долговые обязательства. В случае возникновения спора по этому поводу перевозчик обязан осуществить поставку груза лишь в
 случае внесения получателем залога.</p><br>

<p style="font-weight:500;">Статья 14</p><br>

<p style="text-indent:2em;">1. Если по какой-либо причине выполнение договора на определенных в накладной условиях является или становится невозможным до прибытия
 груза к предусмотренному месту доставки, перевозчик обязан запросить инструкции у лица, имеющего право распоряжаться грузом согласно
 статье 12.</p><br>

<p style="text-indent:2em;">2. Если же обстоятельства позволяют выполнить перевозку в условиях, отличных от предусмотренных в накладной, и если перевозчик не смог
 своевременно получить инструкций от лица, имеющего право распоряжаться грузом согласно статье 12, перевозчик должен принять меры, которые
 представляются ему наиболее подходящими в интересах лица, имеющего право распоряжаться грузом.</p><br>

<p style="font-weight:500;">Статья 15</p><br>

<p style="text-indent:2em;">1. Если после прибытия груза на место назначения возникают препятствия к его сдаче, перевозчик должен запросить инструкции у отправителя.
 Если получатель отказывается принять груз, отправитель имеет право распоряжаться грузом, не предъявляя первого экземпляра накладной.</p><br>

<p style="text-indent:2em;">2. Получатель, даже если он отказался от принятия груза, может в любой момент потребовать его сдачи до тех пор, пока перевозчик не получил
 от отправителя противоположных инструкций.</p><br>

<p style="text-indent:2em;">3. Если препятствие к сдаче груза возникает после того как получатель согласно предоставленному ему пунктом 3 статьи 12 праву дал приказ
 доставить груз какому-либо другому лицу, то для выполнения вышеуказанных положений пунктов 1 и 2 получатель становится на место отправителя,
 а это другое лицо - на место получателя.</p><br>

<p style="font-weight:500;">Статья 16</p><br>

<p style="text-indent:2em;">1. Перевозчик имеет право на возмещение расходов, вызванных запросом инструкций или выполнением полученных инструкций, поскольку эти
 расходы не являются следствием его собственной вины.</p><br>

<p style="text-indent:2em;">2. В условиях, указанных в пункте 1 статьи 14 и в статье 15, перевозчик может немедленно выгрузить груз за счет лица, правомочного по договору;
 после такой выгрузки перевозка считается законченной. В таком случае перевозчик осуществляет хранение груза. Он может, однако, доверить хранение
 груза какому-либо третьему лицу и в этом случае несет ответственность лишь за осмотрительный выбор этих третьих лиц. Груз остается при этом
 обремененным лежащими на нем договорными обязательствами, вытекающими из накладной, и всеми прочими расходами.</p><br>

<p style="text-indent:2em;">3. Перевозчик может продать груз, не выжидая инструкций от правомочного по договору лица, если груз является скоропортящимся или если того
 требует его состояние, или же если хранение груза влечет за собой расходы, слишком высокие по сравнению с его стоимостью. В других случаях
 перевозчик может также продать груз, если в надлежащий срок им не будет получено от правомочного по договору лица противоположных инструкций,
 выполнение которых может быть справедливо потребовано.</p><br>

<p style="text-indent:2em;">4. В случае продажи груза согласно положениям настоящей статьи вырученная сумма, за вычетом лежащих на грузе и подлежащих уплате расходов, должна
 быть передана в распоряжение правомочного по договору лица. Если расходы превосходят выручку, то перевозчик имеет право получить причитающуюся
 ему разницу.</p><br>

<p style="text-indent:2em;">5. Применяемая при продаже процедура определяется действующими на месте продажи законами или обычаями.</p>

<p></p><h4>Глава IV. ОТВЕТСТВЕННОСТЬ ПЕРЕВОЗЧИКА</h4><p></p>

<p style="font-weight:500;">Статья 17</p><br>

<p style="text-indent:2em;">1. Перевозчик несет ответственность за полную или частичную потерю груза или за его повреждение, происшедшее в промежуток времени между принятием
 груза к перевозке и его сдачей, а также за опоздание доставки.</p><br>

<p style="text-indent:2em;">2. Перевозчик освобождается от этой ответственности, если потеря груза, его повреждение или опоздание произошли по вине правомочного по договору лица,
 вследствие приказа последнего, не вызванного какой-либо виной перевозчика, каким-либо дефектом самого груза или обстоятельствами, избегнуть которые
 перевозчик не мог и последствия которых он не мог предотвратить.</p><br>

<p style="text-indent:2em;">3. Перевозчик не может ссылаться для сложения с себя ответственности ни на дефекты транспортного средства, которым он пользуется для осуществления
 перевозки, ни на вину лица, у которого был взят в аренду автомобиль, или агентов последнего.</p>

<p style="text-indent:2em;"></p><h6>4. С учетом пунктов 2 и 5 статьи 18 перевозчик освобождается от лежащей на нем ответственности, когда потеря или повреждение груза являются следствием
 особого риска, неразрывно связанного с одним или несколькими из перечисленных ниже обстоятельств:</h6><p></p>

<p style="text-indent:2em;">с использованием открытых или неукрытых транспортных средств, если такое использование было специально оговорено и указано в накладной;</p><br>

<p style="text-indent:2em;">с отсутствием или повреждением упаковки грузов, по своей природе подверженных порче и повреждению без упаковки или при неудовлетворительной
 упаковке их;</p><br>

<p style="text-indent:2em;">с перемещением, погрузкой, размещением или выгрузкой груза отправителем или получателем, или лицами, действующими от имени отправителя или
 грузополучателя;</p><br>

<p style="text-indent:2em;">с природой некоторых грузов, подверженных из-за этих свойств, обусловленных их природой, полной или частичной гибели или повреждению, в частности,
 подверженных поломке, ржавению, внезапному гниению, усушке, утечке, нормальной потере или нападению паразитов и грызунов;</p><br>

<p>с недостаточностью или неудовлетворительностью маркировки или нумерации грузовых мест;</p><br>

<p>с перевозкой животных.</p><br>

<p style="text-indent:2em;">5. В тех случаях, когда согласно настоящей статье перевозчик не несет ответственности за некоторые обстоятельства, вызвавшие ущерб, лежащая на нем
 ответственность ограничивается лишь той мерой, в какой он отвечает согласно настоящей статье за обстоятельства, способствовавшие причинению ущерба.</p><br>

<p style="font-weight:500;">Статья 18</p><br>

<p style="text-indent:2em;">1. На перевозчике лежит бремя доказательства того, что потеря груза, его повреждение или опоздание были вызваны обстоятельствами, указанными в пункте
 2 статьи 17.</p><br>

<p style="text-indent:2em;">2. Если перевозчик докажет, что при создавшихся обстоятельствах потеря груза или его повреждение могли явиться следствием одного или нескольких
 особых рисков, указанных в пункте 4 статьи 17, допускается презумпция, что таковые произошли вследствие этого. Правомочное лицо может, однако,
 доказывать, что частичный или полный ущерб не явился следствием одного из этих рисков.</p><br>

<p style="text-indent:2em;">3. Указанная выше презумпция не допускается в случае, предусмотренном в пункте 4 a) статьи 17, если убыль превышает нормально допустимую или при
 потере грузового места.</p><br>

<p style="text-indent:2em;">4. Если перевозка производится посредством транспортного средства, оборудованного так, чтобы груз не подвергался влиянию тепла, холода, изменений
 температуры или влажности воздуха, перевозчик может ссылаться на пункт 4 d) статьи 17 лишь в том случае, если докажет, что все меры, которые он
 обязан был принять, учитывая обстоятельства, были им приняты в отношении выбора, содержания и использования вышеупомянутых установок и что он
 руководствовался данными ему специальными инструкциями.</p><br>

<p style="text-indent:2em;">5. Перевозчик может ссылаться в свою пользу на пункт 4 f) статьи 17 только в том случае, если докажет, что все меры, которые он был обязан принять,
 учитывая обстоятельства, были им приняты и что он придерживался специальных инструкций, 
 которые могли быть ему даны.</p><br>

<p style="font-weight:500;">Статья 19</p><br>

<p style="text-indent:2em;">Признается, что имела место просрочка, если груз не был доставлен в оговоренный срок или, при отсутствии оговоренного срока, если с учетом
 обстоятельств, в которых перевозка производилась, а в частности, при частичной погрузке, с учетом времени, необходимого для составления полной
 партии в нормальных условиях, фактическая продолжительность перевозки превышает время, необходимое при обычных условиях для выполнения перевозки
 добросовестным перевозчиком.</p><br>

<p style="font-weight:500;">Статья 20</p><br>

<p style="text-indent:2em;">1. Правомочное по договору лицо может без представления иных доказательств считать груз потерянным, если он не был доставлен в течение тридцати
 дней по прошествии установленного срока или, когда такового не было, в течение 
 шестидесяти дней со дня принятия груза перевозчиком.</p><br>

<p style="text-indent:2em;">2. Правомочное по договору лицо может при получении им возмещения за утраченный груз просить в письменной форме о его немедленном возвращении в
 том случае, если груз будет найден в течение года, следующего за уплатой возмещения. Принятие к сведению такого его требования должно быть
 подтверждено письменно.</p><br>

<p style="text-indent:2em;">3. В течение тридцати дней после получения извещения о том, что груз найден, правомочное по договору лицо может требовать, чтобы груз был ему
 сдан по уплате долговых обязательств, вытекающих из накладной, а также возвращении полученного им возмещения за вычетом возможных понесенных
 расходов, включенных в возмещенную сумму, и с оговоркой о сохранении всех прав на возмещение за опоздание с доставкой, предусмотренных в статье
 23 и, если к тому имеются основания, в статье 26.</p><br>

<p style="text-indent:2em;">4. За отсутствием либо просьбы, указанной выше в пункте 2, либо инструкций, данных в тридцатидневный срок, предусмотренный в пункте 3, или же в
 случае, если груз был найден только по прошествии года со дня уплаты возмещения за его потерю, перевозчик может распоряжаться найденным грузом,
 соблюдая требования закона в месте нахождения груза.</p><br>

<p style="font-weight:500;">Статья 21</p><br>

<p style="text-indent:2em;">Если груз сдан получателю без взыскания наложенного на груз платежа, который должен был быть взыскан перевозчиком согласно условиям договора
 перевозки, перевозчик обязан выплатить отправителю компенсацию в сумме, не превышающей суммы такого наложенного платежа, без ущерба для своего
 права предъявить иск к получателю.</p><br>

<p style="font-weight:500;">Статья 22</p><br>

<p style="text-indent:2em;">1. Если отправитель передает перевозчику опасные грузы, он должен ему указать точно характер представляемой этими грузами опасности, а также,
 если нужно, указать необходимые предосторожности, которые следует предпринять.</p><br>

<p style="text-indent:2em;">Если эти указания не внесены в накладную, отправитель или получатель должен всяким иным путем доказать, что перевозчик был осведомлен в деталях
 о характере опасности, которую представляет перевозка упомянутых грузов.</p><br>

<p style="text-indent:2em;">2. Опасные грузы, о характере которых перевозчик не был осведомлен с соблюдением условий, указанных в пункте 1 настоящей статьи, могут быть в
 любой момент и в любом месте выгружены, уничтожены или обезврежены перевозчиком без всякого возмещения убытков за них; отправитель является,
 кроме того, ответственным за все расходы и убытки, вызванные передачей этих грузов для перевозки или их перевозкой.</p><br>

<p style="font-weight:500;">Статья 23</p><br>

<p style="text-indent:2em;">1. Когда согласно постановлениям настоящей Конвенции перевозчик обязан возместить ущерб, вызванный полной или частичной потерей груза, размер
 подлежащей возмещению суммы определяется на основании стоимости груза в месте и во время принятия его для перевозки.</p><br>

<p style="text-indent:2em;">2. Стоимость груза определяется на основании биржевой котировки, или за отсутствием таковой на основании текущей рыночной цены, или же при
 отсутствии и той и другой на основании обычной стоимости товара такого же рода и качества.</p><br>

<p style="text-indent:2em;">3. Размер возмещения не может, однако, превышать 25 франков за килограмм недостающего веса брутто. Под франком подразумевается золотой франк
 весом 10/31 гр. золота 0.900 пробы.</p>

<p style="text-indent:2em;"></p><h6>4. Кроме того, подлежат возмещению:</h6><p></p>

<p>оплата за перевозку,</p><br>

<p>таможенные сборы и пошлины,</p><br>

<p style="text-indent:2em;">а также прочие расходы, связанные с перевозкой груза, полностью в случае потери всего груза и в пропорции, соответствующей размеру ущерба,
 при частичной потере;</p><br>

<p>иной убыток возмещению не подлежит.</p><br>

<p style="text-indent:2em;">5. В случае просрочки с доставкой и если полномочное по договору лицо докажет, что просрочка нанесла ущерб, перевозчик обязан возместить ущерб,
 который не может превышать платы за перевозку.</p><br>

<p style="text-indent:2em;">6. Более значительное по своему размеру возмещение может быть потребовано с перевозчика только в том случае, если в соответствии со статьями 24
 и 26 была сделана декларация о стоимости груза или декларация о дополнительной ценности
 груза при доставке.</p><br>

<p style="font-weight:500;">Статья 24</p><br>

<p style="text-indent:2em;">Отправитель может указать в накладной при условии уплаты установленной по обоюдному соглашению надбавки к провозной плате стоимость груза,
 превышающую предел, указанный в пункте 3 статьи 23, и в таком случае заявленная сумма заменяет этот предел.</p><br>

<p style="font-weight:500;">Статья 25</p><br>

<p style="text-indent:2em;">1. В случае повреждения груза перевозчик оплачивает сумму, соответствующую обесцениванию груза, рассчитываемую по стоимости груза, установленной
 в соответствии с требованиями пунктов 1, 2 и 4 статьи 23.</p>

<p style="text-indent:2em;"></p><h6>2. Размер возмещения не может, однако, превышать:</h6><p></p>

<p style="text-indent:2em;">в случае, если вследствие повреждения обесцениванию подвергся весь перевозимый груз, - суммы возмещения, которая причиталась бы
 при потере всего груза;</p><br>

<p style="text-indent:2em;">в случае, если вследствие повреждения обесцениванию подверглась лишь часть перевозимого груза, - суммы, которая причиталась бы
 при потере той части груза, которая оказалась обесцененной.</p><br>

<p style="font-weight:500;">Статья 26</p><br>

<p style="text-indent:2em;">1. Отправитель может указать, вписав в накладную и при условии уплаты установленной по обоюдному соглашению надбавки к провозной плате,
 объявленную ценность груза на случай потери или повреждения груза, а также недоставки
 груза в оговоренный срок.</p><br>

<p style="text-indent:2em;">2. В случае объявления ценности груза при доставке может быть потребовано независимо от возмещений, предусмотренных в статьях 23, 24 и 25,
 и в пределах суммы заявленной ценности груза возмещение, соответствующее дополнительному 
 ущербу, нанесение которого доказано.</p><br>

<p style="font-weight:500;">Статья 27</p><br>

<p style="text-indent:2em;">1. Правомочное по договору лицо может потребовать уплаты процентов на сумму, подлежащую возмещению. Проценты эти исчисляются из расчета пяти
 процентов годовых со дня передачи перевозчику письменной рекламации или же, 
 если таковой не последовало, со дня подачи иска.</p><br>

<p style="text-indent:2em;">2. В том случае, когда данные, служащие для исчисления подлежащей возмещению суммы, не выражены в валюте государства, в котором предъявлено
 требование о возмещении, пересчет в эту валюту производится по текущему курсу дня на 
 месте выплаты возмещения.</p><br>

<p style="font-weight:500;">Статья 28</p><br>

<p style="text-indent:2em;">1. В тех случаях, когда согласно применяемому закону в связи с потерей, повреждением или просрочкой в доставке, происшедшими при выполнении
 подпадающей под настоящую Конвенцию перевозки, может быть предъявлено внедоговорное требование, перевозчик может сослаться на положения настоящей
 Конвенции, исключающие его ответственность или определяющие или ограничивающие 
 подлежащие уплате возмещения.</p><br>

<p style="text-indent:2em;">2. Когда встает вопрос о внедоговорной ответственности за потерю, повреждение или просрочку в доставке одного из лиц, за которых перевозчик
 отвечает согласно требованиям статьи 3, это лицо может также сослаться на положения настоящей Конвенции, исключающие ответственность перевозчика
 или определяющие или ограничивающие подлежащие уплате возмещения.</p><br>

<p style="font-weight:500;">Статья 29</p><br>

<p style="text-indent:2em;">1. Перевозчик не вправе ссылаться на положения настоящей главы, которые или ограничивают его ответственность или переносят бремя доказательства
 на другую сторону, если ущерб был вызван его злоумышленным поступком или произошел по вине, которая согласно закону, применяемому разбирающим
 дело судом, приравнивается к злоумышленному поступку.</p><br>

<p style="text-indent:2em;">2. То же замечание относится и к тем случаям, когда ущерб был вызван злоумышленным поступком или виной агентов перевозчика или других лиц,
 к услугам которых перевозчик прибегает для выполнения перевозки, в момент выполнения этими агентами или другими лицами возложенных на них
 обязанностей. В таком случае эти агенты или другие лица также не вправе ссылаться на указанные в пункте 1 положения настоящей главы, поскольку
 дело касается их личной ответственности.</p>

<p></p><h4>Глава V. РЕКЛАМАЦИИ И ИСКИ</h4><p></p>

<p style="font-weight:500;">Статья 30</p><br>

<p style="text-indent:2em;">1. Если получатель принял груз и не установил состояния груза в присутствии перевозчика или самое позднее в момент принятия груза, когда речь
 идет о заметных потерях или повреждениях, или в течение семи дней со дня поставки груза, не считая воскресенья и прочих нерабочих дней, когда
 речь идет о незаметных внешне потерях или повреждениях, не сделал перевозчику оговорок, указывающих общий характер потери или повреждений,
 имеется, поскольку не доказано обратное, основание для презумпций, что груз был принят получателем в состоянии, описанном в накладной. Когда
 речь идет о незаметных снаружи утратах или повреждениях, указанные выше оговорки должны быть сделаны в письменной форме.</p><br>

<p style="text-indent:2em;">2. Когда, напротив, состояние груза было установлено в присутствии как получателя, так и перевозчика, доказательство, необходимое для отрицания
 результата этой констатации, может быть представлено лишь в том случае, если речь идет о внешне незаметных потерях или повреждениях и если
 получатель адресовал письменные оговорки перевозчику в течение семи дней, не считая воскресенья и прочих нерабочих дней, со дня такой констатации.</p><br>

<p style="text-indent:2em;">3. Просрочка в доставке груза может привести к уплате возмещения лишь в том случае, если была сделана письменная оговорка в течение 21 дня
 со дня передачи груза в распоряжение получателя.</p><br>

<p style="text-indent:2em;">4. При исчислении сроков дата поставки или в зависимости от обстоятельств дата констатации или дата передачи товара получателю не входит в
 срок, предусмотренный настоящей статьей.</p><br>

<p style="text-indent:2em;">5. Перевозчик и получатель должны оказать друг другу надлежащее содействие при выполнении всех необходимых обследований и проверок.</p><br>

<p style="font-weight:500;">Статья 31</p>

<p style="text-indent:2em;"></p><h6>1. По любым спорам, возникающим по поводу перевозок, производимых в соответствии с настоящей Конвенцией, истец может обращаться помимо
 компетентных судов участвующих в Конвенции стран, указанных с общего согласия сторонами, к 
 суду страны, на территории которой находятся:</h6><p></p>

<p style="text-indent:2em;">обычное местожительство ответчика, его главная контора или отделение или агентство, при посредстве которых был заключен договор 
перевозки, или</p><br>

<p>место принятия груза к перевозке или место доставки, и может обратиться лишь к этим судам.</p><br>

<p style="text-indent:2em;">2. Когда при возникновении какого-либо спора, о котором говорится в пункте 1 настоящей статьи, дело находится на рассмотрении в суде, компетентном
 в силу положений пункта, или когда по такому спору этим судом было вынесено решение, между одними и теми же сторонами не может быть возбуждено
 нового дела на одном и том же основании, за исключением тех случаев, когда решение суда, которому был передан первый иск, не может быть приведено
 в исполнение в стране, в которой предъявлен новый иск.</p><br>

<p style="text-indent:2em;">3. Когда по какому-либо спору, о котором говорится в пункте 1 настоящей статьи, решение, вынесенное судом одной из стран, участвующих в Конвенции,
 подлежит исполнению в этой стране, это решение становится также подлежащим исполнению в любой из других участвующих в Конвенции стран немедленно
 по сообщении предписанных для этого формальностей в этой стране. Формальности
 эти не могут иметь предметом пересмотр дела.</p><br>

<p style="text-indent:2em;">4. Положения, содержащиеся в пункте 3 настоящей статьи, относятся к решениям, вынесенным в присутствии сторон, к решениям заочным и к
 примирительному судопроизводству, но не относятся ни к судебным решениям, имеющим лишь временную силу, ни к решениям, согласно которым проигравший
 дело должен оплатить не только судебные издержки, но и возместить ответчику убытки, причиненные полным или частичным неудовлетворением его
 исковой претензии.</p><br>

<p style="text-indent:2em;">5. Суд не вправе требовать с граждан стран, участвующих в Конвенции, местожительство которых или постоянное пребывание находится в одной из этих 
стран, внесения залога для обеспечения уплаты судебных издержек, связанных с предъявлением иска, касающегося перевозок, выполняемых в соответствии
 с настоящей Конвенцией.</p><br>

<p style="font-weight:500;">Статья 32</p>

<p style="text-indent:2em;"></p><h6>1. Подача исков, которые могут возникнуть в результате перевозок, выполненных в соответствии с настоящей Конвенцией, может происходить в течение 
одного года. Однако в случае злоумышленного поступка или вины, которая согласно закону, применяемому разбирающим дело судом, приравнивается к 
злоумышленному поступку, срок устанавливается в три года. Срок исчисляется:</h6><p></p>

<p>в случае частичной потери груза, повреждения его или просрочки в доставке - со дня сдачи груза;</p><br>

<p style="text-indent:2em;">в случае потери всего груза - с тридцатого дня по истечении установленного для перевозки срока или, если таковой не был установлен, с 
шестидесятого дня по принятии груза перевозчиком к перевозке;</p><br>

<p>во всех прочих случаях - по истечении трехмесячного срока со дня заключения договора перевозки.</p><br>

День, указанный выше в качестве точки отсчета срока подачи иска, не принимается в расчет при установлении его срока.<p></p><br>

<p style="text-indent:2em;">2. Предъявление рекламации в письменной форме приостанавливает течение срока до того дня, когда перевозчик в письменной форме отверг резолюцию 
с возвращением приложенных к ней документов. В случае частичного признания предъявленной рекламации срок подачи иска возобновляется только в отношении 
той части рекламации, которая остается предметом спора. Доказательство факта получения рекламации или ответа на нее, а также возвращения относящихся к 
делу документов лежит на стороне, которая ссылается на этот факт.</p><br>

<p>Предъявление дальнейших рекламаций на том же основании не прерывает течения срока.</p><br>

<p style="text-indent:2em;">3. При условии соблюдения положений, содержащихся в приведенном выше пункте 2, приостановление срока подачи иска регулируется судебным законом. 
То же самое относится к прерыванию этого срока.</p><br>

<p style="text-indent:2em;">4. Иск по истечении вышеуказанного срока не может быть более предъявлен даже в форме встречного иска или возвращения.</p><br>

<p style="font-weight:500;">Статья 33</p><br>

<p style="text-indent:2em;">Договор перевозки может содержать статью, которой признается компетенция арбитражного трибунала, при условии, чтобы этой статьей предусматривалось, 
что арбитражный трибунал должен применять постановления настоящей Конвенции.</p>

<p></p><h4> Глава VI. ПОЛОЖЕНИЯ, КАСАЮЩИЕСЯ ПЕРЕВОЗКИ, ПРОИЗВОДИМОЙ ПОСЛЕДОВАТЕЛЬНО НЕСКОЛЬКИМИ ПЕРЕВОЗЧИКАМИ</h4><p></p>

<p style="font-weight:500;">Статья 34</p><br>

<p style="text-indent:2em;">Если перевозка, условия которой определяются единственным договором, осуществляется несколькими перевозчиками, каждый из них несет ответственность 
за всю перевозку, причем второй перевозчик и каждый из следующих перевозчиков становятся в силу принятия ими груза и накладной участниками договора
 перевозки на указанных в накладной условиях.</p><br>

<p style="font-weight:500;">Статья 35</p><br>

<p style="text-indent:2em;">1. Перевозчик, принимающий груз от своего предшественника, вручает последнему датированную и подписанную им расписку. Он должен отметить свое имя и 
адрес на втором экземпляре накладной. В случае надобности он делает в этом экземпляре, равно как и в выданной им расписке, оговорки, аналогичные 
предусмотренным в пункте 2 статьи 8.</p><br>

<p style="text-indent:2em;">2. Положения, содержащиеся в статье 9, применяются к взаимоотношениям между
 перевозчиками, последовательно осуществляющими перевозку.</p><br>

<p style="font-weight:500;">Статья 36</p><br>

<p style="text-indent:2em;">Поскольку дело не касается встречного иска или возражения, представленного при рассмотрении иска, основанного на том же договоре перевозки, 
всякий иск, касающийся ответственности за потерю груза, повреждение его или просрочку доставки, может быть предъявлен только первому перевозчику, 
последнему перевозчику либо перевозчику, выполняющему ту часть перевозки, при которой имел место факт, вызвавший потерю груза, его повреждение 
или просрочку доставки; иск может быть предъявлен одновременно против нескольких из этих перевозчиков.</p><br>

<p style="font-weight:500;">Статья 37</p>

<p style="text-indent:2em;"></p><h6>Перевозчик, уплативший согласно положениям настоящей Конвенции возмещение за ущерб, имеет право взыскивать с остальных участвовавших в выполнении 
договора перевозки перевозчиков основную, подлежащую возмещению сумму, проценты на нее и издержки, связанные с перевозкой, согласно нижеследующим 
положениям:</h6><p></p>

<p style="text-indent:2em;">перевозчик, по вине которого был причинен ущерб, должен один нести ответственность за
 убытки, оплачиваемые им самим или другим перевозчиком;</p><br>

<p style="text-indent:2em;">если ущерб был причинен по вине двух или нескольких перевозчиков, каждый из них должен уплатить сумму, пропорциональную доле лежащей на них 
ответственности; если же определение этой доли представляется невозможным, каждый перевозчик несет ответственность пропорционально части 
причитающейся ему платы за перевозку;</p><br>

<p style="text-indent:2em;">если нельзя установить, кто из перевозчиков несет ответственность за ущерб, причитающаяся за возмещение его сумма распределяется между 
всеми транспортными агентами в пропорции, указанной выше в пункте b).</p><br>

<p style="font-weight:500;">Статья 38</p><br>

<p style="text-indent:2em;">В случае неплатежеспособности одного из перевозчиков причитающаяся с него и не уплаченная им часть возмещения распределяется между всеми 
перевозчиками пропорционально приходящемуся на долю каждого из них вознаграждению за перевозку.</p><br>

<p style="font-weight:500;">Статья 39</p><br>

<p style="text-indent:2em;">1. Перевозчик, к которому предъявлен один из указанных в статьях 37 и 38 исков о возмещении убытков, не имеет права оспаривать обоснованность 
платежа, произведенного предъявляющим к нему иск перевозчиком, когда размер возмещения за ущерб установлен решением суда, если только он был 
надлежащим образом осведомлен о процессе и имел возможность принять в нем участие.</p><br>

<p style="text-indent:2em;">2. Перевозчик, желающий предъявить иск об убытках, может направить исковое прошение в компетентный суд страны, в которой находится постоянное 
местожительство одного из заинтересованных в деле перевозчиков, его главная контора или отделение или агентство, при посредстве которого был 
заключен договор перевозки.</p><br>

<p style="text-indent:2em;">Иск может быть возбужден против всех заинтересованных
 в деле перевозчиков в одной и той же судебной инстанции.</p><br>

<p style="text-indent:2em;">3. Положения, содержащиеся в пункте 3 статьи 31, применяются к судебным решениям,
 вынесенным по искам, упомянутым в статьях 37 и 38.</p><br>

<p style="text-indent:2em;">4. Положения статьи 32 действительны в отношении исков, предъявляемых одними перевозчиками к другим. Однако срок подачи иска исчисляется либо 
со дня вынесения окончательного судебного решения, которым определяется размер возмещения, подлежащего уплате согласно положениям настоящей Конвенции, 
либо при отсутствии такого решения со дня фактической уплаты возмещения.</p><br>

<p style="font-weight:500;">Статья 40</p><br>

<p style="text-indent:2em;">Перевозчики вправе установить по взаимному соглашению условия, нарушающие положения,
 содержащиеся в статьях 37 и 38.</p>

<p></p><h4>Глава VII. НЕДЕЙСТВИТЕЛЬНОСТЬ УСЛОВИЙ КОНТРАКТА, ПРОТИВОРЕЧАЩИХ НАСТОЯЩЕЙ КОНВЕНЦИИ</h4><p></p>

<p style="font-weight:500;">Статья 41</p><br>

<p style="text-indent:2em;">1. С оговоркой относительно положений статьи 40 признается не имеющим силы всякое условие, которым прямо или косвенно допускается отступление 
от положений настоящей Конвенции.</p><br>

<p style="text-indent:2em;">Недействительность такого условия не влечет за собой недействительность других
 содержащихся в договоре условий.</p><br>

<p style="text-indent:2em;">2. В частности, недействительным является всякое условие, в силу которого перевозчику переуступаются права, предоставленные страхователю груза, 
или всякое аналогичное условие, а также всякое условие, которым перелагается бремя доказательства.</p>

<p></p><h4>Глава VIII. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 42</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция открыта для подписания или присоединения к ней стран - членов Европейской Экономической Комиссии и стран, допущенных с 
правом совещательного голоса в соответствии с пунктом 8 мандата этой Комиссии.</p><br>

<p style="text-indent:2em;">2. Страны, допущенные к участию в некоторых работах Европейской Экономической Комиссии, согласно статье 2 ее мандата ведения могут стать 
Договаривающимися сторонами настоящей Конвенции путем присоединения к ней по ее вступлении в силу.</p><br>

<p style="text-indent:2em;">3. Конвенция будет открыта для ее подписания до 31 августа 1956 г.
 включительно. После этой даты она будет открыта для присоединения к ней.</p><br>

<p>4. Настоящая Конвенция подлежит ратификации.</p><br>

<p style="text-indent:2em;">5. Ратификация Конвенции или присоединение к ней должны производиться путем передачи на хранение Генеральному Секретарю Организации Объединенных 
Наций надлежащего акта.</p><br>

<p style="font-weight:500;">Статья 43</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция вступает в силу на девяностый день после того, как пять из указанных в пункте 1 статьи 42 стран передадут их акты о 
ратификации или присоединении.</p><br>

<p style="text-indent:2em;">2. В отношении каждой страны, которая ратифицирует настоящую Конвенцию и присоединится к ней после того, как пять стран передадут их акты о 
ратификации или присоединении, настоящая Конвенция вступает в силу на девяностый день после передачи каждой из этих стран ратифицированных 
грамот или актов о присоединении.</p><br>

<p style="font-weight:500;">Статья 44</p><br>

<p style="text-indent:2em;">1. Любая Договаривающаяся сторона может выйти из настоящей Конвенции путем направления уведомления Генеральному Секретарю Организации 
Объединенных Наций.</p><br>

<p style="text-indent:2em;">2. Выход из Конвенции вступает в силу по истечении двенадцатимесячного срока после получения Генеральным Секретарем вышеупомянутого 
уведомления.</p><br>

<p style="font-weight:500;">Статья 45</p><br>

<p style="text-indent:2em;">Если после вступления в силу настоящей Конвенции число Договаривающихся сторон окажется вследствие отказа от участия в ней менее пяти, 
настоящая Конвенция теряет силу со дня, когда станет действительным последний из отказов от участия.</p><br>

<p style="font-weight:500;">Статья 46</p><br>

<p style="text-indent:2em;">1. Каждая страна может при передаче своей ратификационной грамоты или акта о присоединении или в любое время впоследствии заявить путем 
уведомления о том Генерального Секретаря Организации Объединенных Наций, что настоящая Конвенция распространяется на все или часть 
территорий, за внешние сношения которых она является ответственной. Конвенция начинает применяться на территории или на территориях, 
указанных в уведомлении, по истечении девяноста дней после получения Генеральным Секретарем Организации Объединенных Наций упомянутого 
уведомления или, если в тот день Конвенция еще не вступит в силу, после вступления ее в силу.</p><br>

<p style="text-indent:2em;">2. Каждая страна, сделавшая в соответствии с предыдущим пунктом заявление о применении настоящей Конвенции на территории, за внешние 
сношения которой она является ответственной, может в соответствии со статьей 44 денонсировать 
Конвенцию в отношении упомянутой территории.</p><br>

<p style="font-weight:500;">Статья 47</p><br>

<p style="text-indent:2em;">Всякий спор между двумя или несколькими Договаривающимися сторонами относительно истолкования или применения настоящей Конвенции, 
который стороны не смогут разрешить путем переговоров или другим способом, может быть по просьбе любой из заинтересованных 
Договаривающихся сторон передан Международному Суду для разрешения им.</p><br>

<p style="font-weight:500;">Статья 48</p><br>

<p style="text-indent:2em;">1. Каждая Договаривающаяся сторона может в момент подписания или ратификации настоящей Конвенции или присоединения к ней заявить, 
что она не считает себя связанной статьей 47 Конвенции.</p><br>

<p style="text-indent:2em;">Другие Договаривающиеся стороны не будут связаны статьей 47 по отношению ко всем Договаривающимся сторонам, сформулировавшим 
подобную оговорку.</p><br>

<p style="text-indent:2em;">2. Каждая Договаривающаяся сторона, сделавшая оговорку в соответствии с пунктом 1, может в любой момент взять ее обратно путем 
уведомления, адресованного Генеральному Секретарю Организации Объединенных Наций.</p><br>

<p>3. Никакие другие оговорки к настоящей Конвенции не допускаются.</p><br>

<p style="font-weight:500;">Статья 49</p><br>

<p style="text-indent:2em;">1. После трехлетнего действия настоящей Конвенции любая Договаривающаяся сторона может путем уведомления, адресованного 
Генеральному Секретарю Организации Объединенных Наций, представить просьбу о созыве совещания с целью пересмотра настоящей 
Конвенции.</p><br>

<p style="text-indent:2em;">Генеральный Секретарь сообщит об этой просьбе всем Договаривающимся
 сторонам и созовет совещание для пересмотра</p><br>

<p style="text-indent:2em;">Конвенции, если только в течение четырехмесячного срока после его сообщения по меньшей мере одна четверть Договаривающихся 
сторон уведомит его о своем согласии на созыв такого совещания.</p><br>

<p style="text-indent:2em;">2. Если совещание созывается в соответствии с предыдущим пунктом,
 Генеральный Секретарь уведомляет об этом все</p><br>

<p style="text-indent:2em;">Договаривающиеся стороны и представляет им просьбу сообщить в трехмесячный срок предложения, рассмотрение которых на совещании 
представляется им желательным. По меньшей мере за три месяца до открытия совещания Генеральный Секретарь сообщает всем Договаривающимся 
сторонам предварительную повестку дня совещания, а также текст этих предложений.</p><br>

<p style="text-indent:2em;">3. Генеральный Секретарь пригласит на любое созванное согласно настоящей статье совещание все страны, оговариваемые пунктом 1 статьи 
42, а также страны, ставшие Договаривающимися сторонами на основании пункта 2 статьи 42.</p><br>

<p style="font-weight:500;">Статья 50</p>

<p style="text-indent:2em;"></p><h6>Помимо уведомлений, упомянутых в статье 49, Генеральный Секретарь Организации Объединенных Наций сообщает странам, указанным в 
пункте 1 статьи 42, а также странам, ставшим Договаривающимися сторонами 
на основании пункта 2 статьи 42:</h6><p></p>

<p>о ратификации и присоединении к Конвенции согласно статье 42;</p><br>

<p>о датах вступления в силу настоящей Конвенции в соответствии со статьей 43;</p><br>

<p>о денонсациях в силу статьи 44;</p><br>

<p>об утрате настоящей Конвенцией силы в соответствии со статьей 45;</p><br>

<p>об уведомлениях, полученных в соответствии со статьей 46;</p><br>

<p>о заявлениях и уведомлениях, полученных в соответствии с пунктами 1 и 2 статьи 48.</p><br>

<p style="font-weight:500;">Статья 51</p><br>

<p style="text-indent:2em;">После 31 августа 1956 г. подлинник настоящей Конвенции будет сдан на хранение Генеральному Секретарю Организации Объединенных 
Наций, который препроводит надлежащим образом заверенные копии каждой из стран, указанных в пунктах 1 и 2 статьи 42.</p><br>

<p style="text-indent:2em;">В удостоверение чего нижеподписавшиеся, надлежащим образом на то
 уполномоченные, подписали настоящую Конвенцию.</p><br>

<p style="text-indent:2em;">Составлено в Женеве в одном экземпляре 19 мая 1956 года на английском и французском языках, причем оба текста имеют 
одинаковую силу.</p>

<p></p><h4>ПРОТОКОЛ ПОДПИСАНИЯ</h4><p></p>

<p style="text-indent:2em;"></p><h6>В момент подписания Конвенции, касающейся договора международной перевозки грузов по дорогам, нижеподписавшиеся, надлежащим 
образом уполномоченные, согласились внести нижеследующие заявления и пояснения:</h6><p></p>

<p style="text-indent:2em;">1. Настоящая Конвенция не применяется к перевозкам между Соединенным Королевством Великобритании и Северной Ирландии и 
Ирландской Республикой.</p><br>

<p>2. К пункту 4 статьи 1.</p><br>

<p style="text-indent:2em;">Нижеподписавшиеся обязуются провести переговоры о заключении Конвенции, регулирующей договор перевозки обстановки и мебели 
при переездах, и Конвенции, регулирующей смешанную перевозку.</p><br>

<p style="text-indent:2em;">В удостоверение чего нижеподписавшиеся, надлежащим образом на то уполномоченные, подписали настоящий Протокол.</p><br>

<p style="text-indent:2em;">Совершено в Женеве 19 мая 1956 года в одном экземпляре на английском и французском языках, причем каждый текст 
является равно аутентичным.</p><br>

<p style="text-indent:2em;">Перевод сделан с подлинного текста, опубликованного ООН на французском и английском языках отдельным изданием 
за N N E/EE/253, E/ECE/TRANS/480.</p>

<p></p><h4>СПИСОК ГОСУДАРСТВ - УЧАСТНИКОВ КОНВЕНЦИИ О ДОГОВОРЕ МЕЖДУНАРОДНОЙ ДОРОЖНОЙ ПЕРЕВОЗКИ ГРУЗОВ</h4><p></p>

<p>(Женева, 19 мая 1956 г.)</p><br>

<p>Название Дата Дата присоединения ПРИМЕЧАНИЕ</p><br>

<p>государства подписания (вступления в силу)</p><br>

<p>АВСТРИЯ 19.05.1956 18.07.1960</p><br>
<p>БЕЛАРУСЬ 05.04.1993</p><br>
<p>БЕЛЬГИЯ 19.05.1956 18.09.1962</p><br>
<p>БОЛГАРИЯ 20.10.1977</p><br>
<p>БОСНИЯ И ГЕРЦЕГОВИНА 06.03.1992</p><br>
<p>ВЕЛИКОБРИТАНИЯ 21.07.1967</p><br>
<p>ВНР 29.04.1970</p><br>
<p>ГДР 27.12.1973</p><br>
<p>ГРЕЦИЯ 24.05.1977</p><br>
<p>ДАНИЯ 28.06.1965</p><br>
<p>ИСПАНИЯ 12.02.1974</p><br>
<p>ИТАЛИЯ 03.04.1961</p><br>
<p>ЛАТВИЯ 14.01.1994</p><br>
<p>ЛИТВА 17.03.1993</p><br>
<p>ЛЮКСЕМБУРГ 19.05.1956 20.04.1964</p><br>
<p>НИДЕРЛАНДЫ 19.05.1956 27.09.1960</p><br>
<p>НОРВЕГИЯ 01.07.1969</p><br>
<p>НРБ 20.10.1977</p><br>
<p>ПНР 19.05.1956 13.06.1962</p><br>
<p>ПОРТУГАЛИЯ 22.09.1969</p><br>
<p>СЛОВАКИЯ 01.01.1993 правопреемство</p><br>
<p>СРР 23.01.1973</p><br>
<p>СССР 01.12.1983</p><br>
<p>СФРЮ 19.05.1956 22.10.1958</p><br>
<p>ТУНИС 24.01.1994</p><br>
<p>ТУРЦИЯ 26.04.1983</p><br>
<p>ФИНЛЯНДИЯ 27.06.1973</p><br>
<p>ФРАНЦИЯ 19.05.1956 20.05.1959</p><br>
<p>ФРГ 19.05.1956 07.11.1961</p><br>
<p>ХОРВАТИЯ 03.09.1992 правопреемство с 08.10.1991</p><br>
<p>ЧССР 04.09.1974</p><br>
<p>ШВЕЙЦАРИЯ 19.05.1956 27.02.1970</p><br>
<p>ШВЕЦИЯ 19.05.1956 02.04.1969</p><br>

<p><a href="https://www.transportir.by/mezhdunarodnoe-transportnoe-pravo/konventsiya-o-dogovore-mezhdunarodnoj-dorozhnoj-perevozki-gruzov-kdpg.html">Источник</a></p>

</div>
`