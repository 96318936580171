import React, { useState } from 'react'

import {
  FORM_FIELD_ERROR_POSITION_TOP,
  FORM_FIELD_ERROR_POSITION_BOTTOM,
} from '../../../store/constants'

import './style.scss'

export default ({
  input,
  label = null,
  placeholder,
  type,
  errorPosition = FORM_FIELD_ERROR_POSITION_BOTTOM,
  formatError,
  onErrorChange,
  meta: { touched, error },
  ...restProps
}) => {
  const [isHidden, setHidden] = useState(true)
  const changeHidden = () => setHidden(!isHidden)
  const typeControl = isHidden ? type : 'text'

  return (
    <div className="form-group">
      {label && (
        <label>
          <span className="text">{label}</span>
          {errorPosition === FORM_FIELD_ERROR_POSITION_TOP && touched && error && (
            <div className="validation-message text-danger">{error}</div>
          )}
        </label>
      )}
      <div className="form-container">
        <input
          className="form-control"
          {...input}
          {...restProps}
          placeholder={placeholder}
          type={typeControl}
        />
        {type === 'password' && (
          <div className="password-control" onClick={changeHidden}>
            <i className={isHidden ? 'fa fa-eye' : 'fa fa-eye-slash'} />
          </div>
        )}
        {errorPosition === FORM_FIELD_ERROR_POSITION_BOTTOM && (
          <div className="validation-message text-danger">{touched && error}</div>
        )}
      </div>
    </div>
  )
}
