import { connect } from 'react-redux'
import { compose } from 'recompose'
import _ from 'lodash'

import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'

import { getProfile, resetProfile, getGlobalUserRating, getGlobalCanUserAddReview } from '../../../store/actions/profile'
import { userChangeDateRegistration } from '../../../store/actions/user'

import Component from './component'
import { IReduxStorage } from '../../../store/reducers/types'
import { ProfileMiniBaseProps } from './types'

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state: IReduxStorage) => {
      const customUserSelected = !_.isEmpty(state.profile.profile)

      return {
        languages: state.ui.languages,
        loaded: state.auth.profile.get('loaded'),
        loading: customUserSelected ? state.auth.profile.get('loading') : state.profile.isLoading,
        profile: customUserSelected ? state.profile.profile : state.auth.profile.get('data').toJS(),
        userReviewQuota: state.profile.userReviewQuota,
        myProfile: state.auth.profile.get('data').toJS(),
        userCanAddReview: state.profile.userCanAddReview,
        isAuth: !!state.auth.accessToken,
      }
    },
    {
      getProfile,
      resetProfile,
      getGlobalUserRating,
      getGlobalCanUserAddReview,
      userChangeDateRegistration,
    }
  )
)(Component) as React.ComponentClass<ProfileMiniBaseProps>
