import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import fetch from '../../shared/fetch'
import dict from '../../shared/dictionaries'
import Widget from '../../shared/components/widget'
import './styles.scss'

interface IBuySellState {
  items: IBuySellItem[]
}

interface IBuySellItem {
  name: string
  total: number
  today: number
}

export default class extends Component<ComponentBaseProps, IBuySellState> {
  state = {
    items: [],
  }

  componentDidMount() {
    const { langId } = this.props
    const subCategories = dict.filterSubOptionsForAds(langId)

    subCategories.handler.then(result => {
      fetch('/announcement/buySellTable').then(response => {
        const items = result.items.map(item => {
          const value = response.data.find(el => +el.categoryId === item.value) || {}

          return {
            name: item.name,
            total: value.total || 0,
            today: value.today || 0,
          }
        })
        this.setState({ items })
      })
    })
  }

  render() {
    const { t, dataMoveForMobile } = this.props
    const { items } = this.state

    return (
      <Widget
        title={t('ads.buy.sell').toUpperCase()}
        linksTo={{ title: '/ads/list/437' }}
        {...{dataMoveForMobile}}
      >
        <table className="table-simple buy-sell-table">
          <thead>
            <tr>
              <th />
              <th>{t('ads.todays')}</th>
              <th>{t('statistics.total')}</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>
                  <Link to="/ads/list/437">{item.name}</Link>
                </td>
                <td>{item.today}</td>
                <td>{item.total}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Widget>
    )
  }
}
