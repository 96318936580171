import React from 'react'
import moment from 'moment'
import { getDifferentHoursFromUtcTime } from '../../../shared/utils'

function renderthemeDataTime(commentData) {
  return (
    <div className="comment-date-time-container">

      <div className="comment-calendar">
        <i className="fa fa-calendar"></i>
        <span>{moment(commentData).format('DD.MM.YY')}</span>
      </div>

      <div className="comment-clock">
        <i className="fa fa-clock-o"></i>
        {/* <span>{moment(commentData).add(getDifferentHoursFromUtcTime(), 'hours').format('HH:mm')}</span> */}
        <span>{moment(commentData).format('HH:mm')}</span>
      </div>

    </div>
  )
}

function renderthemeData(commentData) {
  return (
    <span>
      <i className="fa fa-calendar" style={{ paddingRight: '5px' }}></i>
      {moment(commentData).format('DD.MM.YY')}
    </span>
  )
}

export default function ForumCommentDate(commentData) {
  const today = moment(new Date()).format('DD.MM.YY')
  const themeData = moment(commentData).format('DD.MM.YY')
  const mainData =
    today === themeData ? renderthemeDataTime(commentData) : renderthemeData(commentData)
  return mainData
}
