import { connect } from 'react-redux'
import { compose } from 'recompose'

import withTranslation from '../../../globals/components/withTranslation'
import { setInitialMessengerState, getConversationList } from '../../../store/actions'

import Component from './component'
import withPermissions from '../../../globals/components/withPermissions'
import { IReduxStorage } from '../../../store/reducers/types'
import { MessageListInterface } from './types'

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state: IReduxStorage) => {
      const profile = state.auth.profile.get('data')

      return {
        chatMsgs: state.socket.chatMsgs,
      }
    },
    {
        setInitialMessengerState,
        getConversationList
    }
  )
)(Component) as React.ComponentClass<MessageListInterface>
