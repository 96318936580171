const { REACT_APP_TRANSPORT_TARGET: target } = process.env

if (target === 'local') {
  module.exports = require('./local')
} else if (target === 'test') {
  module.exports = require('./testing')
} else {
  // if (REACT_APP_TRANSPORT_TARGET === 'dist') {
  module.exports = require('./dist')
}
