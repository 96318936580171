export const int21 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Европейская конвенция о защите животных при международной перевозке
Париж 13 декабря 1968 года</h4><p></p>

<p style="text-indent:2em;"></p><h6>См. также Дополнительный протокол к Европейской конвенции о защите животных при международной
 перевозке Страсбург, 10 мая 1979 года:</h6><p></p>

<p style="text-indent:2em;">Государства-члены Совета Европы, подписавшие настоящую Конвенцию, считая, что целью Совета Европы
 является достижение большего единства между его членами в целях защиты и осуществления идеалов
 и принципов, являющихся их общим наследием;</p><br>

<p style="text-indent:2em;">будучи убеждены, что требования международной перевозки животных не являются несовместимыми с 
требованиями охраны здоровья животных; преисполненные желания в максимально возможной степени
 оградить животных от страданий при перевозке;</p>

<p style="text-indent:2em;"></p><h6>считая, что прогресс в этом отношении мог бы быть достигнут на основе принятия единых положений,
 касающихся международной перевозки животных, договорились о нижеследующем:</h6><p></p>

<p></p><h4>Глава I</h4><p></p>

<p style="font-weight:500;">Статья 1</p><br>

<p style="text-indent:2em;">1. Каждая Договаривающаяся Сторона применяет содержащиеся к настоящей Конвенции положения,
 регулирующие международную перевозку животных.</p><br>

<p style="text-indent:2em;">2. Для целей настоящей Конвенции международная перевозка означает любое перемещение,
 связанное с пересечением границы. Приграничные перевозки, однако, исключаются.</p><br>

<p style="text-indent:2em;">3. Компетентные органы страны отправления решают, соответствует ли перевозка положениям
 настоящей Конвенции. Тем не менее страна назначения или промежуточные страны могут оспорить
 соответствие какой-либо конкретной перевозки положениям настоящей Конвенции. Однако такая партия
 задерживается только в том случае, когда это строго необходимо для охраны здоровья животных.</p><br>

<p style="text-indent:2em;">4. Каждая Договаривающаяся Сторона принимает необходимые меры для предотвращения или уменьшения
 до минимума страданий животных в тех случаях, когда забастовки или другие непредвиденные
 обстоятельства на ее территории препятствуют строгому выполнению положений настоящей Конвенции.
 Для этой цели она будет руководствоваться принципами, установленными в настоящей Конвенции.</p><br>

<p style="font-weight:500;">Статья 2</p>

<p style="text-indent:2em;"></p><h6>Настоящая Конвенция применяется в отношении международной перевозки:</h6><p></p>

<p>а) домашних непарнокопытных и домашних животных: крупного рогатого скота, овец, коз и 
свиней (Глава II);</p><br>

<p>b) домашней птицы и домашних кроликов (Глава III);</p><br>

<p>с) домашних собак и домашних кошек (Глава IV);</p><br>

<p>d) других млекопитающих и птиц (Глава V);</p><br>

<p>е) холоднокровных животных (Глава VI).</p>

<p></p><h4>Глава II – Домашние непарнокопытные и домашние животные: крупный рогатый скот, 
овцы, козы и свиньи</h4><p></p>

<p style="font-weight:500;">А. Общие положения</p><br>

<p style="font-weight:500;">Статья З</p><br>

<p style="text-indent:2em;">1. До погрузки животных для международной перевозки они осматриваются уполномоченным 
сотрудником ветеринарной службы страны-экспортера, который удостоверяется в том, что они 
годны к перевозке. Для целей настоящей Конвенции под уполномоченным сотрудником ветеринарной
 службы понимается сотрудник ветеринарной службы, назначенный компетентным органом.</p><br>

<p style="text-indent:2em;">2. Погрузка осуществляется в соответствии с порядком, утвержденным уполномоченным 
сотрудником ветеринарной службы.</p><br>

<p style="text-indent:2em;">3. Уполномоченный сотрудник ветеринарной службы выдает сертификат, в котором 
указывается вид животных, подтверждается, что они годны к перевозке, и при возможности 
содержится регистрационный номер и вид используемого транспортного средства.</p><br>

<p style="text-indent:2em;">4. В некоторых случаях, определяемых по соглашению между заинтересованными 
Договаривающимися Сторонами, положения настоящей статьи могут не применяться.</p><br>

<p style="font-weight:500;">Статья 4</p><br>

<p style="text-indent:2em;">Животные, которые должны родить в ходе перевозки или родившие в течение предшествующих 
48 часов, не считаются годными для перевозки.</p><br>

<p style="font-weight:500;">Статья 5</p><br>

<p style="text-indent:2em;">Уполномоченный сотрудник ветеринарной службы страны-экспортера, страны транзита или 
страны-импортера может предписать период отдыха в определенном им месте, в течение
 которого животные получают необходимый уход.</p><br>

<p style="font-weight:500;">Статья 6</p><br>

<p style="text-indent:2em;">1. Животным обеспечивается достаточное пространство и, если особые условия не потребуют
 иного, возможность лежать.</p><br>

<p style="text-indent:2em;">2. Средства перевозки и контейнеры должны иметь конструкцию, обеспечивающую защиту животных
 от неблагоприятных погодных условий и резких различий в климатических условиях. Вентиляция
 и объем пространства должны соответствовать условиям перевозки и учитывать особенности 
 перевозимых видов животных.</p><br>

<p style="text-indent:2em;">3. Контейнеры для транспортировки животных имеют маркировку, указывающую на наличие живых животных,
 и знак, указывающий вертикальное положение. Контейнеры должны легко чиститься, исключать бегство 
 животных, а их конструкция должна обеспечивать безопасность животных. Контейнеры также допускают 
 проведение осмотра и уход за животными и укладываются таким образом, чтобы не препятствовать 
 вентиляции. В ходе перевозки и перегрузки контейнеры всегда находятся в вертикальном положении 
 и не подвергаются резким толчкам или раскачиванию.</p><br>

<p style="text-indent:2em;">4. При перевозке через необходимые промежутки времени животным даются вода и соответствующая пища. 
Животные не должны оставаться без пищи и воды более 24 часов. Этот период может быть, однако, 
продлен, если перевозка к месту назначения, где разгружаются животные, может быть завершена в 
пределах разумного периода времени.</p><br>

<p style="text-indent:2em;">5. Во время перевозки на непарнокопытных должны быть надеты недоуздки. Это положение может 
не распространяться на животных, не приученных к недоуздку.</p><br>

<p style="text-indent:2em;">6. Если животные находятся на привязи, веревки или другие используемые привязи должны быть 
достаточно прочными, чтобы не рваться при перевозке в обычных условиях; они должны быть 
достаточной длины, чтобы животные при необходимости могли лежать, есть и пить. Крупный 
рогатый скот не привязывают за рога.</p><br>

<p>7. У непарнокопытных, если они не находятся в отдельных стойлах, задние ноги не должны 
быть связаны.</p><br>

<p style="text-indent:2em;">8. Бычков старше 18 месяцев предпочтительнее привязывать. Носовое кольцо может 
использоваться только для управления животными.</p><br>

<p style="font-weight:500;">Статья 7</p><br>

<p style="text-indent:2em;">1. При перевозке животных различных видов в одном вагоне, транспортном средстве, 
на одном судне или воздушном судне один вид должен быть отделен от другого. Кроме того, 
для предотвращения неблагоприятных реакций, которые могут быть вызваны перевозкой в одной 
партии видов, враждебных друг другу в естественных условиях, принимаются специальные меры. 
При перевозке животных различного возраста в одном вагоне, транспортном средстве, на одном 
судне или воздушном судне взрослые животные и молодняк содержатся отдельно; это ограничение, 
однако, не распространяется на самок, перевозимых со вскармливаемыми ими детенышами. Что 
касается крупного рогатого скота, непарнокопытных и свиней, то некастрированные самцы 
отделяются от самок. Взрослые хряки также отделяются друг от друга; это относится и к 
жеребцам.</p><br>

<p style="text-indent:2em;">2. В отделениях, где перевозятся животные, не размещают грузы, которые могут отрицательно 
повлиять на состояние животных.</p><br>

<p style="font-weight:500;">Статья 8</p><br>

<p style="text-indent:2em;">Для погрузки и выгрузки животных используются соответствующие приспособления, такие как 
мостки, сходни или подмостки. Настил таких приспособлений по своей конструкции должен 
исключать скольжение, и при необходимости они снабжаются боковыми ограждениями. При 
погрузке и разгрузке животных не поднимают за голову, рога или ноги.</p><br>

<p style="font-weight:500;">Статья 9</p><br>

<p style="text-indent:2em;">Пол вагонов, транспортных средств, воздушных судов или контейнеров должен быть достаточно
 прочным, чтобы выдерживать вес перевозимых животных, не иметь щелей и по своей 
 конструкции не допускать скольжения. Пол должен быть покрыт достаточным 
 количеством подстилки, поглощающей экскременты, кроме тех случаев, когда 
 они могут по меньшей мере столь же эффективно удаляться иным образом.</p><br>

<p style="font-weight:500;">Статья 10</p>

<p style="text-indent:2em;"></p><h6>Для обеспечения надлежащего ухода за животными при перевозке партии животных 
сопровождаются обслуживающим персоналом, за исключением следующих случаев:</h6><p></p>

<p>а) когда животные находятся в закрытых контейнерах;</p><br>

<p>b) когда перевозчик обязуется взять на себя функции сопровождающего;</p><br>

<p style="text-indent:2em;">с) когда грузоотправитель назначил представителя для ухода за животными в 
соответствующих пунктах остановки.</p><br>

<p style="font-weight:500;">Статья 11</p><br>

<p style="text-indent:2em;">1. Сопровождающий или представитель грузоотправителя обеспечивает уход за 
животными, кормит и поит и при необходимости доит их.</p><br>

<p>2. Дойных коров доят через интервалы, не превышающие 12 часов.</p><br>

<p style="text-indent:2em;">3. Для того чтобы сопровождающий мог осуществлять такой уход, он должен иметь 
в случае необходимости соответствующие осветительные средства.</p><br>

<p style="font-weight:500;">Статья 12</p><br>

<p style="text-indent:2em;">Животным, заболевшим или получившим травмы при перевозке, как можно скорее 
оказывают ветеринарную помощь, и при необходимости их забивают таким образом, 
который исключал бы их чрезмерные страдания.</p><br>

<p style="font-weight:500;">Статья 13</p><br>

<p style="text-indent:2em;">Животные грузятся только в такие вагоны, транспортные средства, суда, воздушные 
суда или контейнеры, которые были тщательно очищены. Мертвые животные, 
подстилка и экскременты удаляются как можно скорее.</p><br>

<p style="font-weight:500;">Статья 14</p><br>

<p style="text-indent:2em;">Животные как можно скорее перевозятся к месту их назначения, и задержки, 
в частности в перегрузочных и сортировочных загонах, сокращаются до минимума.</p><br>

<p style="font-weight:500;">Статья 15</p><br>

<p style="text-indent:2em;">В целях скорейшего выполнения ввозных и транзитных формальностей уведомления о 
прибытии партий животных направляются контрольным пунктам как можно скорее. 
При выполнении таких формальностей приоритет отдается партиям животных.</p><br>

<p style="font-weight:500;">Статья 16</p><br>

<p style="text-indent:2em;">В тех пунктах, в которых осуществляется санитарный контроль и через которые 
обычно перевозится значительное число животных, обеспечиваются условия 
для отдыха, кормления и подачи воды.</p><br>

<p style="font-weight:500;">В. Особые положения для железнодорожной перевозки</p><br>

<p style="font-weight:500;">Статья 17</p><br>

<p style="text-indent:2em;">Любой железнодорожный вагон, в котором перевозятся животные, имеет маркировку, 
указывающую на наличие живых животных. При отсутствии возможности получить 
вагоны, специально приспособленные для транспортировки животных, животные 
перевозятся в крытых вагонах, пригодных для перевозки на большой скорости и 
имеющих достаточно большие вентиляционные отверстия. Их конструкция должна 
предотвращать бегство животных и обеспечивать их безопасность. Внутренние 
стенки вагонов должны быть изготовлены из дерева или других соответствующих 
материалов, быть совершенно гладкими и иметь на соответствующей высоте кольца 
или штанги, к которым можно было бы привязывать животных.</p><br>

<p style="font-weight:500;">Статья 18</p><br>

<p style="text-indent:2em;">Непарнокопытных необходимо привязывать так, чтобы все они располагались 
головой к одной стене транспортного средства или головой друг к другу. 
Однако молодняк, не приученный к привязи, не привязывается.</p><br>

<p style="font-weight:500;">Статья 19</p><br>

<p>Крупные животные грузятся таким образом, чтобы сопровождающий мог 
передвигаться между ними.</p><br>

<p style="font-weight:500;">Статья 20</p><br>

<p style="text-indent:2em;">Когда, в соответствии с положениями статьи 7, необходимо раздельное 
размещение животных, это осуществляется либо путем привязывания их в 
отделенных частях вагона, если для этого имеется пространство, либо 
путем установки соответствующих перегородок.</p><br>

<p style="font-weight:500;">Статья 21</p><br>

<p style="text-indent:2em;">При составлении поездов и при любых других перемещениях вагонов 
принимаются все меры предосторожности, чтобы не допустить резких 
толчков вагонов, в которых находятся животные.</p><br>

<p style="font-weight:500;">С. Особые положения для автомобильной перевозки</p><br>

<p style="font-weight:500;">Статья 22</p><br>

<p style="text-indent:2em;">Транспортные средства должны исключать бегство животных и иметь конструкцию,
 обеспечивающую их безопасность, а также крышу для необходимой защиты от 
 плохих погодных условий.</p><br>

<p style="font-weight:500;">Статья 23</p><br>

<p style="text-indent:2em;">Транспортные средства, в которых перевозятся крупные животные, содержащиеся 
обычно на привязи, должны быть оснащены такими привязями. Если транспортные 
средства разгораживаются, устанавливаемые перегородки должны иметь жесткую 
конструкцию.</p><br>

<p style="font-weight:500;">Статья 24</p><br>

<p>Транспортные средства должны быть оснащены сходнями, отвечающими 
требованиям статьи 8.</p><br>

<p style="font-weight:500;">D. Особые положения для водной перевозки</p><br>

<p style="font-weight:500;">Статья 25</p><br>

<p style="text-indent:2em;">Внутреннее оборудование судов должно исключать нанесение травм и причинение 
чрезмерных страданий животным при перевозке.</p><br>

<p style="font-weight:500;">Статья 26</p><br>

<p style="text-indent:2em;">Животные не перевозятся на открытых палубах, кроме как в надлежащим 
образом защищенных контейнерах или прочных сооружениях, разрешенных компетентными
 органами и обеспечивающих надлежащую защиту при неблагоприятных морских и 
 погодных условиях.</p><br>

<p style="font-weight:500;">Статья 27</p><br>

<p>Животные содержатся на привязи или надлежащим образом размещаются в 
загонах или контейнерах.</p><br>

<p style="font-weight:500;">Статья 28</p><br>

<p style="text-indent:2em;">Предусматриваются необходимые проходы, открывающие доступ ко всем загонам 
и контейнерам, в которых содержатся животные. Обеспечиваются средства 
освещения.</p><br>

<p style="font-weight:500;">Статья 29</p><br>

<p style="text-indent:2em;">Выделяется достаточное число сопровождающих с учетом числа перевозимых 
животных и продолжительности перевозки.</p><br>

<p style="font-weight:500;">Статья 30</p><br>

<p style="text-indent:2em;">Все части судна, в которых располагаются животные, имеют стоки и содержатся 
в надлежащем санитарном состоянии.</p><br>

<p style="font-weight:500;">Статья 31</p><br>

<p style="text-indent:2em;">На борту транспортного средства должно быть соответствующее приспособление 
для забоя животных, утвержденное компетентными органами.</p><br>

<p style="font-weight:500;">Статья 32</p><br>

<p style="text-indent:2em;">Суда, используемые для транспортировки животных, перед отплытием обеспечиваются 
таким количеством питьевой воды и соответствующих кормов, которое считается 
достаточным, по мнению компетентных органов страны грузоотправителя, с учетом 
конкретных видов и числа перевозимых животных, а также продолжительности 
перевозки.</p><br>

<p style="font-weight:500;">Статья 33</p><br>

<p style="text-indent:2em;">Предусматривается возможность изолировать больных или раненых животных в ходе 
перевозки и оказать при необходимости первую помощь.</p><br>

<p style="font-weight:500;">Статья 34</p><br>

<p style="text-indent:2em;">Положения статей 25-33 не распространяются на перевозку животных, погруженных 
в железнодорожные вагоны или автотранспортные средства на борту паромов 
или аналогичных судов.</p><br>

<p style="font-weight:500;">Е. Особые положения для воздушной перевозки</p><br>

<p style="font-weight:500;">Статья 35</p><br>

<p style="text-indent:2em;">Животные перевозятся в контейнерах или стойлах, подходящих для данного вида. 
Могут допускаться некоторые изменения этих требований, если соответствующие 
меры принимаются для ограничения перемещения животных.</p><br>

<p style="font-weight:500;">Статья 36</p><br>

<p style="text-indent:2em;">Принимаются меры предосторожности для предотвращения чрезмерно высокой или 
чрезмерно низкой температуры на борту, с учетом особенностей перевозимого вида 
животных. Кроме того, принимаются меры к тому, чтобы избежать резких колебаний 
давления воздуха.</p><br>

<p style="font-weight:500;">Статья 37</p><br>

<p style="text-indent:2em;">На грузовых воздушных судах при необходимости должно быть соответствующее 
приспособление для забоя животных, утвержденное компетентными органами.</p>

<p></p><h4>Глава III – Домашняя птица и домашние кролики</h4><p></p>

<p style="font-weight:500;">Статья 38</p><br>

<p style="text-indent:2em;">В случае перевозки домашней птицы и домашних кроликов с соответствующими 
изменениями применяются следующие положения Главы II: статья 6, пункты 1-3, 
статьи 7, 13-17 включительно, 21, 22, 25-30 включительно, 32, 34-36 
включительно.</p><br>

<p style="font-weight:500;">Статья 39</p><br>

<p style="text-indent:2em;">1. Больные или травмированные животные не считаются годными к перевозке. 
Любому заболевшему или травмированному животному как можно скорее оказывают 
первую помощь и при необходимости подвергают ветеринарному осмотру.</p><br>

<p style="text-indent:2em;">2. При погрузке животных в контейнеры, размещенные один на другом, или в вагон, 
или транспортное средство с более чем одним ярусом, принимаются необходимые меры 
предосторожности, чтобы помет не попадал на животных, размещенных внизу.</p>

<p style="text-indent:2em;"></p><h6>3. Обеспечивается достаточное количество соответствующего корма и при 
необходимости воды, кроме тех случаев, когда:</h6><p></p>

<p>а) перевозка продолжается менее 12 часов;</p><br>

<p style="text-indent:2em;">b) перевозка продолжается менее 24 часов в случае цыплят всех видов, при условии, 
что она завершается в пределах 72 часов после выведения.</p>

<p></p><h4>Глава IV – Домашние собаки и домашние кошки</h4><p></p>

<p style="font-weight:500;">Статья 40</p><br>

<p style="text-indent:2em;">1. Положения настоящей Главы распространяются на перевозку домашних собак и 
домашних кошек, за исключением случаев, когда они сопровождаются владельцем 
или его представителем.</p><br>

<p style="text-indent:2em;">2. В случае перевозки домашних собак и домашних кошек с соответствующими изменениями 
применяются следующие положения Главы II: статья 4, статья 6, пункты 1-3 включительно, 
статьи 7, 9, 10, статья 11, пункты 1 и 3, статьи 12-17 включительно, 20-23 включительно, 
25- 29 включительно и 31-37 включительно.</p><br>

<p style="font-weight:500;">Статья 41</p><br>

<p style="text-indent:2em;">Перевозимые животные должны получать корм не реже чем через 24 часа, а воду - не 
реже чем через 12 часов. Должны иметься четкие письменные инструкции о кормлении 
и подаче воды. Суки в период течки должны быть отделены от кобелей.</p>

<p></p><h4>Глава V – Прочие млекопитающие и птицы</h4><p></p>

<p style="font-weight:500;">Статья 42</p><br>

<p style="text-indent:2em;">1. Положения настоящей Главы распространяются на перевозку тех млекопитающих и 
птиц, которые еще не охвачены положениями предыдущих глав.</p><br>

<p style="text-indent:2em;">2. В отношении перевозки видов, охватываемых настоящей Главой, применяются с 
соответствующими изменениями следующие положения Главы II: статьи 4 и 5, статья 6, 
пункты 1-3 включительно, статьи 7-10 включительно, статья 11, пункты 1 и 3, статьи 
12-17 включительно, 20-37 включительно.</p><br>

<p style="font-weight:500;">Статья 43</p><br>

<p style="text-indent:2em;">Животные перевозятся только в соответствующим образом сконструированных транспортных 
средствах или контейнерах, на которых при необходимости должно быть указано, что в них 
находятся пугливые или опасные дикие животные. Кроме того, должны иметься четкие 
письменные инструкции о питании и подаче воды и о любом необходимом особом уходе.</p><br>

<p style="font-weight:500;">Статья 44</p><br>

<p>Животные, имеющие рога, не перевозятся в период "бархата", если не приняты особые 
меры предосторожности.</p><br>

<p style="font-weight:500;">Статья 45</p><br>

<p style="text-indent:2em;">Животные, о которых говорится в настоящей Главе, обеспечиваются уходом в соответствии 
с инструкциями, упомянутыми в статье 43.</p>

<p></p><h4>Глава VI – Холоднокровные животные</h4><p></p>

<p style="font-weight:500;">Статья 46</p><br>

<p style="text-indent:2em;">Холоднокровные животные перевозятся в таких контейнерах и в таких условиях, в частности 
в том, что касается помещений, вентиляции и температуры, и с таким запасом воды и кислорода, 
которые считаются подходящими для данного вида. Они перевозятся к месту назначения как можно 
скорее.</p>

<p></p><h4>Глава VII – Урегулирование споров</h4><p></p>

<p style="font-weight:500;">Статья 47</p><br>

<p style="text-indent:2em;">1. В случае спора, касающегося толкования или применения положений настоящей Конвенции, 
компетентные органы соответствующих Договаривающихся Сторон проводят взаимные консультации. 
Каждая Договаривающаяся Сторона сообщает Генеральному секретарю Совета Европы название и адрес 
своих компетентных органов.</p><br>

<p style="text-indent:2em;">2. Если спор не был урегулирован таким образом, по просьбе одной из сторон спора он передается 
в арбитраж. Каждая сторона назначает арбитра, и два арбитра назначают третейского судью. Если одна 
из двух сторон спора не назначила своего арбитра в течение трех месяцев после просьбы об арбитраже, 
он назначается по просьбе другой стороны спора Председателем Европейского суда по правам человека. 
Если последний является гражданином страны - одной из сторон спора, то эти обязанности выполняются 
заместителем Председателя Суда или, если заместитель Председателя является гражданином 
страны - одной из сторон спора, старшим по возрасту членом Суда, не являющимся 
гражданином страны какой-либо из сторон спора. Та же процедура применяется в 
том случае, если арбитры не могут достичь согласия в отношении выбора 
третейского судьи.</p><br>

<p style="text-indent:2em;">3. Арбитражный трибунал утверждает свою собственную процедуру. Его решения принимаются
 большинством голосов. Его постановление, которое основывается на настоящей Конвенции,
 является окончательным.</p>

<p></p><h4>Глава VIII – Заключительные положения</h4><p></p>

<p style="font-weight:500;">Статья 48</p><br>

<p style="text-indent:2em;">1. Конвенция открыта для подписания государствами – членами Совета Европы.
 Она подлежит ратификации или принятию. Ратификационные грамоты или документы 
 о принятии сдаются на хранение Генеральному секретарю Совета Европы.</p><br>

<p style="text-indent:2em;">2. Настоящая Конвенция вступает в силу по истечении шести месяцев со дня сдачи
 на хранение четвертой ратификационной грамоты или документа о принятии.</p><br>

<p style="text-indent:2em;">3. В отношении какого-либо государства, подписавшего Конвенцию и впоследствии
 ратифицировавшего или принявшего ее, Конвенция вступает в силу по истечении 
 шести месяцев со дня сдачи на хранение его ратификационной грамоты или 
 документа о принятии.</p><br>

<p style="font-weight:500;">Статья 49</p><br>

<p style="text-indent:2em;">1. После вступления в силу настоящей Конвенции Комитет министров Совета Европы
 может пригласить любое государство, не являющееся членом Совета Европы, 
 присоединиться к настоящей Конвенции.</p><br>

<p style="text-indent:2em;">2. Такое присоединение осуществляется путем сдачи на хранение Генеральному 
секретарю Совета Европы документа о присоединении, и это присоединение вступает 
в силу по истечении шести месяцев со дня сдачи документа на хранение.</p><br>

<p style="font-weight:500;">Статья 50</p><br>

<p style="text-indent:2em;">1. Любая Договаривающаяся Сторона в момент подписания или сдачи на хранение 
своей ратификационной грамоты или документа о принятии или присоединении может
 указать территорию или территории, к которым будет применяться настоящая 
 Конвенция.</p><br>

<p style="text-indent:2em;">2. Любая Договаривающаяся Сторона при сдаче на хранение ратификационной грамоты 
или документа о принятии или присоединении или в любой момент впоследствии может
 путем направления заявления на имя Генерального секретаря Совета Европы распространить
 действие настоящей Конвенции на любую другую территорию или территории, которые 
 указаны в этом заявлении и за международные отношения которых она несет 
 ответственность или от имени которых она уполномочена принимать на себя 
 обязательства.</p><br>

<p style="text-indent:2em;">3. Любые заявления, сделанные в соответствии с предыдущим пунктом в отношении 
любой территории, упомянутой в таком заявлении, могут быть отозваны в соответствии
 с процедурой, предусмотренной в статье 51 настоящей Конвенции.</p><br>

<p style="font-weight:500;">Статья 51</p><br>

<p>1. Настоящая Конвенция действует в течение неопределенного времени.</p><br>

<p style="text-indent:2em;">2. Любая Договаривающаяся Сторона может, в той мере, в какой это ее касается,
 денонсировать настоящую Конвенцию путем направления уведомления на имя Генерального
 секретаря Совета Европы.</p><br>

<p style="text-indent:2em;">3. Такая денонсация вступает в силу по истечении шести месяцев со дня получения
 такого уведомления Генеральным секретарем.</p><br>

<p style="font-weight:500;">Статья 52</p>

<p style="text-indent:2em;"></p><h6>1. Генеральный секретарь Совета Европы уведомляет государства - члены Совета и
 любое государство, которое присоединилось к настоящей Конвенции, о:</h6><p></p>

<p>а) любом подписании;</p><br>

<p>b) любой сдаче на хранение ратификационной грамоты или документа о принятии
 или присоединении;</p><br>

<p>с) любой дате вступления настоящей Конвенции в силу в соответствии с ее 
статьей 48;</p><br>

<p>d) любом заявлении, полученном в соответствии с пунктами 2 и 3 статьи 50;</p><br>

<p style="text-indent:2em;">е) любом уведомлении, полученном в соответствии с положениями статьи 51,
 и о дате вступления денонсации в силу;</p><br>

<p>f) любом сообщении, полученном в соответствии с пунктом 1 статьи 47.</p><br>

<p style="text-indent:2em;">В УДОСТОВЕРЕНИЕ ЧЕГО нижеподписавшиеся, должным образом на то уполномоченные,
 подписали настоящую Конвенцию.</p><br>

<p style="text-indent:2em;">СОВЕРШЕНО в Париже 13 декабря 1968 года на английском и французском языках, 
причем оба текста имеют одинаковую силу, в одном экземпляре, который будет 
храниться в архивах Совета Европы. Генеральный секретарь Совета Европы направляет 
заверенные копии каждому из государств, подписавших настоящую Конвенцию и 
присоединившихся к ней.</p><br>

<p style="text-indent:2em;">Дополнительный протокол к Европейской конвенции о защите животных при 
международной перевозке Страсбург, 10 мая 1979 года</p><br>

<p>(Официальный перевод Российской Федерации для подготовки к ратификации)</p>

<p style="text-indent:2em;"></p><h6>Государства-члены Совета Европы, подписавшие настоящий Дополнительный 
протокол, принимая во внимание Европейскую конвенцию о защите животных 
при международной перевозке, ниже именуемую "Конвенцией", которая была 
открыта для подписания в Париже 13 декабря 1968 года государствами-членами
 Совета Европы и содержит общие положения, направленные на то, чтобы защитить
 животных от страданий при перевозке, считая, что с учетом компетенции, которой 
 эта организация обладает в вопросах, охватываемых указанной Конвенцией, Европейское 
 экономическое сообщество должно иметь возможность стать Договаривающейся Стороной данного
 документа, договорились о следующем:</h6><p></p>

<p style="font-weight:500;">Статья 1</p><br>

<p style="text-indent:2em;">Статья 48 Конвенции дополняется следующим пунктом: "4. Европейское экономическое
 сообщество может стать Договаривающейся Стороной настоящей Конвенции путем ее
 подписания. Конвенция вступает в силу в отношении Сообщества по истечении
 шести месяцев со дня ее подписания".</p><br>

<p style="font-weight:500;">Статья 2</p><br>

<p style="text-indent:2em;">В статье 52 Конвенции слова "любое государство, которое присоединилось к
 настоящей Конвенции" заменяются словами "любая Договаривающаяся Сторона,
 которая не является членом Совета".</p><br>

<p style="font-weight:500;">Статья 3</p><br>

<p style="text-indent:2em;">Пункт 2 статьи 47 Конвенции дополняется следующим подпунктом: "В случае
 спора между двумя Договаривающимися Сторонами, одна из которых является
 государством - членом Европейского экономического сообщества, которое,
 в свою очередь, является Договаривающейся Стороной, другая Договаривающаяся
 Сторона направляет просьбу об арбитраже как упомянутому государству-члену,
 так и Сообществу, которые в течение трех месяцев с момента получения просьбы
 совместно уведомляют его о том, являются ли государство-член или Сообщество
 или государство-член и Сообщество совместно стороной в споре. В отсутствие в 
 указанный срок такого уведомления государство-член и Сообщество рассматриваются
 как одна и та же сторона в споре для целей применения положений, регулирующих
 порядок создания и процедуру арбитражного трибунала. То же самое применяется,
 когда государство-член и Сообщество совместно выступают как сторона в споре".</p><br>

<p style="font-weight:500;">Статья 4</p>

<p style="text-indent:2em;"></p><h6>1. Настоящий Дополнительный протокол открыт для подписания государствами - 
членами Совета Европы, подписавшими Конвенцию, которые могут стать Договаривающимися
 Сторонами настоящего Дополнительного протокола путем:</h6><p></p>

<p>а) подписания без оговорки в отношении ратификации, принятия или
 одобрения или</p><br>

<p style="text-indent:2em;">b) подписания с оговоркой в отношении ратификации, принятия или
 одобрения с последующей ратификацией, принятием или одобрением.</p><br>

<p style="text-indent:2em;">2. Государства, которые присоединились к Конвенции, могут также
 присоединиться к настоящему Дополнительному протоколу.</p><br>

<p style="text-indent:2em;">3. Ратификационные грамоты, документы о принятии, одобрении или
 присоединении сдаются на хранение Генеральному секретарю Совета
 Европы.</p><br>

<p style="font-weight:500;">Статья 5</p><br>

<p style="text-indent:2em;">Настоящий Дополнительный протокол вступает в силу, как только все
 Договаривающиеся Стороны Конвенции становятся Сторонами настоящего
 Дополнительного протокола в соответствии со статьей 4.</p><br>

<p style="font-weight:500;">Статья 6</p><br>

<p style="text-indent:2em;">Настоящий Дополнительный протокол является неотъемлемой частью
 Конвенции со дня его вступления в силу. С этой даты ни одно государство
 -член не может стать Договаривающейся Стороной Конвенции, не становясь
 одновременно Договаривающейся Стороной настоящего Дополнительного протокола.</p><br>

<p style="font-weight:500;">Статья 7</p>

<p style="text-indent:2em;"></p><h6>Генеральный секретарь Совета Европы уведомляет государства - члены Совета
 Европы, другие Стороны Конвенции и Комиссию Европейского экономического сообщества о:</h6><p></p>

<p>а) любом подписании без оговорки в отношении ратификации, принятия или
 одобрения;</p><br>

<p>b) любом подписании с оговоркой в отношении ратификации, принятия или
 одобрения;</p><br>

<p>с) любой сдаче на хранение ратификационной грамоты или документа о принятии
, одобрении или присоединении;</p><br>

<p>d) любой дате вступления настоящего Дополнительного протокола в силу
 в соответствии с его статьей 5.</p><br>

<p style="text-indent:2em;">В удостоверение чего нижеподписавшиеся, должным образом на то уполномоченные,
 подписали настоящий Протокол.</p><br>

<p style="text-indent:2em;">Совершено в Страсбурге 10 мая 1979 года на английском и французском языках,
 причем оба текста имеют одинаковую силу, в одном экземпляре, который будет 
 храниться в архивах Совета Европы. Генеральный секретарь Совета Европы направляет 
 заверенные копии каждому из государств, подписавших настоящий Протокол или 
 присоединившихся к нему.</p><br>
 
 <p><a href="https://online.zakon.kz/Document/?doc_id=30243237">Источник</a></p>

</div>
`