import { endOfMonth } from 'date-fns'
import moment from 'moment'

export const setFormDatePart = (basePropName, strOldDate, change, { month, year, day }) => {
  const [yy, mm, dd] = strOldDate.split('-').map(x => +x)
  year = year || yy
  month = month || mm
  day = day || dd
  const dt = new Date(year, month - 1, day)
  if (dt.getMonth() !== month - 1) {
    day = endOfMonth(new Date(year, month - 1, 1)).getDate()
    change(`${basePropName}.day`, day)
    change(`${basePropName}.month`, month)
  }

  change(`${basePropName}.date`, moment([year, month - 1, day]).format('YYYY-MM-DD'))
}

export const setFormDate = (basePropName, change, newDate) => {
  const d = moment(newDate)
  change(`${basePropName}.date`, d.format('YYYY-MM-DD'))
  change(`${basePropName}.day`, newDate.getDate())
  change(`${basePropName}.month`, newDate.getMonth() + 1)
  change(`${basePropName}.year`, newDate.getFullYear())
}

export const stopBubble = event => {
  event.preventDefault()
  event.stopPropagation()
}
