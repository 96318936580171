import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reduxForm } from 'redux-form'
import withTranslation from '../../../globals/components/withTranslation'
import Component from './component'
import { register } from '../../../store/actions/auth'

const initialValues = {
  username: undefined,
  password: undefined,
  password2: undefined,
  email: undefined,
  confirm: undefined,
}

export default compose(
  withTranslation,
  connect(null, {
    register,
  }),
  reduxForm({
    form: 'userRegistration',
    initialValues,
    touchOnChange: true,
    touchOnBlur: false,
    onSubmit: (values, dispatch, props) =>
      dispatch(
        register(values, () => {
          props.history.push('/auth/registration/success')
        })
      ),
  })
)(Component)
