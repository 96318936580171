import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import TooltipWrapper from '../../UI/popover-wrapper'
import TooltipModal from '../../search/table/tooltip-modal'
import { SingleReviewProps } from './types'
import Rating from '../../UI/star-rating'
import fetch from '../../shared/fetch'
import { toastr } from 'react-redux-toastr'
import { canDo } from '../../../store/utils'
import classNames from 'classnames'

import './styles.scss'
export default class SingleReview extends React.Component<SingleReviewProps> {
  state = {
    delReviewID: 0,
  }

  renderDelimitedMessage = (message: string) => {
    return message.split('\n').map((part, id) => (
      <p key={id} style={{ wordBreak: 'break-word' }}>
        {part}
      </p>
    ))
  }

  deleteReview = () => {
    const { currentDeleteReviewId, refreshData, reviewingCompanyId, review: { answeredReview }, reCalcCounter } = this.props
    const baseUrl = `/company/${reviewingCompanyId}/review/${currentDeleteReviewId}`
    const url = !answeredReview ? baseUrl : `${baseUrl}/answer`

    fetch(url, { method: 'DELETE' })
      .then(res => {
        if (res.ok) {
          reCalcCounter()
          refreshData()
        }
      })
      .catch(err => toastr.error(err.message))
  }

  renderDeleteReviewPopup = (t, resetDeleteReviewId) => {
    return (
      <div className="popup-remove-comment">
        <div className="remove-container">
          <h1>{t('remove.review.note')}</h1>
          <button
            type="button"
            className="button button-orange"
            onClick={() => this.deleteReview()}
          >
            {t('delete')}
          </button>
          <button
            type="button"
            onClick={() => resetDeleteReviewId()}
            className="button button-orange button-blue"
          >
            {t('button.close')}
          </button>
          <div className="remove-note review-note">{t('review.note')}</div>
        </div>
      </div>
    )
  }

  renderDeleteReviewBtn = (reviewId, reviewerUserId = 0) => {
    const { delReviewID, t } = this.props
    return (
      <Link to="#" className="link-message" onClick={() => delReviewID(reviewId, reviewerUserId)}>
        {t('review.delete')}
      </Link>
    )
  }

  render() {
    const {
      t,
      review: { reviewId, grade, reviewerName, answerFor, reviewerId, datePosted, title, review, answerDate, answeredReview },
      reviewingCompanyId,
      userId,
      setResponsing,
      delReviewID,
      currentDeleteReviewId,
      resetDeleteReviewId,
      permissions,
      isCompanyAdmin,
      isOwnerCompanyId
    } = this.props

    const canDeleteReviews = canDo(permissions, 'profile', 'deleteReview')
    return (
      <div className="panel-content-row review-content">
        {currentDeleteReviewId === reviewId && this.renderDeleteReviewPopup(t, resetDeleteReviewId)}

        {reviewerName && (
          <div className="panel-message">
            <p className="title">{title}</p>
            <div className="panel-message-header">
              <Rating averageRating={grade} />
              <div className="review-author-container">
                <span>{t('review.author')}: </span>
                <TooltipWrapper
                  text={<TooltipModal companyId={0} userId={reviewerId} />}
                  hover
                  innerClassName="full-tooltip"
                  disabled={false}
                  parentCustomClass="move-up-container"
                >
                  <Link to={`/user/details/${reviewerId}`} className="name">
                    {reviewerName}
                  </Link>
                </TooltipWrapper>
              </div>
              <span className="meta-gray">{moment(datePosted).format('DD/MM/YYYY HH:mm')}</span>
            </div>
            <div className="panel-message-content">{review}</div>
            {answeredReview && (
              <div className="panel-message-gray review-asnwer">
                <p className="title-sm review-answer-date">
                  {moment(answerDate).format('DD/MM/YYYY HH:mm')}
                </p>
                {answeredReview}
              </div>
            )}
          </div>
        )}

        {canDeleteReviews
          ? this.renderDeleteReviewBtn(reviewId, Number(reviewerId))
          : userId === Number(reviewerId) && this.renderDeleteReviewBtn(reviewId, userId)}

        {Number(isOwnerCompanyId) === Number(reviewingCompanyId) && (
          <Link
            to="#"
            className="link-message"
            onClick={() =>
              answeredReview
                ? delReviewID(reviewId, userId)
                : setResponsing({ reviewId, reviewTitle: title })
            }
          >
            {answeredReview ? t('review.answer.delete') : t('review.answer')}
          </Link>
        )}
      </div>
    )
  }
}
