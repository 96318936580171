export const int10 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Международная конвенция о Гармонизированной системе описания и кодирования товаров</h4><p></p>

<p></p><h4>(Брюссель, 14 июня 1983 г.)</h4><p></p>

<p></p><h4>(с изменениями от 24 июня 1986 г.)</h4><p></p>

<p style="font-weight:500;">Преамбула</p><br>

<p style="font-weight:500;">Статья 1. Определения</p><br>

<p style="font-weight:500;">Статья 2. Приложение</p><br>

<p style="font-weight:500;">Статья 3. Обязательства Договаривающихся Сторон</p><br>

<p style="font-weight:500;">Статья 4. Частичное применение Гармонизированной системы развивающимися странами</p><br>

<p style="font-weight:500;">Статья 5. Техническое содействие развивающимся странам</p><br>

<p style="font-weight:500;">Статья 6. Комитет по Гармонизированной системе</p><br>

<p style="font-weight:500;">Статья 7. Функции Комитета</p><br>

<p style="font-weight:500;">Статья 8. Роль Совета</p><br>

<p style="font-weight:500;">Статья 9. Ставки таможенных пошлин</p><br>

<p style="font-weight:500;">Статья 10. Урегулирование споров</p><br>

<p style="font-weight:500;">Статья 11. Правовая основа участия в качестве Договаривающейся Стороны Конвенции</p><br>

<p style="font-weight:500;">Статья 12. Участие в Конвенции в качестве Договаривающейся Стороны</p><br>

<p style="font-weight:500;">Статья 13. Вступление в силу</p><br>

<p style="font-weight:500;">Статья 14. Применение положений Конвенции зависимыми территориями</p><br>

<p style="font-weight:500;">Статья 15. Денонсация</p><br>

<p style="font-weight:500;">Статья 16. Процедура внесения поправок</p><br>

<p style="font-weight:500;">Статья 17. Права Договаривающихся Сторон в отношении Гармонизированной системы</p><br>

<p style="font-weight:500;">Статья 18. Оговорки</p><br>

<p style="font-weight:500;">Статья 19. Уведомления Генеральным Секретарем</p><br>

<p style="font-weight:500;">Статья 20. Регистрация в Организации Объединенных Наций</p><br>

<p style="font-weight:500;">Преамбула</p><br>

<p style="text-indent:2em;">Договаривающиеся Стороны настоящей Конвенции, разработанной под руководством Совета по таможенному 
сотрудничеству,</p><br>

<p>желая содействовать международной торговле,</p><br>

<p style="text-indent:2em;">желая упростить сбор, сопоставление и анализ статистических данных, в особенности относящихся к 
международной торговле,</p><br>

<p style="text-indent:2em;">желая уменьшить расходы, вызываемые повторным описанием, классификацией и кодированием товаров при их 
переходе из одной системы классификации в другую в процессе международной торговли, и способствовать 
стандартизации внешнеторговой документации и передаче статистических данных,</p><br>

<p style="text-indent:2em;">считая, что технологические сдвиги и изменения в структуре международной торговли обусловливают необходимость 
внесения существенных изменений в Конвенцию о Номенклатуре для классификации товаров в таможенных тарифах, 
заключенную в Брюсселе 15 декабря 1950 г.,</p><br>

<p style="text-indent:2em;">считая также, что степень детализации, отвечающая требованиям таможенных органов, а также требованиям, 
предъявляемым к статистике со стороны правительств и торговых кругов, вышла далеко за рамки того, что 
обеспечивается номенклатурой, прилагаемой к указанной Конвенции,</p><br>

<p>признавая важность точных и сопоставимых данных для ведения международных торговых переговоров,</p><br>

<p style="text-indent:2em;">считая, что Гармонизированную систему целесообразно использовать для транспортных тарифов и для статистики 
грузоперевозок различными видами транспорта,</p><br>

<p style="text-indent:2em;">считая, что Гармонизированная система предназначена для возможно более широкого использования в других 
системах описания и кодирования товаров.</p><br>

<p style="text-indent:2em;">считая, что Гармонизированная система призвана способствовать установлению возможно более тесной 
взаимосвязи между статистикой внешней торговли и статистикой производства,</p><br>

<p style="text-indent:2em;">считая, что между Гармонизированной системой и Стандартной международной торговой классификацией (СМТК) 
Организации Объединенных Наций должна быть сохранена тесная взаимосвязь,</p><br>

<p style="text-indent:2em;">считая желательным удовлетворение указанных выше потребностей посредством применения единой таможенно-
статистической номенклатуры, пригодной для использования различными кругами, связанными с международной 
торговлей,</p><br>

<p style="text-indent:2em;">считая важным, чтобы в Гармонизированной системе учитывались технологические сдвиги или изменения в 
структуре международной торговли,</p><br>

<p style="text-indent:2em;">принимая во внимание работу, проделанную в этой области Комитетом по Гармонизированной системе, 
созданным Советом по таможенному сотрудничеству,</p><br>

<p style="text-indent:2em;">считая, что поскольку указанная выше Конвенция о Номенклатуре оказалась эффективным инструментом для 
достижения некоторых этих целей, то лучшим средством для достижения желаемых результатов является 
заключение новой международной конвенции,</p>

<p style="text-indent:2em;"></p><h6>договорились о нижеследующем:</h6><p></p>

<p style="font-weight:500;">Статья 1. Определения</p>

<p style="text-indent:2em;"></p><h6>Для целей настоящей Конвенции:</h6><p></p>

<p style="text-indent:2em;">а) под "Гармонизированной системой описания и кодирования товаров", именуемой ниже "Гармонизированная 
система", понимается Номенклатура, включающая в себя товарные позиции, субпозиции и относящиеся к ним 
цифровые коды, примечания к разделам, группам и субпозициям, а также основные правила классификации 
для толкования Гармонизированной системы, приведенные в приложении к настоящей Конвенции;</p><br>

<p style="text-indent:2em;">b) под "номенклатурой таможенных тарифов" понимается номенклатура, разработанная в соответствии с 
законодательством Договаривающейся Стороны для взимания таможенных пошлин при импорте товаров;</p><br>

<p style="text-indent:2em;">с) под "статистической номенклатурой" понимаются номенклатуры товаров, разработанные Договаривающейся 
Стороной для сбора статистических данных по экспорту и импорту товаров;</p><br>

<p style="text-indent:2em;">d) под "единой таможенно-статистической номенклатурой" понимается номенклатура, объединяющая 
номенклатуру таможенных тарифов и статистическую номенклатуру, которую по законодательству 
Договаривающаяся Сторона требует использовать при декларации товаров при импорте;</p><br>

<p style="text-indent:2em;">е) под "Конвенцией о создании Совета" понимается Конвенция о создании Совета по таможенному 
сотрудничеству, заключенная в Брюсселе 15 декабря 1950 г.;</p><br>

<p>f) под "Советом" понимается Совет по таможенному сотрудничеству, указанный в параграфе (е);</p><br>

<p>g) под "Генеральным Секретарем" понимается Генеральный Секретарь Совета;</p><br>

<p>h) под "ратификацией" понимается собственно ратификация, принятие или одобрение.</p><br>

<p style="font-weight:500;">Статья 2. Приложение</p><br>

<p style="text-indent:2em;">Приложение к настоящей Конвенции является ее неотъемлемой частью, и любая ссылка на эту Конвенцию равно 
означает и ссылку на это приложение.</p><br>

<p style="font-weight:500;">Статья 3. Обязательства Договаривающихся Сторон</p>

<p style="text-indent:2em;"></p><h6>1. С учетом исключений, перечисленных в статье 4:</h6><p></p>

<p style="text-indent:2em;"></p><h6>а) каждая Договаривающаяся Сторона Конвенции обязуется, за исключением случаев применения положений пункта 
(с) настоящего параграфа, что ее таможенно-статистические номенклатуры будут находиться в соответствии с 
Гармонизированной системой с момента вступления настоящей Конвенции в силу по отношению к этой стороне. 
Тем самым она обязуется по отношению к своей номенклатуре таможенных тарифов и статистической 
номенклатуре:</h6><p></p>

<p style="text-indent:2em;">i) использовать все товарные позиции и субпозиции Гармонизированной системы, а также относящиеся к ним 
цифровые коды без каких-либо дополнений или изменений;</p><br>

<p style="text-indent:2em;">ii) применять основные правила классификации для толкования Гармонизированной системы, а также все примечания 
к разделам, группам и субпозициям и не изменять объем разделов, групп, товарных позиций и субпозиций 
Гармонизированной системы;</p><br>

<p>iii) соблюдать порядок кодирования, принятый в Гармонизированной системе;</p><br>

<p style="text-indent:2em;">b) каждая Договаривающаяся Сторона Конвенции также опубликует свои статистические данные по импорту и экспорту 
товаров в соответствии с шестизначным кодом Гармонизированной системы или, по желанию этой стороны, в соответствии 
с более детальной классификацией, если подобные данные не являются коммерческой тайной и не наносят ущерба 
интересам государственной безопасности;</p><br>

<p style="text-indent:2em;">с) ничто в настоящей статье не обязывает Договаривающуюся Сторону использовать субпозиции Гармонизированной 
системы в своей номенклатуре таможенных тарифов при условии, что его единая таможенно-статистическая номенклатура 
соответствует требованиям, указанным в пунктах а) i/, а) ii/ и а) iii/.</p><br>

<p style="text-indent:2em;">2. В соответствии с требованиями параграфа 1(а) настоящей статьи каждая Договаривающаяся Сторона может вносить в
 текст изменения, необходимые для того, чтобы Гармонизированная система была отражена в национальном 
 законодательстве.</p><br>

<p style="text-indent:2em;">3. Ничто в настоящей статье не запрещает Договаривающейся Стороне создавать в своей номенклатуре таможенных 
тарифов или статистической номенклатуре подразделы для классификации товаров по большему числу знаков, чем в 
Гармонизированной системе, при условии, что эти подразделы будут добавлены и кодированы сверх шестизначного 
цифрового кода, приведенного в приложении к настоящей Конвенции.</p><br>

<p style="font-weight:500;">Статья 4. Частичное применение Гармонизированной системы развивающимися странами</p><br>

<p style="text-indent:2em;">1. Любая Договаривающаяся Сторона, являющаяся развивающейся страной, может отсрочить применение всех или части 
субпозиций Гармонизированной системы на такой период, который может потребоваться, учитывая структуру ее внешней 
торговли и хозяйственные возможности.</p><br>

<p style="text-indent:2em;">2. Любая Договаривающаяся Сторона, являющаяся развивающейся страной, которая частично применяет Гармонизированную 
систему в соответствии с настоящей статьей, соглашается принять все меры для применения шестизначной Гармонизированной 
системы в полном объеме в течение пяти лет, считая с даты, с которой настоящая Конвенция вступает в силу в отношении 
этой страны, или в любой другой срок, который она сочтет необходимым, принимая во внимание положения параграфа 1 
настоящей статьи.</p><br>

<p style="text-indent:2em;">3. Любая Договаривающаяся Сторона, являющаяся развивающейся страной, которая частично применяет гармонизированную 
систему в соответствии с положениями настоящей статьи, применяет либо все субпозиции с двумя дефисами, относящиеся 
к одной и той же субпозиции с одним дефисом, либо ни одну из них, а также либо все субпозиции с одним дефисом, 
относящиеся к одной и той же товарной позиции, либо ни одну из них. В таких случаях шестой знак или пятый и шестой 
знаки, соответствующие неиспользуемой части кода Гармонизированной системы, заменяются "0" или "00" 
соответственно.</p><br>

<p style="text-indent:2em;">4. Развивающаяся страна, которая частично применяет Гармонизированную систему в соответствии с положениями настоящей 
статьи, становясь Договаривающейся Стороной, уведомляет Генерального Секретаря о субпозициях, которые она не будет 
применять на дату, с которой настоящая Конвенция вступает в силу в отношении нее, и уведомляет его также о субпозициях, 
которые она будет применять впоследствии.</p><br>

<p style="text-indent:2em;">5. Развивающаяся страна, которая частично применяет Гармонизированную систему в соответствии с положениями настоящей 
статьи, становясь Договаривающейся Стороной, может уведомить Генерального Секретаря, что она официально обязуется применять 
шестизначную Гармонизированную систему в полном объеме через три года, считая с даты, с которой настоящая Конвенция вступает 
в силу в отношении нее.</p><br>

<p style="text-indent:2em;">6. Любая Договаривающаяся Сторона, являющаяся развивающейся страной, которая частично применяет Гармонизированную систему 
в соответствии с положениями настоящей статьи, освобождается от обязательств, вытекающих из статьи 3, в том, что касается 
субпозиций, которые она не применяет.</p><br>

<p style="font-weight:500;">Статья 5. Техническое содействие развивающимся странам</p><br>

<p style="text-indent:2em;">Договаривающаяся Сторона, являющаяся развитой страной, оказывает развивающимся странам по их просьбе техническое 
содействие на взаимно согласованных условиях, в частности в области подготовки кадров, приведения их национальных 
номенклатур в соответствие с Гармонизированной системой, рекомендаций по ведению таких номенклатур, учитывая вносимые 
поправки и дополнения к гармонизированной системе, а также по применению положений настоящей Конвенции.</p><br>

<p style="font-weight:500;">Статья 6. Комитет по Гармонизированной системе</p><br>

<p style="text-indent:2em;">1. В соответствии с настоящей Конвенцией учреждается Комитет, именуемый Комитетом по Гармонизированной системе и 
состоящий из представителей каждой Договаривающейся Стороны.</p><br>

<p>2. Комитет по Гармонизированной системе собирается по общему правилу не реже двух раз в год.</p><br>

<p style="text-indent:2em;">3. Его заседания созываются Генеральным Секретарем и проводятся в штаб-квартире Совета, если участники Конвенции не
 договариваются о другом месте их проведения.</p><br>

<p style="text-indent:2em;">4. В Комитете по Гармонизированной системе каждая Договаривающаяся Сторона имеет один голос; тем не менее для целей 
настоящей Конвенции и без ущерба для любой будущей конвенции Договаривающиеся Стороны, в качестве которых могут выступать 
Таможенный или Экономический союз, а также одно или несколько государств - членов такого Союза, имеют совместно только один 
голос. Аналогично, когда все государства - члены Таможенного или Экономического союза, который имеет право стать Договаривающейся 
Стороной, в соответствии с положениями статьи 11 (b) становятся Договаривающимися Сторонами, они совместно имеют только один 
голос.</p><br>

<p style="text-indent:2em;">5. Комитет по Гармонизированной системе выбирает своего Председателя, а также одного или нескольких заместителей 
Председателя.</p><br>

<p style="text-indent:2em;">6. Комитет устанавливает свой регламент решением, принимаемым большинством не менее чем в две трети голосов его членов. 
Этот регламент передается на одобрение Совета.</p><br>

<p style="text-indent:2em;">7. Комитет приглашает такие межправительственные организации или другие международные организации, какие сочтет необходимыми 
участвовать в своей работе в качестве наблюдателей.</p><br>

<p style="text-indent:2em;">8. В случае необходимости Комитет создает подкомитеты или рабочие группы с учетом, в частности, положений параграфа 1(а) 
статьи 7 и определяет состав, избирательные права и регламент этих органов.</p><br>

<p style="font-weight:500;">Статья 7. Функции Комитета</p>

<p style="text-indent:2em;"></p><h6>1. С учетом положений статьи 8 Комитет по Гармонизированной
 системе осуществляет следующие функции:</h6><p></p>

<p style="text-indent:2em;">а) предлагает любые поправки к настоящей Конвенции, которые представляются ему желательными, с учетом, в частности, 
потребностей пользователей, изменений в технологии и структуре международной торговли;</p><br>

<p style="text-indent:2em;">b) составляет пояснения, принципы классификации и прочие рекомендации, относящиеся к толкованию Гармонизированной 
системы;</p><br>

<p style="text-indent:2em;">с) разрабатывает рекомендации с целью обеспечения единообразного толкования и применения Гармонизированной 
системы;</p><br>

<p>d) сопоставляет и распространяет информацию, относящуюся к применению Гармонизированной системы;</p><br>

<p style="text-indent:2em;">е) по своей инициативе или по чьей-либо просьбе предоставляет сведения и рекомендации по всем вопросам, относящимся к 
классификации товаров в Гармонизированной системе, Договаривающимся Сторонам, государствам - членам Совета, а также межправительственным 
или другим международным организациям, которые Комитет может счесть необходимыми;</p><br>

<p style="text-indent:2em;">f) на каждой сессии Совета представляет отчет о своей деятельности, включая проекты поправок, пояснений, принципов классификации 
и прочих рекомендаций;</p><br>

<p style="text-indent:2em;">g) осуществляет в отношении Гармонизированной системы все прочие права и функции, которые Совет или Договаривающиеся Стороны 
могут счесть необходимыми.</p><br>

<p style="text-indent:2em;">2. Административные решения Комитета по Гармонизированной системе, которые требуют бюджетных ассигнований, подлежат 
утверждению Советом.</p><br>

<p style="font-weight:500;">Статья 8. Роль Совета</p><br>

<p style="text-indent:2em;">1. Совет рассматривает предложения о поправках к настоящей Конвенции, разработанные Комитетом по Гармонизированной системе, 
и рекомендует их Договаривающимся Сторонам в соответствии с порядком, предусмотренным статьей 16, если только какой-либо член 
Совета, являющийся Договаривающейся Стороной Конвенции, не потребует, чтобы все или часть этих предложений были переданы в Комитет 
на повторное рассмотрение.</p><br>

<p style="text-indent:2em;">2. Пояснения, принципы классификации и прочие рекомендации, относящиеся к толкованию Гармонизированной системы, а также рекомендации, 
имеющие цель обеспечить единообразное толкование и применение Гармонизированной системы, которые были подготовлены в ходе сессии Комитета 
по Гармонизированной системе в соответствии с положениями параграфа 1 статьи 7, считаются утвержденными Советом, если до истечения двух 
месяцев, прошедших после закрытия сессии, ни одна из Договаривающихся Сторон Конвенции не уведомит Генерального Секретаря о том, что она 
требует передать этот вопрос на рассмотрение Совета.</p><br>

<p style="text-indent:2em;">3. Когда на рассмотрение Совета передается какой-либо вопрос в соответствии с положениями параграфа 2 настоящей статьи, Совет утверждает 
указанные пояснения, принципы классификации и прочие рекомендации, если только какой-либо член Совета, который является Договаривающейся 
Стороной Конвенции, не потребует передать их полностью или частично в 
Комитет для повторного рассмотрения.</p><br>

<p style="font-weight:500;">Статья 9. Ставки таможенных пошлин</p><br>

<p style="text-indent:2em;">Договаривающиеся Стороны в соответствии с настоящей Конвенцией не берут на себя никаких обязательств в том, что касается ставок таможенных 
пошлин.</p><br>

<p style="font-weight:500;">Статья 10. Урегулирование споров</p><br>

<p style="text-indent:2em;">1. Споры между Договаривающимися Сторонами относительно толкования или применения настоящей Конвенции разрешаются по мере возможности 
посредством переговоров между ними.</p><br>

<p style="text-indent:2em;">2. Споры, которые не урегулированы таким путем, передаются их участниками в Комитет по Гармонизированной системе, который рассматривает 
споры и дает рекомендации по их урегулированию.</p><br>

<p style="text-indent:2em;">3. Если Комитет по Гармонизированной системе не в состоянии урегулировать спор, он передает его на рассмотрение Совета, который дает 
рекомендации в соответствии со статьей 1(е) Конвенции о создании Совета.</p><br>

<p style="text-indent:2em;">4. Участники спора могут заранее договориться считать рекомендации Комитета или Совета обязательными для 
выполнения.</p><br>

<p style="font-weight:500;">Статья 11. Правовая основа участия в качестве Договаривающейся Стороны Конвенции</p>

<p style="text-indent:2em;"></p><h6>Договаривающимися Сторонами настоящей Конвенции могут стать:</h6><p></p>

<p>а) государства - члены Совета;</p><br>

<p style="text-indent:2em;">b) Таможенные или Экономические союзы, наделенные компетенцией вступать в договоры, предметом которых являются все или некоторые 
вопросы, регулируемые настоящей Конвенцией;</p><br>

<p>с) любое другое государство, которому Генеральный Секретарь направляет приглашение 
по указанию Совета.</p><br>

<p style="font-weight:500;">Статья 12. Участие в Конвенции в качестве Договаривающейся Стороны</p>

<p style="text-indent:2em;"></p><h6>1. Любое государство, Таможенный или Экономический союз, имеющие на это право, могут стать Договаривающимися Сторонами настоящей 
Конвенции:</h6><p></p>

<p>а) подписав ее без оговорки о ратификации;</p><br>

<p>b) вручив ратификационные грамоты после подписания Конвенции с оговоркой о ратификации;</p><br>

<p>с) присоединившись к ней после того, как Конвенция перестала быть открытой для подписания.</p><br>

<p style="text-indent:2em;">2. Настоящая Конвенция открыта для подписания государствами и Таможенными или Экономическими союзами, указанными в статье 11, 
до 31 декабря 1986 г. в штаб-квартире Совета в Брюсселе. После этой даты она 
будет открыта для их присоединения.</p><br>

<p>3. Ратификационные грамоты или документ о присоединении вручаются Генеральному Секретарю.</p><br>

<p style="font-weight:500;">Статья 13. Вступление в силу</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция вступает в силу с 1 января очередного года по истечении не менее трех месяцев после того, как не менее 
семнадцати государств, таможенных или экономических союзов, указанных в статье 11, подпишут ее без оговорки о ратификации или 
передадут на хранение свои документы о ратификации или присоединении, но не ранее 1 января 1988 года.</p><br>

<p style="text-indent:2em;">2. В отношении любого государства, Таможенного или Экономического союза, подписывающих настоящую Конвенцию без оговорки о 
ратификации, ратифицирующих ее или присоединяющихся к ней после того, как число членов Конвенции достигнет минимального числа, 
указанного в параграфе 1 настоящей статьи, настоящая Конвенция вступает в силу с 1 января очередного года по истечении не менее 
12 и не более 24 месяцев после того, как это государство, Таможенный или Экономический союз подпишет Конвенцию без оговорки о 
ратификации или вручит ратификационную грамоту или грамоту о присоединении, если не оговорен более ранний срок. Однако дата вступления 
в силу, вытекающая из положений настоящего параграфа, не может предшествовать дате, предусмотренной в параграфе 1 настоящей 
статьи.</p><br>

<p style="font-weight:500;">Статья 14. Применение положений Конвенции зависимыми территориями</p><br>

<p style="text-indent:2em;">1. Любое государство может либо в момент, когда оно становится Договаривающейся Стороной настоящей Конвенции, либо впоследствии 
уведомить Генерального Секретаря о том, что настоящая Конвенция распространяется на все или на некоторые из территорий, международные 
отношения которых находятся под его ответственностью и которые указаны в этом уведомлении. Это уведомление вступает в силу с 1 января 
очередного года по истечении не менее и не более 24 месяцев с момента его получения Генеральным Секретарем, если в нем не указывается 
более ранний срок. Однако настоящая Конвенция не может применяться по отношению к этим территориям до ее вступления в силу в отношении 
соответствующего государства.</p><br>

<p style="text-indent:2em;">2. Действие настоящей Конвенции в отношении указанной территории прекращается с момента, когда Договаривающаяся Сторона перестает быть 
ответственной за международные отношения этой территории, или с любой более ранней даты, которая может быть сообщена Генеральному 
Секретарю в соответствии с процедурой, предусмотренной в статье 15.</p><br>

<p style="font-weight:500;">Статья 15. Денонсация</p><br>

<p style="text-indent:2em;">Настоящая Конвенция является бессрочной. Однако любая Договаривающаяся Сторона может денонсировать ее, и денонсация вступает в силу 
через год после получения Генеральным Секретарем уведомления о денонсации,
 если в нем не указан более поздний срок.</p><br>

<p style="font-weight:500;">Статья 16. Процедура внесения поправок</p><br>

<p>1. Совет может рекомендовать Договаривающимся Сторонам настоящей Конвенции внести в нее поправки.</p><br>

<p style="text-indent:2em;">2. Любая Договаривающаяся Сторона может уведомить Генерального Секретаря о своем несогласии с рекомендуемыми поправками и может 
впоследствии снять это возражение в срок, предусмотренный параграфом 3 настоящей статьи.</p><br>

<p style="text-indent:2em;">3. Любая рекомендованная поправка считается принятой по истечении шести месяцев с момента уведомления Генеральным Секретарем об 
указанной поправке при условии, что до конца этого периода не было выдвинуто никаких возражений.</p>

<p style="text-indent:2em;"></p><h6>4. Принятые поправки вступают в силу в отношении всех Договаривающихся Сторон в следующие сроки:</h6><p></p>

<p style="text-indent:2em;">а) в случае, когда уведомление о рекомендуемой поправке получено до 1 апреля - с 1 января очередного года по истечении года с 
момента</p><br>

<p>уведомления; или</p><br>

<p style="text-indent:2em;">b) в случае, когда уведомление о рекомендуемой поправке получено 1 апреля или позднее - с 1 января очередного года по истечении двух 
лет с момента уведомления.</p><br>

<p style="text-indent:2em;">5. В срок, указанный в параграфе 4 настоящей статьи, статистические номенклатуры и номенклатура таможенного тарифа каждого участника 
настоящей Конвенции или - в случае, предусмотренном в параграфе 1(с) статьи 3, - его единая таможенно-статистическая номенклатура должны 
быть приведены в соответствие с дополненной Гармонизированной системой.</p><br>

<p style="text-indent:2em;">6. Любое государство, Таможенный или Экономический союз, которые подписывают настоящую Конвенцию без оговорки о ратификации, 
ратифицируют ее или присоединяются к ней, считаются принявшими любые поправки, которые на момент, когда это государство или Союз 
стали Договаривающимися Сторонами, вступили в силу или были приняты в соответствии 
с положениями параграфа 3 настоящей статьи.</p><br>

<p style="font-weight:500;">Статья 17. Права Договаривающихся Сторон в отношении Гармонизированной системы</p>

<p style="text-indent:2em;"></p><h6>По всем вопросам, относящимся к Гармонизированной системе, положения параграфа 4 статьи 6, статьи 8 и параграфа 2 статьи 16 
предоставляют каждой Договаривающейся Стороне права:</h6><p></p>

<p style="text-indent:2em;">а) касающиеся всех разделов Гармонизированной системы, которые она применяет в соответствии с положениями настоящей 
Конвенции;</p><br>

<p style="text-indent:2em;">b) до момента вступления для нее в силу настоящей Конвенции - в соответствии с положениями статьи 13, - касающиеся всех разделов 
Гармонизированной системы, которые она обязана применять на указанную дату в соответствии с положениями настоящей Конвенции;</p><br>

<p style="text-indent:2em;">с) касающиеся всех разделов Гармонизированной системы при условии, что она официально обязалась применять шестизначную Гармонизированную 
систему в полном объеме в течение трехлетнего периода, указанного в параграфе 5 статьи 4, вплоть до его истечения.</p><br>

<p style="font-weight:500;">Статья 18. Оговорки</p><br>

<p>Никакие оговорки к настоящей Конвенции не допускаются.</p><br>

<p style="font-weight:500;">Статья 19. Уведомления Генеральным Секретарем</p>

<p style="text-indent:2em;"></p><h6>Генеральный Секретарь официально уведомляет Договаривающиеся Стороны, другие государства, подписавшие ее, государства - члены Совета, 
не являющиеся Договаривающимися Сторонами настоящей Конвенции, и Генерального Секретаря Организации
 Объединенных Наций:</h6><p></p>

<p>а) об уведомлениях, полученных в соответствии со статьей 4;</p><br>

<p>b) о подписаниях, ратификациях и присоединениях, указанных в статье 12;</p><br>

<p>с) о моменте вступления настоящей Конвенции в силу в
 соответствии со статьей 13;</p><br>

<p>d) об уведомлениях, предусмотренных в статье 14;</p><br>

<p>е) о денонсациях, предусмотренных в статье 15;</p><br>

<p>f) о поправках, вносимых в настоящую Конвенцию и
 предусмотренных в статье 16;</p><br>

<p style="text-indent:2em;">g) о несогласиях с поправками, рекомендуемыми в 
соответствии со статьей 16, а также об их возможном снятии;</p><br>

<p>h) о поправках, принятых в соответствии со статьей 16, а также о моменте их вступления в силу.</p><br>

<p style="font-weight:500;">Статья 20. Регистрация в Организации Объединенных Наций</p><br>

<p style="text-indent:2em;">В соответствии со статьей 102 Устава Организации Объединенных Наций по представлению Генерального Секретаря Совета настоящая Конвенция 
подлежит регистрации в Секретариате ООН.</p><br>

<p style="text-indent:2em;">В удостоверение чего нижеподписавшиеся, должным образом уполномоченные на это лица,
 подписали настоящую Конвенцию.</p><br>

<p style="text-indent:2em;">Совершено в Брюсселе 14 июня 1983 г. на французском и английском языках, причем оба текста имеют одинаковую силу, в одном экземпляре, 
подлежащем сдаче на хранение Генеральному Секретарю Совета, который направит заверенные копии всем государствам и Таможенным 
или Экономическим союзам, указанным в статье 11.</p><br>

<p><a href="http://docs.cntd.ru/document/1901904">Источник</a></p>

</div>
`