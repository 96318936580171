import _ from 'lodash'
import React from 'react'

import { UserNoteProps, UserNoteState } from './types'
import Widget from '../../../../shared/components/widget'
import fetch from '../../../../shared/fetch'

import './styles.scss'

const MAX_NOTE_LENGTH = 1000

export default class UserNote extends React.Component<UserNoteProps, UserNoteState> {
  timer: NodeJS.Timeout
  constructor(props) {
    super(props)

    this.state = {
      noteText: '',
      noteUserId: parseInt(props.match?.params['userId']) || props.userId,
      userFound: false,
    }
  }

  componentDidMount() {
    this.checkUserAndRefresh()
  }

  checkUserAndRefresh = (userId?: number) => {
    fetch(`/user/checkUserExists?userId=${userId || this.state.noteUserId}`).then(res => {
      if (res.ok) {
        this.refreshUserNote(userId)
        this.setState({ userFound: true })
      }
    })
  }

  componentDidUpdate(oldProps: UserNoteProps) {
    const { userId = null } = this.props.match?.params as any
    const { userId: oldUserId = null } = oldProps.match?.params as any

    if ((userId || oldUserId) && parseInt(userId) !== parseInt(oldUserId)) {
      this.checkUserAndRefresh(userId)
    }
  }

  refreshUserNote = (userId?: number) => {
    const { noteUserId } = this.state
    if (!userId) userId = noteUserId
    fetch(`/user/getNote/${userId}`).then(res => {
      this.setState({
        noteText: res.data.noteText,
        noteUserId: userId,
      })
    })
  }

  handleNoteChange = e => {
    const { value } = e.target

    if (value.length <= MAX_NOTE_LENGTH) {
      this.setState({ noteText: value })
      this.saveNewNote(value)
    }
  }

  saveNewNote = (noteText: string) => {
    if (this.timer) {
      clearTimeout(this.timer)
    }

    this.timer = setTimeout(() => {
      fetch('/user/updateNote', {
        method: 'POST',
        body: JSON.stringify({
          noteText,
          noteUserId: this.state.noteUserId,
        }),
      })
    }, 3000)
  }

  render() {
    const { noteText, userFound } = this.state
    const { t } = this.props

    if (userFound) {
      return (
        <Widget title={t('user.notes.header')}>
          <textarea
            value={noteText}
            onChange={this.handleNoteChange}
            placeholder={t('user.notes.placeholder')}
            className="note-area"
          />
        </Widget>
      )
    }

    return ''
  }
}
