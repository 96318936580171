import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import logo from '../../../images/logo.png'
import menuItems from '../../../templates/header-bottom'
import { ModalConsumer, ModalContextState } from '../../../context/modal-context'
import ButtonsNotLogged from './user-not-logged'
import ButtonsLogged from './user-logged'
import LoginLink from '../login-link'
import { hideScrollBar, addScrollBar } from '../../../store/utils'
import { HeaderProps, HeaderState } from './types'

import './styles.scss'
export default class extends Component<HeaderProps, HeaderState> {
  modalConsumer: ModalContextState
  state = {
    menuOpened: false,
  }
  toggleMenu = () => {
    const { menuOpened } = this.state
    const window = document.querySelector('#html')
    if (window.clientWidth <= 768) {
      //768 - is responsive width, when will add styles for responsove
      if (menuOpened) {
        addScrollBar()
      } else {
        hideScrollBar()
      }
    }

    this.setState(prevState => ({ menuOpened: !prevState.menuOpened }))
  }
  getMenuItems = () => {
    const { currentLanguage } = this.props
    return menuItems[currentLanguage.toLowerCase()] || menuItems.ru
  }

  renderMobileNotLoggedContainer = () => {
    return (
      <div className="not-logged-for-mobile user-mob">
        <LoginLink>
          <span className="ico ico-user-stroke2"></span>
        </LoginLink>
      </div>
    )
  }

  showNotAuthModal = (event, url) => {
    const { t } = this.props
    const { showModalComponent, clearModal } = this.modalConsumer

    const text = url === "/cargo/add" ? t('add.cargo.only.auth') : t('add.transport.only.auth')

    const notAuthComponent = (
      <div className="panel-search-wrapper centered-container">
        <div className="panel-search-log centered-item">
          <span className="fa fa-clock-o" />
          <p className="panel-search-text">{text}</p>
          <div className="panel-search-links">
            <LoginLink> {t('login.enter.2')} </LoginLink>
            <span>{t('or')} </span>
            <Link to="/auth/registration" onClick={clearModal}>
              {t('login.register')}
            </Link>
          </div>
          <br />
          {t('login.family')}
        </div>
      </div>
    )

    event.preventDefault()

    showModalComponent(notAuthComponent, {
      className: 'not-auth-modal popup popup-truck',
      backdrop: true,
      skipLayout: true,
    })
  }

  render() {
    const { menuOpened } = this.state
    const { isAuthenticated, enqueueSnackbar } = this.props
    const menuItems = this.getMenuItems()

    return (
      <div>
        <div className="header-bottom">
          <div className="container">
            <span className="menu-icon menu-mob mobile-only" onClick={this.toggleMenu}>
              <span className="fa fa-bars" />
            </span>
            <Link to="/" className="logo-container">
              <img src={logo} alt="Logo transport.md" />
            </Link>
            <div
              className={classNames('menu-modal-overlay menu-mob', { 'is-opened': menuOpened })}
              onClick={this.toggleMenu}
            >
              <ul
                className={classNames('menu menu-nav menu-nav-main', { 'is-opened': menuOpened })}
              >
                {menuItems.map((item, index) => {
                  const { href = '#', name } = item
                  const active = this.props.location.pathname.includes(href)
                  return (
                    <li key={index} className={classNames({ active })}>
                      {active ? (
                        <span>
                          <Link
                            to={href}
                            onClick={(e) => item?.showLoginAlert && !isAuthenticated && this.showNotAuthModal(e, href)}>
                            {name}
                          </Link>
                        </span>
                      ) : (
                        <Link
                          to={href}
                          onClick={(e) => item?.showLoginAlert && !isAuthenticated && this.showNotAuthModal(e, href)} >
                          {name}
                        </Link>
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>
            {!isAuthenticated ? <ButtonsNotLogged /> : <ButtonsLogged {...{ enqueueSnackbar }} />}
            {!isAuthenticated && this.renderMobileNotLoggedContainer()}
          </div>
        </div>
        {isAuthenticated && <div className="header-user mobile-only"></div>}
        <ModalConsumer>
          {modalConsumer => {
            this.modalConsumer = modalConsumer
            return null
          }}
        </ModalConsumer>
      </div>
    )
  }
}
