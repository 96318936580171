import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reduxForm, formValueSelector } from 'redux-form'

import Component from './component'

import withTranslation from '../../../globals/components/withTranslation'
import { changeLanguage, setAvailableLanguages } from '../../../store/actions/ui'
import {
  loadCargoLastSearch,
  loadTransportLastSearch,
} from '../../../store/actions'
import { IReduxStorage } from '../../../store/reducers/types'

const formName = 'header-top'
const formSelector = formValueSelector(formName)

export default compose(
  withTranslation,
  connect(
    (state: IReduxStorage) => ({
      searchText: formSelector(state, 'search') || '',
      isAuthenticated: !!state.auth.accessToken,
      langId: state.ui.langId,
    }),
    {
      changeLanguage,
      setAvailableLanguages,
      loadCargoLastSearch,
      loadTransportLastSearch,
    }
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(Component)
