import withTranslation from '../../../../globals/components/withTranslation'
import Component from './component'
import { SingleClaimProps } from './types'
import { compose } from 'recompose'
import withPermissions from '../../../../globals/components/withPermissions'

export default compose(
  withTranslation,
  withPermissions
)(Component) as React.ComponentClass<SingleClaimProps>
