export const int6 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>CONVENŢIE relativă la contractul de transport internațional 
al mărfurilor pe șosele (C.M.R.)*</h4><p></p>

<p></p><h4>PREAMBUL</h4><p></p>

<p style="text-indent:2em;"></p><h6>Părţile contractante, recunoscând utilitatea reglementării într-un mod uniform a condiţiilor contractului de transport internaţional
	de mărfuri pe şosele, în special în ceea ce priveşte documentele folosite pentru acest transport şi responsabilitatea transportatorului
	au convenit asupra celor ce urmează:</h6><p></p>

<p style="font-weight:500;">CAPITOLUL I
DOMENIUL DE APLICARE</p><br>

<p style="font-weight:500;">Articolul 1</p><br>

<p style="text-indent:2em;">1. Prezenta Convenţie se aplică oricărui contract de transport de mărfuri pe şosele cu vehicule, când locul primirii mărfii şi locul
	prevăzut pentru eliberare, aşa cum sunt indicate în contract, sunt situate în două ţări diferite, dintre care cel puţin una este ţară
	contractantă, independent de domiciliul şi de naţionalitatea participanţilor la contract.</p><br>

<p style="text-indent:2em;">2. În sensul prezentei Convenţii, prin "vehicule" se înţeleg automobilele, vehiculele articulate, remorcile şi semiremorcile, aşa
	cum sunt ele definite în articolul 4 al Convenţiei asupra circulaţiei rutiere din 19 septembrie 1949.</p><br>

<p style="text-indent:2em;">3. Prezenta Convenţie se aplică chiar dacă transporturile intrând în domeniul ei de aplicare sunt efectuate de către state sau
	de către instituţii ori organizaţii guvernamentale.</p>

<p style="text-indent:2em;"></p><h6>4. Prezenta Convenţie nu se aplica:</h6><p></p>

<p>a) transporturilor efectuate pe baza convenţiilor poştale internaţionale;</p><br>

<p>b) transporturilor funerare;</p><br>

<p>c) transporturilor efectelor de strămutare.</p><br>

<p style="text-indent:2em;">5. Părţile contractante se obligă să nu aducă nici o modificare prezentei Convenţii prin acorduri speciale încheiate între doua
	sau mai multe dintre ele, putându-se totuşi scoate de sub prevederile ei micul trafic de frontieră sau autorizată folosirii de
	scrisorii trăsură reprezentative a mărfii în transporturile efectuate exclusiv pe teritoriile lor.</p><br>

<p style="font-weight:500;">Articolul 2</p><br>

<p style="text-indent:2em;">1. Dacă vehiculul conţinând mărfuri este transportat pe o parte a parcursului pe mare, cale ferată, cale navigabilă interioară
	sau aeriană, fără descărcarea mărfii din vehicul, cu excepţia aplicării eventuale a dispoziţiilor articolului 14, prezenta Convenţie
	se aplică pentru întregul transport. Totuşi, în măsura în care s-a dovedit că o pierdere, o avarie sau o întârziere la eliberarea mărfii
	, care a survenit în cursul transportului cu un alt fel de transport decât cel rutier, nu a fost cauzată de o acţiune sau omisiune a 
	transportatorului rutier şi că provine dintr-un fapt care nu a putut să se producă decât în cursul şi din cauza transportului nerutier,
	răspunderea transportatorului rutier nu este determinată de prezenta Convenţie, ci de felul în care răspunderea transportatorului nerutier 
	ar fi fost determinată, dacă un contract de trans-port ar fi fost încheiat între expeditor şi transportatorul nerutier numai pentru transportul
	mărfii, conform prevederilor legale privind transportul de mărfuri printr-un alt fel de transport decât cel rutier. Cu toate acestea, în lipsa
	unor astfel de prevederi, răspunderea transportorului rutier va fi determinată de prezenta Convenţie.</p><br>

<p style="text-indent:2em;">2. Dacă transportatorul rutier este în acelaşi timp şi transportator nerutier, răspunderea sa este, de asemenea, determinată de 
	prevederile paragrafului l, ca şi când funcţia sa de transportator rutier şi funcţia sa de transportator nerutier ar fi exercitate
	de către două persoane diferite.</p><br>

<p style="font-weight:500;">CAPITOLUL II
PERSOANELE PENTRU CARE RĂSPUNDE 
TRANSPORTATORUL</p><br>

<p style="font-weight:500;">Articolul 3</p><br>

<p style="text-indent:2em;">Pentru aplicarea prezentei Convenţii, transportatorul răspunde ca de propriile sale acţiuni şi omisiuni, de acţiunile şi
	omisiunile prepuşilor săi şi ale oricăror alte persoane la serviciile cărora recurge pentru executarea transportului, dacă 
	aceşti prepuşi sau aceste persoane acţionează în exerciţiul funcţiilor lor.</p><br>

<p style="font-weight:500;">CAPITOLUL III
ÎNCHEIEREA ŞI EXECUTAREA 
CONTRACTULUI DE TRANSPORT</p><br>

<p style="font-weight:500;">Articolul 4</p><br>

<p style="text-indent:2em;">Proba contractului de transport se face prin scrisoare de trăsură. Absenţa, neregularitatea sau pierderea scrisorii de 
	trăsură nu afectează nici existenţa, nici valabilitatea contractului de transport, care rămâne, supus dispoziţiilor 
	prezentei Convenţii.</p><br>

<p style="font-weight:500;">Articolul 5</p><br>

<p style="text-indent:2em;">1. Scrisoarea de trăsură este întocmită în trei exemplare originale, semnate de expeditor şi de transportator, aceste
	semnături putând sa fie imprimate sau înlocuite prin ştampilele expeditorului şi transportatorului, dacă legislaţia ţării
	în care este întocmită scrisoarea de trăsură o permite. Primul exemplar se trimite expeditorului, al doilea însoţeşte marfa,
	iar al treilea se reţine de transportator.</p><br>

<p style="text-indent:2em;">2. În cazul în care marfa de transportat trebuie să fie încărcată în vehicule diferite sau dacă este vorba de diferite
	feluri de marfă ori de loturi distincte, expeditorul sau transportatorul are dreptul să ceară întocmirea de scrisori de
	trăsură pentru fiecare vehicul folosit sau pentru fiecare fel de marfă ori lot de mărfuri.</p><br>

<p style="font-weight:500;">Articolul 6</p>

<p style="text-indent:2em;"></p><h6>1) Scrisoarea de trăsură trebuie să conţină următoarele date:</h6><p></p>

<p>a) locul şi data întocmirii sale;</p><br>

<p>b) numele şi adresa expeditorului;</p><br>

<p>c) numele şi adresa transportatorului;</p><br>

<p>d) locul şi data primirii mărfii şi locul prevăzut pentru eliberarea acesteia;</p><br>

<p>e) numele şi adresa destinatarului;</p><br>

<p style="text-indent:2em;">f) denumirea curentă a naturii mărfii şi felul ambalajului, iar pentru mărfurile periculoase, denumirea
	lor general recunoscută;</p><br>

<p>g) numărul coletelor, marcajele speciale şi numerele lor;</p><br>

<p>h) greutatea brută sau cantitatea altfel exprimată a mărfii;</p><br>

<p style="text-indent:2em;">i) cheltuielile aferente transportului (preţ de transport, cheltuieli accesorii, taxe de vamă şi alte cheltuieli
	survenite de la încheierea contractului şi până la eliberare);</p><br>

<p>j) instrucţiunile necesare pentru formalităţile de vamă şi altele;</p><br>

<p>k) indicaţia că transportul este supus regimului stabilit prin prezenta Convenţie şi nici unei alte clauze
	contrare.</p>

<p style="text-indent:2em;"></p><h6>2. Dacă este cazul, scrisoarea de trăsură trebuie să conţină şi indicaţiile următoare:</h6><p></p>

<p>a) interzicerea transpordării;</p><br>

<p>b) cheltuielile pe care expeditorul le ia asupra sa;</p><br>

<p>c) totalul sumelor ramburs de perceput la eliberarea mărfii;</p><br>

<p>d) valoarea declarată a mărfii şi suma care reprezintă interesul special la eliberare;</p><br>

<p>e) instrucţiunile expeditorului către transportator cu privire la asigurarea mărfii;</p><br>

<p>f) termenul convenit în care transportul trebuie să fie efectuat;</p><br>

<p>g) lista documentelor remise transportatorului.</p><br>

<p>3. Părţile pot insera în scrisoarea de trăsură orice altă indicaţie pe care ele o consideră utilă.</p><br>

<p style="font-weight:500;">Articolul 7</p>

<p style="text-indent:2em;"></p><h6>1. Expeditorul răspunde pentru toate cheltuielile şi daunele pricinuite transportatorului din cauza
	inexactităţii sau insuficienţei:</h6><p></p>

<p>a) indicaţiilor menţionate în articolul 6 paragraful l b), d), e), f), g), h) şi j);</p><br>

<p>b) indicaţiilor menţionate în articolul 6 al paragrafului 2;</p><br>

<p>c) oricăror alte indicaţii sau instrucţiuni date de el pentru emiterea scrisorii de trăsură sau pentru
	a fi înserate în aceasta.</p><br>

<p style="text-indent:2em;">2. Dacă, la cererea expeditorului, transportatorul înscrie în scrisoarea de trăsură menţiunile indicate în
	paragraful l al prezentului articol, se consideră, până la proba contrarie, că acesta a acţionat în contul
	expeditorului.</p><br>

<p style="text-indent:2em;">3. Dacă scrisoarea de trăsură nu conţine menţiunea prevăzută în articolul 6 paragraful l k), transportatorul
	răspunde pentru toate cheltuielile şi daunele pe care le-ar suporta persoana care are dreptul să dispună asupra
	mărfii datorită acestei omisiuni.</p><br>

<p style="font-weight:500;">Articolul 8</p>

<p style="text-indent:2em;"></p><h6>1. La primirea mărfii, transportatorul este obligat să verifice:</h6><p></p>

<p>a) exactitatea menţiunilor din scrisoarea de trăsură referitoare la numărul de colete, cât şi la marcajul şi
	la numerele lor;</p><br>

<p>b) starea aparentă a mărfii şi a ambalajului ei.</p><br>

<p style="text-indent:2em;">2. Dacă transportatorul nu are mijloace rezonabile de a verifia exactitatea menţiunilor indicate în paragraful 
	l a) al prezentului articol, el va înscrie în scrisoarea de trăsură rezerve care trebuie să fie motivate. El
	trebuie, de asemenea, să motiveze toate rezervele pe care le face cu privire la starea aparentă a mărfii şi a
	ambalajului ei. Aceste rezerve nu angajează pe expedi-tor, dacă acesta nu le-a acceptat în mod expres în 
	scrisoarea de trăsură.</p><br>

<p style="text-indent:2em;">3. Expeditorul are dreptul să ceară verificarea de către transportator a greutăţii brute a mărfii sau a cantităţii
	acesteia altfel exprimate. El poate cere, de asemenea, verificarea conţinutului coletelor. Transportatorul poate cere
	plata cheltuielilor de verificare. Rezultatul verificărilor se consemnează în scrisoarea de trăsură.</p><br>

<p style="font-weight:500;">Articolul 9</p><br>

<p style="text-indent:2em;">1. Scrisoarea de trăsură face dovada, până la proba contrarie, a condiţiilor contractului şi a primirii mărfii
	de către transportator.</p><br>

<p style="text-indent:2em;">2. În absenţa înscrierii în scrisoare de trăsură a rezervelor motivate ale transportatorului, există prezumţia
	că marfa şi ambalajul ei erau în stare aparent bună la momentul primirii de către transportator şi că numărul de
	colete, cât şi marcajele şi numerele lor erau conforme cu menţiunile din scrisoarea de trăsură.</p><br>

<p style="font-weight:500;">Articolul 10</p><br>

<p style="text-indent:2em;">Expeditorul este răspunzător faţă de transportator pentru daunele pricinuite persoanelor, materialului sau altor
	mărfuri, precum şi pentru cheltuielile cauzate de defectuozitatea ambalajului mărfii, afară de cazul când defectuozitatea,
	fiind aparentă sau cunoscută transportatorului în momentul primirii mărfii, transportatorul nu a făcut rezerve cu privire
	la aceasta.</p><br>

<p style="font-weight:500;">Articolul 11</p><br>

<p style="text-indent:2em;">1. În vederea îndeplinirii formalităţilor de vamă şi a altora care trebuie făcute înainte de eliberarea mărfii, expeditorul
	trebuie să anexeze la scrisoarea de trăsură sau să pună la dispoziţia transportatorului documentele necesare şi să-i furnizeze
	toate informaţiile cerute.</p><br>

<p style="text-indent:2em;">2. Transportatorul nu este obligat să examineze dacă aceste documente şi informaţii sunt exacte sau suficiente. Expeditorul
	este răspunzător faţă de transportator pentru toate daunele care ar putea rezulta din lipsa, insuficienţa sau din neregularitatea
	acestor documente şi informaţii, în afară de cazul în care culpa este a transportatorului.</p><br>

<p style="text-indent:2em;">3. Transportatorul este răspunzător ca şi un comisionar de consecinţele pierderii sau utilizării inexacte a documentelor
	menţionate în scrisoarea de trăsură şi care o însoţesc sau care i s-au predat: totuşi, despăgubirea pusă în sarcina sa nu
	o va depăşi pe aceea care ar fi datorată în caz de pierdere a mărfii.</p><br>

<p style="font-weight:500;">Articolul 12</p><br>

<p style="text-indent:2em;">1. Expeditorul are dreptul de dispoziţie asupra mărfii, în special să ceară transportatorului oprirea transportului,
	schimbarea locului prevăzut pentru eliberare sau să elibereze marfa unui alt destinatar decât cel indicat în scrisoarea
	de trăsură.</p><br>

<p style="text-indent:2em;">2. Acest drept se stinge în momentul în care al doilea exemplar al scrisorii de trăsură este remis destinatarului sau
	dacă acesta îşi exercită dreptul prevăzut de articolul 13 paragraful 1; din acel moment, transportatorul trebuie să se
	conformeze ordinelor destinatarului.</p><br>

<p style="text-indent:2em;">3. Dreptul de dispoziţie aparţine totuşi destinatarului din momentul întocmirii scrisorii de trăsură, dacă expeditorul
	face o menţiune în acest sens pe această scrisoare.</p><br>

<p style="text-indent:2em;">4. Dacă, exercitând dreptul său de dispoziţie, destinatarul îi ordonă eliberarea mărfii unei alte persoane, aceasta nu 
	poate desemna alţi destinatari.</p>

<p style="text-indent:2em;"></p><h6>5. Exerciţiul dreptului de dispoziţie este supus următoarei condiţii:</h6><p></p>

<p style="text-indent:2em;">a) expeditorul sau, în cazul indicat în paragraful 3 al prezentului articol, destinatarul, care vrea să exercite acest 
	drept, trebuie să prezinte primul exemplar al scrisorii de trăsură, pe care trebuie să fie înscrise noile instrucţiuni 
	date transportatorului şi să despăgubească pe transportator pentru cheltuielile şi prejudiciul pe care le-a antrenat 
	executarea acestor instrucţiuni;</p><br>

<p style="text-indent:2em;">b) executarea acestor instrucţiuni trebuie să fie posibilă în momentul în care instrucţiunile parvin persoanei care trebuie
	să le execute şi nu trebuie nici să împiedice exploatarea normală a întreprinderii transportatorului, nici să aducă prejudicii
	expeditorilor sau destinatarilor altor transporturi;</p><br>

<p>c) instrucţiunile nu trebuie niciodată să aibă ca efect divizarea transportului.</p><br>

<p style="text-indent:2em;">6. În cazul în care, datorită dispoziţiilor prevăzute în paragraful 5 b) al prezentului articol, transportatorul nu poate 
	executa instrucţiunile pe care le primeşte, el trebuie să înştiinţeze imediat despre aceasta persoana care a dat aceste 
	instrucţiuni.</p><br>

<p style="text-indent:2em;">7. Transportatorul care nu execută instrucţiunile date în condiţiile prevăzute de prezentul articol sau care s-a conformat
	acestor instrucţiuni fără să fi cerut prezentarea primului exemplar al scrisorii de trăsură va fi răspunzător faţă de cel care
	are dreptul să ceară acoperirea prejudiciului cauzat de acest drept.</p><br>

<p style="font-weight:500;">Articolul 13</p><br>

<p style="text-indent:2em;">1. După sosirea mărfii la locul prevăzut pentru eliberare, destinatarul are dreptul de a cere ca al doilea exemplar
	al scrisorii de trăsură să-i fie remis şi ca marfa să-i fie eliberată contra unei dovezi de primire.</p><br>
	
<p style="text-indent:2em;">Dacă pierderea mărfii este stabilită sau dacă marfa nu a sosit la expirarea termenului prevăzut în articolul 19,
 destinatarul este autorizat să pretindă în numele său de la transportator drepturile care rezultă din contractul
 de transport.</p><br>

<p style="text-indent:2em;">2. Destinatarul care se prevalează de drepturile care îi sunt acordate de paragraful l al prezentului articol,
	este obligat să plătească creanţele care rezultă din scrisoarea de trăsură, în caz de contestare a acestora,
	transportatorul nu este obligat să efectueze eliberarea mărfii decât în cazul în care destinatarul a
	depus o cauţiune.</p><br>

<p style="font-weight:500;">Articolul 14</p><br>

<p style="text-indent:2em;">1. Dacă, pentru un motiv oarecare, executarea contractului în condiţiile prevăzute în scrisoarea de trăsură
	este sau devine imposibilă înainte de sosirea mărfii la locul prevăzut pentru eliberare, transportatorul este
	obligat să ceară instrucţiuni persoanei care are dreptul să dispună de marfă, în conformitate cu articolul 12.</p><br>

<p style="text-indent:2em;">2. Totuşi, dacă circumstanţele permit efectuarea transportului în condiţii care diferă de cele prevăzute în
	scrisoarea de trăsură şi dacă transportatorul nu a putut să obţină în timp util instrucţiunile de la persoana
	care are dreptul de a dispune de marfă conform articolului 12, el va lua măsurile pe care le consideră ca
	fiind cele mai bune în interesul persoanei care are dreptul de a dispune de marfă.</p><br>

<p style="font-weight:500;">Articolul 15</p><br>

<p style="text-indent:2em;">1. În cazul în care după sosirea mărfii la locul de destinaţie se ivesc impedimente la eliberare,
	transportatorul cere instrucţiuni de la expeditor.</p><br>

<p style="text-indent:2em;">Dacă destinatarul refuză marfa, expeditorul are dreptul de a dispune de aceasta, fără a prezenta
	primul exemplar al scrisorii de trăsură.</p><br>

<p style="text-indent:2em;">2. Chiar dacă a refuzat marfa, destinatarul poate oricând să ceară eliberarea ei atât timp cât
	transportatorul nu a primit instrucţiuni contrarii de la expe-ditor.</p><br>

<p style="text-indent:2em;">3. Dacă impedimentul la eliberarea mărfii apare după ce destinatarul, conform dreptului pe care
	îl deţine în virtutea articolului 12 paragraful 3, a dat ordin de a se elibera marfa unei alte persoane,
	destinatarul se substituie expeditorului, iar această altă persoană destinatarului, pentru aplicarea paragrafelor 
	l şi 2 de mai sus.</p><br>

<p style="font-weight:500;">Articolul 16</p><br>

<p style="text-indent:2em;">1. Transportatorul are dreptul la rambursarea cheltuielilor legate de solicitarea de instrucţiuni sau cele determinate
	de executarea instrucţiunilor primite cu condiţia ca aceste cheltuieli să nu fie consecinţa culpei sale.</p><br>

<p style="text-indent:2em;">2. În cazurile indicate în articolul 14 paragraful l şi în articolul 15, transportatorul poate să descarce imediat marfa
	în contul persoanei care are dreptul să dispună de aceasta; după această descărcare, transportul este considerat terminat.
	Transportatorul îşi asumă atunci păstrarea mărfii. El poate totodată să încredinţeze marfa unui terţ şi nu este atunci
	răspunzător decât de alegerea judicioasă a acestui terţ. Marfa rămâne grevată de creanţele rezultând din scrisoarea de
	trăsură şi de toate celelalte cheltuieli.</p><br>

<p style="text-indent:2em;">3. Transportatorul poate să procedeze la vânzarea mărfii fără să aştepte instrucţiuni de la persoana care are dreptul
	să dispună, când natura perisabilă a mărfii sau starea ei o justifică sau când cheltuielile pentru păstrare sunt disproporţionale
	faţă de valoarea mărfii, în celelalte cazuri el poate, de asemenea, proceda la vânzarea mărfii, dacă, într-un termen rezonabil, el
	nu a primit din partea persoanei care are dreptul să dispună de marfă instrucţiuni contrarii a căror executare nu poate fi cerută
	în mod echitabil.</p><br>

<p style="text-indent:2em;">4. Dacă marfa a fost vândută în baza dispoziţiilor prezentului articol, suma obţinută din vânzare trebuie pusă la dispoziţia
	persoanei care are dreptul de a dispune de marfă, minus cheltuielile care grevează marfa. Dacă aceste cheltuieli sunt mai mari
	decât suma obţinută din vânzare, transportatorul are dreptul la diferenţă.</p><br>

<p>5. Procedura utilizată în caz de vânzare este determinată de legea sau de uzanţele locului
 unde se găseşte marfa.</p><br>

<p style="font-weight:500;">CAPITOLUL IV
RĂSPUNDEREA TRANSPORTATORULUI</p><br>

<p style="font-weight:500;">Articolul 17</p><br>

<p style="text-indent:2em;">1. Transportatorul este răspunzător pentru pierderea totală sau parţială sau pentru avariere, produse intre momentul primirii
	mărfii şi cel al eliberării acesteia, cât şi pentru întârzierea în eliberare.</p><br>

<p style="text-indent:2em;">2. Transportatorul este exonerat de această răspundere dacă pierderea, avaria sau întârzierea a avut drept cauză o culpă
	a persoanei care are dreptul să dispună de marfă, un ordin al acesteia nerezultând dintr-o culpă a transportatorului, un 
	viciu propriu al mărfii sau de circumstanţe pe care transportatorul nu putea să le ştie şi ale căror consecinţe nu le 
	putea preveni.</p><br>

<p style="text-indent:2em;">3. Transportatorul nu poate invoca, pentru a fi exonerat de răspundere, nici defecţiunea vehiculului pe care-1 foloseşte 
	pentru efectuarea transportului, nici culpa persoanei de la care a închiriat vehiculul sau a prepuşilor acesteia.</p>

<p style="text-indent:2em;"></p><h6>4. Ţinând cont de articolul 18 paragrafele 2 până la 5 transportatorul este exonerat de răspundere dacă pierderea
	sau avaria rezultă din riscurile particulare aferente uneia sau mai multora dintre următoarele cauze:</h6><p></p>

<p style="text-indent:2em;">a) folosirea de vehicule descoperite, fără prelate, dacă această folosire a fost convenită într-un mod expres
	şi menţionată în scrisoarea de trăsură;</p><br>

<p style="text-indent:2em;">b) lipsa sau defectuozitatea ambalajului pentru mărfurile expuse prin felul lor la stricăciuni sau avariere,
	dacă aceste mărfuri nu sunt ambalate sau sunt rău ambalate;</p><br>

<p style="text-indent:2em;">c) manipularea, încărcarea, stivuirea sau descărcarea mărfii de către expe-ditor sau destinatar sau de către
	persoane care acţionează în contul expeditorului sau destinatarului;</p><br>

<p style="text-indent:2em;">d) natura unor mărfuri expuse, datorită însăşi naturii lor, fie la pierdere totală sau parţială, fie la avarie
	în special prin spargere, rugină, deteriorare internă şi spontană, uscare, curgere, pierdere normală sau prin
	acţiunea insectelor sau a rozătoarelor;</p><br>

<p>e) insuficienţa sau imperfecţiunea marcajelor sau a numerelor coletelor;</p><br>

<p>f) transportul de animale vii.</p><br>

<p style="text-indent:2em;">5. Dacă în baza prezentului articol, transportatorul nu răspunde de unii factori care au cauzat dauna,
	răspunderea sa nu este angajată decât în măsura în care factorii de care el răspunde, în baza prezentului
	articol, au contribuit la daună.</p><br>

<p style="font-weight:500;">Articolul 18</p><br>

<p style="text-indent:2em;">1. Sarcina probei că pierderea, avaria sau întârzierea transportării a avut drept cauză unul dintre
	factorii prevăzuţi în articolul 17 paragraful 2 incumbă transportatorului.</p><br>

<p style="text-indent:2em;">2. Când transportatorul stabileşte că, ţinând seama de circumstanţele de fapt, pierderea sau avarierea
	a putut să rezulte din unul sau mai multe riscuri particulare prevăzute în articolul 17 paragraful 4, există
	prezumţia că ea a rezultat din această cauză. Cel în drept poate cere să facă dovada că dauna nu a avut drept
	cauză în întregime sau parţial unul dintre aceste riscuri.</p><br>

<p style="text-indent:2em;">3. Prezumţia arătată mai sus nu este aplicabilă în cazul prevăzut de articolul 17 paragraful 4 a), dacă este
	vorba de o pierdere anormală sau o pierdere de colete.</p><br>

<p style="text-indent:2em;">4. Dacă transportul este efectuat cu ajutorul unui vehicul amenajat în vederea protejării mărfurilor împotriva
	influenţei căldurii, a frigului, a variaţiilor de temperatură sau a umidităţii aerului, transportatorul nu poate
	invoca beneficiul prevederilor articolului 17 paragraful 4 d) decât dacă face dovada că toate mărfurie care îl
	incumbă, ţinând cont de circumstanţe, au fost luate în ceea ce priveşte alegerea, întreţinerea şi folosirea acestor
	amenajări şi ca el s-a conformat instrucţiunilor speciale care i-au fost date.</p><br>

<p style="text-indent:2em;">5. Transportatorul nu poate invoca beneficiul prevederilor articolului 17 paragraful 4 f) decât dacă face dovada
	că toate măsurile care îl incumbă în mod normal, ţinând cont de circumstanţe, au fost luate şi că el s-a conformat
	instrucţiunilor speciale care i-au fost date.</p><br>

<p style="font-weight:500;">Articolul 19</p><br>

<p style="text-indent:2em;">Se consideră întârziere la eliberare atunci când marfa nu a fost eliberată în termenul convenit
	sau, dacă nu a fost convenit un termen, atunci când durata efectivă a transportului depăşeşte timpul
	care în mod rezonabil este acordat unui transportator diligent, ţinându-se cont de circumstanţe şi,
	printre altele, în cazul unei încărcări parţiale, de timpul necesar pentru asamblarea unei încărcături
	complete în condiţii normale.</p><br>

<p style="font-weight:500;">Articolul 20</p><br>

<p style="text-indent:2em;">1. Cel în drept poate să considere marfa pierdută, fără a prezenta alte dovezi, când aceasta nu a fost
	eliberată în termen de 30 de zile de la expirarea termenului convenit sau, dacă nu a fost convenit un asemenea
	termen, în termen de 60 de zile de la primirea mărfii de către transportator.</p><br>

<p style="text-indent:2em;">2. Cel în drept poate, primind despăgubirea pentru marfa pierdută, să ceară, în scris, să fie înştiinţat
	imediat, în cazul în care marfa ar fi găsită în cursul anului care urmează plăţii despăgubirii. Confirmarea
	primirii unei astfel de cereri se comunică în scris.</p><br>

<p style="text-indent:2em;">3. În termen de 30 de zile de la primirea unei atări înştiinţări cel în drept poate cere ca marfa să-i
	fie predată contra plăţii creanţelor rezultând din scrisoarea de trăsură şi contra restituirii despăgubirii
	primite, deducându-se eventual cheltuielile care ar fi fost cuprinse în această despăgubire şi sub rezerva
	tuturor drepturilor la despăgubire pentru întârzierea la eliberare prevăzută în articolul 23 şi, după caz,
	în articolul 20.</p><br>

<p style="text-indent:2em;">4. În cazul lipsei fie a cererii prevăzute în paragraful 2, fie a instrucţiunilor date în termenul de 30
	de zile prevăzut în paragraful 3, sau dacă marfa a fost găsita după un an de la plata despăgubirii,
	transportatorul poate dispune de aceasta conform legislaţiei locului unde se găseşte marfa.</p><br>

<p style="font-weight:500;">Articolul 21</p><br>

<p style="text-indent:2em;">Dacă marfa este eliberată destinatarului fără încasarea sumei ramburs care ar fi trebuit să fie percepută
	de către transportator în virtutea dispoziţiilor contractului de transport, transportatorul este obligat
	să despăgubească pe expeditor cu suma ramburs, fără a fi prejudiciat în dreptul său în regres împotriva
	destinatarului.</p><br>

<p style="font-weight:500;">Articolul 22</p><br>

<p style="text-indent:2em;">1. Dacă expeditorul predă transportatorului mărfuri periculoase, el îi semnalează acestuia natura exactă
	a pericolului pe care mărfurile îl prezintă şi îi indică eventual precauţiile care trebuie luate, în cazul
	când această semnalare nu a fost consemnată în scrisoarea de trăsură, sarcina de a face dovada, prin orice
	mijloace, că transportatorul a avut cunoştinţă de natura exactă a pricolului pe care îl prezenta transportul
	mărfurilor sus-menţionate revine expeditorului sau destinatarului.</p><br>

<p style="text-indent:2em;">2. Mărfurile periculoase care nu au fost cunoscute ca atare de către transportator în condiţiile prevăzute de
	paragraful l al prezentului articol pot fi, în orice moment şi în orice loc, descărcate, distruse sau făcute
	inofensive de către transportator, fără nici o despăgubire: expeditorul este în plus răspunzător pentru toate
	cheltuielile şi daunele rezultând din predarea la transport a acestor mărfuri sau pentru transportul lor.</p><br>

<p style="font-weight:500;">Articolul 23</p><br>

<p style="text-indent:2em;">1. Când, în conformitate cu dispoziţiile prezentei Convenţii, este pusă în sarcina transportatorului o despăgubire
	pentru pierderea totală sau parţială a mărfii, această despăgubire este calculată după valoarea mărfii la locul şi
	în momentul primirii acesteia pentru transport.</p><br>

<p style="text-indent:2em;">2. Valoarea mărfii este determinată pe baza cursului bursei sau, în lipsa acestuia, pe baza preţului curent al
	pieţei, sau, în lipsa amândurora, pe baza valorii normale a mărfurilor de acelaşi fel şi de aceeaşi calitate.</p><br>

<p style="text-indent:2em;">3. Totuşi, cuantumul despăgubirii nu poate depăşi 25 franci per kilogram de greutate brută lipsă. Prin franc se
	înţelege francul-aur, cu greutatea de 10/ 31 grame şi conţinut de fineţe de 0,900.</p><br>

<p style="text-indent:2em;">4. În plus, se restituie taxa de transport, taxele vamale şi alte cheltuieli ivite cu ocazia transportului mărfii,
	în totalitate în caz de pierdere totală şi proporţional în caz de pierdere parţială; alte daune-interese pentru
	pierdere nu sunt datorate.</p><br>

<p style="text-indent:2em;">5. În caz de întârziere, dacă cel în drept face dovada că din această întârziere a rezultat un prejudiciu,
	transportatorul este ţinut să plătească daune care nu pot depăşi preţul transportului.</p><br>

<p style="text-indent:2em;">6. O despăgubire mai mare nu poate fi pretinsă decât în caz de declarare a valorii mărfii sau de declarare
	de interes special la predare, conform articolelor 24 şi 26.</p><br>

<p style="font-weight:500;">Articolul 24</p><br>

<p style="text-indent:2em;">Expeditorul poate să declare în scrisoarea de trăsură, contra plăţii unui supliment de preţ convenit, o
	valoare a mărfii care depăşeşte limita menţionată în paragraful 3 al articolului 23 şi, în acest caz,
	valoarea declarată înlocuieşte această limită.</p><br>

<p style="font-weight:500;">Articolul 25</p><br>

<p style="text-indent:2em;">1. În caz de avarie, transportatorul plăteşte contravaloarea deprecierii mărfii, calculată pe baza valorii
	stabilite în conformitate cu articolul 23 paragrafele l, 2 şi 4.</p>

<p style="text-indent:2em;"></p><h6>2. Totuşi, despăgubirea nu poate să depăşească:</h6><p></p>

<p>a) dacă totalul expediţiei este depreciat prin avarie, suma care ar fi trebuit plătită în caz de 
	pierdere totală;</p><br>

<p style="text-indent:2em;">b) dacă numai o parte a expediţiei a fost depreciată prin avarie, suma care ar trebui plătită în 
	caz de pierdere a părţii depreciate.</p><br>

<p style="font-weight:500;">Articolul 26</p><br>

<p style="text-indent:2em;">1. Expeditorul poate fixa, făcând menţiunea respectivă în scrisoarea de trăsură şi contra plăţii unui
	supliment de preţ stabilit convenit, suma care reprezintă un interes special la eliberare, pentru cazul
	unei pierderi sau avarii şi pentru cazul depăşirii termenului convenit.</p><br>

<p style="text-indent:2em;">2. Dacă a fost făcută o declaraţie de interes special la eliberare, poate fi cerută - independent de 
	despăgubirile prevăzute în articolele 23, 24 şi 25 şi până la concurenţa contravalorii interesului declarat
	- o despăgubire egală cu o pagubă suplimentară pentru care s-a făcut dovada.</p><br>

<p style="font-weight:500;">Articolul 27</p><br>

<p style="text-indent:2em;">1. Cel în drept poate să pretindă dobânzi pentru pagubele suferite. Aceste dobânzi, calculate la 5% pe an,
	curg din ziua reclamaţiei adresate în scris transportatorului sau, dacă nu a existat o reclamaţie, din ziua
	introducerii acţiunii în justiţie.</p><br>

<p style="text-indent:2em;">2. Dacă elementele care servesc ca bază de calcul al despăgubirii nu sunt exprimate în moneda ţării în care
	se cere plata, convertirea va fi făcută la cursul zilei şi locului plăţii despăgubirii.</p><br>

<p style="font-weight:500;">Articolul 28</p><br>

<p style="text-indent:2em;">1. În cazurile în care, potrivit legii aplicabile, pierderea, avaria sau întârzierea survenită în cursul
	unui transport supus prezentei Convenţii, pot da loc unei reclamaţii extracontractuale, transportatorul 
	se poate prevala de dispoziţiile prezentei Convenţii care exclud răspunderea sa sau care determină ori 
	limitează despăgubirile datorate.</p><br>

<p style="text-indent:2em;">2. În cazul când, pentru pierdere, avarie sau întârziere intervine răspunderea extracontractuală a uneia
	dintre persoanele pentru care transportatorul răspunde potrivit prevederilor articolului 3, această persoană 
	poate deasemenea, să se prevaleze de dispoziţiile prezentei Convenţii care exclud răspunderea transportatorului 
	sau care determină ori limitează despăgubirile datorate.</p><br>

<p style="font-weight:500;">Articolul 29</p><br>

<p style="text-indent:2em;">1. Transportatorul nu are dreptul să se prevaleze de dispoziţiile prezentului capitol care exclud sau limitează 
	răspunderea sau care răstoarnă sarcina probei, dacă paguba a fost provocată din dolul său sau dintr-o culpă care
	îi este imputabilă şi care, în conformitate cu legea ţării căreia îi aparţine organul de jurisdicţie sesizat, este
	considerată echivalentă cu un dol.</p><br>

<p style="text-indent:2em;">2. Acelaşi lucru este valabil şi dacă faptele săfârşite cu dol sau culpă sunt ale prepuşilor transportatorului sau
	ale oricărei alte persoane la serviciile cărora el recurge pentru executarea transportului, dacă aceşti prepuşi sau 
	persoane lucrează în exerciţiul funcţiunii lor. În acest caz, prepuşii sau celelalte persoane nu au dreptul de a se 
	prevala, în ceea ce priveşte răspunderea lor, de dispoziţiile din prezentul capitol, menţionate în paragraful 1.</p><br>

<p style="font-weight:500;">CAPITOLUL V
RECLAMAŢII ŞI ACŢIUNI</p><br>

<p style="font-weight:500;">Articolul 30</p><br>

<p style="text-indent:2em;">1. Daca destinatarul a primit marfă fără să fi constatat starea ei în contradictoriu cu transportatorul sau dacă cel mai târziu 
	în momentul eliberării, când este vorba de pierderi ori avarii aparente, sau în termen de 7 zile de la data eliberării, în afară 
	de duminici şi sărbători legale, când este vorba de pierderi sau avarii neaparente, nu a făcut rezerve transportatorului, indicând
	natura generală a pierderii sau avariei, se prezumă până la proba contrarie, că a primit marfa în valoarea descrisă în scrisoarea 
	de trăsură. Rezervele arătate mai sus trebuie să fie făcute în scris dacă este vorba de
	prevederi sau de avarii neaparente.</p><br>

<p style="text-indent:2em;">2. Dacă starea mărfii a fost constatată în mod contradictoriu de către destinatar şi transportator, dovada contrarie rezultatului
	acestei constatări nu poate fi făcută decât dacă este vorba de pierderi sau de avarii aparente şi dacă destinatarul a adresat 
	rezervele sale în scris transportatorului în termen de 7 zile, în afara duminicilor şi sărbătorilor legale, de la data acestei
	constatări.</p><br>

<p style="text-indent:2em;">3. O depăşire a termenului de eliberare poate da loc unei despăgubiri numai dacă a fost adresată o rezervă în scris, în termen 
	de 21 de zile de la data punerii mărfii la dispoziţia destinatarului.</p><br>

<p style="text-indent:2em;">4. Data eliberării sau, după caz, cea a constatării sau a punerii mărfii la dispoziţie nu se include în termenele prevăzute 
	în prezentul articol.</p><br>

<p style="text-indent:2em;">5. Transportatorul şi destinatarul trebuie să-şi acorde în mod reciproc toate înlesnirile rezonabile pentru condiţiile şi 
	verificările necesare.</p><br>

<p style="font-weight:500;">Articolul 31</p>

<p style="text-indent:2em;"></p><h6>1. Pentru toate litigiile decurgând din transporturile supuse prezentei Convenţii, reclamantul poate sa sesizeze în afara organelor de 
	jurisdicţie ale ţărilor contractante, desemnate de comun acord de către părţi,
	organele de jurisdicţie din ţara pe teritoriul căreia:</h6><p></p>

<p style="text-indent:2em;">a) se află reşedinţa obişnuită a pârâtului, sediul său principal sau sucursala ori agenţia prin intermediul căreia contractul de 
	transport a fost încheiat;</p><br>

<p style="text-indent:2em;">b) este situat locul preluării mărfurilor sau a celui prevăzut pentru eliberarea mărfurilor, şi nu poate sesiza decât aceste organe 
	de jurisdicţie.</p><br>

<p style="text-indent:2em;">2. Dacă în cazul unui litigiu din cele arătate la paragraful l din prezentul articol este în curs de judecată o acţiune la un organ 
	de jurisdicţie competent potrivit prevederilor acestui paragraf sau dacă într-un asemenea litigiu s-a pronunţat o hotărâre de către
	un astfel de organ de jurisdicţie, nu mai poate fi introdusă aceeaşi acţiune pentru aceeaşi cauză între aceleaşi părţi, cu excepţia
	cazului în care hotărârea organului de jurisdicţie la care a fost introdusă prima acţiune nu este pasibilă de a fi executată în ţara
	în care este introdusă noua acţiune.</p><br>

<p style="text-indent:2em;">3. Dacă într-un litigiu prevăzut în paragraful l din prezentul articol o hotărâre dată de un organ de jurisdicţie al unei ţări 
	contractante a devenit executorie în această ţară, ea devine, de asemenea, executorie în fiecare dintre celelalte ţări contractante,
	imediat după îndeplinirea formalităţilor prevăzute în acest scop în ţara dată. Aceste formalităţi nu pot duce la o revizuire a 
	cauzei.</p><br>

<p style="text-indent:2em;">4. Dispoziţiile paragrafului 3 din prezentul articol se aplică hotărârilor pronunţate contradictoriu, celor pronunţate în lipsa
	tranzacţiilor judiciare, dar nu se aplică hotărârilor judecătoreşti care sunt cu execuţie provizorie, nici hotărârilor prin care 
	se acordă, în afara cheltuielilor de judecată, daune-interese împotriva unui reclamant ca urmare a respingerii totale sau parţiale 
	a acţiunii sale.</p><br>

<p style="text-indent:2em;">5. Pentru asigurarea plăţii cheltuielilor ocazionate de acţiunile în justiţie, cauzate de transporturile supuse prezentei Convenţii, 
	nu se poate solicita cauţiunea cetăţenilor ţărilor contractante care îşi
	au domiciliul sau un sediu în una dintre aceste ţări.</p><br>

<p style="font-weight:500;">Articolul 32</p>

<p style="text-indent:2em;"></p><h6>1. Acţiunile derivând din transporturile supuse prezentei Convenţii se prescriu în termen de un an. Totuşi, în caz de doi sau culpă 
	considerată de legea ţării, căreia îi aparţine organul de jurisdicţie sesizat ca echivalenta cu dolul, termenul de prescripţie este de
	5 ani. Termenul de prescripţie curge:</h6><p></p>

<p>a) în caz de pierdere parţială, de avarie sau de întârziere - din ziua în care
 marfa a fost eliberată;</p><br>

<p style="text-indent:2em;">b) în caz de pierdere totală - începând de la a treizecea zi după expirarea termenului convenit sau, dacă nu a fost convenit nici un 
	termen, începând de la a şaizecea zi de la primirea mărfii de către transportator;</p><br>

<p>c) în toate celelalte cazuri - începând de la expirarea unui termen de 3 luni de la data încheierii contractului 
	de transport.</p><br>

<p>Ziua indicată mai sus ca punct de plecare al prescripţiei acţiunii nu este cuprinsă în termen.</p><br>

<p style="text-indent:2em;">2. O reclamaţie scrisă suspendă prescripţia până în ziua în care transportatorul respinge reclamaţia în scris şi 
	restituie documentele care erau anexate, în caz de acceptare parţială a reclamaţiei, prescripţia nu-şi reia cursul 
	său decât pentru partea din reclamaţie care rămâne litigioasă. Dovada primirii reclamaţiei sau a răspunsului şi a 
	restituirii documentelor cade în sarcina părţii care invocă acest fapt. Reclamaţiile ulterioare având acelaşi obiect
	nu suspendă prescripţia.</p><br>

<p style="text-indent:2em;">3. Sub rezerva dispoziţiilor paragrafului 2 de mai sus, suspendarea prescripţiei este reglementată potrivit legii 
	ţării căreia îi aparţine organul de jurisdicţie sesizat. Această prevedere se aplică şi în cazul întreruperii 
	prescripţiei.</p><br>

<p>4. Acţiunea prescrisă nu mai poate fi exercitată nici sub formă de cerere reconvenţională sau de excepţie.</p><br>

<p style="font-weight:500;">Articolul 33</p><br>

<p style="text-indent:2em;">Contractul de transport poate sa conţină o clauză atribuind competenţa unui tribunal arbitral, cu condiţia ca această 
	clauză să prevadă că tribunalul arbitral va aplica dispoziţiile prezentei Convenţii.</p><br>

<p style="font-weight:500;">CAPITOLUL VI
DISPOZIŢII REFERITOARE LA TRANSPORTUL
 EFECTUAT DE TRANSPORTATORI SUCCESIVI</p><br>

<p style="font-weight:500;">Articolul 34</p><br>

<p style="text-indent:2em;">Dacă un transport care face obiectul unui contract unic este executat în mod succesiv de către mai mulţi transportatori 
	rutieri, fiecare dintre aceştia îşi asuma răspunderea executării transportului total, transportatorul al doilea şi fiecare dintre 
	transportatorii următori devenind, prin primirea de către ei a mărfii şi a scrisorii de trăsură, Părţi la contract, în condiţiile 
	prevăzute în scrisoarea de trăsură.</p><br>

<p style="font-weight:500;">Articolul 35</p><br>

<p style="text-indent:2em;">1. Transportatorul care preia marfa de la transportatorul precedent îi remite acestuia o confirmare de primire datată şi semnată. 
	El trebuie să-şi înscrie numele şi adresa sa pe al doilea exemplar al scrisorii de trăsură. Dacă este cazul, ei completează pe 
	acest exemplar, cât şi pe confirmarea de primire, rezervele sale de natura celor prevăzute în articolul 8 paragraful 2.</p><br>

<p>2. Dispoziţiile articolului 9 se aplică relaţiilor dintre transportatorii succesivi.</p><br>

<p style="font-weight:500;">Articolul 36</p><br>

<p style="text-indent:2em;">Atât timp cât nu este vorba de o cerere reconvenţională sau de o excepţie formulată în faţa unei instanţe referitoare la o 
	acţiune întemeiată pe acelaşi contract de transport, acţiunea privind răspunderea pentru pierdere, avarie sau întârziere nu 
	poate fi îndreptată decât împotriva primului transportator, ultimului transportator sau transportatorului care a executat partea 
	din trans-port pe parcursul căruia s-a produs faptul care a cauzat pierderea, avaria sau întârzierea; acţiunea poate fi îndreptată 
	în acelaşi timp împotriva mai multor dintre aceşti transportatori.</p><br>

<p style="font-weight:500;">Articolul 37</p>

<p style="text-indent:2em;"></p><h6>Transportatorul care a plătit despăgubiri, în conformitate cu dispoziţiile prezentei Convenţii, are dreptul de regres, pentru suma 
	plătită, împreună cu dobânzile şi cheltuielile suportate, contra transportatorilor care au participat la executarea contractului de 
	transport conform dispoziţiilor următoare:</h6><p></p>

<p style="text-indent:2em;">a) transportatorul prin fapta căruia s-a produs paguba trebuie să suporte singur despăgubirea plătită de el sau de un alt 
	transportator;</p><br>

<p style="text-indent:2em;">b) dacă paguba s-a produs prin fapta a doi sau mai mulţi transportatori, fiecare dintre ei trebuie să plătească o sumă proporţională
	cu partea sa de răspundere: dacă evaluarea părţilor de răspundere este imposibilă, fiecare dintre ei este răspunzător proporţional cu 
	partea de remunerare a transportului care îi revine;</p><br>

<p style="text-indent:2em;">c) dacă nu se poate stabili care dintre transportatori răspunde pentru pagubă, despăgubirea se repartizează între toţi transportatorii, 
	în proporţia fixată în punctul "b".</p><br>

<p style="font-weight:500;">Articolul 38</p><br>

<p style="text-indent:2em;">Dacă unul dintre transportatori nu este solvabil, partea de despăgubire care îi incumbă şi pe care nu a plătit-o se repartizează între toţi ceilalţi
	transportatori, proporţional cu remunerarea lor.</p><br>

<p style="font-weight:500;">Articolul 39</p><br>

<p style="text-indent:2em;">1. Transportatorul împotriva căruia se exercită un regres potrivit prevederilor articolelor 37 şi 38 nu poate contesta valabilitatea plăţii efectuate
	de către transportatorul care exercită regresul, dacă despăgubirea a fost stabilită prin hotărâre judecătorească, cu condiţia să fi fost informat legal 
	asupra procesului şi să fi avut posibilitatea să intervină.</p><br>

<p style="text-indent:2em;">2. Transportatorul care voieşte să exercite dreptul său în regres poate să-1 formuleze înaintea tribunalului competent al ţării în care unul dintre 
	transportatorii interesaţi îşi are reşedinţa obişnuită, sediul său principal sau sucursala ori agenţia prin intermediul căreia a fost încheiat contractul
	de transport. Regresul poate fi exercitat împotriva tuturor transportatorilor interesaţi, printr-o singură acţiune.</p><br>

<p style="text-indent:2em;">3. Dispoziţiile articolului 31 paragrafele 3 şi 4 se aplică hotărârilor
 judecătoreşti privind acţiunile în regres prevăzute în articolele 37 şi 38.</p><br>

<p style="text-indent:2em;">4. Dispoziţiile articolului 32 sunt aplicabile acţiunilor în regres între transportatori. Totuşi, prescripţia curge fie din ziua pronunţării unei hotărâri
	judecătoreşti definitive, prin care se fixează despăgubirile care urmează să fie plătite în virtutea dispoziţiilor prezentei Convenţii, fie, în cazul în care
	o astfel de hotărâre nu există, începând din ziua plăţii efective.</p><br>

<p style="font-weight:500;">Articolul 40</p><br>

<p>Transportatorii sunt liberi să convină între ei asupra unor dispoziţii
 derogatorii de la cele prevăzute în articolele 37 şi 38.</p><br>


<p style="font-weight:500;">CAPITOLUL VII
NULITATEA STIPULAŢIILOR CONTRARE 
CONVENŢIEI</p><br>

<p style="font-weight:500;">Articolul 41</p><br>

<p style="text-indent:2em;">1. Sub rezerva dispoziţiilor articolului 40, este nulă şi fără nici un efect orice stipulaţie care, direct sau indirect, ar deroga de la dispoziţiile 
	prezentei Convenţii. Nulitatea unor astfel de stipulaţii nu atrage nulitatea celorlalte prevederi ale contractului.</p><br>

<p style="text-indent:2em;">2. În special vor fi nule toate clauzele prin care s-ar ceda transportatorului beneficiul asigurării mărfii sau orice alta clauză analoagă, ca şi orice
	clauză care schimbă sarcina probei.</p><br>

<p style="font-weight:500;">CAPITOLUL VIII DISPOZIŢII FINALE</p><br>

<p style="font-weight:500;">Articolul 42</p><br>

<p style="text-indent:2em;">1. Prezenta Convenţie este deschisa pentru semnare sau aderare ţărilor membre ale Comisiei Economice pentru Europa şi ţările admise în comisie cu titlu
	consultativ conform paragrafului 8 al mandatului acestei comisii.</p><br>

<p style="text-indent:2em;">2. Ţările susceptibile să participe la anumite lucrări ale Comisiei Economice pentru Europa, conform paragrafului 11 al mandatului acestei comisii, 
	pot deveni Părţi Contractante la prezenta Convenţie, aderând la ea după intrarea sa în vigoare.</p><br>

<p style="text-indent:2em;">3. Convenţia va fi deschisă pentru semnare până la l august 1956 inclusiv. După această dată, ea va fi deschisă pentru aderare.</p><br>

<p>4. Prezenta Convenţie va fi rarificată.</p><br>

<p style="text-indent:2em;">5. Ratificarea sau aderarea va fi efectuată prin depunerea unui instrument la Secretarul General al Organizaţiei Naţiunilor Unite.</p><br>

<p style="font-weight:500;">Articolul 43</p><br>

<p style="text-indent:2em;">1. Prezenta Convenţie va intra în vigoare în a nouăzecea zi după ce cinci dintre ţările menţionate în paragraful l al articolului 42 vor fi depus 
	instrumentul de ratificare sau de aderare.</p><br>

<p style="text-indent:2em;">2. Pentru fiecare ţară care o va ratifica sau va adera la ea după ce cinci ţări vor fi depus instrumentul de ratificare sau de aderare, prezenta 
	Convenţie va intra în vigoare în a nouăzecea zi după depunerea instrumentului de ratificare sau de aderare a ţării respective.</p><br>

<p style="font-weight:500;">Articolul 44</p><br>

<p style="text-indent:2em;">1. Fiecare Parte Contractantă va putea denunţa prezenta Convenţie printr-o notificare adresată Secretarului General al Organizaţiei 
	Naţiunilor Unite.</p><br>

<p>2. Denunţarea va intra în vigoare la 12 luni de la data primirii notificării de denunţare de către Secretarul General.</p><br>

<p style="font-weight:500;">Articolul 45</p><br>

<p style="text-indent:2em;">Dacă după intrarea în vigoare a prezentei Convenţii numărul Părţilor Contractante va scădea, ca urmare a denunţărilor, la mai puţin de cinci,
	prezenta Convenţie va înceta să mai fie în vigoare, începând cu data la care ultima dintre aceste denunţări va deveni valabilă.</p><br>

<p style="font-weight:500;">Articolul 46</p><br>

<p style="text-indent:2em;">1. Orice ţară va putea, în momentul depunerii instrumetului de ratificare sau de aderare sau la orice dată ulterioară, să declare, prin notificare
	adresată Secretarului General al Organizaţiei Naţiunilor Unite, că prezenta Convenţie va fi aplicabilă la totalitatea sau numai la o parte a 
	teritoriilor pe care le reprezintă în plan internaţional. Convenţia va fi aplicabilă, teritoriului sau teritoriilor menţionate în notificare după
	a noăzecea zi din momentul primirii acestei notificări de către Secretarul General sau, dacă la această dată Convenţia încă nu a intrat în vigoare,
	de la data intrării sale în vigoare.</p><br>

<p style="text-indent:2em;">2. Orice ţară care va fi făcut, conform paragrafului precedent o declaraţie având drept efect aplicarea prezentei Convenţii pe un teritoriu pe 
	care ea îl reprezintă pe plan internaţional, va putea, conform articolului 44, să denunţe Convenţia în ceea ce priveşte numitul teritoriu.</p><br>

<p style="font-weight:500;">Articolul 47</p><br>

<p style="text-indent:2em;">Orice litigiu între două sau mai multe Părţi Contractante privind interpretarea sau aplicarea prezentei Convenţii, pe care Părţile nu au putut să-1 
	reglementeze prin tratative sau prin alt mijloc, va putea fi adus, la cererea oricăreia dintre Părţile Contractante interesate înaintea Curţii 
	Internaţionale de Justiţie, pentru a fi soluţionat de aceasta.</p><br>

<p style="font-weight:500;">Articolul 48</p><br>

<p style="text-indent:2em;">1. Fiecare Parte Contractantă va putea, în momentul când va semna sau ratifica prezenta Convenţie sau va adera la ea, să declare că ea nu se con-sideră
	legată de prevederile articolului 47 al Convenţiei. Celelalte Părţi Contractante nu vor fi legate prin articolul 47 faţă de orice Parte Contractantă 
	care a formulat o astfel de rezervă.</p><br>

<p style="text-indent:2em;">2. Orice Parte Contractantă care a formulat o rezervă conform paragrafului l va putea în orice moment să ridice această rezervă printr-o notificare
	adresată Secretarului General al Organizaţiei Naţiunilor Unite.</p><br>

<p>3. Nici o alta rezervă la prezenta Convenţie nu va fi admisă.</p><br>

<p style="font-weight:500;">Articolul 49</p><br>

<p style="text-indent:2em;">1. După ce prezenta Convenţie va fi fost în vigoare timp de 3 ani, orice Parte Contractantă va putea, prin notificare adresată Secretarului General
	al Organizaţiei Naţiunilor Unite, să ceară convocarea unei conferinţe cu scopul de a revizui prezenta Convenţie. Secretarul General va notifica această
	cerere tuturor Părţilor Contractante şi va convoca o conferinţă de revizuire dacă, într-un termen de 4 luni de la data notificării adresate de el, cel
	puţin un sfert din numărul Părţilor Contractante îi notifică acordul lor faţă de această cerere.</p><br>

<p style="text-indent:2em;">2. Dacă o conferinţă este convocată conform paragrafului precedent, Secretarul General va înştiinţa despre aceasta toate Părţile Contractante şi le va 
	invita să prezinte, în termen de 3 luni, propuneri spre examinare. Secretarul General va comunica tuturor Părţilor Contractante ordinea de zi provizorie
	a conferinţei, precum şi textul acestor propuneri, cel mai târziu până la 3 luni înainte de deschiderea conferinţei.</p><br>

<p style="text-indent:2em;">3. Secretarul General va invita la orice conferinţă convocată conform prezentului articol toate ţările la care se referă paragraful l al articolului 42,
	precum şi ţările devenite Părţi Contractante prin aplicarea paragrafului 2 al articolului 42.</p><br>

<p style="font-weight:500;">Articolul 50</p>

<p style="text-indent:2em;"></p><h6>În afară de notificările prevăzute în articolul 42, Secretatul General al Organizaţiei Naţiunilor Unite va notifica ţărilor vizate de paragraful l al 
	articolului 42, cât şi ţărilor devenite Părţi Contractante prin aplicarea paragrafului 2 al articolului 42:</h6><p></p>

<p>a) ratificările şi aderările conform articolului 42;</p><br>

<p>b) datele intrării în vigoare a prezentei Convenţii, în conformitate cu articolul 43;</p><br>

<p>c) denunţările conform articolului 44;</p><br>

<p>d) abrogarea prezentei Convenţii conform articolului 45;</p><br>

<p>e) notificările primite conform articolului 46;</p><br>

<p>f) declaraţiile şi notificările primite conform paragrafelor l şi 2 ale articolului 48.</p><br>

<p style="font-weight:500;">Articolul 51</p><br>

<p style="text-indent:2em;">După 31 august 1956 originalul prezentei Convenţii va fi depus la Secretarul General al Organizaţiilor Naţiunilor Unite, care va trimite 
	copii certificate fiecăreia dintre ţările vizate în paragrafele l şi 2 ale articolului 42.</p><br>

<p>Drept care subsemnaţii, valabil împuterniciţi, au semnat prezenta Convenţie.</p><br>

<p style="text-indent:2em;">Încheiată la Geneva, la nouăsprezece mai una mie nouă sute cincizeci şi şase, într-un singur exemplar, în limbile engleză şi franceză,
	ambele texte având aceeaşi valabilitate.</p><br>
	
	<p><a href="http://www.monitoruljuridic.ro/act/conventie-din-19-mai-1956-referitoare-la-contractul-de-transport-international-de-marfuri-pe-sosele-cmr-emitent-consiliul-de-stat-23458.html">Sursa</a></p>

</div>
`