import React, { Component, Fragment } from 'react'
import fetch from '../../../../components/shared/fetch'
import CommentsItems from './comments-items'
import { COMMENTS_ON_PAGE, HEIGH_FROM_TOP_TO_MYDIV } from '../forum-constants'
import ForumNoContent from '../forum-no-content'
import ForumCommentDate from '../forum-date'
import WidgetHeader from '../../../shared/components/widget-header'
import TooltipWrapper from '../../../UI/popover-wrapper'
import TooltipModal from '../../../search/table/tooltip-modal'
import { Link } from 'react-router-dom'
import { canDo } from '../../../../store/utils'
import Dropdown from '../../../UI/dropdown'
import { Field } from 'redux-form'

import './forum-need-help-item.scss'

interface ForumNeedHelpProps extends ComponentBaseProps {
  themeId: number
  themeTitle: string
  sectionTitle: string
  onLoadContentForSection: any
  isAuthenticated: boolean
  themeComments: any
  totalCount: number
  dateAdded: string
  refreshLastComments: boolean
  resetCurrentLastPagePagination: any
  commentAuthor: number
  permissions: any
  refreshLastCommentsAfterDeleteComment: any
  quoteMessage: any
  username: string
  companyName: string
  currentActiveSection: number
  onThemeMovedInOtherSextion: any
}

export default class ForumNeedHelp extends Component<ForumNeedHelpProps> {
  state = {
    pagination: {},
    themes: 0,
    openSection: 0,
    searchText: '',
    showModal: false,
    sections: [],
    pageSize: 10,
    pageIndex: 0,
    themeComments: [],
    commentsThemeTitle: '',
    showSpinner: true,
    onethemeTitle: '',
    oneThemeId: 0,
    themeId: 0,
    themeTitle: '',
    sectionTitle: '',
    totalComments: 0,
    totalCount: 0,
    oneThemeDateAdded: '',
    scrollCoordinates: 0,
    newUsername: '',
    newCompanyName: '',
    sectionByDefault: 0,
    selectedSection: 0,
  }

  componentDidMount() {
    const { themeId, themeTitle, sectionTitle } = this.props
    fetch(`/forum/getAllSections`).then(sections => {
      sections.data.forEach(({ id, by_default }) => {
        if (by_default == 1) {
          this.setState({ themeId, themeTitle, sectionTitle, sectionByDefault: id }, () => {
            this.fetchThemeForumSections()
            return
          })
        }
      })
      this.setState({ sections: sections.data })
    })
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.themeId !== prevProps.themeId ||
      this.props.themeComments != prevProps.themeComments
    ) {
      const { themeId, themeTitle, sectionTitle, username, companyName } = this.props
      const { scrollCoordinates } = this.state

      const element = document.getElementById('allCommentInCurrentTheme')
      const bodyRect = document.body.getBoundingClientRect()
      const elemRect = element.getBoundingClientRect()
      const needScrollToMyDivInPX = elemRect.top - bodyRect.top
      const realCoordinates = scrollCoordinates > HEIGH_FROM_TOP_TO_MYDIV ? scrollCoordinates : 0
      window.scrollTo({
        top: realCoordinates,
        behavior: 'smooth',
      })
      this.fetchThemeComments(themeId, this.state.pageSize, this.state.pageIndex, 'themeComments')
      this.setState({
        themeId,
        themeTitle,
        sectionTitle,
        newUsername: username,
        newCompanyName: companyName,
        scrollCoordinates: needScrollToMyDivInPX,
      })
    }
    if (this.props.currentActiveSection !== prevProps.currentActiveSection) {
      //this.fetchThemeForumSections(this.props.currentActiveSection)
    }
  }

  fetchThemeComments = (
    themeId: number,
    pageSize: number,
    pageIndex: number,
    stateName: any
  ): void => {
    fetch(`/forum/getMessagesByTheme/${themeId}/${pageSize}/${pageIndex}`).then(comments => {
      this.setState({
        [stateName]: comments.data.messages,
        totalCount: comments.data.count,
        showSpinner: false,
      })
    })
  }

  fetchThemeForumSections = (currentActiveSection = this.state.sectionByDefault) => {
    fetch(`/forum/getAllThemes/${currentActiveSection}/1/0/0`).then(themes => {
      if (themes.data.themes[0]) {
        const {
          id,
          title,
          dateAdded,
          user: { username, companyName },
          section,
        } = themes.data.themes[0]
        this.setState({
          oneThemeId: id,
          onethemeTitle: title,
          oneThemeDateAdded: dateAdded,
          newUsername: username,
          newCompanyName: companyName,
          sectionTitle: section.title,
          themeId: 0,
          themeTitle: '',
        })
        this.fetchThemeComments(id, this.state.pageSize, this.state.pageIndex, 'themeComments')
      }
    })
  }

  renderCommentsBlock = () => {
    const {
      themeId,
      refreshLastComments,
      resetCurrentLastPagePagination,
      refreshLastCommentsAfterDeleteComment,
      t,
      commentAuthor,
      permissions,
      quoteMessage,
    } = this.props

    const { oneThemeId, themeComments, totalCount } = this.state

    let pageContent
    if (themeComments.length === 0) {
      pageContent = <ForumNoContent title={t('forum.empty.theme')} />
    } else {
      const rest = totalCount % COMMENTS_ON_PAGE === 0 ? 0 : 1
      const allPages = Math.floor(totalCount / COMMENTS_ON_PAGE + rest)

      pageContent = (
        <CommentsItems
          themeId={!themeId ? oneThemeId : themeId}
          themeComments={themeComments}
          isAuthenticated={this.props.isAuthenticated}
          totalCount={totalCount}
          currentPage={!refreshLastComments ? 0 : allPages - 1}
          resetCurrentLastPagePagination={resetCurrentLastPagePagination}
          commentAuthor={commentAuthor}
          permissions={permissions}
          refreshLastCommentsAfterDeleteComment={refreshLastCommentsAfterDeleteComment}
          quoteMessage={quoteMessage}
        />
      )
    }

    return pageContent
  }

  renderCommentThemeItems = () => {
    const { dateAdded } = this.props
    const { onethemeTitle, themeTitle, oneThemeDateAdded } = this.state

    const blockThemeTitle = !themeTitle ? onethemeTitle : themeTitle
    const blockThemeDate = !dateAdded ? oneThemeDateAdded : dateAdded

    return (
      <div className="panel-content">
        <div className="panel-content-row section-theme-title">
          <div className="single-theme-title col-md-13"> {blockThemeTitle} </div>
          <div className="single-theme-date col-md-3">{ForumCommentDate(blockThemeDate)} </div>
          <div className="clear"></div>
        </div>
        {this.renderCommentsBlock()}
      </div>
    )
  }

  renderSelectOptions = () =>
    this.state.sections.map(({ id, title }) => ({
      value: id,
      name: title,
    }))

  moveInToStateThisValue = sectionId => {
    this.setState({ selectedSection: sectionId })
  }

  moveTopic = () => {
    const { themeId, onThemeMovedInOtherSextion } = this.props
    const { selectedSection } = this.state

    const body = JSON.stringify({
      themeId: themeId,
      sectionId: selectedSection,
    })

    fetch('/forum/moveTopicInOtherSection', {
      method: 'POST',
      body,
    }).then(() => {
      this.setState({ selectedSection: 0 })
      onThemeMovedInOtherSextion()
    })
  }

  renderMoveTopicELements = () => {
    const { t } = this.props
    const { selectedSection } = this.state
    const disabled = selectedSection !== 0
    return (
      <Fragment>
        <Field
          onChange={this.moveInToStateThisValue}
          className="move-topic-select"
          name="add-theme"
          component={Dropdown}
          emptyOption={''}
          items={this.renderSelectOptions()}
          placeholder={t('forum.move.theme')}
        />
        <button
          type="button"
          onClick={() => this.moveTopic()}
          disabled={!disabled}
          className="button button-orange btn-move-topic"
        >
          {t('forum.move.theme.label')}
        </button>
      </Fragment>
    )
  }

  render() {
    const {
      onLoadContentForSection: { title },
      t,
      permissions,
    } = this.props
    const { sectionTitle, themeComments } = this.state

    const canMoveTopic = canDo(permissions, 'forum', 'moveTopic')
    const blockSectionTitle = !sectionTitle ? title : sectionTitle

    const content =
      themeComments.length > 0 ? (
        this.renderCommentThemeItems()
      ) : (
        <ForumNoContent title={t('forum.empty.section')} />
      )
    return (
      <div className="panel" id="allCommentInCurrentTheme">
        <WidgetHeader title={blockSectionTitle} className="panel-header">
          {canMoveTopic && this.renderMoveTopicELements()}
        </WidgetHeader>
        {content}
      </div>
    )
  }
}
