import React, { Component } from 'react'
import { match } from 'react-router'
import { Redirect } from 'react-router-dom'

import { confirmChangeEmail } from '../../../../store/actions'

interface ChangeEmailConfirmationProps {
  confirmChangeEmail: typeof confirmChangeEmail
  match: match<{ token }>
}

export default class extends Component<ChangeEmailConfirmationProps> {
  componentDidMount() {
    const { confirmChangeEmail, match } = this.props
    const { token } = match.params
    confirmChangeEmail(token)
  }

  render() {
    return <Redirect to="/" />
  }
}
