import React, { Component } from 'react'
import Banner from '../../../banner'
import fetch from '../../../../components/shared/fetch'
import SingleItem from '../single-item'
import Pagination from '../../../UI/pagination'
import { scrollToElement } from '../../../../store/utils'
import Spinner from '../../../UI/spinner'

import "./styles.scss"

export interface IContentReviewsProps {
  pageSize: number
  activeFilter: string
  t: any
}

export interface IContentReviewsState {
  items: any,
  isFetching: boolean
  pageIndex: number
  totalItems: number
}

export default class ContentReviews extends Component<IContentReviewsProps, IContentReviewsState> {
  state = {
    items: [],
    isFetching: false,
    pageIndex: 0,
    totalItems: 0,
  }

  getReviewsType = (filter) => {
    let type

    switch (filter) {
      case "usersReviews":
        type = "USER"
        break;

      case "companiesReviews":
        type = "COMPANY";
        break;
      default:
        type = "ALL";
        break;
    }

    return type
  }

  componentDidMount() {
    const { pageSize, activeFilter } = this.props
    const { pageIndex } = this.state
    this.fetchData(pageIndex, pageSize, activeFilter)
  }

  componentDidUpdate(prevProps) {
    const { pageSize, activeFilter } = this.props
    const { pageIndex } = this.state
    if (prevProps.pageSize !== pageSize || prevProps.activeFilter !== activeFilter) {
      this.fetchData(pageIndex, pageSize, activeFilter)
    }
  }

  fetchData = (pageIndex, pageSize, activeFilter) => {
    this.setState({ isFetching: true })
    const data = {
      type: this.getReviewsType(activeFilter),
      pageIndex,
      pageSize
    }
    fetch(`/reviews`, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then(res => {
      this.setState({
        items: res.data.items,
        totalItems: res.data.total,
        isFetching: false,
      })
    })
  }

  renderItems = () => {
    const { activeFilter } = this.props
    const { items } = this.state

    const itemType = "reviews"
    const filterType = this.getReviewsType(activeFilter)
    
    const content = items.map((itemData, index) => {
      return <SingleItem
        key={index}
        {...{ filterType }}
        {...{ itemType }}
        {...{ itemData }}
      />
    })

    return content
  }

  paginationClick = page => {
    const { pageSize, activeFilter } = this.props
    this.setState({ pageIndex: page }, () => {
      this.fetchData(page, pageSize, activeFilter)
      scrollToElement("latter-reviews-claims")
    })
  }

  render() {
    const { totalItems, pageIndex, isFetching } = this.state
    const { pageSize } = this.props

    if (isFetching) {
      return <Spinner />
    }

    return (
      <div className="tabs-content" id="content-reviews">
        <div className="panel panel-message-main">
          <div className="panel-content">
            <div className="panel-content-row panel-content-row-message">
              {this.renderItems()}
            </div>
          </div>
        </div>
        <Banner bannerId={587} />
        <div className="pagination-block">
          <Pagination
            onClick={this.paginationClick}
            total={totalItems}
            {...{ pageIndex }}
            {...{ pageSize }}
          />
        </div>
      </div>
    )
  }
}
