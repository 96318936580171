import isNil from 'lodash/isNil'
import isArray from 'lodash/isArray'
import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import uuid from 'uuid/v4'
import PopoverWrapper from '../popover-wrapper'
import Spinner from '../spinner'
import DropdownBase, { DropdownName, DropdownPanel, DropdownPopover } from '../dropdown-base'
import FlexBand from 'flexband'

import './styles.scss'

const STYLE_BASE = 'dropdown_'

export default class Dropdown extends Component {
  guid = `ddl${uuid()}`
  state = {
    hoverValue: undefined,
    options: [],
  }

  componentDidMount() {
    const { items, input } = this.props
    this.refreshItems(items)
    if (input.value !== '') {
      input.onChange(input.value)
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.refreshItems(this.props.items)
    }
  }

  toggle = () => {
    const { disabled, activeGuid, setActivePopup } = this.props
    if (disabled) return
    const guid = activeGuid === this.guid ? undefined : this.guid
    setActivePopup(guid)
  }
  closePopup = () => this.props.setActivePopup(undefined)

  getStatus = () => {
    const { items = {} } = this.props
    if (isArray(items)) {
      return {
        loading: false,
        loaded: true,
      }
    } else {
      // 'items' is resolved Promise, see /shared/dictionaries/index.js - getDictionary()
      const { loaded = false, loading = false } = items
      return {
        loaded,
        loading,
      }
    }
  }

  getValidationClassName = () => {
    const { meta: { pristine } = {}, isRequired } = this.props

    if (!isRequired) return null

    if (pristine) return `${STYLE_BASE}required`
    return `${STYLE_BASE}valid`
  }

  refreshItems = data => {
    const { emptyOption, sortAlphabetic = false, useTranslate = false, t } = this.props

    // helper function
    const initItems = items => {

      let options
      const arr = []
      if(useTranslate){
        items.forEach(i => arr.push({name:t(i.name), value: i.value}))
        options = arr
      } else {
        options = items || []
      }
     
      if (emptyOption) {
        options = [{ value: '', name: emptyOption }, ...options]
      }

      this.setState({ options })
    }

    if (data) {
      if (isArray(data)) {
        initItems(data)
      } else if (data.type === 'dictionary') {
        const { loading, loaded, handler } = data

        // set up items
        if (loading) {
          handler.then(x => initItems(!sortAlphabetic ? x.items : x.itemsSorted))
        } else if (loaded) {
          initItems(!sortAlphabetic ? data.items : data.itemsSorted)
        }
      }
    } else {
      this.setState({ options: [] })
    }
  }

  getItemContent = item => {
    if (item.companyDelimiter) {
      return this.props.t('company.autopark.dropdown')
    } else if (item.userDelimiter) {
      return this.props.t('user.autopark.dropdown')
    }
  }

  render() {
    const {
      input = {},
      meta: { touched, error } = {},
      emptyOption,
      placeholder,
      className,
      tooltip,
      activeGuid,
      disabled,
      itemDeleting,
      onDeleteItem,
    } = this.props
    const { hoverValue, options } = this.state
    const { loading } = this.getStatus()
   
    const selectedValue = options.find(
      x => !isNil(input.value) && x.value.toString() === input.value.toString()
    )
    const showDropdown = activeGuid === this.guid

    const validationClassName = this.getValidationClassName()

    let { name: label = emptyOption } = selectedValue || {}
    label = label || placeholder

    return (
      <Fragment>
        <DropdownBase
          className={classNames({
            'chosen-container chosen-container-single chosen-container-single-nosearch': true,
            [`${STYLE_BASE}wrapper`]: true,
            [`${STYLE_BASE}disabled`]: disabled,
            [className]: !!className,
          })}
          style={{ width: '100%' }}
        >
          <DropdownName>
            <Link
              className={classNames({
                'chosen-single': true,
                [validationClassName]: !!validationClassName,
              })}
              to="#"
              onClick={event => {
                event.preventDefault()
                this.toggle()
              }}
            >
              <span>{label}</span>
              <div>
                <b />
              </div>
            </Link>
          </DropdownName>

          <DropdownPanel>
            {showDropdown && (
              <div className="chosen-drop">
                <div className="chosen-search">
                  <input type="text" autoComplete="off" readOnly="" />
                </div>
                {loading ? (
                  <Spinner />
                ) : (
                  <ul className="chosen-results">
                    {options.map((item, index) => (
                      <li
                        key={index}
                        className={classNames('active-result', {
                          highlighted: item.value === hoverValue,
                          'dropdown-delimiter': item.companyDelimiter || item.userDelimiter,
                        })}
                        content={this.getItemContent(item)}
                        onClick={() => {
                          input.onChange(item.value)
                          this.closePopup()
                        }}
                        onMouseEnter={() => this.setState({ hoverValue: item.value })}
                        onMouseLeave={() => this.setState({ hoverValue: undefined })}
                      >
                        <FlexBand
                          justify="space-between"
                          className={classNames({
                            'result-selected': item.value === input.value,
                          })}
                        >
                          <div className="chosen-drop-item">{item.name || ` `}</div>
                          {itemDeleting && item.name !== emptyOption && (
                            <i
                              className="fa fa-close delete-button"
                              onClick={() => onDeleteItem(item.value)}
                            />
                          )}
                        </FlexBand>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </DropdownPanel>

          <DropdownPopover>
            {tooltip && (
              <PopoverWrapper text={tooltip}>
                <i className="fa fa-question-circle col-blue tooltip-trigger" />
              </PopoverWrapper>
            )}
          </DropdownPopover>
        </DropdownBase>

        {touched && error && <div className="validation-message red">{error}</div>}
      </Fragment>
    )
  }
}
