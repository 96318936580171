import React, { Component, Fragment } from 'react'
import WidgetList from '../../shared/components/widget-list'
import dict from '../../shared/dictionaries'
import fetch from '../../shared/fetch'
import { IAdsListMiniProps, IAdsListMiniState } from './types'

export default class extends Component<IAdsListMiniProps, IAdsListMiniState> {
  filterBar = {
    items: dict.filterOptionsForAds(this.props.langId),
    emptyOption: this.props.t('all'),
    selectedKey: undefined,
    onClick: filterKey => this.fetchAds(filterKey.length === 0 ? undefined : filterKey),
  }

  state = {
    adsList: [],
  }

  componentDidMount() {
    const { selectedKey } = this.filterBar
    this.fetchAds(selectedKey)
  }

  fetchAds = filterKey => {
    this.filterBar.selectedKey = filterKey
    fetch(
      `/announcement/list?page_index=0&page_size=5&tree_type_id=${filterKey || 436}&active=1`
    ).then(adsList => this.setState({ adsList }))
  }

  render() {
    const { adsList } = this.state
    const { t, dataMoveForMobile } = this.props

    return (
      <Fragment>
        <WidgetList
          title={t('ads.last').toUpperCase()}
          items={adsList}
          filterBar={this.filterBar}
          textPropName="title"
          loadLanguages
          linksTo={{ title: '/ads/list/436', item: '/ads/item' }}
          {...{ dataMoveForMobile }}
        />
      </Fragment>
    )
  }
}
