import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { Link, Redirect } from 'react-router-dom'
import { Field } from 'redux-form'
import uuid from 'uuid/v4'
import { Popover, PopoverBody } from 'reactstrap'
import FlexBand, { FlexBandItem } from 'flexband'
import { toastr } from 'react-redux-toastr'
import TooltipWrapper from '../../../UI/popover-wrapper'
import {
  PRICE_TYPE_MY_PRICE,
  PRICE_TYPE_REQUEST,
  PRICE_TYPE_AGREEMENT,
} from '../../../../store/constants'
import WidgetBase from '../../../shared/components/widget-base'
import FilterFieldRender from '../../../UI/filter-field'
import Map from '../../../UI/openstreetmaps-widget'

import { ModalConsumer, ModalContextState } from '../../../../context/modal-context'
import { required, moreThanZero } from '../../../shared/form-validators'
import Dropdown from '../../../UI/dropdown'
import { CheckboxField } from '../../../UI/checkbox'
import dict from '../../../shared/dictionaries'
import ProfileMini from '../../../user/profile-mini'
import DateSelectorCalendar from '../../../shared/components/date-selector-calendar'
import TemperatureFromTo from '../../../shared/components/temperature-mode-from-to'
import PopoverWrapper from '../../../UI/popover-wrapper'
import RenderNumber from '../../../shared/components/render-number'
import CountryCityRadiusFromTo from '../../../shared/components/country-city-radius_from-to'
import VolumeFields from '../../../shared/components/volume-fields'
import SaveAsTemplateForm from './save-as-template'
import TransportationInfo from '../transportation'
import getFormInputNames from '../../../shared/getFormInputNames'
import Captcha from '../../../captcha'
import { canDo, addScrollBar } from '../../../../store/utils'
import { CargoAddProps, ICargoCheckboxesOpts, CargoAddState } from './types'
import TooltipForDownload from '../../../UI/tooltip-for-download'
import TooltipForDownloadTemp from '../../../UI/tooltip-for-download-temp'

import './styles.scss'

const STYLE_BASE = 'cargo-add_'

export default class extends Component<CargoAddProps, CargoAddState> {
  modalConsumer: ModalContextState
  butAddId: string
  butAddAndContinueId: string
  optionsDocuments: ICargoCheckboxesOpts[]
  optionsLoadingSide: ICargoCheckboxesOpts[]
  item: ICargoTransportEdit

  constructor(props) {
    super(props)

    this.butAddId = `id${uuid()}`
    this.butAddAndContinueId = `id${uuid()}`

    this.state = {
      redirectTo: undefined,
      popoverId: undefined,
      mapPoints: {
        from: {
          value: '',
          coords: null,
          marker: null,
        },
        to: {
          value: '',
          coords: null,
          marker: null,
        },
        middlePoints: [],
      },
      mapInitValues: {},
      captchaVerified: false,
    }

    const { t } = props
    this.optionsDocuments = [
      { name: 'cargo.isTir', label: 'TIR' },
      { name: 'cargo.isCmr', label: 'CMR' },
      { name: 'cargo.isDeclaration', label: t('cargo.on.declaration') },
      { name: 'cargo.isCustom', label: t('under.t1.declaration') },
      { name: 'cargo.isCmrInsurance', label: t('cargo.insurance.cmr') },
    ]
    this.optionsLoadingSide = [
      { name: 'transport.isLoadingTop', label: t('cargo.loading.top') },
      { name: 'transport.isLoadingSide', label: t('cargo.loading.side') },
      { name: 'transport.isLoadingRare', label: t('cargo.loading.rare') },
    ]
  }

  componentDidMount() {
    const {
      isAuthenticated,
      getTemplates,
      getItem,
      initialize,
      match,
      permissions,
      onCanAdd,
      change,
    } = this.props
    const { itemId } = match.params
    if (isAuthenticated) {
      getTemplates('cargo')
      if (itemId) {
        getItem({ itemId, isEdit: true }).then(cargoItem => {
          this.item = cargoItem
          if (cargoItem.from?.address) {
            this.item.filterKey = 'manual'
          } else {
            this.item.filterKey = 'choose'
          }
          initialize(this.item)
          this.setInitPoints(this.item)
        })
      } else {
        onCanAdd('cargo')
      }
      change('transport.quantity', 1)
    }
    if (canDo(permissions, 'captcha', 'skip')) {
      this.setState({ captchaVerified: true })
    }
    addScrollBar()
  }

  setInitPoints = item => {
    const { from, to, middlePoints } = item
    this.setState({
      mapInitValues: {
        from: {
          country: from?.countryId,
          city: from?.cityId,
          coords: from?.coords,
        },
        to: {
          country: to?.countryId,
          city: to?.cityId,
          coords: to?.coords,
        },
        middlePoints,
      },
    })
  }

  componentDidUpdate(prevProps) {
    const {
      initialize,
      initialValues,
      isAuthenticated,
      getTemplates,
      match,
      shippingType,
      change,
    } = this.props
    const { itemId } = match.params
    if (shippingType !== prevProps.shippingType) {
      change('transport.quantity', 1)
    }
    if (isAuthenticated !== prevProps.isAuthenticated) {
      getTemplates('cargo')
    }
    if (prevProps.template !== this.props.template) {
      this.setInitPoints(initialValues)
      initialize(initialValues)
    }
    if (prevProps.match.params.itemId !== itemId) {
      if (itemId) {
        initialize(this.item)
      } else {
        initialize(initialValues)
      }
    }
  }

  toggleValidationError = e => {
    this.setState({ popoverId: undefined })
  }

  onClickMap = (place, insertingItem) => {
    const { mapPoints } = this.state
    const { change } = this.props

    if (!place.value || place.value === '') {
      place.coords = null
    }

    const insItemSplitted = insertingItem.split('-')

    let insertingPlace
    if (insItemSplitted[0] === 'middlePoint') {
      insertingPlace = mapPoints.middlePoints[insItemSplitted[1]]
    } else {
      insertingPlace = mapPoints[insertingItem]
      change(insertingItem, place)
    }

    // eslint-disable-next-line no-unused-vars
    for (const i in place) {
      insertingPlace[i] = place[i]
    }
    this.setState({ mapPoints })
  }

  onMapAddMiddlePoint = (): Promise<number> => {
    return new Promise((resolve, reject) => {
      const { middlePoints } = this.state.mapPoints

      middlePoints.length <= 10 &&
        middlePoints.push({
          value: '',
          coords: null,
          marker: null,
          isLoadingPlace: false,
          city: null,
          country: null,
        })
      this.forceUpdate(() => {
        try {
          resolve(middlePoints.length - 1)
        } catch (e) {
          reject(e)
        }
      })
    })
  }

  onMapRemoveMiddlePoint = id => {
    this.state.mapPoints.middlePoints.splice(id, 1)

    this.forceUpdate()
  }

  handlePointLoadingPlace = id => {
    const { middlePoints } = this.state.mapPoints

    middlePoints[id].isLoadingPlace = !middlePoints[id].isLoadingPlace

    this.forceUpdate()
  }

  onAdd = (butId, isContinue?) => {
    const {
      t,
      invalid,
      handleSubmit,
      reset,
      touch,
      enteredValues,
      match,
      addCargo,
      getUserStatistics,
    } = this.props
    const { itemId } = match.params
    const {
      mapPoints: { from, to, middlePoints },
    } = this.state

    delete from.marker
    delete to.marker

    middlePoints.forEach(point => {
      delete point.marker
    })

    const formNames = getFormInputNames(enteredValues, '')

    touch(...formNames)

    if (invalid || !from || !to) {
      this.setState({ popoverId: butId })
      return
    }

    const middlePointsString = JSON.stringify(middlePoints)

    handleSubmit(values =>
      addCargo(
        { item: { ...values, mapPoints: { from, to }, middlePoints: middlePointsString, itemId } },
        response => {
          itemId
            ? toastr.success(t('cargo.edited'), t('cargo.message.success.edit'), { timeOut: 4000 })
            : toastr.success(t('cargo.added'), t('cargo.message.success'), { timeOut: 4000 })
          getUserStatistics('profile')
          if (isContinue) {
            window.location.reload()
            reset()
          } else {
            this.setState({ redirectTo: `/cargo/${response.d.itemId}` })
          }
        }
      )
    )()
  }
  onClickAddContinue = () => this.onAdd(this.butAddAndContinueId, true)
  onClickAdd = () => this.onAdd(this.butAddId)

  renderPopover = butId => {
    return (
      <Popover
        placement="top"
        isOpen={this.state.popoverId === butId}
        target={butId}
        toggle={this.toggleValidationError}
      >
        <PopoverBody>{this.props.t('validation.not-all-required-fields-entered')}</PopoverBody>
      </Popover>
    )
  }

  renderHeaderMenu = () => {
    const { templates, t } = this.props

    const items = [
      ...templates.map(({ name, id }) => ({
        name,
        value: parseInt(id),
      })),
    ]

    return (
      <div className="select-template">
        <div className="select-container">
          <Field
            name="template"
            component={Dropdown}
            items={items}
            emptyOption={t('complete.by.template')}
            itemDeleting
            onDeleteItem={this.onDeleteTemplate}
          />
        </div>
      </div>
    )
  }

  onDeleteTemplate = (id: number) => {
    this.props.deleteTemplate(id, 'cargo')
  }

  onSaveAsTemplate = ({ name }) => {
    this.props.saveAsTemplate({ name, item: this.props.enteredValues }, 'cargo')
    this.modalConsumer.clearModal()
  }

  showSaveAsTemplate = () => {
    const { t } = this.props
    const { showModalComponent, clearModal } = this.modalConsumer
    showModalComponent(
      <SaveAsTemplateForm onSave={this.onSaveAsTemplate} onCancel={clearModal} />,
      {
        title: t('save.as.template'),
        className: `${STYLE_BASE}modal`,
        backdrop: true,
      }
    )
  }

  transportTypesChange = () => {
    this.props.change('transport.types', [])
  }

  onChooseChange = () => {
    const { change } = this.props
    change('from.coords', null)
    change('to.coords', null)
    change('from.address', null)
    change('to.address', null)
    this.setState({
      mapPoints: {
        from: {
          value: '',
          coords: null,
          marker: null,
        },
        to: {
          value: '',
          coords: null,
          marker: null,
        },
        middlePoints: [],
      },
    })
  }

  handleFilterChange = value => {
    const { mapPoints } = this.state
    const {
      change,
      fromCountryId,
      toCountryId,
      fromCityId,
      toCityId,
      fromCoords,
      toCoords,
      middlePoints,
    } = this.props

    if (value !== 'manual') {
      // eslint-disable-next-line no-unused-vars
      for (const i in mapPoints) {
        if (i !== 'from' && i !== 'to') continue
        const { countryId = null, cityId = null } = mapPoints[i]
        const initCountry = this.props[`${i}CountryId`]
        const initCity = this.props[`${i}CityId`]
        if (countryId) {
          if (countryId !== initCountry) {
            change(`${i}.countryId`, countryId)
            change(`${i}.cityId`, null)
          }

          if (cityId !== initCity || !cityId) {
            change(`${i}.cityId`, cityId)
          }
        }
        // else {
        //   change(`${i}.countryId`, null)
        // }
      }
    }
  }

  render() {
    const { t, canAdd } = this.props
    const { captchaVerified, redirectTo, mapPoints, mapInitValues } = this.state

    if (redirectTo) {
      return <Redirect to={redirectTo} push />
    }

    const {
      filterKey,
      fromCountryId,
      toCountryId,

      submitting,
      change,
      langId,
      priceType,
      shippingType,
      notes = '',
      shipOnDate,
      form: formName,
      match,
      permissions,
    } = this.props

    const { itemId } = match.params

    const filterItems = dict.cargoAddFilterItems(t)

    const cargoTypes = dict.cargoTypes(langId)

    const showCaptcha = !canDo(permissions, 'captcha', 'skip')

    const disabled = submitting || !captchaVerified || (!itemId && !canAdd)
    const placement = window.innerWidth < 450 ? 'left' : 'top'

    const isContainerShippingType = shippingType === 'container'
    const countTransportTypes = isContainerShippingType
      ? t('transport.container.number')
      : t('transport.auto.number.optional')

    return (
      <form>
        <WidgetBase
          title={itemId ? `${t('cargo.edit')} (id: ${itemId})` : t('cargo.add')}
          headerMenu={this.renderHeaderMenu}
          className={classNames({
            [`${STYLE_BASE}wrapper`]: true,
          })}
        >
          <div
            data-fix-col="true"
            className="panel-body"
            onClick={() => this.setState({ popoverId: null })}
          >
            <Field
              name="filterKey"
              component={FilterFieldRender}
              items={filterItems}
              onFilterClick={this.handleFilterChange}
              className="tabs-list"
              loadLanguages
            />
            <div className="panel-content">
              <div className="panel-form">
                {/* 
                *********************************
                filter - 'manual' showing Map
                ********************************* 
                */}
                {filterKey === 'manual' ? (
                  <Map
                    className={classNames({
                      'map-container': true,
                      [`${STYLE_BASE}map-container`]: true,
                    })}
                    onClick={this.onClickMap}
                    onMapAddMiddlePoint={this.onMapAddMiddlePoint}
                    onMapRemoveMiddlePoint={this.onMapRemoveMiddlePoint}
                    handlePointLoadingPlace={this.handlePointLoadingPlace}
                    mapPoints={mapPoints}
                    mapInitValues={mapInitValues}
                    itemLabelFrom={t('cargo.location.from')}
                    itemLabelTo={t('cargo.location.to')}
                  />
                ) : (
                  <CountryCityRadiusFromTo
                    className="panel-content-row"
                    titleFrom={t('cargo.location.from')}
                    titleTo={t('cargo.location.to')}
                    fromCountryId={fromCountryId}
                    toCountryId={toCountryId}
                    onChange={this.onChooseChange}
                    fromEntity="cargo"
                  />
                )}

                <div className="panel-content-row">
                  <div className="panel-form-row">
                    <div className="row">
                      <DateSelectorCalendar
                        className="col-xl-8"
                        shipOnDate={shipOnDate}
                        basePropName="shipOn"
                        change={change}
                        formName={formName}
                        disableOutdated
                      />
                    </div>
                  </div>
                </div>
                <TransportationInfo
                  langId={langId}
                  shippingType={shippingType}
                  entityType="cargo"
                  onChange={this.transportTypesChange}
                  itemId={itemId || null}
                />
                <div
                  className="panel-content-row"
                  style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}
                >
                  <FlexBand className="cargo-options" alignItems="center">
                    <div className="cargo-types">
                      <label className="required-field label-bold">{t('cargo.type')}</label>
                      <Field
                        name="cargo.type"
                        component={Dropdown}
                        items={cargoTypes}
                        validate={required}
                        placeholder={`${t('choose')}...`}
                      />
                    </div>
                  </FlexBand>
                  <FlexBand
                    className="cargo-options additional-cargos-options"
                    alignItems="center"
                    style={{ flexDirection: 'column', height: 43, marginLeft: 10 }}
                  >
                    <div>
                      <div className="input-container-sel">
                        <CheckboxField name="cargo.isGrouping">
                          {t('cargo.grouping')}
                          <i className="fa fa-th-large" />
                        </CheckboxField>
                        <PopoverWrapper
                          {...{ placement }}
                          hover
                          text={<div>{t('cargo.grouping.description')}</div>}
                        >
                          <i className="fa fa-question-circle col-blue tooltip-trigger" />
                        </PopoverWrapper>
                      </div>
                    </div>
                    <div>
                      <CheckboxField name="cargo.isAdr">
                        {`ADR`}
                        <i className="fa fa-fire" />
                      </CheckboxField>{' '}
                      <div className="select-wrapper">
                        <Field
                          name="cargo.adrClass"
                          component={Dropdown}
                          items={dict.adrClasses}
                          className={`${STYLE_BASE}adr-class`}
                        />
                      </div>
                      <PopoverWrapper
                        {...{ placement }}
                        hover
                        clickOnUrl
                        text={
                          <div>
                            {langId === 3 || langId === 2 ? (
                              <a href="https://www.legis.md/cautare/getResults?doc_id=118009&lang=ro" target="_blank" rel="noopener noreferrer">
                                {t('adr.description')}
                              </a>
                            ) : (
                              <Link to="/useful/legislation/int/int18" target="_blank" rel="noopener noreferrer">{t('adr.description')}</Link>
                            )}
                          </div>
                        }
                      >
                        <i className="fa fa-question-circle col-blue tooltip-trigger" />
                      </PopoverWrapper>
                    </div>
                    <div>
                      <TemperatureFromTo
                        text={t(`cargo.temperature.mode`)}
                        fieldNameCheckbox="cargo.isClimateControl"
                        fieldNameFrom="cargo.temperatureFrom"
                        fieldNameTo="cargo.temperatureTo"
                      />
                      <PopoverWrapper
                        {...{ placement }}
                        hover
                        clickOnUrl
                        text={<TooltipForDownloadTemp t={t} langId={langId} />}
                      >
                        <i className="fa fa-question-circle col-blue tooltip-trigger" />
                      </PopoverWrapper>
                    </div>
                  </FlexBand>
                </div>
                <div className="panel-content-row">
                  <div className="panel-form-row" style={{ display: 'flex' }}>
                    <div className="panel-form-col-xs">
                      <label className="required-field" htmlFor="s3">
                        {t('cargo.weight.2')}
                      </label>
                      <div className="input-text">
                        <Field
                          name="cargo.weight.value"
                          component={RenderNumber}
                          type="text"
                          placeholder="00.00"
                          unit="t"
                          integerLimit={2}
                          decimalLimit={2}
                          validate={required}
                        />
                      </div>
                    </div>
                    <VolumeFields
                      className="panel-form-col"
                      fieldNameM3="cargo.volume.m3"
                      fieldNameLdm="cargo.volume.ldm"
                      fieldNamePal="cargo.volume.pal"
                      formName={formName}
                      validate={required}
                    />
                    <div className="panel-form-col select-container-no-label">
                      <div className="inputs-iconed">
                        <div className="input-container-sel">
                          <CheckboxField name="cargo.isOversized">
                            {t('no.gabarit')}
                            <i className="ico ico-weight" />
                          </CheckboxField>
                          <PopoverWrapper
                            {...{ placement }}
                            hover
                            clickOnUrl
                            text={<TooltipForDownload t={t} />}
                          >
                            <i className="fa fa-question-circle col-blue tooltip-trigger" />
                          </PopoverWrapper>
                        </div>
                        <div className="input-container-sel input-toggle-select">
                          <CheckboxField name="cargo.isFragile">
                            {t('cargo.fragile')}
                            <i className="fa fa-glass" />
                          </CheckboxField>{' '}
                          <PopoverWrapper
                            {...{ placement }}
                            hover
                            text={<div>{t('cargo.fragile.description')}</div>}
                          >
                            <i className="fa fa-question-circle col-blue tooltip-trigger" />
                          </PopoverWrapper>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel-content-row">
                  <div className="panel-form-row">
                    <div className="row row-lg">
                      <div className="col-sm-8 col-md-16 col-lg-8">
                        <span className="label">{t('loading.unloading') + ':'}</span>
                        <div className="checkboxes-aligned clearfix">
                          {this.optionsLoadingSide.map((x, j) => (
                            <div className="panel-form-col-md" key={j}>
                              <CheckboxField name={x.name}>{x.label}</CheckboxField>
                            </div>
                          ))}
                        </div>
                      </div>
                      {shippingType !== 'avia' && (
                        <div className="col-sm-8 col-md-16 col-lg-8">
                          <label className={`${isContainerShippingType && 'required-field'}`}>
                            {countTransportTypes}
                          </label>
                          <div className="select-container-float">
                            <div className="select-sm">
                              {isContainerShippingType ? (
                                <Field
                                  name="transport.quantity"
                                  component={RenderNumber}
                                  type="text"
                                  placeholder="00"
                                  integerLimit={2}
                                  decimalLimit={0}
                                  validate={[required, moreThanZero]}
                                  style={{ width: '50px' }}
                                  checkNoZero
                                />
                              ) : (
                                <Field
                                  name="transport.quantity"
                                  component={RenderNumber}
                                  type="text"
                                  placeholder="00"
                                  integerLimit={2}
                                  decimalLimit={0}
                                  validate={moreThanZero}
                                  style={{ width: '50px' }}
                                  checkNoZero
                                />
                              )}
                            </div>
                            <div className="select-xl">
                              <Field
                                name="transport.recurring"
                                component={Dropdown}
                                items={dict.truckRecurings}
                                style={{ width: '150px' }}
                                useTranslate
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {!isContainerShippingType && (
                  <>
                    <div className="panel-content-row">
                      <div className="panel-form-row">
                        <span className="label">{`${t('required.documents')} (${t(
                          'optional'
                        )})`}</span>
                        <div className="checkboxes-aligned clearfix">
                          {this.optionsDocuments.map((x, j) => (
                            <div className="panel-form-col" key={j}>
                              <CheckboxField name={x.name}>{x.label}</CheckboxField>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="panel-content-row">
                      <div className="panel-form-row">
                        <label
                          className={classNames({
                            'required-field': priceType === PRICE_TYPE_MY_PRICE,
                          })}
                        >
                          {t('price') + ':'}
                        </label>
                        <FlexBand
                          wrap="nowrap"
                          justify="space-between"
                          alignItems="baseline "
                          style={{ width: '84%' }}
                          className="prices-container"
                        >
                          <FlexBandItem style={{ width: 210 }}>
                            <Field
                              name="price.type"
                              component={Dropdown}
                              items={dict.priceTypes}
                              useTranslate
                            />
                          </FlexBandItem>
                          <FlexBandItem style={{ width: 80 }}>
                            {priceType === PRICE_TYPE_REQUEST ? (
                              <div className="label">{t('request.a.bid')}</div>
                            ) : priceType === PRICE_TYPE_AGREEMENT ? (
                              <div className="label">{t('negotiated')}</div>
                            ) : priceType === PRICE_TYPE_MY_PRICE ? (
                              <Field
                                name="price.value"
                                component={RenderNumber}
                                type="text"
                                placeholder="00000"
                                integerLimit={5}
                                decimalLimit={0}
                                validate={required}
                                style={{ width: 80 }}
                              />
                            ) : null}
                          </FlexBandItem>
                          <FlexBandItem style={{ width: 80 }}>
                            <Field
                              name="price.currency"
                              component={Dropdown}
                              items={dict.currencies(langId)}
                            />
                          </FlexBandItem>
                          <FlexBandItem style={{ width: 160 }}>
                            <Field
                              name="price.recurring"
                              component={Dropdown}
                              items={dict.priceRecurrings}
                              useTranslate
                            />
                          </FlexBandItem>
                        </FlexBand>
                      </div>
                    </div>
                    <div className="panel-content-row">
                      <div className="panel-form-row">
                        <span className="label">{`${t('cargo.preferred.payment.method')}:`}</span>
                        <div className="checkboxes-light clearfix">
                          {dict.cargo_options.map((column, j1) => (
                            <div className="panel-form-col-md" key={j1}>
                              {column.map((row, j2) => {
                                return (
                                  <div className="input-container" key={j2}>
                                    <CheckboxField name={'options.' + row.fieldName}>
                                      {t(row.displayName)}
                                    </CheckboxField>
                                  </div>
                                )
                              })}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="panel-content-row">
                  <div className="panel-form-row clearfix">
                    <span className="label fl-left">{`${t('addition-info-optional')}: `}</span>
                    <span className="col-gray length-countdown fl-right">
                      <span className="count">{notes.length}</span>
                      {` / `}
                      <span className="length">250</span>
                    </span>
                    <Field
                      name="notes"
                      component="textarea"
                      placeholder={t('character.limit.250')}
                      style={{ height: '100px', marginBottom: '10px' }}
                      maxLength={250}
                    />
                  </div>
                  <div className="captcha-position">
                    {showCaptcha && (
                      <Captcha
                        verifyCallback={key => {
                          this.setState({ captchaVerified: true })
                          this.props.change('captchaKey', key)
                        }}
                        expiredCallback={() => this.setState({ captchaVerified: false })}
                      />
                    )}
                    <div>
                      <span className="color-red">*</span> - {t('required.field')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </WidgetBase>

        <p className="fs-14">{t('contact.details.publishing')}</p>

        <ProfileMini />

        <div className="btns-template clearfix">
          <ModalConsumer>
            {modalConsumer => {
              this.modalConsumer = modalConsumer
              return (
                <Link to="#" className="link-button fl-left" onClick={this.showSaveAsTemplate}>
                  {t('save.as.template')}
                </Link>
              )
            }}
          </ModalConsumer>

          {!itemId && (
            <Fragment>
              <button
                className="button button-orange fl-right"
                {...{ disabled }}
                type="button"
                onClick={this.onClickAddContinue}
                id={this.butAddAndContinueId}
              >
                {t('cargo.add.create')}
              </button>
              {this.renderPopover(this.butAddAndContinueId)}
            </Fragment>
          )}
          <div className="allow-btn-container">
            <button
              className="button button-orange fl-right"
              {...{ disabled }}
              type="button"
              onClick={this.onClickAdd}
              id={this.butAddId}
            >
              {itemId ? t('save.changes') : t('add')}
            </button>
            {!itemId && !canAdd && (
              <TooltipWrapper text={t('cargo.limit')} hover>
                <i className="fa fa-question-circle m-l-5" style={{ marginTop: 8 }} />
              </TooltipWrapper>
            )}
          </div>

          {this.renderPopover(this.butAddId)}
        </div>
      </form>
    )
  }
}
