export const eu3 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Obiective şi organisme</h4><p></p>

<p style="font-weight:500;">Foaie de parcurs pentru un spațiu european unic al transporturilor: Către un sistem de transport competitiv și eficient din punct de vedere al resurselor</p><br>

<p style="text-indent:2em;">Transporturile sunt fundamentale pentru economia și societatea noastră. Transporturile le permit oamenilor să se bucure de libertatea de a călători, transferă mărfurile pe 
întregul continent și creează creștere și locuri de muncă. În același timp, transporturile trebuie să fie sustenabile având în vedere constrângerile tot mai mari în materie
de resurse și de mediu.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Carte albă Foaie de parcurs pentru un spațiu european unic al transporturilor - Către un sistem de transport competitiv și eficient din punct de vedere al resurselor [COM(2011)
144 final din 28 martie 2011].</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Transporturile sunt fundamentale pentru economia și societatea noastră. Transporturile le permit oamenilor să se bucure de libertatea de a călători, transferă mărfurile pe întregul
continent și creează creștere și locuri de muncă. În același timp, transporturile trebuie să fie sustenabile având în vedere constrângerile tot mai mari în materie de resurse și de mediu.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL FOII DE PARCURS?</p><br>

<p style="text-indent:2em;">Actul stabilește 40 de puncte de acțiune specifice și enumeră 131 de inițiative concrete pentru deceniul următor în vederea construirii unui sistem de transport competitiv care va îndepărta
blocajele majore și va ajuta la transportul persoanelor și al mărfurilor în mod eficient și sigur în întreaga Uniune Europeană (UE). Propunerile ar trebui să reducă dependența UE de petrolul
importat, să implementeze o mobilitate urbană practic lipsită de CO2 în marile aglomerări urbane până în 2030 și să reducă emisiile de dioxid de carbon aferente transporturilor cu 60 % până
în 2050.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Foaia de parcurs identifică următoarele obiective pentru orientarea măsurilor de politică:</h6><p></p>

<p style="text-indent:2em;">înjumătățirea utilizării în orașe a autovehiculelor alimentate cu motorină și benzină până în 2030, dispariția completă a acestora până în 2050 și implementarea unei mobilități urbane
lipsite de CO2 până în 2030;</p><br>

<p>creșterea utilizării combustibililor sustenabili cu conținut scăzut de carbon în transporturile aeriene la 40 % până în 2050;</p><br>

<p>reducerea emisiilor de CO2 ale UE rezultate din combustibilii de nave cu 40 % până în 2050;</p><br>

<p style="text-indent:2em;">transferarea către transportul pe calea ferată sau pe căile navigabile a unui procent de 30 % din transportul rutier de mărfuri pe distanțe de peste 300 km până în 2030, acest procent
trebuind să depășească 50 % până în 2050;</p><br>

<p style="text-indent:2em;">triplarea rețelei feroviare de mare viteză existente până în 2030. Majoritatea transportului de călători pe distanțe medii ar trebui să se efectueze pe calea ferată până în 2050;</p><br>

<p style="text-indent:2em;">stabilirea unei rețele primare TEN-T complet funcționale la nivelul întregii UE, care să integreze toate formele de transport până în 2030;</p><br>

<p style="text-indent:2em;">conectarea aeroporturilor majore la rețeaua feroviară, precum și porturile maritime primare la sistemul feroviar și la cel de căi navigabile interioare până în 2050;</p><br>

<p style="text-indent:2em;">introducerea unor sisteme de gestionare a traficului pentru diversele moduri de transport, cum ar fi pentru căile ferate și șosele;</p><br>

<p>dezvoltarea, până în 2020, a unui sistem de informare, gestionare și plată aplicabil transportului multimodal;</p><br>

<p>înjumătățirea numărului victimelor accidentelor rutiere până în 2020 și reducerea acestora la aproape zero până în 2050;</p><br>

<p style="text-indent:2em;">aplicarea deplină a principiilor „utilizator-plătitor” (adică persoanele care utilizează infrastructura plătesc pentru ea) și „poluator-plătitor” (adică persoanele care poluează, plătesc 
pentru aceasta).</p>

<p style="text-indent:2em;"></p><h6>Această strategie cuprinzătoare pentru transporturi urmărește crearea unui sistem de transport competitiv care va crește mobilitatea, va elimina obstacolele majore din zonele-cheie și 
va stimula creșterea economică și ocuparea până în 2050. Strategia este completată de inițiative precum:</h6><p></p>

<p style="text-indent:2em;">Programul Orizont 2020 prin sprijinul acordat de acesta pentru cercetare și inovare în domeniul transportului inteligent, ecologic și integrat;</p><br>

<p style="text-indent:2em;">Mecanismul Conectarea Europei care oferă finanțare pentru 10 proiecte transfrontaliere în domeniul infrastructurii de transport;</p><br>

<p style="text-indent:2em;">Foaia de parcurs pentru o economie cu emisii scăzute de dioxid de carbon până în 2020 în care transportul este un sector-cheie pentru atingerea obiectivului respectiv;</p><br>

<p style="font-weight:500;">Diverse măsuri de eficiență energetică pentru a reduce utilizarea energiei primare a Europei cu 20 % până în 2020.</p><br>

<p style="font-weight:500;">GSA - agenția responsabilă de sistemele globale de navigație prin satelit europene</p><br>

<p style="text-indent:2em;">Uniunea Europeană (UE) a instituit o agenție însărcinată cu maximizarea randamentului investițiilor europene în sisteme globale de navigație prin satelit (GNSS) în ceea ce privește 
beneficiile pentru utilizatori, creșterea economică și competitivitatea.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Regulamentul (UE) nr. 912/2010 al Parlamentului European și al Consiliului din 22 septembrie 2010 de instituire a Agenției GNSS European, de abrogare a Regulamentului (CE) nr. 
1321/2004 al Consiliului privind crearea unor structuri de gestionare a programelor europene de radionavigație prin satelit și de modificare a Regulamentului (CE) nr. 683/2008
al Parlamentului European și al Consiliului</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Uniunea Europeană (UE) a instituit o agenție însărcinată cu maximizarea randamentului investițiilor europene în sisteme globale de navigație prin satelit (GNSS) în ceea ce privește
beneficiile pentru utilizatori, creșterea economică și competitivitatea.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul (UE) nr. 912/2010 instituie Agenția Sistemului Global de Navigație prin Satelit (GNSS) European, modificând denumirea și atribuțiile fostei Autorități Europene de 
Supraveghere a GNSS.</p><br>

<p style="text-indent:2em;">Actul pune în aplicare acordurile de guvernanță publică privind programele GNSS pentru perioada 2014-2020, stabilite prin Regulamentul (UE) nr. 1285/2013. Agenția nu mai are 
funcția de a asigura gestionarea intereselor publice privind programele GNSS (această funcție fiind preluată de Comisia Europeană).</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Agenția GNSS este un organism care funcționează la nivelul UE și este responsabil de:</h6><p></p>

<p>acreditarea de securitate (*) pentru sistemele sale globale de navigație prin satelit;</p><br>

<p>supravegherea funcționării Centrului de monitorizare a securității Galileo;</p><br>

<p style="text-indent:2em;">contribuția la promovarea și comercializarea serviciilor GNSS și îndeplinirea altor sarcini, delegate de Comisie, referitoare la punerea în aplicare a programelor Galileo și 
EGNOS, inclusiv gestionarea programelor.</p><br>

<p style="font-weight:500;">Structura</p>

<p style="text-indent:2em;"></p><h6>Agenția este formată din trei organisme:</h6><p></p>

<p style="text-indent:2em;">1.Consiliul de administrație: acesta are responsabilitatea de a se asigura că agenția își îndeplinește sarcinile, fiind responsabil și de aspecte care țin de resurse și de 
buget în ceea ce privește acreditarea de securitate;</p><br>

<p style="text-indent:2em;">2.directorul executiv: acesta este numit de Consiliul de administrație și este responsabil de gestionarea și de reprezentarea agenției;</p>

<p style="text-indent:2em;"></p><h6>3.Consiliul de acreditare de securitate: acesta este responsabil de acreditarea de securitate, stabilind conformitatea sistemelor cu cerințele de securitate înainte de 
orice decizie de programare, cum ar fi:</h6><p></p>

<p>aprobarea strategiei de acreditare de securitate;</p><br>

<p>lansarea de sateliți;</p><br>

<p>autorizarea exploatării sistemelor și a stațiilor terestre (*).</p><br>

<p style="font-weight:500;">Independența activităților de acreditare a sistemelor de securitate</p>

<p style="text-indent:2em;"></p><h6>Regulamentul (UE) nr. 912/2010 a fost modificat prin Regulamentul (UE) nr. 512/2014 în vederea adaptării la noua structură de gestionare pentru GNSS European, stabilită prin 
Regulamentul (UE) nr. 1285/2013. Modificările introduse asigură independența activităților legate de acreditarea sistemelor de securitate, precum și separarea dintre acestea 
și celelalte activități ale agenției. Printre aceste modificări se numără:</h6><p></p>

<p>competențe sporite pentru Consiliul de acreditare de securitate;</p>

<p style="text-indent:2em;"></p><h6>Consiliul de acreditare de securitate, nu Consiliul de administrație, este responsabil de pregătirea și aprobarea operațiunilor din cadrul programelor agenției legate de 
activitățile de acreditare de securitate a sistemului:</h6><p></p>

<p style="text-indent:2em;">președintele Consiliului de acreditare de securitate va gestiona activitățile de acreditare de securitate și va asigura punerea acestora în aplicare.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Începând cu 9 noiembrie 2010.</p><br>

<p>Pentru informații suplimentare, consultați site-ul Agenției Sistemului Global de Navigație prin Satelit European.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="text-indent:2em;">(*) Acreditare de securitate: verificarea conformității în cadrul normelor și al reglementărilor de securitate aplicabile întocmite de Consiliu și de Comisie. În legătură 
cu principiile generale privind acreditarea de securitate, responsabilitățile și sarcinile, consultați Capitolul III din Regulamentul (UE) nr. 912/2010.</p><br>

<p style="text-indent:2em;">(*) Stații terestre: unități la sol dotate cu echipament pentru recepția sau pentru recepția și transmisia de semnale de la sau la sateliți de comunicații.</p><br>

<p style="font-weight:500;">Siguranța maritimă: Agenția Europeană pentru Siguranță Maritimă</p><br>

<p style="text-indent:2em;">Agenția Europeană pentru Siguranță Maritimă (EMSA) are ca obiectiv asigurarea unui nivel ridicat, unitar și eficient de siguranță și securitate maritimă în Uniunea Europeană 
(UE). De asemenea, aceasta depune eforturi pentru prevenirea poluării și pentru combaterea poluării cauzate de navele maritime sau de instalațiile de petrol și gaze.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Regulamentul (CE) nr. 1406/2002 al Parlamentului European și al Consiliului din 27 iunie 2002 de instituire a unei Agenții Europene pentru Siguranță Maritimă</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Agenția Europeană pentru Siguranță Maritimă (EMSA) are ca obiectiv asigurarea unui nivel ridicat, unitar și eficient de siguranță și securitate maritimă în Uniunea Europeană 
(UE). De asemenea, aceasta depune eforturi pentru prevenirea poluării și pentru combaterea poluării cauzate de navele maritime sau de instalațiile de petrol și gaze.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Prezentul regulament instituie EMSA, cu sediul la Lisabona. Agenția acordă asistență tehnică și sprijin Comisiei Europene (CE) și țărilor UE cu privire la dezvoltarea, punerea 
în aplicare și evaluarea legislației UE în materie de siguranță, de securitate și de poluare maritimă.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">EMSA are atribuții principalesprijin și desprijin</p> .

<p style="text-indent:2em;"></p><h6>Atribuțiile principale sunt:</h6><p></p>

<p>acordarea de sprijin la lucrările pregătitoare pentru actualizarea și dezvoltarea legislației UE relevante;</p><br>

<p>vizite și inspecții în țările UE în legătură cu punerea eficientă în aplicare a actelor legislative relevante și obligatorii ale UE;</p><br>

<p>activități de formare profesională și asistență tehnică pentru administrațiile naționale;</p><br>

<p style="text-indent:2em;">sprijinirea acțiunilor de combatere a poluării în situațiile de poluare cauzate de navele maritime și de instalațiile de petrol și gaze (EMSA oferă asistență operațională doar 
la cererea țării afectate).</p><br>

<p style="text-indent:2em;">De asemenea, EMSA operează Centrul european de date pentru identificarea și urmărirea navelor la mare distanță și Sistemul UE de schimb de informații maritime (SafeSeaNet). 
Agenția poate oferi, de asemenea, sprijin operațional cu privire la investigații în situațiile în care au existat decese sau vătămări grave.</p><br>

<p style="font-weight:500;">Atribuțiile de sprijin</p><br>

<p style="text-indent:2em;">EMSA își asumă aceste sarcini doar dacă acestea creează valoare adăugată demonstrată și evită duplicarea eforturilor și nu încalcă drepturile și obligațiile țărilor UE. Aceste atribuții 
sunt legate de aspecte de mediu, de programul de observare a Pământului GMES (acum Copernicus) și de căile navigabile interioare.</p><br>

<p style="font-weight:500;">Vizitele și inspecțiile EMSA</p><br>

<p style="text-indent:2em;">EMSA vizitează țările pentru a asista CE și administrațiile naționale la verificarea punerii eficiente în aplicare a normelor UE și pentru a garanta un nivel ridicat și eficient de 
siguranță. Agenția efectuează inspecții la societățile de clasificare, precum și în țări terțe, cu privire la formarea și la certificarea navigatorilor.</p><br>

<p style="font-weight:500;">Structura</p><br>

<p style="text-indent:2em;">EMSA este un organism al UE și are personalitate juridică. Personalul agenției este format din funcționari recrutați de agenție și oficiali ai UE și din funcționari ai țărilor UE 
numiți sau detașați cu titlu temporar pentru agenție. Agenția este condusă de un director executiv care este total independent în îndeplinirea atribuțiilor sale.</p><br>

<p style="text-indent:2em;">Consiliul de administrație al agenției este compus din reprezentanți ai CE și ai fiecărei țări a UE și toți au drept de vot. Consiliul include și reprezentanți ai Norvegiei și ai 
Islandei, precum și profesioniști din patru sectoare maritime, fără drept de vot. Mandatul are o durată de cinci ani și poate fi reînnoit o singură dată.</p><br>

<p style="font-weight:500;">Bugetul</p><br>

<p style="text-indent:2em;">Regulamentul (UE) nr. 911/2014 prevede un plan de finanțare multianual de 160,5 milioane EUR pentru intervențiile EMSA în scopul combaterii activităților de poluare pentru perioada 
2014-2020. Bugetul general al EMSA cuprinde o contribuție principală de la bugetul UE și contribuții suplimentare din țările din afara UE care se implică în activitățile agenției.</p><br>
 
<p>De asemenea, agenția poate aplica taxe pentru publicații, formare profesională și orice alte servicii pe care le furnizează.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p style="text-indent:2em;">Două accidente (Erika în 1999 și Prestige în 2002) au dus la deversări de petrol în apele europene. Ambele au cauzat daune ecologice și economice grave coastelor franceze și spaniole. 
Acest fapt a determinat instituirea EMSA în 2003 pentru a asigura că Europa este pregătită pentru deversări de petrol la scară largă.</p><br>

<p style="font-weight:500;">Aviația civilă și Agenția Europeană de Siguranță a Aviației</p>

<p style="text-indent:2em;"></p><h6>SINTEZĂ PRIVIND:</h6><p></p>

<p style="text-indent:2em;">Regulamentul (CE) nr. 216/2008 – norme comune în domeniul aviației civile și instituirea unei Agenții Europene de Siguranță a Aviației</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Obiectivul său principal este de a stabili normele și principiile principale de instituire și menținere a unui nivel ridicat unitar de siguranță a aviației civile în Europa, 
inclusiv crearea unei Agenții Europene de Siguranță a Aviației (AESA).</p><br>

<p style="text-indent:2em;">Normele comune de siguranță oferă un nivel uniform al cerințelor pentru operatori*, producători și personalul din domeniul aviației. Acest lucru permite, la rândul său, libera 
circulație a produselor, a persoanelor și a serviciilor în cadrul pieței UE și permite recunoașterea reciprocă a certificatelor* de către țările UE. Se reduc astfel sarcina 
administrativă și volumul de muncă pentru autoritățile naționale și pentru industrie.</p>

<p style="text-indent:2em;"></p><h6>Regulamentul urmărește, de asemenea:</h6><p></p>

<p>asigurarea unui nivel ridicat de protecție a mediului;</p><br>

<p>facilitarea liberei circulații a mărfurilor, persoanelor și serviciilor;</p><br>

<p>promovarea rentabilității în procesele de reglementare și certificare*.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Acest act legislativ UE se aplică proiectării, producției, întreținerii și operării pieselor și echipamentului produselor aeronautice, precum și personalului și organizațiilor 
implicate în aceste activități.</p><br>

<p style="text-indent:2em;">Unul dintre principalele mijloace de atingere a obiectivelor de mai sus este prin intermediul instituirii AESA. Actul legislativ prevede sarcinile, structura internă, metodele 
de lucru și cerințele financiare ale Agenției.</p>

<p style="text-indent:2em;"></p><h6>Principalele sarcini ale AESA includ:</h6><p></p>

<p>adoptarea avizelor privind siguranța aviației civile în Europa;</p><br>

<p style="text-indent:2em;">susținerea Comisiei Europene privind măsurile de implementare a legii, inclusiv cele aferente chestiunilor tehnice, cum ar fi construcția și proiectarea;</p><br>

<p>realizarea inspecțiilor și investigațiilor necesare pentru a-și îndeplini sarcinile.</p><br>

<p style="text-indent:2em;">În ceea ce privește structura internă, actul legislativ prevede normele principale ale agenției, inclusiv statutul juridic, competențele și componența consiliului de administrație 
și competențele și funcțiile directorului executiv.</p>

<p style="text-indent:2em;"></p><h6>În plus la AESA, acesta prevede normele comune de siguranță a aviației, inclusiv:</h6><p></p>

<p style="text-indent:2em;">navigabilitatea: care solicită ca o aeronavă să dețină un certificat de navigabilitate valid, inclusiv certificate specifice pentru produsele, piesele și conceptul aeronavei;
protecția mediului: care solicită conformitatea produselor, pieselor și aparatelor aeronavei cu prevederile de protecție a mediului la nivelul UE și global;</p><br>

<p style="text-indent:2em;">certificarea echipajului de zbor: care solicită piloților să dețină o licență și un certificat medical adecvate, iar organizațiilor de instruire să dețină certificate adecvate;</p><br>

<p style="text-indent:2em;">aerodromurile și controlul traficului aerian: care solicită ca certificatele să garanteze siguranța aerodromurilor* și deținerea certificatelor adecvate de către 
controlorii de trafic aerian.</p><br>

<p style="text-indent:2em;">Actul legislativ abordează și o serie de chestiuni aferente. Acestea includ supravegherea și aplicarea, recunoașterea certificatelor și acceptarea certificatelor 
din afara UE.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Regulamentul a intrat în vigoare la 8 aprilie 2008.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p>Paginile privind politica europeană în domeniul siguranței aviației pe site-ul Comisiei Europene</p><br>

<p>Paginile privind regulamentele pe site-ul Agenției Europene pentru Siguranța Aviației</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p>Operator: orice persoană juridică sau fizică, care operează sau propune operarea uneia sau a mai multor aeronave.</p><br>

<p>Certificat: orice aprobare, licență sau alt document emis, în urma certificării.</p><br>

<p style="text-indent:2em;">Certificare: orice formă de recunoaștere conform căreia un produs, piesă sau aparat, organizație sau persoană se conformează cerințelor aplicabile. De asemenea, 
acoperă emiterea certificatului relevant de atestare a conformității.</p><br>

<p style="text-indent:2em;">Aerodrom: orice amplasament în care se derulează operațiuni de zbor, care Variază de la pistele de aterizare la marile aeroporturi internaționale.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Regulamentul (CE) nr. 216/2008 al Parlamentului European și al Consiliului din 20 februarie 2008 privind normele comune în domeniul aviației civile și instituirea unei 
Agenții Europene de Siguranță a Aviației și de abrogare a Directivei 91/670/CEE a Consiliului, a Regulamentului (CE) nr. 1592/2002 și a Directivei 2004/36/CE (JO L 79, 
19.3.2008, pp. 1-49)</p><br>

<p style="text-indent:2em;">Modificările succesive aduse Regulamentului (CE) nr. 216/2008 au fost integrate în textul de bază. Această versiune consolidată are doar un caracter informativ.</p><br>

<p style="font-weight:500;">ACTE CONEXE</p><br>

<p style="text-indent:2em;">Regulamentul (CE) nr. 300/2008 al Parlamentului European și al Consiliului din 11 martie 2008 privind norme comune în domeniul securității aviației civile și de abrogare a Regulamentului 
(CE) nr. 2320/2002 (JO L 97, 9.4.2008, pp. 72-84)</p><br>

<p><a href="https://eur-lex.europa.eu/summary/chapter/transport/3201.html?root=3201">Sursa</a></p>




</div>
`