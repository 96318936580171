import React, { Component } from 'react'
import { Field } from 'redux-form'
import ImageUploadField from '../../../../UI/image-upload-field'
import { IAdsPhotoProps, IAdsPhotoState } from './types'
import './styles.scss'

const STYLE_BASE = 'ads-add-photo'

export default class extends Component<IAdsPhotoProps, IAdsPhotoState> {
  constructor(props) {
    super(props)
    this.state = {
      photos: [null, null, null, null, null],
    }
  }

  componentDidUpdate(prevProps) {
    const { photos, change } = this.props
    if (prevProps.photos !== photos) {
      photos && this.setState({ photos })
      photos.map((photo, id) => change(`photo[${id}]`, photo))
    }
  }

  render() {
    const { photos } = this.state
    const { t, folder } = this.props

    return (
      <div className={`${STYLE_BASE} panel-content-row`}>
        <div className="panel-form-row">
          <span className="label">{t('photos-optional')}</span>
          <span className="meta-gray">{t('jpg.file.format')} | 5 MB</span>
          <div className="panel-form-photo" id="photos-div">
            {photos.map((val, id) => (
              <div className="col" key={id}>
                <Field
                  name={`photo[${id}]`}
                  component={ImageUploadField}
                  className="photo-block"
                  folder={folder}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
