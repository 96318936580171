import React, { Component, Fragment } from 'react'
import { Field } from 'redux-form'
import PopoverWrapper from '../popover-wrapper'

class Checkbox extends Component {
  componentDidMount() {
    const { input } = this.props
    if (input.value !== '') {
      input.onChange(input.value)
    }
  }

  render() {
    const { input, disabled = false, label, children, tooltip, tooltipTrigger } = this.props

    return (
      <Fragment>
        <input
          type="checkbox"
          id={input.name}
          {...{ disabled }}
          {...input}
          onChange={() => input.onChange(!input.value)}
        />
        <label htmlFor={input.name}>{label || children}</label>
        {tooltip && (
          <PopoverWrapper text={tooltip}>
            {tooltipTrigger || <i className="fa fa-question-circle col-blue tooltip-trigger" />}
          </PopoverWrapper>
        )}
      </Fragment>
    )
  }
}

const CheckboxField = props => {
  const { children, name, tooltip, tooltipTrigger } = props
  return (
    <Field
      type="checkbox"
      name={name}
      component={Checkbox}
      tooltip={tooltip}
      tooltipTrigger={tooltipTrigger}
    >
      {children}
    </Field>
  )
}

export default Checkbox
export { CheckboxField }
