export const int3 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>CMR</h4><p></p>

<img src="images/cmr-ro.jpg" alt="cmr" style="margin:auto;display: block;">

<p><a href="https://www.vlaros-print.com/scrisori-de-transport-auto-international-cmr-5ex-50set-model2-3513.html#.X0Z5O8gzZhE">Sursa</a></p>



</div>
`