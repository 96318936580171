import React from 'react'
import { Route, Switch } from 'react-router-dom'

import UserList from './list'
import UserProfile from './profile'
import ProfileFull from './profile-full'
import UserNotices from './user-notices'
import ProtectedRoute from '../../protectedRoute'

export default () => {
  return (
    <Switch>
      <Route path="/user" exact component={UserList} />
      <Route
        path="/user/details/:userId?/:openedTab?"
        component={ProfileFull}
        exact
        requireAuth
      />
      <ProtectedRoute path="/user/notices/:userId" component={UserNotices} exact requireAuth />
      <Route path="/user/:userId" exact component={UserProfile} />
    </Switch>
  )
}
