import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import { dateFormatter } from "../../../shared/utils"
import Widget from '../widget'

import "./styles.scss"
export default class extends Component {
  renderItem = (item = {}, index) => {
    // return <div key={index}></div>
    const { insertItemSectionIdInUrl = false } = this.props
    const { date, sectionId } = item
    const formattedDate = Date.parse(date) ? dateFormatter(date, true) : date
    const { onItemClick = _.noop, linksTo = {}, textPropName = 'text' } = this.props
    const { item: toItem } = linksTo
    let to
    if (!insertItemSectionIdInUrl) {
      to = toItem ? `${toItem}${toItem[toItem.length - 1] === '/' ? '' : '/'}${item.id}` : '#'
    } else {
      to = toItem ? `${toItem}/${sectionId}/${item.id}` : '#'
    }
    const onClick = toItem ? undefined : () => onItemClick(item)
    return (
      <div key={index} className="panel-content-row">
        <span className="meta">{formattedDate}</span>
        <Link to={to} className="text" onClick={onClick}>
          {item[textPropName]}
        </Link>
      </div>
    )
  }

  defaultRenderRows = items => {
    return items.map((item, index) => this.renderItem(item, index))
  }

  noDataContent = () => {
    const { t } = this.props
    return(
      <div className="no-data-content">
        {t('no.data')}
      </div>
    )
  }

  render() {
    const {
      items = [],
      onItemClick,
      linkTo,
      renderRows = this.defaultRenderRows,
      textPropName,
      // renderRow = this.defaultRender,
      ...rest
    } = this.props

    const content = items.length > 0 ? renderRows(items) : this.noDataContent()
    return (
      <Widget {...rest}>
        {content}
      </Widget>
    )
  }
}
