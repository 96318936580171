export const eu2 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Transport, energie şi mediu</h4><p></p>

<p style="font-weight:500;">Protecţia mediului</p><br>

<p style="font-weight:500;">Protocolul privind transporturile conform Convenției alpine</p><br>

<p style="text-indent:2em;">Convenția alpină este un acord-cadru în vederea protecției și dezvoltării durabile a regiunii alpine și vizează conservarea și protejarea masivului (din perspectivă de mediu, economică și socială),
 prin aplicarea principiilor prevenirii, poluatorul plătește și al cooperării între statele membre. Textul regrupează nouă protocoale, fiecare acoperind un sector diferit. Prezenta decizie vizează
 aplicarea Protocolului privind aplicarea Convenției alpine în domeniul transporturilor de către Uniunea Europeană.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Decizia 2007/799/CE a Consiliului din 12 octombrie 2006 privind semnarea, în numele Comunității Europene, a Protocolului privind aplicarea Convenției alpine în domeniul transporturilor (Protocolul
 privind transporturile).</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Prin Decizia 96/191/CE a Consiliului, Uniunea Europeană (UE) a încheiat Convenția privind protecția Alpilor. Aceasta vizează salvgardarea ecosistemului natural și promovarea dezvoltării durabile
 a Alpilor, promovând totodată interesele economice și culturale ale populațiilor. Convenția alpină dispune de un comitet permanent, alcătuit din reprezentanți ai părților contractante.</p><br>

<p style="text-indent:2em;">Semnarea acestui protocol de către UE are, de asemenea, scopul de a transmite un semnal politic tuturor părților implicate, invitând părțile contractante să îl semneze și să îl ratifice.</p><br>

<p style="font-weight:500;">Obiective</p>

<p style="text-indent:2em;"></p><h6>Obiectivele protocolului sunt următoarele:</h6><p></p>

<p>reducerea riscurilor și a pericolelor proprii transportului intraalpin;</p><br>

<p style="text-indent:2em;">contribuirea la dezvoltarea habitatelor și a spațiilor economice grație unei politici a transporturilor armonizată între diferitele țări și care integrează diferitele moduri de transport (rutier,
 feroviar etc.);</p><br>

<p style="text-indent:2em;">contribuirea la reducerea pericolului asupra biodiversității spațiului alpin, precum și asupra patrimoniului natural și cultural al acestuia;</p><br>

<p style="text-indent:2em;">asigurarea circulației la costuri suportabile din punct de vedere economic, printr-o creștere a eficacității și a durabilității sistemelor de transport.</p>

<p style="text-indent:2em;"></p><h6>Părțile contractante ale Convenției se angajează, astfel, să ia în considerare, în administrarea teritoriului alpin, riscurile și pericolele asociate circulației, cum ar fi poluarea (fonică sau chimică)
 și siguranța persoanelor și a bunurilor. În paralel, semnatarii trebuie:</h6><p></p>

<p>să sporească rentabilitatea sectorului transporturilor;</p><br>

<p>să optimizeze utilizarea infrastructurilor existente;</p><br>

<p>să ia în considerare problematica transporturilor în evaluarea și în punerea în aplicare a altor politici;</p><br>

<p>să implice colectivitățile teritoriale în luarea deciziei.</p><br>

<p style="font-weight:500;">Măsuri și strategii specifice</p>

<p style="text-indent:2em;"></p><h6>Convenția alpină definește, de asemenea, un anumit număr de măsuri și strategii specifice. Toate vizează promovarea unei administrări raționale și sigure a transporturilor,
 grație principiilor următoare:</h6><p></p>

<p>o bună coordonare a diferitelor moduri și mijloace de transport;</p><br>

<p>utilizarea intermodalității;</p><br>

<p>transferul traficului către modurile de transport mai nepoluante;</p><br>

<p>protecția căilor de comunicație împotriva riscurilor naturale;</p><br>

<p>protecția persoanelor și a mediului înconjurător;</p><br>

<p>reducerea progresivă a emisiilor de substanțe nocive și a emisiilor fonice;</p><br>

<p>crearea și dezvoltarea unor transporturi publice ușor de utilizat și adaptate mediului înconjurător;</p><br>

<p>utilizarea studiilor de impact pentru proiectele planificate și consultarea părților interesate.</p>

<p style="text-indent:2em;"></p><h6>Convenția definește, în plus, principii particulare pentru diferitele moduri de transport:</h6><p></p>

<p style="text-indent:2em;">o consolidare a transportului feroviar prin îmbunătățirea infrastructurilor, optimizarea exploatării acestora, precum și utilizarea intermodalității pentru transportul de mărfuri;</p><br>

<p>o utilizare sporită a capacităților de navigație fluvială și maritimă;</p><br>

<p style="text-indent:2em;">în domeniul transportului rutier, semnatarii trebuie să evite construcția de noi drumuri de mare capacitate. Proiectele pot fi totuși realizate în condițiile minimalizării 
impactului asupra mediului înconjurător;</p><br>

<p style="text-indent:2em;">efectele dăunătoare ale transportului aerian trebuie să fie reduse în măsura posibilului. Activitățile aeriene recreative nemotorizate trebuie, de asemenea, să fie limitate.</p><br>

<p style="font-weight:500;">Principiul poluatorul plătește</p><br>

<p style="text-indent:2em;">Textul invită părțile contractante în egală măsură să aplice principiul poluatorul plătește, precum și să stabilească un sistem de calcul care să permită integrarea costurilor de
 mediu în evaluarea costului global al infrastructurilor.</p><br>

<p style="text-indent:2em;">Semnatarii trebuie să raporteze în mod regulat Comitetului permanent cu privire la măsurile luate în temeiul protocolului. Comitetul întocmește un raport de constatare a respectării
 protocolului.</p><br>

<p style="font-weight:500;">Context</p><br>

<p style="text-indent:2em;">Pe 14 mai 1991, Consiliul a autorizat Comisia să ia parte la negocierile pentru stabilirea Convenției alpine și a protocoalelor acesteia.</p><br>

<p style="text-indent:2em;">Protocolul privind transporturile a fost deschis în vederea semnării de către părțile contractante cu ocazia reuniunii ministeriale a Convenției alpine desfășurate la Lucerna în zilele
 de 30-31 octombrie 2000.</p><br>

<p style="text-indent:2em;">Consiliul a semnat Protocolul privind transporturile al Convenției alpine pe 12 decembrie 2006. Această decizie a fost însoțită de o declarație comună a Consiliului și a Comisiei privind
 interpretarea Protocolului privind transporturile.</p><br>

<p style="text-indent:2em;">În plus față de Uniunea Europeană, părțile contractante ale Convenției alpine sunt Germania, Franța, Italia, Liechtenstein, Monaco, Austria, Elveția și Slovenia.</p><br>

<p style="font-weight:500;">Vehicule de transport rutier nepoluante și eficiente din punct de vedere energetic</p><br>

<p style="text-indent:2em;">Această directivă contribuie la obiectivele europene privind ameliorarea eficienței energetice și reducerea emisiilor poluante din sectorul transporturilor. Directiva instituie un cadru
 care să permită promovarea și dezvoltarea unei piețe pentru vehiculele nepoluante.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Directiva 2009/33/CE a Parlamentului European și a Consiliului din 23 aprilie 2009 privind promovarea vehiculelor de transport rutier nepoluante și eficiente din punct de vedere energetic
 (Text cu relevanță pentru SEE).</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Această directivă are drept obiectiv promovarea și stimularea dezvoltării unei piețe de vehicule nepoluante și eficiente din punct de vedere energetic. Directiva impune autorităților
 publice și altor operatori să țină cont, la achiziționarea din bani publici a vehiculelor de transport, de impactul exploatării acestora pe întreaga lor durată de viață în ceea ce privește
 consumul de energie, emisiile de CO2 și alți poluanți.</p><br>

<p style="font-weight:500;">Domeniu de aplicare</p>

<p style="text-indent:2em;"></p><h6>Directiva se aplică contractelor de cumpărare de vehicule de transport rutier încheiate de:</h6><p></p>

<p>autorități contractante sau entități contractante;</p><br>

<p>operatori care îndeplinesc obligații de serviciu public în cadrul unui contract de servicii publice.</p><br>

<p>Cumpărarea de vehicule de transport rutier nepoluante și eficiente din punct de vedere energetic</p><br>

<p style="text-indent:2em;">Statele membre trebuie să se asigure că autoritățile contractante, entitățile contractante și operatorii în cadrul unui contract de servicii publice țin cont, la cumpărarea de vehicule de
 transport rutier, de impactul energetic și de mediu al acestora, pe întreaga lor durată de viață.</p>

<p style="text-indent:2em;"></p><h6>Aspectele luate în considerare la evaluarea impactului energetic și de mediu includ:</h6><p></p>

<p>consumul de energie;</p><br>

<p>emisiile de CO2;</p><br>

<p>emisiile de NOx, NMHC și de particule.</p><br>

<p style="text-indent:2em;">Pentru a respecta obligația conform căreia trebuie să țină cont de impactul de mediu al vehiculelor, autoritățile contractante, entitățile contractante și operatorii de servicii
 publice de transport pot alege să stabilească specificații tehnice pentru performanța energetică și ecologică pe care să le includă în documentația privind achiziționarea vehiculului
 în cauză sau să includă impactul energetic și de mediu în decizia de cumpărare.</p><br>

<p style="font-weight:500;">Metodologia pentru calcularea costurilor operaționale pe durata de viață</p><br>

<p style="text-indent:2em;">Directiva prevede o metodologie de calcul care permite evaluarea în bani a costului consumului de energie și a emisiilor de CO2 și de poluanți pentru întreaga durată de viață a vehiculului.</p>

<p style="text-indent:2em;"></p><h6>Costul operațional al consumului de energie pentru un vehicul, estimat pe toată durata de viață a acestuia, se calculează cu ajutorul următoarei metode:</h6><p></p>

<p>consumul de carburant pe kilometru se contorizează în unități de consum de energie pe kilometru;</p><br>

<p>se utilizează o singură valoare monetară pentru fiecare unitate energetică;</p><br>

<p style="text-indent:2em;">costul operațional al consumului de energie pentru un vehicul,  estimat pe toată durata de viață a acestuia, se calculează prin înmulțirea kilometrajului total cu consumul de energie
 pe kilometru și apoi cu costul pe unitate energetică.</p><br>

<p style="text-indent:2em;">Costul emisiilor de CO2 se calculează înmulțind kilometrajul pe durata de viață cu emisiile de CO2 exprimate în kilogram pe kilometru și cu costul pe kilogram.</p><br>

<p>Costul emisiilor de poluanți se obține adunând costurile legate de emisiile de NOx, NMHC și de particule.</p><br>

<p style="font-weight:500;">Schimbul de bune practici</p><br>

<p style="text-indent:2em;">Comisia trebuie să încurajeze schimburile de cunoștințe și de bune practici între statele membre în domeniul promovării achiziționării de vehicule de transport rutier nepoluante
 și eficiente din punct de vedere energetic.</p><br>

<p style="font-weight:500;">Context</p><br>

<p style="text-indent:2em;">Printre principalele obstacole în calea dezvoltării durabile se numără emisiile de gaze cu efect de seră și poluarea cauzată de transport, după cum a subliniat Consiliul European
 de la Göteborg din 15 și 16 iunie 2001. Prin urmare, este necesar să se întreprindă eforturi în sectorul transporturilor pentru a se atinge obiectivele stabilite pentru 2020 în
 ceea ce privește eficiența energetică, utilizarea energiilor regenerabile și reducerea emisiilor de gaze cu efect de seră. Promovarea și dezvoltarea unor piețe pentru vehicule mai
 puțin poluante, mai inteligente, mai sigure și cu un consum redus de energie reprezintă una dintre modalitățile prin care se vor putea îndeplini aceste obiective.</p><br>

<p style="font-weight:500;">Biocarburanţi şi reducerea emisiilor</p><br>

<p style="font-weight:500;">Reducerea emisiilor de CO2 generate de autoturismele noi</p><br>

<p style="text-indent:2em;">Având în vedere faptul că transportul rutier este unul dintre sectoarele cele mai poluante în ceea ce privește emisiile de gaze cu efect de seră (GES) din UE, prezentul regulament
 are ca scop reducerea acestor emisii prin stabilirea unor limite pentru autoturismele noi.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Regulamentul (CE) nr. 443/2009 al Parlamentului European și al Consiliului din 23 aprilie 2009 de stabilire a standardelor de performanță privind emisiile pentru autoturismele noi,
 ca parte a abordării integrate a Comunității de a reduce emisiile de CO2 generate de vehiculele ușoare</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul stabilește standarde pentru autoturismele noi în ceea ce privește emisiile de dioxid de carbon (CO2) ale acestora. Limita curentă este stabilită la 130 de grame de CO2 emise
 per kilometru. Limita se va reduce la 95 de grame de CO2 /km până în anul 2021.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Limita de 130g/km introdusă progresiv între 2012 și 2015. Pentru fiecare an din perioada respectivă, procentajul autoturismelor unui producător care trebuie să respecte limita crește.
 Din 2015, 100 % din autoturisme trebuie să fie conforme (comparativ cu 75 % în 2013 și 80 % în 2014).</p><br>

<p style="text-indent:2em;">Dacă media emisiilor de CO2 de la mai multe autoturisme ale unui producător depășește limita de emisii, atunci se va aplica o amendă ridicată. Pentru fiecare autoturism, producătorul
 trebuie să plătească 5 EUR pentru primul g/km peste limită, 15 EUR pentru al doilea, 25 EUR pentru al treilea și 95 EUR pentru fiecare g/km după acestea. Din 2019, fiecare g/km peste
 limită va fi taxat cu 95 EUR.</p><br>

<p style="text-indent:2em;">Micii producători care înregistrează un număr mai mic de 1 000 de autoturisme în UE per an sunt scutiți de la această legislație, iar cei care înregistrează între 1 000 și 10 000 de
 autoturisme pe an pot propune propriile ținte de reducere a emisiilor. Între timp, producătorii care fabrică între 10 000 și 300 000 de autoturisme per an, pot aplica pentru o țintă
 de reducere fixă.</p><br>

<p style="text-indent:2em;">Inovarea ecologică realizată de producătorii de autoturisme este recompensată printr-un sistem de credite și super credite* de emisii.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p>În prezent, autoturismele sunt responsabile pentru circa 12 % din totalul emisiilor de CO2 din UE.</p><br>

<p style="text-indent:2em;">Originile prezentului regulament se află într-o strategie adoptată de UE în 1995 care viza reducerea emisiilor de CO2 pe baza a trei componente: un angajament voluntar de reducere
 a emisiilor din partea producătorilor de autoturisme, promovarea autoturismelor cu un grad ridicat de eficiență energetică prin intermediul unor măsuri fiscale și informarea consumatorilor
 prin etichete care afișează emisiile de CO2 ale unui autoturism, conform detalierii din Directiva 1999/94/CE.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="text-indent:2em;">Super credite sunt stimulente oferite producătorilor pentru a înregistra autoturisme cu nivel scăzut de emisii. În cadrul acestui program, autoturismele care generează 50 g de CO2/km sau
 mai puțin sunt considerate ca fiind echivalente cu 1,5 autoturisme până în 2016. Acest lucru, în schimb, oferă producătorilor flexibilitatea de a produce și autoturisme mai puțin
 eficiente din punct de vedere al consumului de carburant.</p><br>

<p style="font-weight:500;">Emisiile provenite de la vehiculele grele (Euro VI): norme de certificare</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p>

<p style="text-indent:2em;"></p><h6>Regulamentul definește normele pentru omologarea de tip a autovehiculelor, a motoarelor și a pieselor de schimb pentru vehiculele grele (camioane, autobuze și autocare), în ceea ce
 privește emisiile acestora. De asemenea, acesta stabilește norme privind:</h6><p></p>

<p>conformitatea în funcționare a vehiculelor și motoarelor;</p><br>

<p>durabilitatea dispozitivelor pentru controlul poluării;</p><br>

<p>sistemele de diagnosticare la bord (on-board diagnostic – OBD);</p><br>

<p>accesibilitatea la informațiile referitoare la sistemul OBD al vehiculelor și la repararea și întreținerea vehiculelor;</p><br>

<p>măsurarea consumului de carburant și a emisiilor de CO2.</p><br>

<p>Regulamentul abrogă directivele 80/1269/CEE, 2005/55/CE și 2005/78/CE începând de la 31 decembrie 2013.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Domeniul de aplicare</p><br>

<p style="text-indent:2em;">Acest regulament se aplică în cazul vehiculelor cu motor din categoriile M1, M2, N1 și N2 cu o masă de referință mai mare de 2 610 kg, precum și tuturor autovehiculelor din categoriile M3
 și N3, astfel cum sunt definite în anexa II la Directiva 2007/46/CE.</p><br>

<p style="font-weight:500;">Obligațiile producătorilor</p>

<p style="text-indent:2em;"></p><h6>Producătorii trebuie:</h6><p></p>

<p style="text-indent:2em;">să poată demonstra că toate vehiculele, motoarele sau piesele de schimb noi comercializate, înregistrate sau puse în funcțiune în UE au fost omologate de tip în conformitate cu acest
 regulament;</p><br>

<p style="text-indent:2em;">să aplice măsuri tehnice pentru a garanta limitarea efectivă a emisiilor la țeava de eșapament pe toată durata de viață normală a vehiculelor și în condiții de utilizare normale.</p><br>

<p style="font-weight:500;">Cerințe și încercări</p><br>

<p style="text-indent:2em;">Producătorii trebuie să echipeze vehiculele și motoarele cu componente care să asigure respectarea limitelor de emisii stabilite în anexa I la acest regulament, în condițiile prevăzute de
 către Comisia Europeană în acest regulament și în actele de punere în aplicare.</p><br>

<p style="font-weight:500;">Accesul la informație</p>

<p style="text-indent:2em;"></p><h6>Producătorii trebuie:</h6><p></p>

<p style="text-indent:2em;">să garanteze accesul operatorilor independenți (adică al întreprinderilor independente de distribuitorii și reparatorii agreați ai unei anumite mărci de vehicule) la informațiile privind
 sistemele OBD și echipamentele, instrumentele sau software-ul de diagnosticare;</p><br>

<p style="text-indent:2em;">să furnizeze o structură standardizată, sigură și la distanță pentru a permite reparatorilor independenți să realizeze operații care implică accesul la sistemul de siguranță al vehiculului.</p><br>

<p style="text-indent:2em;">Informațiile respective ar trebui să fie publicate pe site-urile internet ale producătorilor sau, dacă acest lucru nu este posibil datorită naturii informațiilor, într-un alt format adecvat.</p><br>

<p style="font-weight:500;">Calendarul</p><br>

<p style="text-indent:2em;">Autoritățile naționale trebuie să nu mai acorde omologarea de tip UE sau națională pentru vehiculele care nu respectă prevederile acestui regulament începând cu 31 decembrie 2012. De asemenea,
 acestea trebuie să interzică înregistrarea vehiculelor noi care nu respectă prevederile acestui regulament începând cu 31 decembrie 2013.</p><br>

<p style="font-weight:500;">Stimulente financiare</p><br>

<p style="text-indent:2em;">Țările pot acorda stimulente financiare pentru achiziționarea de vehicule cu motor produse în serie care respectă prevederile acestui regulament până la 31 decembrie 2013. Pot fi luate
 în calcul și măsuri de postechipare pentru adaptarea vehiculelor în funcțiune, precum și măsuri pentru eliminarea vehiculelor care nu sunt conforme.</p><br>

<p style="text-indent:2em;">Suma stimulentelor financiare trebuie să fie egală cu costurile suplimentare aferente măsurilor tehnice utilizate pentru a asigura respectarea de către vehicul a valorilor-limită de emisii.</p><br>

<p style="font-weight:500;">Legislația de punere în aplicare</p><br>

<p>Regulamentul (UE) nr. 582/2011 al Comisiei conține măsurile de punere în aplicare a Regulamentului (CE) nr. 595/2009.</p><br>

<p style="text-indent:2em;">Acesta prevede cerințele tehnice specifice pentru omologarea vehiculelor grele în ceea ce privește emisiile și accesul la informații referitoare la repararea și întreținerea vehiculelor.</p>

<p style="text-indent:2em;"></p><h6>Regulamentul (UE) 2017/2400 al Comisiei completează normele legale instituite prin Regulamentul (UE) nr. 582/2011. Acesta prevede normele pentru:</h6><p></p>

<p style="text-indent:2em;">certificarea componentelor de camioane care au un impact asupra emisiilor de CO2 și asupra consumului de combustibil ale vehiculelor;</p><br>

<p style="text-indent:2em;">autorizarea efectuării de simulări pentru determinarea emisiilor de CO2 și a consumului de combustibil ale vehiculelor noi care urmează să fie vândute, înmatriculate sau puse în circulație
 în UE;</p><br>

<p>operarea simulatoarelor și declararea valorilor emisiilor de CO2 și ale consumului de combustibil pe care le determină.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p style="text-indent:2em;">Se aplică de la 7 august 2009, cu excepția anumitor puncte din anexa II referitoare la anexele la Directiva 2007/46/CE, care se aplică de la 31 decembrie 2013.</p><br>

<p style="font-weight:500;">Prevenirea poluării marine</p><br>

<p>Răspunderea civilă pentru daunele provocate de poluarea cu hidrocarburi: Convenția hidrocarburi pentru propulsia navei</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DECIZII?</p><br>

<p style="text-indent:2em;">Decizia autorizează țările Uniunii Europene (UE) să semneze, să ratifice sau să adere la Convenția internațională din 2001 privind răspunderea civilă pentru daunele provocate de poluarea
 cu hidrocarburi utilizate pentru propulsia navei (Convenția hidrocarburi pentru propulsia navei).</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Convenția hidrocarburi pentru propulsia navei a fost adoptată sub auspiciile Organizației Maritime Internaționale (OMI) pentru a asigura compensații adecvate, prompte și eficiente pentru
 persoanele care au suferit prejudicii din cauza deversărilor de hidrocarburi transportate sub formă de combustibil în buncărele navelor.</p><br>

<p style="font-weight:500;">Compatibilitatea cu normele UE</p><br>

<p style="text-indent:2em;">Articolele 9 și 10 din Convenția hidrocarburi pentru propulsia navei au efecte asupra normelor prevăzute în Regulamentul (CE) nr. 44/2001 al Consiliului [în prezent Regulamentul (UE) nr.
 1215/2012] privind competența judiciară, recunoașterea și executarea hotărârilor în materie civilă și comercială.</p><br>

<p>Convenția nu stipulează ca o organizație internațională, precum UE, să semneze, să ratifice sau să adere la aceasta.</p><br>

<p style="text-indent:2em;">Prin urmare, țările UE, cu autorizarea UE, trebuie să semneze, să ratifice sau să adere la convenție în interesul UE. În acest context, țările UE trebuie să facă și o declarație prin care
 își asumă obligația să aplice Regulamentul (CE) nr. 1215/2012 în relațiile lor reciproce.</p><br>

<p style="font-weight:500;">Domeniul de aplicare</p>

<p style="text-indent:2em;"></p><h6>Convenția se aplică pentru:</h6><p></p>

<p style="text-indent:2em;">daunele provocate de poluare în teritoriu, pe marea teritorială și în zona economică exclusivă sau echivalentă din orice țară care este parte la convenție;</p><br>

<p>măsurile luate pentru prevenirea sau minimizarea unor astfel de daune.</p><br>

<p style="text-indent:2em;">Convenția nu se aplică navelor de război, navelor auxiliare sau altor nave deținute de către o țară. Cu toate acestea, orice țară care face parte din convenție poate decide să o aplice
 acestor nave.</p><br>

<p style="font-weight:500;">Răspunderea armatorului</p>

<p style="text-indent:2em;"></p><h6>În momentul unui incident, armatorul este responsabil de toate daunele provocate de poluarea cu hidrocarburile utilizate pentru propulsia navei. Cu toate acestea, armatorul va fi exonerat
 de răspundere dacă poate să dovedească faptul că:</h6><p></p>

<p style="text-indent:2em;">prejudiciul a fost cauzat de un act de război, ostilități, insurecție sau de un fenomen natural cu caracter excepțional, inevitabil și căruia nu i se poate opune rezistență;</p><br> 

<p>sau prejudiciul a fost cauzat de o acțiune sau de o omisiune comisă de o terță parte cu intenția de a provoca prejudicii;</p><br> 

<p>sau prejudiciul a fost cauzat în întregime de neglijența oricărui guvern sau a oricărei alte autorități responsabile.</p><br>

<p style="font-weight:500;">Asigurarea obligatorie sau garanția financiară</p><br>

<p style="text-indent:2em;">Proprietarii de nave cu un tonaj brut mai mare de 1 000 de tone și care sunt înregistrate într-o țară care face parte din convenție trebuie să mențină o asigurare sau o altă garanție financiară,
 precum garanția unei bănci sau a unei instituții financiare similare, pentru a-și acoperi pierderea din cauza daunelor provocate de poluare.</p><br>

<p style="text-indent:2em;">Autoritatea competentă a unei țări eliberează pentru fiecare navă un certificat care atestă că este în vigoare asigurarea sau altă garanție financiară, iar acesta trebuie să fie păstrat la bordul navei.
 De asemenea, o copie a certificatului trebuie să fie depusă la autoritatea care păstrează registrul de înmatriculare a navei.</p><br>

<p style="font-weight:500;">Competența judiciară și executarea hotărârilor</p><br>

<p style="text-indent:2em;">Acțiunea judiciară pentru compensația solicitată de la armator, asigurător sau altă persoană care asigură garanția trebuie introdusă în țara sau în țările în care au fost provocate daunele
 din cauza poluării.</p><br>

<p style="text-indent:2em;">Convenția definește condițiile în care o hotărâre pronunțată de o instanță dintr-o țară care este parte la convenție poate fi recunoscută sau executată în altă țară.</p><br>

<p style="font-weight:500;">Termene</p><br>

<p style="text-indent:2em;">Drepturile la compensație în temeiul acestei convenții vor expira dacă nu se introduce nicio acțiune în termen de 3 ani de la data la care s-a produs prejudiciul.</p><br>

<p>Nu se poate introduce nicio acțiune după mai mult de 6 ani de la data incidentului care a provocat prejudiciul.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ CONVENȚIA?</p><br>

<p style="text-indent:2em;">Decizia Consiliului a solicitat țărilor UE să ia măsurile necesare pentru a depune instrumentele de ratificare sau instrumentele de aderare, dacă este posibil, înainte de 30 iunie 2006.</p><br>

<p style="font-weight:500;">EFECTE</p><br>

<p>Începând din 2015, toate cele 28 de țări ale UE au ratificat/aderat la convenție în conformitate cu decizia Consiliului.</p><br>

<p style="font-weight:500;">Poluarea cauzată de nave și sancțiunile penale</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva creează norme aplicabile în întreaga UE pentru impunerea de sancțiuni în cazul deversărilor de hidrocarburi și alte substanțe poluante de către navele care navighează în apele UE.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Legislația actuală prevede că deversările poluante provenite de la nave constituie în principiu o infracțiune. Conform directivei, acest lucru se referă la deversările de hidrocarburi sau alte substanțe
 poluante de la nave. Deversările minore nu vor fi considerate automat ca fiind infracțiuni, decât dacă deversările repetate duc la o deteriorare a calității apei.</p><br>

<p style="text-indent:2em;">Persoanele responsabile pentru deversarea de substanțe poluante pot fi supuse unor sancțiuni penale dacă au acționat cu intenție, prin imprudență* sau prin neglijență gravă. Incitarea sau ajutarea
 și instigarea la deversarea unei substanțe poluante pot, de asemenea, să ducă la sancțiuni penale.</p><br>

<p>Directiva se aplică tuturor tipurilor de nave, indiferent de pavilionul acestora.</p>

<p style="text-indent:2em;"></p><h6>Deversările poluante sunt interzise în:</h6><p></p>

<p>apele interne, inclusiv porturile, ale unei țări a UE;</p><br>

<p>apele teritoriale ale unei țări a UE;</p><br>

<p style="text-indent:2em;">strâmtorile utilizate pentru navigația internațională supuse dreptului de transport de tranzit, în conformitate cu Convenția Organizației Națiunilor Unite din 1982 privind dreptul mării;</p><br>

<p>zona economică exclusivă (ZEE)* a unei țări a UE;</p><br>

<p>marea liberă.</p><br>

<p style="font-weight:500;">Excepții</p><br>

<p style="text-indent:2em;">Acest regim nu se aplică în cazul deversărilor provenite de la nave de război sau alte nave aparținând unui stat sau exploatate de stat și utilizate numai pentru un serviciu public necomercial.</p><br>

<p style="text-indent:2em;">Se pot aplica excepții de la interdicția deversărilor de substanțe poluante în cazul în care este în pericol siguranța umană sau siguranța navei.</p><br>

<p style="font-weight:500;">Persoane juridice</p><br>

<p style="text-indent:2em;">Directiva 2009/123/CE a modificat Directiva 2005/35/CE pentru a îmbunătăți normele privind poluarea cauzată de nave și pentru a garanta că autorii deversărilor de substanțe poluante sunt supuși unor
 sancțiuni adecvate. Aceasta le impune țărilor UE să introducă norme privind răspunderea persoanelor juridice conform dreptului privat*, cum sunt întreprinderile.</p><br>

<p style="text-indent:2em;">Companiile pot fi supuse unor sancțiuni penale în cazul în care o persoană fizică din conducerea întreprinderii a comis, în beneficiul propriu, o infracțiune penală, indiferent dacă persoana respectivă
 a acționat individual sau ca parte a întreprinderii.</p><br>

<p style="text-indent:2em;">Compania este responsabilă și pentru infracțiunile comise de către o persoană fizică din neglijență, și anume din cauza lipsei supravegherii și a controlului.</p><br>

<p>Răspunderea întreprinderii nu va exclude demararea unor proceduri penale împotriva persoanelor fizice implicate.</p><br>

<p style="font-weight:500;">Aplicarea sancțiunilor</p><br>

<p style="text-indent:2em;">Directiva 2009/123/CE le impune, de asemenea, autorităților naționale din țările UE să asigure aplicarea unor sancțiuni efective, proporționale și cu efect de descurajare, inclusiv în cazurile minore.
 Ele trebuie să coopereze atunci când se constată că o navă se face vinovată de deversare ilegală în zona lor de responsabilitate, înainte ca nava să acosteze în portul altei țări a UE.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p style="text-indent:2em;">Directiva se aplică începând cu data de 1 octombrie 2005. Țările UE au avut obligația de a o transpune în legislația națională până la 1 aprilie 2007.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p>Acțiune neglijentă: o acțiune întreprinsă având cunoștință de faptul că aceasta va avea ca rezultat probabil o daună.</p><br>

<p style="text-indent:2em;">Zonă economică exclusivă (ZEE): se înțelege, în general, că această zonă se constituie atunci când o țară preia jurisdicția asupra explorării și a exploatării resurselor marine din apele sale de coastă.
 În mod normal, această secțiune este considerată a fi o fâșie întinsă la 200 de mile de țărm</p><br>

<p style="text-indent:2em;">Persoane juridice conform dreptului privat: toate entitățile juridice, cum sunt întreprinderile, cu excepția statelor, a organismelor publice și a organizațiilor internaționale publice.</p><br>

<p><a href="https://eur-lex.europa.eu/summary/chapter/transport/3206.html?root=3206">Sursa</a></p>



</div>
`