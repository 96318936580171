import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { cog } from 'react-icons-kit/fa/cog'
import { plus } from 'react-icons-kit/fa/plus'
import { table } from 'react-icons-kit/fa/table'
import { pencil } from 'react-icons-kit/fa/pencil'
import { trashO } from 'react-icons-kit/fa/trashO'
import Icon from 'react-icons-kit'
import uuid from 'uuid/v4'
import { Field } from 'redux-form'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Cookies from 'universal-cookie'
import { ModalConsumer } from '../../../context/modal-context.tsx'
import Filter from '../../shared/components/widget-filter'
import dict from '../../shared/dictionaries'
import AddEdit from '../item/add-edit-modal/add-edit'
import Pagination from '../../UI/pagination'
import Banner from '../../banner'
import Switch from '../../UI/switch'
import config from '../../../config'
import DropdownCheckboxlist from '../../shared/components/dropdown-checkboxlist'
import WidgetHeader from '../../shared/components/widget-header'
import { LIST_LAYOUT_ITEM, LIST_LAYOUT_GRID, formatDDMMYYYY } from '../../../store/constants'
import { canDo } from '../../../store/utils'
import { SHOW_ADS_MIN_ITEMS } from "../../../components/shared/utils"
import './styles.scss'
import ForumCommentDate from '../../forum/forum-full-page/forum-date'

const STYLE_BASE = 'news-list_'
const NUMBER_OF_ROWS_TO_INSERT_AD = 5

export default class extends Component {
  constructor(props) {
    super(props)

    const { initialValues, langId, isGridLayout, permissions, switchListLayout, list: { searchText } } = props

    initialValues.searchKey = searchText

    const categories = dict.filterOptionsForNews(langId)
    this.state = {
      categories,
      isOpen: false,
      guidDdlLang: uuid(),
      confirmItemId: undefined,
    }

    const isAdmin = canDo(permissions, 'admin')
    if (!isAdmin && isGridLayout) {
      switchListLayout(LIST_LAYOUT_ITEM)
    }
  }

  componentDidMount() {
    const languagesByDefault = ['ro', 'ru', 'en']
    const cookies = new Cookies()
    const languages = cookies.get('newsSelectedLanguages')

    if (!languages) {
      this.refresh({ languages: languagesByDefault, pageIndex: 0 })
    } else {
      this.refresh({ languages, pageIndex: 0 })
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
      this.refresh({ pageIndex: 0 })
    }
    if (this.props.langId !== prevProps.langId) {
      this.setState({ categories: dict.filterOptionsForNews(this.props.langId) })
    }

    const urlParams = new URLSearchParams(window.location.search)
    const filterKey = parseInt(urlParams.get('filterKey'))
    if (filterKey) {
      this.clickFilter(filterKey)
    }
  }

  getGroups() {
    const { items = [], pageSize } = this.props
    let chunk = []
    const groups = [chunk]

    items.forEach((x, j) => {
      if (j < pageSize) {
        if (j === 0) {
          chunk = groups[0]
        } else if (j % NUMBER_OF_ROWS_TO_INSERT_AD === 0) {
          chunk = []
          groups.push(chunk)
        }
        chunk.push(x)
      }
    })
    return groups
  }

  switchLayout = () => {
    const { isGridLayout, switchListLayout } = this.props
    switchListLayout(isGridLayout ? LIST_LAYOUT_ITEM : LIST_LAYOUT_GRID)
  }
  getIsGridLayout = () => this.props.isGridLayout

  showEditModal = item => {
    const { t } = this.props
    this.showModalComponent(<AddEdit onClose={this.clearModal} item={item} />, {
      title: t(item ? 'news.edit.item' : 'news.create.item'),
      className: 'modal-news-item',
      backdrop: true,
    })
  }
  clickAdd = () => {
    this.showEditModal()
  }
  clickEdit = item => {
    this.showEditModal(item)
  }
  clickDelete = item => {
    const { t, deleteNews } = this.props

    this.setState({ confirmItemId: item.id })
    confirmAlert({
      title: t('delete.confirm'),
      message: (
        <span>
          {t('delete.sure')}
          <br />
          {t('delete.cannot.undone')}
        </span>
      ),
      buttons: [
        {
          label: t('delete'),
          onClick: () => {
            deleteNews(this.state.confirmItemId, () => {
              this.hideConfirm()
              this.refresh()
            })
          },
        },
        {
          label: t('button.close'),
          onClick: this.hideConfirm,
        },
      ],
      onClickOutside: this.hideConfirm,
      onKeypressEscape: this.hideConfirm,
    })
  }
  hideConfirm = () => {
    this.setState({ confirmItemId: undefined })
  }

  toggle = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  }

  refresh = params => {
    const { languages, pageIndex, pageSize, filterKey, searchText, setParams } = this.props

    setParams('list', {
      languages,
      pageIndex,
      pageSize,
      filterKey,
      searchText,
      includeDeleted: true,
      ...params,
    })
  }

  clickFilter = newFilterKey => {
    const { filterKey } = this.props
    if (filterKey !== newFilterKey) {
      newFilterKey = newFilterKey === '' ? undefined : newFilterKey
      this.refresh({ filterKey: newFilterKey, pageIndex: 0 })
    }
  }

  doSearch = () => {
    const { searchKey, searchText } = this.props
    if (searchText !== searchKey) {
      this.refresh({ searchText: searchKey })
    }
  }
  onKeyUpSearch = event => {
    if (event.keyCode === 13) {
      this.doSearch()
    }
  }
  onBlurSearch = () => {
    this.doSearch()
  }

  drawAd() {
    return (
      <div className="link-simple">
        <Banner bannerId={569} />
      </div>
    )
  }

  drawFilterBar() {
    const { t, filterKey } = this.props
    const { categories } = this.state

    return (
      <Filter
        items={categories}
        emptyOption={t('all')}
        selectedKey={filterKey}
        loadLanguages
        css={{
          container: 'panel-filter-md panel-filter-search',
          filter: 'priority-nav-has-dropdown',
        }}
        className="priority-nav-has-dropdown"
        onClick={this.clickFilter}
      >
        <div className="filter-container">
          <div className="search-container-secondary">
            <Field
              name="searchKey"
              type="text"
              component="input"
              placeholder={t('news.search')}
              onBlur={this.onBlurSearch}
              onKeyUp={this.onKeyUpSearch}
            />
            <button type="submit">
              <span className="fa fa-search" />
            </button>
          </div>
        </div>
      </Filter>
    )
  }

  getLangItems = () => {
    const { t, languages } = this.props

    const langs = ['ro', 'ru', 'en'].map(lang => {
      const checked = languages.includes(lang)
      return {
        name: t(`news.submenu.options.${lang}`),
        value: lang,
        checked,
      }
    })
    return [{ name: t('news.submenu.header'), type: 'header' }, ...langs]
  }
  onChangeLang = languages => {
    const cookies = new Cookies()
    cookies.set('newsSelectedLanguages', languages)
    this.refresh({ languages })
  }
  renderWidgetHeader = () => {
    const { t, isAuthenticated, isGridLayout, permissions, languages } = this.props
    const canAdd = canDo(permissions, 'news', 'add')
    const isAdmin = canDo(permissions, 'admin')
    return (
      <WidgetHeader title={t('news.widget.title.list')}>
        {isAdmin && (
          <Switch
            className={classNames({
              [`${STYLE_BASE}wrapper`]: true,
              'menu-item': true,
              'has-text': true,
            })}
            isOn={isGridLayout}
            label={<Icon icon={table} />}
            onClick={this.switchLayout}
          />
        )}
        {isAuthenticated && canAdd && (
          <div className="menu-item has-text" onClick={this.clickAdd}>
            <div>
              <Icon icon={plus} size={12} />
              <span>{t('news.add')}</span>
            </div>
          </div>
        )}
        <DropdownCheckboxlist
          icon={cog}
          text={t('news.submenu.set.lang')}
          items={languages && this.getLangItems()}
          onChange={this.onChangeLang}
        />
      </WidgetHeader>
    )
  }

  renderListLayout = () => {
    const groups = this.getGroups()

    return groups.map((chunk, j) => (
      <Fragment key={j}>
        <div className="panel">
          {j === 0 && this.renderWidgetHeader()}

          <div className="panel-body">
            {j === 0 && this.drawFilterBar()}
            {chunk.map((item, index2) => (
                <Link key={index2} to={`/news/${item.id}`} className="news-item is-viewed">
                  {item.image && (
                    <div className={classNames('img-container', `${STYLE_BASE}img-container`)}>
                      <img
                        src={`${config.apiUrl}/common/file/${item.imageFolder}/${item.image}`}
                        alt={item.title}
                      />
                    </div>
                  )}
                  <div className="news-item-content">
                    <div className="news-item-title">
                      <h6 className="title">
                        {item.title}
                      </h6>
                      <div className={classNames(`${STYLE_BASE}date-time`)} >
                        {ForumCommentDate(item.date)}
                      </div>
                    </div>
                    <p>{item.short}</p>
                  </div>
                </Link>
              ))}
          </div>
        </div>
        {j !== groups.length - 1 && this.drawAd()}
      </Fragment>
    ))
  }
  getCategoryName = categoryId => {
    const { categories: { items = [] } = {} } = this.state
    const category = items.find(x => x.value === categoryId)
    return category && category.name
  }
  renderGridLayout = () => {
    const groups = this.getGroups()
    const { t, activeItemId, isAuthenticated, permissions, pageIndex, pageSize } = this.props
    const { confirmItemId } = this.state
    const canPublish = canDo(permissions, 'news', 'publish')
    const canEdit = canDo(permissions, 'news', 'edit')
    const canDelete = canDo(permissions, 'news', 'delete')
    let num = pageIndex * pageSize
    return groups.map((chunk, j) => (
      <Fragment key={j}>
        <div className="panel">
          {j === 0 && this.renderWidgetHeader()}

          <div className="panel-body">
            {j === 0 && this.drawFilterBar()}

            <div className="panel-tab tabs-content">
              <div className="panel-content">
                <div className="panel-content-table">
                  <table className="table table-striped-main table-link table-lg table-responsive js-table-responsive">
                    <thead>
                      <tr
                        className={classNames({
                          'tr-rounded-down': j > 0,
                        })}
                      >
                        <th>№</th>
                        <th>{t('news.category')}</th>
                        <th>{t('title')}</th>
                        <th>{t('date')}</th>
                        {isAuthenticated && (
                          <Fragment>
                            {canPublish && <th />}
                            {canEdit && <th />}
                            {canDelete && <th />}
                          </Fragment>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {chunk.map((item, index2) => {
                        num++
                        return (
                          <tr
                            key={index2}
                            className={classNames({
                              'tr-rounded-up': index2 === chunk.length - 1,
                              'tr-highlighted':
                                activeItemId === item.id || confirmItemId === item.id,
                            })}
                          >
                            <td className="num">{num}</td>
                            <td className="category">
                              <Link to={`/news/${item.id}`}>
                                {this.getCategoryName(item.category)}
                              </Link>
                            </td>
                            <td className="title">
                              <Link to={`/news/${item.id}`}>{item.title}</Link>
                            </td>
                            <td className="date-time">
                              <div className="date">{moment(item.date).format(formatDDMMYYYY)}</div>
                              <div className="time">
                                {/* <i className="fa fa-clock-o" /> */}
                                {moment(item.date).format('HH:mm:ss')}
                              </div>
                            </td>
                            {isAuthenticated && (
                              <Fragment>
                                {canPublish && (
                                  <td
                                    className="command"
                                    title={t(
                                      item.published ? 'news.switch.disable' : 'news.switch.enable'
                                    )}
                                  >
                                    <Switch
                                      isOn={item.published}
                                      onClick={() => this.publishItem(item)}
                                    />
                                  </td>
                                )}
                                {canEdit && (
                                  <td>
                                    <Icon
                                      className="command"
                                      title={t('edit')}
                                      icon={pencil}
                                      size={16}
                                      onClick={() => this.clickEdit(item)}
                                    />
                                  </td>
                                )}
                                {canDelete && (
                                  <td>
                                    <Icon
                                      className="command"
                                      title={t('delete')}
                                      icon={trashO}
                                      size={16}
                                      onClick={() => this.clickDelete(item)}
                                    />
                                  </td>
                                )}
                              </Fragment>
                            )}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {j !== groups.length - 1 && this.drawAd()}
      </Fragment>
    ))
  }
  publishItem = ({ itemId, published }) => {
    published = !published
    this.props.publishItem({ itemId, published, refreshList: true })
  }

  render() {
    const { total, pageIndex, pageSize, permissions, isGridLayout } = this.props
    const pagination = { total, pageIndex, pageSize }
    const isAdmin = canDo(permissions, 'admin')

    return (
      <ModalConsumer>
        {({ showModalComponent, clearModal }) => {
          this.showModalComponent = showModalComponent
          this.clearModal = clearModal
          return (
            <div
              className={classNames({
                [`${STYLE_BASE}wrapper`]: true,
              })}
            >
              {isAdmin && isGridLayout ? this.renderGridLayout() : this.renderListLayout()}
              {pagination.total <= SHOW_ADS_MIN_ITEMS && this.drawAd()}
              <Pagination
                {...pagination}
                onClick={pageIndex => {
                  this.refresh({ pageIndex })
                }}
              />
            </div>
          )
        }}
      </ModalConsumer>
    )
  }
}
