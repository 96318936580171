// import _ from "lodash"
import React, { Component, Fragment } from 'react'
import WidgetBase from '../../shared/components/widget-base'
import fetch from '../../../components/shared/fetch'
import { Link } from 'react-router-dom'

export default class extends Component {

  state = {
    items: [],
  }

  componentDidMount() {
    const { lang } = this.props
    const body = JSON.stringify({
      languages: [lang],
      pageIndex: 0,
      pageSize: 5,
      includeDeleted: true
    })

    fetch('/news/list', {
      method: 'POST',
      body,
    }).then(result => {
      const {
        d: { items },
      } = result
      this.setState({ items })
    })
  }

  render() {
    const { t } = this.props
    const { items } = this.state
    return (
      <WidgetBase title={t('read.more')}>
        <div className="panel-content">
          {(items || []).map(({ id, title }, index) => {
            return (
              <div className="panel-content-row" key={index}>
                <Link to={`/news/${id}`}>{title}</Link>
              </div>
            )
          })}

        </div>
      </WidgetBase>
    )
  }
}
