export const md19 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>ПРИКАЗ об утверждении положений и правил в области санитарно-ветеринарного надзора на границе и транспорте</h4><p></p>

<p></p><h4>Положение о ветеринарно-санитарном контроле грузов животного происхождения на экспорт/импорт и транзит</h4><p></p>

<p style="font-weight:500;">1. Настоящее положение регламентирует порядок ветеринарно-санитарного контроля подконтрольных Государственной  ветеринарной службе грузов, оформления сопровождающих ветеринарных документов при их импорте/экспорте и транзите.</p>

<p style="text-indent:2em;"></p><h6>2. Подконтрольными Государственной  ветеринарной службе являются следующие грузы животного происхождения при экспорте/импорте и транзите независимо от способа их транспортировки через государственную границу Республики Молдова:</h6><p></p>

<p style="text-indent:2em;">- животные всех видов, включая птиц, пушных зверей, лабораторных, зоопарковых,  спортивных, цирковых и домашних животных, морских зверей, пчел, рыб, других гидробионтов,
 эмбрионы и сперму животных, оплодотворенную икру, инкубационные яйца, тутовый шелкопряд;</p><br>

<p style="text-indent:2em;">- продукты животного происхождения (мясо и мясопродукты, мясо-растительные продукты, молоко и молочные продукты, рыба и рыбопродукты, яйца, яичный порошок, продукты яичного
 производства, мясо птицы и тушки  пернатой дичи, мед и другие продукты пчеловодства);</p><br>

<p style="text-indent:2em;">- сырье животного происхождения (шкуры, шерсть, мытая и немытая, пушнина, пух, перо, эндокринное и кишечное сырье, кровь, рога, копыта, кости, шерстяные очесы, щетина,
 желчь и другие виды сырья животного происхождения);</p><br>

<p>- искусственная пленка для колбасных изделий, специи для колбас, сычуги;</p><br>

<p style="text-indent:2em;">- корма животного происхождения (мясная, костная, мясокостная и рыбная мука, другие продукты убоя животных а также сухие комбинированные и живые корма для рыб);</p><br>

<p style="text-indent:2em;">- корма растительного происхождения (фуражное зерно, комбикорма, кормовые смеси, шроты, жмыхи, корнеклубнеплоды, сено, солома, сенаж, отруби, меляса, свекловичный жом, коконы шелкопряда, подстилка, торф);</p><br>

<p style="text-indent:2em;">- ветеринарные препараты, вакцины ветеринарные, живые культуры микроорганизмов, сыворотки иммунные из крови животных, среды культурные для выращивания микроорганизмов, диагностические препараты, коллекции и предметы
 коллекционирования животного происхождения, охотничьи трофеи;</p><br>

<p style="text-indent:2em;">- готовые пищевые продукты (консервы мясные, мясо-растительные, рыбные, сыры, масло сливочное, маргарин, жиры, сухое молоко, детское питание, колбасные изделия, концентраты, мороженое, майонез) в ненарушенной упаковке,
 изготовленные в соответствии с требованиями стандартов и нормативов на предприятиях государства-экспортера;</p><br>

<p>- дезинфектанты и препараты для дератизации;</p><br>

<p>- все продукты, содержащие компоненты животного происхождения;</p><br>

<p>- искусственная оболочка, специи для колбас, сычуги.</p><br>

<p style="font-weight:500;">3. Допускаются к импорту в Республику Молдова и экспорту из Республики Молдова продукты животного происхождение исключительно только юридическими лицами (организации, учреждения, предприятия, ассоциации, ООО и др.),
 аттестованными в установленном порядке Государственной ветеринарной службой. Физическим лицам запрещается импорт в Республику Молдова и экспорт из Республики Молдова продуктов животного происхождения за исключением
 ограниченного количества термически обработанных продуктов для питания на период нахождения гражданина в пути.</p><br>

<p style="text-indent:2em;">Транспортировка продуктов и сырья животного происхождения, живых животных на экспорт/импорт осуществляется транспортными средствами (автомобильными, железнодорожными вагонами и контейнерами, воздушными и морскими судами),
 аттестованными Государственной ветеринарной службой и обработанными перед их погрузкой на специализированных дезопромывочных пунктах (ДПП).</p>

<p></p><h4>Экспорт</h4><p></p>

<p style="text-indent:2em;">Перед отправкой на экспорт грузы подлежат ветеринарно-санитарному контролю территориальной Государственной ветеринарной службой. В необходимых случаях проводятся лабораторные исследования, животные содержатся в карантине
 в соответствии с имеющимися инструкциями и в это время проводятся все лабораторные исследования и профилактические вакцинации.</p><br>

<p style="text-indent:2em;">На основании ветеринарно-санитарной аттестации экономический агент получает в Главном управлении ветеринарной медицины ветеринарно-санитарное разрешение на право экспорта.</p><br>

<p style="text-indent:2em;">Территориальная государственная ветеринарная служба после проведенного ветеринарного контроля выписывает национальное ветеринарное свидетельство (сертификат) на каждую единицу транспорта с грузом, а для грузов,
 экспортируемых в страны СНГ, выписывает специальный ветеринарный сертификат с подписью и печатью Главного управления ветеринарной медицины.</p><br>

<p style="text-indent:2em;">На пограничных пунктах государственный ветеринарный инспектор проверяет весь пакет документов, сопровождающих груз (ветеринарно- санитарное разрешение на экспорт, ветеринарное свидетельство, сертификат на дезинфекцию автотранспорта,
 сертификат соответствия и др.) и после этого осуществляет ветеринарно-санитарный контроль груза, упаковки, санитарного состояния транспортного средства, условий транспортировки продукции, опись перевозимых животных, производится
 термометрия животных. В случае, когда все условия соблюдаются, пограничный ветеринарно-санитарный инспектор регистрирует в специальном журнале и в компьютере всю информацию, выписывает международный ветеринарный сертификат взамен
 национального сертификата, а для грузов, следующих в страны СНГ, на ветеринарном сертификате делает необходимые отметки.</p><br>

<p style="text-indent:2em;">За проведенную работу взимается плата в соответствии с имеющимися тарифами (в случае пользования единым боном - делает соответствующую отметку в боне).</p><br>

<p style="text-indent:2em;">В случаях экспорта соответствующих грузов по железной дороге, ветеринарно-санитарный инспектор на границе и транспорте присутствует при погрузке продукции (животных) в вагоны или контейнеры и требует соблюдения действующих
 ветеринарно-санитарных требований (ветеринарно-санитарного состояния вагонов или контейнеров, продукции и упаковки, соответствия количества продукции (количество животных), погруженной в вагоны (контейнеры), количеству продукции,
 указанной в сопроводительных документах. После этого осуществляет проверку сопроводительных документов (ветеринарно-санитарное разрешение на экспорт, ветеринарный сертификат (национальный или СНГ), опись животных, экспертиза
 лабораторных исследовании, сертификат дезинфекции и др.).</p>

<p></p><h4>Импорт</h4><p></p>

<p style="text-indent:2em;">При въезде на территорию Республики Молдова на пограничных таможенных пунктах весь автотранспорт подвергается профилактической дезинфекции в целях недопущения ввоза из других стран болезнетворных микроорганизмов инфекционных
 заболеваний животных.</p><br>

<p style="text-indent:2em;">Пограничный ветеринарно-санитарный инспектор осуществляет контроль ветеринарных документов, сопровождающих груз (ветеринарно-санитарное разрешение на импорт, ветеринарный сертификат, документы качества, сертификат соответствия и др.).
 После этого осуществляется ветеринарно-санитарный осмотр импортированной продукции - состояние упаковки, груза, срок годности продукции, санитарное состояние транспортного средства, условия перевозки продукции, животные
 подвергаются термометрии.</p><br>

<p style="text-indent:2em;">После завершения контроля пограничный ветеринарный инспектор регистрирует всю информацию в журнале установленной формы и в компьютере, делает нужные отметки, ставит печать на сопровождающий грузы ветеринарный сертификат
 и взимает плату в соответствии с имеющимися тарифами (в случае применения единого платежного талона делается в нем соответствующая запись и подписывается талон о пропуске транспортного средства с подконтрольным грузом).</p><br>

<p style="text-indent:2em;">На внутренней таможне ветеринарно-санитарный инспектор пограничного транспортного ветеринарного пункта изучает сопровождающие груз документы, проверяет дату и место прохождения транспорта через государственную границу.</p><br>

<p style="text-indent:2em;">Ветеринарно-санитарный инспектор пограничного транспортного ветеринарного пункта присутствует при выгрузке в аттестованные склады (холодильники) импортируемой продукции, следит, чтобы их ветеринарно-санитарное состояние
 соответствовало требованиям для хранения данной продукции.</p><br>

<p style="text-indent:2em;">В момент выгрузки продукция и упаковка подвергаются тщательному ветеринарно-санитарному контролю в присутствии владельца груза и таможенного инспектора.</p><br>

<p style="text-indent:2em;">Ветеринарный инспектор производит отбор проб для лабораторного исследования, составляет акт отбора проб и в сопровождении ветеринарного сертификата и акта отбора проб направляет их в РДВЦ для национальной сертификации.</p><br>

<p style="text-indent:2em;">Одновременно ветеринарно-санитарный инспектор пограничного транспортного ветеринарного пункта извещает (письменно или по телефону) территориальную государственную ветеринарную службу об импортируемой продукции с указанием
 наименования продукции, его количества, название страны происхождения товара, адрес склада (холодильника), где хранится груз.</p><br>

<p style="text-indent:2em;">Начиная с этого момента ответственным за дальнейшее состояние данной продукции является экономический агент - владелец продукции и территориальная государственная ветеринарная служба.</p><br>

<p style="text-indent:2em;">Исходя из результатов лабораторных исследований, проведенных в РДВЦ, экономический агент получает в территориальной госветслежбе ветеринарный сертификат, который дает право на реализацию (переработку) импортированной
 продукции.</p>

<p></p><h4>Транзит</h4><p></p>

<p style="text-indent:2em;">На пограничных контрольно-транспортных ветеринарных пунктах ветеринарные инспектора изучают сопровождающие подконтрольные госветслужбе грузы, делают соответствующие записи, ставят печать на документы, регистрируют всю информацию
 в журнале и в компьютере, взимают плату согласно действующим тарифам (плата берется один раз при въезде транспортного средства с грузом на территорию Республики Молдова) и отпускает транспорт для дальнейшего следования по
 назначению.</p><br>

<p style="text-indent:2em;">При транзите через территорию Республики Молдова животных (за исключением домашних непродуктивных животных, принадлежащих физическим лицам) требуется получение согласия на транзит (в письменном виде) данной партии животных
 у Главного управления ветеринарной медицины Республики Молдова.</p><br>

<p style="text-indent:2em;">Во всех случаях, когда при импорте/экспорте и транзите продукции животного происхождения обнаруживаются нарушения ветеринарного законодательства (отсутствие ветеринарно-санитарного разрешения на импорт/экспорт и транзит,
 транспортировка продукции с нарушениями ветеринарно-санитарных требований, несоблюдение температурного режима, несоответствие упаковки, просроченные сроки годности продукции, плохое санитарное состояние транспортного средства,
 обнаружены больные животные и трупы животных, отсутствие описей перевозимых животных, отсутствие индивидуальных номеров животных и др.), ветеринарно-санитарный инспектор на границе составляет акт на задержание данной партии груза.
 Об этом информируется таможенный инспектор, который также подписывает акт, и руководство госветслужбы на границе и транспорте.</p><br>

<p style="text-indent:2em;">Пропуск данного груза через государственную границу производится только в случае устранения выявленных нарушений.</p><br>

<p style="text-indent:2em;">В случае, когда устранение выявленных нарушений невозможно, составляется соответствующий акт и данная партия продукции возвращается отправителю. Об этом также ставится в известность руководство Госветслужбы на границе и 
транспорте.</p><br>

<p style="text-indent:2em;">В случае экспорта/импорта небольшого количества продукции животного происхождения с грубыми нарушениями ветеринарного законодательства, которая представляет опасность для здоровья людей и животных, данная продукция подлежит уничтожению
 на погранично-контрольном ветеринарном пункте путем сжигания в специально оборудованной печи в присутствии владельца груза, таможенного инспектора и других заинтересованных лиц с составлением соответствующего акта об этом.</p><br>

<p style="text-indent:2em;">За все проведенные работы и оказанные услуги (профилактическая дезинфекция транспортного средства, ветеринарно-санитарный контроль груза, оформление сопровождающих документов, составление актов задержания, возврата или уничтожение груза)
 взимается плата в соответствии с имеющимися тарифами.</p><br>

<p style="text-indent:2em;">За нарушение ветеринарного законодательства при импорте/экспорте и транзите продукции животного происхождения государственный ветеринарно-санитарный инспектор на границе и транспорте имеет право наложить штрафные санкции в размере до 10
 минимальных заработных плат для граждан и до 30 минимальных заработных плат для должностных лиц (ст. 107 Кодекса административных правонарушении Республики Молдова).</p>

<p style="text-indent:2em;"></p><h6>Приложение № 2</h6><p></p>

<p></p><h4>Положение о пограничном транспортном контрольном ветеринарном пункте (ПТКВП)</h4><p></p>

<p style="font-weight:500;">1. В соответствии с Законом "О ветеринарной деятельности" погранично-транспортные контрольные ветеринарные пункты (ПТКВП) создаются приказом Министерства сельского хозяйства и пищевой промышленности РМ по согласованию с Таможенным
 департаментом РМ, Министерством транспорта и связи РМ, другими заинтересованными министерствами и ведомствами.</p><br>

<p style="text-indent:2em;">ПТКВП создаются на пограничных таможенных постах в местах перехода продукции и сырья животного происхождения, живых животных, кормов для животных, других подконтрольных госветслужбе грузов, на внутренних таможенных постах и на
 железнодорожных станциях вблизи мест погрузки-выгрузки транспортных средств (автомобильные, железнодорожные и др.) с продуктами и сырьем животного происхождения, живыми животными, кормами для животных и другими грузами, подконтрольными
 Госветслужбе при их импорте, экспорте и транзите по территории РМ.</p><br>

<p style="text-indent:2em;">ПТКВП являются структурными подразделениями Государственной ветеринарной службы на границе и транспорте, возглавляемыми начальником, который назначается на эту должность и освобождается начальником Госветслужбы на границе и
 транспорте при согласовании с начальником Главного управления ветеринарной медицины, с Госветинспекцией РМ. Ветеринарные специалисты ПТКВП принимаются на работу и освобождаются с работы начальником Госветслужбы на границе и
 транспорте по согласованию с начальником ПТКВП.</p>

<p style="text-indent:2em;"></p><h6>2. Основные функции ПТКВП:</h6><p></p>

<p style="text-indent:2em;">- ветеринарно-санитарный надзор за импортом/экспортом и транзитом продуктов и сырья животного происхождения, живых животных всех видов, кормов для животных, других подконтрольных Госветслужбе грузов на пограничных таможенных
 постах, на внутренних таможенных постах, на пограничных железнодорожных постах и на железнодорожных станциях;</p><br>

<p style="text-indent:2em;">- ветеринарно-санитарный контроль и оформление ветеринарных документов, сопровождающих грузы при импорте/экспорте и транзите в любом виде транспорта;</p><br>

<p style="text-indent:2em;">- ветеринарно-санитарный контроль подконтрольных Госветслужбе импортированных грузов, отбор проб для лабораторных исследований и их национальной сертификации;</p><br>

<p style="text-indent:2em;">- контроль за соблюдением ответственными лицами, экономическими агентами и физическими лицами ветеринарно-санитарных требований при импорте, экспорте и транзите подконтрольных Госветслужбе грузов;</p><br>

<p style="text-indent:2em;">- осуществление ветеринарно-санитарных профилактических мероприятий по охране территории республики от заноса извне инфекционных заболеваний животных;</p><br>

<p style="text-indent:2em;">- осуществление ветеринарно-санитарной обработки транспортных средств перед погрузкой и после выгрузки продуктов подконтрольных Госветслужбе.</p><br>

<p style="font-weight:500;">3. В своей деятельности ветеринарные специалисты ПТКВП руководствуются Законом о ветеринарной деятельности РМ, ветеринарным уставом РМ, приказами и распоряжениями Главного управления ветеринарной медицины с Госветинспекцией,
 приказами и распоряжениями начальника Госветслужбы на границе и транспорте, инструкциями, правилами, положениями и другими нормативными актами в области ветеринарии как национальными, так и международными.</p>

<p style="text-indent:2em;"></p><h6>4. Государственному ветеринарному надзору на границе и транспорте подвержены следующие грузы импортированные/экспортированные в/из Республики Молдова всеми видами транспорта, включая ручную кладь:</h6><p></p>

<p style="text-indent:2em;">- животные всех видов, включая птиц, пушных зверей, лабораторных, зоопарковых, спортивных, цирковых и домашних животных, морских зверей, пчел, рыб, других гидробионтов, эмбрионы и сперму животных, оплодотворенную икру,
 инкубационные яйца, тутовый шелкопряд;</p><br>

<p style="text-indent:2em;">- продукты животного происхождения (мясо и мясопродукты, мясо-растительные продукты, молоко и молочные продукты, рыба и рыбопродукты, яйца, яичный порошок, продукты яичного производства, мяса птицы и тушки пернатой дичи,
 мед и другие продукты пчеловодства);</p><br>

<p style="text-indent:2em;">- сырье животного происхождения (шкуры, шерсть мытая и немытая, пушнина, пух, перо, эндокринное и кишечное сырье, кровь, рога, копыта, кости, шерстяные очесы, щетина, желчь и другие виды сырья животного происхождения);</p><br>

<p style="text-indent:2em;">- корма животного происхождения (мясная, костная, мясо-костная и рыбная мука, другие продукты убоя животных, а также сухие комбинированные и живые корма для рыб);</p><br>

<p style="text-indent:2em;">- корма растительного происхождения (фуражное зерно, комбикорма, кормовые смеси, шроты, жмыхи, корнеклубнеплоды, сено, солома, сенаж, отруби, меляса, свекловичный жом, коконы шелкопряда, подстилка, торф);</p><br>

<p style="text-indent:2em;">- ветеринарные препараты, вакцины ветеринарные, живые культуры микроорганизмов, сыворотки иммунные из крови животных, среды культурные для выращивания микроорганизмов, диагностические препараты, коллекции и предметы
 коллекционирования животного происхождения, охотничьи трофеи;</p><br>

<p style="text-indent:2em;">- готовые пищевые продукты (консервы мясные, мясо-растительные, рыбные, сыры, масло сливочное, маргарин, жиры, сухое молоко, детское питание, колбасные изделия, концентраты, мороженое, майонез) в ненарушенной упаковке,
 изготовленные в соответствии с требованиями стандартов и нормативов на предприятиях государства-экспортера;</p><br>

<p>- дезинфектанты и препараты для дератизации;</p><br>

<p>- все продукты, содержащие компоненты животного происхождения;</p><br>

<p>- искусственная оболочка, специи для колбас, сычуги.</p><br>

<p style="font-weight:500;">5. ПТКВП функционирует 24 часа в сутки. Распорядок дня работы пункта устанавливает руководство Госветслужбы на границе и транспорте с учетом распорядка дня работы таможенной службы, грузовых товарных железнодорожных
 станций и других служб.</p><br>

<p style="font-weight:500;">6. Финансирование ПТКВП осуществляется централизованно Госветслужбой на границе и транспорте из госбюджета и собственных средств.</p><br>

<p style="font-weight:500;">7. Штатная структура и численность персонала ПТКВП устанавливается и утверждается начальником Госветслужбы на границе и транспорте и согласовывается с Главным управлением ветеринарной медицины с Госветинспекцией.</p><br>

<p style="font-weight:500;">8. За оказанные ветеринарными специалистами ПТКВП услуги (оформление ветеринарных сопроводительных документов, ветеринарно-санитарный контроль грузов животного происхождения при их импорте/экспорте и транзите,
 профилактическая дезинфекция транспортных средств, отбор проб для лабораторного исследования и национальной сертификации и др.), взимается плата в соответствии с утвержденными тарифами.</p><br>

<p style="font-weight:500;">9. ПТКВП оборудованы и обеспечены всем необходимым для их нормального функционирования - обеспечены служебными помещениями, подсобными помещениями для хранения дезосредств и инвентаря, ПТКВП обеспечены телефонной связью.</p><br>

<p style="font-weight:500;">10. Для осуществления профилактической дезинфекции транспортных средств в составе Госветслужбы на границе и транспорте образованы зональные пункты по ветеринарно-санитарной обработке транспортных средств, а для
 ветеринарно-санитарной обработки вагонов действует дезопромывочный пункт (ДПП) Варница.</p><br>

<p style="font-weight:500;">11. На пограничных таможенных пунктах установлены оборудование и сооружения для профилактической дезинфекции транспортных средств при их въезде на территорию республики.</p><br>

<p style="font-weight:500;">12. Сотрудники ПТКВП обеспечиваются бесплатно служебной одеждой и обувью.</p><br>

<p style="font-weight:500;">13. ПТКВП оборудованы компьютерами и журналами для учета всех подконтрольных Госветслужбе грузов, регистрации всех отобранных для лабораторных исследований проб, регистрации всех обработанных транспортных средств
 и других выполненных ветеринарными специалистами работ.</p>

<p style="text-indent:2em;"></p><h6>Начальник ПТКВП - государственный ветеринарный инспектор:</h6><p></p>

<p style="text-indent:2em;">- является ответственным за нормальное функционирование ПТКВП, за выполнение сотрудниками поставленных перед ними задач;</p><br>

<p style="text-indent:2em;">- является ответственным за хранение, учет и использование по назначению всех находящихся на ПТКВП материальных ценностей;</p><br>

<p style="text-indent:2em;">- совместно с начальником Госветслужбы на границе и транспорте, начальником таможенного поста, начальником товарной железнодорожной станции составляет распорядок работы ПТКВП;</p><br>

<p style="text-indent:2em;">- является ответственным за ведением учета и отчетности на ПТКВП;</p><br>

<p style="text-indent:2em;">- координирует прием на работу и освобождение с работы, осуществляет перевод сотрудников на другие участки работы;</p><br>

<p style="text-indent:2em;">- предлагает наложение дисциплинарных и других взысканий на сотрудников, допустивших нарушения дисциплины или должностных обязанностей;</p><br>

<p style="text-indent:2em;">- предлагает премирование отличившихся сотрудников за примерное выполнение своих служебных обязанностей;</p><br>

<p style="text-indent:2em;">- доводит до сведения сотрудников полученные от вышестоящих органов или от других служб и организаций служебные документы;</p><br>

<p style="text-indent:2em;">- оперативно информирует начальников таможни, начальников товарных железнодорожных станций, другие заинтересованные службы о случаях возникновения инфекционных болезней в странах, с которыми Республика
 Молдова имеет торговые и другие связи;</p><br>

<p style="text-indent:2em;">- непосредственно участвует в ветеринарно-санитарном контроле грузов животного происхождения и оформлении сопровождающих ветеринарных документов;</p><br>

<p style="text-indent:2em;">- организует и проводит на ПТКВП ветеринарно-санитарные профилактические мероприятия по охране территории Республики Молдова от заноса инфекционных заболеваний животных;</p><br>

<p style="text-indent:2em;">- представляет ПТКВП на совещаниях, заседаниях, других форумах, которые проводятся на таможнях, железнодорожных станциях и в других службах и организациях;</p><br>

<p>- предоставляет оперативную и другую информацию по требованию вышестоящих инстанций;</p><br>

<p">- представляет в установленные сроки отчеты о проделанной работе на ПТКВП.<p></p>

<p style="text-indent:2em;"></p><h6>Начальник ПТКВП имеет право:</h6><p></p>

<p style="text-indent:2em;">- давать указания должностным лицам, экономическим агентам, руководителям предприятий и физическим лицам по соблюдению ветеринарно-санитарных требований при импорте/экспорте и транзите подконтрольных
 Госветслужбе грузов, ветеринарно-санитарной обработке транспортных средств и др.;</p><br>

<p style="text-indent:2em;">- запрещать импорт/экспорт и транзит животных в случаях обнаружения в транспортируемой партии больных животных или в случаях выявления в зоне действия ПТКВП очагов инфекционных заболеваний животных;</p><br>

<p style="text-indent:2em;">- запрещать импорт/экспорт и транзит подконтрольных Госветслужбе грузов в случаях несоблюдения ветеринарного законодательства как национального, так и международного;</p><br>

<p style="text-indent:2em;">- расследовать факты, связанные с нарушением национального или международного ветеринарного законодательства;</p><br>

<p style="text-indent:2em;">- применять к ответственным лицам, к экономическим агентам и физическим лицам санкции за нарушения ветеринарного законодательства;</p><br>

<p style="text-indent:2em;">- посещать без ограничений в служебных целях предприятия, организации и производственные подразделения, расположенные в зоне действия ПТКВП.</p>

<p style="text-indent:2em;"></p><h6>Ветеринарные врачи ПТКВП обязаны:</h6><p></p>

<p>- осуществлять ветеринарно-санитарный контроль подконтрольных Госветслужбе грузов;</p><br>

<p>- проверять и оформлять сопровождающие груз ветеринарные документы;</p><br>

<p style="text-indent:2em;">- проверять ветеринарно-санитарное состояние транспортного средства, перевозящего груз животного происхождения при импорте/экспорте и транзите;</p><br>

<p>- проверять соблюдение условий перевозки продукции и животных, состояние упаковки;</p><br>

<p>- проверять сроки хранения импортируемых/экспортируемых грузов животного происхождения;</p><br>

<p style="text-indent:2em;">- производить отбор проб импортированной продукции животного происхождения и направлять их для национальной сертификации в РВДЦ;</p><br>

<p style="text-indent:2em;">- проверять выполнение экономическими агентами и физическими лицами мероприятий по охране территории от заноса извне инфекционных заболеваний животных при въезде на территорию республики;</p><br>

<p>- вести учет в специальном журнале всех подконтрольных Госветслужбе грузов, следующих через ПТКВП;</p><br>

<p style="text-indent:2em;">- предоставлять Госветслужбе отчет и оперативную информацию о выполненной работе на ПТКВП по установленной форме и в определенные сроки;</p><br>

<p style="text-indent:2em;">- при импорте/экспорте животных осуществлять их клинический осмотр и термометрию в соответствии с действующим положением;</p><br>

<p style="text-indent:2em;">- осуществлять ветеринарно-санитарную обработку транспортных средств, предназначенных для транспортировки подконтрольных Госветслужбе грузов и транспортных средств после их выгрузки в соответствии
 с действующими инструкциями;</p><br>

<p style="text-indent:2em;">- взаимодействовать с таможенными инспекторами, сотрудниками грузовых товарных станций, другими специалистами и службами.</p>

<p style="text-indent:2em;"></p><h6>Ветеринарные специалисты имеют право:</h6><p></p>

<p style="text-indent:2em;">- задерживать или возвращать отправителю партию грузов животного происхождения в случаях нарушения национального или международного ветеринарного законодательства, несоблюдения условий транспортировки
 продукции или нарушения других ветеринарно-санитарных требований;</p><br>

<p style="text-indent:2em;">- запрещать погрузку в вагоны и выгрузку из вагонов и из других транспортных средств в случаях несоблюдения ветеринарно-санитарных требований;</p><br>

<p style="text-indent:2em;">- применять штрафные санкции к должностным лицам, к экономическим агентам и физическим лицам за нарушения национального и международного ветеринарного законодательства при импорте/экспорте и транзите
 продукции животного происхождения, живых животных, кормов для животных и других продуктов животного происхождения, включенных в список, утвержденный Главным управлением ветеринарной медицины с Госветинспекцией;</p><br>

<p style="text-indent:2em;">- давать указания должностным лицам, экономическим агентам и физическим лицам по вопросам соблюдения ветеринарно-санитарных требований при импорте/экспорте и транзите подконтрольных Госветслужбе грузов
 или ограничений, введенных в связи с неблагополучной эпизоотической обстановкой;</p><br>

<p>- носить форменную одежду установленного образца.</p><br>

<p style="text-indent:2em;">Личные листки по учету кадров, трудовые книжки сотрудников ПТКВСП, другие документы находятся в отделе кадров Госветслужбы на границе и транспорте.</p>

<p style="text-indent:2em;"></p><h6>Приложение № 3</h6><p></p>

<p></p><h4>Положение о пункте для дезинфекции автотранспорта</h4><p></p>

<p style="font-weight:500;">1. Пункт для дезинфекции автотранспорта является структурным подразделением Госветслужбы на границе и транспорте (ПТКВП) и находится в подчинении руководителя этой организации.</p><br>

<p style="font-weight:500;">2. Главной задачей пункта для дезинфекции автотранспорта является: дезинфекция автотранспорта для перевозки продукции животного происхождения, сырья животного происхождения, живых животных
 и кормов для животных; ветеринарно-санитарная обработка транспортных средств после выгрузки продукции и сырья животного происхождения, животных и кормов для животных.</p><br>

<p style="font-weight:500;">3. Для выполнения работ по ветеринарно-санитарной обработке транспортных средств и их дезинфекции пункт обеспечивается дезсредствами, а работники обеспечиваются спецодеждой и спецобувью, инвентарем
 и всем необходимым для работы.</p><br>

<p style="font-weight:500;">4. Пункт для дезинфекции автотранспорта работает в установленное рабочее время и его работа контролируется начальником ПТКВП, в подчинении которого он находится.</p><br>

<p style="font-weight:500;">5. На пункте для дезинфекции транспортных средств ведется учет обработанного транспорта и выписывается сертификат о проведенной дезинфекции под номером, соответствующем записи в журнале.</p><br>

<p style="font-weight:500;">6. За проделанную работу по ветеринарно-санитарной обработке транспортных средств взимается плата в соответствии с имеющимися тарифами исходя из категории проведенной дезинфекции.</p><br>

<p style="font-weight:500;">7. Штаты пункта для дезинфекции состоят из ветеринарного врача, одновременно являющегося и начальником пункта и санитаром-дезинфектором.</p><br>

<p style="font-weight:500;">8. Должностные обязанности начальника пункта для дезинфекции автотранспорта</p><br>

<p style="font-weight:500;">8.1 ветеринарный врач (начальник) пункта для дезинфекции: организует работу пункта, составляет план работы, ведет учет обработанных транспортных средств, берет плату за выполненную работу, выписывает
 сертификат на обработанный транспорт, составляет отчет о проделанной работе и представляет его вышестоящей организации, ведет переписку, рассматривает жалобы, делает заявку на дезсредства и списывает
 использованные препараты;</p><br>

<p style="font-weight:500;">8.2 заключает договора на выполнение работ;</p><br>

<p style="font-weight:500;">8.3 периодически приглашает лабораторных работников для отбора смывов от продезинфицированного автотранспорта с целью определения качества проведенной дезинфекции.</p>

<p style="text-indent:2em;"></p><h6>9. Ветеринарный врач пункта для дезинфекции (начальник) несет ответственность:</h6><p></p>

<p style="font-weight:500;">9.1 за сохранность и использование по назначению материальных ценностей, находящихся на пункте;</p><br>

<p style="font-weight:500;">9.2 за выполнение качественно и в срок работ по ветеринарно-санитарной обработке транспортных средств;</p><br>

<p style="font-weight:500;">9.3 за правильное использование дезсредств;</p><br>

<p style="font-weight:500;">9.4 за технику безопасности во время работы с дезсредствами.</p>

<p></p><h4>Порядок дезинфекции автотранспорта</h4><p></p>

<p style="font-weight:500;">1. Дезинфекции подвергается весь автотранспорт, предназначенный для перевозки за пределами страны продукции животного происхождения, и весь автотранспорт после выгрузки такого же импортированного
 груза. Дезинфекция автотранспорта и упаковки производится после каждой выгрузки (освобождения) из транспортного средства продукции животного происхождения, ввезенной из-за границы в Республику
 Молдова, и каждая единица автотранспорта перед погрузкой в нее продукции животного происхождения, предназначенной на экспорт. Транспортные средства очищаются, моются и дезинфицируются.</p><br>

<p style="text-indent:2em;">Дезинфекция производится с использованием проверенных и широко применяемых для этих целей в других странах дезсредств.</p><br>

<p style="text-indent:2em;">Запрещается использовать агрессивные к металлам дезсредства, которые могут вызвать порчу транспортного средства, имеющие сильный неприятный запах и др. В зависимости от характеристики препарата он
 может использоваться в холодном или горячем виде.</p><br>

<p>Установлены три категории дезинфекции транспортных средств - I, II, III.</p><br>

<p style="text-indent:2em;">По первой категории обрабатывают транспортные средства, в которых перевозили здоровых животных, мясо и мясопродукты от здоровых животных и другие продукты, полученные от здоровых животных, транспорт,
 в который предусматривается погрузка продукции, предназначенной для перевозки в пределах страны. Транспортное средство очищается от остатков после перевозки продукции и моется водой. В необходимых
 случаях мойка транспортного средства может осуществляться повторно.</p><br>

<p style="text-indent:2em;">По второй категории обрабатываются транспортные средства после перевозки живой товарной рыбы, подаваемые под погрузку рыб, оплодотворенной икры, племенных и других животных и всех продуктов животного
 происхождения, отправляемых на экспорт, а также после выгрузки импортной продукции животного происхождения.</p><br>

<p style="text-indent:2em;">В профилактических целях автотранспорт обрабатывается по второй категории, которая предусматривает его очищение от остатков после перевозки продукции, мойка и дезинфекция. После дезинфекции выдерживается
 пауза-экспозиция для действия препарата на микроорганизмы. В каждом случае данная экспозиция зависит от применяемого препарата и длится от 15 минут до двух часов. После этого производится окончательная
 мойка транспорта от остатков дезсредства.</p><br>

<p style="text-indent:2em;">По третьей категории обрабатываются транспортные средства, перевозившие больных или подозрительных в заражении инфекционными заболеваниями, грузы животноводства неизвестного ветеринарно-санитарного
 происхождения или в которых при перевозке животных обнаружен труп животного. Эти транспортные средства очищают от загрязнений и навоза, дезинфицируют, промывают и вторично дезинфицируют.</p><br>

<p style="text-indent:2em;">Перед очисткой внутренние стены, пол и поверхностный слой навоза, а также находящиеся внутри транспорта оборудование и приспособления орошают дезинфицирующим раствором. Малоценные предметы внутреннего
 оборудования сжигают. Транспорт со всем оборудованием дезинфицируют с внутренней и наружной сторон одним из дезрастворов: раствор гипохлора, осветленный раствор хлорной извести или раствором гипохлорита
 кальция, содержащим не менее 5% активного хлора, или 4%-ным раствором формальдегида. Норма расхода раствора - 0,5 л на 1 кв. м площади. Через 30 мин. после дезинфекции транспортное средство с внутренней
 и наружной сторон тщательно промывают струей горячей воды под давлением не менее 2 атм. до полного осветления стекающей жидкости. После промывки транспортное средство с внутренней стороны дезинфицируют
 повторно тем же раствором при норме расхода 1 л/кв. м. После этого транспорт закрывают и тем же раствором дезинфицируют наружные поверхности, площадки и ходовую часть. Норма расхода 0,5 л/кв. м площади.
 Через 30 мин. после окончания повторной дезинфекции транспортное средство промывают горячей водой.</p><br>

<p>После окончания всей процедуры исполнитель ставит в путевом листе печать с надписью "DEZINFECTAT".</p><br>

<p style="text-indent:2em;">Ветеринарный врач записывает в журнале данные об обработанном транспортном средстве, выдает сертификат, подтверждающий проведение дезинфекции под номером, зарегистрированном в журнале, и берет плату в
 соответствии с действующими тарифами в зависимости от категории обработки.</p><br>

<p>Срок действия сертификата - 3 дня со дня выдачи.</p><br>

<p style="text-indent:2em;">Сертификат предъявляется ветеринарному врачу, который осуществляет ветеринарно-санитарный контроль при погрузке продуктов животного происхождения, и ветеринарному врачу на пограничном контрольном ветеринарном посту.</p>

<p style="text-indent:2em;"></p><h6>Приложение № 4</h6><p></p>

<p></p><h4>ВЕТЕРИНАРНО-САНИТАРНЫЕ ПРАВИЛА</h4><p></p>

<p style="text-indent:2em;">при экспорте и импорте грузов, поднадзорных Госветслужбе, сырья животного
происхождения и кормов автомобильным, железнодорожным и воздушным транспортом</p><br>

<p style="font-weight:500;">1. Перевозке любым видом транспорта грузов, поднадзорных Госветслужбе, сырья животного происхождения, кормов для животных разрешается если они происходят из благополучных хозяйств и территории по
 инфекционным болезням животных и соответствуют действующим стандартам.</p><br>

<p style="text-indent:2em;">В отдельных случаях Главное управление ветеринарной медицины с Госветинспекций может ввести специальные условия доставки или запрещения доставки подконтрольных грузов.</p><br>

<p style="font-weight:500;">2. При погрузке в любой вид транспорта на экспорт подконтрольных Госветслужбе грузов и выгрузке импортированных грузов необходимо обязательное присутствие специалиста погранично-контрольного
 ветеринарного пункта на транспорте (ПКВПТ).</p><br>

<p style="font-weight:500;">3. Для отправки поднадзорных грузов отправитель представляет ветеринарному специалисту на ПКВПТ разрешение на экспорт, выданное Главным ветеринарным управлением с Госветинспекцией, сертификат о
 дезинфекции вагонов, выданный Варницким дезинфекционно-промывочным пунктом, сертификат о дезинфекции автотранспорта, выданный соответствующими дезинфекционными пунктами Госветслужбы на границе
 и транспорте, ветеринарный сертификат установленной формы, выданный территориальной Госветслужбой, и сертификат соответствия на отправленную партию груза, выданный лабораторией качества 
 продовольственных товаров Республиканского диагностического ветеринарного центра или другими центрами, аккредитованными в этой области.</p><br>

<p style="font-weight:500;">4. Ветеринарный специалист ПКВПТ подвергает ветеринарно-санитарному контролю каждую единицу груза, отправленную на экспорт (туши, полутуши, упаковки и т.д.), и соответствие информации о продуктах,
 указанных в транспортных и ветеринарных документах.</p><br>

<p style="font-weight:500;">5. По окончании погрузки в вагоны или автотранспорт следующие в страны СНГ, ветеринарный специалист ПКВПТ делает отметку в ветеринарном свидетельстве, выданном территориальной Госветслужбой. 
В случаях, когда грузы следуют в другие страны, ветеринарный специалист выписывает международный ветеринарный сертификат взамен ветеринарного свидетельства.</p><br>

<p style="font-weight:500;">6. При разгрузке импортируемых грузов ветеринарный специалист ПКВПТ осуществляет ветеринарно-санитарный контроль этих грузов, состояние упаковки, соответствие информации о грузе с данными 
транспортных и ветеринарных документов, сопровождающих их, наличие этикеток на упаковке и др.</p><br>

<p style="text-indent:2em;">Остаточный срок годности продуктов должен соответствовать нормам годности, установленным действующими стандартами.</p><br>

<p style="font-weight:500;">7. Ветеринарный специалист ПКВПТ осуществляет отбор средней пробы с каждой единицы транспорта (вагон, автомобиль) с соблюдением всех требований, заполняет акт отбора проб установленной формы
 и направляет в лабораторию на исследования для национальной сертификации.</p><br>

<p style="text-indent:2em;">Информация об импортируемых продуктах предоставляется в письменном виде территориальной Госветслужбе для дальнейшего контроля при хранении и реализации в зависимости от результатов лабораторных
 анализов.</p><br>

<p style="font-weight:500;">8. В случае необходимости контроля поднадзорных грузов во время их транспортировки представитель Госветнадзора обращается с заявлением к официальным лицам станции, таможенному пункту, где грузы
 находятся на данный момент. В случаях задержки грузов составляется соответствующий акт с указанием выявленных нарушений норм доставки грузов по железной дороге, а также других действующих правил
 и мер по их устранению.</p><br>

<p style="font-weight:500;">9. Соленое и сухое кожевенное сырье, а также пушно-меховое сырье грузят в деревянной или картонной таре, а мокро-соленое сырье - в бочках или непроницаемой таре. Кожевенное и пушно-меховое сырье
 без упаковки или замороженное не допускается к перевозке по железной дороге.</p><br>

<p style="text-indent:2em;">Не допускается транспортировка мокро-соленого кожевенного сырья, а также пушно-мехового сырья вместе с другим индустриальным сырьем животного происхождения без упаковки или в мягкой таре.</p><br>

<p style="font-weight:500;">10. Шкуры и меха исследуются на сибирскую язву в соответствующих ветеринарных лабораториях. Отрицательный результат исследования вносится в ветеринарное свидетельство, а шкуры и меха клеймятся
 печатью лаборатории, проводившей исследования. Разрешается транспортировка без клеймения шкур и мехов, полученных путем убоя животных в аккредитованных производственных убойных цехах, с отметками
 в ветеринарном свидетельстве свиных шкур без инфильтратов, шкурок ягнят, кроликов, собак, кошек, зайцев, сусликов, белок, лис и других животных, которые не подлежат клеймению.</p><br>

<p style="text-indent:2em;">Сборные свиные шкуры с инфильтратами подлежат клеймению только после исследования на сибирскую язву с отрицательным результатом.</p><br>

<p style="font-weight:500;">11. Необработанные обрезки шкур подлежат транспортировке только в сухом виде в мешках, коробках или ящиках.</p><br>

<p style="text-indent:2em;">Сухая мездра перевозится в двойных мешках с внутренним слоем из целлофана, а консервированная - в известковом растворе в герметичных бочках.</p><br>

<p style="font-weight:500;">12. Шерсть, свиная щетина, волос, пух и перья перевозятся в мягкой упаковке.</p><br>

<p>Каждый тюк с шерстью, отправленный после мойки, должен иметь этикетку моечного предприятия.</p><br>

<p style="font-weight:500;">13. Сухие кишки и мочевые пузыри перевозят в ящиках или тюках, обвернутых в матерчатую ткань.</p><br>

<p>Соленое кишечное сырье перевозят в бочках без протеканий.</p><br>

<p style="font-weight:500;">14. Рога, копыта и кости подлежат транспортировке в мягкой таре без мышечной ткани, чистые и высушенные.</p><br>

<p style="text-indent:2em;">Мокрые рога, копыта и кости не допускаются к транспортировке. Транспортировка может производиться в крытых вагонах, контейнерах или автотранспортом. Разрешается перевозка необработанного сырья в
 мороженом состоянии в зимнее время при температуре воздуха не выше -50 С с разрешением получателя, с необходимыми отметками в транспортных документах.</p><br>

<p style="text-indent:2em;">В ветеринарном сертификате на перевозимое сырье указывается его происхождение (хозяйственные, боенские, сборные). Хозяйственные и боенские кости перевозят в ящиках или бочках. Перевозка хозяйственных
 и боенских костей вместе со сборными не допускается. Также запрещена перевозка сборных костей в посылках.</p><br>

<p style="text-indent:2em;">Не допускается погрузка в транспортные средства, разгрузка и хранение костей, рогов и копыт в общественных местах. Погрузка и разгрузка этих грузов осуществляется в мешках прямо из автотранспорта
 в железнодорожные вагоны и обратно.</p><br>

<p style="font-weight:500;">15. Запрещается совместная перевозка, в том числе в посылках, сырья животного происхождения с продовольственными товарами, кормами для животных, металлическими изделиями, легко воспламеняющимися,
 ароматическими и летучими веществами, хозяйственными товарами, а также совместная перевозка продовольственного сырья с техническим сырьем животного происхождения.</p><br>

<p style="text-indent:2em;">Запрещается перевозка сырья животного происхождения в контейнерах (исключение - частные и арендованные) за исключением шкурок, не подлежащих исследованию на сибирскую язву.</p><br>

<p style="font-weight:500;">16. Перевозка технического сырья животного происхождения и кормов в посылках осуществляется в соответствии с правилами перевозок грузов мелкими партиями по железной дороге с соблюдением дополнительных
 условий, указанных в этом пункте.</p><br>

<p style="font-weight:500;">16.1. Кожевенное и меховое сырье, шерсть, волос, пух, перья, кишки, мочевые пузыри, рога, копыта, кости (хозяйственные, боенские), мездра и кожевенное обрезки подаются для транспортировки
 мелкими партиями в упакованном виде.</p><br>

<p style="font-weight:500;">16.2. Шерсть, волос, перья и пух перевозят в ящиках или в мягкой упаковке, а рога и копыта только в твердой упаковке.</p><br>

<p style="font-weight:500;">16.3. Перевозка обрезков шкур и мездры мелкими партиями разрешается в бочках без течи.</p><br>

<p style="font-weight:500;">16.4. Перевозка этих грузов воздушным транспортом разрешается в каждом отдельном случае с обязательным согласованием условий перевозки между администрацией аэропорта и Главным ветеринарным управлением
 с Госветинспекцией в соответствии с обстановкой на данный момент.</p>

<p style="text-indent:2em;"></p><h6>Приложение № 5</h6><p></p>

<p></p><h4>Список грузов, подконтрольных Государственной ветеринарной службе Республики Молдова</h4><p></p>

<p style="font-weight:500;">1. Животные всех видов, включая птиц, пушных зверей, лабораторных, зоопарковых, спортивных, цирковых и домашних животных, морских зверей, пчел, рыб, других гидробионов, эмбрионы и сперму животных,
 оплодотворенную икру, инкубационные яйца, тутовый шелкопряд.</p><br>

<p style="font-weight:500;">2. Продукты животного происхождения (мясо и мясопродукты, мясо-растительные продукты, молоко и молочные продукты, рыба и рыбопродукты, яйца, яичный порошок, продукты яичного производства,
 мяса птицы и тушки пернатой дичи, мед и другие продукты пчеловодства).</p><br>

<p style="font-weight:500;">3. Сырье животного происхождения (шкуры, шерсть мытая и немытая, пушнина, пух, перо, эндокринное и кишечное сырье, кровь, рога, копыта, кости, шерстяные очесы, щетина, желчь и другие виды 
сырья животного происхождения).</p><br>

<p style="font-weight:500;">4. Корма животного происхождения (мясная, костная, мясо-костная и рыбная мука, другие продукты убоя животных, а также сухие комбинированные и живые корма для рыб).</p><br>

<p style="font-weight:500;">5. Корма растительного происхождения (фуражное зерно, комбикорма, кормовые смеси, шроты, жмыхи, корнеклубнеплоды, сено, солома, сенаж, отруби, меляса, свекловичный жом, коконы шелкопряда,
 подстилка, торф).</p><br>

<p style="font-weight:500;">6. Ветеринарные препараты, вакцины ветеринарные, живые культуры микроорганизмов, сыворотки иммунные из крови животных, среды культурные для выращивания микроорганизмов, диагностические препараты,
 коллекции и предметы коллекционирования животного происхождения, охотничьи трофеи.</p><br>

<p style="font-weight:500;">7. Готовые пищевые продукты (консервы мясные, мясо-растительные, рыбные, сыры, масло сливочное, маргарин, жиры, сухое молоко, детское питание, колбасные изделия, концентраты, мороженое, майонез)
 в ненарушенной упаковке, изготовленные в соответствии с требованиями стандартов и нормативов на предприятиях государства-экспортера.</p><br>

<p style="font-weight:500;">8. Дезинфектанты и препараты для дератизации.</p><br>

<p style="font-weight:500;">9. Все продукты, содержащие компоненты животного происхождения.</p><br>

<p style="font-weight:500;">10. Искусственная оболочка, специи для колбас, сычуги.</p><br>

<p><a href="https://www.legis.md/cautare/getResults?doc_id=104111&amp;lang=ru">Источник</a></p>

</p"></div>
`