import React, { FunctionComponent } from 'react'
import Recaptcha, { RecaptchaProps } from 'react-recaptcha'

import { CAPTCHA_SITE_KEY } from '../../store/constants'

import './style.scss'

interface ICaptchaProps extends RecaptchaProps {
  currentLanguage?: string
}

const Captcha: FunctionComponent<ICaptchaProps> = props => {
  const { currentLanguage } = props

  return <Recaptcha sitekey={CAPTCHA_SITE_KEY} hl={currentLanguage} {...props} />
}

export default Captcha
