import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Us from './us'
import Rules from './rules'
import TermOfUse from './terms-of-use'
import Policy from './policy'
import Cookies from './cookies'
import Advertising from './advertising'
import Faq from './faq'
import Prices from './prices'
import Contacts from './contacts'
import About from './component'

export default function AboutRoutes() {
  return (
    <Switch>
      <Route path="/about-us/rules" exact component={Rules} />
      <Route path="/about-us/terms-of-use" exact component={TermOfUse} />
      <Route path="/about-us/policy" exact component={Policy} />
      <Route path="/about-us/cookies" exact component={Cookies} />
      <Route path="/about-us/advertising" exact component={Advertising} />
      <Route path="/about-us/faq" exact component={Faq} />
      <Route path="/about-us/prices" exact component={Prices} />
      <Route path="/about-us/contacts" exact component={Contacts} />
      <Route path="/about-us" exact component={Us} />
    </Switch>
  )
}
