import React from 'react'
import FlexBand from 'flexband'
import fetch from '../shared/fetch'
import SingleReview from './single-review'
import { ReviewListProps, ReviewListState, IResponse } from './types'
import Pagination from '../UI/pagination'
import AddUserReview from './add-review'
import observedObject from '../../observedObject'
import { REVIEWS_ON_PAGE } from './constants'
import Spinner from '../UI/spinner'
import { scrollToElement } from '../../store/utils'
export default class CompanyReviewList extends React.Component<ReviewListProps, ReviewListState> {
  constructor(props) {
    super(props)

    this.state = {
      responsing: {
        reviewId: null,
        reviewTitle: '',
      },
      quota: {
        reviewQuota: 0,
        answerQuota: 0,
      },
      delReview: 0,
      authorOrAdminUserId: 0,
      isLoading: false,
    }
  }

  componentDidMount() {
    const { profile } = this.props
    const onFetch = profile.firstName || profile.lastName
    onFetch && this.getCompanyQuota()
  }

  getCompanyQuota = () => {
    const { reviewingCompanyId, reCheckShowAddReviewLink } = this.props
    this.setState({ isLoading: true })
    fetch(`/company/${reviewingCompanyId}/reviewQuota`).then(res => {
      if (res.ok) {
        reCheckShowAddReviewLink()
        this.setState({ quota: res.data, isLoading: false })
        //getGlobalCanUserAddReview(res.data.reviewQuota > 0) 
      }
    })
  }

  refreshData = () => {
    const { reviewingCompanyId, getGlobalUserRating, } = this.props
    this.setState({ responsing: { reviewId: null, reviewTitle: '' }, delReview: 0 }, () => {
      this.getCompanyQuota()
      fetch(`/user/getUserRating?userId=${reviewingCompanyId}`).then(res => {
        getGlobalUserRating(res.data)
      })
    })
    this.props.refresh(0)
  }

  handleResponse = (responsing: IResponse) => {
    this.setState({ responsing })
    setTimeout(() => {
      observedObject.emit('focus', 'addReview')
      scrollToElement('addReview')
    }, 500);
  }

  setDelReviewId = (delReview, authorOrAdminUserId) => {
    this.setState({ delReview, authorOrAdminUserId })
  }

  resetReviews = () => {
    scrollToElement('reviewsContainer')
    this.setState({ responsing: { reviewId: null, reviewTitle: '' } })
  }

  renderContent = () => {
    const {
      t,
      reviews,
      totalItems,
      pageIndex,
      pageSize,
      notFoundText,
      refresh,
      userId,
      reviewingCompanyId,
      profile,
      reCalcCounter
    } = this.props
    const { responsing, quota, delReview, authorOrAdminUserId } = this.state

    const notAuthorAndCanWriteReview =
      userId != reviewingCompanyId &&
      quota.reviewQuota > 0 &&
      Number(profile?.companyId) !== Number(reviewingCompanyId) &&
      profile.verifiedCompany > 0
    const showAddReviewForm = responsing.reviewTitle.length > 0 ? true : notAuthorAndCanWriteReview

    return (
      <div id="reviewsContainer">
        {reviews.length > 0 ? (
          reviews.map(review => (
            <SingleReview
              key={review.reviewId}
              review={review}
              userId={userId}
              isCompanyAdmin={profile?.isCompanyAdmin}
              isOwnerCompanyId={profile?.companyId}
              refreshData={this.refreshData}
              reCalcCounter={reCalcCounter}
              reviewingCompanyId={reviewingCompanyId}
              setResponsing={this.handleResponse}
              delReviewID={this.setDelReviewId}
              currentDeleteReviewId={delReview}
              currentAuthorOrAdminUserId={authorOrAdminUserId}
              resetDeleteReviewId={() => this.setState({ delReview: 0 })}
            />
          ))
        ) : (
          <FlexBand className="table-no-entries" justify="center" align="center">
            {notFoundText || t('not.found')}
          </FlexBand>
        )}
        {totalItems > REVIEWS_ON_PAGE && (
          <Pagination
            total={totalItems}
            pageIndex={pageIndex}
            pageSize={pageSize}
            onClick={refresh}
          />
        )}

        {showAddReviewForm && (
          <div className="panel-content-row" style={{ marginTop: 10 }}>
            <AddUserReview
              {...{ reviewingCompanyId }}
              {...{ userId }}
              refresh={this.refreshData}
              answerFor={responsing}
              {...{ quota }}
              resetResponse={() => this.resetReviews()}
              reCalcCounter={reCalcCounter}
            />
          </div>
        )}
        <div id="addCompanyReview"></div>
      </div>
    )
  }

  render() {
    const { isLoading } = this.state
    const content = isLoading ? <Spinner /> : this.renderContent()

    return content
  }
}
