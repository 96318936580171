// import Component from './component'
// import withTranslation from '../../../globals/components/withTranslation'

// export default withTranslation(Component)
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reduxForm } from 'redux-form'
import { withRouter } from 'react-router-dom'
import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'
import { IReduxStorage } from '../../../store/reducers/types'

import {
    setglobalActiveTab
  } from '../../../store/actions'

import Component from './component'

export default compose(
    withTranslation,
    withPermissions,
    connect((state: IReduxStorage) => {
      const {
        auth: { profile },
     
      } = state
  
      return {
        profile: profile.get('data').toJS(),
      }
    }, {
        setglobalActiveTab,
    })
  )(Component)
