import { connect } from 'react-redux'
import { compose } from 'recompose'
import _ from 'lodash'
import { reduxForm, formValueSelector } from 'redux-form'
import withTranslation from '../../../../globals/components/withTranslation'
import withPermissions from '../../../../globals/components/withPermissions'

import Component from './component'
const formName = 'banner-modal'
const formSelector = formValueSelector(formName)

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state) => {
      return {
        languages: state.ui.languages,
        bannerEditModalData: formSelector(state, 'bannerEditModalData'),
      }
    },
    {
   
    }
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    forceUnregisterOnUnmount: false,
  })
)(Component)