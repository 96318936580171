import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import uuid from 'uuid/v4'
import moment from 'moment'
import { IUserInfo, IStatistics } from '../../../../store/reducers/types'
import {
  setActivePopup,
  getUserStatistics,
  logout,
  setCompanyVerivicationData,
  setInitialMessengerState,
  setCompanyJoinRequests,
} from '../../../../store/actions'
import Messenger from '../../../messenger/messenger'
import DropdownBase, { DropdownName, DropdownPanel } from '../../../UI/dropdown-base'
import UserPhoto from '../../../user/user-photo'
import { getAccessStatusDate, getDifferentHoursFromUtcTime } from '../../../shared/utils'
import { hideScrollBar, addScrollBar } from "../../../../store/utils"
import fetch from '../../../shared/fetch'
import { canDo } from '../../../../store/utils'
import TooltipWrapper from '../../../UI/popover-wrapper'
import { confirmAlert } from 'react-confirm-alert'
import { toastr } from 'react-redux-toastr'

import './styles.scss'

interface IUserLoggedProps extends ComponentBaseProps, PermissionInjectProps, IUserInfo {
  enqueueSnackbar?: any
  userCompanyName: string
  isCompanyAdmin: number
  companyId: number
  activeGuid: string
  statistics: IStatistics
  companyVerifiedInfo: any
  setActivePopup: typeof setActivePopup
  autoparkSetActiveTab: any
  getUserStatistics: typeof getUserStatistics
  setInitialMessengerState: typeof setInitialMessengerState
  logout: typeof logout
  setCompanyVerivicationData: typeof setCompanyVerivicationData
  unreadMsgs: any
  profile: any
  showTutorialJoinCompanyPopup: any
  languagesIsFetching: boolean
  showRequestInCompanyInProcess: any
  requestCompanyName: string
  requestCompanyId: string
  logoutFromAllDevices: boolean
  profileGtu: string
  setCompanyJoinRequests: typeof setCompanyJoinRequests
  companyJoinRequests: any
}

interface IUserLoggedState {
  showMessenger: boolean
  showMessengerPopup: boolean
  showDropdown: boolean
  companyVerifiedInfo: {
    isValid: number
    verifiedAt: string
    active: number
  }
  unreadMsgsCounter: number
  isCheckedNewMessages: boolean
  options: {
    autoHideDuration: number
  }
  allClaims: number
  isAdmin: boolean
  showReregistrationModal: boolean
}

const STYLE_BASE = 'user-logged_'

export default class extends Component<IUserLoggedProps, IUserLoggedState> {
  state = {
    showMessenger: false,
    showMessengerPopup: false,
    showDropdown: false,
    companyVerifiedInfo: {
      isValid: -1,
      verifiedAt: '',
      active: 1,
    },
    showReregistrationModal: false,
    unreadMsgsCounter: 0,
    isCheckedNewMessages: false,
    options: {
      autoHideDuration: 2000
    },
    allClaims: 0,
    isAdmin: false,
    pageIndex: 0,
    pageSize: 10,
  }

  guid: string = `ddl${uuid()}`

  componentDidMount() {
    const { getUserStatistics, companyId, permissions, isCompanyAdmin } = this.props

    const isAdmin = canDo(permissions, 'admin')

    this.setState({ isAdmin })
    getUserStatistics('profile')
    companyId && this.getCompanyVerification(companyId)
    this.checkUnreadMessages()

    this.setState({
      isCheckedNewMessages: true
    })

    isAdmin && this.getAllClaims()
    isCompanyAdmin && this.getCompanyJoinRequests()
  }

  getCompanyJoinRequests = () => {
    const { companyId, isCompanyAdmin, setCompanyJoinRequests } = this.props

    if (isCompanyAdmin && companyId > 0) {
      fetch(`/company/employees/${companyId}/1/0/1000`).then(res => {
        if (res.ok) {
          setCompanyJoinRequests(res.employees)
        }
      })
    }
  }

  getAllClaims = () => {
    const { pageIndex, pageSize } = this.state
    const body = JSON.stringify({
      claimType: 0,
      pageIndex,
      pageSize
    })

    fetch(`/claim`, {
      method: 'POST',
      body,
    }).then(res => {
      this.setState({
        allClaims: (res.d.items || []).filter(claim => claim.approved !== 1).length
      })
    })
  }

  getCompanyVerification = companyId => {
    const { setCompanyVerivicationData } = this.props
    fetch(`/company/${companyId}/verification`)
      .then(res => {
        if (res.ok) {
          setCompanyVerivicationData(res.data)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  checkUnreadMessages = () => {
    const { setInitialMessengerState } = this.props
    fetch('/messenger/unread', {
      method: 'GET',
    }).then(response => {

      this.setState({ unreadMsgsCounter: response.data.messages.length, isCheckedNewMessages: true }, () => {
        setInitialMessengerState(response.data.messages)
      })
    })
  }

  renderNewMessageContent = () => {
    const { t } = this.props
    return <div style={{ fontSize: 14 }}>{t("new.message")}</div>
  }

  componentDidUpdate(prevProps) {
    const { companyVerifiedInfo, unreadMsgs, enqueueSnackbar, languagesIsFetching } = this.props
    const { unreadMsgsCounter, showMessenger, isCheckedNewMessages, options } = this.state

    if (companyVerifiedInfo != prevProps.companyVerifiedInfo) {
      this.setState({ companyVerifiedInfo })
      const { getUserStatistics } = this.props
      getUserStatistics('profile')
    }

    if (!languagesIsFetching && isCheckedNewMessages && unreadMsgsCounter < unreadMsgs.length && !showMessenger) {
      this.setState({
        isCheckedNewMessages: false,
        unreadMsgsCounter: unreadMsgs.length
      }, () => {
        enqueueSnackbar(this.renderNewMessageContent(), { ...options })
        this.checkUnreadMessages()
      })
    }
  }

  toggle = () => {
    const { activeGuid, setActivePopup, getUserStatistics } = this.props
    const guid = activeGuid === this.guid ? undefined : this.guid
    getUserStatistics('profile')
    setActivePopup(guid)
  }
  closePopup = () => this.props.setActivePopup(undefined)

  resendReregistraionEmail = () => {
    const { t } = this.props

    confirmAlert({
      title: t('resend.reregistererd.email'),
      message: (
        alert
      ) as any,
      buttons: [
        {
          label: "OK",
          onClick: () => {
            this.setState({ showReregistrationModal: false })
            fetch(`/auth/resend-reregistration-email`, {
              method: 'POST',
            }).then(() => {
              toastr.success(t('reregisration.email.was.sended'))
            })
          },
        },
        {
          label: t('button.close'),
          onClick: () => this.setState({ showReregistrationModal: false }),
        },
      ],
      onClickOutside: () => this.setState({ showReregistrationModal: false }),
      onKeypressEscape: () => this.setState({ showReregistrationModal: false }),
    })
  }

  userCompanyNotCreated = () => {
    const { showTutorialJoinCompanyPopup, t, profile } = this.props
    return (
      <div className="user-info-footer">
        <div
          dangerouslySetInnerHTML={{
            __html: t('no.company.description')
          }} />

        <div className="no-company-links">

          {profile.isReregistered === "N" ?
            <>
              <TooltipWrapper text={t('you.have.not.yet.re.registered')} hover >

                <Link to="#" onClick={() => this.resendReregistraionEmail()} className="link-lg">
                  {t('company.create')}
                </Link>
              </TooltipWrapper>

            </> :
            <>
              <Link to="/company/add" onClick={this.closePopup} className="link-lg">
                {t('company.create')}
              </Link>
            </>
          }

          <Link to="/company/items" onClick={() => showTutorialJoinCompanyPopup()} className="link-lg">
            {t('company.send.request')}
          </Link>
        </div>
      </div>
    )
  }

  userCompanyActive = () => {
    const { companyId, userCompanyName, isCompanyAdmin, t, profile } = this.props
    const { companyVerifiedInfo } = this.state

    return (
      <div className="user-info-footer">
        <div className={classNames(`${STYLE_BASE}company-info-wrapper`)}>
          <Link
            to={`/company/item/${companyId}`}
            onClick={this.closePopup}
            className="link-lg company-name"
          >
            {userCompanyName}
          </Link>
          <span className="meta-lg">GTC{profile.gtc} </span>
        </div>

        {isCompanyAdmin === 1 && (
          <>
            {!companyVerifiedInfo || companyVerifiedInfo?.isValid !== 0 ? (
              <>
                <Link to="/company/confirmation" onClick={this.closePopup} className="link-lg">
                  {companyVerifiedInfo?.isValid > 0
                    ? t('company.verification.success', {
                      date: moment(companyVerifiedInfo.verifiedAt).add(getDifferentHoursFromUtcTime(), 'hours').format('DD/MM/YYYY HH:mm'),
                    })
                    : t('company.pass.verification')}
                </Link>
                <Link
                  to={`/company/edit/${companyId}`}
                  onClick={this.closePopup}
                  className="link-lg"
                >
                  {t('company.edit')}
                </Link>
                <Link to="/company/employee-list" onClick={this.closePopup} className="link-lg">
                  {t('company.add.or.edit.employee')}
                </Link>
              </>
            ) : companyVerifiedInfo?.isValid === 0 && companyVerifiedInfo?.verifiedAt || companyVerifiedInfo.active === 0 ? (
              <>
                <Link to="/company/confirmation" onClick={this.closePopup} className="link-lg">
                  {t('company.pass.verification')}
                </Link>
                <Link
                  to={`/company/edit/${companyId}`}
                  onClick={this.closePopup}
                  className="link-lg"
                >
                  {t('company.edit')}
                </Link>
                <Link to="/company/employee-list" onClick={this.closePopup} className="link-lg">
                  {t('company.add.or.edit.employee')}
                </Link>
              </>
            ) : (
              <>
                <div className="link-lg verification-navigation">
                  <Link to="/company/confirmation" onClick={this.closePopup} className="link-lg">
                    {t('company.verification.in.process')}
                  </Link>
                  <span className="cancel-my-verification" onClick={() => this.cancelOrderForCompanyVerification(companyId)}>({t('cancel')})</span>
                </div>
                <Link
                  to={`/company/edit/${companyId}`}
                  onClick={this.closePopup}
                  className="link-lg"
                >
                  {t('company.edit')}
                </Link>
                <Link to="/company/employee-list" onClick={this.closePopup} className="link-lg">
                  {t('company.add.or.edit.employee')}
                </Link>
              </>
            )}
          </>
        )}
      </div>
    )
  }

  cancelOrderForCompanyVerification = (companyId) => {
    fetch(`/company/${companyId}/abort-verification`, {
      method: 'POST',
      body: JSON.stringify({
        companyId,
      }),
    }).then(response => {
      if (response.ok) {
        this.getCompanyVerification(companyId)
      }
    })
  }

  closeModal = () => {
    this.setState({ showDropdown: false })
    this.props.setActivePopup(undefined)
  }

  setActiveAutoparkTabAndClose = (activeTab) => {
    const { autoparkSetActiveTab } = this.props
    autoparkSetActiveTab(activeTab)
    this.closePopup
  }

  cancelCompanyJoinRequest = () => {
    const { companyId, requestCompanyId } = this.props
    fetch(`/company/cancel/${companyId || requestCompanyId}`).then(_ => {
      location.reload()
    })
  }

  renderUserCompanyInProcess = () => {
    const { userCompanyName, t, requestCompanyName } = this.props

    return (
      <div className="request-send">
        {t('request.in.process.part1')} <b>{userCompanyName || requestCompanyName}</b> {t('request.in.process.part2')}
        (<Link to="#" className="link-edit" onClick={this.cancelCompanyJoinRequest}>
          <i className="fa fa-times mobile-only" />
          <span>{t('company.cancel.request')}</span>
        </Link>)
      </div>
    )
  }

  toggleMessengerWindow = (state = true) => {
    this.setState({ showMessenger: state, isCheckedNewMessages: false, showDropdown: false, }, () => {
      this.checkUnreadMessages()
      state ? hideScrollBar() : addScrollBar()
    })
  }

  setLogout = () => {
    const { userId, logout } = this.props
    const body = JSON.stringify({
      userId,
    })
    window.socket.emit('SOCKET_LOGOUT_FROM_ALL_DEVICES', body);
    logout()
  }

  render() {
    const {
      t,
      firstName,
      lastName,
      logout,
      activeGuid,
      userId,
      companyId,
      isCompanyAdmin,
      statistics,
      accessStatus,
      accessStatusExpires,
      unreadMsgs,
      profile,
      showRequestInCompanyInProcess,
      logoutFromAllDevices,
      companyJoinRequests
    } = this.props

    const { showMessenger, showDropdown, allClaims, isAdmin } = this.state
    const {
      cargo,
      cargoTotal,
      oncomingTransport,
      transport,
      transportTotal,
      oncomingCargo,
      ads,
      adsTotal,
      autopark = 0,
      autoparkTotal = 0,
      companyAutoparkTotalNew = 0,
      companyAutopark = 0,
    } = statistics

    const showDropdownState = activeGuid === this.guid
    const userHaveCompany = showRequestInCompanyInProcess ? this.renderUserCompanyInProcess() : Number(profile.statusId) === 1 ? this.renderUserCompanyInProcess() : this.userCompanyActive()
    //need add scenario if user not admin but work in company
    // const userCompanyInfo = companyId !== null || showRequestInCompanyInProcess ? userHaveCompany : this.userCompanyNotCreated()
    const userCompanyInfo = companyId !== null || showRequestInCompanyInProcess ? userHaveCompany : this.userCompanyNotCreated()

    return (
      <div
        className={classNames({
          'user-container user-container-logged': true,
          [`${STYLE_BASE}wrapper`]: true,
        })}
        onMouseLeave={() => this.setState({ showDropdown: false })}
      // why we need this here? On click on messenger or any other badge, profile dropdown is oppening. This is wrong
      //        onClick={() => this.setState({ showDropdown: !showDropdownState ? !showDropdown : false })}
      >
        {logoutFromAllDevices && logout()}
        <div className="click-container" onClick={() => this.setState({ showDropdown: !showDropdownState ? !showDropdown : false })} />
        {showMessenger && (
          <div data-popup="TWE" className={`popup-overlay ${showMessenger && 'show-messenger'}`}>
            <div className="overlay-to-close" onClick={() => this.toggleMessengerWindow(false)} />
            <Messenger userId={userId} closeMessenger={() => this.toggleMessengerWindow(false)} />
          </div>
        )}

        <ul className={classNames({
          'hide-menu-small-window menu menu-links': true,
        })}
        >
          <li>
            {/* <Link to="#" className="has-bage">
              <span className="fa fa-bookmark" />
              <span className="bage bage-empty">0</span>
            </Link> */}
          </li>
          <li>
            <Link
              to="#"
              className="has-bage"
              onClick={() => this.toggleMessengerWindow()}
            >
              <span className="fa fa-comments" />
              <span className="bage">
                {' '}
                {unreadMsgs && unreadMsgs.length > 0 ? unreadMsgs.length : ''}{' '}
              </span>
            </Link>
          </li>
          {isCompanyAdmin === 1 ? (
            <li>
              <Link to="/company/employee-list" className="has-bage">
                <span className="fa fa-users" />
                { companyJoinRequests && companyJoinRequests.length > 0 && 
                  <span className="bage">
                    { companyJoinRequests.length }
                  </span>
                }
              </Link>
            </li>
          ) : null}
          
            <li>
{/*
              <Link to="#" className="has-bage">
                <span className="ico ico-wallet" />
                <span className="number">
                  <span className="col-orange">2150</span>
                  <span> geo</span>
                </span>
              </Link>
*/}
            </li>
{/*
          <li>
            <Link to="/" className="has-bage">
              <span className="fa fa-bell" />
              {<span className="bage">+7</span>}
            </Link>
          </li>
*/}
        </ul>
        <DropdownBase
          className={classNames({
            'user-block has-dropdown': true,
            [`${STYLE_BASE}dropdown-wrapper`]: true,
            'is-opened': showDropdownState,
            'show-dropdown': showDropdown,
          })}
          isStopBubble={false}
        >
          <DropdownName>
            <div className="link-dropdown user-link" onMouseEnter={this.toggle}>
              <div className="img-container">
                <UserPhoto />
              </div>
            </div>
          </DropdownName>
          <DropdownPanel>
            <div className="dropdown" onClick={() => this.setState({ showDropdown: false })}>
              <div className="user-info">
                <div className="user-info-header">
                  <div className="user-info-header-cell">
                    <div className="img-container">
                      <UserPhoto />
                    </div>
                  </div>
                  <div className="user-info-header-cell user-info-header-name">
                    <Link
                      to="/user/details"
                      onClick={this.closePopup}
                      className="name"
                    >{`${firstName} ${lastName}`}</Link>
                    <Link to="/about-us/prices" className="disabled">
                      {accessStatus ? `${accessStatus} user ` : null}
                    </Link>
                    {accessStatus !== "Free" && getAccessStatusDate(accessStatusExpires, t('to') + ' ')}
                  </div>
                  <div className="user-info-header-cell user-info-header-right">
                    <span className="meta-bl">GTU{profile.gtu}</span>
                    <Link to="/user/profile" onClick={this.closePopup}>
                      {t('edit')}
                    </Link>
                  </div>
                </div>

                <div className="user-info-body">
                  <table className="table-simple table-simple-no-b">
                    <thead>
                      <tr>
                        <th />
                        <th />
                        <th>{t('statistics.activ')}</th>
                        <th>{t('statistics.total')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={2}>
                          <Link to="/cargo/offers" onClick={this.closePopup}>
                            {t('statistics.my.cargo')}
                          </Link>
                        </td>
                        <td>{cargo}</td>
                        <td>{cargoTotal}</td>
                      </tr>
                      <tr className="col-gray">
                        <td colSpan={3}>
                          <Link to="/cargo/offers" onClick={this.closePopup}>
                            {t('statistics.oncoming.transport')}
                          </Link>
                        </td>
                        <td>{oncomingTransport}</td>
                      </tr>
                      <tr className="divider">
                        <td colSpan={4} />
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <Link to="/transport/offers" onClick={this.closePopup}>
                            {t('statistics.my.transport')}
                          </Link>
                        </td>
                        <td>{transport}</td>
                        <td>{transportTotal}</td>
                      </tr>
                      <tr className="col-gray">
                        <td colSpan={3}>
                          <Link to="/transport/offers" onClick={this.closePopup}>
                            {t('statistics.oncoming.cargo')}
                          </Link>
                        </td>
                        <td>{oncomingCargo}</td>
                      </tr>
                      <tr className="divider">
                        <td colSpan={4} />
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <Link to="/ads/my-ads" onClick={this.closePopup}>
                            {t('statistics.my.ads')}
                          </Link>
                        </td>
                        <td>{ads}</td>
                        <td>{adsTotal}</td>
                      </tr>
                      <tr className="divider">
                        <td colSpan={4} />
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <Link to="/transport/autopark" onClick={() => this.setActiveAutoparkTabAndClose('myPark')}>
                            {t('autopark')}
                          </Link>
                        </td>
                        <td>{autopark || 0}</td>
                        <td>{autoparkTotal || 0}</td>
                      </tr>
                      {profile.statusId === "2" &&
                        <tr className="divider">
                          <td colSpan={4} />
                        </tr>}
                      {profile.statusId === "2" &&
                        <tr>
                          <td colSpan={2}>
                            <Link to="/transport/autopark" onClick={() => this.setActiveAutoparkTabAndClose('companyPark')}>
                              {t('autopark.my.company')}
                            </Link>
                          </td>
                          <td>{companyAutopark || 0}</td>
                          <td>{companyAutoparkTotalNew || 0}</td>
                        </tr>}
                      {isAdmin &&
                        <tr className="divider">
                          <td colSpan={4} />
                        </tr>}
                      {isAdmin &&
                        <tr>
                          <td colSpan={3}>
                            <Link to="/admin/claims">
                              {t('company.all.claims')}
                            </Link>
                          </td>
                          <td>{allClaims}</td>
                        </tr>}

                    </tbody>
                  </table>
                </div>
                {userCompanyInfo}
                <div className="user-info-logout">
                  <Link to="#" onClick={() => this.setLogout()}>
                    {t('menu.logout')}
                  </Link>
                </div>
              </div>
            </div>
          </DropdownPanel>
        </DropdownBase>
      </div>
    )
  }
}
