// import _ from "lodash"
import React, { Component } from 'react'
// import classNames from 'classnames'

import List from './list'
import News from '../../news/list-mini'
import Ads from '../../ads'
// import Forum from '../../forum'
import BuySell from '../../buy-sell/short'

// import './styles.scss'

export default class extends Component {
  render() {
    const { isAuthenticated } = this.props
    if (!isAuthenticated) {
      // return <Redirect to='/' />
    }

    return (
      <div className="row">
        <div className="col-xl-12">
          <List />
        </div>
        <div className="col-xl-4">
          <div className="row">
            <div className="col-sm-8 col-md-6 col-xl-16">
              <News />
            </div>
            <div className="col-sm-8 col-md-6 col-xl-16">
              <Ads />
            </div>
            <div className="col-sm-16 col-md-4 col-xl-16">
              <BuySell />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
