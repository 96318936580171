import React, { Component } from 'react'
// import PropTypes from "prop-types"
import classNames from 'classnames'

import './styles.scss'

const STYLE_BASE = 'switch_'

export default class Switch extends Component {
  renderLabel = () => {
    const { label } = this.props
    return label && <div className={`${STYLE_BASE}label`}>{label}</div>
  }

  render() {
    const { className, isOn = false, onClick, isTextBeforeBullet = false } = this.props
    return (
      <div
        className={classNames({
          [`${STYLE_BASE}wrapper`]: true,
          [`${STYLE_BASE}text-bullet`]: isTextBeforeBullet,
          [className]: !!className,
        })}
        onClick={onClick}
      >
        {isTextBeforeBullet && this.renderLabel()}
        <div
          className={classNames({
            [`${STYLE_BASE}radio`]: true,
            [`${STYLE_BASE}on`]: isOn,
          })}
        >
          <div className={`${STYLE_BASE}radio-bullet`} />
        </div>
        {!isTextBeforeBullet && this.renderLabel()}
      </div>
    )
  }
}

export class SwitchField extends Component {
  onClick = () => {
    const { input } = this.props
    const { value, onChange } = input
    onChange(!value)
  }

  render() {
    const {
      className,
      input: { value },
      isTextBeforeBullet,
      label,
    } = this.props

    return (
      <Switch
        className={className}
        onClick={this.onClick}
        isOn={!!value}
        isTextBeforeBullet={isTextBeforeBullet}
        label={label}
      />
    )
  }
}
