import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reduxForm, formValueSelector, getFormValues, getFormSyncErrors } from 'redux-form'

import withTranslation from '../../../../globals/components/withTranslation'

import Component from './component'
import dict from '../../../shared/dictionaries'
import {
  addCargo,
  saveAsTemplate,
  getTemplates,
  deleteTemplate,
  getItem,
  getUserStatistics
} from '../../../../store/actions'
import { CargoItemModel } from '../../../../store/models'
import withPermissions from '../../../../globals/components/withPermissions'
import { IReduxStorage } from '../../../../store/reducers/types'
import { onCanAdd } from '../../../../store/actions/profile'

const formName = 'add-cargo'
const formSelector = formValueSelector(formName)

const initialValues = {
  ...CargoItemModel,
  filterKey: 'choose',
  price: {
    type: dict.priceTypes[0].value,
    value: undefined,
    currency: undefined,
    recurring: dict.priceRecurrings[0].value,
  },
}

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state: IReduxStorage) => {
      const profile = state.auth.profile.get('data')
      const langId = state.ui.langId
      const currencies = dict.currencies(langId).items
      const template = formSelector(state, 'template')
      const templates = state.cargo.get('templates')

      initialValues.price.currency = currencies && currencies[0].value

      let initialValuesNew
      if (template) {
        const tmpl = templates.find(x => x.id == template)
        if (tmpl) {
          initialValuesNew = { ...initialValues, ...JSON.parse(tmpl.json), template }
        }
      }

      return {
        initialValues: initialValuesNew,
        isAuthenticated: !!state.auth.accessToken,
        filterKey: formSelector(state, 'filterKey'),
        fromCoords: formSelector(state, 'from.coords'),
        toCoords: formSelector(state, 'to.coords'),
        fromCountryId: Number(formSelector(state, 'from.countryId')),
        toCountryId: Number(formSelector(state, 'to.countryId')),
        fromCityId: Number(formSelector(state, 'from.cityId')),
        toCityId: Number(formSelector(state, 'to.cityId')),
        middlePoints: formSelector(state, 'middlePoints'),
        langId,
        submitErrors: getFormSyncErrors(formName)(state),
        photo: profile.get('photo'),
        priceType: formSelector(state, 'price.type'),
        shippingType: formSelector(state, 'shippingType'),
        shipOnDate: formSelector(state, 'shipOn.date'),
        notes: formSelector(state, 'notes'),
        template: formSelector(state, 'template'),
        enteredValues: getFormValues(formName)(state),
        templates,
        currentCargo: state.cargo.get('edit').toJS(),
        canAdd: state.profile.canAdd,
      }
    },
    (dispatch: any) => ({
      addCargo: ({ item }, cb) => dispatch(addCargo({ item }, cb)),
      saveAsTemplate: ({ name, item }, searchType, cb) =>
        dispatch(saveAsTemplate({ name, item }, searchType, cb)),
      getTemplates: (searchType, cb) => dispatch(getTemplates(searchType, cb)),
      deleteTemplate: (templateId, searchType) => dispatch(deleteTemplate(templateId, searchType)),
      getItem: ({ itemId, isEdit }) => dispatch(getItem({ itemId, searchType: 'cargo', isEdit })),
      onCanAdd:  (type) => dispatch(onCanAdd(type)),
      getUserStatistics:  (type) => dispatch(getUserStatistics(type)),
    })
  ),
  reduxForm({
    form: formName,
    initialValues,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })
)(Component)
