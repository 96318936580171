import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reduxForm } from 'redux-form'
import Component from './component'
import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'
import { IReduxStorage } from '../../../store/reducers/types'
import { onCanAdd } from '../../../store/actions/profile'

const formName = 'ads-list'

const initialValues = { pageSize: 10 }

export default compose(
  withTranslation,
  withPermissions,
  connect((state: IReduxStorage) => {
    const langId = state.ui.langId
    const canAdd = state.profile.canAdd
    return {
      langId,
      isAuth: !!state.auth.accessToken,
      canAdd
    }
  },{
    onCanAdd
  }),
  reduxForm({
    form: formName,
    initialValues,
    enableReinitialize: true,
    forceUnregisterOnUnmount: false,
  })
)(Component)
