import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reduxForm } from 'redux-form'
import { withRouter } from 'react-router-dom'

import Component from './component'
import { login } from '../../../store/actions/auth'
import withTranslation from '../../../globals/components/withTranslation'

const initialValues = {
  username: undefined,
  password: undefined,
}

export default compose(
  withTranslation,
  withRouter,
  connect(null, {
    login,
  }),
  reduxForm({
    form: 'userLogin',
    initialValues,
    onSubmit: (values, dispatch, { onClose, history }) => {
      return dispatch(
        login(values, (status, payload) => {
          const { approved = null, clientId } = payload || {}
          onClose && onClose(status, payload)
          if (!approved && clientId) {
            history.push('/user/profile')
          }
        })
      )
    },
  })
)(Component)
