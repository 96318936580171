export const md2 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->
<p></p><h4>Гражданский Кодекс РМ .</h4><p></p>

<p></p><h4>Глава XII</h4><p></p>

<p></p><h4>ПЕРЕВОЗКА</h4><p></p>

<p></p><h5>Часть 1</h5><p></p>

<p></p><h5>Общие положения о перевозке</h5><p></p>

<p style="font-weight:500;">Статья 1411. Договор перевозки </p><br>

<p style="text-indent:2em;">(1) По договору перевозки одна сторона (перевозчик) обязуется перед другой стороной (пассажиром или отправителем) перевезти пассажира и багаж или вещь
 в пункт назначения взамен условленной платы, в отношении которой обязуется другая сторона.</p><br>

<p style="text-indent:2em;">(2) Бесплатная перевозка лиц или вещей, за исключением перевозки в рамках предпринимательской деятельности, осуществляемой лицом, публично предоставляющим транспортные услуги, не регулируется правилами, предусмотренными
 в настоящей главе; при этом на перевозчике лежит только обязанность осмотрительности и усердия.</p><br>

<p style="text-indent:2em;">(3) Положения настоящей главы не применяются к транспортным средствам, подпадающим под действие Кодекса торгового мореплавания Республики Молдова.</p><br>
 
<p style="text-indent:2em;">(4) Правовые положения об оказании услуг применяются к договору перевозки 
в той мере, в какой это не противоречит положениям договора перевозки.</p><br>
 
<p style="font-weight:500;">Статья1412. Обязанность заключения договора перевозки</p><br>

<p style="text-indent:2em;">Лицо, публично предлагающее осуществление перевозки людей и вещей, обязано заключить договор перевозки, за исключением случаев, когда имеются
  обоснованные причины для отказа от этого.</p><br>

<p style="font-weight:500;">Статья 1413. Последовательная и комбинированная перевозка</p><br>

<p style="text-indent:2em;">(1) Последовательной перевозкой признается перевозка, в ходе которой несколько перевозчиков сменяют друг друга, используя один
  и тот же способ перевозки (одно и то же транспортное средство).</p><br>

<p style="text-indent:2em;">(2) Комбинированной признается перевозка, в ходе которой перевозчики сменяют друг друга, используя различные способы перевозки
  (различные транспортные средства).</p><br>

<p style="font-weight:500;">Статья 1414. Смена перевозчика</p><br>

<p style="text-indent:2em;">(1) В случае передачи перевозчиком исполнения своих обязанностей в полном объеме или частично другому лицу сменившее его лицо
 считается стороной договора перевозки.</p><br>

<p style="text-indent:2em;">(2) Оплата, произведенная пассажиром или отправителем одному из перевозчиков, освобождает его от такой обязанности в
 отношении остальных перевозчиков.</p><br>

<p style="font-weight:500;">Статья 1415. Срок перевозки пассажира или вещи</p><br>

<p style="text-indent:2em;">(1) Перевозчик обязан осуществить перевозку пассажира и багажа или вещи в установленные законом или договором сроки,
 а в случае, когда такие сроки не установлены, – в разумный срок.</p><br>

<p>(2) Перевозка должна производиться по наиболее короткому и оптимальному пути.</p><br>

<p style="font-weight:500;">Статья 1416. Плата за перевозку</p><br>

<p style="text-indent:2em;">(1) За перевозку пассажира и багажа или вещи вносится провозная плата, установленная по соглашению сторон, 
если законом не предусмотрено иное.</p><br>

<p style="text-indent:2em;">(2) Плата за перевозку вносится до перевозки пассажира и багажа или вещи, если законом или договором не 
 предусмотрено иное.</p><br>

<p>(3) Перевозчик имеет право удерживать багаж и вещь до внесения провозной платы.</p>

<p></p><h5>Часть 2</h5><p></p>

<p></p><h5>Перевозка пассажиров</h5><p></p>

<p style="font-weight:500;">Статья 1417. Билет (проездной документ)</p><br>

<p style="text-indent:2em;">(1) Договор перевозки пассажира удостоверяется (документируется) выдачей билета (проездного документа) или иного 
 документа, дающего право на проезд в транспорте. Билет может быть выдан в электронном виде.</p><br>

<p style="text-indent:2em;">(2) Билет (проездной документ) может быть передан другому лицу, если прямо не оговорен запрет на его передачу. 
 Возможность передачи прекращается не позднее начала поездки.</p><br>

<p style="text-indent:2em;">(3) Любое условие, отступающее от положений настоящей части в составе главы в ущерб пассажиру-потребителю, 
  является ничтожным.</p><br>

<p style="font-weight:500;">Статья 1418. Договор фрахтования (чартер)</p><br>

<p style="text-indent:2em;">(1) Договор фрахтования (чартер) – это договор перевозки пассажиров, по которому перевозчик обязуется перевозить 
  фрахтователя или пассажира, используя транспортное средство, которое перевозчик предоставляет фрахтователю 
  или пассажиру в полном объеме, вместе с оператором, в целях перевозки, а фрахтователь обязуется уплатить 
  согласованную плату. Договор фрахтования может быть заключен на определенный или неопределенный срок.</p><br>

<p style="text-indent:2em;">(2) В соответствии с договором фрахтования перевозчик требует от оператора транспортного средства выполнения 
 указаний фрахтователя или пассажира в предусмотренном договором объеме. Перевозчик отвечает за выполнение 
 указаний.</p><br>

<p style="text-indent:2em;">(3) Правовые положения об имущественном найме не применяются к договору фрахтования.</p><br>

<p style="font-weight:500;">Статья 1419. Объем обязательства</p><br>

<p>(1) Перевозка пассажира включает операции по посадке, пересадке, перевозке и высадке.</p><br>

<p>(2) Перевозчик обязан осуществить безопасную перевозку пассажира в пункт назначения.</p><br>

<p style="font-weight:500;">Статья 1420. Ответственность перевозчика</p><br>

<p style="text-indent:2em;">(1) Перевозчик обязан возместить ущерб, причиненный в результате смерти пассажира, нанесения ему телесного повреждения, 
уничтожения или повреждения его вещей, если это не противоречит положениям статьи 1421, или вследствие нарушения срока 
перевозки, за исключением случая, когда такой ущерб является результатом препятствия, находящегося вне контроля перевозчика,
 и если от перевозчика нельзя было разумно ожидать, что он избежит или преодолеет препятствие или его последствия, либо когда
 ущерб является следствием состояния здоровья пассажира или его деяния. Перевозчик обязан также возместить ущерб, причиненный
 в связи с состоянием его здоровья или здоровья его представителей либо в связи с состоянием или функционированием транспортного 
 средства.</p><br>

<p style="text-indent:2em;">(2) Ответственность перевозчика не может быть исключена или ограничена договором. Любое соглашение о противном является
 ничтожным.</p><br>

<p style="text-indent:2em;">(3) Ограничения размера возмещения убытков и фиксированный размер возмещения убытков при определенных категориях нарушений,
  в том числе внедоговорного характера, в транспорте общего пользования утверждаются Правительством, если законом не установлены
  иные ограничения.</p><br>

<p style="text-indent:2em;">(4) Перевозчик несет ответственность как за собственные действия или бездействие за действия и бездействие своих представителей
  или любых других лиц, к услугам которых он прибегает для осуществления перевозки, если такие представители или такие лица 
  действуют при осуществлении своих функций.</p><br>

<p style="font-weight:500;">Статья 1421. Ответственность перевозчика за багаж</p><br>

<p style="text-indent:2em;">(1) Перевозчик отвечает за утрату или повреждение багажа, доверенного ему пассажиром, за исключением случая, когда он докажет,
 что это произошло вследствие препятствия, находящегося вне его контроля, и если от него нельзя было разумно ожидать, что он
 избежит или преодолеет препятствие или его последствия, либо когда он докажет, что это произошло вследствие недостатков вещи
 или по вине пассажира.</p><br>

<p style="text-indent:2em;">(2) Перевозчик не отвечает за утрату документов, денег или других ценных вещей, за исключением случая, когда ему сообщили о
 характере или стоимости вещи и он согласился осуществить ее перевозку. Перевозчик тем более не отвечает за утрату ручной 
 клади, остающейся под присмотром пассажира, за исключением случая, когда последний докажет вину перевозчика.</p><br>

<p style="font-weight:500;">Статья 1422. Претензии относительно багажа</p><br>

<p style="text-indent:2em;">(1) В случае очевидного повреждения ручной клади пассажир должен уведомить перевозчика о повреждении при выходе из 
  транспортного средства. В случае очевидного повреждения прочего багажа уведомление о повреждении должно быть сделано 
  при получении багажа.</p><br>

<p style="text-indent:2em;">(2) Если багаж поврежден, но повреждение не является очевидным, уведомление о повреждении должно быть сделано в текстовой 
  форме в семидневный срок после выхода из транспортного средства или получения багажа, если таковой получен позже.</p><br>

<p style="text-indent:2em;">(3) Если ручная кладь утеряна, пассажир должен уведомить перевозчика об утрате при выходе из транспортного средства. В 
 случае утери прочего багажа пассажир должен уведомить перевозчика об утрате в семидневный срок с даты, когда багаж 
 должен был быть получен.</p><br>

<p style="text-indent:2em;">(4) В случае несоблюдения пассажиром положений частей (1)-(3) предполагается, что он получил весь багаж в хорошем 
 состоянии.</p><br>

<p style="text-indent:2em;">(5) Пассажир лишается права требовать в соответствии с законом и договором возмещения ущерба за утрату или повреждение, 
 указанные в настоящей статье, если он не уведомит перевозчика об утрате или, по обстоятельствам, повреждении в 
 месячный срок с момента прибытия в место назначения.</p><br>

<p style="font-weight:500;">Статья 1423. Ответственность перевозчика в случае последовательной или комбинированной перевозки пассажиров</p><br>

<p style="text-indent:2em;">В случае последовательной или комбинированной перевозки пассажиров лицо, осуществляющее перевозку, в процессе 
которой причинены убытки, несет ответственность за них, за исключением случая, когда один из перевозчиков 
специальной оговоркой принимает на себя ответственность за весь путь.</p><br>

<p style="font-weight:500;">Статья 1424. Солидарная ответственность перевозчика и фактического перевозчика</p><br>

<p style="text-indent:2em;">(1) Если перевозка осуществляется полностью или частично иным, чем перевозчик, лицом (фактическим перевозчиком), 
  фактический перевозчик несет ту же ответственность, что и перевозчик, за ущерб, причиненный в результате смерти 
  пассажира, нанесения ему телесного повреждения, уничтожения или повреждения его багажа, нарушения срока перевозки 
  или иного неисполнения обязательств, вытекающих из договора перевозки пассажиров, в ходе выполнения перевозки 
  фактическим перевозчиком. Соглашение между перевозчиком и фактическим перевозчиком о расширении ответственности 
  перевозчика по сравнению с предусмотренной законом применяется к фактическому перевозчику, только если он принял 
  его в текстовой форме.</p><br>

<p style="text-indent:2em;">(2) Фактический перевозчик может выдвигать возражения, которые вправе выдвигать перевозчик на основании договора 
  перевозки пассажиров.</p><br>

<p style="text-indent:2em;">(3) Перевозчик и фактический перевозчик несут солидарную ответственность перед пассажиром.</p><br>

<p style="font-weight:500;">Статья 1425. Исключения из ограничения ответственности перевозчика</p><br>

<p style="text-indent:2em;">(1) Перевозчик не может ссылаться на правовые положения, исключающие или ограничивающие его ответственность либо 
переносящие бремя доказывания на другую сторону, если ущерб причинен умышленно или по грубой небрежности.</p><br>

<p style="text-indent:2em;">(2) Положения части (1) применяются соответствующим образом в случае, когда деяния совершены умышленно или по 
  грубой небрежности представителями перевозчика или любыми другими лицами, к услугам которых он прибегает для 
  осуществления перевозки, если такие представители или такие лица действуют при осуществлении своих функций. В 
  этом случае представители или другие лица не вправе ссылаться относительно своей ответственности на правовые 
  положения, исключающие или ограничивающие ответственность либо переносящие бремя доказывания на другую сторону.</p><br>

<p style="font-weight:500;">Статья 1426. Обязанности пассажиров</p><br>

<p style="text-indent:2em;">(1) В ходе осуществления перевозки пассажиры должны вести себя таким образом, чтобы не создавать угрозу безопасности 
транспортного средства и не нарушать условия перевозки. Пассажиры обязаны следовать инструкциям перевозчика, оператора 
транспортного средства или иного компетентного лица.</p><br>

<p style="text-indent:2em;">(2) Пассажир должен представить свой багаж для перевозки таким образом, чтобы это не способствовало утрате или
 повреждению багажа и не причинило ущерб перевозчику. Если багаж содержит опасные предметы, пассажир обязан 
 уведомить перевозчика о наличии опасного предмета и об общем характере опасности.</p><br>

<p style="font-weight:500;">Статья 1427. Ответственность фрахтователя</p><br>

<p style="text-indent:2em;">(1) Если пассажир или все пассажиры либо их багаж по какой-либо причине не готовы к перевозке в согласованное время, 
 перевозчик может заявить о расторжении договора фрахтования или же начать рейс. Если перевозчику причинен ущерб по 
 причине того, что пассажир или все пассажиры либо их багаж по какой-либо причине не готовы к перевозке в согласованное 
 время, фрахтователь обязан возместить перевозчику его убытки.</p><br>

<p style="text-indent:2em;">(2) Фрахтователь обязан возместить перевозчику ущерб, причиненный ему отсутствием у пассажира необходимых документов, 
за которое несет ответственность фрахтователь.</p><br>

<p style="text-indent:2em;">(3) В случае перевозки по договору фрахтования фрахтователь должен возместить перевозчику ущерб, причиненный в ходе 
  исполнения договорных обязательств исполнением перевозчиком указаний фрахтователя или пассажира, если оператор 
  транспортного средства разумно следовал указаниям.</p><br>

<p style="font-weight:500;">Статья 1428. Ответственность пассажира</p><br>

<p style="text-indent:2em;">Пассажир отвечает за убытки, причиненные перевозчику своим поведением либо характером или состоянием перевозимого с 
собой багажа, за исключением случая, когда убытки возникли вопреки надлежащему поведению пассажира. Пассажир не может 
ссылаться на недостатки или характер своего багажа в целях освобождения от ответственности.</p><br>

<p style="font-weight:500;">Статья 1429. Расторжение договора перевозки пассажиров</p><br>

<p style="text-indent:2em;">(1) Пассажир может заявить о расторжении договора перевозки пассажиров в любое время, если это не влечет задержки 
 отправления. Он обязан возместить перевозчику причиненные расторжением убытки.</p><br>

<p style="text-indent:2em;">(2) Пассажир может заявить о расторжении договора перевозки пассажиров в случае, когда выясняются обстоятельства в 
 сфере деятельности перевозчика, о которых пассажир не мог знать и которые, если бы он знал о них, послужили бы для 
 него обоснованной причиной не заключать договор перевозки пассажиров.</p><br>
 
<p style="text-indent:2em;">(3) Пассажир может заявить о расторжении договора перевозки пассажиров также в случае, когда предвидится, что будут
 задержки в отношении согласованного времени и срока. В таком случае обязанность возмещения убытков не возникает.</p><br>

<p style="font-weight:500;">Статья 1430. Положения, применимые к перевозке багажа</p><br>

<p style="text-indent:2em;">В дополнение к положениям настоящей части в составе главы к перевозке багажа применяются положения статей 1438, 1444,
 1446, 1447, 1452 и 1455, если стороны не согласились об ином.</p>

<p></p><h5>Часть 3</h5><p></p>

<p></p><h5>Перевозка вещей</h5><p></p>

<p>1. Заключение и исполнение договора</p><br>

<p style="font-weight:500;">Статья 1431. Перевозка несколькими транспортными средствами</p><br>

<p style="text-indent:2em;">Если транспортное средство, в которое или на которое погружена транспортируемая вещь, прошло часть пути морем, по железной
 дороге, по реке или каналу либо воздушным путем, а вещь при этом не выгружена (не перегружена), положения настоящей части 
 применяются к перевозке в целом.</p><br>

<p style="font-weight:500;">Статья 1432. Форма договора перевозки</p><br>

<p style="text-indent:2em;">(1) Договор перевозки оформляется в виде накладной (коносамента или иного равнозначного документа).</p><br>

<p style="text-indent:2em;">(2) Отсутствие, утрата или повреждение накладной не влияет на действительность договора перевозки.</p><br>

<p style="font-weight:500;">Статья 1433. Составление накладной</p><br>

<p>(1) Накладная составляется отправителем, если стороны не согласились об ином.</p><br>

<p style="text-indent:2em;">(2) Накладная составляется не менее чем в трех экземплярах, которые подписываются отправителем и перевозчиком, причем эти
 подписи могут быть отпечатаны типографским способом или заменены штемпелями отправителя и перевозчика. Первый экземпляр 
 хранится у отправителя, второй сопровождает вещь, а третий вручается перевозчику.</p><br>

<p style="text-indent:2em;">(3) Если вещи должны быть погружены на несколько транспортных средств или же когда речь идет о различного рода вещах или о
 различных партиях вещей, как перевозчик, так и отправитель может требовать составления такого количества накладных, которое
 соответствует количеству используемых транспортных средств либо количеству подлежащих перевозке разных вещей или партий
 вещей.</p><br>

<p style="font-weight:500;">Статья 1434. Содержание накладной</p>

<p style="text-indent:2em;"></p><h6>(1) Накладная должна содержать следующие данные:</h6><p></p>

<p>a) место и дата ее составления;</p><br>

<p>b) имя и адрес отправителя;</p><br>

<p>c) имя и адрес перевозчика;</p><br>

<p>d) место и дата принятия вещи и место ее доставки;</p><br>

<p>е) имя и адрес получателя;</p><br>

<p style="text-indent:2em;">f) принятое обозначение характера вещи и тип ее упаковки и, в случае перевозки опасных вещей, их обычно признанное
 обозначение;</p><br>

<p>g) число грузовых мест, их особая маркировка и номера;</p><br>

<p>h) вес брутто или выраженное в других единицах измерения количество вещей;</p><br>
  
<p style="text-indent:2em;">i) связанные с перевозкой расходы (плата за перевозку, дополнительные расходы, таможенные пошлины и прочие расходы
  с момента заключения договора и до сдачи вещи);</p><br>

<p style="text-indent:2em;">j) инструкции, необходимые для выполнения таможенных формальностей,
 и другие, по обстоятельствам.</p>

<p style="text-indent:2em;"></p><h6>(2) В случае необходимости накладная должна содержать также следующие указания:</h6><p></p>
 
<p>a) запрещение перегрузки вещи;</p><br>

<p>b) расходы, которые отправитель принимает на себя;</p><br>

<p>c) сумма наложенного на вещь платежа, подлежащего возмещению при сдаче вещи;</p><br>
 
<p>d) заявленная стоимость вещи и сумма дополнительной ценности ее при доставке;</p><br>
  
<p>e) инструкции отправителя перевозчику относительно страхования вещи;</p><br>

<p>f) согласованный срок осуществления перевозки;</p><br>

<p>g) перечень документов, переданных перевозчику.</p><br>

<p style="text-indent:2em;">(3) Стороны могут внести в накладную любое другое указание, которое они посчитают необходимым.</p><br>

<p style="font-weight:500;">Статья 1435. Неправильность сведений,содержащихся в накладной</p>

<p style="text-indent:2em;"></p><h6>(1) Отправитель отвечает за все издержки перевозки и убытки, причиненные перевозчику вследствие неточности или
 недостаточности:</h6><p></p>

<p>a) указаний, приведенных в пунктах b), d)-h) и j) части (1) статьи 1434;</p><br>

<p>b) указаний, приведенных в части (2) статьи 1434;</p><br>

<p style="text-indent:2em;">c) любых других указаний или инструкций, данных им для составления накладной или для включения в нее.</p><br>

<p style="text-indent:2em;">(2) Если по просьбе отправителя перевозчик вносит в накладную указания, приведенные в части (1), признается до тех пор,
 пока не будет доказано обратное, что он действовал от имени отправителя.</p><br>

<p style="font-weight:500;">Статья 1436. Обязанности перевозчика при принятии вещи</p>

<p style="text-indent:2em;"></p><h6>(1) При принятии вещи перевозчик обязан проверить:</h6><p></p>

<p style="text-indent:2em;">a) точность записей, сделанных в накладной относительно числа грузовых мест, а также их маркировки и номеров;</p><br>

<p>b) внешнее состояние вещи и ее упаковки;</p><br>

<p>c) способ погрузки и укладки вещи в транспортном средстве.</p><br>

<p style="text-indent:2em;">(2) Если перевозчик не располагает надлежащими средствами для проверки данных, указанных в пункте a) части (1), он должен
 вписать в накладную свои обоснованные оговорки. Он должен также обосновать оговорки относительно внешнего состояния вещи
 или ее упаковки. Оговорки не обязывают отправителя, если он прямо не указал в накладной, что он их принимает.</p><br>

<p style="text-indent:2em;">(3) Отправитель может требовать проверки перевозчиком веса брутто или количества вещи, выраженного в других единицах 
измерения, а также содержимого грузовых мест. Перевозчик имеет право на возмещение расходов по проверке. Результаты 
проверки вносятся в накладную.</p><br>

<p style="font-weight:500;">Статья 1437. Ответственность отправителя за упаковку</p><br>

<p style="text-indent:2em;">Отправитель несет ответственность перед перевозчиком за ущерб, причиненный ненадлежащей упаковкой вещи лицам, оборудованию
 или транспортному оснащению либо другим вещам, а также за все расходы, понесенные в связи с ненадлежащей упаковкой, за
 исключением случая, когда при очевидном или известном перевозчику при принятии вещи недостатке он не сделал относительно
 этого оговорок.</p><br>

<p style="font-weight:500;">Статья 1438. Приложения к накладной</p><br>

<p style="text-indent:2em;">(1) Отправитель должен приложить к накладной документы, необходимые для осуществления таможенных процедур (при необходимости)
 или других подобных операций, предшествующих доставке, либо предоставить эти документы перевозчику, сопроводив их 
 соответствующими указаниями.</p><br>

<p style="text-indent:2em;">(2) Перевозчик не обязан проверять, являются ли эти документы или указания надлежащими и достаточными. Отправитель отвечает перед 
перевозчиком за убытки, причиненные ошибками либо неполнотой или недостоверностью документов или указаний, если в этом нет вины
 перевозчика.</p><br>

<p style="text-indent:2em;">(3) Перевозчик отвечает согласно правовым положениям о поручении за утрату или ненадлежащее использование документов, 
  приложенных к накладной или переданных ему. При этом размер уплачиваемого им возмещения не должен превышать размер возмещения
  за утрату вещи.</p><br>

<p style="font-weight:500;">Статья 1439. Последствия подписания накладной</p><br>

<p style="text-indent:2em;">(1) Накладная является, до тех пор, пока не будет доказано обратное, доказательством заключения договора перевозки и согласия
  с его содержанием, а также принятия вещи перевозчиком.</p><br>

<p style="text-indent:2em;">(2) Если накладная не содержит обоснованных оговорок перевозчика, до тех пор, пока не будет доказано обратное, предполагается,
 что вещь и ее упаковка при приемке были в надлежащем состоянии, а общий объем вещи, число грузовых мест, а также их маркировка
 и номера соответствуют сведениям, содержащимся в накладной.</p><br>

<p style="font-weight:500;">Статья 1440. Право отправителя на расторжение договора перевозки</p><br>

<p>(1) Отправитель вправе расторгнуть договор перевозки в любое время.</p><br>

<p style="text-indent:2em;">(2) Если отправитель заявляет о расторжении договора перевозки по иным основаниям, нежели неисполнение обязательства перевозчиком,
 последний может потребовать внесения платы за перевозку и уплаты других расходов, за вычетом экономии, которую перевозчик получил
 или должен получить в результате расторжения договора.</p><br>

<p style="text-indent:2em;">(3) Вместо требования, основанного на положениях части (2), перевозчик может потребовать уплаты одной трети платы за перевозку.</p><br>

<p style="text-indent:2em;">(4) Если вещь уже была загружена до расторжения договора, перевозчик может обратиться к отправителю за инструкциями о последующих
 операциях с вещью или может потребовать выгрузки вещи без неоправданного промедления. Если перевозчик не получает инструкций в
 разумный срок, он может принять меры, предусмотренные статьей 1446, за счет отправителя.</p><br>

<p style="text-indent:2em;">(5) Если в случае, предусмотренном частью (4), отправитель дает инструкции о выгрузке вещи, перевозчик обязан выгрузить вещь лишь
 при условии, что это возможно без помех для текущей деятельности перевозчика и без ущерба для других отправителей или 
 получателей.</p><br>

<p style="text-indent:2em;">(6) Если отправитель заявляет о расторжении в связи с неисполнением обязательства перевозчиком, последний обязан без неоправданного
 промедления выгрузить вещь за свой счет.</p><br>

<p style="font-weight:500;">Статья 1441. Право отправителя распоряжаться вещью</p><br>

<p style="text-indent:2em;">(1) Отправитель вправе распоряжаться вещью. Он может, в частности, требовать от перевозчика не перевозить вещь далее, изменить
  пункт назначения вещи или доставить вещь не тому получателю, который указан в накладной.</p><br>

<p style="text-indent:2em;">(2) Право, предусмотренное частью (1) настоящей статьи, прекращается с момента вручения второго экземпляра накладной получателю
  либо с момента осуществления им права, предусмотренного частью (2) статьи 1443. С этого момента перевозчик должен исполнять 
  указания получателя.</p><br>

<p style="text-indent:2em;">(3) Право распоряжения вещью возникает для получателя с момента составления накладной в случае, когда отправитель сделал в накладной
 соответствующую пометку.</p><br>

<p style="text-indent:2em;">(4) Если получатель при осуществлении своего права распоряжения распорядился о доставке вещи третьему лицу, последнее не вправе 
 назначить в свою очередь другого получателя.</p>

<p style="text-indent:2em;"></p><h6>(5) Право распоряжения осуществляется согласно следующим правилам:</h6><p></p>

<p style="text-indent:2em;">a) отправитель или указанный в части (3) получатель, намеревающийся осуществить свое право распоряжения, должен представить первый 
  экземпляр накладной, в котором записаны новые указания перевозчику, и возместить перевозчику убытки, в том числе расходы, связанные 
  с выполнением этих указаний;</p><br>
  
<p style="text-indent:2em;">b) выполнение указаний должно быть возможным в момент, когда они дойдут до лица, которое должно их исполнить, не должно препятствовать
 осуществлению текущей деятельности перевозчика и наносить ущерб другим отправителям или получателям;</p><br>

<p>c) указания не должны вести к дроблению перевозки.</p><br>

<p style="text-indent:2em;">(6) Если перевозчик на основании пункта b) части (5) не может исполнить полученные указания, он должен незамедлительно сообщить об
  этом лицу, давшему их.</p><br>

<p style="text-indent:2em;">(7) Перевозчик, не исполнивший указания, переданные ему с соблюдением положений настоящей статьи, либо исполнивший такие указания, 
не потребовав представления первого экземпляра накладной, отвечает перед правомочным лицом за причиненные в связи с этим убытки.</p><br>

<p style="font-weight:500;">Статья 1442. Оборотная накладная</p><br>

<p style="text-indent:2em;">(1) Накладная не является оборотной, если она не содержит прямой отметки об этом или если законом не предусмотрено иное.</p><br>

<p style="text-indent:2em;">(2) В случае если она является оборотной, накладная по требованию передается посредством индоссамента, а накладная на предъявителя
 – посредством передачи.</p><br>

<p style="text-indent:2em;">(3) Когда накладная выписана по требованию или на предъявителя, право собственности на вещь передается в результате передачи этой
 накладной.</p><br>

<p style="text-indent:2em;">(4) Форма и последствия индоссаментов, отмена и замена накладной регулируются положениями о переводном и простом векселях.</p><br>

<p style="text-indent:2em;">(5) Последний индоссант непрерывного ряда индоссаментов, являющийся держателем документа, признается собственником. Должник, 
  который выполняет обязательство, вытекающее из документа, удостоверяющего право собственности, освобождается только в случае
  , если с его стороны не было злоупотребления или грубой небрежности.</p><br>

<p style="font-weight:500;">Статья 1443. Права получателя при приемке вещи</p><br>

<p style="text-indent:2em;">(1) С момента прибытия вещи в пункт назначения получатель вправе требовать от перевозчика передачи ему, взамен расписки о получении,
 второго экземпляра накладной и сдачи вещи.</p><br>

<p style="text-indent:2em;">(2) Если установлена утрата вещи либо если вещь не прибыла в течение срока, предусмотренного статьей 1451, получатель может от своего
 имени осуществить против перевозчика вытекающие из договора перевозки права.</p><br>

<p style="text-indent:2em;">(3) Получатель, осуществляющий права, которыми он обладает на основе частей (1) и (2), должен уплатить общую сумму платежей, вытекающих
  из накладной. При наличии разногласий по этому вопросу перевозчик обязан осуществить выгрузку лишь в случае предоставления гарантий 
  получателем.</p><br>

<p style="font-weight:500;">Статья 1444. Невозможность исполнения договора</p><br>

<p style="text-indent:2em;">(1) Если по какой-либо причине до того, как вещь прибыла в пункт назначения, исполнение договора на установленных в накладной условиях
 невозможно либо становится невозможным, перевозчик должен потребовать указаний от лица, правомочного согласно статье 1441 распоряжаться
 вещью.</p><br>

<p style="text-indent:2em;">(2) Если обстоятельства позволяют выполнить перевозку на условиях, отличных от условий, указанных в накладной, и если перевозчик не 
  может получить в разумный срок указания от лица, правомочного согласно статье 1441, перевозчик должен принять меры, которые он считает
  наилучшими в интересах лица, правомочного распоряжаться вещью.</p><br>

<p style="font-weight:500;">Статья 1445. Обстоятельства, препятствующие сдаче вещи</p><br>

<p style="text-indent:2em;">(1) В случае если после прибытия вещи в место назначения возникают препятствия к ее выдаче, перевозчик обращается за инструкциями к 
  отправителю. Если получатель отказывается принять вещь, перевозчик вправе распоряжаться ею без представления первого экземпляра 
  накладной.</p><br>

<p style="text-indent:2em;">(2) Получатель может, хотя бы и в случае отказа принять вещь, требовать ее доставки до тех пор, пока перевозчик не получит других указаний
 от отправителя или не распорядится вещью.</p><br>

<p style="text-indent:2em;">(3) Если препятствие по доставке возникает после того, как получателем даны на основе его прерогатив, предусмотренных частью (3) статьи 1441,
 указания доставить вещь третьему лицу, при применении положений частей (1) и (2) настоящей статьи получатель переходит на место отправителя, 
 а третье лицо – на место получателя.</p><br>

<p style="font-weight:500;">Статья 1446. Право перевозчика на возмещение расходов, связанных с исполнением указаний отправителя</p><br>

<p style="text-indent:2em;">(1) Перевозчик имеет право на возмещение ему расходов, связанных с истребованием и исполнением указаний, если на основании договора или закона
 он не обязан нести эти расходы.</p><br>

<p style="text-indent:2em;">(2) В случаях, указанных в части (1) статьи 1444 и статье 1445, перевозчик может незамедлительно выгрузить вещь за счет лица, правомочного
  распоряжаться ею. После выгрузки перевозка считается завершенной. Перевозчик должен сохранить вещь для лица, правомочного распоряжаться ею.
  При этом он может поручить вещь третьему лицу, отвечая в этом случае лишь за осмотрительность в выборе третьего лица. Вещь остается обремененной 
  требованиями, вытекающими из накладной, а также остальными расходами.</p><br>

<p style="text-indent:2em;">(3) Перевозчик может продать вещь, не ожидая указаний от лица, правомочного давать их, если речь идет о скоропортящейся продукции либо если 
такая мера оправдана состоянием вещи, а также если расходы по хранению несоразмерны в сравнении со стоимостью вещи. Он может продать вещь и в 
других случаях, если в течение соответствующего срока не получил от правомочного лица иных указаний, исполнения которых можно потребовать на 
основе принципа справедливости.</p><br>

<p style="text-indent:2em;">(4) В случае продажи вещи в соответствии с положениями настоящей статьи вырученная сумма предоставляется в распоряжение лица, правомочного 
распоряжаться вещью, после вычета всех расходов, которыми была обременена вещь. Если эти расходы превышают обычные цены, перевозчик может 
требовать выплаты ему разницы.</p><br>

<p style="text-indent:2em;">(5) Порядок продажи определяется в соответствии с законом или обычаями места, в котором находится вещь.</p><br>

<p style="font-weight:500;">Статья 1447. Право удержания в отношении вещи</p><br>

<p style="text-indent:2em;">Перевозчик обладает правом удержания в отношении вещи в пределах времени, в течение которого он вправе распоряжаться вещью, по всем 
 расходам, вытекающим из договора перевозки.</p><br>

<p>2. Ответственность перевозчика</p><br>

<p style="font-weight:500;">Статья 1448. Основания ответственности перевозчика</p><br>

<p style="text-indent:2em;">(1) Перевозчик отвечает за гибель, полную или частичную утрату или повреждение вещи в той мере, в какой гибель, утрата или повреждение 
  имели место с момента принятия и до момента доставки, а также за просрочку в доставке.</p><br>
  
<p style="text-indent:2em;">(2) Перевозчик несет ответственность как за собственные действия или бездействие за действия и бездействие своих представителей или 
  любых других лиц, к услугам которых он прибегает для осуществления перевозки, если такие представители или такие лица действуют при 
  осуществлении своих функций.</p><br>

<p style="font-weight:500;">Статья 1449. Освобождение от ответственности</p>

<p style="text-indent:2em;"></p><h6>(1) Перевозчик освобождается от ответственности за гибель, утрату или повреждение вещи либо за просрочку в доставке, если:</h6><p></p>

<p>a) это произошло по вине лица, правомочного распоряжаться вещью;</p><br>

<p style="text-indent:2em;">b) это обусловлено указаниями лица, правомочного распоряжаться вещью, в случае, когда они не вызваны виной перевозчика;</p><br>

<p>c) это вызвано собственным недостатком вещи;</p><br>

<p style="text-indent:2em;">d) это обусловлено обстоятельствами, избегнуть которые перевозчик не мог и последствия которых он не мог предотвратить.</p><br>

<p style="text-indent:2em;">(2) Перевозчик не может освобождаться от ответственности по причине неисправности транспортного средства, которым он пользуется для 
  осуществления перевозки, или вины его лизингодателя или представителей последнего.</p>
  
<p style="text-indent:2em;"></p><h6>(3) С учетом положений частей (2)-(5) статьи 1450 перевозчик освобождается от ответственности, если гибель, утрата или 
повреждение вещи являются следствием особых рисков, присущих одному или нескольким из следующих обстоятельств:</h6><p></p>

<p style="text-indent:2em;">a) использование открытых, бестентовых транспортных средств, если такое использование было прямо согласовано 
и указано в накладной;</p><br>

<p style="text-indent:2em;">b) отсутствие или повреждение упаковки вещей, по своей природе подверженных утрате или повреждению без упаковки
 или при ненадлежащей упаковке;</p><br>
 
<p style="text-indent:2em;">c) перемещение, погрузка, размещение или выгрузка вещей производились отправителем, получателем или третьим лицом,
 действующим от имени отправителя или получателя;</p><br>

<p style="text-indent:2em;">d) естественные свойства некоторых вещей, вследствие которых они подвержены полной или частичной гибели либо повреждению,
 в частности вследствие боя, коррозии, самопроизвольного гниения, усушки, утечки, нормальной усадки либо воздействия
 паразитов или грызунов;</p><br>

<p style="text-indent:2em;">e) недостаточность или неудовлетворительность маркировки или нумерации грузовых мест;</p><br>

<p>f) перевозка живых животных.</p><br>

<p style="text-indent:2em;">(4) Если в соответствии с настоящей статьей ущерб причинен частично в силу обстоятельств, за которые перевозчик не
 отвечает, и частично по причине обстоятельств, которые могут вменяться ему в вину, перевозчик отвечает лишь в той 
 мере, в какой ущерб причинен обстоятельствами второго вида.</p><br>

<p style="font-weight:500;">Статья 1450. Бремя доказывания</p><br>

<p style="text-indent:2em;">(1) Бремя доказывания того, что гибель, утрата, повреждение вещи или просрочка в доставке вызваны одним из обстоятельств,
  указанных в части (1) статьи 1449, возлагается на перевозчика.</p><br>

<p style="text-indent:2em;">(2) В случае, когда перевозчик утверждает, что при создавшихся обстоятельствах гибель, утрата или повреждение вещи могли 
 явиться следствием одного или нескольких рисков, указанных в части (3) статьи 1449, предполагается, что ущерб причинен такими 
 обстоятельствами. Правомочное лицо может, однако, доказывать, что частичный или полный ущерб не явился следствием одного из 
 этих рисков.</p><br>

<p style="text-indent:2em;">(3) Презумпция, предусмотренная частью (2), не допускается в случае, указанном в пункте a) части (3) статьи 1449, если убыль
  превышает нормально допустимую или при потере грузового места целиком.</p><br>

<p style="text-indent:2em;">(4) В случае перевозки транспортным средством, специально оборудованным для предохранения вещи от воздействия тепла, холода, 
 изменений температуры или влажности воздуха, перевозчик может ссылаться на положения пункта d) части (3) статьи 1449, только 
 если докажет, что принял все меры, которые он должен был принять с учетом обстоятельств, в отношении выбора, содержания и 
 использования этого специального оборудования и действовал согласно данным ему специальным указаниям.</p><br>

<p style="text-indent:2em;">(5) Перевозчик может ссылаться на положения пункта f) части (3) статьи 1449, только если докажет, что принял все меры, которые
 он должен был принять, учитывая обстоятельства, и соблюдал данные ему особые указания.</p><br>

<p style="font-weight:500;">Статья 1451. Нарушение срока доставки вещи</p><br>

<p style="text-indent:2em;">Срок доставки считается нарушенным в случае, если вещь не была доставлена в оговоренный срок или, при отсутствии оговоренного
 срока, если с учетом обстоятельств, в которых перевозка производилась, а в частности при частичной погрузке, с учетом времени,
 необходимого для составления полной партии в нормальных условиях, фактическая продолжительность перевозки превышает время, 
 необходимое при обычных условиях для выполнения перевозки добросовестным перевозчиком.</p><br>

<p style="font-weight:500;">Статья 1452. Презумпция утраты вещи</p><br>

<p style="text-indent:2em;">(1) Лицо, правомочное распоряжаться вещью, может, не будучи обязанным приводить иные доказательства, считать утраченной вещь,
 которая не была доставлена в течение тридцати дней по истечении срока доставки либо, если относительно срока не существует 
 договоренности, – в течение шестидесяти дней со дня принятия вещи перевозчиком.</p><br>

<p style="text-indent:2em;">(2) Лицо, правомочное распоряжаться вещью, при получении возмещения за утрату вещи может в письменной форме потребовать 
 немедленного уведомления его в случае обнаружения вещи в течение одного года со дня выплаты ему возмещения. Такое требование
 должно быть письменно подтверждено обязанным лицом.</p><br>

<p style="text-indent:2em;">(3) Лицо, правомочное распоряжаться вещью, может в тридцатидневный срок со дня получения уведомления, предусмотренного частью
 (2), потребовать доставки ему вещи взамен удовлетворения требований, вытекающих из накладной, и возврата полученного возмещения 
 за вычетом в случае необходимости расходов, связанных с возмещением ущерба. При этом его требования в связи с выплатой возмещения
 за просрочку в доставке вещи в соответствии со статьей 1455 и, по обстоятельствам, статьей 1458 остаются в силе.</p><br>
 
<p style="text-indent:2em;">(4) Если требование, предусмотренное частью (2), не предъявлено, либо если не дано никаких указаний в течение срока, указанного в
 части (3), либо если вещь обнаружена по истечении более чем одного года после выплаты возмещения, перевозчик может распорядиться
 вещью по своему усмотрению.</p><br>

<p style="font-weight:500;">Статья 1453. Доставка вещи без получения платы за нее</p><br>

<p style="text-indent:2em;">Если вещь доставлена получателю без получения за нее платы, которую перевозчик должен был получить согласно договору, перевозчик,
  сохраняя за собой право регресса против получателя, обязан выплатить отправителю возмещение в пределах размера платы за вещь.</p><br>

<p style="font-weight:500;">Статья 1454. Правила перевозки опасных вещей</p><br>

<p style="text-indent:2em;">(1) Отправитель обязан предупредить перевозчика в случае передачи ему опасных вещей о точном характере угрозы и при необходимости
  указать необходимые меры предосторожности. Если эта информация не указана в накладной, отправитель или получатель должен доказать
  иным способом, что перевозчику был известен точный характер угрозы, связанной с перевозкой вещи.</p><br>
  
<p style="text-indent:2em;">(2) Если перевозчик не знал об опасности вещи в соответствии с положениями части (1), эта вещь может быть выгружена, уничтожена либо
 обезврежена перевозчиком в любой момент без обязанности возмещения ущерба. Кроме того, отправитель отвечает за убытки, в том числе 
 расходы, причиненные сдачей, перевозкой, выгрузкой и уничтожением этой вещи.</p><br>

<p style="font-weight:500;">Статья 1455. Определение стоимости вещи в случае ее утраты и выплата возмещений</p><br>

<p style="text-indent:2em;">(1) Если на основе положений настоящей части в составе главы перевозчик обязан выплатить возмещение за полную или частичную утрату вещи
, размер возмещения исчисляется в зависимости от стоимости вещи в месте и в момент ее принятия.</p><br>

<p style="text-indent:2em;">(2) Стоимость вещи определяется на основании биржевой котировки, или, за отсутствием таковой, на основании рыночной цены, или же, 
 при отсутствии и той и другой, на основании обычной стоимости вещи такого же рода и качества.</p><br>
 
<p style="text-indent:2em;">(3) Размер возмещения не может, однако, превышать 8,33 расчетной единицы за килограмм недостающего веса брутто.</p><br>

<p style="text-indent:2em;">(4) Помимо выплаты возмещения, предусмотренного частью (1), подлежат возмещению плата за перевозку, таможенные пошлины, а также
  прочие расходы, связанные с перевозкой, полностью в случае утраты всей вещи и в пропорции, соответствующей размеру ущерба, при 
  частичной утрате. Иной ущерб возмещению не подлежит.</p><br>

<p style="text-indent:2em;">(5) В случае просрочки в доставке, если правомочное лицо докажет, что просрочка нанесла ущерб, перевозчик обязан возместить ущерб,
  который не может превышать плату за перевозку.</p><br>

<p style="text-indent:2em;">(6) Более значительное по размеру возмещение может быть потребовано только в случае декларирования стоимости вещей в соответствии
 со статьей 1456 или декларирования особого интереса отправителя в соответствии со статьей 1458.</p><br>

<p style="text-indent:2em;">(7) Расчетная единица, упомянутая в части (3), представляет собой единицу специальных прав заимствования (Special Drawing Rights),
 как она определена Международным валютным фондом.</p><br>

<p style="text-indent:2em;">(8) Суммы, рассчитанные в соответствии с частью (3), пересчитываются по официальному курсу молдавского лея, действующему на дату
 принятия вещей перевозчиком или на любую другую дату, согласованную сторонами.</p><br>

<p style="font-weight:500;">Статья 1456. Декларирование стоимости вещи</p><br>

<p style="text-indent:2em;">Отправитель может, при условии уплаты согласованной надбавки к плате за перевозку, указать в накладной стоимость вещи, превышающую
 предел, указанный в части (3) статьи 1455, и в таком случае объявленная сумма заменяет этот предел.</p><br>

<p style="font-weight:500;">Статья 1457. Ответственность за повреждение вещи</p><br>

<p style="text-indent:2em;">(1) В случае повреждения вещи перевозчик несет ответственность, соответствующую обесцениванию вещи. Объем возмещения определяется
 в соответствии с частями (1), (2) и (4) статьи 1455.</p>

<p style="text-indent:2em;"></p><h6>(2) Размер возмещения не может, однако, превышать:</h6><p></p>

<p style="text-indent:2em;">a) в случае если вследствие повреждения обесцениванию подверглась вся перевозимая вещь – сумму возмещения,
  которая причиталась бы при полной утрате;</p><br>
  
<p style="text-indent:2em;">b) в случае если вследствие повреждения обесцениванию подверглась лишь часть перевозимой вещи – сумму, которая
 причиталась бы при утрате той части вещи, которая оказалась обесцененной.</p><br>

<p style="font-weight:500;">Статья 1458. Особый интерес отправителя</p><br>

<p style="text-indent:2em;">(1) Отправитель может, взамен надбавки к плате за перевозку, установить посредством отметки в накладной сумму
  особого интереса в доставке на случай утраты, повреждения или просрочки в доставке.</p><br>

<p style="text-indent:2em;">(2) В случае объявления особого интереса в доставке может быть потребовано независимо от возмещений, предусмотренных
 статьями 1455, 1456 и 1457, и в пределах суммы объявленного интереса возмещение, соответствующее доказанному
 дополнительному ущербу.</p><br>

<p style="font-weight:500;">Статья 1459. Право правомочного лица на проценты</p><br>

 <p style="text-indent:2em;">Правомочное лицо может потребовать уплаты процентов на сумму, подлежащую возмещению, в размере 5 процентов годовых.</p><br>
 
 <p style="text-indent:2em;">Проценты эти исчисляются со дня направления перевозчику претензии или же, если такая претензия не предъявлялась,
 со дня подачи иска.</p><br>

<p style="font-weight:500;">Статья 1460. Права перевозчика в случае предъявления внедоговорных требований</p><br>

<p style="text-indent:2em;">(1) Если вследствие гибели, утраты, повреждения или просрочки в доставке, возникших при перевозке, подпадающей под
 действие положений настоящей части в составе главы, в соответствии с применяемым правом заявлены внедоговорные требования,
 перевозчик может ссылаться в отношении них на положения настоящей части в составе главы, исключающие его ответственность 
 либо ограничивающие или определяющие размер причитающегося возмещения.</p><br>

<p style="text-indent:2em;">(2) В случае предъявления внедоговорных требований по поводу гибели, утраты, повреждения или просрочки в доставке против 
  правомочного лица оно также может ссылаться на положения настоящей части в составе главы в той мере, в которой они исключают
  его ответственность либо определяют или ограничивают размер причитающегося возмещения.</p><br>

<p style="font-weight:500;">Статья 1461. Запрещение освобождения перевозчика от ответственности</p><br>

<p style="text-indent:2em;">(1) Перевозчик не вправе ссылаться на положения настоящей части в составе главы, исключающие или ограничивающие его ответственность
 либо переносящие бремя доказывания на другую сторону, если ущерб был причинен умышленно или по грубой небрежности.</p><br>

<p style="text-indent:2em;">(2) Положения части (1) применяются соответствующим образом в случае, когда деяния совершены умышленно или по грубой небрежности
  представителями перевозчика или любыми другими лицами, к услугам которых он прибегает для осуществления перевозки, если такие 
  представители или такие лица действуют при осуществлении своих функций. В этом случае представители или другие лица не вправе 
  ссылаться относительно своей ответственности на положения настоящей части в составе главы, исключающие или ограничивающие 
  ответственность либо переносящие бремя доказывания на другую сторону.</p><br>

<p>3. Претензии и иски</p><br>

<p style="font-weight:500;">Статья 1462. Предъявление претензий</p><br>

<p style="text-indent:2em;">(1) Если получатель принял вещь без надлежащей проверки ее состояния в присутствии перевозчика либо если самое позднее в 
момент доставки вещи, когда утрата или повреждение являются очевидными, либо в течение семи дней со дня доставки, не считая
 воскресений и установленных законом праздничных дней, когда утрата или повреждение не являются очевидными, не сделал перевозчику
 оговорок, указывающих на общий характер утраты или повреждения, то до тех пор, пока не будет доказано обратное, предполагается,
 что он получил вещь в состоянии, описанном в накладной. В случае утраты или повреждения, которые не являются очевидными, указанные
 оговорки должны быть сделаны в письменной форме.</p><br>

<p style="text-indent:2em;">(2) Если получатель и перевозчик вместе проверили состояние вещи, доказательства, противоречащие результатам этой проверки, 
  допускаются только в том случае, если речь идет о внешне незаметных повреждениях, и при условии, что получатель адресовал 
  письменные оговорки перевозчику в течение семи дней, не считая воскресений и установленных законом праздничных дней, со дня
  такой проверки.</p><br>

<p style="text-indent:2em;">(3) Просрочка в доставке вещи может привести к уплате возмещения лишь в том случае, если была сделана письменная оговорка в 
 течение двадцати одного дня со дня передачи вещи в распоряжение получателя.</p><br>

<p style="text-indent:2em;">(4) Перевозчик и получатель обязаны оказывать друг другу надлежащее содействие в выполнении необходимых обследований и 
проверок.</p><br>

<p style="font-weight:500;">Статья 1463. Срок исковой давности в отношениях перевозки</p><br>

<p style="text-indent:2em;">(1) Срок исковой давности в отношениях перевозки составляет один год. В случае умысла или грубой небрежности срок исковой
 давности составляет три года.</p>
 
<p style="text-indent:2em;"></p><h6>(2) Течение срока исковой давности начинается:</h6><p></p>

<p style="text-indent:2em;">a) в случае частичной утраты, повреждения вещи или просрочки в доставке – со дня сдачи вещи получателю;</p><br>

<p style="text-indent:2em;">b) в случае полной утраты вещи – с тридцатого дня по истечении срока перевозки, а если срок не установлен сторонами или законом
 – с шестидесятого дня со дня принятия вещи перевозчиком;</p><br>

<p style="text-indent:2em;">c) во всех прочих случаях – по истечении трехмесячного срока со дня заключения договора перевозки.</p><br>

<p style="text-indent:2em;">(3) Предъявление претензии в письменной форме приостанавливает течение срока исковой давности до того дня, когда перевозчик
 в письменной форме отклонил претензию и вернул приложенные к ней документы. В случае частичного признания претензии течение
 срока исковой давности возобновляется только в отношении той части претензии, которая остается в споре. Бремя доказывания 
 получения претензии или ответа на нее, а также возвращения документов возлагается на сторону, которая ссылается на этот факт.
 Предъявление дальнейших претензий на том же основании не прерывает течения срока исковой давности.</p><br>

<p style="text-indent:2em;">4. Положения, касающиеся перевозки, осуществляемой последовательно несколькими перевозчиками</p><br>

<p style="font-weight:500;">Статья 1464. Ответственность последовательных перевозчиков</p><br>

<p style="text-indent:2em;">(1) Если перевозка, условия которой определяются единым договором, осуществляется последовательно несколькими перевозчиками,
  каждый из них несет ответственность за всю перевозку.</p><br>

<p style="text-indent:2em;">(2) Второй перевозчик и каждый из следующих перевозчиков становятся, в силу принятия вещи и накладной, сторонами договора в
 пределах указанных в накладной условий.</p><br>

<p style="font-weight:500;">Статья 1465. Принятие вещи от предшествующего перевозчика</p><br>

<p style="text-indent:2em;">(1) Перевозчик, принимающий вещь от предшествующего перевозчика, должен вручить ему датированную и подписанную расписку
 о получении. Он должен указать свое имя и адрес на втором экземпляре накладной. В случае необходимости он делает на втором
 экземпляре накладной и в расписке о получении свои оговорки в соответствии с частью (2) статьи 1436.</p><br>

<p style="text-indent:2em;">(2) Отношения между последовательными перевозчиками регулируются положениями статьи 1439.</p><br>

<p style="font-weight:500;">Статья 1466. Предъявление претензий к последовательным перевозчикам</p><br>

<p style="text-indent:2em;">(1) Иски о возмещении убытков в связи с гибелью, утратой, повреждением вещи или просрочкой в доставке могут быть предъявлены
 только к первому перевозчику, к последнему перевозчику или к перевозчику, который осуществлял ту часть перевозки, на
 протяжении которой имели место событие или факт, повлекшие гибель, утрату, повреждение или просрочку в доставке.</p><br>
 
<p style="text-indent:2em;">(2) Один и тот же иск может быть предъявлен одновременно к нескольким из этих перевозчиков.</p><br>

<p style="font-weight:500;">Статья 1467. Право регресса</p>

<p style="text-indent:2em;"></p><h6>Перевозчик, уплативший на основе положений настоящей главы возмещение убытков, имеет право регресса к другим 
 перевозчикам, участвовавшим в исполнении договора перевозки, относительно уплаченной суммы возмещения, вместе
 с процентами и понесенными расходами, в соответствии со следующими правилами:</h6><p></p>

<p style="text-indent:2em;">a) перевозчик, по вине которого был причинен ущерб, должен единолично нести ответственность за возмещение, 
выплаченное им самим или другим перевозчиком;</p><br>
  
<p style="text-indent:2em;">b) если ущерб был причинен по вине двух или нескольких перевозчиков, каждый из них должен уплатить сумму,
  пропорциональную доле лежащей на нем ответственности. В случае если определение этой доли представляется невозможным,
  каждый перевозчик несет ответственность пропорционально доле причитающейся ему платы за перевозку;</p><br>

<p style="text-indent:2em;">c) если нельзя установить, кто из перевозчиков несет ответственность за ущерб, причитающаяся за возмещение его сумма
 распределяется между всеми перевозчиками в пропорции, определяемой по правилу, указанному в пункте b).</p><br>

<p style="font-weight:500;">Статья 1468. Неплатежеспособность одного из перевозчиков</p><br>

<p style="text-indent:2em;">В случае неплатежеспособности одного из перевозчиков часть возмещения, которую он должен уплатить, но не уплатил,
 распределяется между остальными перевозчиками пропорционально доле каждого в плате за перевозку.</p><br>
 
<p style="font-weight:500;">Статья 1469. Возражения, выдвигаемые в случае регрессного иска</p><br>

<p style="text-indent:2em;">(1) Перевозчик, против которого на основе статей 1467 и 1468 осуществляется право регресса, не может ссылаться на то,
 что перевозчик, осуществивший право регресса, выплатил возмещение лицу, которому нанесен ущерб, не будучи обязанным 
 сделать это, если возмещение установлено судебным решением и если перевозчик, к которому предъявлен регрессный иск,
 был уведомлен в установленном порядке об осуществляемом процессе и мог принять участие в нем.</p><br>

<p style="text-indent:2em;">(2) Положения статьи 1463 применимы к регрессным искам, предъявляемым одними перевозчиками к другим. Однако течение
 срока исковой давности начинается либо со дня вынесения окончательного судебного решения, которым определяется размер
 возмещения, подлежащего уплате согласно положениям настоящей части в составе главы, либо, при отсутствии такого решения,
 со дня фактической уплаты возмещения.</p><br>

<p style="font-weight:500;">Статья 1470. Право перевозчиков отступать от требований настоящего кодекса</p><br>

<p style="text-indent:2em;">Перевозчики вправе без ущерба для интересов отправителя или получателя груза договориться между собой относительно
  правил, являющихся отступлением от положений статей 1467 и 1468.</p><br>

<p style="font-weight:500;">Статья 1471. Ничтожность соглашений</p><br>

<p style="text-indent:2em;">(1) Без ущерба для положений статьи 1470 любое условие, представляющее собой прямое или косвенное отступление от 
положений настоящей части в составе главы, является ничтожным. Ничтожность таких условий не влечет ничтожности 
прочих условий договора.</p><br>

<p style="text-indent:2em;">(2) Ничтожным является, в частности, соглашение, в силу которого перевозчик становится выгодоприобретателем 
страхования вещи, а также любое подобное соглашение.</p><br>

<p style="text-indent:2em;">(3) Ничтожным является также соглашение, на основе которого бремя доказывания переносится 
 на другую сторону.</p><br>

<p><a href="https://www.legis.md/cautare/getResults?doc_id=120080&amp;lang=ru#">Источник</a></p> 


</div>
`