import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reduxForm } from 'redux-form'
import { withRouter } from 'react-router-dom'
import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'
import { IReduxStorage } from '../../../store/reducers/types'
import { onCanAdd } from '../../../store/actions/profile'

import Component from './component'
const formName = 'add-theme'
export default compose(
  withTranslation,
  withRouter,
  withPermissions,
  connect((state: IReduxStorage) => {
    return {
      langId: state.ui.langId,
      isAuthenticated: !!state.auth.accessToken,
    }
  },
    (dispatch: any) => ({
      onCanAdd: (type) => dispatch(onCanAdd(type)),
    })),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    forceUnregisterOnUnmount: false,
  })
)(Component)
