import React, { Component } from 'react'
import store from '../../../store';
import config from '../../../config'
import { USER_PHOTO_URL_PLACEHOLDER } from '../../../store/constants'
import forumCommentDate from '../../forum/forum-full-page/forum-date'
import UserPhoto from '../../user/user-photo'
import { Link } from 'react-router-dom'

import './conversationlistitem.scss'

interface ConversationListItemInterface extends ComponentBaseProps {
  userId: number
  showConversationWithUser: any
  isShowSpinner: any
  setActiveConv: any
  conversationDetails: any
  activeConvId: number
  closeMessenger: any
}

export interface ConversationListItemState {
  unreadMsgs: [],
}

export default class ConversationListItem extends Component<ConversationListItemInterface, ConversationListItemState> {
  constructor(props) {
    super(props);

    this.state = {
      unreadMsgs: [],
    };

    const { listUserId } = this.props?.conversationDetails;
    store.subscribe(() => {
      if (listUserId && listUserId > 0) {
        this.setState({ unreadMsgs: store.getState()?.socket?.unreadMsgs?.filter(item => item.sendUserId === listUserId) });
      }
    });
  }

  renderUnreadMessagesBubble = (newMessages) => {
    if (newMessages == 0) {
      return;
    }

    // setTimeout(() => this.setState({ unreadMsgs: [] }), 3000);

    return (
      <span className="unread-bubble">
        <b>{newMessages}</b>
      </span>
    )
  }

  propsFunctions = (listUserId, userFullName) => {
    const { showConversationWithUser, isShowSpinner, setActiveConv, activeConvId } = this.props
    showConversationWithUser(listUserId, userFullName)

    if (activeConvId !== listUserId) {
      isShowSpinner()
    }

    setActiveConv(listUserId)
  }

  render() {
    const {
      conversationDetails: {
        userFullName,
        newMessages,
        companyId,
        listUserId,
        lastMessage,
        lastMessageTime,
        companyName,
        userImage,
        userPhoto,
        gender
      },
      activeConvId,
      closeMessenger
    } = this.props
    const userPhotoUrl = userPhoto
      ? `${(config as any).apiUrl}/common/file/profile/${listUserId}/${userPhoto}`
      : USER_PHOTO_URL_PLACEHOLDER

    const activeConvStyle = activeConvId === listUserId ? 'active-conversation' : ''

    return (
      <div className={`conversation-list-item ${activeConvStyle}`}
        onClick={() => this.propsFunctions(listUserId, userFullName)}
      >
        {(activeConvId !== listUserId) && this.renderUnreadMessagesBubble(newMessages)}

        <div className="user-photo-container">
          <UserPhoto customUserId={listUserId} photo={userPhoto} gender={gender} />
        </div>


        <div className="conversation-info">
          <div className="name-company-container">
            <div className="conversation-user-container">
              <h1 className="conversation-title">
                <Link to={`/user/details/${listUserId}`} onClick={() => closeMessenger()}>
                  {userFullName}
                </Link>
              </h1>
              {companyId ? <div className="conversation-user-company">
                <Link to={`/company/item/${companyId}`} onClick={() => closeMessenger()}>
                  {companyName}
                </Link>
              </div> :
                <div className="conversation-user-company">
                  {companyName}
                </div>}

            </div>
            <div>
              <b className="conversation-time-date">{forumCommentDate(lastMessageTime)}</b>
            </div>
          </div>

          <div className="clear" />

          <div className="conversation-snippet">{lastMessage}</div>
        </div>
      </div>
    )
  }
}
