import React, { Component } from 'react'
import classNames from 'classnames'

import { stopBubble } from '../../shared/utils/form-utils'

import './styles.scss'

const STYLE_BASE = 'dropdown-base_'

export default class Dropdown extends Component {
  render() {
    const { children, className, style, isStopBubble = true } = this.props

    const childs = React.Children.toArray(children)
    const DropdownName = childs.find(child => child.type.displayName.includes('DropdownName'))
    const DropdownPanel = childs.find(child => child.type.displayName.includes('DropdownPanel'))
    const DropdownPopover =
      childs.find(child => child.type.displayName.includes('DropdownPopover')) || null

    return (
      <div
        className={classNames({
          [`${STYLE_BASE}wrapper`]: true,
          [className]: !!className,
        })}
        style={style}
        //onTouchEnd={isStopBubble && stopBubble}
        onClick={isStopBubble && stopBubble}
      >
        {DropdownName}
        {DropdownPanel}
        {DropdownPopover}
      </div>
    )
  }
}
