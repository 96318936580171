import React, { Component } from 'react'
import { cog } from 'react-icons-kit/fa/cog'
import WidgetList from '../../shared/components/widget-list'
import DropdownCheckboxlist from '../../shared/components/dropdown-checkboxlist'
import dict from '../../shared/dictionaries'
import fetch from '../../../components/shared/fetch'
import Cookies from 'universal-cookie'

export default class extends Component {
  constructor(props) {
    super(props)

    const { t, langId, currentLanguage } = props
    this.state = {
      filterBar: {
        items: dict.filterOptionsForNews(langId),
        emptyOption: t('all'),
        selectedKey: undefined,
        onClick: filterKey => {
          const { filterBar } = this.state
          if (filterKey !== filterBar.selectedKey) {
            this.refresh({ filterKey: filterKey || undefined })
          }
        },
      },
      newsItems: [],
      languages: [currentLanguage],
    }
  }

  componentDidMount() {
    //const { currentLanguage } = this.props

    //const languagesByDefault = [currentLanguage]
    const languagesByDefault = ['ro', 'ru', 'en']
    const cookies = new Cookies()
    const languages = cookies.get('newsSelectedLanguages')

    if (!languages) {
      this.setState({ languages: languagesByDefault }, () => {
        this.commonSetStateAndRefresh()
      })
    } else {
      this.setState({ languages }, () => {
        this.commonSetStateAndRefresh()
      })
    }
  }

  commonSetStateAndRefresh = () => {
    const { filterBar } = this.state
    this.setState({ pageIndex: 0, filterKey: filterBar.selectedKey }, () => {
      this.refresh({ pageIndex: 0, filterKey: filterBar.selectedKey })
    })
  }

  refresh = props => {
    const { pageSize, pageIndex: pageIndexOld } = this.props
    const { filterBar: filterBarOld, languages: languagesOld } = this.state
    const { pageIndex = pageIndexOld, languages = languagesOld, filterKey } = props

    if (filterKey !== filterBarOld.selectedKey) {
      const filterBar = { ...filterBarOld }
      filterBar.selectedKey = filterKey
      this.setState({ filterBar })
    }

    const body = JSON.stringify({
      languages,
      pageIndex,
      pageSize,
      filterKey,
    })

    fetch('/news/list', {
      method: 'POST',
      body,
    }).then(result => {
      const {
        d: { items },
      } = result
      this.setState({ newsItems: items })
    })
  }

  getLangItems = () => {
    const { t } = this.props
    const { languages = [] } = this.state
    const langs = ['ru', 'en', 'ro'].map(lang => ({
      name: t(`news.submenu.options.${lang}`),
      value: lang,
      checked: languages.includes(lang),
    }))
    return [{ name: t('news.submenu.header'), type: 'header' }, ...langs]
  }
  onChangeLang = languages => {
    const cookies = new Cookies()
    cookies.set('newsSelectedLanguages', languages)
    this.setState({ languages }, () => {
      this.refresh({ languages, filterKey: this.state.filterBar.selectedKey })
    })
  }
  renderMenu = () => {
    const { languages } = this.state
    return (
      <DropdownCheckboxlist icon={cog} items={languages && this.getLangItems()} onChange={this.onChangeLang} />
    )
  }

  render() {
    const { t, dataMoveForMobile } = this.props
    const { filterBar, newsItems } = this.state

    return (
      <WidgetList
        title={t('news.widget.title.listMini')}
        headerMenu={this.renderMenu}
        items={newsItems || []}
        loadLanguages
        filterBar={filterBar}
        linksTo={{ title: '/news/list', item: '/news/' }}
        textPropName="title"
        dataMoveForMobile={dataMoveForMobile}
      />
    )
  }
}
