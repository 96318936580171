import { compose } from 'recompose'

import Component from './component'
import withTranslation from '../../../../globals/components/withTranslation'
import withPermissions from '../../../../globals/components/withPermissions'
import { IWidgetAceessProps } from './types'

export default compose(
  withTranslation,
  withPermissions
)(Component) as React.ComponentClass<IWidgetAceessProps>
