import React, { Component } from 'react'
// import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { Field } from 'redux-form'
import Dropdown from '../../../../../UI/dropdown'
import { required } from '../../../../../shared/form-validators'
import dict from '../../../../../shared/dictionaries'
import TemperatureFromTo from '../../../../../shared/components/temperature-mode-from-to'
import PopoverWrapper from '../../../../../UI/popover-wrapper'
import { CheckboxField } from '../../../../../UI/checkbox'
import TooltipForDownload from '../../../../../UI/tooltip-for-download'
import TooltipForDownloadTemp from '../../../../../UI/tooltip-for-download-temp'

// import './styles.scss'

const STYLE_BASE = 'car-load-data_'

export default class extends Component {
  render() {
    const { t } = this.props

    const {
      // className,
      adrName,
      adrClassName,
      oversizedName,
      groupingName,
      gpsName,
      isVisible = true,
      isAutopark,
      shippingType,
      langId,
    } = this.props

    const validateProps = {}
    if (isVisible) {
      validateProps.validate = [required]
    }

    const label = shippingType === 'container' ? t('is.gps.container') : t('autopark.is.gps')

    return (
      // <div
      //   className={classNames({
      //     'panel-content-row': true,
      //     [className]: !!className,
      //   })}
      // >
      <div className="panel-form-row" style={{ paddingLeft: 14 }}>
        <div className="inputs-iconed inputs-select inputs-no-p">
          <div style={{ display: 'flex', minHeight: 35 }}>
            <div className="input-container-sel input-toggle-select">
              <CheckboxField name={adrName}>
                {`ADR`}
                <i className="fa fa-fire" />
              </CheckboxField>{' '}
              <div className="select-wrapper">
                <Field
                  name={adrClassName}
                  component={Dropdown}
                  items={dict.adrClasses}
                  className={`${STYLE_BASE}adr-class`}
                />
              </div>
              <PopoverWrapper
                hover
                clickOnUrl
                text={
                  <div>
                    {langId === 3 || langId === 2 ? ( 
                      <>
                        <a
                          href="https://www.legis.md/cautare/getResults?doc_id=118009&lang=ro"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('adr.description')}
                        </a>
                        <div>{t('adr.line.2')}</div>
                      </>
                    ) : (
                      <>
                        <Link to="/useful/legislation/int/int18" target="_blank" rel="noopener noreferrer">{t('adr.description')}</Link>
                        <div>{t('adr.line.2')}</div>
                      </>
                    )}
                  </div>
                }
              >
                <i className="fa fa-question-circle col-blue tooltip-trigger" />
              </PopoverWrapper>
              {/* <PopoverWrapper
                hover
                text={
                  <div>
                    <Link to="#">{t('adr.line.1')}</Link>
                    <div>{t('adr.line.2')}</div>
                  </div>
                }
              >
                <i className="fa fa-question-circle col-blue tooltip-trigger" />
              </PopoverWrapper> */}
            </div>

            <div style={{display: 'flex', alignItems: 'baseline'}}>
              <TemperatureFromTo
                text={t(`cargo.temperature.mode`)}
                fieldNameCheckbox={`${isAutopark ? '' : 'cargo.'}isClimateControl`}
                fieldNameFrom={`${isAutopark ? '' : 'cargo.'}temperatureFrom`}
                fieldNameTo={`${isAutopark ? '' : 'cargo.'}temperatureTo`}
                // tooltip={
                //   <div>
                //     {t('adr.line.3')}
                //     <div>
                //       <Link to="#">{t('adr.line.4')}</Link>
                //     </div>
                //   </div>
                // }
              />
              <PopoverWrapper hover clickOnUrl text={<TooltipForDownloadTemp t={t} langId />}>
                <i className="fa fa-question-circle col-blue tooltip-trigger" />
              </PopoverWrapper>
            </div>
          </div>
          <div>
            <div className="input-container-sel">
              <CheckboxField name={oversizedName}>
                {t('no.gabarit')}
                <i className="ico ico-weight" />
              </CheckboxField>
              <PopoverWrapper hover clickOnUrl text={<TooltipForDownload t={t} langId />}>
                <i className="fa fa-question-circle col-blue tooltip-trigger" />
              </PopoverWrapper>
            </div>
            <div className="input-container-sel">
              <CheckboxField name={groupingName}>
                {t('cargo.grouping')}
                <i className="fa fa-th-large" />
              </CheckboxField>
              <PopoverWrapper hover text={<div>{t('adr.line.8')}</div>}>
                <i className="fa fa-question-circle col-blue tooltip-trigger" />
              </PopoverWrapper>
            </div>

            <div className="input-container-sel">
              <CheckboxField name={gpsName}>{label}</CheckboxField>
            </div>
          </div>
        </div>
      </div>
      // </div>
    )
  }
}
