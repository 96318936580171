import { connect } from 'react-redux'
import { compose } from 'recompose'

import {
  getAccessList,
  getUserFullName,
  addAccessPoint,
  deleteAccessPoint,
  setAccessGroup,
} from '../../store/actions/access'
import { IReduxStorage } from '../../store/reducers/types'
import Component from './component'
import withTranslation from '../../globals/components/withTranslation'
import withPermissions from '../../globals/components/withPermissions'

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state: IReduxStorage) => {
      const { accessPoints, accessGroups, userFullName } = state.access

      return {
        accessPoints,
        accessGroups,
        userFullName
      }
    },
    {
      getAccessList,
      getUserFullName,
      addAccessPoint,
      deleteAccessPoint,
      setAccessGroup,
    }
  )
)(Component)