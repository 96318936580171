import window from 'global/window'
import _ from 'lodash'
import { compose, pure, lifecycle } from 'recompose'
import { connect } from 'react-redux'

import { reportLoginStatus } from '../../store/actions/socket'

const { localStorage } = window

const storageMappings = [
  {
    storageKey: 'accessToken',
    propsKey: 'accessToken',
  },
  {
    storageKey: 'refreshToken',
    propsKey: 'refreshToken',
  },
  {
    storageKey: 'sessionId',
    propsKey: 'sessionId',
  },

  {
    storageKey: 'culture',
    propsKey: 'culture',
  },
  {
    storageKey: 'timezone',
    propsKey: 'timezone',
  },
  {
    storageKey: 'clientId',
    propsKey: 'clientId',
  },
  {
    storageKey: 'drawerOpen',
    propsKey: 'drawerOpen',
    parse: it => it === 'true',
  },
]

export const getSystemHydrateData = () => {
  const data = storageMappings.reduce((acc, { storageKey, propsKey, parse = _.identity }) => {
    const value = localStorage.getItem(storageKey)
    if (_.isString(value)) {
      acc[propsKey] = parse(value)
    }

    return acc
  }, {})

  return data
}

export const getSystemAccessToken = () => localStorage.getItem('accessToken')

const enhance = compose(
  pure,
  connect(null, {
    reportLoginStatus,
  }),
  lifecycle({
    handleStorage(props) {
      storageMappings.forEach(({ storageKey, propsKey }) => {
        const value = props[propsKey]
        
        if (_.isUndefined(value)) {
          localStorage.removeItem(storageKey)
        } else {
          localStorage.setItem(storageKey, value)
        }
      })

      props.reportLoginStatus()
    },
    componentDidMount() {
      this.handleStorage(this.props)
    },
    componentDidUpdate() {
      this.handleStorage(this.props)
    },
  })
)

export const LocalStorage = () => false

export default enhance(LocalStorage)
