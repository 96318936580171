import React, { Component } from 'react'
import classNames from 'classnames'
import { Field } from 'redux-form'

import PopoverWrapper from '../../../UI/popover-wrapper'
import Dropdown from '../../../UI/dropdown'
import { CheckboxField } from '../../../UI/checkbox'
import dict from '../../../shared/dictionaries'

import './styles.scss'

const STYLE_BASE = 'temperatures-from-to_'

export default class extends Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     monthDays: dict.monthDays(props.shipOnDate),
  //     showShipDateCalendar: false,
  //   }
  // }

  render() {
    const { t, text, fieldNameCheckbox, fieldNameFrom, fieldNameTo, tooltip } = this.props

    return (
      <div
        className={classNames(
          'input-container-sel input-container-sel-lg input-toggle-select',
          `${STYLE_BASE}wrapper`
        )}
        style={{ display: 'flex' }}
      >
        <CheckboxField name={fieldNameCheckbox}>
          {text}
          <i className="fa fa-snowflake-o" />
        </CheckboxField>{' '}
        <div className="select-wrapper">
          {`${t('from')} `}
          <Field
            name={fieldNameFrom}
            component={Dropdown}
            items={dict.cargoTemperatures}
            className={`${STYLE_BASE}temperature`}
          />
          {` ${t('to')} `}
          <Field
            name={fieldNameTo}
            component={Dropdown}
            items={dict.cargoTemperatures}
            className={`${STYLE_BASE}temperature`}
          />
        </div>
        {/* <PopoverWrapper hover text={tooltip}>
          <i className="fa fa-question-circle col-blue tooltip-trigger" />
        </PopoverWrapper> */}
      </div>
    )
  }
}
