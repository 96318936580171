import { connect } from 'react-redux'
import { compose, withProps, withHandlers, lifecycle } from 'recompose'

import Component from './component'

import withTranslation from '../../../globals/components/withTranslation'
import { fetchUsers } from '../../../store/actions/user'

import {
  makeSortPageSelector,
} from '../../shared/selectors/search-params'

export default compose(
  withTranslation,
  withProps(
    makeSortPageSelector({
      sortCol: 'name',
    })
  ),
  connect(
    state => ({
      items: state.user.items,
      table: state.user.table,
    }),
    {
      fetchUsers,
    }
  ),
  withHandlers({
    onInitialLoad: ({ fetchUsers, sortCol, sortDir, pageAt, pageSize }) => () => {
      const filtersParams = {}
      fetchUsers(filtersParams, sortCol, sortDir, pageAt, pageSize)
    },

    onRowClick: ({ history }) => ({
      currentTarget: {
        dataset: { userId },
      },
    }) => {
      history.push(`/user/${userId}`)
    },

    onSortColumn: ({ history, location, sortCol, sortDir }) => nextSortCol => {
      const searchParams = new URLSearchParams(location.search)
      if (sortCol !== nextSortCol) {
        searchParams.set('sortCol', nextSortCol)
      } else {
        searchParams.set('sortDir', sortDir !== 'asc' ? 'asc' : 'desc')
      }
      searchParams.set('pageAt', 0)

      history.replace({
        ...location,
        search: searchParams.toString(),
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.onInitialLoad()
    },

    componentDidUpdate(prevProps) {
      if (this.props.location.search !== prevProps.location.search) {
        this.props.onInitialLoad()
      }
    },
  })
)(Component)
