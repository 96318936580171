export default [
  {
    id: 'Dateline Standard Time',
    name: 'International Date Line West (UTC-12:00)',
    timeZone: 'Pacific/Wake',
  },
  {
    id: 'UTC-11',
    name: 'Coordinated Universal Time-11 (UTC-11:00)',
    timeZone: 'Pacific/Niue',
  },
  {
    id: 'Hawaiian Standard Time',
    name: 'Hawaii (UTC-10:00)',
    timeZone: 'Pacific/Honolulu',
  },
  {
    id: 'Alaskan Standard Time',
    name: 'Alaska (UTC-09:00)',
    timeZone: 'America/Juneau',
  },
  {
    id: 'Pacific Standard Time (Mexico)',
    name: 'Baja California (UTC-08:00)',
    timeZone: 'America/Santa_Isabel',
  },
  {
    id: 'Pacific Standard Time',
    name: 'Pacific Time (US & Canada) (UTC-08:00)',
    timeZone: 'America/Los_Angeles',
  },
  {
    id: 'US Mountain Standard Time',
    name: 'Arizona (UTC-07:00)',
    timeZone: 'America/Phoenix',
  },
  {
    id: 'Mountain Standard Time (Mexico)',
    name: 'Chihuahua, La Paz, Mazatlan (UTC-07:00)',
    timeZone: 'America/Chihuahua',
  },
  {
    id: 'Mountain Standard Time',
    name: 'Mountain Time (US & Canada) (UTC-07:00)',
    timeZone: 'America/Denver',
  },
  {
    id: 'Central America Standard Time',
    name: 'Central America (UTC-06:00)',
    timeZone: 'America/Chicago',
  },
  {
    id: 'Central Standard Time',
    name: 'Central Time (US & Canada) (UTC-06:00)',
    timeZone: 'Canada/Central',
  },
  {
    id: 'Central Standard Time (Mexico)',
    name: 'Guadalajara, Mexico City, Monterrey (UTC-06:00)',
    timeZone: 'America/Mexico_City',
  },
  {
    id: 'Canada Central Standard Time',
    name: 'Saskatchewan (UTC-06:00)',
    timeZone: 'Canada/Central',
  },
  {
    id: 'SA Pacific Standard Time',
    name: 'Bogota, Lima, Quito (UTC-05:00)',
    timeZone: 'America/Bogota',
  },
  {
    id: 'Eastern Standard Time',
    name: 'Eastern Time (US & Canada) (UTC-05:00)',
    timeZone: 'America/New_York',
  },
  {
    id: 'US Eastern Standard Time',
    name: 'Indiana (East) (UTC-05:00)',
    timeZone: 'America/Indianapolis',
  },
  {
    id: 'Venezuela Standard Time',
    name: 'Caracas (UTC-04:30)',
    timeZone: 'America/Caracas',
  },
  {
    id: 'Paraguay Standard Time',
    name: 'Asuncion (UTC-04:00)',
    timeZone: 'America/Asuncion',
  },
  {
    id: 'Atlantic Standard Time',
    name: 'Atlantic Time (Canada) (UTC-04:00)',
    timeZone: 'Canada/Atlantic',
  },
  {
    id: 'Central Brazilian Standard Time',
    name: 'Cuiaba (UTC-04:00)',
    timeZone: 'America/Cuiaba',
  },
  {
    id: 'SA Western Standard Time',
    name: 'Georgetown, La Paz, Manaus, San Juan (UTC-04:00)',
    timeZone: 'America/La_Paz',
  },
  {
    id: 'Pacific SA Standard Time',
    name: 'Santiago (UTC-04:00)',
    timeZone: 'America/Santiago',
  },
  {
    id: 'Newfoundland Standard Time',
    name: 'Newfoundland (UTC-03:30)',
    timeZone: 'Canada/Newfoundland',
  },
  {
    id: 'E. South America Standard Time',
    name: 'Brasilia (UTC-03:00)',
    timeZone: 'Brazil/East',
  },
  {
    id: 'Argentina Standard Time',
    name: 'Buenos Aires (UTC-03:00)',
    timeZone: 'America/Buenos_Aires',
  },
  {
    id: 'SA Eastern Standard Time',
    name: 'Cayenne, Fortaleza (UTC-03:00)',
    timeZone: 'America/Cayenne',
  },
  {
    id: 'Greenland Standard Time',
    name: 'Greenland (UTC-03:00)',
    timeZone: 'America/Godthab',
  },
  {
    id: 'Montevideo Standard Time',
    name: 'Montevideo (UTC-03:00)',
    timeZone: 'America/Montevideo',
  },
  {
    id: 'Bahia Standard Time',
    name: 'Salvador (UTC-03:00)',
    timeZone: 'America/Bahia',
  },
  {
    id: 'UTC-02',
    name: 'Coordinated Universal Time-02 (UTC-02:00)',
    timeZone: 'Atlantic/South_Georgia',
  },
  {
    id: 'Mid-Atlantic Standard Time',
    name: 'Mid-Atlantic (UTC-02:00)',
    timeZone: 'Atlantic/South_Georgia',
  },
  {
    id: 'Azores Standard Time',
    name: 'Azores (UTC-01:00)',
    timeZone: 'Atlantic/Azores',
  },
  {
    id: 'Cape Verde Standard Time',
    name: 'Cape Verde Is. (UTC-01:00)',
    timeZone: 'Atlantic/Cape_Verde',
  },
  {
    id: 'Morocco Standard Time',
    name: 'Casablanca (UTC)',
    timeZone: 'Africa/Casablanca',
  },
  { id: 'UTC', name: 'Coordinated Universal Time (UTC)', timeZone: 'UTC' },
  {
    id: 'GMT Standard Time',
    name: 'Dublin, Edinburgh, Lisbon, London (UTC)',
    timeZone: 'Europe/London',
  },
  {
    id: 'Greenwich Standard Time',
    name: 'Monrovia, Reykjavik (UTC)',
    timeZone: 'Africa/Monrovia',
  },
  {
    id: 'W. Europe Standard Time',
    name: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (UTC+01:00)',
    timeZone: 'Europe/Berlin',
  },
  {
    id: 'Central Europe Standard Time',
    name: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague (UTC+01:00)',
    timeZone: 'Europe/Prague',
  },
  {
    id: 'Romance Standard Time',
    name: 'Brussels, Copenhagen, Madrid, Paris (UTC+01:00)',
    timeZone: 'Europe/Paris',
  },
  {
    id: 'Central European Standard Time',
    name: 'Sarajevo, Skopje, Warsaw, Zagreb (UTC+01:00)',
    timeZone: 'Europe/Zagreb',
  },
  {
    id: 'W. Central Africa Standard Time',
    name: 'West Central Africa (UTC+01:00)',
    timeZone: 'Africa/Lagos',
  },
  {
    id: 'Namibia Standard Time',
    name: 'Windhoek (UTC+01:00)',
    timeZone: 'Africa/Windhoek',
  },
  {
    id: 'Jordan Standard Time',
    name: 'Amman (UTC+02:00)',
    timeZone: 'Asia/Amman',
  },
  {
    id: 'GTB Standard Time',
    name: 'Athens, Bucharest (UTC+02:00)',
    timeZone: 'Europe/Athens',
  },
  {
    id: 'Middle East Standard Time',
    name: 'Beirut (UTC+02:00)',
    timeZone: 'Asia/Beirut',
  },
  {
    id: 'Egypt Standard Time',
    name: 'Cairo (UTC+02:00)',
    timeZone: 'Africa/Cairo',
  },
  {
    id: 'Syria Standard Time',
    name: 'Damascus (UTC+02:00)',
    timeZone: 'Asia/Damascus',
  },
  {
    id: 'South Africa Standard Time',
    name: 'Harare, Pretoria (UTC+02:00)',
    timeZone: 'Africa/Johannesburg',
  },
  {
    id: 'FLE Standard Time',
    name: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (UTC+02:00)',
    timeZone: 'Europe/Helsinki',
  },
  {
    id: 'Turkey Standard Time',
    name: 'Istanbul (UTC+02:00)',
    timeZone: 'Europe/Istanbul',
  },
  {
    id: 'Israel Standard Time',
    name: 'Jerusalem (UTC+02:00)',
    timeZone: 'Asia/Jerusalem',
  },
  {
    id: 'E. Europe Standard Time',
    name: 'Nicosia (UTC+02:00)',
    timeZone: 'Asia/Nicosia',
  },
  {
    id: 'Arabic Standard Time',
    name: 'Baghdad (UTC+03:00)',
    timeZone: 'Asia/Baghdad',
  },
  {
    id: 'Kaliningrad Standard Time',
    name: 'Kaliningrad, Minsk (UTC+03:00)',
    timeZone: 'Europe/Minsk',
  },
  {
    id: 'Arab Standard Time',
    name: 'Kuwait, Riyadh (UTC+03:00)',
    timeZone: 'Asia/Kuwait',
  },
  {
    id: 'E. Africa Standard Time',
    name: 'Nairobi (UTC+03:00)',
    timeZone: 'Africa/Nairobi',
  },
  {
    id: 'Iran Standard Time',
    name: 'Tehran (UTC+03:30)',
    timeZone: 'Asia/Tehran',
  },
  {
    id: 'Arabian Standard Time',
    name: 'Abu Dhabi, Muscat (UTC+04:00)',
    timeZone: 'Asia/Muscat',
  },
  {
    id: 'Azerbaijan Standard Time',
    name: 'Baku (UTC+04:00)',
    timeZone: 'Asia/Baku',
  },
  {
    id: 'Russian Standard Time',
    name: 'Moscow, St. Petersburg, Volgograd (UTC+04:00)',
    timeZone: 'Europe/Moscow',
  },
  {
    id: 'Mauritius Standard Time',
    name: 'Port Louis (UTC+04:00)',
    timeZone: 'Indian/Mauritius',
  },
  {
    id: 'Georgian Standard Time',
    name: 'Tbilisi (UTC+04:00)',
    timeZone: 'Asia/Tbilisi',
  },
  {
    id: 'Caucasus Standard Time',
    name: 'Yerevan (UTC+04:00)',
    timeZone: 'Asia/Yerevan',
  },
  {
    id: 'Afghanistan Standard Time',
    name: 'Kabul (UTC+04:30)',
    timeZone: 'Asia/Kabul',
  },
  {
    id: 'Pakistan Standard Time',
    name: 'Islamabad, Karachi (UTC+05:00)',
    timeZone: 'Asia/Karachi',
  },
  {
    id: 'West Asia Standard Time',
    name: 'Tashkent (UTC+05:00)',
    timeZone: 'Asia/Tashkent',
  },
  {
    id: 'India Standard Time',
    name: 'Chennai, Kolkata, Mumbai, New Delhi (UTC+05:30)',
    timeZone: 'Asia/Kolkata',
  },
  {
    id: 'Sri Lanka Standard Time',
    name: 'Sri Jayawardenepura (UTC+05:30)',
    timeZone: 'Asia/Colombo',
  },
  {
    id: 'Nepal Standard Time',
    name: 'Kathmandu (UTC+05:45)',
    timeZone: 'Asia/Kathmandu',
  },
  {
    id: 'Central Asia Standard Time',
    name: 'Astana (UTC+06:00)',
    timeZone: 'Asia/Omsk',
  },
  {
    id: 'Bangladesh Standard Time',
    name: 'Dhaka (UTC+06:00)',
    timeZone: 'Asia/Dhaka',
  },
  {
    id: 'Ekaterinburg Standard Time',
    name: 'Ekaterinburg (UTC+06:00)',
    timeZone: 'Asia/Yekaterinburg',
  },
  {
    id: 'Myanmar Standard Time',
    name: 'Yangon (Rangoon) (UTC+06:30)',
    timeZone: 'Asia/Rangoon',
  },
  {
    id: 'SE Asia Standard Time',
    name: 'Bangkok, Hanoi, Jakarta (UTC+07:00)',
    timeZone: 'Asia/Bangkok',
  },
  {
    id: 'N. Central Asia Standard Time',
    name: 'Novosibirsk (UTC+07:00)',
    timeZone: 'Asia/Novosibirsk',
  },
  {
    id: 'China Standard Time',
    name: 'Beijing, Chongqing, Hong Kong, Urumqi (UTC+08:00)',
    timeZone: 'Asia/Hong_Kong',
  },
  {
    id: 'North Asia Standard Time',
    name: 'Krasnoyarsk (UTC+08:00)',
    timeZone: 'Asia/Krasnoyarsk',
  },
  {
    id: 'Singapore Standard Time',
    name: 'Kuala Lumpur, Singapore (UTC+08:00)',
    timeZone: 'Asia/Singapore',
  },
  {
    id: 'W. Australia Standard Time',
    name: 'Perth (UTC+08:00)',
    timeZone: 'Australia/West',
  },
  {
    id: 'Taipei Standard Time',
    name: 'Taipei (UTC+08:00)',
    timeZone: 'Asia/Taipei',
  },
  {
    id: 'Ulaanbaatar Standard Time',
    name: 'Ulaanbaatar (UTC+08:00)',
    timeZone: 'Asia/Ulaanbaatar',
  },
  {
    id: 'North Asia East Standard Time',
    name: 'Irkutsk (UTC+09:00)',
    timeZone: 'Asia/Irkutsk',
  },
  {
    id: 'Tokyo Standard Time',
    name: 'Osaka, Sapporo, Tokyo (UTC+09:00)',
    timeZone: 'Asia/Tokyo',
  },
  {
    id: 'Korea Standard Time',
    name: 'Seoul (UTC+09:00)',
    timeZone: 'Asia/Seoul',
  },
  {
    id: 'Cen. Australia Standard Time',
    name: 'Adelaide (UTC+09:30)',
    timeZone: 'Australia/South',
  },
  {
    id: 'AUS Central Standard Time',
    name: 'Darwin (UTC+09:30)',
    timeZone: 'Australia/North',
  },
  {
    id: 'E. Australia Standard Time',
    name: 'Brisbane (UTC+10:00)',
    timeZone: 'Australia/Queensland',
  },
  {
    id: 'AUS Eastern Standard Time',
    name: 'Canberra, Melbourne, Sydney (UTC+10:00)',
    timeZone: 'Australia/Canberra',
  },
  {
    id: 'West Pacific Standard Time',
    name: 'Guam, Port Moresby (UTC+10:00)',
    timeZone: 'Pacific/Guam',
  },
  {
    id: 'Tasmania Standard Time',
    name: 'Hobart (UTC+10:00)',
    timeZone: 'Australia/Tasmania',
  },
  {
    id: 'Yakutsk Standard Time',
    name: 'Yakutsk (UTC+10:00)',
    timeZone: 'Asia/Yakutsk',
  },
  {
    id: 'Central Pacific Standard Time',
    name: 'Solomon Is., New Caledonia (UTC+11:00)',
    timeZone: 'Pacific/Guadalcanal',
  },
  {
    id: 'Vladivostok Standard Time',
    name: 'Vladivostok (UTC+11:00)',
    timeZone: 'Asia/Vladivostok',
  },
  {
    id: 'New Zealand Standard Time',
    name: 'Auckland, Wellington (UTC+12:00)',
    timeZone: 'Pacific/Auckland',
  },
  {
    id: 'UTC+12',
    name: 'Coordinated Universal Time+12 (UTC+12:00)',
    timeZone: 'Pacific/Funafuti',
  },
  {
    id: 'Fiji Standard Time',
    name: 'Fiji (UTC+12:00)',
    timeZone: 'Pacific/Fiji',
  },
  {
    id: 'Magadan Standard Time',
    name: 'Magadan (UTC+12:00)',
    timeZone: 'Asia/Magadan',
  },
  {
    id: 'Kamchatka Standard Time',
    name: 'Petropavlovsk-Kamchatsky - Old (UTC+12:00)',
    timeZone: 'Asia/Kamchatka',
  },
  {
    id: 'Tonga Standard Time',
    name: "Nuku'alofa (UTC+13:00)",
    timeZone: 'Pacific/Tongatapu',
  },
  {
    id: 'Samoa Standard Time',
    name: 'Samoa (UTC+13:00)',
    timeZone: 'Pacific/Samoa',
  },
]
