import React from 'react'

import Filter from '../../shared/components/widget-filter'

export default ({ input, items, className, hideBorders, onFilterClick, loadLanguages }) => {
  const props = {
    items,
    selectedKey: input.value,
    onClick: value => {
      onFilterClick && onFilterClick(value)
      input.onChange(value)
    },
    className,
    hideBorders,
    loadLanguages
  }
  return <Filter {...props} />
}
