export const linksEU = (t) => {
    const links = [
        { key: "eu1", title: "Transport." },
        { key: "eu2", title: "Transport, energie şi mediu.." },
        { key: "eu3", title: "Obiective şi organisme.." },
        { key: "eu4", title: "Intermodalitate şi reţele transeuropene.." },
        { key: "eu5", title: "Transport rutier.." },
        { key: "eu6", title: "Transport feroviar.." },
        { key: "eu7", title: "Transport aerian.." },
        { key: "eu8", title: "Transport maritim şi fluvial.." },
        { key: "eu9", title: "Transport inteligent şi navigaţie prin satelit.." },
        { key: "eu10", title: "Dimensiunea internaţională şi extinderea.." },
        
    ]

    return links
}

