export const int12 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Согласование кодов статуса перевозки
Рекомендация N 24 Европейской экономической комиссии ООН</h4><p></p>

<p></p><h4>(Женева, январь 1996 года)
(ECE/TRADE/206)</h4><p></p>

<p style="font-weight:500;">Рекомендация</p><br>

<p style="font-weight:500;">I. История вопроса</p><br>

<p style="font-weight:500;">II. Сфера охвата</p><br>

<p style="font-weight:500;">III. Область применения</p><br>

<p style="font-weight:500;">IV. Справочный материал</p><br>

<p style="font-weight:500;">V. Система кодирования для кодовых обозначений статуса перевозки</p><br>

<p style="font-weight:500;">VI. Ведение кода</p><br>

<p style="font-weight:500;">VII. Использование</p><br>

<p style="font-weight:500;">Приложение. КОДЫ СТАТУСА ПЕРЕВОЗКИ</p><br>

<p style="text-indent:2em;">В 1992 году в ходе совещаний объединенных групп докладчиков ЭДИФАКТ ООН Объединенная группа по транспорту начала работу 
по согласованию кодов статуса перевозки. Свой вклад в эту работу внесли представители следующих стран: Австралии, Австрии, 
Бельгии, Германии, Дании, Испании, Канады, Нидерландов, Новой Зеландии, Норвегии, Соединенного Королевства, Соединенных Штатов 
Америки, Финляндии, Франции, Швейцарии, Швеции и Японии. Во время этой работы были учтены интересы различных видов транспорта.</p><br>

<p style="text-indent:2em;">Информация об итогах этой работы была представлена Рабочей группе по упрощению процедур международной торговли на ее тридцать 
девятой сессии в марте 1994 года. Рабочая группа назначила специальную группу для подготовки рекомендации о кодах статуса 
перевозки, которая должна была также принять во внимание предложение, представленное делегацией Болгарии в 1991 году в 
отношении классификатора кодов статуса перевозки.</p><br>

<p style="text-indent:2em;">Результаты деятельности этой специальной группы были сообщены Рабочей группе по упрощению процедур международной торговли 
и в принципе одобрены на ее сорок первой сессии в марте 1995 года.</p><br>

<p style="text-indent:2em;">На своей сорок второй сессии в сентябре 1995 года Рабочая группа постановила утвердить
 настоящую Рекомендацию в окончательном виде.</p><br>

<p style="font-weight:500;">РЕКОМЕНДАЦИЯ</p>

<p style="text-indent:2em;"></p><h6>Рабочая группа по упрощению процедур международной торговли на своей сорок второй сессии в сентябре 1995 года 
постановила рекомендовать:</h6><p></p>

<p>1. Участникам международной торговли признать и использовать коды статуса перевозки;</p><br>

<p style="text-indent:2em;">2. Правительствам, соответствующим международным организациям и национальным органам, занимающимся упрощением торговых 
процедур, признать и поощрять использование этих кодов.</p><br>

<p style="text-indent:2em;">На сорок второй сессии присутствовали представители: Австрии, Бельгии, Болгарии, Венгрии, Германии, Дании, Ирландии, 
Исландии, Испании, Италии, Канады, Люксембурга, Мальты, Нидерландов, Норвегии, Российской Федерации, Румынии, Словацкой 
Республики, Соединенного Королевства Великобритании и Северной Ирландии, Соединенных Штатов Америки, Финляндии, Франции, 
Чешской Республики, Швейцарии, Швеции и Эстонии. В соответствии со статьей 11 Круга ведения Комиссии в работе сессии 
участвовали представители Австралии, Бразилии, Габона, Кореи, Нигерии, Сенегала и Японии.</p><br>

<p style="text-indent:2em;">На сессии также присутствовали представители Секретариатов Конференции Организации Объединенных Наций по торговле и 
развитию (ЮНКТАД), Комиссии Организации Объединенных Наций по праву международной торговли (ЮНСИТРАЛ), а также представители 
следующих межправительственных и неправительственных организаций: Центрального бюро международных железнодорожных перевозок 
(ЦБМЖП), Всемирной таможенной организации (ВТО), Международной ассоциации воздушного транспорта (ИАТА), Международной ассоциации 
по кодированию потребительских товаров (ЕАН), Европейской ассоциации электронных сообщений (ЕАЭС), Международной федерации 
транспортно-экспедиторских ассоциаций (ФИАТА), Международной торговой палаты (МТП), Международной конференции служб экспресс-
перевозок (МКСЭП), Международной организации по стандартизации (ИСО), Общества всемирной межбанковской дальней связи для передачи 
финансовой информации (СВИФТ), Международного союза железных дорог (МСЖД), Международной федерации инспекционных 
учреждений (МФИУ).</p><br>

<p style="font-weight:500;">I. История вопроса</p><br>

<p style="text-indent:2em;">1. В международной торговле существует требование в отношении обмена информацией о статусе партий товаров, грузов, транспортного 
оборудования или транспортных средств в определенный момент времени или в конкретном месте в рамках логистической цепи.</p><br>

<p style="text-indent:2em;">2. В качестве средства передачи информации, касающейся движения грузов по транспортной цепи, все больше используется электронный 
обмен данными.</p><br>

<p style="text-indent:2em;">3. Для целей составления маршрутов и отслеживания движения было введено понятие "кодов статуса перевозки" и были разработаны 
сообщения ЭДИФАКТ, содержащие эту информацию.</p><br>

<p style="text-indent:2em;">4. Для обеспечения согласованности при обмене информацией о статусе грузов, партий товаров и / или оборудования необходимо 
использовать общепринятые понятия "статус перевозки" и "обоснование статуса".</p><br>

<p style="text-indent:2em;">5. В результате исследований, которые проводились международными организациями, занимающимися вопросами транспорта, и которые 
охватывали различные виды транспорта, были выработаны следующие определения указанных выше понятий.</p><br>

<p style="text-indent:2em;">Статус перевозки: краткое описание положения и / или состояния партий товаров, грузов и / или оборудования в любой момент 
времени в течение всей перевозки или в любом месте логистической цепи.</p>

<p style="text-indent:2em;"></p><h6>Обоснование статуса: </h6><p></p>

<p>объяснение или обоснование статуса партий товаров, грузов и / или оборудования.</p><br>

<p style="text-indent:2em;">6. Лица, пользующиеся системой кодовых обозначений статуса перевозки, по своему желанию выбирают в целях соблюдения 
коммерческих требований кодовые обозначения, соответствующие статусу перевозки или обоснованию статуса.</p><br>

<p style="font-weight:500;">II. Сфера охвата</p><br>

<p style="text-indent:2em;">7. В настоящей Рекомендации предусматривается система кодирования для кодовых обозначений статуса перевозки.</p><br>

<p style="font-weight:500;">III. Область применения</p><br>

<p style="text-indent:2em;">8. Кодовые обозначения, предусмотренные в настоящей Рекомендации, предназначены для использования в неавтоматизированных 
и / или автоматизированных системах обмена информацией между всеми участниками международной торговли.</p><br>

<p style="font-weight:500;">IV. Справочный материал</p><br>

<p style="text-indent:2em;">9. В настоящем документе приводится ссылка на Справочник элементов внешнеторговых данных ЕЭК ООН (СЭВДООН) и на Справочник 
элементов данных ЭДИФАКТ ЕЭК ООН (СЭДООН).</p><br>

<p style="font-weight:500;">V. Система кодирования для кодовых обозначений статуса перевозки</p><br>

<p>10. Кодовые обозначения и их определения для статуса перевозки приводятся в Приложении к Рекомендации.</p><br>

<p style="font-weight:500;">VI. Ведение кода</p><br>

<p>11. Ведение кодовых обозначений статуса перевозки будет осуществляться Секретариатом ЕЭК ООН.</p><br>

<p style="text-indent:2em;">12. Запросы в отношении новых обозначений кодов, их изменения или отмены следует направлять в Секретариат в соответствии 
с процедурами, касающимися вопросов кодовых обозначений.</p><br>

<p style="font-weight:500;">VII. Использование</p><br>

<p style="text-indent:2em;">13. Кодовые обозначения статуса перевозки могут быть представлены либо путем их краткого описания, либо в форме кодов. 
Для электронного обмена данными рекомендуется использовать кодовую форму.</p><br>

<p style="text-indent:2em;">14. Выбор способа описания, который будет применяться,
 зависит от требований партнеров, ведущих обмен информацией.</p><br>

<p style="text-indent:2em;">15. Партнеры, ведущие обмен информацией, будут определять, какие кодовые обозначения из всего набора кодов следует 
использовать.</p><br>

<p style="font-weight:500;">Приложение КОДЫ СТАТУСА ПЕРЕВОЗКИ</p><br>

<p style="font-weight:500;">1   Прибытие</p><br>

<p>Груз /  партии  товаров / оборудование / транспортные средства
прибыли в пункт назначения.</p><br>

<p style="font-weight:500;">2   Разрешение на погрузку</p><br>

<p>Разрешение на погрузку было дано соответствующей стороной.</p><br>

<p style="font-weight:500;">3   Прибытие в поврежденном состоянии</p><br>

<p style="text-indent:2em;">Груз / партии товаров / оборудование получены или транспортные
средства прибыли в поврежденном состоянии.</p><br>

<p style="font-weight:500;">4   Поврежденное оборудование возвращено в эксплуатацию</p><br>

<p>Оборудование, ранее  находившееся  в  "поврежденном состоянии"
возвращено в эксплуатацию.</p><br>

<p style="font-weight:500;">5   Начало операции</p><br>

<p>Операция была начата.</p><br>

<p style="font-weight:500;">6   Резервация</p><br>

<p style="text-indent:2em;">Грузы / партии товаров / оборудование или средства  транспорта
были зарезервированы для последующей перевозки.</p><br>

<p style="font-weight:500;">7   Резервация отменена</p><br>

<p>Резервация грузов  / партий товаров / оборудования или средств
транспорта была отменена.</p><br>

<p style="font-weight:500;">8   Ограничения на ввоз сняты</p><br>

<p style="text-indent:2em;">Грузы /  партии  товаров  /   оборудование,   задержанные   по
соображениям ограничения на ввоз, были допущены к ввозу.</p><br>

<p style="font-weight:500;">9   Ограничения на вывоз сняты</p><br>

<p>Грузы /   партии   товаров   /  оборудование,  задержанные  до
погрузки, прошли таможенную очистку для вывоза.</p><br>

<p style="font-weight:500;">10  Разрешение   компетентных   органов,   занимающихся  вопросами
сельскохозяйственной, пищевой и рыбной продукции, получено</p><br>

<p style="text-indent:2em;">Грузы /   партии   товаров   /  оборудование,  задержанные  до
отправки,  получили разрешение на отправку после  их  проверки
соответствующим органом.</p><br>

<p style="font-weight:500;">11  Разрешение портовых властей получено</p><br>

<p style="text-indent:2em;">Портовые власти  дали  разрешение на то,  чтобы грузы / партии
товаров / оборудование / транспортные средства покинули порт.</p><br>

<p style="font-weight:500;">12  Таможенная очистка пройдена</p><br>

<p>Грузы / партии товаров для ввоза /  вывоза  прошли  таможенную
очистку.</p><br>

<p style="font-weight:500;">13  Получение</p><br>

<p>Грузы /  партии товаров / оборудование были получены в заранее
установленном пункте.</p><br>

<p style="font-weight:500;">14  Операция завершена</p><br>

<p>Операция была завершена.</p><br>

<p style="font-weight:500;">15  Консолидация</p><br>

<p style="text-indent:2em;">Грузы / партии товаров были объединены  с  другими  грузами  /
партиями товаров для формирования более крупной партии товаров
и / или были погружены в один или более контейнеров / грузовых
мест.</p><br>

<p style="font-weight:500;">16  Граница пересечена</p><br>

<p>Грузы /  партии  товаров  / оборудование пересекли границу при
движении из одной страны в другую.</p><br>

<p style="font-weight:500;">17  Отказ таможни</p><br>

<p>Таможенные органы  отказали  в  таможенной  очистке  грузов  /
партии товаров / оборудования.</p><br>

<p style="font-weight:500;">18  Повреждение в ходе перевозки</p><br>

<p>Грузы /  партии  товаров / оборудование были повреждены в ходе
перевозки.</p><br>

<p style="font-weight:500;">19  Оценка поврежденного оборудования проведена</p><br>

<p style="text-indent:2em;">Поврежденное оборудование  было  оценено,  и   соответствующая
оценочная ведомость была направлена арендодателю.</p><br>

<p style="font-weight:500;">20  Задержаны в ходе перевозки</p><br>

<p>Груз /  партии  товаров  /  оборудование были задержаны в ходе
перевозки.</p><br>

<p style="font-weight:500;">21  Доставка</p><br>

<p>Груз / партии товаров / оборудование были доставлены  в  пункт
назначения / стороне в транспортной цепи.</p><br>

<p style="font-weight:500;">22  Доставка завершена в соответствии с указаниями</p><br>

<p>Доставка груза  / партий товаров / оборудования была завершена
в соответствии с указаниями.</p><br>

<p style="font-weight:500;">23  Доставка не завершена.</p><br>

<p>Доставка груза  /  партий  товаров  /  оборудования  не   была
завершена.</p><br>

<p style="font-weight:500;">24  Транспортные средства отправлены</p><br>

<p>Транспортные средства отправлены из соответствующего пункта  в
транспортной цепи.</p><br>

<p style="font-weight:500;">25  Задержка отправления</p><br>

<p>Перевозка была   задержана  при  отправлении  соответствующего
транспортного средства.</p><br>

<p style="font-weight:500;">26  Оборудование снято с первоочередной обработки</p><br>

<p>Оборудование было  снято   с   первоочередной   обработки   на
транспортном средстве.</p><br>

<p style="font-weight:500;">27  Отправление</p><br>

<p>Груз /  партии  товаров,  оборудование  были   отправлены   из
соответствующего пункта в транспортной цепи.</p><br>

<p style="font-weight:500;">28  Выгрузка</p><br>

<p style="text-indent:2em;">Груз /   партии  товаров  /  оборудование  были  выгружены  из
транспортного оборудования, в котором они перевозились.</p><br>

<p style="font-weight:500;">29  Разгрузка</p><br>

<p>Груз /  партии  товаров  /  оборудование  были  разгружены   с
транспортного средства.</p><br>

<p style="font-weight:500;">30  Порожние при осмотре</p><br>

<p>При проведении осмотра упаковка / оборудование были порожними.</p><br>

<p style="font-weight:500;">31  В пути</p><br>

<p>Груз /  партии  товаров  /  оборудование  находятся в процессе
перевозки в следующий пункт назначения.</p><br>

<p style="font-weight:500;">32  Оборудование, поступившее из ремонта</p><br>

<p>Оборудование было получено после его отправки в ремонт.</p><br>

<p style="font-weight:500;">33  Оборудование, отправленное в ремонт</p><br>

<p>Оборудование было отправлено в ремонт.</p><br>

<p style="font-weight:500;">34  Оборудование отремонтировано</p><br>

<p>Поврежденное оборудование было отремонтировано.</p><br>

<p style="font-weight:500;">35  Отправление в пункт назначения</p><br>

<p style="text-indent:2em;">Груз /  партии  товаров  /  оборудование  были  отправлены   в
следующий /  окончательный  пункт  назначения  в  транспортной
цепи.</p><br>

<p style="font-weight:500;">36  Грузы не обнаружены</p><br>

<p>Местоположение груза / партий товаров / оборудования,  которые
находятся в розыске, не обнаружено.</p><br>

<p style="font-weight:500;">37  Грузы обнаружены</p><br>

<p style="text-indent:2em;">Местоположение груза / партий товаров / оборудования,  которые
ранее находились в розыске, в настоящее время обнаружено.</p><br>

<p style="font-weight:500;">38  Фрахт оплачен</p><br>

<p>Плата за перевозку  груза  /  партий  товаров  /  оборудования
произведена.</p><br>

<p style="font-weight:500;">39  С таможенного склада</p><br>

<p>Груз /  партии товаров / оборудование были перевезены / готовы
к перевозке с таможенного склада.</p><br>

<p style="font-weight:500;">40  Груз / партии товаров / оборудование в порту</p><br>

<p>Груз / партии товаров / оборудование прибыли в порт /  имеются
в порту.</p><br>

<p style="font-weight:500;">41  Передача</p><br>

<p style="text-indent:2em;">Груз /  партии  товаров  /  оборудование  формально перешли из
одной  точки  в  транспортной   цепи   в   другую   в   рамках
ответственности  одного  и  того  же перевозчика.  (См.  также
"доставлены с передачей".)</p><br>

<p style="font-weight:500;">42  Доставлены с передачей</p><br>

<p style="text-indent:2em;">Груз /  партии  товаров  /  оборудование  перешли  от   одного
транспортного  оператора  к  другому  транспортному оператору.
(См. также "передача".)</p><br>

<p style="font-weight:500;">43  Получены с передачей</p><br>

<p style="text-indent:2em;">Груз / партии  товаров  /  оборудование  были  получены  одним
транспортным оператором от другого транспортного оператора.</p><br>

<p style="font-weight:500;">44  Маршрут  партии  товаров,  отправленной  не по адресу, изменен</p><br>

<p style="text-indent:2em;">Груз /  партии  товаров  /  оборудование,  которые  ранее были
направлены не по адресу,  находятся в пути в надлежащий  пункт
назначения.</p><br>

<p style="font-weight:500;">45  Грузополучатель проинформирован о прибытии груза</p><br>

<p style="text-indent:2em;">Грузополучатель был   официально  проинформирован  о  прибытии
груза / партий товаров  /  оборудования  в  промежуточный  или
окончательный пункт назначения.</p><br>

<p style="font-weight:500;">46  На таможенный склад</p><br>

<p>Груз /  партии товаров / оборудование были перевезены / готовы
к перевозке на таможенный склад.</p><br>

<p style="font-weight:500;">47  На склад для небольших грузовых мест</p><br>

<p style="text-indent:2em;">Груз / партии товаров / оборудование были перевезены на  склад
для   небольших   грузовых   мест   (грузовое   место   меньше
контейнера).</p><br>

<p style="font-weight:500;">48  Грузы погружены</p><br>

<p>Груз /  партии  товаров  /  оборудование  были  погружены   на
транспортное средство.</p><br>

<p style="font-weight:500;">49  Грузы утеряны</p><br>

<p>Груз /  партии  товаров / оборудование утеряны в ходе движения
по транспортной цепи.</p><br>

<p style="font-weight:500;">50  Грузы включены в грузовой манифест</p><br>

<p style="text-indent:2em;">Груз / партии товаров / оборудование были  включены  в  список
предметов, перевозимых из одного места в другое в транспортной
цепи.</p><br>

<p style="font-weight:500;">51  Реализация</p><br>

<p>Грузовая партия была реализована.</p><br>

<p style="font-weight:500;">52  Грузы не вывезены</p><br>

<p style="text-indent:2em;">Груз / партии товаров / оборудование,  которые, как ожидалось,
должны   быть   вывезены   и  направлены  из  одного  места  в
транспортной цепи в другое, не были вывезены.</p><br>

<p style="font-weight:500;">53  Грузы не обнаружены</p><br>

<p style="text-indent:2em;">Груз / партии товаров / оборудование, местоположение которых в
транспортной  цепи,  как ожидалось,  должно быть известно,  не
обнаружены.</p><br>

<p style="font-weight:500;">54  Грузы не востребованы</p><br>

<p style="text-indent:2em;">Груз / партии товаров / оборудование,  которые, как ожидалось,
должны  быть  востребованы  после  перевозки,  востребованы не
были.</p><br>

<p style="font-weight:500;">55  Грузы не доставлены</p><br>

<p>Груз / партии товаров / оборудование не были доставлены.</p><br>

<p style="font-weight:500;">56  Грузы не погружены</p><br>

<p style="text-indent:2em;">Груз / партии товаров / оборудование, которые должны были быть
погружены  на  транспортное  средство  в  ходе  предполагаемой
перевозки, не были погружены.</p><br>

<p style="font-weight:500;">57  Аренда прекращена</p><br>

<p style="text-indent:2em;">Оборудование, ранее  арендованное  клиентом,  было  возвращено
арендодателю, и договор аренды был прекращен.</p><br>

<p style="font-weight:500;">58  Преждевременная разгрузка</p><br>

<p style="text-indent:2em;">Груз /  партии  товаров  / оборудование,  ранее погруженные на
транспортное средство,  были сняты с транспортного средства до
завершения планируемой перевозки.</p><br>

<p style="font-weight:500;">59  В аренде</p><br>

<p>Оборудование было арендовано арендатором / клиентов.</p><br>

<p style="font-weight:500;">60  Предъявленные претензии урегулированы</p><br>

<p>Претензии, предъявленные  в отношении груза / партий товаров /
оборудования, были урегулированы.</p><br>

<p style="font-weight:500;">61  Грузы выгружены несвоевременно</p><br>

<p style="text-indent:2em;">Груз /  партии  товаров  /  оборудование  были   выгружены   с
транспортного  средства  в указанном месте с нарушением сроков
выгрузки.</p><br>

<p style="font-weight:500;">62  Грузовое место не готово</p><br>

<p>Грузовое место не было готово для вывоза.</p><br>

<p style="font-weight:500;">63  Ожидание вывоза</p><br>

<p>Груз / партии товаров / оборудование ожидают вывоза.</p><br>

<p style="font-weight:500;">64  Оборудование подключено</p><br>

<p>Оборудование, например   контейнер   -   рефрижератор,    было
подключено к источнику питания.</p><br>

<p style="font-weight:500;">65  Грузы разворованы</p><br>

<p>Груз / партии товаров / оборудование были разворованы.</p><br>

<p style="font-weight:500;">66  Груз / партии товаров / оборудование размещены</p><br>

<p>Груз /   партии  товаров  /  оборудование  были  размещены  на
транспортном средстве.</p><br>

<p style="font-weight:500;">67  Направление информации до перевозки</p><br>

<p>Грузоотправитель / грузополучатель был заранее проинформирован
о перевозке.</p><br>

<p style="font-weight:500;">68  Грузы списаны</p><br>

<p>Груз /   партии   товаров   /   оборудование  были  списаны  и
ликвидированы.</p><br>

<p style="font-weight:500;">69  Приоритетное оборудование</p><br>

<p>Оборудование было  обработано  в  первоочередном   порядке   и
установлено на транспортном средстве.</p><br>

<p style="font-weight:500;">70  Грузы готовы к перевозке</p><br>

<p>Груз /  партии  товаров  /  оборудование  были  подготовлены к
перевозке.</p><br>

<p style="font-weight:500;">71  Полностью подтвержденное получение</p><br>

<p>Грузополучатель полностью подтвердил получение груза.</p><br>

<p style="font-weight:500;">72  Частично подтвержденное получение</p><br>

<p>Грузополучатель не подтвердил полностью получение груза.</p><br>

<p style="font-weight:500;">73  Грузы получены</p><br>

<p style="text-indent:2em;">Груз / партии товаров / оборудование были получены в  том  или
ином месте / одним из участников транспортной цепи.</p><br>

<p style="font-weight:500;">74  Грузы переданы</p><br>

<p style="text-indent:2em;">Груз /  партии  товаров / оборудование были переданы одному из
участников  перевозки,  за  исключением  первоначального   или
последующего грузополучателя.</p><br>

<p style="font-weight:500;">75  Переадресовка по требованию</p><br>

<p style="text-indent:2em;">Груз /  партии  товаров / оборудование,  отправка которых была
задержана,  будут  /   были   переадресованы   по   требованию
соответствующего органа.</p><br>

<p style="font-weight:500;">76  Отказ</p><br>

<p>В перевозке / в приеме документации было отказано.</p><br>

<p style="font-weight:500;">77  Выдача</p><br>

<p>Груз /    партии    товаров   /   оборудование   были   выданы
соответствующему участнику перевозки.</p><br>

<p style="font-weight:500;">78  Повторная погрузка</p><br>

<p>Груз / партии товаров / оборудование были вновь  погружены  на
транспортное средство.</p><br>

<p style="font-weight:500;">79  Возвращение в соответствии с указанием</p><br>

<p>Груз /  партии товаров / оборудование были возвращены в то или
иное место в соответствии с указаниями.</p><br>

<p style="font-weight:500;">80  Возвращение в поврежденном состоянии</p><br>

<p style="text-indent:2em;">Груз /  партии  товаров  /  оборудование  были  возвращены   в
поврежденном  состоянии  в  то или иное место в соответствии с
указаниями.</p><br>

<p style="font-weight:500;">81  Грузы возвращены</p><br>

<p>Груз / партии товаров / оборудование были возвращены в  пункт,
где они находились ранее.</p><br>

<p style="font-weight:500;">82  Оборудование опечатано</p><br>

<p>Оборудование было опечатано в соответствии с эксплуатационными
и государственными требованиями.</p><br>

<p style="font-weight:500;">83  Перевозка оформлена</p><br>

<p>Перевозка была оформлена в связи с  транспортировкой  груза  /
партий товаров / оборудования.</p><br>

<p style="font-weight:500;">84  Разгрузка не произведена</p><br>

<p style="text-indent:2em;">Груз / партии товаров / оборудование,  которые, как ожидалось,
должны быть разгружены с транспортного  средства  в  указанном
месте, не были разгружены.</p><br>

<p style="font-weight:500;">85  Отгрузка не произведена</p><br>

<p style="text-indent:2em;">Груз / партии товаров / оборудование,  которые, как ожидалось,
должны  быть  отправлены  в  конкретный  пункт  назначения  на
соответствующем  транспортном  средстве  из конкретного пункта
отправления, не были погружены для последующей доставки.</p><br>

<p style="font-weight:500;">86  Ошибка в сортировке</p><br>

<p style="text-indent:2em;">Груз /   партии   товаров   /   оборудование   были   ошибочно
отсортированы для перевозки по непредусмотренному маршруту.</p><br>

<p style="font-weight:500;">87  Разбивка партии</p><br>

<p>Партия товаров была разбита на две или более партии.</p><br>

<p style="font-weight:500;">88  Обработка паром</p><br>

<p>Оборудование, например контейнер, было обработано паром.</p><br>

<p style="font-weight:500;">89  Перевозка прекращена</p><br>

<p>Дальнейшая перевозка  груза / партий товаров / оборудования по
транспортной цепи была прекращена.</p><br>

<p style="font-weight:500;">90  Складирование</p><br>

<p>Груз / партии товаров / оборудование были складированы  в  том
или ином месте.</p><br>

<p style="font-weight:500;">91  Грузы уложены</p><br>

<p style="text-indent:2em;">Груз /   партии   товаров  /  оборудование  были  уложены  для
перевозки в специально отобранном оборудовании /  транспортном
средстве.</p><br>

<p style="font-weight:500;">92  Загрузка</p><br>

<p>Груз /   партии   товаров  /  оборудование  были  загружены  в
соответствующее оборудование, например в контейнер.</p><br>

<p style="font-weight:500;">93  Грузы загружены и опечатаны</p><br>

<p style="text-indent:2em;">Груз /  партии  товаров  /  оборудование  были   загружены   в
соответствующее транспортное оборудование,  и это оборудование
было опечатано.</p><br>

<p style="font-weight:500;">94  Оборудование отдано в субаренду</p><br>

<p>Арендодатель был   уведомлен    о    передаче    арендованного
оборудования в субаренду.</p><br>

<p style="font-weight:500;">95  Оборудование получено из субаренды</p><br>

<p>Арендатор направил   уведомление   о   прекращении   субаренды
арендованного оборудования.</p><br>

<p style="font-weight:500;">96  Оцененный ущерб</p><br>

<p style="text-indent:2em;">Поврежденные груз  /  партии  товаров  /   оборудование   были
официально  осмотрены  третьей  стороной  для  оценки величины
ущерба.</p><br>

<p style="font-weight:500;">97  Грузы перевезены</p><br>

<p>Груз /  партии  товаров  /  оборудование  были  перевезены  из
другого пункта.</p><br>

<p style="font-weight:500;">98  Грузы вывезены</p><br>

<p>Груз /  партии  товаров  / оборудование были вывезены в другой
пункт.</p><br>

<p style="font-weight:500;">99  Перевалка</p><br>

<p>Груз / партии  товаров  /  оборудование  были  перегружены  на
другое транспортное средство.</p><br>

<p style="font-weight:500;">100 Задержка транзита</p><br>

<p>Транзитная перевозка  груза  /  партий  товаров / оборудования
была задержана.</p><br>

<p style="font-weight:500;">101 Грузы неизвестны</p><br>

<p>Груз / партии товаров / оборудование не известны  респонденту,
которому направлен запрос.</p><br>

<p style="font-weight:500;">102 Оборудование отключено</p><br>

<p>Оборудование, например    контейнер   -   рефрижератор,   было
отключено от источника питания в указанном пункте.</p><br>

<p style="font-weight:500;">103 Отмена перевозки с изложением причин переноса сроков</p><br>

<p style="text-indent:2em;">Доставка груза   была   отменена   грузополучателем,   который
потребовал осуществить доставку груза на других условиях.</p><br>

<p style="font-weight:500;">104 Перевозка отложена</p><br>

<p>Временная отмена перевозки части или всей партии груза.</p><br>

<p style="font-weight:500;">105 Перевозка отменена</p><br>

<p>Окончательная отмена перевозки части или всей партии груза.</p><br>

<p style="font-weight:500;">106 Нарушения, обнаруженные при доставке</p><br>

<p style="text-indent:2em;">При доставке   были  обнаружены  нарушения,  и  в  этой  связи
требуется представить дополнительную информацию путем указания
причин или кодовых обозначений.</p><br>

<p style="font-weight:500;">107 Рекламация, претензии при доставке</p><br>

<p style="text-indent:2em;">При прибытии  груза  была  подана  рекламация,  и в этой связи
требуется представить дополнительную информацию путем указания
причин или кодовых обозначений.</p><br>

<p style="font-weight:500;">108 Доставка невозможна, уведомление о задержке доставки передано</p><br>

<p>Доставка не  была  завершена;  уведомление о задержке доставки
было передано грузополучателю.</p><br>

<p style="font-weight:500;">109 Доставка   невозможна,  уведомление  о  задержке  доставки  не
передано</p><br>

<p>Доставка не  была  завершена;  уведомление о задержке доставки
грузополучателю не передано.</p><br>

<p style="font-weight:500;">110 Перевозка завершена</p><br>

<p>Перевозка транспортного средства,  на котором находился  груз,
завершена.</p><br>

<p style="font-weight:500;">111 Груз находится в распоряжении грузополучателя</p><br>

<p style="text-indent:2em;">Партия товаров  или  грузовое  место  находится в распоряжении
грузополучателя в соответствии  с  условиями  договора  или  в
результате первой не состоявшейся операции,  для осуществления
которой требуются более конкретные указания.</p><br>

<p style="font-weight:500;">112 В процессе доставки</p><br>

<p style="text-indent:2em;">Груз / партии товаров находятся в процессе  доставки.  Никакие
проблемы  не  обнаружены,  информация  о  завершении  процесса
доставки еще не поступала.</p><br>

<p style="font-weight:500;">113 Груз принят с оговоркой о его дальнейшем осмотре</p><br>

<p style="text-indent:2em;">Груз принят с оговоркой о его дальнейшем  осмотре,  результаты
которого   будут   сообщены   позже   с   указанием   кодового
обозначения,  обосновывающего проведение этого  осмотра  (либо
будет   передано  текстовое  сообщение,  если  соответствующее
кодовое обозначение отсутствует).</p><br>

<p style="font-weight:500;">114 Несоответствие в описании груза / оборудования</p><br>

<p>Описание груза / оборудования, которое было передано ранее, не
соответствует реальному положению.</p><br>

<p style="font-weight:500;">115 Операцию по вывозу осуществить не удалось</p><br>

<p>Вывезти груз / партии товаров / оборудование не удалось.</p><br>

<p style="font-weight:500;">116 Отсутствие грузов</p><br>

<p>Указанные грузы / партии товаров / оборудование отсутствуют.</p><br>

<p style="font-weight:500;">117 На палубе</p><br>

<p>Грузы /  партии товаров / оборудование уложены на палубе / над
палубой.</p><br>

<p style="font-weight:500;">118 Местонахождение определить невозможно.</p><br>

<p>Местонахождение груза  /   партий   товаров   /   оборудования
определить невозможно.</p><br>

<p style="font-weight:500;">119 В неприемлемом состоянии</p><br>

<p>Грузы /   партии   товаров   /   оборудование   находились   в
неприемлемом состоянии в момент доставки / вывоза.</p><br>

<p style="font-weight:500;">120 Под палубой</p><br>

<p>Грузы / партии товаров / оборудование размещены под палубой  /
ниже палубы.</p><br>

<p style="font-weight:500;">121 Вывоз</p><br>

<p>Грузы /  партии  товаров  /  оборудование  были  вывезены  или
получены от грузоотправителя.</p><br>

<p style="font-weight:500;">122 Документы о доставке груза выданы</p><br>

<p>Все документы,    сопровождающие     груз,     были     выданы
грузополучателю или его агенту.</p><br>

<p style="font-weight:500;">123 Предполагаемая передача</p><br>

<p style="text-indent:2em;">Партию товаров   предполагается   передать  от  одного  агента
другому или для осуществления другой транспортной операции.</p><br>

<p style="font-weight:500;">124 Статус отсутствует</p><br>

<p>Статус грузов / партий товаров / оборудования отсутствует.</p><br>

<p style="font-weight:500;">125 Таможенная очистка проводится</p><br>

<p style="text-indent:2em;">Информация, необходимая    таможням    для    предварительного
ознакомления  с  грузом,  получена  и  может  быть передана на
рассмотрение.</p><br>

<p style="font-weight:500;">126 Груз   /   партии   товаров   /   оборудование  отправлены  на
транспортном средстве</p><br>

<p>Груз /   партии   товаров   /   оборудование   отправлены   на
транспортном средстве.</p><br>

<p style="font-weight:500;">127 Ожидание разрешения таможни на ввоз</p><br>

<p style="text-indent:2em;">Груз /  партии  товаров   /   оборудование,   задержанные   по
соображениям  ограничения импорта,  ожидают разрешения таможни
на ввоз.</p><br>

<p style="font-weight:500;">128 Перевозка под контролем таможни</p><br>

<p style="text-indent:2em;">Процедура таможенного  досмотра  будет  проводиться  в пункте,
расположенном внутри страны,  или в другом  пункте,  но  не  в
пункте пересечения границы.</p><br>

<p style="font-weight:500;">129 Партия товаров получена от грузоотправителя</p><br>

<p>Партия товаров была получена от грузоотправителя.</p><br>

<p style="font-weight:500;">130 Партия товаров будет передана другому перевозчику</p><br>

<p>Партия товаров  будет   /   должна   быть   передана   другому
перевозчику.</p><br>

<p style="font-weight:500;">131 Погрузка</p><br>

<p>Груз / партии товаров / оборудование грузятся на  транспортное
средство.</p><br>

<p style="font-weight:500;">132 Погрузка с соблюдением специальных условий</p><br>

<p style="text-indent:2em;">Операции, связанные с соблюдением специальных условий погрузки
груза   /   партий  товаров  /  оборудования  на  транспортное
средство.</p><br>

<p style="font-weight:500;">133 Посадка пассажиров</p><br>

<p>Операции, связанные с посадкой пассажиров.</p><br>

<p style="font-weight:500;">134 Разгрузка</p><br>

<p>Груз /  партии   товаров   /   оборудование   разгружаются   с
транспортного средства.</p><br>

<p style="font-weight:500;">135 Разгрузка с соблюдением специальных условий</p><br>

<p style="text-indent:2em;">Операции, связанные    с   соблюдением   специальных   условий
разгрузки  груза   /   партий   товаров   /   оборудования   с
транспортного средства.</p><br>

<p style="font-weight:500;">136 Высадка пассажиров</p><br>

<p>Операции, связанные с высадкой пассажиров.</p><br>

<p style="font-weight:500;">137 Изменение позиции</p><br>

<p>Операции, связанные   с   изменением   позиции   транспортного
средства.</p><br>

<p style="font-weight:500;">138 Маневрирование при вхождении в порт</p><br>

<p>Операции, связанные с вхождением в портовую зону.</p><br>

<p style="font-weight:500;">139 Маневрирование при выходе из порта</p><br>

<p>Операции, связанные с выходом из портовой зоны.</p><br>

<p style="font-weight:500;">140 Маневрирование при смене мест в порту</p><br>

<p>Операции, связанные с движением судна с одного места на другое
в пределах портовой зоны.</p><br>

<p style="font-weight:500;">141 Подготовка для обработки транспортного средства</p><br>

<p>Операции, связанные с подготовкой для обработки  транспортного
средства.</p><br>

<p style="font-weight:500;">142 Загрузка балласта</p><br>

<p>Операции, связанные с загрузкой балласта.</p><br>

<p style="font-weight:500;">143 Разгрузка балласта</p><br>

<p>Операции, связанные с разгрузкой балласта.</p><br>

<p style="font-weight:500;">144 Подготовка места для груза</p><br>

<p>Операции, связанные   с   подготовкой   места   для  груза  на
транспортном средстве.</p><br>

<p style="font-weight:500;">145 Пополнение запасов</p><br>

<p style="text-indent:2em;">Операции, связанные  с  пополнением  запасов  топлива,   воды,
продуктов  питания  и  масел,  поставляемых  для транспортного
средства.</p><br>

<p style="font-weight:500;">146 Санитарные операции</p><br>

<p style="text-indent:2em;">Операции, связанные  с  обеспечением   надлежащих   санитарных
условий и условий здоровья на транспортном средстве.</p><br>

<p style="font-weight:500;">147 Фумигация</p><br>

<p style="text-indent:2em;">Операции, связанные     с    дезинфекцией    и    дератизацией
транспортного средства с помощью ядовитого дыма, используемого
для   борьбы   с   насекомыми,   крысами  и  другими  опасными
организмами.</p><br>

<p style="font-weight:500;">148 Дезинфекция</p><br>

<p>Операции, связанные  с  ликвидацией   опасных   насекомых   на
транспортном средстве.</p><br>

<p style="font-weight:500;">149 Дератизация</p><br>

<p>Операции, связанные с уничтожением крыс на судне.</p><br>

<p style="font-weight:500;">150 Дегазация</p><br>

<p>Операции, связанные   с  очисткой  транспортного  средства  от
опасных или ядовитых газов и веществ.</p><br>

<p style="font-weight:500;">151 Набор экипажа</p><br>

<p>Операции, связанные  с  набором  экипажа   для   транспортного
средства.</p><br>

<p style="font-weight:500;">152 Спасательные операции</p><br>

<p>Операции, связанные с проведением спасательных работ.</p><br>

<p style="font-weight:500;">153 Погрузка вдоль борта</p><br>

<p>Операции, связанные с погрузкой грузов на судно, расположенное
бортом к причалу.</p><br>

<p style="font-weight:500;">154 Погрузка на борт</p><br>

<p style="text-indent:2em;">Операции, связанные с погрузкой грузов  на  борт,  когда  одно
судно   расположено   бортом   к   причалу,   а  другое  судно
пришвартовано вдоль борта.</p><br>

<p style="font-weight:500;">155 Погрузка на якоре</p><br>

<p>Операции, связанные с погрузкой грузов на  борт,  когда  судно
находится на якоре.</p><br>

<p style="font-weight:500;">156 Разгрузка на пристань</p><br>

<p>Операции, связанные  с разгрузкой грузов с судна,  когда судно
расположено бортом к причалу.</p><br>

<p style="font-weight:500;">157 Разгрузка на борт</p><br>

<p style="text-indent:2em;">Операции, связанные с разгрузкой грузов с  судна,  когда  одно
судно   расположено   бортом   к   причалу,   а  другое  судно
пришвартовано вдоль борта.</p><br>

<p style="font-weight:500;">158 Разгрузка на якоре</p><br>

<p>Операции, связанные с разгрузкой грузов с судна,  когда  судно
находится на якоре.</p><br>

<p style="font-weight:500;">159 Одновременная погрузка и разгрузка</p><br>

<p style="text-indent:2em;">Операции, связанные   с   одновременной  погрузкой  грузов  на
транспортное средство  и  разгрузкой  грузов  с  транспортного
средства.</p><br>

<p style="font-weight:500;">160 Стоянка с целью ожидания времени, указанного в расписании</p><br>

<p style="text-indent:2em;">Операции, связанные  с  периодом  времени,  в течение которого
транспортное средство  дожидается  обработки  в  сроки  (день,
час), указанные в расписании.</p><br>

<p style="font-weight:500;">161 Технические операции</p><br>

<p>Технические операции на транспортном средстве.</p><br>

<p style="font-weight:500;">162 Формальности</p><br>

<p>Операции, связанные   с   очисткой   документов  транспортного
средства.</p><br>

<p style="font-weight:500;">163 Таможенные формальности</p><br>

<p style="text-indent:2em;">Операции, связанные  с   таможенным   контролем   и   очисткой
таможенных документов, относящихся к транспортному средству.</p><br>

<p style="font-weight:500;">164 Медицинский контроль</p><br>

<p>Операции, связанные   с   санитарным  контролем  и  касающиеся
транспортного средства и его экипажа.</p><br>

<p style="font-weight:500;">165 Подготовка для погрузки</p><br>

<p>Операции, связанные  с  подготовкой  для  погрузки  груза   на
транспортное средство.</p><br>

<p style="font-weight:500;">166 Подготовка для разгрузки</p><br>

<p>Операции, связанные   с  подготовкой  для  разгрузки  груза  с
транспортного средства.</p><br>

<p style="font-weight:500;">167 Разгрузка грязного балласта</p><br>

<p>Операции, связанные с разгрузкой грязного балласта,  отходов и
т.д. с транспортного средства.</p><br>

<p style="font-weight:500;">168 Оформление документов по реестру отгруженных грузов</p><br>

<p>Операции, связанные   с   оформлением   документов   судна  по
соответствующему реестру отгруженных грузов.</p><br>

<p style="font-weight:500;">169 Оформление документации по аварии</p><br>

<p>Операции, связанные с оформлением документов для транспортного
средства по аварии.</p><br>

<p style="font-weight:500;">170 Ожидание на вход или выход</p><br>

<p>Вход или отправление задерживаются.</p><br>

<p style="font-weight:500;">171 Ожидание лоцмана</p><br>

<p>Ожидание свободного лоцмана.</p><br>

<p style="font-weight:500;">172 Ожидание буксира</p><br>

<p>Ожидание свободного буксира.</p><br>

<p style="font-weight:500;">173 Ожидание светлой части суток</p><br>

<p>Отправление или  вход  осуществляются только в течение светлой
части суток.</p><br>

<p style="font-weight:500;">174 Ожидание, вызванное метеорологическими условиями</p><br>

<p>Ожидание, вызванное метеорологическими условиями.</p><br>

<p style="font-weight:500;">175 Ожидание формирования состава</p><br>

<p>Ожидание формирования состава.</p><br>

<p style="font-weight:500;">176 Ожидание действий со стороны компетентных органов</p><br>

<p>Ожидание действий,    которые    должны    быть    предприняты
компетентными органами.</p><br>

<p style="font-weight:500;">177 Ожидание, вызванное запрещением</p><br>

<p>Ожидание, вызванное запрещением.</p><br>

<p style="font-weight:500;">178 Ожидание очередности, определяемой компетентными органами</p><br>

<p>Ожидание очередности, определяемой компетентными органами.</p><br>

<p style="font-weight:500;">179 Ожидание, вызванное действиями компетентных органов</p><br>

<p>Ожидание, вызванное действиями компетентных органов.</p><br>

<p style="font-weight:500;">180 Ожидание, вызванное условиями чартера</p><br>

<p>Ожидание, вызванное условиями, указанными в чартере.</p><br>

<p style="font-weight:500;">181 Ожидание места</p><br>

<p>Ожидание свободного места.</p><br>

<p style="font-weight:500;">182 Груз отсутствует</p><br>

<p>Ожидание, вызванное отсутствием груза.</p><br>

<p style="font-weight:500;">183 Рабочие отсутствуют</p><br>

<p>Ожидание, вызванное отсутствием рабочих.</p><br>

<p style="font-weight:500;">184 Место для складирования груза отсутствует</p><br>

<p>Ожидание, вызванное    нехваткой    свободного    места    для
складирования груза.</p><br>

<p style="font-weight:500;">185 Ожидание оборудования</p><br>

<p>Ожидание, вызванное отсутствием свободного оборудования.</p><br>

<p style="font-weight:500;">186 Ожидание других транспортных средств</p><br>

<p>Ожидание, вызванное отсутствием других транспортных средств.</p><br>

<p style="font-weight:500;">187 Оборудование для обработки отсутствует</p><br>

<p>Ожидание, вызванное отсутствием  свободного  оборудования  для
погрузки и разгрузки.</p><br>

<p style="font-weight:500;">188 Оборудование для обработки на борту отсутствует</p><br>

<p>Ожидание, вызванное    отсутствием    на    борту   свободного
оборудования для погрузки и разгрузки.</p><br>

<p style="font-weight:500;">189 Груз не готов для погрузки</p><br>

<p>Ожидание, вызванное   неготовностью   груза   для  погрузки  /
обработки.</p><br>

<p style="font-weight:500;">190 Транспортное средство не готово для грузовых операций</p><br>

<p>Ожидание, вызванное  тем,  что транспортное средство не готово
для погрузки или обработки.</p><br>

<p style="font-weight:500;">191 Груз поврежден</p><br>

<p>Ожидание, вызванное повреждением груза.</p><br>

<p style="font-weight:500;">192 Периоды нерабочего времени</p><br>

<p>Ожидание, вызванное наступлением периода нерабочего времени.</p><br>

<p style="font-weight:500;">193 Ремонт и / или техническое обслуживание транспортного средства</p><br>

<p>Ожидание, вызванное ремонтом и / или техническим обслуживанием
транспортного средства.</p><br>

<p style="font-weight:500;">194 Ремонт  и  /  или  техническое  обслуживание  оборудования для
обработки  грузов</p><br>

<p>Ожидание, вызванное ремонтом и / или техническим обслуживанием
оборудования для обработки грузов.</p><br>

<p style="font-weight:500;">195 Ожидание прохождения встречного конвоя судов</p><br>

<p>Ожидание, вызванное проходом встречного конвоя судов.</p><br>

<p style="font-weight:500;">196 Адрес за пределами зоны доставки</p><br>

<p>Указанный адрес  доставки  не  охватывается   зоной   действия
оператора / перевозчика.</p><br>

<p style="font-weight:500;">197 После отправления транспортного средства</p><br>

<p>Груз / партии товаров / оборудование прибыли после отправления
транспортного средства.</p><br>

<p style="font-weight:500;">198 Отказ агента</p><br>

<p>Агент грузополучателя отказался принимать доставленный груз.</p><br>

<p style="font-weight:500;">199 Замена печатей</p><br>

<p>Печати на оборудовании отличаются от тех, которые были указаны
в уведомлении.</p><br>

<p style="font-weight:500;">200 Доставка включена в график</p><br>

<p>Были приняты меры по  доставке  груза  в  конкретно  указанное
время.</p><br>

<p style="font-weight:500;">201 Неудачная попытка доставки груза</p><br>

<p>Доставку груза / партий товаров / оборудования осуществить  не
удалось.</p><br>

<p style="font-weight:500;">202 Предприятие закрыто</p><br>

<p style="text-indent:2em;">Груз /   партии   товаров   /   оборудование   не  могут  быть
доставлены / вывезены ввиду того, что предприятие закрыто.</p><br>

<p style="font-weight:500;">203 Изменение расписания</p><br>

<p style="text-indent:2em;">Груз / партии товаров / оборудование не могут быть / не  будут
доставлены  /  вывезены  в  указанные  сроки  из-за  изменения
расписания.</p><br>

<p style="font-weight:500;">204 Необходим дополнительный адрес</p><br>

<p>Для осуществления доставки / вывоза груза / партии  товаров  /
оборудования требуется дополнительный адрес.</p><br>

<p style="font-weight:500;">205 Выход из строя компьютерной системы</p><br>

<p>Компьютерная система находится в нерабочем состоянии.</p><br>

<p style="font-weight:500;">206 Требуется согласие на доставку в кредит</p><br>

<p>Грузополучатель требует,  чтобы  доставка  осуществлялась   на
основе кредита.</p><br>

<p style="font-weight:500;">207 Меры со стороны клиента</p><br>

<p>Для доставки груза / партий товаров / оборудования необходимо,
чтобы клиент принял соответствующие меры.</p><br>

<p style="font-weight:500;">208 Груз поврежден</p><br>

<p>Груз / партии товаров / оборудование были повреждены.</p><br>

<p style="font-weight:500;">209 Доставка в конкретно указанные сроки / время / периоды</p><br>

<p>Доставку груза  /  партий  товаров  /  оборудования  требуется
осуществить в конкретные сроки / время / периоды.</p><br>

<p style="font-weight:500;">210 Неправильно указанный пункт назначения</p><br>

<p>Груз /  партии  товаров  /  оборудование  были  направлены   в
неправильно указанный пункт назначения.</p><br>

<p style="font-weight:500;">211 Крушение</p><br>

<p>Поезд, осуществляющий  перевозку  груза  /  партий  товаров  /
оборудования, потерпел крушение.</p><br>

<p style="font-weight:500;">212 Несоответствие</p><br>

<p>Описание груза / оборудования, которое было передано ранее, не
соответствует реальному положению.</p><br>

<p style="font-weight:500;">213 Забастовка докеров</p><br>

<p>Груз /   партии   товаров   /   оборудование   не  могут  быть
доставлены / вывезены из-за забастовки докеров.</p><br>

<p style="font-weight:500;">214 Из-за действий клиента</p><br>

<p>Действия клиента повлияли на работу транспортной цепи.</p><br>

<p style="font-weight:500;">215 Выход из строя оборудования</p><br>

<p>Доставка / вывоз не  могут  быть  осуществлены  из-за  поломки
оборудования.</p><br>

<p style="font-weight:500;">216 Соответствующий компетентный орган требует проведения досмотра</p><br>

<p>Соответствующий компетентный   орган   потребовал   проведения
досмотра груза / оборудования.</p><br>

<p style="font-weight:500;">217 Запрещение на вывоз</p><br>

<p>Вывоз груза  /  партий  товаров  /  оборудования  запрещен  до
проведения дополнительного расследования.</p><br>

<p style="font-weight:500;">218 Отсутствие грузовых мест</p>

<p style="text-indent:2em;"></p><h6>Результаты проверки  груза  / партий товаров / оборудования не
соответствуют количеству,  указанному в извещении.  Результат:</h6><p></p>

<p>меньше чем в извещении.</p><br>

<p style="font-weight:500;">219 Ограничения на ввоз</p><br>

<p style="text-indent:2em;">До получения  разрешения  на  ввоз  груз  /  партии  товаров /
оборудование  должны   пройти   соответствующие   проверки   и
испытания.</p><br>

<p style="font-weight:500;">220 Неправильная информация о вывозе</p><br>

<p>Груз /  партии  товаров  / оборудование не были вывезены из-за
неправильной информации о вывозе.</p><br>

<p style="font-weight:500;">221 Неправильно указанный адрес</p><br>

<p>Адрес для осуществления перевозки был указан неправильно.</p><br>

<p style="font-weight:500;">222 Производственный конфликт</p><br>

<p>Перевозка не осуществлена из-за производственного конфликта.</p><br>

<p style="font-weight:500;">223 Ожидание указаний</p><br>

<p>Требуются дополнительные указания.</p><br>

<p style="font-weight:500;">224 Транспортное средство повреждено</p><br>

<p style="text-indent:2em;">Транспортное средство,  на котором осуществлялась (должна была
осуществляться)   перевозка   груза   /   партий   товаров   /
оборудования, получило повреждение.</p><br>

<p style="font-weight:500;">225 Механическая поломка</p><br>

<p style="text-indent:2em;">Транспортное средство / оборудование,  на котором перевозились
(должны   были   перевозиться)   груз   /   партии  товаров  /
оборудование, имеет механическую поломку.</p><br>

<p style="font-weight:500;">226 Технический осмотр</p><br>

<p style="text-indent:2em;">Необходимо провести    технический    осмотр     транспортного
средства / оборудования,  на котором перевозились (должны были
перевозиться) груз / партии товаров / оборудование.</p><br>

<p style="font-weight:500;">227 Отсутствие и / или неправильное оформление документов</p><br>

<p>На груз / партии товаров /  оборудование  требуется  полная  и
правильно оформленная документация.</p><br>

<p style="font-weight:500;">228 Новые условия доставки</p><br>

<p>Новые несостоявшейся доставки  грузополучатель  передал  новые
условия доставки.</p><br>

<p style="font-weight:500;">229 Информация о получателе отсутствует</p><br>

<p>Информация об    ответственном   лице   по   адресу   доставки
отсутствует.</p><br>

<p style="font-weight:500;">230 Номер слежения за грузовым местом неизвестен</p><br>

<p>Номер слежения за грузовым местом неизвестен.</p><br>

<p style="font-weight:500;">231 Оплата не получена</p><br>

<p>Ожидаемая оплата за перевозку не была получена.</p><br>

<p style="font-weight:500;">232 В оплате отказано</p><br>

<p>Плательщик отказался оплачивать перевозку.</p><br>

<p style="font-weight:500;">233 Отказ без представления объяснений</p><br>

<p>В перевозке  /  документации  было  отказано без представления
объяснений.</p><br>

<p style="font-weight:500;">234 Перевозка  запланирована  на  дату  после указанных предельных
сроков</p><br>

<p style="text-indent:2em;">Перевозка груза  /  партий  товаров  /  оборудования,  которые
должны быть доставлены / вывезены,  была запланирована на дату
после указанных предельных сроков.</p><br>

<p style="font-weight:500;">235 Перевод на запасные пути</p><br>

<p style="text-indent:2em;">Транспортное средство,  в которое должны быть погружены груз /
партии товаров / оборудование,  было  переведено  на  запасные
пути.</p><br>

<p style="font-weight:500;">236 Подпись не требуется</p><br>

<p>Подпись не требуется.</p><br>

<p style="font-weight:500;">237 Перевозка с соблюдением специальных условий</p><br>

<p>Для перевозки  груза / партий товаров / оборудования требуется
соблюдение специальных условий.</p><br>

<p style="font-weight:500;">238 Информация о слежении отсутствует</p><br>

<p>Информация о  слежении  за  грузом  /   партиями   товаров   /
оборудованием отсутствует.</p><br>

<p style="font-weight:500;">239 Причина не известна</p><br>

<p>Причина не известна.</p><br>

<p style="font-weight:500;">240 Погодные условия</p><br>

<p>Погодные условия оказали влияние на вывоз / доставку.</p><br>

<p style="font-weight:500;">241 Период бесплатного хранения истек</p><br>

<p style="text-indent:2em;">Груз /  партии  товаров  /  оборудование хранились на складе в
течение периода,  превышающего допустимый  период  бесплатного
хранения.</p><br>

<p style="font-weight:500;">242 Грузополучатель отсутствует</p><br>

<p style="text-indent:2em;">Доставку произвести     не     удалось     ввиду    отсутствия
грузополучателя или его агента,  уведомление о  доставке  было
отправлено или передано.</p><br>

<p style="font-weight:500;">243 Задержка вызвана местными условиями</p><br>

<p>Операция была  отложена  из-за  местных  условий:  забастовка,
наводнение, дорожные работы, погодные условия и т.д.</p><br>

<p style="font-weight:500;">244 Задержка    вызвана    необходимостью    получения    указаний
грузополучателя</p><br>

<p>Операция в   настоящее  время  отложена  в  ожидании  указаний
грузополучателя.</p><br>

<p style="font-weight:500;">245 Задержка    вызвана    необходимостью    получения    указаний
грузоотправителя</p><br>

<p>Операция в   настоящее  время  отложена  в  ожидании  указаний
грузоотправителя.</p><br>

<p style="font-weight:500;">246 Задержка вызвана различными причинами</p><br>

<p>Задержка обусловлена различными причинами.</p><br>

<p style="font-weight:500;">247 Неполный адрес</p><br>

<p>Операция задерживается из-за неполного адреса.</p><br>

<p style="font-weight:500;">248 Заказ на поставку отменен</p><br>

<p>Партия товаров или грузопакет не приняты,  заказ  на  поставку
отменен.</p><br>

<p style="font-weight:500;">249 Возвращение грузоотправителю</p><br>

<p>Отказ и  возвращение  грузоотправителю  в  соответствии  с его
указаниями.</p><br>

<p style="font-weight:500;">250 Несоответствие заказу на доставку</p><br>

<p>Партия товаров   или   грузопакет   не   приняты   из-за    их
несоответствия заказу на поставку.</p><br>

<p style="font-weight:500;">251 Сроки не соблюдены</p><br>

<p>Партия товаров  или  грузопакет не приняты,  сроки поставки не
соблюдены.</p><br>

<p style="font-weight:500;">252 Автоматическое возвращение</p><br>

<p>Указание об  автоматическом  возвращении   в   случае   отказа грузополучателя.</p><br>

<p style="font-weight:500;">253 Партия товаров или грузопакет не приняты - заказ уже выполнен.</p><br>

<p>Партия товаров  или  грузопакет не приняты;  заказ на поставку
уже выполнен.</p><br>

<p style="font-weight:500;">254 В принятии отказано - требуются указания</p><br>

<p>Отказ, требуются указания грузополучателя.</p><br>

<p style="font-weight:500;">255 В принятии отказано - несостоятельность грузополучателя</p><br>

<p>Отказ из-за   несостоятельности   грузополучателя   (например,
банкротство, смерть грузополучателя).</p><br>

<p style="font-weight:500;">256 В принятии отказано - отсутствие части груза</p><br>

<p>В принятии отказано из-за частичного отсутствия по прибытии.</p><br>

<p style="font-weight:500;">257 В принятии отказано - заказ не размещался</p><br>

<p>Партия товаров  или грузопакет не приняты,  грузополучатель их
не заказывал.</p><br>

<p style="font-weight:500;">258 В принятии отказано - остаток не принят</p><br>

<p>Доставленные остатки груза не приняты.</p><br>

<p style="font-weight:500;">259 В принятии отказано - поврежденное состояние</p><br>

<p>Вся партия  товаров  или  грузопакет  не  приняты   из-за   их
поврежденного состояния.</p><br>

<p style="font-weight:500;">260 Отсутствие содержимого</p><br>

<p style="text-indent:2em;">При проверке      вызывающего      подозрения      грузопакета
грузополучатель обнаружил, что содержимое в нем отсутствует.</p><br>

<p style="font-weight:500;">261 В упаковочном листе не указан</p><br>

<p>Грузопакет не указан в упаковочном листе.</p><br>

<p style="font-weight:500;">262 Неидентифицированный грузопакет</p><br>

<p style="text-indent:2em;">После проверки   или    сортировочной    операции    обнаружен
грузопакет,   который   не   может   быть  надлежащим  образом
идентифицирован.</p><br>

<p style="font-weight:500;">263 Временное прекращение операции: взимание платы за перевозку</p><br>

<p>Необходимы дополнительные указания,  поскольку грузополучатель
отказался оплачивать перевозку.</p><br>

<p style="font-weight:500;">264 Временное прекрашение операции: компенсация</p><br>

<p style="text-indent:2em;">Необходимы дополнительные указания,  поскольку грузополучатель
отказался производить компенсационные платежи, которые требует
грузоотправитель.</p><br>

<p style="font-weight:500;">265 Временное прекращение операции: поставка без заказа</p><br>

<p>Необходимы дополнительные указания,  поскольку грузополучатель
утверждает, что он не заказывал груз.</p><br>

<p style="font-weight:500;">256 В принятии отказано - отсутствие части груза</p><br>

<p>В принятии отказано из-за частичного отсутствия по прибытии.</p><br>

<p style="font-weight:500;">257 В принятии отказано - заказ не размещался</p><br>

<p>Партия товаров или грузопакет не приняты,  грузополучатель  их
не заказывал.</p><br>

<p style="font-weight:500;">258 В принятии отказано - остаток не принят</p><br>

<p>Доставленные остатки груза не приняты.</p><br>

<p style="font-weight:500;">259 В принятии отказано - поврежденное состояние</p><br>

<p>Вся партия   товаров   или  грузопакет  не  приняты  из-за  их
поврежденного состояния.</p><br>

<p style="font-weight:500;">260 Отсутствие содержимого</p><br>

<p style="text-indent:2em;">При проверке      вызывающего      подозрения      грузопакета
грузополучатель обнаружил, что содержимое в нем отсутствует.</p><br>

<p style="font-weight:500;">261 В упаковочном листе не указан</p><br>

<p>Грузопакет не указан в упаковочном листе.</p><br>

<p style="font-weight:500;">262 Неидентифицированный грузопакет</p><br>

<p style="text-indent:2em;">После проверки    или    сортировочной    операции   обнаружен
грузопакет,  который  не   может   быть   надлежащим   образом
идентифицирован.</p><br>

<p style="font-weight:500;">263 Временное прекращение операции:  взимание платы за перевозку</p><br>

<p>Необходимы дополнительные указания,  поскольку грузополучатель
отказался оплачивать перевозку.</p><br>

<p style="font-weight:500;">264 Временное прекращение операции: компенсация</p><br>

<p style="text-indent:2em;">Необходимы дополнительные указания,  поскольку грузополучатель
отказался производить компенсационные платежи, которые требует
грузоотправитель.</p><br>

<p style="font-weight:500;">265 Временное прекращение операции:  поставка без заказа</p><br>

<p>Необходимы дополнительные указания,  поскольку грузополучатель
утверждает, что он не заказывал груз.</p><br>

<p style="font-weight:500;">266 Временное прекращение операции: задержка</p><br>

<p>Грузы были  доставлены слишком поздно после заказа на поставку
или после отгрузки.</p><br>

<p style="font-weight:500;">267 Вскрытый грузопакет не принят</p><br>

<p>Грузополучатель отказался принимать вскрытый грузопакет.</p><br>

<p style="font-weight:500;">268 Промоченный изнутри грузопакет не принят</p><br>

<p style="text-indent:2em;">Грузополучатель отказался  принимать  грузопакет,   содержание
которого, как предполагается, испорчено жидкостью.</p><br>

<p style="font-weight:500;">269 Отказы из-за неприемлемых коммерческих условий</p><br>

<p style="text-indent:2em;">Грузополучатель отказывается  принимать  доставленный  груз на
коммерческих условиях грузоотправителя: цена, сроки доставки и
т.д.</p><br>

<p style="font-weight:500;">270 Несоответствующая продукция или упаковка</p><br>

<p style="text-indent:2em;">Грузоотправитель отказывается   принимать  доставленный  груз,
ссылаясь  на   несоответствие   поставленной   продукции   или
упаковки.</p><br>

<p style="font-weight:500;">271 Изменение сроков / времени доставки</p><br>

<p>Грузополучатель изменил дату или время получения груза.</p><br>

<p style="font-weight:500;">272 Грузовая документация поступила без груза</p><br>

<p>Документация на груз была получена без соответствующего груза.</p><br>

<p style="font-weight:500;">273 Груз получен без документации</p><br>

<p>Груз /   партия  товаров  были  получены  без  соответствующей
товаросопроводительной документации.</p><br>

<p style="font-weight:500;">274 Груз доставлен не по адресу</p><br>

<p>Партия товаров была доставлена не по адресу.</p><br>

<p style="font-weight:500;">275 Документация обнаружена</p><br>

<p>Документация, которая ранее считалась утерянной, обнаружена.</p><br>

<p><a href="http://www.6pl.ru/asmap/r_oon24.htm">Источник</a></p>

</div>
`