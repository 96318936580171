import { connect } from 'react-redux'
import { compose } from 'recompose'

import withTranslation from '../../../../globals/components/withTranslation'
import { setInitialChatState, setChatPartnerId } from '../../../../store/actions'
import { conversationScrollToBottom } from "../../../../store/actions/socket"
import Component from './component'
import withPermissions from '../../../../globals/components/withPermissions'
import { IReduxStorage } from '../../../../store/reducers/types'
import { ChatProps } from './types'

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state: IReduxStorage) => {
      const profile = state.auth.profile.get('data')

      return {
        chatMsgs: state.socket.chatMsgs,
        needToScrollInMiniMessenger: state.socket.needToScrollInMiniMessenger
      }
    },
    {
        setInitialChatState,
        setChatPartnerId,
        conversationScrollToBottom,
    }
  )
)(Component) as React.ComponentClass<ChatProps>