import React, { Component, Fragment } from 'react'
import dayjs from 'dayjs'
import { trashO } from 'react-icons-kit/fa/trashO'
import Icon from 'react-icons-kit'
import { confirmAlert } from 'react-confirm-alert'
import { NEW_LINE } from '../../../store/constants'
import WidgetBase from '../../shared/components/widget-base'
import { commentWordEndingMulti, commentWordEndingSingle, commentWordEndingSingleOne } from '../../shared/utils'
import { canDo } from '../../../store/utils'
import Pagination from '../../UI/pagination'
import TooltipWrapper from '../../UI/popover-wrapper'
import TooltipModal from '../../search/table/tooltip-modal'
import { Link } from 'react-router-dom'

import './styles.scss'

const STYLE_BASE = 'news-comments_'

function isToday(dt) {
  return dayjs(dt).format('DD.MM.YYYY') === dayjs().format('DD.MM.YYYY')
}

export default class extends Component {
  state = {
    itemId: undefined,
    pageIndex: undefined,
    pageSize: undefined,
  }

  componentDidMount() {
    this.refresh()
  }

  componentDidUpdate(prevProps) {
    const { itemId, comments } = this.props
    if (itemId !== prevProps.itemId) {
      this.refresh()
    }

    if (prevProps.comments !== comments) {
      this.setState({
        pageIndex: comments.pageIndex,
        pageSize: comments.pageSize,
      })
    }
  }

  paginationClick = page => {
    const { itemId, loadComments } = this.props
    const { pageSize } = this.state
    this.setState({ pageIndex: page }, () => {
      loadComments({ itemId, pageIndex: page, pageSize, force: true })
    })


  }

  refresh({ itemId, force } = {}) {
    if (!itemId) {
      itemId = this.props.itemId
    }

    const { pageIndex: curIndex, pageSize: curSize, loading } = this.props.comments
    const itemChanged = itemId !== this.state.itemId
    const pageIndex = itemChanged ? 0 : curIndex
    const pageSize = itemChanged ? 10 : curSize
    const changed =
      itemId !== this.state.itemId ||
      pageIndex !== this.state.pageIndex ||
      pageSize !== this.state.pageSize

    if (force || (changed && !loading)) {
      this.setState({ itemId })
      this.props.loadComments({
        itemId,
        pageIndex: this.state.pageIndex,
        pageSize: this.state.pageSize,
        force
      })
    }
  }

  clickDelete = item => {
    const { deleteComment } = this.props

    confirmAlert({
      title: t('delete.confirm'),
      message: (
        <span>
          {t('delete.sure')}
          <br />
          {t('delete.cannot.undone')}
        </span>
      ),
      buttons: [
        {
          label: t('delete'),
          onClick: () => {
            deleteComment &&
              deleteComment(item.id, () => {
                this.refresh({ force: true })
              })
          },
        },
        {
          label: t('button.close'),
          onClick: this.setState({ redirect: false }),
        },
      ],
      onClickOutside: this.setState({ redirect: false }),
      onKeypressEscape: this.setState({ redirect: false }),
    })
  }


  getWordEnding = (numberBase) => {
    const { langId } = this.props
    const lastDigit = numberBase % 10

    if (langId === 1) {
      if (lastDigit === 1) {
        return commentWordEndingSingle
      } else if (lastDigit === 2 || lastDigit === 3) {
        return commentWordEndingSingleOne
      } else {
        return commentWordEndingMulti
      }
    } else {
      if (lastDigit === 1) {
        return ''
      } else {
        return 's'
      }
    }
  }

  render() {
    const { t, comments, isAuthenticated, permissions, userId } = this.props
    const { loading, items, total, } = comments
    const { pageIndex, pageSize } = this.state
    const isAdmin = canDo(permissions, 'admin')

    return (
      <div className="news-comments-container">
        <WidgetBase title={t('news.widget.title.comments')}>
          {loading ? (
            <div>loading</div>
          ) : total === 0 ? (
            <div className="no-comments">
              {t('news.no.comments')}
            </div>
          ) : (

            <Fragment>
              <span className="comment-title">
                {t('news.total.comments', {
                  total: total,
                  wordEnding: this.getWordEnding(total),
                })}
              </span>
              <div className="panel-comments news-comment-panel">
                {items.map((comment, index) => {
                  const hasAccess = isAuthenticated && (isAdmin || comment.userId === userId)
                  // const userPhoto = comment.photo
                  //   ? `${config.apiUrl}/common/file/profile/${comment.userId}/${comment.photo}`
                  //   : USER_PHOTO_URL_PLACEHOLDER

                  return (
                    <div key={index} className="panel-content-row">
                      <div className="panel-comment">

                       
                        <div className="panel-comment-content">
                          <div className="panel-comment-name">
                            <span className="name">

                              <Link to={`user/details/${comment.userId}`} className="name-link has-tooltip">
                                <TooltipWrapper
                                  text={<TooltipModal companyId={0} userId={comment.userId} />}
                                  hover
                                  innerClassName="full-tooltip"
                                  disabled={false}
                                  parentCustomClass="move-up-container"
                                >
                                  {comment.userName}
                                </TooltipWrapper>
                              </Link>

                              
                            </span>
                            <div className={`${STYLE_BASE}right`}>
                              <span className={`${STYLE_BASE}date`}>
                                {dayjs(comment.date).format(
                                  isToday(comment.date) ? 'HH:mm' : 'DD.MM.YY'
                                )}
                              </span>
                              {hasAccess && (
                                <Fragment>
                                  <button
                                    className="btn btn-link"
                                    onClick={() => this.clickDelete(comment)}
                                  >
                                    <Icon title={t('delete')} icon={trashO} size={16} />
                                  </button>
                                </Fragment>
                              )}
                            </div>
                          </div>
                          <div className="panel-comment-desc">
                            {comment.body
                              .split(NEW_LINE)
                              .filter(y => y !== '')
                              .map(y => (
                                <p key={y}>{y}</p>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>


            </Fragment>


          )}
        </WidgetBase>
        {total !== 0 &&
          <Pagination
            onClick={this.paginationClick}
            total={total}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />}
      </div>
    )
  }
}
