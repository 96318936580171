import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'

import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'
import {
  loadCargoLastSearch,
  deleteCargoLastSearch,
  loadTransportLastSearch,
  deleteTransportLastSearch,
} from '../../../store/actions'

import Component from './component'
import { LastSearchProps } from './types'

export default compose<LastSearchProps, {}>(
  withTranslation,
  withPermissions,
  connect(
    (state: any, props: LastSearchProps) => {
      let lastSearch: any
      if (props.searchType === 'transport') {
        lastSearch = state.transport.lastSearch
      } else {
        lastSearch = state.cargo.get('lastSearch')
      }
      
      return {
        loading: lastSearch.get('loading'),
        loaded: lastSearch.get('loaded'),
        totalItems: lastSearch.get('totalItems'),
        items: lastSearch.get('items'),
        error: lastSearch.get('error'),
      }
    },
    (dispatch: any, props: LastSearchProps) => ({
      loadLastSearch: () =>
        dispatch(
          props.searchType === 'transport' ? loadTransportLastSearch() : loadCargoLastSearch()
        ),
      deleteLastSearch: (itemId: number) =>
        dispatch(
          props.searchType === 'transport'
            ? deleteTransportLastSearch(itemId)
            : deleteCargoLastSearch(itemId)
        ),
    })
  )
)(Component) as React.ComponentClass<LastSearchProps>
