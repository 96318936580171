import { connect } from 'react-redux'
import { compose } from 'recompose'

import withTranslation from '../../../globals/components/withTranslation'

import Component from './component'
import { MapProps } from './types'

export default compose(
  withTranslation,
  connect((state: any) => {
    return {
      langId: state.ui.langId,
      isAuthenticated: !!state.auth.accessToken,
    }
  })
)(Component) as React.ComponentClass<MapProps>
