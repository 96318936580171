import React from 'react'
import { AdminClaimsProps } from './types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import TooltipWrapper from '../../UI/popover-wrapper'
import TooltipModal from '../../search/table/tooltip-modal'
import FlexBand from 'flexband'
import fetch from '../../shared/fetch'
import Spinner from '../../UI/spinner'
import classnames from 'classnames'
import { confirmAlert } from 'react-confirm-alert'
import dict from '../../shared/dictionaries'

import "./styles.scss"

export default class AdminClaims extends React.Component<AdminClaimsProps, {}> {

    state = {
        isFetching: false
    }

    claimOnAction = (claimId, action) => {
        const { refreshClaimsData } = this.props
        this.setState({
            isFetching: true
        })

        if (action === "approve") {
            const body = JSON.stringify({
                approved: true,
            })

            fetch(`/claim/${claimId}/approve`, {
                method: 'POST',
                body,
            }).then(() => {
                this.setState({ isFetching: false })
                refreshClaimsData()
            })
        } else {
            fetch(`/claim/${claimId}`, {
                method: 'DELETE',
            }).then(() => {
                this.setState({ isFetching: false })
                refreshClaimsData()
            })
        }

    }

    componentDidUpdate(prevProps) {
        const { claimData } = this.props
        if (claimData.approved !== prevProps.claimData.approved) {
            this.setState({
                isFetching: false
            })
        }
    }


    renderBlockPopover = (claimId: number, approvedDate: string) => {
        const { t } = this.props
        const { isFetching } = this.state
        return (
            <div className="claim-actions-container">
                <div className="claim-actions-title">{t('actions')}</div>
                <FlexBand >
                    {!approvedDate && <div className="action-button" onClick={() => this.claimOnAction(claimId, "approve")}>
                        <button className="button button-orange" disabled={isFetching}>{t('aprrove')}</button>
                    </div>}
                    <div className="action-button" onClick={() => this.claimOnAction(claimId, "delete")}>
                        <button className="button reject-button" disabled={isFetching}>{t('delete')}</button>
                    </div>

                </FlexBand>
            </div>
        )
    }

    doClaimAction = (claimId: number, action: string) => {
        const { t } = this.props
        confirmAlert({
            title: t("are.you.sure.delete"),
            message: (
                alert
            ) as any,
            buttons: [
                {
                    label: "OK",
                    onClick: () => this.claimOnAction(claimId, action),
                },
                {
                    label: t('button.close'),
                    onClick: () => this.setState({ isFetching: false }),
                },
            ],
            onClickOutside: () => this.setState({ isFetching: false }),
            onKeypressEscape: () => this.setState({ isFetching: false }),
        })
    }

    render() {
        const { t, claimData, claimData: {
            targetCompanyName,
            targetCompanyId,
            createdDate,
            createdByCompanyName,
            createdByFirstName,
            createdByLastName,
            title,
            claimId,
            createdByUserId,
            description,
            createdByCompanyId,
            claimType,
            approved,
            approvedDate
        } } = this.props
        const { isFetching } = this.state

        const filteredClaimType = dict.claimsTypes(t).filter(claim => claim.value === claimType)
        return (

            <div className="panel-content-row panel-content-row-message claim-parent-container">
                {isFetching &&
                    <div className="claim-spinner-container">
                        <Spinner />
                    </div>}

                <div className="panel-message panel-message-reviews">
                    <div className="panel-message-header">
                        <div className="panel-message-header-left"><span>
                            {t('claims.on.company')}</span>
                            <TooltipWrapper
                                text={<TooltipModal companyId={targetCompanyId} userId={0} />}
                                hover
                                innerClassName="full-tooltip"
                                disabled={false}
                                parentCustomClass="move-up-container"
                            >
                                <Link to={`/company/item/${targetCompanyId}`} className="name">
                                    ({targetCompanyName})
                                </Link>
                            </TooltipWrapper>
                        </div>
                        <div className="panel-message-header-right">
                            <span>Автор</span>
                            <TooltipWrapper
                                text={<TooltipModal companyId={0} userId={createdByUserId} />}
                                hover
                                innerClassName="full-tooltip"
                                disabled={false}
                                parentCustomClass="move-up-container"
                            >
                                <Link to={`/user/details/${createdByUserId}`} className="name">
                                    {`${createdByFirstName} ${createdByLastName}`}
                                </Link>
                            </TooltipWrapper>


                            <span className="meta-message">{moment(createdDate).format("DD/MM/YYYY HH:mm")}</span>


                            <>
                                {!approvedDate && <TooltipWrapper text={t('aprrove')} hover>
                                    <Link to="#" onClick={() => this.doClaimAction(claimId, "approve")}>
                                        <i style={{ color: '#56bf42' }} className=" fa fa-check-circle"></i>
                                    </Link>
                                </TooltipWrapper>}

                                <TooltipWrapper text={t('delete')} hover>
                                    <Link to="#" onClick={() => this.doClaimAction(claimId, "delete")} >
                                        <i className="fa fa-times"></i>
                                    </Link>
                                </TooltipWrapper>
                            </>



                        </div>
                    </div>
                    <div className={classnames('panel-message-title', approved ? "claim-approved" : "claim-not-approved")} >
                        <p className="title">{approved ?
                            <>
                                {t('solution.is')} <b>({moment(approvedDate).format("DD/MM/YYYY HH:mm")})</b>
                            </>
                            : t('solution.not')}</p>
                    </div>
                    <div className="panel-message-title">
                        <p className="title">{title}</p>
                        {filteredClaimType[0]?.name &&
                            <div className="claim-type">
                                <label>{t('claim.type')}:</label>
                                <span>{filteredClaimType[0]?.name}</span>
                                </div>
                        }
                    </div>
                    <div className="panel-message-content">
                        {description}
                    </div>
                </div>
            </div>

        )
    }
}
