import { connect } from 'react-redux'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

import {
  showModal as showAuthModal,
  logout,
  showForm,
  closeForm,
  setActivePopup,
} from '../../store/actions'
import { IReduxStorage } from '../../store/reducers/types'
import withTranslation from '../../globals/components/withTranslation'
import withPermissions from '../../globals/components/withPermissions'

import Component from './component'

export default compose(
  injectIntl,
  withTranslation,
  withPermissions,
  withRouter,
  connect(
    (state: IReduxStorage) => {
      return {
        isAuthenticated: !!state.auth.accessToken,
        modalForm: state.modalForm,
        activeGuid: state.ui.activePopupGuid,
      }
    },
    {
      showAuthModal,
      showForm,
      closeForm,
      logout,
      setActivePopup,
    }
  )
)(Component)
