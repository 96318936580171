import React, { FunctionComponent } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { getAccessStatusDate } from '../shared/utils'

import './style.scss'

interface IWelcomeUserProps extends ComponentBaseProps {
  firstName: string
  accessStatus: string
  accessStatusExpires: Date
  lastLogin: Date
  dataMoveForMobile: string
}

const WelcomeUser: FunctionComponent<IWelcomeUserProps> = props => {
  const { t, firstName, accessStatus, accessStatusExpires, lastLogin, dataMoveForMobile } = props
  const сonjunction = t('in').toLowerCase()

  return (
    <div className="panel" data-move={dataMoveForMobile}>
      <div className="panel-content-row fs-14">
        <span>{t('welcome')}, </span>
        <b>{firstName}</b>
      </div>
      <div className="panel-content-row">
        <p className="last-visit">
          <span>{t('your.last.visit')}: </span>
          <b>{moment(lastLogin).format(`DD.MM.YYYY ${сonjunction} HH:mm`)}</b>
        </p>
        <p>
          <span>{t('your.status')}: </span>
          <Link to="/about-us/prices">{accessStatus} user </Link>
          <span>{getAccessStatusDate(accessStatusExpires, `(${t('expires')} `, ')')}</span>
        </p>
      </div>
    </div>
  )
}

export default WelcomeUser
