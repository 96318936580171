import { connect } from 'react-redux'
import {
  compose,
  // withHandlers
} from 'recompose'
// import { bindActionCreators } from "redux"
import { setActivePopup } from '../../../store/actions/ui'

import Component from './component'

// export default Component
// export default connect()( Component )

export default compose(
  connect(
    state => ({
      instances: state.modal.instances,
    }),
    {
      setActivePopup,
    }
  )
)(Component)
