import {
  PROFILE_GET_START,
  PROFILE_GET_SUCCESS,
  PROFILE_GET_FAILURE,
  PROFILE_RESET,
  USER_GET_PROFILE_SUCCESS,
  USER_GET_STATISTICS,
  USER_CHANGE_DATE_REGISTRATION,
  PROFILE_GET_USER_RATING,
  PROFILE_GET_USER_ADD_REVIEW,
  PROFILE_SCROLL_TO_ONCOMMING_ITEMS,
  CAN_ADD
} from '../actions/action-types'
import { ProfileState, IProfile } from './types'

const initialState: ProfileState = {
  isLoading: false,
  error: null,
  profile: {
    gtu: undefined,
    contactDetails: {
      phone1: undefined,
      phone2: undefined,
      email: undefined,
      telegram: undefined,
      whatsup: undefined,
      skype: undefined,
      fbMessenger: undefined,
      viber: undefined,
      linkedin: undefined,
      additionalInfo: undefined,
    },
    userInfo: {
      dateRegistered: undefined,
      accessStatus: undefined,
      accessStatusExpires: undefined,
      averageRating: undefined,
      firstName: undefined,
      lastName: undefined,
      countryId: undefined,
      company: {
        companyId: undefined,
        companyName: undefined,
      },
      languages: undefined,
    },
  },
  statistics: {
    cargo: 0,
    cargoTotal: 0,
    oncomingTransport: 0,
    transport: 0,
    transportTotal: 0,
    oncomingCargo: 0,
    ads: 0,
    adsTotal: 0,
    autopark: 0,
    autoparkTotal: 0,
    companyAutoparkTotal: 0,
    companyAutopark: 0,
    companyAutoparkTotalNew: 0,
    averageRatingLast10Days: 0,
    companiesReviewedByMeLast10Days: 0,
    meBlockedByUsersLast10Days: 0,
    meReviewedByUsersLast10Days: 0,
    myCompanyReviewedByUsersLast10Days: 0,
    usersBlockedByMeLast10Days: 0,
    usersReviewedByMeLast10Days: 0,
  },
  userReviewQuota: {
    reviewCount: 0,
  },
  userCanAddReview: false,
  scrollToOncommingItems: false,
  canAdd: false,
}

export default (state = initialState, { type, payload }: any = {}) => {
  switch (type) {
    case PROFILE_GET_START:
      return {
        ...state,
        isLoading: true,
      }
    case PROFILE_GET_SUCCESS:
      {
        const {
          phone1,
          phone2,
          skype,
          viber,
          whatsup,
          linkedin,
          fbMessenger,
          telegram,
          email,
          firstName,
          lastName,
          dateRegistered,
          languages,
          additionalInfo,
          userCompanyName,
          companyId,
          photo,
          accessStatus,
          accessStatusExpires,
          countryId,
          hasAdminNotices,
          gtu
        } = payload
        
        const profile: IProfile = {
          contactDetails: {
            phone1,
            phone2,
            skype,
            viber,
            whatsup,
            linkedin,
            fbMessenger,
            telegram,
            email,
            additionalInfo,
          },
          userInfo: {
            firstName,
            lastName,
            dateRegistered,
            accessStatus,
            accessStatusExpires,
            languages,
            countryId,
            company: {
              companyId,
              companyName: userCompanyName,
            },
            photo,
            hasAdminNotices,
          },
          gtu
        }

        return {
          ...state,
          profile,
          isLoading: false,
        }
      }
    case PROFILE_GET_FAILURE:
      return {
        ...state,
        error: payload,
      }
    case PROFILE_GET_USER_RATING:
      return {
        ...state,
        userReviewQuota: payload,
      }
    case PROFILE_SCROLL_TO_ONCOMMING_ITEMS:
      return {
        ...state,
        scrollToOncommingItems: payload
      }
    case CAN_ADD:
      return {
        ...state,
        canAdd: payload
      }

    case PROFILE_GET_USER_ADD_REVIEW:
      return {
        ...state,
        userCanAddReview: payload,
      }
    case PROFILE_RESET:
      return {
        ...initialState,
      }
    case USER_GET_STATISTICS:
      return {
        ...state,
        statistics: payload,
      }
    case USER_CHANGE_DATE_REGISTRATION: {
      const { dateRegistered } = payload
      const userInfo = { ...state.profile.userInfo, dateRegistered }
      const profile = { ...state.profile, userInfo }
      return {
        ...state,
        profile,
      }
    }
    case USER_GET_PROFILE_SUCCESS:
    default:
      return state
  }
}
