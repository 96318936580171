export const int13 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Convenția internațională privind Sistemul armonizat de denumire și codificare a mărfurilor, încheiată la Bruxelles la 14 iunie 1983</h4><p></p>

<p></p><h4>PREAMBUL</h4><p></p>

<p style="text-indent:2em;"></p><h6>Părțile contractante la prezenta convenție, elaborată sub auspiciile Consiliului de Cooperare Vamală:</h6><p></p>

<p>în dorința de a facilita comerțul internațional,</p><br>

<p style="text-indent:2em;">în dorința de a facilita culegerea, compararea și analizarea datelor statistice, în special a celor privind comerțul internațional,</p><br>

<p style="text-indent:2em;">în dorința de a reduce cheltuielile antrenate de necesitatea de a atribui mărfurilor o nouă denumire, o nouă clasificare și o nouă codificare, atunci când în cadrul schimburilor
internaționale acestea trec dintr-un sistem de clasificare în altul, și în dorința de a facilita uniformizarea documentelor comerciale,
 ca și transmiterea datelor,</p><br>

<p style="text-indent:2em;">considerând că evoluția tehnicilor și a structurilor comerțului internațional implică modificări importante în Convenția privind Nomenclatura pentru clasificarea mărfurilor în
 tarifele vamale, încheiată la Bruxelles la 15 decembrie 1950,</p><br>

<p style="text-indent:2em;">considerând, de asemenea, că gradul de detaliere, cerut în scopuri tarifare și statistice de guvernele și cercurile comerciale, depășește în prezent cu mult pe cel
 oferit de nomenclatura anexată la convenția menționată mai sus,</p><br>

<p style="text-indent:2em;">considerând că este important să se dispună de date exacte și comparabile, în scopul negocierilor comerciale internaționale,</p><br>

<p style="text-indent:2em;">considerând că Sistemul armonizat este destinat utilizării pentru stabilirea tarifelor și pentru statisticile aferente
 diferitelor moduri de transport al mărfurilor,</p><br>

<p style="text-indent:2em;">considerând că Sistemul armonizat este destinat a fi încorporat, pe cât posibil, în sistemele comerciale de denumire și codificare
 a mărfurilor,</p><br>

<p style="text-indent:2em;">considerând că Sistemul armonizat este destinat să favorizeze stabilirea unei corelații cât mai strânse între statisticile comerciale de
 import și de export, pe de o parte, și statisticile de producție, pe de altă parte,</p><br>

<p style="text-indent:2em;">considerând că trebuie să fie menținută o corelație strânsă între Sistemul armonizat și Clasificarea Tip pentru Comerțul Internațional
 (C.T.C.I.) a Națiunilor Unite,</p><br>

<p style="text-indent:2em;">considerând că este indicat a se răspunde cerințelor menționate mai sus prin intermediul unei nomenclaturi tarifare și statistici combinate,
 care să poată fi utilizată de diverșii participanți la comerțul internațional,</p><br>

<p style="text-indent:2em;">considerând că este important ca să se asigure ținerea la zi a Sistemului armonizat, în funcție de evoluția tehnicilor și structurilor
 comerțului internațional,</p><br>

<p style="text-indent:2em;">considerând lucrările deja realizate în acest domeniu de Comitetul Sistemului armonizat, înființat de Consiliul de Cooperare Vamală,</p><br>

<p style="text-indent:2em;">considerând că, în situația în care Convenția privind Nomenclatura, menționată mai sus, s-a dovedit a fi un instrument eficient în
 atingerea unora dintre aceste obiective, mijlocul cel mai bun de obținere a rezultatelor dorite constă în a încheia o nouă
 convenție internațională,</p>

<p style="text-indent:2em;"></p><h6>au convenit asupra următoarelor:</h6><p></p>

<p style="font-weight:500;">ARTICOLUL 1 Definiții Jurisprudență (1)</p>

<p style="text-indent:2em;"></p><h6>În sensul prezentei convenții, se înțelege:</h6><p></p>

<p style="text-indent:2em;">a) prin Sistem armonizat de denumire și codificare a mărfurilor, denumit, în continuare, Sistem armonizat: nomenclatura care cuprinde
 pozițiile și subpozițiile și codurile numerice aferente, notele de secțiuni, capitole și subpoziții, precum și regulile generale de interpretare
 a Sistemului armonizat cuprinse în anexa la prezenta convenție;</p><br>

<p style="text-indent:2em;">b) prin nomenclatură tarifară: nomenclatura stabilită în conformitate cu legislația părții contractante pentru încasarea taxelor vamale
 la import;</p><br>

<p style="text-indent:2em;">c) prin nomenclaturi statistice: nomenclaturile mărfurilor, elaborate de partea contractantă, pentru culegerea datelor necesare întocmirii
 statisticilor comerciale de import și de export;</p><br>

<p style="text-indent:2em;">d) prin nomenclatură tarifară și statistică combinată: o nomenclatură combinată care integrează nomenclatura tarifară și nomenclaturile
 statistice cerute în mod legal de către partea contractantă pentru declararea mărfurilor la import;</p><br>

<p style="text-indent:2em;">e) prin Convenția privind înființarea Consiliului: Convenția privind înființarea unui Consiliu de Cooperare Vamală, încheiată la Bruxelles
 la 15 decembrie 1950;</p><br>

<p>f) prin Consiliu: Consiliul de Cooperare Vamală, menționat la lit. e);</p><br>

<p>g) prin secretar general: secretarul general al Consiliului;</p><br>

<p>h) prin ratificare: ratificarea propriu-zisă, acceptarea sau aprobarea.</p><br>

<p style="font-weight:500;">ARTICOLUL 2 Anexa</p><br>

<p style="font-weight:500;">Anexa la prezenta convenție face parte integrantă din aceasta și orice referire la prezenta convenție se aplică, de asemenea,
 acestei anexe.</p><br>

<p style="font-weight:500;">ARTICOLUL 3 Obligațiile părților contractante</p>

<p style="text-indent:2em;"></p><h6>1. Sub rezerva excepțiilor menționate la art. 4:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) Fiecare parte contractantă se angajează, cu excepția aplicării prevederilor subparagrafului c) al prezentului paragraf, ca de la data la
 care prezenta convenție intră în vigoare, în ceea ce o privește, nomenclaturile sale tarifare și statistice să fie conforme cu Sistemul armonizat.
 Aceasta se angajează, prin urmare, ca la elaborarea nomenclaturilor sale tarifare și statistice:</h6><p></p>

<p style="text-indent:2em;">1. să folosească toate pozițiile și subpozițiile Sistemului armonizat, fără completări sau modificări, precum și codurile numerice aferente;</p><br>

<p style="text-indent:2em;">2. să aplice regulile generale pentru interpretarea Sistemului armonizat, precum și toate notele privind secțiunile, capitolele și subpozițiile și
 să nu modifice domeniul de aplicare a acestor secțiuni, capitole, poziții sau subpoziții ale Sistemului armonizat;</p><br>

<p>3. să respecte ordinea de numerotare a Sistemului armonizat.</p><br>

<p style="text-indent:2em;">b) Fiecare parte contractantă pune, de asemenea, la dispoziția publicului statisticile sale comerciale de import și de export, în conformitate
 cu codul de șase cifre al Sistemului armonizat, sau, la inițiativa părții contractante, peste acest nivel, în măsura în care această publicare
 nu este exclusă din motive excepționale, cum ar fi caracterul confidențial al informațiilor comerciale sau securitatea națională.</p><br>

<p style="text-indent:2em;">c) Nici o dispoziție a prezentului articol nu obligă părțile contractante să utilizeze subpozițiile Sistemului armonizat în nomenclatura lor
 tarifară, cu condiția de a se conforma, în nomenclatura lor tarifară și statistică combinată, prevederilor subparagrafelor a) 1., a) 2. și a)
 3. de mai sus.</p><br>

<p style="text-indent:2em;">2. Conformându-se angajamentelor de la paragraful 1 subparagraful a) al prezentului articol, fiecare parte contractantă poate face acele
 adaptări de text necesare pentru ca Sistemul armonizat să devină operant în raport cu legislația sa națională.</p><br>

<p style="text-indent:2em;">3. Nici o dispoziție a prezentului articol nu interzice părților contractante să stabilească, în nomenclaturile lor tarifare sau statistice,
 subdiviziuni pentru clasificarea mărfurilor peste nivelul Sistemului armonizat, cu condiția ca aceste subdiviziuni să fie adăugate și
 codificate în afara codului numeric de șase cifre care figurează în anexa la prezenta convenție.</p><br>

<p style="font-weight:500;">ARTICOLUL 4 Aplicarea parțială de către țările în curs de dezvoltare</p><br>

<p style="text-indent:2em;">1. Orice țară în curs de dezvoltare, parte contractantă, poate amâna aplicarea unora sau a tuturor subpozițiilor Sistemului armonizat, atât
 timp cât este necesar, ținând seama de propria structură în cadrul comerțului internațional sau de capacitățile sale
 administrative.</p><br>

<p style="text-indent:2em;">2. Orice țară în curs de dezvoltare, parte contractantă, care optează pentru aplicarea parțială a Sistemului armonizat, conform prevederilor
 prezentului articol, se angajează să depună toate eforturile în vederea aplicării întregului Sistem armonizat cu șase cifre într-o perioadă
 de 5 ani de la data la care prezenta convenție intră în vigoare în ceea ce o privește sau în orice altă perioadă pe care o consideră necesară,
 ținând seama de prevederile paragrafului 1 al prezentului articol.</p><br>

<p style="text-indent:2em;">3. Orice țară în curs de dezvoltare, parte contractantă, care optează pentru aplicarea parțială a Sistemului armonizat, conform prevederilor
 prezentului articol, aplică fie toate, fie nici una dintre subpozițiile marcate cu două liniuțe, care aparțin unei subpoziții marcate cu o 
 liniuță, fie toate, fie nici una dintre subpozițiile marcate cu o liniuță care aparțin unei poziții. În asemenea cazuri de aplicare parțială,
 a șasea cifră sau cifrele a cincea și a șasea, care corespund părții din codul Sistemului armonizat neaplicate, sunt înlocuite cu 0 sau
 , respectiv, 00.</p><br>

<p style="text-indent:2em;">4. Orice țară în curs de dezvoltare care optează pentru aplicarea parțială a Sistemului armonizat, conform prevederilor prezentului articol,
 atunci când devine parte contractantă, notifică secretarului general subpozițiile pe care nu le va aplica la data la care prezenta convenție
 va intra în vigoare, în ceea ce o privește, precum și subpozițiile pe care le va aplica ulterior.</p><br>

<p style="text-indent:2em;">5. Orice țară în curs de dezvoltare care optează pentru aplicarea parțială a Sistemului armonizat, conform prevederilor prezentului articol,
 atunci când devine parte contractantă, poate notifica secretarului general că se angajează formal să aplice întregul Sistem armonizat de șase
 cifre, în termen de 3 ani de la data intrării convenției în vigoare, în ceea ce o privește.</p><br>

<p style="text-indent:2em;">6. Orice țară în curs de dezvoltare, parte contractantă, care aplică parțial Sistemul armonizat, conform prevederilor prezentului articol,
 este eliberată de obligațiile ce decurg din art. 3 cu privire la subpozițiile pe care aceasta nu le aplică.</p><br>

<p style="font-weight:500;">ARTICOLUL 5 Asistența tehnică pentru țările în curs de dezvoltare</p><br>

<p style="text-indent:2em;">Țările dezvoltate, părți contractante, acordă țărilor în curs de dezvoltare, la cererea acestora, asistența tehnică, în condiții convenite
 de comun acord, mai ales pentru pregătirea personalului, transpunerea nomenclaturilor lor existente în Sistemul armonizat și asistența pentru
 actualizarea sistemelor lor transpuse, ținând seama de amendamentele la Sistemul armonizat, cât și pentru aplicarea prevederilor prezentei
 convenții.</p><br>

<p style="font-weight:500;">ARTICOLUL 6 Comitetul Sistemului armonizat</p><br>

<p style="text-indent:2em;">1. Se instituie, conform prezentei convenții, un comitet, denumit Comitetul Sistemului armonizat, format din reprezentanți ai fiecărei părți
 contractante.</p><br>

<p>2. Comitetul Sistemului armonizat se întrunește, de regulă, cel puțin de două ori pe an.</p><br>

<p style="text-indent:2em;">3. Reuniunile sale sunt convocate de secretarul general și, dacă părțile contractante nu vor decide altfel, acestea au loc la sediul
 Consiliului.</p><br>

<p style="text-indent:2em;">4. În cadrul Comitetului Sistemului armonizat, fiecare parte contractantă are dreptul la un vot; cu toate acestea, în sensul prevederilor
 prezentei convenții și fără a prejudicia orice convenție care s-ar încheia în viitor, atunci când o uniune vamală sau economică, precum și
 atunci când unul sau mai multe dintre statele ei membre sunt părți contractante, aceste părți contractante au împreună dreptul la un singur
 vot. În mod similar, când toate statele membre ale unei uniuni vamale sau economice, care poate deveni parte contractantă conform prevederilor
 art. 11 subparagraful b) devin părți contractante, acestea au împreună dreptul la un singur vot.</p><br>

<p>5. Comitetul Sistemului armonizat alege un președinte și unul sau mai mulți vicepreședinți.</p><br>

<p style="text-indent:2em;">6. Regulamentul interior se stabilește prin hotărâre adoptată cu majoritatea a două treimi din voturile exprimate de membrii săi. Acest
 regulament este supus aprobării Consiliului.</p><br>

<p style="text-indent:2em;">7. Comitetul Sistemului armonizat invită, dacă consideră necesar, organizații interguvernamentale sau alte organizații internaționale să
 participe la lucrările sale, în calitate de observatori.</p><br>

<p style="text-indent:2em;">8. Comitetul Sistemului armonizat creează, dacă este cazul, subcomitete sau grupe de lucru, ținând seama, în special, de prevederile
 paragrafului 1 subparagraful a) al art. 7 și stabilește componența, drepturile referitoare la vot și regulamentul interior ale
 acestora.</p><br>

<p style="font-weight:500;">ARTICOLUL 7 Funcțiile Comitetului</p>

<p style="text-indent:2em;"></p><h6>1. Comitetul Sistemului armonizat exercită, ținând seama de prevederile art. 8, următoarele funcții:</h6><p></p>

<p style="text-indent:2em;">a) propune orice proiect de amendament la prezenta convenție, pe care-l socotește de dorit, ținând seama, în special, de necesitățile 
utilizatorilor sau de evoluția tehnicilor și structurilor comerțului internațional;</p><br>

<p>b) redactează note explicative, avize de clasificare și alte avize pentru interpretarea Sistemului armonizat;</p><br>

<p style="font-weight:500;">Jurisprudență (1)</p><br>

<p>c) formulează recomandări pentru a asigura o interpretare și o aplicare uniformă a Sistemului armonizat;</p><br>

<p>d) culege și difuzează orice informații cu privire la aplicarea Sistemului armonizat;</p><br>

<p style="text-indent:2em;">e) furnizează, din oficiu sau la cerere, părților contractante, statelor membre ale Consiliului, precum și organizațiilor interguvernamentale
 și altor organizații internaționale pe care Comitetul le consideră corespunzătoare informații sau îndrumări privind orice aspecte cu privire
 la clasificarea mărfurilor în Sistemul armonizat;</p><br>

<p style="text-indent:2em;">f) prezintă, la fiecare sesiune a Consiliului, rapoarte cu privire la activitatea sa, inclusiv propuneri de amendamente, de note explicative,
 de avize de clasificare și de alte avize;</p><br>

<p style="text-indent:2em;">g) exercită, în ceea ce privește Sistemul armonizat, orice alte atribuții sau funcții pe care Consiliul sau părțile contractante le pot
 considera utile.</p><br>

<p style="text-indent:2em;">2. Deciziile administrative ale Comitetului Sistemului armonizat, care au implicații bugetare, sunt supuse aprobării Consiliului.</p><br>

<p style="font-weight:500;">ARTICOLUL 8 Rolul Consiliului Jurisprudență (1)</p><br>

<p style="text-indent:2em;">1. Consiliul examinează propunerile de amendamente la prezenta convenție, elaborate de Comitetul Sistemului armonizat, și le recomandă părților 
contractante, conform procedurii de la art. 16, numai dacă un stat membru al Consiliului, care este parte contractantă la prezenta convenție, nu
 cere ca toate sau o parte dintre propunerile în cauză să fie restituite Comitetului pentru o nouă examinare.</p><br>

<p style="text-indent:2em;">2. Notele explicative, avizele de clasificare, celelalte avize privind interpretarea Sistemului armonizat și recomandările care vizează asigurarea unei
 interpretări și aplicări uniforme a Sistemului armonizat, formulate în timpul unei sesiuni a Comitetului Sistemului armonizat, conform prevederilor
 paragrafului 1 al art. 7, sunt considerate ca aprobate de către Consiliu, dacă, înainte de sfârșitul celei de-a doua luni de la cea în care această
 sesiune s-a încheiat, nici una dintre părțile contractante la prezenta convenție nu a notificat secretarului general că cere ca problema respectivă
 să fie supusă Consiliului.</p><br>

<p style="text-indent:2em;">3. Atunci când Consiliul este sesizat cu o problemă conform prevederilor paragrafului 2 al prezentului articol, el aprobă respectivele note explicative,
 avizele de clasificare, alte avize sau recomandări, numai dacă un stat membru al Consiliului, care este parte contractantă la prezenta convenție,
 nu cere ca toate sau o parte dintre propunerile respective să fie restituite Comitetului pentru o nouă examinare.</p><br>

<p style="font-weight:500;">ARTICOLUL 9 Nivelul drepturilor vamale</p><br>

<p>Părțile contractante nu își asumă, prin prezenta convenție, nici o obligație în ceea ce privește nivelul drepturilor vamale.</p><br>

<p style="font-weight:500;">ARTICOLUL 10 Reglementarea diferendelor</p><br>

<p style="text-indent:2em;">1. Orice diferend între părțile contractante cu privire la interpretarea sau aplicarea prezentei convenții este soluționat, pe cât posibil, prin negocieri
 directe între părțile respective.</p><br>

<p style="text-indent:2em;">2. Orice diferend care nu a fost soluționat în acest mod este prezentat, de către părțile în dispută, Comitetului Sistemului armonizat, care îl examinează
 și face recomandări în vederea reglementării lui.</p><br>

<p style="text-indent:2em;">3. În cazul în care Comitetul Sistemului armonizat nu poate soluționa diferendul, el îl prezintă Consiliului, care face recomandări în conformitate cu art.
 III lit. e) al Convenției pentru înființarea Consiliului.</p><br>

<p>4. Părțile în dispută pot conveni, în prealabil, să accepte recomandările Comitetului sau ale Consiliului.</p><br>

<p style="font-weight:500;">ARTICOLUL 11 Condiții necesare pentru a deveni parte contractantă</p>

<p style="text-indent:2em;"></p><h6>Pot deveni părți contractante la prezenta convenție:</h6><p></p>

<p>a) statele membre ale Consiliului;</p><br>

<p style="text-indent:2em;">b) uniunile vamale sau economice, cărora li s-a transferat competența de a încheia tratate cu privire la unele sau la toate problemele care intră sub
 incidența prezentei convenții; și</p><br>

<p>c) orice alt stat căruia secretarul general îi adresează o invitație în acest scop, în conformitate cu
 instrucțiunile Consiliului.</p><br>

<p style="font-weight:500;">ARTICOLUL 12 Procedura necesară pentru a deveni parte contractantă</p>

<p style="text-indent:2em;"></p><h6>1. Orice stat sau uniune vamală sau economică, ce îndeplinește condițiile cerute, poate deveni
 parte contractantă la prezenta convenție:</h6><p></p>

<p>a) prin semnare, fără rezerva ratificării;</p><br>

<p>b) prin depunerea unui instrument de ratificare, după semnarea convenției sub rezerva ratificării; sau</p><br>

<p>c) prin aderare, după ce convenția a încetat să fie deschisă pentru semnare.</p><br>

<p style="text-indent:2em;">2. Prezenta convenție este deschisă pentru semnare de statele membre și uniunile vamale sau economice, prevăzute la art. 11, la sediul Consiliului din
 Bruxelles, până la data de 31 decembrie 1986. După această dată, convenția va fi deschisă pentru aderare.</p><br>

<p>3. Instrumentele de ratificare sau de aderare se depun la secretarul general.</p><br>

<p style="font-weight:500;">ARTICOLUL 13 Intrarea în vigoare</p><br>

<p style="text-indent:2em;">1. Prezenta convenție intră în vigoare la prima dată de 1 ianuarie după trecerea unei perioade de cel puțin 12 luni, dar nu mai mult de 24 de luni, de la
 data la care 17 state sau uniuni economice sau vamale, prevăzute la art. 11 de mai sus, au semnat-o fără rezerva ratificării sau au depus instrumentele
 lor de ratificare sau de aderare, dar nu înainte de 1 ianuarie 1987.</p><br>

<p style="text-indent:2em;">2. Pentru orice stat sau uniune vamală sau economică ce semnează prezenta convenție fără rezerva ratificării, care o ratifică sau care aderă la ea, după ce numărul
 minim cerut conform paragrafului 1 al prezentului articol a fost atins, prezenta convenție intră în vigoare la prima dată de 1 ianuarie după trecerea unei perioade
 de cel puțin 12 luni, dar nu mai mult de 24 de luni, dată la care acest stat sau uniune economică sau vamală a semnat convenția fără rezerva ratificării sau a depus
 instrumentul său de ratificare sau de aderare, dacă nu se precizează o dată mai apropiată. Totuși data intrării în vigoare, conform prevederilor prezentului paragraf,
 nu poate fi anterioară datei de intrare în vigoare prevăzute la paragraful 1 al prezentului articol.</p><br>

<p style="font-weight:500;">ARTICOLUL 14 Aplicarea de către teritoriile dependente</p><br>

<p style="text-indent:2em;">1. Orice stat poate, fie la data la care devine parte contractantă la prezenta convenție, fie la o dată ulterioară, să notifice secretarului general că această convenție
 se extinde asupra tuturor sau asupra unora dintre teritoriile ale căror relații internaționale sunt sub responsabilitatea sa și care sunt menționate în notificare.
 Această notificare intră în vigoare la data de 1 ianuarie după trecerea unei perioade de cel puțin 12 luni, dar nu mai mult de 24 de luni de la data la care secretarul
 general o primește, numai dacă în notificare nu este precizată o dată mai apropiată. Totuși prezenta convenție nu poate deveni aplicabilă pentru aceste teritorii
 înaintea intrării sale în vigoare pentru statul interesat.</p><br>

<p style="text-indent:2em;">2. Prezenta convenție încetează de a fi aplicabilă într-un teritoriu desemnat, la data la care relațiile internaționale ale acestui teritoriu nu mai sunt sub
 responsabilitatea părții contractante sau la o dată anterioară notificată secretarului general, 
 conform prevederilor art. 15.</p><br>

<p style="font-weight:500;">ARTICOLUL 15 Denunțare</p><br>

<p style="text-indent:2em;">Prezenta convenție este încheiată pentru o durată nelimitată. Totuși oricare parte contractantă o poate denunța și denunțarea intră în vigoare la un an de la
 primirea de către secretarul general a instrumentului de denunțare, numai dacă în 
 acesta nu este precizată o dată mai îndepărtată.</p><br>

<p style="font-weight:500;">ARTICOLUL 16 Procedura de amendare</p><br>

<p>1. Consiliul poate recomanda părților contractante amendamente la prezenta convenție.</p><br>

<p style="text-indent:2em;">2. Orice parte contractantă poate notifica secretarului general că formulează o obiecție la un amendament recomandat și poate ulterior să-și retragă această
obiecție în termenul precizat la paragraful 3 al prezentului articol.</p><br>

<p style="text-indent:2em;">3. Orice amendament recomandat este considerat acceptat la expirarea unui termen de 6 luni de la data la care secretarul general a notificat respectivul
 amendament, cu condiția ca, până la expirarea acestui termen, să nu existe nici o obiecție.</p>

<p style="text-indent:2em;"></p><h6>4. Amendamentele acceptate intră în vigoare pentru toate părțile contractante la una dintre următoarele date:</h6><p></p>

<p style="text-indent:2em;">a) în cazul în care amendamentul recomandat este notificat înainte de 1 aprilie, data intrării în vigoare va fi 1 ianuarie a celui de-al doilea an care urmează
 datei acestei notificări; sau</p><br>

<p style="text-indent:2em;">b) în cazul în care amendamentul recomandat este notificat la 1 aprilie sau ulterior, data intrării în vigoare va fi 1 ianuarie a celui de-al treilea an care
 urmează datei acestei notificări.</p><br>

<p style="text-indent:2em;">5. Nomenclaturile statistice ale fiecărei părți contractante, precum și nomenclatura sa tarifară sau, în cazul prevăzut la paragraful 1 subparagraful c) al art.
 3, nomenclatura sa tarifară și statistică combinată trebuie să fie puse în concordanță cu Sistemul armonizat amendat la data prevăzută la paragraful 4 al
 prezentului articol.</p><br>

<p style="text-indent:2em;">6. Orice stat sau uniune vamală sau economică ce semnează prezenta convenție fără rezerva ratificării, care o ratifică sau care aderă la ea, se consideră că a
 acceptat toate amendamentele care, la data când acest stat sau uniune a devenit parte contractantă, au intrat în vigoare sau care au fost acceptate conform
 prevederilor paragrafului 3 al prezentului articol.</p><br>

<p style="font-weight:500;">ARTICOLUL 17 Drepturile părților contractante cu privire la Sistemul armonizat</p>

<p style="text-indent:2em;"></p><h6>Pentru problemele referitoare la Sistemul armonizat, paragraful 4 al art. 6, art. 8 și paragraful 2 al art. 16 conferă fiecărei părți contractante
 drepturi:</h6><p></p>

<p style="text-indent:2em;">a) în ceea ce privește toate părțile Sistemului armonizat pe care le aplică în conformitate cu prevederile prezentei convenții; sau</p><br>

<p style="text-indent:2em;">b) până la data la care prezenta convenție intră în vigoare, în ceea ce o privește, conform prevederilor art. 13 și în ceea ce privește toate părțile Sistemului
 armonizat pe care este obligată să le aplice la data respectivă, conform prevederilor prezentei convenții; sau</p><br>

<p style="text-indent:2em;">c) în ceea ce privește toate părțile Sistemului armonizat, cu condiția ca partea contractantă să se fi angajat formal că aplică întregul Sistem armonizat cu codificarea din șase cifre,
 în termenul de 3 ani prevăzut la paragraful 5 al art. 4 și până la expirarea acestui termen.</p><br>

<p style="font-weight:500;">ARTICOLUL 18 Rezerve</p><br>

<p>Nu se admite nici o rezervă la prezenta convenție.</p><br>

<p style="font-weight:500;">ARTICOLUL 19 Notificări ale secretarului general</p>

<p style="text-indent:2em;"></p><h6>Secretarul general notifică părților contractante, celorlalte state semnatare, statelor membre ale Consiliului, care nu sunt părți contractante la prezenta Convenție, și secretarului
 general al Organizației Națiunilor Unite:</h6><p></p>

<p>a) notificările primite conform art. 4;</p><br>

<p>b) semnăturile, ratificările și aderările menționate la art. 12;</p><br>

<p>c) data la care prezenta convenție intră în vigoare, conform art. 13;</p><br>

<p>d) notificările primite conform art. 14;</p><br>

<p>e) denunțările primite conform art. 15;</p><br>

<p>f) amendamentele la prezenta convenție, recomandate conform art. 16;</p><br>

<p>g) obiecțiile formulate la amendamentele recomandate conform art. 16, precum și retragerea eventuală a acestora;</p><br>

<p>h) amendamentele acceptate conform art. 16, precum și data intrării lor în vigoare.</p><br>

<p style="font-weight:500;">ARTICOLUL 20 Înregistrarea la Națiunile Unite</p><br>

<p style="text-indent:2em;">Conform art. 102 din Carta Națiunilor Unite, prezenta convenție este înregistrată la Secretariatul Națiunilor Unite,
 la cererea secretarului general al Consiliului.</p><br>

<p>Drept pentru care, subsemnații, pe deplin autorizați, au semnat prezenta convenție.</p><br>

<p style="text-indent:2em;">Încheiată la Bruxelles, la 14 iunie 1983, în limbile franceză și engleză, ambele texte având aceeași valoare, într-un singur exemplar original ce se depune la secretarul general al Consiliului,
 care va transmite copii certificate pentru conformitate tuturor statelor și uniunilor vamale sau economice
 menționate la art. 11.</p><br>

<p style="font-weight:500;">ANEXĂ Jurisprudență (9)</p><br>

<p style="font-weight:500;">NOMENCLATURA SISTEMULUI ARMONIZAT</p><br>

<p style="font-weight:500;">CUPRINS</p><br>

<p>Reguli generale pentru interpretarea Sistemului armonizat</p><br>

<p style="font-weight:500;">Secțiunea I Animale vii și produse ale regnului animal</p><br>

<p style="font-weight:500;">Note de secțiune</p><br>

<p>1. Animale vii</p><br>

<p>2. Carne și organe comestibile</p><br>

<p>3. Pește și crustacee, moluște și alte nevertebrate acvatice</p><br>

<p style="text-indent:2em;">4. Lapte și produse lactate; ouă de păsări; miere naturală; produse comestibile de origine animală, nedenumite și necuprinse în altă parte</p><br>

<p>5. Alte produse de origine animală, nedenumite și necuprinse în altă parte</p><br>

<p style="font-weight:500;">Secțiunea a II-a Produse ale regnului vegetal</p><br>

<p style="font-weight:500;">Note de secțiune</p><br>

<p>6. Plante vii și produse de horticultură</p><br>

<p>7. Legume, plante, rădăcini și tuberculi alimentari</p><br>

<p>8. Fructe comestibile; coji de citrice și de pepeni</p><br>

<p>9. Cafea, ceai, mate și condimente</p><br>

<p>10. Cereale</p><br>

<p>11. Produse ale industriei morăritului; malț; amidon și fecule; inulină; gluten de grâu</p><br>

<p>12. Semințe și fructe oleaginoase; semințe și fructe diverse; plante industriale sau medicinale; paie și furaje</p><br>

<p>13. Gume, rășini și alte seve și extracte vegetale</p><br>

<p>14. Materiale de împletit și alte produse de origine vegetală, nedenumite și necuprinse în altă parte</p><br>

<p style="font-weight:500;">Secțiunea a III-a Grăsimi și uleiuri de origine animală sau vegetală;
produse ale disocierii lor; grăsimi alimentare prelucrate;
ceară de origine animală sau vegetală</p><br>

<p style="text-indent:2em;">15. Grăsimi și uleiuri de origine animală sau vegetală; produse ale disocierii lor; grăsimi alimentare prelucrate; ceară de origine animală sau vegetală</p><br>

<p style="font-weight:500;">Secțiunea a IV-a Produse ale industriilor alimentare; băuturi,
lichide alcoolice și oțet; tutun și înlocuitori de tutun prelucrați
Note de secțiune</p><br>

<p>16. Preparate din carne, din pește sau din crustacee, din moluște sau din alte nevertebrate acvatice</p><br>

<p>17. Zaharuri și produse zaharoase</p><br>

<p>18. Cacao și preparate din cacao</p><br>

<p>19. Preparate pe bază de cereale, de făină, de amidon, de fecule sau de lapte; produse de patiserie</p><br>

<p>20. Preparate din legume, din fructe sau din alte părți de plante</p><br>

<p>21. Preparate alimentare diverse</p><br>

<p>22. Băuturi, lichide alcoolice și oțeturi</p><br>

<p>23. Reziduuri și deșeuri ale industriei alimentare; ali mente preparate pentru animale</p><br>

<p>24. Tutun și înlocuitori de tutun, prelucrați</p><br>

<p style="font-weight:500;">Secțiunea a V-a Produse minerale</p><br>

<p>25. Sare; sulf; pământuri și pietre; ipsos, var și ciment</p><br>

<p>26. Minereuri, zgură și cenușă</p><br>

<p>27. Combustibili minerali, uleiuri minerale și produsele distilării acestora; materii bituminoase; ceară minerală</p><br>

<p style="font-weight:500;">Secțiunea a VI-a Produse ale industriei chimice sau ale industriilor conexe</p><br>

<p style="font-weight:500;">Note de secțiune</p><br>

<p style="text-indent:2em;">28. Produse chimice anorganice; compuși anorganici sau organici ai metalelor prețioase, ai elementelor radioactive, ai pământurilor rare sau ai izotopilor</p><br>

<p>29. Produse chimice organice</p><br>

<p>30. Produse farmaceutice</p><br>

<p>31. Îngrășăminte</p><br>

<p style="text-indent:2em;">32. Extracte tanante sau colorante; tanini și derivații lor; pigmenți și alte materii colorante; vopsele și lacuri; masticuri; cerneluri</p><br>

<p>33. Uleiuri esențiale și rezinoide; produse de parfumerie sau de toaletă, preparate, și preparate cosmetice</p><br>

<p style="text-indent:2em;">34. Săpunuri, agenți de suprafață organici, preparate pentru spălat, preparate lubrifiante, ceară artificială, ceară preparată, produse pentru întreținere, lumânări și articole similare,
 paste de modelat, ceară pentru tehnica dentară și preparate pentru tehnica dentară pe bază de ipsos</p><br>

<p>35. Substanțe albuminoide; produse pe bază de amidon sau de fecule modificate; cleiuri; enzime</p><br>

<p>36. Pulberi și explozivi; articole de pirotehnie; chibrituri; aliaje piroforice; materii inflamabile</p><br>

<p>37. Produse fotografice sau cinematografice</p><br>

<p>38. Produse diverse ale industriei chimice</p><br>

<p style="font-weight:500;">Secțiunea a VII-a Materiale plastice și articole din aceste materiale;
cauciuc și articole din cauciuc
Note de secțiune</p><br>

<p>39. Materiale plastice și articole din aceste materiale</p><br>

<p>40. Cauciuc și articole din cauciuc</p><br>

<p style="font-weight:500;">Secțiunea a VIII-a Piei brute, piei tăbăcite, blănuri și produse din acestea;
articole de curelărie și de șelărie; articole de voiaj,
sacoșe de mână și articole similare;
articole obținute din intestine de animale</p><br>

<p>41. Piei brute și piei tăbăcite</p><br>

<p style="text-indent:2em;">42. Obiecte din piele; articole de curelărie sau de șelărie; articole de voiaj,
 sacoșe și produse similare; produse din intestine de animale</p><br>

<p>43. Blănuri și articole din blană; blănuri artificiale</p><br>

<p style="font-weight:500;">Secțiunea a IX-a Lemn, cărbune de lemn și articole din lemn; plută și lucrări din
plută; lucrări din împletituri din fibre vegetale sau din nuiele</p><br>

<p>44. Lemn, cărbune de lemn și articole din lemn</p><br>

<p>45. Plută și articole din plută</p><br>

<p>46. Produse din împletituri din fibre vegetale sau din nuiele</p><br>

<p style="font-weight:500;">Secțiunea a X-a Pastă de lemn sau din alte materiale fibroase celulozice; deșeuri și
rebuturi de hârtie sau de carton; hârtie și articole din hârtie</p><br>

<p>47. Pastă de lemn sau din alte materiale fibroase celulozice; deșeuri și
 rebuturi de hârtie sau de carton</p><br>

<p>48. Hârtie și carton; articole din pastă de celuloză, din hârtie sau din carton</p><br>

<p>49. Produse de editură, de presă sau ale altor industrii grafice; texte manuscrise,
 texte dactilografiate și planuri</p><br>

<p style="font-weight:500;">Secțiunea a XI-a Materiale textile și articole din aceste materiale
Note de secțiune</p><br>

<p>50. Mătase</p><br>

<p>51. Lână, păr fin sau grosier de animale; fire și țesături din păr de cal</p><br>

<p>52. Bumbac</p><br>

<p>53. Alte fibre textile vegetale; fire de hârtie și țesături din fire de hârtie</p><br>

<p>54. Filamente sintetice sau artificiale</p><br>

<p>55. Fibre sintetice sau artificiale discontinue</p><br>

<p>56. Vată, pâslă (fetru) și materiale nețesute; fire speciale; sfori, corzi, funii, frânghii; articole din acestea</p><br>

<p>57. Covoare și alte acoperitoare de podea din materiale textile</p><br>

<p>58. Țesături speciale; țesături buclate; dantele; tapiserii, pasmanterii; broderii</p><br>

<p>59. Țesături impregnate, unse, acoperite sau stratificate; articole tehnice din materiale textile</p><br>

<p>60. Materiale tricotate sau croșetate</p><br>

<p>61. Articole și accesorii de îmbrăcăminte, tricotate sau croșetate</p><br>

<p>62. Articole de îmbrăcăminte și accesorii de îmbrăcăminte, altele decât tricotate sau croșetate</p><br>

<p>63. Alte articole textile confecționate; seturi, îmbrăcăminte purtată sau uzată; zdrențe</p><br>

<p style="font-weight:500;">Secțiunea a XII-a Încălțăminte, articole de acoperit capul, umbrele, umbrele de soare,
bastoane, bice, cravașe și părți ale acestora; pene și fulgi apretați și
articole din acestea; flori artificiale; articole din păr uman</p><br>

<p>64. Încălțăminte, ghetre și articole similare; părți ale acestor articole</p><br>

<p>65. Obiecte de acoperit capul și părți ale acestora</p><br>

<p>66. Umbrele, umbreluțe de soare, parasolare, bastoane, bice, cravașe și părți ale acestora</p><br>

<p>67. Pene și puf, apretate, și articole din pene sau puf; flori artificiale; articole din păr uman</p><br>

<p style="font-weight:500;">Secțiunea a XIII-a Articole din piatră, ipsos, ciment, azbest, mică sau din materiale
similare; produse ceramice; sticlă și articole din sticlă
68. Articole din piatră, ipsos, ciment, azbest, mică sau din materiale similare</p><br>

<p>69. Produse ceramice</p><br>

<p>70. Sticlă și articole din sticlă</p><br>

<p style="font-weight:500;">Secțiunea a XIV-a Perle naturale sau de cultură, pietre prețioase sau semiprețioase,
metale prețioase, metale placate sau suflate cu metale prețioase și
obiecte din acestea; bijuterii de fantezie; monede</p><br>

<p style="text-indent:2em;">71. Perle naturale sau de cultură, pietre prețioase sau semiprețioase, metale prețioase, metale placate sau suflate cu metale prețioase
 și obiecte din acestea; bijuterii de fantezie; monede</p><br>

<p style="font-weight:500;">Secțiunea a XV-a Metale comune și articole din metale comune
Note de secțiune</p><br>

<p>72. Fontă, fier și oțel</p><br>

<p>73. Produse din fontă, fier sau oțel</p><br>

<p>74. Cupru și articole din cupru</p><br>

<p>75. Nichel și articole din nichel</p><br>

<p>76. Aluminiu și articole din aluminiu</p><br>

<p>77. (Rezervat pentru o eventuală utilizare viitoare în cadrul Sistemului armonizat)</p><br>

<p>78. Plumb și articole din plumb</p><br>

<p>79. Zinc și articole din zinc</p><br>

<p>80. Staniu (cositor) și articole din staniu</p><br>

<p>81. Alte metale comune; metalo-ceramice; articole din acestea</p><br>

<p>82. Unelte și scule, tacâmuri (cuțite, linguri, furculițe), din metale comune; părți ale acestor articole, din metale comune</p><br>

<p>83. Articole diverse din metale comune</p><br>

<p style="font-weight:500;">Secțiunea a XVI-a Mașini și aparate, echipamente electrice și părți ale acestora;
aparate de înregistrat sau de reprodus sunetul, aparate de înregistrat
sau de reprodus imagini și sunet de televiziune și părți
și accesorii ale acestor aparate
Note de secțiune</p><br>

<p>84. Reactori nucleari, încălzitoare de apă, mașini, aparate și dispozitive mecanice; părți ale acestora</p><br>

<p style="text-indent:2em;">85. Mașini, aparate și echipamente electrice și părți ale acestora; aparate de înregistrat sau de reprodus sunetul, aparate de înregistrat
 sau de reprodus imagini și sunet de televiziune și părți și accesorii ale acestor aparate</p><br>

<p style="font-weight:500;">Secțiunea a XVII-a Mijloace de transport Jurisprudență (1)
Note de secțiune</p><br>

<p style="text-indent:2em;">86. Vehicule și echipamente pentru căile ferate sau similare și părți ale acestora; aparate mecanice (inclusiv electromecanice) pentru
 semnalizări de trafic</p><br>

<p>87. Autovehicule, tractoare, vehicule pe două sau trei roți și alte vehicule terestre, părți și accesorii ale acestora</p><br>

<p>88. Navigație aeriană sau spațială</p><br>

<p>89. Navigație maritimă sau fluvială</p><br>

<p style="font-weight:500;">Secțiunea a XVIII-a Instrumente și aparate optice, fotografice sau cinematografice,
de măsură, de control sau de precizie; instrumente și aparate
medico-chirurgicale; ceasornicărie; instrumente muzicale;
părți și accesorii ale acestora</p><br>

<p style="text-indent:2em;">90. Instrumente și aparate optice, fotografice sau cinematografice, de măsură, de control sau de precizie, instrumente și aparate medico
-chirurgicale; părți și accesorii ale acestora</p><br>

<p>91. Ceasornicărie</p><br>

<p>92. Instrumente muzicale; părți și accesorii pentru instrumente muzicale</p><br>

<p style="font-weight:500;">Secțiunea a XIX-a Arme și muniții; părți și accesorii ale acestora</p><br>

<p>93. Arme, muniții, părți și accesorii ale acestora</p><br>

<p style="font-weight:500;">Secțiunea a XX-a Mărfuri și produse diverse</p><br>

<p style="text-indent:2em;">94. Mobilă; mobilier medico-chirurgical; articole de pat și similare; aparate de iluminat, nedenumite și neincluse în altă parte; lămpi
 pentru firme și reclame luminoase, indicatoare luminoase și articole similare; construcții prefabricate</p><br>

<p>95. Jucării, jocuri, articole pentru divertisment sau pentru sport; părți și accesorii ale acestora</p><br>

<p>96. Articole diverse</p><br>

<p style="font-weight:500;">Secțiunea a XXI-a Obiecte de artă, de colecție sau de antichitate</p><br>

<p>97. Obiecte de artă, de colecție sau de antichitate</p><br>

<p>98. (Rezervat pentru unele utilizări deosebite de către părțile contractante)</p><br>

<p>99. (Rezervat pentru unele utilizări deosebite de către părțile contractante).</p>

<p></p><h4>REGULI GENERALE PENTRU INTERPRETAREA
SISTEMULUI ARMONIZAT</h4><p></p>

<p style="font-weight:500;">Jurisprudență (2)</p>

<p style="text-indent:2em;"></p><h6>Încadrarea mărfurilor în Nomenclatura pentru clasificarea mărfurilor în tarifele vamale se face conform
 următoarelor principii:</h6><p></p>

<p style="text-indent:2em;"></p><h6>1. Enunțul titlurilor secțiunilor, capitolelor sau subcapitolelor este considerat ca având numai o valoare indicativă, încadrarea mărfii
 considerându-se legal determinată atunci când este în concordanță cu textul pozițiilor și al notelor de secțiuni și de capitole și atunci
 când nu sunt contrare termenilor utilizați în acele poziții și note, după următoarele reguli:</h6><p></p>

<p style="font-weight:500;">Jurisprudență (3)</p><br>

<p>2.</p><br>

<p style="font-weight:500;">Jurisprudență (1)</p><br>

<p style="text-indent:2em;">a) Orice referire la un articol de la o poziție determinată acoperă acest articol, chiar incomplet sau nefinit, cu condiția ca el să prezinte,
 ca atare, caracteristicile esențiale ale articolului complet sau finit. Această regulă se aplică și la articolul complet sau finit, sau considerat
 ca atare, în virtutea dispozițiilor precedente, atunci când articolul este prezentat demontat sau nemontat;</p><br>

<p style="font-weight:500;">Jurisprudență (1)</p><br>

<p style="text-indent:2em;">b) orice mențiune la o materie de la o poziție determinată se referă la acea materie fie în stare pură, fie în amestec sau asociată cu alte materii.
 De asemenea, orice mențiune la produse dintr-o anumită materie se referă la produsele alcătuite total sau parțial din această materie. Încadrarea
 acestor produse amestecate sau a articolelor asociate se face conform principiilor enunțate în regula 3.</p><br>

<p style="font-weight:500;">Jurisprudență (1)</p>

<p style="text-indent:2em;"></p><h6>3. Atunci când mărfurile ar putea fi încadrate la două sau mai multe poziții, prin aplicarea regulii 2 b), sau în orice alt caz, încadrarea se face
 după cum urmează:</h6><p></p>

<p style="text-indent:2em;">a) poziția cea mai specifică are prioritate față de pozițiile cu un domeniu de aplicare mai general. Totuși, atunci când două sau mai multe poziții
 se referă fiecare numai la o parte dintre materiile care compun un produs amestecat sau la un articol compozit, sau la o parte dintre articolele
 mărfurilor prezentate în seturi condiționate pentru vânzarea cu amănuntul, aceste poziții se consideră, în raport cu produsul sau articolul, la 
 fel de specifice, chiar dacă una dintre poziții dă o descriere mai precisă sau mai completă;</p><br>

<p style="text-indent:2em;">b) produsele amestecate, articolele compuse din materii diferite sau constituite prin asamblarea unor articole diferite și mărfurile prezentate
 în seturi condiționate pentru vânzarea cu amănuntul, a căror încadrare nu poate fi efectuată prin aplicarea regulii 3 a), sunt încadrate după
 materia sau articolul care le conferă caracterul esențial, atunci când este posibilă efectuarea acestei determinări;</p><br>

<p style="font-weight:500;">Jurisprudență (1)</p><br>

<p style="text-indent:2em;">c) în cazul în care regulile 3 a) și 3 b) nu permit efectuarea încadrării, marfa se încadrează la poziția cu numărul cel mai mare dintre pozițiile
 susceptibile de a putea fi luate în considerare.</p><br>

<p style="text-indent:2em;">4. Mărfurile care nu pot fi încadrate în virtutea regulilor precedente se încadrează la poziția aferentă
 articolelor cele mai asemănătoare.</p>

<p style="text-indent:2em;"></p><h6>5. În afara dispozițiilor precedente, pentru mărfurile specificate mai jos se aplică următoarele reguli:</h6><p></p>

<p style="text-indent:2em;">a) cutiile pentru aparate fotografice, pentru instrumente muzicale, pentru arme, pentru instrumente de desen, cutiile pentru bijuterii și similare,
 special amenajate pentru a conține un articol determinat sau un set, susceptibile de a avea o utilizare îndelungată și prezentate împreună cu articolele
 cărora le sunt destinate, se încadrează împreună cu aceste articole când, în mod normal, se vând împreună. Totuși această regulă nu se aplică dacă
 articolele de mai sus conferă ansamblului caracterul esențial;</p><br>

<p style="text-indent:2em;">b) sub rezerva dispozițiilor regulii 5 a) de mai sus, ambalajele care conțin mărfuri se încadrează cu acestea din urmă atunci când fac parte din categoria
 celor folosite în mod normal pentru astfel de mărfuri. Totuși această dispoziție nu se aplică, dacă ambalajele sunt folosite repetat,
 în mod obligatoriu.</p><br>

<p style="text-indent:2em;">6. Încadrarea mărfurilor în subpozițiile unei poziții se efectuează, în mod legal, prin respectarea termenilor acelor subpoziții și ale notelor de subpoziții,
 cu care se află în relație și cu respectarea regulilor de mai sus, înțelegând prin aceasta că nu pot fi comparate decât subpozițiile aflate pe același nivel.
 În sensul acestei reguli, se utilizează și notele de secțiuni și capitole corespunzătoare, cu excepția cazului în care conțin dispoziții
 contrare.</p><br>

<p style="font-weight:500;">Jurisprudență (3)</p><br>

<p><a href="https://lege5.ro/Gratuit/g44dsnbw/conventia-internationala-privind-sistemul-armonizat-de-denumire-si-codificare-a-marfurilor-incheiata-la-bruxelles-la-14-iunie-1983">Sursa</a></p>

</div>
`