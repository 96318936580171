import React, { Component } from 'react'
import _ from 'lodash'
import { Field } from 'redux-form'

import { changeWidgetFilter } from '../../shared/utility'
import Filter from '../../shared/components/widget-filter'
import Dropdown from '../../UI/dropdown'
import SearchTable from '../table'
import { SearchResultsProps, SearchResultsState } from './types'
import { scrollToElement } from '../../../store/utils'

import './styles.scss'
import dict from '../../shared/dictionaries'

const dictPageSize = [
  { name: '10', value: 10 },
  { name: '20', value: 20 },
  { name: '50', value: 50 },
]

export default class SearchResults extends Component<SearchResultsProps, SearchResultsState> {
  constructor(props: SearchResultsProps) {
    super(props)

    const { t } = props
    const items = [
      {
        value: 'from-last-visit',
        name: t('ads.lastVist'),
      },
      {
        value: 'today',
        name: t('ads.todays'),
      },
      {
        value: 'last-3-days',
        name: t('ads.lastThreeDays'),
      },
      {
        value: 'all',
        name: t('all'),
      },
    ]

    this.state = {
      filterBar: {
        items,
        selectedKey: props.filterKey,
        onClick: filterKey => {
          changeWidgetFilter(this, filterKey)
          this.props.change('filterKey', filterKey)
          this.refresh(0, filterKey)
        },
      },
    }
  }

  componentDidMount() {
    const {
      search,
      initialize,
      initialValues,
      isMyOffers,
      userId,
      filterKey,
      sameCountryOnly
    } = this.props

    if (isMyOffers) {
      initialValues.filterKey = 'active'
      initialize(initialValues)
      this.updateFilterBarItems()
      changeWidgetFilter(this, 'active')
      search({
        pageSize: initialValues.pageSize,
        exactUserId: isMyOffers && userId,
        dateScope: 'all',
        activeScope: 'active',
      })
    } else if (sameCountryOnly === "true") {
      this.refresh()
    } else {
      this.setState({ filterBar: { selectedKey: filterKey } })

      const fKey = filterKey || initialValues.filterKey
      initialize({ ...initialValues, fKey })
      changeWidgetFilter(this, fKey)
      setTimeout(() => {
        this.refresh(0, fKey)
      }, 500);
    }
  }

  componentDidUpdate(oldProps: SearchResultsProps) {
    const {
      userId,
      refreshing,
      statistics,
      isMyOffers,
      socketRefreshSearchFinish,
      scrollToOncommingItems,
      setScrollToOncommingItems} = this.props

    if (!oldProps.userId && userId) {
      this.refresh()
    }

    if (!oldProps.refreshing && refreshing) {
      this.refresh()
      socketRefreshSearchFinish()
    }

    if (isMyOffers && JSON.stringify(oldProps.statistics) !== JSON.stringify(statistics)) {
      this.updateFilterBarItems()
    }

    setTimeout(() => {
      if (scrollToOncommingItems) {
        scrollToElement("inCommingItems")
        setScrollToOncommingItems(false)
      }
    }, 1500);
  }

  updateFilterBarItems = () => {
    const {
      t,
      searchType,
      statistics: { cargo, cargoTotal, transport, transportTotal },
    } = this.props
    const { filterBar } = this.state
    const isCargo = searchType === 'cargo'

    const allCount = isCargo ? cargoTotal : transportTotal
    const activeCount = isCargo ? cargo : transport
    const unactiveCount = allCount - activeCount

    filterBar.items = [
      {
        value: 'all',
        name: `${t('all')} (${allCount})`,
      },
      {
        value: 'active',
        name: `${t('active')} (${activeCount})`,
      },
      {
        value: 'unactive',
        name: `${t('unactive')} (${unactiveCount})`,
      },
    ]
    this.setState({ filterBar })
  }

  refresh = (pageIndex?: number, filterKey?: string) => {
    const { search, isMyOffers, userId, pageSize, searchForm, sameCountryOnly } = this.props
    const { selectedKey } = this.state.filterBar
    const query = searchForm.search?.values
    const dateScope = isMyOffers ? null : filterKey || selectedKey
    const activeScope = isMyOffers ? filterKey || selectedKey : null

    search({
      query,
      pageIndex,
      pageSize,
      exactUserId: isMyOffers && userId,
      dateScope,
      activeScope,
      sameCountryOnly: sameCountryOnly === "true"
    })
  }

  onAutoupdateCheck = e => {
    localStorage.setItem('autoupdate', e.target.checked)
  }

  onOncomingTransport = e => {
    return true;
  }

  // ------------------------------
  // draw table row cells
  // ------------------------------

  render() {
    const {
      t,
      processing,
      items,
      totalItems,
      addFavourite,
      deleteFavourite,
      notificationUnsubscribe,
      pageIndex,
      pageSize,
      openIn,
      queryStr,
      userId,
      searchType,
      isMyOffers,
      history,
      bannerId,
      showDateIsShort = false,
      needToShowAdsNearPagination = false,
      autoupdate,
    } = this.props
    const dictSortBy = dict.cargoSortBy(t)

    const dictOpenIn = [
      { name: t('preview'), value: 'preview' },
      { name: t('open.in.window'), value: 'window' },
    ]

    const dictOffersFilter = [
      { name: t('statistics.my.cargo'), value: 'cargo' },
      { name: t('statistics.my.transport'), value: 'transport' },
    ]

    return (
      <div className="panel panel-table panel-table-lg">
        <div className="panel-body">
          {isMyOffers && (
            <Filter
              items={dictOffersFilter}
              loadLanguages
              selectedKey={searchType}
              onClick={searchType => history.push(`/${searchType}/offers`)}
            />
          )}
          <Filter {...this.state.filterBar} extended loadLanguages>
            <div className="filter-container">
              {!isMyOffers && (
                <div className="filter-block">
                  <label htmlFor="or1">{t('open.by.click') + ':'}</label>
                  <div className="select-container">
                    <Field name="openIn" component={Dropdown} items={dictOpenIn} />
                  </div>
                </div>
              )}
              <div className="filter-block">
                <label htmlFor="or2">{t('sort') + ':'}</label>
                <div className="select-container">
                  <Field name="sortBy" component={Dropdown} items={dictSortBy} />
                </div>
              </div>
              <div className="filter-block filter-block-sm">
                <label htmlFor="or3">{t('ads.quantity') + ':'}</label>
                <div className="select-container">
                  <Field name="pageSize" component={Dropdown} items={dictPageSize} />
                </div>
              </div>
            </div>
          </Filter>
          <div className="panel-content">
            {!isMyOffers && (
              <div className="panel-content-header panel-content-header-cargo">
                <div className="panel-content-header-left">
                  <h4 className="label-items-found">{`${t(
                    'cargo.found.items'
                  )}: ${totalItems}`}</h4>
                </div>
                <div className="panel-content-header-right">
                  <div className="input-container">
                    <input
                      type="checkbox"
                      className="show-only-expires-packets"
                      id="sh1"
                      checked={JSON.parse(autoupdate)}
                      onChange={this.onAutoupdateCheck}
                    />
                    <label htmlFor="sh1">{t('cargo.autoupdate.results')}</label>



                  </div>
                </div>
                <span className="meta">
                  {queryStr ? t('cargo.search.query') + ': ' + queryStr : null}
                </span>
              </div>
            )}
            <div className="panel-content-table panel-content-table-cargo">
              <SearchTable
                userId={userId}
                items={items}
                totalItems={totalItems}
                pageIndex={pageIndex}
                pageSize={pageSize}
                searchType={searchType}
                addFavourite={addFavourite}
                deleteFavourite={deleteFavourite}
                notificationUnsubscribe={notificationUnsubscribe}
                openIn={openIn}
                processing={processing}
                refresh={this.refresh}
                isMyOffers={isMyOffers}
                {...{ needToShowAdsNearPagination }}
                {...{ showDateIsShort }}
                {...{ bannerId }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
