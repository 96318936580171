import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reduxForm } from 'redux-form'

import Component from './component'
import withTranslation from '../../../../globals/components/withTranslation'
import { recoverPasswordSetNewPassword } from '../../../../store/actions/auth'

const FORMNAME = 'userRecoverPassword'

const initialValues = {
  password: undefined,
  password2: undefined,
}

export default compose(
  withTranslation,
  connect(),
  reduxForm({
    form: FORMNAME,
    initialValues,
    onSubmit: (values, dispatch, props) => {
      const { password } = values
      const { token } = props.match.params
      return dispatch(
        recoverPasswordSetNewPassword({ token, password }, () => props.history.push('/'))
      )
    },
  })
)(Component)
