import baseConfig from './base'

const rootUrl = 'http://localhost:7000'
const config = {
  appEnv: 'test',
  apiUrl: `${rootUrl}/api`,
  ioUrl: rootUrl,
  sentry: false, // {dsn: 'https://<key>@sentry.io/<app>'}
  piwik: false, // {url: '//analytics.transport.md', siteId: 1}
  userInactiveMinutes: 15, // time in minutes after user is considered inactive
  supportTelephone: '+373-68-191-361',
  supportEmail: 'rbuiucli@netinfo.md',
}

export default Object.freeze(Object.assign(baseConfig, config))
