export const md12 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>ПОЛОЖЕНИЕ о порядке выдачи и учета разрешений на международные автомобильные перевозки</h4><p></p>

<p></p><h4>ПОЛОЖЕНИЕ о порядке выдачи и учета разрешений на международные автомобильные перевозки</h4><p></p>

<p></p><h4>I. ЦЕЛЬ И ОБЛАСТЬ ПРИМЕНЕНИЯ</h4><p></p>

<p style="font-weight:500;">1.1. Настоящее положение разработано в целях установления порядка получения, выдачи и использования одноразовых разрешений на международные автоперевозки в соответствии с межправительственными
 соглашениями в области автоперевозок.</p>

<p></p><h4>II. ОПРЕДЕЛЕНИЯ ИСПОЛЬЗУЕМЫХ ТЕРМИНОВ</h4><p></p>

<p></p><h6>a) Одноразовое разрешение</h6><p></p>

<p style="text-indent:2em;">Представляет собой документ, на основании которого автотранспортное средство, зарегистрированное в одном государстве, может передвигаться по территории другого государства; документ, выданный государством,
 дорожная сеть которого будет использоваться.</p><br>

<p style="text-indent:2em;">Отношения государств в области перевозок регламентируются двусторонними межправительственными соглашениями на правительственном уровне. Данные соглашения предусматривают условия, при которых автотранспортные
 средства одного государства могут использовать дорожную сеть другого государства.</p><br>

<p style="text-indent:2em;">Конкретные элементы применения положений соглашения между двумя государствами находятся в компетенции смешанных комиссий, составленных из специалистов в области перевозок.</p>

<p style="text-indent:2em;"></p><h6>b) Виды разрешений:</h6><p></p> 

<p style="text-indent:2em;">- "двусторонние" разрешения, которые дают право автотранспортному средству использовать дорожную сеть государства, выдавшего разрешение для перевозки грузов в/из данной страны, или из/в страну, где
 зарегистрировано автотранспортное средство;</p><br>

<p style="text-indent:2em;">- "транзитные" разрешения, которые дают право автотранспортному средству использовать дорожную сеть государства, выдавшего разрешения для транзита по его территории, пунктом назначения перевозки
 в данном случае является третья страна, которая находится за пределами территории страны, выдавшей разрешения;</p><br>

<p>- "универсальные" разрешения, которые включают оба действия - "двусторонние" и "транзит";</p><br>

<p style="text-indent:2em;">- "экологические" разрешения, которые дают право использовать дорожную сеть государства, выдавшего разрешение, только если автотранспорт страны регистрации соответствует предусмотренным требованиям экологии;</p><br>

<p style="text-indent:2em;">- разрешения "RO-LA" предоставляются при условии применения технологии транспортировки RO-LA (погрузка автотранспорта на железнодорожные платформы и транзитирование страны по железной дороге);</p><br>

<p style="text-indent:2em;">- разрешения "третья страна", которые дают право автотранспортному средству погрузить товар на территории государства, выдавшего разрешение, и перевезти его в третью страну (другая, чем страна
 регистрации автотранспортного средства);</p><br>

<p style="text-indent:2em;">- разрешения "въезд без груза", которые дают право автотранспортному средству на въезд на территорию государства, выдавшего разрешение, без товара для погрузки в стране регистрации;</p><br>

<p style="text-indent:2em;">- специальные разрешения, которые дают право на осуществление перевозок грузов, превышающих массу и/или габариты, или перевозок опасных грузов в соответствии с законодательством страны, выдавшей разрешения;</p><br>

<p style="text-indent:2em;">- разрешения на регулярные пассажирские перевозки, которые дают право автотранспортному средству на перевозку пассажиров по территориям других стран по регулярным маршрутам, без оплаты дорожных сборов;</p><br>

<p style="text-indent:2em;">- разрешения на нерегулярные пассажирские перевозки, которые дают право автотранспортному средству на перевозку пассажиров по территориям других стран по нерегулярным маршрутам, с оплатой дорожных сборов.</p>

<p style="text-indent:2em;"></p><h6>Каждое из указанных разрешений может быть в свою очередь двух видов:</h6><p></p>

<p>- разрешение с оплатой сборов;</p><br>

<p>- разрешение без оплаты сборов.</p><br>

<p style="text-indent:2em;">Разрешения "без оплаты сборов" дают право автотранспортному средству, при предъявлении такого разрешения, при въезде на территорию государства, выдавшего разрешение, использовать его дорожную сеть
 бесплатно или с оплатой некоторых сборов.</p><br>

<p style="text-indent:2em;">Разрешения "с оплатой сборов" дают право автотранспортному средству использовать дорожную сеть государства, выдавшего разрешение, только если при въезде на территорию данного государства были
 оплачены дорожные сборы.</p>

<p></p><h4>III. БЛАНКИ РАЗРЕШЕНИЙ</h4><p></p>

<p style="font-weight:500;">3.1.  Бланки разрешений являются финансовыми документами строгой отчетности.</p><br>

<p style="font-weight:500;">3.2. Бланки разрешений Республики Молдова изготовлены типографским способом на основании специального заказа, которым присваивается и наносится типографским способом серия и порядковый
 номер.</p><br>

<p style="font-weight:500;">3.3. Количество и виды разрешений определяются в соответствии с положениями международных соглашений в области международных автоперевозок, стороной которых является Республика Молдова.</p>

<p></p><h4>IV. ПЛАТА ЗА ПОЛУЧЕНИЕ РАЗРЕШЕНИЙ</h4><p></p>

<p style="font-weight:500;">4.1. Стоимость разрешений определяется в зависимости от расходов на их изготовление, обмен, выдачу и учет. Стоимость разрешений устанавливается МТС.</p>

<p></p><h4>V. СРОК ДЕЙСТВИЯ РАЗРЕШЕНИЙ</h4><p></p>

<p style="font-weight:500;">5.1. Разрешения Республики Молдова и других стран действительны в течение указанного в них срока и обладают юридической силой только при наличии подписи руководителя и печати ГП "AMTAI".</p>

<p></p><h4>VI. УЧЕТ ИСПОЛЬЗОВАНИЯ РАЗРЕШЕНИЙ</h4><p></p>

<p style="text-indent:2em;"></p><h6>6.1. Учет использования разрешений осуществляется ГП "AMTAI" следующим образом:</h6><p></p>

<p style="text-indent:2em;">- все бланки разрешений Республики Молдова и других стран состоят на учете в бухгалтерии ГП "AMTAI" с регистрацией в журнале установленной формы;</p><br>

<p style="text-indent:2em;">- страницы журнала нумеруются, сшиваются и визируются подписью руководителя и печатью ГП "AMTAI". Журнал заполняется по странам и видам разрешений;</p><br>

<p style="text-indent:2em;">- в случае отсутствия сопроводительного документа к разрешениям, полученным из других стран, составляется акт получения, впоследствии утверждаемый руководителем ГП "AMTAI".</p><br>

<p style="font-weight:500;">6.2. В целях осуществления контроля за движением разрешений и ведением учета до 15 числа следующего месяца отчетного периода руководству МТС предоставляется "Информация о движении разрешений".</p>

<p></p><h4>VII. ВЫДАЧА И ВОЗВРАТ РАЗРЕШЕНИЙ</h4><p></p>

<p style="font-weight:500;">7.1. В соответствии с настоящим положением разрешения выдаются перевозчикам Республики Молдова, которые располагают документами, подтверждающими законность осуществления международных перевозок
 грузов или пассажиров.</p><br>

<p style="font-weight:500;">7.2. Разрешения выдаются ГП "AMTAI" в центральном аппарате в мун. Кишинэу и на пограничных отделениях на основании журнала учета разрешений и приложения к лицензии (копия).</p><br>

<p style="font-weight:500;">7.3. Дефицитные разрешения на международные автоперевозки распределяются автотранспортным средствам грузоподъемностью не менее 12 тонн. Дефицитные разрешения будут выдаваться только автотранспортным
 средствам, которые соответствуют техническим требованиям Евро-2 и Евро-3, с исключением российских и румынских разрешений с ограничениями.</p><br>

<p style="font-weight:500;">7.4. Разрешение не исключает специального разрешения, выданного на перевозку грузов, превышающих нормы массы и/или габаритов, или специальных грузов (опасные грузы).</p><br>

<p style="font-weight:500;">7.5. Разрешения на въезд и транзит по территориям других стран выдаются перевозчикам Республики Молдова в соответствии с поданным заявлением.</p><br>

<p style="font-weight:500;">7.6. При выдаче разрешения указывается наименование перевозчика без указания регистрационного номера автотранспортного средства.</p>

<p style="text-indent:2em;"></p><h6>7.7. Полученная квота разрешений на международные автоперевозки распределяется перевозчикам следующим образом:</h6><p></p>

<p style="text-indent:2em;">a) 60% полученной квоты разрешений распределяются перевозчикам в конце каждого года посредством соответствующей комиссии, состав которой утверждается приказом МТС, в зависимости от количества транспортных
 единиц, находящихся на учете, поданного заявления, соответствующих контрактов и информации по использованию и эффективности использования разрешений в текущем году. Соответствующая комиссия устанавливает
 квоту распределенных разрешений на следующий год для каждого перевозчика. Эти квоты могут быть изменены комиссией, в зависимости от их использования по итогам квартала;</p><br>

<p>b) 30% разрешений распределяются перевозчикам в течение года ГП "AMTAI";</p><br>

<p>c) 10% разрешений распределяются перевозчикам МТС для выполнения срочных заказов.</p><br>

<p style="font-weight:500;">7.8. Разрешения для осуществления грузовых перевозок выдаются на автотранспортные средства общей массой более 6 тонн и/или грузоподъемностью 3,5 тонны.</p>

<p style="text-indent:2em;"></p><h6>7.9. Дефицитные разрешения будут распределяться следующим образом:</h6><p></p>

<p>- первоначально экономическим агентам выдаются 30% разрешений из квоты, распределенной комиссией;</p><br>

<p style="text-indent:2em;">- остальная часть разрешений из распределенной квоты выдается только в случае возврата экономическим агентом использованных разрешений, учитывая эффективность их использования.</p><br>

<p style="font-weight:500;">7.10. Обязательным условием является ежеквартальное представление экономическими агентами информации по использованию разрешений, распределенных комиссией.</p>

<p></p><h4>VIII. ОБЯЗАТЕЛЬНЫЕ УСЛОВИЯ</h4><p></p>

<p style="font-weight:500;">8.1. В случае выявления передачи разрешения другому перевозчику первому не будут выдаваться разрешения в будущем.</p><br>

<p style="font-weight:500;">8.2. На каждую транспортную единицу выдается, как правило, не более двух разрешений каждого вида для каждой страны, исключения допускаются только в особых случаях,
 которые перевозчик указывает в обращении к руководству ГП "AMTAI".</p><br>
 
 <p><a href="https://www.legis.md/cautare/getResults?doc_id=14322&amp;lang=ru">Источник</a></p>


</div>
`