export const md16 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>ПОЛОЖЕНИЕ Правила перевозки скоропортящихся грузов</h4><p></p>

<p></p><h4>I. ОБЩИЕ ПОЛОЖЕНИЯ</h4><p></p>

    <p style="font-weight:500;">1. Настоящие  правила разработаны Министерством транспорта и связи в соответствии  с  Кодексом автомобильного транспорта (органический  Закон N116-XIV  от  29.07.1998  г., ОМ N90-91, 1998 г.,  ст.581)
	и  правилами автомобильных перевозок грузов, утвержденными в установленном порядке.</p><br>

    <p style="font-weight:500;">2. Настоящие  правила  освещают специфику перевозок  скоропортящихся грузов  и  обязательны для всех сторон, участвующих в  организации  этих перевозок.</p><br>

    <p style="font-weight:500;">3. Настоящие  правила  устанавливают порядок организации  городских, пригородных    и   междугородних   перевозок   скоропортящихся    грузов автомобильным транспортом на территории Республики Молдова.</p><br>

    <p style="font-weight:500;">4. Под скоропортящимися  понимаются грузы, максимально расположенные к  порче  в условиях окружающей среды и которые требуют  особых  условий перевозки,  защиты  от  действия  на них  высоких  и  низких
	температур наружного  воздуха  и  срока ре Цализации в  соответствии  с  нормативными документами на данный вид продукции.</p>

    <p style="text-indent:2em;"></p><h6>5. К скоропортящимся грузам относятся:</h6><p></p>

    <p style="text-indent:2em;">- продукты растительного происхождения: фрукты, ягоды, овощи, грибы, живые  ртения:  саженцы деревьев и плодовых  кустарников,  виноградные черенки, столоны клубники, цветы живые и срезанные и т.п.;</p><br>

    <p style="text-indent:2em;">- продукты животного  происхождения:  мясо и мясопродукты  различных животных и птиц, рыба, икра, молоко, яйца и др.;</p><br>

    <p style="text-indent:2em;">- продукты переработки:    молочные   продукты,   жиры,    различные замороженные плоды, колбасные изделия, сыр, масло и т.п.</p><br>

    <p style="font-weight:500;">6. Перевозка скоропортящихся грузов между странами осуществляется на основании   предварительного  согласования  с  государственным   органом Республики  Молдова, с Государственной ветеринарной службой
	республики, включая  соответствующие  правила их погрузки,  оформление  ветеринарных документов  (для  продукции  животного  происхождения)  и  гигиенических сертификатов.</p><br>

    <p style="text-indent:2em;">Продовольственные  товары, ввозимые в Республику Молдова, могут быть перевезены  по ее территории после оплаты таможенных сборов с  условием, что их срок годности не превышает 2/3 срока, установленного стандартами,
	а   на   этикетках   и   таре  имеют   соответствующую   информацию   на государственном языке.</p><br>

    <p style="font-weight:500;">7. На каждое    транспортное    средство,   деятельность    которого осуществляется   на   основе  лицензии,   государственным   предприятием \"Автотранспортная  инспекция\"  выдается \"Карнет де  кореспундере
	пентру аутомобил\".</p><br>

    <p style="text-indent:2em;">Подвижной состав,  выделяемый  для перевозки скоропортящихся  грузов (продукции),   должен  быть  технически  исправным,  оборудованным   для перевозки  данного вида продукции и отвечать требованиям санитарных
	норм и национальных стандартов.</p><br>

    <p style="text-indent:2em;">Подвижной состав  должен быть чистым, сухим, без посторонних запахов и  иметь  санитарный  паспорт  и надпись с  указанием  вида  груза,  для которого предназначен.</p><br>

    <p style="text-indent:2em;">Перевозка скоропортящихся  продуктов  в изотермическом транспорте  в отсутствие рефрижерации не допускается.</p><br>

    <p style="font-weight:500;">8. При перевозке   скоропортящихся   грузов   транспортные   агенты, грузоотправители   и   грузополучатели  обязаны  соблюдать   действующие санитарно-гигиенические  требования и должны быть обеспечены спецобувью,
	спецодеждой, перчатками и т.д.</p><br>

    <p style="font-weight:500;">9. Скоропортящиеся   грузы   должны  предъявляться  к  перевозке   в транспортабельном  состоянии  и соответствовать по качеству, упаковке  и маркировке   требованиям,  установленным  национальными  стандартами
	и другими действующими нормативными документами в Республике Молдова.</p><br>

    <p style="text-indent:2em;">Обязательно наличие  сертификата  соответствия и качества,  а  также гигиенического   и  фитосанитарного  сертификатов,  товарно-транспортной накладной   и  ветеринарного  свидетельства  (для  продуктов   животного
	происхождения) с указанием на них даты и часа окончания технологического процесса, даты и часа истечения срока реализации, температуры хранения.</p><br>

    <p style="text-indent:2em;">Тара должна быть  в хорошем состоянии, прочной, чистой и  допущенной Министерством здравоохранения для контакта с продовольственными грузами.</p><br>

    <p style="text-indent:2em;">По требованию    транспортного   агента   или   водителя-экспедитора грузоотправитель (грузополучатель) обязан для проверки состояния груза и тары предъявлять стандарты или соответствующие технические условия.</p><br>

    <p style="font-weight:500;">10. После выгрузки  скоропортящихся  грузов  грузополучатель  обязан промыть подвижной состав и при необходимости произвести его дезинфекцию.</p>

<p></p><h4>II. МЯСО, СУБПРОДУКТЫ И МЯСНЫЕ ПОЛУФАБРИКАТЫ</h4><p></p>

    <p style="font-weight:500;">11. Настоящими   правилами  предусматриваются  условия  городских  и пригородных перевозок мяса, субпродуктов и мясных полуфабрикатов.</p><br>

    <p style="font-weight:500;">12. Грузоотправитель    обязан   предъявлять   к   перевозке   мясо, субпродукты  и  мясные  полуфабрикаты с температурой  в  соответствии  с приложением 1.</p><br>

    <p>Грузоотправитель    обязан   отмечать   в   транспортной   накладной температуру груза, предъявляемого к перевозке.</p><br>

    <p style="font-weight:500;">13. Перевозка мяса, субпродуктов и мясных полуфабрикатов выполняется в      авторефрижераторах,      изотермических      автомобилях      или автомобилях-фургонах, оснащенных рефрижераторными установками.</p><br>

    <p style="font-weight:500;">14. Погрузка  мяса,  субпродуктов и мясных  полуфабрикатов,  включая подвешивание охлажденного и остывшего мяса на крючья в кузове подвижного состава,  должна  производиться силами и средствами грузоотправителя,
	а разгрузка - грузополучателем.</p><br>

    <p style="text-indent:2em;">Транспортные агенты по договору на перевозку могут принимать участие в погрузке и разгрузке с возложением этих функций на водителей.</p><br>

    <p style="text-indent:2em;">Представители  поставщика  и грузополучателя, в том числе  водители, которые  выполняют  функции погрузки-выгрузки, должны иметь  медицинскую книжку, санитарную одежду, пройти гигиенический инструктаж.</p><br>

    <p style="font-weight:500;">15. Мороженые  грузы (мясо, субпродукты, шпик), мясопродукты в  таре должны   укладываться  грузоотправителем  в  кузове  автомобиля  плотным штабелем.</p><br>

<p>Охлажденное остывшее мясо в тушах должно подвешиваться на крючья.</p><br>

    <p style="font-weight:500;">16. Прием к перевозке  от  грузоотправителя и сдача  грузополучателю мясных   грузов  в  таре,  имеющей  маркировочный  вес,   осуществляется транспортным  агентом  по  количеству грузовых мест  без  перевешивания;
	прием  и  сдача  мяса  в блоках, тушах и полутушах,  субпродуктов  -  по количеству   мест   и   весу;   прием   и   сдача   колбасных   изделий, мясокопченостей,   мясных   полуфабрикатов   -   в   таре   за
	пломбой грузоотправителя   при  наличии  сертификата  соответствия  и  качества, ветеринарного  свидетельства,  а также гигиенического и  фитосанитарного сертификатов.</p><br>

    <p style="text-indent:2em;">На колбасные изделия и мясокопчености допускается отметка в товарных накладных  номера  и даты выдачи ветеринарного свидетельства,  выданного предприятию на партию продукции, выработанной за конкретную смену.</p><br>

    <p style="font-weight:500;">17. Грузополучатель   должен   предъявлять,  а  транспортный   агент доставлять   грузоотправителю   порожнюю  инвентарную  тару   в   сроки, установленные договором между поставщиком и покупателем.</p>

<p></p><h4>III. МОЛОКО И МОЛОЧНЫЕ ПРОДУКТЫ</h4><p></p>

    <p style="font-weight:500;">18. Цельномолочная   продукция  перевозится  бестарным  способом   в специализированных   автомобилях-цистернах   или   тарным   способом   в авторефрижераторах, изотермических автомобилях или автомобилях-фургонах,
	оснащенных рефрижераторными установками.</p>

<p></p><h6>Молочные продукты перевозятся тарным способом.</h6><p></p>

    <p style="text-indent:2em;">Транспортные  агенты  должны выделять для тарной перевозки молока  и молочных  продуктов  специализированный подвижной состав с  регулируемым температурным режимом в зависимости от продолжительности перевозки,
	вида тары и температуры наружного воздуха.</p><br>

    <p style="font-weight:500;">19. При перевозке молока и молочных продуктов в автомобиле-фургоне в адрес одного грузополучателя грузоотправитель обязан опломбировать груз.</p>

    <p style="text-indent:2em;"></p><h6>20. Грузоотправитель  обязан  предъявлять груз к перевозке  в  таре, разрешенной     Министерством    здравоохранения    для    контакта    с продовольственными грузами:</h6><p></p>

   <p> - молоко разливное    -    в   запломбированных    грузоотправителем металлических луженых флягах и цистернах;</p><br>

    <p style="text-indent:2em;">- молоко, сливки,   кефир,   простокваша  и  другая   цельномолочная продукция,  расфасованная  в бутылках, бумажных  пакетах  \"Тетра-Паках\", \"Тетра-Бриках\"  и  в  полиэтиленовой упаковке, уложенных  в  специальных
	металлических   и   пластмассовых   ящиках  и   корзинах   и   групповой полиэтиленовой пленке по 10 л;</p><br>

    <p style="text-indent:2em;">- сметана, творог  и  творожная  масса  весовые  в  запломбированных грузоотправителем  металлических луженых флягах, а расфасованные, в  том числе сырки, в пластмассовой таре;</p><br>

    <p>- брынза в пластмассовой и деревянной таре.</p><br>

    <p style="text-indent:2em;">Тара, предназначенная  для  перевозки молокопродуктов, в  том  числе сыров,  масла  животного и мороженого, должна соответствовать  ГОСТам  и техническим условиям и иметь разрешение Министерства здравоохранения.</p><br>

    <p style="font-weight:500;">21. При предъявлении  к  перевозке  на  одном  автомобиле  молока  и молочных    продуктов   разного   ассортимента   в   адрес    нескольких грузополучателей    грузоотправитель   должен   заранее,   до
	прибытия автомобиля,  подгруппировать  груз  по грузополучателям  и  осуществлять погрузку на одном посту.</p><br>

    <p style="font-weight:500;">22. Возврат и доставка порожней тары грузоотправителю осуществляется согласно действующим санитарным требованиям.</p><br>

    <p style="font-weight:500;">23. Грузоотправитель   обязан   предъявлять  молоко  для   перевозки бестарным  способом с низовых молочных заводов при температуре молока не выше +6 градусов С максимально.</p><br>

    <p style="text-indent:2em;">Температура молока,  предъявляемого к перевозке с городских молочных заводов  в  предприятия  торговли  и общественного  питания,  не  должна превышать +6 градусов С максимально.</p><br>

    <p style="text-indent:2em;">Грузоотправителем  в  транспортной накладной указывается дата и  час окончания   технологического  процесса,  дата  и  час  истечения   срока реализации, а также температура хранения.</p><br>

    <p style="font-weight:500;">24. Прием к перевозке  от  грузоотправителя и сдача  грузополучателю молока и молочных продуктов в таре осуществляются транспортными агентами при  наличии удостоверения о качестве по количеству фляг, бочек, а
	также заполненных ящиков и корзин согласно нормативной документации (НД).</p><br>

    <p>Прием и сдача   молока   при   перевозке  его   бестарным   способом осуществляются за пломбой грузоотправителя.</p><br>

    <p style="text-indent:2em;">Молоко и молочные продукты должны быть сопровождены гигиеническими и фитосанитарными  сертификатами, ветеринарными справками в соответствии с правилами,    установленными   Государственной   ветеринарной
    службой республики.</p>

    <p style="text-indent:2em;"></p><h6>25. Грузоотправитель (городской молочный завод) обязан производить:</h6><p></p>     

<p>- проверку наличия  и  исправности пломб грузоотправителя  на  люках цистерны и сливных трубопроводах;</p><br>

    <p>- соединение и разъединение загрузочных рукавов с цистерной;</p><br>

    <p>- снятие пломб;</p><br>

    <p>- открытие люков   цистерн  перед  погрузкой  и  закрытие  их  после погрузки;</p><br>

    <p>- налив цистерны;</p><br>

    <p>- опломбирование люков и сливных трубопроводов цистерны;</p><br>

    <p>- промывку и  дезинфекцию  с внутренней и наружной стороны  цистерны после слива молока;</p><br>

    <p>- отметку в путевом  листе  или  паспорте  о  санитарной  обработке,
времени проведения санитарной обработки;</p><br>

    <p>- обогрев горячей водой и паром сливных люков и труб в зимнее время.</p>

<p></p><h4>IV. РЫБА И РЫБОПРОДУКТЫ</h4><p></p>

    <p style="font-weight:500;">26. Рыба и рыбопродукты    перевозятся   специальным    транспортом, обеспечивающим  сохранение  качества, с соблюдением температуры  в  теле рыбы  не  выше -9 градусов С с указанием в транспортной  накладной  даты
	замораживания,   температуры   продукта  и  даты  упаковки   с   выдачей удостоверения о качестве.</p><br>

    <p style="font-weight:500;">27. Перевозка  мороженой  рыбы допускается только в  упаковке.  Рыба должна  быть  упакована  в  деревянные,  пластмассовые  ящики,  бочки  и пластмассовую тару и т.п., разрешенные Министерством здравоохранения для
	упаковки   этого   продукта.  Ящики  по  торцам  должны  быть   обтянуты упаковочной полосой из железа или проволокой, а корзины, коробки, тюки и  кули обвязаны прочной 
веревкой. Тару необходимо выстилать внутри чистыми  рогожами,  бумагой  или другими изолирующими материалами.  Рыба  высшего качества  и рыбные филе упаковываются только в ящики, выстланные  внутри изолирующими материалами.</p><br>

    <p style="font-weight:500;">28. Охлажденная  рыба перевозится в деревянных ящиках или сухотарных бочках.  На  дно  тары  и на каждый ряд рыбы должен  быть  насыпан  слой мелкодробленного  чистого льда. В бочках и ящиках должны быть  отверстия
	для стока воды от таяния льда.</p><br>

    <p style="text-indent:2em;">Охлажденная рыба   должна   иметь   температуру  в  толще   мяса   у позвоночника  в  пределах  от  -2 до +5 градусов С.  В  удостоверении  о качестве  должно  быть  указано: дата и час  окончания  технологического
	процесса, дата и час конечного срока реализации, t хранения.</p><br>    

<p style="font-weight:500;">29. Рыба соленая  и  сельдь  должны  быть  упакованы  в  деревянные, пластмассовые и сухотарные бочки.</p><br>

    <p style="font-weight:500;">30. Рыбу холодного  копчения, вяленую и другие рыботовары  перевозят только в таре, которая пригодна для обработки.</p><br>

    <p style="font-weight:500;">31. Живую рыбу  перевозят  в  специально оборудованных  цистернах  и контейнерах  с  аэрацией.  Допускается перевозка в  водопроводной  воде, содержащей  хлор,  при  условии  предварительной  тщательной  аэрации  в
	течение  30-50 мин. Соотношение рыбы и воды в цистернах и контейнерах  с системой  принудительной  аэрации  (при перевозке толстолобика,  леща  и другой пресноводной рыбы) 1:2, без аэрации 1:3. Морская рыба перевозится
	только с принудительной аэрацией в соотношении рыбы и воды 1:10.</p><br>

    <p style="font-weight:500;">32. Погрузка  в автомобили рыбы и рыбопродуктов в таре  производится плотными  рядами. Рыба, переложенная льдом, перевозится в жесткой  таре.</p><br>

<p style="text-indent:2em;">Погрузка  соленых рыбных продуктов в таре производится в зависимости  от размеров отдельных мест, но не более чем в два яруса по высоте.</p><br> 

    <p style="font-weight:500;">33. Продолжительность  перевозки  живой рыбы не должна  превышать  8 часов  при t окружающего воздуха не выше 10 градусов С без замены  воды. При  более высокой температуре воду охлаждают льдом, а продолжительность
	транспортировки сокращается до 6 часов. После 6 часов вода меняется.</p><br>

    <p style="font-weight:500;">34. Сопроводительным    ветеринарным    документом   для   рыбы    и рыбопродуктов   должно  быть  ветеринарное  свидетельство  установленной формы,  выдаваемое территориальной государственной ветеринарной  службой по
	месту отправления, а также сертификат соответствия, гигиенический  и фитосанитарный сертификаты.</p>

<p></p><h4>V. КАРТОФЕЛЬ, ОВОЩИ И БАХЧЕВЫЕ КУЛЬТУРЫ</h4><p></p>

    <p style="text-indent:2em;"></p><h6>35. Настоящей  главой  правил предусматриваются условия городских  и пригородных перевозок картофеля, овощей и бахчевых культур:</h6><p></p>

    <p style="text-indent:2em;">- из агрохозяйств,  ассоциаций  крестьянских (фермерских)  хозяйств, заготовительных  организаций  потребительской кооперации  в  предприятия торговли,   общественного  питания  и  на  перерабатывающие
	предприятия овощехранилища;</p><br> 

    <p>- из овощехранилищ в предприятия торговли и общественного питания.</p><br> 

    <p style="font-weight:500;">36. При перевозке  картофеля, овощей и бахчевых культур транспортные агенты,  грузоотправители  и грузополучатели обязаны принимать  меры  по предохранению  их  от  механических  повреждений,  атмосферных
	осадков, воздействия низких и высоких температур.</p><br>

    <p style="font-weight:500;">37. Грузоотправитель   должен   предъявлять  груз  к   перевозке   в исправной,  чистой, сухой, без постороннего запаха таре, соответствующей требованиям  национальных  стандартов, в соответствии с  приложением,
	а также транспортную накладную, удостоверение о качестве или гигиенический сертификат  с  указанием  остаточного содержания  нитратов,  пестицидов, токсических элементов, микотоксинов.</p><br>

    <p style="text-indent:2em;">Картофель, овощи,    отправляемые   агрохозяйствами,    ассоциациями крестьянских  (фермерских)  хозяйств  и  заготовительными  организациями потребительской  кооперации, можно принимать к перевозке в нестандартной таре,
	обеспечивающей их сохранность при перевозке.</p><br> 

    <p style="font-weight:500;">38. Картофель,    овощи    и   бахчевые   культур   перевозятся    в авторефрижераторах, изотермических автомобилях, автомобилях-фургонах или автомобилях с бортовой платформой.</p><br>

    <p style="text-indent:2em;">Выбор подвижного  состава для перевозки картофеля, овощей и бахчевых культур   производится  транспортными  агентами  с  учетом   температуры наружного воздуха и продолжительности перевозки.</p><br> 

    <p style="font-weight:500;">39. Картофель,  овощи и бахчевые культуры, перевозимые в автомобилях с  бортовой платформой при температуре воздуха ниже 0 градусов С, должны быть  утеплены грузоотправителем. По договору на перевозку  транспортные агенты
	могут взять на себя оплату средств утепления груза.</p><br>

    <p style="font-weight:500;">40. Подвижной   состав,   выделяемый  транспортными   агентами   для перевозок картофеля, овощей и бахчевых культур, должен иметь необходимый такелаж: брезент и веревки.</p><br>

    <p style="text-indent:2em;">При постоянных   перевозках   капусты  и  бахчевых   культур   борта автомобилей  и  прицепов  должны быть наращены до высоты 1,4  м,  а  при перевозке других овощей и картофеля - до высоты 0,9 м от пола кузова.</p><br> 

    <p style="font-weight:500;">41. Грузоотправитель  должен укладывать ящики, мешки, кули с овощами и картофелем в кузовах автомобилей плотными рядами без промежутков между грузовыми местами.</p><br>

    <p style="font-weight:500;">42. Грузоотправитель должен предъявить к перевозке бахчевые культуры свежими, чистыми, без механических повреждений и повреждений вредителями и   болезнями,   однородными   по  степени  зрелости,   упакованными
	в соответствии  со  стандартами  в  исправную, прочную,  сухую  с  гладкой внутренней  поверхностью, без постороннего запаха тару в соответствии  с приложением 2.</p><br>

    <p style="font-weight:500;">43. В период  массовых заготовок и реализации допускается  перевозка арбузов, дынь, тыкв и кабачков навалом.</p><br>

    <p style="text-indent:2em;">При перевозке  арбузов и стойких сортов дынь без тары на пол  кузова укладывается подстилка из слоя соломы высотой не менее 10 см или рогожи, брезент, мешковина и т.п.</p><br> 

    <p style="text-indent:2em;">Высота погрузки  арбузов  должна  быть не более 1,3 -  1,4  м.  Дыни укладываются  рядами, но не более трех. Каждый ряд прокладывается  слоем соломы или другими упаковочными материалами.</p><br>

    <p style="font-weight:500;">44. Прием к перевозке  от  грузоотправителя и сдача  грузополучателю картофеля,   овощей  и  бахчевых  культур  осуществляются  транспортными агентами   по   весу   при   наличии   товарно-транспортной   накладной,
	удостоверения   о   качестве   или  фитосанитарного   и   гигиенического сертификатов  с  указанием остаточного содержания нитратов,  пестицидов, токсических  элементов, микотоксинов, радионуклидов, а также сертификата
	соответствия.</p><br>

    <p style="font-weight:500;">45. При перевозке  картофеля и овощей в одном автомобиле  нескольким грузополучателям  грузоотправитель  обязан  размещать их в  кузове  так, чтобы  обеспечивалась  возможность  выгрузки всего  ассортимента  груза,
	требуемого для каждого грузополучателя.</p><br>

    <p style="font-weight:500;">46. При перевозке картофеля, овощей и бахчевых культур в контейнерах или  автомобилях-фургонах  в  адрес одного грузополучателя  груз  должен отправляться за пломбой грузоотправителя.</p><br>

    <p style="font-weight:500;">47. Картофель,  овощи  и  бахчевые культуры, перевозимые  на  откорм скоту,  должны сопровождаться ветеринарным свидетельством, выдаваемым  в установленном порядке.</p>

<p></p><h4>VI. ФРУКТЫ, ЯГОДЫ, ВИНОГРАД</h4><p></p>

    <p style="text-indent:2em;"></p><h6>48. Правилами  предусматриваются условия местных перевозок  фруктов, ягод, винограда и бахчевых культур:</h6><p></p>

    <p style="text-indent:2em;">- из агрохозяйств,  ассоциаций  крестьянских (фермерских)  хозяйств, заготовительных  организаций  потребительской кооперации  в  предприятия торговли, общественного питания и овощехранилища,</p><br>

    <p style="text-indent:2em;">- со станций   железных   дорог,   морских  портов   и   аэропортов, аграрно-промышленных  и аграрно-сбытовых объединений, их заготовительных и сбытовых баз в предприятия торговли и общественного питания,     - из
	овощехранилищ в предприятия торговли и общественного питания.</p><br>

    <p style="font-weight:500;">49. При перевозке  фруктов,  ягод и винограда  транспортные  агенты, грузоотправители    и   грузополучатели   обязаны   обеспечить   наличие товаротранспортной  накладной, удостоверения о качестве, фитосанитарного и
	гигиенического  сертификатов  с  указанием  остаточного   содержания пестицидов,    нитратов,   микотоксинов,   радионуклидов,    токсических элементов,   а   также  сертификата  соответствия,  принимать  меры   по
	предохранению  их  от  механических  повреждений,  атмосферных  осадков, воздействия низких и высоких температур.</p><br>

    <p style="font-weight:500;">50. Фрукты, ягоды    и   виноград   перевозятся   в   изотермических автомобилях,  автомобилях-фургонах, автомобилях (автопоездах) с бортовой платформой, обеспечивающих их качественное состояние и сохранность.</p><br>

    <p style="text-indent:2em;">Подвижной состав  для  перевозки фруктов, ягод и винограда  выбирают транспортные    агенты   с   учетом   температуры   наружного   воздуха, продолжительности перевозки и транспортабельности перевозимой продукции.</p><br>

    <p>Подвижной состав  должен  быть  чистым, без посторонних  запахов,  а также обеспечен брезентом и веревками.</p><br>

    <p style="font-weight:500;">51. Для перевозки   фруктов,   ягод  и  винограда  при   температуре наружного  воздуха  ниже  0 градусов С транспортные агенты  должны,  как правило, выделять автомобили с изотермическими кузовами. При перевозке в автомобилях
    с    бортовой   платформой    или    автомобилях-фургонах грузоотправитель обязан утеплять груз.</p><br>

    <p style="text-indent:2em;">Транспортные  агенты по договору на перевозку могут принять на  себя работы   по  утеплению  груза  с  оплатой  средств  утепления  за   счет грузоотправителя.</p><br>

    <p style="font-weight:500;">52. Грузоотправитель  должен предъявлять к перевозке фрукты, ягоды и виноград  свежими,  чистыми, без механических повреждений и  повреждений вредителями и болезнями, однородными по степени зрелости, упакованными в соответствии
	cо  cтандартами  в исправную, прочную,  сухую,  с  гладкой внутренней  поверхностью, без постороннего запаха тару в соответствии  с приложением 2.</p><br>

    <p style="font-weight:500;">53. В период  массовых заготовок и реализации допускается  перевозка яблок навалом.</p><br>

    <p>Борта автомобилей и прицепов должны быть наращены до высоты 1,4 м от пола кузова.</p><br>

    <p style="font-weight:500;">54. На перевозку  фруктов, ягод и винограда грузоотправитель  обязан передать  транспортному  агенту  вместе с  товаротранспортной  накладной удостоверение   о  качестве  и  гигиенический  сертификат  с   указанием результатов
	проверки  содержания  пестицидов,  нитратов,   токсических элементов,  микотоксинов, радионуклидов, сроков хранения и качественного состояния  груза  и упаковки со ссылкой на соответствующий  национальный стандарт  и  другие
	нормативные  документы,  действующие  в  Республике Молдова.  Удостоверение о качестве оформляется в день предъявления груза к   перевозке  и  заверяется  печатью  грузоотправителя,  который  несет ответственность за качество
	груза и упаковки.</p><br>

    <p style="font-weight:500;">55. Грузоотправитель  должен  укладывать грузовые места с  фруктами, ягодами   и  виноградом  в  кузовах  автомобилей  плотными  рядами,  без промежутков между ними.</p><br>

    <p style="font-weight:500;">56. При перевозке  фруктов  в открытых ящиках, не забитых  крышками, между  рядами  ящиков  должен прокладываться деревянный щит  по  размеру кузова или несколько щитов соответственно меньшего размера.</p><br>

    <p style="text-indent:2em;">Ящики укладываются  в 3-4 ряда так, чтобы верхний ряд выступал  выше бортов  кузова  автомобиля  не  более  чем  на  половину  своей  высоты,
увязываются веревкой и укрываются брезентом.</p><br>

    <p style="font-weight:500;">57. При перевозке   винограда   в  закрытых  ящиках   ящики   должны укладываться не более чем в 9 рядов.</p><br>

    <p>Верхний ряд забивается дощечками (по три дощечки на каждый ящик).</p><br>

    <p>Кузов автомобиля укрывается брезентом.</p><br>

    <p style="font-weight:500;">58. Ягоды в легкой  таре  при перевозке укладываются  в  контейнеры, клетки  или скрепляются другим образом, обеспечивая при этом сохранность груза.</p><br>

    <p style="font-weight:500;">59. Транспортные  агенты принимают к перевозке от грузоотправителя и сдают  грузополучателю  фрукты,  ягоды и виноград по  весу  при  наличии фитосанитарного   сертификата,  в  том  числе  на  наличие   пестицидов,
нитратов, микотоксинов, токсических веществ и радионуклидов.</p><br>

    <p style="text-indent:2em;">Указанные продукты,    предназначенные   для   кормовых   целей    в животноводстве,   должны  сопровождаться  ветеринарным   свидетельством, выдаваемым в установленном порядке.</p><br>

    <p style="text-indent:2em;">При перевозке  в контейнерах или автомобилях-фургонах в адрес одного грузополучателя груз должен отправляться за пломбой грузоотправителя.</p><br>

    <p style="font-weight:500;">60. При перевозке  фруктов,  ягод  и винограда  в  одном  автомобиле нескольким  грузополучателям  грузоотправитель  обязан  размещать  их  в кузове  автомобиля так, чтобы обеспечивалась выгрузка всего ассортимента груза,
	адресованного  каждому  грузополучателю,  а  также   выписывать транспортную накладную каждому грузополучателю.</p><br>

    <p style="font-weight:500;">61. Перевозка посадочного садоводческого, виноградарского материала, плодовых  кустарников осуществляется транспортными средствами c укладкой соломы,  на которую кладутся корни и укладываются в наклонном  положении надземной
	стороной  в направлении противоположном движению  автомобиля. Рекомендуется  покрывать брезентом, особенно при длительных  перевозках, когда   существует  угроза  замерзания,  солома  или  другие  материалы, которыми прокладываются
	корни, увлажняются.</p><br>

    <p style="text-indent:2em;">Посадочный садоводческий  и виноградарский материал упаковывается  в тюки,  максимальный  вес которых не должен превышать 80 кг.  Такие  тюки содержат  80-100  годовалых  деревьев  (прутов)  или  200-250  саженцев, отводков  или
	черенков,  к  тюкам прикрепляются  этикетки,  на  которых записываются  порядковый номер тюка, имя и адрес грузополучателя, имя  и адрес грузоотправителя.</p><br>

    <p style="text-indent:2em;">Как правило,  на протяжении всей перевозки посадочный материал нужно оберегать  от  засухи, которая приводит к обезвоживанию,  от  температур ниже 0 градусов С, которые сразу уничтожают активные корни.</p>

    <p style="text-indent:2em;"></p><h6>Садоводческий  посадочный материал перевозится при наличии следующих документов:</h6><p></p>

    <p>- фактура и протокол о количественном и качественном приеме;</p><br>

    <p>- сертификат биологической ценности (копия);</p><br>

    <p>- фитосанитарный сертификат о свободном передвижении, номер которого указывается на фактуре.</p>

<p></p><h4>VII. ТОРТЫ, ПИРОЖНЫЕ И ДРУГИЕ МУЧНИСТО-КРЕМОВЫЕ ИЗДЕЛИЯ</h4><p></p>

    <p style="font-weight:500;">62. Перевозка  тортов,  пирожных и других мучнисто-кремовых  изделий осуществляется  при наличии удостоверения о качестве с указанием даты  и часа  окончания  технологического процесса, даты и часа конечного  срока реализации,
	t хранения.</p><br>

    <p style="text-indent:2em;">Не допускается   совместная  перевозка  тортов,  пирожных  и  других мучнисто-кремовых изделий с непищевыми продуктами, а также с продуктами, имеющими резкий специфический запах, и со свежевыпеченным хлебом.</p><br>

    <p style="font-weight:500;">63. Грузоотправитель   обязан   предъявлять  торты  к  перевозке   в картонных  коробках  и пластмассовых упаковках,  разрешенных  Минздравом Республики  Молдова, обвязанных клеенной лентой. Коробки укладываются  в кузов в несколько
	рядов по высоте кузова. Мелкоштучные изделия (пирожные и  другие)  должны  укладываться в один ряд по высоте  на  металлические лотки, которые закрываются крышкой.
    Все лотки должны  быть  пронумерованы  в  соответствии  с  Правилами перевозок грузов автомобильным транспортом.</p><br>

    <p style="font-weight:500;">64. Грузоотправитель  обязан предъявлять к перевозке кондитерские  и мучнисто-кремовые  изделия  с  температурой  от 0 до  6  градусов  С,  а замороженных  не  выше -10 градусов С при  наличии  товарно-транспортной накладной и 
	удостоверения о качестве.</p><br>

    <p style="font-weight:500;">65. Транспортные   агенты  должны  выделять  для  перевозки  тортов, пирожных  и  других мучнисто-кремовых изделий изотермические  автомобили или   автомобили-фургоны,  оснащенные  рефрижераторными  установками 
	с санитарным паспортом.</p><br>

    <p style="font-weight:500;">66. Прием к перевозке  от  грузоотправителя и сдача  грузополучателю мучнисто-кремовых   изделий  осуществляются  транспортными  агентами  по наименованию и количеству коробок или стандартно заполненных лотков.</p><br>

    <p style="font-weight:500;">67. Погрузка    и   выгрузка   мучнисто-кремовых   изделий    должны производиться осторожно, без ударов и резких сотрясений.</p><br>

    <p style="font-weight:500;">68. Санитарную    обработку   кузова   подвижного   состава   должны производить транспортные агенты.</p><br>

    <p style="font-weight:500;">69. Лотки для  перевозки тортов, пирожных и других мучнисто-кремовых изделий являются инвентарной тарой грузоотправителя.</p><br>

    <p>Грузоотправитель должен производить санитарную обработку лотков.</p>

<p></p><h4>VIII. ПРАВИЛА ПЕРЕВОЗОК СКОРОПОРТЯЩИХСЯ ГРУЗОВ
В МЕЖДУГОРОДНОМ СООБЩЕНИИ</h4><p></p>

    <p style="font-weight:500;">70. Скоропортящиеся  грузы  перевозятся автомобильным транспортом  в остывшем,  охлажденном,  замороженном, глубокозамороженном состоянии,  а также в свежем виде.</p><br>

    <p style="font-weight:500;">71. Для обеспечения      сохранности     скоропортящихся      грузов грузоотправители,   транспортные   агенты  и   грузополучатели   обязаны организовать четкую и согласованную работу по планированию, подготовке
	и осуществлению этих перевозок.</p><br>

    <p style="text-indent:2em;">Массовые перевозки    скоропортящихся   грузов   осуществляются   по договорам,  графикам, согласованным между поставщиками, потребителями  и транспортными агентами.</p><br>

    <p style="font-weight:500;">72. Подвижной   состав,  выделяемый  для  перевозки  скоропортящихся грузов,   должен  быть  чистым  и  отвечать  установленным  требованиям.</p><br> 

<p style="text-indent:2em;">Холодильная  установка, грузонесущие устройства и запоры у дверей должны  быть     исправными,     кузова     изотермических     автомобилей     и автомобилей-рефрижераторов  должны  обеспечивать  поддержание  заданного температурного
  режима  и  быть оборудованы  установками  автоматической регистрации температуры.</p><br>

    <p style="font-weight:500;">73. Скоропортящиеся   грузы  должны  предъявляться  к  перевозке   в транспортабельном  состоянии  и соответствовать по качеству, упаковке  и маркировке   требованиям,  установленным  стандартами  или  нормативными документами,
	при  наличии  сертификата  соответствия  и  качества,   с указанием в нем срока годности, условий хранения, а для овощей и фруктов -  гигиенических  и фитосанитарных сертификатов, в том числе на  наличие пестицидов    и    микотоксинов,
	токсических   элементов,    нитратов, радионуклидов,  товаротранспортной накладной, ветеринарного  сертификата (для  продукции  животного происхождения). По  требованию  транспортного агента   или  водителя-экспедитора  грузоотправитель
	(грузополучатель) обязан  для проверки состояния груза или тары представлять стандарты или нормативную документацию.</p><br>

    <p style="text-indent:2em;">Тара для скоропортящихся    грузов   должна   отвечать    санитарно- гигиеническим  условиям их перевозки, сохранять качество и внешний  вид, предохранять грузы от деформации.</p><br>

    <p style="font-weight:500;">74. При перевозке   скоропортящихся  грузов  температура  в   кузове рефрижератора  должна  соответствовать данным, указанным в графах 3 и  4 приложения 3.</p><br>

    <p style="font-weight:500;">75. В свежем  виде перевозятся устойчивые фрукты и овощи, которые не требуют  поддержания определенного температурного режима, но нуждаются в постоянном или периодическом проветривании.</p><br>

    <p style="text-indent:2em;">Свежие фрукты  и  овощи при нахождении в пути (с  момента  окончания погрузки  и  до начала разгрузки) не более 6 часов могут перевозиться  в весенний,  летний  и осенний периоды при наружной температуре не ниже  0 градусов
	С  на неспециализированном  подвижном  составе  с   укрытием брезентом и проветриванием.</p><br>

    <p style="font-weight:500;">76. При перевозке  замороженных грузов температура воздуха в  кузове подвижного  состава, разрешенного госэпидемслужбой для перевозки пищевых грузов,  должна  поддерживаться на уровне,  соответствующем  температуре груза  в  момент 
	его  приема к перевозке, с колебаниями  в  пределах  3 градусов С.</p><br>

    <p style="text-indent:2em;">В тех случаях,  когда груз непосредственно после перевозки поступает в употребление, допускается, по письменному разрешению грузоотправителя, постепенное   повышение   температуры  груза  за  время   перевозки   до температуры, при 
	которой производится его употребление.</p><br>

    <p style="font-weight:500;">77. В зависимости  от  свойств  груза и  его  термической  обработки водитель  обязан в летнее время произвести предварительное, до  погрузки груза,  охлаждение  кузова  рефрижератора, а в зимнее  время  -  обогрев кузова до температуры,
	указанной в графах 3,4 приложения 3.</p><br>

    <p style="text-indent:2em;">Температура в  кузове  рефрижератора перед погрузкой  и  температура груза в период погрузки отмечается грузоотправителем и водителем в листе контрольных проверок. Листы контрольных проверок температуры (приложение 5)  изготавливаются
	транспортным агентом и выписываются в 2 экз.  (один экземпляр вручается грузополучателю, второй остается при путевом листе).</p><br> 

<p style="text-indent:2em;">При перевозке  скоропортящихся грузов в авторефрижераторах  водитель  обязан   периодически   проверять  температуру  в  кузове  и   постоянно поддерживать  температурный  режим  на  том уровне,  который  задан  при погрузке,   и
   результаты  замеров  температуры  записывать   в   листе контрольных проверок.</p><br>

    <p style="text-indent:2em;">Скоропортящиеся  грузы не принимаются к перевозке, если  допускаемый срок  их  доставки  (транспортабельность) нормативной  документацией  по качеству  будет  меньше  предельного срока доставки, указанного  в
	п.90 настоящих правил.</p><br>

    <p style="font-weight:500;">78. Правильное  размещение скоропортящихся грузов в кузове  является одним  из  основных условий обеспечения их сохранности при  перевозке  и полного использования грузоподъемности подвижного состава.</p><br>

    <p>Грузоотправитель  несет  ответственность за правильное размещение  и укладку груза в кузове автомобиля.</p><br>

    <p style="font-weight:500;">79. Укладка плодов  и овощей в закрытую тару должна быть плотной,  в уровень с краями тары, чтобы они не бились при транспортировке.</p><br>

    <p style="text-indent:2em;">Ягоды, виноград,  плоды  семечковые  (яблоки, груши)  и  косточковые (слива,  абрикос, персики и т.п.), помидоры, арбузы и дыни должны быть в каждой партии однородными по степени зрелости.</p><br>

    <p style="text-indent:2em;">Транспортный   агент   имеет   право  проверить  состояние   до   5% предъявляемых  к  отправке  грузовых  мест  путем  осмотра  и  измерения температуры  груза.  Вскрытие  груза,  его  последующая  упаковка  после проверки
	проводятся грузоотправителем. Грузы в термической упаковке  не вскрываются.</p><br>

    <p style="text-indent:2em;">Груз, не соответствующий   по   качественному  состоянию   упаковки, маркировки   и  термической  обработки  установленным  требованиям,   не принимается  к перевозке до устранения дефектов, выявленных в результате осмотра.</p><br>

    <p style="font-weight:500;">80. Укладка ящиков  с  плодоовощами  в кузове  должна  производиться таким  образом,  чтобы зазор между потолком (поддоном) и  верхним  рядом груза  был  не  менее 30-35 см и не было зазора между штабелем  груза  и задней
	стенкой кузова.</p><br>

    <p style="text-indent:2em;">В случаях, когда  длина  ящиков не кратна длине кузова, должны  быть созданы условия, препятствующие перемещению груза.</p><br>

    <p>Необходимые для  этого  материалы представляются  и  устанавливаются грузоотправителем.</p>

    <p style="text-indent:2em;"></p><h6>81. Мясо и мясопродукты в междугородном сообщении перевозятся только:</h6><p></p>

    <p style="text-indent:2em;">а) в охлажденном   состоянии  -  подвергнутые  после  разделки   туш охлаждению  до температуры в толще мышц у костей от 0 до +4 градусов С и имеющие  сухую  поверхность  с корочкой подсыхания без  следов  плесени,
	ослизнения и увлажнения;</p><br>

    <p style="text-indent:2em;">b) в замороженном  -  подвергнутые  замораживанию до  температуры  в толще мышц у костей не выше минус 8 градусов С.</p><br>

    <p>Перевозка парного и дефростированного мяса запрещается.</p><br>

    <p style="font-weight:500;">82. Замороженное  мясо  и другие замороженные грузы  укладываются  в кузове  плотными штабелями с небольшим использованием внутреннего объема кузова.   Таким   же   порядком  укладываются  ящики   с   замороженными
	мясопродуктами.</p><br>

    <p style="text-indent:2em;">Охлажденные грузы в таре (битая птица, колбасы, копчености), а также фрукты  и овощи укладываются штабельным способом. Остывшая и охлажденная говядина,  свинина, баранина и телятина грузятся в кузов только
	подвесом на крючья.</p><br>

    <p style="text-indent:2em;">Отдельные места  туш,  полутуш или четверти не должны  соприкасаться между собой, с полом и со стенками кузова.</p><br>

    <p style="font-weight:500;">83. Перевозка  замороженных  мясных грузов совместно с  охлажденными или остывшими, а также остывшего мяса с охлажденным не допускается.</p>

    <p style="text-indent:2em;"></p><h6>Не допускаются  к совместной перевозке в одном автомобиле с  другими продуктами следующие грузы:</h6><p></p>

    <p>1) рыба мороженая и охлажденная;</p><br>

    <p>2) сельдь, рыба соленая, икра;</p><br>

    <p>3) рыбокопчености, сухая, вяленая рыба;</p><br>

    <p>4) мясо охлажденное;</p><br>

    <p>5) мясокопчености;</p><br>

    <p>6) сыры всех видов;</p><br>

    <p>7) плоды, обладающие    сильными   ароматами   (апельсины,   лимоны, мандарины, дыни);</p><br>

    <p>8) овощи с резкими запахами (лук, чеснок);</p><br>

    <p>9) дрожжи хлебопекарные.</p><br>

    <p>За последствия  загрузки указанных грузов в один автомобиль отвечает грузоотправитель.</p><br>

    <p style="font-weight:500;">84. Мясо и мясные   продукты  принимаются  к  перевозке  только  при наличии удостоверения о качестве и ветеринарных свидетельств, выдаваемых органами государственного ветеринарного надзора.</p><br>

    <p style="text-indent:2em;">Водитель автомобиля,  перевозящий продукты животноводства, не  имеет права  выезжать  в  рейс без  ветеринарного  свидетельства,  выдаваемого грузоотправителем.</p><br>

    <p style="text-indent:2em;">Живые растения, цветы, клубни, плоды, семена и т.п., отправляемые из местностей,  объявленных  под  карантином, принимаются  к  перевозке  по предъявлении  отправителем  на  каждую партию разрешений  и  карантинных сертификатов,
	выдаваемых инспекцией по карантину растений  Министерства сельского хозяйства и продовольствия.</p><br>

    <p style="font-weight:500;">85. Перевозка  скоропортящихся  грузов в  автомобилях-рефрижераторах осуществляется  за  пломбой отправителя, который отвечает  за  качество, количество,  ассортимент  отправляемого  груза, вес и  соответствие  его санитарным
	требованиям.</p><br>

    <p style="text-indent:2em;">Грузоотправитель   несет   ответственность  за  понижение   качества перевозимых,   предварительно  не  охлажденных  грузов  до  температуры, указанной  в  приложении 3, при условии соблюдения транспортным  агентом заданного
	температурного  режима  в  кузове  авторефрижератора,  сроков доставки груза, а также исправности пломбы отправителя.</p><br>

    <p style="font-weight:500;">86. Транспортный  агент несет ответственность за сохранность груза и пломбы  в  пути  следования, а также за доставку груза  в  установленные сроки  и  качество  груза, если потери качества или  снижение  сортности произошли
	в результате нарушения температурного режима при  перевозках, неисправности   рефрижераторного   оборудования  или  задержки  в   пути следования  по  вине  транспортного  агента.  Транспортный  агент  несет одинаковую
	ответственность за доставку груза наряду с экспедитором.</p><br>

    <p style="font-weight:500;">87. Транспортные  агенты обязаны доставлять скоропортящиеся грузы по назначению,   исходя   из   среднесуточного   пробега   350   км.   Если скоропортящиеся  грузы  перевозятся  на расстояние свыше 350 км,  то  на автомобиль
    назначается    2   водителя   и   среднесуточный    пробег устанавливается 600 км.</p><br>

    <p style="text-indent:2em;">Предельные сроки  доставки  грузов исчисляются с  момента  окончания погрузки  и  оформления  документов  до момента  прибытия  автомобиля  к грузополучателю.</p><br>

    <p style="text-indent:2em;">В случае, если грузополучатель не обеспечит выгрузку в установленные сроки   или  изменит  пункт  назначения,  он  несет  ответственность  за понижение   качества  груза,  вызванное  простоем  автомобиля  в   пункт назначения
	или увеличением сроков доставки вследствие изменения  пункта назначения.</p><br>
	
    <p style="font-weight:500;">88. По прибытии  в пункт назначения водитель предъявляет  получателю транспортную   накладную,   лист  контрольных  проверок  температуры   и сопроводительные  документы  грузоотправителя, а также  удостоверение  о качестве,
	а  для  овощей  и  фруктов  и  фитосанитарные  сертификаты  с указанием   результатов   проверки  содержания   пестицидов,   нитратов, токсических  элементов,  микотоксинов, радионуклидов и сроков  хранения, сертификаты   соответствия,
	а  также  другие  документы,  установленные действующим законодательством.</p><br>

    <p style="text-indent:2em;">Грузополучатель   производит   надлежащие  записи   в   транспортных накладных  о  получении  груза, вскрывает авторефрижератор  и  вместе  с водителем  проверяет  температуру  в кузове  рефрижератора,  температуру груза  и  делает
	запись в листе контрольных проверок  температуры.  Эти записи скрепляются обоюдными подписями грузополучателя и водителя, после чего грузополучатель приступает к разгрузке авторефрижератора.</p><br>

    <p style="text-indent:2em;">Один экземпляр     листа    контрольных    проверок    остается    у грузополучателя, второй приобщается к путевому листу.</p><br>

    <p style="text-indent:2em;">После разгрузки   скоропортящихся   грузов  грузополучатель   обязан очистить  кузов  подвижного  состава и, в соответствии  с  ветеринарными требованиями,  промыть  или  произвести его  дезинфекцию.  О  проведении промывки и
	дезинфекции подвижного состава грузополучатель делает отметку в документе.</p><br>

   <p style="font-weight:500;">89. При междугородных  перевозках  скоропортящихся  животноводческих грузов    автотранспорт   должен   проходить   обработку   в   Варницком дезпромывочном  пункте  не  реже одного раза в десять  дней;  в  случаях вывоза
   продукции за пределы республики - перед отправкой каждой  партии груза.</p>

    <p style="text-indent:2em;"></p><h6>90. При перевозке  скоропортящихся грузов водитель авторефрижератора ОБЯЗАН:</h6><p></p>

    <p style="text-indent:2em;">а) проверять  правильность  погрузки  и размещения  груза  в  кузове рефрижератора  и  в случае обнаружения неправильности  размещения  груза требовать  устранения замеченных недостатков. Указания водителя в  части устранения 
	замеченных   недостатков   являются   обязательными    для грузоотправителя;</p><br>

    <p style="text-indent:2em;">b) проверить соответствие внесенных данных о температуре груза перед  погрузкой,  сроках  доставки, качественном состоянии груза и упаковки  в предъявленном   грузоотправителем   сертификате  или   удостоверении   о качестве,
	а также в листе контрольных проверок температуры;</p><br>

    <p style="text-indent:2em;">c) проследить  за полной очисткой грузополучателем кузова автомобиля после выгрузки груза, а в необходимых случаях потребовать его промывки и дезинфекции;</p><br>

    <p style="text-indent:2em;">d) предъявлять для проверки представителям грузополучателя и органам контроля   лист   контрольных  проверок  (приложение  5)   с   отметками температуры в пути следования;</p><br>

    <p style="text-indent:2em;">e) при наличии  признаков  возможного снижения качества  перевозимой продукции  ставить  в  известность ближайшего транспортного  агента  для вызова    представителей   этого   предприятия,   органы   контроля    и представителей
	торговой   сети  для  решения  вопроса  о   возможности дальнейшей перевозки или передаче груза местным органам для реализации;</p><br>

    <p style="text-indent:2em;">f) поддерживать температурный режим, указанный в приложении 3, а при транспортировке  предварительно неохлажденных овощей и фруктов снижать в пути следования температуру в кузове до указанной в приложении.</p>

<p></p><h4>IX. КОНТРОЛЬ ЗА ВЫПОЛНЕНИЕМ
УСТАНОВЛЕННЫХ ПРАВИЛ ПЕРЕВОЗОК ГРУЗОВ</h4><p></p>

    <p style="text-indent:2em;">Контроль за выполнением   установленных   правил  перевозок   грузов автомобильным    транспортом    на   территории    Республики    Молдова осуществляется   соответствующими   центральными  отраслевыми   органами публичного
	управления  и  органами  местного  публичного  управления  в пределах  их  компетенции  (ст.53 Кодекса автомобильного  транспорта)  и Государственной санитарно-эпидемиологической службой Республики Молдова.</p>

<p></p><h4>X. ОТВЕТСТВЕННОСТЬ ТРАНСПОРТНЫХ  АГЕНТОВ,
УЧАСТВУЮЩИХ В ПЕРЕВОЗКАХ ГРУЗОВ</h4><p></p>

    <p style="font-weight:500;">1. Ответственность  транспортных агентов за невыполнение качественно и   в   срок  перевозок  грузов,  за  сохранность   перевозимых   грузов определяется   в  соответствии  с  Кодексом  автомобильного   транспорта
	Республики Молдова и действующим законодательством.</p><br>

    <p style="font-weight:500;">2. По всем вопросам,   не   предусмотренным  настоящими   правилами, необходимо    руководствоваться   Кодексом   автомобильного   транспорта Республики Молдова и Правилами автомобильных перевозок грузов.</p>

<p></p><h6>Приложение 1</h6><p></p>

<p></p><h4>ТЕМПЕРАТУРА ПРЕДЪЯВЛЯЕМЫХ К ПЕРЕВОЗКЕ МЯСА,
СУБПРОДУКТОВ И МЯСНЫХ ПОЛУФАБРИКАТОВ</h4><p></p>

<p>N | Наименование груза | Температура груза,</p><br>

<p>п/п| |  предъявляемого к</p><br>

<p>   |    |    перевозке, С</p>

<p></p><h4>М Я С О</h4><p></p>
	
<p>а) мороженое</p><br>
	
<p>1. Мясо - говядина                                       не выше - 6</p><br>

<p>2. Мясо - баранина и козлятина                           не выше - 6</p><br>

<p>3. Мясо - свинина                                        не выше - 6</p><br>

<p>4. Блоки мясные                                          не выше - 6</p><br>

<p>5. Мясо - птицы, кроликов                                не выше - 6</p><br>

<p>6. Субпродукты                                           не выше - 6</p><br>

<p>7. Жир - сырец, шпик свиной                              не выше - 8</p><br>

<p>b) охлажденное</p><br>
	
<p>8. Мясо - говядина                                       от 0 до + 4</p><br>

<p>9. Мясо - баранина и козлятина                           от 0 до + 4</p><br>

<p>10. Мясо - свинина                                        от 0 до + 4</p><br>

<p>11. Мясо птицы и кроликов                                 от 0 до + 4</p><br>

<p>c) остывшее</p><br>
	
<p>12. Мясо - говядина                                       от + 4 до + 12</p><br>

<p>13. Мясо - телятина и баранина                            от + 4 до + 12</p>

<p></p><h4>М Я С О П Р О Д У К Т Ы</h4><p></p>
	
<p>14. Колбасы сырокопченые, копченые и мясокопчености       не выше + 15</p><br>

<p>15. Колбасы вареные                                       от + 2 до + 6</p><br>

<p>16. Бекон соленый                                         не выше + 8</p><br>

<p>17. Солонина из свинины и говядины, мясопродукты соленые  не выше + 8</p><br>

<p>18. Жиры животные, топленые пищевые                       от -5 до -8</p>

<p></p><h6>Примечание.</h6><p></p> 

<p style="text-indent:2em;">Мясо перевозится в подвижном составе тушами и полутушами без тары. Мясопродукты перевозятся в затаренном виде.</p>

<p></p><h6>Приложение 2</h6><p></p> 

<p></p><h4>ВИДЫ ТАРЫ ДЛЯ ПЕРЕВОЗКИ ПРОДУКТОВ РАСТИТЕЛЬНОГО ПРОИСХОЖДЕНИЯ</h4><p></p>

<p></p><h6>Вид тары для продуктов</h6><p></p>

<p>Продукты</p><br>

<p>предназначенных для потребления в свежем виде и хранения</p><br>

<p>предназначенных для промышленной переработки</p>

<p></p><h6>1. Картофель</h6><p></p>

<p>Контейнеры, корзины, ящики-клет- ки, мешки, кули.</p><br> 

<p>Перевозка кар-тофеля навалом допускается в пе-риод массовых заготовок и реали-зации.</p>

<p></p><h6>2. Капуста бело-качанная, в т.ч. красно-качанная</h6><p></p>
	
<p>Ящики, контейнеры, мешки, кули. Допускается перевозка
 без тары (навалом на чистой  подстилке).</p>
	
<p></p><h6>3. Капуста цветная</h6><p></p>

<p>Ящики</p>

<p></p><h6>4. Лук, чеснок</h6><p></p>    

<p>Ящики</p><br>

<p>При перевозках от  места выращи-вания до  заготовительных  пунк-тов упаковывается в мягкую тару.</p>

<p></p><h6>5. Морковь, свекла Ящики, контейнеры.</h6><p></p>

<p>Допускается перевозка  корнепло-дов в мягкой  таре для местного потребления.</p>

<p></p><h6>6. Бурые помидоры</h6><p></p>

<p></p><h6>7. Спелые помидоры</h6><p></p>

<p></p><h6>8. Огурцы</h6><p></p>          

<p>Ящики, корзины</p>

<p></p><h6>9. Перец</h6><p></p>

<p>Ящики</p>

<p></p><h6>10. Баклажаны</h6><p></p>     

<p>Ящики</p>

<p></p><h6>11. Дыни</h6><p></p>           

<p>Контейнеры, клетки емк. до 50кг и без тары на подстилке.</p><br>

<p>Перевозка навалом допускается в период массовых заготовок и реа- лизации.</p><br>

<p style="text-indent:2em;">В каждую единицу  упа- ковки должны укладываться плоды одного размера, помологического и товарного сортов.</p>

<p></p><h6>12. Арбузы</h6><p></p>          

<p style="text-indent:2em;">Контейнеры,  клетки и  без тары на подстилке из соломы. Перевозка навалом допускается в
период массовых заготовок и реа- лизации. Арбузы  калибруются по размерам: крупные от 25 см и бо-
лее в диаметре, средние - от 20 до 25см, мелкие - от 15 до 20см.</p>

<p></p><h6>13. Кабачки</h6><p></p>
 
<p>Ящики</p>

<p></p><h6>14. Стручковая фасоль</h6><p></p>
	
<p></p><h6>15. Горох</h6><p></p>

<p></p><h6>16. Зелень</h6><p></p>

<p>Ящики</p>

<p></p><h6>17. Яблоки, груши,айва</h6><p></p>

<p>Ящики емк.15-20 кг</p><br>

<p>Ящики емк. 20-25 кг</p><br>

<p>Ящики емк. 40,0-42,5 кг</p><br>

<p style="text-indent:2em;">При доставке  в  торговую  сеть яблоки крупных и  средних разме- ров первого сорта  должны упако-
вываться с оберткой каждого пло-да в тонкую бумагу.  Яблоки мел- кие размером до 40мм в диаметре
могут быть упакованы в ящики на- сыпью. Стружка кладется  на дно и под крышку ящика.</p><br>
    
<p>Ящики емк.40,0-42,5кг и без тары на постил-ке из соломы.</p>

<p></p><h6>18. Вишня, черешня</h6><p></p>  

<p>Корзинки, решета емк. до 6 кг.</p><br>  

<p>Ящики и корзина емк.</p><br>
  
<p>Открытые ящики емк. до 10 кг. до 12 кг  (решета и плоские корзины мо-гут  увязываться  в паки).</p><br>

<p>Ящики и корзины до 12 кг. Упа-ковываются насыпью с выкладкой  верхнего ряда.</p>                 

<p></p><h6>19. Абрикосы,персики</h6><p></p>

<p>Ящики, корзины, решета, поддоны	емк. до 12 кг.</p><br>

<p style="text-indent:2em;">Крупные плоды абрикосов  уклады- ваются в ящики  рядами,  а сред-
ние и мелкие плоды укладываются насыпью с выкладкой верхнего ря-
да. Абрикосы и  персики  уклады- ваются в уровень  с краями тары
с повышением к центру.Высота слоя 10-14 см. Персики калибруются по размерам:
крупные более  50 мм в диаметре, средние 35-50 мм, мелкие-меньше 35 мм.</p>

<p></p><h6>20. Слива, алыча</h6><p></p>    

<p>Ящики емк. 12-15 кг, решета емк до 60 кг, (решета  могут  увязы-
ваться в паки).</p>

<p></p><h6>21. Смородина,крыжовник</h6><p></p>       

<p>Корзина, ящики емк. до 10 кг.</p><br>   

<p>Ящики, решета ем- костью  до  6 кг.</p>

<p></p><h6>22. Малина,клубника</h6><p></p>         

<p>Ящики, корзинки  емк. до 30 кг. Ящики, корзинки</p><br>

<p>Корзинки и ящики  наполняются с емкостью до 5 кг. небольшим повышением  к центру.</p><br>

<p>Выстилка бумагой дна и боков та- ры не допускается.</p>

<p></p><h6>23. Виноград</h6><p></p>        

<p>Ящики и решета емк. до 7 кг (ре-шета могут увязываться в паки).</p>

<p></p><h6>Приложение 3</h6><p></p> 

<p></p><h4>ТЕМПЕРАТУРНЫЙ РЕЖИМ В КУЗОВЕ АВТОРЕФРИЖЕРАТОРА ПРИ ПЕРЕВОЗКЕ СКОРОПОРТЯЩИХСЯ ПРОДУКТОВ</h4><p></p>

<p>N</p><br> 

<p>Наименование груза</p><br> 

<p>Температура воздуха</p>

<p></p><h6>Приложение</h6><p></p>

<p>п/п в кузове авторефри - жератора при транс-портировке, С</p><br>

<p>от | до</p><br>

<p style="text-indent:2em;">1. Мороженые грузы (мясо, субпродукты,   не выше мясо кроличье,  птица, шпик,  рыба,     
яичные мороженые продукты -12</p><br>
	
<p>2. Колбасы копченые  0 -3</p><br>

<p>3. Колбасы полукопченые                      0         -3</p><br>

<p>4. Сыры сырокопченые                       +10         +8</p><br>

<p>5. Мясо остывшее                           +10         +4</p><br>

<p>6. Мясо и птица охлажденные                  0         -1</p><br>

<p>7. Жиры животные,  топленые, маргарин,масло топленое                            0         -3</p><br>
	
<p>8. Масло сливочное                     не выше -6</p><br>

<p>9. Молоко  свежее  и  пастеризованное,Перевозка молочные продукты в бутылках и продолжит.</p><br>

<p>тетрапаках +5 0 более 12 часов не разрешается</p><br>

<p>10. Молоко сгущенное и в порошке             +6         +2</p><br>

<p>11. Сыры всякие                              +8         -2</p><br>

<p>12. Мороженое                           не выше -14</p><br>

<p>13. Слабосоленая сельдь в ящиках        не выше -6</p><br>

<p>14. Маринады, балыки  копченые и  вяле-ные, икра рыбная (кроме наименован-ной в п.15). 0 -3</p><br>

<p>15. Икра рыбная (зернистая,  паюсная и лососевая  красная,  крепкосоленая частиковая) 0  -5</p><br>
	
<p>16. Рыба охлажденная, переложенная льдом      0         -1</p><br>

<p>17. Консервы рыбные                          +6         -1</p><br>

<p>18. Пресервы рыбные                           0         -5</p><br>

<p>19. Рыба горячего копчения замороженная  не выше -8</p><br>

<p>20. Консервы всякие (кроме рыбных)          +15        +20</p>

<p></p><h6>21. Яйца</h6><p></p>

<p>а) не подвергнутые холодильной обработке    +8         +4</p><br>

<p>b) из холодильника                       +3          0</p><br>
	
<p>22. Дрожжи                               0         +4</p><br>

<p style="text-indent:2em;">23. Быстрозамороженные мясные, рыбные,
    кулинарные изделия, фрукты и  яго-
    ды, сгущенные соки фруктовые        не выше -14</p><br>
	
<p>24. Масло растительное                      +10         +2</p>

<p></p><h6>Продукты растительного происхождения</h6><p></p>

<p>25. Картофель                                +5        +20</p><br>

<p>26. Капуста кочанная ранняя                   0        +10</p><br>

<p>27. Капуста брюссельская                     +1        +12</p><br>

<p>28. Капуста цветная                          +1         +8</p><br>

<p>29. Морковь, свекла красная                   0         +8</p><br>

<p>30. Помидоры бурые                           +8        +15</p><br>

<p>31. Помидоры красные                         +4         +8</p><br>

<p>32. Огурцы, перец                            +5        +10</p><br>

<p>33. Баклажаны                                +8        +10</p><br>

<p>34. Дыни                                     +4        +10</p><br>

<p>35. Тыква                                     0         +5</p><br>

<p>36. Кабачки                                  +5        +10</p><br>

<p>37. Стручковая фасоль                        +2         +8</p><br>

<p>38. Горох-лопатка                            +1         +5</p><br>

<p>39. Зелень                                    0         +5</p><br>

<p>40. Яблоки, айва                             +3        +10</p><br>

<p>41. Груши                                     0         +5</p><br>

<p>42. Вишня, черешня                            0         +4</p><br>

<p>43. Абрикосы                                  0         +3</p><br>

<p>44. Персики                                   0         +7</p><br>

<p>45. Слива, алыча                              0         +7</p><br>

<p>46. Клубника, крыжовник, смородина           +1         +2</p><br>

<p>47. Виноград                                 +1         +8</p><br>

<p>48. Ананасы                                  +8        +11</p><br>

<p>49. Бананы (не совсем зрелые)               +11        +15</p>

<p style="text-indent:2em;"></p><h6>50. Цитрусовые:</h6><p></p>

    <p>а) апельсины                             +4</p><br>
	
    <p>b) лимоны незрелые                       +8</p><br>
	
    <p>c) лимоны зрелые                         +2</p><br>
	
    <p>d) мандарины                             +2</p><br>
	
<p>51. Черника                                   0         +4</p><br>

<p>52. Редис                                    +1         +8</p>

<p style="text-indent:2em;"></p><h6>Примечания:</h6><p></p>

<p style="text-indent:2em;">1. При понижении  температуры  наружного  воздуха ниже  указанной  в графе  4  перевозка грузов, перечисленных в пунктах 4, 5, 6, 9, 11,  12, 13,   15,  16,  17,  18,  20,  21,  22,  24,  в  авторефрижераторах,
не оборудованных приборами отопления, не допускается.</p><br>
  
<p style="text-indent:2em;">2. При понижении  температуры  наружного  воздуха ниже  указанной  в графе   3   перевозка   грузов,  перечисленных  в   пунктах   25-52,   в авторефрижераторах, не оборудованных приборами отопления, не допускается.</p><br>

<p style="text-indent:2em;">3. Для грузов, перевозимых в зимний период при понижении температуры наружного  воздуха ниже указанной в графе 4, перевозка производится  без включения холодильной установки.</p>

<p></p><h6>Приложение 4</h6><p></p>

<p></p><h4>ГРУППЫ скоропортящихся грузов, допускаемых к совместной перевозке в одном автомобиле</h4><p></p>

<p></p><h6>Группа 1. Мороженые и охлажденные продукты</h6><p></p>

<p>1. Мороженое говяжье и баранье мясо</p><br>

<p>2. Мороженое свиное мясо</p><br>

<p>3. Субпродукты мороженые I категории (кроме мозгов) в таре</p><br>

<p>4. Мясо и субпродукты, замороженные в блоках</p><br>

<p>5. Мороженая птица</p><br>

<p>6. Сало-шпик</p><br>

<p>7. Говяжий и бараний топленый жир</p><br>

<p>8. Лярд</p><br>

<p>9. Масло сливочное</p><br>

<p>10. Масло топленое</p><br>

<p>11. Маргарин</p><br>

<p>12. Яичные и мороженые продукты</p>

<p></p><h6>Группа 2. Охлажденные продукты</h6><p></p>

<p>1. Яблоки зимние</p><br>

<p>2. Груши зимние</p><br>

<p>3. Виноград</p>

<p></p><h6>Группа 3. Охлажденные продукты</h6><p></p>

<p>1. Яблоки и груши летние и осенние</p><br>

<p>2. Абрикосы и персики</p><br>

<p>3. Слива</p><br>

<p>4. Виноград</p><br>

<p>5. Вишня, черешня</p><br>

<p>6. Крыжовник</p><br>

<p>7. Смородина</p>

<p></p><h6>Группа 4. Охлажденные продукты</h6><p></p>

<p>1. Томаты бурые и розовые</p><br>

<p>2. Капуста цветная</p><br>

<p>3. Капуста белокочанная</p>

<p></p><h6>Группа 5. Охлажденные продукты</h6><p></p>

<p>1. Яйца</p><br>

<p>2. Консервы в герметической жестяной и стеклянной таре</p>

<p></p><h6>Группа 6. Сушеные продукты</h6><p></p>

<p>1. Сухие яичные продукты</p><br>

<p>2. Сухой омлет</p><br>

<p>3. Сухое молоко</p><br>

<p>4. Сухое обезжиренное молоко</p><br>

<p>5. Сухофрукты</p><br>

<p>6. Орехи</p><br>

<p>7. Сгущенное молоко</p><br>

<p>8. Сгущенное молоко в герметической жестяной таре</p><br>

<p>9. Консервы в герметической жестяной и стеклянной таре</p>

<p></p><h6>Приложение 5</h6><p></p>

<p></p><h4>Л И С Т</h4><p></p>

<p>контрольных проверок температуры</p><br>

<p>Водитель ___________________________________________________________</p><br>

<p>(фио)</p><br>

<p>авторефрижератор  N __________________, путевой лист N______________</p><br>

<p>1. Авторефрижератор загружен__________________, температурный режим:</p><br>

<p>наимен.груза</p><br>

<p>а) в кузове рефрижератора____________, b) загруженного груза________</p><br>

<p>подписи ____________________________________________________________</p><br>

<p>грузоотправитель, водитель, дата, время</p>

<p style="text-indent:2em;"></p><h6>2. Результаты замера температуры в пути следования:</h6><p></p>

<p style="text-indent:2em;">N | Дата, |Наименование|Результаты замера| Подпись ответ. лица,| Приме-
п/п| часы |контрольного| температуры в | контролировавшего | чание | 
пункта | кузове | температуру</p>

<p style="text-indent:2em;"></p><h6>3. Результаты замера температуры при разгрузке:</h6><p></p>

<p>а) в кузове рефрижератора_____________b) разгруженного груза________</p><br>

<p>Подписи ____________________________________________________________</p><br>

<p>грузополучатель, водитель, дата, часы</p>

<p></p><h6>Приложение 6</h6><p></p>

<p>Перечень нормативных  актов,  утративших  силу в связи  с  принятием Правил перевозки скоропортящихся грузов.</p><br>

<p style="text-indent:2em;">Разделы 30, 33,   34,  35,  47,  49,  58  Правил  перевозок   грузов автомобильным  транспортом в Молдавской ССР, утвержденных  
Министерством автомобильного транспорта Молдавской ССР (г.Кишинев, Картя Молдовеняскэ, 1982 г.).</p><br>

<p><a href="http://lex.justice.md/viewdoc.php?action=view&amp;view=doc&amp;id=313586&amp;lang=2">Источник</a></p>


</div>
`