import { connect } from 'react-redux'
import { compose } from 'recompose'

import withTranslation from '../../../globals/components/withTranslation'

import Component from './component'

export default compose(
  withTranslation,
  connect(state => {
    return {
      isAuthenticated: !!state.auth.accessToken,
    }
  })
)(Component)
