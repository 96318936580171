export const int18 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Европейское соглашение о международной дорожной перевозке опасных грузов (ДОПОГ)</h4><p></p>

<p></p><h4>(Женева, 30 сентября 1957 г.)</h4><p></p>

<p style="text-indent:2em;"></p><h6>Договаривающиеся стороны, стремясь увеличить безопасность международных дорожных перевозок,
 согласились о нижеследующем:</h6><p></p>

<p style="font-weight:500;">Статья 1</p>

<p style="text-indent:2em;"></p><h6>Для целей настоящего Соглашения:</h6><p></p>

<p style="text-indent:2em;">а) термин "транспортные средства" означает автомобили, тягачи с полуприцепами, прицепы и полуприцепы,
 соответствующие определениям, приведенным в статье 4 Конвенции о дорожном движении от 19 сентября 1949
 года, за исключением транспортных средств, принадлежащих вооруженным силам одной из Договаривающихся
 Сторон или находящихся в ведении этих вооруженных сил;</p><br>

<p style="text-indent:2em;">б) термин "опасные грузы" означает вещества и предметы, которые не допускаются к международной дорожной
 перевозке согласно положениям приложений А и В или допускаются к ней с соблюдением определенных
 условий;</p><br>

<p style="text-indent:2em;">с) термин "международная перевозка" означает всякую перевозку производимую через территорию по 
крайней мере двух Договаривающихся Сторон при помощи определенных выше в пункте а) транспортных
 средств.</p><br>

<p style="font-weight:500;">Статья 2</p><br>

<p style="text-indent:2em;">1. При условии соблюдения положений пункта 3 статьи 4 опасные грузы, которые не допускаются к
 перевозке согласно приложения А, не должны быть предметом международной перевозки.</p>

<p style="text-indent:2em;"></p><h6>2. Международная перевозка прочих опасных грузов разрешается при условии соблюдения:</h6><p></p>

<p style="text-indent:2em;">а) предусмотренных в приложении А требований, которым должны удовлетворять рассматриваемые грузы,
 в частности, что касается их упаковки и маркировки, и</p><br>

<p style="text-indent:2em;">б) предусмотренных в приложении В требований, в частности, что касается конструкции, оборудования
 и движения транспортного средства, перевозящего рассматриваемые грузы, при условии соблюдения
 предписаний, приведенных в пункте 2 статьи 4.</p><br>

<p style="font-weight:500;">Статья 3</p><br>

<p>Приложения к настоящему Соглашению являются его неотъемлемой частью.</p><br>

<p style="font-weight:500;">Статья 4</p><br>

<p style="text-indent:2em;">1. Каждая Договаривающаяся Сторона сохраняет за собой право регламентировать или воспрещать
 по причинам, иным, чем безопасность в пути, ввоз на свою территорию опасных грузов.</p><br>

<p style="text-indent:2em;">2. Транспортные средства, находившиеся в эксплуатации на территории одной из Договаривающихся 
Сторон в момент вступления в силу настоящего Соглашения или которые были сданы в эксплуатацию
 в течение двух месяцев после этого вступления в силу, имеют право производить в течение трех лет,
 начиная с даты этого вступления в силу, имеют право производить в течение трех лет, начиная с даты 
 этого вступления в силу, международные перевозки опасных грузов, даже если их конструкция
 и оборудование не полностью удовлетворяют требованиям, предусмотренным в приложении В для
 данной перевозки. Однако этот срок может быть сокращен путем включения в приложение В особых
 положений.</p><br>

<p style="text-indent:2em;">3. Договаривающиеся Стороны сохраняют за собой право уславливаться путем заключения особых
 двусторонних или многосторонних соглашений о том, что некоторые опасные грузы, всякая международная
 перевозка которых запрещается настоящим Соглашением, могут при известных условиях допускаться 
 к международной перевозке через их территорию или что опасные грузы, международная перевозка
 которых допускается настоящим Соглашением лишь на определенных условиях, могут быть предметом 
 международной перевозки через их территорию с соблюдением требований, менее строгих, чем те,
 которые предписаны в приложениях к настоящему Соглашению. Предусматриваемые в настоящем пункте
 особые двусторонние или многосторонние соглашения доводится до сведения Генерального секретаря
 Организации Объединенных Наций, который сообщает о них Договаривающимся Сторонам, не подписавшим
 вышеупомянутых соглашений.</p><br>

<p style="font-weight:500;">Статья 5</p><br>

<p style="text-indent:2em;">Перевозки, в отношении которых применяется настоящее Соглашение, продолжают быть предметом
 внутригосударственных или международных предписаний, касающихся дорожного движения, международных
 дорожных перевозок или международной торговли вообще.</p><br>

<p style="font-weight:500;">Статья 6</p>

<p style="text-indent:2em;"></p><h6>1. Страны, состоящие членами Европейской экономической комиссии, и страны, участвующие в работе
 Комиссии с правом совещательного голоса согласно пункту 8 положения о круге ведения этой Комиссии,
 могут стать Договаривающимися Сторонами настоящего Соглашения:</h6><p></p>

<p>а) путем его подписания,</p><br>

<p>б) путем его ратификации после подписания с оговоркой о ратификации и</p><br>

<p>с) путем присоединения к нему.</p><br>

<p style="text-indent:2em;">2. Страны, могущие участвовать в некоторых работах Европейской экономической комиссии в порядке
 применения пункта 11 Положения о круге ведения этой комиссии, могут стать Договаривающимися Сторонами
 настоящего Соглашения путем присоединения к нему после его вступления в силу.</p><br>

<p style="text-indent:2em;">3. Соглашение открыто для подписания до 15 декабря 1957 года. После этой даты оно будет открыто для
 присоединения.</p><br>

<p style="text-indent:2em;">4. Ратификация или присоединение производится путем сдачи на хранение Генеральному секретарю
 Организации Объединенных Наций соответствующего акта.</p><br>

<p style="font-weight:500;">Статья 7</p><br>

<p style="text-indent:2em;">1. Настоящее Соглашение вступает в силу по истечении одного месяца с того дня, когда число указанных
 в пункте 1 статьи 6 стран подписавших его без оговорки о ратификации или сдавших на хранение акты о
 ратификации или присоединении, достигнет пяти. Однако правила приложений будут применяться лишь по
 истечении шести месяцев после вступления в силу самого Соглашения.</p><br>

<p style="text-indent:2em;">2. В каждой стране, которая ратифицирует настоящее Соглашение или присоединится к нему после того, как
 пять упомянутых в пункте 1 статьи 6стран подпишут его без оговорки о ратификации или сдадут на хранение
 акт о ратификации или присоединении, настоящее Соглашение вступает в силу по истечении одного месяца со
 дня сдачи на хранение акта о ратификации или присоединении указанной страны, а правила приложений к 
 Соглашению будут применяться в этой стране либо в тот же день, если они вступили в силу в этот момент,
 либо, в противном случае, в день, когда они должны применяться согласно приложениям пункта 1 настоящей 
 статьи.</p><br>

<p style="font-weight:500;">Статья 8</p><br>

<p style="text-indent:2em;">1. Каждая Договаривающаяся Сторона может денонсировать настоящее Соглашение путем нотификации,
 направленной Генеральному секретарю Организации Объединенных Наций.</p><br>

<p style="text-indent:2em;">2. Денонсация вступает в силы по истечении двенадцати месяцев с даты получения Генеральным
 секретарем касающейся ее нотификации.</p><br>

<p style="font-weight:500;">Статья 9</p><br>

<p style="text-indent:2em;">1. Настоящее Соглашение теряет силу, если после вступления его в силу число Договаривающихся Сторон
 составит менее пяти в течение последовательных двенадцати месяцев.</p><br>

<p style="text-indent:2em;">2. В случае заключения всемирного соглашения, регламентирующего дорожную перевозку опасных грузов,
 всякое положение настоящего Соглашения, противоречащее какому-либо положению всемирного соглашения
 автоматически лишается силу в сношениях между участвующими в настоящем Соглашении Сторонами, ставшими
 договаривающимися сторонами всемирного соглашения, считая со дня вступления последнего в силу, и
 заменяется ipso facto соответствующим положением всемирного соглашения.</p><br>

<p style="font-weight:500;">Статья 10</p><br>

<p style="text-indent:2em;">1. Каждая страна может при подписании настоящего Соглашения без оговорки о ратификации или при сдаче на
 хранение своего акта о ратификации или присоединении, или же в любой последующий момент заявить путем нотификации,
 направленной Генеральному секретарю Организации Объединенных Наций, что настоящее Соглашение будет применяться ко
 всем или к некоторым территориям, за внешние сношения которых она является от Соглашение и приложения к нему применяются
 к указанным в нотификации территории или территориям по истечении одного месяца со дня получения этой нотификации
 Генеральным секретарем.</p><br>

<p style="text-indent:2em;">2. Каждая страна, сделавшая, согласно пункту 1 настоящей статьи заявление о распространении действия настоящего
 Соглашения на территорию, за внешние сношения которой она является ответственной, может, согласно статье 8, денонсировать
 Соглашение, поскольку это касается указанной территории.</p><br>

<p style="font-weight:500;">Статья 11</p><br>

<p style="text-indent:2em;">1. Всякий спор между двумя или несколькими Договаривающимися Сторонами относительно толкования или применения настоящего
 Соглашения должен по возможности разрешаться путем переговоров между Сторонами,
 между которыми возник спор.</p><br>

<p style="text-indent:2em;">2. Всякий спор, который не будет разрешен путем переговоров, должен быть передан на арбитраж - по заявлению одной из
 спорящих Договаривающихся Сторон - и передается поэтому одному или нескольким третейским судьям, избранным по общему
 согласию спорящих Сторон. Если в течение трех месяцев со дня заявления об арбитраже спорящие Стороны не придут к
 соглашению относительно избрания третейского судьи или третейских судей, любая из этих Сторон может обратиться к
 Генеральному секретарю Организации Объединенных Наций с просьбой назначить единого третейского судью, которому спор
 передается на разрешение.</p><br>

<p style="text-indent:2em;">3. Решение третейского судьи или третейских судей, назначенных согласно пункту 2 настоящей статьи, имеет обязательную
 силу для спорящих Сторон.</p><br>

<p style="font-weight:500;">Статья 12</p><br>

<p style="text-indent:2em;">или ратификации настоящего Соглашения или присоединении к нему заявить, что она не считает себя связанной статьей 11.
 Другие Договаривающиеся Стороны не будут связаны статьей 11 по отношению к любой Договаривающейся Стороне, сделавшей
 подобную оговорку.</p><br>

<p style="text-indent:2em;">2. Каждая Договаривающаяся Сторона, сделавшая оговорку в соответствии с пунктом 1 настоящей статьи, может в любой 
момент снять ее путем нотификации, направленной Генеральному секретарю Организации Объединенных Наций.</p><br>

<p style="font-weight:500;">Статья 13</p><br>

<p style="text-indent:2em;">1. По истечении трех лет со дня вступления в силу настоящего Соглашения любая Договаривающаяся Сторона может путем
 нотификации, направленной Генеральному секретарю Организации Объединенных Наций, просить о созыве конференции для
 пересмотра текста Соглашения. Генеральный секретарь извещает об этой просьбе все Договаривающиеся Стороны и созывает
 конференцию для пересмотра Соглашения, если по истечении четырех месяцев со дня отправки им указанного извещения по
 крайней мере одна четверть Договаривающихся Сторон уведомят о том что они согласны с этой просьбой.</p><br>

<p style="text-indent:2em;">2. Если в соответствии с пунктом 1 настоящей статьи созывается конференция, Генеральный секретарь уведомляет об этом
 все Договаривающиеся Стороны и обращается к ним с просьбой представить в трехмесячный срок предложения, которые они
 желают внести на рассмотрение конференции. Генеральный секретарь сообщает всем Договаривающимся Сторонам предварительную
 повестку дня конференции, а также текст этих предложений по крайней мере за
 три месяца до даты открытия конференции.</p><br>

<p style="text-indent:2em;">3. На конференцию, созываемую в соответствии с настоящей статьей Генеральный секретарь приглашает все страны, указанные
 в пункте 1 статьи 6, а также страны, ставшие Договаривающимися Сторонами в соответствии с пунктом 2 статьи 6.</p><br>

<p style="font-weight:500;">Статья 14</p><br>

<p style="text-indent:2em;">1. Независимо от предусмотренной в статье 13 процедуры пересмотра, любая Договаривающаяся Сторона может предложить одну
 или несколько поправок в приложении к настоящему Соглашению. С этой целью она препровождает текст поправок Генеральному
 секретарю Организации Объединенных Наций. Для того чтобы обеспечить соответствие между этими приложениями и другими 
 международными соглашениями, касающимися перевозки опасных грузов, Генеральный секретарь может также вносить предложения
 об изменениях приложений к настоящему Соглашению.</p><br>

<p style="text-indent:2em;">2. Генеральный секретарь сообщает всем Договаривающимся Сторонам и доводит до сведения других стран, указанных в пункте
 1 статьи 6, все предложения, внесенные в соответствии с пунктом 1 настоящей статьи.</p><br>

<p style="text-indent:2em;">3. Всякий проект внесения поправок в приложении будет считаться принятым, если по истечении трех месяцев со дня его
 отправки Генеральным секретарем, по крайней мере одна треть Договаривающихся Сторон или пять из них, если одна треть
 превышает это число, не уведомят в письменной форме Генерального секретаря о своем возражении против предлагаемой
 поправки. Если поправка считается принятой, она вступает в силу для всех Договаривающихся Сторон либо по истечении
 нового трехмесячного срока, либо - если аналогичные поправки были внесены или, по всей вероятности, будут внесены в
 другие международные соглашения, предусмотренные в пункте 1 настоящей статьи - по истечении срока, устанавливаемого
 Генеральным секретарем, с тем чтобы по мере возможности позволить одновременное вступление в силу данной поправки и
 поправок, которые были внесены или, по всей вероятности, будут внесены в другие вышеупомянутые соглашения, однако этот
 срок не может быть меньше одного месяца.</p><br>

<p style="text-indent:2em;">4. Генеральный секретарь сообщает по возможности без промедления всем Договаривающимся Сторонам и всем указанным в
 пункте 1 статьи 6 странам о всяких возражениях Договаривающихся Сторон против предложенной поправки.</p>

<p style="text-indent:2em;"></p><h6>5. Если проект о внесении поправок в приложения не считается принятым, но если по крайней мере одна Договаривающаяся
 Сторона, иначе представившая проект, уведомит в письменной форме Генерального секретаря о своем согласии с проектом,
 Генеральный секретарь созывает в трехмесячный срок, начинающийся по истечении трехмесячного срока, предусмотренного
 в пункте 3настоящей статьи для возражения против поправки, совещание всех Договаривающихся Сторон и всех стран указанных
 в пункте 1 статьи 6. Генеральный секретарь может также пригласить на это совещание представителей:</h6><p></p>

<p>а) международных правительственных организаций, компетентных в области транспорта;</p><br>

<p style="text-indent:2em;">б) международных неправительственных организаций, деятельность которых имеет непосредственное отношение к
 перевозке опасных грузов на территории Договаривающихся Сторон.</p><br>

<p style="text-indent:2em;">6. Всякая поправка, принятая более, чем половиной всех Договаривающихся Сторон на совещании, созванном в
 соответствии с пунктом 5 настоящей статьи, вступает в силу для всех Договаривающихся Сторон в порядке,
 установленном на вышеупомянутом совещании большинством участвующих в нем Договаривающихся Сторон.</p><br>

<p style="font-weight:500;">Статья 15</p>

<p style="text-indent:2em;"></p><h6>Помимо предусмотренных в статьях 13 и 14нотификаций, Генеральный секретарь Организации Объединенных Наций
 сообщает упомянутым в пункте 1 статьи 6странам, а также странам, ставшим Договаривающимися Сторонами
 согласно пункту 2 статьи 6,:</h6><p></p>

<p>а) о подписании и ратификации Соглашения и присоединениях к нему в соответствии со статьей 6;</p><br>

<p>б) о датах вступления в силу настоящего Соглашения и приложений к нему в соответствии со статьей 7;</p><br>

<p>с) о денонсациях в соответствии со статьей 8;</p><br>

<p>д) об утрате настоящим Соглашением силы в соответствии со статьей 9;</p><br>

<p>е) о нотификациях и денонсациях, полученных в соответствии со статьей 10;</p><br>

<p>ж) о заявлениях и нотификациях, полученных в соответствии с пунктами 1 и 2 статьи 12;</p><br>

<p>з) о принятии и сроке вступления в силу поправок в соответствии с пунктами 3 и 6 статьи 14.</p><br>

<p style="font-weight:500;">Статья 16</p><br>

<p style="text-indent:2em;">1. Протокол о подписании настоящего Соглашения имеет ту же силу и значение и срок действия, что и само Соглашение,
 неотъемлемой частью которого он является.</p><br>

<p style="text-indent:2em;">2. Помимо оговорок, внесенных в Протокол о подписании, и тех, которые были сделаны в соответствии со статьей 12,
 никаких других оговорок к настоящему Соглашению не допускается.</p><br>

<p style="font-weight:500;">Статья 17</p><br>

<p style="text-indent:2em;">После 15 декабря 1957 года подлинник настоящего Соглашения будет сдан на хранение Генеральному секретарю Организации
 Объединенных Наций, который препроводит заверенные копии каждой из стран, указанных в пункте
 1 статьи 6.</p><br>

<p style="text-indent:2em;">В удостоверение чего нижеподписавшиеся, надлежащим образом на то уполномоченные,
 подписали настоящее Соглашение.</p><br>

<p style="text-indent:2em;">Составлено в Женеве 30 сентября тысяча девятьсот пятьдесят седьмого года в одном экземпляре; текст самого Соглашения
 - на английском и французском языках и приложений - на французском языке, причем оба текста самого Соглашения
 являются равно аутентичными.</p><br>

<p style="text-indent:2em;">Генеральному секретарю Организации Объединенных Наций предлагается обеспечить официальный перевод приложений на
 английский язык и приложить этот перевод к предусмотренным в статье 17 настоящего Соглашения
 заверенным копиям.</p><br>

<p style="text-indent:2em;">Аутентичность данного текста на русском языке официальным текстам на английском и французском
 языках удостоверяется.</p><br>

<p style="text-indent:2em;">Подлинный текст Соглашения опубликован Комитетом по внутреннему транспорту Европейской экономической комиссии ООН
 отдельным изданием за N ЕСЕ/ТРАНС/60.</p><br>

<p style="font-weight:500;">Протокол о подписании Европейского соглашения о международной дорожной
 перевозке опасных грузов (ДОПОГ)</p>

<p style="text-indent:2em;"></p><h6>В момент подписания Европейского соглашения о международной дорожной перевозке опасных грузов (ДОПОГ) нижеподписавшиеся,
 надлежащим образом уполномоченные:</h6><p></p>

<p style="text-indent:2em;">1. Считая, что условия перевозки опасных грузов морским путем при ввозе в Соединенное Королевство или вывозе из этой
 страны значительно отличаются от условий, предписанных в приложении А к ДОПОГ, и что в ближайшем будущем не представляется
 возможным их изменить для согласования их с условиями ДОПОГ,</p><br>

<p style="text-indent:2em;">Приняв во внимание, что Соединенное Королевство обязалось представить в виде поправки к приложению А особое добавление
 к нему, которое будет содержать особые положения, применяемые при дорожных и морских перевозках опасных грузов между
 континентом и Соединенным Королевством,</p><br>

<p style="text-indent:2em;">Решают, что до вступления в силу этого особого добавления производимые в порядке применения ДОПОГ перевозки опасных
 грузов при ввозе в Соединенное Королевство или при вывозе из этой страны должны удовлетворять правилам приложения А 
 к ДОПОГ и, кроме того, действующим в Соединенном Королевстве предписаниям, касающимся перевозки опасных грузов
 морским путем;</p><br>

<p style="text-indent:2em;">2. Принимают к сведению сделанное представителем Франции заявление, согласно которому правительство Французской
 Республики, в отступление от пункта 2 статьи 4, сохраняет за собой право разрешать транспортным средствам, эксплуатируемым
 на территории другой Договаривающейся стороны и независимо от даты их сдачи в эксплуатацию, производить перевозки опасных
 грузов на французской территории лишь в том случае, если эти транспортные средства удовлетворяют либо требованиям,
 предусмотренным для этих перевозок в приложении В, либо условиям, предписанным для перевозки указанных грузов во
 французских правилах дорожной перевозки опасных грузов;</p><br>

<p style="text-indent:2em;">3. Рекомендуют, чтобы предложения о внесении поправок в настоящее Соглашение или в приложения к нему, сделанные в
 соответствии с пунктом 1 статьи 14 или пунктом 2 статьи 13, по мере возможности, предварительно обсуждались на
 совещании экспертов Договаривающихся сторон и, в случае необходимости, также экспертами как других упомянутых
 в пункте 1 статьи 6 Соглашения стран, так и международных организаций, указанных в пункте 5 статьи 14
 Соглашения.</p><br>
 
 <p><a href="http://docs.cntd.ru/document/58804886">Источник</a></p>

</div>
`