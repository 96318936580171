export const linksMD = (t) => {
    const links = [
        { key: "md1", title: t('md.menu.item.1') },
        { key: "md2", title: t('md.menu.item.2') },
        { key: "md3", title: t('md.menu.item.3') },
        { key: "md4", title: t('md.menu.item.4') },
        { key: "md5", title: t('md.menu.item.5') },
        { key: "md6", title: t('md.menu.item.6') },
        { key: "md7", title: t('md.menu.item.7') },
        { key: "md8", title: t('md.menu.item.8') },
        { key: "md9", title: t('md.menu.item.9') },
        { key: "md10", title: t('md.menu.item.10') },
        { key: "md11", title: t('md.menu.item.11') },
        { key: "md12", title: t('md.menu.item.12') },
        { key: "md13", title: t('md.menu.item.13') },
        { key: "md14", title: t('md.menu.item.14') },
        { key: "md15", title: t('md.menu.item.15') },
        { key: "md16", title: t('md.menu.item.16') },
        { key: "md17", title: t('md.menu.item.17') },
        { key: "md18", title: t('md.menu.item.18') },
        { key: "md19", title: t('md.menu.item.19') },
        { key: "md20", title: t('md.menu.item.20') },
    ]

    return links
}

