import { connect } from 'react-redux'
import { compose } from 'recompose'
import Component from './component'
import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'
import { IReduxStorage } from '../../../store/reducers/types'


export default compose(
  withTranslation,
  withPermissions,
  connect((state: IReduxStorage) => {
    const langId = state.ui.langId
    return {
      langId,
      isAuth: !!state.auth.accessToken,
    }
  })
)(Component)
