import React, { Component } from 'react'
import News from '../../news/list-mini'
import Forum from '../../forum'
import Ads from '../list-mini'
import Form from './form'
import Banner from '../../banner'

export default class extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-10 col-xl-9">
          <Form />
        </div>
        <div className="col-md-6 col-xl-7">
          <News />
          <Banner bannerId={580} />
          <Ads />
          <Forum />
        </div>
      </div>
    )
  }
}
