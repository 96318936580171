import React, { Component } from 'react'
import ConversationList from '../conversationlist'
import MessageList from '../messagelist'
import WidgetHeader from '../../shared/components/widget-header'
import { MessageListInterface } from './types'
import fetch from '../../shared/fetch'

import './messenger.scss'

export default class Messenger extends Component<MessageListInterface> {
  state = {
    userId: 0,
    listUserId: 0,
    userFullName: '',
    showSpinner: false
  }

  showMessages = (listUserId, userFullName) => {
    this.setState({ listUserId, userFullName })
  }
  componentDidMount() {
    const { userId } = this.props
    window.socket.emit('GET_CONVERSATION_LIST', userId);
  }

  componentWillUnmount() {
    const { setInitialMessengerState } = this.props

    fetch('/messenger/unread', {
      method: 'GET',
    }).then(response => {
      setInitialMessengerState(response.data.messages)
    })
  }

  render() {
    const { userId, closeMessenger, t } = this.props
    const { listUserId, userFullName, showSpinner } = this.state

    return (
      <div className="container-fluid messenger">
        <div className="panel-header widget-header_wrapper">
          <h4>{t('messenger.alt')}</h4>
          <div className="modal-close-button" onClick={closeMessenger}>
            <i className="fa fa-times modal-close-icon"></i>
          </div>
        </div>

        <div className='row' style={{ height: '100%' }}>
          <div className="col-xs-8 col-sm-7 col-md-6 sidebar">
            <ConversationList
              userId={userId}
              showConversationWithUser={this.showMessages}
              isShowSpinner={() => this.setState({ showSpinner: !showSpinner })}
              closeMessenger={closeMessenger}
            />
          </div>
          <div className="col-xs-8 col-sm-9 col-md-10 content">
            <MessageList
              listUserId={listUserId}
              userId={userId}
              userFullName={userFullName}
              showSpinner={showSpinner}
              isShowSpinner={() => this.setState({ showSpinner: false })}
            />
          </div>
        </div>
      </div>
    )
  }
}
