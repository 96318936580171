import { connect } from 'react-redux'
import { compose } from 'recompose'
import _ from 'lodash'

import withTranslation from '../../globals/components/withTranslation'
import withPermissions from '../../globals/components/withPermissions'

import Component from './component'

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state) => {
     
      return {
        languages: state.ui.languages,
        langId: state.ui.langId,
      }
    },
    {
   
    }
  )
)(Component)
