export const eu5 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Transport rutier</h4><p></p>

<p style="font-weight:500;">Transportul de mărfuri</p><br>

<p style="font-weight:500;">Taxarea vehiculelor grele de marfă: Directiva „Eurovigneta”</p><br>

<p style="text-indent:2em;">Majoritatea țărilor UE impun taxe transportatorilor rutieri pentru utilizarea infrastructurii lor de transport, în special a drumurilor. UE a introdus Eurovigneta cu scopul de
 a recupera costurile de construcție, de întreținere, de reparare și de mediu, de a asigura o concurență echitabilă și de a preveni discriminarea.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Directiva 1999/62/CE a Parlamentului European și a Consiliului din 17 iunie 1999 de aplicare a taxelor la vehiculele grele de marfă pentru utilizarea anumitor infrastructuri.</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva înlocuiește un act legislativ anterior - Directiva 93/89/CEE - care fusese anulată de Curtea Europeană de Justiție în 1995. Aceasta armonizează condițiile în care autoritățile
 naționale pot aplica impozite, taxe de trecere și taxe de utilizare bunurilor transportate pe cale rutieră.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Taxele financiare s-au aplicat inițial numai vehiculelor cu o greutate încărcată brută maxim admisă de 12 tone. Începând din 2012, aplicarea s-a extins și în cazul vehiculelor de
 peste 3,5 tone.</p><br>

<p>Aceste taxe pot fi impuse pe autostrăzi, pe poduri, în tunele, la trecătorile montane și pe anumite alte categorii de drumuri.</p><br>

<p style="text-indent:2em;">Taxele nu pot face discriminări din motive legate de naționalitatea transportatorului rutier sau de originea sau destinația vehiculului.</p><br>

<p>Sunt interzise controalele obligatorii la granițele interne ale UE.</p><br>

<p>Taxele pot varia în funcție de emisiile produse sau de durata utilizării infrastructurii rutiere.</p><br>

<p style="text-indent:2em;">Autoritățile naționale pot impune și alte taxe în anumite condiții, cum ar fi pentru înmatriculare, încărcături anormale, parcare sau pentru evitarea congestionării.</p><br>

<p style="text-indent:2em;">Taxele nu se aplică vehiculelor înmatriculate în Insulele Canare, în Ceuta și Melilla, în Insulele Azore sau în Madeira și care circulă pe teritoriile respective sau între acele
 teritorii și Spania și Portugalia.</p><br>

<p style="text-indent:2em;">Directiva 2003/96/CE stabilește nivelurile minime alte taxelor pentru toate tipurile de carburant. Țările cu taxe de utilizare a drumurilor pot aplica valori reduse pentru
 combustibilul utilizat de vehiculele respective.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p>Începând cu 20 iulie 1999.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p style="text-indent:2em;">În 2014, Comisia a adoptat o strategie de reducere a consumului de carburant și a emisiilor de CO2 din transporturi. Aceasta a accentuat contribuțiile posibile ale tehnologiilor noi,
 inclusiv ale combustibililor alternativi, precum și ale trecerii la alte forme de transport.</p><br>

<p>Pentru informații suplimentare, consultați site-ul Comisiei Europene referitor la taxarea rutieră.</p><br>

<p style="font-weight:500;">Norme pentru compilarea statisticilor referitoare la transportul rutier de mărfuri</p>

<p style="text-indent:2em;"></p><h6>SINTEZĂ PRIVIND:</h6><p></p>

<p>Regulamentul (UE) nr. 70/2012 – statisticile referitoare la transportul rutier de mărfuri</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Stabilește normele pentru realizarea de statistici comparabile la nivelul întregii UE referitoare la transportul rutier de mărfuri.</p><br>

<p style="text-indent:2em;">Reformează și abrogă Regulamentul (CE) nr. 1172/98, care a fost modificat de mai multe ori, și aliniază regulamentul la Tratatul de la Lisabona în ceea ce privește delegarea de competențe
 Comisiei Europene pentru adoptarea de acte legislative suplimentare.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Domeniul de aplicare</p><br>

<p style="text-indent:2em;">Fiecare țară a UE trebuie să elaboreze și să îi transmită Comisiei (Eurostat) date referitoare la mărfurile transportate cu vehicule de transport rutier de mărfuri care sunt
 înmatriculate pe teritoriul lor.</p>

<p style="text-indent:2em;"></p><h6>Regulamentul nu se aplică mărfurilor transportate cu:</h6><p></p>

<p style="text-indent:2em;">vehicule de transport rutier de mărfuri a căror greutate sau ale căror dimensiuni autorizate depășesc limitele normal admise în țările UE respective;</p><br>

<p style="text-indent:2em;">vehicule agricole, vehicule militare și vehicule aparținând administrațiilor publice centrale sau locale, cu excepția vehiculelor de transport rutier de mărfuri care aparțin unor
 întreprinderi publice, în special întreprinderilor de cale ferată.</p><br>

<p style="text-indent:2em;">Țările UE pot exclude vehiculele de transport rutier de mărfuri a căror sarcină utilă sau greutate maximă admisă se situează sub o anumită limită. Această limită nu poate depăși
 o sarcină utilă de 3,5 tone sau o greutate maximă admisă de 6 tone în cazul unui singur autovehicul.</p><br>

<p style="text-indent:2em;">Regulamentul nu se aplică în cazul Maltei, atât timp cât numărul vehiculelor de transport rutier de mărfuri înmatriculate în Malta autorizate să efectueze transporturi rutiere
 internaționale de mărfuri nu depășește 400 de vehicule.</p><br>

<p style="font-weight:500;">Culegerea și transmiterea datelor</p>

<p style="text-indent:2em;"></p><h6>În fiecare trimestru, în baza unor sondaje, țările UE trebuie să colecteze și să transmită Eurostat date referitoare la trei aspecte:</h6><p></p>

<p>vehicule (a se vedea anexele I și II la regulament);</p><br>

<p>deplasări (a se vedea anexa III la regulament);</p><br>

<p>mărfuri (a se vedea anexele IV și V la regulament).</p><br>

<p>Țările UE trebuie să transmită datele în decurs de cinci luni de la sfârșitul fiecărei perioade trimestriale de observare.</p><br>

<p style="font-weight:500;">Difuzarea rezultatelor</p><br>

<p>Eurostat difuzează datele în termen de cel mult 12 luni de la terminarea perioadei la care se referă acestea.</p><br>

<p style="font-weight:500;">Rapoarte</p><br>

<p style="text-indent:2em;">Atunci când transmit primele date trimestriale, țările UE trebuie să trimită Eurostat un raport asupra metodelor folosite pentru compilarea datelor statistice. De asemenea, trebuie
 să informeze Eurostat cu privire la orice modificare majoră adusă acestor metode. În fiecare an, țările UE trebuie să furnizeze Eurostat informații despre dimensiunea eșantioanelor,
 rata non-răspunsurilor și, sub forma marjelor standard de eroare sau a intervalelor de încredere, despre acuratețea principalelor rezultate statistice.</p><br>

<p style="text-indent:2em;">Comisia (Eurostat) trebuie să prezinte un raport, prima oară până în decembrie 2014, iar ulterior din trei în trei ani, destinat Parlamentului European și Consiliului cu privire la
 punerea în aplicare a regulamentului.</p><br>

<p style="font-weight:500;">Comitetul</p><br>

<p>Comisia este sfătuită și asistată de Comitetul sistemului statistic european, înființat prin Regulamentul (CE) nr. 223/2009.</p><br>

<p style="font-weight:500;">Competențele delegate Comisiei</p><br>

<p style="text-indent:2em;">Până acum, Comisia nu și-a exercitat competența de a adopta acte delegate, conferită de regulament. Într-un raport publicat în 2016, Comisia a explicat că nici evoluțiile economice
 și tehnice din domeniul transportului rutier de mărfuri, nici concluziile grupurilor de lucru ale Comisiei cu experții din țările UE privind statisticile legate de transportul rutier
 de mărfuri nu au impus necesitatea de a utiliza aceste competențe delegate.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Se aplică începând cu 23 februarie 2012.</p><br>

<p style="font-weight:500;">CONTEXT</p>

<p style="text-indent:2em;"></p><h6>Pentru informații suplimentare, consultați:</h6><p></p>

<p>Statistici referitoare la transportul rutier de mărfuri (Eurostat).</p><br>

<p style="font-weight:500;">DOCUMENTUL PRINCIPAL</p><br>

<p style="text-indent:2em;">Regulamentul (UE) nr. 70/2012 al Parlamentului European și al Consiliului din 18 ianuarie 2012 privind datele statistice referitoare la transportul rutier de mărfuri (JO L 32, 
3.2.2012, pp. 1-18)</p><br>

<p style="text-indent:2em;">Modificările succesive aduse Regulamentului (UE) nr. 70/2012 au fost integrate în textul de bază. Această versiune consolidată are doar un caracter informativ.</p><br>

<p style="font-weight:500;">DOCUMENTE CONEXE</p><br>

<p style="text-indent:2em;">Raport al Comisiei către Parlamentul European și Consiliu privind exercitarea competenței de a adopta acte delegate conferite Comisiei în temeiul Regulamentului (UE) nr. 70/2012
 din 18 ianuarie 2012 privind datele statistice referitoare la transportul rutier de mărfuri [COM(2016) 562 final, 12.9.2016]</p><br>

<p style="text-indent:2em;">Raport al Comisiei către Parlamentul European și Consiliu privind punerea în aplicare a Regulamentului (UE) nr. 70/2012 al Parlamentului European și al Consiliului din 18 ianuarie
 2012 privind datele statistice referitoare la transportul rutier de mărfuri [COM(2015) 17 final, 26.1.2015]</p><br>

<p style="font-weight:500;">Norme comune privind accesul la piața UE a transportului rutier de mărfuri</p><br>

<p style="text-indent:2em;">Operațiunile de transport rutier internațional* au fost complet liberalizate în Uniunea Europeană (UE). Cu toate acestea, transporturile rutiere naționale în țările UE efectuate de
 transportatori rezidenți într-o altă țară, cunoscute și sub denumirea de cabotaj*, fac în continuare obiectul restricțiilor. Introducerea unor norme comune pentru accesarea 
 piețelor internaționale și de cabotaj contribuie la promovarea unor condiții echitabile de concurență.</p><br>
 
<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Regulamentul (CE) nr. 1072/2009 al Parlamentului European și al Consiliului din 21 octombrie 2009 privind normele comune pentru accesul la piața transportului rutier internațional
 de mărfuri.</p><br>
 
<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul stabilește norme comune aplicabile pentru accesarea pieței transporturilor rutiere internaționale de mărfuri* pe cale rutieră în cadrul UE. Acesta stabilește condițiile
 în baza cărora operatorii de transport rutier de mărfuri rezidenți într-o altă țară pot să opereze servicii de transport într-o țară UE.</p><br>
 
<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Actul legislativ se aplică pentru:</h6><p></p>

<p>transportul rutier internațional de mărfuri efectuat contra cost în numele unui terț* pentru călătorii efectuate în UE;</p><br>

<p style="text-indent:2em;">transportul rutier național de mărfuri efectuat temporar de către un operator de transport rutier de mărfuri rezident într-o altă țară;</p><br>

<p style="text-indent:2em;">acea parte a călătoriei care se efectuează pe teritoriul oricărei țări UE în tranzit, unde transportul are loc între o țară UE și o țară din afara UE. Nu se aplică acelei părți
 din călătorie efectuată pe teritoriul țării UE de încărcare sau descărcare.</p><br>
 
<p style="text-indent:2em;">Pentru a efectua un transport internațional, operatorul de transport rutier de mărfuri trebuie să dețină o licență comunitară și un atestat de conducător dacă șoferul este un
 resortisant din afara UE.</p>
 
<p style="text-indent:2em;"></p><h6>Licența comunitară este:</h6><p></p>

<p style="text-indent:2em;">emisă de autoritățile competente ale țării UE unde este stabilit operatorul de transport rutier de mărfuri care este îndreptățit să efectueze transportul rutier internațional
 de mărfuri contra cost în numele unui terț;</p><br>
 
<p>reînnoibilă pe perioade de până la 10 ani;</p><br>

<p>emisă în numele operatorului de transport rutier de mărfuri și nu este transferabilă.</p>

<p style="text-indent:2em;"></p><h6>Atestatul de conducător este:</h6><p></p>

<p style="text-indent:2em;">emis de autoritățile competente ale țării UE unde este înregistrat operatorul de transport rutier de mărfuri care deține licența comunitară și utilizează sau angajează un șofer în
 respectiva țară, care nu este resortisant din UE sau rezident pe termen lung;</p><br>

<p>valabil până la cinci ani.</p><br>

<p style="text-indent:2em;">Dacă aceste condiții nu sunt îndeplinite, autoritățile de mai sus pot respinge o cerere pentru emiterea sau reînnoirea unei licențe sau pentru emiterea unui atestat.</p><br>

<p style="text-indent:2em;">Dacă deținătorul licenței comunitare sau a atestatului de conducător nu mai îndeplinește condițiile sau a furnizat informații incorecte, licența sau atestatul sunt retrase.</p>

<p style="text-indent:2em;"></p><h6>Legea impune reguli stricte în ceea ce privește operațiunile de cabotaj, în special:</h6><p></p>

<p style="text-indent:2em;">orice operator de transport rutier de mărfuri care efectuează operațiuni contra cost în numele unui terț, care deține licență comunitară și al cărui șofer, în cazul
în care este un resortisant din afara UE, este îndreptățit să efectueze operațiuni de cabotaj într-o țară UE (alta decât cea în care este înregistrat) în mod temporar în urma unei operațiuni internaționale în respectiva țară;</p><br>

<p style="text-indent:2em;">după livrarea mărfurilor care au făcut obiectul transportului internațional, operatorii de transport rutier de mărfuri au la dispoziție șapte zile în
care pot să efectueze până la trei operațiuni de cabotaj;</p><br>

<p style="text-indent:2em;">de asemenea, este posibil să se efectueze trei operațiuni de cabotaj în orice altă țară UE în urma unui transport internațional, în limita unei operațiuni pe țară.</p><br>

<p style="text-indent:2em;">Această lege se aplică doar dacă operatorul de transport rutier de mărfuri poate să prezinte dovada efectuării transportului internațional
de mărfuri în țara UE în cauză, precum și dovada efectuării fiecărei operațiuni ulterioare de cabotaj.</p>
 
<p style="text-indent:2em;"></p><h6>Operațiunile de cabotaj sunt supuse legislației naționale în țara UE gazdă în ceea ce privește:</h6><p></p>

<p>condițiile care guvernează contractul de transport;</p><br>

<p>greutățile și dimensiunile vehiculelor rutiere;</p><br>

<p style="text-indent:2em;">cerințele referitoare la transportul anumitor categorii de mărfuri, în special mărfuri periculoase, produsele alimentare perisabile și animalele vii;</p><br>

<p>duratele de conducere și perioadele de repaus;</p><br>

<p>taxa pe valoare adăugată (TVA) pe serviciile de transport.</p><br>

<p style="font-weight:500;">Legile și regulamentele de mai sus se aplică în mod egal operatorilor nerezidenți stabiliți în țara UE gazdă.</p>

<p style="text-indent:2em;"></p><h6>În cazul în care un operator de transport încalcă legislația UE privind transportul rutier:</h6><p></p>

<p style="text-indent:2em;">autoritățile competente din țara UE de înregistrare pot să emită un avertisment sau pot să impună sancțiuni administrative, precum retragerea licenței comunitare;</p><br>

<p style="text-indent:2em;">dacă operatorul de transport este nerezident, țara UE în care a avut loc încălcarea va notifica autoritățile competente din țara de înregistrare a transportatorului,
 descriind încălcarea și sancțiunile impuse.</p><br>

<p>Toate încălcările grave se vor înregistra în registrul electronic național al întreprinderilor de transport rutier.</p>

<p style="text-indent:2em;"></p><h6>Un raport al Comisiei din 2014 privind piața transporturilor rutiere în UE a constatat că:</h6><p></p>

<p>autoritățile din țările UE trebuie să pună în aplicare legislația UE existentă într-un mod mai consecvent și mai eficient;</p><br>

<p>UE poate să ajute clarificând normele care sunt înțelese, interpretate și implementate în mod diferit în diferite țări ale UE;</p><br>

<p style="text-indent:2em;">normele sociale trebuie aplicate mai bine în domeniul transporturilor rutiere pentru a atrage șoferi noi și pentru a face față cererilor viitoare pentru transportul de mărfuri;</p><br>

<p style="text-indent:2em;">aceste modificări pot crește eficiența economiei UE și pot să reducă emisiile de gaze cu efect de seră generate de transporturile rutiere.</p><br>

<p style="font-weight:500;">Pentru informații suplimentare, consultați site-ul Comisiei Europene referitor la transporturile rutiere.</p>

<p style="text-indent:2em;"></p><h6>TERMENI-CHEIE AI ACTULUI:</h6><p></p>

<p style="text-indent:2em;">Contra cost în numele unui terț: transportul contra cost al persoanelor sau mărfurilor în numele unei terțe părți (definiția OCDE).</p><br>

<p>Operațiune de transport rutier: întreaga călătorie a mărfurilor pe cale rutieră către destinația finală.</p><br>

<p>Transportul mărfurilor: activitatea de transportare a mărfurilor.</p><br>

<p style="font-weight:500;">Cabotaj: operatorul de transport înregistrat într-o țară UE efectuează transporturi naționale într-o altă țară UE.</p><br>

<p style="font-weight:500;">Ocuparea forței de muncă și condiții de lucru</p><br>

<p style="font-weight:500;">Transportul rutier: regulile UE privind perioadele de conducere auto, pauzele și perioadele de repaus</p><br>

<p style="text-indent:2em;">Organizarea timpului de lucru al șoferilor care desfășoară activități de transport rutier în Uniunea Europeană (UE) poate contribui la sporirea siguranței rutiere și a
 sănătății și siguranței șoferilor, asigurând totodată o concurență echitabilă.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Directiva 2002/15/CE a Parlamentului European și a Consiliului din 11 martie 2002 privind organizarea timpului de lucru al persoanelor care efectuează activități mobile
 de transport rutier</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Organizarea timpului de lucru al șoferilor care desfășoară activități de transport rutier în Uniunea Europeană (UE) poate contribui la sporirea siguranței rutiere și a
 sănătății și siguranței șoferilor, asigurând totodată o concurență echitabilă.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva stabilește reguli minime privind organizarea timpului de lucru al șoferilor, completând prevederile Regulamentului (CE) nr. 561/2006, care prevede reguli comune
 referitoare la timpul de conducere auto și la perioadele de odihnă ale conducătorilor auto.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Prevederile directivei se aplică tuturor „lucrătorilor mobili” care fac parte din personalul unei întreprinderi cu sediul într-o țară a UE, efectuând activități de transport rutier.
 De asemenea, directiva se aplică conducătorilor auto independenți.</p>

<p style="text-indent:2em;"></p><h6>Timpul de lucru cuprinde:</h6><p></p>

<p>conducerea autovehiculului;</p><br>

<p>încărcarea și descărcarea;</p><br>

<p>ajutorul acordat pasagerilor la urcarea și coborârea din vehicul;</p><br>

<p>curățenia și întreținerea tehnică;</p><br>

<p style="text-indent:2em;">toate celelalte activități vizând siguranța vehiculului, a pasagerilor sau a încărcăturii, precum și îndeplinirea anumitor obligații legale direct legate de o anumită operațiune de
 transport (cum sunt formalitățile administrative legate de vamă);</p><br>

<p>orice altă perioadă de timp în care șoferul este considerat a fi la postul său de lucru.</p><br>

<p style="text-indent:2em;">Durata maximă a săptămânii de lucru este stabilită la 48 de ore; aceasta poate fi prelungită însă la 60 de ore, cu condiția ca media de 48 de ore pe săptămână să nu fie depășită
 pe parcursul a patru luni.</p><br>

<p style="text-indent:2em;">Șoferilor le este interzis să lucreze mai mult de șase ore fără a face o pauză. Aceste pauze trebuie să fie de cel puțin 30 de minute dacă orele de lucru totalizează între șase și nouă ore.
 Această regulă completează prevederile Regulamentului (CE) 561/2006, care stabilește o durată maximă de condus fără pauză sau odihnă de 4,5 ore.</p><br>

<p style="text-indent:2em;">Directiva menține prevederile Regulamentului (CE) 561/2006 referitoare la odihnă. Șoferii trebuie să își ia perioade zilnice și săptămânale de odihnă.</p><br>

<p>Dacă se efectuează un schimb de noapte, timpul de lucru nu poate depăși 10 ore pentru fiecare perioadă de 24 de ore.</p><br>

<p style="text-indent:2em;">Un raport al Comisiei din 2014 a analizat punerea în aplicare a directivei și a regulamentului în perioada 2011-2012. S-au constatat astfel anumite îmbunătățiri în ceea ce privește
 aplicarea legislației. Cu toate acestea, caracterul incomplet și inconsecvent al informațiilor transmise de țările UE nu a permis o analiză aprofundată a efectelor asupra siguranței
 rutiere sau asupra sănătății și siguranței șoferilor. Comisia a precizat că va lansa o evaluare cuprinzătoare a funcționării legislației sociale în domeniul transportului rutier.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p style="text-indent:2em;">Parlamentul European a respins o propunere a Comisiei din 2008 de modificare a directivei în sensul excluderii conducătorilor auto independenți și al intensificării aplicării directivei.
 Inițial, directiva viza numai lucrătorii mobili care fac parte din personalul unei întreprinderi de transport. Din martie 2009, actul a vizat toți șoferii care intră sub incidența
 regulamentului privind duratele de conducere, pauzele și perioadele de repaos.</p><br>

<p style="font-weight:500;">Tahografele în transportul rutier</p>

<p style="text-indent:2em;"></p><h6>SINTEZĂ PRIVIND:</h6><p></p>

<p>Regulamentul (UE) nr. 165/2014 – norme privind tahografele utilizate în transportul rutier</p><br>

<p style="font-weight:500;">CARE ESTE OBIECTIVUL REGULAMENTULUI?</p><br>

<p style="text-indent:2em;">Regulamentul stabilește cerințele referitoare la construirea, instalarea, folosirea, testarea și controlul tahografelor* utilizate în transportul rutier în Uniunea Europeană (UE).</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Tahografele trebuie să fie instalate la bordul tuturor vehiculelor de peste 3,5 tone care sunt folosite pentru transportul rutier de mărfuri și la bordul vehiculelor care pot transporta
 peste nouă călători (inclusiv conducătorul auto), cu anumite excepții.</p>

<p style="text-indent:2em;"></p><h6>Tahografele digitale înregistrează următoarele date:</h6><p></p>

<p>distanța parcursă și viteza vehiculului;</p><br>

<p>măsurarea timpului;</p><br>

<p style="text-indent:2em;">punctul de începere, punctul de încheiere și poziția la fiecare trei ore (dacă sunt conectate la un sistem de navigație prin satelit);</p><br>

<p>identitatea conducătorului auto;</p><br>

<p>activitatea conducătorului auto;</p><br>

<p>date privind controlul, calibrarea și repararea tahografului;</p><br>

<p>evenimente* și anomalii*.</p><br>

<p>Tahografele analogice trebuie să înregistreze cel puțin distanța, viteza, timpul și activitatea conducătorului auto.</p><br>

<p>Accesul la datele stocate în tahograf poate fi acordat oricând autorităților de control și operatorului de transport în cauză.</p>

<p style="text-indent:2em;"></p><h6>Se afișează următoarele informații:</h6><p></p>

<p>ora;</p><br>

<p>modul de funcționare;</p><br>

<p>activitatea conducătorului auto;</p><br>

<p>timpul curent de conducere continuă și/sau durata curentă cumulată a pauzelor;</p><br>

<p>date privind avertizările;</p><br>

<p>date privind accesul la meniu.</p><br>

<p style="text-indent:2em;">Tahografele digitale avertizează conducătorii auto cu privire la depășirea timpului maxim permis de conducere continuă pentru a le facilita acestora respectarea legislației.</p><br>

<p style="text-indent:2em;">Prelucrarea datelor cu caracter personal trebuie să se efectueze exclusiv în scopul verificării respectării prezentului regulament și a altor regulamente relevante.</p><br>

<p style="font-weight:500;">Tahografele inteligente</p><br>

<p style="text-indent:2em;">Tahografele de la bordul vehiculelor înmatriculate pentru prima dată începând de la 15 iunie 2019 trebuie să fie conectate la un sistem de navigație prin satelit și să fie echipate cu
 tehnologie de comunicare la distanță pentru a facilita efectuarea de controale selective în trafic. Țările UE au la dispoziție 15 ani de la data de 15 iunie 2019 pentru a-și echipa
 autoritățile de control în vederea gestionării acestor comunicări de date. Datele pot fi stocate numai pe durata efectuării unui control în trafic și trebuie șterse în termen de trei
 ore, cu excepția cazului în care tahograful a fost folosit abuziv.</p><br>

<p style="font-weight:500;">Omologarea de tip</p><br>

<p style="text-indent:2em;">Tahografele sunt supuse omologării de tip, care este acordată de organisme desemnate de țările UE pe bază de funcționare, interoperabilitate, conformitate și securitate.</p><br>

<p style="font-weight:500;">Instalarea</p><br>

<p style="text-indent:2em;">Tahografele pot fi instalate numai de montatori sau de întreprinderi aprobate de autoritățile relevante ale fiecărei țări a UE, iar componentele și orice conectări vulnerabile trebuie să fie
 sigilate astfel cum se specifică în certificatul de omologare de tip. Tahograful trebuie inspectat cel puțin o dată la doi ani.</p><br>

<p style="font-weight:500;">Cardurile de conducător auto</p><br>

<p style="text-indent:2em;">Conducătorii auto trebuie să dețină un card de conducător auto emis de autoritatea relevantă din țara UE, cu durata de valabilitate de până la cinci ani, recunoscut reciproc de toate țările UE.</p><br>

<p style="text-indent:2em;">Operatorii de transport și conducătorii auto sunt responsabili de utilizarea corespunzătoare a tahografelor digitale și a cardurilor de conducător auto. Se interzice falsificarea, disimularea,
 ștergerea sau distrugerea înregistrărilor făcute pe foaia de înregistrare sau a datelor stocate în tahograf sau pe cardul de conducător auto, precum și a documentelor imprimate de la tahograf.</p><br>

<p style="text-indent:2em;">Operatorii de transport sunt responsabili de asigurarea formării corespunzătoare a conducătorilor lor auto în ceea ce privește funcționarea corectă a tahografelor și le este interzis să acorde
 conducătorilor lor auto stimulente care ar putea încuraja abuzurile în ceea ce privește tahografele.</p><br>

<p style="text-indent:2em;">În eventualitatea defectării unui tahograf, un operator de transport trebuie să îl prezinte pentru a fi reparat de către un montator aprobat, cât mai curând posibil, iar conducătorul auto 
trebuie să țină informații înregistrate manual cu privire la activități în acest timp.</p><br>

<p style="font-weight:500;">Conformitatea</p><br>

<p style="text-indent:2em;">Pentru asigurarea respectării regulamentului, țările UE desemnează agenți de control cărora li se pun la dispoziție instrumentele necesare și competențe legale corespunzătoare. De asemenea,
 țările UE trebuie să își acorde asistență reciprocă pentru asigurarea conformității și să stabilească regimul sancțiunilor efective, proporționale, disuasive și nediscriminatorii.</p><br>

<p>Se instituie un forum privind tahograful pentru dialog privind aspecte tehnice între experți ai țărilor UE.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Regulamentul se aplică începând cu 2 martie 2016.</p><br>

<p style="font-weight:500;">CONTEXT</p>

<p style="text-indent:2em;"></p><h6>Pentru informații suplimentare, consultați:</h6><p></p>

<p>„Tahograful” pe site-ul Comisiei Europene.</p><br>

<p>Sinteza privind „Durata de conducere în sectorul transporturilor rutiere”.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="font-weight:500;">Tahograf: echipament instalat la bordul vehiculelor rutiere pentru a afișa, a înregistra, a imprima, a stoca și a furniza detalii privind mișcarea acestor vehicule, inclusiv viteza unor astfel 
de vehicule, precum și anumite perioade de activitate ale conducătorilor lor auto.</p><br>

<p style="font-weight:500;">Eveniment: o operațiune anormală detectată de tahograful digital care poate decurge dintr-o tentativă de fraudă.</p><br>

<p style="font-weight:500;">Anomalie: o operațiune anormală detectată de tahograful digital care poate decurge dintr-un deranjament sau o defecțiune a aparatului.</p><br>

<p style="font-weight:500;">ACTUL PRINCIPAL</p><br>

<p style="text-indent:2em;">Regulamentul (UE) nr. 165/2014 al Parlamentului European și al Consiliului din 4 februarie 2014privind tahografele în transportul rutier, de abrogare a Regulamentului (CEE) nr. 3821/85 al Consiliului 
privind aparatura de înregistrare în transportul rutier și de modificare a Regulamentului (CE) nr. 561/2006 al Parlamentului European și al Consiliului privind armonizarea anumitor dispoziții ale 
legislației sociale în domeniul transporturilor rutiere (JO L 60, 28.2.2014, pp. 1-33)</p><br>

<p style="font-weight:500;">ACTE CONEXE</p><br>

<p style="text-indent:2em;">Regulamentul de punere în aplicare (UE) 2016/799 al Comisiei din 18 martie 2016 de punere în aplicare a Regulamentului (UE) nr. 165/2014 al Parlamentului European și al Consiliului de stabilire a 
cerințelor pentru construirea, testarea, instalarea, operarea și repararea tahografelor și a componentelor acestora (JO L 139, 26.5.2016, pp. 1-506)</p><br>

<p style="text-indent:2em;">Regulamentul de punere în aplicare (UE) 2016/68 al Comisiei din 21 ianuarie 2016 privind procedurile și specificațiile comune necesare pentru interconectarea registrelor
 electronice ale cardurilor de conducător auto (JO L 15, 22.1.2016, pp. 51-68)</p><br>

<p style="font-weight:500;">Întreprinderile de transport rutier de marfă și de persoane: norme de funcționare</p><br>

<p style="text-indent:2em;">Țările UE nu au aplicat normele privind accesul în sectorul operatorilor de transport rutier (adică furnizarea de servicii de transport de mărfuri sau de persoane) prevăzute
 de Directiva 96/26/CE într-un mod suficient de sistematic. Prin abrogarea Directivei 96/26/CE, Regulamentul 1071/2009 vizează eliminarea acestui neajuns.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Regulamentul (CE) nr. 1071/2009 al Parlamentului European și al Consiliului din 21 octombrie 2009 de stabilire a unor norme comune privind condițiile care trebuie îndeplinite
 pentru exercitarea ocupației de operator de transport rutier și de abrogare a Directivei 96/26/CE a Consiliului</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Țările UE nu au aplicat normele privind accesul în sectorul operatorilor de transport rutier (adică furnizarea de servicii de transport de mărfuri sau de persoane) prevăzute de
 Directiva 96/26/CE într-un mod suficient de sistematic. Prin abrogarea Directivei 96/26/CE, Regulamentul 1071/2009 vizează eliminarea acestui neajuns.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul stabilește norme pentru întreprinderile care doresc să pătrundă și să activeze în sectorul transportului rutier de marfă (adică transportul de marfă pe șosele) și al
 transportului de persoane.</p><br>

<p style="text-indent:2em;">Actul se aplică tuturor întreprinderilor stabilite pe teritoriul UE care activează (sau intenționează să activeze) în transportul de marfă sau de persoane în schimbul unei plăți.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Întreprinderile care activează în sectorul operatorilor de transport rutier trebuie:</h6><p></p>

<p>să aibă un sediu real și stabil pe teritoriul unei țări a UE;</p><br>

<p>să aibă o bună reputație;</p><br>

<p>să aibă capacitatea financiară adecvată; și</p><br>

<p>să aibă competența profesională necesară.</p><br>

<p style="font-weight:500;">Managerul de transport</p><br>

<p style="text-indent:2em;">Fiecare întreprindere de transport rutier trebuie să desemneze un manager de transport care este responsabil de conducerea permanentă a activităților de transport ale acesteia.
 Acest manager trebuie să fie rezident în UE și să aibă o legătură autentică cu întreprinderea, cum ar fi aceea de a fi angajat, administrator sau acționar al acesteia.</p><br>

<p style="font-weight:500;">Constituirea ca operator de transport rutier</p><br>

<p style="text-indent:2em;">O întreprindere care dorește să activeze în sectorul operatorilor de transport rutier trebuie să aibă un sediu într-o țară a UE, în incinta căruia își poate păstra toate
 documentele (de exemplu, documentele contabile, documentele de gestionare a personalului și documentele conținând date referitoare la perioadele de conducere și perioadele
 de repaus) necesare pentru activitatea sa. Mai mult, întreprinderea trebuie să dispună de cel puțin un vehicul înregistrat în țara UE respectivă (odată ce a fost acordată autorizația)
 și să aibă un centru de exploatare situat în țara respectivă, cu echipamentele și instalațiile tehnice corespunzătoare pentru funcționarea vehiculelor.</p>

<p style="text-indent:2em;"></p><h6>În plus:</h6><p></p>

<p style="text-indent:2em;">întreprinderea și managerul trebuie să nu fi fost condamnate pentru vreo încălcare a vreunor norme naționale și ale UE legate de transportul rutier;</p><br>

<p>întreprinderea trebuie să poată face față obligațiilor sale financiare;</p><br>

<p>managerul trebuie să fi trecut o examinare scrisă obligatorie, care poate fi completată cu o examinare orală.</p><br>

<p style="font-weight:500;">Autorizare și supraveghere</p>

<p style="text-indent:2em;"></p><h6>Țările UE trebuie să desemneze una sau mai multe autorități competente responsabile de:</h6><p></p>

<p>examinarea cererilor introduse de întreprinderi;</p><br>

<p>acordarea autorizațiilor de stabilire ca operator de transport rutier;</p><br>

<p>declararea unei persoane ca fiind aptă/inaptă pentru a conduce activitățile de transport ale unei întreprinderi;</p><br>

<p>verificarea îndeplinirii de către întreprindere a tuturor cerințelor relevante.</p><br>

<p style="text-indent:2em;">De asemenea, autoritățile competente sunt responsabile de urmărirea cererilor de înregistrare ale întreprinderilor în termen de trei luni. Totodată, autoritățile pot să declare
 o întreprindere ca inaptă pentru a conduce activități de transport.</p><br>

<p style="font-weight:500;">Simplificare și cooperare administrativă</p><br>

<p style="text-indent:2em;">Fiecare țară a UE trebuie să păstreze un registru electronic național al întreprinderilor autorizate să activeze în sectorul operatorilor de transport rutier. Autoritățile
 naționale competente sunt responsabile pentru supravegherea datelor din registrul respectiv. Registrele naționale trebuie să fie interconectate, permițând autorităților 
 competente ale oricărei țări a UE să consulte registrul electronic național al oricărei alte țări a UE.</p><br>

<p style="font-weight:500;">Raportul privind punerea în aplicare</p><br>

<p style="text-indent:2em;">În primul său raport privind punerea în aplicare, cuprinzând perioada până la sfârșitul lui 2012, Comisia a indicat că nu poate oferi o analiză completă a punerii în aplicare
 a Regulamentului ca urmare a datelor lipsă. Comisia sugerează că în viitor ar trebui stabilit un formular standard de raportare. De asemenea, aceasta încurajează țările UE să
 pregătească o schemă a sistemelor lor naționale de autorizare.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Începând cu 4 decembrie 2011.</p><br>

<p>Sunt disponibile informații suplimentare pe site-ul Direcției Generale Mobilitate și Transporturi a Comisiei Europene.</p><br>

<p style="font-weight:500;">Durata de conducere în sectorul transporturilor rutiere</p><br>

<p style="text-indent:2em;">Regulamentul (CE) nr. 561/2006 stabilește normele referitoare la perioadele de conducere, de repaus și la pauzele care trebuie respectate de către conducătorii auto de camioane
 și autobuze în vederea îmbunătățirii condițiilor de lucru și a siguranței rutiere.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Regulamentul (CE) nr. 561/2006 al Parlamentului European și al Consiliului din 15 martie 2006 privind armonizarea anumitor dispoziții ale legislației sociale în domeniul 
transporturilor rutiere, de modificare a Regulamentelor (CEE) nr. 3821/85 și (CE) nr. 2135/98 ale Consiliului și de abrogare a Regulamentului (CEE) nr. 3820/85 al Consiliului</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Regulamentul se aplică transportului rutier de mărfuri cu vehicule a căror masă maximă autorizată depășește 3,5 tone și transportului rutier de pasageri cu vehicule care sunt
 amenajate pentru a putea asigura transportul a mai mult de nouă persoane (inclusiv șoferul).</p><br>

<p style="text-indent:2em;">Acesta se aplică, indiferent de țara de înmatriculare a vehiculului, transporturilor rutiere efectuate în interiorul UE și între țările UE, Elveția și țările din Spațiul
 Economic European.</p><br>

<p style="font-weight:500;">Vârsta minimă</p><br>

<p style="text-indent:2em;">Conducătorul și cel de-al doilea conducător auto trebuie să aibă cel puțin 18 ani, cu excepția anumitor situații în care al doilea conducător auto încă urmează cursuri 
în acest sens, vârsta minimă fiind atunci de 16 ani (a se vedea articolul 5 pentru detalii suplimentare).</p><br>

<p style="font-weight:500;">Norme referitoare la perioadele de conducere, pauze și perioadele de repaus</p>

<p style="text-indent:2em;"></p><h6>În acest sens sunt prevăzute norme detaliate în articolele 6, 7, 8 și 9. Printre acestea se numără:</h6><p></p>

<p style="text-indent:2em;">o durată de conducere zilnică de maximum 9 ore, care se poate prelungi la 10 ore de cel mult două ori pe parcursul săptămânii;</p><br>

<p>o durată de conducere săptămânală de maximum 56 de ore;</p><br>

<p>o durată totală de conducere acumulată de maximum 90 de ore pe parcursul a două săptămâni consecutive;</p><br>

<p style="text-indent:2em;">după o perioadă de 4,5 ore de conducere, conducătorul auto trebuie să facă o pauză neîntreruptă de cel puțin 45 de minute, exceptând cazul în care își începe
 o perioadă de repaus;</p><br>

<p>o durată de repaus zilnic de minimum 11 ore, care poate fi redusă la 9 ore de cel mult 3 ori pe parcursul unei săptămâni;</p><br>

<p style="text-indent:2em;">o perioadă normală de repaus săptămânal de minimum 45 de ore și o perioadă redusă de repaus săptămânal de minimum 24 de ore.</p><br>

<p style="font-weight:500;">Tahograf</p><br>

<p style="text-indent:2em;">Regulamentul (CE) nr. 561/2006 este modificat printr-o lege nouă, Regulamentul (UE) nr. 165/2014. Această lege prevede cerințe privind construcția, instalarea și folosirea
 aparaturii de înregistrare (tahograf) cu care trebuie să fie echipate vehiculele vizate de acest regulament.</p>

<p style="text-indent:2em;"></p><h6>Tahograful este un dispozitiv care înregistrează timpul de conducere, perioadele de pauză și de repaus, precum și perioadele disponibile și alte perioade de lucru ale unui
 șofer. Scopul tahografului este:</h6><p></p>

<p>să verifice respectarea de către șoferi a normelor în vigoare;</p><br>

<p>să monitorizeze duratele de condus ale șoferilor profesioniști pentru a preveni oboseala;</p><br>

<p>să asigure o concurență corectă și siguranța rutieră.</p><br>

<p style="text-indent:2em;">Noua lege introduce tahograful inteligent. care, odată pornit, asigură înregistrarea automată folosind serviciul de poziționare prin satelit. Acest lucru ar trebui să sporească
 nivelul de conformitate și dă permită detectarea timpurie a posibilelor fraude sau abuzuri.</p><br>

<p style="text-indent:2em;">Este obligatorie instalarea unui tahograf digital în vehiculele noi cu masa de peste 3,5 tone (în cazul transportului de mărfuri) și care transportă mai mult de 9 persoane
 inclusiv șoferul (în cazul transportului de pasageri).</p><br>

<p style="font-weight:500;">Siguranța rutieră</p><br>

<p style="font-weight:500;">Transportul interior de mărfuri periculoase</p>

<p style="text-indent:2em;"></p><h6>SINTEZĂ PRIVIND:</h6><p></p>

<p>Directiva 2008/68/CE privind transportul interior de mărfuri periculoase</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva prevede norme comune pentru transportul în condiții de siguranță și securitate al mărfurilor periculoase pe teritoriul țărilor Uniunii Europene (UE) și între acestea,
 pe cale rutieră, feroviară sau pe căi navigabile interioare. De asemenea, directiva vizează aspecte precum încărcarea și descărcarea, transferul dinspre sau înspre alt mod de
 transport, precum și staționările pe parcursul procesului de transport. Actul legislativ extinde aplicarea normelor internaționale la transportul intern de mărfuri periculoase.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Excluderi</p>

<p style="text-indent:2em;"></p><h6>Directiva nu se aplică transportului de mărfuri periculoase realizat cu:</h6><p></p>

<p>vehicule, vagoane sau nave aparținând forțelor armate sau aflate sub răspunderea acestora;</p><br>

<p>nave maritime pe căi navigabile maritime care fac parte din căile navigabile interioare;</p><br>

<p>feriboturi care traversează numai căi navigabile interioare sau un port;</p><br>

<p>efectuat integral în perimetrul unei zone închise.</p><br>

<p style="font-weight:500;">Norme naționale</p>

<p style="text-indent:2em;"></p><h6>Din alte motive decât cele care țin de siguranța pe durata transportului (de exemplu, din motive de securitate națională sau de protecție a mediului), țările UE au dreptul
 de a reglementa sau interzice transportul de mărfuri periculoase pe teritoriul propriu. De asemenea, acestea pot stabili cerințe specifice de siguranță pentru transportul
 național și internațional de mărfuri periculoase pe teritoriul lor în ceea ce privește:</h6><p></p>

<p style="text-indent:2em;">transportul de mărfuri periculoase realizat cu vehicule, vagoane sau nave destinate căilor navigabile interioare, care nu face obiectul acestei directive;</p><br>

<p>în cazuri justificate, utilizarea unor rute prestabilite, inclusiv utilizarea unor moduri de transport prestabilite;</p><br>

<p>normele speciale pentru transportul de mărfuri periculoase în trenuri de călători.</p><br>
	
<p style="font-weight:500;">Acorduri internaționale</p><br>

<p style="text-indent:2em;">Transportul internațional de mărfuri periculoase este reglementat de Acordul european referitor la transportul rutier internațional al mărfurilor periculoase (ADR)*, de Acordul
 european privind transportul internațional al mărfurilor periculoase pe căi navigabile interioare (ADN)* și de Regulamentul privind transportul internațional feroviar al
 mărfurilor periculoase (RID)*. Aceste norme internaționale trebuie să fie extinse la transportul național pentru a armoniza în întreaga Comunitate condițiile în care sunt
 transportate mărfurile periculoase și pentru a asigura funcționarea adecvată a pieței comune a UE în domeniul transporturilor. În anexele la directivă se face referire la
 textele acestor acorduri.</p><br>

<p style="text-indent:2em;">ADR, RID și ADN au elaborat o listă cu mărfurile periculoase, indicând dacă transportul acestora este interzis sau nu și definind cerințele pentru transportul lor, în
 cazul în care acesta este autorizat. Țările UE pot solicita derogări temporare în anumite condiții.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p>Începând cu 20 octombrie 2008.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="text-indent:2em;">ADR: Acordul european referitor la transportul rutier internațional al mărfurilor periculoase, încheiat la Geneva la 30 septembrie 1957.</p><br>

<p style="text-indent:2em;">ADN: Acordul european privind transportul internațional al mărfurilor periculoase pe căi navigabile interioare, încheiat la Geneva la 26 mai 2000.</p><br>

<p style="text-indent:2em;">RID: Regulamentul privind transportul internațional feroviar al mărfurilor periculoase, care figurează în anexa C la Convenția privind transporturile internaționale
 feroviare (COTIF), încheiată la Vilnius la 3 iunie 1999.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p>Pagina referitoare la siguranța rutieră</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Directiva 2008/68/CE a Parlamentului European și a Consiliului din 24 septembrie 2008 privind transportul interior de mărfuri periculoase</p><br>

<p>Transportul rutier transfrontalier de numerar în euro</p>

<p style="text-indent:2em;"></p><h6>SINTEZĂ PRIVIND:</h6><p></p>

<p style="text-indent:2em;">Regulamentul (UE) nr. 1214/2011 privind transportul rutier transfrontalier profesionist de numerar în euro între statele membre din zona euro</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p>

<p style="text-indent:2em;"></p><h6>Obiectivele acestuia sunt duble:</h6><p></p>

<p style="text-indent:2em;">să asigure condiții de concurență mai echitabile pentru societățile de transport de numerar (CIT) care asigură transportul rutier de numerar în euro în zona euro;</p><br>

<p style="text-indent:2em;">să se asigure că transportul profesionist de numerar în euro între țările care aparțin zonei euro este competitiv, sigur și în condiții de siguranță atât pentru personalul
 în cauză, cât și pentru publicul larg.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Argumentare și domeniul de aplicare</p><br>

<p style="text-indent:2em;">Introducerea monedei euro a sporit necesitatea transportului rutier transfrontalier de numerar în țările din zona euro. Băncile, sectorul marii distribuții și alți 
profesioniști care manipulează numerar ar trebui să poată încheia contracte cu societățile CIT care oferă cel mai bun preț și/sau serviciu. De asemenea, aceștia ar 
trebui să aibă dreptul să beneficieze de serviciile de numerar ale celei mai apropiate sucursale a băncii centrale naționale sau ale centrului de numerar CIT, chiar 
dacă acestea sunt situate într-o altă țară din zona euro. Țările din zona euro („țările participante”) au, de asemenea, dreptul de a încheia acorduri pentru producerea
 bancnotelor și monedelor euro în străinătate. Comercianții cu amănuntul și băncile situate în zonele de frontieră ar putea dori să obțină bunurile din centrul de 
 numerar cel mai apropiat de acestea, ceea ce nu este neapărat în aceeași țară a UE. Simplul principiu al unei monede unice implică libertatea de a transfera numerar
 între țările participante din zona euro.</p><br>

<p style="text-indent:2em;">O armonizare completă a transportului CIT în țările participante ale UE nu a fost considerată fezabilă și nici un sistem nu a fost considerat adecvat în cazul în care
 autorizația într-o țară a UE ar fi valabilă în toate țările UE („recunoașterea reciprocă”). Prin urmare, Regulamentul (UE) nr. 1214/2011 stabilește un set de norme 
 comune valabile în toate țările UE, fără a aduce atingere normelor naționale privind anumite aspecte indicate explicit în regulament. Nu implică armonizare completă,
 deoarece normele comune se aplică numai transportului transfrontalier.</p><br>

<p style="font-weight:500;">Licența CIT de transport transfrontalier</p><br>

<p style="text-indent:2em;">Având în vedere amenințările potențiale asociate activității de transport de numerar, a securității personalului societăților CIT și a publicului larg, transportul 
transfrontalier de numerar în euro este condiționat de deținerea unei licențe CIT specifice transfrontaliere. Autoritățile naționale acordă această licență pe o perioadă
 de cinci ani dacă societățile CIT îndeplinesc anumite condiții, cum ar fi cerințele pentru personalul de securitate sau pentru vehicule.</p><br>

<p style="text-indent:2em;">Licențele CIT sunt înregistrate în Sistemul de informare al pieței interne a UE pentru a permite autorităților publice să acceseze aceste licențe cu ușurință.</p><br>

<p style="text-indent:2em;">Personalul CIT care efectuează operațiuni CIT transfrontaliere are dreptul la nivelurile minime de remunerație aplicabile în statele membre gazdă.</p><br>

<p style="font-weight:500;">Transportul transfrontalier de numerar în euro</p><br>

<p style="text-indent:2em;">Licența CIT de transport transfrontalier acordă titularului dreptul de a efectua transporturi rutiere transfrontaliere de bancnote și monede euro, pe timpul zilei și 
numai dacă majoritatea operațiunilor de colectare sau de livrare se desfășoară în statul membru gazdă și dacă valoarea numerarului în euro reprezintă cel puțin 80 % din
 valoarea totală a numerarului transportat în vehicul. Anumite modalități specifice de transport sunt exceptate în mod explicit de la domeniul de aplicare al regulamentului,
 cum ar fi transportul de la un punct la altul, la și de la bănci centrale naționale sau unități de producție de numerar.</p><br>

<p style="font-weight:500;">Modalități de transport</p>

<p style="text-indent:2em;"></p><h6>Regulamentul prevede cinci tipuri de modalități de transport pentru bancnotele euro și două pentru monedele euro. Acesta definește condițiile fiecăruia, cum ar fi:</h6><p></p>

<p>blindajul vehiculelor;</p><br>

<p>utilizarea sistemului inteligent de neutralizare a bancnotelor (IBNS);</p><br>

<p>prezența personalului de securitate CIT.</p><br>

<p style="font-weight:500;">Țările participante decid ce modalități de transport se aplică pe propriul teritoriu.</p><br>

<p style="font-weight:500;">Rolul IBNS și eliminarea bancnotelor neutralizate</p><br>

<p style="text-indent:2em;">Regulamentul urmărește să faciliteze utilizarea IBNS pentru a îmbunătăți securitatea personalului de securitate CIT și a publicului. Societățile CIT trebuie să scoată din 
circulație bancnotele neutralizate (bancnotele devenite inutilizabile pentru a le proteja împotriva accesului neautorizat), astfel încât acestea să nu mai fie utilizate 
pentru operațiuni de plată.</p><br>

<p style="text-indent:2em;">Regulile țării gazdă aplicabile transportului: forțele naționale de poliție, normele de securitate publică și portul de arme de foc</p>

<p style="text-indent:2em;"></p><h6>Aspectele CIT care nu intră sub incidența normelor comune ale regulamentului sunt reglementate de dreptul intern, sub rezerva normelor generale ale tratatului (cum ar fi 
principiul nediscriminării) și trebuie să fie respectate în țara gazdă de societatea CIT care efectuează transportul transfrontalier. Aceste norme naționale se referă la:</h6><p></p>

<p>rolul forțelor de poliție (cum ar fi notificarea prealabilă, însoțirea sau localizarea la distanță),</p><br>

<p>normele de securitate privind livrarea sau colectarea numerarului în locații și la</p><br>

<p>normele privind armele de foc.</p><br>

<p style="font-weight:500;">Sarcinile de notificare și de informare</p><br>

<p>Titularii de licențe trebuie să informeze țara participantă cu privire la începerea transportului transfrontalier.</p><br>

<p>Țările participante trebuie să se informeze reciproc cu privire la operațiunea transfrontalieră notificată de către societățile CIT.</p>

<p style="text-indent:2em;"></p><h6>Pe site-ul Europa, Comisia Europeană publică toate informațiile privind:</h6><p></p>

<p>sistemele IBNS omologate de către țările membre participante;</p><br>

<p style="text-indent:2em;">normele naționale privind rolul forțelor de poliție și securitatea locațiilor în care urmează să fie livrat sau colectat numerarul;</p><br>

<p>cerințele naționale de formare pentru personalul CIT;</p><br>

<p style="text-indent:2em;">acreditările autorității naționale emitente și ale administrației gazdă care trebuie informată despre începutul transportului transfrontalier.</p><br>

<p>Comisia publică în Jurnalul Oficial al Uniunii Europene măsurile de transport aplicabile alese de țările participante.</p><br>

<p style="font-weight:500;">Controale de conformitate, sancțiuni și măsuri de securitate pentru situații de urgență</p><br>

<p style="text-indent:2em;">Țările participante efectuează controale de conformitate a societăților CIT care acționează pe teritoriul lor. Se pot aplica sancțiuni în caz de neconformitate.</p><br>

<p style="text-indent:2em;">Autoritățile competente pot introduce măsuri de securitate temporare în cazul unei probleme urgente care afectează în mod semnificativ securitatea operațiunilor CIT.</p><br>

<p style="font-weight:500;">Revizuirea</p><br>

<p style="text-indent:2em;">La fiecare cinci ani, Comisia raportează Parlamentului European și Consiliului cu privire la punerea în aplicare a regulamentului.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Se aplică din 29 noiembrie 2012.</p><br>

<p style="font-weight:500;">CONTEXT</p>

<p style="text-indent:2em;"></p><h6>Pentru informații suplimentare, consultați:</h6><p></p>

<p>Reguli comune ale UE privind transportul rutier transfrontalier profesionist de numerar în euro (Comisia Europeană).</p><br>

<p style="font-weight:500;">DOCUMENTUL PRINCIPAL</p><br>

<p style="text-indent:2em;">Regulamentul (UE) nr. 1214/2011 al Parlamentului European și al Consiliului din 16 noiembrie 2011 privind transportul rutier transfrontalier profesionist de numerar în euro
 între statele membre din zona euro (JO L 316, 29.11.2011, pp. 1-20)</p><br>

<p style="font-weight:500;">DOCUMENTE CONEXE</p><br>

<p style="text-indent:2em;">Modalități de transport aplicabile în statele membre ale zonei euro [articolul 13 alineatul (5)], precum și în Andorra, Monaco, San Marino și Vatican [Regulamentul (UE) nr.
 1214/2011 privind transportul rutier transfrontalier de numerar în euro între statele membre din zona euro] (JO C 139, 4.5.2017, pp. 14-23)</p><br>

<p style="text-indent:2em;">Raport al Comisiei către Parlamentul European și Consiliu privind punerea în aplicare a Regulamentului (UE) nr. 1214/2011 al Parlamentului European și al Consiliului din 16 
noiembrie 2011 privind transportul rutier transfrontalier profesionist de numerar în euro între statele membre din zona euro, prezentat în conformitate cu articolul 26 din 
regulamentul menționat [COM(2017) 5 final, 11.1.2017]</p><br>

<p style="text-indent:2em;">Directiva 96/71/CE a Parlamentului European și a Consiliului din 16 decembrie 1996 privind detașarea lucrătorilor în cadrul prestării de servicii (JO L 18, 21.1.1997, pp. 1-6)</p><br>

<p style="font-weight:500;">Siguranța rutieră: orientări pentru politica de siguranță rutieră 2011-2020</p>

<p style="text-indent:2em;"></p><h6>SINTEZĂ PRIVIND:</h6><p></p>

<p>Comunicarea Comisiei Europene [COM(2010) 389 final] – Pentru un spațiu european de siguranță rutieră 2011-2020</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI COMUNICĂRI A COMISIEI EUROPENE?</p><br>

<p style="text-indent:2em;">Deși s-au înregistrat progrese cu privire la siguranța rutieră în Uniunea Europeană (UE) în cursul celui de Al treilea program european de acțiune pentru siguranță rutieră (RSAP)
 desfășurat între 2003 și 2010, Comisia Europeană subliniază că eforturile de îmbunătățire a siguranței rutiere trebuie să fie în continuare intensificate.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Orientările europene pentru politica de siguranță rutieră (2011-2020) au ca scop furnizarea unui cadru general și a unor obiective motivante care să ajute la elaborarea strategiilor
 naționale și locale, conform principiului subsidiarității. În cadrul general, Comisia subliniază necesitatea de:</h6><p></p>

<p>a crea un cadru de cooperare pe baza schimbului de bune practici în toată UE;</p><br>

<p style="text-indent:2em;">a adopta o strategie privind vătămările și acordarea primului ajutor pentru a răspunde nevoii de a reduce numărul accidentelor rutiere cu vătămări corporale;</p><br>

<p>a îmbunătăți siguranța participanților la trafic vulnerabili.</p><br>

<p style="font-weight:500;">Principii și obiectiv</p>

<p style="text-indent:2em;"></p><h6>Comisia stabilește trei principii esențiale:</h6><p></p>

<p style="text-indent:2em;">eforturile pentru atingerea celor mai ridicate standarde de siguranță rutieră în întreaga Europă – prin încurajarea cetățenilor UE să își asume responsabilitatea primară în ceea ce
 privește siguranța lor și a celor din jur pe drumurile UE și prin concentrarea asupra creșterii siguranței participanților la trafic mai vulnerabili;</p><br>

<p style="text-indent:2em;">abordare integrată a siguranței rutiere – prin cooperarea cu alte domenii de politică ale UE, precum energie, mediu, educație, justiție, inovare și tehnologie;</p><br>

<p style="text-indent:2em;">subsidiaritate, proporționalitate și responsabilitate comună – prin conceptul responsabilității comune, angajament și măsuri practice la toate nivelurile, începând cu țările UE și
 autoritățile acestora și până la organismele regionale și locale.</p><br>

<p style="text-indent:2em;">După RSAP 2003-2010, Comisia propune menținerea obiectivului privind reducerea la jumătate a numărului total de decese în accidente rutiere în UE între 2010 și 2020. Acest fapt 
demonstrează angajamentul clar al UE în ceea ce privește siguranța rutieră, iar existența unui obiectiv comun are scopul de a le oferi cetățenilor UE un grad mai uniform de siguranță
 rutieră pe teritoriul UE. Comisia încurajează fiecare țară a UE să contribuie la realizarea acestui obiectiv comun prin propriile strategii naționale în domeniul siguranței rutiere.</p><br>

<p style="text-indent:2em;">În cadrul consultării publice pentru aceste orientări privind politica, desfășurată în perioada iulie - decembrie 2009, s-a propus un obiectiv de reducere a vătămărilor grave
 provocate de accidente rutiere.</p><br> 

<p style="text-indent:2em;">Odată ce există o definiție comună a „vătămărilor grave”, Comisia trebuie să adauge un „obiectiv comun de reducere a vătămărilor” în aceste orientări europene pentru politica 
de siguranță rutieră pentru perioada de până în 2020.</p><br>

<p style="font-weight:500;">Obiective strategice</p>

<p style="text-indent:2em;"></p><h6>Comisia a identificat șapte obiective pentru care vor fi propuse măsuri la nivelul UE și la nivel național:</h6><p></p>

<p style="text-indent:2em;">îmbunătățirea gradului de educație și instruire a participanților la trafic – calitatea sistemului de acordare a permiselor de conducere și de instruire trebuie să fie îmbunătățită,
 cu accent pe tinerii șoferi începătoriComisia va conlucra cu țările UE la elaborarea unei strategii comune de educație și instruire privind siguranța rutieră, incluzând pregătirea
 înaintea examinării, examenul pentru permisul de conducere și instruirea după obținerea permisului de conducere;</p><br>

<p style="text-indent:2em;">întărirea controlului aplicării normelor rutiere – Comisia, Parlamentul European și Consiliul vor conlucra pentru a institui un schimb transfrontalier de informații în domeniul siguranței
 rutiere. De asemenea, Comisia va acționa în vederea elaborării unei strategii comune privind siguranța rutieră care va cuprinde campanii de control al aplicării normelor, obiective 
 naționale în domeniul respectării normelor rutiere și tehnologia auto în serviciul controlului respectării normelor, inclusiv posibilitatea de a instala limitatoare de viteză la bordul
 vehiculelor comerciale ușoare și etiloteste antidemaraj;</p><br>

<p style="text-indent:2em;">infrastructură rutieră mai sigură – Comisia se va asigura că fondurile UE vor fi acordate doar pentru infrastructura care respectă cerințele de siguranță ale UE. De asemenea, Comisia va
 promova aplicarea principiilor pertinente privind gestionarea în siguranță la rețelele de drumuri secundare ale țărilor UE, în special prin schimbul de bune practici;</p><br>

<p style="text-indent:2em;">vehicule mai sigure – pe lângă continuarea încurajării progreselor în domeniul siguranței vehiculelor, Comisia va analiza și va propune măsuri de armonizare și de consolidare progresivă
 a legislației UE privind inspecția tehnică auto și controalele rutiere tehnice;</p><br>

<p style="text-indent:2em;">promovarea tehnologiilor moderne pentru ameliorarea siguranței rutiere – Comisia va continua să promoveze utilizarea sistemelor de transport inteligente pentru ameliorarea siguranței în
 trafic. Eficiența și viteza de intervenție în situații de urgență vor crește prin adoptarea sistemului european de apeluri de urgență integrat la bordul vehiculelor, „eCall”;</p><br>

<p style="text-indent:2em;">creșterea calității serviciilor de urgență și post-traumatice – Comisia va propune stabilirea unei strategii globale de acțiune privind vătămările în accidente rutiere și primul ajutor.
 Comisia va examina în special mijloacele de îmbunătățire a eficienței intervenției de prim ajutor și a gestionării îngrijirilor medicale ulterioare în vederea reducerii impactului
 accidentelor rutiere;</p><br>

<p style="text-indent:2em;">protejarea participanților la trafic vulnerabili – Comisia va depune eforturi pentru îmbunătățirea siguranței motocicliștilor, abordând comportamentul, precum și siguranța vehiculelor
 și a infrastructurii. De asemenea, Comisia va încuraja realizarea unor infrastructuri adecvate pentru creșterea gradului de siguranță a bicicliștilor și a altor participanți la trafic
 vulnerabili.</p><br>

<p style="font-weight:500;">Implementarea orientărilor europene pentru politica de siguranță rutieră 2011-2020</p><br>

<p style="text-indent:2em;">Cooperarea deschisă între țările UE și Comisie facilitează implementarea politicii UE în domeniul siguranței rutiere. În paralel cu aceasta, țările UE au, de asemenea, planuri naționale de
 siguranță rutieră care conțin obiective naționale specifice, în funcție de situația particulară a fiecărei țări.</p><br>

<p style="text-indent:2em;">Comisia va urmări îmbunătățirea instrumentelor existente pentru colectarea și analiza datelor, precum CARE, baza de date a UE privind accidentele rutiere înființată în conformitate cu Decizia
 93/704/CE a Consiliului, și Observatorul european pentru siguranță rutieră (ERSO), care pune la dispoziția publicului pe internet cunoștințele și datele privind siguranța rutieră la nivel european.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p>Pentru informații suplimentare, consultați Siguranța rutieră de pe site-ul Comisiei Europene.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Comunicare a Comisiei către Parlamentul European, Consiliu, Comitetul Economic și Social European și Comitetul Regiunilor – Pentru un spațiu european de siguranță rutieră: orientări pentru
 politica de siguranță rutieră 2011-2020 [COM(2010) 389 final din 20 iulie 2010]</p><br>

<p style="font-weight:500;">Controalele tehnice în trafic privind siguranța vehiculelor comerciale</p><br>

<p style="text-indent:2em;">Obiectivul acestei directive este de a îmbunătăți siguranța rutieră și de a reduce impacturile negative ale vehiculelor comerciale asupra mediului. Directiva stabilește cerințe minime
 pentru controalele tehnice în trafic aplicate acestor vehicule pe teritoriul Uniunii Europene (UE).</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Directiva 2014/47/UE a Parlamentului European și a Consiliului din 3 aprilie 2014 privind controlul tehnic în trafic al vehiculelor comerciale care circulă în Uniune și de abrogare
 a Directivei 2000/30/CE.</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Obiectivul acestei directive este de a îmbunătăți siguranța rutieră și de a reduce impacturile negative ale vehiculelor comerciale asupra mediului. Directiva stabilește cerințe minime
 pentru controalele tehnice în trafic aplicate acestor vehicule pe teritoriul Uniunii Europene (UE).</p><br>

<p style="text-indent:2em;">Acest act legislativ a fost adoptat împreună cu normele revizuite privind documentele de înmatriculare pentru vehicule și cu o nouă directivă privind inspecția tehnică periodică
 a autovehiculelor.</p><br>

<p style="font-weight:500;">Domeniul de aplicare</p><br>

<p style="text-indent:2em;">Actul legislativ se referă la autobuze și autocare (categoriile M2 și M3), la camioane (N2 și N3) și la remorcile de peste 3,5 tone (O3 și O4), precum și la tractoarele utilizate în
 activități comerciale de transport rutier de marfă și capabile de a depăși viteza de 40 km/h (T5).</p><br>

<p style="text-indent:2em;">Țările europene pot efectua controale și asupra unor vehicule care nu sunt vizate de acest act legislativ, cum ar fi vehiculele comerciale ușoare, sau în alte locuri decât pe drumurile publice.</p><br>

<p style="font-weight:500;">Controalele</p><br>

<p style="text-indent:2em;">Acestea cuprind controalele inițiale și, dacă este necesar, controale mai detaliate vizând în special frânele, anvelopele, jantele și șasiul, precum și elementele poluante (zgomotul,
 gazele de evacuare etc.). Se așteaptă ca rata inspecțiilor să fie proporțională cu numărul de vehicule înmatriculate în fiecare țară din UE. Obiectivul este ca cel puțin 5 % dintre
 toate vehiculele comerciale înmatriculate în UE să fie supuse anual controalelor.</p><br>

<p style="font-weight:500;">Inspectorii</p><br>

<p style="text-indent:2em;">Inspectorilor li se interzice să facă orice discriminare pe considerente care țin de naționalitatea conducătorului auto sau de țara de înmatriculare atunci când selectează vehiculul
 pentru un control. Aceștia trebuie să fie liberi de orice conflict de interese care le-ar putea influența imparțialitatea, iar remunerarea lor nu trebuie să depindă de rezultatele
 controalelor.</p><br>

<p style="font-weight:500;">Responsabilitățile conducătorilor auto</p><br>

<p style="text-indent:2em;">Conducătorii auto au obligația de a păstra în vehicul cel mai recent certificat de inspecție tehnică, precum și cel mai recent raport de control tehnic în trafic, dacă sunt disponibile.
 De asemenea, conducătorii auto trebuie să coopereze cu inspectorii și să le ofere acestora acces la vehicul, la piesele acestuia și la documentație.</p><br>

<p style="font-weight:500;">Sistemul de clasificare în funcție de gradul de risc</p><br>

<p style="text-indent:2em;">Începând din 2019, țările UE trebuie să utilizeze sisteme de clasificare în funcție de gradul de risc, pentru a le permite inspectorilor să identifice întreprinderile de transport cu profil
 de risc ridicat, astfel încât acestea să poată fi verificate mai atent și mai frecvent.</p><br>

<p style="font-weight:500;">Arimarea încărcăturii</p><br>

<p style="text-indent:2em;">Actul legislativ include principii pentru controale și standarde aplicabile referitoare la arimarea încărcăturii, precum și la evaluarea defecțiunilor.</p><br>

<p style="font-weight:500;">Defecțiunile</p><br>

<p style="text-indent:2em;">Defecțiunile sunt clasificate ca minore, majore și periculoase. Orice deficiență majoră sau periculoasă identificată în cursul unui control trebuie rectificată înainte ca vehiculul să
 fie folosit pe drumurile publice.</p><br>

<p style="font-weight:500;">Schimbul de informații</p><br>

<p style="text-indent:2em;">Fiecare țară a UE va desemna un punct de contact pentru a asigura schimburile de informații și a oferi asistență punctelor de contact ale altor țări din UE. De asemenea, țările trebuie
 să participe periodic la activități de control tehnic în trafic concertate cu statele membre ale UE învecinate.</p><br>

<p style="font-weight:500;">CÂND SE APLICĂ DIRECTIVA?</p><br>

<p>Acest act legislativ se aplică începând cu data de 20.5.2018.</p><br>

<p>Încălcările normelor de circulație – partajarea informațiilor între țări</p>

<p style="text-indent:2em;"></p><h6>SINTEZĂ PRIVIND:</h6><p></p>

<p>Directiva (UE) 2015/413 – partajarea informațiilor privind încălcările normelor de circulație</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva stabilește norme pentru reducerea impunității șoferilor străini care comit încălcări periculoase ale normelor de circulație, facilitând partajarea de către autoritățile
 polițienești din diferite țări ale Uniunii Europene (UE) a informațiilor în vederea identificării contravenienților.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Directiva vizează următoarele încălcări:</h6><p></p>

<p>excesul de viteză;</p><br>

<p>nefolosirea centurii de siguranță;</p><br>

<p>nerespectarea semnificației culorii roșii a semaforului;</p><br>

<p>conducerea sub influența alcoolului;</p><br>

<p>conducerea sub influența drogurilor;</p><br>

<p>nefolosirea căștii de protecție;</p><br>

<p>utilizarea unei benzi interzise (de exemplu, a unei benzi pentru autobuze);</p><br>

<p>folosirea unui telefon mobil sau a altor dispozitive de comunicare în timpul conducerii.</p><br>

<p style="text-indent:2em;">Fiecare țară trebuie să acorde oricărei alte țări care investighează o încălcare a normelor rutiere comise pe teritoriul acesteia acces la datele privind înmatricularea
 vehiculului, astfel încât țara respectivă să poată identifica vehiculele și proprietarii sau utilizatorii acestora.</p>

<p style="text-indent:2em;"></p><h6>Dacă țara în care s-a produs încălcarea normelor decide să ia măsuri suplimentare, aceasta trebuie să anunțe posibilul contravenient și să îl informeze cu privire la
 consecințele juridice, printr-o scrisoare care să precizeze:</h6><p></p>

<p>natura încălcării;</p><br>

<p>locul, data și ora;</p><br>

<p>legea încălcată și sancțiunea;</p><br>

<p>(dacă este cazul) dispozitivul utilizat pentru constatarea încălcării.</p><br>

<p style="text-indent:2em;">Pentru a monitoriza funcționarea acestui sistem, fiecare țară trebuie să transmită Comisiei un raport până în luna mai 2016 și, ulterior, la fiecare doi ani, cu detalii
 privind numărul de căutări efectuate și numărul de scrisori de notificare expediate ulterior.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p>Directiva a intrat în vigoare la 17 martie 2015.</p><br>

<p style="text-indent:2em;">Aceasta a trebuit transpusă în legislația națională până la 6 mai 2015. Acest termen a fost prelungit până la 6 mai 2017 pentru Danemarca, Irlanda și Regatul Unit.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p style="text-indent:2em;">Directiva anterioară pe această temă, Directiva 2011/82/UE, a fost anulată de Curtea de Justiție a UE în 2014 pentru că nu avea baza juridică corectă.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Directiva (UE) 2015/413 a Parlamentului European și a Consiliului din 11 martie 2015 de facilitare a schimbului transfrontalier de informații privind încălcările
 normelor de circulație care afectează siguranța rutieră (JO L 68, 13.3.2015, pp. 9-25)</p><br>

<p>Siguranța rutieră: greutatea maximă și dimensiunile autorizate pentru camioane, autobuze și autocare</p>

<p style="text-indent:2em;"></p><h6>SINTEZĂ PRIVIND:</h6><p></p>

<p>Directiva nr. 96/53/CE – dimensiunile și greutatea vehiculelor comerciale</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva stabilește limite maxime pentru vehiculele grele de marfă, autobuzele și autocarele care efectuează transporturi internaționale pe teritoriul Uniunii Europene (UE).</p><br>

<p style="text-indent:2em;">Actul impune respectarea de către societățile naționale de transport a standardelor stabilite pentru transportul internațional.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>În traficul internațional și național, directiva a stabilit limite maxime privind dimensiunile:</h6><p></p>

<p>vehiculelor de marfă care au o greutate de peste 3,5 tone;</p><br>

<p>vehiculelor de călători care transportă peste nouă persoane.</p><br>

<p style="text-indent:2em;">În traficul internațional directiva stabilește, de asemenea, limite maxime privind greutatea și anumite alte caracteristici ale vehiculelor definite în anexele directivei.</p><br>

<p style="font-weight:500;">Ce se permite?</p><br>

<p style="text-indent:2em;">În transportul internațional, orice vehicule care depășesc limitele stabilite nu pot utiliza șoselele unei țări a UE decât dacă dețin permise speciale.</p>

<p style="text-indent:2em;"></p><h6>În transportul național, vehiculele care depășesc limitele stabilite cu privire la dimensiuni nu pot utiliza șoselele unei țări a UE. Cu toate acestea, 
există mai multe derogări de la aceste limite, inclusiv pentru:</h6><p></p>

<p>lungimea vehiculelor cu caracteristici aerodinamice concepute pentru creșterea eficienței energetice;</p><br>

<p>lungimea vehiculelor destinate transportului anumitor mărfuri, precum lemnul;</p><br>

<p>greutatea vehiculelor care transportă containere în cazul transportului combinat*.</p><br>

<p style="text-indent:2em;">Vehiculelor dintr-o țară a UE care se încadrează în limitele stabilite trebuie să li se permită să utilizeze drumurile altei țări a UE, cu posibila excepție a unor
 sectoare de drum limitate, precum satele mici sau locurile de interes deosebit, unde pot fi stabilite limite mai mici de către administrație. Țărilor UE li se permite
 să aibă limite mai ridicate în ceea ce privește greutatea pentru transportul național pe teritoriile lor, precum și să testeze noi tehnologii pe vehiculele care 
 depășesc limitele stabilite pentru o perioadă de timp limitată.</p><br>

<p style="font-weight:500;">Conformitatea</p>

<p style="text-indent:2em;"></p><h6>Țările UE trebuie să se asigure că vehiculele pot dovedi că respectă normele furnizând:</h6><p></p>

<p>fie plăcuța fabricantului împreună cu o plăcuță suplimentară care să indice dimensiunile;</p><br>

<p>fie o singură plăcuță care să le conțină pe ambele de mai sus;</p><br>

<p>fie un document emis de țara UE în care vehiculul a fost înregistrat și care să conțină aceleași informații.</p><br>

<p style="font-weight:500;">Aplicarea normelor</p>

<p style="text-indent:2em;"></p><h6>Guvernele naționale decid modul de aplicare a normelor și sancțiunile impuse pentru depășirea limitelor. O dată la doi ani trebuie să se trimită
 la Comisia Europeană un raport care să precizeze:</h6><p></p>

<p>numărul de verificări efectuate în ultimii doi ani calendaristici;</p><br>

<p>numărul de vehicule supraîncărcate sau de combinații de vehicule detectate.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p style="text-indent:2em;">Directiva a intrat în vigoare la 17 septembrie 1996. Țările UE au trebuit să o încorporeze în legislația națională până la 16 septembrie 1997.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p style="font-weight:500;">Greutate și dimensiuni admise pe drumuri</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="text-indent:2em;"> Transport combinat: transport de marfă care implică o combinație de transport rutier cu transport feroviar și/sau naval alternativ. Aceste moduri alternative
 trebuie să fie utilizate pentru cea mai mare parte a călătoriei, în timp ce utilizarea transportului rutier trebuie să fie limitată la o distanță scurtă, la
 începutul și/sau la sfârșitul călătoriei.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Directiva 96/53/CE a Consiliului din 25 iulie 1996 de stabilire, pentru anumite vehicule rutiere care circulă în interiorul Comunității, a dimensiunilor maxime
 autorizate în traficul național și internațional și a greutății maxime autorizate în traficul internațional (JO L 235, 17.9.1996, pp. 59-75)</p><br>

<p style="text-indent:2em;">Modificările succesive aduse Directivei 96/53/CE au fost integrate în textul de bază. Această versiune consolidată are doar un caracter informativ.</p><br>

<p style="font-weight:500;">Verificarea siguranței vehiculelor și a remorcilor</p><br>

<p style="text-indent:2em;">Obiectivul directivei este de a îmbunătăți siguranța rutieră prin stabilirea de cerințe minime privind inspecțiile tehnice ale vehiculelor și ale remorcilor pe
 teritoriul Uniunii Europene (UE).</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Directiva 2014/45/UE a Parlamentului European și a Consiliului din 3 aprilie 2014 privind inspecția tehnică periodică a autovehiculelor și a remorcilor acestora
 și de abrogare a Directivei 2009/40/CE.</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Obiectivul directivei este de a îmbunătăți siguranța rutieră prin stabilirea de cerințe minime privind inspecțiile tehnice ale vehiculelor și ale remorcilor
 pe teritoriul Uniunii Europene (UE).</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Domeniul de aplicare: actul legislativ se aplică vehiculelor care pot depăși viteza de 25 km/h și fac parte din categoriile de mai jos:</h6><p></p>

<p style="text-indent:2em;">autoturisme și vehicule utilitare ușoare (categoriile M1 și N1): acestea trebuie spuse inspecției la patru ani de la data primei înmatriculări și, ulterior,
 o dată la doi ani;</p><br>

<p style="text-indent:2em;">vehiculele din categoria M1 utilizate ca taxiuri sau ambulanțe, autobuze sau microbuze (M2, M3), vehiculele grele de marfă (N2, N3) și remorcile grele (O3, O4):
 acestea trebuie supuse inspecției la un an de la data primei înmatriculări și, ulterior, anual;</p><br>

<p style="text-indent:2em;">tractoarele rapide cu o viteză constructivă mai mare de 40 km/h (T5) și utilizate în scopuri comerciale: acestea trebuie supuse inspecției la patru ani de la data
 primei înmatriculări și, ulterior, o dată la doi ani.</p><br>

<p style="text-indent:2em;">Motocicletele de putere (vehiculele din categoria L cu motorul mai mare de 125 cm3) vor fi supuse inspecției începând din anul 2022, cu excepția cazului în care
 statisticile legate de siguranța rutieră din ultimii cinci ani arată că s-ar putea atinge același nivel de siguranță rutieră prin măsuri alternative.</p>

<p style="text-indent:2em;"></p><h6>Poate fi impusă efectuarea unei inspecții tehnice a vehiculelor înainte de datele-limită:</h6><p></p>

<p>după un accident;</p><br>

<p>în cazul schimbării deținătorului certificatului de înmatriculare al vehiculului;</p><br>

<p>atunci când vehiculul a ajuns la un kilometraj de 160 000 km;</p><br>

<p style="font-weight:500;">în cazurile în care siguranța rutieră este grav afectată.</p><br>

<p style="font-weight:500;">Scutiri: vehiculele de interes istoric, vehiculele diplomatice, vehiculele utilizate de forțele armate, poliție, autoritățile vamale, serviciile de pompieri sau vehiculele
 utilizate numai în scopuri agricole sau forestiere, precum și vehiculele utilizate exclusiv pe insule mici.</p><br>

<p style="text-indent:2em;">Centre de inspecție aprobate: fiecare țară din UE trebuie să aibă centre de inspecție aprobate și corespunzătoare, iar inspectorii trebuie să întrunească criteriile de
 competență și să nu se afle în niciun conflict de interese.</p><br>

<p style="text-indent:2em;">Evaluarea defecțiunilor: defecțiunile sunt clasificate ca minore, majore sau periculoase, cele minore nefiind suficiente pentru a respinge vehiculele. Dacă se constată
 defecte periculoase, poate fi suspendată utilizarea vehiculului pe drumurile publice până la remedierea deficiențelor.</p><br>

<p style="text-indent:2em;">Certificatul de inspecție tehnică: la reînmatricularea unui vehicul deja înmatriculat într-o altă țară din UE, certificatul acestuia trebuie recunoscut de alte țări ale
 UE, chiar și în cazul schimbării proprietarului. Până în 2021, centrele de inspecție au obligația de a comunica autorității competente din țara lor informațiile relevante.</p><br>

<p style="text-indent:2em;">Urmează a fi examinate fezabilitatea, costurile și beneficiile instituirii unei platforme electronice europene de informații privind vehiculele.</p><br>

<p style="font-weight:500;">Fraudarea: în scopul detectării fraudării odometrului, trebuie puse la dispoziția inspectorilor datele de la inspecția anterioară. Falsificarea kilometrajului unui
 vehicul constituie contravenție pasibilă de sancțiune.</p><br>

<p style="font-weight:500;">CÂND SE APLICĂ ACESTE PREVEDERI?</p><br>

<p>Directiva se aplică începând cu data de 20.5.2018.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p>Pentru informații suplimentare, consultați comunicatul de presă.</p><br>

<p style="font-weight:500;">Autovehicule și remorcile acestora: controlul rutier al vehiculelor utilitare (până în 2018)</p><br>

<p style="text-indent:2em;">Creșterea traficului în Uniunea Europeană (UE) ridică probleme similare privind siguranța și mediul înconjurător pentru toate țările UE. În vederea asigurării siguranței
 rutiere, a protecției mediului și a unor condiții echitabile de concurență, UE armonizează controlul tehnic rutier al autovehiculelor și al remorcilor acestora în cadrul
 UE. Aceste aspecte se aplică vehiculelor utilitare menite pentru transportul pasagerilor și al mărfurilor.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Directiva 2000/30/UE a Parlamentului European și a Consiliului din 6 iunie 2000 privind controlul tehnic rutier al vehiculelor utilitare care circulă în Comunitate</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Această directivă stabilește cadrul legal și completează regulamentele privind controalele anuale obligatorii în cadrul stațiilor de testare pentru vehiculele utilitare
 rutiere, în temeiul Directivei 2009/40/CE (care a abrogat directiva 96/96/CE în 2009 și a fost abrogată, la rândul ei, de Directiva 2014/45/UE, care se aplică începând
 cu 20 mai 2018).</p><br>

<p style="text-indent:2em;">Controalele rutiere sunt controale neanunțate asupra unui vehicul utilitar care circulă într-o țară a Uniunii Europene (UE). Acestea sunt efectuate de către autoritățile 
rutiere, portuare, sau orice alte autorități competente.</p>

<p style="text-indent:2em;"></p><h6>Această directivă prevede compunerea controlului tehnic rutier din unu, două sau toate aspectele următoare:</h6><p></p>

<p>controlul vizual al stării de mentenanță a autovehiculului care circulă pe rețeaua rutieră;</p><br>

<p style="text-indent:2em;">controlul documentelor care dovedesc efectuarea controlului tehnic rutier, iar în cazul în care conducătorul auto face dovada acestora, se va verifica și fișa de
 revizie tehnică rutieră;</p><br>
 
<p style="text-indent:2em;">control pentru depistarea nivelului precar de întreținere (anvelope uzate, sistem de frânare defect etc.). În acest caz, inspectorul va lua în considerare
 cele mai recente documente și orice alte certificate privind siguranța.</p><br>

<p style="text-indent:2em;">Directiva prevede introducerea unor controale rutiere de către țările UE, care se vor efectua periodic și în mod adecvat, fără discriminare în ceea ce privește naționalitatea
 conducătorului auto sau înmatricularea vehiculului. Acestea vor acoperi în fiecare an o selecție semnificativă și reprezentativă de vehicule utilitare din toate categoriile.</p><br>

<p style="text-indent:2em;">La fiecare doi ani, țările UE trebuie să transmită Comisiei informații privind numărul de vehicule utilitare verificate în ultimii doi ani.</p><br>

<p style="text-indent:2em;">Controalele rutiere stipulate în directivă trebuie efectuate în conformitate cu o listă de verificare (anexa II). Certificatul care atestă rezultatele controlului la fața
 locului se va înmâna conducătorului vehiculului. Conducătorul auto trebuie să fie capabil să îl prezinte la cerere pentru a simplifica sau evita controalele ulterioare.</p><br>

<p style="text-indent:2em;">Dacă nivelul precar de întreținere necesită o inspecție mai detaliată, vehiculul poate fi supus unui control mai amănunțit în cadrul unei stații de inspecție tehnică.</p>

<p style="text-indent:2em;"></p><h6>Utilizarea unui vehicul utilitar pe drumurile publice este imediat interzisă dacă:</h6><p></p>

<p style="text-indent:2em;">rezultatele unui control în trafic arată că vehiculul nu îndeplinește standardele stabilite în directivă (anexa II: Sisteme de frânare, emisii de gaze de eșapament), sau</p><br>

<p style="text-indent:2em;">dacă se constată că vehiculul nu respectă Directiva 2009/40/CE în cadrul unui control tehnic ulterior efectuat la un centru de testare aprobat și</p><br>

<p>dacă acesta constituie un risc major pentru pasageri sau alți participanți la trafic.</p><br>

<p style="text-indent:2em;">Defecțiunile grave ale unui vehicul aflat în proprietatea unui nerezident trebuie raportate autorităților din țara UE unde este înmatriculat vehiculul. Țara unde s-a identificat
 defecțiunea poate solicita ulterior luarea unor măsuri împotriva contravenientului. În cazurile în care se iau astfel de măsuri, țara de înmatriculare trebuie să notifice țara 
 unde s-a constatat defecțiunea cu privire la măsura luată împotriva contravenientului în cauză.</p><br>

<p style="text-indent:2em;">Comisia are competența de a defini anumite standarde minime și metode pentru testare și de a le alinia la progresul tehnic.</p><br>

<p style="font-weight:500;">Tunelurile rutiere: normele de siguranță din UE</p>

<p style="text-indent:2em;"></p><h6>SINTEZĂ PRIVIND:</h6><p></p>

<p>Directiva 2004/54/CE privind cerințele minime de siguranță pentru tunelurile din Rețeaua rutieră transeuropeană</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva prevede un set de standarde minime de siguranță cu privire la diversele aspecte organizaționale, structurale, tehnice și operaționale ale tunelurilor rutiere care
 fac parte din infrastructura transeuropeană de transport desemnată.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Cerințe de siguranță</p><br>

<p style="text-indent:2em;">Directiva urmărește să se asigure că toate tunelurile cu o lungime mai mare de 500 m, indiferent dacă sunt în exploatare, în construcție sau în stadiul de proiect, și care fac
 parte din rețeaua rutieră transeuropeană sunt conforme cu noile standarde armonizate de siguranță.</p><br>

<p style="font-weight:500;">Autoritatea administrativă</p><br>

<p style="text-indent:2em;">Fiecare țară UE trebuie să desemneze una sau mai multe autorități administrative, responsabile pentru toate aspectele de siguranță a tunelurilor. Autoritățile adoptă toate măsurile
 necesare pentru a garanta conformitatea cu această directivă.</p><br>

<p style="text-indent:2em;">Această autoritate poate fi înființată la nivel național, regional sau local. În cazul tunelurilor care traversează granița dintre țări, fiecare dintre țările respective poate 
desemna o autoritate administrativă sau cele două țări desemnează o autoritate administrativă comună.</p><br>

<p style="text-indent:2em;">În cazul în care se dă în exploatare un tunel nou sau urmează să se reconstruiască un tunel existent, este necesară autorizarea prealabilă din partea autorității. Autoritatea are
 competența de a suspenda sau restricționa funcționarea unui tunel în cazul în care nu sunt îndeplinite condițiile de siguranță.</p>

<p style="text-indent:2em;"></p><h6>Autoritatea administrativă se asigură că se efectuează următoarele sarcini:</h6><p></p>

<p>verificarea și controlul cu regularitate al tunelurilor și elaborarea cerințelor de siguranță conexe;</p><br>

<p style="text-indent:2em;">realizarea unor programe organizatorice și operaționale (inclusiv planuri de acțiune în situații de urgență) pentru instruirea și dotarea serviciilor de urgență;</p><br>

<p>definirea procedurii pentru închiderea imediată a unui tunel în situații de urgență;</p><br>

<p>implementarea măsurilor de reducere a riscurilor necesare.</p><br>

<p style="font-weight:500;">Administratorul tunelului</p><br>

<p style="text-indent:2em;">Pentru fiecare tunel situat pe teritoriul unei țări din UE, indiferent dacă acesta este în stadiul de proiectare, construcție sau de exploatare, autoritatea recunoaște, în calitate
 de administrator al tunelului, organismul public sau privat care răspunde de administrarea tunelului în etapa respectivă. Administratorul tunelului trebuie să întocmească un raport 
 în cazul oricărui incident sau accident semnificativ produs într-un tunel.</p><br>

<p style="font-weight:500;">Responsabilul cu siguranța</p>

<p style="text-indent:2em;"></p><h6>Pentru fiecare tunel, administratorul tunelului desemnează, cu aprobarea prealabilă a autorității administrative, un responsabil cu siguranța, acesta coordonând toate măsurile de prevenire
 și de protecție necesare pentru siguranța utilizatorilor și a personalului de exploatare. Printre sarcinile responsabilului cu siguranța se numără următoarele:</h6><p></p>

<p>coordonarea cu serviciile de urgență și ia parte la pregătirea programelor operaționale;</p><br>

<p>participarea la planificarea, punerea în aplicare și evaluarea operațiunilor de urgență;</p><br>

<p>participarea la întocmirea planurilor de siguranță și a specificațiilor privind instalațiile infrastructurii;</p><br>

<p style="text-indent:2em;">verificarea instruirii personalului de exploatare și a serviciilor de urgență și participarea la organizarea exercițiilor, care au loc la intervale de timp regulate;</p><br>

<p>avizarea dării în exploatare a structurii, a echipamentelor și a exploatării tunelurilor;</p><br>

<p>verificarea întreținerii și a reparării structurii și a echipamentului tunelului;</p><br>

<p>participarea la evaluarea oricărui incident sau accident semnificativ.</p><br>

<p style="font-weight:500;">Inspecțiile periodice</p><br>

<p>Statele membre ale UE se asigură că autoritățile de inspecție efectuează inspecțiile, evaluările și testele.</p><br>

<p style="text-indent:2em;">Autoritatea administrativă verifică regularitatea cu care se efectuează inspecțiile de către autoritatea de inspecție pentru a se asigura că toate tunelurile care intră în sfera de aplicare
 a acestei directive sunt conforme cu normele sale.</p><br>

<p>Perioada dintre două inspecții consecutive ale oricărui tunel dat nu trebuie să depășească șase ani.</p><br>

<p style="font-weight:500;">Analiza riscurilor</p><br>

<p style="text-indent:2em;">La cererea autorității administrative, un organism independent efectuează o analiză a riscurilor pentru un anumit tunel, pe baza unei metodologii unice definite la nivel național. Această
 analiză ia în considerare toți factorii de proiectare și toate condițiile de trafic care afectează siguranța, în special caracteristicile și tipul de trafic, lungimea și geometria tunelului, precum și numărul estimat de vehicule grele de marfă pe zi.</p><br>

<p style="font-weight:500;">Rapoarte</p><br>

<p style="text-indent:2em;">Din doi în doi ani, țările UE redactează rapoarte referitoare la incendiile produse în tuneluri și la accidentele care afectează în mod clar siguranța utilizatorilor de drumuri din tuneluri,
 precum și la frecvența și cauzele unor astfel de incidente și le evaluează, furnizând informații cu privire la rolul real și eficiența echipamentelor și a măsurilor de siguranță.</p><br>

<p style="font-weight:500;">Adaptarea la evoluția tehnologică</p><br>

<p>Comisia are competența de a adapta anexele la această directivă pentru a ține pasul cu evoluția tehnologică.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p style="text-indent:2em;">Directiva se aplică începând cu data de 30 aprilie 2004. Țările UE au avut obligația de a o transpune în legislația națională până la data de 30 aprilie 2006.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p style="text-indent:2em;">Tunelurile reprezintă infrastructuri importante esențiale pentru transportul pe distanțe mari și pentru dezvoltarea economiilor regionale ale UE. Cu toate acestea, accidentele din tuneluri, în
 special incendiile, pot avea consecințe dramatice și se pot dovedi extrem de costisitoare în ceea ce privește viețile umane, congestionarea sporită a traficului și costurile de poluare și de reparații.</p><br>

<p>Pentru informații suplimentare, consultați pagina „Tuneluri” pe site-ul Comisiei Europene.</p><br>

<p style="font-weight:500;">ACTUL PRINCIPAL</p><br>

<p style="text-indent:2em;">Directiva 2004/54/CE a Parlamentului European și a Consiliului din 29 aprilie 2004 privind cerințele minime de siguranță pentru tunelurile din rețeaua 
rutieră transeuropeană (JO L 167, 30.4.2004, pp. 39-91)</p><br>

<p style="text-indent:2em;">Modificările succesive aduse Directivei 2004/54/CE au fost integrate în textul de bază. Această versiune consolidată are doar un caracter informativ.</p><br>

<p style="font-weight:500;">Siguranța rutieră: permisele de conducere</p><br>

<p style="text-indent:2em;">Normele mai clare privind permisele de conducere în UE asigură o mai mare libertate de mișcare pentru șoferii din UE, consolidează siguranța rutieră și
 contribuie la reducerea fraudei.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Directiva 2006/126/CE a Parlamentului European și a Consiliului din 20 decembrie 2006 privind permisele de conducere (reformată).</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Normele mai clare privind permisele de conducere în UE asigură o mai mare libertate de mișcare pentru șoferii din UE, consolidează siguranța rutieră și
 contribuie la reducerea fraudei.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p>Directiva introduce un nou permis de conducere european. Aceasta reformează și abrogă Directiva 91/439/CEE.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Directiva:</h6><p></p>

<p style="text-indent:2em;">reduce riscul de fraudă: noul permis are un microcip, care încorporează informațiile tipărite pe card. Actul introduce o perioadă de valabilitate de 10-15 ani în cazul
 permisului de conducere pentru autoturism și pentru motocicletă, pentru a permite actualizarea regulată a elementelor de protecție și a informațiilor privind deținătorii.
 Directiva înființează o nouă rețea electronică pentru a facilita comunicarea dintre autoritățile naționale în scopul verificării permiselor.</p><br>

<p style="text-indent:2em;">contribuie la îmbunătățirea siguranței rutiere: actul introduce o nouă categorie de permise de conducere pentru mopeduri și impune o probă prealabilă obligatorie de verificare
 a cunoștințelor teoretice și un acces treptat la motocicletele mai grele. De asemenea, directiva precizează cerințele de testare. Conducătorii profesioniști trebuie să treacă
 printr-o examinare medicală o dată la cinci ani. Sunt stabilite cerințe privind calificarea și formarea examinatorilor.</p>

<p style="text-indent:2em;"></p><h6>Permisele emise de țările UE trebuie să fie recunoscute reciproc. Categoriile de permise de conducere sunt următoarele:</h6><p></p>

<p style="text-indent:2em;">categoria AM - pentru vehicule cu două sau și cu trei roți, având o viteză maximă proiectată care nu depășește 45 km/h, precum și pentru cvadriciclurile ușoare;</p><br>

<p style="text-indent:2em;">categoria A1 - pentru motociclete ușoare cu capacitate cilindrică maximă de 125 de centimetri cubi și cu puterea maximă de 11 kW;</p><br>

<p>categoria A2 - pentru motociclete cu puterea maximă de 35 kW;</p><br>

<p>categoria A - pentru motociclete grele, fără restricții în ceea ce privește puterea;</p><br>

<p style="text-indent:2em;">categoria B - pentru autovehicule de pasageri având o masă de până la 3 500 kg, destinate transportului a cel mult opt pasageri;</p><br>

<p>categoria BE - pentru autovehicule de categoria B care tractează o remorcă a cărei masă nu depășește 3 500 kg;</p><br>

<p>categoria B1 (opțională) - pentru cvadricicluri;</p><br>

<p style="text-indent:2em;">categoria C1 - pentru autovehicule de marfă având masa între 3 500 și 7 500 kg și construite pentru transportul a maximum opt pasageri;</p><br>

<p style="text-indent:2em;">categoria C1E - pentru vehicule din categoria C1 sau B care tractează o remorcă grea, masa combinată nedepășind 12 000 kg;</p><br>

<p style="text-indent:2em;">categoria C - pentru vehiculele de marfă a căror masă depășește 3 500 kg și care sunt construite pentru transportul a maximum opt pasageri;</p><br>

<p>categoria CE - pentru autovehicule din categoria C care tractează o remorcă grea;</p><br>

<p style="text-indent:2em;">categoria D1 - pentru autovehicule construite pentru transportul a maximum 16 pasageri și care au o lungime maximă ce nu depășește 8 m;</p><br>

<p>categoria D1E - pentru autovehicule din categoria D1 care tractează o remorcă grea;</p><br>

<p>categoria D - pentru autovehicule construite pentru transportul a peste opt pasageri;</p><br>

<p>categoria DE - pentru autovehicule din categoria D care tractează o remorcă grea;</p><br>

<p>remorcile ușoare având masa de până la 750 kg pot fi tractate cu autovehicule din categoriile B, C1, C, D1 și D.</p><br>

<p style="text-indent:2em;">Decizia 2014/209/UE conține un tabel al echivalențelor dintre aceste categorii și cele aferente permiselor emise în țările UE înainte de această directivă.</p><br>

<p style="text-indent:2em;">Permisele trebuie să precizeze condițiile în care conducătorul este autorizat să conducă autovehiculele. În cazul în care condusul este autorizat doar pentru anumite tipuri de vehicule
 sau pentru vehicule adaptate, acest fapt trebuie indicat pe permis printr-un cod.</p>

<p style="text-indent:2em;"></p><h6>Eliberarea permiselor face și ea obiectul unor condiții:</h6><p></p>

<p style="text-indent:2em;">permisul pentru categoriile C1, C, D1 și D se eliberează numai conducătorilor auto deja autorizați să conducă vehicule din categoria B;</p><br>

<p style="text-indent:2em;">permisul pentru categoriile BE, C1E, CE, D1E și DE se eliberează numai conducătorilor auto deja autorizați să conducă vehicule din categoriile B, C1, C, D1, respectiv D.</p>

<p style="text-indent:2em;"></p><h6>Vârsta minimă pentru eliberarea permiselor de conducere este:</h6><p></p>

<p>16 ani pentru categoriile AM, A1 (motociclete ușoare) și B1 (tricicluri și cvadricicluri cu motor);</p><br>

<p>18 ani pentru categoriile A2, B, BE și C1;</p><br>

<p>21 de ani pentru categoriile C, CE, D1 și D1E;</p><br>

<p>24 de ani pentru categoriile D și DE.</p><br>

<p style="font-weight:500;">Țările UE pot crește sau reduce vârsta minimă pentru anumite categorii, între anumite limite.</p>

<p style="text-indent:2em;"></p><h6>Țările UE trebuie să se asigure că solicitanții permiselor de conducere au cunoștințele, aptitudinile și comportamentul necesare pentru conducerea unui autovehicul. În general
, examinările în acest sens trebuie să cuprindă:</h6><p></p>

<p>o probă de verificare a cunoștințelor teoretice;</p><br>

<p>o probă de verificare a aptitudinilor și a comportamentului.</p><br>

<p style="text-indent:2em;">Examinatorii probelor de verificare a aptitudinilor și a comportamentului trebuie să posede anumite competențe minime. Aceștia funcționează într-un regim de asigurare a calității
 și trebuie să urmeze periodic cursuri de formare.</p><br>

<p style="font-weight:500;">CÂND SE APLICĂ DIRECTIVA?</p><br>

<p>Începând cu 19 ianuarie 2007.</p><br>

<p>Pentru informații suplimentare, consultați site-ul Comisiei Europene privind permisul de conducere din UE.</p><br>

<p>Siguranța rutieră: baza de date a UE privind accidentele rutiere</p>

<p style="text-indent:2em;"></p><h6>SINTEZĂ PRIVIND:</h6><p></p>

<p>Decizia 93/704/CE privind crearea unei baze de date a UE asupra accidentelor rutiere</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DECIZII?</p><br>

<p>Decizia creează CARE – baza de date a UE privind accidentele rutiere.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Țările UE trebuie:</h6><p></p>

<p style="text-indent:2em;">să înregistreze statistici privind accidentele rutiere produse pe teritoriul lor în care este implicat cel puțin un vehicul și care se soldează cu vătămare corporală sau pierdere
 de vieți omenești;</p><br>

<p>să transmită anual datele respective Comisiei Europene (Eurostat).</p><br>

<p style="text-indent:2em;">Comisia Europeană trebuie să asigure difuzarea datelor primite și buna funcționare a bazei de date și decide asupra condițiilor de acces la statistici și asupra oricăror publicații,
 în cooperare cu Grupul de lucru al experților CARE.</p><br>

<p style="text-indent:2em;">La trei ani de la punerea în aplicare a deciziei, Comisia a avut obligația de a întocmi un raport pentru Consiliu privind rezultatele obținute și indicatorii care decurg din acestea
 în ceea ce privește continuarea activităților.</p>

<p style="text-indent:2em;"></p><h6>Concluziile raportului au fost, în general, pozitive. S-au propus unele îmbunătățiri, precum:</h6><p></p>

<p>armonizarea datelor privind accidentele;</p><br>

<p>furnizarea de informații noi pentru finalizarea analizelor internaționale comparative;</p><br>

<p style="text-indent:2em;">îmbunătățirea cooperării dintre țările UE, deoarece aceasta joacă un rol esențial în gestionarea și calitatea datelor, precum și în elaborarea unei politici privind accesarea și
 folosirea bazei de date CARE de către utilizatori.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DECIZIA?</p><br>

<p>Se aplică de la 22 decembrie 1993.</p><br>

<p style="font-weight:500;">CONTEXT</p>

<p style="text-indent:2em;"></p><h6>Pentru informații suplimentare, consultați:</h6><p></p>

<p>Siguranța rutieră (Comisia Europeană).</p><br>

<p style="font-weight:500;">DOCUMENTUL PRINCIPAL</p><br>

<p style="text-indent:2em;">Decizia 93/704/CE a Consiliului din 30 noiembrie 1993 privind crearea unei baze de date comunitare asupra accidentelor rutiere (JO L 329, 30.12.1993, pp. 63-65)</p><br>

<p style="text-indent:2em;">Modificările succesive aduse Deciziei 93/704/CE au fost integrate în textul de bază. Această versiune consolidată are doar un caracter informativ.</p><br>

<p style="font-weight:500;">DOCUMENTE CONEXE</p><br>

<p style="text-indent:2em;">Raport al Comisiei privind progresele înregistrate în cadrul proiectului și perspectivele viitoare ale acestuia – CARE: baza de date comunitară asupra accidentelor rutiere – Decizia Consiliului din 30 
noiembrie 1993 (93/704/CE) [COM(97) 238 final, 26.5.1997]</p><br>

<p><a href="https://eur-lex.europa.eu/summary/chapter/transport/3202.html?root=3202">Sursa</a></p>



</div>
`