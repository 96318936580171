import _ from 'lodash'
import { createFetch, base, header, accept } from 'http-client'
import { defineMessages } from 'react-intl'
//import { showError } from '../../appNotification/AppNotificationRedux'

import configuration from '../../config'
import { getSystemAccessToken } from '../../globals/sideEffects/localStorage'

const config: any = configuration

class APIApplicationError extends Error {
  statusCode: number
  constructor(...args) {
    try {
      super(...args)
      Error.captureStackTrace(this, APIApplicationError)
    } catch (e) {
      console.error(this)
    }
  }
}

class ConnectionError extends Error {
  statusCode: number
  constructor(...args) {
    try {
      super(...args)
      Error.captureStackTrace(this, ConnectionError)
      this.statusCode = 0
    } catch (e) {
      console.error(this)
    }
  }
}


function addAuthHeaderMiddleware(fetch, input, options = {}) {
  const token = getSystemAccessToken()
  if (token) {
    _.set(options, 'headers.Authorization', `Bearer ${token}`)
  }
  return fetch(input, options)
}


// check if a property looks like an ID (key is equal to 'id' or ends with 'Id')
function isIdLike(key) {
  return key === 'id' || key.match(/.+Id$/)
}

// change data type of IDs to a string
// params in react router are always strings
// so this saves us from having to do conversions throught the app
function changeAllIdsToString(data) {
  if (_.isArray(data)) {
    return data.map(changeAllIdsToString)
  } else if (_.isObject(data)) {
    return _.mapValues(data, (value, key) =>
      isIdLike(key)
        ? value === null || value === undefined || _.isObject(value)
          ? value
          : String(value)
        : changeAllIdsToString(value)
    )
  }
  return data
}


function handleFetchResponseOrError(fetch, input, options = {}) {
  return fetch(input, options)
    .then(
      resp => {
        if (resp.ok) {
          // HTTP Status === 2xx
          return resp.json().then(changeAllIdsToString)
        }
        // HTTP Status !== 2xx
        return resp.json().then(data => {
          const error = new APIApplicationError(data.message)
          error.statusCode = data.statusCode /* HAPI always responds with statusCode */
          throw error
        })
      },
      () => Promise.reject(new ConnectionError('Connection Error'))
    )
    .catch(err => {
      let errorNotification = _.get(
        options,
        `errorNotifications.${err.statusCode}`,
        _.get(options, 'errorNotifications.default')
      )

      if (errorNotification) {
        if (errorNotification[err.message]) {
          errorNotification = errorNotification[err.message]
        } else if (errorNotification.default) {
          errorNotification = errorNotification.default
        }
        // const store = require('../../store').default
        if (err.statusCode === 0) {
          errorNotification = {
            ...defineMessages({
              message: {
                id: 'errorResponseMessages.disconnected.default.message',
                defaultMessage:
                  'Either your computer has been disconnected from the internet or our server has failed to respond.',
              },
            }),
            ...errorNotification,
          }
        }
        //store.dispatch(showError(errorNotification, err))
      }
      return Promise.reject(err)
    })
}

export const fetch: (url: string, options?: any) => Promise<any> = createFetch(
  base(config.apiUrl),
  accept('application/json'),
  header('Content-Type', 'application/json'),
  header('x-client-timezone-offset', -new Date().getTimezoneOffset()),
  addAuthHeaderMiddleware,
  handleFetchResponseOrError
)

export default fetch

// export const searchCargo = payload => {}
export const searchCargo = () => { return true }
