import { connect } from 'react-redux'
import { compose } from 'recompose'

import pick from 'lodash/pick'
import { reduxForm, formValueSelector } from 'redux-form'

import Component from './component'

import { CompanyListProps } from './types'

import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'
import { showTutorialJoinCompanyPopup } from "../../../store/actions/company"
import { loadItems, setParams, getItem, deleteItem } from '../../../store/actions/company'
import { IReduxStorage } from '../../../store/reducers/types'

const formName: string = 'list-companies'
const formSelector = formValueSelector(formName)

const initialValues = {
  searchText: '',
  pageSize: 10,
  sortBy: 'foundation',
}

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state: IReduxStorage, props: CompanyListProps) => {
      const filterKey = props.match.params['filterKey']
      const {
        auth: { profile },
        ui: { langId },
      } = state
      const listProps = pick(state.company.list, [
        'total',
        'pageIndex',
        'pageSize',
        'loading',
        'items',
        'searchText',
      ])

      const { isOpen, item } = state.company.editModal
      const activeItemId = isOpen && item.itemId
      const showTutorialPopup = state.company.showTutorialPopup

      return {
        filterKey,
        ...listProps,
        searchText: formSelector(state, 'searchText'),
        activeGuid: state.ui.activePopupGuid,
        country: formSelector(state, 'country'),
        businessType: formSelector(state, 'businessType'),
        sortBy: formSelector(state, 'sortBy'),
        activeItemId,
        showTutorialPopup,
        profile: profile.get('data').toJS(),
        langId,
      }
    },
    {
      loadItems,
      setParams,
      getItem,
      deleteItem,
      showTutorialJoinCompanyPopup
    }
  ),
  reduxForm({
    form: formName,
    initialValues,
    onChange: (values: any, dispatch, props: CompanyListProps, prevValues: any) => {
      const { loadItems, filterKey } = props

      if (values.searchText === prevValues.searchText) {
        loadItems({ filterKey, ...values })
      }
    },
  })
)(Component)
