import React from 'react'
import { match } from 'react-router'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import withPermissions from '../globals/components/withPermissions'
import { getAccessPoint, checkAuthorStatus } from '../store/actions'
import ProtectionWidget from '../components/shared/components/widget-protection'
import Spinner from '../components/UI/spinner'

export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated?: boolean
  withoutAuth?: boolean
  requireAuth?: boolean
  permissionScope?: 'admin' | 'cargo' | 'transport' | 'ad' | 'news' | 'ads'
  actionType?: 'add' | 'delete' | 'edit'
  permissions?: string[]
  checkAuthor?: boolean
  computedMatch?: match<{ itemId; adId }>
}

export interface ProtectedRouteState {
  permissionStatus: AccessPermissonStatus
}

export class ProtectedRoute extends React.Component<ProtectedRouteProps, ProtectedRouteState> {
  constructor(props) {
    super(props)
    this.state = {
      permissionStatus: 'loading',
    }
  }

  checkPermission() {
    const { isAuthenticated, permissionScope, actionType, checkAuthor, computedMatch } = this.props
    const { itemId, adId } = computedMatch.params
    if (isAuthenticated && permissionScope) {
      getAccessPoint(`${permissionScope}:${actionType}`)
        .then(permissionStatus => {
          if (checkAuthor && permissionStatus === 'denied') {
            return checkAuthorStatus(permissionScope, itemId || adId)
          }
          return permissionStatus
        })
        .then(permissionStatus => this.setState({ permissionStatus }))
        .catch(err => this.setState({ permissionStatus: 'denied' }))
    }
  }

  componentDidUpdate(prevProps) {
    const { isAuthenticated, permissionScope, actionType } = this.props
    const { permissionStatus } = this.state
    if (prevProps.permissionScope !== permissionScope || prevProps.actionType !== actionType) {
      this.setState({ permissionStatus: 'loading' })
    }
    if (permissionStatus === 'loading' && isAuthenticated) {
      this.checkPermission()
    }
  }

  componentDidMount() {
    this.checkPermission()
  }

  render() {
    const {
      isAuthenticated,
      withoutAuth,
      requireAuth,
      permissions,
      permissionScope,
      actionType,
      checkAuthor,
      ...rest
    } = this.props
    const { permissionStatus } = this.state
    if (isAuthenticated && withoutAuth) return <Redirect to="/" />
    if (!isAuthenticated && requireAuth) return <ProtectionWidget />
    if (!isAuthenticated && !requireAuth) return <Route {...rest} />
    if (permissionScope && permissionStatus === 'loading') return <Spinner />
    if (permissionScope && permissionStatus === 'denied') {
      return <ProtectionWidget notEnoughRights />
    }

    return <Route {...rest} />
  }
}

export default withPermissions(ProtectedRoute) as React.ComponentClass<ProtectedRouteProps>
