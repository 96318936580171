import { HTML_LOADING, HTML_LOADED, HTML_LOAD_ERROR } from '../actions/action-types'
import { TemplateState } from './types'

const initialState: TemplateState = {
  lang: undefined,
  html: '',
  requestedAt: undefined,
  loading: false,
  loaded: false,
  error: undefined,
}

// ---------------------------------------
// reducer
// ---------------------------------------
export default (state = initialState, { type, payload }: any = {}) => {
  switch (type) {
    case HTML_LOADING: {
      const { requestedAt, lang } = payload

      return {
        ...state,
        lang,
        requestedAt,
        loading: true,
        error: undefined,
      }
    }
    case HTML_LOADED: {
      const { html } = payload
      return {
        ...state,
        html,
        loading: false,
        loaded: true,
        error: undefined,
      }
    }
    case HTML_LOAD_ERROR: {
      const { error } = payload
      return {
        ...state,
        html: '&nbsp',
        error,
        loading: false,
      }
    }

    default:
      return state
  }
}
