import { connect } from 'react-redux'
import { compose } from 'recompose'
import withTranslation from '../../globals/components/withTranslation'
import withPermissions from '../../globals/components/withPermissions'
import { getGlobalUserRating, getGlobalCanUserAddReview } from '../../store/actions/profile'
import Component from './component'
import { ReviewListProps } from './types'
import { IReduxStorage } from '../../store/reducers/types'

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state: IReduxStorage) => {
      const {
        auth: { profile },
        ui: { langId },
      } = state
  
      return { 
        profile: profile.get('data').toJS(), langId,
        isAuth: !!state.auth.accessToken,
      }
    },
    {
      getGlobalUserRating,
      getGlobalCanUserAddReview
    }
  )
)(Component) as React.ComponentClass<ReviewListProps>