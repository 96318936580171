import React, { Component } from 'react'
import ForumCommentDate from '../forum-date'
import fetch from '../../../../components/shared/fetch'
import { COMMENT_LENGTH_FOR_CUT } from '../forum-constants'
import { Link } from 'react-router-dom'
import TooltipWrapper from '../../../UI/popover-wrapper'
import TooltipModal from '../../../search/table/tooltip-modal'
import './forum-last-comments.scss'

interface ForumLastCommentsProps extends ComponentBaseProps {
  singleComment: any
  commentsThemeTitle: string
  showComment: any
  showHideFullComment: boolean
  showDeleteCommentIcon: boolean
  refreshLastCommentsAfterDeleteComment: any
  closeModal: any
}

export default class ForumLastComments extends Component<ForumLastCommentsProps> {
  state = {
    showFullComment: 0,
    deleteCommentId: 0,
  }

  setShowFullComment = value => {
    this.setState({ showFullComment: value })
  }
  renderShowMore = singleComment => {
    const { t } = this.props
    const { showFullComment } = this.state
    const label = showFullComment == 0 ? t('forum.details') : t('forum.hide')
    const commentValue = showFullComment == 0 ? singleComment.id : 0
    return (
      <div className="show-more-details">
        <b onClick={() => this.setShowFullComment(commentValue)}>{label}</b>
      </div>
    )
  }

  onDeleteComment = commentId => {
    const { refreshLastCommentsAfterDeleteComment } = this.props
    fetch(`/forum/deleteComment/${commentId}`, {
      method: 'DELETE',
    }).then(() => {
      this.setState({ deleteCommentId: 0 })
      refreshLastCommentsAfterDeleteComment()
    })
  }

  renderCommentRemoveContainer = id => {
    const { t } = this.props
    return (
      <div className="remove-comment-container">
        <h1>{t('forum.delete.comment.title')}</h1>
        <div className="remove-button-container">
          <button
            type="button"
            className="button button-orange"
            onClick={() => {
              this.onDeleteComment(id)
            }}
          >
            {t('forum.delete')}
          </button>
          <button
            type="button"
            className="button button-orange button-blue"
            onClick={() => {
              this.setState({ deleteCommentId: 0 })
            }}
          >
            {t('forum.close')}
          </button>
        </div>
      </div>
    )
  }
  closeModal = (id: number, title: string, text: string, datePosted: string, username: string) => {
    const { showComment, closeModal } = this.props
    showComment(id, title, text, datePosted, username)
    closeModal(false, 0)
  }

  render() {
    const {
      singleComment,
      singleComment: {
        message,
        user: { userId, username },
        datePosted,
        sectionName,
        theme: { id, title },
        isFirstMessage,
      },
      t,
      showComment,
      showHideFullComment,
      showDeleteCommentIcon,
    } = this.props
    const { showFullComment, deleteCommentId } = this.state
    const readMore =
      message.length > COMMENT_LENGTH_FOR_CUT ? this.renderShowMore(singleComment) : ''
    const show = showFullComment == singleComment.id ? 'show-full-comment' : ''

    return (
      <div className="single-comment-box">
        {deleteCommentId === singleComment.id &&
          this.renderCommentRemoveContainer(singleComment.id)}
        <div className="panel-content">
          <div className="panel-content-row">
            {showDeleteCommentIcon && !isFirstMessage && (
              <i
                className="fa fa-remove"
                onClick={() => this.setState({ deleteCommentId: singleComment.id })}
              />
            )}
            <span className="meta">{ForumCommentDate(datePosted)}</span>
            <p className="widget-comment-title">
              <Link to={`user/details/${userId}`} className="name-link has-tooltip" target="_blank">
                <span className="icon">
                  <i className="fa fa-check-circle" />
                </span>
                <TooltipWrapper
                  text={<TooltipModal companyId={0} userId={userId} />}
                  hover
                  innerClassName="full-tooltip"
                  disabled={false}
                  parentCustomClass="move-up-container"
                >
                  {username}
                </TooltipWrapper>
              </Link>
              <span>
                {''} {t('forum.author.add.comment')}:
              </span>
            </p>
            <div className="font-bold">
              <div className="last-comments-theme-wrapper">
                <span className="last-comments-theme-title">{t('forum.theme')}:</span>
                <span
                  className="theme-title theme-title-all-comments col-md-14"
                  onClick={() => this.closeModal(id, title, sectionName, datePosted, username)}
                >
                  {' ' + title}
                </span>
              </div>
            </div>
            <div className="clear" />
            <p className={`text ${show}`}>{message}</p>
            {readMore}
          </div>
        </div>
      </div>
    )
  }
}
