import React, { Component } from 'react'
// import { connect } from "react-redux"
// import { compose, withHandlers } from "recompose"
import { Link } from 'react-router-dom'

import { Field } from 'redux-form'
// import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap'
import classNames from 'classnames'

import Checkbox from '../../UI/checkbox'
import WidgetBase from '../../shared/components/widget-base'
// import baseMessages from '../../shared/utils/baseMessages'
import { focusFirstInput } from '../../shared/utility'

// import { ModalConsumer } from '../../../context/modal-context'
// import HtmlLoadable from '../../shared/components/html-loadable'
// // import {
// //   FORM_FIELD_ERROR_POSITION_TOP,
// //   // FORM_FIELD_ERROR_POSITION_BOTTOM
// // } from '../../../store/constants'

import {
  required,
  loginValidation,
  minLoginLength,
  maxLoginLength,
  minPasswordLength,
  maxPasswordLength,
  passwordValidation,
  matchToField,
  email,
} from '../../shared/form-validators'
import FieldBase from '../../UI/field-base'
import './registration.scss'

const STYLE_BASE = 'auth-registration_'

export default class extends Component {
  // constructor(props) {
  //     super(props)

  //     props.initialValues.langId = props.langId
  // }
  // state = {
  //   activeTab: USER_REGISTRATION_TABS[0].prop
  // }

  constructor(props) {
    super(props)

    this.ref = React.createRef()
    this.matchPassword = this.validatePasswords()
  }

  componentDidMount() {
    focusFirstInput(this.ref)
  }
  keyDown = event => {
    if (event.keyCode === 13) {
      this.props.valid && this.props.handleSubmit()
    }
  }

  validatePasswords = () => {
    const { t } = this.props
    return matchToField('password', t('login.password.mismatch'))
  }

  // toggle(tab) {
  //   this.state.activeTab !== tab && this.setState({ activeTab: tab })
  // }

  render() {
    const {
      t,
      currentLanguage: lang,
      // intl,
      // handleSubmit,
      pristine,
      // reset,
      submitting,
      invalid,
      // onLoginMode,
      handleSubmit,
      // register,
    } = this.props

    return (
      <WidgetBase title={t('registration.user')}>
        <div
          ref={this.ref}
          className={classNames('panel-body', `${STYLE_BASE}wrapper`)}
          onKeyDown={this.keyDown}
        >
          <div className="panel-content">
            <div className="clearfix row-right">
              <div className="col-md-8">
                <div className="panel-block">

                  <div
                    dangerouslySetInnerHTML={{
                      __html: t('why.for.me.registration')
                    }}
                  />
                </div>
              </div>
              <div className="col-md-8">
                <div className="panel-block">
                  <form className="panel-form">
                    <div className="panel-form-row">
                      <div className="panel-block-inline">
                        <div>
                          <Field
                            name="username"
                            type="text"
                            component={FieldBase}
                            label={t('login.username')}
                            placeholder={t('login.username.placeholder')}
                            validate={[required, loginValidation, minLoginLength, maxLoginLength]}
                          />
                        </div>
                        <div>
                          <p className="meta">
                            <div dangerouslySetInnerHTML={{
                              __html: t('login.rule.message.info')
                              }} 
                            >
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="panel-form-row">
                      <div className="panel-block-inline">
                        <div>
                          <Field
                            name="password"
                            type="password"
                            component={FieldBase}
                            label={t('login.password')}
                            placeholder={t('login.password.placeholder')}
                            validate={[
                              required,
                              passwordValidation,
                              minPasswordLength,
                              maxPasswordLength,
                            ]}
                          />
                        </div>
                        <div>
                          <p className="meta" 
                            dangerouslySetInnerHTML={{
                              __html: t('password.rule.message.info')
                            }}>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="panel-form-row">
                      <div className="panel-block-inline">
                        <div>
                          <Field
                            name="password2"
                            type="password"
                            component={FieldBase}
                            label={t('login.password.repeat')}
                            placeholder={t('login.password.repeat.placeholder')}
                            validate={[required, this.matchPassword]}
                          />
                        </div>
                        <div />
                      </div>
                    </div>
                    <div className="panel-form-row">
                      <div className="panel-block-inline">
                        <div>
                          <Field
                            name="email"
                            type="email"
                            component={FieldBase}
                            label="E-mail"
                            placeholder={t('your.email')}
                            validate={[required, email]}
                          />
                        </div>
                        <div>
                          <p className="meta">{t('email.rule.message.info')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="panel-form-row">
                      <div className="checkbox-container">
                        <Field name="confirm" component={Checkbox} validate={[required]}>
                          <Link to={`/about-us/terms-of-use`} target="_blank">
                            {t('registration.checkbox.rules')}
                          </Link>
                          {/* <ModalConsumer>
                                        {({ showModalComponent, clearModal }) => (
                                          <Link to="#" onClick={() => {
                                            showModalComponent(<HtmlLoadable path="registration/rules" />, 
                                            { 
                                              className: `${STYLE_BASE}modal`,
                                              title: 'Правила пользования сайтом'
                                            })
                                          }}>
                                            Правила поведения на сайте
                                          </Link>
                                        )}
                                      </ModalConsumer> */}
                          {t('read.and.approve')}
                        </Field>
                        {/* <input type="checkbox" id="a2"/>
                                      <label for="a2"><a href="#">Правила поведения и Условия использования сайта&nbsp</a>прочёл и согласен</label> */}
                      </div>
                      <div className="checkbox-container">
                        <Field name="confirm2" component={Checkbox} validate={[required]}>
                          <Link
                            to={`/about-us/policy`}
                            target="_blank"
                          >
                            {t('registration.checkbox.conditions')}
                          </Link>
                          {/* <ModalConsumer>
                                        {({ showModalComponent, clearModal }) => (
                                          <Link to="#" target='' onClick={() => {
                                            showModalComponent(<HtmlLoadable path="registration/howtouse" />, 
                                            { 
                                              className: `${STYLE_BASE}modal`,
                                              title: 'Условия использования'
                                            })
                                          }}>
                                            Условия использования сайта
                                          </Link>
                                        )}
                                      </ModalConsumer> */}
                          {t('read.and.approve')}
                        </Field>
                        {/* <input type="checkbox" id="a2"/>
                                      <label for="a2"><a href="#">Правила поведения и Условия использования сайта&nbsp</a>прочёл и согласен</label> */}
                      </div>
                    </div>
                    <div className="panel-form-row">
                      <div className="panel-block-inline command-buttons">
                        {/* <button className="button button-orange">Зарегистрироваться</button> */}
                        <button
                          className="button button-orange"
                          disabled={pristine || submitting || invalid}
                          type="button"
                          onClick={handleSubmit}
                        >
                          {t('login.register.register')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </WidgetBase>
    )
  }
}

//   </div>
// </div>

// <div className="form form-simple" onKeyDown={this.keyDown}>

//   { x.title && <h3>{x.title}</h3> }
//   { x.subtitle && <h5>{x.subtitle}</h5> }

//   {
//     this.drawLogin()
//   }

//   <div className="form-row form-row-buttons">
//     <div>
//         Уже зарегистрированы?
//         <br />
//         <Link to="#" onClick={onLoginMode}> Войдите в систему  </Link>
//     </div>
//     <button
//         className="button button-orange button-centered"
//         disabled={pristine || submitting || invalid}
//         type="submit"
//         // onClick={handleSubmit(register)}
//     >
//         Зарегистрироваться
//     </button>
//   </div>
// </div>
