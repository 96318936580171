import React, { Component } from 'react'
import { THEMES_ON_PAGE, SHOW_COUNT_PAGES_IN_PAGINATION } from '../forum-constants'
import fetch from '../../../../components/shared/fetch'
import ForumCommentDate from '../forum-date'
import Pagination from '../../../UI/pagination'

import './forum-theme-item.scss'

interface ForumThemeProps extends ComponentBaseProps {
  sectionName: string
  themeTitle: string
  key: number
  sectionId: number
  openSubthemes: any
  openSection: number
  showComment: any
  id: number
  sections: any
  refreshLastComments: boolean
  canDeteleForumTheme: boolean
  allLastThemesSectionId: number
  loggedUserId: number
  type: string
  closeModal: any
  sectionByDefault: number
}

export default class ForumThemeItem extends Component<ForumThemeProps> {
  state = {
    showSubthemes: 0,
    themes: [],
    sectionId: 0,
    totalThemes: 0,
    currentSection: 0,
    currentPage: 0,
    pagination: [],
    showPagesInStart: 0,
    showConfirmDeleteTheme: 0,
  }

  componentDidUpdate(prevProps) {
    const { id, openSection, refreshLastComments, loggedUserId, type } = this.props
    const { currentPage } = this.state

    if (id === openSection) {
      if (openSection !== prevProps.openSection) {
        if (type === 'allThemes') {
          this.fetchThemeForumSections(0, 0)
        } else if (type === 'myThemes') {
          this.fetchThemeForumSections(0, 0, THEMES_ON_PAGE, loggedUserId)
        } else {
          this.fetchThemeForumSections(id, 0)
        }
        this.setState({ currentPage: 0 })
      }

      //refresh current open section
      if (refreshLastComments && type === 'myThemes') {
        this.fetchThemeForumSections(id, currentPage, THEMES_ON_PAGE, loggedUserId)
      } else if (refreshLastComments && type === 'allThemes') {
        this.fetchThemeForumSections(0, 0)
      } else if (refreshLastComments && !type) {
        this.fetchThemeForumSections(id, currentPage)
      }
    }
  }

  componentDidMount() {
    const { id, allLastThemesSectionId, openSection } = this.props
    const lastIdValue = allLastThemesSectionId === id ? 0 : id
    this.setState({ sectionId: lastIdValue, showPagesInStart: SHOW_COUNT_PAGES_IN_PAGINATION })
    if (id === openSection) {
      this.fetchThemeForumSections(id, 0)
    }
  }

  fetchThemeForumSections = async (
    sectionId: number,
    pageIndex: number,
    themesOnPage: number = THEMES_ON_PAGE,
    loggedUserId: number = 0
  ) => {
    const customSectionId = sectionId < 0 ? 0 : sectionId
    fetch(
      `/forum/getAllThemes/${customSectionId}/${themesOnPage}/${pageIndex}/${loggedUserId}`
    ).then(themes => {
      this.setState({
        themes: themes.data.themes,
        totalThemes: themes.data.count,
      })
    })
  }

  showSubthemes = id => {
    this.setState({ showSubthemes: id })
  }

  deleteForumTheme = (commentId: number, sectionByDefault: number) => {
    fetch(`/forum/deleteForumTheme/${commentId}`, {
      method: 'DELETE',
    }).then(() => {
      const { id, closeModal } = this.props
      const { currentPage } = this.state
      closeModal(true, sectionByDefault)
      this.fetchThemeForumSections(id, currentPage)
    })
  }

  onConfirmDeleteTheme = id => {
    const { t, sectionByDefault } = this.props
    return (
      <div className="confirm-delete-theme">
        <button
          type="button"
          className="button button-orange"
          onClick={() => {
            this.deleteForumTheme(id, sectionByDefault)
          }}
        >
          {t('forum.delete')}
        </button>
        <button
          type="button"
          className="button button-orange button-blue"
          onClick={() => {
            this.setState({ showConfirmDeleteTheme: 0 })
          }}
        >
          {t('forum.close')}
        </button>
      </div>
    )
  }

  renderThemesInSection = themes => {
    const { showComment, canDeteleForumTheme } = this.props
    const { showConfirmDeleteTheme } = this.state
    const renderSubThemes = themes.map(theme => {
      const {
        title,
        messageCount,
        dateAdded,
        id,
        section,
        user: { username },
        company,
      } = theme

      const companyName = company ? company.companyName : ''
      return (
        <div
          className="panel-content-row theme-row"
          key={id}
          onClick={() => showComment(id, title, section.title, dateAdded, username, companyName)}
        >
          {showConfirmDeleteTheme === id && this.onConfirmDeleteTheme(id)}
          <div className="icon col-bg ico"></div>
          <div className="text ellipsis col-md-11"> {title} </div>
          <span className="number col-md-5">
            {canDeteleForumTheme && (
              <i
                className="fa fa-remove"
                onClick={() => {
                  this.setState({ showConfirmDeleteTheme: id })
                }}
              />
            )}
            ({messageCount}) {ForumCommentDate(dateAdded)}
          </span>
        </div>
      )
    })
    return renderSubThemes
  }

  paginationClick = page => {
    const { id, allLastThemesSectionId, type, loggedUserId } = this.props
    this.setState({ currentPage: page })
    const lastIdValue = allLastThemesSectionId === id ? 0 : id
    if (type === 'allThemes') {
      this.fetchThemeForumSections(lastIdValue, page)
    } else if (type === 'myThemes') {
      this.fetchThemeForumSections(lastIdValue, page, THEMES_ON_PAGE, loggedUserId)
    } else {
      this.fetchThemeForumSections(lastIdValue, page)
    }
  }

  renderPaginationWrapper = () => {
    const { totalThemes } = this.state
    return (
      <div className="panel-content-row forum-theme-pagination">
        {this.renderPagination(totalThemes)}
      </div>
    )
  }

  renderPagination = totalThemes => {
    const { currentPage } = this.state
    return (
      <Pagination
        onClick={this.paginationClick}
        total={totalThemes}
        pageIndex={currentPage}
        pageSize={THEMES_ON_PAGE}
      />
    )
  }

  openSection = sectionId => {
    const { openSubthemes } = this.props
    openSubthemes(sectionId)
    this.setState({ currentSection: sectionId })
  }

  render() {
    const { sectionName, themeTitle, sectionId, openSubthemes, openSection } = this.props
    const { themes, totalThemes } = this.state

    const showThemes = sectionId == openSection ? 'show-themes' : null
    const rotateIcon = sectionId == openSection ? 'rotate' : 'no-rotate'
    if (openSection == 0) {
      openSubthemes(sectionId)
    }
    const onVisiblePagination = totalThemes > THEMES_ON_PAGE
    return (
      <div className="panel-body theme-item">
        <div className="panel-table-collapsible">
          <div
            className={`panel-table-header ${rotateIcon}`}
            onClick={() => this.openSection(sectionId)}
          >
            <div className="theme-title">
              <h6>{sectionName}</h6>
              <div className={`fa fa-chevron-up collapse-close ${rotateIcon}`}></div>
              <div className="theme-subtitle">{themeTitle}</div>
            </div>
          </div>
          <div id="incometoggle" className={`collapsible-content ${showThemes}`}>
            <div className="panel-content">
              {onVisiblePagination && this.renderPaginationWrapper()}
              {this.renderThemesInSection(themes)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
