import { toastr } from 'react-redux-toastr'
import window from 'global/window'

import fetch from '../../components/shared/fetch'
import { getClientName } from '../../components/shared/utils/browser'
import { getError } from '../../components/shared/utility'
import { getBaseProps } from '../utils'

import {
  AUTH_MODAL_OPEN,
  AUTH_MODAL_CLOSE,
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  AUTH_LOGOUT,
  AUTH_LOGOUT_ONLY,
  SET_AUTH_REDIRECT_PATH,
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_FAILURE,
  AUTH_HYDRATE_FROM_LOCAL_STORAGE,
  AUTH_REGISTRATION_START,
  AUTH_REGISTRATION_SUCCESS,
  AUTH_REGISTRATION_FAILURE,
  AUTH_REGISTER_PROFILE_START,
  AUTH_REGISTER_PROFILE_SUCCESS,
  AUTH_REGISTER_PROFILE_FAILURE,
  AUTH_RECOVER_PASSWORD_SEND_EMAIL_START,
  AUTH_RECOVER_PASSWORD_SEND_EMAIL_SUCCESS,
  AUTH_RECOVER_PASSWORD_SEND_EMAIL_FAILURE,
  AUTH_RECOVER_PASSWORD_SET_NEW_VALUE_START,
  AUTH_RECOVER_PASSWORD_SET_NEW_VALUE_SUCCESS,
  AUTH_RECOVER_PASSWORD_SET_NEW_VALUE_FAILURE,
  AUTH_REMIND_PASSWORD_START,
  AUTH_REMIND_PASSWORD_SUCCESS,
  AUTH_REMIND_PASSWORD_FAILURE,
  AUTH_CHANGE_PASSWORD_START,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  AUTH_CHANGE_EMAIL_SUCCESS,
} from './action-types'
import { reasonTypes } from '../../components/auth/logout-reasons'
import {
  LOGIN_SUSPENDED_ASKED_FOR_PERMISSION,
  LOGIN_SUCCESSFULLY_LOGGED_IN,
  LOGIN_PERMISSION_STATUS_ALLOWED,
} from '../constants'
import { resetLoginSocket } from './socket'
import { ReduxGetState } from '../reducers/types'

export const hydrateFromLocalStorage = data => {
  return {
    type: AUTH_HYDRATE_FROM_LOCAL_STORAGE,
    data,
  }
}

export const showModal = mode => {
  return {
    type: AUTH_MODAL_OPEN,
    mode,
  }
}

export const closeModal = () => {
  return {
    type: AUTH_MODAL_CLOSE,
  }
}

export const logout = () => {
  return {
    type: AUTH_LOGOUT,
    reason: reasonTypes.idle,
  }
}

export const logoutOnly = () => {
  return {
    type: AUTH_LOGOUT_ONLY,
    reason: reasonTypes.idle,
  }
}

export const logoutHereAfterLoginOnAnotherDevice = () => {
  return {
    type: AUTH_LOGOUT,
    reason: reasonTypes.loginOnAnotherDevice,
  }
}

export const idleLogout = () => {
  return dispatch => {
    dispatch({
      type: AUTH_LOGOUT,
      reason: reasonTypes.idle,
    })
    window.location.href = '/'
  }
}

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout())
    }, expirationTime * 1000)
  }
}

// -----------------------------------------------------------------
// login
// -----------------------------------------------------------------
export const loginStart = (requestedAt = new Date()) => ({ type: AUTH_START, requestedAt })
export const loginSuccess = (data, status?) => dispatch => {
  const { username, fullName } = data
  toastr.success(
    `${window.t('login.logged.as')} '${username}'`,
    `${window.t('hello')}, ${fullName}!`,
    {
      timeOut: 4000,
      transitionIn: 'bounceInDown', //'fadeIn',
      transitionOut: 'bounceOutUp', //'fadeOut',
      position: 'top-left',
      progressBar: true,
    }
  )
  dispatch({ type: AUTH_SUCCESS, data, status })
}
export const loginFailure = error => dispatch => {
  toastr.error(error.message, { progressBar: true })
  dispatch({ type: AUTH_FAILURE, error })
}
export const login = ({ username, password }, cb) => {
  return async (dispatch, getState: ReduxGetState) => {
    dispatch(loginStart())
    dispatch(resetLoginSocket())

    const state = getState()
    return fetch('/auth', {
      method: 'POST',
      body: JSON.stringify({
        username,
        password,
        ...getBaseProps(state),
      }),
    })
      .then(json => {
        const { status } = json
        if (status === LOGIN_SUCCESSFULLY_LOGGED_IN) {
          dispatch(loginSuccess(json))
          cb && cb(status, json)
        } else if (status === LOGIN_SUSPENDED_ASKED_FOR_PERMISSION) {
          const { payload } = json
          cb && cb(status, payload)
        }
      })
      .catch(json => dispatch(loginFailure({ statusCode: json.statusCode, message: json.message })))
  }
}
export const loginAllowed = ({ username, password }, cb) => {
  return async (dispatch, getState: ReduxGetState) => {
    dispatch(loginStart())

    const state = getState()
    return fetch('/loginAllowed', {
      method: 'POST',
      body: JSON.stringify({
        username,
        password,
        ...getBaseProps(state),
      }),
    })
      .then(json => {
        dispatch(loginSuccess(json, LOGIN_PERMISSION_STATUS_ALLOWED))
        cb && cb()
      })
      .catch(json => dispatch(loginFailure({ statusCode: json.statusCode, message: json.message })))
  }
}
// -----------------------------------------------------------------
// register
// -----------------------------------------------------------------
export const registerStart = (requestedAt = new Date()) => ({
  type: AUTH_REGISTRATION_START,
  requestedAt,
})
export const registerSuccess = data => dispatch => {
  dispatch({ type: AUTH_REGISTRATION_SUCCESS, data })
}
export const registerFailure = error => dispatch => {
  toastr.error(error.message)
  dispatch({ type: AUTH_REGISTRATION_FAILURE, error })
}
export const register = (values, cb) => {
  return async (dispatch, getState: ReduxGetState) => {
    dispatch(registerStart())
    const state = getState()
    return fetch('/auth/register', {
      method: 'POST',
      body: JSON.stringify({
        ...values,
        ...getBaseProps(state),
      }),
    })
      .then(json => {
        dispatch(registerSuccess(json))
        cb && cb(json)
      })
      .catch(json =>
        dispatch(registerFailure({ statusCode: json.statusCode, message: json.message }))
      )
  }
}
// -----------------------------------------------------------------
// register Profile
// -----------------------------------------------------------------
const registerProfileStart = (requestedAt = new Date()) => ({
  type: AUTH_REGISTER_PROFILE_START,
  requestedAt,
})
const registerProfileSuccess = data => dispatch => {
  const { message } = data
  toastr.success(message, {
    timeOut: 4000,
    transitionIn: 'bounceInDown', //'fadeIn',
    transitionOut: 'bounceOutUp', //'fadeOut',
    position: 'top-left',
    progressBar: true,
  })
  dispatch({ type: AUTH_REGISTER_PROFILE_SUCCESS, data })
}
const registerProfileFailure = error => dispatch => {
  toastr.error(error.message)
  dispatch({ type: AUTH_REGISTER_PROFILE_FAILURE, error })
}
export const registerProfile = (values, cb) => {
  return async (dispatch, getState: ReduxGetState) => {
    dispatch(registerProfileStart())
    const state = getState()
    return fetch('/auth/registerProfile', {
      method: 'POST',
      body: JSON.stringify({
        ...values,
        ...getBaseProps(state),
      }),
    })
      .then(json => {
        dispatch(registerProfileSuccess(json))
        cb && cb(json)
      })
      .catch(json =>
        dispatch(registerProfileFailure({ statusCode: json.statusCode, message: json.message }))
      )
  }
}

// -----------------------------------------------------------------
// recover password - 1. send email
// -----------------------------------------------------------------
const recoverPasswordSendEmailStart = (requestedAt = new Date()) => ({
  type: AUTH_RECOVER_PASSWORD_SEND_EMAIL_START,
  requestedAt,
})
const recoverPasswordSendEmailSuccess = data => dispatch => {
  dispatch({ type: AUTH_RECOVER_PASSWORD_SEND_EMAIL_SUCCESS, data })
  toastr.success(window.t('login.password.recovery.sent.email'), {
    position: 'top-left',
    timeOut: 4000,
  })
}
const recoverPasswordSendEmailFailure = error => dispatch => {
  dispatch({ type: AUTH_RECOVER_PASSWORD_SEND_EMAIL_FAILURE, error: error })
  toastr.error(error.message)
}
export const recoverPasswordSendEmail = ({ email }, cb?) => {
  return (dispatch, getState: ReduxGetState) => {
    dispatch(recoverPasswordSendEmailStart())

    const state = getState()
    const { clientId } = state.auth
    return fetch('/auth/recoverPasswordSendEmail', {
      method: 'POST',
      body: JSON.stringify({
        email,
        clientId,
        clientName: getClientName(),
      }),
    })
      .then(json => {
        dispatch(recoverPasswordSendEmailSuccess(json))
        cb && cb()
      })
      .catch(json =>
        dispatch(
          recoverPasswordSendEmailFailure({ statusCode: json.statusCode, message: json.message })
        )
      )
  }
}
// -----------------------------------------------------------------
// recover password - 2. set new password
// -----------------------------------------------------------------
const recoverPasswordSetNewPasswordStart = (requestedAt = new Date()) => ({
  type: AUTH_RECOVER_PASSWORD_SET_NEW_VALUE_START,
  requestedAt,
})
const recoverPasswordSetNewPasswordSuccess = data => dispatch => {
  const { username, fullName } = data
  toastr.success(
    `${window.t('login.password.recovery.success')} '${username}'`,
    `${window.t('hello')}, ${fullName}!`,
    {
      timeOut: 4000,
      transitionIn: 'bounceInDown', //'fadeIn',
      transitionOut: 'bounceOutUp', //'fadeOut',
      position: 'top-left',
      progressBar: true,
    }
  )
  dispatch({ type: AUTH_RECOVER_PASSWORD_SET_NEW_VALUE_SUCCESS })
  dispatch({ type: AUTH_SUCCESS, data })
}
const recoverPasswordSetNewPasswordFailure = error => dispatch => {
  dispatch({ type: AUTH_RECOVER_PASSWORD_SET_NEW_VALUE_FAILURE, error: error })
  toastr.error(error.message)
}
export const recoverPasswordSetNewPassword = ({ token, password }, cb) => {
  return (dispatch, getState: ReduxGetState) => {
    dispatch(recoverPasswordSetNewPasswordStart())

    const state = getState()
    const { clientId } = state.auth
    return fetch('/auth/recoverPasswordSetNew', {
      method: 'POST',
      body: JSON.stringify({
        token,
        password,
        clientId,
        clientName: getClientName(),
      }),
    })
      .then(json => {
        dispatch(recoverPasswordSetNewPasswordSuccess(json))
        cb && cb()
      })
      .catch(json => {
        dispatch(
          recoverPasswordSetNewPasswordFailure({
            statusCode: json.statusCode,
            message: json.message,
          })
        )
      })
  }
}

// -----------------------------------------------------------------
// remind password
// -----------------------------------------------------------------
export const remindPasswordStart = (requestedAt = new Date()) => ({
  type: AUTH_REMIND_PASSWORD_START,
  requestedAt,
})
export const remindPasswordSuccess = data => ({ type: AUTH_REMIND_PASSWORD_SUCCESS, data })
export const remindPasswordFailure = error => ({
  type: AUTH_REMIND_PASSWORD_FAILURE,
  error: error,
})
export const remindPassword = ({ username, password }) => {
  return (dispatch, getState: ReduxGetState) => {
    dispatch(remindPasswordStart())

    const state = getState()
    const { clientId } = state.auth
    return fetch('/auth/remindPassword', {
      method: 'POST',
      body: JSON.stringify({
        username,
        password,
        clientId,
        clientName: getClientName(),
      }),
    })
      .then(json => dispatch(remindPasswordSuccess(json)))
      .catch(err => dispatch(remindPasswordFailure(getError(err))))
  }
}
// -----------------------------------------------------------------
// change password
// -----------------------------------------------------------------
export const changePasswordStart = (requestedAt = new Date()) => ({
  type: AUTH_CHANGE_PASSWORD_START,
  requestedAt,
})
export const changePasswordSuccess = () => dispatch => {
  toastr.success(window.t('login.password.change.success'))
  dispatch({ type: AUTH_CHANGE_PASSWORD_SUCCESS })
}
export const changePasswordFailure = error => {
  toastr.error(error.message)
}
export const changePassword = ({ password, oldPassword }, cb) => {
  return (dispatch, getState: ReduxGetState) => {
    dispatch(changePasswordStart())

    const state = getState()
    return fetch('/auth/changePassword', {
      method: 'PUT',
      body: JSON.stringify({
        oldPassword,
        newPassword: password,
        ...getBaseProps(state),
      }),
    })
      .then(() => {
        dispatch(changePasswordSuccess())
        cb && cb()
      })
      .catch(error => changePasswordFailure(error))
  }
}

export const changeEmailSuccess = (email: string) => dispatch => {
  toastr.success(window.t('login.email.change.success'))
  dispatch({ type: AUTH_CHANGE_EMAIL_SUCCESS, email })
  setTimeout(() => {
    window.location.reload();
  }, 1000);
}

export const changeEmail = ({ email }) => {
  return (dispatch, getState: ReduxGetState) => {
    const state = getState()

    return fetch('/auth/changeEmail', {
      method: 'POST',
      body: JSON.stringify({
        email,
        ...getBaseProps(state),
      }),
    })
      .then(response => {
        return toastr.success(`${window.t('login.email.change.sent.email')} ${response.email}`)
      })
      .catch(error => toastr.error(error.message))
  }
}

export const confirmChangeEmail = (token: string) => {
  return (dispatch, getState: ReduxGetState) => {
    const state = getState()
    return fetch('/auth/changeEmailConfirm', {
      method: 'POST',
      body: JSON.stringify({
        token,
        ...getBaseProps(state),
      }),
    })
      .then(json => {
        dispatch(changeEmailSuccess(json.email))
      })
      .catch(error => toastr.error(error.message))
  }
}

export const setAuthRedirectPath = path => {
  return {
    type: SET_AUTH_REDIRECT_PATH,
    path: path,
  }
}

export const getAccessPoint = (accessToken: string): Promise<AccessPermissonStatus> => {
  return fetch(`/auth/getUserAccessPoint`, {
    method: 'POST',
    body: JSON.stringify({ accessToken }),
  }).then(response => {
    return response.permissionStatus
  })
}

export const checkAuthorStatus = (
  entity: string,
  itemId: number
): Promise<AccessPermissonStatus> => {
  return fetch(`/auth/checkAuthor/${entity}/${itemId}`).then(response => response.permissionStatus)
}

function refreshSuccess(data) {
  return {
    type: AUTH_REFRESH_SUCCESS,
    data,
  }
}

function refreshFailure() {
  return {
    type: AUTH_REFRESH_FAILURE,
    reason: reasonTypes.refreshJWTFailure,
  }
}

export function refreshStart() {
  return function (dispatch, getState: ReduxGetState) {
    const state = getState()
    const { clientId, refreshToken } = state.auth

    if (!refreshToken) {
      return Promise.resolve()
    }

    const data = {
      token: refreshToken,
      clientId,
    }
    // try to get a new access token:
    return (
      fetch('/auth/refresh', {
        method: 'POST',
        body: JSON.stringify(data),
      })
        .then(
          json =>
            dispatch(
              refreshSuccess({
                accessToken: json.accessToken,
                refreshToken: json.refreshToken,
                clientId: json.clientId,
              })
            )
        )
        .catch(() => {
          dispatch(refreshFailure())
        })
    )
  }
}
