import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { reduxForm, formValueSelector } from 'redux-form'
import { toastr } from 'react-redux-toastr'

import Component from './component'
import fetch from '../../shared/fetch'
import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'
import { IReduxStorage } from '../../../store/reducers/types'

const formName = 'add-admin-notice'
const formSelector = formValueSelector(formName)

export default compose(
  withPermissions,
  withTranslation,
  withRouter,
  connect((state: IReduxStorage) => ({
    textCounter: formSelector(state, 'text')?.length || 0,
  })),
  reduxForm({
    form: formName,
    onSubmit: (values, dispatch, props) => {
      const propsClone: any = { ...props }
      const valuesClone: any = { ...values }
      const { t, match, history } = propsClone
      const { userId } = match.params
      const { title, text, placementPeriod } = valuesClone

      fetch('/user/addAdminNotice', {
        method: 'POST',
        body: JSON.stringify({
          userId,
          title,
          text,
          placementPeriod,
        }),
      }).then(response => {
        toastr.success(t('admin.notice.add.success'))
        history.push(`/user/details/${userId}`)
      })
    },
  })
)(Component)
