import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import LoginForm from '../../auth/login'
import { ModalConsumer } from '../../../context/modal-context.tsx'
import WaitLoginPermission from '../../auth/login/wait-login-permission'
import {
  LOGIN_SUCCESSFULLY_LOGGED_IN,
  LOGIN_SUSPENDED_ASKED_FOR_PERMISSION,
} from '../../../store/constants'

export default class extends Component {
  openLoginForm = () => {
    const { t } = this.props

    this.showModalComponent(<LoginForm onClose={this.onClose} />, {
      title: t('login.title.login'),
      className: 'auth-modal mode-login',
      backdrop: true,
    })
  }
  onClose = (status, payload) => {
    if (status === LOGIN_SUSPENDED_ASKED_FOR_PERMISSION) {
      this.openSecondSessionGuard(payload)
    } else if (!status || status === LOGIN_SUCCESSFULLY_LOGGED_IN) {
      this.clearModal()
    }
  }
  openSecondSessionGuard = payload => {
    const { loginAllowed } = this.props

    this.showModalComponent(
      <WaitLoginPermission
        onAllow={() =>
          loginAllowed(payload, () => {
            this.clearModal()
          })
        }
        onDeny={() => this.clearModal()}
      />,
      {
        className: 'side-login-guard',
        backdrop: true,
      }
    )
  }

  render() {
    const { t, children } = this.props

    return (
      <ModalConsumer>
        {({ showModalComponent, clearModal }) => {
          this.showModalComponent = showModalComponent
          this.clearModal = clearModal
          return (
            <Link to="#" onClick={this.openLoginForm}>
              {children || t('menu.login')}
            </Link>
          )
        }}
      </ModalConsumer>
    )
  }
}
