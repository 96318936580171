import React from 'react'
import _ from 'lodash'
import classnames from 'classnames/bind'
// import { connect } from "react-redux"
import { defineMessages } from 'react-intl'
// import { compose, withHandlers } from "recompose"
import {
  Link,
  //NavLink,
  //    Redirect
} from 'react-router-dom'
import Helmet from 'react-helmet'
// import { Field, reduxForm } from "redux-form"

import EmptyList from '../../shared/components/empty-list'

const cssClasses = classnames.bind(require('./styles.css'))

const messages = defineMessages({
  contactsTitle: {
    id: 'pages.PersonList.title',
    defaultMessage: 'Contacts',
  },
  personName: {
    id: 'pages.PersonList.personName',
    defaultMessage: 'Name',
  },
  level: {
    id: 'pages.PersonList.level',
    defaultMessage: 'Job',
  },
  state: {
    id: 'pages.PersonList.state',
    defaultMessage: 'State',
  },
  lga: {
    id: 'pages.PersonList.lga',
    defaultMessage: 'County',
  },
  paginatorStatus: {
    id: 'pages.PersonList.paginatorStatus',
    defaultMessage: `Showing {firstItemIndex}-{lastItemIndex} of {itemCount, plural,
      one {# contact}
      other {# contacts}
    }`,
  },
})

// ------------------------------------------------------------------------------------------
const getFullName = person =>
  _.trim(
    (person.get('firstName') || '') +
      ' ' +
      (person.get('middleName') || '') +
      ' ' +
      (person.get('lastName') || '')
  ) || '???'
// const sortBy = fieldName => {

// }
// ==========================================================================================
// ==========================================================================================
// ===
// ==========================================================================================
// ==========================================================================================
export default ({
  t,
  // history,
  // location,
  table,
  items,
  // --- injected by recompose ---
  sortCol,
  sortDir,
  pageAt,
  pageSize,
  onRowClick,
  onSortColumn,
  // reducedFilters,
}) => {
  const totalPages = Math.floor(table.total / pageSize) + 1
  const pageNumber = pageAt + 1

  return (
    <React.Fragment>
      {/* <div>
        {table.total}
        </div> */}

      <Helmet title={messages.contactsTitle.defaultMessage} />
      <div className="panel">
        <div className="card-header">
          <h4>Добавить Груз</h4>
        </div>
        <div className="card-body">
          <div className="panel-filter">
            <div className="filter-wrapper filter-nav clearfix priority-nav">
              {/* <Field
                name="priority"
                component = { FilterLinksFieldRender }
                items = { dict.priority }
              /> */}
            </div>
          </div>

          {/* <div className={cssClasses('Container')}>
    <Helmet title={messages.contactsTitle.defaultMessage} /> */}

          {!items.currentIdList.isEmpty() && (
            <React.Fragment>
              <table className="table table-striped table-hover">
                <thead>
                  <tr className="first-left-rest-center">
                    <th
                      className={cssClasses('clickable', {
                        'a-z': sortCol === 'name' && sortDir === 'asc',
                        'z-a': sortCol === 'name' && sortDir === 'desc',
                      })}
                      onClick={() => onSortColumn('name')}
                      style={{ minWidth: '100px' }}
                    >
                      {t('name')}
                    </th>
                    <th>Рейтинг</th>
                    <th>Статус</th>
                    <th>Кол-во отзывов</th>
                    <th>Кол-во блонировок</th>
                    <th>Кол-во предложений</th>
                    <th>Название компании</th>
                    <th>Дата регистрации</th>
                    <th>Страна</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    items.currentIdList
                      .map(id => items.byId.get(id))
                      .map(person => (
                        <tr
                          key={person.get('id')}
                          className="first-left-rest-center"
                          onClick={onRowClick}
                          data-user-id={person.get('id')}
                        >
                          <td>{getFullName(person)}</td>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td>
                            {new Date(person.get('registeredOn')).toLocaleDateString(
                              person.get('countryCulture')
                            )}
                          </td>
                          <td>{person.get('countryName')}</td>
                        </tr>
                        // <PersonDataTableRow
                        //   key={person.get('id')}
                        //   intl={intl}
                        //   person={person}
                        //   onClick={onRowClick}
                        //   data-person-id={person.get('id')}
                        // />
                      ))

                    /* {
                _.map(items.currentIdList, item => (
                  <tr>
                    <td>
                      {item.name}
                    </td>
                  </tr>
                ))
              } */
                  }
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan="9">
                      <div className="paging">
                        <div className="totals">
                          {`
                          ${pageAt * pageSize + 1}-${pageAt * pageSize + pageSize} 
                          out of ${table.total}
                          `}
                        </div>
                      </div>
                    </th>
                  </tr>
                </tfoot>
              </table>
              <div className="pagination-block">
                <ul className="pagination">
                  <li className={'prev' + (pageNumber > 1 ? '' : ' disabled')}>
                    <Link to="#">Пред.</Link>
                  </li>

                  <li className="page">
                    <Link to="/">1</Link>
                  </li>
                  <li className="current">
                    <span>2</span>
                  </li>
                  <li className="page">
                    <Link to="/">3</Link>
                  </li>
                  <li className="dots">
                    <span>...</span>
                  </li>
                  <li className="page">
                    <Link to="/">11</Link>
                  </li>
                  <li className="page">
                    <Link to="/">12</Link>
                  </li>
                  <li className="page">
                    <Link to="/">13</Link>
                  </li>

                  <li className={'next' + (pageNumber < totalPages ? '' : ' disabled')}>
                    <Link to="#">След.</Link>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          )}

          {items.currentIdList.isEmpty() && (
            // <BrowseEmptyList history={history} intl={intl} />
            // <EmptyList intl={intl} />
            <EmptyList />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}
