export const int7 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Классификация грузовых автотранспортных средств</h4><p></p>

<p style="text-indent:2em;">Для перевозок грузов автотранспортными предприятиями используется грузовой подвижной состав: грузовые автомобили и автомобильные прицепы
 различной грузоподъемности (бортовые, самосвалы, фургоны, в том числе изотермические, цистерны и другие), автомобили повышенной
 проходимости, автомобили-тягачи с полуприцепами. Эта часть транспортной сети также
 имеет свою разветвленную структуру.</p>

<p style="text-indent:2em;"></p><h6>Классификация грузовых транспортных средств по различным основаниям выглядит следующим образом:</h6><p></p>

<p style="text-indent:2em;"></p><h6>По типу кузова:</h6><p></p>

<p>закрытый тип</p><br>

<p>контейнер</p><br>

<p>тентованный</p><br>

<p>рефрижератор (изотермический кузов)</p><br>

<p>изотермический фургон</p><br>

<p>микроавтобус</p><br>

<p>открытый тип</p><br>

<p>бортовой</p><br>

<p>самосвал</p><br>

<p>конт. площадка</p><br>

<p>кран</p><br>

<p>автотранспортер</p><br>

<p>цистерна</p><br>

<p>лесовоз</p><br>

<p>седельный тягач</p>

<p style="text-indent:2em;"></p><h6>По группам:</h6><p></p>

<p></p><h4>I группа</h4><p></p>

<p>бортовые автомобили</p><br>

<p>(автомобили-фургоны общего назначения)</p>

<p></p><h4>II группа</h4><p></p>

<p>специализированные</p><br>

<p style="text-indent:2em;">(самосвалы, фургоны, рефрижераторы, контейнеровозы, седельные тягачи с
 полуприцепами, балластные тягачи с прицепами)</p>

<p></p><h4>III группа (условно)автомобили-цистерны</h4><p></p>

<p style="text-indent:2em;"></p><h6>По количеству осей:</h6><p></p>

<p>двухосные</p><br>

<p>трехосные</p><br>

<p>четырехосные</p><br>

<p>пятиосные и более</p>

<p style="text-indent:2em;"></p><h6>По осевым нагрузкам (на наиболее загруженную ось):</h6><p></p>

<p>до 6 т включительно</p><br>

<p>свыше 6 т до 10 т включительно</p>

<p style="text-indent:2em;"></p><h6>По колесной формуле:</h6><p></p>

<p>4х2</p><br>

<p>4х4</p><br>

<p>6х4</p><br>

<p>6х6</p>

<p style="text-indent:2em;"></p><h6>По составу:</h6><p></p>

<p>одиночное транспортное средство</p>

<p style="text-indent:2em;"></p><h6>автопоезд в составе:</h6><p></p>

<p>автомобиль-прицеп</p><br>

<p>автомобиль-полуприцеп</p>

<p style="text-indent:2em;"></p><h6>По типу двигателя:</h6><p></p>

<p>бензиновые</p><br>

<p>дизельные</p>

<p style="text-indent:2em;"></p><h6>По грузоподъемности:</h6><p></p>

<p>малой</p><br>

<p>средней</p><br>

<p>большой</p><br>

<p>от 1,5 до 16 тонн</p><br>

<p>свыше 16 тонн</p><br>

<p style="text-indent:2em;">Такое многообразие способов классификации объясняется потребностью выделения отдельных параметров транспортных средств для выбора последних при
 перевозке грузов на основании оптимального сочетания экономичности, скорости доставки, коммерческой пригодности, безопасности, вместимости,
 грузоподъемности и т.д.</p><br>

<p style="text-indent:2em;">Список перечисленных оснований является приблизительным, так как его можно продолжить, исходя из определенных функциональных назначений транспортных
 средств, их эксплуатационных характеристик, специфики перевозимых грузов и т.д.</p>

<p style="text-indent:2em;"></p><h6>Кроме вышеперечисленных способов классификации, отраслевой нормалью ОН 025 270-66 введена классификация и система обозначения автомобильного
 подвижного состава. Так, в отношении грузовых автомобилей принята следующая система обозначения автотранспортных средств (АТС):</h6><p></p>

<p>Примечание. Классы от 18 до 78 являются резервными и в индексацию не включены.</p>

<p style="text-indent:2em;"></p><h6>2-я цифра обозначает тип АТС:</h6><p></p>

<p>3 - грузовой бортовой автомобиль или пикап;</p><br>

<p>4 - седельный тягач;</p><br>

<p>5 - самосвал;</p><br>

<p>6 - цистерна;</p><br>

<p>7 - фургон;</p><br>

<p>8 - резервная цифра;</p><br>

<p>9 - специальное автотранспортное средство.</p><br>

<p>3-я и 4-я цифры индексов указывают на порядковый номер модели;</p><br>

<p>5-я цифра - модификация автомобиля;</p>

<p style="text-indent:2em;"></p><h6>6-я цифра - вид исполнения:</h6><p></p>

<p>1 - для холодного климата;</p><br>

<p>6 - экспортное исполнение для умеренного климата;</p><br>

<p>7 - экспортное исполнение для тропического климата.</p><br>

<p style="text-indent:2em;">Некоторые автотранспортные средства имеют в своем обозначении через тире приставку 01, 02, 03 и т.д., что указывает на то, что модель или модификация
 является переходной или имеет дополнительные комплектации. Перед цифровым индексом по данной классификации, в большинстве случаев, указывается буквенное
 обозначение завода-изготовителя (например, КамАЗ 5320). Обозначения автомобилей иностранных марок, в большинстве случаев, состоят из буквенного
 обозначения марки завода-изготовителя и заводского порядкового номера модели и модификации.</p>

<p style="text-indent:2em;"></p><h6>В настоящее время все большее распространение получают обозначения, принятые в международных требованиях по безопасности (Правилах ЕЭК ООН),
 разрабатываемых Комитетом по внутреннему транспорту Европейской экономической комиссии ООН. В соответствии с вышеуказанными Правилами принята
 следующая международная классификация грузовых АТС:</h6><p></p>

<p style="text-indent:2em;"></p><h6>Международная классификация транспортных средств разделяется на четыре категории:</h6><p></p>

<p style="text-indent:2em;">категория L - механические транспортные средства, имеющие менее 4-х колес, рабочий объем двигателя которых более 50 см3 и максимальная
 скорость более 50 км/ч;</p><br>

<p style="text-indent:2em;">категория М - механические транспортные средства с двигателем, имеющие не менее четырех колес, используемые для перевозки пассажиров;</p><br>

<p style="text-indent:2em;">категория N - механические транспортные средства имеющие не менее четырех колес, используемые для перевозки грузов;</p><br>

<p>категория О - включает в себя прицепы.</p>

<p style="text-indent:2em;"></p><h6>В свою очередь категории делятся на подкатегории:</h6><p></p>

<p>L3 L4 L5 — мотоциклы, мотороллеры и другие транспортные средства, имеющие менее 4-х колес.</p><br>

<p style="text-indent:2em;">М1 — автотранспортные средства, для перевозки пассажиров, имеюшие не более 8 мест для сидений ( кроме места водителя );</p><br>

<p style="text-indent:2em;">М2 — автотранспортные средства, для перевозки пассажиров, имеюшие более 8 мест для сидений ( кроме места водителя ) и максимальная масса
 которыхне более 5 т;</p><br>

<p style="text-indent:2em;">М3 — автотранспортные средства, для перевозки пассажиров, имеюшие не более 8 мест для сидений ( кроме места водителя ) и максимальная
 масса которых более 5 т;</p><br>

<p>N1 — автотранспортные средства, для перевозки грузов, максимальная масса которых не более 3.5 т.;</p><br>

<p style="text-indent:2em;">N2 — автотранспортные средства, для перевозки грузов, максимальная масса которых более 3.5 т., но не превышает 12 т., пассажиров, имеюшие
 более 8 мест для сидений ( кроме места водителя ) и максимальная масса которыхне более 5 т;</p><br>

<p>N3 — автотранспортные средства, для перевозки грузов, максимальная масса которых более 12 т.;</p><br>

<p>O1 — транспортные средства ( прицепы), максимальная масса которых не превышает 0.75 т.;</p><br>

<p>O2 — транспортные средства ( прицепы), максимальная масса которых более 0.75 т., но не превышает 3.5 т.;</p><br>

<p style="text-indent:2em;">O3 — транспортные средства ( прицепы, полуприцепы), максимальная масса которых более 3.5 т., но не превышает 10 т.;</p><br>

<p>O4 — транспортные средства ( прицепы, полуприцепы), максимальная масса более 10 т..</p><br>

<p><a href="https://www.rh2.ru/home/p2_articleid/50">Источник</a></p>

</div>
`