import React from 'react'
import { Link } from 'react-router-dom'

import { Field } from 'redux-form'

import { ModalConsumer } from '../../../context/modal-context.tsx'
import WidgetBase from '../../shared/components/widget-base'

import { required } from '../../shared/form-validators'
import Dropdown from '../../UI/dropdown'
import dict from '../../shared/dictionaries'
import FieldBase from '../../UI/field-base'
import ContactDetails from '../contact-details'
import CloseCompanyModal from '../modal-close-company'
import fetch from '../../shared/fetch'

import './styles.scss'
import FlexBand, { FlexBandItem } from 'flexband'

const STYLE_BASE = 'company-edit_'

const yearsFounded = dict.rangeYears(100).reverse()

export default class EditBranch extends React.Component {
  state = {
    companies: [],
  }

  componentDidMount() {
    fetch('/company/getUserCompanies', {
      method: 'GET',
    }).then(response => {
      this.setState({ companies: response.d })
    })
  }

  showCloseCompanyModal = ({ showModalComponent, clearModal }) => {
    showModalComponent(<CloseCompanyModal onCancel={clearModal} />, {
      title: this.props.t('company.title.close'),
      className: `${STYLE_BASE}modal`,
      backdrop: true,
    })
  }

  drawHeaderMenu = () => {
    const { t, itemId } = this.props
    return itemId ? (
      <div className="link-right">
        <ModalConsumer>
          {modalConsumer => (
            <Link
              to="#"
              data-popup-link="close-company"
              onClick={() => this.showCloseCompanyModal(modalConsumer)}
            >
              <i className="fa fa-close col-red" />
              {t('company.button.close')}
            </Link>
          )}
        </ModalConsumer>
      </div>
    ) : null
  }

  render() {
    const { t, langId, itemId, handleSubmit } = this.props
    const { companies } = this.state

    return (
      <WidgetBase
        title={itemId ? t('branch.edit') : t('branch.create')}
        headerMenu={this.drawHeaderMenu}
        className="company-profile"
      >
        <form onSubmit={handleSubmit}>
          <div className="panel-body">
            <div className="panel-content">
              <div className="panel-form panel-form-simple">
                <div className="panel-content-row">
                  <div className="panel-form-row">
                    <div className="row">
                      <div className="col-md-4 col-lg-5">
                        <h5 className="title-gray">{t('branch.info')}</h5>
                      </div>
                      <div className="col-md-12 col-lg-10">
                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <Field
                              name="company.name"
                              type="text"
                              component={FieldBase}
                              label={t('branch.name')}
                              placeholder={t('branch.name')}
                              validate={[required]}
                              // errorPosition={FORM_FIELD_ERROR_POSITION_TOP}
                            />
                          </div>
                          {/* <div className="col-sm-8">
                            <div className="meta-aligned">
                              <span className="meta-gray">Название согласно сертификату</span>
                            </div>
                          </div> */}
                        </div>
                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <label>{t('branch.head.company')}</label>
                            <Field
                              name="branch.head"
                              emptyOption={t('branch.choose.head')}
                              component={Dropdown}
                              items={companies}
                              // validate={required}
                            />
                          </div>
                        </div>
                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <label htmlFor="t2">{t('property.type')}</label>
                            <Field
                              name="company.propertyType"
                              component={Dropdown}
                              items={dict.ownershipTypes(langId)}
                              // validate={required}
                            />
                          </div>
                        </div>
                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <label>{t('country')}</label>
                            <Field
                              name="company.country"
                              component={Dropdown}
                              items={dict.countries(langId)}
                              // validate={required}
                            />
                          </div>
                        </div>
                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <label htmlFor="t4">{t('business.type')}</label>
                            <Field
                              name="company.businessType"
                              component={Dropdown}
                              items={dict.businessTypes(langId)}
                            />
                          </div>
                        </div>
                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <label htmlFor="t5">{t('director.administrator')}</label>
                            <select id="t5" style={{ display: 'none' }}>
                              <option>Александр Меньшиков</option>
                              <option>Молдова</option>
                              <option>Молдова</option>
                            </select>
                            <div
                              className="chosen-container chosen-container-single chosen-container-single-nosearch"
                              style={{ width: '100%' }}
                              title=""
                              id="t5_chosen"
                            >
                              <Link className="chosen-single" to="#">
                                <span>Александр Меньшиков</span>
                                <div>
                                  <b />
                                </div>
                              </Link>
                              <div className="chosen-drop">
                                <div className="chosen-search">
                                  <input type="text" autoComplete="off" readOnly="" />
                                </div>
                                <ul className="chosen-results" />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-8">
                            <div className="meta-aligned">
                              <Link to="#">Добавить пользователей в компанию</Link>
                            </div>
                          </div>
                        </div>
                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <div className="row">
                              <div className="col-sm-6">
                                <label htmlFor="t6">{t('foundation.year')}</label>
                                <Field
                                  name="company.formationYear"
                                  component={Dropdown}
                                  items={yearsFounded}
                                />
                              </div>
                              <div className="col-sm-10">
                                <Field
                                  name="company.fiscalCode"
                                  type="text"
                                  component={FieldBase}
                                  label={t('fiscal.code')}
                                  placeholder={t('fiscal.code')}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <label htmlFor="t8">
                              {t('description')} ({t('optional')})
                            </label>
                            <Field
                              name="company.description"
                              component="textarea"
                              placeholder={t('character.limit.1000')}
                              maxLength={1000}
                              style={{ height: '100px' }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel-content-row">
                  <div className="panel-form-row">
                    <div className="row">
                      <div className="col-md-4 col-lg-5">
                        <h5 className="title-gray">
                          {t('contact.details')}
                          {/* <span className="col-bg"></span> */}
                        </h5>
                      </div>
                      <ContactDetails
                        className="col-md-12 col-lg-10"
                        fieldNamePhone1="company.headquarters.phone1"
                        fieldNamePhone2="company.headquarters.phone2"
                        fieldNameFax="company.headquarters.fax"
                        fieldNameEmail="company.headquarters.email"
                        fieldNameSkype="company.headquarters.skype"
                        fieldNameViber="company.headquarters.viber"
                        fieldNameAddress="company.headquarters.address"
                      />
                    </div>
                  </div>
                </div>
                <FlexBand justify="center">
                  <FlexBandItem className="button-style">
                    <button type="submit" className="button button-orange button-block">
                      {t('button.save')}
                    </button>
                  </FlexBandItem>
                </FlexBand>
                {/* <div className="panel-content-row">
                  <div className="panel-form-row">
                    <div className="row">
                      <div className="col-md-4 col-lg-5">
                        <h5 className="title-gray">Документы компании</h5>
                      </div>
                      <div className="col-md-12 col-lg-10">
                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <label htmlFor="t23">Сертификат о регистрации компании</label>
                            <input type="file" id="t23" />
                          </div>
                        </div>
                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <label htmlFor="t24">
                              Вид деятельности согласно
                              <Link to="#">регистру</Link>
                            </label>
                            <input type="text" id="t24" />
                          </div>
                        </div>
                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <label htmlFor="t25">
                              Вид лицензии согласно
                              <Link to="#"> регистру</Link>
                            </label>
                            <input type="text" id="t25" />
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </form>
      </WidgetBase>
    )
  }
}
