export const int20 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Acordul european privind activitatea echipajelor vehiculelor care efectuează transporturi rutiere
 internaționale (A.E.T.R.) din 01.07.1970 *)</h4><p></p>

<p>Geneva, 1 iulie 1970</p><br>

<p>Părțile contractante,</p><br>

<p>în dorința de a favoriza dezvoltarea și îmbunătățirea transporturilor rutiere internaționale de mărfuri și pasageri,</p><br>

<p style="text-indent:2em;">conștiente de necesitatea de a spori siguranța traficului rutier, de a reglementa condițiile de muncă în transporturile rutiere
 internaționale în conformitate cu principiile Organizației Internaționale a Muncii și de a adopta în comun anumite măsuri
 pentru asigurarea respectării unei asemenea reglementări,</p>

<p style="text-indent:2em;"></p><h6>au convenit după cum urmează:</h6><p></p>

<p style="font-weight:500;">ARTICOLUL 1 Definiții</p>

<p style="text-indent:2em;"></p><h6>În sensul prezentului acord, se înțelege:</h6><p></p>

<p>a) prin vehicul, orice automobil sau remorcă; acest termen include orice ansamblu de vehicule;</p><br>

<p style="text-indent:2em;">b) prin automobil, orice vehicul având un motor cu propulsie, care circulă pe șosea cu mijloacele sale proprii și care servește
 în mod normal la transportul de persoane sau de mărfuri pe șosea, sau la tracțiunea pe șosea a vehiculelor utilizate pentru
 transportul de persoane sau de mărfuri; acest termen nu cuprinde tractoarele agricole;</p><br>

<p>c) prin remorcă, orice vehicul destinat a fi cuplat cu un automobil; acest termen cuprinde semiremorcile;</p><br>

<p style="text-indent:2em;">d) prin semiremorcă, orice remorcă destinată a fi cuplată cu un automobil, astfel încât aceasta să se sprijine cu o parte pe
 automobil și o parte importantă din greutatea sa și din greutatea încărcăturii să fie suportată
 de automobilul respectiv;</p><br>

<p>e) prin ansamblu de vehicule, vehiculele cuplate care participă la circulația rutieră ca o singură unitate;</p><br>

<p style="text-indent:2em;">f) prin greutate maximă permisă, greutatea maximă a vehiculului încărcat, declarată admisă de către autoritatea competentă a
 statului în care vehiculul este înmatriculat;</p><br>

<p style="text-indent:2em;">g) prin transport rutier, orice deplasare efectuată pe rute deschise pentru public, cu încărcătură sau fără încărcătură, a
 unui vehicul destinat transportului de călători sau mărfuri;</p><br>

<p>h) prin transport rutier internațional, orice transport rutier care include traversarea
 a cel puțin unei frontiere;</p><br>

<p style="text-indent:2em;">i) prin servicii regulate, serviciile care asigură transportul de persoane, efectuat după un program și pe itinerare
 determinate, aceste servicii oferind posibilitatea de a lua și de a lăsa pasagerii din/în stații
 stabilite în prealabil.</p><br>

<p style="text-indent:2em;">Un regulament de exploatare sau documente înlocuitoare, aprobate de către autoritățile publice competente ale părților
 contractante și publicate de transportator înaintea punerii în aplicare, stabilesc condițiile de transport, mai ales frecvența,
 orarele, tarifele și obligația de a transporta, în măsura în care aceste condiții nu sunt precizate printr-un text legal sau
 reglementar.</p><br>

<p style="text-indent:2em;">Oricare ar fi organizatorul transporturilor, sunt considerate tot ca servicii regulate acele servicii care asigură transportul
 unor categorii determinate de călători, excluzând alți călători, în măsura în care aceste servicii sunt efectuate conform condițiilor
 indicate în primul alineat al prezentei definiții. Serviciile acestei categorii, mai ales acelea care asigură transportul lucrătorilor
 la locul de muncă și la domiciliul lor sau transportul elevilor la institutele de învățământ și la domiciliul lor, sunt numite în
 continuare servicii regulate speciale;</p><br>

<p style="text-indent:2em;">j) prin șofer, orice persoană, salariată sau nu, care conduce vehiculul, chiar pentru o perioadă scurtă, sau care este la bordul
 vehiculului pentru a-l putea conduce, dacă este cazul;</p>

<p style="text-indent:2em;"></p><h6>k) prin membru al echipajului sau membru de echipaj, șoferul sau una dintre persoanele menționate mai jos, fie că acest șofer sau
 că aceste persoane sunt salariate sau nu:</h6><p></p>

<p style="text-indent:2em;">(i) însoțitorul, adică orice persoană care însoțește șoferul pentru a-l asista la anumite manevre și care ia parte efectiv,
 în mod obișnuit, la operațiunile de transport, fără a fi un șofer în sensul paragrafului j) al prezentului articol.</p><br>

<p style="text-indent:2em;">(ii) încasatorul (taxatorul), adică orice persoană care însoțește șoferul unui vehicul care transportă persoane și care are
 ca sarcină principală să elibereze sau să controleze biletele sau alte documente care permit persoanelor să călătorească
 în vehicul;</p><br>

<p>l) prin săptămână, perioada cuprinsă între ora 0 a zilei de luni și ora 24 a zilei de duminică;</p><br>

<p>m) prin repaus, orice perioadă neîntreruptă de cel puțin o oră, în timpul căreia șoferul poate să dispună liber de timpul său.</p><br>

<p style="font-weight:500;">ARTICOLUL 2 Domeniu de aplicabilitate</p><br>

<p style="text-indent:2em;">1. Prezentul acord se va aplica pe teritoriul fiecărei părți contractante tuturor operațiunilor de transport rutier internațional
 efectuate cu un vehicul înmatriculat pe teritoriul acestei părți contractante sau pe teritoriul
 oricărei alte părți contractante.</p>

<p style="text-indent:2em;"></p><h6>2. Cu toate acestea:</h6><p></p>

<p style="text-indent:2em;">a) dacă, pe parcursul unei operațiuni de transport rutier internațional, unul sau mai mulți membri ai echipajului nu părăsesc
 teritoriul național unde își exercită în mod normal activitățile lor profesionale, partea contractantă căreia îi aparține acest
 teritoriu va fi liberă să nu aplice prevederile prezentului acord cu privire
 la acest sau la acești membri ai echipajului;</p>

<p style="text-indent:2em;"></p><h6>b) dacă părțile contractante al căror teritoriu este tranzitat nu se înțeleg într-un alt mod, prezentul acord nu se va aplica
 transporturilor rutiere internaționale efectuate cu:</h6><p></p>

<p style="text-indent:2em;">1. vehicule destinate transporturilor de mărfuri și a căror greutate maximă admisă, inclusiv cea a remorcilor sau a semiremorcilor,
 nu depășește 3,5 tone;</p><br>

<p style="text-indent:2em;">2. vehicule destinate transporturilor de călători care, după tipul lor de construcție sau de echipament, sunt construite să transporte
 maximum nouă persoane, inclusiv șoferul, și sunt destinate acestui scop;</p><br>

<p>3. vehicule destinate transporturilor de persoane prin servicii regulate al
 căror parcurs al liniei nu depășește 50 kilometri;</p><br>

<p>4. vehicule a căror viteză maximă admisă nu depășește 30 kilometri pe oră;</p><br>

<p style="text-indent:2em;">5. vehicule destinate serviciilor forțelor armate, protecției civile, pompierilor și forțelor care răspund de menținerea ordinii
 publice sau care sunt sub controlul acestora;</p><br>

<p style="text-indent:2em;">6. vehicule destinate serviciilor de canalizare, de protecție împotriva inundațiilor, serviciilor apei, gazului, electricității,
 gunoiului, colectării gunoaielor, telegrafului, telefoanelor, poștei, radiodifuziunii, televiziunii și detectării emițătorilor
 sau receptorilor de televiziune sau radio;</p><br>

<p>7. vehicule folosite pentru stare de urgență sau destinate acțiunilor de salvare;</p><br>

<p>8. vehicule specializate destinate serviciilor medicale;</p><br>

<p>9. vehicule care transportă materiale aparținând circurilor sau bâlciurilor;</p><br>

<p>10. vehicule specializate de depanare;</p><br>

<p style="text-indent:2em;">11. vehicule supuse testelor rutiere în vederea perfecționării tehnice, reparării sau întreținerii, și vehicule noi sau transformate,
 care nu sunt încă puse în circulație;</p><br>

<p>12. vehicule folosite pentru transporturi necomerciale de bunuri în scopuri particulare;</p><br>

<p style="text-indent:2em;">13. vehicule folosite pentru colectarea laptelui în ferme sau pentru aducerea în ferme a bidoanelor cu lapte sau a produselor
 din lapte destinate hranei animalelor.</p><br>

<p style="font-weight:500;">ARTICOLUL 3 Aplicarea anumitor dispoziții ale acordului în transporturile rutiere
efectuate de vehicule provenind din state care nu sunt părți contractante</p><br>

<p style="text-indent:2em;">1. Fiecare parte contractantă va aplica pe teritoriul său, în transporturile rutiere internaționale efectuate de orice vehicul
 înmatriculat pe teritoriul unui stat care nu este parte contractantă la prezentul acord, dispoziții cel puțin la fel de stricte
 ca acelea care sunt prevăzute în art. 5, 6, 7, 8, 9 și 10 ale prezentului acord.</p><br>

<p style="text-indent:2em;">2. Fiecare parte contractantă va putea, în cazul unui vehicul înmatriculat într-un stat care nu este parte contractantă la
 prezentul acord, să nu pretindă decât foi de parcurs zilnice completate de mână de către șofer în locul aparatului de control
 , conform specificațiilor cuprinse în anexa la prezentul acord.</p><br>

<p style="font-weight:500;">ARTICOLUL 4 Principii generale</p><br>

<p style="text-indent:2em;">Fiecare parte contractantă poate aplica minime mai ridicate sau maxime mai scăzute decât cele prevăzute în art. 5-8 inclusiv.
 Dispozițiile prezentului acord rămân totuși aplicabile șoferilor care efectuează transporturi internaționale pe vehicule 
 înmatriculate într-un alt stat parte contractantă sau nu.</p><br>

<p style="font-weight:500;">ARTICOLUL 5 Echipaje</p>

<p style="text-indent:2em;"></p><h6>1. Vârsta minimă a șoferilor care efectuează transporturi de mărfuri este fixată:</h6><p></p>

<p style="text-indent:2em;">a) pentru vehiculele, inclusiv, dacă este cazul, remorcile sau semiremorcile, a căror greutate maximă admisă este inferioară
 sau egală cu 7,5 tone, la 18 ani împliniți;</p>

<p style="text-indent:2em;"></p><h6>b) pentru celelalte vehicule, la:</h6><p></p>

<p>21 ani împliniți</p><br>

<p>sau</p><br>

<p style="text-indent:2em;">18 ani împliniți, cu condiția ca persoana interesată să posede un carnet de conducere care să ateste pregătirea ca șofer pentru
 transporturile rutiere de mărfuri, recunoscut de către una dintre părțile contractante. Părțile contractante se vor informa
 asupra nivelului minim de pregătire cerut în țara lor și asupra altor condiții speciale care sunt aplicabile șoferilor pentru
 transporturi de mărfuri, în conformitate cu dispozițiile prezentului acord.</p><br>

<p style="font-weight:500;">2. Șoferii care efectuează transporturi de călători trebuie să fie în
 vârstă de cel puțin 21 de ani.</p>

<p style="text-indent:2em;"></p><h6>Șoferii care efectuează transporturi de călători pe rute care depășesc o rază de 50 de kilometri în jurul punctului de bază 
obișnuit al vehiculului trebuie să îndeplinească, de asemenea, una dintre condițiile următoare:</h6><p></p>

<p style="text-indent:2em;">a) să fi efectuat timp de un an, cel puțin, activitatea de șofer pentru transporturi de mărfuri, pe vehicule a căror greutate
 maximă admisă este mai mare de 3,5 tone;</p><br>

<p style="text-indent:2em;">b) să fi efectuat timp de un an, cel puțin, activitatea de șofer pentru transporturi de călători, pe rute care nu depășesc 
o rază de 50 de kilometri în jurul punctului de bază obișnuit al vehiculului sau pentru alte tipuri de transporturi de călători,
 care nu sunt supuse prezentului acord, atât timp cât autoritatea competentă consideră că ei au dobândit în acest fel experiența
 necesară;</p><br>

<p style="text-indent:2em;">c) să fie posesorii unui carnet de conducere care să ateste pregătirea ca șofer pentru transporturi rutiere de călători, 
recunoscut de una dintre părțile contractante.</p><br>

<p style="font-weight:500;">ARTICOLUL 6 Timpul de conducere a vehiculului</p><br>

<p style="text-indent:2em;">1. Durata totală de conducere a vehiculului cuprinsă între două repausuri zilnice sau între un repaus zilnic și un repaus săptămânal,
 numită în continuare perioada de conducere zilnică nu trebuie să depășească 9 ore. Poate să fie prelungită până la 10 ore, 
 de două ori pe săptămână.</p><br>

<p style="text-indent:2em;">După maximum 6 perioade de conducere zilnică, șoferul trebuie să ia un repaus săptămânal ca cel stabilit în paragraful 3
 al art. 8.</p><br>

<p style="text-indent:2em;">Perioada de repaus săptămânal poate fi mutată la sfârșitul celei de a șasea zi, dacă durata totală de conducere a vehiculului
 în cursul celor 6 zile nu depășește perioada maximă corespunzătoare celor
 șase perioade de conducere zilnică a vehiculului.</p><br>

<p style="text-indent:2em;">În cazul transporturilor internaționale de călători, altele decât serviciile regulate, cuvintele "șase" și "al șaselea",
 care figurează în al doilea și în al treilea alineat, sunt înlocuite cu "doisprezece" și, respectiv, cu "
 al doisprezecelea".</p><br>

<p>2. Durata totală de conducere nu trebuie să depășească 90 de ore pentru o perioadă de două săptămâni consecutive.</p><br>

<p style="font-weight:500;">ARTICOLUL 7 Întreruperi</p><br>

<p style="text-indent:2em;">1. După patru ore și jumătate de conducere a vehiculului, șoferul trebuie să facă o pauză de cel puțin 45 de minute, cu
 excepția cazului când el intră în perioada de repaus.</p><br>

<p style="text-indent:2em;">2. Această pauză poate fi înlocuită prin pauze de cel puțin 15 minute fiecare, intercalate în timpul când se conduce 
vehiculul sau imediat după această perioadă, astfel încât să se respecte dispozițiile paragrafului 1.</p><br>

<p style="text-indent:2em;">3. În timpul acestor pauze șoferul nu poate efectua alte activități. Conform prezentului articol, timpul de așteptare și 
timpul care nu este afectat conducerii vehiculului, petrecuți într-un vehicul în mers, în feribot sau în tren, nu sunt 
considerați ca alte activități.</p><br>

<p>4. Întreruperile menționate în prezentul articol nu pot fi considerate ca repausuri zilnice.</p><br>

<p style="font-weight:500;">ARTICOLUL 8 Timp de repaus</p><br>

<p style="text-indent:2em;">1. În fiecare perioadă de 24 de ore, șoferul beneficiază de un timp de repaus zilnic, de cel puțin 11 ore consecutive, care
 ar putea fi redus la un minimum de 9 ore consecutive, cel mult de 3 ori pe săptămână, cu condiția ca un timp de repaus 
 corespunzător să fie acordat prin compensare, înainte de sfârșitul săptămânii următoare.</p><br>

<p style="text-indent:2em;">În zilele în care perioada de repaus nu este redusă conform primului alineat, repausul poate fi luat în două sau trei perioade
 separate în timpul perioadei de 24 de ore, una dintre aceste perioade trebuind să fie de cel puțin 8 ore consecutive. În acest
 caz durata minimă de repaus ajunge până la 12 ore.</p><br>

<p style="text-indent:2em;">2. În timpul fiecărei perioade de 30 de ore, în care există cel puțin doi șoferi la bordul unui vehicul, aceștia trebuie să 
beneficieze, fiecare, de un repaus zilnic, de cel puțin 8 ore consecutive.</p><br>

<p style="text-indent:2em;">3. În cursul fiecărei săptămâni, una dintre perioadele de repaus menționate în paragrafele 1 și 2 atinge, cu titlu de repaus 
săptămânal, un total de 45 de ore consecutive. Această perioadă de repaus poate fi redusă la un minimum de 36 de ore consecutive,
 dacă ea este luată la punctul de bază obișnuit al vehiculului sau al șoferului sau la un minimum de 24 de ore consecutive, dacă 
 ea este luată în afara acestor puncte. Fiecare scurtare este compensată printr-un timp de repaus echivalent, luat în bloc înainte
 de sfârșitul celei de-a treia săptămâni care urmează săptămânii respective.</p><br>

<p style="text-indent:2em;">4. O perioadă de repaus săptămânal care începe într-o săptămână și se prelungește în următoarea poate fi legată la una sau alta 
din aceste săptămâni.</p><br>

<p style="text-indent:2em;">5. În cazul transporturilor de călători, cărora li se aplică paragraful 1 alin. 4 al art. 6, o perioadă de repaus săptămânal 
poate fi mutată în săptămâna care urmează celei la care se referă perioada de repaus și adăugată repausului săptămânal al acestei
 de-a doua săptămâni.</p><br>

<p style="text-indent:2em;">6. Orice timp de repaus luat în compensare pentru reducerea perioadelor de repaus zilnic și/sau săptămânal trebuie să fie legat 
de un alt repaus, de cel puțin 8 ore, și trebuie să fie acordat, la cererea persoanei interesate, la locul de staționare a 
vehiculului sau la punctul de bază al șoferului.</p><br>

<p>7. Pauza zilnică poate fi luată într-un vehicul, atunci când acesta este echipat
 cu o cușetă și este în staționare.</p>

<p style="text-indent:2em;"></p><h6>8. Prin derogare de la dispozițiile paragrafului 1 mai sus menționat, în cazul în care, în domeniul transporturilor de mărfuri 
sau de pasageri, un șofer însoțește un vehicul transportat cu feribotul sau cu trenul, repausul zilnic poate fi întrerupt o 
singură dată, atât timp cât sunt îndeplinite condițiile următoare:</h6><p></p>

<p style="text-indent:2em;">partea de repaus zilnic luată pe jos trebuie să se situeze înainte sau după partea repausului zilnic luată la bordul 
feribotului sau în tren;</p><br>

<p style="text-indent:2em;">perioada între cele două părți ale repausului zilnic trebuie să fie cât se poate de scurtă și nu poate, în nici un caz, să 
depășească o oră înainte de îmbarcare sau după debarcare, formalitățile vamale fiind cuprinse în operațiunile de îmbarcare 
sau de debarcare;</p><br>

<p>în timpul celor două părți ale repausului zilnic, șoferul trebuie să aibă
 la dispoziție un pat sau o cușetă.</p><br>

<p>Repausul zilnic, astfel întrerupt, crește cu 2 ore.</p><br>

<p style="font-weight:500;">ARTICOLUL 9 Derogări</p><br>

<p style="text-indent:2em;">Cu condiția să nu se prejudicieze siguranța rutieră și pentru a se da posibilitatea șoferului să atingă un punct de oprire corespunzător, 
șoferul poate să se abată de la prezentul acord atât cât este necesar pentru a asigura securitatea pasagerilor, vehiculului sau încărcăturii
 sale. Șoferul trebuie să menționeze natura și motivul abaterii de la acord pe foaia de înregistrare a aparatului de control sau în registrul
 său de serviciu.</p><br>

<p style="font-weight:500;">ARTICOLUL 10 Aparatul de control</p>

<p style="text-indent:2em;"></p><h6>1. Părțile contractante vor trebui să prescrie instalarea și folosirea pe vehiculele înmatriculate pe teritoriile lor a unui aparat de 
control, în conformitate cu prescripțiile următoare:</h6><p></p>

<p style="text-indent:2em;">a) aparatul de control, în sensul prezentului acord, trebuie să corespundă, în ceea ce privește condițiile sale de construcție, de instalare,
 de folosire și de control, dispozițiilor prezentului acord, inclusiv anexei sale;</p><br>

<p style="text-indent:2em;">b) dacă nu se poate folosi în mod normal și într-un mod adecvat un aparat de control instalat pe un vehicul, fiecare membru al echipajului 
trebuie să scrie de mână, pe foaia de parcurs, folosind simboluri grafice adecvate, indicațiile privind etapele sale de activitate profesională
 și de repaus;</p><br>

<p style="text-indent:2em;">c) dacă, datorită depărtării de vehicul, membrii echipajului nu au fost în măsură să folosească aparatul, ei trebuie să adauge de mână, 
folosind simboluri grafice adecvate, pe foaia de înregistrare, diverșii timpi corespunzători activităților lor profesionale în perioada 
când ei nu erau în vehicul;</p><br>

<p style="text-indent:2em;">d) membrii echipajului trebuie să aibă mereu la ei și să poată prezenta la control foile de înregistrare pentru săptămâna în curs și ale 
ultimei zile a săptămânii precedente, în cursul căreia ei au condus;</p><br>

<p style="text-indent:2em;">e) membrii echipajului trebuie să fie atenți ca aparatul de control să fie pus în funcțiune și manipulat corect și ca, în caz de defectare, 
el să fie reparat cât mai repede posibil.</p><br>

<p style="text-indent:2em;">2. Proprietarul eliberează șoferilor un număr suficient de foi de înregistrare, ținând cont de caracterul individual al acestor foi, de durata 
serviciului și de obligația de a înlocui eventual foile stricate sau reținute de un agent de control. Proprietarul nu înmânează șoferilor decât 
foi de un model omologat, corespunzătoare, pentru a fi folosite în aparatul instalat la bordul vehiculului.</p><br>

<p style="text-indent:2em;">3. Întreprinderile vor păstra foile de înregistrare completate conform dispozițiilor de la alin. b), c), d) ale paragrafului 1 din prezentul 
articol, pe o perioadă de cel puțin 12 luni după data ultimei înscrieri, și le vor
 prezenta, la cerere, agenților de control.</p><br>

<p style="font-weight:500;">ARTICOLUL 11 Controale efectuate de întreprindere</p><br>

<p style="text-indent:2em;">1. Întreprinderea trebuie să organizeze serviciul de transport rutier, astfel încât membrii echipajului să fie în măsură să respecte 
dispozițiile prezentului acord.</p><br>

<p style="text-indent:2em;">2. Întreprinderea trebuie să supravegheze în mod regulat perioadele de conducere a vehiculului și perioadele altor activități, la fel ca 
și orele de repaus, folosind toate documentele de care ea dispune, ca de exemplu, carnetele individuale de control. Dacă întreprinderea 
constată abateri de la prezentul acord, ea trebuie să le facă să înceteze imediat și să ia măsuri pentru a evita repetarea lor, ca de 
exemplu, modificând orarele și itinerarele.</p><br>

<p style="text-indent:2em;">3. Este interzisă remunerarea șoferilor salariați, chiar prin acordarea de prime sau prin majorarea salariilor, în funcție de distanțele 
parcurse și/sau de volumul de mărfuri transportate, în condițiile în care aceste remunerații sunt de natură a compromite securitatea 
rutieră.</p><br>

<p style="font-weight:500;">ARTICOLUL 12 Măsuri pentru a asigura punerea în aplicare a acordului</p><br>

<p style="text-indent:2em;">1. Fiecare parte contractantă va lua toate măsurile corespunzătoare pentru a se respecta dispozițiile prezentului acord, în special prin 
controale la un nivel adecvat, efectuate pe drumuri și în întreprinderi. Autoritățile competente ale părților contractante vor fi informate 
asupra măsurilor generale luate în acest scop.</p><br>

<p>2. Părțile contractante își acordă reciproc asistență pentru aplicarea prezentului acord și pentru controlul acesteia.</p>

<p style="text-indent:2em;"></p><h6>3. În cadrul acestei asistențe reciproce, autoritățile competente ale părților contractante își comunică, în mod regulat, toate informațiile 
disponibile privind:</h6><p></p>

<p> abaterile de la prezentul acord comise de cei care nu sunt rezidenți și orice sancțiune
 aplicată pentru astfel de abateri;</p><br>

<p style="text-indent:2em;">sancțiunile aplicate de către o parte contractantă rezidenților săi, pentru astfel
 de abateri comise pe teritoriul altor părți contractante.</p><br>

<p>În cazul abaterilor grave, această informare trebuie să conțină și sancțiunile aplicate.</p><br>

<p style="text-indent:2em;">4. Dacă, atunci când se face un control pe ruta unui șofer al unui vehicul înmatriculat pe teritoriul unei alte părți contractante, constatările
 efectuate stabilesc că au fost comise abateri care nu pot fi dovedite în cursul acestui control, ca urmare a lipsei elementelor necesare, 
 autoritățile competente ale părților contractante implicate își acordă reciproc asistență, în vederea clarificării situației. În acest scop, 
 în cazul în care partea contractantă competentă procedează la un control în întreprinderi, rezultatele acestui control sunt aduse la cunoștință 
 celeilalte părți contractante interesate.</p><br>

<p style="font-weight:500;">ARTICOLUL 13 Dispoziții tranzitorii</p><br>

<p style="text-indent:2em;">Dispozițiile noului articol 10 - aparat de control - nu vor deveni obligatorii pentru țările părți contractante la acest acord înainte de 3 ani 
după intrarea în vigoare a acestor amendamente. Înaintea acestei date, dispozițiile fostului articol 12 - Carnet individual de control - rămân 
valabile.</p><br>

<p style="font-weight:500;">ARTICOLUL 14 Dispoziții finale</p><br>

<p style="text-indent:2em;">1. Prezentul acord este deschis pentru semnare până la 31 martie 1971 și, după această dată, este deschis aderării statelor membre ale Comisiei 
economice pentru Europa și a statelor admise la comisie cu titlu consultativ
, conform paragrafului 8 din mandatul acestei comisii.</p><br>

<p>2. Prezentul acord va fi ratificat.</p><br>

<p>3. Instrumentele de ratificare sau de aderare vor fi depuse la secretarul general al Organizației Națiunilor Unite.</p><br>

<p>4. Prezentul acord va intra în vigoare în a 180-a zi de la depunerea celui de-al 8-lea instrument de ratificare sau de aderare.</p><br>

<p style="text-indent:2em;">5. Pentru fiecare stat care va ratifica prezentul acord sau va adera la el, după depunerea celui de-al 8-lea instrument de ratificare sau de 
aderare precizat în paragraful 4 al prezentului articol, prezentul acord va intra în vigoare după 180 de zile de la data depunerii de către acest 
stat a instrumentului său de ratificare sau de aderare.</p><br>

<p style="font-weight:500;">ARTICOLUL 15</p><br>

<p style="text-indent:2em;">1. Orice parte contractantă va putea denunța prezentul acord printr-o comunicare adresată secretarului general al Organizației Națiunilor 
Unite.</p><br>

<p style="text-indent:2em;">2. Denunțarea contractului va intra în vigoare după 6 luni de la data de
 la care secretarul general va fi înștiințat despre aceasta.</p><br>

<p style="font-weight:500;">ARTICOLUL 16</p><br>

<p style="text-indent:2em;">Prezentul acord va înceta să-și exercite dispozițiile sale, dacă, după intrarea sa în vigoare, numărul părților contractante este mai mic de 
trei timp de o perioadă oarecare de 12 luni consecutive.</p><br>

<p style="font-weight:500;">ARTICOLUL 17</p><br>

<p style="text-indent:2em;">1. Orice stat va putea, atunci când el va semna prezentul acord sau cu ocazia depunerii instrumentului său de ratificare sau de aderare, sau 
oricând ulterior, să declare, prin notificare adresată secretarului general al Organizației Națiunilor Unite, că valabilitatea prezentului acord 
va acoperi, în totalitate sau parțial, teritoriile pe care el le reprezintă pe plan internațional. Prezentul acord se va aplica pe teritoriul sau 
pe teritoriile menționate în notificare, începând cu cea de-a 180-a zi din momentul primirii acestei notificări de către secretarul general sau, 
dacă în acea zi prezentul acord nu a intrat încă în vigoare, începând cu ziua intrării sale în vigoare.</p><br>

<p style="text-indent:2em;">2. Orice stat care va face, conform paragrafului precedent, o declarație având drept scop punerea în aplicare a prezentului acord pe un teritoriu pe 
care el îl reprezintă pe plan internațional, va putea, conform art. 15 din prezentul acord, să denunțe prezentul acord în ceea ce privește teritoriul 
respectiv.</p><br>

<p style="font-weight:500;">ARTICOLUL 18</p><br>

<p style="text-indent:2em;">1. Orice dezacord între două sau mai multe părți contractante privind interpretarea sau punerea în aplicare a prezentului acord va fi, pe cât posibil, 
rezolvat prin negocieri între părțile aflate în litigiu.</p><br>

<p style="text-indent:2em;">2. Orice dezacord, care nu va fi rezolvat prin negocieri, va fi supus arbitrajului, dacă vreuna dintre părțile contractante aflate în litigiu cere acest 
lucru, și va fi, în consecință, înaintat unuia sau mai multor arbitri aleși de comun acord de către părțile aflate în litigiu. Dacă, după 3 luni de la 
data cererii de arbitraj, părțile în litigiu nu ajung la o înțelegere în ceea ce privește alegerea arbitrului sau arbitrilor, oricare dintre aceste 
părți va putea cere secretarului general al Organizației Națiunilor Unite să desemneze un arbitru unic, căruia i se va prezenta pentru rezolvare 
diferendul.</p><br>

<p style="text-indent:2em;">3. Decizia arbitrului sau arbitrilor desemnați conform paragrafului precedent va fi
 obligatorie pentru părțile contractante aflate în litigiu.</p><br>

<p style="font-weight:500;">ARTICOLUL 19</p><br>

<p style="text-indent:2em;">1. Orice stat va putea, în momentul când va semna sau va ratifica prezentul acord, sau va adera la el, să declare că el nu se consideră legat prin 
paragrafele 2 și 3 ale art. 18 din prezentul acord. Celelalte părți contractante nu vor fi legate de aceste paragrafe față de oricare parte contractantă 
care va formula o astfel de rezervă.</p><br>

<p style="text-indent:2em;">2. Dacă la data depunerii instrumentelor sale de ratificare sau de aderare, un stat formulează o rezervă, alta decât cea prevăzută la paragraful 1 al 
prezentului articol, secretarul general al O.N.U. va comunica această rezervă statelor care au depus deja instrumentele de ratificare sau aderare și care
 nu au denunțat ulterior prezentul acord. Rezerva se va considera acceptată, dacă, într-un termen de 6 luni de la data acestei comunicări, nici unul dintre
 aceste state nu se opune aprobării sale. În caz contrar, rezerva nu va fi admisă și, dacă statul care a formulat-o nu o retrage, depunerea instrumentelor 
 de aderare sau ratificare a acestui stat va fi fără efect. Pentru aplicarea prezentului paragraf nu se va ține seama de opoziția statelor a căror aderare 
 sau ratificare este fără efect, în virtutea acestui paragraf, datorită faptului
 că rezervele introduse de ele nu vor fi luate în considerare.</p><br>

<p style="text-indent:2em;">3. Orice parte contractantă a cărei rezervă va fi adoptată în protocolul de semnare a prezentului acord sau care va formula o rezervă conform paragrafului 
1 din prezentul articol sau care va exprima o rezervă acceptată conform paragrafului 2 al prezentului articol va putea, în orice moment, să renunțe la 
această rezervă printr-o notificare adresată secretarului general.</p><br>

<p style="font-weight:500;">ARTICOLUL 20</p><br>

<p style="text-indent:2em;">1. După ce prezentul acord va fi în vigoare timp de 3 ani, orice parte contractantă va putea cere convocarea unei conferințe, în scopul revizuirii prezentului
 acord, printr-o notă adresată secretarului general al O.N.U. Secretarul general va notifica această cerere tuturor părților contractante și va convoca o 
 conferință de revizuire, dacă, într-un interval de patru luni de la data notificării secretarului general, cel puțin o treime dintre părțile contractante 
 comunică acordul lor în legătură cu această cerere.</p><br>

<p style="text-indent:2em;">2. Dacă este convocată o conferință conform paragrafului precedent, secretarul general va anunța toate părțile contractante și le va invita să prezinte, 
într-un termen de 3 luni, propunerile pe care ele doresc să fie examinate în cadrul conferinței. Secretarul general va comunica tuturor părților contractante
 ordinea de zi provizorie a conferinței, precum și textul acestor propuneri,
 cu cel puțin 3 luni înainte de data deschiderii conferinței.</p><br>

<p style="text-indent:2em;">3. Secretarul general va invita la orice conferință convocată conform prezentului articol toate statele la care se referă paragraful 1 al art. 14 din 
prezentul acord.</p><br>

<p style="font-weight:500;">ARTICOLUL 21</p>

<p style="text-indent:2em;"></p><h6>1. Orice parte contract:</h6><p></p>

<p style="text-indent:2em;">b) fie că, cu toate că intenționează să accepte proiectul, condițiile
 necesare pentru această acceptare nu sunt încă îndeplinite în statul său.</p><br>

<p style="text-indent:2em;">3. Dacă o parte contractantă, care a înaintat secretarului general o comunicare, așa cum este prevăzut în paragraful 2 b) din prezentul articol, nu a 
comunicat secretarului general acceptul său, ea va putea, într-un interval de 9 luni, începând de la data expirării perioadei de 6 luni prevăzută pentru 
comunicare, să prezinte o obiecție la amendamentul propus.</p><br>

<p style="text-indent:2em;">4. Dacă se face vreo obiecție la proiectul de amendament în condițiile prevăzute la paragrafele 2 și 3 ale prezentului articol, amendamentul va fi 
considerat ca nefiind acceptat și va fi fără efect.</p>

<p style="text-indent:2em;"></p><h6>5. Dacă nu a fost formulată nici o obiecție la proiectul de amendament în condițiile prevăzute la paragrafele 2 și 3 ale prezentului articol, 
amendamentul va fi considerat acceptat începând de la data:</h6><p></p>

<p style="text-indent:2em;">a) expirării termenului de 6 luni prevăzut la paragraful 2 din prezentul articol, dacă nici o parte contractantă nu a înaintat o comunicare 
conform paragrafului 2 b) al acestui articol;</p>

<p style="text-indent:2em;"></p><h6>b) cea mai apropiată dintre următoarele două date, dacă, cel puțin o parte contractantă a adresat o comunicare, așa cum prevede paragraful 
2 b) al prezentului articol:</h6><p></p>

<p style="text-indent:2em;">data la care toate părțile contractante care au trimis o asemenea comunicare au notificat secretarului general acceptarea proiectului, această 
dată fiind totuși amânată până la expirarea termenului de 6 luni menționat în paragraful 2 al prezentului articol, dacă toate acordurile au fost 
comunicate înaintea acestei date de expirare;</p><br>

<p>data expirării termenului de 9 luni menționat în paragraful 3 al prezentului articol.</p><br>

<p>6. Orice amendament considerat acceptat va intra în vigoare după trei luni de la data la care a fost considerat acceptat.</p><br>

<p style="text-indent:2em;">7. Secretarul general va adresa, cât mai curând posibil, tuturor părților contractante o notificare pentru a le înștiința dacă a fost formulată 
vreo obiecție în legătură cu proiectul de amendament conform cu paragraful 2 a) al prezentului articol și dacă una sau mai multe părți contractante 
i-au adresat o comunicare conform cu paragraful 2 b) al prezentului articol. În cazul în care una sau mai multe părți contractante au adresat o 
asemenea notificare, el va anunța ulterior toate părțile contractante dacă partea sau părțile contractante care au adresat o asemenea comunicare 
ridică vreo obiecție față de proiectul de amendament sau dacă îl acceptă.</p><br>

<p style="text-indent:2em;">8. Independent de procedura de amendare prevăzută la paragrafele 1-6 ale prezentului articol, anexa prezentului acord va putea fi modificată prin 
înțelegere între autoritățile competente ale tuturor părților contractante; dacă autoritatea competentă a uneia dintre părțile contractante a 
declarat că legislația sa națională o obligă să supună acordul său obținerii unei autorizări speciale în acest scop sau aprobării unui organ 
legislativ, consimțământul autorității competente al părții contractante în cauză, în legătură cu modificările anexei, nu va fi considerat ca 
dat decât în momentul în care această autoritate competentă va declara secretarului general că autorizările sau aprobările necesare au fost obținute. 
Autoritățile competente, de comun acord, vor fixa data intrării în vigoare a anexei modificate și vor putea prevedea ca, pe parcursul unei perioade 
tranzitorii, fosta anexă să rămână în vigoare, în totalitate sau parțial, simultan cu anexa modificată.</p><br>

<p style="font-weight:500;">ARTICOLUL 22</p><br>

<p>1. Apendicele 1 și 2 la anexa prezentului acord vor putea fi amendate conform procedurii stabilite în prezentul articol.</p><br>

<p style="text-indent:2em;">2. La cererea unei părți contractante, orice amendament la apendicele 1 și 2 la anexa prezentului acord propus de către această parte va fi 
examinat de grupa de lucru principală a transporturilor rutiere a Comisiei economice pentru Europa.</p><br>

<p style="text-indent:2em;">3. Dacă el este adoptat cu majoritatea membrilor prezenți și votanți și dacă această majoritate conține majoritatea părților contractante prezente 
și votante, amendamentul va fi comunicat de către secretarul general pentru acordul
 autorităților competente ale tuturor părților contractante.</p><br>

<p style="text-indent:2em;">4. Amendamentul va fi acceptat, dacă, într-un termen de 6 luni de la data acestei comunicări, mai puțin de o treime dintre autoritățile competente 
ale părților contractante fac cunoscută secretarului general obiecția lor la amendament.</p><br>

<p style="text-indent:2em;">5. Orice amendament acceptat va fi comunicat de către secretarul general tuturor părților contractante și va intra în vigoare după 3 luni de 
la data acestei comunicări.</p><br>

<p style="font-weight:500;">ARTICOLUL 23</p>

<p style="text-indent:2em;"></p><h6>În afară de notificările prevăzute la art. 20 și 21 ale prezentului acord, secretarul general al O.N.U. va anunța statele menționate la 
paragraful 1 al art. 14 al prezentului acord în legătură cu:</h6><p></p>

<p>a) ratificările și adeziunile conform art. 14 al prezentului acord;</p><br>

<p>b) datele la care prezentul acord va intra în vigoare, conform art. 14 al prezentului acord;</p><br>

<p>c) denunțările conform art. 15 din prezentul acord;</p><br>

<p>d) abrogarea prezentului acord conform art. 16 al prezentului acord;</p><br>

<p>e) notificările primite conform art. 17 al prezentului acord;</p><br>

<p>f) declarațiile și notificările primite conform art. 19 al prezentului acord;</p><br>

<p>g) intrarea în vigoare a oricărui amendament conform art. 21 al prezentului acord.</p><br>

<p style="font-weight:500;">ARTICOLUL 24</p><br>

<p style="text-indent:2em;">Protocolul de semnare a prezentului acord are aceeași valabilitate, valoare și durată ca însuși acordul din care este considerat ca făcând
 parte integrantă.</p><br>

<p style="font-weight:500;">ARTICOLUL 25</p><br>

<p style="text-indent:2em;">După data de 31 martie 1971, originalul prezentului acord va fi depus la secretarul general al O.N.U., care va transmite copii certificate conforme
 fiecăruia dintre statele menționate la paragraful 1 al art. 14 din prezentul acord.</p><br>

<p style="text-indent:2em;">Drept pentru care, subsemnații, având depline puteri, au semnat prezentul acord.</p><br>

<p style="text-indent:2em;">Încheiat la Geneva la data de 1 iulie 1970, într-un singur exemplar, în limbile engleză și
 franceză, ambele texte având aceeași valabilitate.</p>

<p></p><h4>ANEXĂ</h4><p></p>

<p style="font-weight:500;">APARAT DE CONTROL</p><br>

<p style="font-weight:500;">DISPOZIȚII GENERALE</p><br>

<p style="font-weight:500;">I. Omologare</p><br>

<p style="font-weight:500;">ARTICOLUL 1</p><br>

<p style="text-indent:2em;">Orice cerere de omologare pentru un model de aparat de control sau de foaie de înregistrare, însoțită de documente descriptive corespunzătoare, este
 înaintată de către fabricant sau de către împuternicitul său unei părți contractante. Pentru un același model de aparat de control sau de foaie de
 înregistrare, această cerere nu poate fi înaintată decât unei singure părți contractante.</p><br>

<p style="font-weight:500;">ARTICOLUL 2</p><br>

<p style="text-indent:2em;">Fiecare parte contractantă aprobă omologarea oricărui model de aparat de control sau oricărui model de foaie de înregistrare, dacă acestea sunt conforme
 prevederilor apendicelui 1 și dacă partea contractantă este în măsură să supravegheze producția
 pentru a corespunde modelului omologat.</p><br>

<p style="text-indent:2em;">Modificările sau completările la un model omologat trebuie să constituie obiectul unei omologări suplimentare din partea părții contractante care a 
aprobat omologarea inițială.</p><br>

<p style="font-weight:500;">ARTICOLUL 3</p><br>

<p style="text-indent:2em;">Părțile contractante atribuie solicitantului o marcă de omologare corespunzătoare modelului stabilit la apendicele 2, pentru fiecare model de aparat de
 control sau de foaie de înregistrare, pe care ele le omologhează în virtutea art. 2.</p><br>

<p style="font-weight:500;">ARTICOLUL 4</p><br>

<p style="text-indent:2em;">Autoritățile competente ale părții contractante căreia i-a fost înaintată cererea de omologare trimit celorlalte părți contractante, într-un termen de o 
lună, o copie a fișei de omologare însoțită de o copie a documentelor descriptive necesare sau le comunică refuzul omologării, pentru fiecare model de 
aparat de control sau de foaie de înregistrare pe care ele le omologhează sau le refuză; în caz 
de refuz ele comunică motivarea deciziei.</p><br>

<p style="font-weight:500;">ARTICOLUL 5</p><br>

<p style="text-indent:2em;">1. Dacă partea contractantă care a făcut omologarea menționată la art. 2 constată că aparatele de control sau foile de înregistrare care poartă marca 
de omologare pe care ea le-a atribuit-o nu sunt conforme cu modelul pe care ea l-a omologat, aceasta ia măsurile necesare pentru ca producția să 
corespundă modelului. Dacă este cazul, măsurile luate pot merge până la retragerea omologării.</p><br>

<p style="text-indent:2em;">2. Partea contractantă, care a aprobat o omologare, trebuie să o revoce, dacă aparatul de control sau foaia de înregistrare care constituie obiectul 
omologării este considerată ca nefiind conformă cu prezenta anexă, inclusiv cu apendicele sale, sau prezintă, la folosire, un defect de ordin general 
care le face improprii destinației lor.</p><br>

<p style="text-indent:2em;">3. Dacă partea contractantă, care a aprobat o omologare, este informată de către altă parte contractantă despre existența unuia dintre cazurile 
menționate în paragrafele 1 și 2, aceasta ia, de asemenea, după consultarea acesteia din urmă, măsurile prevăzute în paragrafele menționate, sub 
rezerva paragrafului 5.</p><br>

<p style="text-indent:2em;">4. Partea contractantă, care a constatat existența unuia dintre cazurile prevăzute la paragraful 2, poate să suspende până la un nou ordin lansarea pe 
piață și punerea în funcțiune a aparatelor de control sau a foilor. Același lucru se întâmplă în cazurile prevăzute la paragraful 1 pentru aparatele de 
control sau pentru foile scutite de verificarea primară, dacă fabricantul, după avertizare, nu le face conform modelului aprobat sau cerințelor prezentei 
anexe.</p><br>

<p style="text-indent:2em;">În orice caz, autoritățile competente ale părților contractante se informează reciproc într-un termen de o lună asupra retragerii unei omologări 
acordate și asupra altor măsuri luate în conformitate cu paragrafele 1, 2, 3, la fel ca și
 asupra motivelor care justifică aceste măsuri.</p><br>

<p style="text-indent:2em;">5. Dacă partea contractantă, care a aprobat o omologare, contestă existența cazurilor prevăzute la paragrafele 1 și 2, despre care ea a fost
 informată, părțile contractante interesate se ocupă de rezolvarea diferendului.</p><br>

<p style="font-weight:500;">ARTICOLUL 6</p><br>

<p style="text-indent:2em;">1. Cel care solicită omologarea pentru un model de foaie de înregistrare trebuie să precizeze pe cererea sa modelul sau modelele de aparate de
 control cărora le este destinată pentru folosire această foaie și trebuie să furnizeze un aparat adecvat pentru tipul sau tipurile respective,
 în scopul testării foii de înregistrare.</p><br>

<p style="text-indent:2em;">2. Autoritățile competente ale fiecărei părți contractante indică pe fișa de omologare a modelului foii de înregistrare modelul sau modelele de
 aparate de control pe care se poate folosi modelul foii de înregistrare.</p><br>

<p style="font-weight:500;">ARTICOLUL 7</p><br>

<p style="text-indent:2em;">Părțile contractante nu pot să refuze înmatricularea sau să interzică punerea în circulație sau folosirea vehiculelor echipate cu aparat de control,
 datorită unor cauze inerente unui astfel de aparat, dacă aparatul are marca de omologare menționată la art. 3 și placa de instalare menționată la art
 . 9.</p><br>

<p style="font-weight:500;">ARTICOLUL 8</p><br>

<p style="text-indent:2em;">Orice decizie, care se referă la refuzul sau la retragerea omologării unui model de aparat de control sau de foaie de înregistrare, luată în virtutea
 prezentei anexe, este motivată într-un mod precis. Ea este adusă la cunoștință celui interesat, indicându-se căile de a face recurs, stabilite de către
 legislația în vigoare a părților contractante, și termenele în care aceste recursuri pot fi înaintate.</p><br>

<p style="font-weight:500;">II. Instalare și control</p><br>

<p style="font-weight:500;">ARTICOLUL 9</p><br>

<p style="text-indent:2em;">1. Sunt autorizați să efectueze operațiunile de instalare și de reparare ale aparatului de control doar instalatorii sau atelierele autorizate în
 acest scop de către autoritățile competente ale părților contractante, după ce acestea au ținut seama, dacă ele doresc acest lucru, de părerea
 fabricanților interesați.</p><br>

<p style="text-indent:2em;">2. Cel care instalează sau atelierul autorizat aplică un însemn special pe sigiliul pus. Autoritățile competente ale fiecărei părți contractante
 țin o evidență a tuturor însemnelor folosite.</p><br>

<p style="text-indent:2em;">3. Autoritățile competente ale părților contractante se informează reciproc asupra listei celor care fac instalarea sau asupra atelierelor autorizate
 și își transmit o copie a însemnelor folosite.</p><br>

<p style="text-indent:2em;">4. Instalarea aparatului de control conform dispozițiilor din prezenta anexă este atestată prin placa de instalare aplicată conform condițiilor
 prevăzute la apendicele 1.</p><br>

<p style="font-weight:500;">III. Mod de folosire</p><br>

<p style="font-weight:500;">ARTICOLUL 10</p><br>

<p>Proprietarul și șoferii iau măsuri pentru a asigura o bună funcționare și o corectă întrebuințare a aparatului.</p><br>

<p style="font-weight:500;">ARTICOLUL 11</p><br>

<p style="text-indent:2em;">1. Șoferii nu utilizează foi de înregistrare murdare sau deteriorate. În acest 
scop, foile trebuie să fie protejate în mod corespunzător.</p><br>

<p style="text-indent:2em;">În cazul deteriorării unei foi care conține înregistrări, șoferii trebuie să anexeze foaia deteriorată la foaia de rezervă folosită pentru a
 o înlocui.</p><br>

2. Șoferii folosesc foile de înregistrare în fiecare zi în care ei conduc, din momentul în care ei iau în primire vehiculul. Foaia de înregistrare
 nu este retrasă înainte de sfârșitul perioadei de lucru zilnice, doar dacă retragerea sa nu este permisă în alt mod.<p></p><br>

<p>Nici o foaie de înregistrare nu poate fi folosită pe o perioadă mai lungă decât aceea pentru care ea a fost destinată.</p><br>

<p style="text-indent:2em;">Când, ca urmare a faptului că nu sunt în apropierea vehiculului, șoferii nu pot folosi aparatul montat pe vehicul, perioadele de timp sunt înscrise
 în mod lizibil și fără ștersături, pe foi, de mână, automat sau prin alte mijloace.</p><br>

<p style="text-indent:2em;">Ei trec pe foile de înregistrare modificările necesare, atunci când la bordul unui vehicul se află mai mult de un șofer, astfel încât informațiile
 menționate la apendicele 1, rubrica II, de la pct. 1-3, să fie înregistrate pe foaia șoferului care conduce efectiv.</p><br>

<p style="text-indent:2em;">Aparatul trebuie să fie conceput astfel încât să permită agenților însărcinați cu controlul să citească, după deschiderea eventuală a aparatului,
 fără să se deformeze definitiv, să se strice sau să se murdărească foaia, înregistrările făcute în
 cele 9 ore dinaintea controlului.</p><br>

<p style="text-indent:2em;">În plus, aparatul trebuie să fie astfel conceput încât să permită să se verifice dacă se
 fac înregistrările, fără a deschide cutia.</p><br>

<p style="text-indent:2em;">4. Șoferul trebuie să fie în măsură să prezinte, ori de câte ori agentul de control solicită aceasta, foile de înregistrare ale săptămânii în curs
 și, în orice caz, foaia ultimei zile pentru săptămâna precedentă, în cursul căreia el a condus.</p>

<p></p><h4>ANEXA-APENDICE Nr. 1</h4><p></p>

<p style="font-weight:500;">CONDIȚII</p><br>

<p>de construcție, de test, de instalare și de control</p><br>

<p style="font-weight:500;">I. Definiții</p>

<p style="text-indent:2em;"></p><h6>Prin termenii prezentului apendice se înțelege:</h6><p></p>

<p style="text-indent:2em;">a) prin aparat de control, un aparat destinat instalării la bordul vehiculelor rutiere pentru a indica și a înregistra, în mod automat sau semiautomat,
 datele privind mersul acestor vehicule și anumiți timpi de lucru ai șoferilor lor;</p><br>

<p style="text-indent:2em;">b) prin foaie de înregistrare, o foaie concepută pentru a primi și a păstra înregistrările, care se introduce în aparatul de control și pe care
 dispozitivele de înscriere ale acestuia înscriu în mod continuu diagramele datelor de înregistrat;</p><br>

<p style="text-indent:2em;">c) prin constanta aparatului de control, caracteristica numerică, care dă valoarea semnalului de intrare necesară pentru a obține indicarea și
 înregistrarea unei distanțe de 1 kilometru parcurse; această constantă trebuie să fie exprimată fie în turații pe kilometru (K = ... tr/km),
 fie în impulsuri pe kilometru (K = ... imp/km);</p><br>

<p style="text-indent:2em;">d) prin coeficient specific al vehiculului, caracteristica numerică, care dă valoarea semnalului de ieșire emis de către piesa prevăzută pe vehicul,
 pentru racordarea sa la aparatul de control (priza de ieșire a cutiei de viteze în anumite cazuri, roata vehiculului în alte cazuri), când vehiculul
 parcurge distanța de un kilometru măsurată în condiții normale de test (a se vedea capitolul VI 
 paragraful 4 al prezentului apendice).</p><br>

<p style="text-indent:2em;">Coeficientul caracteristic este exprimat fie în turații pe kilometru (W = ...
 tr/km), fie în impulsuri pe kilometru (W = ... imp/km);</p><br>

<p style="text-indent:2em;">e) prin circumferința efectivă a pneurilor roților, media distanțelor parcurse de fiecare dintre roțile care antrenează vehiculul (roți motrice),
 în timpul unei rotații complete. Măsurarea acestor distanțe trebuie să se facă în condiții normale de test (a se vedea capitolul VI paragraful 4
 al prezentului apendice) și este exprimată sub forma "l = ... mm".</p><br>

<p style="font-weight:500;">II. Caracteristicile generale și funcțiile aparatului de control</p>

<p style="text-indent:2em;"></p><h6>Aparatul trebuie să realizeze înregistrarea elementelor următoare:</h6><p></p>

<p>1) distanța parcursă de vehicul;</p><br>

<p>2) viteza vehiculului;</p><br>

<p>3) timpul de conducere;</p><br>

<p>4) alți timpi de lucru și timpi disponibili;</p><br>

<p>5) întreruperi de lucru și timpi de repaus zilnici;</p><br>

<p>6) deschiderea cutiei care conține foaia de înregistrare;</p><br>

<p style="text-indent:2em;">7) pentru aparatele electronice care funcționează pe bază de semnale transmise electric prin indicatorul de distanță și de viteză, orice întrerupere
 a alimentării aparatului mai mare de 100 milisecunde (cu excepția luminării), a alimentării indicatorului de distanță și de viteză și orice
 întrerupere a semnalului indicatorului de distanță și de viteză.</p><br>

<p style="text-indent:2em;">Pentru vehiculele folosite de doi șoferi aparatul trebuie să permită înregistrarea timpilor menționați la pct. 3, 4 și 5 în mod simultan și
 diferențiat pe două foi distincte.</p><br>

<p style="font-weight:500;">III. Condiții de construcție a aparatului de control</p><br>

<p style="font-weight:500;">A. Generalități</p>

<p style="text-indent:2em;"></p><h6>1. Pentru aparatul de control sunt prevăzute următoarele dispozitive:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) dispozitive indicatoare:</h6><p></p>

<p> pentru distanța parcursă (contor totalizator);</p><br>

<p>pentru viteză (tahometru);</p><br>

<p>pentru timp (ceas);</p>

<p style="text-indent:2em;"></p><h6>b) dispozitive de înregistrare care cuprind:</h6><p></p>

<p>un înregistrator pentru distanța parcursă;</p><br>

<p>un înregistrator pentru viteză;</p><br>

<p>unul sau mai multe înregistratoare de timp corespunzătoare condițiilor stabilite la rubrica III, secțiunea C paragraful 4;</p>

<p style="text-indent:2em;"></p><h6>c) un dispozitiv de marcare, care indică separat pe foaia de înregistrare:</h6><p></p>

<p>orice deschidere a cutiei care conține această foaie;</p><br>

<p style="text-indent:2em;"> pentru aparatele electronice, ca cele definite la pct. 7 al cap. II, orice întrerupere a alimentării aparatului mai mare de 100 milisecunde (cu
 excepția luminării), cel târziu în momentul când se face realimentarea;</p><br>

<p style="text-indent:2em;"> pentru aparatele electronice, ca cele definite la pct. 7 al cap. II, orice întrerupere de alimentare mai mare de 100 milisecunde, a indicatorului
 pentru distanță și viteză, și orice întrerupere a semnalului indicatorului de distanță și de viteză.</p><br>

<p style="text-indent:2em;">2. Prezența eventuală în aparat a altor dispozitive decât cele enumerate mai sus nu trebuie să compromită buna funcționare a dispozitivelor
 obligatorii și nici să împiedice citirea lor.</p><br>

<p>Aparatul trebuie să fie prezentat la omologare echipat cu aceste eventuale dispozitive complementare.</p>

<p style="text-indent:2em;"></p><h6>3. Materiale:</h6><p></p>

<p style="text-indent:2em;">a) Toate elementele constitutive ale aparatului de control trebuie să fie realizate din materiale cu o stabilitate și cu o rezistență mecanică
 corespunzătoare și cu caracteristici electrice și magnetice invariabile.</p><br>

<p style="text-indent:2em;">b) Orice schimbare a unui element al aparatului sau a felului materialelor folosite pentru fabricația sa trebuie să fie autorizată, înaintea folosirii,
 de către autoritatea care a omologat aparatul.</p><br>

<p style="font-weight:500;">4. Măsurarea distanței parcurse</p>

<p style="text-indent:2em;"></p><h6>Distanțele parcurse pot fi totalizate și înregistrate:</h6><p></p>

<p> fie în mers înainte și în mers înapoi;</p><br>

<p> fie doar în mers înainte.</p><br>

<p style="text-indent:2em;">Înregistrarea eventuală a manevrelor de mers înapoi nu trebuie neapărat să influențeze asupra clarității
 și preciziei celorlalte înregistrări.</p>

<p style="text-indent:2em;"></p><h6>5. Măsurarea vitezei:</h6><p></p>

<p>a) Plaja de măsură a vitezei este stabilită prin certificatul de omologare a modelului.</p><br>

<p style="text-indent:2em;">b) Frecvența proprie și dispozitivul de amortizare al mecanismului de măsură trebuie să fie astfel încât dispozitivele indicator și înregistrator
 de viteză să poată, în limita plajei de măsură, să ajungă până la o accelerare de 2
 m/s2, în limitele toleranțelor admise.</p>

<p style="text-indent:2em;"></p><h6>6. Măsurarea timpului (ceas):</h6><p></p>

<p style="text-indent:2em;">a) Comanda dispozitivului de potrivire la oră trebuie să se găsească în interiorul cutiei care conține foaia de înregistrare, a cărei fiecare
 deschidere este marcată automat pe foaia de înregistrare.</p><br>

<p style="text-indent:2em;">b) Dacă mecanismul de avansare a foii de înregistrare este comandat prin ceas, durata de funcționare corectă a acestuia, după întoarcerea completă,
 va trebui să fie superioară cu cel puțin 10% duratei de înregistrare corespunzătoare încărcăturii maxime în foile de înregistrare ale aparatului.</p>

<p style="text-indent:2em;"></p><h6>7. Luminare și protecție:</h6><p></p>

<p>a) Dispozitivele indicatoare ale aparatului trebuie să fie prevăzute cu o luminare adecvată, care să nu fie prea puternică.</p><br>

<p style="text-indent:2em;">b) Pentru condiții normale de folosire, toate părțile interne ale aparatului trebuie să fie protejate împotriva umidității și prafului. În plus,
 ele trebuie să fie protejate împotriva violării sigiliului.</p><br>

<p style="font-weight:500;">B. Dispozitive indicatoare</p>

<p style="text-indent:2em;"></p><h6>1. Indicatorul distanței parcurse (contor totalizator):</h6><p></p>

<p style="text-indent:2em;">a) Valoarea celei mai mici gradații a dispozitivului indicator al distanței parcurse trebuie să fie de 0,1 km. Cifrele care indică hectometrii
 trebuie să fie diferențiate în mod clar de acelea care indică numerele întregi de kilometri.</p><br>

<p>b) Cifrele contorului totalizator trebuie să fie clar lizibile și să aibă o înălțime aparentă de cel puțin 4 mm.</p><br>

<p>c) Contorul totalizator trebuie să aibă capacitatea să indice până la 99999,9 km, cel puțin.</p>

<p style="text-indent:2em;"></p><h6>2. Indicatorul de viteză (tahometru):</h6><p></p>

<p style="text-indent:2em;">a) Între limitele plajei de măsură, scala vitezei trebuie să fie gradată în mod uniform prin 1, 2, 5 sau 10 km/h. Valoarea în viteză a gradației
 (intervalul cuprins între două repere succesive) nu trebuie să depășească 10% din viteza maximă indicată pe scală.</p><br>

<p>b) Spațiul care depășește plaja de măsură nu trebuie să fie marcat prin cifre.</p><br>

<p style="text-indent:2em;">c) Lungimea fiecărei gradații de pe scală care reprezintă o diferență de viteză de 10 km/h nu trebuie să fie mai mică de 10 mm.</p><br>

<p>d) Pe un indicator cu ac, distanța între ac și cadran nu trebuie să depășească 3 mm.</p>

<p style="text-indent:2em;"></p><h6>3. Indicator de timp (ceas):</h6><p></p>

<p>Indicatorul de timp trebuie să fie vizibil din exteriorul aparatului și citirea acestuia trebuie
 să fie sigură, ușoară și clară.</p><br>

<p style="font-weight:500;">C. Dispozitive de înregistrare</p>

<p style="text-indent:2em;"></p><h6>1. Generalități:</h6><p></p>

<p style="text-indent:2em;">a) În orice aparat, oricare ar fi forma foii de înregistrare (bandă sau disc), trebuie prevăzut un reper care să permită o așezare corectă a foii 
de înregistrare, astfel încât ora indicată de ceas să corespundă cu ora înscrisă pe foaie.</p><br>

<p style="text-indent:2em;">b) Mecanismul care antrenează foaia de înregistrare trebuie să asigure antrenarea acesteia fără joc, introducerea și scoaterea ei în mod liber.</p><br>

<p style="text-indent:2em;">c) Dispozitivul de avansare a foii de înregistrare, când aceasta are forma unui disc, va fi comandat de mecanismul ceasului. În acest caz, mișcarea
 de rotație a foii va fi continuă și uniformă, cu o viteză minimă de 7 mm/h, măsurată pe partea interioară a inelului care delimitează zona de
 înregistrare a vitezei.</p><br>

<p style="text-indent:2em;">În cazul aparatelor cu bandă, când dispozitivul de avansare a foilor este comandat de mecanismul ceasului, viteza de avansare rectilinie va fi
 de 10 mm/h, cel puțin.</p><br>

<p style="text-indent:2em;">d) Înregistrările distanței parcurse, ale vitezei vehiculului și ale deschiderii cutiei conținând foaia sau foile de înregistrare trebuie să
 fie automate.</p>

<p style="text-indent:2em;"></p><h6>2. Înregistrarea distanței parcurse:</h6><p></p>

<p style="text-indent:2em;">a) Orice distanță parcursă, de 1 km, trebuie să fie reprezentată pe diagramă printr-o variație de cel puțin 1 mm a coordonatei
 corespunzătoare.</p><br>

<p>b) Chiar la viteze situate la limita superioară a plajei de măsură,
 diagrama parcursurilor trebuie să fie clar lizibilă.</p>

<p style="text-indent:2em;"></p><h6>3. Înregistrarea vitezei:</h6><p></p>

<p style="text-indent:2em;">a) Acul de înregistrare a vitezei trebuie să aibă, în principiu, o mișcare rectilinie și perpendiculară pe direcția de deplasare a foii
 de înregistrare, oricare ar fi forma acesteia.</p>

<p style="text-indent:2em;"></p><h6>Totuși poate fi admisă o mișcare curbilinie a acului, dacă sunt îndeplinite
 condițiile următoare:</h6><p></p>

<p style="text-indent:2em;"> traiectoria acului este perpendiculară pe circumferința medie (în cazul foilor sub formă de disc) sau pe axa zonei rezervate înregistrării
 vitezei (în cazul foilor sub formă de bandă);</p><br>

<p style="text-indent:2em;"> raportul dintre raza de curbură a traiectoriei trasate de ac și lățimea zonei rezervate înregistrării vitezei nu este mai mic de 2,4:1,
 oricare ar fi forma foii de înregistrare;</p><br>

<p style="text-indent:2em;"> diversele linii ale scalei de timp trebuie să traverseze zona de înregistrare după o curbă cu aceeași rază ca traiectoria descrisă de ac.
 Distanța dintre liniile scalei de timp trebuie să reprezinte o perioadă care să nu depășească o oră.</p><br>

<p style="text-indent:2em;">b) Orice variație de 10 km/h a vitezei trebuie să fie reprezentată pe diagramă printr-o variație de cel puțin 1,5 mm a coordonatei
 corespunzătoare.</p><br>

<p style="font-weight:500;">4. Înregistrarea timpilor</p>

<p style="text-indent:2em;"></p><h6>a) Aparatul trebuie să înregistreze timpul de condus, complet automat. El trebuie să înregistreze, de asemenea, după efectuarea eventuală
 a unei manevre corespunzătoare, celelalte grupe de timp, după cum urmează:</h6><p></p>

<p> (i)   sub semnul   Q: timpul de conducere;</p><br>

<p> (ii)  sub semnul   X: toți ceilalți timpi de lucru;</p>

<p style="text-indent:2em;"></p><h6> (iii) sub semnul [/]: timpul disponibil, adică:</h6><p></p>

<p style="text-indent:2em;"> timpul de așteptare - perioada în care șoferii nu sunt obligați să rămână la posturile lor de muncă, decât doar pentru a răspunde la
un eventual apel, pentru a porni sau a relua cursa sau pentru a efectua alte activități;</p><br>

<p> timpul petrecut alături de un șofer în timpul mersului vehiculului;</p><br>

<p> timpul petrecut pe o cușetă, în timpul mersului vehiculului;</p><br>

<p> (iv)  sub semnul ||||¯ §: opriri și perioade de odihnă zilnică.</p><br>

<p style="text-indent:2em;">Fiecare parte contractantă poate permite, pentru foile de înregistrare folosite pe vehicule înmatriculate pe teritoriul său, ca perioadele
 de timp menționate la pct. (ii) și (iii) de mai sus să fie toate înregistrate sub semnul: [/].</p><br>

<p style="text-indent:2em;">b) Caracteristicile traiectoriilor, pozițiile lor relative și, eventual, semnele prevăzute la paragraful 4 a) trebuie să facă distincția
 clară dintre diversele perioade de timp.</p><br>

<p style="text-indent:2em;">Distincția dintre diversele grupe de timp este reprezentată în diagramă prin diferența de grosime a liniilor corespunzătoare acestora sau
 prin orice alt sistem care să fie cel puțin la fel de eficace din
 punct de vedere al lizibilității și interpretării diagramei.</p><br>

<p style="text-indent:2em;">c) În cazul vehiculelor folosite de un echipaj compus din mai mulți șoferi, înregistrările menționate în paragraful 4a) trebuie să fie
 făcute pe două foi distincte, fiecare fiind atribuită unui șofer. În acest caz, avansarea diverselor foi de înregistrare trebuie să
 fie asigurată de același mecanism ori de mecanisme sincronizate.</p><br>

<p style="font-weight:500;">D. Dispozitiv de închidere</p><br>

<p>1. Cutia conținând foaia sau foile de înregistrare și comanda dispozitivului de
 fixare la oră trebuie să aibă o încuietoare.</p><br>

<p style="text-indent:2em;">2. Orice deschidere a cutiei, conținând o foaie sau mai multe foi de înregistrare și comanda dispozitivului de fixare la oră trebuie să
 fie marcată în mod automat pe foaia sau pe foile de înregistrare.</p><br>

<p style="font-weight:500;">E. Înscrieri</p>

<p style="text-indent:2em;"></p><h6>1. Pe cadranul aparatului trebuie să figureze înscrierile următoare:</h6><p></p>

<p> lângă numărul indicat de contorul totalizator, unitatea de măsură a distanțelor, cu simbolul "km",</p><br>

<p> lângă scala de viteze, însemnul "km/h",</p><br>

<p style="text-indent:2em;"> plaja de măsură a tahometrului, cu simbolul "Vmin ... km/h, Vmax ... km/h". Această indicație nu este necesară, dacă ea figurează pe
 placa semnalizatoare a aparatului.</p><br>

<p>Totuși aceste prevederi nu se aplică aparatelor de control omologate înainte de 10 august 1970.</p>

<p style="text-indent:2em;"></p><h6>2. Pe placa semnalizatoare care face corp comun cu aparatul trebuie să figureze următoarele date, care trebuie să fie vizibile pe
 aparatul instalat:</h6><p></p>

<p> numele și adresa fabricantului aparatului;</p><br>

<p> numărul de fabricație și anul de construcție;</p><br>

<p> marca de omologare a modelului aparatului;</p><br>

<p> constanta aparatului cu simbolul "k = ... tr/km" sau "k = ... imp/km";</p><br>

<p> eventual, plaja de măsură a vitezei, sub forma indicată în paragraful 1;</p>

<p style="text-indent:2em;"></p><h6> dacă sensibilitatea instrumentului la unghiul de înclinație poate să influențeze asupra indicațiilor date de aparat, dincolo
 de toleranțele admise, unghiul permis are forma următoare:</h6><p></p>
 
<p>+ beta</p><br>
<p>(un unghi ascuțit)</p><br>
<p>alfa</p><br>
<p>_____________ - gama</p><br>

<p style="text-indent:2em;">în care formă s-a reprezentat unghiul măsurat pornind din poziția orizontală a părții din față (orientată în sus) a aparatului
 pentru care este reglat instrumentul, b) și c) reprezentând, respectiv în sus și în jos, limitele de abatere admisibile față
 de unghiul alfa.</p><br>

<p style="font-weight:500;">F. Erori maxime admise (dispozitive indicatoare și înregistratoare)</p>

<p style="text-indent:2em;"></p><h6>1. La bancul de probe, înaintea instalării:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) distanța parcursă:</h6><p></p>

<p>1% în plus sau în minus față de distanța reală, aceasta fiind cel puțin egală cu 1 km;</p>

<p style="text-indent:2em;"></p><h6>b) viteza:</h6><p></p>

<p>3 km/h în plus sau în minus față de viteza reală;</p><br>

<p style="text-indent:2em;">c) timp: ±2 minute/zi, cu maximum de 10 minute/7 zile, în cazul când durata de mers a ceasului, după întoarcere, nu
 este inferioară acestei perioade.</p>

<p style="text-indent:2em;"></p><h6>2. La instalare:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) distanța parcursă:</h6><p></p>

<p>2% în plus sau în minus din distanța reală, aceasta fiind cel puțin egală cu 1 km;</p>

<p style="text-indent:2em;"></p><h6>b) viteza:</h6><p></p>

<p>4 km/h în plus sau în minus în raport cu viteza reală;</p>

<p style="text-indent:2em;"></p><h6>c) timp:</h6><p></p>

<p>± 2 minute/zi, sau,</p><br>

<p>± 10 minute/7 zile.</p>

<p style="text-indent:2em;"></p><h6>3. În funcțiune:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) distanța parcursă:</h6><p></p>

<p>4% în plus sau în minus din distanța reală, aceasta fiind cel puțin egală cu 1 km;</p>

<p style="text-indent:2em;"></p><h6>b) viteza:</h6><p></p>

<p>6 km/h în plus sau în minus în raport cu viteza reală;</p>

<p style="text-indent:2em;"></p><h6>c) timp:</h6><p></p>

<p>± 2 minute/zi, sau</p><br>

<p>± 10 minute/7 zile.</p><br>

<p style="text-indent:2em;">4. Erorile maxime admise enumerate în paragrafele 1, 2, 3 sunt valabile pentru temperaturi situate între 0° și 40°C,
 temperaturile fiind înregistrate în imediata apropiere a aparatului.</p><br>

<p style="text-indent:2em;">5. Măsurarea erorilor maxime admise în paragrafele 2 și 3 se va face în conformitate cu condițiile menționate în
 capitolul VI.</p><br>

<p style="font-weight:500;">IV. Foi de înregistrare</p><br>

<p style="font-weight:500;">A. Generalități</p><br>

<p style="text-indent:2em;">1. Foile de înregistrare trebuie să fie de o asemenea calitate, încât să nu împiedice funcționarea normală a aparatului și
 ca înregistrările făcute pe ele să nu se șteargă, să fie clar lizibile și să poată fi identificate.</p><br>

<p>Foile de înregistrare trebuie să-și păstreze dimensiunile și înregistrările, în condiții normale de higrometrie și
 temperatură.</p>

<p style="text-indent:2em;"></p><h6>În plus, trebuie să existe posibilitatea pentru fiecare membru al echipajului să înscrie pe foile de înregistrare,
 fără să le deterioreze și fără să afecteze lizibilitatea înregistrărilor, indicațiile următoare:</h6><p></p>

<p>a) numele și prenumele său, la începutul folosirii foii;</p><br>

<p>b) data și locul, la începutul și sfârșitul folosirii foii;</p><br>

<p style="text-indent:2em;">c) numărul plăcii de înmatriculare a vehiculului pe care îl conduce, înainte de prima cursă înregistrată pe foaie și
 apoi, în cazul schimbării vehiculului, în timpul folosirii foii de înregistrare;</p>

<p style="text-indent:2em;"></p><h6>d) contorul de parcurs:</h6><p></p>

<p> înainte de prima cursă înregistrată pe foaie;</p><br>

<p> la sfârșitul ultimei curse înregistrate pe foaie;</p><br>

<p style="text-indent:2em;"> în cazul schimbării vehiculului, în timpul zilei de lucru (contorul vehiculului pe care l-a condus și contorul vehiculului
 pe care îl va conduce);</p><br>

<p>e) dacă este cazul, ora schimbării vehiculului.</p><br>

<p>În condiții normale de păstrare, înregistrările trebuie să rămână clar lizibile timp de cel puțin un an.</p><br>

<p>2. Capacitatea minimă de înregistrare a foilor, oricare ar fi forma lor, trebuie să fie de 24 de ore.</p><br>

<p style="text-indent:2em;">Dacă mai multe discuri sunt legate între ele pentru a mări capacitatea de înregistrare continuă, realizabilă fără intervenția
 personalului, racordările dintre diferitele discuri trebuie să fie astfel realizate încât înregistrările să nu prezinte nici
 întreruperi, nici suprapuneri în locurile de trecere de la un disc la altul.</p><br>

<p style="font-weight:500;">B. Zone de înregistrare și împărțirea lor în grade</p>

<p style="text-indent:2em;"></p><h6>1. Foile de înregistrare cuprind următoarele zone de înregistrare:</h6><p></p>

<p> o zonă rezervată exclusiv indicațiilor privind viteza;</p><br>

<p> o zonă rezervată exclusiv indicațiilor privind distanțele parcurse;</p><br>

<p style="text-indent:2em;"> o zonă sau zone pentru indicațiile privind timpii de conducere, alți timpi de lucru, timpi disponibili, întreruperile de lucru
 și repausul șoferilor.</p><br>

<p style="text-indent:2em;">2. Zona rezervată înregistrării vitezei trebuie să fie subîmpărțită în tranșe de 20 km/h, cel puțin. Viteza corespunzătoare trebuie
 să fie indicată în cifre pe fiecare linie a acestei subdiviziuni. Simbolul "km/h" trebuie să figureze cel puțin o dată în interiorul
 acestei zone. Ultima linie a acestei zone trebuie să coincidă cu limita superioară a plajei de măsură.</p><br>

<p style="text-indent:2em;">3. Zona rezervată înregistrării parcursurilor trebuie să fie astfel tipărită încât să permită citirea ușoară a numărului de
 kilometri parcurși.</p><br>

<p style="text-indent:2em;">4. Zona sau zonele rezervate înregistrării timpilor menționați în paragraful 1 trebuie să fie astfel marcate încât să se poată
 face diferența clară dintre diversele grupe de timp.</p><br>

<p style="font-weight:500;">C. Indicații tipărite pe foile de înregistrare</p>

<p style="text-indent:2em;"></p><h6>Fiecare foaie trebuie să conțină tipărite următoarele indicații:</h6><p></p>

<p> numele și adresa sau marca fabricantului;</p><br>

<p> marca de omologare a modelului foii de înregistrare;</p><br>

<p>marca de omologare a modelului sau modelelor de aparate, în care se poate folosi foaia de înregistrare;</p><br>

<p>limita superioară a vitezei care se poate înregistra, tipărită, în km/h.</p><br>

<p style="text-indent:2em;">Fiecare foaie trebuie să aibă tipărită, în plus, cel puțin o scală de timp gradată, astfel încât să permită citirea directă 
a timpului la intervale de 15 minute, la fel ca și determinarea fără dificultăți a intervalelor de 5 minute.</p><br>

<p style="font-weight:500;">D. Spațiu liber pentru înscrieri de mână</p>

<p style="text-indent:2em;"></p><h6>Trebuie să se prevadă un spațiu liber pe foi, pentru a permite șoferului să adauge în acest spațiu, de mână, cel puțin
 mențiunile de mai jos:</h6><p></p>

<p>numele și prenumele șoferului;</p><br>

<p>data și locul când se începe și se termină folosirea foii;</p><br>

<p style="text-indent:2em;"> numărul sau numerele plăcii de înmatriculare a vehiculului sau vehiculelor pe care le conduce șoferul în timpul
 cât folosește foaia;</p><br>

<p>contoarele kilometrice de parcurs ale vehiculului sau vehiculelor pe care le conduce șoferul în timpul cât
 folosește foaia;</p><br>

<p>ora schimbării de vehicul.</p><br>

<p style="font-weight:500;">V. Instalarea aparatului de control</p><br>

<p style="font-weight:500;">A. Generalități</p><br>

<p style="text-indent:2em;">1. Aparatele de control trebuie să fie montate pe vehicule, astfel încât, pe de o parte, șoferul să poată supraveghea
 cu ușurință de la locul său indicatorul de viteză, contorul totalizator și ceasul și, pe de altă parte, toate elementele lor,
 inclusiv cele de transmisie, să fie protejate împotriva oricărei deteriorări intervenite.</p><br>

<p style="text-indent:2em;">2. Constanta aparatului de control trebuie să fie adaptată coeficientului caracteristic al vehiculului, cu ajutorul unui
 dispozitiv corespunzător, numit adaptor.</p><br>

<p style="text-indent:2em;">Vehiculele cu două sau mai multe sarcini pe axă trebuie să fie prevăzute cu un dispozitiv de comutare, care reglează automat
 aceste sarcini, conform cu aceea pentru care adaptarea aparatului la vehicul este realizată prin adaptor.</p><br>

<p style="text-indent:2em;">3. O plăcuță de instalare este instalată la loc vizibil pe vehicul, lângă aparat sau chiar pe aparat, după verificarea făcută
 cu ocazia primei instalări. După fiecare intervenție a unui instalator sau a unui atelier autorizat, care necesită o modificare
 a reglării instalării propriu-zise, trebuie să fie atașată o nouă plăcuță, care o înlocuiește pe cea anterioară.</p>

<p style="text-indent:2em;"></p><h6>Plăcuța trebuie să conțină cel puțin mențiunile de mai jos:</h6><p></p>

<p> numele, adresa sau marca instalatorului, sau a atelierului autorizat;</p><br>

<p> coeficientul caracteristic al vehiculului, cu simbolul "w = ... tr/km" sau "w = ... imp/km";</p><br>

<p style="text-indent:2em;"> circumferința efectivă a pneurilor roților, cu simbolul "l = ... mm";</p><br>

<p style="text-indent:2em;"> data când a fost determinat coeficientul caracteristic al vehiculului și când s-a măsurat circumferința efectivă a
 pneurilor roților.</p><br>

<p style="font-weight:500;">B. Sigilii</p>

<p style="text-indent:2em;"></p><h6>Trebuie să fie sigilate următoarele elemente:</h6><p></p>

<p style="text-indent:2em;">a) plăcuța de instalare, numai dacă ea nu este aplicată astfel încât să nu poată fi desprinsă, fără să se deterioreze
 indicațiile;</p><br>

<p>b) capetele legăturii dintre aparatul de control propriu-zis și vehicul;</p><br>

<p>c) adaptorul propriu-zis și inserarea sa în circuit;</p><br>

<p>d) dispozitivul de comutare pentru vehiculele cu mai multe sarcini pe axă;</p><br>

<p>e) legăturile adaptorului și dispozitivului de comutare cu celelalte elemente de instalare;</p><br>

<p>f) ambalajele prevăzute în capitolul III secțiunea A paragraful 7 b).</p><br>

<p style="text-indent:2em;">Pentru cazuri particulare pot fi prevăzute și alte sigilii, cu ocazia omologării modelului aparatului și trebuie să se
 menționeze pe fișa de omologare amplasarea acestor sigilii.</p><br>

<p style="text-indent:2em;">Doar sigiliile de legătură menționate la lit. b), c) și e) pot fi înlăturate în caz de urgență; orice rupere a acestor
 sigilii trebuie să constituie obiectul unei justificări în scris, care să stea la dispoziția autorității competente.</p><br>

<p style="font-weight:500;">VI. Verificări și controale</p><br>

<p>Părțile contractante desemnează organele care trebuie să efectueze verificările și controalele.</p><br>

<p style="font-weight:500;">1. Atestarea instrumentelor noi sau reparate</p><br>

<p style="text-indent:2em;">Orice aparat individual, nou sau reparat, este atestat în ceea ce privește buna sa funcționare și exactitatea
 indicațiilor și înregistrărilor, în limitele fixate în capitolul III secțiunea F paragraful 1, prin sigiliul
 prevăzut în capitolul V secțiunea B lit. f).</p><br>

<p style="text-indent:2em;">Părțile contractante pot introduce, în acest scop, verificarea primară, care este controlul și recunoașterea
 conformității unui aparat nou sau recondiționat cu modelul omologat și/sau cu cerințele anexei, inclusiv cu 
 apendicele sale, sau acordarea de drepturi de atestare fabricanților sau reprezentanților acestora.</p><br>

<p style="font-weight:500;">2. Instalare</p><br>

<p style="text-indent:2em;">Cu ocazia instalării sale la bordul unui vehicul, aparatul și toată instalarea sa trebuie să corespundă dispozițiilor
 privind erorile maxime admise stabilite în capitolul III secțiunea F paragraful 2.</p><br>

<p>Testele de control aferente acestora sunt executate, pe răspunderea sa, de instalator sau de atelierul
 autorizat.</p><br>

<p style="font-weight:500;">3. Controale periodice</p><br>

<p style="text-indent:2em;">a) Controalele periodice ale aparatelor instalate pe vehicule au loc cel puțin din 2 în 2 ani și pot fi
 efectuate, printre altele, în cadrul inspecțiilor tehnice ale vehiculelor automobile.</p>

<p style="text-indent:2em;"></p><h6>Se vor controla mai ales:</h6><p></p>

<p> starea de bună funcționare a aparatului;</p><br>

<p> prezența semnului de omologare pe aparate;</p><br>

<p> prezența plăcuței de instalare;</p><br>

<p> integritatea sigiliilor aparatului și a celorlalte elemente de instalare;</p><br>

<p> circumferința efectivă a pneurilor.</p><br>

<p style="text-indent:2em;">b) Controlul respectării dispozițiilor capitolului III secțiunea F paragraful 3 privind erorile maxime
 tolerate în funcționare va fi efectuat cel puțin o dată din 6 în 6 ani, cu posibilitatea, pentru orice parte
 contractantă, de a prevedea un termen mai scurt pentru vehiculele înmatriculate pe teritoriul său. Acest control
 presupune, în mod obligatoriu, înlocuirea plăcuței de instalare.</p><br>

<p style="font-weight:500;">4. Depistarea erorilor</p>

<p style="text-indent:2em;"></p><h6>Depistarea erorilor la instalare și în funcționare se realizează în condițiile următoare, considerate drept
 condiții normale de încercare (test):</h6><p></p>

<p>vehicule fără încărcătură, în condiții normale de mers;</p><br>

<p>presiunea pneurilor, conform indicațiilor date de către fabricant;</p><br>

<p>uzura pneurilor, în limitele admise de prevederile în vigoare;</p><br>

<p style="text-indent:2em;"> deplasarea vehiculului: acesta trebuie să se deplaseze, pus în mișcare de propriul său motor, în linie
 dreaptă, pe o suprafață plană, cu o viteză de 50±5 km/h; controlul, cu condiția ca el să fie de o exactitate
 comparabilă, poate fi efectuat, de asemenea, pe un banc de încercări corespunzător.</p>

<p></p><h4>ANEXA-APENDICE Nr. 2</h4><p></p>

<p style="font-weight:500;">MARCA ȘI FIȘA DE OMOLOGARE</p><br>

<p style="font-weight:500;">I. Marca de omologare</p>

<p style="text-indent:2em;"></p><h6>1. Marca de omologare este compusă din:</h6><p></p>

<p style="text-indent:2em;"></p><h6> un dreptunghi, în interiorul căruia este situată litera "E", urmată de un număr distinctiv al țării
 care a acordat omologarea, conform celor convenite mai jos:</h6><p></p>

<p>Norvegia - 1</p><br>

<p>Spania – 2</p><br>

<p>Suedia - 3</p><br>

<p>Portugalia - 4</p><br>

<p>Grecia - 5</p><br>

<p>Iugoslavia - 6</p><br>

<p>Austria - 7</p><br>

<p>Germania - 8</p><br>

<p>Republica Federativă Cehă și Slovacă - 9</p><br>

<p>Belgia - 10</p><br>

<p>Danemarca - 11</p><br>

<p>Luxemburg - 12</p><br>

<p>Țările de Jos - 13</p><br>

<p>Regatul Unit - 14</p><br>

<p>Franța - 15</p><br>

<p>Uniunea Republicilor Socialiste Sovietice - 16</p><br>

<p>Italia - 17</p><br>

<p>Irlanda - 18</p>

<p style="text-indent:2em;"></p><h6>Cifrele care urmează sunt atribuite celorlalte țări, în funcție de ordinea cronologică a ratificării lor
 a Acordului european privind munca echipajelor vehiculelor care efectuează transporturi internaționale
 rutiere sau de aderarea lor la acest acord, și:</h6><p></p>

<p style="text-indent:2em;"> un număr de omologare corespunzător numărului fișei de omologare stabilită pentru prototipul aparatului
 de control sau al foii, număr plasat oriunde în imediata apropiere a acestui dreptunghi.</p><br>

<p style="text-indent:2em;">2. Marca de omologare este pusă pe plăcuța semnalizatoare a fiecărui aparat și pe fiecare foaie de
 înregistrare. Ea trebuie să nu poată fi ștearsă și să rămână mereu lizibilă.</p><br>

<p style="text-indent:2em;">3. Dimensiunile mărcii de omologare desenate mai jos sunt exprimate în mm, aceste dimensiuni constituind
 minimele. Trebuie să se respecte raportul dintre aceste dimensiuni.</p><br>

<p style="font-weight:500;">II. Fișa de omologare</p><br>

<p style="text-indent:2em;">Partea contractantă, care a omologat, eliberează solicitatorului o fișă de omologare stabilită după modelul de mai jos. Pentru
 a comunica celorlalte părți contractante omologările acordate sau eventualele retrageri, fiecare parte contractantă folosește
 copii ale acestui document.</p><br>
 
<p style="font-weight:500;">FIȘA DE OMOLOGARE</p><br>

<p>Numele administrației competente</p>

<p style="text-indent:2em;"></p><h6>Comunicare privind*):</h6><p></p>

<p>omologarea unui model de aparat de control;</p><br>

<p>retragerea omologării unui model de aparat de control;</p><br>

<p>omologarea foii de înregistrare;</p><br>

<p>retragerea omologării foii de înregistrare.</p><br>

<p>*) A se șterge mențiunile inutile.</p>

<p style="text-indent:2em;"></p><h6>Nr. de omologare:</h6><p></p>

<p>1. Marca de fabrică sau de comerț</p><br>

<p>2. Denumirea modelului</p><br>

<p>3. Numele fabricantului</p><br>
 
<p>4. Adresa fabricantului</p><br>
 
<p>5. Prezentat la omologare la data de</p><br>
 
<p>6. Laborator de încercări</p><br>
 
<p>7. Data și numărul procesului-verbal al laboratorului</p><br>
 
<p>8. Data omologării</p><br>
 
<p>9. Data retragerii omologării</p><br>
 
<p>10. Modele de aparate de control, pe care se poate folosi foaia de înregistrare</p><br>

<p>11. Loc</p><br>

<p>12. Data</p><br>

<p>13. În anexă, documente descriptive</p><br>

<p>14. Observații</p><br>

<p>(semnătura)</p><br>

<p style="font-weight:500;">PROTOCOL DE SEMNARE</p>

<p style="text-indent:2em;"></p><h6>În momentul semnării Acordului european privind activitatea echipajelor vehiculelor care efectuează transporturi internaționale rutiere, subsemnații, având depline
 puteri, au convenit ceea ce urmează:</h6><p></p>

<p style="text-indent:2em;">Părțile contractante declară că prezentul acord nu prejudiciază dispozițiile care ar putea fi, dacă este cazul, elaborate ulterior, privind timpul de lucru
 și durata zilei de lucru.</p><br>

<p style="font-weight:500;">La articolul 4 al acordului</p><br>

<p style="text-indent:2em;">Dispozițiile paragrafului 1 al acestui articol nu trebuie să fie interpretate ca făcând aplicabile, în afara statului care înmatriculează vehiculul care
 efectuează transportul, interdicțiile de circulație, în anumite zile sau la anumite ore, care pot exista în acest stat pentru anumite categorii de vehicule.
 Dispozițiile paragrafului 2 al acestui articol nu trebuie să fie interpretate ca împiedicând o parte contractantă să-și impună pe teritoriul său respectarea
 dispozițiilor legislației sale naționale care interzic, în anumite zile sau la anumite ore, circulația anumitor categorii de vehicule.</p><br>

<p style="text-indent:2em;">Orice parte contractantă, parte la un acord special, așa cum prevede paragraful 2 al art. 4 din prezentul acord, și care ar autoriza efectuarea transporturilor
 internaționale, începând și terminându-se pe teritoriul părților participante la respectivul acord special, prin vehicule înmatriculate pe teritoriul unui stat
 care este parte contractantă la A.E.T.R., dar care nu este parte la respectivul acord special, va putea stabili, ca o condiție a încheierii acordurilor bilaterale
 sau multilaterale care autorizează aceste transporturi, ca echipajele care efectuează transporturile respective să se conformeze dispozițiilor acestuia din urmă
 , pe teritoriul statelor părți la acordul special.</p><br>

<p style="font-weight:500;">La articolul 12 al acordului</p><br>

<p style="text-indent:2em;">Subsemnații se angajează să discute, o dată intrat în vigoare acordul, introducerea în acord, pe bază de amendament, a unei clauze care să prevadă folosirea
 unui aparat de control de tip omologat, montat pe vehicul, care ar înlocui, în măsura posibilului, carnetul individual de control.</p><br>

<p style="font-weight:500;">La articolul 14 al acordului</p>

<p style="text-indent:2em;"></p><h6>Părțile contractante recunosc că este de dorit:</h6><p></p>

<p style="text-indent:2em;"> ca fiecare parte contractantă să ia măsurile necesare pentru a fi capabilă să urmărească abaterile de la dispozițiile acordului, nu numai când ele sunt
 comise pe teritoriul său, dar și când ele sunt comise pe teritoriul unui alt stat, în cursul unui transport internațional rutier efectuat de un vehicul
 pe care partea l-a înmatriculat;</p><br>

<p>ca părțile să-și acorde ajutor reciproc, pentru a sancționa abaterile comise.</p><br>

<p><a href="https://lege5.ro/Gratuit/gu4tcmbu/acordul-european-privind-activitatea-echipajelor-vehiculelor-care-efectueaza-transporturi-rutiere-internationale-aetr-din-01071970-">Sursa</a></p>

</div>
`