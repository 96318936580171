export const md20 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Таможни Молдовы</h4><p></p>

<table style="width:100%;text-align:center;">
   <tbody><tr><!--<tr> строка таблицы -->
    <td class="tab_rule" colspan="3">Таможня - Кишинёв</td><!--<td> ячейка таблицы -->
   </tr>
   <tr><td class="tab_rule">Приёмная</td><td class="tab_rule">Манесцу Лилиа</td><td class="tab_rule">(+37322) 535365</td></tr>
   <tr><td class="tab_rule">Информационный отдел</td><td class="tab_rule">Попа Юрии</td><td class="tab_rule">(+37322) 536943, 523229</td></tr>
   <tr><td class="tab_rule">Экономический отдел</td><td class="tab_rule">Чебан Любовь</td><td class="tab_rule">(+37322) 539313, 534774</td></tr>
   <tr><td class="tab_rule">Отдел (TEE)</td><td class="tab_rule">Дорин Алексей</td><td class="tab_rule">(+37322) 534794</td></tr>
   <tr><td class="tab_rule">Отдел Видеонаблюдения</td><td class="tab_rule">Цернетчии Сергеи</td><td class="tab_rule">(+37322) 534794</td></tr>
   <tr><td class="tab_rule">Главный Бухгалтер</td><td class="tab_rule">Дулче Тамара</td><td class="tab_rule">(+37322) 537673</td></tr>
   <tr><td class="tab_rule">Отдел Контрабанды</td><td class="tab_rule"></td><td class="tab_rule">(+37322) 558029</td></tr>
   <tr><td class="tab_rule">Железная Дорога</td><td class="tab_rule"></td><td class="tab_rule">(+37322) 521595</td></tr>
   <tr><td class="tab_rule">Подтверждение</td><td class="tab_rule"></td><td class="tab_rule">(+37322) 533156</td></tr>
   
   <tr><!--<tr> строка таблицы -->
    <td class="tab_rule" colspan="2">Таможенные переходы</td><!--<td> ячейка таблицы -->
   </tr>
   <tr><td class="tab_rule">Таможенный переход</td><td class="tab_rule">Телефон</td></tr>
   <tr><td class="tab_rule">Леушены</td><td class="tab_rule">+373 269 46246</td></tr>
   <tr><td class="tab_rule">Унгены</td><td class="tab_rule">+373 236 23570</td></tr>
   <tr><td class="tab_rule">Бричаны</td><td class="tab_rule">+373 247 25401</td></tr>
   <tr><td class="tab_rule">Окница</td><td class="tab_rule">+373 271 23245</td></tr>
   <tr><td class="tab_rule">Кагул</td><td class="tab_rule">+373 299 28942; +373 299  21927</td></tr>
   <tr><td class="tab_rule">Атаки</td><td class="tab_rule">+373 271 93300</td></tr>
   <tr><td class="tab_rule">Жюржюлешты</td><td class="tab_rule">+373 293 71067</td></tr>
   <tr><td class="tab_rule">Бельцы (Nord)</td><td class="tab_rule">+373 231 30255</td></tr>
   <tr><td class="tab_rule">Скулень</td><td class="tab_rule">+373 236 23876</td></tr>
   <tr><td class="tab_rule">Тудора</td><td class="tab_rule">+373 242 28278</td></tr>
   <tr><td class="tab_rule">Каушаны (Бендеры)</td><td class="tab_rule">+373 243 21386</td></tr>
   <tr><td class="tab_rule">Паланка</td><td class="tab_rule">+373 242 22221; +373 242 23029</td></tr>
   <tr><td class="tab_rule">Сороки</td><td class="tab_rule">+373 230 23110</td></tr>
   <tr><td class="tab_rule">Крива</td><td class="tab_rule">+373 247 47233</td></tr>
   <tr><td class="tab_rule">Бассарабяска</td><td class="tab_rule">+373 297 22230</td></tr>
   <tr><td class="tab_rule">Бельцы</td><td class="tab_rule">+373 231 30255</td></tr>
   <tr><td class="tab_rule">Комрат</td><td class="tab_rule">+373 298 24663</td></tr>
   <tr><td class="tab_rule">Expo-Bussines Кишинёв</td><td class="tab_rule">+373 22 749327</td></tr>
   <tr><td class="tab_rule">Аэропорт Кишинёв</td><td class="tab_rule">+373 22 526142</td></tr>
   
</tbody></table>

<p></p><h4>Пропускные пункты Молдовы</h4><p></p>

<table style="width:100%;text-align:center;">
   <tbody><tr><!--<tr> строка таблицы -->
    <td class="tab_rule" colspan="1">№</td>
	<td class="tab_rule" colspan="1">Название пункта пропуска</td>
	<td class="tab_rule" colspan="1">Вид пункта пропуска</td><!--<td> ячейка таблицы -->
   </tr>
   
   <tr><!--<tr> строка таблицы -->
    <td class="tab_rule" colspan="3">На границе с Румынией</td><!--<td> ячейка таблицы -->
   </tr>
   
   <tr><td class="tab_rule">1</td><td class="tab_rule">Рэдэуци Прут</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">2</td><td class="tab_rule">Костешты Стынка</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">3</td><td class="tab_rule">Скулени Скуляны</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">4</td><td class="tab_rule">Яссы Унгены</td><td class="tab_rule">ж/д</td></tr>
   <tr><td class="tab_rule">5</td><td class="tab_rule">Албица Леушены</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">6</td><td class="tab_rule">Оанчя Кагул</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">7</td><td class="tab_rule">Галац Джурджулешты</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">8</td><td class="tab_rule">Порт Джурджулешты</td><td class="tab_rule">морской</td></tr>
   
   <tr><!--<tr> строка таблицы -->
    <td class="tab_rule" colspan="3">На границе с Украиной</td><!--<td> ячейка таблицы -->
   </tr>
   
   <tr><td class="tab_rule">1</td><td class="tab_rule">Большая Косница Хрушка</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">2</td><td class="tab_rule">Болган Христовая</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">3</td><td class="tab_rule">Могилев-Подольский Отаки</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">4</td><td class="tab_rule">Грабаревка Окница</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">5</td><td class="tab_rule">Бронница Унгуры</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">6</td><td class="tab_rule">Студеная Ротарь</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">7</td><td class="tab_rule">Могилев-Подольский Вылченец</td><td class="tab_rule">ж/д</td></tr>
   <tr><td class="tab_rule">8</td><td class="tab_rule">Большая Косница Василкэу</td><td class="tab_rule">речн.</td></tr>
   <tr><td class="tab_rule">9</td><td class="tab_rule">Оксановка Куресньца</td><td class="tab_rule">речн.</td></tr>
   <tr><td class="tab_rule">10</td><td class="tab_rule">Михайловка Кременчуг</td><td class="tab_rule">речн.</td></tr>
   <tr><td class="tab_rule">11</td><td class="tab_rule">Цекиновка Сороки</td><td class="tab_rule">речн.</td></tr>
   <tr><td class="tab_rule">12</td><td class="tab_rule">Большой Овраг Чукулень</td><td class="tab_rule">речн.</td></tr>
   <tr><td class="tab_rule">13</td><td class="tab_rule">Ямполь Косэуц</td><td class="tab_rule">речн.</td></tr>
   <tr><td class="tab_rule">14</td><td class="tab_rule">Цекиновка Сороки</td><td class="tab_rule">паром</td></tr>
   <tr><td class="tab_rule">15</td><td class="tab_rule">Ямполь Косэуц</td><td class="tab_rule">паром</td></tr>
   <tr><td class="tab_rule">16</td><td class="tab_rule">Долинское Цишмичиои</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">17</td><td class="tab_rule">Рени Джурджулешты</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">18</td><td class="tab_rule">Рени Джурджулешты</td><td class="tab_rule">ж/д</td></tr>
   <tr><td class="tab_rule">19</td><td class="tab_rule">Лесное Сэицы</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">20</td><td class="tab_rule">Малоярославець 1 Чадыр Лунга</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">21</td><td class="tab_rule">Новые Трояны Чадыр Лунга</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">22</td><td class="tab_rule">Августовск 1 Чадыр Лунга</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">23</td><td class="tab_rule">Виноградовка Вулканешты</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">24</td><td class="tab_rule">Маяки-Удобное Паланка</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">25</td><td class="tab_rule">Староказачье Тудора</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">26</td><td class="tab_rule">Табаки Мирное</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">27</td><td class="tab_rule">Железнодорожное Мирное</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">28</td><td class="tab_rule">Великоплоское Мэлэешты</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">29</td><td class="tab_rule">Градинцы Незавертайловка</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">30</td><td class="tab_rule">Йосиповка Колосова</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">31</td><td class="tab_rule">Славяносербка Ближний Хутор</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">32</td><td class="tab_rule">Станиславка Вэрэнкэу</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">33</td><td class="tab_rule">Тимково Броштяны</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">34</td><td class="tab_rule">Кучурган Первомайск</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">35</td><td class="tab_rule">Платоново Новые Гояны</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">36</td><td class="tab_rule">Гребеники Тирасполь</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">37</td><td class="tab_rule">Дубовое Дубэу</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">38</td><td class="tab_rule">Розаливка Фрунзэ</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">39</td><td class="tab_rule">Алексеевка Вадул Туркулуй</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">40</td><td class="tab_rule">Кучурган Первомайск</td><td class="tab_rule">ж/д</td></tr>
   <tr><td class="tab_rule">41</td><td class="tab_rule">Тимково Броштяны</td><td class="tab_rule">ж/д</td></tr>
   <tr><td class="tab_rule">42</td><td class="tab_rule">Васильковое Вадул Туркулуй</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">43</td><td class="tab_rule">Подворьевка Медвежа</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">44</td><td class="tab_rule">Росошаны Бричяны</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">45</td><td class="tab_rule">Кельменцы Ларга</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">46</td><td class="tab_rule">Мамалыга Крива</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">47</td><td class="tab_rule">Сокиряны Клокушна</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">48</td><td class="tab_rule">Гвоздевцы Клокушна</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">49</td><td class="tab_rule">Вашковцы Гримэнкэуць</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">50</td><td class="tab_rule">Зеленая Липканы</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">51</td><td class="tab_rule">Новоалексеевка Сокиряны</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">52</td><td class="tab_rule">Сокиряны Клокушна</td><td class="tab_rule">авто</td></tr>
   <tr><td class="tab_rule">53</td><td class="tab_rule">Кельменцы Ларга</td><td class="tab_rule">ж/д</td></tr>
   <tr><td class="tab_rule">54</td><td class="tab_rule">Мамалыга Крива</td><td class="tab_rule">ж/д</td></tr>
   <tr><td class="tab_rule">55</td><td class="tab_rule">Сокиряны Клокушна</td><td class="tab_rule">ж/д</td></tr>
   
</tbody></table>

<p style="text-indent:2em;"></p><h6>Пункты пропуска через государственную границу классифицируются:</h6><p></p>

<p></p><h4>ПО ТИПУ</h4><p></p>

<p style="text-indent:2em;">1.«АВТОМОБИЛЬНЫЕ» - предназначенные для пересечения государственной границы на транспортных средствах и в пешем порядке (для автомобильного, пассажирского и грузового сообщения);</p><br>

<p style="text-indent:2em;">2. «ЖЕЛЕЗНОДОРОЖНЫЕ» - предназначенные для пересечения государственной границы на поездах(для железнодорожного пассажирского и грузового сообщения);</p><br>

<p style="text-indent:2em;">3. «РЕЧНЫЕ» - предназначенные для пересечения молдавско - украинской государственной границы на плавательных средствах (для пассажирского лодочного, катерного, паромного сообщения в светлое время суток);</p><br>

<p style="text-indent:2em;">4. «АВИА» - предназначены для пересечения государственной границы на воздушных суднах (для пассажирского и грузового сообщения).</p><br>

<p><a href="https://customs.gov.md">Источник</a></p>


</div>
`