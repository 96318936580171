import React, { Component } from 'react'
import { toastr } from 'react-redux-toastr'
import reactHtmlParser from 'react-html-parser'

import fetch from '../../shared/fetch'

import News from '../../news/list-mini'
import Ads from '../../ads'
import Forum from '../../forum'

import WidgetBase from '../../shared/components/widget-base'
import './legislation-article.scss'

const STYLE_BASE = 'legislation-article_'

export default class extends Component {
  state = {
    html: '',
    // loading: false,
  }

  componentDidMount() {
    const { lang, area, article } = this.props

    // this.setState({ loading: true })

    fetch(`/common/legislation/${lang}/${area}/${article}`)
      .then(resp => {
        const { html = '', error } = resp.d
        // this.setState({ html, loading: false })
        this.setState({ html })
        error && toastr.error(error.message)
      })
      .catch(err => {
        let { response: { data: { error } = {} } = {} } = err
        error = error || { message: err.message }
        // this.setState({ html: 'nbsp', loading: false })
        this.setState({ html: 'nbsp' })
        toastr.error(error.message)
      })
  }
  getTitle = () => {
    const { t } = this.props
    let { area = '' } = this.props
    area = area.toLowerCase()
    const title =
      area === 'moldova'
        ? t('legislation.widget.title.moldova')
        : area === 'international'
        ? t('legislation.widget.title.international')
        : ''
    return title
  }

  render() {
    const { html } = this.state
    return (
      <div className="row">
        <div className="col-md-10 col-xl-9">
          <WidgetBase title={this.getTitle()}>
            <div className={`${STYLE_BASE}panel`}>{reactHtmlParser(html)}</div>
          </WidgetBase>
        </div>
        <div className="col-md-6 col-xl-7">
          <News />
          <Ads />
          <Forum />
        </div>
      </div>
    )
  }
}
