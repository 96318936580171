export const md14 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>ПРАВИЛА
АВТОТРАНСПОРТНЫХ ПЕРЕВОЗОК ОПАСНЫХ ГРУЗОВ</h4><p></p>

<p></p><h4>I. ОБЩИЕ ПОЛОЖЕНИЯ И ОБЛАСТЬ ПРИМЕНЕНИЯ</h4><p></p>

<p style="font-weight:500;">1. Правила автотранспортных перевозок опасных грузов (в дальнейшем – Правила) устанавливают область применения в Республике
			Молдова положений Европейского соглашения о международной дорожной перевозке опасных грузов (ДОПОГ), совершенного в Женеве 
			30 сентября 1957 года, к которому Республика Молдова присоединилась Постановлением Парламента Республики Молдова № 44-XIV от 4 июня 1998 года.</p><br>
<p style="font-weight:500;">2. Настоящие нормы применяются к автотранспортным перевозкам опасных грузов по территории Республики Молдова, контрольной
			деятельности, проводимой в ходе автотранспортных перевозок опасных грузов в отношении транспортных средств, передвигающихся
			по территории или въезжающих на территорию Республики Молдова из другой страны, проверкам деятельности, осуществляемой
			экономическими агентами, как определено в настоящих Правилах.</p><br>
<p style="font-weight:500;">3. Настоящие нормы не применяются к перевозкам опасных грузов транспортными средствами, принадлежащими публичным учреждениям,
			входящим в состав национальной системы обороны или находящимся в ведении этих учреждений.</p><br>
<p style="font-weight:500;">4. Перевозки опасных грузов транспортными средствами, принадлежащими публичным учреждениям, входящим в состав национальной
			системы обороны, в том числе транспортными средствами, осуществляющими перевозки в их интересах, осуществляются в соответствии
			с нормами, разработанными Министерством обороны, Министерством внутренних дел, Службой информации и безопасности, по необходимости.</p><br>
<p style="font-weight:500;">5. Без ущерба для положений пунктов 18-19 настоящих Правил, опасные грузы, перевозка которых запрещена согласно приложениям
			А и В к ДОПОГ, не подлежат автотранспортной перевозке.</p>
<p style="text-indent:2em;"></p><h6>6. Перевозка остальных опасных грузов, перечисленных в приложении А к ДОПОГ, допускается при условии соблюдения положений
			приложений А и В к ДОПОГ, в частности требований, касающихся:</h6><p></p>
<p>1) упаковки и маркировки этих грузов;</p><br>
<p style="text-indent:2em;">2) конструкции, оборудования и надлежащей эксплуатации автотранспортных средств, перевозящих данные грузы.</p>
<p style="text-indent:2em;"></p><h6>7. В целях настоящих Правил используются следующие определения:</h6><p></p>
<p style="text-indent:2em;">консультант по безопасности перевозки опасных грузов – назначенное на предприятии лицо, ответственное за соблюдение условий
			безопасности при перевозке опасных грузов;</p><br>
<p style="text-indent:2em;">контроль – совокупность действий, осуществляемых органом, наделенным функциями контроля, или группой таких органов, по
			проверке соблюдения лицами, подлежащими контролю, положений законодательства в области автотранспортных перевозок опасных грузов;</p><br>
<p style="text-indent:2em;">предприятие – любое физическое или юридическое лицо, преследующее цель получения прибыли, выполняющее автотранспортные
перевозки грузов в коммерческих целях;</p><br>
<p style="text-indent:2em;">опасные грузы – вещества и изделия, которые не допускаются к перевозке или допускаются к ней только при определенных
обстоятельствах, оговоренных в приложениях А и В к ДОПОГ;</p><br>
<p style="text-indent:2em;">автотранспортная перевозка опасных грузов – операция по автотранспортной перевозке, осуществляемая дорожными транспортными
			средствами по перемещению грузов, на определенном участке дороги, в том числе операции по погрузке и разгрузке опасных грузов,
			предусмотренные в приложениях A и B к ДОПОГ, за исключением операций, осуществляемых только внутри периметра огороженной зоны;</p><br>
<p style="text-indent:2em;">дорожное транспортное средство – самоходная или несамоходная механическая система, предназначенная для передвижения по 
			автомобильным дорогам общего пользования. Считаются дорожными транспортными средствами автотранспортные средства, прицепы,
			полуприцепы и тягачи, определяемые согласно части (1) статьи 5 Кодекса автомобильного транспорта, за исключением транспортных
			средств, принадлежащих вооруженным силам одной из Договаривающихся сторон или находящихся в ведении этих вооруженных сил.</p><br>
<p style="font-weight:500;">8. Определения, предусмотренные в пункте 7 настоящих Правил, дополняются определениями, предусмотренными в главе 1.2 приложения А к ДОПОГ.</p>

<p></p><h4>II. ОТСТУПЛЕНИЯ, ОГРАНИЧЕНИЯ
И ИСКЛЮЧЕНИЯ</h4><p></p>

<p style="font-weight:500;">9. Для перевозки опасных грузов в национальном сообщении, осуществляемой транспортными средствами, зарегистрированными в Республике
			Молдова, Министерство транспорта и дорожной инфраструктуры может принимать дополнительные распоряжения к предусмотренным в приложениях
			A и B к ДОПОГ, если они соответствуют рекомендациям Организации Объединенных Наций по перевозке опасных грузов.</p><br>
<p style="font-weight:500;">10. Положения пункта 9 настоящих Правил могут действовать максимум до тех пор, пока приложения A и B к ДОПОГ переймут путем пересмотра
			эти рекомендации.</p><br>
<p style="font-weight:500;">11. Центральный отраслевой орган информирует Европейскую экономическую комиссию Организации Объединенных Наций о положениях,
			предусмотренных в пункте 9 настоящих Правил.</p><br>
<p style="font-weight:500;">12. Правительство может регулировать или запретить автотранспортную перевозку определенных опасных грузов, строго по другим
			соображениям, чем безопасность во время перевозки, например, из соображений национальной безопасности, запрета на их хранение
			или использование, либо охраны окружающей среды.</p><br>
<p style="font-weight:500;">13. Любые правила, введенные в соответствии с пунктом 12 настоящих Правил в отношении транспортных средств, осуществляющих международные
			перевозки, утверждаются согласно положениям главы 1.9 приложения А к ДОПОГ, ограничиваются по площади и области применения, применяются
			в отношении как национального транспорта, так и международного, и не могут порождать дискриминацию.</p><br>
<p style="font-weight:500;">14. Если центральный отраслевой орган считает, что применяемые положения в области безопасности автотранспортных перевозок опасных грузов 
			недостаточны для ограничения опасности, вызванной автотранспортными перевозками, в случае наступления события и при наличии острой потребности
			принятия меры, в этом случае, в фазе планирования, он уведомляет Европейскую экономическую комиссию Организации Объединенных Наций о предлагаемых
			для принятия мерах, однако эти меры подлежат применению лишь после получения согласия данной Комиссии.</p><br>
<p style="font-weight:500;">15. Без ущерба для положений пункта 47 настоящих Правил, в случае операций по мультимодальным перевозкам, включающим, помимо автотранспортных
			перевозок, морские и воздушные перевозки, если опасные грузы классифицированы, упакованы и маркированы согласно соответствующим правилам для морской
			и воздушной перевозки опасных грузов, в этом случае требования настоящих норм по классификации, упаковке и маркировке считаются выполненными.</p><br>
<p style="font-weight:500;">16. В отступление от положений приложений А и В к ДОПОГ по поводу языка, используемого в соответствующей маркировке и документации, при
			автотранспортной перевозке опасных грузов в национальном сообщении может использоваться лишь государственный язык.</p><br>
<p style="font-weight:500;">17. Переносные сосуды под давлением могут использоваться в соответствии с действующим законодательством.</p><br>
<p style="font-weight:500;">18. Национальное агентство автомобильного транспорта (в дальнейшем – Агентство) в соответствии с положениями Постановления Правительства №
			143 от 18 февраля 2016 г. «Об утверждении Правил внутренней перевозки опасных грузов» допускает проведение ad hoc операции по внутренней
			автотранспортной перевозке опасных грузов, которая либо запрещена приложением А или В к ДОПОГ, либо проводится в иных условиях, чем условия,
			предусмотренные в этих приложениях, при условии, что указанная транспортная операция ad hoc не будет влиять на надежность или безопасность и
			будет четко ограничена во времени и цели.</p><br>
<p style="font-weight:500;">19. В сроки, не превышающие 5 лет, центральный отраслевой орган может утвердить в соответствии с положениями Постановления Правительства №
			143 от 18 февраля 2016 г. некоторые временные отступления от соблюдения приложений А и В к ДОПОГ с соблюдением положений главы 1.5 приложения
			А к ДОПОГ и в результате уведомления Европейской экономической комиссии Организации Объединенных Наций.</p><br>
<p style="font-weight:500;">20. Временные отступления, предусмотренные в пункте 19 настоящих Правил, не подлежат возобновлению.</p><br>
<p style="font-weight:500;">21. Транспортные средства, зарегистрированные или введенные в эксплуатацию в других странах, допускаются к международным перевозкам опасных
			грузов на территории Республики Молдова, если указанные перевозки соблюдают положения ДОПОГ.</p><br>
<p style="font-weight:500;">22. Отступления, предусмотренные в настоящей главе, дополняются отступлениями, предусмотренными в разделе 1.1.3 и в главах 1.6 и 3.4
			приложения A к ДОПОГ.</p>

<p></p><h4>III. ФУНКЦИИ И ОБЯЗАННОСТИ</h4><p></p>

<p style="text-indent:2em;"></p><h6>23. Органы публичного управления имеют следующие обязанности:</h6><p></p>
<p style="text-indent:2em;"></p><h6>1) Министерство транспорта и дорожной инфраструктуры:</h6><p></p>
<p style="text-indent:2em;">a) регулирование условий допущения к перевозке и проведения периодического технического осмотра транспортных средств, предназначенных 
			для перевозки некоторых опасных грузов;</p><br>
<p style="text-indent:2em;">b) размещение на официальной веб-странице министерства списка и текста национальных/международных нормативных актов, применимых к процессу
			организации и осуществления автомобильных перевозок опасных грузов, в том числе Европейского соглашения о международной дорожной перевозке
			опасных грузов, заключенного в Женеве 30 сентября 1957 года, и приложений к нему, в том числе постоянное обновление всех изменений или поправок
			к ним;</p><br>
<p style="text-indent:2em;">c) согласование учебных планов и программ непрерывного профессионального обучения водителей автотранспортных средств, перевозящих опасные
			грузы, и консультантов по вопросам безопасности перевозки опасных грузов. Все свидетельства о подготовке, отвечающие требованиям раздела 8.</p><br>
<p style="font-weight:500;">2.1 приложения В к ДОПОГ, выдаваемые компетентным органом одной договаривающейся стороны, признаются в течение срока их действия компетентными
			органами других договаривающихся сторон;</p><br>
<p style="text-indent:2em;">d) определение автомобильных дорог общего пользования, на которых допускается движение транспортных средств, перевозящих опасные грузы,
			с указанием объездных или альтернативных маршрутов и способа их сигнализации;</p><br>
<p style="text-indent:2em;">e) согласование схем участков дорог Республики Молдова, по которым разрешено перемещение опасных грузов, а также обозначение автомобильных
			дорог соответствующими дорожными знаками, совместно с Национальным инспекторатом по патрулированию Министерства внутренних дел;</p>
<p style="text-indent:2em;"></p><h6>2) Административный орган «Национальное агентство автомобильного транспорта»:</h6><p></p>
<p style="text-indent:2em;">a) выдает по требованию предприятий, через единое окно, акт согласования маршрутов перевозки опасных грузов, в том числе для иностранных
			перевозчиков, перевозящих опасные грузы в Республику Молдова/из Республики Молдова, пересекающих транзитом территорию Республики Молдова
			или в третьи страны/из третьих стран;</p><br>
<p style="text-indent:2em;">b) разрешает проведение ad hoc операции по внутренней автотранспортной перевозке опасных грузов в соответствии с пунктом 18 настоящих
			Правил;</p><br>
<p>c) контроль и мониторинг дорожной перевозки опасных грузов по территории страны;</p><br>
<p style="text-indent:2em;">d) обеспечивает размещение на официальной веб-странице Агентства списка и текста национальных/международных нормативных актов, применимых
			к процессу организации и осуществления автомобильных перевозок опасных грузов, в том числе Европейского соглашения о международной дорожной
			перевозке опасных грузов, заключенного в Женеве 30 сентября 1957 года, и приложений к нему, в том числе постоянное обновление всех изменений
			или поправок к ним;</p><br>
<p style="text-indent:2em;">e) исследует совместно с другими центральными органами публичного управления события, произошедшие при погрузке, разгрузке и автотранспортной 
			перевозке опасных грузов, а также устанавливает и применяет необходимые меры;</p>
<p style="text-indent:2em;"></p><h6>3) Министерство внутренних дел:</h6><p></p>
<p style="text-indent:2em;">a) согласует и контролирует совместно с Министерством окружающей среды импорт, экспорт, перевозку вредных продуктов и веществ, а также
			перевозку опасных грузов по территории страны;</p><br>
<p style="text-indent:2em;">b) обеспечивает при пересечении государственной границы контроль автотранспортных средств, привлеченных для перевозки опасных грузов;</p><br>
<p style="text-indent:2em;">c) устанавливает и утверждает совместно с заинтересованными органами публичного управления численность сил и количество средств, необходимых для
			ликвидации последствий возможных аварий и манипулирования опасными грузами, а также согласовывает перевозки вредных веществ и осуществляет за
			ними надзор;</p><br>
<p style="text-indent:2em;">d) осуществляет оперативное вмешательство в случае наступления события или неминуемости наступления события во время автотранспортной перевозки
			опасных грузов;</p><br>
<p style="text-indent:2em;">e) совместно с Министерством транспорта и дорожной инфраструктуры согласует схемы участков дорог Республики Молдова, по которым разрешено
			перемещение опасных грузов;</p><br>
<p style="text-indent:2em;">f) разрабатывает совместно с местными публичными органами планы аварийной сигнализации и вмешательства в случае аварийного загрязнения окружающей
			среды при перевозке опасных грузов;</p><br>
<p style="text-indent:2em;">g) исследует совместно с другими центральными органами публичного управления события, произошедшие при погрузке, разгрузке и автотранспортной 
			перевозке опасных грузов, а также устанавливает и применяет необходимые меры;</p><br>
<p style="text-indent:2em;">h) разрабатывает и осуществляет контроль за соблюдением подведомственными единицами и структурами норм, предусмотренных в пункте 4 настоящих
			Правил;</p><br>
<p style="text-indent:2em;">i) совместно с Таможенной службой организует обучение сотрудников, в функции которых входит осуществление контроля за соблюдением экономическими 
			агентами правил перевозки опасных грузов;</p><br>
<p style="font-weight:500;">4) Министерство обороны: разрабатывает и осуществляет контроль за соблюдением подведомственными единицами и учреждениями норм, предусмотренных в
			пункте 4 настоящих Правил;</p><br>
<p style="font-weight:500;">5) Министерство окружающей среды: совместно с Министерством внутренних дел осуществляет контроль за перевозкой опасных отходов и их уничтожением 
			в соответствии с положениями Базельской конвенции по указанным вопросам, к которой Республика Молдова присоединилась Постановлением Парламента № 
			1599-XIII от 10 марта 1998 г.;</p><br>
<p style="font-weight:500;">6) Таможенная служба: обеспечивает соблюдение таможенных правил при перевозке через таможенную границу опасных грузов на территорию Республики
			Молдова;</p><br>
<p style="font-weight:500;">7) Институт стандартизации Молдовы: обеспечивает принятие в качестве молдавских стандартов и общедоступность европейских, международных стандартов
			и других документов по стандартизации веществ, относящихся к опасным, по поводу их упаковки и методов сертификации в целях гармонизации национального
			технического законодательства с требованиями ДОПОГ;</p><br>
<p style="font-weight:500;">8) Министерство просвещения: утверждает методологию профессиональной подготовки водителей автотранспортных средств, перевозящих опасные грузы, 
			консультантов по вопросам безопасности перевозки опасных грузов и экспертов, осуществляющих технический осмотр транспортных единиц, перевозящих 
			опасные грузы.</p>
<p style="text-indent:2em;"></p><h6>24. Для согласования маршрутов перевозки опасных грузов автотранспортные операторы должны представить Агентству заявление, содержащее следующую 
			информацию:</h6><p></p>
<p>1) пункт отправления (погрузки), пункт назначения (разгрузки) опасного груза;</p><br>
<p>2) наименование населенных пунктов, входящих в маршрут;</p><br>
<p style="text-indent:2em;">3) название, классы и подклассы перевозимых по маршруту опасных грузов, типы опасности;</p><br>
<p>4) количество (вес) опасных грузов, подлежащее перевозке;</p><br>
<p>5) период перевозки.</p>
<p style="text-indent:2em;"></p><h6>25. Передача Агентству заявления, предусмотренного в пункте 24, осуществляется следующими способами:</h6><p></p>
<p style="text-indent:2em;">1) путем подачи лично менеджером по автотранспортным перевозкам или уполномоченным представителем предприятия, под роспись, в
			единое окно Агентства;</p><br>
<p>2) заказным письмом с уведомлением о вручении;</p><br>
<p>3) в электронном формате.</p><br>
<p style="font-weight:500;">26. Агентство рассматривает документы автотранспортных операторов в течение 20 календарных дней с даты их подачи, в том числе 
			осуществляет согласование маршрутов с уполномоченными органами.</p><br>
<p style="font-weight:500;">27. Агентство отклоняет документы, поданные в целях согласования маршрутов перевозки опасных грузов, если не соблюдены положения 
			пункта 24 настоящих Правил, в течение 7 календарных дней. В случае отклонения документов, в решении указываются мотивы, послужившие 
			основанием для его принятия.</p>
<p style="text-indent:2em;"></p><h6>28. В случае удовлетворения поданного заявления Агентство:</h6><p></p>
<p style="text-indent:2em;"></p><h6>1) выдает акт согласования в течение 3 календарных дней:</h6><p></p>
<p style="text-indent:2em;">2) обеспечивает согласование акта с компетентными органами в течение 7 календарных дней;</p><br>
<p style="text-indent:2em;">3) обеспечивает отправку акта согласования в электронном формате в адрес заявителя и других компетентных органов, по необходимости, 
			в течение 10 календарных дней с даты подачи документов;</p><br>
<p>4) размещает копию акта согласования на официальной веб-странице учреждения.</p>
<p style="text-indent:2em;"></p><h6>29. Акт согласования маршрутов перевозки опасных грузов должен содержать следующие сведения:</h6><p></p>
<p>1) наименование автотранспортного оператора;</p><br>
<p>2) срок действия маршрута;</p><br>
<p>3) ограничения движения по маршруту: время суток, время года;</p><br>
<p style="text-indent:2em;">4) пункты пропуска через государственную границу, в случае услуг, выполняемых иностранными операторами;</p><br>
<p style="text-indent:2em;">5) наименование дорог вне населенных пунктов, по которым разрешено проследовать транспортным средствам с опасными грузами, в том числе
			следующих транзитом через территорию Республики Молдова;</p><br>
<p style="text-indent:2em;">6) наименование населенных пунктов и их улиц, по которым разрешено проследовать транспортным средствам с опасными грузами;</p><br>
<p>7) максимально разрешенная скорость движения на маршруте;</p><br>
<p>8) адрес специализированных автостоянок и АЗС;</p><br>
<p>9) особые условия движения;</p>
<p style="text-indent:2em;"></p><h6>10) местонахождение и номера телефонов:</h6><p></p>
<p>a) Национального инспектората по патрулированию;</p><br>
<p>b) Службы гражданской защиты и чрезвычайных ситуаций;</p><br>
<p>c) служб аварийного спасения;</p><br>
<p>d) Национального центра догоспитальной скорой медицинской помощи.</p>
<p style="text-indent:2em;"></p><h6>30. Агентство обеспечивает:</h6><p></p>
<p style="text-indent:2em;">1) создание базы данных выдаваемых актов согласования, ее обновление и помещение на официальном сайте учреждения;</p><br>
<p style="text-indent:2em;">2) онлайн уведомление предприятий, транспортных операторов, Пограничной полиции и Таможенной службы о выдаваемых актах согласования;</p><br>
<p style="text-indent:2em;">3) доступ предприятий, транспортных операторов и уполномоченных органов к базе данных.</p><br>
<p style="font-weight:500;">31. Транспортные операторы обеспечивают распечатку и наличие на борту транспортного средства акта согласования маршрутов перевозки
			опасных грузов.</p><br>
<p style="font-weight:500;">32. Акт согласования маршрутов перевозки опасных грузов является именным документом транспортного оператора.</p><br>
<p style="font-weight:500;">33. Срок действия акта согласования маршрутов перевозки опасных грузов не может превышать 12 месяцев.</p><br>
<p style="font-weight:500;">34. Обязанности основных участников процесса перевозки опасных грузов, соответственно грузоотправителя, перевозчика и грузополучателя,
			установлены в разделах 1.4.1 и 1.4.2 приложения A к ДОПОГ.</p><br>
<p style="font-weight:500;">35. Обязанности остальных участников процесса перевозки опасных грузов, соответственно поставщика/грузоотправителя, упаковщика,
			ответственного за наполнение и оператора контейнера-цистерны или переносной цистерны, установлены в разделах 1.4.1 и 1.4.3 приложения
			A к ДОПОГ.</p><br>
<p style="font-weight:500;">36. Предприятия, осуществляющие погрузку, отгрузку, перевозку или разгрузку опасных грузов, должны назначить для каждого рабочего
			пункта не менее одного консультанта по вопросам безопасности перевозки опасных грузов, который должен убедиться, что выполнены условия
			безопасности перевозки опасных грузов.</p>
<p style="text-indent:2em;"></p><h6>1) Консультант по вопросам безопасности перевозки опасных грузов должен иметь свидетельство о профессиональной подготовке, согласно
			приложению № 5, выданное аккредитованным центром обучения, совершенствования и профессиональной аттестации, в соответствии с положениями
			раздела 1.8.3 приложения А к ДОПОГ, полученное после окончания курса профессиональной подготовки и сдачи соответствующих экзаменов по 
			одной из следующих специализаций:</h6><p></p>
<p>Взрывчатые вещества: класс 1;</p><br>
<p>Газы: класс 2;</p><br>
<p>Радиоактивные материалы: класс 7;</p><br>
<p>Жидкие и твердые вещества: классы 3, 4.1, 4.2, 4.3, 5.1, 5.2, 6.1, 6.2, 8 и 9;</p><br>
<p style="text-indent:2em;"> Жидкое топливо: № ООН 1202, 1203, 1223, 3475 и авиационное топливо, отнесенное к № ООН 1268 или 1863.</p><br>
<p style="text-indent:2em;">2) Функции консультанта по вопросам безопасности перевозки опасных грузов может выполнять любой работник предприятия, владеющий свидетельством 
			о профессиональной подготовке, а также руководитель предприятия или третье лицо, владеющее свидетельством о профессиональной подготовке и
			назначенное руководителем предприятия.</p><br>
<p style="font-weight:500;">37. Водители, перевозящие опасные грузы, должны иметь ADR – свидетельство о профессиональной подготовке водителей в соответствии с 
			положениями приложения № 1 к настоящим Правилам, срок действия которого составляет пять лет, выданное аккредитованным центром обучения,
			совершенствования и профессиональной аттестации, в соответствии с положениями разделов 8.2.1 и 8.2.2 приложения В к ДОПОГ.</p>
<p style="text-indent:2em;"></p><h6>38. Специальные требования к профессиональной подготовке водителей:</h6><p></p>
<p style="text-indent:2em;">1) учебный центр должен гарантировать, что преподаватели имеют высшее образование и соответствующую специализацию. Программа подготовки
			должна устанавливаться в соответствии с авторизацией, предусмотренной в подразделе 8.2.2.6, и основываться на темах, указанных в пунктах 
			8.2.2.3.2–8.2.2.3.5 приложения B к ДОПОГ;</p><br>
<p style="text-indent:2em;">2) подготовка проводится в виде базового курса подготовки и, при необходимости, специализированных курсов подготовки. Базовые курсы
			подготовки и специализированные курсы подготовки могут предоставляться в рамках всеобъемлющих курсов подготовки, проводимых на комплексной
			основе одним и тем же центром;</p><br>
<p style="text-indent:2em;">3) центры обучения, совершенствования и профессиональной аттестации, аккредитованные Национальным агентством по обеспечению качества в
			профессиональном образовании и/или международными организациями в сфере профессиональной подготовки водителей транспортных средств, перевозящих
			опасные грузы, и консультантов по вопросам безопасности перевозки опасных грузов, обладающие необходимым оборудованием для изготовления «ADR
			– свидетельства о профессиональной подготовке водителей» согласно стандартам ISO, вправе изготавливать эти свидетельства от имени Агентства
			и обязаны вести их строгий учет, а также информировать Агентство в запрашиваемой им форме.</p><br>
<p style="font-weight:500;">39. Лица, участвующие в перевозке опасных грузов, кроме предусмотренных в пунктах 36 и 37 настоящих Правил, должны получить подготовку в
			аккредитованном центре обучения, совершенствования и профессиональной аттестации, согласно положениям главы 1.3 приложения A к ДОПОГ и
			раздела 8.2.3 приложения B к ДОПОГ.</p>
<p style="text-indent:2em;"></p><h6>40. Транспортные средства, используемые для перевозки опасных грузов, должны удовлетворять в совокупности следующие условия:</h6><p></p>
<p>1) соответствовать положениям главы 8.1 приложения B к ДОПОГ;</p><br>
<p>2) содержать информационное табло в соответствии с главой 5.3 приложения A к ДОПОГ;</p><br>
<p style="text-indent:2em;">3) иметь свидетельство о допущении к перевозке, согласно положениям части 9 приложения B к ДОПОГ.</p><br>
 <p style="font-weight:500;">41. Предприятия, осуществляющие перевозку грузов повышенной опасности, предусмотренных таблицей 1.10.5 приложения A к ДОПОГ, должны соблюдать
			положения главы 1.10 приложения A к ДОПОГ.</p>
<p style="text-indent:2em;"></p><h6>42. Для загрузки в транспортные средства с целью перевозки, опасные грузы должны удовлетворять следующие условия:</h6><p></p>
<p style="text-indent:2em;">1) быть допущенными к автотранспортной перевозке в соответствии с главой 2.2 и таблицей A главы 3.2 приложения A к ДОПОГ;</p><br>
<p style="text-indent:2em;">2) быть полностью классифицированными в соответствии с положениями части 2 приложения А к ДОПОГ;</p><br>
<p style="text-indent:2em;">3) быть упакованными в упаковки или цистерны в соответствии с положениями части 4 и главы 5.1 приложения A к ДОПОГ;</p><br>
<p style="text-indent:2em;">4) быть маркированными и этикетированными в соответствии с главой 5.2 приложения A к ДОПОГ.</p><br>
<p style="font-weight:500;">43. Помимо документов на автотранспортные перевозки, предписанных другими правилами, на борту дорожных транспортных средств, перевозящих 
			опасные грузы, должны находиться документы, установленные в разделе 8.1.2 приложения В к ДОПОГ, которые хранятся в оранжевой папке.</p><br>
<p style="font-weight:500;">44. Аккредитованные центры обучения, совершенствования и профессиональной аттестации, в целях соблюдения положений глав 8.1, 8.3 и 8.4 
			приложения B к ДОПОГ, обеспечивают, за отдельную плату, водителей во время обучения учебной литературой, оранжевыми папками для хранения
			документов, которые должны находиться на борту транспортной единицы (письменные инструкции, свидетельство о допущении к перевозке 
			транспортного средства и т.д.).</p><br>
<p style="font-weight:500;">45. Тара, контейнеры средней грузоподъемности для массовых грузов (КСГМГ), крупногабаритная тара, цистерны и контейнеры для массовых 
			грузов, используемые для перевозки опасных грузов, должны соответствовать требованиям, установленным в части 6 приложения A к ДОПОГ.</p><br>
<p style="font-weight:500;">46. При погрузке, перевозке и разгрузке опасных грузов следует соблюдать положения главы 5.5 и части 7 приложения A к ДОПОГ.</p><br>
<p style="font-weight:500;">47. В случае комбинированной или мультимодальной перевозки опасных грузов или использования оборудования, построенного для соответствия
			требованиям правил, предусматривающих другие виды перевозок, в дополнение к положениям пункта 19 настоящих Правил применяются соответствующие
			положения раздела 1.1.4 приложения A к ДОПОГ.</p><br>
<p style="font-weight:500;">48. Предприятия, работающие с радиоактивными материалами класса 7, должны соблюдать и положения главы 1.7 приложения A к ДОПОГ.</p><br>
<p style="font-weight:500;">49. При классификации, маркировке, упаковке, обработке, погрузке, разгрузке и автотранспортной перевозке опасных грузов, предприятия обязаны
			соблюдать все положения, указанные в главах 3.1 и 3.2 приложения A к ДОПОГ.</p><br>
<p style="font-weight:500;">50. В случае серьезного происшествия во время перевозки опасных грузов на территории Республики Молдова, государства-члена Европейского Союза
			или государства, которое является Договаривающейся стороной ДОПОГ, предприятия, зарегистрированные в Республике Молдова, автомобили которых
			попали в аварию, обязаны составить отчет в соответствии с положениями раздела 1.8.5 приложения А к ДОПОГ и представить его Агентству.</p>
<p style="text-indent:2em;"></p><h6>51. Члены экипажей транспортных средств, перевозящих опасные грузы, обязаны:</h6><p></p>
<p>1) соблюдать положения глав 8.1, 8.3 и 8.4 приложения B к ДОПОГ;</p><br>
<p style="text-indent:2em;">2) иметь при себе, на протяжении всей перевозки, удостоверяющие личность документы и, в случае водителя, - действительное ADR - свидетельство
			о профессиональной подготовке водителей, соответствующее типу транспортного средства и перевозимых грузов, согласно приложению №1 к настоящим 
			Правилам;</p><br>
<p style="text-indent:2em;">3) в случае происшествий, в которых участвуют опасные грузы, принять меры, предусмотренные в письменных инструкциях, и, если это применимо, 
			предупредить/проинформировать об этом компетентные органы, с вмешательством в подобные ситуации.</p>

<p></p><h4>IV. ПЕРИОДИЧЕСКИЙ ТЕХНИЧЕСКИЙ ОСМОТР ТРАНСПОРТНЫХ СРЕДСТВ, ПЕРЕВОЗЯЩИХ ОПАСНЫЕ ГРУЗЫ</h4><p></p>

<p style="font-weight:500;">52. Эксперты ДОПОГ по сертификации автотранспортных средств, перевозящих опасные грузы, обязаны иметь свидетельство о профессиональной
			компетенции, в соответствии с приложением № 2 к настоящим Правилам, на условиях статей 43 и 127 Кодекса автомобильного транспорта, выданное
			аккредитованными центрами обучения, совершенствования и профессиональной аттестации в соответствии с Кодексом Республики Молдова об 
			образовании.</p><br>
<p style="font-weight:500;">53. Периодический технический осмотр транспортных средств, перевозящих опасные грузы, осуществляется на станциях технического осмотра,
			получивших разрешение Агентства в соответствии с главой 6 Кодекса автомобильного транспорта.</p><br>
<p style="font-weight:500;">54. Бланки свидетельств о допущении к перевозке, согласно приложению № 3 к настоящим Правилам, выдаются станциям технического осмотра,
			получившим разрешение Агентства, посредством единого окна.</p><br>
<p style="font-weight:500;">55. Свидетельство о допущении к перевозке выдается для каждой транспортной единицы в отдельности.</p><br>
<p style="font-weight:500;">56. Срок действия свидетельства о допущении к перевозке составляет 6 месяцев, после которого может продлеваться на аналогичный период.</p><br>
<p style="font-weight:500;">57. Свидетельство о допущении к перевозке подлежит возврату органу, выдавшему свидетельство, при изъятии транспортного средства из
			обращения, при смене транспортного оператора, пользователя или владельца, указанного в графе 5 данного свидетельства, при истечении 
			срока действия, а также при изменении характеристик транспортного средства.</p>

<p></p><h4>V. КОНТРОЛЬ</h4><p></p>

<p style="text-indent:2em;"></p><h6>58. Контроль за соблюдением настоящих норм осуществляется:</h6><p></p>
<p style="text-indent:2em;">1) персоналом, наделенным функциями контроля в области дорожного движения, уполномоченным и обученным Агентством, по местонахождению
			предприятий;</p><br>
<p style="text-indent:2em;">2) персоналом, наделенным функциями контроля относительно соблюдения норм, предусмотренных в пункте 4 настоящих Правил, подведомственными
			единицами и учреждениями, уполномоченным и обученным Министерством обороны;</p><br>
<p style="text-indent:2em;">3) Пограничной полицией и Таможенной службой, в пределах компетенции, в пунктах пропуска через государственную границу.</p><br>
<p style="font-weight:500;">59. Компетентные органы, уполномоченные осуществлять контроль согласно пункту 58 настоящих Правил, должны убедиться в том, что
			репрезентативный сегмент автотранспортных перевозок опасных грузов является объектом контроля, установленного настоящими нормами,
			с целью проверки их соответствия положениям законодательства об автотранспортных перевозках опасных грузов.</p><br>
<p style="font-weight:500;">60. При осуществлении контроля за соблюдением настоящих норм, органы, предусмотренные в пункте 58 настоящих Правил, будут следить
			за соблюдением положений раздела 1.8.1 приложения А к ДОПОГ.</p><br>
<p style="font-weight:500;">61. При осуществлении контроля в движении, в пунктах погрузки/разгрузки опасных грузов, персонал, наделенный функциями контроля,
			предусмотренный в пункте 58 настоящих Правил, обязуется заполнить бюллетень контроля, предусмотренный в приложении № 4 к настоящим
			Правилам, в 3 экземплярах, из которых один экземпляр вручается водителю.</p><br>
<p style="font-weight:500;">62. Положения пункта 61 настоящих Правил не умаляют права персонала, предусмотренного в пункте 58 настоящих Правил, осуществлять
			контроль любой перевозки опасных грузов согласно компетенции.</p><br>
<p style="font-weight:500;">63. Контроль в движении осуществляется с соблюдением положений Закона № 131 от 8 июня 2012 года о государственном контроле
			предпринимательской деятельности в соответствии с принципами Общей методологии планирования государственного контроля предпринимательской
			деятельности на основе анализа критериев риска, утвержденной Постановлением Правительства № 694 от 5 сентября 2013 г., и охватят обширную
			часть дорожной сети.</p>
<p style="text-indent:2em;"></p><h6>64. Места, выбранные для осуществления контроля в движении, должны выполнять следующие условия:</h6><p></p>
<p style="text-indent:2em;">1) позволять изъятие из дорожного движения, остановку и стоянку транспортных средств в условиях безопасности;</p><br>
<p style="text-indent:2em;">2) позволять приведение в соответствие с положениями настоящих норм несоответствующих перевозок;</p><br>
<p style="text-indent:2em;">3) позволять, при необходимости, иммобилизацию транспортных средств на месте или в другом месте, предназначенном для этой цели, персоналом,
			наделенным функциями контроля, не ставя под угрозу безопасность.</p><br>
<p style="font-weight:500;">65. При наличии расхождений между записями в транспортных документах и перевозимыми грузами, при условии, что это не ставит под угрозу
			безопасность, в движении или по местонахождению предприятий, осуществляется отбор образцов груза для рассмотрения уполномоченными
			лабораториями.</p><br>
<p style="font-weight:500;">66. Продолжительность контроля не должна превышать 30 минут – в дорожном движении и 4 часа – по местонахождению предприятий.</p><br>
<p style="font-weight:500;">67. При осуществлении контроля транспортных средств, загружающих, перевозящих или разгружающих опасные грузы, персонал, предусмотренный
			в пункте 58 настоящих Правил, вправе проникать в грузовое отделение для осуществления необходимого контроля и проверок.</p><br>
<p style="font-weight:500;">68. При осуществлении контроля по местонахождению предприятий, персонал, предусмотренный в пункте 58 настоящих Правил, вправе проникать
			в места, предназначенные для стоянки транспортных средств, осуществляющих перевозку опасных грузов, а предприятия обязуются обеспечить
			доступ персонала в эти места для осуществления контроля указанных транспортных средств.</p><br>
<p style="font-weight:500;">69. При осуществлении контроля по местонахождению предприятий, они обязаны иметь в своем офисе документы, предусмотренные статьей 48
			Кодекса автомобильного транспорта, в том числе копии согласованных маршрутов перевозки опасных грузов, затребованные персоналом, предусмотренным
			в пункте 58 настоящих Правил.</p><br>
<p style="font-weight:500;">70. Не препятствуя наложению других санкций, установленных согласно законодательству, транспортные средства, представляющие неизбежную
			опасность путем нарушения одного или нескольких правил дорожного движения, в частности, предусмотренных в пунктах 81 и 84 настоящих Правил,
			будут иммобилизованы на месте или в другом месте, предназначенном для этой цели, персоналом, наделенным функциями контроля, и от них
			потребуется приведение в соответствие перед продолжением движения или будут приняты меры в зависимости от обстоятельств или требований
			по безопасности, которые могут включать отказ в доступе на территорию Республики Молдова.</p><br>
<p style="font-weight:500;">71. В случае, когда в течение 72 часов с момента иммобилизации транспортного средства правонарушитель не устранил причины, вызвавшие
			иммобилизацию, персонал, наделенный функциями контроля, может привлечь третью сторону для устранения причин иммобилизации, в этом случае
			расходы, связанные с проведением операций по устранению, несет правонарушитель.</p><br>
<p style="font-weight:500;">72. Положения пункта 71 настоящих Правил не относятся к ситуациям, когда требования безопасности требуют немедленного вмешательства
			уполномоченных органов для устранения состояния неминуемой опасности.</p>
<p style="text-indent:2em;"></p><h6>73. Контроль по месту нахождения предприятий осуществляется в следующих ситуациях:</h6><p></p>
<p style="text-indent:2em;">1) если в процессе дорожного движения установлены нарушения, которые ставят под угрозу безопасность перевозки опасных грузов;</p><br>
<p style="text-indent:2em;">2) если контролирующие органы других государств-членов или договаривающихся сторон ДОПОГ проинформировали органы, предусмотренные в
			пункте 58 настоящих Правил, о серьезных или неоднократных нарушениях правил перевозки опасных грузов.</p><br>
<p style="font-weight:500;">74. Целью данного контроля является обеспечение соблюдения законодательства об условиях безопасности во время автотранспортной перевозки
			опасных грузов.</p><br>
<p style="font-weight:500;">75. При выявлении одного или нескольких нарушений правил автотранспортной перевозки опасных грузов, в частности, предусмотренных в
			пунктах 81, 84 и 87 настоящих Правил, запрещается осуществление автотранспортных операций до их устранения.</p><br>
<p style="font-weight:500;">76. Для целей настоящих норм нарушения, предусмотренные в пунктах 81, 84 и 87 настоящих Правил, разделены на 3 категории, классифицированные
			в зависимости от размера риска, который они представляют.</p><br>
<p style="font-weight:500;">77. Орган, осуществляющий контроль, является ответственным за подведение фактов под соответствующие категории риска с учетом
			положений пунктов 81, 84 и 87 настоящих Правил.</p><br>
<p style="font-weight:500;">78. В случае выявления нескольких нарушений на одной транспортной единице, в пункте 31 бюллетеня контроля, предусмотренного в
			приложении № 4 к настоящим Правилам, указывается лишь наиболее серьезная категория риска.</p><br>
<p style="font-weight:500;">79. I категория риска присваивается в случаях, когда несоблюдение положений настоящих норм приводит к высокому риску смерти,
			нанесению тяжких телесных повреждений или причинения значительного ущерба окружающей среде.</p><br>
<p style="font-weight:500;">80. За нарушения первой категории риска незамедлительно принимаются соответствующие меры безопасности, такие как иммобилизация
			транспортного средства, в соответствии с законом.</p>
<p style="text-indent:2em;"></p><h6>81. Нарушениями I категории риска являются следующие деяния:</h6><p></p>
<p>1) перевозка опасных грузов, запрещенных к перевозке;</p><br>
<p>2) утечка опасных веществ;</p><br>
<p style="text-indent:2em;">3) использование запрещенного способа перевозки или неподходящего транспортного средства;</p><br>
<p style="text-indent:2em;">4) перевозка насыпью в контейнере, который конструктивно не пригоден для использования;</p><br>
<p style="text-indent:2em;">5) перевозка транспортным средством, не имеющим соответствующего свидетельства о допущении к перевозке определенных опасных
			грузов;</p><br>
<p style="text-indent:2em;">6) транспортное средство больше не соответствует условиям допущения к перевозке и представляет непосредственную опасность.
			(Если транспортное средство не представляет непосредственной опасности, нарушение обнаруживается во второй категории риска);</p><br>
<p>7) использование упаковки, не соответствующей применимым инструкциям по упаковке;</p><br>
<p>8) несоблюдение специальных положений, касающихся совместной упаковки;</p><br>
<p>9) несоблюдение положений, касающихся крепления и укладки груза;</p><br>
<p>10) несоблюдение положений, касающихся совместной погрузки упаковок;</p><br>
<p>11) несоблюдение степени наполнения цистерн или упаковок;</p><br>
<p style="text-indent:2em;">12) несоблюдение положений, ограничивающих перевозимое количество в одной транспортной единице;</p><br>
<p style="text-indent:2em;">13) перевозка опасных грузов без указания об их наличии в документах путем маркировки и этикетирования груза или путем размещения
			информационных табло и маркировок на транспортном средстве;</p><br>
<p style="text-indent:2em;">14) перевозка без размещения информационных табло и маркировок на транспортном средстве;</p><br>
<p style="text-indent:2em;">15) отсутствие информации, относящейся к перево-зимому веществу, позволяющей определить правонарушение I категории риска;</p><br>
<p style="text-indent:2em;">16) отсутствие у водителя действительного ADR – свидетельства о профессиональной подготовке водителя;</p><br>
<p>17) использование огня или незащищенной электрической лампы;</p><br>
<p>18) несоблюдение запрета на курение;</p><br>
<p style="text-indent:2em;">19) невыполнение мер, предусмотренных в письменных инструкциях, находящихся на борту транспортного средства и применяемых к
			перевозимому грузу;</p><br>
<p style="text-indent:2em;">20) отказ в доступе или создание препятствий персоналу, предусмотренному в пункте 58 настоящих Правил, в осуществлении контроля
			в движении или по местонахождению предприятий.</p><br>
<p style="font-weight:500;">82. II категория риска присваивается в случае, когда несоблюдение положений настоящих норм приводит к риску нанесения телесных
			повреждений или причинения ущерба окружающей среде.</p><br>
<p style="font-weight:500;">83. В случае совершения нарушений II категории риска, транспортный оператор обязуется следовать настоящим нормам на месте контроля,
			а если это не представляется возможным, устранить выявленные нарушения после завершения транспортной операции.</p>
<p style="text-indent:2em;"></p><h6>84. Нарушениями II категории риска являются следующие деяния:</h6><p></p>
<p>1) транспортная единица имеет более одного прицепа/полуприцепа;</p><br>
<p style="text-indent:2em;">2) транспортное средство больше не соответствует условиям допущения к перевозке, однако не представляет непосредственную опасность;</p><br>
<p style="text-indent:2em;">3) на борту транспортного средства отсутствуют все функциональные огнетушители в соответствии с требованиями настоящих норм.</p><br>
<p style="text-indent:2em;">Огнетушитель может считаться функциональным, если он годен к употреблению, однако отсутствует лишь предписанная пломба и/или
			срок годности. Не считается функциональным огнетушитель, который явно не пригоден к употреблению, например, огнетушитель, у
			которого показания манометра почти равны «нулю»;</p><br>
<p style="text-indent:2em;">4) отсутствие на борту транспортного средства оборудования, предусмотренного в ДОПОГ или письменных инструкциях;</p><br>
<p style="text-indent:2em;">5) требования к испытаниям и осмотру, а также к сроку использования упаковок, контейнеров для массовых грузов или крупногабаритной
			тары не соблюдены;</p><br>
<p style="text-indent:2em;">6) перевозка грузов в поврежденных упаковках, контейнерах для массовых грузов или крупногабаритной таре, или перевозка пустой,
			неочищенной и поврежденной тары;</p><br>
<p style="text-indent:2em;">7) перевозка грузов в упаковке, в контейнере, которые конструктивно не пригодны для использования;</p><br>
<p style="text-indent:2em;">8) цистерны/контейнеры-цистерны, в том числе пустые и не очищенные, не закрытые должным образом;</p><br>
<p style="text-indent:2em;">9) перевозка совместной упаковки, или в дополнительной упаковке, не закрытой должным образом;</p><br>
<p style="text-indent:2em;">10) несоответствующее этикетирование, маркировка или размещение информационных табло;</p><br>
<p style="text-indent:2em;">11) отсутствие письменных инструкций в соответствии с ДОПОГ или наличие письменных инструкций, не применимых в отношении
			перевозимых грузов;</p><br>
<p>12) ненадлежащее наблюдение или неправильная стоянка транспортного средства;</p><br>
<p>13) указание на таре, упаковке или транспортных средствах несуществующих опасностей;</p><br>
<p style="text-indent:2em;">14) использование необученного персонала для выполнения операций по обработке, погрузке или разгрузке опасных грузов;</p><br>
<p style="text-indent:2em;">15) невыполнение, ненадлежащее выполнение или выполнение необученным персоналом операций по очистке, промывке, нейтрализации,
			обеззараживанию или дезинфекции тары или транспортного средства после разгрузки опасных грузов.</p><br>
<p style="text-indent:2em;">Если действия, предусмотренные в подпунктах 14) и 15) настоящего пункта, представляют серьезный риск для здоровья персонала,
			они включаются в первую категорию риска.</p><br>
<p style="text-indent:2em;">Инструктаж персонала, предусмотренного в подпунктах 14) и 15), осуществляется менеджером по перевозкам или консультантом по
			вопросам безопасности, в зависимости от обстоятельств. Предприятия ведут учет инструктажа в реестре (пронумерованном, подшитом
			и скрепленном печатью). Периодичность инструктажа – раз в 6 месяцев.</p><br>
<p style="font-weight:500;">85. III категория риска присваивается в случае, когда несоблюдение положений настоящих норм приводит к незначительному риску
			нанесения телесных повреждений или причинения ущерба окружающей среде.</p><br>
<p style="font-weight:500;">86. В случае совершения нарушений III категории риска, если нет необходимости в принятии соответствующих корректирующих мер
			на месте, они могут быть применены на более позднюю дату по месту нахождения предприятия.</p>
<p style="text-indent:2em;"></p><h6>87. Нарушениями III категории риска являются следующие деяния:</h6><p></p>
<p style="text-indent:2em;">a) размеры табло или этикеток или размеры букв, цифр или знаков на табло или этикетках не соответствуют положениям подпункта
2) пункта 40 и подпункта 4) пункта 42 настоящих Правил;</p><br>
<p style="text-indent:2em;">b) отсутствие в транспортных документах информации, помимо указанной в I категории риска, предусмотренной в подпункте 16)
			пункта 81 настоящих Правил;</p><br>
<p style="text-indent:2em;">c) отсутствие на борту автотранспортного средства ADR – свидетельства о профессиональной подготовке водителя, в то время
			как имеются доказательства того, что имеется такое свидетельство;</p><br>
<p>d) неназначение консультанта/консультантов по вопросам безопасности на предприятии;</p><br>
<p style="text-indent:2em;">e) несоставление и/или несообщение Агентству, выдавшему свидетельство консультанту по вопросам безопасности предприятия,
			годового отчета консультанта по вопросам безопасности в соответствии с положениями подраздела 1.8.3.3 ДОПОГ;</p><br>
<p style="text-indent:2em;">f) несоставление, ненадлежащее составление, невнедрение и/или ненадлежащее внедрение предприятием плана обеспечения безопасности,
			затребованного в соответствии с положениями главы 1.10 ДОПОГ.</p><br>
<p style="font-weight:500;">88. Иммобилизация транспортного средства, предусмотренная в пунктах 60-71 и 78-80 настоящих Правил, осуществляется в условиях
			безопасности до приведения в соответствие для продолжения маршрута.</p>
<p style="text-indent:2em;"></p><h6>89. Мера по иммобилизации транспортного средства, назначенная персоналом, наделенным функциями контроля, указывается в
			следующих документах:</h6><p></p>
<p>1) Протокол о правонарушении;</p><br>
<p>2) бюллетень контроля;</p><br>
<p>3) диаграмма тахографа, по необходимости;</p><br>
<p>4) доказательство, выданное водителю, в случае снятия номерных знаков.</p><br>
<p style="font-weight:500;">90. На время иммобилизации обеспечение целостности и безопасности транспортного средства и груза находится под правовой
			ответственностью перевозчика.</p>
<p style="text-indent:2em;"></p><h6>91. Персонал, наделенный функциями контроля, предусмотренный в пункте 58 настоящих Правил, вправе удержать:</h6><p></p>
<p style="text-indent:2em;">1) свидетельство о допущении к перевозке – если установлено, что оно используется другим автотранспортным средством, кроме
			указанного, содержит видимые подчистки, его срок действия истек;</p><br>
<p style="text-indent:2em;">2) свидетельство о профессиональной подготовке водителя – если установлено, что оно было передано третьим лицам и/или его
			срок действия истек.</p><br>
<p style="font-weight:500;">92. Разрешение на возобновление перевозки транспортными средствами, зарегистрированными в других странах, дается после
			приведения в соответствие с положениями пунктов 39, 43, 46-51 настоящих Правил, а также при условии уплаты наложенного
			штрафа на условиях, предусмотренных действующим законодательством.</p><br>
<p style="font-weight:500;">93. Агентство осуществляет обобщение и анализ информации о проведении и результатах технического осмотра транспортных
			средств в соответствии с условиями ДОПОГ для оптимизации сети станций периодического технического осмотра в области
			автотранспортных перевозок опасных грузов.</p><br>
			
<p><a href="https://www.legis.md/cautare/getResults?doc_id=101382&amp;lang=ru">Источник</a></p>


</div>
`