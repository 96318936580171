import React from 'react'
import { Route, Switch } from 'react-router-dom'

import SearchCargo from './search/index.ts'
import AddCargo from './add'
import ItemPage from '../search/item'
import ProtectedRoute from '../../protectedRoute'
import OffersCargo from './offers'

export default () => {
  return (
    <Switch>
      <ProtectedRoute
        path="/cargo/search"
        component={SearchCargo}
        permissionScope="cargo"
        actionType="search"
      />
      <ProtectedRoute
        path="/cargo/add"
        component={AddCargo}
        permissionScope="cargo"
        actionType="add"
        requireAuth
      />
      <ProtectedRoute
        path="/cargo/edit/:itemId"
        component={AddCargo}
        permissionScope="cargo"
        actionType="edit"
        requireAuth
        checkAuthor
      />
      <ProtectedRoute path="/cargo/offers" component={OffersCargo} requireAuth />
      <Route
        path="/cargo/:itemId"
        render={renderProps => <ItemPage {...renderProps} searchType="cargo" showDateIsShort/>}
      />
    </Switch>
  )
}
