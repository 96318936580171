import React from 'react'
import './not-ready-yet.scss'

const STYLE_BASE = 'not-ready-yet_'
export default props => {
  const search = props.location.search
  const params = new URLSearchParams(search)
  const title = params.get('title')

  return (
    <div className={`${STYLE_BASE}wrapper`}>
      <h3>{title}</h3>
      <div className={`${STYLE_BASE}message`}>Страница в разработке</div>
    </div>
  )
}

// export default class extends Component{

//   render() {
//     return (
//       <div className="error-page">

//         <div className="error-message">
//           Страница не найдена!
//         </div>
//       </div>
//     )
//   }
// }
