import React, { Component, Fragment } from 'react'
import { Field } from 'redux-form'

import WidgetBase from '../../shared/components/widget-base'
import FormBase from '../../UI/field-base'
import { required, passwordValidation, matchToField } from '../../shared/form-validators'

import './style.scss'

export default class extends Component {
  constructor(props) {
    super(props)

    this.matchPassword = this.validatePasswords()
  }

  validatePasswords = () => {
    const { t } = this.props
    return matchToField('password', t('login.password.mismatch'))
  }

  render() {
    const {
      t,
      pristine,
      submitting,
      invalid,
      handleSubmit,
      isLegacyPassword,
      clearModal,
    } = this.props

    return (
      <WidgetBase title={t('login.password.change.title')}>
        {!isLegacyPassword && <div className="fa fa-times icon-close" onClick={clearModal} />}
        <Fragment>
          {isLegacyPassword ? (
            <div className="reregistration-notification-block">
              <p className="text-error">
                <i className="fa fa-exclamation-triangle" />
                <span>{t('attention')}</span>
              </p>
              <p className="reregistration-notification-text">
                <span>{t('reregistration.info3.1')}</span>
                <b>{t('reregistration.info3.2')}</b>
                <span>{t('reregistration.info3.3')}</span>
                <b>{t('reregistration.info3.4')}</b>
                <span>{t('reregistration.info3.5')}</span>
                <b>{t('reregistration.info3.6')}</b>
                <span>{t('reregistration.info3.7')}</span>
              </p>
              <p className="reregistration-notification-text">
                <span>{t('reregistration.info4.1')}</span>
                <b>{t('reregistration.info4.2')}</b>
                <span>{t('reregistration.info4.3')}</span>
                <b>{t('reregistration.info4.4')}</b>
                <span>{t('reregistration.info4.5')}</span>
              </p>
              <p className="reregistration-notification-text">
                <span>{t('reregistration.info5.1')}</span>
                <b>{t('reregistration.info5.2')}</b>
                <span>{t('reregistration.info5.3')}</span>
                <b>{t('reregistration.info5.4')}</b>
              </p>
            </div>
          ) : null}
          <div className="edit-panel-form panel-block">
            <div>
              <div className="edit-panel-input-block">
                <Field
                  name="oldPassword"
                  type="password"
                  component={FormBase}
                  label={t('enter.old.password')}
                  placeholder={t('login.password.new.placeholder')}
                  validate={[required]}
                />
              </div>
              <div className="edit-panel-input-block">
                <Field
                  name="password"
                  type="password"
                  component={FormBase}
                  label={t('login.password.new')}
                  placeholder={t('login.password.new.placeholder')}
                  validate={[required, passwordValidation]}
                />
              </div>
              <div className="edit-panel-input-block">
                <Field
                  name="password2"
                  type="password"
                  component={FormBase}
                  label={t('login.password.new.repeat')}
                  placeholder={t('login.password.new.repeat.placeholder')}
                  validate={[required, this.matchPassword]}
                />
                <button
                  type="submit"
                  className="button button-orange button-block"
                  disabled={pristine || submitting || invalid}
                  onClick={handleSubmit}
                >
                  {t('login.password.change')}
                </button>
              </div>
            </div>
            <div className="edit-panel-notification-block">
              <span className="meta" 
                dangerouslySetInnerHTML={{
                  __html: t('password.rule.message.info')
                }} 
              ></span>
            </div>
          </div>
        </Fragment>
      </WidgetBase>
    )
  }
}
