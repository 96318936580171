import React from 'react'
import { Route, Switch } from 'react-router-dom'

// import ListPage from './list-page'
import Page from './page'

export default () => {
  // render() {
  return (
    <Switch>
      {/* <Route path="/legislation" exact component={ListPage} /> */}
      <Route path="/pages/:lang/:path*" component={Page} />
    </Switch>
  )
  // }
}
