export const int10 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>CONVENTIA din 1978 Natiunilor Unite privind transportul de marfuri pe mare, 1978*)</h4><p></p>

<p></p><h4>PREAMBUL</h4><p></p>

<p style="text-indent:2em;"></p><h6>Statele pãrţi la prezenta convenţie,recunoscind utilitatea elaborãrii de comun acord a anumitor reguli privind transportul de 
mãrfuri pe mare,au hotãrît sa încheie o convenţie în acest scop şi au convenit asupra celor ce urmeazã:</h6><p></p>

<p style="font-weight:500;">PARTEA I</p><br>

<p style="font-weight:500;">Prevederi generale</p><br>

<p style="font-weight:500;">ART. 1</p><br>

<p style="font-weight:500;">Definiţii</p>

<p style="text-indent:2em;"></p><h6>În prezenta convenţie:</h6><p></p>

<p style="text-indent:2em;">1. cãrãuş înseamnã orice persoana care (sau în numele cãreia) a încheiat un contract de transport de mãrfuri pe mare cu 
un încãrcãtor;</p><br>

<p style="text-indent:2em;">2. cãrãuş efectiv înseamnã orice persoana cãreia i s-a încredinţat, de cãtre cãrãuş, efectuarea parţialã sau în întregime a unui 
transport de mãrfuri şi include orice alta persoana cãreia i s-a încredinţat o astfel de sarcina;</p><br>

<p style="text-indent:2em;">3. încãrcãtor înseamnã orice persoana de cãtre care sau în numele cãreia sau din autorizarea cãreia s-a încheiat cu cãrãuşul
 un contract de transport de mãrfuri pe mare, sau orice persoana de cãtre care sau în numele cãreia sau din autorizarea cãreia
 mãrfurile sînt în mod efectiv predate cãrãuşului în legatura cu contractul de transport pe mare;</p><br>

<p>4. destinatar înseamnã persoana indreptatita sa preia mãrfurile;</p><br>

<p style="text-indent:2em;">5. termenul mãrfuri include şi animalele vii; în cazul cînd mãrfurile sînt grupate într-un container, pe o paleta sau pe o alta
 unitate similarã de transport sau în cazul cînd mãrfurile sînt ambalate, termenul mãrfuri include aceste unitãţi de transport sau
 ambalaje, dacã sînt puse la dispoziţie de cãtre încãrcãtor;</p>

<p style="text-indent:2em;"></p><h6>6. contract de transport pe mare înseamnã orice contract prin care cãrãuşul se obliga, contra plãţii unui navlu, sa transporte
 mãrfuri pe mare de la un port la altul; în orice caz, un contract care implica, pe lîngã transportul pe mare, şi transportul
 cu alte mijloace, este considerat contract de transport pe mare în sensul prezentei convenţii numai în mãsura în care are 
 legatura cu transportul pe mare:</h6><p></p>

<p style="text-indent:2em;">7. conosament înseamnã un document care face dovada unui contract de transport pe mare şi a preluãrii sau încãrcãrii mãrfurilor
 de cãtre cãrãuş, prin care cãrãuşul se obliga sa livreze mãrfurile contra prezentãrii acestui document. O astfel de obligaţie
 se realizeazã prin prevederea expresã din document ca mãrfurile sa fie livrate la ordinul unei persoane nominalizate, la ordin,
 sau la purtãtor;</p><br>

<p>8. în scris include, printre altele, telegrama şi telex.</p><br>

<p style="font-weight:500;">ART. 2</p><br>

<p style="font-weight:500;">Domeniul de aplicare</p>

<p style="text-indent:2em;"></p><h6>1. Prevederile prezentei convenţii se aplica la toate
 contractele de transport pe mare între doua state diferite, dacã:</h6><p></p>

<p>a) portul de încãrcare prevãzut în contractul de transport pe mare este situat într-un stat contractant, sau</p><br>

<p>b) portul de descãrcare prevãzut în contractul de transport pe mare este situat într-un stat contractant, sau</p><br>

<p style="text-indent:2em;">c) unul dintre porturile facultative de descãrcare prevãzut în contractul de transport pe mare este portul efectiv de descãrcare
 şi este situat într-un stat contractant, sau</p><br>

<p>d) conosamentul sau alt document care face dovada contractului de transport pe mare este emis într-un stat contractant, sau</p><br>

<p style="text-indent:2em;">e) conosamentul sau alt document care face dovada contractului de transport pe mare stipuleazã ca prevederile prezentei convenţii
 sau legislaţia oricãrui stat care le aplica guverneazã contractul.</p><br>

<p style="text-indent:2em;">2. Prevederile prezentei convenţii se aplica independent de naţionalitatea navei, a cãrãuşului, a cãrãuşului efectiv, a încãrcãtorului,
 a destinatarului sau a oricãrei persoane interesate.</p><br>

<p style="text-indent:2em;">3. Prevederile prezentei convenţii nu se aplica contractelor de navlosire. Totuşi, în cazul cînd se emite un conosament ca urmare a unui
 contract de navlosire, prevederile convenţiei se aplica la un astfel de conosament în cazul în care acesta reglementeazã relaţiile
 dintre cãrãuş şi deţinãtorul conosamentului, dacã acesta din urma nu este şi navlositor.</p><br>

<p style="text-indent:2em;">4. Atunci cînd un contract stipuleazã ca în viitor transportul mãrfurilor se va face prin expeditii succesive, într-o perioada convenitã,
 prevederile prezentei convenţii se vor aplica la fiecare expediţie. Cu toate acestea, atunci cînd un transport se executa potrivit unui
 contract de navlosire, se aplica prevederilor pct. 3 al prezentului articol.</p><br>

<p style="font-weight:500;">ART. 3</p><br>

<p style="font-weight:500;">Interpretarea convenţiei</p><br>

<p style="text-indent:2em;">În interpretarea şi aplicarea prevederilor prezentei convenţii se va tine seama de caracterul ei internaţional
 şi de necesitatea de a promova uniformitatea.</p><br>

<p style="font-weight:500;">PARTEA A II-A</p><br>

<p style="font-weight:500;">RĂSPUNDEREA CĂRĂUŞULUI</p><br>

<p style="font-weight:500;">ART. 4</p><br>

<p style="font-weight:500;">Durata rãspunderii</p><br>

<p style="text-indent:2em;">1. Rãspunderea cãrãuşului pentru mãrfuri în baza acestei convenţii acoperã perioada în care mãrfurile sînt în grija
 sa la portul de încãrcare, pe timpul transportului şi la portul de descãrcare.</p>

<p style="text-indent:2em;"></p><h6>2. În sensul pct. 1 al acestui articol, mãrfurile sînt considerate în grija cãrãuşului:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) din momentul cînd acesta le preia de la:</h6><p></p>

<p>i) încãrcãtor sau de la o persoana care acţioneazã în numele acestuia, sau</p><br>

<p style="text-indent:2em;">ii) o autoritate sau o alta terta persoana cãreia, potrivit legii sau reglementãrilor aplicabile în portul de încãrcare,
 mãrfurile trebuie predate pentru încãrcare;</p>

<p style="text-indent:2em;"></p><h6>b) pînã în momentul cînd cãrãuşul livreaza mãrfurile:</h6><p></p> 

<p>i) prin predarea mãrfurilor destinatarului, sau</p><br>

<p style="text-indent:2em;">ii) în cazurile cînd destinatarul nu primeşte mãrfurile de la cãrãuş, prin punerea lor la dispoziţia destinatarului în
 conformitate cu contractul sau legea, sau cu practicile ce se aplica în portul de descãrcare, sau</p><br>

<p style="text-indent:2em;">iii) prin predarea mãrfurilor unei autoritãţi sau unei terţe pãrţi cãreia, potrivit legii sau reglementãrilor aplicabile
 în portul de descãrcare, trebuie predate mãrfurile.</p><br>

<p style="text-indent:2em;">3. La pct. 1 şi 2 ale prezentului articol, referirea la cãrãuş sau destinatar include, în afarã de cãrãuş sau destinatar,
 şi pe prepuşii sau mandatarii acestora.</p><br>

<p style="font-weight:500;">ART. 5</p><br>

<p style="font-weight:500;">Temeiul rãspunderii</p><br>

<p style="text-indent:2em;">1. Cãrãuşul este rãspunzãtor pentru daune rezultate din pierderea sau avarierea mãrfurilor, precum şi din intirzierea în
 livrare, dacã împrejurarea care a cauzat pierderea, avarierea sau intirzierea s-a produs în timpul cît mãrfurile se aflau
 în grija sa în sensul art. 4, dacã nu dovedeşte ca el, prepuşii sau mandatarii sãi au luat toate mãsurile care se cereau în
 mod rezonabil sa fie luate pentru a evita apariţia şi consecinţele acestei împrejurãri.</p><br>

<p style="text-indent:2em;">2. Se considera intirziere a livrãrii atunci cînd mãrfurile nu au fost livrate la portul de descãrcare prevãzut în contractul
 de transport maritim în termenul convenit în mod expres sau, în lipsa unui asemenea acord, într-un termen ce poate fi în mod
 rezonabil pretins unui cãrãuş, diligent, avînd în vedere împrejurãrile de fapt.</p><br>

<p style="text-indent:2em;">3. Persoana indreptatita sa facã o reclamaţie pentru pierderea mãrfurilor poate considera mãrfurile ca pierdute dacã acestea
 nu au fost livrate, asa cum prevede art. 4, în termen de 60 de zile consecutive de la expirarea termenului de livrare conform
 pct. 2 al prezentului articol.</p>

<p style="text-indent:2em;"></p><h6>4. a) Cãrãuşul este rãspunzãtor:</h6><p></p>

<p style="text-indent:2em;">i) pentru pierderea sau avariile provocate mãrfurilor sau pentru intirzierea în livrare cauzatã de incendiu,
 dacã reclamantul dovedeşte ca incendiul a rezultat dintr-o greseala sau dintr-o neglijenţa a cãrãuşului, a
 prepuşilor sau mandatarilor sãi;</p><br>

<p style="text-indent:2em;">ii) pentru pierderea, avariile sau întîrzierile în livrarea mãrfurilor pentru care reclamantul dovedeşte ca sînt
 un rezultat al culpei sau al neglijentei cãrãuşului, a prepuşilor sau a mandatarilor sãi în luarea tuturor mãsurilor
 care pot fi pretinse în mod rezonabil pentru a stinge incendiul şi a evita sau a limita consecinţele acestuia.</p><br>

<p style="text-indent:2em;">b) În cazul cînd incendiul la bordul navei afecteazã mãrfurile, dacã reclamantul sau cãrãuşul doreşte, va fi efectuat
 un raport de expertiza, în conformitate cu practica transporturilor maritime, pentru determinarea cauzelor şi a
 împrejurãrilor ce au provocat incendiul, şi o copie a raportului întocmit de expert va fi pusã, la cerere, la
 dispoziţia cãrãuşului şi a reclamantului.</p><br>

<p style="text-indent:2em;">5. În cazul transportului de animale vii, cãrãuşul nu este rãspunzãtor de pierderile, daunele sau intirzierea în
 livrare care rezulta din riscuri speciale inerente acestui fel de transport. Dacã cãrãuşul dovedeşte ca s-a conformat
 instrucţiunilor speciale care i-au fost date de cãtre încãrcãtor şi ca, în împrejurãrile de fapt, pierderea, dãuna sau
 intirzierea în livrare poate fi atribuitã unor astfel de riscuri, se presupune ca pierderea, dãuna sau intirzierea în
 livrare a fost astfel cauzatã, dacã nu se face dovada ca pierderea, dãuna sau intirzierea rezulta, în totalitate sau
 în parte, dintr-o culpa sau dintr-o neglijenţa a cãrãuşului, a prepuşilor sau a mandatarilor sãi.</p><br>

<p style="text-indent:2em;">6. Cãrãuşul nu este rãspunzãtor, cu excepţia cazurilor de avarie comuna, cînd pierderea, avarierea sau intirzierea
 în livrare a apãrut ca urmare a mãsurilor luate pentru salvarea de vieţi omeneşti sau din mãsuri rezonabile luate 
 în vederea salvãrii bunurilor pe mare.</p><br>

<p style="text-indent:2em;">7. Atunci cînd culpa sau neglijenţa cãrãuşului, a prepuşilor sau a mandatarilor sãi este combinata cu alte cauze de
 producere a pierderii, avarierii sau intirzierii în livrare, cãrãuşul este rãspunzãtor numai în mãsura în care pierderea,
 avarierea sau intirzierea în livrare se datoreazã unei astfel de culpe sau neglijente, cu condiţia ca el sa dovedeascã
 cuantumul pierderii, al avarierii sau al intirzierii în livrare care nu poate fi atribuit respectivei culpe sau neglijente.</p><br>

<p style="font-weight:500;">ART. 6</p><br>

<p style="font-weight:500;">Limitele rãspunderii</p><br>

<p style="text-indent:2em;">1. a) Rãspunderea cãrãuşului pentru daune rezultate din pierderea sau avarierea produsã mãrfurilor în conformitate cu
 prevederile art. 5 este limitatã la o suma echivalenta cu 835 de unitãţi de cont pe colet sau alta unitate de transport,
 sau la 2,5 unitãţi de cont pe kilogram de greutate bruta a mãrfurilor pierdute sau avariate, oricare din ele este mai
 mare.</p><br>

<p style="text-indent:2em;">b) Rãspunderea cãrãuşului pentru intirziere în livrare în conformitate cu prevederile art. 5 este limitatã la un cuantum
 echivalent cu de doua ori şi jumãtate valoarea navlului plãtibil pentru mãrfurile livrate cu intirziere, dar care nu va
 depãşi navlul total plãtibil potrivit contractului de transport maritim al mãrfurilor.</p><br>

<p style="text-indent:2em;">c) În nici un caz totalul responsabilitãţii cãrãuşului potrivit subpunctelor a) şi b) ale prezentului articol nu va depãşi
 limitarea care ar fi stabilitã conform subpunctului a) al prezentului articol pentru pierderea totalã a mãrfurilor la
 care o asemenea responsabilitate a apãrut.</p>

<p style="text-indent:2em;"></p><h6>2. În scopul calculãrii cuantumului mai mare, conform pct. 1 a) al acestui articol
, se vor aplica urmãtoarele reguli:</h6><p></p>

<p style="text-indent:2em;">a) În cazul cînd pentru gruparea mãrfurilor se utilizeazã un container, paleta sau alta unitate similarã de transport,
 enumerate în conosament, dacã este emis, sau în orice alt document fãcînd dovada unui contract de transport maritim,
 acestea sînt considerate colete sau unitãţi de transport.</p><br>

<p style="text-indent:2em;">Cu excepţia celor menţionate mai sus, mãrfurile într-o astfel de unitate de transport sînt considerate o singura unitate
 de transport.</p><br>

<p style="text-indent:2em;">b) Atunci cînd însãşi unitatea de transport este pierdutã sau avariata, aceasta este consideratã ca o unitate distinctã
 dacã nu aparţine cãrãuşului sau nu este pusã la dispoziţie de cãtre cãrãuş.</p><br>

<p>3. Prin unitate de cont se înţelege unitatea de cont menţionatã în art. 26.</p><br>

<p>4. Cãrãuşul şi încãrcãtorul pot fixa, de comun acord, limite de rãspundere superioare celor prevãzute în art. 1.</p><br>

<p style="font-weight:500;">ART. 7</p><br>

<p style="font-weight:500;">Aplicarea la pretenţii necontractuale</p><br>

<p style="text-indent:2em;">1. Apãrãrile şi limitarile rãspunderii prevãzute în prezenta convenţie se aplica în orice acţiune impotriva cãrãuşului cu
 privire la pierderea sau avarierea mãrfurilor ce fac obiectul contractului de transport maritim sau pentru intirzieri în
 livrare, indiferent dacã acţiunea este intemeiata pe rãspunderea contractualã sau delictuala sau în alt mod.</p><br>

<p style="text-indent:2em;">2. Dacã aceasta acţiune este îndreptatã impotriva unui prepus sau mandatar al cãrãuşului, acest prepus sau mandatar, dacã
 dovedeşte ca a acţionat în limitele angajamentului sau, este îndreptãţit sa se prevaleze de apãrãrile şi limitarile
 rãspunderii pe care cãrãuşul este îndreptãţit sa le invoce potrivit prezentei convenţii.</p><br>

<p style="text-indent:2em;">3. Cu excepţia prevederilor de la art. 8, suma totalã a despãgubirilor datorate de cãrãuş şi de persoanele vizate la pct.
 2 al prezentului articol nu va depãşi limitele rãspunderii prevãzute în prezenta convenţie.</p><br>

<p style="font-weight:500;">ART. 8</p><br>

<p style="font-weight:500;">Decãderea din dreptul la limitarea rãspunderii</p><br>

<p style="text-indent:2em;">1. Cãrãuşul nu este îndreptãţit sa beneficieze de limitarea rãspunderii prevãzute la art. 6 dacã se dovedeşte ca pierderea,
 avarierea sau intirzierea în livrare a rezultat dintr-o acţiune sau omisiune a cãrãuşului comisã fie cu intenţia de a cauza
 aceasta pierdere, avariere sau intirziere, fie prin nechibzuinta şi cunoscind ca o asemenea pierdere, avariere sau intirziere
 ar putea probabil sa se producã.</p><br>

<p style="text-indent:2em;">2. Independent de prevederile pct. 2 al art. 7, un prepus sau un mandatar al cãrãuşului nu este îndreptãţit sa beneficieze
 de limitarea rãspunderii asa cum este prevãzutã în art. 6, dacã se dovedeşte ca pierderea, avarierea sau intirzierea în
 livrare rezulta dintr-un act sau dintr-o omisiune a acestui prepus sau mandatar, comis fie cu intenţia de a cauza o asemenea
 pierdere, avariere sau intirziere, fie prin nechibzuinta şi cunoscind ca o asemenea pierdere, avariere sau intirziere ar putea
 probabil sa se producã.</p><br>

<p style="font-weight:500;">ART. 9</p><br>

<p style="font-weight:500;">Mãrfuri transportate pe punte</p><br>

<p style="text-indent:2em;">1. Cãrãuşul este autorizat sa transporte mãrfurile pe punte numai dacã un asemenea transport se efectueazã pe baza unui acord
 cu încãrcãtorul sau în conformitate cu uzanţele comerţului respectiv sau dacã este cerut de reglementãri sau reguli statutare
 în vigoare.</p><br>

<p style="text-indent:2em;">2. Dacã cãrãuşul şi încãrcãtorul au convenit ca mãrfurile vor fi sau ar putea fi transportate pe punte, cãrãuşul trebuie sa
 insereze în conosament sau în orice alt document care face dovada contractului de transport maritim o declaraţie în acest
 sens. În lipsa unei asemenea declaraţii, cãrãuşul are sarcina de a dovedi ca s-a încheiat o asemenea înţelegere pentru
 transportul pe punte; cãrãuşul nu are însã dreptul de a invoca aceasta înţelegere fata de un terţ, inclusiv fata de un
 destinatar, care este deţinãtor de buna-credinţa al conosamentului.</p><br>

<p style="text-indent:2em;">3. Atunci cînd mãrfurile au fost transportate pe punte contrar prevederilor pct. 1 al prezentului articol sau cînd cãrãuşul
 nu poate invoca o înţelegere privind transportul pe punte potrivit pct. 2 al prezentului articol, cãrãuşul, independent de
 prevederile pct. 1 al art. 5, este rãspunzãtor de pierderea sau avarierea mãrfurilor, ca şi de intirzierea în livrare care
 rezulta numai din transportul pe punte, iar întinderea rãspunderii sale va fi stabilitã conform prevederilor art. 6 sau art.
 8 din prezenta convenţie, dupã caz.</p><br> 

<p style="text-indent:2em;">4. Transportul mãrfurilor efectuat pe punte, contrar unei înţelegeri exprese de transport sub punte, este considerat ca un
 act sau o omisiune a cãrãuşului în sensul art. 8.</p><br>

<p style="font-weight:500;">ART. 10</p><br>

<p style="font-weight:500;">Rãspunderea cãrãuşului şi a cãrãuşului efectiv</p><br>

<p style="text-indent:2em;">1. Cînd executarea transportului sau a unei pãrţi a acestuia a fost încredinţatã unui cãrãuş efectiv, indiferent dacã
 contractul de transport maritim îi permite sau nu, cãrãuşul rãmîne în orice caz rãspunzãtor de întregul transport conform
 prevederilor prezentei convenţii. Cãrãuşul este rãspunzãtor, pentru partea de transport efectuat de cãrãuşul efectiv, de
 actele şi omisiunile cãrãuşului efectiv şi de cele ale prepuşilor sau mandatarilor acestuia, care acţioneazã în cadrul
 atribuţiilor lor.</p><br>

<p style="text-indent:2em;">2. Toate prevederile prezentei convenţii care guverneazã rãspunderea cãrãuşului se aplica de asemenea şi rãspunderii cãrãuşului
 efectiv pentru transportul efectuat de cãtre el. Prevederile pct. 2 şi 3 ale art. 7 şi cele ale pct. 2 al art. 8 se aplica atunci
 cînd o acţiune este intentatã impotriva unui prepus sau mandatar al cãrãuşului efectiv.</p><br>

<p style="text-indent:2em;">3. Orice înţelegere specialã prin care cãrãuşul îşi asuma obligaţii ce nu îi incumba în virtutea prezentei convenţii sau renunţa
 la drepturi care îi sînt conferite prin prezenta convenţie are efecte fata de cãrãuşul efectiv numai dacã acesta a fost de acord
 cu aceasta înţelegere în mod expres şi în scris. Chiar dacã cãrãuşul efectiv a fost sau nu de acord cu aceasta înţelegere, cãrãuşul
 rãmîne în orice caz legat de obligaţiile sau renunţãrile rezultind dintr-o asemenea înţelegere specialã.</p><br>

<p>4. Dacã şi în mãsura în care atît cãrãuşul, cît şi cãrãuşul efectiv, sînt responsabili, responsabilitatea lor
 este solidarã.</p><br>

<p style="text-indent:2em;">5. Suma totalã a despãgubirilor datorate de cãrãuş, de cãrãuşul efectiv şi de prepuşii şi mandatarii acestora nu
 poate depãşi limitele rãspunderii prevãzute în prezenta convenţie.</p><br>

<p style="text-indent:2em;">6. Nici o prevedere din prezentul articol nu va aduce atingerea drepturilor de regres care pot exista între
 cãrãuş şi cãrãuşul efectiv.</p><br>


<p style="font-weight:500;">ART. 11</p><br>

<p style="font-weight:500;">Transport cu cãrãuşi succesivi</p><br>

<p style="text-indent:2em;">1. Deosebit de prevederile pct. 1 al art. 1, cînd un contract de transport pe mare prevede în mod expres ca o parte
 anumitã a transportului cãruia i se aplica contractul respectiv va fi executatã de o persoana desemnatã, alta decît
 cãrãuşul, contractul poate, de asemenea, prevedea ca cãrãuşul nu este rãspunzãtor de pierderea, avarierea sau intirzierea
 în livrare cauzate de o împrejurare care a avut loc în timp ce mãrfurile se aflau în grija cãrãuşului efectiv pe durata
 acestei pãrţi a transportului. Totuşi, orice prevedere limitind sau excluzind o asemenea rãspundere este fãrã efect
 dacã nici o procedura judiciarã nu poate fi pornitã impotriva cãrãuşului efectiv în fata unui tribunal competent în
 conformitate cu pct. 1 şi 2 ale art. 21.</p><br> 

<p>Cãrãuşul are sarcina de a dovedi ca pierderea, avarierea sau intirzierea în livrare a fost cauzatã de o astfel de
 împrejurare.</p><br>

<p style="text-indent:2em;">2. Cãrãuşul efectiv este rãspunzãtor, în conformitate cu prevederile pct. 2 al art. 10, pentru pierderea, avarierea
 sau intirzierea în livrare cauzatã printr-o împrejurare care s-a produs în timp ce mãrfurile erau în grija sa.</p><br>

<p style="font-weight:500;">PARTEA A III-A</p><br>

<p style="font-weight:500;">RĂSPUNDEREA ÎNCĂRCĂTORULUI</p><br>

<p style="font-weight:500;">ART. 12</p><br>

<p style="font-weight:500;">Regula generalã</p><br>

<p style="text-indent:2em;">Încãrcãtorul nu este rãspunzãtor de prejudiciul suferit de cãrãuş sau de cãrãuşul efectiv, nici de avarierea navei,
 decît dacã prejudiciul sau avarierea a fost provocatã din culpa sau neglijenţa încãrcãtorului, a prepuşilor sau a
 mandatarilor acestuia. De asemenea, nici prepuşii sau mandatarii încãrcãtorului nu sînt rãspunzãtori de pierdere
 sau avariere decît dacã ele au rezultat din culpa sau prin neglijenţa lor.</p><br>

<p style="font-weight:500;">ART. 13</p><br>

<p style="font-weight:500;">Reguli speciale privind mãrfurile periculoase</p><br>

<p style="text-indent:2em;">1. Încãrcãtorul trebuie sa aplice pe mãrfurile periculoase un marcaj sau o eticheta prin care se indica într-un mod
 corespunzãtor ca mãrfurile sînt periculoase.</p>

<p style="text-indent:2em;"></p><h6>2. Cînd încãrcãtorul preda mãrfuri periculoase cãrãuşului sau cãrãuşului efectiv, încãrcãtorul trebuie sa-l informeze
 pe cãrãuş sau pe cãrãuşul efectiv, dupã caz, despre caracterul periculos al mãrfurilor şi, dacã este necesar, asupra
 precautiilor ce trebuie luate. Dacã încãrcãtorul nu-şi îndeplineşte aceasta obligaţie şi dacã cãrãuşul sau cãrãuşul
 efectiv nu cunoaşte pe alta cale caracterul lor periculos:</h6><p></p>

<p style="text-indent:2em;">a) încãrcãtorul este rãspunzãtor fata de cãrãuş şi fata de orice alt cãrãuş efectiv pentru pierderea care rezulta din
 încãrcarea acestor mãrfuri, şi</p><br>

<p style="text-indent:2em;">b) mãrfurile pot fi descãrcate în orice moment, distruse sau fãcute inofensive, dupã cum o cer împrejurãrile, fãrã
 plata unei compensãri.</p><br>

<p style="text-indent:2em;">3. Prevederile pct. 2 al prezentului articol nu pot fi invocate de o persoana care, în timpul transportului, a luat
 în primire mãrfurile cunoscind ca ele sînt periculoase.</p><br>

<p style="text-indent:2em;">4. Dacã în cazurile cînd prevederile pct. 2 subpunctul b) din prezentul articol nu sînt aplicabile sau nu pot fi
 invocate, mãrfurile periculoase devin un pericol efectiv pentru persoane sau bunuri, ele pot fi descãrcate, distruse
 sau fãcute inofensive, dupã cum o cer împrejurãrile, fãrã plata unei compensãri, cu excepţia cazurilor cînd exista
 obligaţia de a contribui la avaria comuna sau cînd cãrãuşul este rãspunzãtor în conformitate cu prevederile art. 5.</p><br>

<p style="font-weight:500;">PARTEA A IV-A
DOCUMENTE DE TRANSPORT</p><br>

<p style="font-weight:500;">ART. 14</p><br>

<p style="font-weight:500;">Emiterea conosamentului</p><br>

<p style="text-indent:2em;">1. Cînd mãrfurile sînt luate în primire de cãtre cãrãuş sau de cãtre cãrãuşul efectiv, cãrãuşul trebuie, la cererea
 încãrcãtorului, sa emita un conosament.</p><br>

<p style="text-indent:2em;">2. Conosamentul poate fi semnat de cãtre o persoana care a primit împuternicirea cãrãuşului. Un conosament semnat de
 comerciantul navei care transporta mãrfurile se considera ca fiind semnat în numele cãrãuşului.</p><br>

<p style="text-indent:2em;">3. Semnatura depusa pe conosament poate fi scrisã de mina, tiparita în facsimil, aplicatã prin perforare sau prin
 stampilare, se poate prezenta sub forma de simbol sau sa fie aplicatã prin orice alte mijloace mecanice sau electronice,
 dacã procedeul nu contravine legilor tarii unde s-a emis conosamentul.</p><br>

<p style="font-weight:500;">ART. 11</p><br>

<p style="font-weight:500;">Transport cu cãrãuşi succesivi</p><br>

<p style="text-indent:2em;">1. Deosebit de prevederile pct. 1 al art. 1, cînd un contract de transport pe mare prevede în mod expres ca o parte
 anumitã a transportului cãruia i se aplica contractul respectiv va fi executatã de o persoana desemnatã, alta decît
 cãrãuşul, contractul poate, de asemenea, prevedea ca cãrãuşul nu este rãspunzãtor de pierderea, avarierea sau intirzierea
 în livrare cauzate de o împrejurare care a avut loc în timp ce mãrfurile se aflau în grija cãrãuşului efectiv pe durata acestei
 pãrţi a transportului. Totuşi, orice prevedere limitind sau excluzind o asemenea rãspundere este fãrã efect dacã nici o procedura
 judiciarã nu poate fi pornitã impotriva cãrãuşului efectiv în fata unui tribunal competent în conformitate cu pct.
 1 şi 2 ale art. 21.</p><br>

<p>Cãrãuşul are sarcina de a dovedi ca pierderea, avarierea sau intirzierea în livrare a fost cauzatã de o astfel de
 împrejurare.</p><br>

<p style="text-indent:2em;">2. Cãrãuşul efectiv este rãspunzãtor, în conformitate cu prevederile pct. 2 al art. 10, pentru pierderea, avarierea
 sau intirzierea în livrare cauzatã printr-o împrejurare care s-a produs în timp ce mãrfurile erau în grija sa.</p><br>

<p style="font-weight:500;">PARTEA A III-A</p><br>

<p style="font-weight:500;">RĂSPUNDEREA ÎNCĂRCĂTORULUI</p><br>

<p style="font-weight:500;">ART. 12</p><br>

<p style="font-weight:500;">Regula generalã</p><br>

<p style="text-indent:2em;">Încãrcãtorul nu este rãspunzãtor de prejudiciul suferit de cãrãuş sau de cãrãuşul efectiv, nici de avarierea navei,
 decît dacã prejudiciul sau avarierea a fost provocatã din culpa sau neglijenţa încãrcãtorului, a prepuşilor sau a
 mandatarilor acestuia. De asemenea, nici prepuşii sau mandatarii încãrcãtorului nu sînt rãspunzãtori de pierdere
 sau avariere decît dacã ele au rezultat din culpa sau prin neglijenţa lor.</p><br>

<p style="font-weight:500;">ART. 13</p><br>

<p style="font-weight:500;">Reguli speciale privind mãrfurile periculoase</p><br>

<p style="text-indent:2em;">1. Încãrcãtorul trebuie sa aplice pe mãrfurile periculoase un marcaj sau o eticheta prin care se indica într-un
 mod corespunzãtor ca mãrfurile sînt periculoase.</p>

<p style="text-indent:2em;"></p><h6>2. Cînd încãrcãtorul preda mãrfuri periculoase cãrãuşului sau cãrãuşului efectiv, încãrcãtorul trebuie sa-l informeze
 pe cãrãuş sau pe cãrãuşul efectiv, dupã caz, despre caracterul periculos al mãrfurilor şi, dacã este necesar, asupra precautiilor
 ce trebuie luate. Dacã încãrcãtorul nu-şi îndeplineşte aceasta obligaţie şi dacã cãrãuşul sau cãrãuşul efectiv nu cunoaşte pe
 alta cale caracterul lor periculos:</h6><p></p>

<p style="text-indent:2em;">a) încãrcãtorul este rãspunzãtor fata de cãrãuş şi fata de orice alt cãrãuş efectiv pentru pierderea care rezulta din
 încãrcarea acestor mãrfuri, şi</p><br>

<p style="text-indent:2em;">b) mãrfurile pot fi descãrcate în orice moment, distruse sau fãcute inofensive, dupã cum o cer împrejurãrile, fãrã plata
 unei compensãri.</p><br>

<p style="text-indent:2em;">3. Prevederile pct. 2 al prezentului articol nu pot fi invocate de o persoana care, în timpul transportului, a luat în
 primire mãrfurile cunoscind ca ele sînt periculoase.</p><br>

<p style="text-indent:2em;">4. Dacã în cazurile cînd prevederile pct. 2 subpunctul b) din prezentul articol nu sînt aplicabile sau nu pot fi invocate
, mãrfurile periculoase devin un pericol efectiv pentru persoane sau bunuri, ele pot fi descãrcate, distruse sau fãcute inofensive
, dupã cum o cer împrejurãrile, fãrã plata unei compensãri, cu excepţia cazurilor cînd exista obligaţia de a contribui la avaria comuna
 sau cînd cãrãuşul este rãspunzãtor în conformitate cu prevederile art. 5.</p><br>

<p style="font-weight:500;">PARTEA A IV-A</p><br>

<p style="font-weight:500;">DOCUMENTE DE TRANSPORT</p><br>

<p style="font-weight:500;">ART. 14</p><br>

<p style="font-weight:500;">Emiterea conosamentului</p><br>

<p style="text-indent:2em;">1. Cînd mãrfurile sînt luate în primire de cãtre cãrãuş sau de cãtre cãrãuşul efectiv, cãrãuşul trebuie, la cererea încãrcãtorului,
 sa emita un conosament.</p><br>

<p style="text-indent:2em;">2. Conosamentul poate fi semnat de cãtre o persoana care a primit împuternicirea cãrãuşului. Un conosament semnat de comerciantul navei
 care transporta mãrfurile se considera ca fiind semnat în numele cãrãuşului.</p><br>

<p style="text-indent:2em;">3. Semnatura depusa pe conosament poate fi scrisã de mina, tiparita în facsimil, aplicatã prin perforare sau prin stampilare, se poate
 prezenta sub forma de simbol sau sa fie aplicatã prin orice alte mijloace mecanice sau electronice, dacã procedeul nu contravine legilor 
 tarii unde s-a emis conosamentul.</p><br>

<p style="font-weight:500;">ART. 17</p><br>

<p style="font-weight:500;">Garanţii date de cãtre încãrcãtor</p><br>

<p style="text-indent:2em;">1. Se considera ca încãrcãtorul a garantat cãrãuşului exactitatea indicaţiilor privind natura generalã a mãrfurilor, marcajul lor, numãrul,
 cantitatea şi greutatea, furnizate de cãtre el pentru a fi inserate în conosament. Încãrcãtorul trebuie sa despãgubeascã pe cãrãuş pentru
 prejudiciul rezultat din inexactitatea acestor indicaţii. Încãrcãtorul rãmîne rãspunzãtor chiar dacã conosamentul a fost transmis de cãtre
 el.</p><br>

<p style="text-indent:2em;">Dreptul cãrãuşului la o asemenea despãgubire nu limiteazã în nici un fel rãspunderea ce revine pe baza contractului de transport maritim
 fata de orice persoana, alta decît încãrcãtorul.</p><br>

<p style="text-indent:2em;">2. Orice scrisoare de garanţie sau orice înţelegere prin care încãrcãtorul se obliga sa despãgubeascã pe cãrãuş pentru orice pierdere rezultatã
 din emiterea de cãtre cãrãuş sau de cãtre o persoana ce acţioneazã în numele sau, a conosamentului fãrã rezerve privind indicaţiile furnizate
 de cãtre încãrcãtor pentru a fi inserate în conosament, sau cu privire la starea aparenta a mãrfurilor, este nul şi fãrã efecte fata de orice 
 terţ, inclusiv un destinatar, cãruia i-a fost transmis conosamentul.</p><br>

<p style="text-indent:2em;">3. Aceasta scrisoare de garanţie sau înţelegere este opozabilã încãrcãtorului, în afarã de cazul în care cãrãuşul sau persoana care acţioneazã
 în numele sau, omitind sa facã rezerva la care se referã pct. 2 al prezentului articol, intenţioneazã sa lezeze o terta persoana, inclusiv un
 destinatar, care acţioneazã bazat pe descrierea mãrfurilor conform conosamentului. În acest ultim caz, dacã rezerva omisa se referã la indicaţii
 furnizate de cãtre încãrcãtor pentru a fi inserate în conosament, cãrãuşul nu are dreptul la nici o despãgubire din partea încãrcãtorului în 
 virtutea pct. 1 al prezentului articol.</p><br>

<p style="text-indent:2em;">4. În cazul lezarii intenţionate, la care se face referinta la pct. 3 al prezentului articol, cãrãuşul este rãspunzãtor,
 fãrã sa beneficieze de limitarea rãspunderii prevãzutã în prezenta convenţie, pentru orice prejudiciu suferit de un terţ, 
 inclusiv un destinatar, deoarece el a acţionat în baza descrierii mãrfurilor conform conosamentului.</p><br>

<p style="font-weight:500;">ART. 18</p><br>

<p style="font-weight:500;">Alte documente în afarã de conosament</p><br>

<p style="text-indent:2em;">Dacã un cãrãuş emite un document altul decît conosamentul pentru a face dovada primirii mãrfurilor spre a fi transportate,
 acest document face dovada, pînã la proba contrarã, a încheierii contractului de transport maritim şi a preluãrii de cãtre 
 cãrãuş a mãrfurilor asa cum sînt descrise în acest document.</p><br>

<p style="font-weight:500;">PARTEA A V-A</p><br>

<p style="font-weight:500;">RECLAMAŢII ŞI ACŢIUNI</p><br>

<p style="font-weight:500;">ART. 19</p><br>

<p style="font-weight:500;">Notificare de pierdere, de avariere sau de intirziere</p><br>

<p style="text-indent:2em;">1. Dacã destinatarul nu transmite cãrãuşului o notificare scrisã de pierdere sau de avariere, specificind natura generalã a 
acestei pierderi sau avarieri, cel mai tirziu în ziua lucrãtoare care urmeazã zilei cînd mãrfurile i-au fost predate de cãtre 
cãrãuş, se prezuma, pînã la dovada contrarã, ca mãrfurile au fost predate de cãtre cãrãuş asa cum sînt descrise în documentul 
de transport sau, dacã nici un asemenea document nu a fost emis, ca ele au fost predate în bune condiţii.</p><br>

<p style="text-indent:2em;">2. Cînd pierderea sau avarierea nu este aparenta, prevederile pct. 1 al prezentului articol se aplica corespunzãtor dacã 
notificarea nu este data în scris în termen de 15 zile consecutive începînd cu ziua urmãtoare celei în care mãrfurile au
 fost predate destinatarului.</p><br>

<p style="text-indent:2em;">3. Dacã starea mãrfurilor în momentul predãrii lor cãtre destinatar a fãcut obiectul unei expertize/inspecţii comune de 
cãtre pãrţi, nu este necesar sa se dea o notificare în scris pentru pierderea sau avarierea constatatã în timpul expertizei
/inspecţiei respective.</p><br>

<p style="text-indent:2em;">4. În caz de pierdere sau de avarie efectivã sau prezumatã, cãrãuşul şi destinatarul trebuie sa-şi acorde reciproc toate 
facilitãţile rezonabile pentru a proceda la inspectarea şi talimania mãrfurilor.</p><br>

<p style="text-indent:2em;">5. Nici o despãgubire nu va fi datoratã pentru prejudiciul rezultat din intirzierea în livrare, dacã nu s-a dat nici o 
notificare în scris cãrãuşului în termen de 60 de zile consecutive începînd din ziua urmãtoare celei în care mãrfurile au 
fost predate cãtre destinatar.</p><br> 

<p style="text-indent:2em;">6. Dacã mãrfurile au fost predate de cãtre un cãrãuş efectiv, orice notificare care i-a fost data în virtutea prezentului 
articol va avea acelaşi efect ca şi cum ar fi fost data cãrãuşului şi orice notificare data cãrãuşului are acelaşi efect ca
 şi cum ar fi fost data cãrãuşului efectiv.</p><br>

<p style="text-indent:2em;">7. Dacã o notificare de pierdere sau de avariere, specificind natura generalã a pierderii sau avarierii, nu a fost data în 
scris de cãtre cãrãuş sau de cãtre cãrãuşul efectiv încãrcãtorului în termen de 90 de zile consecutive dupã împrejurarea care 
a produs o asemenea pierdere sau avariere sau dupã predarea mãrfurilor conform cu pct. 2 al art. 4, oricare este mai tirzie, în
 lipsa unei astfel de notificãri, se considera, pînã la dovada contrarã, ca cãrãuşul sau cãrãuşul efectiv nu a suportat nici o 
 pierdere sau avariere datoratã unei culpe sau unei neglijente a încãrcãtorului, a prepuşilor sau a mandatarilor sãi.</p><br>

<p style="text-indent:2em;">8. În sensul prezentului articol, o notificare data unei persoane ce acţioneazã în numele cãrãuşului sau a cãrãuşului efectiv, 
inclusiv comandantul sau ofiţerul sub controlul cãruia este nava, sau unei persoane ce acţioneazã în numele încãrcãtorului, 
se considera a fi fost data cãrãuşului, cãrãuşului efectiv, respectiv încãrcãtorului.</p><br>

<p style="font-weight:500;">ART. 20</p><br>

<p style="font-weight:500;">Prescrierea acţiunilor</p><br>

<p style="text-indent:2em;">1. Orice acţiune referitoare la transportul mãrfurilor în virtutea prezentei convenţii se prescrie dacã nici o procedura
 judiciarã sau arbitrarã nu a fost introdusã în termen de 2 ani.</p><br>

<p style="text-indent:2em;">2. Termenul de prescripţie curge din ziua în care cãrãuşul a predat mãrfurile sau o parte a acestora sau, în cazurile
 cînd mãrfurile nu au fost predate, începînd din ultima zi în care mãrfurile trebuiau sa fie predate.</p><br>

<p>3. Ziua din care începe sa curgã termenul de prescripţie nu este inclusã în acest termen.</p><br>

<p style="text-indent:2em;">4. Persoana cãreia îi este adresatã o reclamaţie poate, în orice moment în timpul termenului de prescripţie, sa prelungeascã
 acest termen printr-o declaraţie în scris adresatã reclamantului. Termenul poate fi prelungit din nou prin una sau mai multe
 alte declaraţii.</p><br>

<p style="text-indent:2em;">5. O acţiune pentru despãgubire a unei persoane ţinuta rãspunzãtoare poate fi exercitatã chiar şi dupã expirarea termenului de
 prescripţie prevãzut în punctele precedente, dacã a fost exercitatã şi în termenul fixat de legea statului unde a fost începutã
 procedura, Totuşi, acest termen nu va putea fi mai mic de 90 de zile începînd din ziua cînd persoana ce introduce acţiunea pentru
 despãgubire a rezolvat reclamaţia sau a primit ea însãşi notificarea de introducere a unei acţiuni impotriva sa.</p><br>

<p style="font-weight:500;">ART. 21</p><br>

<p style="font-weight:500;">Jurisdicţie</p>

<p style="text-indent:2em;"></p><h6>1. În orice acţiune privind transportul de mãrfuri în virtutea prezentei convenţii, reclamantul poate, la opţiunea sa, sa înainteze
 o acţiune în fata unui tribunal care este competent în conformitate cu legea statului în care este situat şi sub jurisdicţia cãruia
 se afla unul dintre locurile urmãtoare:</h6><p></p>

<p>a) sediul piritului sau, în lipsa, domiciliul sau obişnuit; sau</p><br>

<p style="text-indent:2em;">b) locul unde s-a încheiat contractul, cu condiţia ca pîrîtul sa aibã acolo sediul, o sucursala sau o agenţie 
prin care s-a încheiat contractul; sau</p><br>

<p>c) portul de încãrcare sau portul de descãrcare; sau</p><br>

<p>d) orice alt loc desemnat în acest scop în contractul de transport maritim.</p><br>

<p style="text-indent:2em;">2. a) Independent de prevederile anterioare ale prezentului articol, o acţiune poate fi intentatã în fata tribunalelor din orice port
 sau loc într-un stat contractant în care nava care efectueazã transportul sau orice alta nava aparţinînd aceluiaşi proprietar a fost
 sechestrata în concordanta cu regulile ce se aplica conform legislaţiei acestui stat şi cele ale dreptului internaţional. Totuşi,
 într-un asemenea caz, la cererea piritului, reclamantul trebuie sa strãmute acţiunea, la alegerea sa, într-una din jurisdictiile
 vizate la pct. 1 al prezentului articol pentru ca aceasta sa judece cererea, însã pîrîtul trebuie sa depunã în prealabil o garanţie
 suficienta pentru a asigura plata oricãror sume care ar putea fi acordate reclamantului;</p><br>

<p>b) Tribunalul din portul sau locul sechestrãrii navei va hotãrî dacã şi în ce mãsura garanţia este suficienta.</p><br>

<p style="text-indent:2em;">3. Nici o procedura judiciarã referitoare la transportul mãrfurilor în virtutea prezentei convenţii nu poate fi începutã într-un loc
 care nu este specificat în pct. 1 sau 2 ale prezentului articol. Prevederile prezentului punct nu constituie un obstacol în competenta
 tribunalelor din statele contractante în ce priveşte mãsurile provizorii asiguratorii şi de protecţie.</p><br>

<p style="text-indent:2em;">4. a) Atunci cînd o acţiune a fost intentatã în fata unui tribunal competent în baza pct. 1 sau 2 din prezentul articol sau cînd o
 hotãrîre a fost pronunţatã de un asemenea tribunal, o noua acţiune nu mai poate fi introdusã între aceleaşi pãrţi fiind intemeiata pe 
 aceeaşi cauza, decît dacã hotãrîrea tribunalului în fata cãruia a fost intentatã prima acţiune nu este executorie în ţara în care se
 instituie noua procedura.</p><br>

<p style="text-indent:2em;">b) În sensul prezentului articol, mãsurile avînd ca obiect obţinerea executãrii unei hotãrîri nu sînt considerate ca introducere a
 unei noi acţiuni.</p><br>

<p style="text-indent:2em;">c) În sensul prezentului articol, strãmutarea unei acţiuni în fata unui alt tribunal pe teritoriul aceleiaşi tari, sau în fata unui
 tribunal dintr-o alta ţara, în conformitate cu pct. 2. a) al prezentului articol, nu se considera ca o introducere a unei noi 
 acţiuni.</p><br>

<p style="text-indent:2em;">5. Independent de prevederile punctelor precedente, o înţelegere între pãrţi desemnind locul unde reclamantul poate introduce acţiunea,
 intervenita dupã ce a apãrut reclamaţia în baza contractului de transport, produce efecte.</p><br>

<p style="font-weight:500;">ART. 22</p><br>

<p style="font-weight:500;">Arbitrajul</p><br>

<p style="text-indent:2em;">1. Sub rezerva prevederilor prezentului articol, pãrţile pot prevedea printr-o înţelegere atestata în scris ca orice litigiu
 ce poate aparea privind transportul mãrfurilor în baza prezentei convenţii va fi deferit arbitrajului.</p><br>

<p style="text-indent:2em;">2. Cînd un contract de navlosire conţine o dispoziţie prin care se prevede ca litigiile ce pot aparea din executarea lui vor fi
 supuse arbitrajului şi conosamentul emis ca urmare a acestui contract de navlosire nu specifica printr-o clauza expresã ca aceasta
 dispoziţie este obligatorie pentru deţinãtorul conosamentului, cãrãuşul nu poate invoca aceasta dispoziţie fata de un deţinãtor de
 buna-credinţa al conosamentului.</p>

<p style="text-indent:2em;"></p><h6>3. Procedura de arbitraj va fi declansata, la opţiunea reclamantului, într-unul
 din urmãtoarele locuri:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) un loc într-un stat pe teritoriul cãruia este situat:</h6><p></p>

<p>i) sediul piritului sau, în lipsa, domiciliul sau obişnuit; sau</p><br>

<p style="text-indent:2em;">ii) locul unde s-a încheiat contractul, cu condiţia ca pîrîtul sa aibã acolo un sediu, o sucursala sau o agenţie prin
 care s-a încheiat contractul; sau</p><br>

<p>iii) portul de încãrcare sau portul de descãrcare; sau</p><br>

<p>b) orice loc desemnat în acest scop în clauza de arbitraj sau pact compromisoriu.</p><br>

<p>4. Arbitrul sau arbitrajul va aplica regulile din prezenta convenţie.</p><br>

<p style="text-indent:2em;">5. Prevederile pct. 3 şi 4 din prezentul articol se considera ca fãcînd parte din orice clauza de arbitraj sau pact compromisoriu 
şi orice dispoziţie sau clauza care contravine celor de mai sus este nulã.</p><br>

<p style="text-indent:2em;">6. Nici o dispoziţie din prezentul articol nu afecteazã validitatea unui acord referitor la arbitraj încheiat de pãrţi dupã 
apariţia unui litigiu din contractul de transport maritim.</p><br>

<p style="font-weight:500;">PARTEA A VI-A</p><br>

<p style="font-weight:500;">PREVEDERI SUPLIMENTARE</p><br>

<p style="font-weight:500;">ART. 23</p><br>

<p style="font-weight:500;">Clauze contractuale</p><br>

<p style="text-indent:2em;">1. Orice stipulaţie dintr-un contract de transport maritim, dintr-un conosament sau orice alt document care face dovada contractului
 de transport maritim este nulã în mãsura în care deroga, direct sau indirect, de la prevederile prezentei convenţii. Nulitatea unei asemenea
 stipulaţii nu afecteazã valabilitatea celorlalte prevederi ale contractului sau documentului din care face parte. O clauza prin care se cesioneaza
 cãrãuşului beneficiul asigurãrii mãrfurilor sau orice alta clauza similarã este nulã.</p><br>

<p style="text-indent:2em;">2. Independent de prevederile pct. 1 al prezentului articol, cãrãuşul îşi poate asuma o responsabilitate şi obligaţii mai mari
 decît cele care sînt prevãzute în prezenta convenţie.</p><br>

<p style="text-indent:2em;">3. Atunci cînd se emite un conosament sau orice alt document care face dovada unui contract de transport maritim, acest document
 trebuie sa conţinã o menţiune din care sa rezulte ca transportul este supus prevederilor prezentei convenţii care anuleazã orice
 stipulaţie ce deroga de la aceasta în detrimentul încãrcãtorului sau primitorului.</p><br>

<p style="text-indent:2em;">4. Cînd reclamantul a suportat un prejudiciu cu privire la marfa, ca rezultat al unei stipulaţii nule în baza prezentului articol
 sau ca rezultat al unei omisiuni a menţiunii la care face referire pct. 3 al acestui articol, cãrãuşul trebuie sa despãgubeascã
 în limitele cerute, în scopul de a despãgubi pe reclamant conform prevederilor acestei convenţii, pentru orice pierdere sau 
 avariere a mãrfurilor, la fel ca şi pentru intirziere în livrare. Cãrãuşul trebuie, în plus, sa ramburseze reclamantului
 cheltuielile efectuate în scopul exercitãrii dreptului sau cu condiţia ca cheltuielile din acţiunea unde prevederea anterioarã
 este invocatã sa fie determinate conform dreptului statului unde s-a instituit acţiunea.</p><br>

<p style="font-weight:500;">ART. 24</p><br>

<p style="font-weight:500;">Avariile comune</p><br>

<p style="text-indent:2em;">1. Nici o prevedere din prezenta convenţie nu se opune aplicãrii prevederilor contractului de transport maritim sau a legislaţiei 
naţionale privind reglementarea avariilor comune.</p><br>

<p style="text-indent:2em;">2. Cu excepţia art. 20, prevederile prezentei convenţii în legatura cu rãspunderea cãrãuşului pentru pierderea sau avarierea suferitã
 de marfa stabilesc de asemenea dacã destinatarul poate refuza sa contribuie la avariile comune şi responsabilitatea cãrãuşului pentru 
 a-l despãgubi pe destinatar pentru o astfel de contribuţie sau pentru cheltuielile de salvare plãtite.</p><br>

<p style="font-weight:500;">ART. 25</p><br>

<p style="font-weight:500;">Alte convenţii</p><br>

<p style="text-indent:2em;">1. Prezenta convenţie nu afecteazã în nici un fel drepturile sau obligaţiile cãrãuşului, ale cãrãuşului efectiv şi ale prepuşilor
 sau mandatarilor lor rezultind din convenţii internaţionale sau din legislaţia nationala privind limitarea rãspunderii proprietarilor
 de nave maritime.</p><br>

<p style="text-indent:2em;">2. Prevederile art. 21 şi 22 ale prezentei convenţii nu se opun aplicãrii prevederilor obligatorii conţinute într-o alta convenţie
 multilaterala deja în vigoare la data încheierii prezentei convenţii şi care privesc probleme tratate în articolele menţionate, cu 
 condiţia ca diferendul sa apara în exclusivitate între pãrţi ce îşi au sediul în state care sînt membre la alta convenţie de acest fel.
 Totuşi, prezentul punct nu afecteazã aplicarea pct. 4 al art. 22 din prezenta convenţie.</p>

<p style="text-indent:2em;"></p><h6>3. Nu se creeazã nici o rãspundere în virtutea prevederilor prezentei convenţii cu privire la o avariere cauzatã de un accident nuclear,
 dacã cel care utilizeazã o instalatie nucleara este rãspunzãtor pentru aceasta avariere:</h6><p></p>

<p style="text-indent:2em;">a) fie în aplicarea Convenţiei de la Paris din 29 iulie 1960, cu privire la rãspunderea terţului în domeniul energiei nucleare, asa cum
 a fost modificatã prin Protocolul sau adiţional din 28 ianuarie 1964, fie în aplicarea Convenţiei de la Viena din 21 mai 1963 privind 
 rãspunderea civilã în materie de avarieri nucleare, sau</p><br>

<p style="text-indent:2em;">b) în baza legislaţiei naţionale ce guverneazã rãspunderea pentru asemenea avariere, cu condiţia ca aceasta legislaţie sa fie din toate
 punctele de vedere tot atît de favorabilã persoanelor ce ar putea fi lezate prin asemenea avariere ca şi Convenţia de la Paris sau cea
 de la Viena.</p><br>

<p style="text-indent:2em;">4. Nu se creeazã nici o rãspundere în virtutea prevederilor prezentei convenţii pentru pierderea, avarierea sau intirzierea în predarea
 barjelor, pentru care cãrãuşul este rãspunzãtor în baza oricãrei convenţii internaţionale sau a legislaţiei internaţionale referitoare
 la transportul pasagerilor şi al bagajelor lor pe mare.</p><br>

<p style="text-indent:2em;">5. Nici o prevedere din prezenta convenţie nu interzice unui stat contractant sa aplice o alta convenţie internationala care este deja 
în vigoare la data încheierii prezentei convenţii şi care se aplica obligatoriu la contractele de transport de mãrfuri efectuat în primul
 rind în alt mod decît cel maritim. Aceasta prevedere se aplica de asemenea tuturor revizuirilor sau modificãrilor ulterioare a convenţiei
 internaţionale respective.</p><br>

<p style="font-weight:500;">ART. 26</p><br>

<p style="font-weight:500;">Unitatea de cont</p><br>

<p style="text-indent:2em;">1. Unitatea de cont la care se face referire în art. 6 al prezentei convenţii este dreptul special de tragere asa cum este el definit de
 Fondul Monetar Internaţional. Sumele menţionate în art. 6 se vor converti în moneda nationala a unui stat în funcţie de valoarea acestei 
 monede la data pronunţãrii unei hotãrîri judecãtoreşti sau la o data convenitã de pãrţi. Valoarea, în drepturi speciale de tragere, 
 a monedei naţionale a unui stat contractant care este membru al Fondului Monetar Internaţional se calculeazã în conformitate cu metoda 
 de evaluare aplicatã de Fondul Monetar Internaţional la data respectiva pentru operaţiunile şi tranzacţiile sale. Valoarea, în drepturi
 speciale de tragere, a monedei naţionale a unui stat contractant care nu este membru al Fondului Monetar Internaţional se calculeazã în
 modul stabilit de acel stat.</p>

<p style="text-indent:2em;"></p><h6>2. Totuşi, acele state care nu sînt membre al Fondului Monetar Internaţional şi a cãror legislaţie nu permite aplicarea prevederilor pct.
 1 al prezentului articol pot, la data semnãrii sau la data ratificãrii, a acceptãrii, a aprobãrii sau a aderãrii sau la orice data ulterioara,
 sa declare ca limitele rãspunderii prevãzute în prezenta convenţie şi aplicabile pe teritoriile lor vor fi fixate la:</h6><p></p>

<p style="text-indent:2em;">12.000 unitãţi monetare pe colet sau alta unitate de transport sau 37,5 unitãţi monetare pe kg de
 greutate bruta a mãrfurilor.</p><br>

<p style="text-indent:2em;">3. Unitatea monetara la care se referã pct. 2 al prezentului articol corespunde cu 65,5 miligrame de aur de noua sute miimi puritate.
 Convertirea în moneda nationala a sumelor la care se referã pct. 2 se efectueazã conform legislaţiei statului respectiv.</p><br>

<p style="text-indent:2em;">4. Calculul menţionat în ultima fraza a pct. 1 şi convertirea menţionatã la pct. 3 al prezentului articol se vor face astfel încît sa
 exprime, în moneda nationala a statului contractant, pe cît este posibil, aceeaşi valoare realã cu cea care este exprimatã în unitãţi
 de cont în art. 6. La data semnãrii sau cînd se depun instrumentele lor de ratificare a acceptãrii, aprobãrii sau aderãrii sau cînd se
 prevaleazã de opţiunea oferitã la pct. 2 al prezentului articol, şi ori de cîte ori se produce o schimbare în metoda lor de calcul sau
 în rezultatul convertirii, statele contractante trebuie sa comunice depozitarului metoda de calcul în conformitate cu pct. 1 al prezentului
 articol sau rezultatul acestei convertiri menţionatã la pct. 3 al prezentului articol, dupã caz.</p><br>

<p style="font-weight:500;">PARTEA A VII-A</p><br>

<p style="font-weight:500;">CLAUZE FINALE</p><br>

<p style="font-weight:500;">ART. 27</p><br>

<p style="font-weight:500;">Depozitar</p><br>

<p>Secretarul general al Organizaţiei Naţiunilor Unite este desemnat ca depozitar al prezentei convenţii.</p><br>

<p style="font-weight:500;">ART. 28</p><br>

<p style="font-weight:500;">Semnarea, ratificarea, acceptarea, aprobarea, aderarea</p><br>

<p style="text-indent:2em;">1. Prezenta convenţie este deschisã spre semnare tuturor statelor, pînã la 30 aprilie 1979, la sediul Organizaţiei
 Naţiunilor Unite din New York.</p><br>

<p>2. Prezenta convenţie este supusã ratificãrii, acceptãrii sau aprobãrii de cãtre statele semnatare.</p><br>

<p>3. Dupã 30 aprilie 1979, prezenta convenţie va fi deschisã spre aderare tuturor statelor care nu sînt semnatare.</p><br>

<p style="text-indent:2em;">4. Instrumentele de ratificare, de acceptare, de aprobare şi de aderare vor fi depuse secretarului general al
 Organizaţiei Naţiunilor Unite.</p><br>

<p style="font-weight:500;">ART. 29</p><br>

<p style="font-weight:500;">Rezerve</p><br>

<p>Nici o rezerva nu este admisã la prezenta convenţie.</p><br>

<p style="font-weight:500;">ART. 30</p><br>

<p style="font-weight:500;">Intrarea în vigoare</p><br>

<p style="text-indent:2em;">1. Prezenta convenţie intra în vigoare în prima zi a lunii care urmeazã expirãrii unui termen de un an de la data depunerii
 celui de-al douazecilea instrument de ratificare, de acceptare, de aprobare sau de aderare.</p><br>

<p style="text-indent:2em;">2. Pentru fiecare stat care devine un stat contractant la prezenta convenţie dupã data depunerii celui de-al douazecilea 
instrument de ratificare, acceptare, aprobare sau aderare, prezenta convenţie intra în vigoare în prima zi a lunii ce urmeazã
 expirãrii termenului de un an de la data depunerii instrumentului corespunzãtor în numele statului respectiv.</p><br>

<p style="text-indent:2em;">3. Fiecare stat contractant va aplica prevederile prezentei convenţii contractelor de transport maritim încheiate la sau dupã
 data intrãrii în vigoare a prezentei convenţii în ceea ce priveşte statul respectiv.</p><br>

<p style="font-weight:500;">ART. 31</p><br>

<p style="font-weight:500;">Denunţarea altor convenţii</p><br>

<p style="text-indent:2em;">1. În momentul cînd devine stat contractant la prezenta convenţie, orice stat parte la Convenţia internationala pentru unificarea
 anumitor reguli în materie de conosamente, semnatã la Bruxelles la 25 august 1924 (Convenţia din 1924), trebuie sa notifice 
 guvernului belgian, ca depozitar al Convenţiei din 1924, ca denunta numita convenţie, declarind ca denunţarea va intra în vigoare
 la data cînd prezenta convenţie intra în vigoare pentru acel stat.</p><br>

<p style="text-indent:2em;">2. La data intrãrii în vigoare a prezentei convenţii în virtutea pct. 1 al art. 30, depozitarul prezentei convenţii trebuie sa 
notifice guvernului Belgiei, ca depozitar al Convenţiei din 1924, data acestei intrari în vigoare, precum şi numele statelor
 contractante pentru care convenţia a intrat în vigoare.</p><br>

<p style="text-indent:2em;">3. Prevederile pct. 1 şi 2 ale prezentului articol se aplica în mod corespunzãtor statelor pãrţi la Protocolul semnat la 23 februarie
 1968, care aduce modificãri Convenţiei internaţionale pentru unificarea anumitor reguli în materie de conosamente, semnatã la
 Bruxelles la 25 august 1924.</p><br>

<p style="text-indent:2em;">4. Deosebit de prevederile art. 2 al prezentei convenţii, în sensul pct. 1 al prezentului articol, un stat contractant poate, 
dacã considera necesar, sa amine denunţarea Convenţiei din 1924 şi a Convenţiei din 1924, asa cum a fost modificatã prin Protocolul
 din 1968, pentru o perioada de maximum 5 ani de la data intrãrii în vigoare a prezentei convenţii. În acest caz, va notifica guvernului
 belgian intenţia sa. În timpul acestei perioade tranzitorii va trebui sa se aplice statelor contractante prezenta convenţie excluzind 
 oricare alta convenţie.</p><br>

<p style="font-weight:500;">ART. 32</p><br>

<p style="font-weight:500;">Revizuire şi amendamente</p><br>

<p style="text-indent:2em;">1. La cererea a minimum o treime din statele contractante la prezenta convenţie, depozitarul va convoca o conferinţa a statelor 
contractante în scopul de a revizui sau de a aduce amendamente la prezenta convenţie.</p><br>

<p style="text-indent:2em;">2. Orice instrument de ratificare, de acceptare, de aprobare sau de aderare depus dupã intrarea în vigoare a unui amendament la 
prezenta convenţie va fi considerat ca se aplica la convenţie asa cum a fost ea modificatã.</p><br>

<p style="font-weight:500;">ART. 33</p><br>

<p>Revizuirea cuantumurilor limita şi a unitãţii de cont sau a unitãţii monetare</p><br>

<p style="text-indent:2em;">1. Deosebit de prevederile art. 32, o conferinţa avînd ca obiect numai revizuirea cuantumurilor stabilite în art. 6 şi la pct. 2 al
 art. 26 sau substituirea oricãreia sau ambelor unitãţi definite la pct. 1 şi 3 ale art. 26, prin alte unitãţi, va fi convocatã de 
 cãtre depozitar în conformitate cu pct. 2 al prezentului articol.</p><br> 

<p>Revizuirea cuantumurilor se va face numai ca urmare a unei schimbãri semnificative a valorii lor reale.</p><br>

<p style="text-indent:2em;">2. O conferinţa de revizuire va fi convocatã de cãtre depozitar la cererea a minimum 
o pãtrime din numãrul statelor contractante.</p><br>

<p style="text-indent:2em;">3. Orice hotãrîre a conferintei trebuie adoptatã cu o majoritate de doua treimi din numãrul statelor participante. Amendamentul va fi 
comunicat de cãtre depozitar tuturor statelor contractante pentru acceptare şi tuturor statelor semnatare ale convenţiei pentru 
informare.</p><br>

<p style="text-indent:2em;">4. Orice amendament adoptat intra în vigoare în prima zi a lunii ce urmeazã expirãrii unui an de la acceptarea ei de cãtre doua treimi
 din numãrul statelor contractante. Acceptarea se va efectua prin depunerea unui instrument formal în acest sens depozitarului.</p><br>

<p style="text-indent:2em;">5. Dupã intrarea în vigoare a unui amendament, un stat contractant care a acceptat amendamentul este îndreptãţit sa aplice convenţia 
asa cum a fost ea modificatã în relaţiile sale cu statele contractante care, într-un termen de 6 luni de la adoptarea amendamentului, 
nu au notificat depozitarului ca nu sînt legate de modificarea respectiva.</p><br>

<p style="text-indent:2em;">6. Orice instrument de ratificare, de acceptare, de aprobare sau de aderare depus dupã intrarea în vigoare a unui amendament la 
prezenta convenţie se considera ca se aplica la convenţie asa cum a fost ea modificatã.</p><br>

<p style="font-weight:500;">ART. 34</p><br>

<p style="font-weight:500;">Denunţarea</p><br>

<p style="text-indent:2em;">1. Orice stat contractant poate denunta în orice moment prezenta convenţie printr-o notificare
 în scris adresatã depozitarului.</p><br>

<p style="text-indent:2em;">2. Denunţarea intra în vigoare în prima zi a lunii ce urmeazã expirãrii unui an de la primirea notificãrii de cãtre depozitar. Dacã 
în notificare este specificatã o perioada mai lungã, denunţarea intra în vigoare la expirarea perioadei respective dupã ce notificarea 
a fost primitã de cãtre depozitar.</p><br>

<p style="text-indent:2em;">Facuta la Hamburg azi, treizeci şi unu martie una mie noua sute şaptezeci şi opt, într-un singur original, ale cãrui texte în limbile 
arabã, chineza, engleza, franceza, rusa şi spaniola sînt, de asemenea, autentice.</p><br>

<p style="text-indent:2em;">Drept pentru care subsemnaţii plenipotentiari, pe deplin autorizaţi de guvernele lor respective,
 au semnat prezenta convenţie.</p><br>

<p style="font-weight:500;">CONSENSUL ADOPTAT DE CONFERINŢA NAŢIUNILOR UNITE ASUPRA TRANSPORTULUI DE</p><br>

<p style="font-weight:500;">MĂRFURI PE MARE</p><br>

<p style="text-indent:2em;">Se înţelege ca rãspunderea cãrãuşului în virtutea prezentei convenţii se bazeazã pe principiul culpei sau neglijentei prezumate. 
Aceasta înseamnã ca, de regula, sarcina prezentãrii de dovezi incumba cãrãuşului, dar ca, în anumite cazuri, prevederile convenţiei 
modifica aceasta regula.</p><br>

<p style="font-weight:500;">REZOLUŢIA adoptatã de Conferinţa Naţiunilor Unite asupra transportului de mãrfuri pe mare</p><br>

<p style="text-indent:2em;">Conferinţa Naţiunilor Unite asupra transportului de mãrfuri pe mare, amintind cu recunoştinţã amabila initiativa a 
Republicii Federale Germania, care a gãzduit conferinţa la Hamburg, constienta de faptul ca serviciile puse la dispoziţia conferintei
 şi generoasa ospitalitate acordatã participanţilor de guvernul Republicii Federale Germania şi de oraşul liber şi hanseatic Hamburg au
 contribuit în mare mãsura la succesul conferintei, exprima recunoştinţã sa guvernului şi poporului Republicii Federale Germania şi, 
 adoptînd Convenţia privind transportul de mãrfuri pe mare pe baza unui proiect de convenţie elaborat de Comisia Naţiunilor Unite pentru
 dreptul comercial internaţional, la solicitarea Conferintei Naţiunilor Unite pentru comerţ şi dezvoltare, exprima recunoştinţã sa Comisiei
 Naţiunilor Unite pentru dreptul comercial internaţional şi Conferintei Naţiunilor Unite pentru comerţ şi dezvoltare, pentru contribuţia lor
 remarcabila la simplificarea şi la armonizarea dreptului privind transportul mãrfurilor pe mare, şi  hotãrãşte sa intituleze convenţia 
 adoptatã de conferinţa: Convenţia Naţiunilor Unite privind transportul de mãrfuri pe mare, 1978, şi recomanda ca regulile pe care le conţine
 sa fie denumite "Regulile de la Hamburg".</p><br>

<p style="font-weight:500;">NOTA</p><br>

<p style="text-indent:2em;">Convenţia a fost adoptatã la 30 martie 1978 prin Conferinţa Naţiunilor Unite asupra transportului mãrfurilor pe mare, 
care s-a ţinut la Hamburg între 6 şi 31 martie 1978.</p><br>

<p style="text-indent:2em;">Conferinţa a fost convocatã de cãtre Secretarul general al O.N.U., în conformitate cu rezoluţia nr. 31/100 adoptatã de 
Adunarea generalã din 15 decembrie 1976.</p><br>

<p style="text-indent:2em;">Convenţia a fost deschisã odatã cu semnatura de la Hamburg la 31 martie 1978 şi a rãmas deschisã pînã la semnarea ei de 
cãtre toate statele la sediul O.N.U. de la New York, pînã la 30 aprilie 1979.</p>

<p style="text-indent:2em;"></p><h6>Statul,Semnatura,Ratificare/aderare (a):</h6><p></p>

<p>Australia      
30 aprilie 1979        
2 februarie 1981/a)</p><br>
    
<p>Ins. Barbados</p><br>

<p>Brazilia
31 martie 1978</p><br>

<p>Cehoslovacia
6 martie 1979</p><br>

<p>Chile
31 martie 1978
9 iulie 1982</p><br>

<p>Danemarca
18 aprilie 1979</p><br>

<p>Ecuador
31 martie 1978</p><br>

<p>Egipt
31 martie 1978
23 aprilie 1979</p><br>

<p>Finlanda
18 aprilie 1979</p><br>

<p>Filipine
14 iunie 1978</p><br>

<p>Franta
18 aprilie 1979</p><br>

<p>Ghana
31 martie 1978</p><br>

<p>R. F. G.
31 martie 1978</p><br>

<p>Madagascar
31 martie 1978</p><br>

<p>Maroc</p><br>

<p>Norvegia
19 aprilie 1979
12 iunie 1981 a)</p><br>

<p>Pakistan
8 martie 1979</p><br>

<p>Panama
31 martie 1978</p><br>

<p>Portugalia
31 martie 1978</p><br>

<p>Senegal
31 martie 1978</p><br>

<p>Sierra Leone
15 august 1978</p><br>

<p>Singapore
31 martie 1978</p><br>

<p>S.U.A.
30 aprilie 1979</p><br>

<p>Suedia
18 aprilie 1979</p><br>

<p>Thailanda
6 decembrie 1985</p><br>

<p>R. P. Unita</p><br>

<p>Tanzania
24 iulie 1979/a</p><br>

<p>Tunisia
15 septembrie 1980 a)</p><br>

<p>Uganda
6 iulie 1979</p><br>
        
<p>R.P. Ungaria
31 martie 1978</p><br>
               
<p>Vatican
1 octombrie 1986</p><br>
   
<p>Venezuela
31 martie 1978</p><br>
       
<p>Zair
19 aprilie 1979</p><br>

<p><a href="http://www.monitoruljuridic.ro/act/conventia-din-1978-natiunilor-unite-privind-transportul-de-marfuri-pe-mare-1978-emitent-consiliul-de-stat-publicat-n-24467.html">Sursa</a></p>


</div>
`