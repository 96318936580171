export const int8 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>КОНВЕНЦИЯ О ДОРОЖНОМ ДВИЖЕНИИ</h4><p></p>

<p style="text-indent:2em;"></p><h6>Договаривающиеся стороны, желая облегчить международное дорожное движение и повысить безопасность
 на дорогах путем принятия единообразных правил движения, согласились о нижеследующем:</h6><p></p>

<p></p><h4>Глава I</h4><p></p>

<p style="font-weight:500;">ОБЩИЕ ПОЛОЖЕНИЯ</p><br>

<p style="font-weight:500;">Статья 1</p><br>

<p>Определения</p>

<p style="text-indent:2em;"></p><h6>При применении положений настоящей Конвенции нижеследующие термины имеют то значение, которое придается
 им в настоящей статье:</h6><p></p>

<p style="text-indent:2em;">а) термин "национальное законодательство" Договаривающейся стороны означает совокупность национальных
 или местных законов и правил, действующих на территории этой Договаривающейся стороны;</p>

<p style="text-indent:2em;"></p><h6>b) транспортное средство считается находящимся в "международном движении" по территории какого-либо
 государства, если оно:</h6><p></p>

<p style="text-indent:2em;">i) принадлежит физическому или юридическому лицу, имеющему обычное местожительство вне этого
 государства </p><br>

<p>ii) не зарегистрировано в этом государстве;</p><br>

<p>iii) временно ввезено в него;</p><br>

<p style="text-indent:2em;">однако каждая Договаривающаяся сторона вправе не считать находящимся в "международном движении" любое
 транспортное средство, которое оставалось на ее территории более одного года без значительного перерыва,
 продолжительность которого может устанавливаться этой Договаривающейся стороной.</p><br>

<p style="text-indent:2em;">Состав транспортных средств считается находящимся в "международном движении", если по крайней мере
 одно из транспортных средств состава отвечает этому определению;</p><br>

<p style="text-indent:2em;">с) термин "населенный пункт" означает застроенную территорию, въезды на которую и выезды с которой
 специально обозначены соответствующими знаками или которая каким-либо иным образом определена в
 национальном законодательстве;</p><br>

<p>d) термин "дорога" означает всю полосу отвода любой дороги или улицы,
 открытой для движения;</p><br>

<p style="text-indent:2em;">e) термин "проезжая часть дороги" означает часть дороги, обычно используемую для движения транспортных
 средств; дорога может иметь несколько проезжих частей, четко разграниченных, в частности разделительной
 полосой, или находящихся на различных уровнях;</p><br>

<p style="text-indent:2em;">f) на проезжей части, где крайняя полоса или крайние полосы движения или велодорожки предназначены для
 движения определенных транспортных средств, термин "край проезжей части" означает для других пользователей
 дороги край остальной части дороги;</p><br>

<p style="text-indent:2em;">g) термин "полоса движения" означает любую из продольных полос, на которые может быть разделена проезжая
 часть дороги, обозначенных или не обозначенных посредством продольной разметки, но имеющих ширину, достаточную
 для движения в один ряд автомобилей, иных, чем мотоциклы;</p><br>

<p style="text-indent:2em;">h) термин "перекресток" означает любое пересечение на одном уровне, соединение или разветвление дорог, включая
 территорию, образуемую такими пересечениями, соединениями или разветвлениями;</p><br>

<p style="text-indent:2em;">i) термин "железнодорожный переезд" означает любое пересечение на одном уровне дороги с железнодорожными или
 трамвайными путями, имеющими независимое полотно;</p>

<p style="text-indent:2em;"></p><h6>j) термин "автомагистраль" означает дорогу, специально построенную и предназначенную для движения автотранспортных
 средств, которая не обслуживает придорожных владений и которая:</h6><p></p>

<p style="text-indent:2em;">i) за исключением отдельных мест или во временном порядке, имеет для обоих направлений движения отдельные
 проезжие части, отделенные друг от друга разделительной полосой, не предназначенной для движения, или, в
 исключительных случаях, другими средствами;</p><br>

<p style="text-indent:2em;">ii) не имеет пересечений на одном уровне ни с дорогами, ни с железнодорожными или трамвайными путями, ни
 с пешеходными дорожками;</p><br>

<p>iii) специально обозначена в качестве автомагистрали;</p>

<p style="text-indent:2em;"></p><h6>к) транспортное средство считается:</h6><p></p>

<p style="text-indent:2em;">i) "остановленным", если оно приведено в неподвижное состояние на период времени, необходимый либо для
 посадки или высадки людей, либо для погрузки или выгрузки грузов;</p><br>

<p style="text-indent:2em;">ii) "находящимся на стоянке", если оно приведено в неподвижное состояние по причине, иной, чем необходимость
 избежать столкновения с другим пользователем дороги или с каким-либо препятствием или выполнить предписания
 правил движения, и если его остановка не ограничивается временем, необходимым либо для посадки или высадки
 людей, либо для погрузки или выгрузки грузов.</p><br>

<p style="text-indent:2em;">Однако Договаривающиеся стороны стороны могут считать "остановленными" транспортные средства, приведенные в
 неподвижное состояние в условиях, определенных выше, в подпункте ii, если длительность этого состояния не
 превышает предела времени, установленного национальным законодательством, и могут считать "находящимися на
 стоянке" транспортные средства, приведенные в неподвижное состояние в условиях, определенных выше, в подпункте i
 , если длительность этого состояния превышает предел времени, установленный национальным законодательством;</p><br>

<p style="text-indent:2em;">l) термин "велосипед" означает любое транспортное средство, которое имеет по крайней мере два колеса и приводится
 в движение исключительно мускульной энергией лиц, находящихся на этом транспортном средстве, в частности с
 помощью педалей или рукояток;</p><br>

<p style="text-indent:2em;">m) термин "велосипед с подвесным двигателем" означает любое из двух- или трехколесное транспортное средство,
 снабженное двигателем внутреннего сгорания с объемом цилиндра, не превышающим 50 куб.см (3.05 куб. дюйма),
 и максимальная конструктивная скорость которого не превышает 50 км/час (30 миль/час); однако Договаривающиеся
 стороны могут не считать велосипедами с подвесным двигателем в соответствии со своим национальным законодательством
 те транспортные средства, которые не имеют характеристик велосипедов в отношении возможностей их использования, в
 частности возможности приведения их в движение с помощью педалей, или которые по своей максимальной конструктивной
 скорости, весу или некоторым характеристикам двигателя превышают установленные пределы. Ни одно положение настоящего
 определения не должно толковаться как препятствующее Договаривающимся сторонам полностью приравнивать велосипеды с
 подвесным двигателем к велосипедам при применении предписаний своих национальных законодательств, касающихся дорожного
 движения;</p><br>

<p style="text-indent:2em;">n) термин "мотоцикл" означает любое двухколесное транспортное средство с коляской или без нее, имеющее двигатель.
 Договаривающиеся стороны могут в своем национальном законодательстве приравнивать к мотоциклам трехколесные транспортные
 средства, вес которых без нагрузки не превышает 400 кг (900 фунтов). Термин "мотоцикл" не включает велосипеды с подвесным
 двигателем, однако Договаривающиеся стороны могут при условии, что они сделаю соответствующее заявление согласно пункту 2
 статьи 54 настоящей Конвенции, приравнивать велосипеды с подвесным двигателем к мотоциклам при применении настоящей 
 Конвенции;</p><br>

<p style="text-indent:2em;">о) термин "механическое транспортное средство" означает любое самоходное дорожное транспортное средство, за исключением
 велосипедов с подвесным двигателем, на территории Договаривающихся сторон, которые не приравнивают их к мотоциклам, и
 за исключением рельсовых транспортных средств;</p><br>

<p style="text-indent:2em;">р) термин "автомобиль" означает механическое транспортное средство, используемое обычно для перевозки по дорогам людей
 или грузов или для буксировки по дорогам транспортных средств, используемых для перевозки людей или грузов. Этот термин
 охватывает троллейбусы, то есть нерельсовые транспортные средства, соединенные с электрическим приводом; он не охватывает
 такие транспортные средства, как сельскохозяйственные тракторы, использование которых для перевозки людей или грузов по
 дорогам или для буксировки по дорогам транспортных средств, используемых для перевозки людей или грузов, является лишь
 вспомогательной функцией;</p><br>

<p style="text-indent:2em;">q) термин "прицеп" означает любое транспортное средство, предназначенное для буксировки механическим транспортным
 средством; этот термин охватывает также полуприцепы;</p><br>

<p style="text-indent:2em;">r) термин "полуприцеп" означает любой прицеп, предназначенный для сцепления с автомобилем таким образом, что часть
 его опирается на автомобиль, и последний несет на себе значительную часть веса полуприцепа и его 
 груза;</p><br>

<p style="text-indent:2em;">s) термин "легкий прицеп" означает всякий прицеп, разрешенный максимальный вес которого не превышает 750 кг
 (1650 фунтов);</p><br>

<p style="text-indent:2em;">t) термин "состав транспортных средств" означает сцепленные транспортные средства, которые участвуют в дорожном
 движении как одно целое;</p><br>

<p style="text-indent:2em;">u) термин "транспортное средство с полуприцепом" означает состав из 
автомобиля и сцепленного с ним полуприцепа;</p><br>

<p style="text-indent:2em;">v) термин "водитель" ("погонщик") означает всякое лицо, управляющее транспортным средством, автомобилем и т.д.
 (включая велосипеды) или ведущее по дорогам скот, стада, упряжных, вьючных или верховых животных;</p><br>

<p style="text-indent:2em;">w) термин "разрешенный максимальный вес" означает максимальный вес груженого транспортного средства, объявленный
 допустимым компетентным органом государства, в котором зарегистрировано транспортное средство;</p><br>

<p style="text-indent:2em;">х) термин "вес без нагрузки" означает вес транспортного средства без экипажа, пассажиров и груза, но с полным
 запасом топлива и необходимым комплектом инструмента;</p><br>

<p style="text-indent:2em;">y) термин "вес с нагрузкой" означает фактический вес груженого транспортного средства,
 экипажа и пассажиров;</p><br>

<p style="text-indent:2em;">z) термины "направление движений" и "соответствующий направлению движения" означают правую сторону, если в
 соответствии с национальным законодательством водитель транспортного средства должен при встрече с другим
 транспортным средством пропустить его слева; в противном случае эти термины означают левую сторону;</p><br>

<p style="text-indent:2em;">аа) предписание для водителя "уступить дорогу" другим транспортным средствам означает, что водитель не должен продолжать
 или возобновлять движение или осуществлять маневр, если это может вынудить водителей других транспортных средств
 внезапно изменить направление движения или скорость.</p><br>

<p style="font-weight:500;">Статья 2</p><br>

<p>Приложения к Конвенции</p>

<p style="text-indent:2em;"></p><h6>Нижеследующие приложения к настоящей Конвенции являются ее неотъемлемой 
частью:</h6><p></p>

<p>приложение 1: Отступления от обязательства допускать к международному движению автомобили и прицепы;</p><br>

<p>приложение 2: Регистрационный номер автомобилей и прицепов, находящихся в международном движении;</p><br>

<p>приложение 3: Отличительный знак автомобилей и прицепов, находящихся в международном движении;</p><br>

<p>приложение 4: Опознавательные знаки автомобилей и прицепов, находящихся в международном движении;</p><br>

<p>приложение 5: Технические условия, касающиеся автомобилей и прицепов;</p><br>

<p>приложение 6: Национальное водительское удостоверение;</p><br>

<p>приложение 7: Международное водительское удостоверение;</p><br>

<p style="font-weight:500;">Статья 3</p>

<p style="text-indent:2em;"></p><h6>Обязательства Договаривающихся сторон 1. а) Договаривающиеся стороны принимают соответствующие меры к тому, чтобы
 действующие на их территории правила дорожного движения по своему существу соответствовали положениям главы II настоящей 
 Конвенции. При условии, что эти правила ни в каком отношении не противоречат упомянутым положениям:</h6><p></p>

<p style="text-indent:2em;">i) эти правила могут не включать те положения, которые относятся к случаям, не имеющим места на территории данной
 Договаривающейся стороны;</p><br>

<p>ii) эти правила могут содержать положения, не предусмотренные в главе II.</p><br>

<p style="text-indent:2em;">b) положения настоящего пункта не обязывают Договаривающиеся стороны предусматривать санкции за любое нарушение положений
 главы II, которые они включили в свои правила дорожного движения.</p><br>

<p style="text-indent:2em;">2. а) Договаривающиеся стороны принимают также надлежащие меры к тому, чтобы действующие на их территории правила,
 касающиеся технических условий, которым должны отвечать автомобили и прицепы, соответствовали положениям приложения
 5 к настоящей Конвенции; эти правила могут содержать положения, не предусмотренные в приложении 5, при условии, что
 эти положения не будут ни в каком отношении противоречить принципам безопасности, определяющим положения приложения5.
 Они также принимают надлежащие меры к тому, чтобы находящиеся в международном движении автомобили и прицепы,
 зарегистрированные на их территории, соответствовали положениям приложения5 настоящей Конвенции.</p><br>

<p style="text-indent:2em;">b) Что касается действующих на территории Договаривающихся сторон правил, то положения настоящего пункта не предписывают
 этим сторонам каких-либо обязательств в отношении технических условий, которым должны отвечать механические транспортные
 средства, не являющиеся автомобилями применительно к настоящей Конвенции.</p><br>

<p style="text-indent:2em;">3. С учетом отступлений, предусмотренных в приложении 1 к настоящей Конвенции, Договаривающиеся стороны обязуются
 допускать к международному движению по своей территории автомобили и прицепы, которые отвечают требованиям, предусмотренным
 в главе III настоящей Конвенции, и водители которых отвечают требованиям главы IY; Договаривающиеся стороны обязуются также
 признавать свидетельства о регистрации, выдаваемые в соответствии с положениями главы III, как удостоверяющие, если не будет
 доказано обратное, что транспортные средства, на которые они выданы, отвечают условиям, предусмотренным в указанной главе
 III.</p><br>

<p style="text-indent:2em;">4. Меры, которые Договаривающиеся стороны приняли или примут либо в одностороннем порядке, либо путем подписания двусторонних
 или многосторонних соглашений с целью допущения на их территории к международному движению автомобилей и прицепов,
 не удовлетворяющих всем условиям, указанным в главе III настоящей Конвенции, и признания, помимо случаев, предусмотренных 
 в главе IY, действительности на их территории водительских удостоверений, выданных какой-либо другой Договаривающейся стороной,
 считаются соответствующими целям настоящей Конвенции.</p><br>

<p style="text-indent:2em;">5. Договаривающиеся стороны обязуются допускать к международному движению по своей территории велосипеды и велосипеды с 
подвесным двигателем, которые отвечают техническим условиям, предусмотренным в главе Y настоящей Конвенции, и водители которых
 имеют обычное местожительство на территории другой Договаривающейся стороны. Ни одна Договаривающаяся сторона не может требовать
 наличия водительского удостоверения у водителей велосипедов или велосипедов с подвесным двигателем, находящихся в международном
 движении; однако те Договаривающиеся стороны, которые в соответствии с положениями пункта 2 статьи 54 настоящей Конвенции сделают
 заявление относительно приравнивания к мотоциклам велосипедов с подвесным двигателем, могут требовать наличия водительского удостоверения
 у водителей велосипедов с подвесным двигателем, находящихся в международном движении.</p><br>

<p style="text-indent:2em;">6. Каждая Договаривающаяся сторона обязуется сообщать любой Договаривающейся стороне по ее просьбе информацию, позволяющую установить
 лицо, на имя которого автомобиль или сцепленный с автомобилем прицеп зарегистрирован на ее территории, если в представленной просьбе
 указано, что это транспортное средство причастно к дорожно0транспортному происшествию6 имевшему место на территории Договаривающейся
 стороны, которая обратилась с такой просьбой.</p><br>

<p style="text-indent:2em;">7. считаются соответствующими целям настоящей Конвенции меры, которые приняли или примут Договаривающиеся стороны либо в одностороннем
 порядке, либо путем заключения двусторонних или многосторонних соглашений в целях облегчения международного движения путем упрощения
 таможенных, полицейских6 санитарных или иных подобного рода формальностей, а также меры, направленные на то, чтобы привести в соответствие
 компетенцию и часы работы таможенных отделений и постов на одном и том же пограничном пункте.</p><br>

<p style="text-indent:2em;">8. Положения пунктов 3, 5 и 7 настоящей статьи не препятствуют использованию каждой Договаривающейся стороной своего права подчинять
 допущение к международному движению по ее территории автомобилей, прицепов, велосипедов с подвесным двигателем и велосипедов, а также
 их водителей и пассажиров своим правилам коммерческих перевозок пассажиров и грузов, своим правилам в области страхования от гражданской 
 ответственности водителей и своим таможенным правилам, а также вообще своим предписаниям в областях, не относящихся к дорожному
 движению.</p><br>

<p style="font-weight:500;">Статья 4</p><br>

<p>Дорожные знаки и сигналы</p>

<p style="text-indent:2em;"></p><h6>Договаривающиеся стороны настоящей Конвенции, не являющиеся Договаривающимися сторонами Конвенции о дорожных знаках и сигналах, открытой
 для подписания в Вене одновременно с настоящей Конвенцией, обязуются принять меры к тому, чтобы:</h6><p></p>

<p style="text-indent:2em;">а) все установленные на их территории дорожные знаки и сигналы, световые дорожные сигналы и разметка дорог составляли единую
 систему;</p><br>

<p style="text-indent:2em;">b) число типов сигналов было ограниченно, и сигналы устанавливались лишь в тех местах, где 
их наличие считается полезным;</p><br>

<p style="text-indent:2em;">с) предупреждающие сигнальные знаки устанавливались на достаточном расстоянии от препятствий для своевременного предупреждения
 о них водителей;</p>

<p style="text-indent:2em;"></p><h6>d) было запрещено:</h6><p></p>

<p style="text-indent:2em;">i) помещать на сигнальном знаке, на его опоре или любом другом приспособлении, предназначенном для регулирования движения, что-либо,
 что не имеет отношения к значению этого сигнального знака или приспособления; однако, если Договаривающиеся стороны или их территориальные
 подразделения разрешают какой-либо ассоциации, не имеющей целью получение материальной выгоды, устанавливать указательные знаки, они могут
 разрешать, чтобы эмблема этой ассоциации обозначалась на знаке или на его опоре при условии, что это не затруднит его
 понимания;</p><br>

<p style="text-indent:2em;">ii) помещать плакаты, афиши, производить разметку и устанавливать приспособления, которые либо могут быть приняты за сигнальные знаки или
 другие приспособления для регулирования движения, либо могут снизить их видимость или эффективность, либо ослеплять пользователей дороги
 или отвлекать их внимание и тем самым ставить под угрозу безопасность дорожного движения.</p>

<p></p><h4>Глава II</h4><p></p>

<p style="font-weight:500;">ПРАВИЛА ДОРОЖНОГО ДВИЖЕНИЯ</p><br>

<p style="font-weight:500;">Статья 5</p><br>

<p>Значение дорожных знаков и сигналов</p><br>

<p style="text-indent:2em;">1. Пользователи дороги должны выполнять предписания дорожных знаков и сигналов, световых дорожных сигналов и разметки дорог, даже если
 упомянутые предписания кажутся противоречащими другим правилам движения.</p><br>

<p style="text-indent:2em;">2. Предписания световых дорожных сигналов превалирует над предписаниями дорожных знаков, определяющих преимущественное право
 проезда.</p><br>

<p style="font-weight:500;">Статья 6</p><br>

<p>Сигналы лиц, уполномоченных регулировать дорожное движение</p><br>

<p style="text-indent:2em;">1. Лица, уполномоченные регулировать дорожное движение, должны быть хорошо распознаваемыми и видимыми на расстоянии как днем, так
 и ночью.</p>

<p style="text-indent:2em;"></p><h6>2. пользователи дороги должны немедленно выполнять любое указание лиц, уполномоченных регулировать дорожное движение. 3. рекомендуется
 предусмотреть в национальном законодательстве, чтобы в качестве указаний лиц, уполномоченных регулировать дорожное движение, считались,
 в частности, перечисленные ниже сигналы:</h6><p></p>

<p style="text-indent:2em;">а) поднятая вертикально рука; этот жест означает "внимание, стоп" для всех пользователей дороги, кроме тех водителей, которые не могли бы
 уже остановиться в условиях достаточной безопасности; кроме того, если этот сигнал подается на перекрестке, водитель, уже выехавший на
 перекресток, может продолжать движение;</p><br>

<p style="text-indent:2em;">b) рука или руки, вытянутые горизонтально; этот жест означает сигнал "стоп" для всех пользователей дороги, которые независимо от направления
 их движения движутся с направлений, пересекающих направление, обозначенное вытянутой рукой или руками лица, уполномоченного регулировать
 дорожное движение; подав этот сигнал, регулировщик может опустить руку или руки; для водителей, к которым он обращен грудью или спиной
 , этот жест также означает сигнал "стоп";</p><br>

<p style="text-indent:2em;">с) покачивание красным огнем; этот жест означает сигнал "стоп" для пользователей дороги,
 в сторону которых направлен этот огонь.</p><br>

<p style="text-indent:2em;">4. Сигналы лиц, уполномоченных регулировать дорожное движение, превалируют над предписаниями дорожных знаков и сигналов и световых дорожных
 сигналов или разметки дорог, а также правил дорожного движения.</p><br>

<p style="font-weight:500;">Статья 7.</p><br>

<p>Общие правила</p><br>

<p style="text-indent:2em;">1. Пользователи дороги должны вести себя таким образом, чтобы не создавать опасности или препятствий для движения, не подвергать опасности
 людей и не причинять ущерба государственному, общественному или частному имуществу.</p><br>

<p style="text-indent:2em;">2. Рекомендуется предусмотреть в национальном законодательстве, что пользователи дороги не должны стеснять дорожное движение или ставить
 под угрозу его безопасность, бросая, складывая или оставляя на дороге предметы или материалы, или создавая на ней какие-либо другие препятствия
 . Пользователи дороги, которым не удалось избежать создания такого препятствия или такой опасности, должны принять необходимые меры для возможно
 более быстрого их устранения и, если они не могут сделать это немедленно, для предупреждения 
 о них других пользователей дороги.</p><br>

<p style="font-weight:500;">Статья 8</p><br>

<p>Водители</p><br>

<p style="text-indent:2em;">1. Каждое транспортное средство или состав транспортных средств, которые
 находятся в движении, должны иметь водителя.</p><br>

<p style="text-indent:2em;">2. Рекомендуется предусмотреть в национальном законодательстве, что вьючные, упряжные и верховые животные, а также скот и стада (за исключением
 соответствующим образом обозначенных зон) должны иметь погонщика.</p><br>

<p style="text-indent:2em;">3. Водитель должен обладать необходимыми физическими и психическими качествами, и его физическое и умственное состояние должно позволять ему
 управлять транспортным средством.</p><br>

<p style="text-indent:2em;">4. Водитель механического транспортного средства должен иметь знания и навыки, необходимые для управления транспортным средством; однако
 это положение не препятствует обучению вождению в соответствии с 
 положениями национального законодательства.</p><br>

<p style="text-indent:2em;">5. Водитель (погонщик) должен быть всегда в состоянии управлять своим транспортным 
средством (направлять своих животных).</p><br>

<p style="font-weight:500;">Статья 9</p><br>

<p>Стада животных</p><br>

<p style="text-indent:2em;">Рекомендуется предусмотреть в национальном законодательстве, что, за исключением отступлений, имеющих целью облегчить перегон скота, стада
 должны разделяться на части умеренной длины, причем расстояние между этими частями 
 должно быть достаточным для удобства движения.</p><br>

<p style="font-weight:500;">Статья 10</p><br>

<p>Расположение на проезжей части</p><br>

<p style="text-indent:2em;">1. Направление движения должно быть одинаковым на всех дорогах данного государства, за исключением в соответствующих случаях, используемых
 исключительно или главным образом для транзита между двумя другими государствами.</p><br>

<p style="text-indent:2em;">2. Двигающихся по дороге животных следует вести как можно ближе к краю проезжей 
части, соответствующему направлению движения.</p><br>

<p style="text-indent:2em;">3. Не нарушая иного рода расположения пункта 1 статьи 7, пункта 6 статьи 11 и других положений противоположного характера, содержащихся
 в настоящей Конвенции, водитель транспортного средства должен в той мере, в какой позволяют обстоятельства, находиться ближе к краю
 проезжей части, соответствующему направлению движения. Однако Договаривающиеся стороны или их территориальные подразделения могут
 регламентировать более конкретно правила расположения на проезжей части грузовых транспортных средств.</p><br>

<p style="text-indent:2em;">4. На дороге с двумя или с тремя проезжими частями водитель транспортного средства не должен выезжать на проезжую часть, противоположную
 стороне, соответствующей направлению движения.</p><br>

<p style="text-indent:2em;">5. а) На дороге с двусторонним движением, имеющей по крайней мере четыре полосы движения, водитель транспортного средства не должен выезжать
 на полосы, по которым совершается движение в противоположном направлении.</p><br>

<p style="text-indent:2em;">b) На дороге с двустороннем движением, имеющей три полосы движения, водитель транспортного средства не должен выезжать на крайнюю полосу
 проезжей части, по которой совершается движение в противоположном направлении.</p><br>

<p style="font-weight:500;">Статья 11</p><br>

<p>Обгон и движение в рядах</p><br>

<p style="text-indent:2em;">1. а) Обгон должен производиться со стороны, противоположной стороне
 соответствующей направлению движения.</p><br>

<p style="text-indent:2em;">b) Однако обгон должен производиться ............... подал сигнал о своем намерении повернуть в сторону, противоположную стороне,
 соответствующей направлению движения, и направил свое транспортное средство или своих животных в эту сторону, с тем чтобы либо
 повернуть на другую дорогу или въехать в придорожное владение, либо остановиться на этой стороне.</p>

<p style="text-indent:2em;"></p><h6>2. Перед обгоном водитель должен, не нарушая положений пункта 1 статьи 7 и положений статьи 14 настоящей Конвенции, убедиться в
 том, что:</h6><p></p>

<p>а) ни один следующий позади водитель не начал обгона;</p><br>

<p style="text-indent:2em;">b) водитель транспортного средства, движущегося впереди него по той же полосе движения, не подал сигнала о своем намерении совершить
 обгон другого транспортного средства;</p><br>

<p style="text-indent:2em;">с) полоса движения, на которую он намерен выехать, свободна на расстоянии, достаточном для того, чтобы, учитывая разницу между
 скоростью его транспортного средства во время обгона и скоростью обгоняемых пользователей дороги, его маневр не поставил под
 угрозу или не затруднил движение встречных транспортных средств; и</p><br>

<p style="text-indent:2em;">d) за исключением случаев выезда на полосу, встречное движение по которой запрещено, он может без ущерба для обгоняемого 
пользователя или пользователей дороги вновь занять место, предписанное пунктом 
3 статьи 10 настоящей Конвенции.</p><br>

<p style="text-indent:2em;">3. Во исполнение положений пункта 3 настоящей статьи запрещается, в частности на дорогах с двусторонним движением, совершать 
обгон при приближении к концу подъема и, когда видимость недостаточна, на поворотах, за исключением случаев, когда в этих
 местах имеется продольная разметка и когда обгон совершается без выезда на полосы, 
 предназначенные для встречного движения.</p><br>

<p style="text-indent:2em;">4. В момент обгона водитель должен оставлять для обгоняемого пользователя или пользователей дороги достаточное боковое
 пространство.</p><br>

<p style="text-indent:2em;">5. а) На дорогах, имеющих по крайней мере две полосы, предназначенные для движения в данном направлении, водитель транспортного
 средства, который вынужден был бы совершить новый обгон сразу или вскоре после того, как он вернется в свой ряд, предписанный
 пунктом 3 статьи 10 настоящей Конвенции, может для совершения этого маневра остаться на полосе движения, занятой им для первого
 обгона, предварительно убедившись, что он может это сделать, не создавая серьезных затруднений для водителей транспортных средств
 , следующих за ним на большой скорости.</p><br>

<p style="text-indent:2em;">b) Однако Договаривающиеся стороны или их территориальное подразделение могут не применять положений настоящего пункта к водителям
 велосипедов, велосипедов с подвесным двигателем, мотоциклов и транспортных средств, не считающихся автомобилями согласно настоящей
 Конвенции, а также к водителям автомобилей, разрешенный максимальный вес которых превышает 3500 кг (7700 фунтов) или конструктивная
 скорость которых не превышает 40 км/час (25 миль/час).</p>

<p style="text-indent:2em;"></p><h6>6. Когда применяются положения подпункта а пункта 5 настоящей статьи и интенсивность движения такова, что транспортные средства не
 только занимают всю ширину проезжей части, предназначенной исключительно для движения в данном направлении, но к тому же двигаются
 со скоростью, зависящей от скорости транспортного средства, следующего 
 впереди них по данному ряду, то:</h6><p></p>

<p style="text-indent:2em;">а) без ущерба для положений пункта 9 настоящей статьи тот факт, что транспортные средства одного ряда двигаются с большей скоростью,
 чем транспортные средства другого ряда, не рассматривается как обгон в 
 соответствии с настоящей статьей;</p><br>

<p style="text-indent:2em;">b) водитель транспортного средства, которое не находится на полосе движения, наиболее близкой к краю проезжей части, соответствующему
 направлению движения, может выезжать из ряда только с целью сделать поворот направо или налево или выехать на стоянку; однако это
 требование не применяется, если водитель выезжает из одного ряда в другой согласно положениям национального законодательства при
 выполнении предписаний подпункта "b" пункта 5 настоящей статьи.</p><br>

<p style="text-indent:2em;">7. Если полосы разграничены на проезжей части продольной разметкой, водителям запрещается при движении в рядах, описанном в пунктах
 5 и 6 настоящей статьи, наезжать на эту разметку.</p>

<p style="text-indent:2em;"></p><h6>8. Без ущерба для положений пункта 2 настоящей статьи и других ограничений, которые Договаривающиеся стороны или их территориальные
 подразделения могут предписать в отношении обгона на перекрестках и железнодорожных переездах, ни один водитель транспортного средства
 не должен обгонять другое транспортное средство, иное, чем двухколесный велосипед, двухколесный велосипед с подвесным двигателем или
 двухколесный мотоцикл без коляски:</h6><p></p>

<p style="text-indent:2em;"></p><h6>а) непосредственно перед перекрестком и на перекрестке, ином, 
чем перекресток с круговым движением, за исключением:</h6><p></p>

<p>i) случая, предусмотренного в подпункте b пункта 1 настоящей статьи;</p><br>

<p style="text-indent:2em;">ii) случая, когда обгон совершается по дороге, при движении по которой на перекрестке предоставляется преимущественное право
 проезда;</p><br>

<p>iii) случая, когда движение на перекрестке регулируется регулировщиком или световыми
 дорожными сигналами;</p><br>

<p style="text-indent:2em;">b) непосредственно перед железнодорожными переездами без шлагбаумов или полушлагбаумов и на этих переездах, учитывая, однако, что
 Договаривающиеся стороны или их территориальные подразделения могут разрешать такой обгон на железнодорожных переездах, где дорожное
 движение регулируется световыми дорожными сигналами, имеющими огонь, разрешающий транспортным средствам проезд.</p><br>

<p style="text-indent:2em;">9. Транспортное средство может обгонять другое транспортное средство, которое приближается к пешеходному переходу, обозначенному либо 
разметкой на проезжей части дороги, либо знаком или сигналом, или которое остановилось непосредственно перед этим переходом, только на
 достаточно сниженной скорости, обеспечивающей своевременную остановку в том случае, если на переходе окажутся пешеходы. Ни одно положение
 настоящего пункта не должно толковаться как препятствующее Договаривающимся сторонам или их территориальным подразделениям запрещать обгон,
 начиная с определенного расстояния до пешеходного перехода, или предъявлять более строгие требования к водителям транспортных средств
 , намеревающимся совершать обгон транспортного средства, остановившегося у пешеходного перехода.</p><br>

<p style="text-indent:2em;">10. Водитель, который убедится, что следующий за ним водитель намерен совершить обгон, должен, за исключением случая, предусмотренного в
 подпункте b пункта 1 статьи 16 настоящей Конвенции, приблизиться к краю проезжей части дороги, соответствующему направлению движения, и
 не повышать скорость. Если недостаточная ширина, профиль или состояние проезжей части дороги не позволяют с учетом интенсивности встречного
 движениялегко и безопасно обгонять тихоходное, крупногабаритное или вынужденное соблюдать определенную скорость транспортное средство,
 водитель последнего должен замедлить движение и в случае необходимости при первой возможности уступить дорогу, для того чтобы пропустить
 следующее за ним транспортные средства.</p>

<p style="text-indent:2em;"></p><h6>11. а) Договаривающиеся стороны или их территориальные подразделения могут на дорогах с односторонним движением и на дорогах с двусторонним
 движением в том случае, если по крайней мере две полосы движения в населенных пунктах и три полосы движения вне населенных пунктов
 предназначены для движения в одном и том же направлении и обозначены продольной разметкой:</h6><p></p>

<p style="text-indent:2em;">i) разрешать транспортным средствам, двигающимся по одной полосе движения обгонять со стороны, соответствующей направлению движения,
 транспортные средства, движущиеся по другой полосе движения;</p><br>

<p>ii) не применять положений пункта 3 статьи 10 настоящей Конвенции;</p><br>

<p>при наличии соответствующих предписаний, ограничивающих возможность выезда на другую полосу движения;</p><br>

<p style="text-indent:2em;">b) в случае, предусмотренном выше, в подпункте а, и без ущерба для положений пункта 9 настоящей статьи, такое опережение не считается
 обгоном по смыслу настоящей Конвенции.</p><br>

<p style="font-weight:500;">Статья 12</p><br>

<p style="text-indent:2em;">Встречный разъезд 1. При встречном разъезде водитель транспортного средства должен оставить сбоку достаточное свободное пространство
 и в случае необходимости держаться ближе к краю проезжей части дороги, соответствующему 
 направлению движения;</p><br>

<p style="text-indent:2em;">если при этом его движение будет затруднено ввиду наличия препятствия или других пользователей дороги, он должен замедлить движение и
 в случае необходимости остановиться, чтобы пропустить встречное транспортное средство или 
 транспортные средства.</p><br>

<p style="text-indent:2em;">2. На горных дорогах и на дорогах с большим уклоном, имеющих аналогичные характеристики, где разъезд невозможен или затруднен, водитель
 движущегося под уклон транспортного средства обязан остановиться и уступить дорогу любому движущемуся на подъем транспортному средству,
 за исключением тех случаев, когда места для разъезда располагаются вдоль проезжей части таким образом, что движущееся на подъем транспортное
 средство могло бы, учитывая скорость и положение транспортных средств, остановиться на имеющемся впереди него месте для разъезда, в связи с
 чем отпала бы необходимость для одного из транспортных средств дать задний ход. В случае если одно из двух встречных транспортных средств
 должно с целью разъезда дать задний ход, это обязан сделать водитель движущегося под уклон транспортного средства, за исключением случаев,
 когда совершенно очевидно, что такой маневр значительно легче выполнить водителю движущегося на подъем транспортного средства. Однако
 Договаривающиеся стороны или их территориальные подразделения могут предписать в отношении некоторых транспортных средств или некоторых
 дорог или участков дорог специальные правила, отличающиеся от правил настоящего пункта.</p><br>

<p style="font-weight:500;">Статья 13</p><br>

<p>Скорость и дистанция</p><br>

<p style="text-indent:2em;">1. Водитель транспортного средства должен при любых обстоятельствах сохранять контроль над своим транспортным средством, с тем чтобы соблюдать
 необходимую осторожность и быть всегда в состоянии осуществлять любые маневры, которые ему надлежит выполнить. Он должен при изменении
 скорости движения транспортного средства постоянно учитывать обстоятельства, в частности рельеф местности, состояние дороги и транспортного
 средства, его нагрузку, атмосферные условия и интенсивность движения, чтобы быть в состоянии остановить транспортное средство в конкретных
 условиях видимости в направлении движения, а также перед любым препятствием, которое водитель в состоянии предвидеть. Он должен снижать скорость
 и в случае необходимости останавливаться всякий раз, когда того требуют обстоятельства, 
 особенно когда видимость неудовлетворительна.</p><br>

<p style="text-indent:2em;">2. Водитель не должен без особого на то основания затруднять нормальное движение других транспортных средств, двигаясь на слишком малой
 скорости.</p><br>

<p style="text-indent:2em;">3. Водитель транспортного средства, следующего за другим транспортным средством, должен соблюдать соответствующую дистанцию, с тем чтобы
 избежать столкновения в случае неожиданного торможения или остановки движущегося впереди 
 транспортного средства.</p>

<p style="text-indent:2em;"></p><h6>4. Вне населенных пунктов с целью облегчения обгона водители транспортных средств или составов транспортных средств, разрешенный
 максимальный вес которых превышает 3500 кг (7700 фунтов) или габаритная длина которых превышает 10 м (33 фута), должны, за исключением
 момента обгона или начала обгона, поддерживать между своими транспортными средствами и движущимися впереди них механическими транспортными
 средствами такую дистанцию, чтобы обгоняющие транспортные средства могли в полной безопасности вновь занять соответствующее место в ряду
 впереди транспортного средства, которое они обогнали. Однако это положение не относится ни к очень интенсивному движению, ни к случаям
 запрещения обгона. Кроме того:</h6><p></p>

<p style="text-indent:2em;">а) компетентные органы могут предусмотреть для некоторых составов транспортных средств отступления от этого положения или не применять
 его также на дорогах, на которых две полосы предназначены для движения в данном направлении;</p><br>

<p style="text-indent:2em;">b) Договаривающиеся стороны или их территориальные подразделения могут устанавливать для характеристик транспортных средств величины,
 которые отличаются от указанных в настоящем пункте и начиная с которых применяется это положение.</p><br>

5. Ни одно положение настоящей Конвенции не должно толковаться как препятствующее Договаривающимся сторонам или их территориальным
 подразделениям предписывать общие или местные ограничения скорости для всех транспортных средств, либо для некоторых категорий транспортных
 средств или предписывать на некоторых дорогах или на некоторых категориях дорог либо минимальную и максимальную скорость, либо только 
 максимальную или минимальную скорость, или предписывать минимальные дистанции, обоснованные наличием на дороге некоторых категорий
 транспортных средств, представляющих опасность, в частности вследствие их веса или нагрузки.<p></p><br>

<p style="font-weight:500;">Статья 14</p><br>

<p>Общие предписания, касающиеся маневров</p><br>

<p style="text-indent:2em;">1. Водитель, который намерен выполнить какой-либо маневр, например выехать из ряда транспортных средств, находящихся на стоянке, или
 въехать в него, принять вправо или влево на проезжей части дороги, выполнить поворот налево или направо для выезда на другую дорогу
 или для въезда в придорожное владение, должен начинать этот маневр только после того, как он убедится, что может это сделать, не 
 подвергая опасности тех пользователей дороги, которые следуют позади него, впереди или навстречу, и с учетом их положения,
 направления движения и скорости.</p><br>

<p style="text-indent:2em;">2. Водитель, который намерен выполнить разворот или дать задний ход, должен убедиться в том, что он может выполнить этот маневр,
 не подвергая опасности других пользователей дороги и не мешая им.</p><br>

<p style="text-indent:2em;">3. Перед тем как повернуть или выполнить маневр, связанный с движением в сторону, водитель должен ясно и заблаговременно предупредить
 о своем намерении посредством включения указателя или указателей поворота своего транспортного средства или, при отсутствии таковых,
 если возможно, посредством соответствующего сигнала рукой. Сигнал, подаваемый при помощи указателя или указателей поворота, должен
 подаваться в течение всего времени выполнения маневра и прекращаться немедленно после его завершения.</p><br>

<p style="font-weight:500;">Статья 15</p><br>

<p style="text-indent:2em;">Особые предписания, касающиеся транспортных средств, осуществляющих перевозки на регулярных 
линиях городского транспорта</p><br>

<p style="text-indent:2em;">Рекомендуется предусмотреть в национальном законодательстве, что в населенных пунктах для облегчения движения транспортных средств,
 осуществляющих перевозки на регулярных линиях городского транспорта, водители других транспортных средств, при условии соблюдения
 предписаний пункта 1 статьи 17 настоящей Конвенции, должны замедлить движение и в случае необходимости остановиться, чтобы позволить
 этим транспортным средствам общего пользования выполнить маневр, необходимый для выезда с соответствующим образом обозначенной 
 остановки. Такие положения, предписанные Договаривающимися сторонами или их территориальными подразделениями, ни в коей мере не
 освобождают водителей транспортных средств общего пользования от обязанности приниматьпосле подачи при помощи указателей поворота
 сигнала о намерении возобновить движениемеры предосторожности, необходимые для того, чтобы избежать дорожно-транспортного
 происшествия.</p><br>

<p style="font-weight:500;">Статья 16</p><br>

<p>Изменение направления движения</p>

<p style="text-indent:2em;"></p><h6>1. Перед поворотом направо или налево для выезда на другую дорогу или для въезда в придорожное владение водитель должен, не нарушая
 положений пункта 1 статьи 7 и статьи 14 настоящей Конвенции:</h6><p></p>

<p style="text-indent:2em;">а) если он намерен выехать с дороги со стороны, соответствующей направлению движения,приблизиться, насколько это возможно, к краю
 проезжей части дороги, соответствующему этому направлению, и затем выполнить возможно более 
 короткий маневр;</p><br>

<p style="text-indent:2em;">b) если он намерен выехать с дороги с другой стороны, при условии что Договаривающиеся стороны или их территориальные подразделения
 будут иметь возможность предписать иные положения для велосипедов и велосипедов с подвесным двигателем,приблизиться, насколько это
 возможно, у оси проезжей части (на дорогах с двусторонним движением) или к краю, противоположному стороне, соответствующей направлению
 движения (на дорогах с односторонним движением), а если он намерен выехать на другую дорогу с двусторонним движением,совершить поворот
 таким образом, чтобы выехать на проезжую часть этой другой дороги по стороне, 
 соответствующей направлению движения.</p><br>

<p style="text-indent:2em;">2. При выполнении маневра, связанного с изменением направления движения, водитель должен, не нарушая положений статьи 21 настоящей
 Конвенции в отношении пешеходов, пропустить транспортные средства, движущиеся в противоположном направлении по проезжей части, с
 которой он собирается выехать, а также велосипеды и велосипеды с подвесным двигателем, движущиеся по велосипедным дорожкам,
 пересекающим проезжую часть, на которую он выезжает.</p><br>

<p style="font-weight:500;">Статья 17</p><br>

<p>Замедление движения</p><br>

<p style="text-indent:2em;">1. Водитель транспортного средства не должен резко тормозить, если это не требуется в 
интересах безопасности.</p><br>

<p style="text-indent:2em;">2. Водитель, который намерен заметно снизить скорость, если только это снижение не вызвано непосредственной опасностью, должен
 предварительно убедиться, что он может это сделать, не создавая опасности для других пользователей дороги и не затрудняя излишним
 образом их движение. Кроме того, водитель должен соответствующим знаком рукой ясно и заблаговременно указать о своем намерении
 замедлить движение, за исключением тех случаев, когда он убедился, что позади него нет транспортных средств или что они находятся
 на очень большом расстоянии; однако это положение не применяется, если сигнал о снижении скорости подается посредством имеющихся
 на транспортном средстве стоп-сигналов, упомянутых в пункте 31 приложения 5 к настоящей Конвенции.</p><br>

<p style="font-weight:500;">Статья 18</p><br>

<p>Перекрестки и обязанность уступить дорогу</p><br>

<p style="text-indent:2em;">1. При приближении к перекрестку водитель должен проявлять особую осторожность в зависимости от местных условий. Водитель должен
, в частности, вести транспортное средство с такой скоростью, чтобы иметь возможность остановиться и пропустить транспортные
 средства, имеющие преимущественное право проезда.</p><br>

<p style="text-indent:2em;">2. Водитель, выезжающий с проселочной или грунтовой дороги на дорогу, не являющуюся проселочной или грунтовой, обязан уступить
 дорогу транспортным средствам, движущимся по этой дороге. Для целей настоящей статьи смысл терминов "проселочная дорога" и "
 грунтовая дорога" может быть определен в национальном законодательстве.</p><br>

<p style="text-indent:2em;">3. Водитель, выезжающий на дорогу из придорожного владения, обязан уступить дорогу транспортным средствам, движущимся по этой
 дороге.</p>

<p style="text-indent:2em;"></p><h6>4. При условии соблюдения положений пункта 7 настоящей статьи:</h6><p></p>

<p style="text-indent:2em;">а) в государствах с правостороннем движением на перекрестках, иных, чем упомянутые в пункте 2 настоящей статьи и в пунктах 2 и
 4 статьи 25 настоящей Конвенции, водитель транспортного средства обязан уступить дорогу транспортным средствам, движущимся 
 справа от него;</p><br>

<p style="text-indent:2em;">b) Договаривающиеся стороны или их подразделения, на территории которых движение является левосторонним, вправе определять по 
своему усмотрению правила очередности проезда через перекрестки.</p><br>

<p style="text-indent:2em;">5. Даже если световые сигналы разрешают проезд, водитель не должен выезжать на перекресток, если образовался такой затор, что
 он, вероятно, будет вынужден остановиться на перекрестке, создавая тем самым помехи или препятствия для движения в поперечном
 направлении.</p><br>

<p style="text-indent:2em;">6. Водитель, въехавший на перекресток, где движение регулируется световыми дорожными сигналами, может выехать с перекрестка,
 не ожидая, когда будет открыто движение в нужном ему направлении, при условии, однако, что это не будет препятствовать движению
 других пользователей дороги в том направлении, в котором открыто движение.</p><br>

<p style="text-indent:2em;">7. На перекрестках водители нерельсовых транспортных средств
 обязаны уступать дорогу рельсовым транспортным средствам.</p><br>

<p style="font-weight:500;">Статья 19</p><br>

<p>Железнодорожные переезды</p>

<p style="text-indent:2em;"></p><h6>Каждый пользователь дороги должен проявлять особую осторожность при приближении к железнодорожному переезду и при проезде по
 нему. В частности:</h6><p></p>

<p>а) водитель транспортного средства должен двигаться с умеренной скоростью;</p><br>

<p style="text-indent:2em;">b) с учетом указаний светового сигнала или звукового сигнала, предписывающего остановку, ни один пользователь дороги не должен
 въезжать на железнодорожный переезд, шлагбаумы или полушлагбаумы которого закрыты или закрываются или шлагбаумы которого
 начинают подниматься;</p><br>

<p style="text-indent:2em;">с) если железнодорожный переезд не оборудован шлагбаумами, полушлагбаумами или световыми сигналами, ни один пользователь
 дороги не должен проезжать по нему, не убедившись в том, что к переезду не 
 приближается никакое транспортное средство;</p><br>

<p style="text-indent:2em;">d) пользователь дороги должен переезжать по железнодорожному переезду не задерживаясь; при вынужденной остановке транспортного
 средства водитель должен постараться вывести его с железнодорожного полотна, а если он не может этого сделать, то немедленно
 принять все зависящие от него меры к заблаговременному предупреждению об опасности машинистов рельсовых транспортных
 средств.</p><br>

<p style="font-weight:500;">Статья 20</p><br>

<p>Предписания, касающиеся пешеходов</p><br>

<p style="text-indent:2em;">1. Договаривающиеся стороны или их территориальные подразделения могут предписать, что положения настоящей статьи применяются
 лишь в тех случаях, когда движение пешеходов по проезжей части дороги затрудняло бы или ставило под угрозу движение
 транспортных средств.</p>

<p style="text-indent:2em;"></p><h6>2. Если у края проезжей части дороги имеется тротуар или обочина, пригодные для движения пешеходов, последние могут
 пользоваться ими. Однако, принимая необходимые меры предосторожности:</h6><p></p>

<p style="text-indent:2em;">а) пешеходы, перевозящие или переносящие громоздкие предметы, могут идти по проезжей части, если их движение по тротуару
 или обочине может служить серьезной помехой для других пешеходов;</p><br>

<p style="text-indent:2em;">b) группы пешеходов, возглавляемые руководителями или представляющие собой 
шествие, могут идти по проезжей части.</p><br>

<p style="text-indent:2em;">3. При отсутствии тротуаров или обочин или в случае невозможности их использования пешеходы могут передвигаться по проезжей
 части; если имеется велосипедная дорожка и если интенсивность движения позволяет это, они могут двигаться по этой велосипедной
 дорожке, не затрудняя, однако, движения водителей велосипедов и велосипедов с подвесным двигателем.</p><br>

<p style="text-indent:2em;">4. Когда пешеходы движутся по проезжей части дороги в соответствии с пунктами 2 и 3 настоящей статьи, они должны находиться
 возможно ближе к краю проезжей части.</p><br>

<p style="text-indent:2em;">5. Рекомендуется предусмотреть в национальном законодательстве, что пешеходы, движущиеся по проезжей части дороги, должны
 придерживаться (если это не угрожает их безопасности) стороны, противоположной направлению движения; однако лица, ведущие 
 велосипед, велосипед с подвесным двигателем или мотоцикл, должны всегда придерживаться стороны проезжей части дороги, 
 соответствующей направлению движения; это положение относится также к группам пешеходов, возглавляемым руководителем или 
 представляющим собой шествие. За исключением шествий, пешеходы, движущиеся по проезжей части ночью или в условиях плохой 
 видимости, а также днем, если этого требует интенсивность движения транспортных средств, должны по возможности двигаться 
 цепочкой один за другим.</p><br>

<p style="text-indent:2em;">6. а) Пешеходы не должны пересекать проезжую часть дороги, не приняв меры предосторожности; они должны пользоваться 
пешеходными переходами, если таковые имеются поблизости.</p>

<p style="text-indent:2em;"></p><h6>b) При переходе по пешеходному переходу,
 обозначенному разметкой или соответствующим знаком или сигналом:</h6><p></p>

<p style="text-indent:2em;">i) если на пешеходном переходе имеются световые сигналы для пешеходов, последние должны следовать указаниям этих 
сигналов;</p><br>

<p style="text-indent:2em;">ii) если на пешеходном переходе не имеется таких знаков или сигналов, но движение транспортных средств регулируется 
световыми дорожными сигналами или регулировщиком, пешеходы не должны выходить на проезжую часть, пока световой сигнал 
или жест регулировщика разрешает движение транспортных средств;</p><br>

<p style="text-indent:2em;">iii) на иных переходах пешеходы не должны выходить на проезжую часть дороги, не оценив расстояние до приближающихся 
транспортных средств и их скорость.</p><br>

<p style="text-indent:2em;">с) При переходе проезжей части вне пешеходного перехода, обозначенного разметкой или соответствующим знаком или сигналом, 
пешеходы не должны выходить на проезжую часть, не убедившись, что они могут сделать это, не затрудняя движение транспортных 
средств.</p><br>

<p style="text-indent:2em;">d) Выйдя на проезжую часть дороги, пешеходы не должны удлинять свой переход, задерживаться или останавливаться на ней без 
необходимости.</p><br>

<p style="text-indent:2em;">7. Однако Договаривающиеся стороны или их территориальные подразделения могут предписать более строгие правила для 
пешеходов, переходящих проезжую часть.</p><br>

<p style="font-weight:500;">Статья 21</p><br>

<p>Правила, касающиеся поведения водителей по отношению к пешеходам</p>

<p style="text-indent:2em;"></p><h6>1. Без ущерба для положений пункта 1 статьи 7, пункта 9 статьи 11 и пункта 1 статьи 13 настоящей Конвенции на пешеходных 
переходах, обозначенных разметкой или соответствующим знаком или сигналом, должны 
выполняться следующие предписания:</h6><p></p>

<p style="text-indent:2em;">а) если движение транспортных средств на таком пешеходном переходе регулируется световыми дорожными сигналами или 
регулировщиком, водители должны при наличии сигнала, запрещающего движение, остановиться перед переходом, а когда они 
получают разрешение продолжать движение, не мешать пешеходам, которые уже вступили на проезжую часть и переходят дорогу 
в условиях, предусмотренных статьей 20 настоящей Конвенции; если водитель делает поворот для выезда на другую дорогу, 
при въезде на которую находится пешеходный переход, он должен делать это только на небольшой скорости и пропуская пешеходов 
(при необходимости остановившись), вступивших или вступающих на переход в условиях, предусмотренных пунктом 6 статьи 20 
настоящей Конвенции;</p><br>

<p style="text-indent:2em;">b) если движение транспортных средств на этом переходе не регулируется ни световыми дорожными сигналами, ни регулировщиком, 
водители должны при приближении к этому переходу надлежащим образом снизить скорость, чтобы не подвергать опасности пешеходов, 
вступивших или вступающих на переход; в случае необходимости надлежит остановиться 
и пропустить пешеходов.</p><br>

<p style="text-indent:2em;">2. Водители, намеревающиеся проехать со стороны, соответствующей направлению движения, мимо транспортного средства общего 
пользования, находящегося на надлежащим образом обозначенной остановке, должны снизить скорость и в случае необходимости 
остановиться, с тем чтобы обеспечить пассажирам общественного транспорта 
возможность посадки или высадки.</p><br>

<p style="text-indent:2em;">3. Ни одно положение настоящей статьи не должно толковаться как препятствующее Договаривающимся сторонам или их 
территориальным подразделениям: предписывать водителям транспортных 
средств останавливаться во всех случаях,</p><br>

<p style="text-indent:2em;">когда пешеходы вступают или вступили на пешеходный переход, обозначенный разметкой или знаками и сигналами, 
в условиях, предусмотренных статьей 20 настоящей Конвенции, или запрещать водителям мешать движению пешеходов, 
которые уже вступили на проезжую часть на перекрестке или вблизи него, даже если в этом месте не имеется пешеходных 
переходов, обозначенных разметкой или знаками и сигналами.</p><br>

<p style="font-weight:500;">Статья 22</p><br>

<p>Островки безопасности</p>

<p style="text-indent:2em;"></p><h6>Не нарушая положений статьи 10 настоящей Конвенции, водитель может объезжать справа или слева островки безопасности, 
тумбы и другие приспособления, установленные на проезжей части6 по которой он движется, за исключением случаев, 
когда:</h6><p></p>

<p style="text-indent:2em;">а) сигнальный знак предписывает, с какой стороны следует объезжать островок безопасности, тумбу или 
приспособление;</p><br>

<p style="text-indent:2em;">b) островок безопасности, тумба или приспособление находятся на оси проезжей части дороги с двусторонним движением; в последнем случае водитель должен объезжать островок безопасности, 
тумбу или приспособление со стороны соответствующей направлению движения;</p><br>

<p style="font-weight:500;">Статья 23</p><br>

<p>Остановка и стоянка</p><br>

<p style="text-indent:2em;">1. Вне населенных пунктов останавливающиеся или находящиеся на стоянке транспортные средства и животные должны по 
мере возможности находиться вне проезжей части. Они не должны находиться ни на велосипедных дорожках, на (исключая случаи,
 разрешенные национальным законодательством) на тротуарах или обочинах, предназначенных для пешеходов.</p>

<p style="text-indent:2em;"></p><h6>2. а) Останавливающиеся или находящиеся на стоянке на проезжей части животные и транспортные средства должны находиться 
как можно ближе к краю проезжей части. Водитель может останавливаться или поставить на стоянку транспортное средство на 
проезжей части только на стороне, соответствующей для него направлению движения; однако остановка или стоянка разрешается 
на другой стороне проезжей части, если ввиду наличия рельсовых путей невозможно остановиться на стороне, соответствующей 
направлению движения. Кроме того, Договаривающиеся стороны или их территориальные подразделения могут:</h6><p></p>

<p style="text-indent:2em;">i) при определенных условиях, например, когда имеются дорожные знаки, запрещающие остановку на стороне, соответствующей 
направлению движения, разрешать остановку или стоянку на другой стороне проезжей части дороги;</p><br>

<p style="text-indent:2em;">ii) на проезжей части дороги с односторонним движениемразрешать остановку и стоянку только на другой стороне или также 
одновременно на стороне, соответствующей направлению движения;</p><br>

<p>iii) разрешать остановку и стоянку посредине проезжей части в специально обозначенных местах.</p><br>

<p style="text-indent:2em;">b) При отсутствии в национальном законодательстве иных положений транспортные средства, исключая двухколесные 
велосипеды, двухколесные велосипеды с подвесным двигателем и двухколесные мотоциклы без коляски, не должны останавливаться 
или находиться на стоянке проезжей части в два ряда. Остановившиеся и находящиеся на стоянке транспортные средства должны 
располагаться параллельно краю проезжей части, за исключением тех мест, конфигурация которых допускает иное расположение 
транспортных средств.</p>

<p style="text-indent:2em;"></p><h6>3. а) Всякая остановка и стоянка транспортных средств
 запрещается на проезжей части дороги:</h6><p></p>

<p>i) на пешеходных переходах, переездах для велосипедистов и на железнодорожных переездах;</p><br>

<p style="text-indent:2em;">ii) на трамвайных и железнодорожных путях, проходящих по дороге или настолько близко от этих путей, что движение 
трамваев или поездов может быть затруднено, а также - при условии предоставления Договаривающимся сторонам или их 
территориальным подразделениям возможности предусмотреть противоположные положения- на тротуарах и велосипедных 
дорожках.</p>

<p style="text-indent:2em;"></p><h6>b) Всякая остановка и стоянка транспортного средства запрещается в любом месте, где она может создать опасность, в 
частности:</h6><p></p>

<p>i) за исключением специально обозначенных мест- под эстакадами и в туннелях;</p><br>

<p style="text-indent:2em;">ii) на проезжей части дороги вблизи переломов и на поворотах, когда видимость недостаточна для безопасного объезда 
транспортного средства, с учетом скорости транспортных средств на этом участке дороги;</p><br>

<p style="text-indent:2em;">iii) на проезжей части дороги, где расстояние между продольной разметкой, запрещающей выезд за нее, и остановившимся 
транспортным средством составляет менее 3 м (10 футов), и если при этом не применяется положение подпункта b ii) 
настоящего пункта.</p>

<p style="text-indent:2em;"></p><h6>с) Всякая стоянка транспортного средства на проезжей части дороги 
запрещается:</h6><p></p>

<p style="text-indent:2em;">i) вблизи железнодорожных переездов, перекрестков и остановок автобуса, троллейбуса или рельсовых транспортных средств 
на расстояниях, установленных национальным законодательством;</p><br>

<p>ii) напротив выезда из ворот;</p><br>

<p style="text-indent:2em;">iii) в любом месте, где находящееся на стоянке транспортное средство мешало бы подъезду другого транспортного средства 
к месту своей обычной стоянки или выезду оттуда;</p><br>

<p style="text-indent:2em;">iv) на центральной полосе проезжей части дороги, состоящей из трех полос движения, и - вне населенных пунктов- 
на проезжей части дорог, обозначенных соответствующими знаками или сигналами как главная дорога;</p><br>

<p style="text-indent:2em;">v) в местах, где находящееся на стоянке транспортное средство закрывало бы от пользователей дороги дорожные знаки 
или световые дорожные сигналы.</p><br>

<p style="text-indent:2em;">4. Водитель не должен оставлять свое транспортное средство, а погонщик своих животных, не приняв все необходимые 
меры предосторожности во избежание дорожно-транспортного происшествия, а если речь идет об автомобиле- чтобы не 
допустить его использования без соответствующего разрешения.</p>

<p style="text-indent:2em;"></p><h6>5. Рекомендуется предусмотреть в национальном законодательстве, что каждое механическое транспортное средство, иное, 
чем двухколесный велосипед с подвесным двигателем или двухколесный мотоцикл без коляски, а также каждый сцепленный или 
несцепленный с тягачом прицеп, остановившиеся на проезжей части дороги вне населенного пункта, должны быть обозначены на 
некотором расстоянии с помощью по крайней мере одного приспособления, установленного в наиболее подходящем месте, для 
своевременного предупреждения приближающихся водителей:</h6><p></p>

<p style="text-indent:2em;">а) если транспортное средство остановилось ночью на проезжей части в таких условиях, что приближающиеся водители не 
могут заметить препятствие, каким является остановившееся транспортное средство;</p><br>

<p style="text-indent:2em;">b) если в других случаях водитель вынужден остановить свое транспортное средство в месте
, где остановка запрещена.</p><br>

<p style="text-indent:2em;">6. Ни одно положение настоящей статьи не должно толковаться как препятствующее Договаривающимся сторонам или их 
территориальным подразделениям предусматривать иные запрещения стоянки и остановки.</p><br>

<p style="font-weight:500;">Статья 24</p><br>

<p>Дверцы транспортных средств</p><br>

<p style="text-indent:2em;">Запрещается открывать дверцы транспортного средства, оставлять их открытыми или выходить из транспортного средства, 
не убедившись в том, что это не повлечет за собой опасности для других пользователей дороги.</p><br>

<p style="font-weight:500;">С т а т ь я 25</p><br>

<p>Автомагистрали и дороги подобного рода</p>

<p style="text-indent:2em;"></p><h6>1. На автомагистралях и , если это предусматривается национальным законодательством, на специальных выездах на 
автомагистрали и съездах с них :</h6><p></p>

<p style="text-indent:2em;">а) запрещается движение пешеходов, животных, велосипедов, велосипедов с подвесным двигателем, если они не приравнены 
к мотоциклам, и всех транспортных средств, иных, чем автомобили и их прицепы, а также автомобилей и их прицепов, 
конструктивная скорость которых не может достигать на ровной дороге определенной величины, установленной 
национальным законодательством;</p>

<p style="text-indent:2em;"></p><h6>b) Водителям запрещается :</h6><p></p>

<p style="text-indent:2em;">i) останавливать транспортное средство или ставить его на стоянку в иных местах, чем специально обозначенные 
для этого места стоянки. Водитель, вынужденный по не зависящим от него обстоятельствам остановиться, должен 
постараться вывести транспортное средство с проезжей части, а также с полосы вынужденной остановки или, если 
он не может этого сделать, немедленно обозначить транспортное средство на достаточном расстоянии, с тем чтобы 
своевременно предупредить приближающихся водителей;</p><br>

<p style="text-indent:2em;">ii) разворачиваться или двигаться задним ходом или въезжать на центральную разделительную полосу и на поперечные 
соединения обеих проезжих частей дороги.</p>

<p style="text-indent:2em;"></p><h6>2. При въезде на автомагистраль водители должны:</h6><p></p>

<p style="text-indent:2em;">а) при отсутствии полосы ускорения движения, являющейся продолжением подъездной дороги, пропускать движущиеся 
по автомагистрали транспортные средства;</p><br>

<p style="text-indent:2em;">b) при наличии полосы ускорения - выезжать на нее и включаться в движение по автомагистрали в соответствии с 
предписаниями пунктов 1 и 3 статьи 14 настоящей Конвенции.</p><br>

<p style="text-indent:2em;">3. При выезде с автомагистрали водитель должен своевременно въехать на полосу, прилегающую к съезду с 
автомагистрали, и по возможности быстрее въехать на полосу замедления движения, если такая имеется.</p><br>

<p style="text-indent:2em;">4. При применении положений пунктов 1, 2 и 3 настоящей статьи к автомагистралям приравниваются другие 
предназначенные для автомобильного движения и обозначенные как таковые дороги, не обслуживающие 
придорожных владений.</p><br>

<p style="font-weight:500;">С т а т ь я 26</p><br>

<p>Особые правила, касающиеся шествий и инвалидов</p><br>

<p style="text-indent:2em;">1. Пользователям дороги запрещается пересекать воинские колонны, группы школьников, идущих шеренгами во 
главе с руководителем, и другие шествия.</p><br>

<p style="text-indent:2em;">2. Инвалиды, передвигающиеся в колясках, приводимых в действие ими самими или имеющих скорость пешехода,
 могут пользоваться тротуарами и обочинами, пригодными для такого движения.</p><br>

<p style="font-weight:500;">С т а т ь я 27</p><br>

<p>Особые правила, касающиеся водителей велосипедов, велосипедов с подвесным двигателем и мотоциклов</p><br>

<p style="text-indent:2em;">1. Несмотря на положения пункта 3 статьи 10 настоящей Конвенции, Договаривающиеся стороны или их территориальные 
подразделения могут не запрещать водителям велосипедов передвигаться по несколько человек в ряд.</p><br>

<p style="text-indent:2em;">2. Водителям велосипедов запрещается передвигаться, не держась за руль по крайней мере одной рукой, 
использовать другие другие транспортные средства в качестве буксира или перевозить, буксировать или толкать 
предметы, которые могут помешать управлению или представляют опасность для других пользователей дороги. 
Аналогичные положения применяются к водителям велосипедов с подвесным двигателем и мотоциклов, но помимо 
этого, они должны держать руль обеими руками, за исключением случаев, когда необходимо подать сигнал о 
маневре, описанном в пункте 3 стать 14 настоящей Конвенции.</p><br>

<p style="text-indent:2em;">3. Водителям велосипедов и велосипедов с подвесным двигателем запрещается перевозить пассажиров; однако 
Договаривающиеся стороны или их территориальные подразделения могут разрешить отступления от этого положения 
и, в частности, разрешить перевозку пассажиров на дополнительном сиденье (сиденьях), оборудованном на велосипеде. 
Водителям мотоциклов разрешается перевозить пассажиров только в коляске (если такая имеется) и на дополнительном 
сиденье, которое может быть оборудовано позади водителя.</p><br>

<p style="text-indent:2em;">4. При наличии велосипедных дорожек Договаривающиеся стороны или их территориальные подразделения могут 
запретить водителям велосипедов передвигаться по проезжей части вне велосипедной дорожки. В этом случае они 
могут разрешить водителям велосипедов с подвесным двигателем передвигаться по велосипедной дорожке и, если они 
сочтут это необходимым, запретить им передвигаться по проезжей части вне велосипедной дорожки.</p><br>

<p style="font-weight:500;">С т а т ь я 28</p><br>

<p>Звуковые и световые сигналы</p>

<p style="text-indent:2em;"></p><h6>1. Звуковые и световые сигнальные приборы могут применяться только :</h6><p></p>

<p>а) для своевременного предупреждения во избежание дорожно-транспортного происшествия;</p><br>

<p style="text-indent:2em;">b) вне населенных пунктов, когда следует предупредить водителя другого транспортного средства о намерении 
произвести обгон. Звуковые сигналы не должны быть более продолжительными, чем это необходимо.</p><br>

<p style="text-indent:2em;">2. Вместо звуковых сигналов водители автомобилей могут с наступлением темноты и до рассвета подавать 
световые сигналы, предусмотренные в пункте 5 статьи 33 настоящей Конвенции. Они могут делать это также 
и днем в целях, указанных в подпункте "b" пункта 1 настоящей статьи, если это более соответствует 
обстоятельствам.</p><br>

<p style="text-indent:2em;">3. Договаривающиеся стороны или их территориальные подразделения могут разрешить подачу световых сигналов 
в целях, указанных в подпункте "b" пункта 1 настоящей статьи, также и в населенных пунктах.</p><br>

<p style="font-weight:500;">С т а т ь я 29</p><br>

<p>Рельсовые транспортные средства</p><br>

<p style="text-indent:2em;">1. Если рельсовый путь проходит по проезжей части дороги, каждый пользователь дороги должен при приближении 
трамвая или другого рельсового транспортного средства возможно быстрее освободить путь, чтобы пропустить 
рельсовое транспортное средство.</p><br>

<p style="text-indent:2em;">2. В отношении движения рельсовых транспортных средств по автомобильным дорогам, встречного разъезда с этими 
транспортными средствами или их обгона Договаривающиеся стороны или их территориальные подразделения могут 
принимать специальные правила, отличающиеся от предусмотренных настоящей главой. Однако Договаривающиеся 
стороны или их территориальные подразделения не могут принимать положений, противоречащих положениям пункта 
7 статьи 18 настоящей Конвенции.</p><br>

<p style="font-weight:500;">С т а т ь я 30</p><br>

<p>Груз транспортных средств</p><br>

<p style="text-indent:2em;">1. Если для транспортного средства установлен разрешенный максимальный вес, то вес транспортного средства 
с нагрузкой никогда не должен превышать разрешенный максимальный вес.</p>

<p style="text-indent:2em;"></p><h6>2. Любой груз должен быть размещен и в случае необходимости закреплен на транспортном средстве таким 
образом, чтобы он :</h6><p></p>

<p style="text-indent:2em;">а) не подвергал опасности людей и не наносил ущерба государственному, общественному или личному имуществу, 
в частности, не волочился и не падал на дорогу;</p><br>

<p style="text-indent:2em;">b) не ограничивал видимости и обзорности, не нарушал устойчивости транспортного средства и не 
затруднял управление им;</p><br>

<p>c) не производил шума, не поднимал пыли и не вызывал других неудобств,которых можно избежать;</p><br>

<p style="text-indent:2em;">d) не закрывал огней, включая стоп-сигнал и указатели поворота, светоотражающих приспособлений, 
регистрационных номеров и отличительного знака государства регистрации, которые должны быть на транспортном 
средстве в соответствии с настоящей Конвенцией или с соответствии с национальным законодательством, или не 
закрывал сигналов, поднимаемых рукой в соответствии с положениями пункта 3 статьи 14 или пункта 2 статьи 17 
настоящей Конвенции.</p><br>

<p style="text-indent:2em;">3. Все вспомогательные приспособления, используемые для закрепления и обеспечения сохранности груза 
(веревки, цепи, брезент), должны быть туго затянуты и прочно закреплены. Все вспомогательные приспособления, 
используемые для обеспечения сохранности груза, должны удовлетворять требованиям, предусмотренным в отношении 
груза пунктом 2 настоящей статьи.</p>

<p style="text-indent:2em;"></p><h6>4. Груз, выступающий и спереди, сзади и сбоку за габарит транспортного средства, должен быть заметным образом 
обозначен во всех случаях, когда он может не быть замечен водителями других транспортных средств; ночью эта 
сигнализация осуществляется спереди с помощью белого огня и белого светоотражающего приспособления, а сзади с 
помощью красного огня или красного светоотражающего приспособления. В частности, на механических транспортных 
средствах:</h6><p></p>

<p style="text-indent:2em;">а) грузы, выступающие за пределы транспортного средства более чем на 1 м (3 фута 4 дюйма) сзади или спереди , 
должны быть всегда обозначены;</p><br>

<p style="text-indent:2em;">b) грузы, выступающие сбоку за габарит транспортного средства таким образом, что их крайняя точка по ширине 
находится на расстоянии более 0,40 м (16 дюймов) от внешнего края подфарника транспортного средства, должны 
обозначаться ночью спереди; аналогичным образом ночью должны обозначаться сзади грузы, выступающие за габарит 
транспортного средства таким образом, что их крайняя точка по ширине находится на расстоянии более 0,40 м 
(16 дюймов) от внешнего края заднего красного габаритного фонаря транспортного средства.</p><br>

<p style="text-indent:2em;">5. Ни одно положение пункта 4 настоящей статьи не должно толковаться как препятствующее Договаривающимся 
сторонам или их территориальным подразделениям запрещать, ограничивать или подчинять специальному разрешению 
перевозку выступающих за габарит транспортного средства грузов, упомянутых в указанном пункте 4.</p><br>

<p style="font-weight:500;">С т а т ь я 31</p><br>

<p>Поведение водителя в случае дорожно-транспортного происшествия</p>

<p style="text-indent:2em;"></p><h6>1. Не нарушая предписаний национального законодательства относительно обязанности оказывать помощь пострадавшим
, водитель или любой другой пользователь дороги, причастный к дорожно-транспортному 
происшествию, должен :</h6><p></p>

<p>а) по возможности быстрее остановиться, не создавая дополнительной опасности для движения;</p><br>

<p style="text-indent:2em;">b) принять меры для обеспечения безопасности движения в месте дорожно-транспортного происшествия и, если в
 результате дорожно-транспортного происшествия имеются тяжелораненые или убитые, избегать в той мере, в какой
 это не влияет на безопасность движения, изменять что-либо на месте происшествия или уничтожать следы, которые
 могут быть полезными для установления ответственности;</p><br>

<p>с) по требованию других лиц, причастных к дорожно-транспортному происшествию, назвать себя;</p><br>

<p style="text-indent:2em;">d) если в результате дорожно-транспортного происшествия имеются раненые или убитые, сообщить об этом в службу
 дорожного движения и, если последняя не разрешает покинуть место происшествия или если он не должен оказать помощь
 раненым или получить медицинскую помощь сам, возвратиться к месту происшествия или остаться на месте до прибытия
 сотрудников службы дорожного движения.</p><br>

<p style="text-indent:2em;">2. Договаривающиеся стороны или их территориальные подразделения могут в своем национальном законодательстве не
 предписывать положение, указанное в подпункте "d" пункта 1 настоящей статьи, в том случае если не было нанесено
 никакого серьезного телесного повреждения и если никто из лиц, попавших в дорожно-транспортное происшествие, не
 требует уведомить об этом полицию.</p><br>

<p style="font-weight:500;">С т а т ь я 32</p>

<p style="text-indent:2em;"></p><h6>Освещение :</h6><p></p>

<p>общие предписания</p><br>

<p style="text-indent:2em;">1. В соответствии с настоящей статьей термин "ночь" охватывает период времени между наступлением темноты и рассветом,
 в также другие моменты, когда видимость недостаточна, например, вследствие тумана, сильного дождя или проезда по
 туннелю.</p>

<p style="text-indent:2em;"></p><h6>2. Ночью :</h6><p></p>

<p style="text-indent:2em;">а) на любом находящемся на дороге механическом транспортном средстве, ином, чем велосипед с подвесным двигателем
 или двухколесный мотоцикл без коляски, должно быть включено спереди не менее двух белых или желтых селективных огней,
 а сзади - парное количество красных огней в соответствии с предписаниями, предусмотренными для автомобилей в пунктах
 23 и 24 приложения 5; однако национальное законодательство может разрешить использование автожелтых подфарников.
 Положения настоящего подпункта применяются к составам, включающим одно механическое транспортное средство и одно
 или несколько прицепов, причем в этом случае красные огни должны помещаться сзади последнего прицепа; на прицепах,
 к которым применимы предписания пункта 30 приложения 5 к настоящей Конвенции, должны быть включены спереди два белых
 огня, которые они должны иметь в соответствии с предписаниями указанного пункта 30;</p><br>

<p style="text-indent:2em;">b) любые находящиеся на дороге транспортные средства или составы транспортных средств, к которым не применяются
 положения подпункта "а" настоящего пункта, должны иметь по крайней мере один передний белый или желтый селективный
 огонь и по крайней мере один задний красный огонь; если имеется лишь один передний или один задний огонь, этот огонь
 должен помещаться по центру или на стороне транспортного средства, противоположной стороне, соответствующей направлению
 движения; что касается гужевых повозок и ручных тележек, то приспособление с указанными огнями может нести водитель или
 сопровождающее лицо, идущее с этой стороны транспортного средства.</p><br>

<p style="text-indent:2em;">3. Предусмотренные пунктом 2 настоящей статьи огни должны отчетливо обозначать транспортное средство для других пользователей
 дороги; одна и та же лампа или одно и то же приспособление может быть источником переднего и заднего огня лишь в том случае,
 если характеристики транспортного средства, в частности его небольшая длина, таковы, что в этих условиях указанное предписание
 может быть соблюдено.</p>

<p style="text-indent:2em;"></p><h6>4. а) В отступление от положений пункта 2 настоящей статьи:</h6><p></p>

<p style="text-indent:2em;">i) положения указанного пункта 2 не применяются к транспортным средствам, остановившимся или находящимся на стоянке на дороге,
 освещенной таким образом, что они ясно видны с достаточного расстояния;</p><br>

<p style="text-indent:2em;">ii) на механических транспортных средствах, длина и ширина которых не превышает соответственно 6 м (20 футов) и 2 м (6 футов и
 6 дюймов) и которые не буксируют какое-либо другое транспортное средство, может быть включен во время остановки или стоянки на
 дороге в населенном пункте лишь один огонь, находящийся на стороне транспортного средства, противоположной краю проезжей части,
 вдоль которого остановилось или находится на стоянке это транспортное средство; этот огонь должен быть белым или автожелтым спереди
 и красным или автожелтым сзади;</p><br>

<p style="text-indent:2em;">iii) положения подпункта "b" пункта 2 настоящей статьи не относятся ни к двухколесным велосипедам, ни к двухколесным
 велосипедам с подвесным двигателем, ни к двухколесным мотоциклам без коляски, не снабженным батареями, если они остановились
 или находятся на стоянке в населенном пункте у самого края проезжей части;</p>

<p style="text-indent:2em;"></p><h6>b) кроме того, национальное законодательство может допускать отступления от положений настоящей
 статьи в отношении:</h6><p></p>

<p style="text-indent:2em;">i) транспортных средств, остановившихся или находящихся на стоянке на специально предназначенных для этого местах вне
 проезжей части;</p><br>

<p style="text-indent:2em;">ii) транспортные средства, остановившихся или находящихся на
 стоянке на жилых улицах с очень слабым движением.</p><br>

<p style="text-indent:2em;">5. Ни в коем случае транспортное средство не должно иметь спереди красных огней, красных светоотражающих приспособлений
 или красных светоотражающих материалов, а сзади - белых или желтых селективных огней, белых или желтых селективных светоотражающих
 приспособлений или белых или желтых селективных светоотражающих материалов; это предписание не относится ни к использованию белых
 или желтых селективных огней заднего хода, ни к приданию на задних номерных знаках светоотражающих свойств цифрам или буквам светлого
 цвета, или отличительным знакам, или прочим отличительным знакам, которые могут требоваться согласно национальному законодательству,
 ни к приданию светоотражающих свойств светлому фону этих номерных или отличительных знаков, ни к красным вращающимся или проблесковым
 огням некоторых транспортных средств, имеющих преимущественное право проезда.</p>

<p style="text-indent:2em;"></p><h6>6. Договаривающиеся стороны или их территориальные подразделения могут - в той мере, в какой они считают возможным это делать без
 ущерба для безопасности движения, - допускать в своем национальном законодательстве исключения из положений настоящей статьи в 
 отношении:</h6><p></p>

<p>а) гужевых повозок и ручных тележек;</p><br>

<p style="text-indent:2em;">b) транспортных средств, имеющих особую форму или особый характер или используемых в особых целях и в особых условиях.</p><br>

<p style="text-indent:2em;">7. Ни одно положение настоящей Конвенции не должно толковаться как препятствующее предписать в национальном законодательстве, чтобы
 группы пешеходов, возглавляемые руководителем, или шествия, а также погонщики скота, стад, упряжных, вьючных или верховых животных,
 движущиеся по проезжей части в условиях, определенных подпунктом "b" пункта 2 настоящей статьи, имели светоотражающие приспособления
 или огонь; в этом случае отражаемый или излучаемый свет должен быть либо белым или желтым селективным спереди и красным сзади, либо
 автожелтым спереди и сзади.</p><br>

<p style="font-weight:500;">С т а т ь я 33</p><br>

<p>Освещение: условия использования огней, предусмотренных в приложении 5</p>

<p style="text-indent:2em;"></p><h6>1. Водитель транспортного средства, имеющего огни дальнего света, огни ближнего света и подфарники, определенные в приложении
 5 к настоящей Конвенции, должен пользоваться этими огнями в нижеследующих условиях, когда в соответствии со статьей 32 настоящей
 Конвенции транспортное средство должно быть обозначено спереди по меньшей мере одним или двумя белыми или желтыми селективными
 огнями:</h6><p></p>

<p style="text-indent:2em;">а) дальний свет не должен включаться ни в населенных пунктах, если дорога достаточно освещена, ни вне населенных пунктов, если проезжая
 часть равномерно освещена и если это освещение достаточно для обеспечения водителю хорошей видимости на достаточном расстоянии, ни
 на остановке;</p>

<p style="text-indent:2em;"></p><h6>b) с учетом возможности разрешать в национальном законодательстве использование огней дальнего света днем, когда видимость недостаточна,
 например ввиду тумана, снегопада, сильного дождя или проезда через туннель, дальний свет не должен включаться или должен направляться
 таким образом, чтобы не вызывать ослепления:</h6><p></p>

<p style="text-indent:2em;">i) при встречном разъезде, когда используются огни фар, они должны выключаться на достаточном расстоянии или быть отрегулированы таким
 образом, чтобы не вызывать ослепления и дать водителю встречного транспортного средства возможность спокойно и безопасно продолжать
 движение;</p><br>

<p style="text-indent:2em;">ii) если транспортное средство следует на большом расстоянии позади другого транспортного средства; однако дальний свет может быть использован
 в соответствии с положениями пункта 5 настоящей статьи для указания намерения произвести обгон в условиях, предусмотренных в статье 28
 настоящей Конвенции;</p><br>

<p style="text-indent:2em;">iii) в любых других условиях, когда следует избегать ослепления других пользователей дороги или пользователей водного или железнодорожного пути,
 проходящего вдоль дороги;</p><br>

<p style="text-indent:2em;">с) при условии соблюдения положений подпункта "d" настоящего пункта огни ближнего света должны быть выключены, когда использование огней 
дальнего света запрещено в соответствии с положениям приведенных выше подпунктов "а" и "b", и они могут быть использованы вместо огней 
дальнего света, когда огней ближнего света достаточно, чтобы дать возможность водителю ясно видеть на достаточном расстоянии, а другим 
пользователям дороги - видеть данное транспортное средство на достаточном расстоянии;</p><br>

<p style="text-indent:2em;">d) подфарники следует использовать вместе с огнями дальнего света, огнями ближнего света или противотуманными фарами. Их можно использовать
 отдельно, когда транспортное средство остановилось или находится на стоянке или когда на дорогах, кроме автомагистралей и дорог, указанных 
 в пункте 4 статьи 25 настоящей Конвенции, условия освещенности таковы, что водитель хорошо видит на достаточном расстоянии, а другие 
 пользователи дороги видят транспортное средство с достаточного расстояния.</p><br>

<p style="text-indent:2em;">2. Если на транспортном средстве имеются противотуманные фары, определенные в приложении 5 к настоящей Конвенции, ими можно пользоваться 
только во время тумана, снегопада или сильного дождя. В отступление от положений подпункта "с" пункта 1 настоящей статьи, при включении 
противотуманных фар фары ближнего света должны выключаться, однако национальное законодательство может разрешить в этом случае одновременное 
включение противотуманных фар и фар ближнего света.</p><br>

<p style="text-indent:2em;">3. В отступлении от положений пункта 2 настоящей статьи национальное законодательство может разрешить включение противотуманных фар даже 
при отсутствии тумана, снегопада или сильного дождя на узких дорогах с большим количеством поворотов.</p><br>

<p style="text-indent:2em;">4. Ни одно положение настоящей Конвенции не должно толковаться как препятствующее Договаривающимся сторонам вменять в обязанность 
использование огней ближнего света в населенных пунктах.</p><br>

<p style="text-indent:2em;">5. Упомянутые в пункте 2 статьи 28 настоящей Конвенции световые сигналы представляют собой многократное и кратковременное включение 
и выключение дальнего и ближнего света или кратковременное попеременное включение ближнего и дальнего света.</p><br>

<p style="font-weight:500;">С т а т ь я 34</p><br>

<p>Отступления</p><br>

<p style="text-indent:2em;">1. При приближении пользующегося преимущественным правом проезда транспортного средства, которое подает сигналы специальными 
световыми и звуковыми приборами, каждый пользователь дороги должен освободить для него место на проезжей части и в случае 
необходимости остановиться.</p><br>

<p style="text-indent:2em;">2. В национальном законодательстве может быть предусмотрено, что водители транспортных средств, имеющих преимущественное право 
проезда, подавшие сигналы о своем приближении специальными сигнальными приборами транспортного средства, и при условии, что не 
будут поставлены под угрозу другие пользователи дороги, могут не соблюдать все или некоторые положения настоящей главы II, 
иные чем положения пункта 2 статьи 6.</p><br>

<p style="text-indent:2em;">3. В национальном законодательстве может быть предусмотрено, в какой степени персонал, занятый на работах по строительству, 
ремонту или содержанию дорог, включая водителей механизмов, используемых на этих работах, может, при условии принятия 
необходимых мер предосторожности, не соблюдать во время работы положений настоящей главы II.</p><br>

<p style="text-indent:2em;">4. В целях обгона или встречного разъезда с механизмами, упомянутыми в пункте 3 настоящей статьи, когда они используются на 
дорожных работах, водители других транспортных средств могут, по мере необходимости и при условии соблюдения всех мер 
предосторожности, не соблюдать положений статей 11 и 12 настоящей Конвенции.</p>

<p></p><h4>Глава III</h4><p></p>

<p style="font-weight:500;">УСЛОВИЯ ДОПУЩЕНИЯ К МЕЖДУНАРОДНОМУ ДВИЖЕНИЮ АВТОМОБИЛЕЙ И ПРИЦЕПОВ</p><br>

<p style="font-weight:500;">С т а т ь я 35</p><br>

<p>Регистрация</p>

<p style="text-indent:2em;"></p><h6>1. а) Положения настоящей Конвенции применяются к автомобилям, находящимся в международном движении, и к прицепам, иным,
 чем сцепленный с автомобилем легкий прицеп, которые зарегистрированы одной из Договаривающихся сторон или одним из ее
 территориальных подразделений, причем водитель автомобиля должен иметь свидетельство о регистрации, выданное в подтверждение
 факта регистрации либо соответствующим компетентным органом этой Договаривающейся стороны или ее территориальным подразделением,
 либо от имени Договаривающейся стороны или ее территориального подразделения уполномоченным ею на то объединением. В свидетельстве
 о регистрации должны быть по крайней мере указаны :</h6><p></p>

<p style="text-indent:2em;">порядковый номер, именуемый регистрационным номером, составные элементы которого указаны в приложении 2 к настоящей
 Конвенции;</p><br>

<p>дата первой регистрации транспортного средства;</p><br>

<p>фамилия, имя и местожительство владельца свидетельства;</p><br>

<p>название или фабричная марка завода-изготовителя транспортного средства;</p><br>

<p>порядковый номер шасси (заводской или серийный номер завода-изготовителя);</p><br>

<p style="text-indent:2em;">разрешенный максимальный вес, если речь идет о транспортном средстве, 
предназначенном для перевозки грузов;</p><br>

<p>срок действия, если он ограничен.</p><br>

<p style="text-indent:2em;">Указанные в свидетельстве данные пишутся либо исключительно латинскими буквами или прописью, либо повторяются одним 
из этих способов.</p><br>

<p style="text-indent:2em;">b) Однако Договаривающиеся стороны или их территориальные подразделения могут решить, что в свидетельствах, выдаваемых
 на их территории, вместо даты первой регистрации будет указываться год выпуска.</p><br>

<p style="text-indent:2em;">2. В отступление от положений пункта 1 настоящей статьи на транспортное средство с полуприцепом, находящееся в международном
 движении в нерасцепленном состоянии, распространяются положения настоящей Конвенции, даже если тягач и полуприцеп зарегистрированы
 вместе и имеют только одно свидетельство о регистрации.</p><br>

<p style="text-indent:2em;">3. Ни одно положение настоящей Конвенции не должно толковаться как ограничивающее право Договаривающихся сторон или их территориальных
 подразделений требовать подтверждение права водителя на владение находящимся в международном движении транспортного средства, которое
 не зарегистрировано на имя находящегося в нем лица.</p><br>

<p style="text-indent:2em;">4. Рекомендуется, чтобы Договаривающиеся стороны, которые еще не сделали этого, создали орган, которому было бы поручено регистрировать
 в национальном или региональном масштабе сдаваемые в эксплуатацию автомобили и вести централизованный учет по каждому транспортному
 средству сведений, содержащихся в свидетельстве о регистрации.</p><br>

<p style="font-weight:500;">С т а т ь я 36</p><br>

<p>Регистрационный номер</p><br>

<p style="text-indent:2em;">1. На каждом автомобиле, находящемся в международном движении, должен помещаться спереди и сзади регистрационный номер; однако на
 мотоциклах этот номер должен помещаться только сзади.</p><br>

<p style="text-indent:2em;">2. На каждом зарегистрированном прицепе, находящемся в международном движении, должен помещаться сзади регистрационный номер.
 Если автомобиль буксирует один или несколько прицепов, то единственный прицеп или последний прицеп, если они не зарегистрированы,
 должны иметь регистрационный номер автомобиля, с которым они сцеплены.</p><br>

<p style="text-indent:2em;">3. Составные элементы и способ нанесения регистрационного номера, указанного в настоящей статье, должны соответствовать
 предписаниям приложения 2 к настоящей Конвенции.</p><br>

<p style="font-weight:500;">С т а т ь я 37</p><br>

<p>Отличительный знак государства регистрации</p><br>

<p style="text-indent:2em;">1. на каждом автомобиле, находящемся в международном движении, должен помещаться сзади, помимо его регистрационного номера,
 отличительный знак государства, в котором он зарегистрирован.</p><br>

<p style="text-indent:2em;">2. На каждом буксируемом автомобилем прицепе, на котором должен в соответствии со статьей 36 настоящей Конвенции помещаться
 сзади регистрационный номер, должен также помещаться сзади отличительный знак государства, выдавшего этот регистрационный
 номер. Предписания данного пункта применяются даже в том случае, если прицеп зарегистрирован в государстве, ином, чем государство
 регистрации транспортного средства, с которым он сцеплен; если прицеп не зарегистрирован, он должен иметь сзади отличительный знак
 государства регистрации транспортного средства-тягача, за исключением тех случаев, когда он передвигается по территории этого
 государства.</p><br>

<p style="text-indent:2em;">3. Составные элементы и способ нанесения отличительного знака, указанного в настоящей статье, должны соответствовать предписаниям
 приложения 3 к настоящей Конвенции.</p><br>

<p style="font-weight:500;">С т а т ь я 38</p><br>

<p>Опознавательные знаки</p><br>

<p style="text-indent:2em;">На каждом автомобиле и на каждом прицепе, находящихся в международном движении, должны быть опознавательные знаки, определенные
 в приложении 4 к настоящей Конвенции.</p><br>

<p style="font-weight:500;">С т а т ь я 39</p><br>

<p>Технические предписания</p><br>

<p style="text-indent:2em;">Каждый автомобиль, каждый прицеп и каждый состав транспортных средств, находящиеся в международном движении, должны удовлетворять
 положениям приложения 5 к настоящей Конвенции. Кроме того, они должны быть в исправном состоянии.</p><br>

<p style="font-weight:500;">С т а т ь я 40</p><br>

<p>Переходное положение</p><br>

<p style="text-indent:2em;">В течение десяти лет с момента вступления в силу настоящей Конвенции в соответствии с пунктом 1 статьи 47 на прицепы, находящиеся
 в международном движении, независимо от их разрешенного максимального веса, распространяются положения настоящей Конвенции даже 
 в том случае, если эти транспортные средства не будут зарегистрированы.</p>

<p></p><h4>Глава IV</h4><p></p>

<p style="font-weight:500;">ВОДИТЕЛИ АВТОМОБИЛЕЙ</p><br>

<p style="font-weight:500;">С т а т ь я 41</p><br>

<p>Действительность водительских удостоверений</p>

<p style="text-indent:2em;"></p><h6>1. Договаривающиеся стороны будут признавать :</h6><p></p>

<p style="text-indent:2em;">а) любое национальное водительское удостоверение, составленное на их национальном языке или на одном из их национальных языках, либо
, если оно не составлено на таком языке, сопровождаемое заверенным переводом;</p><br>

<p style="text-indent:2em;">b) любое национальное водительское удостоверение, соответствующее 
предписаниям приложения 6 к настоящей Конвенции, и</p><br>

<p style="text-indent:2em;">с) любое международное водительское удостоверение, соответствующее предписаниям приложения 7 к настоящей Конвенции, действительными
 на своей территории для управления автомобилем, соответствующим категории или категориям транспортных средств, на управление которыми 
 выдано удостоверение, при условии, что указанное удостоверение является действительным и что оно выдано другой Договаривающейся стороной 
 или одним из ее территориальных подразделений, либо объединением, уполномоченным на то этой Договаривающейся стороной или одним из ее 
 территориальных подразделений. Положения настоящего пункта не применяются к водительскому 
 удостоверению ученика-водителя.</p>

<p style="text-indent:2em;"></p><h6>2. независимо от положений, содержащихся в предыдущем пункте:</h6><p></p>

<p style="text-indent:2em;">а) если действительность водительского удостоверения обусловлена специальной пометкой, требующей ношения данным лицом некоторых приспособлений
 или предусматривающей некоторое переустройство транспортного средства с учетом индивидуальности водителя, это удостоверение считается 
 действительным только при условии соблюдения этих предписаний;</p><br>

<p style="text-indent:2em;">b) Договаривающиеся стороны могут не признавать действительными на своей территории водительские удостоверения, владельцу которого не 
исполнилось восемнадцати лет;</p><br>

<p style="text-indent:2em;">с) каждая Договаривающаяся сторона может не признавать действительными на своей территории для управления автомобилями или составами транспортных 
средств категорий C,D,Е, упомянутых в приложениях 6 и 7 к настоящей Конвенции, водительские удостоверения, владельцам которых не исполнилось 
двадцати одного года.</p><br>

<p style="text-indent:2em;">3. Договаривающиеся стороны обязуются принять необходимые меры для того, чтобы национальные и международные водительские удостоверения, 
указанные в подпунктах "а", "b" и "с" пункта 1 настоящей статьи, не выдавались на их территории без разумной гарантии способности водителя 
управлять транспортным средством и его физической пригодности.</p>

<p style="text-indent:2em;"></p><h6>4. При применении пункта 1 и подпункта "с" пункта 2 настоящей статьи :</h6><p></p>

<p style="text-indent:2em;">а) автомобиль категории В, указанной в приложениях 6 и 7 к настоящей Конвенции, может буксировать легкий прицеп; он может также буксировать 
прицеп, разрешенный максимальный вес которого превышает 750 кг (1650 фунтов), но не превышает веса автомобиля без нагрузки, если общий 
разрешенный максимальный вес такого состава не превышает 3500 кг (7700 фунтов);</p><br>

<p style="text-indent:2em;">b) автомобили категорий С и D, указанных в приложениях 6 и 7 к настоящей Конвенции, могут буксировать легкий прицеп, причем такой состав 
продолжает входить в категорию С или в категорию D.</p><br>

<p style="text-indent:2em;">5. Международное водительское удостоверение может выдаваться только на основании национального водительского удостоверения, выданного с 
учетом минимальных требований, предусмотренных в настоящей Конвенции. Международное водительское удостоверение теряет силу по истечении 
срока действия соответствующего национального водительского удостоверения, номер которого должен проставляться в международном 
водительском удостоверении.</p>

<p style="text-indent:2em;"></p><h6>6. Положения настоящей статьи не обязывают Договаривающиеся стороны:</h6><p></p>

<p style="text-indent:2em;">а) признавать действительными национальные или международные водительские удостоверения, выданные на территории другой Договаривающейся 
тороны лицам, которые имели на их территории обычное местожительство в момент выдачи этого водительского удостоверения или которые перенесли 
свое обычное местожительство на их территорию после выдачи этого удостоверения;</p><br>

<p style="text-indent:2em;">b) признавать действительность вышеуказанных водительских удостоверений, выданных водителям, обычное местожительство которых в момент 
выдачи удостоверения находилось не на территории, где были выданы удостоверения, или которые перенесли свое местожительство после выдачи 
удостоверений на другую территорию.</p><br>

<p style="font-weight:500;">С т а т ь я 42</p><br>

<p>Приостановления действия водительских удостоверений</p>

<p style="text-indent:2em;"></p><h6>1. Договаривающиеся стороны или их территориальные подразделения могут лишить водителя права пользоваться на их территории национальными 
или международными водительским удостоверением в случае нарушения им на их территории правил, за которые согласно их законодательству 
предусматривается лишение права пользоваться водительским удостоверением. В этом случае компетентный орган Договаривающейся стороны или ее 
территориального подразделения, который лишил водителя права пользоваться водительским удостоверением, 
может :</h6><p></p>

<p style="text-indent:2em;">а) изъять водительское удостоверение и задержать его до истечения срока, на который изымается это удостоверение, или до момента выезда 
водителя с данной территории, в зависимости от того, какой срок наступает скорее;</p><br>

<p style="text-indent:2em;">b) информировать орган, который выдал или от имени которого выдано водительское удостоверение, о лишении права пользования этим 
удостоверением;</p><br>

<p style="text-indent:2em;">с) если речь идет о международном водительском удостоверении, сделать в специально предусмотренном для этого месте отметку о том, 
что указанное водительское удостоверение перестало быть действительным на данной территории;</p><br>

<p style="text-indent:2em;">d) в этом случае, когда он не применяет процедуру, упомянутую в подпункте "а" настоящего пункта, в дополнение к сообщению, упомянутому 
в подпункте "b", просить орган, который выдал или от имени которого было выдано водительское удостоверение, информировать заинтересованное
 лицо о принятии в отношении него решении.</p><br>

<p style="text-indent:2em;">2. Договаривающиеся стороны будут стремиться ставить заинтересованных лиц в известность о решениях, которые будут им сообщаться в 
соответствии с процедурой, предусмотренной в подпункте "d" пункта 1 настоящей статьи.</p><br>

<p style="text-indent:2em;">3. Ни одно положение настоящей Конвенции не должно толковаться как препятствующее Договаривающимся сторонам или их территориальным 
подразделениям запретить водителю, имеющему национальное или международное водительское удостоверение, управлять автомобилем, если очевидно
 и доказано, что его состояние таково, что он может управлять транспортным средством, не создавая угрозы для движения, или если он был лишен
 прав на вождение в государстве, где он имеет свое обычное местожительство.</p><br>

<p style="font-weight:500;">С т а т ь я 43</p><br>

<p>Переходное положение</p><br>

<p style="text-indent:2em;">Международные водительские удостоверения, соответствующие положениям Конвенции о дородном движении, подписанной в Женеве 19 сентября 1949
 года, и выданные в течение пяти лет после вступления в силу настоящей Конвенции в соответствии с пунктом 1 статьи 47 настоящей Конвенции, 
 будут при применении статей 41 и 42 настоящей Конвенции приравниваться к международным водительским удостоверениям, предусмотренным в 
 настоящей Конвенции.</p>

<p></p><h4>Глава Y</h4><p></p>

<p style="font-weight:500;">Условия допущения велосипедов и велосипедов с подвесным двигателем к международному движению</p><br>

<p style="font-weight:500;">Статья 44</p>

<p style="text-indent:2em;"></p><h6>1. Велосипеды без двигателя, находящиеся в международном движении, 
должны быть снабжены:</h6><p></p>

<p>а) эффективным тормозом; b) звонком, слышимым на достаточном расстоянии, причем всякие</p><br>

<p style="text-indent:2em;">другие звуковые сигнальные приборы запрещаются; с) задним красным светоотражающим приспособлением и приспособлениями, излучающими белый 
или желтый селективный свет спереди и красный свет сзади.</p>

<p style="text-indent:2em;"></p><h6>2. На территории Договаривающихся сторон, которые не сделали в соответствии с пунктом 2 статьи 54 настоящей Конвенции заявления о 
приравнивании велосипедов с подвесным двигателем к мотоциклам, находящиеся в международном движении велосипеды с подвесным двигателем 
должны быть снабжены:</h6><p></p>

<p>а) двумя независимыми тормозами;</p><br>

<p>b) звонком или другим звуковым сигнальным прибором, слышимым на достаточном расстоянии;</p><br>

<p>с) эффективным глушителем;</p><br>

<p style="text-indent:2em;">d) приспособлениями, излучающими передний белый или желтый селективный свет и задний красный свет, а также задним красным 
светоотражающим приспособлением;</p><br>

<p>е) опознавательным знаком, определение которого содержится в приложении 4 к настоящей Конвенции.</p><br>

<p style="text-indent:2em;">3. На территории Договаривающихся сторон, которые сделали в соответствии с пунктом 2 статьи 54 настоящей Конвенции заявление о 
приравнивании велосипедов с подвесным двигателем к мотоциклам, условиями, которым должны отвечать велосипеды с подвесным двигателем, 
для того чтобы быть допущенным к международному движению, являются условия, определенные для мотоциклов в приложении 5 к настоящей 
Конвенции.</p>

<p></p><h4>Глава YI</h4><p></p>

<p style="font-weight:500;">Заключительные положения</p><br>

<p style="font-weight:500;">Статья 45</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция открыта в Центральных учреждениях Организации Объединенных Наций в Нью-Йорке до 31 декабря 1969 года для подписания 
всеми государствами - членами Организации Объединенных Наций или членами любых специализированных учреждений, или членами Международного 
агентства по атомной энергии, или Сторонами Статута Международного Суда и любым другим государством, приглашенными Генеральной Ассамблеей 
Организации Объединенных Наций стать Стороной настоящей Конвенции.</p><br>

<p style="text-indent:2em;">2. Настоящая Конвенция подлежит ратификации. Ратификационные грамоты передаются на хранение Генеральному Секретарю Организации 
Объединенных Наций.</p><br>

<p style="text-indent:2em;">3. Настоящая Конвенция остается открытой для присоединения к ней любого из государств, указанных в пункте 1 настоящей статьи. 
Документы о присоединении передаются на хранение Генеральному Секретарю.</p><br>

<p style="text-indent:2em;">4. Каждое государство должно в момент подписания настоящей Конвенции или сдачи на хранение ратификационной грамоты или документа о 
присоединении уведомить Генерального Секретаря о выбранном им отличительном знаке, который будет проставляться, в соответствии с 
приложением 3 к настоящей Конвенции, на находящихся в международном движении 
зарегистрированных им транспортных средствах.</p><br>

<p style="text-indent:2em;">Посредством последующей нотификации, адресованной Генеральному Секретарю, каждое государство может изменить ранее выбранный им 
отличительный знак.</p><br>

<p style="font-weight:500;">Статья 46</p><br>

<p style="text-indent:2em;">1. Каждое государство может при подписании, ратификации настоящей Конвенции или при присоединении к ней, или в любой момент впоследствии 
заявить посредством нотификации, адресованной Генеральному Секретарю, что Конвенция становится применимой ко всем территориям или части 
территорий, за внешние сношения которых оно ответственно. Конвенция начинает применяться на территории или на территориях, указанных в 
нотификации, по истечении тридцати дней со дня получения Генеральным Секретарем упомянутой нотификации или в момент вступления Конвенции 
в силу в отношении государства, сделавшего нотификацию, если эта дата является более поздней.</p><br>

<p style="text-indent:2em;">2. Каждое государство, сделавшее заявление в соответствии с пунктом 1 настоящей статьи, может в любой момент впоследствии заявить 
посредством нотификации, адресованной Генеральному Секретарю, что Конвенция перестанет применяться на территории, указанной в нотификации 
и Конвенция перестанет применяться на территории, указанной в нотификации, по истечении тридцати дней со дня по истечении одного года со 
дня получения Генеральным Секретарем этой нотификации.</p><br>

<p style="text-indent:2em;">3. Каждое государство, сделавшее нотификацию в соответствии с пунктом 1 настоящей статьи, уведомляет Генерального Секретаря о выбранном 
им отличительном знаке или знаках, которые будут проставляться на находящихся в международном движении транспортных средствах, которые 
зарегистрированы на соответствующей территории или территориях согласно приложению 3 к настоящей Конвенции. Посредством последующей нотификации, 
адресованной Генеральному Секретарю, каждое государство может изменить ранее выбранный им 
отличительный знак.</p><br>

<p style="font-weight:500;">Статья 47</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция вступает в силу по истечении двенадцати месяцев со дня сдачи на хранение пятнадцатого документа о 
ратификации или присоединении.</p><br>

<p style="text-indent:2em;">2. В отношении каждого государства, которое ратифицирует настоящую Конвенцию или присоединяется к ней после сдачи на хранение 
пятнадцатого документа о ратификации или присоединении, Конвенция вступает в силу по истечении двенадцати месяцев со дня сдачи этим 
государством на хранение ратифицированной грамоты или документа о присоединении.</p><br>

<p style="font-weight:500;">Статья 48</p><br>

<p style="text-indent:2em;">С момента вступления настоящей Конвенции в силу ею отменяются и заменяются в отношениях Договаривающимися сторонами Международная 
Конвенция об автомобильном движении и Международная Конвенция о дорожном движении, подписанные в Париже 24 апреля 1926 года, Конвенция
 о правилах автомобильного движения между американскими странами, открытая для подписания в Вашингтоне 15 декабря 1943 года, 
 и Конвенция о дорожном движении, открытая для подписания в Женеве 19 сентября 1949 года.</p><br>

<p style="font-weight:500;">Статья 49</p><br>

<p style="text-indent:2em;">1. Через один год после вступления в силу настоящей Конвенции каждая Договаривающаяся сторона может предложить одну или несколько 
поправок к Конвенции. Текст любой предложенной поправки вместе с пояснительным меморандумом направляется Генеральному Секретарю, 
который препровождает его всем Договаривающимся сторонам. Договаривающиеся стороны имеют возможность уведомить его в двенадцатимесячный 
срок, начиная со дня препровождения этого текста, о том: а) принимают ли они поправку, b) отклоняют ли они поправку или с) желают ли они,
 чтобы для рассмотрения этой поправки была созвана конференция. Генеральный Секретарь направляет также текст предложенной поправки всем
 другим государствам, указанным в пункте 1 статьи 45 настоящей Конвенции.</p><br>

<p style="text-indent:2em;">2. а) Любая предложенная поправка, разосланная в соответствии с предыдущим пунктом, считается принятой, если в течение упомянутого выше
 двенадцатимесячного срока менее трети Договаривающихся сторон информируют Генерального Секретаря о том, что они отклоняют поправку или
 желают созыва конференции для рассмотрения этой поправки. Генеральный Секретарь уведомляет все Договаривающиеся стороны о каждом принятии
 или отклонении предложенной поправки и о любом поступлении просьбы о созыве конференции. Если в течение установленного двенадцатимесячного
 срока такие отклонения и просьбы будут получены менее чем от трети всех Договаривающихся сторон, Генеральный Секретарь информирует все
 Договаривающиеся стороны о том, что поправка вступает в силу через шесть месяцев по истечении двенадцатимесячного срока, указанного в предыдущем
 пункте, для всех Договаривающихся сторон, за исключением тех, которые в течение установленного срока отклонили поправку или обратились с
 просьбой созвать конференцию для ее обсуждения.</p><br>

<p style="text-indent:2em;">b) Каждая Договаривающаяся сторона, которая в течение вышеуказанного двенадцатимесячного срока отклонила предложенную поправку или попросила
 созвать конференцию для ее рассмотрения, может в любое время после истечения этого срока уведомить Генерального Секретаря о принятии ею поправки,
 и Генеральный Секретарь рассылает это уведомление всем остальным Договаривающимся сторонам. Поправка вступает в силу в отношении Договаривающихся
 сторон, направивших такие уведомления о принятии, через шесть месяцев после получения их Генеральным Секретарем.</p><br>

<p style="text-indent:2em;">3. Если предложенная поправка не была принята в соответствии с пунктом 2 настоящей статьи и если в течение двенадцатимесячного срока,
 предусмотренного в пункте 1 настоящей статьи, менее половины общего числа Договаривающихся сторон информируют Генерального секретаря
 о том, что они отклоняют предложенную поправку, и если по крайней мере треть всех Договаривающихся сторон, но не менее десяти, информируют
 его о том, что они принимают ее или желают созыва конференции для обсуждения этой поправки, Генеральный Секретарь созывает конференцию для
 рассмотрения предложенной поправки или любого другого предложения, которое может быть ему представлено в соответствии с пунктом 4
 настоящей статьи.</p><br>

<p style="text-indent:2em;">4. Если конференция созывается в соответствии с пунктом 3 настоящей статьи, Генеральный Секретарь приглашает на нее все государства,
 указанные в пункте 1 статьи 45 настоящей Конвенции. Генеральный Секретарь обращается ко всем приглашенным на конференцию государствам
 с просьбой представить ему по крайней мере за шесть месяцев до открытия конференции любые предложения, которые они в дополнение к
 предложенной поправке могут пожелать рассмотреть на конференции, и уведомляет об этих предложениях по крайней мере за три месяца
 до открытия конференции все приглашенные на конференцию государства.</p><br>

<p style="text-indent:2em;">5. а) Любая поправка к настоящей Конвенции считается принятой, если она принимается большинством в две трети государств,
 представленных на Конференции, при условии, что это большинство включает по крайней мере две трети всех Договаривающихся
 сторон, представленных на Конференции. Генеральный Секретарь извещает все Договаривающиеся стороны о принятии поправки, и
 она вступает в силу через двенадцать месяцев со дня такого уведомления в отношении всех Договаривающихся сторон, за исключением
 тех, которые в течение этого срока сообщили Генеральному Секретарю об отклонении поправки.</p><br>

<p style="text-indent:2em;">b) Каждая Договаривающаяся сторона, отклонившая в течение вышеуказанного двенадцатимесячного срока поправку, может в любое время
 уведомить Генерального Секретаря о принятии ею поправки, и Генеральный Секретарь извещает об этом все остальные Договаривающиеся
 стороны. Поправка вступает в силу в отношении Договаривающийся стороны, уведомившей о ее принятии, через шесть месяцев после получения
 уведомления Генеральным Секретарем или по истечении вышеуказанного двенадцатимесячного срока,
 если этот срок истекает позднее.</p><br>

<p style="text-indent:2em;">6. Если предложенная поправка не считается принятой в соответствии с пунктом 2 настоящей статьи и если не выполнены условия созыва
 конференции, предписанные в пункте 3 настоящей статьи, предложенная поправка считается отклоненной.</p><br>

<p style="font-weight:500;">Статья 50</p><br>

<p style="text-indent:2em;">Каждая Договаривающаяся сторона может денонсировать настоящую Конвенцию посредством письменной нотификации, адресованной Генеральному
 Секретарю. Денонсация вступает в силу по истечении одного года со дня получения Генеральным 
 Секретарем этой нотификации.</p><br>

<p style="font-weight:500;">Статья 51</p><br>

<p style="text-indent:2em;">Настоящая Конвенция теряет силу, если число Договаривающихся сторон составляет в течение какого-либо периода последовательных
 двенадцати месяцев менее пяти.</p><br>

<p style="font-weight:500;">Статья 52</p><br>

<p style="text-indent:2em;">Всякий спор между двумя или более Договаривающимися сторонами относительно толкования или применения настоящей Конвенции,
 который Стороны не могут разрешить путем переговоров или другими средствами урегулирования, может быть по просьбе любой из
 заинтересованных Договаривающихся сторон передан для разрешения Международному Суду.</p><br>

<p style="font-weight:500;">Статья 53</p><br>

<p style="text-indent:2em;">Никакое положение настоящей Конвенции не должно толковаться как препятствующее какой-либо из Договаривающихся сторон принимать
 совместимые с Уставом Организации Объединенных Наций и ограничиваемые создавшимся положением меры, которые она считает
 необходимыми для обеспечения своей внешней и внутренней безопасности.</p><br>

<p style="font-weight:500;">Статья 54</p><br>

<p style="text-indent:2em;">1. Каждое государство может при подписании настоящей Конвенции или при сдаче на хранение своей ратификационной грамоты или
 документа о присоединении заявить, что оно не считает себя связанным статьей 52 настоящей Конвенции. Договаривающиеся стороны
 не являются связанными статьей 52 в отношении любой Договаривающейся стороны, сделавшей такое
 заявление.</p><br>

<p style="text-indent:2em;">2. В момент сдачи на хранение своей ратификационной грамоты или документа о присоединении каждое государство может заявить
 посредством нотификации, адресованной Генеральному Секретарю, что в целях применения настоящей Конвенции оно приравнивает
 велосипеды с подвесным двигателем к мотоциклам (подпункт n статьи 1). В любое время впоследствии каждое государство может 
 посредством нотификации, адресованной Генеральному Секретарю, взять обратно свое заявление.</p><br>

<p style="text-indent:2em;">3. Предусмотренные в пункте 2 настоящей статьи заявления вступают в силу через шесть месяцев после получения Генеральным 
Секретарем нотификации или в момент вступления в силу Конвенции в отношении государства, сделавшего заявление, если эта
 дата является более поздней.</p><br>

<p style="text-indent:2em;">4. Любое изменение ранее выбранного отличительного знака, о котором сделано уведомление в соответствии с пунктом 4 статьи
 45 или пунктом 3 статьи 46 настоящей Конвенции, вступает в силу через три месяца со дня получения уведомления Генеральным
 Секретарем.</p><br>

<p style="text-indent:2em;">5. Оговорка к настоящей Конвенции и приложения к ней, иные, чем оговорка, предусмотренная в пункте 1 настоящей статьи,
 разрешаются при условии представления их в письменной форме и, если они сделаны до сдачи на хранение ратифицированной 
 грамоты или документа о присоединении, при условии подтверждения их в ратификационной грамоте или документе о 
 присоединении. Генеральный Секретарь сообщает об указанных оговорках всем государствам, указанным в пункте 1 статьи 
 45 настоящей Конвенции.</p><br>

<p style="text-indent:2em;">6. Каждая Договаривающаяся сторона, которая делает оговорку или заявление в соответствии с пунктами 1 или 4 
настоящей статьи, может в любой момент взять свою оговорку путем нотификации, адресованной Генеральному 
Секретарю.</p>

<p style="text-indent:2em;"></p><h6>7. Любая оговорка, сделанная в соответствии с пунктом 5 настоящей 
статьи:</h6><p></p>

<p style="text-indent:2em;">а) изменяет для Договаривающейся стороны, сделавшей вышеуказанную оговорку, в рамках этой оговорки положения
 Конвенции, к которым она относится;</p><br>

<p style="text-indent:2em;">b) изменяет в такой же мере эти положения и для других Договаривающихся сторон в их взаимоотношениях с 
Договаривающейся стороной, сделавшей оговорку.</p><br>

<p style="font-weight:500;">Статья 55</p>

<p style="text-indent:2em;"></p><h6>Помимо заявлений, нотификаций и уведомлений, предусмотренных в статьях 49 и 54 настоящей Конвенции, 
Генеральный Секретарь сообщает всем государствам, указанным в пункте 1 статьи 45:</h6><p></p>

<p>а) о подписаниях, ратификациях и присоединениях к Конвенции в соответствии со статьей 45;</p><br>

<p>b) о нотификациях и заявлениях в соответствии с пунктом 4 статьи 45 и со статьей 46;</p><br>

<p>с) о датах вступления в силу настоящей Конвенции в соответствии со статьей 47;</p><br>

<p>d) о дате вступления в силу поправок к настоящей Конституции в соответствии с пунктами 2 
и 5 статьи 49;</p><br>

<p>е) о денонсациях в соответствии со статьей 50;</p><br>

<p>f) об утрате настоящей Конвенции силы в соответствии со статьей 51.</p><br>

<p style="font-weight:500;">Статья 56</p><br>

<p style="text-indent:2em;">Подлинник настоящей Конвенции, составленный в одном экземпляре на английском, испанском, китайском, 
русском и французском языках, причем все пять текстов являются равно аутентичными, сдается на хранение 
Генеральному Секретарю Организации Объединенных Наций, который препровождает надлежащим образом заверенные 
копии всем государствам, указанным в пункте 1 статьи 45 настоящей Конвенции.</p><br>

<p style="text-indent:2em;">В УДОСТОВЕРЕНИИ ЧЕГО нижеподписавшиеся представители, надлежащим образом на то уполномоченные своими 
правительствами, подписали настоящую Конвенцию.</p><br>

<p>СОВЕРШЕНО в Вене в ноября месяца восьмого дня тысяча девятьсот шестьдесят восьмого года.</p><br>

<p>(Подписи)</p>

<p style="text-indent:2em;"></p><h6>От имени СССР Конвенция подписана 8 ноября 1968 г. и ратифицирована Президиумом Верховного Совета СССР 
29 апреля 1974 г. со следующими оговорками и заявлениями, сделанными при подписании:</h6><p></p>

<p style="text-indent:2em;">"Союз Советских Социалистических Республик не считает себя связанным положениями статьи 52 Конвенции о 
дорожном движении относительно передачи споров о толковании при применении Конвенции для разрешения 
Международному Суду по просьбе любой из сторон".</p><br>

<p style="text-indent:2em;">"Союз Советских Социалистических Республик заявляет, что положения статьи 45 Конвенции о дорожном движении, 
согласно которым ряд государств не может стать участником этой Конвенции, носят дискриминационный характер, 
и считает, что Конвенция о дорожном движении должна быть открыта для участия всех заинтересованных государств, 
без какой-либо дискриминации и ограничений".</p><br>

<p style="text-indent:2em;">"Союз Советских Социалистических Республик, что положения статьи 46 Конвенции о дорожном движении являются 
устаревшими и противоречат Декларации Генеральной Ассамблеи Организации Объединенных Наций о предоставлении
 независимости колониальным странам и народам (резолюция 1514 (XY) от 14 декабря 1960 г.)".</p><br>

<p style="text-indent:2em;">Ратификационная грамота СССР сдана на хранение Генеральному Секретарю Организации Объединенных Наций 7 июня 
1974 г. В соответствии с пунктом 1 статьи 47 Конвенция вступила в силу 21 мая 1977 г.</p>

<p></p><h4>ПРИЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">Приложение 1</p><br>

<p style="font-weight:500;">Отступления от обязательства допускать к международному движению автомобили и прицепы</p><br>

<p style="text-indent:2em;">1. Договаривающиеся стороны могут не допускать к международному движению по своей территории автомобили, 
прицепы и составы транспортных средств, общий вес которых или нагрузка на ось, или габаритные размеры 
превышают пределы, установленные их национальным законодательством для транспортных средств, 
зарегистрированных на их территории. Договаривающиеся стороны, на территории которых имеет место 
международное движение тяжелых транспортных средств, должны стараться заключать региональные соглашения, 
допускающие к международному движению по дорогам данного района, за исключением дорог с низкими 
характеристиками, транспортные средства и составы транспортных средств, вес и размеры которых не 
превышают величин, установленных этими соглашениями.</p>

<p style="text-indent:2em;"></p><h6>2. При применении предписаний пункта 1 настоящего приложения не будут считаться превышением разрешенной 
максимальной ширины выходящие за габаритные размеры по ширине транспортные средства:</h6><p></p>

<p style="text-indent:2em;">а) шины вблизи от точек их соприкосновения с землей и соединительные детали индикаторов давления в 
шинах;</p><br>

<p>b) приспособления противоскольжения, надетые на колеса;</p><br>

<p style="text-indent:2em;">с) зеркала заднего вида, сконструированные таким образом, что при легком нажиме они могут поворачиваться
 в обоих направлениях, не выступая при этом за разрешенную максимальную ширину;</p><br>

<p style="text-indent:2em;">d) боковые указатели поворота и габаритные огни при условии, что они выступают за габарит не более
 чем на несколько сантиметров;</p><br>

<p style="text-indent:2em;">е) таможенные печати (пломбы), наложенные на груз, и приспособления для обеспечения сохранности и
 защиты этих печатей (пломб).</p>

<p style="text-indent:2em;"></p><h6>3. Договаривающиеся стороны могут не допускать к международному движению по своей территории следующие
 составы транспортных средств, если их национальное законодательство запрещает движение таких
 составов:</h6><p></p>

<p>а) мотоциклы с прицепом;</p><br>

<p>b) составы, включающие автомобиль и несколько прицепов;</p><br>

<p>с) транспортные средства с полуприцепом, предназначенные для перевозки пассажиров.</p><br>

<p style="text-indent:2em;">4. Договаривающиеся стороны могут не допускать к международному движению по своей территории
 автомобили и прицепы, на которые распространяются отступления согласно пункту 60 приложения
 5 настоящей Конвенции.</p><br>

<p style="text-indent:2em;">5. Договаривающиеся стороны могут не допускать к международному движению по своей территории
 велосипеды с подвесным двигателем и мотоциклы, водители и в соответствующих случаях пассажиры
 которых не имеют защитных шлемов.</p><br>

<p style="text-indent:2em;">6. Договаривающиеся стороны могут обусловить допуск к международному движению по своей территории
 любого автомобиля, иного чем двухколесный велосипед с подвесным двигателем и двухколесный мотоцикл
 без коляски, наличием на автомобиле устройства, указанного в пункте 56 приложения 5 к настоящей
 Конвенции и предназначенного, в случае остановки на проезжей части, для предупреждения об опасности,
 которую представляет собой остановившееся на ней транспортное средство.</p><br>

<p style="text-indent:2em;">7. Договаривающиеся стороны могут обусловить допуск к международному движению по своей территории
 по некоторым трудным дорогам или в некоторых районах со сложным рельефом автомобилей, разрешенный
 максимальный вес которых превышает 3500 кг (7700 фунтов), соблюдением предписаний их национального
 законодательства, касающихся допуска к движению по этим дорогам или в этих районах зарегистрированных
 ими транспортных средств, имеющих такой же разрешенный максимальный вес.</p><br>

<p style="text-indent:2em;">8. Договаривающиеся стороны могут не допускать к международному движению по своей территории автомобили,
 имеющие огни ближнего света с асимметричными лучами, если регулировка этих лучей не соответствует принятому
 на их территории направлению движения.</p><br>

<p style="text-indent:2em;">9. Договаривающиеся стороны могут не допускать к международному движению по своей территории автомобили или
 буксируемые автомобилем прицепы, имеющие отличительный знак, иной, чем предусмотренный для этого транспортного
 средства статьей 37 настоящей Конвенции.</p><br>

<p style="font-weight:500;">Приложение 2</p><br>

<p>Регистрационный номер автомобилей и прицепов, находящихся в между народном движении</p><br>

<p style="text-indent:2em;">1. Указанный в статьях 35 и 36 настоящей Конвенции регистрационный номер должен состоять из цифр, либо из
 цифр и букв. Цифры должны быть арабскими, а буквы заглавными латинского алфавита. Однако допускается 
 употребление других цифр или букв, но в таких случаях регистрационный номер должен быть повторен арабскими
 цифрами и заглавными латинскими буквами.</p><br>

<p style="text-indent:2em;">2. Регистрационный номер должен быть составлен и обозначен таким образом, чтобы находящийся на оси 
неподвижного транспортного средства наблюдатель мог его различать днем в ясную погоду с расстояния не менее
 40 м (130 футов); однако Договаривающиеся стороны могут уменьшить это минимальное расстояние для 
 зарегистрированных на их территории мотоциклов и особых категорий автомобилей, регистрационным номерам
 которых трудно придать такие размеры, чтобы их можно было различать на расстоянии 40 м (130 футов).</p><br>

<p style="text-indent:2em;">3. Если регистрационный номер обозначен на специальной табличке, эта табличка должна быть плоской и 
закрепленной в вертикальном или почти вертикальном положении перпендикулярно среднему продольному 
сечению транспортного средства. Если номер обозначается или наносится краской на самом транспортном 
средстве, поверхность, на которой он обозначается или наносится краской, должна быть плоской и 
вертикальной или почти вертикальной и перпендикулярной среднему сечению транспортного средства.</p><br>

<p style="text-indent:2em;">4. С учетом положений пункта 5 статьи 32 табличка или поверхность, на которой обозначен или нанесен краской 
регистрационный номер, может быть из светоотражающего материала.</p><br>

<p style="font-weight:500;">Приложение 3</p><br>

<p>Отличительный знак автомобилей и прицепов, находящихся в между народном движении</p><br>

<p style="text-indent:2em;">1. Упомянутый в статье 37 настоящей Конвенции отличительный знак должен состоять из одной-трех заглавных букв 
латинского алфавита. Буквы должны быть высотой не менее 0,08 м (3,1 дюйма) и обозначены штрихами шириной не 
менее 0,01м (0,4 дюйма). Буквы должны быть нанесены черной краской на белом фоне, имеющим форму эллипса, 
главная ось которого горизонтальная.</p><br>

<p style="text-indent:2em;">2. В тех случаях, когда отличительный знак содержит только одну
 букву, главная ось эллипса может быть вертикальной.</p><br>

<p style="text-indent:2em;">3. Отличительный знак не должен включаться в регистрационный номер или обозначаться таким образом, что он
 может быть принят за этот последний или может ухудшить его удобочитаемость.</p>

<p style="text-indent:2em;"></p><h6>4. На мотоциклах и их прицепах длина осей эллипса должна составлять не менее 0,175м (6,9 дюйма) и 0,115м 
(4,5 дюйма). На других автомобилях и их прицепах длина осей эллипса должна составлять не менее:</h6><p></p>

<p>а) 0,24м (9,4 дюйма) и 0,145м (5,7 дюйма), если отличительный знак состоит из трех букв;</p><br>

<p>b) 0,175м (6,9 дюйма) и 0,115м (4,5 дюйма), если отличительный знак состоит менее чем из трех букв.</p><br>

<p style="text-indent:2em;">5. Положения пункта 3 приложения 2 применяются к обозначению отличительного знака
 на транспортных средствах.</p><br>

<p style="font-weight:500;">Приложение 4</p><br>

<p>Опознавательные знаки автомобилей и прицепов, находящихся в между народном движении</p>

<p style="text-indent:2em;"></p><h6>1. Опознавательные знаки включают:</h6><p></p>

<p style="text-indent:2em;"></p><h6>а) для автомобилей :</h6><p></p>

<p>i) название и марка завода - изготовителя транспортного средства;</p><br>

<p style="text-indent:2em;">ii) на шасси или (при отсутствии его) на кузове автомобиля заводской номер или серийный номер 
завода-изготовителя;</p><br>

<p>iii) на двигателе - заводской номер двигателя, если такой номер обозначается заводом-изготовителем;</p><br>

<p>b) для прицепов - обозначения, указанные выше, в подпунктах "i" и "ii";</p><br>

<p>с) для велосипедов с подвесным двигателем - указание рабочего объема двигателя и марку "СМ".</p><br>

<p style="text-indent:2em;">2. Буквы и цифры, включенные в опознавательные знаки, упомянутые в пункте 1 настоящего приложения, должны 
помещаться в легко доступном месте и быть удобочитаемыми; кроме того, они должны быть нанесены таким образом, 
чтобы из было трудно изменить или устранить. Опознавательные знаки должны наноситься либо исключительно латинскими 
буквами или прописью и арабскими цифрами, либо они должны быть повторены таким образом.</p><br>

<p style="font-weight:500;">Приложение 5</p><br>

<p>Технические условия, касающиеся автомобилей и прицепов</p><br>

<p style="text-indent:2em;">1. Договаривающиеся стороны, которые в соответствии с подпунктом "n" статьи 1 настоящей Конвенции заявили о своем 
намерении приравнять к мотоциклам трехколесные транспортные средства, вес которых без нагрузки не превышает 400 кг 
(900 фунтов), должны распространить на эти последние содержащиеся в настоящем приложении предписания, касающиеся либо
 мотоциклов, либо других автомобилей.</p><br>

<p style="text-indent:2em;">2. В настоящем приложении термин "прицеп" применяется только к прицепам, предназначенным для буксировки 
автомобилей.</p><br>

<p style="text-indent:2em;">3. При условии соблюдения положений подпункта "а" пункта 2 статьи 3 настоящей Конвенции каждая Договаривающаяся 
сторона может предписывать для автомобилей, которые она регистрирует, и для прицепов, которые она допускает к движению 
в соответствии со своим национальным законодательством, предписания, дополняющие положения настоящего приложения или 
являющиеся более строгими.</p>

<p></p><h4>Глава I</h4><p></p>

<p style="font-weight:500;">ТОРМОЖЕНИЕ</p>

<p style="text-indent:2em;"></p><h6>4. В настоящей главе:</h6><p></p>

<p style="text-indent:2em;">а) термин "колеса одной оси" означают колеса, расположенные симметрично или почти симметрично по отношению к 
среднему продольному сечению транспортного средства, даже в том случая, если они не находятся на одной и той 
же оси (ось-тандем рассматривается как две оси);</p><br>

<p style="text-indent:2em;">b) термин "рабочий тормоз" означает устройство, используемое для замедления движения и остановки транспортного 
средства;</p><br>

<p style="text-indent:2em;">с) термин "стояночный тормоз" означает устройство, используемое для удержания транспортного средства в 
неподвижном состоянии в отсутствии водителя или для удержания отцепленного прицепа в неподвижном состоянии;</p><br>

<p style="text-indent:2em;">d) термин "аварийный тормоз" означает устройство, предназначенное для замедления движения и остановки 
транспортного средства в случае выхода из строя рабочего тормоза.</p><br>

<p style="font-weight:500;">А. Торможение автомобилей, иных, чем мотоциклы</p>

<p style="text-indent:2em;"></p><h6>5. Каждый автомобиль, иной, чем мотоцикл, должен иметь тормоза, которые могут легко приводиться в действие
 находящимся за рулем водителем. Эти тормозные устройства должны выполнять 
 следующие три функции торможения:</h6><p></p>

<p style="text-indent:2em;">а) рабочий тормоз - замедлять движение транспортного средства и останавливать его надежно, быстро и 
эффективно независимо от его условий загрузки и от того, движется ли транспортное средство на подъем
 или под уклон;</p><br>

<p style="text-indent:2em;">b) стояночный тормоз - удерживать транспортное средство в неподвижном состоянии, независимо от его условий 
загрузки, на подъеме или на спуске с уклоном в 16%, причем тормозящие поверхности тормоза должны удерживаться
 в положении торможения посредством устройства чисто механического типа;</p><br>

<p style="text-indent:2em;">с) аварийный тормоз - замедлять движение транспортного средства и останавливать его, независимо от его условий 
загрузки, на достаточно коротком расстоянии даже в случае выхода из строя рабочего тормоза.</p><br>

<p style="text-indent:2em;">6. При условии соблюдения положений пункта 5 настоящего приложения приспособления, обеспечивающие выполнение 
трех функций торможения (рабочее, аварийное и стояночное), могут иметь общие части; совмещение приводов 
допускается только при условии, что при этом остаются по крайней мере два отдельных привода.</p><br>

<p style="text-indent:2em;">7. Рабочий тормоз должен действовать на все колеса транспортного средства; однако на транспортных средствах,
 имеющих более двух осей, колеса одной оси могут не тормозиться.</p><br>

<p style="text-indent:2em;">8. Аварийный тормоз должен действовать по крайней мере на одно колесо с каждой стороны среднего продольного 
сечения транспортного средства; аналогичное положение относится к стояночному тормозу.</p><br>

<p style="text-indent:2em;">9. Рабочий тормоз и стояночный тормоз должен действовать на тормозящие поверхности, постоянно связанные с 
колесами при помощи достаточно прочных частей.</p>

<p style="text-indent:2em;"></p><h6>10. Никакая тормозящая поверхность не должна быть устроена таким образом, чтобы она могла отделяться от 
колес. Однако такое разъединение допускается для некоторых тормозящих поверхностей при условии, что :</h6><p></p>

<p>а) оно является кратковременным, например, при изменении передаточного числа;</p><br>

<p>b) в отношении стояночного тормоза оно может произойти лишь в результате действия водителя;</p><br>

<p style="text-indent:2em;">с) в отношении рабочего и аварийного тормоза торможение продолжается с предписанной эффективностью в 
соответствии с пунктом 5 настоящего приложения.</p><br>

<p style="font-weight:500;">В. Торможение прицепов</p>

<p style="text-indent:2em;"></p><h6>11. При условии соблюдения положений подпункта "с" пункта 17 настоящего приложения каждый прицеп, за 
исключением легких прицепов, должен иметь, в частности :</h6><p></p>

<p style="text-indent:2em;">а) рабочий тормоз, позволяющий замедлить движение транспортного средства и остановить его надежно, 
быстро и эффективно независимо от его условий загрузки и от того, движется ли транспортное средство 
на подъем или под уклон;</p><br>

<p style="text-indent:2em;">b) стояночный тормоз, позволяющий удержать транспортное средство в неподвижном состоянии, независимо 
от его условий загрузки на подъеме или спуске с уклоном 16%, причем тормозящие поверхности тормоза 
должны удерживаться в положении торможения посредством устройства чисто механического действия. 
Настоящее положение не распространяется на прицепы, которые не могут быть отцеплены от транспортного 
средства-тягача без помощи инструментов, при том условии, что требования, предъявляемые к стояночному 
торможению, будут соблюдаться в отношении состава транспортных средств.</p><br>

<p style="text-indent:2em;">12. Устройства, обеспечивающие выполнение этих двух функций торможения (рабочее и стояночное), могут 
иметь общие части.</p><br>

<p>13. Рабочий тормоз должен действовать на все колеса прицепа.</p><br>

<p style="text-indent:2em;">14. Рабочий тормоз должен приводиться в действие приводом рабочего тормоза транспортного средства-тягача: 
однако, если разрешенный максимальный вес прицепа не превышает 3500кг (7700 фунтов), рабочий тормоз может 
быть сконструирован таким образом, чтобы во время движения он приводился в действие лишь путем сближения 
прицепа с тягачем (торможение силой инерции).</p><br>

<p style="text-indent:2em;">15. Рабочий тормоз и стояночны тормоз должны действовать на тормозящие поверхности, постоянно связанные с 
колесами при помощи достаточно прочных частей.</p><br>

<p style="text-indent:2em;">16. Тормозящие устройства должны автоматически обеспечивать остановку прицепа в случае разрыва сцепного 
устройства во время движения. Однако это предписание не распространяется ни на одноосные, ни на двухосные 
прицепы, расстояние между осями которых составляет менее 1 м (40 дюймов), при условии, что их разрешенный 
максимальный вес превышает 1500кг (3300 фунтов) и что, за исключением полуприцепов, у них на ряду со сцепным 
устройством имеется дополнительное сцепное приспособление, предусмотренное в пункте 58 настоящего 
приложения.</p><br>

<p style="font-weight:500;">С. Торможение составов транспортных средств</p>

<p style="text-indent:2em;"></p><h6>17. В дополнение к содержащимся в разделах А и В настоящей главы положениям, касающимся несцепленных 
транспортных средств (автомобилей и прицепов), к составам сцепленных транспортных средств относятся 
следующие положения :</h6><p></p>

<p style="text-indent:2em;">а) тормозные устройства каждого транспортного средства, являющегося частью состава, должны быть 
совместимыми;</p><br>

<p style="text-indent:2em;">b) действие рабочего тормоза должно надлежащим образом распределяться и синхронизироваться по всему 
составу сцепленных транспортных средств;</p><br>

<p style="text-indent:2em;">с) разрешенный максимальный вес прицепа, не оборудованного рабочим тормозом, не должен превышать 
половины суммы веса транспортного средства-тягача без нагрузки и веса водителя.</p><br>

<p style="font-weight:500;">D. Торможение мотоциклов</p><br>

<p style="text-indent:2em;">18. а) Каждый мотоцикл должен иметь два тормозных устройства, одно из которых действует по крайней мере 
на заднее колесо, а другое - по крайней мере на переднее или передние колеса; если у мотоцикла имеется коляска, 
торможение колеса коляски не требуется. Эти тормозные устройства должны позволять замедлить движение мотоцикла и 
остановить его надежно, быстро и эффективно независимо от условий его загрузки и от того, движется ли он на 
подъем или под уклон.</p><br>

<p style="text-indent:2em;">b) В дополнение к положениям, содержащимся в подпункте "а" настоящего пункта, мотоциклы с тремя колесами, 
расположенными симметрично по отношению к среднему продольному сечению транспортного средства, должны быть 
оборудованы стояночным тормозом, соответствующим положениям подпункта "b" пункта 5 настоящего приложения.</p>

<p></p><h4>Глава II</h4><p></p>

<p style="font-weight:500;">ОГНИ И СВЕТООТРАЖАЮЩИЕ ПРИСПОСОБЛЕНИЯ</p>

<p style="text-indent:2em;"></p><h6>19. При применении положений настоящей Конвенции :</h6><p></p>

<p style="text-indent:2em;">термин "огонь дальнего света" означает огонь транспортного средства, предназначенный для освещения дороги на 
дальнее расстояние в направлении движения вперед;</p><br>

<p style="text-indent:2em;">термин "огонь ближнего света" означает огонь транспортного средства, предназначенный для освещения дороги впереди 
транспортного средства таким образом, чтобы не ослеплять чрезмерно и не причинять неудобств водителям встречных 
транспортных средств и другим пользователям дороги;</p><br>

<p style="text-indent:2em;">термин "подфарник" означает огонь транспортного средства, предназначенный для сигнализации спереди транспортного 
средства и его габаритной ширины;</p><br>

<p style="text-indent:2em;">термин "задний габаритный фонарь" означает огонь транспортного средства, предназначенный для сигнализации сзади 
транспортного средства и его габаритной ширины;</p><br>

<p style="text-indent:2em;">термин "стоп - сигнал" означает огонь транспортного средства, предназначенный для сигнализации другим пользователям 
дороги, находящимся сзади транспортного средства, что водитель, привел в действие рабочий тормоз;</p><br>

<p style="text-indent:2em;">термин "противотуманная фара " означает огонь транспортного средства, предназначенный для улучшения освещенности 
дороги в туман, снегопад, ливень и пылевую бурю;</p><br>

<p style="text-indent:2em;">термин "задняя фара" означает огонь транспортного средства, предназначенный для освещения дороги сзади транспортного 
средства и подачи предупреждающего сигнала другим пользователям дороги, когда транспортное средство дает или 
намеревается дать задний ход;</p><br>

<p style="text-indent:2em;">термин "указатель поворота" означает огонь транспортного средства, предназначенный для сигнализации другим 
пользователям дороги намерения водителя свернуть вправо или влево;</p><br>

<p style="text-indent:2em;">термин "светоотражающее приспособление" означает огонь транспортного средства, предназначенный для сигнализации 
наличия транспортного средства посредством отражения света, излучаемого источником света, не связанным с этим 
транспортным средством, причем наблюдатель находится вблизи этого источника;</p><br>

<p style="text-indent:2em;">термин "освещающая поверхность" означает в отношении огней видимую поверхность, с которой излучается свет, а в 
отношении светоотражающих приспособлений - видимую поверхность, от которой отражается свет.</p><br>

<p style="text-indent:2em;">20. Цвета указанных в настоящей главе огней должны по мере возможности соответствовать определениям, содержащимся 
в добавлении к настоящему приложению.</p><br>

<p style="text-indent:2em;">21. Каждый автомобиль, кроме мотоциклов, скорость которого по ровной дороге может превышать 40км (25 миль) в час, 
должен иметь спереди четное число белых или желтых селективных огней дальнего света, способных надлежащим образом 
освещать дорогу в ясную погоду ночью на расстояние по крайней мере 100м (325 футов) впереди транспортного средства. 
Внешние края освещающих поверхностей огней дальнего света ни в коем случае не должны быть расположены ближе к краю 
габаритной ширины транспортного средства, чем внешние края освещающей поверхности огней ближнего света.</p><br>

<p style="text-indent:2em;">22. Каждый автомобиль, кроме мотоциклов, скорость которого на ровной дороге может превышать 10км (6 миль) в час, 
должен быть снабжен спереди двумя белыми или желтыми селективными огнями ближнего света, способными надлежащим образом 
освещать дорогу в ясную погоду ночью на расстояние по крайней мере 40 м (130 футов) впереди транспортного средства. 
С каждой стороны точка освещающей поверхности, наиболее удаленная от среднего продольного сечения транспортного средства, 
должна находиться на расстоянии не более 0,4 м (16 дюймов) от края габаритной ширины транспортного средства. Автомобиль не 
должен иметь более двух огней ближнего света. Огни ближнего света должны быть отрегулированы таким образом, чтобы они 
соответствовали определению, изложенному в пункте 19 настоящего приложения.</p><br>

<p style="text-indent:2em;">23. Каждый автомобиль, иной, чем двухколесный мотоцикл без коляски, должен иметь два подфарника белого цвета; однако 
селективный желтый цвет допускается для подфарников, совмещенных с фарами дальнего или ближнего света, излучающими пучки 
селективного желтого цвета. Если эти подфарники являются единственными огнями, включенными спереди транспортного средства, 
они должны быть видимыми ночью в ясную погоду на расстоянии по крайней мере 300м (1000 футов), не ослепляя и не причиняя 
излишних неудобств другим пользователям дороги. С каждой стороны точка освещающей поверхности, наиболее удаленная от среднего 
продольного сечения транспортного средства, должна находиться на расстоянии не более 0,4 м (16 дюймов) от края габаритной ширины 
транспортного средства.</p><br>

<p style="text-indent:2em;">24. а) Каждый автомобиль, иной, чем двухколесный мотоцикл без коляски, должен иметь сзади четное число задних габаритных 
фонарей красного цвета, видимых в ясную погоду ночью на расстоянии по крайней мере 300м (1000 футов), не ослепляя и не причиняя
 излишних неудобств другим пользователям дороги. С каждой стороны точка освещающей поверхности, наиболее удаленная от среднего 
 продольного сечения транспортного средства, должна находиться на расстоянии не более 0,4 м (16 дюймов) от края габаритной ширины
 транспортного средства.</p><br>

<p style="text-indent:2em;">b) Каждый прицеп должен иметь сзади четное число задних габаритных фонарей красного цвета, видимых в ясную погоду ночью на 
расстоянии по крайней мере 300м (1000 футов), не ослепляя и не причиняя излишних неудобств другим пользователям дороги. 
С каждой стороны точка освещающей поверхности, наиболее удаленная от среднего продольного сечения прицепа, должна находиться 
на расстоянии не более 0,4 м (16 дюймов) от края габаритной ширины прицепа. Однако прицепы, габаритная ширина которых не превышает
 0,80 м (32 дюйма), могут иметь лишь один из этих огней, если они буксируются двух колесным мотоциклом без коляски.</p><br>

<p style="text-indent:2em;">25. Каждый автомобиль или прицеп, имеющий сзади номерной знак, должен быть снабжен приспособлением для освещения этого знака, 
установленным таким образом, чтобы освещаемый этим приспособлением номерной знак был удобочитаем в ясную погоду ночью при 
остановленном транспортном средстве с расстояния в 20м (65 футов) сзади транспортного средства; однако каждая Договаривающаяся
 сторона может уменьшить это минимальное расстояние удобочитаемости ночью в тех же пределах и для тех же транспортных средств,
 для которых она в соответствии с пунктом 2 приложения 2 к настоящей Конвенции уменьшит минимальное расстояние удобочитаемости
 днем.</p><br>

<p style="text-indent:2em;">26. На каждом автомобиле, включая мотоциклы, и на каждом составе транспортных средств, включающем автомобиль и один или несколько
 прицепов, электрические соединения должны быть установлены таким образом, чтобы огни дальнего света, огни ближнего света,
 противотуманные фары, подфарники автомобилей и упомянутое выше, в пункте 25, приспособление могли включаться лишь при одновременном
 включении задних габаритных фонарей автомобиля или последнего транспортного средства, 
 включенного в состав.</p><br>

<p style="text-indent:2em;">Однако это положение не относится к огням дальнего или ближнего света, если они применяются для подачи световых сигналов, указанных 
в пункте 5 статьи 33 настоящей Конвенции. Кроме того, электрические соединения должны устроены таким образом, чтобы подфарники 
автомобилей всегда включались при включении огней ближнего света, огней дальнего света или 
противотуманных фар.</p><br>

<p style="text-indent:2em;">27. Каждый автомобиль, кроме двухколесного мотоцикла без коляски, должен иметь сзади по крайней мере два красных светоотражающих 
приспособления нетреугольной формы. С каждой стороны край освещающей поверхности, наиболее удаленный от среднего продольного
 сечения транспортного средства, должен находиться на расстоянии не более 0,4 м (16 дюймов) от края габаритной ширины транспортного
 средства. Светоотражающие приспособления должны быть видимыми водителю транспортного средства в ясную погоду ночью на расстоянии по
 крайней мере 150м (500 футов), когда они освещены дальним светом этого транспортного средства.</p><br>

<p style="text-indent:2em;">28. Каждый прицеп должен иметь сзади по крайней мере два красных светоотражающих приспособления. Эти светоотражающие приспособления
 должны иметь форму равностороннего треугольника с вершиной, обращенной кверху, и одной горизонтальной стороной, причем длина каждой
 стороны составляет не менее 0,15м (6 дюймов) и не более 0,2 м (8 дюймов); никакой сигнальный огонь не должен помещаться внутри этого
 треугольника. Указанные светоотражающие приспособления должны отвечать в отношении видимости требованиям, изложенным выше, в пункте 27.
 С каждой стороны край освещающей поверхности, наиболее удаленной от среднего продольного сечения прицепа, должен находиться на расстоянии
 не более 0,4 м (16 дюймов) от края габаритной ширины прицепа. Однако прицепы, габаритная ширина которых не превышает 0,8м (32 дюйма),
 могут иметь только одно светоотражающее приспособление, если они буксируются двухколесным 
 мотоциклом без коляски.</p><br>

<p style="text-indent:2em;">29. Каждый прицеп должен иметь спереди два белых светоотражающих приспособления нетреугольной формы. Эти светоотражающие приспособления
 должны удовлетворять условиям размещения и видимости, изложенным выше, в пункте 27.</p><br>

<p style="text-indent:2em;">30. Прицеп должен иметь два белых подфарника, если его ширина превышает 1,6м (5 футов 4 дюйма). Предписанные таким образом подфраники
 должны быть расположены возможно ближе к краю габаритной ширины прицепа и во всяком случае так, чтобы внешний край освещенной поверхности,
 наиболее удаленный от среднего продольного сечения прицепа, находился на расстоянии не более 
 0,15м (6 дюймов) от этого края.</p><br>

<p style="text-indent:2em;">31. Каждый автомобиль (кроме двухколесных мотоциклов с коляской или без нее), скорость которого по ровной дороге может превышать 25км
 (15 миль) в час, должен иметь сзади два красных стоп-сигнала, светосила которых явно превосходит светосилу задних габаритных фонарей.
 Это же положение относится к каждому прицепу, находящемуся в конце состава транспортных средств; однако стоп-сигналы не являются
 обязательными для небольших прицепов, размеры которых таковы, что стоп-сигналы тягача остаются 
 видимыми.</p>

<p style="text-indent:2em;"></p><h6>32. С оговоркой о том, что Договаривающиеся стороны, сделавшие в соответствии с пунктом 2 статьи 54 настоящей Конвенции заявление о
 приравнивании велосипедов с подвесным двигателем к мотоциклам, могут освободить велосипеды с подвесным двигателем от соблюдения
 всех нижеследующих положений или их части:</h6><p></p>

<p style="text-indent:2em;">а) каждый двухколесный мотоцикл с коляской или без нее должен быть снабжен фарой ближнего света, отвечающей в отношении цвета и
 видимости предписаниям, содержащимся в пункте 22 настоящего приложения;</p><br>

<p style="text-indent:2em;">b) каждый двухколесный мотоцикл с коляской и без нее, скорость которого на ровной дороге может превышать 40 км (25 миль) в час,
 должен иметь, помимо фары ближнего света, по крайней мере одну фару дальнего света, отвечающую в отношении цвета и видимости предписаниям,
 содержащимся в пункте 21 настоящего приложения. Если такой мотоцикл имеет более одной фары дальнего света, эти фары должны располагаться
 возможно ближе друг к другу;</p><br>

<p style="text-indent:2em;">с) двухколесный мотоцикл с коляской или без нее должен иметь не более одной фары ближнего света и не более двух фар дальнего 
света.</p><br>

<p style="text-indent:2em;">33. Каждый двухколесный мотоцикл без коляски может быть снабжен спереди одним или двумя подфарниками, отвечающими в отношении цвета
 и видимости предписаниям, содержащимся в пункте 23 настоящего приложения. Если такой мотоцикл имеет два подфарника, эти подфарники
 должны располагаться возможно ближе друг к другу. Двухколесный мотоцикл без коляски 
 не должен иметь более двух подфарников.</p><br>

<p style="text-indent:2em;">34. Каждый двухколесный мотоцикл без коляски должен иметь один задний габаритный фонарь, отвечающий в отношении цвета и видимости 
предписаниям, содержащимся в подпункте "а" пункта 24 настоящего приложения.</p><br>

<p style="text-indent:2em;">35. Каждый двухколесный мотоцикл без коляски должен иметь сзади одно светоотражающее приспособление, удовлетворяющее в отношении 
цвета и видимости предписаниям, содержащимся в пункте 27 настоящего приложения.</p><br>

<p style="text-indent:2em;">36. Каждый двухколесный мотоцикл с коляской или без нее должен иметь стоп-сигнал, удовлетворяющий положениям пункта 31 настоящего 
приложения, с оговоркой о том, что Договаривающиеся стороны, сделавшие в соответствии с пунктом 2 статьи 54 настоящей Конвенции заявление
 о приравнивании велосипедов с подвесным двигателем к мотоциклам, могут не распространять это предписание на двухколесные велосипеды с 
 подвесным двигателем с коляской или без нее.</p><br>

<p style="text-indent:2em;">37. Без ущерба для положений, касающихся огней и приспособлений, предписанных для мотоциклов без коляски, каждая коляска, соединенная с
 двухколесным мотоциклом, должна иметь один подфарник, отвечающий в отношении цвета и видимости предписаниям, содержащимся в пункте 23 
 настоящего приложения, один задний габаритный фонарь, отвечающий в отношении цвета и видимости предписаниям, содержащимся в подпункте 
 "а" пункта 24 настоящего приложения, и одно светоотражающее приспособление, отвечающее в отношении цвета и видимости предписаниям, 
 содержащимся в пункте 27 настоящего приложения. Электрические соединения должны быть устроены таким образом, чтобы подфарник и задний
 габаритный фонарь коляски включались одновременно с задним габаритным фонарем мотоцикла. Во всяком случае коляска не должна иметь фар
 дальнего и ближнего света.</p><br>

<p style="text-indent:2em;">38. Автомобили с тремя колесами, симметричными по отношению к среднему продольному сечению транспортного средства, приравненные к 
мотоциклам в соответствии с подпунктом "n" статьи 1 Конвенции, должны быть снабжены приспособлениями, предписываемыми в пунктах 21,
 22, 23, 24, "а", 27 и 31 настоящего приложения. Однако, если ширина транспортного средства не превышает 1,30м (4 фута 3 дюйма),
 достаточно одной фары дальнего света и одной фары ближнего света. В этом случае не применяются предписания относительно расстояния
 освещающей поверхности по отношению к краю габаритной ширины транспортного средства.</p><br>

<p style="text-indent:2em;">39. Каждый автомобиль, за исключением тех, водители которых могут подать рукой сигналя об изменении направления движения, видимые во
 всех направлениях для других пользователей дороги, должны иметь неподвижные указатели поворота с мигающими огнями автожелтого цвета
 , расположенные попарно на транспортном средстве и видимые днем и ночью пользователями дороги. Частота мигания этих огней должна
 составлять 90 миганий в минуту с допуском ё 30.</p><br>

<p style="text-indent:2em;">40. Если на автомобиле, ином, чем двухколесный мотоцикл с коляской или без коляски, имеются противотуманные фары, они должны быть
 белого или желтого цвета в количестве двух и устанавливаться таким образом, чтобы ни одна точка их освещающей поверхности не
 находилась выше самой высокой точки освещающей поверхности огней ближнего света и чтобы с каждой стороны точка освещающей поверхности,
 наиболее удаленная от среднего продольного сечения транспортного средства, находилась на расстоянии не более 0,4м ( 16 дюймов) от края 
 габаритной ширины транспортного средства.</p><br>

<p style="text-indent:2em;">41.Никакая задняя фара не должна приводить к ослеплению или создавать неудобства для других пользователей дороги. Если автомобиль 
оборудован задней фарой, то эта фара должна излучать белый, автожелтый или желтый селективный свет. Приспособления для ее включения
 должно быть устроено таким образом, чтобы она могла включаться только тогда, когда приведено в действие приспособление заднего 
 хода.</p><br>

<p style="text-indent:2em;">42. Никакой огонь, иной, чем огни указателей поворота, установленные на автомобиле или прицепе, не должны быть мигающими, за
 исключением огней, используемых в соответствии с национальным законодательством Договаривающихся сторон для обозначения транспортных 
 средств или составов транспортных средств, которые не обязаны соблюдать общие правила дорожного движения или наличие которых на дороге 
 обязывает других пользователей дороги соблюдать особую осторожность; такими транспортными средствами, в частности, являются транспортные
 средства, имеющие преимущественное право проезда, автопоезда, транспортные средства, имеющие особые размеры, и транспортные средства или
 оборудование, использующиеся для строительства или ремонта дорог. Однако Договаривающиеся стороны могут разрешить или предписать, чтобы 
 некоторые огни или часть этих огней мигали, за исключением огней, излучающих красный свет, для предупреждения об особой опасности, которую
 временно представляет собой транспортное средство.</p>

<p style="text-indent:2em;"></p><h6>43. При применении положений настоящего приложения следует рассматривать:</h6><p></p>

<p style="text-indent:2em;">а) как один огонь любое сочетание двух или более огней, тождественных или нет, но имеющих одинаковое назначение и одинаковый цвет
, если проекция их освещающих поверхностей на вертикальную плоскость, перпендикулярную среднему продольному сечению транспортного
 средства, занимает не менее 50% поверхности наименьшего прямоугольника, описывающего проекции 
 указанных освещающих поверхностей;</p><br>

<p style="text-indent:2em;">b) как два огня или парное число огней единую освещающую поверхность в форме полосы при условии, что она расположена симметрично 
по отношению к среднему продольному сечению транспортного средства, что она находится по крайней мере на расстоянии 0,4м (16 дюймов
) от края габаритной ширины транспортного средства и ее длина составляет по меньшей мере 0,8м (32 дюйма). Освещение этой поверхности
 должно осуществляться по крайней мере двумя источниками света, находящимися возможно ближе к ее краям. Освещающая поверхность может
 включать ряд элементов, расположенных таким образом, что проекция освещающих поверхностей различных элементов на вертикальную плоскость,
 перпендикулярную среднему продольному сечению транспортного средства, занимает не менее 50% наименьшего прямоугольника, описывающего 
 проекции указанных освещающих поверхностей элементов.</p><br>

<p style="text-indent:2em;">44. На одном и том же транспортном средстве огни, имеющие одинаковое назначение и направленные в одну и ту же сторону, должны быть 
одинакового цвета. Огни и светоотражающие приспособления, число которых является четным, должны быть расположены симметрично по отношению 
к среднему продольному сечению транспортного средства, за исключением транспортных средств, внешняя форма которых не является симметричной.
 Каждая пара огней должна иметь явно одинаковую светосилу.</p><br>

<p style="text-indent:2em;">45. Различные по своему назначению огни и - при условии соблюдения положений других пунктов настоящей главы - огни и светоотражающие 
приспособления могут быть вмонтированы в один световой прибор или соединены в нем при условии, что каждый из этих огней и светоотражающих 
приспособлений соответствует относящимся к нему положениям настоящего приложения.</p>

<p></p><h4>Глава III</h4><p></p>

<p style="font-weight:500;">ДРУГИЕ ПРЕДПИСАНИЯ</p><br>

<p>Рулевое управление</p><br>

<p style="text-indent:2em;">46. Каждый автомобиль должен иметь прочное рулевое устройство, позволяющее водителю легко, быстро и уверенно менять направление движения
 транспортного средства.</p><br>

<p>Зеркало заднего вида</p><br>

<p style="text-indent:2em;">47. Каждый автомобиль, иной, чем двухколесный мотоцикл с коляской или без нее, должен иметь одно или несколько зеркал заднего вида; количество,
 размер и расположение этих зеркал должны быть таковы, чтобы водитель мог видеть дорожное движение позади своего 
 транспортного средства.</p><br>

<p>Звуковой сигнальный прибор</p><br>

<p style="text-indent:2em;">48. Каждый автомобиль должен иметь по крайней мере один звуковой сигнальный прибор достаточной мощности. Звук, издаваемый сигнальным прибором,
 должен быть продолжительным, однотонным и нерезким. Транспортные средства, имеющие преимущественное право проезда, и пассажирские транспортные
 средства общего пользования могут иметь дополнительные звуковые сигнальные приборы, не отвечающие этим требованиям.</p><br>

<p>Стеклоочиститель</p><br>

<p style="text-indent:2em;">49. Каждый автомобиль, снабженный ветровым стеклом, размеры и форма которого таковы, что водитель может свободно видеть со своего места дорогу
 впереди лишь через прозрачные элементы этого ветрового стекла, должен иметь по крайней мере один эффективный и прочный стеклоочиститель, который
 , находясь в соответствующем положении, действует, не требуя постоянного вмешательства водителя.</p><br>

<p>Стеклоомыватель</p><br>

<p style="text-indent:2em;">50. Каждый автомобиль, который в обязательном порядке должен иметь по крайней мере один стеклоочиститель, 
должен иметь и стеклоомыватель.</p><br>

<p>Ветровое стекло и стекла</p>

<p style="text-indent:2em;"></p><h6>51. На каждом автомобиле и на каждом прицепе:</h6><p></p>

<p style="text-indent:2em;">а) прозрачные материалы, из которых изготовлены внешние элементы транспортного средства, включая ветровое стекло, или элементы внутренней
 перегородки, должны быть такими, чтобы в случае их повреждения опасность нанесения увечий была сведена до минимума;</p><br>

<p style="text-indent:2em;">b) ветровое стекло должно быть сделано из материала, прозрачность которого не ухудшается, и должно быть таким, чтобы оно не вызывало никакого
 значительного искажения видимых через него предметов и чтобы в случае его повреждения водитель имел возможность видеть в достаточной мере
 дорогу.</p><br>

<p>Приспособление для движения задним ходом</p><br>

<p style="text-indent:2em;">52. Каждый автомобиль должен быть снабжен приспособлением для движения задним ходом, приводимым в действие с места водителем. Однако это
 приспособление является обязательным для мотоциклов и автомобилей с тремя колесами, расположенными симметрично по отношению к среднему
 продольному сечению транспортного средства, только в том случае, если их разрешенным максимальный вес превышает 
 400кг (900 фунтов).</p><br>

<p>Глушитель</p><br>

<p style="text-indent:2em;">53. Каждый двигатель внутреннего сгорания, установленный на автомобиле, должен быть снабжен эффективным глушителем; это приспособление должно
 быть устроено таким образом, чтобы водитель не мог со своего места вывести его в нерабочее состояние.</p><br>

<p>Шины</p><br>

<p style="text-indent:2em;">54. Колеса автомобилей и их прицепов должны быть снабжены пневматическими шинами, причем состояние этих шин должно быть таким, чтобы
 обеспечивалась безопасность и сцепление с грунтом даже на мокрой дороге. Однако настоящее положение не препятствует Договаривающимся 
 сторонам разрешить использование приспособлений, которые могут дать результаты, по крайней мере эквивалентные результатам, полученным
 при использовании пневматических шин.</p><br>

<p>Спидометр</p><br>

<p style="text-indent:2em;">55. Каждый автомобиль, скорость которого по равной дороге может превышать 40 км (25 миль) в час, должен иметь спидометр; однако каждая
 Договаривающаяся сторона может не распространить этого предписания на некоторые категории мотоциклов и других легких транспортных 
 средств.</p><br>

<p>Приспособления для сигнализации на автомобилях</p>

<p style="text-indent:2em;"></p><h6>56. Приспособление, упомянутое в пункте 5 статьи 23 и в пункте 6 приложения 1 к 
настоящей Конвенции, должно быть :</h6><p></p>

<p style="text-indent:2em;">а) Либо щитком, имеющим форму равностороннего треугольника со сторонами длиной не менее 0,4м (16 дюймов) и красными краями шириной не
 менее 0,05м (2 дюйма); середина этого треугольника может быть полой или закрашенной в светлый цвет; красные края должны либо пропускать
 свет в силу своей прозрачности, либо иметь полосу, обладающую светоотражательными свойствами; щиток должен быть приспособлен для того,
 чтобы его можно было поставить в устойчивое вертикальное положение;</p><br>

<p style="text-indent:2em;">b) либо другим столь же эффективным приспособлением, предписанным законодательством страны, в которой зарегистрировано транспортное
 средство.</p><br>

<p>Противоугонное устройство</p><br>

<p style="text-indent:2em;">57. Каждый автомобиль должен быть снабжен противоугонным устройством, позволяющим с момента оставления транспортного средства на стоянке
 вывести из строя или заблокировать какой-либо основной агрегат транспортного средства.</p><br>

<p>Сцепное устройство легких прицепов</p><br>

<p style="text-indent:2em;">58. За исключением полуприцепов, прицепы, которые не оборудованы пневматическим тормозом, указанным в пункте 16 настоящего приложения,
 должны быть оборудованы, помимо сцепного устройства, дополнительным сцепным приспособлением (цепь, трос и т.п.), которое в случае поломки
 сцепного устройства могло бы предотвратить падение дышла на землю и обеспечить в определенной 
 степени частичное управление прицепом.</p><br>

<p>Общие положения</p><br>

<p style="text-indent:2em;">59. а) Механизмы и приспособления автомобиля по возможности не должны представлять опасности пожара или взрыва; они не должны также 
выделять в чрезмерном количестве вредные газы, плотный дым, издавать неприятный запах и производить шум.</p><br>

<p style="text-indent:2em;">b) Система зажигания высокого напряжения двигателей автомобилей по возможности не должна являться источником чрезвычайного 
распространения существенных радиоэлектрических помех.</p><br>

<p style="text-indent:2em;">с) Конструкция каждого автомобиля должна обеспечивать водителю спереди, справа и слева достаточную обзорность, позволяющую ему 
безопасно управлять автомобилем.</p><br>

<p style="text-indent:2em;">d) Конструкция и оборудование автомобилей и прицепов должны по возможности обеспечивать уменьшение опасности для их пассажиров и
 для других пользователей дороги в случае дорожно-транспортного происшествия.В частности, транспортное средство не должно иметь ни
 внутри, ни снаружи никаких украшений или других не являющихся необходимыми предметов, имеющих острые края или выходящих за габарит
 транспортного средства, которые могут представлять опасность для водителей и пассажиров и для других пользователей дороги.</p>

<p></p><h4>Глава IV</h4><p></p>

<p style="font-weight:500;">ОТСТУПЛЕНИЯ ОТ ПРАВИЛ</p>

<p style="text-indent:2em;"></p><h6>60. При применении настоящей Конвенции на своей территории Договаривающиеся стороны могут допускать отступления от положений
 настоящего приложения в отношении :</h6><p></p>

<p style="text-indent:2em;">а) автомобилей и прицепов, конструктивная скорость которых на ровной дороге не может превышать 25 км (15 миль) в час или скорость
 которых согласно национальному законодательству не должна превышать 25 км в час;</p><br>

<p style="text-indent:2em;">b) инвалидных колясок, то есть маленьких автомобилей, которые специально сконструированы и предназначены - а не только
 приспособлены - для использования лицом, имеющим увечье или физический недостаток, и которые обычно используются только
 этим лицом;</p><br>

<p style="text-indent:2em;">с) транспортных средств, предназначенных для экспериментов в целях применения достижений техники и повышения безопасности;</p><br>

<p style="text-indent:2em;">d) транспортных средств особой формы или особого типа или транспортных средств, используемых для специальных целей в особых
 условиях.</p>

<p style="text-indent:2em;"></p><h6>61. Договаривающиеся стороны могут даже допускать отступления от положений настоящего приложения в отношении регистрируемых
 ими транспортных средств, которые могут находиться в международном движении:</h6><p></p>

<p style="text-indent:2em;">а) разрешая автожелтый цвет для указанных в пунктах 23 и 30 настоящего приложения подфарников и указанных в пункте 29 настоящего
 приложения светоотражающих приспособлений;</p><br>

<p style="text-indent:2em;">b) разрешая красный цвет для указанных в пункте 39 настоящего приложения световых указателей поворота, устанавливаемых сзади
 транспортного средства;</p><br>

<p style="text-indent:2em;">с) разрешая красный цвет для указанных в последней фразе пункта 42 настоящего приложения огней, устанавливаемых сзади
 транспортного средства;</p><br>

<p style="text-indent:2em;">d) в отношении расположения огней на специализированных транспортных средствах, внешняя форма которых не позволяет соблюдать
 эти положения, не прибегая к монтажным приспособлениям, которые могут быть легко повреждены или сорваны;</p><br>

<p>е) разрешая наличие нечетного - более двух - числа огней дальнего света на регистрируемых ею автомобилях; и</p><br>

<p style="text-indent:2em;">f) в отношении прицепов, которые служат для перевозки длинномерных грузов (бревен, труб и т.д.) и которые при движении не 
сцепляются с тягачом, а связаны с ним только грузом.</p>

<p></p><h4>Глава V</h4><p></p>

<p style="font-weight:500;">ПЕРЕХОДНЫЕ ПОЛОЖЕНИЯ</p><br>

<p style="text-indent:2em;">62. Положения настоящего приложения не распространяются на автомобили, которые впервые зарегистрированы, и на прицепы
, которые сданы в эксплуатацию на территории Договаривающейся стороны до вступления в силу настоящей Конвенции или два
 года спустя после ее вступления в силу, при условии, что эти автомобили и прицепы отвечают предписаниям частей I, II 
 и III приложения 6 Конвенции 1949 года о дорожном движении.</p><br>

<p style="text-indent:2em;">1 В этих случаях приняты приделы, иные, чем рекомендованные МКО, ввиду того что напряжение питания у зажимов ламп, 
которыми оборудованы огни, колеблется весьма значительно.</p><br>

<p style="text-indent:2em;">2 Применяется к цвету автомобильных огней, который до настоящего времени назывался "оранжевым" или "желто-оранжевым". 
Соответствует вполне определенной части "желтой" зоны цветного треугольника МКО.</p><br>

<p style="text-indent:2em;">3 Применяется исключительно к фарам дальнего и ближнего света. Что касается противотуманных фар, то селективность цвета
 будет считаться удовлетворительной, если показатель частоты будет равен не менее 0,820, а предел в сторону белого у&gt;= -
 х+0,966, составив в этом случае у &gt;= -х+0,940 и у=0,440.</p><br>

<p style="font-weight:500;">Приложение 6</p><br>

<p style="font-weight:500;">Национальное водительское удостоверение</p><br>

<p style="text-indent:2em;">1. Национальное водительское удостоверение представляет собой листок розового цвета формата А 7 (74х105 мм - 2,91х4,13 
дюйма), либо сложенный вдвое по этому формату (148х105 мм - 5,82х4,13 дюйма), либо сложенный втрое по указанному формату 
(222х105 мм - 8,78х4,13 дюйма) листок.</p><br>

<p style="text-indent:2em;">2. Удостоверение печатается на языке или языках, установленных органом власти, который выдает или которому поручено 
выдавать этот документ; однако на нем обозначается на французском языке название "Permis de conduire", которое может 
сопровождаться или не сопровождаться названием "Водительское удостоверение" на других языках.</p><br>

<p style="text-indent:2em;">3. Вносимые в удостоверение от руки или отпечатанные на машинке записи делаются либо только буквами латинского алфавита
 или прописью, либо повторяются таким образом.</p><br>

<p style="text-indent:2em;">4. Две из страниц удостоверения должны соответствовать прилагаемым образцам N 1 и 2. При условии, что не будут изменены
 ни содержание рубрик А, В, С, D и Е, исходя из положений пункта 4 статьи 41 настоящей Конвенции, ни их буквенные обозначения,
 ни существо рубрик, касающихся лично владельца удостоверения, настоящее положение будет считаться выполненным даже в том случае,
 если в указанные образцы будут внесены изменения второстепенного характера; в частности национальные водительские удостоверения,
 соответствующие образцу приложения 9 к настоящей Конвенции о дорожном движении, составленной в Женеве 19 сентября 1949 года, будут
 рассматриваться как удовлетворяющие положениям настоящего приложения.</p><br>

<p style="text-indent:2em;">5. Вопрос о включении в удостоверение страницы образца N 3 и о включении в него дополнительных записей определяется национальным
 законодательством; если предусматривается место для указания изменения местожительства, оно будет находиться вверху оборотной
 стороны страницы N 3, за исключением удостоверения, соответствующего образцу приложения 9 Конвенции 1949 года.</p><br>

<p style="font-weight:500;">Сноски к таблице</p><br>

<p style="text-indent:2em;">1 На удостоверениях, сложенных вдвое (если они складываются так, что их первая страница не соответствует образцу), и на
 удостоверениях, сложенных втрое, указанная надпись может делаться на первой странице.</p><br>

<p style="text-indent:2em;">2 Здесь указывается название или отличительный знак государства, определенный в соответствии с приложением 3 к настоящей
 Конвенции. Указание, содержащееся выше, в примечании 1, относится также к этой рубрике.</p><br>

<p>3 Здесь можно указать имя отца или мужа.</p><br>

<p>4 Если дата рождения неизвестна, следует указать приблизительный возраст в момент выдачи удостоверения.</p><br>

<p>5 Не заполнять, если место рождения неизвестно.</p><br>

<p>6 "Или отпечаток большого пальца руки". Подпись и отпечаток большого пальца руки могут не включаться.</p><br>

<p>7 Эта рубрика обязательна в удостоверениях, имеющих страницу, соответствующую образцу N 3.</p><br>

<p style="text-indent:2em;">8 Подпись и/или печать или штемпель органа, выдавшего удостоверение, или уполномоченного на то объединения. На удостоверениях
, сложенных вдвое (если они складываются так, что их первая страница не соответствует образцу), и на удостоверениях, сложенных 
втрое, печать или штемпель могут ставиться на первой странице.</p><br>

<p style="text-indent:2em;">9 Печать или штемпель органа, выдавшего удостоверение, и в соответствующих случаях дата проставления печати или штемпеля. 
Указанная печать или штемпель, ставятся в первой колонке образца страницы N 2 рядом с графами определений категорий транспортных 
средств, для которых действительно удостоверение, и только рядом с этими графами; аналогичные положения применяются к отметкам о 
продлении, которые следует внести в первую колонку образца страницы N 3. Вместо того чтобы проставлять в первой колонке образца 
страницы N 2 печать или штемпель, органа, выдавшего удостоверение. Договаривающиеся стороны могут вписать в новую рубрику 8 
"Категории" на образце страницы N 1 букву или буквы, соответствующие категории или категориям, для которых действительно удостоверение,
 и звездочку для каждой категории, для которой удостоверение недействительно (например. "8. Категория А, В ***").</p><br>

<p style="text-indent:2em;">10 Место, предназначенное для других категорий транспортных средств, определенных национальным законодательством.</p><br>

<p style="text-indent:2em;">11 Место для дополнительных замечаний, которые могут быть сделаны в соответствующих случаях компетентными органами государства,
 выдавшего удостоверение, включая ограничительные условия пользования (например, "Управление только в очках", "Действительно только 
 для управления транспортным средством N ....", " При условии переоборудования транспортного средства для управления лицом, лишенным 
 одной ноги").</p><br>

<p style="text-indent:2em;">В случае предусмотренном во втором абзаце примечания 9, предпочтительно проставлять эти дополнительные указания на образце страницы N 1
. Другие дополнительные указания могут быть сделаны на страницах, не соответствующих образцу.</p><br>

<p style="font-weight:500;">Приложение 7</p><br>

<p style="font-weight:500;">Международное водительское удостоверение</p><br>

<p style="text-indent:2em;">1. Международное водительское удостоверение представляет собой книжку формата А 6 (148х105 мм - 5,82х4,13 дюйма). Она имеет серую 
обложку и белые внутренние страницы.</p><br>

<p style="text-indent:2em;">2. Лицевая и внутренняя стороны первого листа обложки идентичны, соответственно, прилагаемым образцам страниц N 1 и 2; они печатаются
 на национальном языке или по крайней мере на одном из национальных языков государства, в котором выдано удостоверение. В конце 
 внутренних страниц две соседние страницы соответствуют прилагаемому образцу N 3 и печатаются на французском языке. На предшествующих
 им внутренних страницах повторяется на нескольких языках, их которых обязательными являются английский, испанский и русский языки,
 первая из этих двух страниц.</p><br>

<p style="text-indent:2em;">3. Вносимые в удостоверение от руки или отпечатанные на машинке записи делаются буквами латинского алфавита или прописью.</p><br>

<p style="text-indent:2em;">4. Договаривающиеся стороны, выдающие или разрешающие выдавать международные водительские удостоверения, обложка которых печатается
 на ином языке, чем английский, испанский, русский и французский, должны направить Генеральному Секретарю Организации Объединенных 
 Наций перевод текста прилагаемого образца N 3 на этот язык.</p><br>
   
<p style="text-indent:2em;">1 Название государства, в котором выдано удостоверение, и отличительный знак этого государства, определенный в приложении 3.</p><br>

<p style="text-indent:2em;">2 Три года с момента выдачи или дата истечения срока действия национального водительского удостоверения, в зависимости от того,
 какой срок наступит скорее.</p><br>

<p>3 Подпись органа власти или объединения, выдавшего удостоверение.</p><br>

<p>4 Печать или штемпель органа власти или объединения, выдавшего удостоверение.</p><br>

<p>Оно действительно на территории  всех  других  Договаривающихся сторон.</p><br>
   
<p>Категории транспортных средств, на управление которыми оно дает право, указаны в конце книжки.</p><br>
   
<p style="text-indent:2em;">Настоящее удостоверение не освобождает владельца от неукосни- тельного соблюдения на территории любого государства, по которому он
 ездит, законов и правил, касающихся права на обоснование или занятие какой-либо профессией. В частности, оно теряет свою силу в госу
 - дарстве, которое становится обычным местожительством его владельца.</p><br>

<p style="text-indent:2em;">Здесь указывается название государства, являющегося Догова- ривающейся стороной, в котором его владелец имеет
 обычное местожи- тельство.</p><br>

<p style="text-indent:2em;">2 Место, предназначенное для необязательного включения списка государств, являющихся Договаривающимися сторонами.</p><br>

<p><a href="http://pravo.kulichki.com/megd2007/dokz32/str1.htm">Источник</a></p>

</div>
`