import { int1 } from "./pages-ro/int1"
import { int2 } from "./pages-ro/int2"
import { int3 } from "./pages-ro/int3"
import { int4 } from "./pages-ro/int4"
import { int5 } from "./pages-ro/int5"
import { int6 } from "./pages-ro/int6"
import { int7 } from "./pages-ro/int7"
import { int8 } from "./pages-ro/int8"
import { int9 } from "./pages-ro/int9"
import { int10 } from "./pages-ro/int10"
import { int11 } from "./pages-ro/int11"
import { int12 } from "./pages-ro/int12"
import { int13 } from "./pages-ro/int13"
import { int14 } from "./pages-ro/int14"
import { int15 } from "./pages-ro/int15"
import { int16 } from "./pages-ro/int16"
import { int17 } from "./pages-ro/int17"
import { int18 } from "./pages-ro/int18"
import { int19 } from "./pages-ro/int19"
import { int20 } from "./pages-ro/int20"
import { int21 } from "./pages-ro/int21"
import { int22 } from "./pages-ro/int22"
import { int23 } from "./pages-ro/int23"
import { int24 } from "./pages-ro/int24"
import { int25 } from "./pages-ro/int25"
import { int26 } from "./pages-ro/int26"


export const contentINTro = [
    { key: "int1", html: int1 },
    { key: "int2", html: int2 },
    { key: "int3", html: int3 },
    { key: "int4", html: int4 },
    { key: "int5", html: int5 },
    { key: "int6", html: int6 },
    { key: "int7", html: int7 },
    { key: "int8", html: int8 },
    { key: "int9", html: int9 },
    { key: "int10", html: int10 },
    { key: "int11", html: int11 },
    { key: "int12", html: int12 },
    { key: "int13", html: int13 },
    { key: "int14", html: int14 },
    { key: "int15", html: int15 },
    { key: "int16", html: int16 },
    { key: "int17", html: int17 },
    { key: "int18", html: int18 },
    { key: "int19", html: int19 },
    { key: "int20", html: int20 },
    { key: "int21", html: int21 },
    { key: "int22", html: int22 },
    { key: "int23", html: int23 },
    { key: "int24", html: int24 },
    { key: "int25", html: int25 },
    { key: "int26", html: int26 },
]