import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reduxForm, formValueSelector } from 'redux-form'
import { withRouter } from 'react-router-dom'

import Component from './component'

import { registerProfile, saveProfile } from '../../../store/actions'
import { PROFILE_MODE_REGISTRATION, PROFILE_MODE_EDIT } from '../../../store/constants'

import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'

const formName = 'profile-form'
const formSelector = formValueSelector(formName)

export default compose(
  withTranslation,
  // withPermissions,
  withRouter,
  connect(state => {
    const initialValues = state.auth.profile.get('data').toJS()
    const { email, legacyEmail, approved, countryId, legacyCountryId } = initialValues
    if (!email && legacyEmail && !approved) {
      initialValues.email = legacyEmail.trim()
    }
    if (!countryId && legacyCountryId) {
      initialValues.countryId = legacyCountryId
    }
    return {
      isAuthenticated: !!state.auth.accessToken,
      companyVerifiedInfo: state.company.companyVerifiedInfo,
      photo: formSelector(state, 'photo'),
      additionalInfo: formSelector(state, 'additionalInfo'),
      emailForm: formSelector(state, 'email'),
      langId: state.ui.langId,
      initialValues,
    }
  }),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onSubmit: (values, dispatch, props) => {
      const { mode, token, userId, onSaved, history } = props
      let action
      if (mode === PROFILE_MODE_REGISTRATION) {
        values = { ...values, token }
        action = registerProfile
      } else if (mode === PROFILE_MODE_EDIT) {
        //Here reregistration
        values = { ...values, userId }
        action = saveProfile
      }
      return dispatch(action(values, onSaved)).then(response => {
        if (!values.approved && response?.ok) {
          history.push('/auth/reregistration/success')
        }
      })
    },
  })
)(Component)
