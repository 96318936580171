export default (paramsString: string) => {
  const stringArr = paramsString.split('')

  let parsingState: 'key' | 'value' = 'key'

  const keyValueParamsObject: any = {}
  let nextKey: string = ''
  let nextValue: string = ''

  stringArr.forEach((char, id) => {
    if (char === '?') return

    if (char === '=' && parsingState === 'key') {
      keyValueParamsObject[nextKey] = ''
      parsingState = 'value'
      return
    } else if (char === '&' && parsingState === 'value') {
      keyValueParamsObject[nextKey] = nextValue
      nextKey = ''
      nextValue = ''
      parsingState = 'key'
      return
    }

    if (parsingState === 'key') {
      nextKey += char
    } else {
      nextValue += char
    }

    if (id === stringArr.length - 1) {
      keyValueParamsObject[nextKey] = nextValue
    }
  })

  return keyValueParamsObject
}
