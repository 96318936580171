import { connect } from 'react-redux'
import { compose } from 'recompose'
import {
  reduxForm,
  // formValueSelector
} from 'redux-form'

import Component from './component'
import withTranslation from '../../../globals/components/withTranslation'
import { remindPassword } from '../../../store/actions/auth'

const FORMNAME = 'userRemindPassword'
//const formSelector = formValueSelector(FORMNAME)

const initialValues = {
  username: undefined,
  password: undefined,
  password2: undefined,
}

export default compose(
  withTranslation,
  connect(),
  reduxForm({
    form: FORMNAME,
    initialValues,
    touchOnChange: true,
    onSubmit: (values, dispatch) => {
      dispatch(remindPassword(values))
    },
  })
)(Component)
