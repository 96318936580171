import openrouteservice from 'openrouteservice-js'
const ORS_API_KEY = '5b3ce3597851110001cf6248445945c97ec04aa3be04c19d065cb2ab'

export default class RoutingService {
  private service: any
  constructor() {
    this.service = new openrouteservice.Directions({
      api_key: ORS_API_KEY,
    })
  }

  calculate = (coordinates: number[][]): Promise<any> => {
    return this.service
      .calculate({
        coordinates,
        profile: 'driving-car',
        format: 'geojson',
        api_version: 'v2',
      })
      .then(res => res)
  }

  getDistance(coords: number[][]): Promise<number> {
    return this.calculate(coords).then(res => {
      return parseFloat((res.features[0].properties.summary.distance / 1000).toFixed(2))
    })
  }
}
