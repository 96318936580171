import { endOfMonth } from 'date-fns'
import fetch from '../fetch'
import {
  PRICE_TYPE_MY_PRICE,
  PRICE_TYPE_REQUEST,
  PRICE_TYPE_AGREEMENT,
  PRICE_RECURRING_PER_TRIP,
  PRICE_RECURRING_PER_KM,
  PRICE_RECURRING_PER_TON,
  PRICE_RECURRING_PER_HOUR,
  REVIEW_VERY_GOOD,
  REVIEW_GOOD,
  REVIEW_OK,
  REVIEW_BAD,
  REVIEW_VERY_BAD,
  COMPANY_ALL_CLAIMS,
  COMPANY_PAYMENT_INCIDENTS,
  COMPANY_TRANSPORT_INCIDENTS,
  COMPANY_WORK_INCIDENTS,
  COMPANY_OTHERS_CLAIMS,
} from '../../../store/constants'

const returnValue = {}

// Доска объявлений
// Свободный груз
// Свободный транспорт
// Тип перевозок
// Описание груза
// Тип транспорта (авто)
// Тип транспорта (Авиа)
// Тип транспорта (Ж/Д)
// Тип транспорта (Морские)
// Тип транспорта (Мультимодальные)
// Тип транспорта (Пассажирские)
// Форма собственности
// Профиль деятельности
// Тип инцидента
// Новости

let loading = false
let dictionaries = undefined
let readingNow = undefined
const getDictionaries = () => {
  if (dictionaries) {
    return Promise.resolve(dictionaries)
  } else {
    if (loading) {
      return readingNow
    } else {
      loading = true
      readingNow = fetch('/common/dictionaries')
        .then(json => {
          loading = false
          dictionaries = json
          return dictionaries
        })
        .catch(err => {
          loading = false
          return err
        })
      return readingNow
    }
  }
}

// ------------------------------------
// allDictionaries - main repository of all dictionaries
// ------------------------------------
const allDictionaries = {}
// ------------------------------------
// getDictionary
// ------------------------------------
function getDictionary(dictionaryId, langId, refValue) {
  let token = `dict_${dictionaryId}`

  if (langId) {
    token += '_' + langId
  }
  if (refValue) {
    token += '_' + refValue
  }

  let dict = allDictionaries[token]
  if (!dict) {
    dict = allDictionaries[token] = {
      type: 'dictionary',
      dictionaryId,
      langId,
      refValue,
      token,
      loading: false,
      loaded: false,
      error: undefined,
      handler: undefined,
      items: undefined,
      itemsSorted: undefined
    }
  }  

  if (!dict.loaded && !dict.loading) {
    const getUrl = (dictionaryId, langId) => {
      switch (dictionaryId) {
        case 'languages':
          return '/common/languages'
        case 'countries':
        case 'cities':
        case 'months':
          return `/common/${dictionaryId}/${langId}` + (refValue ? '/' + refValue : '')
        default:
          return `/common/getDictionaryById/${dictionaryId}/${langId}`
      }
    }
    const url = getUrl(dictionaryId, langId)

    //sort array alphabetic
    const sortData = (data) => {
      return (data || []).slice().sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      })
    }

    // start loading
    dict.loading = true
    // start fetching and keep promise in 'handler' prop
    dict.handler = fetch(url)
      .then(json => {
        const { d } = json

        dict.items = d,
        dict.itemsSorted = sortData(dict.items)
        dict.loading = false
        dict.loaded = true
        return dict
      })
      .catch(err => {
        dict.error = err
        dict.loading = false
        dict.loaded = true
        return dict
      })
  }
  return dict
}
// --------------------------------------------------
returnValue.countries = (langId, listType) => getDictionary('countries', langId, listType)
returnValue.cities = (langId, countryId) => getDictionary('cities', langId, countryId)

// get available languages
returnValue.languages = () => getDictionary('languages')

//Получение всех значений - Incoterms® 2020
export const incotermsTypes = langId => getDictionary(591, langId)
// Тип транспорта (авто)
returnValue.transportTypesAuto = langId => getDictionary(8, langId)
// Тип транспорта (Авиа)
returnValue.transportTypesAvia = langId => getDictionary(9, langId)
// Тип транспорта (Ж/Д)
returnValue.transportTypesRail = langId => getDictionary(10, langId)
// Тип транспорта (Морские)
returnValue.transportTypesSea = langId => getDictionary(11, langId)
// Тип транспорта (Мультимодальные)
returnValue.transportTypesMulti = langId => getDictionary(12, langId)
// Тип транспорта (Пассажирские)
returnValue.transportTypesPublic = langId => getDictionary(235, langId)
export const getTransportTypes = (returnValue.getTransportTypes = shippingType => {
  switch (shippingType) {
    case 'container':
      return returnValue.transportTypesRail
    case 'avia':
      return returnValue.transportTypesAvia
    default:
      return returnValue.transportTypesAuto
  }
})

// cargo types
returnValue.cargoTypes = langId => getDictionary(5, langId)
// currency
returnValue.currencies = langId => getDictionary(13, langId)

// опции фильтра для панели Новости
returnValue.filterOptionsForNews = langId => getDictionary(430, langId)
//опции фильтра для панели Объявлений
returnValue.filterOptionsForAds = langId => getDictionary(436, langId)

returnValue.filterSubOptionsForAds = langId => getDictionary(444, langId)
// Форма собственности
returnValue.ownershipTypes = langId => getDictionary(500, langId)
// Профиль деятельности
returnValue.businessTypes = langId => getDictionary(411, langId)
// Тип инцидента
returnValue.incidentTypes = langId => getDictionary(425, langId)
// Должность в компании
returnValue.positionsInCompany = langId => getDictionary(459, langId)

returnValue.summaryTableFilterItems = t => {
  return [
    { name: t('all.countries'), value: false },
    { name: t('favorites'), value: true },
  ]
}

returnValue.summaryTableShippingTypes = t => {
  return [
    { name: t('all'), value: 'all' },
    { name: t('auto'), value: 'auto' },
    { name: t('avia'), value: 'avia' },
    { name: t('container2'), value: 'container' },
  ]
}

returnValue.cargoAddFilterItems = t => {
  return [
    { name: t('cargo.filter.manual-or-map'), value: 'manual' },
    { name: t('cargo.filter.choose-from-list'), value: 'choose' },
  ]
}

returnValue.cargoSortBy = t => {
  return [
    { name: t('by.addition.date'), value: 'date-add' },
    { name: t('by.onload'), value: 'load-end' },
    { name: t('by.country.send'), value: 'country-from' },
    { name: t('by.country.arrive'), value: 'country-to' },
    { name: t('by.weight.up'), value: 'weight-asc' },
    { name: t('by.weight.down'), value: 'weight-desc' },
    { name: t('by.city.onboard'), value: 'city-from' },
    { name: t('by.city.arrive') , value: 'city-to' },
  ]
}

returnValue.companyListFilterItems = t => {
  return [
    { name: t('company.all-companies'), value: 'items' },
    { name: t('company.black-listed-companies'), value: 'blacklist' },
    { name: t('company.all-users'), value: 'users' }
  ]
}
returnValue.companyListFilterItemsForAdmin = t => {
  return [
    { name: t('company.all-companies'), value: 'items' },
    { name: t('company.black-listed-companies'), value: 'blacklist' },
    { name: t('company.all-users'), value: 'users' },
    { name: t('company.verification.requests'), value: 'verification' },
  ]
}
returnValue.bodyTypes = t => {
  return [
    { name: t('body-type.tent'), value: 2001 }, // 'tent' },
    { name: t('body-type.samosval'), value: 2002 }, // 'samosval' },
  ]
}
returnValue.carTypes = t => {
  return [
    { name: t('car-type.truck'), value: 2101, nameEng: 'truck' }, // 'truck' },
    { name: t('car-type.truck-trailer'), value: 2102, hasTrailer: true, nameEng: 'truck' }, // 'truck-trailer' },
    { name: t('car-type.tractor-trailer'), value: 2103, hasTrailer: true, nameEng: 'tractor' }, // 'tractor-trailer' },
    { name: t('car-type.minibus'), value: 2104, nameEng: 'minibus' }, // 'minibus' },
  ]
}

returnValue.ownershipTypesCars = t => {
  return [
    { name: t('ownership.own'), value: 2201 }, // 'own' },
    { name: t('ownership.rent'), value: 2202 }, // 'rent' },
    { name: t('ownership.forward'), value: 2203 }, // 'forward' },
  ]
}

returnValue.pageSizes = [
  { name: '10', value: 10 },
  { name: '20', value: 20 },
  { name: '50', value: 50 },
]

export const priority = [
  { name: 'Ввести вручную / выбрать на карте', value: 'manual' },
  { name: 'Выбрать из списка', value: 'choose' },
]

export const days = Array.from(Array(31), (__, x) => {
  const v = x + 1
  return { name: v + '', value: v }
})

const getMonthDays = (lastDay, limit) => {
  const firstCount = limit ? limit : 1
  const days = []
  for (let j = firstCount; j <= lastDay; j++) {
    days.push({ name: j + '', value: j })
  }
  return days
}

returnValue.monthDays = (dt, limit) => {
  const lastDay = endOfMonth(dt).getDate()
  return getMonthDays(lastDay, limit)
}
export const currentYear = new Date().getFullYear()
export const years = [
  { name: currentYear - 1, value: currentYear - 1 },
  { name: currentYear, value: currentYear },
  { name: currentYear + 1, value: currentYear + 1 },
]

returnValue.getMonths = langId => getDictionary('months', langId)
returnValue.months = langId => getDictionary('months', langId)
returnValue.monthsLimit = (langId, limit) => getDictionary('months', langId, limit)

returnValue.rangeYears = (startYear, endYear = currentYear) => {
  const years = []
  for (let j = startYear; j <= endYear; j++) {
    years.push({ name: j + '', value: j })
  }
  return years
}

const cargoTemperatures = []
for (let j = -24; j <= 24; j += 2) {
  const t = (j > 0 ? '+' : '') + j
  cargoTemperatures.push({
    name: t + '\u2103',
    value: t,
  })
}
returnValue.cargoTemperatures = cargoTemperatures

export const rangeDays = Array.from(Array(6), (__, x) => {
  const v = '' + x
  return { name: v, value: v }
})

export const carriageTypes = [
  { name: 'Любой', value: 'any' },
  { name: 'Не любой', value: 'not-any' },
]
export const carryingItems = [
  { name: 'Все страны', value: 'any' },
  { name: '100 т', value: '100' },
]

export const adrClasses = Array.from(Array(9), (__, x) => {
  const v = '' + (x + 1)
  return { name: v, value: v }
})

export const shippingTypes = (returnValue.shippingTypes = () => [
  { name: 'auto', value: 'auto' },
  { name: 'avia', value: 'avia' },
  { name: 'container2', value: 'container' },
  // { name: 'Все', value: 'all' },
])

returnValue.ecoStandards = () => [
  { name: 'Euro-0', value: '0' },
  { name: 'Euro-1', value: '1' },
  { name: 'Euro-2', value: '2' },
  { name: 'Euro-3', value: '3' },
  { name: 'Euro-4', value: '4' },
  { name: 'Euro-5', value: '5' },
  { name: 'Euro-6', value: '6' }
]

returnValue.getMonths = () => [
  { name: '1', value: '1' },
  { name: '2', value: '2' },
  { name: '3', value: '3' },
  { name: '4', value: '4' },
  { name: '5', value: '5' },
  { name: '6', value: '6' },
  { name: '7', value: '7' },
  { name: '8', value: '8' },
  { name: '9', value: '9' },
  { name: '10', value: '10' },
  { name: '11', value: '11' },
  { name: '12', value: '12' },
]

export const truckRecurings = [
  { name: 'one.time', value: 'one-time' },
  { name: 'daily', value: 'daily' },
  { name: 'weekly', value: 'weekly' },
  { name: 'monthly', value: 'monthly' },
]

export const priceTypes = [
  { name: 'my.price', value: PRICE_TYPE_MY_PRICE },
  { name: 'request.a.bid', value: PRICE_TYPE_REQUEST },
  { name: 'negotiated.price', value: PRICE_TYPE_AGREEMENT },
]
export const userActionsFilter = t => [
  { name: t('reviews.on.user'), value: 'userReview' },
  { name: t('reviews.on.company'), value: 'companyReview' },
  { name: t('users.blockings'), value: 'userBlock' },
]
export const reviewTypes = t => [
  { name: t('review.very.good'), value: REVIEW_VERY_GOOD },
  { name: t('review.good'), value: REVIEW_GOOD },
  { name: t('review.ok'), value: REVIEW_OK },
  { name: t('review.bad'), value: REVIEW_BAD },
  { name: t('review.very.bad'), value: REVIEW_VERY_BAD },
]

export const claimsTypes = t => [
  // { name: t('company.all.claims'), value: COMPANY_ALL_CLAIMS },  
  { name: t('company.payment.incidents.claims'), value: COMPANY_PAYMENT_INCIDENTS },
  { name: t('company.transport.incidents.claims'), value: COMPANY_TRANSPORT_INCIDENTS },
  { name: t('company.work.incidents.claims'), value: COMPANY_WORK_INCIDENTS },
  { name: t('company.others.claims'), value: COMPANY_OTHERS_CLAIMS },
]

returnValue.priceRecurrings = [
  { name: 'per.flight', value: PRICE_RECURRING_PER_TRIP },
  { name: 'per.kilometer', value: PRICE_RECURRING_PER_KM },
  { name: 'per.ton', value: PRICE_RECURRING_PER_TON },
  { name: 'in.an.hour', value: PRICE_RECURRING_PER_HOUR },
]

export const cargo_options = [
  [
    { fieldName: 'isCash', displayName: 'isCash' },
    { fieldName: 'isNonCash', displayName: 'isNonCash' },
    { fieldName: 'isCombo', displayName: 'isCombo' },
  ],
  [
    { fieldName: 'isOnLoading', displayName: 'isOnLoading' },
    { fieldName: 'isOnUnloading', displayName: 'isOnUnloading' },
    { fieldName: 'isUponDocument', displayName: 'isUponDocument' },
  ],
  [
    { fieldName: 'isPrepayment', displayName: 'isPrepayment' },
    { fieldName: 'isDeferred', displayName: 'isDeferred' },
  ],
]

export const jobTypes = [{ name: 'Экспедитор', value: 'expeditor' }]

const dict = {
  getDictionaries,
  jobTypes,
  priority,
  currentYear,
  years,
  rangeDays,
  carriageTypes,
  carryingItems,
  adrClasses,
  shippingTypes,
  userActionsFilter,
  truckRecurings,
  priceTypes,
  cargo_options,
  reviewTypes,
  claimsTypes,
  incotermsTypes,
  ...returnValue,
}

export default dict
