import React from 'react'
import { Link } from 'react-router-dom'

import LoginLink from '../../login-link'

export default props => {
  const { t } = props
  return (
    <div className="user-container user-not-logged">
      <ul className="menu menu-nav menu-login">
        <li>
          <LoginLink>{t('menu.login')}</LoginLink>
        </li>
        <li>
          <Link to="/auth/registration">{t('menu.register')}</Link>
        </li>
      </ul>
    </div>
  )
}
