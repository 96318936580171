import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reduxForm } from 'redux-form'

import Component from './component'
import withTranslation from '../../../../globals/components/withTranslation'
import { recoverPasswordSendEmail } from '../../../../store/actions/auth'

const FORMNAME = 'userRecoverPassword'

const initialValues = {
  email: undefined,
}

export default compose(
  withTranslation,
  connect(),
  reduxForm({
    form: FORMNAME,
    initialValues,
    onSubmit: (values, dispatch) => {
      dispatch(recoverPasswordSendEmail(values))
    },
  })
)(Component)
