import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Field } from 'redux-form'
import _ from 'lodash'
import dateFns from 'date-fns'

import { ModalConsumer, ModalContextState } from '../../../context/modal-context'
import LoginLink from '../../navigation/login-link'
import WidgetHeader from '../../shared/components/widget-header'
import Filter from '../../shared/components/widget-filter'
import Dropdown from '../../UI/dropdown'
import NewsList from '../../news/list-mini'
import RatingList from '../../rating/short'
import dict from '../../shared/dictionaries'
import fetch from '../../shared/fetch'
import { getUrlParamsString } from '../../../store/utils'
import { AdsProps, AdsState } from './types'
import './styles.scss'
import AdsTable from '../ads-table'
import TooltipWrapper from '../../UI/popover-wrapper'

const STYLES_BASE: string = 'ads-list_'

export default class extends Component<AdsProps, AdsState> {
  modalConsumer: ModalContextState
  state = {
    filterKey: +this.props.match.params.filterKey,
    dateFilterKey: '',
    dateString: 'all',
    pagination: {
      totalItems: 0,
      pageIndex: 0,
      pageSize: 10,
    },
    ads: [],
  }

  componentDidMount() {
    const { dateString } = this.state
    const { onCanAdd, isAuth } = this.props
    this.changeDateFilterHandler(dateString)
    isAuth && onCanAdd("ads")
  }

  componentDidUpdate(prevProps, _) {
    const {
      pagination: { pageSize },
      dateString,
    } = this.state
    const {
      match: {
        params: { filterKey: currentFilterKey },
      },
      isAuth: currentIsAuth,
    } = this.props
    const {
      match: {
        params: { filterKey: prevFilterKey },
      },
      isAuth: prevIsAuth,
    } = prevProps
    if (prevFilterKey !== currentFilterKey || prevIsAuth !== currentIsAuth) {
      this.setState(prevState => ({
        pagination: {
          ...prevState.pagination,
          pageIndex: 0,
        },
        filterKey: +currentFilterKey,
      }))
      this.fetchAds(0, pageSize, dateString, +currentFilterKey)
    }
  }

  changeFilterHandler = newFilterKey => {
    const {
      pagination: { pageIndex, pageSize },
      dateString,
    } = this.state
    this.setState({ filterKey: newFilterKey })
    this.fetchAds(pageIndex, pageSize, dateString, newFilterKey)
  }

  changeDateFilterHandler = (dateFilterKey: string) => {
    const { format, subDays } = dateFns
    const {
      pagination: { pageIndex, pageSize },
      filterKey,
    } = this.state
    let lastActive = new Date()
    if (localStorage.getItem('idlemonitor')) {
      lastActive = JSON.parse(localStorage.getItem('idlemonitor')).lastActive
    }
    let time: string

    if (_.isEmpty(dateFilterKey)) time = format(lastActive, 'YYYY-MM-DD HH:mm:ss')
    if (dateFilterKey === 'today') time = format(new Date(), 'YYYY-MM-DD 00:00:00')
    if (dateFilterKey === '3 days') time = format(subDays(new Date(), 3), 'YYYY-MM-DD 00:00:00')

    this.setState({ dateFilterKey, dateString: time })
    this.fetchAds(pageIndex, pageSize, time, filterKey)
  }

  changePage = newPageIndex => {
    const {
      pagination: { pageSize },
      filterKey,
      dateString,
    } = this.state
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        pageIndex: newPageIndex,
      },
    }))
    this.fetchAds(newPageIndex, pageSize, dateString, filterKey)
  }

  changeNumberOfAds = (newPageSize): void => {
    const { filterKey, dateString } = this.state
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        pageSize: newPageSize,
        pageIndex: 0,
      },
    }))
    this.fetchAds(0, newPageSize, dateString, filterKey)
  }

  fetchAds = (page: number, pageSize: number, daysInterval: string, treeTypeId: number): void => {
    const { langId, userId } = this.props

    if (daysInterval === 'all') daysInterval = ''

    const queryString = getUrlParamsString({
      page_index: page,
      page_size: pageSize,
      tree_type_id: treeTypeId || 436,
      days_interval: daysInterval || '',
      active: 1,
      lang_id: langId,
    })
    let getAdsEndpoint = `/announcement/list?${queryString}`
    if (userId) getAdsEndpoint += `&user_id=${userId}`
    fetch(getAdsEndpoint).then(ads => {
      this.setState(prevState => ({
        ads,
        pagination: {
          ...prevState.pagination,
          totalItems: ads[0] ? ads[0].total : 0,
        },
      }))
    })
  }

  showNotAuthModal = event => {
    const { t } = this.props
    const { showModalComponent, clearModal } = this.modalConsumer

    const notAuthComponent = (
      <div className="panel-search-wrapper centered-container">
        <div className="panel-search-log centered-item">
          <span className="fa fa-clock-o" />
          <p className="panel-search-text">{t('ads.add.only.auth')}</p>
          <div className="panel-search-links">
            <LoginLink> {t('login.enter.2')} </LoginLink>
            <span>{t('or')} </span>
            <Link to="/auth/registration" onClick={clearModal}>
              {t('login.register')}
            </Link>
          </div>
          <br />
          {t('login.family')}
        </div>
      </div>
    )

    event.preventDefault()

    showModalComponent(notAuthComponent, {
      className: 'not-auth-modal popup popup-truck',
      backdrop: true,
      skipLayout: true,
    })
  }

  render() {
    const {
      ads,
      filterKey,
      dateFilterKey,
      pagination,
      pagination: { totalItems },
      dateString
    } = this.state
    const { t, langId, isAuth, canAdd } = this.props
    const dateFiltersOptions = [
      { value: 'today', name: t('ads.todays') },
      { value: '3 days', name: t('ads.lastThreeDays') },
      { value: 'all', name: t('all') },
    ]
    const categories = dict.filterOptionsForAds(langId)

    return (
      <Fragment>
        <div className="row">
          <div className="col-xl-12" id="ads-main-list">
            <div className={STYLES_BASE}>
              <WidgetHeader title={t('ads.name')} className={`${STYLES_BASE}header`}>
                <div>
                  {!isAuth ? (
                    <Link
                      onClick={!isAuth && this.showNotAuthModal}
                      to="/ads/add-ads"
                      className="add-ads-link"
                    >
                      <span className="button button-orange" id="add-ads-button">
                        <i className="fa fa-plus"></i>
                      </span>
                      <span className="link-text">{t('ads.addAds')}</span>
                    </Link>
                  ) : canAdd && isAuth ? (
                    <Link
                      to="/ads/add-ads"
                      className="add-ads-link"
                    >
                      <span className="button button-orange" id="add-ads-button">
                        <i className="fa fa-plus"></i>
                      </span>
                      <span className="link-text">{t('ads.addAds')}</span>
                    </Link>
                  ) : (<>
                    <span className="link-text">{t('ads.addAds')}</span>
                    <TooltipWrapper text={t('ads.limit')} hover>
                      <i className="fa fa-question-circle m-l-5" />
                    </TooltipWrapper>
                  </>)}
                </div>
              </WidgetHeader>
              <div className="bordered-filters">
                <Filter
                  items={categories}
                  emptyOption={t('ads.allAds')}
                  loadLanguages
                  selectedKey={filterKey === 436 ? '' : filterKey}
                  css={{
                    container: 'panel-filter-md panel-filter-search',
                    filter: 'priority-nav-has-dropdown',
                  }}
                  className="priority-nav-has-dropdown"
                  onClick={this.changeFilterHandler}
                />
              </div>
              <div className="bordered-filters" id="second-bordered-filter">
                <h3 id="found-ads-total">
                  {t('ads.found')}: {totalItems}
                </h3>
                <Filter
                  emptyOption={t('ads.lastVist')}
                  items={dateFiltersOptions}
                  loadLanguages
                  css={{
                    container: 'panel-filter-md panel-filter-search',
                    filter: 'priority-nav-has-dropdown',
                  }}
                  className="priority-nav-has-dropdown"
                  onClick={this.changeDateFilterHandler}
                  selectedKey={dateFilterKey}
                >
                  <div className="page-quantity">
                    <span id="quantity-label">{t('ads.quantity')}:</span>
                    <Field
                      name="pageSize"
                      component={Dropdown}
                      items={dict.pageSizes}
                      className="page-quantity-dropdown"
                      placeholder="К-во Записей"
                      onChange={this.changeNumberOfAds}
                    />
                  </div>
                </Filter>
              </div>
              <AdsTable
                ads={ads}
                refresh={this.changePage}
                {...pagination}
                {...{ dateString, filterKey }}
                fetchAds={this.fetchAds}
                loggedUserId={0}
                openIn="window"
              />
            </div>
          </div>
          <div className="col-xl-4">
            <RatingList />
            <NewsList />
          </div>
        </div>
        <ModalConsumer>
          {modalConsumer => {
            this.modalConsumer = modalConsumer
            return null
          }}
        </ModalConsumer>
      </Fragment>
    )
  }
}
