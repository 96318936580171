import React, { Component } from 'react'
import classNames from 'classnames'
import uuid from 'uuid/v4'

import DropdownBase, { DropdownName, DropdownPanel } from '../../../UI/dropdown-base'

// const STYLE_BASE = 'dropdown-checkboxlist_'

export default class DropdownCheckboxlist extends Component {
  constructor(props) {
    super(props)

    const { items } = props
    this.state = {
      guid: uuid(),
      items,
    }
  }
  componentDidUpdate(prevProps) {
    const { items } = this.props
    if (items !== prevProps.items) {
      this.setState({ items })
    }
  }
  onClick = (checked, item) => {
    const { disabled } = item
    if (disabled) return

    item.checked = checked

    const { onChange } = this.props
    if (onChange) {
      const value = this.state.items.filter(x => x.checked).map(x => x.value)
      onChange(value)
    }
  }
  render() {
    const { inline = true, activeGuid, items, icon, text } = this.props
    const guid = this.state.guid
    return (
      <DropdownBase
        className={classNames({
          // [`${STYLE_BASE}wrapper`]: true,
          'menu-item': true,
          'has-dropdown': true,
          'has-text': !!text,
          'is-opened': guid === activeGuid,
        })}
      >
        <DropdownName inline={inline} icon={icon} iconSize={12} text={text} guid={guid} />
        <DropdownPanel>
          <div
            className={classNames({
              dropdown: true,
              'dropdown-checkbox': true,
            })}
          >
            {items.map((item, j) => {
              const { name, checked, type } = item
              if (type === 'header' || type === 'title') {
                return <h5 key={j}>{name}</h5>
              }
              return (
                <div key={j} className="checkbox-row">
                  <input type="checkbox" name={name} value={checked} />
                  <label htmlFor={name} onClick={() => this.onClick(!checked, item)}>
                    {name}
                  </label>
                </div>
              )
            })}
          </div>
        </DropdownPanel>
      </DropdownBase>
    )
  }
}

export class DropdownCheckboxlistField extends Component {
  onChange = () => {
    const { input } = this.props
    const { value, onChange } = input
    onChange(value)
  }
  render() {
    const { className, icon, text, items } = this.props
    return (
      <DropdownCheckboxlist
        className={className}
        icon={icon}
        text={text}
        items={items}
        onChange={this.onChange}
      />
    )
  }
}
