import React from 'react'
import classnames from 'classnames'
import WidgetHeader from '../widget-header'
import LoginLink from '../../../navigation/login-link'
import './styles.scss'

export default props => {
  const {
    t,
    title = '',
    className,
    linksTo: { title: titleLink = '#' } = {},
    notEnoughRights,
  } = props

  return (
    <div className={classnames('panel', className)}>
      {title && <WidgetHeader title={title} titleLink={titleLink} />}
      <div className="protected-widget">
        {!notEnoughRights
          ? props.children || <LoginLink>{t('login-to-view')}</LoginLink>
          : t('not.enough.rights')}
      </div>
    </div>
  )
}
