import React from 'react'
import { ModalConsumer } from '../../../context/modal-context'
import fetch from '../../shared/fetch'
import { confirmAlert } from 'react-confirm-alert'

export default props => {

    const setStatusVerification = ({ clearModal }) => {
        const { t, companyVerificationInfo: { companyId, ID } } = props

        confirmAlert({
            title: t("approve.decision"),
            message: (
                t('remove.review.note')
            ),
            buttons: [
                {
                    label: "OK",
                    onClick: () => {
                        clearModal()
                        fetch(`/company/${companyId}/verification/${ID}`, {
                            method: 'PUT',
                            body: JSON.stringify({
                                companyId,
                                id: ID,
                                isValid: false,
                            }),
                        }).then(response => {
                            if (response.ok) {
                                clearModal()
                                window.location.reload()
                            }
                        })
                    },
                },
                {
                    label: t('button.close'),
                },
            ],
        })
    }

    return (
        <div className="panel-content">
            <div className="ta-c">
                <ModalConsumer>
                    {modalConsumer => (
                        <button
                            type="button"
                            className="button button-orange"
                            onClick={() => setStatusVerification(modalConsumer)}
                        >
                            {t('remove.review.note')}
                        </button>
                    )}
                </ModalConsumer>
            </div>
        </div>
    )
}
