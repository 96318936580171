export const int17 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Международная Конвенция об упрощении и гармонизации таможенных процедур</h4><p></p>

<p>(Киотская конвенция 1973 года)</p>

<p></p><h4>Приложение 1</h4><p></p>

<p style="font-weight:500;">Относительно свободных зон</p><br>

<p>(вступило в силу 19 марта 1979 года)</p><br>

<p style="font-weight:500;">1. Текст и комментарии</p><br>

<p style="font-weight:500;">Введение</p><br>

<p style="text-indent:2em;">Некоторые государства с давних пор считали необходимым поощрять развитие
 своей внешней и международной торговли в целом путем предоставления
 некоторого облегчения в уплате импортных пошлин и налогов в отношении
 товаров, ввозимых на часть территории, где они обычно рассматриваются как
 товары, находящиеся вне таможенной территории. Ввезенные таким образом товары
 не подвергаются обычному таможенному контролю.</p><br>

<p style="text-indent:2em;">В настоящем приложении эта часть территории называется "свободная зона", хотя
 в некоторых странах она известна также под рядом других названий, таких как 
 "свободный порт", "свободный склад".</p><br>

<p style="text-indent:2em;">Можно различать коммерческую и промышленную свободные зоны. В коммерческих 
свободных зонах разрешаемые с товарами операции обычно ограничиваются операциями,
 необходимыми для сохранения товаров, и обычными видами операций для улучшения их
 упаковки или товарных качеств, или для подготовки их к отгрузке, В промышленных
 свободных зонах разрешается переработка товаров.</p><br>

<p style="text-indent:2em;">Хотя ввозимые на территорию таможенной зоны товары обычно рассматриваются, в том,
 что касается импортных пошлин и налогов, как товары, ввезенные из-за пределов 
 таможенной территории, к ним могут применяться определенные положения, установленные
 соответствующим государством, например, запрещения и ограничения, вытекающие из 
 национального законодательства. Таможенные службы осуществляют также определенный 
 контроль в пределах свободной зоны, чтобы установить, что проводимые операции 
 осуществляются в соответствии с установленными требованиям.</p><br>

<p style="text-indent:2em;">Товары, ввезенные в свободную зону с таможенной территории, обычно подпадают под 
освобождение от оплаты импортных пошлин и налогов или внутренних пошлин и налогов 
либо под возмещение таковых, предоставляемых для экспортируемых товаров.</p><br>

<p style="text-indent:2em;">В случаях, когда товары, не подвергшиеся переработке в свободной зоне, получают 
разрешение на ввоз в пределы таможенной территории для внутреннего потребления,
 они подпадают под обложение импортными пошлинами и налогами, как если бы они 
 ввозились непосредственно из-за границы. Однако, в случае, если иностранные товары
 подвергались переработке в свободной зоне, или если использовались товары национального 
 происхождения, или если товары были ввезены под уплату импортных пошлин и налогов и были
 освобождены от уплаты пошлин и налогов, или был предусмотрен возврат пошлин и налогов
 , когда товары ввозились в свободную зону, применяются специальные правила обложения
 , предусматриваемые национальным законодательством.</p><br>

<p style="text-indent:2em;">В некоторых странах таможенные льготы, подобные тем, которые характерны для свободных
 зон, предоставляются по всей территории в контексте других таможенных процедур, 
 таких как таможенные склады, возврат пошлины, временный ввоз для внутренней 
 переработки или таможенный транзит.</p><br>

<p style="font-weight:500;">Определения</p>

<p style="text-indent:2em;"></p><h6>Для целей данного Приложения:</h6><p></p>

<p style="text-indent:2em;">(а) термин "свободная зона" означает часть территории государства, где любые 
ввезенные товары обычно рассматриваются, в том, что касается импортных пошлин 
и налогов, как находящиеся вне пределов таможенной территории и не являющиеся 
предметом обычного таможенного контроля (1), (2), (3);</p>

<p style="text-indent:2em;"></p><h6>Примечание:</h6><p></p>

<p style="text-indent:2em;">Следует различать коммерческую и промышленную свободные зоны. В коммерческую 
свободную зону товары допускаются для последующей продажи, их переработка или
 использование в производстве обычно запрещены. Товары, допущенные к ввозу в 
 промышленные свободные зоны, могут быть подвергнуты разрешенным операциям 
 по переработке (4);</p><br>

<p style="text-indent:2em;">b) термин "таможенная территория" означает территорию, на которой таможенные 
законы государства применяются в полной мере;</p><br>

<p style="text-indent:2em;">с) термин "импортные пошлины и налоги" означает таможенные пошлины 
и все другие пошлины, налоги, сборы или другие платежи, которые собираются 
при или в связи с импортом товаров, но не включают сборов и платежей, 
которые по сумме ограничиваются примерной стоимостью оказанных услуг 
(5);</p><br>

<p style="text-indent:2em;">d) термин "таможенный контроль" означает меры, применяемые для обеспечения 
соответствия законам и правилам, исполнение которых призвана контролировать 
таможенная служба;</p><br>

<p>е) термин "лицо" означает как физическое, так и юридическое лицо, 
если из текста не вытекает иное.</p><br>

<p style="font-weight:500;">Комментарии.</p><br>

<p style="text-indent:2em;">(1) Учреждение свободной зоны зависит от включения официального положения 
в национальное законодательство. Отсюда недостаточно, чтобы часть национальной
 территории была бы расположена вне пределов таможенной территории для того,
 чтобы она автоматически рассматривалась как образующая свободную зону.</p><br>

<p style="text-indent:2em;">(2) Устанавливая, что товары не подпадают под обычный таможенный контроль,
 определение обращает внимание на тот факт, что таможенный контроль, 
 осуществляемый в отношении товаров, находящихся в свободной зоне, является
 более гибким, чем контроль, применяемый в отношении товаров, находящихся, 
 например, на таможенных складах, или пропущенных по условиям временного 
 ввоза для процедуры внутренней переработки. Если, осуществляя обычный 
 таможенный контроль, таможенные власти имеют в своем распоряжении целую 
 серию специфических мер, предназначенных для обеспечения исполнения законов 
 и постановлений, за исполнение которых они отвечают, то в отношении свободных 
 зон обычно прибегают лишь к мерам общего надзора. Таким образом, сооружения, 
 расположенные в свободных зонах, не являются предметом постоянного таможенного 
 надзора. Меры контроля, применяемые к товарам во время их нахождения в свободных 
 зонах, обычно сводятся к абсолютному минимуму и, главным образом, касаются 
 соответствующей документации, хотя Стандарт признает за таможенными властями 
 право осуществлять проверку товаров в любое время.</p><br>

<p style="text-indent:2em;">(3) Существуют также свободные зоны, в которых таможенное законодательство 
применяется в полном объеме. Эти свободные зоны не выпадают из сферы 
применения данного определения, при условии, что ввезенные в них товары 
не облагаются импортными пошлинами и налогами и не подвергаются обычному 
таможенному контролю.</p><br>

<p style="text-indent:2em;">(4) В некоторых странах в свободных зонах могут осуществляться как 
промышленные, так и коммерческие операции.</p><br>

<p style="text-indent:2em;">(5) Значение термина "импортные пошлины и налоги" включают в себя также 
VAT - налог на добавленную стоимость, взысканный в связи с импортом 
товаров.</p><br>

<p style="font-weight:500;">Принцип</p><br>

<p style="text-indent:2em;">1. Стандарт. Применяемое в свободных зонах таможенное регулирование 
осуществляется в соответствии с положениями настоящего Приложения
 (1).</p><br>

<p style="font-weight:500;">Комментарий.</p><br>

<p style="text-indent:2em;">(1) Государства не обязаны принимать все положения настоящего Приложения,
 и они могут делать оговорки в отношении любого стандарта или рекомендуемой
 практики, которые они не в состоянии применять (см. Статью 5 Конвенции).</p><br>

<p style="text-indent:2em;">Таможенные власти могут вводить в отношении свободных зон правила, не 
предусмотренные специфическими положениями настоящего Приложения.</p><br>

<p style="text-indent:2em;">Таможенные власти могут предоставлять большие льготы, чем те, которые 
предусмотрены в Приложении. Предоставление таких больших льгот рекомендуется
 в Статье 2 Конвенции.</p><br>

<p style="font-weight:500;">Учреждение свободных зон</p><br>

<p style="text-indent:2em;">2. Стандарт. Национальное законодательство (1) должно определять требования
 в отношении учреждения свободных зон, разновидности товаров, допускаемых в
 такие зоны и характер операций, которым товары могут в них 
 подвергаться (2).</p><br>

<p style="font-weight:500;">Примечания.</p><br>

<p style="text-indent:2em;">1. Свободные зоны обычно учреждаются в морских портах, аэропортах и местах
 с подобными географическими преимуществами (3).</p><br>

<p style="text-indent:2em;">2. В соответствии с положениями национального законодательства, 
свободные зоны могут управляться таможенными властями, другими 
властями или физическими или юридическими лицами (4).</p><br>

<p style="font-weight:500;">Комментарий.</p><br>

<p>(1) Смотри комментарий к Статье 3 Конвенции.</p><br>

<p style="text-indent:2em;">(2) Поскольку национальное законодательство определяет разновидности 
допускаемых товаров и характер разрешенных операций, таможенные власти
 должны иметь возможность издавать общие разрешения, а не применять 
 систему предварительного разрешения на каждую партию товара, ввозимого
 в свободную зону.</p><br>

<p style="text-indent:2em;">(3) Свободные зоны могут быть довольно обширными (например, весь порт 
или часть его) или относительно небольшими (небольшой участок, 
примыкающий к аэропорту).</p><br>

<p style="text-indent:2em;">(4) Вне зависимости от условий, которые могут быть установлены таможенными
 властями для того, чтобы обеспечить контроль за находящимися в свободной
 зоне грузами, управляющий может установить специальные условия в отношении,
 в частности, мер сохранности и безопасности, которые должны соблюдаться в
 связи с хранением товаров, сборов, уплачиваемых за использование сооружений,
 арендной платой за помещения, и за допуск и контроль за персоналом, имеющим 
 доступ в свободную зону.</p><br>

<p style="text-indent:2em;">3. Стандарт. Требования в отношении строительства и размещения свободных зон
 и организации таможенного контроля должны устанавливаться таможенными 
 властями (1).</p><br>

<p style="font-weight:500;">Примечания.</p><br>

<p style="text-indent:2em;">1. Таможенные власти могут требовать, чтобы свободные зоны были огорожены;
 они могут также ввести ограничения на способ доступа и устанавливать 
 часы работы.</p>

<p style="text-indent:2em;"></p><h6>2. Для целей контроля таможенные власти могут, в частности:</h6><p></p>

<p>осуществлять постоянный и периодический надзор за способом доступа в
 свободную зону;</p><br>

<p style="text-indent:2em;"> потребовать от лиц, ввозящих товары в свободные зоны вести учет таким 
образом, чтобы можно было контролировать товарооборот;</p><br>

<p style="text-indent:2em;"> осуществлять контроль на месте хранящихся товаров, чтобы гарантировать, 
что они были подвергнуты только разрешенной переработке и что не были 
ввезены неразрешенные грузы.</p>

<p style="text-indent:2em;"></p><h6>Комментарий:</h6><p></p>

<p style="text-indent:2em;">(1) Требования в отношении строительства и размещения свободных зон 
устанавливаются таможенными властями с целью обеспечения должной 
безопасности.</p><br>

<p style="text-indent:2em;">4. Стандарт. Таможенные власти должны иметь право в любое время осуществлять
 проверку товаров, складируемых в помещениях любого лица, ввозящего товары 
 в свободную зону.</p><br>

<p style="font-weight:500;">Товары допущенные к ввозу</p><br>

<p style="text-indent:2em;">5. Рекомендуемая практика. Пропуск в свободную зону не должен сопровождаться
 условием, согласно которому товары ввозятся или складируются в зоне в точно
 определенных количествах (1).</p><br>

<p style="font-weight:500;">Комментарий.</p><br>

<p style="text-indent:2em;">(1). Стандарт 2 позволяет таможенным властям установить разновидность товаров,
 которые могут допускаться в свободную зону. Однако, требование таможенных властей
 в ввозе или складировании в свободной зоне определенных количеств товаров может
 рассматриваться как препятствие международной торговле.</p><br>

<p style="text-indent:2em;">6. Стандарт. Допуск в свободную зону должен разрешаться не только для товаров,
 непосредственно ввозимых из-за границы, но также и для товаров, ввозимых с 
 таможенной территории соответствующего государства (1).</p><br>

<p style="font-weight:500;">Примечание.</p><br>

<p style="text-indent:2em;">Товары, доставленные с таможенной территории заинтересованного государства, могут
 быть товарами в свободном обращении или товарами, находящимися в режиме условного
 освобождения от импортных пошлин и налогов, или в режиме переработки.</p>

<p style="text-indent:2em;"></p><h6>Комментарий:</h6><p></p>

<p style="text-indent:2em;">(1) Чтобы не допустить затоваривания свободных зон товарами, помещенными туда, 
главным образом с целью определения, подпадают ли данные товары под условия освобождения
 от уплаты внутренних пошлин и налогов или возмещение таковых, таможенные власти могут 
 обусловить такое освобождение или возмещение вывозом товаров с национальной территории 
 (см. Примечание к Стандарту 8).</p><br>

<p style="text-indent:2em;">7. Стандарт. Товары, допускаемые к ввозу в свободную зону и имеющие право на 
освобождение от оплаты импортных пошлин и налогов или возмещения таковых в случае
 экспорта (1), подпадают под такое освобождение или возмещение сразу же после того,
 как они были ввезены в свободную зону (2).</p>

<p style="text-indent:2em;"></p><h6>Комментарий:</h6><p></p>

<p style="text-indent:2em;">(1) Это относится, в частности, к товарам, на которые затребован возврат пошлин и
 сборов, товары, временно допущенные для внутренней переработки и товары, в отношении
 которых предоставлено возмещение импортных пошлин и налогов по причине справедливости,
 признаваемой местным законодательством что обычно имеет место, в частности, в отношении 
 грузов, реэкспортируемых не в соответствии с контрактом.</p><br>

<p style="text-indent:2em;">(2) Повторный ввоз таких товаров на таможенную территорию влечет за собой уплату 
соответствующих импортных пошлин и налогов.</p><br>

<p style="text-indent:2em;">8. Стандарт. Товары, допускаемые к ввозу в свободную зону, и имеющие право на 
освобождение от оплаты внутренних пошлин и налогов или возмещения таковых в случае
 экспорта (1), подпадают под такое освобождение или возмещение сразу после того, 
 как они были ввезены в свободную зону (2), (3).</p><br>

<p style="font-weight:500;">Примечание.</p><br>

<p style="text-indent:2em;">Освобождение или возмещение обычно осуществляется сразу же после того, как товары 
поступили в свободную зону. В особых случаях освобождение или возмещение может быть 
обусловлено вывозом товаров, за пределы национальной территории. В исключительных случаях
 может быть затребовано свидетельство прибытия товаров в страну назначения.</p>

<p style="text-indent:2em;"></p><h6>Комментарий:</h6><p></p>

<p style="text-indent:2em;">(1) Суммы, которые, как это может иметь место, получаются или ассигнуются при экспорте
 сельскохозяйственных продуктов, особенно для компенсации разницы в ценах на мировых рынках,
 не рассматриваются в значении этого Стандарта как "внутренние пошлины и налоги".</p><br>

<p style="text-indent:2em;">(2) Стандарт устанавливает лишь время, в течение которого действует право на освобождение
 от оплаты внутренних пошлин и налогов или на возмещение таковых, и не устанавливает режима 
 предоставления таких освобождений и возмещений; это оставляется на усмотрение 
 Договаривающихся Сторон.</p><br>

<p style="text-indent:2em;">(3) Повторный ввоз этих товаров на таможенную территорию влечет за собой уплату 
соответствующих внутренних пошлин и налогов.</p><br>

<p style="text-indent:2em;">9. Стандарт. Допуск в таможенную зону не может быть отказан только на основании 
страны происхождения товаров, страну из которой они поступили, или страны назначения 
товаров (1).</p>

<p style="text-indent:2em;"></p><h6>Комментарий:</h6><p></p>

<p style="text-indent:2em;">(1) Там, где запрещения или ограничения подходят под решения, принятые компетентными органами Объединенных
 Наций, эти запрещения или ограничения могут применяться без необходимости для Договаривающихся Сторон
 делать оговорки в отношении данного Стандарта.</p><br>

<p style="text-indent:2em;">10. Стандарт. Допуск в свободную зону товаров, ввозимых из-за границы не может быть запрещен только на
 основании того, что товары подпадают под ограничения и запреты помимо тех, которые устанавливаются по
 соображениям общественной морали и порядка, общественной безопасности, общественной гигиены или здоровья,
 или по ветеринарным или фитосанитарным соображениям, а также по соображениям, связанным с защитой патентов,
 торговых марок или авторских прав (1), (2).</p>

<p style="text-indent:2em;"></p><h6>Комментарий:</h6><p></p>

<p style="text-indent:2em;">(1) В принципе товары, которые подпадают под запрещения и ограничения экономического характера, должны 
допускаться в свободные зоны. Поэтому Договаривающиеся Стороны, которые не сделали оговорок в отношении 
этого положения, не могут в дальнейшем применять такие запрещения или ограничения на основании Статьи 3
 Конвенции.</p><br>

<p style="text-indent:2em;">(2) Экспортные ограничения и запрещения в полной мере применяются к товарам ввезенным с таможенной 
территории.</p><br>

<p style="text-indent:2em;">11. Рекомендуемая практика. Товары, представляющие опасность, могущие, вероятно, воздействовать на другие
 товары или требующие специальных сооружений, должны допускаться только в свободные зоны, специально 
 оборудованные для помещения таких товаров.</p><br>

<p style="font-weight:500;">Ввоз в свободную зону</p><br>

<p style="text-indent:2em;">12. Стандарт. В случаях, когда таможне должен представляться документ в отношении товаров, ввозимых в 
таможенную зону непосредственно из-за границы без пересечения таможенной территории заинтересованного 
государства (1), таможенные власти требуют лишь представления коммерческого или официального документа 
(2) (счет-фактура, дорожная накладная, извещение об отправке и т.д.) с указанием основных особенностей 
товаров, о которых идет речь.</p><br>

<p style="font-weight:500;">Комментарий.</p><br>

<p style="text-indent:2em;">(1) Свободные зоны, о которых идет речь, - это зоны, расположенные непосредственно на границе или
 имеющие прямой доступ по воздуху.</p><br>

<p style="text-indent:2em;">(2) "Официальный документ", который должен быть представлен, может, например, быть упрощенным 
документом на специальном бланке, характеризующий ввезенные в свободную зону товары.</p><br>

<p style="text-indent:2em;">13. Рекомендуемая практика. Допуск в свободную зону товаров, ввезенных с таможенной территории 
соответствующего государства, или товаров, которые транзитом пересекли эту территорию, не должен 
повлечь за собой заполнение какого-либо другого документа, кроме грузовой таможенной декларации, 
обычно требуемой к представлению на этой территории для сопровождения экспорта, реэкспорта или 
транзита товаров (1).</p><br>

<p style="font-weight:500;">Комментарий.</p><br>

<p style="text-indent:2em;">(1) Даже в случае, если транспортировка товаров, предназначенных для ввоза в свободную зону, 
осуществляется в сопровождении упрощенного документа, таможенные власти не должны требовать 
заполнения нового документа для допуска товаров в свободную зону.</p><br>

<p>14. Стандарт. Таможенные власти не должны требовать гарантии на допуск товаров в свободную зону
 (1), (2).</p><br>

<p style="font-weight:500;">Комментарий.</p><br>

<p style="text-indent:2em;">Договаривающиеся стороны, которые требуют гарантии, в частности, как средства обеспечения выполнения
 обязательств, возникающих в результате выполнения операций в свободной зоне, должны сделать оговорки
 к этому стандарту.</p><br>

<p style="text-indent:2em;">(2) Гарантии, которые могут требоваться на товары, проходящие транзитом в свободную зону, не 
регулируются данным положением.</p><br>

<p style="text-indent:2em;">15. Стандарт. При осуществлении контроля за грузами, предназначенными для ввоза в свободную зону, 
таможенные власти должны предпринимать только такие действия, которые считаются абсолютно необходимыми
 для обеспечения выполнения законов и правил, за исполнение которых таможни несут ответственность.</p><br>

<p style="font-weight:500;">Примечание.</p><br>

<p style="text-indent:2em;">В частности, таможни вправе удостовериться, что речь идет о товарах, ввоз которых в таможенную зону разрешен,
 и что любые относящиеся к делу запрещения и ограничения соблюдены.</p><br>

<p style="font-weight:500;">Разрешение операции</p><br>

<p style="text-indent:2em;">16. Стандарт. Помимо операций по погрузке, разгрузке, перевалке и хранению разрешается подвергать допущенные 
в коммерческую свободную зону товары операциям, необходимым для сохранения, а также обычным операциям для
 улучшения их упаковки или их рыночных качеств или для подготовки их к перевозке, таким как дробление товара
 на партии, формирование отправок, сортировка и классификация, а также переупаковка (1).</p><br>

<p style="font-weight:500;">Комментарий.</p><br>

<p style="text-indent:2em;">(1) Данный Стандарт не имеет в виду разрешать производить какие-либо изменения в существенных качествах 
товаров. Например, смешивание является одной из операций, о которых здесь не упоминается.</p><br>

<p style="text-indent:2em;">17. Стандарт. Операции по переработке, которым допущенные в промышленную зону товары могут подвергаться, 
должны быть точно определены компетентными властями в общих выражениях и/или детально в правилах, применяемых
 во всей свободной зоне или полномочиях, представленных предприятию, осуществляющему эти операции (1) (2).</p><br>

<p style="font-weight:500;">Примечание.</p><br>

<p style="text-indent:2em;">Право на осуществление операций по переработке может быть обусловлено тем, чтобы предложенные операции 
рассматривались компетентными властями как выгодные для национальной экономики (3).</p><br>

<p style="font-weight:500;">Комментарий.</p><br>

<p style="text-indent:2em;">(1) Деятельность, связанная с судостроением обычно разрешается в промышленных свободных зонах, 
организуемых в морских портах.</p><br>

<p style="text-indent:2em;">(2) Для определения происхождения товаров, произведенных в промышленной свободной зоне, может быть
 принято решение использовать существующие правила определения происхождения товаров, временно допущенных
 для внутренней переработки. Более того, положения Приложений D.1., D.2., и D.3. ( к Киотской конвенции
 - прим. составителя), касающиеся происхождения, также применимы для товаров, переработанных в свободных
 зонах.</p><br>

<p style="text-indent:2em;">(3) Рассматривая вопрос о представлении такого права, компетентные власти могут, в частности, 
принять во внимание тот факт, что цель операций по переработке - сделать товары подпадающими под
 более низкую ставку пошлины.</p><br>

<p style="font-weight:500;">Товары потребляемые внутри свободной зоны</p><br>

<p style="text-indent:2em;">18. Стандарт. Национальное законодательство должно перечислить случаи, когда товары, предназначенные
 для потребления внутри свободной зоны (1), могут допускаться без обложения пошлинами и налогами, и
 должны установить условия, обязательные для выполнения (2).</p><br>

<p style="font-weight:500;">Примечание.</p><br>

<p style="text-indent:2em;">1. Свободный доступ может быть разрешен не только в отношении ввозных пошлин и налогов, но и в
 отношении внутренних пошлин и налогов.</p><br>

<p style="text-indent:2em;">2. может быть разрешен свободный доступ оборудования для использования только внутри свободной
 зоны и предназначенного для транспортировки, хранения и переработки грузов.</p><br>

<p style="font-weight:500;">Передача права собственности</p><br>

<p>19. Стандарт. Может быть разрешена передача права собственности на товары, допущенные в
 свободную зону (1).</p><br>

<p style="font-weight:500;">Примечание.</p><br>

<p>1. Внутри свободных зон может быть разрешена розничная торговля (2).</p><br>

<p>2. Допущенные в свободную зону товары могут быть использованы для снабжения судов и 
самолетов.</p><br>

<p style="font-weight:500;">Комментарий.</p><br>

<p style="text-indent:2em;">(1) Как и в Приложении, касающемся таможенных складов (см.Стандарт 20 Приложения Е.3.), не
 могут устанавливаться никакие ограничения на передачу права собственности.</p><br>

<p style="text-indent:2em;">(2) Розничная торговля может рассматриваться как ввоз для внутреннего потребления; поэтому
 она не рассматривается данным Стандартом.</p><br>

<p style="font-weight:500;">Уничтожение</p><br>

<p style="text-indent:2em;">20. Стандарт. Следует разрешать уничтожение товаров, допущенных в свободную зону, или
 приведение их в коммерчески непригодное состояние под таможенным контролем (1).</p><br>

<p style="font-weight:500;">Комментарий</p><br>

<p style="text-indent:2em;">(1) Вопрос об использовании для внутреннего потребления отходов и остатков, образовавшихся
 в результате уничтожения товаров, обычно не встает, когда уничтожение осуществляется внутри
 свободной зоны. Обычно также не возникает вопроса об оставлении товаров в пользу таможенных 
 властей.</p><br>

<p style="font-weight:500;">Продолжительность нахождения в свободной зоне</p><br>

<p>21. Стандарт. Не должно вводиться ограничений на продолжительность нахождения товаров в 
свободной зоне.</p><br>

<p style="font-weight:500;">Ввоз из свободной зоны</p><br>

<p style="text-indent:2em;">Там, где таможне должен предоставляться документ в отношении товаров, которые при вывозе 
из свободной зоны направляются непосредственно за границу без пересечения таможенной 
территории заинтересованного государства (1), таможенные власти не должны требовать ничего 
иного, кроме представления коммерческого или официального документа (2) (счет-фактура, 
дорожная накладная, извещение об отправке и т.д.) с указанием основных особенностей товаров,
 о которых идет речь.</p><br>

<p style="font-weight:500;">Комментарий.</p><br>

<p>1) Смотри комментарий (1) к Стандарту 12.</p><br>

<p>2) Смотри комментарий (2) к Стандарту 12.</p><br>

<p style="text-indent:2em;">23. Стандарт. Единственной декларацией, требуемой на товары, которые разрешены для ввоза 
на таможенную территорию соответствующего государства при их вывозе из свободной зоны, 
должна быть товарная декларация (1), обычно требуемая для осуществления таможенных процедур, 
предназначенных для этих товаров.</p><br>

<p style="font-weight:500;">Комментарий.</p><br>

<p style="text-indent:2em;">(1) Документы, требуемые для предоставления национальным законодательством в поддержку 
товарной декларации, также охватываются термином "товарная декларация".</p><br>

<p style="text-indent:2em;">24. Рекомендуемая практика. Товары, разрешенные к вывозу из свободной зоны на таможенную 
территорию заинтересованного государства, должны подпадать под процедуру условного освобождения
 или под действующие процедуры по переработке на условиях, применяемых в отношении товаров, 
 ввозимых непосредственно из-за границы.</p><br>

<p style="text-indent:2em;">25. Стандарт. Национальное законодательство должно точно установить точку отсчета во времени,
 которую необходимо принимать во внимание с целью определения стоимости и количества товаров,
 могущих быть использованными для свободного обращения внутри страны при вывозе из свободной зоны
 и ставки импортных пошлин и налогов, применяемых к ним (1).</p><br>

<p style="font-weight:500;">Комментарий.</p><br>

<p style="text-indent:2em;">(1) Данное положение не исключает возможности соответствующего лица выбирать ставку пошлины,
 применяемую к его товарам в случае изменения ставок после допуска товаров в свободную зону.
 Что касается различных альтернатив для определения точки отсчета во времени, которую необходимо
 принимать во внимание с целью определения применяемой ставки, должна быть сделана ссылка на 
 Примечание к Стандарту 47 Приложения В.1, касающегося выпуска товаров для свободного обращения
 внутри страны.</p><br>

<p style="text-indent:2em;">26. Стандарт. Национальное законодательство должно точно устанавливать правила, применяемые для
 определения суммы импортных пошлин и налогов, взимаемых с товаров для внутреннего потребления 
 после манипуляции или переработки в свободной зоне.</p><br>

<p style="font-weight:500;">Примечание.</p><br>

<p style="text-indent:2em;">1. Сумма импортных пошлин и налогов, взимаемых с товаров, ввезенных для внутреннего потребления
 после переработки в свободной зоне, может быть ограничена суммой импортных налогов и пошлин, 
 взимаемых с использованных иностранных товаров в состоянии, в котором они были ввезены в свободную
 зону, плюс, если были использованы национальные товары или товары, ввезенные с уплатой импортных
 пошлин и налогов, сумма любого освобождения от оплаты внутренних пошлин и налогов или импортных 
 налогов и пошлин, предоставленных при ввозе этих товаров в свободную зону (1), или возврата 
 таковых.</p><br>

<p style="text-indent:2em;">2. Может быть установлена специальная процедура обложения, при которой оборудование, используемое 
для переработки в свободной зоне, может быть допущено без взимания импортных пошлин и налогов.</p><br>

<p style="font-weight:500;">Комментарий.</p><br>

<p style="text-indent:2em;">(1) Примечание указывает лишь на один из нескольких возможных методов определения суммы взимаемых
 импортных пошлин и налогов. Следует отметить, что если с переработанной в свободной зоне продукции 
 были взысканы импортные пошлины и налоги согласно тех же правил, которые применяются к товарам, 
 ввезенным непосредственно из-за границы, выгоды, которые международная торговля получает от свободных
 зон, будут значительно уменьшены.</p><br>

<p style="font-weight:500;">Ликвидация свободной зоны</p><br>

<p style="text-indent:2em;">27. Стандарт. В случае ликвидации свободной зоны заинтересованным лицам должно быть предоставлено
 достаточно времени, чтобы распорядиться своими товарами.</p><br>

<p style="font-weight:500;">Комментарий.</p><br>

<p style="text-indent:2em;">(1) Власти, которые имеют право учреждать свободную зону, обычно уполномочиваются принимать решение
 о ее ликвидации.</p><br>

<p style="font-weight:500;">Информация о свободных зонах</p><br>

<p style="text-indent:2em;">28. Стандарт. Таможенные власти должны обеспечить, чтобы вся относящаяся к делу информация о
 таможенных правилах, применяемых к свободным зонам, была доступна для любого заинтересованного
 лица (1).</p><br>

<p style="font-weight:500;">Комментарий.</p><br>

<p style="text-indent:2em;">(1) Такая информация может быть сделана доступной для заинтересованных лиц путем использования
 обычных средств информации, таких как периодические издания (официальные издания), эта информация
 по запросу должна без затруднений предоставляться в центральном таможенном учреждении.</p><br>
 
 <p><a href="https://online.zakon.kz/Document/?doc_id=1030546">Источник</a></p>

</div>
`