import { connect } from 'react-redux'
import { compose, pure } from 'recompose'
import { reduxForm, formValueSelector } from 'redux-form'
import { withRouter } from 'react-router-dom'

import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'
import {
  cargoSearch,
  cargoAddFavourite,
  cargoDeleteFavourite,
  cargoNotificationUnsubscribe,
  transportAddFavourite,
  transportDeleteFavourite,
  transportSearch,
} from '../../../store/actions'
import parseUrlParamsFromString from '../../shared/parseUrlParamsFromString'
import { socketRefreshSearchFinish } from '../../../store/actions'
import Component from './component'
import { SearchResultsProps, SearchResultsActions } from './types'
import { IReduxStorage } from '../../../store/reducers/types'
import { setScrollToOncommingItems } from '../../../store/actions/profile'

const FORMNAME = 'searchResults'
const formSelector = formValueSelector(FORMNAME)



export default compose(
  withTranslation,
  withPermissions,
  withRouter,
  pure,
  connect(
    (state: IReduxStorage, props: SearchResultsProps) => {
      const { cargo, transport, auth, socket, form, profile } = state
      const search = props.searchType === 'transport' ? transport.search : cargo.get('search')
      const pageSize = formSelector(state, 'pageSize')
      const urlParams = parseUrlParamsFromString(window.location.search)
      const autoupdate = localStorage.getItem('autoupdate')
      const { filterKey, sameCountryOnly } = urlParams

      const initialValues = {
        filterKey: 'all',
        openIn: 'preview',
        sortBy: 'date-add',
        pageSize: 20,
        autoupdate: JSON.parse(localStorage.getItem('autoupdate')) || true,
        oncomingTransport: JSON.parse(localStorage.getItem('oncomingTransport')) || true,
        allRequests: JSON.parse(localStorage.getItem('allRequests')) || true,
        sameCountryOnly
      }

     
      return {
        userId: auth.jwt.userId,
        processing: search.get('processing'),
        pageSize,
        filterKey,
        sameCountryOnly,
        pageIndex: search.get('pageIndex'),
        totalItems: search.get('totalItems'),
        queryStr: search.get('queryStr'),
        items: search.get('items'),
        error: search.get('error'),
        refreshing: socket.searchRefresh,
        openIn: formSelector(state, 'openIn'),
        initialValues,
        autoupdate,
        searchForm: form,
        statistics: profile.statistics,
        scrollToOncommingItems: profile.scrollToOncommingItems
      }
    },
    (dispatch: any, props: SearchResultsProps): SearchResultsActions => {
      const isCargo = props.searchType === 'cargo'
      return {
        search: data => dispatch(isCargo ? cargoSearch(data) : transportSearch(data)),
        addFavourite: entityId =>
          dispatch(
            isCargo
              ? cargoAddFavourite({ cargoId: entityId })
              : transportAddFavourite({ transportId: entityId })
          ),
        deleteFavourite: entityId =>
          dispatch(
            isCargo
              ? cargoDeleteFavourite({ cargoId: entityId })
              : transportDeleteFavourite({ transportId: entityId })
          ),
        notificationUnsubscribe: () => dispatch(cargoNotificationUnsubscribe()),
        socketRefreshSearchFinish,
        setScrollToOncommingItems: bool => dispatch(setScrollToOncommingItems(bool)),
      }
    }
  ),
  reduxForm({
    form: FORMNAME,
    destroyOnUnmount: false,
    onChange: (values: any, dispatch, props: SearchResultsProps, previousValues: any) => {
      const { filterKey, openIn, sortBy, pageSize } = values
      const { isMyOffers, userId, searchForm } = props
      const query = searchForm.search?.values
      if (openIn === previousValues.openIn) {
        props.search({
          query,
          sortBy,
          pageSize,
          exactUserId: isMyOffers && userId,
          dateScope: isMyOffers ? null : filterKey,
          activeScope: isMyOffers ? filterKey : null,
        })
      }
    },
  })
)(Component) as React.ComponentClass<SearchResultsProps>
