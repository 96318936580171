import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { Field } from 'redux-form'

import { required } from '../../../shared/form-validators'
import Dropdown from '../../../UI/dropdown'
import dict from '../../../shared/dictionaries'
import RenderNumber from '../render-number'

import './styles.scss'

// const STYLE_BASE = 'cargo-add_'

const cargoFromTo = ({
  t,
  title,
  titleRadius,
  nameCountry,
  nameCity,
  nameRange,
  langId,
  countryId,
  onChange,
}) => {
  return (
    <Fragment>
      <label className="required-field">{title}</label>
      <div className="row row-lg">
        <div className="col-xs-10 col-sm-12">
          <div className="row row-lg">
            <div className="col-sm-8">
              <div className="select-container">
                <Field
                  name={nameCountry}
                  component={Dropdown}
                  items={dict.countries(langId)}
                  emptyOption={t('select.country')}
                  validate={[required]}
                  onChange={value => onChange && onChange(nameCountry, value)}
                  //   isRequired={true}
                />
              </div>
            </div>
            <div className="col-sm-8">
              <div className="select-container">
                <Field
                  name={nameCity}
                  component={Dropdown}
                  items={dict.cities(langId, countryId)}
                  emptyOption={t('select.city')}
                  validate={[required]}
                  onChange={value => onChange && onChange(nameCity, value)}
                  //   isRequired={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-6 col-sm-4 custom-add-wrapper">
          <label htmlFor="inp1">{titleRadius}</label>
          <div className="input-container input-custom-add">
            <Field
              name={nameRange}
              component={RenderNumber}
              type="text"
              placeholder="000.0"
              unit={t('unit.distance.km')}
              integerLimit={3}
              decimalLimit={1}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default class extends Component {
  render() {
    const {
      t,
      className,
      titleFrom,
      titleTo,
      radiusTitleFrom,
      radiusTitleTo,
      fromCountryId,
      toCountryId,
      onChange,
      langId,
    } = this.props

    return (
      <div
        className={classNames({
          'panel-content-row': true,
          [className]: !!className,
        })}
      >
        <div className="panel-form-row">
          {cargoFromTo({
            t,
            title: titleFrom,
            titleRadius: radiusTitleFrom,
            nameCountry: 'from.countryId',
            nameCity: 'from.cityId',
            nameRange: 'from.range',
            langId,
            countryId: fromCountryId,
            onChange: onChange,
          })}
        </div>
        <div className="panel-form-row">
          {cargoFromTo({
            t,
            title: titleTo,
            titleRadius: radiusTitleTo,
            nameCountry: 'to.countryId',
            nameCity: 'to.cityId',
            nameRange: 'to.range',
            langId,
            countryId: toCountryId,
            onChange: onChange,
          })}
        </div>
      </div>
    )
  }
}
