export const md6 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->


<p></p><h4>Правила автомобильных перевозок грузов</h4><p></p>

<p></p><h5>I. Общие положения</h5><p></p>

<p style="font-weight:500;">1. Настоящие правила разработаны Министерством транспорта и связи в соответствии со ст.2 и ст.54 Кодекса автомобильного транспорта.</p><br>

<p style="font-weight:500;">2. Настоящие правила определяют порядок организации автомобильных перевозок грузов на территории Республики Молдова.</p><br>

<p style="text-indent:2em;">Международные автомобильные перевозки грузов осуществляются в соответствии с международными договорами и соглашениями и межправительственными соглашениями,
 одной из сторон которых является Республика Молдова, а также согласно ее внутреннему законодательству.</p><br>

<p style="font-weight:500;">3. Перевозка отдельных видов,имеющих особую специфику, регулируется в соответствии с правилами, разработанными в установленнном порядке.</p>

<p style="text-indent:2em;"></p><h6>4. Определения:</h6><p></p>

<p style="text-indent:2em;">\"транспортный агент\" - любое физическое или юридическое лицо, перевозящее на автотранспортных средствах пассажиров, багаж и грузы хозяйствующих
 субъектов и граждан,либо выполняющее или организующее по своей инициативе или по соглашению с грузоотправителем(грузополучателем) операции,предусмотренные
 транспортно-экспедиционным договором;</p><br>

<p style="text-indent:2em;">\"перевозчик\" - любое физическое или юридическое лицо, осуществляющее перевозку грузов своими собственными транспортными средствами (фактический
 или действительный перевозчик) или любое физическое или юридическое лицо,выполняющее перевозку грузов посредством возложения на себя обязательств
 перевозчика по доставке груза получателю (договорной перевозчик);</p><br>

<p>\"грузоотправитель\" - физическое или юридическое лицо,являющееся собственником или распорядителем груза;</p><br>

<p>\"грузополучатель\" - физическое или юридическое лицо, имеющее право на получение перевозимого груза;</p><br>

<p style="text-indent:2em;">\"перевозимые грузы\" - любые товарно-материальные ценности, предъявляемые грузоотправителем к перевозке с момента принятия их к перевозке
 транспортным агентом до момента сдачи грузополучателю;</p><br>

<p>\"товарный груз\" - груз, имеющий материальную и товарную ценность;</p><br>

<p>\"нетоварный груз\" - груз,не имеющий материальной и товарной ценности;</p><br>

<p>\"мелкие отправки\" - перевозки небольших партий грузов разнообразной номенклатуры.</p>

<p></p><h5>II. Организация перевозок грузов</h5><p></p>

<p style="font-weight:500;">5. Транспортные агенты организуют свою деятельность самостоятельно исходя из спроса на транспортные услуги.</p><br>

<p style="font-weight:500;">6. Транспортные агенты принимают к перевозке грузы на основании договоров на перевозку грузов, заключенных между грузоотправителями или грузополучателями.</p><br>

<p style="font-weight:500;">7. В соответствии с договором на перевозку грузов транспортный агент обязуется в установленные сроки принимать, а грузоотправитель (грузополучатель)
 предъявлять к перевозке грузы в обусловленном объеме.</p><br>

<p style="font-weight:500;">8. Транспортный агент может заключать договор на перевозку грузов с любыми экономическими агентами или гражданами, не являющимися грузоотправителями или
 грузополучателями. В данном случае экономические агенты или граждане являются заказчиками, пользуются правами и берут на себя обязанности и ответственность,
 предусмотренные настояшими правилами для грузоотправителей и грузополучателей.</p><br>

<p style="font-weight:500;">9. При взаимоотношениях транспортных агентов с грузоотправителями (грузополучателями), основанных на договоре, грузоотправители (грузополучатели)
 представляют транспортному агенту заявки на перевозку грузов в письменном виде.</p><br>

<p style="font-weight:500;">10. При необходимости исполнения срочного задания транспортный агент может принять заявку устно или по телефону с последующим обязательным оформлением
 этой заявки в десятидневный срок в письменном виде.</p><br>

<p style="font-weight:500;">11. Изменения и дополнения должны производиться только в письменном виде.</p><br>

<p style="font-weight:500;">12. Форма заявки на перевозку грузов прилагается и носит рекомендательный характер (Приложение 1).</p>

<p></p><h5>III. Договор на перевозку грузов</h5><p></p>

<p style="font-weight:500;">13. В соответствии с договором на перевозку грузов транспортный агент обязуется доставить вверенный ему грузоотправителем груз в пункт назначения и 
выдать его грузополучателю, указанному в товарно-транспортной накладной, а грузоотправитель или другой экономический агент или гражданин, подтвержденный
 договором перевозки, обязуется уплатить за перевозку груза установленную плату.</p>

<p style="text-indent:2em;"></p><h6>14. Договор на перевозку грузов должен содержать следующие условия:</h6><p></p>

<p>- наименования и адреса сторон;</p><br>

<p>- предмет договора;</p><br>

<p>- обязанности перевозчика;</p><br>

<p>- обязанности заказчика;</p><br>

<p>- расчеты за перевозку;</p><br>

<p>- ответственность сторон.</p><br>

<p style="font-weight:500;">15. В договор на перевозку грузов по соглашению сторон могут включаться и иные условия.</p><br>

<p style="font-weight:500;">16. Споры и разногласия между транспортным агентом, который осуществляет перевозку,и грузоотправителем, возникшие при исполнении договора,
 регулируются действующим законодательством Республики Молдова и условиями конкретного договора на перевозку грузов.</p><br>

<p style="font-weight:500;">17. Договор прилагается и носит рекомендательный характер (Приложение 2).</p>

<p></p><h5>IV. Правила приема грузов к перевозке</h5><p></p>

<p style="font-weight:500;">18. Транспортные агенты принимают грузы к перевозке на основе договоров на перевозку грузов.</p><br>

<p style="text-indent:2em;">Они обеспечивают первоочередность перевозки договорных грузов, за исключением случаев стихийных бедствий,катастроф, аварий и других чрезвычайных
 ситуаций.</p><br>

<p style="font-weight:500;">19. Типы и количество автомобилей,необходимых для осуществления перевозок грузов,специфику этих грузов, а также маршрут перевозки оговариваются
 в договоре на перевозку грузов.</p><br>

<p style="font-weight:500;">20. Транспортный агент обязан подать грузоотправителю под погрузку предусмотренное исправное автотранспортное средство, пригодное для перевозки
 данного вида груза и отвечающее требованиям санитарных норм и охраны окружающей среды,в согласованные с грузоотправителем сроки.</p><br>

<p style="text-indent:2em;">Подача автотранспортного средства, не пригодного для перевозки указанного в договоре (заявке) груза,приравнивается к неисполнению договорных
 обязательств. При отказе грузоотправителя от поданного транспортным агентом подвижного состава составляется акт по форме, установленной в
 договоре на перевозку грузов.</p><br>

<p style="font-weight:500;">21. Грузоотправитель обязан обеспечить подготовку, затаривание, маркировку и погрузку груза на подвижной состав в согласованные с
 транспортным агентом сроки.</p><br>

<p style="font-weight:500;">22. Если грузоотправитель предъявил к перевозке груз, не предусмотренный договором, или указывает другой пункт назначения, транспортный
 агент вправе отказаться от перевозки,взыскав стоимость пробега автомобиля в обоих направлениях до пункта погрузки.</p>

<p style="text-indent:2em;"></p><h6>23. Грузоотправитель не имеет права предъявлять, а транспортный агент принимать груз к перевозке в случаях, когда:</h6><p></p>

<p>1) грузы не оформлены товарно-транспортной накладной;</p><br>

<p style="text-indent:2em;">2) предъявляемые к перевозке различные виды грузов мелкими отправками по своим свойствам не допускаются к совместной перевозке без
 специальной упаковки в одном автомобиле.</p><br>

<p style="font-weight:500;">24. За предъявление запрещенного к перевозке груза или груза, при перевозке которого требуются особые меры предосторожности, с неправильным
 указанием в грузосопроводительных документах его наименования или свойств, а также за непредставление к такому грузу документов,подтверждающих
 соблюдение норм таможенного, санитарного и административного права,грузоотправитель возмещает причиненные транспортному агенту убытки.</p><br>

<p style="font-weight:500;">25. Грузы, которые могут нанести ущерб другим грузам (опасные грузы), а также скоропортящиеся грузы принимаются транспортным агентом только по письменной заявке.</p><br>

<p style="font-weight:500;">26. Грузоотправитель (грузополучатель) обязан возместить транспортному агенту убытки,причиненные в связи с повреждением автотранспортного средства
 в результате неправильных упаковки и крепления груза грузоотправителем (грузополучателем) при их погрузке (разгрузке).</p><br>

<p style="font-weight:500;">27. Сопровождение груза может быть организовано как грузоотправителем, так и транспортным агентом согласно заключенному договору.</p><br>

<p style="font-weight:500;">28. При приеме груза к перевозке водитель или экспедитор предъявляет грузоотправителю служебное удостоверение и путевой лист, заверенные печатью
 транспортного агента.</p><br>

<p style="text-indent:2em;">Путевой лист дает право на въезд подвижного состава и находящихся в нем лиц, указанных в путевом листе,на территорию грузоотправителя (грузополучателя),
 если для этого не требуется оформления специального пропуска.</p><br>

<p style="text-indent:2em;">В тех случаях, когда для въезда (выезда) автомобиля требуются специальные пропуска, транспортный агент и грузоотправитель (грузополучатель)
 должны в договоре на перевозку грузов предусмотреть порядок выдачи указанных пропусков для приема и выдачи грузов.</p><br>

<p style="font-weight:500;">29. Транспортные агенты по соглашению с грузоотправителями (грузополучателями) могут перевозить грузы с объявленной ценностью.</p><br>

<p style="text-indent:2em;">За перевозку грузов с объявленной ценностью с грузоотправителей (грузополучателей) взимается дополнительная плата, размер которой устанавливается
 соглашением сторон по договору на перевозку грузов.</p><br>

<p style="text-indent:2em;">При предъявлении к перевозке груза с объявленной ценностью грузоотправитель обязан составить опись грузовых мест по форме, установленной договором
 на перевозку грузов, в трех экземплярах. Один экземпляр описи остается у грузоотправителя,второй у транспортного агента, а третий вкладывается
 грузоотправителем во внутрь грузового места.</p>

<p style="text-indent:2em;"></p><h6>30. Отправитель обязан объявить ценность следующих грузов:</h6><p></p>

<p style="text-indent:2em;">1) драгоценных металлов и изделий из них,драгоценных камней, предметов искусства,картин, антикварных изделий,произведений искусства, ковров,
 опытных машин, оборудования и приборов, на которые не утверждены цены;</p><br>

<p>2) домашних вещей.</p><br>

<p style="font-weight:500;">31. Не допускается объявление ценности грузов,перевозимых навалом, насыпью, наливом, за пломбами грузоотправителей, а также скоропортящихся и
 опасных грузов. Не допускается объявление ценности части груза,перевозимого по одной товарно-транспортной накладной.</p><br>

<p style="font-weight:500;">32. Объявленная ценность не должна превышать действительной стоимости груза. В случае разногласий между транспортным агентом и грузоотправителем
 стоимость груза определяется экспертизой, о чем составляется акт.</p><br>

<p style="font-weight:500;">33. При сдаче грузоотправителем и приеме транспортным агентом грузов,перевозимых навалом, насыпью, наливом и в контейнерах, должен быть определен
 и узан в товарно-транспортной накладной вес этих грузов.</p><br>

<p style="text-indent:2em;">Тарные и штучные грузы принимаются к перевозке с указанием в товарно-транспортной накладной веса груза и количества грузовых мест.</p><br>

<p style="text-indent:2em;">Вес тарных и штучных грузов определяется грузоотправителем до предъявления их к перевозке и указывается на грузовых местах.</p><br>

<p style="text-indent:2em;">Общий вес груза определяется взвешиванием на весах или подсчетом веса на грузовых местах по трафарету или стандарту.</p><br>

<p style="text-indent:2em;">Для отдельных грузов, по которым определение веса невозможно, вес может определяться расчетным путем, по обмеру, по объемному весу или условно.</p><br>

<p>Запись в товарно-транспортной накладной о весе груза производится грузооотправителем.</p><br>

<p style="font-weight:500;">34. Определение веса груза производится совместно грузоотправителем и транспортным агентом техническими средствами грузоотправителя.</p><br>

<p style="text-indent:2em;">При перевозке грузов в крытых автомобилях и прицепах, отдельных секциях автомобилей, контейнерах и цистернах, опломбированных грузоотправителем,
 определение веса груза производится грузоотправителем.</p><br>

<p style="font-weight:500;">35. Грузы, имеющие маркировочный вес нетто или брутто, перевеске не подлежат. Водитель-экспедитор при отсутствии следов вскрытия тары или упаковки
 принимает такой груз у грузоотправителя согласно весу, указанному в маркировке.</p><br>

<p style="text-indent:2em;">Если предъявляются грузовые места одного стандартного размера в адрес одного грузополучателя,указание веса на каждом грузовом месте не обязательно,
 за исключением случаев, когда национальными стандартами предусмотрено обязательное указание веса брутто и нетто на стандартных местах.</p><br>

<p style="font-weight:500;">36. Количество грузов нетоварного характера определяется по актам замера (взвешивания). При массовых перевозках грунта определение его количества
 может производиться геодезическим замером.</p><br>

<p style="text-indent:2em;">Акт замера(взвешивания) составляется на основании контрольного взвешивания 5-10 автомобилей, после чего определяется средний вес груза в одном
 автомобиле соответствующей марки.</p><br>

<p style="text-indent:2em;">При определении количества груза геодезическим замером общий вес груза устанавливается путем умножения объемных показателей геодезического замера
 на объемный вес груза,определяемый лабораторным методом.</p><br>

<p style="text-indent:2em;">При изменении характера груза или других условий перевозок по требованию транспортного агента или грузоотправителя (грузополучателя) производится
 новое контрольное взвешивание или определение объемного веса груза.</p><br>

<p style="text-indent:2em;">Транспортный агент, грузоотправитель или грузополучатель не вправе отказаться от повторного взвешивания или определения объемного веса груза.</p><br>

<p style="font-weight:500;">37. Грузы, нуждающиеся в таре для предохранения их от утраты, недостачи, порчи и повреждения при перевозке,должны предъявляться к перевозке в 
исправной таре,соответствующей национальным стандартам или техническим условиям, или в установленных случаях в иной исправной таре, 
обеспечивающей их полную сохранность.</p><br>

<p style="font-weight:500;">38. Грузоотправитель отвечает за все последствия неправильной внутренней упаковки грузов(бой, поломка, деформация, течь и т.д.), а также применения
 тары и упаковки, не соответствующих свойствам груза, его весу или установленным стандартам и техническим условиям.</p><br>

<p style="font-weight:500;">39. Скоропортящиеся грузы принимаются к перевозке транспортным агентом в первоочередном порядке. Перевозка скоропортящихся грузов осуществляется
 согласно Правилам перевозки скоропортящихся грузов.</p><br>

<p style="font-weight:500;">40. Опасные грузы принимаются к перевозке транспортным агентом в порядке и в случаях, предусмотренных Правилами перевозки опасных грузов.</p><br>

<p style="font-weight:500;">41. Тяжеловесные и негабаритные грузы принимаются к перевозке транспортным агентом в порядке и в случаях, предусмотренных Инструкцией по перевозке
 тяжеловесных и негабаритных грузов.</p>

<p></p><h5>V. Правила маркировки грузов</h5><p></p>

<p style="font-weight:500;">42. При предъявлении грузов в таре или упаковке и штучных грузов мелкими отправками грузоотправитель обязан заблаговременно замаркировать каждое
 грузовое место.</p>

<p style="text-indent:2em;"></p><h6>В маркировке указываются:</h6><p></p>

<p style="text-indent:2em;">а) знак получателя - полное или сокращенное наименование грузополучателя или обозначение,применяемое получателем(при мелкопартионных перевозках);</p><br>

<p>b) номер заказа-наряда;</p><br>

<p>c) место назначения - обозначается указанием пункта (при междугородных перевозках);</p><br>

<p>d) вес грузовой единицы - обозначается числами, определяющими вес брутто и вес нетто в килограммах.</p><br>

<p>Данные, приведенные в сопроводительных документах, должны полностью соответствовать маркировке.</p><br>

<p style="text-indent:2em;">Грузоотправитель обязан также наносить специальную маркировку, указывающую свойства грузов, способ обращения с ними при погрузочно-разгрузочных работах,
 перевозке и хранении (например, \"Верх\", \"Не кантовать\" и т.д.).</p>

<p style="text-indent:2em;"></p><h6>43. Маркировка должна быть проведена одним из следующих способов:</h6><p></p>

<p>а) непосредственно нанесением знаков на грузовые места;</p><br>

<p>b) с помощью ярлыков.</p><br>

<p style="text-indent:2em;">Маркировка может производиться окраской по шаблону, штамповкой, клеймением или специальными маркировочными машинами. Маркировка от руки допускается
 в исключительных случаях.</p><br>

<p style="font-weight:500;">44. Маркировка должна производиться на упаковке условными обозначениями (знаками), выраженными надписью, буквами, цифрами или рисунками (символами)
 с применением контрастной краски. Цвет краски должен резко отличаться от цвета тары и груза.</p><br>

<p>Маркировка мест груза должна быть четкой, ясной и надежной.</p><br>

<p style="font-weight:500;">45. Маркировка должна производиться краской, хорошо удерживающейся на любой поверхности (нестирающейся и неотслаивающейся), светостойкой и не
 смывающейся водой.</p><br>

<p style="font-weight:500;">46. Маркировочные ярлыки могут быть изготовлены из бумаги, картона, ткани, фанеры, металла, пластмассы.</p>

<p style="text-indent:2em;"></p><h6>Маркировка на ярлыки должна быть нанесена одним из нижеследующих способов:</h6><p></p>

<p>а) типографским;</p><br>

<p>b) печатанием на машинке;</p><br>

<p>c) штемпелеванием по трафарету;</p><br>

<p>d) продавливанием.</p><br>

<p>Поверхность ярлыков должна быть устойчивой к воздействию климатических условий.</p><br>

<p style="font-weight:500;">47. Ярлыки из бумаги и картона должны быть прикреплены к таре клеем.</p><br>

<p>Ярлыки из ткани должны быть пришиты.</p><br>

<p>Ярлыки из фанеры, металла, пластмассы должны быть прикреплены болтами, шурупами, гвоздями.</p><br>

<p>Прибивание ярлыков к фанерным, картонным и бумажным ящикам не допускается.</p><br>

<p>Допускается прикрепление ярлыков к грузам проволокой, если другой способ прикрепления невозможен.</p><br>

<p style="font-weight:500;">48. При развозе таких грузов, как металлические прутки, трубы, громоздкий или с длинными рукоятками инструмент и т.д., в адрес нескольких
 грузополучателей допускается производить маркировку окраской концов масляной краской, по которой можно легко определить принадлежность их
 к одной партии.</p>

<p style="text-indent:2em;"></p><h6>49. Маркировка наносится:</h6><p></p>

<p>а) на ящиках - на одной из боковых сторон;</p><br>

<p>b) на мешках и тюках - на одной широкой стороне.</p><br>

<p>Предупредительная маркировка наносится на двух смежных сторонах.</p><br>

<p style="font-weight:500;">50. При невозможности нанести маркировку полностью на боковых или торцевых сторонах на малогабаритных ящиках высотой 200 мм и менее допускается
 маркировка на смежных стенках тары (в том числе на крышке).</p><br>

<p style="font-weight:500;">51. Предупредительные знаки следует располагать в левом верхнем углу от основной маркировки, за исключением знаков \"стропить здесь\" и \"центр тяжести\",
 которые следует наносить в обозначаемых ими местах.</p>

<p style="text-indent:2em;"></p><h6>52. При перевозке однородных грузов в адрес одного грузополучателя допускается нанесение маркировки не на всех грузовых местах, но не менее чем на четырех.
 В этих случаях замаркированные места укладываются:</h6><p></p>

<p>а) в фургонах - у двери маркировкой наружу;</p><br>

<p style="text-indent:2em;">b) на открытом подвижном составе - в верхнем ярусе погрузки по два места у каждого продольного борта кузова маркировкой наружу.</p><br>

<p>При перевозке грузов навалом,насыпью и наливом маркировка не производится.</p>

<p></p><h5>VI. Правила пломбировки грузов</h5><p></p>

<p style="font-weight:500;">53. Загруженные крытые автомобили (в т.ч. изотермические и рефрижераторы) и прицепы, отдельные секции автомобилей, контейнеры и цистерны с назначением одному
 грузополучателю должны быть грузоотправителем опломбированы, а мелкоштучные товары,находящиеся в ящиках,коробках и другой таре, опломбированы или 
 обандеролены.</p><br>

<p style="text-indent:2em;">Об опломбировании груза указывается в товарно-транспортной накладной и подтверждается печатью грузоотправителя.</p><br>

<p style="text-indent:2em;">Транспортный агент может по соглашению с грузоотправителем осуществлять перевозки грузов в крытых автомобилях, прицепах, отдельных секциях автомобилей и цистернах
 без пломбы. В этих случаях услуга, связанная с выполнением водителем-экспедитором дополнительных операций по приему и сдаче грузов, оплачивается по договорным
 тарифам.</p><br>

<p style="font-weight:500;">54. Водитель не принимает участия в перевесках и пересчетах грузов, прибывших в исправных автомобилях, прицепах, отдельных секциях автомобиля, контейнерах и
 цистернах с неповрежденными пломбами грузоотправителя, которые выдаются грузополучателю без проверки веса и состояния груза и количества грузовых мест.</p><br>

<p style="font-weight:500;">55. Пломбы грузоотправителя должны иметь сокращенное наименование грузоотправителя и контрольные знаки.</p><br>

<p style="text-indent:2em;">При пломбировании транспортным агентом пломбы должны иметь наименование транспортного агента и номер оттисков.</p><br>

<p style="font-weight:500;">56. Навешенная пломба не должна допускать возможности доступа к грузу и снятия пломбы с подвижного состава, секции или отдельного грузового места без
 нарушения целости.</p>

<p style="text-indent:2em;"></p><h6>57. Пломбы навешиваются:</h6><p></p>

<p>а) у фургонов - на всех дверях фургонов по одной пломбе;</p><br>

<p>b) у цистерн - на крышке люка и сливного отверстия;</p><br>

<p style="text-indent:2em;">c) у контейнеров - на дверях по одной пломбе, за исключением случаев, когда особый порядок пломбирования предусмотрен правилами перевозок отдельных видов
 наливных грузов;</p><br>

<p style="text-indent:2em;">d) у грузового места от одной до 4 пломб в точках стыкования окантовочных полос или других упаковочных материалов.</p><br>

<p style="font-weight:500;">58. Пломбирование груза, укрытого брезентом, можно производить только тогда, когда соединение его с кузовом обеспечивает невозможность доступа к грузу.
 Пломбы навешиваются на концах соединительного материала в местах стыкования последнего с кузовом подвижного состава.</p><br>

<p style="font-weight:500;">59. Перед пломбированием автофургонов (контейнера) обе дверные петли (накладки) должны быть укреплены закрутками из отожженной проволоки диаметром
 не менее 2 мм и длиной 250-260 мм.</p><br>

<p style="text-indent:2em;">Закручивание проволоки должно производиться специальной металлической пластинкой с двумя отверстиями диаметром 6-10 мм и расстоянием между ними 35 мм.</p><br>

<p style="text-indent:2em;">Укрепление дверных петель или накладок проволочными закрутками осуществляет тот, кто производит погрузку груза.</p><br>

<p style="font-weight:500;">60. Для пломбирования могут применяться свинцовые или полиэтиленовые пломбы с камерой или с двумя параллельными отверстиями, а также термически
 обработанная (отожженная) проволока диаметром 0,6 мм.</p><br>

<p style="font-weight:500;">61. Пломбы должны быть навешены на проволоку, предварительно скрученную в две нити. Скручивание проволоки производится из расчета четырех винтов
 на сантиметр длины.</p><br>

<p style="font-weight:500;">62. Петля, образуемая при пломбировании между ушками дверной накладки (крышки колпака цистерны) и пломбой, должна быть не более 25 мм.</p><br>

<p style="font-weight:500;">63. Пломбы сжимаются тисками так, чтобы с обеих сторон они получились четкими и ясными, а проволоку нельзя было вытащить из пломбы. После сжатия
 тисками каждая пломба должна быть тщательно осмотрена и в случае обнаружения дефекта (неясность цифр знака тисков, срез знаков и т.п.) заменяется
 другой.</p><br>

<p style="font-weight:500;">64. Контрольные знаки пломб должны иметь буквенные и цифровые обозначения, нумерация которых производится в последовательном порядке от 001 до 999.</p><br>

<p style="font-weight:500;">65. Транспортный агент не должен приступать к выполнению перевозки при наличии неправильно навешенной пломбы или неясных оттисков установленных
 на ней знаков.</p><br>

<p style="font-weight:500;">66. Обандероливание (опечатывание) тары производится при перевозке мелкоштучных товаров, находящихся в ящиках, коробках и т.д., для обеспечения 
сохранности груза.</p><br>

<p style="text-indent:2em;">Материалы, которыми произведено обандероливание (бумажная лента, тесьма и т.д.), должны представлять собой единое целое (без узлов наращивания)
 и скрепляться в местах соединений отличительным знаком изготовителя или грузоотправителя (печатью или штампом).</p><br>

<p style="text-indent:2em;">Обандероливание должно быть произведено так, чтобы без разрыва материала, которым оно произведено, доступ к грузу был невозможен.</p><br>

<p style="font-weight:500;">67. Транспортный агент несет ответственность за сохранность пломб и материала, посредством которого произведено обандероливание груза.</p>

<p></p><h5>VII. Правила погрузки и разгрузки грузов</h5><p></p>

<p style="font-weight:500;">68. Погрузка грузов на автомобиль, закрепление, укрытие и увязка грузов производятся грузоотправителем, а выгрузка грузов из автомобиля, снятие
 креплений и покрытий - грузополучателем.</p><br>

<p style="text-indent:2em;">Грузоотправитель и грузополучатель производят открытие и закрытие бортов автомобилей и люков автоцистерн, опускание и выемку шлангов из люков автоцистерн,
 привинчивание и отвинчивание шлангов.</p><br>

<p style="font-weight:500;">69. Транспортный агент может по соглашению с грузоотправителем или грузополучателем принять на себя погрузку и разгрузку грузов.</p><br>

<p style="text-indent:2em;">В случае участия водителя в погрузке и разгрузке он принимает груз с борта автомобиля при погрузке и подает его на борт автомобиля при разгрузке.</p><br>

<p style="font-weight:500;">70. В случае, когда транспортный агент по соглашению с грузоотправителем (грузополучателем) принимает на себя производство погрузочно-разгрузочных работ,
 он несет ответственность за порчу или повреждение груза при погрузке и разгрузке,происшедших по его вине.</p><br>

<p style="font-weight:500;">71. Грузоотправители и грузополучатели обязаны иметь подъездные пути от автомобильных дорог к пунктам погрузки и выгрузки и содержать их в исправном состоянии,
 обеспечивающем беспрепятственное и безопасное движение автотранспортных средств и их свободное маневрирование в любой момент осуществления перевозок.</p><br>

<p style="font-weight:500;">72. Грузоотправитель и транспортный агент при перевозке грузов обязаны в пределах объемов грузов, указанных в заявке грузоотправителем (грузополучателем),
 производить загрузку подвижного состава до полного использования его вместимости, но не выше его грузоподъемности.</p><br>

<p style="text-indent:2em;">При массовых перевозках легковесных грузов (в том числе сельскохозяйственных) допускается соглашением сторон по договору на перевозку грузов наращивание бортов,
 обеспечивающее повышение использования грузоподъемности подвижного состава.</p><br>

<p style="font-weight:500;">73. Тяжеловесные грузы без тары должны иметь специальные приспособления для застропки, как то: выступы, рамы, петли, проушины и др.</p><br>

<p style="text-indent:2em;">При перевозке на поддонах отдельные грузовые места укладываются на них таким образом, чтобы можно было проверить количество без нарушения их положения на поддоне
 и крепление (за исключением ящичных закрытых поддонов,перевозимых за пломбами грузоотправителя).</p><br>

<p style="font-weight:500;">74. Грузы должны быть уложены в подвижном составе и надежно закреплены так, чтобы не было сдвига, падения, навеса на двери, потертости или повреждения груза при
 перевозке, а также обеспечивалась сохранность подвижного состава при погрузке, разгрузке и в пути следования.</p><br>

<p>Запрещается крепление грузов гвоздями, скобами и другими средствами, повреждающими подвижной состав.</p><br>

<p style="font-weight:500;">75. Необходимые для погрузки и перевозки приспособления и вспомогательные материалы (козлы, стойки, прокладки, лотки, подкладки, проволока, щитовые ограждения и т.п.)
 должны предоставляться и устанавливаться грузоотправителем и сниматься грузополучателем, если иное не установлено соглашением сторон по договору на перевозку грузов.</p><br>

<p style="text-indent:2em;">Брезент и веревки для укрытия и увязки грузов предоставляются транспортным агентом с оплатой по договорным тарифам.</p><br>

<p style="font-weight:500;">76. Дополнительное оборудование и оснащение автомобилей для перевозки определенного груза могут производиться грузоотправителем только по согласованию с
 транспортным агентом.</p><br>

<p style="font-weight:500;">77. Грузоотправитель (грузополучатель) оплачивает стоимость произведенного по их требованиюпециального переоборудования кузовов автомобилей.</p><br>

<p style="font-weight:500;">78. Все приспособления, принадлежащие грузоотправителю, выдаются транспортным агентом грузополучателю вместе с грузом или возвращаются грузоотправителю в соответствии
 с его указанием в товарно-транспортной накладной и за его счет.</p><br>

<p style="font-weight:500;">79. Водитель автотранспортного средства обязан проверить соответствие укладки и крепления груза на автомобиле требованиям безопасности движения и обеспечения сохранности
 автотранспортного средства. Грузоотправитель по требованию водителя автотранспортного средства обязан устранить недостатки, обнаруженные в укладке и креплении груза.
 Если требования по устранению указанных недостатков не выполнены, водитель автотранспортного средства обязан отметить этот факт во всех экземплярах грузосопроводительного
 документа и отказаться от выполнения транспортных операций.</p><br>

<p style="text-indent:2em;">Исходя их требований безопасности движения, водитель обязан проверить соответствие габаритов груза Правилам дорожного движения, а также состояние крепления и увязки груза,
исключающих смещение груза за пределы кузова или его выпадение из кузова.</p><br>

<p style="font-weight:500;">80. Грузоотправитель (грузополучатель) обязан обеспечить контроль за соблюдением правил по технике безопасности при производстве погрузочно-разгрузочных работ и несут полную
 ответственность за несчастные случаи, происшедшие в результате невыполнения ими этих правил.</p><br>

<p style="text-indent:2em;">При осуществлении погрузочно-разгрузочных работ транспортным агентом обязанность по обеспечению контроля за соблюдением правил по технике безопасности при производстве
 погрузочно-разгрузочных работ, а также ответственность за возможные несчастные случаи,происшедшие в результате невыполнения этих правил, несет транспортный агент.</p><br>

<p style="font-weight:500;">81.Сроки погрузки грузов на автомобили (прицепы и полуприцепы) и разгрузки грузов, а также сроки выполнения дополнительных операций, связанных с погрузкой и разгрузкой грузов,
 устанавливаются по договоренности сторон, заключивших договор на перевозку грузов.</p><br>

<p style="text-indent:2em;">Время прибытия автомобиля под погрузку исчисляется с момента предъявления водителем путевого листа в пункте погрузки, а время прибытия автомобиля под разгрузку - с момента
 предъявления водителем товарно-транспортной накладной в пункте разгрузки.</p><br>

<p style="text-indent:2em;">При наличии в пунктах погрузки и разгрузки въездных дорог или контрольно-пропускных пунктов время прибытия автомобиля под погрузку (разгрузку) исчисляется с момента
 предъявления водителем путевого листа или товарно-транспортной накладной грузоотправителю (грузополучателю) у въездных ворот или на контрольно-пропускном пункте.</p><br>

<p style="text-indent:2em;">Погрузка и разгрузка считаются законченными после вручения водителю надлежаще оформленных товарно-транспортных документов на погруженный или выгруженный груз.</p><br>

<p style="text-indent:2em;">Время пробега автомобиля от ворот (контрольно-пропускного пункта) к месту погрузки или разгрузки и обратно исключается при исчислении времени нахождения автомобиля
 под погрузкой или разгрузкой.</p><br>

<p style="text-indent:2em;">В случае прибытия автомобиля под погрузку ранее согласованного времени автомобиль считается прибывшим под погрузку в согласованное время, если грузоотправитель не
 примет его под погрузку с момента фактического прибытия.</p><br>

<p style="text-indent:2em;">Время прибытия и убытия автомобиля для погрузки (разгрузки), а также время простоя автомобиля (часы, минуты) подтверждаются в товарно-транспортных накладных
 подписью и печатью грузоотправителя (грузополучателя).</p>

<p></p><h5>VIII. Правила оформления перевозочных документов</h5><p></p>

<p style="font-weight:500;">82. Перевозка грузов оформляется путевыми листами с приложенными к ним товарно-транспортными накладными.</p><br>

<p style="font-weight:500;">83. Грузоотправителям (грузополучателям) запрещается предъявлять (получать), а транспортным агентам принимать к перевозке грузы без оформления транспортных
 документов.</p><br>

<p style="font-weight:500;">84. Грузоотправители (грузополучатели) несут ответственность за неправильные,неточные и неполные данные, указанные в товарно-транспортной накладной.</p>

<p style="text-indent:2em;"></p><h6>85. Расчетные документы, составленные по путевым листам без приложения к ним товарно-транспортных документов, а также в случаях предоставления водителем путевого
 листа с приложением к нему неправильно оформленных (недооформленных) товарно-транспортных документов не должны предъявляться к оплате заказчику за работу
 автотранспорта, за исключением случаев:</h6><p></p>

<p style="text-indent:2em;">а) если при перевозке грузов нетоварного характера невозможен учет путем замера, взвешивания, геодезического замера (очистка территории от мусора, перевозка грузов
 внутри территории предприятий и строек и т.п.);</p><br>

<p>b) внутризаводских, внутрипостроечных и внутрискладских перевозок грузов;</p><br>

<p style="text-indent:2em;">c) использования автомобиля для обслуживания линий связи и электропередач, нефтегазопроводов, перевозки почты и периодической печати внутри города,
 а также для научно-изыскательских, геологических работ, киносъемок и при стихийных бедствиях.</p><br>

<p style="font-weight:500;">86. Товарно-транспортные накладные и путевые листы грузового автомобиля являются документами специального режима, предусмотренные с защитными знаками,
 которые изготавливаются типографским способом в установленном порядке (с учетной серией и номером, установленными централизованно).</p><br>

<p style="text-indent:2em;">Заполнение путевого листа для грузовых автомобилей осуществляется согласно инструкциям, утверждаемым в установленном порядке Департаментом статистических
 и социологических исследований.</p><br>

<p style="font-weight:500;">87. Товарно-транспортная накладная оформляется грузоотправителем для каждого грузополучателя отдельно с обязательным указанием всех реквизитов,
 необходимых для полноты и правильности расчетов по перевозкам, осуществляемым автомобилями, а также для вывода из хозяйствования и ввода в 
 хозяйствование товарно-материальных ценностей.</p><br>

<p style="text-indent:2em;">Товарно-транспортная накладная составляется в минимальном количестве экземпляров, как правило в четырех.</p><br>

<p style="font-weight:500;">88. В тех случаях, когда в товарно-транспортных накладных в разделе \"Сведения о грузах\" невозможно указать все названия и характер грузов и материальных ценностей,
 к товарно-транспортной накладной прилагается, как составная часть, помимо бланков первичных типовых документов специального режима, специализированные документы
 (формы и их содержание устанавливаются самостоятельно); например: сертификат качества, справки, карточки приема материалов и т.д., утвержденные
 Департаментом статистических и социологических исследований. В этом случае указывается в товарно-транспортной накладной в графе \"прилагаемые документы\",
 что в качестве документа о грузе прилагается специализированный документ, без которого товарно-транспортная накладная считается недействительной.</p><br>

<p style="text-indent:2em;">Грузоотправитель в случае необходимости может составить дополнительно и 5-й экземпляр,который служит разрешением при выходе автомобиля с места погрузки.</p><br>

<p style="font-weight:500;">89. Время прибытия и убытия автомобиля под погрузку указывается в соответствии с п.81 раздела VII настоящих правил.</p>

<p></p><h5>IX. Правила выдачи грузов</h5><p></p>

<p style="font-weight:500;">90. Транспортный агент выдает груз в пункте назначения, указанном в товарно-транспортной накладной. Получатель обязан обеспечить прием груза и разгрузку
 автомобиля в сроки, оговоренные в договоре на перевозку груза.</p><br>

<p style="font-weight:500;">91. Выдача груза грузополучателю в пункте назначения по весу и количеству мест производится в том же порядке, в каком груз был принят грузоотправителем
 (взвешиванием на весах, обмером, счетом мест и т.д.).</p><br>

<p style="text-indent:2em;">Грузы, прибывшие в исправных автомобилях, прицепах, отдельных секциях автомобиля, контейнерах и цистернах с неповрежденными пломбами грузоотправителя,
 выдаются грузополучателю без проверки веса и состояния груза и количества грузовых мест.</p><br>

<p style="text-indent:2em;">При перевозке грунта, снега и других грузов,по которым невозможно определить количество путем измерения, взвешивания и т.д., транспортные агенты по
 условиям договора могут быть освобождены от обязанности сдать груз грузополучателям.</p><br>

<p style="text-indent:2em;">Выдача получателю грузов, доставляемых со станций железных дорог, портов и пристаней, производится транспортным агентом в том же порядке, в каком грузы
 были приняты от станции железной дороги, порта, пристани.</p><br>

<p style="font-weight:500;">92. Тарные и штучные грузы, принятые к перевозке по стандартному весу или по весу, указанному грузоотправителем на каждом грузовом месте, выдаются
 грузополучателю в пункте назначения без взвешивания, по счету мест с проверкой веса и состояния груза только в поврежденных местах.</p><br>

<p style="text-indent:2em;">При отсутствии автомобильных весов у грузополучателя грузы, перевозимые навалом или насыпью, прибывшие без признаков недостачи выдаются без проверки веса.</p><br>

<p style="font-weight:500;">93. При определении количества груза с помощью взвешивания транспортный агент не несет материальной ответственности, если разница между весом груза в пункте
 отправления и в пункте назначения не превышает установленных стандартами норм естественной убыли для соответствующего вида груза.</p><br>

<p style="font-weight:500;">94. Если при проверке в пункте назначения веса,количества грузовых мест или состояния груза обнаружены недостача,порча или повреждение груза или если эти
 обстоятельства установлены актом, составленным в пути следования, транспортный агент обязан определить объем недостачи, порчи или повреждения груза.</p><br>

<p style="text-indent:2em;">При необходимости проведения экспертизы для установления размера или причины недостачи, порчи или повреждения груза и суммы, на которую понизилась его стоимость,
 транспортный агент по собственной инициативе или по требованию грузополучателя приглашает соответствующих специалистов.</p><br>

<p style="text-indent:2em;">Если транспортный агент уклоняется от вызова эксперта, грузополучатель вправе пригласить эксперта, уведомив транспортного агента о времени и
 месте проведения экспертизы.</p><br>

<p style="text-indent:2em;">До прибытия эксперта грузополучатель обязан обеспечить надлежащее хранение груза, исключающее дальнейшую его порчу. Экспертиза производится в
 присутствии представителей транспортного агента и грузополучателя. При неявке одной из сторон, извещенной о проведении экспертизы, последняя
 производится в его отсутствие и акт признается действительным. Результаты экспертизы оформляются актом. Акт экспертизы подписывается экспертом
 и лицами, присутствующими при производстве экспертизы.</p><br>

<p style="text-indent:2em;">Транспортный агент и грузополучатель могут назначить повторную экспертизу. Расходы по экспертизе относятся на сторону, виновную в недостаче,
 порче или повреждении груза.</p><br>

<p style="text-indent:2em;">При междугородных перевозках грузополучатель обязан принять от транспортного агента доставленный ему груз. В случае прибытия груза, поставка которого
 не предусмотрена договором на перевозку грузов, грузополучатель принимает такой груз на ответственное хранение, удостоверяя подписью и печатью
 прием груза от водителя.</p><br>

<p style="text-indent:2em;">Грузополучатель может отказаться от принятия груза лишь в том случае, когда качество груза вследствие порчи или повреждения, за которое транспортный
 агент несет ответственность, изменилось настолько, что исключается возможность полного или частичного использования груза.</p><br>

<p style="text-indent:2em;">При невозможности сдать груз грузополучателю при междугородных перевозках по причинам, не зависящим от транспортного агента, грузоотправитель обязан
 дать транспортному агенту указание о новом пункте назначения груза.</p><br>

<p style="text-indent:2em;">В случае, когда транспортный агент не имеет возможности доставить груз к месту нового назначения, он может отказаться от этой перевозки,
 поставив об этом в известность грузоотправителя. В этом случае транспортный агент возвращает груз грузоотправителю. Связанные с этим 
 дополнительные расходы (прогон и простой подвижного состава, хранение груза и т.п.) оплачиваются грузоотправителем.</p><br>

<p style="text-indent:2em;">Если скоропортящийся груз,перевозимый в междугородном сообщении, не может быть сдан грузополучателю, а грузоотправитель не дал указаний о
 новом грузополучателе, груз может быть сдан для реализации другому экономическому агенту.</p><br>

<p style="font-weight:500;">95. После выгрузки груза автомобили и контейнеры должны быть очищены грузополучателем от остатков этого груза, а после перевозки животных,
 птицы, сырых животных продуктов, скоропортящихся грузов грузополучатель обязан промыть подвижной состав и при необходимости произвести его
 дезинфекцию. После сдачи иных грузов промывка и дезинфекция подвижного состава производятся в соответствии с требованиями санитарных норм.</p><br>

<p style="text-indent:2em;">Транспортный агент может по согласованию с грузоотправителем или грузополучателем принять на себя за плату выполнение работ по очистке,
 промывке и дезинфекции загрязненной грузовой платформы (кузова) автомобиля и контейнера.</p>

<p></p><h5>X. Правила переадресовки грузов</h5><p></p>

<p style="font-weight:500;">96. При городских и пригородных перевозках в случаях отказа грузополучателя принять груз по причинам, не зависящим от транспортного агента,
 груз переадресовывается грузоотправителем другому грузополучателю или возвращается грузоотправителю. В этих случаях стоимость перевозки груза
 в оба конца, а также штраф за простой автомобиля оплачиваются грузоотправителем. Грузополучатель обязан сделать в товарно-транспортной накладной
 отметку об отказе в приеме груза с указанием причины отказа, заверенную подписью и печатью (штампом).</p><br>

<p style="font-weight:500;">97. При междугородных перевозках грузополучатель обязан принять от транспортного агента доставленный ему груз.</p><br>

<p style="text-indent:2em;">Грузоотправитель обязан сообщить транспортному агенту новый пункт назначения, в случае когда по причинам, не зависящим от транспортного агента,
 груз, перевозимый в междугородном сообщении, не может быть сдан грузополучателю.</p><br>

<p style="text-indent:2em;">В случае, когда транспортный агент не может доставить груз к новому пункту назначения, он вправе отказаться от этой перевозки, поставив об
 этом в известность грузоотправителя. В этом случае транспортный агент возвращает груз грузоотправителю. Связанные с этим дополнительные расходы
 (прогон и простой автомобилей, хранение груза и т.п.) оплачиваются грузоотправителем.</p>

<p style="text-indent:2em;"></p><h6>98. Распоряжение грузоотправителя транспортному агенту о переадресовке груза должно быть оформлено в письменном виде и содержать следующие данные:</h6><p></p>

<p>а) номер первого заказа и товарно-транспортной накладной;</p><br>

<p>b) адрес первоначального назначения;</p><br>

<p>c) наименование первоначального грузополучателя;</p><br>

<p>d) адрес нового назначения;</p><br>

<p>e) наименование нового грузополучателя;</p><br>

<p>f) причины переадресовки.</p><br>

<p style="text-indent:2em;">По соглашению между грузоотправителем и транспортным агентом распоряжение о переадресовке может допускаться по телефону с указанием всех
 перечисленных сведений и последующим письменным подтверждением.</p><br>

<p style="font-weight:500;">99. Распоряжение грузоотправителя по переадресовке грузов может быть выполнено только относительно всего груза, перечисленного в 
товарно-транспортной накладной.</p><br>

<p style="font-weight:500;">100. Переадресовка негабаритных грузов может быть разрешена только по получении грузоотправителем письменного или телеграфного уведомления
 от соответствующих органов (управления дорожной полиции и дорожных органов).</p><br>

<p style="font-weight:500;">101. При переадресовке груза реквизиты,указанные в графах \"грузополучатель\" и \"место разгрузки\", пишутся через дробь и в графе 
6 \"Переадресовано\" проставляются реквизиты нового грузополучателя.</p><br>

<p>Эти записи заверяются подписью водителя.</p>

<p style="text-indent:2em;"></p><h6>102. В случае отказа принять груз грузополучателем (в адрес которого произведена переадресовка) и невозможности получить указания от
 грузоотправителя в дальнейшем направлении груза, транспортный агент, выполняющий перевозку, вправе:</h6><p></p>

<p style="text-indent:2em;">а) сдать груз на хранение до получения указания грузоотправителя, а при наличии собственных складских помещений - принять груз на хранение;</p><br>

<p>b) передать груз другой организации, если он по своему характеру требует срочной реализации;</p><br>

<p>c) возвратить груз грузоотправителю.</p>

<p></p><h5>XI. Расчеты за перевозки</h5><p></p>

<p style="font-weight:500;">103. Размер платы за перевозку грузов определяется на основе тарифов, установленных законодательством, а в случаях, когда тарифы не установлены,
 - по соглашению сторон, заключивших договор на перевозку грузов.</p><br>

<p style="font-weight:500;">104. Форма расчетов должна быть обусловлена в договорах на перевозку грузов автомобильным транспортом.</p><br>

<p style="font-weight:500;">105. Окончательный расчет за перевозку груза производится грузоотправителем (грузополучателем) на основании счета транспортного агента.</p><br>

<p style="text-indent:2em;">Основанием для выписки счета за выполненные перевозки служат товарно-транспортные накладные, талон заказчика (оторванный от путевого листа)
 и международная транспортная накладная CMR.</p><br>

<p style="font-weight:500;">106. Для окончательного расчета транспортный агент в пятидневный срок выписывает счет грузоотправителю (грузополучателю)
 с приложением перевозочных документов.</p>

<p></p><h5>XII. Транспортно-экспедиционные услуги,оказываемые транспортными агентами</h5><p></p>

<p style="font-weight:500;">107. Транспортные агенты могут выполнять по транспортно- экспедиционному договору и экспедиторские услуги, связанные с перевозкой.</p><br>

<p style="text-indent:2em;">Перечень транспортно-экспедиционных услуг, а также порядок осуществления этих услуг устанавливаются Правилами предоставления
 транспортно-экспедиционных услуг, утвержденными в установленном порядке.</p>

<p></p><h5>XIII. Выдача лицензий на осуществление автомобильных перевозок</h5><p></p>

<p style="font-weight:500;">108. Виды деятельности, на которые необходимо получение лицензии, установлены законодательством Республики Молдова.</p><br>

<p style="text-indent:2em;">На каждое транспортное средство, деятельность которого осуществляется на основе лицензии, государственным предприятием 
\"Автотранспортная инспекция\" выдается \"Carnet de corespundere pentru vehicul\".</p>

<p></p><h5>XIV. Ответственность транспортных агентов,грузоотправителей и грузополучателей. Акты, претензии, иски</h5><p></p>

<p style="font-weight:500;">109. Транспортные агенты, грузоотправители и грузополучатели несут установленную законодательством имущественную ответственность
 за несоблюдение обязательств, предусмотренных Кодексом автомобильного транспорта и настоящими правилами.</p><br>

<p style="font-weight:500;">110. Транспортный агент за невывоз грузов в объеме, предусмотренном в договоре на перевозку грузов, а грузоотправитель (грузополучатель)
 за непредъявление к перевозке грузов в этих объемах несут материальную ответственность, устанавливаемую по соглашению сторон.</p><br>

<p style="font-weight:500;">111. Транспортные агенты несут ответственность за сохранность груза с момента принятия его к перевозке и до выдачи грузополучателю.</p>

<p style="text-indent:2em;"></p><h6>Транспортные агенты возмещают ущерб,причиненный при перевозке груза, в следующих размерах:</h6><p></p>

<p>а) за утрату или недостачу груза - в размере действительной стоимости утраченного или недостающего груза;</p><br>

<p>b) за порчу или повреждение груза - в размере суммы, на которую снизилась его стоимость;</p><br>

<p style="text-indent:2em;">c) за утрату,недостачу, порчу или повреждение груза с объявленной ценностью - в размере объявленной ценности, если не будет доказано,
 что объявленная ценность не соответствует действительной стоимости груза.</p>

<p style="text-indent:2em;"></p><h6>Грузоотправитель(грузополучатель) вправе считать груз утраченным и потребовать возмещения ущерба за его утрату,
 если груз не был выдан по его требованию:</h6><p></p>

<p>1) при городской и пригородной перевозке - в течение 10 дней со дня приема груза к перевозке;</p><br>

<p>2) при междугородной перевозке - в течение 30 дней с момента истечения указанного в договоре срока доставки.</p><br>

<p style="font-weight:500;">112. Наряду с возмещением ущерба, причиненного утратой, недостачей, порчей или повреждением груза транспортный агент должен возвратить
 грузоотправителю (грузополучателю) взысканную за перевозку утраченных, недостающих, испорченных или поврежденных грузов плату,
 если она не входит в стоимость груза.</p><br>

<p style="text-indent:2em;">Транспортный агент возмещает стоимость утраченного, недостающего, испорченного или поврежденного груза на основании счета или другого
 заменяющего его документа, предъявленного грузоотправителем (грузополучателем).</p><br>

<p style="text-indent:2em;">Стоимость груза определяется по среднерыночным ценам, действовавшим на дату и в месте выдачи груза грузополучателю, предусмотренных в договоре.</p><br>

<p style="font-weight:500;">113. При отказе физического или юридического лица - заказчика автотранспортных средств с повременным тарифом оплаты использовать последние в объеме,
 указанном в договоре, заказчик обязан уплатить неустойку по договорному повременному тарифу за простой указанных средств.</p><br>

<p style="text-indent:2em;">При применении других схем тарифов ответственность за отказ использовать автотранспортные средства в указанных объемах определяется согласно договору.</p><br>

<p style="font-weight:500;">114. За задержку по вине грузоотправителя (грузополучателя) автомобилей (автопоездов), поданных под погрузку или разгрузку,
 сверх установленных сроков, грузоотправитель или грузополучатель уплачивает транспортному агенту штраф за каждую минуту простоя
 автомобиля (автопоезда) в размере, предусмотренном соглашением сторон.</p><br>

<p style="font-weight:500;">115. Обстоятельства, которые могут служить основанием для привлечения к имущественной ответственности транспортных агентов,
 грузоотправителей, грузополучателей, удостоверяются записями в грузосопроводительных документах и актами установленной формы.</p><br>

<p style="font-weight:500;">116. До предъявления иска по автомобильным перевозкам обязательно предъявление претензии к участникам перевозки.</p><br>

<p style="text-indent:2em;">Претензии и иски по автомобильным перевозкам грузов рассматриваются в порядке,установленном законодательством.</p><br>

<p style="font-weight:500;">117. Передача права на предъявление претензий и исков другим физическим и юридическим лицам не допускается,
 за исключением случаев передачи такого права грузоотправителем грузополучателю или грузополучателем грузоотправителю либо грузоотправителем (грузополучателем)
 вышестоящей организации или транспортно- экспедиционной организации.</p>

<p></p><h5>Приложение 1</h5><p></p>

<p></p><h5>З А Я В К А на перевозку грузов (наименование транспортного агента, которому подается заявка)</h5><p></p>

<p>1. Грузоотправитель______(наименование,адрес, телефон)</p>
<p>2. Наименование и адрес пункта погрузки __________</p>
<p>Дата\"____\"________________ 20____г.</p>
<p>Прибытие под погрузку ________________ час.</p>
<p>3. Грузополучатель_____(наименование,адрес,телефон)</p>
<p>4.Наименование и адрес пункта разгрузки ___________</p>
<p>5.Характеристика груза:</p>
<p>наименование ___________________________</p>
<p>общий вес, тонн ________________________</p>
<p>объем,м ________________________________</p>
<p>вес одного места,кг ____________________</p>
<p>количество мест ________________________</p>
<p>6. Особые условия перевозки ____________</p>
<p>Подпись заказчика____________________</p>
<p>М.П. Дата \"____\"_____________________</p>
 
<p></p><h5>Приложение 2</h5><p></p>

<p></p><h5>Д О Г О В О Р на перевозку грузов</h5><p></p>

<p>N _____от________________20____г. наименование транспортного агента</p>
<p>___________, именуемое в дальнейшем \"Перевозчик\", в лице_________</p>
<p>(должность, фамилия _________________, действующего на основании __</p>
<p>имя)                                      (наименование документа)</p>
<p>с одной стороны, и _______________, (наименование грузоотправителя, </p>
<p>грузополучателя) именуемое в дальнейшем \"Заказчик\", в лице ______</p>
<p>,(должность, фамилия, имя) действующего на основании ______________,</p>
<p>(наименование документа) с другой стороны, заключили договор о </p>
<p>нижеследующем:</p>

<p></p><h5>I. ПРЕДМЕТ ДОГОВОРА</h5><p></p>

<p>1.\"Заказчик\"  предъявляет, а  \"Перевозчик\"  принимает  к   перевозке</p>
<p>грузы ______________________________________(вид груза , объем в тыс. тонн)</p>

<p></p><h5>II. РАСЧЕТЫ ЗА ПЕРЕВОЗКУ</h5><p></p>

<p>1. Расчеты за перевозку производятся по договорному тарифу.</p>
<p>2. При увеличении  затрат \"Перевозчика\" по причинам, которые от него</p>
<p>не зависят, договаривающиеся стороны пересматривают тариф.</p>
<p>3. Платежи за  перевозку грузов состоят из платежей (в леях, валюте)</p>
<p>и/или платежей в натуре _________________________(объем в ед.измерения)</p>
<p>и вносятся_______________________________________. сроки, вид оплаты)</p>
<p>4. Если оплата  по  счету не производится в установленный  настоящим</p>
<p>договором    срок,   \"Заказчик\"   выплачивает   \"Перевозчику\"   пеню</p>
<p>в размере ________% от суммы неоплаты за каждый день просрочки.</p>

<p></p><h5>III. ОБЯЗАННОСТИ \"ЗАКАЗЧИКА\"</h5><p></p>

<p style="text-indent:2em;"></p><h6>1.\"Заказчик\" обязан:</h6><p></p>

<p>1) подавать \"Перевозчику\" заявки на перевозку грузов;</p><br>

<p>2) предъявлять к перевозке грузы в указанных объемах и в согласованные с \"Перевозчиком\" дни и часы;</p><br>

<p>3) своевременно вносить плату за перевозки грузов;</p><br>

<p style="text-indent:2em;">4) предъявлять к перевозке грузы в надлежащей и исправной таре и упаковке, предохраняющей их от порчи и повреждения в пути следования;</p><br>

<p style="text-indent:2em;">5) осуществлять своими силами и средствами с соблюдением техники безопасности и обеспечения сохранности груза и подвижного состава погрузку
 и крепление груза на подвижной состав, не допуская его простоя под погрузкой сверх установленных норм времени;</p><br>

<p style="text-indent:2em;">6) иметь подъездные пути от автомобильных дорог к пунктам погрузки и выгрузки и содержать их в исправном состоянии,
 обеспечивающем беспрепятственное и безопасное движение автотранспортных средств и их свободное маневрирование в
 любой момент осуществления перевозки;</p><br>

<p style="text-indent:2em;">7) своевременно и надлежащим образом осуществлять оформление путевых и товарно-транспортных документов;</p><br>

<p style="text-indent:2em;">8) обеспечить \"Перевозчика\" специальными инструкциями по перевозке грузов, требующих особых условий
 (опасные, скоропортящиеся, негабаритные, тяжеловесные).</p>

<p></p><h5>IV. ОБЯЗАННОСТИ ПЕРЕВОЗЧИКА</h5><p></p>

<p style="text-indent:2em;"></p><h6>1. \"Перевозчик\" обязан:</h6><p></p>

<p>1) обеспечивать подачу подвижного состава под погрузку в согласованные сторонами дни и часы;</p><br>

<p style="text-indent:2em;">2) подавать под погрузку исправный подвижной состав, пригодный для перевозки данного вида груза и отвечающий требованиям санитарных
 и других норм;</p><br>

<p style="text-indent:2em;">3) доставить принятый к перевозке груз в пункт назначения и выдать его грузополучателям, указанным в товарно-транспортной накладной;</p><br>

<p>4) обеспечить сохранность в пути следования всех перевозимых по настоящему договору грузов;</p><br>

<p>5) соблюдать сроки доставки грузов.</p>

<p></p><h5>V. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ ПЕРЕВОЗОК</h5><p></p>

<p style="text-indent:2em;">(сроки доставки, режим работы, тариф за оказание транспортных услуг,
предоставление ГСМ и т.д.) __________________</p>

<p></p><h5>VI.ОТВЕТСТВЕННОСТЬ СТОРОН И ПОРЯДОК РАССМОТРЕНИЯ СПОРОВ</h5><p></p>

<p style="text-indent:2em;">1. В случае несоблюдения или ненадлежащего соблюдения условий договора стороны несут ответственность в соответствии с законодательством Республики Молдова.</p><br>

<p style="text-indent:2em;">2. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по настоящему договору, если оно явилось
 следствием обстоятельств непреодолимой силы (форс-мажорные обстоятельства), а именно: пожары, наводнения, землетрясения, а также забастовки,
 народные волнения.</p>

<p style="text-indent:2em;"></p><h6>3. \"Перевозчик\" несет ответственность за:</h6><p></p>

<p>- невывоз грузов - в размере штрафных санкций, предъявленных \"заказчиком\", оговоренных в договоре;</p><br>

<p>- сохранность груза с момента принятия его к перевозке и до выдачи грузополучателю.</p>

<p style="text-indent:2em;"></p><h6>4. \"Заказчик\" несет материальную ответственность за убытки, причиненные \"Перевозчику\":</h6><p></p>

<p>- ненадлежащим выполнением условий настоящего договора;</p><br>

<p style="text-indent:2em;">- непредъявлением груза к перевозке - в размере штрафных санкций, предъявленных \"Перевозчиком\", оговоренных в договоре;</p><br>

<p style="text-indent:2em;">- действиями, приведшими к простою транспортных средств, - в размере штрафных санкций, предъявленных \"Перевозчиком\".</p><br>

<p style="text-indent:2em;">5. Споры, возникшие при исполнении данного договора, стороны решают в претензионном порядке, исковые требования рассматриваются
 судебной инстанцией по месту нахождения ответчика.</p>

<p></p><h5>VII. СРОК ДЕЙСТВИЯ ДОГОВОРА</h5><p></p>

<p style="text-indent:2em;">1. Срок действия       настоящего      договора      устанавливается 
с_____________________  по ___________________ и может быть изменен  или
расторгнут  по  обоюдному  согласию,  а при разногласиях  -  в  судебном
порядке.</p><br>

<p>Настоящий договор  составлен  в  __________  экземплярах, каждый  из
которых имеет равную юридическую силу.</p>

<p></p><h5>VIII. БАНКОВСКИЕ РЕКВИЗИТЫ И ЮРИДИЧЕСКИЕ
АДРЕСА СТОРОН</h5><p></p>

<p>Перевозчик____________________   Заказчик___________________ </p>

<p></p><h4>Приложение 3</h4><p></p>

<p>Перечень нормативных актов, утративших силу в связи с принятием Правил автомобильных перевозок грузов</p><br>

<p style="text-indent:2em;">Разделы 1 - 14 Правил перевозок грузов автомобильным транспортом в Молдавской ССР, утвержденных Министрством автомобильного транспорта Молдавсой ССР
(г.Кишинэу, Картя Молдовеняскэ, 1982 г.).</p><br>

<p><a href="https://www.legis.md/cautare/getResults?doc_id=79463&amp;lang=ru">Источник</a></p>


</div>
`