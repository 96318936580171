import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import imageFooter from '../../images/logo-footer.png'
import classNames from 'classnames'
import './styles.scss'
import { COPYRIGHT, phone1, phone2, phone3, phone4 } from './constants'

class Footer extends Component {
  state = {
    showMenu: false,
  }

  render() {
    const { t } = this.props
    const { showMenu } = this.state
    return (
      <footer className="footer sticky-footer">
        <div className="footer-top">
          <div className="container">
            <div className="sm-only footer-top-mob">
              <div className="row">
                <div className="col-xs-7">
                    <a href="#" data-move="logo-footer-responsive" className="logo-container">
                      <img src={imageFooter} alt="logo transport.md" />
                    </a>
                </div>
                <div className="col-xs-9">
                    <div data-move="social-footer-responsive" className="social-block">
                      <ul className="list-social">
                        <li>
                          <a href="#">
                            <span className="fa fa-facebook-square"></span>Facebook
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span className="fa fa-twitter-square"></span>Twitter
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span className="fa fa-linkedin-square"></span>Linkedin
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span className="fa fa-google-plus-square"></span>Google Plus
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className="collapsible-block">
            <div className="container">
              <div
                data-collapse="true"
                className={classNames('collapse-title', showMenu && 'is-active')}
                onClick={() => this.setState({ showMenu: !showMenu })}
              >
                {t('footer.sitemap')}
                <span className="fa fa-chevron-down fl-right" />
              </div>
              <div className={classNames('collapsible-content', showMenu && 'show-footer-content')}>
                <div className="flex-container">
                  <div className="flex-item">
                    <span data-replace="logo-footer" />
                    <Link to="/" data-move="logo-footer" className="logo-container">
                      <img src={imageFooter} alt="logo transport.md" />
                    </Link>

                    <div className="flex-item">
                      <ul className="menu-footer">
                        <li>
                          <Link to="/about-us">{t('footer.about')}</Link>
                        </li>
                        <li>
                          <Link to="/about-us/faq">{t('menu.faq')}</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex-item">
                    <ul className="menu-footer">
                      <li>
                        <Link to="/cargo/search">{t('cargo.search.header')}</Link>
                      </li>
                      <li>
                        <Link to="/transport/search">{t('transport.search.header')}</Link>
                      </li>
                      <li>
                        <Link to="/cargo/add">{t('cargo.add')}</Link>
                      </li>
                      <li>
                        <Link to="/transport/add">{t('transport.add')}</Link>
                      </li>
                      <li>
                        <Link to="/summary-table">{t('summary.table')}</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="flex-item">
                    <ul className="menu-footer">
                      <li>
                        <Link to="/company">{t('company.all-companies.and.users')}</Link>
                      </li>
                      <li>
                        <Link to="/company/joining-the-company">{t('about.join.in.company')}</Link>
                      </li>
                      <li>
                        <Link to="/company/company-verification">{t('about.company.verification')}</Link>
                      </li>
                      <li>
                        <Link to="/forum">{t('forum.page.title')}</Link>
                      </li>
                      <li>
                        <Link to="/company/autopark">{t('menu.autopark')} </Link>
                      </li>
                    </ul>
                  </div>
                
                  <div className="flex-item">
                    <ul className="menu-footer">
                      <li>
                        <Link to="/news/list">{t('menu.news')}</Link>
                      </li>
                      <li>
                        <Link to="/ads/list/436">{t('menu.ads')}</Link>
                      </li>
                      <li>
                        <Link to="/useful/legislation">{t('menu.legislation')}</Link>
                      </li>
                      <li>
                        <a href="https://www.curs.md/ru" target="_blank" rel="noopener noreferrer">
                          {t('footer.currency.converter')}
                        </a>
                      </li>
                      <li>
                        <a href="https://ec.europa.eu/assets/home/visa-calculator/calculator.htm?lang=ru" target="_blank" rel="noopener noreferrer">
                          {t('footer.schengen.calculator')}
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="flex-item">

                    <ul className="menu-footer">
                      <li>
                        <Link to="/about-us/terms-of-use">{t('menu.rules.for.posting.information')}</Link>
                      </li>
                      <li>
                        <Link to="/about-us/policy">{t('menu.terms.of.use')}</Link>
                      </li>
                      <li>
                        <Link to="/about-us/cookies">{t('menu.advertising.on.the.website')}</Link>
                      </li>
                      <li>
                        <Link to="/about-us/prices">{t('menu.prices')}</Link>
                      </li>
                      <li>
                        <Link to="/about-us/advertising">{t('menu.ads.on.site')}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row footer-info-section">
            
              <div className="">
                <div className="contact-info">
                  <div className="contact-info-row flex-basline">
                    <h6 className="label">{t('footer.office.address')}:</h6>
                    <span className='company-address'>MD-2029; Moldova, mun. Chisinau, str. Drumul Viilor 36/1</span>
                  </div>
                  <div className="contact-info-row flex-basline">
                    <h6 className="label">{t('footer.office')}</h6>
                    <span className="contact-support-item">
                      <a href={`tel:${phone1}`}>{`+${phone1}`}</a>;
                    </span>
                    <span className="contact-support-item">
                      <a href={`tel:${phone2}`}>{`+${phone2}`}</a>;
                    </span>
                    <span className="contact-support-item">
                      <a href={`tel:${phone3}`}>{`+${phone3}`}</a>;
                    </span>
                  </div>
                  <span className="flex-basline contact-info-row ">
                      <h6 className="label">{t('footer.accounting')}</h6>
                      <span className="contact-support-item">
                        <a href={`tel:${phone4}`}>{`+${phone4}`}</a>
                      </span>
                    </span>
                
                  <div className="contact-info-row contact-info-row-inline flex-basline">
                    <h6 className="label">{t('footer.support')}</h6>
                    <span className='contact-support-item'>
                      <a href={`tel:${phone1}`}>{`+${phone1}`}</a>;
                    </span>
                    <span className='contact-support-item'>
                      <a href={`tel:${phone2}`}>{`+${phone2}`}</a>;
                    </span>
                    <span className='contact-support-item'>
                      <a href={`tel:${phone3}`}>{`+${phone3}`}</a>;
                    </span>
                    <a href="mailto:support@transport.md">
                      {`support@transport.md`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="">
                <h6>“Logonica” SRL</h6>
                <p className="text">
                  <span>{t('footer.certificate.of.registration')}</span>
                  <span>1012600002093 / 26.01.2012.</span>
                </p>
                <p className="copyright">
                  <i className="fa fa-copyright"></i>
                  {COPYRIGHT}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
