import React, { Fragment, PureComponent } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Field } from 'redux-form'
import PopoverWrapper from '../../../UI/popover-wrapper'
import TooltipWrapper from '../../../UI/popover-wrapper'
import config from '../../../../config'
import WidgetHeader from '../../../shared/components/widget-header'
import dict from '../../../shared/dictionaries'
import Pagination from '../../../UI/pagination'
import Dropdown from '../../../UI/dropdown'
import Spinner from '../../../UI/spinner'
import { IEmployeesTableProps, IEmployeesTableState } from './types'
import { ACTIVE_STATUS_ID, DECLINED_STATUS_ID } from '../constants'
import UserPhoto from '../../../user/user-photo'

import "./styles.scss"

const STYLE_BASE: string = 'company-employee-list_'

export default class extends PureComponent<IEmployeesTableProps, IEmployeesTableState> {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: props.isLoading,
      pagination: {
        total: props.total,
        pageIndex: 0,
        pageSize: 10,
      },
    }
  }

  componentDidUpdate(prevProps) {
    const { total, isLoading } = this.props
    const { total: prevTotal, isLoading: prevIsLoading } = prevProps
    if (isLoading !== prevIsLoading) this.setState({ isLoading: !this.state.isLoading })
    if (total !== prevTotal)
      this.setState(prevState => ({
        pagination: {
          ...prevState.pagination,
          total: total,
        },
      }))
  }

  changePage = (newPageIndex: number): void => {
    const { employeesType, employeesStatusId, changePage } = this.props
    const {
      pagination: { pageSize },
    } = this.state
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        pageIndex: newPageIndex,
      },
    }))
    changePage(employeesType, employeesStatusId, newPageIndex, pageSize)
  }

  changePageSize = (newPageSize: any): void => {
    const { employeesType, employeesStatusId, changePageSize } = this.props
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        pageIndex: 0,
        pageSize: newPageSize,
      },
    }))
    changePageSize(employeesType, employeesStatusId, newPageSize)
  }

  render() {
    const {
      pagination,
      isLoading,
      pagination: { pageSize },
    } = this.state
    const {
      t,
      langId,
      transparentTable,
      headerTitle,
      employeesStatusId,
      peopleList,
      acceptOrDeclineRequest,
      noDataMessage,
      companyVerifiedInfo,
      acceptOrDeclineRequestButtons = true,
      hideTableHeader = false
    } = this.props

    const rolesItems = dict.positionsInCompany(langId)
    return (
      <div className={STYLE_BASE}>
        <div
          className={classNames({
            panel: true,
            'panel-n-bordered': true,
            'panel-transparent': transparentTable,
          })}
        >
          {!hideTableHeader && <WidgetHeader title={headerTitle} className={`${STYLE_BASE}header`} />}
          <div className="panel-body employee-table">
            <div className="panel-tab">
              {!hideTableHeader && <div className="panel-body-bordered">
                <div className="panel-filter panel-filter-xs">
                  <div className="page-quantity-container">
                    <span>{t('ads.quantity')}:</span>
                    <Field
                      component={Dropdown}
                      name="pageSize"
                      items={dict.pageSizes}
                      className="page-quantity-dropdown"
                      placeholder={t('ads.quantity')}
                      onChange={this.changePageSize}
                    />
                  </div>
                </div>
              </div>}
              <div className="panel-content">
                <div className="panel-content-table">
                  <table

                    className={classNames({
                      'table': true,
                      'table-striped-main': true,
                      'table-centered': true,
                      'table-link': true,
                      'table-lg': true,
                      'table-responsive': true,
                      'js-table-responsive': true,
                      'remove-border': hideTableHeader,
                    })}>


                    <thead>
                      <tr>
                        <th className="td-image" >{t('name')}</th>
                        <th className="default-td">{t('role')}</th>
                        <th className="default-td">{t('employees.status')}</th>
                        <th className="default-td">{t('employess.blocks')}</th>
                        <th className="default-td">{t('phone')}</th>
                        <th className="default-td">{t('skype')}</th>
                        <th className="default-td">{t('viber')}</th>
                        <th className="default-td">{t('email')}</th>
                        {acceptOrDeclineRequestButtons && employeesStatusId !== 3 && <th></th>}
                      </tr>
                    </thead>
                    <tbody>
                      {!isLoading ? (
                        <Fragment>
                          {peopleList && peopleList.length > 0 && rolesItems ? (
                            peopleList.map((employee, index) => {
                              const { userPhoto, userId, positionInCompany, userGender, accessStatus } = employee
                              const employeeRole = rolesItems.items?.find(item => item.value === positionInCompany)
                              const defaultImage = '/images/default-profile/user-male.svg'
                              const employeeImage = `${(config as any).apiUrl
                                }/common/file/profile/${userId}/${userPhoto}`
                              return (
                                <tr
                                  key={employee.userId}
                                  className={classNames({
                                    'tr-rounded-up': index === peopleList.length - 1,
                                  })}
                                >
                                  <td className="td-image" >
                                    <span className="mobile-row-prefix">{t('name')}</span>
                                    <Link to={`/user/details/${userId}`}>
                                      {userPhoto ?
                                        <div className="img-container" style={{ backgroundImage: `url(${employeeImage || defaultImage})` }} /> :
                                        <div className="img-container" >
                                          <UserPhoto customUserId={userId} photo={userPhoto} gender={userGender} />
                                        </div>
                                      }
                                      <span className="name">{`${employee.userName} ${employee.userLastName}`}</span>
                                    </Link>
                                  </td>
                                  <td >
                                    <span className="mobile-row-prefix">{t('role')}</span>
                                    <Link to={`/user/details/${userId}`}>
                                      {employee.isAdmin === 1 ? t('administrator') : employeeRole?.name || ""}
                                    </Link>
                                  </td>
                                  <td className="default-td">
                                    <span className="mobile-row-prefix">
                                      {t('employees.status')}
                                    </span>
                                    <Link to="#">{accessStatus}</Link>
                                  </td>
                                  <td className="default-td">
                                    <span className="mobile-row-prefix">
                                      {t('employess.blocks')}
                                    </span>
                                    <Link to={`/user/details/${userId}`}>{employee.blockedCount}</Link>
                                  </td>
                                  <td className="default-td">
                                    <span className="mobile-row-prefix">{t('phone')}</span>
                                    <Link to="#">{employee.phone1}</Link>
                                  </td>
                                  <td className="default-td">
                                    <span className="mobile-row-prefix">{t('skype')}</span>
                                    <Link to="#">{employee.skype}</Link>
                                  </td>
                                  <td className="default-td">
                                    <span className="mobile-row-prefix">{t('viber')}</span>
                                    <Link to="#">{employee.viber}</Link>
                                  </td>
                                  <td className="td-email default-td">
                                    <span className="mobile-row-prefix">{t('email')}</span>
                                    <Link to="#">{employee.email}</Link>
                                  </td>
                                  {acceptOrDeclineRequestButtons && employeesStatusId !== 3 && <td className="td-icon">
                                    <span className="mobile-row-prefix"></span>
                                    {employeesStatusId === 2 && (
                                      <Link to={`/company/employee-edit/${employee.userId}`}>
                                        <i className="fa fa-pencil"></i>
                                      </Link>
                                    )}
                                    {companyVerifiedInfo?.isValid === 0 && !companyVerifiedInfo.verifiedAt && employeesStatusId !== 2 ? (
                                      <PopoverWrapper text={t('verification.was.sended')}>
                                        <TooltipWrapper text={t('cannot.add.employee')} hover>
                                          <i className="fa fa-question-circle col-blue tooltip-trigger" />
                                        </TooltipWrapper>
                                      </PopoverWrapper>
                                    ) : (
                                      employeesStatusId === 1 && (
                                        <Fragment>
                                          <Link
                                            to="#"
                                            onClick={() =>
                                              acceptOrDeclineRequest(
                                                +employee.userId,
                                                ACTIVE_STATUS_ID,
                                                index,
                                                pageSize
                                              )
                                            }
                                          >
                                            <i className="fa fa-check"></i>
                                          </Link>
                                          <Link
                                            to="#"
                                            onClick={() =>
                                              acceptOrDeclineRequest(
                                                +employee.userId,
                                                DECLINED_STATUS_ID,
                                                index,
                                                pageSize
                                              )
                                            }
                                          >
                                            <i className="fa fa-times"></i>
                                          </Link>
                                        </Fragment>
                                      )
                                    )}
                                  </td>}
                                </tr>
                              )
                            })
                          ) : (
                            <tr className="tr-rounded-up">
                              <td colSpan={9}>
                                <h1>{t(noDataMessage)}</h1>
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      ) : (
                        <tr className="tr-rounded-up">
                          <td colSpan={9}>
                            <Spinner />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isLoading && <Pagination {...pagination} onClick={this.changePage} />}
      </div>
    )
  }
}
