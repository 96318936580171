import React, { FunctionComponent } from 'react'
import { match } from 'react-router'

import Banner from '../../banner'
import NewsItem from '../item'
import News from '../list-mini'
import Ads from '../../ads/list-mini'
import Forum from '../../forum'
import Comments from '../comments'
import AddComment from '../add-comment'
import WidgetAccess from '../../shared/components/widget-access'
import LastNews from "../last-news"
import { BANNER_BIG_SIZE } from '../../../store/constants'

interface INewsPageProps extends ComponentBaseProps, PermissionInjectProps {
  match: match<{ itemId }>
}

const NewsPage: FunctionComponent<INewsPageProps> = props => {
  const { t, match } = props
  const { itemId } = match.params

  return (
    <div className="row">
      <div className="col-lg-12">
        <Banner size={BANNER_BIG_SIZE} />
        <NewsItem itemId={itemId} />
        <Banner size={BANNER_BIG_SIZE} />
        <Comments itemId={itemId} />
        <WidgetAccess
          title={t('news.widget.title.addComment')}
          actionMessage={t('news.comments.add')}
          module="news"
          action="addComments"
        >
          <AddComment itemId={itemId} />
        </WidgetAccess>
        <LastNews />
      </div>
      <div className="col-lg-4">
        <Forum />
        <News />
        <Ads />
      </div>
    </div>
  )
}

export default NewsPage
