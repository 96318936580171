import React, { Fragment } from 'react'

import SearchResults from '../../search/results'
import Banner from '../../banner'
import WidgetBase from '../../shared/components/widget-base'

import "./styles.scss"

export default props => (
  <Fragment>
    <div className="banner-row">
      <Banner bannerId={558} />
      <div className="mobile-banner-on">
        <Banner bannerId={559} />
      </div>
    </div>
    <div className="row row-right my-offers-table-page">
      <div className="col-md-16">
        <WidgetBase title={props.t('cargo.my.offers')} className="my-offers">
          <SearchResults
            searchType="transport"
            isMyOffers
            showDateIsShort
            bannerId={560}
          />
        </WidgetBase>
        <div className="banner-bottom">
          <Banner bannerId={559} />
        </div>
      </div>
    </div>
  </Fragment>
)
