import { connect } from 'react-redux'
import { compose } from 'recompose'

import pick from 'lodash/pick'
import { reduxForm, formValueSelector } from 'redux-form'

import Component from './component'

import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'

// import {
//   loadItems,
//   setParams,
//   showEditModal,
//   hideEditModal,
//   switchListLayout,
//   getItem,
//   deleteNews,
//   publishItem,
// } from '../../../store/actions/news'

const formName = 'list-auto-park'
const formSelector = formValueSelector(formName)

const initialValues = {
  searchKey: '',
}

export default compose(
  withTranslation,
  withPermissions,
  connect(
    state => {
      const listProps = pick(state.news.list, [
        'total',
        'languages',
        'pageIndex',
        'pageSize',
        'loading',
        'items',
        'searchText',
        'filterKey',
        'isGridLayout',
      ])

      const { isOpen, item } = state.news.editModal
      const activeItemId = isOpen && item.itemId

      return {
        list: state.news.list,
        ...listProps,
        searchKey: formSelector(state, 'searchKey'),
        activeGuid: state.ui.activePopupGuid,
        // editModal: state.news.editModal,
        activeItemId,
      }
    },
    {
      // loadItems,
      // setParams,
      // showEditModal,
      // hideEditModal,
      // switchListLayout,
      // getItem,
      // deleteNews,
      // publishItem,
    }
  ),
  reduxForm({
    form: formName,
    initialValues,
  })
)(Component)
