import { connect } from 'react-redux'
import { compose } from 'recompose'
import withTranslation from '../../../globals/components/withTranslation'
import { IReduxStorage } from '../../../store/reducers/types'
import Component from './component'

export default compose(
  withTranslation,
  connect((state: IReduxStorage) => {
    const langId = state.ui.langId
    return { langId }
  })
)(Component)
