/*
import withTranslation from '../../../globals/components/withTranslation'
import Component from './components'
import { setInitialChatState } from '../../../store/actions'

export default withTranslation(Component)
*/
import { withSnackbar } from 'notistack';

import { connect } from 'react-redux'
import { compose } from 'recompose'

import withTranslation from '../../../globals/components/withTranslation'
import { setInitialChatState, setChatPartnerId, setInitialMessengerState } from '../../../store/actions'

import Component from './components'
import withPermissions from '../../../globals/components/withPermissions'
import { IReduxStorage } from '../../../store/reducers/types'
import { ChatProps } from './types'

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state: IReduxStorage) => {
      const profile = state.auth.profile.get('data')

      return {
        chatMsgs: state.socket.chatMsgs,
      }
    },
    {
        setInitialChatState,
        setChatPartnerId,
        setInitialMessengerState,
    }
  )
)(withSnackbar(Component)) as React.ComponentClass<ChatProps>
