import { connect } from 'react-redux'
import { compose } from 'recompose'

import Component from './component'
import withTranslation from '../../../../globals/components/withTranslation'
import withPermissions from '../../../../globals/components/withPermissions'
import { deleteCar } from '../../../../store/actions/transport'

export default compose(
  withTranslation,
  withPermissions,
  connect(null, {
    deleteCar,
  })
)(Component) as any
