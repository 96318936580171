import React from 'react'
import classNames from 'classnames'

import WidgetBase from '../../../shared/components/widget-base'

import './registration-success.scss'

const STYLE_BASE = 'auth-registration-success_'

export default props => {
  const {
    t,
    match: { params },
  } = props
  const isReregistration = !!params[0]

  return (
    <WidgetBase title={isReregistration ? t('reregistration.user') : t('registration.user')}>
      <div className={classNames('panel-body', `${STYLE_BASE}wrapper`)}>
        <div className="panel-content">
          <h2>{isReregistration ? t('reregistration.thanks') : t('registration.thanks')}</h2>
          <h3>{t('registration.email.send')}</h3>
        </div>
      </div>
    </WidgetBase>
  )
}
