import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import fetch from '../shared/fetch'
import { getLanguageLowercaseName } from "../../store/utils"
import config from '../../config'
import { BannerProps, BannerState } from './types'

import "./styles.scss"

export default class extends Component<BannerProps, BannerState> {
  constructor(props) {
    super(props)
    this.state = {
      bannerInfo: {},
      showUploadedImage: false
    }
  }

  componentDidMount() {
    const { bannerId = null, langId } = this.props
    bannerId && this.getInfoAboutBannerPosition(bannerId, langId)
  }

  getInfoAboutBannerPosition = async (bannerId, langId) => {
    const lang = getLanguageLowercaseName(langId)
    await fetch(`/common/banner/${bannerId}/active/lang/${lang}`).then(res => {
      this.setState({ bannerInfo: res.d[0] })
    })
  }

  handleImageError = e => {
    this.setState({ showUploadedImage: true })
  }

  render() {
    const { dataMoveForMobile } = this.props
    const {
      bannerInfo:
      {
        adv_type,
        banner_link,
        banner_name,
        banner_description,
        default_size_1,
        default_size_2,
        default_shablon,
        adv_id,
        default_alt,
        default_link
      },
      showUploadedImage
    } = this.state
    const name = banner_name || default_shablon
    return (
      <>
        {adv_type === "B" ?
          <>
            <div className="banner banner-responsive" data-move={dataMoveForMobile}>
              <a href={!adv_id ?  default_link : banner_link} target="_blank" rel="noopener noreferrer">
                {!adv_id ?
                  <img src={require(`../../images/banner_templates/${default_shablon}`)}
                    alt={default_alt}
                    title={default_alt}
                    style={{ width: default_size_1, height: default_size_2 }} />
                  :
                  <img src={`${(config as any).apiUrl}/common/file/uploads/banners/${name}`}
                    alt={banner_description}
                    title={banner_description}
                    style={{ width: default_size_1, height: default_size_2 }} />
                }
              </a>
            </div>
          </>
          :

          <a href={banner_link} target="_blank" title={banner_description} rel="noopener noreferrer" className="banner-text-url">
            <div>{banner_name}</div>
          </a>

        }
      </>
    )
  }
}
