import React from 'react'
import { Field } from 'redux-form'

import { required } from '../../shared/form-validators'
import renderPhone from '../../UI/util/render-phone'
import './styles.scss'
import FieldBase from '../../UI/field-base'
import FlexBand from 'flexband'

export default class ContactDetails extends React.Component {
  state = {
    contactErrors: {
      phone1: false,
      phone2: false,
      fax: false,
      viber: false,
    },
  }

  formatErrorChange = () => {
    let isErrs = false
    const { contactErrors } = this.state

    //eslint-disable-next-line
    for (let i in contactErrors) {
      if (contactErrors[i]) {
        isErrs = true

        break
      }
    }

    return isErrs
  }

  onErrorChange = (fieldName, status) => {
    this.setState({ contactErrors: { ...this.state.contactErrors, [fieldName]: status } }, () => {
      this.props.onFormatErrorChange(this.formatErrorChange())
    })
  }

  render() {
    const {
      contactErrors: { phone1, phone2, fax, viber },
    } = this.state
    const {
      t,
      fieldNamePhone1,
      fieldNamePhone2,
      fieldNameFax,
      fieldNameSkype,
      fieldNameViber,
      fieldNameEmail,
      fieldNameAddress,
      fieldNameWebSite
    } = this.props

    return (
      <FlexBand direction="column" className="full-details">
        <FlexBand direction="column" className="contact-details">
          <div>
            <label className="required">{t('phone')}</label>
            <Field
              name={fieldNamePhone1}
              formatError={phone1}
              onErrorChange={this.onErrorChange}
              type="text"
              component={renderPhone}
              placeholder="+"
              validate={required}
            />
          </div>
          <div>
            <label>
              {t('phone')}
              <span className="meta-gray"> ({t('optional')})</span>
            </label>
            <Field
              className="phone-number"
              name={fieldNamePhone2}
              formatError={phone2}
              onErrorChange={this.onErrorChange}
              type="text"
              component={renderPhone}
              placeholder="+"
            />
          </div>
          <div>
            <label>
              {`${t('fax')} `}
              <span className="meta-gray"> ({t('optional')})</span>
            </label>
            <Field
              name={fieldNameFax}
              component={renderPhone}
              type="text"
              formatError={fax}
              onErrorChange={this.onErrorChange}
            />
          </div>
          <div>
            <label>
              {`Skype `}
              <span className="meta-gray"> ({t('optional')})</span>
            </label>
            <Field name={fieldNameSkype} type="text" component="input" />
          </div>
          <div>
            <label>
              {`Viber `}
              <span className="meta-gray"> ({t('optional')})</span>
            </label>
            <Field
              name={fieldNameViber}
              component={renderPhone}
              type="text"
              formatError={viber}
              onErrorChange={this.onErrorChange}
            />
          </div>
          <div>
            <label>
              {`Email `}
              <span className="meta-gray">({t('optional')})</span>
            </label>
            <Field name={fieldNameEmail} type="text" component="input" />
          </div>
        </FlexBand>
        <div className="address-line">
          <label className="required">{t('address')}</label>
          <Field name={fieldNameAddress} type="text" component={FieldBase} validate={required} />
        </div>
        <div className="address-line">
          <label >Website
            <span className="meta-gray"> ({t('optional')})</span>
          </label>
          <Field name={fieldNameWebSite} type="text" component={FieldBase} />
        </div>
      </FlexBand>
    )
  }
}
