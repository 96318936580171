export const int14 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>ЕВРОПЕЙСКОЕ СОГЛАШЕНИЕ О МЕЖДУНАРОДНЫХ АВТОМАГИСТРАЛЯХ (СМА)</h4><p></p>

<p></p><h4>(Женева, 15 ноября 1975 года)</h4><p></p>

<p style="text-indent:2em;"></p><h6>Договаривающиеся Стороны, сознавая необходимость облегчения и развития
 в Европе международного дорожного движения, полагая, что для 
 обеспечения и развития связей между европейскими странами 
 необходим координированный план постройки и реконструкции 
 дорог, удовлетворяющих требованиям международного движения
 в будущем, договорились о нижеследующем:</h6><p></p>

<p></p><h4>ОПРЕДЕЛЕНИЕ И ПРИНЯТИЕ ПЛАНА МЕЖДУНАРОДНОЙ СЕТИ "E"</h4><p></p>

<p style="font-weight:500;">Статья 1</p><br>

<p style="text-indent:2em;">Договаривающиеся Стороны принимают план автодорожной сети, 
называемой ниже международной сетью "E", описание которой 
приведено в Приложении I к настоящему Соглашению в качестве
 координированного плана постройки и реконструкции дорог,
 имеющих международное значение, который они намерены 
 выполнять в рамках своих внутригосударственных программ.</p><br>

<p style="font-weight:500;">Статья 2</p><br>

<p style="text-indent:2em;">Международная сеть "E" представляет собой сетку основных
 дорог общим направлением север - юг и запад - восток; 
 она включает также промежуточные дороги, расположенные 
 между основными дорогами, равно как ответвления и 
 соединительные дороги.</p>

<p></p><h4>ПОСТРОЙКА И РЕКОНСТРУКЦИЯ ДОРОГ МЕЖДУНАРОДНОЙ СЕТИ "E"</h4><p></p>

<p style="font-weight:500;">Статья 3</p><br>

<p style="text-indent:2em;">Дороги международной сети "E", упоминаемой в статье 1 
настоящего Соглашения, должны быть приведены в 
соответствие с положениями Приложения II к 
настоящему Соглашению.</p>

<p></p><h4>ОБОЗНАЧЕНИЕ ДОРОГ МЕЖДУНАРОДНОЙ СЕТИ "E"</h4><p></p>

<p style="font-weight:500;">Статья 4</p><br>

<p style="text-indent:2em;">1. Дороги международной сети "E" должны идентифицироваться 
и обозначаться дорожным знаком, описание которого приводится
 в Приложении III к настоящему Соглашению.</p><br>

<p style="text-indent:2em;">2. Все используемые для обозначения дорог "E" дорожные знаки,
 не соответствующие положениям настоящего Соглашения и Приложений
 к нему, должны быть сняты в течение трех лет со дня вступления
 в силу настоящего Соглашения в отношении данного
 государства в соответствии с положениями статьи
 6.</p><br>

<p style="text-indent:2em;">3. Новые дорожные знаки, соответствующие знаку, описанному
 в Приложении III к настоящему Соглашению, должны быть
 установлены на всех дорогах международной сети дорог
 "E" в течение четырех лет со дня вступления в силу 
 настоящего Соглашения в отношении данного 
 государства в соответствии с положениями статьи 6.</p><br>

<p style="text-indent:2em;">4. Положения настоящей статьи не подпадают под ограничения,
 которые могут быть обусловлены внутригосударственными 
 программами, упомянутыми в статье 1 настоящего 
 Соглашения.</p>

<p></p><h4>ПРОЦЕДУРА ПОДПИСАНИЯ НАСТОЯЩЕГО СОГЛАШЕНИЯ И ПРОЦЕДУРА,
 НЕОБХОДИМАЯ ДЛЯ ТОГО, ЧТОБЫ СТАТЬ СТОРОНОЙ НАСТОЯЩЕГО
 СОГЛАШЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 5</p><br>

<p style="text-indent:2em;">1. Настоящее Соглашение открыто для подписания до 31 декабря
 1976 года государствами, которые либо являются членами Европейской 
 экономической комиссии Организации Объединенных Наций, либо допущены
 к участию в работе Комиссии с консультативным статусом в 
 соответствии с пунктом 8 Положения о круге ведения этой 
 Комиссии.</p>

<p style="text-indent:2em;"></p><h6>2. Эти государства могут стать Сторонами настоящего 
Соглашения путем:</h6><p></p>

<p>a) подписания без оговорки о ратификации, принятии 
или утверждении;</p><br>

<p style="text-indent:2em;">b) подписания с оговоркой о ратификации, принятии или 
утверждении, за которым следует ратификация, принятие
 или утверждение; или</p><br>

<p>c) присоединения.</p><br>

<p style="text-indent:2em;">3. Ратификация, принятие, утверждение или присоединение
 осуществляется путем передачи на хранение Генеральному
 секретарю Организации Объединенных Наций документа, 
 составленного в должной форме.</p>

<p></p><h4>ВСТУПЛЕНИЕ В СИЛУ НАСТОЯЩЕГО СОГЛАШЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 6</p><br>

<p style="text-indent:2em;">1. Настоящее Соглашение вступает в силу по истечении 90 
дней с того дня, в который правительства восьми государств 
либо подпишут его без оговорки о ратификации, принятии или 
утверждении, либо сдадут на хранение документ о ратификации, 
принятии, утверждении или присоединении при условии, что одна 
или несколько дорог международной сети "E" непрерывно соединяют 
территории по крайней мере четырех государств, подписавших 
таким образом или сдавших на хранение подобный документ. При невыполнении
 этого условия Соглашение вступает в силу по истечении 90 дней со дня 
 либо подписания без оговорки о ратификации, принятии или утверждении,
 либо сдачи на хранение документа о ратификации, принятии, утверждении
 или присоединении, при наличии которого это условие будет выполнено.</p><br>

<p style="text-indent:2em;">2. В отношении каждого государства, которое сдаст на хранение документ 
о ратификации, принятии, утверждении или присоединении после даты, 
начиная с которой исчисляется срок 90 дней, указанный в пункте 1 
настоящей статьи, Соглашение вступает в силу по истечении 90 дней
 со дня сдачи на хранение этого документа.</p><br>

<p style="text-indent:2em;">3. С момента вступления в силу настоящего Соглашения им отменяется
 и заменяется в отношениях между Договаривающимися Сторонами Декларация
 о постройке международных автомагистралей, подписанная в г. Женеве 
 16 сентября 1950 года.</p>

<p></p><h4>ПРОЦЕДУРА ВНЕСЕНИЯ ПОПРАВОК В ОСНОВНОЙ ТЕКСТ НАСТОЯЩЕГО 
СОГЛАШЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 7</p><br>

<p style="text-indent:2em;">1. В основной текст настоящего Соглашения могут вноситься поправки
 в соответствии с любой из процедур, указанных в настоящей статье.</p><br>

<p style="text-indent:2em;">2. a) По просьбе какой-либо Договаривающейся Стороны любое ее предложение
 о внесении поправки в основной текст настоящего Соглашения 
 рассматривается Рабочей группой по автомобильному транспорту 
 Европейской экономической комиссии (ЕЭК).</p><br>

<p style="text-indent:2em;">b) В случае одобрения поправки большинством в две трети 
присутствующих и участвующих в голосовании и если такое 
большинство включает большинство в две трети представленных
 и участвующих в голосовании Договаривающихся Сторон, 
 Генеральный секретарь направляет поправку всем Договаривающимся
 Сторонам для ее принятия.</p><br>

<p style="text-indent:2em;">c) В случае принятия поправки большинством в две трети 
Договаривающихся Сторон Генеральный секретарь уведомляет 
об этом все Договаривающиеся Стороны, и поправка вступает 
в силу по истечении двенадцати месяцев со дня такого
 уведомления. Поправка вступает в силу в отношении всех 
 Договаривающихся Сторон, за исключением тех, которые 
 перед вступлением ее в силу заявили о том, что они не 
 принимают эту поправку.</p><br>

<p style="text-indent:2em;">3. По просьбе по крайней мере одной трети Договаривающихся Сторон
 Генеральный секретарь созывает конференцию, на которую 
 приглашаются государства, указанные в статье 5 настоящего 
 Соглашения. Процедура, описанная в подпунктах "а" и "b" пункта
 2 настоящей статьи, применяется в отношении любой поправки, 
 представленной на рассмотрение такой конференции.</p>

<p></p><h4>ПРОЦЕДУРА ВНЕСЕНИЯ ПОПРАВОК В ПРИЛОЖЕНИЕ I К НАСТОЯЩЕМУ 
СОГЛАШЕНИЮ</h4><p></p>

<p style="font-weight:500;">Статья 8</p><br>

<p style="text-indent:2em;">1. В Приложение I к настоящему Соглашению могут вноситься поправки в
 соответствии с процедурой, предусмотренной в настоящей статье.</p><br>

<p style="text-indent:2em;">2. По просьбе какой-либо Договаривающейся Стороны любое ее предложение
 о внесении поправки в Приложение I к настоящему Соглашению 
 рассматривается Рабочей группой по автомобильному транспорту
 Европейской экономической комиссии (ЕЭК).</p>

<p style="text-indent:2em;"></p><h6>3. В случае одобрения поправки большинством присутствующих и
 участвующих в голосовании и если такое большинство включает
 большинство представленных и участвующих в голосовании Договаривающихся
 Сторон, Генеральный секретарь направляет поправку компетентным 
 администрациям непосредственно заинтересованных Договаривающихся Сторон.
 Непосредственно заинтересованными Договаривающимися Сторонами 
 считаются:</h6><p></p>

<p style="text-indent:2em;">a) в случае включения новой или изменения существующей международной дороги
 класса A - каждая Договаривающаяся Сторона, по территории которой проходит
 данная дорога;</p><br>

<p style="text-indent:2em;">b) в случае включения новой или изменения существующей международной дороги класса
 B - каждая Договаривающаяся Сторона, граничащая со страной, подавшей заявку, по
 территории которой приходит(ят) международная(ые) дорога(и) класса A, с которой
 (ыми) соединена новая или подлежащая изменению международная дорога класса B. В
 соответствии с настоящим пунктом граничащими считаются также две Договаривающиеся
 Стороны, на территории которых находятся конечные пункты морского маршрута,
 предусмотренного трассой упомянутой(ых) выше международной(ых) дороги(-
 ) класса A.</p><br>

<p style="text-indent:2em;">4. Любая предложенная поправка, сообщение о которой разослано в соответствии с
 положениями пункта 3 настоящей статьи, принимается, если в течение шестимесячного 
 срока со дня этого сообщения ни одна из компетентных администраций непосредственно
 заинтересованных Договаривающихся Сторон не уведомляет</p><br>

<p style="text-indent:2em;">Генерального секретаря о том, что она возражает против этой поправки. Если 
администрация какой-либо Договаривающейся Стороны заявит, что согласно ее национальному
 законодательству ее согласие зависит от получения специального разрешения или от 
 одобрения законодательного органа, согласие упомянутой администрации на внесение 
 изменений в Приложение I к настоящему Соглашению считается данным и предложенная 
 поправка считается принятой лишь тогда, когда указанная администрация заявит 
 Генеральному секретарю, что требуемое разрешение или полномочие получено. Если 
 это заявление не будет сделано в течение восемнадцатимесячного срока со времени 
 препровождения предложенной поправки указанной компетентной администрации или 
 если в течение указанного выше шестимесячного срока компетентная администрация 
 непосредственно заинтересованной Договаривающейся Стороны представит возражение 
 против предлагаемой поправки, поправка не будет принята.</p><br>

<p style="text-indent:2em;">5. Генеральный секретарь уведомляет все Договаривающиеся Стороны о любой принятой
 поправке, и она вступает в силу для всех Договаривающихся Сторон через три 
 месяца со дня этого уведомления.</p>

<p></p><h4>ПРОЦЕДУРА ВНЕСЕНИЯ ПОПРАВОК В ПРИЛОЖЕНИЯ II И III К НАСТОЯЩЕМУ СОГЛАШЕНИЮ</h4><p></p>

<p style="font-weight:500;">Статья 9</p><br>

<p style="text-indent:2em;">1. В Приложения II и III к настоящему Соглашению могут вноситься поправки в 
соответствии с процедурой, предусмотренной в настоящей статье.</p><br>

<p style="text-indent:2em;">2. По просьбе какой-либо Договаривающейся Стороны любое ее предложение о 
внесении поправки в Приложения II и III к настоящему Соглашению рассматривается
 Рабочей группой по автомобильному транспорту Европейской экономической 
 комиссии (ЕЭК).</p><br>

<p style="text-indent:2em;">3. В случае одобрения поправки большинством присутствующих и участвующих 
в голосовании и если такое большинство включает большинство представленных
 и участвующих в голосовании Договаривающихся Сторон, Генеральный секретарь
 направляет поправку компетентным администрациям всех Договаривающихся 
 Сторон для ее принятия.</p><br>

<p style="text-indent:2em;">4. Поправка принимается, если в течение периода шести месяцев со дня нотификации
 менее одной трети компетентных администраций Договаривающихся Сторон уведомляют
 Генерального секретаря о своем возражении против поправки.</p><br>

<p style="text-indent:2em;">5. Генеральный секретарь уведомляет все Договаривающиеся Стороны о принятии
 поправки, и она вступает в силу через три месяца со дня такого уведомления.</p>

<p></p><h4>СООБЩЕНИЕ АДРЕСА АДМИНИСТРАЦИИ, КОТОРОЙ ДОЛЖНЫ НАПРАВЛЯТЬСЯ ПРЕДЛОЖЕНИЯ О 
ВНЕСЕНИИ ПОПРАВОК В ПРИЛОЖЕНИЯ К НАСТОЯЩЕМУ СОГЛАШЕНИЮ</h4><p></p>

<p style="font-weight:500;">Статья 10</p><br>

<p style="text-indent:2em;">Каждое государство в момент подписания, ратификации, принятия или утверждения 
настоящего Соглашения или присоединения к нему сообщает Генеральному секретарю
 название и адрес своей администрации, которой должны направляться, в соответствии
 с положениями статей 8 и 9 настоящего Соглашения, предложения о внесении поправок
 к настоящему Соглашению.</p>

<p></p><h4>ДЕНОНСАЦИЯ СОГЛАШЕНИЯ И ПРЕКРАЩЕНИЕ ЕГО ДЕЙСТВИЯ</h4><p></p>

<p style="font-weight:500;">Статья 11</p><br>

<p style="text-indent:2em;">Каждая Договаривающаяся Сторона может денонсировать настоящее Соглашение путем
 письменной нотификации, адресованной Генеральному секретарю. Денонсация вступает
 в силу по истечении одного года со дня получения Генеральным секретарем этой
 нотификации.</p><br>

<p style="font-weight:500;">Статья 12</p><br>

<p style="text-indent:2em;">Настоящее Соглашение теряет силу, если число Договаривающихся Сторон составляет
 в течение какого-либо периода последовательных двенадцати месяцев менее восьми.</p>

<p></p><h4>УРЕГУЛИРОВАНИЕ СПОРОВ</h4><p></p>

<p style="font-weight:500;">Статья 13</p><br>

<p style="text-indent:2em;">1. Всякий спор между двумя или более Договаривающимися Сторонами относительно
 толкования или применения настоящего Соглашения, который спорящие Стороны не
 могут разрешить путем переговоров или другими средствами урегулирования, 
 передается на арбитраж по заявлению одной из Договаривающихся Сторон, между
 которыми возник спор, и передается соответственно одному или нескольким арбитрам
 , избранным по общему соглашению спорящих Сторон. Если в течение трех месяцев со
 дня представления просьбы о рассмотрении дела в арбитраже спорящие Стороны не придут
 к Соглашению относительно выбора арбитра, любая из этих Сторон может обратиться к
 Генеральному секретарю Организации Объединенных Наций с просьбой о назначении единого
 арбитра, которому спор передается на разрешение.</p><br>

<p style="text-indent:2em;">2. Решение арбитра или арбитров, назначенных в соответствии с положениями пункта 1
 настоящей статьи, имеет обязательную силу для спорящих Договаривающихся Сторон.</p>

<p></p><h4>ОГРАНИЧЕНИЯ В ПРИМЕНЕНИИ НАСТОЯЩЕГО СОГЛАШЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 14</p><br>

<p style="text-indent:2em;">Никакое положение настоящего Соглашения не должно толковаться как препятствующее
 какой-либо из Договаривающихся Сторон принимать совместимые с Уставом Организации
 Объединенных Наций и ограничиваемые создавшимся положением меры, которые она считает
 необходимыми для обеспечения своей внешней или внутренней безопасности.</p>

<p></p><h4>ЗАЯВЛЕНИЕ ОТНОСИТЕЛЬНО СТАТЬИ 13 НАСТОЯЩЕГО СОГЛАШЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 15</p><br>

<p style="text-indent:2em;">Каждое государство может при подписании настоящего Соглашения или при сдаче на
 хранение ратификационной грамоты или документа о принятии, утверждении или присоединении
 заявить, что оно не считает себя связанным статьей 13 настоящего Соглашения. Другие
 Договаривающиеся Стороны не являются связанными положениями статьи 13 в отношении
 любой Договаривающейся Стороны, сделавшей такое заявление.</p>

<p></p><h4>СООБЩЕНИЯ ДОГОВАРИВАЮЩИМСЯ СТОРОНАМ</h4><p></p>

<p style="font-weight:500;">Статья 16</p>

<p style="text-indent:2em;"></p><h6>Помимо заявлений, нотификаций и уведомлений, предусмотренных в статьях 7, 8, 9 и 15
 настоящего Соглашения, Генеральный секретарь сообщает Договаривающимся Сторонам и
 другим государствам, указанным в статье 5: </h6><p></p>
 
<p>a) о подписаниях, ратификациях, принятиях,
 утверждениях и присоединениях в соответствии со статьей 5;</p><br>

<p>b) о датах вступления в силу настоящего Соглашения в соответствии со статьей 6;</p><br>

<p style="text-indent:2em;">c) о дате вступления в силу поправок к настоящему Соглашению в соответствии с пунктом 2
 "c" статьи 7, пунктами 4 и 5 статьи 8 и статьей 9;</p><br>

<p>d) о денонсациях в соответствии со статьей 11;</p><br>

<p>e) о прекращении настоящего Соглашения в соответствии со статьей 12.</p>

<p></p><h4>СДАЧА НАСТОЯЩЕГО СОГЛАШЕНИЯ НА ХРАНЕНИЕ ГЕНЕРАЛЬНОМУ СЕКРЕТАРЮ</h4><p></p>

<p style="font-weight:500;">Статья 17</p><br>

<p style="text-indent:2em;">После 31 декабря 1976 года подлинник настоящего Соглашения сдается на хранение Генеральному
 секретарю Организации Объединенных Наций, который препровождает надлежащим образом 
 заверенные копии всем государствам, указанным в статье 5 настоящего Соглашения.</p><br>

<p style="text-indent:2em;">В удостоверение чего нижеподписавшиеся, должным образом на то уполномоченные, подписали
 настоящее Соглашение. Совершено в г. Женеве в одном экземпляре пятнадцатого ноября 
 тысяча девятьсот семьдесят пятого года на английском, русском и французском языках,
 причем все три текста являются равно аутентичными.</p><br>

<p>(Подписи)</p>

<p></p><h4>Приложение I</h4><p></p>

<p style="font-weight:500;">МЕЖДУНАРОДНАЯ СЕТЬ "E"</p><br>

<p style="font-weight:500;">ПОЯСНЕНИЯ</p><br>

<p style="text-indent:2em;">1. Основные и промежуточные дороги, называемые дорогами класса A, имеют двузначные 
номера; ответвления и соединительные дороги, называемые дорогами класса B, имеют 
трехзначные номера.</p><br>

<p style="text-indent:2em;">2. Основные дороги северо-южной ориентации имеют двузначные нечетные номера, 
оканчивающиеся цифрой 5 и возрастающие с запада на восток. Основные дороги восточно
-западной ориентации имеют двузначные четные номера, оканчивающиеся цифрой 0 и 
возрастающие с севера на юг. Промежуточные дороги имеют соответственно двузначные
 нечетные и двузначные четные номера, заключенные между номерами тех основных дорог,
 между которыми они расположены. Дороги класса B имеют трехзначные номера, причем первая
 цифра совпадает с номером ближайшей основной дороги, расположенной к северу от данной 
 дороги В, а вторая цифра совпадает с номером ближайшей основной дороги, расположенной
 к западу от упомянутой дороги B; третья цифра представляет собой порядковый номер.</p>

<p></p><h4>ПЕРЕЧЕНЬ ДОРОГ</h4><p></p>

<p></p><h4>A. Главные дороги</h4><p></p>

<p style="font-weight:500;">1) Направление запад - восток</p><br>

<p style="font-weight:500;">a) Основные дороги</p><br>

<p>E 20 Шаннон - Лимерик - Порт-Лейише - Дублин ...</p><br>

<p>Ливерпуль - Манчестер - Брадфорд - Лидс - Гулль ...</p><br>

<p>Эсбьерг - Коллинг - Миддельфарт - Нюборг ...</p><br>

<p>Корсер -  Копенгаген  ...  Мальме - Истад ...  - Таллин -
Ленинград.</p><br>

<p>E 30 Корк - Уотерфорд - Уэксфорд - Рослэр ...</p><br>

<p>Фишгард  -  Суонси  -  Кардифф  -  Ньюпорт  -  Бристоль -
Лондон - Колчестр - Ипсуич - Феликстоу ...</p><br>

<p style="text-indent:2em;">Хук-ван-Холланд -  Гаага  -  Гоуда - Утрехт - Амерсфорт -
Ольденцаль -  Оснабрюк  -  Бад-Ойенхаузен  -  Ганновер  -
Брауншвейг -  Магдебург  - Берлин - Свебодзин - Познань -
Лович - Варшава - Брест - Минск - Смоленск - Москва.</p><br>
	
<p style="text-indent:2em;">E 40 Кале -  Остенде - Гент - Брюссель - Льеж - Ахен - Кельн -
Ольпе - Гисен - Бад Херсфельд - Херлесхаузен - Эйзенах  -
Эрфурт  -  Гера  -  Карл-Маркс-штадт - Дрезден - Герлиц -
Легница - Вроцлав - Ополе - Гливице - Краков - Пшемысль -
Львов   -   Ровно   -   Житомир   -   Киев  -  Харьков  -
Ростов-на-Дону.</p><br>

<p style="text-indent:2em;">E 50 Брест  -  Ренн   -   Ле-Ман  -  Париж  -  Реймс  -  Мец -
Саарбрюккен -  Мангейм  -  Хейльбронн  -  Фейхтванген   -
Нюрнберг  - Розвадов - Пльзень - Прага - Йиглава - Брно -
Жилина - Прешов - Кошице -  Вишне  Немецке  -  Ужгород  -
Мукачево.</p><br>

<p style="text-indent:2em;">E 60 Брест  -  Нант  - Тур - Мюлуз - Базель - Ольтен - Цюрих -
Винтертур -  Санкт-Галлен  - Сант-Маргаретен - Лаутерах -
Фельдкирх - Имст - Инсбрук - Вергель - Зальцбург - Линц -
Вена  - Никельсдорф - Мосонмадьяровар - Дьер - Будапешт -
Пюшпекладани - Орадя -  Клуж  -  Турда  -  Тыргу-Муреш  -
Брашов  -  Плоешти  -  Бухарест  -  Урзичени - Слобозия -
Хырсово - Констанца.</p><br>

<p style="text-indent:2em;">E 70 Ла-Рошель - Лион - Шамбери - Сюза - Турин - Алессандрия -
Тортона -  Брешиа  -  Верона  -   Местре   (Венеция)    -
Пальманова - Триест  -  Любляна  -  Загреб  -  Дьяково  -
Белград -    Вршац    -    Тимишоара   -   Карансебеш   -
Турну Северин - Крайова - Питешти - Бухарест - Джурджу  -
Русе - Разград - Шумен - Варна.</p><br>

<p style="text-indent:2em;">E 80 Ла-Корунья  -   Сантандер  -  Бильбао  -  Сан-Себастьян -
По - Тулуза - Нарбонн - Ним - Экс-ан-Прованс  -  Ницца  -
Винтимилья  -  Савона  -  Генуя  -  Специя  - Мильярино -
Ливорно - Гроссето  -  Рим  -  Пескара  ...  Дубровник  -
Петровац  -  Титоград  -  Приштина  - Ниш - Дмитровград -
София - Пловдив - Эдирне - Бабаэски - Силиври - Стамбул -
Измир  -  Адапазары  -  Болу - Гереде - Анкара - Йозгат -
Сивас - Эрзинджан - Муту - Ашкале  -  Эрзурум  -  Агры  -
Иран.</p><br>

<p style="text-indent:2em;">E 90 Лиссабон - Сетубал - Пегуенш - Элваш - Бадахос - Мадрид -
 Сарагоса -  Лерида  - Барселона ...  Мадзара-дель-Валло -
Палермо - Мессина ...  Реджо-ди-Калабрия -  Катандзаро  -
Сибари  -  Кротоне  -  Метапонто - Таранто - Бриндизи ...
Игуменица - Янина - Козани - Салоники - Александруполис -
Ипсала  -  Кешан  -  Измир  -  Айдын - Анталья - Тарсус -
Адана - Кемюрлер - Газиантеп - Урфа - Мардин - Нусайбин -
Джизре - Эзендер - Иран.</p><br>

<p style="font-weight:500;">b) Промежуточные дороги</p><br>
  
<p>E 12 Мо и Рана - Умео ... Васа - Тампере - Хельсинки.</p><br>

<p>E 16 Лондондерри - Белфаст ... Глазго - Эдинбург.</p><br>

<p style="text-indent:2em;">E 18 Крейвагон   -  Белфаст  -  Ларн ... Странрар  -  Гретна -
Карлайл - Ньюкасл ...    Ставангер  -    Кристиансанн   -
Ларвик -  Драммен  -  Осло  -  Эрье - Карлстад - Эребру -
Арбуга - Энчепинг - Стокгольм - Нортелье - Каппельскер  -
Аланд  ...  Турку  и  Наантали  -  Хельсинки - Ваалимаа -
Ленинград.</p><br>

<p style="text-indent:2em;">E 22 Холихед -  Честер  -  Уоррингтон  -  Манчестер  -  Лидс -
Донкастер -   Иммингем   ...   Амстердам  -  Гронинген  -
Ольденбург  -  Бремен  -  Гамбург  -  Любек  -  Росток  -
Штральзунд - Засниц.</p><br>

<p>E 24 Гамбург - Берлин.</p><br>

<p>E 26 Берлин - Щецин - Голенюв - Кошалин - Гданьск.</p><br>

<p>E 28 Бирминген - Кембридж - Ипсуич.</p><br>

<p>E 32 Колчестр - Гарвич.</p><br>

<p>E 36 Антверпен  -  Эйндховен  -  Венло - Оберхаузен -  Камен -
Бад Ойенхаузен.</p><br>

<p>E 38 Берлин - Люббенау - Котбус - Легница.</p><br>

<p>E 42 Дюнкерк  - Лиль  - Монс  -  Шарлеруа   -  Намюр - Льеж  -</p><br>

<p>Сен-Вит-Витлих - Бинген - Висбаден - Франкфурт-на-Майне -
Ашаффенбург - Вюрцбург.</p><br>

<p style="text-indent:2em;">E 44 Сен-Брие - Кан - Руан - Амьен  -  Шарлевиль  -  Мезьер  -
Люксембург -  Трир - Витлих - Кобленц - Рансбах-Баумбах -
Гиссен.</p><br>
	
<p>E 46 Руан - Реймс - Шарлевиль - Мезьер - Льеж.</p><br>

<p>E 48 Байрейт - Марктредвиц - Хеб - Карловы Вары - Прага.</p><br>

<p>E 52 Париж  -  Нанси  -  Страсбург  -  Аппенвейер - Карлсруэ -
Штутгарт - Ульм - Мюнхен - Браунау - Вельс - Линц.</p><br>

<p>E 54 Париж -  Шомон  -  Мюлуз  - Базель - Вальдсхут - Линдау -
Меммингем - Мюнхен - Розенгейм - Зальцбург.</p><br>

<p>E 56 Нюрнберг  -  Регенсбург  -  Деггендорф - Пассау - Вельс -
Заттледт.</p><br>

<p>E 62 Нант  -  Пуатье  - Макон -  Женева - Лозанна - Мартиньи -
Сьон - Симплон - Гравеллона Точе - Милан - Тортона.</p><br>

<p>E 64 Сегед - Арад - Дева - Сибиу - Брашов.</p><br>

<p>E 66 Турин - Милан - Брешиа.</p><br>

<p>E 68 Фортецца - Сан-Кандидо - Шпитталь - Филлах - Клагенфурт -
Грац - Веспрем - Балатоналига.</p><br>

<p>E 72 Ницца - Кунео - Асти - Алессандрия.</p><br>

<p>E 74 Мильярино - Флоренция.</p><br>

<p>E 76 Бордо - Тулуза.</p><br>

<p>E 78 Гроссето - Ареццо - Сансеполькро - Фано.</p><br>

<p>E 82 Коимбра - Селорико да Бейра -  Саламанка  -  Вальядолид -
Бургос.</p><br>

<p>E 86 Кристалопиджи - Флорина - Веви - Ефира - Салоники.</p><br>

<p>E 88 Кешан - Текирдаг - Силиври.</p><br>

<p>E 92 Рион - Эйон.</p><br>

<p>E 94 Коринф - Афины.</p><br>

<p style="font-weight:500;">2) Направление север - юг</p><br>

<p style="font-weight:500;">a) Основные дороги</p><br>

<p style="text-indent:2em;">E 05 Гринок  -  Глазго - Гретна - Карлайл - Пенрит - Престон -
Уоррингтон - Бирмингем - Ньюбери - Саутгемптон ... Гавр -
Париж  -  Орлеан - Тур - Пуатье - Бордо - Сан-Себастьян -
Бургос - Мадрид - Кордова - Севилья - Кадис - Альхесирас.</p><br>

<p style="text-indent:2em;">E 15 Инвернесс - Перт - Эдинбург  -  Ньюкасл -  Скотч-Корнер -
Донкастер - Лондон - Фолкстон - Дувр ...  Кале - Париж  -
Лион - Оранж - Нарбонн - Жерона - Барселона - Таррагона -
Кастельон-де-ла-Плана - Валенсия - Аликанте  -  Мурсия  -
Альхесирас.</p><br>

<p style="text-indent:2em;">E 25 Амстердам   -   Утрехт   -   Хертогенбос  -   Эйндховен -
Маастрихт -  Льеж  - Бастонь - Арлон - Люксембург - Мец -
Сент-Авольд  -   Страсбург  -  Мюлуз  - Базель - Ольтен -
Берн - Лозанна  -  Женева  -  Мон-Блан  - Аоста - Турин -
Алессандрия - Тортона - Генуя.</p><br>

<p style="text-indent:2em;">E 35 Хук  ван  Холланд - Роттердам - Гауда - Утрехт  - Арнем -
Эммерих -  Оберхаузен  -  Кельн   -   Рансбах-Баумбах   -
Франкфурт-на-Майне - Гейдельберг - Карлсруэ - Оффенбург -
Базель - Ольтен  -  Люцерн  -  Альтдорф  -  Сен-Готард  -
Беллинцона  - Лугано - Киассо - Комо - Милан - Пьяченца -
Парма - Модена - Флоренция - Ареццо - Рим.</p><br>

<p style="text-indent:2em;">E 45 Воллан - Мо и Рана  -   Схьердальхальсен   -   Тронхейм -
Домбос - Отта -  Хамар  -  Эйдсволль  -  Осло  -  Мосс  -
Свинезунд   -   Уддевалла   -   Гетеборг  -  Хальмстад  -
Хельсингборг  ...  Хельсингер  -  Копенгаген  -  Кеге   -
Вордингборг   -   Редби  ...  Путгарден  ...  Гамбург   -
Вальсроде - Ганновер - Нортгейм - Геттинген -  Кассель  -
Бад-Херсфельд  -  Фульда - Вюрцбург - Нюрнберг - Мюнхен -
Розенгейм - Вергль - Инсбрук - пер.  Бреннер - Фортецца -
Больцано  - Тренто - Верона - Модена - Болонья - Чезена -
Перуджа - Рим - Неаполь - Салерно - Сичиньяно - Козенца -
Вилла-Сан-Джованни   (Реджо-ди-Калабрия)  ...  Мессина  -
Катания - Сиракузы - Джела.</p><br>

<p style="text-indent:2em;">E 55 Торнио - Хапаранда - Лулео - Умео - Сундсвалль -  Евле  -
Упсала - Стокгольм - Седертелье - Норчепинг - Линчепинг -
Йенчепинг  -  Хельсингборг   - Мальме  -   Треллеборг ...</p><br>

<p style="text-indent:2em;">Засниц  - Штральзунд  -  Росток  -  Берлин  -  Люббенау -
Дрезден -  Циновец (Цинвальд) - Теплице - Прага - Табор -
Ческе Будеевице - Дольни Дворжиште - Линц -  Зальцбург  -
Филлах  -  Тарвизио   -  Удине   -  Пальманова  -  Местре
(Венеция) - Равенна - Чезена - Римини - Фано -  Анкона  -
Пескара  -  Каноса  -  Бари  - Бриндизи ...  Ингуменица -
Превеза - Месолонгион - Рион - Патры - Пиргос - Каламе.</p><br>

<p style="text-indent:2em;">E 65 Истад - ... Свиноуйсьце   -   Волин  - Галленюв - Щепин -
Свебодзин  -  Еленя-Гура  -  Харрахов  -  Железни  Брод -
Турнов - Млада Болеслав  -  Прага  -  Йиглава  -  Брно  -
Бржецлав - Братислава - Райка - Мошонмадьяровар - Чорна -
Сомбатхей - Керменд - Редич - Загреб - Карловац - Риека -
Сплит - Меткович - Дубровник  -  Петровац  -  Титоград  -
Бьело  Поле  -  Скопье - Кичево - Охрид - Битола - Ники -
Веви - Козани - Лариса - Домокос - Ламия - Браллос - Итея
... Эйон - Коринф - Триполис - Иитион.</p><br>

<p style="text-indent:2em;">E 75 Тромсе   -   Нордхосботн  -  Шиботн   -   Хеллигскуген  -
Килписьярви -   Торнио  -  Оулу  -  Ювяскюля  -  Лахти  -
Хельсинки ...  Гданьск - Эльблонг -  Оструда  -  Млава  -
Варшава  - Радом - Краков - Трстена - Ружомберок - Банска
Быстрица - Зволен - Шаги - Будапешт - Сегед -  Белград  -
Ниш  - Куманово - Скопье - Гевгелия - Эвзони - Салоники -
Лариса - Алмирос - Ламия - Афины -  Ханья  -  Ираклион  -
Айос - Николаос - Сития.</p><br>
	
<p style="text-indent:2em;">E 85 Черновцы - Сирет - Сучава - Роман - Бакэу  -  Мэрэшести -
Бузэу -  Урзичени  -  Бухарест  - Джурджу - Русе - Бяла -
Велико Тырново - Стара  Загора  -  Хасково  -  Подкова  -
Комотини.</p><br>

<p>E 95 Ленинград  -  Москва  -  Орел  - Харьков -  Симферополь -
Алушта - Ялта.</p><br>

<p style="font-weight:500;">b) Промежуточные дороги</p><br>

<p>E 01 Ларн  -   Белфаст  -  Дублин  -  Уэскфорд  -  Рослэр  ...</p><br>

<p style="text-indent:2em;">Ла Корунья - Понтеверда - Порту  -  Албергария-а-Велья  -
Коимбра  -  Вила  Франка  ди  Шира - Лиссабон - Сетубал -
Портиман - Фаро - Уэльва - Севилья.</p><br>

<p>E 03 Шербур - Ренн - Нант - Ла Рошель.</p><br>

<p>E 07 Орлеан - Лимож - Тулуза - Сарагоса.</p><br>

<p>E 13 Донкастер  - Шеффилд - Ноттингем - Лестер - Нортгемптон -
Лондон.</p><br>

<p>E 17 Антверпен - Гент - Кортрейк - Камбре - Реймс - Бон.</p><br>

<p>E 19 Амстердам   -  Гаага  -  Роттердам  - Бреда - Антверпен -
Брюссель - Монс - Валансьен - Париж.</p><br>

<p>E 21 Мец  -  Нанси  -  Дижон  -  Женева - Шамбери - Гренобль -
Валанс - Марсель.</p><br>

<p>E 23 Мец - Нанси - Безансон - Валлорб - Лозанна.</p><br>

<p>E 27 Дортмунд  -  Кельн  -  Прюм - Люксембург -  Саарбрюккен -
Саргемин - (E 25 Страсбург).</p><br>

<p>E 29 Бельфор - Берн - Мартиньи - Гран-Сен-Бернар - Аоста.</p><br>

<p>E 31 Парма - Специя.</p><br>

<p>E 33 Роттердам  -  Горинхем  -  Неймеген  -  Гох  - Крефельд -
Кельн - Кобленц - Бинген - Людвигсхафен.</p><br>

<p style="text-indent:2em;">E 37 Стокгольм - Седертелье - Эребру - Мариестад - Гетеборг...
Фредериксхавн  -  Ольборг  -  Орхус  -  Вайле - Коллинг -
Крусо  -  Фленсбург  -  Шлезвиг - Неймюнстер  - Гамбург -
Бремен - Оснабрюк - Дортмунд - Ольпе - Гисен.</p><br>

<p>E 39 Гисен - Франкфурт-на-Майне - Дармштадт.</p><br>

<p>E 41 Вюрцбург  -  Хейльбронн  -  Штутгарт  -   Донауэшинген  -
Шафхаузен - Винтертур - Цюрих - Альтдорф.</p><br>

<p style="text-indent:2em;">E 43 Вюрцбург  -  Фейхтванген  -  Ульм - Меммингем  - Линдау -
Брегенц   -   Санкт-Маргаретен   -   Букс   -   Кур     -
Сан-Бернардино - Беллинцона.</p><br>

<p style="text-indent:2em;">E 47 Магдебург - Галле  - Лейпциг -  Карл-Маркс-штадт  -  Божи
Дар - Карловы   Вары  -  Пльзень  -  Ческе   Будеевице  -
Тршебонь  -  Галамки - Вена.</p><br>

<p>E 49 Ореховед - Нюкебинг - Гесер ... Росток.</p><br>

<p>E 51 Берлин - Лейпциг - Гера - Хоф - Байрейт - Нюрнберг.</p><br>

<p>E 53 Пльзень - Байер - Эйзенштейн - Деггендорф - Мюнхен.</p><br>

<p>E 57 Саттледт - Лицен  -  Санк-Михаэль  -  Грац  -  Марибор  -
Любляна.</p><br>

<p style="text-indent:2em;">E 59 Прага - Йиглава - Вена - Грац - Шпильфельд  -  Марибор  -
Загреб - Карловац - Бихач - Дони-Лапац - Книн - Сплит.</p><br>

<p>E 63 Клагенфурт - Лойбльталь - Любляна - Триест - Риека.</p><br>

<p>E 67 Варшава - Лович - Вроцлав - Клодзко - Беловене -  Наход -
Градец-Кралове - Прага.</p><br>

<p>E 69 Варшава  -  Петркув  - Катовице - Чески-Тешин -  Жилина -
Тренчин - Пьештяни - Братислава - Винер-Нейштадт.</p><br>

<p>E 71 Кошице  -  Мишкольц   -   Будапешт   -   Балатоналига   -
Надьканижа - Загреб.</p><br>

<p>E 73 Будапешт  -  Сексард  - Мохач - Осиек - Джяково - Самак -
Зеница - Мостар - Меткович.</p><br>

<p>E 77 Пюшпекладань - Ньиредьхаза.</p><br>

<p style="text-indent:2em;">E 79 Орадя - Беюш - Дева  -  Петрошени - Тыргу-Жиу - Крайова -
Калафат ...   Видин   -  Враца  -  Ботевград  -  София  -
Благоевград - Сере - Салоники.</p><br>

<p>E 81 Халмеу  -  Сату-Маре  -  Залэу - Клуж - Турда  -  Себеш -
Сибиу - Питешти.</p><br>

<p>E 83 Бяла - Плевен - Яблоница - Ботевград - София.</p><br>

<p>E 87 Тулча  -  Констанца  -  Варна  -  Бургас  -   Мичурин   -
Малко-Тырново - Кыркларели - Бабаески.</p><br>

<p style="text-indent:2em;">E 89 Трабзон - Гюмюшане - Ашкале - Муту - Тунсели  -  Элязыг -
Малатья -  Мараш  -  Кемюрлер  -  Искандерон  - Антакья -
граница Сирии.</p><br>

<p>E 93 Орел - Киев - Одесса.</p>

<p></p><h4>B. Ответвления и соединительные дороги</h4><p></p>

<p>E 130 Вайле - Миддельфарт.</p><br>

<p>E 135 Хеугесунн - Хеукели - Конгсберг - Драммен.</p><br>

<p>E 136 Берген - Гудванген ... Лердальсейри - Фагернесс -
Хенефосс - Осло.</p><br>

<p>E 137 Олесунн - Ондальснес - Домбос.</p><br>

<p>E 140 Тронхейм - Стурлиен - Эстерсунд - Сундсвалль.</p><br>

<p>E 160 Турку - Тампере - Ювяскюля - Куопио.</p><br>

<p>E 200 Корк - Порт-Лейише.</p><br>

<p>E 230 Амстердам - Амерсфорт.</p><br>

<p>E 231 Амерсфорт - Гронинген.</p><br>

<p>E 232 Олдензал - Бремен.</p><br>

<p>E 233 Бремерхафен - Бремен - Вальсроде.</p><br>

<p>E 250 Штральзунд - Нейбранденбург - Берлин.</p><br>

<p>E 267 Гданьск - Швьеце - Познань - Вроцлав.</p><br>

<p>E 269 Швьеце - Лодзь - Петркув.</p><br>

<p>E 312 Бреда - Горинхем - Утрехт.</p><br>

<p>E 313 Антверпен - Льеж.</p><br>

<p>E 314 Хасселт - Херлен - Ахен.</p><br>

<p>E 330 Унна - Сест - Кассель - Херлесхаузен.</p><br>

<p>E 410 Брюссель - Намюр - Арлон.</p><br>

<p>E 420 Ахен - Сен-Вит - Люксембург.</p><br>

<p>E 440 Карловы Вары  -  Теплице  -  Турнов  -  Градец-Кралове -
Оломоуц - Жилина.</p><br>

<p>E 460 Брно - Оломоуц - Чески-Тешин - Краков.</p><br>

<p>E 461 Градец-Кралове - Брно - Вена.</p><br>

<p>E 470 Мукачево - Львов.</p><br>

<p>E 530 Офенбург - Донауэшинген.</p><br>

<p>E 532 Мюнхен   -   Гармиш   -  Партенкирхен  -  Миттенвальд  -
Зеефельд -  Инсбрук.</p><br>

<p>E 550 Ческе Будеевице - Йиглава.</p><br>

<p>E 562 Братислава  -  Эволен - Кошице.</p><br>

<p>E 571 Клуж - Деж - Бистрица - Сучава.</p><br>

<p>E 572 Бакэу - Брашов - Питешти.</p><br>

<p>E 573 Ньиредьхаза  -  Чоп  - Ужгород.</p><br>

<p>E 580 Мэрэшешти  -  Текуч  -  Альбита  - Леушень -  Кишинев  -
Одесса.</p><br>

<p>E 650 Альтенмарк - Лицен.</p><br>

<p>E 651 Филлах - Подкорен - Накло.</p><br>

<p>E 660 Суботица - Сомбор - Осиек.</p><br>

<p>E 661 Балатонкерестур   -   Надьатад  -  Барч  -  Вировитица -
Окучани - Банья-Лука - Яйце - Дони-Вакуф - Зеница.</p><br>

<p>E 671 Тимишоара - Арад - Орадя.</p><br>

<p>E 717 Турин - Савона.</p><br>

<p>E 751 Риека - Пула - Копер.</p><br>

<p>E 752 Турну-Северин - Неготин - Заечар  -  Ниш  -   Приштина -
Призрен - (Албания) - Петровац.</p><br>

<p>E 760 Белград - Чачак - Нова-Варош - Бьело Поле.</p><br>

<p style="text-indent:2em;">E 761 Бихач  -  Яйце   -   Дони-Вакуф   -  Зеница  - Сараево -
Титово-Ужице - Чачак - Кралево  -  Крушевац  -  Поете  -
Парачин - Заечар.</p><br>

<p>E 762 Сараево - Титоград - албанская граница.</p><br>

<p>E 771 Ябланица - Велико-Тырново - Шумен.</p><br>

<p>E 772 Поповица - Стара Загора - Бургас.</p><br>

<p>E 800 Албергария-а-Велья - Селорико-да-Бейра.</p><br>

<p>E 801 Вила-Франка-ди-Шира - Пегуенш.</p><br>

<p>E 804 Саламанка - Бадахос - Севилья.</p><br>

<p>E 805 Бильбао - Логроньо - Сарагоса.</p><br>

<p>E 841 Авеллино - Салерно.</p><br>

<p>E 842 Неаполь - Авеллино - Беневенто - Каноса.</p><br>

<p>E 843 Бари - Таранто.</p><br>

<p>E 844 Спеццано-Альбанесе - Сибари.</p><br>

<p>E 846 Козенца - Кортоне.</p><br>

<p>E 847 Сичиньяно - Потенца - Метапонто.</p><br>

<p>E 848 Сан-Эвфемия - Катандзаро.</p><br>

<p>E 850 Охрид - албанская граница.</p><br>

<p>E 851 Янина - албанская граница.</p><br>

<p>E 870 София - Кюстендил - Куманово.</p><br>

<p>E 880 Измир - Анкара.</p><br>

<p>E 881 Анкара - Адана.</p><br>

<p>E 901 Хаен - Гранада - Малага.</p><br>

<p>E 902 Мадрид - Валенсия.</p><br>

<p>E 931 Мадзара-дель-Валло - Джела.</p><br>

<p>E 950 Янина - Трикала - Лариса - Волос.</p><br>

<p>E 951 Ламия - Карпенисси - Амфилохия.</p><br>
 
<p>E 952 Триполис - Мегалополис - Такона.</p><br>
  
<p>E 980 Сизре - Ирак.</p>
 
<p></p><h4>Приложение II</h4><p></p>

<p></p><h4>УСЛОВИЯ, КОТОРЫМ ДОЛЖНЫ ОТВЕЧАТЬ МЕЖДУНАРОДНЫЕ АВТОМАГИСТРАЛИ</h4><p></p>

<p style="font-weight:500;">I. Общие замечания</p><br>

<p style="text-indent:2em;">I.1. Основные характеристики, которые должны быть приняты при строительстве или реконструкции
 международных автомагистралей, именуемых в дальнейшем "международными дорогами",
 изложены в приведенных ниже положениях, которые разработаны с учетом современных
 концепций в области дорожного строительства. Они не относятся к населенным пунктам.
 При прокладке международной дороги следует оставлять в стороне населенные пункты,
 если последние служат препятствием движению или представляют для него опасность.</p><br>

<p style="text-indent:2em;">I.2. Указанные ниже значения этих характеристик являются минимальными или максимальными.
 Их следует увеличивать или уменьшать, когда это возможно без дополнительных затрат или
 когда это является экономически оправданным. I.3. Все положения настоящего Приложения
 должны приниматься во внимание с учетом актуализированных затрат и выгод и, в частности
 , с учетом соображений безопасности. В отношении движения транспортных средств оценка
 производится для различных вариантов, составленных на основе различных допущений,
 касающихся, в частности, расчетной скорости &lt;*&gt;, и с учетом ожидаемого объема движения,
 его структуры и почасового распределения потоков движения в течение года.</p><br>

<p style="text-indent:2em;">При составлении проекта реконструкции или строительства дороги расчетной скоростью 
является скорость, которая выбирается для определения минимальных геометрических 
характеристик, обеспечивающих безопасное движение отдельных транспортных средств 
с этой скоростью.</p><br>

<p style="text-indent:2em;">I.4. При планировании и строительстве новой международной автомагистрали 
следует учитывать необходимость охраны окружающей среды.</p><br>

<p style="font-weight:500;">II. Категории международных дорог</p>

<p style="text-indent:2em;"></p><h6>Международные дороги делятся на следующие категории:</h6><p></p> 

<p>II.1. Дороги обычного типа</p>

<p style="text-indent:2em;"></p><h6>Категория I: </h6><p></p>

<p>дороги с двумя полосами движения (единая проезжая часть).</p>

<p style="text-indent:2em;"></p><h6>Категория II: </h6><p></p>

<p>дороги, у которых число полос движения больше двух (одна или 
несколько проезжих частей).</p><br>

<p>II.2. Автомагистрали</p>

<p style="text-indent:2em;"></p><h6>Термин "автомагистраль" означает дорогу, специально построенную и предназначенную 
для движения автотранспортных средств, которая не обслуживает придорожных 
владений и которая:</h6><p></p>

<p style="text-indent:2em;">i) за исключением отдельных мест или во временном порядке имеет для обоих направлений 
движения отдельные проезжие части, отделенные друг от друга разделительной полосой, 
не предназначенной для движения, или в исключительных случаях другими средствами;</p><br>

<p style="text-indent:2em;">ii) не имеет пересечений на одном уровне ни с дорогами, ни с железнодорожными или 
трамвайными путями, ни с пешеходными дорожками;</p><br>

<p>iii) специально обозначена в качестве автомагистрали. II.3. Скоростные дороги</p><br>

<p style="text-indent:2em;">Дороги, предназначенные для автомобильного движения, въезд на которые возможен 
только через развязки или на регулируемых перекрестках и на которых, в частности,
 остановка и стоянка запрещены.</p><br>

<p style="font-weight:500;">III. Стандарты на конструкцию дорог</p><br>

<p>III.1. Поперечный профиль</p><br>

<p style="text-indent:2em;">Земляное полотно международных дорог должно включать, кроме проезжей части или 
проезжих частей, обочины и, возможно, разделительную полосу и специальные дорожки
 для пешеходов и велосипедистов. Устройство таких специальных дорожек в пределах
 земляного полотна автомагистралей не допускается. Они допускаются на скоростных
 дорогах только в том случае, если они отделяются от них достаточно широким
 пространством.</p><br>

<p style="text-indent:2em;">Ни на проезжей части дорог обычного типа, ни в пределах земляного полотна 
автомагистралей и скоростных дорог не допускается наличие трамвайных или 
железнодорожных путей &lt;*&gt;.</p><br>

<p style="text-indent:2em;">Данное положение не распространяется на автомагистрали, специально 
сконструированные для того, чтобы включать железнодорожный путь.</p><br>

<p>III.1.1. Проезжие части</p><br>

<p>III.1.1.1. Ширина</p><br>

<p>На прямом участке минимальная ширина полосы движения на проезжей части 
должна составлять 3,5 м.</p><br>

<p style="text-indent:2em;">В кривых радиусом менее 200 м предусмотрена большая ширина, с тем чтобы 
транспортные средства максимально допустимых размеров могли беспрепятственно
 двигаться с обычной скоростью.</p><br>

<p style="text-indent:2em;">При проектной скорости, равной или большей 100 км/час, не следует включать в 
эту величину продольную разметку краев проезжей части.</p><br>

<p style="text-indent:2em;">Однако ширина дополнительной полосы движения для транспортных средств, движущихся
 с небольшой скоростью на подъеме, может быть уменьшена до 3 м.</p><br>

<p>III.1.1.2. Поперечный уклон</p><br>

<p style="text-indent:2em;">На прямом участке поперечный профиль проезжей части должен состоять из одной 
или двух плоскостей с поперечным уклоном 2 - 3%.</p><br>

<p>III.1.2. Обочины и разделительная полоса</p><br>

<p style="text-indent:2em;">III.1.2.1. Рекомендуемая минимальная ширина обочины должна быть 3,25 м для 
дорог обычного типа и скоростных дорог и 3,75 м для автомагистралей.</p><br>

<p style="text-indent:2em;">III.1.2.2. Обочины автомагистралей и скоростных дорог должны включать с правой 
стороны проезжей части непрерывную, имеющую покрытие или стабилизированную полосу
 остановки, минимальной шириной в 2,5 м, на которой, в случае крайней необходимости,
 могло бы остановиться транспортное средство.</p><br>

<p style="text-indent:2em;">Устройство такой полосы рекомендуется на дорогах обычного типа. Если такой полосы
 нет или если ее ширина меньше 2,5 м, следует устраивать через определенные 
 интервалы места для остановки.</p><br>

<p>В случае необходимости следует также предусматривать вне проезжей части места
 для остановки автобусов.</p><br>

<p style="text-indent:2em;">Во всех случаях на обочинах дороги должны предусматриваться имеющие покрытие 
или стабилизированные боковые полосы шириной 1 м. По соображениям безопасности
 вдоль автомагистралей или скоростных дорог должны устраиваться более широкие 
 полосы, свободные от каких-либо препятствий.</p><br>

<p style="text-indent:2em;">III.1.2.3. При наличии разделительной полосы ее минимальная рекомендуемая ширина
 между проезжими частями для автомагистралей должна составлять 4 м. Рекомендуется
 увеличивать эту ширину, особенно на кривых, если это необходимо по условиям 
 видимости.</p><br>

<p style="text-indent:2em;">Рекомендуется устраивать по краям разделительной полосы направляющие и предохранительные
 полосы, имеющие покрытие или стабилизированные, шириной не менее 1 м.</p><br>

<p>III.1.3. Специальные дорожки</p><br>

<p style="text-indent:2em;">На обочинах дорог обычного типа, где интенсивность движения достигает по крайней мере
 2000 транспортных средств в сутки, необходимо устаивать специальные дорожки, предназначенные
 для пешеходов, велосипедистов или приравненных к ним пользователей дороги в тех случаях
 , когда число их в одном направлении достигает 200 за 30 мин при самом интенсивном
 движении или 1000 в сутки.</p><br>

<p style="text-indent:2em;">Велосипедные дорожки, как правило, предусматриваются для одностороннего движения и 
должны иметь ширину не менее 2,2 м. Между проезжей частью и специальными дорожками
 следует устраивать разделительную полосу шириной не менее 1 м.</p><br>

<p>III.2. План и продольный профиль</p><br>

<p>III.2.1. Однородность и соответствие плана и продольного профиля</p><br>

<p style="text-indent:2em;">Характеристики международных дорог должны быть однородными на протяжении участков 
достаточно большой длины. Изменения характеристик производятся в таких местах, которые
 водитель обычно может предвидеть (проезд через населенный пункт или изменение рельефа
 местности). Если это невозможно, изменение характеристик осуществляется постепенно.</p><br>

<p style="text-indent:2em;">План и продольный профиль дороги должны координироваться таким образом, чтобы она не 
представлялась водителю слишком неоднородной, позволяла ему предвидеть ее трассу и ясно
 различать характерные места, в частности, перекрестки, въезды и съезды в местах 
 развязок.</p><br>

<p>III.2.2. Геометрические характеристики</p><br>

<p style="text-indent:2em;">III.2.2.1. Покрытия международных дорог должны всюду представлять собой ровную поверхность
. Максимальная разница уровня на участке длинной 3 м не должна превышать 4 мм.</p>

<p style="text-indent:2em;"></p><h6>III.2.2.2. Основные геометрические характеристики международных дорог приведены в следующей
 таблице; они основаны на коэффициенте продольного трения (при заблокированных колесах и 
 гладких шинах) 0,4 скорости 50 км/час; они должны рассматриваться в качестве минимальных
 величин, которые следует соблюдать:</h6><p></p>

<p style="text-indent:2em;">Расчетная скорость 120 км/час принимается лишь при раздельных проезжих частях и в том случае
, если большая часть перекрестков устаивается в виде развязок (см. ниже раздел IV). Расчетная
 скорость 140 км/час принимается только для автомагистралей. Вогнутые кривые должны быть такого
 радиуса, чтобы при данной расчетной скорости вертикальное ускорение не превышало 0,25 м/кв.
 сек. Значения горизонтальных кривых являются минимальными и соответствуют максимальному 
 наклону, равному 7%.</p><br>

<p style="text-indent:2em;">Они достаточны для обеспечения устойчивости транспортного средства и удобства водителя при 
обычных условиях.</p><br>

<p>Уклон, получаемый в результате сочетания продольного уклона и наклона, не должен превышать
 10%.</p><br>

<p>III.2.2.3. В плане круговые и прямые участки должны соединяться переходными кривыми.</p><br>

<p style="text-indent:2em;">III.2.2.4. Видимость в горизонтальной и вертикальной плоскости должна быть такой, чтобы 
обеспечивалась одна и та же степень безопасности с учетом уклонов, при наличии таковых.</p>

<p style="text-indent:2em;"></p><h6>Минимальные расстояния видимости, необходимые при обгоне на проезжей части с двусторонним
 движением, приводятся в следующей таблице:</h6><p></p>

<p style="text-indent:2em;">Эти расстояния должны обеспечиваться на возможно большем протяжении дороги и возможно 
более равномерно распределяться по ее длине.</p><br>

<p style="text-indent:2em;">III.2.2.5. При недостаточной видимости на вершинах и в кривых рекомендуется раздвоение 
проезжей части дорог обычного типа с двумя или тремя полосами движения.</p><br>

<p>III.3. Интенсивность движения</p><br>

<p style="text-indent:2em;">Дороги различных категорий при обычных условиях, т.е. при определенном качестве или уровне 
обслуживания, обязательном для международных дорог, и в соответствии со стандартами, 
определенными в пункте III.2, должны обеспечивать интенсивность &lt;*&gt; движения, указанную 
в колонке 1 следующей таблицы, выраженную в количестве транспортных единиц (ТЕ) 
в час &lt;**&gt;.</p><br>

<p style="text-indent:2em;">Транспортная единица соответствует легковому автомобилю. Для других транспортных 
средств применяется соответствующий коэффициент.</p><br>

<p>Вне городских зон.</p><br>

<p style="text-indent:2em;">Рекомендуется, чтобы для дорог определенной категории приведенная в колонке 1 интенсивность 
движения превышалась не более чем в течение 50 часов в год, за исключением случаев, когда 
экономически целесообразно устройство дополнительной полосы движения или перевод дороги в 
более высокую категорию.</p><br>

<p style="text-indent:2em;">Когда интенсивность движения, указанная в колонке 2, превышается в течение более 50 часов 
в год, рекомендуется предусмотреть оборудование дополнительной полосы движения или 
реконструирование дороги до более высокой категории с учетом затрат, связанных со 
строительством и окружающей средой.</p>

<p style="text-indent:2em;"></p><h6>Эти значения относятся к непрерывному потоку движения, при условии:</h6><p></p>

<p style="text-indent:2em;">i) что пересечения на одном уровне не слишком многочисленны и не вызывают чрезмерно 
большого числа дорожно-транспортных происшествий;</p><br>

<p style="text-indent:2em;">ii) что на дорогах с двумя или тремя полосами движения на всем их протяжении 
обеспечиваются необходимые расстояния видимости при обгоне.</p><br>

<p style="text-indent:2em;">Не рекомендуется строить дороги с тремя полосами движения, когда превышается нормальная 
интенсивность движения, указанная в колонке 1 приведенной таблицы.</p><br>

<p style="text-indent:2em;">Когда на дорогах с четырьмя полосами максимальная интенсивность движения в более 
напряженном направлении превышает 1500 ТЕ/час в течение более 50 часов в год, 
рекомендуется по соображениям безопасности устраивать отдельные проезжие части 
с односторонним движением.</p><br>

<p style="font-weight:500;">IV. Стандарты на конструкцию пересечений</p><br>

<p>IV.1. Определения</p><br>

<p style="text-indent:2em;">Те места, где международные дороги пересекаются друг с другом, с другими дорогами 
или с другими путями сообщения, называются "пересечениями".</p><br>

<p>Этот текст составлен, исходя из предположения, что движение является 
правосторонним.</p>

<p style="text-indent:2em;"></p><h6>Пересечения бывают следующих видов:</h6><p></p>

<p style="text-indent:2em;"></p><h6>Пересечения дорог обычного типа:</h6><p></p>

<p>пересечения на одном уровне, когда участки дороги в месте пересечения расположены 
в одной плоскости;</p>

<p style="text-indent:2em;"></p><h6>пересечения в разных уровнях или пересечения с разделенными уровнями, когда по 
крайней мере одна из дорог, пересекающая одну или несколько других дорог, находится 
по отношению к ним в другом уровне. Пересечения автомагистралей или скоростных дорог 
с дорогами той же категории:</h6><p></p>

<p style="text-indent:2em;"></p><h6>Развязки типа A, соединительные дороги которых позволяют избегать всякого пересечения 
потоков движения. Пересечения автомагистралей с дорогами обычного типа: Развязки типа 
B, обеспечивающие отсутствие пересечения проезжих частей автомагистралей. Пересечения 
скоростных дорог с дорогами обычного типа: На важных пересечениях:</h6><p></p>

<p style="text-indent:2em;"></p><h6>Развязки типа B, обеспечивающие отсутствие пересечения проезжей(их) части(ей) 
скоростной дороги. На пересечениях второстепенной важности, где по экономическим 
соображениям устройство развязки нецелесообразно, предусматриваются:</h6><p></p>

<p>Пересечения на одном уровне или в разных уровнях, регулируемые по возможности с 
помощью светофоров.</p><br>

<p>IV.2. Пересечения на дорогах обычного типа</p><br>

<p>IV.2.1. Пересечения на одном уровне</p><br>

<p style="text-indent:2em;">IV.2.1.1. На международных дорогах следует избегать пересечений на одном уровне в тех случаях, когда это является экономически 
целесообразным.</p><br>

<p style="text-indent:2em;">IV.2.1.2. Пересечения на одном уровне, включающие более четырех ответвлений, 
следует упрощать путем группировки некоторых потоков движения в соответствии с 
их интенсивностью.</p><br>

<p style="text-indent:2em;">IV.2.1.3. Площади с круговым движением и установку светофоров следует предусматривать 
лишь в тех случаях, когда другие решения, позволяющие избегать слияния и перекрещивания
 потоков движения, являются экономически нецелесообразными.</p><br>

<p style="text-indent:2em;">IV.2.1.4. Обзорность перекрестка должна быть обеспечена на достаточном расстоянии при 
приближении к нему, с тем чтобы водители имели достаточно времени для принятия решений, 
зависящих от способа регулирования и условий движения в данный момент. Эта обзорность будет 
лучшей, если проезжие части, и особенно те, по которым следуют водители, обязанные пропускать 
другие транспортные средства, будут иметь небольшой наклон перед перекрестком.</p><br>

<p style="text-indent:2em;">IV.2.1.5. Международная дорога имеет приоритет по отношению ко всем другим дорогам при 
наличии нескольких международных дорог приоритет должен устанавливаться в соответствии 
с относительными значениями объема движения.</p><br>

<p style="text-indent:2em;">IV.2.1.6. Скорость потока движения на международной дороге, имеющей приоритет, не должна 
снижаться. С этой целью между обоими направлениями движения следует устраивать зоны ожидания 
достаточной длины для транспортных средств, осуществляющих левый поворот.</p><br>

<p style="text-indent:2em;">IV.2.1.7. На важных перекрестках специальные полосы ускорения и замедления в том случае,
 если это экономически целесообразно, следует устаивать на въездах и съездах с проезжей
 части международных дорог, имеющих приоритет.</p>

<p style="text-indent:2em;"></p><h6>IV.2.1.8. Такой перекресток должен включать на проезжих частях, не имеющих приоритета, 
направляющие островки для разделения потоков движения, удовлетворяющие следующим требованиям:</h6><p></p>

<p style="text-indent:2em;">a) геометрия совокупности полос движения должна быть как можно более простой, 
чтобы быть сразу понятой водителями;</p><br>

<p style="text-indent:2em;">b) скорость движения в неприоритетных направлениях должна снижаться, причем отклонения 
соответствующих полос движения должны соответствовать объему движения по ним;</p><br>

<p>c) пересекающие направления должны располагаться, по возможности, под прямым углом;</p><br>

<p style="text-indent:2em;">d) места пересечения должны располагаться на определенном расстоянии друг от друга (не
 соединяться) таким образом, чтобы водители могли подъезжать к каждому из них отдельно 
 через промежуточные зоны ожидания;</p><br>

<p>e) самую прямую дорожку следует оставлять для пешеходов;</p><br>

<p style="text-indent:2em;">f) при наличии велосипедных дорожек следует отводить велосипедистов от собственно перекрестка
 таким образом, чтобы они пересекали полосы для транспортных средств, по возможности, 
 под прямым углом;</p><br>

<p style="text-indent:2em;">g) направляющие островки следует окаймлять слегка выступающим бордюром белого цвета. Ночью, 
если это экономически целесообразно, они должны освещаться. При соответствии освещения бордюр
 должен иметь светоотражающую поверхность.</p><br>

<p>IV.2.2. Пересечения в разных уровнях</p><br>

<p style="text-indent:2em;">В том случае, когда установлена экономическая целесообразность соответствующей конструкции
, следует разделять уровни некоторых значительных потоков движения, с тем чтобы ликвидировать
 пересечения их с другими потоками.</p><br>

<p style="text-indent:2em;">План и продольный профиль соединительных дорог, находящихся в разных уровнях, должны
 соответствовать тем принципам и стандартам строительства развязок, которые применимы
 к ним (см. IV.3).</p><br>

<p style="text-indent:2em;">Соединительные дороги, расположенные на одном уровне, должны образовывать при пересечениях
 перекрестки, соответствующие вышеизложенным условиям (см. IV.2.1).</p><br>

<p>IV.3. Развязки</p><br>

<p>IV.3.1. Определения</p><br>

<p style="text-indent:2em;">Проезжие части развязок подразделяются на основные проезжие части и проезжие части
 соединительных дорог, которые связывают основные проезжие части.</p><br>

<p style="text-indent:2em;">Основной проезжей частью считается та, которая несет основной поток движения (учитывая
 при необходимости изменения этого потока в отдельные часы) и в отношении которой не
 должно допускаться значительное уменьшение расчетной скорости.</p><br>

<p>IV.3.2. Движение на проезжей части развязок</p><br>

<p style="text-indent:2em;">На развязках типа A движение должно быть односторонним. На развязках типа B на некоторых
 участках соединительных дорог движение может быть двусторонним; однако движение на въездах
 и съездах с автомагистрали или скоростной дороги всегда должно быть односторонним.</p><br>

<p>IV.3.3. Принципы планировки развязок</p>

<p style="text-indent:2em;"></p><h6>Планировка развязок должна удовлетворять следующим принципам:</h6><p></p>

<p>IV.3.3.1. Принцип A. Тип развязки</p><br>

<p style="text-indent:2em;">При выборе типа развязки и ее основных проезжих частей и соединительных дорог следует
 учитывать абсолютную и относительную интенсивность движения по этой развязке.</p><br>

<p>IV.3.3.2. Принцип B. Разделение потоков движения</p><br>

<p style="text-indent:2em;">Когда проезжая часть разделяется на две самостоятельные проезжие части, разделение обоих
 потоков движения должно осуществляться таким образом, чтобы это не вызывало значительного
 снижения скорости транспортных средств.</p><br>

<p style="text-indent:2em;">Для этого водитель должен иметь время, чтобы занять наиболее подходящую полосу движения для
 следования в выбранном направлении, и должна быть обеспечена достаточная видимость места 
 разветвления. На развязках типа A проезжая часть, разделяющаяся на две самостоятельные 
 проезжие части, должна быть расширена перед местом разделения, и при этом число полос 
 движения должно быть равно сумме полос движения на обеих проезжих частях на расстоянии, 
 достаточном для разделения потоков перед местом разветвления. Уширение желательно делать 
 с правой стороны.</p><br>

<p style="text-indent:2em;">Неосновной поток движения должен идти по правой проезжей части, чтобы, по возможности, 
меньшее число транспортных средств снижало скорость при перестроении. При необходимости 
уменьшить скорость движения этого потока следует устраивать полосу замедления. Эта правая 
проезжая часть должна, по возможности, постепенно подниматься по отношению к основной проезжей 
части, с тем чтобы облегчить в случае необходимости снижение скорости и обеспечить лучшую 
видимость места разветвления.</p><br>

<p style="text-indent:2em;">На развязках типа B проезжая часть съезда с проезжей части автомагистрали или скоростной 
дороги должна отклоняться вправо и включать полосу замедления.</p><br>

<p>IV.3.3.3. Принцип C. Слияние потоков движения</p>

<p style="text-indent:2em;"></p><h6>Когда две проезжие части сливаются в одну, слияние обоих потоков движения должно 
происходить безопасно и без значительного уменьшения скоростей движения транспортных
 средств. Для этого:</h6><p></p>

<p>a) транспортные средства неосновного потока движения должны вливаться справа в
 основной поток;</p><br>

<p style="text-indent:2em;">b) водитель, который должен въезжать на дорогу, должен хорошо видеть другую проезжую
 часть перед местом слияния и после него.</p><br>

<p style="text-indent:2em;">При вливании в основной поток, при необходимости через полосу ускорения, не должно 
происходить заметного снижения скорости основного потока. Видимость улучшается и маневр
 вливания в основной поток облегчается, если проезжая часть, несущая поток, который 
 должен вливаться, имеет небольшой наклон по направлению к другой проезжей части.</p><br>

<p style="text-indent:2em;">c) желательно также, чтобы с основной проезжей части была обеспечена хорошая обзорность
 другой проезжей части;</p><br>

<p style="text-indent:2em;">d) когда при слиянии двух основных проезжих частей в одну уменьшается общее число полос
 движения, такое уменьшение должно производиться на достаточном расстоянии от места 
 слияния.</p><br>

<p style="text-indent:2em;">На развязках типа B проезжая часть въезда должна примыкать к проезжей части автомагистрали
 или скоростной дороги справа и включать полосу ускорения.</p><br>

<p>IV.3.3.4. Принцип D. Участки пересечения</p><br>

<p style="text-indent:2em;">На основных проезжих частях следует избегать участков пересечения. Участок пересечения 
допускается лишь в том случае, если пересекающиеся потоки движения незначительны; с правой
 стороны основной проезжей части, если возможно, должна устраиваться по крайней мере одна
 дополнительная полоса движения. В любом случае геометрические характеристики участка пересечения
 и проезжих частей перед ним и после него должны быть такими, чтобы скорости пересекающихся потоков
 транспортных средств не были слишком различными, и чтобы не возникала необходимость значительного
 снижения скоростей на этих проезжих частях.</p><br>

<p style="text-indent:2em;">IV.3.3.5. Принцип E. Места разветвления и слияния В пределах одной развязки на каждой
 основной проезжей части должно быть только одно место разветвления и одно место слияния.
 В любом случае при наличии на одной и той же проезжей части нескольких мест разветвления
 или слияния должны приниматься меры для обеспечения удобного маневрирования 
 и независимой сигнализации последующих мест разветвления или слияния.</p><br>

<p>IV.3.4. Геометрические характеристики развязок</p><br>

<p>IV.3.4.1. Расчетная скорость для основных проезжих частей</p><br>

<p style="text-indent:2em;">Значения расчетной скорости для основных проезжих частей развязки должны, по возможности,
 совпадать со значениями этой скорости для проезжих частей, расположенных за пределами
 развязки и служащих продолжением основных проезжих частей развязки, и в любом случае
 составлять по крайней мере 3/4 этой величины. Однако на развязках типа B на проезжей
 части как автомагистралей, так и скоростных дорог не следует допускать уменьшения
 расчетной скорости.</p><br>

<p style="text-indent:2em;">IV.3.4.2. Радиус соединительных дорог На горизонтальном участке минимальный радиус
 внутренней кромки проезжей части должен быть 50 м. Это значение теоретически 
 соответствует максимально допустимому наклону проезжей части на горизонтальном 
 участке.</p><br>

<p style="text-indent:2em;">В любом случае кривые малого радиуса должны соединяться переходными кривыми (с 
постепенным изменением кривизны) различной длины, с тем чтобы у водителя не 
возникало трудностей при изменении скорости.</p><br>

<p>IV.3.4.3. Ширина соединительных дорог</p><br>

<p style="text-indent:2em;">В любом месте должна обеспечиваться возможность объезда остановившегося 
транспортного средства. Желательно также, чтобы на соединительных дорогах 
определенной длины обеспечивалась возможность обгона движущегося транспортного
 средства.</p>

<p style="text-indent:2em;"></p><h6>Для этого:</h6><p></p>

<p style="text-indent:2em;">общая ширина проезжей части с одной полосой движения должна быть по крайней
 мере 6 м, включая стабилизированную обочину, обычно не используемую для
 движения;</p><br>

<p style="text-indent:2em;">ширина проезжей части с двумя полосами движения должна быть про крайней мере
 7 м. В этом случае наличие стабилизированной обочины не обязательно. 
 Такая проезжая часть должна быть сведена в одну полосу движения вблизи
 места въезда на основную проезжую часть (или съезда с нее), если общее
 число полос движения на основной проезжей части не увеличивается после
 места въезда (или не уменьшается после места съезда).</p><br>

<p>IV.3.4.4. Участки пересечения</p><br>

<p style="text-indent:2em;">Рекомендуется, чтобы минимальная длина участков пересечения была 0,2Q
 (м), где Q - общая величина пересекающихся потоков в ТЕ/час. Необходимое 
 число полос движения согласно этой гипотезе определяется путем умножения
 меньшего из пересекающихся потоков на 3.</p><br>

<p style="text-indent:2em;">Если в исключительных случаях нельзя избежать участка пересечения на
 основной проезжей части, его длина должна быть Q м, но не меньше 500
 м.</p><br>

<p style="text-indent:2em;">Развязка должна быть сконструирована таким образом, чтобы в ее пределах
 общая величина пересекающихся потоков движения была меньше 2000 ТЕ/час.</p><br>

<p>IV.3.4.5. Длина полос ускорения</p><br>

<p style="text-indent:2em;">Рекомендуется устройство проезжих частей въездов с собственно полосой
 ускорения, за которой следует полоса переменной ширины, называемая 
 клином.</p><br>

<p style="text-indent:2em;">Когда проезжая часть автомагистрали или скоростной дороги и полоса 
ускорения находятся в одном уровне на прямом участке, общая длина полосы
 ускорения должна быть не менее 300 м; при этом длина собственно полосы ускорения
 должна быть не менее 200 м. При наличии отклонений в плане и в продольном
 профиле длина полос ускорения должна соответствующим образом
 изменяться.</p><br>

<p>IV.3.4.6. Длина полос замедления</p><br>

<p style="text-indent:2em;">Полоса замедления состоит из полосы переменной ширины, называемой клином
, за которой идет собственно полоса замедления постоянной ширины, которая
 может быть расположена параллельно проезжей части автомагистрали и рядом
 с ней или в стороне от нее. Этот клин должен позволять водителю постепенно
 выходить из основного потока без заметного уменьшения скорости; его длина
 определяется, исходя из предположения, что достаточное время для совершения
 этого маневра составляет около 3,5 секунды. Длина собственно полосы замедления
 определяется, исходя из того, что величина замедления транспортных средств
 составляет не более 1,5 м/кв. сек.</p><br>

<p>IV.4. Пересечения с железными дорогами</p><br>

<p>Пересечения международных дорог с железными дорогами должны устаиваться
 в разных уровнях.</p><br>

<p style="font-weight:500;">V. Сооружения</p><br>

<p>V.1. Поперечный профиль</p><br>

<p style="text-indent:2em;">За исключением особых случаев (горные районы, особо трудные грунты и т.д.),
 не следует допускать каких-либо ограничений характеристик проезжей части и,
 в соответствующих случаях, велосипедных и пешеходных дорожек над или под 
 сооружениями. В частности, на автомагистралях и на скоростных дорогах должна 
 сохраняться предусмотренная в пункте III.1.2 полоса остановки на обочине.</p><br>

<p>V.2. Свободная высота</p><br>

<p>Минимальная свободная высота над проезжей частью должна составлять 4,5 м.</p><br>

<p style="font-weight:500;">VI. Устройства для обеспечения безопасности</p><br>

<p>VI.1. Освещение</p><br>

<p style="text-indent:2em;">При большой интенсивности ночного движения, когда это целесообразно по экономическим
 и эксплуатационным соображениям, участки, перекрестки и развязки международных 
 дорог должны освещаться; такое освещение должно быть равномерным и достаточным
 для того, чтобы водители автомобилей могли не пользоваться огнями дальнего света.</p><br>

<p>VI.2. Противоослепляющие устройства</p><br>

<p style="text-indent:2em;">При значительном ночном движении следует предусматривать насаждения или 
экраны на разделительных полосах автомагистралей и скоростных дорог и, в 
случае необходимости, на их обочинах, если огни дальнего света транспортных
 средств, движущихся в встречном направлении по другой проезжей части или по
 другой дороге, идущей рядом с международной дорогой, создают визуальные 
 помехи на международной дороге.</p><br>

<p>VI.3. Предохранительные барьеры</p><br>

<p style="text-indent:2em;">Необходимо предусматривать предохранительные барьеры во избежание столкновений
 с препятствиями, находящимися на обочинах или на разделительной полосе, 
 при условии, однако, что риск и последствия столкновения с барьерами будут
 меньшими, чем при столкновении с ограждаемыми ими препятствиями. 
 Не обязательно ограждать барьерами опоры для дорожных знаков и 
 осветительного оборудования, если конструктивные характеристики 
 этих опор могут уменьшить последствия наезда на них транспортных 
 средств.</p><br>

<p style="text-indent:2em;">Рекомендуется устанавливать предохранительные барьеры на максимальном 
расстоянии от края проезжей части, совместимом с наличием транспортных
 средств или посторонних препятствий.</p>

<p style="text-indent:2em;"></p><h6>На автомагистралях и скоростных дорогах предохранительные барьеры 
следует устраивать, в частности:</h6><p></p>

<p style="text-indent:2em;">a) на разделительной полосе, когда ее ширина менее 6 м, если суточный
 объем движения достигает 20000 при 2 x 2 полосах движения или 30000 
 при 2 x 3 полосах движения, или когда ее ширина менее 4,5 м, 
 независимо от интенсивности движения;</p>

<p style="text-indent:2em;"></p><h6>b) на обочинах:</h6><p></p>

<p style="text-indent:2em;">i) когда на расстоянии менее 3,5 м от края проезжей части расположены 
неподвижные и жесткие препятствия, такие, как береговые устои и опоры
 мостов, подпорные стенки, опоры эстакад, непрерывный ряд фонарных 
 столбов и т.д.;</p><br>

<p>ii) на насыпях, когда их высота или откос представляют очевидную опасность;</p><br>

<p style="text-indent:2em;">iii) на участках, где на расстоянии менее 10 м от края проезжей части 
находится водный путь, автомобильная дорога или железнодорожные пути;</p><br>

<p>c) на сооружениях, в частности при наличии барьеров по обеим сторонам
 сооружения.</p><br>

<p style="font-weight:500;">VII. Ландшафт</p><br>

<p style="text-indent:2em;">VII.1. Соответствие плана и продольного профиля должно быть обеспечено 
(III.2.1) не только с точки зрения безопасности, но также с точки зрения гармоничного вписывания дороги в местность. VII.2. 
Все элементы ландшафта вместе с дорожными знаками должны способствовать 
повышению удобств для водителей и безопасности движения. В частности, 
желательно создавать с помощью насаждений из кустарника, гармонирующих 
с окружающей растительностью, хорошее визуальное обозначение трассы и 
устраивать в однообразных равнинных районах экраны из листвы, определяющие
 глубину поля обзора.</p><br>

<p style="text-indent:2em;">VII.3. Насаждения кустарника должны также устраиваться для обеспечения 
защиты пользователей от ослепления, ветра, снежных заносов и, в соответствующих 
случаях, для защиты жителей прилегающей к дороге полосы от шума и от 
загрязнения воздуха.</p><br>

<p style="text-indent:2em;">VII.4. В целях безопасности и по эстетическим соображениям по сторонам 
международных дорог запрещается установка рекламных щитов.</p><br>

<p style="font-weight:500;">VIII. Вспомогательные службы</p><br>

<p>VIII.1. Пограничные устройства</p><br>

<p style="text-indent:2em;">На границах необходимо предусматривать соответствующие дорожные устройства
 и, в частности, места для стоянки и проверки транспортных средств. 
 Желательно разделять коммерческие и туристические перевозки и создавать
 комбинированные пограничные пункты.</p><br>

<p>VIII.2. Различные устройства</p><br>

<p style="text-indent:2em;">На автомагистралях и, по возможности, на скоростных дорогах, в стороне от
 проезжей части, должны устаиваться на определенном расстоянии друг от друга
 зоны обслуживания и стоянки. Зоны обслуживания должны включать заправочные
 станции, места для стоянки, туалеты, пункты первой помощи и, по возможности,
 рестораны и мотели.</p><br>

<p style="text-indent:2em;">Места для стоянки устаиваются лишь для остановки транспортных средств и обычно
 не обеспечиваются всеми вышеупомянутыми средствами обслуживания.</p><br>

<p style="text-indent:2em;">Доступ в зоны обслуживания и стоянки на автомагистралях &lt;*&gt; должен осуществляться
 только с автомагистралей. Эти зоны должны соединяться с автомагистралями с помощью
 специальных полос движения для въезда и выезда, отвечающих требованиям, касающимся
 соответствующих полос на развязках типа B.</p><br>

<p style="text-indent:2em;">Однако для поставщиков и обслуживающего персонала могут устаиваться въезды с обычной сети. В менее развитых районах поблизости от международной
 дороги должны находиться заправочные станции и, в случае необходимости, гаражи, 
 мастерские, а также помещения для отдыха и приема пищи.</p><br> 
 
<p>VIII.3. Пункты первой помощи</p><br>

<p style="text-indent:2em;">Вдоль международных дорог, в дополнение к местным средствам обслуживания, должны, 
в случае необходимости, устаиваться пункты первой помощи. Они должны быть оборудованы
 в соответствии с рекомендациями Постоянной международной комиссии по оказанию первой
 помощи на дорогах и Лиги обществ Красного Креста.</p><br>

<p>VIII.4. Телесвязь</p><br>

<p style="text-indent:2em;">На международных дорогах на определенном расстоянии друг от друга должны устанавливаться
 телефоны или устраиваться другие посты, обеспечивающие надежную связь с пунктами первой
 помощи. Они должны быть простыми в эксплуатации; для облегчения пользования ими желательно,
 чтобы инструкции давались в виде обозначений или идеограмм. Расположенные на достаточно 
 близком расстоянии друг от друга стрелки должны указывать на местоположение ближайшего 
 поста.</p>

<p></p><h4>Приложение III</h4><p></p>

<p></p><h4>ИДЕНТИФИКАЦИЯ И МАРШРУТНЫЕ МАРКИ ДЛЯ ОБОЗНАЧЕНИЯ ДОРОГ E</h4><p></p>

<p>1. Знак, используемый для идентификации и обозначения дорог E, имеет четырехугольную
 форму.</p><br>

<p style="text-indent:2em;">2. Этот знак состоит из буквы E, за которой, как правило, следует номер дороги, 
обозначенной арабскими цифрами.</p><br>

<p>3. Он представляет собой белую надпись на зеленом фоне; он может дополнять другие 
знаки или сочетаться с ними.</p><br>

<p style="text-indent:2em;">4. Знак должен быть такого размера, чтобы водитель транспортного средства, движущегося 
на большой скорости, мог легко опознать и понять этот знак.</p><br>

<p style="text-indent:2em;">5. Знак, предназначенный для идентификации и обозначения дорог E, не исключает знака, 
идентифицирующего дорогу в национальном плане.</p><br>

<p style="text-indent:2em;">Заявления СССР, сделанные при присоединении к Соглашению: "Союз Советских Социалистических
 Республик не считает себя связанным статьей 13 Европейского соглашения о международных
 автомагистралях от 15 ноября 1975 года и заявляет, что для передачи арбитрам любого спора
 между Договаривающимися Сторонами относительно толкования или применения Европейского
 соглашения необходимо в каждом отдельном случае согласие всех Сторон, участвующих в споре,
 и что арбитрами могут являться лишь лица, назначенные участниками спора с их общего 
 согласия".</p><br>

<p style="text-indent:2em;">"Сделанное Правительством ФРГ заявление о распространении на Западный Берлин Европейского
 соглашения о международных автомагистралях 1975 года несовместимо с положениями Четырехстороннего
 соглашения от 3 сентября 1971 года. В соответствии с этими положениями ФРГ не имеет права
 распространять на Западный Берлин международные соглашения и договоренности, затрагивающие
 вопросы безопасности и статуса. Упомянутое же Соглашение непосредственно затрагивает именно
 эти вопросы. Учитывая изложенное, Советская Сторона рассматривает указанное заявление
 Правительства ФРГ как неправомерное и не имеющее юридической силы.</p><br>

<p style="text-indent:2em;">Исходя из того, что по Четырехстороннему соглашению правительства Франции, Великобритании 
и США сохраняют свои права и ответственность в отношении представительства за границей 
интересов Западного Берлина и его постоянных жителей, включая права и ответственность, 
касающиеся вопросов безопасности и статуса, как в международных организациях, так и в 
отношениях с другими странами, Советская Сторона будет обращаться по всем вопросам, 
могущим возникнуть в связи с применением и выполнением Соглашения в Западном Берлине,
 к властям Франции, Великобритании и США".</p><br>
 
 <p><a href="https://topuch.ru/1-obekt-i-predmet-mejdunarodnoj-logistiki-metodi-resheniya-zad/index5.html">Источник</a></p>

</div>
`