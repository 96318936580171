export const md9 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->


<p></p><h4>ЗАКОН РЕСПУБЛИКИ МОЛДОВА о гражданской авиации</h4><p></p>

<p></p><h4>Глава I - ОБЩИЕ ПОЛОЖЕНИЯ</h4><p></p>

<p></p><h4>Глава II - ГОСУДАРСТВЕННАЯ АДМИНИСТРАЦИЯ ГРАЖДАНСКОЙ АВИАЦИИ</h4><p></p>

<p></p><h4>Глава III - ВОЗДУШНОЕ СУДНО</h4><p></p>

<p></p><h4>Глава IV - АВИАЦИОННЫЙ ПЕРСОНАЛ</h4><p></p>

<p></p><h4>Глава V - АЭРОДРОМЫ (АЭРОПОРТЫ)</h4><p></p>

<p></p><h4>Глава VI - ЛЕТНАЯ ДЕЯТЕЛЬНОСТЬ</h4><p></p>

<p></p><h4>Глава VII - ВОЗДУШНЫЕ ПЕРЕВОЗКИ</h4><p></p>

<p></p><h4>Глава VIII - ДРУГАЯ ДЕЯТЕЛЬНОСТЬ В ОБЛАСТИ ГРАЖДАНСКОЙ АВИАЦИИ</h4><p></p>

<p></p><h4>Глава IX - АВИАЦИОННАЯ БЕЗОПАСНОСТЬ</h4><p></p>

<p></p><h4>Глава X - Правонарушения и ответственность</h4><p></p>

<p></p><h4>Глава XI - ЗАКЛЮЧИТЕЛЬНЫЕ И ПЕРЕХОДНЫЕ ПОЛОЖЕНИЯ</h4><p></p>

<p></p><h4>Глава I</h4><p></p>

<p></p><h4>ОБЩИЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 1. Область применения закона</p><br>

<p style="text-indent:2em;">(1) Настоящий закон регулирует отношения, возникающие в связи с использованием воздушного пространства Республики Молдова и деятельностью в области гражданской
 авиации на территории Республики Молдова.</p><br>

<p style="text-indent:2em;">(2) Настоящий закон применяется ко всем физическим и юридическим лицам, осуществляющим деятельность в области гражданской авиации на территории Республики Молдова, ко всем воздушным судам, находящимся
 в воздушном пространстве Республики Молдова, а также воздушным судам, зарегистрированным в Республике Молдова, где бы они ни находились.</p><br>

<p style="text-indent:2em;">(3) Настоящий закон не применяется к государственным воздушным судам, под которыми понимаются воздушные суда, используемые на военной, таможенной или полицейской службах. 
При установлении правил для государственных воздушных судов Правительство учитывает безопасность полетов гражданских воздушных судов.</p><br>

<p style="text-indent:2em;">(4) Настоящий закон применяется в целях защиты суверенитета, безопасности и экономических интересов Республики Молдова в рамках взаимовыгодного международного сотрудничества 
в области гражданской авиации.</p><br>

<p style="font-weight:500;">Статья 2. Суверенитет над воздушным пространством</p><br>

<p style="text-indent:2em;">Республика Молдова обладает полным и исключительным суверенитетом над воздушным пространством над своей территорией.</p><br>

<p style="font-weight:500;">Статья 3. Основные определения</p>

<p style="text-indent:2em;"></p><h6>В целях настоящего закона используются следующие основные определения:</h6><p></p>

<p style="text-indent:2em;"></p><h6>авиационное происшествие - событие, связанное с использованием воздушного судна, которое имеет место с момента, когда какое-либо лицо поднимается на борт воздушного судна с намерением совершить полет,
 до момента, когда все находившиеся на борту лица покинули воздушное судно, в ходе которого:</h6><p></p>

<p style="text-indent:2em;"></p><h6>а) какое-либо лицо получает серьезное телесное повреждение или телесные повреждения со смертельным исходом в результате:</h6><p></p>

<p>- нахождения на борту воздушного судна;</p><br>

<p style="text-indent:2em;">- непосредственного соприкосновения с какой-либо частью воздушного судна, включая части, отделившиеся от данного воздушного судна;</p><br>

<p style="text-indent:2em;">- прямого воздействия струи газов реактивного двигателя, за исключением случаев, когда телесные повреждения получены в результате естественных причин
, нанесены самому себе либо нанесены другими лицами или когда телесные повреждения нанесены безбилетным пассажирам, скрывающимся в зонах, недоступных для пассажиров и членов экипажа;</p>

<p style="text-indent:2em;"></p><h6>b) воздушное судно получает повреждения или происходит разрушение его конструкций, в результате чего:</h6><p></p>

<p style="text-indent:2em;">- нарушается прочность конструкции, ухудшаются технические или летные характеристики; и - требуется капитальный ремонт или замена поврежденного элемента,
 за исключением случаев повреждения или отказа двигателя, если поврежден только сам двигатель, капот или вспомогательные агрегаты, или повреждены только воздушные винты, законцовки
 крыла, антенны, пневматики, тормозные устройства, обтекатели, или в обшивке имеются небольшие вмятины либо пробоины;</p><br>

<p>с) воздушное судно пропадает без вести или доступ к нему невозможен;</p><br>

<p style="text-indent:2em;">аэродром - определенный участок земной (водной) поверхности, включая здания, сооружения и оборудование, предназначенный полностью или частично для взлета,
 посадки, руления и стоянки воздушных судов;</p><br>

<p style="text-indent:2em;">аэропорт - комплекс сооружений, включающий в себя аэродром, аэровокзал и другие сооружения, предназначенный для приема и отправки воздушных судов, обслуживания воздушных перевозок;</p><br>

<p style="text-indent:2em;">воздушное судно - любой аппарат, поддерживаемый в атмосфере за счет взаимодействия с воздухом, исключая взаимодействие с воздухом, отраженным от земной поверхности;</p><br>

<p style="text-indent:2em;">международный аэропорт - любой аэропорт на территории Республики Молдова, определенный компетентным органом власти в качестве аэропорта прибытия и вылета воздушных судов,
 выполняющих международные воздушные перевозки, в котором осуществляются пограничные, таможенные, фитосанитарные, ветеринарные формальности, формальности, связанные со здоровьем населения,
 а также любые другие подобные процедуры;</p><br>

<p style="text-indent:2em;">авиационный агент - любое юридическое лицо, сертифицированное для осуществления деятельности в области гражданской авиации;</p><br>

<p style="text-indent:2em;">разрешение эксплуатанта - документ, разрешающий эксплуатанту выполнять специальные авиационные работы или полеты воздушных судов авиации общего назначения;</p><br>

<p style="text-indent:2em;">багаж - имущество, принадлежащее пассажирам, перевозимое на борту воздушного судна по соглашению с его эксплуатантом;</p><br>

<p style="text-indent:2em;">сертификат летной годности - документ, удостоверяющий после инспекции, ревизии, ремонта, модификации или монтажа, что воздушное
 судно или его составные части соответствуют действующим нормам летной годности;</p><br>

<p style="text-indent:2em;">сертификат эксплуатанта - документ, разрешающий эксплуатанту выполнять коммерческие воздушные перевозки;</p><br>

<p style="text-indent:2em;">инцидент - любое событие, кроме авиационного происшествия, связанное с использованием воздушного судна, которое влияет или могло бы повлиять на безопасность его эксплуатации;</p><br>

<p style="text-indent:2em;">аэронавигационная информация - информация, касающаяся вопросов безопасности полетов или относящаяся к аэронавигационным, техническим, административным или юридическим вопросам;</p><br>

<p style="text-indent:2em;">груз - любое перевозимое на борту воздушного судна имущество, за исключением почты, бортприпасов и сопровождаемого или неправильно засланного багажа;</p><br>

<p style="text-indent:2em;">эксплуатант - физическое или юридическое лицо, занимающееся эксплуатацией воздушных судов или предлагающее услуги в этой области;</p><br>

<p style="text-indent:2em;">эксплуатант аэродрома (аэропорта) - юридическое или физическое лицо, осуществляющее эксплуатацию аэродрома (аэропорта) на основании сертификата аэродрома (аэропорта);</p><br>

<p style="text-indent:2em;">авиационный персонал - летный экипаж, в том числе бортпроводники, а также наземный персонал, технический персонал, персонал по контролю и надзору;</p><br>

<p style="text-indent:2em;">план полета - определенные сведения о намеченном полете или части полета, представляемые органам обслуживания воздушного движения;</p><br>

<p style="text-indent:2em;">авиационные правила - специальные правила, содержащие стандарты, требования, нормы и процедуры обязательного характера в области гражданской авиации, изданные органом публичного
 управления в области гражданской авиации;</p><br>

<p style="text-indent:2em;">авиационная безопасность - комплекс мер, а также людские и материальные ресурсы, предназначенные для защиты гражданской авиации от актов незаконного вмешательства;</p><br>

<p style="text-indent:2em;">воздушное сообщение - любое регулярное воздушное сообщение, осуществляемое воздушными судами с целью общественных перевозок пассажиров, почты или груза;</p><br>

<p>воздушное пространство Республики Молдова - воздушное пространство над территорией Республики Молдова;</p><br>

<p style="text-indent:2em;">воздушное движение - воздушные суда, находящиеся в полете или движущиеся по площади маневрирования аэродрома;</p><br>

<p style="text-indent:2em;">запретная зона - воздушное пространство установленных размеров, в пределах которого полеты воздушных судов запрещены;</p><br>

<p style="text-indent:2em;">зона ограничения полетов - воздушное пространство установленных размеров, в пределах которого полеты воздушных судов ограничены определенными условиями.</p>

<p></p><h4>Глава II</h4><p></p>

<p></p><h4>ГОСУДАРСТВЕННАЯ АДМИНИСТРАЦИЯ ГРАЖДАНСКОЙ АВИАЦИИ</h4><p></p>

<p style="font-weight:500;">Статья 4. Публичное управление в области гражданской авиации</p><br>

<p style="text-indent:2em;">(1) Государственная администрация гражданской авиации Республики Молдова (далее - Администрация) является центральным отраслевым органом публичного управления,
 созданным Правительством в целях осуществления руководства, координации и контроля в области гражданской авиации.</p><br>

<p style="text-indent:2em;">(2) Генеральный директор, первый заместитель генерального директора и заместитель генерального директора Администрации назначаются на должность Правительством.</p><br>

<p style="text-indent:2em;">(3) Администрация принимает решения административного характера и утверждает положения, правила, инструкции и другие нормативные акты, обязательные к исполнению
 физическими и юридическими лицами, осуществляющими деятельность в области гражданской авиации. Статья 5. Основные направления деятельности</p>

<p style="text-indent:2em;"></p><h6>(1) Администрация ответственна за:</h6><p></p>

<p>а) подготовку и применение международных соглашений в области гражданской авиации;</p><br>

<p style="text-indent:2em;">b) представление Республики Молдова в международных организациях гражданской авиации и совместно с Министерством иностранных дел - сотрудничество с другими
 государствами в области гражданской авиации;</p><br>

<p>с) выдачу разрешений на выполнение регулярных и нерегулярных полетов;</p><br>

<p style="text-indent:2em;">d) определение и назначение органов, ответственных за обслуживание воздушного движения, авиационную безопасность, аэронавигационную информацию, метеорологическое
 и медицинское обеспечение гражданской авиации в соответствии с действующим законодательством; е) определение запретных зон и зон ограничения полетов в воздушном
 пространстве Республики Молдова;</p><br>

<p style="text-indent:2em;">f) координацию с Министерством обороны полетов гражданских и военных воздушных судов в воздушном пространстве Республики Молдова в целях обеспечения безопасности
 гражданской аэронавигации;</p><br>

<p>g) координацию деятельности по обеспечению аэронавигационной информацией;</p><br>

<p style="text-indent:2em;">h) сбор и оценку статистических и других данных, которые согласно действующему законодательству должны представляться физическими и юридическими лицами,
 осуществляющими деятельность в области гражданской авиации;</p><br>

<p>i) разработку, координацию и применение национальной программы авиационной безопасности;</p><br>

<p>j) надзор за обеспечением безопасности полетов гражданских воздушных судов;</p><br>

<p>k) создание и ведение Авиационного реестра Республики Молдова;</p><br>

<p style="text-indent:2em;">l) проверку, выдачу, подтверждение, приостановление действия и изъятие сертификатов летной годности воздушных судов и другой авиационной техники;</p><br>

<p style="text-indent:2em;">m) выдачу, признание, приостановление действия и аннулирование свидетельств авиационного персонала, сертификатов (разрешений)эксплуатанта, сертификатов (разрешений)
 авиационного агента, разрешений на эксплуатацию воздушной линии, документов о сертификации технических средств, используемых в гражданской авиации, других документов, выдаваемых Администрацией;</p><br>

<p style="text-indent:2em;">№) сертификацию и надзор за эксплуатацией аэродромов (аэропортов) и других наземных служб и оборудования;</p><br>

<p style="text-indent:2em;">о) проведение расследований авиационных происшествий и инцидентов, случившихся на территории Республики Молдова, и участие в таких расследованиях в 
других государствах, если речь идет о воздушном судне, зарегистрированном в Республике Молдова;</p><br>

<p>p) обеспечение надзора за управлением государственным имуществом в области гражданской авиации;</p><br>

<p style="text-indent:2em;">r) издание обязательных для исполнения правил сертификации (освидетельствования) эксплуатантов, авиационных агентов, авиационного персонала,
 технических средств, используемых в области гражданской авиации, и установление условий выдачи, приостановления действия или аннулирования документов о сертификации (освидетельствовании);</p><br>

<p style="text-indent:2em;">s) назначение менеджеров-руководителей и утверждение состава административных советов государственных авиапредприятий;</p><br>

<p>t) аттестацию менеджеров-руководителей государственных авиапредприятий;</p><br>

<p>u) осуществление других функций, определенных Правительством в соответствии с законодательством.</p><br>

<p style="text-indent:2em;">(2) Все физические и юридические лица, осуществляющие деятельность в области гражданской авиации на территории Республики Молдова,
 обязаны представлять Администрации любую необходимую информацию и документацию, а также обеспечивать должностным лицам Администрации доступ к любой информации,
 документации, оборудованию, местам и зонам, находящимся под их юрисдикцией или контролем, в соответствии с действующим законодательством.</p><br>

<p style="font-weight:500;">Статья 6. Обжалование решений Администрации</p><br>

<p style="text-indent:2em;">Решения Администрации обжалуются в установленном законодательством порядке. Подача жалобы не приостанавливает исполнение решения, которое обжалуется,
 если это решение связано с обеспечением безопасности полетов или авиационной безопасностью.</p>

<p></p><h4>Глава III</h4><p></p>

<p></p><h4>ВОЗДУШНОЕ СУДНО</h4><p></p>

<p style="font-weight:500;">Статья 7. Авиационный реестр</p><br>

<p style="text-indent:2em;">(1) Авиационный реестр Республики Молдова является государственным административным документом, в котором регистрируются гражданские воздушные суда,
 находящиеся в собственности или в аренде и эксплуатируемые физическими или юридическими лицами Республики Молдова.</p>

<p style="text-indent:2em;"></p><h6>(2) Гражданское воздушное судно может быть зарегистрировано в Республике Молдова только в случае, если:</h6><p></p>

<p style="text-indent:2em;">а) соответствующий сертификат типа воздушного судна или другой аналогичный документ был выдан или признан действительным Администрацией; и b)
 это воздушное судно не зарегистрировано в другом государстве.</p><br>

<p style="text-indent:2em;">(3) Посредством регистрации воздушное судно приобретает национальную принадлежность Республике Молдова. Администрация выдает свидетельство о регистрации,
 где указываются национальные и регистрационные знаки, которые должны быть нанесены на воздушное судно в соответствии с международными стандартами.</p><br>

<p style="text-indent:2em;">(4) Детальные положения о представлении заявок на регистрацию воздушного судна и о ведении Авиационного реестра Республики Молдова
 содержатся в изданных Администрацией правилах.</p><br>

<p style="font-weight:500;">Статья 8. Летная годность</p><br>

<p style="text-indent:2em;">(1) По заявке эксплуатанта воздушного судна Администрация на основе экспертизы и проверок решает, соответствует ли воздушное судно действующим нормам летной
 годности, и выдает сертификат летной годности.</p><br>

<p style="text-indent:2em;">Администрация может признать действительными сертификаты летной годности, выданные государством регистрации воздушных судов, если требования, на основе которых
 эти сертификаты были выданы, соответствуют международным авиационным стандартам, признанным Республикой Молдова.</p><br>

<p style="text-indent:2em;">(2) Расходы, связанные с экспертизой, проверками и выдачей сертификатов летной годности воздушного судна или его составных частей, несет заявитель.</p><br>

<p style="text-indent:2em;">(3) Все воздушные суда, зарегистрированные в Республике Молдова, подвергаются регулярной проверке на летную годность с интервалами и по условиям, определенным
 разработчиком воздушного судна или Администрацией. Если такая проверка выявляет, что воздушное судно или его составные части не соответствуют действующим нормам
 летной годности, Администрация может приостановить действие или изъять сертификат летной годности.</p><br>

<p style="text-indent:2em;">(4) Воздушное судно, зарегистрированное в другом государстве, может использоваться эксплуатантом Республики Молдова только в случае, если сертификат летной годности,
 выданный государством регистрации воздушного судна, был признан действительным Администрацией.</p><br>

<p style="text-indent:2em;">(5) Администрация принимает решения о летной годности воздушных судов и их составных частей исключительно в целях обеспечения высокой степени безопасности полетов
 и охраны окружающей среды.</p><br>

<p style="text-indent:2em;">(6) Любое радиооборудование может устанавливаться на борту воздушного судна и эксплуатироваться членами экипажа, если на него выдано специальное разрешение
 государством регистрации воздушного судна.</p>

<p></p><h4>Глава IV</h4><p></p>

<p></p><h4>АВИАЦИОННЫЙ ПЕРСОНАЛ</h4><p></p>

<p style="font-weight:500;">Статья 9. Свидетельства авиационного персонала</p><br>

<p style="text-indent:2em;">(1) Авиационный персонал состоит из физических лиц с удовлетворительным состоянием здоровья, имеющих специальную подготовку, подтвержденную свидетельствами,
 выданными Администрацией в соответствии с действующими правилами.</p><br>

<p style="text-indent:2em;">(2) Администрация может признать действительными свидетельства, выданные другими государствами, при условии, что требования, на основе которых такие свидетельства были выданы,
 соответствуют международным авиационным стандартам, признанным Республикой Молдова.</p><br>

<p style="text-indent:2em;">(3) Медицинский осмотр претендентов на получение свидетельств может проводиться только медицинским учреждением, назначенным Администрацией в
 соответствии с действующим законодательством.</p><br>

<p style="font-weight:500;">Статья 10. Командир воздушного судна</p><br>

<p style="text-indent:2em;">(1) Эксплуатант воздушного судна назначает для каждого отдельного полета командира воздушного судна из числа опытных пилотов, имеющих соответствующую подготовку
 и выданное Администрацией свидетельство на управление воздушным судном определенного типа.</p><br>

<p style="text-indent:2em;">(2) Командир воздушного судна несет ответственность за принятие окончательных решений на всех этапах полета воздушного судна, а также за поддержание дисциплины и
 порядка на борту судна. Члены экипажа и пассажиры обязаны следовать его указаниям.</p>

<p></p><h4>Глава V</h4><p></p>

<p></p><h4>АЭРОДРОМЫ (АЭРОПОРТЫ)</h4><p></p>

<p style="font-weight:500;">Статья 11. Сертификация и управление эксплуатацией аэродромов (аэропортов)</p><br>

<p style="text-indent:2em;">(1) Все аэродромы (аэропорты) на территории Республики Молдова, открытые для эксплуатации гражданских воздушных судов, должны быть сертифицированы Администрацией
 на предмет соответствия действующим эксплуатационным требованиям и требованиям безопасности.</p><br>

<p style="text-indent:2em;">(2) Администрация осуществляет регистрацию гражданских аэродромов (аэропортов), расположенных на территории Республики Молдова, и определяет, является аэродром (аэропорт) частным или открытым
 для общественного пользования, обслуживает внутренние или международные полеты и какие типы воздушных судов могут пользоваться этим аэродромом (аэропортом). Администрация ограничивает, 
 приостанавливает или аннулирует регистрацию аэродрома (аэропорта), если обнаруживает его несоответствие действующим эксплуатационным требованиям и требованиям безопасности.</p><br>

<p style="text-indent:2em;">(3) Эксплуатант аэродрома (аэропорта) обеспечивает безопасные посадку и взлет воздушных судов, их охрану и обслуживание, общественный порядок и авиационную безопасность на аэродроме (в аэропорту)
, а также наличие пожарной службы, службы скорой помощи и других служб.</p><br>

<p style="text-indent:2em;">(4) Воздушные суда, выполняющие международные полеты на территорию или с территории Республики Молдова, если не уполномочены иным образом Администрацией или не находятся в чрезвычайных ситуациях
, должны взлетать и приземляться в международном аэропорту, где имеются соответствующие службы пограничного, таможенного, санитарного и иного контроля.</p><br>

<p style="text-indent:2em;">(5) Администрация управляет напрямую либо через назначенных ею представителей эксплуатацией гражданских государственных аэродромов (аэропортов) и осуществляет надзор за эксплуатацией всех
 гражданских аэродромов (аэропортов).</p><br>

<p style="font-weight:500;">Статья 12. Строительство и реконструкция аэродромов (аэропортов)</p><br>

<p style="text-indent:2em;">(1) Строительство новых аэродромов (аэропортов) и реконструкция существующих осуществляются только на основании разрешения, выданного Администрацией после согласования с компетентными
 органами в соответствии с действующим законодательством.</p><br>

<p style="text-indent:2em;">(2) В планах строительства и реконструкции аэродромов (аэропортов)должны учитываться безопасность полетов, авиационная безопасность и безопасность населения, охрана окружающей среды, а также потребности в инфраструктуре (дороги и другие наземные пути
 сообщения, телекоммуникации и т. п.) для обслуживания аэродрома (аэропорта) и обеспечения его безопасной и эффективной эксплуатации.</p><br>

<p style="text-indent:2em;">(3) В зоне аэродрома (аэропорта) запрещается возведение новых зданий, сооружений или объектов либо выполнение других видов работ без предварительного согласования в установленном
 Администрацией порядке.</p><br>

<p style="text-indent:2em;">(4) Собственники зданий, сооружений и других объектов, представляющих угрозу для безопасности полетов гражданских воздушных судов, обязаны размещать на указанных объектах за свой счет маркировочные
 знаки и сигнальные устройства в соответствии с правилами, изданными Администрацией.</p><br>

<p style="font-weight:500;">Статья 13. Сборы с пользователей</p><br>

<p style="text-indent:2em;">(1) Эксплуатанты воздушных судов обязаны платить эксплуатанту аэродрома (аэропорта) сборы за пользование аэропортом. Размер этих сборов утверждается Администрацией
 и должен обеспечивать покрытие всех расходов, связанных с предоставлением аэропортовых услуг.</p><br>

<p style="text-indent:2em;">(2) Воздушные суда, выполняющие поисково-спасательные работы, работы по оказанию гуманитарной помощи в случае стихийных бедствий или в чрезвычайных ситуациях,
 а также воздушные суда, терпящие бедствие, освобождаются от уплаты аэропортовых сборов. Порядок компенсации расходов, связанных с предоставлением аэропортовых услуг данным воздушным судам, определяется Правительством.</p><br>

<p style="text-indent:2em;">(3) Эксплуатант аэродрома (аэропорта) или его представитель имеет право задержать вылет любого воздушного судна, пока эксплуатант последнего не уплатит аэропортовые
 сборы и имеющиеся задолженности за оказанные услуги или не предоставит приемлемые для эксплуатанта аэродрома (аэропорта) гарантии их уплаты.</p><br>

<p style="text-indent:2em;">(4) Условия пользования гражданскими аэродромами (аэропортами) для государственных воздушных судов определяются на основе правил, установленных Правительством
 в соответствии с частью (3) статьи 1. В данных правилах определяются также условия пользования военными аэродромами для гражданских воздушных судов.</p>

<p></p><h4>Глава VI</h4><p></p>

<p></p><h4>ЛЕТНАЯ ДЕЯТЕЛЬНОСТЬ</h4><p></p>

<p style="font-weight:500;">Статья 14. Безопасность полетов</p><br>

<p style="text-indent:2em;">(1) При выполнении полета гражданским воздушным судном должна обеспечиваться безопасность воздушного судна, пассажиров и экипажа, а также безопасность людей и имущества на земле.</p><br>

<p style="text-indent:2em;">(2) Перед началом полета командир воздушного судна обязан завершить предполетную подготовку согласно действующим правилам, иметь на борту
 воздушного судна необходимую документацию и утвержденный план полета.</p><br>

<p style="text-indent:2em;">(3) Полеты в воздушном пространстве Республики Молдова, если на них нет иного разрешения органа обслуживания воздушного движения, должны
 выполняться по воздушным трассам и в соответствии с правилами полетов, установленными Администрацией.</p><br>

<p style="text-indent:2em;">(4) Любое воздушное судно, входящее в запретную зону или в зону ограничения полетов либо отклоняющееся от утвержденного маршрута, обязано
 следовать всем указаниям компетентных органов по изменению маршрута или приземлению на назначенном аэродроме (аэропорту).</p><br>

<p style="text-indent:2em;">(5) Предметы и вещества, подвергающие опасности воздушное судно, его пассажиров и экипаж или людей и имущество, находящихся на земле, могут
 быть перевезены на борту воздушного судна только по специальному разрешению Администрации.</p><br>

<p style="font-weight:500;">Статья 15. Обслуживание воздушного движения</p>

<p style="text-indent:2em;"></p><h6>(1) Обслуживание воздушного движения в воздушном пространстве Республики Молдова обеспечивается государственным предприятием, созданным
 Администрацией, и имеет целью:</h6><p></p>

<p>а) предотвращение столкновений между воздушными судами;</p><br>

<p style="text-indent:2em;">b) предотвращение столкновений воздушных судов, находящихся на площади маневрирования, с препятствиями на этой площади;</p><br>

<p>с) ускорение воздушного движения и управление им;</p><br>

<p style="text-indent:2em;">d) предоставление консультаций и информации, необходимых для обеспечения безопасного и эффективного выполнения полетов;</p><br>

<p style="text-indent:2em;">е) уведомление соответствующих органов о воздушных судах, нуждающихся в помощи, и оказание таким органам необходимого содействия.</p><br>

<p style="text-indent:2em;">(2) Все воздушные суда, пролетающие над территорией Республики Молдова, обязаны уплачивать сборы за пользование аэронавигационными средствами,
 включая радио- и метеообеспечение. Размер этих сборов утверждается Администрацией и должен обеспечивать покрытие всех расходов, связанных с предоставлением данных услуг.</p><br>

<p style="text-indent:2em;">(3) Предприятие, обеспечивающее обслуживание воздушного движения в воздушном пространстве Республики Молдова, вправе запретить вылет любого воздушного
 судна до уплаты его эксплуатантом аэронавигационных сборов и погашения имеющихся задолженностей за оказанные услуги.</p><br>

<p style="font-weight:500;">Статья 16. Авиационные происшествия, инциденты, поиск и спасание</p><br>

<p style="text-indent:2em;">(1) Администрация координирует все поисково-спасательные работы при авиационных происшествиях и инцидентах на территории Республики Молдова.</p><br>

<p style="text-indent:2em;">Все физические и юридические лица Республики Молдова обязаны оказывать помощь при поисково-спасательных работах в целях спасения человеческих жизней и имущества, предотвращения
 увеличения ущерба и сохранения доказательств для соответствующего расследования авиационных происшествий и инцидентов.</p><br>

<p style="text-indent:2em;">(2) При авиационном происшествии или инциденте с гражданским воздушным судном на территории Республики Молдова Администрация назначает расследование его обстоятельств. Расследование
 имеет целью установление причин авиационного происшествия или инцидента и принятие необходимых мер по предотвращению подобных происшествий и инцидентов в будущем. Заключительный
 доклад по результатам расследования утверждается генеральным директором Администрации.</p><br>

<p style="text-indent:2em;">(3) В случае авиационного происшествия или инцидента с иностранным воздушным судном на территории Республики Молдова государству, где воздушное судно зарегистрировано, предоставляется
 возможность назначить наблюдателей, которые будут присутствовать при расследовании. Все результаты расследования сообщаются этому государству. В случае авиационного происшествия или
 инцидента на территории другого государства с гражданским воздушным судном, зарегистрированным в Республике Молдова, Администрация делает запрос о своем участии в качестве наблюдателя
 в расследовании, проводимом соответствующим государством.</p>

<p></p><h4>Глава VII</h4><p></p>

<p></p><h4>ВОЗДУШНЫЕ ПЕРЕВОЗКИ</h4><p></p>

<p style="font-weight:500;">Статья 17. Сертификация и освидетельствование эксплуатантов.</p><br>

<p style="text-indent:2em;">Разрешение на выполнение полетов (1) Коммерческие авиаперевозки пассажиров, багажа, груза и почты осуществляются только эксплуатантами, имеющими действительный сертификат эксплуатанта 
и разрешение на эксплуатацию воздушной линии или разрешение на полет. Коммерческие авиаперевозки осуществляются регулярными или нерегулярными рейсами.</p><br>

<p style="text-indent:2em;">(2) Специальные авиационные работы осуществляются эксплуатантами для нужд промышленности, сельского и лесного хозяйства, здравоохранения, защиты окружающей среды, поиска и спасения,
 научных исследований, аэрофотосъемки, мониторинга, рекламы, а также для других целей только на основании разрешения эксплуатанта, выданного Администрацией.</p><br>

<p style="text-indent:2em;">(3) К полетам воздушных судов авиации общего назначения относятся полеты, не входящие в категории, предусмотренные частями (1) и (2), являющиеся полетами, осуществляемыми в личных интересах,
 частными, прогулочными, тренировочными, учебными и спортивными.</p><br>

<p style="text-indent:2em;">(4) Сертификат (разрешение) эксплуатанта выдается Администрацией на основании доказательства претендентом того, что он выполнил все требования и условия, установленные действующими авиационными
 правилами, изданными Администрацией, и соответствует этим требованиям и условиям.</p><br>

<p style="text-indent:2em;">(5) Разрешение на эксплуатацию воздушной линии или разрешение на полет выдается эксплуатанту, сертифицированному в соответствии с положениями части (4). Условия выдачи указанных разрешений
 устанавливаются авиационными правилами, изданными Администрацией.</p><br>

<p style="text-indent:2em;">(6) Администрация может приостановить действие или аннулировать разрешение на эксплуатацию воздушной линии в случае, если эксплуатант не соблюдает требований, на основании которых оно было 
выдано, а также в случае, если эксплуатант имеет просроченные задолженности за аэронавигационные и/или аэропортовые услуги, а также долги перед другими авиационными агентами, осуществляющими
 деятельность на рынке авиационных услуг.</p><br>

<p style="font-weight:500;">Статья 18. Международная воздушная перевозка</p><br>

<p style="text-indent:2em;">(1) При соблюдении положений статьи 17 эксплуатант может осуществлять международную воздушную перевозку на основании разрешения, выданного компетентными властями иностранного государства (государств),
 в воздушном пространстве которого (которых) будет выполняться перевозка.</p>

<p style="text-indent:2em;"></p><h6>(2) Иностранный эксплуатант может осуществлять международную воздушную перевозку на территорию или с территории Республики Молдова на основании разрешения, выданного Администрацией в соответствии с
 международным соглашением, одной из сторон которого является Республика Молдова, или в общественных интересах, при условии, что иностранный эксплуатант:</h6><p></p>

<p>а) имеет действительный сертификат эксплуатанта;</p><br>

<p>b) имеет действительный сертификат летной годности воздушного судна;</p><br>

<p style="text-indent:2em;">с) застрахован в установленном порядке на случай возможного причинения ущерба при осуществлении воздушных перевозок;</p><br>

<p>d) имеет адекватную программу авиационной безопасности.</p><br>

<p style="text-indent:2em;">(3) Администрация может приостановить действие или изъять разрешение, выданное иностранному эксплуатанту, если последний не соблюдает требований, на основе которых оно было выдано.</p><br>

<p style="font-weight:500;">Статья 19. Дополнительные условия регулярных воздушных перевозок</p><br>

<p style="text-indent:2em;">Регулярные воздушные перевозки осуществляются при условии применения тарифов и расписаний полетов, утвержденных Администрацией.</p><br>

<p style="font-weight:500;">Статья 20. Упрощение формальностей</p><br>

<p style="text-indent:2em;">(1) В целях содействия и ускорения воздушных перевозок, а также предотвращения необоснованных задержек воздушных судов, экипажей, пассажиров и груза Администрация
 по согласованию с другими компетентными органами разрабатывает специальные правила, направленные на упрощение формальностей, связанных с пограничным, 
 таможенным, санитарным контролем, и других.</p><br>

<p style="text-indent:2em;">(2) Меры по упрощению формальностей, связанных с воздушной перевозкой, не должны осуществляться в ущерб мерам по поддержанию авиационной безопасности
 и недопущению ввоза или вывоза запрещенных товаров.</p>

<p></p><h4>Глава VIII</h4><p></p>

<p></p><h4>ДРУГАЯ ДЕЯТЕЛЬНОСТЬ В ОБЛАСТИ ГРАЖДАНСКОЙ АВИАЦИИ</h4><p></p>

<p style="font-weight:500;">Статья 21. Другая деятельность в области гражданской авиации</p><br>

<p style="text-indent:2em;">(1) Другая деятельность в области гражданской авиации осуществляется только на основании сертификата (разрешения), выданного Администрацией.</p>

<p style="text-indent:2em;"></p><h6>(2) Положение части (1) распространяется на:</h6><p></p>

<p style="text-indent:2em;">а) любую деятельность по наземному обслуживанию воздушных судов, в том числе связанную с обслуживанием пассажиров, багажа, грузов и почты, заправкой воздушных судов топливом,
 обеспечением питанием на борту воздушных судов, и другую подобную деятельность;</p><br>

<p>b) деятельность по обучению и подготовке авиационного персонала;</p><br>

<p>с) иную деятельность, регламентируемую правилами, изданными Администрацией.</p><br>

<p style="text-indent:2em;">(3) Администрация издает авиационные правила для сертификации (освидетельствования) деятельности, предусмотренной частью (2).</p>

<p></p><h4>Глава IX</h4><p></p>

<p></p><h4>АВИАЦИОННАЯ БЕЗОПАСНОСТЬ</h4><p></p>

<p style="font-weight:500;">Статья 22. Авиационная безопасность</p><br>

<p style="text-indent:2em;">(1) В целях защиты гражданской авиации от актов незаконного вмешательства Администрация по согласованию с другими компетентными органами разрабатывает национальную программу
 авиационной безопасности и представляет ее на утверждение Правительству.</p><br>

<p style="text-indent:2em;">(2) Физические и юридические лица, осуществляющие деятельность в области гражданской авиации на территории Республики Молдова, в соответствии с действующим законодательством
 разрабатывают и представляют на утверждение Администрации программы авиационной безопасности, указывая меры по предупреждению и предотвращению актов незаконного вмешательства,
 необходимый персонал и его подготовку.</p><br>

<p style="text-indent:2em;">(3) В целях обеспечения авиационной безопасности воздушное судно, включая его бортовые запасы, груз и почту, а также члены экипажа, пассажиры, их багаж подвергаются в аэропорту
 досмотру перед посадкой или погрузкой в воздушное судно. Эксплуатант аэродрома (аэропорта) или воздушного судна может отстранить от воздушной перевозки любое лицо, отказывающееся
 пройти досмотр либо имеющее при себе предметы или вещества, которые представляют угрозу авиационной безопасности.</p>

<p></p><h4>Глава X</h4><p></p>

<p></p><h4>ПРАВОНАРУШЕНИЕ И ОТВЕТСТВЕНОСТЬ</h4><p></p>

<p style="font-weight:500;">Статья 23. Правонарушения</p>

<p style="text-indent:2em;"></p><h6>(1) Правонарушениями в области гражданской авиации являются:</h6><p></p>

<p>а) отсутствие на борту воздушного судна документов, предусмотренных действующими правилами;</p><br>

<p style="text-indent:2em;">b) нарушение правил, касающихся условий эксплуатации авиационной техники, в том числе нарушение первоначальных условий
 сертификации (освидетельствования) и эксплуатационных стандартов, установленных национальными авиационными правилами;</p><br>

<p>с) нарушение правил, касающихся условий наземного обслуживания воздушных судов;</p><br>

<p style="text-indent:2em;">d) выполнение авиационным персоналом своих обязанностей без соответствующего свидетельства либо при наличии свидетельства
 с истекшим сроком действия или не признанного Администрацией. Это положение распространяется на случаи выполнения обязанностей при отсутствии
 соответствующих квалификационных допусков и/или медицинского сертификата;</p><br>

<p style="text-indent:2em;">е) неосуществление записей, касающихся учета рабочего времени, времени полета и времени отдыха членов экипажа;</p><br>

<p>f) перевозка опасных грузов без специального разрешения, выданного Администрацией;</p><br>

<p style="text-indent:2em;">g) нарушение норм максимально допустимой взлетной массы и центровки воздушного судна, рассчитанных для определенных
 условий;</p><br>

<p style="text-indent:2em;">h) отказ предоставлять лицам, уполномоченным Администрацией, документы, связанные с эксплуатируемыми воздушными судами,
 а также воспрепятствование инспектированию организации;</p><br>

<p style="text-indent:2em;">i) эксплуатация воздушного судна без полисов страхования пассажиров, багажа, экипажа и гражданской ответственности перед третьими лицами;</p><br>

<p style="text-indent:2em;">j) непредставление Администрации информации об инцидентах и авиационных происшествиях.</p><br>

<p style="text-indent:2em;">(2) Нарушения, предусмотренные частью (1), наказываются штрафом, размер которого устанавливается Кодексом об административных правонарушениях.</p><br>

<p style="text-indent:2em;">(3) Применение санкций за правонарушения, указанные в части (1), не освобождает от обязанности устранения выявленных нарушений.
 Они должны быть устранены в срок до одного месяца, если иное не установлено Администрацией, с обязательным уведомлением Администрации об их устранении.</p><br>

<p style="text-indent:2em;">(4) В случае, если нарушения не были устранены в указанный срок и продолжают иметь место или если они носят систематический характер,
 Администрация вправе принять решение о приостановлении действия или аннулировании документа о сертификации (освидетельствовании).</p><br>

<p style="font-weight:500;">Статья 24. Ответственность</p><br>

<p style="text-indent:2em;">(1) Ответственность за совершение актов незаконного вмешательства в гражданскую авиацию устанавливается согласно действующему законодательству.</p><br>

<p style="text-indent:2em;">(2) Ответственность за ущерб, причиненный при осуществлении воздушных перевозок в воздушном пространстве Республики Молдова,
 устанавливается согласно действующему законодательству.</p><br>

<p style="text-indent:2em;">(3) Физические и юридические лица, деятельность которых связана с использованием воздушного пространства Республики Молдова,
 проектированием, изготовлением, ремонтом и эксплуатацией авиационной техники и аэродромов (аэропортов), обслуживанием воздушного движения, обеспечением
 безопасности полетов и авиационной безопасности, осуществлением другой деятельности в области гражданской авиации на территории Республики Молдова, а
 также управлением и надзором в этой области, несут предусмотренную действующим законодательством ответственность за нарушение положений настоящего закона.</p>

<p></p><h4>Глава XI</h4><p></p>

<p></p><h4>ЗАКЛЮЧИТЕЛЬНЫЕ И ПЕРЕХОДНЫЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 25</p><br>

<p style="text-indent:2em;">Если международным договором, одной из сторон которого является Республика Молдова, установлены иные положения, чем те,
 которые содержатся в настоящем законе, применяются положения международного договора.</p><br>

<p style="font-weight:500;">Статья 26</p><br>

<p style="text-indent:2em;">Настоящий закон вступает в силу со дня опубликования.</p><br>

<p style="font-weight:500;">Статья 27</p><br>

<p style="text-indent:2em;">До приведения законодательства в соответствие с настоящим законом применяются только те положения нормативных актов, которые не противоречат ему.</p><br>

<p style="font-weight:500;">Статья 28</p>

<p style="text-indent:2em;"></p><h6>Правительству в трехмесячный срок:</h6><p></p>

<p style="text-indent:2em;">- представить Парламенту предложения по приведению действующего законодательства в соответствие с настоящим законом;</p><br>

<p>- привести свои нормативные акты в соответствие с настоящим законом;</p><br>

<p style="text-indent:2em;">- обеспечить изменение или отмену министерствами и департаментами нормативных актов, противоречащих настоящему закону;</p><br>

<p>- разработать и утвердить на основе настоящего закона подзаконные нормативные акты.</p><br>

<p><a href="https://www.legis.md/cautare/getResults?doc_id=109159&amp;lang=ru">Источник</a></p>


</div>
`