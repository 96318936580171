import React, { Component, Fragment, CSSProperties } from 'react'
import _ from 'lodash'
// import { connect } from 'react-redux'
import { Popover, PopoverHeader, PopoverBody, PopoverProps } from 'reactstrap'
import uuid from 'uuid/v4'
import classNames from 'classnames'
import './styles.scss'

interface WrapperProps {
  title?: string
  text: any
  placement?: PopoverProps['placement']
  hover?: boolean
  focus?: boolean
  isOpen?: boolean
  hasTooltipSign?: boolean
  component?: any
  style?: CSSProperties
  innerStyle?: CSSProperties
  disabled?: boolean
  innerClassName?: string
  parentCustomClass?: string
  clickOnUrl?: boolean
}

interface WrapperState {
  isOpen: boolean
}

class ComponentWrapper extends Component<WrapperProps, WrapperState> {
  state = {
    isOpen: false,
  }
  id = `id${uuid()}`

  componentDidMount() {
    const { isOpen } = this.props
    if (isOpen) {
      this.setState({ isOpen })
    }
  }

  componentDidUpdate() {
    const { hover, focus } = this.props
    if (this.state.isOpen && !hover && !focus) {
      document.addEventListener('click', this.toggle as any)
    }
  }

  checkElIsPopup = (el, action) => {
    if (!el) {
      return action()
    }

    if (el.id !== `popover-${this.id}`) {
      if (el.tagName !== 'BODY') {
        this.checkElIsPopup(el.parentElement, action)
      } else {
        action()
      }
    }
  }

  toggle = (e: React.MouseEvent) => {
    document.removeEventListener('click', this.toggle as any)

    if (this.state.isOpen) {
      e.target && this.checkElIsPopup(e.target, () => this.setState({ isOpen: false })) // to prevent closing popup if click was performed inside it
    } else {
      this.setState({ isOpen: true })
    }

    return true
  }

  handlePopoverClick = e => {
    const { clickOnUrl = false } = this.props
    e.persist()

    if (e.target.tagName === 'LABEL') {
      document.getElementById(e.target.htmlFor).click()
    }

    !clickOnUrl && e.stopPropagation()
    !clickOnUrl && e.preventDefault()
  }

  render() {
    const {
      title,
      text,
      placement = 'top',
      hover,
      focus,
      hasTooltipSign,
      component: Component = 'span',
      style,
      innerStyle,
      disabled,
      innerClassName,
      parentCustomClass,
    } = this.props
    const { isOpen } = this.state

    return (
      <Fragment>
        <Component className={hasTooltipSign ? 'has-tooltip' : null} style={style} id={this.id}>
          {this.props.children}
        </Component>

        {!disabled && (
          <Popover
            trigger={(hover && 'hover') || (focus && 'focus')}
            placement={placement}
            isOpen={isOpen}
            target={this.id}
            toggle={this.toggle}
            id={`popover-${this.id}`}
            hideArrow
            className={parentCustomClass}
          >
            {title && <PopoverHeader>{title}</PopoverHeader>}
            <PopoverBody
              className={classNames(`${innerClassName} popover-styles`)}
              style={{ ...innerStyle }}
              onClick={this.handlePopoverClick}
              onMouseLeave={() => isOpen && hover && this.setState({ isOpen: false })}
            >
              {text}
            </PopoverBody>
          </Popover>
        )}
      </Fragment>
    )
  }
}

export default ComponentWrapper
