import React, { Fragment } from 'react'
import { IProps } from './types'
import { cloudDownload } from 'react-icons-kit/fa/cloudDownload'
import Icon from 'react-icons-kit'
import Widget from '../../../shared/components/widget'
import PhonesRow from './phones-row'
import TooltipWrapper from '../../../UI/popover-wrapper'
import { Link } from 'react-router-dom'
import config from '../../../../config'
import { dateTimeFormatter, directorId } from "../../../shared/utils"
import dict from '../../../shared/dictionaries'

const renderBussinesContent = arr => {
  const content = arr.map((line, i) => {
    return (
      <div key={i} className="one-buss-type">
        {line.businessName}
      </div>
    )
  })
  return content
}

const SideWidget: React.SFC<IProps> = ({
  t,
  title,
  langId,
  widgetType,
  companyInfo,
  businessTypesContent,
  showCertificateModal,
  employees,
  companyInfo: {
    yearCreated,
    fiscalCode,
    businessTypeName,
    businessTypesId,
    adminUserName,
    adminUserLastName,
    adminUserId,
    zipCode,
    countryName,
    city,
    address,
    phone1,
    phone2,
    fax1,
    fax2,
    skype,
    viber,
    email1,
    email2,
    website,
    branches,
    ownerUsername,
    ownerLastName,
    ownershipTypeId,
    companyName,
    isClosed,
    closeDate,
    ownerUserId,
    avgGrade,
    reviewCount,
    registrationCertificate,
    registrationCertificateDoc,
    companyLicensesDocs,
    allowClaim,
    gtc
  },
}) => {
  const zipCountryCity = []
  if (zipCode) zipCountryCity.push(zipCode)
  if (countryName) zipCountryCity.push(countryName)
  if (city) zipCountryCity.push(city)

  const adminInfo = adminUserName ? `${adminUserName} ${adminUserLastName}` : t('no.data')
  const noAdminData = !adminUserName
  const folder = `company/${companyInfo.id}`


  const ownershipTypesDict = dict.ownershipTypes(langId);

  const ownership = ownershipTypesDict.items ? ownershipTypesDict.items.find(o => o.value === Number(ownershipTypeId)) : null 
  const ownType = ownership?.name

  const directorName = (employees?.list || []).find(item => item.positionInCompany === directorId)
  const directorFullName = `${directorName?.userName} ${directorName?.userLastName}`
  let documentsLicenses

  const newDocs = companyLicensesDocs.filter(doc => doc && doc.fileName)

  if (companyInfo?.companyLicenses && newDocs.length > 0) {
    documentsLicenses = newDocs.map((doc, index) => {
      const text = doc ? (
        doc?.fileName?.endsWith('.pdf') ?
          <a href={`${(config as any).apiUrl}/common/files/${doc?.uuid}`} target="_blank" rel="noopener noreferrer">
            {t('licensy')} {++index}
          </a>
          :
          <a href="#" onClick={() => showCertificateModal(`${folder}/${doc?.uuid}`)}>
            {t('licensy')} {++index}
          </a>
      ) : (
        <b>{t('not.added')}</b>
      )

      return (
        <li key={index}>
          <TooltipWrapper text={t("show")} hover>
            <span className="ico ico-download" />{text}
          </TooltipWrapper>
        </li>
      )
    })
  }else{
    documentsLicenses = (
      <li >
        <TooltipWrapper text={t("not.added")} hover>
          <span className="ico ico-download" /><b>{t('not.added')}</b>
        </TooltipWrapper>
      </li>
    )
  }

  return (
    <Widget title={title}>
      {widgetType === 'aboutCompany' &&
        (yearCreated || fiscalCode || businessTypeName || ownerUsername || ownerLastName) && (
          <Fragment>
            {isClosed > 0 &&
              <div className="panel-content-row">
                <span className="meta-gray-lg ">{t('company.status')}: <b className="company-inactive">{t('company.not.active')}</b></span>
                <span className="no-break-word">{t('company.closed.date')}: <b>{dateTimeFormatter(closeDate)}</b></span>
              </div>}
            {companyName && (
              <div className="panel-content-row">
                <span className="meta-gray-lg ">{t('company.name')}</span>
                <span className="no-break-word">{companyName}</span>
              </div>
            )}
            {yearCreated && (
              <div className="panel-content-row">
                <span className="meta-gray-lg">{t('foundation.year').toUpperCase()}</span>
                <span>{yearCreated}</span>
              </div>
            )}
            {fiscalCode && (
              <div className="panel-content-row">
                <span className="meta-gray-lg">{t('fiscal.code').toUpperCase()}</span>
                <span>{fiscalCode}</span>
              </div>
            )}

            {(registrationCertificateDoc as any)?.uuid && (
              <div className="panel-content-row">
                <span className="meta-gray-lg">{t('registration.certificate').toUpperCase()}</span>
                <span>
                  {(registrationCertificateDoc as any)?.fileName?.endsWith('.pdf') ?
                    <a href={`${(config as any).apiUrl}/common/files/${(registrationCertificateDoc as any)?.uuid}`} target="_blank" rel="noopener noreferrer">
                      <span className="show-certificate">
                        ({t('show')})
                      </span>
                    </a>
                    :
                    <span onClick={() => showCertificateModal(`${folder}/${(registrationCertificateDoc as any)?.uuid}`)} className="show-certificate">
                      ({t('show')})
                    </span>
                  }
                </span>
              </div>
            )}
            {documentsLicenses && <div className="panel-content-row company-licenses">
              <span className="meta-gray-lg">{t('registration.licenses').toUpperCase()}</span>
              <span>
                {documentsLicenses ? documentsLicenses : t('not.added')}
              </span>
            </div>}

            {businessTypesId && (
              <div className="panel-content-row">
                <span className="meta-gray-lg">{t('property.type').toUpperCase()}</span>
                <span>{ownType}</span>
              </div>
            )}


            {businessTypesContent && (
              <div className="panel-content-row">
                <span className="meta-gray-lg">{t('business.type').toUpperCase()}</span>
                <span>{renderBussinesContent(businessTypesContent)}</span>
              </div>
            )}
            {(ownerUsername || ownerLastName) && (
              <div className="panel-content-row">
                <span className="meta-gray-lg">{t('director.administrator').toUpperCase()}</span>
                <span className={`text ${noAdminData && "cursor-default"}`}>
                  {adminInfo === directorFullName || !directorName ? (
                    !noAdminData ? <Link to={`/user/details/${adminUserId}`} >
                      {`${adminInfo}`}
                    </Link> :  adminInfo
                  ) : (
                    <>
                      <Link to={`/user/details/${directorName?.userId}`} >
                        {`${directorFullName}`}
                      </Link>
                      {` `}/{` `}
                      <Link to={`/user/details/${adminUserId}`} >
                        {`${adminInfo}`}
                      </Link>
                    </>
                  )}
                </span>
              </div>
            )}
            {website && (
              <div className="panel-content-row">
                <span className="meta-gray-lg">WEBSITE</span>
                <span><a href={website} rel="noopener noreferrer nofollow" target="_blank">{website}</a></span>
              </div>
            )}
          </Fragment>
        )}
      {widgetType === 'headOffice' && (
        <Fragment>
          {(zipCode || countryName || city || address) && (
            <div className="panel-content-row">
              <span className="text">{zipCountryCity.join(',')}</span>
              <span className="text">{address}</span>
            </div>
          )}
          {(phone1 || phone2 || fax1 || fax2) && (
            <PhonesRow phone1={phone1} phone2={phone2} fax1={fax1} fax2={fax2} />
          )}
          {skype && (
            <div className="panel-content-row">
              <span className="meta-gray-lg">{t('skype').toUpperCase()}</span>
              <span>{skype}</span>
            </div>
          )}
          {viber && (
            <div className="panel-content-row">
              <span className="meta-gray-lg">{t('viber').toUpperCase()}</span>
              <span>{viber}</span>
            </div>
          )}
          {email1 && (
            <div className="panel-content-row">
              <span className="meta-gray-lg">{t('email').toUpperCase()}</span>
              <span>{email1}</span>
            </div>
          )}

        </Fragment>
      )}
      {widgetType === 'branches' && (
        <Fragment>
          {branches.map(({ zipCode, countryName, city, address, phone1, phone2, id }) => {
            const zipCountryCity = []
            if (zipCode) zipCountryCity.push(zipCode)
            if (countryName) zipCountryCity.push(countryName)
            if (city) zipCountryCity.push(city)

            return (
              <Fragment key={id}>
                <div className="panel-content-row">
                  <span className="text">{zipCountryCity.join(',')}</span>
                  <span className="text">{address}</span>
                </div>
                <PhonesRow phone1={phone1} phone2={phone2} fax1={fax1} fax2={fax2} />
              </Fragment>
            )
          })}
        </Fragment>
      )}
    </Widget>
  )
}

export default SideWidget
