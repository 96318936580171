import React, { Component } from 'react'
import classNames from 'classnames'

import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import { ModalConsumer } from '../../context/modal-context.tsx'
import './modal-overlay.scss'

export default class ModalOverlay extends Component {
  render() {
    return (
      <ModalConsumer>
        {({
          isOpen,
          modalComponent,
          clearModal,
          skipLayout,
          title,
          className,
          hideHeader,
          backdrop,
          keyboard,
        }) => {
          const modalClassName = classNames(className, {})
          return (
            <Modal
              isOpen={isOpen}
              toggle={clearModal}
              className={modalClassName}
              modalTransition={{ timeout: 300 }}
              backdropTransition={{ timeout: 0 }}
              backdrop={backdrop}
              keyboard={keyboard}
            >
              {!hideHeader && <ModalHeader toggle={clearModal}>{title}</ModalHeader>}
              {skipLayout ? modalComponent : <ModalBody>{modalComponent}</ModalBody>}
            </Modal>
          )
        }}
      </ModalConsumer>
    )
  }
}
