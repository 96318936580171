import React, { Component } from 'react'

import ImageUploadField from '../../../../../UI/image-upload-field'
import { Field } from 'redux-form'

import './styles.scss'

// const STYLE_BASE = 'car-base-data_'

export default class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      photos: [null, null, null, null, null],
    }
  }

  componentDidUpdate(prevProps) {
    const { photos, change } = this.props
    if (prevProps.photos !== photos) {
      photos && this.setState({ photos })
      photos.map((photo, id) => change(`photo[${id}]`, photo))
    }
  }

  render() {
    const { photos } = this.state
    const { t, folder } = this.props

    return (
      <div className="panel-content-row">
        <div className="panel-form-row">
          <span className="label">{t('photos-optional')}</span>
          <div className="panel-form-photo" id="photos-div">
            {photos.map((val, id) => (
              <div className="col" key={id}>
                <Field
                  name={`photo[${id}]`}
                  component={ImageUploadField}
                  className="photo-block"
                  folder={folder}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
