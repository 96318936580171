import React from 'react'
import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js/max'
import withTranslation from '../../../globals/components/withTranslation'
import PopoverWrapper from '../../UI/popover-wrapper'

interface RenderPhoneProps extends ComponentBaseProps {
  input?: any
  unit?: string
  style?: React.CSSProperties
  className?: string
  placeholder?: string
  formatError: boolean
  popover?: PopoverWrapper
  onErrorChange: (fieldName: string, status: boolean) => void
  meta?: {
    touched: boolean
    error: string
  }
  maxLength?: number
}

interface RenderPropsState {
  phone: string
}

export class RenderPhone extends React.Component<RenderPhoneProps, RenderPropsState> {
  constructor(props) {
    super(props)

    this.state = {
      phone: '',
    }
  }

  formatPhoneString = (phone: string, cb: () => void): void => {
    const {
      input: { name: fieldFullName },
      onErrorChange,
    } = this.props
    const nameSpl = fieldFullName.split('.')
    const name = nameSpl[nameSpl.length - 1]

    if (!phone) {
      this.setState({ phone: '' }, cb)
      onErrorChange(name, false)
      return
    } else if (phone === '+') {
      this.setState({ phone: '+' }, cb)
      onErrorChange(name, true)
      return
    } else if (phone.length === 1) {
      this.setState({ phone: `+${phone}` }, cb)
      onErrorChange(name, true)
      return
    }

    const ok = /^\+?[-\s0-9]+$/g.test(phone)

    if (ok) {
      const phoneNumber = parsePhoneNumberFromString(phone)

      if (phoneNumber) {
        if (phoneNumber.isValid()) {
          onErrorChange(name, false)
        } else {
          onErrorChange(name, true)
        }
      } else {
        onErrorChange(name, true)
      }

      this.setState({ phone }, cb)
    }
  }

  onValueChange = event => {
    const { value, selectionStart } = event.target

    this.formatPhoneString(value, () => {
      if (selectionStart === value.length) {
        this.beautifyPhoneString()
      }
    })
  }

  beautifyPhoneString = () => {
    const phone = new AsYouType().input(this.state.phone)
    this.setState({ phone })
    this.props.input.onChange(phone)
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props.input
    const { value: valuePrev } = prevProps.input

    // check on mount
    if (!valuePrev && value.trim()) {
      this.formatPhoneString(value, this.beautifyPhoneString)
    }
  }

  render() {
    const {
      t,
      unit,
      style,
      className,
      placeholder,
      formatError,
      popover,
      maxLength = 20,
      meta: { touched, error },
    } = this.props
    const { phone } = this.state

    return (
      <div>
        <div className="render-phone-inline">
          <input
            type="text"
            placeholder={placeholder}
            value={phone}
            maxLength={maxLength}
            style={style}
            className={className}
            onChange={this.onValueChange}
            onBlur={this.beautifyPhoneString}
          />
          {popover}
        </div>
        {unit && <span className="mark">{unit}</span>}
        {touched && error && <div className="validation-message text-danger">{error}</div>}
        {!error && formatError && (
          <div className="validation-message text-danger">{t('invalid.phone.number')}</div>
        )}
      </div>
    )
  }
}

export default withTranslation(RenderPhone)
