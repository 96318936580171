import withTranslation from '../../../../globals/components/withTranslation'
import withPermissions from '../../../../globals/components/withPermissions'
import Component from './component'
import { compose } from 'recompose'
import { AddClaimProps } from './types'

export default compose(
    withTranslation,
    withPermissions
)(Component) as React.ComponentClass<AddClaimProps>
