import React from 'react'

import config from '../../../config'
import { UserPhotoProps } from './types'

import './style.scss'

const getActualUserPhoto = (photo: string, gender: string, userId: number) => {
  if (photo) {
    return `${(config as any).apiUrl}/common/file/profile/${userId}/${photo}`
  } else {
    return `/images/default-profile/user-${gender}.svg`
  }
}

const userPhoto: React.FunctionComponent<UserPhotoProps> = props => {
  const { gender, photo, userId, customUserId } = props
  const user = customUserId || userId
  const userPhoto = getActualUserPhoto(photo, gender, user)

  return <img src={userPhoto} alt="user avatar" />
}

export default userPhoto
