import {
  cargoNotificationUnsubscribe,
  cargoAddFavourite,
  cargoDeleteFavourite,
  getUnblockedItem,
} from '../../../store/actions'

interface IDelayTimer {
  readonly incrementFunc: (timerId: number, timeRemaining: number) => void
  readonly timerEndFunc: (timerId: number) => void
  readonly timers: NodeJS.Timeout[]
}

interface IDelayTimerParams {
  timerUpdate: IDelayTimer['incrementFunc']
  timerEnd: IDelayTimer['timerEndFunc']
}

// TODO: Replace it anywhere else
export class DelayTimer implements IDelayTimer {
  incrementFunc: IDelayTimer['incrementFunc']
  timerEndFunc: IDelayTimer['timerEndFunc']
  timers: NodeJS.Timeout[] = []
  constructor({ timerUpdate, timerEnd }: IDelayTimerParams) {
    this.incrementFunc = timerUpdate
    this.timerEndFunc = timerEnd
  }

  setTimer(timerId: number, updateDate: number, timeout: number) {

    let updatedTimeOut = timeout
    if (!this.timers[timerId]) {
      this.timers[timerId] = setInterval(() => {

        if (updatedTimeOut === 0) {
          clearInterval(this.timers[timerId])
          this.timerEndFunc(timerId)
        } else {
          updatedTimeOut = updatedTimeOut - 1
          this.incrementFunc(timerId, updatedTimeOut)
        }
      }, 1000 * 60)

      this.incrementFunc(timerId, timeout)
    }
    return timeout
  }

  cancelAllTimers = () => {
    this.timers.forEach(timer => {
      clearInterval(timer)
    })
  }
}

export interface SearchTableProps extends PaginationProps, AuthBaseProps, ComponentBaseProps {
  notificationUnsubscribe?: typeof cargoNotificationUnsubscribe
  addFavourite?: typeof cargoAddFavourite
  deleteFavourite?: typeof cargoDeleteFavourite
  exactUserId?: number
  notFoundText?: string
  searchType: SearchType
  openIn: SearchOpenIn
  items: any
  processing: boolean
  refresh: any
  isMyOffers?: boolean
  bannerId?: number
  getUnblockedItem?(...rest: any[]): any
  changeActiveItem?(...rest: any[]): any
  getUserStatistics?(...rest: any[]): any
  setScrollToOncommingItems?: any
  scrollToOncommingItems?: boolean
  onCanAdd?: any
  canAdd?: boolean
  statistics?: any
  showDateIsShort?: boolean
  needToShowAdsNearPagination?: boolean
  isAuth?: boolean
  shippingType?: string
}

export interface SearchTableState {
  itemId: number
  items: SearchTableProps['items']
  timers: number[]
  redirectToFullPage: boolean,
  redirectItemId: number
  canAdd?: boolean
}
