import _ from 'lodash'
import React, { Fragment } from 'react'
import { compose } from 'recompose'
import { Provider, connect } from 'react-redux'
import { addLocaleData, IntlProvider } from 'react-intl'
import { BrowserRouter, withRouter } from 'react-router-dom'
import ReduxToastr from 'react-redux-toastr'

import en from 'react-intl/locale-data/en'
import ru from 'react-intl/locale-data/ru'
import ro from 'react-intl/locale-data/ro'

import { DEFAULT_LANG } from '../../store/constants'

import { getTimezoneById } from '../../components/shared/utils/timezone'
import { refreshStart } from '../../store/actions/auth'
import IdleDetection from '../../idle/components/IdleDetection'
import LocalStorage from '../sideEffects/localStorage'
import { actions as idleActions } from '../../idle/IdleRedux'

import RefreshToken from '../../components/auth/login/refreshToken'
import { reasonTypes } from '../../components/auth/logout-reasons'
import { SnackbarProvider } from 'notistack';
import layoutFactory from './layoutFactory'
import routerFactory from './routerFactory'

addLocaleData([...en, ...ru, ...ro])

const formatsByTimezoneId = timezoneId => {
  const formats = {}

  const timezone = getTimezoneById(timezoneId)
  if (timezone) {
    formats.date = {
      'user-timezone': {
        timeZone: timezone,
      },
      '2-digit-date-time': {
        // https://github.com/yahoo/react-intl/wiki/Components#date-formatting-components
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      },
    }
    formats.time = {
      'user-timezone': {
        timeZone: timezone,
      },
    }
  }
  return formats
}

const mapStateToProps = state => {
  let isLoggedOut = false
  let { loggedOutReason } = state.auth

  const token = localStorage.accessToken
  const jwtExp = state.auth.jwt.exp
  if (_.isNumber(jwtExp) && token) {
    const isExpired = jwtExp < Date.now() / 1000
    if (isExpired) {
      isLoggedOut = true
      loggedOutReason = reasonTypes.expiredJWT
    }
  } else {
    isLoggedOut = true
  }

  let locale = state.ui.currentLanguage
  const translation = state.ui.translation[locale] || state.ui.translation[DEFAULT_LANG] || {}

  const { timezone, accessToken, sessionId, refreshToken, clientId, drawerOpen } = state.auth

  locale = locale === 'md' ? 'ro' : locale
  const culture = locale

  return {
    // --- for IntlProvider
    culture,
    timezone,
    translation,
    locale,
    // --- for other SideEffect components
    accessToken,
    refreshToken,
    sessionId,
    clientId,
    drawerOpen,
    // --- for render()
    isLoggedOut,
    loggedOutReason,
    isAuthenticated: !isLoggedOut,
  }
}

export const appFactory = compose(
  render => ({ store }) => (
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={3}
        action={[
          <button type="button"
            key="snack-dismiss"
            className="close"
            style={{
              marginTop: -2
            }}
            aria-label="Close">
            <span aria-hidden="true" style={{ color: "#e3e3e3" }}>×</span>
          </button>
        ]}>
        <BrowserRouter>{render()}</BrowserRouter>
      </SnackbarProvider>
    </Provider>
  ),
  React.createFactory,
  withRouter,
  connect(mapStateToProps, {
    refreshStart,
    idleStart: idleActions.start,
    idleStop: idleActions.stop,
  }),
  render => {
    const App = ({
      // For IntlProvider
      culture,
      timezone,
      translation,
      locale,
      // For other SideEffect components
      accessToken,
      refreshToken,
      sessionId,
      clientId,
      drawerOpen,
      refreshStart,
      idleStart,
      idleStop,
      // For render()
      ...restProps
    }) => (
      <IntlProvider
        key={/* https://git.io/viGEA */ locale}
        locale={locale}
        messages={translation}
        formats={formatsByTimezoneId(timezone)}
      >
        <Fragment>
          <RefreshToken refreshStart={refreshStart} />
          <IdleDetection
            isLoggedOut={restProps.isLoggedOut}
            idleStart={idleStart}
            idleStop={idleStop}
          />
          <LocalStorage
            accessToken={accessToken}
            refreshToken={refreshToken}
            sessionId={sessionId}
            clientId={clientId}
            culture={culture}
            timezone={timezone}
            drawerOpen={drawerOpen}
          />

          {render(restProps)}

          <ReduxToastr timeOut={0} />
        </Fragment>
      </IntlProvider>
    )
    return App
  },
  layoutFactory,
  routerFactory
)

export default appFactory
