import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'

import Component from './component'

export default compose(
  withTranslation,
  withRouter,
  withPermissions,
  connect((state) => {
    return {
      langId: state.ui.langId,
    }
  })
)(Component)
