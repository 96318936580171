export const int16 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>ACORD EUROPEAN REFERITOR LA TRANSPORTUL RUTIER INTERNAŢIONAL AL MĂRFURILOR PERICULOASE (A.D.R.)*</h4><p></p>

<p style="text-indent:2em;"></p><h6>Părţile contractante, dornice să crească securitatea transporturilor rutiere internaţionale, au convenit după cum urmează:</h6><p></p>

<p style="font-weight:500;">Articolul 1</p>

<p style="text-indent:2em;"></p><h6>În sensul prezentului acord, se înţelege:</h6><p></p>

<p style="text-indent:2em;">a) prin vehicule, automobilele, vehiculele articulate, remorcile şi semiremorcile, aşa cum sunt definite prin art. 4 al Convenţiei referitoare la circulaţia rutieră, din data de
	19 septembrie 1949, cu excepţia vehiculelor care aparţin forţelor armate ale unei părţi contractante sau care se află sub respon-sabilitatea acestor forţe armate;</p><br>

<p style="text-indent:2em;">b) prin mărfuri periculoase, substanţele şi obiectele al căror transport rutier internaţional se interzice prin anexele A şi B sau nu este autorizat decât în anumite condiţii;</p><br>

<p style="text-indent:2em;">c) prin transport internaţional, orice transport efectuat pe teritoriul a cel puţin două părţi contractante, cu vehiculele definite în paragraful a) de mai sus.</p><br>

<p style="font-weight:500;">Articolul 2</p><br>

<p style="text-indent:2em;">1. Sub rezerva dispoziţiilor paragrafului 3 al art. 4, mărfurile periculoase al căror transport este exclus de anexa A nu trebuie să facă obiectul unui transport internaţional.</p>

<p style="text-indent:2em;"></p><h6>2. Transporturile internaţionale ale altor mărfuri periculoase sunt autori-zate, dacă se îndeplinesc:</h6><p></p> 

<p>a) condiţiile impuse de anexa A pentru mărfurile în cauză, în special privind ambalarea şi etichetarea; si</p><br>
	
<p style="text-indent:2em;">b) condiţiile impuse de anexa B, în special privind construcţia, echiparea şi circulaţia vehiculului care transportă mărfurile respective, conform dispoziţiilor paragrafului 2 al art. 4.</p><br>

<p style="font-weight:500;">Articolul 3</p><br>

<p>Anexele la prezentul acord fac parte integrantă din acest acord.</p><br>

<p style="font-weight:500;">Articolul 4</p><br>

<p style="text-indent:2em;">1. Fiecare parte contractantă îşi păstrează dreptul de a reglementa sau de a interzice, din alte motive decît cele de securitate, pe parcursul drumului intrarea pe teritoriul sau a 
	mărfurilor periculoase.</p><br>

<p style="text-indent:2em;">2. Vehiculele care erau în serviciu pe teritoriul unei părţi contractante la întrarea în vigoare a prezentului acord sau care au fost puse în serviciu în termen de 2 luni după această 
	intrare în vigoare vor putea efectua, în decurs de 3 ani de la această intrare în vigoare, un transport internaţional de mărfuri periculoase chiar dacă construcţia şi echipamentul lor nu 
	satisfac în întregime condiţiile impuse de anexa B pentru transportul în cauză. Cu toate acestea, clauze speciale ale anexei B pot reduce acest interval.</p><br>

<p style="text-indent:2em;">3. Părţile contractante îşi păstrează dreptul de a conveni prin acorduri speciale, bilaterale sau multilaterale, ca anumite mărfuri periculoase, al căror transport internaţional este 
	interzis prin prezentul acord, să poată, totuşi, în anumite condiţii, face obiectul transporturilor internaţionale pe teritoriile lor sau ca mărfurile periculoase al căror transport este 
	autorizat de prezentul acord în condiţii determinate să poată face obiectul transporturilor internaţionale pe teritoriile lor în condiţii mai puţin riguroase decît cele impuse de anexele la 
	prezentul acord. Acordurile speciale, bilaterale sau multilaterale, la care se referă prezentul paragraf, vor fi comunicate Secretarului General al O.N.U. care le va comunica părţilor 
	contractante care nu au semnat aceste acorduri.</p><br> 

<p style="font-weight:500;">Articolul 5</p><br>

<p style="text-indent:2em;">Transporturile cărora li se aplică prezentul acord rămân supuse prevederilor naţionale sau internaţionale care privesc în mod general circulaţia rutieră, transporturile rutiere internaţionale 
	sau schimburile internaţionale de mărfuri.</p><br>

<p style="font-weight:500;">Articolul 6</p>

<p style="text-indent:2em;"></p><h6>1. Ţările membre ale Comisiei Economice pentru Europa şi ţările admise în comisie cu titlu consultativ, conform paragrafului 8 al mandatului acestei comisii, pot deveni părţi contractante la 
	prezentul acord:</h6><p></p> 

<p>a) semnându-l;</p><br>

<p>b) ratificându-l după semnarea lui, sub rezerva ratificării;</p><br>

<p>c) aderând la el.</p><br>

<p style="text-indent:2em;">2. Ţările susceptibile de a participa la anumite lucrări ale Comisiei Economice pentru Europa, aplicând paragraful 11 al mandatului acestei comisii, pot deveni părţi contractante la prezentul 
	acord aderând la el după intrarea lui în vigoare.</p><br>

<p>3. Acordul va fi deschis spre semnare până la 15 septembrie 1957. După această dată va fi deschis spre aderare.</p><br>

<p style="text-indent:2em;">4. Ratificarea sau aderarea va fi făcută prin depunerea unui instrument pe lângă Secretarul General al Organizaţiei Naţiunilor Unite.</p><br>

<p style="font-weight:500;">Articolul 7</p><br>

<p style="text-indent:2em;">1. Prezentul acord va intra în vigoare la o lună de la data la care numărul de ţări menţionate în paragraful l al art. 6 care l-au semnat fără rezerva ratificării sau au depus instrumentul lor 
	de ratificare sau de aderare se va ridica la cinci. Cu toate acestea, anexele nu se vor aplica decât la 6 luni după intrarea în vigoare a acordului.</p><br>

<p style="text-indent:2em;">2. Pentru fiecare ţară care va ratifica prezentul acord sau va adera la el după ce cinci dintre ţările menţionate în paragraful l al art. 6 îl vor semna fără rezerva ratificării sau vor fi depus 
	instrumentul de ratificare sau de aderare, prezentul acord va intra în vigoare la o lună după depunerea instrumentului de ratificare sau de aderare a ţării respective şi anexele sale vor fi aplicate, 
	pentru acea ţară, fie la aceeaşi dată, dacă sunt deja în vigoare în momentul acela, fie, dacă nu, la data la care se vor aplica conform dispoziţiilor paragrafului l al prezentului articol.</p><br>

<p style="font-weight:500;">Articolul 8</p><br>

<p style="text-indent:2em;">1. Fiecare parte contractantă va putea denunţa prezentul acord prin notificarea adresată Secretarului General al Organizaţiei Naţiunilor Unite.</p><br>

<p>2. Denunţarea va avea efect la 12 luni după data la care Secretarul General a primit notificarea.</p><br>

<p style="font-weight:500;">Articolul 9</p><br>

<p style="text-indent:2em;">1. Prezentul acord va înceta să producă efect dacă, după intrarea lui în vigoare, numărul părţilor contractante este mai mic de cinci, timp de 12 luni consecutive.</p><br>

<p style="text-indent:2em;">2. În cazul în care se încheie un acord mondial reglementînd transporturile de mărfuri periculoase, orice dispoziţie a prezentului acord care ar fi în contradicţie cu una dintre dispoziţiile acestui 
	acord mondial, în raporturile dintre părţile la prezentul acord, devenite părţi la acordul mondial, va fi abrogată în mod automat începînd cu data intrării în vigoare a acestuia şi înlocuită ipso 
	facto de dispoziţia respectivă a acordului mondial.</p><br>

<p style="font-weight:500;">Articolul 10</p><br>

<p style="text-indent:2em;">1. Orice ţară va putea declara, la semnarea prezentului acord fără rezerva ratificării sau la depunerea instrumentului de ratificare sau de aderare sau oricînd ulterior, printr-o înştiinţare adresată 
	Secretarului General al O.N.U., că prezentul acord va fi aplicabil pe tot teritoriul pe care îl reprezintă pe plan internaţional sau numai pe o parte a acestuia. Acordul şi anexele sale vor fi aplicabile 
	pe teritoriul sau pe teritoriile menţionate în înştiinţare, la o lună după primirea acesteia de către Secretarul General.</p><br>

<p style="text-indent:2em;">2. Orice ţară care va face, conform paragrafului 1 al acestui articol, o declaraţie în scopul de a face aplicabil prezentul acord pe un teritoriu pe care îl reprezintă pe plan internaţional va putea 
	denunţa acordul, conform art.8, în ceea ce priveşte teritoriul respectiv.</p><br>

<p style="font-weight:500;">Articolul 11</p><br>

<p style="text-indent:2em;">1. Orice diferend între două sau mai multe părţi contractante, referitor la interpretarea sau aplicarea prezentului acord, va fi soluţionat, pe cît posibil, pe calea negocierii între părţile 
	în litigiu.</p><br>

<p style="text-indent:2em;">2. Orice diferend care nu va fi soluţionat pe calea negocierii va fi supus arbitrajului la cererea uneia dintre părţile contractante în litigiu şi va fi trimis, în consecinţă, unuia sau mai multor 
	arbitri aleşi de comun acord între părţile în litigiu. Dacă, în 3 luni de la cererea de arbitraj, părţile în litigiu nu ajung să se înţeleagă în ceea ce priveşte alegerea unui arbitru sau arbitrilor, 
	oricare dintre aceste părţi va putea cere Secretarului General al Organizaţiei Naţiunilor Unite desemnarea unui arbitru unic în faţa căruia va fi admis diferendul pentru decizie.</p><br>

<p style="text-indent:2em;">3. Sentinţa arbitrului sau arbitrilor desemnaţi conform paragrafului 2 al prezentului articol va fi obligatorie pentru părţile contractante în litigiu.</p><br>

<p style="font-weight:500;">Articolul 12</p><br>

<p style="text-indent:2em;">1. Fiecare parte contractantă va putea declara, în momentul semnării sau ratificării prezentului acord sau la aderare, că nu se consideră legată să respecte art. 11. Celelalte părţi contractante nu vor 
	fi legate să respecte art. 11 faţă de orice parte contractantă care va formula o astfel de rezervă.</p><br>

<p style="text-indent:2em;">2. Orice parte contractantă care va formula o rezervă conform paragrafului l al acestui articol va putea retrage această rezervă în orice moment, printr-o înştiinţare adresată Secretarului General al 
	Organizaţiei Naţiunilor Unite.</p><br> 

<p style="font-weight:500;">Articolul 13</p><br>

<p style="text-indent:2em;">1. După ce prezentul acord va fi în vigoare timp de 3 ani, orice parte contractantă va putea cere, prin notificare adresată Secretarului General al Organizaţiei Naţiunilor Unite, convocarea unei conferinţe 
	în scopul revizuirii textului acordului. Secretarul General va aduce la cunoştinţă această cerere tuturor părţilor contractante şi va convoca o conferinţă de revizuire dacă, într-un interval de 4 luni de la 
	înştiinţarea adresată de el, cel puţin un sfert dintre părţile contractante îi aduc la cunoştinţă asentimentul lor faţă de această cerere.</p><br>

<p style="text-indent:2em;">2. Dacă se convoacă o conferinţă, conform paragrafului l al prezentului articol, Secretarul General al O.N.U. va anunţa toate părţile contractante şi le va invita să prezinte, în termen de 3 luni, propunerile 
	pe care vor dori să le examineze conferinţa. Secretarul General va comunica tuturor părţilor contractante ordinea de zi provizorie a conferinţei, cât şi textul acestor propuneri, cu cel puţin 3 luni înaintea 
	datei de deschidere a conferinţei.</p><br>

<p style="text-indent:2em;">3. Secretarul General al O.N.U. va invita la orice conferinţă convocată conform prezentului articol toate ţările vizate în paragraful l al art. 6, cât şi ţările devenite părţi contractante ca urmare a 
	prevederilor paragrafului 2 al art. 6.</p><br>

<p style="font-weight:500;">Articolul 14</p><br>

<p style="text-indent:2em;">1. Independent de procedura de revizuire prevăzută în art. 13, orice parte contractantă va putea propune unul sau mai multe amendamente la anexele prezentului acord, în acest scop, ea va transmite textul 
	Secretarului General al Organizaţiei Naţiunilor Unite. Pentru a obţine concordanţa acestor anexe cu celelalte acorduri internaţionale referitoare la transportul mărfurilor periculoase, Secretarul General 
	va propune, deasemenea, amendamente la anexele prezentului acord.</p><br>

<p style="text-indent:2em;">2. Secretarul General al O.N.U. va comunica tuturor părţilor contractante şi va aduce la cunoştinţă celorlalte ţări vizate în paragraful 1 al art.6 orice propunere făcută conform prevederilor paragrafului 
	1 al prezentului articol.</p>

    <p style="text-indent:2em;"></p><h6>3. Orice proiect de amendament al anexelor va fi considerat acceptat dacă, în termen de 3 luni de la data la care Secretarul General al O.N.U. l-a transmis, cel puţin o treime dintre părţile contractante 
	sau cinci dintre ele dacă o treime este mai mare decît această cifră nu au comunicat în scris Secretarului General opoziţia lor la amendamentul propus. Dacă amendamentul este considerat accepta, va intra 
	în vigoare pentru toate părţile contractante la expirarea unui nou termen de 3 luni, cu excepţia cazurilor de mai jos:</h6><p></p>

<p style="text-indent:2em;">a) în cazul în care au fost aduse sau vor fi probabil aduse amendamente la alte acorduri internaţionale vizate în paragraful 1 al prezentului articol, va intra în vigoare la expirarea unui termen care va 
	fi fixat de Secretarul General astfel încît să permită, pe cît posibil, intrarea în vigoare simultană a amendamentelor respective şi a celor care au fost sau vor fi probabil aduse la celelalte acorduri. 
	Termenul nu va putea fi oricum mai mic de o lună;</p><br>

<p style="text-indent:2em;">b) partea contractantă care înaintează proiectul de amendament va putea specifica în propunerea sa un termen mai mare de 3 luni pentru intrarea în vigoare a amendamentului în cazul cînd va fi acceptat.</p><br>

<p style="text-indent:2em;">4. Secretarul General al O.N.U. va comunica cît mai curînd posibil tuturor părţilor contractante şi tuturor ţărilor vizate în paragraful 1 al art.6 orice obiecţie primită de la părţile contractante faţă 
	de un amendament propus.</p>

    <p style="text-indent:2em;"></p><h6>5. Dacă proiectul de amendament al anexelor nu este considerat acceptat, dar dacă cel puţin o parte contractantă, alta decît cea care l-a propus, a comunicat în scris Secretarului General al O.N.U. 
	acordul ei cu privire la proiect, va fi convocată o reuniune a tuturor părţilor contractante şi a tuturor ţărilor vizate în paragraful 1 al art.6 de către Secretarul General, în termen de 3 luni calculate 
	de la expirarea termenului de 3 luni prevăzut de paragraful 3 al prezentului articol, pentru a se opune amendamentului. Secretarul General poate invita la această reuniune şi reprezentanţii:</h6><p></p>

<p>a) ai organizaţiilor internaţionale guvernamentale cu atribuţii în domeniul transporturilor;</p><br>

<p style="text-indent:2em;">b) ai organizaţiilor internaţionale neguvernamentale ale căror activităţi sunt legate direct de transportul de mărfuri periculoase pe teritoriul părţilor contractante.</p><br>

<p style="text-indent:2em;">6. Orice amendament adoptat de mai mult de jumătate din numărul total al părţilor contractante la o reuniune convocată conform paragrafului 5 al acestui articol va intra în vigoare, pentru toate părţile 
	contractante, conform modalităţilor hotărîte la respectiva reuniune de către majoritatea părţilor contractante participante la aceasta.</p><br>

<p style="font-weight:500;">Articolul 15</p>

    <p style="text-indent:2em;"></p><h6>În afara înştiinţărilor prevăzute în art. 13 şi 14, Secretarul General al Organizaţiei Naţiunilor Unite va aduce la cunoştinţă ţărilor vizate în paragraful 1 al art. 6, cât şi ţărilor devenite părţi 
	contractante ca urmare a paragrafului 2 al art. 6:</h6><p></p>

<p>a) semnările, ratificările şi aderările, conform art. 6;</p><br>

<p>b) datele la care prezentul acord şi anexele sale intră în vigoare, conform art. 7;</p><br>

<p>c) denunţările, conform art. 8;</p><br>

<p>d) abrogarea acordului, conform art. 9;</p><br>

<p>e) înştiinţările şi denunţările primite, conform art. 10;</p><br>

<p>f) declaraţiile şi înştiinţările primite, conform paragrafelor l şi 2 ale art. 12;</p><br>

<p>g) acceptarea şi data intrării în vigoare a modificărilor, conform paragrafelor 3 şi 6 ale art. 14.</p><br>

<p style="font-weight:500;">Articolul 16</p><br>

<p style="text-indent:2em;">1. Protocolul de semnare a prezentului acord va avea aceeaşi valabilitate, valoare şi durată ca şi acordul însuşi, din care va fi considerat ca făcând parte integrantă.</p><br>

<p style="text-indent:2em;">2. Nu se admite nici o rezervă la prezentul acord, cu excepţia celor înscrise în protocolul de semnare şi celor formulate conform art. 12.</p><br>

<p style="font-weight:500;">Articolul 17</p><br>

<p style="text-indent:2em;">După 15 decembrie 1957, originalul prezentului acord va fi depus la Sec-retarul General al Organizaţiei Naţiunilor Unite, care va trimite copii certifi-cate conforme fiecăreia dintre 
	ţările menţionate în paragraful l al art. 6.</p><br>

<p>Drept pentru care subsemnaţii, autorizaţi în modul cuvenit, au semnat prezentul acord.</p><br>

<p style="text-indent:2em;">Încheiat la Geneva la 30 septembrie 1957, într-un singur exemplar, în limbile engleză şi franceză pentru textul acordului şi în limba franceză pentru anexe, fiecare text având aceeaşi 
	valabilitate pentru acordul menţionat.</p><br>

<p style="text-indent:2em;">Secretarul General al O.N.U. este invitat să întocmească o traducere oficială a anexelor în limba engleză şi să anexeze această traducere la copiile certifi-cate, menţionate în art. 17.</p><br>

<p><a href="http://www.monitoruljuridic.ro/act/acord-european-din-30-septembrie-1957-referitor-la-transportul-rutier-interna-ional-al-m-rfurilor-periculoase-a-d-r-emitent-parlamentul-31967.html">Sursa</a></p>



</div>
`