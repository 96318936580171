import React, { Component } from 'react'

import News from '../../news/list-mini'
import Ads from '../../ads'
import Forum from '../../forum'
import WidgetBase from '../../shared/components/widget-base'
import reactHtmlParser from 'react-html-parser'
import { contentMDru } from '../../../legacy-content/md/templates/content-ru'
import { contentMDro } from '../../../legacy-content/md/templates/content-ro'
import { contentEUru } from '../../../legacy-content/eu/templates/content-ru'
import { contentEUro } from '../../../legacy-content/eu/templates/content-ro'
import { contentINTru } from '../../../legacy-content/international/templates/content-ru'
import { contentINTro } from '../../../legacy-content/international/templates/content-ro'


export default class LegislationContent extends Component {
    state = {
        items: [],
        sections: [],
        selectedKey: 0,
        title: "",
        html: "",
    }

    componentDidMount() {
        const { match: { params: { area, key } }, langId } = this.props

        this.setTitle(area)
        this.setContent(area, key, langId)
    }

    setContent = (area, key, langId) => {

        let content

        switch (area) {
            case "md":
                content = langId === 1 ? contentMDru.find(item => item.key === key) : contentMDro.find(item => item.key === key)
                break;
            case "eu":
                content = langId === 1 ? contentEUru.find(item => item.key === key) : contentEUro.find(item => item.key === key)
                break;
            case "int":
                content = langId === 1 ? contentINTru.find(item => item.key === key) : contentINTro.find(item => item.key === key)
                break;
            default:
                break;
        }

        const html = content?.html ? content.html : ""

        this.setState({ html })
    }

    setTitle = (area) => {
        let title

        switch (area) {
            case "md":
                title = "ЗАКОНОДАТЕЛЬСТВО МОЛДОВЫ"
                break;
            case "eu":
                title = "LEGISLATIA UNIUNEI EUROPENE"
                break;
            case "int":
                title = "МЕЖДУНАРОДНОЕ ЗАКОНОДАТЕЛЬСТВО"
                break;
            default:
                break;
        }

        this.setState({ title })
    }

    render() {
        const { title, html } = this.state
        return (
            <div className="row">
                <div className="col-md-10 col-xl-9">
                    <WidgetBase {...{ title }}>
                        <div>{reactHtmlParser(html)}</div>
                    </WidgetBase>
                </div>
                <div className="col-md-6 col-xl-7">
                    <News />
                    <Ads />
                    <Forum />
                </div>
            </div>
        )
    }
}
