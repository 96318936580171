import { connect } from 'react-redux'
import { compose } from 'recompose'

export default compose(
  connect(state => {
    const { permissions = [], userId, username, email } = state.auth.jwt || {}
    return {
      isAuthenticated: !!state.auth.accessToken,
      userId,
      username,
      email,
      permissions,
    }
  })
)
