import { compose } from 'recompose'

import Component from './component'
import { ForumAllCommentsModalProps } from './types'
import withTranslation from '../../../../globals/components/withTranslation'
import withPermissions from '../../../../globals/components/withPermissions'

export default compose(
  withTranslation,
  withPermissions
)(Component) as React.ComponentClass<ForumAllCommentsModalProps>
