import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { reduxForm, formValueSelector } from 'redux-form'

import withTranslation from '../../../globals/components/withTranslation'
import {
  cargoSearch,
  cargoAddFavourite,
  cargoDeleteFavourite,
  cargoNotificationUnsubscribe,
  transportSearch,
  transportAddFavourite,
  transportDeleteFavourite,
} from '../../../store/actions'
import { socketRefreshSearchFinish } from '../../../store/actions'
import Component from './component'
import { IReduxStorage } from '../../../store/reducers/types'
import { CounterOffersBaseProps } from './types'

const FORMNAME = 'cargoCounterOffersResults'
const formSelector = formValueSelector(FORMNAME)

const initialValues = {
  filterKey: 'new',
  openIn: 'preview',
  sortBy: 'date-add',
  pageSize: 20,
  showHidden: false,

  isCountries: true,
  isDates: true,
  isTransportType: true,
  isCarryingCapacity: false,
  isClimateControl: false,
  isGrouping: false,
  isAdr: false,
  isOversized: false,
  isOnlyProvedCompanies: false,
  isOnlyFromMyCountry: false,
}

export default compose(
  withTranslation,
  connect(
    (state: IReduxStorage, props: CounterOffersBaseProps) => {
      const { cargo, auth, transport } = state
      const searchEntity = props.searchType === 'transport' ? cargo.get('search') : transport.search
      const pageSize = searchEntity.get('pageSize')
      initialValues.pageSize = pageSize
      initialValues.filterKey = searchEntity.get('dateScope')

      return {
        userId: auth.jwt.userId,
        processing: searchEntity.get('processing'),
        pageSize,
        pageIndex: searchEntity.get('pageIndex'),
        totalItems: searchEntity.get('totalItems'),
        items: searchEntity.get('items'),
        error: searchEntity.get('error'),
        queryStr: searchEntity.get('queryStr'),
        query: formSelector(state, ...Object.keys(initialValues)),
        openIn: formSelector(state, 'openIn'),
        initialValues,
        langId: state.ui.langId,
      }
    },
    (dispatch: any, props: CounterOffersBaseProps) => {
      const isCargo = props.searchType === 'cargo'
      return {
        search: data => dispatch(isCargo ? transportSearch(data) : cargoSearch(data)),
        addFavourite: (itemId: number) =>
          dispatch(isCargo ? transportAddFavourite(itemId) : cargoAddFavourite(itemId)),
        deleteFavourite: (itemId: number) =>
          dispatch(isCargo ? transportDeleteFavourite(itemId) : cargoDeleteFavourite(itemId)),
        notificationUnsubscribe: () => dispatch(cargoNotificationUnsubscribe),
        socketSearchRefreshFinish: () => dispatch(socketRefreshSearchFinish),
      }
    }
  ),
  withHandlers({
    onSubmit: () => values => {
      console.log(values)
    },
  }),
  reduxForm({
    form: FORMNAME,
  })
)(Component) as React.ComponentClass<CounterOffersBaseProps>
