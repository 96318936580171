import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import Spinner from '../../UI/spinner'
import { LastSearchProps } from './types'
import './styles.scss'
import LoginLink from '../../navigation/login-link'

const STYLE_BASE = 'cargo-last-search_'
const RESPONSIVE_RANGE = 1320

export default class extends Component<LastSearchProps> {
  state = {
    showLastSearchContent: false,
  }
  load = () => {
    const { isAuthenticated, loadLastSearch, loading, loaded } = this.props
    if (isAuthenticated && !loaded && !loading) {
      loadLastSearch()
    }
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate() {
    this.load()
  }

  onClickLogin = () => {
    const w: any = window
    // const link = w.refLogin.current
    const link: any = ReactDOM.findDOMNode(w.refLogin)
    link && link.click && link.click()
  }

  renderNotAuthenticated = () => {
    const { t } = this.props
    return (
      <div className="panel-search-wrapper centered-container">
        <div className="panel-search-log centered-item">
          <span className="fa fa-clock-o" />
          <p className="panel-search-text">
            {t('history.protected')}
          </p>
          <div className="panel-search-links">
            {/* <Link to="#" onClick={this.onClickLogin} data-popup-link="login">
              {t('login.enter.2')}
            </Link> */}
            <LoginLink>{t('login.enter.2')}</LoginLink>
            {` ${t('or')} `}
            <Link to="/auth/registration">{t('login.register')}</Link>
          </div>
          <br />
          {t('login.family')}
        </div>
      </div>
    )
  }

  renderNoSearch = () => {
    const { t } = this.props
    return (
      <div className="panel-search-wrapper centered-container">
        <div className="panel-search-log centered-item">
          <span className="fa fa-clock-o" />
          <p className="panel-search-text">{t('cargo.search.not-yet')}</p>
        </div>
      </div>
    )
  }
  renderItems = () => {
    const { t, loading, loaded, items, totalItems, insertSearch, deleteLastSearch } = this.props
    if (loading) {
      return <Spinner className={classNames(`${STYLE_BASE}spinner`)} />
    } else if (loaded) {
      if (totalItems === 0) {
        return (
          <div className="panel-search-wrapper centered-container">
            <div className="panel-search-log centered-item">
              <span className="fa fa-clock-o" />
              <p className="panel-search-text">{t('cargo.search.not-yet')}</p>
            </div>
          </div>
        )
      } else {
        return items.map((x, index) => (
          <div key={index} className="panel-content-row panel-search-row">
            <div
              style={{ textOverflow: 'ellipsis', width: '100%', cursor: 'pointer' }}
              onClick={() => {
                insertSearch(x.get('query'))
              }}
            >
              <span className="text text-search-icon">{x.get('text')}</span>
            </div>
            <span
              className="icon-remove fa fa-trash"
              onClick={() => deleteLastSearch(x.get('id'))}
            />
          </div>
        ))
      }
    }
    return null
  }

  render() {
    const { t, isAuthenticated } = this.props
    const { showLastSearchContent } = this.state

    const classRotate = showLastSearchContent ? 'rotate' : ''
    const hideBlock = showLastSearchContent ? 'hide-block' : ''

    const pageWidth = document.body.clientWidth
    const checkResponsive = RESPONSIVE_RANGE <= pageWidth ? '' : hideBlock

    return (
      <div className="panel panel-search search-height">
        <div
          className="panel-header"
          onClick={() => this.setState({ showLastSearchContent: !showLastSearchContent })}
        >
          <span className={`fa fa-chevron-up ${classRotate}`} />
          <h4>{t('cargo.search.header.last')}</h4>
        </div>
        <div className={`panel-body ${checkResponsive}`}>
          <div className="panel-content">
            {isAuthenticated ? this.renderItems() : this.renderNotAuthenticated()}
          </div>
        </div>
      </div>
    )
  }
}
