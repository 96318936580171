import React, { Component } from 'react'
import classNames from 'classnames'

import FineUploaderTraditional from 'fine-uploader-wrappers'
import DeleteButton from 'react-fine-uploader/delete-button'
import Dropzone from 'react-fine-uploader/dropzone'
import FileInput from 'react-fine-uploader/file-input'
import Thumbnail from 'react-fine-uploader/thumbnail'

import { toastr } from 'react-redux-toastr'

import Spinner from '../spinner'
import config from '../../../config'
import { ALLOWED_EXTENSIONS_IMAGE } from '../../../store/constants'

import './file-uploader.scss'

const STYLE_BASE = 'file-uploader_'

const uploader = new FineUploaderTraditional({
  options: {
    core: {
      multiple: false,
    },
    cors: {
      expected: true,
      sendCredentials: true,
    },
    deleteFile: {
      enabled: true,
      endpoint: `${config.apiUrl}/common/upload`,
    },
    request: {
      endpoint: `${config.apiUrl}/common/upload`,
    },
    validation: {
      allowedExtensions: ALLOWED_EXTENSIONS_IMAGE,
    },
  },
})
// see for statuses - https://docs.fineuploader.com/features/statistics-and-status-updates.html
const { CANCELED, DELETED, SUBMITTED, UPLOADING, UPLOAD_SUCCESSFUL, REJECTED } = uploader.qq.status

export default class FileUploader extends Component {
  constructor(props) {
    super(props)

    const { folder = '' } = props

    this.state = {
      files: [],
      uploading: false,
      currentId: undefined,
    }

    this.sizeLimit = 1024 * 1024 * 5 // 5 MB

    this.uploader = new FineUploaderTraditional({
      options: {
        core: {
          multiple: false,
        },
        cors: {
          expected: true,
          sendCredentials: true,
        },
        deleteFile: {
          enabled: true,
          endpoint: `${config.apiUrl}/common/deleteFile`,
        },
        request: {
          endpoint: `${config.apiUrl}/common/upload/${folder}`,
        },
        validation: {
          allowedExtensions: props?.allowedExtensions ? props?.allowedExtensions : ALLOWED_EXTENSIONS_IMAGE,
          sizeLimit: this.sizeLimit,
        },
      },
    })
  }

  componentDidMount() {
    const { t, folder, cbRefUploader } = this.props
    this.uploader.on('statusChange', (id, oldStatus, newStatus) => {
      const { initialValues = {} } = this.props
      const { files: oldFiles } = this.state
      const files = [...oldFiles]
      this.setState({ currentId: id })

      if (newStatus === UPLOAD_SUCCESSFUL) {
        const item = this.getItem(id, folder)
        const { input: { onChange } = {} } = this.props
        this.setState({ uploading: false })
        if (onChange) {
          onChange(item.uuid)
        } else {
          const { onSelected } = this.props
          onSelected && onSelected(item)
        }
      } else if (newStatus === UPLOADING) {
        this.setState({ uploading: true })
      } else if (newStatus === SUBMITTED) {
        const item = this.getItem(id, folder)
        this.setState({ files: [item], uploading: true })
      } else if ([CANCELED, DELETED].includes(newStatus)) {
        const indexToRemove = files.findIndex(f => f.id === id)
        files.splice(indexToRemove, 1)
        this.setState({ files })

        const { onDeleted } = this.props
        onDeleted && onDeleted(id)
      } else if (newStatus === REJECTED) {
        if (this.uploader.methods.getSize(id) > this.sizeLimit) {
          toastr.error(t('file.size.limit', { size: 5 }))
        } else {
          toastr.error(t('not-supported.format'))
        }
      }
      if (initialValues) {
        initialValues.files = files
      }
    })
    cbRefUploader && cbRefUploader(this.uploader)
  }
  componentWillUnmount() {
    this.uploader.off('statusChange')
  }
  componentDidUpdate(prevProps) {
    if (prevProps.delete !== this.props.delete) {
      this.uploader.methods.deleteFile(this.state.currentId)
    }
    if (prevProps.folder !== this.props.folder) {
      this.uploader.methods.setEndpoint(`${config.apiUrl}/common/upload/${this.props.folder}`)
    }
  }
  getItem = (id, folder) => {
    const uuid = this.uploader.methods.getUuid(id)
    const item = {
      id,
      name: this.uploader.methods.getName(id),
      size: this.uploader.methods.getSize(id),
      uuid,
      url: `${config.apiUrl}/common/file/${folder}/${uuid}`,
    }
    return item
  }

  render() {
    const { files, uploading } = this.state
    const { className, children, showPreview = true, works = true, allowedExtensions } = this.props

    let accept = [ALLOWED_EXTENSIONS_IMAGE.map(item => '.' + item)].join(",")
    
    if (allowedExtensions) {
      accept = [allowedExtensions.map(item => '.' + item)].join(",")
    }
    
    return (
      works && (
        <div
          className={classNames({
            [`${STYLE_BASE}wrapper`]: true,
            [className]: !!className,
          })}
        >
          <Dropzone uploader={this.uploader}>
            {showPreview &&
              files.map(({ id }) => (
                <div key={id}>
                  <Thumbnail id={id} uploader={this.uploader} />
                  <DeleteButton id={id} uploader={this.uploader}>
                    <div className={`${STYLE_BASE}delete-wrapper`}>
                      <i className="fa fa-times-circle" />
                    </div>
                  </DeleteButton>
                </div>
              ))}
            <FileInput
              uploader={this.uploader}
              accept={accept}
              title="Click to upload"
              className={classNames({
                [`click-wrapper`]: true,
                [`click-centered`]: files.length === 0,
              })}
            >
              {uploading ? <Spinner className={`${STYLE_BASE}spinner`} /> : children}
            </FileInput>
          </Dropzone>
        </div>
      )
    )
  }
}
