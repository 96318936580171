import React, { Fragment, PureComponent } from 'react'
import isArray from 'lodash/isArray'
import Select from 'react-select'
import classNames from 'classnames'

// import AsyncSelect from 'react-select/lib/Async'
import makeAnimated from 'react-select/animated'
import uuid from 'uuid/v4'
import "./styles.scss"
export default class extends PureComponent {
  guid = `sel${uuid()}`
  state = {
    options: [],
  }

  componentDidMount() {
    const { items, input } = this.props
    this.refreshItems(items)
    if (input.value !== '') {
      input.onChange(input.value)
    }
  }
  componentDidUpdate(prevProps) {
    const { items } = this.props
    if (prevProps.items !== items) {
      this.refreshItems(items)
    }
  }

  singleChangeHandler = func => value => func(value ? value.value : '')
  multiChangeHandler = func => values => func(values ? values.map(value => value.value) : [])

  transformValue = (value, options, multi) => {
    if (multi && typeof value === 'string') return []

    if (multi) {
      // return options.filter(option => value.indexOf(option.value) !== -1)
      return value.map(v => options.find(option => option.value === v))
    } else {
      return options.find(option => option.value === value)
    }
  }

  handle = (multi, onChange) => {
    return multi ? this.multiChangeHandler(onChange) : this.singleChangeHandler(onChange)
  }

  refreshItems(data) {
    const { showOnlySiteLanguages = false } = this.props
    if (data) {
      if (isArray(data)) {
        // this.setOptions(data)
        this.setState({ options: data })
      } else if (data.type === 'dictionary') {
        const { loading, loaded, handler } = data
        // this.setState({ loading, loaded })
        // helper function
        // const initItems = ({ loading, loaded, items, error }) => {
        const initItems = ({ items }) => {
          let options
          if(!showOnlySiteLanguages){
            options = items?.map(x => ({
              value: x.value,
              label: x.name,
            }))
          } else{
            options = items?.filter(i => i.isSiteLang).map(x => {
                return ({
                  value: x.value,
                  label: x.name,
                })
            })
          }
          

          // this.setState({ options, loading, loaded, error })
          this.setState({ options })
          // this.setOptions(options, { loading, loaded, error })
        }
        // set up items
        if (loading) {
          handler.then(x => {
            const { loading, loaded, items = [], error } = x
            initItems({ loading, loaded, items, error })
          })
        } else if (loaded) {
          const { items, error } = data
          initItems({ loading, loaded, items, error })
        }
      }
    } else {
      // this.setState({ loading: false, loaded: false, options: [] })
      this.setState({ options: [] })
    }
  }

  render() {
    const {
      className,
      input,
      meta: { touched, error },
      //items = [],
      placeholder,
      multi,
      //style,
      width,
      //styles = {},
      setActivePopup,
      maxOptionsToSelect,
    } = this.props
    const { options } = this.state
    const { value, onChange } = input //, ...restInput
    const transformedValue = this.transformValue(value, options, multi)
    const customStyles = {}
    if (width) {
      customStyles.control = styles => ({ ...styles, width })
    }
    return (
      <Fragment>
        <Select
          components={makeAnimated()}
          classNamePrefix="multi-select"
          className={classNames({
            'multi-select': true,
            [className]: !!className,
          })}
          options={options}
          placeholder={placeholder}
          value={transformedValue}
          isMulti={multi}
          animated
          onChange={this.handle(multi, onChange)}
          onMenuOpen={() => setActivePopup(this.guid)}
          styles={customStyles}
          //{ ...this.customStyles, ...styles }}
          //onBlur = { this.handle(multi, onChange) }
          // { ...restInput }
          //onChange = { onChange }
          //onBlur={() => onBlur(value)}
          //onFocus={onFocus}
          isOptionDisabled={(option, selectedOptions) =>
            maxOptionsToSelect && selectedOptions.length >= maxOptionsToSelect
          }
        />
        {touched && error && <div className="validation-message">{error}</div>}
      </Fragment>
    )
  }
}
