import React, { Component } from 'react'
import Widget from '../../shared/components/widget'
import News from '../../news/list-mini'
import Ads from '../../ads/list-mini'
import Forum from '../../forum'

export default class extends Component {
  render() {
    const { t } = this.props
    return (
      <div className="row">
        <div className="col-md-10">
          <Widget title={t('about.company.verification')}>
            <div
              dangerouslySetInnerHTML={{
                __html: t('company.verification')
              }} />
          </Widget>
        </div>
        <div className="col-md-6">
          <News />
          <Ads />
          <Forum />
        </div>
      </div>
    )
  }
}