import { compose } from 'recompose'
import { connect } from 'react-redux'
import { logoutOnly } from '../../../store/actions/auth'
import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'
import Component from './component'

export default compose(
  withTranslation,
  withPermissions,
  connect(null, {
    logoutOnly
  })
)(Component)
