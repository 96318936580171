export const linksINT = (t) => {
    const links = [
        { key: "int1", title: t('int.menu.item.1') },
        { key: "int2", title: t('int.menu.item.2') },
        { key: "int3", title: t('int.menu.item.3') },
        { key: "int4", title: t('int.menu.item.4') },
        { key: "int5", title: t('int.menu.item.5') },
        { key: "int6", title: t('int.menu.item.6') },
        { key: "int7", title: t('int.menu.item.7') },
        { key: "int8", title: t('int.menu.item.8') },
        { key: "int9", title: t('int.menu.item.9') },
        { key: "int10", title: t('int.menu.item.10') },
        { key: "int11", title: t('int.menu.item.11') },
        { key: "int12", title: t('int.menu.item.12') },
        { key: "int13", title: t('int.menu.item.13') },
        { key: "int14", title: t('int.menu.item.14') },
        { key: "int15", title: t('int.menu.item.15') },
        { key: "int16", title: t('int.menu.item.16') },
        { key: "int17", title: t('int.menu.item.17') },
        { key: "int18", title: t('int.menu.item.18') },
        { key: "int19", title: t('int.menu.item.19') },
        { key: "int20", title: t('int.menu.item.20') },
        { key: "int21", title: t('int.menu.item.21') },
        { key: "int22", title: t('int.menu.item.22') },
        { key: "int23", title: t('int.menu.item.23') },
        { key: "int24", title: t('int.menu.item.24') },
        { key: "int25", title: t('int.menu.item.25') },
        { key: "int26", title: t('int.menu.item.26') },
    ]

    return links
}

