export const eu1 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Transport</h4><p></p>

<p style="text-indent:2em;">Politica Uniunii Europene (UE) în domeniul transporturilor urmăreşte asigurarea circulaţiei line, eficiente, sigure şi libere a persoanelor şi a bunurilor pe întreg teritoriul UE, cu ajutorul
unor reţele integrate care includ toate modalităţile de transport (rutier, feroviar, pe apă şi aerian). De asemenea, politica UE abordează aspecte foarte variate, cum ar fi schimbările climatice,
drepturile pasagerilor, combustibilii curaţi şi reducerea birocraţiei vamale în porturi.</p><br>

<p style="text-indent:2em;">Cuprins în Titlul VI (articolele 90-100) din Tratatul privind funcţionarea UE, transportul reprezintă una dintre cele mai strategice politici comune ale UE. Politica viitoare se va baza în
mare parte pe o carte albă din 2011, care cuprinde 40 de iniţiative menite să genereze creştere economică şi locuri de muncă, să diminueze dependenţa de petrolul importat şi să reducă emisiile
de carbon din sector cu 60 % până în 2050.</p><br>

<p><a href="https://eur-lex.europa.eu/summary/chapter/transport.html?root_default=SUM_1_CODED%3D32&amp;locale=ro">Sursa</a></p>



</div>
`