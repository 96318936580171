import { connect } from 'react-redux'
import { compose } from 'recompose'

import Component from './component'

import withTranslation from '../../../globals/components/withTranslation'

export default compose(
  withTranslation,
  connect((state, props) => {
    const { lang, area = '', article } = props.match.params

    return {
      lang,
      area,
      article,
      isAuthenticated: !!state.auth.accessToken,
    }
  })
)(Component)
