import React, { Component } from 'react'

import { describeArc } from '../shared/utils/svg'
import { MODE_TIME_REMAINED } from '../../store/constants'
import './timer.scss'

const TIMER_RADIUS = 40
const TIMER_TICKNESS_BAGEL = 6

const STYLE_BASE = 'timer_'

export default class extends Component {
  constructor(props) {
    super(props)

    this.state = {
      timeLeft: props.autoResolveIn,
      refInterval: setInterval(() => {
        if (this.state.timeLeft > 0) {
          this.setState(prevState => ({ timeLeft: prevState.timeLeft - 1 }))
        } else {
          const { onFinish } = this.props
          onFinish && onFinish()
        }
      }, 1000),
    }
  }

  componentWillUnmount() {
    this.stopTimer()
  }

  drawTicks = ({ radius, thickness }) => {
    const tickX1 = radius - thickness
    const tickX2 = radius - thickness * 1.5
    return (
      <g className="marks" transform={`translate(${radius},${radius})`}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(x => {
          return <line key={x} x1={tickX1} y1="0" x2={tickX2} y2="0" />
        })}
      </g>
    )
  }

  stopTimer() {
    if (this.state.refInterval) {
      clearInterval(this.state.refInterval)
    }
    this.setState({
      refInterval: null,
    })
  }

  render() {
    const radius = TIMER_RADIUS
    const thickness = TIMER_TICKNESS_BAGEL
    const { timeLeft } = this.state
    const { autoResolveIn = 60 } = this.props
    const pc = (100 * timeLeft) / autoResolveIn
    const actualRadius = radius - thickness / 2
    const timeArc = describeArc({ radius: actualRadius, mode: MODE_TIME_REMAINED }, pc)

    return (
      <div className={`${STYLE_BASE}wrapper`}>
        <svg width={2 * radius} height={2 * radius}>
          <g transform={`translate(${radius},${radius})`}>
            <text className="timer-text" dy=".35em">
              {this.state.timeLeft}
            </text>
            <circle r={actualRadius} strokeWidth={thickness} />
            <path className="timer-arc" stroke="#5CB85C" strokeWidth={thickness} d={timeArc} />
          </g>
          {this.drawTicks({ radius, thickness })}
        </svg>
      </div>
    )
  }
}
