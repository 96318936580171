import React from 'react'

import ProfileDetails from './profile-details'
import FlexBand from 'flexband'
import AdminNotice from '../../admin-notice/notice'
import UserNote from './profile-details/user-note'
import UserLogs from './profile-details/user-logs'

import Ads from '../../ads'
import SiteStat from '../../site-stat'
import BuySellShort from '../../buy-sell/short'

import './styles.scss'

interface IFullProfileProps {
  isAuth?: boolean
}

export default class FullProfile extends React.Component<IFullProfileProps> {
  render() {
    const { isAuth } = this.props
    return (
      <FlexBand justify="space-between">
        <div className="profile">
          <ProfileDetails />
        </div>
        <div className="note">
          {isAuth ? <>
            <AdminNotice />
            <UserNote />
          </> : <>
            <SiteStat />
            <BuySellShort />
            <Ads />
          </>}
          <UserLogs />
        </div>
      </FlexBand>
    )
  }
}
