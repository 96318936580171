import React from 'react'

export default class EmployeeListInfo extends React.Component {
  
  renderEmployeeList = employeesList => {
    const content = employeesList.map((oneEmploye, index) => {
      const { userLastName, userName, email, isAdmin } = oneEmploye
      return (
        <tr key={index}>
          <td>
            {userName} {userLastName}
          </td>
          <td>{isAdmin === 1 ? t('administrator') : t('manager')}</td>
          <td>{email}</td>
        </tr>
      )
    })
    return content
  }

  render() {
    const { employeesList, t } = this.props

    return (
      <div className="panel-tab">
        <div className="panel-content">
          <div className="panel-content-table">
            <table className="table table-striped-main table-employee table-centered table-link table-lg table-responsive js-table-responsive">
              <thead>
                <tr>
                  <th>
                    <span>{t('verification.company.employees')}</span>
                  </th>
                  <th>
                    <span>{t('verification.company.function')}</span>
                  </th>
                  <th>
                    <span>{t('email')}</span>
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderEmployeeList(employeesList)}</tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}
