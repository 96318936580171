import React, { Component } from 'react'
import { match } from 'react-router'
import { Redirect, Link } from 'react-router-dom'
import dayjs from 'dayjs'

import fetch from '../../shared/fetch'
import Widget from '../../shared/components/widget'
import { makeLinksActive } from '../../shared/utils'
import Pagination from '../../UI/pagination'

import './styles.scss'

interface IUserNoticesProps extends ComponentBaseProps, PermissionInjectProps {
  match: match<{ userId }>
}

interface IUserNoticesState {
  list: INotice[]
  pageIndex: number
}

interface INotice {
  id: number
  title: string
  text: string
  adminId: number
  adminName: string
  datePosted: string | Date
  totalItems: number
}

const pageSize = 10

export default class UserNotices extends Component<IUserNoticesProps, IUserNoticesState> {
  constructor(props) {
    super(props)

    this.state = {
      list: [],
      pageIndex: 0,
    }
  }

  componentDidMount() {
    const { permissions, userId, match } = this.props
    const { userId: noticeUserId } = match.params
    const isAdmin = permissions.includes('admin')
    const hasAccess = isAdmin || userId === +noticeUserId

    fetch(`/user/checkUserExists?userId=${noticeUserId}`).then(res => {
      if (res.ok && hasAccess) {
        this.getAdminNoticeList()
      }
    })
  }

  getAdminNoticeList = (pageIndex: number = 0) => {
    const { userId } = this.props.match.params
    fetch(`/user/getAdminNoticeList/${userId}/${pageSize}/${pageIndex}`).then(result => {
      this.setState({
        pageIndex,
        list: result.data,
      })
    })
  }

  render() {
    const { t, permissions, userId, match } = this.props
    const { userId: noticeUserId } = match.params
    const { list, pageIndex } = this.state
    const isAdmin = permissions.includes('admin')
    const hasAccess = isAdmin || userId === +noticeUserId

    if (!hasAccess) {
      return <Redirect to="/" />
    }

    return (
      <Widget title={t('admin.notices')} className="user-notices-panel">
        {list.map(notice => (
          <div key={notice.id} className="panel-content-row">
            <span className="header-info-panel">
              <i className="fa fa-calendar" />
              {` ${dayjs(notice.datePosted).format('DD/MM/YY')}`}
              <Link to={`/user/details/${notice.adminId}`} className="notice-author">
                {notice.adminName}
              </Link>
              <span>{` ${t('admin.notices.add.notice')}:`}</span>
            </span>
            <h4 className="title">{notice.title}</h4>
            <div className="text">{makeLinksActive(notice.text)}</div>
          </div>
        ))}
        <Pagination
          total={list[0]?.totalItems || 0}
          pageIndex={pageIndex}
          pageSize={pageSize}
          onClick={index => this.getAdminNoticeList(index)}
        />
      </Widget>
    )
  }
}
