import React from 'react'

import FindCargo from '../cargo/search/widget-form'
import News from '../news/list-mini'
import Ads from '../ads/list-mini'
import Forum from '../forum'
import WelcomeUser from '../welcome-user'
import MyStatistics from '../my-statistics'
import RatingShort from '../rating/short'
import BlacklistShort from '../black-list/short'

import AdminNotice from '../admin-notice/notice'
import SiteStat from '../site-stat'
import CalcDistance from '../calc-distance'
import InfoCountry from '../info-country'
import BuySellShort from '../buy-sell/short'
import Banner from '../banner'
import Table from '../summary-table/table'
import Widget from '../shared/components/widget'

import './style.scss'

import calc from '../../images/calc-schengen.png'

export default props => {
  const { isAuthenticated } = props

  return (
    <div className="row">
      <div className="col-md-12">
        {!isAuthenticated && <FindCargo />}
        <div className="banner-line">
          <Banner bannerId={518} dataMoveForMobile={'banner1'} />
          <Banner bannerId={519} dataMoveForMobile={'banner2'} />
        </div>

        <div className="panels-mobile" id="panelForMobile">
          <div data-mobile-container="banner1"></div>
          <div data-mobile-container="welcome-user"></div>
          <div data-mobile-container="banner2"></div>
          <div data-mobile-container="panel-statistics"></div>
          <div data-mobile-container="banner3"></div>
          {isAuthenticated && <div data-mobile-container="my-statistic"></div>}
          <div data-mobile-container="panel-news"></div>
          <div data-mobile-container="baner-right2"></div>
          <div data-mobile-container="panel-ads"></div>
          <div data-mobile-container="panel-forum"></div>
          <div data-mobile-container="panel-rating"></div>
          <div data-mobile-container="baner-right1" className="banner-container"></div>
          <div data-mobile-container="panel-blacklist"></div>
          <div data-mobile-container="panel-calc"></div>
          <div data-mobile-container="panel-info"></div>
          <div data-mobile-container="panel-ads-sidebar"></div>
          <div data-mobile-container="banner4"></div>
          <div data-mobile-container="panel-table"></div>
          <div data-mobile-container="panel-table-int"></div>
        </div>

        <div className="row row-custom-main">
          <div className="col-sm-8 col-md-7">
            {isAuthenticated && <MyStatistics dataMoveForMobile={'my-statistic'} />}
            <RatingShort dataMoveForMobile={'panel-rating'} />
            <Banner dataMoveForMobile={'baner-right1'} bannerId={520} />
            <BlacklistShort dataMoveForMobile={'panel-blacklist'} />
          </div>
          <div className="col-sm-8 col-md-9">
            <News dataMoveForMobile={'panel-news'} />
            <Banner dataMoveForMobile={'baner-right2'} bannerId={521} />
            <Ads dataMoveForMobile={'panel-ads'} />
            <Forum dataMoveForMobile={'panel-forum'} />
            <Banner bannerId={522} dataMoveForMobile={'banner4'} />
            <Widget
              title={t('summary.table.title')}
              linksTo={{ title: '/summary-table' }}
              dataMoveForMobile={'panel-table'}
            >
              <Table entity="intranational" isWidget />
            </Widget>
            <Table entity="international" dataMoveForMobile={'panel-table-int'} isWidget />
          </div>
        </div>
      </div>
      <div className="col-md-4">
        {isAuthenticated && <AdminNotice />}
        {isAuthenticated && <WelcomeUser dataMoveForMobile={'welcome-user'} />}
        <SiteStat dataMoveForMobile={'panel-statistics'} />
        <Banner bannerId={523} dataMoveForMobile={'banner3'} />
        <CalcDistance dataMoveForMobile={'panel-calc'} />
        <Banner bannerId={524} />
        <div className="banner">
          <iframe
            className="cursmd"
            title="cursmd"
            src="//www.curs.md/ru/curs_provider_box/"
            scrolling="no"
            frameBorder="0"
            marginWidth="0"
            marginHeight="0"
          />
        </div>
        <BuySellShort dataMoveForMobile={'panel-ads-sidebar'} />
        <div className="banner">
          {/*  */}
          <a
            href="https://ec.europa.eu/assets/home/visa-calculator/calculator.htm?lang=ru"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={calc} alt="calculator schengen" className="calc-schengen" />
          </a>
        </div>
      </div>
    </div>
  )
}
