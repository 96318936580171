import { compose } from 'recompose'
import { connect } from 'react-redux'

import Component from './component'
import WithTranslation from '../../globals/components/withTranslation'
import { IReduxStorage } from '../../store/reducers/types'

export default compose(
  WithTranslation,
  connect((state: IReduxStorage) => {
    const profile = state.auth.profile.get('data')

    return {
      firstName: profile.get('firstName'),
      accessStatus: profile.get('accessStatus'),
      accessStatusExpires: profile.get('accessStatusExpires'),
      lastLogin: profile.get('lastLogin'),
    }
  })
)(Component)
