import { connect } from 'react-redux'
import { compose } from 'recompose'
import {
  reduxForm,
  // formValueSelector
} from 'redux-form'

import withTranslation from '../../../globals/components/withTranslation'

import Component from './component'
import { save } from '../../../store/actions/company'

const FORMNAME = 'edit-company'
//const formSelector = formValueSelector(FORMNAME)

const contactInfo = {
  phone1: undefined,
  phone2: undefined,
  fax: undefined,
  email: undefined,
  skype: undefined,
  viber: undefined,
  address: undefined,
}
const initialValues = {
  name: undefined,
  ownershipType: undefined, //'1',
  country: undefined,
  businessType: undefined,
  director: undefined,
  yearStarted: undefined,
  fiscalCode: undefined,
  description: undefined,
  contact: {
    headQuarter: {
      ...contactInfo,
    },
    branch: {
      ...contactInfo,
    },
  },
  documents: {
    registrationCertificate: undefined,
    kindOfActivity: undefined,
    lisenseType: undefined,
  },
}

export default compose(
  withTranslation,
  connect(
    (state, props) => {
      const { itemId } = props.match.params
      const {
        auth: { profile },
      } = state

      return {
        itemId,
        profile: profile.get('data').toJS(),
      }
    },
    {
      save,
    }
  ),
  reduxForm({
    form: FORMNAME,
    initialValues,
    onSubmit: (values, dispatch, props) => {
      const { save, history } = props

      return save({
        companyType: 'branch',
        ...values,
      }).then(response => {
        if (response && response.type === 'COMPANY_SAVED') {
          history.push('/company')
        }
      })
    },
  })
)(Component)
