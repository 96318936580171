import React, { Component } from 'react'
import Banner from '../../../banner'
import fetch from '../../../../components/shared/fetch'
import SingleItem from '../single-item'
import Pagination from '../../../UI/pagination'
import { scrollToElement } from '../../../../store/utils'
import Spinner from '../../../UI/spinner'

import "./styles.scss"

export interface IContentClaimsProps {
  pageSize: number
  activeFilter: string
  t: any
}

export interface IContentClaimsState {
  items: any,
  pageIndex: number,
  isFetching: boolean
  totalItems: number
}

export default class ContentClaims extends Component<IContentClaimsProps, IContentClaimsState> {

  state = {
    items: [],
    pageIndex: 0,
    isFetching: false,
    totalItems: 0
  }

  componentDidMount() {
    const { pageSize } = this.props
    const { pageIndex } = this.state
    this.fetchData(pageIndex, pageSize)
  }

  componentDidUpdate(prevProps) {
    const { pageSize } = this.props
    const { pageIndex } = this.state
    if (prevProps.pageSize !== pageSize) {
      this.fetchData(pageIndex, pageSize)
    }
  }

  fetchData = (pageIndex, pageSize) => {
    this.setState({ isFetching: true })
    const body = JSON.stringify({
      claimType: 0,
      pageIndex,
      pageSize
    })

    fetch(`/claim`, {
      method: 'POST',
      body,
    }).then(res => {

      this.setState({
        items: res.d.items,
        totalItems: res.d.total,
        isFetching: false
      })
    })
  }


  renderItems = (items) => {
    const itemType = "claims"
    const content = items.map((itemData, index) => {
      return <SingleItem
        key={index}
        {...{ itemType }}
        {...{ itemData }}
      />
    })

    return content
  }

  paginationClick = page => {
    const { pageSize } = this.props
    this.setState({ pageIndex: page }, () => {
      this.fetchData(page, pageSize)
      scrollToElement("latter-reviews-claims")
    })
  }

  render() {
    const { totalItems, pageIndex, items, isFetching } = this.state
    const { pageSize } = this.props

    if(isFetching){
      return <Spinner/>
    }

    return (
      <div className="tabs-content" id="content-claims">

        <div className="panel panel-message-main">
          <div className="panel-content">
            <div className="panel-content-row panel-content-row-message">
              {this.renderItems(items)}
            </div>
          </div>
        </div>
        <Banner bannerId={587} />
        <div className="pagination-block">
          <Pagination
            onClick={this.paginationClick}
            total={totalItems}
            {...{ pageIndex }}
            {...{ pageSize }}
          />
        </div>
      </div>
    )
  }
}
