import React, { FunctionComponent, useState } from 'react'
import { Link } from 'react-router-dom'

import LoginLink from '../navigation/login-link'
import { IReregistrationModalProps } from './types'

import './style.scss'

const ReregistrationModal: FunctionComponent<IReregistrationModalProps> = props => {
  const { t, setApproveInfo, clearModal } = props
  const [doNotShowAgain, setDoNotShowAgain] = useState(false)

  const closeModal = () => {
    setApproveInfo()
    clearModal()
  }
  const onSetDoNotShowAgain = (checked) => {
    setDoNotShowAgain(checked)
    if (checked) {
      localStorage.setItem('doNotShowAgain', '1')
    } else {
      localStorage.removeItem('doNotShowAgain')
    }
  }


  return (
    <div className="reregistration-modal">
      <div className="header">
        <div className="close" onClick={closeModal}>
          <i className="fa fa-times" />
        </div>
        <h2>{t('important.to.read') + '!'}</h2>
      </div>
      <div className="text">
        <span>{t('reregistration.info1.1')}</span>
        <LoginLink>
          <b onClick={setApproveInfo}>{t('reregistration.info1.2')}</b>
        </LoginLink>
        <span>{` `}{t('reregistration.info1.3')}</span>
      </div>
      <div className="text">
        {t('reregistration.info2.1')}
        <Link to="/auth/registration" onClick={clearModal}>
          <u onClick={closeModal}>{t('reregistration.info2.2')}</u>
        </Link>
        <span
          dangerouslySetInnerHTML={{
            __html: t('reregistration.info2.3')
          }}
        />
      </div>
      <div className="accept-panel">
        <button type="button" className="button button-orange" onClick={closeModal}>
          {t('continue')}
        </button>
        <div className="input-container" style={{ marginTop: 10 }}>
          <input
            type="checkbox"
            id="ch245"
            checked={doNotShowAgain}
            onChange={e =>
              onSetDoNotShowAgain(e.target.checked)
            }
          />

          <label htmlFor="ch245">
            {t('do.not.show.this.message')}
          </label>
        </div>
      </div>
    </div>
  )
}

export default ReregistrationModal
