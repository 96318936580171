import React, { Component, Fragment } from 'react'

import Widget from '../../shared/components/widget'
import History from './company-history'
import fetch from '../../shared/fetch'
import { getLanguageLowercaseName } from "../../../store/utils"
import { ICompanyLogsProps, ICompanyLogsState } from './types'

export default class extends Component<ICompanyLogsProps, ICompanyLogsState> {
  constructor(props) {
    super(props)
    const { companyId } = props
    this.state = {
      actions: [],
      pageIndex: 0,
      companyId: companyId,
      userFound: false,
    }
  }

  componentDidMount() {
    this.refreshCompanyLogs()
  }

  refreshCompanyLogs = () => {
    const { langId } = this.props
    const { pageIndex, companyId } = this.state
    const lang = getLanguageLowercaseName(langId)
    const paramsString = `/company/${companyId}/logs/${lang}/${pageIndex}/10`
          
    fetch(`${paramsString}`).then(res => {
      this.setState({ actions: res.data })
    })
  }

  refreshHistory = pageIndex => {
    this.setState({ pageIndex }, () => {
      this.refreshCompanyLogs()
    })
  }

  render() {
    const { t,  } = this.props
    const { actions, pageIndex, companyId } = this.state

    const baseProps = {
      pageIndex,
      pageSize: 10,
      totalItems: actions[0]?.total || 0,
      refresh: this.refreshHistory,
      companyId
    }

    return (
      <Fragment>
         <Widget title={t('company.history')}>
            <History {...baseProps} actions={actions} />
          </Widget>
      </Fragment>
    )
  }
}
