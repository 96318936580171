import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Field } from 'redux-form'
import { confirmAlert } from 'react-confirm-alert'
import moment from 'moment'
import classNames from 'classnames'
import 'react-confirm-alert/src/react-confirm-alert.css'
import addDays from 'date-fns/add_days'
import WidgetHeader from '../../../shared/components/widget-header'
import Dropdown from '../../../UI/dropdown'
import Pagination from '../../../UI/pagination'
import Bullet from '../../../UI/bullet'
import fetch from '../../../shared/fetch'
import { Props, State } from './types'
import TooltipWrapper from '../../../UI/popover-wrapper'

import './style.scss'

const STYLE_BASE: string = 'my-ads_'

export default class extends Component<Props, State> {
  state = {
    pagination: {
      total: 0,
      pageIndex: 0,
      pageSize: 10,
    },
    ads: [],
    userActiveAds: 0,
    userUnactiveAds: 0,
    userAllAds: 0,
    userTotal: 0,
    active: null,
    showConfirm: undefined,
    sortType: 'date',
  }

  componentDidMount() {
    const { onCanAdd } = this.props
    const { active, sortType } = this.state
    onCanAdd("ads")
    this.fetchMyAds(0, 10, 0, sortType, 'userUnactiveAds')
    this.fetchMyAds(0, 10, 1, sortType, 'userActiveAds')
    this.fetchMyAds(0, 10, active, sortType, 'userAllAds')
  }

  setTotalCounters = (totalState, ads, pageIndex) => {
    const { response } = ads
    const firstAd = response[0]
    switch (totalState) {
      case 'userAllAds':
        this.setState({ userAllAds: firstAd?.total || 0, userTotal: firstAd?.total || 0 })
        break
      case 'userUnactiveAds':
        this.setState({ userUnactiveAds: firstAd?.total || 0, userTotal: firstAd?.total || 0 })
        break
      case 'userActiveAds':
        this.setState({ userActiveAds: firstAd?.total || 0, userTotal: firstAd?.total || 0 })
        break

      default:
        break
    }
    this.setState(prevState => ({
      ads: response,
      pagination: {
        ...prevState.pagination,
        pageIndex,
        total: firstAd ? firstAd?.total : 0,
      },
    }))
  }

  fetchMyAds = (
    pageIndex: number,
    pageSize: number,
    active?: number,
    sortType?: string,
    totalState?: string
  ) => {
    fetch(`/announcement/my/${pageIndex}/${pageSize}/${active}/${sortType}`).then(ads => {
      ads && this.setTotalCounters(totalState, ads, pageIndex)
    })
  }

  changePage = newPageIndex => {
    const {
      pagination: { pageSize },
      active,
      sortType,
    } = this.state
    this.fetchMyAds(newPageIndex, pageSize, active, sortType)
  }

  sortTypeChange = sortType => {
    const {
      pagination: { pageSize },
      active,
    } = this.state
    this.setState({ sortType })
    this.fetchMyAds(0, pageSize, active, sortType)
  }

  changeActivity = (active?: number) => {
    const {
      pagination: { pageIndex, pageSize },
      sortType,
    } = this.state
    this.setState({ active })
    this.fetchMyAds(pageIndex, pageSize, active, sortType)
  }

  deleteItem = (itemId: number, itemIndex: number): void => {
    const { t } = this.props
    // const { userActiveAds, userUnactiveAds, ads } = this.state
    const {
      pagination: { pageSize },
      active,
      sortType,
    } = this.state
    this.setState({ showConfirm: true })
    confirmAlert({
      title: t('ads.approve.delete.title'),
      message: t('ads.approve.delete.text'),
      buttons: [
        {
          label: t('yes'),
          onClick: () => {
            fetch(`/announcement/item/${itemId}`, { method: 'DELETE' }).then(_ => {
              this.fetchMyAds(0, pageSize, active, sortType)
              this.hideConfirm()
              this.forceUpdate()
            })
          },
        },
        {
          label: t('no'),
          onClick: this.hideConfirm,
        },
      ],
      onClickOutside: this.hideConfirm,
      onKeypressEscape: this.hideConfirm,
    })
  }
  hideConfirm = () => {
    this.setState({ showConfirm: undefined })
  }

  singleAdsItemRedirect = id => this.props.history.push(`/ads/item/${id}`)

  render() {
    const { pagination, ads, userActiveAds, userUnactiveAds, userAllAds, active } = this.state
    const { t, canAdd } = this.props

    const sortValues = [
      { name: t('ads.filter.by.date'), value: 'date' },
      { name: t('ads.filter.by.views.desc'), value: 'views-desc' },
      { name: t('ads.filter.by.views.asc'), value: 'views-asc' },
    ]

    return (
      <div className={STYLE_BASE}>
        <WidgetHeader title={t('ads.my')} className={`${STYLE_BASE}header`}>
          <div>
            {canAdd ? (
              <Link to="/ads/add-ads" className="add-ads-link">
                <span className="button button-orange" id="add-ads-button">
                  <i className="fa fa-plus"></i>
                </span>
                <span className="link-text">{t('ads.addAds')}</span>
              </Link>
            ) : (
              <div className="add-container">
                <span className="link-text">{t('ads.addAds')}</span>
                <TooltipWrapper text={t('ads.limit')} hover>
                  <i className="fa fa-question-circle m-l-5" />
                </TooltipWrapper>
              </div>
            )}
          </div>
        </WidgetHeader>
        <div className="bordered-filters">
          <div className="filter">
            <span
              className={classNames('all', active === null && 'selected')}
              onClick={() => this.changeActivity(null)}
            >
              {t('all')} ({userAllAds})
            </span>{' '}
            |
            <span
              className={classNames('active', active === 1 && 'selected')}
              onClick={() => this.changeActivity(1)}
            >
              {` ${t('active')} (${userActiveAds})`}
            </span>{' '}
            |
            <span
              className={classNames('unactive', active === 0 && 'selected')}
              onClick={() => this.changeActivity(0)}
            >
              {` ${t('unactive')} (${userUnactiveAds})`}
            </span>
          </div>
          <div className="label-dropdown-container">
            <div className="sort-type-container">
              <Field
                name="sortType"
                component={Dropdown}
                items={sortValues}
                placeholder={t('sort')}
                onChange={this.sortTypeChange}
                label={t('sort')}
              />
            </div>
          </div>
        </div>
        <table className="table table-ads table-link table-ads-lg table-my-ads">
          <tbody className="table-ads-body">
            {ads.map((adsItem, index) => {
              const adStillAvailable = addDays(adsItem.date, adsItem.placement_period) > new Date()
              const isActive = adsItem.active === 1 && adStillAvailable

              return (
                <tr key={adsItem.id}>
                  <td className="td-bullet">
                    <Bullet
                      isActive={isActive}
                      id={adsItem.id}
                      tooltipContent={isActive ? t('active.single') : t('unactive.single')}
                    />
                  </td>
                  <td className="td-date" onClick={() => this.singleAdsItemRedirect(adsItem.id)}>
                    {moment(adsItem.date).format('DD.MM.YY')}
                  </td>
                  <td className="td-info" onClick={() => this.singleAdsItemRedirect(adsItem.id)}>
                    {adsItem.title}
                  </td>
                  <td className="td-views" onClick={() => this.singleAdsItemRedirect(adsItem.id)}>
                    <span>
                      <i className="fa fa-eye"></i>
                      <span>{adsItem.views}</span>
                    </span>
                  </td>
                  <td className="td-edit">
                    <Link to={`/ads/edit/${adsItem.id}`}>
                      <i className="fa fa-pencil"></i>
                    </Link>
                  </td>
                  <td
                    className="td-remove"
                    onClick={adsItem.active === 1 ? () => this.deleteItem(adsItem.id, index) : null}
                  >
                    <i
                      className="fa fa-trash"
                      style={{ color: (adsItem.active !== 1 || !adStillAvailable) && '#525354' }}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <Pagination onClick={this.changePage} {...pagination} />
      </div>
    )
  }
}
