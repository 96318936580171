import React, { Component } from 'react'

import Timer from '../../../timer'

import {
  LOGIN_PERMISSION_STATUS_ALLOWED,
  LOGIN_PERMISSION_STATUS_DENIED,
} from '../../../../store/constants'

export default class extends Component {
  allow = () => {
    const { onAllow, permissionStatus } = this.props
    if (permissionStatus === LOGIN_PERMISSION_STATUS_ALLOWED) {
      onAllow && onAllow()
    }
  }

  renderTimer = () => {
    const { autoResolveIn, t } = this.props

    return (
      <div className="guard-body">
        <h2>{t('login.used.other.device')}</h2>
        <br />
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {t('via')}
          <Timer autoResolveIn={autoResolveIn} onFinish={this.allow} />
          {t('seconds')}
        </div>
        {t('login.from.device.will.allow')}
      </div>
    )
  }
  renderAllowed = () => {
    const { t } = this.props
    return (
      <div className="guard-body">
        <h2>{t('get.approve')}</h2>

        <br />
        <br />
        <br />

        <button type="button" className="button button-orange button-centered" onClick={this.allow}>
          {t('continue.login')}
        </button>
      </div>
    )
  }
  renderDenied = () => {
    const { onDeny, t } = this.props
    return (
      <div className="guard-body">
        <h2>{t('login.not.allow')}</h2>

        <br />
        <br />
        <br />

        <button type="button" className="button button-orange button-centered" onClick={onDeny}>
          {t('button.close')}
        </button>
      </div>
    )
  }

  render() {
    const { permissionStatus } = this.props
    switch (permissionStatus) {
      case LOGIN_PERMISSION_STATUS_ALLOWED:
        return this.renderAllowed()
      case LOGIN_PERMISSION_STATUS_DENIED:
        return this.renderDenied()
      default:
        return this.renderTimer()
    }
  }
}
