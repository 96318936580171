export const eu8 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Transport maritim şi fluvial</h4><p></p>

<p style="font-weight:500;">Asigurarea respectării și a aplicării Convenției privind munca în domeniul maritim de către țările UE</p><br>

<p style="text-indent:2em;">Această directivă a Uniunii Europene (UE) definește responsabilitățile statelor de pavilion (țările în care sunt înregistrate navele) de aplicare a Convenției privind munca în domeniul
 maritim (MLC), încheiată în 2006 de Organizația Internațională a Muncii (OIM).</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Directiva 2013/54/UE a Parlamentului European și a Consiliului din 20 noiembrie 2013 privind anumite responsabilități ale statului de pavilion referitoare la respectarea și asigurarea
 aplicării Convenției din 2006 privind munca în domeniul maritim.</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Obiectivul directivei este de a garanta respectarea de către țările UE a obligațiilor lor în calitate de state de pavilion în ceea ce privește punerea în aplicare de către navele care
 arborează pavilionul lor a părților relevante din Directiva 2009/13/CE, care a încorporat în legislația europeană o parte importantă a MLC 2006.</p><br>

<p style="text-indent:2em;">MLC 2006 stabilește standarde globale minime în vederea garantării dreptului la condiții de viață și de muncă decente pentru toți navigatorii, indiferent de naționalitatea lor și de
 pavilionul navelor la bordul cărora își desfășoară activitatea. De asemenea, directiva urmărește limitarea dumpingului social pentru a asigura o concurență echitabilă pentru armatorii
 care respectă drepturile navigatorilor.</p>

<p style="text-indent:2em;"></p><h6>Principalele aspecte ale noii directive sunt:</h6><p></p>

<p style="font-weight:500;">1.Monitorizarea respectării</p><br>

<p style="text-indent:2em;">Țările UE au obligația de a introduce mecanisme eficace și adecvate de asigurare a aplicării și de monitorizare, inclusiv inspecții la anumite intervale, pentru a garanta navigatorilor
 de la bordul navelor care arborează pavilionul acestora condiții de viață și de muncă care sunt și vor continua să fie conforme cerințelor din MLC 2006.</p><br>

<p style="text-indent:2em;">Aceste mecanisme pot fi adaptate pentru a ține cont de condițiile specifice referitoare la navele cu tonaj brut mai mic de 200 de tone care nu efectuează curse internaționale. Deși rămân
 pe deplin responsabile pentru inspecție, țările UE pot autoriza organizații cu know-how concret în domeniu (organizații recunoscute) să desfășoare asemenea inspecții.</p><br>

<p style="font-weight:500;">2.Inspectorii</p><br>

<p style="text-indent:2em;">Personalul autorizat să realizeze inspecții și responsabil cu verificarea punerii corecte în aplicare trebuie să aibă competența profesională și independența necesare.</p><br>

<p style="text-indent:2em;">În cazul în care nu sunt respectate standardele din MLC 2006, inspectorii pot interzice unei nave să părăsească portul până la realizarea acțiunilor necesare.</p><br>

<p style="font-weight:500;">3.Procedurile de reclamații</p><br>

<p style="text-indent:2em;">Fiecare țară a UE trebuie să garanteze existența unor proceduri de reclamații la bord adecvate. Personalul însărcinat cu soluționarea plângerilor sau informat cu privire la acestea trebuie să
 trateze drept confidențială sursa oricărei reclamații sau plângeri.</p><br>

<p style="text-indent:2em;">Responsabilitățile statelor de port cu privire la asigurarea aplicării MLC 2006 sunt reglementate de 
Directiva 2013/38/UE, adoptată în 2013.</p><br>

<p style="font-weight:500;">Obiective şi organisme</p><br>

<p style="font-weight:500;">Centura albastră: un spațiu unic de transport maritim pentru UE</p><br>

<p style="text-indent:2em;">Simplificarea formalităților vamale pentru bunurile expediate în UE și pentru bunurile provenite din UE care tranzitează porturi din afara UE este menită să impulsioneze sectorul
 transporturilor maritime.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Comunicare a Comisiei: Centura albastră, un spațiu unic de transport maritim [COM(2013) 510 final din 8.7.2013 - nepublicată în Jurnalul Oficial]</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Navele transportă 75 % din mărfurile de comerț exterior al Uniunii Europene (UE) și 37 % din mărfurile de comerț interior. În comparație cu alte moduri de transport, transportul maritim
 se caracterizează prin costuri mai mici și un impact redus asupra mediului. Transportul maritim din UE s-a confruntat însă cu un handicap: se consideră că navele care părăsesc apele teritoriale
 ale unui stat membru (la 12 mile de la țărm) au părăsit UE și deci teritoriul vamal al UE. Aceasta înseamnă că bunurile trebuie să treacă prin formalități vamale atât la plecare, cât și la sosirea
 într-un port din UE, ceea ce cauzează cheltuieli și întârzieri.</p><br>

<p style="font-weight:500;">Simplificare</p><br>

<p style="text-indent:2em;">Situația se schimbă prin așa-numitul pachet privind Centura albastră, care vizează reducerea costurilor și facilitarea schimburilor comerciale prin simplificarea formalităților vamale pentru
 bunurile transportate pe cale maritimă. Ținând cont de necesitatea de a-și proteja consumatorii și companiile împotriva produselor ilegale care pătrund în UE, pachetul tratează transportul
 maritim de bunuri atât între porturi din UE, cât și cu nave care vizitează porturi din afara UE pe traseul lor între porturi ale UE (de exemplu, o navă din Cipru care merge la Calais ar putea
 descărca și ridica marfă în porturi din afara UE, cum ar fi Tunis).</p><br>

<p style="font-weight:500;">Transportul maritim de bunuri din UE între porturi din UE</p><br>

<p style="text-indent:2em;">Sistemul de servicii de transport maritim regulat simplificat, care se aplică deja în cazul navelor care transportă bunuri din UE și care fac escală în mod regulat numai în porturi din UE, a
 intrat în vigoare în 1.3.2014. Acesta permite păstrarea statutului UE al bunurilor chiar și când acestea părăsesc apele teritoriale ale UE în călătoria dintre porturi ale statelor membre. Acest
 statut este acordat la cerere și numai navelor care efectuează curse exclusiv între porturi ale statelor membre ale UE. Procedura de punere în aplicare s-a redus de la 45 la 15 zile, iar procedura
 de adăugare a unor porturi noi la serviciu a devenit mai flexibilă.</p><br>

<p style="font-weight:500;">Transportul de bunuri din UE și din afara UE între porturi din UE și cele care tranzitează porturi din afara UE</p><br>

<p style="text-indent:2em;">Din cauza faptului că majoritatea traficului containerizat conține marfă mixtă, este necesară introducerea unui e-Manifest - o declarație electronică privind marfa - pentru a face distincția între
 bunurile din UE și cele internaționale (adică din afara UE). Acest lucru ar trebui să ducă la controale mai eficiente: bunurile din UE ar fi descărcate rapid, iar cele din afara UE ar fi supuse
 formalităților vamale.</p><br>

<p style="text-indent:2em;">Propunerile privind Centura albastră vin în completarea Analizei politicilor portuare, adoptate în mai 2013, care vizează creșterea competitivității porturilor maritime și eliberarea potențialului
 de creștere al acestora.</p><br>

<p style="font-weight:500;">Siguranța maritimă: Agenția Europeană pentru Siguranță Maritimă</p><br>

<p style="text-indent:2em;">Agenția Europeană pentru Siguranță Maritimă (EMSA) are ca obiectiv asigurarea unui nivel ridicat, unitar și eficient de siguranță și securitate maritimă în Uniunea Europeană (UE). De asemenea, aceasta
 depune eforturi pentru prevenirea poluării și pentru combaterea poluării cauzate de navele maritime sau de instalațiile de petrol și gaze.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Regulamentul (CE) nr. 1406/2002 al Parlamentului European și al Consiliului din 27 iunie 2002 de instituire a unei Agenții Europene pentru Siguranță Maritimă</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Agenția Europeană pentru Siguranță Maritimă (EMSA) are ca obiectiv asigurarea unui nivel ridicat, unitar și eficient de siguranță și securitate maritimă în Uniunea Europeană (UE). De asemenea, aceasta
 depune eforturi pentru prevenirea poluării și pentru combaterea poluării cauzate de navele maritime sau de instalațiile de petrol și gaze.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Prezentul regulament instituie EMSA, cu sediul la Lisabona. Agenția acordă asistență tehnică și sprijin Comisiei Europene (CE) și țărilor UE cu privire la dezvoltarea, punerea în aplicare și evaluarea
 legislației UE în materie de siguranță, de securitate și de poluare maritimă.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p>EMSA are atribuții principalesprijin și desprijin .</p><br>

<p style="font-weight:500;">Atribuțiile principale sunt:</p><br>

<p>acordarea de sprijin la lucrările pregătitoare pentru actualizarea și dezvoltarea legislației UE relevante;</p><br>

<p>vizite și inspecții în țările UE în legătură cu punerea eficientă în aplicare a actelor legislative relevante și obligatorii ale UE;</p><br>

<p>activități de formare profesională și asistență tehnică pentru administrațiile naționale;</p><br>

<p style="text-indent:2em;">sprijinirea acțiunilor de combatere a poluării în situațiile de poluare cauzate de navele maritime și de instalațiile de petrol și gaze (EMSA oferă asistență operațională doar la cererea țării afectate).</p><br>

<p style="text-indent:2em;">De asemenea, EMSA operează Centrul european de date pentru identificarea și urmărirea navelor la mare distanță și Sistemul UE de schimb de informații maritime (SafeSeaNet). Agenția poate oferi,
de asemenea, sprijin operațional cu privire la investigații în situațiile în care au existat decese sau vătămări grave.</p><br>

<p style="font-weight:500;">Atribuțiile de sprijin</p><br>

<p style="text-indent:2em;">EMSA își asumă aceste sarcini doar dacă acestea creează valoare adăugată demonstrată și evită duplicarea eforturilor și nu încalcă drepturile și obligațiile țărilor UE. Aceste atribuții sunt
 legate de aspecte de mediu, de programul de observare a Pământului GMES (acum Copernicus) și de căile navigabile interioare.</p><br>

<p style="font-weight:500;">Vizitele și inspecțiile EMSA</p><br>

<p style="text-indent:2em;">EMSA vizitează țările pentru a asista CE și administrațiile naționale la verificarea punerii eficiente în aplicare a normelor UE și pentru a garanta un nivel ridicat și eficient de siguranță.
Agenția efectuează inspecții la societățile de clasificare, precum și în țări terțe, cu privire la formarea și la certificarea navigatorilor.</p><br>

<p style="font-weight:500;">Structura</p><br>

<p style="text-indent:2em;">EMSA este un organism al UE și are personalitate juridică. Personalul agenției este format din funcționari recrutați de agenție și oficiali ai UE și din funcționari ai țărilor UE numiți sau
detașați cu titlu temporar pentru agenție. Agenția este condusă de un director executiv care este total independent în îndeplinirea atribuțiilor sale.</p><br>

<p style="text-indent:2em;">Consiliul de administrație al agenției este compus din reprezentanți ai CE și ai fiecărei țări a UE și toți au drept de vot. Consiliul include și reprezentanți ai Norvegiei și ai Islandei,
 precum și profesioniști din patru sectoare maritime, fără drept de vot. Mandatul are o durată de cinci ani și poate fi reînnoit o singură dată.</p><br>

<p style="font-weight:500;"></p><p style="font-weight:500;">Bugetul</p><br>

<p style="text-indent:2em;">Regulamentul (UE) nr. 911/2014 prevede un plan de finanțare multianual de 160,5 milioane EUR pentru intervențiile EMSA în scopul combaterii activităților de poluare pentru perioada 2014-2020.
 Bugetul general al EMSA cuprinde o contribuție principală de la bugetul UE și contribuții suplimentare din țările din afara UE care se implică în activitățile agenției. De asemenea, agenția
 poate aplica taxe pentru publicații, formare profesională și orice alte servicii pe care le furnizează.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p style="text-indent:2em;">Două accidente (Erika în 1999 și Prestige în 2002) au dus la deversări de petrol în apele europene. Ambele au cauzat daune ecologice și economice grave coastelor franceze și spaniole. Acest fapt
 a determinat instituirea EMSA în 2003 pentru a asigura că Europa este pregătită pentru deversări de petrol la scară largă.</p><br>

<p style="font-weight:500;">Piața internă</p><br>

<p style="font-weight:500;">Transportul maritim — prestarea de servicii, concurența, practici tarifare neloiale și accesul la traficul transoceanic</p><br>

<p style="font-weight:500;">CARE ESTE OBIECTIVUL REGULAMENTELOR?</p><br>

<p style="text-indent:2em;">Obiectivul regulamentelor este organizarea transportului maritim în conformitate cu principiile de bază ale legislației UE privind prestarea de servicii, concurența și accesul liber la
 piață în transportul maritim.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Libertatea de a presta servicii</p>

<p style="text-indent:2em;"></p><h6>Regulamentul nr. 4055/86:</h6><p></p>

<p style="text-indent:2em;">garantează resortisanților țărilor UE (și societăților de transport maritim din afara UE care folosesc nave înregistrate într-o țară a UE și controlate de resortisanți ai UE) dreptul de a
 efectua transport maritim de pasageri și mărfuri între orice port al unei țări a UE și orice port sau instalație offshore a unei alte țări a UE sau din afara UE;</p><br>

<p style="text-indent:2em;">impune eliminarea progresivă sau adaptarearestricțiilor naționale prin care transportul de mărfuri este rezervat navelor care arborează pavilionul național și previne introducerea unor
 restricții noi;</p><br>

<p style="text-indent:2em;">stabilește o procedură pentru cazurile în care companiile maritime din UE nu dispun de o posibilitate efectivă de a participa la transportul de mărfuri înspre și dinspre o anumită țară
 din afara UE;</p><br>

<p>extinde beneficiile regulamentului resortisanților țărilor din afara UE stabiliți în UE.</p><br>

<p style="text-indent:2em;">Regulamentul nr. 3577/92/CEE vizează în mod specific libertatea de a presta servicii de transport maritim în cadrul țărilor UE („cabotaj maritim”*).</p><br>

<p style="font-weight:500;">Practici tarifare neloiale în transportul maritim</p>

<p style="text-indent:2em;"></p><h6>Regulamentul nr. 4057/86:</h6><p></p>

<p style="text-indent:2em;">autorizează UE să aplice taxe compensatorii pentru a proteja armatorii din țările UE împotriva practicilor tarifare neloiale ale armatorilor din țările din afara UE. Aceste taxe compensatorii
 pot fi impuse în urma unei investigații care constată că practicile tarifare neloiale au determinat prejudicii și că interesele UE fac necesară o intervenție;</p><br>

<p style="text-indent:2em;">în ceea ce privește examinarea unui prejudiciu, regulamentul stabilește factorii sau indicatorii relevanți care trebuie luați în considerare, cum ar fi scăderea cotei de piață sau a profitului
 unui armator, sau efectul asupra ocupării forței de muncă;</p><br>

<p>stabilește o procedură pentru depunerea de plângeri, desfășurarea consultărilor și a investigațiilor ulterioare.</p><br>

<p style="font-weight:500;">Accesul liber la comerțul oceanic</p>

<p style="text-indent:2em;"></p><h6>Regulamentul nr. 4058/86:</h6><p></p>

<p style="text-indent:2em;">se aplică atunci când acțiunile unei țări din afara UE, sau demarate prin agenții săi, restricționează accesul liber la transportul de linie, de încărcături în vrac sau alte încărcături efectuate
 de companiile maritime din UE sau de către nave înregistrate într-o țară a UE (cu excepția cazului în care o astfel de acțiune este în conformitate cu Convenția Națiunilor Unite asupra unui cod
 de conduită al conferințelor maritime);</p><br>

<p style="text-indent:2em;">permite o acțiune coordonată a UE în urma unei solicitări transmise Comisiei Europene de o țară a UE. O astfel de acțiune poate consta în proteste diplomatice adresate țărilor din afara UE și
 contramăsuri împotriva companiilor maritime în cauză;</p><br>

<p style="text-indent:2em;">permite luarea unei acțiuni coordonate similare la solicitarea unei alte țări din cadrul Organizației pentru Cooperare și Dezvoltare Economică, cu care s-a încheiat un aranjament reciproc.</p><br>

<p style="font-weight:500;">Norme privind concurența</p><br>

<p style="text-indent:2em;">Normele generale ale UE în materie de concurență stabilite în Regulamentul (CE) nr. 1/2003 se aplică și sectorului transporturilor maritime al UE. Cu toate acestea, potrivit Regulamentului (CE)
 nr. 246/2009 al Consiliului, Comisia poate face excepții pentru anumite tipuri de cooperări între companiile maritime de  linie* (consorții*). Comisia a adoptat, pe cale de consecință, Regulamentul
 (CE) nr. 906/2009, care permite aceste excepții și l-a extins până la 25 aprilie 2020 prin intermediul Regulamentului (UE) nr. 697/2014.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTELE?</p><br>

<p>Regulamentul (CEE) nr. 4055/86 al Consiliului se aplică de la 1 ianuarie 1987.</p><br>

<p>Regulamentele (CEE) nr. 4057/86 și (CEE) 4058/86 ale Consiliului se aplică de la 1 iulie 1987.</p><br>

<p>Regulamentul (CE) nr. 1/2003 al Consiliului se aplică de la 1 mai 2004.</p><br>

<p>Regulamentul (CE) nr. 246/2009 al Consiliului se aplică de la 14 aprilie 2009.</p><br>

<p>Regulamentul (CE) nr. 906/2009 al Comisiei se aplică de la 26 aprilie 2010.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="font-weight:500;">Cabotaj: atunci când o companie care transportă mărfuri, înregistrată într-o țară a UE, efectuează transporturi naţionale într-o altă țară a UE.</p><br>

<p style="text-indent:2em;">Transport maritim de linie: transportul de mărfuri efectuat în mod regulat pe o anumită rută sau pe anumite rute între porturi, conform unor orare și date de navigație publicate
 în prealabil și disponibile, fie și ocazional, oricărui utilizator de transport contra cost.</p><br>

<p style="font-weight:500;">Consorții: un acord (sau serie de acorduri) între doi sau mai mulți transportatori maritimi care asigură servicii maritime internaționale de linie pentru transportul exclusiv de
 mărfuri pe unul sau mai multe traficuri comerciale. Obiectivul acestora este de a stabili o cooperare pentru operarea în comun a unui serviciu de transport maritim, care îmbunătățește
 serviciul oferit în mod individual de către fiecare dintre membrii săi (în absența unui consorțiu).</p><br>

<p style="font-weight:500;">Libertatea de a presta servicii în cadrul UE (comerț maritim)</p><br>

<p style="text-indent:2em;">Obiectivul acestui regulament este de a elimina restricțiile impuse libertății de a presta servicii de transport maritim pe teritoriul Uniunii Europene (UE).</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Regulamentul (CEE) nr. 3577/92 al Consiliului din 7 decembrie 1992 de aplicare a principiului liberei circulații a serviciilor la transporturile maritime în interiorul statelor membre
 (cabotaj maritim).</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">Domeniul de aplicare</p><br>

<p style="text-indent:2em;">Această lege se asigură că, pe teritoriul unei anumite țări UE, companiile maritime sau resortisanții din alte țări comunitare au dreptul să ofere servicii de transport maritim (cunoscute
 sub numele de cabotaj maritim), cu condiția ca acestea să se conformeze tuturor condițiilor de realizare a cabotajului pe teritoriul țării respective. Companiile maritime cu sediul în țări
 din afara UE, dar controlate de resortisanți ai UE, pot, la rândul lor, oferi astfel de servicii.</p><br>

<p style="text-indent:2em;">Regulamentul definește „serviciile de transport maritim pe teritoriul unei țări UE (cabotaj maritim)”, „armatorii comunitari”, „contractul de servicii publice”, „obligațiile legate de
 serviciul public” și „perturbarea gravă a pieței interne a transporturilor”.</p><br>

<p style="font-weight:500;">Normele privind echipajul</p><br>

<p style="text-indent:2em;">În funcție de tipul de serviciu de transport, chestiunile aferente normelor privind echipajul reprezintă responsabilitatea țării UE de înregistrare (stat de pavilion) sau a țării în care
 se prestează serviciul de cabotaj (stat gazdă).</p><br>

<p style="font-weight:500;">Serviciul public</p><br>

<p style="text-indent:2em;">Țările UE pot supune dreptul de a presta servicii de transport pentru a face obiectul obligațiilor legate de serviciul public sau pot încheia contracte de servicii publice în interesul
 menținerii serviciilor de cabotaj adecvate între continent și insulele sale și între insule.</p><br>

<p style="font-weight:500;">Măsurile de salvgardare</p><br>

<p style="text-indent:2em;">În situațiile în care deschiderea pieței la cabotaj duce la probleme (cum ar fi excedentul serios al ofertei în raport cu cererea) care amenință supraviețuirea financiară a companiilor
 maritime, Comisia poate introduce măsuri de salvgardare. Acestea pot include excluderea temporară a zonei vizate din domeniul de aplicare a regulamentului.</p><br>

<p style="font-weight:500;">Nediscriminarea</p><br>

<p style="text-indent:2em;">Persoanele care prestează servicii de transport maritim pe teritoriul unei țări UE diferite de propria lor țară pot proceda astfel temporar, în baza acelorași termeni, în mod similar celor
 aplicați de acea țară propriilor săi resortisanți.</p><br>

<p style="font-weight:500;">Calendarul</p><br>

<p style="text-indent:2em;">Cabotajul maritim a fost liberalizat la 1 ianuarie 1993. Pentru Franța, Italia, Portugalia și Spania, cabotajul continental a fost liberalizat treptat conform unui program specific pentru
 fiecare tip de serviciu de transport. Cabotajul continental-insular și insular-insular pentru aceste țări a fost liberalizat în 1999. Această derogare a fost extinsă pentru Grecia până în
 2004 pentru serviciile de transport de călători programate și pentru nave mai ușoare și serviciile care implică navele cu mai puțin de 650 de tone brute. Croației i s-au conferit derogări
 până la data de 31 decembrie 2016 pentru contractele de servicii publice existente și pentru serviciile de croazieră între porturile croate ale navelor cu mai puțin de 650 de tone brute,
 care sunt rezervate navelor croate până la 31 decembrie 2014.</p><br>

<p style="font-weight:500;">Raportări statistice cu privire la transportul maritim de mărfuri și de pasageri</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva stabilește modalitatea în care țările Uniunii Europene (UE) ar trebuie să elaboreze raportările statistice cu privire la transporturile maritime de mărfuri și pasageri care fac
escală în porturile UE.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Datele</p>

<p style="text-indent:2em;"></p><h6>Aceste date statistice se referă la informații privind mărfurile și pasagerii și informații asupra navei propriu-zise. Anexele la această directivă specifică caracteristicile culegerii
de date, inclusiv:</h6><p></p>

<p>greutatea brută și descrierea mărfurilor;</p><br>

<p>portul de raportare;</p><br>

<p>clasificarea mărfurilor;</p><br>

<p>numărul pasagerilor;</p><br>

<p>tipul și dimensiunea navelor.</p><br>

<p style="font-weight:500;">Navele cu un tonaj brut sub 100 de tone pot fi excluse de la culegerea datelor.</p><br>

<p style="font-weight:500;">Culegerea</p><br>

<p style="text-indent:2em;">Comisia Europeană este responsabilă de elaborarea unei liste cu toate porturile din UE pentru care trebuie să se furnizeze date succinte. În plus, fiecare țară UE trebuie să selecteze din
această listă orice port care: manipulează mai mult de un milion de tone de mărfuri sau, înregistrează deplasări de peste 200 000 de pasageri anual.</p><br>

<p>Aceste porturi selectate sunt ulterior supuse unei culegeri de date mai detaliate.</p><br>

<p style="font-weight:500;">Transmiterea datelor</p>

<p style="text-indent:2em;"></p><h6>Țările UE trebuie să transmită rezultatele culegerii de date Comisiei (Eurostat):</h6><p></p>

<p style="text-indent:2em;">în termen de cinci luni de la sfârșitul perioadei de observație, pentru datele cu periodicitate trimestrială, și
în termen de opt luni pentru datele cu periodicitate anuală.</p><br>

<p style="text-indent:2em;">Țările UE trebuie să notifice Eurostat cu privire la metodele folosite pentru compilarea datelor, precum și să ofere detalii cu privire la orice modificări semnificative aduse metodelor folosite.
Ulterior, Eurostat va publica statisticile corespunzătoare culese.</p><br>

<p style="text-indent:2em;">Directiva a fost modificată prin Regulamentul (UE) nr. 1090/2010 cu scopul de a alinia culegerea de date cu privire la transporturile maritime de mărfuri cu alte moduri de transport.</p><br>

<p style="text-indent:2em;">Decizia 2010/216/UE a modificat anumite caracteristici tehnice cu privire la culegerea de date, inclusiv codurile care se vor utiliza pentru naționalitatea de înregistrare a navelor și
structura acestor tabele de date.</p><br>

<p style="text-indent:2em;">Decizia 2012/186/UE a modificat o parte din variabilele statistice folosite în culegerea de date și a clarificat anumite definiții și clasificarea mărfurilor.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p style="text-indent:2em;">Directiva 2009/42/CE a intrat în vigoare la 26 iunie 2009. Aceasta este o versiune reformată a Directivei 95/64/CE ¾ și modificările ulterioare ale acesteia ¾ care a trebuit integrată în
legislația națională până la 31 decembrie 1996.</p><br>

<p style="font-weight:500;">ACTUL PRINCIPAL</p><br>

<p style="text-indent:2em;">Directiva 2009/42/CE a Parlamentului European și a Consiliului din 6 mai 2009 privind raportările statistice cu privire la transportul maritim de mărfuri și pasageri (reformare) (JO L 141,
 6.6.2009, pp. 29-47)</p><br>

<p style="text-indent:2em;">Modificările succesive aduse Directivei 2009/42/CE au fost integrate în textul de bază. Această versiune consolidată are doar un caracter informativ.</p><br>

<p style="font-weight:500;">Prestarea serviciilor în transporturile maritime</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Libertatea de a presta servicii este unul dintre principiile fundamentale ale Uniunii Europene (UE). Cu toate acestea, în sectorul transporturilor maritime se constată uneori că țările
 din afara UE impun restricții și condiții, cum ar fi partajarea încărcăturii. Acestea sporesc substanțial costurile de transport și pot aduce prejudicii comerțului UE în ansamblu.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul confirmă că libertatea de a presta servicii se aplică transporturilor maritime din Uniunea Europeană și dintre Uniune și țările din afara UE. Actul stabilește condițiile de
 aplicare a acestui principiu. Regulamentul abrogă treptat restricțiile anterioare și împiedică introducerea unor restricții noi.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Companiile de transport maritim pot transporta pasageri sau mărfuri către orice port sau instalație pe mare (cum sunt platformele petroliere sau de gaz) din interiorul sau din afara UE.</p><br>

<p style="text-indent:2em;">De același drept beneficiază și companiile cu sediul în afara UE, cu condiția ca proprietarul acestora să fie cetățean al UE, iar companiile să fie înregistrate în UE.</p><br>

<p style="text-indent:2em;">Restricțiile naționale unilaterale aflate în vigoare înainte de 1 iulie 1986 au făcut obiectul unei eliminări treptate din 31 decembrie 1989 până în 1 ianuarie 1993.</p><br>

<p style="text-indent:2em;">Clauzele de partajare a încărcăturilor cuprinse în acordurile bilaterale dintre țările UE și din afara UE au trebuit eliminate sau reajustate.</p><br>

<p style="text-indent:2em;">Guvernele UE au avut obligația de a transmite rapoarte Comisiei Europene, inițial o dată la șase luni, iar apoi anual, cu privire la modificările aduse acordurilor anterioare.</p><br>

<p style="text-indent:2em;">Țările UE care au întâmpinat probleme la modificarea unui acord existent au avut obligația de a informa Consiliul de Miniștri și Comisia.</p><br>

<p style="text-indent:2em;">Clauzele de partajare a încărcăturilor nu sunt permise în niciun acord viitor cu țările din afara UE, decât în situații excepționale în care lipsa acestor acorduri ar împiedica o companie
din UE să navigheze în scopuri comerciale.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Începând cu 1 ianuarie 1987.</p><br>

<p style="font-weight:500;">Infrastructura portuară</p><br>

<p style="font-weight:500;">Porturile: un motor al creșterii economice</p><br>

<p style="text-indent:2em;">Din punctul de vedere al comerțului, al investițiilor și al locurilor de muncă, porturile sunt esențiale pentru Uniunea Europeană (UE). Strategia Uniunii urmărește să le pregătească
 pentru provocările viitoare: volume de marfă mai mari, portcontainere mai mari și un decalaj de performanță pronunțat între porturile cele mai ineficiente și cele mai eficiente.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Comunicare a Comisiei: Porturile: un motor al creșterii economice [COM(2013) 295 final din 23.5.2013 - nepublicată în Jurnalul Oficial].</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">Importanța porturilor</p><br>

<p style="text-indent:2em;">UE are peste 1 200 de porturi de-a lungul celor peste 70 000 km de coastă. În fiecare an, 74 % din bunurile tranzacționate cu țări din afara UE trec prin porturi, precum și 37 % din
 traficul de marfă intern al UE și 385 de milioane de pasageri.</p><br>

<p style="text-indent:2em;">Porturile au un rol important în economia UE în ceea ce privește locurile de muncă, estimându-se că ele angajează 3 milioane de persoane în mod direct (în industriile portuare și
 legate de porturi) și indirect (logistică/lanț de aprovizionare) într-o varietate amplă de sectoare din cele 22 de țări membre cu regiuni costiere.</p><br>

<p style="font-weight:500;">Provocări</p><br>

<p style="text-indent:2em;">Până în 2030 se așteaptă o creștere cu 50 % a volumelor de marfă manipulate în porturile din UE. Acest lucru înseamnă că va fi necesară crearea unor locuri de muncă noi pentru
 manipularea traficului sporit.</p><br>

<p style="text-indent:2em;">Există o tendință din ce în ce mai pronunțată privind nave mai mari: unele nave pot transporta 18 000 de containere, echivalentul unui șir continuu de camioane care s-ar
 întinde de la Rotterdam la Paris.</p><br>

<p style="text-indent:2em;">Nivelurile structurale de performanțe prezintă diferențe mari: există o concentrare a activității în porturile cele mai eficiente pentru că multe nu dispun de facilitățile necesare sau
 de acces ușor. Acest lucru duce la aglomerare și la creșterea duratei de călătorie, atât pe cale rutieră, cât și pe cale maritimă, ceea ce cauzează o creștere a nivelului de emisii.</p><br>

<p style="font-weight:500;">Abordarea problemelor</p>

<p style="text-indent:2em;"></p><h6>Pentru a garanta că porturile vor dinamiza economia UE în deceniile următoare, strategia susține acțiuni pentru:</h6><p></p>

<p style="text-indent:2em;">conectarea porturilor la rețeaua transeuropeană: noile orientări privind TEN-T vor face posibile o planificare mai integrată a infrastructurii, strategii de investiții consecvente
 și o finanțare din partea UE.</p><br> 

<p style="text-indent:2em;">Mecanismul Conectarea Europei și fondurile structurale vor urmări să asigure conectarea porturilor cu hinterlandul lor și cu rețeaua de transport mai largă;</p><br>

<p style="text-indent:2em;">atragerea de investiții și modernizarea serviciilor portuare: s-au propus noi norme în UE pentru sporirea transparenței finanțării sectorului public, pentru asigurarea unei concurențe
 echitabile între porturi și atragerea unor investitori privați. Propunerea va reduce incertitudinile legislative pentru operatorii portuari prin introducerea unor norme comune pentru
 un acces deschis și nediscriminatoriu la piața serviciilor portuare.</p><br>

<p style="text-indent:2em;">De asemenea, se pune accent pe dialogul social constructiv în sectorul porturilor și pe importanța de a îmbunătăți profilul de mediu al porturilor și de a acorda un rol central
 inovării în sector.</p><br>

<p style="text-indent:2em;">Strategia este însoțită de o propunere de lege menită să abordeze aspectele-cheie legate de accesul la piața serviciilor portuare și de transparența și autonomia financiară
 a porturilor.</p><br>

<p style="font-weight:500;">Porturile: transparența financiară și furnizarea serviciilor acestora</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Acesta este primul act legislativ al UE care se referă anume la porturi. Regulamentul stabilește norme privind transparența financiară, furnizarea de servicii portuare și taxele
 de utilizare a infrastructurii.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Actul legislativ se aplică următoarelor:</h6><p></p>

<p>buncheraj</p><br>

<p>manipularea mărfurilor

</p><p>amarare</p><br>

<p>servicii pentru pasageri</p><br>

<p>colectarea deșeurilor și a reziduurilor de încărcătură de la nave</p><br>

<p>piloții care conduc navele pentru a asigura navigarea în condiții de siguranță în cadrul și în jurul portului
remorcare*.</p>

<p style="text-indent:2em;"></p><h6>Administratorii portuari:</h6><p></p>

<p style="text-indent:2em;">pot solicita ca furnizorii de servicii să îndeplinească cerințe minime, precum calificări profesionale, echipamente adecvate și resurse financiare suficiente;</p><br>

<p style="text-indent:2em;">trebuie să asigure faptul că aceste cerințe minime sunt transparente, obiective, nediscriminatorii, proporționale și relevante pentru serviciile furnizate;</p><br>

<p style="text-indent:2em;">pot limita numărul de furnizori de servicii pe baza anumitor motive, în special dacă zona în cauză este rezervată pentru o altă utilizare;</p><br>

<p style="text-indent:2em;">trebuie să le acorde furnizorilor de servicii potențiali ocazia de a fi ascultați, publicând eventualele restricții planificate cu cel puțin trei luni înainte
 de luarea unei decizii;</p><br>

<p style="text-indent:2em;">pot decide să furnizeze serviciul ei înșiși sau prin intermediul unei companii sau organizații care se află sub controlul acestora ca și cum ar fi un departament propriu.</p><br>

<p style="text-indent:2em;">Dacă se aplică o limitare, va urma o procedură de selecție care trebuie să fie deschisă tuturor părților interesate, nediscriminatorie și transparentă.</p>

<p style="text-indent:2em;"></p><h6>Țările UE:</h6><p></p>

<p style="text-indent:2em;">pot impune obligații de serviciu public operatorilor portuari, pentru a asigura disponibilitatea neîntreruptă și convenabilitatea prețului serviciilor, de exemplu,
 sau din motive de siguranță, de securitate și de mediu;</p><br>

<p style="text-indent:2em;">trebuie să asigure perceperea unei taxe de infrastructură portuară – fără ca aceasta să împiedice furnizorii de servicii să aplice propriile taxe;</p><br>

<p>pot scuti porturile din regiunile ultraperiferice* de la aplicarea legislației;</p><br>

<p>trebuie să prevadă proceduri de tratare a plângerilor rezultate și să stabilească sancțiuni pentru încălcarea legislației.</p>

<p style="text-indent:2em;"></p><h6>Furnizorii de servicii portuare:</h6><p></p>

<p style="text-indent:2em;">trebuie să se asigure că lucrătorii beneficiază de formarea necesară, în special în ceea ce privește sănătatea și siguranța, pentru desfășurarea activității lor;</p><br>

<p>trebuie să se asigure că formarea este actualizată periodic, pentru a corespunde evoluțiilor tehnologice.</p>

<p style="text-indent:2em;"></p><h6>Relația financiară între autoritățile naționale și administratorii portuari trebuie să fie transparentă și să prezinte în mod clar:</h6><p></p>

<p>fondurile publice puse la dispoziția organelor de administrare a portului în mod direct sau prin intermediari;</p><br>

<p>utilizarea dată fondurilor;</p><br>

<p>taxele percepute de la un operator intern care îndeplinește obligații de serviciu public.</p>

<p style="text-indent:2em;"></p><h6>Regulamentul:</h6><p></p>

<p style="text-indent:2em;">nu afectează normele naționale în domeniul social și al muncii și nici legislația UE în materie de achiziții publice sau de contracte de concesiune;</p><br>

<p style="text-indent:2em;">nu se aplică contractelor de servicii portuare încheiate înainte de 15 februarie 2017 și limitate în timp (contractele încheiate înainte de 15 februarie 2017 care nu sunt
limitate în timp trebuie să se conformeze până la 1 iulie 2025).</p><br>

<p style="text-indent:2em;">Comisia Europeană trebuie să transmită Parlamentului European și Consiliului, până cel târziu la 24 martie 2023, o evaluare a aplicării legislației.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Se aplică începând cu 24 martie 2019.</p><br>

<p>Instalațiile portuare pentru deșeurile generate de nave și reziduurile de încărcătură</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva îmbunătățește disponibilitatea și utilizarea instalațiilor portuare de preluare a deșeurilor provenite din exploatarea navelor și a reziduurilor de încărcătură.</p><br>

<p>De asemenea, actul legislativ stabilește un regim de aplicare, inclusiv un sistem de inspecții și de schimb de informații.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Directiva vizează:</h6><p></p>

<p style="text-indent:2em;">toate navele, indiferent de pavilionul lor, care fac escală într-un port dintr-o țară a UE, cu excepția navelor de război și a navelor care aparțin sau sunt în
 exploatarea unui stat în scopuri guvernamentale necomerciale;</p><br>

<p>toate porturile din țările UE care sunt utilizate în mod normal de aceste nave.</p>

<p style="text-indent:2em;"></p><h6>Țările UE trebuie să se asigure că instalațiile portuare de preluare:</h6><p></p>

<p>răspund cerințelor navelor care utilizează porturile fără a cauza întârzieri excesive;</p><br>

<p style="text-indent:2em;">sunt elaborate în funcție de mărimea portului și de categoriile de nave care fac escală acolo, porturile mai mari având, în general, un trafic sporit și nave mai mari.</p><br>

<p style="font-weight:500;">Preluarea și manipularea deșeurilor</p><br>

<p style="text-indent:2em;">În fiecare port trebuie întocmit un plan de preluare și de manipulare a deșeurilor. Aceste planuri trebuie aprobate și evaluate de țara UE în cauză. Planurile trebuie
 reaprobate la fiecare trei ani cel puțin.</p><br>

<p style="font-weight:500;">Notificare</p>

<p style="text-indent:2em;"></p><h6>Căpitanii navelor (altele decât navele de pescuit și de agrement pentru maximum 12 pasageri) având ca destinație un port situat în UE trebuie să notifice
 anumite informații, în special:</h6><p></p>

<p>data și ultimul port în care au fost deversate deșeurile provenite din exploatarea navei;</p><br>

<p style="text-indent:2em;">tipurile și cantitățile de deșeuri și reziduuri care trebuie deversate și/sau reținute la bord și procentul din capacitatea de stocare maximă.</p><br>

<p style="font-weight:500;">Deversarea</p>

<p style="text-indent:2em;"></p><h6>Deșeurile provenite din exploatarea navelor trebuie predate unei autorități de preluare din cadrul portului înainte de părăsirea unui port al UE, cu excepția
 cazului în care căpitanul poate demonstra că nava are o capacitate de stocare suficientă pentru a ajunge la portul de deversare dorit. Cu toate acestea, chiar și
 în acest caz, o țară a UE poate impune navelor să își deverseze deșeurile înainte de a părăsi portul dacă are motive întemeiate de a crede că:</h6><p></p>

<p>portul unde este prevăzută deversarea nu dispune de instalații adecvate sau</p><br>

<p>acest port nu este cunoscut și</p><br>

<p>deci există riscul ca deșeurile să fie deversate în mare.</p><br>

<p style="font-weight:500;">Inspecții</p>

<p style="text-indent:2em;"></p><h6>Există o cerință privind realizarea inspecției în procent de minimum 25 % în cazul navelor care prestează servicii într-un port din UE. Țările UE trebuie să acorde
 o atenție deosebită navelor care:</h6><p></p>

<p>nu și-au respectat obligația de notificare;</p><br>

<p>sunt suspectate că nu și-au deversat deșeurile în conformitate cu directiva.</p><br>

<p style="font-weight:500;">Taxe pentru deșeuri</p><br>

<p style="text-indent:2em;">Porturile trebuie să stabilească sisteme de recuperare a costurilor pentru a încuraja deversarea deșeurilor pe uscat și a descuraja deversarea acestora în mare. Toate navele
 care fac escală într-un port din UE suportă o parte semnificativă din costuri (stabilită de Comisia Europeană la 30 %), chiar dacă utilizează sau nu instalațiile. Taxele pot
 varia în funcție de categoria, tipul și mărimea navei. De asemenea, taxele pot fi reduse în cazul în care căpitanul poate demonstra că gestiunea, concepția, echipamentul și 
 exploatarea navei din punct de vedere al mediului produc cantități reduse de deșeuri provenite din exploatare.</p><br>

<p style="font-weight:500;">Punerea în aplicare</p><br>

<p style="text-indent:2em;">Potrivit unui studiu de evaluare finală a punerii în aplicare a directivei, publicat de Comisie în 2015, directiva a fost parțial eficace, eficientă și coerentă. De asemenea,
 în studiu s-au identificat mai multe probleme care ar putea fi vizate într-o revizuire a directivei.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p style="text-indent:2em;">Directiva se aplică începând cu data de 28 decembrie 2000. Țările UE au avut obligația de a o transpune în legislația națională până la 28 decembrie 2002.</p><br>

<p style="font-weight:500;">Timpul de lucru la bordul navelor care folosesc porturile UE</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Această directivă are obiectivul de a combate denaturarea concurenței de către proprietarii navelor din țările din afara UE și de a proteja sănătatea și securitatea navigatorilor
 la bordul navelor care folosesc porturile UE.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva are obiectivul de a oferi proceduri pentru verificarea și asigurarea conformității navelor care fac escală în porturile din țările UE cu Directiva 1999/63/CE (modificată
 ulterior prin Directiva 2009/13/CE), care subliniază normele privind timpul de lucru al navigatorilor, inclusiv orele, odihna, concediile plătite și capacitatea de efectuare
 a muncii.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Țările UE, prin intermediul inspectorilor de control ai statului portului desemnați, desfășoară inspecții la bordul navelor care fac escală în porturile lor, indiferent de țara
 în care acestea sunt înmatriculate. Navele de pescuit nu sunt incluse în directivă.</p><br>
 
<p style="text-indent:2em;">Inspecțiile au loc în special după primirea unei reclamații din partea căpitanului, a unui membru al echipajului sau a oricărei persoane sau organizații având un interes legitim
 în ceea ce privește exploatarea în siguranță a navei, condițiile de viață și de muncă la bordul navei sau prevenirea poluării.</p>

<p style="text-indent:2em;"></p><h6>Inspecțiile stabilesc dacă:</h6><p></p>

<p>un tabel cu regimul de muncă la bordul navei este afișat într-un loc ușor accesibil;</p><br>

<p style="text-indent:2em;">un registru în care se consemnează orele de muncă și perioadele de odihnă se ține la bord și este vizat de autoritatea competentă a țării în care este înmatriculată nava.</p><br>

<p style="text-indent:2em;">Dacă se constată că navigatorii sunt excesiv de obosiți, se efectuează o inspecție mai detaliată pentru a stabili dacă orele de muncă ce au fost consemnate sunt în conformitate
cu reglementările și dacă au fost respectate.</p><br>

<p style="text-indent:2em;">În cazul oricărei situații care prezintă în mod clar un pericol pentru securitate sau sănătate, țara UE poate interzice părăsirea portului de către navă până la rectificarea 
deficiențelor sau până când echipajul este odihnit.</p><br>

<p style="text-indent:2em;">Dacă unei nave i se interzice să părăsească portul, se va informa căpitanul, proprietarul sau un funcționar al țării de pavilion, al țării în care este înmatriculată nava sau un reprezentant
 diplomatic cu privire la decizie și la orice măsuri de remediere necesare.</p><br>
 
<p style="text-indent:2em;">Dacă o navă este întârziată în mod inutil, proprietarul este îndreptățit la compensații pentru orice pierdere sau daună suferită, sarcina probei revenind proprietarului navei, 
care are și dreptul de a ataca o decizie de imobilizare.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p>Directiva a intrat în vigoare la 20 ianuarie 2000.</p><br>

<p style="text-indent:2em;">Pentru informații suplimentare, consultați Convenția privind munca din domeniul maritim din 2006 pe site-ul Organizației Internaționale a Muncii.</p><br>

<p style="font-weight:500;">Transportul pe căi navigabile interioare — condiții de muncă</p><br>

<p style="font-weight:500;">SINTEZĂ PRIVIND:

</p><p style="text-indent:2em;">Directiva 2014/112/UE de punere în aplicare a Acordului european privind anumite aspecte ale organizării timpului de lucru în transportul pe căi navigabile interioare</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva pune în aplicare Acordul european între partenerii sociali din acest sector pentru a adapta legislația Uniunii Europene (UE) privind timpul de lucru la nevoile
 specifice ale persoanelor care lucrează pe căile navigabile interioare.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Baza de calcul a timpului de lucru este o zi de 8 ore. Timpul de lucru nu trebuie să depășească:</h6><p></p>

<p>în medie, 48 de ore pe săptămână într-un interval de 12 luni;</p><br>

<p>2 304 ore într-un interval de 52 de săptămâni;</p><br>

<p>14 ore într-un interval de 24 de ore;</p><br>

<p>84 de ore într-un interval de 7 zile;</p><br>

<p style="text-indent:2em;">în medie, 72 de ore pe săptămână într-un interval de 4 luni, dacă rezultă un număr mai mare de zile de lucru decât de zile de repaus;</p><br>

<p>31 de zile consecutive.</p>

<p style="text-indent:2em;"></p><h6>Condiții speciale se aplică lucrătorilor sezonieri angajați pe navele de pasageri. Timpul de lucru nu trebuie să depășească:</h6><p></p>

<p>12 ore în orice interval de 24 de ore;</p><br>

<p>72 de ore în orice interval de 7 zile;</p><br>

<p>lucrătorii au dreptul la 0,2 zile de repaus pentru fiecare zi de lucru.</p>

<p style="text-indent:2em;"></p><h6>Perioadele de repaus trebuie să fie suficient de lungi și continue pentru a garanta sănătatea și siguranța lucrătorilor și a colegilor acestora. Acestea nu trebuie să fie mai mici de:</h6><p></p>

<p>10 ore în orice interval de 24 de ore, din care cel puțin 6 ore neîntrerupte;</p><br>

<p>84 de ore în orice interval de 7 zile.</p><br>

<p style="text-indent:2em;">Se aplică alte norme dacă, potrivit programului de lucru, numărul de zile de lucru este mai mare sau egal cu numărul de zile de repaus.</p><br>

<p>Trebuie să se asigure pauze oricărei persoane care lucrează mai mult de 6 ore pe zi.</p><br>

<p>Munca pe timpul nopții este limitată la 7 ore și la maximum 42 de ore într-un interval de 7 zile.</p><br>

<p>Fiecare lucrător are dreptul la concediu anual plătit de cel puțin 4 săptămâni.</p><br>

<p style="text-indent:2em;">Lucrătorii sub vârsta de 18 ani fac obiectul Directivei 94/33/CE. Se aplică excepții speciale tinerilor lucrători cu vârsta peste 16 ani.</p><br>

<p>Se va păstra un jurnal detaliat privind timpul de lucru și de repaus al fiecărei persoane.</p><br>

<p style="text-indent:2em;">Conducătorii de nave pot solicita membrilor echipajului să lucreze toate orele necesare în cazul unei situații de urgență. Dacă această situație apare pe durata perioadei de repaus
 a lucrătorilor, aceștia vor beneficia de o perioadă de repaus adecvată.</p><br>

<p>Toți membrii echipajului au dreptul la controale medicale anuale gratuite.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p style="text-indent:2em;">Directiva se aplică începând cu data de 12 ianuarie 2015. Țările UE au avut obligația de a o transpune în legislația națională până la 31 decembrie 2016.</p><br>

<p style="font-weight:500;">Timpul de lucru al navigatorilor</p><br>

<p style="font-weight:500;">SINTEZĂ PRIVIND:</p><br>

<p>Directiva 1999/63/CE – Acordul de organizare a timpului de lucru al navigatorilor</p><br>

<p style="font-weight:500;">CARE ESTE OBIECTIVUL DIRECTIVEI?</p><br>

<p style="text-indent:2em;">Directiva introduce în legislație acordul privind timpul de lucru al navigatorilor* încheiat între Asociația Proprietarilor de Nave* din Comunitatea Europeană și 
Federația Sindicatelor Lucrătorilor din Transporturi la 30 septembrie 1998.</p><br>

<p>Actul ia în considerare Convenția privind munca din domeniul maritim a Organizației Internaționale a Muncii.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Orice navă maritimă aflată în proprietate publică sau privată, care este înregistrată într-o țară a Uniunii Europene (UE) și care desfășoară activități 
maritime comerciale trebuie să respecte legislația.</p><br>

<p style="text-indent:2em;">Directiva specifică un număr maxim de ore de lucru* sau un număr minim de ore de odihnă* de-a lungul unei perioade date.</p>

<p style="text-indent:2em;"></p><h6>Orele de lucru trebuie să respecte că:</h6><p></p>

<p>o zi de lucru standard este de 8 ore, cu o zi de odihnă și repausuri în timpul sărbătorilor legale;</p><br>

<p>numărul maxim de ore de lucru trebuie să nu depășească 14 în orice perioadă de 24 de ore sau 72 de ore în 7 zile.</p>

<p style="text-indent:2em;"></p><h6>Perioadele de odihnă:</h6><p></p>

<p>trebuie să nu fie de mai puțin de 10 ore în orice perioadă de 24 de ore sau 77 de ore în 7 zile;</p><br>

<p>pot fi împărțite în cel mult două perioade, dintre care una trebuie să fie de cel puțin 6 ore;</p><br>

<p>trebuie să fie separate printr-un interval de cel puțin 14 ore;</p><br>

<p style="text-indent:2em;">trebuie să fie perturbate cât mai puțin de exerciții de siguranță, precum exercițiile de verificare a echipajului și de instrucție pentru stingerea 
incendiilor și de salvare;</p><br>

<p>trebuie să includă o compensație adecvată pentru navigatorii chemați la post în timpul orelor de odihnă.</p><br>

<p style="text-indent:2em;">Comandanții navelor au dreptul să ceară echipajului să se prezinte la post pentru a garanta siguranța imediată a navei, a persoanelor de la bord, a 
încărcăturii și a altor persoane aflate în pericol.</p><br>

<p>Detaliile privind programul de lucru de la bordul navei și dispozițiile legislative trebuie să fie accesibile și afișate.</p><br>

<p>Trebuie să se întocmească registre pentru orele zilnice de lucru și orele de odihnă ale navigatorilor.</p><br>

<p style="text-indent:2em;">Nivelurile de dotare cu personal trebuie să evite sau să diminueze orele excesive de lucru pentru a asigura odihnă suficientă și pentru a 
limita oboseala.</p><br>

<p>Niciun navigator cu vârsta sub 18 ani nu poate lucra pe timp de noapte*, deși sunt permise unele excepții.</p><br>

<p>Nicio persoană cu vârsta sub 16 ani nu poate lucra pe o navă.</p>

<p style="text-indent:2em;"></p><h6>Fiecare navigator:</h6><p></p>

<p style="text-indent:2em;">trebuie să aibă un certificat de sănătate care să îi ateste capacitatea din punct de vedere medical de a lucra pe mare. Sunt permise unele excepții;</p><br>

<p style="text-indent:2em;">are dreptul la un concediu anual plătit. Acesta este bazat pe un minimum de 2,5 zile pe lună lucrată și proporțional pentru lunile lucrate incomplet.</p>

<p style="text-indent:2em;"></p><h6>Țările UE pot:</h6><p></p>

<p>să permită scutiri de la orele de lucru specificate și de la perioadele de odihnă, în anumite condiții;</p><br>

<p>să aplice navigatorilor mai multe condiții, dar nu mai puțin favorabile decât cele cuprinse în directivă.</p><br>

<p>Normele completează actul legislativ separat privind timpul de lucru la bordul navelor care folosesc porturile UE.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p style="text-indent:2em;">Directiva se aplică începând cu data de 22 iulie 1999. Țările UE au avut obligația de a o transpune în legislația națională până la 30 iunie 2002.</p><br>

<p style="font-weight:500;">Siguranța maritimă</p><br>

<p>Siguranța maritimă: Codul Internațional de Management al Siguranței (ISM) pentru transportul maritim</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul include „Codul internațional de management pentru exploatarea în siguranță a navelor și pentru prevenirea poluării” ( codul ISM)
 în Uniunea Europeană (UE).</p>

<p style="text-indent:2em;"></p><h6>În special, obiectivul său este să asigure că societățile de transport maritim respectă codul ISM prin:</h6><p></p>

<p style="text-indent:2em;">înființarea, aplicarea și întreținerea corespunzătoare a sistemelor de management al siguranței de la bordul navei și de la țărm și controlul acestora 
prin administrația statului de pavilion (adică țara în care este înregistrată o navă) și administrația statului de port (adică țara în al cărei port 
este chemată sau ancorează o navă).</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Domeniul de aplicare</p>

<p style="text-indent:2em;"></p><h6>Regulamentul se aplică următoarelor tipuri de nave și companiilor care le exploatează:</h6><p></p>

<p>nave de marfă și nave de pasageri care navighează sub pavilionul unei țări a UE, care efectuează curse internaționale;</p><br>

<p>nave de marfă și nave de pasageri care efectuează numai curse interne, indiferent de pavilion;</p><br>

<p style="text-indent:2em;">nave de marfă și nave de pasageri exploatate spre și dinspre porturi ale UE, în cadrul unui serviciu de transport regulat, indiferent de pavilion;</p><br>

<p>unități mobile de foraj în larg exploatate sub autoritatea unei țări a UE.</p>

<p style="text-indent:2em;"></p><h6>Regulamentul nu se aplică:</h6><p></p>

<p style="text-indent:2em;">navelor de război sau navelor destinate transportului de trupe deținute sau exploatate de o țară a UE și utilizate numai în serviciile guvernamentale 
cu caracter necomercial;</p><br>

<p style="text-indent:2em;">navelor care nu sunt propulsate prin mijloace mecanice, navelor de lemn de construcție primitivă, ambarcațiunilor de agrement, cu excepția cazului în care 
acestea transportă mai mult de 12 persoane în scopuri comerciale;</p><br>

<p>navelor de pescuit;</p><br>

<p>navelor de marfă și unităților mobile de foraj în larg cu un tonaj brut mai mic de 500;</p><br>

<p style="text-indent:2em;">navelor de pasageri, altele decât feriboturile de pasageri de tip ro-ro (roll-on/roll-off), în anumite zone maritime, în sensul definiției de la articolul 
4 din Directiva 2009/45/CE.</p><br>

<p style="font-weight:500;">Certificare și verificare</p><br>

<p>Țările UE trebuie să respecte dispozițiile prevăzute de codul ISM partea B.</p><br>

<p style="font-weight:500;">Scutiri</p><br>

<p style="text-indent:2em;">În cazul în care o țară a UE consideră că în practică este dificil ca anumite dispoziții din codul ISM să fie respectate de către companii pentru nave specifice 
care efectuează numai curse interne, aceasta le poate scuti de aceste dispoziții prin impunerea unor măsuri echivalente.</p><br>

<p style="text-indent:2em;">În asemenea cazuri, țara UE trebuie să informeze Comisia Europeană în consecinţă. În cazul în care scutirea propusă este nejustificată, țara trebuie să modifice 
sau să renunțe la adoptarea acestor măsuri.</p><br>

<p style="font-weight:500;">Sancțiuni</p><br>

<p style="text-indent:2em;">Țările UE trebuie să stabilească un sistem de sancțiuni eficiente, proporționale și descurajante pentru nerespectarea regulamentului.</p><br>

<p style="font-weight:500;">Rapoarte</p><br>

<p style="text-indent:2em;">Țările UE trebuie să raporteze Comisiei, o dată la doi ani, cu privire la punerea în aplicare a codului ISM. Pe baza acestui raport bienal, Comisia pregătește 
un raport consolidat pentru Parlamentul European și Consiliu.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Regulamentul se aplică de la 24 martie 2006.</p><br>

<p style="font-weight:500;">Încărcarea și descărcarea vrachierelor</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva îmbunătățește procedurile privind siguranța vrachierelor la încărcarea și descărcarea mărfurilor solide în vrac în porturile Uniunii Europene (UE).</p><br>

<p>Directiva are ca scop reducerea riscului de distrugere a navelor în timpul încărcării și descărcării.</p><br>

<p>Directiva armonizează normele pentru nave și terminale și pentru cooperarea și comunicarea dintre acestea.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Legislația se aplică terminalelor* UE și vrachierelor care le utilizează pentru încărcarea și descărcarea mărfurilor solide în vrac, indiferent de pavilionul acestora.</p>

<p style="text-indent:2em;"></p><h6>Țările UE trebuie să asigure că:</h6><p></p>

<p>operatorii terminalelor au certitudinea că vrachierele care acostează și propriile terminale respectă legislația;</p><br>

<p style="text-indent:2em;">planurile de încărcare și de descărcare în conformitate cu Convenția SOLAS 1974 sunt agreate între comandantul navei și reprezentantul terminalului înainte de începerea 
oricărei operațiuni;</p><br>

<p style="text-indent:2em;">autoritățile naționale previn sau opresc orice operațiune în cazul în care consideră că ar periclita siguranța navei sau a echipajului;</p><br>

<p style="text-indent:2em;">se efectuează inspecții regulate, inclusiv inspecții neanunțate, în timpul operațiunilor de încărcare și de descărcare pentru verificarea respectării legislației. Rezultatele 
inspecțiilor sunt trimise Comisiei Europene o dată la trei ani.</p><br>

<p style="text-indent:2em;">Comandanții de nave sunt permanent responsabili pentru încărcarea și descărcarea în siguranță a vrachierelor aflate sub comanda lor.</p><br>

<p style="text-indent:2em;">Reprezentanții terminalelor trebuie să înștiințeze comandantul în legătură cu informații relevante, precum denumirea danei și echipamentul disponibil în locul în care operațiunea 
va avea loc, înainte de sosirea navei.</p><br>

<p style="text-indent:2em;">Reprezentanții terminalelor trebuie să raporteze comandantului navei orice deficiențe constatate la navă sau la echipamentul terminalului în timpul încărcării sau descărcării. 
Dacă este necesar, acestea trebuie remediate.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p style="text-indent:2em;">Directiva se aplică de la 5 februarie 2002. Țările UE au avut obligația de a o transpune în legislația națională până la 5 august 2003.</p><br>

<p style="font-weight:500;">TERMEN-CHEIE</p><br>

<p style="text-indent:2em;">Terminal: orice instalație fixă, plutitoare sau mobilă, echipată și utilizată pentru încărcarea sau descărcarea mărfurilor uscate în vrac în sau din vrachiere.</p><br>

<p style="font-weight:500;">Siguranța maritimă în UE: organizațiile cu rol de inspecție și control al navelor</p><br>

<p style="text-indent:2em;">Uniunea Europeană (UE) a stabilit un cadru legal de reglementare a relațiilor dintre țările UE și organizațiile recunoscute (*), desemnate pentru efectuarea inspecțiilor,
 controalelor și certificarea navelor, care acționează în numele lor.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Directiva 2009/15/CE a Parlamentului European și a Consiliului din 23 aprilie 2009 privind normele și standardele comune pentru organizațiile cu rol de inspecție și control
 al navelor, precum și pentru activitățile în domeniu ale administrațiilor maritime</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Uniunea Europeană (UE) a stabilit un cadru legal de reglementare a relațiilor dintre țările UE și organizațiile recunoscute (*), desemnate pentru efectuarea inspecțiilor,
 controalelor și certificarea navelor, care acționează în numele lor.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva stabilește normele și condițiile în baza cărora o țară UE, în calitate de stat de pavilion, poate autoriza o organizație recunoscută să efectueze inspecții
 și certificări statutare în numele său.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Autorizarea organizațiilor recunoscute</p><br>

<p style="text-indent:2em;">Țările UE trebuie să se asigure că administrațiile lor pun în aplicare convențiile internaționale (*) relevante în ceea ce privește inspecția și certificarea navelor care
 arborează pavilionul lor.</p><br>

<p style="text-indent:2em;">O țară UE poate să autorizeze organizațiile să efectueze integral sau parțial inspecțiile și controalele aferente emiterii sau reînnoirii certificatelor statutare (*) ale navelor.
 Țara respectivă poate să confere aceste competențe numai organizațiilor recunoscute.</p><br>

<p style="text-indent:2em;">Competențele legate de emiterea de certificate de siguranță a comunicațiilor radio pentru navele comerciale pot fi însă acordate unor organisme private recunoscute care dețin
 suficientă experiență și personal calificat.</p><br>

<p style="text-indent:2em;">O țară UE nu poate refuza autorizarea niciunei organizații recunoscute. Cu toate acestea, țara UE respectivă poate să decidă privind limitarea numărului de organizații autorizate
 în baza unor criterii obiective și nediscriminatorii.</p><br>

<p style="text-indent:2em;">Organizațiile situate în țările din afara UE pot fi recunoscute la nivelul UE și ulterior autorizate de administrațiile țărilor UE. În astfel de cazuri pot fi solicitate proceduri
 pentru autorizarea reciprocă.</p><br>

<p style="font-weight:500;">Relațiile de colaborare</p><br>

<p style="text-indent:2em;">Atunci când o țară UE autorizează o organizație recunoscută, țara respectivă stabilește o „relație de colaborare” cu aceasta. Această relație este reglementată printr-un acord
 care conține clauze privind răspunderea financiară, auditarea periodică a atribuțiilor, inspecțiile aleatorii și amănunțite ale navelor și dispoziții privind raportarea obligatorie
 a informațiilor referitoare la flota de nave înscrise în registrul său de clasificare („clasa” de nave se referă la un grup de nave cu aceleași specificații de proiectare). 
 Organizațiile autorizate pot fi obligate să fie reprezentate la nivel local în țara în cauză.</p><br>

<p>Țările UE trebuie să notifice Comisia Europeană despre relațiile de colaborare pe care le încheie.</p><br>

<p style="text-indent:2em;">O țară a UE poate suspenda sau retrage autorizația unei organizații recunoscute în cazul în care consideră că acea organizație nu mai îndeplinește condițiile necesare executării
 sarcinilor ce îi revin.</p><br>

<p style="font-weight:500;">Monitorizare</p><br>

<p style="text-indent:2em;">Țările UE trebuie să se asigure că organizațiile recunoscute care acționează în numele lor își îndeplinesc sarcinile în mod corespunzător. Acestea trebuie să le monitorizeze o dată
 la fiecare doi ani și trebuie să informeze țările UE și Comisia cu privire la rezultatele acestor activități de monitorizare.</p>

<p style="text-indent:2em;"></p><h6>Atunci când o țară a UE monitorizează nave în calitate de stat de port, aceasta trebuie să informeze Comisia și alte țări ale UE dacă:</h6><p></p>

<p>o navă care deține un certificat statutar valabil nu îndeplinește, de fapt, cerințele relevante ale convențiilor internaționale;</p><br>

<p style="text-indent:2em;">la o navă care deține un certificat de clasă (*) valabil se constată o defecțiune care privește elementele cuprinse în certificatul respectiv.</p><br>

<p style="text-indent:2em;">Țările UE trebuie să raporteze cazurile navelor care reprezintă o amenințare reală la adresa siguranței și mediului sau cele care fac dovada unei neglijențe deosebite din partea 
organizațiilor recunoscute. Organizațiile trebuie ținute la curent pentru a putea lua măsurile de corecție necesare.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p>Începând cu 17 iunie 2009.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p style="text-indent:2em;">Această directivă a fost adoptată în paralel cu Regulamentul (CE) nr. 391/2009 privind normele și standardele comune pentru organizațiile cu rol de inspecție și control al 
navelor.</p><br> 

<p>Cele două instrumente abrogă Directiva 94/57/CE.</p><br> 

<p style="text-indent:2em;">Regulamentul creează un sistem de acordare a autorizațiilor la nivelul UE, care este o precondiție pentru orice organizație ce urmează să fie autorizată de o țară a UE în 
contextul Directivei 2009/15/CE.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="font-weight:500;">(*) Organizație recunoscută: o organizație recunoscută în conformitate cu Regulamentul (CE) nr. 391/2009 privind normele și standardele comune pentru organizațiile cu rol de 
inspecție și control al navelor (a se vedea secțiunea „Context”).</p><br>

<p style="font-weight:500;">(*) Convenții internaționale: Convenția pentru ocrotirea vieții omenești pe mare din 1 noiembrie 1974 (SOLAS 74), cu excepția capitolului XI-2 din anexa la aceasta; Convenția 
asupra liniilor de încărcare din 5 aprilie 1966; Convenția pentru prevenirea poluării de către nave din 2 noiembrie 1973 (MARPOL); împreună cu protocoalele și modificările la 
acestea și codurile conexe cu statut obligatoriu în toate țările UE, cu excepția alineatelor 16.1, 18.1 și 19 din partea 2 din Codul de aplicare a instrumentelor OMI și a 
secțiunilor 1.1, 1.3, 3.9.3.1, 3.9.3.2 și 3.9.3.3 din partea 2 din Codul IMO pentru organizațiile recunoscute, în versiunea actualizată a acestora.</p><br>

<p style="font-weight:500;">(*) Certificat statutar: un certificat eliberat de un stat de pavilion sau în numele unui stat de pavilion în conformitate cu convențiile internaționale.</p><br>

<p style="font-weight:500;">(*) Certificat de clasă: un certificat prin care se atestă că nava poate fi utilizată într-un anume scop sau pentru un anumit serviciu, eliberat în conformitate cu normele și 
procedurile organizației recunoscute.</p><br>

<p style="font-weight:500;">Răspunderea deținătorilor de nave în caz de accident</p><br>

<p style="text-indent:2em;">Pasagerii implicați în accidente pe mare trebuie să beneficieze de un nivel adecvat de compensare pentru eventualele pierderi sau daune suferite. În acest scop, deținătorii de 
nave trebuie să aibă încheiate asigurări adecvate.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Regulamentul (CE) nr. 392/2009 al Parlamentului European și al Consiliului din 23 aprilie 2009 privind răspunderea în caz de accident a transportatorilor de persoane pe mare</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Pasagerii implicați în accidente pe mare trebuie să beneficieze de un nivel adecvat de compensare pentru eventualele pierderi sau daune suferite. În acest scop, deținătorii de 
nave trebuie să aibă încheiate asigurări adecvate.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul prevede norme armonizate privind răspunderea și asigurarea companiilor de navigație care transportă pasageri pe mare. Acesta introduce în legislația europeană 
prevederile Convenției de la Atena din 1974 privind transportul pe mare al pasagerilor și al bagajelor lor, precum și orientările formulate de Organizația Maritimă 
Internațională.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Actul legislativ se aplică tuturor navelor care arborează drapelul unei țări a Uniunii Europene (UE), călătoresc spre sau dinspre un port european sau în temeiul unui contract
 european de transport (adică un contract între transportator și pasageri care definește drepturile, obligațiile și răspunderea).</p><br>

<p style="text-indent:2em;">În prezent, legea se aplică atât călătoriilor internaționale, cât și celor interne, dar nu și călătoriilor interne pe parcursul cărora nava se află la mai puțin de cinci mile 
de linia de coastă.</p><br>

<p style="text-indent:2em;">Răspunderea operatorilor acoperă pasagerii, bagajele și vehiculele acestora, precum și echipamentele de mobilitate ale persoanelor cu mobilitate redusă.</p><br>

<p style="text-indent:2em;">Pentru orice vătămare sau daună provocată de un incident maritim (adică naufragiu, răsturnare, coliziune sau eșuare a navei, explozii sau incendii la bord sau defectarea acesteia),
 victimele nu sunt obligate să dovedească vina transportatorului pentru a beneficia de compensație.</p><br>

<p style="text-indent:2em;">Operatorii de transport trebuie să plătească un avans care să acopere necesitățile economice imediate în caz de deces sau vătămare corporală a unui pasager într-un
 incident maritim.</p><br> 

<p>Efectuarea acestei plăți nu înseamnă că societatea de navigație își recunoaște răspunderea.</p><br>

<p>Avansul minim în caz de deces al unui pasager este de 21 000 EUR.</p><br>

<p style="text-indent:2em;">Companiile de navigație trebuie să le pună la dispoziție pasagerilor informații inteligibile cu privire la drepturile care le revin.</p><br>

<p style="text-indent:2em;">Aceste informații trebuie să fie disponibile în toate punctele de vânzare, inclusiv în cazul vânzării telefonice și pe internet; de asemenea, ele trebuie furnizate înaintea sau
 cel târziu în momentul plecării.</p><br>

<p style="text-indent:2em;">La cel târziu trei ani de la intrarea în vigoare a actului legislativ, (31 decembrie 2012), Comisia Europeană trebuie să prezinte un raport privind punerea în aplicare a acestuia.</p><br>

<p style="text-indent:2em;">Guvernele UE pot amâna aplicarea actului legislativ în cazul navelor care efectuează exclusiv curse interne vizate de regulament. Pentru navele care călătoresc mai puțin de 20 de
 mile de la linia de coastă, termenul-limită este 31 decembrie 2018. Pentru toate celelalte nave, termenul-limită este 31 decembrie 2016.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Începând cu 29 mai 2009.</p><br>

<p>Pentru informații suplimentare, consultați pagina referitoare la drepturile pasagerilor pe site-ul Comisiei Europene.</p><br>

<p style="font-weight:500;">Sistemul UE de informații și schimb de informații maritime</p><br>

<p style="text-indent:2em;">Acest act legislativ al Uniunii Europene (UE) instituie un sistem de monitorizare și de schimb de informații privind traficul navelor maritime. Obiectivul acestuia este de
 a spori siguranța maritimă, securitatea portuară și maritimă, protecția mediului și pregătirea pentru situații de poluare. De asemenea, sistemul permite schimbul și 
 partajarea de informații suplimentare care să faciliteze eficiența traficului și a transportului maritim.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Directiva 2002/59/CE a Parlamentului European și a Consiliului din 27 iunie 2002 de instituire a unui sistem comunitar de monitorizare și informare privind traficul navelor
 maritime și de abrogare a Directivei 93/75/CEE a Consiliului.</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Acest act legislativ al Uniunii Europene (UE) instituie un sistem de monitorizare și de schimb de informații privind traficul navelor maritime. Obiectivul acestuia este 
de a spori siguranța maritimă, securitatea portuară și maritimă, protecția mediului și pregătirea pentru situații de poluare. De asemenea, sistemul permite schimbul și 
partajarea de informații suplimentare care să faciliteze eficiența traficului și a transportului maritim.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva instituie un sistem de monitorizare a apelor și a coastelor Europei - supraveghere maritimă și cunoașterea situației activităților desfășurate pe mare (a pozițiilor
 navelor) - care să sprijine țările UE în ceea ce privește sarcinile lor operaționale.</p><br>

<p style="text-indent:2em;">Pentru a spori cunoașterea situației în domeniul maritim și a oferi autorităților soluții adaptate, actul legislativ a fost modificat prin Directiva Comisiei 2014/100/UE.
 Aceasta permite integrarea informațiilor colectate și transmise prin sistemul UE de schimb de informații maritime [SafeSeaNet (SSN)] cu datele provenite din alte sisteme
 de monitorizare și de urmărire ale UE, cum ar fi CleanSeaNet, precum și din sisteme externe [de exemplu, sistemele de identificare automată prin satelit (AIS)].</p><br>

<p style="text-indent:2em;">Directiva stabilește responsabilitățile țărilor UE, ale autorităților maritime, ale transportatorilor, ale operatorilor navelor și ale căpitanilor de nave.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Sunt vizate toate navele cu un tonaj brut de 300 sau mai mare, indiferent dacă transportă sau nu mărfuri periculoase, cu excepția:</h6><p></p>

<p>navelor de război;</p><br>

<p>vaselor de pescuit, ambarcațiunilor tradiționale și celor de agrement având o lungime mai mică de 45 de metri;</p><br>

<p>buncărelor mai mici de 1 000 de tone.</p><br>

<p>Cerințe impuse operatorilor de nave care se îndreaptă spre un port al UE</p><br>

<p style="text-indent:2em;">Aceștia trebuie să notifice anumite informații (identificarea navei, numărul total al persoanelor de la bord, portul de destinație, ora de sosire estimată etc.) ghișeului național
 unic (începând cu data de 1 iunie 2015).</p><br>

<p style="font-weight:500;">Echipamentele și instalațiile</p>

<p style="text-indent:2em;"></p><h6>Navele care fac o escală într-un port al unei țări din UE trebuie să fie dotate cu:</h6><p></p>

<p>un sistem de identificare automată;</p><br>

<p style="text-indent:2em;">un sistem de înregistrare a datelor de voiaj (VDR sau „cutie neagră”) pentru a facilita efectuarea anchetelor în urma producerii accidentelor.</p><br>

<p style="text-indent:2em;">Țările din UE și din Spațiul Economic European (SEE) trebuie să aibă receptoare AIS și să conecteze ghișeul unic național/SSN național la sistemul SSN central.</p><br>

<p style="font-weight:500;">Mărfurile periculoase sau poluante de la bordul navelor</p><br>

<p>Înainte de a prelua la bord asemenea mărfuri, transportatorii trebuie să le declare operatorului.</p><br>

<p style="text-indent:2em;">De asemenea, operatorul, agentul sau căpitanul navei trebuie să notifice autorității competente informații generale, cum ar fi identificarea navei
 și informațiile furnizate de către transportator.</p><br>

<p>Monitorizarea navelor potențial periculoase și intervenția în caz de incidente și accidente</p>

<p style="text-indent:2em;"></p><h6>Autoritățile trebuie să informeze țările UE în cauză dacă sunt anunțate cu privire la nave:</h6><p></p>

<p>care au fost implicate în incidente sau accidente pe mare;</p><br>

<p>care nu au respectat cerințele de notificare și raportare;</p><br>

<p>care au deversat în mod deliberat substanțe poluante sau</p><br>

<p>cărora li s-a refuzat accesul în porturi</p>

<p style="text-indent:2em;"></p><h6>Căpitanul unei nave trebuie să raporteze imediat:</h6><p></p>

<p>orice incident sau accident care afectează siguranța navei;</p><br>

<p>orice incident sau accident care compromite siguranța navigației;</p><br>

<p>orice situație de natură să determine poluarea apelor sau a țărmului unei țări a UE;</p><br>

<p>orice pată de produse poluante si containere sau colete care se observă plutind în derivă pe mare.</p><br>

<p style="font-weight:500;">Locuri de refugiu</p><br>

<p style="text-indent:2em;">Toate țările UE/SEE trebuie să întocmească planuri pentru a găzdui navele care necesită asistență în locuri de refugiu. De asemenea, acestea trebuie să se reunească periodic
 pentru a face schimb de experiență și a lua măsuri de îmbunătățire comune.</p><br>

<p style="font-weight:500;">Conformitatea</p><br>

<p style="text-indent:2em;">Țările UE trebuie să verifice funcționarea sistemelor proprii de informare și să introducă un sistem de sancțiuni financiare care să descurajeze nerespectarea cerințelor directivei.</p><br>

<p style="font-weight:500;">Gestionarea</p><br>

<p style="text-indent:2em;">Sistemul este dezvoltat și gestionat de Comisia Europeană și de țările UE/SEE. Agenția Europeană pentru Siguranță Maritimă răspunde de funcționarea tehnică a acestuia.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p style="text-indent:2em;">Această directivă este o parte integrantă a politicii UE de siguranță maritimă. Pe lângă rolul lor în menținerea siguranței, a securității și a caracterului durabil, sistemul
 și platforma îndeplinesc un rol central în cadrul creării unui spațiu maritim european unic - spațiul european de transport maritim.</p>

<p style="text-indent:2em;"></p><h6>Mai multe informații sunt disponibile pe:</h6><p></p>

<p>site-ul Comisiei Europene referitor la domeniul maritim;</p><br>

<p>site-ul Agenției Europene pentru Siguranță Maritimă.</p><br>

<p style="font-weight:500;">Siguranța maritimă: organizațiile cu rol de inspecție și control al navelor</p><br>

<p style="text-indent:2em;">Uniunea Europeană (UE) a instituit un cadru juridic care se aplică recunoașterii și monitorizării activităților organizațiilor responsabile pentru efectuarea inspecțiilor și a 
controalelor navelor din partea țărilor UE care acționează ca stat de pavilion.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Regulamentul (CE) nr. 391/2009 al Parlamentului European și al Consiliului din 23 aprilie 2009 privind normele și standardele comune pentru organizațiile cu rol de
 inspecție și control al navelor</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Uniunea Europeană (UE) a instituit un cadru juridic care se aplică recunoașterii și monitorizării activităților organizațiilor responsabile pentru efectuarea inspecțiilor
 și a controalelor navelor din partea țărilor UE care acționează ca stat de pavilion.</p><br> 

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul creează un sistem de autorizare (recunoaștere), care face obiectul mai multor criterii și obligații în vederea asigurării că o organizație recunoscută aplică
 aceeași rigoare tuturor navelor din registrul său, indiferent de pavilionul pe care îl arborează navele.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Programul de recunoaștere al UE</p><br>

<p>Țările UE trebuie să prezinte Comisiei Europene (CE) o cerere pentru a obține recunoașterea pentru o organizație.</p><br>

<p style="text-indent:2em;">Recunoașterea este acordată și gestionată central de către CE. Autorizarea de către o țară a UE este o condiție prealabilă pe care orice organizație trebuie să o îndeplinească
 pentru a putea efectua inspecții și controale legale ale navelor care arborează pavilionul acesteia (Directiva 2009/15/CE).</p>

<p style="text-indent:2em;"></p><h6>Pentru obținerea recunoașterii, organizația trebuie să îndeplinească câteva criterii minime:</h6><p></p>

<p>să aibă personalitate juridică;</p><br>

<p>să aibă excelență profesională;</p><br>

<p>să aibă experiență;</p><br>

<p>să aibă independență;</p><br>

<p>să fie reglementată de un cod etic;</p><br>

<p>să folosească un sistem de gestionare a calității certificat.</p><br>

<p style="text-indent:2em;">CE îi poate cere organizației să adopte măsuri preventive și de remediere dacă nu îndeplinește cerințele sau când performanța sa în materie de prevenire a poluării
 și de siguranță a scăzut în mod semnificativ.</p><br>

<p style="font-weight:500;">Amenzile, penalitățile periodice și retragerea recunoașterii</p><br>

<p style="text-indent:2em;">CE poate impune amenzi unei organizații recunoscute când scăderea performanței sau încălcarea gravă sau repetată a criteriilor minime sau a obligațiilor sale în temeiul
 prezentului regulament evidențiază existența unor deficiențe grave în structura, sistemele, procedurile sau controalele interne ale organizației. O amendă poate fi impusă 
 și dacă organizația furnizează intenționat informații incorecte sau incomplete în contextul evaluării sale.</p><br>

<p style="text-indent:2em;">De asemenea, CE poate decide să emită penalități periodice împotriva organizației recunoscute dacă aceasta nu pune în aplicare măsurile necesare de prevenire și remediere.</p><br>

<p style="text-indent:2em;">CE poate decide, la cererea unei țări a UE sau din proprie inițiativă, să retragă recunoașterea în circumstanțe specifice. Acestea pot apărea în special când există o încălcare
 gravă și repetată a criteriilor minime sau a cerințelor stabilite în regulament sau când performanța slabă constituie o amenințare inacceptabilă pentru siguranță sau mediu.</p><br>

<p style="text-indent:2em;">Regulamentul (UE) nr. 788/2014 al Comisiei stabilește normele detaliate pentru aplicarea amenzilor și a penalităților cu titlu cominatoriu și pentru retragerea recunoașterii.</p><br>

<p style="font-weight:500;">Evaluarea organizațiilor</p><br>

<p style="text-indent:2em;">CE împreună cu țara UE care a prezentat cererea de recunoaștere trebuie să evalueze organizațiile recunoscute cel puțin o dată la doi ani. Evaluatorii trebuie să verifice
 conformitatea cu obligațiile și cu criteriile minime și să acorde o atenție specială siguranței, prevenirii poluării și evidențelor privind accidentele.</p><br>

<p style="font-weight:500;">Normele și procedurile</p><br>

<p style="text-indent:2em;">Organizațiile recunoscute trebuie să se consulte reciproc în vederea armonizării normelor și a procedurilor lor, precum și în vederea definirii condițiilor necesare pentru 
recunoașterea reciprocă a certificatelor de clasă emise pentru materiale, echipamente și componente. Certificatele pentru echipamente marine conforme cu Directiva 96/98/CE 
(sau, după 18 septembrie 2016, Directiva 2014/90/UE) trebuie să fie acceptate de organizații recunoscute pentru scopuri de clasificare.</p><br>

<p style="font-weight:500;">Entitatea independentă de evaluare și de certificare a calității</p><br>

<p style="text-indent:2em;">Organizațiile recunoscute trebuie să instituie o entitate independentă de evaluare și de certificare a calității care este responsabilă cu evaluarea și certificarea sistemelor
 de gestionare a calității ale acestora. Activitatea entității trebuie să fie evaluată în mod periodic de către CE.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="font-weight:500;">Norme și proceduri: standardele tehnice elaborate de către o organizație recunoscută referitoare la proiectarea, construcția, echiparea, întreținerea și controlul navelor.</p><br>

<p style="font-weight:500;">Organizație recunoscută: o organizație recunoscută în conformitate cu Regulamentul (CE) nr. 391/2009.</p><br>

<p style="font-weight:500;">Certificat de clasă: un document eliberat de o organizație recunoscută, prin care se atestă că nava poate fi utilizată într-un anume scop sau pentru un anumit serviciu în 
conformitate cu normele și procedurile stabilite de către respectiva organizație.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p style="text-indent:2em;">Directiva 2009/15/CE, adoptată în paralel cu Regulamentul (CE) nr. 391/2009, instituie cadrul juridic care reglementează relațiile dintre statele membre și organizațiile recunoscute.</p><br>

<p style="text-indent:2em;">Pentru informații suplimentare, consultați site-ul Comisiei Europene referitor la acțiunile UE privind siguranța maritimă și protecția mediului înconjurător.</p><br>

<p style="font-weight:500;">Transportul maritim şi mediul</p><br>

<p style="font-weight:500;">Poluarea cauzată de nave și sancțiunile penale</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva creează norme aplicabile în întreaga UE pentru impunerea de sancțiuni în cazul deversărilor de hidrocarburi și alte substanțe poluante de către navele care
 navighează în apele UE.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Legislația actuală prevede că deversările poluante provenite de la nave constituie în principiu o infracțiune. Conform directivei, acest lucru se referă la deversările de
 hidrocarburi sau alte substanțe poluante de la nave. Deversările minore nu vor fi considerate automat ca fiind infracțiuni, decât dacă deversările repetate duc la o deteriorare
 a calității apei.</p><br>

<p style="text-indent:2em;">Persoanele responsabile pentru deversarea de substanțe poluante pot fi supuse unor sancțiuni penale dacă au acționat cu intenție, prin imprudență* sau prin neglijență gravă.
 Incitarea sau ajutarea și instigarea la deversarea unei substanțe poluante pot, de asemenea, să ducă la sancțiuni penale.</p><br>

<p>Directiva se aplică tuturor tipurilor de nave, indiferent de pavilionul acestora.</p>

<p style="text-indent:2em;"></p><h6>Deversările poluante sunt interzise în:</h6><p></p>

<p>apele interne, inclusiv porturile, ale unei țări a UE;</p><br>

<p>apele teritoriale ale unei țări a UE;</p><br>

<p style="text-indent:2em;">strâmtorile utilizate pentru navigația internațională supuse dreptului de transport de tranzit, în conformitate cu Convenția Organizației Națiunilor Unite din 1982 
privind dreptul mării;</p><br>

<p>zona economică exclusivă (ZEE)* a unei țări a UE;</p><br>

<p>marea liberă.</p><br>

<p style="font-weight:500;">Excepții</p><br>

<p style="text-indent:2em;">Acest regim nu se aplică în cazul deversărilor provenite de la nave de război sau alte nave aparținând unui stat sau exploatate de stat și utilizate numai pentru un 
serviciu public necomercial.</p><br>

<p style="text-indent:2em;">Se pot aplica excepții de la interdicția deversărilor de substanțe poluante în cazul în care este în pericol siguranța umană sau siguranța navei.</p><br>

<p style="font-weight:500;">Persoane juridice</p><br>

<p style="text-indent:2em;">Directiva 2009/123/CE a modificat Directiva 2005/35/CE pentru a îmbunătăți normele privind poluarea cauzată de nave și pentru a garanta că autorii deversărilor de substanțe 
poluante sunt supuși unor sancțiuni adecvate. Aceasta le impune țărilor UE să introducă norme privind răspunderea persoanelor juridice conform dreptului privat*, cum sunt
 întreprinderile.</p><br>

<p style="text-indent:2em;">Companiile pot fi supuse unor sancțiuni penale în cazul în care o persoană fizică din conducerea întreprinderii a comis, în beneficiul propriu, o infracțiune penală, indiferent
 dacă persoana respectivă a acționat individual sau ca parte a întreprinderii.</p><br>

<p style="text-indent:2em;">Compania este responsabilă și pentru infracțiunile comise de către o persoană fizică din neglijență, și anume din cauza lipsei supravegherii și a controlului.</p><br>

<p>Răspunderea întreprinderii nu va exclude demararea unor proceduri penale împotriva persoanelor fizice implicate.</p><br>

<p style="font-weight:500;">Aplicarea sancțiunilor</p><br>

<p style="text-indent:2em;">Directiva 2009/123/CE le impune, de asemenea, autorităților naționale din țările UE să asigure aplicarea unor sancțiuni efective, proporționale și cu efect de descurajare, inclusiv
 în cazurile minore. Ele trebuie să coopereze atunci când se constată că o navă se face vinovată de deversare ilegală în zona lor de responsabilitate, înainte ca nava să acosteze 
 în portul altei țări a UE.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p>Directiva se aplică începând cu data de 1 octombrie 2005.</p><br>

<p>Țările UE au avut obligația de a o transpune în legislația națională până la 1 aprilie 2007.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p style="text-indent:2em;">Naufragiul navei Prestige în noiembrie 2002 și cel al navei Erika în decembrie 1999 au evidențiat necesitatea unor măsuri mai severe cu privire la poluarea cauzată de nave. 
Accidentele nu sunt însă principala sursă de poluare: de cele mai multe ori este vorba despre deversări deliberate (operațiuni de spălare a cisternelor și eliminare a 
hidrocarburilor reziduale).</p><br>

<p style="text-indent:2em;">Aceste norme încorporează în dreptul UE părți din Convenția Internațională din 1973 pentru prevenirea poluării de către nave și din protocolul acesteia din 1978 (cunoscut drept
 Convenția Marpol). Se va putea armoniza astfel aplicarea normelor acestei convenții.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="font-weight:500;">Acțiune neglijentă: o acțiune întreprinsă având cunoștință de faptul că aceasta va avea ca rezultat probabil o daună.</p><br>

<p style="font-weight:500;">Zonă economică exclusivă (ZEE): se înțelege, în general, că această zonă se constituie atunci când o țară preia jurisdicția asupra explorării și a exploatării resurselor marine din apele
 sale de coastă. În mod normal, această secțiune este considerată a fi o fâșie întinsă la 200 de mile de țărm</p><br>

<p style="font-weight:500;">Persoane juridice conform dreptului privat: toate entitățile juridice, cum sunt întreprinderile, cu excepția statelor, a organismelor publice și a organizațiilor internaționale publice.</p><br>

<p style="font-weight:500;">Răspunderea civilă pentru daunele provocate de poluarea cu hidrocarburi: Convenția hidrocarburi pentru propulsia navei</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DECIZII?</p><br>

<p style="text-indent:2em;">Decizia autorizează țările Uniunii Europene (UE) să semneze, să ratifice sau să adere la Convenția internațională din 2001 privind răspunderea civilă pentru daunele provocate de poluarea
 cu hidrocarburi utilizate pentru propulsia navei (Convenția hidrocarburi pentru propulsia navei).</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Convenția hidrocarburi pentru propulsia navei a fost adoptată sub auspiciile Organizației Maritime Internaționale (OMI) pentru a asigura compensații adecvate, prompte și eficiente pentru
 persoanele care au suferit prejudicii din cauza deversărilor de hidrocarburi transportate sub formă de combustibil în buncărele navelor.</p><br>

<p style="font-weight:500;">Compatibilitatea cu normele UE</p><br>

<p style="text-indent:2em;">Articolele 9 și 10 din Convenția hidrocarburi pentru propulsia navei au efecte asupra normelor prevăzute în Regulamentul (CE) nr. 44/2001 al Consiliului [în prezent Regulamentul (UE) nr.
 1215/2012] privind competența judiciară, recunoașterea și executarea hotărârilor în materie civilă și comercială.</p><br>

<p>Convenția nu stipulează ca o organizație internațională, precum UE, să semneze, să ratifice sau să adere la aceasta.</p><br>

<p style="text-indent:2em;">Prin urmare, țările UE, cu autorizarea UE, trebuie să semneze, să ratifice sau să adere la convenție în interesul UE. În acest context, țările UE trebuie să facă și o declarație prin care
 își asumă obligația să aplice Regulamentul (CE) nr. 1215/2012 în relațiile lor reciproce.</p><br>

<p style="font-weight:500;">Domeniul de aplicare</p>

<p style="text-indent:2em;"></p><h6>Convenția se aplică pentru:</h6><p></p>

<p style="text-indent:2em;">daunele provocate de poluare în teritoriu, pe marea teritorială și în zona economică exclusivă sau echivalentă din orice țară care este parte la convenție;</p><br>

<p>măsurile luate pentru prevenirea sau minimizarea unor astfel de daune.</p><br>

<p style="text-indent:2em;">Convenția nu se aplică navelor de război, navelor auxiliare sau altor nave deținute de către o țară. Cu toate acestea, orice țară care face parte din convenție poate decide 
să o aplice acestor nave.</p><br>

<p style="font-weight:500;">Răspunderea armatorului</p>

<p style="text-indent:2em;"></p><h6>În momentul unui incident, armatorul este responsabil de toate daunele provocate de poluarea cu hidrocarburile utilizate pentru propulsia navei. Cu toate acestea, armatorul va
 fi exonerat de răspundere dacă poate să dovedească faptul că:</h6><p></p>

<p style="text-indent:2em;">prejudiciul a fost cauzat de un act de război, ostilități, insurecție sau de un fenomen natural cu caracter excepțional, inevitabil și căruia nu i se poate opune rezistență;</p><br> 

<p>sau</p><br>

<p>prejudiciul a fost cauzat de o acțiune sau de o omisiune comisă de o terță parte cu intenția de a provoca prejudicii;</p><br> 

<p>sau</p><br>

<p>prejudiciul a fost cauzat în întregime de neglijența oricărui guvern sau a oricărei alte autorități responsabile.</p><br>

<p style="font-weight:500;">Asigurarea obligatorie sau garanția financiară</p><br>

<p style="text-indent:2em;">Proprietarii de nave cu un tonaj brut mai mare de 1 000 de tone și care sunt înregistrate într-o țară care face parte din convenție trebuie să mențină o asigurare sau o altă garanție 
financiară, precum garanția unei bănci sau a unei instituții financiare similare, pentru a-și acoperi pierderea din cauza daunelor provocate de poluare.</p><br>

<p style="text-indent:2em;">Autoritatea competentă a unei țări eliberează pentru fiecare navă un certificat care atestă că este în vigoare asigurarea sau altă garanție financiară, iar acesta trebuie să fie păstrat
 la bordul navei. De asemenea, o copie a certificatului trebuie să fie depusă la autoritatea care păstrează registrul de înmatriculare a navei.</p><br>

<p style="font-weight:500;">Competența judiciară și executarea hotărârilor</p><br>

<p style="text-indent:2em;">Acțiunea judiciară pentru compensația solicitată de la armator, asigurător sau altă persoană care asigură garanția trebuie introdusă în țara sau în țările în care au fost provocate
 daunele din cauza poluării.</p><br>

<p style="text-indent:2em;">Convenția definește condițiile în care o hotărâre pronunțată de o instanță dintr-o țară care este parte la convenție poate fi recunoscută sau executată în altă țară.</p><br>

<p style="font-weight:500;">Termene</p><br>

<p style="text-indent:2em;">Drepturile la compensație în temeiul acestei convenții vor expira dacă nu se introduce nicio acțiune în termen de 3 ani de la data la care s-a produs prejudiciul.</p><br>

<p>Nu se poate introduce nicio acțiune după mai mult de 6 ani de la data incidentului care a provocat prejudiciul.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ CONVENȚIA?</p><br>

<p style="text-indent:2em;">Decizia Consiliului a solicitat țărilor UE să ia măsurile necesare pentru a depune instrumentele de ratificare sau instrumentele de aderare, dacă este posibil,
 înainte de 30 iunie 2006.</p><br>

<p style="font-weight:500;">EFECTE</p><br>

<p>Începând din 2015, toate cele 28 de țări ale UE au ratificat/aderat la convenție în conformitate cu decizia Consiliului.</p><br>

<p style="font-weight:500;">Căi navigabile interioare</p><br>

<p style="font-weight:500;">Promovarea transportului pe căile navigabile interioare „NAIADES”</p><br>

<p style="text-indent:2em;">Căile navigabile interioare pot să aducă o contribuție majoră la realizarea unui sistem de transport durabil la nivelul Uniunii Europene (UE), reducând blocajele și poluarea de pe drumurile
 europene. Cu toate acestea, sunt necesare inițiative la nivel național și comunitar pentru a-și dezvolta întregul potențial.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Comunicarea Comisiei privind promovarea transportului pe căile navigabile interioare „NAIADES”: un program de acțiune european integrat pentru transportul pe căile navigabile interioare
 [COM(2006) 6 final, 17.1.2006].</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Căile navigabile interioare pot să aducă o contribuție majoră la realizarea unui sistem de transport durabil la nivelul Uniunii Europene (UE), reducând blocajele și poluarea de pe drumurile
 europene. Cu toate acestea, sunt necesare inițiative la nivel național și comunitar pentru a-și dezvolta întregul potențial.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI COMUNICĂRI?</p><br>

<p style="text-indent:2em;">Aceasta prezintă o strategie integrată pentru a spori atractivitatea și fezabilitatea utilizării căilor navigabile interioare. Programul comunitar de acțiune intitulat Acțiune și dezvoltare
 privind navigația și căile navigabile interioare în Europa (Navigation And Inland Waterway Action and Development in Europe - NAIADES) vizează cinci domenii strategice.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Piețele: este necesară dezvoltarea de oportunități, în special în domenii de nișă precum transportul deșeurilor, al mărfurilor periculoase, al încărcăturilor extrem de mari și transporturile
 fluviale-maritime.</p><br>

<p style="font-weight:500;">Flota: sunt necesare investiții continue în modernizare și inovare pentru a îmbunătăți proiectarea navelor, a reduce consumul de combustibil și a îmbunătăți tehnologia siguranței.</p><br>

<p style="text-indent:2em;">Locuri de muncă și competențe: condiții de lucru și sociale mai bune împreună cu punerea la dispoziție a unor mijloace corespunzătoare de educație și de formare, pentru a încuraja oamenii
 să lucreze în acest sector.</p><br>

<p style="font-weight:500;">Imaginea: imaginea depășită a navigației interioare trebuie să se îmbunătățească și să se modernizeze pentru a reflecta corect performanța tehnologică obținută.</p><br>

<p style="font-weight:500;">Infrastructura: un plan de dezvoltare european ar putea înlătura numeroasele blocaje existente, precum podurile joase și ecluzele înguste. Această acțiune trebuie însoțită de modernizarea
 structurii organizaționale în ansamblu pentru a înlătura obstacolele administrative.</p><br>

<p style="text-indent:2em;">Comisia a actualizat programul NAIADES în 2013 (NAIADES II), stabilind obiective specifice până în anul 2020. Rolul programului este să creeze condițiile necesare pentru ca navigația interioară
 să devină un mijloc de transport de calitate.</p><br>

<p style="text-indent:2em;">În 2014, UE a adoptat un act legislativ pentru a permite guvernelor să ia măsuri în vederea îmbunătățirii competitivității sectorului prin intermediul regimului de pensionare anticipată și
 printr-o utilizare mai amplă a programelor de inovare, de formare și de siguranță.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p style="text-indent:2em;">Peste 37 000 de kilometri de căi navigabile și sute de porturi fluviale unesc numeroasele zone industriale importante din Europa. Această rețea poate să joace un rol durabil important
 în contextul creșterii volumului transporturilor de marfă.</p><br>

<p>Pentru mai multe informații, consultați site-ul Comisiei Europene privind căile navigabile interioare.</p><br>

<p style="font-weight:500;">Transport fluvial sigur și eficient: serviciile de informații fluviale</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva stabilește norme privind utilizarea serviciilor de informații fluviale (RIS)* armonizate. Aceste norme sunt concepute pentru a se garanta siguranța, eficiența navigației
 interioare și respectul pentru mediu în UE. Normele se aplică canalelor, râurilor, lacurilor și porturilor care permit traficul navelor între 1 000 și 1 500 de tone.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Serviciile de informații fluviale cuprind:</h6><p></p>

<p>informații geografice, hidrologice și administrative privind căile navigabile („informații privind pasele de navigație”);</p><br>

<p style="text-indent:2em;">informații care afectează deciziile imediate, pe termen mediu și lung privind navigația („informații tactice și strategice privind traficul”);</p><br>

<p>acțiuni în cazul unui accident („sprijin pentru atenuarea catastrofelor”);</p><br>

<p>statistici, servicii vamale, redevențe de cale navigabilă și taxe portuare.</p>

<p style="text-indent:2em;"></p><h6>Țările UE trebuie:</h6><p></p>

<p>să asigure ca aplicarea RIS să fie eficientă, să poată fi extinsă și să poată interacționa cu alte sisteme;</p><br>

<p>să furnizeze tuturor utilizatorilor RIS datele relevante privind navigația și planificarea curselor;</p><br>

<p>să stabilească centre RIS în conformitate cu necesitățile regionale;</p><br>

<p>să desemneze autoritățile care să supravegheze aplicarea RIS și schimbul internațional de date.</p>

<p style="text-indent:2em;"></p><h6>Comisia Europeană este responsabilă pentru definirea, până la termenele stabilite, a orientărilor tehnice pentru planificarea, punerea în aplicare și exploatarea operațională a RIS.
 Printre acestea se numără:</h6><p></p>

<p>un sistem de afișare a hărților electronice și de informare;</p><br>

<p>rapoarte electronice transmise de nave;</p><br>

<p>notificări către comandanții navelor;</p><br>

<p>sisteme de reperare și de urmărire a navelor;</p><br>

<p>compatibilitatea echipamentelor RIS.</p><br>

<p>Comisia, asistată de un comitet de experți naționali, a dezvoltat cerințe tehnice pentru RIS.</p><br>

<p style="font-weight:500;">Program de acțiune</p><br>

<p style="text-indent:2em;">În 2006, Comisia a prezentat o comunicare privind un program de acțiune european integrat pentru transportul pe căi navigabile interioare. Cunoscut sub denumirea de Naiades (Navigation and
 inland waterway action and development in Europe), programul a fost actualizat în 2013 (Naiades II) și s-au stabilit obiective specifice până în anul 2020. Comunicarea Comisiei cu privire la Naiades
 II are ca scop crearea condițiilor pentru ca transportul pe căile navigabile interioare să devină un mijloc de transport de calitate.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p style="text-indent:2em;">Directiva a intrat în vigoare la 20 octombrie 2005. Țările UE au trebuit să o încorporeze în legislația națională până la 20 octombrie 2007.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p>UE dispune de peste 37 000 km de canale și râuri care fac legătura dintre orașe și zone economice cheie.</p><br>

<p style="text-indent:2em;">Aceste căi navigabile interioare aduc o contribuție majoră unui sistem durabil de transport, reducând congestia și poluarea pe drumurile UE.</p><br>

<p style="font-weight:500;">Portalul serviciilor de informații fluviale</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="font-weight:500;">Servicii de informații fluviale (RIS): acestea sprijină gestionarea traficului și transportului pe căile navigabile interioare și, ori de câte ori este posibil din punct de vedere tehnic,
 coordonarea cu alte moduri de transport.</p><br>

<p style="font-weight:500;">Transportul de mărfuri și de călători pe căile navigabile interioare</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Obiectivul regulamentului este de a garanta faptul că operatorii care transportă mărfuri sau călători pe căile navigabile interioare au libertatea de a furniza aceste servicii de
 transport între țările UE.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Regulamentul se aplică transportului de mărfuri sau de călători pe căile navigabile interioare între țările UE și în tranzit prin acestea.</p>

<p style="text-indent:2em;"></p><h6>Toți operatorii care transportă mărfuri sau călători pe căile navigabile interioare pot să furnizeze aceste servicii între țările UE, cu condiția ca aceștia:</h6><p></p>

<p>să fie înregistrați într-o țară a UE în conformitate cu legislația din țara respectivă;</p><br>

<p style="text-indent:2em;">să aibă dreptul în țara respectivă de a efectua transport internațional de mărfuri sau de călători pe căile navigabile interioare;</p><br>

<p style="text-indent:2em;">să folosească nave înregistrate într-o țară a UE sau, dacă nu au nave înregistrate, să posede un certificat de membru al flotei unei țări a UE;</p><br>

<p style="text-indent:2em;">să respecte cerințele stabilite în Regulamentul (CEE) nr. 3921/91 în temeiul cărora operatorii nerezidenți pot transporta mărfuri sau călători pe căile navigabile interioare într-o țară a
 UE (servicii denumite „cabotaj”).</p><br>

<p style="text-indent:2em;">Convenția revizuită privind navigația pe Rin (Convenția de la Mannheim) și Convenția privind navigația pe Dunăre (Convenția de la Belgrad) prevăd norme speciale privind drepturile operatorilor
 din țările din afara UE. De asemenea, astfel de norme speciale pot rezulta și din alte acorduri sau tratate internaționale la care UE este parte semnatară.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Regulamentul se aplică începând cu 2 august 1996.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p style="text-indent:2em;">Pentru informații suplimentare, consultați pagina referitoare la căile navigabile interioare de pe site-ul web al Comisiei Europene.</p><br>

<p style="font-weight:500;">Norme privind promovarea transportului pe căile navigabile interioare în UE</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Alături de regulamentele de modificare aferente, acest regulament stabilește normele unei politici privind capacitatea flotei UE.</p><br>

<p>Obiectivul regulamentului este de a încuraja dezvoltarea unui transport durabil și competitiv pe căile navigabile interioare.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Țările UE cu un tonaj al flotei de peste 100 000 de tone și ale căror ape interioare se leagă cu cele ale altor țări din UE au obligația de a stabili un Fond pentru navigația interioară
 (cu fonduri de rezervă separate pentru navele pentru marfă uscată*, navele-cisternă* și remorcherele-împingătoare*).</p><br>

<p style="text-indent:2em;">Regulamentul vizează navele care transportă marfă în scopuri comerciale. Există diferite excepții, cum ar fi pentru navele care circulă pe Dunăre sau care sunt utilizate exclusiv pentru
 depozitarea mărfurilor sau pentru dragare.</p>

<p style="text-indent:2em;"></p><h6>718/1999, acest fonduri trebuiau administrate de autoritățile naționale și puteau fi utilizate în două situații:</h6><p></p>

<p>în cazul unei „perturbări grave a pieței” de transport pe căi navigabile interioare (în sensul Directivei 96/75/CE);</p><br>

<p>și</p><br>

<p style="text-indent:2em;">la cererea unanimă a organizațiilor care reprezintă transportul pe căile navigabile interioare. La începutul anului 2014, aceste fonduri de rezervă nu fuseseră utilizate niciodată.</p><br>

<p style="text-indent:2em;">Regulamentul (CE) nr. 718/1999 cuprindea o regulă „vechi pentru nou”. Aceasta însemna că orice proprietar care dorea să aducă în flotă o navă nouă trebuia fie să taie pentru fier vechi
 tonajul navei vechi, fie să plătească o sumă de bani. Această regulă a luat sfârșit în temeiul Regulamentului (CE) nr. 411/2003, ea putând fi reactivată, cu sau fără măsurile de îmbunătățire
 structurală, numai în cazul unei perturbări grave a pieței de tipul celor la care se face referire în Directiva 96/75/CE.</p><br>

<p style="font-weight:500;">O serie extinsă de măsuri</p>

<p style="text-indent:2em;"></p><h6>În temeiul Regulamentului (UE) nr. 546/2014, domeniul de aplicare a măsurilor disponibile în temeiul Regulamentului (CE) nr. 718/1999 a fost modificat și extins. Printre măsuri se numără:</h6><p></p>

<p style="text-indent:2em;">ajutorul (cum ar fi informațiile) acordat transportatorilor fluviali care se retrag pentru obținerea unei pensii anticipate sau pentru transferul la o altă activitate economică;</p><br>

<p>programe de calificare sau recalificare pentru acei membri ai echipajelor care se retrag;</p><br>

<p style="text-indent:2em;">îmbunătățirea capacităților de navigare pe căi interioare, precum și a cunoștințelor și a logisticii, pentru a proteja dezvoltarea și viitorul profesiei;</p><br>

<p style="text-indent:2em;">încurajarea operatorilor-proprietari să facă parte din asociații profesionale și să consolideze organizațiile care reprezintă transporturile pe căi navigabile interioare la nivelul UE;</p><br>

<p>încurajarea modernizării navelor pentru a îmbunătăți condițiile de muncă și siguranța;</p><br>

<p>încurajarea inovării în ceea ce privește navele și performanțele lor de mediu îmbunătățite;</p><br>

<p style="text-indent:2em;">încurajarea utilizării fondurilor de rezervă alături de instrumente financiare precum Orizont 2020 și mecanismul Conectarea Europei.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Regulamentul (CE) nr. 718/1999 se aplică de la 29.4.1999.</p><br> 

<p>Regulamentul (UE) nr. 546/2014 se aplică de la 18.6.2014.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="font-weight:500;">Nave pentru marfă uscată: nave care transportă marfă uscată (de exemplu cereale).</p><br>

<p style="font-weight:500;">Nave-cisternă: nave care transportă marfă în stare lichidă (de exemplu petrol).</p><br>

<p style="font-weight:500;">Remorchere-împingătoare: nave care au rolul de a împinge alte nave, cum sunt barjele, fără a transporta însă marfă.</p><br>

<p style="font-weight:500;">Transportul interior de mărfuri periculoase</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva prevede norme comune pentru transportul în condiții de siguranță și securitate al mărfurilor periculoase pe teritoriul țărilor Uniunii Europene (UE) și între acestea, pe cale rutieră,
 feroviară sau pe căi navigabile interioare. De asemenea, directiva vizează aspecte precum încărcarea și descărcarea, transferul dinspre sau înspre alt mod de transport, precum și staționările
 pe parcursul procesului de transport. Actul legislativ extinde aplicarea normelor internaționale la transportul intern de mărfuri periculoase.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Excluderi</p>

<p style="text-indent:2em;"></p><h6>Directiva nu se aplică transportului de mărfuri periculoase realizat cu:</h6><p></p>

<p>vehicule, vagoane sau nave aparținând forțelor armate sau aflate sub răspunderea acestora;</p><br>

<p>nave maritime pe căi navigabile maritime care fac parte din căile navigabile interioare;</p><br>

<p>feriboturi care traversează numai căi navigabile interioare sau un port;</p><br>

<p>efectuat integral în perimetrul unei zone închise.</p><br>

<p style="font-weight:500;">Norme naționale</p>

<p style="text-indent:2em;"></p><h6>Din alte motive decât cele care țin de siguranța pe durata transportului (de exemplu, din motive de securitate națională sau de protecție a mediului), țările UE au dreptul
 de a reglementa sau interzice transportul de mărfuri periculoase pe teritoriul propriu. De asemenea, acestea pot stabili cerințe specifice de siguranță pentru transportul
 național și internațional de mărfuri periculoase pe teritoriul lor în ceea ce privește:</h6><p></p>

<p style="text-indent:2em;">transportul de mărfuri periculoase realizat cu vehicule, vagoane sau nave destinate căilor navigabile interioare, care nu face obiectul acestei directive;</p><br>

<p>în cazuri justificate, utilizarea unor rute prestabilite, inclusiv utilizarea unor moduri de transport prestabilite;</p><br>

<p>normele speciale pentru transportul de mărfuri periculoase în trenuri de călători.</p><br>

<p style="font-weight:500;">Acorduri internaționale</p><br>

<p style="text-indent:2em;">Transportul internațional de mărfuri periculoase este reglementat de Acordul european referitor la transportul rutier internațional al mărfurilor periculoase (ADR)*, de Acordul european privind
 transportul internațional al mărfurilor periculoase pe căi navigabile interioare (ADN)* și de Regulamentul privind transportul internațional feroviar al mărfurilor periculoase (RID)*. Aceste norme
 internaționale trebuie să fie extinse la transportul național pentru a armoniza în întreaga Comunitate condițiile în care sunt transportate mărfurile periculoase și pentru a asigura funcționarea
 adecvată a pieței comune a UE în domeniul transporturilor. În anexele la directivă se face referire la textele acestor acorduri.</p><br>

<p style="font-weight:500;">ADR, RID și ADN au elaborat o listă cu mărfurile periculoase, indicând dacă transportul acestora este interzis sau nu și definind cerințele pentru transportul lor, în cazul în care acesta este autorizat.
 Țările UE pot solicita derogări temporare în anumite condiții.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p>Începând cu 20 octombrie 2008.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="font-weight:500;">ADR: Acordul european referitor la transportul rutier internațional al mărfurilor periculoase, încheiat la Geneva la 30 septembrie 1957.</p><br>

<p style="font-weight:500;">ADN: Acordul european privind transportul internațional al mărfurilor periculoase pe căi navigabile interioare, încheiat la Geneva la 26 mai 2000.</p><br>

<p style="font-weight:500;">RID: Regulamentul privind transportul internațional feroviar al mărfurilor periculoase, care figurează în anexa C la Convenția privind transporturile internaționale
 feroviare (COTIF), încheiată la Vilnius la 3 iunie 1999.</p><br>

<p style="font-weight:500;">Statistici UE privind transporturile de mărfuri pe căi navigabile interioare</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul stabilește normele pentru realizarea de statistici comparabile la nivelul întregii UE referitoare la transporturile de mărfuri pe căi navigabile interioare.</p><br>

<p style="text-indent:2em;">Acesta urmărește să ofere o imagine asupra volumului și a performanței transportului de mărfuri în rețeaua UE de căi navigabile interioare.</p><br>

<p>Regulamentul codifică și abrogă Regulamentul (CE) nr. 1365/2006, care a fost modificat substanțial de mai multe ori.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Domeniul de aplicare</p><br>

<p style="text-indent:2em;">Țările UE sunt obligate să compileze și să transmită Comisiei Europene (Eurostat) date privind transporturile pe căile navigabile interioare.</p>

<p style="text-indent:2em;"></p><h6>Regulamentul nu se aplică transportului de mărfuri cu nave cu tonaj mai mic de 50 de tone:</h6><p></p>

<p>navelor utilizate în principal pentru transportul de pasageri;</p><br>

<p>navelor utilizate în scop de feribot;</p><br>

<p>navelor utilizate exclusiv în scopuri necomerciale de către administrațiile portuare și autoritățile publice;</p><br>

<p>navelor utilizate exclusiv în scop de buncăr sau pentru depozitare;</p><br>

<p style="text-indent:2em;">navelor care nu sunt utilizate pentru transportul mărfurilor, precum navele de pescuit, dragele, atelierele plutitoare, casele plutitoare și ambarcațiunile de agrement.</p><br>

<p style="text-indent:2em;">Țările UE în care volumul total de mărfuri transportate anual pe căi navigabile interioare ca transporturi naționale, internaționale sau de tranzit depășește un milion de tone trebuie să
 furnizeze datele menționate în anexele I-IV la regulament.</p><br>

<p style="text-indent:2em;">Țările UE în care nu sunt transporturi internaționale sau de tranzit pe căi navigabile interioare, dar în care volumul total de mărfuri transportate anual pe căi navigabile interioare ca
 transporturi naționale depășește un milion de tone trebuie să furnizeze datele menționate în Anexa V la regulament.</p><br>

<p>Acest regulament se aplică doar acelor țări în care acest mod de transport există.</p><br>

<p style="font-weight:500;">Studii-pilot</p><br>

<p style="text-indent:2em;">Până la 8 decembrie 2018, Comisia Europeană va elabora, în cooperare cu țările UE, metodologia adecvată pentru elaborarea statisticilor privind transportul de călători pe căile navigabile 
interioare, inclusiv prin intermediul serviciilor de transport transfrontalier.</p><br>

<p style="text-indent:2em;">În 2019, Comisia va lansa studii-pilot care să fie realizate de țările UE pe bază de voluntariat și care să furnizeze date din domeniul de aplicare al prezentului regulament cu privire la 
disponibilitatea datelor statistice referitoare la transportul de călători pe căile navigabile interioare. Aceste studii-pilot vor evalua fezabilitatea noilor colectări de date, costurile 
colectărilor de date aferente, precum și implicit calitatea statisticilor.</p><br>

<p style="text-indent:2em;">Până la 8 decembrie 2020, Comisia va trebui să prezinte Parlamentului European și Consiliului un raport cu privire la rezultatele studiilor-pilot.</p><br>

<p style="font-weight:500;">Culegerea și transmiterea datelor</p><br>

<p style="text-indent:2em;">Datele sunt raportate pe baza principiului teritorialității, conform căruia fiecare țară raportează datele privind încărcările, descărcările și deplasările de mărfuri ce au loc pe 
teritoriul său național.</p><br>

<p>Tabelele prezentate în anexele I-V la regulament stabilesc normele și formatul referitoare la colectarea datelor.</p><br>

<p style="text-indent:2em;">Țările UE trebuie să transmită Eurostat datele colectate în cel mult cinci luni de la sfârșitul perioadei relevante de observație.</p><br>

<p>Comisia va adopta acte de punere în aplicare de stabilire a modalităților de transmitere a datelor către Eurostat.</p><br>

<p style="font-weight:500;">Difuzarea datelor</p><br>

<p>Eurostat difuzează datele cu o frecvență similară celei stabilite pentru transmiterea datelor (trimestrial sau anual).</p><br>

<p style="font-weight:500;">Calitatea datelor</p><br>

<p style="text-indent:2em;">Comisia adoptă acte de punere în aplicare de stabilire a criteriilor și cerințelor metodologice destinate să asigure calitatea datelor produse.</p><br>

<p style="text-indent:2em;">Țările UE trebuie să asigure calitatea datelor transmise și să furnizeze Comisiei (Eurostat) un raport care conține informațiile și datele pe care aceasta le poate solicita în vederea 
verificării calității datelor transmise.</p><br>

<p style="font-weight:500;">Raportul privind punerea în aplicare</p><br>

<p style="text-indent:2em;">Comisia (Eurostat) trebuie să prezinte un raport – prima oară până la 31 decembrie 2020 și, ulterior, la fiecare cinci ani – Parlamentului European și Consiliului privind punerea în aplicare
 a regulamentului și evoluțiile viitoare.</p><br>

<p style="font-weight:500;">Procedura comitetului</p><br>

<p style="text-indent:2em;">Comisia este sfătuită și asistată de Comitetul Sistemului Statistic European, înființat prin Regulamentul (CE) nr. 223/2009 (Cum funcționează Sistemul Statistic European).</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p style="text-indent:2em;">Se aplică de la 5 august 2018. Regulamentul (UE) 2018/974 codifică și înlocuiește Regulamentul (CE) nr. 1365/2006 și modificările ulterioare ale acestuia.</p><br>

<p style="font-weight:500;">Securitatea maritimă</p><br>

<p style="font-weight:500;">Intensificarea cooperării europene în domeniul supravegherii maritime</p><br>

<p style="text-indent:2em;">Reunirea datelor de supraveghere maritimă de care dispun autoritățile civile și militare poate contribui la evitarea suprapunerii eforturilor și la reducerea costurilor. Sporirea cooperării 
și schimbul de informații pot eficientiza, de asemenea, intervențiile în timp real în cazul unor evenimente produse pe mare, cum sunt accidentele, incidentele de poluare, infracțiunile sau
 amenințările la adresa securității.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Comunicare a Comisiei către Parlamentul European și Consiliu - O mai bună cunoaștere a situației prin intermediul unei mai strânse cooperări între autoritățile care se ocupă de supravegherea
 maritimă: următoarele etape în cadrul mediului comun pentru schimbul de informații pentru domeniul maritim al UE [COM(2014) 451 final din 8 iulie 2014]</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Reunirea datelor de supraveghere maritimă de care dispun autoritățile civile și militare poate contribui la evitarea suprapunerii eforturilor și la reducerea costurilor. Sporirea cooperării
 și schimbul de informații pot eficientiza, de asemenea, intervențiile în timp real în cazul unor evenimente produse pe mare, cum sunt accidentele, incidentele de poluare, infracțiunile sau
 amenințările la adresa securității.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI COMUNICĂRI?</p><br>

<p style="text-indent:2em;">Comunicarea prezintă progresele înregistrate în mediul comun pentru schimbul de informații (Common Information Sharing Environment - CISE) de la instituirea acestuia de către Comisia Europeană
 în 2010. Documentul propune orientări și priorități suplimentare la nivelul Uniunii Europene (UE) și la nivel național.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">CISE este unul dintre obiectivele strategice cheie ale politicii maritime integrate a UE și o componentă importantă a Strategiei de securitate maritimă.</p><br>

<p style="font-weight:500;">Ce este CISE pentru domeniul maritim?</p><br>

<p style="text-indent:2em;">Acesta este un proces colaborativ voluntar care are la bază sistemele și platformele existente de schimb și partajare de informații. Îmbunătățirea interoperabilității acestora ar trebui
 să optimizeze schimbul de informații între autoritățile de supraveghere, ducând la o mai bună reprezentare a situației maritime. Mai mult, principalul obiectiv al CISE pentru domeniul
 maritim este de a spori siguranța, securitatea și protecția mediului în zonele maritime ale UE.</p><br>

<p style="font-weight:500;">Care sunt avantajele CISE?</p><br>

<p style="text-indent:2em;">îmbunătățirea cunoștințelor și a gradului de conștientizare a situației maritime, permițând îmbunătățirea coordonării și sporirea promptitudinii operațiunilor de supraveghere;</p><br>

<p>o reducere substanțială în eforturile de colectare a datelor;</p><br>

<p>reducerea costurilor administrative și operaționale ale activităților de supraveghere maritimă.</p>

<p style="text-indent:2em;"></p><h6>Realizări la nivelul UE:</h6><p></p>

<p style="text-indent:2em;"></p><h6>S-au instituit mai multe sisteme în diferite domenii de politică, care acoperă diferite sectoare. Acestea cuprind:</h6><p></p>

<p style="text-indent:2em;">SafeSeaNet, care furnizează servicii maritime integrate pentru monitorizarea traficului (cunoașterea situației spațiale) și pentru a asigura implementarea legislației europene;</p><br>

<p style="text-indent:2em;">Sistemul comun de comunicare și informare în caz de urgență, care facilitează comunicarea în timpul incidentelor maritime și al dezastrelor;</p><br>

<p style="text-indent:2em;">Sistemul de monitorizare a navelor, un sistem de monitorizare prin satelit care furnizează autorităților din domeniul pescuitului date privind poziția, traiectoria și viteza navelor;</p><br>

<p style="text-indent:2em;">Rețeaua de supraveghere maritimă, care permite schimbul de informații și servicii operaționale maritime, cum ar fi pozițiile navelor, traiectoria acestora, date de identificare,
 discuții sau imagini;</p><br>

<p style="text-indent:2em;">Sistemul european de supraveghere a frontierelor (Eurosur), un sistem polivalent pentru prevenirea imigrației ilegale și a infracțiunii transfrontaliere la frontierele externe ale UE;</p>

<p style="text-indent:2em;"></p><h6>Agenția Europeană pentru Gestionarea Cooperării Operative la Frontierele Externe - Frontex, care promovează, coordonează și elaborează gestionarea frontierelor europene:</h6><p></p>

<p style="text-indent:2em;">ghișeele naționale unice, care constituie platforme naționale de schimb de informații pentru difuzarea și schimbul de informații legate de nave și sunt corelate cu sistemul
 Uniunii de schimb de informații maritime.</p><br>

<p style="font-weight:500;">Care sunt domeniile prioritare în care se impune intensificarea cooperării transfrontaliere și intersectoriale?</p><br>

<p style="text-indent:2em;">schimbul în timp real de poziții ale navelor și aeronavelor de patrulare pentru a asigura un răspuns cât mai rapid la operațiunile de salvare și/sau la alte evenimente pe mare;</p><br>

<p>instrumente de colaborare pentru gestionarea crizelor transfrontaliere;</p><br>

<p>combinarea datelor și schimbul de informații privind navele suspecte care navighează în apele UE;</p><br>

<p style="text-indent:2em;">registrele naționale ale ambarcațiunilor de agrement, pentru a permite prelucrarea computerizată a solicitărilor de informații între țările UE.</p>

<p style="text-indent:2em;"></p><h6>Comisia propune și alte măsuri. De exemplu, aceasta intenționează:</h6><p></p>

<p style="text-indent:2em;">să elaboreze, până la sfârșitul anului 2016, un ghid CISE pentru domeniul maritim fără caracter obligatoriu, cu recomandări de bune practici și informații privind modul de aplicare a CISE;</p><br>

<p style="text-indent:2em;">să sprijine măsurile destinate să elaboreze, să mențină și să disemineze standarde care să permită sistemelor de supraveghere maritimă să fie interoperabile;</p><br>

<p style="text-indent:2em;">să examineze în continuare legislația sectorială existentă la nivelul UE în scopul de a înlătura eventualele obstacole de natură juridică rămase în calea schimbului intersectorial de
 informații, asigurând în același timp respectarea cerințelor relevante privind protecția datelor.</p><br>

<p style="text-indent:2em;">Până în 2018, Comisia va lansa, de asemenea, un proces de revizuire pentru a evalua implementarea CISE pentru domeniul maritim și necesitatea unor acțiuni ulterioare.</p><br>

<p style="text-indent:2em;">Pentru informații suplimentare, consultați pagina referitoare la supravegherea maritimă integrată pe site-ul Comisiei Europene.</p><br>

<p style="font-weight:500;">Securitatea navelor și a instalațiilor portuare</p><br>

<p style="font-weight:500;">CARE ESTE OBIECTIVUL ACESTUI REGULAMENT?</p><br>

<p style="text-indent:2em;">Regulamentul introduce măsuri de consolidare a securității traficului maritim* internațional și național, precum și a instalațiilor portuare împotriva actelor ilegale intenționate.</p><br>

<p style="text-indent:2em;">Acesta asigură punerea uniformă în aplicare, în întreaga Uniune Europeană (UE), a măsurilor de securitate asupra cărora a convenit Organizația Maritimă Internațională (OMI) în decembrie
 2002, când a modificat Convenția internațională pentru siguranța vieții pe mare din 1974 (SOLAS).</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Toate țările UE au avut obligația de a aplica pe deplin varianta modificată a SOLAS și Codul internațional pentru securitatea navelor și a instalațiilor portuare în traficul maritim
 internațional până cel târziu la 1 iulie 2004. Pentru principalele nave de pasageri din traficul național, termenul a fost 1 iulie 2005.</p><br>

<p style="text-indent:2em;">Până la 1 iulie 2007, toate țările UE, în urma unei evaluări obligatorii a riscurilor și a securității, au avut obligația de a decide în ce măsură aplică măsurile în cazul altor categorii
 de transport maritim intern.</p><br>

<p style="text-indent:2em;">Fiecare țară a UE informează OMI, Comisia Europeană și alte țări ale UE cu privire la măsurile pe care le-a luat. Printre acestea se numără datele punctelor de contact pentru securitatea
 maritimă desemnate de fiecare țară a UE.</p>

<p style="text-indent:2em;"></p><h6>Navele care fac obiectul unor măsuri speciale și intenționează să intre într-un port al UE trebuie să furnizeze autorităților naționale competente informații privind securitatea:</h6><p></p>

<p>cu cel puțin 24 de ore înainte</p><br> 

<p>sau</p><br>

<p>cel târziu în momentul în care nava iese din portul anterior, în cazul în care durata călătoriei este mai mică de 24 de ore,</p><br> 

<p>sau</p><br>

<p>de îndată ce se stabilește care este portul de escală, în cazul în care acesta era necunoscut în momentul plecării.</p><br>

<p style="text-indent:2em;">Serviciile regulate de transport maritim care navighează între porturi aflate pe teritoriul propriu sau între două țări ale UE pot fi exceptate de la obligația de a furniza informații 
înainte de sosire, cu condiția să furnizeze totuși informațiile respective la cererea autorităților.</p><br>

<p style="text-indent:2em;">Autoritățile naționale țin o listă a companiilor și a navelor care beneficiază de exceptare și actualizează periodic această listă.</p><br>

<p>Până la 1 iulie 2004, țările UE au avut obligația de a desemna un punct de contact pentru securitatea maritimă.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p>Regulamentul se aplică începând cu 1 iulie 2004.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="font-weight:500;">Securitate maritimă: combinarea măsurilor preventive menite să protejeze transportul maritim și instalațiile portuare în fața amenințării reprezentate de actele ilegale intenționate.</p><br>

<p><a href="https://eur-lex.europa.eu/summary/chapter/transport/3204.html?root=3204">Sursa</a></p>

</div>
`