export const int16 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Международная Конвенция о согласовании условий проведения контроля грузов на границах
(Женева, 21 октября 1982 г.)</h4><p></p>

<p style="text-indent:2em;"></p><h6>Договаривающиеся Стороны, желая улучшить международные перевозки грузов, принимая во 
внимание необходимость содействия прохождению грузов через границы, отмечая, что меры
 контроля осуществляются на границах различными службами контроля, признавая, что условия
 проведения этих видов контроля могут быть в значительной мере согласованы без нанесения 
 ущерба их целям, их надлежащему проведению и без снижения их эффективности, будучи убеждены,
 что согласование условий проведения пограничного контроля представляет собой одной из важных
 средств для достижения этих целей, согласились о нижеследующем:</h6><p></p>
 
<p></p><h4>Глава I. Общие положения</h4><p></p>

<p style="font-weight:500;">Статья 1. Определения</p>

<p style="text-indent:2em;"></p><h6>В соответствии с настоящей Конвенцией:</h6><p></p>

<p style="text-indent:2em;">a) под "таможней" подразумевается государственная служба, которая несет ответственность за
 обеспечение выполнения таможенного законодательства и сбор импортных и экспортных пошлин и
 налогов, а также за применение других законов и правил, касающихся, среди прочего, ввоза,
 транзита и вывоза грузов;</p><br>

<p style="text-indent:2em;">b) под "таможенным контролем" подразумеваются меры, применяемые в целях обеспечения выполнения 
законов и правил, за соблюдение которых несут ответственность таможни;</p><br>

<p style="text-indent:2em;">c) под "медико-санитарным контролем" подразумевается контроль, осуществляемый в целях охраны
 жизни и здоровья людей, за исключением ветеринарного контроля;</p><br>

<p style="text-indent:2em;">d) под "ветеринарным контролем" подразумевается санитарный контроль, осуществляемый в отношении 
животных и продуктов животного происхождения с целью защиты жизни и здоровья людей и животных, 
а также контроль предметов или грузов, которые могут являться переносчиками болезней животных;</p><br>

<p style="text-indent:2em;">е) под "фитосанитарным контролем" подразумевается контроль, направленный на предупреждение 
распространения и перевозки через государственные границы вредителей растений и продуктов 
растительного происхождения;</p><br>

<p style="text-indent:2em;">f) под "контролем соответствия техническим стандартам" подразумевается контроль, 
обеспечивающий соответствие грузов минимальным требованиям международным или национальных
 стандартов, определенным соответствующими законами и правилами;</p><br>

<p style="text-indent:2em;">g) под "контролем качества" подразумевается всякий другой контроль, отличающийся от 
вышеперечисленных и осуществляемый с целью обеспечения соответствия грузов минимальным 
международным или национальным показателям качества, установленным соответствующими 
законами и правилами;</p><br>

<p style="text-indent:2em;">h) под "службой контроля" подразумевается любая служба, на которую возложена обязанность 
осуществлять все или некоторые виды контроля, определенные выше, или любые другие виды 
контроля, которым регулярно подвергаются импортные, экспортные или транзитные грузы.</p><br>

<p style="font-weight:500;">Статья 2. Цель Конвенции</p><br>

<p style="text-indent:2em;">Для облегчения международной перевозки грузов настоящая Конвенция имеет целью сокращение 
требований в отношении соблюдения формальностей, а также видов и продолжительности контроля, 
в частности путем национальной и международной координации процедур контроля и методов 
их применения.</p><br>

<p style="font-weight:500;">Статья 3. Сфера применения</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция применяется ко всем грузам, пересекающим при ввозе, вывозе или транзите 
одну или более морских, воздушных или сухопутных границ.</p><br>

<p>2. Настоящая Конвенция применяется ко всем службам контроля Договаривающихся Сторон.</p>

<p></p><h4>Глава II. Согласование процедур</h4><p></p>

<p style="font-weight:500;">Статья 4. Координация видов контроля</p><br>

<p style="text-indent:2em;">Договаривающиеся стороны обязуются по возможности организовывать согласованную работу 
таможенных служб и других служб контроля.</p><br>

<p style="font-weight:500;">Статья 5. Средства, предоставляемые в распоряжение служб</p>

<p style="text-indent:2em;"></p><h6>Для обеспечения надлежащего функционирования служб контроля Договаривающиеся Стороны должны 
обеспечивать, чтобы данные службы имели по мере возможности и в рамках национального 
законодательства в своем распоряжении:</h6><p></p>

<p>а) квалифицированный персонал в достаточном количестве с учетом потребностей перевозок;</p><br>

<p style="text-indent:2em;">б) оборудование и устройства, необходимые для проведения проверки с учетом вида транспорта и 
грузов, подлежащих контролю, и потребностей перевозок;</p><br>

<p style="text-indent:2em;">с) официальные инструкции для должностных лиц, с тем чтобы они действовали в соответствии с 
международными договорами и соглашениями, а также в соответствии с действующими 
национальными предписаниями.</p><br>

<p style="font-weight:500;">Статья 6. Международное сотрудничество</p><br>

<p style="text-indent:2em;">Договаривающиеся Стороны обязуются сотрудничать друг с другом и прибегать к необходимому 
сотрудничеству с компетентными международными органами для достижения целей настоящей 
Конвенции, а также стремиться к заключению в необходимых случаях новых многосторонних 
или двусторонних договоров или соглашений.</p><br>

<p style="font-weight:500;">Статья 7. Сотрудничество между соседними странами</p>

<p style="text-indent:2em;"></p><h6>Во всех случаях пересечения общей сухопутной границы заинтересованные Договаривающиеся 
Стороны принимают, когда это возможно, соответствующие меры для облегчения провоза 
грузов, в частности:</h6><p></p>

<p style="text-indent:2em;">а) они прилагают все усилия для организации совместного контроля грузов и документов 
путем установки совместно используемого оборудования;</p>

<p style="text-indent:2em;"></p><h6>б) они прилагают все усилия для обеспечения того, чтобы были аналогичными:</h6><p></p>

<p>часы работы пограничных постов,</p><br>

<p>службы контроля, которые осуществляют свою деятельность на этих постах,</p><br>

<p style="text-indent:2em;">категории грузов, средства транспорта и системы международного таможенного транзита, 
которые могут быть приняты или использоваться в этих странах.</p><br>

<p style="font-weight:500;">Статья 8. Обмен информацией</p><br>

<p style="text-indent:2em;">Договаривающиеся Стороны обмениваются при наличии соответствующей просьбы информацией, 
необходимой для применения настоящей Конвенции в соответствии с условиями, указанными 
в приложениях.</p><br>

<p style="font-weight:500;">Статья 9. Документы</p><br>

<p style="text-indent:2em;">1. Договаривающиеся Стороны будут стремиться широко использовать в отношениях друг с 
другом и с компетентными международными органами документы, составленные в соответствии 
с образцом-формуляром Организации Объединенных Наций. 2. Договаривающиеся Стороны принимают 
документы, изготовленные с использованием любого соответствующего технического процесса, при 
условии, что они отвечают официальным правилам в отношении их формы, аутентичности и заверения
, а также при условии, что они легко читаемы и понятны. 3. Договаривающиеся стороны обеспечивают,
 чтобы необходимые документы подготавливались и заверялись в полном соответствии с 
 соответствующим законодательством.</p>

<p></p><h4>Глава III. Положения, относящиеся к транзиту</h4><p></p>

<p style="font-weight:500;">Статья 10. Транзитные грузы</p><br>

<p style="text-indent:2em;">1. Договаривающиеся Стороны соглашаются применять, по мере возможности, простой и ускоренный 
режим в отношении транзитных грузов, в особенности грузов, которые перевозятся в соответствии
 с системой международного таможенного транзита, предусматривая проведение их контроля только
 в тех случаях, когда это оправдано сложившимися обстоятельствами или рисками. Кроме того, они
 принимают во внимание положение стран, не имеющих выхода к морю. Они прилагают все усилия для
 того, чтобы предусмотреть увеличение продолжительности времени, в течение которого может
 производиться выдача по уплате таможенной пошлины грузов, перевозимых в соответствии с какой
 -либо системой международного таможенного транзита, и расширение компетенции существующих
 таможен в этой области.</p><br>

<p style="text-indent:2em;">2. Они прилагают все усилия для максимального облегчения транзита грузов, перевозимых в
 контейнерах или других упаковочных средствах, обеспечивающих достаточную безопасность.</p>

<p></p><h4>Глава IV. Прочие положения</h4><p></p>

<p style="font-weight:500;">Статья 11. Общественный порядок</p><br>

<p style="text-indent:2em;">1. Ни одно положение настоящей Конвенции не препятствует введению запретов или ограничений в
 отношении импорта, экспорта или транзита по соображениям общественного порядка и, в частности,
 общественной безопасности, морали и здравоохранения или в целях охраны окружающей среды,
 культурного наследия или промышленной, коммерческой и интеллектуальной собственности.</p><br>

<p style="text-indent:2em;">2. Тем не менее Договаривающиеся Стороны стремятся применять к видам контроля, связанным с
 применением мер, упомянутых в пункте 1 выше, когда это возможно без ущерба для эффективности
 таких видов контроля, положения настоящей Конвенции, в частности положения статей 6 - 9.</p><br>

<p style="font-weight:500;">Статья 12. Чрезвычайные меры</p><br>

<p style="text-indent:2em;">1. Чрезвычайные меры, которые Договаривающиеся стороны могут быть вынуждены ввести ввиду
 особых обстоятельств, должны соответствовать причинам, вызвавшим их введение, и их осуществление
 должно быть приостановлено или отменено при устранении этих причин.</p><br>

<p style="text-indent:2em;">2. Во всех случаях, когда это не наносит ущерба эффективности этих мер, Договаривающиеся
 Стороны публикуют соответствующие положения в отношении таких мер.</p><br>

<p style="font-weight:500;">Статья 13. Приложения</p><br>

<p>1. Приложения к настоящей Конвенции являются неотъемлемой частью данной Конвенции.</p><br>

<p style="text-indent:2em;">2. Новые приложения, касающиеся других видов контроля, могут быть включены в настоящую
 Конвенцию в соответствии с процедурой, определенной в статьях 22 или 24 ниже.</p><br>

<p style="font-weight:500;">Статья 14. Отношение к другим договорам</p><br>

<p style="text-indent:2em;">Без ущерба для положений статьи 6 настоящая Конвенции не влияет на права и обязательства,
 вытекающие из договоров, которые Договаривающиеся Стороны настоящей Конвенции заключили,
 прежде чем стать Договаривающимися Сторонами данной Конвенции.</p><br>

<p style="font-weight:500;">Статья 15</p><br>

<p style="text-indent:2em;">Настоящая Конвенция не препятствует применению более широких льгот, которые две или более
 Договаривающиеся Стороны пожелают предоставить друг другу, и не ущемляет права региональных
 организаций в области экономической интеграции, упомянутых в статье 16, которые являются
 Договаривающимися Сторонами, принимать свое законодательство к контролю на своих внутренних
 границах при условии, что это никоим образом не уменьшает объем льгот, вытекающих из
 настоящей Конвенции.</p><br>

<p style="font-weight:500;">Статья 16. Подписание, ратификация, принятие, утверждение и присоединение</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция, которая сдается на хранение Генеральному секретарю Организации
 Объединенных Наций, открыта для участия всех государств и региональных организаций в
 области экономической интеграции, состоящих из суверенных государств, компетентных в
 отношении ведения переговоров, заключения и применения международных соглашений, касающихся
 вопросов, предусмотренных в настоящей Конвенции.</p><br>

<p style="text-indent:2em;">2. В отношении вопросов, входящих в их компетенцию, региональные организации в области 
экономической интеграции, упомянутые в пункте 1, могут от своего имени осуществлять права
 и выполнять обязательства, предусмотренные настоящей Конвенцией для государств-членов, 
 которые являются Договаривающимися Сторонами данной Конвенции. В этих случаях государства-
 члены таких организаций не могут самостоятельно осуществлять эти права, включая право 
 голосования.</p>

<p style="text-indent:2em;"></p><h6>3. Государства и вышеупомянутые региональные организации в области экономической интеграции
 могут стать Договаривающимися Сторонами настоящей Конвенции:</h6><p></p>

<p>a) путем сдачи на хранение документа о ратификации, принятии или утверждении после ее
 подписания или</p><br>

<p>b) путем сдачи на хранение документа о присоединении.</p><br>

<p style="text-indent:2em;">4. Настоящая Конвенция будет открыта для подписания всеми государствами и упомянутыми в 
пункте 1 региональными организациями в области экономической интеграции в отделении 
Организации Объединенных Наций в Женеве с 1 апреля 1983 года по 31 марта 1984 года 
включительно.</p><br>

<p>5. С 1 апреля 1983 года она будет также открыта для присоединения.</p><br>

<p style="text-indent:2em;">6. Документы о ратификации, принятии, утверждении или присоединении сдаются на хранение 
Генеральному секретарю Организации Объединенных Наций.</p><br>

<p style="font-weight:500;">Статья 17. Вступление в силу</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция вступает в силу по истечении трех месяцев с того дня, когда пять 
государств сдадут на хранение свои документы о ратификации, принятии, утверждении или 
присоединении.</p><br>

<p style="text-indent:2em;">2. После того как пять государств сдадут на хранение свои документы о ратификации, принятии, 
утверждении или присоединении, настоящая Конвенция вступает в силу для всех последующих 
Договаривающихся Сторон по истечении трех месяцев со дня сдачи ими на хранение своих документов
 о ратификации, принятии, утверждении или присоединении.</p><br>

<p style="text-indent:2em;">3. Любой документ о ратификации, принятии, утверждении или присоединении, сданный на
 хранение после вступления в силу какой-либо поправки к настоящей Конвенции, считается
 относящимся к измененному тексту настоящей Конвенции со дня вступления в силу этой
 поправки.</p><br>

<p style="font-weight:500;">Статья 18. Денонсация</p><br>

<p style="text-indent:2em;">1. Каждая Договаривающаяся Сторона может денонсировать настоящую Конвенцию посредством
 нотификации, адресованной Генеральному секретарю Организации Объединенных Наций.</p><br>

<p style="text-indent:2em;">2. Денонсация вступает в силу по истечении шести месяцев со дня получения Генеральным
 секретарем этой нотификации.</p><br>

<p style="font-weight:500;">Статья 19. Прекращение действия</p><br>

<p style="text-indent:2em;">Если после вступления в силу настоящей Конвенции число государств, являющихся
 Договаривающимися сторонами, будет менее пяти в течение любого периода, состоящего
 из последовательных двенадцати месяцев, по истечении указанного выше 
 двенадцатимесячного периода настоящая Конвенция утрачивает силу.</p><br>

<p style="font-weight:500;">Статья 20. Разрешение споров</p><br>

<p style="text-indent:2em;">1. Любой спор между двумя или более Договаривающимися Сторонами в отношении толкования
 или применения настоящей Конвенции по возможности разрешается путем переговоров между
 ними или другими средствами урегулирования.</p><br>

<p style="text-indent:2em;">2. Любой спор между двумя или более Договаривающимися Сторонами в отношении толкования
 или применения настоящей Конвенции, который не может быть разрешен способами,
 предусмотренными в пункте 1 настоящей статьи, передается по просьбе одной из их
 арбитражному суду, составленному следующим образом: каждая Сторона в споре назначает 
 арбитра, и эти арбитры назначают другого арбитра, который становится председателем. Если 
 по истечении трех месяцев со дня получения просьбы одна из Сторон не назначила арбитра или 
 арбитры не смогли избрать председателя, любая из Сторон может обратиться к Генеральному 
 секретарю Организации Объединенных Наций с просьбой назначить арбитра или председателя 
 арбитражного суда.</p><br>

<p style="text-indent:2em;">3. Решение арбитражного суда, назначенного в соответствии с положениями пункта 2, 
является окончательным и имеет обязательную силу для Сторон в споре.</p><br>

<p>4. Арбитражный суд устанавливает свои правила процедуры.</p><br>

<p style="text-indent:2em;">5. Арбитражный суд принимает свои решения большинством голосов на основе договоров, 
существующих между сторонами в споре, и общего международного права.</p><br>

<p style="text-indent:2em;">6. Любые разногласия, которые могут возникнуть между Сторонами в споре в отношении 
толкования и выполнения арбитражного решения, могут быть переданы любой из Сторон на
 рассмотрение арбитражного суда, который вынес это решение.</p><br>

<p style="text-indent:2em;">7. Каждая Сторона в споре несет расходы, связанные с использованием услуг назначенного
 ею арбитра и своих представителей при арбитражном разбирательстве; расходы, связанные
 с использованием услуг председателя, и другие расходы Стороны в споре несут в
 равных частях.</p><br>

<p style="font-weight:500;">Статья 21. Оговорки</p><br>

<p style="text-indent:2em;">1. Любая Договаривающаяся Сторона может в момент подписания, ратификации, принятия
 или утверждения настоящей Конвенции или присоединения к ней заявить, что она не
 считает себя связанной пунктами 2 - 7 статьи 20 настоящей Конвенции. Другие
 Договаривающиеся Стороны не будут связаны этими пунктами в отношении любой
 Договаривающейся Стороны, сделавшей такую оговорку.</p><br>

<p style="text-indent:2em;">2. Любая Договаривающаяся Сторона, сделавшая оговорку в соответствии с пунктом 
1 настоящей статьи, может в любое время снять эту оговорку путем уведомления
 Генерального секретаря Организации Объединенных Наций.</p><br>

<p style="text-indent:2em;">3. За исключением оговорок, предусмотренных в пункте 1 настоящей статьи, никакие
 другие оговорки к настоящей Конвенции не допускаются.</p><br>

<p style="font-weight:500;">Статья 22. Процедура внесения поправок в настоящую Конвенцию</p><br>

<p style="text-indent:2em;">1. Поправки к настоящей Конвенции, включая приложения к ней, могут вноситься по
 предложению любой Договаривающейся Стороны в соответствии с процедурой,
 установленной в настоящей статье.</p><br>

<p style="text-indent:2em;">2. Любая предложенная поправка к настоящей Конвенции рассматривается Административным
 комитетом, состоящим из всех Договаривающихся Сторон, в соответствии с правилами 
 процедуры, изложенными в приложении 7. Любая такая поправка, рассмотренная или 
 разработанная на сессии Административного комитета и одобренная им, направляется
 Генеральным секретарем Организации Объединенных Наций Договаривающимся Сторонам 
 для принятия.</p><br>

<p style="text-indent:2em;">3. Любая предложенная поправка, разосланная в соответствии с предыдущим пунктом, 
вступает в силу для всех Договаривающихся Сторон через три месяца по истечении 
двенадцатимесячного периода после даты сообщения о предлагаемой поправке, если в 
течение этого срока Генеральный секретарь Организации Объединенных Наций не получил
 от какого-либо государства, являющегося Договаривающейся Стороной, или от региональной
 организации в области экономической интеграции, являющейся Договаривающейся Стороной
 и действующей в соответствии с условиями, указанными в пункте 2 статьи 16 настоящей
 Конвенции, никаких возражений против предложенной поправки.</p><br>

<p style="text-indent:2em;">4. В случае возражения против предложенной поправки, представленного в соответствии
 с положениями пункта 3 настоящей статьи, поправка считается непринятой и в связи с
 ней не принимается никаких мер.</p><br>

<p style="font-weight:500;">Статья 23. Запросы, сообщения и возражения</p><br>

<p style="text-indent:2em;">Генеральный секретарь Организации Объединенных Наций информирует все Договаривающиеся
 Стороны и все государства обо всех запросах, сообщениях или возражениях, сделанных в
 соответствии со статьей 22, и о сроке вступления в силу каждой поправки.</p><br>

<p style="font-weight:500;">Статья 24. Конференция по пересмотру</p>

<p style="text-indent:2em;"></p><h6>По истечении пяти лет после вступления настоящей Конвенции в силу любая
 Договаривающаяся Сторона может путем уведомления, направляемого Генеральному
 секретарю Организации Объединенных Наций, просить о созыве конференции с целью
 пересмотра настоящей Конвенции, указав предложения, которые должны быть рассмотрены
 этой конференцией. В этом случае:</h6><p></p>

<p style="text-indent:2em;">I) Генеральный секретарь Организации Объединенных Наций уведомляет об этом все
 Договаривающиеся Стороны и предлагает им представить в трехмесячный срок свои
 замечания в отношении первоначальных предложений, а также другие предложения,
 рассмотрение которых на конференции представляется им желательным;</p><br>

<p style="text-indent:2em;">II) Генеральный секретарь Организации Объединенных Наций сообщает также всем 
Договаривающимся Сторонам текст любых других сделанных предложений и созывает
 конференцию по пересмотру, если в течение шести месяцев после уведомления по 
 крайней мере третья часть Договаривающихся Сторон уведомляет его о своем согласии
 на созыв такой конференции;</p><br>

<p style="text-indent:2em;">III) однако если Генеральный секретарь Организации Объединенных Наций полагает, 
что предложение о пересмотре можно рассматривать как предложение о внесении поправки 
в соответствии с пунктом 1 статьи 22, то с согласия Договаривающейся Стороны, 
сделавшей предложение, он может вместо процедуры пересмотра прибегнуть к 
процедуре внесения поправок, предусмотренной в статье 22.</p><br>

<p style="font-weight:500;">Статья 25. Уведомления</p>

<p style="text-indent:2em;"></p><h6>Помимо уведомлений и сообщений, предусмотренных в статьях 23 и 24, Генеральный 
секретарь Организации Объединенных Наций уведомляет все государства:</h6><p></p>

<p>а) о подписании, ратификации, принятии, утверждении и присоединении в соответствии
 со статьей 16;</p><br>

<p>б) о датах вступления в силу настоящей Конвенции в соответствии со статьей 17;</p><br>

<p>в) о денонсациях в соответствии со статьей 18;</p><br>

<p>г) о прекращении действия настоящей Конвенции в соответствии со статьей 19;</p><br>

<p>д) об оговорках, сделанных в соответствии со статьей 21.</p><br>

<p style="font-weight:500;">Статья 26. Заверенные копии</p><br>

<p style="text-indent:2em;">После 31 марта 1984 года Генеральный секретарь Организации Объединенных Наций препровождает
 две надлежащим образом заверенные копии настоящей Конвенции каждой из
 Договаривающихся Сторон и всем государствам, не являющимся
 Договаривающимися Сторонами.</p><br>

<p style="text-indent:2em;">Совершено в Женеве 21 октября одна тысяча девятьсот восемьдесят второго года
 в одном подлиннике, английский, испанский, русский и французский тексты
 которого являются равно аутентичными.</p><br>

<p style="text-indent:2em;">В удостоверение чего нижеподписавшиеся полномочные представители, должным
 образом уполномоченные с этой целью, подписали настоящую Конвенцию.</p>

<p></p><h4>Приложение 1</h4><p></p>

<p></p><h4>Согласование таможенного контроля и других видов контроля</h4><p></p>

<p style="font-weight:500;">Статья 1. Принципы</p><br>

<p style="text-indent:2em;">1. Учитывая наличие таможен на всех границах и общий характер их работы,
 организация других видов контроля, по мере возможности, координируется 
 с таможенным контролем.</p><br>

<p style="text-indent:2em;">2. Во исполнение этого принципа в случае необходимости допускается 
проведение всех или отдельных видов контроля не на границе, при условии,
 что используемые процедуры содействуют облегчению международных 
 перевозок грузов.</p><br>

<p style="font-weight:500;">Статья 2</p><br>

<p style="text-indent:2em;">1. Таможня должна иметь полную информацию о юридических предписаниях или
 правилах, которые могут потребовать осуществления других видов контроля,
 отличных от таможенного.</p><br>

<p style="text-indent:2em;">2. Если установлена необходимость осуществления других видов контроля,
 таможня обеспечивает, чтобы причастные службы были информированы об
 этом, и сотрудничает с ними.</p><br>

<p style="font-weight:500;">Статья 3. Организация контроля</p><br>

<p style="text-indent:2em;">1. В случае необходимости проведения нескольких видов контроля в одном
 месте компетентные службы принимают все надлежащие меры для того, чтобы
 проводить их по возможности одновременно или в возможно короткое время.
 Они прилагают все усилия для того, чтобы скоординировать свои потребности
 в документах и информации.</p><br>

<p style="text-indent:2em;">2. В частности, компетентные службы принимают все необходимые меры для
 того, чтобы в том месте, где проводится контроль, имелся необходимый
 персонал и оборудование.</p><br>

<p style="text-indent:2em;">3. Таможни могут при предоставлении им компетентными службами в прямой
 форме полномочий проводить от их имени все или некоторые виды контроля,
 ответственность за проведение которых несут эти службы. В таком случае
 эти службы обеспечивают, чтобы таможням были переданы все необходимые
 средства.</p><br>

<p style="font-weight:500;">Статья 4. Результаты контроля</p><br>

<p style="text-indent:2em;">1. По всем вопросам, охватываемым настоящей Конвенцией, контрольные службы
 и таможни незамедлительно обмениваются всей соответствующей информацией
 с целью обеспечения эффективности соответствующих видов контроля.</p><br>

<p style="text-indent:2em;">2. На основании результатов проведенного контроля компетентная служба
 принимает решение о том, как в дальнейшем поступить с данным грузом,
 и, в случае необходимости, информирует об этом компетентные службы, 
 проводящие другие виды контроля. На основании этого решения таможня
 определяет для такого груза соответствующий таможенный режим.</p>

<p></p><h4>Приложение 2</h4><p></p>

<p></p><h4>Медико-санитарный контроль</h4><p></p>

<p style="font-weight:500;">Статья 1. Принципы</p><br>

<p style="text-indent:2em;">Медико-санитарный контроль, в каком бы месте он не осуществлялся, 
производится в соответствии с принципами, определенными в настоящей
 Конвенции, и в частности в приложении 1 к ней.</p><br>

<p style="font-weight:500;">Статья 2. Информация</p>

<p style="text-indent:2em;"></p><h6>Каждая Договаривающаяся Сторона обеспечивает, чтобы любому заинтересованному
 лицу незамедлительно предоставлялась следующая информация:</h6><p></p>

<p>о грузах, подлежащих медико-санитарному контролю;</p><br>

<p>о местах, в которых данные грузы могут быть представлены для контроля;</p><br>

<p style="text-indent:2em;">о предусмотренных законодательными актами и правилами требованиях 
в отношении медико-санитарного контроля, а также об обычно 
применяемых процедурах.</p><br>

<p style="font-weight:500;">Статья 3. Организация контроля</p><br>

<p style="text-indent:2em;">1. Контрольные службы следят за тем, чтобы пограничные пункты, на которых
 может проводиться медико-санитарный контроль, оснащались необходимым 
 оборудованием.</p><br>

<p style="text-indent:2em;">2. Медико-санитарный контроль может также осуществляться в пунктах, 
расположенных внутри страны, если представленные свидетельства и способ
 перевозки ясно свидетельствуют о том, что грузы не могут испортиться 
 или стать источником заражения во время перевозки.</p><br>

<p style="text-indent:2em;">3. В рамках действующих конвенций Договаривающиеся Стороны стремятся по
 возможности сократить физический контроль перевозимых скоропортящихся 
 грузов.</p><br>

<p style="text-indent:2em;">4. В случаях, когда грузы необходимо задержать до получения результатов
 медико-санитарного контроля, компетентные контрольные службы Договаривающихся
 Сторон принимают меры к тому, чтобы грузы хранились в условиях, обеспечивающих
 их сохранность, при минимуме таможенных формальностей.</p><br>

<p style="font-weight:500;">Статья 4. Транзитные грузы</p><br>

<p style="text-indent:2em;">В рамках действующих конвенций Договаривающиеся Стороны в тех случаях, когда
 не существует опасности заражения, по возможности освобождают транзитные
 грузы от медико-санитарного контроля.</p><br>

<p style="font-weight:500;">Статья 5. Сотрудничество</p><br>

<p style="text-indent:2em;">1. Медико-санитарные службы осуществляют сотрудничество с соответствующими
 службами других Договаривающихся Сторон в целях ускорения прохождения
 скоропортящихся грузов, подлежащих медико-санитарному контролю, в частности
 путем обмена полезной информацией.</p><br>

<p style="text-indent:2em;">2. В случаях, когда партия скоропортящихся грузов задерживается при медико
-санитарном контроле, компетентная служба принимает меры к тому, чтобы в
 кратчайший срок уведомить соответствующую службу страны-экспортера, 
 указывая причины этого задержания и меры, принятые в отношении грузов.</p>

<p></p><h4>Приложение 3</h4><p></p>

<p></p><h4>Ветеринарный контроль</h4><p></p>

<p style="font-weight:500;">Статья 1. Принципы</p><br>

<p style="text-indent:2em;">Ветеринарный контроль, в каком бы месте он ни осуществлялся, производится
 в соответствии с принципами, определенными в настоящей Конвенции и, в 
 частности, в приложении 1 к ней.</p><br>

<p style="font-weight:500;">Статья 2. Определения</p><br>

<p style="text-indent:2em;">Ветеринарный контроль, определенный в статье 1(d) настоящей Конвенции, 
охватывает также контроль средств и условий перевозки животных и продуктов 
животного происхождения. Он может также включать в себя контроль качества, 
стандартов и различных правил, например, контроль с целью сохранения 
находящихся под угрозой видов, который, по соображениям эффективности,
 часто связан с ветеринарным контролем.</p><br>

<p style="font-weight:500;">Статья 3. Информация</p>

<p style="text-indent:2em;"></p><h6>Каждая Договаривающаяся Сторона обеспечивает, чтобы любому 
заинтересованному лицу незамедлительно предоставлялась 
следующая информация:</h6><p></p>

<p>о грузах, подлежащих ветеринарному контролю;</p><br>

<p>о местах, в которых грузы могут быть представлены для контроля;</p><br>

<p>о болезнях, уведомление о которых является обязательным;</p><br>

<p style="text-indent:2em;">о предусмотренных законодательными актами и правилами требованиях в 
отношении ветеринарного контроля, а также об обычно применяемых 
процедурах.</p><br>

<p style="font-weight:500;">Статья 4. Организация контроля</p>

<p style="text-indent:2em;"></p><h6>1. Договаривающиеся Стороны стремятся:</h6><p></p>

<p style="text-indent:2em;">создавать там, где это необходимо и возможно, соответствующие устройства
 для ветеринарного контроля в соответствии с потребностями перевозок;</p><br>

<p style="text-indent:2em;">содействовать продвижению грузов, в частности путем координации часов 
работы ветеринарных и таможенных служб и обеспечения возможности производить
 таможенную очистку грузов в часы, не предусмотренные обычным графиком работы,
 если извещение об их прибытии поступило заблаговременно.</p><br>

<p style="text-indent:2em;">2. Ветеринарный контроль продуктов животного происхождения может производиться
 в пунктах, расположенных внутри страны, если может быть доказано и если 
 используемые транспортные средства таковы, что эти продукты не могут испортиться
 или стать источником заражения во время их перевозки.</p><br>

<p style="text-indent:2em;">3. В рамках действующих конвенций Договаривающиеся Стороны стремятся по 
возможности сократить физический контроль перевозимых скоропортящихся
 грузов.</p><br>

<p style="text-indent:2em;">4. В случаях, когда грузы необходимо задержать до получения результатов
 ветеринарного контроля, компетентные контрольные службы Договаривающихся
 Сторон принимают меры к тому, чтобы хранение грузов осуществлялось при
 минимуме таможенных формальностей и в условиях, обеспечивающих их надежный
 карантин и сохранность.</p><br>

<p style="font-weight:500;">Статья 5. Транзитные грузы</p><br>

<p style="text-indent:2em;">В рамках действующих конвенций Договаривающиеся Стороны в тех случаях, 
когда не существует опасности заражения, по мере возможности освобождают
 от ветеринарного контроля продукты животного происхождения, перевозимые
 транзитом.</p><br>

<p style="font-weight:500;">Статья 6. Сотрудничество</p><br>

<p style="text-indent:2em;">1. Службы ветеринарного контроля осуществляют сотрудничество с соответствующими
 службами других Договаривающихся Сторон в целях ускорения прохождения грузов,
 подлежащих ветеринарному контролю, в частности путем обмена полезной
 информацией.</p><br>

<p style="text-indent:2em;">2. В случаях, когда партия скоропортящихся грузов или живых животных задерживается
 при ветеринарном контроле, компетентная служба принимает меры к тому, чтобы в
 кратчайший срок уведомить соответствующую службу страны-экспортера, указывая
 причины этого задержания и меры, принятые в отношении грузов.</p>

<p></p><h4>Приложение 4</h4><p></p>

<p></p><h4>Фитосанитарный контроль</h4><p></p>

<p style="font-weight:500;">Статья 1. Принципы</p><br>

<p style="text-indent:2em;">Фитосанитарный контроль, в каком бы месте он не осуществлялся, производится
 в соответствии с принципами, определенными в настоящей Конвенции и, в
 частности, в приложении 1 к ней.</p><br>

<p style="font-weight:500;">Статья 2. Определения</p><br>

<p style="text-indent:2em;">Фитосанитарный контроль, определенный в статье 1(e) настоящей Конвенции,
 охватывает также контроль средств и условий перевозки растений и продуктов
 растительного происхождения. Он может также включать в себя меры, направленные
 на сохранение видов растений, которым грозит исчезновение.</p><br>

<p style="font-weight:500;">Статья 3. Информация</p>

<p style="text-indent:2em;"></p><h6>Каждая Договаривающаяся Сторона обеспечивает, чтобы любому заинтересованному
 лицу незамедлительно предоставлялась следующая информация:</h6><p></p>

<p>о грузах, на которые распространяются специальные фитосанитарные условия;</p><br>

<p style="text-indent:2em;">о местах, в которых отдельные растения и продукты растительного происхождения
 могут быть представлены для контроля;</p><br>

<p style="text-indent:2em;">перечень вредителей растений и продуктов растительного происхождения, в
 отношении которых действуют запрещения или ограничения;</p><br>

<p style="text-indent:2em;">перечень предусмотренных законодательными актами и правилами требований в
 отношении фитосанитарного контроля, а также информация об обычно применяемых
 процедурах.</p><br>

<p style="font-weight:500;">Статья 4. Организация контроля</p>

<p style="text-indent:2em;"></p><h6>1. Договаривающиеся Стороны стремятся:</h6><p></p>

<p style="text-indent:2em;">создавать там, где это необходимо и возможно, соответствующие устройства для
 фитосанитарного контроля, хранения, дезинсекции и дезинфекции в соответствии
 с потребностями перевозок;</p><br>

<p style="text-indent:2em;">содействовать продвижению грузов, в частности путем координации часов работы
 фитосанитарных и таможенных служб и обеспечения возможности производить таможенную
 очистку скоропортящихся грузов в часы, не предусмотренные обычным графиком работы,
 если извещение об их прибытии поступило заблаговременно.</p><br>

<p style="text-indent:2em;">2. Фитосанитарный контроль растений и продуктов растительного происхождения может
 производиться в пунктах, расположенных внутри страны, если может быть доказано и
 если используемые транспортные средства таковы, что эти продукты не могут стать
 источником заражения во время их перевозки.</p><br>

<p style="text-indent:2em;">3. В рамках действующих конвенций Договаривающиеся Стороны стремятся по возможности
 сократить физический контроль перевозимых быстропогибающих растений и скоропортящихся
 продуктов растительного происхождения.</p><br>

<p style="text-indent:2em;">4. В случаях, когда грузы необходимо задержать до получения результатов фитосанитарного
 контроля, компетентные контрольные службы Договаривающихся Сторон принимают меры к тому,
 чтобы хранение грузов осуществлялось при минимуме таможенных формальностей и в условиях,
 обеспечивающих их надежный карантин и сохранность.</p><br>

<p style="font-weight:500;">Статья 5. Транзитные грузы</p><br>

<p style="text-indent:2em;">В рамках действующих конвенций, за исключением случаев, когда такие меры необходимы для
 защиты растений в их странах, Договаривающиеся Стороны по мере возможности освобождают
 транзитные грузы от фитосанитарного контроля.</p><br>

<p style="font-weight:500;">Статья 6. Сотрудничество</p><br>

<p style="text-indent:2em;">1. Фитосанитарные службы осуществляют сотрудничество с соответствующими службами других
 Договаривающихся Сторон в целях ускорения прохождения растений и растительных продуктов,
 подлежащих фитосанитарному контролю, в частности путем обмена полезной информацией.</p><br>

<p style="text-indent:2em;">2. В случаях, когда партия растений или продуктов растительного происхождения задерживается
 при фитосанитарном контроле, компетентная служба принимает меры к тому, чтобы в кратчайший
 срок уведомить соответствующую службу страны-экспортера, указывая причины этого задержания
 и меры, принятые в отношении грузов.</p>

<p></p><h4>Приложение 5</h4><p></p>

<p></p><h4>Контроль соответствия техническим стандартам</h4><p></p>

<p style="font-weight:500;">Статья 1. Принципы</p><br>

<p style="text-indent:2em;">Контроль соответствия техническим стандартам в отношении грузов, предусмотренных в настоящей
 Конвенции, в каком бы месте он ни осуществлялся, производится в соответствии с принципами
 определенными в настоящей Конвенции и, в частности, в приложении 1 к ней.</p><br>

<p style="font-weight:500;">Статья 2. Информация</p>

<p style="text-indent:2em;"></p><h6>Каждая Договаривающаяся Сторона обеспечивает, чтобы любому заинтересованному лицу
 незамедлительно предоставлялась следующая информация:</h6><p></p>

<p>о стандартах, применяемых ею;</p><br>

<p>о местах, в которых грузы могут быть представлены для контроля;</p><br>

<p style="text-indent:2em;">о предусмотренных законодательными актами и правилами требованиях в отношении контроля
 соответствия техническим стандартам, а также об обычно применяемых процедурах.</p><br>

<p style="font-weight:500;">Статья 3. Согласование стандартов</p><br>

<p style="text-indent:2em;">В случае отсутствия международных стандартов Договаривающиеся Стороны, применяющие национальные
 стандарты, прилагают усилия по их согласованию путем заключения международных соглашений.</p><br>

<p style="font-weight:500;">Статья 4. Организация контроля</p>

<p style="text-indent:2em;"></p><h6>1. Договаривающиеся Стороны стремятся:</h6><p></p>

<p style="text-indent:2em;">организовывать там, где это необходимо и возможно, пункты контроля соответствия техническим стандартам
 в соответствии с потребностями перевозок;</p><br>

<p style="text-indent:2em;">содействовать продвижению грузов, в частности путем координации часов работы служб, ответственных
 за контроль соответствия техническим стандартам, и таможенных служб и обеспечения возможности
 производить таможенную очистку скоропортящихся грузов в часы, не предусмотренные обычным графиком
 работы, если извещение об их прибытии поступило заблаговременно.</p><br>

<p style="text-indent:2em;">2. Контроль соответствия техническим стандартам может также производиться в пунктах, расположенных
 внутри страны, если может быть доказано и если используемые транспортные средства таковы, что эти
 грузы, и особенно скоропортящиеся грузы, не могут испортиться во время их перевозки.</p><br>

<p style="text-indent:2em;">3. В рамках действующих конвенций Договаривающиеся Стороны стремятся по возможности сократить
 физический контроль перевозимых скоропортящихся грузов, подлежащих контролю соответствия
 техническим стандартам.</p><br>

<p style="text-indent:2em;">4. Договаривающиеся Стороны организуют контроль соответствия техническим стандартам, согласовывая
 всегда, когда это возможно, процедуры, применяемые службами, ответственными за эти виды контроля,
 и процедуры, применяемые службами, ответственными за проведение других видов контроля и осмотра.</p><br>

<p style="text-indent:2em;">5. В случае, когда скоропортящиеся грузы задерживаются до получения результатов контроля
 соответствия техническим стандартам, компетентные контрольные службы Договаривающихся Сторон 
 принимают меры к тому, чтобы хранение грузов или стоянка транспортных средств осуществлялись 
 при минимуме таможенных формальностей и в условиях, обеспечивающих сохранность грузов.</p><br>

<p style="font-weight:500;">Статья 5. Транзитные грузы</p><br>

<p style="text-indent:2em;">Контроль соответствия техническим стандартам обычно не проводится в отношении грузов, 
перевозимых в режиме сквозного транзита.</p><br>

<p style="font-weight:500;">Статья 6. Сотрудничество</p><br>

<p style="text-indent:2em;">1. Службы, ответственные за проведение контроля соответствия техническим стандартам, 
осуществляют сотрудничество с соответствующими службами других Договаривающихся Сторон 
в целях ускорения прохождения скоропортящихся грузов, подлежащих контролю соответствия 
техническим стандартам, в частности путем обмена полезной информацией.</p><br>

<p style="text-indent:2em;">2. В случаях, когда партия скоропортящихся грузов задерживается при контроле соответствия 
техническим стандартам, компетентная служба принимает меры к тому, чтобы в кратчайший срок 
уведомить соответствующую службу страны-экспортера, указывая причины этого задержания и меры, 
принятые в отношении грузов.</p>

<p></p><h4>Приложение 6</h4><p></p>

<p></p><h4>Контроль качества</h4><p></p>

<p style="font-weight:500;">Статья 1. Принципы</p><br>

<p style="text-indent:2em;">Контроль качества грузов, предусмотренных в настоящей Конвенции, в каком бы месте он ни 
осуществлялся, производится в соответствии с принципами, определенными в настоящей Конвенции,
 и в частности в приложении 1 к ней.</p><br>

<p style="font-weight:500;">Статья 2. Информация</p>

<p style="text-indent:2em;"></p><h6>Каждая Договаривающаяся Сторона обеспечивает, чтобы любому заинтересованному лицу 
незамедлительно предоставлялась следующая информация:</h6><p></p>

<p>о местах, где грузы могут быть представлены для контроля;</p><br>

<p style="text-indent:2em;">о предусмотренных законодательными актами и правилами требованиях в отношении контроля 
качества, а также об обычно применяемых процедурах.</p><br>

<p style="font-weight:500;">Статья 3. Организация контроля</p>

<p style="text-indent:2em;"></p><h6>1. Договаривающиеся Стороны стремятся:</h6><p></p>

<p style="text-indent:2em;">организовывать там, где это необходимо и возможно, пункты контроля качества в соответствии 
с потребностями перевозок;</p><br>

<p style="text-indent:2em;">содействовать продвижению грузов, в частности путем координации часов работы служб 
контроля качества и таможенных служб и обеспечения возможностей производить таможенную 
очистку скоропортящихся грузов в часы, не предусмотренные обычным графиком работы, если 
извещение об их прибытии поступило заблаговременно.</p><br>

<p style="text-indent:2em;">2. Контроль качества может производиться в пунктах, расположенных внутри страны, при 
условии, что применяемые процедуры содействуют международным перевозкам грузов.</p><br>

<p style="text-indent:2em;">3. В рамках действующих конвенций Договаривающиеся Стороны стремятся по возможности 
сократить физический контроль перевозимых скоропортящихся грузов, подлежащих контролю 
качества.</p><br>

<p style="text-indent:2em;">4. Договаривающиеся Стороны организуют контроль качества, согласовывая всегда, когда 
это возможно, процедуры, применяемые службой, ответственной за этот контроль, 
и процедуры, применяемые службами, ответственными за проведение других видов 
контроля и осмотра.</p><br>

<p style="font-weight:500;">Статья 4. Транзитные грузы</p><br>

<p>Контроль качества обычно не осуществляется в отношении грузов, перевозимых в 
режиме сквозного транзита.</p><br>

<p style="font-weight:500;">Статья 5. Сотрудничество</p><br>

<p style="text-indent:2em;">1. Службы контроля качества осуществляют сотрудничество с соответствующими службами
 других Договаривающихся Сторон в целях ускорения прохождения скоропортящихся грузов,
 подлежащих контролю качества, в частности путем обмена полезной информацией.</p><br>

<p style="text-indent:2em;">2. В случаях, когда партия скоропортящихся грузов задерживается при контроле качества,
 компетентная служба принимает меры к тому, чтобы в кратчайший срок уведомить 
 соответствующую службу страны-экспортера, указывая причины этого задержания и 
 меры, принятые в отношении грузов.</p>

<p></p><h4>Приложение 7</h4><p></p>

<p></p><h4>Правила процедуры административного комитета, упомянутого в статье 22 настоящей Конвенции</h4><p></p>

<p style="font-weight:500;">Статья 1. Члены</p><br>

<p>Членами Административного комитета являются Договаривающиеся Стороны настоящей Конвенции.</p><br>

<p style="font-weight:500;">Статья 2. Наблюдатели</p><br>

<p style="text-indent:2em;">1. Административный комитет может принять решение пригласить компетентные администрации 
всех государств, не являющихся Договаривающимися Сторонами, или представителей 
международных организаций, которые не являются Договаривающимися Сторонами, 
присутствовать на сессиях Комитета в качестве наблюдателей при обсуждении 
интересующих их вопросов.</p><br>

<p style="text-indent:2em;">2. Тем не менее без ущерба для положений статьи 1 компетентные международные организации, 
упомянутые в пункте 1, имеют право участвовать в работе Административного комитета в 
качестве наблюдателей при рассмотрении вопросов, регулируемых приложениями к настоящей 
Конвенции.</p><br>

<p style="font-weight:500;">Статья 3. Секретариат</p><br>

<p style="text-indent:2em;">Персонал секретариата Комитета выделяется Исполнительным секретарем 
Экономической комиссии для Европы.</p><br>

<p style="font-weight:500;">Статья 4. Созыв</p>

<p style="text-indent:2em;"></p><h6>Исполнительный секретарь Экономической комиссии для Европы созывает Комитет:</h6><p></p>

<p>I) спустя два года после вступления Конвенции в силу;</p><br>

<p>II) затем - в сроки, установленные Комитетом, но не реже одного раза в пять лет;</p><br>

<p style="text-indent:2em;">III) по просьбе компетентных органов по меньшей мере пяти государств, которые 
являются Договаривающимися Сторонами.</p><br>

<p style="font-weight:500;">Статья 5. Должностные лица</p><br>

<p>На каждой сессии Комитет избирает председателя и заместителя председателя.</p><br>

<p style="font-weight:500;">Статья 6. Кворум</p><br>

<p style="text-indent:2em;">Для принятия решений необходим кворум, составляющий не менее одной трети государств, 
являющихся Договаривающимися Сторонами.</p><br>

<p style="font-weight:500;">Статья 7. Принятие решений</p><br>

<p>I) Предложения ставятся на голосование;</p><br>

<p>II) Каждое являющееся Договаривающейся Стороной государство, представленное на сессии, 
имеет один голос;</p><br>

<p style="text-indent:2em;">III) В случае применения пункта 2 статьи 16 Конвенции региональные организации в области 
экономической интеграции, являющиеся участниками настоящей Конвенции, при голосовании 
располагают лишь числом голосов, равным общему числу голосов их государств-членов, 
являющихся также участниками настоящей Конвенции.</p><br>

<p>В этом последнем случае государства-члены не осуществляют своего права голоса;</p><br>

<p style="text-indent:2em;">IV) С учетом положений, содержащихся в подпункте v ниже, предложения принимаются 
простым большинством голосов присутствующих и участвующих в голосовании членов в 
соответствии с условиями, определенными в подпунктах II и III выше;</p><br>

<p style="text-indent:2em;">V) Поправки к настоящей Конвенции принимаются большинством в две трети голосов 
присутствующих и участвующих в голосовании членов в соответствии с условиями, 
определенными в подпунктах II и III выше.</p><br>

<p style="font-weight:500;">Статья 8. Доклад</p><br>

<p>Перед закрытием сессии Комитет утверждает свой доклад.</p><br>

<p style="font-weight:500;">Статья 9. Дополняющие положения</p><br>

<p style="text-indent:2em;">При отсутствии в настоящем приложении надлежащих положений применяются правила 
процедуры Экономической комиссии для Европы, если Комитет не примет иного 
решения.</p><br>

<p>Примечание к документу</p><br>

<p>Конвенция вступила в силу 15.10.1985.</p><br>

<p><a href="http://docs.cntd.ru/document/551160441">Источник</a></p>

</div>
`