import React from 'react'
import { Route, Switch } from 'react-router-dom'
import List from './list'
import AddAds from './add-ads'
import AdsItem from './item'
import MyAds from './my-ads'
import ProtectedRoute from '../../protectedRoute'

export default () => {
  return (
    <Switch>
      <ProtectedRoute
        path="/ads/list/:filterKey"
        component={List}
        permissionScope="ad"
        actionType="list"
      />
      <Route path="/ads/item/:itemId" component={AdsItem} />
      <ProtectedRoute
        path="/ads/add-ads"
        component={AddAds}
        requireAuth
        permissionScope="ad"
        actionType="add"
      />
      <ProtectedRoute
        path="/ads/edit/:adId"
        requireAuth
        component={AddAds}
        permissionScope="ad"
        actionType="edit"
        checkAuthor
      />
      <ProtectedRoute path="/ads/my-ads" requireAuth component={MyAds} />
    </Switch>
  )
}
