import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Add from './add'
import AutoParkAdd from './autopark/add'
import AutoParkList from './autopark'
import Search from './search'
import Offers from './offers'
import ItemPage from '../search/item'
import ProtectedRoute from '../../protectedRoute'

export default () => {
  return (
    <Switch>
      <ProtectedRoute
        path="/transport/add"
        component={Add}
        permissionScope="transport"
        actionType="add"
        requireAuth
      />
      <ProtectedRoute
        path="/transport/edit/:itemId"
        component={Add}
        permissionScope="transport"
        actionType="edit"
        requireAuth
        checkAuthor
      />
      <ProtectedRoute path="/transport/autopark/add" component={AutoParkAdd} requireAuth />
      <ProtectedRoute path="/transport/offers" component={Offers} requireAuth />
      <ProtectedRoute path="/transport/autopark/:itemId" component={AutoParkAdd} requireAuth />
      <ProtectedRoute path="/transport/autopark" component={AutoParkList} requireAuth />
      <ProtectedRoute
        path="/transport/search"
        permissionScope="transport"
        actionType="search"
        component={Search}
      />
      <Route
        path="/transport/:itemId"
        render={routeProps => <ItemPage {...routeProps} searchType="transport" showDateIsShort/>}
      />
    </Switch>
  )
}
