import { connect } from 'react-redux'
import { compose } from 'recompose'

import pick from 'lodash/pick'
import { reduxForm, formValueSelector } from 'redux-form'

import Component from './component'

import withTranslation from '../../../../globals/components/withTranslation'
import withPermissions from '../../../../globals/components/withPermissions'
import { onCanAdd } from '../../../../store/actions/profile'
import { loadCars, deleteCar, autoparkSetActiveTab } from '../../../../store/actions/transport'
import { IReduxStorage } from '../../../../store/reducers/types'

const formName = 'list-auto-park'
const formSelector = formValueSelector(formName)

const initialValues = {
  searchKey: '',
}

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state: IReduxStorage) => {
      const listProps = pick(state.transport.list, [
        'total',
        'pageIndex',
        'pageSize',
        'loading',
        'items',
        'searchText',
      ])
      const profile = state.auth.profile.get('data')
      return {
        list: state.transport.list,
        activeTab: state.transport.activeTab,
        ...listProps,
        searchKey: formSelector(state, 'searchKey'),
        activeGuid: state.ui.activePopupGuid,
        companyId: profile.get('companyId'),
        isCompanyAdmin: profile.get('isCompanyAdmin'),
        langId: state.ui.langId,
        canAdd: state.profile.canAdd,
      }
    },
    {
      loadCars,
      autoparkSetActiveTab,
      onCanAdd,
    }
  ),
  reduxForm({
    form: formName,
    initialValues,
  })
)(Component)
