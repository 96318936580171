import { connect } from 'react-redux'
import { compose } from 'recompose'
import {
  reduxForm,
  getFormValues,
  // formValueSelector
} from 'redux-form'

import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'

import Component from './component'
import { save } from '../../../store/actions/company'
import { getProfile } from '../../../store/actions/user'
import getFormInputNames from '../../shared/getFormInputNames'
import fetch from '../../shared/fetch'
import {
  logout
} from '../../../store/actions'

const FORMNAME = 'edit-company'
//const formSelector = formValueSelector(FORMNAME)

const contactInfo = {
  phone1: undefined,
  phone2: undefined,
  fax: undefined,
  email: undefined,
  skype: undefined,
  viber: undefined,
  address: undefined,
}
const initialValues = {
  name: undefined,
  ownershipType: undefined, //'1',
  country: undefined,
  businessType: undefined,
  positionInCompany: undefined,
  director: undefined,
  yearStarted: undefined,
  fiscalCode: undefined,
  description: undefined,
  contact: {
    headQuarter: {
      ...contactInfo,
    },
    branch: {
      ...contactInfo,
    },
  },
  documents: {
    registrationregistrationCertificate: undefined,
    kindOfActivity: undefined,
    lisenseType: undefined,
  },
}

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state, props) => {
      const { itemId } = props.match.params
      const {
        ui: { langId },
        auth: { profile },
      } = state

      return {
        itemId,
        enteredValues: getFormValues(FORMNAME)(state),
        langId,
        profile: profile.get('data').toJS(),
      }
    },
    {
      save,
      getProfile,
      logout
    }
  ),
  reduxForm({
    form: FORMNAME,
    initialValues,
    onSubmit: (values, dispatch, props) => {
      const { enteredValues, touch, itemId, history, save, getProfile, userId } = props
      const inputNames = getFormInputNames(enteredValues, '')

      touch(...inputNames)

      if (itemId) {
        const {
          name: companyName,
          country: countryId,
          propertyType,
          businessType,
          positionInCompany,
          formationYear,
          fiscalCode,
          description,
          selectedCompanyAdmin,
          registrationCertificateDoc,
          licenseOneDoc,
          licenseTwoDoc,
          licenseThreeDoc,
          headquarters: { phone1, phone2, skype, viber, fax1: fax, email, address, website },
        } = values.company
        
        const body = JSON.stringify({
          companyName,
          propertyType: +propertyType,
          countryId: +countryId,
          businessType,
          adminId: selectedCompanyAdmin,
          positionInCompany,
          formationYear,
          fiscalCode,
          description,
          phone1,
          phone2,
          skype,
          viber,
          fax,
          email,
          address,
          website,
          registrationCertificate: registrationCertificateDoc?.uuid ?? '',
          companyLicenses: `${licenseOneDoc?.uuid},${licenseTwoDoc?.uuid ?? ''},${licenseThreeDoc?.uuid ?? ''}`
        })

        fetch(`/company/edit/${itemId}`, {
          method: 'PUT',
          body,
        }).then(_ => {
          history.push(`/company/item/${itemId}`)
        })
      } else {
        save({
          companyType: 'head',
          ...values,
          company: {
            ...values.company,
            registrationCertificate: values.company?.registrationCertificateDoc?.uuid ?? '',
            companyLicenses: `${values.company?.licenseOneDoc?.uuid},${values.company?.licenseTwoDoc?.uuid ?? ''},${values.company?.licenseThreeDoc?.uuid ?? ''}`,
            registrationCertificateDoc: undefined,
            licenseOneDoc: undefined,
            licenseTwoDoc: undefined,
            licenseThreeDoc: undefined,
          }

        }).then(response => {
          if (response && response.type === 'COMPANY_SAVED') {
            getProfile(userId, () => {
              history.push('/company/items')
            })
          }
        })
      }
    },
  })
)(Component)
