import React, { Component } from 'react'
import { toastr } from 'react-redux-toastr'
import { Link } from 'react-router-dom'

import fetch from '../../shared/fetch'
import WidgetBase from '../../shared/components/widget-base'
import './list.scss'

const STYLE_BASE = 'legislation-list_'

export default class extends Component {
  state = {
    items: [],
  }

  componentDidMount() {
    const { items } = this.props
    this.setState({ items })
  }

  render() {
    const { title, area } = this.props
    const { items } = this.state

    return (
      <WidgetBase title={title}>
        <div className={`${STYLE_BASE}panel`}>
          {items.map(({ title, key }, index) => (
            <div key={index} className="panel-content-row">
              <Link
                to={`/useful/legislation/${area}/${key}`}
                className="text"
              >
                {title}
              </Link>
            </div>
          ))}
        </div>
      </WidgetBase>
    )
  }
}
