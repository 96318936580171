import { connect } from 'react-redux'
import { compose } from 'recompose'

import Component from './component'
import { UserPhotoBaseProps, UserPhotoProps } from './types'
import { IReduxStorage } from '../../../store/reducers/types'
import withPermissions from '../../../globals/components/withPermissions'

export default compose(
  withPermissions,
  connect((state: IReduxStorage, props: UserPhotoProps) => {
    const profile = state.auth.profile.get('data')
    const isCurrentUser = props.customUserId == props.userId || !props.customUserId

    return {
      photo: isCurrentUser ? profile.get('photo') : props.photo || null,
      gender: (isCurrentUser ? profile.get('gender') : props.gender) || 'male',
    }
  })
)(Component) as React.ComponentClass<UserPhotoBaseProps>
