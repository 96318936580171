import { connect } from 'react-redux'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { reduxForm, formValueSelector } from 'redux-form'

import withTranslation from '../../../../globals/components/withTranslation'
import Component from './component'
import { IReduxStorage } from '../../../../store/reducers/types'

const FORMNAME = 'searchCargoShort'
const formSelector = formValueSelector(FORMNAME)

const initialValues = {
  what: 'cargo',
}

export default compose(
  injectIntl,
  withTranslation,
  connect((state: IReduxStorage) => {
    return {
      activeSearchType: formSelector(state, 'what'),
      countryFrom: formSelector(state, 'from'),
      countryTo: formSelector(state, 'to'),
      langId: state.ui.langId,
    }
  }),
  reduxForm({
    form: FORMNAME,
    initialValues,
    touchOnChange: true,
  })
)(Component)
