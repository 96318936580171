import React from 'react'
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons' //text-mask-addons/dist/createNumberMask'

export default props => {
  const {
    input,
    unit,
    placeholder,
    prefix = '',
    integerLimit = 1,
    decimalLimit = 0,
    allowLeadingZeroes = true,
    style,
    checkNoZero = false,
    meta: { touched, error, warning },
  } = props
  const { value, onChange } = input
  // const [touched, setTouched] = useState(false)

  // const onBlur = () => {
  //   setTouched(true)
  // }
  const setOnChange = (v) => {
    checkNoZero ? v === "0" ? onChange("1") : onChange(v) : onChange(v)
  }

  return (
    <div className="input-text">
      <MaskedInput
        type="text"
        placeholder={placeholder}
        value={checkNoZero && value === "0" ? setOnChange(1) : value}
        onChange={(e) => setOnChange(e.target.value)}
        onBlur={onChange}
        mask={createNumberMask({
          thousandsSeparatorSymbol: ' ',
          prefix,
          allowDecimal: decimalLimit > 0,
          allowLeadingZeroes,
          decimalLimit,
          integerLimit,
        })}
        style={style}
      />
      {unit && <span className="mark">{unit}</span>}
      {touched &&
        ((error && <span style={{ color: 'red' }}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  )
}
