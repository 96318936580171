export const int9 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Transportul de mărfuri, Transportul terestru, pe apă, aerian</h4><p></p>

<p style="font-weight:500;">Transportul de mărfuri</p>

<p style="text-indent:2em;"></p><h6>Mijloacele de transport mărfuri se clasifică în funcţie de calea de comunicaţie astfel:</h6><p></p>

<p>terestre (auto şi feroviar)</p><br>

<p>pe apă (maritim şi fluvial)</p><br>

<p>aerospaţial.</p><br>

<p style="font-weight:500;">Transportul terestru</p><br>

<p style="text-indent:2em;">Pentru a fi rentabile, mijloacele de transport marfă trebuie să transporte mai repede şi la un preţ de cost cât mai scăzut o cantitate mare de mărfuri. Firmele producătoare de astfel
 de vehicule încearcă permanent să ofere cele mai profitabile soluţii de transport.</p>

<p style="text-indent:2em;"></p><h6>Din punct de vedere al destinaţiei, autovehiculele de marfă sunt:</h6><p></p>

<p>autoutilitare închise sau deschise, cu o încărcătură până la 1,5 tone</p><br>

<p>autocamionete pentru încărcătură de 1,5-2 tone</p><br>

<p>autocamioane închise sau deschise, cu încărcătură de la 2 tone până la încărcături mai mari de 12 tone</p><br>

<p>autobasculante</p><br>

<p>autocisterne, ce realizează transportul materialelor lichide.</p>

<p></p><h4>Transportul feroviar de marfă se realizează cu ajutorul vagoanelor, care în funcţie de marfa transportată, pot fi:
 cu platformă, cu obloane, pentru cereale şi cisternă.</h4><p></p>

<p style="font-weight:500;">Transportul pe apă</p>

<p style="text-indent:2em;"></p><h6>Navele pentru transportul mărfurilor se împart, în:</h6><p></p>

<p>nave fluviale: barje, bacuri plutitoare, platforme (pontoane);</p><br>

<p>nave maritime: mineraliere, cargouri, traulere (de pescuit), frigorifice, petroliere, feriboturi.</p><br>

<p style="font-weight:500;">Transportul aerian terestru</p>

<p style="text-indent:2em;"></p><h6>Mijloacele aeriene de transport mărfuri sunt:</h6><p></p>

<p>avioanele</p><br>

<p>elicopterele.</p><br>

<p><a href="https://www.gimnaziu.info/transportul-de-marfuri-transportul-terestru-pe-apa-aerian">Sursa</a></p>



</div>
`