import React from 'react'

import Legislation from '../list'
import { linksMD } from "../../../legacy-content/md"
import { linksEU } from "../../../legacy-content/eu"
import { linksINT } from "../../../legacy-content/international"
// import News from '../../news/list-mini'
// import Ads from '../../ads'
// import Forum from '../../forum'

export default props => {
  // render() {
  const { t, currentLanguage: lang } = props

  return (
    <div className="row">
      <div className="col-md-8">

        <Legislation
          title={t('legislation.widget.title.moldova')}
          area="md"
          lang={lang}
          items={linksMD(t)}
        />

        <Legislation
          title={"LEGISLATIA UNIUNEI EUROPENE"}
          area="eu"
          lang={lang}
          items={linksEU(t)}
        />
      </div>
      <div className="col-md-8">

        <Legislation
          title={t('legislation.widget.title.international')}
          area="int"
          lang={lang}
          items={linksINT(t)}
        />

      </div>
    </div>
  )
}
