import React, { Component } from 'react'

import AddEditCar from './form'
import News from '../../../news/list-mini'
import Ads from '../../../ads/list-mini'
import Forum from '../../../forum'
import Banner from '../../../banner'
import { BANNER_THIN_SIZE } from '../../../../store/constants'

export default class extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-10 col-xl-9">
          <AddEditCar itemId={this.props.itemId} />
        </div>
        <div className="col-md-6 col-xl-7">
          <News />
          <Banner size={BANNER_THIN_SIZE} />
          <Ads />
          <Forum />
        </div>
      </div>
    )
  }
}
