import React, { Component } from 'react'
import classNames from 'classnames'
import WidgetHeader from '../../../shared/components/widget-header'
import moment from "moment"
import DatePicker from '../../../UI/date-picker'
import ImageUploadField from '../../../UI/image-upload-field'
import fetch from '../../../shared/fetch'
import { Field } from 'redux-form'
import FieldBase from '../../../UI/field-base'
import { getLanguageLowercaseName } from "../../../../store/utils"
import config from '../../../../config'
import { confirmAlert } from 'react-confirm-alert'

import './styles.scss'

const initialState = {
    bannerType: "",
    defaultLink: "",
    defaultShablon: "",
    bannerWidthOrMaxLength: "",
    bannerHeight: "",
    placeId: 0,
    bannerLangId: "",
}
const STYLE_BASE = 'adsbanner-modal'

export default class AdsBannerModal extends Component {
    state = {
        dateBegin: null,
        dateEnd: null,
        bannerDescription: "",
        bannerName: "",
        bannerLink: "",
        bannerInfo: {},
        defaultShablon: "",
        datesIsLoaded: false,
        showUploadedImage: false,
    }

    componentDidMount() {
        const { langId, bannerId, initialize } = this.props
        initialize({
            bannerEditModalData: { ...initialState },
        })

        this.initializeFormValues(langId, bannerId)
    }

    initializeFormValues = async (langId, bannerId) => {
        const { initialize } = this.props
        const lang = getLanguageLowercaseName(langId)

        const data = await fetch(`/common/banner/${bannerId}/lang/${lang}`)

        const lastItem = data.d.length - 1


        if (data.d[lastItem]) {
            const {
                adv_type: bannerType,
                adv_id: bannerId,
                banner_link: defaultLink,
                banner_name: defaultShablon,
                banner_description: bannerDescription,
                default_size_1: bannerWidthOrMaxLength,
                default_size_2: bannerHeight,
                item_id: placeId,
                lang_id: bannerLangId,
                date_begin: dateBegin,
                date_end: dateEnd,

            } = data.d[lastItem]


            this.setState({
                defaultShablon
            })

            if (dateBegin && dateEnd) {
                this.setState({
                    dateBegin: moment.utc(dateBegin),
                    dateEnd: moment.utc(dateEnd)
                })
            }

            initialize({
                bannerEditModalData: {
                    bannerType,
                    defaultLink,
                    bannerName: defaultShablon,
                    bannerWidthOrMaxLength,
                    bannerHeight: bannerHeight || 0,
                    placeId,
                    bannerLangId,
                    bannerDescription,
                    bannerId
                },
            })
        } else {
            initialize({
                bannerEditModalData: {
                    bannerType: "",
                    defaultLink: "",
                    defaultShablon: "",
                    bannerWidthOrMaxLength: 0,
                    bannerHeight: 0,
                    placeId: "",
                    bannerLangId: "",
                },
            })
        }

        this.setState({ datesIsLoaded: true })
    }

    onChangeDateTo = (dateEnd) => {
        this.setState({ dateEnd })
    }

    onChangeDateFrom = (dateBegin) => {
        this.setState({ dateBegin })
    }

    moveInToStateThisValue = selectedLang => {
        this.setState({ selectedLang })
    }

    insertBannerPlace = (placeId) => {
        const { langId, onClose, bannerEditModalData: {
            bannerDescription,
            bannerName,
            defaultLink
        } } = this.props

        const { dateBegin, dateEnd } = this.state

        const lang = getLanguageLowercaseName(langId)

        const body = JSON.stringify({
            lang,
            description: bannerDescription,
            name: bannerName || "",
            link: defaultLink,
            dateBegin: moment(dateBegin).format('YYYY-MM-DD'),
            dateEnd: moment(dateEnd).format('YYYY-MM-DD'),
        })

        fetch(`/common/banner/${placeId}`, {
            method: 'POST',
            body,
        }).then(() => {
            onClose()
        })
    }

    deleteBannerPlace = (bannerId) => {
        const { onClose, t } = this.props

        confirmAlert({
            title: t('delete.confirm'),
            message: (
                <span>
                    {t('delete.sure')}
                    <br />
                    {t('delete.cannot.undone')}
                </span>
            ),
            buttons: [
                {
                    label: t('delete'),
                    onClick: () => {
                        fetch(`/common/banner/${bannerId}`, {
                            method: 'DELETE',
                        }).then(() => {
                            onClose()
                        })
                    },
                },
                {
                    label: t('button.close'),
                    onClick: this.setState({ showUploadedImage: false }),
                },
            ],
            onClickOutside: this.setState({ showUploadedImage: false }),
            onKeypressEscape: this.setState({ showUploadedImage: false }),
        })



    }

    handleImageError = e => {
        this.setState({ showUploadedImage: true })
    }

    render() {
        const {
            onClose,
            t,
            langText,
            bannerEditModalData,
        } = this.props

        const { dateBegin, dateEnd, defaultShablon, datesIsLoaded, showUploadedImage } = this.state

        const isBannerImage = bannerEditModalData && bannerEditModalData.bannerType === "B"
        const disabled = isBannerImage && !bannerEditModalData?.bannerName || !dateEnd || !dateBegin || !bannerEditModalData?.defaultLink || !bannerEditModalData?.bannerDescription

        return (
            <div className={classNames('panel panel-has-user', `${STYLE_BASE}`)}>

                <WidgetHeader className="panel-header" title={`${t('ads.banner.title.modal')} (${langText.toUpperCase()})`}>
                    <div onClick={() => onClose()} className="fa fa-times" />
                </WidgetHeader>
                <form>
                    <div className="block-list-container">
                        <div className="panel-content-row">
                            <h2>{t('ads.about.banner')}</h2>
                            <div className="banner-line-description">
                                <div>{t('ads.banner.name')}</div>
                                <div className="banner-input-field">
                                    <Field
                                        component={FieldBase}
                                        name="bannerEditModalData.bannerDescription"
                                        disabled={bannerEditModalData?.bannerId}
                                        maxLength='100'
                                    />
                                </div>
                            </div>
                            {isBannerImage && <div className="banner-line-description banner-container">
                                <div>{t('ads.banner.path')}</div>
                                <div>
                                    {!defaultShablon && <Field
                                        name="bannerEditModalData.bannerName"
                                        component={ImageUploadField}
                                        className="photo-block photo-rounded"
                                        folder={"/uploads/banners"}
                                    />}

                                    {defaultShablon && !showUploadedImage ?
                                        <img src={`../../images/banner_templates/${defaultShablon}`} alt="banner" onError={this.handleImageError} /> :

                                        showUploadedImage &&
                                        <img src={`${config.apiUrl}/common/file/uploads/banners/${defaultShablon}`} alt={defaultShablon} />}
                                </div>
                            </div>}
                            {!isBannerImage && <div className="banner-line-description">
                                <div> {t('ads.banner.url.text')}</div>
                                <div className="banner-input-field textarea-wrapper">
                                    <Field
                                        name="bannerEditModalData.bannerName"
                                        component="textarea"
                                        disabled={bannerEditModalData?.bannerId}
                                        maxLength="400"
                                    />
                                    <div className="count-wrapper">
                                        <span className="count">{bannerEditModalData?.bannerName?.length || 0}</span>
                                            {` / `}
                                        <span className="length">400</span>
                                    </div>
                                </div>
                            </div>}
                            <div className="banner-line-description">
                                <div> {t('ads.banner.url.label')}</div>
                                <div className="banner-input-field">
                                    <Field
                                        name="bannerEditModalData.defaultLink"
                                        component={FieldBase}
                                        disabled={bannerEditModalData?.bannerId}
                                    />
                                </div>
                            </div>
                            {datesIsLoaded &&
                                <>
                                    <div className="banner-line-description">
                                        <div>{t('ads.banner.date.from')}</div>
                                        <div className="banner-input-field date-field">
                                            <DatePicker
                                                initialValue={dateBegin}
                                                onChangeDate={this.onChangeDateFrom}
                                                disabled={bannerEditModalData?.bannerId}
                                            />
                                        </div>
                                    </div>
                                    <div className="banner-line-description">
                                        <div>{t('ads.banner.date.to')}</div>
                                        <div className="banner-input-field date-field">
                                            <DatePicker
                                                initialValue={dateEnd}
                                                onChangeDate={this.onChangeDateTo}
                                                disabled={bannerEditModalData?.bannerId}
                                            />
                                        </div>
                                    </div>
                                </>
                            }

                        </div>

                        {bannerEditModalData && bannerEditModalData.bannerId ?
                            <>
                                <div className="banner-buttons-block">
                                    <button
                                        type="button"
                                        className="button button-orange"
                                        onClick={() => this.deleteBannerPlace(bannerEditModalData.bannerId)}
                                    >
                                        {t('delete')}
                                    </button>
                                </div>
                            </>
                            :
                            <>
                                <div className="banner-buttons-block">
                                    <button
                                        type="button"
                                        className="button button-orange"
                                        {...{ disabled }}
                                        onClick={() => this.insertBannerPlace(bannerEditModalData.placeId)}
                                    >
                                        {t('confirm')}
                                    </button>

                                    <button
                                        type="button"
                                        className="button cancel"
                                        onClick={() => onClose()}
                                    >
                                        {t('cancel')}
                                    </button>
                                </div>
                            </>}

                    </div>
                </form>
            </div>

        )
    }
}
