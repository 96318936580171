import * as actionTypes from '../actions/action-types'
import { LIST_LAYOUT_GRID } from '../constants'
import { baseProps } from '../models'
import { NewsState, NewsList, NewsItem } from './types'

const baseEmptyItem = {
  langId: 1, //undefined,
  itemId: undefined,
  //title: 'ooooooooo', //undefined,
  title: undefined,
  short: undefined,
  body: '', // undefined,
  priority: '1',
  category: undefined,
  // mini: false,
  published: false,
  image: undefined,
  video: undefined,
  files: [],
  // ru: true,
  // en: false,
  // ro: false,
}
const baseItem: NewsItem = {
  itemId: undefined,
  langId: undefined,
  treeTypeId: undefined,
  title: undefined,
  body: undefined,
  date: undefined,
  published: undefined,
  image: undefined,
  short: undefined,
  order: undefined,
  userName: undefined,
  count: undefined,
  mode: undefined,
  ...baseProps,
}
const baseList = (pageSize: number): NewsList => ({
  languages: [],
  langId: undefined,
  total: 0,
  pageIndex: 0,
  pageSize,
  filterKey: undefined,
  searchText: undefined,
  items: [],
  ...baseProps,
})
const initialState: NewsState = {
  mini: {
    ...baseList(5),
  },
  list: {
    ...baseList(10),
    isGridLayout: true,
  },
  item: {
    ...baseItem,
  },
  comments: {
    ...baseList(10),
    itemId: undefined,
    adding: false,
  },
  comment: {
    itemId: undefined,
    commentId: undefined,
    text: '',
    ...baseProps,
  },
  editModal: {
    isOpen: false,
    item: baseEmptyItem,
    // uploading: false,
  },
}

function getListProps({
  itemId,
  languages,
  langId,
  total = 0,
  pageIndex = 0,
  pageSize = 10,
  items = [],
}) {
  return {
    itemId,
    languages,
    langId,
    total,
    pageIndex,
    pageSize,
    items,
  }
}
// ------------------------------------------
// list-mini items
// ------------------------------------------
const listMiniStartLoading = (state: NewsState, action) => {
  const {
    requestedAt,
    langId,
    pageIndex,
    pageSize,
    filterKey,
    // clientId,
    // clientName,
  } = action

  const mini = {
    ...state.mini,
    langId,
    pageIndex,
    pageSize,
    filterKey,

    requestedAt,
    loading: true,
    error: undefined,
  }

  return {
    ...state,
    mini,
  }
}
const listMiniLoaded = (state: NewsState, action) => {
  const props = getListProps(action.data)
  const mini = {
    ...state.mini,
    error: undefined,
    loading: false,
    ...props,
  }

  return { ...state, mini }
}
const listMiniLoadError = (state: NewsState, action) => {
  const mini = {
    ...state.mini,
    error: action.error,
    loading: false,
    items: [],
  }

  return { ...state, mini }
}

// ------------------------------------------
// news items
// ------------------------------------------
const listStartLoading = (state: NewsState, action) => {
  const {
    requestedAt,
    langId,
    pageIndex,
    pageSize,
    filterKey,
    // clientId,
    // clientName,
  } = action

  const list = {
    ...state.list,
    langId,
    pageIndex,
    pageSize,
    filterKey,

    requestedAt,
    loading: true,
    error: undefined,
  }

  return {
    ...state,
    list,
  }
}
const listLoaded = (state: NewsState, action) => {
  const props = getListProps(action.data)
  const list = {
    ...state.list,
    error: undefined,
    loading: false,
    ...props,
  }

  return { ...state, list }
}
const listLoadError = (state: NewsState, action) => {
  const list = {
    ...state.list,
    error: action.error,
    loading: false,
    items: [],
  }

  return { ...state, list }
}

// ------------------------------------------
// single news item
// ------------------------------------------
const itemStartLoading = (state: NewsState, action) => {
  const { requestedAt, itemId } = action
  const item = {
    ...baseItem,
    itemId,
    requestedAt,
    loading: true,
  }
  return { ...state, item }
}
const itemLoaded = (state: NewsState, action) => {
  const item = {
    ...state.item,
    ...action.data,
    error: undefined,
    loading: false,
  }
  return { ...state, item }
}
const itemLoadError = (state: NewsState, action) => {
  const item = {
    ...state.item,
    error: action.error,
    loading: false,
  }

  return { ...state, item }
}

// ------------------------------------------
// news comments
// ------------------------------------------
const commentsStartLoading = (state: NewsState, action) => {
  const {
    requestedAt,
    itemId,
    pageIndex,
    pageSize,
    // clientId,
    // clientName,
  } = action

  const comments = {
    ...state.comments,
    itemId,
    pageIndex,
    pageSize,

    requestedAt,
    loading: true,
    error: undefined,
  }

  return { ...state, comments }
}
const commentsLoaded = (state: NewsState, action) => {
  const props = getListProps(action.data)
  const comments = {
    ...state.comments,
    error: undefined,
    loading: false,
    ...props,
  }

  return { ...state, comments }
}
const commentsLoadError = (state: NewsState, action) => {
  const comments = {
    ...state.comments,
    error: action.error,
    loading: false,
    items: [],
  }

  return { ...state, comments }
}
// ------------------------------------------
// add comment
// ------------------------------------------
const commentStartAdding = (state: NewsState, action) => {
  const { requestedAt, itemId, text } = action

  const comment = {
    ...state.comment,
    itemId,
    commentId: undefined,
    text,

    requestedAt,
    loading: true,
    error: undefined,
  }

  return { ...state, comment }
}
const commentAdded = (state: NewsState, action) => {
  const props = getListProps(action.data)
  const comments = {
    ...state.comments,
    error: undefined,
    loading: false,
    ...props,
  }

  const comment = {
    ...state.comment,
    item: undefined,
    commentId: undefined,
    text: '',
    error: undefined,
    loading: false,
  }

  return { ...state, comment, comments }
}
const commentAddError = (state: NewsState, action) => {
  const comment = {
    ...state.comment,
    error: action.error,
    loading: false,
  }

  return { ...state, comment }
}
// ------------------------------------------
// set edit item
// ------------------------------------------
const setEditingItem = (state: NewsState, action) => {
  const { item, langId } = action
  const editModal = {
    ...state.editModal,
    item: { ...baseEmptyItem, ...item, langId },
  }
  return { ...state, editModal }
}
// ------------------------------------------
// show/hide Modal
// ------------------------------------------
const showEditModal = (state: NewsState, action) => {
  const { item, langId } = action
  const editModal = {
    ...state.editModal,
    isOpen: true,
    item: { ...baseEmptyItem, ...item, langId, loading: true },
  }
  return { ...state, editModal }
}
const hideEditModal = state => {
  const editModal = { ...state.editModal, isOpen: false }
  return { ...state, editModal }
}

// ---------------------------------------
// reducer
// ---------------------------------------
export default (state = initialState, action: any = {}): NewsState => {
  const { type } = action
  switch (type) {
    case actionTypes.NEWS_LIST_MINI_LOADING:
      return listMiniStartLoading(state, action)
    case actionTypes.NEWS_LIST_MINI_LOADED:
      return listMiniLoaded(state, action)
    case actionTypes.NEWS_LIST_MINI_LOAD_ERROR:
      return listMiniLoadError(state, action)

    case actionTypes.NEWS_LIST_LOADING:
      return listStartLoading(state, action)
    case actionTypes.NEWS_LIST_LOADED:
      return listLoaded(state, action)
    case actionTypes.NEWS_LIST_LOAD_ERROR:
      return listLoadError(state, action)
    case actionTypes.NEWS_LIST_SET_PARAMS: {
      const {
        payload: { scope, params },
      } = action
      if (scope === 'list') {
        return { ...state, list: { ...state.list, ...params } }
      } else if (scope === 'mini') {
        return { ...state, mini: { ...state.mini, ...params } }
      }
      return state
    }

    case actionTypes.NEWS_ITEM_LOADING:
      return itemStartLoading(state, action)
    case actionTypes.NEWS_ITEM_LOADED:
      return itemLoaded(state, action)
    case actionTypes.NEWS_ITEM_LOAD_ERROR:
      return itemLoadError(state, action)

    case actionTypes.NEWS_COMMENTS_LOADING:
      return commentsStartLoading(state, action)
    case actionTypes.NEWS_COMMENTS_LOADED:
      return commentsLoaded(state, action)
    case actionTypes.NEWS_COMMENTS_LOAD_ERROR:
      return commentsLoadError(state, action)

    case actionTypes.NEWS_COMMENT_ADDING:
      return commentStartAdding(state, action)
    case actionTypes.NEWS_COMMENT_ADDED:
      return commentAdded(state, action)
    case actionTypes.NEWS_COMMENT_ADD_ERROR:
      return commentAddError(state, action)

    case actionTypes.NEWS_SET_EDITING_ITEM:
      return setEditingItem(state, action)
    case actionTypes.NEWS_MODAL_SHOW:
      return showEditModal(state, action)
    case actionTypes.NEWS_MODAL_HIDE:
      return hideEditModal(state)
    case actionTypes.NEWS_SAVE_START:
      return state
    case actionTypes.NEWS_SAVED:
      return state
    case actionTypes.NEWS_SAVE_ERROR:
      return state

    case actionTypes.NEWS_DELETING:
      return { ...state, item: { ...state.item, mode: 'deleting' } }
    case actionTypes.NEWS_DELETED:
      return { ...state, item: { ...state.item, mode: 'deleted' } }
    case actionTypes.NEWS_DELETE_ERROR:
      return { ...state, item: { ...state.item, mode: undefined } }

    case actionTypes.NEWS_SWITCH_LAYOUT:
      return {
        ...state,
        list: { ...state.list, isGridLayout: action.layoutType === LIST_LAYOUT_GRID },
      }
    case actionTypes.NEWS_PUBLISH_FINISH: {
      const { itemId, published } = action
      let list = { ...state.list }
      const items = [...list.items]
      const item = items.find(x => x.itemId === itemId)
      if (item) {
        item.published = published
        list = { ...list, items }
        return { ...state, list }
      }
      return state
    }

    default:
      return state
  }
}
