import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Field } from 'redux-form'
import classNames from 'classnames'

import { focusFirstInput } from '../../shared/utility'
import { required } from '../../shared/form-validators'
import FormBase from '../../UI/field-base'
import './auth-login.scss'

const STYLE_BASE = 'auth-login_'
export default class extends Component {
  constructor(props) {
    super(props)

    this.ref = React.createRef()
  }
  componentDidMount() {
    focusFirstInput(this.ref)
  }

  render() {
    const { t, handleSubmit, pristine, submitting, invalid, onClose } = this.props

    return (
      <form onSubmit={handleSubmit} ref={this.ref}>
        <div className={classNames('form form-simple', `${STYLE_BASE}wrapper`)}>
          <div className="form-row">
            <Field
              name="username"
              type="text"
              component={FormBase}
              label={t('login.username')}
              placeholder={t('login.username.placeholder')}
              validate={[required]}
            />
          </div>
          <div className="form-row">
            <Field
              name="password"
              type="password"
              component={FormBase}
              label={t('login.password')}
              placeholder={t('login.password.placeholder')}
              validate={[required]}
            />
          </div>
          <div className="form-row form-row-buttons">
            <button
              className="button button-orange button-centered"
              disabled={pristine || submitting || invalid}
              type="submit"
            >
              {t('login.enter')}
            </button>
            <Link
              to="/auth/recover-password"
              onClick={() => {
                onClose()
                return true
              }}
            >
              {t('login.forgotPassword')}
            </Link>
            <p>{t('login.notRegistered')}</p>
            <Link
              to="/auth/registration"
              onClick={() => {
                onClose()
                return true
              }}
            >
              {t('login.register')}
            </Link>
          </div>
        </div>
      </form>
    )
  }
}
