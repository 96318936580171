import React from 'react'
import Widget from '../shared/components/widget'
import News from '../news/list-mini'
import Ads from '../ads/list-mini'
import Forum from '../forum'
import Banner from '../banner'
import Table from './table'

import "./styles.scss"

export default (props: ComponentBaseProps) => {
  const { t } = props

  return (
    <div className="row summary-table-page">
      <div className="col-md-9">
        <Widget title={t('summary.table.title')}>
          <Table entity="intranational" />
        </Widget>
        <div className="banner-row">
          <Banner bannerId={582} />
          <Banner bannerId={583} />
        </div>
        <Table entity="international" />
        <div className="banner-bottom">
          <Banner bannerId={583} />
        </div>

      </div>
      <div className="col-md-7">
        <News />
        <Ads />
        <Forum />
      </div>
    </div>
  )
}
