import { connect } from 'react-redux'
import { compose } from 'recompose'

import withTranslation from '../../../globals/components/withTranslation'
import { setInitialMessengerState, getConversationList } from '../../../store/actions'

import Component from './component'
import withPermissions from '../../../globals/components/withPermissions'
import { IReduxStorage } from '../../../store/reducers/types'
import { ConversationsListInterface } from './types'

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state: IReduxStorage) => {

      return {
        myConversationList: state.socket.myConversationList,
      }
    },
    {
        setInitialMessengerState,
        getConversationList
    }
  )
)(Component) as React.ComponentClass<ConversationsListInterface>
