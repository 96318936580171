import React, { Component } from 'react'
import ConversationSearch from '../conversationsearch'
import ConversationListItem from '../conversationlistitem'
import Toolbar from '../toolbar'
import ToolbarButton from '../toolbarbutton'
import fetch from '../../shared/fetch'
import Spinner from '../../UI/spinner'

import './conversationlist.scss'

interface ConversationListInterface extends ComponentBaseProps {
  userId: number
  showConversationWithUser: any
  isShowSpinner: any
  myConversationList: any
  closeMessenger: any
}

export default class ConversationList extends Component<ConversationListInterface> {
  state = {
    allConversationList: [],
    conversationList: [],
    showSpinner: false,
    activeConvId: 0,
    searchText: "",
    isLoading: false,
  }


  onMapConversationsList = (items) => {
    const conversationList = []

    items.forEach((oneConversation, id: number) => {
      const {
        all_messages,
        companyId,
        companyName,
        last_mess_time,
        list_user_id,
        new_messages,
        userFullName,
        gender,
        user_image,
        userPhoto,
        last_message,
      } = oneConversation

      const allConversations = {
        allMessages: all_messages,
        companyId: companyId,
        companyName: companyName,
        lastMessageTime: last_mess_time,
        listUserId: list_user_id,
        newMessages: new_messages,
        userFullName: userFullName,
        gender: gender,
        userImage: user_image,
        lastMessage: last_message,
        userPhoto: userPhoto,
      }

      conversationList.push(allConversations)
    })

    return conversationList
  }

  componentDidMount() {
    this.setState({ isLoading: true })
  }
  componentDidUpdate(prevProps) {
    const { myConversationList } = this.props

    if (myConversationList !== prevProps.myConversationList) {

      this.setState({
        allConversationList: this.onMapConversationsList(myConversationList),
        conversationList: this.onMapConversationsList(myConversationList),
        isLoading: false
      })
    }
  }

  getConversationList = () => {
    const { userId } = this.props
    const body = JSON.stringify({
      userId,
    })

    fetch('/messenger/getListContactsMsngr', {
      method: 'POST',
      body,
    }).then(messages => {
      const {
        data: { conversationList },
      } = messages

      this.setState({ allConversationList: conversationList, conversationList })
    })
  }

  setActiveConvItem = activeConvId => {
    this.setState({ activeConvId })
  }

  doSearch = (str) => {
    const { allConversationList } = this.state

    this.setState({
      conversationList: allConversationList.filter((item) =>
        item.userFullName?.toLowerCase().indexOf(str.toLowerCase()) !== -1
      ),
      searchText: str
    })
  }

  showConversationWithUser = (listUserId, userFullName) => {
    const { showConversationWithUser, userId } = this.props
    showConversationWithUser(listUserId, userFullName)
    setTimeout(() => {
      window.socket.emit('GET_CONVERSATION_LIST', userId);
    }, 1000);

  }

  render() {
    const { isShowSpinner, t, closeMessenger } = this.props
    const { conversationList, activeConvId, searchText, isLoading } = this.state

    return (
      <div className="conversation-list">
        {/*
        <Toolbar
          title={t('messenger.messages')}
          leftItems={[<ToolbarButton key="cog" icon="ion-ios-cog" />]}
          rightItems={[<ToolbarButton key="add" icon="ion-ios-add-circle-outline" />]}
        />
*/}
        <ConversationSearch searchCallback={this.doSearch} />

        {isLoading ? <Spinner /> : conversationList.length > 0 ? conversationList.map((conversation, index) => (
          <ConversationListItem
            key={index}
            conversationDetails={conversation}
            showConversationWithUser={this.showConversationWithUser}
            isShowSpinner={() => isShowSpinner()}
            activeConvId={activeConvId}
            closeMessenger={closeMessenger}
            setActiveConv={e => {
              this.setActiveConvItem(e)
            }}
          />
        )) : (
          <div className="conversation-not-found">
            {searchText.length > 0 ? t('messenger.no.results') : t('messenger.no.conversations')}
          </div>
        )}
      </div>
    )
  }
}
