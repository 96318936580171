import React, { Component } from 'react'
import classNames from 'classnames'
import { Field } from 'redux-form'

import config from '../../../config'
import WidgetBase from '../../shared/components/widget-base'
import { USER_PHOTO_URL_PLACEHOLDER } from '../../../store/constants'
import { required } from '../../shared/form-validators'

import './styles.scss'

const STYLE_BASE = 'news-add-comment_'
const MAX_LENGTH = 1000

export default class extends Component {
  state = {
    textLength: 0,
  }

  componentDidUpdate(prevProps) {
    const { itemId, text } = this.props
    if (itemId !== prevProps.itemId) {
      this.props.reset()
    }
    if (text !== prevProps.text) {
      this.setState({ textLength: 0 })
    }
  }

  componentWillUnmount() {
    this.props.reset()
  }

  onChange = e => {
    const { currentTarget: { textLength = 0 } = {} } = e
    this.setState({ textLength })
  }

  render() {
    const { t, handleSubmit, invalid, submitting, saving, photo, userId } = this.props
    const { textLength } = this.state
    const userPhoto = photo
      ? `${config.apiUrl}/common/file/profile/${userId}/${photo}`
      : USER_PHOTO_URL_PLACEHOLDER

    return (
      <WidgetBase title={t('news.widget.title.addComment')}>
        <div className={classNames('panel-comments', `${STYLE_BASE}wrapper`)}>
          <div className="panel-content-row">
            <div className="panel-comment panel-comment-add">

              <form>
                <div className="add-comment-top-form">
                  <div className={`${STYLE_BASE}info-row`}>
                    <label>{t('forum.your.comment')}:</label>
                    <div className="counter">
                      <span>{textLength}</span>
                      <span>{` / ${MAX_LENGTH}`}</span>
                    </div>
                  </div>
                </div>

                <Field
                  name="text"
                  component="textarea"
                  placeholder={t('validation.maxLength', { max: MAX_LENGTH })}
                  className="textarea-resize"
                  maxLength={MAX_LENGTH}
                  validate={required}
                  onChange={this.onChange}
                />

                <button
                  type="submit"
                  className="button button-orange"
                  disabled={invalid || saving || submitting || textLength === 0}
                  onClick={handleSubmit}
                >
                  {t('send')}
                </button>
              </form>
            </div>
          </div>
        </div>
      </WidgetBase>
    )
  }
}
