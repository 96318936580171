import React from 'react'
import { Link } from 'react-router-dom'
import { CompanyInfo } from '../../types'

const PhonesRow: React.SFC<CompanyInfo> = ({ phone1, phone2, fax1, fax2 }) => {
  return (
    <div className="panel-content-row">
      {phone1 && (
        <Link to="#" className="link link-download">
          <span className="fa fa-phone"></span>
          <span>{phone1}</span>
        </Link>
      )}
      {phone2 && (
        <Link to="#" className="link link-download">
          <span className="fa fa-mobile"></span>
          <span>{phone2}</span>
        </Link>
      )}
      {fax1 && (
        <Link to="#" className="link link-download">
          <span className="fa fa-fax"></span>
          <span>{fax1}</span>
        </Link>
      )}
      {fax2 && (
        <Link to="#" className="link link-download">
          <span className="fa fa-fax"></span>
          <span>{fax2}</span>
        </Link>
      )}
    </div>
  )
}

export default PhonesRow
