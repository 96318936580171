import { connect } from 'react-redux'
import { compose } from 'recompose'
// import { reduxForm } from "redux-form"
// import uuid from 'uuid/v4'

import { setActivePopup } from '../../../../../store/actions/ui'

import Component from './component'

export default compose(
  connect(
    state => {
      // const { items } = props
      // const initialValues = {
      //   ru: false,
      //   en: false,
      //   ro: false,
      // }
      return {
        isAuthenticated: !!state.auth.accessToken,
        activeGuid: state.ui.activePopupGuid,
        // initialValues,
      }
    },
    {
      setActivePopup,
    }
  )
  // reduxForm({
  //   form: `widgetHeaderMenu${uuid()}`,
  //   // initialValues,
  //   // touchOnChange: true,
  //   // touchOnBlur: false,
  //   // onSubmit: (values, dispatch, props) => dispatch(register(values, () => {
  //   //   // const { ok, d: { html = '' } = {} } = response
  //   //   props.history.push('/auth/registration/success')
  //   // })),
  // }),
)(Component)
