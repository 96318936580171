import React, { Component } from 'react'
import classNames from 'classnames'
import { Redirect } from 'react-router-dom'
import { Field } from 'redux-form'
import uuid from 'uuid/v4'
import { Popover, PopoverBody } from 'reactstrap'
import Cookies from 'universal-cookie'
import WidgetBase from '../../../../shared/components/widget-base'
import { required } from '../../../../shared/form-validators'
import Dropdown from '../../../../UI/dropdown'
import dict from '../../../../shared/dictionaries'
import LoadData from './car-load-data'
import BaseData from './car-base-data'
import CargoData from './cargo-data'
import CarPhoto from './car-photo'
import AvailableDocs from './available-docs'
import { BUSE_ID, CARGO_ID, TRACTOR_ID, CARGO_LARGE_ID } from '../../autopark-constants'
import TooltipWrapper from '../../../../UI/popover-wrapper'
import { canDo } from '../../../../../store/utils'
import Captcha from '../../../../captcha'

import './styles.scss'

const STYLE_BASE = 'auto-park-add_'
const LIMIT_CHARTS = 250
export default class extends Component {
  constructor(props) {
    super(props)

    this.butAddId = `id${uuid()}`
    this.state = {
      redirectTo: undefined,
      popoverId: undefined,
      activeTab: '',
      isCarCompany: 0,
      captchaVerified: false
    }
    const { itemId, loadCar, newCar } = props
    if (itemId) {
      loadCar(itemId)
    } else {
      setTimeout(() => {
        newCar()
      }, 100);
    }
  }

  componentDidMount() {
    const { activeTab, onCanAdd, permissions } = this.props
    if (canDo(permissions, 'captcha', 'skip')) {
      this.setState({ captchaVerified: true })
    }

    this.setState({ activeTab })
    const onCannAddValue = activeTab === "myPark" ? "autopark" : "company-autopark"
    onCanAdd(onCannAddValue)
    const isCarCompany = activeTab === 'companyPark' ? 1 : 0
    this.setState({ isCarCompany })
  }

  onClickSave = () => {
    const { invalid, handleSubmit, itemId } = this.props
    const { isCarCompany } = this.state
    if (invalid) {
      this.setState({ popoverId: this.butAddId })
      return
    }
    handleSubmit(values => {
      const item = { ...values, itemId, isCarCompany }
      this.props.saveCar({ item }, () => {
        this.setState({ redirectTo: '/transport/autopark' })
      })
    })()
  }
  renderPopover = butId => {
    return (
      <Popover
        placement="top"
        isOpen={this.state.popoverId === butId}
        target={butId}
        toggle={() => this.setState({ popoverId: undefined })}
      >
        <PopoverBody>{this.props.t('validation.not-all-required-fields-entered')}</PopoverBody>
      </Popover>
    )
  }

  baseDataTitle = (typeId, t) => {
    let title = ''

    switch (typeId) {
      case BUSE_ID:
        title = t('add.transport.for.buse')
        break
      case CARGO_ID:
        title = t('add.transport.for.cargo')
        break
      case TRACTOR_ID:
        title = t('add.transport.for.tractor')
        break
      case CARGO_LARGE_ID:
        title = t('add.transport.for.cargo')
        break
    }

    return title
  }

  additionalBaseDataTitle = (typeId, t) => {
    let title = ''

    switch (typeId) {
      case TRACTOR_ID:
        title = t('add.transport.for.semitrailer')
        break
      case CARGO_LARGE_ID:
        title = t('add.transport.for.trailer')
        break
    }

    return title
  }

  render() {
    const { t, permissions } = this.props

    const { redirectTo, captchaVerified } = this.state
    if (redirectTo) {
      return <Redirect to={redirectTo} />
    }

    const {
      userId = '',
      pristine,
      submitting,
      carType = '',
      ownershipType = '',
      notes = '',
      itemId,
      form: formName,
      isLoadingSide,
      isLoadingTop,
      isLoadingRare,
      canAdd,
      enteredValues
    } = this.props

    const { activeTab } = this.state
    const showCaptcha = !canDo(permissions, 'captcha', 'skip')
    const folder = `auto-park/${userId}`
    console.log("enteredValues", enteredValues)
    const carTypesDict = dict.carTypes(t)
    const ownershipTypesDict = dict.ownershipTypesCars(t)
    const hasTrailer = [2102, 2103].includes(carType) //carType.indexOf('trailer') !== -1
    const tabLabel = itemId ? t('auto-park.edit-car') : activeTab === 'companyPark'
    const title = itemId ? t('auto-park.edit-car') : tabLabel ? t('auto-park.add-company-car') : t('auto-park.add-car-in-my-list')
    //1 - company cars
    //0 - my cars

    const aditionalItemForcarTypes = [{ name: t('choose.one'), nameEng: 'Select one', value: 0 }]
    const aditionalItemForownershipTypes = [
      { name: t('choose.one'), nameEng: 'Select one', value: 0 },
    ]
    const carTypes = aditionalItemForcarTypes.concat(carTypesDict)
    const ownershipTypes = aditionalItemForownershipTypes.concat(ownershipTypesDict)

    const notValidCarTypeField = !carType || carType === 0

    const typePlaceholder = this.baseDataTitle(carType, t)
    const typeTrailerPlaceholder = this.additionalBaseDataTitle(carType, t)
    const checked = isLoadingSide || isLoadingTop || isLoadingRare

    const noRequiredFieldsForTransport = !enteredValues?.car?.makeAndModel || !enteredValues?.car?.year
    const noRequiredFieldsForTrailer = !enteredValues?.trailer?.makeAndModel || !enteredValues?.trailer?.bodyType || !enteredValues?.trailer?.year
    const disabled = pristine || submitting || !checked || (!itemId && !canAdd) || (hasTrailer && noRequiredFieldsForTrailer) || noRequiredFieldsForTransport || !captchaVerified

    return (
      <form>
        <WidgetBase
          title={title}
          className={classNames({
            [`${STYLE_BASE}wrapper`]: true,
          })}
        >
          <div className="panel-body">
            <div className="panel-content">
              <div className="panel-form">
                <div className="panel-content-row">
                  <div className="panel-form-row">
                    <div className="row">
                      <div className="col-mob-lg col-xs-2">
                        <span className="icon-truck">
                          <i
                            className={classNames({
                              'ico-placeholder': true,
                              'ico-truck-buse': carType === BUSE_ID,
                              'ico-truck-cargo': carType === CARGO_ID,
                              'ico-truck-tractor': carType === TRACTOR_ID,
                              'ico-truck-cargo-lg': carType === CARGO_LARGE_ID,
                            })}
                          />
                        </span>
                      </div>
                      <div className="col-mob-lg col-xs-7 col-sm-6">
                        <label className="required-field">{t('car.type')}</label>
                        <Field
                          name="carType"
                          component={Dropdown}
                          items={carTypes}
                          placeholder={t('choose.one')}
                          validate={required}
                        />
                      </div>
                      <div className="col-mob-lg col-xs-7 col-sm-6">
                        <label>{t('car.ownership')}</label>
                        <Field
                          name="ownershipType"
                          component={Dropdown}
                          items={ownershipTypes}
                          placeholder={t('choose.one')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {!notValidCarTypeField && (
                  <>
                    <BaseData
                      title={t('add.transport.placeholder') + typePlaceholder}
                      {...{ formName }}
                      makeAndModelLabel={
                        t('add.transport.model') +
                        typePlaceholder +
                        t('add.transport.and.model.by.technical.certificate')
                      }
                      makeAndModelName="car.makeAndModel"
                      stateNumberName="car.stateNumber"
                      bodyTypeName="car.bodyType"
                      yearName="car.year"
                      ecoStandardName="car.ecoStandard"
                      {...{ carType }}
                    />
                    <BaseData
                      title={t('add.transport.placeholder') + typeTrailerPlaceholder}
                      {...{ formName }}
                      makeAndModelLabel={
                        t('add.transport.model') +
                        typeTrailerPlaceholder +
                        t('add.transport.and.model.by.technical.certificate')
                      }
                      makeAndModelName="trailer.makeAndModel"
                      stateNumberName="trailer.stateNumber"
                      bodyTypeName="trailer.bodyType"
                      yearName="trailer.year"
                      className={classNames({
                        [`${STYLE_BASE}trailer-row`]: true,
                        [`${STYLE_BASE}no-trailer-row`]: !hasTrailer,
                      })}
                      isVisible={hasTrailer}
                    />
                  </>
                )}
                <div className="disabled-layer">
                  {notValidCarTypeField && <div className={'layer-overlay'} />}
                  <LoadData
                    weightName="weight"
                    loadingTopName="isLoadingTop"
                    loadingSideName="isLoadingSide"
                    loadingRareName="isLoadingRare"
                    {...{ formName }}
                  />
                  <CargoData
                    adrName="isAdr"
                    adrClassName="adrClass"
                    oversizedName="isOversized"
                    groupingName="isGrouping"
                    gpsName="isGps"
                    isAutopark
                  />
                  <AvailableDocs />
                  <CarPhoto folder={folder} />
                  <div className="panel-content-row">
                    <div className="panel-form-row clearfix">
                      <span className="label fl-left">{`${t('addition-info-optional')}: `}</span>
                      <span className="col-gray length-countdown fl-right">
                        <span className="count">{(notes && notes.length) || 0}</span>
                        {` / `}
                        <span className="length">{LIMIT_CHARTS}</span>
                      </span>
                      <Field
                        name="notes"
                        component="textarea"
                        placeholder={t('validation.maxLength', { max: LIMIT_CHARTS })}
                        maxLength={LIMIT_CHARTS}
                        className={'add-transport-textarea'}
                      />
                    </div>


                    {showCaptcha && (
                      <Captcha
                        verifyCallback={() => {
                          this.setState({ captchaVerified: true });
                        }}
                        expiredCallback={() => this.setState({ captchaVerified: false })}
                      />
                    )}


                    <div className="panel-form-row panel-form-row-button clearfix allow-btn-container">
                      <button
                        className="button button-orange fl-right"
                        disabled={disabled}
                        type="button"
                        onClick={this.onClickSave}
                        id={this.butAddId}
                      >
                        {t(itemId ? 'save.changes' : 'add')}
                      </button>

                      {!itemId && !canAdd && <TooltipWrapper text={t('autopark.limit')} hover>
                        <i className="fa fa-question-circle" style={{ marginTop: 8, marginRight: 5 }} />
                      </TooltipWrapper>}
                      {this.renderPopover(this.butAddId)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </WidgetBase>
      </form>
    )
  }
}
