export const md5 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>ПРАВИЛА ДОРОЖНОГО ДВИЖЕНИЯ</h4><p></p>

<p></p><h4>РАЗДЕЛ I</h4><p></p>

<p></p><h4>ОБЩИЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">1. Правила дорожного движения (в дальнейшем - правила) устанавливают единый порядок движения транспортных средств и пешеходов на всей территории страны.
 Соблюдение настоящих правил гарантирует безопасность всех участников дорожного движения, охрану окружающей среды, защиту прав и интересов физических и
 юридических лиц, а также охрану их собственности.</p><br>

<p style="font-weight:500;">2. В Республике Молдова установлено правостороннее движение транспортных средств. Запрещается движение по общественным дорогам автомобилей, у которых рулевое
 колесо установлено справа, за исключением транспортных средств, участвующих в международном движении.</p><br>

<p style="font-weight:500;">3. Участники дорожного движения при движении по общественным дорогам обязаны соблюдать настоящие правила, руководствоваться средствами дорожной сигнализации
 и сигналами, применяемыми для регулирования дорожного движения, и своими действиями не наносить ущерба другим участникам дорожного движения, не подвергать
 их опасности, а также не создавать необоснованных препятствий или помех в движении больших, чем это вызвано неизбежными обстоятельствами.</p><br>

<p style="font-weight:500;">4. Каждый участник дорожного движения, соблюдающий настоящие правила, вправе рассчитывать на то, что и другие участники дорожного движения выполняют их
 требования.</p><br>

<p style="font-weight:500;">5. Запрещается создавать препятствия для движения транспортных средств и пешеходов при выполнении дорожных работ, складировать материалы, оставлять
 какие-либо предметы на проезжей части общественной дороги или на тротуарах без предварительного согласования с органами дорожной полиции. Не считаются
 препятствием находящиеся на тротуарах материалы или предметы, если при этом они не создают помех движению пешеходов.</p><br>

<p style="text-indent:2em;">Препятствия, ограничивающие движение по общественным дорогам, должны быть обозначены теми, кто их создал, днем - соответствующими средствами сигнализации
 (приложение N 5) и предупреждающими дорожными знаками 1.23 или 1.29, а в темное время суток - и фонарями красного цвета, видимыми с обеих сторон на
 расстоянии не менее 50 м.</p><br>

<p style="font-weight:500;">6. На общественных дорогах запрещена организация спортивных соревнований, тренировок, конкурсов и т. п. без предварительного согласования с дорожной
 полицией.Организаторы обязаны предпринять все необходимые меры для безопасного проведения таких мероприятий, а также для охраны других участников дорожного движения</p>

<p style="text-indent:2em;"></p><h6>7. В настоящих правилах используются следующие понятия и термины:</h6><p></p>

<p style="text-indent:2em;">дорожно-транспортное происшествие - событие, произошедшее вследствие нарушения норм безопасности дорожного движения, с вовлечением одного или нескольких транспортных средств, находящихся в движении по общественной дороге, приведшее к нарушению
 здоровья, нанесению телесных повреждений, гибели одного или нескольких человек либо причинению материального ущерба</p><br>
 
<p>обочина – продольная полоса, расположенная между проезжей частью и краем полотна дороги;</p><br>

<p style="text-indent:2em;">\"регулировщик\" - лицо, соответственно экипированное, наделенное полномочиями по контролю и регулированию дорожного движения посредством сигналов, установленных настоящими Правилами (полицейский, дежурный на железнодорожном переезде, работник
 дорожно-эксплуатационной службы, дежурный на паромной переправе);</p><br>
 
<p style="text-indent:2em;">состав транспортных средств – состав, сформированный из механического транспортного средства, являющегося тягачом, и прицепа или полуприцепа, который движется по общественной дороге как одно целое;</p><br>

<p style="text-indent:2em;">\"автомагистраль\" - дорога, специально сконструированная, обустроенная и обозначенная знаком 5.2, имеющая проезжие части, отделенные друг от друга разделительной полосой, а при ее отсутствии - другими средствами, предназначенная исключительно для движения механических транспортных средств на большой скорости,
 не имеющая пересечений на одном уровне с другими дорогами, железнодорожными путями и пешеходными переходами;</p><br>

<p style="text-indent:2em;">\"механическое транспортное средство за исключением мопеда\" - самоходное транспортное средство, предназначенное для перевозки людей или грузов либо производства работ, за исключением мопеда и рельсовых
 транспортных средств. Троллейбус считается механическим транспортным средством;</p><br>

<p style="text-indent:2em;">\"полоса движения \" - продольная полоса проезжей части, обозначенная или не обозначенная дорожной разметкой, имеющая ширину, достаточную для беспрепятственного движения в один ряд транспортных средств иных, чем двухколесные;</p><br>

<p style="text-indent:2em;">\"разделительная полоса\" - элемент дороги, выделенный конструктивно и/или посредством разметки, который разделяет смежные проезжие части и который не предназначен для движения и остановки транспортных средств;</p><br>

<p style="text-indent:2em;">велосипед (в том числе трехколесный) – транспортное средство с двумя (тремя) колесами и педалями или рычагами для его перемещения посредством мускульной силы пользователя;</p><br>

<p style="text-indent:2em;">\"проезжая часть\" - часть дорожного полотна, предназначенная для движения транспортных средств. Дорога может иметь несколько проезжих частей, отделенных одна от другой разделительными полосами;</p><br>

<p style="text-indent:2em;">\"уступи дорогу\" - обязанность участника дорожного движения воздержаться от движения, если это вынудит других участников, имеющих преимущество, резко изменить направление движения, скорость или остановиться;</p><br>

<p style="text-indent:2em;">регистрационный документ или документ учета транспортного средства – официальный государственный документ, подтверждающий регистрацию или постановку на учет транспортного средства и дающий разрешение на эксплуатацию 
его владельцу или лицу, имеющему мандат;</p><br>

<p style="text-indent:2em;">мопед – транспортное средство с двумя или тремя колесами, оборудованное двигателем внутреннего сгорания или электродвигателем. Рабочий объем двигателя внутреннего сгорания не превышает 50 cм3, мощность, как двигателя внутреннего сгорания, так
 и электродвигателя не превышает 4 кВт, а конструктивная скорость этого транспортного средства не превышает 45 км/ч;</p><br>
 
 <p style="text-indent:2em;">ограниченная обзорность – видимость в направлении движения, ограниченная геометрическими параметрами дороги, инженерными сооружениями, придорожными зелеными насаждениями или другими транспортными средствами;</p><br>
 
 <p style="text-indent:2em;">официальная колонна – колонна транспортных средств, впереди которой движется транспортное средство с приоритетным режимом движения, на котором включены проблесковые маячки синего и/или
 красного цвета, а также специальный звуковой сигнал;</p><br>

<p style="text-indent:2em;">\"регулируемое движение\" - регулирование действий участников дорожного движения посредством сигналов регулировщика или сигналов светофора;</p><br>

<p style="text-indent:2em;">\"дорожные условия\" - реальная дорожная обстановка, в которой находится транспортное средство в данный момент (интенсивность дорожного движения,
 состояние окружающей среды, наличие технических средств регулирования движения и т.п.);</p><br>

<p style="text-indent:2em;">водитель - лицо, управляющее на общественных дорогах транспортным средством, ведущее организованную группу людей, сопровождающее одиночных, вьючных, верховых
 животных или отару, стадо и т.п., а также лицо, обучающее вождению транспортного средства;</p><br>

<p style="text-indent:2em;">\"обгон\" - опережение движущегося транспортного средства, связанное с выездом из занимаемой полосы. Движение транспортных средств по одной полосе проезжей части со скоростью большей, чем 
скорость транспортных средств по соседней полосе, не считается обгоном;</p><br>

<p style="text-indent:2em;">\"главная дорога\" - дорога, обозначенная знаками 1.7.1 - 1.7.3, 2.3 и 5.2, или дорога с покрытием по отношению к дороге без покрытия;</p><br>

<p style="text-indent:2em;">общественная дорога - любой наземный путь сообщения, включая искусственные сооружения и грунтовые дороги, открытый для движения транспортных средств и пешеходов, находящийся в ведении уполномоченного органа а также подъездные дороги к прилегающим территориям, которые не находятся
 в непосредственной близости к дороге. Наряду с проезжими частями элементами дороги являются разделительные полосы, тротуары, обочины, кюветы и обрезы;</p><br>
 
<p style="text-indent:2em;">мобильная группа Пограничной полиции – оперативное образование, состоящее из пограничных полицейских, осуществляющих свою деятельность, согласно установленным полномочиям,
 с использованием мобильных автосредств Пограничной полиции , которые идентифицируются отличительными знаками учреждения</p><br>

<p style="text-indent:2em;">мобильная группа Таможенной службы – специальное оперативное подразделение Таможенной службы, созданное в целях обеспечения соблюдения таможенного законодательства;</p><br>

<p style="text-indent:2em;">магистральные дороги - дороги, по которым осуществляются основные международные автотранспортные перевозки;</p><br>

<p style="text-indent:2em;">республиканские дороги - дороги, которые соединяют столицу с муниципиями, городами, промышленными центрами и другими объектами в пределах страны</p><br>

<p style="text-indent:2em;">\"перекресток\" - место пересечения, примыкания или разветвления дорог на одном уровне, границами которого являются воображаемые линии, соединяющие начала закруглений проезжей части каждой дороги. Не считается перекрестком место выезда с прилегающей территории;</p><br>

<p style="text-indent:2em;">\"населенный пункт\" - село, коммуна, город и муниципий, въезды в которые и выезды из которых обозначены дорожными знаками 5.63.1 – 5.66;</p><br>

<p style="text-indent:2em;">разрешенная максимальная масса – общая масса снаряженного транспортного средства с грузом, водителем и пассажирами, установленная предприятием-изготовителем в качестве максимально допустимой. Разрешенная максимальная масса состава 
транспортных средств – это сумма разрешенных максимальных масс транспортных средств, входящих в состав;</p><br>

<p style="text-indent:2em;">\"самоходная машина\" - механическое транспортное средство (включая трактор), предназначенное для выполнения строительных, сельскохозяйственных, лесохозяйственных и других работ;</p><br>

<p style="text-indent:2em;">\"мотоцикл\" - двухколесное механическое транспортное средство с боковым прицепом или без него, оборудованное двигателем с рабочим объемом цилиндров более 50 cм3. Механическое транспортное средство с тремя колесами (трицикл) или четырьмя колесами, расположенными симметрично относительно 
продольной оси (квадрицикл), у которого рабочий объем цилиндров двигателя более 50 см3 и масса в снаряженном состоянии менее 400 кг считается мотоциклом;</p><br>

<p style="text-indent:2em;">\"вынужденная остановка\" - прекращение движения транспортного средства из-за его технической неисправности, возможной опасности, связанной с перевозимым грузом, состоянием 
здоровья водителя (пассажира) или препятствием на дороге;</p><br>

<p style="text-indent:2em;">служебная остановка – прекращение движения транспортного средства на запрещающий сигнал регулировщика, светофора, у знака или для того, чтобы уступить дорогу транспортному средству (пешеходу), пользующемуся преимуществом;</p><br>

<p style="text-indent:2em;">\"остановка\" - прекращение движения транспортного средства на время, необходимое для посадки или высадки пассажиров, либо его загрузки или разгрузки, при этом водитель находится за рулем или вблизи транспортного средства и, при необходимости, может его отвести;</p><br>

<p style="text-indent:2em;">\"парковка\" - стоянка транспортного средства в специально отведенном для этого месте, обозначенном знаками 5.26, 5.48.1 – 5.48.3 и разметкой 1.1.1 d);</p><br>

<p style="text-indent:2em;">участник дорожного движения - лицо, принимающее участие в процессе движения в качестве водителя, пешехода или пассажира</p><br>

<p style="text-indent:2em;">преимущество - право на первоочередное движение в намеченном направлении или осуществление маневра по отношению к другим участникам дорожного движения</p><br>

<p style="text-indent:2em;">\"пешеход\" - лицо, находящееся на дороге вне транспортного средства и не выполняющее на ней работу. Пешеходами считаются также лица, передвигающиеся в
 инвалидных колясках без двигателя, ведущие велосипед, мопед, мотоцикл, везущие санки, тележку, детскую или инвалидную коляску;</p><br>
 
<p style="text-indent:2em;">дорожное полотно – часть дороги, включающая проезжую часть (проезжие части), разделительную полосу (разделительные полосы) и обочины;</p><br>

<p style="text-indent:2em;">\"прицеп\" - транспортное средство, предназначенное для перевозки грузов в составе с механическим транспортным средством;</p><br>

<p>\"легкий прицеп\" - прицеп, разрешенная максимальная масса которого не превышает 750 кг;</p><br>

<p>полуприцеп – прицеп, часть массы которого приходится на механическое транспортное средство, являющееся тягачом;</p><br>

<p style="text-indent:2em;">\"дорожная обстановка\" - совокупность факторов, характеризующих дорожные условия, наличие препятствий на определенном участке дороги, интенсивность и
 уровень организации дорожного движения, которые должен учитывать водитель при выборе скорости, полосы движения и приемов управления транспортным средством;</p><br>

<p style="text-indent:2em;">\"стоянка\" - прекращение движения транспортного средства по причинам, не связанным с посадкой или высадкой пассажиров, либо его загрузкой или разгрузкой
 и не ограниченное временем, необходимым для этого;</p><br>

<p style="text-indent:2em;">\"территория, прилегающая к дороге\" - территория, расположенная в непосредственной близости от дороги (дворы, места стоянки, автозаправочные станции, предприятия и т.п.);</p><br>

<p>\"темное время суток\" - промежуток времени от конца вечерних сумерек до начала рассвета;</p><br>

<p>\"железнодорожный переезд\" - пересечение дороги с железнодорожными путями на одном уровне;</p><br>

<p style="text-indent:2em;">\"пешеходный переход\" - участок проезжей части дороги или сооружение в разных уровнях, предназначенный для движения пешеходов через дорогу. Эти переходы обозначаются дорожными знаками 5.50.1 – 5.50.2, 5.51.1 – 5.51.4 и/или дорожной разметкой 1.14.1, 1.14.2. При отсутствии дорожной разметки территория пешеходного перехода определяется расстоянием между дорожными знаками. В пределах перекрестка, при отсутствии знаков и/или разметки,
 территория пешеходного перехода определяется шириной тротуаров или обочин;</p><br>

<p style="text-indent:2em;">Пешеходные переходы обозначаются дорожными знаками 5.40.1 - 5.41.4 и/или дорожной разметкой 1.14.1-1.14.3. При отсутствии дорожной разметки границы
 пешеходного перехода определяются дорожными знаками, а на перекрестке - шириной тротуаров или обочин;</p><br>

<p style="text-indent:2em;">\"тротуар\" - элемент дороги, предназначенный для движения пешеходов, который примыкает к проезжей части или отделен от нее газоном;</p><br>

<p style="text-indent:2em;">\"транспортное средство\" - самоходная или не самоходная механическая система, предназначенная для перевозки людей и грузов или оснащенная механизмами, которые могут производить определенные работы;</p><br>

<p style="text-indent:2em;">\"тихоходное транспортное средство\" - транспортное средство, максимальная скорость которого по технической характеристике не более 30 км/ч;</p><br>

<p>\"длинномерное транспортное средство\" - транспортное средство, длина которого более 12 м;</p><br>

<p style="text-indent:2em;">\"маршрутное транспортное средство\" - транспортное средство общего пользования (автобус, троллейбус, трамвай), предназначенное для перевозки
 пассажиров по общественным дорогам и движущееся по установленному маршруту с остановочными пунктами, обозначенными знаками 5.6, 5.7 ;</p><br>

<p style="text-indent:2em;">транспортное средство в международном движении - транспортное средство, временно находящееся на территории какой-либо страны, не зарегистрированное
 в этой стране и принадлежащее физическому или юридическому лицу другого государства</p><br>

<p style="text-indent:2em;">транспортное средство с приоритетным режимом движения - транспортное средство, оборудованное проблесковыми маячками синего и/или красного цвета и
 специальными звуковыми сигналами</p><br>

<p style="text-indent:2em;">\"недостаточная видимость\" - видимость дороги менее 300 м в период вечерних и утренних сумерек, в условиях тумана, снегопада, дождя, а также в случаях,
 когда обзорность ограничена геометрическими параметрами дороги, придорожными инженерными сооружениями, зелеными насаждениями или другими транспортными средствами;</p><br>

<p style="text-indent:2em;">\"жилая зона\" - часть населенного пункта, в которой действуют особые условия движения, въезды на которую и выезды с которой обозначены знаками 5.66 и 5.67.</p><br>

<p style="text-indent:2em;">регистрационный документ транспортного средства - официальный государственный документ, подтверждающий регистрацию транспортного средства и дающий
 разрешение его собственнику или временному пользователю на его эксплуатацию;</p><br>

<p style="text-indent:2em;">собственник транспортного средства - физическое или юридическое лицо, которому принадлежит право владения, пользования и распоряжения транспортным средством</p>

<p></p><h4>РАЗДЕЛ II</h4><p></p>

<p></p><h4>ВОДИТЕЛИ ТРАНСПОРТНЫХ СРЕДСТВ И ИХ ОБЯЗАННОСТИ</h4><p></p>

<p style="text-indent:2em;"></p><h6>8. Минимальный возраст водителя:</h6><p></p>

<p style="text-indent:2em;">(1) 14 полных лет - для управления на общественных дорогах велосипедом, гужевой повозкой, а также одиночными животными или стадом; (2) 16 полных лет
 - для управления велосипедом с двигателем, мопедом, мотоциклом;</p><br>

<p style="text-indent:2em;">(3) 18 полных лет - для управления механическими транспортными средствами, перевозящими не более 8 человек, кроме водителя, а также транспортными средствами,
 разрешенная максимальная масса которых превышает 3,5 т;</p><br>

<p style="text-indent:2em;">(4) 21 год - для управления механическими транспортными средствами, перевозящими более 8 человек, кроме водителя, такси, автопоездами, буксирующими прицепы,
 иные, чем легкие.</p><br>

<p style="font-weight:500;">9. Движение транспортных средств по общественным дорогам разрешается только после их регистрации в установленном порядке.</p>

<p style="text-indent:2em;"></p><h6>10. (1) Лицо, управляющее механическим транспортным средством, должно:</h6><p></p>

<p style="text-indent:2em;">а) быть годным по состоянию здоровья в соответствии с требованиями, установленными Министерством здравоохранения;</p><br>

<p style="text-indent:2em;">b) знать и выполнять требования настоящих правил, а также иметь необходимые навыки уверенного и безопасного управления транспортным средством;</p><br>

<p>с) уметь оказывать первую медицинскую помощь пострадавшим в дорожно-транспортных происшествиях.</p>

<p style="text-indent:2em;"></p><h6>(2) Лицо, управляющее механическим транспортным средством, обязано иметь при себе и по требованию работника полиции передавать для контроля:</h6><p></p>

<p style="text-indent:2em;">а) водительское удостоверение, соответствующее категории, к которой относится управляемое им транспортное средство;</p><br>

<p style="text-indent:2em;">b) регистрационный документ на транспортное средство, выданный на его имя, в установленном порядке регистрационные документы на транспортное средство;</p><br>

<p>с) полис обязательного страхования гражданской ответственности владельцев автотранспортных средств</p><br>

<p>d) товаротранспортные документы на перевозимый груз.</p>

<p style="text-indent:2em;"></p><h6>11. Водитель транспортного средства перед выездом и во время движения обязан:</h6><p></p>

<p style="text-indent:2em;">(1) Проверять техническое состояние транспортного средства, в особенности рулевого управления, тормозной системы, приборов освещения и сигнализации.</p><br>

<p style="text-indent:2em;">(2) Пристегнуть ремень безопасности и убедиться, что пассажиры также пристегнули ремни, если на транспортном средстве они предусмотрены.</p>

<p style="text-indent:2em;"></p><h6>Могут не пристегивать ремни безопасности:</h6><p></p>

<p>а) водители, выполняющие маневр движения задним ходом;</p><br>

<p>b) инструкторы по вождению во время обучения курсантов;</p><br>

<p>с) беременные женщины;</p><br>

<p style="text-indent:2em;">d) в населенных пунктах - водители транспортных средств с приоритетным режимом движения при выполнении оперативного задания.</p><br>

<p style="text-indent:2em;">(3) При управлении мотоциклом быть в застегнутом мотошлеме, а также убедиться, что пассажиры застегнули мотошлемы.</p><br>

<p style="text-indent:2em;">(4) Проявлять повышенное внимание при появлении транспортных средств, у которых включен проблесковый маячок желтого цвета или на которых установлены
 опознавательные знаки 5, 7, 8, 10, 12, 13 или 15 (приложение N 6).</p><br>

<p style="text-indent:2em;">(5) Предоставлять преимущество пешеходам на нерегулируемых пешеходных переходах, обозначенных дорожными знаками и/или соответствующей дорожной разметкой.</p><br>

<p style="text-indent:2em;">(6) Управлять транспортным средством таким образом, чтобы не забрызгивать пешеходов, здания, другие транспортные средства, когда на дороге есть вода или грязь.</p><br>

<p style="text-indent:2em;">(7) Обеспечивать хорошую видимость и читаемость регистрационных номерных знаков на расстоянии не менее 20 м, приборов освещения и сигнализации, световозвращателей,
 а также содержать в чистоте стекла транспортного средства.</p>

<p style="text-indent:2em;"></p><h6>(8) Незамедлительно останавливать транспортное средство на сигнал:</h6><p></p>

<p>а) регулировщика;</p><br>

<p>b) работника полиции;</p><br>

<p style="text-indent:2em;">с) офицеры мобильных групп Таможенной службы Республики Молдова в случаях, установленных действующими нормативными актами;</p><br>

<p>d) слепого пешехода, переходящего дорогу (сигнал - поднятая белая трость).</p>

<p style="text-indent:2em;"></p><h6>Примечание. После остановки на сигнал работника полиции или офицеру мобильных групп Таможенной службы Республики Молдова в случаях, установленных действующими
 нормативными актами водитель должен оставаться в транспортном средстве, держа руки на руле до приближения полицейского или офицеру мобильных групп Таможенной
 службы Республики Молдова в случаях, установленных действующими нормативными актами. В темное время суток водитель обязан включить освещение в салоне (кабине)
 транспортного средства.</h6><p></p>

<p style="text-indent:2em;"></p><h6>(8) Незамедлительно останавливать транспортное средство на сигнал:</h6><p></p>

<p>а) регулировщика;</p><br>

<p>b) работника полиции;</p><br>

<p>с) слепого пешехода, переходящего дорогу (сигнал - поднятая белая трость).</p>

<p style="text-indent:2em;"></p><h6>Примечание. После остановки на сигнал работника полиции водитель должен оставаться в транспортном средстве, держа руки на руле до приближения полицейского. В темное
 время суток водитель обязан включить освещение в салоне (кабине) транспортного средства</h6><p></p>

<p style="text-indent:2em;">(9) Предоставлять транспортное средство работникам полиции по их требованию, независимо от направления движения, для транспортировки в медицинское учреждение лиц,
 нуждающихся в срочной медицинской помощи, а также для проезда к месту дорожно-транспортного происшествия или стихийного бедствия (в случае, если происшествие или 
 стихийное бедствие имело место в направлении движения транспортного средства).</p>

<p style="text-indent:2em;"></p><h6>Примечание. Работник полиции, который намерен воспользоваться транспортным средством в вышеуказанных случаях, обязан по требованию водителя предъявить служебное 
удостоверение.</h6><p></p>

<p style="text-indent:2em;">(10) При обнаружении на дороге препятствия или ситуации, которые могут нарушить ритм и безопасность движения, обозначить данное место в соответствии с примечанием к п.
 37 настоящих правил и сообщить об этом в ближайшее подразделение дорожной службы или полиции.</p><br>

<p style="font-weight:500;">12(1) Водитель транспортного средства, причастный к дорожно-транспортному происшествию, обязан:</p><br>

<p style="text-indent:2em;">а) без промедления остановить транспортное средство и обозначить его в соответствии с абзацем а) пункта 37 настоящих правил или примечанием к этому пункту;</p><br>

<p style="text-indent:2em;">b) не менять положения транспортного средства и предметов, оказавшихся на дороге и имеющих отношение к происшествию, за исключением случаев, предусмотренных абзацами
 с) и е) настоящего пункта;</p><br>

<p style="text-indent:2em;">c) если есть пострадавшие, оказать им первую медицинскую помощь, вызвать скорую помощь, а если это невозможно либо в неотложном случае отправить пострадавших в ближайшее
 медицинское учреждение на попутном или отвезти на собственном транспортном средстве;</p><br>

<p style="text-indent:2em;">d) предъявить в медицинском учреждении водительское удостоверение или другой документ, удостоверяющий личность, а также регистрационные документы на транспортное средство,
 после чего возвратиться на место происшествия;</p><br>

<p style="text-indent:2em;">е) освободить проезжую часть, если в результате дорожно-транспортного происшествия движение других транспортных средств затруднено или невозможно и нет погибших и/или раненых,
 переместив данное транспортное средство на обочину или как можно ближе к бордюру тротуара;</p><br>

<p style="text-indent:2em;">f) обозначить первоначальное положение транспортного средства, предметов, имеющих отношение к происшествию, и пострадавших, если возникла необходимость их перемещения для оказания
 первой медицинской помощи, доставки пострадавших в медицинское учреждение собственным транспортом или для освобождения проезжей части;</p><br>

<p>g) обеспечить, насколько это возможно, сохранение следов, а также объезд места происшествия;</p><br>

<p style="text-indent:2em;">h) сообщить о происшествии в полицию, записать фамилии и адреса очевидцев и оставаться на месте до прибытия работников полиции;</p><br>

<p style="text-indent:2em;">i) не употреблять до прохождения медицинского освидетельствования алкогольных напитков или наркотиков, а также лекарственных препаратов, изготовленных на их основе.</p>

<p style="text-indent:2em;"></p><h6>(2) Водитель транспортного средства, подъехавший к месту дорожно-транспортного происшествия, обязан остановиться и в случае необходимости:</h6><p></p>

<p style="text-indent:2em;">а) оказать пострадавшим первую медицинскую помощь, вызвать скорую помощь, а если это невозможно или в неотложном случае отвезти их на собственном транспортном средстве в
 ближайшее медицинское учреждение;</p><br>

<p style="text-indent:2em;">b) предъявить в медицинском учреждении водительское удостоверение или другой документ, удостоверяющий личность, а также регистрационные документы на транспортное средство;</p><br>

<p>с) сообщить о происшествии в полицию.</p><br>

<p style="text-indent:2em;">(3) Если к дорожно-транспортному происшествию причастно транспортное средство с приоритетным режимом движения при выполнении неотложного служебного задания, его водитель должен
 выполнить требования абзаца g) подпункта (1) и требования подпункта (2) настоящего пункта, а после выполнения задания возвратиться на место происшествия или явиться в 
 подразделение полиции.</p>

<p style="text-indent:2em;"></p><h6>12. (1) Водители транспортных средств, причастные к дорожно- транспортному происшествию, обязаны:</h6><p></p>

<p style="text-indent:2em;">а) немедленно остановиться и, если пострадавшие не нуждаются в срочной медицинской помощи, оставаться на месте происшествия до прибытия работников полиции;</p><br>

<p style="text-indent:2em;">b) не менять положения транспортного средства или предметов, оказавшихся на дороге и имеющих отношение к дорожно-транспортному происшествию, обеспечить сохранение следов
 и обозначить положение транспортного средства на дороге в соответствии с настоящими правилами, а также записать фамилии и адреса очевидцев. Если для оказания помощи 
 пострадавшему или во избежание дополнительной опасности необходимо изменить положение транспортного средства и пострадавшего, следует предварительно обозначить их 
 первоначальную позицию;</p><br>

<p style="text-indent:2em;">с) оказать первую помощь пострадавшим, кроме случая, когда он сам травмирован и не в состоянии оказать медицинскую помощь;</p><br>

<p style="text-indent:2em;">d) доставить пострадавшего в ближайшее медицинское учреждение, если это не может сделать водитель другого транспортного средства, предварительно обозначив любым способом
 место происшествия и обеспечив, насколько возможно, сохранение следов, после чего возвратиться к месту происшествия или явиться в подразделение полиции. В медицинском 
 учреждении водитель, доставивший пострадавшего, должен представить сведения о себе и данные о транспортном средстве;</p><br>

<p>е) любым способом незамедлительно оповестить ближайшее подразделение полиции;</p><br>

<p style="text-indent:2em;">f) не употреблять алкогольных напитков или наркотиков до взятия биологических проб или прохождения медицинского освидетельствования.</p><br>

<p style="text-indent:2em;">(2) Водитель транспортного средства, подъезжающий к месту дорожно-транспортного происшествия, в результате которого есть погибшие или раненые, должен остановиться и в случае
 необходимости действовать в соответствии с требованиями абзацев с), d) и е) подпункта (1) настоящего пункта.</p><br>

<p style="text-indent:2em;">(3) Если к дорожно-транспортному происшествию причастно транспортное средство с приоритетным режимом движения при выполнении неотложного служебного задания, его водитель должен
 обеспечить сохранение следов происшествия, выполнить требования абзацев с), d) и е) подпункта (1) настоящего пункта и после выполнения задания возвратиться к месту происшествия
 или явиться в подразделение полиции.</p><br>

<p style="font-weight:500;">13. Участники дорожного движения, причастные к дорожно-транспортному происшествию, в результате которого причинен только незначительный материальный ущерб, при взаимном согласии
 в оценке обстоятельств происшествия и если техническое состояние транспортных средств обеспечивает безопасность движения, должны прибыть на ближайший пост дорожной полиции для
 оформления необходимых документов, имея при себе предварительно составленную схему дорожно-транспортного происшествия, которая должна быть подписана участниками происшествия и
 очевидцами, если они есть .</p>

<p style="text-indent:2em;"></p><h6>Примечание. В случае разногласия и необходимости освободить проезжую часть участники происшествия обязаны действовать в соответствии с абзацем b) пункта 12.</h6><p></p>

<p style="text-indent:2em;"></p><h6>14. Водителю транспортного средства запрещается:</h6><p></p>

<p style="text-indent:2em;">а) управлять транспортным средством в состоянии алкогольного, наркотического или иного опьянения, под воздействием лекарственных препаратов, снижающих реакцию, если он болен
, травмирован или находится в утомленном состоянии, ставящем под угрозу безопасность движения;</p><br>

<p style="text-indent:2em;">b) передавать управление транспортным средством лицу, не имеющему водительского удостоверения соответствующей категории, находящемуся в состоянии алкогольного, наркотического
 или иного опьянения, под воздействием лекарственных препаратов, снижающих реакцию, больному, травмированному, а также находящемуся в утомленном состоянии, ставящем под угрозу
 безопасность движения;</p><br>

<p>с) трогаться с места с незакрытыми дверями или открывать их во время движения.</p><br>

<p style="text-indent:2em;">d) участвовать в разговоре, если это может отвлечь от управления транспортным средством, а также вести переговоры по радиотелефону во время движения, если транспортное средство
 не оборудовано микрофоном и динамиком, за исключением водителей транспортных средств с приоритетным режимом движения;</p><br>

<p style="text-indent:2em;">e) выезжать на дорогу с усовершенствованным покрытием, если на колесах или в кузове транспортного средства имеется грязь, строительные материалы и т.п., которые могут загрязнить
 проезжую часть, или если из транспортного средства происходит утечка топливно-смазочных материалов.</p>

<p></p><h4>РАЗДЕЛ III ОРГАНИЗАЦИЯ И РЕГУЛИРОВАНИЕ ДОРОЖНОГО ДВИЖЕНИЯ</h4><p></p>

<p></p><h4>Глава I ОБЩИЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">15. Организация и регулирование дорожного движения осуществляются посредством дорожных знаков, дорожной разметки, светофоров, а также регулировщиками.</p><br>

<p style="text-indent:2em;">Средства регулирования дорожного движения, указанные в предыдущем абзаце настоящего пункта, представлены в приложениях, которые являются неотъемлемой частью настоящих правил.</p><br>

<p style="font-weight:500;">16. (1) Средства регулирования дорожного движения должны взаимно дополнять друг друга и соответствовать настоящим правилам.</p>

<p style="text-indent:2em;"></p><h6>В случае несоответствия значений средств регулирования дорожного движения между собой, преимущество имеют:</h6><p></p>

<p>а) дорожный знак перед дорожной разметкой;</p><br>

<p>b) сигналы светофора перед знаками приоритета;</p><br>

<p style="text-indent:2em;">c) сигналы или указания регулировщика перед сигналами светофара, знаками приоритета, а также перед настоящими правилами.</p><br>

<p style="text-indent:2em;">(2) Запрещается монтаж, демонтаж, загораживание, повреждение, изменение положения, а также установка или нанесение средств регулирования дорожного движения по собственной
 инициативе лицами, не имеющими на это разрешения органов дорожной полиции.</p><br>

<p style="text-indent:2em;">(3) Запрещается без согласования с органами дорожной полиции размещать афиши, стенды или устройства, которые могут быть восприняты в качестве дорожных знаков или других
 средств регулирования дорожного движения, ограничивают их видимость, создают препятствия для участников дорожного движения, отвлекают их внимание, тем самым представляя
 опасность для движения.</p>

<p></p><h4>Глава II СИГНАЛЫ РЕГУЛИРОВЩИКА</h4><p></p>

<p style="text-indent:2em;"></p><h6>(приложение N 1)</h6><p></p>

<p style="text-indent:2em;"></p><h6>17. Сигналы регулировщика имеют следующие значения:</h6><p></p>

<p style="text-indent:2em;">(1) Положение, при котором регулировщик обращен к участникам дорожного движения грудью или спиной, рука или руки вытянуты в стороны, запрещает движение; после подачи этого
 сигнала он может опустить руку или руки, это положение также запрещает движение участникам дорожного движения, которые приближаются со стороны груди или спины.</p><br>

<p style="text-indent:2em;">Участникам дорожного движения, к которым регулировщик обращен правым или левым боком, движение разрешается во всех направлениях в соответствии с правилами проезда перекрестков.</p><br>

<p style="text-indent:2em;">(2) Положение, при котором регулировщик поднял руку вверх, означает \"внимание, движение запрещено\" для всех приближающихся участников дорожного движения, за исключением
 водителей транспортных средств, которые не могут остановиться, не прибегая к экстренному торможению; при этом водители, въехавшие на перекресток, а также пешеходы 
 находящиеся на проезжей части могут продолжить движение.</p><br>

<p style="text-indent:2em;">(3) Сигнал, выражающий требование об остановке транспортного средства вне перекрестка, подается жезлом, жестом руки или с помощью громкоговорящего устройства. Водитель должен
 остановиться в месте, указанном регулировщиком</p>

<p style="text-indent:2em;"></p><h6>Примечания: 1. Для привлечения внимания участников дорожного движения при подаче сигналов, указанных в настоящем пункте, регулировщик может использовать свисток</h6><p></p>

<p style="text-indent:2em;">2. Регулировщики должны быть экипированы и расположены таким образом, чтобы их сигналы были видны днем, в темное время суток, а также в условиях недостаточной видимости 
всем участникам дорожного движения.</p><br>

<p style="font-weight:500;">18(1) Сигнал, выражающий требование уступить дорогу либо освободить проезжую часть для проезда транспортного средства дорожной полиции и сопровождаемой им колонны, а
 также сигнал об остановке другим транспортным средствам регулировщик подает балансированием в вертикальной плоскости жезлом или горизонтально вытянутой рукой из окна
 автомобиля. При этом могут использоваться специальные звуковые (сирена, громкоговорящее устройство) и световые (проблесковый маячок или мигающая надпись "STOP, POLITIA")
 сигналы, установленные на автомобиле.</p><br>

<p style="text-indent:2em;">(2) Если регулировщик выполняет неотложное служебное задание, находясь в попутном транспортном средстве, он подает сигнал, выражающий требование об остановке, другим
 транспортным средствам балансированием в вертикальной плоскости жезлом или горизонтально вытянутой рукой из окна автомобиля либо с помощью громкоговорящего устройства.</p>

<p style="text-indent:2em;"></p><h6>Примечание. Регулировщик может подавать и другие сигналы, не вводящие ограничений и понятные всем участникам дорожного движения, к которым они обращены</h6><p></p>

<p style="font-weight:500;">18. (1) Водители транспортных средств обязаны немедленно остановиться на обочине, а при ее отсутствии - как можно ближе к правому краю проезжей части, если регулировщик
 балансирует в вертикальной плоскости жезлом из сопровождающего колонну автомобиля с включенными специальными световыми и звуковыми сигналами. Возобновлять движение
 разрешается только после проезда колонны.</p><br>

<p style="text-indent:2em;">(2) Если сигнал остановки адресован конкретному водителю, он должен остановиться на обочине, а при ее отсутствии - как можно ближе к правому краю проезжей части. Водитель
 обязан также остановиться и при включении светового мигающего сигнала с надписью \"Stop, politia\", установленного на полицейском автомобиле, или если сигнал остановки
 подан жезлом либо горизонтально вытянутой рукой.</p>

<p></p><h4>Глава III СИГНАЛЫ СВЕТОФОРОВ</h4><p></p>

<p style="text-indent:2em;"></p><h6>(приложение N 2)</h6><p></p>

<p style="font-weight:500;">19. (1) Для регулирования движения транспортных средств применяются световые сигналы красного, желтого и зеленого цветов. Сигналы подаются в следующей последовательности:
 красный, красный и желтый, зеленый, желтый или красный, зеленый, желтый.</p><br>

<p style="text-indent:2em;">(2) Сигналы светофора для регулирования движения транспортных средств действуют на всю ширину проезжей части данного направления.</p><br>

<p style="text-indent:2em;">(3) На дорогах с двумя или более полосами для движения в данном направлении, обозначенных продольной разметкой, светофоры могут устанавливаться над каждой из полос. В
 этом случае водители транспортных средств должны руководствоваться сигналами светофора, соответствующего той полосе, по которой они движутся.</p>

<p style="text-indent:2em;"></p><h6>20. Сигналы светофоров для регулирования движения транспортных средств имеют следующие значения:</h6><p></p>

<p>(1) Красный сигнал запрещает движение.</p>

<p style="text-indent:2em;"></p><h6>Примечание. При включении красного сигнала транспортные средства должны остановиться перед стоп-линией (знаком 5.61) или перед обозначенным пешеходным переходом, а при
 их отсутствии - непосредственно перед светофором. Однако, если светофор установлен над перекрестком или на его противоположной стороне, при отсутствии стоп-линии
 (знака 5.61) или обозначенного пешеходного перехода транспортные средства должны остановиться перед перекрестком, не создавая помех пешеходам.</h6><p></p>

<p style="text-indent:2em;">(2) Красный сигнал, включенный одновременно с желтым, не меняет его запрещающего значения и означает, что время, отведенное красному сигналу, истекает.</p>

<p style="text-indent:2em;"></p><h6>Примечание. Контурные стрелки черного цвета на фоне красного и желтого сигналов информируют о разрешенных направлениях движения при включении зеленого сигнала.</h6><p></p>

<p>(3) Зеленый сигнал разрешает движение.</p>

<p style="text-indent:2em;"></p><h6>Примечания:</h6><p></p>

<p style="text-indent:2em;">1. Водители транспортных средств не должны въезжать на перекресток при зеленом сигнале светофора, если дорожная обстановка в направлении движения не
 позволяет им освободить перекресток до смены сигнала.</p><br>

<p style="text-indent:2em;">2. Контурная стрелка (контурные стрелки) черного цвета на фоне зеленого сигнала разрешает (разрешают) движение только в указанном направлении (указанных направлениях).
 Такое же значение имеет зеленый сигнал в виде стрелки на черном фоне в дополнительной секции светофора или стрелка (стрелки) на табличках 6.22.1 - 6.22.5. Стрелка,
 разрешающая поворот налево, разрешает и разворот</p><br>

<p style="text-indent:2em;">3. На участках дорог, где движение организовано по системе \"зеленая волна\", водители транспортных средств могут двигаться со скоростью, рекомендуемой знаком 5.42.</p><br>

<p style="text-indent:2em;">4. Зеленый мигающий сигнал разрешает движение и одновременно информирует, что время его действия истекает и вскоре будет включен желтый сигнал.</p><br>

<p style="text-indent:2em;">(4) Желтый сигнал предупреждает, что приближающиеся транспортные средства должны остановиться в местах, указанных в примечании к подпункту (1) настоящего пункта, за
 исключением транспортных средств, которые при включении сигнала находятся в непосредственной близости от этих мест и не могут остановиться, не прибегая к экстренному
 торможению.</p>

<p style="text-indent:2em;"></p><h6>Примечание. Желтый мигающий сигнал разрешает движение и предупреждает, что перекресток или пешеходный переход является нерегулируемым и представляет опасность,
 что требует от водителей особой осторожности.</h6><p></p>

<p style="font-weight:500;">21. На перекрестках регулирование движения маршрутных транспортных средств может осуществляться независимо от регулирования движения других транспортных средств
 специальными светофорами с четырьмя сигналами белого цвета, расположенными в виде буквы \"Т\".</p><br>

<p style="text-indent:2em;">Движение разрешается только при включении нижнего сигнала одновременно с одним или несколькими верхними, при этом левый разрешает поворот налево, средний - движение
 прямо, правый - поворот направо. При включении только верхних сигналов движение запрещено.</p><br>

<p style="font-weight:500;">22. Для регулирования движения транспортных средств по полосам проезжей части, обозначенным продольной разметкой 1.9, движение по которым может быть временно изменено
 на противоположное, применяются светофоры со следующими сигналами: красный в виде двух скрещенных полос; желтый в виде стрелы, направленной по диагонали вправо; зеленый
 в виде стрелы, направленной вниз. Красный сигнал запрещает движение; желтый - информирует о предстоящей смене зеленого сигнала на красный и обязывает водителей перестроиться
 в указанном направлении, зеленый - разрешает движение.</p><br>

<p style="font-weight:500;">23. (1) Светофоры для регулирования движения пешеходов имеют два световых сигнала - красного и зеленого цветов, а их включение скоординировано с сигналами светофоров,
 предназначенных для регулирования движения транспортных средств.</p><br>

<p style="text-indent:2em;">Зеленый сигнал выполнен в виде силуэта идущего пешехода, красный - в виде стоящего. Эти светофоры должны быть установлены таким образом, чтобы их сигналы не могли
 восприниматься как сигналы, регулирующие движение транспортных средств.</p><br>

<p>(2) Зеленый сигнал разрешает переход.</p><br>

<p>Зеленый мигающий сигнал означает, что время, отведенное для перехода дороги, истекает.</p><br>

<p>(3) Красный сигнал запрещает переход.</p><br>

<p style="text-indent:2em;">Пешеходы, которых красный сигнал застал на пешеходном переходе, должны закончить переход, однако, если на проезжей части имеется островок безопасности,
 они должны остановиться на нем и дождаться включения зеленого сигнала.</p>

<p></p><h4>Глава IV СИГНАЛИЗАЦИЯ НА ЖЕЛЕЗНОДОРОЖНЫХ ПЕРЕЕЗДАХ</h4><p></p>

<p style="font-weight:500;">24. (1) Для регулирования движения на железнодорожных переездах применяются светофоры с двумя попеременно мигающими красными сигналами, установленными
 на одном уровне, и расположенным над ними белым сигналом.</p><br>

<p style="text-indent:2em;">Попеременно мигающие световые сигналы красного цвета запрещают движение через переезд. Эти сигналы сопровождаются звуковыми сигналами.</p><br>

<p>Белый мигающий сигнал разрешает движение.</p><br>

<p>(2) При закрытых или начинающих закрываться шлагбаумах движение через переезд запрещается.</p><br>

<p>Шлагбаумы окрашиваются чередующимися красными и белыми световозвращающими полосами.</p><br>

<p style="text-indent:2em;">На переездах без искусственного освещения шлагбаумы должны освещаться или на них должны быть установлены световозвращающие элементы или красные мигающие фонари.</p><br>

<p>Перед переездами через электрифицированную железную дорогу могут быть установлены габаритные ворота.</p><br>

<p style="text-indent:2em;">(3) О приближении к железнодорожному переезду предупреждают дорожные знаки 1.1 или 1.2, а непосредственно перед переездом без шлагбаума
 устанавливаются знаки 1.3.1 или 1.3.2.</p><br>

<p style="text-indent:2em;">Вне населенных пунктов перед железнодорожными переездами по правой стороне дороги последовательно устанавливаются знаки 1.4.1 - 1.4.3, а по левой 
стороне - знаки 1.4.4 - 1.4.6. Знаки 1.4.1, 1.4.3, 1.4.4 и 1.4.6 устанавливаются совместно со знаком 1.1 или 1.2.</p><br>

<p style="text-indent:2em;">Если при приближении к железнодорожному переезду без шлагбаума видимость ограничена, непосредственно перед ним устанавливается знак приоритета 2.2.
 На этих переездах регулирование движения транспортных средств может осуществляться дежурным по переезду.</p>

<p></p><h4>Глава V ДОРОЖНЫЕ ЗНАКИ</h4><p></p>

<p style="text-indent:2em;"></p><h6>(приложение N 3)</h6><p></p>

<p style="text-indent:2em;"></p><h6>25. Дорожные знаки, устанавливаемые на общественных дорогах, делятся на:</h6><p></p>

<p>а) предупреждающие;</p><br>

<p>b) приоритета;</p><br>

<p>с) запрещающие или ограничивающие;</p><br>

<p>d) предписывающие;</p><br>

<p>е) информационно-указательные;</p><br>

<p>f) дополнительные таблички.</p><br>

<p style="font-weight:500;">26. (1) Предупреждающие знаки информируют участников дорожного движения о приближении к опасному участку дороги и характере опасности. Движение 
по этому участку требует принятия мер, соответствующих дорожной обстановке</p><br>

<p style="text-indent:2em;">(2) Знаки приоритета устанавливают очередность проезда перекрестков, а также узких участков дорог, на которых одновременный встречный разъезд
 транспортных средств невозможен.</p><br>

<p style="text-indent:2em;">(3) Запрещающие или ограничивающие знаки вводят или отменяют определенные запреты или ограничения для участников дорожного движения.</p><br>

<p style="text-indent:2em;">(4) Предписывающие знаки обязывают двигаться только в указанных направлениях, разрешают движение на отдельных участках дорог только определенным
 участникам, а также предписывают двигаться с указанной на знаке или большей скоростью.</p><br>

<p style="text-indent:2em;">(5) Информационно-указательные знаки вводят или отменяют определенные режимы движения, информируют о расположении населенных пунктов и других объектов,
 а также ориентируют водителей о направлениях движения на перекрестках.</p><br>

<p>(6) Дополнительные таблички уточняют или ограничивают действие знаков, с которыми они применяются.</p><br>

<p style="font-weight:500;">27. Дорожные знаки должны устанавливаться в соответствии с действующими нормативами и техническими условиями таким образом, чтобы их видимость была
 обеспечена всем участникам дорожного движения.</p>

<p></p><h4>Глава VI ДОРОЖНАЯ РАЗМЕТКА</h4><p></p>

<p style="text-indent:2em;"></p><h6>(приложение N 4)</h6><p></p>

<p style="font-weight:500;">28. Дорожная разметка предназначена для организации и регулирования дорожного движения и применяется самостоятельно или в сочетании с другими средствами
 дорожной сигнализации, которые она дополняет, уточняет или дублирует.</p><br>

<p style="font-weight:500;">29. Дорожная разметка подразделяется на горизонтальную и вертикальную.</p><br>

<p style="font-weight:500;">30.(1) Горизонтальная разметка устанавливает определенный режим и порядок движения, наносится на проезжую часть или бордюры и имеет белый или желтый цвет.</p><br>

<p style="text-indent:2em;">(2) Вертикальная разметка в виде сочетания черных и белых полос наносится на элементы дорожных сооружений (мосты, пешеходные переходы в разных уровнях,
 опоры путепроводов, столбы, парапеты и другие объекты, находящиеся в пределах дорожного полотна и представляющие опасность для движения), служит для
 предупреждения о возможности наезда на эти сооружения и способствуют зрительному ориентированию водителей.</p>

<p></p><h4>Глава VII СРЕДСТВА ОБОЗНАЧЕНИЯ ДОРОЖНЫХ РАБОТ</h4><p></p>

<p style="text-indent:2em;"></p><h6>(приложение N 5)</h6><p></p>

<p style="font-weight:500;">31. Средства обозначения дорожных работ устанавливаются в соответствии с действующими нормативными и техническими условиями и должны обеспечивать
 безопасность движения на ремонтируемом участке дороги или на объезде этого участка. Дорожные работы могут начинаться только после согласования с 
 дорожной полицией, органами дорожной администрации и установки в зоне их проведения средств обозначения, соответствующих характеру и продолжительности
 этих работ.</p><br>

<p style="text-indent:2em;">Границы участков, на которых производятся работы, должны быть обозначены дорожными знаками, щитами, шлагбаумами, направляющими столбиками,
 конусами и другими специальными средствами.</p><br>

<p style="text-indent:2em;">При необходимости регулирование движения в зоне работ могут осуществлять работники дорожно-эксплуатационной службы. Эти лица должны иметь красную
 нарукавную повязку и жезл.</p><br>

<p style="text-indent:2em;">Если средства обозначения дорожных работ не имеют световозвращающих устройств, в темное время суток или в условиях недостаточной видимости совместно
 с ними должны устанавливаться фонари с постоянными или мигающими огнями красного или оранжевого цвета.</p><br>

<p style="text-indent:2em;">На весь период производства работ средства их обозначения сохраняются. После завершения работ восстанавливаются первоначальные средства регулирования
 или вводятся изменения, соответствующие новым условиям.</p><br>

<p style="text-indent:2em;">Лица, выполняющие дорожные работы, должны быть одеты в жилеты оранжевого цвета. Жилет должен быть световозвращающим или с оранжевыми световозвращающими
 элементами.</p><br>

<p style="text-indent:2em;">Прекращение движения или введение ограничений на период производства дорожных работ, а также порядок обозначения зон проведения работ осуществляются по
 согласованию с органами дорожной полиции</p>

<p></p><h4>Глава VIII СИГНАЛЫ ВОДИТЕЛЕЙ ТРАНСПОРТНЫХ СРЕДСТВ И ПОЛЬЗОВАНИЕ ВНЕШНИМИ СВЕТОВЫМИ ПРИБОРАМИ</h4><p></p>

<p style="font-weight:500;">32.(1) Для предотвращения опасности, а также во избежание создания помех для движения водители транспортных средств должны подавать предупредительные сигналы
 световыми указателями поворота или рукой, звуковым сигналом, переключением света фар, включением ближнего света фар в дневное время, включением аварийной сигнализации.</p><br>

<p style="text-indent:2em;">Подача предупредительного сигнала вышеуказанными средствами не дает права преимущественного проезда и не освобождает водителей транспортных средств от принятия
 ими всех необходимых мер, направленных на предотвращение дорожно-транспортного происшествия.</p>

<p style="text-indent:2em;"></p><h6>(2) Водитель транспортного средства должен подавать сигналы световыми указателями поворота соответствующего направления, а если они отсутствуют или неисправны
 - рукой, перед:</h6><p></p>

<p>а) началом движения;</p><br>

<p>b) маневрированием (перестроением, поворотом или разворотом);</p><br>

<p>с) остановкой.</p><br>

<p style="text-indent:2em;">(3) Подача сигнала указателями поворота или рукой должна производиться заблаговременно до начала выполнения маневра и прекращаться немедленно после его
 завершения. Для облегчения маневра подача сигнала рукой может быть прекращена непосредственно перед выполнением маневра.</p>

<p style="text-indent:2em;"></p><h6>Примечания: 1. Левая рука, вытянутая в сторону, либо правая рука, вытянутая в сторону и согнутая в локте под прямым углом вверх, означает, что водитель этого
 транспортного средства намерен повернуть налево или развернуться в обратном направлении.</h6><p></p>

<p style="text-indent:2em;">2. Правая рука, вытянутая в сторону, либо левая рука, вытянутая в сторону и согнутая в локте под прямым углом вверх, означает, что водитель этого транспортного
 средства намерен повернуть направо.</p><br>

<p>3. Поднятая вверх левая либо правая рука означает, что водитель намерен снизить скорость или остановиться.</p><br>

<p style="text-indent:2em;">(4) Предупредительный сигнал перед выполнением любого маневра подается только после того как водитель убедится, что этим маневром не будет создана помеха 
другим участникам дорожного движения.</p><br>

<p>(5) Сигнал не должен подаваться, если он может ввести в заблуждение других участников дорожного движения.</p><br>

<p style="font-weight:500;">33.(1) Звуковой сигнал может применяться, только если это необходимо для предотвращения дорожно-транспортного происшествия, а кроме того - вне населенных
 пунктов для привлечения внимания водителя обгоняемого транспортного средства.</p><br>

<p style="text-indent:2em;">(2) Продолжительность звукового сигнала должна быть кратковременной. Сигнал должен подаваться на достаточном расстоянии, чтобы не застать врасплох тех,
 кому он адресован, вынуждая их к действиям, ставящим под угрозу безопасность движения.</p>

<p style="text-indent:2em;"></p><h6>34.(1) В темное время суток, в условиях недостаточной видимости, а также в тоннелях на движущихся механических транспортных средствах и прицепах световые
 приборы должны включаться следующим образом:</h6><p></p>

<p style="text-indent:2em;">а) на механических транспортных средствах в зависимости от освещения дороги - габаритные огни, ближний или дальний свет фар;</p><br>

<p>b) на прицепах - габаритные огни.</p>

<p style="text-indent:2em;"></p><h6>(2) Дальний свет фар должен быть переключен на ближний:</h6><p></p>

<p>а) на достаточно освещенных дорогах;</p><br>

<p style="text-indent:2em;">b) во избежание ослепления при встречном разъезде на расстоянии не менее чем за 150 м либо на большем расстоянии, если водитель встречного транспортного
 средства покажет необходимость этого периодическим переключением света фар, а также при движении на малом расстоянии до движущегося впереди
 транспортного средства.</p><br>

<p style="text-indent:2em;">Для предупреждения об обгоне при условии, что не будут ослеплены водители встречных транспортных средств, на короткое время может быть включен дальний свет фар.</p><br>

<p style="text-indent:2em;">При ослеплении водитель должен включить аварийную сигнализацию и, не меняя полосы движения, снизить скорость и остановиться.</p>

<p style="text-indent:2em;"></p><h6>(3) Для привлечения внимания других участников дорожного движения и с целью обозначения движущихся транспортных средств ближний свет фар
 в дневное время должны включать водители:</h6><p></p>

<p>а) мотоциклов и мопедов;</p><br>

<p>b) особо малых автобусов и автобусов, перевозящих группы детей;</p><br>

<p>с) при движении в организованной транспортной колонне;</p><br>

<p style="text-indent:2em;">d) буксирующих механических транспортных средств (на буксируемом механическом транспортном средстве должна быть включена аварийная сигнализация,
 а если она неисправна или отсутствует, эти транспортные средства должны быть обозначены знаком аварийной остановки, укрепленным на них сзади);</p><br>

<p>е) механических транспортных средств, перевозящих опасные, тяжеловесные и крупногабаритные грузы;</p><br>

<p style="text-indent:2em;">f) маршрутных транспортных средств, движущихся по полосе, обозначенной знаком 5.34 и/или соответствующей разметкой, навстречу основному
 потоку транспортных средств.</p><br>

<p style="text-indent:2em;">(4) При остановке в темное время суток на неосвещенных участках общественных дорог или в условиях недостаточной видимости на транспортных средствах
 должны быть включены стояночные огни. При их неисправности транспортные средства должны быть отведены за пределы дороги, а если это невозможно,
 должна быть включена аварийная сигнализация или установлен знак аварийной остановки (в соответствии с примечанием к пункту 37 настоящих правил).</p><br>

<p style="text-indent:2em;">В условиях густого тумана, обильного снегопада, проливного дождя и т.п. должен быть включен ближний свет фар или противотуманные фары.</p>

<p style="text-indent:2em;"></p><h6>(5) Противотуманные фары могут быть использованы:</h6><p></p>

<p>а) в условиях недостаточной видимости - отдельно, а также совместно с ближним или дальним светом фар;</p><br>

<p>b) в темное время суток на неосвещенных участках дорог - совместно с ближним или дальним светом фар;</p><br>

<p>с) в случаях, указанных в подпункте (3) настоящего пункта, - вместо ближнего света фар.</p><br>

<p>(6) Задние противотуманные фонари могут применяться только в условиях недостаточной видимости.</p><br>

<p>Запрещается подключать задние противотуманные фонари к стоп-сигналам.</p>

<p style="text-indent:2em;"></p><h6>(7) Фара-прожектор и фара-искатель могут быть использованы:</h6><p></p>

<p>а) вне населенных пунктов только при отсутствии встречных транспортных средств;</p><br>

<p style="text-indent:2em;">b) в населенных пунктах водителями транспортных средств с приоритетным режимом движения при выполнении неотложного служебного задания.</p><br>

<p style="font-weight:500;">35. (1) Опознавательный знак \"Автопоезд\" должен быть включен при движении автопоезда, а также на время его остановки, или стоянки в темное
 время суток, или в условиях недостаточной видимости.</p><br>

<p style="text-indent:2em;">(2) Механические транспортные средства, предназначенные для обучения вождению и принадлежащие учебным организациям, оборудуются кассетой в
 форме треугольника с белым светом и надписью \"S\" (при обучении в индивидуальном порядке спереди и сзади устанавливается опознавательный знак 10,
 приложение N 6), а такси - кассетой в форме прямоугольника с желтым светом и надписью \"TAXI\", которые устанавливаются на крыше этих транспортных
 средств и должны быть включены при движении в темное время суток или в условиях недостаточной видимости.</p><br>

<p style="font-weight:500;">36. На гужевых повозках должны быть установлены спереди световозвращатели белого цвета, по бокам - оранжевого цвета, сзади - красного цвета.</p>

<p style="text-indent:2em;"></p><h6>37. Аварийная сигнализация, кроме перечисленных ранее случаев, должна быть включена:</h6><p></p>

<p>а) при дорожно-транспортном происшествии;</p><br>

<p style="text-indent:2em;">b) при вынужденной остановке, если транспортное средство представляет опасность для других участников движения;</p><br>

<p>с) для предупреждения других участников дорожного движения о предстоящей опасности.</p>

<p style="text-indent:2em;"></p><h6>Примечание. При отсутствии или неисправности аварийной сигнализации водитель должен установить знак аварийной остановки (портативный фонарь с
 красным мигающим светом) на расстоянии не менее 30 м от транспортного средства сзади или спереди на той же полосе таким образом, чтобы он был
 замечен на достаточном расстоянии водителями приближающихся транспортных средств. В населенных пунктах при интенсивном движении знак аварийной
 остановки может быть установлен на меньшем расстоянии таким образом, чтобы он был замечен водителями приближающихся транспортных средств.</h6><p></p>

<p style="font-weight:500;">38. Запрещается установка на механических транспортных средствах и прицепах приборов освещения и звуковых сигналов, не предусмотренных конструкцией
 (за исключением случаев, указанных в примечаниях к подпункту (3) пункта 122 настоящих правил).</p>

<p style="text-indent:2em;"></p><h6>Примечание. Транспортные средства с приоритетным режимом движения (Министерства внутренних дел, Министерства обороны, Службы информации и безопасности
 Республики Молдова, Департамента пограничных войск, Службы государственной охраны предназначенные для тушения пожаров, скорой помощи, банковских служб,
 специальной почты) а также транспортные средства дорожно-эксплуатационной службы, перевозящие крупногабаритные грузы и т.п., должны быть оборудованы
 проблесковыми маячками синего, красного, зеленого или оранжевого цветов и специальными звуковыми сигналами в соответствии с действующими нормативами
 и стандартами и только по разрешению Управления дорожной полиции.</h6><p></p>

<p></p><h4>РАЗДЕЛ IV ПРАВИЛА ДВИЖЕНИЯ ТРАНСПОРТНЫХ СРЕДСТВ.</h4><p></p>

<p></p><h4>Глава I НАЧАЛО ДВИЖЕНИЯ И ИЗМЕНЕНИЕ НАПРАВЛЕНИЯ ДВИЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">39. (1) Перед началом движения, перестроением или другим изменением направления движения водитель должен убедиться, что этот маневр будет безопасным и
 не создаст препятствий другим участникам дорожного движения.</p><br>

<p style="text-indent:2em;">(2) При выезде с территории, прилегающей к дороге, водитель должен уступить дорогу пешеходам и транспортным средствам, движущимся по ней, а при съезде
 с дороги - велосипедистам, движущимся по велосипедной дорожке, и пешеходам, траекторию движения которых он пересекает.</p><br>

<p style="text-indent:2em;">(3) При перестроении водитель должен уступить дорогу транспортным средствам, движущимся попутно по полосе, на которую он намерен перестроиться. 
При одновременном перестроении транспортных средств, движущихся попутно, водитель должен уступить дорогу транспортному средству, находящемуся справа.</p><br>

<p style="text-indent:2em;">(4) В населенных пунктах водители обязаны уступить дорогу маршрутным транспортным средствам, начинающим движение от обозначенной остановки. При 
этом водители маршрутных транспортных средств могут начинать движение, предварительно убедившись, что им уступают дорогу. Они не освобождаются 
от необходимости принятия мер безопасности для избежания дорожно-транспортного происшествия.</p>

<p style="text-indent:2em;"></p><h6>40. (1) Перед поворотом налево или разворотом водитель должен:</h6><p></p>

<p style="text-indent:2em;">а) заблаговременно приблизиться к оси дороги, на проезжей части которой движение осуществляется в обоих направлениях, или к левому краю проезжей 
части дороги с односторонним движением, кроме случаев, когда поворот совершается при въезде на перекресток с круговым движением;</p><br>

<p style="text-indent:2em;">b) уступить дорогу транспортным средствам, следующим с противоположного направления прямо или направо, а также пешеходам.</p><br>

<p style="text-indent:2em;">Если при развороте вне перекрестка габариты транспортного средства или недостаточная ширина проезжей части не позволяет произвести этот маневр из 
крайнего левого положения, он может быть произведен от правого края проезжей части или даже с обочины. При этом водитель должен уступить дорогу 
попутным и встречным транспортным средствам.</p>

<p style="text-indent:2em;"></p><h6>(2) Перед поворотом направо водитель должен:</h6><p></p>

<p style="text-indent:2em;">а) заблаговременно занять на проезжей части крайнее правое положение, кроме случаев, когда поворот совершается при въезде на перекресток с 
круговым движением;</p><br>

<p>b) уступить дорогу велосипедистам, движущимся по велосипедной дорожке, а также пешеходам;</p><br>

<p style="text-indent:2em;">с) если из-за габаритов транспортного средства или недостаточной ширины проезжей части водитель вынужден частично или полностью занять и соседнюю 
полосу, этот маневр он может произвести убедившись, что не создаст угрозу безопасности движения. При необходимости он должен прибегнуть к помощи 
других лиц.</p><br>

<p style="text-indent:2em;">(3) Повороты должны осуществляться таким образом, чтобы при выезде с перекрестка транспортное средство не оказалось на полосе встречного движения.</p><br>

<p style="text-indent:2em;">(4) При наличии полосы торможения водитель должен своевременно перестроиться на нее для снижения скорости.</p><br>

<p style="text-indent:2em;">При наличии в месте въезда на дорогу полосы разгона водитель должен двигаться по ней и вливаться в транспортный поток, не создавая препятствий 
транспортным средствам, движущимся по этой дороге.</p><br>

<p style="text-indent:2em;">(5) Если на дороге, на которую производится поворот, имеется полоса или полосы с реверсивным движением, при въезде на эту дорогу водитель должен 
двигаться по крайней правой полосе.</p><br>

<p style="text-indent:2em;">Перестраиваться разрешается только после того, как водитель убедится, что движение в данном направлении разрешено по этим полосам.</p><br>

<p style="text-indent:2em;">(6) В случаях, когда траектории движения транспортных средств пересекаются, а очередность движения не оговорена настоящими правилами, водитель 
должен уступить дорогу транспортному средству, которое приближается справа.</p>

<p style="text-indent:2em;"></p><h6>41. (1) Разворот запрещается:</h6><p></p>

<p>а) на пешеходных переходах;</p><br>

<p>b) в тоннелях, на мостах, путепроводах, эстакадах и под ними;</p><br>

<p>с) на железнодорожных переездах и ближе 50 м от них;</p><br>

<p style="text-indent:2em;">d) в местах с видимостью хотя бы в одном направлении менее 100 м без помощи другого лица, находящегося вне транспортного средства.</p><br>

<p>е) в местах расположения остановок маршрутных транспортных средств</p><br>

<p style="text-indent:2em;">(2) Начинать движение задним ходом водитель может только после того как убедится, что этим маневром не будет создана опасность или помеха 
другим участникам движения.</p><br>

<p style="text-indent:2em;">В случае, когда видимость сзади ограничена, водитель должен прибегнуть к помощи другого лица, находящегося вне транспортного средства.</p><br>

<p>Движение задним ходом запрещается на перекрестках и в местах, указанных в подпункте (1) настоящего пункта</p>

<p></p><h4>Глава II РАСПОЛОЖЕНИЕ ТРАНСПОРТНЫХ СРЕДСТВ НА ПРОЕЗЖЕЙ ЧАСТИ</h4><p></p>

<p style="font-weight:500;">42. (1) Количество полос движения определяется разметкой или знаками 5.28 - 5.32.3 , а при их отсутствии - самими водителями с учетом ширины проезжей 
части, габаритов транспортных средств и необходимых безопасных интервалов между ними.</p><br>

<p style="text-indent:2em;">(2) Вне населенных пунктов водители должны вести транспортные средства как можно ближе к правому краю проезжей части.</p><br>

<p style="text-indent:2em;">(3) На дорогах с двусторонним движением, имеющих не менее двух полос для движения в каждом направлении, запрещается выезжать на сторону проезжей части, 
предназначенную для встречного движения.</p><br>

<p style="text-indent:2em;">(4) На дорогах с двусторонним движением, имеющих три полосы, средняя полоса предназначена только для объезда, обгона и перестроения для поворота 
налево или разворота.</p><br>

<p>Выезд на крайнюю левую полосу запрещается.</p><br>

<p style="text-indent:2em;">(5) На дороге, имеющей не менее двух полос для движения в данном направлении, при наличии трамвайных путей выезд на них запрещен. Если для движения в 
данном направлении имеется только одна полоса, выезжать разрешается только на трамвайные пути попутного направления при условии, что не будут созданы 
помехи для движения трамвая.</p>

<p style="text-indent:2em;"></p><h6>43. (1) На дорогах с тремя и более полосами для движения в данном направлении:</h6><p></p>

<p style="text-indent:2em;">а) тихоходные транспортные средства должны двигаться по крайней правой полосе, кроме случаев обгона, объезда и перестроения для поворота налево или 
разворота;</p><br>

<p style="text-indent:2em;">b) грузовые автомобили с разрешенной максимальной массой более 3,5 т могут занимать крайнюю левую полосу только для поворота налево или разворота.</p><br>

<p style="text-indent:2em;">(2) На дорогах, проезжая часть которых разделена на полосы линиями продольной разметки, движение транспортных средств должно осуществляться строго по 
полосам. Пересекать прерывистые линии разрешается только при перестроении.</p><br>

<p>(3) При интенсивном движении водители могут сменить полосу только для поворота, разворота или остановки.</p><br>

<p style="font-weight:500;">44. На тротуары и пешеходные дорожки разрешается заезжать только машинам, осуществляющим работы по их обслуживанию и ремонту, имеющим на это 
разрешение органов дорожной полиции</p><br>

<p style="font-weight:500;">44. По тротуарам и пешеходным дорожкам разрешается движение по кратчайшему пути только транспортных средств, обслуживающих торговые и другие предприятия,
 расположенные непосредственно у дороги, а также самоходных машин, осуществляющих на этих тротуарах или пешеходных дорожках уборочные либо ремонтные работы 
 с условием, что при этом не создаются помехи для пешеходов.</p>

<p></p><h4>Глава III СКОРОСТЬ ДВИЖЕНИЯ И ДИСТАНЦИЯ МЕЖДУ ТРАНСПОРТНЫМИ СРЕДСТВАМИ</h4><p></p>

<p style="text-indent:2em;"></p><h6>45. (1) Водитель должен вести транспортное средство со скоростью, не превышающей установленного ограничения, постоянно учитывая при этом следующие факторы:</h6><p></p>

<p>а) психофизиологическое состояние, которое влияет на внимание и реакцию;</p><br>

<p>b) опыт водителя, позволяющий прогнозировать опасные ситуации;</p><br>

<p>с) состояние транспортного средства и особенности груза;</p><br>

<p>d) дорожные условия;</p><br>

<p>е) дорожную обстановку.</p><br>

<p style="text-indent:2em;">(2) При возникновении в пределах видимости препятствия, которое водитель в состоянии обнаружить, он должен снизить скорость вплоть до остановки, чтобы не 
ставить под угрозу безопасность движения.</p>

<p style="text-indent:2em;"></p><h6>46. Водители транспортных средств должны проявлять особую осторожность и в случае необходимости снизить скорость до предела, обеспечивающего безопасность 
движения, или даже остановиться при проезде мимо:</h6><p></p>

<p>а) детей, людей преклонного возраста и лиц с явными признаками инвалидности;</p><br>

<p>b) стоящих особо малых автобусов и автобусов с опознавательным знаком 5 (приложение N 6);</p><br>

<p>с) маршрутных транспортных средств, находящихся на остановках.</p>

<p style="text-indent:2em;"></p><h6>47. (1) Допустимые максимальные пределы скорости на общественных дорогах:</h6><p></p>

<p style="text-indent:2em;">а) в населенных пунктах - 60 км/ч; при условии обеспечения безопасности движения этот предел на отдельных участках дорог может быть повышен до 90 км/ч путем 
установки соответствующего знака 3.27;</p><br>

<p>b) вне населенных пунктов - 110 км/ч - на дорогах, обозначенных знаком 5.4, 90 км/ч - на других дорогах.</p>

<p style="text-indent:2em;"></p><h6>(2) Допустимые максимальные пределы скорости вне населенных пунктов в зависимости от категории транспортных средств:</h6><p></p>

<p style="text-indent:2em;">а) для легковых автомобилей и грузовых автомобилей с разрешенной максимальной массой не более 3,5 т - на дорогах, обозначенных знаком 5.4 - 110 км/ч, на 
других дорогах - 90 км/ч;</p><br>

<p style="text-indent:2em;">b) для особо малых автобусов, междугородных и туристских автобусов, а также мотоциклов на всех дорогах - 90 км/ч;</p><br>

<p style="text-indent:2em;">с) для других автобусов, грузовых автомобилей с разрешенной максимальной массой более 3,5 т, а также для легковых автомобилей и грузовых автомобилей с 
разрешенной максимальной массой не более 3,5 т при буксировке прицепа по дорогам, обозначенным знаком 5.4, - 90 км/ч, на других дорогах - 70 км/ч</p><br>

<p style="font-weight:500;">48. (1) Водителям транспортных средств со стажем менее одного года на всех дорогах запрещается превышать скорость 70 км/ч. На этих транспортных средствах 
спереди и сзади должен быть установлен опознавательный знак 15 (приложение N 6).</p><br>

<p>(2) При перевозке людей в кузове грузового автомобиля максимальная скорость не должна превышать 60 км/ч.</p><br>

<p style="text-indent:2em;">(3) Буксировка неисправных механических транспортных средств должна осуществляться со скоростью, не превышаюшей 50 км/ч.</p><br>

<p style="text-indent:2em;">(4) Водители транспортных средств, предназначенных для перевозки опасных, тяжеловесных или крупногабаритных грузов, должны соблюдать скорость, предписанную при согласовании 
условий перевозки. На этих транспортных средствах сзади устанавливается опознавательный знак 11 (приложение N 6) .</p><br>

<p style="font-weight:500;">49. (1) В зависимости от скорости движения водитель должен соблюдать такое расстояние (дистанцию) до движущегося впереди транспортного средства, которое позволит избежать 
столкновения в случае экстренного торможения или внезапной остановки последнего.</p><br>

<p style="text-indent:2em;">(2) Вне населенных пунктов водители тихоходных, тяжеловесных, крупногабаритных и длинномерных транспортных средств должны соблюдать такую дистанцию до движущегося впереди 
транспортного средства, которая позволит обгоняющим их транспортным средствам безопасно завершить обгон.</p><br>

<p style="text-indent:2em;">Исключением являются случаи, когда эти водители намерены сами произвести обгон, а также при интенсивном движении и при движении по дорогам, имеющим не менее двух полос для 
движения в данном направлении.</p>

<p style="text-indent:2em;"></p><h6>50. Водителю запрещается:</h6><p></p>

<p>а) превышать максимальную скорость, определенную технической характеристикой транспортного средства;</p><br>

<p>b) превышать скорость, указанную на опознавательном знаке 11 (приложение N 6);</p><br>

<p>с) двигаться без необходимости со слишком малой скоростью, тем самым создавая помехи движению;</p><br>

<p>d) резко тормозить, если это не требуется для обеспечения безопасности движения.</p>

<p></p><h4>Глава IV ОБГОН И ВСТРЕЧНЫЙ РАЗЪЕЗД</h4><p></p>

<p style="text-indent:2em;"></p><h6>51.(1) Прежде чем начать обгон, водитель должен убедиться в том, что:</h6><p></p>

<p>а) ни один из следующих за ним водителей не начал обгон;</p><br>

<p style="text-indent:2em;">b) водитель транспортного средства, движущегося впереди него по той же полосе, не подал сигнал о намерении совершить обгон или перестроиться налево;</p><br>

<p style="text-indent:2em;">с) он сможет совершить обгон, не создавая помех встречным транспортным средствам, то есть полоса движения, на которую он намерен выехать, свободна на достаточном расстоянии 
и скорость обгоняемого транспортного средства позволяет совершить этот маневр;</p><br>

<p style="text-indent:2em;">d) он сможет возвратиться на ранее занимаемую полосу, не создавая помех обгоняемому транспортному средству.</p><br>

<p style="text-indent:2em;">Непосредственно после завершения обгона запрещается резко снижать скорость или останавливаться, если это не требуется для обеспечения безопасности движения.</p><br>

<p style="text-indent:2em;">(2) В момент обгона водитель должен соблюдать достаточный боковой интервал между своим и обгоняемым транспортным средством, в особенности при обгоне велосипедов, мопедов и 
двухколесных мотоциклов.</p><br>

<p style="text-indent:2em;">(3) На дорогах, имеющих не менее двух полос для движения в данном направлении, водитель, совершивший обгон, может остаться на этой полосе, если он намерен опережать другие 
транспортные средства, убедившись, что при этом не будут созданы помехи для водителей транспортных средств, движущихся за ним с более высокой скоростью.</p><br>

<p style="font-weight:500;">52. Обгонять транспортные средства разрешается только с левой стороны. В виде исключения обгон транспортных средств, водители которых подали сигнал поворота налево и 
приступили к выполнению маневра, а также трамваев производится с правой стороны при наличии для этого достаточного пространства.</p>

<p style="text-indent:2em;"></p><h6>Примечание. Трамваи обгоняются с левой стороны, если между правым рельсом и краем проезжей части нет достаточного пространства для обгона, а также на дорогах с 
односторонним движением.</h6><p></p>

<p style="font-weight:500;">53. (1) Водителю обгоняемого транспортного средства запрещается препятствовать обгону увеличением скорости или иными действиями.</p><br>

<p style="text-indent:2em;">(2) Если дорожная обстановка не позволяет обогнать тихоходное транспортное средство, водитель последнего обязан при первой возможности уступить дорогу транспортным
 средствам, движущимся за ним.</p>

<p style="text-indent:2em;"></p><h6>54. (1) Обгон запрещается:</h6><p></p>

<p style="text-indent:2em;">а) на нерегулируемых перекрестках и в непосредственной близости от них при движении по равнозначным или второстепенным дорогам;</p><br>

<p>b) на пешеходных переходах и в непосредственной близости перед ними;</p><br>

<p>с) в тоннелях;</p><br>

<p>d) на железнодорожных переездах и ближе 100 м перед ними.</p>

<p style="text-indent:2em;"></p><h6>(2) Обгон с выездом на полосу встречного движения запрещается:</h6><p></p>

<p>а) на регулируемых перекрестках;</p><br>

<p>b) на участках дорог с ограниченной видимостью;</p><br>

<p style="text-indent:2em;">с) в местах, где образовалась колонна транспортных средств, водители которых обязаны соблюдать требования сигналов, запрещающих движение, а также в случаях,
 когда движение осуществляется на малой скорости.</p><br>

<p style="font-weight:500;">55. (1) Если встречный разъезд затруднен, водитель, на стороне которого находится препятствие, должен уступить дорогу.</p><br>

<p style="text-indent:2em;">(2) На дорогах с уклонами, обозначенными дорожными знаками 1.14.1 и 1.14.2, где одновременный проезд двух движущихся навстречу транспортных средств затруднен,
 уступить дорогу должен водитель транспортного средства, движущегося на спуск.</p>

<p></p><h4>Глава V ПРОЕЗД ПЕРЕКРЕСТКОВ</h4><p></p>

<p></p><h4>ОБЩИЕ ПРАВИЛА</h4><p></p>

<p style="font-weight:500;">56. (1) Если траектории движения транспортных средств не пересекаются, они могут проехать перекресток одновременно.</p><br>

<p style="text-indent:2em;">(2) При повороте налево или развороте водитель должен уступить дорогу транспортным средствам, движущимся со встречного направления прямо или направо.
 Этим же правилом руководствуются между собой и водители трамваев.</p><br>

<p style="text-indent:2em;">Водители транспортных средств, одновременно поворачивающие налево или разворачивающиеся со встречных направлений, оставляют центр перекрестка с правой стороны,
 если средства дорожной сигнализации или конфигурация перекрестка не предписывают другой порядок выполнения маневра.</p><br>

<p style="text-indent:2em;">(3) При повороте направо или налево водитель должен уступить дорогу велосипедистам, пересекающим ее по велосипедной дорожке, а также пешеходам,
 переходящим проезжую часть дороги, на которую он поворачивает.</p><br>

<p style="text-indent:2em;">(4) При одновременном и равном праве на движение водители трамваев имеют преимущество перед водителями других транспортных средств.</p><br>

<p style="font-weight:500;">57. Запрещается выезжать на перекресток, если в направлении движения образовался затор, который вынудит водителя остановиться, что
 создаст препятствие для движения транспортных средств в пересекаемом направлении.</p>

<p></p><h4>РЕГУЛИРУЕМЫЕ ПЕРЕКРЕСТКИ</h4><p></p>

<p style="font-weight:500;">58. (1) Перекресток, на котором очередность движения определяется сигналами светофора или регулировщика, считается регулируемым.</p><br>

<p style="text-indent:2em;">(2) Водитель, въехавший на перекресток при зеленом сигнале светофора, должен выехать в намеченном направлении независимо от сигналов светофора
 на выезде. Однако, если на перекрестке перед светофорами, расположенными на пути следования водителя, имеется разметка 1.12 и/или установлен
 знак 5.61, водитель должен руководствоваться сигналами каждого светофора.</p><br>

<p style="text-indent:2em;">(3) При включении зеленого сигнала светофора водитель должен уступить дорогу транспортным средствам, находящимся на перекрестке, независимо от их
 дальнейшего движения, а также пешеходам, которые завершают переход проезжей части.</p><br>

<p style="text-indent:2em;">(4) При движении в направлении стрелки, включенной в дополнительной секции одновременно с красным сигналом светофора, водитель должен уступить дорогу
 транспортным средствам, движущимся с других направлений.</p><br>

<p style="text-indent:2em;">(5) На перекрестке, где движение регулируется светофором с дополнительной секцией, при отсутствии знака 5.28 или разметки 1.18 водитель, находящийся на полосе,
 с которой производится поворот, должен продолжить движение в направлении, указанном включенной стрелкой, если его остановка создаст помеху транспортным средствам,
 следующим за ним по той же полосе.</p>

<p></p><h4>НЕРЕГУЛИРУЕМЫЕ ПЕРЕКРЕСТКИ</h4><p></p>

<p style="font-weight:500;">59. (1) Перекресток, на котором светофоры выключены или работают в режиме желтого мигающего сигнала, считается нерегулируемым.</p><br>

<p style="text-indent:2em;">(2) На перекрестке неравнозначных дорог водитель транспортного средства, движущегося по второстепенной дороге, должен уступить дорогу транспортным средствам
, приближающимся к перекрестку по главной дороге.</p><br>

<p style="text-indent:2em;">(3) В случае, когда главная дорога на перекрестке меняет направление, водители транспортных средств, которые движутся по главной дороге, должны руководствоваться
 между собой правилами проезда перекрестков равнозначных дорог. Этим же правилом руководствуются между собой и водители, движущиеся по второстепенным дорогам.</p><br>

<p style="text-indent:2em;">(4) На перекрестке равнозначных дорог водитель должен уступить дорогу транспортным средствам, приближающимся справа независимо от направления их дальнейшего движения.</p>

<p></p><h4>Глава VI ЖЕЛЕЗНОДОРОЖНЫЕ ПЕРЕЕЗДЫ</h4><p></p>

<p style="font-weight:500;">60. Участники дорожного движения должны пересекать железнодорожные пути только по железнодорожным переездам, уступая дорогу поезду или другому
 рельсовому транспортному средству.</p><br>

<p style="font-weight:500;">61. Подъезжая к железнодорожному переезду, водитель должен снизить скорость и убедиться в отсутствии приближающегося поезда.</p><br>

<p style="text-indent:2em;">Каждый участник дорожного движения должен руководствоваться требованиями дорожных знаков и разметки, сигналами светофора, положением шлагбаума,
 а также указаниями дежурного по переезду.</p>

<p style="text-indent:2em;"></p><h6>Примечание. Положение, при котором дежурный по переезду обращен к участникам дорожного движения грудью или спиной, руки вытянуты в сторону или опущены либо
 с поднятым над головой жезлом (флажком), означает \" движение запрещено\".</h6><p></p>

<p style="font-weight:500;">62.(1) Если переезд оборудован шлагбаумами и/или светофорами, водитель может продолжить движение только при открытом шлагбауме и разрешающем сигнале светофора.</p><br>

<p style="text-indent:2em;">(2) При закрытых или начинающих закрываться шлагбаумах и/или при сигналах светофора, запрещающих движение (независимо от положения шлагбаума), водитель должен
 остановиться у разметки 1.12 перед светофором, а при ее отсутствии - не ближе 5 м от шлагбаума.</p><br>

<p style="font-weight:500;">63. При отсутствии на железнодорожном переезде шлагбаумов и светофоров водитель может продолжить движение только убедившись, что к переезду ни с одной из сторон
 не приближается поезд или другое рельсовое транспортное средство.</p><br>

<p style="text-indent:2em;">Для пропуска приближающегося поезда или другого рельсового транспортного средства водитель должен остановиться у разметки 1.12 или знака 2.2, а при их отсутствии
 - не ближе 10 м до ближнего рельса.</p><br>

<p style="text-indent:2em;">Возобновить движение водитель может только убедившись, что к переезду не приближается поезд или другое рельсовое транспортное средство.</p><br>

<p style="font-weight:500;">64. При отсутствии перед переездом знака 5.28 и/или разметки 1.18 движение транспортных средств через переезд разрешается только в один ряд.</p>

<p style="text-indent:2em;"></p><h6>65. Водителю транспортного средства запрещается:</h6><p></p>

<p>а) въезжать на переезд, если дорожная обстановка вынудит его остановиться на переезде;</p><br>

<p style="text-indent:2em;">b) самовольно открывать шлагбаумы или объезжать закрытые шлагбаумы, а также объезжать стоящие перед переездом транспортные средства.</p>

<p style="text-indent:2em;"></p><h6>Примечания: 1. Движение через железнодорожный переезд самоходных машин разрешается только в транспортном положении.</h6><p></p>

<p style="text-indent:2em;">2. Движение крупногабаритных, тяжеловесных, длинномерных и тихоходных транспортных средств через железнодорожные переезды осуществляется с разрешения
 начальника дистанции пути железной дороги.</p><br>

<p style="font-weight:500;">66. (1) При вынужденной остановке транспортного средства на железнодорожном переезде водитель, дежурный по переезду, а также водители других,
 находящихся вблизи переезда транспортных средств, обязаны принять необходимые меры для немедленного вывода людей с железнодорожного переезда
 и удаления с него транспортного средства.</p>

<p style="text-indent:2em;"></p><h6>(2) Если транспортное средство невозможно удалить с переезда, водитель должен:</h6><p></p>

<p style="text-indent:2em;">а) по возможности послать двух человек в обе стороны от переезда на расстояние не менее 1 км (если одного, то в сторону худшей видимости),
 объяснив им правила подачи сигнала остановки машинисту приближающегося поезда;</p><br>

<p>b) оставаться возле транспортного средства и подавать сигналы общей тревоги;</p><br>

<p>с) при появлении поезда бежать ему навстречу, подавая сигнал остановки.</p>

<p style="text-indent:2em;"></p><h6>Примечания: 1. Сигнал остановки поезда подается круговым движением руки (в темное время суток и в условиях недостаточной видимости нужно
 использовать фонарь или факел).</h6><p></p>

<p>2. Сигналом общей тревоги является серия звуковых сигналов из одного длинного и трех коротких.</p><br>

<p style="font-weight:500;">67. Стадо животных разрешается перегонять через переезд только при достаточном количестве погонщиков, но не менее трех. Переводить одиночных
 животных (не более двух на одного погонщика) необходимо за повод.</p>

<p></p><h4>Глава VII ОСТАНОВКА И СТОЯНКА, ПАРКОВАНИЕ</h4><p></p>

<p style="font-weight:500;">68. (1) Остановка и стоянка транспортных средств разрешается в один ряд параллельно краю проезжей части (там, где имеются местные уширения проезжей части,
 остановка и стоянка допускаются иным способом), если дорожные знаки или разметка не предписывают другое. Мотоциклы без бокового прицепа, мопеды и
 велосипеды могут быть поставлены на стоянку на проезжей части в два ряда.</p><br>

<p style="text-indent:2em;">(2) При постановке транспортного средства на стоянку в местах, не обозначенных разметкой, водители должны рационально использовать эту территорию.</p>

<p style="text-indent:2em;"></p><h6>(3) В населенных пунктах остановка и стоянка транспортных средств разрешается следующим образом:</h6><p></p>

<p>а) на правой стороне дороги, как можно ближе к бордюру или на обочине;</p><br>

<p style="text-indent:2em;">b) по обе стороны дороги с односторонним движением, если при этом остается свободной не менее одной полосы (грузовые автомобили с разрешенной максимальной
 массой более 3,5 т могут остановиться с левой стороны только для погрузки или разгрузки).</p>

<p style="text-indent:2em;"></p><h6>Примечание. Остановка и стоянка легковых автомобилей и мотоциклов может осуществляться с частичным или полным заездом на тротуар только в специально
 оборудованных местах, обозначенных знаками 3.32.4 или 5.39, а также разметкой 1.1, с). Постановка транспортных средств на стоянку должна производиться
 способом, указанным на дополнительной табличке 6.8.2, 6.8.3, 6.8.6-6.8.9.</h6><p></p>

<p>Запрещаются остановка и стоянка на газонах</p><br>

<p style="text-indent:2em;">(4) На дорогах вне населенных пунктов остановка и стоянка транспортных средств разрешается только вне проезжей части, а если это невозможно,
 как можно ближе к ее правому краю, параллельно оси дороги.</p><br>

<p style="text-indent:2em;">Длительная стоянка (отдых, ночлег и т.п.) разрешается только на площадках для стоянки или за пределами дороги.</p>

<p style="text-indent:2em;"></p><h6>69. Остановка запрещается:</h6><p></p>

<p>а) на железнодорожных переездах и на расстоянии менее 50 м от них;</p><br>

<p>b) в тоннелях, на мостах, путепроводах, эстакадах и под ними;</p><br>

<p>с) на участках дорог с ограниченной видимостью (в конце подъема, на опасных поворотах и т.п.);</p><br>

<p>d) на пешеходных переходах и на расстоянии менее 5 м перед ними;</p><br>

<p>е) на перекрестках и на расстоянии ближе 15 м от них;</p><br>

<p style="text-indent:2em;">f) ближе 25 м от остановочных площадок маршрутных транспортных средств или такси, а при их отсутствии - от знаков 5.6 - 5.8, если при этом
 создается помеха движению этих транспортных средств.</p>

<p style="text-indent:2em;"></p><h6>Примечание. Остановка маршрутных автобусов особо малого класса должна осуществляться после дорожного знака 5.6 или 5.7.</h6><p></p>

<p style="text-indent:2em;">g) напротив сплошной линии продольной разметки, если водители других транспортных средств, движущихся в том же направлении, будут вынуждены 
по этой причине пересекать эту разметку;</p><br>

<p>h) в местах, где остановившееся транспортное средство загораживает дорожные знаки или сигналы светофора;</p><br>

<p style="text-indent:2em;">i) на трамвайных путях и в непосредственной близости от них, если при этом создается помеха для движения трамваев.</p>

<p style="text-indent:2em;"></p><h6>70. Стоянка запрещается:</h6><p></p>

<p>а) в местах, в которых запрещена остановка;</p><br>

<p>b) на общественных дорогах шириной менее 6 м;</p><br>

<p>с) в местах выезда с прилегающих к дороге территорий;</p><br>

<p>d) на участках дорог с уклонами, обозначенными знаками 1.14.1 и 1.14.2.</p>

<p style="text-indent:2em;"></p><h6>Примечание. В виде исключения в населенных пунктах в зоне действия знаков 3.31 и 3.32.1 допускаются остановка и стоянка легковых автомобилей
 и мотоциклов при условии их полного заезда на тротуар.</h6><p></p>

<p style="font-weight:500;">71. (1) Водитель может оставлять транспортное средство, если им приняты необходимые меры, исключающие самопроизвольное движение транспортного
 средства или использование его в отсутствие водителя.</p><br>

<p style="text-indent:2em;">(2) Запрещается открывать двери стоящего транспортного средства, если это угрожает безопасности водителя или пассажиров либо создает помехи
 другим участникам дорожного движения.</p><br>

<p style="text-indent:2em;">(3) При вынужденной остановке транспортного средства в местах, где остановка запрещена, водитель должен принять меры для отвода его из этих мест,
 а если это невозможно, обозначить в соответствии с требованиями настоящих правил.</p><br>

<p style="font-weight:500;">72. (1) В темное время суток на неосвещенных участках общественных дорог стоящее транспортное средство должно быть обозначено в соответствии 
с требованиями настоящих правил.</p><br>

<p style="text-indent:2em;">(2) Запрещается стоянка в темное время суток на общественных дорогах самоходных машин, прицепов, гужевых повозок (саней), а также транспортных 
средств, передвигаемых вручную.</p><br>

<p style="font-weight:500;">73. Паркование транспортных средств разрешается только в специально предназначенных для этого местах, обозначенных соответствующими знаками 
и разметкой.</p>

<p></p><h4>Глава VIII БУКСИРОВКА НЕИСПРАВНЫХ МЕХАНИЧЕСКИХ ТРАНСПОРТНЫХ СРЕДСТВ И ПРИЦЕПОВ</h4><p></p>

<p style="font-weight:500;">74. (1) В случае повреждения или поломки механического транспортного средства водитель должен немедленно отвести его за пределы проезжей части а если
 это невозможно, переместить его как можно ближе к бордюру и, при необходимости, сообщить в службу технической помощи.</p><br>

<p style="text-indent:2em;">(2) Если неисправное транспортное средство не удается удалить с проезжей части и при этом создаются помехи для других участников дорожного движения,
 водитель должен обозначить его в соответствии с пунктом 37 настоящих правил.</p>

<p style="text-indent:2em;"></p><h6>75. Буксировка неисправных механических транспортных средств осуществляется с соблюдением следующих условий:</h6><p></p>

<p style="text-indent:2em;">а) посредством гибкой сцепки длиной от 4 до 6 м, которая должна быть обозначена через каждый метр флажками красного цвета размером 200х200 мм, в случае,
 если на буксируемом транспортном средстве исправны рабочая тормозная система и рулевое управление;</p><br>

<p style="text-indent:2em;">b) посредством металлической штанги длиной не более 3 м или устройства, обеспечивающего следование буксируемого транспортного средства по траектории буксирующего,
 если на буксируемом транспортном средстве исправно рулевое управление;</p><br>

<p style="text-indent:2em;">с) при неисправном рулевом управлении - посредством погрузки передней части буксируемого транспортного средства на специальную платформу или в кузов буксирующего;</p><br>

<p style="text-indent:2em;">d) за рулем буксирующего транспортного средства должен находиться водитель со стажем вождения не менее 1 года;</p><br>

<p style="text-indent:2em;">е) при буксировке на гибкой сцепке или посредством металлической штанги за рулем буксируемого транспортного средства должен находиться водитель,
 имеющий водительское удостоверение соответствующей категории;</p><br>

<p style="text-indent:2em;">f) механические транспортные средства в процессе буксировки должны быть обозначены в соответствии с абзацем d) подпункта (3) пункта 34.</p>

<p style="text-indent:2em;"></p><h6>76. Буксировка запрещается:</h6><p></p>

<p>а) в гололедицу на гибкой сцепке;</p><br>

<p>b) мотоциклами без бокового прицепа, а также таких мотоциклов;</p><br>

<p>с) двух механических транспортных средств одновременно;</p><br>

<p style="text-indent:2em;">d) если фактическая масса буксируемого транспортного средства с неисправной рабочей тормозной системой превышает половину фактической массы буксирующего.</p><br>

<p style="font-weight:500;">77. (1) При буксировке посредством гибкой или жесткой сцепки запрещается перевозить людей в буксируемом автобусе, троллейбусе и в кузове буксируемого
 грузового автомобиля.</p><br>

<p style="text-indent:2em;">(2) При буксировке посредством частичной погрузки запрещается нахождение людей в кабине и кузове буксируемого транспортного средства, а также в кузове
 буксирующего.</p><br>

<p style="font-weight:500;">78. (1) Автомобиль может буксировать только один прицеп или полуприцеп. При этом длина состава транспортных средств не должна превышать 20 м. Трактора могут
 буксировать два прицепа при условии, что длина состава не будет превышать 24 м, а скорость не будет превышать 30 км/ч.</p><br>

<p style="text-indent:2em;">(2) Автобусы могут буксировать прицеп для перевозки багажа, разрешенная максимальная масса которого не более 3 т.</p><br>

<p>(3) Мотоциклы без бокового прицепа, мопеды, а также велосипеды могут буксировать одноосный прицеп.</p>

<p style="text-indent:2em;"></p><h6>79. (1) Соединение транспортного средства с одним или двумя прицепами производится при условии, если:</h6><p></p>

<p>а) элементы, составляющие сцепное устройство, соответствуют модели тягача и прицепа;</p><br>

<p>b) тип прицепа соответствует типу тягача.</p><br>

<p style="text-indent:2em;">(2) Скорость состава транспортных средств не должна превышать максимально установленную заводом-изготовителем скорость любого из его составляющих.</p>

<p></p><h4>Глава IX ПЕРЕВОЗКА ПАССАЖИРОВ</h4><p></p>

<p style="text-indent:2em;"></p><h6>80. (1) Водитель маршрутного транспортного средства обязан:</h6><p></p>

<p>а) останавливаться для посадки и высадки пассажиров только на обозначенных остановках;</p><br>

<p>b) открывать двери только после полной остановки транспортного средства;</p><br>

<p>с) закрывать двери только после окончания посадки и/или высадке пассажиров;</p><br>

<p style="text-indent:2em;">d) начинать движение от остановки после принятия необходимых мер предосторожности для предотвращения дорожно-транспортного происшествия.</p><br>

<p style="text-indent:2em;">(2) Водителю маршрутного транспортного средства запрещается во время движения разговаривать с пассажирами, впускать их в кабину, принимать пищу, пить или курить.</p>

<p style="text-indent:2em;"></p><h6>81. (1) Перевозка людей в кузове грузового автомобиля разрешается, если:</h6><p></p>

<p style="text-indent:2em;">а) возраст водителя не менее 21 года и стаж управления транспортными средствами категории \"С\" не менее 3 лет, а при перевозке более 8 человек,
 включая пассажиров в кабине, водитель имеет и категорию \"D\";</p><br>

<p style="text-indent:2em;">b) кузов грузового автомобиля оборудован сиденьями, закрепленными на расстоянии не менее 0,3 м от верхнего края бортов и 0,3-0,5 м от пола, а сиденья,
 расположенные вдоль боковых бортов или заднего борта , имеют прочные спинки;</p><br>

<p>с) число перевозимых людей не превышает количества оборудованных для сиденья мест.</p><br>

<p style="text-indent:2em;">(2) Проезд в кузове грузового автомобиля, не оборудованного для перевозки людей, разрешается только лицам, сопровождающим груз или следующим за его получением,
 при условии, что они обеспечены местами для сиденья, расположенными ниже верхнего края бортов.</p><br>

<p style="font-weight:500;">82. (1) Дети до 7 лет, не находящиеся под наблюдением или на руках у взрослых, могут перевозиться в легковом автомобиле только в специальном детском
 удерживающем устройстве.</p><br>

<p style="text-indent:2em;">(2) Перевозка групп детей производится только в автобусах. В салоне автобуса должен находиться как минимум один взрослый в качестве сопровождающего.
 На автобусе спереди и сзади должен быть установлен опознавательный знак 5 (приложение N 6).</p>

<p style="text-indent:2em;"></p><h6>83. Запрещается:</h6><p></p>

<p style="text-indent:2em;">а) перевозка людей сверх количества, предусмотренного технической характеристикой транспортного средства (за исключением детей до 7-летнего возраста,
 находящихся на руках), при этом общая фактическая масса транспортного средства не должна превышать его разрешенной максимальной массы,
 установленной предприятием-изготовителем;</p><br>

<p style="text-indent:2em;">b) перевозка на мотоцикле без бокового прицепа детей, не достигших 12 лет, а также других лиц, если мотоцикл не оборудован сиденьем и подножками;</p><br>

<p style="text-indent:2em;">с) перевозка на мотоциклах лиц с явными признаками опьянения или если обе ноги расположены по одну сторону мотоцикла;</p><br>

<p style="text-indent:2em;">d) перевозить на переднем сиденье автобуса особо малого класса или легкового автомобиля детей, не достигших 12 лет, даже на руках у взрослых;</p><br>

<p style="text-indent:2em;">е) перевозить пассажиров вне кабины грузового автомобиля (кроме случаев перевозки людей в оборудованном для этого кузове или в фургоне), трактора,
 других самоходных машин, в прицепах, в прицепах-дачах, в кузове грузового мотоцикла.</p>

<p style="text-indent:2em;"></p><h6>84. Пассажир обязан:</h6><p></p>

<p>а) пристегиваться ремнями безопасности, если транспортное средство оборудовано ими;</p><br>

<p>b) при движении на мотоцикле быть в застегнутом мотошлеме;</p><br>

<p>с) ожидать маршрутные транспортные средства на тротуаре, обочине или на островке безопасности.</p>

<p style="text-indent:2em;"></p><h6>Примечание. Посадка и высадка пассажиров разрешается только со стороны тротуара или обочины.</h6><p></p>

<p style="text-indent:2em;"></p><h6>85. Пассажиру запрещается:</h6><p></p>

<p style="text-indent:2em;">а) во время движения входить в транспортное средство, выходить из него, открывать двери или удерживать их открытыми;</p><br>

<p>b) во время движения отвлекать водителя разговорами или иными действиями;</p><br>

<p>c) выбрасывать из транспортного средства какие-либо предметы;</p><br>

<p>d) стоять в кузове при движении грузового автомобиля.</p>

<p></p><h4>Глава X ПЕРЕВОЗКА ГРУЗОВ</h4><p></p>

<p style="font-weight:500;">86. Масса перевозимого груза и распределение нагрузки по осям не должны превышать величин, установленных технической характеристикой транспортного
 средства.</p><br>

<p style="font-weight:500;">87. Максимальные габариты транспортных средств (включая груз) не должны превышать: по ширине - 2,5 м; по высоте - 4 м; по длине груз не должен выступать
 за заднюю точку габарита транспортного средства более чем на 2 м.</p><br>

<p style="font-weight:500;">88. Если груз выступает за габариты транспортного средства по длине спереди или сзади более чем на 1 м, по ширине - более чем на 0,4 м от внешнего
 края габаритного огня, он должен быть обозначен опознавательными знаками 12 (приложение N 6), а в темное время суток - кроме того, фонарями или 
 световозвращателями спереди белого, а сзади красного цвета.</p><br>

<p style="font-weight:500;">89. Если транспортные средства или перевозимый ими груз превышают максимальные габариты, они могут двигаться по общественным дорогам только по
 разрешению органов дорожной полиции</p><br>

<p style="font-weight:500;">90. Запрещается движение транспортных средств, превышающих установленные габариты, в условиях недостаточной видимости и гололеда.</p>

<p style="text-indent:2em;"></p><h6>91. Груз должен быть размещен и закреплен на транспортном средстве таким образом, чтобы он:</h6><p></p>

<p>а) не подвергал опасности участников движения и не наносил ущерб общественному или личному имуществу;</p><br>

<p>b) не ограничивал обзор водителю, не нарушал устойчивости транспортного средства и не затруднял управление им;</p><br>

<p>с) не волочился и не выпадал;</p><br>

<p style="text-indent:2em;">d) не закрывал внешние световые приборы, световозращатели и регистрационные номерные знаки, а также сигналы, подаваемые рукой, в случае неисправности
 или отсутствия световых указателей поворота и стоп-сигналов.</p>

<p style="text-indent:2em;"></p><h6>Примечание. В случае выпадения груза из транспортного средства на проезжую часть и невозможности его немедленного удаления водитель должен обозначить
 его в соответствии с примечанием к пункту 37 настоящих правил.</h6><p></p>

<p style="text-indent:2em;"></p><h6>92. Водителям транспортных средств, предназначенных для перевозки людей, запрещается:</h6><p></p>

<p>а) перевозить груз при отсутствии багажника на крыше этих транспортных средств;</p><br>

<p style="text-indent:2em;">b) перевозить груз, габариты которого превышают габариты багажника, установленного на крыше транспортного средства;</p><br>

<p style="text-indent:2em;">с) перевозить громоздкие предметы с открытым багажником при отсутствии с обеих сторон наружных зеркал заднего вида.</p><br>

<p style="font-weight:500;">93. Перевозка опасных грузов должна осуществляться только специально оборудованными транспортными средствами, обозначенными соответствующими
 опознавательными знаками, и по маршрутам, согласованным с органами дорожной полиции</p><br>

<p style="font-weight:500;">93. Перевозка легковоспламеняющихся, взрывоопасных, радиоактивных, отравляющих и других опасных грузов осуществляется только на специально оборудованных
 транспортных средствах, обозначенных соответствующими опознавательными знаками, при соблюдении специальных правил.</p>

<p></p><h4>Глава XIДВИЖЕНИЕ В ЖИЛЫХ ЗОНАХ</h4><p></p>

<p style="font-weight:500;">94. (1) В жилых зонах движение пешеходов разрешается как по тротуарам, так и по проезжей части. Они пользуются преимуществом по отношению к транспортным
 средствам, однако не должны создавать необоснованных помех их движению.</p><br>

<p>Детские игры разрешены на всей территории жилой зоны.</p><br>

<p style="text-indent:2em;">(2) Скорость движения транспортных средств не должна превышать 20 км/ч. Водители транспортных средств должны двигаться таким образом, чтобы не создавать
 помех или опасности для пешеходов. В случае необходимости они должны остановиться.</p>

<p style="text-indent:2em;"></p><h6>95. В жилых зонах запрещается:</h6><p></p>

<p>а) транзитное движение транспортных средств;</p><br>

<p style="text-indent:2em;">b) движение грузовых автомобилей (за исключением обслуживающих население, учреждения, расположенные в этих зонах, или осуществляющих
 технологические работы);</p><br>

<p>с) учебная езда;</p><br>

<p style="text-indent:2em;">d) cтоянка транспортных средств вне специально обозначенных для этой цели мест, а также такое их расположение, которое затруднит или 
сделает невозможным движение пешеходов и транспортных средств с приоритетным режимом движения;</p><br>

<p>е) стоянка с работающим двигателем.</p><br>

<p style="font-weight:500;">96. При выезде из жилой зоны водители должны уступить дорогу другим участникам дорожного движения.</p><br>

<p style="font-weight:500;">97. Требования этой главы распространяются также и на дворовые территории.</p>

<p></p><h4>Глава XII ДВИЖЕНИЕ НА АВТОМАГИСТРАЛЯХ</h4><p></p>

<p style="text-indent:2em;"></p><h6>98. На автомагистралях запрещается:</h6><p></p>

<p style="text-indent:2em;">а) движение пешеходов, велосипедов, мопедов, самоходных машин (за исключением тех, которые производят ремонт и обслуживание дороги), 
других транспортных средств, скорость которых по технической характеристике или по другим причинам менее 60 км/ч, а также прогон домашних животных;</p><br>

<p style="text-indent:2em;">b) движение грузовых автомобилей с разрешенной максимальной массой более 3,5 т, а также автомобилей длиной более 7 м далее второй полосы,
 если автомагистраль имеет не менее трех полос;</p><br>

<p>с) буксировка неисправных механических транспортных средств далее ближайшего съезда с автомагистрали;</p><br>

<p>d) разворот и въезд в разрывы разделительной полосы;</p><br>

<p>е) движение задним ходом;</p><br>

<p>f) обучение вождению, технические испытания транспортных средств;</p><br>

<p>g) остановка вне специально оборудованных мест, обозначенных знаками 5.19 или 5.39.</p><br>

<p style="font-weight:500;">99. При вынужденной остановке водитель должен обозначить транспортное средство в соответствии с пунктом 37 настоящих правил и принять необходимые
 меры для вывода его за линию разметки 1.2, отделяющую проезжую часть от полосы, предназначенной для этой цели</p><br>

<p style="font-weight:500;">98. (1) На автомагистралях запрещается прогон домашних животных, движение пешеходов, гужевого транспорта, всадников, транспортных средств, 
передвигаемых вручную, велосипедов, мопедов, мотоколясок, самоходных машин (за исключением тех, которые производят ремонт и обслуживание дороги),
 а также транспортных средств, скорость которых по технической характеристике или по другим причинам менее 60 км/ч.</p><br>

<p style="text-indent:2em;">(2) На автомагистралях запрещается учебная езда, технические испытания транспортных средств, манифестации, спортивные соревнования и т.п.</p>

<p style="text-indent:2em;"></p><h6>99. Водителям транспортных средств запрещается:</h6><p></p>

<p>а) остановка вне специально оборудованных мест, обозначенных знаками 5.19 или 5.39.</p><br>

<p style="text-indent:2em;">При вынужденной остановке водитель должен обозначить транспортное средство в соответствии с пунктом 37 настоящих правил, и принять необходимые меры
 для вывода его за линию разметки 1.2, отделяющую проезжую часть от полосы, предназначенной для этой цели;</p><br>

<p>b) разворот и въезд в разрывы разделительной полосы;</p><br>

<p>с) движение задним ходом;</p><br>

<p>d) буксировка неисправных механических транспортных средств далее ближайшего съезда с автомагистрали.</p><br>

<p style="font-weight:500;">100.(1) При въезде на автомагистраль водитель должен двигаться по полосе разгона, уступая дорогу транспортным средствам, движущимся по ней,
 и вливаться в транспортный поток, не создавая им помех.</p><br>

<p style="text-indent:2em;">(2) При съезде с автомагистрали водитель должен своевременно перестроиться на полосу торможения и снижать скорость только на ней.</p><br>

<p style="font-weight:500;">100.(1) На автомагистралях, имеющих не менее трех полос для движения в данном направлении, водителям грузовых автомобилей с разрешенной максимальной
 массой более 3,5 т, а также автомобилей длиной более 7 м запрещается движение далее второй полосы.</p>

<p></p><h4>Глава XIII УЧАСТИЕ ТРАНСПОРТНЫХ СРЕДСТВ В МЕЖДУНАРОДНОМ ДВИЖЕНИИ</h4><p></p>

<p style="font-weight:500;">101.Транспортные средства, зарегистрированные в Республике Молдова, могут пересекать государственную границу при выполнении условий допуска к
 международному движению и при наличии у водителя национального или международного водительского удостоверения и регистрационных номерных знаков образца,
 установленного Международной конвенцией по дорожному движению, стороной которой Республика Молдова является. На этих транспортных средствах сзади
 устанавливается опознавательный знак 3 (приложение N 6).</p><br>

<p style="font-weight:500;">102. Транспортные средства, зарегистрированные в других государствах, могут двигаться по общественным дорогам Республики Молдова только при выполнении
 ими условий допуска к международному движению.</p><br>

<p style="text-indent:2em;">Зарегистрированные в других странах транспортные средства, свидетельства о регистрации которых не соответствуют условиям, установленным Международной
 конвенцией по дорожному движению, могут двигаться по общественным дорогам после того как будет получено разрешение отдела дорожной полиции, обслуживающего
 пограничный пост, через который они въехали в страну. Для получения разрешения эти транспортные средства могут двигаться от пограничного поста до места,
 где расположен отдел дорожной полиции.</p><br>

<p style="font-weight:500;">103. Лица, постоянно проживающие в других странах, временно прибывшие в Республику Молдова, могут управлять транспортными средствами при наличии у них
 национального или международного водительского удостоверения образца, установленного Международной конвенцией по дорожному движению, выданного компетентными
 органами соответствующего государства, а также признанного на основе взаимной договоренности.</p><br>

<p style="font-weight:500;">104. Лица, проживающие в Республике Молдова и владеющие транспортными средствами, зарегистрированными в других государствах, обязаны в 10-дневный срок
 поставить на учет свои транспортные средства в органах дорожной полиции по месту жительства.</p>

<p></p><h4>Глава XIV ТРАНСПОРТНЫЕ СРЕДСТВА С ПРИОРИТЕТНЫМ РЕЖИМОМ ДВИЖЕНИЯ И ОБЯЗАННОСТИ ДРУГИХ ВОДИТЕЛЕЙ</h4><p></p>

<p style="font-weight:500;">105. (1) Транспортные средства Министерства внутренних дел, Министерства обороны, Службы информации и безопасности Республики Молдова, Департамента пограничных
 войск, Службы государственной охраны, предназначенные для тушения пожаров, скорой медицинской помощи, а также банковских служб, специальной почты, оборудованные
 специальными световыми и звуковыми сигналами в соответствии с пунктом 38 настоящих правил и/или имеющие специальную окраску, при выполнении неотложных служебных
 заданий могут отступать от требований настоящих правил (за исключением глав II, VIII и XVIII) при условии принятия ими необходимых мер предосторожности для 
 предотвращения дорожно-транспортного происшествия.</p><br>

<p style="text-indent:2em;">(2) Водители этих транспортных средств при выполнении неотложных служебных заданий должны включать специальные световые и звуковые сигналы. При этом они могут
 воспользоваться преимуществом только после того, как убедятся, что им уступают дорогу. Преимуществом в движении также пользуются водители транспортных средств,
 сопровождаемых транспортными средствами с приоритетным режимом движения. На сопровождаемых транспортных средствах должен быть включен ближний свет фар</p><br>

<p style="font-weight:500;">106. (1) При приближении транспортного средства с включенными проблесковыми маячками синего цвета и звуковым сигналом или без него водители должны уступить им
 дорогу независимо от направления их дальнейшего движения, либо освободить полосу, по которой движется это транспортное средство, переместившись как можно ближе
 к бордюру, на обочину, а при необходимости - остановиться.</p><br>

<p style="text-indent:2em;">(2) При приближении колонны транспортных средств, сопровождаемой транспортным средством с приоритетным режимом движения, на котором включены проблесковые маячки
 синего и/или красного цветов, водители, независимо от направления движения, обязаны остановиться как можно ближе к бордюру или на обочине. Исключение составляют
 транспортные средства, движущиеся в противоположном направлении по дороге с разделительной полосой.</p><br>

<p style="text-indent:2em;">Возобновлять движение разрешается только после проезда замыкающего колонну транспортного средства с включенным проблесковым маячком синего или зеленого цвета.</p>

<p style="text-indent:2em;"></p><h6>Примечание. Водителям транспортных средств с приоритетным режимом движения запрещается использовать специальные световые и звуковые сигналы в целях, не связанных
 с выполнением неотложных служебных заданий.</h6><p></p>

<p style="text-indent:2em;">(3) При приближении к стоящему транспортному средству с включенным проблесковым маячком синего цвета водитель должен снизить скорость, чтобы иметь возможность,
 в случае необходимости, немедленно остановиться</p><br>

<p style="font-weight:500;">107. Водители транспортных средств дорожно- эксплутационных служб, оборудованных проблесковым маячком оранжевого цвета, при выполнении работ на дороге могут отступать
 от требований дорожных знаков и разметки, а также подпункта (5) пункта 40, подпунктов (2) и (5) пункта 42, подпунктов (1) и (2) пункта 43 и пункта 44 настоящих правил
 при условии обеспечения безопасности движения.</p><br>

<p>Водителям других транспортных средств запрещается препятствовать их работе.</p>

<p style="text-indent:2em;"></p><h6>Примечание. Проблесковый маячок оранжевого цвета не дает преимущества в движении, однако он служит для привлечения внимания и предупреждения других водителей об опасности.</h6><p></p>

<p></p><h4>Глава XV ОБУЧЕНИЕ ВОЖДЕНИЮ ТРАНСПОРТНЫХ СРЕДСТВ</h4><p></p>

<p style="text-indent:2em;"></p><h6>108. (1) Лицо, желающее пройти курс обучения вождению транспортным средством, должно:</h6><p></p>

<p style="text-indent:2em;">а) достигнуть возраста: на категорию \"А\" - 14 лет; на категорию \"В\" - 16 лет; на категорию \"С\" и \"H\" - 17 лет; на категорию \"D\", \"Е\" и \"F\" - 21 года;</p><br>

<p style="text-indent:2em;">b) быть годным по состоянию здоровья в соответствии с требованиями, установленными Министерством здравоохранения;</p><br>

<p>с) знать и выполнять требования настоящих правил;</p><br>

<p style="text-indent:2em;">d) при обучении вождению в индивидуальном порядке предъявлять по требованию работника дорожной полиции удостоверение личности и медицинскую справку о годности
 к управлению транспортным средством соответствующей категории.</p><br>

<p style="text-indent:2em;">(2) Инструкторы по вождению школ по подготовке водителей транспортных средств должны иметь свидетельство на право обучения установленного образца.</p><br>

<p style="text-indent:2em;">Обучать вождению транспортных средств категории \"А\" и \"В\" в индивидуальном порядке разрешается лицам, достигшим 21 года, с водительским стажем не менее 3 лет,
 и имеющим удостоверение на право управления транспортным средством соответствующей категории.</p><br>

<p style="text-indent:2em;">(3) Учебные транспортные средства в школах по подготовке водителей должны быть оборудованы дополнительными педалями сцепления и тормоза, а также зеркалом заднего вида
 для обучающего (последнее относится и к транспортным средствам, на которых проводится обучение в индивидуальном порядке). Эти транспортные средства должны быть обозначены
 опознавательными знаками в соответствии с подпунктом (2) пункта 35 настоящих правил.</p><br>

<p style="text-indent:2em;">(4) Первоначальное обучение вождению транспортных средств должно проводиться на закрытых площадках под постоянным контролем инструктора. Обучение вождению на общественных
 дорогах может проводиться только на маршрутах, утвержденных местными органами дорожной полиции, по правой крайней полосе (за исключением случаев объезда, обгона, поворота
 налево или разворота) и при условии, что обучаемый имеет достаточные навыки управления транспортным средством, а также знает и выполняет требования настоящих правил.</p>

<p></p><h4>Глава XVI ДВИЖЕНИЕ ВЕЛОСИПЕДОВ, ГУЖЕВОГО ТРАНСПОРТА И ТРАНСПОРТНЫХ СРЕДСТВ, ПЕРЕДВИГАЕМЫХ ВРУЧНУЮ</h4><p></p>

<p style="font-weight:500;">109. (1) Велосипедисты, водители мопедов, гужевых повозок (саней), всадники должны двигаться по общественным дорогам только в один ряд на расстоянии не более
 1 м от правого края проезжей части.</p><br>

<p style="text-indent:2em;">(2) На нерегулируемом пересечении велосипедной дорожки с общественной дорогой вне перекрестка водители велосипедов и мопедов должны уступить дорогу транспортным
 средствам, движущимся по этой дороге.</p>

<p style="text-indent:2em;"></p><h6>(3) Водителям велосипедов и мопедов запрещается:</h6><p></p>

<p>а) двигаться по проезжей части дороги, если по ходу движения имеется велосипедная дорожка или обочина;</p><br>

<p>b) ездить не держась за руль хотя бы одной рукой и убирать ноги с педалей (подножек);</p><br>

<p>с) держаться рукой за движущееся транспортное средство;</p><br>

<p>d) перевозить пассажиров, кроме ребенка в возрасте до 7 лет на специальном сиденье, установленном спереди;</p><br>

<p style="text-indent:2em;">е) перевозить грузы, объем, длина или масса которых затрудняют управление велосипедом (мопедом) либо создают помеху другим участникам дорожного движения;</p><br>

<p style="text-indent:2em;">f) двигаться по тротуарам, аллеям парков и скверов (кроме детей на детских велосипедах под присмотром взрослых);</p><br>

<p style="text-indent:2em;">g) поворачивать налево или разворачиваться на дорогах, имеющих более одной полосы для движения в данном направлении;</p><br>

<p>h) управлять велосипедом или мопедом в состоянии опьянения;</p><br>

<p>i) управлять этими транспортными средствами с неисправными тормозами.</p><br>

<p style="text-indent:2em;">(4) Запрещается буксировка велосипедов (мопедов), а также велосипедами (мопедами), кроме буксировки прицепа, предназначенного для эксплуатации с этими
 транспортными средствами.</p>

<p style="text-indent:2em;"></p><h6>Примечание. Велосипеды и мопеды должны быть оборудованы спереди световозвращателями белого цвета (или фарой) по бокам оранжевого, сзади красного цвета.</h6><p></p>

<p style="font-weight:500;">110. (1) Водитель гужевой повозки (саней) должен во время движения находиться в транспортном средстве и постоянно осуществлять контроль за животными,
 если же по какой-либо причине он вынужден идти пешком, он должен вести животных за узду.</p><br>

<p>Вести животных за узду он должен и в случае выезда на главную дорогу в условиях недостаточной видимости.</p>

<p style="text-indent:2em;"></p><h6>(2) Водителю гужевой повозки (саней) запрещается:</h6><p></p>

<p>а) двигаться по дорогам с усовершенствованным покрытием, если по ходу движения имеется грунтовая дорога;</p><br>

<p>b) двигаться галопом в населенных пунктах;</p><br>

<p style="text-indent:2em;">c) перевозить груз, превышающий габариты повозки (саней), не обозначенный по ширине днем, - красными флажками.</p>

<p style="text-indent:2em;"></p><h6>Примечание. В темное время суток и в условиях недостаточной видимости перевозка грузов, превышающих габариты транспортного средства, запрещена;</h6><p></p>

<p>d) оставлять животных без привязи во время стоянки.</p><br>

<p>(3) Животные, сопровождающие повозку (сани), должны быть привязаны к ней сзади справа.</p><br>

<p style="font-weight:500;">111. (1) Одиночные животные или стада должны передвигаться по дорогам только в сопровождении погонщиков.</p><br>

<p style="text-indent:2em;">(2) Большое стадо животных должно быть разделено на группы с учетом количества погонщиков. Между группами должно поддерживаться расстояние, позволяющее
 другим участникам дорожного движения осуществлять необходимые маневры уверенно и безопасно.</p><br>

<p style="font-weight:500;">112. (1) Водитель транспортного средства, передвигаемого вручную, не должен двигаться по общественным дорогам, если груз существенно ограничивает видимость
 в направлении движения.</p>

<p style="text-indent:2em;"></p><h6>(2) Водителю ручной тележки запрещается:</h6><p></p>

<p>а) двигаться по проезжей части дорог с интенсивным движением;</p><br>

<p>b) двигаться по неосвещенным дорогам в темное время суток или в условиях недостаточной видимости;</p><br>

<p>с) оставлять ручную тележку на проезжей части.</p>

<p></p><h4>Глава XVII ДВИЖЕНИЕ ПЕШЕХОДОВ И КОЛОНН</h4><p></p>

<p style="font-weight:500;">113. (1) Пешеходы должны двигаться по правой стороне тротуаров, пешеходных дорожек или по тропам, примыкающим к общественной дороге, а при их отсутствии
 - по обочинам. Пешеходы могут двигаться и по велосипедным дорожкам, не создавая помех движению велосипедистов и водителей мопедов.</p><br>

<p style="text-indent:2em;">(2) Пешеходы, переносящие громоздкие предметы, а также лица, передвигающиеся в инвалидных колясках, при отсутствии тротуаров, пешеходных дорожек и обочин
 могут двигаться у правого края проезжей части дороги с особой осторожностью, не создавая помех другим участникам дорожного движения.</p><br>

<p style="text-indent:2em;">(3) На дорогах с разделительной полосой при отсутствии тротуаров и обочин пешеходы должны двигаться один за другим по правому краю проезжей части дороги.</p><br>

<p style="text-indent:2em;">(4) Вне населенных пунктов при движении по обочине или краю проезжей части дороги пешеходы должны идти навстречу движению транспортных средств. Лица, 
передвигающиеся в инвалидных колясках, ведущие велосипед, мопед или мотоцикл, должны следовать по ходу движения транспортных средств.</p><br>

<p style="text-indent:2em;">(5) При движении по проезжей части или обочине в темное время суток или в условиях недостаточной видимости пешеходы по возможности должны прикреплять 
к своей одежде световозвращающие элементы.</p><br>

<p style="font-weight:500;">114. (1) Пешеходы должны пересекать проезжую часть только по обозначенным пешеходным переходам, в том числе по подземным или надземным, а при их отсутствии 
- на перекрестках по линии тротуаров или обочин.</p><br>

<p style="text-indent:2em;">(2) Если в зоне видимости (100-150 м) нет пешеходного перехода или перекрестка, пешеходам разрешается переходить дорогу под прямым углом к краю проезжей части,
 предварительно убедившись в отсутствии приближающихся транспортных средств.</p><br>

<p>(3) Выйдя на проезжую часть, пешеходы не должны задерживаться или останавливаться на ней без причины.</p><br>

<p style="font-weight:500;">115. (1) На регулируемых пешеходных переходах пешеходы должны руководствоваться сигналами пешеходных светофоров, а при их отсутствии - сигналами транспортных
 светофоров или регулировщиков.</p><br>

<p style="text-indent:2em;">(2) На нерегулируемых пешеходных переходах, обозначенных знаками и разметкой, пешеходы имеют преимущество перед водителями транспортных средств, однако
 выходить на проезжую часть они могут лишь убедившись в безопасности перехода.</p>

<p style="text-indent:2em;"></p><h6>116. (1) Пешеходам запрещается переходить дорогу:</h6><p></p>

<p style="text-indent:2em;">а) непосредственно перед и за находящимся на остановке маршрутным транспортным средством, а также выходя из-за стоящих на дороге других транспортных средств;</p><br>

<p>b) на участках дорог с ограниченной видимостью;</p><br>

<p>с) по территории перекрестков, вне пешеходных переходов;</p><br>

<p style="text-indent:2em;">d) при приближении транспортного средства с приоритетным режимом движения с включенными световыми и звуковыми сигналами.</p><br>

<p style="text-indent:2em;">(2) Пешеходам запрещается переходить пути на железнодорожных переездах, если световой сигнал светофора или положение шлагбаума запрещает движение.</p><br>

<p style="text-indent:2em;">(3) Пешеходы должны ожидать маршрутные транспортные средства на остановочных площадках, тротуарах, а там, где они отсутствуют, - на обочине.</p><br>

<p style="font-weight:500;">117. (1) Родители, воспитатели и другие лица, осуществляющие присмотр за детьми, не должны позволять детям самостоятельно выходить на проезжую часть дороги.</p><br>

<p style="text-indent:2em;">(2) Взрослые, сопровождающие детей в возрасте до 7 лет, должны вести их по внешней стороне тротуаров или обочин.</p><br>

<p style="text-indent:2em;">(3) Группы детей разрешается водить колонной по два только по тротуарам и пешеходным дорожкам, а при их отсутствии - по обочине, но лишь в светлое время суток
 и в сопровождении взрослых.</p>

<p style="text-indent:2em;"></p><h6>118. Руководители колонн обязаны:</h6><p></p>

<p style="text-indent:2em;">а) формировать колонну не более чем по четыре человека в ряд, за исключением манифестаций, шествий и кортежей;</p><br>

<p style="text-indent:2em;">b) назначить на левом фланге в начале и в конце колонны сопровождающих: днем с красными флажками, а в темное время суток или в условиях недостаточной видимости
 - с зажженными фонарями: спереди белого цвета, сзади - красного;</p><br>

<p>с) разделить колонну на группы, чтобы обеспечить транспортным средствам возможность обгона.</p>

<p></p><h4>Глава ХVIII ТЕХНИЧЕСКИЕ УСЛОВИЯ ДЛЯ ДОПУСКА К ДОРОЖНОМУ ДВИЖЕНИЮ МЕХАНИЧЕСКИХ ТРАНСПОРТНЫХ СРЕДСТВ И ПРИЦЕПОВ</h4><p></p>

<p style="font-weight:500;">119. Техническое состояние и оборудование транспортных средств должны соответствовать требованиям стандартов, касающихся безопасности дорожного движения, 
охраны окружающей среды, правил технической эксплуатации, инструкций предприятий - изготовителей, а также другой нормативно-технической документации.</p>

<p style="text-indent:2em;"></p><h6>Примечание. Запрещается эксплуатация троллейбусов при выявлении неисправностей, указанных в правилах технической эксплуатации этих транспортных средств.</h6><p></p>

<p style="font-weight:500;">120. Для допуска транспортных средств к дорожному движению необходимо подвергать их периодическому техническому осмотру или техническому тестированию на
 авторизированных станциях в установленном порядке.</p>

<p style="text-indent:2em;"></p><h6>Примечание. До завершения создания сети центров и станций тестирования проверка технического состояния транспортных средств осуществляется и подразделениями
 дорожной полиции, и инспекциями "Intehagro"</h6><p></p>

<p style="font-weight:500;">120. Для допуска к дорожному движению транспортные средства должны периодически проходить технический осмотр. После его прохождения органы дорожной полиции
 выдают владельцу транспортного средства талон установленного образца, который помещается в правом нижнем углу за ветровым стеклом</p>

<p style="font-weight:500;">120. Техническое состояние транспортных средств проверяется в станциях тестирования, аккредитованных в установленном порядке.</p>

<p style="text-indent:2em;"></p><h6>Примечание. До завершения создания сети станций тестирования проверка технического состояния транспортных средств осуществляется и подразделениями дорожной
 полиции и инспекциями \"Intehagro\".</h6><p></p>

<p style="font-weight:500;">120. Техническое состояние транспортных средств проверяется в центрах и станциях тестирования, аккредитованных в установленном порядке.</p>

<p style="text-indent:2em;"></p><h6>Примечание. До завершения создания сети центров и станций тестирования проверка технического состояния транспортных средств осуществляется и подразделениями
 дорожной полиции и инспекциями \"Intehagro\".</h6><p></p>

<p style="text-indent:2em;"></p><h6>121. Запрещается эксплуатация транспортных средств с изъятием табличек с регистрационным номером:</h6><p></p>

<p style="text-indent:2em;">а) в случае их изготовления или переоборудования с нарушением требований действующих стандартов, правил и нормативов, касающихся безопасности дорожного
 движения;</p><br>

<p style="text-indent:2em;">b) если они не прошли техническое тестирование или государственный технический осмотр, и отсутствует голограмма, выданная станцией тестирования, или талон,
 выданный органами дорожной полиции;</p><br>

<p>с) при несоответствии регистрационных знаков установленным стандартам;</p><br>

<p style="text-indent:2em;">d) в случае оборудования специальными световыми и звуковыми сигналами без разрешения Управления дорожной полиции.</p>

<p style="text-indent:2em;"></p><h6>122. Запрещается эксплуатация транспортных средств при выявлении следующих технических неисправностей:</h6><p></p>

<p style="text-indent:2em;"></p><h6>(1) Тормозные системы:</h6><p></p>

<p style="text-indent:2em;">а) изменена конструкция тормозной системы, применены узлы или отдельные детали, не предусмотренные для данной модели транспортного средства или не отвечающие
 требованиям предприятия-изготовителя;</p><br>

<p style="text-indent:2em;">b) во время дорожных испытаний не соблюдаются следующие нормы эффективности торможения рабочей тормозной системой:</p>

<p style="text-indent:2em;"></p><h6>Тип транспортного средства</h6><p></p>

<p>Легковые автомобили и их модификации для перевозки грузов</p>

<p style="text-indent:2em;"></p><h6>Тормозной</h6><p></p>

<p>12,2 (14,5)</p>

<p style="text-indent:2em;"></p><h6>Установившееся путь (м) замедление (м/с2) не более не менее</h6><p></p>

<p>6,8 (6,1)</p>

<p style="text-indent:2em;"></p><h6>Тип транспортного средства</h6><p></p>

<p>Автобусы с разрешенной  максимальной массой до 5 т включительно</p>

<p style="text-indent:2em;"></p><h6>Тормозной</h6><p></p>

<p>13,6 (18,7)</p>

<p style="text-indent:2em;"></p><h6>Установившееся путь (м) замедление (м/с2) не более не менее</h6><p></p>

<p>6,8 (5,5)</p>

<p style="text-indent:2em;"></p><h6>Тип транспортного средства</h6><p></p>

<p>То же, свыше 5 т</p>

<p style="text-indent:2em;"></p><h6>Тормозной</h6><p></p>

<p>16,8 (19,9)</p>

<p style="text-indent:2em;"></p><h6>Установившееся путь (м) замедление (м/с2) не более не менее</h6><p></p>

<p>5,7 (5)</p>

<p style="text-indent:2em;"></p><h6>Тип транспортного средства</h6><p></p>

<p>Грузовые автомобили с разрешенной мак-симальной массой до 3,5 т включительно</p>

<p style="text-indent:2em;"></p><h6>Тормозной</h6><p></p>

<p>15,1 (19)</p>

<p style="text-indent:2em;"></p><h6>Установившееся путь (м) замедление (м/с2) не более не менее</h6><p></p>

<p>5,7 (5,4)</p>

<p style="text-indent:2em;"></p><h6>Тип транспортного средства</h6><p></p>

<p>То же, от 3,5 до 12 т включительно</p>

<p style="text-indent:2em;"></p><h6>Тормозной</h6><p></p>

<p>17,3 (18,4)</p>

<p style="text-indent:2em;"></p><h6>Установившееся путь (м) замедление (м/с2) не более не менее</h6><p></p>

<p>5,7 (5,7)</p>

<p style="text-indent:2em;"></p><h6>Тип транспортного средства</h6><p></p>

<p>То же, свыше 12 т</p>

<p style="text-indent:2em;"></p><h6>Тормозной</h6><p></p>

<p>16 (17,7)</p>

<p style="text-indent:2em;"></p><h6>Установившееся путь (м) замедление (м/с2) не более не менее</h6><p></p>

<p>6,2 (6,1)</p>

<p style="text-indent:2em;"></p><h6>Тип транспортного средства</h6><p></p>

<p>Автопоезда, тягачами которых являются легковые автомобили и их модификации для перевозки грузов</p>

<p style="text-indent:2em;"></p><h6>Тормозной</h6><p></p>

<p>13,6 (14,5)</p>

<p style="text-indent:2em;"></p><h6>Установившееся путь (м) замедление (м/с2) не более не менее</h6><p></p>

<p>5,9 (6,1)</p>

<p style="text-indent:2em;"></p><h6>Тип транспортного средства</h6><p></p>

<p>Автопоезда, тягачами которых являются автобусы с разрешенной максимальной массой до 5 т включительно</p> 

<p style="text-indent:2em;"></p><h6>Тормозной</h6><p></p>

<p>15,2 (18,7)</p>

<p style="text-indent:2em;"></p><h6>Установившееся путь (м) замедление (м/с2) не более не менее</h6><p></p>

<p>5,7 (5,5)</p>

<p style="text-indent:2em;"></p><h6>Тип транспортного средства</h6><p></p>

<p>То же, свыше 5 т</p>     

<p style="text-indent:2em;"></p><h6>Тормозной</h6><p></p>

<p>18,4 (19,9)</p>

<p style="text-indent:2em;"></p><h6>Установившееся путь (м) замедление (м/с2) не более не менее</h6><p></p>

<p>5,5 (5)</p>

<p style="text-indent:2em;"></p><h6>Тип транспортного средства</h6><p></p>

<p>Автопоезда, тягачами которых являются грузовые автомобили с разрешенной мак-симальной массой до 3,5 т включительно</p>    

<p style="text-indent:2em;"></p><h6>Тормозной</h6><p></p>

<p>17,7 (22,7)</p>

<p style="text-indent:2em;"></p><h6>Установившееся путь (м) замедление (м/с2) не более не менее</h6><p></p>

<p>4,6 (4,7)</p>

<p style="text-indent:2em;"></p><h6>Тип транспортного средства</h6><p></p>

<p>То же, от 3,5 до 12 т включительно</p> 

<p style="text-indent:2em;"></p><h6>Тормозной</h6><p></p>

<p>18,8 (22,1)</p>

<p style="text-indent:2em;"></p><h6>Установившееся путь (м) замедление (м/с2) не более не менее</h6><p></p>

<p>5,5 (4,9)</p>

<p style="text-indent:2em;"></p><h6>Тип транспортного средства</h6><p></p>

<p>То же, свыше 12 т</p> 

<p style="text-indent:2em;"></p><h6>Тормозной</h6><p></p>

<p>18,4 (21,9)</p>

<p style="text-indent:2em;"></p><h6>Установившееся путь (м) замедление (м/с2) не более не менее</h6><p></p>

<p>5,5 (5)</p>

<p style="text-indent:2em;"></p><h6>Тип транспортного средства</h6><p></p>

<p>Двухколесные мотоциклы и мопеды</p>

<p style="text-indent:2em;"></p><h6>Тормозной</h6><p></p>

<p>7,5 (7,5)</p>

<p style="text-indent:2em;"></p><h6>Установившееся путь (м) замедление (м/с2) не более не менее</h6><p></p>

<p>5,5 (5,5)</p>

<p style="text-indent:2em;"></p><h6>Тип транспортного средства</h6><p></p>

<p>Мотоциклы с боковым прицепом</p>

<p style="text-indent:2em;"></p><h6>Тормозной</h6><p></p>

<p>8,2 (8,2)</p>

<p style="text-indent:2em;"></p><h6>Установившееся путь (м) замедление (м/с2) не более не менее</h6><p></p>

<p>5 (5)</p>

<p style="text-indent:2em;"></p><h6>Примечания: 1. Значения тормозного пути и установившегося замедления для транспортных средств, производство которых было начато до 1 января 1981г., приведены в скобках.</h6><p></p>

<p style="text-indent:2em;">2. Испытание рабочей тормозной системы проводится на горизонтальном участке дороги с ровным, сухим, чистым цементным или асфальтовым покрытием при начальной скорости
 торможения: 40 км/ч - для автомобилей, автобусов и автопоездов; 30 км/ч - для мотоциклов и мопедов по методу одноразового воздействия на органы управления тормозной системой.</p><br>

<p style="text-indent:2em;">Результаты испытания тормозной системы считаются недействительными, если для сохранения прямолинейного направления в процессе торможения водитель должен
 исправлять траекторию движения.</p><br>

<p style="text-indent:2em;">3. Тормозной путь измеряется с момента нажатия на тормозную педаль (воздействия на ручной и ножной приводы тормоза мотоцикла и мопеда) до полной остановки транспортного средства;</p><br>

<p>с) нарушена герметичность гидравлического тормозного привода;</p><br>

<p style="text-indent:2em;">d) нарушение герметичности пневматического и пневмогидравлического тормозных приводов вызывает падение давления воздуха при неработающем двигателе более чем
 на 0,05 МПа (0,5 кгс/кв.см) за 15 минут после полного приведения их в действие;</p><br>

<p>e) не действует манометр пневматического или пневмогидравлического тормозного привода;</p><br>

<p style="text-indent:2em;">f) стояночная тормозная система не обеспечивает неподвижное состояние транспортного средства с полной нагрузкой на уклоне не менее 16%.</p>

<p style="text-indent:2em;"></p><h6>Примечание. При испытании стояночной тормозной системы двигатель должен быть отключен от трансмиссии;</h6><p></p>

<p style="text-indent:2em;">g) рычаг (рукоятка) стояночной тормозной системы не удерживается запирающим устройством в рабочем положении.</p>

<p style="text-indent:2em;"></p><h6>(2) Рулевое управление:</h6><p></p>

<p style="text-indent:2em;"></p><h6>а) суммарный люфт в рулевом управлении превышает следующие предельные значения:</h6><p></p>

<p>Тип транспортного средства</p><br>

<p>Предельное значение сум- марного люфта, градусов,не более</p><br>

<p>Легковые автомобили и созданные на их базе грузовые модификации и автобусы 10</p><br>

<p>Автобусы 20</p><br>

<p>Грузовые автомобили 25</p><br>

<p style="text-indent:2em;">b) имеются ощутимые взаимные перемещения деталей и узлов рулевого управления или перемещения их относительно шасси, кузова, рамы транспортного средства,
 резьбовые соединения не зашплинтованы, а также некоторые детали не зафиксированы надежно;</p><br>

<p style="text-indent:2em;">с) поврежден или отсутствует предусмотренный конструкцией усилитель рулевого управления или рулевой демпфер на мотоциклах;</p><br>

<p style="text-indent:2em;">d) детали рулевого управления изношены, имеют деформации и другие дефекты, применены детали и рабочие жидкости, не предусмотренные для данной модели
 транспортного средства или не соответствующие требованиям, предусмотренным конструкцией.</p>

<p style="text-indent:2em;"></p><h6>(3) Приборы освещения, световой и звуковой сигнализации:</h6><p></p>

<p style="text-indent:2em;">а) количество, тип, цвет, размещение и режим работы внешних световых приборов не соответствуют конструкции;</p><br>

<p>b) нарушена регулировка фар;</p><br>

<p>с) не работают приборы освещения и световой сигнализации;</p><br>

<p style="text-indent:2em;">d) на приборах освещения и световой сигнализации нет рассеивателей или используются рассеиватели и лампы, не отвечающие типу, предусмотренному
 конструкцией;</p><br>

<p>е) не работает или отсутствует звуковой сигнал.</p>

<p style="text-indent:2em;"></p><h6>Примечания: 1. Мотоциклы (мопеды) могут быть дополнительно оборудованы одной противотуманной фарой, другие механические транспортные средства - двумя.
 Противотуманные фары должны размещаться на высоте не менее 0,25 м от поверхности дороги (но не выше фар ближнего света) симметрично относительно продольной
 оси транспортного средства и не далее 0,4 м от внешнего габарита по ширине.</h6><p></p>

<p style="text-indent:2em;">2. Разрешается устанавливать на автомобилях один или два задних противотуманных фонаря красного цвета на высоте 0,4-1,2 м и не ближе 0,1 м от фонарей
 сигнала торможения (стоп-сигналы).</p><br>

<p style="text-indent:2em;">3. Включение противотуманных фар и задних противотуманных фонарей должно осуществляться одновременно с включением габаритных огней и освещением
 регистрационного знака.</p><br>

<p style="text-indent:2em;">4. На легковом автомобиле и автобусе разрешается устанавливать один или два дополнительных немигающих сигнала торможения красного цвета на высоте
 1,15 - 1,4 м от поверхности дороги.</p><br>

<p style="text-indent:2em;">5. На транспортных средствах, снятых с производства, можно устанавливать приборы освещения и световой сигнализации от других транспортных средств
 соответствующих марок и моделей.</p>

<p style="text-indent:2em;"></p><h6>(4) Стеклоочистители и стеклоомыватели ветрового стекла:</h6><p></p>

<p>а) не работают стеклоочистители;</p><br>

<p>b) не работают стеклоомыватели, предусмотренные конструкцией транспортного средства;</p>

<p style="text-indent:2em;"></p><h6>(5) Колеса и шины:</h6><p></p>

<p style="text-indent:2em;">а) шины легковых автомобилей имеют остаточную высоту рисунка протектора менее 1,6 мм, автобусов - 2,0 мм, грузовых автомобилей - 1,0 мм,
 мотоциклов и мопедов - 0,8 мм.</p>

<p style="text-indent:2em;"></p><h6>Примечание. Для прицепов устанавливаются нормы остаточной высоты рисунка протектора шин, аналогичные нормам для шин автомобилей- тягачей;</h6><p></p>

<p style="text-indent:2em;">b) шины имеют местные повреждения (порезы, разрывы и т.п.), обнажающие корд, а также расслоение каркаса, отслоение протектора и боковины;</p><br>

<p>c) шины по размеру или допустимой нагрузке не соответствуют модели транспортного средства;</p><br>

<p style="text-indent:2em;">d) на одну ось транспортного средства установлены диагональные шины совместно с радиальными или шины с различным рисунком протектора;</p><br>

<p>e) на передней оси автобуса и легкового автомобиля установлены шины с восстановленным протектором;</p><br>

<p>f) отсутствует болт (гайка) крепления или имеются трещины диска или обода колеса.</p>

<p style="text-indent:2em;"></p><h6>(6) Прочие технические условия:</h6><p></p>

<p>а) содержание вредных веществ в отработанных газах превышает установленные нормы;</p><br>

<p>b) негерметична система смазки</p><br>

<p>с) неисправна система выпуска отработанных газов;</p><br>

<p style="text-indent:2em;">d) отсутствуют предусмотренные конструкцией транспортного средства стекла, зеркала заднего вида или они имеют дефекты, ухудшающие видимость;</p><br>

<p style="text-indent:2em;">е) лобовое стекло и стекла передних дверей затенены или на них установлены различные предметы, ограничивающие обзор с места водителя.</p>

<p style="text-indent:2em;"></p><h6>Примечание. Допускается тонирование или нанесение иных покрытий, использование занавесок, жалюзи и т.п. на задних стеклах, если
 на автомобилях с обеих сторон имеются наружные зеркала заднего вида;</h6><p></p>

<p style="text-indent:2em;">f) не работают замки дверей кузова или кабины, а также запоры бортов грузовой платформы, запоры горловин цистерн и топливных баков,
 механизм регулирования положения сиденья водителя, аварийные выходы, устройства для приведения их в действие, привод управления дверями,
 спидометр, устройство для вентиляции и обогрева, используются трубопроводы выхлопных газов в целях обогрева пространства, предназначенного
 для водителя и пассажиров;</p><br>

<p style="text-indent:2em;">g) на автобусах и грузовых автомобилях, участвующих в международном движении, не работает или отсутствует предусмотренный конструкцией
 тахограф;</p><br>

<p>h) разрушен коренной лист или центральный болт рессоры;</p><br>

<p style="text-indent:2em;">i) неисправны тягово-сцепное или опорно-сцепное устройства тягача и прицепного звена, отсутствуют или повреждены страховочные тросы (цепи)
 на прицепах, не оборудованных автономной тормозной системой. Имеются люфты в соединениях рамы мотоцикла и коляски;</p><br>

<p style="text-indent:2em;">j) отсутствует предусмотренный конструкцией передний или задний бампер, грязезащитные фартуки и брызговики;</p>

<p style="text-indent:2em;"></p><h6>k) отсутствуют:</h6><p></p>

<p style="text-indent:2em;">на автобусе, легковом и грузовом автомобилях, самоходной машине - медицинская аптечка, огнетушитель, знак аварийной остановки 14 (приложение N 6)
 или мигающий фонарь красного цвета;</p><br>

<p style="text-indent:2em;">на грузовых автомобилях с разрешенной максимальной массой более 3,5 т - не менее двух противооткатных упора;</p><br>

<p style="text-indent:2em;">на мотоцикле с боковым прицепом - медицинская аптечка, знак аварийной остановки (мигающий фонарь красного цвета).</p>

<p style="text-indent:2em;"></p><h6>Примечание. Медицинская аптечка и огнетушитель должны быть закреплены в местах, определенных предприятием-изготовителем. Если конструкцией
 транспортного средства эти места не предусмотрены, аптечка и огнетушитель должны находиться в легкодоступных местах, кроме багажника
 легкового автомобиля. В автобусе один огнетушитель должен быть в кабине водителя, второй - в пассажирском салоне;</h6><p></p>

<p style="text-indent:2em;">l) отсутствуют ремни безопасности и подголовники, если их установка предусмотрена конструкцией транспортного средства;</p><br>

<p>m) ремни безопасности в нерабочем состоянии или имеют видимые надрывы;</p><br>

<p style="text-indent:2em;">n) на мотоцикле нет предусмотренных конструкцией дуг безопасности, подножек, поперечных рукояток на седле для пассажира.</p><br>

<p style="font-weight:500;">123. При возникновении в пути неисправностей, указанных в предыдущем пункте, водитель должен принять меры к их устранению, а если это невозможно
 - двигаться к ближайшему месту стоянки или ремонта, соблюдая необходимые меры предосторожности с выполнением требования абзаца с)
 пункта 37 настоящих правил.</p>

<p style="text-indent:2em;"></p><h6>124. Запрещается дальнейшее движение транспортных средств если:</h6><p></p>

<p>а) не действует рабочая тормозная система;</p><br>

<p>b) не действует рулевое управление;</p><br>

<p>с) повреждено тягово-сцепное или опорно-сцепное устройство автопоезда;</p><br>

<p style="text-indent:2em;">d) в темное время суток на дорогах без искусственного освещения или в условиях недостаточной видимости не горят фары и задние габаритные фонари;</p><br>

<p style="text-indent:2em;">е) в дождь или снегопад не действуют стеклоочиститель и стеклоомыватель со стороны водителя и устройство обдува лобового стекла.</p><br>

<p>f) негерметична топливная система</p>

<p style="text-indent:2em;"></p><h6>Примечание. Недействующими считаются рабочая тормозная система или рулевое управление, которые не дают водителю возможности остановить
 транспортное средство или осуществить маневр при движении с минимальной скоростью.</h6><p></p>

<p style="text-indent:2em;"></p><h6>125. Запрещается эксплуатация транспортного средства с последующей доставкой его на штрафную площадку в случае, если:</h6><p></p>

<p>а) оно не зарегистрировано в установленном порядке;</p><br>

<p style="text-indent:2em;">b) номера узлов и агрегатов транспортного средства подделаны или не соответствуют записям в регистрационном документе;</p><br>

<p>с) отсутствуют регистрационные знаки или они не соответствуют записям в регистрационном документе.</p>

<p></p><h4>РАЗДЕЛ V ОБЯЗАННОСТИ СОБСТВЕННИКОВ ТРАНСПОРТА И ДОЛЖНОСТНЫХ ЛИЦ, ОТВЕТСТВЕННЫХ ЗА ЭКСПЛУАТАЦИЮ ТРАНСПОРТНЫХ СРЕДСТВ, ОБЩЕСТВЕННЫХ ДОРОГ,
ЖЕЛЕЗНОДОРОЖНЫХ ПЕРЕЕЗДОВ И ДРУГИХ ДОРОЖНЫХ СООРУЖЕНИЙ ПО ОБЕСПЕЧЕНИЮ БЕЗОПАСНОСТИ ДОРОЖНОГО ДВИЖЕНИЯ</h4><p></p>

<p></p><h4>РАЗДЕЛ V ДОЛЖНОСТНЫЕ ОБЯЗАННОСТИ ЛИЦ, ОТВЕТСТВЕННЫХ ЗА ЭКСПЛУАТАЦИЮ ТРАНСПОРТНЫХ СРЕДСТВ, ОБЩЕСТВЕННЫХ ДОРОГ, ЖЕЛЕЗНОДОРОЖНЫХ ПЕРЕЕЗДОВ И ДРУГИХ
 ДОРОЖНЫХ СООРУЖЕНИЙ ПО ОБЕСПЕЧЕНИЮ БЕЗОПАСНОСТИ ДОРОЖНОГО ДВИЖЕНИЯ</h4><p></p>

<p style="text-indent:2em;"></p><h6>126. (1)Лица, ответственные за эксплуатацию и техническое состояние транспортных средств, обязаны:</h6><p></p>

<p style="text-indent:2em;">а) не допускать к управлению транспортными средствами лиц, не имеющих удостоверения на право управления транспортными средствами соответствующей 
категории, не прошедших медицинского освиде- тельствования, находящихся в состоянии алкогольного, наркотического или иного опьянения, под воздействием
 лекарственных препаратов, снижающих реакцию, в болезненном состоянии, травмированных или находящихся в утомленном состоянии, ставящем под угрозу 
 безопасность движения;</p><br>

<p style="text-indent:2em;">b) не выпускать на линию транспортные средства, техническое состояние которых не соответствует техническим условиям, а также не зарегистрированные
 или не прошедшие технический осмотр;</p><br>

<p style="text-indent:2em;">с) принимать меры, исключающие движение гусеничных самоходных машин по дорогам с усовершенствованным покрытием.</p><br>

<p style="text-indent:2em;">(2) Руководителям станций технического обслуживания или других предприятий по ремонту автотранспортных средств, независимо от формы собственности,
 а также физическим лицам, предоставляющим услуги такого рода, запрещается производить работы по ремонту поврежденных в результате дорожно-транспортных
 происшествий транспортных средств при отсутствии у их владельцев справки о регистрации происшествия, выданной в установленном порядке органами 
 дорожной полиции</p><br>

<p style="text-indent:2em;">(3) Собственники транспортных средств, передающие их в возмездное или безвозмездное пользование другим лицам, обязаны зарегистрировать передачу
 транспорта в Государственном регистре транспорта и обеспечить получение временными пользователями регистрационных документов, выданных на их имя</p>

<p style="text-indent:2em;"></p><h6>127. Лица, ответственные за благоустройство, уход и эксплуатацию общественных дорог, железнодорожных переездов и других дорожных сооружений, обязаны:</h6><p></p>

<p>а) содержать эти сооружения в состоянии, обеспечивающем безопасность дорожного движения;</p><br>

<p style="text-indent:2em;">b) принимать меры для своевременного устранения помех, которые угрожают безопасности движения, или для их обозначения, если немедленное
 устранение этих помех невозможно;</p><br>

<p style="text-indent:2em;">с) ограничивать или даже запрещать движение на участках дорог, когда пользование ими угрожает безопасности движения.</p><br>

<p style="font-weight:500;">128. (1) Лица, ответственные за производство работ на дорогах, обязаны обеспечить безопасность движения в местах их проведения. Эти места
 (зоны), а также не работающие дорожные машины, материалы и другие строительные элементы, которые не могут быть убраны за пределы дороги,
 должны быть обозначены в соответствии с пунктом 31 настоящих правил.</p><br>

<p style="text-indent:2em;">(2) После завершения строительных или ремонтных работ лицо, ответственное за их выполнение, обязано немедленно устранить все препятствия,
 возникшие при этих работах и привести дорогу в состояние, обеспечивающее безопасное движение транспортных средств и пешеходов.</p>

<p style="text-indent:2em;"></p><h6>129. Соответствующие должностные лица обязаны в установленном порядке согласовывать с органами дорожной полиции:</h6><p></p>

<p>а) маршруты и расположение остановочных пунктов маршрутных транспортных средств;</p><br>

<p style="text-indent:2em;">b) установку в непосредственной близости от дороги рекламных щитов, а также строительство в этих местах различных объектов;</p><br>

<p>с) установку или нанесение на общественных дорогах средств дорожной сигнализации;</p><br>

<p style="text-indent:2em;">d) переоборудование транспортных средств, установку на них специальных звуковых и/или световых сигналов, нанесение наклонных белых полос на
 боковые поверхности кузовов транспортных средств, а также каких-либо надписей или опознавательных знаков;</p><br>

<p style="text-indent:2em;">е) перевозку крупногабаритных и тяжеловесных грузов, а также способ буксировки двух и более транспортных средств;</p><br>

<p>f) маршруты движения при перевозке опасных, взрывчатых и легковоспламеняющихся грузов;</p><br>

<p>g) производство любых работ на дорогах.</p>

<p style="text-indent:2em;"></p><h6>Приложение N 1</h6><p></p>

<p>к Правилам дорожного движения</p><br>

<p>СИГНАЛЫ РЕГУЛИРОВЩИКА</p>

<p style="text-indent:2em;"></p><h6>Приложение N 2</h6><p></p>

<p>к Правилам дорожного движения</p><br>

<p>УСТРОЙСТВО СВЕТОВОЙ СИГНАЛИЗАЦИИ</p>

<p style="text-indent:2em;"></p><h6>Приложение N 3</h6><p></p>

<p>к Правилам дорожного движения</p><br>

<p>Значения дорожных знаков и особенности их применения</p><br>

<p></p><h4>I. ПРЕДУПРЕЖДАЮЩИЕ ЗНАКИ</h4><p></p>

<p>1.1 \"Железнодорожный переезд со шлагбаумом\".</p><br>

<p>1.2 \"Железнодорожный переезд без шлагбаума\".</p><br>

<p>1.3.1 \"Однопутная железная дорога без шлагбаума\".</p><br>

<p>1.3.2 \"Многопутная железная дорога без шлагбаума\".</p><br>

<p>1.4.1 - 1.4.6 \"Приближение к железнодорожному переезду вне населенного пункта\".</p><br>

<p>1.5 \"Пересечение с трамвайной линией\".</p><br>

<p>1.6 \"Пересечение равнозначных дорог\".</p><br>

<p>1.7.1 - 1.7.3 \"Пересечение со второстепенной дорогой\".</p><br>

<p>1.8 \"Пересечение с круговым движением\".</p><br>

<p style="text-indent:2em;">1.9 \"Светофор\" - перекресток, пешеходный переход или участок дороги, на которых движение регулируется сигналами светофора.</p><br>

<p>1.10 \"Разводной мост или паром\".</p><br>

<p>1.11 \"Выезд на набережную\".</p><br>

<p>1.12.1, 1.12.2 \"Опасный поворот\" - закругление дороги малого радиуса или с ограниченной видимостью.</p><br>

<p>1.13.1, 1.13.2 \"Опасные повороты\" - соответственно, первый направо; первый налево.</p><br>

<p>1.14.1, 1.14.2 \"Крутой спуск\"; \"Крутой подъем\".</p><br>

<p>1.15 \"Скользкая дорога\" - участок дороги с повышенной скользкостью проезжей части.</p><br>

<p>1.16 \"Неровная дорога\" - участок поврежденной дороги (выбоины, волнистость проезжей части и т. п.).</p><br>

<p style="text-indent:2em;">1.17 \"Выброс гравия\" - участок дороги, на котором возможен выброс гравия или щебня из-под колес транспортных средств.</p><br>

<p style="text-indent:2em;">1.18.1 - 1.18.3 \"Сужение дороги\" - соответственно: 1.18.1 - сужение с обеих сторон; 1.18.2 - сужение справа; 1.18.3 - сужение слева.</p><br>

<p>1.19 \"Двустороннее движение\" - участок дороги, на котором движение осуществляется в обоих направлениях.</p><br>

<p style="text-indent:2em;">1.20 \"Пешеходный переход\" - участок дороги, обозначенный знаками 5.40.1, 5.40.2 и/или разметкой 1.14.1 - 1.14.3.</p><br>

<p style="text-indent:2em;">1.21 \"Дети\" - участок дороги вблизи школ, дошкольных учреждений и т. п., на проезжей части которого возможно появление детей.</p><br>

<p>1.22 \"Велосипедисты\" - место пересечения с велосипедной дорожкой вне перекрестка.</p><br>

<p>1.23 \"Дорожные работы\".</p><br>

<p style="text-indent:2em;">Знаки 1.21 и 1.23 повторяются и в населенных пунктах на участках дорог, на которых допустимая максимальная скорость движения превышает 60 км/ч</p><br>

<p>1.24.1, 1.24.2 \"Животные\" - участок дороги, на котором возможно появление животных.</p><br>

<p>1.25 \"Падение камней\" - участок дороги, на котором возможны обвалы, оползни или падение камней.</p><br>

<p style="text-indent:2em;">1.26 \"Боковой ветер\" - участок дороги, проходящий по высоким насыпям, мостам, путепроводам, вдоль обрывистых берегов водоемов и т. д.,
 на котором возможен сильный ветер или его внезапные порывы.</p><br>

<p>1.27 \"Аэродром\" - участок дороги, над которым на небольшой высоте пролетают самолеты или вертолеты.</p><br>

<p style="text-indent:2em;">1.28 \"Тоннель\" - инженерное сооружение без искусственного освещения или с ограниченной видимостью въездного портала.</p><br>

<p style="text-indent:2em;">1.29 \"Прочие опасности\" - участок дороги, на котором возможна опасность, не предусмотренная другими предупреждающими знаками.</p><br>

<p style="text-indent:2em;">1.30 \"Авария\" - появление на дороге препятствия или ситуации (дорожно-транспортное происшествие), которые могут затруднить или сделать невозможным движение.</p><br>

<p style="text-indent:2em;">1.31.1, 1.31.2 \"Направление опасного поворота\" - направление поворота на закруглениях, обозначенных знаками 1.12.1 - 1.13.2, или объезд ремонтируемого участка дороги.</p><br>

<p style="text-indent:2em;">1.32 \"Направление опасных поворотов налево и направо\" - направление движения на Т-образном перекрестке, разветвлении дорог или направление
 объезда ремонтируемого участка дороги.</p><br>

<p>1.33.1, 1.33.2 "Особо опасный поворот"</p><br>

<p>Особенности применения предупреждающих знаков</p><br>

<p style="text-indent:2em;">Расстояние от места установки предупреждающих знаков до начала опасного участка дороги зависит в основном от допустимого максимального предела
 скорости на общественных дорогах. На участках дорог, на которых допустимый максимальный предел скорости превышает 60 км/ч как в населенных пунктах,
 так и вне их, предупреждающие знаки, за исключением знаков 1.3.1 - 1.4.6 и 1.31.1 - 1.33.2, устанавливаются на расстоянии 150-300 м, на других участках
 дорог в населенных пунктах - на расстоянии 50-100 м. При необходимости предупреждающие знаки могут устанавливаться на другом расстоянии с использованием
 в этих случаях дополнительной таблички 6.1.1</p><br>

<p>Знаки 1.3.1, 1.3.2 - устанавливаются непосредственно перед железнодорожным переездом.</p><br>

<p style="text-indent:2em;">Знаки 1.4.1 - 1.4.3 устанавливаются на правой стороне дороги, а знаки 1.4.4 - 1.4.6 - на левой. Знаки 1.4.1 и 1.4.4 под первым знаком 1.1 или 1.2,
 а знаки 1.4.3 и 1.4.6. под вторым знаком 1.1 или 1.2 по ходу движения; знаки 1.4.2 и 1.4.5 устанавливаются самостоятельно на равном расстоянии, 
 между знаками 1.1 или 1.2.</p><br>

<p style="text-indent:2em;">Конфигурация пересечения или примыкания, изображенная на знаках 1.7.1 - 1.7.3, должна соответствовать реальной конфигурации перекрестка.</p><br>

<p>Знак 1.9 устанавливается в местах, где видимость сигналов светофора ограничена для участников движения.</p><br>

<p style="text-indent:2em;">Знаки 1.14.1, 1.14.2 могут устанавливаться без таблички 6.1.1 непосредственно перед началом спуска или подъема, если спуски и подъемы следуют друг за другом.</p><br>

<p style="text-indent:2em;">Знак 1.19 устанавливается на дороге (проезжей части) с односторонним движением перед участками дороги (проезжей части) со встречным движением.</p><br>

<p style="text-indent:2em;">Знак 1.23 при проведении краткосрочных работ на дороге может быть установлен без таблички 6.1.1 на расстоянии 10-15 м до места проведения работ.</p><br>

<p style="text-indent:2em;">Вне населенных пунктов знаки 1.1, 1.2, 1.10, 1.11, 1.21 и 1.23 повторяются. Следующий знак устанавливается на расстоянии не менее 50 м до начала
 опасного участка дороги.</p><br>

<p style="text-indent:2em;">Знаки 1.21 и 1.23 повторяются и в населенных пунктах на участках дорог, на которых допустимая максимальная скорость движения превышает 60 км/ч</p><br>

<p style="text-indent:2em;">Знаки 1.33.1, 1.33.2 устанавливаются последовательно через каждые 20-50 м по всей длине внешней стороны особо опасных поворотов</p>

<p></p><h4>II. ЗНАКИ ПРИОРИТЕТА</h4><p></p>

<p style="text-indent:2em;">2.1 Уступи дорогу" - водитель должен уступить дорогу транспортным средствам, приближающимся к перекрестку по пересекаемой дороге, а при наличии 
таблички 6.15 - тем транспортным средствам, которые приближаются к перекрестку по главной дороге. При необходимости он должен остановиться перед 
разметкой 1.13, а при ее отсутствии - перед перекрестком.</p><br>

<p style="text-indent:2em;">2.2 \"Проезд без остановки запрещен\" - запрещается движение без остановки перед разметкой 1.12, а при ее отсутствии - перед краем пересекаемой
 проезжей части. Водитель должен уступить дорогу транспортным средствам, приближающимся к перекрестку по пересекаемой дороге, а при наличии 
 таблички 6.15 - тем транспортным средствам, которые приближаются к перекрестку по главной дороге.</p><br>

<p style="text-indent:2em;">2.3 \"Главная дорога\" - предоставляет водителю право преимущественного проезда нерегулируемых перекрестков.</p><br>

<p>2.4 \"Конец главной дороги\".</p><br>

<p style="text-indent:2em;">2.5 \"Преимущество встречного движения\" - запрещается въезд на узкий участок дороги, на котором встречный разъезд затруднен или невозможен. 
Водитель должен уступить дорогу встречным транспортным средствам, находящимся на узком участке или противоположном подъезде к нему.</p><br>

<p style="text-indent:2em;">2.6 \"Преимущество перед встречным движением\". Водители пользуются преимуществом по отношению к встречным транспортным средствам при движении
 по узкому участку дороги.</p><br>

<p>Особенности применения знаков приоритета</p><br>

<p style="text-indent:2em;">Знаки 2.1, 2.2 устанавливаются непосредственно перед перекрестком, а знаки 2.5 и 2.6 - непосредственно перед узким участком дороги.</p><br>

<p style="text-indent:2em;">Знаки 2.3 и 2.4 устанавливаются в начале и в конце главной дороги. Знак 2.3 может повторяться перед перекрестками.</p><br>

<p style="text-indent:2em;">Вне населенных пунктов на дорогах с усовершенствованным покрытием знакам 2.1 и 2.2 предшествует знак 2.1, соответственно с табличками</p><br>

<p style="text-indent:2em;">Знак 2.2 может быть установлен перед железнодорожным переездом без шлагбаума. В этом случае водитель транспортного средства обязан остановиться
 перед разметкой 1.12, а при ее отсутствии - перед знаком.</p>

<p></p><h4>III. ЗАПРЕЩАЮЩИЕ ИЛИ ОГРАНИЧИВАЮЩИЕ ЗНАКИ</h4><p></p>

<p>3.1 \"Въезд запрещен\" - запрещается въезд всех транспортных средств.</p><br>

<p>3.2 \"Движение запрещено\" - запрещается движение всех транспортных средств</p><br>

<p>3.3 \"Движение механических транспортных средств запрещено\".</p><br>

<p style="text-indent:2em;">3.4 \"Движение грузовых автомобилей зарпрещено\" - запрещается движение грузовых автомобилей и составов транспортных средств, с разрешенной максимальной
 массой более 3,5 т (если на знаке не указана масса) или превышающей указанную на знаке, а также самоходных машин.</p><br>

<p>3.5 \"Движение мотоциклов запрещено\".</p><br>

<p>3.6 \"Движение самоходных машин запрещено\".</p><br>

<p style="text-indent:2em;">3.7 \"Движение автопоездов запрещено\" - запрещается движение грузовых автомобилей и самоходных машин с прицепами любого типа, а также буксировка
 механических транспортных средств.</p><br>

<p>3.8 \"Движение транспортных средств с взрывчатыми и легковоспламеняющимися грузами запрещено\".</p><br>

<p>3.9 \"Движение транспортных средств с опасными грузами запрещено\".</p><br>

<p style="text-indent:2em;">3.10 \"Движение гужевого транспорта запрещено\" - запрещаестя движение гужевых повозок (саней), верховых и вьючных животных, а также прогон скота.</p><br>

<p>3.11 \"Движение на велосипедах запрещено\" - запрещается движение велосипедов и мопедов.</p><br>

<p>3.12 \"Движение транспортных средств, передвигаемых вручную, запрещено\".</p><br>

<p>3.13 \"Движение пешеходов запрещено\".</p><br>

<p style="text-indent:2em;">3.14 \"Ограничение массы\" - запрещается движение транспортных средств, в том числе составов транспортных средств, общая фактическая масса которых
 больше указанной на знаке.</p><br>

<p style="text-indent:2em;">3.15 \"Ограничение нагрузки на ось\" - запрещается движение транспортных средств, у которых фактическая нагрузка на какую-либо ось больше указанной 
на знаке.</p><br>

<p style="text-indent:2em;">3.16 \"Ограничение высоты\" - запрещается движение транспортных средств, габаритная высота которых от проезжей части (с грузом или без груза) больше
 указанной на знаке.</p><br>

<p style="text-indent:2em;">3.17 \"Ограничение ширины\" - запрещается движение транспортных средств, габаритная ширина которых (с грузом или без груза) больше указанной на знаке.</p><br>

<p style="text-indent:2em;">3.18 \"Ограничение длины\" - запрещается движение транспортных средств, в том числе составов транспортных средств, габаритная длина которых (с грузом
 или без груза) больше указанной на знаке.</p><br>

<p>3.19.1, 3.19.2 \"Таможня\", \"Полиция\" - запрещается движение без остановки у этих знаков.</p><br>

<p>3.20.1 \"Поворот направо запрещен\".</p><br>

<p>3.20.2 \"Поворот налево запрещен\".</p><br>

<p>3.21 \"Разворот запрещен\".</p><br>

<p style="text-indent:2em;">3.22 \"Ограничение минимальной дистанции\" - запрещается движение транспортных средств с дистанцией между ними меньше указанной на знаке.</p><br>

<p style="text-indent:2em;">3.23 \"Обгон запрещен\" - запрещается обгон всех транспортных средств, кроме одиночных, движущихся со скоростью менее 30 км/ч.</p><br>

<p>3.24 \"Конец зоны запрещения обгона\".</p><br>

<p style="text-indent:2em;">3.25 \"Обгон грузовым автомобилям запрещен\" - запрещается грузовым автомобилям с разрешенной максимальной массой более 3,5 т обгон всех транспортных средств,
 кроме одиночных, движущихся со скоростью менее 30 км/ч. Самоходным машинам запрещается обгон всех транспортных средств, кроме гужевых повозок и велосипедов.</p><br>

<p>3.26 \"Конец зоны запрещения обгона грузовым автомобилям\".</p><br>

<p style="text-indent:2em;">3.27 \"Ограничение максимальной скорости\" - запрещается движение транспортных средств со скоростью, превышающей указанную на знаке.</p><br>

<p>3.28 \"Конец зоны ограничения максимальной скорости\".</p><br>

<p>3.29 \"Ограничение максимальной скорости по категориям транспортных средств\".</p><br>

<p style="text-indent:2em;">3.30 \"Подача звукового сигнала запрещена\" - запрещается пользоваться звуковым сигналом, кроме тех случаев, когда сигнал подается для предотвращения 
дорожно-транспортного происшествия.</p><br>

<p style="text-indent:2em;">3.31 \"Остановка запрещена\" - запрещаестя остановка и стоянка транспортных средств, кроме такси, на время, необходимое для посадки либо высадки пассажиров.</p><br>

<p>3.32.1 \"Стоянка запрещена\" - запрещается стоянка транспортных средств.</p><br>

<p>3.32.2 \"Стоянка запрещена по нечетным числам месяца\".</p><br>

<p>3.32.3 \"Стоянка запрещена по четным числам месяца\".</p><br>

<p style="text-indent:2em;">3.32.4 \"Зарезервированная стоянка\" - разрешается стоянка только транспортным средствам, принадлежащим соответствующим предприятиям или организациям, 
а также их сотрудникам.</p><br>

<p style="text-indent:2em;">3.33 \"Конец всех ограничений\" - обозначение конца зоны действия одновременно нескольких знаков из следующих: 3.22, 3.23, 3.25, 3.27, 3.29 - 3.32.4.</p><br>

<p>Особенности применения запрещающих или ограничивающих знаков</p><br>

<p>Знаки 3.2 - 3.11 запрещают движение соответствующих видов транспортных средств в обоих направлениях</p>

<p style="text-indent:2em;"></p><h6>Действие знаков не распространяется:</h6><p></p>

<p>3.1 - 3.3, 3.20.1 - 3.21, 3.31 - на маршрутные транспортные средства;</p><br>

<p>3.2-3.3, 3.31, 3.32.1 - на транспортные средства, управляемые инвалидами I и II группы</p><br>

<p style="text-indent:2em;">3.2 - 3.7 и 3.10 - на транспортные средства, которые обслуживают предприятия, находящиеся в обозначенной зоне, а также обслуживают граждан или принадлежат 
гражданам, проживающим или работающим в этой зоне. В этих случаях транспортные средства должны въезжать в обозначенную зону и выезжать из нее на ближайшем 
к месту назначения перекрестке;</p><br>

<p style="text-indent:2em;">3.4 - на грузовые автомобили с наклонной белой полосой на внешней боковой поверхности кузова или предназначенные для перевозки людей;</p><br>

<p>3.31 - на такси, если остановка необходима для посадки или высадки пассажиров;</p><br>

<p>3.32.1 - 3.32.4 - на такси с включенным таксометром;</p><br>

<p>3.31, 3.32.1 - 3.32.4 - на транспортные средства, управляемые инвалидами.</p><br>

<p style="text-indent:2em;">Действие знаков 3.20.1 - 3.21 распространяется только на проезжую часть и другие места, перед которыми они установлены.</p><br>

<p style="text-indent:2em;">Зона действия знаков 3.22, 3.23, 3.25, 3.27, 3.29 - 3.32.4 распространяется от места установки знака до ближайшего перекрестка за ним, а в населенных пунктах
 - при отсутствии перекрестка - до конца населенного пункта. Действие знаков не прерывается в местах выезда с прилегающих к дороге территорий и в местах 
 пересечения (примыкания) с грунтовыми дорогами, перед которыми не установлены знаки приоритета.</p><br>

<p style="text-indent:2em;">Действие знаков 3.12, 3.13, 3.31 - 3.32.4 распространяется только на ту сторону дороги, на которой они установлены.</p><br>

<p style="text-indent:2em;">Действие знаков 3.27 и 3.29, установленных перед населенным пунктом, обозначенным знаком 5.49, распространяется до этого знака.</p><br>

<p style="text-indent:2em;">При одновременном применении знаков 3.32.2 и 3.32.3 время перестановки транспортных средств с одной стороны на другую разрешается с 19.00.</p>

<p style="text-indent:2em;"></p><h6>Зона действия знаков может быть уменьшена:</h6><p></p>

<p>для знаков 3.22, 3.23, 3.25, 3.27, 3.29 и 3.30 применением дополнительной таблички 6.3;</p><br>

<p style="text-indent:2em;">для знаков 3.23, 3.25, 3.27 установкой в конце зоны их действия знаков 3.24, 3.26, 3.28. Зона действия знаков 3.27 и 3.29 может быть уменьшена установкой
 знаков 3.27 и 3.29 с другим значением максимальной скорости;</p><br>

<p style="text-indent:2em;">для знаков 3.31, 3.32.1 - 3.32.4 установкой таблички 6.4.1 или установкой в конце зоны их действия повторных знаков 3.31, 3.32.1 - 3.32.4 с дополнительной 
табличкой 6.4.3.</p><br>

<p style="text-indent:2em;">Знак 3.31 может быть применен совместно с разметкой 1.4, а знаки 3.32.1 - 3.32.4 - с разметкой 1.10, при этом зона действия знаков определяется 
протяженностью линии разметки.</p>

<p></p><h4>IV. ПРЕДПИСЫВАЮЩИЕ ЗНАКИ</h4><p></p>

<p style="text-indent:2em;"></p><h6>4.1.1 - 4.1.6 \"Обязательное направление движения\", соответственно:</h6><p></p>

<p style="text-indent:2em;">4.1.1 - прямо; 4.1.2 - направо; 4.1.3 - налево; 4.1.4 - прямо или направо; 4.1.5 - прямо или налево; 4.1.6 - направо или налево.</p><br>

<p>Знаки 4.1.3, 4.1.5 и 4.1.6 разрешают и разворот.</p><br>

<p>Действие знаков 4.1.1 - 4.1.6 не распространяется на маршрутные транспортные средства.</p><br>

<p style="text-indent:2em;">Действие знаков 4.1.1 - 4.1.6 распространяется на пересечение проезжих частей, перед которым они устанавливаются.</p><br>

<p style="text-indent:2em;">Действие знака 4.1.1, установленного в начале участка дороги, распространяется до ближайшего перекрестка, знак не запрещает поворот направо на 
прилегающие к дороге территории.</p>

<p style="text-indent:2em;"></p><h6>4.2.1 - 4.2.3 \"Обязательное направление объезда препятствия\", соответственно:</h6><p></p>

<p>4.2.1 - справа;</p><br>

<p>4.2.2 - слева;</p><br>

<p>4.2.3 - справа или слева.</p><br>

<p>4.3 \"Круговое движение\" - разрешается движение только в указанном стрелками направлении.</p><br>

<p style="text-indent:2em;">4.4 \"Движение легковых автомобилей\" - разрешается движение легковых автомобилей, автобусов, маршрутных транспортных средств, а также грузовых 
автомобилей, разрешенная максимальная масса которых не превышает 3,5 т. Знак разрешает движение и других транспортных средств, обслуживающих 
предприятия, находящиеся в обозначенной зоне, а также обслуживающих граждан или принадлежащих гражданам, проживающим или работающим в обозначенной
 зоне. В этих случаях транспортные средства должны въезжать в обозначенную зону и выезжать из нее на ближайшем к месту назначения перекрестке.</p><br>

<p style="text-indent:2em;">4.5 \"Велосипедная дорожка\" - разрешается движение только на велосипедах и мопедах. По велосипедной дорожке при отсутствии тротуара или пешеходной
 дорожки могут двигаться также и пешеходы.</p><br>

<p>4.6 \"Пешеходная дорожка\" - разрешается движение только пешеходам.</p><br>

<p style="text-indent:2em;">4.7 Ограничение минимальной скорости - разрешается движение с указанной на знаке или большей скоростью, при этом не должны превышаться ограничения,
 установленные пунктом 47 настоящих правил.</p><br>

<p>4.8 Конец зоны ограничения минимальной скорости.</p>

<p></p><h4>V. ИНФОРМАЦИОННО-УКАЗАТЕЛЬНЫЕ ЗНАКИ</h4><p></p>

<p>а) Информационные</p><br>

<p style="text-indent:2em;">5.1 \"Общее ограничение скорости\" - предоставляет информацию об ограничениях скорости движения в населенных пунктах, вне населенных пунктов, 
а также на автомагистралях. Знак устанавливается при въезде в страну.</p><br>

<p style="text-indent:2em;">5.2 \"Автомагистраль\" - дорога, на которой действуют специальные требования, установленные настоящими правилами.</p><br>

<p>5.3 \"Конец автомагистрали\".</p><br>

<p style="text-indent:2em;">5.4 \"Дорога для автомобилей\" - дорога, предназначенная для движения только автомобилей, автобусов и мотоциклов.</p><br>

<p>5.5 \"Конец дороги для автомобилей\".</p><br>

<p style="text-indent:2em;">5.6 и 5.7 \"Остановка маршрутных транспортных средств\" - указывают место остановки маршрутных транспортных средств, соответственно автобусов, 
троллейбусов и трамваев.</p><br>

<p>5.8 \"Место стоянки такси\".</p><br>

<p>5.9 \"Пункт медицинской помощи\".</p><br>

<p>5.10 \"Больница\".</p><br>

<p>5.11 \"Автозаправочная станция\".</p><br>

<p>5.12 \"Автосервис\".</p><br>

<p>5.13 "Мойка\".</p><br>

<p>5.14 \"Телефон\".</p><br>

<p>5.15 \"Ресторан\".</p><br>

<p>5.16 \"Питьевая вода\".</p><br>

<p>5.17 \"Гостиница или мотель\".</p><br>

<p>5.18 \"Кемпинг\".</p><br>

<p>5.19 \"Место отдыха\".</p><br>

<p>5.20 \"Полиция\".</p><br>

<p>5.21 \"Дорожная полиция\".</p><br>

<p>5.22 \"Туалет\".</p><br>

<p>5.23 \"Пляж или плавательный бассейн\".</p><br>

<p>5.24 \"Пост туристической информации\".</p><br>

<p style="text-indent:2em;">5.25 \"Дорога с односторонним движением\" - дорога или проезжая часть, по которой движение транспортных средств по всей ширине
 осуществляется только в одном направлении.</p><br>

<p>5.26 \"Конец дороги с односторонним движением\".</p><br>

<p style="text-indent:2em;">5.27 \"Выезд на дорогу с односторонним движением\" - выезд на дорогу или проезжую часть, по которой движение транспортных средств 
разрешается только в направлении, указанном стрелкой.</p><br>

<p style="text-indent:2em;">5.28 \"Направление движения по полосам\" - количество полос и разрешенные направления движения на каждой из них в пределах перекрестка.</p><br>

<p style="text-indent:2em;">5.29.1- 5.29.4 "Направление движения по полосе". Знаки 5.28, 5.29.1 и их варианты, разрешающие поворот налево из крайней левой полосы,
 разрешают и разворот.</p><br>

<p style="text-indent:2em;">5.30.1 - 5.30.3 \"Дополнительная полоса\" - соответственно начало полосы торможения или дополнительной полосы на подъеме. Если на знаке</p><br>

<p style="text-indent:2em;">5.30.2 изображен знак 4.7, то водитель транспортного средства, который не может продолжать движение по основной полосе со скоростью, 
указанной на знаке, должен перестроиться на дополнительную полосу.</p><br>

<p style="text-indent:2em;">Знак 5.30.3 - определяет начало участка средней полосы трехполосной проезжей части или полосы торможения, предназначенной для поворота 
налево или разворота.</p><br>

<p style="text-indent:2em;">5.31.1 и 5.31.2 \"Конец полосы\". Знак 5.31.1 обозначает конец дополнительной полосы на подъеме или полосы разгона, а знак 5.31.2 - 
конец участка средней полосы трехполосной проезжей части, предназ- наченного для движения в данном направлении.</p><br>

<p style="text-indent:2em;">5.32.1 - 5.32.3 \"Направление движения по полосам\" - определяет направление движения по полосам на трехполосной проезжей части. Если
 на фоне знака 5.32.2 изображен знак, запрещающий движение каким-либо транспортным средствам, то движение этих транспортных средств по
 соответствующей полосе запрещается.</p><br>

<p style="text-indent:2em;">5.33 \"Полоса для маршрутных транспортных средств\" - полоса, предназначенная для движения только маршрутных транспортных средств, 
движущихся попутно общему потоку транспорта. Этот знак может быть установлен над полосой или у правого края дороги. Если эта полоса 
расположена справа и не отделена от остальной проезжей части разметкой 1.2, транспортные средства, которые осуществляют поворот направо
 для выезда с этой дороги, должны перестраиваться на эту полосу. Таким же образом должны поступать водители транспортных средств, которые
 въезжают на эту дорогу с поворотом направо. Разрешается также в местах, в которых эта полоса отделена прерывистой разметкой, заезжать на
 нее для остановки с целью посадки или высадки пассажиров, не создавая при этом помех маршрутным транспортным средствам.</p><br>

<p style="text-indent:2em;">5.34 \"Дорога с полосой для маршрутных транспортных средств\" - дорога, по которой движение маршрутных транспортных средств осуществляется
 по специально выделенной полосе навстречу общему потоку транспорта. Движение других транспортных средств по этой полосе а также остановка
 на ней запрещаются.</p><br>

<p>5.35 \"Конец дороги с полосой для маршрутных транспортных средств\".</p><br>

<p style="text-indent:2em;">5.36.1, 5.36.2 \"Выезд на дорогу с полосой для маршрутных транспортных средств\" - соответственно направо, налево.</p><br>

<p style="text-indent:2em;">5.37 \"Место разворота\" - указывает место, где разрешен разворот транспортных средств. Поворот налево запрещается.</p><br>

<p style="text-indent:2em;">5.38 \"Зона для разворота\" - определяет протяженность зоны для разворота транспортных средств. Поворот налево запрещается.</p><br>

<p>5.39 \"Место стоянки\".</p><br>

<p style="text-indent:2em;">5.40.1, 5.40.2 \"Пешеходный переход\" - при отсутствии на переходе разметки 1.14.1 - 1.14.3, знак 5.40.1 устанавливается на правой стороне
 дороги на ближней границе перехода, а знак 5.40.2 - на левой стороне дороги на дальней границе перехода.</p><br>

<p>5.41.1 - 5.41.4 - \"Пешеходный переход на разных уровнях\" - соответственно подземный или надземный.</p><br>

<p style="text-indent:2em;">5.42 \"Рекомендуемая скорость\" - скорость, с которой рекомендуется движение на данном участке дороги. Зона действия знака распространяется
 до ближайшего перекрестка. Отклонение от рекомендуемой скорости не считается нарушением настоящих правил.</p><br>

<p>5.43.1, 5.43.3 \"Дорога, не имеющая сквозного проезда\".</p><br>

<p>b) Указательные</p><br>

<p style="text-indent:2em;">5.44.1 - 5.44.4 \"Предварительный указатель направлений\" - на знаках могут быть нанесены изображения запрещающих или информационно- указательных
 знаков, символ аэропорта, спортивные и иные пиктограммы и т. п.</p><br>

<p style="text-indent:2em;">В нижней части знаков 5.44.1 - 5.44.4 указывается расстояние от места установки знака до перекрестка или начала полосы торможения.</p><br>

<p style="text-indent:2em;">5.45 \"Схема объезда\" - указывает направление объезда участков дорог, перед которыми установлен один из запрещающих знаков 3.14 - 3.18.</p><br>

<p style="text-indent:2em;">5.46 \"Схема для изменения направления движения\" - определяет маршрут движения при запрещении на перекрестке отдельных маневров или разрешенные
 направления движения на сложном перекрестке.</p><br>

<p>5.47.1, 5.47.2 \"Направление движения к населенным пунктам или другим объектам\".</p><br>

<p style="text-indent:2em;">Синий фон знаков 5.44.1 - 5.44.4, 5.45 и 5.47.2, установленных в населенном пункте, означает, что после выезда из данного населенного пункта движение
 к указанным на знаке населенным пунктам или объектам осуществляется по магистральной или республиканской дороге; белый фон означает, что объект (объекты)
 находится (находятся) в данном населенном пункте. Изображение знака 5.47.2 на фоне знака 5.44.3 означает, что указанный объект находится вне данного 
 населенного пункта.</p><br>

<p style="text-indent:2em;">5.48 \"Указатель расстояний к указанным населенным пунктам\" - информирует о расстоянии в километрах до населенных пунктов, расположенных на маршруте.</p><br>

<p style="text-indent:2em;">5.49 \"Въезд в населенный пункт\" - наименование и начало населенного пункта, в котором действуют требования правил, устанавливающие порядок движения
 в населенных пунктах.</p><br>

<p>5.50 \"Выезд из населенного пункта\".</p><br>

<p style="text-indent:2em;">5.51 \"Въезд в населенный пункт\" - наименование и начало населенного пункта, в котором не действуют требования правил, устанавливающие порядок 
движения в населенных пунктах.</p><br>

<p>5.52 \"Выезд из населенного пункта\".</p><br>

<p>5.53 \"Наименование реки или другого объекта\".</p><br>

<p>5.54 \"Наименование улицы\".</p><br>

<p>5.55 \"Направление к улице\".</p><br>

<p style="text-indent:2em;">5.56 \"Километровый знак\" - расстояние в километрах до начала или конца дороги. Могут устанавливаться бетонные тумбы, на которых указывается 
расстояние до населенных пунктов, расположенных по маршруту.</p><br>

<p style="text-indent:2em;">5.57.1 - 5.57.5 \"Номер маршрута\" - знаки 5.57.1 и 5.57.2 указывают номер дороги (маршрута) 5.57.2 обозначает номер дороги европейского значения,
 зеленый фон знака указывает, что эта дорога является автомагистралью, а 5.57.3 - 5.57.5 - номер и направление дороги.</p><br>

<p style="text-indent:2em;">5.58.1 - 5.58.3 \"Рекомендуемое направление движения грузовым автомобилям\" - рекомендуемое направление движения для грузовых автомобилей и самоходных машин,
 если на перекрестке их движение в одном из направлений запрещено.</p><br>

<p>5.59 \"Временная схема объезда\" - маршрут объезда участка дороги, временно закрытого для движения.</p><br>

<p style="text-indent:2em;">5.60.1 - 5.60.3 \"Направление объезда\" - направление объезда участка дороги, временно закрытого для движения.</p><br>

<p style="text-indent:2em;">5.61 Место для остановки - указывает место остановки транспортных средств при запрещающем сигнале светофора или регулировщика.</p><br>

<p>5.62 \"Предупреждение, запрещение или ограничение на пересекаемой дороге\".</p><br>

<p style="text-indent:2em;">5.63 \"Реверсивное движение\" - указывает начало участка дороги, на котором на одной или нескольких полосах направление движения может изменяться 
на противоположное.</p><br>

<p>5.64 \"Конец реверсивного движения\".</p><br>

<p>5.65 \"Выезд на дорогу с реверсивным движением\".</p><br>

<p style="text-indent:2em;">5.66 \"Жилая зона\" - указывает начало части населенного пункта, где движение осуществляется в соответствии с требованиями главы XI раздела IV 
настоящих правил.</p><br>

<p>5.67 \"Конец жилой зоны\".</p>

<p></p><h4>VI. ДОПОЛНИТЕЛЬНЫЕ ТАБЛИЧКИ</h4><p></p>

<p style="text-indent:2em;">6.1.1, 6.1.2 \"Расстояние до места начала действия знака\": 6.1.1 - указывает расстояние от знака до начала опасного участка, места введения соответствующего
 запрещения или ограничения, а также расстояние до определенного объекта, находящегося впереди по ходу движения; 6.1.2 - указывает расстояние от знака 2.1 
 до перекрестка в случае, если непосредственно перед перекрестком установлен знак 2.2.</p><br>

<p style="text-indent:2em;">6.2.1, 6.2.2 \"Направление и расстояние\" - указывает направление и расстояние до объекта, находящегося в стороне от дороги.</p><br>

<p style="text-indent:2em;">6.3 \"Зона действия\" - определяет протяженность опасного участка дороги, обозначенного предупреждающими знаками, или зону действия запрещающих и 
информационно-указательных знаков.</p><br>

<p style="text-indent:2em;">6.4.1 - 6.4.5 \"Зона действия\" - соответственно 6.4.1 - уменьшает; 6.4.2 - подтверждает; 6.4.3 - прекращает зону действия знаков 3.31 - 3.32.4; 6.4.4 и 6.4.5
 указывают направление и зону действия знаков 3.31, 3.32 и 3.32.4 при запрещении остановки или стоянки вдоль фасада здания, стороны площади и т. п.</p><br>

<p style="text-indent:2em;">6.5.1 - 6.5.3 \"Направление действия\" - указывают направление действия некоторых запрещающих и предписывающих знаков, установленных перед перекрестком, 
или направление движения к обозначенным объектам, находящимся непосредственно у дороги.</p><br>

<p style="text-indent:2em;">6.6.1 - 6.6.8 \"Вид транспортного средства\" - указывают вид транспортного средства, на который распространяется действие знака. Табличка 6.6.1 
распространяет действие знака на легковые автомобили, а также на грузовые автомобили с разрешенной максимальной массой до 3,5 т, табличка 6.6.2
 - на грузовые автомобили, в том числе и составы транспортных средств с разрешенной максимальной массой более 3,5 т а также на самоходные машины,
 табличка 6.6.8 - на транспортные средства, обозначенные опознавательным знаком \"Опасный груз\".</p><br>

<p>6.7.1 \"Выходные или праздничные дни\";</p><br>

<p>6.7.2 \"Рабочие дни\";</p><br>

<p>6.7.3 \"Дни недели\" - указывают дни недели, в течение которых действует знак.</p><br>

<p>6.7.4 - 6.7.7 \"Время действия\" - указывают время суток и дни недели, в течение которых действует знак.</p><br>

<p style="text-indent:2em;">6.8.1 - 6.8.9 \"Способ постановки транспортного средства на стоянку\" - табличка 6.8.1 указывает, что всем транспортным средствам разрешается
 стоянка на проезжей части вдоль тротуара, 6.8.2 - 6.8.9 указывают способ постановки легковых автомобилей и мотоциклов на околотротуарной стоянке.</p><br>

<p style="text-indent:2em;">6.9 \"Стоянка с неработающим двигателем\" - указывает, что разрешается стоянка транспортных средств только с неработающим двигателем.</p><br>

<p>6.10 \"Платные услуги\".</p><br>

<p style="text-indent:2em;">6.11 \"Ограничение продолжительности стоянки\" - указывает максимальную продолжительность пребывания транспортного средства на стоянке,
 обозначенной знаком 5.39.</p><br>

<p style="text-indent:2em;">6.12 \"Место для осмотра автомобилей\" - указывает, что на площадке, обозначенной знаком 5.19 или 5.39, имеется эстакада или смотровая канава.</p><br>

<p style="text-indent:2em;">6.13 \"Ограничение максимальной массы\" - указывает, что действие знака распространяется только на транспортные средства с разрешенной
 максимальной массой более указанной на табличке.</p><br>

<p style="text-indent:2em;">6.14 \"Опасная обочина\" - предупреждает, что съезд на обочину опасен в связи с проведением на ней ремонтных работ. Применяется со знаками 1.23, 1.29.</p><br>

<p>6.15.1 \"Направление главной дороги\" - указывает направление главной дороги на перекрестке.</p><br>

<p>6.15.2 "Направление главной дороги"</p><br>

<p style="text-indent:2em;">6.16 \"Полоса движения\" - указывает полосу движения, на которую распространяется действие знака или светофора.</p><br>

<p style="text-indent:2em;">6.17 \"Слепые пешеходы\" - предупреждает, что пешеходным переходом пользуются слепые. Применяется со знаками 1.20, 5.40.1, 5.40.2.</p><br>

<p style="text-indent:2em;">6.18 \"Влажное покрытие\" - указывает, что действие знака распространяется на период времени, когда покрытие проезжей части влажное.</p><br>

<p style="text-indent:2em;">6.19 \"Место стоянки инвалидов\" - указывает, что действие знака 5.39 распространяется только на автомобили, на которых установлен
 опознавательный знак 8, приложение N 6.</p><br>

<p style="text-indent:2em;">6.20 \"Кроме инвалидов\" - указывает, что действие знака не распространяется на автомобили, на которых установлен опознавательный
 знак 8 из приложения N 6.</p><br>

<p style="text-indent:2em;">6.21 \"Пользуйтесь ближним светом\" - указывает, что на данном участке дороги водители транспортных средств должны двигаться с включенным 
ближним светом фар.</p><br>

<p style="text-indent:2em;">6.22.1 - 6.22.5 \"Направление движения\" - указывают направление движения на зеленый сигнал в случаях, когда на сигналах светофора
 отсутствуют стрелки. Устанавливаются под светофором.</p><br>

<p>Особенности применения дополнительных табличек</p><br>

<p>Таблички 6.22.3 и 6.22.5 разрешают и разворот.</p><br>

<p style="text-indent:2em;">Дополнительные таблички размещаются непосредственно под знаком, с которым они применены. Таблички 6.4.1 - 6.4.3 и 6.15 при расположении 
знаков над проезжей частью, обочиной или тротуаром размещаются сбоку от знака.</p><br>

<p style="text-indent:2em;">В случаях, когда значения временных дорожных знаков (на переносной стойке) и стационарных знаков противоречат друг другу, водители должны
 руководствоваться временными знаками.</p>

<p style="text-indent:2em;"></p><h6>К приложению N 3</h6><p></p>

<p>Правил дорожного движения</p>

<p></p><h4>ДОРОЖНЫЕ ЗНАКИ</h4><p></p>

<p></p><h4>I.ПРЕДУПРЕЖДАЮЩИЕ ЗНАКИ</h4><p></p>

<p></p><h4>II.ЗНАКИ ПРИОРИТЕТА</h4><p></p>

<p></p><h4>III.ЗАПРЕЩАЮЩИЕ ИЛИ ОГРАНИЧИВАЮЩИЕ ЗНАКИ</h4><p></p>

<p></p><h4>IV.ПРЕДПИСЫВАЮЩТЕ ЗНАКИ</h4><p></p>

<p></p><h4>V.ИНФОРМАЦИОННО-УКАЗАТЕЛЬНЫЕ ЗНАКИ</h4><p></p>

<p>a) ИНФОРМАЦИОННЫЕ</p><br>

<p>b) УКАЗАТЕЛЬНЫЕ</p>

<p></p><h4>VI.ДОПОЛНИТЕЛЬНЫЕ ТАБЛИЧКИ</h4><p></p>

<p style="text-indent:2em;"></p><h6>Приложение N 4</h6><p></p>

<p>к Правилам дорожного движения</p>

<p></p><h4>ЗНАЧЕНИЯ ДОРОЖНОЙ РАЗМЕТКИ И ОСОБЕННОСТИ ЕЕ ПРИМЕНЕНИЯ</h4><p></p>

<p></p><h4>I. ЗНАЧЕНИЯ ГОРИЗОНТАЛЬНОЙ РАЗМЕТКИ</h4><p></p>

<p>А) Продольная разметка</p>

<p style="text-indent:2em;"></p><h6>1.1. Узкая сплошная линия разделяет транспортные потоки противоположных направлений на опасных участках дорог, имеющих
 две или три полосы движения, а также обозначает:</h6><p></p>

<p>а) границы полос на опасных участках дорог с двумя и более полосами для движения в данном направлении;</p><br>

<p>b) границы участков проезжей части, на которые въезд запрещен;</p><br>

<p>с) границы мест стоянки транспортных средств;</p><br>

<p>d) край проезжей части дорог иных, чем автомагистрали.</p>

<p style="text-indent:2em;"></p><h6>1.2. Широкая сплошная линия обозначает:</h6><p></p>

<p>а) край проезжей части на автомагистралях;</p><br>

<p>b) границы полос, предназначенных для движения маршрутных транспортных средств.</p><br>

<p style="text-indent:2em;">1.3. Две параллельные сплошные узкие линии - разделяют транспортные потоки противоположных направлений на дорогах, имеющих четыре
 и более полос движения.</p><br>

<p style="text-indent:2em;">1.4. Узкая сплошная линия - обозначает места, где запрещена остановка. Применяется самостоятельно или в сочетании со знаком 3.31,
 ограничивая зону его действия. Наносится на бордюры тротуаров или у края проезжей части.</p>

<p style="text-indent:2em;"></p><h6>1.5. Узкая прерывистая линия, у которой длина штрихов в три раза меньше промежутков между ними:</h6><p></p>

<p style="text-indent:2em;">а) разделяет транспортные потоки противоположных направлений на дорогах, имеющих две или три полосы движения;</p><br>

<p style="text-indent:2em;">b) обозначает границы полос движения на дорогах с двумя и более полосами для движения в данном направлении.</p><br>

<p style="text-indent:2em;">1.6. Узкая прерывистая линия, у которой длина штрихов в три раза больше промежутков между ними, - предупреждает о приближении к линии
 1.1 или 1.11, разделяющей транспортные потоки противоположных или попутных направлений.</p>

<p style="text-indent:2em;"></p><h6>1.7. Узкая прерывистая линия, у которой длина штрихов равна промежуткам между ними, обозначает:</h6><p></p>

<p>а) границы полос движения в пределах перекрестка;</p><br>

<p>b) направление главной дороги в пределах перекрестка.</p><br>

<p style="text-indent:2em;">1.8. Широкая прерывистая линия, у которой длина штрихов в три раза меньше промежутков между ними, - обозначает границу между полосой
 торможения или разгона и смежной полосой проезжей части (на перекрестках, пересечениях на разных уровнях, в зоне остановок маршрутных
 транспортных средств и т.п.).</p>

<p style="text-indent:2em;"></p><h6>1.9. Две параллельные узкие прерывистые линии, у которых длина штрихов в три раза больше промежутков между ними:</h6><p></p>

<p>а) обозначает границы полос, движение по которым может изменяться на противоположное;</p><br>

<p style="text-indent:2em;">b) разделяет транспортные потоки противоположных и попутных направлений при включенных реверсивных светофорах.</p><br>

<p style="text-indent:2em;">1.10. Узкая прерывистая линия - обозначает места, где запрещена стоянка. Применяется самостоятельно или в сочетании со знаками 3.32.1
 - 3.32.4, ограничивая зону их действия. Наносится на бордюры тротуаров или у края проезжей части.</p>

<p style="text-indent:2em;"></p><h6>1.11. Две параллельные узкие линии, одна из которых сплошная, другая прерывистая:</h6><p></p>

<p style="text-indent:2em;">а) разделяет транспортные потоки противоположных или попутных направлений на участках дорог, где маневрирование разрешено только со
 стороны прерывистой линии;</p><br>

<p style="text-indent:2em;">b) обозначает места, предназначенные для разворота, въезда на прилегающие к дороге территории и выезда из них.</p><br>

<p>B) Поперечная разметка</p><br>

<p style="text-indent:2em;">1.12. Широкая сплошная линия (стоп-линия) - указывает место, где водитель должен остановиться при наличии знака 2.2, запрещающем сигнале
 светофора (регулировщика), а также перед железнодорожным переездом.</p><br>

<p style="text-indent:2em;">1.13. Разметка в виде прилегающих друг к другу треугольников, направленных вершинами в сторону приближающегося транспорта, указывает место,
 где водитель при необходимости должен остановиться, уступая дорогу транспортным средствам, движущимся по пересекаемой дороге.</p><br>

<p style="text-indent:2em;">1.14.1, 1.14.2. Широкие линии, параллельные оси проезжей части, обозначают нерегулируемый пешеходный переход; разметка 1.14.2 со стрелками
 указывает направление движения пешеходов.</p><br>

<p style="text-indent:2em;">1.14.3. Широкие прерывистые параллельные линии, перпендикулярные оси дороги, у которых длина штрихов в два раза больше промежутков между ними,
 - обозначают границы пешеходного перехода, где движение регулируется сигналами светофора;</p><br>

<p style="text-indent:2em;">1.15. Параллельные прерывистые линии, перпендикулярные оси дороги, у которых длина штрихов равна промежуткам между ними, обозначают 
границы участка проезжей части в местах, где велосипедная дорожка пересекает проезжую часть.</p><br>

<p>C) Другая разметка</p>

<p style="text-indent:2em;"></p><h6>1.16.1. - 1.16.3. Широкие параллельные или ломаные линии, заключенные в контур, обозначают участки дороги,
 на которые въезд запрещен, а именно:</h6><p></p>

<p>а) 1.16.1 - разрывы разделительной полосы, резервные зоны, островки безопасности и т.п.</p><br>

<p>b) 1.16.2 - направляющие островки в местах разделения транспортных потоков;</p><br>

<p>c) 1.16.3 - направляющие островки в местах слияния транспортных потоков.</p><br>

<p style="text-indent:2em;">1.17. Узкая сплошная зигзагообразная линия с надписью \"BUS\" или \"TAXI\" либо без нее - обозначает места
 остановок маршрутных транспортных средств (автобусов, троллейбусов) или стоянок такси.</p><br>

<p style="text-indent:2em;">1.18. Стрелы с прямым стержнем указывают разрешенные направления движения по полосам на перекрестке. Применяются
 самостоятельно или в сочетании со знаками 5.28, 5.29.1 - 5.29.4. Разметка с изображением тупика указывает, что
 поворот на ближайшую проезжую часть запрещен.</p><br>

<p>Разметка, разрешающая поворот налево из крайней левой полосы, разрешает и разворот.</p>

<p style="text-indent:2em;"></p><h6>1.19. Стрелы с изогнутым стержнем предупреждают о приближении к:</h6><p></p>

<p>а) сужению проезжей части в сочетании со знаками 1.18.1 - 1.18.3;</p><br>

<p>b) участку дороги, где количество полос для движения в данном направлении уменьшается;</p><br>

<p>c) линиям разметки 1.1 и 1.11, разделяющим транспортные потоки противоположных направлений.</p><br>

<p style="text-indent:2em;">1.20. Треугольник, у которого основание параллельно разметке 1.13 и вершина направлена в сторону приближающегося транспорта,
 предупреждает о приближении к разметке 1.13.</p><br>

<p style="text-indent:2em;">1.21. Надпись \"STOP\" - предупреждает о приближении к разметке 1.12 (стоп-линии), когда она применяется со знаком 2.2.</p><br>

<p>1.22. Надпись, состоящая из букв и/или цифр, указывает номер дороги (маршрута).</p><br>

<p style="text-indent:2em;">1.23. Надпись \"BUS\" - обозначает полосу, предназначенную для движения маршрутных транспортных средств, а также их остановок.</p><br>

<p>1.24. Надпись \"TAXI\" - обозначает место стоянки такси.</p><br>

<p style="text-indent:2em;">1.25. Символ \"Инвалид\" - обозначает место стоянки, предназначенное для транспортных средств, управляемых инвалидами.</p><br>

<p>1.26. Надпись \"PR\" - обозначает место стоянки транспортных средств дорожной полиции.</p><br>

<p>Горизонтальная разметка на проезжей части имеет белый цвет.</p><br>

<p>Разметка 1.4, 1.10, а также 1.17 с надписью \"BUS\" или \"TAXI\" либо без нее имеет желтый цвет.</p><br>

<p>Временная разметка, в связи с дорожными работами, заменяющая постоянную, имеет оранжевый цвет.</p><br>

<p>Особенности применения горизонтальной разметки</p><br>

<p style="text-indent:2em;">Категорически запрещается пересекать линии 1.1 и 1.3 (как исключение, линию 1.1 можно пересекать для остановки транспортного
 средства только в том случае, когда она обозначает правый край проезжей части).</p><br>

<p style="text-indent:2em;">Линию 1.2, обозначающую внешний край проезжей части на автомагистралях, разрешается пересекать только при вынужденной
 остановке транспортного средства.</p><br>

<p>Линии 1.5 - 1.8 при маневрировании можно пересекать с любой стороны.</p><br>

<p style="text-indent:2em;">При разрешающем сигнале реверсивного светофора линию 1.9, разделяющую полосы попутного направления, можно пересекать с 
любой стороны, а линию 1.9, разделяющую транспортные потоки противоположных направлений, пересекать запрещается.</p><br>

<p style="text-indent:2em;">При включении желтого сигнала реверсивного светофора водители транспортных средств должны немедленно перестроиться вправо
 на полосу, указанную стрелкой.</p><br>

<p>При выключенном реверсивном светофоре въезд на реверсивную полосу запрещается.</p><br>

<p style="text-indent:2em;">Линию 1.11 можно пересекать со стороны прерывистой линии, а также со стороны сплошной только при возвращении на 
ранее занимаемую полосу после завершения объезда или обгона.</p><br>

<p style="text-indent:2em;">В случаях, когда значения временных дорожных знаков (на переносных стойках) противоречат значению линий разметки,
 водители должны руководствоваться знаками.</p>

<p></p><h4>II. ЗНАЧЕНИЯ ВЕРТИКАЛЬНОЙ РАЗМЕТКИ</h4><p></p>

<p style="text-indent:2em;">Вертикальная дорожная разметка в виде сочетания черных и белых полос на дорожных сооружениях показывает их габариты
 и служит средством зрительного ориентирования.</p>

<p style="text-indent:2em;"></p><h6>Вертикальная разметка обозначает:</h6><p></p>

<p style="text-indent:2em;">2.1. - элементы дорожных сооружений (опоры мостов, путепроводов, торцевых частей парапетов и т.п.), в случаях, когда
 они представляют опасность для участников движения;</p><br>

<p>2.2. - нижний край пролетного строения тоннелей, мостов, путепроводов;</p><br>

<p>2.3. - круглые тумбы, установленные на разделительных полосах или островках безопасности для пешеходов;</p><br>

<p>2.4. - направляющие столбики, надолбы, опоры ограждений и т.п.;</p><br>

<p style="text-indent:2em;">2.5. - боковые поверхности ограждений дорог на закруглениях малого радиуса, крутых спусках, других опасных участках;</p><br>

<p>2.6. - боковые поверхности ограждений на других участках дорог;</p><br>

<p style="text-indent:2em;">2.7. - бордюры на опасных участках проезжей части, а также возвышающиеся островки безопасности для пешеходов.</p>

<p style="text-indent:2em;"></p><h6>К приложению N 4</h6><p></p>

<p>Правил дорожного движения</p><br>

<p>ДОРОЖНАЯ РАЗМЕТКА</p><br>

<p>I.ГОРИЗОНТАЛЬНАЯ РАЗМЕТКА</p><br>

<p>A) Продольная разметка</p><br>

<p>B) Поперечная разметка</p><br>

<p>C) Другая разметка</p>

<p></p><h4>II.ВЕРТИКАЛЬНАЯ РАЗМЕТКА</h4><p></p>

<p>ПРИМЕНЕНИЕ ГОРИЗОНТАЛЬНОЙ ДОРОЖНОЙ РАЗМЕТКИ</p><br>

<p>ПРИМЕНЕНИЕ ВЕРТИКАЛЬНОЙ ДОРОЖНОЙ РАЗМЕТКИ</p>

<p style="text-indent:2em;"></p><h6>Приложение N 5</h6><p></p>

<p>к Правилам дорожного движения</p><br>

<p>СРЕДСТВА ОБОЗНАЧЕНИЯ ДОРОЖНЫХ РАБОТ</p>

<p style="text-indent:2em;"></p><h6>Приложение N 6</h6><p></p>

<p>к Правилам дорожного движения</p><br>

<p>Опознавательные знаки транспортных средств</p><br>

<p style="text-indent:2em;">Транспортные средства, движущиеся по общественным дорогам, должны быть зарегистрированы и иметь регистрационные номерные знаки, установленные
 в предусмотренных для них местах, за исключением троллейбусов и трамваев, на которых наносятся регистрационные номера, присваиваемые им 
 соответствующими ведомствами.</p><br>

<p style="text-indent:2em;">На задней стенке кузова грузовых автомобилей, прицепов (кроме легких) и автобусов (кроме особо малых) должны быть нанесены цифры и буквы, 
дублирующие регистрационный номер. Высота цифр не должна быть менее 300 мм, ширина - 120 мм, толщина штриха - 30 мм, размер букв должен 
составлять 2/3 от размера цифр</p>

<p style="text-indent:2em;"></p><h6>В целях повышения безопасности движения транспортные средства обозначаются соответствующими опознавательными знаками:</h6><p></p>

<p style="text-indent:2em;">1. \"Автопоезд\" - три фонаря оранжевого цвета, расположенные горизонтально над кабиной с промежутками 150-300 мм между ними -устанавливаются
 на грузовых автомобилях и колесных тракторах (класса 1,4 т и выше) с прицепами, а также на сочлененных автобусах и троллейбусах.</p><br>

<p style="text-indent:2em;">2. \"Длинномерное транспортное средство\" - прямоугольник желтого цвета со светоотражающей поверхностью (размер 560 х 200 мм), с каймой 
красного цвета (ширина - 40 мм) - устанавливается сзади транспортного средства с обеих сторон нижней части кузова на расстоянии не более 
0,4 м от его внешних краев.</p><br>

<p style="text-indent:2em;">3. \"Опознавательный знак государства\" - эллипс (овал) белого цвета со светоотражающей поверхностью (длина большой оси - 165 мм, 
малой - 105 мм) с черной каймой (ширина - 5 мм) и буквами \"MD\" черного цвета - устанавливается сзади на транспортных средствах, 
зарегистрированных в Республике Молдова, участвующих в международном движении.</p><br>

<p style="text-indent:2em;">4. \"Шины с элементами противоскольжения\" - равносторонний треугольник белого цвета, вершина которого направлена вверх, с каймой 
красного цвета, с изображением шины с цепями черного цвета (сторона треугольника 200-300 мм в зависимости от типа транспортного 
средства, ширина каймы - 1/10 стороны) -устанавливается сзади слева на транспортном средстве, на котором установлены шины с 
элементами противоскольжения.</p><br>

<p style="text-indent:2em;">5. \"Дети\" - квадрат желтого цвета с каймой красного цвета (сторона 250-300 мм в зависимости от типа автобуса, ширина каймы - 
1/10 стороны) с изображением символа дорожного знака 1.21 черного цвета - устанавливается спереди и сзади на автобусах, 
осуществляющих перевозку группы детей.</p><br>

<p style="text-indent:2em;">6. \"Тихоходное транспортное средство\" - равносторонний треугольник желтого цвета со световозвращающей поверхностью и незамкнутым
 контуром красного цвета, вершина которого направлена вверх (сторона треугольника 200-300 мм в зависимости от типа транспортного средства,
 ширина контура - 1/10 стороны) -устанавливается с обеих сторон сзади в нижней части кузова на расстоянии не более 0,4 м от его внешних краев.</p><br>

<p style="text-indent:2em;">7. \"Глухонемой водитель\" - круг желтого цвета с каймой черного цвета (диаметр круга - 160 мм, ширина каймы - 5 мм), в котором изображены 
три кружка (диаметр - 40 мм), расположенные по углам воображаемого равностороннего треугольника, вершина которого направлена вниз - 
устанавливается спереди и сзади на транспортном средстве, которым управляет глухой или глухонемой водитель.</p><br>

<p style="text-indent:2em;">8. \"Инвалид\" - квадрат желтого цвета с каймой черного цвета (сторона - 140 мм, ширина каймы - 5 мм) с изображением символа инвалида - 
устанавливается спереди и сзади на транспортном средстве, которым управляет водитель-инвалид.</p><br>

<p style="text-indent:2em;">9. \"Водитель-врач\" - квадрат синего цвета с вписанным в него кругом белого цвета, на который нанесен красный крест (сторона квадрата - 
140 мм, диаметр круга - 125 мм, длина штриха - 90 мм, ширина штриха - 25 мм) - устанавливается спереди и сзади на транспортом средстве по 
желанию водителя-врача.</p><br>

<p style="text-indent:2em;">10. \"Учебное транспортное средство\" - равносторонний треугольник белого цвета с вершиной, направленной вверх, с каймой красного цвета,
 в которой вписана буква \"S\" черного цвета (сторона треугольника - 200-300 мм в зависимости от типа транспортного средства, ширина каймы
 - 1/10 стороны) - устанавливается спереди и сзади на транспортном средстве, используемом для обучения вождению.</p><br>

<p style="text-indent:2em;">11. \"Ограничение скорости\" - круг белого цвета с каймой красного цвета (диаметр круга - 160-250 мм в зависимости от типа транспортного
 средства, ширина каймы -1/10 диаметра), представляющий собой изображение знака 3.27 с соответствующим значением разрещенной скорости движения,
 - устанавливается сзади с левой стороны на транспортных средствах, перевозящих тяжеловесные и крупногабаритные грузы, а также в случаях, когда
 максимальная скорость транспортного средства, согласно его технической характеристике, меньше определенной п. 47 настоящих правил.</p><br>

<p style="text-indent:2em;">12. \"Крупногабаритный груз\" - табличка со световозвращающей поверхностью размером 400 х 400 мм с нанесенными по диагонали чередующимися красными
 и белыми полосами шириной 50 мм -устанавливается в соответствии с п.88 настоящих правил.</p><br>

<p style="text-indent:2em;">13. \"Опасный груз\" - прямоугольник со световозвращающей поверхностью размером 690 х 300 мм с черной каймой (правая часть размером 400 х 300 мм</p><br>
 <p style="text-indent:2em;">- оранжевого цвета, левая 290 х 300 мм -белого цвета, ширина каймы - 15 мм), на котором наносятся обозначения, характеризующие вид опасного груза</p><br>
<p>- устанавливается спереди и сзади на транспортных средствах, перевозящих такие грузы.</p><br>

<p style="text-indent:2em;">14. \"Знак аварийной остановки\" - соответствует стандарту -устанавливается в соответствии с требованиями примечания к п.37 настоящих правил.</p><br>

<p style="text-indent:2em;">15. \"Начинающий водитель\" - круг желтого цвета с каймой черного цвета (диаметр - 160 мм, ширина каймы - 5 мм) с изображением восклицательного
 знака черного цвета - устанавливается спереди и сзади на транспортном средстве, которым управляет водитель со стажем менее одного года.</p><br>
 
<p style="text-indent:2em;">16. «Воинская колонна» – прямоугольник со световозвращающей поверхностью размером 750 х 300 мм, желтого цвета, с каймой красного цвета (ширина каймы – 40 мм)
, на котором нанесена надпись «ATENŢIE! COLOANĂ MILITARĂ» черного цвета (высота букв – 60 мм, ширина – 30 мм, толщина штриха – 10 мм, расстояние между строчками – 40 мм).</p><br> 

<p style="text-indent:2em;">Устанавливается спереди первого транспортного средства и сзади последнего в колонне транспортного средства на расстоянии не более 0,40 м от его левого края.</p><br>

<p style="text-indent:2em;">Транспортное средство на газовом топливе» – равносторонний треугольник желтого цвета, вершина которого направлена вверх, с черным контуром, с изображением
	легковоспламеняющегося вещества и надписью „GAZ” черного цвета (сторона треугольника 100 – 300 мм, в зависимости от типа транспортного средства, ширина контура
	– 1/10 стороны), устанавливается спереди и сзади транспортных средств, у которых система питания двигателя оборудована для работы на газе.</p>


<p style="text-indent:2em;"></p><h6>К приложению N 6</h6><p></p>

<p>Правил дорожного движения</p><br>

<p>ОПОЗНАВАТЕЛЬНЫЕ ЗНАКИ ТРАНСПОРТНЫХ СРЕДСТВ</p><br>

<p>Изменяются знаки</p><br>

<p><a href="https://www.legis.md/cautare/getResults?doc_id=113381&amp;lang=ru">Источник</a></p>


</div>
`