import React from 'react'
import FlexBand from 'flexband'
import fetch from '../../shared/fetch'
import SingleClaim from './single-claim'
import { ReviewListProps, ReviewListState, IResponse } from './types'
import Pagination from '../../UI/pagination'
import AddClaim from './add-claim'
import observedObject from '../../../observedObject'
import { REVIEWS_ON_PAGE } from './constants'
import Spinner from '../../UI/spinner'
import { scrollToElement } from '../../../store/utils'
import { ALLOW_CLAIM } from "./constants"
export default class CompanyReviewList extends React.Component<ReviewListProps, ReviewListState> {
  constructor(props) {
    super(props)

    this.state = {
      responsing: {
        claimId: null,
        title: '',
      },
      quota: {
        reviewQuota: 0,
        answerQuota: 0,
      },
      delClaim: 0,
      authorOrAdminUserId: 0,
      isLoading: false,
      deleteClaimType: "",
      claimResponseId: 0,
    }
  }

  renderAlertContent = (text) => {
    const { t } = this.props
    return <div style={{ fontSize: 14 }}>{text}</div>
  }

  refreshData = (text: string) => {
    const { enqueueSnackbar } = this.props
    enqueueSnackbar(this.renderAlertContent(text), { autoHideDuration: 2000 })
    this.props.refresh(0)
  }

  handleResponse = (responsing: IResponse) => {
    this.setState({ responsing })
    setTimeout(() => {
      observedObject.emit('focus', 'claimAnswer')
      scrollToElement('claimAnswer')
    }, 500);
  }

  setDelClaimId = (delClaim, authorOrAdminUserId, deleteClaimType, claimResponseId) => {
    this.setState({ delClaim, authorOrAdminUserId, deleteClaimType, claimResponseId })
  }

  resetReviews = () => {
    scrollToElement('reviewsContainer')
    this.setState({ responsing: { claimId: null, title: '' } })
  }

  renderContent = () => {
    const {
      t,
      claims,
      totalItems,
      pageIndex,
      pageSize,
      notFoundText,
      refresh,
      userId,
      reviewingCompanyId,
      profile,
      reCalcCounter,
      categoryKey,
      scrollToReviewForm,
      claimsTypes,
      allowClaim
    } = this.props
    const { responsing, delClaim, authorOrAdminUserId, deleteClaimType, claimResponseId } = this.state

    const noAddedClaimAndAllowed = profile.verifiedCompany > 0 && allowClaim === ALLOW_CLAIM
    const notAuthorAndCanWriteReview = profile?.isCompanyAdmin && userId != reviewingCompanyId && Number(profile?.companyId) !== Number(reviewingCompanyId) && noAddedClaimAndAllowed
    const showAddReviewForm = responsing.title.length ? true : notAuthorAndCanWriteReview

    return (
      <div id="reviewsContainer">
        {claims.length > 0 ? (
          claims.map(claims => (
            <SingleClaim
              key={claims.claimId}
              claim={claims}
              userId={userId}
              isCompanyAdmin={profile?.isCompanyAdmin}
              isOwnerCompanyId={profile?.companyId}
              refreshData={this.refreshData}
              reCalcCounter={reCalcCounter}
              reviewingCompanyId={reviewingCompanyId}
              setResponsing={this.handleResponse}
              delClaimID={this.setDelClaimId}
              {...{ deleteClaimType }}
              {...{ claimResponseId }}
              currentDeleteReviewId={delClaim}
              currentAuthorOrAdminUserId={authorOrAdminUserId}
              resetDeleteReviewId={() => this.setState({ delClaim: 0 })}
            />
          ))
        ) : (
          <FlexBand className="table-no-entries" justify="center" align="center">
            {notFoundText || t('not.found')}
          </FlexBand>
        )}
        {totalItems > REVIEWS_ON_PAGE && (
          <Pagination
            total={totalItems}
            pageIndex={pageIndex}
            pageSize={pageSize}
            onClick={refresh}
          />
        )}

        {(Number(showAddReviewForm) !== 0 && userId) && (
          <div className="panel-content-row" style={{ marginTop: 10 }}>
            <div id="claimAnswer"></div>
            <AddClaim
              {...{ reviewingCompanyId }}
              {...{ userId }}
              refresh={this.refreshData}
              answerFor={responsing}
              {...{ categoryKey }}
              {...{ claimsTypes }}
              claimId={responsing.claimId}
              resetResponse={() => this.resetReviews()}
              reCalcCounter={reCalcCounter}
              scrollToReviewForm={() => scrollToReviewForm()}
            />
          </div>
        )}
      </div>
    )
  }

  render() {
    const { isLoading } = this.state
    const content = isLoading ? <Spinner /> : this.renderContent()

    return content
  }
}
