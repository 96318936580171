import React from 'react'
import moment from 'moment'
import FlexBand from 'flexband'
import { Link } from 'react-router-dom'

import { BlockListProps } from './types'
import UserPhoto from '../user/user-photo'
import Pagination from '../UI/pagination'

import './styles.scss'

export default class BlockList extends React.Component<BlockListProps> {
  render() {
    const { blocks, notFoundText, t, totalItems, pageIndex, pageSize, refresh } = this.props
    return (
      <div>
        {blocks?.length !== 0 ? (
          <div>
            <table className="table table-striped-main table-collapsed">
              <tbody>
                {blocks.map(block => (
                  <tr key={block.blockId} className="block-line">
                    <td className="td-date">
                      <span>{moment(block.dateBlocked).format('DD.MM.YYYY')}</span>
                    </td>
                    <td className="td-user-image">
                      {block.user && (
                        <span>
                          <span className="img-container">
                            <UserPhoto
                              customUserId={block.user.userId}
                              photo={block.user.photo}
                              gender="male"
                            />
                          </span>
                          <Link to={`/user/details/${block.user.userId}`} className="name">
                            {block.user.username}
                          </Link>
                          {block.user.company?.companyId && (
                            <Link
                              to={`/company/item/${block.user.company?.companyId}`}
                              className="name"
                            >
                              {block.user.company.companyName}
                            </Link>
                          )}
                        </span>
                      )}
                    </td>
                    <td className="td-last">
                      <span>
                        {t('user.blocked.proposals')}:
                        {block.blockedCargo && t('user.blocked.cargo')}
                        {block.blockedCargo && block.blockedTransport && ', '}
                        {block.blockedTransport && t('user.blocked.transport')}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              total={totalItems}
              pageIndex={pageIndex}
              pageSize={pageSize}
              onClick={refresh}
            />
          </div>
        ) : (
          <FlexBand className="table-no-entries" justify="center" align="center">
            {notFoundText || t('not.found')}
          </FlexBand>
        )}
      </div>
    )
  }
}
