import React, { Component } from 'react'
import fetch from '../../../shared/fetch'
import forumCommentDate from '../../../forum/forum-full-page/forum-date'
import Spinner from '../../../UI/spinner'
import ForumNoContent from '../../../forum/forum-full-page/forum-no-content'
import WidgetHeader from '../../../shared/components/widget-header'
import { DESCRIPTION_MAX_LENGTH } from '../../../forum/forum-full-page/forum-constants'

import { ChatProps } from './types'

import './send-message-popup.scss'

export default class SendMessagePopup extends Component<ChatProps> {
  state = {
    items: [],
    sections: [],
    isLoading: true,
    newMessage: '',
    chatPartnerId: 0,
    //   chatMsgs: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      items: [],
      sections: [],
      isLoading: true,
      newMessage: '',
      chatPartnerId: 0,
      //     chatMsgs: [],
    }
  }

  componentWillUnmount() {
    const { setInitialChatState } = this.props
    setInitialChatState([])
  }

  componentDidMount() {
    const { userId, chatPartnerId, setChatPartnerId } = this.props
    setChatPartnerId(chatPartnerId)
    this.getMessages(userId, chatPartnerId)
  }

  componentDidUpdate(prevProps) {

    const { chatMsgs, needToScrollInMiniMessenger, conversationScrollToBottom } = this.props

    if (prevProps.chatMsgs.length !== chatMsgs.length) {
      this.setState({ chatMsgs: [...chatMsgs] }, () => this.scrollToBottom())
    }

    if (prevProps.needToScrollInMiniMessenger !== needToScrollInMiniMessenger) {
          this.scrollToBottom()
          conversationScrollToBottom() 
    }
  }

  getMessages = (userId, opponentId) => {
    const { setInitialChatState } = this.props

    const body = JSON.stringify({
      userId,
      opponentId,
    })

    fetch('/messenger/getAllMessagesMsngr', {
      method: 'POST',
      body,
    }).then(response => {
      this.setState({ isLoading: false })
      setInitialChatState( [...response.data.messages].reverse())
    })
  }

  scrollToBottom = () => {
    const parentCtrl = document.getElementById('bubblesBottom')
    const targetCtrl = document.getElementById('chatPnl')

    if (targetCtrl) {
      parentCtrl.scrollTo({
        top: targetCtrl.getBoundingClientRect() ? targetCtrl.getBoundingClientRect().height : 0,
        behavior: 'smooth',
      })
    }
  }

  renderMessagesBubles = () => {
    const { chatMsgs, userId, t } = this.props

    if (chatMsgs.length > 0) {
      const messagesBubbles = chatMsgs.map(oneMessage => {
        const { contentReceived, contentSend, dateTime, messageId, sendUserId } = oneMessage
        const content = contentReceived == null ? contentSend : contentReceived
        const textAlignRightStyle = sendUserId == userId ? 'ta-right' : ''

        return (
          <div className={`message-bubble ${textAlignRightStyle}`} key={messageId}>
            <div className="sm-date"> </div>
            <div className="clear"></div>
            <div className="sm-message">
              <span className="bubble-date">{forumCommentDate(dateTime)}</span>
              {content}
            </div>
            <div className="clear"></div>
          </div>
        )
      })

      return (
        <div id="chatPnl">
          {messagesBubbles}
        </div>
      )
    } else {
      return <ForumNoContent title={t('no.messages')} />
    }
  }

  addMessage = () => {
    const { userId, chatPartnerId } = this.props
    const { newMessage } = this.state

    const body = JSON.stringify({
      userId: userId,
      partnerUserId: chatPartnerId,
      message: newMessage,
    })

    //    console.log('SOCKET_NEW_MESSAGE sending: ', body)
    window.socket.emit('SOCKET_NEW_MESSAGE', body)

    this.setState({ newMessage: '' }, () => {
      setTimeout(() => {
        this.scrollToBottom()
      }, 1000);
    })
  }

  messagesBubblesContainer = () => {
    const { isLoading } = this.state
    const display = !isLoading ? 'display-block' : ''

    return (
      <div className={`messages-bublles ${display}`} id="bubblesBottom">
        {this.renderMessagesBubles()}
        <div />
      </div>
    )
  }

  onEnterPress = e => {
    if (e.keyCode === 13 && (e.ctrlKey || e.metaKey)) {
      e.preventDefault()
      this.addMessage()
    }
  }

  render() {
    const { hidePopup, opponentName, t } = this.props
    const { newMessage, isLoading } = this.state
    const disabled = newMessage.length <= 0 || newMessage.length > DESCRIPTION_MAX_LENGTH
    const error = newMessage.length > DESCRIPTION_MAX_LENGTH ? 'error-text' : ''

    return (
      <div className="messenger-overlay">
        <div className="overlay-to-close" onClick={hidePopup}></div>
        <div className="send-message-popup-container panel-body mini-messenger">
          <div className="top-container">
            <WidgetHeader title={t('messenger.alt')}>
              <i className="fa fa-close" onClick={hidePopup}></i>
            </WidgetHeader>
            <div className="panel-offer-header">
              <span className="name">{opponentName}</span>
            </div>
          </div>

          <div className="bottom-container">
            {isLoading ? <Spinner /> : this.messagesBubblesContainer()}
            <div className="send-message-container">
              <textarea
                value={newMessage}
                className="send-message-field"
                onKeyDown={this.onEnterPress}
                onChange={e => {
                  this.setState({ newMessage: e.target.value })
                }}
              />
              <div className="message-length">
                <span className={error}>{newMessage.length}</span> / {DESCRIPTION_MAX_LENGTH}
              </div>
              <button
                type="button"
                disabled={disabled}
                className="button button-orange"
                onClick={() => this.addMessage()}
              >
                {t('messenger.send')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
