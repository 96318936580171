import React, { Component } from 'react'
// import Ads from '../../ads'
// import SiteStat from '../../site-stat'
// import BuySellShort from '../../buy-sell/short'
import PriceTable from "./price-table"
import { priceData } from "./mock-prices"

export default class extends Component {


  render() {
    const { t } = this.props
    const { usersAndPrices, companyAdminPermissions, accountTypePrices } = priceData
    return (
      <div className="row prices-page">

        <div className="col-md-16">
          <PriceTable
            title={t('prices.users')}
            data={usersAndPrices}
            blockId={"usersAndPrices"}
            {...{ t }}
          />

          <PriceTable
            title={t('prices.admins')}
            data={companyAdminPermissions}
            blockId={"companyAdminPermissions"}
             {...{ t }}
          />

          <PriceTable
            title={t('prices.different.statuses')}
            data={accountTypePrices}
            blockId={"accountTypePrices"}
             {...{ t }}
          />
        </div>


        {/* <div
          dangerouslySetInnerHTML={{
            __html: t('about.us.prices')
          }} /> */}
      </div>
    )
  }
}
