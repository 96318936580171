import _ from 'lodash'
import fetch from '../../components/shared/fetch'
import * as actionTypes from './action-types'
import { oops, fetchNews } from '../utils'

// ------------------------------------------
// list-mini
// ------------------------------------------
export const loadMiniItems = props => {
  return dispatch => {
    // assign defaults if props missing
    props = _.defaults(props, {
      pageIndex: 0,
      pageSize: 5,
      requestedAt: new Date(),
    })
    // set 'loading'
    dispatch({ type: actionTypes.NEWS_LIST_MINI_LOADING, ...props })
    // call api
    return fetchNews(props)
      .then(json => dispatch({ type: actionTypes.NEWS_LIST_MINI_LOADED, data: json.d }))
      .catch(error => dispatch(oops(error, actionTypes.NEWS_LIST_MINI_LOAD_ERROR)))
  }
}

// ------------------------------------------
// news items
// ------------------------------------------
export const loadItems = props => {
  return dispatch => {
    // assign defaults if props missing
    props = _.defaults(props, {
      pageIndex: 0,
      pageSize: 10,
      requestedAt: new Date(),
      includeDeleted: false,
    })
    // set 'loading'
    dispatch({ type: actionTypes.NEWS_LIST_LOADING, ...props })
    // call api
    return fetchNews(props)
      .then(json => dispatch({ type: actionTypes.NEWS_LIST_LOADED, data: json.d }))
      .catch(error => dispatch(oops(error, actionTypes.NEWS_LIST_LOAD_ERROR)))
  }
}
// ------------------------------------------
//
// ------------------------------------------
export const setParams = (scope, params) => {
  return dispatch => {
    dispatch({
      type: actionTypes.NEWS_LIST_SET_PARAMS,
      payload: { scope, params },
    })
    dispatch(loadItems(params))
  }
}

// ------------------------------------------
// single news item
// ------------------------------------------
export const getItem = ({ itemId }) => {
  return (dispatch, getState) => {
    const state = getState()
    const current = state.news.item

    if (itemId === current.itemId && current.loading) return

    dispatch({ type: actionTypes.NEWS_ITEM_LOADING, requestedAt: new Date(), itemId })

    return fetch(`/news/${itemId}`)
      .then(json => dispatch({ type: actionTypes.NEWS_ITEM_LOADED, data: json.d }))
      .catch(error => dispatch(oops(error, actionTypes.NEWS_ITEM_LOAD_ERROR)))
  }
}
// ------------------------------------------
//
// ------------------------------------------
export const loadComments = props => {
  return (dispatch, getState) => {
    // assign defaults if props missing
    props = _.defaults(props, {
      pageIndex: 0,
      pageSize: 10,
      requestedAt: new Date(),
    })
    const { itemId, pageIndex, pageSize, force } = props
    const state = getState()
    const current = state.news.comments
    const changed =
      itemId !== current.itemId || pageIndex !== current.pageIndex || pageSize !== current.pageSize
    if (!force && (!changed || current.loading)) return
    // set 'loading'
    dispatch({ type: actionTypes.NEWS_COMMENTS_LOADING, ...props })
    // call api
    return fetch(`/news/comments/${itemId}/${pageIndex}/${pageSize}`)
      .then(json => dispatch({ type: actionTypes.NEWS_COMMENTS_LOADED, data: json.d }))
      .catch(error => dispatch(oops(error, actionTypes.NEWS_COMMENTS_LOAD_ERROR)))
  }
}
// ------------------------------------------
//
// ------------------------------------------
export const addComment = ({ itemId, text, pageIndex, pageSize }) => {
  return dispatch => {
    dispatch({ type: actionTypes.NEWS_COMMENT_ADDING, requestedAt: new Date(), itemId, text })
    return fetch('/news/comment', {
      method: 'POST',
      body: JSON.stringify({
        itemId,
        text,
        pageIndex,
        pageSize,
      }),
    })
      .then(json => dispatch({ type: actionTypes.NEWS_COMMENT_ADDED, data: json.d }))
      .catch(error => dispatch(oops(error, actionTypes.NEWS_COMMENT_ADD_ERROR)))
  }
}
// ------------------------------------------
//
// ------------------------------------------
export const deleteComment = (itemId, cb) => {
  return dispatch => {
    dispatch({ type: actionTypes.NEWS_COMMENT_DELETING, requestedAt: new Date(), itemId })
    return fetch(`/news/comment/${itemId}`, { method: 'DELETE' })
      .then(json => {
        dispatch({ type: actionTypes.NEWS_COMMENT_DELETED, data: json.d })
        cb && cb(itemId)
      })
      .catch(error => dispatch(oops(error, actionTypes.NEWS_COMMENT_DELETE_ERROR)))
  }
}
// ------------------------------------------
// set edit item
// ------------------------------------------
export const setEditingItem = (langId, item) => ({
  type: actionTypes.NEWS_SET_EDITING_ITEM,
  langId,
  item,
})
// ------------------------------------------
// show/hide Modal
// ------------------------------------------
export const showEditModal = (langId, item) => ({
  type: actionTypes.NEWS_MODAL_SHOW,
  langId,
  item,
})
export const hideEditModal = () => ({
  type: actionTypes.NEWS_MODAL_HIDE,
})
// ------------------------------------------
//
// ------------------------------------------
export const save = values => {
  return dispatch => {
    dispatch({ type: actionTypes.NEWS_SAVE_START, requestedAt: new Date() })
    return fetch('/news/save', {
      method: 'POST',
      body: JSON.stringify(values),
    })
      .then(json => dispatch({ type: actionTypes.NEWS_SAVED, data: json.d }))
      .catch(error => dispatch(oops(error, actionTypes.NEWS_SAVE_ERROR)))
  }
}
// ------------------------------------------
//
// ------------------------------------------
export const deleteNews = (itemId, cb) => {
  return dispatch => {
    dispatch({ type: actionTypes.NEWS_DELETING, requestedAt: new Date(), itemId })
    return fetch(`/news/${itemId}`, { method: 'DELETE' })
      .then(json => {
        dispatch({ type: actionTypes.NEWS_DELETED, data: json.d })
        cb && cb(itemId)
      })
      .catch(error => dispatch(oops(error, actionTypes.NEWS_DELETE_ERROR)))
  }
}
// ------------------------------------------
//
// ------------------------------------------
export const switchListLayout = layoutType => ({
  type: actionTypes.NEWS_SWITCH_LAYOUT,
  layoutType,
})
// ------------------------------------------
//
// ------------------------------------------
export const publishItem = ({ itemId, published }) => {
  return dispatch => {
    dispatch({ type: actionTypes.NEWS_PUBLISH_START, requestedAt: new Date(), itemId })
    return fetch(`/news/setPublished/${itemId}`, {
      method: 'PUT',
      body: JSON.stringify({ published }),
    })
      .then(json =>
        dispatch({
          type: actionTypes.NEWS_PUBLISH_FINISH,
          data: json.d,
          itemId,
          published,
        })
      )
      .catch(error => dispatch(oops(error, actionTypes.NEWS_PUBLISH_ERROR)))
  }
}
