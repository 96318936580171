export default [
  
  {
    name: 'Cargo Search',
    type: 'item',
    href: '/cargo/search',
  },
  {
    name: 'Transport Search',
    type: 'item',
    href: '/transport/search',
  },
  {
    name: 'Add cargo',
    type: 'item',
    href: '/cargo/add',
    showLoginAlert: true
  },
  {
    name: 'Add transport',
    type: 'item',
    href: '/transport/add',
    showLoginAlert: true
  },
]
