export const int19 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Классификация опасных грузов и их характеристики.</h4><p></p>

<p style="font-weight:500;">КЛАСС 1 - взрывчатые материалы, которые по своим свойствам могут взрываться, вызывать пожар с взрывчатым действием,
 а также устройства, содержащие взрывчатые вещества и средства взрывания, предназначенные для производства
 пиротехнического эффекта;</p><br>

<p style="text-indent:2em;">подкласс 1.1 - взрывчатые и пиротехнические вещества и изделия с опасностью взрыва массой, когда взрыв мгновенно
 охватывает весь груз;</p><br>

<p style="text-indent:2em;">подкласс 1.2 - взрывчатые и пиротехнические вещества и изделия, не взрывающиеся массой;</p><br>

<p style="text-indent:2em;">подкласс 1.3 - взрывчатые и пиротехнические вещества и изделия, обладающие опасностью загорания с незначительным
 взрывчатым действием или без него;</p><br>

<p style="text-indent:2em;">подкласс 1.4 - взрывчатые и пиротехнические вещества и изделия, представляющие незначительную опасность взрыва во
 время транспортировки только в случае воспламенения или инициирования, не дающие разрушения устройств и
 упаковок;</p><br>

<p style="text-indent:2em;">подкласс 1.5 - взрывчатые вещества с опасностью взрыва массой, которые настолько нечувствительны, что при
 транспортировании инициирование или переход от горения к детонации маловероятны;</p><br>

<p style="text-indent:2em;">подкласс 1.6 - изделия, содержащие исключительно нечувствительные к детонации вещества, не взрывающиеся
 массой и характеризующиеся низкой вероятностью случайного инициирования;</p>

<p style="text-indent:2em;"></p><h6>КЛАСС 2 - газы сжатые, сжиженные охлаждением и растворенные под давлением, отвечающие хотя бы одному
 из следующих условий:</h6><p></p>

<p>абсолютное давление паров при температуре 50С равно или выше 3 кгс/смЧЗОО кПа);</p>

<p style="text-indent:2em;"></p><h6>критическая температура ниже 50С. По физическому состоянию газы делятся на:</h6><p></p>

<p>сжатые, критическая температура которых ниже -10С;</p><br>

<p>сжиженные, критическая температура которых равна или выше -10С, но ниже 70С;</p><br>

<p>сжиженные, критическая температура которых равна или выше 70С;</p><br>

<p>растворенные под давлением;</p><br>

<p>сжиженные переохлаждением;</p><br>

<p>аэрозоли и сжатые газы, попадающие под действие специальных предписаний.</p><br>

<p>подкласс 2.1 - невоспламеняющиеся газы;</p><br>

<p>подкласс 2.2 - невоспламеняющиеся ядовитые газы;</p><br>

<p>подкласс 2.3 - легковоспламеняющиеся газы;</p><br>

<p>подкласс 2.4 - легковоспламеняющиеся ядовитые газы;</p><br>

<p>подкласс 2.5 - химически неустойчивые;</p><br>

<p>подкласс 2.6 - химически неустойчивые ядовитые.</p><br>

<p style="font-weight:500;">КЛАСС 3 - легковоспламеняющиеся жидкости, смеси жидкостей, а также жидкости, содержащие твердые вещества
 в растворе или суспензии, которые выделяют легковоспламеняющиеся пары, имеющие температуру вспышки в
 закрытом тигле 61С и ниже;</p><br>

<p style="text-indent:2em;">подкласс 3.1 - легковоспламеняющиеся жидкости с низкой температурой вспышки и жидкости, имеющие температуру
 вспышки в закрытом тигле ниже минус 18С или имеющие температуру вспышки в сочетании с другими опасными
 свойствами, кроме легковоспламеняемости;</p><br>

<p style="text-indent:2em;">подкласс 3.2 - легковоспламеняющиеся жидкости со средней температурой вспышки - жидкости с температурой
 вспышки в закрытом тигле от минус 18 до плюс 23С;</p><br>

<p style="text-indent:2em;">подкласс 3.3 - лекговоспламеняющиеся жидкости с высокой температурой вспышки - жидкости с температурой
 вспышки от 23 до 61С включительно в закрытом тигле.</p><br>


<p style="font-weight:500;">КЛАСС 4 - легковоспламеняющиеся вещества и материалы (кроме классифицированных как взрывчатые),
 способные во время перевозки легко загораться от внешних источников воспламенения, в результате
 трения, поглощения влаги, самопроизвольных химических превращений, а также при нагревании;</p><br>

<p style="text-indent:2em;">подкласс 4.1 - легковоспламеняющиеся твердью вещества, способные легко воспламеняться от
 кратковременного воздействия внешних источников воспламенения (искры, пламени или трения)
 и активно гореть;</p><br>

<p style="text-indent:2em;">подкласс 4.2 - самовоспламеняющиеся вещества, которые в обычных условиях транспортирования
 могут самопроизвольно нагреваться и воспламеняться;</p><br>

<p>подкласс 4.3 - вещества, выделяющие воспламеняющиеся газы при взаимодействии с водой</p><br>

<p style="font-weight:500;">КЛАСС 5 - окисляющие вещества и органические пероксиды, которые способны легко выделять кислород,
 поддерживать горение, а также могут, в соответствующих условиях или в смеси с другими веществами,
 вызвать самовоспламенение и взрыв;</p><br>

<p style="text-indent:2em;">подкласс 5.1 - окисляющие вещества, которые сами по себе не горючи, но способствуют легкой
 воспламеняемости других веществ и выделяют кислород при горении, тем самым увеличивая интенсивность
 огня;</p><br>

<p style="text-indent:2em;">подкласс 5.2 - органические пероксиды, которые в большинстве случаев горючи, могут действовать
 как окисляющие вещества и опасно взаимодействовать с другими веществами. Многие из них легко
 загораются и чувствительны к удару и трению.</p><br>

<p style="font-weight:500;">КЛАСС 6 - ядовитые и инфекционные вещества, способные вызывать смерть, отравление или заболевание
 при попадании внутрь организма или при соприкосновении с кожей и слизистой оболочкой;</p><br>

<p style="text-indent:2em;">подкласс 6.1 - ядовитые (токсичные) вещества, способные вызвать отравление при вдыхании (паров,
 пыли), попадании внутрь или контакте с кожей;</p><br>

<p>подкласс 6.2 - вещества и материалы, содержащие болезнетворные микроорганизмы,</p><br>

<p>опасные для людей и животных.</p><br>

<p style="font-weight:500;">КЛАСС 7 - радиоактивные вещества с удельной активностью более 70 кБк/кг (2 нКи/г).</p><br>

<p style="font-weight:500;">КЛАСС 8 - едкие и коррозионные вещества, которые вызывают повреждение кожи, поражение слизистых
 оболочек глаз и дыхательных путей, коррозию металлов и повреждения транспортных средств, сооружений
 или грузов, а также могут вызывать пожар при взаимодействии с органическими материалами или
 некоторыми химическими веществами;</p><br>

<p>подкласс 8.1 - кислоты,</p><br>

<p>подкласс 8.2 - щелочи;</p><br>

<p>подкласс 8.3 - разные едкие и коррозионные вещества.</p><br>

<p style="font-weight:500;">КЛАСС 9 - вещества с относительно низкой опасностью при транспортировании, не отнесенные ни к одному
 из предыдущих классов, но требующих применения к ним определенных правил перевозки и хранения;</p><br>

<p style="text-indent:2em;">подкласс 9.1 - твердые и жидкие горючие вещества и материалы, которые по своим свойствам не
 относятся к 3 и 4-му классам, но при определенных условиях могут быть опасными в пожарном отношении
 (горючие жидкости с температурой вспышки от +61 C до +100 C в закрытом сосуде, волокна и другие
 аналогичные материалы);</p><br>

<p>подкласс 9.2 - вещества, становящиеся едкими и коррозионными при определенных условиях.</p>

<p style="text-indent:2em;"></p><h6>К опасным грузам, требующим особых мер предосторожности при перевозке, относятся вещества и материалы
 с физико-химическими свойствами высокой степени опасности по ГОСТ 19433-88, далее по тексту "особо
 опасные грузы":</h6><p></p>

<p>Взрывчатые вещества класса 1, кроме подклассов 1.4, 1.5 и 1.6;</p><br>

<p>Радиоактивные вещества класса 7;</p><br>

<p>Не воспламеняющиеся неядовитые газы окисляющие;</p><br>

<p>Ядовитые газы окисляющие;</p><br>

<p style="text-indent:2em;">Ядовитые газы окисляющие, едкие и (или) коррозионные; 6. Легковоспламеняющиеся жидкости с температурой
 вспышки менее минус 18С ядовитые;</p><br>
 
<p>Легковоспламеняющиеся жидкости с температурой вспышки менее минус 18С едкие и (или) коррозионные;</p><br>

<p style="text-indent:2em;">Легковоспламеняющиеся жидкости с температурой вспышки от минус 18С до плюс 23С едкие и (или)
 коррозионные;</p><br>
 
<p style="text-indent:2em;">Легковоспламеняющиеся жидкости с температурой вспышки от минус 18С до плюс 23С ядовитые,
 едкие и (или) коррозионные;</p><br>
 
<p>Легковоспламеняющиеся твердые вещества едкие и (или) коррозионные;</p><br>

<p style="text-indent:2em;">Легковоспламеняющиеся твердые вещества саморазлагающиеся при температуре не более
 50С с опасностью разрыва упаковки;</p><br>
 
<p>Самовозгорающиеся твердые вещества ядовитые;</p><br>

<p>Самовозгорающиеся твердые вещества едкие и (или) коррозионные;</p><br>

<p>Вещества, выделяющие горючие газы при взаимодействии с водой, легковоспламеняющиеся;</p><br>

<p>Вещества, выделяющие горючие газы при взаимодействии с водой, Самовозгорающиеся и ядовитые;</p><br>

<p style="text-indent:2em;">Вещества, выделяющие горючие газы при взаимодействии с водой, легковоспламеняющиеся, едкие и (или) коррозионные;</p><br>

<p>Окисляющие вещества ядовитые, едкие и (или) коррозионные;</p><br>

<p>Органические пероксиды взрывоопасные, саморазлагающиеся при температуре не более 50С;</p><br>

<p>Органические пероксиды саморазлагающиеся при температуре более 50С;</p><br>

<p>Органические пероксиды взрывоопасные;</p><br>

<p>Органические пероксиды без дополнительного вида опасности;</p><br>

<p>Органические пероксиды едкие для глаз;</p><br>

<p>Органические пероксиды легковоспламеняющиеся;</p><br>

<p>Органические пероксиды легковоспламеняющиеся, едкие для глаз;</p><br>

<p>Ядовитые вещества, летучие без дополнительного вида опасности;</p><br>

<p>Ядовитые вещества, летучие легковоспламеняющиеся, с температурой вспышки не более 23С;</p><br>

<p>Ядовитые вещества, летучие легковоспламеняющиеся, с температурой вспышки больше 23С, но не более 6ГС;</p><br>

<p>Ядовитые вещества, летучие едкие и (или) коррозионные;</p><br>

<p>Едкие и (или) коррозионные, обладающие кислотными свойствами ядовитые и окисляющие;</p><br>

<p>Едкие и (или) коррозионные, обладающие кислотными свойствами, окисляющие;</p><br>

<p>Едкие и (или) коррозионные, обладающие кислотными свойствами, ядовитые;</p><br>

<p style="text-indent:2em;">Едкие и (или) коррозионные, обладающие основными свойствами, легковоспламеняющиеся, с температурой вспышки от 23С до 61С;</p><br>

<p>Едкие и (или) коррозионные, обладающие основными свойствами, окисляющие;</p><br>

<p>Едкие и (или) коррозионные разные, ядовитые и окисляющие;</p><br>

<p>Едкие и (или) коррозионные разные, легковоспламеняющиеся, с температурой вспышки не более 23С;</p><br>

<p>Едкие и (или) коррозионные разные, легковоспламеняющиеся, с температурой вспышки от 24С до 61C;</p><br>

<p>Едкие и (или) коррозионные разные, ядовитые.</p><br>

<p><a href="http://lider1.ru/a/klassifikaciya_opasnyh_gruzov_i_ih_harakteristiki">Источник</a></p>

</div>
`