import React from 'react'
// import classnames from "classnames"
// import isFunction from 'lodash/isFunction'

import WidgetBase from '../widget-base'
import Filter from '../widget-filter'

export default class Widget extends React.Component {
  componentDidMount() {
    // this.refreshItems(this.props.items)
  }

  render() {
    const { filterBar, loadLanguages = false, ...rest } = this.props

    return (
      <WidgetBase {...rest}>
        <div className="panel-body">
          {filterBar && <Filter {...filterBar} {...{ loadLanguages }} />}
          <div className="panel-content">{this.props.children}</div>
        </div>
      </WidgetBase>
    )
  }
}
