export const int3 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Комментарий
к Конвенции о договоре международной дорожной перевозки груза</h4><p></p>

<p></p><h4>Convention on the contract for the international carriage of goods by road of 19 may 1956</h4><p></p>

<p style="text-indent:2em;"></p><h6>Преамбула договаривающиеся Стороны, признав желательность унификации условий, регулирующих договор международной дорожной перевозки грузов, и в частности, 
что касается документов, применяемых для такой перевозки, и ответственности перевозчика, согласились о нижеследующем:</h6><p></p>

<p style="text-indent:2em;">Конвенция о договоре международной дорожной перевозки грузов (КДПГ), именуемая далее Конвенция, является базовой, основополагающей нормой международного 
автотранспортного частного права. Она регламентирует общепринятые, сложившиеся в мировой практике правила, обычаи и обыкновения, касающиеся договора 
перевозки. Конвенция устанавливает процедуры, применяемые сторонами договора международной дорожной перевозки грузов в части его заключения; исполнения, 
расторжения, а также устанавливает ответственность перевозчика за неисполнение или ненадлежащее исполнение им условий договора.</p><br>

<p style="text-indent:2em;">Конвенция разработана под эгидой и в рамках Комитета по внутреннему транспорту Европейской экономической Комиссии ООН. Ее подлинный текст опубликован 
ООН на французском и английском языках отдельным изданием за NNE/EE/253, E/ECE/TRANS/480. Среди транспортников Конвенция больше известна под 
аббревиатурой CMR (Convention relative au contrat de transport international de marchandises par roure).</p>

<p></p><h4>Глава I. Область применения Конвенции</h4><p></p>

<p style="font-weight:500;">Статья 1.</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция применяется ко всякому договору дорожной перевозки грузов транспортными средствами за вознаграждение, когда место принятия 
груза и место, предназначенное для сдачи груза, указанные в договоре, находятся в двух разных странах, из которых, по крайней мере, одна является 
участницей Конвенции, независимо от местожительства и национальности сторон договора.</p><br>

<p style="text-indent:2em;">Сферой применения Конвенции являются международные автомобильные перевозки в случаях, когда договорные отношения по перевозке груза предусматривают, 
что груз должен покинуть территорию одной страны и быть перевезен на территорию другой.</p><br>

<p style="text-indent:2em;">Условие “должен” подразумевает намерение сторон по операциям с грузом. Однако возможное пересечение государственной границы не всегда классифицирует 
компетенцию Конвенции в отношении любого договора международной автомобильной 
перевозки грузов. См. комментарий к пункту 5 данной статьи.</p><br>

<p style="text-indent:2em;">Положения Конвенции применяются также в тех случаях, когда грузы, перевозимые транспортными средствами и оформленные по накладной в соответствии с 
договором перевозки, по факту не покинули страну отправления, а были, например, сняты с экспорта или потребованы отправителем к возврату до 
пересечения государственной границы.</p><br>

<p style="text-indent:2em;">Конвенция предусматривает возмездность договора международной автомобильной перевозки грузов. Оплата услуг перевозчика как одно из существенных 
условий договора должна быть формально декларирована в договоре перевозки и в накладной и фактически произведена в соответствии с процедурами и в 
сроки, с которыми согласились стороны договора. Форма вознаграждения за услуги по договору перевозки груза и порядок расчетов между сторонами 
договора перевозки Конвенцией не определена.</p><br>

<p style="text-indent:2em;">Конвенция не применяется в тех случаях, когда отправитель и перевозчик являются одним и тем же лицом (юридическим или физическим) поскольку одно 
и то же лицо не может заключить договор перевозки само с собой. Накладная в этих случаях не будет иметь юридической силы (т.к. нет договора перевозки) 
и формально не обязательна, но оформляется по настоятельным требованиям таможенных властей для ведения статистического учета международных перевозок 
грузов.</p><br>

<p style="text-indent:2em;">Перевозчик и получатель могут быть одним и тем же лицом, при условии, что из договора перевозки следует, что услуги перевозчика будут оплачены 
(в той или иной форме) отправителем с соответствующими отметками в накладной и фактически 
подтверждены соответствующими документами.</p><br>

<p style="text-indent:2em;">Конвенция носит так называемый открытый характер. Для того, чтобы применялись ее положения, достаточно, чтобы хотя бы одно из двух мест — принятия 
груза к перевозке или предназначенное для его сдачи — находились в двух разных странах, из которых хотя бы одна являлась участницей Конвенции. Такая 
особенность юрисдикции Конвенции имеет практическое применение только в тех случаях, когда по договору перевозки или в связи с ним возникают споры между 
сторонами. Любая из сторон договора — отправитель, перевозчик, получатель — могут обратиться к надлежащий суд только страны-участницы Конвенции в соответствии 
с положениями статей 31 и 33 независимо от того, имеют они или нет национальную принадлежность
 страны-участницы Конвенции.</p><br>

<p style="text-indent:2em;">Однако любой суд или арбитраж страны, не участвующей в Конвенции, не примет к рассмотрению иск от любой стороны договора перевозки (независимо от их 
национальной принадлежности), так как будет некомпетентен разрешать спор в соответствии с положениями Конвенции, i если иное не определено в договоре 
и не противоречит статьям 31 и 33” Конвенции. См. комментарии к данным статьям.</p><br>

<p style="text-indent:2em;">Условия договора перевозки, неопределяемые Конвенцией, регулируются нормами того национального законодательства, к соглашению о применении которого 
пришли стороны договора международной перевозки груза. В противном случае споры по условиям договора перевозки, не попадающим под юрисдикцию Конвенции, 
решаются на основе норм коллизионного права по законам страны суда, рассматривающего дело.</p><br>

<p style="text-indent:2em;">I 2. При применении настоящей Конвенции под “транспортным средством” следует понимать автомобили, автомобили-тягачи, прицепы и полуприцепы, так как 
они определяются в статье 4 Конвенции о дорожном движении от 19 сентября 1949 года.</p><br>

<p style="text-indent:2em;">Конвенция о дорожном движении 1949 года была изменена и дополнена следующими международными нормативными актами: Европейским соглашением от 16 
сентября 1950 года, Конвенцией о дорожном движении от 8 ноября 1968 года и Европейским соглашением от 1 мая 1971 года. Для конкретизации понятия 
“транспортное средство” следует пользоваться определениями последних по сроку принятия 
указанных международных договоров.</p><br>

<p style="text-indent:2em;">Возможность использования того или иного транспортного средства 1 определяется и положениями двусторонних межправительственных соглашений о 
международном автомобильном сообщении. На 1 января 2000 года Россия имеет такие соглашения со следующими странами: Азербайджаном от 11.01.93 г. 
Австрией от 03.07.73 г., Албанией от 23.06.93 г., Арменией от 08.09.92 г., Беларусью от 20.07.92 г., Бельгией от 29.11.73 г., Болгарией от 19.05.95 г., 
Великобританией и Северной Ирландией от 22.01.88 г., Венгрией от 19.03.66 г., Германией от 14.07.93 г., Грецией от 12.02.85 г., Грузией от 03.02.94 г., 
, Данией от 03.12.71 г., Ираном от 17.08.92 г., Ирландией от 09.12.94 г., Испанией от 20.05.86 г., Италией от 19.06.84 г., Казахстаном от 23.03.92 г., 
Кипром от 30.01.90 г., Китаем от 18.12.92 г., Кыргызстаном от 10.06.92 г., Латвией от 22.02.95 г., Люксембургом от 22.10.90 г., Литвой от 26.07.96 г., 
Молдовой от 16.07.93 г., Нидерландами от 26.11.71 г., Норвегией от 13.12.74 г., Польшей от 07.05.66 г., Румынией от 21.06.66 г. и от 08.10.68 г., 
Таджикистаном от 20.10.92 г., Турцией от 20.06.88 г., Узбекистаном от 18.03.93 г., Украиной от 21.03.95 г., Финляндией от 18.10.68 г., Францией от 
20.04.73 г., Швецией от 25.11.70 г., Чехией от 13.03.98 г., Словакией от 03.02.67 г. (между СССР и ЧССР), Македонией от 21.10.97 г. В отношении Югославии
, Словении, Хорватии, Сербии, Боснии и Герцеговины действует межправительственное 
Соглашение между СССР и СФРЮ от 18.06.70 г.</p><br>

<p style="text-indent:2em;">3. Настоящая Конвенция применяется также тогда, когда перевозки, входящие в область ее применения, осуществляются государствами или правительственными
 учреждениями или организациями.</p><br>

<p>Без комментариев.</p>

<p style="text-indent:2em;"></p><h6>4. Настоящая Конвенция не применяется:</h6><p></p>

<p>а) к перевозкам, осуществляемым согласно международным почтовым конвенциям;</p><br>

<p>в) к перевозкам покойников;</p><br>

<p>с) к перевозкам обстановки и мебели при переездах.</p><br>

<p style="text-indent:2em;">Декларируется перечень грузов, к перевозкам которых не применяются положения Конвенции. Указанный перечень категорий грузов на самом деле существенно
 расширен указанными выше двусторонними, а также многосторонними межправительственными соглашениями о международном автомобильном сообщении. 
 Конвенция согласно соглашениям не применяется, когда грузы некоммерческого характера оформляются для перевозки в международном автомобильном 
 сообщении для их использовании владельцами (отправителями и получателями в одном и том же лице) на территории иностранного государства с 
 обязательным последующим возвратом в страну отправления. К таким грузам относятся: транспортные средства, животные и инвентарь для проведения 
 спортивных мероприятий; экспонаты и оборудование для выставок и ярмарок;</p><br>

<p style="text-indent:2em;">театральные декорации и реквизит; музыкальные инструменты; оборудование для кино- и фотосъемок, радио- и телепередач; поврежденные автотранспортные 
средства; запасные части для непосредственной их замены на неисправных автотранспортных средствах и пр.</p><br>

<p style="text-indent:2em;">Следует отметить, что отнесение ярмарочных и выставочных грузов к некоммерческим не исключает возможности их последующей реализации (дарения, а 
иногда и продажи) в стране назначения.</p><br>

<p style="text-indent:2em;">5. Договаривающиеся Стороны согласились не изменять положения настоящей Конвенции путем специальных соглашений между двумя или несколькими 
Договаривающимися Сторонами, за исключением отмены ее применения к их пограничным перевозкам или разрешения использования накладных, 
представляющих право собственности на груз при перевозках, осуществляемых исключительно в пределах своих территорий.</p><br>

<p style="text-indent:2em;">Декларируется императивный характер Конвенции в отношении применения ее положений к условиям международной автомобильной перевозки груза. 
Участники Конвенции договорились использовать ее положения без изменений, изъятий и дополнений. См. комментарий к статье 41.</p><br>

<p style="text-indent:2em;">Упомянутые выше межправительственные соглашения о международном автомобильном сообщении (Статья 1, пункты 3,4) не затрагивают положений Конвенции, 
а регламентируют лишь такие взаимоотношения стран-участниц Конвенции, которые ею не определены. Соглашения определяют принципиальные положения, 
касающиеся: автомобильных дорог, открытых для использования в международном сообщении; водительских прав и регистрационных документов на транспортные 
средства; разрешительной системы осуществления перевозок; страхования гражданской ответственности перед третьими лицами; уровня налогов и сборов, 
в т. ч. и дорожных и порядок их взимания; процедур пограничного, таможенного, санитарного, ветеринарного и иных видов контроля;</p><br>

<p style="text-indent:2em;">расчетов и платежей; санкций за нарушение перевозчиками условий соглашений и пр. Заключаемые соглашения в большинстве своем унифицированы по 
форме и близки по содержанию.</p><br>

<p style="text-indent:2em;">Возможность применения положений Конвенции при перевозках между пунктами отправления и назначения, находящихся на территории одной и той же страны 
транзитом по территории другой (других) страны (стран), определяется соответствующими соглашениями заинтересованных сторон. Такие соглашения могут 
признать как юрисдикцию настоящей Конвенции, так и регулирование перевозки нормами национального права в пределах каждой страны, по территории 
которой осуществляется такая перевозка.</p><br>

<p style="text-indent:2em;">Подобные соглашения заключаются соседними государствами и для осуществления международных автомобильных перевозок в рамках реализации процедур 
приграничной торговли между ними. Международные перевозки грузов между пунктами отправления и назначения, расположенными в пределах установленных 
по соглашению сторон приграничных зон, регулируются упрощенными правилами, вырабатываемыми, однако, как правило, на основе положений Конвенции.</p><br>

<p style="text-indent:2em;">Накладная на международную перевозку груза, оформляемая в соответствии с положениями Конвенции, не является оборотным транспортным документом. 
Она не подлежит индоссированию и с ее помощью нельзя передавать права собственности на перевозимый груз. Однако Конвенция не исключает возможности 
применения (дополнительно к накладной) иных документов, как правило, товарораспорядительного характера, по которым могут передаваться права 
собственности на грузы в пределах страны назначения. Такие документы не имеют для перевозчика юридической силы, так как не относятся к договору 
международной автомобильной перевозки грузов. Дополнительные товарораспорядительные документы важны для фактических получателей грузов, если в 
процессе переадресовки грузов такие документы были индоссированы в их пользу. См. комментарий к статье 12.</p><br>

<p style="font-weight:500;">Статья 2.</p><br>

<p style="text-indent:2em;">1. Когда транспортное средство, содержащее груз, часть пути проходит по морю, железной дороге, внутреннему водному пути или воздушным путем, и, 
кроме случая, когда применяются положения статьи 14, груз с транспортного средства не перегружается, настоящая Конвенция применяется ко всей 
перевозке в целом. Однако, если будет доказано, что утрата груза, его повреждение или просрочка в доставке произошли во время перевозки и не 
были вызваны действием или упущением автомобильного перевозчика, а были вызваны событием, которое могло произойти только во время и по причине 
перевозки, осуществляемой этим другим видом транспорта, ответственность автомобильного перевозчика определяется не настоящей Конвенцией, а 
таким образом, каким определялась бы ответственность перевозчика другого вида транспорта, если бы договор перевозки груза был бы заключен 
отправителем с перевозчиком другого вида транспорта в соответствии с императивными нормами закона о перевозке груза этим видом транспорта. 
Однако, если такие императивные нормы отсутствуют, то ответственность автомобильного перевозчика определяется настоящей Конвенцией.</p><br>

<p style="text-indent:2em;">Если груз не перегружается из транспортного средства до момента его прибытия в пункт назначения, то к такой международной автомобильной 
перевозке применяются положения настоящей Конвенции, несмотря на то, что часть договорного маршрута автотранспортное средство с грузом само
 перевозится как груз на других видах транспорта. Это означает, что автотранспортное средство с находящимся в нем грузом является объектом 
 договора перевозки, для которого автомобильный перевозчик является отправителем и получателем в одном лице, а в качестве перевозчика 
 выступает предприятие другого вида транспорта.</p><br>

<p style="text-indent:2em;">При отсутствии вины международного автомобильного перевозчика, его ответственность в таких случаях за повреждение, порчу, полную или 
частичную утрату груза, а также за просрочку в доставке определяется перед отправителем или получателем самого груза по тем же нормам 
и правилам, по которым перед автомобильным перевозчиком отвечает перевозчик другого вида транспорта. При возникновении такой ситуации 
автомобильный перевозчик должен возместить ущерб правомочному по договору международной автомобильной перевозки лицу в размере, 
предусмотренном императивным нормативным актом другого вида транспорта. После этого автомобильный перевозчик вправе на основании 
своих договорных отношений с перевозчиком другого вида транспорта потребовать от последнего возврата выплаченных сумм в порядке 
регресса.</p><br>

<p style="text-indent:2em;">Бремя доказывания отсутствия вины автомобильного перевозчика перед правомочным по договору лицом и обоснования размеров выплат в 
соответствии с нормами императивного права на других видах транспорта лежит на автомобильном перевозчике.</p><br>

<p style="text-indent:2em;">2. Если автомобильный перевозчик также осуществляет перевозки и другими видами транспорта, его ответственность также определяется 
пунктом 1 настоящей статьи, как если бы его функция автомобильного перевозчика и функция перевозчика другого вида транспорта 
осуществлялись бы двумя различными лицами.</p><br>

<p style="text-indent:2em;">Возможность перевозки груза на какой-либо части маршрута другим (не автомобильным) видом транспорта должна быть отражена в договоре 
международной автомобильной перевозки. При этом перевозчик, осуществляющий доставку груза на различных (по видам транспорта) участках 
комбинированной перевозки (при обязательном автомобильном участке), может быть одним и тем же юридическим лицом. Тогда ответственность 
такого перевозчика за несохранность груза или нарушение сроков доставки, происшедшие именно на неавтомобильной части маршрута, 
определяется в соответствии с императивными нормами закона о договоре перевозки груза на ином
 виде транспорта.</p>

<p style="text-indent:2em;"></p><h6>Основными международными правовыми актами частного транспортного права на других видах
 транспорта являются:</h6><p></p>

<p style="text-indent:2em;">1. На морском транспорте — Брюссельская Конвенция об унификации некоторых правил о коносаменте, 1924 г. с изменениями и дополнениями 
в Протоколе 1968 г. и Конвенция ООН о морской перевозке груза 1978 г. Иногда эти конвенции называют, соответственно, 
Гаагско-Висбийскими правилами и Гамбургскими правилами. Россия не является участницей ни одной из вышеупомянутых конвенций, 
однако нормы внутреннего законодательства, изложенные в Кодексе торгового мореплавания Российской Федерации применительно к 
международным сообщениям, почти полностью соответствуют положениям Гаагско-Висбийских правил.</p><br>

<p style="text-indent:2em;">2. На железнодорожном транспорте — Международные Бернские грузовые конвенции (последняя редакция 1985 г.), именуемые, как правило, 
по их полной французской аббревиатуре CIM-COTIF и Соглашение о международном железнодорожном грузовом сообщении (СМГС), (последняя 
редакция 1998 г.). Россия является участницей СМГС и не входит в CIM-COTIF.</p><br>

<p style="text-indent:2em;">3. На воздушном транспорте — Варшавская Конвенция для унификации некоторых правил, касающихся международных воздушных перевозок, 
1929 г., с изменениями в Гаагском Протоколе 1955 г. Россия является участницей этих международных договоров как правопреемник СССР, 
который ратифицировал Конвенцию и Протокол, соответственно в 1934 и 1957 гг.</p><br>

<p style="text-indent:2em;">4. Для смешанных перевозок грузов — Конвенция ООН о смешанной перевозке грузов, Женева, 1980 г. и Правила ЮНКТАД-МТП в отношении 
документов смешанных перевозок, Женева, 1992 г. И Конвенция, не вступившая до сих пор в силу, и Правила, являются факультативными 
нормами. Поэтому каждая их них регулируют условия договора смешанной перевозки только в том случае, когда стороны договора признали 
это целесообразным. На практике чаще всего применяются Правила.</p><br>

<p style="text-indent:2em;">Если порядок исчисления возмещения и размер сумм, подлежащих выплате, будут иными, нежели те, которые указаны в настоящей Конвенции, 
на перевозчике лежит бремя доказывания того, что повреждение, порча или утрата груза, а также нарушение срока доставки произошли именно 
на том участке маршрута, на котором автомобильный перевозчик фактически не являлся таковым, а являлся перевозчиком другого вида транспорта. 
В таком договоре (и в накладной) международной автомобильной перевозки должны быть оговорены маршрут перевозки и возможность использования 
иных видов транспорта (при сохранении целостности автотранспортного средства) или оговорка о том, что перевозчик вправе выбрать способ 
доставки груза по своему усмотрению на основе принципов разумности и добросовестности.</p>

<p></p><h4>Глава II. Ответственность перевозчика за других лиц.</h4><p></p>

<p style="font-weight:500;">Статья 3.</p><br>

<p style="text-indent:2em;">При применении настоящей Конвенции перевозчик отвечает как за свои собственные действия и упущения, так и за действия и упущения своих 
агентов, своих служащих и всех других лиц, к услугам которых он прибегает, когда эти агенты, служащие или другие лица действуют в рамках, 
возложенных на них обязанностей.</p><br>

Если в договоре перевозки не оговорено противного, перевозчик вправе в целях надлежащего его исполнения привлекать любых юридических или 
физических лиц. Перевозчик отвечает в полной мере за действия и упущения своих агентов, экспедиторов, а также служащих как за свои собственные,
 если только не докажет, что такие виновные лица и или служащие действовали за пределами возложенных на них обязанностей или вне области, 
 определенной договором перевозки. См, комментарий к статье 29.<p></p>

<p></p><h4>Глава III. Заключение и исполнение договора перевозки.</h4><p></p>

<p style="font-weight:500;">Статья 4.</p><br>

<p style="text-indent:2em;">Договор перевозки подтверждается составлением накладной. Отсутствие, неправильность или утрата накладной не влияют ни на существование, ни 
на действительность договора перевозки, к которому и в этом случае применяются
 положения настоящей Конвенции.</p><br>

<p>Накладная не является договором перевозки, а лишь подтверждает факт его наличия.</p>

<p style="text-indent:2em;"></p><h6>Любая международная автомобильная перевозка оформляется договором, согласно которому перевозчик обязуется доставить груз в указанное место 
и время за установленное вознаграждение, а грузовладелец обязуется предоставить груз и оплатить транспортные услуги. Договор перевозки должен
 иметь письменную форму. Сторонами договора являются:</h6><p></p>

<p>перевозчик— с одной стороны, и отправитель или получатель — с другой.</p><br>

<p style="text-indent:2em;">Формально стороной договора перевозки является отправитель. Но иногда специально оговаривается, что функции и обязанности отправителя 
исполняет получатель груза. В последнем случае в договоре перевозки необходимо определенно указывать, что именно получатель является 
правомочным по договору лицом и имеет право распоряжаться грузом.</p><br>

<p style="text-indent:2em;">Конвенция содержит понятие “отправитель”, вкладывая в него и правовое и функциональное содержание: отправитель — сторона договора международной
 перевозки груза и поэтому единственное для перевозчика правомочное по договору лицо до момента передачи груза получателю и отправитель — лицо,
 передающее груз перевозчику. См. комментарий к пункту 3 статьи 12.</p><br>

<p style="text-indent:2em;">Договор перевозки составляется, как правило, в двух формах — совместно подписанного
 документа и безусловного акцепта твердой оферты.</p><br>

<p style="text-indent:2em;">Договор перевозки в форме совместно подписанного документа составляют, как правило, в том случае, когда стороны имеют намерения. установить 
долгосрочные отношения, связанные с перевозками грузов в международных автомобильных сообщениях. Такой договор чаще всего именуют бланкетным 
договором или “Генеральным соглашением”, в котором стороны стараются оговорить все возможные аспекты отношений друг с другом, связанных с 
организацией и осуществлением перевозок, а также вопросы ответственности сторон по условиям договора международной перевозки груза, не 
поименованных в Конвенции.</p><br>

<p style="text-indent:2em;">Договор перевозки в форме безусловного акцепта твердой оферты оформляется, как правило, в случаях начальных или эпизодических контактов между 
сторонами. Договор считается заключенным, когда конкретное адресное предложение (оферта) одной из сторон полностью принято (акцептовано) другой. 
Письменная форма договора означает в этом случае кроме прочего телекс, факс
 и иные регистрируемые формы передачи и приема сообщений.</p><br>

<p style="text-indent:2em;">Важнейшим для любого договора перевозки является предмет соглашения, в котором его стороны явственно выражают намерения исполнить все оговоренные 
условия.</p><br>

<p style="text-indent:2em;">Конвенция регулирует не все возможные аспекты взаимоотношений между сторонами договора международной автомобильной перевозки груза. Например, 
отсутствуют положения о принципах организации перевозок, сроках предъявления и удовлетворения заявок на перевозки, об уровне применяемых ставок 
и тарифов, о формах и сроках расчетов между сторонами, о сроках подачи, погрузки и выгрузки транспортных средств, таможенного оформления грузов 
и транспортных средств и др.</p><br>

<p style="text-indent:2em;">В “Генеральном соглашении” эти и другие вопросы могу быть оговорены сторонами и регулируются выбранной сторонами договора нормой национального 
гражданского права. В договоре по форме “Оферта-акцепт” вопросы, не относящиеся к юрисдикции Конвенции, разрешаются на основе коллизионных норм 
применимого права.</p><br>

<p style="font-weight:500;">Статья 5.</p><br>

<p style="text-indent:2em;">1. Накладная составляется в трех экземплярах, подписанных отправителем и перевозчиком. Эти подписи могут быть отпечатаны типографским способом
 или заменены штемпелями отправителя и перевозчика, если это допускается законом страны, в которой составлена накладная. Первый экземпляр накладной
 передается отправителю, второй сопровождает груз, а третий остается у перевозчика.</p><br>

<p style="text-indent:2em;">Форма накладной (CMR Internationaler Frachtbrief) Конвенцией не определена. Международным Союзом Автомобильного Транспорта (МСАТ) была
 разработана форма накладной, рекомендуемая перевозчикам, экспедиторам, операторам и агентам для оформления и подтверждения договоров перевозки
 груза. Однако каждое из упомянутых последними лиц вправе разрабатывать и применять наиболее удобную для него форму перевозочного документа в
 соответствии с законами и нормативными актами своей страны. Любая форма накладной должна содержать обязательные и дополнительные реквизиты,
 поименованные в статье 6 Конвенции.</p><br>

<p style="text-indent:2em;">Комплект перевозочных документов (накладных) может состоять из любого количества экземпляров, однако только первые три поименованные в данной
 статье имеют одинаковую юридическую силу. Остальные являются копиями. Количество копий накладной определяется, как правило, числом пересекаемых
 грузом государственных границ, а также национальными таможенными процедурами. Точное количество оформляемых копий указывается в двусторонних
 межправительственных соглашениях о международных автомобильных сообщениях.</p><br>

<p style="text-indent:2em;">2. Когда подлежащий перевозке груз должен быть погружен на несколько автомобилей или же является разнородным или разделенным на разные партии
, отправитель или перевозчик имеют право потребовать составления накладной на каждый используемый автомобиль или на груз каждого рода или на
 каждую партию груза.</p><br>

<p style="text-indent:2em;">если договор предусматривает перевозку партии груза в объеме (количестве), превышающем вместимость (грузоподъемность) указанного в договоре
 типа транспортного средства, то накладных следует оформлять столько, сколько транспортных средств необходимо использовать для осуществления
 перевозки всего груза. Деление груза на несколько транспортных средств с оформлением соответствующего количества накладных может быть затребовано
 отправителем или перевозчиком в тех случаях, когда к перевозке предъявляются грузы, по любой причине несовместимые для совместной транспортировки
 (химические, физические, биологические и иные свойства грузов), а также по требованию
 таможенных органов.</p><br>

<p style="text-indent:2em;">Перевозка нескольких партий грузов (отправок) в одном транспортном средстве допускается только при полном соблюдении процедур в соответствии с
 Конвенцией МДП (Таможенной конвенцией о международной перевозке грузов с применением книжки МДП от 14 ноября 1975 года). В Конвенции МДП указано,
 что в одном транспортном средстве можно перевозить несколько отправок при соблюдении следующих условий: общее число таможен мест отправления и мест
 назначения грузов не должно быть более 4; все таможни места отправления грузов должны находиться в одной стране; таможни мест назначения грузов
 должны находиться не более чем в двух странах; партии груза должны быть размещены в автотранспортном средстве таким образом, чтобы был возможен 
 досмотр каждой из них.</p><br>

<p style="font-weight:500;">Статья 6.</p>

<p style="text-indent:2em;"></p><h6>1. Накладная должна содержать следующие данные:</h6><p></p>

<p>a) дата накладной и место ее составления;</p><br>

<p>b) наименование и адрес отправителя;</p><br>

<p>c) наименование и адрес перевозчика;</p><br>

<p>d) место и дата принятия груза и место, предназначенное для доставки;</p><br>

<p>e) наименование и адрес получателя;</p><br>

<p style="text-indent:2em;">Адреса, указываемые в подпунктах а) — е) накладной имеют значения для определения мест обращения с исками в надлежащие суды в соответствие с пунктом1
 статьи 31 Конвенции. При ввозе товаров на территорию России в подпункте d) указывается наименование и адрес таможенного органа, куда перевозчиком
 должен быть доставлен груз.</p><br>

<p style="text-indent:2em;">О принятое обозначение характера груза и род его упаковки и, в случае
 перевозки опасных грузов, их обычно признанное обозначение;</p><br>

<p>Без комментариев.</p><br>

<p>g) число грузовых мест, их специальная маркировка и нумерация мест;</p><br>

<p>Без комментариев.</p><br>

<p>h) вес груза брутто или выраженное в других единицах измерения количество груза;</p><br>

<p>Без комментариев.</p><br>

<p style="text-indent:2em;">i) платежи, связанные с перевозкой (провозные платежи, дополнительные платежи, таможенные пошлины и сборы), а также другие платежи, взимаемые с момента
 заключения договора и до сдачи груза;</p><br>

<p>Без комментариев.</p><br>

<p>j) инструкции, требуемые для выполнения таможенных и других формальностей;</p><br>

<p>См. комментарий к статье 11.</p><br>

<p style="text-indent:2em;">k) указание, что перевозка осуществляется независимо от любых оговорок
 согласно положениям настоящей Конвенции.</p><br>

<p>См. комментарий к пункту 3 статьи 7.</p>

<p style="text-indent:2em;"></p><h6>2. В случае необходимости, накладная должна также содержать следующие данные:</h6><p></p>

<p>a) указание, что перегрузка не разрешается;</p><br>

<p style="text-indent:2em;">Право отправителя сделать подобную оговорку в накладной, как ^правило, объясняется причинами, побудившими отправителя на такое заявление. Обычно запрет
 перегрузки в другое транспортное средство мотивируется состоянием груза, спецификой его укладки и крепления, невозможностью его застропки, смещенным
 центром масс груза и др. Такая оговорка является полезной для перевозчика, так как проясняет ему характер и свойства груза, реальное состояние тары и
 упаковки, последствия манипуляций с грузом и пр.</p><br>

<p>b) платежи, которые отправитель обязан оплатить;</p><br>

<p style="text-indent:2em;">Плательщик транспортных услуг (отправитель или получатель) определяется договором перевозки и в случае необходимости указывается в накладной. Перевозчик
 предпочитает строить свои финансовые отношения с отправителем и получать оговоренные провозные платежи до начала процесса транспортировки груза. Однако
 на практике плательщик (или плательщики) по договору перевозки определяются не произвольно, а в соответствии с тем, на каких базисных условиях поставки
 был заключен договор купли-продажи товаров, о доставке которых и был заключен данный 
 договор международной перевозки груза.</p><br>

<p style="text-indent:2em;">Обязанности сторон договора купли-продажи нести те или иные транспортные расходы, то есть полностью или частично оплачивать перевозчику провозные платежи,
 определены Правилами толкования международных торговых терминов (Инкотермс) соответствующего года издания по официальным документам Международной Торговой
 Палаты. Чаще всего Инкотермс используется в редакции 1990 и 2000 гг.</p><br>

<p style="text-indent:2em;">В соответствии с этими документами оплата международных автотранспортных услуг по перевозке товаров производится продавцом (отправителем), если договор
 купли-продажи заключен на базисных условиях СРТ (перевозка оплачена до), CIP (перевозка и страхование оплачены до), DDU (поставка без оплаты пошлины)
 и DDP (поставка с оплатой пошлины).</p><br>

<p style="text-indent:2em;">Покупатель (получатель) оплачивает перевозку в тех случаях, когда стороны договора купли-продажи согласились с одним из следующих базисных условий: EXW
 (с завода) и FCA (франке перевозчик).</p><br>

<p style="text-indent:2em;">Указание платежей, которые обязуется оплатить отправитель, обозначается в договоре перевозки и в накладной в тех случаях, когда товары доставляются в
 соответствии с договорами купли-продажи, заключенными на условии DAF (поставка на границе). В этих случаях продавец (отправитель) обязуется оплатить
 лишь часть обусловленных провозных платежей (обычно до поименованного в базисном условии автомобильного погранперехода), а окончательные расчеты за
 всю перевозку перевозчик производит с покупателем (получателем) товара. На практике подобное встречается крайне редко, так как перевозчик старается
 строить свои финансовые отношения с одним — известным ему и надежным плательщиком. Чаще всего им становится отправитель или лицо, его представляющее,
 например, экспедитор, реже — получатель.</p><br>

<p>c) сумма платежа, подлежащего оплате при сдаче груза;</p><br>

<p style="text-indent:2em;">Присутствие в накладной и величина такого платежного показателя обуславливается
 наличием и конкретным содержанием подпункта Ь).</p><br>

<p>d) объявленная стоимость груза и сумма, представляющая специальный интерес в доставке;</p><br>

<p style="text-indent:2em;">Объявляемая отправителем стоимость груза не имеет ничего общего с практикой проставления в накладной цены товара, которая используется российскими
 таможенными органами для определения таможенной стоимости импортного товара для целей взыскания акцизов, налогов, пошлин и сборов. Необходимость
 объявлять (декларировать) в накладной стоимость груза возникает тогда, когда фактическая (действительная) стоимость товара выше максимального предела
 ответственности перевозчика в случаях повреждения, порчи, полной или частичной утраты груза. См. комментарии к статьям 23 и 24 Конвенции и к статье 2
 Протокола к Конвенции от 5 июля 1978 г.</p><br>

<p style="text-indent:2em;">Часто перевозчик в целях поддержания своего авторитета и репутации добровольно декларирует грузовладельцу отказ от своих прав пользования пределом
 ответственности в виде указания в накладной (фактической (действительной) стоимости груза без взыскания за эту услугу дополнительных платежей и
 сборов.</p><br>

<p style="text-indent:2em;">Действительный смысл оговорки в накладной поданному подпункту заключается в другом. Сумма, представляющая специальный интерес в доставке, указывается
 отправителем в накладной в тех случаях, когда размер полного возмещения перевозчика за утраченный или недоставленный груз меньше, чем заранее известные
 убытки грузовладельца. Именно размер возможных убытков объявляется как сумма, представляющая специальный интерес в доставке. Если убытки возникают у
 грузовладельца только при недоставке товара в пункт назначения позже определенной даты (задержка в доставке груза), то следует объявлять не сумму,
 представляющую специальный интерес в доставке, а сумму, представляющую специальный интерес в доставке в конкретно согласованный с перевозчиком срок.
 Такая дополнительная договоренность должна быть подтверждена соответствующей отметкой в 
 накладной. См комментарий к статье 26.</p><br>

<p>е) инструкции отправителя перевозчику относительно страхования груза;</p><br>

<p style="text-indent:2em;">Инструкции о порядке и условиях страхования груза дается отправителем перевозчику в тех случаях, когда в соответствии с определенным в договоре купли
-продажи базисом поставки на продавце лежит обязанность или необходимость застраховать товар (при использовании базисных условий CIP,DDU,DDP). Кроме
 того, перевозчик должен согласиться с тем, что помимо своих основных обязанностей по договору перевозки он будет также исполнять функции экспедитора.
 Такие инструкции должны содержать: наименование выгодоприобретателя по договору страхования груза, одно из трех условий транспортного страхования груза 
 (А, В или С) в соответствии с Правилами института лондонских страховщиков, размер объявленной страховой суммы, максимально возможная ставка страховой
 премии, ограничения по оговоркам франшизы и абандона, дополнительно объявляемые страховщику риски, названия фирм наиболее приемлемых страховщиков. См.
 комментарий к пункту 2 статьи 41.</p><br>

<p>f) согласованный срок, в течение которого перевозка должна быть осуществлена;</p><br>

<p style="text-indent:2em;">Конвенция не содержит нормативов, в соответствии с которыми следует исчислять сроки доставки груза. Определение срока доставки в договоре и указание
 его в накладной необязательно, однако стороны договора перевозки вправе определить и зафиксировать этот показатель. Однако, указанный в накладной срок
 доставки не возлагает на перевозчика никаких финансовых обязательств, если о них ничего 
 конкретно не упомянуто в договоре перевозки.</p><br>

<p style="text-indent:2em;">В качестве европейского обычая, при исчислении срока доставки пользуются следующими нормативами суточного пробега транспортного средства: 600 км при
 перевозке груза по скоростным автомагистралям, 450 км по обычным шоссейным дорогам, 
 400 км по дорогам России и стран СНГ.</p><br>

<p>g) перечень документов, переданных перевозчику.</p><br>

<p style="text-indent:2em;">Отправитель может передать перевозчику любые документы, в которых содержатся дополнительные, по сравнению с накладной, сведения о грузе, отправителе,
 получателе, таможнях мест отправления и назначения и др. Обычно к накладной прикладываются все или некоторые из товаросопроводительных документов
 (счет-фактура, отгрузочная спецификация, фактура-спецификация, сертификат качества товара, сертификат происхождения товара, сертификат соответствия,
 упаковочный лист, карантинный сертификат, ветеринарный сертификат, санитарный сертификат и др.), таможенные документы (книжка МДП, грузовая таможенная
 декларация и пр.), страховые полисы, банковские гарантии и др. Условия внесения в накладную сведений о предоставленных перевозчику документах определяются
 положениями статьи 11.</p><br>

<p style="text-indent:2em;">3. Стороны договора перевозки могут внести в накладную любые другие данные, 
которые будут ими признаны необходимыми.</p><br>

<p style="text-indent:2em;">Любые данные, условия и оговорки, вносимые сторонами договора перевозки должны соответствовать реальному положению дел; предписания перевозчику должны
 иметь возможность фактического их исполнения. В любом случае, вносимые в накладную данные не должны противоречить императивным нормам Конвенции и
 нормам применимого национального права.</p><br>

<p style="text-indent:2em;">В накладную могут быть внесены, например, сроки подачи транспортного средства, его погрузки и выгрузки, маршрут следования, наименование экспедиторских
 и агентских фирм, представляющие стороны договора перевозки, их контактные телефоны и пр.</p><br>

<p style="font-weight:500;">Статья 7.</p>

<p style="text-indent:2em;"></p><h6>1. Отправитель несет ответственность за все расходы и убытки, возникшие у перевозчика
 вследствие неправильности или недостаточности:</h6><p></p>

<p>а) данных, указанных в подпунктах “b”, “d”, “е”, “f”, g”, “h” и “j” пункта 1 статьи 6;</p><br>

<p>b) данных, указанных в пункте 2 статьи 6;</p><br>

<p style="text-indent:2em;">с) всех других данных или инструкций, сообщенных отправителем для составления или для включения в накладную.</p>

<p style="text-indent:2em;"></p><h6>Отправитель несет материальную ответственность за полноту 
и достоверность любых сведений, указанных в накладной за исключением:</h6><p></p>

<p style="text-indent:2em;">даты и места составления накладной, наименования перевозчика и его адреса, а также наличия в накладной оговорки в соответствии с подпунктом к) пункта 1
 статьи 6. Однако перевозчик должен доказать, что его убытки вызваны виной отправителя, неправильно или неполно оформившего накладную. Например, если
 отправитель неточно указал в накладной место, предназначенное для доставки груза, то перевозчик должен доказать и размер ущерба и то, что ущерб вызван
 именно неправильностью приведенного в накладной адреса получателя.</p><br>

<p style="text-indent:2em;">2. Если, по просьбе отправителя перевозчик вносит в накладные данные, относящиеся к пункту 1 настоящей статьи, то признается, до доказательства иного,
 что это им сделано от имени отправителя.</p><br>

<p style="text-indent:2em;">Поручение отправителя другим лицам (торговому представителю, экспедитору, агенту перевозчика и пр.) оформить накладную не освобождает его от ответственности
 за полноту и правильность составленного документа. Отправитель будет освобожден от ответственности, если докажет, что не давал поручения никаким третьим
 лицам (в том числе и перевозчику) оформлять от его имени накладную или вносить в нее 
 какие-либо сведения.</p><br>

<p style="text-indent:2em;">3. Если накладная не содержит указания, предусмотренного в подпункте “k” пункта 1 статьи 6, перевозчик отвечает за все расходы и за все убытки, которые
 лицо, имеющее право распоряжаться грузом, может понести вследствие такого упущения.</p><br>

<p style="text-indent:2em;">Конвенция является единственным общепризнанным нормативным актом, регулирующим условия договора перевозки груза в международном автомобильном сообщении.
 Как правило, все накладные, подтверждающие договоры перевозок, оформляются в соответствии с положениями Конвенции (о чем свидетельствуют аббревиатуры 
 “CMR” на бланках) и содержат оговорку подпункта к) пункта 1 статьи 6. Юрисдикция Конвенции может быть признана в судебном порядке даже при отсутствии 
 в накладной такой оговорки. Если это приведет к убыткам грузовладельца (отправителя, получателя), то перевозчик будет вынужден возместить их в полном 
 объеме без права применения изъятий и ограничителей ответственности.</p><br>

<p style="font-weight:500;">Статья 8.</p>

<p style="text-indent:2em;"></p><h6>1. При принятии груза перевозчик обязан проверить:</h6><p></p>

<p style="text-indent:2em;">a) правильность записей, сделанных в накладной относительно числа грузовых мест, 
а также их маркировки и нумерации мест;</p><br>

<p>b) внешнее состояние груза и его упаковки.</p><br>

<p style="text-indent:2em;">Если в накладной перевозчик не сделал никаких мотивированных оговорок относительно числа грузовых мест, их маркировки и нумерации, а также внешнего
 состояния груза и его упаковки, действует презумпция того, что сведения указанные отправителем согласно положениям подпунктов д) и п) пункта 1 статьи
 6 верны, а сам груз в момент его приема перевозчиком находился в коммерчески исправном
 состоянии, соответствующем условиям перевозки.</p><br>

<p style="text-indent:2em;">2. Если перевозчик не имеет достаточной возможности проверить правильность записей, упомянутых в подпункте “а” пункта 1 настоящей статьи, он должен
 вписать в накладную оговорки, которые он должен обосновать. Он таким же образом должен обосновать все сделанные им оговорки, касающиеся внешнего
 состояния груза и его упаковки. Эти оговорки не обязательны для отправителя, если
 последний ясно выражено не указал об этом в накладной.</p><br>

<p style="text-indent:2em;">Обоснованность оговорок перевозчика должна быть письменно принята отправителем. Односторонние пометки перевозчика в накладной типа “Принято без подсчета
 количества мест”, “При погрузке не присутствовал” и пр. не имеют доказательной силы. С другой стороны, отказ отправителя признать очевидные несоответствия
 , обнаруженные перевозчиком поданным, проверяемым им согласно пункту 1 статьи 8 Конвенции, может привести к негативным последствиям вплоть до расторжения
 договора перевозки по вине отправителя.</p><br>

<p style="text-indent:2em;">Перевозчик может не выполнять свои обязанности в соответствии с пунктом 1 статьи 8, если груз ему передан в исправном транспортном средстве или контейнере
 за чужими исправными пломбами (отправителя, экспедитора, таможни). В этом случае перевозчик не несет ответственности за сохранность и состояние груза, а
 лишь отвечает за надлежащее состояние транспортного средства или контейнера и целостность
 пломб и иных охранных знаков и устройств.</p><br>

<p style="text-indent:2em;">3. Отправитель имеет право потребовать проверки перевозчиком веса брутто груза или его количества, выраженного в других единицах измерения. Он может также
 потребовать проверки содержимого грузовых мест. Перевозчик может потребовать возмещения расходов, связанных с проверкой. Результаты проверок вносятся в
 накладную.</p><br>

<p style="text-indent:2em;">Отправитель может потребовать, чтобы перевозчик проверил массу (количество) груза, а также содержимое грузовых мест для того, чтобы впоследствии перевозчик
 не смог воспользоваться ограничениями своей ответственности. После выполнения указанных проверок перевозчик не сможет впоследствии сослаться на то, что не
 имел достоверных сведений о массе, объеме или количестве принятого им груза, а также, что не знал и не мог знать о том, какой в действительности груз
 находился внутри объявленных мест и какого было его реальное состояние к моменту начала перевозки. Результаты проверок заносятся в накладную и подписываются
 правомочными представителями сторон. Стоимость поверочных операций должна быть обоснована перевозчиком и объявлена до начала выполнения проверок.</p><br>

<p style="font-weight:500;">Статья 9.</p><br>

<p style="text-indent:2em;">1. Накладная является доказательством prima facie заключения договора перевозки, условий
 этого договора и принятия груза перевозчиком.</p><br>

<p style="text-indent:2em;">Если договор перевозки устанавливает основные условия взаимоотношений между его сторонами (отправителем и перевозчиком), то каждая накладная не
 только подтверждает факт наличия договора, но и конкретизирует каждый конкретный грузовой рейс. Термин prima facie (на первый взгляд) означает,
 что накладная будет подтверждать наличие договора перевозки до тех пор, пока не 
 будет доказано обратное.</p><br>

<p style="text-indent:2em;">Кроме указанной выше функции, накладная выполняет еще одну важную функцию в коммерческом обороте. Международный автомобильный перевозчик не
 является стороной договора купли-продажи, однако подписанная им накладная является одним из основных банковских документов. Факт принятия груза
 перевозчиком придает накладной функции документа, подтверждающего поставку товаров по договорам купли-продажи на базисных условий FCA, СРТ, С1Р
 (по Инкотермс 90 и Инкотермс 2000). Чистая накладная, то есть без мотивированных оговорок перевозчика относительно количества, качества и состояния
 принятого им груза, представляется продавцом в банк наряду с другими необходимыми документами, подтверждающими поставку товара на установленных в
 международном договоре купли-продажи условиях.</p><br>

<p style="text-indent:2em;">Продавец (отправитель) всегда проявляет заинтересованность в получении именно чистой накладной независимо от того, в каком состоянии и количестве
 он передает товар перевозчику. Широко известна и часто применяется практика так называемых “гарантийных доверительных писем”, в которых продавец
 (отправитель) просит перевозчика не делать никаких отметок и оговорок в накладной, независимо от количества, качества и состояния груза,
 принятого последним.</p><br>

<p style="text-indent:2em;">В случае принятия такого письма перевозчиком, продавец обязуется возместить ему все расходы, связанные с выдачей такой “чистой накладной”,
 а именно: возместить перевозчику убытки в том объеме, в каком он выплатил получателю (покупателю) стоимость заведомо недостающего или
 поврежденного груза и выплатить перевозчику компенсацию морального ущерба (вреда) в
 заранее оговоренном размере.</p><br>

<p style="text-indent:2em;">Практика “гарантийных доверительных писем”, представляющая собой общепризнанный международный торговый обычай, вызывает в последнее время 
особое беспокойство судов, в связи с тем, что она является заведомым сговором продавца с перевозчиком, направленным против третьего лица — 
покупателя товара. Если бы перевозчик не выдал продавцу (отправителю) чистую накладную, продавец бы считался невыполнившим свои обязательства
 по международному договору купли-продажи, не получил бы оплату за товар и, возможно уплатил бы покупателю штрафные суммы, определенные таким
 договором.</p><br>

<p style="text-indent:2em;">Обычно перевозчики соглашаются принять доверительные гарантии только тех лиц, с которыми поддерживают долговременные деловые отношения, так как
 в случае неуплаты продавцом перевозчику денежных обязательств по письму, последний не может предъявить по нему иск судебным органам (заведомый
 сговор с продавцом).</p><br>

<p style="text-indent:2em;">2. Если накладная не содержит специальных оговорок перевозчика, то до доказательства иного предполагается, что груз и его упаковка были внешне
 в надлежащем состоянии в момент принятия груза перевозчиком, и что число грузовых мест, а также их маркировка и нумерация мест соответствовали
 указанному в накладной.</p><br>

<p>См. комментарии к пункту 3 статьи 8 и пункту 1 статьи 9.</p><br>

<p style="font-weight:500;">Статья 10.</p><br>

<p style="text-indent:2em;">Отправитель несет ответственность перед перевозчиком за ущерб, причиненный лицам, оборудованию или другим грузам, а также за любые расходы,
 вызванные дефектами упаковки груза, за исключением случаев, когда дефект был очевидным или известным перевозчику в момент принятия груза,
 и он не сделал относительно этого надлежащих оговорок.</p><br>

<p style="text-indent:2em;">Если перевозчик несет материальную ответственность за груз, то отправитель отвечает перед перевозчиком за ущерб, который может быть нанесен
 перевозчику или каким-либо другим лицам в результате транспортировки товара как по договорным, так и по вне договорным отношениям, то есть 
 на основании договора перевозки и норм применимого права.</p><br>

<p style="text-indent:2em;">Перевозчик может потребовать возмещение расходов и убытков, причиненных действиями грузовладельца из-за ненадлежащей тары или упаковки груза,
 только в тех случаях, если будет ссылаться на дефекты упаковки, которые он не имел возможности обнаружить при наружном визуальном осмотре
 груза.</p><br>

<p style="text-indent:2em;">Например, убытки перевозчика были вызваны тем, что произошло повреждение кузова автомобиля из-за того, что грузовые места не были надлежащим 
образом закреплены отправителем. В этом случае перевозчику будет сложно доказать вину отправителя, так как перевозчик должен был оценить дефекты
 упаковки и качество крепления грузовых мест при визуальном осмотре груза в момент
 его приема к перевозке.</p><br>

<p style="font-weight:500;">Статья 11.</p><br>

<p style="text-indent:2em;">1. Для целей таможенных и других формальностей, которые должны быть совершены до выдачи груза получателю, отправитель обязан приложить к накладной
 необходимые документы или предоставить их в распоряжение перевозчика и сообщить ему
 все требуемые сведения.</p><br>

<p style="text-indent:2em;">Как правило, добросовестный перевозчик, заинтересованный в надлежащем исполнении договора без возникновения дополнительных расходов и убытков
 его сторон, ставит в известность отправителя о содержании этой статьи Конвенции и сообщает ему перечень необходимых документов и сведений. См.
 комментарий к подпункту д) пункта 2 статьи 6.</p><br>

<p style="text-indent:2em;">2. Перевозчик не обязан проверять правильность и полноту этих документов и сведений. Отправитель несет ответственность перед перевозчиком за
 ущерб, причиненный отсутствием, недостаточностью или неправильностью таких документов и сведений, за исключением случаев вины 
 перевозчика.</p><br>

<p style="text-indent:2em;">Отправитель может попросить перевозчика проверить наличие и полноту приложенных к накладной документов на соответствие их содержания общепринятым
 обычаям и нормам международного и национального права, под юрисдикцию которых подпадает осуществление договора перевозки. Даже если перевозчик 
 проверил приложенные товаросопроводительные документы на груз и стороны подписали соответствующую оговорку в накладной, все равно отправитель 
 остается ответственным перед перевозчиком за возможные убытки последнего (императивная норма).</p><br>

<p style="text-indent:2em;">Чаще всего убытки перевозчика бывают вызваны тем, что содержание таможенных, платежных документов и различного рода сертификатов не дает 
возможности проверяющим такие документы национальным органам (пограничным, таможенным, санитарным, ветеринарным и др.) пропустить груз через 
государственную границу страны. Однако отправитель праве доказывать, что убытки перевозчика вызваны собственной виной последнего (например, 
перевозчиком был утерян приложенный и зафиксированный в накладной документ).</p>

<p style="text-indent:2em;"></p><h6>3. Перевозчик несет ответственность так же, как экспедитор, за последствия утраты или неправильного использования документов, упомянутых в 
накладной и приложенных к ней или предоставленных в его распоряжение:</h6><p></p>

<p style="text-indent:2em;">сумма причитающегося с него возмещения не должна, однако, превышать ту,
 которая подлежала бы уплате в случае утраты груза.</p><br>

<p style="text-indent:2em;">Отправитель заинтересован в том, чтобы полный перечень приложенных товаросопроводительных документов был внесен в накладную, вследствие 
императивной ответственности перевозчика за их утрату и неправильное использование. Конкретный размер связанных с этим убытков доказывается 
грузовладельцем (в иске отправителя или получателя) и определяется судом или арбитражем, но не может превышать размеров возмещения как за 
полную утрату груза или его части в соответствии с положениями статьи 23 Конвенции. Причем предъявителю иска необходимо доказать, что утрата 
или неправильное использование документов фактически означало для грузовладельца утрату самого груза или невозможность его использования. 
Бремя доказывания надлежащего использования приложенных к накладной документов, лежит на перевозчике.</p><br>

<p style="font-weight:500;">Статья 12.</p><br>

<p style="text-indent:2em;">1. Отправитель имеет право распоряжаться грузом, в частности, потребовать от перевозчика прекращения перевозки, изменения места, предусмотренного 
для доставки груза, или выдачи груза не тому получателю, который указан в накладной.</p>

<p style="text-indent:2em;"></p><h6>Изначально только отправитель является для перевозчика правомочным по договору лицом. Перевозчик обязан исполнять любые указания отправителя в 
рамках, установленных договором, и применимых норм международного (включая Конвенцию) и национального права. Любое указание отправителя должно 
быть реально осуществимо по месту и времени его исполнения и не должно причинять ущерба сторонам договора перевозки и третьим лицам. 
Дополнительное указание на исполнение услуг, не предусмотренных договором, реализуется перевозчиком:</h6><p></p>

<p style="text-indent:2em;">за дополнительное вознаграждение, размер которого конкретно оговаривается сторонами до 
исполнения услуги.</p><br>

<p style="text-indent:2em;">Отправитель имеет право потребовать от перевозчика изменения пункта назначения и\или выдачи груза другому получателю только в стране 
назначения груза.</p><br>

<p style="text-indent:2em;">2. Отправитель теряет это право с того момента, когда второй экземпляр накладной передан получателю или когда последний осуществляет свои права, 
предусмотренные в пункте 1 статьи 13; с этого момента перевозчик должен руководствоваться указаниями 
получателя груза.</p><br>

<p style="text-indent:2em;">Получатель может вступить в договор перевозки и реализовывать свои права распоряжаться грузом в тех случаях, когда примет от перевозчика груз 
или документы на груз (в том числе и второй экземпляр накладной). Если груз является полностью утраченным или считается таковым в соответствии 
с положениями статей 19 и 20, получатель наравне с отправителем, может потребовать от перевозчика выплаты возмещения, если из содержания 
представленных перевозчику документов следует, что он является собственником груза или имеет хотя 
бы право распоряжаться им.</p><br>

<p style="text-indent:2em;">Указания, полученные перевозчиком от получателя, не могут выходить за рамки работ и услуг, предусмотренных в договоре перевозки, а лишь 
должны конкретизировать их.</p><br>

<p style="text-indent:2em;">3. Однако право распоряжения грузом принадлежит получателю с момента составления накладной, если в накладной отправителем сделано такого
 рода указание.</p><br>

<p style="text-indent:2em;">Отправитель может передать все свои права правомочного по договору лица получателю путем соответствующей оговорки в накладной. Такая 
оговорка в накладной, отражающая право только получателя распоряжаться грузом, соответствует транспортным условиям договоров купли-продажи, 
заключенных по базисам EXW и FCA (по Инкотермс 90 и Инкотермс 2000), когда расходы и риски по транспортировке товара 1 лежат именно на 
покупателе.</p><br>

<p style="text-indent:2em;">4. Если, осуществляя свое право распоряжения грузом, получатель дает указание сдать груз другому лицу, последнее не вправе назначить 
других получателей.</p><br>

<p style="text-indent:2em;">Термины “получатель” и “покупатель” редко отождествляют одно и то же лицо. Чаще всего покупает товар и заключает международный договор 
купли-продажи одно лицо (покупатель), а конечным потребителем такого товара — другое (получатель). В таких случаях в договоре перевозки 
указывают покупателя, который, реализуя свое право, указанное в данной статье Конвенции, дает указание перевозчику после выполнения таможенных 
формальностей сдать груз непосредственному получателю. Подобное указание должно сопровождаться окончательным расчетом за перевозку с учетом 
дополнительного пробега и сверхнормативного простоя транспортного средства. Конечный получатель груза обязан только принять товар и не может 
давать никаких дополнительных указаний перевозчику.</p>

<p style="text-indent:2em;"></p><h6>5. Право распоряжения грузом осуществляется при условии соблюдения 
следующих положений:</h6><p></p>

<p style="text-indent:2em;">a) отправитель или в случае, указанном в пункте 3 настоящей статьи — получатель, желающий осуществить это право, должен представить 
перевозчику первый экземпляр накладной, в которой должны быть внесены новые инструкции, а также возместить перевозчику расходы и ущерб, 
вызванные выполнением этих инструкций;</p><br>

<p style="text-indent:2em;">b) выполнение этих инструкций должно быть возможным в тот момент, когда их получает лицо, которое должно их выполнить; оно не должно 
нарушать хода нормальной работы предприятия перевозчика и не должно наносить ущерба отправителям или получателям других грузов;</p><br>

<p>c) упомянутые инструкции не должны ни в коем случае приводить к делению отправок.</p><br>

<p style="text-indent:2em;">Накладная не является товарораспорядительным документом. С помощью накладной продавец (отправитель) не может передавать права собственности 
на товар покупателю (получателю), а тот еще какому-либо третьему лицу по передаточной (переуступочной) надписи в накладной. Это означает, 
что накладную нельзя индоссировать. Поэтому лицо, желающее осуществить свои права распоряжаться грузом, должно дать указание перевозчику 
относительно его дальнейших действий, в том (первом) экземпляре накладной, какой перевозчик выдал отправителю в момент приема груза. 
Обычно первый экземпляр накладной, после перечисления банковских платежей за товар продавцу по международному договору купли-продажи, 
пересылается покупателю, который, являясь получателем, и вносит в нее соответствующие инструкции перевозчику. рм. комментарий к пункту 
1 статьи 9.</p><br>

<p style="text-indent:2em;">При перевозке нескольких отправок (партий груза) в одном транспортном средстве, переадресовка любой из них не должна увеличивать сроки 
доставки других.</p><br>

<p style="text-indent:2em;">6. Если перевозчик не может выполнить полученные им инструкции по причине, указанной в подпункте “в” пункта 5, он должен немедленно 
сообщить об этом лицу, которым были даны инструкции.</p><br>

<p style="text-indent:2em;">Отказ перевозчика от выполнения инструкций лица, имеющего право распоряжаться грузом (отправителя или получателя), должен быть 
мотивированным и документально обоснованным.</p><br>

<p style="text-indent:2em;">7. Перевозчик, не выполнивший инструкций, которые были ему даны с учетом положений, предусмотренных в настоящей статье, или выполнивший 
инструкции, не потребовав представления ему первого экземпляра накладной, несет ответственность перед лицом, имеющим право предъявить 
претензию, за причиненный в связи с этим ущерб.</p><br>

<p style="text-indent:2em;">Если перевозчик не выполнил инструкций правомочного по договору лица в пределах ранее вмененных перевозчику обязанностей, считается, 
что он ненадлежащим образом исполнил договор перевозки.</p><br>

<p style="text-indent:2em;">Требование предъявления перевозчику первого экземпляра накладной имеет смысл только тогда, когда правомочным по договору лицом является 
отправитель, а дополнительные указания перевозчику дает получатель. Если перевозчик выполнит указания получателя без требования предъявления 
ему первого экземпляра накладной, он рискует тем самым нанести ущерб отправителю и будет обязан последнему его возместить.</p><br>

<p style="text-indent:2em;">Это в полной мере может относиться к таможенным операциям с грузом. Если, например, перевозчик не получал никаких указаний от отправителя, 
а затем по устному указанию получателя оставляет груз на таможенном складе или терминале, то именно перевозчик, а не таможенный орган, 
несет ответственность как за сохранность груза, так и за его доставку.</p><br>

<p style="text-indent:2em;">Перевозчик, выдавший груз постороннему лицу, будет отвечать перед отправителем или получателем в том же размере, что и за полную утрату 
всего груза. Сдача груза таможенному органу места назначения не означает выполнения перевозчиком его обязанностей по договору, 
если получатель не отметил в накладной факт принятия груза или, если такой таможенный орган не был указан в накладной в качестве 
конечного пункта назначения.</p><br>

<p style="font-weight:500;">Статья 13.</p><br>

<p style="text-indent:2em;">1. По прибытии груза на место, предназначенное для сдачи, получатель имеет право потребовать передачи ему второго экземпляра 
накладной вместо расписки и сдачи ему груза. Если установлена утрата груза или если груз не прибыл по истечении срока, предусмотренного 
в статье 19, получатель может от своего имени предъявить перевозчику требования, основанные на праве, вытекающем из договора 
перевозки.</p><br>

<p style="text-indent:2em;">Декларируется право получателя вступить в договор перевозки и распоряжаться грузом после того, как он примет груз от перевозчика и 
распишется во втором экземпляре накладной. Если груз получателю не доставлен, то это тем не менее не ограничивает его право вступить 
в договор перевозки и на основании этого права предъявлять перевозчику соответствующие претензии и иски. См. комментарии к статьям 
19 и 20.</p><br>

<p style="text-indent:2em;">2. Получатель, осуществляющий права, предоставляемые ему согласно пункту 1 настоящей статьи, обязан произвести платежи по задолженности,
 указанной в накладной. В случае возникновения спора по этому поводу перевозчик не обязан выдавать груз до представления получателем 
 гарантий платежа.</p><br>

<p style="text-indent:2em;">Перевозчик имеет право осуществлять свое залоговое право на груз и не выдавать его получателю до тех пор, пока последний полностью не 
рассчитается с перевозчиком за все работы и услуги по исполненному договору перевозки. Размер задолженности определяется не только 
платежами, изначально указанными в накладной, но и фактической стоимостью дополнительных целесообразных работ и услуг, исполненных 
перевозчиком в интересах правомочного по договору лица.</p><br>

<p style="font-weight:500;">Статья 14.</p><br>

<p style="text-indent:2em;">1. Если по какой-либо причине выполнение договора на определенных в накладной условиях является или становится невозможным до 
прибытия груза к месту, предназначенному для сдачи, перевозчик обязан запросить инструкции у лица, имеющего право распоряжаться 
грузом в соответствии с положениями статьи 12.</p><br>

<p style="text-indent:2em;">К основным причинам, по которым исполнение договора на условиях, определенных в накладной, становится для перевозчика невозможным 
до прибытия груза к месту назначения, необходимо отнести вынужденное изменение маршрута перевозки из-за закрытия или ограничения 
пропускной способности автомобильных дорог и изменение срока доставки груза в связи с очередями на погранпереходах и пунктах 
таможенного контроля. Кроме того, для перевозчика является важным изменение стоимости услуг по выполнению договора перевозки 
из-за увеличения пробега транспортного средства и дополнительно взимаемых фискальных платежей
 на иностранной территории.</p><br>

<p style="text-indent:2em;">2. Если же обстоятельства позволяют выполнить перевозку на условиях, отличных от предусмотренных в накладной, и если перевозчик 
не смог в разумный срок получить инструкций от лица, имеющего право распоряжаться грузом в соответствии с положениями статьи 12,
 перевозчик должен принять меры, которые представляются ему наиболее подходящими в интересах лица, имеющего право распоряжаться 
 грузом.</p><br>

<p style="text-indent:2em;">О возможности исполнения договора перевозки на каких-либо иных условиях перевозчик извещает правомочное лицо с помощью средств 
связи или операций, фиксирующих запрос. Понятие “разумный срок” означает то время, в течение которого отправитель или получатель
 имеют возможность принять решение по поводу сложившейся ситуации и дать ответ перевозчику. Обычно “разумный срок” ограничивается
 окончанием первого рабочего дня, следующего за днем, когда этим лицом могло быть получено уведомление перевозчика.</p><br>

<p style="text-indent:2em;">Если перевозчик все же принимает решение исполнить договор перевозки на условиях, отличных от указанных в накладной, он обязан 
будет впоследствии доказать правомочному по договору лицу, что его действия были обоснованными, целесообразными и направленными
 на обеспечение интересов данного лица. Только в этом случае перевозчик может претендовать на возмещение своих расходов в части
 непокрытых суммами, изначально указанными в накладной.</p><br>

<p style="text-indent:2em;">Для того чтобы исключить возможные коллизии при составлении договора перевозки, сторонам рекомендуется вносить в него 
соответствующую оговорку на основании положений данной статьи Конвенции. Можно, например, предусмотреть в договоре статью,
 регламентирующую порядок информирования перевозчиком правомочного лица об изменениях существенных условий договора, сроки
 и порядок получения ответных инструкций, возможные действия перевозчика в случае неполучения указаний и пр.</p><br>

<p style="font-weight:500;">Статья 15.</p><br>

<p style="text-indent:2em;">1. При возникновении обстоятельств, препятствующих сдаче груза после его прибытия на место, предназначенное для сдачи, 
перевозчик должен запросить инструкции у отправителя. Если получатель откажется принять груз, отправитель имеет право распорядиться
 грузом, не будучи обязанным потребовать предъявления ему первого экземпляра накладной.</p><br>

<p style="text-indent:2em;">Основными препятствиями сдачи груза после прибытия транспортного средства на место назначения являются отказ получателя от приема
 груза по любой причине (в том числе из-за отсутствия возможности или желания получателя растаможить груз), отсутствие получателя
 по указанному адресу, неправильный или неточный адрес доставки и др. Перевозчик должен помнить, что принять от него груз является
 правом, но не обязанностью получателя. Получатель вправе отказаться от приема груза независимо от его состояния без объяснения
 причин вследствие того, что изначально, до предъявления доказательств противного, он не является стороной договора перевозки.</p><br>

<p style="text-indent:2em;">Когда после запроса перевозчика о том, как он должен распорядиться невостребованным получателем грузом, отправитель дал определенный
 ответ, перевозчику следует поступать в соответствии с предписаниями статьи 12 (пункты 1, 5, 6, 7) Конвенции, но без требования 
 дублирования инструкций в первом экземпляре накладной, предъявление которого в данном случае необязательно.</p><br>

<p style="text-indent:2em;">2. Получатель, даже если он отказался от принятия груза, может тем не менее потребовать его сдачи до тех пор, пока перевозчик не
 получил от отправителя противоположных инструкций.</p><br>

<p style="text-indent:2em;">До получения перевозчиком от отправителя противоположных по ^держанию указаний за получателем остается право вступить в договор 
перевозки и принять груз даже в том случае, если он ранее отказался от него. Если отправитель дает указание перевозчику, как ему 
в дальнейшем следует распорядиться грузом, то подразумевается, что отправитель обязуется возместить перевозчику все расходы, 
связанные с надлежащим выполнением данных им инструкций.</p><br>

<p style="text-indent:2em;">3. Если препятствие к сдаче груза возникает после того, как получатель, согласно предоставленному ему пунктом 3 статьи 12 праву, 
дал приказ сдать груз другому липу, то пункты 1 и 2 настоящей статьи применяются так, как если бы получатель был бы отправителем,
 а это другое лицо — получателем.</p><br>

<p style="text-indent:2em;">Перевозчик строит свои взаимоотношения с получателем как с единственным правомочным по договору перевозки лицом по процедурам,
 предусмотренным в пунктах 1 и 2 настоящей статьи, в тех случаях, когда получатель, реализуя свои права, поручил сдать груз “новому”
 получателю, а у перевозчика при этом возникли препятствия.</p><br>

<p style="font-weight:500;">Статья 16.</p><br>

<p style="text-indent:2em;">1. Перевозчик имеет право на возмещение расходов, вызванных запросом инструкций или выполнением полученных инструкций, если эти расходы
 не являются следствием его собственной вины.</p><br>

<p style="text-indent:2em;">Все, предъявляемые к возмещению расходов суммы, должны быть перевозчиком обоснованы и документально подтверждены.</p><br>

<p style="text-indent:2em;">2. В случаях, указанных в пункте 1 статьи 14 и в статье 15, перевозчик может немедленно выгрузить груз за счет лица, имеющего право 
распоряжаться грузом, и после такой выгрузки перевозка считается законченной. В таком случае перевозчик осуществляет хранение груза для
 лица, имеющего право распоряжаться грузом. Он может, однако, доверить хранение груза третьему лицу, “и в этом случае несет ответственность
 лишь за осмотрительный выбор этих третьих лиц. Платежи, предусмотренные накладной, и все другие расходы остаются подлежащими оплате против
 выдачи груза.</p><br>

<p style="text-indent:2em;">Если перевозчик не может доставить или сдать груз получателю, то при отсутствии инструкций отправителя, он вправе выгрузить груз.
 После этого договор перевозки считается исполненным. Как правило, местом выгрузки чаще всего является таможенный терминал или склад,
 куда нерастаможенный груз сдается на хранение под его залоговую стоимость. Подтверждать исполнение перевозчиком его обязанностей будет
 чистый складской документ (расписка, простое складское свидетельство, двойное складское свидетельство, складская квитанция и др.), 
 принятый в обычной практике работы такого таможенного терминала или склада. Перевозчик обязан передать складской документ правомочному
 по договору лицу против осуществления последним всех окончательных расчетов за произведенные работы и услуги.</p><br>

<p style="text-indent:2em;">3. Перевозчик может продать груз, не ожидая инструкций от лица, имеющего право распоряжаться грузом, если груз является скоропортящимся
 или если того требует его состояние, или же если расходы по хранению груза оказались, бы несоразмерны со стоимостью груза. В других
 случаях перевозчик может также продать груз, если в разумный срок им не будет получено от лица, имеющего право распоряжаться грузом,
 противоположных инструкций, выполнение которых может быть разумно от него потребовано.</p><br>

<p style="text-indent:2em;">Применяется в практике международных автомобильных перевозок между странами Европейского Союза (ЕС) из-за отсутствия процедур таможенного
 оформления товаров, произведенных и потребляемых внутри ЕС. При осуществлении перевозок в (из) Россию положения данного пункта практически 
 неприменимы из-за того, что права собственности на груз никогда не переходят на перевозчика и он поэтому от своего имени не может произвести
 таможенные формальности и платежи.</p><br>

<p style="text-indent:2em;">Исключение составляют случаи, когда кроме выполнения своих' основных функций, перевозчик является экспедитором отправителя;</p>

<p style="text-indent:2em;"></p><h6>Тогда по договору транспортной экспедиции он растаможить груз, а по статьи 6. Момент сдачи груза определяется датой в накладной,
 простав-договору перевозки осуществит операции, предусмотренные положена ленной во втором ее экземпляре получателем при приеме им груза.
 Соями настоящей статьи, стояние груза в момент приема груза к перевозке и в момент его сдачи получателю определяется:</h6><p></p>

<p></p><h4>Глава IV. Ответственность перевозчика</h4><p></p>

<p style="font-weight:500;">Статья 17.</p><br>

<p style="text-indent:2em;">1. Перевозчик несет ответственность за полную или частичную утрату груза или за его повреждение, происшедшее с момента принятия 
груза к перевозке и до момента его сдачи, а также за просрочку в доставке.</p>

<p style="text-indent:2em;"></p><h6>“Золотое правило” императивной ответственности и презумпции вины любого перевозчика любого вида транспорта. Момент приема груза 
фиксируется по дате в накладной в соответствии с подпунктом а) пункта 1 статьи 6. Момент сдачи груза определяется датой в накладной, проставленной во втором ее экземпляре получателем при приеме им груза.
 Состояние фуза в момент приема груза к перевозке и в момент его сдачи получателю определяется:</h6><p></p>

<p style="text-indent:2em;">наличием или отсутствием в накладной мотивированных оговорок, сделанных соответственно перевозчиком и получателем;</p><br>

<p>признанием этих оговорок, соответственно, отправителем и перевозчиком;</p><br>

<p>содержанием претензии получателя, поданной в сроки, согласно положениям статьи 30 Конвенции.</p><br>

<p style="text-indent:2em;">2. Перевозчик освобождается от этой ответственности, если утрата груза, его повреждение или просрочка в доставке произошли по вине
 заявителя требования, вследствие инструкции заявителя требования, не вызванной виной перевозчика, дефекта, свойственного грузу, 
 или обстоятельств, избегнуть которые перевозчик не мог и последствия которых он не мог предотвратить.</p>

<p style="text-indent:2em;"></p><h6>В случае полной или частичной утраты, повреждения, порчи груза или при просрочке в его доставке априори виновен перевозчик. 
Перевозчик может опровергнуть презумпцию своей вины. Перевозчик будет освобожден от ответственности, если докажет отсутствие своей
 вины в отношении предмета претензии по следующим позициям:</h6><p></p>

<p style="text-indent:2em;">1. Перевозчик может доказать ненадлежащие действия отправителя и\или получателя в тех случаях, когда погрузка, выгрузка, укладка и
 крепление груза производились силами последних и явились причиной повреждения, порчи или утраты груза. Кроме того, перевозчик может 
 сослаться на ненадлежащую упаковку и маркировку груза, на то, что груз был неправильно закреплен внутри тары и упаковки, а также на 
 то, что он принял транспортное средство или контейнер с грузом за чужими пломбами.</p><br>

<p style="text-indent:2em;">2. Неверные инструкции, данные перевозчику или отсутствие таковых относятся к упущениям отправителя или получателя, доказательство 
которых позволяет перевозчику снять с себя ответственность. Однако перевозчику необходимо доказать, что именно данное упущение 
правомочного по договору лица явились причиной повреждения, порчи или утраты груза или просрочки в его доставке.</p><br>

<p style="text-indent:2em;">3. Перевозчику предоставляется возможность доказать, что повреждение, порча или утрата груза произошли вследствие особых естественных
 свойств груза, то есть таких, которые грузу присущи по своей природе (самовозгорание, ржавление, химическая или биологическая реакция
 старой или упаковкой, появление насекомых и пр.).</p><br>

<p style="text-indent:2em;">4. Перевозчик будет освобожден от ответственности, если докажет, что коммерческая неисправность груза или нарушение срока доставки 
произошли при наступлении форс-мажорных обстоятельств, то есть таких, которые перевозчик не мог предвидеть и преодолеть. Применительно
 к договору перевозки к обстоятельствам непреодолимой силы, как правило, относят пожары, стихийные бедствия, запрет экспорта или импорта
 перевозимых товаров, закрытие государственных границ, пограничных переходов, автомагистралей, бандитские действия, любые виды войн и 
 поражения грузов орудиями военных действий, забастовки, народные волнения, конфискационные и ликвидационные действия военных и гражданских
 властей, ядерный взрыв и радиационное заражение, а также иные обстоятельства вне разумного контроля сторон договора. В случае ссылки на 
 форс-мажор перевозчик должен представить соответствующую справку Торгово-промышленной палаты (ТПП).</p><br>

<p style="text-indent:2em;">3. Перевозчик не освобождается от ответственности по причине дефектов транспортного средства, которым он пользуется для осуществления перевозки,
 или вины лица, у которого был взят в аренду автомобиль, или агентов и служащих последнего.</p><br>

<p style="text-indent:2em;">Перевозчик должен всегда эксплуатировать только технически исправное транспортное средство. При повреждении, порче, полной или частичной утрате
 груза, а также при нарушении сроков его доставки, перевозчик не может ссылаться на статьи Конвенции, ограничивающие или исключающие его
 ответственность, если не докажет, что к моменту начала рейса эксплуатируемое им транспортное средство находилось в технически исправном
 состоянии.</p>

<p style="text-indent:2em;"></p><h6>При условии соблюдения пунктов 2 и 5 статьи 18 перевозчик освобождается от лежащей на нем ответственности, когда утрата или повреждение груза
 являются следствием особого риска, неразрывно связанного с одним или несколькими из перечисленных 
 ниже обстоятельств:</h6><p></p>

<p style="text-indent:2em;">с использованием открытых брезентовых транспортных средств, если такое использование было
 специально согласовано и указано в накладной;</p><br>

<p style="text-indent:2em;">с отсутствием или дефектами упаковки, в случаях, когда грузы, перевозимые без упаковки или без надлежащей упаковки, подвержены порче или
 повреждению;</p><br>

<p style="text-indent:2em;">с обработкой, погрузкой, укладкой или выгрузкой груза отправителем или получателем, или лицами, действующими от имени отправителя или
 грузополучателя;</p><br>

<p style="text-indent:2em;">с естественными свойствами некоторых грузов, вследствие которых они подвержены полной или частичной утрате или повреждению, в частности,
 вследствие боя, коррозии, самопроизвольного гниения, усушки, нормальной усадки или
 воздействия паразитов и грызунов;</p><br>

<p>e) с недостаточностью или неудовлетворительностью маркировки или нумерации грузовых мест;</p><br>

<p>f) с перевозкой живых животных.</p>

<p style="text-indent:2em;"></p><h6>Здесь приводится перечень конкретных рисков, ссылаясь на которые, перевозчик может быть освобожден от ответственности, если докажет, что хотя
 бы один из этих рисков явился причиной повреждения, порчи или утраты груза:</h6><p></p>

<p style="text-indent:2em;">а) Право выбора транспортного средства принадлежит отправителю. Если он выбрал открытый подвижной состав, то он тем самым заранее подвергает
 естественным опасностям перевозимый на нем груз (атмосферные осадки, влажность, солнечная радиация и др.). В современной практике международных
 расчетов по документарным аккредитивам и инкассо представление в банк чистой накладной на груз, перевозимый на открытом транспортном средстве,
 не является подтверждением выполнения продавцом его обязанностей по международному договору купли-продажи, если такой способ доставки товара не
 был заранее согласован между продавцом, покупателем и банком.</p><br>

<p style="text-indent:2em;">b) Отсутствие упаковки у груза, который при перевозке без упаковки может быть подвержен повреждению или порче, следует квалифицировать как
 ненадлежащие действия отправителя не обеспечивающие сохранность предъявленного к перевозке груза. Перевозчик может сослаться на дефекты упаковки,
 приведшие к порче или повреждению груза, если эти дефекты было невозможно обнаружить при визуальном осмотре груза в момент приема его к
 перевозке.</p><br>

<p style="text-indent:2em;">c) Перечисленные риски перевозчик может связать с 
повреждением или порчей груза, относя их к ненадлежащим действиям отправителя.</p><br>

<p style="text-indent:2em;">d) Перечислены те особые естественные свойства груза, на которые перевозчик может сослаться, доказывая отсутствие своей вины при повреждении или
 порче груза. Выявление особых естественных свойств должно быть увязано со сроком доставки груза и проявлением перевозчиком заботливости о грузе,
 в целях уменьшения размера ущерба. См. комментарий к пункту 2 статьи 17.</p><br>

<p style="text-indent:2em;">e) Недостаточность или неудовлетворительность маркировки или нумерации грузовых мест можно относить как к действиям, так и к упущениям отправителя.
 Перевозчику очень сложно ссылаться на это ограничение своей ответственности, так как в соответствии с пунктом 1 статьи 8 он был обязан в момент приема
 груза к перевозке проверить маркировку и нумерацию грузовых мест.</p><br>

<p style="text-indent:2em;">f) Имеется в виду падеж животных, для которых обычные условия автомобильной перевозки не являются естественными для жизни и могут стать причинами
 гибели. Бремя доказывания причин падежа животных лежит на перевозчике.</p><br>

<p style="text-indent:2em;">5. Когда согласно настоящей статье перевозчик не несет ответственности за некоторые обстоятельства, вызвавшие ущерб, то он несет ответственность
 только в том объеме, в каком обстоятельства, за которые он ответственен согласно настоящей статье,
 способствовали возникновению ущерба.</p><br>

<p style="text-indent:2em;">Если перевозчик не может доказать полное отсутствие своей вины и перекладывает лишь часть ущерба на отправителя или получателя, то оставшаяся часть
 ущерба должна быть возмещена им самим.</p><br>

<p style="font-weight:500;">Статья 18.</p><br>

<p style="text-indent:2em;">1. Бремя доказательства того, что утрата груза, его повреждение или просрочка в доставке были вызваны обстоятельствами, указанными в пункте 2 статьи 17,
 лежит на перевозчике.</p><br>

<p style="text-indent:2em;">Декларируется априорная императивная вина перевозчика за повреждение, порчу или утрату груза или просрочку в его доставке и право перевозчика доказывать
 обратное при желании быть освобожденным или ограниченным в ответственности.</p><br>

<p style="text-indent:2em;">2. Если перевозчик докажет, что при создавшихся обстоятельствах утрата груза или его повреждение могли явиться следствием одного или нескольких особых
 рисков, указанных в пункте 4 статьи 17, то предполагается, что таковые произошли вследствие этого. Заявитель требования может, однако, доказывать, что
 частичный или полный ущерб не явился следствием одного из этих рисков.</p><br>

<p style="text-indent:2em;">Для сторон договора перевозки существует четыре основных принципа доказывания того,
 что нарушение перевозки произошло не по его вине.</p><br>

<p style="text-indent:2em;">Первый принцип. Перевозчик является prima facie ответственным за утрату или повреждение груза, принятого к перевозке в хорошем состоянии, а выгруженного
 с недостачей или в поврежденном состоянии. Доказательство является опровержимым, то есть на перевозчике лежит бремя представления доказательств,
 опровергающих эту презумпцию.</p><br>

<p style="text-indent:2em;">Второй принцип. Стороны обычно обязаны доказывать обстоятельства рассматриваемого дела, сведения о которых им доступны или могли быть доступны. Перевозчик
 несет основное бремя доказывания, поскольку груз находился либо в его непосредственном распоряжении, либо в распоряжении его служащих и агентов. С другой 
 стороны, отправитель знает, как грузы были произведены или собраны, каковы их особые свойства, как они были упакованы. Отправитель осуществляет контроль за
 погрузкой, и на нем лежит бремя доказывания состояния груза во время погрузки. Получатель, соответственно, доказывает состояние груза во время 
 выгрузки.</p><br>

<p style="text-indent:2em;">Третий принцип. Бремя доказывания не означает, что должны быть доказаны все обстоятельства вплоть до абсурда, а означает представление доказательств
 в разумных пределах. Даже опровержение презумпции ответственности не требует ничего большего, чем представление какого-либо доказательства, 
 свидетельствующего об обратном.</p><br>

<p style="text-indent:2em;">Например, тот факт, что при выгрузке 80% груза находилось в хорошем состоянии является доказательством того, что при погрузке весь груз 
находился в хорошем состоянии, о чем свидетельствует отсутствие оговорок в накладной при приеме груза.</p><br>

<p style="text-indent:2em;">Четвертый принцип. Доказательства стороны, которая скрывает факты, подвергаются сомнению. Обычно суды, как только обнаруживается сокрытие 
фактов, подделка или подчистка документов и пр., подвергают остальную часть доказательств сомнению.</p><br>

<p style="text-indent:2em;">По существующей судебно-арбитражной практике порядок доказывания по претензиям и искам, то есть требований по грузу, практически идентичен 
во всех странах мира. Примерная процедура доказывания выглядит следующим образом.</p>

<p style="text-indent:2em;"></p><h6>1. Истец (отправитель или получатель) должен последовательно доказать
,что:</h6><p></p>

<p>1.1. Является владельцем груза и\или лицом, имеющим право предъявить иск.</p><br>

<p>1.2. Основанием для предъявления иска является нарушение договора или закона.</p><br>

<p>1.3. Лицо, которому предъявляется иск, является лицом, ответственным за причиненный ущерб.</p><br>

<p style="text-indent:2em;">1.4. Утрата или повреждение груза имели место во время его нахождения в распоряжении перевозчика. Это обычно осуществляется путем доказательств 
о состоянии груза на момент, когда он был принят перевозчиком, и о его состоянии при выгрузке.</p><br>

<p>1.5 Размер утраты или повреждения в физическом выражении (экспертиза ТПП).</p><br>

<p>1.6 Фактический размер утраты или повреждения в денежном выражении (экспертиза ТПП).</p>

<p style="text-indent:2em;"></p><h6>2. Перевозчик должен доказать:</h6><p></p>

<p>2.1. Причину ущерба.</p><br>

<p style="text-indent:2em;">2.2. Проявление им должной заботливости о приведении транспортного средства в
 технически исправное состояние к моменту начала рейса.</p><br>

<p style="text-indent:2em;">2.3. Проявлении им заботливости о грузе в целях уменьшения размера ущерба в
 интересах правомочного по договору лица.</p><br>

<p>2.4. Одну из причин ущерба, освобождающих его от ответственности.</p><br>

3. Стороны представляют обстоятельства и доказательства, относящиеся к
 делу и имеющиеся в их распоряжении.<p></p><br>

<p style="text-indent:2em;">3. Указанное выше предположение не допускается в случае, предусмотренном в подпункте “а” пункта 4 статьи 17, если недостача превышает нормально 
допустимую или если утрачено грузовое место.</p><br>

<p style="text-indent:2em;">Выбор отправителем открытого подвижного состава для транспортировки груза не может стать единственной причиной полной утраты одного или 
нескольких грузовых мест. Для доказывания отсутствия своей вины перевозчик должен ссылаться на иные сопутствующие причины, например, на
 неправильную укладку и ненадлежащее крепление отправителем груза в транспортном средстве, на форс-мажорные обстоятельства и др. См. 
 комментарий к пункту 4 статьи 17.</p><br>

<p style="text-indent:2em;">4. Если перевозка осуществляется транспортным средством, специально оборудованным для предохранения груза от воздействия тепла, холода,
 температурных изменений или влажности воздуха, перевозчик не может ссылаться на подпункт “d” пункта 4 статьи 17, если он не докажет, что
 все меры в отношении выбора, содержания и использования вышеупомянутого оборудования, которые он обязан был принять с учетом обстоятельств
 , были им приняты и что он действовал согласно данным ему специальным инструкциям.</p><br>

<p style="text-indent:2em;">Обусловленные в накладной и в договоре перевозки обязательства перевозчика использовать во время транспортировки специальное оборудование,
 применение которого предотвращает проявление особых естественных свойств груза (коррозия, гниение, усушка и пр.), лишают перевозчика 
 возможности ссылаться на исключение или ограничение его ответственности, если только он не докажет, что вид такого специального 
 оборудования был выбран или согласован с отправителем, а само оборудование было использовано и эксплуатировалось надлежащим 
 образом.</p><br>

<p style="text-indent:2em;">5. Перевозчик не может ссылаться в свою пользу на подпункт “f” пункта 4 статьи 17 только в том случае, если он не докажет, что все меры,
 которые он был обязан принять с учетом обстоятельств, были им приняты и что он действовал согласно данным ему специальным 
 инструкциям.</p><br>

<p style="text-indent:2em;">В случае падежа перевозимых животных, перевозчик будет освобожден от ответственности только в том случае, если докажет, что полностью
 и в точности соблюдал все специальные инструкции отправителя или получателя. Процедура такого доказывания является весьма сложной. 
 Транспортировка животных в сопровождении представителя отправителя или получателя фактически снимает с перевозчика ответственность
 за гибель животных.</p><br>

<p style="font-weight:500;">Статья 19.</p><br>

<p style="text-indent:2em;">Признается, что имела место просрочка в доставке, когда груз не был доставлен в согласованный срок или когда в отсутствии согласованного
 срока фактическая продолжительность перевозки с учетом ее конкретных обстоятельств, в частности, в случае частичной загрузки транспортного 
 средства с учетом времени, необходимого в обычных условиях для комплектации грузов для полной загрузки, превышает время, которое разумно 
 должно бы быть предоставлено добросовестному перевозчику.</p><br>

<p style="text-indent:2em;">Срок доставки может быть любым образом установлен сторонами договора перевозки и указан в накладной. Обычно указывается согласованная дата,
 после истечения которой в договоре предусмотрены штрафные санкции к перевозчику (обычно, за каждый день просрочки в доставке). См.
 комментарий к подпункту f) пункта 2 статьи 6.</p><br>

<p style="text-indent:2em;">Отсутствие договоренности между сторонами по этому вопросу, тем не менее позволяет определить для каждого конкретного рейса предельный
 срок доставки груза. Если срок доставки в накладной не указан, то для того чтобы определить, доставил перевозчик груз вовремя или нет,
 необходимо установить, какое время следует признать разумным для выполнения конкретной перевозки по конкретному маршруту в конкретных
 обстоятельствах. Для этого учитываются кратчайшее расстояние перевозки, норматив суточного пробега, наличие очередей на пограничных переходах,
 криминальная обстановки на маршруте доставки, ремонт и временное закрытие определенных участков дорог, время ожидания для включения
 транспортного средства в конвой, влияние атмосферных явлений на состояние дорожного покрытия и т.п.</p><br>

<p style="text-indent:2em;">Если перевозчик, с учетом предположений самых неблагоприятных условий, не превысит максимальных временных параметров по каждой фазе
 прохождения маршрута, то его действия будут признаны разумными. Добросовестным перевозчиком, в данном контексте, следует считать такого,
 который сможет доказать, что он приложил все усилия к минимизации срока доставки, полностью следуя указаниям и инструкциям отправителя
 (получателя), соблюдая международные и национальные законы, процедуры, правила и проявляя заботливость о грузе в интересах правомочного
 по договору лица.</p><br>

<p style="font-weight:500;">Статья 20.</p><br>

<p style="text-indent:2em;">1. Тот факт, что груз не доставлен в течение тридцати дней по истечении согласованного срока и если срок не был согласован, в течение
 шестидесяти дней со дня принятия груза перевозчиком, является бесспорным доказательством утраты груза и лицо, имеющее право заявить
 требование, может считать его утраченным.</p><br>

<p style="text-indent:2em;">Для того чтобы получатель не пропустил сроки предъявления претензии в полном объеме, то есть как за полностью утраченный (недоставленный)
 груз, он должен знать о том, какой конкретный срок доставки указан в накладной или когда груз был принят перевозчиком. Получатель, в случае
 недоставки ему груза, имеет право на предъявление требований перевозчику на основании пункта 1 статьи 13 Конвенции и в сроки, определенные
 сторонами договора или на основании норм применимого права, если договоренность об этом установлена не была. К претензии получатель должен 
 приложить первый экземпляр накладной. См. комментарий к статье 30.</p><br>

<p style="text-indent:2em;">2. Лицо, имеющее право распоряжаться грузом, может, при получении им возмещения за утраченный груз, просить в письменной форме о его
 немедленном извещении, в том случае, если груз будет найден в течение года, следующего за уплатой возмещения. Принятие к сведению такого
 его требования должно быть подтверждено письменно.</p><br>

<p style="text-indent:2em;">Если перевозчик выплатил возмещение за недоставленный груз как за полностью утраченный, получатель может оставить за собой право
 распоряжаться им в случае, если груз будет найден в течение одного года после перечисления перевозчиком получателю денежных средств
 за груз. Заявление, подтверждающее такое право, должно быть принято и зафиксировано перевозчиком, что накладывает на него обязательства
 известить получателя о найденном грузе.</p><br>

<p style="text-indent:2em;">3. В течение тридцати дней после получения такого извещения лицо, имеющее право распоряжаться грузом, может потребовать, чтобы груз
 был ему сдан против уплаты платежей по задолженности, указанной в накладной, а также против возврата полученного им возмещения за
 вычетом Возможных расходов, включенных в сумму возмещения, но без ущерба права требования возмещения за просрочку в доставке,
 предусмотренного в статье 23 и в статье 26, если последняя подлежит применению.</p><br>

<p style="text-indent:2em;">Подача получателем перевозчику заявления о возможном возврате ему найденного груза не накладывает, однако, на первого обязательств
 принимать груз, причем не требует объяснения причин такого решения.</p><br>

<p style="text-indent:2em;">Приняв найденный груз, получатель должен вернуть перевозчику ранее полученные от него денежные суммы. Получатель, как правило,
 пользуется такой возможностью в том случае, когда в договоре перевозки оговорены значительные штрафные санкции за каждый день
 просрочки в доставке.</p><br>

<p style="text-indent:2em;">4. За отсутствием просьбы, упомянутой в пункте 2, или инструкций, данных в тридцатидневный срок, предусмотренный в пункте 3,
 или же в случае, если груз был найден только по прошествии года со дня выплаты возмещения, перевозчик имеет право распорядиться
 найденным грузом в соответствии с законом местонахождения груза.</p><br>

<p style="text-indent:2em;">Если получатель принял окончательное решение считать недоставленный ему груз полностью утраченным, то есть не оставил перевозчику
 заявления о возможном возврате ему найденного груза (см. пуню 2 настоящей статьи), и получил за него соответствующее возмещение,
 перевозчик праве самостоятельно распорядиться найденным впоследствии грузом.</p><br>

<p style="font-weight:500;">Статья 21.</p><br>

<p style="text-indent:2em;">Если груз сдан получателю без взыскания платежа, который должен был быть взыскан перевозчиком с получателя согласно условиям договора
 перевозки, перевозчик обязан уплатить отправителю возмещение в сумме, не превышающей суммы такого платежа без ущерба для своего
 права регресса к получателю.</p><br>

<p>Не имеет практического применения. Без комментариев.</p><br>

<p style="font-weight:500;">Статья 22.</p><br>

<p style="text-indent:2em;">1. Если отправитель передает перевозчику опасные грузы, он должен ему указать точно характер представляемой этими грузами опасности,
 а также, если нужно, указать необходимые меры предосторожности, которые следует предпринять. Если эти указания не внесены в
 накладную, бремя доказательства всякими иными способами того, что перевозчик был осведомлен в деталях о характере опасности,
 которую представляет перевозка упомянутых грузов, лежит на отправителе или получателе.</p><br>

<p style="text-indent:2em;">Порядок заключения и исполнения договора, а также регламентация условий перевозки опасных грузов в международном автомобильном
 сообщении определены в Европейском Соглашении о международной дорожной перевозке опасных грузов (ДОПОГ) от 30 сентября 1957 года,
 вступившего в силу 29 января 1969 года. Россия присоединилась к указанному Соглашению. Поэтому Российским перевозчикам при
 международных перевозках опасных грузов необходимо неукоснительно следовать требованиям ДОПОГ.</p><br>

<p style="text-indent:2em;">Если же опасный груз перевозится на основании условий настоящей Конвенции, то это накладывает на отправителя дополнительную 
ответственность за полноту представления им перевозчику документов и сведений, отраженных в накладной, относительно свойств
 груза, характера представляемой им опасности, необходимых мер предосторожности при обращении с таким грузом. Для снятия с себя
 ответственности за повреждение, порчу, полную или частичную утрату опасного груза, перевозчику достаточно доказать упущения
 отправителя при представлении им в накладной указаний относительно опасности груза и рисков, которые могут возникнуть в процессе 
 его международной перевозки.</p><br>

<p style="text-indent:2em;">2. Опасные грузы, о характере которых перевозчик не был осведомлен с соблюдением условий, указанных в пункте 1 настоящей статьи, 
могут быть в любой момент и в любом месте выгружены, уничтожены или обезврежены перевозчиком без всякого возмещения; кроме того,
 отправитель несет ответственность за все расходы и ущерб, вызванные передачей этих грузов для
 перевозки или их перевозкой.</p><br>

<p style="text-indent:2em;">Несоблюдение отправителем предписаний пункта 1 данной статьи может привести к уничтожению груза с последующими требованиями 
перевозчика возмещения ему всех расходов, связанных с выгрузкой опасного груза и его обезвреживанием. Кроме того, отправителю 
могут быть предъявлены требования по возмещению ущерба, связанного с повреждениями транспортного средства, перевозившего опасный груз.
 Если в договоре перевозки не указано иного, перевозчик сразу может предъявить иск в надлежащем суде или арбитраже без прохождения 
 претензионной процедуры предварительного рассмотрения спора.</p><br>

<p style="font-weight:500;">Статья 23.</p><br>

<p style="text-indent:2em;">Данная статья видоизменена в соответствии с Протоколом к Конвенции о договоре международной дорожной перевозки груза от 5 
июля 1978 года. См. комментарии к Протоколу.</p><br>

<p style="text-indent:2em;">1. Когда, согласно положениям настоящей Конвенции, перевозчик обязан возместить ущерб, вызванный полной или частичной утратой груза,
 размер подлежащей возмещению суммы определяется на основании стоимости груза в месте и во время
 принятия его для перевозки.</p><br>

<p style="text-indent:2em;">Размер возмещения, который должен выплатить международный перевозчик за полную или частичную утрату груза, определяется Конвенцией
 иначе, чем применительно ко внутренним перевозкам в соответствии с Гражданским кодексом Российской Федерации (ГК РФ, часть 2, глава
 140, статья 796, пункт 2), где указано, что “стоимость груза определяется исходя из его цены, указанной в счете продавца или
 предусмотренной договором, а при отсутствии счета или указания цены в договоре исходя из цены, которая при сравнимых обстоятельствах
 обычно взимается за аналогичные товары”. Это означает, что стоимость утраченного груза по законодательству России определяется по
 контрактной цене товара.</p><br>

<p style="text-indent:2em;">Размер претензии правомочного по договору лица должен изначально исчисляться не по контрактной цене товара, а по текущей
 стоимости груза на дату и место принятия его к перевозке.</p><br>

<p style="text-indent:2em;">2. Стоимость груза определяется на основании биржевой котировки или, за отсутствием таковой, на основании текущей рыночной
 цены, или же, при отсутствии и той и другой, на основании обычной стоимости 
 товара такого же рода и качества.</p><br>

<p style="text-indent:2em;">Под стоимостью груза понимается рыночная цена товара, определяемая по котировкам сырьевых и товарных бирж, аукционов, по
 твердым офертам и каталогам основных производителей готовой продукции, по сложившейся текущей цене на дату и место приема
 груза к перевозке. Если для конкретного груза сложно найти его текущую рыночную цену, то она определяется на основе обычной
 стоимости аналогичного товара того же рода и качества. В случае необходимости следует прибегнуть к независимой экспертизе 
 Торгово-промышленной палаты страны места назначения груза.</p><br>

<p style="text-indent:2em;">Так как в нашей стране размер возмещения определяется по контрактной цене товара, получателю предоставляется право доказать, 
что рыночная цена утраченного перевозчиком товара на дату и место приема груза к перевозке выше, чем указано в платежных документах.
 Установление факта такой более высокой цены не влечет за собой, однако, увеличения налоговой и таможенной базы.</p><br>

<p style="text-indent:2em;">Стоимость товара, определенную в соответствии с положениями пунктов 1 и 2 данной статьи, принято называть действительной 
или фактической.</p><br>

<p style="text-indent:2em;">3. Размер возмещения не может, однако, превышать 25 франков за килограмм недостающего веса брутто. Под франком подразумевается
 золотой франк весом в 10/31 грамма золота 0,900 пробы.</p><br>

<p style="text-indent:2em;">Данный пункт заменен другим текстом в соответствии с Протоколом к Конвенции о договоре международной дорожной перевозке груза, 
совершенным в Женеве 5 июля 1978 года. См. комментарии к Протоколу.</p><br>

<p style="text-indent:2em;">4. Кроме того, подлежат возмещению провозные платежи, таможенные сборы и пошлины, а также прочие расходы, связанные с 
перевозкой груза, полностью в случае утраты всего груза и в пропорции, соответствующей размеру ущерба, при частичной утрате;
 иной ущерб возмещению не подлежит.</p><br>

<p style="text-indent:2em;">В случае полной утраты груза перевозчик должен возместить правомочному предъявителю требования цену товара по котировке СРТ 
(по Инкотермс 90 или по Инкотермс 2000), если конечно, не будет ограничения выплат в связи с пределом ответственности перевозчика.
 Об этом свидетельствует перечень дополнительных к цене товара выплат. Расходы отправителя, связанные с перевозкой груза, включают
 в себя, кроме провозных платежей, стоимость оформления перевозочных и товаросопроводительных документов, платежи за проверку груза
 и его массы, стоимость разрешений и книжки МДП и др.</p><br>

<p style="text-indent:2em;">В случае частичной утраты груза размер возмещения пропорционален доле
 причиненного ущерба в стоимостном выражении.</p><br>

<p style="text-indent:2em;">Оговорка “иной ущерб не возмещается” означает, что перевозчик не несет материальной ответственности за косвенные убытки отправителя
 или получателя, а именно: при падении цен на перевозимые товары, перебои или прекращение производства при нарушении сроков доставки,
 неисполнение получателя груза (покупателя товара) своих обязательств перед третьими лицами и пр.</p><br>

<p style="text-indent:2em;">5. В случае просрочки в доставке, если заявитель требования докажет, что просрочка нанесла ущерб, перевозчик обязан возместить
 ущерб, который не может превышать провозных платежей.</p><br>

<p style="text-indent:2em;">Какой бы ущерб ни был нанесен отправителю или получателю вследствие просрочки в доставке груза, размер возмещения не может
 превышать величины провозных платежей, уплаченных перевозчику за услуги по договору перевозки, за исключения случаев, когда
 предъявитель требования докажет преднамеренность или умысел в действиях или упущениях перевозчика. Существование такой нормы
 определяется тем, что перевозчик даже при нарушении срока доставки все же привез груз на место назначения, то есть исполнил
 услугу. В этом случае перевозчик не может нести материальной ответственности за груз, но рискует потерять часть вознаграждения 
 за исполнение перевозки. Штраф, выплаченный перевозчиком за просрочку в доставке исключает возможность требовать от него возмещения
 иного ущерба (исключительная неустойка). Размер неустойки, требуемый к выплате получателем, должен быть им аргументировано обоснован
 и не может превышать провозных платежей. В случае спора конкретный размер штрафа определяется в судебном или арбитражном
 порядке.</p><br>

<p style="text-indent:2em;">6. Более значительное по своему размеру возмещение может быть потребовано с перевозчика только в том случае, если в соответствии со
 статьями 24 и 26 была объявлена стоимость груза или объявлен специальный интерес в доставке.</p><br>

<p style="text-indent:2em;">Заявитель требований не вправе рассчитывать на получения возмещения в больших, чем указано в настоящей статье, размерах, если при
 заключения договора и оформлении накладной не совершил действия, предусмотренные статьями 24 и 26, а также подпунктом d) пункта
 2 статьи 6 Конвенции.</p><br>

<p style="font-weight:500;">Статья 24.</p><br>

<p style="text-indent:2em;">Отправитель может, при условии уплаты обоснованной надбавки к провозным платежам, объявить и указать в накладной стоимость груза,
 превышающую предел, указанный в пункте 3 статьи 23, и в таком случае объявленная сумма заменяет этот предел.</p><br>

<p style="text-indent:2em;">Отправитель может повысить предел ответственности перевозчика за полную или частичную утрату груза, если, предъявляя к перевозке
 груз, действительная стоимость которого выше предела ответственности (по пункту 3 статьи 23), продекларирует стоимость в накладной
 выше предела ответственности перевозчика. Однако объявленная отправителем цена может не отражать его фактической стоимости, а будет
 представлять лишь верхнюю планку ответственности перевозчика за груз по данному договору международной перевозки груза. Какой бы
 высокой ни была объявленная отправителем стоимость груза, размер возмещения за полную утрату не может быть больше его действительной
 стоимости.</p><br>

<p style="text-indent:2em;">“Обоснованность надбавки” применительно к провозным платежам означает, что перевозчик должен объяснить, на каком основании он
 хочет получить заявленную надбавку. Размер надбавки обычно пропорционален увеличению реальной материальной ответственности перевозчика.
 Подразумевается, что номинальный размер провозных платежей соответствует обычному пределу ответственности перевозчика — 8,33 единицы
 специальных прав заимствования в пересчете на один килограмм массы груза брутто. Отправитель вправе согласиться с подобной аргументацией,
 предложить перевозчику свою систему обоснования или отказаться от заключения договора на таких условиях. Результаты договоренности сторон
 вносятся в накладную в соответствии с подпунктом d) пункта 2 статьи 6 Конвенции.</p><br>

<p style="text-indent:2em;">Объявленная (продекларированная) отправителем перевозчику сумма не имеет ничего общего с практикой проставления в накладной контрактной цены
 перевозимого товара, которая используется таможенными органами для начисления налогов, пошлин и сборов в транзитных странах и в стране
 назначения груза.</p><br>

<p style="font-weight:500;">Статья 25.</p><br>

<p style="text-indent:2em;">1. В случае повреждения груза перевозчик уплачивает сумму, соответствующую обесцениванию груза, рассчитываемую по стоимости груза,
 установленной в соответствии с пунктами 1, 2 и 4 статьи 23.</p><br>

<p style="text-indent:2em;">Размер обесценивания груза с признаками порчи или повреждения определяется в физическом и денежном выражении либо по согласованию сторон, 
либо доказывается предъявителем требования с помощью документов независимой экспертизы, выданных, например, ТПП страны назначения груза. Для
 определения размера возмещения не применяется процедура, ограничивающая ответственность перевозчика по пункту 3 статьи 23, за исключением тех
 случаев, когда повреждение или порча привели к полной потере потребительских и товарных свойств и качеств всего или части груза или фактически
 означают таковую потерю. В этих случаях предъявитель требования может настаивать на выплате ему возмещения, соответственно, как за полную или
 частичную утрату груза.</p>

<p style="text-indent:2em;"></p><h6>2. Размер возмещения не может, однако, превышать:</h6><p></p>

<p>a) в случае повреждения всей отправки, суммы возмещения, которая причиталась бы при утрате всего груза;</p><br>

<p style="text-indent:2em;">Размер возмещения за повреждение или порчу всего груза не может быть больше возмещения как за полную его утрату с учетом предела
 ответственности перевозчика.</p><br>

<p style="text-indent:2em;">b) в случае повреждения лишь части отправки, суммы, которая причиталась бы при утрате той части груза, которая оказалась
 поврежденной.</p><br>

<p style="text-indent:2em;">Тоже, что и в предыдущем подпункте, но применительно не ко всему грузу, а лишь к той его части, которая подверглась
 повреждению или порче.</p><br>

<p style="font-weight:500;">Статья 26.</p><br>

<p style="text-indent:2em;">1. Отправитель может при условии уплаты согласованной надбавки к провозным платежам установить сумму специального интереса в
 доставке на случай утраты, повреждения и просрочки в доставке груза в согласованный срок.</p><br>

<p style="text-indent:2em;">Отправитель объявляет сумму специального интереса, как правило, в тех случаях, когда недоставка груза, его повреждение или просрочка
 в доставке могут принести ему или получателю ущерб больший, чем размер возмещения перевозчика за утраченный или поврежденный груз
 и\или штраф за просрочку в доставке. Сумма объявленного специального интереса может быть любой, если стороны договорятся о размере
 надбавки к провозным платежам. Если перевозчик соглашается с объявленной суммой интереса и с размером вознаграждения за риск, то
 считается, что эта сумма является новым пределом ответственности перевозчика за невыполнение или ненадлежащее выполнение им того 
 условия договора, к которому отправителем проявлен специальный интерес.</p><br>

<p style="text-indent:2em;">Чаще всего, отправитель указывает в договоре перевозки специальный интерес в доставке груза не позднее указанного в накладной срока,
 в размере возможных убытков получателя. Такая оговорка, как правило, бывает связана с обязательным выполнением продавцом-отправителем
 условий международного договора купли-продажи, если покупатель-получатель может заранее оценить свои убытки, связанные с несвоевременным
 получением необходимого ему товара.</p><br>

<p style="text-indent:2em;">2. Если объявлен специальный интерес в доставке, то независимо от возмещений, предусмотренных статьями 23, 24 и 25, может быть
 потребовано возмещение доказанного дополнительного ущерба в пределах суммы объявленного интереса.</p><br>

<p style="text-indent:2em;">Какой бы ни была сумма объявленного интереса, размер конкретного возмещения, требуемого от перевозчика, должен быть аргументировано
 доказан. В частности, предъявитель требования должен разъяснить и обосновать, входили ли в сумму объявленного интереса стоимость груза,
 провозные платежи, штрафы, указанные в договоре перевозки, таможенные пошлины и сборы и пр.</p><br>

<p style="text-indent:2em;">Например, в накладной был зафиксирован специальный интерес в доставке груза в согласованный срок в размере возможных убытков получателя
 товара, которые связаны у последнего с неисполнением им своих договорных обязательств перед третьими лицами. Если груз в процессе
 транспортировки был полностью утрачен, то получатель должен обосновать свои претензии по выплате возмещения за недоставленный груз
 дополнительно к суммам объявленного интереса. С другой стороны, если объявленная отправителем сумма интереса ниже размеров ущерба 
 его или получателя, то возмещаются доказанные убытки, но не больше величины,
 указанной отправителем в накладной.</p><br>

<p style="font-weight:500;">Статья 27.</p><br>

<p style="text-indent:2em;">1. Заявитель требования может потребовать уплаты процентов на сумму, подлежащую возмещению. Проценты эти исчисляются из расчета 
пяти процентов годовых со дня направления перевозчику претензии в письменной форме или же, если такая претензия не предъявлялась, 
со дня предъявления иска.</p><br>

<p style="text-indent:2em;">Данная статья относится только к процедуре начисления процентов по требованиям к перевозчику о возмещении ущерба за повреждение, 
порчу, полную или частичную утрату груза, просрочку в доставке и выплатам по суммам объявленного интереса. Следует обратить внимание
 на то, что начало срока исчисления процентов относится к дате предъявления претензии к перевозчику или иска в надлежащем суде или 
 арбитраже. Поэтому факт предъявления претензии должен быть документально зафиксирован.</p><br>

<p style="text-indent:2em;">Сроки и порядок расчетов между сторонами по уплате провозных платежей, дополнительных к ним сборов, штрафов и по взаимному 
сальдированию платежей, как правило, оговариваются сторонами и указываются в договоре перевозки. Там же указываются процентные 
ставки, которые применяются сторонами при несвоевременной уплате надлежащих платежей.</p><br>

<p style="text-indent:2em;">Конвенция не содержит положений о размерах процентных ставок за задержку платежей, за исключением тех, которые упомянуты в данной 
статье. Если стороны не указали в договоре конкретные процентные ставки, то они определяются на основе норм применимого права. 
Например, если положения договора международной дорожной перевозки груза, не подпадающие под юрисдикцию Конвенции, регулируются 
законодательством Российской Федерации, то при задержке уплаты провозных платежей следует руководствоваться положениями статьями 
395,316 ГК РФ.</p><br>

<p style="text-indent:2em;">2. Когда данные, служащие для исчисления подлежащей возмещению суммы, не выражены в валюте государства, в котором предъявлено 
требование о возмещении, перерасчет в эту валюту производится по текущему курсу дня и места 
выплаты возмещения.</p><br>

<p style="text-indent:2em;">Выплата возмещения осуществляется в валюте той страны, в которой предъявлено и удовлетворено требование. Пересчет суммы 
требований в иностранных валютах в национальную осуществляется по курсу на дату и место платежа. В России официальным курсом 
пересчета валют является ежедневный курс Центрального Банка РФ.</p><br>

<p style="font-weight:500;">Статья 28.</p><br>

<p style="text-indent:2em;">1. В тех случаях, когда согласно подлежащему применению закону, в связи с утратой, повреждением или просрочкой в доставке, 
возникшими при перевозке, подпадающей под действие настоящей Конвенции, может быть предъявлено внедоговорное требование, 
перевозчик может сослаться на положения настоящей Конвенции, которые исключают его ответственность или на положения, которые 
определяют или ограничивают возмещение, подлежащее уплате.</p><br>

<p style="text-indent:2em;">Императивная ответственность перевозчика за повреждение, порчу, полную или частичную утрату груза, а также за просрочку в 
доставке не может быть дополнена или каким-либо образом изменена применимыми нормами закона страны суда. Иск к перевозчику, 
основанный на договоре перевозки и связанный с несохранностью груза или нарушением срока его доставки, может быть предъявлен
 только на основании положений настоящей Конвенции. По этим вопросам нормы Конвенции превалируют над соответствующими нормами
 национального права.</p><br>

<p style="text-indent:2em;">Например, иск, поданный в суде или арбитраже России, не может содержать требования выплаты полного возмещения за утраченный 
груз на основании отсутствия в ГК РФ и Уставе автомобильного транспорта РСФСР предела ответственности перевозчика потому, что 
Конвенцией такой предел ответственности установлен (я. 3 ст. 23).</p><br>

<p style="text-indent:2em;">2. Когда требование из внедоговорной ответственности за утрату, повреждение или просрочку в доставке предъявляются к одному из
 лиц, за которых перевозчик отвечает согласно условиям статьи 3, это лицо может также сослаться на положения настоящей Конвенции,
 которые исключают ответственность перевозчика, определяют или ограничивают возмещение,
 подлежащее уплате.</p><br>

<p style="text-indent:2em;">Положения пункта 1 настоящей статьи в полной мере относится к процедуре разрешения споров по требованиям к агентам перевозчика,
 его служащим и иным лицам, к услугам которых прибегает перевозчик для исполнения договора.</p><br>

<p style="text-indent:2em;">Однако эти лица должны доказать, что действовали в рамках возложенных на них обязанностей. Например, агент должен доказать, что
 действовал в интересах перевозчика в рамках заключенного с ним агентского договора.</p><br>

<p style="font-weight:500;">Статья 29.</p><br>

<p style="text-indent:2em;">1. Перевозчик не вправе ссылаться на положения настоящей главы, которые исключают или ограничивают его ответственность или которые
 переносят бремя доказательства на другую сторону, если ущерб был причинен его умышленным неправомерным действием или таким 
 ненадлежащим исполнением обязательств, которое согласно закону, применяемому судом, рассматривающим дело, приравнивается к 
 умышленным неправомерным действиям.</p><br>

<p style="text-indent:2em;">Умышленность или преднамеренность в неправомерных действиях или упущениях перевозчика, приведших к неисполнению или ненадлежащему
 исполнению им своих обязательств по договору международной перевозки груза, должны быть аргументировано и документально доказаны
 лицом, имеющим право предъявить иск, на основании положений Конвенции и закона страны суда.</p><br>

<p style="text-indent:2em;">2. То же самое действительно, если умышленное неправомерное действие или ненадлежащее исполнение обязательств совершено агентом или
 служащим перевозчика или другим лицом, к услугам которых перевозчик прибегает для осуществления перевозки, во время выполнения этими
 агентами, служащими и другими лицами возложенных на них обязанностей. В таком случае эти агенты, служащие или другие лица также не
 вправе ссылаться относительно своей личной ответственности на положения настоящей главы, указанные
 в пункте 1.</p><br>

<p style="text-indent:2em;">Положения пункта 1 применяются также, если указанные выше действия или упущения были допущены агентами перевозчика, его служащими и
 иными лицами, которых перевозчик привлек для исполнения своих обязательств по договору. В таких случаях действия и упущения указанных
 выше юридических и физических лиц отождествляются с действиями и упущениями самого перевозчика.</p>

<p></p><h4>Глава V. Претензии и иски</h4><p></p>

<p style="font-weight:500;">Статья 30.</p><br>

<p style="text-indent:2em;">1. Если получатель принимает груз без надлежащей проверки его состояния совместно с перевозчиком или не делает перевозчику заявления
, указывающего на общий характер утраты или повреждения, самое позднее в момент принятия груза в случае, когда утрата или повреждение
 являются очевидными, не позднее 7 дней со дня принятия груза, исключая воскресные и праздничные дни, в случае, когда утрата или 
 повреждение не являются очевидными, то принятие им груза является доказательством prima facie того, что он получил груз в таком 
 состоянии, как он описан в накладной. В случае утраты и повреждения, которые не являются очевидными, соответствующее заявление 
 должно быть сделано в письменной форме.</p><br>

<p style="text-indent:2em;">Международное автотранспортное право, относящееся к договору перевозки груза, является претензионным. Это означает, что правомочное
 по договору лицо до обращения в суд может попытаться урегулировать свои претензии непосредственно с перевозчиком. Предъявление 
 претензии перевозчику не является для отправителя или получателя обязательным. Если претензия перевозчику не предъявлена, то лицо,
 понесшее убытки, не теряет в связи с этим право на иск.</p><br>

<p style="text-indent:2em;">Предъявление претензии правомочным по договору лицом имеет ряд преимуществ. Во-первых, перевозчик может сразу признать обоснованность
 требований и возместить ущерб. Во-вторых, понесшее убытки лицо может сразу определить размер ущерба. В-третьих такое решение спора 
 экономит средства заведомо виновной стороны, связанные с возможными судебными издержками. В-четвертых, предъявление претензии продлевает
 срок исковой давности на время ее рассмотрения. В-пятых, такое добровольное разрешение спора укрепляет доверие между сторонами договора
 перевозки и сохраняет репутацию перевозчика.</p><br>

<p style="text-indent:2em;">Выдача груза перевозчиком и прием его получателем могут осуществляться как с процедурой совместной проверки, так и без таковой. Чаще 
всего совместная проверка количества и качества груза перевозчиком и получателем не осуществляется. Это связано с тем, что, как правило,
 перевозчик сдает груз не непосредственно получателю, а оставляет его на таможенном складе или терминале, что однако не освобождает перевозчика
 от обязательств окончательной доставки груза (таможенный орган не является получателем груза).</p><br>

<p style="text-indent:2em;">Незаявление получателем требования в отношении несохранности фуза не влечет утрату права на предъявления иска к перевозчику, а лишь
 перелагает бремя доказывания вины перевозчика на грузовладельца.</p><br>

<p style="text-indent:2em;">Если повреждения, порча или утрата носят явный характер, получатель должен сделать мотивированные отметки об этом в накладной в момент
 приема груза. Перевозчик может согласиться или не согласиться с такими оговорками путем подписания или неподписания замечаний. Если
 перевозчик согласился с оговорками, то получатель может принять груз и впоследствии предъявить письменную претензию перевозчику для
 возмещения ущерба на основании акта экспертизы Торгово-промышленной палаты.</p><br>

<p style="text-indent:2em;">Если перевозчик не подпишет оговорки, то получателю не следует принимать груз и он должен сразу обратиться к органу независимой 
экспертизы для определения размера ущерба. В случае немедленного приема груза получателем без принятых перевозчиком оговорок в 
накладной действует презумпция того, что перевозчик сдал груз в надлежащем количестве и качестве.</p><br>

<p style="text-indent:2em;">Если перевозчик сдал, а получатель принял груз внешне в хорошем состоянии, но впоследствии у него были выявлены неявные повреждения 
и утраты, то получатель вправе предъявить письменную претензию перевозчику, подкрепленную актом экспертизы, не позднее семи дней со 
дня приема груза, исключая воскресные и праздничные дни страны выдачи груза. Отметим, что суббота в Российской Федерации является 
официально рабочим днем. Неподача получателем претензии в указанный срок также означает презумпцию 
отсутствия вины перевозчика.</p><br>

<p style="text-indent:2em;">Конвенция не содержит положений о возможности и сроках предъявления претензий перевозчику в случае полной утраты груза, а также тогда,
 когда на основании положений статьи 20 получатель вправе считать груз полностью утраченным и требовать за него полного возмещение.
 Обычно, по истечении сроков, указанных в статье 20, получатель подает перевозчику письменную претензию, доказывая либо полную утрату 
 груза, либо то, что он вправе считать груз утраченным. Такая норма является устоявшимся международным
 обычаем.</p><br>

<p style="text-indent:2em;">Конвенция не предусматривает отправителя в качестве лица, имеющего право предъявить претензию перевозчику. При этом подразумевается, что под
 “получателем” понимается не столько лицо, принявшее груз от перевозчика, сколько правомочное по договору лицо, вступившее в договор 
 международной перевозки груза. Очевидно, что в некоторых случаях (получатель отказался от приема груза, груз возвращен отправителю по
 его указанию, груз не доставлен перевозчику с полностью утраченными перевозочными документами и др.) отправитель остается перед 
 перевозчиком единственным правомочным по договору лицом и имеет право осуществлять действия,
 предписанные Конвенцией для получателя.</p><br>

<p style="text-indent:2em;">2. Когда состояние груза было надлежащим образом проверено получателем и перевозчиком, доказательства, противоречащие результатам этой 
проверки, допускаются только в Случае, когда утрата и повреждения не являются очевидными и при условии, что получатель должным образом 
сделал перевозчику заявление в письменной форме в течение семи дней, исключая воскресные и 
праздничные дни.</p><br>

<p style="text-indent:2em;">Если приемодаточные операции осуществлялись перевозчиком и отправителем с проверкой груза, то у получателя есть 7 дней (не считая 
воскресных и праздничных дней страны пребывания) на то, чтобы доказать, что выявленные впоследствии повреждения и утраты невозможно 
было обнаружить при осмотре груза по наружному виду. Обоснованные доказательства и размер претензий по грузу представляются перевозчику
 в письменном виде.</p><br>

<p style="text-indent:2em;">3. Просрочка в доставке груза может привести к уплате возмещения лишь в том случае, если заявление было сделано в письменной форме в 
течение двадцати одного дня со дня передачи груза в распоряжение получателя.</p><br>

<p style="text-indent:2em;">Нормативы размеров претензий к перевозчику за нарушение срока доставки груза Конвенцией не определены, однако они не могут превышать 
суммы провозных платежей за услуги по договору перевозки. Отсутствие претензии в указанный срок не означает, что перевозчик доставил 
груз, не нарушая условий Конвенции, однако перекладывает бремя доказывания вины перевозчика за такое нарушение договора на получателя. 
См. комментарии к пункту 5 статьи 23 и к статье 26.</p><br>

<p style="text-indent:2em;">4. При исчислении сроков, предусмотренных в настоящей статье, в каждом конкретном случае, день выдачи груза или день проверки, или день 
передачи груза в распоряжение получателя в срок не засчитывается.</p><br>

<p style="text-indent:2em;">Исчисление сроков предъявления претензий, указанных в настоя-; щей статье начинается со дня, следующего за днем выдачи или про-|верки 
груза. Это положение не распространяется на случай, когда повреждения или утраты являются очевидными, и заявить о которых получатель 
обязан в момент приема груза от перевозчика.</p><br>

<p style="text-indent:2em;">5. Перевозчик и получатель должны оказывать друг другу надлежащее
 содействие при выполнении всех необходимых обследований и проверок.</p><br>

<p style="text-indent:2em;">Если получатель сможет доказать, что перевозчик чинил ему различные препятствия при определении качества и количества груза, это может 
иметь самые негативные последствия для определения решения суда, который будет вправе рассматривать действия перевозчика как ненадлежащие 
и недобросовестные, а может быть даже и умышленные.</p><br>

<p style="font-weight:500;">Статья 31.</p>

<p style="text-indent:2em;"></p><h6>1. По всем спорам, возникающим из перевозки, подпадающей под действие настоящей Конвенции, истец, кроме согласованных сторонами судов 
стран-участниц Конвенции, может обратиться в суд страны, на территории которой:</h6><p></p>

<p style="text-indent:2em;">a) ответчик имеет обычное местожительство или имеет местонахождение своего основного коммерческого предприятия или отделения, или 
агентства, через посредство которых был заключен договор перевозки, или</p><br>

<p style="text-indent:2em;">b) находится место принятия груза к перевозке или место, предназначенное для
 сдачи. Обращение в другие суды не допускается.</p><br>

<p style="text-indent:2em;">Здесь приведен полный перечень мест стран, в надлежащие суды которых может обратиться с требованием истец (отправитель, получатель, 
страховщик, перевозчик). Правомочное по договору лицо имеет право выбора места проведения судебного разбирательства. Отправителю удобно 
обращаться в суд по месту приема груза к перевозке, перевозчику— по месту расположения своего основного коммерческого предприятия, а
получателю — по месту, предназначенному для сдачи груза (не следует 
путать с местом фактической сдачи груза перевозчиком).</p><br>

<p style="text-indent:2em;">Однако в любом случае суд, в который обратился истец, должен либо находиться в стране-участнице Конвенции, либо быть компетентным 
рассматривать спор на основе положений Конвенции.</p><br>

<p style="text-indent:2em;">2. Когда в отношении какого-либо спора, указанного в пункте 1 настоящей статьи, иск находится на рассмотрении в суде, компетентном 
в силу положений этого пункта, или когда в отношении такого иска этим судом было вынесено решение, то новый иск между одними и теми 
же сторонами по тому же основанию не допускался, кроме тех случаев, когда решение суда, в котором был предъявлен первый иск, не подлежит
 приведению в исполнение в стране, в которой возбуждается новое разбирательство.</p><br>

<p style="text-indent:2em;">Данная норма нашла свое отражение и в законодательстве нашей страны, в частности в Арбитражном процессуальном кодексе (АПК) Российской
 Федерации. Статья 214 АПК определяет, что арбитражный суд оставляет иск без рассмотрения или прекращает производство по делу, если 
 компетентный суд иностранного государства, принявший дело к рассмотрению до предъявления иска в арбитражный суд Российской Федерации,
 рассматривает дело по спору между теми же лицами, о том же предмете, по тем же основаниям или уже принял по этому делу решение,
 вступившее в законную силу. Однако такие последствия не наступают, если будущее или принятое судом иностранного государства решение
 не подлежит признанию или исполнению на территории России, либо соответствующее дело относится к исключительной компетенции суда
 в Российской Федерации.</p><br>

<p style="text-indent:2em;">Аналогичную норму содержит также “Конвенция о правовой помощи и правовых отношениях по гражданским, семейным и уголовным делам”,
 заключенная между странами СНГ 22 января 1993 года в г. Минске. Пункт 1 статьи 22 этой Конвенции предусматривает, что при ведении
 производства по тождественному делу в компетентных судах разных государств, суд, возбудивший дело позднее, прекращает 
 производство.</p><br>

<p style="text-indent:2em;">Не имея возможности обратиться по одному и тому же делу в другой суд, лицо, оспаривающее правомочность и справедливость принятого 
судом или арбитражем решения, может обратиться к производству по пересмотру решения в апелляционной или кассационной инстанциях страны
 суда, вынесшей первоначальное решение. В Российской Федерации апелляционные и кассационные жалобы предъявляются и рассматриваются в 
 соответствии со главами 20 и 21 АПК.</p><br>

<p style="text-indent:2em;">3. Когда решение по спору, указанному в пункте 1 настоящей статьи, вынесенное судом одной из стран-участниц Конвенции, подлежит 
исполнению в этой стране, это решение становится также подлежащим исполнению в любой из других стран-участниц Конвенции, как 
только выполнены формальности, предписанные в этой стране. Выполнение этих формальностей не может вести к пересмотру дела по 
существу.</p><br>

<p style="text-indent:2em;">Исполнение иностранных судебных решений определено законодательством страны, где предписано исполнение решения, а также международными
 договорами. В соответствии со статьей 215 АПК РФ, арбитражный суд исполняет переданные ему поручения судов иностранных государств о 
 выполнении отдельных процессуальных действий.</p><br>

<p style="text-indent:2em;">Движение поручения к исполнителю может осуществляться по дипломатическим каналам, через центральные органы управления юстиции двух 
государств и непосредственно через арбитражные органы.</p><br>

<p style="text-indent:2em;">Отказ суда страны исполнить присланное ему иностранное судебное поручение возможен только по мотивам, предусмотренным национальным 
законодательством или международными актами. Арбитражные суды исполняют поручения только по тем гражданским делам, которые они были 
бы уполномочены рассматривать исходя из критериев своей подведомственности. В противном случае поручение пересылается органам юстиции
 для определения надлежащего исполнителя. В любом случае исполнение решения не приводит к
 пересмотру существа дела.</p><br>

<p style="text-indent:2em;">4. Положения пункта 3 настоящей статьи применяются к решениям, вынесенным в присутствии сторон, к решениям заочным и к судебным 
мировым сделкам, но не применяются к предварительно исполнимым решениям или к определениям о возмещении истцом, которому полностью
 или частично отказано в иске, ущерба в дополнение к судебным издержкам.</p><br>

<p style="text-indent:2em;">Иностранные судебные решения не подлежат исполнению, если они не являются окончательными и имеют силу лишь в период определенного 
промежутка времени. Кроме того, иностранные судебные решения подлежат исполнению в другой стране лишь в той части, в которой судом 
было вынесено положительное решение.</p><br>

<p style="text-indent:2em;">5. При рассмотрении исков, возникающих из перевозки, подпадающей под действие настоящей Конвенции, не требуется предоставление 
обеспечения судебных издержек гражданами стран-участниц Конвенции, местожительство которых или постоянное пребывание которых 
находится в одной из этих стран.</p><br>

<p style="text-indent:2em;">Судебные расходы состоят из государственной пошлины и издержек, связанных с рассмотрением дела: сумм, подлежащих выплате за 
проведение экспертизы, назначенной арбитражным судом, вызов свидетелей, осмотр доказательств на месте, а также расходов, 
связанных с исполнением судебного акта. В расходы коммерческого арбитража входят: арбитражный сбор (включая регистрационный
 сбор) и дополнительные расходы (издержки на проведение экспертизы и письменные переводы, вознаграждение экспертам и 
 переводчикам, возмещение расходов свидетелям, командировочные расходы и др.).</p><br>

<p style="text-indent:2em;">Предъявитель иска, являющийся гражданином или резидентом одной из стран-участниц Конвенции, обязан уплатить государственную 
пошлину или арбитражный сбор, но не должен предоставлять гарантий обеспечения уплаты судебных или арбитражных издержек. Такие
 издержки (вместе с пошлиной или сбором), как правило, оплачивает впоследствии сторона, проигравшая спор, но по решению суда
 или арбитража.</p><br>

<p style="font-weight:500;">Статья 32.</p>

<p style="text-indent:2em;"></p><h6>1. Срок исковой давности для требований, вытекающих из перевозки, подпадающей под действие настоящей Конвенции, устанавливается
 в один год. Однако, в случае умышленных неправомерных действий или такого ненадлежащего исполнения обязательств, которое 
 согласно закону, применяемому судом, рассматривающим дело, приравнивается к умышленным неправомерным действиям, срок исковой
 давности устанавливается в три года. Течение срока исковой давности начинается:</h6><p></p>

<p>a) в случае частичной утраты груза, повреждения его или просрочки в доставке, — со дня сдачи груза;</p><br>

<p style="text-indent:2em;">b) в случае утраты всего груза — с тридцатого дня по истечении согласованного срока доставки, или, если таковой не был 
согласован, с шестидесятого дня после принятия груза перевозчиком к перевозке;</p><br>

<p style="text-indent:2em;">c) во всех прочих случаях — по истечении трехмесячного срока со дня заключения договора перевозки. День, когда начинается 
течение срока исковой давности, в срок исковой давности не засчитывается.</p><br>

<p style="text-indent:2em;">Исковой давностью признается срок для защиты права по иску лица, право которого нарушено. Срок исковой давности может быть
 увеличен судом, в котором рассматривается иск, до трех лет, если в соответствии с законом страны суда действия какой-либо
 стороны признаны умышленными или приравнены к таковым. Например, перевозчик не доставил груз получателю, отказался предоставлять
 какие-либо документы, сведения и объяснения и избегает контактов с грузовладельцем — такие действия могу быть квалифицированы
 судом как умышленные действия перевозчика при ненадлежащем исполнении им обязательства.</p><br>

<p style="text-indent:2em;">К прочим случаям, указанным в подпункте с) данной статьи, относятся: иски о неуплате или несвоевременной уплате провозных
 платежей и штрафов, требования перевозчиков к грузовладельцам и некоторые другие.</p><br>

<p style="text-indent:2em;">2. Предъявление претензии в письменной форме приостанавливает течение срока исковой давности до того дня, когда перевозчик
 в письменной форме отклонил претензию и вернул приложенные к ней документы. В случае частичного признания претензии течение
 срока исковой давности возобновляется только в отношении той части претензии, которая остается в споре. Бремя доказательства
 получения претензии или ответа на нее, а также возвращения документов, лежит на той стороне, которая ссылается на эти факты.
 Течение срока исковой давности не приостанавливается вследствие предъявления дальнейших претензий
 на том же основании.</p><br>

<p style="text-indent:2em;">Сроки рассмотрения претензий, дачи ответов и удовлетворений требования по ним Конвенцией не определены. Вопрос о том, истек
 или нет срок претензионной давности относится к компетенции суда, рассматривающего дело, и решается на основе норм применимого
 права для условий договора перевозки, не подпадающих под юрисдикцию Конвенции.</p><br>

<p style="text-indent:2em;">Согласно законодательству нашей страны претензия считается отклоненной, если ответчик не удовлетворил ее в разумный срок.
 Поэтому стороны договора международной перевозки груза, как правило, оговаривают сроки рассмотрения претензий. Обычно он
 равен 30 дням с даты предъявления требования.</p><br>

<p style="text-indent:2em;">Предъявитель претензии должен заботиться о документальном фиксировании даты выставления им требования и даты получения 
ответа на нее. Как правило, сначала ответчику высылаются копии документов, и только после получения подтверждения об 
удовлетворении претензии — их оригиналы.</p><br>

<p style="text-indent:2em;">3. При условии соблюдения положений пункта 2 настоящей статьи продление срока исковой давности регулируется законом суда, 
рассматривающим дело. Тот же закон регулирует перерыв течения срока исковой давности.</p><br>

<p style="text-indent:2em;">Приостановление течения срока исковой давности, перерыв течения срока и его восстановление регламентируются в Российской 
Федерации Гражданским кодексом, статьями 202,203 и 205, соответственно.</p><br>

<p style="text-indent:2em;">4. Требования, срок исковой давности по которым истек, не могут быть предъявлены
 в виде встречного иска или возражения.</p><br>

<p style="text-indent:2em;">Лицо, признанное виновным по решению суда или арбитража, не вправе предъявить встречный иск или возражение, если срок исковой
 давности к моменту подачи такого встречного требования уже истек. Единственным основанием для ходатайства о продлении срока 
 исковой давности является доказательство того, что до момента предъявления требования у этого лица не было законных оснований 
 для возбуждения дела. Чаще всего такие основания связаны с умышленными неправомочными действиями другой стороны. См. 
 комментарий к пункту 1 данной статьи.</p><br>

<p style="font-weight:500;">Статья 33.</p><br>

<p style="text-indent:2em;">Договор перевозки может содержать статью, которой признается компетенция арбитража, при условии, чтобы этой статьей 
предусматривалось, что арбитраж должен применять положения настоящей Конвенции.</p><br>

<p style="text-indent:2em;">Обращение в государственные суды общей юрисдикции может не решить всех проблем истца. Получение положительного решения
 такого суда не гарантирует, что оно будет исполнено, если проигравший спор ответчик является иностранным юридическим лицом,
 и исполнять решение суда следует на территории другого государства.</p><br>

<p style="text-indent:2em;">Большинство стран мира, в том числе и Российская Федерация, являются участницами Нью-йоркской 1958 года Конвенции о 
признании и приведении в исполнение иностранных арбитражных решений. Однако положения этого международного договора 
применяются только в тех случаях, когда решение принято не общегосударственным судом, а одним из официальных международных
 коммерческих арбитражей стран-участниц Нью-йоркской конвенции. Таким органом на территории России является Международный
 коммерческий арбитражный суд при ТПП РФ (МКАС). Компетенция МКАС включает любые международные споры, в том числе, 
 связанные с отношениями по перевозке, экспедированию, агентированию и страхованию грузов.</p><br>

<p style="text-indent:2em;">Арбитражная оговорка, рекомендуемая МКАС при ТПП РФ для включения в договоры международной автомобильной перевозки груза,
 выглядит следующим образом: “Все споры, разногласия или требования, возникающие из настоящего договора или в связи с ним,
 в том числе касающиеся его исполнения, нарушения, прекращения или недействительности, подлежат разрешению в Международном
 коммерческом арбитражном суде при Торгово-промышленной палате Российской Федерации в соответствии 
 с его Регламентом”.</p>

<p></p><h4>Глава VI. Положения, касающиеся перевозки, осуществляемой последовательно несколькими перевозчиками</h4><p></p>

<p style="text-indent:2em;">Положения, касающиеся перевозки, осуществляемой последовательно несколькими перевозчиками Положения, содержащиеся в данной
 главе служат для регламентации условий договора перевозки в тех случаях, когда транспортировка осуществляется несколькими
 перевозчиками. Последовательная перевозка разными перевозчиками может осуществляться в силу ряда политических, экономических
 и правовых причин и предпосылок. К таковым следует отнести: запрет или ограничение деятельности перевозчиков отдельных
 государств в той или иной стране, временное закрытие государственных границ, эмбарго на деятельность отдельных перевозчиков
 в той или иной стране, протекционистская поддержка перевозчиков своей страны экономическими и административными методами,
 нежелание отдельных перевозчиков работать в той или иной стране, запреты своих государств на их деятельность в некоторых
 странах, договоренности с перевозчиками других стран о разделе рынка, отсутствие агента перевозчика в стране назначения,
 наличие обратного грузопотока в транзитных странах маршрута и др.</p><br>

<p style="font-weight:500;">Статья 34.</p><br>

<p style="text-indent:2em;">Если перевозка, условия которой определяются единым договором, осуществляется последовательно несколькими автомобильными
 перевозчиками, каждый из них несет ответственность за всю перевозку, причем второй перевозчик и каждый из следующих перевозчиков
 становятся, в силу принятия груза и накладной, участниками договора перевозки на указанных в накладной
 условиях.</p><br>

<p style="text-indent:2em;">Последовательная перевозка несколькими перевозчиками или возможность такой процедуры транспортировки груза не должны быть
 обязательно оговорены в договоре перевозки. Однако добросовестный перевозчик может поставить об этом в известность отправителя.
 Конвенция не запрещает отправителю указывать в договоре и делать отметки в накладной, запрещающие производить перегрузку товара
 в другие транспортные средства и использовать других перевозчиков. Такие оговорки должны быть приняты перевозчиком, и только в
 этом случае перевозчику нельзя будет осуществлять транспортировку груза при участии нескольких последовательных перевозчиков.</p><br>

<p style="text-indent:2em;">Декларируется солидарная ответственность по договору перевозки, которая означает, что каждый последующий перевозчик, принимая
 груз вместе с накладной, вступает тем самым в этот договор и принимает на себя возникающие по нему обязательства в полной мере.
 Каждый последующий перевозчик безоговорочно принимает все условия ранее заключенного договора международной перевозки груза.</p><br>

<p style="font-weight:500;">Статья 35.</p><br>

<p style="text-indent:2em;">1. Перевозчик, принимающий груз от своего предшественника, вручает последнему датированную и подписанную им расписку. Он
 должен вписать свое имя и адрес во второй экземпляр накладной. В случае надобности он делает на этом экземпляре, равно 
 как и на выданной им расписке, оговорки, аналогичные предусмотренным в пункте 2 статьи 8.</p><br>

<p style="text-indent:2em;">Ссылка на пункт 2 статьи 8 означает, что, принимая груз от своего предшественника, последующий перевозчик как бы становится 
“перевозчиком”, а его предшественник “отправителем” в терминах и понятиях указанного пункта статьи 8. Но если последующий 
перевозчик принимает транспортное средство или контейнер за таможенными пломбами, то именно такую отметку ему следует сделать 
в накладной, с которой предшествующий перевозчик должен согласиться.</p><br>

<p style="text-indent:2em;">2. Положения статьи 9 применяются к взаимоотношениям между перевозчиками,
 последовательно осуществляющими перевозку.</p><br>

<p style="text-indent:2em;">Внесение во второй экземпляр накладной своего имени и адреса и выдача расписки своему предшественнику является доказательством
 prima facie того, что последующий перевозчик вступил в договор перевозки и принял груз. При этом предыдущий перевозчик как бы
 является “отправителем”, а последующий — “перевозчиком” в терминах и понятиях статьи 9. Если последующий перевозчик не делает
 никаких мотивированных оговорок в накладной, то с этого момента начинается презумпция его вины за груз, принятый в хорошем
 состоянии. Если последующий перевозчик сделал в накладной оговорки, то они будут иметь юридическую силу, если были приняты 
 предыдущим перевозчиком.</p><br>

<p style="font-weight:500;">Статья 36.</p><br>

<p style="text-indent:2em;">За исключением случаев, когда дело касается встречного иска или возражения, заявленного на одной из стадий рассмотрения иска, 
относящегося к требованию, основанному на том же договоре перевозки, иски в связи с утратой, повреждением или просрочкой в 
доставке могут быть предъявлены только к первому перевозчику, к последнему перевозчику или к перевозчику, который осуществлял 
перевозку на том этапе, на котором произошло событие, вызвавшее утрату, повреждение или просрочку в доставке; иск может быть 
предъявлен одновременно к нескольким из этих перевозчиков.</p><br>

<p style="text-indent:2em;">Лицо, имеющее право предъявить требование, как правило, не знает, на участке какого перевозчика произошла несохранность груза или
 нарушение срока доставки. Поэтому такому лицу предоставляется выбор, кому именно предъявить иск. Отправителю, как правило, удобнее
 подавать иск первому перевозчику, а получателю — последнему. Однако не будет противоречить положениям Конвенции предъявление исков
 правомочным лицом всем перевозчикам, участвовавшим в реализации договора, или некоторым из них.</p><br>

<p style="text-indent:2em;">В случае, если один или несколько перевозчиков имеют встречные требования к правомочному по договору лицу (отправителю или получателю),
 то последнее должно разрешать споры по взаимным претензиям в надлежащем суде или арбитраже только с этим или этими перевозчиками. Если
 встречное требование какого-либо перевозчика предъявлено после иска правомочного по договору лица, то это лицо должно переоформить
 свои требования на имя перевозчика, предъявившего встречный иск.</p><br>

<p style="font-weight:500;">Статья 37.</p>

<p style="text-indent:2em;"></p><h6>Перевозчик, уплативший согласно положениям настоящей Конвенции возмещение за ущерб, имеет право регресса к другим перевозчикам,
 участвовавшим в перевозке, относительно выплаченной суммы возмещения, процентов на нее и издержек, связанных с претензией, при
 соблюдении нижеследующих положений:</h6><p></p>

<p style="text-indent:2em;">a) перевозчик, по вине которого был причинен ущерб, должен нести ответственность за возмещение единолично, независимо от того
 выплачено ли оно им самим или другим перевозчиком;</p><br>

<p style="text-indent:2em;">b) если ущерб был причинен по вине двух или нескольких перевозчиков, каждый из них должен выплатить сумму, пропорциональную доле
 лежащей на них ответственности; если же определение этой доли представляется невозможным, каждый перевозчик несет ответственность
 пропорционально доле причитающихся ему провозных платежей;</p><br>

<p style="text-indent:2em;">с) если нельзя установить, кто из перевозчиков несет ответственность за ущерб, причитающаяся за возмещение его сумма распределяется
 между всеми перевозчиками в пропорции, указанной выше в пункте “Ь”.</p><br>

<p style="text-indent:2em;">Чаще всего один перевозчик принимает от другого опломбированное таможенными органами в соответствии с Конвенцией МДП транспортное
 средство или контейнеры. Если они не содержат видимых повреждений, то у последующего перевозчика, как правило, нет веских оснований
 вносить в накладную и расписку мотивированные оговорки на основании положений статьи 35 настоящей Конвенции. В этих случаях конкретную
 ответственность отдельных перевозчиков установить практически невозможно и она распределяется на каждого из них в соответствии с
 подпунктом с) данной статьи.</p><br>

<p style="text-indent:2em;">Некоторые из последовательных перевозчиков могут доказать другим свою невиновность, если фактами выборочных проверок грузов таможенными
 органами было установлено, что груз на момент вскрытия транспортного средства или контейнеров уже был поврежден или частично утрачен.
 Такие доказательства будут иметь силу, если из содержания таможенных документов можно установить объем и характер порчи, повреждений
 и утрат, а также их причины.</p><br>

<p style="text-indent:2em;">В данном контексте, под провозными платежами следует понимать те суммы, которые непосредственно отражают перевозочную работу каждого
 из участников последовательной доставки груза. Чаще всего пропорции определяются долями фактически пройденного расстояния каждым из
 перевозчиков.</p><br>

<p style="font-weight:500;">Статья 38.</p><br>

<p style="text-indent:2em;">В случае неплатежеспособности одного из перевозчиков, причитающаяся с него и не выплаченная им часть возмещения, распределяется
 между всеми другими перевозчиками пропорционально доле причитающихся им провозных платежей.</p><br>

<p style="text-indent:2em;">Под неплатежеспособностью понимается отсутствие у какого-либо из перевозчиков финансовых возможностей удовлетворить предписанное
 судом или арбитражем требование о возмещении ущерба в данный момент времени. Требование одного или нескольких перевозчиков к неплатежеспособному
 перевозчику на основании решения дела по регрессному иску должно быть квалифицировано как неисполнение последним денежного обязательства и
 разрешаться на основе положений главы IV Конвенции и норм применимого права, в соответствии с которым регулируются условия договора международной
 перевозки груза, не попавшие под юрисдикцию Конвенции.</p><br>

<p style="text-indent:2em;">Положения этой статьи относятся только к взаимоотношениям между перевозчиками, участвовавшими в транспортировке груза, и никоим образом не
 меняют отношений отправителя или получателя с перевозчиком-ответчиком: предъявителю требования безразлично, кто из последовательных перевозчиков
 является неплатежеспособным.</p><br>

<p style="font-weight:500;">Статья 39.</p><br>

<p style="text-indent:2em;">1. Перевозчик, к которому предъявлен регрессный иск в соответствии со статьями 37 и 38, не имеет права оспаривать обоснованность платежа,
 произведенного перевозчиком, предъявляющим регрессный иск, если размер возмещения установлен решением суда, после того, как перевозчик, к 
 которому предъявлен регрессный иск, был надлежащим образом уведомлен о процессе 
 и имел возможность принять в нем участие.</p><br>

<p style="text-indent:2em;">Правомочное по договору лицо (отправитель или получатель), как правило, предъявляют иск одному из перевозчиков, участвовавших в транспортировке
 груза. Если судом или арбитражем вынесено положительное решение в пользу истца, то полный размер возмещения ответчик должен выплатить независимо
 от того, кто конкретно из последовательных перевозчиков на самом деле виновен в повреждении, порче, полной или частичной утрате груза или в
 нарушении срока доставки. После выплаты возмещения перевозчик имеет право предъявить регрессные иски одному, нескольким или всем остальным
 перевозчикам в соответствии с положениями статьи 37.</p><br>

<p style="text-indent:2em;">Если перевозчик, являвшийся ответчиком по иску, уведомил других перевозчиков о процессе и пригласил их принять участие в слушании дела, то
 у последних нет формальных оснований оспаривать правомочность регрессных требований к ним, если они предъявлены в соответствии с положениями
 данной главы Конвенции.</p><br>

<p style="text-indent:2em;">В противном случае, соответчики по иску могут доказать, что перевозчик, которому был предъявлен иск, не принял все необходимые меры и не
 предоставил законные обстоятельства отсутствия вины перевозчиков. В этом контексте при рассмотрении регрессного иска ответчики по нему
 могут доказать ненадлежащие действия или бездействие такого перевозчика, заключавшиеся в том, что он не смог доказать отсутствие своей
 вины.</p><br>

<p style="text-indent:2em;">2. Перевозчик, желающий предъявить регрессный иск, может предъявить свой иск в компетентном суде страны, в которой один из перевозчиков,
 участвовавших в перевозке, имеет обычное местожительство или имеет местонахождение своего основного коммерческого предприятия или отделения
 или агентства, через посредство которых был заключен договор перевозки.</p><br>

<p style="text-indent:2em;">Регрессный иск может быть возбужден в одном и том же суде против всех перевозчиков,
 участвовавших в перевозке.</p><br>

<p style="text-indent:2em;">Регрессный иск должен быть предъявлен каждому из перевозчиков в отдельности либо в местах, исходя из положений данного пункта, либо в
 надлежащем суде по месту заключения договора перевозки, если это место находится в стране-участнице настоящей Конвенции. Второй вывод
 основывается на принципе солидарной ответственности, то есть на том, что все перевозчики, участвовавшие в перевозке, равноправно являлись 
 стороной договора. Истец должен представить доказательства вступления других перевозчиков в договор, а также то, что иск основан на положениях
 данной главы Конвенции и\или других нормах применимого к данному договору перевозки права.</p><br>

<p style="text-indent:2em;">3. Положения пункта 3 статьи 31 применяются к решениям суда, вынесенным по регрессным искам
, упомянутым в статьях 37 и 38.</p><br>

<p>См. комментарии к пункту 3 статьи 31 и к статьям 33 и 40.</p><br>

<p style="text-indent:2em;">4. Положения статьи 32 действительны в отношении регрессных исков, предъявляемых одними перевозчиками к другим. Однако течение срока исковой
 давности начинается со дня вынесения окончательного решения суда, которым определяется размер возмещения, подлежащего выплате согласно
 положениям настоящей Конвенции, или же, при отсутствии такого решения, со 
 дня фактической выплаты возмещения.</p><br>

<p style="text-indent:2em;">Положения статьи 32 в полной мере применяются по регрессным искам для исчисления сроков исковой давности, правил их продления, прерывания
 и возобновления. Исключение составляет правомочность применения пункта 2 этой статьи, так как обязательная претензионная процедура
 предварительного рассмотрения споров по регрессным требованиям одного перевозчика к другим Конвенцией не установлена. См. комментарии
 к статье 40.</p><br>

<p style="font-weight:500;">Статья 40.</p><br>

<p style="text-indent:2em;">Перевозчики вправе заключать между собой соглашения, условия которых
 отличаются от положений статей 37 и 38.</p><br>

<p style="text-indent:2em;">По аналогии с генеральными соглашениями (бланкетными договорами), в которых грузовладелец и перевозчик оговаривают основные условия
 своих взаимоотношений по договору перевозки, перевозчики также могут заключать между собой двусторонние и многосторонние генеральные
 соглашения об основах их взаимоотношений как последовательных перевозчиков. Такие соглашения носят рамочный характер и заключаются в
 случае долгосрочного сотрудничества двух или нескольких перевозчиков. Соглашения призваны дополнять и конкретизировать положения данной
 главы Конвенции, но не противоречить ей.</p><br>

<p style="text-indent:2em;">В таких соглашениях, как правило, указываются географические и страховые зоны интересов и деятельности каждого из перевозчиков,
 порядок и сроки информирования друг друга об осуществлении последовательной перевозки, процедура передачи и приема груза, формы расписок
 и других передаточных документов, процедура расчетов и взаиморасчетов между собой, правила распределения ответственности между
 перевозчиками, претензионный характер предварительного рассмотрения споров, согласованные для рассмотрения споров суды или арбитражи,
 сроки уведомления других, участвовавших в транспортировке перевозчиков, о возбуждении грузовладельцем требования по грузу и сроках начала
 рассмотрения дела, любые другие условия, согласованные сторонами. Такие генеральные соглашения (бланкетные договоры) не должны носить 
 картельный характер и быть направлены против третьих лиц.</p>

<p></p><h4>Глава VII. Недействительность соглашений, противоречащих Конвенции</h4><p></p>

<p style="font-weight:500;">Статья 41.</p><br>

<p style="text-indent:2em;">1. При условии соблюдения положений статьи 40 всякое соглашение, которым прямо или косвенно допускается отступление от положений настоящей 
Конвенции, признается недействительным. Недействительность такого соглашения не влечет за собой недействительность других условий 
договора.</p><br>

<p style="text-indent:2em;">Соглашение между отправителем и перевозчиком (договор международной перевозки груза), как и соглашение перевозчиков между собой, не должны
 содержать положений, противоречащих императивным нормам Конвенции, а те положения, которые все же будут противоречить ей, будут признаны 
 судом или арбитражем, рассматривающим спор, недействительными, что не затрагивает правомочность остальных условий заключенных 
 соглашений.</p><br>

<p style="text-indent:2em;">2. В частности, признается недействительной любая статья, в силу которой перевозчик становится выгодоприобретателем по договору страхования
 груза, или любая аналогичная статья, а также любая статья, которая переносит бремя доказательства
 на другую сторону.</p><br>

<p style="text-indent:2em;">Перевозчик действует в сфере услуг. Поэтому право собственности на груз никогда не переходит на перевозчика. Даже, когда перевозчик 
выполняет дополнительную услугу, страхуя груз по указанию отправителя в соответствии с подпунктом е) пункта 2 статьи 6, он не имеет права
 указать себя в качестве выгодоприобретателя по договору транспортного страхования груза.</p><br>

<p style="text-indent:2em;">Если перевозчик и получатель являются одним и тем же лицом, то ; это лицо может застраховать груз в пользу себя как покупателя товара
 по международному договору купли-продажи, но не как перевозчика.</p><br>

<p style="text-indent:2em;">Однако перевозчик может застраховать свою ответственность по рискам возникновения своих убытков вследствие повреждения, порчи, полной
 или частичной утраты груза или нарушения срока его доставки. Ответственность перевозчика можно застраховать в специально организованных
 клубах страхования взаимной ответственности перевозчиков. Международное транспортное право построено по презумпции вины перевозчика.
 Никакое соглашение между сторонами договора перевозки не может отменить или изменить действительность этого императивного принципа 
 Конвенции. См. комментарии к статьям 17 и 18.</p>

<p></p><h4>Глава VIII. Заключительные положения</h4><p></p>

<p style="font-weight:500;">Статья 42.</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция открыта для подписания или присоединения к ней для стран — членов Европейской Экономической Комиссии и стран, 
допущенных с правом совещательного голоса в соответствии с пунктом 8 мандата этой Комиссии.</p><br>

<p style="text-indent:2em;">2. Страны, допущенные к участию в некоторых работах Европейской Экономической Комиссии согласно статье II ее мандата ведения, могут 
стать Договаривающимися Сторонами настоящей Конвенции путем присоединения к ней после того, как
 она вступит в силу.</p><br>

<p style="text-indent:2em;">3. Конвенция будет открыта для подписания до 31 августа 1956 года включительно. После этой даты она будет открыта для 
присоединения к ней.</p><br>

<p>4. Настоящая Конвенция подлежит ратификации.</p><br>

<p style="text-indent:2em;">5. Ратификация или присоединение осуществляется путем сдачи на хранение генеральному секретарю Организации Объединенных Наций 
ратификационной грамоты или документа о присоединении</p><br>

<p style="text-indent:2em;">На 1 января 2000 г. участниками Конвенции являются: Австрия, Белоруссия, Болгария, Босния и Герцеговина (в порядке правопреемства 
бывшей Югославии), Македония (в порядке правопреемства бывшей Югославии), Венгрия, Германия, Греция, Дания, Ирландия, Испания, 
Италия, Казахстан, Латвия, Литва, Люксембург, Марокко, Молдова, Нидерланды, Норвегия, Польша, Португалия, Российская Федерация, 
Румыния, Словакия (в порядке правопреемства бывшей Чехословакии), Словения (в порядке правопреемства бывшей Югославии), Соединенное
 Королевство, Таджикистан, Тунис, Туркменистан, Турция, Узбекистан, Финляндия, Франция, Хорватия (в порядке правопреемства бывшей 
 Югославии), Чехия (в порядке правопреемства бывшей Чехословакии), Швейцария, Швеция,
 Эстония, Югославия.</p><br>

<p style="font-weight:500;">Статья 43.</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция вступает в силу на девяностый день, после того, как пять из указанных в пункте 1 статьи 42 стран сдадут на 
хранение свои ратификационные грамоты или документы о присоединении.</p><br>

<p>Конвенция вступила в законную силу 2 июля 1961 года.</p><br>

<p style="text-indent:2em;">2. Для каждой страны, которая ратифицирует настоящую Конвенцию и присоединится к ней после того, как пять стран сдадут на хранение 
свои ратификационные грамоты или документы о присоединении, настоящая Конвенция вступает в силу на девяностый день после сдачи 
ратификационных грамот или документов о присоединении.</p><br>

<p style="text-indent:2em;">СССР присоединился к Конвенции 1 августа 1983 года в соответствии Указом президиума Верховного Совета Союза ССР. Для СССР
 Конвенция вступила в силу 30 ноября 1983 года.</p><br>

<p style="font-weight:500;">Статья 44.</p><br>

<p style="text-indent:2em;">1. Любая Договаривающаяся Сторона может денонсировать настоящую Конвенцию путем уведомления генерального секретаря Организации
 Объединенных Наций.</p><br>

<p style="text-indent:2em;">2. Денонсация вступает в силу через двенадцать месяцев, после даты получения
 генеральным секретарем уведомления о денонсации.</p><br>

<p style="font-weight:500;">Статья 45.</p><br>

<p style="text-indent:2em;">Если, после вступления в силу настоящей Конвенции, число Договаривающихся Сторон вследствие денонсации окажется менее пяти,
 настоящая Конвенция теряет силу со дня, когда последняя из денонсации вступает в силу.</p><br>

<p style="font-weight:500;">Статья 46.</p><br>

<p style="text-indent:2em;">1. Любая страна при сдаче на хранение ратификационной грамоты или документа о присоединении или в любое время впоследствии может
 заявить путем уведомления, направленного генеральному секретарю Организации Объединенных Наций, что настоящая Конвенция 
 распространяется на все или часть территорий, за внешние сношения которых она несет ответственность. Конвенция распространяется 
 на территорию или на территории, указанные в уведомлении, по истечении девяноста дней после получения генеральным секретарем 
 Организации Объединенных Наций упомянутого уведомления или, если в тот день Конвенция еще не вступит в силу, в день вступления
 ее в силу.</p><br>

<p style="text-indent:2em;">2. Каждая страна, сделавшая в соответствии с предыдущим пунктом заявление о распространении применения настоящей Конвенции на
 территорию, за внешние сношения которой она несет ответственность, может в соответствии с положениями статьи 44 денонсировать
 Конвенцию в отношении упомянутой территории.</p><br>

<p style="font-weight:500;">Статья 47.</p><br>

<p style="text-indent:2em;">Любой спор между двумя или несколькими Договаривающимися Сторонами относительно толкования или применения настоящей Конвенции, 
который Стороны не смогут разрешить путем переговоров или другим способом, может быть, по просьбе любой из заинтересованных 
Договаривающихся Сторон, передан на разрешение Международного суда.</p><br>

<p style="font-weight:500;">Статья 48.</p><br>

<p style="text-indent:2em;">1. Каждая Договаривающаяся Сторона может при подписании или ратификации настоящей Конвенции или присоединении к ней заявить, 
что она не считает себя связанной статьей 47 Конвенции. Другие Договаривающиеся Стороны не будут связаны статьей 47 по отношению
 ко всем Договаривающимся Сторонам, которые сделали подобную оговорку.</p><br>

<p>Такую оговорку сделали Болгария, Германия, Марокко, Польша, Российская Федерация, Румыния, Турция.</p><br>

<p style="text-indent:2em;">2. Каждая Договаривающаяся Сторона, сделавшая оговорку в соответствии с пунктом 1, может в любой момент взять ее обратно путем 
уведомления, направленного генеральному секретарю Организации Объединенных Наций. L-3. Никакие другие оговорки к настоящей 
Конвенции не допускаются.</p><br>

<p style="font-weight:500;">Статья 49.</p><br>

<p style="text-indent:2em;">1. После трехлетнего действия настоящей Конвенции, любая Договаривающаяся Сторона может путем уведомления, направленного генеральному
 секретарю Организации Объединенных Наций, представить просьбу о созыве конференции для пересмотра настоящей Конвенции. Генеральный 
 секретарь сообщит об этой просьбе всем Договаривающимся Сторонам и созовет конференцию для пересмотра Конвенции, если только в течение
 четырехмесячного срока после его сообщения, по меньшей мере одна четверть Договаривающихся Сторон уведомит его о своем согласии с 
 такой просьбой.</p><br>

<p style="text-indent:2em;">2. Если конференция созывается в соответствии с предыдущим пунктом, генеральный секретарь уведомляет об этом все Договаривающиеся
 Стороны и просит их представить в трехмесячный срок предложения, рассмотрение которых на конференции представляется им желательным.
 По меньшей мере за три месяца до открытия конференции генеральный секретарь сообщает всем Договаривающимся Сторонам предварительную
 повестку дня конференции, а также текст этих предложений.</p><br>

<p style="text-indent:2em;">3. Генеральный секретарь пригласит на любую созванную согласно настоящей статье конференцию все страны, указанные в пункте 1 
статьи 42, а также страны, ставшие Договаривающимися Сторонами на основании пункта 2 статьи 42.</p><br>

<p style="font-weight:500;">Статья 50.</p>

<p style="text-indent:2em;"></p><h6>Помимо уведомлений, упомянутых в статье 49, генеральный секретарь Организации Объединенных Наций сообщает странам, указанным в 
пункте 1 статьи 42, а также странам, ставшим Договаривающимися Сторонами на основании пункта 2 
статьи 42:</h6><p></p>

<p>a) о ратификациях и присоединениях к Конвенции согласно статье 42;</p><br>

<p>b) о датах вступления в силу настоящей Конвенции в соответствии со статьей 43;</p><br>

<p>c) о денонсациях согласно статье 44;</p><br>

<p>d) об утрате настоящей Конвенцией силы в соответствии со статьей 45;</p><br>

<p>e) об уведомлениях, полученных в соответствии со статьей 46;</p><br>

<p>f) о заявлениях и уведомлениях, полученных в соответствии с пунктами 1 и 2 статьи 48.</p><br>

<p style="font-weight:500;">Статья 51.</p><br>

<p style="text-indent:2em;">После 31 августа 1956 г. подлинник настоящей Конвенции будет сдан на хранение генеральному секретарю Организации Объединенных
 Наций, который препроводит надлежащим образом заверенные копии каждой из стран, указанных в пунктах
 1 и 2 статьи 42.</p><br>

<p style="text-indent:2em;">В УДОСТОВЕРЕНИЕ ЧЕГО нижеподписавшиеся, надлежащим образом на то уполномоченные, подписали
 настоящую Конвенцию.</p><br>

<p style="text-indent:2em;">СОВЕРШЕНО в Женеве девятнадцатого мая тысяча девятьсот пятьдесят шестого года в одном экземпляре на английском и французском 
языках, причем каждый текст является равно аутентичным.</p><br>

<p>Протокол о подписании</p><br>

В момент подписания Конвенции о договоре международной дорожной перевозки грузов, нижеподписавшиеся, надлежащим образом 
уполномоченные, согласились внести нижеследующие заявления и пояснения.<p></p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция не применяется к перевозкам между Соединенным Королевством Великобритании и Северной Ирландии 
и Ирландской Республикой.</p><br>

<p style="text-indent:2em;">Тем не менее договоры международных автомобильных перевозок грузов между другими странами-участницами Конвенции и указанными 
|выше государствами регулируются положениями настоящей Конвенции.</p><br>

<p>2. К пункту 4 статьи первой</p><br>

<p style="text-indent:2em;">Нижеподписавшиеся обязуются провести переговоры о заключении конвенции, регулирующей договор перевозки обстановки и мебели 
при переездах, и Конвенции, регулирующей смешанную перевозку.</p><br>

<p style="text-indent:2em;">Конвенция ООН о Международной смешанной перевозке груза была 1 принята и открыта для подписания или присоединения всеми заинтересованными
 государствами на Конференции ООН в Женеве 24 мая 1980 года. (Условное обозначение документа ООН—TD/MT/CONF/17). Конвенция не
 вступила в законную силу до сих пор.</p><br>

<p style="text-indent:2em;">В связи с этим Международная торговая палата (МТП) и Международная федерация экспедиторских ассоциаций (ФИАТА) разработали в 
1992 году проформу коносамента перевозки груза в международном смешанном (интермодальном) сообщении и Стандартные (общие) условия
 оборотного коносамента перевозки груза в смешанном сообщении. Как и Конвенция ООН 1980 года, указанные Стандартные условия 
 являются факультативной нормой и имеют действительную силу только в тех случаях, когда стороны договора интермодальной перевозки груза
 в явном виде указали на юрисдикцию Стандартных условий.</p><br>

<p style="text-indent:2em;">В УДОСТОВЕРЕНИЕ ЧЕГО нижеподписавшиеся, надлежащим образом на то уполномоченные,
 подписали настоящий Протокол.</p><br>

<p style="text-indent:2em;">СОВЕРШЕНО в Женеве девятнадцатого мая тысяча девятьсот пятьдесят шестого года в одном экземпляре на английском и французском языках,
 причем каждый текст является равно аутентичным.</p><br>

<p style="text-indent:2em;">Перевод сделан с подлинного текста, опубликованного ООН на французском и английском языках отдельным изданием за № Е/ЕСЕ/25
 Е/ЕСЕ/ TRANS/480.</p><br>

<p style="text-indent:2em;">Протокол к Конвенции о договоре международной дорожной перевозки грузов 
(КДПГ) совершено в Женеве 5 июля 1978 года</p><br>

<p style="text-indent:2em;">СТОРОНЫ НАСТОЯЩЕГО ПРОТОКОЛА, ЯВЛЯЮЩИЕСЯ Сторонами Конвенции о договоре международной дорожной перевозки грузов (КДПГ)
, подписанной в Женеве 19 мая 1956 года;</p>

<p style="text-indent:2em;"></p><h6>СОГЛАСИЛИСЬ О НИЖЕСЛЕДУЮЩЕМ:</h6><p></p>

<p style="font-weight:500;">Статья 1.</p><br>

<p style="text-indent:2em;">В соответствии с настоящим Протоколом под Конвенцией подразумевается Конвенция о Договоре международной дорожной 
перевозки (КДПГ).</p><br>

<p>Без комментариев.</p><br>

<p style="font-weight:500;">Статья 2.</p>

<p style="font-weight:500;"></p><h6>Статья 23 настоящей Конвенции изменена следующим образом:</h6><p></p>

<p style="text-indent:2em;">1) пункт 3 заменен следующим текстом: 3. Однако размер возмещения не может превышать 8,33 расчетных единиц за кг недостающего
 веса брутто;</p><br>

<p style="text-indent:2em;">Указанное в данном пункте принято называть пределом ответственности перевозчика. Практически любая норма международного транспортного
 права относительно условий договора перевозки на любом виде транспорта содержит положение об ограничении материальной ответственности
 перевозчика в случае полной или частичной утраты перевозимого им груза.</p><br>

<p style="text-indent:2em;">При отсутствии умысла в действиях перевозчика или его грубой небрежности, размер возмещаемого им ущерба ограничен указанной в данном
 пункте величиной. Таким образом, можно утверждать, что перевозчик несет материальную ответственность за полную или частичную утрату
 груза в размере его действительной (фактической) стоимости. Однако размер возмещения не может превышать предела его ответственности,
 исчисляемой по массе фактически утраченного груза.</p>

<p style="text-indent:2em;"></p><h6>2) в конце этой статьи добавлены следующие пункты 7, 8 и 9:</h6><p></p>

<p style="text-indent:2em;">7. Расчетной единицей, указанной в настоящей Конвенции, является единица специальных прав заимствования, соответствующая определению
 Международного валютного фонда. Сумма, указанная в пункте 3 настоящей статьи, переводится в национальную валюту государства, суд
 которого рассматривает данное дело на основе стоимости этой валюты в день вынесения решения или в день, устанавливаемый сторонами
 по договоренности. Выраженная в специальных правах заимствования стоимость национальной валюты государства, которое является членом
 Международного валютного фонда, исчисляется в соответствии с методом оценки, используемым Международным валютным фондом в данный момент
 по своим операциям и сделкам. Выраженная в специальных правах заимствованная стоимость национальной валюты государства, которое не 
 является членом Международного валютного фонда, исчисляется с помощью метода, 
 ^устанавливаемого этим государством.</p><br>

<p style="text-indent:2em;">Единица специальных прав заимствования (СДР—“Special Drawing Rights”) является унифицированной расчетной единицей Международного 
валютного фонда (МВФ) и имеет только безналичную форму. СДР существует с 1974 г., а с 1981 г. формируется на основе корзины, 
состоящей из пяти валют (доллар США — 39%, марка ФРГ — 21%, японская иена — 18%, французский франк и фунт стерлингов — по 11%). 
Курс СДР рассчитывается ежедневно как средневзвешенная величина от рыночной стоимости этих валют. Валютная корзина придала определенную
 стабильность СДР по сравнению с национальными валютами. Определение их курса на базе валютной корзины привело к превращению СДР в 
 международную счетную валютную единицу. Сейчас используется в качестве международного платежного средства в довольно узкой сфере — 
 в основном в официальном секторе (центральные банки стран-членов МВФ, а также некоторые международные организации). Ежедневно Агентство 
 Рейтер публикует курс СДР по отношению к основным твердым валютам мира. На 1 января 
 2000 года 1 СДР = 1,35 доллара США.</p><br>

<p style="text-indent:2em;">8. Тем не менее государство, которое не является членом Международного валютного фонда и национальное законодательство которого 
не позволяет применить положения пункта 7 настоящей статьи, может при ратификации или присоединении к Протоколу к КДПГ или в любое
 время впоследствии заявить, что предусмотренный в пункте 3 настоящей статьи предел ответственности, который применяется на его 
 территории, составляет 25 расчетных единиц. Расчетная единица, указанная в настоящем пункте, соответствует 10/31 гр. золота 0,900
 пробы. Перевод указанной в настоящем пункте суммы в национальную валюту производится в соответствии с национальным законодательством
 заинтересованного государства.</p><br>

<p style="text-indent:2em;">Россия является членом МВФ с 1992 года. Курс СДР в нашей стране рассчитывается Центральным Банком России по текущему курсу
 доллара США.</p><br>

<p style="text-indent:2em;">9. Исчисление, упомянутое в последнем предложении пункта 7, и перевод, указанный в пункте 8 настоящей статьи, следует производить
 таким образом, чтобы выразить в национальной валюте государства, по возможности, ту же реальную стоимость, что и та, которая выражена
 в расчетных единицах в пункте 3 настоящей статьи. При сдаче на хранение документа, указанного в пункте 3 Протокола к КДПГ, и при любом
 изменении применяемого ими метода исчисления, предусмотренного в пункте 7, или же суммы, полученной в результате пересчета, предусмотренного
 в пункте 8 настоящей статьи, государство сообщает генеральному секретарю Организации Объединенных Наций о таковом.</p><br>

<p>Для России не применяется. Без комментариев.</p>

<p></p><h4>Заключительные положения</h4><p></p>

<p style="font-weight:500;">Статья 3.</p><br>

<p style="text-indent:2em;">1. Настоящий Протокол будет открыт для подписания для государств, которые подписали Конвенцию или присоединились к ней и которые либо
 являются членами Европейской Экономической Комиссии, либо допущены к участию в работе Комиссии с консультативным статусом в соответствии
 с пунктом 8 положения о круге ведения этой Комиссии.</p><br>

<p style="text-indent:2em;">2. Настоящий Протокол будет открыт для присоединения к нему любого из упомянутых в пункте 1 настоящей статьи государств, которые
 являются Договаривающимися Сторонами Конвенции.</p><br>

<p style="text-indent:2em;">3. Государства, которые могут участвовать в некоторых работах Европейской Экономической Комиссии согласно статье 11 положения о
 ее круге ведения и которые присоединились к Конвенции, могут стать Договаривающимися Сторонами настоящего Протокола путем
 присоединения к нему по его вступлении в силу</p><br>

<p style="text-indent:2em;">4. Протокол будет открыт для подписания в Женеве с 1 сентября 1978 года по 31 августа 1979 года включительно. После этой даты
 он будет открыт для присоединения к нему.</p><br>

<p style="text-indent:2em;">5. Настоящий Протокол подлежит ратификации после того, как заинтересованное государство ратифицирует Конвенцию или присоединится
 к ней.</p><br>

<p style="text-indent:2em;">6. Ратификация Протокола или присоединение к нему должны производиться путем передачи на хранение генеральному секретарю Организации
 Объединенных Наций над-' лежащего акта.</p><br>

<p style="text-indent:2em;">7. Любой документ о ратификации или присоединении, который сдан на хранение после вступления в силу поправки к настоящему Протоколу
 для всех Договаривающихся Сторон или после завершения всех мер, необходимых для вступления в силу этой поправки для всех Договаривающихся
 Сторон, рассматривается как относящийся к Протоколу с включенной в него поправкой.</p><br>

<p style="text-indent:2em;">На 1 января 2000 г. участниками Протокола являются: Австрия, Бельгия, Македония, Венгрия, Германия, Греция, Дания, Ирландия, Испания,
 Италия, Латвия, Литва, Люксембург, Нидерланды, Норвегия, Португалия, Румыния, Соединенное Королевство, Тунис, Туркменистан, Турция,
 Узбекистан, Финляндия, Франция, Хорватия, Швеция, Швейцария, Эстония.</p><br>

<p style="text-indent:2em;">Российская Федерация не присоединилась к данному Протоколу. Однако его положения используются на практике, поскольку в нашей стране
 не существует иного механизма перерасчета золотых франков в национальную валюту—рубли.</p><br>

<p style="font-weight:500;">Статья 4.</p><br>

<p style="text-indent:2em;">1. Настоящий Протокол вступает в силу на девяностый день после того, как пять из указанных в пунктах 1 и 2 статьи 3 настоящего
 Протокола государств сдадут на хранение свои документы о ратификации или присоединении.</p><br>

<p style="text-indent:2em;">2. Для каждого государства, которое ратифицирует настоящий Протокол или присоединится к нему после того как пять государств сдадут
 на хранение свои документы о ратификации или присоединении, настоящий Протокол вступает в силу на девяностый день со дня сдачи этим
 государством ратификационной грамоты или документа о присоединении.</p><br>

<p style="font-weight:500;">Статья 5.</p><br>

<p style="text-indent:2em;">1. Каждая Договаривающаяся Сторона может денонсировать настоящий Протокол путем нотификации, адресованной генеральному секретарю 
Организации Объединенных Наций.</p><br>

<p style="text-indent:2em;">2. Денонсация вступает в силу по истечении двенадцати месяцев со дня получения
 генеральным секретарем этой нотификации.</p><br>

<p style="text-indent:2em;">3. Любая Договаривающаяся Сторона, которая перестает быть Стороной настоящей Конвенции, одновременно перестает быть Стороной
 настоящего Протокола.</p><br>

<p style="font-weight:500;">Статья 6.</p><br>

<p style="text-indent:2em;">Если после вступления в силу настоящего Протокола число Договаривающихся Сторон окажется вследствие денонсации менее пяти, то
 настоящий Протокол теряет силу с того дня, с которого станет действительной последняя из этих денонсации. Он также теряет силу 
 с того дня, с которого теряет силу Конвенция.</p><br>

<p style="font-weight:500;">Статья 7.</p><br>

<p style="text-indent:2em;">1. Каждое государство может при сдаче на хранение своего документа о ратификации или присоединении или в любой момент впоследствии
 заявить путем нотификации, адресованной генеральному секретарю Организации Объединенных Наций, что настоящий Протокол распространяется
 на все или часть территории, за внешние сношения которых оно является ответственным и в отношении которых оно сделало заявление
 в соответствии со статьей 46 Конвенции. Настоящий Протокол начинает применяться на территории или на территориях, указанных в
 нотификации, на девяностый день после получения генеральным секретарем упомянутой нотификации или, если в тот день Протокол еще
 не вступит в силу, — со дня вступления его в силу.</p><br>

<p style="text-indent:2em;">2. Каждое государство, сделавшее в соответствии с предыдущим пунктом заявление о применении настоящего Протокола на территории, за
 внешние сношения которой оно является ответственным, может в соответствии с вышеупомянутой статьей 5 денонсировать Протокол в
 отношении упомянутой территории.</p><br>

<p style="font-weight:500;">Статья 8.</p><br>

<p style="text-indent:2em;">Всякий спор между двумя или более Договаривающимися Сторонами относительно толкования или применения настоящего Протокола, который
 Стороны не смогут разрешить путем переговоров или другими средствами урегулирования, может быть передан по просьбе любой из 
 заинтересованных Договаривающихся Сторон для разрешения Международному суду.</p><br>

<p style="font-weight:500;">Статья 9.</p><br>

<p style="text-indent:2em;">1. Каждая Договаривающаяся Сторона может в момент подписания или ратификации настоящего Протокола или присоединения к нему заявить
 путем нотификации, адресованной генеральному секретарю Организации Объединенных Наций, что она не считает себя связанной статьей 8
 Протокола. Другие Договаривающиеся Стороны не будут связаны статьей 8 в отношении любой Договаривающейся Стороны, сделавшей такое
 заявление.</p><br>

<p style="text-indent:2em;">2. Заявление, упомянутое в пункте 1 настоящей статьи, в любой момент может быть взято обратно путем нотификации, адресованной
 генеральному секретарю Организации Объединенных Наций.</p><br>

<p>3. Никакие другие оговорки к настоящему Протоколу не допускаются.</p><br>

<p style="font-weight:500;">Статья 10.</p><br>

<p style="text-indent:2em;">1. После трехлетнего действия настоящего Протокола любая Договаривающаяся Сторона может путем нотификации, адресованной генеральному
 секретарю Организации Объединенных Наций, обратиться с просьбой о созыве конференции с целью пересмотра настоящего Протокола. Генеральный
 секретарь сообщает об этой просьбе всем Договаривающимся Сторонам и созывает конференцию для пересмотра Протокола, если в течение
 четырехмесячного срока после его сообщения по меньшей мере одна четверть Договаривающихся Сторон уведомит его о своем согласии на
 созыв такого совещания.</p><br>

<p style="text-indent:2em;">2. Если в соответствий с предыдущим пунктом созывается конференция, то генеральный секретарь уведомляет об этом все Договаривающиеся
 Стороны и обращается к ним с просьбой представить ему в трехмесячный срок предложения, рассмотрение которых на конференции представляется
 им желательным. Не позже чем за три месяца до открытия конференции генеральный секретарь сообщает всем Договаривающимся Сторонам
 предварительную повестку дня конференции, а также текст этих предложений.</p><br>

<p style="text-indent:2em;">3. Генеральный секретарь приглашает на любую конференцию, созванную согласно настоящей статье, все государства, указанные в пунктах
 1 и 2 статьи 3, а также государства, ставшие Договаривающимися Сторонами на основании пункта 3 статьи 3 настоящего Протокола.</p><br>

<p style="font-weight:500;">Статья 11.</p>

<p style="text-indent:2em;"></p><h6>Помимо нотификаций, предусмотренных в статье 10, генеральный секретарь Организации Объединенных Наций сообщает государствам, указанным
 в пунктах 1 и 2 статьи 3, а также государствам, ставшим Договаривающимися Сторонами на основании 
 пункта 3 статьи:</h6><p></p>

<p>a) о ратификации и присоединениях к Протоколу согласно статье 3;</p><br>

<p>b) о датах вступления в силу настоящего Протокола в соответствии со статьей4;</p><br>

<p>c) о сообщениях, полученных согласно пункту 2 статьи 2;</p><br>

<p>d) о денонсации в силу статьи 5;</p><br>

<p>e) об утрате настоящим Протоколом силы в соответствии со статьей 6;</p><br>

<p>f) о нотификациях, полученных в соответствии со статьей 7;</p><br>

<p>g ) о заявлениях и нотификациях, полученных в соответствии с пунктами 1 и 2 статьи 9.</p><br>

<p style="font-weight:500;">Статья 12.</p><br>

<p style="text-indent:2em;">После 31 августа 1979 года подлинник настоящего Протокола будет сдан на хранение генеральному секретарю Организации Объединенных Наций,
 который препроводит надлежащим образом заверенные копии каждому из государств, указанных в пунктах 1, 2 и 3 статьи 3 настоящего 
 Протокола.</p><br>

<p style="text-indent:2em;">СОВЕРШЕНО в Женеве в одном экземпляре пятого июля тысяча девятьсот семьдесят восьмого года на английском и французском языках, причем 
оба текста являются равно аутентичными.</p><br>

<p><a href="http://pravo.kulichki.com/dop/konv/in01.htm">Источник</a></p>

</div>
`