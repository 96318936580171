import React from 'react'
import classNames from 'classnames'
import { Field } from 'redux-form'
import RenderNumber from '../render-number'

export default ({
  t,
  className,
  fieldNameM3,
  fieldNameLdm,
  fieldNamePal,
  validate,
  m3Value,
  ldmValue,
  palValue,
  isTransport,
}) => {
  const validateField = () => {
    const isM3AndValidated = m3Value ? parseFloat(m3Value) > 0 : true
    const isLdmAndValidated = ldmValue ? parseFloat(ldmValue) > 0 : true
    const isPalAndValidated = palValue ? parseFloat(palValue) > 0 : true
    const isOneOfThree = (m3Value || ldmValue || palValue)

    if ( validate && isOneOfThree && isM3AndValidated && isLdmAndValidated && isPalAndValidated ) {
      return null
    } else {
      return validate
    }
  }
  return (
    <div
      className={classNames({
        [className]: !!className,
      })}
    >
      <label className={validate && 'required-field'}>
        {t(`cargo.volume${isTransport ? '' : '.2'}`)}
      </label>
      <div className="input-text-wrapper">
        <div className="input-text">
          <Field
            name={fieldNameM3}
            component={RenderNumber}
            type="text"
            placeholder="000.0"
            //style={{ width: '60px'}}
            unit={
              <span>
                {t('unit.volume.m')}
                <sup>3</sup>
              </span>
            }
            integerLimit={3}
            decimalLimit={1}
            validate={validateField()}
          />
        </div>
        <div className="input-text">
          <Field
            name={fieldNameLdm}
            component={RenderNumber}
            type="text"
            placeholder="00.00"
            //style={{ width: '60px', marginLeft: '10px'}}
            unit="LDM"
            integerLimit={2}
            decimalLimit={2}
            validate={validateField()}
          />
        </div>
        <div className="input-text">
          <Field
            name={fieldNamePal}
            component={RenderNumber}
            type="text"
            placeholder="00"
            //style={{ width: '60px', marginLeft: '10px'}}
            unit="pal"
            integerLimit={2}
            decimalLimit={0}
            validate={validateField()}
          />
        </div>
      </div>
    </div>
  )
}
