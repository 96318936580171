export const eu6 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Transport feroviar</h4><p></p>

<p style="font-weight:500;">O rețea feroviară unică pentru Europa</p>

<p style="text-indent:2em;"></p><h6>SINTEZĂ PRIVIND:</h6><p></p>

<p>Directiva 2012/34/UE privind instituirea unui spațiu feroviar unic al UE</p><br>

<p style="font-weight:500;">SINTEZĂ</p>

<p style="text-indent:2em;"></p><h6>Obiectivul spațiului feroviar unic este de a moderniza sectorul transportului feroviar al Europei abordând trei aspecte:</h6><p></p>

<p>deschiderea pentru concurență a pieței feroviare;</p><br>

<p>îmbunătățirea interoperabilității și a siguranței rețelelor naționale; și dezvoltarea unor infrastructuri de transport feroviar.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p>

<p style="text-indent:2em;"></p><h6>Directiva clarifică normele legislative aplicabile sectorului feroviar european, cu scopul de:</h6><p></p>

<p>a stimula concurența;</p><br>

<p>a consolida supravegherea pieței;</p><br>

<p>și</p><br>

<p>a îmbunătăți condițiile pentru investiții în acest sector.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Concurență sporită: directiva:</h6><p></p>

<p>permite mai multă transparență în ceea ce privește condițiile de acces la piața feroviară;</p><br>

<p style="text-indent:2em;">îmbunătățește accesul operatorilor la servicii conexe transportului feroviar, cum sunt gările, terminalele de marfă și facilitățile de întreținere.</p><br>

<p style="text-indent:2em;">Când infrastructurile sunt exploatate de către societăți feroviare care au avut în trecut o poziție dominantă pe piață, acestea trebuie să fie independente din punct de vedere
 organizatoric și decizional și să aibă sisteme contabile separate.</p>

<p style="text-indent:2em;"></p><h6>Funcțiile vitale ale operatorului de infrastructură (care trebuie exercitate complet independent față de întreprinderile de transport) cuprind:</h6><p></p>

<p>distribuția și alocarea rutelor de tren;</p><br>

<p>decizii privind tarifele percepute pentru accesul la rețele;</p><br>

<p>decizii cu privire la investiții în infrastructură.</p><br>

<p style="text-indent:2em;">În cazul în care una sau mai multe facilități de servicii (de exemplu gări, terminale, grupe de primiri) nu au fost în folosință timp de cel puțin doi ani, acestea trebuie puse la
 dispoziție spre concesionare sau închiriere.</p><br>

<p style="text-indent:2em;">Control reglementar: se consolidează independența organismelor naționale de reglementare. Aceste organisme independente pot să impună amenzi sau să desfășoare audituri. Controlul 
reglementar este consolidat prin strânsa colaborare între organismele de reglementare și autoritățile naționale responsabile cu siguranța și cu acordarea licențelor.</p><br>

<p style="text-indent:2em;">Finanțarea serviciilor feroviare: autoritățile publice competente trebuie să elaboreze strategii de investiții pe termen mai lung, care să le ofere investitorilor mai multă certitudine,
 pentru a încuraja societățile comerciale să investească în modernizarea infrastructurii. Aceste strategii ar trebui să acopere o perioadă de cel puțin cinci ani și să fie reînnoibile.</p><br>

<p style="text-indent:2em;">Tarife și costuri: directiva permite diferențierea tarifelor de utilizare a infrastructurii (printr-un sistem de prime și amenzi), pentru a încuraja operatorii să utilizeze trenuri mai puțin
 zgomotoase și să își doteze locomotivele cu sistemul european de control al trenurilor (ETCS).</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p style="text-indent:2em;">Directiva a intrat în vigoare la 15 decembrie 2012. Țările UE au avut obligația de a o transpune în legislația națională până la 16 iunie 2015.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p style="text-indent:2em;">Această directivă reunește cele trei directive din „primul pachet feroviar”, respectiv cea privind dezvoltarea căilor ferate ale Europei, cea referitoare la acordarea de licențe întreprinderilor
 feroviare și cea privind gestionarea infrastructurii feroviare (Directivele 2001/12/CE, 2001/13/CE și 2001/14/CE).</p><br>

<p>Acest pachet permite deschiderea treptată a sectorului feroviar al Europei pentru concurență.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Directiva 2012/34/UE a Parlamentului European și a Consiliului din 21 noiembrie 2012 privind instituirea spațiului feroviar unic european (JO L 343, 14.12.2012, pp. 32-77)</p><br>

<p style="text-indent:2em;">Modificările succesive aduse Directivei 2012/34/UE au fost integrate în textul de bază. Această versiune consolidată are doar un caracter informativ.</p><br>

<p style="font-weight:500;">ACTE CONEXE</p><br>

<p style="text-indent:2em;">Regulamentul de punere în aplicare (UE) nr. 869/2014 al Comisiei din 11 august 2014 privind noi servicii feroviare pentru călători (JO L 239, 12.8.2014, pp. 1-10)</p><br>

<p style="text-indent:2em;">Regulamentul de punere în aplicare (UE) nr. 870/2014 al Comisiei din 11 august 2014 privind criteriile aplicabile solicitanților de capacități de infrastructură
 feroviară (JO L 239, 12.8.2014, pp. 11-13)</p><br>

<p>Agenția Uniunii Europene pentru Căile Ferate – garantarea unui spațiu feroviar sigur și interoperabil</p>

<p style="text-indent:2em;"></p><h6>SINTEZĂ PRIVIND:</h6><p></p>

<p>Regulamentul (UE) 2016/796 privind Agenția Uniunii Europene pentru Căile Ferate</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI REGULAMENT?</p><br>

<p>Regulamentul instituie Agenția Uniunii Europene pentru Căile Ferate (denumită în continuare „agenția”).</p>

<p style="text-indent:2em;"></p><h6>Obiectivele agenției sunt:</h6><p></p>

<p>de a contribui la dezvoltarea în continuare și la funcționarea eficace a unui spațiu feroviar unic european fără frontiere;</p><br>

<p>de a garanta un nivel ridicat de siguranță feroviară și de interoperabilitate*;</p><br>

<p>de a spori competitivitatea căilor ferate.</p><br>

<p style="text-indent:2em;">Regulamentul este unul dintre cele trei acte legislative care vizează aspectele tehnice ale celui de al patrulea pachet feroviar, care urmărește revitalizarea sectorului feroviar și sporirea calității
 serviciilor și a numărului de opțiuni disponibile pentru călători. Regulamentul se alătură regulamentului privind interoperabilitatea sistemului feroviar al UE și directivei privind siguranța feroviară.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Principalele obiective ale agenției pentru sistemul feroviar al UE sunt:</h6><p></p>

<p>elaborarea unei abordări comune privind siguranța;</p><br>

<p>creșterea nivelului de interoperabilitate;</p><br>

<p style="text-indent:2em;">examinarea normelor naționale în domeniul feroviar pentru a susține autoritățile naționale care activează în domeniile siguranței feroviare și interoperabilității;</p><br>

<p>și</p><br>

<p>promovarea celor mai bune practici.</p><br>

<p style="font-weight:500;">Siguranța feroviară</p>

<p style="text-indent:2em;"></p><h6>Agenția are mai multe sarcini cu privire la siguranța feroviară, inclusiv:</h6><p></p>

<p style="text-indent:2em;">transmiterea de recomandări Comisiei Europene privind indicatorii comuni, metodele comune și obiectivele comune de siguranță, precum și privind sistemul de certificări ale organismelor
 responsabile cu siguranța;</p><br>

<p style="text-indent:2em;">eliberarea, reînnoirea, suspendarea și modificarea certificatelor unice de siguranță și cooperarea cu autoritățile naționale de siguranță în acest sens;</p><br>

<p>încurajarea schimbului de informații privind accidentele, incidentele și incidentele evitate la limită legate de siguranță.</p><br>

<p style="font-weight:500;">Interoperabilitatea</p>

<p style="text-indent:2em;"></p><h6>Agenția are o serie de sarcini menite să îmbunătățească interoperabilitatea prin reducerea barierelor tehnice. Printre acestea se numără:</h6><p></p>

<p>transmiterea de recomandări Comisiei pentru revizuirea specificațiilor tehnice de interoperabilitate (STI);</p><br>

<p style="text-indent:2em;">eliberarea autorizațiilor de introducere pe piață a vehiculelor feroviare și a tipurilor de vehicule. De asemenea, agenția este împuternicită să reînnoiască, să modifice,
 să suspende și să revoce aceste autorizații;</p><br>

<p>aprobarea echipamentelor terestre ale Sistemului european de management al traficului feroviar (ERTMS).</p><br>

<p style="font-weight:500;">Norme naționale</p>

<p style="text-indent:2em;"></p><h6>Agenția este însărcinată să asigure conformitatea normelor naționale cu cerințele esențiale privind interoperabilitatea și siguranța feroviară. În special, aceasta trebuie:</h6><p></p>

<p style="text-indent:2em;">să examineze proiectele de norme naționale și normele naționale existente pentru a asigura conformitatea acestora și faptul că acestea nu duc la discriminare arbitrară;</p><br>

<p style="text-indent:2em;">să gestioneze un sistem informatic dedicat care conține normele naționale și mijloacele naționale de conformitate acceptabile.</p><br>

<p style="font-weight:500;">ERTMS</p><br>

<p style="text-indent:2em;">Agenția îndeplinește rolul de autoritate a sistemului pentru a asigura dezvoltarea coordonată a ERTMS în cadrul UE, în conformitate cu STI relevante.</p><br>

<p style="font-weight:500;">Spațiul feroviar unic european (SERA)</p>

<p style="text-indent:2em;"></p><h6>Agenția are o serie de sarcini legate de monitorizarea SERA, inclusiv:</h6><p></p>

<p>monitorizarea performanței și a procesului decizional al autorităților naționale de siguranță;</p><br>

<p>monitorizarea organismelor notificate de evaluare a conformității;</p><br>

<p>monitorizarea progreselor în domeniul siguranței feroviare și al interoperabilității.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ REGULAMENTUL?</p><br>

<p style="text-indent:2em;">Regulamentul se aplică începând cu 15 iunie 2016 (cu o perioadă de tranziție de trei ani pentru sarcinile legate de autorizare, de certificare și de aprobarea proiectelor
 ERTMS terestre).</p><br>

<p style="font-weight:500;">CONTEXT</p>

<p style="text-indent:2em;"></p><h6>Pentru informații suplimentare, consultați:</h6><p></p>

<p>Agenția Uniunii Europene pentru Căile Ferate;</p><br>

<p style="text-indent:2em;">„Transport feroviar: un acord decisiv va furniza servicii feroviare mai bune pentru călători” (comunicat de presă al Comisiei Europene).</p><br>

<p style="font-weight:500;">* TERMENI-CHEIE</p><br>

<p style="text-indent:2em;">Interoperabilitate: capacitatea unui sistem feroviar de a permite mișcarea continuă și în condiții de siguranță a trenurilor care ating nivelul de performanță solicitat.</p><br>

<p style="font-weight:500;">ACTUL PRINCIPAL</p><br>

<p style="text-indent:2em;">Regulamentul (UE) 2016/796 al Parlamentului European și al Consiliului din 11 mai 2016 privind Agenția Uniunii Europene pentru Căile Ferate și de abrogare a Regulamentului (CE)
 nr. 881/2004 (JO L 138, 26.5.2016, pp. 1-43)</p><br>

<p style="font-weight:500;">ACTE CONEXE</p><br>

<p style="text-indent:2em;">Directiva (UE) 2016/797 a Parlamentului European și a Consiliului din 11 mai 2016 privind interoperabilitatea sistemului feroviar în Uniunea Europeană (JO L 138, 26.5.2016, pp. 44-101)</p><br>

<p style="text-indent:2em;">Directiva (UE) 2016/798 a Parlamentului European și a Consiliului din 11 mai 2016 privind siguranța feroviară (JO L 138, 26.5.2016, pp. 102-149)</p><br>

<p style="font-weight:500;">Un sistem feroviar interoperabil în UE</p>

<p style="text-indent:2em;"></p><h6>SINTEZĂ PRIVIND:</h6><p></p>

<p>Directiva (UE) 2016/797 privind interoperabilitatea sistemului feroviar al UE</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva stabilește condițiile care trebuie îndeplinite pentru realizarea interoperabilității* în cadrul sistemului feroviar al Uniunii Europene (UE).</p><br>

<p style="text-indent:2em;">Aceasta urmărește facilitarea, îmbunătățirea și dezvoltarea serviciilor de transport feroviar în interiorul UE și cu țările din afara UE, contribuind astfel la finalizarea spațiului
 feroviar unic european și la tranziția către tipuri de transport mai eficiente.</p><br>

<p style="text-indent:2em;">Directiva este unul dintre cele trei acte legislative care vizează aspectele tehnice ale celui de al patrulea pachet feroviar, care urmărește revitalizarea sectorului feroviar și
 sporirea calității serviciilor și a numărului de opțiuni disponibile pentru călători. Directiva se alătură regulamentului privind Agenția Uniunii Europene pentru Căile Ferate și
 directivei privind siguranța feroviară.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p>Domeniu de aplicare</p>

<p style="text-indent:2em;"></p><h6>Directiva se aplică sistemului feroviar din țările UE, cuprinzând:</h6><p></p>

<p>vehiculele și infrastructura;</p><br>

<p>energia;</p><br>

<p style="text-indent:2em;">sistemele de semnalizare și aplicațiile telematice* (de exemplu sistemele de emitere a biletelor) pentru serviciile de călători și transport de marfă;</p><br>

<p>asigurarea accesului pentru persoanele cu mobilitate redusă;</p><br>

<p>aspectele legate de zgomot.</p>

<p style="text-indent:2em;"></p><h6>Directiva nu se aplică:</h6><p></p>

<p>metrourilor;</p><br>

<p>tramvaielor și vehiculelor feroviare ușoare, precum și infrastructurii utilizate exclusiv de aceste vehicule;</p><br>

<p style="text-indent:2em;">rețelelor care nu fac parte din sistemele feroviare ale UE și sunt destinate exclusiv exploatării serviciilor de transport de călători locale, urbane sau suburbane, precum și
 întreprinderilor care operează exclusiv pe aceste rețele.</p><br>

<p style="font-weight:500;">Cerințe armonizate</p>

<p style="text-indent:2em;"></p><h6>Directiva stabilește condiții privind:</h6><p></p>

<p style="text-indent:2em;">proiectarea, construirea, punerea în funcțiune, modernizarea, reînnoirea, exploatarea și întreținerea elementelor acestui sistem;</p><br>

<p style="text-indent:2em;">precum și calificările profesionale și condițiile de sănătate și de siguranță aplicabile personalului care contribuie la exploatarea și întreținerea sa.</p>

<p style="text-indent:2em;"></p><h6>Aceste condiții îmbracă forma unor cerințe armonizate care cuprind:</h6><p></p>

<p style="text-indent:2em;"></p><h6>cerințe esențiale privind:</h6><p></p>

<p>siguranța (de exemplu componente precum frânele);</p><br>

<p>fiabilitatea (de exemplu monitorizarea și întreținerea componentelor esențiale);</p><br>

<p>protecția sănătății umane și a mediului;</p><br>

<p>compatibilitatea tehnică și exploatarea sistemului;</p><br>

<p>specificațiile tehnice de interoperabilitate (STI) pentru fiecare subsistem sau parte a unui subsistem;</p><br>

<p style="text-indent:2em;">standardele europene conexe și alte documente pe baza cărora organismele implicate pot demonstra că respectă cerințele esențiale și STI.</p><br>

<p style="font-weight:500;">Conformitate</p>

<p style="text-indent:2em;"></p><h6>Directiva stabilește procedurile referitoare la sisteme și componente interoperabile, vizând:</h6><p></p>

<p>condiții pentru introducerea pe piață;</p><br>

<p>conformitatea și adecvarea pentru utilizare;</p><br>

<p>procedurile în caz de nerespectare a cerințelor esențiale.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p style="text-indent:2em;">Directiva se aplică începând cu 15 iunie 2016. Țările UE au obligația de a o transpune în legislația națională până la 16 iunie 2019.</p><br>

<p style="font-weight:500;">CONTEXT</p>

<p style="text-indent:2em;"></p><h6>Pentru informații suplimentare, consultați:</h6><p></p>

<p style="text-indent:2em;">„Transport feroviar: un acord decisiv va furniza servicii feroviare mai bune pentru călători” (comunicat de presă al Comisiei Europene).</p><br>

<p style="font-weight:500;">* TERMENI-CHEIE</p><br>

<p style="text-indent:2em;">Interoperabilitate: capacitatea unui sistem feroviar de a permite mișcarea continuă și în condiții de siguranță a trenurilor care ating nivelul de performanță solicitat.</p><br>

<p style="font-weight:500;">Telematică: utilizarea integrată a telecomunicațiilor cu tehnologia informațiilor și comunicațiilor.</p><br>

<p style="font-weight:500;">ACTUL PRINCIPAL</p><br>

<p style="text-indent:2em;">Directiva (UE) 2016/797 a Parlamentului European și a Consiliului din 11 mai 2016 privind interoperabilitatea sistemului feroviar în Uniunea Europeană (JO L 138,
 26.5.2016, pp. 44-101)</p><br>

<p style="font-weight:500;">ACTE CONEXE</p><br>

<p style="text-indent:2em;">Regulamentul (UE) 2016/796 al Parlamentului European și al Consiliului din 11 mai 2016 privind Agenția Uniunii Europene pentru Căile Ferate și de abrogare a Regulamentului 
(CE) nr. 881/2004 (JO L 138, 26.5.2016, pp. 1-43)</p><br>

<p style="text-indent:2em;">Directiva (UE) 2016/798 a Parlamentului European și a Consiliului din 11 mai 2016 privind siguranța feroviară (JO L 138, 26.5.2016, pp. 102-149)</p><br>

<p style="font-weight:500;">Asigurarea compatibilității sistemelor feroviare din UE</p><br>

<p style="text-indent:2em;">Uniunea Europeană (UE) și-a propus să accelereze integrarea rețelei sale feroviare* prin introducerea unor condiții și standarde comune. Acestea contribuie la asigurarea
 unor niveluri ridicate de siguranță și eficiență.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Directiva 2008/57/CE a Parlamentului European și a Consiliului din 17 iunie 2008 privind interoperabilitatea sistemului feroviar în cadrul Comunității (reformată).</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Uniunea Europeană (UE) și-a propus să accelereze integrarea rețelei sale feroviare* prin introducerea unor condiții și standarde comune. Acestea contribuie la asigurarea unor
 niveluri ridicate de siguranță și eficiență.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva stabilește condițiile care trebuie îndeplinite pentru a obține o interoperabilitate** în cadrul sistemului feroviar din UE într-un mod care este compatibil cu directiva
 2004/49/CE privind siguranța feroviară. Acest lucru este dorit în special în ceea ce privește procedurile de autorizare a introducerii în circulație a vehiculelor feroviare.</p><br>

<p style="text-indent:2em;">Aceste condiții se referă la proiectarea, construirea, punerea în funcțiune, modernizarea, reînnoirea, exploatarea și întreținerea elementelor acestui sistem. De asemenea, fac
 referire la calificarea profesională și condițiile de sănătate și de siguranță ale personalului care contribuie la exploatarea și întreținerea sa.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">UE își urmărește obiectivul realizării unui sistem feroviar interoperabil - atât pentru sistemele de mare viteză, cât și cele convenționale - prin adoptarea și implementarea
 ulterioară într-un mod uniform a standardelor tehnice aplicabile în toate țările UE.</p>

<p style="text-indent:2em;"></p><h6>Directiva stabilește:</h6><p></p>

<p style="text-indent:2em;">cerințe esențiale în ceea ce privește siguranța (de exemplu, cea a componentelor precum echipamentul de frânare), fiabilitatea (de exemplu, monitorizarea și întreținerea unor
 componente cheie), sănătatea umană, protecția mediului, compatibilitatea tehnică și exploatarea sistemului (anexa III);</p><br>

<p style="text-indent:2em;">specificațiile tehnice pentru interoperabilitate (STI) adoptate de Agenția Europeană a Căilor Ferate pentru fiecare subsistem*** sau parte a unui subsistem, potrivit celor
 stipulate de această directivă;</p><br>

<p>specificațiile europene corespunzătoare.</p><br>

<p style="text-indent:2em;">Comisia Europeană are competența de a adopta legislații suplimentare privind aspectele specifice ale specificațiilor tehnice referitoare la interoperabilitatea feroviară.
 Aspectele abordate de o astfel de legislație includ specificații tehnice pentru vagoanele de marfă, locomotive, accesibilitatea la sistemul feroviar pentru persoanele cu
 handicap sau cu mobilitate redusă și siguranța în tuneluri.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p>Începând cu 19 iulie 2008.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p style="text-indent:2em;">Această directivă a abrogat directiva 96/48CE privind interoperabilitatea sistemului feroviar transeuropean de mare viteză și directiva 2001/16/CE privind interoperabilitatea sistemului
 feroviar european convențional.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE AI ACTULUI</p><br>

<p style="font-weight:500;">Rețea: liniile, stațiile, terminalele și orice echipamente fixe necesare pentru asigurarea exploatării în siguranță și în condiții bune a sistemului feroviar.</p><br>

<p style="font-weight:500;">Interoperabilitate: capacitatea unui sistem feroviar de a permite mișcarea continuă și în condiții de siguranță a trenurilor care ating nivelurile solicitate de performanță pentru
 aceste linii.</p><br>

<p style="font-weight:500;">Subsisteme: înseamnă rezultatul divizării sistemului feroviar (anexa II). Aceste subsisteme pentru care trebuie să se stabilească cerințe esențiale, pot fi structurale (de exemplu,
 infrastructură sau semnalizare) sau funcționale (de exemplu, gestionarea traficului sau întreținerea).</p><br>

<p>Pentru informații suplimentare, consultați site-ul Comisiei Europene privind interoperabilitatea.</p><br>

<p style="font-weight:500;">Rețeaua feroviară europeană pentru un transport de marfă competitiv</p><br>

<p style="text-indent:2em;">Acest regulament stabilește normele de instituire și de organizare a coridoarelor feroviare internaționale pentru un transport feroviar de marfă competitiv în vederea dezvoltării unei
 rețele feroviare europene pentru un transport de marfă competitiv.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Regulamentul (UE) nr. 913/2010 al Parlamentului European și al Consiliului din 22 septembrie 2010 privind rețeaua feroviară europeană pentru un transport de marfă competitiv.</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Scopul acestui regulament este de a dezvolta o rețea feroviară europeană pentru un transport de marfă competitiv prin stabilirea unor norme de instituire și de organizare a coridoarelor
 feroviare internaționale pentru un transport feroviar de marfă competitiv. Anexa la acest regulament, modificată prin Regulamentul (UE) nr. 1316/2013, instituie 9 coridoare de transport
 de marfă inițiale , pe care țările în cauză din Uniunea Europeană (UE) trebuie să le aducă în stare operațională până în noiembrie 2013, noiembrie 2015 sau noiembrie 2020.</p><br>

<p style="text-indent:2em;">Pentru fiecare coridor de transport de marfă, țările UE trebuie să stabilească un comitet executiv, alcătuit din reprezentanți ai autorităților țărilor UE. Pentru fiecare coridor de transport
 de marfă, administratorii de infrastructură trebuie să stabilească un consiliu de administrație, alcătuit din reprezentanții administratorilor de infrastructură. Acest consiliu de administrație
 întocmește un plan de punere în aplicare ce include un plan de investiții, măsurile prevăzute pentru punerea în aplicare a coridorului și elementele principale ale unui studiu privind piața.
 De asemenea, consiliul de administrație va institui un grup consultativ alcătuit din administratori și proprietari ai terminalelor coridorului de transport de marfă, precum și un alt grup
 consultativ format din întreprinderile feroviare care sunt interesate de utilizarea coridorului de transport de marfă.</p><br>

<p style="text-indent:2em;">Consiliul de administrație definește și organizează în comun trasee internaționale prestabilite pentru trenurile de marfă, astfel încât să ofere un grafic de parcurs corespunzător nevoilor
 operatorilor de transport de marfă.</p><br>

<p style="text-indent:2em;">Consiliul de administrație va institui sau desemna un organism comun, astfel încât solicitanții autorizați să poată solicita și primi răspunsuri într-un singur loc cu privire la capacitatea
 de infrastructură pentru trenurile de marfă care traversează cel puțin o frontieră de-a lungul coridorului de transport de marfă. Acest ghișeu unic va lua decizii cu privire la cererile
 referitoare la trasele prestabilite și pentru rezerva de capacitate pentru trenurile de transport internațional de marfă. Eventualele cereri care nu pot fi onorate de ghișeul unic vor fi
 retransmise administratorilor de infrastructură competenți, care vor lua o decizie privind cererea și vor comunica ghișeului unic decizia respectivă în vederea unei prelucrări ulterioare.</p><br>

<p>Pentru cazurile de perturbări ale traficului se elaborează norme de prioritate între diferitele tipuri de trafic.</p><br>

<p>Se publică un document care conține toate informațiile relevante cu privire la utilizarea coridorului.</p><br>

<p>Organismele de reglementare cooperează și fac schimb de informații, în special în cazul unor reclamații.</p><br>

<p style="font-weight:500;">Plan de acțiune privind logistica transportului de marfă</p><br>

<p style="text-indent:2em;">Logistica transportului de marfă este un motor al competitivității Uniunii Europene (UE). Aceasta vizează planificarea, organizarea, gestionarea, controlul și executarea operațiunilor
 de transport de marfă.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Comunicarea Comisiei - Plan de acțiune privind logistica transportului de marfă [COM(2007) 607 final din 18.10.2007]</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Logistica transportului de marfă este un motor al competitivității Uniunii Europene (UE). Aceasta vizează planificarea, organizarea, gestionarea, controlul și executarea operațiunilor
 de transport de marfă.</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTUI PLAN DE ACȚIUNE?</p><br>

<p style="text-indent:2em;">Planul conține măsuri pe termen scurt și mediu. Acesta face parte dintr-o serie de inițiative politice lansate de Comisia Europeană în scopul îmbunătățirii eficienței și viabilității
 transportului de marfă în UE.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="text-indent:2em;">Transportul electronic de marfă: utilizarea tehnologiilor informației și comunicațiilor (TIC) ar putea permite asocierea fluxului fizic de marfă cu un flux de informații electronic și 
„fără hârtie”.</p><br>

<p style="font-weight:500;">Blocaje: s-au identificat aproape 500 de blocaje, de la infrastructura insuficientă până la obstacole administrative. Acestea pot fi abordate prin formarea personalului calificat, prin 
utilizarea unor indicatori de performanță și prin date statistice de calitate sporită.</p><br>

<p style="font-weight:500;">Lanțuri de transport: simplificarea cerințelor administrative prin utilizarea unui document unic pentru toate formele de transport și a unor norme clare privind răspunderea ar putea reduce
 costurile și birocrația.</p><br>

<p style="font-weight:500;">Greutăți și dimensiuni: legislația UE impune limite privind greutățile și dimensiunile vehiculelor. Unele aspecte privind greutatea sunt lăsate însă la latitudinea autorităților naționale.
 În urma unei revizuiri din 2012 a legislației s-au adoptat norme UE revizuite în 2015. Obiectivul acestora este de a îmbunătăți aerodinamica vehiculelor și eficiența energetică a acestora.</p><br>

<p style="font-weight:500;">Coridoare verzi de transport: aceste rute integrate ar permite transportarea mărfurilor cu un impact redus asupra mediului printr-o combinație a călătoriilor scurte maritime, feroviare,
 rutiere și pe căi navigabile interioare și a tehnologiilor relevante (cum ar fi TIC folosite în sistemele de transport inteligente).</p><br>

<p style="font-weight:500;">Transportul urban de marfă: orașele Europei sunt adesea destinația unor mărfuri care au călătorit pe distanțe mari. Urbanismul ar trebui să țină cont de acest aspect.</p>

<p style="text-indent:2em;"></p><h6>Printre alte inițiative conexe ale UE se numără:</h6><p></p>

<p style="text-indent:2em;">mecanismul Conectarea Europei, care pune la dispoziție finanțare pentru proiecte privind rețele de transport transfrontaliere și alte rețele de transport de bază;</p><br>

<p style="text-indent:2em;">creșterea economică albastră, care evidențiază modul în care transportul pe mare, mai puțin poluant decât cel rutier, contribuie la reducerea emisiilor de gaze cu efect de seră;</p><br>

<p>directiva privind planificarea spațiului maritim, care sporește cooperarea între țările UE în domenii precum căile navigabile;</p><br>

<p style="text-indent:2em;">„ Foaia de parcurs pentru un spațiu european unic al transporturilor ” a Comisiei Europene, care identifică modalități de eliminare a principalelor blocaje din calea transportului de
 marfă și de persoane.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p style="text-indent:2em;">Se estimează că industria logistică are o contribuție de aproape 14 % la PIB-ul UE. Aceasta s-a bucurat de o creștere semnificativă în ultimii ani, în special în schimburile comerciale
 prin containere. Acest fapt a dus la congestionarea unor porturi și a zonelor din jurul acestora.</p><br>

<p style="font-weight:500;">ACTE CONEXE</p><br>

<p style="text-indent:2em;">Carte albă: Foaie de parcurs pentru un spațiu european unic al transporturilor - Către un sistem de transport competitiv și eficient din punct de vedere al resurselor [COM(2011)
 144 final din 28.3.2011]</p><br>

<p style="font-weight:500;">Siguranța feroviară la nivelul UE</p>

<p style="text-indent:2em;"></p><h6>SINTEZĂ PRIVIND:</h6><p></p>

<p>Directiva (UE) 2016/798 privind siguranța feroviară</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Obiectivul directivei este de a spori siguranța feroviară la nivelul întregii Uniuni Europene (UE) revizuind rolul autorităților naționale de siguranță (ANS) și realocând responsabilitățile
 între acestea și Agenția Uniunii Europene pentru Căile Ferate (denumită în continuare „agenția”).</p><br>

<p style="text-indent:2em;">Directiva este unul dintre cele trei acte legislative care vizează aspectele tehnice ale celui de al patrulea pachet feroviar, care urmărește revitalizarea sectorului feroviar și sporirea
 calității serviciilor și a numărului de opțiuni disponibile pentru călători. Directiva se alătură regulamentului privind Agenția Uniunii Europene pentru Căile Ferate și directivei privind
 interoperabilitatea sistemului feroviar.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p>

<p style="text-indent:2em;"></p><h6>Directiva stabilește o serie de măsuri pentru asigurarea dezvoltării și ameliorării siguranței și a unui acces îmbunătățit pe piață pentru serviciile de transport feroviar, inclusiv:</h6><p></p>

<p style="text-indent:2em;">stabilirea statutului agenției ca organism care emite certificate de siguranță întreprinderilor feroviare care funcționează în mai multe țări ale UE;</p><br>

<p>definirea responsabilităților diferitelor organisme implicate în sistemul feroviar al UE;</p><br>

<p style="text-indent:2em;">elaborarea de obiective comune de siguranță și metode comune de siguranță în vederea eliminării normelor naționale și, astfel, a barierelor din calea dezvoltării unui spațiu feroviar
 unic european;</p><br>

<p style="text-indent:2em;">stabilirea de principii pentru eliberarea, reînnoirea, modificarea și restricționarea sau revocarea certificatelor și a autorizațiilor de siguranță;</p><br>

<p style="text-indent:2em;">cerința instituirii în fiecare țară a UE a unei autorități naționale de siguranță și a unui organism de investigare a accidentelor și incidentelor;</p><br>

<p>definirea unor principii comune pentru gestionarea și controlul siguranței feroviare.</p><br>

<p style="font-weight:500;">Domeniu de aplicare</p>

<p style="text-indent:2em;"></p><h6>Directiva se aplică sistemului feroviar din țările UE, dar nu se aplică:</h6><p></p>

<p>metrourilor;</p><br>

<p>tramvaielor și vehiculelor feroviare ușoare precum și infrastructurii utilizate exclusiv de aceste vehicule;</p><br>

<p style="text-indent:2em;">rețelelor care nu fac parte din sistemul feroviar al UE și sunt destinate exclusiv exploatării serviciilor de transport de călători locale, urbane sau suburbane, precum și întreprinderilor
 care operează exclusiv pe aceste rețele.</p><br>

<p style="font-weight:500;">Dezvoltarea și managementul siguranței feroviare</p><br>

<p style="text-indent:2em;">În limitele competențelor care le revin în temeiul legislației UE, agenția și țările UE au responsabilitatea de a se asigura că siguranța feroviară este menținută și, în general, îmbunătățită,
 acordând prioritate prevenirii accidentelor.</p>

<p style="text-indent:2em;"></p><h6>Țările UE au și responsabilitatea de a se asigura că:</h6><p></p>

<p>măsurile de dezvoltare și îmbunătățire a siguranței feroviare urmează o abordare sistemică;</p><br>

<p style="text-indent:2em;">administratorilor de infrastructură și întreprinderilor feroviare le revine responsabilitatea pentru exploatarea sigură a sistemului feroviar al UE și pentru controlul tuturor riscurilor asociate.</p><br>

<p style="font-weight:500;">Metode și obiective comune de siguranță</p>

<p style="text-indent:2em;"></p><h6>Evaluarea nivelurilor de siguranță, a realizării obiectivelor de siguranță și a conformității cu alte cerințe de siguranță se realizează prin metode comune de siguranță(MCS) . Este vorba în
 special despre metode comune pentru:</h6><p></p>

<p>evaluarea riscurilor;</p><br>

<p>evaluarea conformității pentru eliberarea certificatelor și a autorizațiilor de siguranță;</p><br>

<p style="text-indent:2em;">supravegherea de către autoritățile naționale de siguranță și monitorizarea de către întreprinderile feroviare, administratorii de infrastructură și organismele de întreținere feroviară;</p><br>

<p>evaluarea realizării obiectivelor de siguranță la nivelul UE și la nivel național.</p><br>

<p style="text-indent:2em;">Obiectivele minime de siguranță pe care trebuie să le realizeze sistemul feroviar al UE în ansamblu sunt definite în obiectivele comune de siguranță (OCS). OCS pot fi exprimate în criterii de
 acceptare a riscului sau în niveluri de siguranță vizate.</p><br>

<p style="font-weight:500;">Certificatul unic de siguranță</p><br>

<p style="text-indent:2em;">Accesul la infrastructura feroviară a UE se acordă exclusiv întreprinderilor care dețin un certificat unic de siguranță eliberat fie de agenție, fie de autoritățile naționale de siguranță 
competente.</p><br>

<p style="text-indent:2em;">Obiectivul certificatului este de a dovedi că întreprinderea în cauză și-a instituit propriul sistem de management al siguranței și că este capabilă să desfășoare operațiuni în condiții de
 siguranță în zona de funcționare preconizată.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p style="text-indent:2em;">Directiva a intrat în vigoare la 15 iunie 2016. Țările UE au obligația de a o transpune în legislația națională până la 16 iunie 2019.</p><br>

<p style="font-weight:500;">CONTEXT</p>

<p style="text-indent:2em;"></p><h6>Pentru informații suplimentare, consultați:</h6><p></p>

<p style="text-indent:2em;">„Transport feroviar: un acord decisiv va furniza servicii feroviare mai bune pentru călători” (comunicat de presă al Comisiei Europene).</p><br>

<p style="font-weight:500;">ACTUL PRINCIPAL</p><br>

<p style="text-indent:2em;">Directiva (UE) 2016/798 a Parlamentului European și a Consiliului din 11 mai 2016 privind siguranța feroviară (JO L 138, 26.5.2016, pp. 102-149)</p><br>

<p style="font-weight:500;">ACTE CONEXE</p><br>

<p style="text-indent:2em;">Regulamentul (UE) 2016/796 al Parlamentului European și al Consiliului din 11 mai 2016 privind Agenția Uniunii Europene pentru Căile Ferate și de abrogare a Regulamentului (CE) nr. 881/2004
 (JO L 138, 26.5.2016, pp. 1-43)</p><br>

<p style="text-indent:2em;">Directiva (UE) 2016/797 a Parlamentului European și a Consiliului din 11 mai 2016 privind interoperabilitatea sistemului feroviar în Uniunea Europeană (JO L 138, 26.5.2016, pp. 44-101)</p><br>

<p style="font-weight:500;">Transportul interior de mărfuri periculoase</p>

<p style="text-indent:2em;"></p><h6>SINTEZĂ PRIVIND:</h6><p></p>

<p>Directiva 2008/68/CE privind transportul interior de mărfuri periculoase</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Directiva prevede norme comune pentru transportul în condiții de siguranță și securitate al mărfurilor periculoase pe teritoriul țărilor Uniunii Europene (UE) și între acestea, pe cale rutieră,
 feroviară sau pe căi navigabile interioare. De asemenea, directiva vizează aspecte precum încărcarea și descărcarea, transferul dinspre sau înspre alt mod de transport, precum și staționările
 pe parcursul procesului de transport. Actul legislativ extinde aplicarea normelor internaționale la transportul intern de mărfuri periculoase.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Excluderi</p>

<p style="text-indent:2em;"></p><h6>Directiva nu se aplică transportului de mărfuri periculoase realizat cu:</h6><p></p>

<p>vehicule, vagoane sau nave aparținând forțelor armate sau aflate sub răspunderea acestora;</p><br>
<p>nave maritime pe căi navigabile maritime care fac parte din căile navigabile interioare;</p><br>
<p>feriboturi care traversează numai căi navigabile interioare sau un port;</p><br>
<p>efectuat integral în perimetrul unei zone închise.</p><br>

<p style="font-weight:500;">Norme naționale</p>

<p style="text-indent:2em;"></p><h6>Din alte motive decât cele care țin de siguranța pe durata transportului (de exemplu, din motive de securitate națională sau de protecție a mediului), țările UE au dreptul de a reglementa sau 
interzice transportul de mărfuri periculoase pe teritoriul propriu. De asemenea, acestea pot stabili cerințe specifice de siguranță pentru transportul național și internațional de mărfuri periculoase
 pe teritoriul lor în ceea ce privește:</h6><p></p>

<p style="text-indent:2em;">transportul de mărfuri periculoase realizat cu vehicule, vagoane sau nave destinate căilor navigabile interioare, care nu face obiectul acestei directive;</p><br>

<p>în cazuri justificate, utilizarea unor rute prestabilite, inclusiv utilizarea unor moduri de transport prestabilite;</p><br>

<p>normele speciale pentru transportul de mărfuri periculoase în trenuri de călători.</p><br>

<p style="font-weight:500;">Acorduri internaționale</p><br>

<p style="text-indent:2em;">Transportul internațional de mărfuri periculoase este reglementat de Acordul european referitor la transportul rutier internațional al mărfurilor periculoase (ADR)*, de Acordul european privind transportul
 internațional al mărfurilor periculoase pe căi navigabile interioare (ADN)* și de Regulamentul privind transportul internațional feroviar al mărfurilor periculoase (RID)*. Aceste norme internaționale
 trebuie să fie extinse la transportul național pentru a armoniza în întreaga Comunitate condițiile în care sunt transportate mărfurile periculoase și pentru a asigura funcționarea adecvată a pieței comune
 a UE în domeniul transporturilor. În anexele la directivă se face referire la textele acestor acorduri.</p><br>

<p style="text-indent:2em;">ADR, RID și ADN au elaborat o listă cu mărfurile periculoase, indicând dacă transportul acestora este interzis sau nu și definind cerințele pentru transportul lor, în cazul în care acesta este autorizat.
 Țările UE pot solicita derogări temporare în anumite condiții.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p>Începând cu 20 octombrie 2008.</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="font-weight:500;">ADR: Acordul european referitor la transportul rutier internațional al mărfurilor periculoase, încheiat la Geneva la 30 septembrie 1957.</p><br>

<p style="font-weight:500;">ADN: Acordul european privind transportul internațional al mărfurilor periculoase pe căi navigabile interioare, încheiat la Geneva la 26 mai 2000.</p><br>

<p style="font-weight:500;">RID: Regulamentul privind transportul internațional feroviar al mărfurilor periculoase, care figurează în anexa C la Convenția privind transporturile internaționale feroviare (COTIF), încheiată
 la Vilnius la 3 iunie 1999.</p><br>

<p style="font-weight:500;">CONTEXT</p><br>

<p>Pagina referitoare la siguranța rutieră</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Directiva 2008/68/CE a Parlamentului European și a Consiliului din 24 septembrie 2008 privind transportul interior de mărfuri periculoase</p><br>

<p style="font-weight:500;">Siguranța feroviară</p>

<p style="text-indent:2em;"></p><h6>SINTEZĂ PRIVIND:</h6><p></p>

<p>Directiva 2004/49/CE – siguranța pe căile ferate din UE</p><br>

<p style="font-weight:500;">CARE ESTE ROLUL ACESTEI DIRECTIVE?</p><br>

<p style="text-indent:2em;">Obiectivul directivei este de a crea un sistem feroviar mai competitiv și mai sigur, care să acopere întreaga piață a Uniunii Europene (UE), în loc să se limiteze în principal la piețele naționale.</p><br>

<p style="font-weight:500;">ASPECTE-CHEIE</p><br>

<p style="font-weight:500;">Domeniul de aplicare</p><br>

<p style="text-indent:2em;">Directiva se aplică sistemului feroviar al țărilor UE și vizează cerințele de siguranță pentru sistemul în ansamblu, inclusiv infrastructura și gestionarea traficului, precum și interacțiunea dintre
 întreprinderile feroviare* și gestionarii de infrastructură*.</p>

<p style="text-indent:2em;"></p><h6>În acest sens, directiva se concentrează pe patru aspecte majore:</h6><p></p>

<p>instituirea, în fiecare țară a UE, a unei autorități responsabile cu controlarea siguranței;</p><br>

<p>recunoașterea reciprocă a certificatelor de siguranță eliberate în țările UE;</p><br>

<p style="text-indent:2em;">stabilirea unor indicatori de siguranță comuni (ISC) pentru a evalua conformitatea sistemului cu obiectivele de siguranță comune (OSC) și pentru facilitarea monitorizării performanțelor de
 siguranță feroviară;</p><br>

<p>definirea unor norme comune pentru anchetele privind siguranța.</p><br>

<p style="font-weight:500;">Dezvoltarea și managementul siguranței</p><br>

<p style="text-indent:2em;">Normele și standardele de siguranță, precum normele de exploatare, normele de semnalizare, cerințele pentru personal și cerințele tehnice aplicabile materialului rulant, au fost concepute
 în principal la nivel național.</p><br>

<p style="text-indent:2em;">Aceste norme interne de siguranță ar trebui înlocuite treptat cu normele bazate pe standarde comune, stabilite prin specificații tehnice de interoperabilitate (STI) – capacitatea echipamentelor
 sau a grupurilor de a opera în mod coordonat unele cu altele. Comisia Europeană are competența de a suspenda punerea în aplicare a unei norme de siguranță interne timp de maximum șase luni.</p>

<p style="text-indent:2em;"></p><h6>În acest scop, țările UE trebuie să se asigure că:</h6><p></p>

<p>siguranța feroviară este în general menținută și îmbunătățită în mod continuu, ținând cont de dezvoltarea legislației UE;</p><br>

<p>normele de siguranță sunt stabilite, aplicate și respectate în mod deschis și nediscriminatoriu;</p><br>

<p style="text-indent:2em;">gestionarii de infrastructură și întreprinderile feroviare trebuie să își asume responsabilitatea exploatării sigure a sistemului feroviar și a controlului riscurilor asociate cu acesta;</p><br>

<p style="text-indent:2em;">se colectează informații privind indicatorii de siguranță comuni prin rapoarte anuale pentru a evalua atingerea ISC și a monitoriza dezvoltarea generală a siguranței feroviare.</p><br>

<p style="font-weight:500;">Certificarea de siguranță</p><br>

<p style="text-indent:2em;">Pentru a i se acorda acces la infrastructura feroviară, o întreprindere feroviară trebuie să dețină un certificat de siguranță. Acest certificat de siguranță poate acoperi întreaga rețea
 feroviară a unei țări din UE sau numai o parte definită a acesteia.</p><br>

<p style="text-indent:2em;">Pentru serviciile de transport internațional, ar trebui să fie suficient să se aprobe sistemul de management al siguranței într-o țară a UE și să se asigure valabilitatea aprobării în întreaga UE.</p><br>

<p>Pe de altă parte, respectarea normelor interne ar trebui certificată suplimentar în fiecare țară a UE.</p><br>

<p style="text-indent:2em;">Certificatul de siguranță trebuie reînnoit la cererea întreprinderii feroviare, la intervale care nu depășesc cinci ani. Acesta trebuie actualizat în întregime sau parțial la fiecare
 modificare substanțială a tipului sau extinderii operațiunii.</p><br>

<p style="text-indent:2em;">În plus față de cerințele de siguranță stabilite în certificat, întreprinderile feroviare licențiate trebuie să respecte cerințele interne, compatibile cu dreptul UE și aplicate într-un
 mod nediscriminatoriu, referitoare la sănătate, securitate și condiții sociale, inclusiv normele referitoare la timpul de conducere, precum și la drepturile lucrătorilor și consumatorilor.</p><br>

<p style="text-indent:2em;">Un aspect esențial al siguranței îl reprezintă formarea și certificarea personalului, în special a mecanicilor de tren. Formarea cuprinde normele de exploatare, sistemul de semnalizare,
 cunoașterea rutelor și a procedurilor de urgență.</p><br>

<p style="font-weight:500;">Întreținerea vehiculelor</p><br>

<p style="text-indent:2em;">Înainte de a fi introdus în circulație sau utilizat în rețea, fiecărui vehicul i se atribuie o entitate de întreținere (care poate fi, concret, o întreprindere feroviară sau un gestionar
 de infrastructură). Entitatea asigură starea de funcționare a vehiculelor introducând un sistem de întreținere în conformitate cu manualul de întreținere al vehiculului și cu cerințele
 de siguranță aplicabile.</p><br>

<p style="font-weight:500;">Autoritatea națională de siguranță</p>

<p style="text-indent:2em;"></p><h6>Fiecare țară a UE trebuie să instituie o autoritate de siguranță care să fie independentă de întreprinderile feroviare, de gestionarii de infrastructură, de solicitanții de certificat de
 siguranță și de entitățile achizitoare. Aceasta:</h6><p></p>

<p>va răspunde prompt la cereri și solicitări;</p><br>

<p>își va comunica cererile de informații fără întârziere; și</p><br>

<p>își va adopta toate deciziile în termen de patru luni după furnizarea tuturor informațiilor cerute.</p><br>

<p style="text-indent:2em;">Autoritatea de siguranță va desfășura toate inspecțiile și anchetele necesare pentru îndeplinirea sarcinilor sale și i se va acorda acces la toate documentele și incintele relevante, la
 instalațiile și echipamentele gestionarilor de infrastructură și ale întreprinderilor feroviare.</p><br>

<p style="font-weight:500;">Anchetarea accidentelor și a incidentelor</p><br>

<p style="text-indent:2em;">Accidentele feroviare grave, precum deraierile și coliziunile cu consecințe fatale, se produc rar, dar atunci când se produc, atrag interesul publicului și al profesioniștilor în materie
 de siguranță din întreaga Europă.</p><br>

<p style="text-indent:2em;">Criteriile de reglementare a independenței organismului de anchetă sunt definite strict, astfel încât acest organism să nu aibă nicio legătură cu diferiții actori din sector. Acest organism
 decide dacă ar trebui întreprinsă o anchetă a unui asemenea accident sau incident și determină amploarea anchetelor și procedura de urmat.</p><br>

<p style="text-indent:2em;">Fiecare țară a UE trebuie să se asigure că anchetele accidentelor și ale incidentelor sunt desfășurate de un organism permanent, care include cel puțin un anchetator capabil să îndeplinească
 funcția de anchetator principal în cazul unui accident sau incident.</p><br>

<p>Abrogarea Directivei 2004/49/CE</p><br>

<p style="text-indent:2em;">Directiva (UE) 2016/798 abrogă și înlocuiește Directiva 2004/49/CE începând cu data de 16 iunie 2020. Obiectivul noii directive este de a spori siguranța feroviară în întreaga UE prin revizuirea
 rolului autorităților naționale de siguranță (ANS) și realocarea responsabilităților între acestea și Agenția Uniunii Europene pentru Căile Ferate. Anexa V la Directiva 2004/49/CE trebuie să se
 aplice până la data aplicării actelor de punere în aplicare la care se face referire în articolul 24, alineatul (2) al Directivei (UE) 2016/798 (structura rapoartelor de investigație a accidentelor
 și a incidentelor).</p><br>

<p style="text-indent:2em;">Directiva (UE) 2016/798 este unul dintre cele trei acte legislative care vizează aspectele tehnice ale celui de al patrulea pachet feroviar, care are ca obiectiv să revitalizeze sectorul 
feroviar și să asigure o calitate sporită a serviciilor și un număr mai mare de opțiuni pentru călători. Aceasta funcționează alături de regulamentul privind Agenția Uniunii Europene pentru
 Căile Ferate și de directiva privind interoperabilitatea sistemului feroviar.</p><br>

<p style="font-weight:500;">DE CÂND SE APLICĂ DIRECTIVA?</p><br>

<p style="text-indent:2em;">Directiva se aplică din 30 aprilie 2004. Țările UE au avut obligația de a o transpune în legislația națională până la 30 aprilie 2006.</p><br>

<p style="font-weight:500;">CONTEXT</p>

<p style="text-indent:2em;"></p><h6>Pentru informații suplimentare, consultați:</h6><p></p>

<p>Căile ferate – siguranța (Comisia Europeană).</p><br>

<p style="font-weight:500;">TERMENI-CHEIE</p><br>

<p style="text-indent:2em;">Întreprinderi feroviare companii publice sau private implicate în furnizarea de servicii de transport feroviar de bunuri și/sau pasageri.</p><br>

<p style="text-indent:2em;">Gestionari de infrastructură: organisme sau companii responsabile în special de instituirea și întreținerea infrastructurii sau a unei părți a acesteia, precum și de siguranță. În unele țări 
ale UE, siguranța poate fi delegată întreprinderilor feroviare.</p><br>

<p style="font-weight:500;">DOCUMENTUL PRINCIPAL</p><br>

<p style="text-indent:2em;">Directiva 2004/49/CE a Parlamentului European și a Consiliului din 29 aprilie 2004 privind siguranța căilor ferate comunitare și de modificare a Directivei 95/18/CE a Consiliului privind 
acordarea de licențe întreprinderilor feroviare și a Directivei 2001/14/CE privind repartizarea capacităților de infrastructură feroviară și perceperea de tarife pentru utilizarea 
infrastructurii feroviare și certificarea siguranței (Directiva privind siguranța feroviară) (JO L 164, 30.4.2004, pp. 44-113)</p><br>

<p style="text-indent:2em;">Modificările succesive aduse Directivei 2004/49/CE au fost integrate în textul de bază. Această versiune consolidată are doar un caracter informativ.</p><br>

<p style="font-weight:500;">DOCUMENTE CONEXE</p><br>

<p style="text-indent:2em;">Regulamentul (UE) 2016/796 al Parlamentului European și al Consiliului din 11 mai 2016 privind Agenția Uniunii Europene pentru Căile Ferate și de abrogare a Regulamentului (CE) nr. 
881/2004 (JO L 138, 26.5.2016, pp. 1-43)</p><br>

<p style="text-indent:2em;">Directiva (UE) 2016/797 a Parlamentului European și a Consiliului din 11 mai 2016 privind interoperabilitatea sistemului feroviar în Uniunea Europeană (JO L 138, 26.5.2016, pp. 44-101)</p><br>

<p style="text-indent:2em;">Directiva (UE) 2016/798 a Parlamentului European și a Consiliului din 11 mai 2016 privind siguranța feroviară (JO L 138, 26.5.2016, pp. 102-149)</p><br>

<p style="text-indent:2em;">Directiva 2008/68/CE a Parlamentului European și a Consiliului din 24 septembrie 2008 privind transportul interior de mărfuri periculoase (JO L 260, 30.9.2008, pp. 13-59)</p><br>

<p>A se vedea versiunea consolidată</p><br>

<p style="text-indent:2em;">Directiva 2008/57/CE a Parlamentului European și a Consiliului din 17 iunie 2008 privind interoperabilitatea sistemului feroviar în Comunitate (reformare) (JO L 191,
 18.7.2008, pp. 1-45)</p><br>

<p>A se vedea versiunea consolidată</p><br>

<p style="text-indent:2em;">Regulamentul (CE) nr. 1371/2007 al Parlamentului European și al Consiliului din 23 octombrie 2007 privind drepturile și obligațiile călătorilor din transportul feroviar (JO L 315,
 3.12.2007, pp. 14-41)</p><br>

<p style="text-indent:2em;">Directiva 2007/59/CE a Parlamentului European și a Consiliului din 23 octombrie 2007 privind certificarea mecanicilor de locomotivă care conduc locomotive și trenuri în sistemul
 feroviar comunitar (JO L 315, 3.12.2007, pp. 51-78)</p><br>

<p>A se vedea versiunea consolidată</p><br>

<p style="text-indent:2em;">Regulamentul (CE) nr. 653/2007 al Comisiei din 13 iunie 2007 privind utilizarea unui format european comun pentru certificatele de siguranță și documentele de cerere în conformitate
 cu articolul 10 din Directiva 2004/49/CE a Parlamentului European și a Consiliului și valabilitatea certificatelor de siguranță prevăzute de Directiva 2001/14/CE a Parlamentului
 European și a Consiliului (JO L 153, 14.6.2007, pp. 9-24)</p><br>

<p>A se vedea versiunea consolidată.</p><br>

<p style="text-indent:2em;">Directiva 2003/88/CE a Parlamentului European și a Consiliului din 4 noiembrie 2003 privind anumite aspecte ale organizării timpului de lucru (JO L 299, 18.11.2003, pp. 9-19)</p><br>

<p><a href="https://eur-lex.europa.eu/summary/chapter/transport/3203.html?root=3203">Sursa</a></p>



</div>
`