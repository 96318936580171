import Component from './component'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form'
import withTranslation from '../../../../globals/components/withTranslation'
import withPermissions from '../../../../globals/components/withPermissions'
import fetch from '../../../shared/fetch'

const FORM_NAME = 'employee-edit'
const formSelector = formValueSelector(FORM_NAME)

export default compose(
  withTranslation,
  withPermissions,
  withRouter,
  connect((state) => {
    const {
      auth: { profile },
    } = state

    return {
      profile: profile.get('data').toJS(),
      loggedUserId: state.auth.jwt.userId,
      selectedPositionInCompany: formSelector(state, 'employee.positionInCompany'),
      selectedEmail: formSelector(state, "email"),
      selectedPhone1: formSelector(state, "phone1"),
      selectedPhone2: formSelector(state, "phone2"),
      selectedSkype: formSelector(state, "skype"),
      selectedViber: formSelector(state, "viber"),
    }
  }),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    forceUnregisterOnUnmount: false,
  })
)(Component)
