import { connect } from 'react-redux'
import { compose } from 'recompose'
import _ from 'lodash'

import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'
import { reduxForm, formValueSelector } from 'redux-form'

import { getProfile } from '../../../store/actions/profile'
import { setCompanyVerivicationData } from '../../../store/actions'
import Component from './component'
import { IReduxStorage } from '../../../store/reducers/types'
import { ProfileMiniBaseProps } from './types'

const formName = 'company-confirmation'
const formSelector = formValueSelector(formName)

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state: IReduxStorage) => {
      return {
        profile: state.profile.profile,
        companyVerifiedInfo: state.company.companyVerifiedInfo,
        constituentDocumentProps: formSelector(state, 'constituentDocumentProps'),
        companyLicensesNameFileOneProps: formSelector(state, 'companyLicensesNameFileOneProps'),
        companyLicensesNameFileTwoProps: formSelector(state, 'companyLicensesNameFileTwoProps'),
        companyLicensesNameFileThreeProps: formSelector(state, 'companyLicensesNameFileThreeProps'),
        selfieProps: formSelector(state, 'selfieProps'),
      }
    },
    {
      getProfile,
      setCompanyVerivicationData,
    }
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })
)(Component) as React.ComponentClass<ProfileMiniBaseProps>
