import { connect } from 'react-redux'
import { compose } from 'recompose'

import Component from './component'

import withTranslation from '../../../../globals/components/withTranslation'

export default compose(
  withTranslation,
  connect(state => {
    return {
      activeGuid: state.ui.activePopupGuid,
    }
  })
)(Component)
