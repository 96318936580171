import { connect } from 'react-redux'
import { compose } from 'recompose'
import { injectIntl, defineMessages } from 'react-intl'

export default compose(
  injectIntl,
  connect((state, props) => {
    const { baseMessages, langId, currentLanguage } = state.ui
    const messages = defineMessages(baseMessages)
    const {
      intl: { formatMessage },
    } = props
    return {
      langId,
      currentLanguage,
      t: (tag, data) => {
        let msg
        try {
          msg = (messages && tag && messages[tag] && formatMessage(messages[tag], data)) || '?'
        } catch (e) {
          console.log('ERROR', e.message)
          msg = '?'
        }
        return msg
      },
    }
  })
)