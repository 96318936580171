import React, { Fragment } from 'react'

import SearchForm from '../../search/form'
import SearchResults from '../../search/results'
import Banner from '../../banner'
import { BANNER_THIN_SIZE } from '../../../store/constants'

import './style.scss'

export default () => (
  <Fragment>
    <div>
      <SearchForm searchType="transport" />
    </div>
    <div className="banner-row" id="bannerRow">
      <Banner bannerId={530} />
      <div className="mobile-banner-on">
        <Banner bannerId={531} />
      </div>
    </div>
    <div className="row row-right">
      <div className="col-md-16">
        <SearchResults
          searchType="transport"
          bannerId={532}
          showDateIsShort
          needToShowAdsNearPagination
        />
      </div>
    </div>
  </Fragment>
)
