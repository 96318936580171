import React, { Component } from 'react'
import pick from 'lodash/pick'
import classNames from 'classnames'

import fetch from '../../../shared/fetch'
import { getClientName } from '../../../shared/utils/browser'
import Widget from '../../../shared/components/widget'
import {
  LOGIN_SUCCESSFULLY_LOGGED_IN,
  PROFILE_MODE_REGISTRATION,
} from '../../../../store/constants'
import store from '../../../../store'
import ProfileForm from '../../../user/profile-form'

import './profile.scss'

const STYLE_BASE = 'profile_'

export default class extends Component {
  state = {
    loading: true,
    token: this.props.match.params.token,
    userId: undefined,
    username: '',
    email: '',
    message: '',
  }

  componentDidMount() {
    const { token } = this.props.match.params
    const { loginSuccess } = this.props

    fetch('/auth/registrationCheckToken', {
      method: 'POST',
      body: JSON.stringify({
        token,
        lang: this.props.currentLanguage,
        clientName: getClientName(),
        socketId: window.socket.id,
      }),
    })
    .then(json => {
      if (json.ok) {
        this.setState({ loading: false, token, ...pick(json, ['userId', 'username', 'email']) })
      }
    })
    .catch(err => {
      let { response: { data: { error } = {} } = {} } = err
      error = error || err.message
      this.setState({ message: error, loading: false })
    })
  }

  onSaved = () => {
    this.props.history.push('/');
  }

  render() {
    const { loading, message } = this.state
    const { t } = this.props

    return (
      <Widget
        title={t('login.title.create.profile')}
        className={classNames(`${STYLE_BASE}wrapper`)}
      >
        {loading ? (
          <div className={classNames(`${STYLE_BASE}info`)}>{`${t('login.checking.token')}...`}</div>
        ) : message ? (
          <div className={classNames(`${STYLE_BASE}info`)}>{message}</div>
        ) : (
          <ProfileForm
            mode={PROFILE_MODE_REGISTRATION}
            onSaved={this.onSaved}
            {...pick(this.state, ['userId', 'username', 'email', 'token'])}
          />
        )}
      </Widget>
    )
  }
}
