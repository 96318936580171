import { MODE_TIME_ELAPSED } from '../../../store/constants'

export function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  }
}
export function describeArc({ radius, mode = MODE_TIME_ELAPSED }, pc) {
  if (pc == null || isNaN(pc)) pc = 0
  if (pc > 100) pc = 100
  if (pc < 0) pc = 0

  const x = 0,
    y = 0,
    startAngle = mode === MODE_TIME_ELAPSED ? 0 : 360 - (pc * 360) / 100
    let endAngle = mode === MODE_TIME_ELAPSED ? (pc * 360) / 100 : 360

  if (endAngle === 360) {
    endAngle = endAngle - 0.1
  }

  const start = polarToCartesian(x, y, radius, endAngle),
    end = polarToCartesian(x, y, radius, startAngle)

  const arcSweep = endAngle - startAngle <= 180 ? '0' : '1'

  return ['M', start.x, start.y, 'A', radius, radius, 0, arcSweep, 0, end.x, end.y].join(' ')
}
