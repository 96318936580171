import { reducer as formReducer } from 'redux-form'
import { combineReducers } from 'redux'

import { reducer as toastrReducer } from 'react-redux-toastr'

import { AUTH_LOGOUT } from '../actions/action-types'

import access from './access'
import auth from './auth'
import cargo from './cargo'
import ads from './ads'
import company from './company'
import headerTop from './header-top'
import idle from '../../idle/IdleRedux'
import modal from './modal'
import modalForm from './modal-form'
import news from './news'
import socket from './socket'
import summary from './summary'
import transport from './transport'
import ui from './ui.reducer'
import profile from './profile'

import htmlTemplate from './html-template'
import { IReduxStorage } from './types'

function createNamedWrapperReducer(reducerFunction, reducerName) {
  return (state, action) => {
    const { name } = action
    const isInitializationCall = state === undefined
    // we will propagate the action to state only when the name matchs or name is ALL
    // which is broadcast
    if (name !== reducerName && name !== 'ALL' && !isInitializationCall) return state

    return reducerFunction(state, action)
  }
}
const appReducer = combineReducers({
  access,
  auth,
  ads,
  cargo,
  company,
  form: formReducer,
  headerTop,
  idle,
  legislation: createNamedWrapperReducer(htmlTemplate, 'LEGISLATION'),
  modal,
  modalForm,
  news,
  socket,
  toastr: toastrReducer,
  summary,
  transport,
  ui,
  profile,
})

const initialState: IReduxStorage = {
  access: undefined,
  auth: undefined,
  ads: undefined,
  cargo: undefined,
  company: undefined,
  form: undefined,
  headerTop: undefined,
  idle: undefined,
  modal: undefined,
  modalForm: undefined,
  news: undefined,
  socket: undefined,
  summary: undefined,
  transport: undefined,
  ui: undefined,
  toastr: undefined,
  legislation: undefined,
  profile: undefined,
  userReviewQuota: undefined
}

export default function rootReducer(state = initialState, action: any = {}) {
  let nextState = state
  // on logout, empty state
  if (action.type === AUTH_LOGOUT) {
    // nextState = initialState
    nextState = { ...initialState, ui: state.ui }
  }

  return appReducer(nextState, action)
}
