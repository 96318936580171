import React, { Component } from 'react'
import classNames from 'classnames'
import FineUploaderTraditional from 'fine-uploader-wrappers'
import Icon from 'react-icons-kit'
import { timesCircle as deleteIcon } from 'react-icons-kit/fa/timesCircle'
import { upload as uploadIcon } from 'react-icons-kit/fa/upload'
import { Field } from 'redux-form'

import Editor from '../../../../UI/editor'
import config from '../../../../../config'
import { required } from '../../../../shared/form-validators'
import Dropdown from '../../../../UI/dropdown'
import SelectField from '../../../../UI/select'
import Checkbox from '../../../../UI/checkbox'
import FileUploader from '../../../../UI/file-uploader'
import dict from '../../../../shared/dictionaries'
import { focusFirstInput } from '../../../../shared/utility'

import './styles.scss'

const dictPriority = [
  { name: '1', value: '1' },
  { name: '2', value: '2' },
  { name: '3', value: '3' },
  { name: '4', value: '4' },
  { name: '5', value: '5' },
]

const STYLE_BASE = 'news_item_add-edit_'
const MAX_LENGTH_TITLE = 190
const MAX_LENGTH_SHORT = 375

const uploader = new FineUploaderTraditional({
  options: {
    core: {
      multiple: false,
    },
    cors: {
      expected: true,
      sendCredentials: true,
    },
    deleteFile: {
      enabled: true,
      endpoint: `${config.apiUrl}/common/upload`,
    },
    request: {
      endpoint: `${config.apiUrl}/common/upload`,
    },
    validation: {
      allowedExtensions: ['jpeg', 'jpg', 'png', 'gif'],
    },
  },
})

export default class extends Component {
  constructor(props) {
    super(props)

    this.state = {
      files: [],
    }

    this.ref = React.createRef()
  }

  componentDidMount() {
    uploader.on('statusChange', (id, oldStatus, newStatus) => {
      const { initialValues } = this.props
      const { files: oldFiles } = this.state
      const files = [...oldFiles]
      if (newStatus === 'submitted') {
        const item = {
          id,
          name: uploader.methods.getName(id),
          size: uploader.methods.getSize(id),
          uuid: uploader.methods.getUuid(id),
        }
        this.setState({ files: [item] })
      } else if (['canceled', 'deleted'].includes(newStatus)) {
        const indexToRemove = files.findIndex(f => f.id === id)
        files.splice(indexToRemove, 1)
        this.setState({ files })
      }
      initialValues.files = files
    })

    focusFirstInput(this.ref)
  }

  componentWillUnmount() {
    uploader.off('statusChange')
  }

  render() {
    const {
      t,
      langId,
      pristine,
      submitting,
      invalid,
      handleSubmit,
      onClose,
      miniatureImage,
      change,
      body,
      isNew,
      imageFolder,
    } = this.props

    const categories = dict.filterOptionsForNews(langId)
    const languages = dict.languages()

    return (
      <form className={classNames('panel-form panel-form-simple', `${STYLE_BASE}wrapper`)}>
        <div className="news-add-edit" ref={this.ref}>
          <div className="f-lex-row" style={{ display: 'flex' }}>
            <div className="grow">
              <div className="form-row flex-row">
                <div className="form-group grow">
                  <label className="label-1 required-field">{`${t('title')}:`}</label>
                  <Field
                    name="title"
                    component="input"
                    type="text"
                    className="form-control g-row"
                    maxLength={MAX_LENGTH_TITLE}
                    placeholder="Введите название новости"
                    validate={required}
                  />
                </div>
              </div>
              <div className="form-row flex-row">
                <div className="form-group grow">
                  <label className="label-1 required-field">{`${t('news.category')}:`}</label>
                  <Field
                    name="category"
                    component={Dropdown}
                    items={categories}
                    className="max-250"
                    validate={[required]}
                  />
                </div>
                <div className="form-group">
                  <label className="required-field">{`${t('language')}:`}</label>
                  <Field
                    name="langIds"
                    component={SelectField}
                    items={languages}
                    placeholder={`${t('choose')}...`}
                    multi
                    showOnlySiteLanguages
                    className={classNames({
                      [`${STYLE_BASE}langs`]: true,
                    })}
                    tooltip="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
                    validate={required}
                  />
                </div>
              </div>

              <div className="form-row flex-row">
                <div className="form-group grow">
                  <label className="required-field label-1">
                    {t('news.short.description') + ':'}
                  </label>
                  <Field
                    name="short"
                    component="textarea"
                    placeholder="Не более 1000 символов"
                    className="textarea-resize"
                    maxLength={MAX_LENGTH_SHORT}
                    style={{ height: '50px' }}
                    validate={[required]}
                  />
                </div>
              </div>

              <div className="form-row flex-row">
                <div className="form-group grow">
                  <label className="label-1">{`${t('video')}:`}</label>
                  <Field
                    name="video"
                    component="input"
                    type="text"
                    className="form-control g-row"
                    placeholder="http://"
                  />
                </div>
              </div>
            </div>
            <div className="right-side">
              <div className="form-row flex-row">
                <div className="form-group">
                  <label className="required-field">{`${t('news.priority')}:`}</label>
                  <Field
                    name="priority"
                    component={Dropdown}
                    items={dictPriority}
                    validate={[required]}
                  />
                </div>
              </div>
              <div className="image-container">
                <div className={classNames('image-wrapper', `${STYLE_BASE}image-wrapper`)}>
                  <div className={classNames('img-container', `${STYLE_BASE}img-container`)}>
                    {miniatureImage && (
                      <img
                        src={`${config.apiUrl}/common/file/${imageFolder}/${miniatureImage}`}
                        alt="image"
                      />
                    )}
                  </div>
                  <div className={classNames(`${STYLE_BASE}input-file-custom`)}>
                    <Field
                      name="image"
                      component={FileUploader}
                      className={classNames({
                        'input-file': true,
                        [`${STYLE_BASE}click-wrapper`]: true,
                        [`${STYLE_BASE}click-centered`]: !miniatureImage,
                      })}
                      folder={imageFolder}
                    >
                      {!miniatureImage && (
                        <div className={`${STYLE_BASE}click-label-wrapper`}>
                          <Icon icon={uploadIcon} size={24} />
                        </div>
                      )}
                    </Field>
                  </div>
                  {miniatureImage && (
                    <Icon
                      icon={deleteIcon}
                      size={12}
                      onClick={() => {
                        change('image', null)
                      }}
                      className={`${STYLE_BASE}button-delete`}
                    />
                  )}
                </div>
              </div>
              <div className="form-row flex-row fld-show">
                <div className="form-group">
                  <label htmlFor="exampleInputName2">{t('show.in')}:</label>
                  <Field name="mini" component={Checkbox} />
                </div>
              </div>
            </div>
          </div>

          <div className="form-row flex-row">
            <div className="form-group grow">
              {(isNew || body !== undefined) && (
                <Field
                  name="body"
                  component={Editor}
                  scope="news"
                  uploadFolder={imageFolder}
                  imageFolder={imageFolder}
                />
              )}
            </div>
          </div>

          <div className="form-row flex-row">
            <div className={`${STYLE_BASE}buttons-info`}>
              <span className="asterix">*</span>
              {` `}
              {t('validation.required')}
            </div>
            <div className="buttons">
              <button
                type="submit"
                disabled={pristine || submitting || invalid}
                onClick={handleSubmit}
                className="button button-orange"
              >
                {t('button.save')}
              </button>
              <button type="button" className="btn btn-default" onClick={onClose}>
                {t('button.close')}
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  }
}
