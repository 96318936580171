import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'

import withTranslation from '../../../globals/components/withTranslation'
import withPersmission from '../../../globals/components/withPermissions'
import { getProfile } from '../../../store/actions/user'
import { setCompanyVerivicationData } from '../../../store/actions'

import Component from './component'

export default compose(
  withTranslation,
  withPersmission,
  withRouter,
  connect(null, { getProfile, setCompanyVerivicationData })
)(Component)
