export const eu4 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Intermodalitate şi reţele transeuropene</h4><p></p>

<p></p><h4>Intermodalitate</h4><p></p>

<p></p><h4>Programul Marco Polo II</h4><p></p>

<p style="text-indent:2em;">Transportul rutier de marfă depinde în mare măsură de combustibilii fosili, care contribuie semnificativ la producţia de CO2. El este, de asemenea, afectat de problema
 congestionării infrastructurilor. Pe acest fundal, utilizarea intermodalităţii la o scară mai largă este necesară pentru a ajuta la realizarea unei utilizări îmbunătăţite 
 a resurselor existente, prin încorporarea transportului maritim pe distanţe mici şi a transportului feroviar şi fluvial în lanţul logistic. Programul Marco Polo II (2007-2013)
 vizează transferarea transportului de mărfuri de pe şosea spre alte mijloace, mai ecologice.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Regulamentul (CE) nr. 1692/2006 al Parlamentului European şi al Consiliului din 24 octombrie 2006 de stabilire a celui de-al doilea program Marco Polo de acordare a asistenţei 
financiare comunitare pentru îmbunătăţirea performanţelor de mediu ale sistemului de transport de mărfuri (Marco Polo II) şi de abrogare a Regulamentului (CE) nr.1382/2003 
[A se vedea actul de modificare].</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Programul Marco Polo II are aceleaşi obiective ca primul program Marco Polo. Este conceput pentru a reduce congestia şi pentru a îmbunătăţi performanţa de mediu a sistemului
de transport intermodal, contribuind astfel la un sistem de transport durabil şi eficient, care oferă valoare adăugată Uniunii Europene (UE), fără a avea un impact negativ 
asupra coeziunii economice, sociale sau teritoriale. Cu toate acestea, Marco Polo cuprinde şi dispoziţii noi.</p><br>

<p style="text-indent:2em;">Programul Marco Polo II propune o acoperire geografică mai mare. El se aplică acţiunilor care implică teritoriul a cel puţin două ţări UE sau teritoriul a cel puţin o ţară UE
şi al unei ţări terţe apropiate.</p>

<p style="text-indent:2em;"></p><h6>Acţiunile eligibile sunt următoarele:</h6><p></p>

<p style="text-indent:2em;">acţiuni cu efect catalizator: acţiunile menite să depăşească barierele structurale semnificative pe piaţa comunitară a transportului de mărfuri care împiedică funcţionarea eficientă
a pieţelor, competitivitatea transportului maritim pe distanţe mici, a celui feroviar sau pe căile navigabile interioare şi/sau eficienţa lanţurilor de transport care utilizează 
respectivele moduri de transport. Acestea au ca scop îmbunătăţirea sinergiilor în sectoarele transportului feroviar, pe căile navigabile interioare şi maritim pe distanţe mici, 
inclusiv pe autostrăzile maritime, printr-o mai bună utilizare a infrastructurii existente;</p><br>

<p style="text-indent:2em;">acţiuni de transfer modal: acţiunile al căror scop este transferarea unei încărcături dinspre transportul rutier către transportul maritim pe distanţe mici, transportul feroviar sau 
navigaţia internă sau o combinaţie între moduri de transport. Obiectivul este de a menţine parcursurile rutiere cât mai scurte posibil;</p><br>

<p style="text-indent:2em;">acţiunile de învăţare în comun: acţiunile menite să îmbunătăţească cooperarea pentru a optimiza, din punct de vedere structural, metodele de lucru şi procedurile în lanţul de transport 
de mărfuri, ţinând seama de cerinţele de logistică;</p><br>

<p style="text-indent:2em;">autostrăzile maritime: o idee introdusă prin Cartea albă din 2001 privind politica transporturilor europene. Autostrăzile maritime au ca scop transferarea directă a unei părţi a 
încărcăturii dinspre transportul rutier către transportul maritim pe distanţe mici sau o combinaţie între transportul maritim pe distanţe mici şi alte moduri de transport, în care 
parcursurile rutiere sunt cât mai scurte posibil. De exemplu, s-ar putea pune în aplicare autostrăzi maritime între Franţa şi Spania, pentru a elimina ambuteiajele rutiere din Pirinei;</p><br>

<p style="text-indent:2em;">acţiuni de evitare a traficului: orice acţiune inovatoare care integrează transportul în logistica de producţie a întreprinderilor pentru a evita un procentaj ridicat al transportului 
rutier de mărfuri, fără a avea efecte secundare asupra producţiei sau mâinii de lucru.</p><br>

<p style="text-indent:2em;">De asemenea, Comisia examinează posibilitatea sprijinirii creării sau modificării infrastructurii auxiliare, care este necesară şi adecvată pentru realizarea cu succes a proiectelor.</p><br>

<p style="text-indent:2em;">Acţiunile trebuie prezentate de întreprinderi sau de consorţii stabilite în ţări UE sau în ţări participante, inclusiv ţările candidate la aderarea la UE, ţările AELS, SEE şi ţările 
terţe apropiate, sub rezerva îndeplinirii anumitor condiţii.</p><br>

<p style="text-indent:2em;">Întreprinderile stabilite în afara ţărilor participante menţionate mai sus pot fi asociate la un proiect, dar nu pot să beneficieze de finanţare europeană în cadrul programului. 
Ajutorul acordat pentru demararea acţiunilor trebuie să fie transparent, obiectiv şi clar delimitat. Asistenţa financiară comunitară se bazează pe numărul de tone-kilometri transferate 
dinspre transportul rutier spre alte moduri de transport, maritim sau pe uscat, sau pe numărul de vehicul-kilometri de trafic rutier evitat. Obiectivul este de a recompensa proiectele de 
înaltă calitate. În timpul procedurii de selecţie trebuie să se evite denaturările concurenţei.</p><br>

<p style="text-indent:2em;">Programul acordă o atenţie specială zonelor sensibile şi metropolitane. Comisia evaluează acţiunile prezentate, ţinând seama de contribuţia lor la reducerea ambuteiajelor rutiere, dar 
şi de meritele lor ecologice relative şi de durabilitatea lor generală.</p><br> 

<p style="text-indent:2em;">Ajutorul financiar din partea UE pentru diferitele acţiuni este limitat la maximum 35 % din cheltuielile totale necesare atingerii obiectivelor acţiunii şi survenite ca rezultat al 
acesteia. În cazul acţiunilor de învăţare în comun, plafonul este fixat la 50 %. Programul Marco Polo II are o sferă de aplicare mai largă decât predecesorul său şi un buget mai mare, 
de 400 de milioane EUR.</p><br>

<p style="text-indent:2em;">Comisia a trebuit să prezinte un raport de evaluare privind rezultatele programului Marco Polo I pentru perioada 2003-2006 până la 30 iunie 2007.</p><br>

<p style="font-weight:500;">Context</p><br>

<p style="text-indent:2em;">Programul Marco Polo II, care acoperă perioada 2007-2013, este o versiune extinsă a programului iniţial creat în 2003, la care s-au făcut înscrieri în exces şi care a fost finanţat în mod 
inadecvat: în cadrul celor patru proceduri de selecţie pentru primul program Marco Polo, Comisia a primit propuneri pentru un nivel de asistenţă de 468 milioane de euro, dar dispunea de un 
buget de numai 100 de milioane de euro.</p><br>

<p style="font-weight:500;">Infrastructură şi tarifare</p><br>

<p style="font-weight:500;">Protocolul privind transporturile conform Convenției alpine</p><br>

<p style="text-indent:2em;">Convenția alpină este un acord-cadru în vederea protecției și dezvoltării durabile a regiunii alpine și vizează conservarea și protejarea masivului (din perspectivă de mediu, economică și 
socială), prin aplicarea principiilor prevenirii, poluatorul plătește și al cooperării între statele membre. Textul regrupează nouă protocoale, fiecare acoperind un sector diferit. Prezenta 
decizie vizează aplicarea Protocolului privind aplicarea Convenției alpine în domeniul transporturilor de către Uniunea Europeană.</p><br>

<p style="font-weight:500;">ACT</p><br>

<p style="text-indent:2em;">Decizia 2007/799/CE a Consiliului din 12 octombrie 2006 privind semnarea, în numele Comunității Europene, a Protocolului privind aplicarea Convenției alpine în domeniul transporturilor 
(Protocolul privind transporturile).</p><br>

<p style="font-weight:500;">SINTEZĂ</p><br>

<p style="text-indent:2em;">Prin Decizia 96/191/CE a Consiliului, Uniunea Europeană (UE) a încheiat Convenția privind protecția Alpilor. Aceasta vizează salvgardarea ecosistemului natural și promovarea dezvoltării 
durabile a Alpilor, promovând totodată interesele economice și culturale ale populațiilor. Convenția alpină dispune de un comitet permanent, alcătuit din reprezentanți ai părților 
contractante.</p><br>

<p style="text-indent:2em;">Semnarea acestui protocol de către UE are, de asemenea, scopul de a transmite un semnal politic tuturor părților implicate, invitând părțile contractante să îl semneze și să îl ratifice.</p><br>

<p style="font-weight:500;">Obiective</p>

<p style="text-indent:2em;"></p><h6>Obiectivele protocolului sunt următoarele:</h6><p></p>

<p>reducerea riscurilor și a pericolelor proprii transportului intraalpin;</p><br>

<p style="text-indent:2em;">contribuirea la dezvoltarea habitatelor și a spațiilor economice grație unei politici a transporturilor armonizată între diferitele țări și care integrează diferitele moduri de transport 
(rutier, feroviar etc.);</p><br>

<p style="text-indent:2em;">contribuirea la reducerea pericolului asupra biodiversității spațiului alpin, precum și asupra patrimoniului natural și cultural al acestuia;</p><br>

<p style="text-indent:2em;">asigurarea circulației la costuri suportabile din punct de vedere economic, printr-o creștere a eficacității și a durabilității sistemelor de transport.</p>

<p style="text-indent:2em;"></p><h6>Părțile contractante ale Convenției se angajează, astfel, să ia în considerare, în administrarea teritoriului alpin, riscurile și pericolele asociate circulației, cum ar fi poluarea (fonică 
sau chimică) și siguranța persoanelor și a bunurilor. În paralel, semnatarii trebuie:</h6><p></p>

<p>să sporească rentabilitatea sectorului transporturilor;</p><br>

<p>să optimizeze utilizarea infrastructurilor existente;</p><br>

<p>să ia în considerare problematica transporturilor în evaluarea și în punerea în aplicare a altor politici;</p><br>

<p>să implice colectivitățile teritoriale în luarea deciziei.</p><br>

<p style="font-weight:500;">Măsuri și strategii specifice</p>

<p style="text-indent:2em;"></p><h6>Convenția alpină definește, de asemenea, un anumit număr de măsuri și strategii specifice. Toate vizează promovarea unei administrări raționale și sigure a transporturilor, 
grație principiilor următoare:</h6><p></p>

<p>o bună coordonare a diferitelor moduri și mijloace de transport;</p><br>

<p>utilizarea intermodalității;</p><br>

<p>transferul traficului către modurile de transport mai nepoluante;</p><br>

<p>protecția căilor de comunicație împotriva riscurilor naturale;</p><br>

<p>protecția persoanelor și a mediului înconjurător;</p><br>

<p>reducerea progresivă a emisiilor de substanțe nocive și a emisiilor fonice;</p><br>

<p>crearea și dezvoltarea unor transporturi publice ușor de utilizat și adaptate mediului înconjurător;</p><br>

<p>utilizarea studiilor de impact pentru proiectele planificate și consultarea părților interesate.</p>

<p style="text-indent:2em;"></p><h6>Convenția definește, în plus, principii particulare pentru 
diferitele moduri de transport:</h6><p></p>

<p style="text-indent:2em;">o consolidare a transportului feroviar prin îmbunătățirea infrastructurilor, optimizarea exploatării acestora, precum și utilizarea intermodalității pentru transportul de mărfuri;</p><br>

<p>o utilizare sporită a capacităților de navigație fluvială și maritimă;</p><br>

<p>în domeniul transportului rutier, semnatarii trebuie să evite construcția de noi drumuri de mare capacitate.</p>

<p style="text-indent:2em;"></p><h6>Proiectele pot fi totuși realizate în condițiile minimalizării impactului asupra mediului înconjurător:</h6><p></p>

<p style="text-indent:2em;">efectele dăunătoare ale transportului aerian trebuie să fie reduse în măsura posibilului. Activitățile aeriene recreative nemotorizate trebuie, de asemenea, să fie limitate.</p><br>

<p style="font-weight:500;">Principiul poluatorul plătește</p><br>

<p style="text-indent:2em;">Textul invită părțile contractante în egală măsură să aplice principiul poluatorul plătește, precum și să stabilească un sistem de calcul care să permită integrarea costurilor de 
mediu în evaluarea costului global al infrastructurilor.</p><br>

<p style="text-indent:2em;">Semnatarii trebuie să raporteze în mod regulat Comitetului permanent cu privire la măsurile luate în temeiul protocolului. Comitetul întocmește un raport de constatare a 
respectării protocolului.</p><br>

<p style="font-weight:500;">Context</p><br>

<p style="text-indent:2em;">Pe 14 mai 1991, Consiliul a autorizat Comisia să ia parte la negocierile pentru stabilirea Convenției alpine și a protocoalelor acesteia.</p><br>

<p style="text-indent:2em;">Protocolul privind transporturile a fost deschis în vederea semnării de către părțile contractante cu ocazia reuniunii ministeriale a Convenției alpine desfășurate la Lucerna 
în zilele de 30-31 octombrie 2000.</p><br>

<p style="text-indent:2em;">Consiliul a semnat Protocolul privind transporturile al Convenției alpine pe 12 decembrie 2006. Această decizie a fost însoțită de o declarație comună a Consiliului și a 
Comisiei privind interpretarea Protocolului privind transporturile.</p><br>

<p style="text-indent:2em;">În plus față de Uniunea Europeană, părțile contractante ale Convenției alpine sunt Germania, Franța, Italia, Liechtenstein, Monaco, Austria, Elveția și Slovenia.</p><br>

<p><a href="https://eur-lex.europa.eu/summary/chapter/transport/3207.html?root=3207">Sursa</a></p>



</div>
`