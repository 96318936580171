export const int15 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Словарь профессиональной терминологии, относящейся к процессам транспортирования грузов</h4><p></p>

<p style="font-weight:500;">АБАНДОН</p><br>

<p style="text-indent:2em;">в морском страховании отказ страхователя от своих прав на застрахованное имущество (судно, груз)
 в пользу страховщика с целью получения от него полной страховой суммы.</p><br>

<p style="font-weight:500;">АВАРИЙНАЯ ОГОВОРКА</p><br>

<p>оговорка о франшизе.</p><br>

<p style="font-weight:500;">АВАРИЙНАЯ ПОДПИСКА</p><br>

<p style="text-indent:2em;">обязательство (письменное заявление) грузополучателя оплатить причитающуюся с него
 долю общей аварии согласно диспаши.</p><br>
 
<p style="font-weight:500;">АВАРИЙНЫЙ КОМИССАР</p><br>

<p style="text-indent:2em;">лицо или фирма, занимающиеся установлением причин. Характера и размера убытка по
 застрахованным транспортным средствам и грузам.</p><br>
 
<p style="font-weight:500;">АВАРИЙНЫЙ СЕРТИФИКАТ</p><br>

<p style="text-indent:2em;">документ, составляемый аварийным комиссаром (сюрвейером), уполномочиваемым
 заранее страховщиком, который содержит описание причин и размера убытка при 
 любом страховом случае, а также другие сведения, позволяющие судить о наличии
 ответственности страховщика и права страхователя на получение страхового возмещения. 
 Аварийный сертификат служит только свидетельством убытка и не предрешает его 
 обязательную оплату страховщиком.</p><br>
 
<p style="font-weight:500;">АДЖАСТЕР</p><br>

<p style="text-indent:2em;">диспашер, специалист в области составления диспаш-расчетов по распределению
 убытков между судном, грузом и фрахтом в связи с общей аварией.</p><br>
 
<p style="font-weight:500;">АКТ О СУБРОГАЦИИ</p><br>

<p style="text-indent:2em;">документ о передаче страхователем страховщику после получения от него суммы
 страхового возмещения прав на взыскание ущерба с третьих лиц, виновных 
 в причинении такого ущерба.</p><br>
 
<p style="font-weight:500;">БАРАБАН</p><br>

<p style="text-indent:2em;">транспортная тара с гладким или гофрированным корпусом цилиндрической формы,
 без обручей или зигзагов катания, с плоским дном.</p><br>
 
<p style="font-weight:500;">БАРАТРИЯ</p><br>

<p style="text-indent:2em;">преднамеренный, умышленный ущерб, причиненный судну или грузу капитаном или 
командой судна, а также другие незаконные действия с их стороны.</p><br>

<p style="font-weight:500;">БЕЗДОКУМЕНТНЫЙ ГРУЗ</p><br>

<p style="text-indent:2em;">груз, обнаруженный на складе порта или на судне без перевозочных документов,
 принадлежность которого к той или иной партии грузов установить не удается.</p><br>
 
<p style="font-weight:500;">БЕЗОПАСНЫЙ ПОРТ</p><br>

<p style="text-indent:2em;">место, куда судно может войти и откуда оно может выйти в груженом
 состоянии без ущерба для себя и перевозимого груза.</p><br>
 
<p style="font-weight:500;">БОЧКА</p><br>

<p style="text-indent:2em;">транспортная тара с корпусом цилиндрической или параболической формы
 с обручами или зигзагами катания, с доньями.</p><br>
 
<p style="font-weight:500;">БРАКЕРАЖ</p><br>

<p style="text-indent:2em;">проверка соответствия качества товара, его упаковки, внешнего оформления 
условиям соответствующих стандартов и условиям запродажной сделки.</p><br>

<p style="font-weight:500;">ВИД ТАРЫ</p><br>

<p>классификационная единица, определяющая тару по форме.</p><br>

<p style="font-weight:500;">ВНУТРЕННЯЯ ЦЕНА</p><br>

<p>продажная цена товара в стране его происхождения (производства).</p><br>

<p style="font-weight:500;">ВНУТРЕННИЕ ПОРОКИ</p><br>

<p style="text-indent:2em;">присущие застрахованному грузу внутренние свойства, которые могут
 привести к его гибели и порче (гниение, самовозгорание, 
 взрывоопасность, впитывание влаги из воздуха).</p><br>
 
<p style="font-weight:500;">ВОЕННЫЙ РИСК</p><br>

<p style="text-indent:2em;">понятие военного риска является условным, имея в виду, что
 в него включается большой круг рисков, которые, строго говоря,
 выходят за рамки этого понятия.</p><br>
 
<p style="font-weight:500;">ГААГСКИЕ ПРАВИЛА</p><br>

<p style="text-indent:2em;">международные основные положения, определяющие объем ответственности
 судовладельцев при перевозке грузов по коносаментам.</p><br>
 
<p style="font-weight:500;">ГРУЗ</p><br>

<p style="text-indent:2em;">общее название всех товаров, предназначенных для перевозки, или
 товары и имущество, перевозимые на судне с целью получения фрахта.</p><br>
 
<p style="font-weight:500;">ДАТА ПОСТАВКИ</p><br>

<p style="text-indent:2em;">момент, принимаемый сторонами, заключившими торговую сделку, за дату
 поставки товара. Она не должна выходить за пределы срока выполнения договора.
 Она связана с переходом права собственности на товар и рисков
 случайной гибели товара.</p><br>
 
<p style="font-weight:500;">ДЕКЛАРАЦИЯ ОБ ОПАСНЫХ ГРУЗАХ</p><br>

<p style="text-indent:2em;">документ, в котором грузоотправитель описывает опасные товары или материалы,
 предназначенные для транспортировки, а также подтверждает, что последние упакованы
 и снабжены ярлыками в соответствии с положениями соответствующих конвенций
 или соглашений.</p><br>
 
<p style="font-weight:500;">ДЕПОЗИТ ПРЕМИИ</p><br>

<p style="text-indent:2em;">страховая премия, уплачиваемая авансом в тех случаях, когда нельзя определить
 твердо фиксируемую сумму премии при размещении страхования.</p><br>
 
<p style="font-weight:500;">ДИСПАША</p><br>

<p style="text-indent:2em;">расчет убытков по общей аварии и их распределение между участниками морского
 предприятия соразмерно стоимости груза и фрахта.</p><br>
 
<p style="font-weight:500;">ДИСПАШЕР</p><br>

<p>эксперт по составлению диспаши.</p><br>

<p style="font-weight:500;">ЕСТЕСТВЕННАЯ УБЫЛЬ</p><br>

<p style="text-indent:2em;">недостача массы грузов по весу, возникающая вследствие естественных свойств,
 присущих соответствующим грузам: усушка, утруска, утечка и т.д.</p><br>
 
<p style="font-weight:500;">ИЗВЛЕЧЕНИЕ ВЫГОДЫ ОТ СТРАХОВАНИЯ</p><br>

<p style="text-indent:2em;">в предположении временного перехода застрахованного имущества другим лицам, в
 полисах делается оговорка, что правом получения выгод от страхования может воспользоваться
 только страхователь или его правопреемник. Это связано с интересами страховщика по правам
 регресса.</p><br>
 
<p style="font-weight:500;">ИНДОССАМЕНТ</p><br>

<p style="text-indent:2em;">передаточная надпись на страховом полисе, коносаменте и других товарораспорядительных
 и денежных документах.</p><br>
 
<p style="font-weight:500;">ИНТЕРЕС</p><br>

<p>имущество, которое является объектом страхования, право на него или.
 обязательство по отношению к нему.</p><br>
 
<p style="font-weight:500;">ЙОРК-АНТВЕРПЕНСКИЕ ПРАВИЛА</p><br>

<p style="text-indent:2em;">свод норм, регулирующих взаимоотношения сторон и расчеты по общей аварии. Правила
 не имеют силы закона и их применение оговаривается в договорах морской перевозки
 в каждом отдельном случае. Действующая в настоящее время редакция текста Правил
 была принята на 30-й конференции Международного морского комитета 5 апреля 1974 года
 ("Йорк-Антверпенские правила, 1974").</p><br>
 
<p style="font-weight:500;">КАРГО</p><br>

<p>в международной практике грузы или имущество, перевозимые 
морским судном с целью получения фрахта.</p><br>

<p style="font-weight:500;">КАСКО</p><br>

<p>страховой термин, применяемый при страховании
 средств транспорта.</p><br>
 
<p style="font-weight:500;">КЛАССИФИКАЦИЯ ГРУЗОВ</p><br>

<p style="text-indent:2em;">распределение транспортируемых грузов по тарифным группам
 с целью установления размера перевозных платежей.</p><br>
 
<p style="font-weight:500;">КОВЕРНОТ</p><br>

<p style="text-indent:2em;">документ, выдаваемый брокером страхователю в подтверждение 
того, что договор страхования по его поручению заключен.</p><br>

<p style="font-weight:500;">КОММЕРЧЕСКИЙ АКТ</p><br>

<p style="text-indent:2em;">документ, составляемый перевозчиком, в подтверждение факта
 недостачи, порчи или повреждения груза при передаче его 
 грузополучателю в пункте назначения.</p><br>
 
<p style="font-weight:500;">КОНОСАМЕНТ</p><br>

<p style="text-indent:2em;">документ, выдаваемый перевозчиком грузовладельцу в удостоверение
 факта принятия груза к морской перевозке и подтверждения обязательства
 передать его грузополучателю в порту назначения.</p><br>
 
<p style="font-weight:500;">КОНОСАМЕНТ ЧИСТЫЙ</p><br>

<p style="text-indent:2em;">коносамент, который не содержит каких-либо оговорок или
 пометок, констатирующих дефектное состояние груза или его упаковки.</p><br>
 
<p style="font-weight:500;">КОНТРИБУЦИОННЫЙ ДИВИДЕНД</p><br>

<p>отношение суммы общей аварии к контрибуционному капиталу.</p><br>

<p style="font-weight:500;">КОНТРИБУЦИОННЫЙ КАПИТАЛ</p><br>

<p>стоимость имущества, участвующего в покрытии общей аварии.</p><br>

<p style="font-weight:500;">КОТИРОВКА</p><br>

<p>в страховании ставка премии, по которой страховщик 
готов принять на страхование соответствующий риск.</p><br>

<p style="font-weight:500;">КУМУЛЯЦИЯ</p><br>

<p style="text-indent:2em;">совокупность застрахованных объектов, затрагиваемых
 одним и тем же страховым случаем, что ведет к возникновению
 очень крупного убытка.</p><br>
 
<p style="font-weight:500;">ЛИМИТ ОТВЕТСТВЕННОСТИ СТРАХОВЩИКА</p><br>

<p style="text-indent:2em;">максимальная ответственность страховщика, вытекающая из условий
 договора страхования, в котором зафиксирована страховая сумма.</p><br>
 
<p style="font-weight:500;">ЛИМИТ СТРАХОВАНИЯ</p><br>

<p>максимальная денежная сумма, на которую можно застраховать
 материальные ценности, жизнь или здоровье.</p><br>
 
<p style="font-weight:500;">ЛИМИТ СТРАХОВОГО ВОЗМЕЩЕНИЯ (ОТВЕТСТВЕННОСТИ СТРАХОВЩИКА)</p><br>

<p>максимальный размер страхового возмещения по одному
 страховому случаю и (или) за весь срок страхования.</p><br>
 
<p style="font-weight:500;">ЛИЦО, В ПОЛЬЗУ КОТОРОГО ЗАКЛЮЧЕНО СТРАХОВАНИЕ</p><br>

<p style="text-indent:2em;">лицо, по условиям страхования назначенное страхователем
 для получения страхового возмещения (страховой суммы).</p><br>
 
<p style="font-weight:500;">ЛЮКОВАЯ ЗАПИСКА</p><br>

<p>опись грузов, размещаемых в каждом грузовом помещении судна.</p><br>

<p style="font-weight:500;">МАКСИМАЛЬНО ВОЗМОЖНЫЙ УБЫТОК (ПМЛ)</p><br>

<p style="text-indent:2em;">оценочно-максимальный размер ущерба, который может быть
 причинен застрахованному объекту в результате страхового
 случая.</p><br>
 
<p style="font-weight:500;">МЕШОК</p><br>

<p style="text-indent:2em;">транспортная мягкая тара с корпусом в форме рукава, с
 дном и горловиной. Горловина мешка открытая или закрытая.
 МИНИМАЛЬНАЯ ПРЕМИЯ - минимум уплаты премии, вносимой в
 качестве депозита.</p><br>
 
<p style="font-weight:500;">НАКЛАДНАЯ</p><br>

<p>документ, применяемый при железнодорожных и речных перевозках.</p><br>

<p style="font-weight:500;">НАСТУПЛЕНИЕ РИСКА</p><br>

<p>начало ответственности страховщика по условиям страхового полиса.</p><br>

<p style="font-weight:500;">НЕДОСТАВКА ГРУЗА</p><br>

<p style="text-indent:2em;">если груз не прибыл в порт назначения и нет доказательств или
 причин, почему это произошло, случай рассматривается, как
 недоставка груза.</p><br>
 
<p style="font-weight:500;">НЕТТО СТОИМОСТЬ</p><br>

<p style="text-indent:2em;">в страховании действительная или продажная стоимость груза
 в пункте назначения без учета всех расходов, связанных с 
 выгрузкой груза в этом пункте.</p><br>
 
<p style="font-weight:500;">ОТКРЫТЫЙ КОВЕР</p><br>

<p style="text-indent:2em;">форма автоматического страхования грузов на длительный период
 с установлением общих лимитов ответственности (то же что и 
 "генеральный полис").</p><br>
 
<p style="font-weight:500;">ПАРТИЯ ГРУЗА (ПАРЦЕЛЬ)</p><br>

<p style="text-indent:2em;">принятое к перевозке по одному транспортному документу 
определенное количество груза, занимающее часть грузовых
 помещений судна.</p><br>
 
<p style="font-weight:500;">ПАРЦЕЛЬНЫЕ ГРУЗЫ</p><br>

<p style="text-indent:2em;">мелкие партии ценных грузов, к которым относятся 
нетоварные грузы: рекламные материалы, образцы товаров, 
подарки и т.п.</p><br>

<p style="font-weight:500;">ПЕРЕДАТОЧНАЯ ВЕДОМОСТЬ</p><br>

<p>коммерческий документ, содержащий перечень грузов,
 передаваемых с одного вида транспорта на другой.</p><br>
 
<p style="font-weight:500;">ПЕРЕДАЧА ПОЛИСА</p><br>

<p style="text-indent:2em;">полис морского страхования может быть свободно 
предан любому лицу, которое имеет страховой интерес
 в имуществе, находящемся на риске.</p><br>
 
<p style="font-weight:500;">ПЕРЕУПАКОВКА</p><br>

<p>уменьшение убытков от повреждения грузов
 за счет переупаковки.</p><br>
 
<p style="font-weight:500;">ПИСЬМЕННО</p><br>

<p style="text-indent:2em;">способ передачи информации с помощью любого o
 средства воспроизведения текста неисчезающими
 знаками, в т.ч. телеграмма, телекс, телефакс.</p><br>
 
<p style="font-weight:500;">ПОВРЕЖДЕНИЕ ДРУГИМИ ГРУЗАМ</p><br>

<p>скрытая и явная порча и физические повреждения,
 причиненные застрахованному грузу другими грузами.</p><br>
 
<p style="font-weight:500;">ПОВРЕЖДЕНИЕ ГРУЗОВ ВСЛЕДСТВИЕ ОТПОТЕВАНИЯ</p><br>

<p>повреждение грузов в результате отпотевания,
явившегося следствием температурных изменений.</p><br>

<p style="font-weight:500;">ПОЛИС</p><br>

<p>юридическое доказательство факта заключения
 договора страхования.</p><br>
 
<p style="font-weight:500;">ПОЛИСОДЕРЖАТЕЛЬ</p><br>

<p>страхователь.</p><br>

<p style="font-weight:500;">ПОЛНОЕ КОЛИЧЕСТВО ВЫГРУЖЕННОГО ГРУЗА</p><br>

<p>груз (в основном отправленный навалом), прибывший
 в порт назначения без какой-либо недостачи.</p><br>
 
<p style="font-weight:500;">ПОРЧА</p><br>

<p>потеря первоначальных качеств грузом за счет его
 естественных свойств, т. е. без воздействия внешних факторов.</p><br>
 
<p style="font-weight:500;">ПОТРЕБИТЕЛЬСКАЯ ТАРА</p><br>

<p style="text-indent:2em;">элемент упаковки, в которую расфасовывают продукцию для доставки
 ее потребителям (бутылки, флаконы, банки, коробки, пачки и т.п.).</p><br>
 
<p style="font-weight:500;">ПОТЕРТОСТЬ</p><br>

<p>повреждение окрашенных частей грузов, транспортируемых без
 дополнительной упаковки.</p><br>
 
<p style="font-weight:500;">ПРИВЕДЕНИЕ ПОВРЕЖДЕННОГО ГРУЗА В ТРАНСПОРТАБЕЛЬНОЕ СОСТОЯНИЕ</p><br>

<p>уменьшение убытка груза, поврежденного опасностями, 
против которых он застрахован.</p><br>

<p style="font-weight:500;">РЕГРЕСС</p><br>

<p style="text-indent:2em;">право страховщика на предъявление к третьей стороне,
 виновной в наступлении страхового случая, претензий
 с целью получения возмещения за причиненный ущерб.</p><br>
 
<p style="font-weight:500;">СВОЙСТВЕННЫЙ ТОВАРУ ПОРОК</p><br>

<p style="text-indent:2em;">внутреннее свойство товара, в результате которого
 ему причиняется повреждение без воздействия внешних факторов.</p><br>
 
<p style="font-weight:500;">СЕРТИФИКАТ КАЧЕСТВА</p><br>

<p>документ, удостоверяющий качество соответствующей партии товара.</p><br>

<p style="font-weight:500;">СЕРТИФИКАТ ВЕСОВОЙ</p><br>

<p style="text-indent:2em;">ведомость, содержащая перечень каждой партии груза с указанием 
ее массы. Составляется в портах и на железнодорожных станциях.</p><br>

<p style="font-weight:500;">СЕРТИФИКАТ О ПРОИСХОЖДЕНИИ</p><br>

<p>документ, официально удостоверяющий страну происхождения
 соответствующего товара.</p><br>
 
<p style="font-weight:500;">СОВМЕСТНАЯ УПАКОВКА</p><br>

<p>упаковочное место, в котором находится больше, чем один груз.</p><br>

<p style="font-weight:500;">СПЕЦИФИКАЦИЯ</p><br>

<p>перечень товаров с указанием их количества и 
качественных характеристик.</p><br>

<p style="font-weight:500;">СТИВИДОР</p><br>

<p>специализированная фирма или организация, 
осуществляющая погрузку и выгрузку судов.</p><br>

<p style="font-weight:500;">СТОИМОСТЬ ГРУЗА В НЕПОВРЕЖДЕННОМ СОСТОЯНИИ</p><br>

<p style="text-indent:2em;">сравнение брутто-стоимости груза в поврежденном состоянии
 с брутто-стоимостью в неповрежденном состоянии, установленной
 в пункте назначения (для определения частичного убытка, 
 возмещаемого страховщиком).</p><br>
 
<p style="font-weight:500;">СТРАХОВАНИЕ ПЕРЕВОЗОК ЖИВОТНЫХ</p><br>

<p style="text-indent:2em;">особое страхование на условиях специальных оговорок, 
так как животные не попадают под понятие груза или 
обычного товара.</p><br>

<p style="font-weight:500;">СТРАХОВОЙ ИНТЕРЕС</p><br>

<p>мера материальной заинтересованности в страховании.</p><br>

<p style="font-weight:500;">СЮРВЕЙЕР</p><br>

<p style="text-indent:2em;">эксперт, производящий осмотр судов и грузов и дающий 
заключение об их состоянии, размере повреждений при 
авариях, мореходности судна и т.д.</p><br>

<p style="font-weight:500;">ТИП ТАРЫ</p><br>

<p>классификационная единица, определяющая 
Тару по материалу.</p><br>

<p style="font-weight:500;">ТОВАРОРАСПОРЯДИТЕЛЬНЫЙ ДОКУМЕНТ</p><br>

<p style="text-indent:2em;">документ, олицетворяющий собой товар (груз)
 и дающий право его держателю распоряжаться 
 указанным в нем товаром (грузом). К числу таких
 документов относится страховой полис.</p><br>
 
<p style="font-weight:500;">ТОЛКОВАНИЕ УСЛОВИЙ ПОЛИСА</p><br>

<p style="text-indent:2em;">объяснение без искажения смысла. Всякие двусмысленные,
 неточные и неопределенные формулировки полиса должны 
 истолковываться в пользу страхователя. При толковании 
 условий полиса прежде всего до внимание должно быть принято
 то, что написано от руки, затем напечатанный на машинке текст,
 штампы, напечатки, стандартные .оговорки. условия, напечатанные
 непосредственно на полисе, принимаются во внимание в 
 последнюю очередь.</p><br>
 
<p style="font-weight:500;">ТРАНСПОРТНАЯ ТАРА</p><br>

<p style="text-indent:2em;">элемент упаковки продукции, как правило, расфасованной 
в потребительскую тару, или вспомогательные упаковочные 
средства и материалы.</p><br>

<p style="font-weight:500;">ТРИПЛИКАТ</p><br>

<p>третий экземпляр документа.</p><br>

<p style="font-weight:500;">УБЫТОК В РЕЗУЛЬТАТЕ ВЫПАДЕНИЯ ГРУЗА ИЗ СТРОПОВ</p><br>

<p>следствие выпадения груза из стропов при погрузочно-разгрузочных работах.</p><br>

<p style="font-weight:500;">УБЫТОК ОТ РЕАЛИЗАЦИИ СПАСЕННОГО ИМУЩЕСТВА</p><br>

<p>разница между полученной суммой выручки от продажи 
спасенного имущества и страховой суммой.</p><br>

<p style="font-weight:500;">УПАКОВКА</p><br>

<p style="text-indent:2em;">комплекс защитных мер и материальных средств по подготовке 
продукции промышленного и сельскохозяйственного производства к 
транспортированию и хранению, для обозначения ее максимальной 
сохранности и придания транспортабельного состояния. Упаковка 
представляет собой потребительскую и транспортную тару, 
прокладочные и амортизирующие материалы, вспомогательные 
упаковочные средства и материалы.</p><br>

<p style="font-weight:500;">УПАКОВОЧНЫЙ ЛИСТ</p><br>

<p>перечень предметов, входящих в одно грузовое место
 (ящик, кипу и т.п.).</p><br>
 
<p style="font-weight:500;">УПАКОВЫВАНИЕ</p><br>

<p style="text-indent:2em;">представляет собой одну или несколько операций на специальных
 машинах или вручную, в большинстве своем завершающих технологический
 процесс производства данной продукции.</p><br>
 
<p style="font-weight:500;">УСУШКА, УТЕЧКА, УТРЯСКА</p><br>

<p>убытки от названных факторов, вследствие естественных свойств 
соответствующих товаров (грузов).</p><br>

<p style="font-weight:500;">ФАКТУРА</p><br>

<p style="text-indent:2em;">документ, служащий доказательством наличия торговой сделки. 
Основополагающий документ при ликвидации убытков.</p><br>

<p style="font-weight:500;">ФЛЯГА</p><br>

<p style="text-indent:2em;">транспортная многооборотная тара с корпусом цилиндрической формы 
и цилиндрической горловиной, диаметр которой меньше диаметра 
корпуса, с приспособлением для переноса, сливной горловиной и
 крышкой с затвором.</p><br>
 
<p style="font-weight:500;">ФРАНШИЗА</p><br>

<p style="text-indent:2em;">определенная часть убытков страхователя, не подлежащая
 возмещению страховщиком в соответствии с условиями 
 страхования.</p><br>
 
<p style="font-weight:500;">ФРАНШИЗА БЕЗУСЛОВНАЯ</p><br>

<p>ответственность страховщика определяется размером 
убытка за минусом франшизы.</p><br>

<p style="font-weight:500;">ФРАНШИЗА УСЛОВНАЯ</p><br>

<p style="text-indent:2em;">страховщик освобождается от ответственности за убыток, 
если его размер не превышает размера франшизы, и убыток
 подлежит возмещению полностью, если его размер превышает 
 франшизу.</p><br>
 
<p style="font-weight:500;">ЯЩИК</p><br>

<p style="text-indent:2em;">транспортная тара с корпусом, имеющим в сечении, параллельном дну,
 преимущественно форму прямоугольника, с дном, двумя торцевыми и 
 боковыми стенками, с крышкой или без нее.</p><br>
 
 <p><a href="https://www.tbncom.com/publ/avtomobilnye_perevozki_road_transport/slovar_professionalnoj_terminologii_otnosjashhejsja_k_processam_transportirovanija_gruzov/1-1-0-6">Источник</a></p>

</div>
`