import React, { Component } from 'react'
import classNames from 'classnames'
import dict from '../../../shared/dictionaries'
import fetch from '../../../../components/shared/fetch'
import ForumCommentDate from '../../../forum/forum-full-page/forum-date'
import UserPhoto from '../../../user/user-photo'
import { Link } from 'react-router-dom'
import Rating from '../../../UI/star-rating'
import userBlocked from "../../../../images/user_block.png"
import TooltipWrapper from '../../../UI/popover-wrapper'
import Spinner from '../../../UI/spinner'

import './tooltip-modal.scss'

const SECONDS_TO_FETCH = 1
interface TooltipModalProps extends ComponentBaseProps {
  userId: number
  companyId: number
  companyName: ''
}

export default class TooltipModal extends Component<TooltipModalProps> {
  state = {
    userId: 0,
    userInfo: {},
    companyInfo: {},
    companyName: '',
    rating: 0,
    reviewCount: 0,
    userIsBlocked: false,
    showInfoCounter: 0,
    canShowContent: false,
    refInterval: setInterval(() => {
      this.setState({ showInfoCounter: this.state.showInfoCounter + 1 })
    }, 1000),
  }

  componentDidMount() {
    setTimeout(() => {
      this.fetchTooltipInfo()
    }, 800);
  }

  fetchTooltipInfo = () => {
    const { userId, companyName, companyId } = this.props

    if (userId) {
      this.getUserInfo(userId)
    } else {
      fetch(`/company/item/${companyId}/1`).then(result => {
        this.setState({ companyInfo: result, companyName, canShowContent: true })
      })
    }
  }

  componentWillUnmount() {
    this.stopTimer()
  }

  stopTimer() {
    const { showInfoCounter } = this.state
    if (showInfoCounter) {
      clearInterval(showInfoCounter)
    }
    this.setState({
      refInterval: null,
    })
  }

  getCompanyInfo = companyId => {
    fetch(`/company/item/${companyId}/1`).then(result => {
      this.setState({ companyInfo: result })
    })
  }

  getUserInfo = userId => {
    fetch(`/user/getUserRating?userId=${userId}`).then(res => {
      const { rating, reviewCount } = res.data
      this.setState({ rating, reviewCount, canShowContent: true })
    })

    fetch(`/auth/getProfile/${userId}`).then(userInfo => {
      this.setState({ userInfo: userInfo.profile })
    })


    fetch(`/auth/getProfile/${userId}`).then(res => {
      const userIsBlocked = res.profile.activeStat === "B" || res.profile.activeStat === "D"
      this.setState({ userIsBlocked })
    })

  }

  renderAdditionalUserDetails = () => {
    const { userId, companyId } = this.props
    const { userInfo } = this.state

    const photo = userInfo['photo']
    const gender = userInfo['gender']
    const languages = userInfo['languages']
    const firstName = userInfo['firstName']
    const lastName = userInfo['lastName']
    const userCompanyName = userInfo['userCompanyName']
    const userCompanyId = userInfo['companyId']

    const { items } = dict.languages()
    const langs = items.filter(x => languages?.split(',').includes(String(x.value)))

    const name = firstName || lastName ? firstName + ' ' + lastName : ''
    return this.renderContantTooltip(photo, gender, name, '', langs, userId, companyId, userCompanyName, userCompanyId, null)
  }

  renderContantTooltip = (photo, gender, name, companyName, langs, userId = null, companyId, userCompanyName, userCompanyId, companyIsVerified) => {
    const { t } = this.props
    const { userIsBlocked, userInfo } = this.state
    const verifiedCompany = userInfo['verifiedCompany']
    return (
      <div className="user-block show-modal-tooltip">
        {!!userId && <div className="img-container user-modal-avatar">
          <UserPhoto customUserId={userId} photo={photo} gender={gender} />
        </div>}
        {!!userId && (
          <Link to={`/user/details/${userId}`} className="name-sm">
            {verifiedCompany > 0 && <i className="fa fa-check-circle col-green"></i>}
            {name}
          </Link>
        )}

        {companyName != '' && companyId &&(
          <Link to={`/company/item/${companyId}`} className="name-sm">
            {companyIsVerified > 0 && <i className="fa fa-check-circle col-green"></i>}
            {companyName}
          </Link>
        )}
        {userIsBlocked && <span className="user-is-blocked">
          <TooltipWrapper text={t('user.is.blocked')} hover>
            <img src={userBlocked} className="user-blocked-image" />
          </TooltipWrapper>
        </span>}
        {companyName == '' && (
          <div className="language-user">
            <span className="meta">{t('speaking.languages')}:</span>
            <ul className="list-flags">
              {langs.map((x, j) => (
                <li key={j} title={x.fullName}>
                  <span className={classNames('flag flag-icon', x.flag)} />
                </li>
              ))}
            </ul>
          </div>
        )}
        {userCompanyName &&
          <div className="name-sm user-company-name">
            <Link to={`/company/item/${userCompanyId}`} className="name-sm">
              {userCompanyName}
            </Link>
          </div>}
      </div>
    )
  }

  renderAdditionalCompanyDetails = () => {
    const { companyInfo } = this.state

    const { items } = dict.languages()
    const languages = companyInfo['languages']
    const companyName = companyInfo['companyName']
    const companyId = companyInfo['id']
    const companyIsVerified = companyInfo['verified']

    const langs = items.filter(x => languages?.split(',').includes(String(x.value)))
    return this.renderContantTooltip(null, null, null, companyName, langs, 0, companyId, "", "", companyIsVerified)
  }

  showRating = rating => {
    return (
      <div>
        <Rating averageRating={rating} /> {rating.toFixed(2)}
      </div>
    )
  }

  renderSpinnerContent = () => {
    return (
      <Spinner />
    )
  }

  renderInfoContent = () => {
    const { t, langId, userId } = this.props
    const { userInfo, rating, companyInfo } = this.state

    const dateRegistered = !userInfo['dateRegistered']
      ? companyInfo['dateRegistered']
      : userInfo['dateRegistered']


    const showUserContent = userInfo['lastName'] || userInfo['firstName']
    const ratingValue = showUserContent ? rating === null ? 0 : rating : Number(companyInfo['avgGrade'])
    const totalReviews = companyInfo['reviewCount'] ? companyInfo['reviewCount'] : userInfo['reviewCount']

    const countryValue = companyInfo['countryId'] ? companyInfo['countryId'] : userInfo['countryId']
    const country = (dict.countries(langId).items || []).filter(country => country.value === Number(countryValue))

    const urlId = userId ? `/user/details/${userId}/reviews` : `/company/item/${companyInfo['id']}/reviews`
    return (
      <div className="company-info">
        {showUserContent ?
          this.renderAdditionalUserDetails() :
          this.renderAdditionalCompanyDetails()
        }
        <div className="company-info-row">
          <div className="company-info-cell ta-l">
            <span>{country[0]?.name}</span>
            <span className="meta">
              {t('tooltip.with.us')}
              {ForumCommentDate(dateRegistered)}
            </span>
          </div>
          <div className="company-info-cell ta-r ">
            <div className="list-stars">{this.showRating(ratingValue)}</div>

            {totalReviews > 0 ?
              <Link className="company-info-cell tooltip-reviews-content" to={urlId}>
                <span>{t('reviews.in.total')}: {totalReviews}</span>
              </Link>
              :
              <span className="tooltip-reviews-content">  {t('reviews.in.total')}: {0} </span>
            }

          </div>
          {companyInfo['claimsCount'] > 0 && companyInfo['id'] ?
            <Link className="company-info-cell" to={`/company/item/${companyInfo['id']}/claims`}>
              {companyInfo['claimsCount'] > 0 && <span>{t('is.in.blacklist')}</span>}
              <span>{t('compliant.in.total')}: {companyInfo['claimsCount']}</span>
            </Link> : companyInfo['id'] && <span>{t('compliant.in.total')}: {companyInfo['claimsCount']}</span>}

        </div>
      </div>
    )
  }

  render() {
    const { showInfoCounter, canShowContent } = this.state
    const isFetching = showInfoCounter >= SECONDS_TO_FETCH
    const content = isFetching && canShowContent ? this.renderInfoContent() : this.renderSpinnerContent()
    const className = classNames({ 'popover-content': true, 'is-loading': !isFetching, })

    return (
      <div {...{ className }}>
        {content}
      </div>
    )
  }
}
