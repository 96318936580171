import dict from '../components/shared/dictionaries'
import moment from 'moment'
import { string } from 'prop-types'

const now = new Date()
const cargoOptions = dict.cargo_options.reduce((prev, curr) => {
  const obj = curr.reduce((p, c) => {
    p[c.fieldName] = false
    return p
  }, {})
  return { ...prev, ...obj }
}, {})

export const baseProps: IBaseProps = {
  requestedAt: undefined,
  loading: false,
  loaded: false,
  error: undefined,
}
// ------------------------------------
// UserProfileModel
// ------------------------------------
export const UserProfileModel: IUserProfileModel = {
  showEmail: false,

  photo: undefined,
  lastName: undefined,
  firstName: undefined,
  middleName: undefined,
  countryId: undefined,
  jobType: undefined,
  languages: [],

  phone1: undefined,
  phone2: undefined,
  skype: undefined,
  viber: undefined,
  whatsup: undefined,
  telegram: undefined,
  fbMessenger: undefined,
  linkedin: undefined,
  additionalInfo: undefined,
  userCompanyName: undefined,
  isCompanyAdmin: undefined,
  companyId: undefined,
}
// ------------------------------------
// Cargo Search Form Model
// ------------------------------------
export const CargoSearchFormModel: ICargoSearchFormModel = {
  filterKey: 'auto',

  countriesFrom: [],
  countriesTo: [],
  dateLoadingFrom: moment().format('YYYY-MM-DD'),
  dateLoadingTo: undefined,
  transportType: undefined,
  carryingCapacity: undefined,

  isAdr: false,
  adrClass: 1,
  isClimateControl: false,
  isGrouping: false,
  isOversized: false,

  isOnlyProvedCompanies: false,
  isOnlyFromMyCountry: false,
}
// ------------------------------------
// Cargo Item Model
// ------------------------------------
export const CargoItemModel: ICargoItemModel = {
  itemId: undefined,
  from: {
    point: undefined,
    countryId: undefined,
    cityId: undefined,
    range: undefined,
    countryName: undefined,
    countryAbbr: undefined,
    cityName: undefined,
    coords: undefined,
  },
  to: {
    point: undefined,
    countryId: undefined,
    cityId: undefined,
    range: undefined,
    countryName: undefined,
    countryAbbr: undefined,
    cityName: undefined,
    coords: undefined,
  },
  middlePoints: undefined,
  shipOn: {
    date: moment(now).format('YYYY-MM-DD'),
    day: now.getDate(),
    month: now.getMonth() + 1,
    year: now.getFullYear(),
    range: 0,
  },
  cargo: {
    type: undefined, //1
    weight: {
      value: undefined,
      unit: 't',
    },
    volume: {
      m3: undefined,
      ldm: undefined,
      pal: undefined,
    },
    volumeUnit: undefined,
    isAdr: false,
    adrClass: 1,
    isTir: false,
    isClimateControl: false,
    temperatureFrom: '0',
    temperatureTo: '0',
    climateTemperature: '0',
    isGrouping: false,
    isOversized: false,

    isDeclaration: false,
    isCustom: false,
    isSanitary: false,
    isCmr: false,
    isCmrInsurance: false,
  },
  companyName: undefined,
  userFullName: undefined,
  shippingType: 'auto',
  transport: {
    types: undefined,
    isLoadingTop: false,
    isLoadingRare: false,
    isLoadingSide: false,
    quantity: undefined,
    recurring: 'one-time',
  },
  price: {
    type: undefined,
    value: undefined,
    currency: undefined,
    recurring: undefined,
  },
  options: { ...cargoOptions },
  notes: undefined,
  template: undefined,
}
// ------------------------------------
// Transport Item Model
// ------------------------------------
export const TransportItemModel: ITransportItemModel = {
  itemId: undefined,
  carType: undefined,
  from: {
    value: undefined,
    point: undefined,
    countryId: undefined,
    cityId: undefined,
    range: undefined,
    countryName: undefined,
    countryAbbr: undefined,
    cityName: undefined,
    coords: undefined,
  },
  to: {
    value: undefined,
    point: undefined,
    countryId: undefined,
    cityId: undefined,
    range: undefined,
    countryName: undefined,
    countryAbbr: undefined,
    cityName: undefined,
    coords: null,
  },
  middlePoints: undefined,
  shipOn: {
    date: moment(now).format('YYYY-MM-DD'),
    day: now.getDate(),
    month: now.getMonth() + 1,
    year: now.getFullYear(),
    range: 0,
  },
  cargo: {
    type: 1,
    weight: {
      value: undefined,
      unit: 't',
    },
    volume: undefined,
    volumeUnit: undefined,
    isAdr: false,
    adrClass: 1,
    isTir: false,
    isClimateControl: false,
    temperatureFrom: '0',
    temperatureTo: '0',
    climateTemperature: '0',
    isGrouping: false,
    isOversized: false,

    isDeclaration: false,
    isCustom: false,
    isSanitary: false,
    isCmr: false,
    isCmrInsurance: false,
  },
  transport: {
    autoparkId: undefined,
    types: undefined,
    weight: undefined,
    isLoadingTop: false,
    isLoadingRare: false,
    isLoadingSide: false,
    quantity: undefined,
    recurring: 'one-time',
    ecoStandard: undefined,
    bodyType: undefined,
    model: undefined,
    number: undefined,
    year: undefined,
  },
  trailer: {
    bodyType: undefined,
    year: undefined,
    model: undefined,
    number: undefined,
  },
  companyName: undefined,
  userFullName: undefined,
  shippingType: 'auto',
  price: {
    type: undefined,
    value: undefined,
    currency: undefined,
    recurring: undefined,
  },
  options: { ...cargoOptions },
  volume: {
    m3: undefined,
    ldm: undefined,
    pal: undefined,
  },
  template: undefined,
  notes: undefined,
}
// ------------------------------------
// Edit model
// ------------------------------------
export const EditModel: ICargoTransportEdit = {
  itemId: 0,
  from: undefined,
  to: undefined,
  middlePoints: [],
  shipOn: {
    date: undefined,
    day: 0,
    month: 0,
    year: 0,
    range: 0,
  },
  price: {
    type: undefined,
    value: 0,
    currency: undefined,
    recurring: undefined,
  },
  options: undefined,
  notes: undefined,
  photos: [],
  cargo: undefined,
  filterKey: undefined,
  shippingType: undefined,
  transport: undefined,
  carType: 0,
  volume: undefined,
  trailer: undefined,
  docs: undefined,
}

// ------------------------------------
// Car Item Model
// ------------------------------------
export const CarItemModel: ICarItemModel = {
  itemId: undefined,
  carType: 0, //choose one item,
  ownershipType: 0, //not required,

  car: {
    makeAndModel: undefined,
    stateNumber: undefined,
    bodyType: 0, //required for one car type
    year: undefined,
    ecoStandard: undefined,
  },
  trailer: {
    makeAndModel: undefined,
    stateNumber: undefined,
    bodyType: undefined,
    year: undefined,
  },
  isLoadingTop: false,
  isLoadingRare: false,
  isLoadingSide: false,
  weight: undefined,
  volume: {
    m3: undefined,
    ldm: undefined,
    pal: undefined,
  },
  isAdr: false,
  adrClass: 1,

  isClimateControl: false,
  temperatureFrom: '0',
  temperatureTo: '0',

  isGrouping: false,
  isOversized: false,
  isGps: false,

  isTir: false,
  isDeclaration: false,
  isCustom: false,
  isCmr: false,
  isCmrInsurance: false,
  isSanitary: false,

  photo: [],

  notes: undefined,
}
// ------------------------------------
// JwtModel
// ------------------------------------
export const JwtModel: IJwtModel = {
  // custom props
  username: '',
  userId: undefined,
  email: '',
  permissions: [],
  // system props
  iat: undefined,
  exp: undefined,
  iss: '',
}
