import _ from 'lodash'
import React from 'react'
import isArray from 'lodash/isArray'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import uuid from 'uuid/v4'
import PopoverWrapper from '../../../UI/popover-wrapper'
import './styles.scss'
import FlexBand from 'flexband'

export default class extends React.Component {
  static displayName = 'WidgetFilter'
  constructor(props) {
    super(props)
    this.state = {
      options: [],
      hiddenCounter: 0,
    }
    this.updateFilters = false
    this.filterId = uuid()
    this.windowSize = window.innerWidth
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.items !== nextProps.items) {
  //     this.refreshItems(nextProps.items)
  //   }
  // }

  componentDidMount() {
    this.refreshItems(this.props.items)

    window.addEventListener(
      'resize',
      _.debounce(event => {
        const newWindowSize = event.target.innerWidth
        if (this.windowSize > newWindowSize) {
          this.handleOverflow()
        } else {
          this.showFilterElement()
        }

        this.windowSize = newWindowSize
      }, 300)
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.items !== prevProps.items) {
      this.refreshItems(this.props.items)
    }

    if (!_.isEqual(this.state.options, prevState.options)) {
      this.handleOverflow()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', _.debounce(this.handleOverflow, 300))
  }

  handleOverflow = (reloadCounter = 0) => {
    const filterBar = document.getElementById(`filter-bar_${this.filterId}`)

    if (!filterBar) {
      return reloadCounter <= 20 && setTimeout(() => this.handleOverflow(reloadCounter++), 300)
    }
    const curOverflow = filterBar.style.overflow

    if (!curOverflow || curOverflow === 'visible') filterBar.style.overflow = 'hidden'

    const isOverflowing =
      filterBar.clientWidth < filterBar.scrollWidth ||
      filterBar.clientHeight < filterBar.scrollHeight

    filterBar.style.overflow = curOverflow

    if (isOverflowing) {
      // const ret = this.state.options.splice(this.state.options.length - 1, 1)
      let { options } = this.state

      options = _.cloneDeep(options)

      for (let i = options.length - 1; i >= 0; i--) {
        const option = options[i]
        if (!option.hidden) {
          option.hidden = true

          this.setState({ options, hiddenCounter: this.state.hiddenCounter + 1 })
          break
        }
      }
    }
  }

  showFilterElement = options => {
    const { options: stateOptions, hiddenCounter } = this.state

    if (!options) {
      options = _.cloneDeep(stateOptions)
    }

    options.find(option => {
      if (option.hidden) {
        option.hidden = false
        this.showFilterElement(options)
        this.setState({ options, hiddenCounter: hiddenCounter - 1 })
        return true
      }

      return false
    })
  }

  setOptions = (options, props) => {
    options = options.filter(x => !!x) // filter out all undefined
    if (this.props.emptyOption) {
      options = [{ value: '', name: this.props.emptyOption }, ...options]
    }
    this.setState({ options, ...props })
  }

  refreshItems(data) {
    if (data) {
      if (isArray(data)) {
        this.setOptions(data)
      } else if (data.type === 'dictionary') {
        const { loading, loaded, handler, items } = data
        // this.setState({ loading, loaded })

        if (loading) {
          handler.then(x => {
            const { loading, loaded, items: options = [], error } = x
            this.setOptions(options, { loading, loaded, error })
          })
        } else if (loaded) {
          this.setOptions(items, { loading, loaded })
        }
      }
    } else {
      // this.setState({ loading: false, loaded: false, options: [] })
      this.setState({ options: [] })
    }
  }

  render() {
    const {
      t,
      selectedKey = '',
      onClick,
      className,
      css = {},
      extended,
      children,
      hideBorders,
      loadLanguages
    } = this.props
    const { options } = this.state
    
    return (
      <div
        className={classnames('panel-filter', hideBorders ? 'hide-borders' : null, css.container, {
          'panel-filter-lg': extended,
        })}
      >
        <div
          className={classnames(
            'filter-wrapper filter-nav clearfix priority-nav',
            className,
            css.filter
          )}
          id={`filter-bar_${this.filterId}`}
        >
          <ul>
            {options.map(
              (x, index) =>
                !x.hidden && (
                  <li key={index} className={classnames({ active: x.value === selectedKey })}>
                    <Link to="#" title={loadLanguages ? x.name :t(x.name)} onClick={() => onClick(x.value)}>
                      {loadLanguages ? x.name :t(x.name)}
                    </Link>
                  </li>
                )
            )}
            {options.find(x => x.hidden) && (
              <PopoverWrapper
                component="li"
                text={
                  <FlexBand jusitfy="flex-start">
                    {options.map(
                      (x, index) =>
                        x.hidden && (
                          <li
                            key={index}
                            className={classnames({ active: x.value === selectedKey })}
                            style={{ width: '100%', textAlign: 'start' }}
                          >
                            <Link to="#" title={x.name} onClick={() => onClick(x.value)}>
                              {x.name}
                            </Link>
                          </li>
                        )
                    )}
                  </FlexBand>
                }
              >
                <Link to="#" title={'еще'} className="widget-more">
                  {t('more.lowercase')}
                </Link>
              </PopoverWrapper>
            )}
          </ul>
          <span className="nav__dropdown-wrapper priority-nav__wrapper" aria-haspopup="false">
            <button
              aria-controls="menu"
              type="button"
              className="nav__dropdown-toggle priority-nav__dropdown-toggle priority-nav-is-hidden"
              // prioritynav-count="0"
            >
              {t('more.lowercase')}
            </button>
            <ul aria-hidden="true" className="nav__dropdown priority-nav__dropdown" />
          </span>
        </div>
        {children}
      </div>
    )
  }
}
