export const int22 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Конвенция ООН о морской перевозке грузов.</h4><p></p>

<p style="font-weight:500;">Преамбула</p>

<p style="text-indent:2em;"></p><h6>Государства - Стороны настоящей Конвенции, признавая желательным установить
 посредством соглашения некоторые правила, касающиеся морской перевозки грузов,
 решили заключить с этой целью Конвенцию и поэтому согласились о 
 нижеследующем:</h6><p></p>

<p style="font-weight:500;">ЧАСТЬ I. ОБЩИЕ ПОЛОЖЕНИЯ</p><br>

<p style="font-weight:500;">ЧАСТЬ II. ОТВЕТСТВЕННОСТЬ ПЕРЕВОЗЧИКА</p><br>

<p style="font-weight:500;">ЧАСТЬ III. ОТВЕТСТВЕННОСТЬ ГРУЗООТПРАВИТЕЛЯ</p><br>

<p style="font-weight:500;">ЧАСТЬ IV. ТРАНСПОРТНЫЕ ДОКУМЕНТЫ</p><br>

<p style="font-weight:500;">ЧАСТЬ V. ТРЕБОВАНИЯ И ИСКИ</p><br>

<p style="font-weight:500;">ЧАСТЬ VI. ДОПОЛНИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</p><br>

<p style="font-weight:500;">ПРИМЕЧАНИЯ</p>

<p></p><h4>ЧАСТЬ I. ОБЩИЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 1. Определения</p>

<p style="text-indent:2em;"></p><h6>В настоящей Конвенции:</h6><p></p>

<p style="text-indent:2em;">1. "Перевозчик" означает любое лицо, которым или от имени которого с грузоотправителем
 заключен договор морской перевозки груза.</p><br>

<p style="text-indent:2em;">2. "Фактический перевозчик" означает любое лицо, которому перевозчиком поручено
 осуществление перевозки груза или части перевозки, и включает любое другое лицо,
 которому поручено такое осуществление перевозки.</p><br>

<p style="text-indent:2em;">3. "Грузоотправитель" означает любое лицо, которым или от имени или от лица 
которого заключен договор морской перевозки груза с перевозчиком, или любое лицо,
 которым или от имени или от лица которого груз фактически сдается перевозчику в
 связи с договором морской перевозки.</p><br>

<p>4."Грузополучатель" означает лицо, управомоченное на получение груза.</p><br>

<p style="text-indent:2em;">5. "Груз" включает живых животных; в тех случаях, когда товары объединены в
 контейнере, на паллете или подобном приспособлении для транспортировки или когда
 они упакованы, "груз" включает такое приспособление для транспортировки или упаковку,
 если они предоставлены грузоотправителем.</p><br>

<p style="text-indent:2em;">6. "Договор морской перевозки" означает любой договор, в соответствии с которым 
перевозчик за уплату фрахта обязуется перевезти груз морем из одного порта в другой;
 однако, договор, охватывающий перевозку морем, а также перевозку каким-либо иным 
 способом, рассматривается как договор морской перевозки для целей настоящей Конвенции лишь
 в той степени, в какой он относится к перевозке морем.</p><br>

<p style="text-indent:2em;">7. "Коносамент" означает документ, который подтверждает договор морской перевозки и 
прием или погрузку груза перевозчиком и в соответствии с которым перевозчик обязуется 
сдать груз против этого документа. Указание в документе о том, что груз должен быть сдан 
приказу поименованного лица или приказу, или предъявителю, представляет собой такое 
обязательство.</p><br>

<p>8. "Письменная форма" включает наряду с прочим телеграмму и телекс.</p><br>

<p style="font-weight:500;">Статья 2. Сфера применения</p>

<p style="text-indent:2em;"></p><h6>1. Положения настоящей Конвенции применяются ко всем договорам морской перевозки 
между двумя различными государствами, если:</h6><p></p>

<p style="text-indent:2em;">a) порт погрузки, предусмотренный в договоре морской перевозки, находится в одном 
из Договаривающихся государств; или</p><br>

<p style="text-indent:2em;">b) порт разгрузки, предусмотренный в договоре морской перевозки, находится в одном 
из Договаривающихся государств; или</p><br>

<p style="text-indent:2em;">c) один из опционных портов разгрузки, предусмотренных в договоре морской перевозки, 
является фактическим портом разгрузки, и такой порт находится в одном из Договаривающихся 
государств; или</p><br>

<p style="text-indent:2em;">d) коносамент или другой документ, подтверждающий договор морской перевозки, выдан в 
одном из Договаривающихся государств; или</p><br>

<p style="text-indent:2em;">e) коносамент или другой документ, подтверждающий договор морской перевозки, 
предусматривает, что договор должен регулироваться положениями настоящей Конвенции 
или законодательством любого государства, вводящим их в действие.</p><br>

<p style="text-indent:2em;">2. Положения настоящей Конвенции применяются независимо от национальной принадлежности 
судна, перевозчика, фактического перевозчика, грузоотправителя, грузополучателя или 
любого другого заинтересованного лица.</p><br>

<p style="text-indent:2em;">3. Положения настоящей Конвенции не применяются к чартерам. Однако, когда коносамент 
выдается согласно чартеру, положения Конвенции применяются к такому коносаменту, если 
он регулирует отношения между перевозчиком и держателем коносамента, не являющимся 
фрахтователем.</p><br>

<p style="text-indent:2em;">4. Если договор предусматривает перевозку груза несколькими отгрузками в течение 
согласованного периода, положения настоящей Конвенции применяются к каждой отгрузке. 
Однако, когда отгрузка осуществляется на основании чартера, применяются положения пункта 
3 настоящей статьи.</p><br>

<p style="font-weight:500;">Статья 3. Толкование Конвенции</p><br>

<p style="text-indent:2em;">При толковании и применении положений настоящей Конвенции надлежит учитывать ее 
международный характер и необходимость содействовать достижению единообразия.</p>

<p></p><h4>ЧАСТЬ II. ОТВЕТСТВЕННОСТЬ ПЕРЕВОЗЧИКА</h4><p></p>

<p style="font-weight:500;">Статья 4. Период ответственности</p><br>

<p style="text-indent:2em;">1. Ответственность перевозчика за груз на основании настоящей Конвенции охватывает 
период, в течение которого груз находится в ведении перевозчика в порту погрузки, 
во время перевозки и в порту разгрузки.</p>

<p style="text-indent:2em;"></p><h6>2. Для целей пункта 1 настоящей статьи считается, что груз находится в 
ведении перевозчика:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) с момента, когда он принял груз от:</h6><p></p>

<p>I) грузоотправителя или лица, действующего от имени грузоотправителя; или</p><br>

<p style="text-indent:2em;">II) какого-либо органа или иного третьего лица, которому в силу закона или 
правил, применяемых в порту погрузки, груз должен быть передан для отгрузки;</p>

<p style="text-indent:2em;"></p><h6>b) до момента, когда он сдал груз:</h6><p></p>

<p>I) путем передачи груза грузополучателю; или</p><br>

<p style="text-indent:2em;">II) в случаях, когда грузополучатель не принимает груз от перевозчика, - 
путем предоставления его в распоряжение грузополучателя в соответствии с 
договором, или законом, или обычаем данной отрасли торговли, применяемых 
в порту разгрузки; или</p><br>

<p style="text-indent:2em;">III) путем передачи груза какому-либо органу или иному третьему лицу, которым 
в силу закона или правил, применяемых в порту разгрузки, должен быть передан груз.</p><br>

<p style="text-indent:2em;">3. В пунктах 1 и 2 настоящей статьи ссылка на перевозчика или грузополучателя 
означает помимо перевозчика или грузополучателя также служащих или агентов, 
соответственно, перевозчика или грузополучателя.</p><br>

<p style="font-weight:500;">Статья 5. Основания ответственности</p><br>

<p style="text-indent:2em;">1. Перевозчик несет ответственность за ущерб, являющийся результатом утраты или 
повреждения груза, а также задержки в сдаче, если обстоятельства, вызвавшие утрату, 
повреждение или задержку, имели место в то время, когда груз находился в его ведении, 
как это определено в статье 4, если только перевозчик не докажет, что он, его служащие 
или агенты приняли все меры, которые могли разумно требоваться, чтобы избежать таких 
обстоятельств и их последствий.</p><br>

<p style="text-indent:2em;">2. Задержка в сдаче имеет место, если груз не сдан в порту разгрузки, предусмотренном 
в договоре морской перевозки, в пределах срока, прямо определенного соглашением, или 
при отсутствии такого соглашения - в пределах срока, который было бы разумно требовать 
от заботливого перевозчика с учетом конкретных обстоятельств.</p><br>

<p style="text-indent:2em;">3. Лицо, управомоченное заявить требование по поводу утраты груза, может считать груз 
утраченным, если он не был сдан в соответствии с требованиями статьи 4 в пределах 60 
календарных дней по истечении срока сдачи согласно пункту 2 настоящей статьи.</p>

<p style="text-indent:2em;"></p><h6>4. a) Перевозчик несет ответственность:</h6><p></p>

<p style="text-indent:2em;">I) за утрату или повреждение груза или задержку в сдаче, вызванные пожаром, если лицо, 
заявляющее требование, докажет, что пожар возник по вине перевозчика, его служащих 
или агентов;</p><br>

<p style="text-indent:2em;">II) за такие утрату, повреждение или задержку в сдаче, которые, если это доказано 
лицом, заявляющим требование, произошли по вине перевозчика, его служащих или агентов
 при принятии всех мер, которые могли разумно требоваться, чтобы ликвидировать пожар
 и избежать или уменьшить его последствия.</p><br>

<p style="text-indent:2em;">b) В случае, когда пожар на борту судна затронул груз, и если того пожелает лицо, 
заявляющее требование, или перевозчик, должен быть проведен сюрвейерский осмотр в 
соответствии с практикой морских перевозок по поводу причин и обстоятельств пожара,
 и копия соответствующего отчета должна предоставляться перевозчику или лицу, заявляющему 
 требование, по их запросу.</p><br>

<p style="text-indent:2em;">5. В отношении живых животных перевозчик не несет ответственности за утрату, повреждение
 или задержку в сдаче, являющиеся результатом любых особых рисков, присущих этому виду 
 перевозки. Если перевозчик докажет, что он выполнил специальные инструкции в отношении 
 животных, данные ему грузоотправителем, и что при данных обстоятельствах утрата, повреждение
 или задержка в сдаче могли бы быть отнесены на счет таких рисков, предполагается, что утрата,
 повреждение или задержка в сдаче были вызваны этими рисками, если нет доказательств того,
 что целиком или отчасти утрата, повреждение или задержка в сдаче произошли по вине перевозчика,
 его служащих или агентов.</p><br>

<p style="text-indent:2em;">6. Перевозчик не несет ответственности, кроме как по общей аварии, в тех случаях, когда утрата,
 повреждение или задержка в сдаче возникли в результате мер по спасению жизни или разумных мер
 по спасению имущества на море.</p><br>

<p style="text-indent:2em;">7. В тех случаях, когда вина перевозчика, его служащих или агентов сочетается с другой причиной
 в возникновении утраты, повреждения или задержки в сдаче, перевозчик несет ответственность лишь
 в той степени, в какой утрата, повреждение или задержка в сдаче обусловлены виной перевозчика,
 его служащих или агентов, при условии, что перевозчик докажет размер утраты, повреждения или
 задержки в сдаче, которые не обусловлены виной с его стороны.</p><br>

<p style="font-weight:500;">Статья 6. Пределы ответственности</p><br>

<p style="text-indent:2em;">1. a) Ответственность перевозчика за ущерб, являющийся результатом утраты или повреждения груза,
 в соответствии с положениями статьи 5 ограничивается суммой, эквивалентной 835 расчетным единицам
 за место или другую единицу отгрузки либо 2,5 расчетной единицы за один килограмм веса брутто утраченного
 или поврежденного груза в зависимости от того, какая сумма выше.</p><br>

<p style="text-indent:2em;">b) Ответственность перевозчика за задержку в сдаче в соответствии с положениями статьи 5 ограничивается
 суммой, в 2,5 раза кратной фрахту, подлежащему уплате за задержанный сдачей груз, но не превышающей
 размеры фрахта, подлежащего уплате на основании договора морской перевозки.</p><br>

<p style="text-indent:2em;">c) Ни в коем случае совокупная ответственность перевозчика на основании обоих подпунктов "a" и "b" 
настоящего пункта не превышает ограничения, которое было бы установлено согласно подпункту "a" настоящего
 пункта за полную утрату груза, в отношении которого возникла такая ответственность.</p>

<p style="text-indent:2em;"></p><h6>2. Для целей исчисления более высокой суммы в соответствии с пунктом 1 "a" настоящей статьи применяются
 следующие правила:</h6><p></p>

<p style="text-indent:2em;">a) когда для объединения товаров используется контейнер, паллет или подобное приспособление для
 транспортировки, места или другие единицы отгрузки, перечисленные в коносаменте, если таковой выдан, 
 или же в любом другом документе, подтверждающем договор морской перевозки, как упакованные в таком 
 приспособлении для транспортировки, рассматриваются как места или единицы отгрузки. За указанным выше 
 исключением грузы в таком приспособлении для транспортировки рассматриваются как одна единица отгрузки;</p><br>

<p style="text-indent:2em;">b) в тех случаях, когда утрачено или повреждено само приспособление для транспортировки, это приспособление
 для транспортировки, если оно не является собственностью перевозчика или не предоставлено им, 
 рассматривается как отдельная единица отгрузки.</p><br>

<p>3. Расчетная единица означает расчетную единицу, указанную в статье 26.</p><br>

<p style="text-indent:2em;">4. По соглашению между перевозчиком и грузоотправителем могут быть установлены пределы ответственности,
 превышающие предусмотренные в пункте 1.</p><br>

<p style="font-weight:500;">Статья 7. Применение в отношении внедоговорных требований</p><br>

<p style="text-indent:2em;">1. Возражения ответчика и пределы ответственности, предусмотренные в настоящей Конвенции, применяются 
при любом иске к перевозчику по поводу утраты или повреждения груза, на который распространяется договор 
морской перевозки, равно как и по поводу задержки в сдаче независимо от того, основан этот иск на договоре,
 деликте или ином правовом основании.</p><br>

<p style="text-indent:2em;">2. Если такой иск предъявлен к служащему или агенту перевозчика, такой служащий или агент, доказав, что 
он действовал в пределах своих служебных обязанностей, имеет право воспользоваться возражениями ответчика 
и пределами ответственности, на которые согласно настоящей Конвенции вправе ссылаться сам перевозчик.</p><br>

<p style="text-indent:2em;">3. За исключением того, что предусмотрено в статье 8, суммы, которые могут быть взысканы с перевозчика и
 любых лиц, упомянутых в пункте 2 настоящей статьи, не превышают в совокупности пределы ответственности, 
 предусмотренные в настоящей Конвенции.</p><br>

<p style="font-weight:500;">Статья 8. Утрата права на ограничение ответственности</p><br>

<p style="text-indent:2em;">1. Перевозчик не имеет права на ограничение ответственности, предусмотренное в статье 6, если доказано, 
что утрата, повреждение или задержка в сдаче явились результатом действия или упущения перевозчика, совершенных
 либо с намерением причинить такую утрату, повреждение или задержку, либо вследствие безответственности и с
 пониманием вероятности возникновения такой утраты, повреждения или задержки.</p><br>

<p style="text-indent:2em;">2. Несмотря на положения пункта 2 статьи 7, служащий или агент перевозчика не имеет права на ограничение ответственности,
 предусмотренное в статье 6, если доказано, что утрата, повреждение или задержка в сдаче явились результатом действия или
 упущения такого служащего или агента, совершенных либо с намерением причинить такую утрату, повреждение или задержку,
 либо вследствие безответственности и с пониманием вероятности возникновения такой утраты, повреждения или задержки.</p><br>

<p style="font-weight:500;">Статья 9. Палубный груз</p><br>

<p style="text-indent:2em;">1. Перевозчик имеет право перевозить груз на палубе только в том случае, если такая перевозка соответствует соглашению 
с грузоотправителем или обычаю данной отрасли торговли либо требуется по законодательным нормам или правилам.</p><br>

<p style="text-indent:2em;">2. Если перевозчик и грузоотправитель договорились о том, что груз должен или может перевозиться на палубе, перевозчик
 обязан включить указание об этом в коносамент или другой документ, подтверждающий договор морской перевозки. 
 При отсутствии подобного указания перевозчик несет бремя доказывания того, что соглашение о перевозке груза на 
 палубе было заключено; однако, перевозчик не имеет права ссылаться на такое соглашение в отношении третьей стороны,
 включая грузополучателя, которая добросовестно приобрела коносамент.</p><br>

<p style="text-indent:2em;">3. В тех случаях, когда груз перевозился на палубе вопреки положениям пункта 1 настоящей статьи или когда перевозчик 
не может в соответствии с пунктом 2 настоящей статьи ссылаться на соглашение о перевозке груза на палубе, перевозчик,
 несмотря на положения пункта 1 статьи 5, несет ответственность за утрату или повреждение груза, а также за задержку
 в сдаче, вызванные исключительно перевозкой груза на палубе, и предел его ответственности определяется в соответствии
 с положениями статьи 6 или статьи 8 настоящей Конвенции в зависимости от обстоятельств.</p><br>

<p style="text-indent:2em;">4. Перевозка груза на палубе вопреки прямому соглашению о перевозке в трюме рассматривается как действие или упущение 
перевозчика в смысле статьи 8.</p><br>

<p style="font-weight:500;">Статья 10. Ответственность перевозчика и фактического перевозчика</p><br>

<p style="text-indent:2em;">1. В тех случаях, когда осуществление перевозки или части ее поручено фактическому перевозчику, даже если это допустимо
 по условиям договора морской перевозки, перевозчик тем не менее остается ответственным за всю перевозку в соответствии
 с положениями настоящей Конвенции. В отношении перевозки, осуществленной фактическим перевозчиком, перевозчик несет 
 ответственность за действия и упущения фактического перевозчика и его служащих и агентов, действовавших в пределах своих
 служебных обязанностей.</p><br>

<p style="text-indent:2em;">2. Все положения настоящей Конвенции, регулирующей ответственность перевозчика, применяются также в отношении ответственности
 фактического перевозчика за осуществленную им перевозку. Положения пунктов 2 и 3 статьи 7 и пункта 2 статьи 8 применимы в
 случае предъявления иска к служащему или агенту фактического перевозчика.</p><br>

<p style="text-indent:2em;">3. Любое специальное соглашение, в соответствии с которым перевозчик принимает на себя обязательства, не предусмотренные
 настоящей Конвенцией, или отказывается от прав, предоставляемых настоящей Конвенцией, распространяется на фактического 
 перевозчика только в том случае, если он с этим согласился прямо выраженным образом и в письменной форме. Независимо от
 наличия или отсутствия такого согласия фактического перевозчика перевозчик тем не менее остается связанным обязательствами
 или отказом от прав, вытекающими из такого специального соглашения.</p><br>

<p style="text-indent:2em;">4. В тех случаях и в той мере, в которых и перевозчик и фактический перевозчик несут ответственность, их ответственность
 является солидарной.</p><br>

<p style="text-indent:2em;">5. Суммы, которые могут быть взысканы с перевозчика, фактического перевозчика и их служащих и агентов, не должны в 
совокупности превышать пределы ответственности, предусмотренные настоящей Конвенцией.</p><br>

<p style="text-indent:2em;">6. Ничто в настоящей статье не умаляет какого бы то ни было права обратного требования в отношениях между перевозчиком 
и фактическим перевозчиком.</p><br>

<p style="font-weight:500;">Статья 11. Сквозная перевозка</p><br>

<p style="text-indent:2em;">1. Несмотря на положения пункта 1 статьи 10, в тех случаях, когда в договоре морской перевозки прямо предусматривается,
 что конкретная часть перевозки, охватываемой указанным договором, должна осуществляться не перевозчиком, 
 а другим определенным лицом, в договоре может быть также предусмотрено, что перевозчик не несет ответственности
 за утрату, повреждение или задержку в сдаче, вызванные обстоятельствами, имевшими место в то время, когда груз
 находился в ведении фактического перевозчика в течение такой части перевозки. Тем не менее любое условие, ограничивающее
 или исключающее такую ответственность, не имеет силы, если судебное разбирательство против фактического перевозчика не 
 может быть возбуждено в каком-либо суде, компетентном на основании пункта 1 или 2 статьи 21. Бремя доказывания того, что 
 утрата, повреждение или задержка в сдаче были вызваны таким обстоятельством, лежит на перевозчике.</p><br>

<p style="text-indent:2em;">2. Фактический перевозчик несет ответственность в соответствии с положениями пункта 2 статьи 10 за утрату, повреждение или
 задержку в сдаче, вызванные обстоятельствами, которые имели место во время нахождения груза в его ведении.</p>

<p></p><h4>ЧАСТЬ III. ОТВЕТСТВЕННОСТЬ ГРУЗООТПРАВИТЕЛЯ</h4><p></p>

<p style="font-weight:500;">Статья 12. Общее правило</p><br>

<p style="text-indent:2em;">Грузоотправитель не отвечает за ущерб, понесенный перевозчиком или фактическим перевозчиком, или за повреждение,
 полученное судном, если только такой ущерб или повреждение не произошли по вине грузоотправителя, его служащих или
 агентов. Служащий или агент грузоотправителя также не несет ответственности за такой ущерб или повреждение, если
 только ущерб или повреждение не были вызваны виной с его стороны.</p><br>

<p style="font-weight:500;">Статья 13. Специальные правила об опасном грузе</p><br>

<p>1. Грузоотправитель должен замаркировать или обозначить подходящим способом опасный груз как опасный.</p>

<p style="text-indent:2em;"></p><h6>2. Когда грузоотправитель передает опасный груз перевозчику или фактическому перевозчику, в зависимости от обстоятельств,
 грузоотправитель должен проинформировать его об опасном характере груза и, если необходимо, о мерах предосторожности,
 которые следует принять. Если грузоотправитель не сделает этого, а такой перевозчик или такой фактический перевозчик 
 иным образом не осведомлены об опасном характере груза:</h6><p></p>

<p style="text-indent:2em;">a) грузоотправитель несет ответственность перед перевозчиком и любым фактическим перевозчиком за ущерб, возникший в
 результате отгрузки такого груза; и</p><br>

<p style="text-indent:2em;">b) груз может быть в любое время выгружен, уничтожен или обезврежен, как того могут потребовать обстоятельства, без 
уплаты компенсации.</p><br>

<p style="text-indent:2em;">3. Ни одно лицо не может ссылаться на положения пункта 2 настоящей статьи, если во время перевозки оно приняло груз в 
свое ведение, зная о его опасном характере.</p><br>

<p style="text-indent:2em;">4. Если - в случаях, когда положения подпункта "b" пункта 2 настоящей статьи неприменимы или на них нельзя ссылаться, 
- опасный груз становится фактически опасным для жизни или имущества, он может быть выгружен, уничтожен или обезврежен,
 как того могут потребовать обстоятельства, без уплаты компенсации, кроме как в силу обязательства по участию в покрытии
 убытков по общей аварии или когда перевозчик несет ответственность в соответствии с положениями статьи 5.</p>

<p></p><h4>ЧАСТЬ IV. ТРАНСПОРТНЫЕ ДОКУМЕНТЫ</h4><p></p>

<p style="font-weight:500;">Статья 14. Выдача коносамента</p><br>

<p style="text-indent:2em;">1. Когда перевозчик или фактический перевозчик принимает груз в свое ведение, перевозчик по требованию грузоотправителя 
обязан выдать грузоотправителю коносамент.</p><br>

<p style="text-indent:2em;">2. Коносамент может быть подписан лицом, имеющим полномочия от перевозчика. Коносамент, подписанный капитаном судна, на
 котором перевозится груз, считается подписанным от имени перевозчика.</p><br>

<p style="text-indent:2em;">3. Подпись на коносаменте может быть сделана от руки, напечатана в виде факсимиле, перфорирована, поставлена с помощью 
штампа, в виде символов или с помощью любых иных механических или электронных средств, если это не противоречит закону 
страны, в которой выдается коносамент.</p><br>

<p style="font-weight:500;">Статья 15. Содержание коносамента</p>

<p style="text-indent:2em;"></p><h6>1. В коносамент должны быть включены, в частности, следующие данные:</h6><p></p>

<p style="text-indent:2em;">a) общий характер груза, основные марки, необходимые для идентификации груза, прямое указание - в соответствующих случаях 
- относительно опасного характера груза, число мест или предметов и вес груза или его количество, обозначенное иным образом,
 причем все эти данные указываются так, как они представлены грузоотправителем;</p><br>

<p>b) внешнее состояние груза;</p><br>

<p>c) наименование перевозчика и местонахождение его основного коммерческого предприятия;</p><br>

<p>d) наименование грузоотправителя;</p><br>

<p>e) наименование грузополучателя, если он указан грузоотправителем;</p><br>

<p>f) порт погрузки согласно договору морской перевозки и дата приема груза перевозчиком в порту погрузки;</p><br>

<p>g) порт разгрузки согласно договору морской перевозки;</p><br>

<p>h) число оригиналов коносамента, если их больше одного;</p><br>

<p>i) место выдачи коносамента;</p><br>

<p>j) подпись перевозчика или лица, действующего от его имени;</p><br>

<p style="text-indent:2em;">k) фрахт в размере, подлежащем уплате грузополучателем, или иное указание на то, что фрахт должен уплачиваться им;</p><br>

<p>l) указание, упомянутое в пункте 3 статьи 23;</p><br>

<p>m) указание - в соответствующих случаях - о том, что груз должен или может перевозиться на палубе;</p><br>

<p>n) дата или период сдачи груза в порту разгрузки, если они прямо согласованы сторонами; и</p><br>

<p style="text-indent:2em;">o) повышенный предел или пределы ответственности, если таковые согласованы в соответствии с пунктом 4 статьи 6.</p><br>

<p style="text-indent:2em;">2. После того, как груз погружен на борт, перевозчик, если того потребует грузоотправитель, должен выдать грузоотправителю 
"бортовой" коносамент, в котором в дополнение к данным, требуемым согласно пункту 1 настоящей статьи, должно быть указано, 
что груз находится на борту определенного судна или судов, а также должна быть указана дата или даты погрузки. Если перевозчик
 ранее выдал грузоотправителю коносамент или иной товарораспорядительный документ, относящийся к данному грузу, то по просьбе 
 перевозчика грузоотправитель должен возвратить такой документ в обмен на "бортовой" коносамент. Перевозчик может удовлетворить
 требование грузоотправителя в отношении "бортового" коносамента путем дополнения любого ранее выданного документа при условии,
 что дополненный таким образом документ включает все данные, которые должны содержаться в "бортовом" коносаменте.</p><br>

<p style="text-indent:2em;">3. Отсутствие в коносаменте каких-либо данных, упомянутых в настоящей статье, не влияет на юридический характер документа как
 коносамента при условии, однако, что он удовлетворяет требованиям, содержащимся в пункте 7 статьи 1.</p><br>

<p style="font-weight:500;">Статья 16. Коносаменты: оговорки и доказательственная сила</p><br>

<p style="text-indent:2em;">1. Если в коносаменте содержатся данные, касающиеся общего характера, основных марок, числа мест или предметов, веса или количества
 груза, в отношении которых перевозчик или другое лицо, выдающее коносамент от его имени, знает или имеет достаточные основания
 подозревать, что они не точно соответствуют фактически принятому, а в случае выдачи "бортового" коносамента - погруженному грузу,
 либо если у него не было разумной возможности проверить такие данные, перевозчик или такое другое лицо должен внести в коносамент
 оговорку, конкретно указывающую на эти неточности, основания для подозрений или отсутствие разумной возможности проверки.</p><br>

<p style="text-indent:2em;">2. Если перевозчик или другое лицо, выдающее коносамент от его имени, не указывает в коносаменте внешнее состояние груза, считается,
 что он указал в коносаменте, что внешнее состояние груза хорошее.</p>

<p style="text-indent:2em;"></p><h6>3. За исключением данных, в отношении которых и в той мере, в какой была внесена оговорка, допустимая согласно пункту 1
 настоящей статьи:</h6><p></p>

<p style="text-indent:2em;">a) коносамент является доказательством prima facie приема, а в случае выдачи "бортового" коносамента - погрузки перевозчиком
 груза, как он описан в коносаменте; и</p><br>

<p style="text-indent:2em;">b) доказывание перевозчиком противного не допускается, если коносамент передан третьей стороне, включая грузополучателя,
 которая действовала добросовестно, полагаясь на содержащееся в коносаменте описание груза.</p><br>

<p style="text-indent:2em;">4. Коносамент, в который не включено, как это предусмотрено в подпункте "k" пункта 1 статьи 15, указание фрахта или иное указание на
 то, что фрахт должен уплачиваться грузополучателем, или не указан возникший в порту погрузки демередж, подлежащий оплате грузополучателем,
 является доказательством prima facie того, что никакого фрахта или демереджа с него не причитается. Однако доказывание перевозчиком противного
 не допускается, если коносамент передан третьей стороне, включая грузополучателя, которая действовала добросовестно, полагаясь на отсутствие
 в коносаменте любого такого указания.</p><br>

<p style="font-weight:500;">Статья 17. Гарантии грузоотправителя</p><br>

<p style="text-indent:2em;">1. Считается, что грузоотправитель гарантировал перевозчику точность представленных им для включения в коносамент данных относительного
 общего характера груза, его марок, числа мест, веса и количества. Грузоотправитель должен возместить перевозчику ущерб, явившийся результатом
 неточности этих данных. Грузоотправитель остается ответственным, даже если коносамент был им передан. Право перевозчика на такое возмещение 
 никоим образом не ограничивает его ответственность по договору морской перевозки перед любым иным, чем грузоотправитель, 
 лицом.</p><br>

<p style="text-indent:2em;">2. Любое гарантийное письмо или соглашение, по которому грузоотправитель обязуется возместить перевозчику ущерб, вытекающий из того, что 
перевозчик или лицо, действующее от его имени, выдадут коносамент без оговорок относительно данных, представленных грузоотправителем для
 включения в коносамент, либо относительно внешнего состояния груза, является недействительным в отношении любой третьей стороны, включая 
 грузополучателя, которой был передан данный коносамент.</p><br>

<p style="text-indent:2em;">3. Такое гарантийное письмо или соглашение является действительным в отношении грузоотправителя, если только перевозчик или лицо,
 действующее от его имени, не делая оговорку, упомянутую в пункте 2 настоящей статьи, не имеет намерения обмануть третью сторону,
 включая грузополучателя, которая действует, полагаясь на описание груза, содержащееся в коносаменте. Если в последнем случае
 несделанная оговорка касается данных, представленных грузоотправителем для включения в коносамент, перевозчик не имеет права 
 требовать от грузоотправителя возмещения ущерба в соответствии с пунктом 1 настоящей статьи.</p><br>

<p style="text-indent:2em;">4. В случае преднамеренного обмана, указанном в пункте 3 настоящей статьи, перевозчик несет ответственность, не пользуясь правом 
на ограничение ответственности, предусмотренное настоящей Конвенцией, за ущерб, понесенный третьей стороной, включая грузополучателя, 
из-за того, что она действовала, полагаясь на описание груза, содержащееся в коносаменте.</p><br>

<p style="font-weight:500;">Статья 18. Другие документы, помимо коносаментов</p><br>

<p style="text-indent:2em;">Когда перевозчик выдает иной, чем коносамент, документ в подтверждение получения груза для перевозки, такой документ является
 доказательством prima facie заключения договора морской перевозки и приема перевозчиком груза, как он описан в
 этом документе.</p>

<p></p><h4>ЧАСТЬ V. ТРЕБОВАНИЯ И ИСКИ</h4><p></p>

<p style="font-weight:500;">Статья 19. Уведомление об утрате, повреждении или задержке</p><br>

<p style="text-indent:2em;">1. Если уведомление об утрате или повреждении, конкретно указывающее на общий характер такой утраты или повреждения, не сделано
 в письменной форме грузополучателем перевозчику не позднее рабочего дня, следующего за днем передачи груза грузополучателю,
 такая передача является доказательством prima facie сдачи перевозчиком груза в соответствии с описанием в транспортном документе 
 или, если такой документ не выдавался, в хорошем состоянии.</p><br>

<p style="text-indent:2em;">2. Если утрата или повреждение не являются очевидными, положения пункта 1 настоящей статьи применяются соответственно, если уведомление
 в письменной форме не сделано в течение 15 календарных дней после дня передачи груза грузополучателю.</p><br>

<p style="text-indent:2em;">3. Если состояние груза при его передаче было предметом совместного осмотра или проверки сторонами, уведомление в письменной 
форме не требуется в отношении утраты или повреждения, установленных во время такого осмотра или проверки.</p><br>

<p style="text-indent:2em;">4. В случае какой-либо фактической или предполагаемой утраты или повреждения перевозчик и грузополучатель предоставляют друг 
другу все разумные возможности для проверки и счета груза.</p><br>

<p style="text-indent:2em;">5. Никакая компенсация не подлежит уплате за ущерб, вызванный задержкой в сдаче, если уведомление в письменной форме не было 
сделано перевозчику в течение 60 календарных дней после дня передачи груза грузополучателю.</p><br>

<p style="text-indent:2em;">6. Если груз был сдан фактическим перевозчиком, любое уведомление, сделанное ему на основании настоящей статьи, имеет ту же силу
, как если бы оно было сделано перевозчику, и любое уведомление, сделанное перевозчику, имеет силу, как если бы оно было сделано
 такому фактическому перевозчику.</p><br>

<p style="text-indent:2em;">7. Если уведомление об ущербе или повреждении, конкретно указывающее на общий характер этого ущерба или повреждения, не сделано
 в письменной форме перевозчиком или фактическим перевозчиком грузоотправителю не позднее 90 календарных дней после возникновения
 такого ущерба или повреждения или после сдачи груза в соответствии с пунктом 2 статьи 4, в зависимости от того, что имеет место 
 позже, отсутствие уведомления является доказательством prima facie того, что перевозчик или фактический перевозчик не понес никакого
 ущерба или повреждения по вине грузоотправителя, его служащих или агентов.</p><br>

<p style="text-indent:2em;">8. Для целей настоящей статьи уведомление, которое сделано действующему от имени перевозчика или фактического перевозчика лицу,
 включая капитана или полномочного члена командного состава судна, либо лицу, действующему от имени грузоотправителя, считается 
 сделанным соответственно перевозчику, фактическому перевозчику или грузоотправителю.</p><br>

<p style="font-weight:500;">Статья 20. Исковая давность</p><br>

<p style="text-indent:2em;">1. Любой иск в связи с перевозкой грузов по настоящей Конвенции погашается давностью, если судебное или арбитражное разбирательство
 не было начато в течение двух лет.</p><br>

<p style="text-indent:2em;">2. Срок исковой давности начинается в день, когда перевозчик сдал груз или часть груза, или в случаях, если груз вообще не был 
сдан, - в последний день, когда груз должен был быть сдан.</p><br>

<p>3. День, когда начинается срок исковой давности, не включается в этот срок.</p><br>

<p style="text-indent:2em;">4. Лицо, к которому предъявлено требование, может в любое время в течение срока исковой давности продлить этот срок путем заявления,
 сделанного в письменной форме заявителю требования. Этот срок может быть продлен путем другого заявления или заявлений.</p><br>

<p style="text-indent:2em;">5. Иск о возмещении может быть предъявлен любым лицом, признанным ответственным, даже по истечении срока исковой давности,
 предусмотренного в предыдущих пунктах, если он предъявляется в пределах срока, предоставленного по закону государства, в
 котором возбуждено разбирательство. Однако этот предоставляемый срок должен быть не менее 90 дней, начиная со дня, когда лицо,
 предъявляющее иск о возмещении, оплатило требование или получило повестку о возбуждении процесса против него самого.</p><br>

<p style="font-weight:500;">Статья 21. Юрисдикция</p>

<p style="text-indent:2em;"></p><h6>1. В случае судебного разбирательства, связанного с перевозкой груза на основании настоящей Конвенции, истец по своему выбору
 может предъявить иск в суде, который является компетентным в соответствии с законом государства суда и в пределах юрисдикции 
 которого находится одно из следующих мест:</h6><p></p>

<p>a) основное коммерческое предприятие или, при отсутствии такового, постоянное местожительство ответчика; или</p><br>

<p style="text-indent:2em;">b) место заключения договора при условии, что ответчик имеет там коммерческое предприятие, отделение или агентство, через 
посредство которого был заключен договор; или</p><br>

<p>c) порт погрузки или порт разгрузки; или</p><br>

<p>d) любое дополнительное место, указанное с этой целью в договоре морской перевозки.</p><br>

<p style="text-indent:2em;">2. a) Несмотря на предыдущие положения настоящей статьи, иск может быть предъявлен в судах любого порта или места в 
Договаривающемся государстве, где перевозившее груз судно или любое другое судно того же собственника может оказаться
 арестованным в соответствии с применяемыми нормами права этого государства и международного права. Однако в таком случае 
 по ходатайству ответчика лицо, заявляющее требование, должно перенести иск по своему выбору в один из судов, указанных в пункте 1
 настоящей статьи, для рассмотрения этого требования по существу; однако, до такого переноса иска ответчик должен предоставить гарантии,
 достаточные для обеспечения оплаты судебного решения, которое может быть впоследствии вынесено в пользу лица, заявляющего требование,
 по этому иску.</p><br>

<p style="text-indent:2em;">b) Все вопросы, касающиеся достаточности или других аспектов обеспечения, определяются судом порта или места ареста.</p><br>

<p style="text-indent:2em;">3. Никакого судебного разбирательства, связанного с перевозкой груза на основании настоящей Конвенции, не может быть возбуждено в
 каком-либо месте, которое не указано в пункте 1 или 2 настоящей статьи. Положения настоящего пункта не препятствуют осуществлению 
 юрисдикции Договаривающихся государств в отношении мер предварительного или обеспечительного характера.</p><br>

<p style="text-indent:2em;">4. a) В тех случаях, когда иск предъявлен в суде, являющемся компетентным в соответствии с пунктом 1 или 2 настоящей статьи, или 
когда таким судом вынесено решение, новый иск между теми же сторонами по тому же основанию не допускается, кроме тех случаев, когда
 решение суда, в котором был предъявлен первый иск, не подлежит приведению в исполнение в стране, в которой возбуждается новое
 разбирательство.</p><br>

<p style="text-indent:2em;">b) Для целей настоящей статьи принятие мер по приведению в исполнение судебного решения не считается предъявлением 
нового иска.</p><br>

<p style="text-indent:2em;">c) Для целей настоящей статьи перенос иска в другой суд в пределах той же страны или в суд в другой стране в соответствии с пунктом
 2 "a" настоящей статьи не считается предъявлением нового иска.</p><br>

<p style="text-indent:2em;">5. Несмотря на положения предыдущих пунктов, имеет силу любое соглашение, которое заключено сторонами после возникновения требования
, вытекающего из договора морской перевозки, и в котором определяется место, где лицо, заявляющее 
требование, может предъявить иск.</p><br>

<p style="font-weight:500;">Статья 22. Арбитражное разбирательство</p><br>

<p style="text-indent:2em;">1. При условии соблюдения положений настоящей статьи стороны могут предусмотреть путем соглашения, подтвержденного в письменной форме,
 что любой спор, который может возникнуть в связи с перевозкой груза на основании настоящей Конвенции, подлежит 
 передаче в арбитраж.</p><br>

<p style="text-indent:2em;">2. Когда в чартере содержится положение о том, что связанные с ним споры подлежат передаче в арбитраж, а в коносаменте, выданном 
согласно чартеру, не содержится специальной пометки о том, что такое положение является обязательным для держателя коносамента, 
перевозчик не может использовать такое положение против держателя, добросовестно приобретшего этот коносамент.</p>

<p style="text-indent:2em;"></p><h6>3. Арбитражное разбирательство по выбору лица, заявляющего требование, проводится 
в одном из следующих мест:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) в каком-либо месте государства, на территории которого находится:</h6><p></p>

<p style="text-indent:2em;">I) основное коммерческое предприятие ответчика или, при отсутствии такового, постоянное местожительство ответчика; или</p><br>

<p style="text-indent:2em;">II) место заключения договора при условии, что ответчик имеет там коммерческое предприятие, отделение или агентство, через которое
 был заключен договор; или</p><br>

<p>III) порт погрузки или порт разгрузки; или</p><br>

<p>b) любое место, указанное с этой целью в арбитражной оговорке или в соглашении.</p><br>

<p>4. Арбитр или арбитражный суд обязаны применять правила настоящей Конвенции.</p><br>

<p style="text-indent:2em;">5. Положения пунктов 3 и 4 настоящей статьи считаются составной частью любой арбитражной оговорки или соглашения, и любое условие 
такой оговорки или соглашения, несовместимое с этими положениями, является ничтожным.</p><br>

<p style="text-indent:2em;">6. Ничто в настоящей статье не затрагивает действительности соглашения об арбитраже, заключенного сторонами после возникновения 
требования из договора морской перевозки.</p>

<p></p><h4>ЧАСТЬ VI. ДОПОЛНИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 23. Договорные условия</p><br>

<p style="text-indent:2em;">1. Любое условие в договоре морской перевозки или коносаменте, или любом другом документе, подтверждающем договор морской 
перевозки, является ничтожным в той степени, в какой оно противоречит прямо или косвенно положениям настоящей Конвенции. Недействительность
 такого условия не затрагивает действительность других положений договора или документа, частью которого оно является. Оговорка о передаче 
 в пользу перевозчика прав страхования груза или любая аналогичная оговорка являются ничтожными.</p><br>

<p style="text-indent:2em;">2. Несмотря на положения пункта 1 настоящей статьи, перевозчик может увеличить свою ответственность и обязательства по настоящей 
Конвенции.</p><br>

<p style="text-indent:2em;">3. В тех случаях, когда выдается коносамент или любой другой документ, подтверждающий договор морской перевозки, он должен включать 
указание о том, что перевозка регулируется положениями настоящей Конвенции, которые лишают юридической силы любое условие, противоречащее 
этим положениям в ущерб грузоотправителю или грузополучателю.</p><br>

<p style="text-indent:2em;">4. В тех случаях, когда лицо, заявляющее требование по поводу груза, понесло ущерб в связи с условием, являющимся в силу настоящей статьи
 ничтожным, или в результате отсутствия упомянутого в пункте 3 настоящей статьи указания, перевозчик должен выплатить компенсацию в требуемых 
 размерах с тем, чтобы возместить этому лицу в соответствии с положениями настоящей Конвенции любую утрату или повреждение груза, а также задержку
 в сдаче. Перевозчик должен, кроме того, выплатить компенсацию за издержки, понесенные этим лицом в целях осуществления 
 своего права, при условии, что издержки, понесенные в связи с иском на основе вышеизложенного положения, 
 определяются в соответствии с законом государства, в котором возбуждено разбирательство.</p><br>

<p style="font-weight:500;">Статья 24. Общая авария</p><br>

<p style="text-indent:2em;">1. Ничто в настоящей Конвенции не препятствует применению положений договора морской перевозки или национального права,
 касающихся распределения убытков по общей аварии.</p><br>

<p style="text-indent:2em;">2. За исключением статьи 20 положения настоящей Конвенции, касающиеся ответственности перевозчика за утрату или повреждение груза,
 определяют также, может ли грузополучатель отказаться от уплаты взноса по общей аварии и обязанность перевозчика
 компенсировать грузополучателю оплату им такого взноса или расходов по спасанию.</p><br>

<p style="font-weight:500;">Статья 25. Другие конвенции</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция не меняет прав или обязанностей перевозчика, фактического перевозчика и их служащих и агентов, предусмотренных
 в международных конвенциях или национальном законодательстве по вопросам ограничения ответственности
 владельцев морских судов.</p><br>

<p style="text-indent:2em;">2. Положения статей 21 и 22 настоящей Конвенции не препятствуют применению императивных положений какой-либо другой
 многосторонней конвенции, действующей на дату настоящей Конвенции, которые касаются вопросов, регулируемых
 в указанных статьях, при условии, что спор возникает исключительно между сторонами, имеющими
 свои основные коммерческие предприятия в государствах - участниках такой другой конвенции.
 Однако настоящий пункт не затрагивает применение пункта 4 статьи 22 настоящей Конвенции.</p>

<p style="text-indent:2em;"></p><h6>3. На основании положений настоящей Конвенции не возникает никакой ответственности за ущерб, причиненный
 ядерным инцидентом, если оператор ядерной установки несет ответственность за такой ущерб:</h6><p></p>

<p style="text-indent:2em;">a) в соответствии либо с Парижской конвенцией от 29 июля 1960 года об ответственности третьих лиц в
 области ядерной энергии с поправками к ней, содержащимися в Дополнительном протоколе
 от 28 января 1964 года, либо в соответствии с Венской конвенцией
 от 21 мая 1963 года о гражданской ответственности за ядерный ущерб; или</p><br>

<p style="text-indent:2em;">b) в соответствии с национальным законодательством, регулирующим ответственность за такой ущерб, при условии,
 что такое законодательство во всех отношениях является таким же благоприятным для 
 лиц, которые могут понести ущерб, как и Парижская или Венская конвенции.</p><br>

<p style="text-indent:2em;">4. На основании положений настоящей Конвенции не возникает никакой ответственности за утрату, повреждение
 или задержку в сдаче багажа, за которые перевозчик отвечает согласно какой-либо международной
 конвенции или национальному законодательству, касающимся морской перевозки
 пассажиров и их багажа.</p><br>

<p style="text-indent:2em;">5. Ничто в настоящей Конвенции не препятствует Договаривающимся государствам применять какую-либо
 другую международную конвенцию, которая уже действует на дату настоящей Конвенции
 и которая применяется императивным образом к договорам перевозки груза
 главным образом не морским транспортом. Настоящее положение
 применяется также в отношении любого
 последующего пересмотра или изменения
 такой международной конвенции.</p><br>

<p style="font-weight:500;">Статья 26. Расчетная единица</p><br>

<p style="text-indent:2em;">1. Расчетная единица, упоминаемая в статье 6 настоящей Конвенции, является единицей "специального
 права заимствования", как она определена Международным валютным фондом. Суммы,
 указанные в статье 6, переводятся в национальную валюту государства в 
 соответствии со стоимостью этой валюты на дату судебного решения или на дату,
 согласованную сторонами. Стоимость в единицах "специального права
 заимствования" национальной валюты Договаривающегося государства,
 которое является членом Международного валютного фонда, исчисляется
 в соответствии с методом определения стоимости, применяемым
 Международным валютным фондом на соответствующую дату для его
 собственных операций и расчетов. Стоимость в единицах "специального
 права заимствования" национальной валюты Договаривающегося
 государства, которое не является членом Международного
 фонда, исчисляется способом, установленным
 этим государством.</p>

<p style="text-indent:2em;"></p><h6>2. Тем не менее государства, которые не являются членами Международного валютного фонда и
 законодательство которых не позволяет применять положения пункта 1 настоящей статьи, 
 могут во время подписания либо во время ратификации, принятия, 
 утверждения или присоединения, либо в любое время после этого 
 заявить, что пределы ответственности, предусмотренные в 
 настоящей Конвенции и применяемые на их территории, 
 устанавливаются в размере:</h6><p></p>

<p style="text-indent:2em;">12 500 валютных единиц за место или другую единицу отгрузки или 37,5 
валютных единиц за один килограмм веса брутто груза.</p><br>

<p style="text-indent:2em;">3. Валютная единица, упомянутая в пункте 2 настоящей статьи, соответствует 
шестидесяти пяти с половиной миллиграммам золота пробы девятьсот тысячных. 
Перевод в национальную валюту сумм, упомянутых в пункте 2, 
осуществляется согласно законодательству соответствующего 
государства.</p><br>

<p style="text-indent:2em;">4. Исчисление, упомянутое в последней фразе пункта 1, и перевод, 
упомянутый в пункте 3 настоящей статьи, осуществляются таким образом, 
чтобы выразить в национальной валюте Договаривающегося государства, 
насколько это возможно, ту же реальную ценность сумм, указанных в 
статье 6, какая выражена в этой статье в расчетных единицах. 
Договаривающиеся государства сообщают депозитарию способ исчисления 
согласно пункту 1 настоящей статьи или в соответствующем случае
 результат перевода, упомянутый в пункте 3 настоящей статьи, во 
 время подписания или при сдаче на хранение своих ратификационных 
 грамот, документов о принятии, утверждении или присоединении, 
 либо при использовании права выбора, предусмотренного в пункте 
 2 настоящей статьи, и затем каждый раз, когда происходит изменение 
 в этом способе или в результатах перевода.</p>

<p></p><h4>ЧАСТЬ VII. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 27. Депозитарий</p><br>

<p style="text-indent:2em;">Генеральный секретарь Организации Объединенных Наций настоящим назначается
 депозитарием настоящей Конвенции.</p><br>

<p style="font-weight:500;">Статья 28. Подписание, ратификация, принятие, утверждение, присоединение</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция открыта для подписания всеми государствами 
до 30 апреля 1979 года в Центральных учреждениях Организации 
Объединенных Наций, Нью-Йорк.</p><br>

<p>2. Настоящая Конвенция подлежит ратификации, принятию или утверждению
 подписавшими ее государствами.</p><br>

<p style="text-indent:2em;">3. После 30 апреля 1979 года настоящая Конвенция будет открыта 
для присоединения для всех не подписавших ее государств.</p><br>

<p style="text-indent:2em;">4. Ратификационные грамоты, документы о принятии, утверждении
 и присоединении сдаются на хранение Генеральному секретарю 
 Организации Объединенных Наций.</p><br>

<p style="font-weight:500;">Статья 29. Оговорки</p><br>

<p>Никакие оговорки не могут быть сделаны к настоящей Конвенции.</p><br>

<p style="font-weight:500;">Статья 30. Вступление в силу</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция вступает в силу с первого дня месяца
 по истечении одного года после даты сдачи на хранение 
 двадцатой ратификационной грамоты или документа о принятии
 , утверждении или присоединении.</p><br>

<p style="text-indent:2em;">2. Для каждого государства, становящегося Договаривающимся 
государством настоящей Конвенции после даты сдачи на хранение
 двадцатой ратификационной грамоты или документа о принятии, 
 утверждении или присоединении, настоящая Конвенция вступает 
 в силу с первого дня месяца, следующего за истечением одного
 года после сдачи на хранение соответствующего документа от 
 имени этого государства.</p><br>

<p style="text-indent:2em;">3. Каждое Договаривающееся государство применяет положения
 настоящей Конвенции к договорам морской перевозки, заключенным
 в день или после дня вступления в силу настоящей Конвенции в 
 отношении этого государства.</p><br>

<p style="font-weight:500;">Статья 31. Денонсация других конвенций</p><br>

<p style="text-indent:2em;">1. Любое государство, являющееся стороной Международной конвенции для
 унификации некоторых правил, касающихся коносаментов, подписанной в 
 Брюсселе 25 августа 1924 года (Конвенция 1924 года), став 
 Договаривающимся государством настоящей Конвенции, 
 уведомляет правительство Бельгии как депозитария Конвенции
 1924 года о своей денонсации указанной Конвенции 
 заявлением о том, что эта денонсация приобретает 
 силу со дня вступления настоящей Конвенции в силу 
 в отношении данного государства.</p><br>

<p style="text-indent:2em;">2. После вступления в силу настоящей Конвенции в соответствии с пунктом 
1 статьи 30 депозитарий настоящей Конвенции уведомляет правительство Бельгии 
как депозитария Конвенции 1924 года о дате такого вступления в силу и сообщает 
список Договаривающихся государств, в отношении которых настоящая Конвенция 
вступила в силу.</p><br>

<p style="text-indent:2em;">3. Положения пунктов 1 и 2 настоящей статьи применяются соответственно в 
отношении государств - участников подписанного 23 февраля 1968 года Протокола 
о поправках в Международной конвенции для унификации некоторых правил, 
касающихся коносаментов, подписанной 25 августа 1924 года в Брюсселе.</p><br>

<p style="text-indent:2em;">4. Несмотря на статью 2 настоящей Конвенции, для целей пункта 1 настоящей 
статьи Договаривающееся государство может, если сочтет это желательным, 
отсрочить денонсацию Конвенции 1924 года и Конвенции 1924 года с внесенными 
в нее Протоколом 1968 года поправками максимально на срок в пять лет с даты 
вступления в силу настоящей Конвенции. В этом случае оно уведомляет правительство 
Бельгии о своем намерении. В течение переходного периода оно должно применять в 
отношении Договаривающихся государств исключительно настоящую Конвенцию.</p><br>

<p style="font-weight:500;">Статья 32. Пересмотр и внесение поправок</p><br>

<p style="text-indent:2em;">1. По просьбе не менее чем одной трети Договаривающихся государств настоящей 
Конвенции депозитарий созывает конференцию Договаривающихся государств для 
пересмотра ее или внесения в нее поправок.</p><br>

<p style="text-indent:2em;">2. Считается, что любая ратификационная грамота и любой документ о принятии, 
утверждении или присоединении, сданные на хранение после вступления в силу 
поправки к настоящей Конвенции, относятся к Конвенции с внесенными в нее 
поправками.</p><br>

<p style="font-weight:500;">Статья 33. Пересмотр сумм ограничения и расчетной единицы или валютной единицы</p><br>

<p style="text-indent:2em;">1. Несмотря на положения статьи 32, конференция, имеющая целью только пересмотр 
сумм, установленных в статье 6 и в пункте 2 статьи 26, или замену одной или обеих 
единиц, определенных в пунктах 1 и 3 статьи 26, другими единицами, созывается 
депозитарием в соответствии с пунктом 2 настоящей статьи. Пересмотр сумм 
производится только вследствие значительного изменения их реальной ценности.</p><br>

<p style="text-indent:2em;">2. Конференция по пересмотру должна созываться депозитарием, когда об этом 
просит не менее одной четверти Договаривающихся государств.</p><br>

<p style="text-indent:2em;">3. Любое решение конференции должно приниматься большинством в две трети 
участвующих государств. Депозитарий сообщает о поправке всем Договаривающимся 
государствам для принятия и всем государствам, подписавшим Конвенцию, 
для информации.</p><br>

<p style="text-indent:2em;">4. Любая утвержденная поправка вступает в силу с первого дня месяца по истечении 
одного года после ее принятия двумя третями Договаривающихся государств. Принятие 
осуществляется путем сдачи официального документа об этом на хранение депозитарию.</p><br>

<p style="text-indent:2em;">5. После вступления в силу поправки Договаривающееся государство, принявшее 
эту поправку, имеет право применять Конвенцию с внесенными в нее поправками 
в отношениях с Договаривающимися государствами, которые в течение шести месяцев 
после утверждения поправки не уведомили депозитария о том, что они не считают 
себя связанными этой поправкой.</p><br>

<p style="text-indent:2em;">6. Считается, что любая ратификационная грамота и любой документ о принятии, 
утверждении или присоединении, сданные на хранение после вступления в силу поправки 
к настоящей Конвенции, относятся к Конвенции с внесенными в нее поправками.</p><br>

<p style="font-weight:500;">Статья 34. Денонсация</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция может быть в любое время денонсирована Договаривающимся 
государством путем направления депозитарию письменного уведомления.</p><br>

<p style="text-indent:2em;">2. Денонсация приобретает силу с первого дня месяца по истечении одного года 
после получения депозитарием такого уведомления. Если в уведомлении указан 
более длительный срок, то денонсация приобретает силу по истечении этого срока.</p><br>

<p style="text-indent:2em;">Совершено в Гамбурге тридцать первого марта тысяча девятьсот семьдесят 
восьмого года в единственном экземпляре, тексты которого на английском, 
арабском, испанском, китайском, русском и французском языках являются равно 
аутентичными.</p><br>

<p style="text-indent:2em;">В удостоверение чего нижеподписавшиеся полномочные представители, должным 
образом на то уполномоченные правительствами своих стран, подписали настоящую 
Конвенцию.</p><br>

<p style="font-weight:500;">ПРИМЕЧАНИЯ</p><br>

<p style="text-indent:2em;">1. Согласно общей договоренности ответственность перевозчика в соответствии с
 настоящей Конвенцией основана на принципе презумпируемой вины. Это означает, 
 что, как правило, бремя доказывания возлагается на перевозчика, однако в 
 определенных случаях положения Конвенции изменяют это правило.</p><br>

<p>2. Рекомендовано именовать содержащиеся в ней правила "ГАМБУРГСКИМИ ПРАВИЛАМИ".</p><br>

<p style="text-indent:2em;">Статус Конвенции Организации Объединенных Наций о морской перевозке грузов 
1978 года (Гамбург, 31 марта 1978 года)</p><br>

<p>(по состоянию на 30 октября 2001 года)</p><br>

<p style="text-indent:2em;">Конвенция была подписана бывшей Чехословакией 6 марта 1979 года &lt;1&gt;. 28
 мая 1993 года Словацкая Республика и 2 июня 1993 года Чешская Республика 
 сдали на хранение документы о правопреемстве в отношении подписания, и Чешская 
 Республика сдала свою ратификационную грамоту 23 июня 1995 года. При ратификации
 Чешская Республика сняла упомянутое в сноске &lt;1&gt; заявление, которое было сделано
 бывшей Чехословакией.</p><br>

<p style="font-weight:500;">Заявления и оговорки</p><br>

<p style="text-indent:2em;">&lt;1&gt; При подписании Конвенции Правительство бывшей Чехословакии заявило в соответствии
 со статьей 26 о формуле пересчета сумм ответственности, о которых говорится в пункте 
 2 данной статьи, в чехословацкую валюту и о сумме пределов ответственности, которая
 будет применяться на территории Чехословакии, в чехословацкой валюте.</p><br>
 
 <p><a href="http://docs.cntd.ru/document/1902905">Источник</a></p>

</div>
`