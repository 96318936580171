import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment'
import { Field } from 'redux-form'
import FileUploadField from '../../UI/file-upload-field'
import WidgetBase from '../../shared/components/widget-base'
import CompanyInfo from './company-info'
import EmployeeListInfo from './employee-list-info'
import fetch from '../../shared/fetch'
import pass from '../../../images/pass.png'
import pass2 from '../../../images/pass2.png'
import config from '../../../config'
import Captcha from '../../captcha'
import { canDo } from '../../../store/utils'

import './styles.scss'
export default class CompanyConfirmation extends React.Component {
  state = {
    registrationAuthenticityConfirmation: false,
    employeesAuthenticityConfirmation: false,
    personalDataProcessingAgreement: false,
    constituentDocument: '',
    companyLicensesFileOne: '',
    companyLicensesFileTwo: '',
    companyLicensesFileThree: '',
    selfie: '',
    companyInfo: {},

    constituentDocumentName: '',
    companyLicensesNameFileOne: '',
    companyLicensesNameFileTwo: '',
    companyLicensesNameFileThree: '',
    selfieUrl: '',
    selfieFileName: '',
    employeesList: [],
    companyVerifiedInfo: {},
    captchaVerified: false
  }

  componentDidMount() {
    const { permissions } = this.props

    if (canDo(permissions, 'captcha', 'skip')) {
      this.setState({ captchaVerified: true })
    }
    this.getUserProfile()
  }

  fetchEmployes = (employeesStatusId, pageIndex, pageSize, companyId) => {
    fetch(`/company/employees/${companyId}/${employeesStatusId}/${pageIndex}/${pageSize}`).then(
      res => {
        if (res.ok) {
          this.setState({
            employeesList: res.employees,
          })
        }
      }
    )
  }

  componentDidUpdate(prevProps) {
    const { profile, langId } = this.props
    if (profile !== prevProps.profile) {
      const {
        userInfo: {
          company: { companyId },
        },
      } = profile
      this.setState({ folder: `company/${companyId}` })
      this.getCompanyInfo(companyId, langId)
    }
  }

  getUserProfile = () => {
    const { customUserId, getProfile, userId, langId } = this.props
    const searchUserId = customUserId || userId
    if (searchUserId) {
      getProfile(searchUserId)
    }
  }

  getCompanyInfo = (companyId, langId) => {
    fetch(`/company/item/${companyId}/${langId}`).then(res => {
      this.setState(
        {
          companyInfo: res,
        },
        () => {
          this.fetchEmployes(2, 0, 100, companyId)
          this.getCompanyVerification(companyId)
        }
      )
    })
  }

  handleChangeCheckbox = (event, name) => {
    this.setState({
      [name]: event.target.checked,
    })
  }

  setImportFileInState = (e, state, stateUrl) => {
    const file = e.target.files[0]
    this.setState({ [state]: file, [stateUrl]: file.name })
    if (state === 'selfie') {
      this.setState({
        [stateUrl]: URL.createObjectURL(e.target.files[0]),
        selfieFileName: file.name,
      })
    }
  }

  getCompanyVerification = companyId => {
    const { setCompanyVerivicationData } = this.props
    fetch(`/company/${companyId}/verification`).then(res => {
      setCompanyVerivicationData(res.data)
    })
  }

  setVerification = companyId => {
    const {
      constituentDocumentProps,
      companyLicensesNameFileOneProps,
      companyLicensesNameFileTwoProps,
      companyLicensesNameFileThreeProps,
      selfieProps,
      t
    } = this.props

    const {
      registrationAuthenticityConfirmation,
      employeesAuthenticityConfirmation,
      personalDataProcessingAgreement,
    } = this.state

    const body = JSON.stringify({
      registrationAuthenticityConfirmation,
      employeesAuthenticityConfirmation,
      personalDataProcessingAgreement,
      constituentDocument: constituentDocumentProps ? constituentDocumentProps.uuid : '',
      companyLicenses: [
        companyLicensesNameFileOneProps ? companyLicensesNameFileOneProps.uuid : '',
        companyLicensesNameFileTwoProps ? companyLicensesNameFileTwoProps.uuid : '',
        companyLicensesNameFileThreeProps ? companyLicensesNameFileThreeProps.uuid : '',
      ],
      selfie: selfieProps.uuid,
    })

    fetch(`/company/${companyId}/verification`, {
      method: 'POST',
      body,
    }).then(res => {
      if (res.ok) {
        this.getCompanyVerification(companyId)
      }
    })
  }

  renderContent = () => {
    const {
      userId,
      constituentDocumentProps,
      companyLicensesNameFileOneProps,
      companyLicensesNameFileTwoProps,
      companyLicensesNameFileThreeProps,
      selfieProps,
      permissions
    } = this.props

    const {
      registrationAuthenticityConfirmation,
      employeesAuthenticityConfirmation,
      personalDataProcessingAgreement,
      companyInfo,
      employeesList,
      folder,
      captchaVerified
    } = this.state

    const disabled =
      !registrationAuthenticityConfirmation || //checkbox
      !employeesAuthenticityConfirmation || //checkbox
      !personalDataProcessingAgreement || // checkbox
      !selfieProps ||
      !constituentDocumentProps ||
      !captchaVerified 
      // ||
      // (!companyLicensesNameFileOneProps &&
      //   !companyLicensesNameFileTwoProps &&
      //   !companyLicensesNameFileThreeProps)

    const showCaptcha = !canDo(permissions, 'captcha', 'skip')

    return (
      <WidgetBase
        title={t('company.verification.title')}
        headerMenu={this.drawHeaderMenu}
        classNameName="company-profile"
      >
        {!userId && <Redirect to="/" />}
        <div className="panel-body company-confirm">
          <div className="panel-content">
            <div className="clearfix">
              <div className="col-md-8">
                <div className="panel-block">
                  <h3 className="title-main">{t('company.ready.to.pass.verificaion')}</h3>
                  <h5>{t('company.current.company.data')}</h5>

                  <div className="panel panel-n-bordered">
                    <div className="panel-header">
                      <h4>&nbsp;</h4>
                      <div className="link-right">
                        {companyInfo && (
                          <Link to={`/company/edit/${companyInfo.id}`} className="link-edit">
                            {t('edit')}
                          </Link>
                        )}
                      </div>
                    </div>

                    {companyInfo && <CompanyInfo {...{ companyInfo }} {...{ t }} />}
                  </div>
                  <div className="input-container">
                    <input
                      type="checkbox"
                      id="ch20"
                      checked={registrationAuthenticityConfirmation}
                      onChange={e =>
                        this.handleChangeCheckbox(e, 'registrationAuthenticityConfirmation')
                      }
                    />
                    <label htmlFor="ch20">
                      {t('company.verification.checkbox.one')}
                      <span className="required">*</span>
                    </label>
                  </div>
                  <p>
                    {t('document.scan')}
                    <span className="required">*</span>
                  </p>
                  <div className="panel-block-row row">
                    <div>
                      <span className="title-label">{t('company.document.scan')}</span>
                    </div>

                    <div className="file-uploader-child">
                      <Field
                        name="constituentDocumentProps"
                        component={FileUploadField}
                        className="photo-block photo-rounded"
                        folder={folder}
                        allowedExtensions={['pdf', 'jpeg', 'jpg', 'png', 'gif']}
                      />
                    </div>
                  </div>
                  <p>{t('company.licences')}</p>
                  <div className="panel-block-row row">
                    <div className="col-sm-16 col-md-16 col-lg-16 col-xl-16 upload-container">
                      <div className="panel-block-row row">
                        <div>
                          <span className="title-label">{t('company.licences.text')} (1) </span>
                        </div>
                        <div className="file-uploader-child">
                          <Field
                            name="companyLicensesNameFileOneProps"
                            component={FileUploadField}
                            className="photo-block photo-rounded"
                            folder={folder}
                            allowedExtensions={['pdf', 'jpeg', 'jpg', 'png', 'gif']}
                          />
                        </div>
                      </div>

                      <div className="panel-block-row row">
                        <div>
                          <span className="title-label">{t('company.licences.text')} (2) </span>
                        </div>
                        <div className="file-uploader-child">
                          <Field
                            name="companyLicensesNameFileTwoProps"
                            component={FileUploadField}
                            className="photo-block photo-rounded"
                            folder={folder}
                            allowedExtensions={['pdf', 'jpeg', 'jpg', 'png', 'gif']}
                          />
                        </div>
                      </div>

                      <div className="panel-block-row row">
                        <div>
                          <span className="title-label">{t('company.licences.text')} (3) </span>
                        </div>
                        <div className="file-uploader-child">
                          <Field
                            name="companyLicensesNameFileThreeProps"
                            component={FileUploadField}
                            className="photo-block photo-rounded"
                            folder={folder}
                            allowedExtensions={['pdf', 'jpeg', 'jpg', 'png', 'gif']}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: t('all.company.documents')
                    }} />
                  <div className="panel panel-n-bordered">
                    <div className="panel-header">
                      <h4>&nbsp;</h4>
                      <div className="link-right">
                        <Link to="/company/employee-list" className="has-bage">
                          {t('edit')}
                        </Link>
                      </div>
                    </div>
                    {employeesList && <EmployeeListInfo {...{ employeesList }} {...{ t }} />}
                  </div>

                  <div className="input-container">
                    <input
                      type="checkbox"
                      id="ch245"
                      checked={employeesAuthenticityConfirmation}
                      onChange={e =>
                        this.handleChangeCheckbox(e, 'employeesAuthenticityConfirmation')
                      }
                    />

                    <label htmlFor="ch245">
                      {t('company.verification.checkbox.two')}

                      <span className="required">*</span>
                    </label>
                  </div>

                  <p>

                  </p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('photos.formats')
                    }} />

                  <h5>{t('schemas.photos')}</h5>

                  <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <div>
                      <img src={pass} alt="banner" />
                    </div>
                  </div>
                  <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <div>
                      <img src={pass2} alt="banner" />
                    </div>
                  </div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: t('save.info')
                    }} />


                  <div className="panel-block-row row">
                    <div className="upload-container">
                      <div>
                        <span className="title-label">{t('selfie.photo')}</span>
                      </div>
                      <div className="file-uploader-child">
                        <Field
                          name="selfieProps"
                          component={FileUploadField}
                          className="photo-block photo-rounded"
                          folder={folder}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-container">
                    <input
                      type="checkbox"
                      id="ch201"
                      checked={personalDataProcessingAgreement}
                      onChange={e =>
                        this.handleChangeCheckbox(e, 'personalDataProcessingAgreement')
                      }
                    />
                    <label htmlFor="ch201">
                      {t('company.verification.checkbox.three')}
                      <span className="bold-underline"> {t('company.checkbox.three.agree')} </span>
                      {t('company.checkbox.three.span')}.<span className="required">*</span>
                    </label>
                  </div>

                  {showCaptcha && (
                    <Captcha
                      verifyCallback={() => {
                        this.setState({ captchaVerified: true });
                      }}
                      expiredCallback={() => this.setState({ captchaVerified: false })}
                    />
                  )}

                  <br />
                  <div className="panel-block-row">
                    <button
                      className="button button-orange button-centered"
                      disabled={disabled}
                      onClick={() => this.setVerification(companyInfo.id)}
                    >
                      {t('company.pass.verification')}
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="panel-block">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t('what.is.verification')
                    }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </WidgetBase>
    )
  }

  renderRequestContent = () => {
    return (
      <WidgetBase
        title={t('footer.company.verification')}
        headerMenu={this.drawHeaderMenu}
        classNameName="company-profile"
      >
        <div className="panel-body company-confirm">
          <div className="panel-content was-requested">{t('order.verification')}</div>
        </div>
      </WidgetBase>
    )
  }

  renderVerifiedContentAndDocs = companyVerifiedInfo => {
    const { t } = this.props
    const { folder } = this.state
    const {
      verifiedAt,
      constituentDocument,
      constituentDocumentDoc,
      selfie,
      companyLicenses,
      companyLicensesDocs,
      personalDataProcessingAgreement,
      employeesAuthenticityConfirmation,
      registrationAuthenticityConfirmation,
    } = companyVerifiedInfo

    let documentsLicenses
    if (companyLicensesDocs) {
      documentsLicenses = companyLicensesDocs.map((doc, index) => {
        const img = doc ? (
          <a href={`${config.apiUrl}/common/files/${doc.uuid}`} target="_blank" rel="noopener noreferrer">
            {doc.fileName?.endsWith('.pdf') ?
              <img src={`${config.apiUrl}/common/file/pdf-icon-64.png`} />
              :
              <img src={`${config.apiUrl}/common/files/${doc.uuid}`} />
            }
          </a>
        ) : (
          <b>{t('not.added')}</b>
        )

        return (
          <li key={index}>
            {t('licensy')} {++index} :{img}
          </li>
        )
      })
    }
    /*
        const documentsLicenses = companyLicenses.map((doc, index) => {
          const img = doc ? (
            <img
              src={`${config.apiUrl}/common/file/${folder}/${doc}`}
              alt="image"
              style={{ maxWidth: 50 }}
            />
          ) : (
            <b>{t('not.added')}</b>
          )
    
          return (
            <li key={index}>
              {t('licensy')} {++index} :{img}
            </li>
          )
        })
    */
    return (
      <WidgetBase
        title={`${t('company.verification.title')} / ${t('verification.passed')}`}
        headerMenu={this.drawHeaderMenu}
        classNameName="company-profile"
      >
        <div className="panel-body company-confirm">
          <div className="panel-content was-requested">
            {t('verification.passed')} - {moment(verifiedAt).format('DD/MM/YYYY HH:mm')}
          </div>
          <ul>
            <li>
              {t('constituent.document.of.the.company')} :
              <a href={`${config.apiUrl}/common/files/${constituentDocumentDoc.uuid}`} target="_blank" rel="noopener noreferrer">
                {constituentDocumentDoc?.fileName?.endsWith('.pdf') ?
                  <img src={`${config.apiUrl}/common/file/pdf-icon-64.png`} width='50px' height='50px' />
                  :
                  <img src={`${config.apiUrl}/common/files/${constituentDocumentDoc.uuid}`} style={{ maxWidth: 50 }} />
                }
              </a>
            </li>

            {documentsLicenses}

            <li>
              {t('Personal.photo')} :
              <a href={`${config.apiUrl}/common/files/${selfie}`} target="_blank" rel="noopener noreferrer">
                <img src={`${config.apiUrl}/common/files/${selfie}`} style={{ maxWidth: 50 }} />
              </a>
            </li>
            <li>
              {t('verified.checkbox.one.approve')}
              <b>{registrationAuthenticityConfirmation > 0 ? t('approved') : t('not.approved')}</b>
            </li>
            <li>
              {t('verified.checkbox.two.approve')} -
              <b>{employeesAuthenticityConfirmation > 0 ? t('approved') : t('not.approved')}</b>
            </li>
            <li>
              {t('verified.checkbox.three.approve')}
              <b>{personalDataProcessingAgreement > 0 ? t('approved') : t('not.approved')}</b>
            </li>
          </ul>
        </div>
      </WidgetBase>
    )
  }

  render() {
    const { companyVerifiedInfo } = this.props
    const isValid = companyVerifiedInfo?.isValid
    const isVerified = isValid > 0 ? this.renderVerifiedContentAndDocs(companyVerifiedInfo) : this.renderContent()
    const content = isValid === 0 && !companyVerifiedInfo?.verifiedAt && companyVerifiedInfo.active !== 0 ? this.renderRequestContent() : isVerified

    return content
  }
}
