import React, { Component } from 'react'
import EmployeesTable from './employes-table'
import { Redirect } from 'react-router-dom'
import { ICompanyEmployesList } from './types'
import fetch from '../../shared/fetch'
import { ACTIVE_STATUS_ID, DECLINED_STATUS_ID } from './constants'
import './styles.scss'

export default class extends Component<ICompanyEmployesList> {
  state = {
    employees: {
      isLoading: true,
      list: [],
      total: null,
    },
    formerEmployees: {
      isLoading: true,
      list: [],
      total: null,
    },
    companyRequest: {
      isLoading: true,
      list: [],
      total: null,
    },
    redirect: false
  }

  componentDidMount() {
    const {
      profile: { isCompanyAdmin },
    } = this.props

    if (isCompanyAdmin > 0) {
      this.fetchEmployes('employees', 2, 0, 10)
      this.fetchEmployes('formerEmployees', 3, 0, 10)
      this.fetchEmployes('companyRequest', 1, 0, 10)
    } else {
      this.setState({ redirect: true })
    }

  }

  componentDidUpdate(prevProps) {
    const {
      profile: { isCompanyAdmin },
    } = this.props

    if (!(isCompanyAdmin !== prevProps.isCompanyAdmin && isCompanyAdmin > 0)) {
      this.setState({ redirect: true })
    }
  }

  changePageHandler = (employeesType, employeesStatusId, newPageIndex, pageSize) => {
    this.fetchEmployes(employeesType, employeesStatusId, newPageIndex, pageSize)
  }

  changePageSizeHandler = (employeesType, employeesStatusId, newPageSize) => {
    this.fetchEmployes(employeesType, employeesStatusId, 0, newPageSize)
  }

  fetchEmployes = (employeesTypes, employeesStatusId, pageIndex, pageSize) => {
    const {
      profile: { companyId },
    } = this.props

    this.setState({
      [employeesTypes]: {
        isLoading: true,
        list: [],
        total: this.state[employeesTypes].total,
      },
    })

    fetch(`/company/employees/${companyId}/${employeesStatusId}/${pageIndex}/${pageSize}`).then(
      res => {
        if (res.ok) {
          this.setState({
            [employeesTypes]: {
              isLoading: false,
              list: res.employees,
              total: res.employees[0] ? res.employees[0].total : null,
            },
          })
        }
      }
    )
  }

  acceptOrDeclineRequestHandler = (
    userId: number,
    statusId: number,
    index: number,
    pageSize: number
  ) => {
    const { setCompanyJoinRequests } = this.props

    fetch(`/company/acceptOrDecline/${userId}/${statusId}`).then(res => {
      if (res.ok && statusId === ACTIVE_STATUS_ID) {
        this.addEmployeeToList(index, pageSize)
      } else if (res.ok && statusId === DECLINED_STATUS_ID) {
        this.removeEmployeeFromList(index)
      }

      setCompanyJoinRequests(this.state.companyRequest.list)
    })
  }

  removeEmployeeFromList = (index: number) => {
    const {
      companyRequest: { total },
    } = this.state
    this.state.companyRequest.list.splice(index, 1)
    // eslint-disable-next-line
    total && this.state.companyRequest.total--

    this.forceUpdate()
  }

  addEmployeeToList = (index: number, pageSize: number) => {
    const {
      employees: { list, total },
      companyRequest: { list: requestsList },
    } = this.state
    const selectedEmployee = requestsList[index]
    this.removeEmployeeFromList(index)
    // eslint-disable-next-line
    total && this.state.employees.total++
    if (list.length < pageSize) {
      this.state.employees.list.push(selectedEmployee)
      this.forceUpdate()
    }
  }

  render() {
    const { employees, formerEmployees, companyRequest, redirect } = this.state
    const { t } = this.props
    const defaultProps = {
      changePageSize: this.changePageSizeHandler,
      changePage: this.changePageHandler,
      acceptOrDeclineRequest: this.acceptOrDeclineRequestHandler,
    }

    const tablesProps = [
      {
        headerTitle: t('employess.access.settings').toUpperCase(),
        employeesType: 'employees',
        employeesStatusId: 2,
        total: employees.total ?? 0,
        isLoading: employees.isLoading,
        peopleList: employees.list,
        form: 'employee-list',
        noDataMessage: 'employee.empty',
        ...defaultProps,
      },
      {
        headerTitle: t('employees.company.request').toUpperCase(),
        employeesType: 'companyRequest',
        employeesStatusId: 1,
        total: companyRequest.total ?? 0,
        isLoading: companyRequest.isLoading,
        peopleList: companyRequest.list,
        form: 'company-request-list',
        noDataMessage: 'employee.empty.requests',
        ...defaultProps,
      },
      {
        transparentTable: true,
        headerTitle: t('employees.former').toUpperCase(),
        employeesType: 'formerEmployees',
        employeesStatusId: 3,
        total: formerEmployees.total ?? 0,
        isLoading: formerEmployees.isLoading,
        peopleList: formerEmployees.list,
        form: 'former-employee-list',
        noDataMessage: 'employee.empty',
        ...defaultProps,
      },
    ]

    const content = tablesProps.map(tableProps => (
      <EmployeesTable {...tableProps} key={tableProps.employeesStatusId} />
    ))

    return (
      <>
        {redirect && <Redirect to="/" />}
        {content}
      </>
    )
  }
}
