import React, { Component } from 'react'
import Icon from 'react-icons-kit'
import { connect } from 'react-redux'

import { setActivePopup } from '../../../store/actions/ui'

class DropdownName extends Component {
  static displayName = 'DropdownName'

  toggleDropdown = guid => {
    const newGuid = this.props.activeGuid === guid ? undefined : guid
    this.props.setActivePopup(newGuid)
  }
  closeDropdown = () => this.props.setActivePopup(undefined)

  render() {
    const { inline = false } = this.props

    if (inline) {
      const { icon, text, guid, iconSize = 14 } = this.props
      return (
        <div onClick={() => this.toggleDropdown(guid)}>
          {icon && <Icon icon={icon} size={iconSize} />}
          {text && <span>{text}</span>}
        </div>
      )
    } else {
      return this.props.children || null
    }
  }
}

export default connect(
  state => ({
    activeGuid: state.ui.activePopupGuid,
  }),
  {
    setActivePopup,
  }
)(DropdownName)

// export { DropdownName, DropdownPanel, DropdownPopover }
