import React, { Component } from 'react'
import { addScrollBar } from '../store/utils'


interface ModalOptions {
  fadeInDuration?: number
  fadeOutDuration?: number
  skipLayout: boolean
  title: string
  className: string
  hideHeader: boolean
  backdrop: boolean | string
  keyboard: boolean
}

export interface ModalContextState extends ModalOptions {
  clearDetectModalFormChange: any
  detectModalFormChange: any
  displayUnsavedModal: any
  handleOutsideClick: any
  showModalComponent: any
  clearModal: any
  hasDetectedFormChange?: boolean
  isDisplayingUnsavedModal: boolean
  modalComponent: any
  overlayStyle: any
  showModal?: boolean
  isOpen: boolean
  fadingState?: any
  skipLayout: boolean
}

const defaultState: ModalContextState = {
  detectModalFormChange: null,
  displayUnsavedModal: null,
  handleOutsideClick: null,
  showModalComponent: null,
  clearDetectModalFormChange: null,
  clearModal: null,
  // providing to consumer
  hasDetectedFormChange: false,
  isDisplayingUnsavedModal: false,
  modalComponent: null, // modal displays if it's not null
  overlayStyle: null, // handles fading of consumer modal-overlay top tag
  showModal: false,
  isOpen: false,

  // for internal use
  fadeInDuration: 0,
  fadeOutDuration: 0,
  fadingState: null, // if not null then it's in fading transition
  skipLayout: false, // if true then skip all inner layout of ModalOverlay (Close icon, container, etc) and output modalComponent as-is

  // onClose,
  title: null,
  className: null,
  hideHeader: false,
  backdrop: true,
  keyboard: true,
}

const ModalContext = React.createContext(defaultState)

const MODAL_SHOW = 'MODAL_SHOW'
const MODAL_FADING_IN = 'MODAL_FADING_IN'
const MODAL_SHOWN = 'MODAL_SHOWN'
const MODAL_HIDDEN = 'MODAL_HIDDEN'

export class ModalProvider extends Component<{}, ModalContextState> {
  state = {
    ...defaultState,
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  timeoutFadingState = (fadingState, timeout) => {
    const timeoutToken = setTimeout(() => {
      clearTimeout(timeoutToken)

      let newState = {}

      if (fadingState === MODAL_FADING_IN) {
        const { fadeInDuration = 0 } = this.state
        newState = {
          fadingState,
          overlayStyle: { opacity: 1, transition: `opacity ease ${fadeInDuration}ms` },
        }
        this.timeoutFadingState(MODAL_SHOWN, fadeInDuration)
      } else if (fadingState === MODAL_SHOWN) {
        newState = { fadingState: null, overlayStyle: { opacity: 1, transition: 'initial' } }
      } else if (fadingState === MODAL_HIDDEN) {
        newState = { ...defaultState } // reset all props
      }

      this.setState({ ...newState })
    }, timeout)
  }

  clearDetectModalFormChange = () => {
    if (this.state.isDisplayingUnsavedModal) {
      this.setState({ isDisplayingUnsavedModal: false })
    }
  }

  clearModal = () => {
    const { hasDetectedFormChange } = this.state
    if (hasDetectedFormChange) {
      this.displayUnsavedModal(true)
    } else {
      this.hide()
    }
  }

  detectModalFormChange = () => {
    if (!this.state.hasDetectedFormChange) {
      this.setState({ hasDetectedFormChange: true })
    }
  }

  displayUnsavedModal = value => {
    this.setState({ isDisplayingUnsavedModal: value })
  }

  handleKeyDown = e => {
    const key = e.which || e.keyCode
    const { keyboard } = this.state
    if (key === 27 && keyboard) {
      // Esc
      e.preventDefault()
      this.clearModal()
    }
  }

  handleOutsideClick = e => {
    e.preventDefault()
    e.stopPropagation()
    if (!this.state.isDisplayingUnsavedModal) {
      this.clearModal()
    }
  }

  showModalComponent = (modalComponent, options: ModalOptions) => {
    const { fadingState } = this.state
    if (fadingState) return
    const {
      fadeInDuration = 0,
      skipLayout = false,
      title,
      className,
      hideHeader = false,
      backdrop = 'static',
      keyboard = true,
    } = options

    this.setState({
      // providing to consumer
      modalComponent,
      isOpen: true,
      overlayStyle: { opacity: fadeInDuration ? 0 : 1, transition: 'initial' },
      // for internal use
      fadeInDuration,
      // fadeOutDuration,
      fadingState: fadeInDuration ? MODAL_SHOW : null,
      skipLayout,
      title,
      className,
      hideHeader,
      backdrop,
      keyboard,
    })

    fadeInDuration && this.timeoutFadingState(MODAL_FADING_IN, 50)
  }
  hide = () => {
    const { modalComponent } = this.state
    addScrollBar()
    if (modalComponent) {
      this.setState({ isOpen: false })
      setTimeout(() => {
        this.setState({ ...defaultState })
      }, 500)
    }
  }

  render() {
    const {
      modalComponent,
      isOpen,
      overlayStyle,
      isDisplayingUnsavedModal,
      skipLayout,

      title,
      className,
      hideHeader,
      backdrop,
      keyboard,
    } = this.state

    return (
      <ModalContext.Provider
        value={{
          clearDetectModalFormChange: this.clearDetectModalFormChange,
          clearModal: this.clearModal,
          detectModalFormChange: this.detectModalFormChange,
          displayUnsavedModal: this.displayUnsavedModal,
          handleOutsideClick: this.handleOutsideClick,
          isDisplayingUnsavedModal,
          isOpen, //: showModal,
          modalComponent,
          showModalComponent: this.showModalComponent,
          overlayStyle,
          skipLayout,

          title,
          className,
          hideHeader,
          backdrop,
          keyboard,
        }}
      >
        {this.props.children}
      </ModalContext.Provider>
    )
  }
}

export const ModalConsumer = ModalContext.Consumer
