import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Field, InjectedFormProps } from 'redux-form'
import classNames from 'classnames'
import { USERS_URL, COMPANIES_URL, rulesLanguagesRo, rulesLanguagesRu } from "./constanst"
import dict from '../../shared/dictionaries'
import menuItems from '../../../templates/header-top'
import { changeLanguage, setAvailableLanguages } from '../../../store/actions/ui'
import Cookies from 'universal-cookie'

import "./styles.scss"

import {
  loadCargoLastSearch,
  loadTransportLastSearch,
} from '../../../store/actions'
interface IHeaderTopProps extends ComponentBaseProps, InjectedFormProps {
  changeLanguage: typeof changeLanguage
  setAvailableLanguages: typeof setAvailableLanguages
  loadCargoLastSearch: typeof loadCargoLastSearch
  loadTransportLastSearch: typeof loadTransportLastSearch
  searchText: string
  isAuthenticated: boolean
}

interface IHeaderTopState {
  languages: any[]
  mainMenu: boolean
  redirectTo: string
  menuOpen: string
  showSearchField: boolean
  currentSearchFilter: string
}

export default class extends Component<IHeaderTopProps, IHeaderTopState> {
  state = {
    languages: [],
    mainMenu: false,
    redirectTo: '',
    menuOpen: "",
    showSearchField: false,
    currentSearchFilter: USERS_URL,
  }

  componentDidMount() {
    const { setAvailableLanguages } = this.props
    const { loading, handler, items } = dict.languages()
    const getSiteLangs = langs => langs.filter(x => x.isSiteLang)
    if (loading) {
      handler.then(data => {
        setAvailableLanguages(data.items)
        this.setState({ languages: getSiteLangs(data.items) }, () => this.fireChangeLang())
      })
    } else {
      this.setState({ languages: getSiteLangs(items) }, () => this.fireChangeLang())
    }
  }

  fireChangeLang = () => {
    const cookies = new Cookies()
    const hasDefaultLanguage = cookies.get('hasDefaultLanguage')
    !hasDefaultLanguage && this.getCurrentLocationAndChangeLang()
  }

  componentDidUpdate() {
    // if (this.state.mainMenu) {
    //   document.addEventListener('click', this.toggleMore)
    // }
  }

  getLocation = () => {

    const xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", "https://ipapi.co/json", false); // false for synchronous request
    xmlHttp.send(null);
    const currCode = JSON.parse(xmlHttp.responseText).country_code.toLowerCase()

    const isDownloadRu = rulesLanguagesRu.find(lng => lng === currCode) || null
    const isDownloadRo = rulesLanguagesRo.find(lng => lng === currCode) || null

    const swicthLang = isDownloadRu ? "ru" : isDownloadRo ? 'ro' : 'en'

    const cookies = new Cookies()
    currCode && cookies.set('hasDefaultLanguage', swicthLang, {
      path: "/",
      maxAge: 10000000
  })

    return swicthLang
  }


  getCurrentLocationAndChangeLang = () => {
    const { languages } = this.state
    const currentLocationCode = this.getLocation()

    const currLang = languages.find(lang => lang.nameLo === currentLocationCode)
    this.changeLang({ name: currLang.name, value: currLang.value })
  }

  toggleMore = (e, targetBlank = false) => {
    // this.setState(
    //   prevState => ({ mainMenu: !prevState.mainMenu }),
    //   () => {
    //     if (!this.state.mainMenu) {
    //       document.removeEventListener('click', this.toggleMore)
    //     }
    //   }
    // )
    this.setState({ mainMenu: !this.state.mainMenu, menuOpen: "" })

    if (!targetBlank) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  setMenuOpen = (name, length, hideMenu) => {

    const { menuOpen } = this.state
    if (!length) {
      this.setState({ mainMenu: false })
    }


    if (menuOpen === name) {
      this.setState({ menuOpen: "", mainMenu: false })
    } else {
      this.setState({ menuOpen: name })
    }

    if (hideMenu) {
      this.setState({ mainMenu: false })
    }
  }

  setActiveMenuItem = (item) => {
    const { menuOpen } = this.state
    const width = window.innerWidth
    if (width > 500) {
      const str = window.location.href
      const itemHref = str.includes(item.href)
      const itemOptionsHref = item?.options ? item.options.find(option => str.includes(option.href)) : false

      if (itemHref || itemOptionsHref) {
        return true
      } else {
        return false
      }
    } else {
      return item.name === menuOpen
    }
  }

  renderAllItems = (items, needToHide = true) => {
    const { t } = this.props
    const { menuOpen } = this.state
    const width = window.innerWidth

    const renderAllItems = items.map((item, j1) => {
      const { href = `/not-ready-yet?title=${item.name}`, dictionary = "" } = item

      const newHref = item.name === menuOpen ? href : "#"
      return item.type === 'item' ? (
        <li key={j1} onClick={() => this.setMenuOpen(item.name, item.options, needToHide)}
          className={classNames({
            'has-sublevel': true,
            'is-opened': this.setActiveMenuItem(item),
            'remove-icon': !item?.options,
          })}
        >
          <Link to={href}
          >{t(item.name)}</Link>
        </li>
      ) : item.type === 'ddl' ? (
        <li
          key={j1}
          //сlassName="has-sublevel" 
          className={classNames({
            'has-sublevel': true,
            'is-opened': this.setActiveMenuItem(item)
          })}
          //onMouseEnter={() => width > 500 && this.setMenuOpen(item.name)}
          onClick={() => this.setMenuOpen(item.name, item.options, needToHide)}>
          {/* {item.name === menuOpen && <Link to={href}>{item.name}</Link>}
          {item.name !== menuOpen && <span>{item.name}</span>} */}
          {/* <span>{item.name}</span> */}
          <Link to={width > 768 ? href : newHref}>{t(item.name)}</Link>
          <ul className="sublevel">
            {item.options.map((opt, j2) => {
              const { href = `/not-ready-yet?title=${opt.name}` } = opt
              return (
                <li key={j2} onClick={(e) => this.toggleMore(e, opt.targetBlank)}>
                  {opt.targetBlank ? <a href={href} target="_blank" rel="noopener noreferrer">{t(opt.name)}</a> :
                    <Link to={href} >{dictionary.length > 0 ? opt.name : t(opt.name)}</Link>
                  }
                </li>
              )
            })}
          </ul>
        </li>
      ) : null
    })

    return renderAllItems
  }

  drawItems = ({ items }) => {
    const { t } = this.props
    const { mainMenu } = this.state

    return (
      <div className="filter-nav priority-nav priority-nav-has-dropdown " >
        <ul data-sublevels="true" className="menu menu-main ">
          {this.renderAllItems(items)}
        </ul>

        <span className="nav__dropdown-wrapper priority-nav__wrapper" aria-haspopup="true">
          <button
            aria-controls="menu"
            type="button"
            onClick={this.toggleMore}
            className={classNames(
              'nav__dropdown-toggle priority-nav__dropdown-toggle priority-nav-is-visible',
              { 'is-open': mainMenu }
            )}
            // prioritynav-count="4"
          >
            {t('menu')}
          </button>
          <ul
            aria-hidden="false"
            className={classNames('nav__dropdown priority-nav__dropdown', { show: mainMenu })}
          >
            {this.renderAllItems(items, false)}
          </ul>
        </span>
      </div>
    )
  }

  renderDictionarySubmenuItems = (menuItem: any) => {
    const { langId } = this.props
    const { dictionary, urlPrefix } = menuItem
    const optionsItems = dict[dictionary](langId)

    const subItems = (optionsItems?.items || []).map(({ value, name }) => {
      return { name, href: `${urlPrefix}${value}` }
    })

    return { ...menuItem, options: subItems }
  }

  getMenuItems = () => {
    const { menu } = menuItems
    let menuList = []

    menu.map((item) => {
      const { type } = item
      if (type === "ddl" && item.dictionary?.length > 0) {
        menuList = [...menuList, this.renderDictionarySubmenuItems(item)]
      } else {
        menuList = [...menuList, { ...item }]
      }
    })

    return menuList
  }

  onKeyUpSearch = event => {
    if (event.keyCode === 13) {
      this.doSearch()
    }
  }

  doSearch = () => {
    const { change, searchText } = this.props
    const { currentSearchFilter } = this.state
    const redirectTo = `/company${currentSearchFilter}/${searchText}`
    this.setState({ redirectTo }, () => {
      this.setState({ redirectTo: "", showSearchField: false })
      change('search', null)
    })
  }

  changeLang = (lang) => {
    const { changeLanguage, loadCargoLastSearch, loadTransportLastSearch, isAuthenticated } = this.props
    const doNotShowAgain = localStorage.getItem('doNotShowAgain');
    if(!doNotShowAgain) {
      localStorage.removeItem('approveInfo')
    }
    changeLanguage(lang)
    if (isAuthenticated) {
      loadCargoLastSearch()
      loadTransportLastSearch()
    }
  }

  renderFlag = (flag) => {
    return flag === "flag-icon-ro" ? "flag-icon-md" : flag 
  }

  render() {
    const { t, currentLanguage, changeLanguage } = this.props
    const { redirectTo, showSearchField, currentSearchFilter } = this.state
    const languages = this.state.languages.filter(
      x => x.name.toLowerCase() !== currentLanguage.toLowerCase()
    )
    const { flag } =
      this.state.languages.find(x => x.name.toLowerCase() === currentLanguage.toLowerCase()) || {}

    const searcFilterLabel = currentSearchFilter === USERS_URL ? t('by.users') : t('by.companies')
    return (
      <div className="header-top">
        <div className="container">
          <div className="header-top-right clearfix">
            <span className="mobile-only search-mob" onClick={() => this.setState({ showSearchField: true })}>
              <span className="fa fa-search" />
            </span>
            <div className={classNames({
              'search-container': true,
              'show-search-modal': showSearchField,
            })}>
              <Field
                type="text"
                name="search"
                component="input"
                placeholder={t('search')}
                onKeyUp={this.onKeyUpSearch}
              />
              <button type="submit" onClick={this.doSearch}>
                <span className="fa fa-search" />
              </button>
              <ul className="menu menu-search-filter">
                <li className="has-sublevel">
                  <Link to="#">
                    {searcFilterLabel}
                  </Link>
                  <ul className="sublevel">
                    <li className={currentSearchFilter === COMPANIES_URL && "active-selected-filter"} onClick={() => this.setState({ currentSearchFilter: COMPANIES_URL })}>{t('by.companies')}</li>
                    <li className={currentSearchFilter === USERS_URL && "active-selected-filter"} onClick={() => this.setState({ currentSearchFilter: USERS_URL })}>{t('by.users')}</li>
                  </ul>
                </li>
              </ul>
              <span className="mobile-only search-close" onClick={() => this.setState({ showSearchField: false })}>
                <span className="ico ico-close" />
              </span>

              <div className="search-overlay" onClick={() => this.setState({ showSearchField: false })} />
            </div>
            <div className="language-container">
              <ul className="menu menu-language">
                <li className="has-sublevel">
                  <Link to="#">
                    <span className={classNames('flag flag-icon', this.renderFlag(flag))} />
                    {currentLanguage.toUpperCase()}
                  </Link>
                  <ul className="sublevel">
                    
                  {languages.map(x => {
                    return (
                      <li key={x.value}>
                        <Link to="#" onClick={() => this.changeLang(x)}>
                          <span className={classNames('flag flag-icon', this.renderFlag(x.flag))} />
                          {x.name.toUpperCase()}
                        </Link>
                      </li>
                      )
        })}
                  </ul>
                </li>
              </ul>
            </div>
          </div>

          {this.drawItems({
            items: this.getMenuItems(),
          })}
        </div>
        {redirectTo && <Redirect to={redirectTo} />}
      </div>
    )
  }
}
