import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import Component from './component'
import withTranslation from '../../../globals/components/withTranslation'
import { getProfile } from '../../../store/actions/user'

export default compose(
  withTranslation,
  connect(
    state => {
      const { username, userId, email } = state.auth.jwt
      const { profile } = state.auth
      
      return {
        isAuthenticated: !!state.auth.accessToken,
        languages: state.ui.languages,
        username,
        email,
        userId,
        loaded: profile.get('loaded'),
        loading: profile.get('loading'),
      }
    },
    {
      getProfile,
    }
  ),
  lifecycle({
    componentDidMount() {
      const { loaded, loading } = this.props

      if (!loaded && !loading) {
        const { getProfile, userId } = this.props

        getProfile(userId)
      }
    }
  })
)(Component)
