// import _ from "lodash"
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { dateFormatter } from "../../shared/utils"
import fetch from '../../shared/fetch'
import moment from 'moment'
// import { changeWidgetFilter } from '../../shared/utility'
import WidgetList from '../../shared/components/widget-list'
import Spinner from '../../UI/spinner'
import TooltipWrapper from '../../UI/popover-wrapper'
import TooltipModal from '../../search/table/tooltip-modal'
import "./styles.scss"

export default class News extends Component {

  state = {
    items: [],
  }


  componentDidMount() {

    const body = JSON.stringify({
      claimType: 0,
      pageIndex: 0,
      pageSize: 5
    })

    fetch(`/claim`, {
      method: 'POST',
      body,
    }).then(res => {

      this.setState({
        items: res.d.items,
        isFetching: false
      })
    })

  }

  renderRows = items => {
    return items.map((item, index) => this.renderRow(item, index))
  }

  renderRow = ({ createdDate, targetCompanyId, targetCompanyName, description, createdByCompanyId, createdByCompanyName }, index) => {
    const { t } = this.props
    return (
      <div key={index} className="panel-content-row widget-blacklist">
        <span className="meta">{dateFormatter(createdDate, true)}</span>

        <Link to={`/company/item/${createdByCompanyId}`} className="name-link">
          <TooltipWrapper
            text={<TooltipModal companyId={createdByCompanyId} userId={0} />}
            hover
            innerClassName="full-tooltip"
            disabled={false}
            parentCustomClass="move-up-container"
          >
            {createdByCompanyName}
          </TooltipWrapper>
        </Link>

        <span>
          {t('claim.from')}
        </span>
        <Link to={`/company/item/${targetCompanyId}`} >

          <TooltipWrapper
            text={<TooltipModal companyId={targetCompanyId} userId={0} />}
            hover
            innerClassName="full-tooltip"
            disabled={false}
            parentCustomClass="move-up-container"
          >
            {targetCompanyName}
          </TooltipWrapper>

        </Link>
        <div className="widget-claim-content">
          <Link className="long-text redirect-reviews" to={`/company/item/${targetCompanyId}/claims`} >
            {description.slice(0, 100)} {description.length > 100 && '...'}
          </Link>
        </div>

      </div>
    )
  }

  render() {
    const { dataMoveForMobile, t } = this.props
    return (
      <WidgetList
        title={t('black.list.widget')}
        items={this.state.items}
        renderRows={this.renderRows}
        linksTo={{ title: '/company/rating/claims' }}
        {...{ dataMoveForMobile }}
      />
    )
  }
}
