import isArray from 'lodash/isArray'
import window from 'global/window'

function translate(token: string, data?) {
  return window.t(token, data)
}

export const required = value => {
  const ok = (value && !isArray(value)) || (isArray(value) && value.length > 0)
  return ok ? undefined : translate('validation.required')
}
export const moreThanZero = value => {
  const ok = Number(value) > 0
  return ok ? undefined : translate('validation.need.will.be.more.than.zero')
}
export const maxLength = (max: number) => value =>
  value && value.length > max ? translate('validation.maxLength', { max }) : undefined
export const maxLength15 = maxLength(15)
export const maxPasswordLength = maxLength(24)

export const minLength = (min: number) => value =>
  value && value.length < min ? translate('validation.minLength', { min }) : undefined
export const minLength2 = minLength(2)
export const minPasswordLength = minLength(8)

export const matchToField = (fieldName: string, errorMessage: string) => (value, allValues) =>
  value && value !== allValues[fieldName] ? errorMessage : undefined

export const matchPassword = matchToField('password', 'do not match')

export const number = value => (value && isNaN(Number(value)) ? 'Must be a number' : undefined)
export const minValue = (min: number) => value =>
  value && value < min ? `Must be at least <<min>>` : undefined
export const minValue13 = minValue(13)
export const minLoginLength = minLength(5)
export const maxLoginLength = maxLength(20)

const loginRegexp = /(?=^.{5,}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\!\@\#\$\%\^\&\*\(\)\_\+\-\=])[\w\!\@\#\$\%\^\&\*\(\)\_\+\-\=]*$/
export const loginValidation = (value: string) =>
  value && !loginRegexp.test(value)
    ? translate('validation.login')
    : undefined

const passwordRegexp = /(?=^.{8,}$)(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[\!\@\#\$\%\^\&\*\(\)\_\+\-\=])[\w\!\@\#\$\%\^\&\*\(\)\_\+\-\=]*$/
export const passwordValidation = (value: string) =>
  value && !passwordRegexp.test(value) ? translate('validation.password') : undefined

export const email = (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? translate('validation.email')
    : undefined

export const tooYoung = (value: number) =>
  value && value < 13 ? 'You do not meet the minimum age requirement!' : undefined
const alphaNumericRegex = /[^a-zA-Zа-яА-Я0-9.,-/ ]/i
export const alphaNumeric = value =>
  value && alphaNumericRegex.test(value) ? translate('only.alphanumeric.characters') : undefined //only.alphanumeric.characters
export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined

export default {
  required,
}
