// import classnames from 'classnames/bind'
import React from 'react'
import { compose, pure } from 'recompose'
import Helmet from 'react-helmet'
// import { Layout, Panel } from 'react-toolbox/lib/layout'

import Layout from '../../components/layout'
// import {
//   // BrowserRouter,
//   withRouter,
//   // Router, Switch, Route, Redirect
// } from 'react-router-dom'
// import IdleDialog from '../../../idle/components/IdleDialog'
// import AppNotificationSnackBar from '../../../appNotification/AppNotificationSnackBar'
// import AppNotificationDialog from '../../../appNotification/AppNotificationDialog'
import intlScrollToTopOnUpdateFactory from './intlScrollToTopOnUpdateFactory'

import { ModalProvider } from '../../context/modal-context.tsx'
import ModalOverlay from '../../components/modal-overlay'
import { getGlobalTitle } from '../../store/utils'

// import ConnectedNavDrawer from './ConnectedNavDrawer'
// import VerticalAppBar from './VerticalAppBar'
// import HorizontalAppBar from './HorizontalAppBar'

// const cssClasses = classnames.bind(require('./index.css'))

export default compose(
  intlScrollToTopOnUpdateFactory,
  // withRouter,
  render => props => {
    const { intl } = props
    const defaultTitle = getGlobalTitle(intl?.locale)
    return (
      <ModalProvider>
        <Layout intl={intl}>
          <Helmet titleTemplate="%s - Transport" {...{ defaultTitle }} />
          {/* <ConnectedNavDrawer history={props.history} location={props.location} />
          <Panel className={cssClasses('Panel')}>
            <VerticalAppBar history={props.history} location={props.location} />
            <HorizontalAppBar history={props.history} location={props.location} />
            */}

          {render(props)}

          {/* <IdleDialog intl={props.intl} />
          <AppNotificationDialog intl={props.intl} />
          <AppNotificationSnackBar intl={props.intl} />
          </Panel> */}
        </Layout>
        <ModalOverlay />
      </ModalProvider>
    )
  },
  React.createFactory,
  pure
)

// export default layoutFactory
