import React, { Component } from 'react'

import './forum-no-content.scss'

interface ForumNoContentProps {
  title: string
}

export default class ForumNoContent extends Component<ForumNoContentProps> {
  render() {
    const { title } = this.props
    return (
      <div className="no-content-wrapper">
        <div className="no-content-title">{title}</div>
      </div>
    )
  }
}
