import React from 'react'
import FlexBand from 'flexband'
import './styles.scss'
import { Field } from 'redux-form'
import Dropdown from '../../../UI/dropdown'
import fetch from '../../../shared/fetch'
import { AddClaimState, AddClaimProps } from './types'
import { toastr } from 'react-redux-toastr'
import { Link } from 'react-router-dom'
import { TITLE_MAX, REVIEW_MAX, REVIEW_MIN } from '../constants'
import classNames from "classnames"
import { canDo } from '../../../../store/utils'
import Captcha from '../../../captcha'

const initialState: AddClaimState = {
  categoryKey: 0,
  title: '',
  review: '',
  approvedClaimsRules: false,
  captchaVerified: false
}

export default class AddClaim extends React.Component<AddClaimProps, AddClaimState> {
  private textareaRef: React.RefObject<any>
  constructor(props) {
    super(props)

    this.state = initialState
  }

  reinitialize = () => {
    this.setState(initialState)
  }

  componentDidMount() {
    const { categoryKey, permissions } = this.props
    this.setState({ categoryKey })
    if (canDo(permissions, 'captcha', 'skip')) {
      this.setState({ captchaVerified: true })
    }
  }

  componentDidUpdate(prevProps) {
    const { categoryKey } = this.props
    if (prevProps.categoryKey !== categoryKey) {
      this.setState({ categoryKey })
    }
  }

  handleTitleChange = e => {
    const { value } = e.target
    value.length <= TITLE_MAX && this.setState({ title: value })
  }

  handleReviewChange = e => {
    const { value } = e.target

    value.length <= REVIEW_MAX && this.setState({ review: e.target.value })
  }

  handlePostClick = () => {
    const { refresh, claimId, reCalcCounter, reviewingCompanyId, resetResponse, t } = this.props
    const { review, title, categoryKey } = this.state

    if (!claimId) {
      fetch(`/company/${reviewingCompanyId}/claim`, {
        method: 'POST',
        body: JSON.stringify({
          claimType: categoryKey,
          title,
          description: review
        }),
      })
        .then(res => {
          if (res.ok) {
            reCalcCounter()
            refresh(t("claim.send.to.approve"))
            this.reinitialize()
          }
        })
        .catch(err => toastr.error(err.message))

    } else {
      fetch(`/claim/${claimId}/response`, {
        method: 'POST',
        body: JSON.stringify({
          comment: review,
        }),
      })
        .then(res => {
          if (res.ok) {
            reCalcCounter()
            refresh(t("claim.answer.was.added"))
            resetResponse()
            this.reinitialize()
          }
        })
        .catch(err => toastr.error(err.message))
    }
  }

  renderSelectOptions = () => {
    const { claimsTypes } = this.props
    const newArr = []
    claimsTypes.forEach(({ value, name }) => {
      newArr.push({ id: value, title: name })
    })

    const renderOptions = [
      ...newArr.map(({ id, title }) => ({
        value: id,
        name: title,
      })),
    ]

    return renderOptions
  }

  moveInToStateThisValue = categoryKey => {
    this.setState({ categoryKey })
  }

  render() {
    const { title, review, approvedClaimsRules, categoryKey, captchaVerified } = this.state
    const {
      answerFor,
      resetResponse,
      t,
      scrollToReviewForm,
      userId,
      permissions
    } = this.props


    const noTitleNoContent = review.length < REVIEW_MIN || !title || !approvedClaimsRules || !categoryKey

    const isButtonActive = answerFor.claimId ? review.length < REVIEW_MIN : noTitleNoContent

    const showCaptcha = !canDo(permissions, 'captcha', 'skip')

    return (
      <div >
        {!answerFor.claimId && (
          <div className="claim-title-container">
            <div className="add-claim-title">{t("claim.add.title")}</div>
            <div className="warning-text-container">
              <span className="color-red">{t('attention')}!</span>
              <div className="warning-text">
                {t('claim.warning.text')} {` `}
                <Link
                  to="#"
                  className={classNames({
                    'additional-styles': true,
                  })}
                >
                  <i className="fa fa-times mobile-only" />
                  <span onClick={() => scrollToReviewForm()}>{t('review.add.label')}</span>
                </Link>
              </div>

            </div>
          </div>
        )}
        {answerFor.claimId && (
          <FlexBand className="response-block">
            <span className="secondary">
              {' '}
              {t('review.answer.for')} "{answerFor.title}"{' '}
            </span>
            <Link to="#" onClick={resetResponse}>
              {t('cancel')}
            </Link>
          </FlexBand>
        )}

        {!answerFor.claimId && (
          <FlexBand direction="column">
            <FlexBand justify="space-between">
              <span> {t('review.add.title')} <span className="color-red">*</span></span>
              <span className="counter">
                {title.length} / {TITLE_MAX}
              </span>
            </FlexBand>
            <input onChange={this.handleTitleChange} value={title} type="text" />


            <div className="add-claim-select-item-container" >
              <span> {t('claim.select.type')}<span className="color-red">*</span></span>
              <Field
                onChange={this.moveInToStateThisValue}
                className="add-claim-select-item"
                name="add-theme"
                component={Dropdown}
                emptyOption={''}
                items={this.renderSelectOptions()}
                placeholder={t("claim.select")}
              />
            </div>
          </FlexBand>
        )}


        <FlexBand direction="column" className="claim-custom-container">
          <FlexBand justify="space-between">
            <span> {t('claim.label')} <span className="color-red">*</span></span>
            <span className="counter">
              {review.length} / {REVIEW_MAX}
            </span>
          </FlexBand>
          <textarea
            onChange={this.handleReviewChange}
            value={review}
            placeholder={t('review.add.area.ph')}
          />
        </FlexBand>
        {!answerFor.claimId && (
          <FlexBand className="claim-custom-container">
            <input
              type="checkbox"
              id={`checkbox-claims`}
              checked={approvedClaimsRules}
              onChange={() => this.setState({ approvedClaimsRules: !approvedClaimsRules })}
            />
            <label htmlFor={`checkbox-claims`}>
              <Link to={`/about/rules`} className="">
                {t('claim.checkbox.1')}
              </Link> 
              {` `}{t('claim.checkbox.2')}
            </label>
          </FlexBand>
        )}

        {showCaptcha && (
          <Captcha
            verifyCallback={() => {
              this.setState({ captchaVerified: true });
            }}
            expiredCallback={() => this.setState({ captchaVerified: false })}
          />
        )}


        <div className="claim-button-container">
          <button
            className="button button-orange"
            onClick={() => !isButtonActive ? this.handlePostClick() : {}}
            disabled={isButtonActive || !captchaVerified}
          >
            {answerFor.claimId ? t('review.answer') : t('send.to.check')}
          </button>
          <span className="appear-after">{t('claim.appear.after.check')}</span>
        </div>
      </div>
    )
  }
}
