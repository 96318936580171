import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'
import classnames from 'classnames'
import './style.scss'

interface BulletProps {
  isActive: boolean
  tooltipContent: string
  id: number
}

const Bullet = (props: BulletProps) => {
  const { isActive, id, tooltipContent } = props
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)

  return (
    <span style={{ padding: "3px"}}>
      <span
        className={classnames({
          bullet: true,
          'bullet-active': isActive,
          'bullet-unactive': !isActive,
        })}
        id={`bullet-${id}`}
      ></span>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target={`bullet-${id}`}
        toggle={toggle}
        className="bullet-tooltip"
      >
        {tooltipContent}
      </Tooltip>
    </span>
  )
}

export default Bullet
