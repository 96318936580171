import React, { Component } from 'react'
import { IGtuGtc } from "./types"
import fetch from '../shared/fetch'
import Spinner from '../UI/spinner'
import { toastr } from 'react-redux-toastr'
import { confirmAlert } from 'react-confirm-alert'

import "./styles.scss"

export default class GtuGtc extends Component<IGtuGtc, {}> {
    state = {
        isAdmin: false,
        showEditState: false,
        code: null,
        isDisabled: false,
    }

    componentDidMount() {
        const { profile: { accessStatus }, code } = this.props
        this.setState({ isAdmin: accessStatus === "Admin", code })
    }

    componentDidUpdate(prevProps) {
        const { code } = this.props
        if (code !== prevProps.code) {
            this.setState({ code })
        }
    }

    resetEditState = () => {
        const { code } = this.props
        this.setState({ showEditState: false, code })
    }

    hideConfirm = () => {
        const { code } = this.props
        this.setState({ isDisabled: false, showEditState: false, code })
    }

    renderConfirmAlert = (number: number) => {
        const { t, type } = this.props

        const typeText = type === "user" ? "GTU" : "GTC"

        confirmAlert({
            title: `Предложение нового ${typeText}`,
            message: (
                <span>
                    Предложенный вами номер занят. <br />Предлагаем {number}. Подтверждаем?
                </span>
            ) as any,
            buttons: [
                {
                    label: "Подтверждаем",
                    onClick: () => {
                        this.setState({ isDisabled: false, showEditState: false, code: number }, () => {
                            this.saveCode()
                        })
                    },
                },
                {
                    label: t('button.close'),
                    onClick: this.hideConfirm,
                },
            ],
            onClickOutside: this.hideConfirm,
            onKeypressEscape: this.hideConfirm,
        })
    }

    saveCode = () => {
        const { type, id, refresh, t, getProfile } = this.props
        const { code } = this.state
        this.setState({ isDisabled: true })
        const body = JSON.stringify({
            type,
            id,
            code: Number(code)
        })

        const typeIsUser = type === "user"
        const typeText = typeIsUser ? t('gtu.was.changed') : t('gtc.was.changed')

        fetch('/common/setgtugtc', {
            method: 'POST',
            body,
        }).then(({ response }) => {
            if (Number(response) === Number(code)) {
                setTimeout(() => {
                    this.setState({ isDisabled: false, showEditState: false, }, () => {
                        getProfile(id)
                    })
                    toastr.success(typeText,
                        {
                            timeOut: 4000,
                            transitionIn: 'bounceInDown', //'fadeIn',
                            transitionOut: 'bounceOutUp', //'fadeOut',
                            position: 'top-left',
                            progressBar: true,
                        }
                    )
                    refresh()
                }, 2000);
            } else if (response === null) {
                const errorText = typeIsUser ? t('gtu.failed') : t('gtс.failed')
                toastr.error(errorText,
                    {
                        timeOut: 4000,
                        transitionIn: 'bounceInDown', //'fadeIn',
                        transitionOut: 'bounceOutUp', //'fadeOut',
                        position: 'top-left',
                        progressBar: true,
                    }
                )
                this.hideConfirm()
            } else {
                setTimeout(() => {
                    this.renderConfirmAlert(Number(response))
                }, 2000);
            }
        })
    }

    onChangeCodeInput = (code) => {
        const regex = /^[0-9]+$/
        const valid = regex.test(code)

        if (valid || code === "") {
            this.setState({ code })
        }
    }

    render() {
        const { type } = this.props
        const { isAdmin, showEditState, code, isDisabled } = this.state

        const prefix = type === "company" ? "GTC" : "GTU"
        const isNewCode = code !== this.props.code && code?.length !== 0 && code?.length === 8
        return (
            <div className="gtu-gtc-container">
                <span className="meta">{prefix}{!showEditState && code}</span>
                {showEditState && <input
                    type="text"
                    disabled={isDisabled}
                    value={code}
                    maxLength={8}
                    className={`${isDisabled && "disabled-input"}`}
                    onChange={(e) => this.onChangeCodeInput(e.target.value)}
                />}
                {isAdmin && !showEditState && <i className="fa fa-pencil col-blue" onClick={() => this.setState({ showEditState: !showEditState })} />}
                {isDisabled ? <Spinner /> : (
                    <>
                        {isNewCode && showEditState && <i className="fa fa-check-square col-green" onClick={() => this.saveCode()} />}
                        {showEditState && <i className="fa fa-close col-red" onClick={() => this.resetEditState()} />}
                    </>
                )}

            </div>
        )
    }
}
