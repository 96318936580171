import {
  TRANSPORT_CAR_NEW,
  TRANSPORT_CAR_LOADING,
  TRANSPORT_CAR_LOADED,
  TRANSPORT_CAR_LOAD_ERROR,
  TRANSPORT_CAR_LIST_LOADING,
  TRANSPORT_CAR_LIST_LOADED,
  TRANSPORT_CAR_LIST_LOAD_ERROR,
  TRANSPORT_SEARCH_START,
  TRANSPORT_SEARCH_SUCCESS,
  TRANSPORT_SEARCH_FAILURE,
  TRANSPORT_GET_UNBLOCKED_SUCCESS,
  TRANSPORT_LAST_SEARCH_START,
  TRANSPORT_LAST_SEARCH_SUCCESS,
  TRANSPORT_LAST_SEARCH_FAILURE,
  TRANSPORT_DELETE_LAST_SEARCH_SUCCESS,
  TRANSPORT_ADD_FAVOURITE_SUCCESS,
  TRANSPORT_DELETE_FAVOURITE_SUCCESS,
  TRANSPORT_ITEM_LOADING,
  TRANSPORT_ITEM_LOADED,
  TRANSPORT_ITEM_LOAD_ERROR,
  TRANSPORT_DELETE_TEMPLATE_SUCCESS,
  TRANSPORT_ADD_ERROR,
  TRANSPORT_TEMPLATE_SAVED,
  TRANSPORT_TEMPLATES_LOADED,
  TRANSPORT_CHANGE_ACTIVE,
  SET_ACTIVE_TAB
} from '../actions/action-types'
import { fromJS } from 'immutable'
import { baseProps, CarItemModel, CargoItemModel, EditModel } from '../models'
import { IListBaseProps, TransportState } from './types'

const baseList = (pageSize: number): IListBaseProps => ({
  total: 0,
  pageIndex: 0,
  pageSize,
  searchText: undefined,
  items: [],
  ...baseProps,
})
const initialState: TransportState = {
  car: {
    itemId: undefined,
    data: { ...CarItemModel },
    ...baseProps,
  },
  list: {
    ...baseList(10),
  },
  lastSearch: fromJS({
    loading: false,
    loaded: false,
    totalItems: 0,
    items: [],
    error: undefined,
  }),
  search: fromJS({
    processing: false,
    totalItems: 0,
    pageSize: 20,
    pageIndex: 0,
    sortBy: 'date-add',
    dateScope: 'all',
    items: [],
    error: undefined,
  }),
  item: {
    ...baseProps,
    ...CargoItemModel,
  },
  edit: EditModel,
  save: fromJS({
    itemId: null,
    error: null,
  }),
  templates: [],
  activeTab: "myPark",
}

function getListProps({ itemId, total = 0, pageIndex = 0, pageSize = 20, searchText, items = [] }) {
  return {
    itemId,
    total,
    pageIndex,
    pageSize,
    searchText,
    items,
  }
}

const changeFavourite = (state: TransportState, cargoId, favStatus) => {
  let itemId
  let newSearch = state.search
  const items = newSearch.get('items')
  if (items.size !== 0) {
    const item = items.find((x, id) => {
      itemId = id
      return x.get('itemId') == cargoId
    })
    const newItem = item.set('isFavourite', favStatus)
    newSearch = newSearch.set('items', items.set(itemId, newItem))
  }
  return { ...state, search: newSearch }
}

export default (state = initialState, action): TransportState => {
  const { type, payload, error, data } = action
  switch (type) {
    case TRANSPORT_ADD_ERROR: {
      const save = {
        processing: false,
        error,
      }
      return { ...state, ...save }
    }
    case TRANSPORT_SEARCH_START: {
      const { pageIndex, pageSize, sortBy, dateScope, queryStr } = payload
      const search = fromJS({
        processing: true,
        totalItems: 0,
        pageIndex,
        pageSize,
        sortBy,
        dateScope,
        items: [],
        error: undefined,
        queryStr,
      })
      state.search = search
      return state
    }
    case TRANSPORT_SEARCH_SUCCESS: {
      const { items, pageSize, pageIndex, totalItems = 0, queryStr } = payload
 
      const search = fromJS({
        ...state.search.toJS(),
        processing: false,
        totalItems,
        pageSize,
        pageIndex,
        items,
        queryStr,
        error: undefined,
      })

      return { ...state, search }
    }
    case TRANSPORT_SEARCH_FAILURE: {
      const search = fromJS({
        processing: false,
        totalItems: 0,
        items: [],
        error,
      })

      return { ...state, search }
    }
    case TRANSPORT_GET_UNBLOCKED_SUCCESS: {
      const search = state.search.toJS()

      for (const i in search.items) {
        if (search.items[i].itemId == payload.itemId) {
          search.items[i] = payload
        }
      }

      return { ...state, search: fromJS(search) }
    }
    case TRANSPORT_LAST_SEARCH_START: {
      const lastSearch = fromJS({
        loading: true,
        loaded: false,
        totalItems: 0,
        error: undefined,
      })
      return { ...state, lastSearch }
    }
    case TRANSPORT_DELETE_LAST_SEARCH_SUCCESS:
    case TRANSPORT_LAST_SEARCH_SUCCESS: {
      const { items, totalItems } = payload
      const lastSearch = fromJS({
        loading: false,
        loaded: true,
        totalItems,
        items,
        error: undefined,
      })
      return { ...state, lastSearch }
    }
    case TRANSPORT_LAST_SEARCH_FAILURE: {
      const lastSearch = fromJS({
        loading: false,
        loaded: false,
        totalItems: 0,
        items: [],
        error,
      })
      return { ...state, lastSearch }
    }
    case SET_ACTIVE_TAB: {
      return { ...state, activeTab: action.activeTab}
    }
    case TRANSPORT_ADD_FAVOURITE_SUCCESS: {
      const { item_id, isItem } = payload
      const itemState = { ...state, item: { ...state.item, isFavourite: true } }
      return changeFavourite(isItem ? itemState : state, item_id, true)
    }
    case TRANSPORT_DELETE_FAVOURITE_SUCCESS: {
      const { transportId, isItem } = payload
      const itemState = { ...state, item: { ...state.item, isFavourite: false } }
      return changeFavourite(isItem ? itemState : state, transportId, false)
    }
    case TRANSPORT_ITEM_LOADING: {
      const { itemId, requestedAt } = payload

      const item = {
        requestedAt,
        loading: true,
        error: undefined,
        itemId,
      }

      return { ...state, item: { ...state.item, ...item } }
    }
    case TRANSPORT_ITEM_LOADED: {
      const item = {
        ...action.data,
        error: undefined,
        loading: false,
      }

      return action.isEdit
        ? { ...state, edit: { ...state.edit, ...item } }
        : { ...state, item: { ...state.item, ...item } }
    }
    case TRANSPORT_ITEM_LOAD_ERROR: {
      const item = {
        loading: false,
        error,
      }

      return { ...state, item: { ...state.item, ...item } }
    }
    case TRANSPORT_CAR_NEW: {
      const car = {
        ...state.car,
        itemId: undefined,
        data: { ...CarItemModel },
        loading: false,
        loaded: false,
        error: undefined,
      }
      return { ...state, car }
    }
    case TRANSPORT_CAR_LOADING: {
      const { requestedAt, itemId } = action
      const car = {
        ...state.car,
        itemId,
        data: { ...CarItemModel }, // initial empty model
        requestedAt,
        loading: true,
        loaded: false,
        error: undefined,
      }
      return { ...state, car }
    }
    case TRANSPORT_CAR_LOADED: {
      const car = {
        ...state.car,
        data, // loaded model
        loading: false,
        loaded: true,
        error: undefined,
      }
      return { ...state, car }
    }
    case TRANSPORT_CAR_LOAD_ERROR: {
      const car = {
        ...state.car,
        error,
        loading: false,
        loaded: false,
      }
      return { ...state, car }
    }

    case TRANSPORT_CAR_LIST_LOADING: {
      const { requestedAt, pageIndex, pageSize, searchText } = action
      const list = {
        ...state.list,
        pageIndex,
        pageSize,
        searchText,
        requestedAt,
        loading: true,
        error: undefined,
      }
      return { ...state, list }
    }
    case TRANSPORT_CAR_LIST_LOADED: {
      const props = getListProps(payload)
      const list = {
        ...state.list,
        error: undefined,
        loading: false,
        ...props,
      }
      return { ...state, list }
    }
    case TRANSPORT_CAR_LIST_LOAD_ERROR: {
      const list = {
        ...state.list,
        error,
        loading: false,
        items: [],
      }
      return { ...state, list }
    }
    case TRANSPORT_TEMPLATE_SAVED: {
      return { ...state, templates: payload }
    }
    case TRANSPORT_DELETE_TEMPLATE_SUCCESS:
    case TRANSPORT_TEMPLATES_LOADED: {
      return { ...state, templates: payload }
    }

    case TRANSPORT_CHANGE_ACTIVE: {
      const { item } = state
      const search = state.search.toJS()
      item.active = !item.active
      for (const i in search.items) {
        if (search.items[i].itemId === payload.transportId) {
          search.items[i].active = !search.items[i].active
        }
      }
      return { ...state, item, search: fromJS(search) }
    }

    default:
      return state
  }
}
