import React, { Component } from 'react'
import classNames from 'classnames'

import fetch from '../../shared/fetch'
import { getClientName } from '../../shared/utils/browser'
import {
  LOGIN_SUSPENDED_ASKED_FOR_PERMISSION,
  LOGIN_SUCCESSFULLY_LOGGED_IN,
} from '../../../store/constants'

import './login-auto.scss'

const STYLE_BASE = 'login-auto_'

export default class extends Component {
  state = {
    loading: false,
    message: '',
  }

  componentDidMount() {
    const { token } = this.props.match.params

    this.setState({ loading: true })

    const { loginStart, loginSuccess, logout, history } = this.props

    loginStart()
    fetch('/auth/confirmation', {
      method: 'POST',
      body: JSON.stringify({
        token,
        clientName: getClientName(),
        socketId: window.socket.id,
      }),
    }).then(json => {
        const { status } = json
        if (status === LOGIN_SUCCESSFULLY_LOGGED_IN) {
          loginSuccess(json)
          history.push('/')
        } else if (status === LOGIN_SUSPENDED_ASKED_FOR_PERMISSION) {
        }
      })
      .catch(err => {
        let { response: { data: { error } = {} } = {} } = err
        error = error || err.message
        this.setState({ message: error, loading: false })
        logout()
      })
  }

  render() {
    const { loading, message } = this.state

    return (
      <div className={classNames(`${STYLE_BASE}wrapper`)}>
        {loading && <div>Logging in...</div>}
        {!loading && <div>{message}</div>}
      </div>
    )
  }
}
