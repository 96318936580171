import { connect } from 'react-redux'
import { compose } from 'recompose'
import withTranslation from '../../../../../../globals/components/withTranslation'
import { formValueSelector } from 'redux-form'
import Component from './component'

export default compose(
  withTranslation,
  connect((state, props) => {
    const selector = formValueSelector(props.formName)

    return {
      makeAndModel: selector(state, props.makeAndModelName),
      regNumber: selector(state, props.stateNumberName),
    }
  })
)(Component)
