import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reduxForm } from 'redux-form'
import { withRouter } from 'react-router-dom'
import Component from './component'
import withTranslation from '../../../../globals/components/withTranslation'
import { onCanAdd } from '../../../../store/actions/profile'
import { IReduxStorage } from '../../../../store/reducers/types'

const formName = 'my-ads-list'

export default compose(
  withTranslation,
  withRouter,
  connect((state: IReduxStorage) => {
    const canAdd = state.profile.canAdd
    return {
      canAdd
    }
  },{
    onCanAdd
  }),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    forceUnregisterOnUnmount: false,
  })
)(Component)
