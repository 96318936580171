type StartWith = 'lat' | 'lng'

export default (fromCoords, toCoords, middlePoints, startWith: StartWith) => {
  const endWith = startWith === 'lat' ? 'lng' : 'lat'
  const middleCoords = []
  middlePoints &&
    middlePoints.forEach(el => {
      middleCoords.push([el.coords[startWith], el.coords[endWith]])
    })
  return [
    [fromCoords[startWith], fromCoords[endWith]],
    ...middleCoords,
    [toCoords[startWith], toCoords[endWith]],
  ]
}
