import { connect } from 'react-redux'
import { compose } from 'recompose'

import withTranslation from '../../../../globals/components/withTranslation'
import withPermissions from '../../../../globals/components/withPermissions'
import { loginAllowed } from '../../../../store/actions/auth'

import Component from './component'

export default compose(
  withTranslation,
  withPermissions,
  connect(null, {
    loginAllowed,
  })
)(Component)
