export const int20 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Конвенция о гражданской ответственности за ущерб, причиненный при перевозке опасных грузов автомобильным,
 железнодорожным и внутренним водным транспортом (КГПОГ)</h4><p></p>

<p></p><h4>(Женева, 10 октября 1989 г.)</h4><p></p>

<p style="text-indent:2em;"></p><h6>Государства - участники настоящей Конвенции, Отмечая постоянный рост объема перевозок опасных грузов,
Не забывая о существовании на международном уровне технических стандартов, направленных на обеспечение
безопасности таких перевозок, Желая установить единообразные правила, обеспечивающие соответствующее и скорейшее возмещение ущерба,
 причиненного при перевозке опасных грузов автомобильным, железнодорожным и внутренним водным
 транспортом, Согласились о нижеследующем:</h6><p></p>

<p></p><h4>ОПРЕДЕЛЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 1</p>

<p style="text-indent:2em;"></p><h6>При применении настоящей Конвенции:</h6><p></p>

<p style="text-indent:2em;">1. Термин "перевозка автомобильным транспортом" означает перевозку опасных грузов дорожным
 транспортным средством;</p><br>

<p style="text-indent:2em;">2. Термин "дорожное транспортное средство" означает любой автомобиль, сочлененное транспортное
 средство, прицеп или полуприцеп в соответствии с определением, содержащимся в статье 1 Конвенции
 о дорожном движении от 8 ноября 1968 года;</p><br>

<p style="text-indent:2em;">3. Термин "перевозка железнодорожным транспортом" означает перевозку опасных грузов железнодорожным
 вагоном, включая моторный вагон или автомотрису;</p><br>

<p>4. Термин "перевозка внутренним водным транспортом" означает перевозку опасных грузов на борту судна;</p><br>

<p style="text-indent:2em;">5. Термин "судно" означает любое водное транспортное или плавучее средство любого типа, не являющееся
 морским самоходным или несамоходным транспортным средством;</p><br>

<p style="text-indent:2em;">6. Термин "транспортное средство" означает дорожное транспортное средство, железнодорожный вагон или
 судно. В случаях, когда несколько транспортных средств объединены в состав, такой состав рассматривается
 как единое транспортное средство;</p><br>

<p style="text-indent:2em;">7. Термин "лицо" означает любое физическое лицо или любое юридическое лицо публичного или частного права,
 включая государство или любую из его составных частей;</p>

<p style="text-indent:2em;"></p><h6>8. Термин "перевозчик" означает:</h6><p></p>

<p style="text-indent:2em;">a) В отношении перевозки автомобильным и внутренним водным транспортом: лицо, которое в момент инцидента
 распоряжается эксплуатацией транспортного средства, перевозящего опасные грузы.</p><br>

<p style="text-indent:2em;">Предполагается, что эксплуатацией транспортного средства распоряжается лицо, на чье имя транспортное
 средство зарегистрировано в государственном регистре, или, при отсутствии такой регистрации, собственник
 транспортного средства, если он не докажет, что эксплуатацией транспортного средства распоряжается другое
 лицо, и укажет имя этого лица или, в случае невозможности его указать, докажет, что такое другое лицо
 распоряжалось транспортным средством без его согласия и при таких обстоятельствах, при которых он не мог
 по разумным причинам предотвратить такую эксплуатацию.</p><br>

<p style="text-indent:2em;">В случае, если транспортное средство, на которое погружены опасные грузы, перевозится другим транспортным
 средством, то перевозчиком считается лицо, распоряжающееся эксплуатацией этого другого транспортного
 средства.</p><br>

<p style="text-indent:2em;">b) В отношении перевозки железнодорожным транспортом: лицо или лица, эксплуатирующие железнодорожную линию
, на которой произошел инцидент; в случае совместной эксплуатации перевозчиком считается каждое из лиц,
 принимающее в ней участие;</p><br>

<p style="text-indent:2em;">9. Термин "опасные грузы" в отношении перевозок автомобильным, железнодорожным или внутренним водным
 транспортом означает любое вещество или предмет, которое либо указано в классах Европейского соглашения
 о международной дорожной перевозке опасных грузов (ДОПОГ), либо предусмотрено в одной из сводных рубрик
 этих классов, или подпадают под действие положений этого Соглашения;</p>

<p style="text-indent:2em;"></p><h6>10. Термин "ущерб" означает:</h6><p></p>

<p style="text-indent:2em;">a) смерть любого лица или нанесение любых телесных повреждений на или вне транспортного средства,
 перевозящего опасные грузы, в результате воздействия этих грузов;</p><br>

<p style="text-indent:2em;">b) потерю или убытки, причиненные имуществу вне транспортного средства, перевозящего опасные грузы,
 в результате их воздействия, за исключением потери или убытков, причиненных другим транспортным средствам
 , входящим в тот же состав транспортных средств, либо потери или убытков, причиненных имуществу, находящемуся
 на таких транспортных средствах;</p><br>

<p style="text-indent:2em;">c) потерю или убытки в результате загрязнения окружающей среды, вызванного опасными грузами, при условии,
 что возмещение за ущерб, нанесенный окружающей среде, за исключением потери прибыли в связи с таким ущербом,
 ограничивается затратами на разумные меры по восстановлению, которые были или будут приняты;</p><br>

<p style="text-indent:2em;">d) затраты на предупредительные меры и последующие убытки или ущерб, вызванные
 предупредительными мерами.</p><br>

<p style="text-indent:2em;">В тех случаях, когда в разумных пределах невозможно провести различие между ущербом, причиненным опасными 
грузами, и ущербом, обусловленным другими факторами, все виды такого ущерба рассматриваются как причиненные
 опасными грузами;</p><br>

<p style="text-indent:2em;">11. Термин "предупредительные меры" означает любые разумные меры, принятые любым лицом, после того, как 
произошел инцидент, для предотвращения или уменьшения ущерба;</p><br>

<p style="text-indent:2em;">12. Термин "инцидент" означает любое происшествие или ряд происшествий одного и того же происхождения, 
результатом которых является ущерб или возникновение серьезной и неизбежной опасности 
причинения ущерба;</p><br>

<p style="text-indent:2em;">13. Термин "система зеленой карточки" означает международную систему страхования автотранспортных средств, 
изложенную в приложении 2 к Сводной резолюции об облегчении автомобильных перевозок (СР. 4) Европейской 
экономической комиссии Организации Объединенных Наций.</p>

<p></p><h4>СФЕРА ПРИМЕНЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 2</p>

<p style="text-indent:2em;"></p><h6>Настоящая Конвенция применяется:</h6><p></p>

<p style="text-indent:2em;">a) к ущербу, причиненному на территории государства-участника, и в результате инцидента, происшедшего 
на территории государства-участника;</p><br>

<p>b) к предупредительным мерам, принятым для предотвращения или уменьшения такого ущерба.</p><br>

<p style="font-weight:500;">Статья 3</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция применяется к искам, за исключением тех, которые возникают на основе какого-либо 
договора на перевозку грузов или пассажиров, за ущерб, причиненный в ходе перевозки опасных грузов автомобильным, 
железнодорожным или внутренним водным транспортом.</p><br>

<p style="text-indent:2em;">2. Настоящая Конвенция применяется в той мере, в какой ее положения не противоречат применимым положениям 
действующего законодательства, касающегося возмещения ущерба работникам, или систем социального 
обеспечения.</p><br>

<p style="text-indent:2em;">3. Перевозка опасных грузов автомобильным, железнодорожным или внутренним водным транспортом включает период 
от начала процесса погрузки груза на транспортное средство для перевозки до 
завершения процесса его разгрузки.</p><br>

<p style="text-indent:2em;">4. В случае, когда транспортное средство, на которое погружены опасные грузы, перевозится на протяжении части 
маршрута другим транспортным средством без разгрузки груза, считается, что на протяжении этой части маршрута 
такой груз перевозится исключительно этим другим транспортным средством.</p><br>

<p style="text-indent:2em;">5. Настоящая Конвенция не применяется в случаях, когда транспортное средство, на которое погружены опасные 
грузы, перевозится морскими самоходными судами, морскими несамоходными судами 
или воздушным транспортом.</p><br>

<p style="font-weight:500;">Статья 4</p>

<p style="text-indent:2em;"></p><h6>Настоящая Конвенция не применяется:</h6><p></p>

<p style="text-indent:2em;">a) к ущербу, причиненному в ходе перевозки, осуществляемой исключительно в месте, недоступном для посторонних 
лиц, и при условии, что такая перевозка является дополнительной к другим видам деятельности и представляет собой 
их составную часть;</p>

<p style="text-indent:2em;"></p><h6>b) к ущербу, причиненному радиоактивным веществом:</h6><p></p>

<p style="text-indent:2em;">i) если оператор ядерной установки несет ответственность за такой ущерб на основании либо Парижской конвенции 
об ответственности перед третьими лицами в области ядерной энергии от 29 июля 1960 года и дополнительного Протокола 
от 28 января 1964 года, либо Венской конвенции о гражданской ответственности за ядерный ущерб от 21 мая 1963 года, 
либо поправок к этим конвенциям; или</p><br>

<p style="text-indent:2em;">ii) если оператор ядерной установки несет ответственность за такой ущерб на основании какого-либо национального 
закона, регламентирующего ответственность за этот ущерб, при условии, что этот закон во всех отношениях не менее 
благоприятен для лиц, которые могут понести ущерб, чем положения Парижской или Венской конвенций, упомянутых в 
пункте i);</p><br>

<p style="text-indent:2em;">c) к перевозкам опасных грузов автомобильным, железнодорожным или внутренним водным транспортом, осуществляемым с 
соблюдением условий, предусмотренных в маргинальном номере 10 010, или перевозкам грузов в количествах, не превышающих 
количеств, указанных в маргинальном номере 10 011 ДОПОГ.</p>

<p></p><h4>ПОЛОЖЕНИЯ ОБ ОТВЕТСТВЕННОСТИ</h4><p></p>

<p style="font-weight:500;">Статья 5</p><br>

<p style="text-indent:2em;">1. За исключением случаев, предусмотренных в пунктах 4 и 5 настоящей статьи и в статье 6, перевозчик с момента 
инцидента несет ответственность за ущерб, причиненный любыми опасными грузами в ходе их перевозки автомобильным, 
железнодорожным или внутренним водным транспортом.</p><br>

<p style="text-indent:2em;">2. Если инцидент состоит из ряда происшествий одного и того же происхождения, то перевозчик несет ответственность 
с момента первого происшествия.</p><br>

<p style="text-indent:2em;">3. Если два или более лиц, упомянутых в пункте 8 b) статьи 1, несут ответственность в качестве перевозчика на 
основании настоящей Конвенции, то их ответственность является солидарной.</p>

<p style="text-indent:2em;"></p><h6>4. Перевозчик освобождается от ответственности, если докажет, что:</h6><p></p>

<p style="text-indent:2em;">a) ущерб явился результатом военных действий, враждебных действий, гражданской войны, восстания или стихийного 
явления исключительного, неизбежного и непреодолимого характера; или</p><br>

<p style="text-indent:2em;">b) ущерб был всецело вызван действием или упущением третьих лиц, совершенным с 
намерением причинить ущерб; или</p><br>

<p style="text-indent:2em;">c) грузоотправитель или любое другое лицо не выполнило своего обязательства уведомить его об опасном характере 
груза, и он, его служащие или агенты не знали и не должны были знать их характера.</p><br>

<p style="text-indent:2em;">5. Если перевозчик докажет, что ущерб был причинен всецело или частично в результате поведения потерпевшего лица, 
которое совершило действие или упущение с намерением причинить ущерб, либо по небрежности этого лица, перевозчик 
может быть полностью или частично освобожден от ответственности перед таким лицом.</p><br>

<p style="text-indent:2em;">6. Никакой иск о возмещении ущерба не может быть предъявлен к перевозчику иначе как в соответствии с настоящей 
Конвенцией. Однако в случае, упомянутом в пункте 4 с) настоящей статьи, ответственность за ущерб, которую может
 нести перевозчик на основании действующего законодательства, не нарушается при условии, что пределы его 
 ответственности не выходят за рамки, предусмотренные в статье 9.</p>

<p style="text-indent:2em;"></p><h6>7. При условии соблюдения пункта 9 настоящей статьи, а также статей 6 и 7, никакой иск о возмещении ущерба на 
основании настоящей Конвенции или на иных основаниях не может быть предъявлен:</h6><p></p>

<p>a) служащим или агентам перевозчика или членам экипажа;</p><br>

<p style="text-indent:2em;">b) лоцману судна или любому другому лицу, не являющемуся членом экипажа, но обслуживающему транспортное 
средство;</p><br>

<p style="text-indent:2em;">c) владельцу, нанимателю, фрахтователю, пользователю, распорядителю или оператору транспортного средства 
при условии, что он не является перевозчиком;</p><br>

<p>d) любому лицу, проводящему спасательные работы с согласия владельца судна;</p><br>

<p>e) любому лицу, проводящему спасательные работы по указанию компетентного государственного органа;</p><br>

<p style="text-indent:2em;">f) любому лицу, за исключением перевозчика, принимающему предупредительные меры, за ущерб, причиненный 
этими мерами;</p><br>

<p style="text-indent:2em;">g) любым служащим или агентам лиц, упомянутых в пунктах b), с), d), е) и f), если ущерб не был нанесен 
в результате действия или упущения этих лиц, совершенного с намерением причинить такой ущерб либо в связи с 
безответственностью и с пониманием того, что имеется возможность возникновения такого ущерба.</p><br>

<p style="text-indent:2em;">8. При применении пункта 8 статьи 1 и пункта 4 статьи 3 лицо, осуществляющее операции в соответствии с 
пунктами 7 d), е) или f) настоящей статьи, или его служащие, или агенты не рассматриваются в качестве 
перевозчика.</p><br>

<p style="text-indent:2em;">В таких случаях лицо, которое являлось перевозчиком в момент инцидента, повлекшего за собой необходимость
 осуществления таких операций, по-прежнему рассматривается в качестве перевозчика в целях настоящей 
 Конвенции.</p><br>

<p style="text-indent:2em;">9. Ничто в настоящей Конвенции не нарушает какого-либо права регресса перевозчика к отправителю или 
получателю грузов, причинивших ущерб, или к любому третьему лицу.</p><br>

<p style="font-weight:500;">Статья 6</p><br>

<p style="text-indent:2em;">1. Если перевозчик докажет, что за погрузку опасных грузов на транспортное средство и за их разгрузку 
отвечало третье лицо, не являющееся перевозчиком, его служащим или агентом, например грузоотправитель или 
грузополучатель, и укажет имя этого лица, то он освобождается от ответственности за ущерб, причиненный такими 
грузами в период погрузки или разгрузки, и ответственность за такой ущерб на основании настоящей Конвенции 
несет это третье лицо.</p><br>

<p style="text-indent:2em;">Однако если погрузочно-разгрузочные операции осуществлялись при совместной ответственности перевозчика и третьего 
лица, упомянутого в настоящем пункте, то перевозчик и это лицо несут на основании настоящей Конвенции солидарную 
ответственность за ущерб, причиненный в ходе погрузки или разгрузки.</p><br>

<p style="text-indent:2em;">При применении настоящего пункта какое-либо лицо не несет ответственности за операции по погрузке или разгрузке, 
если осуществляет эти операции от имени перевозчика или любого другого лица, например грузоотправителя или грузополучателя. 
В этом случае ответственность по-прежнему несет перевозчик или это другое лицо.</p>

<p style="text-indent:2em;"></p><h6>2. Положения настоящей Конвенции применяются к третьему лицу, упомянутому в пункте 1, соответственно при условии, 
что:</h6><p></p>

<p style="text-indent:2em;">a) пункт 6 статьи 5 не применяется к искам о возмещении ущерба к такому третьему лицу и статьи 13-17 также не 
применяются к ответственности этого лица;</p><br>

<p style="text-indent:2em;">b) ограничения, указанные в статье 9, применяются к совокупности всех исков к перевозчику или такому третьему лицу, 
основанных на любом конкретном случае;</p><br>

<p style="text-indent:2em;">c) любой фонд, учрежденный перевозчиком или таким третьим лицом в соответствии со статьей 11, рассматривается в 
качестве созданного ими обоими.</p><br>

<p style="text-indent:2em;">3. Что касается взаимоотношений между перевозчиком и любым третьим лицом, несущим ответственность в соответствии с 
пунктом 1 настоящей статьи, то ответственность налагается на это лицо, если ущерб не явился результатом упущения 
перевозчика, его служащих или агентов.</p><br>

<p style="text-indent:2em;">В случае, если ущерб явился результатом ошибки как перевозчика, его служащих или агентов, так и третьего лица, его 
служащих или агентов, то перевозчик и это лицо несут ответственность в размере, пропорциональном степени упущения 
каждого из них.</p><br>

<p style="text-indent:2em;">4. Настоящая статья не применяется, если погрузочно-разгрузочные операции производились при личной или совместной 
ответственности лица, осуществляющего операции, перечисленные в пунктах 7 d), е) и f) статьи 5.</p><br>

<p style="font-weight:500;">Статья 7</p><br>

<p style="text-indent:2em;">Если перевозчик не несет никакой ответственности в соответствии с пунктом 4 с) статьи 5, то в целях настоящей 
Конвенции вышеупомянутый грузоотправитель или третье лицо рассматриваются в качестве перевозчика. Однако пункт 
6 статьи 5 не применяется к искам о возмещении ущерба к грузоотправителю или третьему лицу, а статьи 13-17 не 
применяются к их ответственности на основании настоящей Конвенции.</p><br>

<p style="font-weight:500;">Статья 8</p><br>

<p style="text-indent:2em;">1. Если ущерб является результатом инцидента с участием двух или более транспортных средств, каждое из которых 
использовалось для перевозки опасных грузов, то каждый перевозчик, за исключением случаев, предусмотренных в пунктах 
4 и 5 статьи 5 или в статье 6, несет ответственность за ущерб. Перевозчики несут солидарную ответственность за весь 
такой ущерб, возмещение за который невозможно должным образом распределить.</p><br>

<p style="text-indent:2em;">2. Однако перевозчик имеет право ограничить свою ответственность на основании статьи 9 в отношении каждого 
транспортного средства.</p><br>

<p style="text-indent:2em;">3. Hичто в настоящей Конвенции не нарушает какого-либо права регресса одного из перевозчиков к любому 
другому перевозчику.</p>

<p></p><h4>ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ</h4><p></p>

<p style="font-weight:500;">Статья 9</p>

<p style="text-indent:2em;"></p><h6>1. Ответственность перевозчика автомобильным и железнодорожным транспортом на основании настоящей Конвенции 
по искам, предъявляемым в связи с каким-либо одним инцидентом, ограничивается следующими размерами:</h6><p></p>

<p>a) в отношении исков, связанных со смертью или телесными повреждениями: 18 млн. расчетных единиц;</p><br>

<p>b) в отношении любых других исков: 12 млн расчетных единиц.</p>

<p style="text-indent:2em;"></p><h6>2. Ответственность перевозчика внутренним водным транспортом на основании настоящей Конвенции по искам, 
предъявляемым в связи с каким-либо одним инцидентом, ограничивается следующими размерами:</h6><p></p>

<p>а) в отношении исков, связанных со смертью или телесными повреждениями: 8 млн. расчетных единиц;</p><br>

<p>b) в отношении любых других исков: 7 млн. расчетных единиц.</p><br>

<p style="text-indent:2em;">3. В случае, когда сумм, предусмотренных в пунктах 1 а) и 2 а) настоящей статьи, недостаточно для удовлетворения 
вышеупомянутых исковых требований в полном объеме, для погашения неоплаченной разницы по исковым требованиям на 
основе пунктов 1 а) и 2 а) используется сумма, установленная в соответствии с пунктами 1 b) и 2 b). 
Такая неоплаченная разница и исковые требования, упомянутые в пунктах 1 b) и 2 b), оплачиваются 
пропорционально их долям.</p><br>

<p style="font-weight:500;">Статья 10</p><br>

<p style="text-indent:2em;">1. Перевозчик не имеет права ограничить свою ответственность на основании настоящей Конвенции, если доказано, 
что ущерб возник в результате его действия или упущения, а также действия или упущения его служащих или агентов, 
совершенного с намерением причинить такой ущерб либо в связи с безответственностью и с пониманием того, что имеется 
возможность возникновения такого ущерба, при условии, что в случае такого действия или упущения со стороны служащего 
или агента также доказано, что они действовали в рамках своих служебных обязанностей.</p><br>

<p style="text-indent:2em;">2. Если перевозчик имеет требование к истцу, основанное на одном и том же инциденте, то их соответствующие исковые 
требования засчитываются по отношению друг к другу и положения настоящей Конвенции 
применяются к существующей разнице.</p><br>

<p style="text-indent:2em;">3. Перевозчик может воспользоваться правом ограничить свою ответственность, даже если упомянутый в статье 11 
лимитирующий фонд не создан.</p><br>

<p style="text-indent:2em;">4. Если право на ограничение ответственности использовано без создания лимитирующего фонда, то применяются 
положения пунктов 4-7 статьи 11, соответственно.</p><br>

<p style="text-indent:2em;">5. Процедурные вопросы, возникающие на основе положений данной статьи, решаются в соответствии с 
законодательством государства-участника, в котором предъявлен иск.</p><br>

<p style="font-weight:500;">Статья 11</p><br>

<p style="text-indent:2em;">1. Перевозчик может создать фонд в суде или другом компетентном органе любого из государств-участников, в 
котором на основании статьи 19 предъявлен иск. Если такой иск на основании статьи 19 предъявлен не в государстве-
участнике, то перевозчик может создать свой фонд в суде или другом компетентном органе любого из государств-участников, 
упомянутых и пунктах 1 а), b) или с) статьи 19 или в подпункте (d), при условии, что обычное местожительство перевозчика
 и всех пострадавших находится на территории одного и того же государства-участника. Фонд создается в размере сумм, 
 указанных в статье 9 и применимых в его случае, а также с учетом процентов за период, исчисляемый со дня инцидента до
 дня создания фонда. Любой созданный таким образом фонд служит только для удовлетворения требований по искам, 
 в отношении которых ответственность может быть ограничена на основании настоящей Конвенции.</p><br>

<p style="text-indent:2em;">2. Фонд может быть создан либо путем внесения суммы в депозит, либо путем представления банковской гарантии или любого
 другого обеспечения, допускаемого законодательством государства-участника, в котором создается фонд, и признаваемого 
 достаточным судом или другим компетентным органом.</p><br>

<p style="text-indent:2em;">3. Любое лицо, предоставившее перевозчику страховое или другое финансовое обеспечение на основании настоящей Конвенции,
 имеет право создать фонд в соответствии с настоящей статьей на тех же условиях и имеющий то же значение, как если бы 
 он был создан перевозчиком. Такой фонд может быть создан даже в том случае, если на основании пункта 1 статьи 10 
 перевозчик не имеет права ограничить свою ответственность, но в таком случае его создание не затрагивает нрав любого 
 истца по отношению к перевозчику. Данный фонд рассматривается в качестве созданного перевозчиком.</p><br>

<p style="text-indent:2em;">4. При условии соблюдения положений пункта 3 статьи 9 фонд распределяется между истцами пропорционально суммам их 
обоснованных исковых требований по отношению к фонду.</p><br>

<p style="text-indent:2em;">5. Если до распределения фонда перевозчик или его страховщик, или какое-либо лицо, предоставившее ему страховое или 
иное финансовое обеспечение, возместили ущерб, причиненный в результате инцидента, то такое лицо приобретает в пределах 
уплаченной им суммы права страхователя, которые на основании настоящей Конвенции принадлежали бы лицу, получившему 
возмещение.</p><br>

<p style="text-indent:2em;">6. Переходом права страхователя к страховщику, предусмотренным в пункте 5 настоящей статьи, может воспользоваться также 
любое лицо, иное чем названное выше, в отношении любой суммы, которую оно уплатило в порядке возмещения ущерба, при условии, 
что такой переход прав разрешен действующим законодательством.</p><br>

<p style="text-indent:2em;">7. Если перевозчик или любое другое лицо установят, что они могут быть впоследствии принуждены уплатить полностью или 
частично такую сумму компенсации, в отношении которой такое лицо могло бы воспользоваться переходом прав на основании 
пунктов 5 и 6 настоящей статьи, если бы возмещение было уплачено до распределения фонда, то суд или другой компетентный 
орган государства, в котором фонд создан, может распорядиться, чтобы достаточная сумма была временно зарезервирована для 
того, чтобы дать такому лицу возможность удовлетворить впоследствии его требования к фонду.</p>

<p style="text-indent:2em;"></p><h6>8. Если перевозчик после инцидента создал фонд на основании настоящей статьи и имеет право ограничить 
свою ответственность, то:</h6><p></p>

<p style="text-indent:2em;">a) никакое лицо, требующее возмещения ущерба, возникшего в результате этого инцидента, не имеет права на удовлетворение 
такого требования за счет какого-либо другого имущества перевозчика;</p><br>

<p style="text-indent:2em;">b) суд или другой компетентный орган любого государства-участника дает распоряжение о снятии ареста с любого имущества 
перевозчика, на которое был наложен арест в связи с требованием о возмещении ущерба, возникшего в результате того же 
инцидента, и возвращает также любой залог или под обеспечение, предоставленное с
 целью избежать такого ареста.</p><br>

<p style="text-indent:2em;">9. Пункт 8 настоящей статьи применяется, однако, лишь в том случае, если истец имеет доступ в суд, который распоряжается 
фондом, и если фонд может быть фактически использован и свободно передан для удовлетворения его иска.</p><br>

<p style="text-indent:2em;">10. При условии соблюдения положений настоящей статьи правила создания и распределения лимитирующего фонда, а также все 
связанные с этим правила процедуры определяются законодательством государства-участника, 
в котором создан фонд.</p><br>

<p style="font-weight:500;">Статья 12</p><br>

<p style="text-indent:2em;">1. "Расчетной единицей", указанной в статье 9, является единица специальных прав заимствования, соответствующая определению 
Международного валютного фонда. Суммы, упомянутые в статье 9, переводятся в национальную валюту в соответствии с ее курсом 
на день создания лимитирующего фонда или, если фонд не был создан, на день осуществления платежа или предоставления 
равноценной гарантии. Стоимость национальной валюты государства-участника, являющегося членом Международного валютного 
фонда, выраженная в специальных правах заимствования, определяется на основе метода, применяемого на вышеупомянутый день 
Международным валютным фондом для осуществления своих операций и сделок. Стоимость национальной валюты государства-участника, 
не являющегося членом Международного валютного фонда, выраженная в специальных правах заимствования, определяется на основе 
метода, устанавливаемого этим государством.</p><br>

<p style="text-indent:2em;">2. Однако Договаривающееся государство, которое не является членом Международного валютного фонда и законодательство 
которого не позволяет применять положения пункта 1 настоящей статьи, может во время ратификации, принятия, утверждения 
или присоединения, или в любой момент после этого заявить, что расчетная единица, упомянутая в этом пункте, равна 15 
золотым франкам. Золотой франк, указанный в настоящем пункте, соответствует 65,5 миллиграмма золота 0,900-й пробы. 
Перевод золотого франка в национальную валюту осуществляется в соответствии с законодательством заинтересованного 
государства-участника.</p><br>

<p style="text-indent:2em;">3. Расчет, упомянутый в последнем предложении пункта 1 настоящей статьи, и перевод, упомянутый в пункте 2, осуществляются 
таким образом, чтобы сумма в национальной валюте государства-участника по возможности соответствовала реальной стоимости сумм, 
упомянутых в статье 9, как если бы она была определена на основе положений первых трех предложений пункта 1 настоящей статьи. 
Договаривающиеся государства сообщают депозитарию о методе расчета, произведенного на основании пункта 1 настоящей статьи, или, 
в соответствующих случаях, о результате перевода, осуществленного на основании пункта 2 настоящей статьи, при сдаче на хранение 
документа о ратификации, принятии, утверждении или присоединении к настоящей Конвенции, а также в случае какого-либо изменения в 
этом методе расчета или в результатах перевода.</p>

<p></p><h4>ОБЯЗАТЕЛЬНОЕ СТРАХОВАНИЕ</h4><p></p>

<p style="font-weight:500;">Статья 13</p><br>

<p style="text-indent:2em;">1. Для обеспечения своей ответственности перевозчик обязан произвести страхование или предоставить иное финансовое обеспечение, 
например банковскую гарантию, если перевозка опасных грузов осуществляется по территории 
государства-участника.</p><br>

<p style="text-indent:2em;">2. Страхование или иное финансовое обеспечение должно охватывать весь период ответственности перевозчика на основании настоящей 
Конвенции в размере сумм, установленных в соответствии с пределами ответственности, указанными в статье 9, а также ответственность 
лица, указанного в свидетельстве в качестве перевозчика, или, если это лицо не является перевозчиком по определению пункта 8 статьи 
1, такого лица, которое несет ответственность на основании настоящей Конвенции.</p><br>

<p style="text-indent:2em;">3. Любые суммы, предоставление которых гарантируется страхованием или иным финансовым обеспечением в соответствии с пунктом 1 
настоящей статьи, должны использоваться исключительно для удовлетворения исков, 
предъявляемых на основании настоящей Конвенции.</p><br>

<p style="font-weight:500;">Статья 14</p><br>

<p style="text-indent:2em;">1. Каждое государство-участник назначает один или несколько компетентных органов, уполномоченных выдавать или удостоверять 
свидетельства, подтверждающие, что перевозчики, подпадающие под определение пункта 8 а) статьи 1, имеют действительное страховое 
или иное финансовое обеспечение в соответствии с положениями настоящей Конвенции.</p>

<p style="text-indent:2em;"></p><h6>2. Свидетельство выдается или удостоверяется компетентным органом:</h6><p></p>

<p style="text-indent:2em;">a) государства регистрации в отношении перевозчика,
 чье транспортное средство зарегистрировано в государстве-участнике; или</p><br>

<p style="text-indent:2em;">b) государства - участника, в котором находится основное коммерческое предприятие перевозчика или, в случае отсутствия такового, 
его обычного местожительства, если транспортное средство не зарегистрировано.</p><br>

<p style="text-indent:2em;">В отношении перевозчика, не упомянутого в подпунктах а) или b) первого предложения настоящего пункта, свидетельство выдается или 
удостоверяется компетентным органом государства-участника, по 
территории которого осуществляется перевозка опасных грузов.</p>

<p style="text-indent:2em;"></p><h6>3. Свидетельство должно содержать следующие сведения:</h6><p></p>

<p>a) номер свидетельства;</p><br>

<p>b) тип и конкретные данные о дорожном транспортном средстве или судне;</p><br>

<p style="text-indent:2em;">c) имя перевозчика и местонахождение его главного предприятия или, в случае
 отсутствия такового, обычное местожительство;</p><br>

<p>d) вид обеспечения;</p><br>

<p style="text-indent:2em;">e) название и местонахождение основного коммерческого предприятия страховщика или
 другого лица, предоставившего обеспечение;</p><br>

<p style="text-indent:2em;">f) срок действия свидетельства, который не может превышать срока действия страхования
 или иного обеспечения.</p><br>

<p>4. Свидетельство предъявляется для проверки по требованию компетентных органов.</p><br>

<p style="text-indent:2em;">5. Свидетельство должно составляться на английском или французском языке или, если оно составлено на каком-либо другом языке, 
содержать перевод по крайней мере на один из этих языков.</p><br>

<p style="text-indent:2em;">6. Государство, в котором выдается или удостоверяется свидетельство, определяет условия выдачи и действия свидетельства при 
условии соблюдения положений настоящей Конвенции.</p><br>

<p style="text-indent:2em;">7. Свидетельства, выданные в государстве-участнике, признаются во всех государствах-участниках для всех целей настоящей Конвенции. 
Однако государство-участник, если оно считает, что страховщик или иное указанное в свидетельстве лицо, предоставившее обеспечение, 
не способно в финансовом отношении выполнить обязательства на основании настоящей Конвенции, может в любой момент обратиться к государству, 
которое выдало свидетельство, с просьбой о консультации.</p><br>

<p style="text-indent:2em;">Каждое государство-участник назначает компетентный орган для отправки или получения любого сообщения, касающегося обязательного 
страхования или любого другого финансового обеспечения.</p><br>

<p style="text-indent:2em;">Любое государство-участник может признавать свидетельства, выданные для целей настоящей Конвенции компетентными органами или признаваемыми 
ими организациями государств, не являющихся участниками.</p><br>

<p style="text-indent:2em;">8. Страхование или иное финансовое обеспечение не удовлетворяет требованиям настоящей Конвенции, если его действие может прекратиться по 
иным причинам, чем истечение указанного в свидетельстве срока действия; до истечения трех месяцев с момента уведомления о таком прекращении, 
сделанного органу, указанному в пункте 2 настоящей статьи, кроме случаев, когда данное свидетельство было возвращено этим органом или когда в 
этот период было выдано новое свидетельство. Предшествующие положения применяются аналогичным образом ко всякому изменению, в результате которого 
страхование или гарантия прекращает удовлетворять требованиям настоящей Конвенции.</p><br>

<p style="text-indent:2em;">9. Положения настоящей статьи не применяются в случае перевозки, осуществляемой автотранспортным средством по территории какого-либо государства 
- участника, которое является участником системы зеленой карточки, если в отношении транспортного средства действует и предъявлена для проверки по 
требованию компетентных органов зеленая карточка, свидетельствующая о страховании ответственности
 перевозчика на основании настоящей Конвенции.</p><br>

<p style="text-indent:2em;">10. Два или более государства-участника могут договориться об освобождении от требований настоящей статьи, касающихся свидетельства или зеленой 
карточки, упомянутой в пункте 9 настоящей статьи, транспортных средств, зарегистрированных на их территориях и используемых для автомобильной 
перевозки грузов на основании настоящей Конвенции по их территориям.</p><br>

<p style="font-weight:500;">Статья 15</p><br>

<p style="text-indent:2em;">1. Всякий иск о возмещении ущерба на основании статей 5 и 6 может быть предъявлен непосредственно к страховщику или другому лицу, предоставившему 
финансовое обеспечение ответственности перевозчика или, в случае автотранспортного средства, к которому применяется система зеленой карточки, к 
страховщику или бюро системы зеленой карточки государства, в котором произошел инцидент.</p>

<p style="text-indent:2em;"></p><h6>2. В случае, указанном в пункте 1 настоящей статьи,
 ответчик может воспользоваться:</h6><p></p>

<p style="text-indent:2em;">a) ограничением ответственности на основании статьи 9, применимой к перевозчику, независимо от того, имеет ли перевозчик право ограничить 
свою ответственность, и</p><br>

<p style="text-indent:2em;">b) возражениями, кроме банкротства или ликвидации предприятия
 перевозчика, которые перевозчик имеет право использовать.</p><br>

<p style="text-indent:2em;">Ответчик не может использовать никакое другое из средств защиты, на которое он имел бы право сослаться в деле, возбужденном против него 
перевозчиком, при этом он не может оспаривать какое-либо положение договора страхования
 или иного финансового обеспечения.</p><br>

<p style="text-indent:2em;">3. Ответчик имеет право во всех случаях требовать, чтобы перевозчик был привлечен
 к участию в деле наравне с ним.</p><br>

<p style="font-weight:500;">Статья 16</p><br>

<p style="text-indent:2em;">1. Что касается перевозки автомобильным и внутренним водным транспортом, то в случае, когда перевозчиком является государство-участник или 
любая составная его часть и когда перевозка осуществляется на некоммерческой государственной основе, это государство может установить, что 
перевозчик освобождается от обязательства обеспечить свою ответственность посредством 
страхования или другой финансовой гарантии.</p><br>

<p style="text-indent:2em;">2. Если в соответствии с пунктом 1 настоящей статьи страхование или иное финансовое обеспечение не распространяется на транспортное средство, 
то положения настоящей Конвенции, касающиеся обязательного страхования, не применяются к такому транспортному средству. Однако свидетельство, 
выданное компетентными органами и удостоверяющее, что перевозчиком является государство-участник или его составная часть и что ответственность 
перевозчика обеспечена в пределах, предписанных настоящей Конвенцией, предъявляется для проверки по требованию органов, указанных в пункте 4 
статьи 14.</p><br>

<p style="text-indent:2em;">3. Свидетельство, упомянутое в пункте 2 настоящей статьи, должно соответствовать
 положениям пункта 3 статьи 14.</p><br>

<p style="text-indent:2em;">4. Что касается перевозки железнодорожным транспортом, то в случае, когда перевозчиком является государство-участник млн любая его составная 
часть, либо организация, полностью принадлежащая или контролируемая в финансовом отношении государством-участником, это государство может установить, 
что перевозчик освобождается от обязательства обеспечить свою ответственность
 посредством страхования или другой финансовой гарантии.</p><br>

<p style="text-indent:2em;">Аналогичное право на освобождение предоставляется государству, в котором находится железнодорожная компания, принадлежащая или контролируемая в 
финансовом отношении двумя или более государствами-участниками.</p><br>

<p style="font-weight:500;">Статья 17</p><br>

<p style="text-indent:2em;">Договаривающееся государство принимает соответствующие законодательные меры с целью обеспечить соблюдение положений настоящей Конвенции, 
касающихся обязательного страхования.</p>

<p></p><h4>ТРЕБОВАНИЯ И ИСКИ</h4><p></p>

<p style="font-weight:500;">Статья 18</p><br>

<p style="text-indent:2em;">1. Права на возмещение на основании настоящей Конвенции утрачиваются, если требование не предъявлено в течение трех лет с момента, когда лицо, 
понесшее ущерб, узнало или при разумных обстоятельствах должно было бы узнать, какой был нанесен ущерб и кто является перевозчиком. Этот период 
может быть продлен по решению сторон после инцидента.</p><br>

<p style="text-indent:2em;">2. Однако в любом случае требование не может быть предъявлено
 по истечении десяти лет с момента инцидента, явившегося причиной ущерба.</p><br>

<p style="text-indent:2em;">3. Если инцидент состоял из ряда происшествий, то периоды, упомянутые в пунктах 1 и 2 настоящей статьи, исчисляются с момента последнего 
из этих происшествий.</p><br>

<p style="font-weight:500;">Статья 19</p>

<p style="text-indent:2em;"></p><h6>1. Требования о возмещении на основании какого-либо положения настоящей Конвенции могут быть предъявлены только в судах любого 
государства-участника:</h6><p></p>

<p>a) на территории которого был нанесен ущерб в результате инцидента; или</p><br>

<p>b) на территории которого произошел инцидент; или</p><br>

<p style="text-indent:2em;">с) на территории которого были приняты предупредительные
 меры для предотвращения или сокращения ущерба; или</p><br>

<p>d) на территории которого находится обычное местожительство перевозчика.</p><br>

<p>В разумные сроки ответчик должен быть уведомлен о предъявлении такого требования.</p><br>

<p style="text-indent:2em;">2. Если дорожное транспортное средство или судно, участвовавшее в инциденте, подлежит регистрации, то государство регистрации дорожного 
транспортного средства или судна рассматривается в качестве обычного местожительства перевозчика.</p><br>

<p style="text-indent:2em;">3. Каждое Договаривающееся государство должно обеспечить, чтобы его суды обладали юрисдикцией, необходимой для рассмотрения таких 
требований о возмещении.</p><br>

<p style="text-indent:2em;">4. После создания фонда решение всех вопросов его пропорционального деления и распределения входит исключительно в компетенцию судов государства,
 в котором создан такой фонд.</p><br>

<p style="font-weight:500;">Статья 20</p>

<p style="text-indent:2em;"></p><h6>1. Всякое решение суда, обладающего юрисдикцией в соответствии со статьей 19, которое может быть исполнено в государстве принадлежности суда, 
где оно не подлежит пересмотру в обычном порядке, признается в любом государстве-участнике,
 за исключением случаев, когда:</h6><p></p>

<p>a) решение вынесено на основе обмана; или</p><br>

<p>b) ответчик не был уведомлен в разумные сроки и не имел возможности защищать свое дело в суде; или</p><br>

<p style="text-indent:2em;">c) это решение противоречит решению по одному к тому же делу между теми же сторонами в государстве, где необходимо его признание, или в другом 
государстве-участнике, обладающем юрисдикцией в соответствии со статьей 19, и уже признанному 
в государстве, где необходимо такое признание.</p><br>

<p style="text-indent:2em;">2. Решение, признанное на основании пункта 1 настоящей статьи, должно подлежать исполнению в любом государстве-участнике непосредственно после 
соблюдения формальностей, требуемых в этом государстве. Эти формальности не должны
 допускать пересмотра дела по существу.</p><br>

<p style="font-weight:500;">Статья 21</p><br>

<p style="text-indent:2em;">В том случае, когда два или более государства-участника имеют обязательства на основании какой-либо международной конвенции, устанавливающей 
правила, касающиеся юрисдикции, или предусматривающей признание и исполнение в каком-либо государстве решений, принятых судами другого государства, 
то положения, содержащиеся в таких соглашениях, применяются вместо аналогичных положений, приведенных в пунктах 1, 2 и 3 статьи 19 и в статье 20 
настоящей Конвекции.</p>

<p></p><h4>ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 22</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция открыта для подписания ее всеми государствами в Женеве с 1 февраля 1990 года до 31 декабря 1990 года 
включительно.</p><br>

<p style="text-indent:2em;">2. Настоящая Конвенция подлежит ратификации, принятию или утверждению государствами,
 которые ее подписали.</p><br>

<p style="text-indent:2em;">3. Настоящая Конвенция открыта для присоединения к ней всех государств, не являющихся подписавшими ее государствами, с 1 
января 1991 года.</p><br>

<p style="text-indent:2em;">4. Ратификация, принятие, утверждение или присоединение осуществляются путем сдачи на хранение депозитарию соответствующего 
официального документа.</p><br>

<p style="font-weight:500;">Статья 23</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция вступает в силу в первый день месяца, следующего за истечением двенадцати месяцев с даты сдачи на хранение 
пятого документа о ратификации, принятии, утверждении или присоединении.</p><br>

<p style="text-indent:2em;">2. Для каждого государства, которое ратифицирует, принимает, утверждает настоящую Конвенцию или присоединяется к ней после сдачи на 
хранение пятого документа о ратификации, принятии, утверждении или присоединении, настоящая Конвенция вступает в силу в первый день месяца,
 следующего за истечением двенадцати месяцев с даты сдачи на хранение этим государством документа о ратификации, принятии, утверждении или 
 присоединении.</p><br>

<p style="text-indent:2em;">3. Любой документ о ратификации, принятии, утверждении или присоединении, сданный на хранение после вступления в силу любого протокола, 
изменяющего настоящую Конвенцию, считается относящимся к измененному тексту настоящей Конвенции.</p><br>

<p style="font-weight:500;">Статья 24</p>

<p style="text-indent:2em;"></p><h6>1. Любое государство может при подписании, ратификации, принятии, утверждении или
 присоединении заявить, что:</h6><p></p>

<p style="text-indent:2em;">a) в отношении исков, связанных с ущербом согласно любому или всем видам ущерба, перечисленным в пункте 10 статьи 1, оно будет применять 
более высокие пределы ответственности по сравнению с установленными в статье 9 или что не будет применять никаких пределов ответственности 
за ущерб, причиненный в результате инцидента, происшедшего на его территории, при условии, что в этом государстве режим ответственности, 
регулирующий вопросы компенсации за такой ущерб, имеет такой же характер, как и режим, предусмотренный настоящей Конвенцией.</p><br>

<p style="text-indent:2em;">Такое государство может обязать перевозчиков, чьи транспортные средства зарегистрированы на его территории или, если транспортные 
средства не зарегистрированы, перевозчиков, основное коммерческое предприятие или обычное местожительство которых находится на его 
территории, обеспечить свою ответственность посредством страхования или другой финансовой гарантии в пределах, выше предусмотренных 
пунктом 2 статьи 13 настоящей Конвенции;</p><br>

<p>b) оно не будет применять положения пункта 4 а) или b) статьи 5;</p><br>

<p style="text-indent:2em;">c) оно будет применять вместо положений пункта 5 статьи 5 свое национальное законодательство при условии, что такое законодательство 
предусматривает сокращение размеров возмещения или полный отказ в возмещении лишь в случае преднамеренного действия или грубой небрежности 
пострадавшего или лица, имеющего право требовать возмещение.</p><br>

<p style="text-indent:2em;">2. Право делать заявления, предусмотренные пунктом 1, предоставляется государству лишь в том случае, если его национальное 
законодательство находится в соответствии с условиями, изложенными в соответствующих подпунктах этого пункта в момент вступления 
Конвенции в силу и в любом случае не позднее чем через пять лет после открытия настоящей
 Конвенции для подписания.</p><br>

<p style="text-indent:2em;">3. Государство, которое использует право, предоставляемое пунктом 1, сообщает депозитарию содержание своего национального 
законодательства.</p><br>

<p>4. Никакие другие оговорки в отношении настоящей Конвенции не допускаются.</p><br>

<p style="font-weight:500;">Статья 25</p><br>

<p style="text-indent:2em;">1. Оговорки, сделанные на основании настоящей Конвенции в момент подписания, подлежат подтверждению при ратификации, 
принятии или утверждении.</p><br>

<p style="text-indent:2em;">2. Оговорки и подтверждения оговорок должны быть в
 письменной форме и должны официально доводиться до сведения депозитария.</p><br>

<p style="text-indent:2em;">3. Любая оговорка вступает в силу для соответствующего государства одновременно
 со вступлением в силу настоящей Конвенции.</p><br>

<p style="text-indent:2em;">4. Любое государство, которое делает оговорку на основании настоящей Конвенции, может в любой момент отменить ее, официально 
уведомив об этом в письменной форме депозитария. Такая отмена вступает в силу в первый день месяца по истечении шести месяцев 
с момента получения такого уведомления депозитарием.</p><br>

<p style="font-weight:500;">Статья 26</p><br>

<p style="text-indent:2em;">Каждое государство в момент подписания, ратификации, принятия, утверждения или присоединения сообщает депозитарию название 
компетентного органа или органов, назначенных им на основании пунктов 1 и 7 статьи 14.</p><br>

<p style="font-weight:500;">Статья 27</p><br>

<p style="text-indent:2em;">Настоящая Конвенция применяется к любому инциденту, определенному в пункте 12 статьи 1, произошедшему после вступления ее в
 силу.</p><br>

<p style="font-weight:500;">Статья 28</p><br>

<p style="text-indent:2em;">1. С целью пересмотра настоящей Конвенции или внесения в нее поправок Комитетом по внутреннему транспорту Европейской экономической 
комиссии Организации Объединенных Наций может быть созвана Конференция.</p><br>

<p style="text-indent:2em;">2. По просьбе не менее одной трети государств-участников, но в количестве не менее трех Комитет по внутреннему транспорту созывает 
Конференцию Договаривающихся государств для пересмотра настоящей Конвенции и внесения в нее поправок.</p><br>

<p style="font-weight:500;">Статья 29</p><br>

<p style="text-indent:2em;">1. По просьбе не менее одной четверти государств-участников, но в количестве не менее трех депозитарий обязан направить всем 
Договаривающимся государствам любое предложение об изменении пределов ответственности,
 установленных в статье 9.</p><br>

<p style="text-indent:2em;">2. Любая предлагаемая поправка, направленная в соответствии с вышеуказанной процедурой, представляется на рассмотрение комитета, 
образуемого Комитетом по внутреннему транспорту Европейской экономической комиссии Организации Объединенных Наций и включающего 
по одному представителю от каждого Договаривающегося государства, не позднее шести 
месяцев со дня ее распространения.</p><br>

<p style="text-indent:2em;">3. Поправки принимаются большинством в две трети Договаривающихся государств, присутствующих и участвующих в голосовании в комитете, 
при условии, что в момент голосования присутствуют представители не менее половины 
Договаривающихся государств.</p><br>

<p style="text-indent:2em;">4. При рассмотрении предложении об изменении пределов ответственности комитет обязан учитывать имеющиеся данные об инцидентах и, в 
частности, размеры причиненного ущерба, изменения валютных курсов и влияние
 предлагаемой поправки на стоимость страхования.</p><br>

<p style="text-indent:2em;">5. а) Никакое изменение пределов ответственности на основании настоящей статьи не может быть рассмотрено ранее чем через пять лет с 
момента открытия настоящей Конвенции для подписания, и не ранее чем через пять лет с момента вступления в силу предыдущей поправки на 
основании настоящей статьи. До вступления в силу настоящей Конвенции никакие
 поправки на основании настоящей статьи не рассматриваются.</p><br>

<p style="text-indent:2em;">b) Никакой предел ответственности не может быть установлен свыше суммы, соответствующей пределу, определенному настоящей Конвенцией, 
с учетом ежегодного увеличения на шесть процентов, начисляемого на основе сложных процентов с момента открытия настоящей Конвенции 
для подписания.</p><br>

<p style="text-indent:2em;">c) Никакой предел ответственности не может быть увеличен свыше суммы, соответствующей пределу, установленному в настоящей Конвенции 
и умноженному на три.</p><br>

<p style="text-indent:2em;">6. Депозитарий сообщает всем Договаривающимся государствам о любой поправке, принятой в соответствии с пунктом 3 настоящей статьи. 
Такая поправка считается принятой по истечении периода в восемнадцать месяцев с момента уведомления, если в течение этого периода нe 
мeнee одной четверти Договаривающихся государств, являющихся таковыми в момент принятия поправки комитетом, не уведомили депозитария 
о несогласии принять эту поправку, в результате чего она отклоняется и не вступает в силу.</p><br>

<p style="text-indent:2em;">7. Поправка, которая считается принятой на основании пункта 6 настоящей статьи, вступает в силу через восемнадцать месяцев после 
принятия.</p><br>

<p style="text-indent:2em;">8. Поправка обязательна для всех Договаривающихся государств, если они не денонсируют настоящую Конвенцию в соответствии с пунктом 1 
статьи 30 по крайней мере за шесть месяцев до вступления в силу данной поправки. Такая денонсация вступает в силу в момент вступления 
поправки в силу.</p><br>

<p style="text-indent:2em;">9. Если комитет принял поправку на основании настоящей статьи, но срок в восемнадцать месяцев для ее принятия не истек, то эта 
поправка в случае ее вступления в силу обязательна для государства, которое стало Договаривающимся в ходе этого периода. Поправка, 
принятая в соответствии с пунктом 6 настоящей статьи, обязательна для государства, ставшего Договаривающимся после истечения этого 
периода. В случаях, упомянутых в настоящем пункте, поправка становится обязательной для государства в момент вступления в силу этой 
поправки или настоящей Конвенции, если в отношении этого государства последняя вступает в силу позднее.</p><br>

<p style="font-weight:500;">Статья 30</p><br>

<p>1. Любое Договаривающееся государство может в любой момент денонсировать настоящую Конвенцию.</p><br>

<p>2. Денонсация осуществляется путем передачи депозитарию соответствующего документа.</p><br>

<p style="text-indent:2em;">3. Денонсация вступает в силу в первый день месяца по истечении шести месяцев с момента 
передачи депозитарию документа о денонсации. </p><br>

<p style="text-indent:2em;">В том случае, если в документе о денонсации предусмотрен более длительный период вступления в силу, то денонсация вступает в силу по
 истечении такого периода после передачи его депозитарию.</p><br>

<p style="font-weight:500;">Статья 31</p>

<p style="text-indent:2em;"></p><h6>1. Генеральный секретарь Организации
 Объединенных Наций является депозитарием настоящей Конвенции:</h6><p></p>

<p style="text-indent:2em;"></p><h6>2. Депозитарий:</h6><p></p>

<p style="text-indent:2em;"></p><h6>а) уведомляет все подписавшие настоящую Конвенцию или присоединившиеся к ней государства:</h6><p></p>

<p style="text-indent:2em;">i) о каждом новом подписании или о передаче на хранение документа о ратификации, принятии, утверждении или присоединении с 
указанием соответствующих дат;</p><br>

<p>ii) о каждом заявлении, сделанном на основании пункта 2 статьи 12;</p><br>

<p style="text-indent:2em;">iii) о каждой оговорке, сделанной на основании статьи 24, включая каждое сообщение, полученное на основании пункта 3 
этой статьи;</p><br>

<p>iv) об отмене любой оговорки, на основании пункта 4 статьи 25;</p><br>

<p>v) о каждом сообщении, полученном на основании статьи 26;</p><br>

<p>vi) о каждой просьбе, полученной на основании пункта 2 статьи 28 и пункта 1 статьи 29;</p><br>

<p>vii) о дате вступления в силу настоящей Конвенции и любой поправки к ней на основании статей 28 и 29;</p><br>

<p style="text-indent:2em;">viii) о передаче на хранение документа о денонсации настоящей Конвенции с указанием даты его передачи и вступления в 
силу;</p><br>

<p style="text-indent:2em;">b) препровождает надлежащим образом заверенные копии настоящей Конвенции всем подписавшим Конвенцию и всем 
присоединившимся к ней государствам.</p><br>

<p style="text-indent:2em;">В УДОСТОВЕРЕНИЕ ЧЕГО нижеподписавшиеся, надлежащим образом
 на то уполномоченные, подписали настоящую Конвенцию.</p><br>

<p style="text-indent:2em;">СОВЕРШЕНО в Женеве десятого октября тысяча девятьсот восемьдесят девятого года в одном экземпляре на английском, французском 
и русском языках, причем все три текста являются равно аутентичными.</p><br>

<p>10 октября 1989 г.</p><br>

<p><a href="http://docs.cntd.ru/document/901756195">Источник</a></p>

</div>
`