export const md17 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>ИНСТРУКЦИЯ о порядке разрешения и осуществления перевозок с весом и/или габаритами, превышающими  предельные значения, установленные действующим законодательством и нормативами</h4><p></p>

<p></p><h4>Глава I. Общие положения</h4><p></p>

<p style="font-weight:500;">1.1. Настоящая  инструкция  составлена в соответствии с  положениями ст.2  п.2  Закона  о  дорогах N 509-ХIII от 22.06.95г.;  ст.6  Закона  о дорожном фонде N 720-ХIII от 02.02.96г.; гл.V
 Положения о формировании и использовании  дорожного  фонда  N893-VIII от 26.06.96г.; гл. ХI  и  ХII Правил  дорожного движения N 552 от 07.08.95г. При разработке  настоящей инструкции учтены
 рекомендации международных отраслевых организаций.</p><br>

<p style="font-weight:500;">1.2. Передвижение  автомобилей,  сочлененных транспортных средств  и автопоездов  с  или без груза, самоходных и прицепных механизмов,  масса и/или  габариты  которых превышают установленные
 законом пределы,  может быть осуществлено по автомобильным дорогам только если они соответствуют требованиям   безопасности  движения,  официально  утверждены  и   имеют специальное   транспортное
 разрешение,  выданное  органами   управления автомобильными дорогами.</p><br>

<p style="font-weight:500;">1.3 Буксировка    тракторами   по   автомобильным   дорогам   общего пользования  прицепов  и  полуприцепов с негабаритными  и  тяжеловесными грузами  запрещена. В исключительных случаях могут быть
 разрешены  такие перевозки  общим  полезным весом до 20 тонн, на  ограниченных  участках, сухой  проезжей части, эффективными тормозными системами на транспортном средстве  и  прицепах, а также с
 соблюдением всех условий,  указанных  в специальном транспортном разрешении.</p>

<p style="text-indent:2em;"></p><h6>1.4 В Республике   Молдова   установлены  следующие  пределы   масс, габаритов  транспортных средств и нагрузок, разрешенных для передвижения по автомобильным дорогам общего пользования:</h6><p></p>

<p style="text-indent:2em;"></p><h6>А) Максимальные предельные нагрузки на оси:</h6><p></p>

<p>Категория дороги с точки зрения дорожной одежды</p><br>

<p>Тип оси</p><br>

<p>Расстояния между смежными осями (м) &lt;1,4 &lt;=2,0 &gt;2,0</p><br>

<p>&gt;Национальные дороги с капитальным типом покрытия</p><br>

<p>Одиночная 10,0 Сдвоенная (тандем) 16,0 Трехосная 22,0</p><br>

<p>Другие дороги с капитальным типом покрытия</p><br>

<p>&gt;Одиночная 8,0 Сдвоенная (тандем) 14,5 Трехосная 20,0</p><br>

<p>Щебеночные дороги</p><br>

<p>Одиночная 7,5 Сдвоенная (тандем) 12,0 Трехосная 16,5</p><br>

<p style="text-indent:2em;">Считается сдвоенными  осями  (тандем)  сочленение  из  двух  осей  с расстоянием  между  ними не более 2,0 м. При неравных нагрузках  на  оси нагрузка  на  более  загруженную  ось,
 составляющую  тандем,  не  должна превышать   максимально   допустимую  нагрузку  на  одиночную  ось   для соответствующей категории дороги (10,0 т; 8,0 т; соответственно 7,5 т).</p><br>

<p style="text-indent:2em;">Считается трехосной осью сочленение из трех осей с расстоянием между ними  не  более  1,40  м, а нагрузка на  две  рядом  расположенные  оси, составляющие  трехосную ось, не должна превышать максимально
  допустимую нагрузку  на  сдвоенную  ось (с таким же расстоянием  между  осями)  для соответствующей  категории  дороги. Для многократных осей, состоящих  из более  чем 3 смежных осей, масса на любую смежную
  группу осей не  должна превышать   максимально  допустимую  нагрузку  на  ось,  таким   образом составленную  (в  зависимости  от  расстояния между  осями  и  категории дороги).</p>

<p style="text-indent:2em;"></p><h6>В) Максимально  допустимая  масса  транспортных  средств  не  должна превышать приведенные ниже значения:</h6><p></p>

<p style="text-indent:2em;"></p><h6>Грузовые автомобили:</h6><p></p>
	
<p style="text-indent:2em;"></p><h6>a) Двухосные с расстоянием между осями:</h6><p></p>
	
<p>- менее 4,0м .............................................16,0 т;</p><br>
	
<p>- равным или более 4,0м...................................18,0 т;</p><br>
	
<p>b) Трехосные .............................................24,0 т;</p><br>
	
<p>c) Четырехосные ..........................................32,0 т;</p><br>
	
<p>d) Автобус сочлененный, трехосный ........................26,0 т;</p>
	
<p style="text-indent:2em;"></p><h6>Прицепы:</h6><p></p>
	
<p style="text-indent:2em;"></p><h6>a) Двухосные с расстоянием между осями:</h6><p></p>
	
<p>- менее 4,0м .............................................16,0 т;</p><br>
	
<p>- равным или более 4,0м ..................................18,0 т;</p><br>
	
<p>- трехосные ..............................................24,0 т;</p>
	
<p style="text-indent:2em;"></p><h6>Сочлененные транспортные средства:</h6><p></p>
	
<p style="text-indent:2em;"></p><h6>a) Тягач с седлом и полуприцепом:</h6><p></p>
	
<p>- Трехосный ..............................................30,0 т;</p>
	
<p style="text-indent:2em;"></p><h6>- Четырехосный с расстоянием между осями:</h6><p></p>
	
<p>- менее 2,0м .............................................36,0 т;</p><br>
	
<p>- равным или более 2,0м ..................................38,0 т;</p><br>
	
<p>b) Автопоезд (автомобиль + прицеп = всего четыре оси) ....36,0 т;</p><br>
	
<p>c) Сочлененные  транспортные  средства, состоящие из пяти или  более
осей .........................................................40,0 т;</p>

<p style="text-indent:2em;"></p><h6>С) Максимальные   габаритные  размеры  (в  т.ч.  груза)  не   должны превышать приведенные ниже значения:</h6><p></p>

<p style="text-indent:2em;">Ширина.....................................................2,50 м (в качестве исключения,    для    рефрижераторных   автомобилей
    с изотермическим  кузовом  с  толстыми  стенами  допускается  максимальная ширина 2,60м)</p><br>

<p>Высота.....................................................4,0 м</p><br>
	
<p>Длина......................................................12,0 м</p><br>
	
<p>- автомобиль без прицепа...................................12,0 м</p><br>
	
<p>- прицеп...................................................12,0 м</p><br>
	
<p>- сочлененный автомобиль...................................16,5 м</p><br>
	
<p>- сочлененный автобус или троллейбус.......................18,0 м</p><br>
	
<p>- автомобиль с прицепом (автопоезд)........................20,0 м</p><br>

<p style="font-weight:500;">1.5. Каждый автомобиль  или сочленение автомобилей в действии должно вписаться  в круговой плоскости с внешним радиусом в 12,5 м и внутренним радиусом  5,3  м.  Расстояние  между осью  сочленения  и  задней  точкой 
сочлененного автомобиля не должно превышать 12,0 м.</p><br>

<p style="font-weight:500;">1.6. Под \"предметами\"   подразумевается:  оборудование,  устройства, металлические изделия, детали, узлы, запасные части, связки любого рода, которые следует перевезти по автомобильным дорогам общего пользования.</p><br>

<p style="text-indent:2em;">Под \"превышением  веса\"  подразумевается превышение нагрузки на  ось или общей массы по отношению к установленным пределам (см. п. А, В). Под \"превышением   габаритов\"  подразумевается  превышение  пределов  длины,
 ширины или высоты, предусмотренных п. С.</p>

<p></p><h4>Глава II Выдача и оплата специальных транспортных разрешений</h4><p></p>

<p style="font-weight:500;">2.1. Передвижение  по дорогам общественного пользования транспорта с грузами  и/или  габаритами,  превышающими законные  положения,  возможно только  от  случая  к  случаю на  основании  специального  транспортного разрешения,
  выдаваемого Министерством транспорта и связи и  Управлением дорожной полиции.</p>

<p style="text-indent:2em;"></p><h6>2.1.1. Специальное транспортное разрешение выдается, учитывая:</h6><p></p>

<p>- условия, предписанные   предварительным  заключением,   выдаваемым Министерством транспорта и связи;</p><br>

<p style="text-indent:2em;">- что соответствующая  перевозка  должна осуществляться  в  условиях безопасности  дорожного  движения  для  него  и  остальных  автомобилей, участвующих в движении;</p><br>

<p>- соблюдение экологических условий;</p><br>

<p>- защиту несущих  балок  мостов или настила,  принадлежащих  другому пути сообщения;</p><br>

<p style="text-indent:2em;">- защиту устройств,   пересекающих   дорогу,  дорожных   насаждений, строений,  необходимых  для  дорожной сигнализации, находящихся  в  зоне автомобильной дороги;</p><br>

<p style="text-indent:2em;">- чтобы не разрушалась   дорожная   одежда  и/или  мосты   за   счет превышения нагрузок, учтенных при модернизации автомобильной дороги.</p><br>

<p style="text-indent:2em;">Не разрешаются  перевозки  опасных грузов с превышением  веса  и/или габарита,    которые   осуществляются   транспортными   средствами,   не соответствующими международным нормам перевозки грузов такого рода.</p><br>   

<p style="font-weight:500;">2.1.2. Специальные  транспортные  разрешения  выдаются  на  короткий срок,   учитывая  ограничения  постоянного  или  временного   характера, устанавливающих  обязательные объезды для автомобилей с превышением 
веса или габаритов.</p><br>

<p style="font-weight:500;">2.1.3. Специальные  транспортные разрешения выдаются с  ограничением для   неблагоприятных  периодов  (чередованием  периодов  замерзания   и оттаивания,   избыточной   влажности),  учитывая  ограниченную   несущую
 способность дорожной одежды и сложности, создаваемые тяжеловесными и/или крупногабаритными    транспортными   средствами   для   безопасности   и равномерности дорожного движения.</p><br>

<p style="font-weight:500;">2.1.3.1. Ограничение  разрешенного предела осуществляется до 25%  от несущей способности дороги для условий с сухой проезжей частью.</p><br>

<p style="font-weight:500;">2.1.3.2. В условиях   сухой  дороги  (летом)  превышение   нагрузок, которое  может быть разрешено согласно настоящей инструкции, составит не более 30% от предельной нагрузки на ось, предусмотренной законом.</p><br>

<p style="font-weight:500;">2.1.4. Специальные транспортные разрешения, выдаваемые Министерством транспорта  и  связи,  действительны на дорогах  общего  пользования.  В случаях,  когда путь следования транспортных средств включает проезд  
по коммунальным  дорогам  или  через  населенные  пункты  и  муниципии,  не исключается   обязанность   пользователя   на   получение   специального транспортного   разрешения  от  органов,  в  ведении  которых  
находятся соответствующие  дороги  и улицы для установления маршрута и условий,  в которых будет осуществляться движение</p><br>

<p style="font-weight:500;">2.1.5. Для трейлеров  грузоподъемностью  20,  40, 60  тонн  или  для автокранов  и других специализированных автомобилей выдаются  постоянные транспортные разрешения со сроком действия, ограниченном в течение года, 
с  условием строгого соблюдения габаритных размеров и веса, записанных в специальном   транспортном   разрешении.  В  этом  случае   уплачиваются предварительно тарифы на среднегодовой пробег.</p><br>

<p style="text-indent:2em;">Постоянные специальные транспортные разрешения недействительны, если конвой  превышает  один  из пределов габаритов и  веса,  предусмотренных разрешением,   или   не  сопровождаются  приложениями   с   разрешенными
 маршрутами и установленными ограничениями.</p><br>

<p style="text-indent:2em;">В случае, когда  владелец  транспорта имеет  постоянное  специальное транспортное  разрешение,  но  по  одному из грузов  превышает  одно  из габаритных или весовых условий, обязан обратиться к органу, управляющему дорогами,
  для получения другого специального транспортного  разрешения, уплачивая тарифы, соответствующие характеристикам предмета.</p><br>

<p style="font-weight:500;">2.1.6. Индивидуальные     специальные    транспортные     разрешения действительны  в  оба конца. В случае, когда загруженный  конвой  обязан следовать  по  объездным маршрутам более продолжительным  для  избежания некоторых
  ограничений,  этот  конвой возвращается  порожним  по  самому короткому  маршруту.  Этот маршрут должен быть установлен в  специальном транспортном разрешении выдающим его органом.</p><br>

<p style="font-weight:500;">2.1.7. Индивидуальные     специальные    транспортные     разрешения тарифицируются в зависимости от веса и пробега в километрах загруженного конвоя  и/или габаритов конвоя (загруженного или порожнего) и пройденных километров.
  В  вес  конвоя  включается  вес  транспортного  средства  + распределитель  нагрузки + вес перевозимого предмета, в т.ч. упаковка, а также вес тягача.</p><br>

<p style="font-weight:500;">2.1.7.1. Если вследствие проверок выявляются на трассах тяжеловесные и/или  крупногабаритные конвои без специальных транспортных  разрешений, кроме  штрафов,  применяется  специальный тариф для  выдачи  специальных транспортных
  разрешений  и  обычные  сборы  за  превышение  веса  и/или габаритов.</p><br>

<p style="font-weight:500;">2.1.7.2. Для  более  одной перевозки тарифицирование  осуществляется путем умножения числа рейсов на стоимость одной перевозки, с добавлениемразового тарифа на разрешение.</p><br>

<p style="font-weight:500;">2.1.8 Уплата   и   использование   сборов  за   выдачу   специальных транспортных  разрешений  осуществляется  в соответствии  с  положениями Закона о дорожном фонде (N 720-XIII от 2 февраля 1996 г.) и \"Положения о порядке  образования
  и  использования дорожного  фонда\",  утвержденного Постановлением Парламента N 893-XIII от 26 июня 1996 г.</p><br>

<p style="font-weight:500;">2.1.9. Специальное     транспортное    разрешение    изготавливается типографским  способом  и  содержит  графы  приложения  N1  к  настоящей инструкции.  Оно заполняется в зависимости от компетенции выдающего  его органа.</p>

<p style="text-indent:2em;"></p><h6>2.2. Компетентными  органами для заполнения специальных транспортных разрешений являются:</h6><p></p>

<p style="text-indent:2em;">- Главное управление     дорожного     хозяйства    для     конвоев, передвигающихся  по автомобильным дорогам общего пользования, независимо от веса и габаритов;</p><br>

<p style="text-indent:2em;">- Управления  местного хозяйства муниципиев и населенных пунктов для маршрутов,  которые  проходят по территории соответствующего города  или муниципия;</p><br>

<p style="text-indent:2em;">- Специализированный  персонал транспортной инспекции, расположенный в  пунктах  перехода  государственной границы в рамках  компетенции  для зарубежных  транзитных  перевозок  через Молдову или  для  зарубежных  и молдавских
  транспортных  средств,  которые  въезжают  или  выезжают  из населенных пунктов страны.</p><br>

<p style="font-weight:500;">2.3. Заявление   на   выдачу  специальных  транспортных   разрешений подаются  владельцем транспорта компетентным органам согласно п. 2.2.  и управлению дорожной полиции.</p><br>

<p style="text-indent:2em;">Заявления Министерства   национальной  безопасности  рассматриваются Министерством  транспорта  и  связи  и управлением  дорожной  полиции  в условиях соблюдения настоящей инструкции.</p><br>

<p style="font-weight:500;">2.4. Заявление   на  выдачу  специального  транспортного  разрешения должно  составляться  согласно  приложению 2 к  настоящей  инструкции  и должно   содержать:  все  размеры  и  вес  перевозимых  предметов,   вид транспортного
  средства  и  тягача, схему или рисунок  упаковки,  вес  и расположение   распределителя  нагрузки,  порядок  анкерного   крепления транспортного средства, число идентичных перевозок, период осуществления перевозок, а также другие
  детали, связанные с перевозкой.</p>

<p style="text-indent:2em;"></p><h6>2.5. Функции компетентных органов, сроки рассмотрения заявлений:</h6><p></p>

<p style="text-indent:2em;">- не более 2 часов для пограничных пунктов или 48 часов для случаев, когда  решение  зависит  от компетенции  главного  управления  дорожного хозяйства Министерства транспорта и связи;</p><br>

<p style="text-indent:2em;">- 10 дней для  заявлений,  поданных  главному  управлению  дорожного хозяйства Министерства транспорта и связи;</p><br>

<p>- 10 дней для заявлений, поданных органам коммунального хозяйства.</p><br>

<p style="text-indent:2em;">Специальные транспортные   разрешения   имеют,  как  правило,   срок действия  30 календарных дней максимально, таким образом, чтобы были ими охвачены ограничения на период осуществления перевозки.</p><br>

<p style="font-weight:500;">2.6. Для прохождения  под  воздушными электрическими, телефонными  и радиопроводами,  трамвайными  и  троллейбусными  линиями,  а  также  для прохождения  на  уровне  электрифицированной железной  дороги,  владелец транспорта
   или   транспортный  агент  должен   получить   своевременно заключения  управляющих ими органов. Специальное транспортное разрешение не охватывает условия прохождения под этими установками.</p><br>

<p style="font-weight:500;">2.7. Утеря специального  транспортного разрешения относится к режиму документов строгой отчетности и к........</p><br>

<p style="font-weight:500;">2.8. Если перевозка   не   осуществлена   вовремя,   срок   действия письменного заявления владельца может быть продлен на 30 дней.</p><br>

<p style="font-weight:500;">2.9. В случае, когда возникают изменения, связанные с маршрутом, или ограничения, они вносятся в специальное транспортное разрешение выдавшим его органом с внесением даты этих изменений.</p>

<p style="text-indent:2em;"></p><h6>2.10. В случае,  когда габариты ограничены в высоту или ширину,  при выдаче разрешения будут учитываться и пространства безопасности:</h6><p></p>

<p>- в высоту максимально 10 см;</p><br>

<p>- в ширину максимально 20 см с каждой стороны;</p>

<p></p><h4>Глава III Условия осуществления перевозок</h4><p></p>

<p style="font-weight:500;">3.1. Предметы, которые следует перевезти, должны иметь нанесенные краской с двух сторон значения веса в тоннах и размеров внешнего контура в  перевозимом  положении в мм (длина х ширина х  высота).  Транспортные средства,
  трейлеры,  прицепы  и  полуприцепы  должны  иметь  нанесенные краской на видном месте значения собственного веса.</p><br>

<p style="font-weight:500;">3.2. Если ширина состава в транспортном положении составляет от 2,50 до  3,50  м,  на  нем в обязательном  порядке  должна  быть  установлена предупредительная табличка с надписью \"Внимание, превышение габарита\", а тягач
  должен постоянно использовать проблесковый маячок желтого  цвета. Предупредительная  табличка  должна иметь размеры 60 х 40 см  с  высотой букв 10 см, нанесенных красной краской на белом фоне.</p><br>

<p style="font-weight:500;">3.3. Если ширина  автомобиля (состава) составляет от 3,5 м до 5,0 м, он  должен  сопровождаться  в пути  следования  автомобилем,  оснащенным проблесковым  маячком желтого цвета в работающем состоянии и табличкой с надписью
 \"Внимание, превышение габарита\".</p><br>

<p style="font-weight:500;">3.4. Если ширина  состава превышает 5,0 м, он должен  сопровождаться двумя  автомобилями  (спереди и сзади) с проблесковыми маячками  желтого цвета  и  предупредительной табличкой с надписью  \"Внимание,  превышение габарита\",
  установленной  спереди  на переднем автомобиле  и  сзади  на сопровождающем.</p><br>

<p style="font-weight:500;">3.5. Двойное  сопровождение обязательно и на случаи, когда длина  от сцепного устройства автомобиля тягача и до конца состава превышает 30,0м.</p><br>

<p style="font-weight:500;">3.6. Для передвижения по улицам сопровождающий автомобиль обязателен только сзади.</p>

<p></p><h6>Примечание.</h6><p></p>

<p style="text-indent:2em;">Во  всех  случаях перечисленных в п. 3.3.,  3.4.,  3.5., 3.6.  управление дорожной полиции вправе принять решение о сопровождении состава  специальным автомобилем дорожной полиции с проблесковым маячком синего цвета.</p><br>

<p style="font-weight:500;">3.7. Во всех  случаях проблесковые маячки желтого цвета должны  быть установлены  на  самых высоких частях автомобиля тягача или груза  таким образом, чтобы в пути следования были видимыми со всех сторон.</p><br>

<p style="font-weight:500;">3.8. В случае,  когда  габариты по ширине превышают 3,5  м,  боковые края,  которые  выступают  за платформу трейлера, должны  быть  отмечены спереди  и  сзади  (слева и справа) на 30 см в ширину и  1,20-1,50  м  в высоту  с
  помощью чередующихся белых и красных полос шириной 10  см,  с наклоном  в  45  град., ниспадающими на внешнюю сторону  автомобиля  или груза.  Того  же  эффекта  можно добиться путем  установки  в  таком  же положении вертикальных
  табличек 1,20-1,50 м, так же окрашенных.</p><br>

<p style="font-weight:500;">3.9. В ситуации,    когда   на   трейлере   перевозится   самоходное оборудование   с   превышением  габаритов,  при  пересечении   некоторых железнодорожных    переездов   разрешается   спустить   оборудование   с транспортного
  средства и пересечь ограничительную зону своим ходом. Для оборудования  на  гусеничном  ходу без резиновых  башмаков  передвижение должно   осуществляться  по  дощатому  настилу  для  защиты  поверхности проезжей   части.  Установка
  и  снятие  дощатого  настила  производятся перевозчиком, который осуществляет перевозку.</p><br>

<p style="font-weight:500;">3.9.1. В случае,  когда  общая высота (автомобиль + груз)  превышает установленные  габариты элементов обстановки пути, прохождение через эти точки осуществляется путем осторожного снятия и установки этих элементов силами и за счет
 заказчика перевозки в присутствии представителя органа, управляющего соответствующей автомобильной дорогой.</p>

<p style="text-indent:2em;"></p><h6>3.10. Для пересечения    переездов    крупногабаритными    составами необходимо  получить разрешение органов, управляющих железной дорогой, в следующих случаях:</h6><p></p>

<p>- ширина более 5,00 м во всех случаях;</p><br>

<p>- высота более 4,0 м в случае электрифицированной линии;</p><br>

<p>- длина более 30 м в случае неохраняемых переездов.</p><br>

<p style="text-indent:2em;">Органы управления  железными дорогами могут дополнить и потребовать, в  случае  необходимости,  соблюдения и других  условий  перехода  через железнодорожные переезды.</p><br>

<p style="font-weight:500;">3.11. В случае перевозок, когда габариты по ширине превышают 5,00 м, по  дорогам  с  интенсивным движением  перевозящая  организация  обязана составить  поэтапную  программу проезда узких мест, где возможно  резкое приостановление
  движения,  которая  должна быть  своевременно  передана органам   дорожной   полиции  и  органам   управления   соответствующими автомобильными дорогами.</p><br>

<p style="text-indent:2em;">В случае, когда  орган,  выдавший  разрешение,  считает  необходимым сопровождение  состава  органами  полиции, он вносит это  в  специальное транспортное разрешение.</p><br>

<p style="font-weight:500;">3.12. Передвижение  при тумане и ограниченной видимости менее 100  м запрещено.   В   случае   начала  снегопада,  обледенения   или   других непредвиденных  обстоятельств, состав должен быть остановлен на  боковой дороге  на  расстоянии
  не  менее 50 м от  автомобильной  дороги  общего пользования,  на месте стоянки или на площадке вне автомобильной  дороги общего пользования.</p><br>

<p style="font-weight:500;">3.13. Передвижение   по   мостам  или  виадукам  осуществляется   со сниженной  скоростью,  по  необходимости  5-15 км/ч,  без  толчков,  без остановок,  по  правой  полосе в сторону движения или в  соответствии  с условиями,
 указанными в специальном разрешении.</p><br>

<p style="font-weight:500;">3.13.1. В конкретно   определенных  случаях  переход  через   мосты, путепроводы или виадуки может осуществляться путем буксировки тросом.</p><br>

<p style="font-weight:500;">3.13.2. Запрещается  остановка  тяжеловесных и/или  крупногабаритных составов  на  участках дорог со стесненными условиями  движения  (мосты, переезды,  спуски,  суженные из-за проведения дорожных работ,  повороты, участки с
 ограниченной видимостью).</p><br>

<p style="font-weight:500;">3.14. Тяжеловесные  и/или крупногабаритные перевозки осуществляются, как  правило,  в периоды со сниженной интенсивностью  движения,  избегая передвижения  по  
городам, муниципиям или многолюдным зонам в  интервале 6-8  и  15-17  часов. </p><br>

<p>Запрещаются  тяжеловесные  и/или  крупногабаритные перевозки в выходные и праздничные дни.</p><br>

<p style="text-indent:2em;">В особых случаях,    при    соответствующей   аргументации,    можно осуществлять   тяжеловесные   и/или  крупногабаритные  перевозки   и   в вышеуказанные  периоды  с  согласия  Министерства  транспорта  и  связи, управления
 дорожной полиции и примэрий транзитных городов.</p>

<p></p><h4>Глава IV Условия, которые должны соблюдать транспортные составы</h4><p></p>

<p style="font-weight:500;">4.1. Автомобили  и  прицепы, которые превышают вес  и/или  габариты, предусмотренные  законом,  должны  выполнять  все  технические  условия, содержащиеся  в  нормативных актах о движении по  автомобильным
  дорогам общего пользования.</p><br>

<p style="font-weight:500;">4.2. В исключительных    случаях    для   прицепов,   для    которых конструктивно   не   предусмотрен  стояночный  тормоз,  торможение   при отцеплении   прицепа  и  во  время  стоянки  может  производиться
  путем блокирования  не  менее 50% от числа колес (шин),  парами  металлических колодок, расположенных спереди и сзади колеса.</p><br>

<p style="font-weight:500;">4.3. Перевозящий  автомобиль  или  тягач должен  иметь  проблесковый маячок,  установленный таким образом, чтобы он был хорошо виден со  всех сторон.  Если  высота груза закрывает сзади предупреждающий свет,
  нужно установить дополнительно в конце груза на высоте 2,50-3,00 м от проезжей части желтую предупредительную лампу.</p><br>

<p>Эти лампы должны гореть все время, пока состав находится на проезжей части.</p><br>

<p style="font-weight:500;">4.4. Автомобиль-тягач,  прицеп  или полуприцеп должны быть  оснащены шинами  с профилем не менее 1,50 мм в глубину в хорошем  работоспособном состоянии.  Прицепное дышло должно быть в отличном состоянии, так же
 как и предохранители для крепления, где не допускается импровизация (завязка проводом, цепью и т. п.).</p><br>

<p style="font-weight:500;">4.5. Транспортные    средства   должны   быть   полностью   оснащены инструментами   и   оборудованием  в  хорошем  состоянии  для   срочного использования в случае необходимости.</p><br>

<p style="font-weight:500;">4.6. Груз на   транспортном   средстве  должен  быть  расположен   и прикреплен  в  соответствии со схемой погрузки,  составленной  проектной организацией, уполномоченной для этих целей. Проектная организация
 несет ответственность   за   ущерб  и  аварии,  вызванные  по  ее   вине   при непредусмотрении  проектом всех мер безопасности по отношению к грузу на время перевозки от поставщика до места назначения.</p><br>

<p style="font-weight:500;">4.7. Заказчик перевозки, перевозчик и поставщик перевозимого объекта обязаны  до  отъезда состава в рейс проверить все крепления  объекта  на транспортном  средстве в соответствии со схемой перевозки,  составленной
 проектантом.  Соблюдение положений схемы перевозки является обязанностью поставщика.</p><br>

<p style="font-weight:500;">4.8. Во время    осуществления   перевозки   водитель   тягача   или ответственный за перевозку со стороны заказчика обязаны проверить в пути крепления  груза  на транспортном средстве и, если  есть  необходимость,
 закрепить  связки, которые расслабились, таким образом, чтобы  крепление груза  было  обеспечено на протяжении всего пути  следования.  Указанные лица остаются ответственными за несоблюдение соответствующих мер.</p><br>

<p style="font-weight:500;">4.9. Заказчик  перевозки обязан сопровождать транспорт от поставщика до  места  назначения  и  принять  соответствующие  меры  если:  в  пути следования   необходимо   вмешательство   для   получения   специального
 транспортного   разрешения,   разборки  и  сборки   элементов   дорожной информации, разборки и сборки или прекращения подачи электрического тока по  проводам,  пересекающим  автомобильную дорогу, с  оплатой  стоимости соответствующих 
 работ, за производство которых он несет ответственность.</p><br>

<p style="font-weight:500;">4.10. Перевозчик    обязан    остановить    состав    при    сигнале уполномоченного    персонала    администрации   автомобильной    дороги, представить  специальное  транспортное  разрешение, на  основе  которого
 осуществляется  перевозка,  соблюдать специальные указания с момента  их получения от него или подчиниться приказу о переходе на стационарные или передвижные весы, установленные по утвержденному маршруту.</p><br>

<p style="font-weight:500;">4.11. Перевозчик  обязан  соблюдать маршрут, ограничения,  объездные маршруты  и  положение  перехода  через мосты, таким  образом,  как  они указаны  в  специальном  транспортном разрешении или  в  соответствии
  с требованиями дорожных знаков и информации.</p><br>

<p style="font-weight:500;">4.12. Передвижение   состава  через  железнодорожные  переезды,  где существует автоматическая дорожная сигнализация, выполняется с условием, что  полный переход состава будет произведен не более чем за 30  секунд.
 Любой  проезд  с превышением времени осуществляется только с  разрешения органа железной дороги.</p><br>

<p style="font-weight:500;">4.13. Передвижение   по   участку  дороги,  не  предусмотренному   в специальном транспортном разрешении, или вообще без разрешения, является нарушением   действующего  законодательства,  которое  влечет  за 
 собой наложение   штрафа   на  водителя  с  возмещением  ущерба,   нанесенного администрации  автомобильной дороги, или даже уголовную ответственность, в зависимости от степени нанесенного ущерба.</p>

<p></p><h4>Глава V Переходные и заключительные положения</h4><p></p>

<p style="font-weight:500;">5.1. Выдача предварительного     заключения     или     специального транспортного  разрешения осуществляется после оплаты заказчиком тарифов на  заключение  (разрешение) и соответственно на превышение
  веса  и/или габаритов.</p><br>

<p style="font-weight:500;">5.2. Министерство  транспорта и связи и управление дорожной  полиции могут  изменить  маршрут  или  некоторые его участки  в  зависимости  от ограничений, которые возникают в период действия выданного
 разрешения.</p><br>

<p style="font-weight:500;">5.2.1. В определенные    периоды   на   автомобильных   дорогах    с международным  сообщением или на других дорогах общего пользования могут быть  запрещены тяжеловесные и/или крупногабаритные перевозки;
  условие, которое вносится в специальное транспортное разрешение.</p><br>

<p style="font-weight:500;">5.3. Ограничения  или отклонения в движении сообщаются через прессу, радио,  телевидение  для доведения их до сведения участников  перевозок. При этом они обязаны прибыть с выданными разрешениями в дорожные
 органы, выдавшие их, для внесения соответствующих изменений.</p><br>

<p style="font-weight:500;">5.4. Органы с   правом   контроля  могут  аннулировать   специальное транспортное  разрешение  в случае нарушения одного или  более  условий, предусмотренных  настоящей инструкцией, или когда по каким-либо
 причинам отсутствуют  условия для проезда. В этих случаях состав направляется для стоянки  на боковую автомобильную дорогу, не менее чем в 50 м от  дороги общего  пользования,  на  место стоянки или в другое
 место  вне  дороги общего пользования.</p><br>

<p style="font-weight:500;">5.5. Во всех  случаях,  предусмотренных в пунктах 5.2, 5.4  заказчик или транспортный агент не вправе требовать возмещения ущерба.</p><br>

<p style="font-weight:500;">5.6. Заказчик или транспортный агент, выполняющий тяжеловесные и/или крупногабаритные перевозки, несет все расходы по ремонту, возникающему в связи  с  деградацией автомобильных дорог или искусственных
  сооружений, вызванных  их воздействием, а также соответствующие возмещения  согласно действующему законодательству.</p><br>

<p style="font-weight:500;">5.7.Невыполнение   требований  настоящей  инструкции   преследуется  административно    или    уголовно   в   соответствии   с    действующим законодательством.</p>

<p></p><h6>Приложение N 1</h6><p></p>

<p>Специальное транспортное разрешение N_________</p><br>

<p style="text-indent:2em;">На выполнение тяжеловесной и/или крупногабаритной перевозки с превышением установленных лимитов согласно инструкции</p><br>

<p>Министерства транспорта и связи</p><br>

<p style="text-indent:2em;">1. Маршрут, по   которому   разрешается   перевозка  и   его   общая
продолжительность (км) _________________________________________________</p><br>

<p>1.1. Ограничения____________________________________________________</p>

<p style="text-indent:2em;"></p><h6>2. Характеристики транспортного средства:</h6><p></p>

<p>Модель ______________________, регистрационный номер _______________</p><br>

<p>Длина ______________________ м</p><br>

<p>Ширина ______________________ м</p><br>
	
<p>Высота ______________________ м</p><br>
	
<p>Вес груза ______________________ тонн</p><br>

<p>Общий вес транспортной единицы _________тонн</p><br>

<p>Максимальная нагрузка на самую загруженную ось _______тонн.</p><br>

<p style="text-indent:2em;">Транспортное    средство   осмотрено   представителем   предприятия, ответственного за перевозку, и соответствует Правилам дорожного движения и   Инструкции   Министерства  транспорта  и  связи  об  организации
и осуществлении тяжеловесных и/или крупногабаритных перевозок.</p><br>

<p style="text-indent:2em;">С правилами, предусмотренными в Инструкции Министерства транспорта и связи об организации и осуществлении тяжеловесных и/или крупногабаритных перевозок, ознакомлены водители: _______________________________________</p><br>

<p>(Ф.И.О., подписи)</p><br>

<p>3. Сопровождающие транспортные cредства ____________________________</p><br>

<p>4. Данные о предприятии-перевозчике ________________________________</p>

<p style="text-indent:2em;"></p><h6>Данные пунктов    1-4    подписывает    руководитель    предприятия,
осуществляющего перевозку:</h6><p></p>

<p>________________________________________________________________________</p><br>

<p>(Ф.И.О., подписи, печать)</p><br>

<p>5. Условия перевозки</p><br>

<p style="text-indent:2em;">Автомобиль-тягач   и  прицеп  соответствуют  техническим   условиям, предусмотренным   в   Правилах   дорожного  движения  и   в   Инструкции Министерства   транспорта   и  связи  об  организации  и   осуществлении тяжеловесных
и/или  крупногабаритных  перевозок, а во  время  перевозки будут строго соблюдаться предписания.</p><br>

<p style="text-indent:2em;">5.1. Перевозка  будет  осуществляться  только  днем  и/или  ночью  с
__________часов    до    _________часов.   С   максимальной    скоростью
___________км/час.</p>

<p style="text-indent:2em;"></p><h6>5.2. В исключительном  случае разрешается осуществление перевозки  в
дни:</h6><p></p>

<p>________________________________________________________________________</p>
    
<p style="text-indent:2em;"></p><h6>5.3. Передвижение   по  нижеследующим  мостам  будет  осуществляться
следующим образом:</h6><p></p>

<p>________________________________________________________________________</p><br>

<p>6. Другие предписания эмитента или контрольных органов.</p><br>

<p style="text-indent:2em;">Дополнительная    информация   об   условиях   движения,   возможных дополнительно  возникающих  ограничениях, будет получена по телефону  от дорожных органов, из прессы и метеосводок по радио и телевидению.</p><br>

<p style="text-indent:2em;">На основании   заявления   на  выдачу   специального   транспортного разрешения  заказчик обязуется в точности соблюдать условия  разрешения, предусмотренные   в  Инструкции  Министерства  транспорта  и  связи
об организации   и   осуществлении  тяжеловесных   и/или   крупногабаритных перевозок.</p><br>

<p>7. Разрешение действительно до _____________________________________</p><br>

<p>8. Выдано ________________ в _______________________________________
дата                     населенный пункт</p><br>

<p>Министерство транспорта связи ______________________________________
(подпись, печать)</p><br>

<p>Управление дорожной полиции ________________________________________
(подпись, печать)</p>

<p></p><h6>Приложение N 2</h6><p></p>

<p>Министерство транспорта и связи</p>

<p></p><h4>З А Я В Л Е Н И Е</h4><p></p>

<p>1. Предприятие______________________________________________________</p><br>

<p>(наименование, реквизиты, юридический адрес)</p><br>

<p>____________________________________________________________________</p><br>

<p>2. Маршрут, по  которому будет осуществляться перевозка и расстояние (км)</p><br>

<p>________________________________________________________________________</p><br>

<p>3. Характеристика транспортного средства с грузом __________________</p><br>

<p>________________________________________________________________________</p><br>

<p>тип, марка, регистрационный номер, вес, размеры)</p><br>

<p>4. Схема расположения груза ________________________________________</p><br>

<p>___t       ____t   ___t   ___t    ____t ___t</p><br>

<p>X----------X----------X---X--------X--------X</p><br>

<p>____m      ____m   ___m   ____m   ____m</p>
 
<p style="text-indent:2em;"></p><h6>где: Х - ось,  __t  -  нагрузка на ось в тоннах, ___м  -  расстояние
между осями.</h6><p></p>

<p>5. Другие детали, связанные с транспортом___________________________</p><br>

<p>6. Перевозка будет осуществляться в период _________________________</p><br>

<p style="text-indent:2em;">7. Подтверждаем, что невозможно дальнейшее сокращение размеров, веса путем разборки. Приняты 
все меры по сокращению до минимума общей высоты.</p><br>

<p style="text-indent:2em;">8. ОБЯЗАТЕЛЬСТВО. Берем на себя ответственность за указание неточных данных  в  настоящем  заявлении и соблюдение Инструкции о  разрешении  и осуществлении тяжеловесных и/или
 крупногабаритных перевозок Министерства транспорта и связи.</p><br>

<p>Руководитель ________________________________________________</p><br>

<p>(ФИО, подпись, печать)</p>

<p></p><h6>Приложение 3</h6><p></p>

<p style="text-indent:2em;">Перечень нормативных  актов,  утративших  силу в связи  с  принятием \"Инструкции о порядке разрешения и осуществления перевозок с весом и/или габаритами,
  превышающими предельные значения, установленные действующим законодательством и нормативами\":</p><br>

<p style="text-indent:2em;">Раздел 54 \"Правил   перевозок  грузов  автомобильным  транспортом  в Молдавской  ССР\",  утвержденных Министерством автомобильного  транспорта Молдавской ССР
 (г. Кишинев, Картя Молдовеняскэ, 1982 г.).</p><br>
 
 <p><a href="https://www.legis.md/cautare/getResults?doc_id=103573&amp;lang=ru">Источник</a></p>


</div>
`