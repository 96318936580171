import React, { Component } from 'react'
import classNames from 'classnames'
import { Field } from 'redux-form'

import WidgetBase from '../../../shared/components/widget-base'
import {
  required,
  minPasswordLength,
  maxPasswordLength,
  matchToField,
} from '../../../shared/form-validators'
import FieldBase from '../../../UI/field-base'
import './recover-password.scss'

const STYLE_BASE = 'recover-password_'
export default class extends Component {
  constructor(props) {
    super(props)

    this.matchPassword = this.validatePasswords()
  }

  validatePasswords = () => {
    const { t } = this.props
    return matchToField('password', t('login.password.mismatch'))
  }

  render() {
    const {
      t,
      handleSubmit,
      pristine,
      // reset,
      submitting,
      invalid,
    } = this.props

    return (
      <div className="row">
        <div className="col-md-offset-2 col-md-12 col-lg-offset-3 col-lg-10 col-xl-offset-4 col-xl-8">
          <WidgetBase title={t('login.title.recoverPassword')}>
            <div className="panel-body">
              <div className="panel-content">
                <div className="panel-content-row">
                  <div className={classNames('panel-block', `${STYLE_BASE}wrapper`)}>
                    <form className="panel-form" onSubmit={handleSubmit}>
                      <div className="panel-form-row">
                        <div className="panel-block-inline">
                          <div>
                            <Field
                              name="password"
                              type="password"
                              component={FieldBase}
                              label={t('login.password.new')}
                              placeholder={t('login.password.new.placeholder')}
                              validate={[required, minPasswordLength, maxPasswordLength]}
                            />
                          </div>
                          <div>
                            <p className="meta"dangerouslySetInnerHTML={{
                              __html: t('password.rule.message.info')
                            }}></p>
                          </div>
                        </div>
                      </div>
                      <div className="panel-form-row">
                        <div className="panel-block-inline">
                          <div>
                            <Field
                              name="password2"
                              type="password"
                              component={FieldBase}
                              label={t('login.password.repeat')}
                              placeholder={t('login.password.repeat.placeholder')}
                              validate={[required, this.matchPassword]}
                            />
                          </div>
                          <div />
                        </div>
                      </div>
                      <div className="panel-form-row">
                        <div className="panel-block-inline command-buttons">
                          <button
                            className="button button-orange"
                            disabled={pristine || submitting || invalid}
                            type="submit"
                          >
                            {t('login.password.change')}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </WidgetBase>
        </div>
      </div>
    )
  }
}
