import { GET_INITIAL_VALUES, RESET_INITIAL_VALUES } from './action-types'
import fetch from '../../components/shared/fetch'

export const getItem = (adId: number, cb: (categoryId: number) => void) => {
  return async dispatch => {
    fetch(`/announcement/item/${adId}`, { method: 'POST' }).then(item => {
      dispatch({ type: GET_INITIAL_VALUES, payload: item })
      cb(item.tree_type_id)
    })
  }
}
export const resetItem = () => {
  return {
    type: RESET_INITIAL_VALUES,
    payload: {},
  }
}
