import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Logout from '../../components/auth/logout'
import Home from '../../components/home'
import News from '../../components/news'
import Ads from '../../components/ads'
import Cargo from '../../components/cargo'
import Transport from '../../components/transport'
import Company from '../../components/company'
import NotFound from '../../components/errors/404'
import Useful from '../../components/useful'
import About from '../../components/about'
import Pages from '../../components/pages'
import NotReadyYet from '../../components/errors/not-ready-yet'
import SummaryTable from '../../components/summary-table'
import Forum from '../../components/forum/forum-full-page'
import Agreement from '../../components/agreement'
import TermsOfUse from '../../components/terms-of-use'
import PrivacyPolicy from '../../components/privacy-policy'
import User from '../../components/user'
import ProtectedRoute from '../../protectedRoute'
import AdminClaims from '../../components/admin-claims'

import Auth from '../../components/auth'

const authorizedRoutes = [<Route path="/logout" component={Logout} key="logout" />]

export default () => () => (
  <Switch>
    {authorizedRoutes}
    <Route path="/auth" component={Auth} />
    <Route path="/summary-table" component={SummaryTable} />
    <ProtectedRoute path="/admin/claims" component={AdminClaims} requireAuth />
    <Route path="/agreement" component={Agreement} />
    <Route path="/terms-of-use" component={TermsOfUse} />
    <Route path="/privacy-policy" component={PrivacyPolicy} />
    <Route path="/forum/:sectionId?/:themeId?" component={Forum} />
    <Route path="/cargo" component={Cargo} />
    <Route path="/transport" component={Transport} />
    <Route path="/company" component={Company} />
    <Route path="/news" component={News} />
    <Route path="/ads" component={Ads} />
    <Route path="/user" component={User} />
    <Route path="/useful" component={Useful} />
    <Route path="/about-us" component={About} />
    <Route path="/pages" component={Pages} />
    <Route path="/not-ready-yet" component={NotReadyYet} />
    <Route path="/" exact component={Home} />
    <Route path="*" component={NotFound} />
  </Switch>
)
