import { connect } from 'react-redux'
import { compose } from 'recompose'

import withTranslation from '../../../globals/components/withTranslation'
import { setActivePopup } from '../../../store/actions/ui'

import Component from './component'

export default compose(
  withTranslation,
  connect(state => ({ activeGuid: state.ui.activePopupGuid }), { setActivePopup })
)(Component)
