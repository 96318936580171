import React, { Component } from 'react'

export default class RoAutopark extends Component {
    render() {

        return (
            <div>
                <div className="panel-content-row" style={{ marginTop: -5 }}>
                    <h5>Parcul auto personal </h5>
                    <p className="text-indent">
                        Orice utilizator care a trecut cu succes procedura de înregistrare are posibilitatea să obțină pe site propria secțiune "<strong>Parcul auto personal</strong>". În această secțiune, puteți să plasați, să stocați și, dacă este necesar, să editați sau să ștergeți informații despre propriile transporturi de marfă. De ce este necesar acest lucru, care sunt avantajele? Atunci când adăugați o cerere pe site pentru transport liber pregatit să transporte mărfuri într-o anumită direcție, puteți "atașa" la aceasta informații despre un camion specific, preluate din secțiunea "<strong>Parcul auto personal</strong>". Acest lucru va reduce semnificativ timpul necesar pentru completarea multor câmpuri ale cererii, oferind partenerilor potențiali o imagine precisă și detaliată asupra caracteristicilor tehnice și cantitative ale transportului propus de utilizator. Informații despre toate camioanele disponibile ale utilizatorului vor fi afișate în profilul său personal, în secțiunea "<strong>Parcul auto personal</strong>", și vor fi disponibile pentru vizualizare de către orice utilizator al site-ului. Secțiunile de profil personal pline de informații vor permite altor participanți de pe site să aibă o imagine mai completă și precisă despre interesele și capacitățile proprietarului de transport!
                    </p>
                    <br />
                    <p className="text-indent">
                        Participanților înregistrați pe site li se oferă posibilitatea de a adăuga în parcul auto atât transport propriu, cât și cel închiriat sau expediat de ei. Numărul admisibil de transport în parcul auto personal (min. - 1, max. - 5) depinde de statutul utilizatorului. La adăugarea informațiilor despre propriul transport în secțiunea "<strong>Parcul auto personal</strong>", utilizatorului i se oferă posibilitatea de a specifica numeroși parametri ai camionului său și/sau al remorcii (semiremorcii): tipul de transport, modelul, anul de fabricație, tipul caroseriei, standardul ecologic, capacitatea de încărcare, metodele de încărcare, regimul de temperatură, prezența certificatului ADR, prezența urmăririi GPS și altele. De asemenea, se poate include și informații despre documentele permisive disponibile la bordul vehiculului: TIR, CMR, CEMT, certificat sanitar. În final, pentru mai multă claritate, se poate, și chiar se recomandă, să se adauge și fotografii ale camionului și compartimentului de marfă, pentru a oferi partenerialor potențiali o imagine exactă și detaliată a caracteristicilor tehnice și cantitative ale vehiculului de transport
                    </p>
                    <br />
                    <h5>Cum poate un utilizator înregistrat să adauge un camion în "Parcul auto personal"?</h5>
                    <p >
                        Aceasta poate fi realizată în mai multe moduri:
                        <br />
                        &nbsp;
                        <ol>
                            <li>În colțul din dreapta sus al oricărei pagini a site-ului se află avatarul utilizatorului. Plasând cursorul mouse-ului peste el sau făcând clic pe el, posesorului contului îi va apărea un meniu derulant cu anumite date ale utilizatorului. În partea de jos a meniului, trebuie de accesat secțiunea "<strong>Parcul auto personal</strong>". Aici se va deschide informația despre toate camioanele utilizatorului (dacă ele există). Făcând clic pe "<strong>Adaugă transport în parcul auto personal</strong>", utilizatorul va putea să adauge un nou transport, în limitele stabilite de statutul său (min. - 1 unitate, max. - 5 unități).</li>
                        </ol>
                        &nbsp;
                        <ol start="2">
                            <li>Pe oricare pagină a site-ului, în unul dintre meniurile de sus pentru utilizatorii înregistrați, există o secțiune "<strong>Adăugați transportul</strong>". Accesând apoi subsecțiunea "<strong>Date despre transport</strong>", utilizatorul va găsi o inscripție hiperactivă: „Alege din <strong>parc auto</strong> sau completează manual?” Făcând clic pe aceasta, veți ajunge în secțiunea "<strong>Parcul auto personal</strong>". Făcând clic pe "<strong>Adaugă transport în parcul auto personal</strong>", puteți adăuga un nou transport în limitele stabilite de statutul utilizatorului (min. - 1 unitate, max. - 5 unități).</li>
                        </ol>
                        &nbsp;
                        <ol start="3">
                            <li>Pe pagina principală a site-ului utilizatoului înregistrat îi apare secțiunea numită "<strong>Statistica mea". </strong>În partea de jos a acestei secțiuni treceți la subsecțiunea "<strong>Parcul auto personal</strong>". Se va deschide informația despre toate camioanele utilizatorului (dacă ele există). Făcând clic pe "<strong>Adaugă transport în parcul auto personal", </strong>utilizatorul poate adăuga un nou transport în limitele stabilite de statutul său (min. - 1 unitate, max. - 5 unități).</li>
                        </ol>
                        &nbsp;
                    </p>
                    <h5>Cum poate un utilizator înregistrat să "atașeze" un transport la o cerere pentru transportul liber din secțiunea "Parcul auto personal"?</h5>
                    <p className="text-indent">
                        Pe oricare pagină a site-ului, în unul dintre meniurile de sus, pentru utilizatorii înregistrați există o secțiune "<strong>Adăugați transportul</strong>". Accesând apoi subsecțiunea "<strong>Date despre transport</strong>", utilizatorul va găsi o căsuță de bifat "<strong>Completarea manuală a datelor</strong>". ". Deschizând lista de transport propusă, utilizatorul trebuie să facă clic pe unul dintre camioanele din parcul auto personal, care va fi „atasat” la cererea pentru transport.
                    </p>
                    <br />
                    <h5>Parcul auto al companiei</h5>
                    <p className="text-indent">
                        Orice utilizator care a trecut cu succes procedura de înregistrare și a devenit membru al unei companii sau a creat propria companie pe site, are acces la secțiunea "<strong>Parcul auto al companiei</strong>". Cu toate acestea, doar administratorul companiei are dreptul să încarce, să editeze și să șteargă înregistrări în această secțiune. Conveniențele de utilizare ale secțiunii sunt aceleași ca cele descrise anterior în secțiunea "<strong>Parcul auto personal</strong>". La adăugarea unei cereri pe site pentru transport liber, dorind să transporte mărfuri într-o anumită direcție, oricare angajat al companiei poate „atașa” la aceasta informații despre un camion specific, preluate din secțiunea "<strong>Parcul auto al companiei</strong>". În același timp, utilizatorul păstrează opțiunea de a alege și, dacă este necesar, poate „atașa” la cerere un transport din secțiunea "<strong>Parcul auto personal</strong>".
                    </p>
                    <br />
                    <p className="text-indent">
                        Numărul admisibil de vehicule în parcul auto al companiei (min. - 5, max. - 100) depinde de statutul administratorului companiei.
                    </p>
                    <br />
                    <h5>Cum poate un administrator de companie să adauge un transport în "Parcul auto al companiei"?</h5>
                    <p >
                        Aceasta poate fi realizată în mai multe moduri:
                        <br />
                        &nbsp;
                        <ol>
                            <li>În colțul din dreapta sus al oricărei pagini a site-ului se află avatarul administratorului. Plasând cursorul mouse-ului peste el sau făcând clic pe el, posesorului contului îi va apărea un meniu derulant cu anumite date ale utilizatorului. În partea de jos a meniului, trebuie de accesat secțiunea <strong>"Parcul auto al companiei"</strong>. În fila <strong>"Parcul auto al companiei mele"</strong> se va deschide informația despre toate camioanele companiei (dacă ele există). Făcând clic pe <strong>"Adaugă transport în parcul auto al companiei"</strong>, administratorul poate adăuga un nou transport, în limitele stabilite de statutul său (min. - 5 unități, max. - 100 de unități).</li>
                        </ol>
                        &nbsp;
                        <ol start="2">
                            <li>Pe orice pagină a site-ului, într-unul dintre meniurile de sus ale administratorului companiei, există o secțiune <strong>"</strong><strong>Adăugați transportul</strong><strong>"</strong>. Accesând această secțiune și apoi subsecțiunea <strong>"Date despre transport"</strong>, utilizatorul va vedea un text hiperactiv: "Alege din <strong><u>parc auto</u></strong> sau completează manual? " Făcând clic pe acesta, va fi redirecționat către secțiunea <strong>"Parcul auto personal"</strong>. Accesând fila <strong>"Parcul auto al companiei"</strong> și apoi <strong>"Adaugă transport în parcul auto al companiei"</strong>, administratorul poate adăuga un nou transport în limitele stabilite de statutul său (min. - 5 unități, max. - 100 de unități).</li>
                        </ol>
                        &nbsp;
                        <ol start="3">
                            <li>Pe pagina principală a site-ului, administratorul companiei are o secțiune <strong>"Statistica mea"</strong>. În partea de jos a acestei secțiuni, trebuie să accesați subsecțiunea <strong>"Parcul auto al companiei"</strong>. Se va deschide informația despre toate camioanele companiei (dacă ele există). Făcând clic pe <strong>"Adaugă transport în parcul auto al companiei"</strong>, administratorul poate adăuga un nou transport în limitele stabilite de statutul său (min. - 5 unități, max. - 100 de unități).</li>
                        </ol>
                    </p>
                    <br />

                    <h5>Cum poate un angajat al companiei să "atașeze" un transport la o cerere pentru transportul liber din "Parcul auto al companiei"?</h5>
                    <p className="text-indent">
                        Pe orice pagină a site-ului, într-unul dintre meniurile de sus pentru un utilizator înregistrat, există o secțiune numită <strong>"</strong><strong>Adăugați transportul"</strong>. Accesând această secțiune, în subsecțiunea <strong>"Date despre transport"</strong>, utilizatorul va găsi o căsuță de selectare numită <strong>"Completarea manuală a datelor"</strong>. Deschizănd lista de transport propusă acolo, utilizatorul trebuie să dea clic pe unul dintre camioanele disponibile în parcul companiei, care va fi astfel "atasat" la cererea de transport.
                    </p>

                </div>
            </div>
        )
    }
}
