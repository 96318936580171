// import _ from "lodash"
import React from 'react'

import Ads from '../../ads'
import SiteStat from '../../site-stat'
import BuySellShort from '../../buy-sell/short'

import NewsList from '../list'

export default () => (
  <div className="row">
    <div className="col-lg-12">
      <NewsList />
    </div>
    <div className="col-lg-4">
      <SiteStat />
      <BuySellShort />
      <Ads />
    </div>
  </div>
)
