import React, { Component } from 'react'
import FlexBand from 'flexband'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import dayjs from 'dayjs'
import classnames from 'classnames'
import ruWordNumeralEnding from '../../../shared/ruWordNumeralEnding'
import config from '../../../../config'
import WidgetBase from '../../../shared/components/widget-base'
import Spinner from '../../../UI/spinner'
import fetch from '../../../shared/fetch'
import SliderModal from '../../../UI/slider-modal'
import ProfileMini from '../../../user/profile-mini'
import { canDo } from '../../../../store/utils'

import './styles.scss'

interface AdsModalContentProps extends ComponentBaseProps {
  onClose: any
  itemId: number
  adsUserId: number
  loggedUserId: number
  permissions: any
  isFavourite: number
  timer?: any
}

export default class AdsModalContent extends Component<AdsModalContentProps> {
  state = {
    itemId: +this.props.itemId,
    adsItem: {
      title: '',
      text: '',
      date: '',
      views: 0,
      tree_type_name: '',
      sub_tree_type_name: '',
      tree_type_id: null,
      user_name: '',
      user_last_name: '',
      user_id: 0,
      photos: [],
      user_active_ads: 0,
      company_active_ads: 0,
    },
    showConfirm: false,
    isLoading: true,
    isOpenModal: false,
    initialSlide: 0,
    isFavState: false,
  }

  componentDidMount() {
    const { isFavourite } = this.props
    const { itemId } = this.state
    this.fetchItem(itemId)
    const favorite = isFavourite ? true : false
    this.setState({ isFavState: favorite })
  }

  fetchItem = (itemId: number) => {
    fetch(`/announcement/item/${itemId}`, {
      method: 'POST',
      body: JSON.stringify({
        active: 1,
    })}).then(adsItem =>
      this.setState({ adsItem, isLoading: false })
    )
  }

  deleteAd = () => {
    const { itemId } = this.state
    const { t, onClose } = this.props
    this.setState({ showConfirm: true })
    confirmAlert({
      title: t('delete.confirm'),
      message: (
        <span>
          {t('delete.sure')}
          <br />
          {t('delete.cannot.undone')}
        </span>
      ) as any,
      buttons: [
        {
          label: t('delete'),
          onClick: () => {
            fetch(`/announcement/item/${itemId}`, { method: 'DELETE' }).then(_ => {
              onClose()
              location.reload()
            })
          },
        },
        {
          label: t('button.close'),
          onClick: this.hideConfirm,
        },
      ],
      onClickOutside: this.hideConfirm,
      onKeypressEscape: this.hideConfirm,
    })
  }

  hideConfirm = () => {
    this.setState({ showConfirm: false })
  }

  printAd = () => {
    const {
      adsItem: { title, tree_type_name, sub_tree_type_name, text, date, user_name, user_last_name },
    } = this.state
    const { t } = this.props
    const printWindow = window.open('', '', 'height=500, width=500')
    printWindow.document.write('<html>')
    printWindow.document.write('<body>')
    printWindow.document.write(`${title}<br>`)
    printWindow.document.write(`${t('ads')}>${tree_type_name}`)
    if (sub_tree_type_name) printWindow.document.write(`>${sub_tree_type_name}`)
    printWindow.document.write('<br>')
    printWindow.document.write(`${t('added')}: ${date}<br>`)
    printWindow.document.write(`${t('author')}: ${user_name} ${user_last_name}<br>`)
    printWindow.document.write(`<p style="word-wrap: break-word;">${text}</p>`)
    printWindow.document.write('</body></html>')
    printWindow.document.close()
    printWindow.print()
  }

  changeViewModal = photoId => {
    this.setState({
      isOpenModal: !this.state.isOpenModal,
      initialSlide: photoId,
    })
  }

  toggleAdFavourite = (adId: number, adIsFavourite?: number): void => {
    const body = JSON.stringify({ adId, alreadyFavourite: adIsFavourite })

    fetch('/announcement/toggleFavourite', { method: 'POST', body }).then(_ => {
      this.setState({ isFavState: !this.state.isFavState })
    })
  }

  contentTimer = () => {
    const { t, langId, timer } = this.props

    return (
      <div className="timeout-remaining">
        {t('time.ads.restriction.wait.first', { time: timer })}
        {` `}{ruWordNumeralEnding(
          t,
          parseInt(timer),
          'time.restriction.wait.second',
          langId
        )}
      </div>
    )
  }

  render() {
    const {
      itemId,
      isLoading,
      isOpenModal,
      initialSlide,
      adsItem: {
        title,
        text,
        date,
        views,
        tree_type_name,
        sub_tree_type_name,
        tree_type_id,
        user_id: postAuthor,
        photos = [],
      },
      isFavState,
    } = this.state
    const { t, onClose, loggedUserId, adsUserId, permissions, isFavourite, timer } = this.props

    if (isLoading) return <Spinner />

    const filteredPhotos = photos.filter(photo => photo)
    const canEdit = canDo(permissions, 'ad', 'edit') || loggedUserId === postAuthor
    const canDelete = canDo(permissions, 'ad', 'delete') || loggedUserId === postAuthor

    return (
      <div className={`ads-modal-wrapper table-ads`}>
        <WidgetBase
          title={t('ads.modal.title')}
          headerMenu={() => {
            return (
              <div className="modal-close-button" onClick={onClose}>
                <i className="fa fa-times modal-close-icon" />
              </div>
            )
          }}
          className="ads-modal-header panel-static"
        >
          <FlexBand className="panel-header-menu " justify="space-between" alignItems="center">
            <FlexBand className="panel-header-menu-item">
              <div>
                {canDelete && (
                  <span className="col-red" onClick={this.deleteAd}>
                    {t('delete')}
                  </span>
                )}

                {canEdit && (
                  <Link to={`/ads/edit/${itemId}`} onClick={onClose}>
                    <span className="edit-link">{t('edit')}</span>
                  </Link>
                )}
              </div>
            </FlexBand>
            <FlexBand justify="space-between" className="actions-panel">
              <Link to={`/ads/item/${itemId}`} target="_blank" className="action-el">
                {t('cargo.open.new.page')}
              </Link>
            </FlexBand>
          </FlexBand>
        </WidgetBase>
        <div className="links-wrapper">
          <Link to="/ads/list/436" onClick={onClose}>
            {t('ads.name')}
          </Link>{' '}
          {'>'}
          <Link to={`/ads/list/${tree_type_id}`} onClick={onClose}>
            {' '}
            {tree_type_name}
          </Link>
          {sub_tree_type_name && (
            <a href="#">
              {' '}
              {'>'} {sub_tree_type_name}
            </a>
          )}
        </div>
        <div className="ads-modal-container">
          <div className="item-container">

            {timer > 0 ?
              this.contentTimer() :

              <div className="item-content">
                <div className="item-container-links-wrapper">
                  <h4 className="transportads-title col-md-14">{title}</h4>
                  <div className="icons-links col-md-2">
                    {loggedUserId && (
                      <span
                        className={classnames('favorite', isFavState && 'is-active')}
                        onClick={() => this.toggleAdFavourite(itemId, isFavState && isFavourite)}
                      >
                        <i className="fa fa-bookmark"></i>
                      </span>
                    )}

                    <i className="fa fa-print" onClick={this.printAd}></i>
                  </div>
                </div>
                <div className="suplimentar-information">
                  <div className="left-panel-info">
                    <span>
                      <i className="fa fa-calendar" />
                      <span> {dayjs(date).format('DD.MM.YY')}</span>
                      <i className="fa fa-clock-o" />
                      <span className="clock-time"> {dayjs(date).format('hh:mm')} </span>
                      {t('views')}: {views}
                    </span>
                  </div>
                  <div className="right-links"></div>
                </div>
                {filteredPhotos.length !== 0 ? (
                  <div className="panel-content-photo" id="ads-item-photos">
                    <div className="panel-form-photo">
                      <SliderModal
                        photos={filteredPhotos}
                        isOpen={isOpenModal}
                        onExit={this.changeViewModal}
                        initialSlide={initialSlide}
                        imageFolder={`announcement/${adsUserId}`}
                      />
                      {filteredPhotos.map((photo, index) => (
                        <div className="col" key={index}>
                          <div
                            className="photo-block"
                            style={{
                              background: `url('${(config as any).apiUrl
                                }/common/file/announcement/${adsUserId}/${photo}')`,
                            }}
                            onClick={() => this.changeViewModal(index)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="item-container-description">
                  <p>{text}</p>
                </div>
              </div>
            }

          </div>
          <ProfileMini customUserId={postAuthor} closeModal={onClose} showFullInfo/>
        </div>
      </div>
    )
  }
}
