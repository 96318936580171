import {
  MODAL_SHOW_REQUEST,
  MODAL_CLOSE_REQUEST,
  MODAL_REFRESH_LAYOUT,
} from '../actions/action-types'

// ----------------------------------------
// initialState
// ----------------------------------------
const initialState = {
  instances: [],
  openedIndex: -1,
  isOpen: false,
}

// ----------------------------------------
// methods
// ----------------------------------------
const open = (state, { header, body, footer, options }) => {
  const instances = [...state.instances]
  const index = state.openedIndex + 1
  if (index > instances.length - 1) {
    instances.push({
      //token: uuid(),
      header,
      body,
      footer,
      options,
      isOpen: true,
    })
  }
  const newState = {
    instances,
    openedIndex: index,
    isOpen: true,
  }

  return { ...state, ...newState }
}
const close = state => {
  return { ...state, ...{ isOpen: false } }
}
const refresh = (state, action) => {
  return { ...state, ...{ formLayout: action.formLayout } }
}

// ----------------------------------------
// reducer
// ----------------------------------------
export default (state = initialState, action) => {
  switch (action.type) {
    case MODAL_SHOW_REQUEST:
      return open(state, action)
    case MODAL_CLOSE_REQUEST:
      return close(state)
    case MODAL_REFRESH_LAYOUT:
      return refresh(state, action)
    default:
      return state
  }
}
