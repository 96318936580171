import React, { Component } from 'react'
import classNames from 'classnames'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import FileUploader from '../file-uploader'
import config from '../../../config'

import './styles.scss'

const STYLE_BASE = 'image-upload-field_'

export default class ImageUploadField extends Component {
  state = {
    showConfirm: undefined,
    delete: false,
  }
  onChange = value => this.setValue(value.uuid)
  setValue = value => {
    const { input: { onChange } = {} } = this.props
    onChange && onChange(value)
  }
  clickDelete = item => {
    const { t } = this.props
    this.setState({ showConfirm: true })
    confirmAlert({
      title: t('delete.confirm'),
      message: (
        <span>
          {t('delete.sure')}
          <br />
          {t('delete.cannot.undone')}
        </span>
      ),
      buttons: [
        {
          label: t('delete'),
          onClick: () => {
            this.hideConfirm()
            this.setValue(null)
            this.setState({ delete: !this.state.delete })
          },
        },
        {
          label: t('button.close'),
          onClick: this.hideConfirm,
        },
      ],
      onClickOutside: this.hideConfirm,
      onKeypressEscape: this.hideConfirm,
    })
  }
  hideConfirm = () => {
    this.setState({ showConfirm: undefined })
  }

  render() {
    const {
      input: { value },
      folder,
      className,
      allowedExtensions,
    } = this.props
    
    return (
      <div className={classNames({
          [`${STYLE_BASE}wrapper`]: true,
          [className]: !!className,
        })}
      >
        <div className="img-container">
          {value && <img src={`${config.apiUrl}/common/file/${folder}/${value}`} alt="image" />}
        </div>
        <div className="input-file-custom">
          <FileUploader
            className="input-file"
            folder={folder}
            onSelected={this.onChange}
            showPreview={false}
            delete={this.state.delete}
            works={!value}
            allowedExtensions={allowedExtensions}
          />
        </div>
        {!value && (
          <span className="icon-add">
            <i className="fa fa-plus" />
          </span>
        )}
        {value && (
          <span className="icon-remove" onClick={this.clickDelete}>
            <i className="fa fa-close" />
          </span>
        )}
      </div>
    )
  }
}
