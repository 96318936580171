import { connect } from 'react-redux'
import { logout, loginStart, loginSuccess, resetLoginSocket } from '../../../store/actions'
// import { resetLoginSocket } from '../../../store/actions/socket'

import Component from './component'

export default connect(null, {
  loginStart,
  loginSuccess,
  logout,
  resetLoginSocket,
})(Component)
