import { connect } from 'react-redux'
import { compose } from 'recompose'

import Component from './component'

import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'

import {
  getItem,
  changeActiveCargo,
  changeActiveTransport,
  cargoAddFavourite,
  cargoDeleteFavourite,
  transportAddFavourite,
  transportDeleteFavourite,
} from '../../../store/actions'
import { onCanAdd } from '../../../store/actions/profile'
import { SearchItemProps } from './types'
import { IReduxStorage } from '../../../store/reducers/types'

export default compose(
  withTranslation,
  withPermissions,
  connect(
    (state: IReduxStorage, props: SearchItemProps) => {
      const item = props.searchType === 'cargo' ? state.cargo.get('item') : state.transport.item
      let itemId

      if (!props.isModalView) {
        itemId = props.match.params.itemId
      } else {
        itemId = props.itemId
      }

      return {
        itemId,
        item,
        scrollToOncommingItems: state.profile.scrollToOncommingItems,
        canAdd: state.profile.canAdd,
        isAuth: !!state.auth.accessToken,
      }
    },
    (dispatch: any, props: SearchItemProps) => {
      const isCargo = props.searchType === 'cargo'
      return {
        getItem: ({ itemId }) => dispatch(getItem({ itemId, searchType: props.searchType })),
        addFavourite: ({ entityId, isItem }) =>
          dispatch(
            isCargo
              ? cargoAddFavourite({ cargoId: entityId, isItem })
              : transportAddFavourite({ transportId: entityId, isItem })
          ),
        deleteFavourite: ({ entityId, isItem }) =>
          dispatch(
            isCargo
              ? cargoDeleteFavourite({ cargoId: entityId, isItem })
              : transportDeleteFavourite({ transportId: entityId, isItem })
          ),
        changeActiveItem: ({ itemId }) =>
          dispatch(
            isCargo
              ? changeActiveCargo({ cargoId: itemId })
              : changeActiveTransport({ transportId: itemId })
          ),
          onCanAdd: (type) => dispatch(onCanAdd(type))
      }
    }
  )
)(Component) as React.ComponentClass<SearchItemProps>
