import React, { Component } from 'react'
import fetch from '../../shared/fetch'
import { canDo } from '../../../store/utils'
import classnames from 'classnames'
import { ModalConsumer, ModalContextState } from '../../../context/modal-context'
import { AdvertisingProps, AdvertisingState } from './types'
import AdsBannerModal from "./ads-item-modal"
import Spinner from '../../UI/spinner'
import Ads from '../../ads'
import SiteStat from '../../site-stat'
import BuySellShort from '../../buy-sell/short'
import { getLanguageLowercaseName } from "../../../store/utils"

import "./styles.scss"
import moment from 'moment'

export default class Advertising extends Component<AdvertisingProps, AdvertisingState> {
  modalConsumer: ModalContextState
  state = {
    bannerPositions: [],
    langText: "",
    isLoading: false,
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    this.fetchAdsRules()
  }

  fetchAdsRules = () => {
    const { langId } = this.props;
    const lang = getLanguageLowercaseName(langId)

    fetch(`/common/all-banners-info/?lang=${lang}`).then(res => {
      this.setState({ bannerPositions: res.d, langText: lang, isLoading: false })
    })
  }

  refreshData = () => {
    const { clearModal } = this.modalConsumer
    clearModal()
    this.fetchAdsRules()
  }

  showEditBannerModal = (bannerId) => {
    const { langId } = this.props;
    const { langText } = this.state
    const { showModalComponent } = this.modalConsumer
    showModalComponent(
      <AdsBannerModal
        onClose={this.refreshData}
        {...{ langId }}
        {...{ langText }}
        {...{ bannerId }}
      />,
      {
        className: `employee-block-list-modal`,
        skipLayout: true,
        backdrop: true,
      }
    )
  }


  renderBannnersRules = (isAdmin) => {
    const { t } = this.props
    const { bannerPositions } = this.state

    const content = bannerPositions.map((banner) => {
      const {
        description,
        default_size_1,
        default_size_2,
        lev_id,
        price,
        item_id,
        date_end
      } = banner

      if (lev_id === 2) {
        return (
          <tr key={item_id}>
            <td colSpan={5} style={{ background: "#fff" }}>
              <span className="table-middle-title">{description}</span>
            </td>
          </tr>
        )
      } else if (lev_id === 3) {
        return (
          <tr key={item_id} onClick={() => isAdmin ? this.showEditBannerModal(item_id) : null}>
            <td><a href="#" className="place">{description}</a>
            </td>
            <td className="size"><a href="#">{default_size_1}{default_size_2 ? `x${default_size_2}` : ""}</a>
            </td>
            <td><a href="#">{price}</a>
            </td>
            <td><a href="#">{date_end ? moment.utc(date_end).format("DD.MM.YYYY") : t("banner.free")}</a>
            </td>
          </tr>
        )
      }
    })

    return content
  }

  renderTable = () => {
    const { t, permissions } = this.props
    const isAdmin = canDo(permissions, 'admin')

    return (
      <table
        className={classnames({
          'table adstable table-striped-main': true,
          'table-centered': true,
          'table-link': true,
          'table-lg ': true,
          'table-responsive': true,
          'c-pointer': isAdmin,
        })}>
        <thead
          dangerouslySetInnerHTML={{
            __html: t('about.us.advertising.thead')
          }} />

        <tbody>
          {this.renderBannnersRules(isAdmin)}
        </tbody>
      </table>
    )
  }


  render() {
    const { t } = this.props
    const { isLoading } = this.state

    const content = isLoading ? <Spinner /> : this.renderTable()

    return (
      <div className="row ads">
        <div className="col-md-12">
          <div className="main-content padding-zero">
            <div className="panel panel-n-bordered">
              <div className="panel-header">
                <h4>{t('ads.page.title')}</h4>
              </div>
              <div className="panel-body">
                <div className="panel-body-bordered">
                </div>
                <div className="panel-tab tabs-content" id="tab1">

                  <div className="panel-body-bordered">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t('about.us.advertising')
                      }} />
                    <div className="panel-filter additional-styles"> {t('ads.places.size')}</div>
                  </div>


                  <div className="panel-content">
                    <div className="panel-content-table">
                      {content}
                    </div>
                  </div>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('about.us.advertising.notes')
                  }} />

              </div>
            </div>
          </div>



        </div>
        <div className="col-md-4">
          <SiteStat />
          <BuySellShort />
          <Ads />
        </div>
        <ModalConsumer>
          {modalConsumer => {
            this.modalConsumer = modalConsumer
            return null
          }}
        </ModalConsumer>
      </div>
    )
  }
}
