import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import WidgetBase from '../widget-base'
import LoginLink from '../../../navigation/login-link'
import { canDo } from '../../../../store/utils'
import { IWidgetAceessProps } from './types'

const WidgetAccess: FunctionComponent<IWidgetAceessProps> = props => {
  const {
    t,
    isAuthenticated,
    permissions,
    module,
    action,
    title,
    actionMessage = '',
    children,
  } = props

  if (!isAuthenticated) {
    return (
      <WidgetBase title={title}>
        <div className="panel-search-wrapper centered-container">
          <div className="panel-search-log centered-item">
            <span className="fa fa-clock-o" />
            <p className="panel-search-text">{`${actionMessage} ${t('auth.only')}`}</p>
            <div className="panel-search-links">
              <LoginLink> {t('login.enter.2')} </LoginLink>
              {t('or')}
              <Link to="/auth/registration"> {t('login.register')} </Link>
            </div>
            <br />
            {t('login.family')}
          </div>
        </div>
      </WidgetBase>
    )
  }

  const hasAccess = canDo(permissions, module, action)

  if (!hasAccess) {
    return (
      <WidgetBase title={title}>
        <div className="panel-search-wrapper centered-container">
          <div className="panel-search-log centered-item">
            <span className="fa fa-clock-o" />
            <p className="panel-search-text">{t('not.enough.rights')}</p>
          </div>
        </div>
      </WidgetBase>
    )
  }

  return children
}

export default WidgetAccess
