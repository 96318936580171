import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reduxForm } from 'redux-form'
import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'
import { getGlobalUserRating, getGlobalCanUserAddReview } from '../../../store/actions/profile'
import Component from './component'
import { ReviewListProps } from './types'
import { withSnackbar } from 'notistack';

const formName = 'add-claim'

export default compose(
  withTranslation,
  withPermissions,
  connect(
    () => { return true; },
    {
      getGlobalUserRating,
      getGlobalCanUserAddReview
    }
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    forceUnregisterOnUnmount: false,
  })
)(withSnackbar(Component)) as React.ComponentClass<ReviewListProps>