import React, { Component } from 'react'
import {
  COMMENTS_ON_PAGE,
  DESCRIPTION_MAX_LENGTH,
  COMMENT_LENGTH_FOR_CUT,
} from '../../forum-constants'
import fetch from '../../../../../components/shared/fetch'
import Spinner from '../../../../UI/spinner'
import ForumCommentDate from '../../forum-date'
import classNames from 'classnames'
import { canDo, hideScrollBar, addScrollBar, fullTextLengthWithBreak } from '../../../../../store/utils'
import Pagination from '../../../../UI/pagination'
import ForumModalCompliant from '../forum-modal-complaint'
import TooltipWrapper from '../../../../UI/popover-wrapper'
import TooltipModal from '../../../../search/table/tooltip-modal'
import { Link } from 'react-router-dom'
import { BANNER_THIN_SIZE } from '../../../../../store/constants'
import Banner from '../../../../banner'

import './comments-items.scss'

interface CommentsItemsProps extends ComponentBaseProps {
  themeComments: any
  isAuthenticated: boolean
  totalCount: number
  themeId: number
  currentPage: number
  resetCurrentLastPagePagination: any
  commentAuthor: number
  permissions: any
  refreshLastCommentsAfterDeleteComment: any
  quoteMessage: any
}

export default class CommentsItems extends Component<CommentsItemsProps> {
  state = {
    themeComments: [],
    showActionDetails: 0,
    currentPage: 0,
    totalCount: 0,
    showSpinner: true,
    themeId: 0,
    editCommentId: 0,
    commentAfterEdit: '',
    removeComment: false,
    showCompliantModal: 0,
    showFullComment: 0,
  }

  componentDidMount() {
    this.getFromPropsAndSetInState()
  }

  componentDidUpdate(prevProps) {
    if (this.props.themeComments !== prevProps.themeComments) {
      this.setState({ showSpinner: true })
      this.getFromPropsAndSetInState()
    }
  }

  fetchThemeComments = (themeId: number, pageSize: number, pageIndex: number): void => {
    fetch(`/forum/getMessagesByTheme/${themeId}/${pageSize}/${pageIndex}`).then(comments => {
      setTimeout(() => {
        this.setState({
          themeComments: comments.data.messages,
          showSpinner: false,
          commentAfterEdit: '',
        })
      }, 300)
    })
  }

  getFromPropsAndSetInState = (currentPageAfterEditingComment = 0) => {
    const { themeComments, themeId, currentPage, resetCurrentLastPagePagination } = this.props
    const curPage =
      currentPageAfterEditingComment <= 0 ? currentPage : currentPageAfterEditingComment
    this.fetchThemeComments(themeId, COMMENTS_ON_PAGE, curPage)

    this.setState({
      themeComments,
      totalCount: themeComments.length,
      themeId,
      currentPage: curPage,
    })
    resetCurrentLastPagePagination()
  }

  showActionDetails = id => {
    const { showActionDetails } = this.state
    if (showActionDetails === id) {
      this.setState({ showActionDetails: 0 })
    } else {
      this.setState({ showActionDetails: id, removeComment: false, editCommentId: 0 })
    }
  }

  showCompliantModal = (commentId) => {
    this.setState({ showCompliantModal: commentId }, () => {
      hideScrollBar()
    })
  }

  renderAdditionalActions = (commentId: number, userId: number, message: string) => {
    const { t, permissions, commentAuthor } = this.props
    const canDeleteComments = canDo(permissions, 'forum', 'deleteComments')
    return (
      <div className="panel-comment-name additional-actions">
        {(commentAuthor == userId || canDeleteComments) && (
          <span
            className="name-link"
            onClick={() => this.setState({ editCommentId: commentId, commentAfterEdit: message })}
          >
            {t('forum.edit')}
          </span>
        )}
        {commentAuthor != userId && (
          <span
            className="name-link"
            onClick={() => this.showCompliantModal(commentId)}
          >
            {t('forum.complaint')}
          </span>
        )}
      </div>
    )
  }

  renderActionBlock = (commentId: number, userId: number, message: string) => {
    const { t, quoteMessage } = this.props
    const { showActionDetails } = this.state
    return (
      <div className="comments-action-block">
        <div className="panel-comment-name">
          <span className="meta-text">
            <span className="name-link" onClick={() => quoteMessage(message, commentId)}>
              {t('forum.quote')}{' '}
            </span>
            <span className="devider">|</span>
            <span className="name-link" onClick={() => this.showActionDetails(commentId)}>
              {t('forum.more')}{' '}
            </span>
          </span>
        </div>
        {showActionDetails === commentId &&
          this.renderAdditionalActions(commentId, userId, message)}
      </div>
    )
  }

  onSubmitEditComment = () => {
    const { refreshLastCommentsAfterDeleteComment } = this.props
    const { commentAfterEdit, editCommentId, currentPage } = this.state
    this.setState({ showSpinner: true })
    const body = JSON.stringify({
      messageId: editCommentId,
      newMessageText: commentAfterEdit,
    })

    fetch('/forum/editComment', {
      method: 'POST',
      body,
    }).then(() => {
      this.getFromPropsAndSetInState(currentPage)
      this.setState({ editCommentId: 0 })
      refreshLastCommentsAfterDeleteComment()
    })
  }

  onDeleteComment = commentId => {
    const { refreshLastCommentsAfterDeleteComment } = this.props
    const { currentPage } = this.state
    this.setState({ showSpinner: true })

    fetch(`/forum/deleteComment/${commentId}`, {
      method: 'DELETE',
    }).then(() => {
      this.getFromPropsAndSetInState(currentPage)
      this.setState({ editCommentId: 0 })
      refreshLastCommentsAfterDeleteComment()
    })
  }

  removeCommentPopup = commentId => {
    const { t } = this.props
    return (
      <div className="popup-remove-comment">
        <div className="remove-container">
          <h1>{t('forum.delete.comment.title')}</h1>
          <button
            type="button"
            className="button button-orange"
            onClick={() => this.onDeleteComment(commentId)}
          >
            {t('forum.delete')}
          </button>
          <button
            type="button"
            className="button button-orange button-blue"
            onClick={() => this.setState({ removeComment: false })}
          >
            {t('forum.close')}
          </button>
          <div className="remove-note">{t('forum.note')}</div>
        </div>
      </div>
    )
  }

  renderTextareaOrText = (message, userId, commentId, index) => {
    const { isAuthenticated, t, commentAuthor, permissions } = this.props
    const { commentAfterEdit, editCommentId, currentPage } = this.state
    const errorComments = message.length > DESCRIPTION_MAX_LENGTH ? 'error' : ''
    const errorClasses = !isAuthenticated ? 'textarea-disabled' : errorComments
    const disabled = message.length === 0 || message.length > DESCRIPTION_MAX_LENGTH
    const disabledBtnEditComment =
      commentAfterEdit == '' || commentAfterEdit.length > DESCRIPTION_MAX_LENGTH ? true : disabled
    const isAdmin = canDo(permissions, 'admin')
    const isAdminForFirstComment = isAdmin && index === 0 && currentPage === 0
    const commentLengthError =
      commentAfterEdit.length > DESCRIPTION_MAX_LENGTH ? 'comment-length-error' : ''

    if (commentAuthor === parseInt(userId) || isAdminForFirstComment) {
      return (
        <div>
          <span className="col-gray length-countdown fl-right">
            <span className={`count ${commentLengthError}`}>{commentAfterEdit.length}</span>/
            <span className="length">{DESCRIPTION_MAX_LENGTH}</span>
          </span>
          <textarea
            placeholder={`${t('forum.no.more')} ${DESCRIPTION_MAX_LENGTH} ${t('forum.characters')}`}
            className={errorClasses}
            value={commentId == editCommentId ? commentAfterEdit : ''}
            onChange={e => this.setState({ commentAfterEdit: e.target.value })}
          />
          <button
            type="button"
            className="button button-orange"
            disabled={disabledBtnEditComment}
            onClick={this.onSubmitEditComment}
          >
            {t('forum.save')}
          </button>
        </div>
      )
    } else {
      return <p className="simple-text">{message}</p>
    }
  }

  showEditForm = (message: string, commentId: number, userId: number, index: number) => {
    const { t, permissions } = this.props
    const { editCommentId, removeComment, currentPage } = this.state
    const disabled = message.length === 0 || message.length > DESCRIPTION_MAX_LENGTH
    const canDeleteMessages = canDo(permissions, 'forum', 'deleteComments')
    return (
      <div className="edit-form-container">
        {editCommentId == commentId && removeComment && this.removeCommentPopup(commentId)}

        {this.renderTextareaOrText(message, userId, commentId, index)}

        <div className="button-container">
          <button
            type="button"
            className="button button-orange button-blue"
            disabled={disabled}
            onClick={() => this.setState({ editCommentId: 0, commentAfterEdit: '' })}
          >
            {t('forum.close')}
          </button>
          {(currentPage !== 0 || index !== 0) && (
            <button
              type="button"
              className="button button-orange button-red"
              onClick={() => this.setState({ removeComment: true })}
            >
              {t('forum.delete')}
            </button>
          )}
        </div>
      </div>
    )
  }

  closeModal = () => {
    this.setState({ showCompliantModal: 0 }, () => {
      addScrollBar()
    })
  }

  setShowFullComment = value => {
    this.setState({ showFullComment: value })
  }
  
  renderShowMore = value => {
    const { t } = this.props
    const { showFullComment } = this.state
    const label = showFullComment == value ? t('forum.hide') : t('forum.details')
    const commentValue = showFullComment == 0 ? value : 0
    return (
      <div className="show-more-details">
        <b onClick={() => this.setShowFullComment(commentValue)}>{label}</b>
      </div>
    )
  }

  renderCommentDetails = (datePosted, dateModified, index, currentPage) => {
    const { t } = this.props
    const actionComment = index === 0 && currentPage === 0 ? t('forum.created') : t('forum.writed')
    if (dateModified) {
      return (
        <span className="meta-text">
          <i className="fa fa-edit" /> {t('forum.edited')}: {ForumCommentDate(dateModified)}
        </span>
      )
    }
    return (
      <span className="meta-text">
        {actionComment}: {ForumCommentDate(datePosted)}
      </span>
    )
  }

  renderComments = () => {
    const { isAuthenticated, commentAuthor, t } = this.props
    const {
      themeComments,
      editCommentId,
      showCompliantModal,
      showFullComment,
      currentPage,
    } = this.state

    const comments = themeComments.map((oneComment, index) => {
      const {
        id,
        message,
        user: { username, userId },
        datePosted,
        dateModified,
      } = oneComment
      const countRepeat = fullTextLengthWithBreak(message)
      const readMore = message.length + countRepeat > COMMENT_LENGTH_FOR_CUT ? this.renderShowMore(id) : ''
      const readMoreForQuote =
        oneComment.quote == undefined
          ? ''
          : oneComment.quote.quoteText.length > COMMENT_LENGTH_FOR_CUT
          ? this.renderShowMore(0 + oneComment.quote.quoteId)
          : ''
      const show = showFullComment == id ? 'show-full-comment' : ''
      const showQuote =
        oneComment.quote == undefined
          ? ''
          : showFullComment == 0 + oneComment.quote.quoteId
          ? 'show-full-comment'
          : ''


      return (
        <div key={id}>
          {index === 1 && <Banner size={BANNER_THIN_SIZE} />}
          <div
            className={classNames({
              'panel-content-row': true,
              'highlight-comment': commentAuthor == userId,
            })}
          >
            {id == showCompliantModal && (
              <ForumModalCompliant commentId={id} closeModal={this.closeModal} t={t} />
            )}
            <div className="panel-comment">
              <div className="panel-comment-content">
                <div className="panel-comment-name">
                  <span className="name">
                    <Link to={`user/details/${userId}`} className="name-link has-tooltip">
                      <TooltipWrapper
                        text={<TooltipModal companyId={0} userId={userId} />}
                        hover
                        innerClassName="full-tooltip"
                        disabled={false}
                        parentCustomClass="move-up-container"
                      >
                        {username}
                      </TooltipWrapper>
                    </Link>
                    {oneComment.company && (
                      <Link to="/" className="office">
                        <TooltipWrapper
                          text={
                            <TooltipModal
                              companyName={oneComment.company.companyName}
                              companyId={oneComment.company.companyId}
                              userId={0}
                            />
                          }
                          hover
                          innerClassName="full-tooltip"
                          disabled={false}
                        >
                          {oneComment.company.companyName}
                        </TooltipWrapper>
                      </Link>
                    )}
                  </span>
                  <div className="comments-details"></div>
                  {this.renderCommentDetails(datePosted, dateModified, index, currentPage)}
                </div>
                {oneComment.quote ? (
                  <div>
                    <div className="panel-message-gray">
                      <p className={`title-sm ${showQuote}`}>{oneComment.quote.quoteText}</p>
                      {editCommentId === 0 && readMoreForQuote}
                    </div>

                    <div className="panel-comment-desc">
                      {editCommentId === id && this.showEditForm(message, id, userId, index)}
                      <p className={show}>{editCommentId !== id ? message : ''}</p>
                      {editCommentId === 0 && readMore}
                    </div>
                  </div>
                ) : (
                  <div className="panel-comment-desc no-qoute">
                    {editCommentId === id && this.showEditForm(message, id, userId, index)}
                    <p className={show}>{editCommentId !== id ? message : ''}</p>
                    {editCommentId === 0 && readMore}
                  </div>
                )}
              </div>
              <div className="comments-clear" />
              {isAuthenticated &&
                editCommentId !== id &&
                this.renderActionBlock(id, userId, message)}
            </div>
          </div>
        </div>
      )
    })
    return comments
  }

  renderPagination = () => {
    const { totalCount } = this.props
    const { currentPage } = this.state
    return (
      <Pagination
        onClick={this.paginationClick}
        total={totalCount}
        pageIndex={currentPage}
        pageSize={COMMENTS_ON_PAGE}
      />
    )
  }

  paginationClick = page => {
    const { themeId } = this.state
    this.setState({ currentPage: page, showSpinner: true })
    this.fetchThemeComments(themeId, COMMENTS_ON_PAGE, page)
  }

  renderWrapperForComment = () => {
    return <div className="panel-content-row comments-pagination">{this.renderPagination()}</div>
  }

  render() {
    const { totalCount } = this.props
    const { showSpinner } = this.state
    const showPagination = totalCount > COMMENTS_ON_PAGE
    return (
      <div className="comment-main-block">
        {showPagination && this.renderWrapperForComment()}
        {showSpinner ? <Spinner /> : this.renderComments()}
        {showPagination && this.renderWrapperForComment()}
      </div>
    )
  }
}
