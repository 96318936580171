import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose } from 'recompose'

import { reduxForm, reset } from 'redux-form'

import Component from './component'

import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'
import { addComment } from '../../../store/actions/news'

const FORMNAME = 'addCommentOnNews'

const initialValues = {
  text: '',
}

export default compose(
  withTranslation,
  withPermissions,
  connect(
    state => {
      const { pageIndex, pageSize } = state.news.comments
      const { itemId } = state.news.item
      const { text, loading: saving } = state.news.comment
      const profile = state.auth.profile
      const photo = profile.getIn(['data', 'photo'])
      return {
        itemId,
        text,
        pageIndex,
        pageSize,
        saving,
        photo,
      }
    },
    dispatch =>
      bindActionCreators(
        {
          addComment,
          reset: () => {
            dispatch(reset(FORMNAME))
          },
        },
        dispatch
      )
  ),
  reduxForm({
    form: FORMNAME,
    initialValues,
    touchOnChange: true,
    // destroyOnUnmount: false,
    onSubmit: ({ text }, dispatch, { itemId, pageIndex, pageSize }) => {
      const d = { itemId, pageIndex, pageSize, text }
      dispatch(addComment(d)).then(() => {
        dispatch(reset(FORMNAME))
      })
    },
  })
)(Component)
