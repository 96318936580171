import React from 'react'

interface ITabItem {
  value: string | number
  name: string
  entityCount?: number
}

interface TabsProps {
  tabItems?: ITabItem[]
  onTabClick?: (tabValue: string | number) => void
  currentTab: string | number
}

const Tabs: React.FunctionComponent<TabsProps> = ({
  tabItems,
  onTabClick,
  children,
  currentTab,
}) => {
  return (
    <div id="tabsContent">
      <div className="filter-nav priority-nav">
        <ul className="panel-tabs-list tabs-list">
          {tabItems.map(item => (
            <li
              key={item.value}
              className={currentTab === item.value ? 'active' : null}
              onClick={() => onTabClick(item.value)}
            >
              <a>{item.name}</a>
            </li>
          ))}
        </ul>
      </div>
      <div
        style={{
          border: '#cbcfd1 solid 1px',
          borderRadius: '10px',
          marginTop: -1,
          borderTopLeftRadius: currentTab === tabItems[0].value ? 0 : 10,
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default Tabs
