import { connect } from 'react-redux'
import { compose, pure } from 'recompose'
import { reduxForm, formValueSelector } from 'redux-form'
import moment from 'moment'

import Component from './component'

import withTranslation from '../../../../../globals/components/withTranslation'
import { save, loadItems, getItem } from '../../../../../store/actions/news'

const formName = 'news-add-edit-form'
const formSelector = formValueSelector(formName)

export default compose(
  withTranslation,
  pure,
  connect(
    (state, props) => {
      const { langId, item = state.news.editModal.item } = props

      const today = moment().format('YYYY-MM-DD')

      const isNew = !item.itemId
      if (isNew) {
        item.langIds = [langId] // set language as current page langId
      }

      return {
        isNew,
        initialValues: item,
        list: state.news.list,
        miniatureImage: formSelector(state, 'image'),
        body: formSelector(state, 'body'),
        imageFolder: item && item.imageFolder ? item.imageFolder : `news/${today}`,
      }
    },
    {
      save,
      loadItems,
      getItem,
    }
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onSubmit: (values, dispatch, props) => {
      const { itemId } = values

      props.save({...values, imageFolder: props.imageFolder}).then(() => {
        props.onClose()
        if (itemId) {
          props.getItem({ itemId })
        }
        props.loadItems(props.list)
      })
    },
  })
)(Component)
