// import ReactDOM from 'react-dom'

export const focusFirstInput = refComponent => {
  // setTimeout(() => {
  //     const c = ReactDOM.findDOMNode(refComponent).getElementsByTagName("input")[0]
  //     c && c.focus()
  // }, 500)
  if (refComponent) {
    // const c = ReactDOM.findDOMNode(refComponent).getElementsByTagName('input')[0]
    const c = refComponent.current.getElementsByTagName('input')[0]
    if (c) {
      setTimeout(() => c.focus(), 100)
    }
  }
}

export const changeWidgetFilter = (instance, filterKey, cb) => {
  const filterBar = { ...instance.state.filterBar }
  filterBar.selectedKey = filterKey
  instance.setState({ filterBar }, () => cb && cb())
}

// flat multidemensional to linear array
export const flattenArray = arr => {
  const flattened = [].concat(...arr)
  return flattened.some(item => Array.isArray(item)) ? flattenArray(flattened) : flattened
}

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  }
}

export const validate = (value, rules) => {
  if (!rules) {
    return true
  }

  const errors = []
  let rule
  let valid = true
  let ok
  const handle = (rule, comparator, defaultMessage) => {
    if (rule) {
      ok = comparator(value)
      valid = ok && valid
      if (!ok) {
        errors.push(rule.message || defaultMessage)
      }
    }
  }

  rule = rules.required
  handle(rule, v => v.trim() !== '', 'Required')

  rule = rules.minLength
  handle(rule, v => v.length >= rule, `Min length is ${rule}`)

  rule = rules.maxLength
  handle(rule, v => v.length <= rule, `Max  length is ${rule}`)

  rule = rules.isEmail
  handle(
    rule,
    v =>
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        v
      ),
    'Email is malformed'
  )

  rule = rules.isNumeric
  handle(rule, v => /^\d+$/.test(v), 'Is not numeric')

  return {
    valid,
    errors,
  }
}

export const getError = err => {
  if (typeof err === 'string') {
    return err
  }
  const { response: { data: { error } = {} } = {} } = err
  return error
}

export const checkValidity = (value, rules) => {
  let isValid = true
  if (!rules) {
    return true
  }

  if (rules.required) {
    isValid = value.trim() !== '' && isValid
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    isValid = pattern.test(value) && isValid
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/
    isValid = pattern.test(value) && isValid
  }

  return isValid
}
