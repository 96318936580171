export default [
    { name: 'menu.pivot.table', type: 'item', href: '/summary-table' },
    {
      name: 'menu.companies.and.users',
      type: 'ddl',
      href: '/company/items',
      dictionary: "",
      options: [
        { name: 'menu.all.companies', href: '/company/items' },
        { name: 'menu.all.users', href: '/company/users' },
        { name: 'menu.reviews.and.claims', href: '/company/rating/reviews' },
        { name: 'menu.blacklist', href: '/company/blacklist' },
        { name: 'menu.user.joining.in.company', href: '/company/joining-the-company' },
        { name: 'menu.company.verification', href: '/company/company-verification' },
        { name: 'menu.autopark', href: '/company/autopark' },
      ],
    },
    {
      name: 'menu.news',
      type: 'ddl',
      href: '/news/list',
      dictionary: "filterOptionsForNews",
      urlPrefix: "/news/list?filterKey=",
      options: [],
    },
    {
      name: 'menu.ads',
      type: 'ddl',
      href: '/ads/list/436',
      dictionary: "filterOptionsForAds",
      urlPrefix: "/ads/list/",
      options: [],
    },
    {
      name: 'menu.forum',
      type: 'item',
      href: '/forum',
      dictionary: "",
    },
    {
      name: 'menu.useful',
      type: 'ddl',
      href: '/useful',
      options: [
        { name: 'menu.legislation', href: '/useful/legislation' },
        { name: 'menu.currency.converter', href: 'https://www.curs.md/ru', targetBlank: true },
        { name: 'menu.schengen.calculator', href: 'https://ec.europa.eu/assets/home/visa-calculator/calculator.htm?lang=ru', targetBlank: true },
      ],
    },
    {
      name: 'menu.about',
      type: 'ddl',
      href: '/about-us',
      dictionary: "",
      options: [
        { name: 'menu.about.us', href: '/about-us' },
        { name: 'menu.rules.for.posting.information', href: '/about-us/terms-of-use' },
        { name: 'menu.terms.of.use', href: '/about-us/policy' },
        { name: 'menu.advertising.on.the.website', href: '/about-us/cookies' },
        { name: 'menu.prices', href: '/about-us/prices' },
        { name: 'menu.ads.on.site', href: '/about-us/advertising' },
        { name: 'menu.faq', href: '/about-us/faq' },
      ],
    },
  ]