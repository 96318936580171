import React, { Component } from 'react'

import Modal from 'react-modal-slider'
import Slider from 'react-slick'
import classNames from 'classnames'
import Icon from 'react-icons-kit'
import { ic_clear } from 'react-icons-kit/md'

import config from '../../../config'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-modal-slider/lib/main.css'
import './style.scss'

interface IModalSliderProps {
  photos: string[]
  isOpen: boolean
  initialSlide: number
  imageFolder: string
  onExit: (photoId?: number) => void
}

interface IIModalSliderState {
  showBar: boolean
}

export default class ModalSlider extends Component<IModalSliderProps, IIModalSliderState> {
  timeOut
  constructor(props) {
    super(props)

    this.state = {
      showBar: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props
    if (isOpen && isOpen !== prevProps.isOpen) {
      this.showBar(10000)
    }
  }

  toggleVisibleModal = () => {
    this.props.onExit()
  }

  renderCloseComponent = () => (
    <div className="modal-close-button" onClick={this.toggleVisibleModal}>
      <Icon icon={ic_clear} size={26} className="modal-close-icon" />
    </div>
  )

  showBar = (timer = 5000) => {
    clearTimeout(this.timeOut)
    this.setState({ showBar: true })
    if (timer) {
      this.timeOut = setTimeout(() => this.setState({ showBar: false }), timer)
    }
  }

  render() {
    const { photos, isOpen, initialSlide, imageFolder } = this.props
    return (
      <div id={'slider-block'} onClick={() => this.showBar()}>
        <Modal
          isOpen={isOpen}
          width={'100%'}
          directionFrom={'top'}
          contentLabel={'Modal'}
          onRequestClose={this.toggleVisibleModal}
          setAppElement={'#root'}
          ariaHideApp={true}
          maxMediaWidth={1024}
          className={classNames({
            'modal-slider': true,
            'animated-bar': this.state.showBar,
          })}
          overlayClassName={'modal-slider-overlay'}
        >
          <Slider
            draggable={false}
            infinite={false}
            speed={500}
            className="slick-slider"
            initialSlide={initialSlide}
          >
            {photos.map((photo, id) => (
              <div key={id} className="slider-photo-block">
                <img
                  src={`${(config as any).apiUrl}/common/file/${imageFolder}/${photo}`}
                  alt="photo"
                  className="slider-photo"
                />
              </div>
            ))}
          </Slider>
          {this.renderCloseComponent()}
          <div
            className="arrows-bar"
            onMouseEnter={() => this.showBar(0)}
            onMouseLeave={() => this.showBar()}
          />
        </Modal>
      </div>
    )
  }
}
