import _ from 'lodash'
import React, { Component } from 'react'
// import css from "classnames"
import { Link } from 'react-router-dom'
import TooltipWrapper from '../../UI/popover-wrapper'
import TooltipModal from '../../search/table/tooltip-modal'
import { changeWidgetFilter } from '../../shared/utility'
import WidgetList from '../../shared/components/widget-list'
import fetch from '../../shared/fetch'
import Rating from '../../UI/star-rating'
import moment from 'moment'
import Spinner from '../../UI/spinner'
import { Redirect } from 'react-router-dom'
import { IReviewWidgetProps } from './types'

import './style.scss'

export default class ReviewWidget extends Component<IReviewWidgetProps> {

  state = {
    items: [],
    isFetching: false,
    filterBar: {
      items: [
        {
          value: 'users',
          name: 'about.users',
        },
        {
          value: 'companies',
          name: 'about.companies',
        },
      ],
      selectedKey: 'users',
      onClick: filterKey =>
        changeWidgetFilter(this, filterKey, () => {
          this.update()
        }),
    },
  }

  componentDidMount() {
    this.refreshReviewsContent("users")
  }

  update = () => {
    const { selectedKey } = this.state.filterBar
    this.setState({
      isFetching: true
    }, () => {
      setTimeout(() => {
        if (selectedKey === 'users') {
          this.refreshReviewsContent("users")
        } else {
          this.refreshReviewsContent("companies")
        }
      }, 500);
    })
  }

  refreshReviewsContent = (filter) => {
    const type = filter === "users" ? "USER" : "COMPANY"

    const data = {
      type,
      pageIndex: 0,
      pageSize: 5
    }
    fetch(`/reviews`, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then(res => {
      this.setState({
        items: res.data.items,
        isFetching: false,
      })
    })
  }

  renderRow = (
    row,
    index,
  ) => {
    const { t } = this.props
    const { filterBar: { selectedKey } } = this.state
    const { datePosted, reviewerId, reviewed, userReviewed, review, grade, reviewerName, userReviewedName } = row

    const linkReviewedCompany = selectedKey === "users" ? `/user/details/${row.userReviewed}` : `/company/item/${row.companyReviewed}`
    const linkReviewCompanyOrUser = selectedKey === "users" ? `/user/details/${reviewerId}` : `/company/item/${reviewerId}`
    const link = selectedKey === "users" ? `/user/details/${row.userReviewed}/reviews` : `/company/item/${row.companyReviewed}/reviews`

    return (
      <div key={index} className="panel-content-row">
        <span className="meta">{moment(datePosted).format('DD.MM')}</span>
        <p className="name">
          <Link to={linkReviewCompanyOrUser} className="name-link">
            <TooltipWrapper
              text={<TooltipModal companyId={selectedKey !== "users" && reviewerId} userId={selectedKey === "users" && reviewerId} />}
              hover
              innerClassName="full-tooltip"
              disabled={false}
              parentCustomClass="move-up-container"
            >
              {reviewerName}
            </TooltipWrapper>
          </Link>
          {selectedKey === "users" ? t('review.widget.content') : t('review.widget.content.company')}
        </p>
        <div className="name-lg">
          <Link to={linkReviewedCompany}>
            {selectedKey === "users" ? (
              <TooltipWrapper
                text={<TooltipModal companyId={0} userId={userReviewed} />}
                hover
                innerClassName="full-tooltip"
                disabled={false}
                parentCustomClass="move-up-container"
              >
                {userReviewedName}
              </TooltipWrapper>
            ) : (
              <TooltipWrapper
                text={<TooltipModal companyId={row.companyReviewed} userId={0} />}
                hover
                innerClassName="full-tooltip"
                disabled={false}
                parentCustomClass="move-up-container"
              >
                {row.companyName}
              </TooltipWrapper>
            )}


          </Link>
        </div>
        {selectedKey === "users" && <Rating averageRating={grade} />}
        <Link className="long-text redirect-reviews" to={link} >
          {review.slice(0, 100)} {review.length > 100 && '...'}
        </Link>
      </div>
    )
  }
  renderRows = items => {
    const { isFetching } = this.state

    if (isFetching) return <Spinner />

    return items.map((item, index) => this.renderRow(item, index))
  }

  render() {
    const { t, dataMoveForMobile } = this.props
    const { filterBar, items } = this.state

    return (
      <WidgetList
        title={t('review.widget.title')}
        items={items}
        renderRows={this.renderRows}
        linksTo={{ title: '/company/rating/reviews' }}
        filterBar={filterBar}
        dataMoveForMobile={dataMoveForMobile}
      />
    )
  }
}
