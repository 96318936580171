import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reduxForm, formValueSelector } from 'redux-form'

import Component from './component'

import withTranslation from '../../globals/components/withTranslation'
import { IReduxStorage } from '../../store/reducers/types'

const formName = 'calcDistance'

const initialValues = {
  from: '',
  to: '',
}

const formSelector = formValueSelector(formName)

export default compose(
  withTranslation,
  connect((state: IReduxStorage) => ({
    from: formSelector(state, 'from'),
    to: formSelector(state, 'to'),
  })),
  reduxForm({
    form: formName,
    initialValues,
  })
)(Component)
