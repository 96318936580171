import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'
import { IReduxStorage } from '../../../store/reducers/types'
import { getProfile } from '../../../store/actions/profile'

import Component from './component'

export default compose(
  withTranslation,
  withRouter,
  withPermissions,
  connect((state: IReduxStorage) => {
    return {
      langId: state.ui.langId,
    }
  }, {
    getProfile
  })
)(Component)