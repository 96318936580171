import React, { Component } from 'react'
import Widget from '../../shared/components/widget'
import Ads from '../../ads'
import SiteStat from '../../site-stat'
import BuySellShort from '../../buy-sell/short'

import "./styles.scss"

export default class extends Component {
  render() {
    const { t } = this.props
    return (
      <div className="row">
        <div className="col-md-12">
          <Widget title={t('about.about.page.title')}>
            <div
              dangerouslySetInnerHTML={{
                __html: t('about.us.page')
              }} />
          </Widget>
        </div>
        <div className="col-md-4">
          <SiteStat />
          <BuySellShort />
          <Ads />
        </div>
      </div>
    )
  }
}
