export const int25 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>ЕВРОПЕЙСКОЕ СОГЛАШЕНИЕ О ВАЖНЕЙШИХ ВНУТРЕННИХ ВОДНЫХ ПУТЯХ МЕЖДУНАРОДНОГО ЗНАЧЕНИЯ (СМВП/AGN)</h4><p></p>

<p style="text-indent:2em;">(Вместе с "ПЕРЕЧНЕМ ВНУТРЕННИХ ВОДНЫХ ПУТЕЙ МЕЖДУНАРОДНОГО ЗНАЧЕНИЯ", "ПЕРЕЧНЕМ 
ПОРТОВ ВНУТРЕННЕГО ПЛАВАНИЯ МЕЖДУНАРОДНОГО ЗНАЧЕНИЯ", "ТЕХНИЧЕСКИМИ И ЭКСПЛУАТАЦИОННЫМИ 
ХАРАКТЕРИСТИКАМИ ВНУТРЕННИХ ВОДНЫХ ПУТЕЙ МЕЖДУНАРОДНОГО ЗНАЧЕНИЯ", 
"КЛАССИФИКАЦИЕЙ ЕВРОПЕЙСКИХ ВНУТРЕННИХ ВОДНЫХ ПУТЕЙ МЕЖДУНАРОДНОГО ЗНАЧЕНИЯ")</p><br>

<p>(Заключено в г. Женеве 19.01.1996)</p>

<p></p><h4>ЕВРОПЕЙСКОЕ СОГЛАШЕНИЕ О ВАЖНЕЙШИХ ВНУТРЕННИХ ВОДНЫХ ПУТЯХ 
МЕЖДУНАРОДНОГО ЗНАЧЕНИЯ (СМВП)</h4><p></p>

<p>(Женева, 19 января 1996 года)</p>

<p style="text-indent:2em;"></p><h6>Договаривающиеся Стороны, сознавая необходимость облегчения и развития в 
Европе международных перевозок внутренним водным транспортом, учитывая ожидаемое 
увеличение грузовых международных перевозок в результате расширения международной
 торговли, подчеркивая важную роль внутреннего водного транспорта, который по 
 сравнению с другими видами внутреннего транспорта располагает экономическими 
 и экологическими преимуществами и характеризуется наличием избыточной 
 инфраструктуры и грузоподъемности судов и поэтому может обусловить 
 снижение социальных издержек и негативного воздействия на окружающую 
 среду со стороны внутреннего транспорта в целом, будучи убежденными, 
 что для повышения эффективности международных перевозок во внутреннем 
 водном сообщении в Европе и их привлекательности для потребителей 
 необходимо создать правовую базу, определяющую согласованный план 
 развития и строительства сети внутренних водных путей международного 
 значения на основе согласованных параметров инфраструктуры и 
 эксплуатационных параметров, договорились о нижеследующем:</h6><p></p>
 
<p style="font-weight:500;">Статья 1</p><br>

<p>Определение сети</p><br>

<p style="text-indent:2em;">Договаривающиеся Стороны принимают положения настоящего Соглашения 
в качестве согласованного плана развития и строительства сети внутренних
 водных путей, именуемой далее "сетью внутренних водных путей международного 
 значения" или "сетью водных путей категории E", который они намерены 
 осуществить в рамках своих соответствующих программ. Сеть водных путей 
 категории E включает внутренние водные пути и порты международного значения, 
 которые указаны в Приложениях I и II к настоящему Соглашению.</p><br>

<p style="font-weight:500;">Статья 2</p><br>

<p>Технические и эксплуатационные характеристики сети</p><br>

<p style="text-indent:2em;">Сеть внутренних водных путей международного значения, о которой говорится 
в статье 1, должна соответствовать характеристикам, указанным в Приложении 
III к настоящему Соглашению, либо будет приведена в соответствие с 
положениями этого Приложения в ходе работ по дальнейшей модернизации.</p><br>

<p style="font-weight:500;">Статья 3</p><br>

<p>Приложения</p><br>

<p>Приложения к настоящему Соглашению являются составной частью Соглашения.</p><br>

<p style="font-weight:500;">Статья 4</p><br>

<p>Определение депозитария</p><br>

<p>Депозитарием настоящего Соглашения является Генеральный секретарь Организации
 Объединенных Наций.</p><br>

<p style="font-weight:500;">Статья 5</p><br>

<p>Подписание</p><br>

<p style="text-indent:2em;">1. Настоящее Соглашение открыто для подписания в Отделении Организации Объединенных
 Наций в Женеве государствами, которые являются членами Европейской экономической комиссии
 Организации Объединенных Наций либо допущены к участию в работе Комиссии с консультативным
 статусом в соответствии с пунктами 8 и 11 Положения о круге ведения Комиссии, с 1 октября
 1996 года до 30 сентября 1997 года.</p><br>

<p>2. Такое подписание подлежит ратификации, принятию или утверждению.</p><br>

<p style="font-weight:500;">Статья 6</p><br>

<p>Ратификация, принятие или утверждение</p><br>

<p>1. Настоящее Соглашение подлежит ратификации, принятию или утверждению в соответствии
 с пунктом 2 статьи 5.</p><br>

<p style="text-indent:2em;">2. Ратификация, принятие или утверждение осуществляются путем сдачи на хранение
 Генеральному секретарю Организации Объединенных Наций соответствующего документа.</p><br>

<p style="font-weight:500;">Статья 7</p><br>

<p>Присоединение</p><br>

<p style="text-indent:2em;">1. К настоящему Соглашению может присоединиться любое государство, указанное
 в пункте 1 статьи 5, с 1 октября 1996 года.</p><br>

<p style="text-indent:2em;">2. Присоединение осуществляется путем сдачи на хранение Генеральному секретарю
 Организации Объединенных Наций соответствующего документа.</p><br>

<p style="font-weight:500;">Статья 8</p><br>

<p>Вступление в силу</p><br>

<p style="text-indent:2em;">1. Настоящее Соглашение вступает в силу по истечении 90 дней с того дня, в
 который правительства пяти государств сдадут на хранение документ о ратификации,
 принятии, утверждении или присоединении при условии, что один или несколько внутренних
 водных путей сети внутренних водных путей международного значения непрерывно
 соединяют территории по крайней мере трех государств, сдавших на хранение такой
 документ.</p><br>

<p style="text-indent:2em;">2. В случае невыполнения этого условия Соглашение вступает в силу по истечении 90
 дней со дня сдачи на хранение документа о ратификации, принятии, утверждении или 
 присоединении, при наличии которого это условие будет выполнено.</p><br>

<p style="text-indent:2em;">3. В отношении каждого государства, которое сдаст на хранение документ о ратификации,
 принятии, утверждении или присоединении после даты, начиная с которой исчисляется срок
 90 дней, указанный в пунктах 1 и 2 настоящей статьи, Соглашение вступает в силу по
 истечении 90 дней со дня сдачи на хранение этого документа.</p><br>

<p style="font-weight:500;">Статья 9</p><br>

<p>Ограничения в применении настоящего Соглашения</p><br>

<p style="text-indent:2em;">1. Никакое положение настоящего Соглашения не должно толковаться как препятствующее
 какой-либо из Договаривающихся Сторон принимать совместимые с Уставом Организации
 Объединенных Наций и ограниченные сложившейся ситуацией меры, которые она считает
 необходимыми для обеспечения своей внешней или внутренней безопасности.</p><br>

<p style="text-indent:2em;">2. Эти меры, которые должны носить временный характер, немедленно доводятся до 
сведения депозитария с указанием их характера.</p><br>

<p style="font-weight:500;">Статья 10</p><br>

<p>Урегулирование споров</p><br>

<p style="text-indent:2em;">1. Любой спор между двумя или более Договаривающимися Сторонами относительно
 толкования или применения настоящего Соглашения, который Стороны в споре не
 могут разрешить путем переговоров или другими средствами урегулирования, 
 передается в арбитраж по заявлению одной из Договаривающихся Сторон, между 
 которыми возник спор, и представляется на рассмотрение соответственно одному 
 или нескольким арбитрам, избираемым по общему согласию Сторон в споре. Если 
 в течение трех месяцев со дня представления просьбы о рассмотрении дела в 
 арбитраже Стороны в споре не придут к согласию относительно выбора арбитра 
 или арбитров, любая из этих Сторон может обратиться к Генеральному секретарю 
 Организации Объединенных Наций с просьбой о назначении единого арбитра, 
 которому спор передается на разрешение.</p><br>

<p style="text-indent:2em;">2. Решение арбитра или арбитров, назначенных в соответствии с положениями пункта 
1 настоящей статьи, имеет обязательную силу для Договаривающихся Сторон в споре.</p><br>

<p style="font-weight:500;">Статья 11</p><br>

<p>Оговорки</p><br>

<p style="text-indent:2em;">Любое государство может при подписании настоящего Соглашения или при сдаче на 
хранение документа о ратификации, принятии, утверждении или присоединении 
сделать заявление о том, что оно не считает себя связанным статьей 10 
настоящего Соглашения.</p><br>

<p style="font-weight:500;">Статья 12</p><br>

<p>Внесение поправок в Соглашение</p><br>

<p style="text-indent:2em;">1. В настоящее Соглашение могут вноситься поправки в соответствии с процедурой,
 предусмотренной в настоящей статье, за исключением случаев, предусмотренных
 в статьях 13 и 14.</p><br>

<p style="text-indent:2em;">2. По просьбе любой Договаривающейся Стороны любая предложенная ею поправка к
 настоящему Соглашению рассматривается Основной рабочей группой по внутреннему
 водному транспорту Европейской экономической комиссии Организации Объединенных
 Наций.</p><br>

<p style="text-indent:2em;">3. В случае одобрения предложенной поправки большинством в две трети присутствующих
 и участвующих в голосовании Договаривающихся Сторон Генеральный секретарь 
 Организации Объединенных Наций препровождает ее всем Договаривающимся Сторонам
 для ее принятия.</p><br>

<p style="text-indent:2em;">4. Любая предложенная поправка, препровождаемая в соответствии с пунктом 3 
настоящей статьи, вступает в силу в отношении всех Договаривающихся Сторон 
через три месяца с момента истечения периода продолжительностью двенадцать 
месяцев, который исчисляется с даты ее препровождения, если в течение этого 
двенадцатимесячного периода Генеральный секретарь Организации Объединенных Наций 
не будет уведомлен о возражении против предлагаемой поправки каким-либо 
государством, являющимся Договаривающейся Стороной.</p><br>

<p style="text-indent:2em;">5. Если против предлагаемой поправки было направлено уведомление о возражении 
в соответствии с пунктом 4 настоящей статьи, поправка считается непринятой и не 
имеет силы.</p><br>

<p style="font-weight:500;">Статья 13</p><br>

<p>Внесение поправок в Приложения I и II</p><br>

<p style="text-indent:2em;">1. В Приложения I и II к настоящему Соглашению могут вноситься поправки в соответствии
 с процедурой, изложенной в настоящей статье.</p><br>

<p style="text-indent:2em;">2. По просьбе любой Договаривающейся Стороны любая предложенная ею поправка
 к Приложениям I и II к настоящему Соглашению рассматривается Основной 
 рабочей группой по внутреннему водному транспорту Европейской экономической
 комиссии Организации Объединенных Наций.</p><br>

<p style="text-indent:2em;">3. В случае одобрения предложенной поправки большинством присутствующих и 
участвующих в голосовании Договаривающихся Сторон Генеральный секретарь Организации
 Объединенных Наций препровождает ее непосредственно заинтересованным 
 Договаривающимся Сторонам для ее принятия. Для целей настоящей статьи 
 Договаривающаяся Сторона считается непосредственно заинтересованной, если
 в случае включения нового внутреннего водного пути или порта международного
 значения или в случае их соответствующего изменения ее территория пересекается
 этим внутренним водным путем или если рассматриваемый порт расположен на указанной
 территории.</p><br>

<p style="text-indent:2em;">4. Любая предложенная поправка, препровождаемая в соответствии с пунктами 
2 и 3 настоящей статьи, считается принятой, если в течение шести месяцев с 
даты ее препровождения депозитарием ни одна из непосредственно заинтересованных 
Договаривающихся Сторон не уведомит Генерального секретаря Организации Объединенных 
Наций о том, что она возражает против предложенной поправки.</p><br>

<p style="text-indent:2em;">5. Любая принятая таким образом поправка препровождается Генеральным секретарем 
Организации Объединенных Наций всем Договаривающимся Сторонам и вступает в силу 
через три месяца со дня препровождения ее депозитарием.</p><br>

<p style="text-indent:2em;">6. Если против предлагаемой поправки было направлено уведомление о возражении в 
соответствии с пунктом 4 настоящей статьи, поправка считается не принятой и не 
имеет силы.</p><br>

<p style="text-indent:2em;">7. Секретариат Европейской экономической комиссии безотлагательно информирует 
депозитария о Договаривающихся Сторонах, которые непосредственно заинтересованы
 в предложенной поправке.</p><br>

<p style="font-weight:500;">Статья 14</p><br>

<p>Внесение поправок в Приложение III</p><br>

<p style="text-indent:2em;">1. В Приложение III к настоящему Соглашению могут вноситься поправки в
 соответствии с процедурой, предусмотренной в настоящей статье.</p><br>

<p style="text-indent:2em;">2. По просьбе любой Договаривающейся Стороны любая предложенная ею поправка
 к Приложению III к настоящему Соглашению рассматривается Основной рабочей группой
 по внутреннему водному транспорту Европейской экономической комиссии Организации
 Объединенных Наций.</p><br>

<p style="text-indent:2em;">3. В случае одобрения предложенной поправки большинством присутствующих и участвующих
 в голосовании Договаривающихся Сторон Генеральный секретарь Организации Объединенных
 Наций препровождает ее всем Договаривающимся Сторонам для ее принятия.</p><br>

<p style="text-indent:2em;">4. Любая предложенная поправка, препровождаемая в соответствии с пунктом 3 настоящей 
статьи, считается принятой, если в течение шести месяцев с даты ее препровождения одна
 пятая или более Договаривающихся Сторон не уведомит Генерального секретаря Организации
 Объединенных Наций о том, что они возражают против предложенной поправки.</p><br>

<p style="text-indent:2em;">5. Любая поправка, принятая в соответствии с пунктом 4 настоящей статьи, препровождается
 Генеральным секретарем Организации Объединенных Наций всем Договаривающимся Сторонам и
 вступает в силу через три месяца со дня ее препровождения в отношении всех Договаривающихся
 Сторон, за исключением тех, которые уже уведомили Генерального секретаря Организации 
 Объединенных Наций о своем возражении против предлагаемой поправки в течение шести месяцев
 со дня ее препровождения в соответствии с пунктом 4 настоящей статьи.</p><br>

<p style="text-indent:2em;">6. Если одна пятая или более Договаривающихся Сторон уведомит о возражении против
 предложенной поправки в соответствии с пунктом 4 настоящей статьи, поправка считается
 не принятой и не имеет силы.</p><br>

<p style="font-weight:500;">Статья 15</p><br>

<p>Денонсация</p><br>

<p style="text-indent:2em;">1. Любая Договаривающаяся Сторона может денонсировать настоящее Соглашение путем письменного
 уведомления, адресованного Генеральному секретарю Организации Объединенных Наций.</p><br>

<p style="text-indent:2em;">2. Денонсация вступает в силу по истечении одного года со дня получения Генеральным 
секретарем указанного уведомления.</p><br>

<p style="font-weight:500;">Статья 16</p><br>

<p>Прекращение действия</p><br>

<p style="text-indent:2em;">Если после вступления в силу настоящего Соглашения число Договаривающихся Сторон
 составит менее пяти в течение какого-либо периода последовательных двенадцати месяцев
 , Соглашение теряет силу по истечении двенадцати месяцев после даты, начиная с которой
 пятое государство перестало быть Договаривающейся Стороной.</p><br>

<p style="font-weight:500;">Статья 17</p><br>

<p>Уведомления и сообщения депозитария</p><br>

<p style="text-indent:2em;">Помимо таких уведомлений и сообщений, которые могут определяться в настоящем Соглашении
, функции Генерального секретаря Организации Объединенных Наций как депозитария определяются
 в части VII Венской конвенции о праве международных договоров, совершена 23 мая 1969 года.</p><br>

<p style="font-weight:500;">Статья 18</p><br>

<p>Аутентичные тексты</p><br>

<p style="text-indent:2em;">Подлинник настоящего Соглашения, тексты которого на английском, русском и французском
 языках являются равно аутентичными, сдается на хранение Генеральному секретарю 
 Организации Объединенных Наций.</p><br>

<p style="text-indent:2em;">В удостоверение чего нижеподписавшиеся, должным образом на то уполномоченные, 
подписали настоящее Соглашение.</p><br>

<p>Совершено в Женеве девятнадцатого января 1996 года.</p><br>

<p>(Подписи)</p>

<p></p><h4>Приложение I</h4><p></p>

<p style="font-weight:500;">ВНУТРЕННИЕ ВОДНЫЕ ПУТИ МЕЖДУНАРОДНОГО ЗНАЧЕНИЯ</p><br>

<p style="font-weight:500;">Нумерация внутренних водных путей международного значения</p><br>

<p style="text-indent:2em;">1. Все внутренние водные пути международного значения (водные пути категории E)
 имеют двух-, четырех- или шестизначные номера, которым предшествует буква "E".</p><br>

<p style="text-indent:2em;">2. Основные элементы сети водных путей категории E имеют двузначные номера, а их
 ответвления и второстепенные ответвления ("ответвления ответвлений") - четырех-
 и шестизначные номера, соответственно.</p><br>

<p style="text-indent:2em;">3. Магистральные внутренние водные пути, главным образом северо-южного направления
, обеспечивающие доступ к морским портам и соединяющие один морской бассейн с другим,
 имеют номера 10, 20, 30, 40 и 50, возрастающие с запада на восток.</p><br>

<p style="text-indent:2em;">4. Магистральные внутренние водные пути, главным образом западно-восточного направления,
 пересекающие три или более внутренних водных путей, указанных в пункте 3 выше, имеют 
 номера 60, 70, 80 и 90, возрастающие с севера на юг.</p><br>

<p style="text-indent:2em;">5. Другие основные внутренние водные пути имеют двузначные номера, заключенные между 
номерами двух магистральных внутренних водных путей, которые указаны в пунктах 3 и 4 
выше и между которыми они расположены.</p><br>

<p style="text-indent:2em;">6. В случае ответвлений (или ответвлений ответвлений) первые две (или четыре) цифры
 указывают на соответствующий более важный элемент сети водных путей, а две другие 
 представляют собой порядковые номера конкретных ответвлений, присваиваемые в 
 направлении от начала до конца более высокого элемента сети, как он описан в 
 таблице ниже. Четные номера используются для обозначения правосторонних ответвлений,
 а нечетные - левосторонних.</p>

<p></p><h4>Приложение II</h4><p></p>

<p style="font-weight:500;">ПОРТЫ ВНУТРЕННЕГО ПЛАВАНИЯ МЕЖДУНАРОДНОГО ЗНАЧЕНИЯ</p><br>

<p style="font-weight:500;">Нумерация портов внутреннего плавания международного значения</p><br>

<p style="text-indent:2em;">Номера всех портов внутреннего плавания международного значения (порты категории E),
 возрастающие с запада на восток и с севера на юг, состоят из номера водного пути, к 
 которому они относятся, за которым следует дефис и две цифры, соответствующие порядковому
 номеру порта конкретного водного пути, которому предшествует буква "P". Частные порты,
 принадлежащие конкретным предприятиям, обозначены звездочкой (*).</p><br>

<p style="font-weight:500;">ПЕРЕЧЕНЬ ПОРТОВ ВНУТРЕННЕГО ПЛАВАНИЯ МЕЖДУНАРОДНОГО ЗНАЧЕНИЯ</p><br>

<p>P 01-01 Дюнкерк (канал Дюнкерк-Валансьенн, 20,5 км)</p><br>

<p>P 01-02 Шарлеруа (Самбра, 38,8 км)</p><br>

<p>P 01-03 Намюр (Мез, 46,3 км)</p><br>

<p>P 01-04 Льеж (Мез, 113,7 км)</p><br>

<p>P 01-05 Маастрихт (Маас, 4,5 км)</p><br>

<p>P 01-06 Штейн (Маас, 21,9 км)</p><br>

<p>P 01-07 Борн (Маас, 29,7 км)</p><br>

<p>P 01-08 Маасбрахт (Маас, 41,8 км)</p><br>

<p>P 01-09 Рурмонд (Маас, 74,3 км)</p><br>

<p>P 01-10 Осе (Маас, 159,1 км)</p><br>

<p>P 01-11 Дордрехт (Мерведе, 974,4 км)</p><br>

<p>P 01-12 Звейндрехт (Ауде-Маас, 980,6 км)</p><br>

<p>P 01-13 Влаардинген (Ньиве Ватервег, 1010,5 км)</p><br>

<p>P 01-14 Маасшлюз (Ньиве Ватервег, 1018,7 км)</p><br>

<p>P 01-01-01 Оверпельт (канал Бохолт-Херенталс, 14,8 км)</p><br>

<p>P 01-03-01 Хертогенбош (Зюйд-Виллемсварт, 4,0 км)</p><br>

<p>P 02-01 Зебрюгге (Северное море)</p><br>

<p>P 02-02 Аальтер (канал Остенде-Брюгге-Гент, 22,5 км)</p><br>

<p>P 02-03 Лиль (Дель, 42,0 км)</p><br>

<p>P 02-02-01 Остенде (Северное море)</p><br>

<p>P 02-04-01 Руселаре (канал Лейе-Руселаре, 0,5 км)</p><br>

<p>P 02-04-02 Изегем (канал Лейе-Руселаре, 6,4 км)</p><br>

<p>P 03-01 Мурдейк (Холландс-Дип)</p><br>

<p>P 03-02 Тернезен (канал Тернезен-Гент, 32,5 км)</p><br>

<p>P 03-03 Зелзат (канал Тернезен-Гент, 19,6 км)</p><br>

<p>P 03-04 Гент (канал Тернезен-Гент, 4,6 км)</p><br>

<p>P 04-01 Флиссинген (Вестершельде)</p><br>

<p>P 04-02 Беверен (Бенеден Зеешельде, 22,9 км)</p><br>

<p>P 04-03 Реисбрук (канал Шарлеруа-Брюссель, 58,8 км)</p><br>

<p>P 04-04 Гримберген (канал Брюссель-Рюпель, 12,2 км)</p><br>

<p>P 04-05 Брюссель (канал Брюссель-Рюпель 62,0 км)</p><br>

<p>P 05-01 Авельгем (Бовеншельде, 35,7 км)</p><br>

<p>P 05-02 Мелле (Бовен-Зеешельде, 9,9 км)</p><br>

<p>P 05-03 Мерхаут (Альберт-канал, 80,7 км)</p><br>

<p>P 05-04 Хам (Альберт-канал, 73,7 км)</p><br>

<p>P 05-05 Хассельт (Альберт-канал, 51,5 км)</p><br>

<p>P 05-06 Генк (Альберт-канал, 42,9 км)</p><br>

<p>P 05-04-01 Альст (Дендер, 53,7 км)</p><br>

<p>P 06-01 Антверпен (Шельда, 102,9 км)</p><br>

<p>P 06-02 Берген-оп-Зум (Соединение Шельда-Рейн, 1031,
8 км)</p><br>

<p>P 10-01 Роттердам (Ньиве Маас, 1002,5 км)</p><br>

<p>P 10-02 Альбассердам (Норд, 981,1 км)</p><br>

<p>P 10-03 Тил (Ваал, 914,6 км)</p><br>

<p>P 10-04 Эммерих (Рейн, 852,0 км)</p><br>

<p>P 10-05 Везель (Рейн, 814,0 км)</p><br>

<p>P 10-06 Рейнберг-Оссенберг (*) (Рейн, 806,0 км)</p><br>

<p>P 10-07 Орсой (Рейн, 794,0 км)</p><br>

<p>P 10-08 Вальсум-Нордхафен (*) (Рейн, 793,0 км)</p><br>

<p>P 10-09 Вальсум-Зюд (*) (Рейн, 791,0 км)</p><br>

<p>P 10-10 Швелгерн (*) (Рейн, 790,0 км)</p><br>

<p>P 10-11 Хомберг, Захтлебен (*) (Рейн, 774,0 км)</p><br>

<p>P 10-12 Дуйсбург-Рурорт Хафен (Рейн, 774,0 км)</p><br>

<p>P 10-13 Крефельд (Рейн, 762,0 км)</p><br>

<p>P 10-14 Дюссельдорф (Рейн, 743,0 км)</p><br>

<p>P 10-15 Нойс (Рейн, 740,0 км)</p><br>

<p>P 10-16 Штюрцельберг (*) (Рейн, 726,0 км)</p><br>

<p>P 10-17 Леверкузен (*) (Рейн, 699,0 км)</p><br>

<p>P 10-18 Кельн (Рейн, 688,0 км)</p><br>

<p>P 10-19 Весселинг-Годорф (*) (Рейн, 672,0 км)</p><br>

<p>P 10-20 Бонн (Рейн, 658,0 км)</p><br>

<p>P 10-21 Андернах (Рейн, 612,0 км)</p><br>

<p>P 10-22 Нойвид (Рейн, 606,0 км)</p><br>

<p>P 10-23 Бендорф (Рейн, 599,0 км)</p><br>

<p>P 10-24 Кобленц (Рейн, 596,0 км)</p><br>

<p>P 10-25 Бинген (Рейн, 527,0 км)</p><br>

<p>P 10-26 Висбаден (Рейн, 500,0 км)</p><br>

<p>P 10-27 Гернсгейм (Рейн, 462,0 км)</p><br>

<p>P 10-28 Вормс (Рейн, 444,0 км)</p><br>

<p>P 10-29 Мангейм (Рейн, 424,0 км)</p><br>

<p>P 10-30 Людвигсхафен (Рейн, 420,0 км)</p><br>

<p>P 10-31 Шпейер (Рейн, 400,0 км)</p><br>

<p>P 10-32 Гермерсгейм (Рейн, 385,0 км)</p><br>

<p>P 10-33 Верт (Рейн, 366,0 км)</p><br>

<p>P 10-34 Карлсруэ (Рейн, 360,0 км)</p><br>

<p>P 10-35 Кель (Рейн, 297,0 км)</p><br>

<p>P 10-36 Страсбург (Рейн, 296,0 км)</p><br>

<p>P 10-37 Брейзах (Рейн, 226,0 км)</p><br>

<p>P 10-38 Кольмар-Неф Бризах (Рейн, 225,8 км)</p><br>

<p>P 10-39 Мюлуз-Оттмарсгейм (Большой эльзасский
канал, 21,0 км)</p><br>

<p>P 10-40 Форт Луи Штатматтен (Большой эльзасский
канал, 322,0 км)</p><br>

<p>P 10-41 Иль Наполеон (канал Рона-Рейн, 37,6 км)</p><br>

<p>P 10-42 Мюлуз (канал Рона-Рейн, 31,0 км)</p><br>

<p>P 10-43 Апропорт (Шалон, Макон, Вильфранш-сюр-Сон)</p><br>

<p>(Сона, 230,0 км, 296,0 км и 335,0 км,
соответственно)</p><br>

<p>P 10-44 Лион (Сона, 375,0 км)</p><br>

<p>P 10-45 Марсель-Фос (канал Марсель-Рона, 0,0 км)</p><br>

<p>P 10-01-01 Рейн-Липп-Хафе (*) (канал Везель-Даттельн,
1,0 км)</p><br>

<p>P 10-01-02 Марль Хюльс-АГ (*) (канал Везель-Даттельн,
38,0 км)</p><br>

<p>P 10-01-03 Август Виктория (*) (канал Везель-Даттельн,
39,0 км)</p><br>

<p>P 10-01-04 Люнен (канал Даттельн-Хамм, 11,0 км)</p><br>

<p>P 10-01-05 Беркамен (*) (канал Даттельн-Хамм, 22,0 км)</p><br>

<p>P 10-01-06 Хамм (канал Даттельн-Хамм, 34,0 км)</p><br>

<p>P 10-01-07 Шмехаузен (*) (канал Даттельн-Хамм, 47,0 км)</p><br>

<p>P 10-03-01 Эссен (канал Рейн - Херне, 16,0 км)</p><br>

<p>P 10-03-02 Коэлн-Нойссен (*) (канал Рейн-Херне, 17,0 км)</p><br>

<p>P 10-03-03 Рур-Оел (*) (канал Рейн-Херне, 22,0 км)</p><br>

<p>P 10-03-04 Гелсенкирхен (канал Рейн-Херне, 24,0 км)</p><br>

<p>P 10-03-05 Ванн-Эйкель (канал Рейн-Херне, 32,0 км)</p><br>

<p>P 10-05-01 Мюльхейм (Рур, 8,0 км)</p><br>

<p>P 10-07-01 Хайлброн (Неккар, 110,0 км)</p><br>

<p>P 10-07-02 Штутгарт (Неккар, 186,0 км)</p><br>

<p>P 10-07-03 Плохинген (Неккар, 200,0 км)</p><br>

<p>P 10-09-01 Хунинг (Рейн, 168,4 км)</p><br>

<p>P 10-09-02 Райнхэфен-байдар-Базель (Рейн, 159,38 - 169,
95 км)</p><br>

<p>P 10-04-01 Сет (канал Рона-Сет, 96,0 км)</p><br>

<p>P 10-06-01 Фос (залив Фос, морской участок)</p><br>

<p>P 11-01 Эймонд (Нордзее канал, 4,7 км)</p><br>

<p>P 11-02 Заанштад (Заан, 1,4 км)</p><br>

<p>P 11-03 Амстердам (Нордзее канал, 20,6 км)</p><br>

<p>P 11-04 Утрехт (Амстердам-Рейн канал, 35,0 км)</p><br>

<p>P 11-01-01 Заандам (Заам, 2,0 км)</p><br>

<p>P 12-01 Неймеген (Ваал, 884,6 км)</p><br>

<p>P 12-02 Арнем (Недеррейн, 885,8 км)</p><br>

<p>P 12-03 Зволле (Эйссел, 980,7 км)</p><br>

<p>P 12-02-01 Меппель (Меппелердип, 10,5 км)</p><br>

<p>P 13-01 Эмсланд (*) (канал Дортмунд-Эмс, 151,0 км)</p><br>

<p>P 13-02 Мюнстер (канал Дортмунд-Эмс, 68,0 км)</p><br>

<p>P 13-03 Дортмунд (канал Дортмунд-Эмс, 1,0 км)</p><br>

<p>P 14-01 Бремерхафен (Везер, 66,0 - 68,0 км)</p><br>

<p>P 14-02 Норденхам (Везер, 54,0 - 64,0 км)</p><br>

<p>P 14-03 Брак (Везер, 41,0 км)</p><br>

<p>P 14-04 Бремен (Везер, 4,0 - 8,0 км)</p><br>

<p>P 15-01 Лелиштад (Эйсселмер)</p><br>

<p>P 15-02 Леммер (Принсес Маргарит канал, 90,5 км)</p><br>

<p>P 15-03 Гронинген (Старкенборг канал, 7,0 км)</p><br>

<p>P 15-04 Эмден (Эмс, 41,0 км)</p><br>

<p>P 15-05 Леер (Эмс, 14,0 км)</p><br>

<p>P 15-06 Олденбург (*) (Хунте, 0,0 - 5,0 км)</p><br>

<p>P 15-01-01 Лейварден (Харинксма - канал, 23,7 км)</p><br>

<p>P 20-01 Куксхавен (Эльба, 724,0 км) &lt;1&gt;</p><br>

<p>P 20-02 Брюнсбюттель (Эльбехафен, 693,0) &lt;1&gt;</p><br>

<p>P 20-03 Бютцфлет (*) (Эльба, 668,0 км) &lt;1&gt;</p><br>

<p>P 20-04 Гамбург (Эльба, 618,0 - 639,0 км) &lt;1&gt;</p><br>

<p>P 20-05 Лауэнбург (Эльба, 568,0 км) &lt;1&gt;</p><br>

<p>P 20-06 Тангермюнде (Эльба, 388,0 км) &lt;1&gt;</p><br>

<p>P 20-07 Кисверк Рогэтц (*) (Эльба, 354,0 км) &lt;1&gt;</p><br>

<p>P 20-08 Магдебюргер Хэфен (Эльба, 330,0 и 333,0 км) &lt;1&gt;</p><br>

<p>P 20-09 Шенебек (Эльба, 315,0 км) &lt;1&gt;</p><br>

<p>P 20-10 Акен (Эльба, 277,0 км) &lt;1&gt;</p><br>

<p>P 20-11 Торгау (Эльба, 154,0 км) &lt;1&gt;</p><br>

<p>P 20-12 Кисверк Мюльберг (*) (Эльба, 125,0 км) &lt;1&gt;</p><br>

<p>P 20-13 Риза (Эльба, 109,0 км) &lt;1&gt;</p><br>

<p>P 20-14 Дрезден (Эльба, 57 и 61 км) &lt;1&gt;</p><br>

<p>P 20-15 Дечин (Эльба, 98,2 и 94,2 км) &lt;1&gt;</p><br>

<p>P 20-16 Усти-над-Лабем (Эльба, 75,3 и 72,5 км) &lt;1&gt;</p><br>

<p>P 20-17 Мельник (Эльба, 3,0 км) &lt;1&gt;</p><br>

<p>P 20-04-01 Халле-Трота (Заале, 86,0 км) &lt;1&gt;</p><br>

<p>P 20-06-01 Прага (Влтава, 46,5 и 55,5 км) &lt;1&gt;</p><br>

<p>P 21-01 Любек (Траве, 2,0 - 8,0 км)</p><br>

<p>P 30-01 Свиноуйсьце (Балтийское море - устье реки Одер)</p><br>

<p>P 30-02 Щецин (Одер, 741,0 км)</p><br>

<p>P 30-03 Костшин (Одер, 617,0 км)</p><br>

<p>P 30-04 Вроцлав (Одер, 255,0 км)</p><br>

<p>P 30-05 Козле (Одер, 96,0 км)</p><br>

<p>P 30-01-01 Гливице (Гливицкий канал, 41,0 км)</p><br>

<p>P 40-01 Гданьск (Балтийское море - устье реки Висла)</p><br>

<p>P 40-02 Быдгощ (Висла, 772,3 км, река Брда, 2,0 км)</p><br>

<p>P 40-03 Варшава (Висла, 520,0 и канал Зеран, 2,0 км)</p><br>

<p>P 40-04 Чернигов (Днепр, 1070,0 км)</p><br>

<p>P 40-05 Киев (Днепр, 856,0 км)</p><br>

<p>P 40-06 Черкассы (Днепр, 653,0 км)</p><br>

<p>P 40-07 Кременчуг (Днепр, 541,0 км)</p><br>

<p>P 40-08 Днепродзержинск (Днепр, 429,0 км)</p><br>

<p>P 40-09 Днепропетровск (Днепр, 393,0 км)</p><br>

<p>P 40-10 Запорожье (Днепр, 308,0 км)</p><br>

<p>P 40-11 Новая Каховка (Днепр, 96,0 км)</p><br>

<p>P 40-12 Херсон (Днепр, 28,0 км)</p><br>

<p>P 40-02-01 Николаев (Южный Буг, 95,0 км)</p><br>

<p>P 41-01 Клайпеда, речной порт (Куршский залив)</p><br>

<p>P 41-02 Неринга (Куршский залив)</p><br>

<p>P 41-03 Юрбаркас (Неман, 126,0 км)</p><br>

<p>P 41-04 Каунас (Неман, 219,0 км)</p><br>

<p>P 50-01 Санкт-Петербург, морской порт (река Нева,
1397,0 км) &lt;2&gt;</p><br>

<p>P 50-02 Санкт-Петербург, речной порт (река Нева,
1385,0 км) &lt;2&gt;</p><br>

<p>P 50-03 Подпорожье (Волго-Балтийский водный путь,
1045,0 км) &lt;2&gt;</p><br>

<p>P 50-04 Череповец (Волго-Балтийский водный путь,
540,0 км) &lt;2&gt;</p><br>

<p>P 50-05 Ярославль (Волга, 520,0 км) &lt;2&gt;</p><br>

<p>P 50-06 Нижний Новгород (Волга, 907,0 км) &lt;2&gt;</p><br>

<p>P 50-07 Казань (Волга, 1313 км) &lt;2&gt;</p><br>

<p>P 50-08 Ульяновск (Волга, 1541,0 км) &lt;2&gt;</p><br>

<p>P 50-09 Самара (Волга, 1746,0 км) &lt;2&gt;</p><br>

<p>P 50-10 Саратов (Волга, 2175,0 км) &lt;2&gt;</p><br>

<p>P 50-11 Волгоград (Волга, 2560,0 км) &lt;2&gt;</p><br>

<p>P 50-12 Астрахань (Волга, 3051,0 км) &lt;2&gt;</p><br>

<p>P 50-02-01 Москва, Северный порт (канал им. Москвы,
42,0 км) &lt;2&gt;</p><br>

<p>P 50-02-02 Москва, Западный порт (канал им. Москвы,
32,0 км) &lt;2&gt;</p><br>

<p>P 50-02-03 Москва, Южный порт (канал им. Москвы,
0,0 км) &lt;2&gt;</p><br>

<p>P 50-02-02-01 Тверь (Волга, 279,0 км) &lt;2&gt;</p><br>

<p>P 50-01-01 Пермь (Кама, 2269,0 км) &lt;2&gt;</p><br>

<p>P 60-01 Шевенинген (Северное море)</p><br>

<p>P 60-02 Ден Хельдер (Северное море)</p><br>

<p>P 60-03 Брюнсбюттель (Кильский канал, 2,0 - 5,0 км)</p><br>

<p>P 60-04 Рендсбург (Кильский канал, 62,0 км)</p><br>

<p>P 60-05 Киль (Кильский канал, 96,0 км)</p><br>

<p>P 60-06 Фленсбург</p><br>

<p>P 60-07 Висмар</p><br>

<p>P 60-08 Росток</p><br>

<p>P 60-09 Штральзунд</p><br>

<p>P 60-10 Грифсвальд</p><br>

<p>P 60-11 Швентойи (Балтийское море)</p><br>

<p>P 60-12 Выборг (Выборгский залив)</p><br>

<p>P 60-13 Петрозаводск (Онежское озеро, 1009,0 км) &lt;2&gt;</p><br>

<p>P 60-14 Архангельский морской порт (Устье Северной
Двины)</p><br>

<p>P 60-15 Архангельский речной порт (Устье Северной Двины)</p><br>

<p>P 60-02-01 Севилья (Гвадалкивир, 80,0 км)</p><br>

<p>P 60-04-01 Дору (Дору, 5,0 км)</p><br>

<p>P 60-04-02 Сардору (Дору, 49,0 км)</p><br>

<p>P 60-04-03 Регуа-Ламего (Дору, 101,0 км)</p><br>

<p>P 60-06-01 Бордо (Жиронда и Гаронна, 359,0 км)</p><br>

<p>P 60-08-01 Нант (Луара, 645,0 км)</p><br>

<p>P 60-10-01 Харлинген (Ваддензе)</p><br>

<p>P 60-12-01 Делфзейл (Ваддензе)</p><br>

<p>P 60-11-01 Мустола (39,0 км от устья Сайменского канала)</p><br>

<p>P 60-11-02 Каукас (*) (52,0 км от устья Сайменского канала)</p><br>

<p>P 60-11-03 Рапасаари (*) (52,0 км от устья Сайменского
канала)</p><br>

<p>P 60-11-04 Ютсено (*) (67,0 км от устья Сайменского канала)</p><br>

<p>P 60-11-05 Вуокси (*) (85,0 км от устья Сайменского канала)</p><br>

<p>P 60-11-06 Варкаус (порт Тайпале, 270,0 км от устья
Сайменского канала)</p><br>

<p>P 60-11-07 Варкаус (порт Коссуланниеми (*), 270,0 км от
устья Сайменского канала)</p><br>

<p>P 60-11-08 Варкаус (порт Аконниеми, 270,0 км от устья
Сайменского канала)</p><br>

<p>P 60-11-09 Куопио (352,0 км от устья Сайменского канала)</p><br>

<p>P 60-11-02-01 Пухос (*) (311,0 км от устья Сайменского канала)</p><br>

<p>P 60-11-02-02 Йонсу (346,0 км от устья Сайменского канала)</p><br>

<p>P 61-01 Анклам (Пене, 95,0 км)</p><br>

<p>P 70-01 Вагенинген (Недеррейн, 903,2 км)</p><br>

<p>P 70-02 Энхеде (Твентеканал, 49,8 км)</p><br>

<p>P 70-03 Иббенбюрен (Среднегерманский канал, 5,0 км)</p><br>

<p>P 70-04 Минден (Среднегерманский канал, 100,0 -
104,0 км)</p><br>

<p>P 70-05 Ганновер (Среднегерманский канал, 155,0 -
159,0 км)</p><br>

<p>P 70-06 Мерум (*) (Среднегерманский канал, 194,0 км)</p><br>

<p>P 70-07 Брауншвейг (Среднегерманский канал, 220,0 км)</p><br>

<p>P 70-08 Брауншвейг / Тун (*) (Среднегерманский канал,
223,0 км)</p><br>

<p>P 70-09 Халденслебен (Среднегерманский канал, 301,0 км)</p><br>

<p>P 70-10 Нигрипп (*) (Канал Эльба-Хафель, 330,0 км)</p><br>

<p>P 70-11 Бранденбург (*) (водный путь Унтер Хафель,
60,0 км)</p><br>

<p>P 70-12 Бранденбург (водный Унтер Хафель, 57,0 км)</p><br>

<p>P 70-13 Депони Дитц (*) (водный путь Унтер Хафель,
40,0 км)</p><br>

<p>P 70-14 Шпандау, Южная гавань (водный путь Унтер Хафель,
2,0 км)</p><br>

<p>P 70-15 Эльблаг (Вислинский залив)</p><br>

<p>P 70-16 Калининградский морской порт (Прегола, 8,0 км)</p><br>

<p>P 70-17 Калининградский речной порт (Прегола, 9,0 км)</p><br>

<p>P 70-01-01 Гауда (Холландс Эйссел, 1,4 км)</p><br>

<p>P 70-03-01 Хенгело (Твентеканал, 45,1 км)</p><br>

<p>P 70-03-02 Алмело (Зейканал, 17,6 км)</p><br>

<p>P 70-02-01 Оснабрюкк (Штихканал, 13,0)</p><br>

<p>P 70-04-01 Ганновер-Линден (Штихканал, 11,0 км)</p><br>

<p>P 70-06-01 Хильдесгейм (Штихканал, 15,0 км)</p><br>

<p>P 70-08-01 Зальцгиттер (Штихканал, 15,0 км)</p><br>

<p>P 70-10-01 Комплекс для обработки грузов (*) (рукав Шпрее,
0,0 км)</p><br>

<p>P 70-10-02 Ноннендам (Шпрее, 2,0 км)</p><br>

<p>P 70-10-03 Электростанция Ройтер (*) (Шпрее, 3,0 км)</p><br>

<p>P 70-10-04 Электростанция Шарлоттенбург (*) (Шпрее, 8,0 км)</p><br>

<p>P 70-10-05 Вестхафен Берлин (Вестхафенканал, 3,0 км)</p><br>

<p>P 70-10-06 Остхафен Берлин (Шпрее, 21,0 км)</p><br>

<p>P 70-10-07 Теплоэлектростанция Клингенберг (Шпрее, 25,0 км)</p><br>

<p>P 70-12-01 Электростанция Моабит (*) (Берлин-Шпандау -
Шиффартсканал, 9,0 км)</p><br>

<p>P 71-01 Погрузо-разгрузочный пункт на Тельтовканале (*)
(Тельтовканал, 31,0 - 34,0 км)</p><br>

<p>P 71-02 Погрузо-разгрузочный пункт Обершеневейде (водный
путь Одер-Шпрее, 28,0 - 29,0 км)</p><br>

<p>P 71-03 Эйзенхюттенштадт Эко (*) (водный путь Одер -
Шпрее, 122,0 км)</p><br>

<p>P 71-04 Эйзенхюттенштадт (водный путь Одер-Шпрее,
124,0 км)</p><br>

<p>P 71-02-01 Потсдам (Потсдаме Хафель, 3,0 км)</p><br>

<p>P 71-06-01 Нидерлем (*) (водный путь Даме, 8,0 км)</p><br>

<p>P 71-06-02 Кенигс Вустерхаузен (водный путь Даме, 8,0 км)</p><br>

<p>P 80-01 Гавр (канал Гавр-Танкарвиль, 20,0 км)</p><br>

<p>P 80-02 Руан (Сена, 242,0 км)</p><br>

<p>P 80-03 Конфлан (Сена, 239,0 км)</p><br>

<p>P 80-04 Фруар (Мозель, 346,5 км)</p><br>

<p>P 80-05 Мец (Мозель, 297,0 - 294,0 км)</p><br>

<p>P 80-06 Мондланж-Ришмон (Мозель, 279,5 - 277,9 км)</p><br>

<p>P 80-07 Тионвиль-Илланж (Мозель, 271,9 - 270,1 км)</p><br>

<p>P 80-08 Мертерт (Мозель, 208,0 км)</p><br>

<p>P 80-09 Трир (Мозель, 184,0 км)</p><br>

<p>P 80-10 Бинген (Рейн, 527,0 км)</p><br>

<p>P 80-11 Висбаден (Рейн, 500,0 км)</p><br>

<p>P 80-12 Майнц (Рейн, 500,0 км)</p><br>

<p>P 80-13 Флерсхейм (*) (Майн, 9,0 км)</p><br>

<p>P 80-14 Раунхейм (*) (Майн, 14,0 км)</p><br>

<p>P 80-15 Хаттерсхейм (*) (Майн, 17,0 км)</p><br>

<p>P 80-16 Келстербах (*) (Майн, 19,0 км)</p><br>

<p>P 80-17 Франкфурт (*) (Майн, 22,0 - 29,0 км)</p><br>

<p>P 80-18 Франкфурт (Майн, 31,0 - 37,0 км)</p><br>

<p>P 80-19 Оффенбах (Майн, 40,0 км)</p><br>

<p>P 80-20 Ханау (Майн, 56,0 - 60,0 км)</p><br>

<p>P 80-21 Гроскротценбург (*) (Майн, 62,0 км)</p><br>

<p>P 80-22 Штокштадт (Майн, 82,0 км)</p><br>

<p>P 80-23 Ашаффенбург (Майн, 83,0 км)</p><br>

<p>P 80-24 Трифенштейн (*) (Майн, 173,0 км)</p><br>

<p>P 80-25 Карлштадт (*) (Майн, 227,0 км)</p><br>

<p>P 80-26 Вюрцбург (Майн, 246,0 - 251,0 км)</p><br>

<p>P 80-27 Швайнфурт (Майн, 330,0 км)</p><br>

<p>P 80-28 Бамберг (канал Майн-Дунай, 3,0 км)</p><br>

<p>P 80-29 Эрланген (канал Майн-Дунай, 46,0 км)</p><br>

<p>P 80-30 Нюрнберг (канал Майн-Дунай, 72,0 км)</p><br>

<p>P 80-31 Регенсбург (Дунай, 2370,0 - 2378,0 км)</p><br>

<p>P 80-32 Деггендорф (*) (Дунай, 2281,0 - 2284,0 км)</p><br>

<p>P 80-33 Линц (Дунай, 2128,2 - 2130,6 км)</p><br>

<p>P 80-34 Линц-Фест (*) (Дунай, 2127,2 км)</p><br>

<p>P 80-35 Энс-Энсдорф (Дунай, 2111,8 км)</p><br>

<p>P 80-36 Креме (Дунай, 2001,5 км)</p><br>

<p>P 80-37 Вена (Дунай, 1916,8 - 1920,2 км)</p><br>

<p>P 80-38 Братислава (Дунай, 1867,0 км)</p><br>

<p>P 80-39 Дьер-Геню (Дунай, 1807,0 км)</p><br>

<p>P 80-40 Комарно (Дунай, 1767,1 км)</p><br>

<p>P 80-41 Штурово (Дунай, 1722,0 км)</p><br>

<p>P 80-42 Будапешт (Дунай, 1640,0 км)</p><br>

<p>P 80-43 Сазалонбата (Дунай, 1618,7 км)</p><br>

<p>P 80-44 Дунайварош (Дунай, 1579,0 км)</p><br>

<p>P 80-45 Дунафельдвар (Дунай, 1563,0 км)</p><br>

<p>P 80-46 Байя (Дунай, 1480,0 км)</p><br>

<p>P 80-47 Вуковар (Дунай, 1333,1 км)</p><br>

<p>P 80-48 Белград (Дунай, 1170,0 км)</p><br>

<p>P 80-49 Смедерево (Дунай, 1116,3 км)</p><br>

<p>P 80-50 Оршова (Дунай, 954,0 км)</p><br>

<p>P 80-51 Турну-Северин (Дунай, 931,0 км)</p><br>

<p>P 80-52 Прахово (Дунай, 861,0 км)</p><br>

<p>P 80-53 Лом (Дунай, 743,0 км)</p><br>

<p>P 80-54 Турну-Магуреле (Дунай, 597,0 км)</p><br>

<p>P 80-55 Свиштов (Дунай, 554,0 км)</p><br>

<p>P 80-56 Русе (Дунай, 495,0 км)</p><br>

<p>P 80-57 Джурджу (Дунай, 493,0 км)</p><br>

<p>P 80-58 Олтеница (Дунай, 430,0 км)</p><br>

<p>P 80-59 Кэлэраши (Дунай, 370,5 км)</p><br>

<p>P 80-60 Брэила (Дунай, 172,0 - 168,5 км)</p><br>

<p>P 80-61 Галац (Дунай, 157,0 - 145,4 км)</p><br>

<p>P 80-62 Джурджулешть (Дунай, 133,0 км) &lt;3&gt;</p><br>

<p>P 80-63 Рени (Дунай, 128,0 км)</p><br>

<p>P 80-64 Тулча (Дунай, 73,5 - 70,0 км)</p>

<p style="text-indent:2em;"></p><h6>P 80-04-01 Париж, автономный порт:</h6><p></p>

<p>Женвилье (Сена, 194,7 км)</p><br>

<p>Боной-Вине (Сена, 169,7 км)</p><br>

<p>Эври (Сена, 137,8 км)</p><br>

<p>Мелэн (Сена, 110,0 км)</p><br>

<p>Лиме-Поршвиль (Сена, 109,0 км)</p><br>

<p>Монтро (Сена, 67,4 км)</p><br>

<p>Нантер (Сена, 39,4 км)</p><br>

<p>Брюйер-сюр-Уаз (Уаза, 96,9 км)</p><br>

<p>Сент-Уан-л'Омон (Уаза, 119,2 км)</p><br>

<p>Лани (Марна, 149,8 км)</p><br>

<p>P 80-06-01 Дилинген (Саар, 59,0 км)</p><br>

<p>P 80-08-01 Осиек (Драва, 14,0 км)</p><br>

<p>P 80-01-01 Сегед (Тиса, 170,0 км)</p><br>

<p>P 80-14-01 Чернавода (канал Дунай-Черное море, 0,00 км)</p><br>

<p>P 80-14-02 Медгидия (канал Дунай-Черное море, 27,5 км)</p><br>

<p>P 80-14-03 Констанца (канал Дунай-Черное море, 64,0 км)</p><br>

<p>P 80-09-01 Измаил (Дунай-Килийское гирло, 93,0 км)</p><br>

<p>P 80-09-02 Килия (Дунай-Килийское гирло, 47,0 км)</p><br>

<p>P 80-09-03 Усть-Дунайск (Дунай-Килийское гирло, 1,0 км)</p><br>

<p>P 90-01 Таганрог (Таганрогский залив)</p><br>

<p>P 90-02 Ейск (Таганрогский залив)</p><br>

<p>P 90-03 Азов (Дон, 3168,0 км) &lt;2&gt;</p><br>

<p>P 90-04 Ростов (Дон, 3134,0 км) &lt;2&gt;</p><br>

<p>P 90-05 Усть-Донецк (Дон, 2997,0 км) &lt;2&gt;</p><br>

<p>P 90-03-01 Белгород-Днестровский (устье реки Днестр)</p><br>

<p>P 90-03-02 Бендеры (Днестр, 228,0 км)</p><br>

<p>P 91-01 Милане-Терминале (канал Милан - По, 0,0 км) &lt;4&gt;</p><br>

<p>P 91-02 Лоди (канал Милан-По, 20,0 км от Милано -
Терминале) &lt;4&gt;</p><br>

<p>P 91-03 Пиццигетоне (канал Милан - По, 40,0 км от
Милано-Терминале)</p><br>

<p>P 91-04 Кремона (По, 55,0 км от Милано-Терминале)</p><br>

<p>P 91-05 Эмилия-Чентрале (По, 145,0 км от Милано -
Терминале) &lt;4&gt;</p><br>

<p>P 91-06 Феррара (По, 200,0 км от Милано-Терминале)</p><br>

<p>P 91-07 Адрия (обводной канал Венета, 265,0 км от
Милано-Терминале)</p><br>

<p>P 91-08 Кьоджа (обводной канал Венета, 285,0 км от
Милано-Терминале)</p><br>

<p>P 91-09 Маргера (обводной канал Венета, 300,0 км от
Милано-Терминале)</p><br>

<p>P 91-10 Ногаро (обводной канал Венета, 355,0 км от
Милано-Терминале)</p><br>

<p>P 91-11 Монфальконе (обводной канал Венета, 410,0 км от
Милано-Терминале)</p><br>

<p>P 91-12 Триест (Адриатическое море)</p><br>

<p>P 91-02-01 Пьяченза (По, 35,0 км от Конка-ди-Кремоны)</p><br>

<p>P 91-02-02 Павия (Тичино, 98,0 км от Конка-ди-Кремоны)</p><br>

<p>P 91-02-03 Касале Монферрато (По, 183,0 км от
Конка-ди-Кремоны)</p><br>

<p>P 91-04-01 Гарибальди (водный путь Феррара, 80,0 км от
Феррары)</p><br>

<p>P 91-06-01 Порто-Толле (По-Гранде, 260,0 км от
Милано-Терминале)</p><br>

<p>P 91-01-01 Мантуя (водный путь Фиссеро-Тартаро-Канал -
Бьянко, 0,0 км)</p><br>

<p>P 91-01-02 Остилья (водный путь Фиссеро-Тартаро-Канал -
Бьянко, 30,0 км) &lt;4&gt;</p><br>

<p>P 91-01-03 Леньяго (водный путь Фиссеро-Тартаро-Канал -
Бьянко, 65,0 км) &lt;4&gt;</p><br>

<p>P 91-01-04 Ровиго (водный путь Фиссеро-Тартаро-Канал -
Бьянко, 140, 0 км) &lt;4&gt;</p><br>

<p>P 91-01-05 Конка-ди-Вольта-Гримана (водный путь
Фиссеро-Тартаро-Канал-Бьянко, 170,0 км)</p><br>

<p style="text-indent:2em;">1 Расстояния до портов на реке Эльбе измеряются: в Германии
 - от чешско-немецкой государственной границы; в Чешской
 Республике - от слияния рек Эльбы и Влтавы в Мельнике.</p><br>

<p>2 Расстояние от Московского южного порта.</p><br>

<p>3 Планируется сооружение.</p><br>

<p>4 В стадии строительства или планирования.</p>

<p></p><h4>Приложение III</h4><p></p>

<p style="font-weight:500;">ТЕХНИЧЕСКИЕ И ЭКСПЛУАТАЦИОННЫЕ ХАРАКТЕРИСТИКИ ВНУТРЕННИХ
 ВОДНЫХ ПУТЕЙ МЕЖДУНАРОДНОГО ЗНАЧЕНИЯ</p><br>

<p>a) Технические характеристики водных путей категории E</p><br>

<p style="text-indent:2em;">Основные технические характеристики водных путей категории
 E в основном соответствуют классификации европейских водных
 путей, приведенной в таблице 1.</p>

<p style="text-indent:2em;"></p><h6>Для оценки различных водных путей категории E используются 
параметры классов IV - VII с учетом следующих принципов:</h6><p></p>

<p style="text-indent:2em;">i) класс водного пути определяется горизонтальными габаритами 
самоходных судов, барж и толкаемых составов и в первую очередь 
основным стандартным габаритом, каковым является их ширина или 
поперечный габарит;</p><br>

<p style="text-indent:2em;">ii) только водные пути, соответствующие по крайней мере основным 
параметрам класса IV (минимальные габариты судов 85 м x 9,5 м), могут 
рассматриваться в качестве водных путей категории Е. Ограничения осадки 
(менее 2,50 м) и минимальной высоты прохода под мостами (менее 5,25 м) 
допускаются в порядке исключения только в отношении существующих 
водных путей;</p><br>

<p style="text-indent:2em;">iii) при модернизации водных путей класса IV (а также малых региональных 
водных путей) рекомендуется соблюдать параметры по крайней мере класса Va;</p><br>

<p style="text-indent:2em;">iv) однако новые водные пути категории E должны соответствовать 
параметрам как минимум класса Vb. В этой связи следует обеспечивать 
прохождение судов с осадкой не менее 2,80 м;</p><br>

<p style="text-indent:2em;">v) при модернизации существующих и / или создании новых водных путей 
следует всегда руководствоваться более высокими значениями габаритов 
судов или составов;</p><br>

<p style="text-indent:2em;">vi) для повышения эффективности контейнерных перевозок следует обеспечивать 
максимально возможную высоту прохода под мостами в соответствии 
со сноской 4 к таблице 1 &lt;*&gt;;</p><br>

<p style="text-indent:2em;">* Однако если доля порожних контейнеров превышает 50%, следует рассмотреть 
вопрос об обеспечении величины минимальной высоты прохода под мостами, 
превышающей величину, указанную в сноске 4.</p><br>

<p style="text-indent:2em;">vii) внутренние водные пути, на которых, как ожидается, будут осуществляться 
интенсивные контейнерные и ролкерные перевозки, должны соответствовать 
как минимум параметрам класса Vb. В целях удовлетворения потребностей, 
обусловленных будущим изменением габаритов контейнеров и необходимостью 
беспрепятственной перевозки прицепов, может быть предусмотрено также 
увеличение на 7 - 10% ширины соответствующих судов, составляющей 11,4 м, 
осуществляющих плавание по внутренним водным путям класса Va и более 
высоких классов;</p><br>

<p style="text-indent:2em;">viii) на водных путях с изменяющимся уровнем воды значение рекомендуемой 
осадки должно соответствовать осадке, которая достигается или превышается 
в течение в среднем 240 дней в году (или 60% периода навигации). Там, где это 
возможно и экономически оправдано, следует обеспечить значение рекомендуемой 
высоты прохода под мостами (5,25, 7,00 или 9,10 м) при наивысшем судоходном 
уровне;</p><br>

<p style="text-indent:2em;">ix) единые параметры класса, осадки и высоты прохода под мостами следует 
обеспечить либо на всем водном пути, либо как минимум на значительных по 
протяженности участках этого пути;</p><br>

<p style="text-indent:2em;">х) по возможности следует обеспечить, чтобы параметры прилегающих
 внутренних водных путей были идентичными или аналогичными;</p><br>

<p style="text-indent:2em;">xi) наибольшие величины осадки (4,50 м) и минимальной высоты прохода 
под мостами (9,10 м) следует обеспечить на всех участках сети, непосредственно 
связанных с прибрежными маршрутами;</p><br>

<p style="text-indent:2em;">xii) минимальная высота прохода под мостами 7,00 м должна быть обеспечена 
на водных путях, которые соединяют важные морские порты с районами, находящимися 
в глубине страны, и которые могут быть успешно использованы для контейнерных 
перевозок и перевозок "река - море";</p><br>

<p style="text-indent:2em;">xiii) прибрежные маршруты, упомянутые в Приложении I выше, призваны обеспечить 
целостность сети европейских внутренних водных путей категории E и предназначены, 
по смыслу настоящего Соглашения, для плавания судов типа "река - море", размеры 
которых должны, когда это возможно и экономически целесообразно, отвечать 
требованиям к самоходным судам, пригодным для плавания по внутренним 
водным путям классов Va и VIb.</p>

<p style="text-indent:2em;"></p><h6>Считается, что для обеспечения пригодности водного пути для контейнерных 
перевозок должны соблюдаться следующие минимальные условия:</h6><p></p>

<p style="text-indent:2em;">суда внутреннего плавания шириной 11,4 м и длиной примерно 110 м должны 
иметь возможность осуществлять перевозку контейнеров в три или более 
ярусов;</p><br>

<p style="text-indent:2em;">в противном случае допустимая длина толкаемых составов должна составлять 
185 м, в связи с чем они могут осуществлять перевозку контейнеров в 
два яруса.</p><br>

<p style="font-weight:500;">КЛАССИФИКАЦИЯ ЕВРОПЕЙСКИХ ВНУТРЕННИХ ВОДНЫХ ПУТЕЙ 
МЕЖДУНАРОДНОГО ЗНАЧЕНИЯ *</p><br>

<p>* Классы I - III не приводятся в настоящей таблице, 
поскольку они имеют лишь региональное значение.</p><br>

<p>** Рисунок не приводится.</p><br>

<p style="text-indent:2em;">1 Первое значение приводится с учетом нынешней ситуации, а 
второе - с учетом будущих изменений и, в некоторых случаях, 
нынешней ситуации.</p><br>

<p style="text-indent:2em;">2 С учетом безопасного расстояния, составляющего примерно 0,30 м,
 между верхней точкой конструкции судна или его груза и мостом.</p><br>

<p style="text-indent:2em;">3 С учетом предполагаемого будущего развития ролкерных перевозок,
 контейнерных перевозок и перевозок "река - море".</p>

<p style="text-indent:2em;"></p><h6>4 Для перевозки контейнеров приняты следующие значения:</h6><p></p>

<p>5,25 м - для судов с загрузкой контейнеров в 2 яруса;</p><br>

<p>7,00 м - для судов с загрузкой контейнеров в 3 яруса;</p><br>

<p>9,10 м - для судов с загрузкой контейнеров в 4 яруса;</p><br>

<p>50% контейнеров могут быть порожними, в противном случае следует 
применять балластировку.</p><br>

<p style="text-indent:2em;">5 Некоторые из существующих водных путей могут рассматриваться как 
относящиеся к классу IV по максимально допустимой длине судов и составов, 
даже если их максимальная ширина составляет 11,4 м, а максимальная 
осадка - 4,00 м.</p><br>

<p>6 Значение осадки для конкретного водного пути должно определяться с
 учетом местных условий.</p><br>

<p style="text-indent:2em;">7 На некоторых участках водных путей класса VII могут также использоваться
 составы с большим числом барж. В этом случае горизонтальные габариты 
 могут превышать значения, указанные в таблице.</p><br>

<p>b) Эксплуатационные характеристики водных путей категории E</p>

<p style="text-indent:2em;"></p><h6>Для надежного обеспечения международных перевозок на водных путях категории 
E эти пути должны соответствовать следующим основным эксплуатационным 
критериям:</h6><p></p>

<p style="text-indent:2em;">i) судоходство должно обеспечиваться в течение всего периода навигации, 
за исключением перерывов, упоминаемых ниже;</p><br>

<p style="text-indent:2em;">ii) продолжительность навигации может составлять менее 365 дней только 
в районах с суровыми климатическими условиями, где в зимнее время невозможно 
держать фарватер свободным ото льда и где, следовательно, необходим зимний 
перерыв. В этих случаях следует устанавливать даты начала и окончания навигации. 
Продолжительность перерывов навигации, обусловленных естественными явлениями, 
такими, как ледостав, паводки и т.д., должна сводиться к минимуму с помощью 
соответствующих технических и организационных мер;</p><br>

<p style="text-indent:2em;">iii) продолжительность перерывов в период навигации, необходимых для 
регулярного технического обслуживания шлюзов и другого гидротехнического 
оборудования, должна сводиться к минимуму. Пользователи водных путей, на 
которых планируется проведение работ по техническому обслуживанию, должны 
информироваться о сроках и продолжительности предполагаемого перерыва в 
навигации. В случае непредвиденного выхода из строя шлюзов или других 
гидротехнических объектов или в случае других форс-мажорных обстоятельств 
продолжительность перерывов должна быть в максимальной степени ограничена 
за счет принятия всех необходимых мер по исправлению ситуации;</p><br>

<p style="text-indent:2em;">iv) никаких перерывов в период низкой воды не допускается. Однако разрешается
 разумное ограничение допустимых значений осадки на водных путях с 
 изменяющимся уровнем воды. Тем не менее в течение всего времени 
 следует обеспечить минимальную осадку в 1,20 м, а рекомендуемая 
 или обычная осадка должна обеспечиваться или превышаться в 
 течение 240 дней в году. В районах, упомянутых выше в 
 подпункте "ii", минимальная осадка в 1,20 м должна 
 обеспечиваться в течение в среднем 60% периода навигации;</p><br>

<p style="text-indent:2em;">v) продолжительность работы шлюзов, разводных мостов и других объектов 
инфраструктуры должна быть такой, чтобы она обеспечивала в случае экономической
 целесообразности круглосуточное (24 часа) судоходство по рабочим дням. 
 В отдельных случаях могут допускаться исключения по организационным и 
 / или техническим причинам. Следует также обеспечить разумное судоходное 
 время в праздничные и выходные дни.</p><br>

<p>c) Технические и эксплуатационные характеристики портов категории E</p>

<p style="text-indent:2em;"></p><h6>Сеть водных путей категории E включает систему портов внутреннего 
плавания международного значения. Каждый порт категории E должен 
соответствовать следующим техническим и эксплуатационным критериям:</h6><p></p>

<p>i) он должен находиться на водном пути категории E;</p><br>

<p style="text-indent:2em;">ii) он должен располагать возможностями для обработки судов или 
толкаемых составов, используемых на конкретном водном пути категории 
E в соответствии с его классом;</p><br>

<p style="text-indent:2em;">iii) он должен быть связан с основными автомобильными дорогами и 
железнодорожными линиями (желательно, чтобы они принадлежали к сети 
международных автомобильных дорог и железнодорожных линий, указанных 
в Европейском соглашении о международных автомагистралях (СМА), в Европейском 
соглашении о международных магистральных железнодорожных линиях (СМЖЛ) и в 
Европейском соглашении о важнейших линиях международных комбинированных перевозок 
и соответствующих объектах (СЛКП));</p><br>

<p>iv) его общий грузооборот должен достигать, по крайней мере, 
0,5 млн. т в год;</p><br>

<p>v) он должен располагать подходящими возможностями для развития 
портовой промышленной зоны;</p><br>

<p style="text-indent:2em;">vi) он должен обеспечивать обработку стандартных контейнеров (за 
исключением портов, специализирующихся на обработке массовых 
грузов);</p><br>

<p style="text-indent:2em;">vii) он должен располагать всем оборудованием, необходимым для 
осуществления обычных операций в рамках международных 
перевозок;</p><br>

<p style="text-indent:2em;">viii) в целях обеспечения охраны окружающей среды в портах 
международного значения должны быть предусмотрены приемные емкости 
для удаления отходов, образующихся на борту судов.</p><br>

<p><a href="http://docs.cntd.ru/document/901784389">Источник</a></p>

</div>
`