import React, { FunctionComponent } from 'react'
import classNames from 'classnames'
import { Field, InjectedFormProps } from 'redux-form'

import WidgetBase from '../../../shared/components/widget-base'
import { required, email } from '../../../shared/form-validators'
import FieldBase from '../../../UI/field-base'
import './recover-password.scss'

const STYLE_BASE = 'recover-password_'

interface IEnterEmailProps extends ComponentBaseProps, InjectedFormProps {}

const EnterEmail: FunctionComponent<IEnterEmailProps> = props => {
  const { t, handleSubmit, pristine, submitting, invalid } = props

  return (
    <div className="row">
      <div className="col-md-offset-2 col-md-12 col-lg-offset-3 col-lg-10 col-xl-offset-4 col-xl-8">
        <WidgetBase title={t('login.title.recoverForgottenPassword')}>
          <div className="panel-body">
            <div className="panel-content">
              <div className="panel-content-row">
                <div className={classNames('panel-block', `${STYLE_BASE}wrapper`)}>
                  <form className="panel-form" onSubmit={handleSubmit}>
                    <div className="panel-form-row">
                      <div className="panel-block-inline">
                        <div>
                          <div className="panel-form-row">
                            <Field
                              name="email"
                              type="email"
                              component={FieldBase}
                              label={t('login.password.recovery.enter.email')}
                              validate={[required, email]}
                            />
                          </div>
                          <div className="panel-block-inline command-buttons">
                            <button
                              className="button button-orange"
                              disabled={pristine || submitting || invalid}
                              type="submit"
                            >
                              {t('login.password.recovery.send.email')}
                            </button>
                          </div>
                        </div>
                        <div>
                          <p className="meta">{t('email.registration.enter.info')}</p>
                          <p className="meta">{t('email.rule.message.info')}</p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </WidgetBase>
      </div>
    </div>
  )
}

export default EnterEmail
