import React, { Component } from 'react'
import Widget from '../../shared/components/widget'
import { Link } from 'react-router-dom'
import { pricesValueToText } from "../../../store/utils"

import "./styles.scss"


export default class extends Component {

    renderTableBodyUsersAndPrices = (data) => {
        const { t } = this.props
        const content = (data || []).map((tableLine, index) => {
            const {
                possibilityLabel,
                permissions: {
                    guest,
                    userWithoutCompanyStandart,
                    userWithoutCompanyGold,
                    userWithCompanyStandart,
                    userWithCompanyGold,
                    userWithVerificatedCompanyStandart,
                    userWithVerificatedCompanyGold,
                    userWithVerificatedCompanyGoldLight,
                }
            } = tableLine
            return (
                <tr key={index}>
                    <td dangerouslySetInnerHTML={{ __html: t(possibilityLabel) }} className="padding-3-20" />
                    <td dangerouslySetInnerHTML={{ __html: pricesValueToText(guest, t) }} />
                    <td dangerouslySetInnerHTML={{ __html: pricesValueToText(userWithoutCompanyStandart, t) }} />
                    <td dangerouslySetInnerHTML={{ __html: pricesValueToText(userWithoutCompanyGold, t) }} className="style-4" />
                    <td dangerouslySetInnerHTML={{ __html: pricesValueToText(userWithCompanyStandart, t) }} />
                    <td dangerouslySetInnerHTML={{ __html: pricesValueToText(userWithCompanyGold, t) }} className="style-4" />
                    <td dangerouslySetInnerHTML={{ __html: pricesValueToText(userWithVerificatedCompanyStandart, t) }} />
                    <td dangerouslySetInnerHTML={{ __html: pricesValueToText(userWithVerificatedCompanyGold, t) }} className="style-4" />
                    <td dangerouslySetInnerHTML={{ __html: pricesValueToText(userWithVerificatedCompanyGoldLight, t) }} className="style-5" />
                </tr>
            )
        })

        return content
    }

    renderBlockUsersAndPrices = () => {
        const { title, data, t } = this.props
        return (
            <>
                <Widget {...{ title }}>

                    <div className="panel-filter panel-filter-check">
                        {t('prices.description')}
                        {` `} <Link to="/about/terms-of-use" className="text-bold">«{t('prices.rules')}»</Link>.
                        {t('prices.rules.two')}
                    </div>
                    <div data-tabs="true" className="panel-table panel-table-collapsible">

                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th rowSpan="3" className="style-1 th-additional-styles"><b>{t('prices.users.posibilities')}</b></th>
                                    <th colSpan="8" className="name-iconed" style={{ padding: 10 }}><span><b>{t('prices.users.statuses')}</b></span>
                                    </th>
                                </tr>
                                <tr>
                                    <th rowSpan="2" className="style-2"><b>{t('prices.guest')}</b><br /> ({t('prices.no.logged.users')})</th>
                                    <th colSpan="2"><b>{t('prices.logged.users')}</b><br /> ({t('prices.without.company')})</th>
                                    <th colSpan="2"><b>{t('prices.logged.users')}</b><br /> ({t('prices.employe.compamny.admin')})</th>
                                    <th colSpan="3"><b>{t('prices.logged.users')}</b><br /> ({t('prices.employe.admin.verif.company')})</th>
                                </tr>
                                <tr>
                                    <th className="style-1">
                                        <div dangerouslySetInnerHTML={{ __html: t('prices.without.paket') }} />
                                    </th>
                                    <th className="style-3">
                                        <b>
                                            <div dangerouslySetInnerHTML={{ __html: t('prices.paket.gold') }} />
                                        </b>
                                    </th>
                                    <th className="style-1">
                                        <div dangerouslySetInnerHTML={{ __html: t('prices.without.paket') }} />
                                    </th>
                                    <th className="style-3">
                                        <b>
                                            <div dangerouslySetInnerHTML={{ __html: t('prices.paket.gold') }} />
                                        </b>
                                    </th>
                                    <th className="style-1">
                                        <div dangerouslySetInnerHTML={{ __html: t('prices.without.paket') }} />
                                    </th>
                                    <th className="style-3">
                                        <b>
                                            <div dangerouslySetInnerHTML={{ __html: t('prices.paket.gold') }} />
                                        </b>
                                    </th>
                                    <th className="style-light">
                                        <div dangerouslySetInnerHTML={{ __html: t('prices.paket.gold.light') }} />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderTableBodyUsersAndPrices(data)}
                            </tbody>
                        </table>
                    </div>
                </Widget>
                <div
                    dangerouslySetInnerHTML={{
                        __html: t('prices.table.one.description')
                    }} />
            </>
        )
    }

    renderTableBodyCompanyAdminPermissions = (data) => {
        const { t } = this.props
        const content = (data || []).map((tableLine, index) => {
            const {
                possibilityLabel,
                permissions: {
                    companyAdminStandart,
                    companyAdminGold,
                    companyAdminVerificatedStandart,
                    companyAdminVerificatedGold,
                    companyAdminVerificatedGoldLight,
                }
            } = tableLine
            return (
                <tr key={index}>
                    <td dangerouslySetInnerHTML={{ __html: t(possibilityLabel) }} className="padding-3-20" />
                    <td dangerouslySetInnerHTML={{ __html: pricesValueToText(companyAdminStandart, t) }} />
                    <td dangerouslySetInnerHTML={{ __html: pricesValueToText(companyAdminGold, t) }} className="style-3" />
                    <td dangerouslySetInnerHTML={{ __html: pricesValueToText(companyAdminVerificatedStandart, t) }} />
                    <td dangerouslySetInnerHTML={{ __html: pricesValueToText(companyAdminVerificatedGold, t) }} className="style-3" />
                    <td dangerouslySetInnerHTML={{ __html: pricesValueToText(companyAdminVerificatedGoldLight, t) }} className="style-light" />
                </tr>
            )
        })

        return content
    }

    renderBlockCompanyAdminPermissions = () => {
        const { title, data, t } = this.props
        return (
            <>
                <Widget {...{ title }}>
                    <table className="table table-striped">
                        <thead>
                            <tr className="">
                                <th rowSpan="3" className="style-1 th-additional-styles"><b>{t('prices.admins')}</b></th>
                                <th colSpan="5" className="name-iconed"><span><b>{t('prices.company.admin.statuses')}</b></span>
                                </th>
                            </tr>
                            <tr className="">
                                <th colSpan="2"><b>{t('prices.company.admin')}</b></th>
                                <th colSpan="3"><b>{t('prices.company.admin.verif')}</b></th>
                            </tr>
                            <tr className="">
                                <th className="style-1">
                                    <div dangerouslySetInnerHTML={{ __html: t('prices.without.paket') }} />
                                </th>
                                <th className="style-3">
                                    <div dangerouslySetInnerHTML={{ __html: t('prices.paket.gold') }} />
                                </th>
                                <th className="style-1">
                                    <div dangerouslySetInnerHTML={{ __html: t('prices.without.paket') }} />
                                </th>
                                <th className="style-3">
                                    <div dangerouslySetInnerHTML={{ __html: t('prices.paket.gold') }} />
                                </th>
                                <th className="style-light">
                                    <div dangerouslySetInnerHTML={{ __html: t('prices.paket.gold.light') }} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableBodyCompanyAdminPermissions(data)}
                        </tbody>
                    </table>
                </Widget>
                <div
                    dangerouslySetInnerHTML={{
                        __html: t('prices.table.two.description')
                    }} />
            </>
        )
    }

    renderTableBodyAccountTypePrices = (data) => {
        const { t } = this.props
        const content = (data || []).map((tableLine, index) => {
            const {
                accountLabel,
                styleColor,
                permissions: {
                    userWithoutCompany12monts,
                    userWithoutCompany6monts,
                    userWithoutCompany3monts,
                    userWithCompany12monts,
                    userWithCompany6monts,
                    userWithCompany3monts,
                    userWitVerificatedCompany12monts,
                    userWitVerificatedCompany6monts,
                    userWitVerificatedCompany3monts,
                }
            } = tableLine
            return (
                <tr key={index}>
                    <td dangerouslySetInnerHTML={{ __html: t(accountLabel) }} className={styleColor} />
                    <td dangerouslySetInnerHTML={{ __html: userWithoutCompany12monts }} className={styleColor} />
                    <td dangerouslySetInnerHTML={{ __html: userWithoutCompany6monts }} className={styleColor} />
                    <td dangerouslySetInnerHTML={{ __html: userWithoutCompany3monts }} className={styleColor} />
                    <td dangerouslySetInnerHTML={{ __html: userWithCompany12monts }} className={styleColor} />
                    <td dangerouslySetInnerHTML={{ __html: userWithCompany6monts }} className={styleColor} />
                    <td dangerouslySetInnerHTML={{ __html: userWithCompany3monts }} className={styleColor} />
                    <td dangerouslySetInnerHTML={{ __html: userWitVerificatedCompany12monts }} className={styleColor} />
                    <td dangerouslySetInnerHTML={{ __html: userWitVerificatedCompany6monts }} className={styleColor} />
                    <td dangerouslySetInnerHTML={{ __html: userWitVerificatedCompany3monts }} className={styleColor} />
                </tr>
            )
        })

        return content
    }

    renderBlockAccountTypePrices = () => {
        const { title, data, t } = this.props
        return (
            <>
                <Widget {...{ title }}>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th rowSpan="3" className="style-1">{t('prices.paket.type')}</th>
                            </tr>
                            <tr>
                                <th colSpan="3">{t('prices.logged.user.without.company')}</th>
                                <th colSpan="3">{t('prices.logged.user.with.company')}</th>
                                <th colSpan="3">{t('prices.logged.user.verig.company')}</th>
                            </tr>
                            <tr>
                                <th className="style-1">12 {t('prices.month')}</th>
                                <th className="style-1">6 {t('prices.month')}</th>
                                <th className="style-1">3 {t('prices.month')}</th>
                                <th className="style-1">12 {t('prices.month')}</th>
                                <th className="style-1">6 {t('prices.month')}</th>
                                <th className="style-1">3 {t('prices.month')}</th>
                                <th className="style-1">12 {t('prices.month')}</th>
                                <th className="style-1">6 {t('prices.month')}</th>
                                <th className="style-1">3 {t('prices.month')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableBodyAccountTypePrices(data)}
                        </tbody>
                    </table>
                </Widget>
                <div
                    dangerouslySetInnerHTML={{
                        __html: t('prices.table.three.description')
                    }} />
            </>
        )
    }

    getContent = (blockId) => {
        let content

        switch (blockId) {
            case "usersAndPrices":
                content = this.renderBlockUsersAndPrices();
                break;
            case "companyAdminPermissions":
                content = this.renderBlockCompanyAdminPermissions()
                break;
            case "accountTypePrices":
                content = this.renderBlockAccountTypePrices()
                break;
            default:
                break;
        }

        return content

    }

    render() {
        const { blockId } = this.props
        const content = this.getContent(blockId)

        return content
    }
}
