import { connect } from 'react-redux'
import { compose } from 'recompose'
import withTranslation from '../../../globals/components/withTranslation'
import withPermission from '../../../globals/components/withPermissions'
import { IReduxStorage } from '../../../store/reducers/types'

import Component from './component'

export default compose(
  withTranslation,
  withPermission,
  connect((state: IReduxStorage) => {
    const {
      auth: { profile },
    } = state

    return {
      profile: profile.get('data').toJS(),
      langId: state.ui.langId,
    }
  }, {
  })
)(Component)
