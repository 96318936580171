import { connect } from 'react-redux'
// import { bindActionCreators } from "redux"

import { setActivePopup } from '../../../store/actions/ui'

import Component from './component'

// const actionCreators = {
//   setActivePopup,
// }
export default connect(
  null,
  {
    setActivePopup,
  }
  // dispatch => bindActionCreators(actionCreators, dispatch)
)(Component)
