import { compose } from 'recompose'
import { reduxForm } from 'redux-form'

import withTranslation from '../../globals/components/withTranslation'
import Component from './component'

const FORMNAME = 'info-country'

const initialValues = { country: '1' }

export default compose(
  withTranslation,
  reduxForm({
    form: FORMNAME,
    initialValues,
    touchOnChange: true,
  })
)(Component)
