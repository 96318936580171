export const md15 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>ПОСТАНОВЛЕНИЕ О регламентации перевозок опасных грузов по территории Республики Молдова и ликвидации последствий возможных при этом аварий</h4><p></p>

<p></p><h4>П О Л О Ж Е Н И Е о порядке перевозки по территории Республики Молдова опасных грузов и ликвидации последствий возможных при этом аварий</h4><p></p>

    <p style="text-indent:2em;">Настоящее Положение о порядке перевозки по территории Республики Молдова опасных грузов и ликвидации последствий возможных при этом аварий (в дальнейшем - Положение) устанавливает порядок перевозки
	опасных грузов всеми видами транспорта, независимо от ведомственной подчиненности и форм собственности, по всей территории Республики Молдова, определяет основные требования к методам организации, 
	технического обеспечения и безопасности погрузочно-разгрузочных работ и перевозок, а также порядок ликвидации последствий возможных аварий и аварийных ситуаций на транспорте, направлено на охрану жизни
	и здоровья людей, защиту народнохозяйственных объектов и окружающей среды, обеспечение сохранности материальных ценностей.</p><br>
	
    <p style="text-indent:2em;">Требования настоящего Положения являются обязательными для исполнения всеми юридическими и физическими лицами Республики Молдова, а также иностранными лицами, находящимися на ее территории.</p>

<p></p><h4>I. Используемые понятия</h4><p></p>

    <p style="text-indent:2em;"></p><h6>1. В настоящем Положении использованы следующие понятия:</h6><p></p>
	
	<p style="text-indent:2em;">опасные грузы - вещества, материалы, изделия и отходы, которые в силу своего физического состояния, химических и биологических свойств,
	радиоактивности или других специфических особенностей в процессе перевозки могут создавать угрозу для жизни и здоровья людей, вызывать загрязнение окружающей среды, гибель флоры и фауны, повреждение
	или уничтожение транспортных средств и иного имущества;</p><br>

    <p style="text-indent:2em;">правила перевозки опасных грузов - нормативно-технические акты, регламентирующие комплекс мер по обеспечению безопасности перевозки опасных грузов с использованием соответствующих видов транспорта;</p><br>

    <p style="text-indent:2em;">участники перевозки - грузоотправители и грузополучатели, предприятия и организации, осуществляющие погрузку, транзитное складирование и временное хранение, транспортирование, разгрузку, охрану
	и сопровождение груза;</p><br>

    <p style="text-indent:2em;">процесс перевозки опасного груза - совокупность операций: подготовка груза и транспортных средств, прием груза к перевозке, его погрузка в транспортное средство, транспортирование груза из пункта
	погрузки в пункт назначения, перегрузка (перевалка) груза с одного вида транспорта на другой при смешанных перевозках, транзитное складирование и временное хранение, разгрузка, выдача груза получателю;</p><br>

    <p style="text-indent:2em;">авария на транспорте при перевозке опасных грузов - обстановка, сложившаяся в результате негативного воздействия перевозимых грузов на человека, материальные ценности и окружающую среду;</p><br>

    <p>аварийная ситуация - ситуация, создающая угрозу возникновения аварии при перевозке опасных грузов.</p>

<p></p><h4>II. Общие положения</h4><p></p>

    <p style="font-weight:500;">2. Физические и юридические лица, независимо от формы собственности, из республики и из-за рубежа,осуществляющие перевозки опасных грузов, или участвующие в таких перевозках, руководствуются
	настоящим Положением а также правилами, разработанными и утвержденными министерством транспорта и согласованными с Департаментом по гражданской защите и чрезвычайным ситуациям Министерства обороны.</p><br>

    <p style="font-weight:500;">3. Грузоотправители и грузополучатели, юридические и физические лица независимо от формы собственности, осуществляющие отгрузку и получение опасных грузов, назначают ответственных исполнителей
	по обеспечению безопасности перевозок и ликвидации аварийных ситуаций с этими грузами.</p><br>

    <p style="font-weight:500;">4. Специализированные и универсальные транспортные средства, принадлежащие физическим и юридическим лицам, независимо от формы собственности, из республики и из-за рубежа, и предназначенные для
	перевозки опасных грузов, допускаются к эксплуатации по автомобильным дорогам республики соответствующими органами Дорожной полиции.</p><br>

    <p style="font-weight:500;">5. Отраслевые министерства и государственные департаменты совместно с органами местного самоуправления ежегодно утверждают перечень предприятий, учреждений и организаций, независимо от формы
	собственности которые используют вещества и изделия, включенные в номенклатуру опасных грузов, и совместно с Департаментом по гражданской защите и чрезвычайным ситуациям Министерства обороны определяют илы и средства, 
	необходимые для ликвидации последствий возможных аварий и аварийных ситуаций.</p><br>

    <p style="font-weight:500;">6. Департамент по гражданской защите и чрезвычайным ситуациям Министерства обороны совместно с заинтересованными министерствами и ведомствами планируют необходимое количество сил и средств
	(формирований гражданской защиты, специализированных формирований министерств, воинских частей), необходимых для выполнения работ по ликвидации последствий аварий и аварийных ситуаций.</p><br>

    <p style="font-weight:500;">7. Организации и предприятия, имеющие спасательные службы и соответствующих специалистов, обязаны участвовать в ликвидации аварийных ситуаций и последствий аварий с опасными грузами в соответствии
	с распоряжениями республиканских органов или органов местного самоуправления.</p><br>

    <p style="font-weight:500;">8. Предприятия, которые используют в своей технологии материалы повышенной опасности и производят их перевозку как по территории республики, так и за ее пределами, осуществляют постоянный контроль
	за поступлением, движением и разгрузкой опасных грузов, а в случае возникновения аварийных ситуаций или аварий принимают незамедлительные меры к ликвидации их последствий.</p><br>

    <p style="font-weight:500;">9. Юридические и физические лица, независимо от формы собственности, обязаны не позднее чем за 12 часов известить органы местного самоуправления, по территории которых будет осуществляться перевозка
	опасного груза автомобильным транспортом, о маршруте, времени перевозки и характере груза.</p><br>

    <p style="text-indent:2em;">При перевозках опасных грузов в количествах, представляющих в случае аварии чрезвычайную опасность, предприятия-получатели обязаны представить ă органы местного самоуправления документацию, подтверждающую
	соответствие тары требованиям стандартов или техническим условиям, а органы местного самоуправления - уточнить маршрут и выделить для сопровождения наряд Дорожной полиции.</p><br>

    <p style="font-weight:500;">10. Органы местного самоуправления, на территории которых расположены предприятия, использующие в своей технологии материалы повышенной опасности, и совершаются перевозки опасных грузов, обязаны иметь 
	специальные формирования гражданской защиты для ликвидации последствий возможных аварий с этими грузами, поддерживать постоянную связь и тесное взаимодействие с частями гражданской защиты и воинскими частями
	Министерства обороны, расположенными на их территории, по всему пути следования груза.</p><br>

    <p style="text-indent:2em;">Всостав таких специальных формирований могут входить подразделения штатные (профессиональные и военизированные пожарные части, санитарные эпидемиологические станции, бригады скорой помощи и др.) и нештатные
	(невоенизированные формирования медицинской, автотранспортной, противопожарной, инженерной, коммунально-технической служб, служба охраны общественного порядка и других территориальных служб гражданской защиты).</p><br>

    <p style="text-indent:2em;">В случае возникновения аварийной ситуации, последствия которой создают угрозу для населения, животных и окружающей среды, органы местного самоуправления немедленно оповещают население, объекты народного
	хозяйства и принимают все меры для их эвакуации и защиты.</p>

<p></p><h4>III. Требования к участникам перевозки
опасных грузов</h4><p></p>

    <p style="font-weight:500;">11. К выполнению полного перечня работ по транспортировке и сопровождению опасных грузов допускаются лица, прошедшие медицинское освидетельствование, имеющие специальную подготовку и документ на право
	их выполнения. Исполнители работ должны быть обеспечены необходимыми средствами защиты.</p><br>

    <p style="font-weight:500;">12. Руководители транспортных предприятий, юридические и физические лица, осуществляющие перевозку опасных грузов, обязаны допускать к перевозке только специально оборудованный транспорт и обладать
	в случаях, предусмотренных действующим законодательством, соответствующими лицензиями, выданными лицензионным органом.</p><br>

    <p style="font-weight:500;">13. Погрузка, разгрузка и транспортировка опасных грузов производится при наличии у участников необходимых сил и средств защиты (пожаротушения, нейтрализации, медицинские и др.).</p><br>

    <p style="font-weight:500;">14. Виды транспортных средств и упаковки,маркировка и подготовка опасного груза к перевозке, условия хранения и транспортировки, требования безопасности при упаковке груза, хранении и транспортировке
	должны соответствовать нормативно-технической документации (стандартам, техническим условиям) на опасные грузы, а также действующим правилам перевозки опасного груза на соответствующем виде транспорта.</p><br>

    <p style="font-weight:500;">15. Участники перевозки обязаны неукоснительно соблюдать требования нормативно-технической документации по перевозке опасного груза.</p><br>

    <p style="font-weight:500;">16. Система классификации и маркировки опасных грузов, а также нанесение опознавательных знаков опасности на транспортные средства определяется государственными стандартами, разработанными на
	основе Рекомендаций по перевозке опасных грузов Экономического и Социального Совета ООН (ЭиСС ООН).</p><br>

    <p style="font-weight:500;">17. В соответствии с указанными Рекомендациями компетентный государственный орган составляет и утверждает перечень опасных грузов, а данные о его опасных свойствах указываются в перевозочных
	документах, включая применение упаковки, тары и схемы погрузки-разгрузки.</p><br>

    <p style="text-indent:2em;">Грузоотправитель ответственен за правильность записи и классификации опасного груза при его транспортировке.</p><br>

    <p style="font-weight:500;">18. Конструкция и параметры специальных контейнеров для перевозки опасных грузов должны соответствовать требованиям стандартов или техническим условиям и обеспечивать сохранность груза и
	безопасность перевозки.</p><br>

    <p style="text-indent:2em;">Грузоотправитель ответственен за соответствие контейнеров требованиям стандартов или техническим условиям.</p><br>

    <p style="font-weight:500;">19. Тара для перевозки опасных грузов маркируется и обеспечивается соответствующими знаками опасности (согласно действующим международным стандартам) заводами-изготовителями, а также
	отправителями указанных грузов.</p><br>

    <p style="font-weight:500;">20. Порожняя тара из-под опасных грузов перевозится на общих обоснованиях при наличии в товарно-транспортных и перевозочных документах отметки \"Тара очищена, безопасно\", проставленной
	ответственным лицом грузополучателя после очистки и дезинфекции тары.</p><br>

    <p style="text-indent:2em;">При отсутствии такой отметки порожняя тара перевозится на тех же условиях, на которых перевозился опасный груз.</p><br>

    <p style="font-weight:500;">21. Грузоотправитель предъявляет к перевозке опасный груз в соответствии с требованиями настоящего Положения, отраслевых нормативных актов, изданных на его основе, и нормативно-технической
	документации на конкретный вид опасной продукции.</p><br>

    <p style="font-weight:500;">22. К перевозочным документам на опасные грузы отправитель обязан приложить удовлетворение, подтверждающее соблюдение требований нормативно-технической документации на эти виды продукции.</p><br>

    <p style="font-weight:500;">23. Перечень опасных грузов, подлежащих охране или сопровождению, порядок реализации и вид охраны, а также решение о возможности перевозки опасных грузов, не вошедших в перечень грузов, допущенных
	к перевозке на всех видах транспорта, утверждаются Правительством Республики Молдова.</p><br>

    <p style="font-weight:500;">24. Для координации работ, проведение единой технической политики в области безопасной перевозки опасных грузов с учетом Рекомендаций по перевозке опасных грузов Экономического и Социального Совета
	ÎОН, документов МАГАТЭ по обеспечению радиационной и ядерной безопасности, а также других международных норм и правил Правительством Республики Молдова назначается государственный орган, компетентный
	в вопросах перевозки опасных грузов.</p><br>

    <p style="font-weight:500;">25. Грузоотправитель совместно с грузополучателем в обязательном пордяке согласовывает с органами Дорожной полиции Республики Молдова маршруты перевозки опасных грузов автомобилльным транспортом.</p><br>

    <p>Срок действия согласованного постоянного маршрута перевозок - не более одного года.</p><br>

    <p>Разовое согласование маршрута действует на срок его осуществления от грузоотправителя до грузополучателя.</p><br>

    <p style="font-weight:500;">26. При перевозке по железным дорогам импортных опасных грузов, поступивших с иностранных железных дорог в прямом международном сообщении или транзитом, применяются меры безопасности, предусмотренные
	настоящим Положением с учетом Правил перевозки опасных грузов на железнодорожном транспорте.</p>

<p></p><h4>IV. Порядок ликвидации последствий
аварий при перевозке опасных грузов</h4><p></p>

    <p style="font-weight:500;">27. Работа по ликвидации последствий аварий при перевозке опасных грузов проводятся исходя из создавшейся обстановки, с учетом свойств этих грузов и возникающей опасности для людей и окружающей среды.
	Эти мероприятия должны проводится четко и быстро при строгом соблюдении рекомендаций и мер безопасности, указанных в аварийных карточках на перевозимые грузы. При сопровождении опасного груза проводниками
	или специалистами грузоотправителя необходимые меры при ликвидации аварийных ситуаций с опасными грузами проводятся с учетом их указаний.</p><br>

    <p style="font-weight:500;">28. При возникновении аварий проводники или специалисты грузоотправителя (грузополучателя), сопровождающие опасные грузы, обязаны оценить обстановку, принять меры к оповещению дежурного по ближайшей
	железнодорожной станции, при аварии на автомобильном транспорте - местные органы Дорожной полиции, при аварии на водном транспорте - капитана судна.</p><br>

    <p style="text-indent:2em;">В случае аварии проводники опасных грузов вместе с машинистами поездов, экипажами судов, водителями машин до прибытия помощи обязаны принять первичные меры по ликвидации последствий аварии в соответствии
	с аварийной карточкой на перевозимый груз, обозначить место аварии и не допускать посторонних лиц (транспорт) к месту аварии.</p><br>

    <p style="font-weight:500;">29. С получением информации о дорожно-транспортном происшествии и возникновении аварийной ситуации при перевозке опасных грузов органы Министерства внутренних дел немедленно вызывают скорую медицинскую помощь,
	пожарные формирования, работников гражданской защиты, санитарно- эпидемиологической станции, направляют к месту аварии наряд (оперативную группу) полиции, сообщают органам местного самоуправления.</p><br>

    <p style="font-weight:500;">30. Решение о порядке ликвидации последствий аварии при перевозке опасных грузов железнодорожным транспортом принимает начальник Молдавской железной дороги, водным транспортом - капитан судна, при
	большом масштабе аварии - республиканские органы управления.</p><br>

    <p style="text-indent:2em;">При необходимости в район аварии направляется поезд для восстановительных работ или пожарный поезд, выход других поездов на аварийный перегон запрещается.</p><br>

    <p style="text-indent:2em;">Решение о порядке ликвидации последствий аварии при перевозке опасных грузов автомобильным транспортом (по дорогам) принимает руководитель органа местного самоуправления.</p><br>

    <p style="font-weight:500;">31. В зависимости от характера аварии и степени возникающей угрозы для населения и окружающей среды к ликвидации ее последствий привлекаются специальные силы и средства предприятий - грузоотправителей или
	грузополучателей, районные, городские и республиканские силы и средства гражданской защиты, специальные воинские подразделения и части.</p><br>

    <p style="font-weight:500;">32. Мероприятия по ликвидации аварийных ситуаций на транспорте при перевозке опасных грузов должны разрабатываться заранее и отражаться в планах гражданской защиты соответствующих министерств, государственных
	департаментов, учреждений и предприятий, а также органов местного самоуправления.</p><br>

    <p style="font-weight:500;">33. Специалисты санитарно-эпидемиологической станции, прибыв к месту аварии, незамедлительно организуют контроль за выполнением предписаний и рекомендаций по безопасному ведению работ, а при необходимости
	организуют работы по обеззараживанию территории, транспортных средств и др.</p><br>

    <p style="text-indent:2em;">На время проведения работ по ликвидации последствий аварии на месте происшествия организуется круглосуточное дежурство медицинского персонала.</p><br>

    <p style="font-weight:500;">34. Слив или выгрузка опасных грузов из поврежденной тары на грунт и в водоемы категорически запрещается.</p><br>

    <p style="text-indent:2em;">Жидкие грузы из поврежденной тары перекачиваются в исправную запасную или нейтрализуются на месте в зависимости от вида опасного груза.</p><br>

    <p style="font-weight:500;">35. Попавшие на грунт или в водоемы токсичные вещества в ходе работ по ликвидации последствий аварий должны быть полностью обеззаражены.</p><br>

    <p style="text-indent:2em;">Работники санитарно-эпидемиологической станции и экологической инспекции после проведения работ по обеззараживанию организуют лабораторный контроль за эффективностью их проведения.</p><br>

    <p style="font-weight:500;">36. Возможность возобновления движения транспортных средств через зону, в которой произошла авария при перевозке опасных токсичных или радиоактивных грузов, определяется работниками санитарно- эпидемиологической
	станции после проведения анализов, характеризующих состояние окружающей среды.</p><br>

    <p style="font-weight:500;">37. В случае аварийной ситуации или аварии с опасными грузами в зимнее время лабораторный контроль эффективности обеззараживающих работ повторно проводится в теплое время года, а при необходимости работы по
	обеззараживанию повторяются.</p><br>

    <p style="font-weight:500;">38. Работы по ликвидации последствий аварии считаются законченными по завершению ликвидации заражения района аварии и после выдачи заключения санитарно-эпидемиологической станцией.</p><br>

    <p style="font-weight:500;">39. Во время проведения работ по ликвидации последствий аварий при перевозке токсичных или радиоактивных веществ учитываются защитные свойства средств индивидуальной защиты и допустимые дозы облучения.</p><br>

    <p style="text-indent:2em;">В безопасном от места аварии районе развертываются пункты санитарной обработки личного состава, дегазации или дезактивации техники.</p><br>

    <p style="font-weight:500;">40. Обеспечение формирований, участвующих в ликвидации последствий аварий, дегазирующими, дезактивирующими веществами и растворами производится по заявке производителя работ предприятиями- грузоотправителями
	(получателями) или другими, блмижайшими к месту аварии предприятиями.</p><br>

    <p style="font-weight:500;">41. Министерство транспорта устанавливает места погрузки и выгрузки опасных грузов на железнодорожных станциях, и авиационных и речных портах, а также осуществляет контроль за созданием ведомственных систем
	безопасности проведения первичных мероприятий по ликвидации последствий аварий.</p>

<p></p><h4>V. Надзор и контроль за соблюдением требований
настоящего Положения</h4><p></p>

    <p style="font-weight:500;">42. Государственный надзор за проведением единой технической политики в вопросе безопасности перевозки опасных грузов, выполнением международных норм и правил перевозки, требований настоящего Положения
	осуществляется государственным органом, утвержденным Правительством Республики Молдова.</p><br>

    <p style="font-weight:500;">43. Требования органов и инспекций государственного надзора по ăопросам соблюдения настоящего Положения являются обязательными для исполнения всеми участниками перевозок.</p><br>

    <p style="font-weight:500;">44. Контроль за соблюдением требований настоящего Положения осуществляют: на автомобильном транспорте - Дорожная полиция, на железнодорожном, воздушном и речном транспорте - Транспортная полиция.</p>

<p></p><h4>VI. Организация подготовки руководящего состава
и специалистов, осуществляющих перевозку
опасных грузов</h4><p></p>

    <p style="font-weight:500;">45. Руководители предприятий, которые осуществляют перевозку опасных грузов, обеспечивают необходимую специальную подготовку руководящего состава этих предприятий, независимо от формы собственности, и
	осуществляют постоянный контроль за специальной подготовкой рабочих и служащих, допускаемых к выполнению погрузочно-разгрузочных работ, перевозок и сопровождению опасных грузов.</p><br>

    <p style="font-weight:500;">46. Руководители предприятий, учреждений и организаций, независимо от формы собственности, несут личную ответственность за подготовку рабочих и служащих, осуществляющих погрузочно-разгрузочные работы,
	перевозку или сопровождение опасных грузов.</p><br>

    <p>Они обязаны обеспечивать специальную подготовку указанных специалистов.</p><br>

    <p style="font-weight:500;">47. Руководящий состав предприятий, учреждений и организаций, осуществляющих отправку, получение и перевозку опасных грузов, а также рабочие и служащие, непосредственно выполняющие погрузочно-разгрузочные работы,
	перевозку или сопровождение опасных грузов, проходят специальную подготовку на курсах повышения квалификации по программам обучения, согласованным с органами государственного надзора и Министерством
	здравоохранения.</p><br>

    <p style="text-indent:2em;">По окончании курса подготовки и сдачи зачетов им выдается специальное удостоверение (допуск), дающее право на производство работ с опасными грузами, их перевозку и сопровождение.</p>

<p></p><h4>VII. Отвественность участников перевозки
опасных грузов</h4><p></p>

    <p style="font-weight:500;">48. За нарушение требований настоящего Положения и требований нормативно-технической документации при перевозках опасных грузов участники перевозок несут ответственность в соответствии с действующим 
	законодательством Республики Молдова.</p><br>

    <p style="font-weight:500;">49. Грузоотправитель опасного груза несет ответственность в соответствии с действующим законодательством за представление неполных или искаженных данных о характере и объеме груза, о соответствии его
	требованиям стандартов.</p><br>

    <p style="font-weight:500;">50. За нарушение технологического процесса перевозок и транспортных нормативов, касающихся обеспечения безопасности, транспортные организации и предприятия несут ответственность в соответствии с 
	действующим законодательством Республики Молдова.</p>

    <p style="text-indent:2em;"></p><h6>Они не несут ответственность в случаях:</h6><p></p>

    <p style="text-indent:2em;">а) если авария произошла вследствие воздействия природных сил (землетрясения, урагана, наводнения, оползня и др.), а также преднамеренных действия третьих лиц;</p><br>

    <p>b) если были введены в заблуждение грузоотправителем о степени опасности перевозимого груза.</p><br>

    <p style="font-weight:500;">51. Предприятия и организации - участники перевозки опасных грузов, виновные в возникновении аварий, обязаны возместить ущерб, причиненный в результате допущенных ими нарушений, включая стоимость
	аварийно-восстановительных работ, а также выплатить компенсацию пострадавшим и их семьям в соответствии с действующим законодательством Республики Молдова.</p>

<p></p><h4>VIII. Финансовое обеспечение мероприятий по ликви-
дации аварийных ситуаций и аварий на транс-
порте и их последствий</h4><p></p>

    <p style="font-weight:500;">52. Ответственность и расходы по ликвидации аварийных ситуаций на транспорте и их последствий несут юридические и физические лица, по вине которых была создана аварийная ситуация, в соответствии
	с действующим законодательством.</p><br>

    <p style="font-weight:500;">53. Размеры прямого и косвенного материального ущерба, вызванного аварийной ситуацией и ее последствиями, определяет специально созданная комиссия, назначаемая в зависимости от величины материального
	ущерба министерствами, государственными департаментами, органами местного самоуправления или Правительством Республики Молдова.</p>

<p></p><h4>IX. Специальные и воинские перевозки</h4><p></p>

    <p style="font-weight:500;">54. Специальные и воинские перевозки регламентируются настоящим Положением, а также приказами Министра обороны, Министра внутренних дел, и директора Службы информации и безопасности Республики Молдова,
	согласованными с Министерством транспорта.</p><br>

    <p style="font-weight:500;">55. Перечень опасных грузов, перевозка которых осуществляется как перевозка специальных или воинских грузов, утверждается Правительством Республики Молдова.</p>

<p></p><h6>Приложение N 2 к постановлению Правительства Республики Молдова N 45 от 24 января 1994 г.</h6><p></p>

<p></p><h4>П Е Р Е Ч Е Н Ь опасных грузов,  запрещенных  к перевозке через территорию   Республики  Молдова  всеми  видами транспорта без особого разрешения Правительства Республики Молдова</h4><p></p>

<p></p><h6>Наименование опасных грузов согласно их классификации	Класс опасности</h6><p></p>

<p>Взрывчатые вещества (ВВ)	1</p><br>

<p>Боевые отравляющие вещества (БОВ)	6</p><br>

<p>Радиоактивные (РВ) и бактериологические (БВ) вещества	7</p><br>

<p>Радиоактивные  вещества</p><br>
	 
<p>Радиоактивное сырье (руды урана, тория и их концентраты)	7</p><br>

<p>Ядерное топливо (ядерно-взрывные делящиеся радиоактивные ные вещества) U-233, U-235, U-238, Pu-239	7</p><br>

<p>Радиоактивные отходы атомных реакторов (твердые, жидкие,   газообразные)	7</p><br>

<p>Бактериологические (биологические) вещества</p>

<p style="text-indent:2em;"></p><h6>Культуры микроорганизмов - возбудителей особо опасныхинфекционных заболеваний человека и животных:	7</h6><p></p>

<p style="text-indent:2em;">холеры, чумы, бруцеллеза, сибирской язвы, лихорадки Ку,сапа, бешенства, вирусной гемморагической лихорадки,ботулизма</p>	 

<p></p><h6>Примечание.</h6><p></p>

<p style="text-indent:2em;">Перевозка опасных грузов классов 1,7,а также боевых отравляющих веществ (класс 6) осуществляется в порядке, установленном  специальными правилами.</p><br>

<p><a href="https://www.legis.md/cautare/getResults?doc_id=109581&amp;lang=ru">Источник</a></p>


</div>
`