import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reduxForm, formValueSelector } from 'redux-form'
import { withRouter } from 'react-router-dom'
import withTranslation from '../../../../globals/components/withTranslation'
import withPersmissions from '../../../../globals/components/withPermissions'
import { IReduxStorage } from '../../../../store/reducers/types'
import { getItem, resetItem } from '../../../../store/actions/ads'
import { onCanAdd } from '../../../../store/actions/profile'

import Component from './component'

const formName = 'add-ads'
const formSelector = formValueSelector(formName)

export default compose(
  withTranslation,
  withPersmissions,
  withRouter,
  connect(
    (state: IReduxStorage) => {
      const langId = state.ui.langId
      const {
        title,
        text,
        tree_type_id,
        sub_tree_type_id,
        placement_period,
        user_id,
        photos,
      } = state.ads.item
      return {
        langId,
        adAuthor: user_id,
        notes: formSelector(state, 'notes'),
        title: formSelector(state, 'title'),
        canAdd: state.profile.canAdd,
        initialValues: {
          title: title || '',
          notes: text || '',
          category: tree_type_id || '',
          subcategory: sub_tree_type_id || '',
          dayInterval: placement_period || '',
          photo: photos || null,
        },
      }
    },
    { getItem, resetItem, onCanAdd }
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    forceUnregisterOnUnmount: false,
  })
)(Component)
