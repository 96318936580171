import React, { Component } from 'react'
import Widget from '../../shared/components/widget'
import Ads from '../../ads'
import SiteStat from '../../site-stat'
import BuySellShort from '../../buy-sell/short'
import RoFaq from "./ro-faq"

import './styles.scss'

export default class extends Component {
  render() {
    const { t, langId } = this.props

    const otherLangs = () => {
      return (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: t('about.us.faq'),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t('about.us.faq.part.second'),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t('about.us.faq.part.third'),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t('about.us.faq.part.fourth'),
            }}
          />
        </>
      )
    }
    const content = langId === 3 ? <RoFaq /> : otherLangs()
    return (
      <div className="row">
        <div className="col-md-12">
          <Widget title={t('menu.faq')}>
            {content}
          </Widget>
        </div>
        <div className="col-md-4">
          <SiteStat />
          <BuySellShort />
          <Ads />
        </div>
      </div>
    )
  }
}
