import { connect } from 'react-redux'
import { compose } from 'recompose'

import Component from './component'

import withTranslation from '../../../globals/components/withTranslation'

export default compose(
  withTranslation,
  connect(state => {
    const { total, pageIndex, pageSize, loading, items } = state.news.mini

    return {
      total,
      pageIndex,
      pageSize,
      loading,
      items,
    }
  })
)(Component)
