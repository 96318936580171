import React from 'react'
import { AdminClaimsState, AdminClaimsProps } from './types'
import Widget from '../shared/components/widget'
import Ads from '../ads'
import SiteStat from '../site-stat'
import BuySellShort from '../buy-sell/short'
import ClaimItem from "./claim-item"
import fetch from '../shared/fetch'
import Spinner from '../UI/spinner'
import Pagination from '../UI/pagination'
import "./styles.scss"

const PAGE_SIZE = 5

const initialState: AdminClaimsState = {
    pageIndex: 0,
    pageSize: PAGE_SIZE,
    claimsData: [],
    isFetching: true,
    totalItems: 0
}


export default class AdminClaims extends React.Component<AdminClaimsProps, AdminClaimsState> {

    constructor(props) {
        super(props)

        this.state = initialState
    }

    componentDidMount() {
        this.getAllClaims()
    }

    getAllClaims = () => {
        const { pageIndex, pageSize } = this.state
        const body = JSON.stringify({
            claimType: 0,
            pageIndex,
            pageSize
        })

        fetch(`/claim`, {
            method: 'POST',
            body,
        }).then(res => {
   
            this.setState({
                claimsData: res.d.items,
                totalItems: res.d.total,
                isFetching: false
            })
        })
    }

    renderClaims = () => {
        const { t } = this.props
        const { claimsData } = this.state

        const content = claimsData.map((item, index) => {
            return <ClaimItem
                claimData={item}
                refreshClaimsData={this.getAllClaims}
                key={index}
                {...{ t }}
            />
        })

        return content
    }

    paginationClick = ( page ) => {
        this.setState({ pageIndex: page }, () => {
            this.getAllClaims()
        })
    }

    render() {
        const { t } = this.props
        const { isFetching, totalItems, pageIndex } = this.state

        const content = isFetching ? <Spinner /> : this.renderClaims()
        return (
            <div className="all-claims">
                <div className="col-md-12">
                    <Widget title={t('all.claims')}>
                        {content}
                        <Pagination
                            total={totalItems}
                            pageIndex={pageIndex}
                            onClick={this.paginationClick}
                            pageSize={PAGE_SIZE}
                        />
                    </Widget>
                </div>
                <div className="col-md-4">
                    <SiteStat />
                    <BuySellShort />
                    <Ads />
                </div>
            </div>
        )
    }
}
