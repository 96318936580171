import React, { Component, Fragment } from 'react'
import weights from '../../../files/weights.pdf'
import dimensions from '../../../files/dimensions.pdf'

export default class TooltipForDownload extends Component {
    render() {
        const { t } = this.props
        return (
            <div>
                {t('no.gabarit.description')}
                {` `}
                <a href={weights} target="_blank" rel="noopener noreferrer">«{t('table.weights.cars')}»</a>
                {` и `}
                <a href={dimensions} target="_blank" rel="noopener noreferrer">«{t('table.weights.cargos')}»</a>
                {`.`}

            </div>
        )
    }
}