import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Field } from 'redux-form'
import moment from 'moment'

import WidgetBase from '../../shared/components/widget-base'
import Banner from '../../banner'
import { BANNER_THIN_SIZE } from '../../../store/constants'
import FilterFieldRender from '../../UI/filter-field'
import Dropdown from '../../UI/dropdown'
import Checkbox from '../../UI/checkbox'
import { DatePickerField } from '../../UI/date-picker'
import SelectField from '../../UI/select'
import { scrollToElement } from '../../../store/utils'
import { focusFirstInput } from '../../shared/utility'
import dict from '../../shared/dictionaries'
import FlexBand, { FlexBandItem } from 'flexband'
import LastSearch from '../last-search'
import fetch from '../../shared/fetch'
import './styles.scss'

import { SearchFormProps, SearchFormState } from './types'
import renderNumber from '../../shared/components/render-number'

export default class extends Component<SearchFormProps, SearchFormState> {
  ref: any
  constructor(props: SearchFormProps) {
    super(props)
    this.state = {
      countryListFrom: [],
      countryListTo: [],
      oldCountryListFrom: [],
      oldCountryListTo: []
    }
    this.ref = React.createRef()
  }
  componentDidMount() {
    const { search, sortBy, todayUrlParameter, initialValues, sameCountryOnly, change, filterKey } = this.props
    change('filterKey', "auto")
    this.getCountries(filterKey)
    // focusFirstInput(this.ref)
    //sameCountryOnly.length === 0 && search({ sortBy, pageSize: 20, dateScope: todayUrlParameter, query: initialValues, sameCountryOnly })
  }

  componentDidUpdate(prevProps) {
    const { filterKey, searchType } = this.props
    if (filterKey !== prevProps.filterKey || searchType !== prevProps.searchType) {
      this.getCountries(filterKey)
    }
  }

  getCountries = (filterKey) => {
    const { searchType, langId } = this.props
    const body = JSON.stringify({
      movingType: filterKey
    })

    const input = searchType === 'cargo' ? 'CI' : 'TI'
    const output = searchType === 'cargo' ? 'CO' : 'TO'

    fetch(`/common/countries/${langId}/${input}`, {
      method: 'POST',
      body,
    }).then(response => {
      this.setState({ countryListTo: response.d })
    })

    fetch(`/common/countries/${langId}/${output}`, {
      method: 'POST',
      body,
    }).then(response => {
      this.setState({ countryListFrom: response.d })
    })

  }

  keyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 13) {
      this.props.valid &&
        this.props.handleSubmit(values => {
          this.startSearch(values, true)
        })
    }
  }

  insertSearch = query => {
    const { change } = this.props

    query = JSON.parse(query)
    const {
      countriesFrom,
      countriesTo,
      carryingCapacity = '',
      dateLoadingFrom = '',
      dateLoadingTo = '',
      fromBlockedUsers = false,
      showHidden = false,
      transportType = '',
      filterKey = 'auto',
      isAdr = false,
      isClimateControl = false,
      isOversized = false,
      isGrouping = false,
      isOnlyFromMyCountry = false,
      isOnlyProvedCompanies = false,
      includingBlocked = false,
    } = query

    change('countriesFrom', countriesFrom)
    change('countriesTo', countriesTo)
    change('carryingCapacity', carryingCapacity)
    change('dateLoadingFrom', dateLoadingFrom)
    change('dateLoadingTo', dateLoadingTo)
    change('fromBlockedUsers', fromBlockedUsers)
    change('showHidden', showHidden)
    change('transportType', transportType)
    change('filterKey', filterKey)
    change('isAdr', isAdr)
    change('isClimateControl', isClimateControl)
    change('isGrouping', isGrouping)
    change('isOversized', isOversized)
    change('isOnlyFromMyCountry', isOnlyFromMyCountry)
    change('isOnlyProvedCompanies', isOnlyProvedCompanies)
    change('includingBlocked', includingBlocked)
  }

  startSearch = (values, log: boolean, needToScroll: boolean = true) => {
    const { search, sortBy, pageSize, dateScope } = this.props
    const { oldCountryListFrom, oldCountryListTo } = this.state
    if ((values.countriesFrom?.length === 0 && oldCountryListFrom?.length > 0) ||
      (values.countriesTo?.length === 0 && oldCountryListTo?.length > 0)) {
      localStorage.setItem('autoupdate', "false")
    }
    this.setState({ oldCountryListFrom: values.countriesFrom, oldCountryListTo: values.countriesTo })

    search({ log, sortBy, pageSize, dateScope, query: values })
    needToScroll && scrollToElement('bannerRow')
  }

  render() {
    const {
      t,
      reset,
      invalid,
      submitting,
      handleSubmit,
      filterKey,
      langId,
      searchType,
      isAuthenticated
    } = this.props

    const { countryListFrom, countryListTo } = this.state

    // conditional dictionaries
    const transportTypes = dict.getTransportTypes(filterKey)(langId)
    const filterItems = dict.shippingTypes()
    const dateToday = moment().startOf('day')

    const label = filterKey !== "container" ? t('body.type') : t('cargo.container.types.simple')

    const itemFrom = { ...dict.countries(langId, searchType === 'cargo' ? 'CI' : 'TI'), items: countryListFrom }
    const itemTo = { ...dict.countries(langId, searchType === 'cargo' ? 'CO' : 'TO'), items: countryListTo }
    return (
      <FlexBand justify="space-between" wrap="wrap" style={{ width: '100%' }}>
        <FlexBandItem grow="1" className="search-form search-form-square-window">
          <div className="mobile-banner-off">
            <Banner bannerId={527} />
          </div>
          <WidgetBase
            title={searchType === 'cargo' ? t('cargo.search.header') : t('transport.search.header')}
            onKeyDown={this.keyDown}
          >
            <div data-fix-col="true" className="panel-body" ref={this.ref}>
              <Field name="filterKey" component={FilterFieldRender} items={filterItems} />

              <div className="panel-content">
                <form className="panel-form">
                  <div className="panel-content-row">
                    <div className="panel-form-row">
                      <label htmlFor="sel1">
                        {t('cargo.counrty.loading')}
                        {` `}
                        <span className="col-gray">
                          {`(${t('cargo.chooseMaxNCountries', { limit: 5 })})`}
                        </span>
                      </label>
                      <div>
                        <Field
                          name="countriesFrom"
                          component={SelectField}
                          items={itemFrom}
                          placeholder={t('all.countries')}
                          multi
                          maxOptionsToSelect={5}
                        />
                      </div>
                    </div>
                    <div className="panel-form-row">
                      <label htmlFor="sel2">
                        {t('cargo.counrty.unloading')}
                        {` `}
                        <span className="col-gray">
                          {`(${t('cargo.chooseMaxNCountries', { limit: 5 })})`}
                        </span>
                      </label>
                      <Field
                        name="countriesTo"
                        component={SelectField}
                        items={itemTo}
                        placeholder={t('all.countries')}
                        multi
                        maxOptionsToSelect={5}
                      />
                      {/* dict.countries(langId, searchType === 'cargo' ? 'CI' : 'TI') */}
                    </div>
                    <div className="panel-form-row">
                      <label htmlFor="datefrom">{t('cargo.loadingTimeframe')}</label>
                      <div className="input-daterange input-group input-inline">
                        <div className="input-append date-selector-calendar_wrapper">
                          <Field
                            name="dateLoadingFrom"
                            component={DatePickerField}
                            minDate={dateToday}
                            normalize={value => (value ? moment(value).format('YYYY-MM-DD') : null)}
                          />
                          <span className="add-on">
                            <i className="ico ico-calendar" />
                          </span>
                        </div>
                        <div className="input-icon">
                          <i className="fa fa-long-arrow-right" />
                        </div>
                        <div className="input-append date-selector-calendar_wrapper">
                          <Field
                            name="dateLoadingTo"
                            component={DatePickerField}
                            minDate={dateToday}
                            normalize={value => (value ? moment(value).format('YYYY-MM-DD') : null)}
                          />
                          <span className="add-on">
                            <i className="ico ico-calendar" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="panel-content-row">
                    <div className="panel-form-row panel-form-inline">
                      {filterKey !== "avia" && <div className="panel-form-col">
                        <label htmlFor="sel3">{label}</label>
                        <div className="select-container">
                          <Field
                            name="transportType"
                            component={Dropdown}
                            items={transportTypes}
                            emptyOption={t('all.types')}
                          />
                        </div>
                      </div>}
                      <div className="panel-form-col">
                        <label htmlFor="sel4">{t('cargo.max.weight')}</label>
                        <div className="select-container">
                          <Field
                            name="carryingCapacity"
                            component={renderNumber}
                            placeholder="00.00"
                            unit="t"
                            integerLimit={2}
                            decimalLimit={2}
                          />
                        </div>
                      </div>
                      <div className="inputs-iconed">
                        <div className="input-container">
                          <Field name="isAdr" component={Checkbox}>
                            {`ADR`}
                            <i className="fa fa-fire" />
                          </Field>
                        </div>
                        <div className="input-container">
                          <Field name="isClimateControl" component={Checkbox}>
                            {t('cargo.temperature.mode')}
                            <i className="fa fa-snowflake-o" />
                          </Field>
                        </div>
                        <div className="input-container">
                          <Field name="isGrouping" component={Checkbox}>
                            {t('cargo.grouping')}
                            <i className="fa fa-th-large" />
                          </Field>
                        </div>
                        <div className="input-container">
                          <Field name="isOversized" component={Checkbox}>
                            {t('no.gabarit')}
                            <i className="ico ico-weight" />
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="panel-content-row">
                    <div className="panel-form-row inputs-iconed">
                      <FlexBand className="checkbox-flex" wrap="wrap" direction="column">
                        <div className="panel-form-col">
                          <Field
                            name="isOnlyProvedCompanies"
                            component={Checkbox}
                            label={t('only.proved.companies')}
                            tooltip={t('text.verification')}
                          />
                        </div>
                        {isAuthenticated &&
                          <>
                            <div className="panel-form-col">
                              <Field
                                name="includingBlocked"
                                component={Checkbox}
                                label={t('including.blocked')}
                              />
                            </div>
                            <div className="panel-form-col">
                              <Field
                                name="isOnlyFromMyCountry"
                                component={Checkbox}
                                label={t('only.users.from.my.country')}
                              />
                            </div>
                          </>}
                      </FlexBand>
                    </div>

                    <div className="panel-form-row panel-button-row">
                      <button
                        className="button button-orange fl-left"
                        disabled={invalid || submitting}
                        onClick={handleSubmit(values => {
                          this.startSearch(values, true)
                        })}
                      >
                        {t('do.action')}
                      </button>
                      <Link to="#" className="link-button" onClick={reset}>
                        {t('reset')}
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </WidgetBase>
        </FlexBandItem>
        <FlexBandItem className="last-search last-search-square-window" basis="400px" grow="2">
          <LastSearch
            updateParent={() => this.forceUpdate()}
            insertSearch={this.insertSearch}
            searchType={searchType}
          />
        </FlexBandItem>
      </FlexBand>
    )
  }
}
