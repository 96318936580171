import { connect } from 'react-redux'

import { setActivePopup } from '../../../store/actions/ui'

import Component from './component'
import DropdownName from './dropdown-name'
import DropdownPanel from './dropdown-panel'
import DropdownPopover from './dropdown-popover'

export default connect(
  state => ({
    activeGuid: state.ui.activePopupGuid,
  }),
  {
    setActivePopup,
  }
)(Component)

export { DropdownName, DropdownPanel, DropdownPopover }
