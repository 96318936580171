// import _ from 'lodash'

import {
  MODALFORM_SHOW_REQUEST,
  MODALFORM_CLOSE_REQUEST,
  MODALFORM_REFRESH_LAYOUT,
} from '../actions/action-types'
import { ModalFormState } from './types'

// ----------------------------------------
// initialState
// ----------------------------------------
const initialState: ModalFormState = {
  isOpen: false,
  formLayout: undefined,
  formData: undefined,
  options: undefined,
}

// ----------------------------------------
// methods
// ----------------------------------------
const showForm = (state: ModalFormState, { formLayout, formData, options }) => {
  const newState = {
    isOpen: true,
    formLayout,
    formData,
    options,
  }

  return { ...state, ...newState }
}
const closeForm = (state: ModalFormState) => {
  return { ...state, ...{ isOpen: false } }
}
const refreshLayout = (state: ModalFormState, action) => {
  return { ...state, ...{ formLayout: action.formLayout } }
}

// ----------------------------------------
// reducer
// ----------------------------------------
export default (state = initialState, action) => {
  switch (action.type) {
    case MODALFORM_SHOW_REQUEST:
      return showForm(state, action)
    case MODALFORM_CLOSE_REQUEST:
      return closeForm(state)
    case MODALFORM_REFRESH_LAYOUT:
      return refreshLayout(state, action)
    default:
      return state
  }
}
