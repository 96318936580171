export const int15 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Clasificarea mărfurilor periculoase și a caracteristicilor acestora</h4><p></p>

<p style="text-indent:2em;">Bunurile periculoase includ pe cele care provoacă daune materiale (daune de marfă, incendii, distrugerea structurilor, mașinilor etc.)
 în timpul transportului, depozitării, manipulării și manipulării mărfurilor, cauzei rănirii, bolii, vătămării sau morții, dăunează
 mediului. Periculoase includ mărfuri cu proprietăți precum inflamabilitatea, explozivitatea, radioactivitatea, toxicitatea (toxicitatea)
 și nocivitatea.</p><br>

<p style="text-indent:2em;">Pentru a lucra cu mărfuri periculoase există o anumită reglementare, care este emisă de ministerele și departamentele de transport. 
Ambalajul în care sunt transportate mărfurile periculoase trebuie să fie de tip omologat, în 
conformitate cu specificațiile.</p>

<p style="text-indent:2em;"></p><h6>Luând în considerare gradul de pericol care apare atunci când se lucrează cu bunuri
 periculoase, ele pot fi împărțite în 4 grupe:</h6><p></p>

<p style="text-indent:2em;"></p><h6>Pe baza semnelor comune, a naturii pericolului, a condițiilor de depozitare și de transport, mărfurile periculoase pot fi împărțite
 în mai multe clase:</h6><p></p>

<p style="text-indent:2em;">Gradul 1 - explozivi și articole. Acestea includ pirotehnica, explozivi, muniții, muniții, praf de pușcă etc. Manipulați explozivii 
cu precauție extremă, deoarece reacționează la șocuri, șocuri, creșteri de temperatură și scântei;</p><br>

<p style="text-indent:2em;">Gradul 2 - gaze comprimate, lichefiate și dizolvate sub presiune. De exemplu, azot, propan, oxigen, amoniac, clor, etc. Principalul 
pericol este că gazul se află într-un vas sub presiune. În plus, aceste gaze au alte proprietăți periculoase, pot fi asfixioase, toxice,
 oxidante, inflamabile;</p><br>

<p>Gradul 3 - lichide inflamabile. Astfel de lichide pot include ulei, benzină, ulei, alcool, kerosen, motorină etc.</p><br>

<p style="text-indent:2em;">Gradul 4 - substanțe și materiale inflamabile. Aceste substanțe includ cărbune, sulf, sodiu, potasiu, hârtie prelucrată și altele 
asemenea.</p><br>

<p style="text-indent:2em;">Substanțele care aparțin acestei clase se pot aprinde cu ușurință de la sursele externe de aprindere în timpul transportului, în plus,
 frecarea, încălzirea, absorbția de umiditate etc. pot provoca aprindere.</p><br>

<p style="text-indent:2em;">Gradul 5 - substanțe oxidante și peroxizi organici. De exemplu, îngrășăminte pe bază de azotați, peroxid, amoniu, azotat de amoniu, 
cloruri și altele asemenea. Astfel de substanțe sunt periculoase deoarece, în anumite condiții sau atunci când sunt amestecate cu 
alte substanțe, se pot auto-aprinde și chiar explodează;</p><br>

<p style="text-indent:2em;">Clasa 6 - substanțe toxice (toxice). Substanțele otrăvitoare includ cianurile, medicamentele, pesticidele, arsenicul etc. Substanțele
 din această clasă, atunci când sunt ingerate, pe mucoasă sau pe piele, pot provoca otrăviri, boli sau deces;</p><br>

<p style="text-indent:2em;">Gradul 7 - substanțe radioactive și infecțioase. De exemplu, uraniul, materialele nucleare, materialele radioactive etc. Poate provoca
 radiații radioactive în diferite forme;</p><br>

<p style="text-indent:2em;">Gradul 8 - substanțe corozive și corozive. Acestea includ mercurul, vopselele, alcalii, acidul etc. Astfel de substanțe, dacă sunt
 scurgeri sau vărsate, pot deteriora sau distruge alte mărfuri sau vehicule;</p><br>

<p>Gradul 9 - alte substanțe periculoase.</p><br>

<p><a href="http://materiale.pvgazeta.info/utilizator-174/clasificarea-marfurilor-periculoase-si-a.html">Sursa</a></p>

</div>
`