import jwtDecode from 'jwt-decode'
import pick from 'lodash/pick'
import window from 'global/window'
import { toastr } from 'react-redux-toastr'
import fetch from '../components/shared/fetch'
import { getClientName } from '../components/shared/utils/browser'
import moment from 'moment'
import dict from '../components/shared/dictionaries'
import { IReduxStorage } from './reducers/types'
import { dateFormatter } from "../components/shared/utils"

const { localStorage } = window

export function getSessionId() {
  return localStorage.getItem('sessionId') || undefined
}

export function getBaseProps(state: IReduxStorage) {
  return {
    lang: state.ui.currentLanguage,
    socketId: window.socket.id,
    sessionId: getSessionId(),

    clientId: state.auth.clientId,
    clientName: getClientName(),
  }
}

export const ITEMS_ON_PAGE = 5
export const TEXT_LENGHT_IN_ONE_LINE_WITH_BREAK_LINE = 90

export const addScrollBar = () => {
  const html = document.getElementById('html')
  html.classList.remove('open-modal')
}

export const getGlobalTitle = (lang) => {
  let defaultTitle = ""

    switch (lang) {
      case "ro":
        defaultTitle = "TRANSPORT.MD - Sistemul informational de transport international"
        break;
      case "en":
        defaultTitle = "TRANSPORT.MD - International transport information system"
        break;
      default:
        defaultTitle = "TRANSPORT.MD - Информационная система международных перевозок"
        break;
    }

    return defaultTitle
}


export const pricesValueToText = (value, t) => {
  let text
  switch (value) {
    case 0:
      text = "-"; break;
    case 1:
      text = "+"; break;
    case 2:
      text = t('price.to.text.2'); break;
    case 3:
      text = t('price.to.text.3'); break;
    case 4:
      text = t('price.to.text.4'); break;
    case 5:
      text = t('price.to.text.5'); break;
    case 6:
      text = t('price.to.text.6'); break;
    case 7:
      text = t('price.to.text.7'); break;
    case 8:
      text = t('price.to.text.8'); break;
    case 9:
      text = t('price.to.text.9'); break;

    default:
      text = t('no.data')
      break;
  }

  return text
}

export const hideScrollBar = () => {
  const html = document.getElementById('html')
  html.classList.add('open-modal')
}


export const fullTextLengthWithBreak = (message) => {
  return (message.replace(/[^\n]/g, "").length) * TEXT_LENGHT_IN_ONE_LINE_WITH_BREAK_LINE
}

export const getLanguageLowercaseName = (langId) => {
  let lang
  switch (langId) {
    case 1:
      lang = "ru"
      break;
    case 2:
      lang = "en"
      break;
    default:
      lang = "ro"
      break;
  }

  return lang
}

export function getUrlParamsString(params: any) {
  const queryString = new URLSearchParams()

  for (const i in params) {
    queryString.append(i, params[i])
  }

  return queryString.toString()
}

export function getJwt(accessToken: string, defaultValue?: string) {
  return accessToken
    ? pick(jwtDecode(accessToken), [
      // custom props
      'username',
      'userId',
      'email',
      'permissions',
      // system props
      'iat',
      'exp',
      'iss',
    ])
    : defaultValue
}

const DELAY = 60

export const getDateCreateInMinutes = (date) => {
  return new Date(date).getTime() / (1000 * 60) 
}

export function canDo(permissions: string[], moduleName: string, action?: string) {
  if (!permissions || !moduleName) {
    return false
  }
  action = !action ? '' : `:${action}`
  return permissions.includes('admin') || permissions.includes(`${moduleName}${action}`)
}

export function oops(error, type: string) {
  return dispatch => {
    toastr.error(error.message)
    dispatch({ type, error })
  }
}

export async function queryInWords(query: any, langId: number) {
  const {
    countriesFrom = [],
    countriesTo = [],
    dateLoadingFrom = null,
    dateLoadingTo = null,

    transportType = null,
    carryingCapacity = null,

    isAdr = null,
    isClimateControl = null,
    isGrouping = null,
    isOversized = null,

    isOnlyProvedCompanies = null,
    includingBlocked = null,
    isOnlyFromMyCountry = null,
    filterKey = "",
  } = query

  const countriesDict = dict.countries(langId)
  const transportTypes = dict.getTransportTypes(filterKey)(langId)

  const transportTypeName = (transportTypes.items || []).find(item => item.value === Number(transportType))?.name

  let countries: any[] = []

  if (countriesDict.loaded) {
    countries = countriesDict.items
  } else {
    const handledCountries = await countriesDict.handler

    countries = handledCountries.items
  }

  // helper functions
  const getCountryNames = ids => {
    if (ids.length) {
      return ids
        .map(countryId => {
          const c = countries.find(x => x.value === Number(countryId))
          return c && c.name
        })
        .join(', ')
    }
    return window.t('all.countries')
  }

  const arr = []

  arr.push(getCountryNames(countriesFrom))
  arr.push(' - ')
  arr.push(getCountryNames(countriesTo))
  arr.push(' ')

  arr.push(dateLoadingFrom ? dateFormatter(dateLoadingFrom) : '')
  dateLoadingTo && arr.push(' - ')
  arr.push(dateLoadingTo ? dateFormatter(dateLoadingTo) : '')
  arr.push(' ')

  transportTypeName && transportType && arr.push(', ' + transportTypeName)

  carryingCapacity && arr.push(', ' + carryingCapacity + 't ')

  isAdr && arr.push(', ADR')
  isGrouping && arr.push(`, ${window.t('cargo.grouping')}`)
  isClimateControl && arr.push(`, ${window.t('cargo.temperature.mode')}`)
  isOversized && arr.push(`, ${window.t('cargo.oversize')}`)
  isOnlyProvedCompanies && arr.push(`, ${window.t('only.from.verificated')}`)
  includingBlocked && arr.push(`, ${window.t('including.blocked')}`)
  isOnlyFromMyCountry && arr.push(`, ${window.t('only.users.from.my.country')}`)

  return arr.join('')
}

export const scrollToElement = (elem, marginTop = 0) => {
  const element = document.getElementById(elem)
  const bodyRect = document.body.getBoundingClientRect()
  const elemRect = element.getBoundingClientRect()
  const needScrollToMyDivInPX = elemRect.top - bodyRect.top

  window.scrollTo({
    top: needScrollToMyDivInPX - marginTop,
    behavior: 'smooth',
  })
}

// ##############################################################################
export const fetchItems = (url: string, props) =>
  fetch(url, {
    method: 'POST',
    body: JSON.stringify(
      pick(props, [
        'langId',
        'languages',
        'pageIndex',
        'pageSize',
        'filterKey',
        'searchText',
        'includeDeleted',
        'includeNonregistered',
        'country',
        'businessType',
        'sortBy',
        'verifiedOnly',
        'blackListOnly',
        'closedOnly',
        'showExpiredRoleOnly',
        'showGoldLightOnly',
        'showGoldOnly',
        'showFreeOnly',
        'showInBlockOnly',
        'showUnregistered'
      ])
    ),
  })

export const fetchNews = props => fetchItems('/news/list', props)
export const fetchCompanies = props => fetchItems('/company/list', props)

export const canAdd = (type: string) => {

  let response
  const body = JSON.stringify({
    type
  })

  fetch(`/auth/can-add`, {
    method: 'POST',
    body,
  }).then((res) => {


    // Promise.resolve(res).then(res=> {
    response = res.allow
    // })

  })

  return response
}