import React from 'react'
import { Link, Redirect } from 'react-router-dom'

import { Field } from 'redux-form'

import { ModalConsumer } from '../../../context/modal-context.tsx'
import WidgetBase from '../../shared/components/widget-base'
import FlexBand from 'flexband'
import { required } from '../../shared/form-validators'
import config from '../../../config'
import Dropdown from '../../UI/dropdown'
import dict from '../../shared/dictionaries'
import FieldBase from '../../UI/field-base'
import ContactDetails from '../contact-details'
import AlertBannerProps from '../../UI/alert-banner'
import SelectField from '../../UI/select'
import CloseCompanyModal from '../modal-close-company'
import RemoveCompanyVerificationModal from '../modal-remove-verification'
import fetch from '../../shared/fetch'
import { canDo } from '../../../store/utils'
import Captcha from '../../captcha'
import { DESCRIPTION_MAX_LENGTH } from '../constants'
import './styles.scss'
import FileUploadField from '../../UI/file-upload-field'
import FineUploaderTraditional from 'fine-uploader-wrappers'
import classnames from 'classnames'
import TooltipWrapper from '../../UI/popover-wrapper'
import { confirmAlert } from 'react-confirm-alert'
import { directorId } from '../../shared/utils'

const uploader = new FineUploaderTraditional({
  options: {
    core: {
      multiple: false,
    },
    cors: {
      expected: true,
      sendCredentials: true,
    },
    deleteFile: {
      enabled: true,
      endpoint: `${config.apiUrl}/common/upload`,
    },
    request: {
      endpoint: `${config.apiUrl}/common/upload`,
    },
  },
})
// const folder = `company/${today}`
const STYLE_BASE = 'company-edit_'
const yearsFounded = dict.rangeYears(1900).reverse()

export default class CompanyEdit extends React.Component {
  state = {
    files: [],
    contactsError: false,
    companyAdmin: '',
    companyAdminId: '',
    additionalInfo: '',
    companyVerificationInfo: {},
    employeesList: {
      items: [],
      total: 0,
    },
    redirect: false,
    downloadedLicenseOne: '',
    downloadedLicenseTwo: '',
    downloadedLicenseTree: '',
    currentDirector: {
      userId: 0,
      userName: '',
      userLastName: '',
      email: '',
      phone1: '',
      phone2: '',
      skype: '',
      viber: '',
    },
    inBlackList: false,
    captchaVerified: false,
  }

  componentDidMount() {
    const {
      itemId,
      profile: { firstName, lastName },
      permissions,
    } = this.props
    if (canDo(permissions, 'captcha', 'skip')) {
      this.setState({ captchaVerified: true })
    }

    uploader.on('statusChange', (id, oldStatus, newStatus) => {
      const { files: oldFiles } = this.state
      const files = [...oldFiles]

      if (newStatus === 'submitted') {
        const item = {
          id,
          name: uploader.methods.getName(id),
          size: uploader.methods.getSize(id),
          uuid: uploader.methods.getUuid(id),
        }
        this.setState({ files: [item] })
      } else if (['canceled', 'deleted'].includes(newStatus)) {
        const indexToRemove = files.findIndex(f => f.id === id)

        files.splice(indexToRemove, 1)
        this.setState({ files })
      }
    })

    if (itemId) {
      this.initializeFormValues(itemId)
      fetch(`/company/${itemId}/verification`)
      .then(companyVerificationInfo => {
        this.setState({ companyVerificationInfo: companyVerificationInfo.data })
      })
      .catch(err => {
        console.log(err)
      })

      fetch(`/company/employees/${itemId}/2/0/1000`).then(res => {
        if (res.ok) {
          const currentDirector = res.employees.find(
            employeItem => employeItem.positionInCompany === directorId
          )
          this.setState({
            employeesList: {
              items: res.employees,
              total: res.employees[0] ? res.employees[0].total : null,
            },
            currentDirector,
          })
        }
      })
    } else {
      this.setState({ companyAdmin: `${firstName} ${lastName}` })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      profile,
      profile: { isCompanyAdmin, accessStatus },
      itemId,
    } = this.props
    const isAdmin = accessStatus === 'Admin' || false

    if (
      !(isCompanyAdmin !== prevProps.isCompanyAdmin && isCompanyAdmin > 0) &&
      !isAdmin &&
      itemId
    ) {
      this.setState({ redirect: true })
    }
  }

  initializeFormValues = async itemId => {
    const { langId, initialize } = this.props

    const {
      fiscalCode,
      phone1,
      phone2,
      fax1,
      address,
      website,
      skype,
      viber,
      claimsCount,
      ownerUsername,
      ownerLastName,
      email1: email,
      companyName: name,
      countryId: country,
      ownershipTypeId: propertyType,
      businessTypesId: businessType,
      positionInCompany,
      yearCreated: formationYear,
      additionalInfo: description,
      adminUserId: selectedCompanyAdmin,
      ownerUserId,
      registrationCertificateDoc,
      companyLicensesDocs,
    } = await fetch(`/company/item/${itemId}/${langId}`)

    const currentAdminId = selectedCompanyAdmin ? selectedCompanyAdmin : ownerUserId

    const licenseDocs = []
    if (companyLicensesDocs?.length > 0) {
      for (let i = 0; i < 3; i++) {
        if (companyLicensesDocs.length >= i) {
          licenseDocs.push(companyLicensesDocs[i])
        }
      }
    }

    initialize({
      company: {
        name,
        country,
        propertyType,
        businessType,
        positionInCompany,
        formationYear,
        fiscalCode,
        description,
        ownerUsername,
        ownerLastName,
        selectedCompanyAdmin: selectedCompanyAdmin ? selectedCompanyAdmin : ownerUserId,
        registrationCertificateDoc,
        licenseOneDoc: licenseDocs?.length > 0 ? licenseDocs[0] : null,
        licenseTwoDoc: licenseDocs?.length > 1 ? licenseDocs[1] : null,
        licenseThreeDoc: licenseDocs?.length > 2 ? licenseDocs[2] : null,
        headquarters: {
          phone1,
          phone2,
          fax1,
          address,
          skype,
          viber,
          email,
          website,
        },
      },
    })
    this.setState({
      companyAdmin: `${ownerUsername} ${ownerLastName}`,
      companyAdminId: currentAdminId,
      additionalInfo: description,
      inBlackList: claimsCount > 0,
    })
  }

  showCloseCompanyModal = ({ showModalComponent, clearModal }) => {
    const { t, itemId } = this.props
    const { companyVerificationInfo } = this.state

    showModalComponent(
      <CloseCompanyModal
        itemId={itemId}
        onCancel={clearModal}
        verificationId={companyVerificationInfo?.id || 0}
      />,
      {
        title: t('company.title.close'),
        className: `${STYLE_BASE}modal`,
        backdrop: true,
      }
    )
  }

  showRemoveVerificatedStatusModal = ({ showModalComponent, clearModal }) => {
    const { t, itemId } = this.props
    const { companyVerificationInfo } = this.state

    showModalComponent(
      <RemoveCompanyVerificationModal
        itemId={itemId}
        onCancel={clearModal}
        companyVerificationInfo={companyVerificationInfo}
      />,
      {
        title: t('remove.company.verification'),
        className: `${STYLE_BASE}modal`,
        backdrop: true,
      }
    )
  }

  drawHeaderMenu = () => {
    const { t, itemId, permissions } = this.props
    const { companyVerificationInfo } = this.state
    const isAdmin = canDo(permissions, 'admin')
    return itemId ? (
      <div className="link-right">
        <ModalConsumer>
          {modalConsumer => (
            <>
              {companyVerificationInfo?.isValid === 1 && isAdmin && (
                <Link
                  to="#"
                  data-popup-link="close-company"
                  style={{ marginRight: 10 }}
                  onClick={() => this.showRemoveVerificatedStatusModal(modalConsumer)}
                >
                  <i className="fa fa-close col-red" />
                  {t('remove.company.verification')}
                </Link>
              )}
              <Link
                to="#"
                data-popup-link="close-company"
                onClick={() => this.showCloseCompanyModal(modalConsumer)}
              >
                <i className="fa fa-close col-red" />
                {t('company.button.close')}
              </Link>
            </>
          )}
        </ModalConsumer>
      </div>
    ) : null
  }

  onContactsErrorChange = status => {
    this.setState({ contactsError: status })
  }

  renderDescriptionLength = additionalInfo => {
    const checkIfIsErrorStyle = additionalInfo.length >= DESCRIPTION_MAX_LENGTH ? 'error-text' : ''
    const redTextError = additionalInfo ? checkIfIsErrorStyle : ''
    return (
      <div>
        <b className={redTextError}>{additionalInfo.length}</b>/{DESCRIPTION_MAX_LENGTH}
      </div>
    )
  }

  renderSelectOptions = () => {
    const { employeesList } = this.state

    const renderOptions = [
      ...employeesList.items.map(({ userName, userLastName, userId }) => ({
        value: userId,
        name: `${userName} ${userLastName}`,
      })),
    ]

    return renderOptions
  }

  submitForm = () => {
    const {
      handleSubmit,
      enteredValues: {
        company: { selectedCompanyAdmin },
      },
      profile: { isCompanyAdmin, companyId },
      logout,
      permissions,
      itemId,
      t,
    } = this.props
    const { companyAdminId, currentDirector } = this.state

    const showBanner = this.isShowBanner()

    const isAdmin = canDo(permissions, 'admin')
    const resetData = showBanner && !!currentDirector?.userId

    if ((isAdmin && !isCompanyAdmin) || !itemId) {
      resetData && this.resetPrevDirectorPosition()
      handleSubmit()
    } else if (Number(selectedCompanyAdmin) !== Number(companyAdminId)) {
      confirmAlert({
        title: t('company.change.company.administrator'),
        message: alert,
        buttons: [
          {
            label: 'OK',
            onClick: () => {
              //resetData && this.resetPrevDirectorPosition()
              handleSubmit()
              logout()
            },
          },
          {
            label: t('button.close'),
            onClick: () => this.setState({ redirect: false }),
          },
        ],
        onClickOutside: () => this.setState({ redirect: false }),
        onKeypressEscape: () => this.setState({ redirect: false }),
      })
    } else {
      resetData && this.resetPrevDirectorPosition()
      handleSubmit()
    }
  }

  resetPrevDirectorPosition = () => {
    const {
      profile: { companyId },
      history,
    } = this.props
    const { currentDirector } = this.state
    const body = JSON.stringify({
      email: currentDirector.email,
      positionInCompany: 462, //by default set prev director position = Manager / Менеджер
      phone1: currentDirector.phone1,
      phone2: currentDirector.phone2,
      skype: currentDirector.skype,
      viber: currentDirector.viber,
      employeId: currentDirector.userId,
      companyId,
    })

    fetch(`/company/${companyId}/employe-contacts`, {
      method: 'PUT',
      body,
    }).then(_ => {
      history.push(`/company/employee-list`)
    })
  }

  isShowBanner = () => {
    const {
      t,
      enteredValues: {
        company: { positionInCompany, selectedCompanyAdmin },
      },
    } = this.props
    const { currentDirector } = this.state
    const showBanner =
      positionInCompany === directorId &&
      !!currentDirector?.userId &&
      currentDirector?.userId !== selectedCompanyAdmin

    return showBanner
  }

  renderAtentionAlert = () => {
    const { t } = this.props
    const { currentDirector } = this.state

    const showBanner = this.isShowBanner()
    const employeeName = `${currentDirector?.userName} ${currentDirector?.userLastName}`

    return (
      showBanner && <AlertBannerProps text={t('already.is.company.director', { employeeName })} />
    )
  }

  renderTooltip = disableField => {
    const { inBlackList } = this.state
    return inBlackList ? (
      <div className="tooltip-verif-company">
        <TooltipWrapper text={t('company.in.black.list')} hover>
          <i className="fa fa-info-circle col-blue" />
        </TooltipWrapper>
      </div>
    ) : (
      disableField && (
        <div className="tooltip-verif-company">
          <TooltipWrapper text={t('verification.in.progress')} hover>
            <i className="fa fa-info-circle col-blue" />
          </TooltipWrapper>
        </div>
      )
    )
  }

  render() {
    const {
      t,
      langId,
      itemId,
      invalid,
      pristine,
      profile,
      profile: { companyId, isCompanyAdmin, firstName, lastName },
      permissions,
      enteredValues,
    } = this.props
    const {
      additionalInfo,
      employeesList,
      redirect,
      currentDirector,
      inBlackList,
      companyVerificationInfo,
      captchaVerified,
    } = this.state
    const canEdit = canDo(permissions, 'company', 'edit')
    const canAdd = canDo(permissions, 'company', 'add')
    const isAdmin = canDo(permissions, 'admin')

    if ((itemId && !isAdmin && !isCompanyAdmin && !canEdit) || (!itemId && !canAdd)) {
      return <Redirect to="/" />
    }

    const showCaptcha = !canDo(permissions, 'captcha', 'skip')

    const disabled = pristine || invalid || this.state.contactsError
    const companyVerificationInProgress =
      companyVerificationInfo?.isValid === 0 && !companyVerificationInfo?.verifiedAt
    const companyVerificated = companyVerificationInfo?.isValid > 0
    const editForAdmin = isAdmin ? false : inBlackList
    const disableField = companyVerificated || companyVerificationInProgress

    const employeeData = []
    if (employeesList.items && employeesList.items.length > 0) {
      employeesList.items.forEach(item => {
        const { userName, userLastName, userId } = item
        employeeData.push({ value: userId, name: `${userName} ${userLastName}` })
      })
    }

    const folder = `company/${companyId}`

    const disableSelectEmployee = employeesList.items[0]?.total < 1 || !itemId
    let showBanner = false
    if (enteredValues?.company?.selectedCompanyAdmin) {
      const {
        company: { positionInCompany, selectedCompanyAdmin },
      } = enteredValues
      showBanner =
        positionInCompany === directorId &&
        !!currentDirector?.userId &&
        currentDirector?.userId !== selectedCompanyAdmin
    }

    return (
      <WidgetBase
        title={itemId ? t('company.edit') : t('company.create')}
        headerMenu={this.drawHeaderMenu}
        className="company-profile"
      >
        {redirect && <Redirect to="/" />}
        <form>
          <div className="panel-body add-edit-company-form">
            <div className="panel-content">
              <div className="panel-form panel-form-simple">
                <div className="panel-content-row">
                  <div className="panel-form-row">
                    <div className="row">
                      <div className="col-md-4 col-lg-5">
                        <h5 className="title-gray">{t('company.info')}</h5>
                      </div>
                      <div className="col-md-12 col-lg-10">
                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <label htmlFor="t2" className="required">
                              {this.renderTooltip(disableField)}
                              {t('company.name')}
                            </label>
                            <Field
                              name="company.name"
                              type="text"
                              disabled={disableField || editForAdmin}
                              component={FieldBase}
                              placeholder={t('company.name')}
                              validate={[required]}
                              maxLength={60}
                              className={classnames({
                                'cursor-disabled': disableField || editForAdmin,
                              })}
                              // errorPosition={FORM_FIELD_ERROR_POSITION_TOP}
                            />
                          </div>
                          <div className="col-sm-8">
                            <div className="meta-aligned">
                              <span className="meta-gray">{t('company.as.in.certificate')}</span>
                            </div>
                          </div>
                        </div>
                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <label htmlFor="t2" className="required">
                              {this.renderTooltip(disableField)}
                              {t('property.type')}
                            </label>
                            <Field
                              name="company.propertyType"
                              component={Dropdown}
                              disabled={disableField || editForAdmin}
                              items={dict.ownershipTypes(langId)}
                              validate={required}
                              className={classnames({
                                'cursor-disabled': disableField || editForAdmin,
                              })}
                            />
                          </div>
                        </div>
                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <label className="required">
                              {this.renderTooltip(disableField)}
                              {t('country')}
                            </label>
                            <Field
                              name="company.country"
                              component={Dropdown}
                              items={dict.countries(langId)}
                              validate={required}
                              disabled={disableField || editForAdmin}
                              className={classnames({
                                'cursor-disabled': disableField || editForAdmin,
                              })}
                            />
                          </div>
                        </div>
                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <label htmlFor="t4" className="required">
                              {t('business.type.limit.5')}
                            </label>
                            <Field
                              name="company.businessType"
                              component={SelectField}
                              items={dict.businessTypes(langId)}
                              placeholder={`${t('choose')}...`}
                              multi
                              validate={required}
                              maxOptionsToSelect={5}
                              className={classnames({
                                'cursor-pointer': true,
                              })}
                            />
                          </div>
                        </div>
                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <label htmlFor="t5">{t('director.administrator')}</label>
                            <div
                              className="chosen-container chosen-container-single chosen-container-single-nosearch"
                              style={{ width: '100%' }}
                              title=""
                              id="t5_chosen"
                            >
                              {employeesList.items.length === 0 ? (
                                <Link className="chosen-single read-only-input" to="#">
                                  <span>
                                    {firstName}
                                    {lastName}
                                  </span>
                                  <div>
                                    <b />
                                  </div>
                                </Link>
                              ) : (
                                <Field
                                  name="company.selectedCompanyAdmin"
                                  component={Dropdown}
                                  items={(employeesList.items && this.renderSelectOptions()) || []}
                                  placeholder={`${t('choose')}...`}
                                  disabled={disableSelectEmployee}
                                />
                              )}

                              {/* <div className="chosen-drop">
                                <div className="chosen-search">
                                  <input type="text" autoComplete="off" readOnly="" />
                                </div>
                                <ul className="chosen-results" />
                              </div> */}
                            </div>
                          </div>
                          {/* <div className="col-sm-8">
                            <div className="meta-aligned">
                              <Link to="#">{t('company.add.users')}</Link>
                            </div>
                          </div> */}
                        </div>

                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <label htmlFor="t4a">{t('company.position.in.company')}</label>
                            <Field
                              name="company.positionInCompany"
                              component={Dropdown}
                              items={dict.positionsInCompany(langId)}
                              placeholder={`${t('choose')}...`}
                              //validate={required}
                              disabled
                              className={classnames({
                                'cursor-pointer': true,
                                'cursor-disabled': disableField || editForAdmin,
                              })}
                            />
                            {showBanner && this.renderAtentionAlert()}
                          </div>
                        </div>

                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <div className="row">
                              <div className="col-sm-6">
                                <label htmlFor="t6" className="required">
                                  {this.renderTooltip(disableField)}
                                  {t('foundation.year')}
                                </label>
                                <Field
                                  name="company.formationYear"
                                  component={Dropdown}
                                  items={yearsFounded}
                                  validate={required}
                                  disabled={disableField || editForAdmin}
                                  className={classnames({
                                    'cursor-disabled': disableField || editForAdmin,
                                  })}
                                />
                              </div>
                              <div className="col-sm-10">
                                <label htmlFor="t6" className="required">
                                  {this.renderTooltip(disableField)}
                                  {t('fiscal.code')}
                                </label>
                                <Field
                                  name="company.fiscalCode"
                                  type="text"
                                  disabled={disableField || editForAdmin}
                                  component={FieldBase}
                                  placeholder={t('fiscal.code')}
                                  validate={required}
                                  className={classnames({
                                    'cursor-disabled': disableField || editForAdmin,
                                  })}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            <label htmlFor="t8">
                              {t('description')} ({t('optional')})
                            </label>
                            <Field
                              name="company.description"
                              component="textarea"
                              placeholder={t('character.limit.1000')}
                              maxLength={DESCRIPTION_MAX_LENGTH}
                              onChange={e => this.setState({ additionalInfo: e.target.value })}
                            />
                            <span className="company-description-length">
                              {additionalInfo
                                ? this.renderDescriptionLength(additionalInfo)
                                : '0/' + DESCRIPTION_MAX_LENGTH}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel-content-row">
                  <div className="panel-form-row">
                    <div className="row">
                      <div className="col-md-4 col-lg-5">
                        <h5 className="title-gray">{t('contact.details')}</h5>
                      </div>
                      <ContactDetails
                        className="col-md-12 col-lg-10"
                        fieldNamePhone1="company.headquarters.phone1"
                        fieldNamePhone2="company.headquarters.phone2"
                        fieldNameFax="company.headquarters.fax1"
                        fieldNameEmail="company.headquarters.email"
                        fieldNameSkype="company.headquarters.skype"
                        fieldNameViber="company.headquarters.viber"
                        fieldNameAddress="company.headquarters.address"
                        fieldNameWebSite="company.headquarters.website"
                        onFormatErrorChange={this.onContactsErrorChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="panel-content-row">
                  <div className="panel-form-row">
                    <div className="row">
                      <div className="col-md-4 col-lg-5">
                        <h5 className="title-gray">{t('company.documents')}</h5>
                        <p className="text-error">
                          <i className="fa fa-exclamation-triangle" />
                          <span>{t('attention')}</span>
                          <div className="atention-text">{t('atention.company.uploads.files')}</div>
                        </p>
                      </div>
                      <div className="col-md-12 col-lg-10">
                        <div className="panel-form-row row">
                          <label htmlFor="t23">{t('company.registration.certificate')}</label>
                          <FlexBand>
                            <div className="upload-container">
                              <div>
                                <Field
                                  name="company.registrationCertificateDoc"
                                  component={FileUploadField}
                                  className="photo-block photo-rounded"
                                  folder={folder}
                                  allowedExtensions={['pdf', 'jpeg', 'jpg', 'png', 'gif']}
                                />
                              </div>
                              <div>
                                <FlexBand
                                  justify="center"
                                  direction="column"
                                  style={{ height: '100%' }}
                                >
                                  <span className="meta-gray">{t('format.images')}</span>
                                  <span className="meta-gray">{t('max.size')} 5mb; </span>
                                </FlexBand>
                              </div>
                            </div>
                          </FlexBand>
                        </div>

                        <div className="panel-form-row row">
                          <label>{t('company.registration.licenses')}</label>
                          <FlexBand>
                            <div className="upload-container">
                              <div className="upload-children">
                                <Field
                                  name="company.licenseOneDoc"
                                  component={FileUploadField}
                                  className="photo-block photo-rounded"
                                  folder={folder}
                                  allowedExtensions={['pdf', 'jpeg', 'jpg', 'png', 'gif']}
                                />
                                <Field
                                  name="company.licenseTwoDoc"
                                  component={FileUploadField}
                                  className="photo-block photo-rounded"
                                  folder={folder}
                                  allowedExtensions={['pdf', 'jpeg', 'jpg', 'png', 'gif']}
                                />
                                <Field
                                  name="company.licenseThreeDoc"
                                  component={FileUploadField}
                                  className="photo-block photo-rounded"
                                  folder={folder}
                                  allowedExtensions={['pdf', 'jpeg', 'jpg', 'png', 'gif']}
                                />
                              </div>

                              <div>
                                <FlexBand
                                  justify="center"
                                  direction="column"
                                  style={{ height: '100%' }}
                                >
                                  <span className="meta-gray">{t('format.images')}</span>
                                  <span className="meta-gray">{t('max.size')} 5mb; </span>
                                </FlexBand>
                              </div>
                            </div>
                          </FlexBand>
                        </div>

                        <div className="panel-form-row row">
                          <div className="col-sm-8">
                            {disabled && !itemId && (
                              <AlertBannerProps
                                text={t('validation.not-all-required-fields-entered')}
                              />
                            )}
                          </div>
                        </div>

                        <div className="panel-form-row row">
                          {companyVerificationInProgress && (
                            <AlertBannerProps text={t('verification.was.sended')} />
                          )}

                          {showCaptcha && (
                            <Captcha
                              verifyCallback={() => {
                                this.setState({ captchaVerified: true })
                              }}
                              expiredCallback={() => this.setState({ captchaVerified: false })}
                            />
                          )}

                          <div className="col-sm-4" style={{ paddingLeft: 0, marginTop: 10 }}>
                            {profile.isReregistered === 'N' ? (
                              <>
                                <TooltipWrapper text={t('you.have.not.yet.re.registered')} hover>
                                  <button
                                    disabled={true}
                                    type="button"
                                    className="button button-orange button-block"
                                  >
                                    {t('button.save')}
                                  </button>
                                </TooltipWrapper>
                              </>
                            ) : (
                              <button
                                disabled={
                                  disabled || companyVerificationInProgress || !captchaVerified
                                }
                                type="button"
                                onClick={() => this.submitForm()}
                                className="button button-orange button-block"
                              >
                                {t('button.save')}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </WidgetBase>
    )
  }
}
