import baseConfig from './base'

let apiUrl = 'https://api.geotrans.ro';
if (window.location.origin.includes('localhost')) {
  apiUrl = 'http://127.0.0.1:7000';
} else if (window.location.origin.includes('hosting.netinfo.md')) {
  apiUrl = 'http://api.transport.hosting.netinfo.md';
} else if (window.location.origin.includes('transport.netinfo.md')) {
  apiUrl = 'https://api.transport.netinfo.md';
} else if (window.location.origin.includes('geotrans.ro')) {
  apiUrl = 'https://api.geotrans.ro';
} else if (window.location.origin.includes('geotrans.md')) {
  apiUrl = 'https://api.geotrans.md';
} else if (window.location.origin.includes('transport.md')) {
  apiUrl = 'https://api.transport.md';
}

const config = {
  appEnv: 'dist',
  apiUrl: `${apiUrl}/api`,
  ioUrl: apiUrl,
  sentry: false,
  piwik: false,
  userInactiveMinutes: 15,
  supportTelephone: '',
  supportEmail: 'ranisov@netinfo.md',
}

export default Object.freeze(Object.assign({}, baseConfig, config))
