import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './cookie-modal.scss'

interface CookieModalProps extends ComponentBaseProps {
  setCookie: any
}
export default class CookieModal extends Component<CookieModalProps> {
  render() {
    const { setCookie, t } = this.props
    return (
      <div className="coockie-modal-contaner">
        <div className="coockie-content-container">
          <h1>{t('cookie.modal.title')}</h1>
          <button type="button" className="button button-orange" onClick={setCookie}>
            {t('cookie.modal.approve.btn')}
          </button>
          <Link to="/about-us/cookies" className="button button-orange button-blue">
            {' '}
            {t('cookie.modal.details.btn')}{' '}
          </Link>
          <div className="coockie-modal-note"> {t('cookie.modal.description')} </div>
        </div>
      </div>
    )
  }
}
