import React, { Fragment } from 'react'
import TooltipWrapper from '../../UI/popover-wrapper'
import Table from '../../shared/components/table'
import Spinner from '../../UI/spinner'
import { ModalConsumer, ModalContextState } from '../../../context/modal-context'
import ruWordNumeralEnding from '../../shared/ruWordNumeralEnding'
import { fromJS } from 'immutable'
import moment from 'moment'
import TooltipModal from './tooltip-modal'
import FlexBand from 'flexband'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import ItemPage from '../item'
import { SearchTableProps, SearchTableState, DelayTimer } from './types'
import './styles.scss'
import dict from '../../shared/dictionaries'
import Banner from '../../banner'
import { hideScrollBar, getDateCreateInMinutes } from '../../../store/utils'
import { dateFormatter, todayDateFormatter } from "../../shared/utils"
import { Redirect } from 'react-router-dom'

const numberOfRowsToInsertAd = 10
const STYLE_BASE = 'cargo-transport-table_'

export default class extends React.Component<SearchTableProps, SearchTableState> {
  modalConsumer: ModalContextState
  transportTypes: any
  containerTypes: any
  timerWorker: DelayTimer
  constructor(props: SearchTableProps) {
    super(props)
    this.state = {
      itemId: null,
      items: props.items,
      timers: [],
      redirectToFullPage: false,
      redirectItemId: 0,
      canAdd: false,
    }

    this.timerWorker = new DelayTimer({
      timerUpdate: (timerId, timeRemaining) => {
        const { timers } = this.state
        timers[timerId] = timeRemaining
        this.setState({ timers })

      },
      timerEnd: timerId => {
        const { timers } = this.state
        const { getUnblockedItem, items } = this.props

        this.setState({ timers })
        const itemId = items.toJS().find(i => Number(i.itemId) === timerId).itemId
        getUnblockedItem(itemId)
      },
    })
    this.transportTypes = dict.transportTypesAuto(props.langId)
    this.containerTypes = dict.getTransportTypes("container")(props.langId)
  }

  componentDidMount() {
    const { isMyOffers, onCanAdd, searchType } = this.props
    isMyOffers && onCanAdd(searchType)
  }

  componentDidUpdate(prevProps: SearchTableProps) {
    const { canAdd } = this.props
    if (prevProps.items !== this.props.items) {
      this.setState({ items: this.props.items }, () => {
        const { items } = this.state
        const _items = items?.toJS()
        let counter = 0

        _items?.forEach((item, id) => {
          const { viewTimeout, transportTypes, shippingType, incoterms, itemId } = item

          if (viewTimeout) {
            counter++
            this.timerWorker.setTimer(Number(itemId), null, viewTimeout)
          } else if (this.props.searchType === 'cargo') {
            counter++
            this.getTransportTypes(transportTypes, shippingType).then(typesString => {
              item.transportTypesStr = typesString
              if (counter >= _items.length) {
                this.setState({ items: fromJS(_items) })
              }
            })
          } else if (this.props.searchType === 'transport' && shippingType === "container") {
            counter++
            this.getTransportTypes(incoterms && incoterms.split(",") || [], shippingType).then(typesString => {
              
              item.transportTypesStr = typesString
              if (counter >= _items.length) {
                this.setState({ items: fromJS(_items) })
              }
            })
          }
        })
      })
    }

    if (canAdd !== prevProps.canAdd) {
      this.setState({ canAdd })
    }
  }

  getTransportTypes = async (types: any[], shippingType: string) => {
    let dictionary: any[]
    const transportTypesArray: string[] = []
    if (this.transportTypes.loaded) {
      dictionary = shippingType === "container" ? this.containerTypes.items : this.transportTypes.items
    } else {
      dictionary = await this.transportTypes.handler?.items
    }

    (types || [])?.forEach(type => {
      transportTypesArray.push(dictionary?.find(x => x.value == type)?.name)
    })

    return transportTypesArray?.join(', ')
  }

  componentWillUnmount() {
    const { notificationUnsubscribe } = this.props
    notificationUnsubscribe && notificationUnsubscribe()
    this.timerWorker.cancelAllTimers()
  }
  tdCountry = (from, itemId) => (
    <td key={'country' + itemId} className="td-country">
      <span title={from.countryName} data-href="#" data-blank="false" data-popup-link="one-cargo">
        {from.countryAbbr}
      </span>
    </td>
  )
  tdCity = (place, itemId) => (
    <td key={'city' + itemId} className="td-city">
      <div
        data-href="#"
        data-blank="false"
        data-popup-link="one-cargo"
        className="city has-tooltip"
      >
        <TooltipWrapper text={place.address} hover disabled={!place.address}>
          {place.city || place.address}
        </TooltipWrapper>
      </div>
    </td>
  )
  tdDates = (dateBegin, dateEnd, itemId) => {
    const { showDateIsShort = false } = this.props
    return (
      <td key={'dates' + itemId} className="td-date">
        <span data-href="#" data-blank="false" data-popup-link="one-cargo">
          {`${dateFormatter(dateBegin, showDateIsShort)} - ${dateFormatter(dateEnd, showDateIsShort)}`}
        </span>
      </td>
    )
  }

  tdCargo = item => {
    const { searchType, exactUserId, t } = this.props
    const { [searchType]: entity } = item
    if (entity) {
      const {
        name,
        price,
        priceType,
        priceRecurring,
        weight,
        weightUnit,
        crg_oversized,
        crg_grouping,
        crg_fragile,
        crg_adr,
        temperature,
        volume,
        volumeUnit,
        itemId,
        ecoStandard
      } = entity
      const meta = []

      if (searchType === "cargo") {

        const priceTypeLabel = dict.priceTypes.find(item => item.value === priceType)?.name || ""
        //const priceRecurringLabel = dict.priceRecurrings.find(item => item.value === priceRecurring)?.name || ""

        price && priceType === 1 && meta.push(price)
        priceType && priceType !== 1 && meta.push(t(priceTypeLabel))
        //priceRecurring && priceType !== 1 && meta.push(priceRecurringLabel)
        entity.isTir && meta.push('TIR')
        item.cmr && meta.push('CMR')
        item.t1 && meta.push('T1')
        item.transportTypesStr && meta.push(item.transportTypesStr)

      } else {

        item[searchType].crg_tir && meta.push('TIR')
        item[searchType].crg_cmr && meta.push('CMR')
        item[searchType].crg_custom_control && meta.push(t('cargo.doc.custom'))
        item[searchType].crg_declaration && meta.push(t('cargo.doc.declaration'))
        item[searchType].crg_cmr_insurance && meta.push(t('sanitary.certificate'))
        item.transportTypesStr && meta.push(item.transportTypesStr)
      }

      return (
        <td
          key={'cargo' + itemId}
          className={classNames({
            'td-info': true,
            'no-max-width': !!exactUserId,
          })}
        >
          <span data-href="#" data-blank="false" data-popup-link="one-cargo">
            <FlexBand className="td-info-flex" justify="space-between" wrap="nowrap">
              <span className="name">
                {name}
                {crg_adr && <i className="fa fa-fire" />}
                {crg_grouping && <i className="fa fa-th-large" />}
                {crg_oversized && <i className="ico ico-weight" />}
                {temperature && <i className="fa fa-snowflake-o" />}
                {crg_fragile && <i className="fa fa-glass" />}
                {ecoStandard && ` Euro-${ecoStandard}`}
              </span>
              <span className="name-info">
                <span>{`${weight || 0}${weightUnit || `t`} `}</span>
                {volume && volume !== "null" && volume !== "[]" && <span className="td-info_volume">
                  {volume}
                  {volumeUnit === 'm3' ? ['m', <sup key="1">3</sup>] : volumeUnit}
                </span>}
              </span>
            </FlexBand>
            <div className="meta meta-data">{meta.length > 0 && meta.join(', ')}</div>
          </span>
        </td>
      )
    }
  }

  tdCompany = (item, index) => {
    const { t, langId } = this.props
    const { companyName, userFullName, viewTimeout, userId, companyId, itemId, isBlocked, createDate, verified } = item

    const updatedDate = todayDateFormatter(createDate)

    if (companyName || userFullName) {
      return (
        <td key={'company' + itemId} className="td-company">
          <span data-href="#" data-blank="false" data-popup-link="one-cargo">
            {companyName ? (
              <Link to="#" className={`has-icon has-tooltip ${verified > 0 && 'verified-company'}`} onClick={(e) => e.stopPropagation()}>
                {verified > 0 && <TooltipWrapper text={t('company.verificated')} hover >
                  <span className="icon">
                    <i className="fa fa-check-circle" />
                  </span>
                </TooltipWrapper>}
                <TooltipWrapper
                  text={<TooltipModal companyName={companyName} companyId={companyId} userId={0} />}
                  hover
                  innerClassName="full-tooltip"
                  disabled={false}
                >
                  {companyName}
                </TooltipWrapper>
              </Link>
            ) : (
              <Link to="#" className="company-users has-tooltip" onClick={(e) => e.stopPropagation()}>
                {isBlocked && <i className="fa fa-ban" />}
                <TooltipWrapper
                  text={<TooltipModal companyId={0} userId={userId} />}
                  hover
                  innerClassName="full-tooltip"
                  disabled={false}
                  parentCustomClass="move-up-container"
                >
                  {userFullName}
                </TooltipWrapper>
              </Link>
            )}

            <FlexBand
              className="date"
              justify={companyName ? "space-between" : "end"}
              style={{ maxHeight: 16, width: '100%', position: 'relative' }}
            >
              {companyName && (<Link to="#" className="company-users show-info-modal" onClick={(e) => e.stopPropagation()}>
                <TooltipWrapper
                  text={<TooltipModal companyId={0} userId={userId} />}
                  hover
                  innerClassName="full-tooltip"
                  disabled={false}
                  parentCustomClass="move-up-container"
                >
                  {isBlocked && <i className="fa fa-ban" />}
                  {companyName && userFullName}
                </TooltipWrapper>
              </Link>)}

              <div className="meta">{updatedDate}</div>
            </FlexBand>
          </span>
        </td>
      )
    } else if (viewTimeout) {
      const timeRemaining = this.state.timers[itemId]?.toFixed(0)
      return (
        <td key={'company' + itemId} valign="middle" className="td-company restricted">
          <div>
            {t('time.restriction.wait.first', { time: timeRemaining })}
            {ruWordNumeralEnding(
              t,
              parseInt(timeRemaining),
              'time.restriction.wait.second',
              langId
            )}
          </div>
        </td>
      )
    }
  }

  tdFavorite = item => {
    const { userId, addFavourite, deleteFavourite } = this.props
    const { itemId, isFavourite } = item

    if (!userId) return
    return (
      <td key={'favourite' + itemId} className="td-favourite">
        <span
          className="favorite is-active"
          onClick={e => {
            e.stopPropagation()
            if (!isFavourite) {
              addFavourite(itemId)
            } else {
              deleteFavourite(itemId)
            }
          }}
        >
          <i style={{ color: isFavourite ? '#0db827' : null }} className="fa fa-bookmark" />
        </span>
      </td>
    )
  }

  redirectToFullPage = (e, itemId: number) => {
    const { setScrollToOncommingItems } = this.props
    setScrollToOncommingItems(true)
    e.stopPropagation()
    this.setState({
      redirectToFullPage: true,
      redirectItemId: itemId
    })
  }

  tdOncomingNew = ({ itemId, oncomingNew }) => {
    const { t } = this.props
    return (
      <td
        key={'oncomingNew' + itemId}
        className="td-oncoming-new restricted"
        onClick={(e) => this.redirectToFullPage(e, itemId)}>
        <span className="offers_mobile_on">{t('cargo.oncoming.transport') + ':'}</span>
        <span>
          <span className="meta offers_mobile_on">{t('new') + ': '}</span>
          <span>{oncomingNew}</span>
        </span>
      </td>
    )
  }

  tdOncomingTotal = ({ itemId, oncomingTotal }) => {
    const { t } = this.props
    return (
      <td
        key={'oncomingTotal' + itemId}
        className="td-oncoming-total restricted"
        onClick={(e) => this.redirectToFullPage(e, itemId)}>
        <span>
          <span className="meta offers_mobile_on">{t('total') + ': '}</span>
          <span>{oncomingTotal}</span>
        </span>
      </td>
    )
  }

  tdViews = item => {
    const { itemId, numViews } = item
    return (
      <td key={'views' + itemId} className="td-views restricted">
        <span>
          <i className="fa fa-eye" />
          {numViews || 0}
        </span>
      </td>
    )
  }

  tdOptions = item => {
    const { itemId } = item
    const { searchType, changeActiveItem, getUserStatistics } = this.props
    const insertedOn = moment(item.insertedOn)
    const insertedDiffMs = moment().diff(insertedOn, 'ms')
    const timeFormat = true
    const getTime = () => (
      <span className="meta">
        <span className="offers_mobile_off">
          {timeFormat
            ? insertedOn.format('HH:mm ')
            : moment(insertedDiffMs).format('hч. mмин. назад / ')}
        </span>
        <span>{timeFormat ? insertedOn.format('DD/MM/YYYY') : insertedOn.format('HH:MM')}</span>
      </span>
    )
    const clickChangeActive = event => {
      event.stopPropagation()
      changeActiveItem(itemId).then(() => getUserStatistics('profile'))
    }

    return (
      <td key={'settings' + itemId} className="td-options restricted">
        <span>
          <Link to={`/${searchType}/edit/${itemId}`}>
            <i className="fa fa-pencil" />
          </Link>
          <Link to="#" onClick={clickChangeActive}>
            <i className="fa fa-trash" />
          </Link>
        </span>
        {getTime()}
      </td>
    )
  }

  // ------------------------------
  // row template
  // ------------------------------
  renderRow = (item, index, arr) => {
    if (item.toJS) {
      item = item.toJS()
    }


    const indexPlusOne = index + 1
    const index10 = index === 10
    const indexNumRows = index % numberOfRowsToInsertAd === 0
    const { from, to, itemId, viewTimeout } = item
    const { searchType, t, exactUserId, isMyOffers, bannerId, langId } = this.props
    const { pageIndex, pageSize, totalItems } = this.getPagination()
    const currentIndex = totalItems - 1 - pageIndex * pageSize
    const lastRow = ((index + 1) % numberOfRowsToInsertAd === 0 || index === currentIndex) && !exactUserId
    const isRoundedBottom = index > 1 && indexNumRows || index10
    const showHeaderRow = index === 0 || index10 || indexNumRows
    const cellsLength = isMyOffers ? 10 : 8
    const className = classNames({ 'tr-rounded-up': lastRow || (index !== 0 && indexPlusOne % 10 === 0) })
    const rowSpanCount = isMyOffers ? 2 : 1
    const isCargo = searchType === 'cargo'

    const rows = []
    if (showHeaderRow) {
      rows.push(
        <Fragment key={'header-' + index}>
          <tr
            className={classNames('skip', `${STYLE_BASE}tr-header`, {
              'tr-rounded-down': isRoundedBottom,
            })}
          >
            <td colSpan={2} rowSpan={rowSpanCount} className="td-country">
              <span>{t('search.load')}</span>
            </td>
            <td colSpan={2} rowSpan={rowSpanCount} className="td-country">
              <span>{t('search.unload')}</span>
            </td>
            <td rowSpan={rowSpanCount} className="td-country td-date">
              <span>{t('date')}</span>
            </td>
            <td rowSpan={rowSpanCount} className="td-country td-info">
              <span>{isCargo ? t('cargo') : t('transport')}</span>
            </td>
            {isMyOffers && (
              <td colSpan={4} className="td-country td-oncoming">
                <span>
                  {isCargo ? t('cargo.oncoming.transport') : t('transport.oncoming.cargo')}
                </span>
              </td>
            )}
            {!exactUserId && !isMyOffers && (
              <td colSpan={2} rowSpan={rowSpanCount} className="td-country td-company">
                <span>{t('search.customer')}</span>
              </td>
            )}
          </tr>
          {isMyOffers && (
            <tr className={`skip ${STYLE_BASE}tr-header ${STYLE_BASE}tr-oncoming`}>
              <td>{t('new')}</td>
              <td>{t('total')}</td>
              <td colSpan={2} />
            </tr>
          )}
        </Fragment>
      )
    }

    const cells = [
      this.tdCountry(from, 'from' + itemId),
      this.tdCity(from, 'from' + itemId),
      this.tdCountry(to, 'to' + itemId),
      this.tdCity(to, 'to' + itemId),
      this.tdDates(from.date, to.date, itemId),
      this.tdCargo(item),
      !exactUserId && !isMyOffers && this.tdCompany(item, index),
      !exactUserId && !isMyOffers && this.tdFavorite(item),
      isMyOffers && this.tdOncomingNew(item),
      isMyOffers && this.tdOncomingTotal(item),
      isMyOffers && this.tdViews(item),
      isMyOffers && this.tdOptions(item),
    ]

    rows.push(
      <tr
        key={itemId}
        className={`${className} table-line ${viewTimeout > 0 && "cursor-default"} ${!item.active && 'is-viewed'}`}
        onClick={() => this.onClickRow(itemId)}
      >
        {viewTimeout > 0 && (exactUserId || isMyOffers) ?
          <td colSpan={6} style={{ padding: "16px 0px" }}>
            {t('time.restriction.wait.first', { time: viewTimeout })}
            {ruWordNumeralEnding(
              t,
              parseInt(viewTimeout),
              'time.restriction.wait.second',
              langId
            )}
          </td>
          : cells}
      </tr>
    )

    if ((index !== 0 && indexPlusOne % 10 === 0) || lastRow) {
      rows.push(
        <tr key={index + '-ad'} className={`tr-link `}>
          <td colSpan={cellsLength} className="td-lg">
            <span>
              <Banner {...{ bannerId }} />
            </span>
          </td>
        </tr>
      )
    } else if (lastRow) {
      rows.push(<tr key={index + '-spacing'} className="tr-spacing" />)
    }

    return rows
  }

  renderRows = (items = []) => items.map((item, index, arr) => this.renderRow(item, index, arr))

  onClickRow = (itemId: number) => {
    const { openIn, searchType } = this.props
    if (itemId) {
      if (openIn === 'window') {
        window.open(`/${searchType}/${itemId}`, '_blank')
        this.setState({ itemId: null })
      } else {
        this.setState({ itemId }, this.showItemModal)
        hideScrollBar()
      }
    }
  }

  getPagination = () => {
    const { pageIndex, pageSize, totalItems, refresh } = this.props
    return {
      totalItems,
      pageIndex,
      pageSize,
      goto: index => refresh(index),
    }
  }

  showItemModal = () => {
    const { searchType, onCanAdd, getUserStatistics, isAuth } = this.props
    const { itemId, canAdd } = this.state
    const { showModalComponent, clearModal } = this.modalConsumer

    showModalComponent(
      <ItemPage
        {...{ itemId }}
        isModalView
        {...{ searchType }}
        // {...{ canAdd }}
        {...{ getUserStatistics }}
        // {...{ onCanAdd }}
        onCanAdd={() => onCanAdd(searchType)}
        // onCanAdd={onCanAdd(searchType)}
        onClose={() => {
          this.setState({ itemId: null }, () => {
            isAuth && onCanAdd(searchType)
          })
          clearModal()
        }}
      />,
      {
        className: `${STYLE_BASE}modal`,
        hideHeader: true,
        skipLayout: true,
        backdrop: true,
      }
    )
  }

  render() {
    const { items, redirectItemId, redirectToFullPage } = this.state
    const { t, notFoundText, bannerId, searchType, needToShowAdsNearPagination } = this.props
    return (
      <Fragment>
        {redirectToFullPage && <Redirect to={`/${searchType}/${redirectItemId}`} />}
        {this.props.processing ? (
          <Spinner />
        ) : items?.toJS()?.length > 0 ? (
          <Table
            items={this.state.items}
            bannerId={bannerId}
            renderRows={this.renderRows}
            pagination={this.getPagination()}
            classNames={this.props.exactUserId ? 'no-borders' : ''}
            {...{ needToShowAdsNearPagination }}
          />
        ) : (
          <div>
            <FlexBand className="table-no-entries" justify="center" align="center">
              {notFoundText || t('not.found')}
            </FlexBand>
            <div style={{ marginTop: 20 }}> <Banner {...{ bannerId }} /> </div>
          </div>

        )}
        <ModalConsumer>
          {modalConsumer => {
            this.modalConsumer = modalConsumer
            return null
          }}
        </ModalConsumer>
      </Fragment>
    )
  }
}
