export const md7 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>КОДЕКС ЖЕЛЕЗНОДОРОЖНОГО ТРАНСПОРТА</h4><p></p>

<p></p><h4>ГЛАВА I ОСНОВЫ ДЕЯТЕЛЬНОСТИ ЖЕЛЕЗНОДОРОЖНОГО ТРАНСПОРТА</h4><p></p>

<p></p><h4>Часть 1 ОБЩИЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 1. Цель и задачи Кодекса железнодорожного транспорта</p>

<p style="text-indent:2em;"></p><h6>Кодекс железнодорожного транспорта (далее - кодекс):</h6><p></p>

<p style="text-indent:2em;">a) определяет правовые, организационные и экономические основы деятельности железнодорожного транспорта, его особенности, место и роль в экономике страны, 
регламентирует его отношения с органами центрального и местного публичного управления, с другими видами транспорта;</p><br>

<p style="text-indent:2em;">b) регулирует отношения между железной дорогой и грузоотправителями, грузополучателями, пассажирами, другими физическими и юридическими лицами, 
пользующимися услугами железнодорожного транспорта, определяет их права, обязанности и ответственность;</p><br>

<p style="text-indent:2em;">c) регламентирует основные условия перевозки пассажиров, грузов, багажа и грузобагажа, устанавливает общие правила эксплуатации железнодорожных подъездных путей.</p><br>

<p style="font-weight:500;">Статья 2. Сфера действия кодекса и контроль за соблюдением его требований</p><br>

<p style="text-indent:2em;">(1) Действие настоящего кодекса распространяется на перевозки пассажиров, грузов, багажа и грузобагажа по железной дороге Республики Молдова,
 открытой для общего пользования, включая перевозки грузов, погрузка и выгрузка которых производится на железнодорожных подъездных путях, а также на
 строящихся железнодорожных линиях, примыкающих к железнодорожным путям общего пользования (магистральным).</p><br>

<p style="text-indent:2em;">(2) Контроль за соблюдением требований настоящего кодекса физическими и юридическими лицами, которые подпадают под его действие,
 осуществляется центральным отраслевым органом публичного управления, а также другими органами центрального публичного управления,
 наделенными законом такой функцией.</p><br>

<p style="font-weight:500;">Статья 3. Основные понятия</p><br>

<p style="text-indent:2em;">В настоящем кодексе используются следующие основные понятия: железнодорожный транспорт - вид транспорта общего пользования,
 представляющий собой единый производственно-технологический комплекс, включающий предприятия и учреждения производственного и социального назначения,
 государственное управление которым осуществляет центральный орган публичного управления в области транспорта;</p>

<p style="text-indent:2em;"></p><h6>железная дорога - рельсовая транспортная система, предназначенная для перевозки пассажиров и грузов, к которой относятся:</h6><p></p>

<p> железнодорожный путь и соответствующие сооружения (железнодорожные тоннели, мосты и т.д.);</p><br>

<p> эксплуатационные сооружения;</p><br>

<p> подвижной состав, а также;</p><br>

<p style="text-indent:2em;"> структуры для обеспечения работы указанных выше элементов и эксплуатационные организации для

осуществления перевозок пассажиров и грузов;</p><br>

<p style="text-indent:2em;"> рынок транспортных услуг - сфера осуществления перевозок пассажиров, грузов, багажа и грузобагажа,

технического обслуживания и ремонта транспортных средств, подсобно-вспомогательных и других видов работ ( услуг), связанных с перевозками;</p><br>

<p style="text-indent:2em;">обеспечение безопасности движения - деятельность органов железнодорожного транспорта, органов центрального и местного публичного управления, предприятий,
 учреждений, граждан по обеспечению бесперебойного функционирования железной дороги и недопущению аварийных ситуаций в перевозочном процессе, а также по уменьшению последствий возможных аварий;</p><br>

<p style="text-indent:2em;">полоса отвода - земли железнодорожного транспорта, занимаемые земляным полотном, искусственными сооружениями, линейно-путевыми и другими зданиями,
 устройствами железнодорожной связи, железнодорожными станциями, защитными лесонасаждениями и путевыми устройствами;</p><br>

<p style="text-indent:2em;">охранные зоны - земельные участки, необходимые для обеспечения сохранности, прочности и устойчивости сооружений, устройств и других объектов железнодорожного транспорта,
 а также земли с подвижной почвой, соседствующие с землями железнодорожного транспорта;</p><br>

<p style="text-indent:2em;">перевозочный процесс - комплекс организационно и технологически взаимосвязанных действий и операций, выполняемых структурными подразделениями железной дороги при подготовке,
 осуществлении и завершении перевозок пассажиров, грузов, багажа и грузобагажа;</p><br>

<p style="text-indent:2em;">железнодорожные пути общего пользования (магистральные) - железнодорожные линии, на которых расположены железнодорожные станции,
 выполняющие соответствующие операции по приему и отправлению поездов, по обслуживанию пассажиров, по приему и выдаче грузов,
 багажа и грузобагажа, производству маневровой работы; прямое смешанное сообщение - перевозки грузов,
 осуществляемые разными видами транспорта по единому перевозочному документу (накладной), составленному на весь путь следования грузов;</p><br>

<p style="text-indent:2em;">пассажир - физическое лицо, имеющее проездной документ (билет) и совершающее поездку в поезде; груз - объект железнодорожной перевозки (изделия,
 предметы, полезные ископаемые, материалы и т.д.), принятый в установленном порядке для транспортирования по железной дороге,
 которая несет ответственность за своевременность его доставки и сохранность в соответствии с настоящим кодексом;</p><br>

<p style="text-indent:2em;">багаж - вещи, иные материальные ценности, отправляемые пассажиром для личных нужд за отдельную плату в багажном вагоне до станции назначения, указанной в проездном документе (билете) пассажира;</p><br>

<p>грузобагаж - груз, иные материальные ценности, перевозимые в пассажирских и почтово-багажных поездах;</p><br>

<p style="text-indent:2em;">опасные грузы - вещества, материалы, изделия и отходы, которые в силу своего физического состояния, химических,
 биологических свойств, радиоактивности или других специфических особенностей могут создать в процессе перевозки,
 выполнения погрузочно-разгрузочных работ и хранения угрозу для жизни и здоровья людей, вызвать загрязнение окружающей среды,
 гибель флоры и фауны, повреждение или уничтожение транспортных средств и иного имущества;</p><br>

<p style="text-indent:2em;">специальные перевозки - вид перевозок, обеспечивающий транспортирование специальных грузов для особо важных государственных и оборонных нужд;</p><br>

<p style="text-indent:2em;">международное железнодорожное сообщение - перевозки пассажиров, грузов, багажа и грузобагажа между Республикой Молдова и другими странами;</p><br>

<p style="text-indent:2em;">грузоотправитель - физическое или юридическое лицо, выступающее от своего имени либо от имени собственника груза, багажа, грузобагажа,
 указываемое в перевозочных документах; грузополучатель - физическое или юридическое лицо, уполномоченное на получение груза, багажа, грузобагажа;</p><br>

<p style="text-indent:2em;">железнодорожный подъездной путь - железнодорожный путь, связанный с общей сетью железных дорог непрерывной рельсовой колеей,
 предназначенный для обслуживания определенных грузоотправителей и грузополучателей;</p><br>

<p style="text-indent:2em;">места общего пользования - склады (крытые и открытые), участки, специально выделенные на территории железнодорожных станций,
 принадлежащие железной дороге и используемые для погрузки, выгрузки, сортировки и хранения грузов, багажа и грузобагажа;</p><br>
 
<p style="text-indent:2em;">места собственного пользования - склады (крытые и открытые), участки, специально оборудованные как за пределами,
 так и на территории железнодорожных станций, не принадлежащие железной дороге или сданные ею в аренду и используемые для выполнения погрузочно-разгрузочных операций;</p><br>

<p style="text-indent:2em;">Правила перевозок грузов по железной дороге - нормативный акт, разработанный в соответствии с положениями настоящего кодекса,
 регламентирующий обязательные для железной дороги, грузоотправителей и грузополучателей условия перевозок грузов, с учетом их особенностей,
 в целях обеспечения безопасности движения, сохранности грузов и подвижного состава, а также экологической безопасности;</p><br>

<p style="text-indent:2em;">Правила перевозок пассажиров, багажа и грузобагажа по железной дороге - нормативный акт, разработанный в соответствии с положениями настоящего кодекса,
 регламентирующий условия таких перевозок и устанавливающий права, обязанности и ответственность железной дороги и пассажиров, пользующихся железнодорожным транспортом;</p><br>
 
<p style="text-indent:2em;">перевозочные документы - накладная, дорожная ведомость, проездной документ (билет), багажная квитанция, грузобагажная квитанция;</p><br>

<p style="text-indent:2em;">сборник "Правила перевозок и тарифы на железнодорожном транспорте" - периодическое издание, в котором публикуются нормативные акты,
 утвержденные центральным отраслевым органом публичного управления в пределах его компетенции; дополнительный сбор - не включенная в тариф ставка оплаты дополнительной операции или работы;</p><br>

<p style="text-indent:2em;">сдвоенная операция - использование грузополучателем выгруженного им вагона и/или контейнера для погрузки других грузов;</p><br>

<p style="text-indent:2em;">Тарифное руководство - систематизированное издание, в котором публикуются утвержденные в установленном порядке и включенные в тарифы цены на работы и услуги,
 соответственно выполняемые и оказываемые железнодорожным транспортом, дополнительные сборы, приводится порядок исчисления ставок платы за перевозки пассажиров,
 грузов, багажа и грузобагажа, за пользование вагонами и контейнерами.</p><br>
 
<p style="font-weight:500;">Статья 4. Роль железнодорожного транспорта в экономике и социальной сфере</p><br>

<p style="text-indent:2em;">(1) Железнодорожный транспорт является составной частью единой транспортной системы страны и призван во взаимодействии с другими видами транспорта своевременно
 и качественно обеспечивать во внутреннем и международном железнодорожных сообщениях потребности населения в перевозках и других услугах, деятельность всех отраслей
 экономики и национальную безопасность государства, эффективное развитие предпринимательской деятельности предприятий отрасли, формировать рынок транспортных услуг.</p><br>

<p>(2) Деятельность железнодорожного транспорта регулируется и контролируется государством.</p><br>

<p style="text-indent:2em;">(3) Развитие материально-технической базы железнодорожного транспорта осуществляется за счет собственных средств его предприятий,
 а также средств государственного и местных бюджетов, привлечения внутренних и внешних займов.</p><br>

<p style="text-indent:2em;">(4) Железнодорожные перевозки относятся к естественной монополии, что определяет особые условия развития и функционирования железнодорожного транспорта.</p><br>

<p style="font-weight:500;">Статья 5. Железная дорога и ее роль в системе железнодорожного транспорта</p><br>

<p>(1) Железная дорога является структурой общего пользования государственного значения.</p><br>

<p style="text-indent:2em;">(2) Ведущее предприятие железнодорожного транспорта в Республике Молдова - государственное предприятие "Calea Ferata din Moldova",
 которое управляет производственной, финансовой деятельностью самостоятельных структурных подразделений, занимающихся железнодорожными перевозками,
 и координирует работу других предприятий, организаций и учреждений железнодорожного транспорта, обеспечивающих его функционирование.</p><br>

<p style="text-indent:2em;">(3) Государственное предприятие "Calea Ferata din Moldova" осуществляет свою деятельность на всей территории Республики Молдова. Управляет им центральный отраслевой орган публичного управления.</p><br>

<p style="font-weight:500;">Статья 6. Законодательство о железнодорожном транспорте</p><br>

<p style="text-indent:2em;">(1) Деятельность железнодорожного транспорта, обеспечивающая перевозки пассажиров, грузов, багажа и грузобагажа, организуется в соответствии с Конституцией Республики Молдова,
 Гражданским кодексом, Законом о транспорте, настоящим кодексом, другими действующими нормативными актами, а также международными договорами, одной из сторон которых является Республика Молдова.</p><br>

<p style="text-indent:2em;">(2) Нормативные акты, регулирующие деятельность железнодорожного транспорта и устанавливающие условия перевозок, использования средств железнодорожного транспорта,
 безопасности движения, соблюдения порядка пересечения железнодорожных путей другими видами транспорта, пожарной безопасности, а также санитарные правила являются обязательными для предприятий,
 организаций, учреждений и граждан, пользующихся услугами железнодорожного транспорта.</p><br>

<p style="font-weight:500;">Статья 7. Управление на железнодорожном транспорте</p><br>

<p style="text-indent:2em;">(1) Согласно действующему законодательству руководство, управление и регулирование деятельности железнодорожного транспорта осуществляются центральным отраслевым органом публичного управления
 - Министерством транспорта и связи в пределах его компетенции. Управление перевозочным процессом производится непосредственно предприятиями, осуществляющими этот вид деятельности.</p><br>

<p style="text-indent:2em;">(2) В систему железнодорожного транспорта входят предприятия железнодорожного транспорта, осуществляющие перевозки пассажиров и грузов, принадлежащий им подвижной состав,
 железнодорожные пути, а также промышленные, строительные, торговые и другие предприятия, организации и учреждения, обеспечивающие ритмичную работу железнодорожного транспорта и его развитие.</p><br>

<p style="text-indent:2em;">(3) Органы центрального и местного публичного управления, общественные и иные организации, а также граждане не вправе вмешиваться в деятельность железнодорожного транспорта,
 непосредственно связанную с перевозочным процессом, за исключением случаев, предусмотренных действующим законодательством.</p><br>

<p style="text-indent:2em;">(4) Создание, реорганизация и ликвидация предприятий железнодорожного транспорта, функционирующих по праву хозяйственного ведения,
 осуществляются согласно действующему законодательству центральным отраслевым органом публичного управления, а предприятий,
 предметом деятельности которых являются организация и обеспечение перевозочного процесса, - согласно постановлениям Правительства.</p><br>

<p style="text-indent:2em;">(5) Прекращение деятельности (закрытие) железнодорожных путей общего пользования (магистральных) производится центральным отраслевым органом публичного
 управления по согласованию с Правительством и соответствующими органами местного публичного управления.</p><br>

<p style="font-weight:500;">Статья 8. Имущество железнодорожного транспорта</p><br>

<p style="text-indent:2em;">(1) Имущество предприятий, организаций, учреждений и объектов железнодорожного транспорта, которые непосредственно обеспечивают перевозочный процесс и проведение аварийно-восстановительных работ,
 является исключительно публичной собственностью.</p><br>

<p style="text-indent:2em;">(2) Предприятия железнодорожного транспорта владеют, пользуются и распоряжаются имуществом в соответствии с положениями Гражданского кодекса,
 Закона о государственном предприятии, Закона о транспорте, настоящего кодекса, иными действующими законодательными актами, а также международными договорами,
 одной из сторон которых является Республика Молдова.</p><br>

<p style="text-indent:2em;">(3) Решение о закреплении имущества в хозяйственном ведении и оперативном управлении за предприятием,
 учреждением железнодорожного транспорта принимается центральным отраслевым органом публичного управления.</p><br>

<p style="text-indent:2em;">(4) Продажа, сдача в аренду или управление иным способом недвижимым имуществом, находящимся в распоряжении предприятий железнодорожного транспорта,
 без изменения права государственной собственности на это имущество производятся такими предприятиями с согласия центрального отраслевого органа публичного управления в порядке, установленном Правительством.</p><br>

<p style="text-indent:2em;">(5) Продажа, обмен, сдача в аренду, безвозмездное предоставление во временное пользование, списание с баланса подвижного состава и контейнеров, находящихся в государственной собственности,
 без изменения права собственности осуществляются с учетом специфики деятельности железнодорожного транспорта центральным отраслевым органом публичного управления или по его поручению администрацией
 железной дороги в порядке, установленном этим органом.</p><br>

<p style="text-indent:2em;">(6) Продажа подвижного состава, контейнеров, зданий и сооружений, не используемых в технологическом процессе предприятия,
 физическим и юридическим лицам с изменением права государственной собственности на это имущество осуществляется в порядке, установленном Правительством.</p><br>

<p style="text-indent:2em;">(7) Средства, полученные от операций с имуществом, предусмотренных настоящей статьей, зачисляются в доходы предприятий железнодорожного транспорта и должны использоваться на приобретение подвижного
 состава и контейнеров, развитие производственных мощностей и строительство социальной инфраструктуры предприятий, на балансе которых находится это имущество.</p><br>

<p style="text-indent:2em;">(8) Объекты и иное имущество железнодорожного транспорта, непосредственно обеспечивающие перевозочный процесс и выполнение аварийно-восстановительных работ, не подлежат разгосударствлению 
и приватизации.</p><br>

<p style="text-indent:2em;">(9) Приватизация предприятий, а также объектов и иного имущества железнодорожного транспорта, за исключением указанных в части (8),
 с установлением сроков и условий ее проведения допускается в порядке, определенном действующим законодательством.</p>

<p></p><h4>Часть 2 ЗЕМЛИ ЖЕЛЕЗНОДОРОЖНОГО ТРАНСПОРТА И ОХРАННЫЕ ЗОНЫ<p></p></h4>

<p style="font-weight:500;">Статья 9. Земли железнодорожного транспорта</p><br>

<p style="text-indent:2em;">(1) Землями железнодорожного транспорта являются земельные участки, предоставленные в соответствии с Земельным кодексом, другими действующими нормативными актами в постоянное (бессрочное)
 пользование предприятиям, учреждениям железнодорожного транспорта для выполнения ими специальных задач. Это земли, отведенные под железнодорожные пути и станции (включая полосу отвода),
 а также занятые защитными и укрепительными насаждениями, строениями, зданиями, сооружениями и иными объектами, необходимыми для обеспечения функционирования железнодорожного транспорта.</p><br>

<p style="text-indent:2em;">(2) Для временного пользования предприятиям, учреждениям железнодорожного транспорта земли могут предоставляться в аренду.</p><br>

<p style="text-indent:2em;">(3) Порядок использования земель железнодорожного транспорта в пределах полосы отвода определяется предприятием железнодорожного транспорта - владельцем с соблюдением требований Земельного кодекса.</p><br>

<p style="text-indent:2em;">(4) Размеры земельных участков (включая полосу отвода) устанавливаются в соответствии с нормами, проектно-сметной документацией и генеральными схемами развития железнодорожных линий и станций,
 а предоставление участков осуществляется с учетом очередности их освоения.</p><br>

<p style="text-indent:2em;">(5) Земли железнодорожного транспорта должны соответствовать градостроительным, экологическим, санитарным, а также иным нормам и требованиям,
 установленным органами центрального отраслевого и местного публичного управления.</p><br>

<p style="font-weight:500;">Статья 10. Охранные зоны</p><br>

<p style="text-indent:2em;">(1) В целях обеспечения безопасности (в том числе со стороны промышленных объектов и других видов транспорта) населения и нормальной эксплуатации железнодорожных линий,
 иных железнодорожных объектов, расположенных на участках земель, подверженных оползням, обвалам, селям и другим опасным явлениям, устанавливаются охранные зоны.</p><br>

<p style="text-indent:2em;">(2) Порядок установления охранных зон, их размеры согласно строительным нормативам и режим использования земель, предоставленных для указанных зон, устанавливаются Правительством.</p>

<p></p><h4>Часть 3 ПРИНЦИПЫ ФИНАНСОВО-ЭКОНОМИЧЕСКОЙ ДЕЯТЕЛЬНОСТИ ЖЕЛЕЗНОДОРОЖНОГО ТРАНСПОРТА<p></p></h4>

<p style="font-weight:500;">Статья 11. Принципы организации перевозок пассажиров, грузов, багажа и грузобагажа по железной дороге</p><br>

<p style="text-indent:2em;">(1) Перевозки пассажиров, грузов, багажа и грузобагажа по железной дороге во внутреннем сообщении осуществляются в порядке и на условиях,
 предусмотренных настоящим кодексом, Правилами перевозок пассажиров, багажа и грузобагажа по железной дороге и Правилами перевозок грузов по железной дороге.</p><br>

<p style="text-indent:2em;">(2) Перевозки пассажиров, грузов, багажа и грузобагажа по железной дороге в международном сообщении транзитом через территорию Республики Молдова осуществляются на основании международных договоров,
 одной из сторон которых является Республика Молдова.</p><br>

<p style="text-indent:2em;">(3) Показатели качества перевозок (сроки доставки, сохранность грузов, багажа, грузобагажа) и качества обслуживания пассажиров, грузоотправителей и грузополучателей,
 выполнение которых является обязательным условием для железной дороги и других предприятий, оказывающих услуги по перевозкам и иные связанные с ними услуги,
 устанавливаются в соответствии с настоящим кодексом, Правилами перевозок пассажиров, багажа и грузобагажа по железной дороге и Правилами перевозок грузов по железной дороге.</p><br>

<p style="font-weight:500;">Статья 12. Тарифы на железнодорожном транспорте</p><br>

<p style="text-indent:2em;">(1) Тарифы на перевозки пассажиров, грузов, багажа и грузобагажа по железной дороге устанавливаются центральным отраслевым органом публичного управления на базе государственной ценовой и тарифной политики,
 являющейся прерогативой Правительства. Тарифы на перевозки в международном сообщении устанавливаются в соответствии с международными договорами, одной из сторон которых является Республика Молдова.</p><br>

<p style="text-indent:2em;">(2) Измененные тарифы на перевозки пассажиров и соответствующие дополнительные сборы публикуются в средствах массовой информации не позднее чем за десять дней до введения их в действие.</p><br>

<p style="text-indent:2em;">(3) Измененные тарифы на перевозки грузов и соответствующие дополнительные сборы публикуются в средствах массовой информации не позднее чем за 15 дней до введения их в действие.</p><br>

<p style="text-indent:2em;">(4) Работы и услуги, не предусмотренные Тарифным руководством и выполненные железной дорогой по просьбе грузоотправителя, грузополучателя или пассажира, а также работы,
 учтенные в стоимости перевозки, но выполненные грузоотправителем или грузополучателем по просьбе железной дороги, оплачиваются согласно договоренности сторон.</p><br>

<p style="font-weight:500;">Статья 13. Основы финансово-экономической деятельности железнодорожного транспорта</p><br>

<p style="text-indent:2em;">(1) Финансово-экономическая деятельность предприятий и учреждений железнодорожного транспорта основывается на принципах государственного регулирования и финансово-экономического самоуправления.</p><br>

<p style="text-indent:2em;">(2) Строительство и реконструкция железнодорожных путей общего пользования (магистральных), объектов мобилизационного назначения, а также приобретение железнодорожного подвижного
 состава относятся к государственным нуждам и осуществляются за счет средств государственного бюджета и собственных средств железной дороги.</p><br>

<p style="text-indent:2em;">(3) Строительство и реконструкция железнодорожных линий (в том числе электрификация) , вокзалов, пешеходных мостов, тоннелей, пассажирских платформ, других железнодорожных объектов
 по обслуживанию населения региона, приобретение поездов для пригородного сообщения могут осуществляться за счет средств государственного бюджета, местных бюджетов,
 собственных средств железной дороги, а также за счет добровольных взносов заинтересованных организаций и граждан.</p><br>

<p style="text-indent:2em;">(4) Проездные документы (билеты) продаются гражданам, пользующимся железнодорожным транспортом, за полную стоимость. Исключение составляют лица,
 которым законом предоставлены льготы по оплате проезда на железнодорожном транспорте. Компенсация стоимости проезда граждан, пользующихся указанными льготами,
 осуществляется за счет средств государственного бюджета в порядке, установленном действующим законодательством.</p><br>

<p style="text-indent:2em;">(5) Расчеты между грузоотправителями, грузополучателями и предприятиями железнодорожного транспорта за перевозки грузов и багажа,
 уплата дополнительных сборов за грузовые операции и использование подвижного состава, а также штрафов и пеней осуществляются в порядке,
 установленном настоящим кодексом, иными действующими нормативными актами, а также международными договорами, одной из сторон которых является Республика Молдова.</p><br>

<p style="text-indent:2em;">(6) Гарантия получения железной дорогой всех платежей, вытекающих из договора о перевозке груза, обеспечивается залоговым правом на грузы,
 порядок осуществления которого определяется действующим законодательством.</p>

<p></p><h4>Часть 4 БЕЗОПАСНОСТЬ ДВИЖЕНИЯ. ОХРАНА ГРУЗОВ И ОБЪЕКТОВ ЖЕЛЕЗНОДОРОЖНОГО ТРАНСПОРТА. ОРГАНИЗАЦИЯ РАБОТЫ В ОСОБЫХ УСЛОВИЯХ.<p></p></h4>

<p style="font-weight:500;">Статья 14. Обеспечение безопасности на железнодорожном транспорте</p><br>

<p style="text-indent:2em;">(1) Соответствующие подразделения железной дороги обеспечивают безопасные условия для жизни и здоровья пассажиров, для перевозки грузов и багажа,
 для движения поездов и других железнодорожных транспортных средств, включая маневровые работы и эксплуатацию транспортных средств,
 используемых в перевозочном процессе; охрану труда железнодорожников и охрану окружающей среды.</p>

<p style="text-indent:2em;"></p><h6>(2) Эта работа включает:</h6><p></p>

<p style="text-indent:2em;">a) правовое регулирование в области безопасности движения и эксплуатации транспортных и иных технических средств, используемых в перевозочном процессе;</p><br>

<p style="text-indent:2em;">b) подготовку предложений для разработки государственной технической, экономической и социальной политики и реализацию ее путем осуществления практических мер по предупреждению нарушений правил
 безопасности движения и эксплуатации транспортных и иных технических средств, используемых в перевозочном процессе;</p><br>

<p style="text-indent:2em;">c) осуществление контроля за выполнением требований действующего законодательства, регламентирующего правовые отношения в области безопасности движения и эксплуатации транспортных и иных технических средств,
 используемых в перевозочном процессе.</p><br>

<p style="text-indent:2em;">(3) На железнодорожном транспорте действуют специальная служба, контролирующая безопасность движения поездов, а также аварийно-вос-становительные подразделения, ликвидирующие последствия аварий на железной дороге.
 Положения об этих подразделениях, а также порядок классификации, служебного расследования и учета нарушений правил безопасности движения утверждаются центральным отраслевым органом публичного управления.</p><br>

<p style="text-indent:2em;">(4) Подвижной состав, в том числе специализированный, и контейнеры, элементы верхнего строения железнодорожных путей, иные технические средства и механизмы, поставляемые железнодорожному транспорту,
 а также услуги, оказываемые пассажирам, должны соответствовать требованиям безопасности движения, правилам охраны труда и экологической безопасности, установленным нормативными актами,
 и подлежат обязательной сертификации на соответствие указанным требованиям.</p><br>

<p style="text-indent:2em;">(5) За нарушение правил безопасности движения и эксплуатации транспортных и иных технических средств, используемых в перевозочном процессе,
 виновные лица несут ответственность согласно действующему законодательству.</p><br>

<p style="font-weight:500;">Статья 15. Зоны повышенной опасности</p><br>

<p style="text-indent:2em;">(1) Территория железнодорожных станций, вокзалов, предприятий и других структурных подразделений железной дороги, деятельность которых связана с перевозочным процессом,
 пассажирские платформы и железнодорожные линии, по которым осуществляется движение поездов и на которых выполняются маневровые и погрузочно-разгрузочные работы, являются зонами повышенной опасности.</p><br>

<p style="text-indent:2em;">(2) Правила нахождения граждан и размещения объектов в зонах повышенной опасности, проведения в них работ,
 проезда и перехода через железнодорожные линии устанавливаются центральным отраслевым органом публичного управления.</p><br>

<p style="font-weight:500;">Статья 16. Порядок перевозки опасных грузов</p><br>

<p style="text-indent:2em;">(1) Владельцы опасных и специальных грузов, перевозимых по железной дороге, отправляющие и получающие такие грузы, должны гарантировать безопасность их транспортирования,
 погрузки и выгрузки, иметь средства для определения степени заражения и оценки факторов риска, а также мобильные подразделения для ликвидации аварийных ситуаций и последствий
 аварий в случаях их возникновения при перевозке указанных грузов.</p><br>

<p style="text-indent:2em;">(2) Железнодорожный транспорт обязан обеспечивать безопасность транспортирования опасных и специальных грузов и в пределах тактико-технических возможностей имеющихся у него восстановительных
 средств участвовать в ликвидации последствий аварий при перевозке таких грузов.</p><br>

<p style="font-weight:500;">Статья 17. Места пересечения железнодорожных линий другими видами транспорта и сооружениями</p><br>

<p style="text-indent:2em;">(1) Места пересечения железнодорожных линий с автомобильными дорогами (железнодорожные переезды) определяются Правилами технической эксплуатации железной дороги,
 а также строительными нормами и правилами.</p><br>

<p style="text-indent:2em;">(2) Порядок эксплуатации железнодорожных переездов, их открытия и закрытия устанавливается центральным отраслевым органом публичного управления с уведомлением органов внутренних дел.</p><br>

<p style="text-indent:2em;">(3) Объекты, на территории которых осуществляются производство, хранение, погрузка, транспортирование и выгрузка опасных грузов, должны быть удалены от железнодорожных
 путей общего пользования (магистральных) и сооружений на расстояние, обеспечивающее их безопасную работу. Минимальные расстояния от указанных объектов до железнодорожных
 линий и сооружений, места пересечения железнодорожных путей общего пользования (магистральных) трубопроводами, линиями связи, электропередачи, другими сооружениями,
 а также нормы строительства и содержания таких объектов в местах пересечения и сближения с железнодорожными линиями устанавливаются нормативно-правовыми актами,
 разработанными соответствующими государственными органами и принятыми по согласованию с центральным отраслевым органом публичного управления.</p><br>

<p style="text-indent:2em;">(4) Ответственность за соблюдение установленных нормативов при строительстве и эксплуатации газонефтепроводов, коммуникаций и других сооружений,
 пересекающих железнодорожные пути общего пользования (магистральные) или находящихся в непосредственной близости от них, несут владельцы таких сооружений.</p><br>

<p style="text-indent:2em;">(5) В случаях возникновения аварийных ситуаций, угрожающих безопасности движения, владельцы указанных в настоящей статье сооружений обязаны своевременно проинформировать
 о произошедшем администрацию железной дороги для принятия соответствующих предупредительных мер.</p><br>

<p style="font-weight:500;">Статья 18. Охрана грузов и объектов железнодорожного транспорта</p><br>

<p style="text-indent:2em;">(1) Охрана грузов в пути следования и на железнодорожных станциях, охрана объектов железнодорожного транспорта, а также проведение профилактической противопожарной работы
, пожарный надзор и ликвидация пожаров на железнодорожном транспорте осуществляются подразделениями военизированной охраны железной дороги. Положение о военизированной охране утверждается
 администрацией железной дороги по согласованию с Управлением полиции на транспорте.</p><br>

<p style="text-indent:2em;">(2) Перечень грузов, подлежащих специальной охране и сопровождению, а также порядок охраны и сопровождения таких грузов устанавливаются центральным отраслевым органом публичного управления.
 В исключительных случаях указанный перечень может быть дополнен администрацией железной дороги с уведомлением об этом центрального отраслевого органа публичного управления.</p><br>

<p style="text-indent:2em;">(3) Охрана и сопровождение отдельных видов грузов осуществляются грузоотправителями и грузополучателями в порядке, предусмотренном настоящим кодексом.</p><br>

<p style="text-indent:2em;">(4) Поддержание общественного порядка и борьба с преступностью на железнодорожном транспорте обеспечиваются органами полиции на транспорте и территориальными
 подразделениями полиции во взаимодействии с подразделениями военизированной охраны, предприятиями и учреждениями железнодорожного транспорта.</p><br>

<p style="font-weight:500;">Статья 19. Организация работы железнодорожного транспорта в особых условиях</p><br>

<p style="text-indent:2em;">(1) Предприятия и организации железнодорожного транспорта в случаях крушений, аварий, стихийных бедствий (заносы, наводнения, пожары, оползни и т.д.),
 ставших причиной нарушения работы транспорта, принимают срочные меры по устранению их последствий.</p><br>

<p style="text-indent:2em;">(2) Органы центрального отраслевого и местного публичного управления, подразделения органов внутренних дел, Департамент чрезвычайных ситуаций,
 силовые министерства оказывают железной дороге помощь в ликвидации последствий крушений, аварий, стихийных бедствий, угрожающих жизни и здоровью людей,
 безопасности движения и сохранности грузов, обеспечивают мобилизационную готовность предприятий железнодорожного транспорта.</p><br>

<p style="text-indent:2em;">(3) Порядок компенсации затрат, связанных с оказанием помощи железной дороге в ликвидации крушений, аварий, последствий стихийных бедствий, устанавливается Правительством.</p>

<p></p><h4>Часть 5 ТРУДОВЫЕ ОТНОШЕНИЯ И ДИСЦИПЛИНА НА ЖЕЛЕЗНОДОРОЖНОМ ТРАНСПОРТЕ<p></p></h4>

<p style="font-weight:500;">Статья 20. Трудовые отношения на железнодорожном транспорте</p><br>

<p style="text-indent:2em;">(1) Трудовые отношения, в том числе связанные с гарантиями и компенсациями, предоставляемыми работникам предприятий и учреждений железнодорожного транспорта,
 регулируются настоящим кодексом, действующим законодательством о труде, а также международными договорами, одной из сторон которых является Республика Молдова.</p><br>

<p style="text-indent:2em;">(2) Режим труда и отдыха, условия труда отдельных категорий работников железнодорожного транспорта, служебные обязанности которых непосредственно связаны с движением поездов,
 устанавливаются отраслевым положением, утвержденным согласно действующему законодательству.</p><br>

<p style="text-indent:2em;">(3) Лица, принимаемые на работу в должностях, непосредственно связанных с движением поездов, проходят профессиональный отбор, они должны иметь соответствующую подготовку и здоровье,
 необходимые для исполнения служебных обязанностей. Перечень таких должностей утверждается администрацией железной дороги. Указанные работники, а также лица, работающие во вредных и тяжелых условиях,
 проходят в установленном порядке обязательные медицинские освидетельствования и проверки.</p><br>

<p style="text-indent:2em;">(4) Работники железнодорожных предприятий, организаций и учреждений ежегодно пользуются правом бесплатного проезда на железнодорожном транспорте.</p><br>

<p style="text-indent:2em;">(5) За лицами, вынужденными изменить место работы вследствие получения трудового увечья, развития профессионального заболевания либо вышедшими на пенсию по инвалидности в связи с трудовым увечьем,
 профессиональным заболеванием или иным повреждением здоровья, возникшим не по их вине, сохраняются право бесплатного проезда на железнодорожном транспорте и права на иные льготы, установленные действующим
 законодательством для работников железнодорожного транспорта, за счет средств соответствующих предприятий, организаций и учреждений.</p><br>

<p style="text-indent:2em;">(6) Работникам подразделений военизированной охраны железной дороги при исполнении служебных обязанностей гарантируется правовая и социальная защита,
 предусмотренная действующим законодательством для сотрудников органов внутренних дел и Министерства обороны.</p><br>

<p style="text-indent:2em;">(7) Условия и оплата труда, меры по обеспечению личной безопасности, гарантии правовой и социальной защиты работников подразделений военизированной охраны,
 а также гарантии и компенсации, предоставляемые работникам предприятий, организаций и учреждений железнодорожного транспорта, труд которых носит разъездной характер,
 при исполнении ими служебных обязанностей на территории других государств, определяются постановлениями Правительства и международными договорами, одной из сторон которых является Республика Молдова.</p><br>

<p style="font-weight:500;">Статья 21. Дисциплина на железнодорожном транспорте</p><br>

<p style="text-indent:2em;">(1) Дисциплина на железнодорожном транспорте регулируется действующим законодательством о труде и Положением о дисциплине работников железнодорожного транспорта, утвержденным Правительством.</p><br>

<p style="text-indent:2em;">(2) Прекращение работы как средство разрешения коллективных трудовых споров на железнодорожном транспорте запрещено.</p><br>

<p style="text-indent:2em;">(3) Прекращение работы по указанной в части (2) причине является грубым нарушением трудовой дисциплины и влечет за собой ответственность согласно действующему законодательству.</p>

<p></p><h4>Часть 6 ДРУГИЕ ВОПРОСЫ ДЕЯТЕЛЬНОСТИ ЖЕЛЕЗНОДОРОЖНОГО ТРАНСПОРТА И ЕЕ ОРГАНИЗАЦИИ<p></p></h4>

<p style="font-weight:500;">Статья 22. Учетно-отчетное время на железнодорожном транспорте</p><br>

<p style="text-indent:2em;">В целях обеспечения непрерывного централизованного управления перевозочным процессом на предприятиях, в организациях и учреждениях железнодорожного транспорта, непосредственно участвующих в перевозках,
 независимо от их местонахождения, устанавливается единое учетно-отчетное время - местное.</p><br>

<p style="font-weight:500;">Статья 23. Взаимодействие предприятий железнодорожного транспорта с предприятиями других видов транспорта, физическими и юридическими лицами, являющимися собственниками железнодорожных объектов</p><br>

<p style="text-indent:2em;">(1) При осуществлении перевозок пассажиров, грузов, багажа и грузобагажа в смешанном сообщении железнодорожный транспорт взаимодействует с другими видами транспорта - морским, речным,
 воздушным и автомобильным.</p><br>

<p style="text-indent:2em;">(2) Отношения железнодорожных предприятий с предприятиями других видов транспорта в процессе осуществления перевозок пассажиров и грузов строятся в соответствии с положениями кодексов (уставов),
 правилами и тарифами, регулирующими перевозки соответствующими видами транспорта, а также в соответствии с международными договорами, одной из сторон которых является Республика Молдова.</p><br>

<p style="text-indent:2em;">(3) Правовые отношения железнодорожных предприятий с юридическими лицами, являющимися собственниками железнодорожных объектов, а также порядок и условия эксплуатации принадлежащего им подвижного
 состава на железнодорожных путях общего пользования (магистральных) устанавливаются настоящим кодексом, другими действующими нормативными актами и заключенными на их основе договорами.</p><br>

<p style="text-indent:2em;">(4) Физические и юридические лица, являющиеся собственниками железнодорожных объектов, при необходимости взаимодействия таких объектов с сетью железных дорог общего пользования обеспечивают их
 развитие и содержание на уровне требований настоящего кодекса, что создает условия для безопасного ведения работ, эффективного использования подвижного состава, сохранности вагонного парка и 
 соблюдения установленных правилами или договорами норм простоя вагонов.</p><br>

<p style="font-weight:500;">Статья 24. Внешнеэкономическая деятельность железнодорожного транспорта Организация перевозок пассажиров, грузов, багажа и грузобагажа в международном железнодорожном и прямом
 смешанном международном сообщениях, развитие международных транспортных связей, внешнеэкономическая деятельность железнодорожного транспорта осуществляются центральным отраслевым
 органом публичного управления и администрацией железной дороги в соответствии с действующим законодательством и международными договорами, одной из сторон которых является Республика Молдова.

</p><p style="font-weight:500;">Статья 25. Мобилизационная подготовка железнодорожного транспорта</p><br>

<p style="text-indent:2em;">(1) Мобилизационная подготовка железнодорожного транспорта является важнейшей государственной задачей по укреплению безопасности страны и осуществляется в мирное время в соответствии с законом.</p><br>

<p style="text-indent:2em;">(2) Финансирование мероприятий по мобилизационной подготовке железнодорожного транспорта производится в порядке, установленном действующим законодательством.</p><br>

<p style="text-indent:2em;">(3) Руководство мероприятиями по мобилизационной подготовке и гражданской обороной на железнодорожном транспорте, а также ответственность за надлежащее состояние мобилизационной подготовки
 и гражданской обороны возлагаются на руководителей предприятий железнодорожного транспорта.</p><br>

<p style="font-weight:500;">Статья 26. Страхование на железнодорожном транспорте</p><br>

<p style="text-indent:2em;">(1) Порядок и условия обязательного личного страхования пассажиров и отдельных категорий работников железнодорожного транспорта определяются действующим законодательством в данной области.</p><br>

<p style="text-indent:2em;">(2) В соответствии с Законом об обязательном страховании гражданской ответственности перевозчиков перед пассажирами на железнодорожном транспорте наряду с обязательным может проводиться
 добровольное личное страхование пассажиров, а также добровольное страхование грузов и багажа.</p><br>

<p style="font-weight:500;">Статья 27. Ответственность предприятий железнодорожного транспорта</p><br>

<p style="text-indent:2em;">(1) Предприятия железнодорожного транспорта не вправе отказывать в перевозке пассажирам и грузоотправителям, оплатившим проезд, перевозку грузов, багажа, грузобагажа и правильно
 оформившим перевозочные документы, кроме случаев, предусмотренных настоящим кодексом, правилами и условиями перевозок, а также международными договорами, одной из сторон которых является Республики Молдова.</p><br>

<p style="text-indent:2em;">(2) Железнодорожные предприятия, осуществляющие перевозочный процесс, несут предусмотренную законом ответственность за вред, причиненный жизни и здоровью пассажиров или иных лиц,
 пострадавших от работы железнодорожного транспорта.</p><br>

<p style="text-indent:2em;">(3) Ответственность предприятий железнодорожного транспорта за неисполнение или ненадлежащее исполнение обязательств по перевозке пассажиров, хранению и перевозке грузов, багажа и грузобагажа,
 а также ответственность перед пассажирами за опоздание поезда устанавливается настоящим кодексом и соответствующими правилами.</p><br>

<p style="text-indent:2em;">(4) Согласно действующему законодательству предприятия железнодорожного транспорта несут ответственность за вред, причиненный по их вине окружающей природной среде,
 и за ненадлежащее содержание принадлежащих им земель.</p>

<p></p><h4>ГЛАВА II ФУНКЦИОНИРОВАНИЕ ЖЕЛЕЗНОЙ ДОРОГИ И ЕЕ ВЗАИМОДЕЙСТВИЕ С ГРУЗООТПРАВИТЕЛЯМИ, ГРУЗОПОЛУЧАТЕЛЯМИ, ПАССАЖИРАМИ, ДРУГИМИ ФИЗИЧЕСКИМИ И ЮРИДИЧЕСКИМИ ЛИЦАМИ<p></p></h4>

<p></p><h4>Часть 1 ОСОБЕННОСТИ ОРГАНИЗАЦИИ ЖЕЛЕЗНОДОРОЖНЫХ ПЕРЕВОЗОК<p></p></h4>

<p style="font-weight:500;">Статья 28. Особенности организации железнодорожных перевозок и утверждение правил их осуществления</p><br>

<p style="text-indent:2em;">(1) Правила оказания услуг по перевозке пассажиров, багажа, груза и грузобагажа для личных нужд на железнодорожном транспорте утверждаются Правительством.</p><br>

<p style="text-indent:2em;">(2) Особенности организации, обеспечения и выполнения воинских перевозок регулируются Уставом воинских железнодорожных перевозок, утвержденным Правительством.</p><br>

<p style="text-indent:2em;">(3) Перевозка опасных грузов с учетом их особенностей, а также страхование гражданской ответственности при перевозке таких грузов осуществляются в соответствии с действующим законодательством.</p><br>

<p style="text-indent:2em;">(4) Порядок взаимодействия таможенных органов и железной дороги обусловлен Положением о взаимодействии Таможенного департамента и Железной дороги Молдовы
 по таможенному оформлению грузов, перевозимых железнодорожным транспортом.</p><br>

<p style="font-weight:500;">Статья 29. Перевозки по строящимся железнодорожным линиям</p><br>

<p style="text-indent:2em;">Перевозки по строящимся железнодорожным линиям, не принятым в постоянную эксплуатацию, производятся железной дорогой на основании договоров, заключенных
 с организациями, которые осуществляют строительство или его финансирование.</p><br>

<p style="font-weight:500;">Статья 30. Особые условия перевозки конкретных грузов</p><br>

<p style="text-indent:2em;">В случаях, когда перевозка конкретного груза не может быть осуществлена на основании Правил перевозок грузов по железной дороге, центральный отраслевой
 орган публичного управления и администрация железной дороги вместе с заинтересованным грузоотправителем или грузополучателем могут установить в заключенном
 между ними договоре особые условия перевозки такого груза и ответственность сторон за его сохранность.</p><br>

<p style="font-weight:500;">Статья 31. Информация, предоставляемая железной дорогой</p><br>

<p style="text-indent:2em;">Железная дорога обязана предоставлять пассажирам, грузоотправителям и грузополучателям необходимую и достоверную информацию об оказываемых ею услугах.</p>

<p></p><h4>Часть 2 ХОЗЯЙСТВО ЖЕЛЕЗНОЙ ДОРОГИ, ОБЕСПЕЧИВАЮЩЕЕ ПЕРЕВОЗОЧНЫЙ ПРОЦЕСС<p></p></h4>

<p style="font-weight:500;">Статья 32. Железнодорожные станции</p><br>

<p style="text-indent:2em;">(1) Железнодорожные станции выполняют операции по приему, погрузке, выгрузке и выдаче грузов повагонными и мелкими отправками, в контейнерах,
 а также операции по перевозке пассажиров, багажа и грузобагажа. Они могут быть открыты для выполнения всех или отдельных операций.</p><br>

<p style="text-indent:2em;">(2) Железная дорога осуществляет перевозки пассажиров, грузов, багажа и грузобагажа между всеми железнодорожными станциями, открытыми для проведения соответствующих операций.</p><br>

<p style="text-indent:2em;">(3) Открытие или закрытие железнодорожных станций для выполнения всех или некоторых операций производится центральным отраслевым органом публичного управления по предложению железной дороги,
согласованному с органом местного публичного управления, с последующей публикацией соответствующей информации в сборнике "Правила перевозок и тарифы на железнодорожном транспорте".</p><br>

<p style="text-indent:2em;">(4) Для оформления перевозочных документов, оказания других услуг и взимания платы за перевозки и услуги на железнодорожных станциях организуются товарные конторы, багажные и билетные кассы,
другие подразделения железной дороги по обслуживанию пассажиров, грузоотправителей и грузополучателей.</p><br>

<p style="font-weight:500;">Статья 33. Подвижной состав, предназначенный для перевозки пассажиров, грузов, багажа и грузобагажа</p><br>

<p style="text-indent:2em;">(1) Перевозки грузов, багажа и грузобагажа по железной дороге осуществляются в вагонах и контейнерах парка железнодорожного транспорта, а также в вагонах, 
являющихся собственностью физических и юридических лиц или арендованных ими.</p><br>

<p style="text-indent:2em;">(2) Подвижной состав, предназначенный для перевозки пассажиров, грузов, багажа и грузобагажа по железнодорожным путям общего пользования (магистральным),
 независимо от его принадлежности, должен отвечать требованиям стандартов, а также Правил технической эксплуатации железной дороги, утвержденных центральным
 отраслевым органом публичного управления. На каждую единицу приобретаемого подвижного состава должен быть сертификат. После капитального ремонта вагонов проверяется их масса.</p><br>

<p style="text-indent:2em;">(3) Контейнеры, используемые для перевозки грузов, независимо от их принадлежности, должны отвечать требованиям стандартов. На каждый изготавливаемый контейнер должен быть сертификат.</p><br>

<p style="font-weight:500;">Статья 34. Весовое хозяйство</p><br>

<p style="text-indent:2em;">(1) Для взвешивания перевозимых грузов, багажа и грузобагажа железная дорога в местах общего пользования, а грузоотправители и грузополучатели - в местах
 собственного пользования согласно действующему законодательству должны иметь необходимое количество весовых приборов.</p><br>

<p style="text-indent:2em;">(2) Весовые приборы подлежат метрологической поверке с последующим опломбированием и обязательным государственным надзором, который осуществляется в порядке,
 установленном центральным органом публичного управления по стандартизации, метрологии и техническому надзору.</p><br>

<p style="text-indent:2em;">(3) Железная дорога в порядке, установленном центральным органом публичного управления по стандартизации, метрологии и техническому надзору,
 может на договорных условиях принимать на техническое обслуживание (ремонт, поверка в рамках технического контроля) средства измерения, включая весовые приборы,
 принадлежащие грузоотправителям, грузополучателям, другим юридическим лицам.</p>

<p></p><h4>Часть 3 ОРГАНИЗАЦИЯ ПЕРЕВОЗОК ГРУЗОВ<p></p></h4>

<p style="font-weight:500;">Статья 35. Места для осуществления погрузочно-разгрузочных операций</p><br>

<p style="text-indent:2em;">(1) Погрузочно-разгрузочные операции и сортировка грузов и контейнеров осуществляются в местах общего и собственного пользования, где должны быть оборудованные надлежащим образом сооружения
 и устройства для бесперебойной, безопасной обработки вагонов и контейнеров, обеспечения сохранности грузов. Такие места должны отвечать требованиям по охране окружающей среды.</p><br>

<p style="text-indent:2em;">(2) Соответствие мест общего и собственного пользования требованиям настоящей статьи обеспечивается за счет средств их владельцев, а соответствие специально выделенных участков
 - за счет средств грузоотправителей или грузополучателей, которым были предоставлены эти участки.</p><br>

<p style="font-weight:500;">Статья 36. Оборудование мест для переработки перевозимых грузов</p><br>

<p style="text-indent:2em;">(1) Места общего пользования, в которых выполняется значительный объем операций по перевозкам отдельных грузов, оборудуются специальными погрузочно-разгрузочными устройствами
 (эстакадами, специализированными площадками и складами, скотопогрузочными платформами), водопойными пунктами, очистными сооружениями, дезинфекционно-промывочными устройствами.</p><br>

<p style="text-indent:2em;">(2) Места собственного пользования оборудуются механизмами, устройствами и сооружениями, которые должны обеспечивать своевременное выполнение необходимых погрузочно-разгрузочных операций,
 восстановление сыпучести смерзающихся грузов, очистку (промывку) вагонов и контейнеров после выгрузки, а также их сохранность.</p><br>

<p style="font-weight:500;">Статья 37. Договоры о перевозках</p><br>

<p style="text-indent:2em;">(1) Грузоотправители, систематически осуществляющие перевозки грузов, могут заключать с железной дорогой долгосрочные договоры о перевозках.</p><br>

<p style="text-indent:2em;">(2) В договорах о перевозках указываются объемы грузов, сроки и условия предоставления транспортных средств и предъявления грузов к перевозке, порядок расчетов,
 а также другие условия выполнения перевозок. Согласно таким договорам железная дорога обязуется принимать в установленные сроки, а грузоотправитель - предъявлять к перевозке грузы в оговоренном объеме.</p><br>

<p style="font-weight:500;">Статья 38. Заявки на перевозку грузов</p><br>

<p style="text-indent:2em;">(1) Перевозки грузов по железной дороге осуществляются в соответствии с заявками, которые представляются грузоотправителями администрации железной дороги не менее чем за 10 дней,
 а при перевозках экспортируемых грузов и грузов, транспортируемых в прямом смешанном сообщении, - не менее чем за 15 дней до начала календарного месяца.</p><br>

<p style="text-indent:2em;">(2) В случаях необходимости в срочной перевозке грузов железная дорога по договоренности с грузоотправителями может устанавливать иные сроки представления заявок на перевозку при наличии на 
станции порожних вагонов.</p><br>

<p style="text-indent:2em;">(3) Перевозки грузов, предназначенных для ликвидации последствий чрезвычайных ситуаций, осуществляются железной дорогой по их предъявлении.</p>

<p style="text-indent:2em;"></p><h6>(4) За внесение по инициативе грузоотправителей изменений в заявки на перевозку грузов в прямом сообщении с их распределением по железным дорогам назначения или в заявки на перевозку
 грузов в местном сообщении с их распределением по железнодорожным станциям назначения грузоотправители уплачивают дополнительные сборы (в том числе в случаях, когда заявки выполняются полностью) в следующих размерах:</h6><p></p>

<p>a) по грузам, перевозки которых осуществляются в вагонах и тоннах, - 0,9 лея с тонны груза;</p><br>

<p style="text-indent:2em;">b) по грузам, перевозки которых осуществляются только в вагонах, - 45 леев за каждый вагон, независимо от вида и количества осей;</p><br>

<p style="text-indent:2em;">c) по грузам, перевозки которых осуществляются в контейнерах, - 1,8 лея за контейнер массой брутто до 5 тонн включительно, 5,4 лея за контейнер массой брутто от 5 до 10 тонн включительно,
 27 леев за контейнер массой брутто свыше 10 тонн.</p><br>

<p style="text-indent:2em;">(5) Администрация железной дороги рассматривает заявки на перевозку грузов в прямом сообщении в течение трех дней, заявки на перевозку экспортируемых грузов и грузов, транспортируемых в прямом смешанном сообщении,
 - в течение десяти дней и извещает грузоотправителей о своем решении или возвращает им заявки с обоснованием отказа.</p><br>

<p style="text-indent:2em;">(6) В заявке на перевозку грузов, которая представляется администрации железной дороги в трех экземплярах, указывается объем грузов в вагонах и тоннах согласно установленной номенклатуре,
 с распределением грузов по видам, железным дорогам назначения и датам погрузки, а при перевозке грузов в пределах железной дороги отправления - с распределением по видам,
 станциям назначения и датам погрузки. Один экземпляр заявки с отметкой о ее принятии направляется грузоотправителю, другой - начальнику станции отправления.</p><br>

<p style="text-indent:2em;">(7) Не позднее чем за три дня до начала выполнения заявки на перевозку грузов начальник станции отправления вместе с грузоотправителем уточняет количество необходимых вагонов и контейнеров для подачи их по дням,
 для соответствующих видов грузов и железных дорог назначения. Эти данные заносятся в учетную карточку, которую по окончании каждых отчетных суток подписывает начальник железнодорожной станции и грузоотправитель.</p>

<p style="text-indent:2em;"></p><h6>(8) По просьбам грузоотправителей администрация железной дороги имеет право:</h6><p></p>

<p style="text-indent:2em;">a) в исключительных случаях разрешать внутристанционные (в пределах одной железнодорожной станции) перевозки грузов. Такие перевозки включаются в заявки и
 учитываются в выполненном объеме перевозок грузов;</p><br>

<p style="text-indent:2em;">b) заменять предусмотренный заявкой на перевозку грузов крытый подвижной состав открытым подвижным составом в случаях,
 если перевозка этих грузов согласно Правилам перевозок грузов по железной дороге может осуществляться в открытом подвижном составе, а также заменять один вид открытого подвижного состава другим;</p><br>

<p>c) изменять предусмотренные в заявках железные дороги и станции назначения.</p><br>

<p style="font-weight:500;">Статья 39. Порядок заполнения и выполнения заявок на перевозку грузов</p><br>

<p style="text-indent:2em;">Форма заявки на перевозку грузов и порядок ее заполнения согласно установленной номенклатуре, порядок выполнения и учета таких заявок устанавливаются центральным отраслевым органом публичного
 управления и публикуются в сборнике "Правила перевозок и тарифы на железнодорожном транспорте".</p><br>

<p style="font-weight:500;">Статья 40. Скорости перевозки грузов</p><br>

<p>(1) Грузы принимаются к перевозке скоростью грузовых поездов или большой скоростью.</p><br>

<p style="text-indent:2em;">(2) К перевозке большой скоростью принимаются грузы, транспортируемые в направлениях, установленных центральным отраслевым органом публичного управления. Перечень таких грузов публикуется в сборнике
 "Правила перевозок и тарифы на железнодорожном транспорте".</p><br>

<p style="text-indent:2em;">(3) Скорость перевозки выбирает и указывает в накладной грузоотправитель. Если перевозка груза допускается только большой скоростью, грузоотправитель должен указать только эту скорость.</p><br>

<p style="font-weight:500;">Статья 41. Порядок взимания провозной платы</p><br>

<p style="text-indent:2em;">При перевозке грузов провозная плата взимается за кратчайшее расстояние. В случаях, предусмотренных Тарифным руководством, провозная плата взимается за фактически пройденное расстояние.</p><br>

<p style="font-weight:500;">Статья 42. Порядок перевозки грузов</p><br>

<p style="text-indent:2em;">Перевозки грузов по железнодорожным линиям узкой колеи, а также со станций железных дорог широкой колеи на станции железных дорог узкой колеи и в обратном направлении осуществляются в порядке,
 установленном Правилами перевозок грузов по железной дороге.</p><br>

<p style="font-weight:500;">Статья 43. Объявление ценности перевозимых грузов</p><br>

<p style="text-indent:2em;">(1) Грузоотправители могут предъявлять к перевозке грузы с объявленной ценностью. Объявление ценности обязательно при предъявлении к перевозке без сопровождения драгоценных металлов (камней) ,
 изделий из них, предметов искусства, антиквариата, иных художественных изделий, видео- и аудиоаппаратуры, электронно-вычислительной и множительной техники, опытных образцов машин,
 оборудования и приборов, грузов бытового назначения и для личных нужд.</p><br>

<p>(2) За объявление ценности груза взимается дополнительный сбор, предусмотренный Тарифным руководством.</p><br>

<p style="font-weight:500;">Статья 44. Сопровождение грузов работниками военизированной охраны</p><br>

<p style="text-indent:2em;">(1) Перевозки грузов, перечень которых утверждается в порядке, установленном центральным отраслевым органом публичного управления, осуществляются в сопровождении работников военизированной
 охраны железной дороги. За сопровождение этих грузов с грузоотправителей взимаются дополнительные сборы, указанные в Тарифном руководстве.</p><br>

<p style="text-indent:2em;">(2) Перечень грузов, охрану и сопровождение которых осуществляют представители грузоотправителей или грузополучателей, определяется Правилами перевозок грузов по железной дороге.</p><br>

<p style="text-indent:2em;">(3) Грузы, не включенные в предусмотренные настоящей статьей перечни, могут сопровождаться представителями грузоотправителей или грузополучателей и в соответствии с договорами,
 заключенными с железной дорогой, - работниками ее военизированной охраны.</p><br>

<p style="font-weight:500;">Статья 45. Подготовка грузов к перевозке</p><br>

<p style="text-indent:2em;">(1) Грузоотправитель обязан подготовить грузы к перевозке таким образом, чтобы были обеспечены безопасность движения и сохранность грузов, вагонов и контейнеров.</p><br>

<p style="text-indent:2em;">(2) Требования к упаковке грузов и качеству продукции, перевозимой по железной дороге, предусматриваются стандартами и техническими условиями,
 утвержденными уполномоченными на то организациями по согласованию с центральным отраслевым органом публичного управления.</p><br>

<p style="font-weight:500;">Статья 46. Ответственность и меры, принимаемые при возникновении аварийных ситуаций</p><br>

<p style="text-indent:2em;">(1) Грузоотправители несут ответственность и возмещают расходы, связанные с ликвидацией аварийных ситуаций, загрязнения и заражения окружающей среды, с перерывами в движении поездов,
 возникшими по их вине. Такую же ответственность несет железная дорога при возникновении аварийных ситуаций по ее вине.</p><br>

<p style="text-indent:2em;">(2) Если во время осуществления перевозки грузов возникает аварийная ситуация, грузоотправитель и грузополучатель, получив сообщение об этом от железной дороги,
 обязаны немедленно направить на место происшествия мобильное подразделение или своего представителя.</p><br>

<p style="font-weight:500;">Статья 47. Подготовка и подача вагонов и контейнеров под погрузку</p><br>

<p style="text-indent:2em;">(1) Работник железнодорожной станции уведомляет грузоотправителя о времени подачи вагонов и контейнеров под погрузку средствами грузоотправителя не позднее чем за два часа до их подачи.</p><br>

<p style="text-indent:2em;">(2) Железная дорога обязана подавать под погрузку вагоны и контейнеры в технически исправном состоянии, со снятыми приспособлениями для крепления грузов (за исключением несъемных) ,
 очищенными внутри и снаружи, а в необходимых случаях - промытыми и продезинфицированными, годными для перевозки конкретных видов грузов.</p><br>

<p style="text-indent:2em;">(3) Подготовка под налив цистерн и бункерных полувагонов, принадлежащих железной дороге, производится ею или согласно договору грузоотправителем за счет железной дороги.</p><br>

<p style="text-indent:2em;">(4) Подготовка под налив специализированных цистерн, не принадлежащих железной дороге или сданных ею в аренду, производится грузоотправителем.</p>

<p style="text-indent:2em;"></p><h6>(5) Пригодность, с коммерческой точки зрения, вагонов и контейнеров для перевозки определенных видов грузов определяется следующим образом:</h6><p></p>

<p style="text-indent:2em;">a) вагонов - грузоотправителем, если погрузка осуществляется им, или железной дорогой, если погрузка производится ею;</p><br>

<p style="text-indent:2em;">b) контейнеров - грузоотправителем.</p><br>

<p style="text-indent:2em;">(6) Перед наливом цистерн грузоотправитель проверяет герметичность котлов цистерн, техническое состояние арматуры и универсальных сливных устройств.</p><br>

<p style="text-indent:2em;">(7) Грузоотправитель вправе отказаться от вагонов и контейнеров, не годных для перевозки определенных видов грузов, а железная дорога обязана подать другие вагоны и
контейнеры в технически исправном состоянии,годные для перевозки таких грузов.</p><br>

<p style="text-indent:2em;">(8) При подаче на железнодорожный подъездной путь груженых вагонов в порядке сдвоенных операций железная дорога определяет их техническую пригодность для погрузки определенных видов грузов.</p><br>

<p style="font-weight:500;">Статья 48. Погрузка грузов</p>

<p style="text-indent:2em;"></p><h6>(1) Погрузка грузов в вагоны и на грузовые автомобили, а также их выгрузка должны осуществляться:</h6><p></p>

<p style="text-indent:2em;">a) железной дорогой - в местах общего пользования с согласия грузоотправителей или грузополучателей, за исключением опасных, скоропортящихся грузов, сырых продуктов животного происхождения,
 грузов массой одного места свыше 0,5 тонны, которые перевозятся в крытых вагонах, негабаритных грузов, грузов, перевозимых наливом, насыпью и навалом, в специализированном подвижном составе
 и в сопровождении представителей грузоотправителей и грузополучателей. За погрузку и выгрузку грузов железная дорога взимает с грузоотправителей и грузополучателей дополнительный сбор в размере,
 предусмотренном Тарифным руководством;</p><br>

<p style="text-indent:2em;">b) грузоотправителями и грузополучателями - в местах собственного пользования, а также в местах общего пользования, если погрузка и выгрузка грузов не обязательна для железной дороги.</p><br>

<p style="text-indent:2em;">(2) Погрузка грузов в контейнеры и их выгрузка производятся грузоотправителями и грузополучателями.</p><br>

<p style="font-weight:500;">Статья 49. Нормы загрузки вагонов и контейнеров, технические условия их погрузки</p><br>

<p style="text-indent:2em;">(1) Грузы должны загружаться в вагоны и контейнеры согласно техническим нормам их загрузки, установленным центральным отраслевым органом публичного управления, но без превышения грузоподъемности по маркировке,
 указанной на вагонах и контейнерах.</p><br>

<p style="text-indent:2em;">(2) Размещение и крепление грузов в вагонах, в том числе в открытом подвижном составе, и контейнерах осуществляются в соответствии с требованиями Технических условий
 погрузки и крепления грузов в вагонах и контейнерах, утвержденных центральным отраслевым органом публичного управления.</p><br>

<p style="text-indent:2em;">(3) Перечень грузов, перевозка которых допускается в открытом подвижном составе, а также перечни грузов, которые могут перевозиться насыпью и навалом,
 определяются центральным отраслевым органом публичного управления и подлежат опубликованию в сборнике "Правила перевозок и тарифы на железнодорожном транспорте".</p><br>

<p style="font-weight:500;">Статья 50. Приспособления для погрузки, крепления и перевозки грузов</p><br>

<p style="text-indent:2em;">(1) Оборудование, материалы, средства пакетирования и иные приспособления, необходимые для погрузки, крепления и перевозки грузов, предоставляются грузоотправителями.
 Их установка при погрузке и снятие при выгрузке осуществляются грузоотправителями и грузополучателями или железной дорогой, в зависимости от того, кто выполняет эти операции.</p><br>

<p style="text-indent:2em;">(2) Грузоотправитель указывает в накладной, какие приспособления установлены. Грузополучателю эти приспособления выдаются вместе с грузами.</p><br>

<p style="font-weight:500;">Статья 51. Перевозочные документы</p><br>

<p style="text-indent:2em;">(1) При предъявлении грузов к перевозке грузоотправитель должен представить на каждую отправку груза накладную и другие документы, предусмотренные Правилами перевозок грузов по железной
 дороге и Соглашением о международном железнодорожном грузовом сообщении.</p><br>

<p style="text-indent:2em;">(2) Дубликат накладной о приеме груза, выданный грузоотправителю, удостоверяет заключение договора о перевозке груза.</p><br>

<p style="text-indent:2em;">(3) В соответствии с договором о перевозке груза железная дорога обязана своевременно и в сохранности доставить груз на станцию назначения с соблюдением условий перевозки и выдать груз грузополучателю,
 а грузоотправитель должен оплатить перевозку.</p><br>

<p style="text-indent:2em;">(4) Центральный отраслевой орган публичного управления разрабатывает и утверждает формы документов на перевозку грузов по железнодорожным путям общего пользования (магистральным),
 публикуемые в сборнике "Правила перевозок и тарифы на железнодорожном транспорте".</p><br>

<p style="text-indent:2em;">(5) В подтверждение приема груза к перевозке железная дорога обязана поставить в накладной календарный штемпель и выдать грузоотправителю под расписку в соответствующей графе корешка
 дорожной ведомости дубликат накладной.</p><br>

<p style="text-indent:2em;">(6) Железная дорога выдает грузоотправителям и грузополучателям бланки перевозочных документов, заявок на перевозку, а также, по их просьбе, за плату копии документов,
 в том числе учетных карточек, ведомостей подачи и уборки вагонов, памяток приемосдатчика.</p><br>

<p style="font-weight:500;">Статья 52. Масса груза</p><br>

<p style="text-indent:2em;">(1) При предъявлении грузов к перевозке грузоотправитель должен указать в перевозочных документах их массу, а для тарных и штучных грузов - и количество грузовых мест.</p><br>

<p style="text-indent:2em;">(2) Не разрешается определение массы грузов расчетным путем или посредством обмера грузов, погрузка которых до полной вместимости вагонов и контейнеров может привести к
 превышению допустимой грузоподъемности.</p>

<p style="text-indent:2em;"></p><h6>(3) Определение массы грузов производится:</h6><p></p>

<p>a) железной дорогой - при погрузке собственными средствами в местах общего пользования;</p><br>

<p>b) грузоотправителем - при погрузке в местах собственного пользования.</p><br>

<p style="text-indent:2em;">(4) Во всех случаях определение массы грузов по маркировке, по стандарту, расчетным путем, посредством обмера или условно производится грузоотправителем.</p><br>

<p style="text-indent:2em;">(5) За взвешивание грузов железной дорогой взимается дополнительный сбор, предусмотренный Тарифным руководством.</p><br>

<p style="font-weight:500;">Статья 53. Ответственность за достоверность данных, внесенных в перевозочные документы</p><br>

<p style="text-indent:2em;">(1) Грузоотправитель несет предусмотренную настоящим кодексом имущественную ответственность за достоверность данных, внесенных в накладную, а также за последствия неправильности,
 неточности или неполноты сведений, указанных им в накладной.</p><br>

<p style="text-indent:2em;">(2) Железная дорога имеет право проверять достоверность внесенных в накладную данных, в том числе массу груза, которая указывается грузоотправителем, путем выборочного взвешивания.
 В случаях установления недостоверности данных расходы на осуществление проверок несут грузоотправители.</p><br>

<p style="text-indent:2em;">(3) За внесение в накладную неправильных, неточных, неполных сведений о грузах, требующих при перевозке особых мер предосторожности, за отправление грузов, не допускаемых к перевозке,
 либо неправильное указание свойств грузов грузоотправители несут имущественную ответственность, предусмотренную статьей 133.</p><br>

<p style="font-weight:500;">Статья 54. Порядок опломбирования вагонов и контейнеров</p><br>

<p style="text-indent:2em;">(1) Загруженные вагоны и контейнеры должны быть опломбированы железной дорогой, если погрузка производилась ею, или грузоотправителем, если грузы были погружены грузоотправителем.</p><br>

<p>(2) При перевозках грузов для личных нужд крытые вагоны и контейнеры пломбируются железной дорогой.</p><br>

<p style="text-indent:2em;">(3) В случаях вскрытия вагонов и контейнеров для таможенного досмотра их опломбирование новыми запорно-пломбировочными устройствами производится таможенными органами.</p><br>

<p style="text-indent:2em;">(4) Порядок опломбирования вагонов и контейнеров запорно-пломбировочными устройствами, а также перечень грузов, допускаемых к перевозке в вагонах и контейнерах без пломб,
 устанавливаются Правилами перевозок грузов по железной дороге.</p><br>

<p style="text-indent:2em;">(5) Запорно-пломбировочные устройства и устройства для их снятия предоставляются железной дорогой грузоотправителям или грузополучателям за плату, соответствующую фактическим затратам.</p><br>

<p style="font-weight:500;">Статья 55. Временное прекращение либо ограничение погрузки грузов, багажа и грузобагажа</p><br>

<p style="text-indent:2em;">(1) При форс-мажорных обстоятельствах: военных действиях, блокаде, эпидемиях - или иных обстоятельствах, препятствующих перевозкам грузов, погрузка грузов,
 багажа и грузобагажа может быть временно прекращена либо ограничена руководителем железной дороги отправления, с немедленным уведомлением об этом в письменной
 форме руководителя центрального отраслевого органа публичного управления, который устанавливает продолжительность прекращения или ограничения погрузки и информирует об этом руководителя железной дороги.</p><br>

<p style="text-indent:2em;">(2) Временное прекращение на территории Республики Молдова операций по погрузке грузов, багажа и грузобагажа для перевозки в определенных направлениях вследствие сложившейся
 на железной дороге ситуации допускается только в исключительных случаях по письменному распоряжению руководителя центрального отраслевого органа публичного управления, с немедленным извещением об этом Правительства.</p><br>

<p style="text-indent:2em;">(3) Железная дорога должна уведомлять грузоотправителей о временном прекращении или ограничении погрузки грузов, багажа и грузобагажа для перевозки в определенных направлениях.
 В течение 12 часов после получения уведомления грузоотправители обязаны приостановить или ограничить до установленных объемов погрузку соответствующих грузов, багажа и грузобагажа.</p><br>

<p style="font-weight:500;">Статья 56. Порядок осуществления расчетов за перевозку грузов</p><br>

<p style="text-indent:2em;">(1) Порядок осуществления расчетов за перевозку грузов между железной дорогой и грузоотправителями устанавливается центральным отраслевым органом публичного управления в соответствии
 с действующим законодательством и нормативными актами Национального банка Молдовы.</p><br>

<p style="text-indent:2em;">(2) Плата за перевозку и другие причитающиеся железной дороге платежи вносятся грузоотправителем до момента отправления грузов с железнодорожной станции,
 если иное не предусмотрено настоящим кодексом или заключенным договором. До внесения указанных платежей железная дорога может задержать отправку грузов.</p><br>

<p style="text-indent:2em;">(3) Подача вагонов и контейнеров для следующей погрузки прекращается до внесения платы за перевозку грузов и иных причитающихся железной дороге платежей за предыдущую перевозку.
 В виде исключения в таких случаях подача вагонов и контейнеров может быть осуществлена лишь по письменному распоряжению руководителя железной дороги, согласованному с 
 центральным отраслевым органом публичного управления.</p><br>

<p style="text-indent:2em;">(4) В случаях перевозки грузов в местном сообщении руководитель железной дороги может принять решение о внесении платы за перевозку и иных причитающихся железной дороге платежей на станции назначения,
 согласовав свое решение в установленном порядке с грузоотправителем и грузополучателем, а в случаях перевозки грузов в прямом сообщении руководитель железной дороги отправления должен согласовать
 такое решение с центральным отраслевым органом публичного управления. При согласовании предусматриваются гарантии внесения платы за перевозку грузов и других платежей, а также действия сторон в
 случае несоблюдения взятых ими обязательств.</p><br>

<p style="text-indent:2em;">(5) Окончательные расчеты, связанные с перевозкой грузов, производятся грузополучателем по прибытии грузов на станцию назначения.</p><br>

<p style="text-indent:2em;">(6) Плата за перевозку грузов и другие причитающиеся железной дороге платежи вносятся наличными деньгами, чеками или иным образом, предусмотренным нормативными актами Национального 
банка Молдовы.</p><br>

<p style="text-indent:2em;">(7) Сроком внесения платы за перевозку грузов и других причитающихся железной дороге платежей на железнодорожной станции отправления является дата приема грузов к перевозке.
 Сроком расчета на железнодорожной станции назначения является дата выдачи грузов грузополучателю, указанная в накладной.</p><br>

<p style="text-indent:2em;">(8) При несвоевременном осуществлении расчетов за перевозку грузов по вине грузоотправителя или грузополучателя с них взыскивается в пользу железной дороги пеня в
 пределах одного процента за каждый день просрочки.</p><br>

<p style="text-indent:2em;">(9) До внесения на станции назначения всех причитающихся железной дороге платежей вагоны и контейнеры находятся на простое под ответственность грузополучателя,
 с него же взимаются дополнительный сбор за хранение грузов и плата за пользование вагонами и контейнерами.</p><br>

<p style="font-weight:500;">Статья 57. Переадресовка грузов</p><br>

<p style="text-indent:2em;">(1) По заявлению грузоотправителя или грузополучателя железная дорога переадресовывает перевозимые грузы с изменением грузополучателя или станции назначения. 
При этом переадресовка грузов, находящихся под таможенным контролем, производится с согласия соответствующего таможенного органа. Если перевозка находящихся под
 таможенным контролем грузов угрожает жизни и здоровью людей, безопасности движения, экологической безопасности, их переадресовка производится без согласия таможенного органа.
 В таких случаях железная дорога обязана уведомить о переадресовке грузов соответствующий таможенный орган.</p><br>

<p>(2) За переадресовку грузов железная дорога устанавливает и взимает дополнительный сбор.</p><br>

<p style="font-weight:500;">Статья 58. Ответственность за переадресовку грузов</p><br>

<p style="text-indent:2em;">Во всех случаях изменения грузополучателя или железнодорожной станции назначения сторона, по заявлению которой произведено изменение, является ответственной перед первоначальным грузополучателем 
за последствия такого изменения и обязана урегулировать расчеты между грузоотправителем, первоначальным и фактическим грузополучателями.</p><br>

<p style="font-weight:500;">Статья 59. Сроки доставки грузов и порядок их исчисления</p><br>

<p style="text-indent:2em;">(1) Железная дорога обязана доставлять грузы, в том числе для личных нужд, по назначению и в установленные сроки.</p><br>

<p style="text-indent:2em;">(2) Сроки доставки грузов и порядок их исчисления устанавливаются Правилами перевозок грузов по железной дороге.</p><br>

<p style="text-indent:2em;">(3) Грузоотправитель и железная дорога могут предусмотреть в договоре о перевозке груза и иной срок доставки.</p><br>

<p>(4) Исчисление срока доставки начинается с 24 часов дня приема грузов к перевозке.</p><br>

<p style="text-indent:2em;">(5) Дата приема грузов к перевозке и дата истечения срока их доставки указываются станцией отправления в выдаваемом грузоотправителю дубликате накладной.</p><br>

<p style="text-indent:2em;">(6) Грузы считаются доставленными в срок, если на железнодорожной станции назначения они выгружаются железной дорогой или если вагоны либо контейнеры с грузами поданы грузополучателю для выгрузки
 до истечения установленного срока доставки.</p><br>

<p style="text-indent:2em;">(7) Грузы считаются доставленными в срок и в том случае, если вагоны или контейнеры с ними прибыли на железнодорожную станцию назначения до истечения установленного срока доставки и подача
 их задержалась из-за занятости фронта выгрузки, невнесения провозной платы и других причитающихся железной дороге платежей по перевозке или по другим причинам, зависящим от грузополучателя,
 о чем составляется акт общей формы.</p><br>

<p style="text-indent:2em;">(8) За несоблюдение сроков доставки грузов, за исключением случаев, предусмотренных статьей 55, железная дорога уплачивает пеню в соответствии со статьей 131.</p><br>

<p style="font-weight:500;">Статья 60. Уведомление о прибытии грузов на станцию</p><br>

<p style="text-indent:2em;">(1) Железная дорога обязана уведомить грузополучателя о прибывших в его адрес грузах не позднее чем в 12 часов дня, следующего за днем прибытия грузов. Порядок и способы уведомления
 устанавливаются начальником железнодорожной станции назначения. По просьбе грузополучателя может быть установлен и иной порядок уведомления.</p><br>

<p style="text-indent:2em;">(2) Если железная дорога не уведомит грузополучателя о прибытии грузов, он освобождается от внесения платы за пользование вагонами, контейнерами и дополнительного сбора
 за хранение грузов до получения соответствующего уведомления.</p><br>

<p style="text-indent:2em;">(3) Железная дорога обязана уведомить таможенный орган о прибытии на станцию назначения грузов, находящихся под таможенным контролем. Задержка выдачи грузов в связи с
 прохождением грузополучателем таможенных процедур не освобождает его от внесения всех причитающихся железной дороге платежей.</p><br>

<p style="text-indent:2em;">(4) Железнодорожная станция уведомляет грузополучателя о времени подачи вагонов и контейнеров под выгрузку средствами грузополучателя не позднее чем за два часа до их подачи.</p><br>

<p style="text-indent:2em;">(5) Железная дорога на договорной основе может предоставлять грузополучателю предварительную информацию о прибывающих в его адрес грузах.</p><br>

<p style="font-weight:500;">Статья 61. Условия и порядок выдачи грузов</p><br>

<p style="text-indent:2em;">(1) На станции назначения грузы выдаются грузополучателю после внесения платы за перевозку и других причитающихся железной дороге платежей. 
Грузы, находящиеся под таможенным контролем, должны выдаваться только с согласия соответствующего таможенного органа, в установленном порядке.</p><br>

<p style="text-indent:2em;">(2) Подтверждением времени выдачи грузов считается дата их вывоза с грузового двора станции при выгрузке средствами железной дороги или дата подачи вагонов для выгрузки средствами грузополучателя
 в места общего и собственного пользования.</p>

<p style="text-indent:2em;"></p><h6>(3) В случаях уклонения грузополучателя от внесения платы за перевозку грузов и иных платежей, причитающихся железной дороге, последняя вправе задержать грузы с уведомлением об
 этом грузоотправителя в письменной форме. В срок, не превышающий четырех суток после получения уведомления, грузоотправитель обязан распорядиться грузами. Если в течение указанного
 срока грузополучатель не примет мер для внесения платы за перевозку и иных платежей, причитающихся железной дороге, а грузоотправитель не распорядится грузами, железная дорога вправе,
 если иное не предусмотрено договором о перевозке, реализовать задерживаемые грузы, за исключением:</h6><p></p>

<p>a) грузов, изъятых из оборота и ограниченных в обороте в соответствии с действующим законодательством;</p><br>

<p>b) специальных (в том числе воинских) грузов, перевозимых для государственных и оборонных нужд;</p><br>

<p style="text-indent:2em;">c) грузов, плата за перевозку которых и иные платежи, причитающиеся железной дороге, вносятся на станции назначения в порядке, предусмотренном статьей 56.</p><br>

<p style="text-indent:2em;">(4) При реализации уполномоченными на то государственными органами изъятых грузов, а также грузов , от которых грузополучатели или грузоотправители отказались в пользу государства,
 сумма платы за перевозку таких грузов и иные платежи, причитающиеся железной дороге, перечисляются этими органами на счет железной дороги, где произведено изъятие грузов или получен
 отказ от них, из средств от реализации грузов.</p><br>

<p style="font-weight:500;">Статья 62. Порядок принятия грузополучателем грузов, поставка которых не предусмотрена договором</p><br>

<p style="text-indent:2em;">(1) В случаях прибытия в адрес грузополучателя грузов, поставка которых не предусмотрена договором между грузоотправителем и грузополучателем, или грузов,
 наименование которых не соответствует указанному в накладной, грузополучатель обязан принять их от железнодорожной станции на ответственное хранение для последующего
 урегулирования отношений с грузоотправителем.</p><br>

<p style="text-indent:2em;">(2) Грузополучатель может отказаться от получения указанных в части (1) грузов лишь в случае, если их качество, изменившееся в результате порчи или повреждения грузов,
 исключает возможность полного или частичного использования последних.</p><br>

<p style="text-indent:2em;">(3) Грузополучатель несет имущественную ответственность за порчу грузов, произошедшую из-за их несвоевременных выгрузки и вывоза, а также из-за задержки вагонов по его
 вине на железнодорожных подъездных и станционных путях.</p><br>

<p style="font-weight:500;">Статья 63. Сроки и порядок хранения грузов на железнодорожной станции</p><br>

<p style="text-indent:2em;">Прибывшие грузы и контейнеры, подлежащие соответственно выгрузке и выдаче в местах общего пользования, хранятся на железнодорожной станции назначения бесплатно в течение 24 часов.
 Этот срок исчисляется с 24 часов дня выгрузки железной дорогой грузов и контейнеров или с 24 часов дня подачи железной дорогой вагонов и контейнеров к предусмотренному грузополучателем
 месту выгрузки. За хранение грузов на железнодорожной станции сверх указанного срока взимается дополнительный сбор, предусмотренный Тарифным руководством. Сроки и порядок хранения грузов
 на железнодорожной станции назначения устанавливаются Правилами перевозок грузов по железной дороге.</p><br>

<p style="font-weight:500;">Статья 64. Плата за пользование вагонами и контейнерами</p><br>

<p style="text-indent:2em;">(1) За время нахождения вагонов и контейнеров в распоряжении грузоотправителя или грузополучателя, а также за время ожидания железной дорогой подачи или приема вагонов и контейнеров
 по вине грузоотправителя или грузополучателя они вносят установленную Тарифным руководством почасовую плату за пользование вагонами и контейнерами.</p><br>

<p style="text-indent:2em;">(2) Ставки платы за пользование вагонами и контейнерами устанавливаются дифференцированно, в зависимости от их вида.</p><br>

<p style="text-indent:2em;">(3) При задержке приема грузополучателем или грузоотправителем принадлежащих им или арендованных ими вагонов и контейнеров железная дорога взимает за время нахождения
 этих вагонов и контейнеров на железнодорожных путях общего пользования плату в размере 50 процентов платы за пользование вагонами и контейнерами, предусмотренной Тарифным руководством.</p><br>

<p style="text-indent:2em;">(4) Расчет платы за пользование вагонами-рефрижераторами рефрижераторных секций или поездов производится с момента окончания погрузки или выгрузки последнего вагона таких секций или поездов.</p><br>

<p style="text-indent:2em;">(5) На железнодорожных линиях узкой колеи плата за пользование вагонами, предусмотренная Тарифным руководством, уменьшается на 50 процентов.</p><br>

<p style="font-weight:500;">Статья 65. Проверка на станции назначения состояния грузов, их массы и количества мест</p>

<p style="text-indent:2em;"></p><h6>(1) При выдаче грузов на станции назначения железная дорога проверяет их состояние, массу и количество мест в случаях:</h6><p></p>

<p style="text-indent:2em;">a) прибытия грузов в неисправном вагоне или контейнере, в вагоне или контейнере с поврежденными запорно-пломбировочными устройствами или с запорно-пломбировочными устройствами,
 наложенными железнодорожными станциями по пути следования;</p><br>

<p style="text-indent:2em;">b) прибытия грузов с признаками недостачи либо повреждения (порчи) при перевозке в открытом подвижном составе;</p><br>

<p style="text-indent:2em;">c) прибытия скоропортящихся грузов, если нарушен срок их доставки или температурный режим, при перевозке в вагонах-рефрижераторах;</p><br>

<p>d) прибытия грузов, погруженных железной дорогой;</p><br>

<p>e) выдачи грузов, выгружаемых железной дорогой, в местах общего пользования.</p><br>

<p style="text-indent:2em;">(2) В предусмотренных настоящей статьей случаях, а также при выявлении обстоятельств, которые могут привести к изменению состояния и массы тарных или штучных грузов,
 железная дорога при выдаче находящихся в поврежденной таре или упаковке грузов проверяет их состояние и массу.</p><br>

<p style="text-indent:2em;">(3) При отсутствии у грузополучателя и на железнодорожной станции назначения вагонных весов перевозимые навалом и насыпью грузы, прибывшие без признаков недостачи,
 выдаются без проверки их массы.</p><br>

<p style="text-indent:2em;">(4) Масса груза считается правильной, если разница в его массе, определенной на станции отправления, по сравнению с массой, определенной на станции назначения,
 не превышает максимальной погрешности весов, установленной соответствующими нормативными документами Национальной системы метрологии, а также нормы естественной
 убыли массы данного груза, установленной уполномоченным Правительством органом.</p><br>

<p style="text-indent:2em;">(5) По просьбе грузополучателя железная дорога может на договорной основе принять участие в проверке состояния грузов, их массы и количества мест.</p><br>

<p style="text-indent:2em;">(6) Наличие на вагоне, контейнере запорно-пломбировочного устройства таможенного органа не является основанием для проверки железной дорогой при выдаче грузов их состояния,
 массы и количества мест, за исключением случаев, предусмотренных настоящей статьей.</p><br>

<p style="font-weight:500;">Статья 66. Порядок определения на станции назначения фактических недостачи, повреждения (порчи) грузов и оформление коммерческого акта</p><br>

<p style="text-indent:2em;">(1) Если при проверке на железнодорожной станции назначения состояния грузов, их массы и количества мест обнаруживаются недостача, повреждение (порча) грузов или если эти
 обстоятельства установлены коммерческим актом, составленным в пути следования, железнодорожная станция назначения обязана определить объем фактических недостачи,
 повреждения (порчи) грузов и выдать грузополучателю соответствующий коммерческий акт.</p><br>

<p style="text-indent:2em;">(2) При необходимости проведения экспертизы железная дорога по своей инициативе или по требованию грузополучателя приглашает экспертов либо специалистов в соответствующей области.</p><br>

<p style="font-weight:500;">Статья 67. Порядок увеличения сбора за хранение грузов и платы за пользование вагонами и контейнерами</p>

<p style="text-indent:2em;"></p><h6>(1) В случаях возникновения на железнодорожных станциях технологических затруднений в связи с задержкой выгрузки и вывоза грузов со станции грузополучателем руководитель железной 
дороги может увеличить:</h6><p></p>

<p>a) дополнительный сбор за хранение грузов и выгруженных контейнеров - до пятикратного размера;</p><br>

<p>b) плату за пользование вагонами и контейнерами - до двукратного размера.</p><br>

<p style="text-indent:2em;">(2) Указанное в части (1) увеличение сбора и платы производится по истечении 24 часов после соответствующего письменного уведомления грузополучателя.</p><br>

<p style="font-weight:500;">Статья 68. Порядок очистки и промывки вагонов и контейнеров после их выгрузки</p><br>

<p style="text-indent:2em;">(1) После выгрузки грузов вагоны и контейнеры должны быть очищены внутри и снаружи, с них должны быть сняты приспособления для крепления грузов,
 несъемные инвентарные приспособления для крепления грузов, в том числе турникеты, должны быть приведены в исправное техническое состояние. Все это делают грузополучатели,
 если выгрузка грузов проводилась их средствами, или железная дорога, если выгрузка осуществлялась ее средствами.</p><br>

<p style="text-indent:2em;">(2) Очистка, промывка, а при необходимости и дезинфекция вагонов после перевозки животных, птицы, сырых продуктов животного происхождения и
 скоропортящихся грузов осуществляются железной дорогой за счет грузополучателей.</p><br>

<p style="text-indent:2em;">(3) Основные требования к очистке вагонов и контейнеров, критерии такой очистки определяются Правилами перевозок грузов по железной дороге.</p><br>

<p style="text-indent:2em;">(4) После выгрузки грузополучателями зловонных и загрязняющих вагоны грузов промывка вагонов производится грузополучателями. Перечень таких грузов устанавливается 
Правилами перевозок грузов по железной дороге.</p><br>

<p style="text-indent:2em;">(5) При отсутствии у грузополучателей возможностей для промывки вагонов железная дорога осуществляет их промывку на договорной основе.</p><br>

<p style="text-indent:2em;">(6) Железная дорога вправе не принимать вагоны и контейнеры после их выгрузки (слива) от грузополучателей до тех пор, пока они не выполнят условий,
 оговоренных настоящей статьей. При этом с грузополучателей взимается плата за пользование вагонами и контейнерами за все время их задержки.</p><br>

<p style="font-weight:500;">Статья 69. Возврат грузов, багажа и грузобагажа грузоотправителю или их реализация</p><br>

<p style="text-indent:2em;">(1) Если вследствие форс-мажорных обстоятельств: военных действий, блокады, эпидемий - или других обстоятельств возникли препятствия для перевозки грузов,
 багажа и грузобагажа, лишившие железную дорогу возможности доставить их по назначению или выдать грузополучателю, а также если по указанным причинам истекли предельные
 сроки хранения грузов, багажа и грузобагажа, железная дорога направляет запрос о дальнейшей их судьбе грузополучателю и грузоотправителю.</p><br>

<p style="text-indent:2em;">(2) В случаях непредставления ответа грузополучателем или грузоотправителем в течение четырех суток после получения ими запроса относительно дальнейшей судьбы грузов,
 багажа и грузобагажа железная дорога по согласованию с администрациями других железных дорог может возвратить такие грузы, багаж и грузобагаж отправителю,
 а при невозможности возврата по указанным в настоящей статье причинам - реализовать их.</p><br>

<p style="font-weight:500;">Статья 70. Ответственность грузоотправителя за нарушение требований, предъявляемых к экспортируемым грузам</p><br>

<p style="text-indent:2em;">(1) При нарушении грузоотправителем требований, предъявляемых к экспортируемым грузам, которое привело к задержке органами таможенного контроля или другими органами государственного
 контроля вагонов либо контейнеров с такими грузами на пограничных или припортовых железнодорожных станциях и невозможности передать эти грузы по указанной причине на морской,
 речной транспорт или иностранным железным дорогам, грузоотправитель уплачивает железной дороге штраф в размере соответственно 810 и 270 леев за каждый вагон и каждый контейнер.</p><br>

<p style="text-indent:2em;">(2) Если грузоотправитель в течение десяти суток с момента получения извещения о задержке вагонов или контейнеров по указанной в части (1)
 причине не принимает должных мер (а при задержке скоропортящихся грузов этот срок составляет четверо суток), железная дорога имеет право возвратить грузы грузоотправителю
 за его счет или, если договором о перевозке не предусмотрено иное, реализовать их. При этом виновный в задержке вагонов или контейнеров на железнодорожной станции сверх
 установленных сроков грузоотправитель несет имущественную ответственность, предусмотренную статьями 135 и 137.</p><br>

<p style="font-weight:500;">Статья 71. Реализация грузов, багажа и грузобагажа</p><br>

<p style="text-indent:2em;">(1) Обращение взыскания на грузы и их реализация производятся по решению руководителя железной дороги. В случаях, предусмотренных статьей 69,
 реализация грузов, багажа и грузобагажа осуществляется по решению центрального отраслевого органа публичного управления.</p><br>

<p style="text-indent:2em;">(2) Грузы, багаж и грузобагаж реализуются железной дорогой в случаях, предусмотренных настоящим кодексом, на основании договоров купли-продажи,
 исходя из цен грузов, багажа и грузобагажа, подтвержденных документами об оплате, а при отсутствии таких документов - договорами о ценах, которые
 в подобных условиях применяются для аналогичных товаров.</p><br>

<p style="text-indent:2em;">(3) Порядок ведения учета и осуществления расчетов за реализуемые грузы, багаж и грузобагаж устанавливается центральным отраслевым органом публичного управления.</p><br>

<p style="font-weight:500;">Статья 72. Доход от реализации грузов, багажа и грузобагажа</p><br>

<p style="text-indent:2em;">(1) Доход, полученный железной дорогой от реализации грузов, багажа и грузобагажа, за вычетом причитающихся ей платежей и затрат на реализацию,
 подлежит перечислению грузополучателю, указанному в перевозочных документах, при оплате им стоимости грузов, багажа, грузобагажа и грузоотправителю 
 - во всех остальных случаях. Исключение составляют случаи, предусмотренные статьями 119 и 132.</p><br>

<p style="text-indent:2em;">(2) Если сумма, предусмотренная частью (1), не может быть перечислена грузополучателю или грузоотправителю, то по истечении срока исковой давности она подлежит перечислению 
в государственный бюджет.</p><br>

<p style="text-indent:2em;">(3) Сумма, полученная железной дорогой за реализованные грузы, багаж и грузобагаж, на которые отсутствуют перевозочные документы, перечисляется на
 депозитный счет железной дороги для возмещения выплаченных ею сумм за не прибывшие по назначению грузы, багаж и грузобагаж. В случае невостребования
 грузоотправителем или грузополучателем этой суммы она по истечении срока исковой давности подлежит перечислению в государственный бюджет.</p>

<p></p><h4>Часть 4 ЖЕЛЕЗНОДОРОЖНЫЕ ПОДЪЕЗДНЫЕ ПУТИ</h4><p></p>

<p style="font-weight:500;">Статья 73. Согласование строительства и примыкания железнодорожных подъездных путей</p><br>

<p style="text-indent:2em;">(1) Разрешение на примыкание железнодорожных подъездных путей к железнодорожным путям общего пользования дается центральным отраслевым органом публичного управления или,
 по его поручению, руководителем железной дороги по согласованию с органами местного публичного управления.</p><br>

<p style="text-indent:2em;">(2) Строительство и реконструкция железнодорожных подъездных путей, а также устройств, предназначенных для погрузки, выгрузки и очистки вагонов и контейнеров,
 осуществляются по согласованию с железной дорогой примыкания. Строительство новых железнодорожных подъездных путей согласуется также с соответствующими органами местного публичного управления.</p><br>

<p style="text-indent:2em;">(3) Конструкция и состояние путевых устройств и технических сооружений железнодорожных подъездных путей должны соответствовать строительным нормам и правилам
 и обеспечивать пропуск вагонов с допустимой на железнодорожных путях общего пользования нормой технической нагрузки, а при обслуживании железнодорожных подъездных
 путей локомотивами железной дороги - пропуск выделенных для этой цели локомотивов.</p><br>

<p style="text-indent:2em;">(4) Железнодорожные подъездные пути, не принадлежащие железной дороге, могут быть переданы ей на основании договора, в том числе для технического обслуживания.</p><br>

<p style="font-weight:500;">Статья 74. Открытие построенного железнодорожного подъездного пути для эксплуатации</p><br>

<p style="text-indent:2em;">(1) Открытие для постоянной эксплуатации построенного железнодорожного подъездного пути и подача на него подвижного состава допускаются после принятия такого
 пути в эксплуатацию комиссией, в состав которой входит представитель железной дороги, и установления железной дорогой по согласованию с владельцем подъездного пути порядка его обслуживания.</p><br>

<p style="text-indent:2em;">(2) На каждый железнодорожный подъездной путь имеются технический паспорт, план и продольный профиль, а также чертежи искусственных сооружений.</p><br>

<p style="text-indent:2em;">(3) Для обслуживания на железнодорожных подъездных путях с оборотом 100 и более вагонов в сутки или со среднесуточной погрузкой-выгрузкой 50 и более
 вагонов грузоотправителя или грузополучателя на основании договора между ним и железной дорогой могут создаваться соответствующие подразделения.</p><br>

<p style="font-weight:500;">Статья 75. Путевое развитие железнодорожных подъездных путей</p><br>

<p style="text-indent:2em;">(1) Путевое развитие, сооружения и устройства железнодорожных подъездных путей должны обеспечивать ритмичную погрузку и выгрузку грузов, выполнение
 маневровой работы в соответствии с объемом перевозок, а также рациональное использование железнодорожного подвижного состава.</p>

<p style="text-indent:2em;"></p><h6>(2) Допускается примыкание вновь строящихся железнодорожных подъездных путей:</h6><p></p>

<p>a) к подъездным путям, принадлежащим железной дороге, - с разрешения железной дороги;</p><br>

<p style="text-indent:2em;">b) к подъездным путям, не принадлежащим железной дороге, - с разрешения юридического или физического лица, которому принадлежит этот подъездной путь,
 с уведомлением соответствующей железной дороги.</p><br>

<p style="font-weight:500;">Статья 76. Порядок выхода ведомственного подвижного состава на железнодорожные пути общего пользования</p><br>

<p style="text-indent:2em;">Порядок выхода подвижного состава, не принадлежащего железной дороге, на железнодорожные пути общего пользования устанавливается администрацией железной дороги.</p><br>

<p style="font-weight:500;">Статья 77. Требования, предъявляемые к работникам, занятым на железнодорожных подъездных путях</p><br>

<p style="text-indent:2em;">(1) Лица, работа которых связана с движением поездов и маневровой работой на железнодорожных подъездных путях, проверяются на знание Правил технической эксплуатации железной дороги,
 а также инструкций по движению поездов, маневровой работе и сигнализации на железной дороге, утвержденных центральным отраслевым органом публичного управления.</p><br>

<p style="text-indent:2em;">(2) Работники, ответственные за погрузку, крепление и выгрузку грузов, должны знать технические условия погрузки, размещения и крепления грузов на железнодорожном подвижном составе.</p><br>

<p style="font-weight:500;">Статья 78. Контроль за соблюдением требований, обеспечивающих безопасность движения, сохранность подвижного состава, контейнеров и перевозимых грузов</p><br>

<p style="text-indent:2em;">(1) Представитель соответствующего органа железнодорожного транспорта и представитель организации, осуществляющей операции по погрузке и выгрузке грузов на железнодорожном подъездном пути,
 имеют право контроля за соблюдением требований, обеспечивающих безопасность движения, сохранность подвижного состава и контейнеров на железнодорожном подъездном пути, и условий выполнения
 операций по погрузке и выгрузке грузов из вагонов и контейнеров, принадлежащих железной дороге.</p><br>

<p style="text-indent:2em;">(2) При выявлении на железнодорожном подъездном пути неисправностей, угрожающих безопасности движения, сохранности железнодорожного подвижного состава и контейнеров или перевозимых грузов,
 комиссией в присутствии представителя владельца подъездного пути составляется акт. На основании этого акта начальник железнодорожной станции или ревизор по безопасности движения запрещает
 подачу железнодорожного подвижного состава на подъездной путь или его участок, указанный в акте, до устранения неисправностей. Имущественную ответственность за задержку вагонов, за несоблюдение
 требований, относящихся к подаче, уборке вагонов, охране грузов, к выполнению заявки на перевозку грузов, несет владелец закрытого железнодорожного подъездного пути или его участка.</p><br>

<p style="font-weight:500;">Статья 79. Договор об эксплуатации железнодорожного подъездного пути</p><br>

<p style="text-indent:2em;">Отношения железной дороги с юридическими и физическими лицами, имеющими подъездные пути, регулируются договорами об эксплуатации железнодорожных подъездных путей.
 При отсутствии у указанных лиц локомотивов подача, уборка вагонов и маневровая работа производятся на железнодорожных подъездных путях локомотивами железной дороги,
 за что последняя взимает дополнительный сбор в размере, предусмотренном Тарифным руководством.</p><br>

<p style="font-weight:500;">Статья 80. Договоры об обслуживании грузоотправителей и грузополучателей. Ответственность владельцев железнодорожных подъездных путей</p><br>

<p style="text-indent:2em;">(1) Подача, уборка вагонов и маневровая работа, осуществляемые для грузоотправителей и грузополучателей, имеющих склады и погрузочно-разгрузочные площадки на железнодорожных
 подъездных путях, принадлежащих железной дороге, регулируются договорами. За использование собственных локомотивов железная дорога взимает дополнительный сбор в соответствии с Тарифным руководством.</p><br>

<p style="text-indent:2em;">(2) Владельцы железнодорожных подъездных путей, грузоотправители и грузополучатели обеспечивают освещение этих путей в пределах занимаемой ими территории и мест погрузки,
 выгрузки грузов, проводят очистку железнодорожных подъездных путей от мусора и снега.</p><br>

<p style="font-weight:500;">Статья 81. Порядок разработки договоров</p><br>

<p style="text-indent:2em;">(1) Договор об эксплуатации железнодорожного подъездного пути и договор о подаче и уборке вагонов разрабатываются с учетом технологии работы железнодорожной станции примыкания и железнодорожного

подъездного пути.</p><br>

<p style="text-indent:2em;">(2) Указанными в части (1) договорами устанавливаются порядок подачи и уборки вагонов, а также сроки оборота вагонов и контейнеров на железнодорожных подъездных путях.</p><br>

<p style="font-weight:500;">Статья 82. Охрана вагонов на железнодорожных подъездных путях</p><br>

<p style="text-indent:2em;">Охрана на железнодорожных подъездных путях груженых и порожних вагонов, принятых от железной дороги, осуществляется средствами и за счет принявшей стороны.</p><br>

<p style="font-weight:500;">Статья 83. Отношения между железной дорогой, владельцем железнодорожного подъездного пути и его контрагентом</p><br>

<p style="text-indent:2em;">(1) Отношения между владельцем железнодорожного подъездного пути, примыкающего к железнодорожным путям общего пользования, и его контрагентом (грузоотправителем или грузополучателем,
 который в пределах подъездного пути, принадлежащего другому грузоотправителю или грузополучателю, имеет свои склады либо имеет железнодорожные подъездные пути, примыкающие к указанному
 подъездному пути) регулируются заключенным между сторонами договором.</p><br>

<p style="text-indent:2em;">(2) Отношения между железной дорогой и контрагентом владельца железнодорожного подъездного пути при обслуживании подъездного пути локомотивом железной дороги регулируются заключенным
 между ними договором о подаче и уборке вагонов.</p><br>

<p style="text-indent:2em;">(3) Обязанности и ответственность владельца железнодорожного подъездного пути и его контрагентов в части организации перевозки грузов одинаковы.</p><br>

<p style="font-weight:500;">Статья 84. Учет времени Учет времени нахождения вагонов и контейнеров на железнодорожных подъездных путях осуществляется номерным способом.</p><br>

<p style="font-weight:500;">Статья 85. Порядок исчисления времени нахождения вагонов и контейнеров на железнодорожных подъездных путях</p><br>

<p style="text-indent:2em;">(1) В случае обслуживания железнодорожного подъездного пути локомотивом железной дороги время нахождения вагонов и контейнеров под погрузкой или выгрузкой исчисляется
 с момента их подачи к месту погрузки или выгрузки грузов до момента получения железнодорожной станцией от грузоотправителя или грузополучателя уведомления о готовности вагонов к уборке.</p><br>

<p style="text-indent:2em;">(2) Время нахождения вагонов и контейнеров на железнодорожном подъездном пути, не обслуживаемом локомотивом железной дороги, исчисляется с момента передачи грузоотправителю или
 грузополучателю вагонов на железнодорожном выставочном пути до момента их возвращения на выставочный путь.</p><br>

<p style="text-indent:2em;">(3) Место и порядок передачи вагонов и контейнеров на железнодорожный подъездной путь, железнодорожный выставочный путь, а также порядок их возвращения устанавливаются договором.</p><br>

<p style="text-indent:2em;">(4) Грузоотправители, грузополучатели, железные дороги, иные организации не вправе использовать не принадлежащие им вагоны и контейнеры для собственных перевозок,
 хранения грузов, а также для погрузки без разрешения на то их владельцев, если иное не предусмотрено соответствующими договорами. За несоблюдение этого требования
 виновная сторона несет имущественную ответственность согласно статье 134.</p><br>

<p style="font-weight:500;">Статья 86. Ответственность железной дороги за самовольное использование ведомственных подъездных путей</p><br>

<p style="text-indent:2em;">С согласия грузоотправителя, грузополучателя, владельца железнодорожного подъездного пути железная дорога может использовать за плату в соответствии с договором принадлежащий
 им железнодорожный подъездной путь, а также часть принадлежащего ей подъездного пути, расположенную на территории грузоотправителя или грузополучателя, в своих целях, в том
 числе для маневровой работы и временного размещения вагонов. При самовольном использовании этих подъездных путей железная дорога несет имущественную ответственность согласно
 статье 134 - за самовольное использование вагонов и контейнеров.</p><br>

<p style="font-weight:500;">Статья 87. Сроки заключения договоров. Разрешение разногласий по договорам</p><br>

<p style="text-indent:2em;">(1) Договор об эксплуатации железнодорожного подъездного пути и договор о подаче и уборке вагонов заключаются на срок не более трех лет.</p><br>

<p style="text-indent:2em;">(2) Договоры подписываются руководителем железной дороги или, по его поручению, начальником железнодорожной станции и владельцем железнодорожного подъездного пути или, 
по его поручению, руководителем обслуживающей подъездной путь организации.</p><br>

<p style="text-indent:2em;">(3) В договоре об эксплуатации железнодорожного подъездного пути и договоре о подаче и уборке вагонов предусматривается порядок их пересмотра до истечения срока действия
 в случае появления изменений в техническом оснащении либо технологии работы железнодорожной станции или железнодорожного подъездного пути.</p><br>

<p style="text-indent:2em;">(4) Разногласия, возникающие по договорам об эксплуатации железнодорожных подъездных путей и договорам о подаче и уборке вагонов, рассматриваются и разрешаются руководителем
 железной дороги и владельцами железнодорожных подъездных путей.</p><br>

<p style="text-indent:2em;">(5) При невозможности устранить разногласия, указанные в части (4), споры рассматриваются в порядке, установленном действующим законодательством. Разногласия по вопросам,
 связанным с обеспечением безопасности движения и сохранности подвижного состава и контейнеров, разрешаются руководителем железной дороги.</p><br>

<p>(6) До разрешения споров все отношения сторон регулируются условиями прежних договоров.</p>

<p></p><h4>Часть 5 ПЕРЕВОЗКА ГРУЗОВ В ПРЯМОМ СМЕШАННОМ СООБЩЕНИИ</h4><p></p>

<p style="font-weight:500;">Статья 88. Прямое смешанное сообщение</p><br>

<p style="text-indent:2em;">(1) Перевозки грузов могут осуществляться в прямом смешанном сообщении при взаимодействии железнодорожного транспорта с водным (морским, речным), воздушным и автомобильным транспортом.</p><br>

<p style="text-indent:2em;">(2) Перевозки грузов в прямом смешанном сообщении выполняются по единому перевозочному документу (накладной), составляемому на весь путь следования грузов.</p><br>

<p style="font-weight:500;">Статья 89. Порядок организации перевозок грузов в прямом смешанном сообщении</p><br>

<p style="text-indent:2em;">(1) Порядок организации перевозок грузов в прямом смешанном сообщении определяется на основании соглашений, заключаемых между организациями соответствующих видов транспорта
 согласно действующему законодательству, а также статьям настоящей части.</p><br>

<p style="text-indent:2em;">(2) Правила перевозок грузов в прямом смешанном сообщении разрабатываются и утверждаются центральным отраслевым органом публичного управления с последующей регистрацией их
 центральным органом публичного управления в области юстиции и публикацией в сборниках правил перевозок и тарифов на всех видах транспорта.</p><br>

<p style="text-indent:2em;">(3) В случаях, не предусмотренных настоящим кодексом, применяются нормы кодексов, уставов и тарифных руководств, а также правила, регулирующие перевозки грузов определенными видами транспорта.</p><br>

<p style="font-weight:500;">Статья 90. Порядок включения в прямое смешанное сообщение</p>

<p style="text-indent:2em;"></p><h6>(1) Прямое смешанное сообщение включает:</h6><p></p>

<p>а) железнодорожные станции, открытые для проведения операций по грузовым перевозкам;</p><br>

<p style="text-indent:2em;">b) морские и речные порты, автостанции и аэропорты - согласно перечню, устанавливаемому центральным отраслевым органом публичного управления и 
Государственной администрацией гражданской авиации.</p><br>

<p style="text-indent:2em;">(2) Порты, автостанции, аэропорты считаются включенными в прямое смешанное сообщение с момента телеграфного или письменного извещения их об этом.
 В последующем соответствующая информация публикуется в сборниках правил перевозок и тарифов на всех видах транспорта.</p><br>

<p style="font-weight:500;">Статья 91. Грузы, допускаемые к перевозке в прямом смешанном сообщении</p><br>

<p style="text-indent:2em;">(1) Грузы, подлежащие перевозке насыпью, наливом в цистернах, а также лес в плотах не принимаются к транспортированию в прямом смешанном сообщении.</p><br>

<p style="text-indent:2em;">(2) Перечень допускаемых к перевозке в прямом смешанном сообщении скоропортящихся и опасных грузов, а также грузов, требующих сопровождения представителями
 грузоотправителей или грузополучателей, устанавливается Правилами перевозок грузов в прямом смешанном сообщении.</p><br>

<p style="font-weight:500;">Статья 92. Порядок передачи грузов в пунктах перевалки</p><br>

<p style="text-indent:2em;">(1) Грузы, перевозимые в опломбированных вагонах, передаются в пунктах перевалки с проверкой исправности наложенных запорно-пломбировочных устройств
 и соответствия оттисков на них данным, внесенным в перевозочные документы.</p><br>

<p style="text-indent:2em;">(2) Грузы, следующие в прямом смешанном железнодорожно-водном сообщении, взвешиваются в пунктах перевалки по требованию стороны, принимающей груз,
 при передаче его с одного вида транспорта на другой, а именно: на вагонных весах - железной дорогой; на товарных весах - портом.</p><br>

<p style="text-indent:2em;">(3) Взвешивание грузов, следующих в прямом смешанном железнодорожно-автомобильном сообщении, производится стороной, осуществляющей в пункте перевалки погрузку,
 выгрузку передаваемых грузов, в присутствии представителя смежного вида транспорта.</p><br>

<p style="text-indent:2em;">(4) Если у стороны, осуществляющей в пункте перевалки погрузку, выгрузку грузов, нет весовых приборов, грузы передаются по их массе, указанной в накладной.</p><br>

<p style="text-indent:2em;">(5) Тарные и штучные грузы, принятые к перевозке в крытом или открытом подвижном составе согласно стандарту или массе, указанной грузоотправителем на каждом грузовом месте,
 и прибывшие в пункт перевалки в исправной таре, передаются с одного вида транспорта на другой без взвешивания, по количеству занятых мест, и в таком же порядке выдаются
 грузополучателю в пункте назначения. При наличии признаков утраты, недостачи или повреждения (порчи) грузов они передаются по массе и фактическому состоянию.</p><br>

<p style="text-indent:2em;">(6) Грузы, перевозимые в контейнерах, передаются с проверкой состояния контейнеров и исправности запорно-пломбировочных устройств.</p><br>

<p style="font-weight:500;">Статья 93. Порядок предоставления контейнеров</p><br>

<p style="text-indent:2em;">Порядок предоставления контейнеров и условия их использования при перевозках грузов в прямом смешанном сообщении устанавливаются соглашениями между организациями соответствующих видов 
транспорта.</p><br>

<p style="font-weight:500;">Статья 94. Сроки начала и окончания приема грузов портами в связи с открытием и закрытием навигации</p><br>

<p style="text-indent:2em;">Сроки начала приема грузов портами от железных дорог в пунктах перевалки при открытии навигации и сроки окончания приема грузов перед закрытием навигации устанавливаются морскими и речными портами по согласованию
 с водным перевозчиком и сообщаются железной дороге перевалки, которая, в свою очередь, извещает об этом другие железные дороги для последующего оповещения грузоотправителей, а также центральный отраслевой
 орган публичного управления.</p><br>

<p style="font-weight:500;">Статья 95. Грузы, своевременно не доставленные в порты перевалки</p><br>

<p style="text-indent:2em;">(1) Грузы, доставленные в пункты перевалки до наступления объявленного срока окончания приема грузов, принимаются портами перевалки от железных дорог беспрепятственно.
 Если такие грузы не могут быть доставлены в пункты назначения водным транспортом в связи с закрытием навигации, порты обязаны по согласованию с грузоотправителями определять, как поступить с этими грузами.</p><br>

<p style="text-indent:2em;">(2) Грузы, доставленные в пункты перевалки после истечения объявленного срока окончания приема грузов, могут передаваться портам (с их согласия) на хранение.
 Если порты отказываются принять грузы на хранение, железная дорога по согласованию с грузоотправителями определяет, как поступить с этими грузами.</p><br>

<p style="text-indent:2em;">(3) Грузы, перевозимые в водно-железнодорожном сообщении, но не доставленные в пункты перевалки в связи с закрытием навигации, должны быть отправлены с
 письменного согласия грузоотправителей через другие порты по назначению в прямом железнодорожном сообщении.</p><br>

<p>(4) В указанных в частях (1) - (3) случаях сроки доставки грузов увеличиваются на время их задержки.</p><br>

<p style="font-weight:500;">Статья 96. Перегрузка грузов в пунктах перевалки</p>

<p style="text-indent:2em;"></p><h6>При перевозках грузов в прямом смешанном сообщении работы по их перегрузке выполняются:</h6><p></p>

<p style="text-indent:2em;">a) портами - с морских, речных судов и из портовых складов в вагоны, а также из вагонов на морские, речные суда и в портовые склады;</p><br>

<p style="text-indent:2em;">b) железной дорогой - из вагонов и складов железнодорожных станций на грузовые автомобили, а также с грузовых автомобилей в вагоны и склады железнодорожных станций.</p><br>

<p style="font-weight:500;">Статья 97. Оборудование вагонов, морских и речных судов для перевозки и крепления грузов</p><br>

<p style="text-indent:2em;">(1) Оборудование вагонов, морских и речных судов для перевозки грузов в несколько ярусов, тяжеловесных, громоздких и длинномерных грузов, а также крепление грузов в вагонах и на
 судах осуществляются портом. Эти работы могут выполняться и железной дорогой на основании договора, заключенного с портом.</p><br>

<p>(2) Оборудование вагонов печами для перевозки скоропортящихся грузов производится железной дорогой.</p><br>

<p style="text-indent:2em;">(3) Необходимые оборудование, материалы, средства пакетирования и иные приспособления для погрузки, крепления и перевозки грузов в вагонах и на судах предоставляются грузоотправителями.</p><br>

<p style="text-indent:2em;">(4) Стоимость произведенных работ по оборудованию вагонов и судов для перевозки и крепления грузов, включая затраты на материалы, указывается в накладных для взимания с грузополучателей.</p><br>

<p style="font-weight:500;">Статья 98. Железнодорожные пути и прирельсовые склады в портах</p><br>

<p style="text-indent:2em;">(1) Железнодорожные пути в портах, предназначенные для обеспечения перевозок грузов в прямом смешанном железнодорожно-водном сообщении, принадлежат железным дорогам.</p><br>

<p style="text-indent:2em;">(2) Строительство и реконструкция в портах прирельсовых складов, предназначенных для приема и отправления грузов, перевозимых в прямом смешанном железнодорожно-водном сообщении,
 осуществляются портами по проектам, согласованным с железной дорогой и другими заинтересованными организациями.</p><br>

<p style="font-weight:500;">Статья 99. Узловое соглашение</p><br>

<p style="text-indent:2em;">(1) Условия работы железнодорожной станции, портов и других транспортных организаций, участвующих в прямом смешанном сообщении,
 определяются узловым соглашением, заключаемым сроком на пять лет. В случае изменения технического оснащения, технологии работы порта или железнодорожной
 станции узловое соглашение по требованию одной из сторон может быть полностью или частично пересмотрено до истечения срока его действия.</p><br>

<p style="text-indent:2em;">(2) Порядок разработки и заключения узловых соглашений устанавливается Правилами перевозок грузов в прямом смешанном сообщении.</p><br>

<p style="text-indent:2em;">(3) Споры, возникающие по узловым соглашениям, рассматриваются в порядке, предусмотренном действующим законодательством.</p><br>

<p style="font-weight:500;">Статья 100. Общий срок доставки грузов</p><br>

<p style="text-indent:2em;">(1) Общий срок доставки грузов в прямом смешанном сообщении определяется как сумма сроков их доставки железнодорожным и другими видами транспорта и рассчитывается на основании
 действующих на этих видах транспорта правил исчисления сроков доставки грузов.</p><br>

<p style="text-indent:2em;">(2) В случаях прибытия грузов с опозданием имущественную ответственность за просрочку в их доставке несет виновная сторона.</p><br>

<p style="font-weight:500;">Статья 101. Плата за перевозку грузов</p>

<p style="text-indent:2em;"></p><h6>(1) Плата за перевозку грузов в железнодорожно-водном сообщении взимается:</h6><p></p>

<p style="text-indent:2em;">a) на железнодорожной станции отправления - с грузоотправителя исходя из расстояния перевозки грузов по железной дороге;</p><br>

<p style="text-indent:2em;">b) в порту перевалки или порту назначения - с грузоотправителя или грузополучателя исходя из расстояния перевозки грузов по водным путям.</p>

<p style="text-indent:2em;"></p><h6>(2) Плата за перевозку грузов в водно-железнодорожном сообщении взимается:</h6><p></p>

<p>a) в порту отправления - с грузополучателя исходя из расстояния перевозки грузов по водным путям;</p><br>

<p style="text-indent:2em;">b) на железнодорожной станции перевалки - с грузоотправителя или грузополучателя исходя из расстояния перевозки грузов по железной дороге.</p><br>

<p style="text-indent:2em;">(3) Плата за перевозку грузов может вноситься экспедитором, выступающим от имени грузоотправителя, грузополучателя.</p><br>

<p style="text-indent:2em;">(4) За подачу вагонов в порты и уборку вагонов из портов локомотивами железной дороги взимается дополнительный сбор в соответствии с Тарифным руководством.</p><br>

<p style="font-weight:500;">Статья 102. Норма перевалки грузов</p><br>

<p style="text-indent:2em;">(1) Выполнение определяемой в соответствии с узловым соглашением нормы перевалки производится раздельно по грузам, передаваемым с железнодорожного на водный транспорт,
 и по грузам, передаваемым с водного на железнодорожный транспорт, по учетным карточкам.</p><br>

<p style="text-indent:2em;">(2) Формы учетных карточек и порядок их составления устанавливаются Правилами перевозок грузов в прямом смешанном сообщении.</p><br>

<p style="font-weight:500;">Статья 103. Ответственность за невыполнение нормы перевалки грузов</p><br>

<p style="text-indent:2em;">(1) При невыполнении нормы перевалки грузов, перевозимых в прямом смешанном железнодорожно-водном сообщении, железные дороги и порты несут имущественную ответственность
 согласно статье 125 - за невыполнение принятой заявки на перевозку грузов.</p>

<p style="text-indent:2em;"></p><h6>(2) Железные дороги и порты освобождаются от имущественной ответственности за невыполнение нормы перевалки грузов в случаях:</h6><p></p>

<p style="text-indent:2em;">a) возникновения форс-мажорных обстоятельств: военных действий, блокады, эпидемий - и/или иных препятствующих перевалке грузов обстоятельств, при которых запрещены
 операции по погрузке-выгрузке грузов, а также аварий в транспортных организациях;</p><br>

<p>b) прекращения или ограничения перевозок грузов в установленном законом порядке;</p><br>

<p style="text-indent:2em;">c) перевыполнения нормы перевалки в первой половине месяца и недогруза во второй или недовыполнения нормы перевалки в первой половине месяца и восполнения недогруза во второй.</p><br>

<p style="text-indent:2em;">(3) За время нахождения вагонов и контейнеров в портах под погрузкой, выгрузкой или перевалкой грузов и на железнодорожных станциях из-за невозможности подачи вагонов
 под погрузку, выгрузку или перевалку грузов по причинам, зависящим от портов, с последних взимается плата за пользование вагонами и контейнерами в соответствии с Тарифным руководством.</p><br>

<p style="text-indent:2em;">(4) За сверхнормативный простой судов из-за неподачи вагонов железные дороги несут имущественную ответственность согласно статье 136.</p><br>

<p style="font-weight:500;">Статья 104. Ответственность за задержку вагонов и контейнеров в пунктах перевалки</p><br>

<p style="text-indent:2em;">(1) За задержку вагонов и контейнеров в пунктах перевалки по вине грузоотправителей с них взыскивается штраф в размерах, установленных статьями 135 и 137.</p><br>

<p style="text-indent:2em;">(2) За задержку вагонов и контейнеров с экспортно-импортными грузами в пунктах таможенного или пограничного контроля по вине таможенных или пограничных органов
 с них взыскивается штраф в размерах, установленных статьями 135 и 137.</p><br>

<p style="font-weight:500;">Статья 105. Ответственность за необеспечение сохранности грузов</p><br>

<p style="text-indent:2em;">(1) Имущественную ответственность за необеспечение сохранности груза до момента фактической его передачи в пункте перевалки несет сдающая сторона, а после его фактической передачи - сторона,
 принявшая груз.</p><br>

<p style="text-indent:2em;">(2) Охрана груженых и порожних вагонов в портах осуществляется портами.</p><br>

<p style="text-indent:2em;">(3) Снятие запорно-пломбировочных устройств с вагонов, подаваемых в пункты перевалки под выгрузку, а также опломбирование вагонов, загруженных в пунктах перевалки грузами,
 перевозимыми в прямом смешанном сообщении, осуществляются в соответствии с Правилами перевозок грузов в прямом смешанном сообщении.</p><br>

<p style="text-indent:2em;">(4) Имущественная ответственность железных дорог и организаций других видов транспорта за необеспечение сохранности грузов, принятых к перевозке в прямом смешанном сообщении,
 определяется транспортными кодексами и уставами.</p><br>

<p style="text-indent:2em;">(5) При установлении вины железных дорог, судоходных компаний и портов в утрате, недостаче или повреждении (порче) грузов ответственность несет виновная сторона.</p>

<p></p><h4>Часть 6 ПЕРЕВОЗКА ПАССАЖИРОВ, БАГАЖА И ГРУЗОБАГАЖА</h4><p></p>

<p style="font-weight:500;">Статья 106. Ответственность железной дороги</p><br>

<p style="text-indent:2em;">(1) Железная дорога должна обеспечивать своевременную перевозку пассажиров и доставку багажа, грузобагажа, безопасность и качественное обслуживание пассажиров на вокзалах и в поездах,
 создание для них необходимых удобств, сохранность перевозимых багажа и грузобагажа.</p><br>

<p>(2) Железная дорога обязана обеспечивать движение пассажирских поездов по расписанию.</p><br>

<p style="text-indent:2em;">(3) Пассажирские вагоны, а также железнодорожные вокзалы и другие сооружения, предназначенные для обслуживания пассажиров, должны содержаться в исправном техническом состоянии и
 соответствовать строительным, санитарным нормам и правилам, иным требованиям нормативных актов.</p><br>

<p style="font-weight:500;">Статья 107. Категории поездов</p>

<p style="text-indent:2em;"></p><h6>(1) Поезда, предназначенные для перевозок пассажиров, классифицируются следующим образом:</h6><p></p>

<p>a) в зависимости от скорости движения - скоростные, скорые и пассажирские;</p><br>

<p style="text-indent:2em;">b) в зависимости от расстояния следования - дальнего следования (расстояние - свыше 700 км), местного следования (до 700 км) и пригородные (расстояние - до 150 км).</p><br>

<p style="text-indent:2em;">(2) Критерии определения категорий поездов в зависимости от скорости их движения и расстояния следования утверждаются центральным отраслевым органом публичного управления.</p><br>

<p style="font-weight:500;">Статья 108. Оборудование и обустройство станций и вокзалов v(1) Железнодорожные станции, открытые для выполнения операций, связанных с перевозками пассажиров, багажа и грузобагажа, должны соответствовать требованиям,
 обеспечивающим выполнение этих операций. v(2) На вокзалах должны быть билетные кассы. В зависимости от объемов перевозок на вокзалах оборудуются помещения для приема и выдачи багажа, камеры хранения ручной клади и багажа,
 залы ожидания, справочные бюро, комнаты отдыха для транзитных пассажиров, комнаты матери и ребенка, рестораны и буфеты, помещения для культурно-бытового и санитарно-гигиенического обслуживания пассажиров (почта, телеграф,
 телефон, радиоузел, парикмахерские, пункты медицинской помощи, туалеты и т.д.).</p><br>
 
<p style="text-indent:2em;">(3) Для удобства пассажиров и их безопасной посадки и высадки, для перехода через железнодорожные пути на железнодорожных станциях сооружаются платформы с навесами и павильонами,
 пешеходные тоннели или мосты.</p><br>

<p style="text-indent:2em;">(4) Привокзальные площади должны быть благоустроены и отвечать требованиям удобного и безопасного перемещения (передвижения) пешеходов и движения городского транспорта.
 Благоустройство таких площадей осуществляется органами местного публичного управления.</p><br>

<p style="text-indent:2em;">(5) Строительство, содержание и ремонт зданий железнодорожных вокзалов производятся за счет средств железной дороги и органов местного публичного управления.</p><br>

<p style="font-weight:500;">Статья 109. Обеспечение пассажиров информацией</p><br>

<p style="text-indent:2em;">Пассажиры должны быть обеспечены своевременной и достоверной информацией о времени отправления и прибытия поездов, стоимости проездных документов (билетов) и перевозки багажа и грузобагажа, о времени работы билетных и
 багажных касс, камер хранения, о расположении вокзальных помещений, об оказываемых услугах, о льготах, предоставляемых отдельным категориям пассажиров, а также другой информацией, предусмотренной Правилами оказания услуг
 по перевозке пассажиров, багажа, груза и грузобагажа для личных нужд на железнодорожном транспорте.</p><br>

<p style="font-weight:500;">Статья 110. Договор о перевозке пассажира</p><br>

<p style="text-indent:2em;">(1) В соответствии с договором о перевозке пассажира железная дорога обязуется перевезти его и принадлежащий ему багаж в пункт назначения, предоставляя пассажиру место в поезде согласно купленному проездному документу (билету),
 пассажир же обязуется оплатить стоимость проезда по установленному тарифу, при сдаче багажа - и стоимость провоза багажа, а отправитель грузобагажа - стоимость провоза грузобагажа.</p><br>

<p style="text-indent:2em;">(2) Заключение договора о перевозке пассажира удостоверяется проездным документом (билетом), а сдача пассажиром багажа и грузобагажа - багажной и грузобагажной квитанциями.</p><br>

<p style="text-indent:2em;">(3) Железная дорога и пассажиры обязаны выполнять Правила оказания услуг по перевозке пассажиров, багажа, груза и грузобагажа для личных нужд на железнодорожном транспорте и Правила перевозок пассажиров,
 багажа и грузобагажа по железной дороге.</p><br>

<p style="text-indent:2em;">(4) Обязательное или добровольное страхование жизни и здоровья пассажиров на период их следования по железной дороге осуществляется в соответствии с действующим законодательством.</p><br>

<p style="text-indent:2em;">(5) Каждый пассажир обязан иметь проездной документ (билет), а железная дорога обязана обеспечить его продажу до указанной пассажиром станции назначения согласно установленному тарифу,
 с учетом предусмотренных действующим законодательством льгот для некоторых категорий граждан.</p><br>

<p style="text-indent:2em;">(6) Компенсация предоставления некоторым категориям граждан льгот по оплате проезда на железнодорожном транспорте осуществляется за счет бюджетных средств.</p><br>

<p style="text-indent:2em;">(7) Формы документов на перевозку пассажиров, багажа и грузобагажа устанавливаются центральным органом публичного управления в области транспорта по согласованию
 с центральным органом публичного управления в области финансов.</p><br>

<p style="text-indent:2em;">(8) Утерянные или испорченные проездные документы (билеты) не восстанавливаются, и уплаченные за них деньги пассажирам не возвращаются.</p><br>

<p style="font-weight:500;">Статья 111. Камеры хранения</p><br>

<p style="text-indent:2em;">(1) Железнодорожные камеры хранения независимо от наличия или отсутствия у физического лица проездного документа (билета) осуществляют прием и хранение ручной клади и багажа,
 за исключением предметов и веществ, запрещенных к хранению Правилами оказания услуг по перевозке пассажиров, багажа, груза и грузобагажа для личных нужд на железнодорожном транспорте.</p><br>

<p style="text-indent:2em;">(2) Преимущественное право пользования услугами камер хранения ручной клади и багажа предоставляется транзитным пассажирам.</p><br>

<p style="font-weight:500;">Статья 112. Права пассажира</p>

<p style="text-indent:2em;"></p><h6>(1) Пассажир, совершающий поездку в поезде дальнего или местного следования, имеет право:</h6><p></p>

<p style="text-indent:2em;">a) приобрести проездной документ (билет) на любой поезд, в любой вагон до указанной им железнодорожной станции назначения, открытой для осуществления операций по перевозке пассажиров;</p><br>

<p style="text-indent:2em;">b) провезти с собой одного ребенка в возрасте не старше пяти лет, если он не занимает отдельного места, бесплатно и детей в возрасте от пяти до десяти лет с оплатой проезда по тарифу
 для детей этого возраста;</p><br>

<p style="text-indent:2em;">c) провезти с собой, не считая мелких вещей, ручную кладь, размеры и масса которой определяются Правилами оказания услуг по перевозке пассажиров, багажа,
 груза и грузобагажа для личных нужд на железнодорожном транспорте;</p><br>

<p>d) предъявив проездной документ (билет), сдать багаж для перевозки за плату по установленному тарифу;</p><br>

<p style="text-indent:2em;">e) делать остановки в пути следования с продлением срока действия проездного документа (билета) не более чем на десять суток;</p><br>

<p style="text-indent:2em;">f) в случае заболевания в пути следования продлить срок действия проездного документа (билета) на все время болезни (по предъявлении справки, выданной медицинским учреждением), в случае непредоставления
 пассажиру места в поезде - на время до отправления следующего поезда, в котором ему будет предоставлено место;</p><br>

<p style="text-indent:2em;">g) выехать поездом, отходящим ранее поезда, на который приобретен проездной документ (билет), после проставления в билетной кассе железнодорожной станции
 необходимой отметки в проездном документе (билете);</p><br>

<p style="text-indent:2em;">h) при опоздании на поезд не более чем на три часа, а вследствие болезни или несчастного случая - не более чем на трое суток с момента отправления поезда, на который был приобретен проездной документ (билет),
 - возобновить действие этого проездного документа (билета), сев в другой поезд и доплатив стоимость плацкарты, или получить обратно от железной дороги стоимость проездного документа (билета),
 за вычетом стоимости плацкарты.</p>

<p style="text-indent:2em;"></p><h6>(2) При возврате неиспользованного проездного документа (билета) для проезда в поезде дальнего следования в билетной кассе железнодорожной станции пассажиру возвращается:</h6><p></p>

<p style="text-indent:2em;">a) не позднее чем за 15 часов до отправления поезда - полная стоимость проездного документа (билета) и плацкарты;</p><br>

<p style="text-indent:2em;">b) менее чем за 15 часов, но не позднее чем за четыре часа до отправления поезда - стоимость п роездного документа (билета) и 50 процентов стоимости плацкарты;</p><br>

<p style="text-indent:2em;">c) менее чем за четыре часа до отправления поезда - стоимость проездного документа (билета), стоимость плацкарты в этом случае не выплачивается.
 При возврате проездного документа (билета) на обратный выезд в пункте его приобретения не позднее чем за 24 часа до отправления поезда пассажиру выплачивается
 полная стоимость проездного документа (билета), а при возврате последнего по истечении этого срока, но до отправления поезда - стоимость проездного документа (билета),
 за вычетом стоимости плацкарты. При возврате проездного документа (билета) на обратный выезд в пункте обратного отправления действуют условия, установленные настоящей статьей.
 Независимо от срока возврата проездного документа (билета) до отправления поезда, стоимость проезда возвращается в случаях отмены поезда, задержки отправления поезда, непредоставления
 пассажиру указанного в проездном документе (билете) места и нежелания его занять другое место, болезни пассажира. Не удерживается стоимость проезда при возврате проездного документа (билета),
 если пассажир в пункте пересадки на согласованный поезд опоздал по вине железной дороги;</p><br>

<p style="text-indent:2em;">d) стоимость проездного документа (билета), за вычетом стоимости плацкарты, за непроследованное пассажиром расстояние при прекращении поездки в пути следования.
 Возврат причитающейся пассажиру суммы осуществляется в порядке, предусмотренном Правилами перевозок пассажиров, багажа и грузобагажа по железной дороге.</p><br>

<p style="text-indent:2em;">(3) При прекращении поездки в пути следования в связи с перерывом в движении поездов по причинам, не зависящим от железной дороги, пассажиру возвращается стоимость
 проезда непроследованного им расстояния, если же движение поездов было прервано по вине железной дороги, возвращается полная стоимость проезда.</p><br>

<p style="text-indent:2em;">(4) Пассажир имеет право в пути следования занять свободное место в вагоне высшей категории в порядке, установленном Правилами оказания услуг по перевозке пассажиров,
 багажа, груза и грузобагажа для личных нужд на железнодорожном транспорте.</p>

<p style="text-indent:2em;"></p><h6>(5) При проезде в поезде пригородного сообщения пассажир имеет право:</h6><p></p>

<p>a) приобрести билет для разовой поездки в один конец либо в оба конца или типовой абонемент;</p><br>

<p>b) провезти с собой детей в возрасте не старше пяти лет бесплатно;</p><br>

<p style="text-indent:2em;">c) провезти с собой ручную кладь, размеры и масса которой определяются Правилами оказания услуг по перевозке пассажиров, багажа,
 груза и грузобагажа для личных нужд на железнодорожном транспорте.</p><br>

<p style="font-weight:500;">Статья 113. Продажа проездных документов (билетов) по заявкам организаций</p><br>

<p style="text-indent:2em;">(1) По заявкам организаций железная дорога осуществляет продажу проездных документов (билетов) для перевозки отдельных групп пассажиров.</p><br>

<p style="text-indent:2em;">(2) При возврате приобретенных организациями по заявкам проездных документов (билетов) в билетные кассы железнодорожных станций менее чем за семь суток, но не позднее чем за трое
 суток до отправления поезда удерживается 50 процентов стоимости плацкарты, а при возврате таких проездных документов (билетов) позднее чем за трое суток до отправления поезда стоимость
 плацкарты не возвращается.</p><br>

<p style="text-indent:2em;">(3) Прием от организаций заявок на приобретение проездных документов (билетов) для перевозки отдельных групп пассажиров, а также выплата стоимости таких проездных документов
 (билетов) при их возврате осуществляются в порядке, установленном центральным отраслевым органом публичного управления.</p><br>

<p style="font-weight:500;">Статья 114. Обязанность железной дороги перед пассажирами</p><br>

<p style="text-indent:2em;">В случае невозможности предоставить пассажиру место в вагоне в соответствии с купленным им проездным документом (билетом) железная дорога обязана предоставить ему,
 с его согласия, место в другом вагоне, даже если это вагон высшей категории, без взимания доплаты. Если пассажиру предоставлено, с его согласия, место в вагоне,
 стоимость проезда в котором ниже стоимости купленного им проездного документа (билета), ему возвращается разница в стоимости проезда.</p><br>

<p style="font-weight:500;">Статья 115. Обязанности пассажиров</p><br>

<p style="text-indent:2em;">Пассажиры обязаны соблюдать общественный порядок, правила поведения в вагонах, в вокзальных помещениях и не наносить вреда имуществу железнодорожного транспорта.</p><br>

<p style="font-weight:500;">Статья 116. Перевозка багажа и грузобагажа</p><br>

<p style="text-indent:2em;">(1) Железная дорога обязана по предъявлении пассажиром проездного документа (билета) принять к перевозке его багаж и отправить ближайшим
 поездом соответствующего назначения, в котором имеется багажный вагон.</p><br>

<p>(2) Пассажиры могут предъявлять к перевозке багаж с объявленной ценностью.</p><br>

<p>(3) За объявление ценности багажа взимается установленный дополнительный сбор.</p><br>

<p style="text-indent:2em;">(4) Перевозка грузобагажа осуществляется в порядке, установленном настоящим кодексом, Правилами оказания услуг по перевозке пассажиров, багажа, груза и грузобагажа для личных нужд
 на железнодорожном транспорте и Правилами перевозок пассажиров, багажа и грузобагажа по железной дороге.</p><br>

<p style="font-weight:500;">Статья 117. Условия приема к перевозке багажа и грузобагажа</p><br>

<p style="text-indent:2em;">(1) К перевозке в качестве багажа от пассажира принимаются такие вещи и предметы, которые по своим размерам, упаковке и свойствам подходят для погрузки и размещения в багажном вагоне
 и не могут послужить причиной повреждения багажа других пассажиров. Масса багажа и требования к его упаковке устанавливаются Правилами перевозок пассажиров, багажа и грузобагажа по железной дороге.</p><br>

<p style="text-indent:2em;">(2) Не допускаются к перевозке в пассажирских поездах в качестве ручной клади, багажа и грузобагажа опасные грузы, перечень которых предусмотрен Правилами оказания услуг по перевозке
 пассажиров, багажа, груза и грузобагажа для личных нужд на железнодорожном транспорте.</p><br>

<p style="font-weight:500;">Статья 118. Сроки доставки багажа и грузобагажа</p><br>

<p style="text-indent:2em;">(1) Срок доставки багажа и грузобагажа определяется временем следования до железнодорожной станции назначения поезда, которым они были отправлены.</p><br>

<p>(2) Дата отправления багажа и грузобагажа указывается в перевозочных документах.</p><br>

<p style="text-indent:2em;">(3) Если багаж и грузобагаж следуют по маршруту с перегрузкой в пути, срок их доставки определяется временем следования по данному маршруту согласованных поездов, в состав которых
 включены багажные вагоны, с добавлением одних суток на каждую перегрузку багажа и двух суток на перегрузку грузобагажа.</p><br>

<p style="text-indent:2em;">(4) Дата прибытия багажа и грузобагажа указывается железнодорожной станцией назначения в перевозочных документах.</p><br>

<p style="font-weight:500;">Статья 119. Утрата и просрочка в доставке багажа и грузобагажа</p><br>

<p style="text-indent:2em;">(1) Багаж и грузобагаж считаются утраченными, если не прибудут на железнодорожную станцию назначения по истечении десяти суток после окончания срока доставки,
 и их стоимость подлежит возмещению.</p><br>

<p style="text-indent:2em;">(2) Если багаж и грузобагаж прибыли по истечении указанного в части (1) срока, грузополучатель может забрать их, возвратив железной дороге сумму, выплаченную ему за утрату багажа
 и грузобагажа в порядке, предусмотренном настоящим кодексом.</p><br>

<p style="text-indent:2em;">(3) В случаях поступления письменно оформленного отказа от получения багажа, грузобагажа или непредставления грузополучателем решения относительно того, как с ними поступить,
 в течение четырех суток после письменного уведомления грузополучателя о прибытии на станцию назначения багажа, грузобагажа железная дорога вправе реализовать их в порядке,
 установленном настоящим кодексом.</p><br>

<p style="font-weight:500;">Статья 120. Выдача багажа</p><br>

<p style="text-indent:2em;">(1) Багаж выдается на железнодорожной станции назначения предъявителю багажной квитанции и проездного документа (билета). Выдача багажа производится в течение всего периода
 времени, на протяжении которого станция открыта для осуществления операций по приему и выдаче багажа.</p><br>

<p style="text-indent:2em;">(2) На железнодорожной станции назначения багаж хранится бесплатно 24 часа, не считая дня его прибытия. За превышение указанного срока железная дорога взимает плату
 за хранение в соответствии с Правилами оказания услуг по перевозке пассажиров, багажа, груза и грузобагажа для личных нужд на железнодорожном транспорте.</p><br>

<p style="font-weight:500;">Статья 121. Невостребованные багаж и грузобагаж</p><br>

<p style="text-indent:2em;">Багаж и грузобагаж физических лиц, не востребованные в срок до 30 дней, и грузобагаж юридических лиц, не востребованный в течение 10 дней с момента письменного уведомления
 о его прибытии, подлежат реализации в порядке, предусмотренном настоящим кодексом.</p><br>

<p style="font-weight:500;">Статья 122. Порядок перевозки почтовых отправлений</p><br>

<p style="text-indent:2em;">Порядок перевозки почтовых отправлений и включения багажно-почтовых вагонов в состав поездов устанавливается центральным отраслевым органом публичного управления.</p><br>

<p style="font-weight:500;">Статья 123. Обеспечение помещениями для оказания пассажирам услуг</p><br>

<p style="text-indent:2em;">Железная дорога на основании договоров об аренде предоставляет организациям связи на железнодорожных станциях помещения для оказания пассажирам почтовых, телеграфных и телефонных услуг.</p><br>

<p style="font-weight:500;">Статья 124. Строительство помещений для оказания услуг</p><br>

<p style="text-indent:2em;">(1) Строительство помещений, специально предназначенных для прижелезнодорожных почтамтов и почтовых отделений, размещаемых вне основных зданий вокзалов,
 а также строительство железнодорожных подъездных путей, специальных тупиков, тоннелей, сортировочных площадок, платформ и т.д., необходимых для обработки почтовых отправлений,
 осуществляется за счет средств центрального органа публичного управления в области связи. Строительные работы выполняются организациями, имеющими лицензию на строительство
 транспортных объектов.</p><br>

<p style="text-indent:2em;">(2) Участки для строительства объектов, перечисленных в части (1), должны отводиться в непосредственной близости от зданий железнодорожных вокзалов.</p>

<p></p><h4>Часть 7 ОТВЕТСТВЕННОСТЬ ЖЕЛЕЗНОЙ ДОРОГИ, ГРУЗООТПРАВИТЕЛЕЙ, ГРУЗОПОЛУЧАТЕЛЕЙ И ПАССАЖИРОВ</h4><p></p>

<p style="font-weight:500;">Статья 125. Ответственность за невыполнение заявки на перевозку грузов</p>

<p style="text-indent:2em;"></p><h6>(1) При невыполнении заявки на перевозку грузов в случае неподачи вагонов и контейнеров - к железной дороге, а при непредъявлении грузов, или неиспользовании поданных вагонов и контейнеров,
 или отказе от предусмотренных заявкой вагонов и контейнеров - к грузоотправителю применяется санкция в виде наложения штрафа в следующих размерах:</h6><p></p>

<p>a) по грузам, перевозка которых установлена в универсальных вагонах и тоннах, - 1,8 лея с тонны груза;</p><br>

<p>b) по грузам, перевозка которых установлена в специализированных вагонах и тоннах, - 3,6 лея с тонны груза;</p><br>

<p style="text-indent:2em;">c) по грузам, перевозимым в контейнерах, - 7,2 лея за контейнер массой брутто до 3 тонн включительно, 10,8 лея за контейнер массой брутто от 5 до 10 тонн включительно,
 45 леев за контейнер массой брутто свыше 10 тонн;</p><br>

<p style="text-indent:2em;">d) по грузам, перевозка которых установлена только в вагонах (кроме рефрижераторных и транспортеров), - 108 леев за каждый вагон, независимо от вида и количества осей;
 по грузам, перевозка которых установлена только в специализированных вагонах, - 216 леев за каждый вагон, независимо от вида и количества осей.</p>

<p style="text-indent:2em;"></p><h6>(2) За неподачу железной дорогой рефрижераторных вагонов и транспортеров в соответствии с принятой заявкой, за неиспользование грузоотправителем предоставленных ему
 вагонов или отказ от выделенных в установленном порядке вагонов соответственно с железной дороги и грузоотправителя взыскивается штраф в следующих размерах:</h6><p></p>

<p>a) по грузам, перевозка которых установлена в вагонах и тоннах, - 3,6 лея с тонны груза;</p><br>

<p style="text-indent:2em;">b) по грузам, перевозка которых установлена только в вагонах, - 216 леев за каждый вагон, независимо от вида и количества осей.</p><br>

<p style="text-indent:2em;">(3) Железная дорога несет имущественную ответственность за неподачу вагонов и контейнеров для выполнения заявки на перевозку грузов, за исключением случаев,
 когда неподача вагонов и контейнеров допущена по вине грузоотправителя.</p>

<p style="text-indent:2em;"></p><h6>(4) Грузоотправитель уплачивает железной дороге дополнительный сбор за непредъявление грузов, которые предназначены для отправки на указанные в заявке железные дороги
 (в местном сообщении - железнодорожные станции), в том числе и тогда, когда заявка на перевозку грузов полностью выполнена, в следующих размерах:</h6><p></p>

<p>a) по грузам, перевозка которых установлена в вагонах и тоннах, - 0,72 лея с тонны груза;</p><br>

<p style="text-indent:2em;">b) по грузам, перевозка которых установлена только в вагонах, - 36 леев за каждый вагон, независимо от вида и количества осей;</p><br>

<p style="text-indent:2em;">c) по грузам, перевозимым в контейнерах, - 3,6 лея за контейнер массой брутто до 5 тонн включительно, 7,2 лея за контейнер массой брутто от 5 до 10 тонн включительно,
 18 леев за контейнер массой брутто свыше 10 тонн.</p><br>

<p style="text-indent:2em;">(5) Штраф за невыполнение принятой заявки на перевозку грузов по железнодорожной линии узкой колеи взыскивается в размере 50 процентов штрафа,
 предусмотренного настоящей статьей по грузам, перевозка которых установлена только в вагонах.</p><br>

<p style="text-indent:2em;">(6) Штраф за невыполнение принятых заявок на перевозку грузов взимается независимо от платы за пользование вагонами и контейнерами.</p><br>

<p style="font-weight:500;">Статья 126. Освобождение грузоотправителя от ответственности за невыполнение принятой железной дорогой заявки на перевозку грузов</p>

<p style="text-indent:2em;"></p><h6>(1) Грузоотправитель освобождается от уплаты штрафа за невыполнение принятой железной дорогой заявки на перевозку грузов, если это произошло вследствие:</h6><p></p>

<p>a) возникновения форс-мажорных обстоятельств;</p><br>

<p>b) прекращения или ограничения погрузки грузов в случаях, предусмотренных статьей 55;</p><br>

<p style="text-indent:2em;">c) возникновения обстоятельств, при которых запрещено проводить операции по погрузке и выгрузке грузов, или аварии у грузоотправителя,
 в результате которой осуществление его основной производственной деятельности было прекращено;</p><br>

<p style="text-indent:2em;">d) неиспользования вагонов и контейнеров, поданных грузоотправителю сверх количества, указанного в заявке на перевозку грузов, без предварительного получения его согласия;</p><br>

<p style="text-indent:2em;">e) выполнения заявки на перевозку грузов в тоннах по грузам, перевозка которых установлена в вагонах и тоннах.</p><br>

<p style="text-indent:2em;">(2) В случаях, когда грузоотправитель в результате уплотненной загрузки вагонов и контейнеров использовал меньшее их количество, чем было предусмотрено поданной им заявкой,
 штраф за неиспользованные вагоны и контейнеры не взыскивается.</p><br>

<p style="text-indent:2em;">(3) Если подавший соответствующую заявку грузоотправитель предупреждает железнодорожную станцию о том, что им не будут использованы вагоны и контейнеры,
 не менее чем за двое суток до дня их погрузки, размер штрафа снижается на одну треть.</p><br>

<p style="font-weight:500;">Статья 127. Освобождение железной дороги от ответственности за полнение заявки на перевозку грузов</p>

<p style="text-indent:2em;"></p><h6>(1) Железная дорога освобождается от уплаты штрафа за невыполнение принятой заявки на перевозку грузов, если это произошло вследствие:</h6><p></p>

<p>a) возникновения форс-мажорных обстоятельств;</p><br>

<p>b) прекращения или ограничения погрузки грузов в случаях, предусмотренных статьей 55;</p><br>

<p style="text-indent:2em;">c) неподачи железной дорогой вагонов и контейнеров по причине невнесения грузоотправителем платы за перевозку грузов и
 других причитающихся железной дороге платежей по осуществленным ранее перевозкам.</p><br>

<p style="text-indent:2em;">(2) В случаях задержки грузоотправителем вагонов и контейнеров под погрузкой, выгрузкой, очисткой и промывкой железная
 дорога освобождается от имущественной ответственности за неподачу этому грузоотправителю того количества вагонов и контейнеров,
 которое было задержано.</p><br>

<p style="font-weight:500;">Статья 128. Необеспечение сохранности груза</p>

<p style="text-indent:2em;"></p><h6>Железная дорога несет имущественную ответственность за утрату, недостачу или повреждение (порчу) груза после принятия его к перевозке до выдачи грузополучателю ,
 если не докажет, что сохранность груза не была обеспечена по причинам, исключающим ее вину, в частности:</h6><p></p>

<p>a) по вине грузоотправителя или грузополучателя;</p><br>

<p>b) из-за особых естественных свойств груза;</p><br>

<p style="text-indent:2em;">c) из-за дефектов тары, упаковки, которые не могли быть замечены по наружному виду при приеме груза к перевозке, или в результате применения тары, упаковки,
 не соответствующих свойствам груза или установленным стандартам, при отсутствии следов повреждения тары, упаковки в пути следования;</p><br>

<p>d) из-за сдачи к перевозке груза, влажность которого превышала установленную норму.</p><br>

<p style="font-weight:500;">Статья 129. Освобождение от ответственности за необеспечение сохранности груза</p>

<p style="text-indent:2em;"></p><h6>(1) Железная дорога освобождается от ответственности за утрату, недостачу или повреждение (порчу) принятого к перевозке груза, если:</h6><p></p>

<p style="text-indent:2em;">a) груз прибыл в исправном вагоне или контейнере с исправными запорно-пломбировочными устройствами, установленными грузоотправителем, или в исправном подвижном
 составе без перегрузки в пути следования, с исправной защитной маркировкой либо неповрежденной увязкой или при наличии других признаков, свидетельствующих о сохранности груза;</p><br>

<p style="text-indent:2em;">b) недостача или повреждение (порча) груза произошли вследствие естественных причин, связанных с его перевозкой в открытом железнодорожном подвижном составе,
 при условии, что именно он был затребован заявителем;</p><br>

<p style="text-indent:2em;">c) груз перевозился в сопровождении представителя грузоотправителя или грузополучателя; vd) недостача груза не превышает норм естественной убыли и допустимой
 погрешности в измерении массы нетто;</p><br>

<p style="text-indent:2em;">d) утрата, недостача или повреждение (порча) груза произошли в результате внесения грузоотправителем в накладную недостоверных, неточных или неполных сведений.</p><br>

<p style="text-indent:2em;">(2) В указанных в части (1) случаях железная дорога несет имущественную ответственность за утрату, недостачу или повреждение (порчу) груза, если истец докажет,
 что они произошли по вине железной дороги.</p><br>

<p style="font-weight:500;">Статья 130. Возмещение ущерба</p>

<p style="text-indent:2em;"></p><h6>(1) Ущерб, причиненный при перевозке грузов, железная дорога возмещает следующим образом:</h6><p></p>

<p>a) в случаях утраты или недостачи груза - соответственно в размере полной или частичной стоимости груза;</p><br>

<p style="text-indent:2em;">b) в случаях повреждения (порчи) груза - в размере суммы, на которую снизилась его стоимость, а при невозможности восстановить поврежденный (испорченный) груз
 - в размере его стоимости;</p><br>

<p style="text-indent:2em;">c) в случаях утраты, недостачи или повреждения (порчи) груза, сданного для перевозки с объявленной стоимостью,- в размере объявленной стоимости груза или доли объявленной стоимости,
 соответствующей утраченной, недостающей или поврежденной (испорченной) части груза.</p><br>

<p style="text-indent:2em;">(2) Стоимость груза определяется по его цене, указанной в счете продавца или в договоре, а при их отсутствии - по цене, которая при сравнимых обстоятельствах
 обычно взимается за аналогичные товары.</p><br>

<p style="text-indent:2em;">(3) Наряду с возмещением стоимости утраченного, недостающего или поврежденного (испорченного) груза железная дорога возвращает взысканную ею провозную плату и
 иные платежи за перевозку, если они не были включены в стоимость груза, пропорционально количеству утраченного, недостающего или поврежденного (испорченного) груза.</p><br>

<p style="font-weight:500;">Статья 131. Просрочка в доставке груза</p><br>

<p style="text-indent:2em;">За просрочку в доставке грузов и порожних вагонов, принадлежащих грузоотправителю и грузополучателю или арендованных ими, железная дорога назначения, а
 при прямом смешанном сообщении - сторона, по вине которой произошла задержка, уплачивает штраф согласно статье 27 Соглашения о международном железнодорожном грузовом сообщении.</p><br>

<p style="font-weight:500;">Статья 132. Утрата груза</p><br>

<p style="text-indent:2em;">(1) Груз считается утраченным, если не выдан грузополучателю по его требованию по истечении 30 дней со дня срока доставки, а при перевозке в прямом смешанном сообщении
 - по истечении четырех месяцев со дня приема груза к перевозке.</p><br>

<p style="text-indent:2em;">(2) Если груз прибыл по истечении указанных в части (1) сроков, грузополучатель обязан распорядиться им в порядке, предусмотренном настоящим кодексом, и возвратить
 железной дороге полученную от нее сумму за утрату груза.</p><br>

<p style="text-indent:2em;">(3) Если грузополучатель отказался принять груз или не представил своего решения относительно дальнейшей судьбы груза в течение четырех суток с момента его уведомления
 о прибытии груза на железнодорожную станцию, железная дорога вправе реализовать груз в порядке, предусмотренном настоящим кодексом.</p><br>

<p style="font-weight:500;">Статья 133. Неточное указание в накладной наименования груза</p><br>

<p style="text-indent:2em;">За неточное указание в накладной наименования груза, требующего особых мер предосторожности при перевозке, за отправление груза, запрещенного к перевозке,
 или неправильное указание свойств груза его отправитель обязан уплатить штраф в размере пятикратной провозной платы за все расстояние перевозки, независимо
 от возмещения железной дороге убытков, вызванных указанными нарушениями.</p><br>

<p style="font-weight:500;">Статья 134. Самовольное использование вагонов и контейнеров</p><br>

<p style="text-indent:2em;">В случаях самовольного использования грузоотправителями, грузополучателями, иными организациями вагонов и контейнеров, принадлежащих железной дороге,
 а также самовольного использования железной дорогой вагонов и контейнеров, принадлежащих грузоотправителям, грузополучателям, другим организациям или арендованных ими,
 виновная сторона уплачивает в десятикратном размере штрафы, предусмотренные статьями 135 и 137, - за задержку вагонов и контейнеров.</p><br>

<p style="font-weight:500;">Статья 135. Ответственность за задержку вагонов</p><br>

<p style="text-indent:2em;">(1) За задержку вагонов в случаях, предусмотренных статьями 70, 104 и 134, на грузоотправителей и грузополучателей железной дорогой налагается
 штраф в размере 3,6 лея за каждый час простоя вагона.</p><br>

<p style="text-indent:2em;">(2) За задержку по вине железной дороги подачи вагонов под погрузку и выгрузку грузов сверх сроков, предусмотренных договорами,
 касающимися эксплуатации железнодорожных подъездных путей, подачи и уборки вагонов, а также за задержку уборки вагонов с мест погрузки и выгрузки на
 железнодорожных подъездных путях в случаях, когда уборка вагонов производится локомотивом, принадлежащим железной дороге, или за задержку приема вагонов
 с железнодорожных подъездных путей железная дорога уплачивает грузоотправителям или грузополучателям штраф в размере 3,6 лея за один вагон в час. Штраф
 начисляется за все время задержки с момента истечения сроков подачи и уборки вагонов, предусмотренных указанными договорами.</p><br>

<p style="text-indent:2em;">(3) За время задержки цистерн, цементовозов, бункерных полувагонов, минераловозов и других специализированных вагонов размер указанного в части (2)
 штрафа увеличивается в два раза, за время задержки рефрижераторных вагонов и транспортеров - в три раза.</p><br>

<p style="text-indent:2em;">(4) Задержка вагонов до 15 минут в расчет не принимается, задержка в 15 минут и более принимается за полный час.</p><br>

<p style="font-weight:500;">Статья 136. Сверхнормативный простой судов у причала</p><br>

<p style="text-indent:2em;">(1) В случае простоя у причала самоходного судна сверх срока, установленного узловым соглашением, из-за неподачи железной дорогой вагонов в количестве,
 предусмотренном соглашением для перевалки грузов с судна непосредственно в вагоны, железная дорога уплачивает штраф в размере 1,08 лея за каждую тонну
 не перегруженных с судна грузов по каждым суткам в отдельности.</p><br>

<p style="text-indent:2em;">(2) За сверхнормативный простой несамоходного судна размер указанного в части (1) штрафа снижается в два раза, за сверхнормативный простой рефрижераторного судна - увеличивается в два раза.</p><br>

<p style="font-weight:500;">Статья 137. Задержка универсальных контейнеров</p>

<p style="text-indent:2em;"></p><h6>За каждый час задержки универсального контейнера в случаях, предусмотренных статьями 70, 104 и 134, грузоотправители и грузополучатели уплачивают железной
 дороге штраф в следующих размерах:</h6><p></p>

<p>a) за контейнер массой брутто менее 5 тонн - 0,54 лея;</p><br>

<p>b) за контейнер массой брутто от 5 до 10 тонн включительно - 0,9 лея;</p><br>

<p>c) за контейнер массой брутто свыше 10 тонн - 1,8 лея.</p><br>

<p style="font-weight:500;">Статья 138. Освобождение от платы за пользование вагонами и контейнерами</p>

<p style="text-indent:2em;"></p><h6>Грузоотправитель, грузополучатель, порт освобождаются от платы за пользование вагонами и контейнерами вследствие:</h6><p></p>

<p style="text-indent:2em;">a) возникновения форс-мажорных обстоятельств: военных действий, блокады, эпидемий, которые обусловили перерыв в движении на железнодорожных подъездных путях,
 обстоятельств, при которых запрещено проводить погрузочно-разгрузочные работы, или аварий, произошедших у грузоотправителя, грузополучателя либо в порту,
 в результате которых было остановлено производство;</p><br>

<p style="text-indent:2em;">b) подачи железной дорогой большего количества вагонов и контейнеров, чем было предусмотрено договором об эксплуатации железнодорожного подъездного пути и договором о подаче и уборке вагонов.</p><br>

<p style="font-weight:500;">Статья 139. Ответственность за задержку поданных под погрузку вагонов и отказ от их использования</p><br>

<p style="text-indent:2em;">(1) Если подача порожних специализированных вагонов грузоотправителю согласно заявке на перевозку грузов задерживается по причинам, зависящим от грузоотправителя,
 последний вносит плату за пользование вагонами, установленную Тарифным руководством, за все время задержки вагонов на железнодорожной станции погрузки.</p><br>

<p style="text-indent:2em;">(2) Если грузоотправитель уведомит железнодорожную станцию об отказе от использования порожних специализированных вагонов, плата за пользование этими вагонами
 исчисляется до момента получения железнодорожной станцией такого уведомления.</p><br>

<p style="text-indent:2em;">(3) В случае отказа грузоотправителя от погрузки порожних специализированных вагонов, которые прибыли в соответствии с его заявкой на железнодорожную станцию погрузки
 и в течение отчетных суток не могли быть использованы на этой станции другими грузоотправителями, железная дорога взыскивает с него плату по тарифу за пробег вагонов
 от железнодорожной станции их отправления, но не более чем за 300 километров.</p><br>

<p style="font-weight:500;">Статья 140. Перегруз вагонов и контейнеров</p><br>

<p style="text-indent:2em;">(1) За погрузку грузов в вагон и контейнер с превышением их грузоподъемности грузоотправитель уплачивает штраф в размере пятикратной платы за перевозку этих грузов.</p><br>

<p style="text-indent:2em;">(2) В случае аварии, возникшей вследствие перегруза вагона или контейнера, грузоотправитель возмещает железной дороге причиненный ущерб.</p><br>

<p style="font-weight:500;">Статья 141. Подача под погрузку неочищенных порожних вагонов и контейнеров</p><br>

<p style="text-indent:2em;">(1) При подаче железной дорогой под погрузку, с согласия грузоотправителя, неочищенных порожних вагонов и контейнеров с открытыми люками и дверьми, с неснятыми 
приспособлениями для крепления грузов железная дорога должна возместить грузоотправителю затраты, связанные с проведением не выполненных ею работ.</p><br>

<p style="text-indent:2em;">(2) На производство указанных в части (1) работ грузоотправителю предоставляется железной дорогой время, определяемое по соглашению сторон.</p><br>

<p style="text-indent:2em;">(3) В случаях нарушения требований, установленных статьей 68, грузополучатель уплачивает железной дороге штраф в размере 810 леев за вагон и 270 леев за контейнер,
 если договором не предусмотрено иное. Имущественную ответственность в определенных настоящей статьей размерах несет и железная дорога в случаях подачи грузоотправителю
 под погрузку, без его согласия, неочищенных порожних вагонов и контейнеров.</p><br>

<p style="font-weight:500;">Статья 142. Повреждение или утрата вагонов и контейнеров, принадлежащих железной дороге</p><br>

<p style="text-indent:2em;">При повреждении или утрате предоставленных железной дорогой вагонов и контейнеров грузоотправители, грузополучатели, другие организации обязаны отремонтировать поврежденные
 вагоны и контейнеры либо уплатить железной дороге штраф в размере пятикратной стоимости поврежденных или утраченных вагонов и контейнеров по их цене на момент повреждения
 или утраты. При этом железной дороге возмещаются также убытки в части, не покрытой штрафом, понесенные ею в результате повреждения или утраты вагонов и контейнеров.</p><br>

<p style="font-weight:500;">Статья 143. Повреждение или утрата вагонов и контейнеров, принадлежащих грузоотправителям и грузополучателям</p><br>

<p style="text-indent:2em;">(1) При повреждении железной дорогой вагонов и контейнеров, принадлежащих грузоотправителям, грузополучателям, другим организациям, она обязана их отремонтировать
 или уплатить владельцу штраф в размере пятикратной стоимости повреждения вагонов и контейнеров, а также возместить убытки в части, не покрытой штрафом, понесенные 
 владельцем вагонов и контейнеров из-за их повреждения.</p><br>

<p style="text-indent:2em;">(2) В случаях утраты железной дорогой вагонов и контейнеров, принадлежащих грузоотправителям, грузополучателям, другим организациям, она обязана, по их требованию,
 предоставить соответствующее количество вагонов и контейнеров во временное бесплатное пользование, а по истечении трех месяцев передать эти вагоны и контейнеры в 
 собственность таких организаций в порядке, установленном действующим законодательством.</p><br>

<p style="text-indent:2em;">(3) Порядок замены поврежденных или утраченных железной дорогой вагонов и контейнеров, переданных ею в аренду, предусматривается договором об аренде.</p><br>

<p style="font-weight:500;">Статья 144. Порядок взыскания штрафов</p><br>

<p>Штрафы, предусмотренные статьями 125, 133-137 и 140-143, взыскиваются в безакцептном порядке.</p><br>

<p style="font-weight:500;">Статья 145. Ответственность за прибывший груз</p><br>

<p style="text-indent:2em;">(1) После прибытия груза на железнодорожную станцию назначения и уведомления ею грузополучателя о прибытии груза в его адрес вся имущественная ответственность
 перед железной дорогой за данную перевозку возлагается на грузополучателя.</p><br>

<p style="text-indent:2em;">(2) Если груз прибыл на железнодорожную станцию назначения, но грузополучатель отсутствует и уведомить его о прибытии груза невозможно, имущественную ответственность
 перед железной дорогой по окончательным расчетам за данную перевозку несет грузоотправитель после получения им письменного уведомления от железной дороги.</p><br>

<p style="font-weight:500;">Статья 146. Необеспечение сохранности багажа</p><br>

<p style="text-indent:2em;">(1) Железная дорога несет имущественную ответственность за утрату, недостачу или повреждение (порчу) багажа с момента принятия его к перевозке до выдачи получателю,
 если не докажет, что сохранность багажа не была обеспечена из-за обстоятельств, которые она не могла ни предотвратить, ни устранить.</p>

<p style="text-indent:2em;"></p><h6>(2) Ущерб, причиненный при перевозке багажа, железная дорога возмещает следующим образом:</h6><p></p>

<p>a) в случаях утраты или недостачи багажа - соответственно в размере полной или частичной стоимости багажа;</p><br>

<p style="text-indent:2em;">b) в случаях повреждения (порчи) багажа - в размере суммы, на которую снизилась его стоимость, а при невозможности восстановить поврежденный (испорченный) багаж
 - в размере его стоимости;</p><br>

<p style="text-indent:2em;">c) в случаях утраты багажа, сданного для перевозки с объявленной ценностью, - в размере объявленной стоимости багажа.</p><br>

<p style="text-indent:2em;">(3) Стоимость багажа определяется по его цене, указанной в счете продавца или в договоре, а при их отсутствии - по цене, которая при сравнимых обстоятельствах обычно 
взимается за аналогичные товары.</p><br>

<p style="text-indent:2em;">(4) Наряду с возмещением ущерба, нанесенного утратой, недостачей или повреждением (порчей) багажа, железная дорога возвращает отправителю провозную плату и иные платежи,
 взысканные за перевозку утраченного, недостающего или поврежденного (испорченного) багажа.</p><br>

<p style="font-weight:500;">Статья 147. Просрочка в доставке багажа</p><br>

<p style="text-indent:2em;">(1) За просрочку в доставке багажа железная дорога назначения на основании акта, составленного по требованию получателя багажа, уплачивает ему при выдаче багажа пеню в соответствии
 с Правилами перевозок пассажиров, багажа и грузобагажа по железной дороге.</p><br>

<p style="text-indent:2em;">(2) Просрочка в доставке багажа начинает исчисляться после 24 часов суток, в течение которых должен был прибыть багаж.</p><br>

<p style="font-weight:500;">Статья 148. Сохранность ручной клади пассажира</p><br>

<p style="text-indent:2em;">Обеспечение целостности и сохранности ручной клади, перевозимой пассажиром, является обязанностью пассажира.</p><br>

<p style="font-weight:500;">Статья 149. Необеспечение сохранности грузобагажа. Просрочка в его доставке</p><br>

<p style="text-indent:2em;">За утрату, недостачу или повреждение (порчу) грузобагажа, а также за просрочку в его доставке железная дорога несет имущественную ответственность в порядке, установленном настоящим кодексом, как за утрату,
 недостачу или повреждение (порчу) багажа и просрочку в его доставке.</p><br>

<p style="font-weight:500;">Статья 150. Отправление или прибытие поезда с опозданием</p><br>

<p style="text-indent:2em;">(1) За отправление или прибытие поезда с опозданием (за исключением перевозок в пригородном сообщении) железная дорога уплачивает пассажиру штраф в размере трех процентов стоимости проезда за каждый час задержки,
 но не превышающем стоимости билета, если не докажет, что задержка в отправлении или опоздание поезда произошли вследствие возникновения форс-мажорных обстоятельств, устранения неисправности транспортных средств,
 угрожающей жизни и здоровью пассажиров, или иных обстоятельств, не зависящих от железной дороги.</p><br>

<p style="text-indent:2em;">(2) Порядок уплаты штрафа, указанного в части (1), определяется Правилами оказания услуг по перевозке пассажиров, багажа, груза и грузобагажа для личных нужд на железнодорожном транспорте.</p><br>

<p style="font-weight:500;">Статья 151. Отправление в багаже предметов, перевозка которых запрещена При обнаружении в сданном для перевозки багаже предметов, перевозка которых в качестве багажа запрещена,
 отправитель уплачивает штраф в размере десятикратной стоимости провоза багажа, если в соответствии с действующим законодательством это нарушение не влечет за собой
 административной или уголовной ответственности.</p><br>

<p style="font-weight:500;">Статья 152. Ответственность за вред, причиненный жизни и здоровью пассажира</p><br>

<p style="text-indent:2em;">Железная дорога несет имущественную ответственность за вред, причиненный жизни и здоровью пассажира, согласно действующему законодательству.</p><br>

<p style="font-weight:500;">Статья 153. Соглашения, ограничивающие или исключающие ответственность</p><br>

<p style="text-indent:2em;">Любые предварительные соглашения, заключенные железной дорогой с грузоотправителями, грузополучателями или пассажирами, имеющие целью ограничить либо исключить имущественную 
ответственность сторон, считаются недействительными, и любые ссылки на них в перевозочных документах, не предусмотренные настоящим кодексом или действующим законодательством, не имеют силы.</p>

<p></p><h4>Часть 8 АКТЫ, ПРЕТЕНЗИИ, ИСКИ</h4><p></p>

<p style="font-weight:500;">Статья 154. Коммерческий акт</p><br>

<p style="text-indent:2em;">(1) Обстоятельства, являющиеся основанием для возложения ответственности на железную дорогу, грузоотправителей, грузополучателей, на порты, другие организации, а 
также на пассажиров при осуществлении перевозок пассажиров, грузов, багажа и грузобагажа по железной дороге, удостоверяются коммерческими актами, актами общей формы и иными актами.</p>

<p style="text-indent:2em;"></p><h6>(2) Коммерческий акт составляется для удостоверения следующих обстоятельств:</h6><p></p>

<p style="text-indent:2em;">a) несоответствие наименования, массы, количества мест груза, багажа и грузобагажа данным, внесенным в перевозочные документы;</p><br>

<p>b) повреждение (порча) груза, багажа и грузобагажа;</p><br>

<p style="text-indent:2em;">c) обнаружение груза, багажа и грузобагажа без перевозочных документов, а также перевозочных документов без груза, багажа и грузобагажа;</p><br>

<p>d) возвращение железной дороге похищенных груза, багажа и грузобагажа;</p><br>

<p style="text-indent:2em;">e) непередача железной дорогой груза на железнодорожный подъездной путь в течение 24 часов после документального оформления выдачи груза. В
 таких случаях коммерческий акт составляется только по требованию грузополучателя.</p>

<p style="text-indent:2em;"></p><h6>(3) Коммерческий акт составляется:</h6><p></p>

<p style="text-indent:2em;">a) при выгрузке груза, багажа и грузобагажа в местах общего пользования - в день выгрузки, в остальных случаях - в день выдачи груза грузополучателю;</p><br>

<p style="text-indent:2em;">b) при выгрузке груза в местах собственного пользования - в день выгрузки, при этом проверка груза должна производиться в процессе выгрузки или непосредственно после нее;</p><br>

<p style="text-indent:2em;">c) в пути следования груза, багажа и грузобагажа - в день установления обстоятельств, подлежащих удостоверению коммерческим актом. При невозможности составления коммерческого акта в указанный
 срок он должен быть оформлен в течение следующих суток.</p><br>

<p style="text-indent:2em;">(4) При выдаче с участием железной дороги однородных грузов, которые были перевезены навалом или насыпью и прибыли от одного грузоотправителя в адрес одного грузополучателя в исправных вагонах
 без признаков утраты, случаи выявления недостачи, превышающей норму естественной убыли массы таких грузов и допустимые погрешности в измерении массы нетто, а также случаи обнаружения излишков,
 установленных как разница между массой грузов, определенной на станции отправления, и массой грузов, определенной на станции назначения, с учетом допустимых погрешностей в измерении массы нетто
 таких грузов, перевозимых отдельными отправками, при проверке за данные календарные сутки оформляются одним коммерческим актом.</p><br>

<p style="text-indent:2em;">(5) Недостача или излишки грузов, перевезенных навалом, насыпью или наливом с перевалкой либо перегрузкой в пути следования, отгруженных одним грузоотправителем в адрес одного грузополучателя и
 прибывших в исправных вагонах без признаков утраты в пути следования, определяются по результатам проверки всей партии выданных грузов и оформляются одним коммерческим актом.</p><br>

<p>(6) Коммерческий акт составляется в трех экземплярах и заполняется без помарок, подчисток и исправлений.</p>

<p style="text-indent:2em;"></p><h6>(7) В коммерческом акте должны содержаться:</h6><p></p>

<p style="text-indent:2em;">a) точное и подробное описание состояния груза, багажа и грузобагажа, а также обстоятельств, послуживших причиной составления коммерческого акта. Не разрешается внесение в
 коммерческий акт каких-либо предположений и выводов о причинах необеспечения сохранности груза либо о виновности грузоотправителя или железной дороги. На вопросы, включенные
 в бланк коммерческого акта, должны быть даны точные ответы. Не допускаются прочерки и знаки повтора в местах повторной записи необходимых данных;</p><br>

<p style="text-indent:2em;">b) данные о правильности погрузки, размещения и крепления груза, багажа, грузобагажа, а также о наличии защитной маркировки для грузов, перевозимых в открытом подвижном составе.
 При неправильных погрузке, размещении или креплении груза в коммерческом акте указывается, какое нарушение допущено.</p><br>

<p style="text-indent:2em;">(8) К коммерческому акту, составленному при обнаружении порчи скоропортящегося груза, прилагается выписка из журнала температур.</p><br>

<p style="text-indent:2em;">(9) Лица, составившие и подписавшие коммерческий акт, содержащий недостоверную информацию , несут ответственность согласно действующему законодательству.</p><br>

<p style="text-indent:2em;">(10) Коммерческий акт подписывается грузополучателем, если он участвует в проверке груза, багажа, грузобагажа, и работниками железной дороги,
 список которых устанавливается Правилами перевозок грузов по железной дороге.</p><br>

<p style="text-indent:2em;">(11) По требованию грузополучателя железнодорожная станция обязана в течение трех дней выдать коммерческий акт. Порядок пересылки коммерческих актов одной
 организацией железнодорожного транспорта другой определяется центральным отраслевым органом публичного управления.</p><br>

<p style="text-indent:2em;">(12) При необоснованной задержке в составлении коммерческого акта свыше предусмотренного настоящей статьей срока или отказе от его оформления железная дорога несет
 ответственность согласно действующему законодательству.</p><br>

<p style="text-indent:2em;">(13) В случаях отказа железнодорожной станции от составления коммерческого акта или оформления его с нарушением установленных требований грузополучатель имеет право подать
 письменное заявление о получении отказа или допущенном нарушении непосредственно администрации железной дороги или начальнику железнодорожной станции.</p><br>

<p style="text-indent:2em;">(14) Администрация железной дороги обязана дать грузополучателю, по его требованию, мотивированный ответ на заявление о получении отказа в составлении коммерческого акта или
 о составлении его с нарушением установленных требований в отношении скоропортящихся грузов - в течение одного дня, в отношении других грузов, багажа или грузобагажа - в течение
 трех дней с момента получения заявления. При обоснованности заявления дополнительный сбор за хранение груза, багажа или грузобагажа за время задержки в составлении коммерческого
 акта с грузополучателя не взимается.</p><br>

<p style="text-indent:2em;">(15) Железная дорога обязана составить коммерческий акт, если ею выявлены обстоятельства, указанные в настоящей статье, или если хотя бы на одно из них указал грузополучатель.</p><br>

<p style="text-indent:2em;">(16) Представители сторон, участвующие в составлении коммерческого акта, не имеют права отказаться от его подписания. При несогласии представителей сторон с содержанием
 коммерческого акта они вправе изложить свое мнение особо.</p><br>

<p style="text-indent:2em;">(17) Для удостоверения не предусмотренных настоящей статьей обстоятельств составляются акты общей формы и иные акты в порядке, установленном Правилами перевозок грузов
 по железной дороге и Правилами перевозок пассажиров, багажа и грузобагажа по железной дороге.</p><br>

<p style="font-weight:500;">Статья 155. Претензия, относящаяся к перевозке груза или грузобагажа</p><br>

<p style="text-indent:2em;">(1) До предъявления к железной дороге иска, касающегося перевозки груза или грузобагажа, в обязательном порядке необходимо предъявление к ней претензии.</p>

<p style="text-indent:2em;"></p><h6>(2) Право на предъявление претензии и иска, относящихся к перевозке груза или грузобагажа, имеют:</h6><p></p>

<p style="text-indent:2em;">a) в случаях утраты груза или грузобагажа - грузополучатель либо грузоотправитель при условии представления квитанции о приеме груза или грузобагажа с отметкой железнодорожной
 станции назначения о неприбытии груза или грузобагажа, если же нет возможности представить грузовую или грузобагажную квитанцию, - при условии предъявления документа об оплате
 стоимости груза или грузобагажа, подтвержденной банком либо иной кредитной организацией, и справки железной дороги об отправке груза или грузобагажа с отметкой железнодорожной
 станции назначения о том, что они не прибыли;</p><br>

<p style="text-indent:2em;">b) в случаях недостачи или повреждения (порчи) груза или грузобагажа - грузополучатель либо грузоотправитель при условии представления накладной или ее в установленном порядке
 заверенной копии, либо грузобагажной квитанции и коммерческого акта, выданных железной дорогой, либо накладной или ее в установленном порядке заверенной копии с отметкой железной
 дороги о составлении коммерческого акта (в случае его утраты), либо накладной или ее в установленном порядке заверенной копии и документов об обжаловании отказа железной дороги в
 составлении коммерческого акта;</p><br>

<p style="text-indent:2em;">c) в случаях просрочки в доставке груза или грузобагажа - грузополучатель или грузоотправитель при условии представления подлинника накладной и грузобагажной квитанции;</p><br>

<p style="text-indent:2em;">d) в случаях задержки в выдаче груза или грузобагажа - грузополучатель или грузоотправитель при условии представления накладной, грузобагажной квитанции и акта общей формы.</p><br>

<p style="text-indent:2em;">(3) Грузополучатель и грузоотправитель могут передать свое право на предъявление претензии и иска другим юридическим и физическим лицам, оформив в установленном
 порядке договор о д елегировании прав или доверенность.</p><br>

<p style="font-weight:500;">Статья 156. Претензия, относящаяся к перевозке пассажира или багажа</p>

<p style="text-indent:2em;"></p><h6>До предъявления к железной дороге иска, относящегося к перевозке пассажира или багажа, в обязательном порядке необходимо предъявление к ней претензии в случаях:</h6><p></p>

<p>a) утраты багажа - предъявителем багажной квитанции;</p><br>

<p style="text-indent:2em;">b) недостачи, повреждения (порчи) багажа или просрочки в его доставке - предъявителем выданного железной дорогой коммерческого акта, удостоверяющего недостачу,
 повреждение (порчу) багажа, или акта общей формы о просрочке в доставке багажа;</p><br>

<p style="text-indent:2em;">c) задержки в отправлении или опоздания поезда - пассажиром при условии представления проездного документа (билета).</p><br>

<p style="font-weight:500;">Статья 157. Порядок предъявления претензий</p><br>

<p>(1) Претензии, относящиеся к перевозке грузов или грузобагажа, предъявляются к железной дороге назначения.</p>

<p style="text-indent:2em;"></p><h6>(2) Претензии, относящиеся к перевозке грузов в прямом смешанном сообщении, предъявляются:</h6><p></p>

<p>a) к железной дороге назначения, если конечным пунктом перевозки груза является железнодорожная станция;</p><br>

<p style="text-indent:2em;">b) к организации другого вида транспорта, которая обслуживает конечный пункт перевозки груза или в ведении которой находится этот пункт.</p><br>

<p style="text-indent:2em;">(3) Претензии грузоотправителей и грузополучателей по поводу взыскания штрафов и начисления пеней предъявляются к железной дороге отправления или к железной дороге назначения.</p><br>

<p style="text-indent:2em;">(4) Претензии в отношении повреждений по вине железной дороги подвижного состава и контейнеров, принадлежащих грузоотправителям, грузополучателям,
 другим организациям или арендованных ими, рассматриваются железной дорогой по месту нахождения владельца или арендатора подвижного состава и контейнеров.</p><br>

<p style="text-indent:2em;">(5) Претензии, касающиеся перевозки пассажиров и багажа, могут быть предъявлены к железной дороге отправления или к железной дороге назначения, по усмотрению заявителя.</p><br>

<p style="font-weight:500;">Статья 158. Документы, прилагаемые к претензии</p><br>

<p style="text-indent:2em;">(1) К претензии должны быть приложены оригиналы документов, подтверждающих предъявленные заявителем требования.</p><br>

<p style="text-indent:2em;">(2) К претензиям относительно утраты, недостачи или повреждения (порчи) грузов или грузобагажа, кроме документов, подтверждающих право на предъявление претензии,
 обязательно прилагается документ, удостоверяющий количество и действительную стоимость (без включения упущенных доходов и неосуществленных затрат) отправленных
 груза или грузобагажа либо стоимость недостающих груза или грузобагажа (деталей, запчастей).</p><br>

<p style="font-weight:500;">Статья 159. Сроки предъявления претензий</p><br>

<p style="text-indent:2em;">(1) Претензии к железной дороге могут быть предъявлены в течение шести месяцев, претензии относительно взыскания штрафов и начисления пеней - в течение 45 дней.</p>

<p style="text-indent:2em;"></p><h6>(2) Сроки предъявления претензий исчисляются:</h6><p></p>

<p style="text-indent:2em;">a) для претензий о возмещении за недостачу или повреждение (порчу) грузов, багажа или грузобагажа - со дня выдачи груза, багажа или грузобагажа;</p><br>

<p>b) для претензий о возмещении за утрату грузов - по истечении 30 дней со дня окончания срока доставки;</p><br>

<p style="text-indent:2em;">c) для претензий о возмещении за утрату грузов в процессе их перевозки в прямом смешанном сообщении - по истечении четырех месяцев со дня приема груза к перевозке;</p><br>

<p style="text-indent:2em;">d) для претензий о возмещении за утрату багажа или грузобагажа - по истечении 30 дней после окончания срока доставки багажа или грузобагажа физических лиц и по истечении
 10 дней после окончания срока доставки грузобагажа юридических лиц;</p><br>

<p style="text-indent:2em;">e) для претензий при просрочке в доставке грузов, багажа или грузобагажа - со дня выдачи груза, багажа или грузобагажа;</p><br>

<p style="text-indent:2em;">f) для претензий о возврате платы за пользование вагонами и контейнерами или штрафов за задержку вагонов и контейнеров - со дня получения от железной дороги лицом,
 подавшим рекламацию, копии инкассового поручения (счета) о начислении платы или штрафа;</p><br>

<p style="text-indent:2em;">g) для претензий о взыскании штрафов за невыполнение принятых заявок на перевозку грузов - по истечении пяти дней со дня взыскания штрафа;</p><br>

<p style="text-indent:2em;">h) для претензий о взыскании штрафов за самовольное занятие железной дорогой вагонов и контейнеров, принадлежащих грузоотправителям,
 грузополучателям, другим организациям или арендованных ими, - по истечении срока доставки грузов в этих вагонах и контейнерах или срока их
 возврата на станции приписки;</p><br>

<p style="text-indent:2em;">i) в остальных случаях, связанных с перевозкой, - со дня наступления события, послужившего основанием для предъявления претензии.</p><br>

<p style="text-indent:2em;">(3) Железная дорога вправе принимать для рассмотрения претензии и по истечении установленных настоящей статьей сроков, если признает
 уважительной причину предъявления претензии в более поздний срок, чем это предусмотрено.</p><br>

<p style="font-weight:500;">Статья 160. Срок рассмотрения претензий</p><br>

<p style="text-indent:2em;">(1) Железная дорога обязана рассмотреть предъявленную к ней претензию и письменно уведомить заявителя о результатах ее рассмотрения в 30-дневный срок со дня получения претензии.</p><br>

<p style="text-indent:2em;">(2) При частичном удовлетворении или отклонении предъявленной к железной дороге претензии в уведомлении о результатах ее рассмотрения должно
 быть указано основание принятого железной дорогой решения со ссылкой на соответствующую статью настоящего кодекса. В таких случаях приложенные
 к претензии документы возвращаются заявителю.</p><br>

<p style="text-indent:2em;">(3) Если при рассмотрении претензии установлено, что груз и грузобагаж согласно заявлению грузоотправителя или первоначального грузополучателя переадресованы
 либо выданы другому грузополучателю, претензия возвращается заявителю с указанием, где, когда и кому именно были выданы груз и грузобагаж, и организации, по заявлению которой была произведена переадресовка либо
 выдача груза, грузобагажа, для осуществления непосредственного расчета с фактическим грузополучателем или с организацией, по заявлению которой была произведена переадресовка либо выдача груза и грузобагажа.</p><br>

<p style="font-weight:500;">Статья 161. Иски к железной дороге</p><br>

<p style="text-indent:2em;">(1) Иски к железной дороге, относящиеся к перевозке грузов, багажа и грузобагажа, могут быть предъявлены в случаях частичного удовлетворения или отклонения железной дорогой претензий либо
 в случаях неполучения от нее ответа в течение 30 дней со дня регистрации претензии.</p><br>

<p style="text-indent:2em;">(2) Указанные в части (1) иски предъявляются в компетентную судебную инстанцию по месту нахождения администрации железной дороги, к которой была предъявлена или могла быть
 предъявлена претензия, в течение одного года со дня наступления события, послужившего основанием для предъявления претензии.</p><br>

<p style="text-indent:2em;">(3) Иски, касающиеся перевозки пассажиров, багажа и грузобагажа, могут быть предъявлены только по истечении установленных статьей 159 сроков предъявления претензий или 
до истечения этих сроков, если железной дорогой был дан ответ на предъявленную к ней претензию.</p><br>

<p style="text-indent:2em;">(4) При рассмотрении исков к железной дороге документы о причинах необеспечения сохранности грузов, багажа и грузобагажа юридических и физических лиц (коммерческие акты,
 акты общей формы, иные акты), составленные железной дорогой в одностороннем порядке, подлежат рассмотрению судебной инстанцией наряду с другими документами, 
 подтверждающими обстоятельства, которые могут послужить основанием для возложения имущественной ответственности на железную дорогу, отправителя либо получателя грузов,
 багажа и грузобагажа - юридических и физических лиц.</p><br>

<p style="font-weight:500;">Статья 162. Иски к грузополучателям, грузоотправителям и пассажирам</p><br>

<p style="text-indent:2em;">(1) Иски железной дороги к грузополучателям, грузоотправителям и пассажирам, относящиеся к перевозке пассажиров, грузов, багажа и грузобагажа, могут быть предъявлены в
 компетентную судебную инстанцию в течение одного года со дня наступления события, послужившего основанием для предъявления иска.</p>

<p style="text-indent:2em;"></p><h6>(2) Указанный в части (1) срок исчисляется:</h6><p></p>

<p style="text-indent:2em;">a) для исков о взыскании штрафов за невыполнение заявок на перевозку грузов - по истечении пяти дней после уведомления железной дорогой;</p><br>

<p>b) в остальных случаях - со дня наступления события, послужившего основанием для предъявления иска.</p>

<p></p><h4>ГЛАВА III ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="font-weight:500;">Статья 163</p><br>

<p style="text-indent:2em;">Контроль за соблюдением положений настоящего кодекса, иных нормативных актов, относящихся к деятельности железнодорожного транспорта, осуществляется центральным отраслевым органом публичного 
управления.</p><br>

<p style="font-weight:500;">Статья 164</p><br>

<p style="text-indent:2em;">До приведения действующего законодательства в соответствие с настоящим кодексом нормативные акты применяются в части, не противоречащей ему.</p><br>

<p style="font-weight:500;">Статья 165</p>

<p style="text-indent:2em;"></p><h6>Правительству:</h6><p></p>

<p>a) привести свои нормативные акты в соответствие с настоящим кодексом;</p><br>

<p style="text-indent:2em;">b) утвердить положение о дисциплине работников железнодорожного транспорта, правила оказания услуг по перевозке пассажиров, багажа, груза и
 грузобагажа для личных нужд на железнодорожном транспорте и перечень грузов, подлежащих специальной охране и сопровождению; [Ст.165 пкт.c)
 исключен ЗП98/26.05.5, MO83/17.06.05 ст.380] [Ст.165 пкт.c) исключен ЗП98/26.05.05, MO83/17.06.05 ст.380]</p><br>

<p style="text-indent:2em;">c) определить порядок расчета тарифов на перевозки пассажиров, грузов, багажа и грузобагажа, порядок расчета платы за пользование вагонами и
 контейнерами, расчета дополнительных сборов, предусмотренных Тарифным руководством, а также порядок компенсации затрат, связанных с оказанием
 помощи железной дороге в ликвидации крушений, аварий, последствий стихийных бедствий.</p><br>

<p style="font-weight:500;">Статья 166</p><br>

<p style="text-indent:2em;">Центральному отраслевому органу публичного управления разработать на основании настоящего кодекса совместно с заинтересованными органами центрального
 публичного управления и утвердить правила перевозок грузов по железной дороге, правила перевозок пассажиров, багажа и грузобагажа по железной дороге и правила
 технической эксплуатации железной дороги, с последующей их регистрацией и публикацией в установленном порядке.</p><br>

<p><a href="https://www.legis.md/cautare/getResults?doc_id=108346&amp;lang=ru">Источник</a></p>

</div>
`