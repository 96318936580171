export const int23 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Коды для названий судов</h4><p></p>

<p>Рекомендация N 10 Европейской экономической комиссии ООН
(Женева, февраль 1978 года)</p><br>

<p style="font-weight:500;">Преамбула</p><br>

<p style="text-indent:2em;">Группа экспертов по автоматической обработке данных и кодированию - 
вспомогательный орган Рабочей группы по упрощению процедур международной 
торговли ЕЭК ООН, на своей пятой сессии в июне 1973 года, а также на 
последующих сессиях обсуждала необходимость и возможность создания кодов 
для обозначения названий судов. На девятой сессии, состоявшейся в феврале 
1975 года, эта тема была включена в программу работы Группы экспертов. 
После этого Международная палата судоходства и ряд членов Группы экспертов 
предприняли исследования и провели консультации как на национальном, так и на 
международном уровнях с лицами и органами, занимающимися вопросами морских перевозок. 
Одновременно Секретариат Экономической комиссии для Латинской Америки Организации 
Объединенных Наций провел исследование по этой же теме и представил его результаты. 
В результате проведенных исследований и консультаций, а также учитывая мнения, 
высказанные в ходе сессий Группы экспертов, и предложения, сделанные Секретариатом 
ЭКЛА, Рабочая группа по упрощению процедур международной торговли подготовила и 
утвердила следующую Рекомендацию.</p><br>

<p style="font-weight:500;">РЕКОМЕНДАЦИЯ</p>

<p style="text-indent:2em;"></p><h6>Рабочая группа по упрощению процедур международной торговли на своей седьмой 
сессии в феврале 1978 года приняла решение рекомендовать:</h6><p></p>

<p style="text-indent:2em;">1. участникам международной торговли, включая судовладельцев, портовые власти 
и другие стороны, имеющие отношение к морским перевозкам грузов</p><br>

<p style="text-indent:2em;">1.1 использовать на временной основе принятие Международным союзом электросвязи 
позывные сигналы судовых радиостанций во всех случаях, когда названия судов 
желательно обозначать кодами;</p><br>

<p style="text-indent:2em;">1.2 разработать и применять удобные методы перехода от позывных сигналов к 
обычным названиям судов, и наоборот;</p><br>

<p>2. правительствам и национальным органам по упрощению торговых процедур</p><br>

<p style="text-indent:2em;">2.1 содействовать временному использованию во всех соответствующих случаях 
в международной торговле позывных, принятых в МСЭ для судовых радиостанций, 
применяя их в качестве элементов данных, которые должны использоваться всякий 
раз, когда названия судов желательно обозначать кодами;</p><br>

<p style="text-indent:2em;">2.2 разработать необходимые меры ко Всемирной административной радиоконференции, 
которая состоится в 1979 году, с целью пересмотра структуры позывных кодов МСЭ с тем, 
чтобы они базировались на двухбуквенных кодах стран, представленных в Рекомендации N 3 
Рабочей группы по упрощению процедур международной торговли, которая соответствует 
международному стандарту ИСО 3166: "Коды для названий стран";</p><br>

<p>2.3 доложить о мерах, принятых для осуществления настоящей Рекомендации.</p>

<p style="text-indent:2em;"></p><h6>Рабочая группа просила Исполнительного секретаря Европейской экономической 
комиссии:</h6><p></p>

<p style="text-indent:2em;">a) разослать данную Рекомендацию исполнительным секретарям других региональных 
экономических комиссий Организации Объединенных Наций с просьбой довести ее до 
сведения правительств в их соответствующих регионах;</p><br>

<p style="text-indent:2em;">b) информировать Генерального секретаря МСЭ о настоящей Рекомендации и о 
требованиях Рабочей группы в отношении кодов для названий судов.</p><br>

<p style="font-weight:500;">Участие в седьмой сессии рабочей группы</p>

<p style="text-indent:2em;"></p><h6>На данной сессии присутствовали представители из:</h6><p></p>

<p style="text-indent:2em;">Австрии; Бельгии; Болгарии; Венгрии; Германии, Федеративной Республики; Германской 
Демократической Республики; Канады; Нидерландов; Норвегии; Польши; Румынии; Соединенного 
Королевства Великобритании и Северной Ирландии; Соединенных Штатов Америки; Союза Советских 
Социалистических Республик; Турции; Финляндии; Франции; Чехословакии; Швейцарии; Швеции;
 Австралии; Нигерии и Японии.</p><br>

<p style="text-indent:2em;">Были также представлены одно специализированное учреждение, и следующие межправительственные 
и неправительственные организации: Межправительственная морская консультативная организация 
(ИМКО); Генеральное соглашение по тарифам и торговле (ГАТТ); Европейское экономическое сообщество 
(ЕЭС); Совет таможенного сотрудничества (СТС); Центральное бюро международных железнодорожных перевозок 
(ЦБМЖП); Международная торговая палата (МТП); Международная авиатранспортная ассоциация (ИАТА); Международная
 организация по стандартизации (ИСО); Международный союз железных дорог (МСЖД); Международная палата судоходства 
 (МПС) и Международная федерация транспортно-экспедиторских ассоциаций (ФИАТА).</p><br>

<p style="font-weight:500;">КОДЫ ДЛЯ НАЗВАНИЙ СУДОВ</p><br>

<p style="font-weight:500;">I. История вопроса</p><br>

<p style="text-indent:2em;">1. В настоящее время название судна необходимо указывать в целом ряде документов, широко используемых в 
международной торговле. Даже если оно не требуется при обмене информацией между грузоотправителем и 
грузополучателем, например, когда морские перевозки составляют только часть смешанной 
транспортной операции, то оно необходимо другим сторонам, например, экспедитору, 
оператору смешанных перевозок, страховщику и т.п.</p><br>

<p style="text-indent:2em;">2. Проведенные в рамках Группы экспертов по автоматической обработке данных и 
кодированию исследования показали, что названия судов занимают на 
документах слишком много места; они не отражают национальной 
принадлежности судна (необходимой во многих случаях), и, кроме 
того, названия судов зачастую бывают слишком длинными, полные 
названия не являются уникальными, т.к. во многих случаях несколько
 судов носят одно и то же название и иногда плавают под 
 одним и тем же флагом.</p><br>

<p style="text-indent:2em;">3. Поэтому было признано желательным в подходящих случаях использовать 
неповторяющееся сокращенное или кодовое выражение названия судов.</p><br>

<p style="text-indent:2em;">4. В результате анализа нескольких существующих кодов, включая 
официальные номера судов, присваиваемые национальными регистрационными 
властями, и номера для обработки данных регистра Ллойда, Группа экспертов 
пришла к выводу, что наибольшими преимуществами обладают радиопозывные 
сигналы (РПС), установленные для радиосвязи и публикуемые МСЭ.</p><br>

<p style="text-indent:2em;">5. Список судовых станций МСЭ позволяет установить название судна по РПС, 
а позывной сигнал - по названию судна. В то же время, критерии, применяемые МСЭ 
при распределении групп серий между отдельными странами, имеют серьезные недостатки с 
точки зрения использования РПС в качестве кода для названия судов при передаче данных. 
Во-первых, они не имеют никакой мнемонической связи со страной регистрации; во-вторых, 
они не дают единого для каждой страны префикса или кода, компонента позывного сигнала, 
обозначающая страну, имеет непостоянную длину и, наконец, установленные серии позывных
 сигналов предназначены не только для обозначения страны. С другой стороны, использование 
 РПС обладает тем преимуществом, что их постоянно и хорошо ведет и обновляет МСЭ, который 
 ежегодно издает свой Список судовых станций и три дополнения между ежегодными выпусками.</p><br>

<p style="text-indent:2em;">6. Группа экспертов, принимая во внимание то, что Всемирная административная радиоконференция,
 которая состоится в 1979 году, очевидно, пересмотрит существующую систему распределения 
 позывных сигналов, решила предложить использовать эти РПС на временной основе для обозначения
 названий судов в международной торговле. Она также решила предложить МСЭ, чтобы любой новый 
 метод установления РПС основывался на коде стран АЛЬФА-2, принятом в международном стандарте
 ИСО 3166 и рекомендованном Рабочей группой по упрощению процедур международной торговли в ее
 Рекомендации N 3 в октябре 1974 года.</p><br>

<p style="font-weight:500;">II. Назначение и область применения</p><br>

<p style="text-indent:2em;">7. Позывные сигналы, публикуемые Международным союзом электросвязи в его ежегодном Списке
 судовых станций, следует использовать в качестве кода при обмене информацией между 
 участниками международной торговли, включая судовладельцев и портовые власти, и их 
 следует надлежащим образом использовать в соответствующих документах, при автоматической
 обработке и передаче данных и, при необходимости, - на любых носителях информации на 
 борту, в портах, в расписаниях движения судов и объявлениях и во всех других случаях,
 когда необходима видимая связь или переход между обычным названием судна и его кодовым
 обозначением.</p><br>

<p style="font-weight:500;">III. Структура кода</p><br>

<p style="text-indent:2em;">8. Список судовых станций ежегодно публикуемый МСЭ состоит из двух частей: в одной
 из них судовые станции расположены в алфавитном порядке по названиям судов независимо
 от страны регистрации, а в другой перечислены кодовые обозначения в алфавитном и в 
 номерном порядке в целях декодирования. Сигналы основаны на группах трехбуквенных 
 обозначений латинского алфавита или на буквенно-цифровых трехзначных сериях позывных
 сигналов, предоставляемых каждой стране или международной организации в соответствии
 с Регламентом радиосвязи МСЭ, установленным на Всемирной административной радиоконференции
 в 1959 году. Многим странам предоставлено несколько серий позывных сигналов; например, СССР
 было присвоено восемь серий, Франции и Соединенному Королевству - по семь серий, а Соединенным
 Штатам Америки - четыре серии.</p><br>

<p style="text-indent:2em;">На основе этих трехзначных комбинаций каждая страна, добавив одну букву к предоставленной ей
 комбинации, устанавливает четырехзначные позывные сигналы для всех своих судовых станций.</p>

<p style="text-indent:2em;"></p><h6>Например: </h6><p></p>

<p>радиосигналом для корабля "Amour" из Советского Союза является: ULCZ.</p><br>

<p style="text-indent:2em;">9. Судовые станции, использующие радиотелефон, также могут использовать позывной сигнал,
 состоящий из двух или трех букв, за которыми следуют четыре цифры.</p>

<p style="text-indent:2em;"></p><h6>Примеры: </h6><p></p>

<p style="font-weight:500;">Датский корабль "Jet" XP9500</p><br>

<p style="font-weight:500;">Корабль "Amour" из Соединенных Штатов WZA8651</p><br>

<p style="text-indent:2em;">10. Судовым станциям, использующим избирательные устройства вызова, компетентные административные
 органы присваивают избирательные номера для сигналов вызова, состоящие из пяти цифр. Они
 выделяются соответствующим властям по мере необходимости группами по 100 номеров. (Если
 для целей идентификации судовых станций, использующих избирательные устройства вызова,
 двухбуквенный код стран неприемлем, то можно использовать в качестве альтернативы
 четырехзначную переводную таблицу в виде матрицы, содержащуюся в приложении к ИСО
 3166 (пересмотренного).</p>

<p style="text-indent:2em;"></p><h6>Пример: </h6><p></p>

<p style="font-weight:500;">Шведское судно "Apollo": 43569</p><br>

<p style="text-indent:2em;">(В семнадцатом издании Списка судовых станций МСЭ числится 18 судов под названием "Apollo".
 Одному из них, а именно, вышеупомянутому судну шведские компетентные органы в добавление к
 избирательному номеру вызова 43569 присвоили позывные SIHU).</p><br>

<p style="text-indent:2em;">11. Если корабль меняет свою национальность, то существующий позывной сигнал аннулируется
 администрацией, предоставившей его, а новый позывной сигнал присваивается администрацией
 новой страны. Позывной сигнал также изменяется при изменении названия судна. Однако, если
 меняется владелец судна, но флаг судна остается прежним, то позывной сигнал сохраняется.</p><br>

<p style="font-weight:500;">IV. Доступ к коду и его ведение</p>

<p style="text-indent:2em;"></p><h6>12. Доступ к коду может иметь любое лицо:</h6><p></p>

<p style="text-indent:2em;">a) путем подписки на ежегодные издания Списка судовых станций МСЭ (публикуемые на английском,
 испанском и французском языках) и/или на выпускаемый раз в два года Алфавитный список позывных 
 сигналов (публикуемый на английском, испанском, китайском, русском и французском языках), оба 
 списка с квартальными дополнениями;</p><br>

<p style="text-indent:2em;">b) через еженедельные дополнения к Судовому регистру Ллойда или подобные издания, в которых
 публикуются позывные сигналы судов;</p><br>

<p style="text-indent:2em;">c) через национальные торговые палаты, организации по содействию экспорту или другие 
соответствующие национальные власти, уполномоченные правительствами выступить в качестве
 справочного источника для всех лиц, не имеющих другого доступа к этой информации. (Эти 
 справочные органы должны предоставлять информацию, требуемую в связи с этими кодами, без 
 промедления и бесплатно.)</p><br>

<p style="font-weight:500;">V. Связь между позывным сигналом и названием судна</p><br>

<p style="text-indent:2em;">13. В документах и телекоммуникационных сообщениях переход от названия судна к его кодовому
 позывному сигналу может быть осуществлен с помощью источников, указанных в пункте 12 настоящей
 Рекомендации.</p><br>

<p style="text-indent:2em;">14. Во всех других случаях потребители должны будут сами избрать наилучший способ обеспечения
 надежной и прямой связи между названием судна и его радиопозывным сигналом: например, в 
 расписаниях движения судов, указывать только радиопозывной сигнал или вместе с названием 
 судна, путем рекламных объявлений или указывая РПС тем или иным способом на борту судов, 
 находящихся в портах, на рейдах для погрузочно-разгрузочных работ, в воротах, в складских 
 зонах и т.п.</p><br>

<p style="font-weight:500;">VI. Пересмотр рекомендации</p><br>

<p style="text-indent:2em;">14. Рекомендация будет пересмотрена в свете накопленного опыта, равно как и результатов 
Всемирной административной радиоконференции, которая состоится в 1979 году.</p><br>

<p><a href="http://www.6pl.ru/asmap/r_oon10.htm">Источник</a></p>

</div>
`