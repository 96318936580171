import React from 'react'
import { Field } from 'redux-form'
import FlexBand from 'flexband'

import dict from '../../../shared/dictionaries'
import RenderNumber from '../../../shared/components/render-number'
import { required, moreThanZero } from '../../../shared/form-validators'
import TooltipWrapper from '../../../UI/popover-wrapper'
import SelectField from '../../../UI/select'
import Dropdown from '../../../UI/dropdown'
import { TransportationProps } from './types'
import './styles.scss'

const Transportation: React.FunctionComponent<TransportationProps> = props => {
  const { t, shippingType, langId, onChange, entityType, itemId } = props
  const shippingTypes = dict.shippingTypes()
  const transportTypes = dict.getTransportTypes(shippingType)(langId)
  const incotermsTypes = dict.incotermsTypes(langId)

  const isShippingTypeContainer = shippingType === 'container'
  const placement = window.innerWidth < 450 ? "left" : "top"
  const label = isShippingTypeContainer ? 'cargo.container.types' : 'cargo.transport.types'
  
  return (
    <div className="panel-content-row">
      <FlexBand style={{ width: '100%' }}>
        <div className="shipping-type">
          <label>{t('cargo.shipping.type')}</label>
          <Field
            name="shippingType"
            component={Dropdown}
            items={shippingTypes}
            onChange={onChange}
            disabled={itemId}
            useTranslate
          // validate={[required]}
          />
        </div>
        {entityType === 'transport' && shippingType !== 'avia' && (
          <div className="choose-quantity">
            <label className="required-field">
              {t(shippingType === 'auto' ? 'transport.auto.number' : 'transport.container.number')}
            </label>
            <FlexBand wrap="nowrap" alignItems="start">
              <div className="quantity-container">
                <div>
                  <Field
                    name="transport.quantity"
                    component={RenderNumber}
                    type="text"
                    placeholder="00"
                    integerLimit={2}
                    decimalLimit={0}
                    style={{ width: '50px' }}
                    validate={moreThanZero}
                    className="quantity-value"
                    checkNoZero
                  />
                </div>
                <div>
                  <Field
                    name="transport.recurring"
                    component={Dropdown}
                    items={dict.truckRecurings}
                    style={{ width: '150px' }}
                    validate={[required]}
                    className="quantity-dropdown"
                    useTranslate
                  />
                </div>
              </div>
            </FlexBand>
          </div>
        )}
        {(shippingType !== 'avia' && entityType === 'cargo') ||
          (isShippingTypeContainer && entityType === 'transport') ? (
          <FlexBand className="choose-transport" alignItems="top">
            <div className="choose-transport-dropdown">
              <label className="required-field">
                {t(label, { limit: 5 })}
              </label>
              <Field
                name="transport.types"
                component={SelectField}
                items={transportTypes}
                placeholder={`${t('choose')}...`}
                multi
                validate={required}
                maxOptionsToSelect={5}
              />
            </div>
            <TooltipWrapper
              style={{
                position: 'relative',
                top: 4,
              }}
              {...{ placement }}
              hover
              text={
                <div>
                  {shippingType !== 'container'
                    ? t('cargo.types')
                    : t('cargo.containers')}
                </div>
              }
            >
              <i className="fa fa-question-circle col-blue tooltip-trigger" />
            </TooltipWrapper>
          </FlexBand>
        ) : null}
      </FlexBand>

      {isShippingTypeContainer && entityType === 'cargo' && (
        <FlexBand alignItems="baseline" className="incoterms">
          <label className="required-field" style={{ marginBottom: 0 }}>
            Incoterms® 2020
          </label>
          <Field
            name="incoterms"
            className="incoterms-dropdown"
            component={Dropdown}
            items={incotermsTypes}
          // emptyOption={'EMPTY LIST'}
          />
          <TooltipWrapper
            hover
            text={
              <a
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                rel="noreferrer"
                href="http://incoterms.iccwbo.ru/pravila-incoterms-inkoterms"
              >
                {t('cargo.incoterms.2020')}
              </a>
            }
          >
            <i className="fa fa-question-circle col-blue tooltip-trigger" />
          </TooltipWrapper>
        </FlexBand>
      )}
    </div>
  )
}

export default Transportation
