import React, { Component } from 'react'
import Widget from '../shared/components/widget'
import Ads from '../ads'
import SiteStat from '../site-stat'
import BuySellShort from '../buy-sell/short'

export default class extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <Widget title="Условия использования">
            <div className="panel-content-row">Условия использования</div>
          </Widget>
        </div>
        <div className="col-md-4">
          <SiteStat />
          <BuySellShort />
          <Ads />
        </div>
      </div>
    )
  }
}
