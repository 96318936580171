export const md10 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->


<p></p><h4>ПОЛОЖЕНИЕ О ПОРЯДКЕ ПРИМЕНЕНИЯ ТАМОЖЕННОЙ КОНВЕНЦИИ
О МЕЖДУНАРОДНОЙ ПЕРЕВОЗКЕ ГРУЗОВ С ПРИМЕНЕНИЕМ КАРНЕТА TIR
(Конвенция TIR, 1975 г.)

<p></p></h4><h4>I. ОБЩИЕ ПОЛОЖЕНИЯ</h4><p></p>

<p style="text-indent:2em;">1. Конвенция TIR касается перевозки товаров, осуществляемой без их промежуточной перегрузки, дорожными транспортными средствами, составами транспортных
 средств или контейнерами, через одну или несколько границ, от таможни отправления одной из Договаривающихся Сторон до таможни назначения другой Договаривающейся
 Стороны при условии, что определенная часть операции TIR между ее началом и концом производится автомобильным транспортом.</p><br>

<p style="text-indent:2em;">2. Грузы, перевозимые с соблюдением процедуры TIR, освобождаются от оплаты или депозита ввозных или вывозных пошлин и сборов в промежуточных таможнях.
 В этих таможнях не взимаются сборы за таможенное оформление карнетов TIR.</p>

<p style="text-indent:2em;"></p><h6>3. Грузы, перевозимые с соблюдением процедуры TIR в запломбированных дорожных транспортных средствах, запломбированных составах транспортных средств или
 в запломбированных контейнерах, как правило, не подлежат таможенному досмотру в промежуточных таможнях. Таможенный досмотр в этих таможнях может производиться
 в исключительных случаях, если имеются достаточные основания полагать, что в запломбированных отделениях транспортных средств или в запломбированных контейнерах
 находятся предметы, не указанные в манифесте карнета TIR. Положения Конвенции TIR не препятствуют применению ограничений, вытекающих с точки зрения общественного
 порядка и морали, охраны здоровья или гигиены, а также ветеринарного или фитосанитарного контроля. 4. В настоящем Положении используются термины, определенные
 Конвенцией TIR. Дополнительно в настоящем Положении используются следующие термины:</h6><p></p>

<p style="text-indent:2em;">таможня ввоза - промежуточная таможня Республики Молдова, расположенная в пункте пропуска на границе и через которую грузы ввозятся в Республику Молдова;</p><br>

<p style="text-indent:2em;">таможня вывоза - промежуточная таможня Республики Молдова, расположенная в пункте пропуска на границе, через которую грузы вывозятся из Республики Молдова;</p><br>

<p>товары - грузы, перевозимые в соответствии с Конвенцией TIR.</p>

<p></p><h4>II. УСЛОВИЯ ПРИМЕНЕНИЯ ПРОЦЕДУРЫ TIR</h4><p></p>

<p style="text-indent:2em;">1. Процедура TIR применяется при условии, что товары: сопровождаются карнетом TIR, заполненным и оформленным в соответствии с Конвенцией TIR и настоящим Положением;</p><br>

<p>обеспечиваются гарантией гарантирующего объединения;</p><br>

<p style="text-indent:2em;">перевозятся в дорожных транспортных средствах, составах транспортных средств или контейнерах, предварительно соединенных для перевозки под таможенными печатями и
 пломбами (за исключением перевозок тяжелых или громоздких товаров), причем транспортные средства при перегоне своим ходом сами считаются грузами, если они конкретно
 указаны в грузовом манифесте карнета TIR.</p><br>

<p style="text-indent:2em;">2. На транспортные средства должны быть прикреплены прямоугольные таблички с надписью "TIR". Одна прикрепляется спереди, а другая - сзади дорожного транспортного
 средства или состава транспортных средств таким образом, чтобы они были хорошо видны. Эти таблички должны быть съемными или установленными таким образом, чтобы
 их можно было перевернуть, накрыть, сложить и т.д., чтобы показать, что данная перевозка не имеет отношения к процедуре TIR.</p><br>

<p style="text-indent:2em;">3. Операция TIR может проводиться через несколько таможен места отправления в одной или нескольких странах при условии, что общее число таможен места отправления
 и таможен места назначения не должно превышать четырех. В случае наличия нескольких таможен места отправления карнет TIR должен заполняться так, чтобы товары,
 впоследствии погруженные на различных таможнях, дополнительно вносились в грузовой манифест и чтобы таможни места отправления указывали в рубрике 16 данные о
 новых проставленных печатях и вносили отметки о подтверждении погруженных в дальнейшем грузов.</p><br>

<p style="text-indent:2em;">4. Таможни ввоза и таможни отправления Республики Молдова могут устанавливать срок доставки товаров до таможен назначения или таможен вывоза, находящихся
 на территории Республики Молдова, и определять маршрут движения транспортных средств.</p><br>

<p style="text-indent:2em;">5. Если сумма таможенных пошлин и сборов, подлежащая уплате за перевозимые товары, больше, чем сумма гарантии, или если имеются основания полагать, что перевозчик
 не может гарантировать соблюдение положений таможенного законодательства Республики Молдова, то может применяться порядок, когда товары сопровождаются должностными
 лицами таможенных органов. Порядок сопровождения установлен Положением о таможенном сопровождении.</p><br>

<p style="text-indent:2em;">6. Когда часть перевозки под прикрытием карнета TIR производится на трассе территории страны, не являющейся договаривающейся стороной Конвенции TIR, или если
 процедура TIR прерывается в связи с применением более льготных транзитных систем, то операция TIR приостанавливается на этой части пути. Перевозка с соблюдением
 процедуры TIR может возобновиться при условии, что таможенные печати и пломбы или иные средства таможенной идентификации не повреждены.</p>

<p></p><h4>III. КАРНЕТ TIR</h4><p></p>

<p style="text-indent:2em;">1. Карнет TIR состоит из 4 страниц обложки, желтого неотрывного листка N1/2 и корешка N1/2, отрывных листов N 1 и корешков N 1 (белого цвета), отрывных листков N2
 и корешков N2 (зеленого цвета) и протокола о дорожно-транспортных происшествиях (желтого цвета).</p><br>

<p style="text-indent:2em;">Комбинация отрывных листов и корешков (N1 и 2) предназначается, как правило, для использования в каждом государстве (отправления, транзита и назначения). Отрывной
 листок N1 и корешок N1 предназначен для использования в таможнях отправления и в таможнях ввоза, а отрывной листок N2 и корешок N2 - в таможнях вывоза и в таможнях
 назначения. Карнеты TIR, используемые в настоящее время, состоят из 4, 6, 14 или 20 листов (т. е. из 2, 3, 7 или 10 комплектов) и предназначаются соответственно для
 перевозки товаров через территорию 2, 3, 7 или 10 государств</p><br>

<p style="text-indent:2em;">К карнету TIR может прилагаться специальный лист, предназначенный для смешанных перевозок (когда транспортная операция производится с использованием нескольких видов
 транспорта). Указанный лист предназначен исключительно для перевозчиков, не заполняется и не проверяется таможенными органами, но может быть использован ими,
 при необходимости, в целях получения дополнительной информации.</p><br>

<p>2. Право осуществлять перевозку с соблюдением процедуры TIR имеет только держатель карнета TIR.</p><br>

<p style="text-indent:2em;">3. Карнеты TIR выдаются гарантийными объединениями государств- участников Конвенции TIR, 1975. В Республике Молдова карнеты TIR выдаются Молдавской ассоциацией
 международного автомобильного транспорта AITA. Эти карнеты печатаются на французском языке, за исключением лицевой стороны обложки, рубрики которой печатаются 
 также на английском языке, и первого желтого неотрывного листа, который печатается на румынском или английском языке.</p><br>

<p style="text-indent:2em;">4. Карнет TIR заполняется в соответствии с правилами, изложенными в приложении N 1 к настоящему Положению. Образцы заполнения обложки карнета TIR, неотрывного 
листа N1/2, отрывных листов N 1 и 2 и корешков N 1 и 2 приводятся в соответствии с приказом Департамента таможенного контроля Республики Молдова.</p><br>

<p style="text-indent:2em;">В карнете TIR не допускаются подчистки и помарки. Все исправления должны вноситься путем вычеркивания ошибочных сведений. Правильные сведения надписываются сверху
 и заверяются владельцем карнета TIR (до его таможенного оформления) или руководством таможни.</p><br>

<p style="text-indent:2em;">5. Гарантийное объединение устанавливает срок действия карнета TIR. Дата истечения срока действия карнета TIR указывается в рубрике 1 первой страницы обложки. По
 истечении этого срока карнет TIR не может быть принят таможней отправления для оформления.</p><br>

<p style="text-indent:2em;">Если карнет TIR был принят таможней отправления для оформления в последний день срока его действия или до этой даты, то карнет TIR действителен до окончания
 перевозки с соблюдением процедуры TIR.</p><br>

<p style="text-indent:2em;">Срок действия карнета TIR может быть продлен только гарантийными объединениями, выдающими карнеты TIR. Сведения о продлении указываются в рубрике 1 карнета
 TIR и заверяются печатью гарантийного объединения. 6. Карнеты TIR могут быть оформлены с оговорками или без них.</p><br>

<p style="text-indent:2em;">Оговорки должны относиться к нарушениям доставки и транзита товаров с соблюдением процедуры TIR. Оговорки указываются в рубрике 27 отрывного листка и в рубрике
 5 корешка карнета TIR. При этом в этих рубриках ставится символ "R" и дается краткое описание нарушения.</p><br>

<p style="text-indent:2em;">7. Если карнет TIR оформлен без оговорок, то таможенные органы не могут требовать от гарантийного объединения уплаты таможенных пошлин и налогов, за
 исключением случаев, когда свидетельство о произведенном таможенном оформлении было получено противозаконным или обманным путем или когда товары не
 были доставлены до таможни назначения или таможни вывоза.</p><br>

<p style="text-indent:2em;">8. Бланки карнетов TIR, ввозимые в Республику Молдова, освобождаются от пошлин и налогов и не подвергаются ограничениям при ввозе и при вывозе.</p>

<p></p><h4>IV. ГАРАНТИЙНОЕ ОБЪЕДИНЕНИЕ</h4><p></p>

<p style="text-indent:2em;">1. В Республике Молдова при транзите и доставке товаров под таможенным контролем с соблюдением процедуры TIR гарантийным объединением является Молдавская
 ассоциация международного автомобильного транспорта AITA.</p><br>

<p style="text-indent:2em;">2. В соответствии с актом о гарантиях Ассоциация AITA берет на себя обязательство уплачивать полагающиеся ввозные или вывозные сборы и налоги, а также 
любые проценты за просрочку согласно таможенному законодательству Республики Молдова при обнаружении нарушений в связи с операцией TIR. Ответственность 
Ассоциации AITA распространяется как на перевозчиков Республики Молдова, так и на зарубежных, перевозящих товары по территории Республики Молдова с 
соблюдением процедуры TIR, независимо от того, какая национальная гарантийная ассоциация выдала карнет TIR.</p><br>

<p style="text-indent:2em;">3. Ассоциация AITA обязуется производить уплату таможенных пошлин и налогов в порядке солидарной ответственности с перевозчиками, с которых причитаются
 эти платежи, по представлении документов, указанных в разделе XV настоящего Положения.</p><br>

<p style="text-indent:2em;">4. По каждому карнету TIR гарантируется уплата таможенных пошлин и налогов в пределах 50 000 (пятьдесят тысяч) долларов США. Указанная сумма уплачивается
 в молдавских леях по курсу Национального банка Молдовы, действующему на день, предшествующий дню платежа.</p><br>

<p style="text-indent:2em;">Ограничение максимальной суммы уплаты по каждому карнету TIR не распространяется на алкогольные напитки и табачные изделия, указанные в разделе XII 
настоящего Положения.</p><br>

<p style="text-indent:2em;">5. Ассоциация AITA несет ответственность перед таможенными органами Республики Молдова за надлежащее завершение таможенного режима транзита или за доставку
 товаров под таможенным надзором с момента их ввоза в Республику Молдова, а при вывозе из Республики Молдова - с момента принятия карнета TIR для оформления в таможне отправления.</p><br>

<p style="text-indent:2em;">6. Ассоциация AITA отвечает не только за товары, перечисленные в карнете TIR, но и за любые товары, которые, не будучи перечисленными в карнете TIR,
 находятся в запломбированном грузовом отделении дорожного транспортного средства или в запломбированном контейнере.</p>

<p></p><h4>V. ДОПУЩЕНИЕ ДОРОЖНЫХ ТРАНСПОРТНЫХ СРЕДСТВ (КОНТЕЙНЕРОВ) К ПЕРЕВОЗКАМ ТОВАРОВ ПОД ТАМОЖЕННЫМИ ПЕЧАТЯМИ И ПЛОМБАМИ</h4><p></p>

<p style="text-indent:2em;">1. Дорожные транспортные средства и контейнеры должны соответствовать техническим требованиям конвенции TIR.</p><br>

<p style="text-indent:2em;">2. Дорожные транспортные средства должны сопро-вождаться свидетельством о допущении, а контейнеры должны иметь табличку о допущении. Дорожные транспортные средства,
 зарегистрированные на территории Республики Молдова, должны быть предварительно осмотрены сотрудником Ассоциации AITA на предмет соответствия требованиям о порядке
 допущения транспортных средств (контейнеров) к перевозкам товаров под таможенными печатями и пломбами с выдачей свидетельства, образец которого утверждается
 Департаментом таможенного контроля. После представления свидетельства таможенным органам и выполнения ими процедуры, утвержденной Департаментом таможенного контроля,
 транспортные средства могут быть допущены к перевозкам товаров под таможенными печатями и пломбами.</p><br>

<p style="text-indent:2em;">3. Свидетельство о допущении представляется в соот-ветствующую таможню Республики Молдова при таможенном оформлении товаров.</p><br>

<p style="text-indent:2em;">4. Свидетельство о допущении в обязательном порядке представляется в таможню отправления Республики Молдова. В других случаях свидетельства о допущении могут не
 представляться, но дорожные транспортные средства (контейнеры) тем не менее должны отвечать техническим требованиям Конвенции TIR и пройти соответствующую процедуру
 разового допущения (Процедура допущения дорожных транспортных средств, отвечающих техническим условиям), приведенную в приложении N 2 к Таможенной конвенции. Запись
 о разовом допущении делается в рубрике "Для служебного пользования" карнета TIR.</p><br>

<p>Свидетельство о допущении не предъявляется в случае перевозки тяжеловесных или громоздких грузов.</p>

<p></p><h4>VI. ДОСТУП К ПРОЦЕДУРЕ TIR</h4><p></p>

<p style="text-indent:2em;"></p><h6>1. Минимальными условиями и требованиями, которые должны соблюдать лица, желающие получить доступ к процедуре TIR, являются:</h6><p></p>

<p>а) наличие лицензии на осуществление международных грузовых перевозок;</p><br>

<p>b) стабильное финансовое положение;</p><br>

<p style="text-indent:2em;">с) свидетельство о получении водителями профес-сиональной подготовки в области перевозок грузов с применением процедуры TIR;</p><br>

<p>d) отсутствие серьезных или неоднократных нарушений таможенных правил или налогового законодательства;</p>

<p style="text-indent:2em;"></p><h6>е) соблюдение положений декларации об обязательствах перед Ассоциацией AITA в том, что данное лицо:</h6><p></p>

<p>будет соблюдать все таможенные формальности согласно конвенции;</p><br>

<p style="text-indent:2em;">по требованию компетентных органов, в соответствии с пунктом 7 статьи 8 конвенции, оплатит суммы, предусмотренные в пунктах 1 и 2 статьи 8 конвенции;</p><br>

<p style="text-indent:2em;">в рамках законодательства Республики Молдова разрешит Ассоциации AITA проверять информацию об указанных выше условиях и требованиях.</p><br>

<p style="text-indent:2em;">2. Департамент таможенного контроля и Ассоциация AITA могут ввести дополнительные и более ограничительные условия и требования для доступа к процедуре TIR.</p><br>

<p style="text-indent:2em;">3. Департамент таможенного контроля устанавливает процедуру доступа к системе TIR на основе минимальных условий и требований, изложенных в пункте 1 данного раздела.</p><br>

<p style="text-indent:2em;">4. Ассоциация AITA ежегодно в течение первой недели года представляет в Департамент таможенного контроля список всех лиц, получивших разрешение, а также лиц, разрешения 
которых были отменены. Копию этого списка Департамент таможенного контроля передает Исполнительному комитету Международного союза автомобильного транспорта. 5. Разрешение
 на доступ к процедуре TIR само по себе не дает права на получение карнета TIR.</p><br>

<p style="text-indent:2em;">6. Наличие у лиц разрешения на использование карнетов TIR в соответствии с изложенными выше минимальными условиями и требованиями не должно снижать их ответственности,
 определенной в конвенции.</p>

<p></p><h4>VII. ПОРЯДОК ТАМОЖЕННОГО ОФОРМЛЕНИЯ ПРИ ВВОЗЕ ТОВАРОВ НА ТЕРРИТОРИЮ РЕСПУБЛИКИ МОЛДОВА</h4><p></p>

<p style="text-indent:2em;">1. На таможне ввоза должны быть представлены следующие документы: карнет TIR, заполненный и оформленный таможенными органами государства отправления и государств транзита;</p><br>

<p>свидетельство о допущении (с учетом пункта 4 раздела V настоящего Положения);</p><br>

<p>грузовые и товаросопроводительные документы.</p>

<p style="text-indent:2em;"></p><h6>2. Группы документального контроля таможен ввоза проверяют соответствие заполнения карнета TIR установленным правилам. При оформлении карнета TIR особое внимание обращается на:</h6><p></p>

<p style="text-indent:2em;">заполнение грузового манифеста (сведения, внесенные в грузовой манифест, должны соответствовать сведениям, указанным в грузовых и товаросопроводительных документах);</p><br>

<p style="text-indent:2em;">наличие на корешках карнета TIR таможенных штампов таможни отправления и промежуточных таможен. Служащие таможни должны удостовериться в том, что карнет TIR не
 числится в списке украденных и что перевозка товаров осуществляется перевозчиком, указанным в рубрике 3 обложки и в рубрике 4 листков карнета TIR (держатель карнета TIR).</p>

<p style="text-indent:2em;"></p><h6>3. Досмотровые группы таможни ввоза:</h6><p></p>

<p>проверяют техническое состояние грузовых отделений транспортных средств или контейнеров;</p><br>

<p>проверяют наличие на контейнерах свидетельства о допущении или табличек о допущении;</p><br>

<p style="text-indent:2em;">проверяют состояние таможенных печатей и пломб на грузовых отделениях транспортных средств или контейнерах;</p><br>

<p>в исключительных случаях проводят досмотр грузовых отделений или контейнеров.</p><br>

<p style="text-indent:2em;">4. В случае обнаружения отклонений от процедуры TIR таможня ввоза может подвергнуть товары и транспортные средства таможенному режиму транзита или процедуре
 доставки в соответствии с таможенным законодательством Республики Молдова.</p><br>

<p style="text-indent:2em;">5. Если в результате документального и фактического контроля не обнаружено каких-либо нарушений процедуры TIR, принимается решение о возможности перемещения
 товаров и транспортных средств по территории Республики Молдова в соответствии с этой процедурой.</p>

<p style="text-indent:2em;"></p><h6>6. После принятия решения о возможности перемещения товаров и транспортных средств по территории Республики Молдова в соответствии с процедурой TIR:</h6><p></p>

<p style="text-indent:2em;">накладываются, в случае необходимости, дополнительные таможенные обеспечения, о чем делается отметка в рубрике "Для служебного пользования" на обоих отрывных
 листках комплекта;</p><br>

<p style="text-indent:2em;">определяется таможенная стоимость товаров. Для этого используются счета-фактуры, транспортные и страховые документы, таможенные декларации стран отправления,
 упаковочные листы или иные товаросопроводительные документы. Таможенная стоимость (в валюте, оговоренной в контракте) указывается в графе "Для официального
 пользования" обоих отрывных листков. Если невозможно установить таможенную стоимость, в этой рубрике делается запись "Стоимость не определена";</p><br>

<p style="text-indent:2em;">по возможности в рубрике 10 обоих отрывных листков дается подробное описание перевозимых товаров, достаточное для их идентификации в случае выдачи,
 утери или недоставления;</p><br>

<p>устанавливается срок доставки. Дата доставки указывается в рубрике 20 обоих отрывных листков карнета TIR;</p><br>

<p style="text-indent:2em;">заполняются рубрики 18 - 23 обоих отрывных листков комплекта и корешок N1 комплекта (корешок N 1, заполненный, с печатью таможни остается в карнете TIR);</p><br>

<p>отделяется листок N1 (белый) комплекта;</p><br>

<p style="text-indent:2em;">карнет TIR регистрируется в специальном журнале по форме, установленной Департаментом таможенного контроля Республики Молдова; оформленный карнет TIR
 возвращается перевозчику;</p><br>

<p style="text-indent:2em;">выезд транспортного средства из зоны таможенного контроля разрешается путем проставления личного номерного штампа должностного лица, ответственного за
 документальный контроль, его подписи и даты в рубрике 23 отрывного листка N1 и рубрике VI корешка N 1.</p><br>

<p>7. Листок N1 комплекта ставится на контроль в таможне ввоза и хранится в отдельной папке.</p><br>

<p style="text-indent:2em;">8. В таможню назначения, после прибытия транспортного средства или контейнера, должны быть представлены следующие документы: карнет TIR с отметками
 таможни ввоза;</p><br>

<p>грузовые и товаросопроводительные документы, необ-ходимые для таможенного оформления товаров.</p><br>

<p style="text-indent:2em;">9. Группы документального контроля проверяют соот-ветствие заполнения карнета TIR установленным правилам и наличие в нем штампов таможни ввоза.</p>

<p style="text-indent:2em;"></p><h6>10. Досмотровые группы таможни назначения:</h6><p></p>

<p>проверяют техническое состояние грузовых отделений транспортных средств или контейнеров;</p><br>

<p style="text-indent:2em;">проверяют состояние таможенных печатей и пломб на грузовых отделениях транспортных средств или на контейнерах;</p><br>

<p style="text-indent:2em;">проводят таможенный досмотр грузовых отделений или контейнеров и контролируют выгрузку товаров из транспортных средств или контейнеров.</p><br>

<p style="text-indent:2em;">11. В случае обнаружения каких-либо нарушений таможенного законодательства Республики Молдова при перевозке товаров с соблюдением процедуры TIR
 таможня назначения должна оформить карнет TIR с оговорками в порядке, предусмотренном в пункте 7 раздела III настоящего Положения.</p>

<p style="text-indent:2em;"></p><h6>12. Если в результате проверки документов и таможенного контроля не обнаружены нарушения таможенного законодательства Республики Молдова, то:</h6><p></p>

<p>заполняются рубрики 24, 25, 26, 27, 28 отрывного листка N2 комплекта и корешок N 2 комплекта;</p><br>

<p style="text-indent:2em;">проставляются дата, личные номерные штампы и подписи должностных лиц таможенного органа в рубрике 28 отрывного листка N 2 и в рубрике 6 корешка
 карнета TIR (корешок остается в карнете);</p><br>

<p>отделяется отрывной листок N 2 комплекта;</p><br>

<p style="text-indent:2em;">карнет TIR регистрируется в специальном журнале по форме, установленной Департаментом таможенного контроля;</p><br>

<p>оформленный карнет TIR возвращается перевозчику.</p>

<p></p><h4>VIII. ПОРЯДОК ТАМОЖЕННОГО ОФОРМЛЕНИЯ ПРИ ВЫВОЗЕ ТОВАРОВ С ТЕРРИТОРИИ РЕСПУБЛИКИ МОЛДОВА</h4><p></p>

<p style="text-indent:2em;">1. Таможня отправления производит таможенное оформление вывозимых товаров в соответствии с таможенным законодательством Республики Молдова.</p>

<p style="text-indent:2em;"></p><h6>2. Для таможенного оформления перевозки товаров с соблюдением процедуры TIR в таможню отправления должны быть представлены следующие документы:</h6><p></p>

<p>заполненный карнет TIR;</p><br>

<p style="text-indent:2em;">свидетельство о допущении транспортного средства к перевозкам товаров под таможенными печатями и пломбами (с учетом пункта 4 раздела V настоящего
 Положения);</p><br>

<p>грузовые и товаросопроводительные документы.</p>

<p style="text-indent:2em;"></p><h6>3. Группы документального контроля проверяют соответствие заполнения карнета TIR установленным правилам. При проверке карнета TIR 
особое внимание обращается на:</h6><p></p>

<p>наличие печатей и подписей на первой странице обложки карнета TIR;</p><br>

<p style="text-indent:2em;">соответствие подписей владельца карнета TIR на первой странице обложки, неотрывном листе и на всех отрывных листах;</p><br>

<p>срок действия карнета TIR.</p><br>

<p style="text-indent:2em;">Служащие таможни должны также удостовериться в том, что карнет TIR не фигурирует в списке украденных карнетов и что перевозка товаров
 осуществляется лицом, указанным в рубрике 3 обложки и в рубрике 4 неотрывных листков карнета TIR.</p>

<p style="text-indent:2em;"></p><h6>4. Группы таможенного контроля таможни отправления:</h6><p></p>

<p>проверяют техническое состояние грузовых отделений транспортных средств или контейнеров;</p><br>

<p style="text-indent:2em;">проверяют свидетельства о допущении или наличие на контейнерах табличек о допущении (с учетом пункта 4 раздела V настоящего Положения);</p><br>

<p>контролируют загрузку товаров в транспортные средства или контейнер;</p><br>

<p>проставляют таможенные печати и пломбы на грузовые отделения транспортных средств или контейнеры.</p><br>

<p style="text-indent:2em;">5. В случае обнаружения каких-либо нарушений при оформлении таможня отправления, в соответствии с процедурой TIR, может не разрешить
 перевозку товаров до устранения этих нарушений.</p>

<p style="text-indent:2em;"></p><h6>6. Если в результате документального контроля и таможенного досмотра установлено, что все условия применения процедуры TIR соблюдены, то:</h6><p></p>

<p>заполняется графа 16 на всех отрывных листках карнета TIR;</p><br>

<p style="text-indent:2em;">в рубрике "Для служебного пользования" отрывного листка N1 и в рубрике 5 корешка N1 в числе прочих сведений указывается код таможенного режима,
 в соответствии с которым товар вывозится из Республики Молдова и, если осуществляется контроль за фактическим вывозом товаров, указывается слово "Контроль";</p><br>

<p>заполняется корешок N1 отрывного листа комплекта;</p><br>

<p>заполняются рубрики 18 - 23 обоих отрывных листков комплекта;</p><br>

<p style="text-indent:2em;">проставляется дата, личный номерной штамп и подпись должностного лица таможенного органа в рубрике 23 отрывного листа N1 и в рубрике 6 корешка N1.</p><br>

<p style="text-indent:2em;">Лист N1 комплекта отделяется от карнета TIR и в случаях, предусмотренных в пункте 8 раздела VIII настоящего Положения, ставится на контроль.</p><br>

<p style="text-indent:2em;">Оформленный карнет TIR регистрируется в специальном журнале по форме, установленной Департаментом таможенного контроля.</p><br>

<p>7. Оформленный карнет TIR возвращается перевозчику.</p>

<p style="text-indent:2em;"></p><h6>8. Надзор за фактическим вывозом товаров с территории Республики Молдова осуществляется таможней отправления при помещении товаров под таможенный
 режим реэкспорта, в том числе после нахождения под таможенными режимами:</h6><p></p>

<p>временного ввоза (код 31);</p><br>

<p>таможенного склада (код 74) ;</p><br>

<p>переработки на таможенной территории (код 51);</p><br>

<p>свободного склада (код 73);</p><br>

<p>свободной таможенной зоны (код 71);</p><br>

<p>магазина беспошлинной торговли (код 72);</p><br>

<p>переработки под таможенным контролем (код 52).</p><br>

<p style="text-indent:2em;">В этом случае таможня отправления устанавливает срок доставки товаров и указывает его в рубрике 20 листков N1 и 2 комплекта. В указанных листках в графе
 "Для служебного пользования" указывается таможенная стоимость перевозимых товаров, а в рубрике 10 - их подробное наименование с указанием кодов по Товарной
 номенклатуре внешнеэкономической деятельности (ТН ВЭД). Если в графе 10 не хватает места для внесения всех сведений, допускается использование дополнительных
 листов (в двух экземплярах). В дополнительных листах указывается также номер карнета TIR, наименование и адрес его держателя. Дополнительные листы заверяются 
 личным номерным штампом должностного лица таможенного органа. Отметка о наличии таких листов делается в рубрике 8 обоих отрывных листков комплекта. Листок N1 
 комплекта ставится на контроль и хранится в отдельной папке.</p><br>

<p>9. В таможню вывоза должны быть представлены следующие документы: карнет TIR;</p><br>

<p>грузовые и товаросопроводительные документы с отметками таможни отправления.</p><br>

<p style="text-indent:2em;">10. Группы документального контроля проверяют соответствие заполнения карнета TIR установленным правилам, наличие штампов таможни отправления на корешке
 N1 комплекта, а также код таможенного режима, указанный на корешке.</p>

<p style="text-indent:2em;"></p><h6>11. Досмотровые группы:</h6><p></p>

<p>проверяют состояние грузовых отделений транспортных средств или контейнеров;</p><br>

<p style="text-indent:2em;">проверяют состояние таможенных печатей и пломб на грузовых отделениях транспортных средств или на контейнерах; в исключительных случаях проводят досмотр
 грузовых отделений транспортных средств или контейнеров.</p><br>

<p style="text-indent:2em;">12. В случае обнаружения каких-либо нарушений таможенного законодательства Республики Молдова при перевозке товаров с соблюдением процедуры TIR таможня вывоза
 оформляет карнет TIR с оговорками в порядке, предусмотренном в пункте 7 раздела III настоящего Положения.</p>

<p style="text-indent:2em;"></p><h6>13. Если в результате документального и таможенного контроля не обнаружены факты нарушений таможенного законодательства Республики Молдова, то:</h6><p></p>

<p>заполняются рубрики 24, 25, 26, 27 и 28 отрывного листка N 2 и отрывной корешок N 2;</p><br>

<p>отделяется отрывной листок N 2 комплекта;</p><br>

<p style="text-indent:2em;">карнет TIR регистрируется в специальном журнале по форме, установленной Департаментом таможенного контроля;</p><br>

<p>оформленный карнет TIR возвращается перевозчику;</p><br>

<p style="text-indent:2em;">дается разрешение на выезд из зоны таможенного контроля путем проставления в рубрике 28 отрывного листка N 2 и в рубрике 6 корешка N 2 личного номерного
 штампа, подписи и даты.</p>

<p></p><h4>IX. ПОРЯДОК ТАМОЖЕННОГО ОФОРМЛЕНИЯ ТРАНЗИТА ТОВАРОВ ЧЕРЕЗ ТЕРРИТОРИЮ РЕСПУБЛИКИ МОЛДОВА</h4><p></p>

<p style="text-indent:2em;">1. Таможенное оформление транзитных товаров в таможне ввоза производится в порядке, предусмотренном в пунктах 1 - 7 раздела VII настоящего Положения.</p><br>

<p style="text-indent:2em;">2. Таможенное оформление транзитных товаров в таможне вывоза производится в порядке, предусмотренном в пунктах 8 - 12 раздела VII настоящего Положения.</p>

<p></p><h4>X. ОСОБЕННОСТИ ТАМОЖЕННОГО ОФОРМЛЕНИЯ В СЛУЧАЕ ПЕРЕВОЗКИ ТОВАРОВ ЧЕРЕЗ НЕСКОЛЬКО ТАМОЖЕН ОТПРАВЛЕНИЯ ИЛИ НАЗНАЧЕНИЯ</h4><p></p>

<p style="text-indent:2em;">1. Перевозка товаров согласно процедуре TIR может осуществляться через несколько таможен отправления при вывозе товаров из Республики Молдова или несколько
 таможен назначения при ввозе товаров в Республику Молдова.</p><br>

<p>2. Для каждой таможни отправления или назначения требуется дополнительный комплект отрывных листов.</p>

<p style="text-indent:2em;"></p><h6>3. В таможне отправления или таможне назначения производится соответственно:</h6><p></p>

<p>при вывозе из Республики Молдова - частичная догрузка товаров;</p><br>

<p>при ввозе в Республику Молдова - частичная выгрузка товаров.</p><br>

<p style="text-indent:2em;">4. Таможня, в которой производится частичная догрузка товаров, производит оформление перевозки товаров в порядке, предусмотренном в пунктах 9 - 13 раздела VIII
 настоящего Положения, с учетом особенностей, указанных в настоящем пункте.</p>

<p style="text-indent:2em;"></p><h6>Должностные лица указанной таможни:</h6><p></p>

<p>снимают печати и пломбы первой таможни отправления;</p><br>

<p>указывают наименование и количество догруженных товаров на всех отрывных листках карнета TIR;</p><br>

<p>вычеркивают запись о таможенных печатях и пломбах в рубрике 16 всех отрывных листков карнета TIR;</p><br>

<p style="text-indent:2em;">делают запись о вновь наложенных таможенных печатях или пломбах в рубрике 16 всех заполненных отрывных листков и в корешке N1 комплекта.</p><br>

<p style="text-indent:2em;">5. Таможня, в которой производится частичная выгрузка товаров, оформляет товары в порядке, предусмотренном в пунктах 8-12 раздела VII настоящего Положения,
 учитывая особенности, изложенные в настоящем пункте.</p>

<p style="text-indent:2em;"></p><h6>Должностные лица указанной таможни:</h6><p></p>

<p>снимают таможенные обеспечения предыдущих таможен;</p><br>

<p style="text-indent:2em;">вычеркивают на всех заполненных отрывных листках карнета TIR наименование и количество выгруженных товаров и делают запись о выгруженных товарах;</p><br>

<p>вычеркивают запись о таможенных обеспечениях в рубрике 16 карнета TIR;</p><br>

<p style="text-indent:2em;">в рубрике 16 всех заполненных отрывных листков и в корешке N1 комплекта делают запись о вновь наложенных таможенных обеспечениях.</p>

<p></p><h4>XI. ОСОБЕННОСТИ ТАМОЖЕННОГО ОФОРМЛЕНИЯ ТЯЖЕЛОВЕСНЫХ ИЛИ ГРОМОЗДКИХ ТОВАРОВ</h4><p></p>

<p style="text-indent:2em;">1. Товары, которые из-за их характера и габаритов невозможно перевозить в запломбированных грузовых отделениях транспортных средств или в контейнерах, 
можно перевозить на открытых транспортных средствах.</p><br>

<p style="text-indent:2em;">2. При перевозке тяжеловесных или громоздких товаров на обложке и на всех листках карнета TIR делается надпись на английском языке "HEAVY OR BULKY GOODS"
 (ТЯЖЕЛОВЕСНЫЕ ИЛИ ГРОМОЗДКИЕ ТОВАРЫ).</p><br>

<p style="text-indent:2em;">3. Таможня отправления может потребовать, чтобы к карнету TIR были приложены фотографии, упаковочные листы, чертежи и другие документы, необходимые для
 идентификации перевозимых товаров.</p><br>

<p style="text-indent:2em;">Такие документы заверяются таможней отправления и прикрепляются к каждому листку карнета TIR. При этом в рубрике 8 всех отрывных листков делается отметка
 о наличии таких документов.</p>

<p style="text-indent:2em;"></p><h6>4. При перемещении таких товаров по территории Республики Молдова таможенные органы должны:</h6><p></p>

<p>следить за соблюдением требований, изложенных в пунктах 2 и 3 настоящего раздела;</p><br>

<p style="text-indent:2em;">при ввозе товаров на территорию Республики Молдова, в случае необходимости, применять дополнительные средства таможенной идентификации;</p><br>

<p style="text-indent:2em;">при ввозе или вывозе производить осмотр товаров с целью выявления случаев перевозки товаров, не заявленных в грузовом манифесте карнета TIR.</p>

<p></p><h4>XII. ОСОБЕННОСТИ ТАМОЖЕННОГО ОФОРМЛЕНИЯ НЕКОТОРЫХ ПОДАКЦИЗНЫХ ТОВАРОВ</h4><p></p>

<p style="text-indent:2em;">1. Товары, указанные в приложении N2 к настоящему Положению, оформляются для перевозки с соблюдением процедуры TIR при предъявлении карнета TIR с повышенным
 пределом гарантии. На обложке и на отрывных листах таких карнетов должна быть проставлена четкая надпись "ТАБАЧНЫЕ ИЗДЕЛИЯ/АЛКОГОЛЬНЫЕ НАПИТКИ". Кроме этого к
 таким карнетам должен быть приложен дополнительный лист, в котором детально указываются наименования перевозимых товаров и их коды по ТН ВЭД.</p><br>

<p style="text-indent:2em;">Дополнительный лист прикрепляется к карнету TIR и должен быть заверен штампом должностного лица таможни отправления. Подобная гарантия по карнетам TIR составляет
 200000 (двести тысяч) долларов США.</p><br>

<p style="text-indent:2em;">2. В соответствии с процедурой TIR не допускается оформление таможенных документов на перевозку товаров, указанных в приложении N 2 к настоящему Положению,
 в случае представления карнетов TIR с пределом гарантии до 50000 (пятьдесят тысяч) долларов США, а также карнетов TIR "ТАБАЧНЫЕ ИЗДЕЛИЯ".</p>

<p></p><h4>XIII. КОНТРОЛЬ ЗА ДОСТАВКОЙ ТОВАРОВ</h4><p></p>

<p style="text-indent:2em;">1. Контроль за доставкой товаров, перевозимых с соблюдением процедуры TIR между таможней ввоза и таможней назначения, таможней ввоза и таможней вывоза при транзите,
 таможней отправления и таможней вывоза после завершения определенных таможенных режимов, осуществляется в порядке, предусмотренном нормативными актами Департамента
 таможенного контроля, относящимися соответственно к использованию таможенного режима транзита и доставки товаров под таможенным контролем с учетом особенностей,
 изложенных в настоящем разделе.</p><br>

<p style="text-indent:2em;">2. Учет перевозок товаров с соблюдением процедуры TIR производится в журналах по формам, установленным Департаментом таможенного контроля.</p><br>

<p style="text-indent:2em;">3. Таможня ввоза или таможня отправления направляет по почте в таможню вывоза или в таможню назначения копию контрольного отрывного листа карнета TIR,
 который остается в таможне ввоза или в таможне отправления для контроля за доставкой товаров.</p><br>

<p style="text-indent:2em;">4. Таможня вывоза или таможня назначения в течение суток направляет по оперативным каналам связи в таможню ввоза или в таможню отправления информацию о поступлении
 товаров с указанием их наименования и количества, номера карнета TIR, наименования перевозчика, даты прибытия товаров. В случае непоступления в течение 10 суток
 оперативной информации о доставке товаров таможня ввоза или таможня отправления направляет в таможню вывоза или в таможню назначения копии всех имеющихся у нее
 документов по данной перевозке.</p><br>

<p style="text-indent:2em;">5. После завершения перевозки с соблюдением процедуры TIR карнет TIR с соответствующими печатями таможни назначения или таможни вывоза возвращается перевозчику и
 служит подтверждением завершения процедуры TIR.</p><br>

<p style="text-indent:2em;">Если таможенное оформление произведено с оговорками (то есть имело место нарушение таможенных правил), в корешках и отрывных листах карнета TIR делаются оговорки
 в соответствии с пунктом 7 раздела III настоящего Положения.</p>

<p></p><h4>XIV. ДОРОЖНО-ТРАНСПОРТНЫЕ ПРОИСШЕСТВИЯ</h4><p></p>

<p style="text-indent:2em;">1. Дорожно-транспортными происшествиями (ДТП) считаются обстоятельства, повлекшие повреждения грузовых отделений транспортных средств или срыв таможенных печатей
 и пломб.</p>

<p style="text-indent:2em;"></p><h6>2. При обращении перевозчика по поводу повреждения грузового отделения транспортного средства или таможенных печатей и пломб таможня, в зоне деятельности которой
 произошло ДТП, обязана:</h6><p></p>

<p>получить письменное объяснение перевозчика об обстоятельствах ДТП;</p><br>

<p>принять решение о возможности дальнейшей перевозки товаров с соблюдением процедуры TIR;</p><br>

<p style="text-indent:2em;">заполнить протокол о констатации дорожно-транспортного происшествия или удостоверить, заполнен ли протокол органами дорожной полиции. Образец заполнения протокола
 констатации ДТП устанавливается Департаментом таможенного контроля.</p><br>

<p style="text-indent:2em;">3. Информацию о ДТП во время перевозки товаров с соблюдением процедуры TIR необходимо в течение суток по любым оперативным каналам связи передать в таможню ввоза
 или в таможню отправления.</p><br>

<p style="text-indent:2em;">4. Все операции с товарами после ДТП должны производиться под таможенным контролем. В случае, если доказано, что повреждение грузового отделения транспортного
 средства или средств идентификации произошло не в результате дорожно-транспортного происшествия, составляется протокол о нарушении таможенных правил и делается 
 оговорка в соответствующих рубриках отрывных листков и корешков карнета TIR.</p><br>

<p style="text-indent:2em;">5. После ДТП товары могут быть перегружены на другое транспортное средство или контейнер и направлены по назначению с соблюдением процедуры TIR. Если перевозка 
с соблюдением процедуры TIR невозможна, товары могут быть направлены в таможню назначения в соответствии с процедурой доставки товаров под таможенным контролем.</p>

<p></p><h4>XV. ОТВЕТСТВЕННОСТЬ ПЕРЕВОЗЧИКА И ГАРАНТИЙНОГО ОБЪЕДИНЕНИЯ</h4><p></p>

<p style="text-indent:2em;">1. Перевозчик товаров, производящий операции согласно процедуре TIR, в случае нарушения положений Таможенного кодекса несет ответственность в соответствии с Кодексом.</p><br>

<p style="text-indent:2em;">2. В случае выдачи товаров без разрешения таможенного органа, их утере или недоставлении в таможню назначения, таможенный орган, в зоне деятельности которого
 обнаружено такое правонарушение, заводит в установленном порядке дело о нарушении таможенных правил и после его рассмотрения выносит постановление о наложении взыскания.</p>

<p style="text-indent:2em;"></p><h6>3. Дела о нарушении таможенных правил, касающихся выдачи товаров без разрешения таможенных органов, утери или недоставления товаров, заводят, как правило
, следующие таможни:</h6><p></p>

<p>таможня назначения - при ввозе товаров в Республику Молдова;</p><br>

<p>таможня вывоза - при транзите товаров через территорию Республики Молдова;</p><br>

<p>таможня отправления - при вывозе товаров из Республики Молдова.</p><br>

<p style="text-indent:2em;">Дела о нарушении таможенных правил заводятся после проверки документов, поступивших из таможен, от которых начинается перевозка товаров по территории Республики Молдова
 под таможенным контролем и подтверждения факта недоставления товаров в таможню назначения или таможню вывоза.</p><br>

<p style="text-indent:2em;">4. В протоколе о нарушении таможенных правил и в решении о применении соответствующих санкций указывается, что перевозка осуществлялась в рамках процедуры TIR, а также
 приводится номер карнета TIR.</p><br>

<p style="text-indent:2em;">5. Таможенные пошлины и сборы, а также другие налоги, подлежащие уплате перевозчиком за выдачу без разрешения, утерю или недоставление товаров, взыскиваются в соответствии
 с законодательством Республики Молдова.</p><br>

<p style="text-indent:2em;">6. В каждом случае выдачи без разрешения, утери или недоставления товаров, влекущем за собой бесспорное взыскание таможенных пошлин и других налогов, кроме указанных в
 пункте 3 данного раздела, в соответствии с законодательством Республики Молдова составляется акт по форме, установленной Департаментом таможенного контроля.</p><br>

<p style="text-indent:2em;">7. Сумма таможенных пошлин и сборов, а также других налогов, подлежащих бесспорному взысканию, рассчитывается исходя из таможенной стоимости в соответствии с действующим
 Законом о бюджете и Постановлением Правительства Республики Молдова N 99 от 27 февраля 1996 г. "Об утверждении Положения о порядке определения таможенной стоимости товаров и 
 ценностей, ввозимых на территорию Республики Молдова".</p><br>

<p style="text-indent:2em;">8. После взыскания таможенных пошлин и сборов, а также других налогов в протоколе о бесспорном взыскании указывается взысканная сумма, дата ее поступления на счет
 таможенного органа и номер банковского документа.</p><br>

<p style="text-indent:2em;">9. Если нет возможности взыскать с перевозчика таможенные пошлины и сборы, а также другие налоги в бесспорном порядке в течение месяца после предъявления требования об уплате,
 об этом делается отметка в протоколе о бесспорном взыскании. Здесь же называются причины, которые делают невозможным взыскать с перевозчика таможенные пошлины и налоги.</p><br>

<p style="text-indent:2em;">Этот протокол вместе с копиями протокола о нарушении таможенных правил, решения о взыскании и отрывных листов карнета TIR направляются в Департамент таможенного контроля.
 К протоколу по мере возможности должно прилагаться подтверждение банка или другого кредитного учреждения о невозможности уплаты требуемых сумм.</p><br>

<p style="text-indent:2em;">Если перевозчик уплачивает таможенные пошлины, сборы и налогы частично, все перечисленные выше документы также направляются в Департамент таможенного контроля. В протоколе
 о бесспорном взыскании указывается сумма, и к нему прилагается копия платежного поручения.</p><br>

<p style="text-indent:2em;">10. Департамент таможенного контроля на основании полученных материалов готовит требования об уплате таможенных пошлин и налогов и не позднее чем через 5 дней после получения
 материалов представляет это требование об уплате вместе с копией отрывного листа карнета TIR и другими необходимыми документами в Ассоциацию AITA.</p><br>

<p style="text-indent:2em;">11. Департамент таможенного контроля контролирует соблюдение установленных Конвенцией TIR сроков рассмотрения требований об уплате и поступление сумм таможенных пошлин,
 сборов и налогов на счет Департамента таможенного контроля.</p>

<p></p><h4>Приложение N 1 к Положению о порядке применения Таможенной конвенции о международной перевозке грузов с применением карнета TIR (Конвенция TIR, 1975 г.)</h4><p></p>

<p></p><h4>Заполнение карнета TIR</h4><p></p>

<p></p><h4>1. Заполнение обложки карнета TIR</h4><p></p>

<p></p><h4>1-я - страница.</h4><p></p>

<p>Рубрики 1 - 5 заполняются гарантийным объединением, выдавшим карнет TIR.</p>

<p style="text-indent:2em;"></p><h6>Примечание:</h6><p></p>

<p style="text-indent:2em;">Изменения на 1-й странице обложки могут производиться только гарантийным объединением, выдавшим карнет TIR. Исправления должны быть заверены печатью 
гарантийного объединения.</p><br>

<p>Рубрики 6 - 12 заполняются владельцем карнета TIR.</p>

<p style="text-indent:2em;"></p><h6>Рубрика "NO"</h6><p></p>

<p>Должен быть напечатан номер карнета TIR, присваиваемый при его выдаче.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 1. "Действителен до ..."<p></p><br>

<p>Указывается дата, до которой карнет TIR может быть предъявлен на таможне отправления.</p>

<p style="text-indent:2em;"></p></h6><h6>Примечание:</h6><p></p>

<p style="text-indent:2em;">Карнет действителен до окончания перевозки товаров, если он был предъявлен таможне отправления до даты, указанной в настоящей рубрике. Если карнет TIR
 предъявлен таможне отправления после указанной даты, то он не может быть принят для таможенного оформления.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 2. "Выдан"</h6><p></p>

<p>Указывается наименование объединения, выдавшего карнет TIR.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 3. "Владелец"</h6><p></p>

<p>Указывается наименование и полный почтовый адрес перевозчика, которому выдан карнет TIR.</p>

<p style="text-indent:2em;"></p><h6>Примечание:</h6><p></p>

<p>Перевозку с соблюдением процедуры TIR может осуществлять только перевозчик, указанный в рубрике 3.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 4. "Подпись организации, выдавшей карнет TIR"</h6><p></p>

<p style="text-indent:2em;">Проставляется подпись должностного лица гарантийного объединения. Подпись должна быть заверена печатью гарантийного объединения.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 5. "Подпись секретаря международной ассоциации"</h6><p></p>

<p>Проставляется подпись секретаря Международного союза автомобильного транспорта.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 6. "Страна отправления"</h6><p></p>

<p>Указывается страна, в которой начинается перевозка товаров по процедуре TIR (государство отправления).</p>

<p style="text-indent:2em;"></p><h6>Рубрика 7. "Страна (страны) назначения"</h6><p></p>

<p style="text-indent:2em;">Указывается государство (государства), в котором (которых) для всех товаров или для их части заканчивается перевозка по процедуре TIR (государство
 назначения).</p>

<p style="text-indent:2em;"></p><h6>Примечание (рубрики 6 и 7).</h6><p></p>

<p style="text-indent:2em;">В соответствии со статьей 18 Конвенции TIR перевозка может осуществляться, когда имеется несколько таможен отправления и назначения. Общее количество
 таможен отправления и назначения не может превышать четырех. Карнет TIR может быть предъявлен таможне назначения, если все таможни отправления
 оформили этот карнет.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 8. "Регистрационный номер транспортного средства"</h6><p></p>

<p>Указывается регистрационный номер транспортного средства, на котором перевозятся транзитные товары.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 9. "Свидетельство о допущении транспортного средства"</h6><p></p>

<p>Указывается номер свидетельства о допущении транспортного средства и дата, до которой оно действительно.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 10. "Идентификационный номер контейнера"</h6><p></p>

<p style="text-indent:2em;">Заполняется в случаях, когда транзитные товары перевозятся в контейнере. Указывается идентификационный номер контейнера.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 11. "Примечания"</h6><p></p>

<p style="text-indent:2em;">Указываются все сведения, которые гарантийное объединение сочтет необходимым сообщить при выдаче карнета TIR, или сведения, внесенные
 по требованию таможенного органа.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 12. "Подпись держателя карнета TIR"</h6><p></p>

<p>Проставляется подпись уполномоченного перевозчика, которому выдан карнет TIR.</p>

<p style="text-indent:2em;"></p><h6>Примечание:</h6><p></p>

<p style="text-indent:2em;">На 2-й странице обложки полиграфическим способом должны быть напечатаны "Правила пользования карнетом TIR" на французском языке.</p><br>

<p style="text-indent:2em;">На 3-й странице обложки полиграфическим способом должны быть напечатаны "Правила пользования карнетом TIR" на английском языке.</p><br>

<p style="text-indent:2em;">В правом верхнем углу 4-й страницы обложки расположен отрывной купон с номером карнета TIR, который при изъятии карнета TIR возвращается
 владельцу с отметками таможенного органа о дате его изъятия и с наименованием таможенного органа, который произвел изъятие.</p><br>

<p>Запись должна быть заверена номерной печатью должностного лица таможенного органа.</p><br>

<p style="text-indent:2em;">Изъятие карнета TIR производится таможенными органами в случае истечения срока действия карнета, представления карнета другим держателем,
 неправильного заполнения обложки. Изъятые карнеты направляются в Департамент таможенного контроля для передачи в Ассоциацию AITA.</p>

<p></p><h4>2. Неотрывной лист N 1/ N 2 (желтого цвета)</h4><p></p>

<p style="text-indent:2em;"></p><h6>Заполняется владельцем карнета TIR.</h6><p></p>

<p style="text-indent:2em;"></p><h6>Рубрика 1. "Карнет TIR N ..."</h6><p></p>

<p>Указывается номер карнета TIR, обозначенный на первом листе обложки.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 2. "Таможня (таможни) отправления"</h6><p></p>

<p style="text-indent:2em;">Указывается наименование таможенного органа (таможенных органов), из которого (которых) начинается перевозка товаров.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 3. "Выдан"</h6><p></p>

<p style="text-indent:2em;">Печатается полиграфическим способом наименование международной организации, выдавшей карнет TIR - "UNION INTERNATIONALE DES TRANSPORTS ROUTIERS"
 (Международный союз автомобильного транспорта).</p>

<p style="text-indent:2em;"></p><h6>Рубрика без номера "Для официального пользования"</h6><p></p>

<p style="text-indent:2em;">Указываются все сведения, которые таможня отправления сочтет необходимым сообщить при отправлении товаров.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 4. "Владелец карнета TIR"</h6><p></p>

<p>Повторяются сведения, указанные в рубрике 3 первого листа обложки карнета TIR.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 5. "Страны отправления"</h6><p></p>

<p>Указывается страна, в которой расположена таможня (таможни) отправления.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 6. "Страна назначения"</h6><p></p>

<p>Указывается страна, в которой расположена таможня (таможни) назначения</p>

<p style="text-indent:2em;"></p><h6>Рубрика 7. "Регистрационный номер транспортного средства".</h6><p></p>

<p>Повторяются сведения, указанные в рубрике 8 1-й страницы обложки.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 8. "Прилагаемые документы"</h6><p></p>

<p style="text-indent:2em;">Указываются документы, которые прилагаются к карнету TIR (фотографии, спецификации и т.п.). Копии указанных документов прикрепляются к
 каждому листу карнета TIR.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 9. "Грузовое (грузовые) отделение (отделения) или контейнер (контейнеры), опознавательные знаки и номера грузовых мест или
 предметов"</h6><p></p>

<p style="text-indent:2em;">Указываются номера грузовых отделений или контейнеров, маркировки грузовых мест отдельно по каждому грузовому отделению или контейнеру,
 опознавательные знаки и номера грузовых мест или предметов.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 10. "Количество и вид грузовых мест, описание товаров"</h6><p></p>

<p style="text-indent:2em;">Указывается количество грузовых мест, вид упаковки, краткое описание товаров отдельно по каждому грузовому отделению или контейнеру.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 11. "Вес брутто"</h6><p></p>

<p>Указывается вес брутто (с упаковкой) по каждому грузовому отделению или контейнеру.</p>

<p style="text-indent:2em;"></p><h6>Примечание (рубрики 9 - 11):</h6><p></p>

<p style="text-indent:2em;">Если карнет TIR выдан на состав транспортных средств или на несколько контейнеров, указывается отдельно содержимое каждого транспортного
 средства или контейнера.</p><br>

<p style="text-indent:2em;">Если маршрут перевозки проходит через несколько таможен отправления или назначения, то сведения о товарах также вносятся отдельно
 по каждой таможне.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 12. "Общее количество грузовых мест, заявленных в манифесте".</h6><p></p>

<p style="text-indent:2em;">Указывается общее количество грузовых мест, перевозимых транспортным средством и заявленных в манифесте карнета TIR. Отдельно
 в рубриках 1, 2 и 3 указывается количество грузовых мест по каждой таможне назначения.</p>

<p style="text-indent:2em;"></p><h6>Рубрики 13 - 15 "Место, дата, подпись держателя карнета TIR"</h6><p></p>

<p>Указывается место, дата заполнения карнета TIR и подпись уполномоченного представителя держателя карнета TIR.</p><br>

<p>Другие рубрики в неотрывном листе 1/2 не заполняются.</p>

<p></p><h4>3. Отрывной лист N 1</h4><p></p>

<p style="text-indent:2em;">Рубрики 1 - 15 заполняются владельцем карнета TIR в порядке, предусмотренном для заполнения неотрывного листа. Сведения, указанные
 в этих рубриках, должны соответствовать сведениям, включенным в неотрывной лист.</p><br>

<p style="text-indent:2em;">Владелец карнета TIR заполняет все листы, которые будут использованы при перевозке с соблюдением процедуры TIR.</p><br>

<p style="text-indent:2em;">Рубрики с 16 по 23 и корешок отрывного листа N 1 белого цвета заполняются таможнями отправления и таможнями ввоза государств
 транзита и назначения.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 16. "Наложенные пломбы или опознавательные знаки (количество, описание)"</h6><p></p>

<p style="text-indent:2em;">Указываются сведения о наложенных таможенных обеспечениях по каждому грузовому отделению или контейнеру.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 17. "Таможня отправления"</h6><p></p>

<p style="text-indent:2em;">Указываются дата, подпись, личный штамп должностного лица таможенного органа, дата заполнения отрывного листа карнета TIR.</p>

<p style="text-indent:2em;"></p><h6>Примечание:</h6><p></p>

<p style="text-indent:2em;"></p><h6>Рубрики 16 и 17 заполняются таможней отправления во всех листах карнета TIR.</h6><p></p>

<p style="text-indent:2em;"></p><h6>Рубрика 18. "Свидетельство о принятии груза к таможенному оформлению (таможня места отправления или промежуточная таможня
 при выезде)".</h6><p></p>

<p style="text-indent:2em;">Указывается наименование таможни отправления или таможни ввоза товаров в государство транзита или государство назначения.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 19. "Наложенные печати, пломбы или опознавательные знаки, признанные неповрежденными"</h6><p></p>

<p>Перечеркивается квадрат в этой рубрике.</p><br>

<p>Рубрика не заполняется при оформлении отрывного листа N 1 таможней отправления.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 20. "Продолжительность транзитной перевозки"</h6><p></p>

<p style="text-indent:2em;">Указывается дата доставки товаров до таможни вывоза товаров при транзите или до таможни назначения в государстве
 назначения.</p>

<p style="text-indent:2em;"></p><h6>Графа 21. "Зарегистрировано таможенным органом ... под N... "</h6><p></p>

<p style="text-indent:2em;">Указывается наименование таможни и номер, под которым зарегистрирована перевозка с использованием карнета TIR.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 22. "Разное (место доставки товаров, таможенный орган, которому должны быть предъявлены товары)"</h6><p></p>

<p style="text-indent:2em;">Указывается наименование таможни, через которую товары вывозятся с территории государства или в которую товары должны быть
 доставлены. В случае необходимости указывается маршрут следования.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 23. "Подпись и печать должностного лица таможенного органа"</h6><p></p>

<p>Проставляется дата, подпись и личная печать должностного лица таможенного органа</p>

<p></p><h4>4. Корешок N 1</h4><p></p>

<p style="text-indent:2em;"></p><h6>Заполняется таможней отправления.</h6><p></p>

<p style="text-indent:2em;"></p><h6>Рубрика 1. "Прибытие установлено таможней ..."</h6><p></p>

<p>Указывается наименование таможни отправления.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 2. "За N"</h6><p></p>

<p>Повторяются сведения из рубрики 21.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 3. "Наложенные пломбы или опознавательные знаки"</h6><p></p>

<p>Вносится запись о таможенных обеспечениях.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 4. "Средства идентификации не повреждены"</h6><p></p>

<p>Проставленный в квадрате крестик свидетельствует, что таможенные обеспечения не нарушены.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 5. "Разное (установленный маршрут, таможня, куда должен быть представлен груз, и т.д.)"</h6><p></p>

<p style="text-indent:2em;">Указывается наименование таможни, куда должны быть доставлены товары. В случае необходимости устанавливается
 маршрут следования.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 6. "Подпись должностного лица таможенного органа и его личная печать"</h6><p></p>

<p>Проставляется дата, подпись и личная печать должностного лица таможни отправления.</p>

<p></p><h4>5. Отрывной лист N 2</h4><p></p>

<p style="text-indent:2em;"></p><h6>Рубрики 24 - 28 заполняются таможней вывоза или таможней назначения.</h6><p></p>

<p style="text-indent:2em;"></p><h6>Рубрика 24. "Отметка о предъявлении товаров"</h6><p></p>

<p style="text-indent:2em;">Указывается регистрационный номер, под которым в журнале регистрации зарегистрирована перевозка с использованием
 карнета TIR</p>

<p style="text-indent:2em;"></p><h6>Рубрика 25. " Средства идентификации не нарушены"</h6><p></p>

<p>Проставленный в квадрате крестик свидетельствует, что таможенные обеспечения не нарушены.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 26. "Число оформленных грузовых мест"</h6><p></p>

<p style="text-indent:2em;">Указывается количество грузовых мест, выгруженных под контролем таможенного органа. Если выгрузка не производилась,
 рубрика не заполняется.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 27. "Оговорки при оформлении"</h6><p></p>

<p>Указываются нарушения правил перевозки товаров в соответствии с пунктом 7 раздела III Положения.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 28. "Подпись и печать должностного лица таможенного органа"</h6><p></p>

<p>Проставляется подпись, дата и печать должностного лица таможенного органа.</p>

<p></p><h4>6. Корешок N 2</h4><p></p>

<p style="text-indent:2em;"></p><h6>Рубрика 1. "Прибытие установлено таможней"</h6><p></p>

<p style="text-indent:2em;">Указывается наименование таможни вывоза или таможни назначения и регистрационный номер карнета TIR в журнале
 регистрации.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 2. "Наложенные печати и пломбы или опознавательные знаки признаны неповрежденными"</h6><p></p>

<p>Проставленный в квадрате крестик свидетельствует, что таможенные обеспечения не нарушены.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 3. "Произведено таможенное оформление грузовых мест или предметов"</h6><p></p>

<p style="text-indent:2em;">Указывается количество грузовых мест, выгруженных под контролем таможенного органа. Если выгрузка не производилась,
 рубрика не заполняется.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 4. "Вновь наложенные печати и пломбы"</h6><p></p>

<p style="text-indent:2em;">Вносится запись о таможенных обеспечениях. Если новые таможенные обеспечения не налагались, графа не заполняется.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 5. "Оговорки при оформлении"</h6><p></p>

<p style="text-indent:2em;">Указываются оговорки, сделанные таможенным органом при оформлении карнета TIR (рубрика 27 отрывного листа N 2).</p>

<p style="text-indent:2em;"></p><h6>Примечание:</h6><p></p>

<p style="text-indent:2em;">Таможенные органы в претензии к Ассоциации AITA должны очень ясно излагать суть сделанной оговорки и проставить
 в рубрике знак "R".</p>

<p style="text-indent:2em;"></p><h6>Рубрика 6. "Подпись и печать должностного лица таможенного органа"</h6><p></p>

<p>Проставляется подпись, дата и печать должностного лица таможенного органа.</p>

<p></p><h4>7. Протокол о дорожно-транспортных происшествиях</h4><p></p>

<p>Рубрики 1, 2, 3 и 5 заполняются в том же порядке, что на отрывных листах карнета TIR.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 4. "Регистрационный номер дорожного транспортного средства, идентификационный номер контейнера"</h6><p></p>

<p>Указываются сведения о номерах транспортных средств и контейнеров, попавших в ДТП.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 6. "Таможенные пломбы повреждены/не повреждены"</h6><p></p>

<p>В соответствующем квадрате рисуется крестик.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 7. "Грузовое отделение/контейнер повреждены/не повреждены" В соответствующем квадрате рисуется крестик.</h6><p></p>

<p style="text-indent:2em;"></p><h6>Рубрика 8. "Замечания"</h6><p></p>

<p style="text-indent:2em;">Указываются общие сведения о состоянии товаров и транспортного средства, грузовых отделений или контейнеров.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 9. "Установлена/не установлена пропажа грузов"</h6><p></p>

<p>В соответствующем квадрате рисуется крестик.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 10. "Грузовое отделение или контейнер, маркировка и номера грузовых мест"</h6><p></p>

<p style="text-indent:2em;">Указывается маркировка, а в случае необходимости - и порядковый номер грузового отделения или регистрационный 
номер контейнера, попавших в ДТП.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 11. "Количество и вид грузовых мест, описание товаров"</h6><p></p>

<p>Указываются сведения о товарах, отсутствующих и/или уничтоженных.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 12. "О" или "У"</h6><p></p>

<p style="text-indent:2em;">Против каждого отсутствующего или уничтоженного вида товаров проставляется "О" (отсутствуют) или "У" 
(уничтожены).</p>

<p style="text-indent:2em;"></p><h6>Рубрика 13. "Замечания"</h6><p></p>

<p>Против каждого отсутствующего или уничтоженного вида товаров указывается их количество.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 14. "Дата и место ..."</h6><p></p>

<p>Указывается дата и место ДТП.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 15. "Меры, принятые ..."</h6><p></p>

<p style="text-indent:2em;">Проставленный в соответствующем квадрате крестик указывает, какие меры были приняты для продолжения перевозки
 товаров.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 16.</h6><p></p>

<p>Заполняется, если товары перегружаются на другое транспортное средство.</p><br>

<p>Указываются все необходимые сведения о таких транспортных средствах и новых таможенных обеспечениях.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 17. "Компетентный орган, составивший протокол"</h6><p></p>

<p>Указывается звание государственного органа (кроме таможни), составившего протокол о ДТП.</p>

<p style="text-indent:2em;"></p><h6>Рубрика 18. "Отметка следующей таможни"</h6><p></p>

<p style="text-indent:2em;">Предназначена для удостоверения факта дорожно- транспортного происшествия таможней, в зоне деятельности которой
 оно произошло. </p>
 
<p style="text-indent:2em;"></p><h6>Примечание:</h6><p></p>

<p>Если протокол составляется непосредственно таможенным органом, заполнение рубрики 17 не обязательно.</p><br>

<p style="text-indent:2em;">Без удостоверения таможней факта дорожно-транспортного происшествия (рубрика 18) протокол считается недействительным.</p>

<p></p><h4>Протокол остается в карнете TIR</h4><p></p>

<p style="text-indent:2em;">Приложение N 2 к Положению о порядке применения Таможенной конвенции о международной перевозке грузов с применением
 карнета TIR (Конвенция TIR, 1975 г.)</p>

<p></p><h4>Перечень товаров, для которых требуется повышенный предел гарантий</h4><p></p>

<p style="text-indent:2em;"></p><h6> Наименование товаров по ТН ВЭД | Код товаров по ТН ВЭД </h6><p></p>

<p>Спирт этиловый  неденатурированный  с  содержанием
спирта по объему не менее 80%.    -   220710000 </p><br>

<p>Спирт этиловый  неденатурированный, содержащий  по 
объему менее  80% чистого спирта; - 2208 </p><br>

<p>Крепкие спиртные  напитки,  ликеры  и прочие алко-
гольные напитки; составные спиртовые  полуфабрика-
ты, используемые для изготовления напитков. - 240210000 </p><br>             

<p>Сигары (включая  сигары  с обрезанными концами)  и 
сигариллы  (тонкие сигары), содержащие табак. - 240220000 </p><br>       

<p>Сигареты, содержащие табак. - 240220000 </p><br>

<p>Курительный табак,  содержащий или  не  содержащий 
заменители табака в любой пропорции. - 240310000 </p><br>

<p><a href="https://www.legis.md/cautare/getResults?doc_id=105697&amp;lang=ru">Источник</a></p>


</div>
`