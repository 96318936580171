import {
  UI_ACTIVE_POPUP_GUID,
  UI_CHANGE_LANGUAGE_START,
  UI_CHANGE_LANGUAGE_END,
  UI_TRANSLATION_LOADING,
  UI_TRANSLATION_LOADED,
  UI_SET_AVAILABLE_LANGUAGES,
  UI_CHANGE_LANGUAGE_FETCHING
} from './action-types'
import fetch from '../../components/shared/fetch'

export const setActivePopup = guid => {
  return {
    type: UI_ACTIVE_POPUP_GUID,
    guid,
  }
}

export const setAvailableLanguages = items => {
  return {
    type: UI_SET_AVAILABLE_LANGUAGES,
    payload: items,
  }
}

const setDelayToChangeLanguage = (dispatch) => {
  setTimeout(() => {
    dispatch({
      type: UI_CHANGE_LANGUAGE_FETCHING,
    })
  }, 1000);
}

export const changeLanguage = ({ name: lang, value: langId }) => {
  return (dispatch, getState) => {
    const state = getState()
    lang = lang.toLowerCase()

    dispatch({
      type: UI_CHANGE_LANGUAGE_FETCHING,
    })

    if (state.ui.translation[lang]) {
      setDelayToChangeLanguage(dispatch)
      return Promise.resolve(
        dispatch({
          type: UI_CHANGE_LANGUAGE_END,
          payload: { lang, langId },
        })
      )
    } else {
      setDelayToChangeLanguage(dispatch)
      dispatch({
        type: UI_TRANSLATION_LOADING,
        payload: { lang },
      })

      dispatch({
        type: UI_CHANGE_LANGUAGE_START,
        payload: { lang, langId },
      })

      return fetch(`/common/translation/${lang}`)
        .then(response => {
          const { d, language } = response
          langId = langId || language.value

          dispatch({
            type: UI_TRANSLATION_LOADED,
            payload: { lang },
            translation: d,
          })

          dispatch({
            type: UI_CHANGE_LANGUAGE_END,
            payload: { lang, langId },
          })

        })
        .catch(err => {
          return err
        })
    }
  }
}

export const loadLanguage = lang => {
  if (typeof lang === 'string') {
    lang = { name: lang }
  }
  return changeLanguage(lang)
}

export const loadTranslation = lang => {
  return (dispatch, getState) => {
    const state = getState()

    lang = lang.toLowerCase()

    if (state.ui.translation[lang]) {

      return Promise.resolve(
        dispatch({
          type: UI_TRANSLATION_LOADED,
          payload: { lang },
        })
      )
    } else {
      dispatch({
        type: UI_TRANSLATION_LOADING,
        payload: { lang },
      })

      return fetch(`/common/translation/${lang}`)
        .then(response => {
          const { d } = response
          dispatch({
            type: UI_TRANSLATION_LOADED,
            payload: { lang },
            translation: d,
          })

          return response
        })
        .catch(err => {
          return err
        })
    }
  }
}

export default {
  setActivePopup,
  changeLanguage,
  loadTranslation,
  loadLanguage,
}
