import React, { Component } from 'react'
import { RouteComponentProps, Link, Redirect } from 'react-router-dom'
import { Field } from 'redux-form'
import classNames from 'classnames'
import ProfileMini from '../../../user/profile-mini'
import WidgetHeader from '../../../shared/components/widget-header'
import Dropdown from '../../../UI/dropdown'
import SelectField from '../../../UI/select'
import dict from '../../../shared/dictionaries'
import { required, email } from '../../../shared/form-validators'
import RenderPhone from '../../../UI/util/render-phone'
import fetch from '../../../shared/fetch'
import { directorId, inputMaxLengthRulesEditEmployee } from '../../../shared/utils'
import { confirmAlert } from 'react-confirm-alert'
import AlertBannerProps from '../../../UI/alert-banner'
import { canDo } from '../../../../store/utils'
import Captcha from '../../../captcha'

import './styles.scss'

const STYLE_BASE: string = 'employee-edit'

interface MatchProps {
  userId: string
}

interface IProps extends RouteComponentProps<MatchProps>, ComponentBaseProps {
  handleSubmit: any
  profile: any
  change: any
  userId: string
  loggedUserId: number
  selectedPositionInCompany: number
  selectedEmail: any
  selectedPhone1: any
  selectedPhone2: any
  selectedSkype: any
  selectedViber: any
  permissions?: any
}

export default class extends Component<IProps> {
  state = {
    phone1: null,
    phone2: null,
    redirect: false,
    currentDirector: {
      userId: 0,
      userName: "",
      userLastName: "",
      email: "",
      phone1: "",
      phone2: "",
      skype: "",
      viber: "",
    },
    captchaVerified: false
  }

  componentDidMount() {
    const {
      loggedUserId,
      profile: {
        companyId,
        isCompanyAdmin
      },
      change,
      match: {
        params: {
          userId
        }
      },
      permissions
    } = this.props

    if (canDo(permissions, 'captcha', 'skip')) {
      this.setState({ captchaVerified: true })
    }

    if (isCompanyAdmin > 0) {
      fetch(`/company/employees/${companyId}/2/0/100`).then(
        res => {
          if (res.ok) {

            const currentEmployee = res.employees.find(employeItem => employeItem.userId === userId)
            const currentDirector = res.employees.find(employeItem => employeItem.positionInCompany === directorId)

            this.setState({
              allEmployees: res.employees,
              currentDirector
            })

            change('employee.positionInCompany', currentEmployee?.positionInCompany || 0)
            change('phone1', currentEmployee?.phone1)
            change('phone2', currentEmployee?.phone2)
            change('skype', currentEmployee?.skype)
            change('viber', currentEmployee?.viber)
            change('email', currentEmployee?.email)
          }
        }
      )
    } else {
      this.setState({ redirect: true })
    }
  }


  componentDidUpdate(prevProps) {
    const {
      profile: { isCompanyAdmin },
    } = this.props

    if (!(isCompanyAdmin !== prevProps.isCompanyAdmin && isCompanyAdmin > 0)) {
      this.setState({ redirect: true })
    }
  }


  onErrorChange = (fieldName, errStatus) => {
    this.setState({ [fieldName]: errStatus })
  }

  deleteEmployee = (userId) => {
    const { t, profile: { companyId } } = this.props
    confirmAlert({
      title: t("employee.edit.delete.employee.confirm"),
      message: (
        alert
      ) as any,
      buttons: [
        {
          label: "OK",
          onClick: () => {

            fetch(`/company/${companyId}/employe/${userId}`, { method: 'DELETE' })
              .then(response => {
                if (response.ok) {
                  this.setState({ redirect: true })
                }
              })
          },
        },
        {
          label: t('button.close'),
          onClick: () => this.setState({ redirect: false }),
        },
      ],
      onClickOutside: () => this.setState({ redirect: false }),
      onKeypressEscape: () => this.setState({ redirect: false }),
    })
  }


  isShowBanner = () => {
    const { match: { params: { userId } }, selectedPositionInCompany } = this.props
    const { currentDirector } = this.state

    const selectedUserIdIsDirector = Number(userId) !== Number(currentDirector?.userId)
    const selectedPositionInCompanyIsDirector = selectedPositionInCompany === directorId
    const showBanner = selectedUserIdIsDirector && selectedPositionInCompanyIsDirector

    return showBanner
  }

  renderAtentionAlert = () => {
    const { t } = this.props
    const { currentDirector } = this.state
    const showBanner = this.isShowBanner()
    const employeeName = `${currentDirector?.userName} ${currentDirector?.userLastName}`

    return showBanner && <AlertBannerProps text={t('already.is.company.director', { employeeName })} />
  }

  resetPrevDirectorPosition = () => {
    const { profile: { companyId }, history } = this.props
    const { currentDirector } = this.state
    const body = JSON.stringify({
      email: currentDirector.email,
      positionInCompany: 462, //by default set prev director position = Manager / Менеджер
      phone1: currentDirector.phone1,
      phone2: currentDirector.phone2,
      skype: currentDirector.skype,
      viber: currentDirector.viber,
      employeId: currentDirector.userId,
      companyId
    })

    fetch(`/company/${companyId}/employe-contacts`, {
      method: 'PUT',
      body,
    }).then(_ => {
      history.push(`/company/employee-list`)
    })
  }

  onSubmitForm = () => {

    const {
      match: {
        params: { userId }
      },
      profile: { companyId },
      history,
      selectedEmail,
      selectedPositionInCompany,
      selectedPhone1,
      selectedPhone2,
      selectedSkype,
      selectedViber
    } = this.props

    const { currentDirector } = this.state

    const showBanner = this.isShowBanner()

    const body = JSON.stringify({
      email: selectedEmail || "",
      positionInCompany: selectedPositionInCompany || "",
      phone1: selectedPhone1 || "",
      phone2: selectedPhone2 || "",
      skype: selectedSkype || "",
      viber: selectedViber || "",
      employeId: userId || "",
      companyId
    })

    fetch(`/company/${companyId}/employe-contacts`, {
      method: 'PUT',
      body,
    }).then(_ => {
      if (showBanner && !!(currentDirector?.userId)) {
        this.resetPrevDirectorPosition()
      } else {
        history.push(`/company/employee-list`)
      }
    })

  }

  render() {
    const { handleSubmit, t, langId, match: { params: { userId } }, loggedUserId, selectedPositionInCompany, selectedEmail, permissions } = this.props
    const { phone1, phone2, redirect, currentDirector, captchaVerified } = this.state
    const { phone1MaxLength,
      phone2MaxLength,
      skypeMaxLength,
      viberMaxLength,
      emailMaxLength,
    } = inputMaxLengthRulesEditEmployee


    //Some values in this constant are hardcoded in order to check how the form works
    const statuses = [
      {
        secondClassname: 'basic',
        name: 'Basic',
        price: t('price.free'),
        id: 'r1',
        value: 'basic', //Hardcoded value must be changed after the backend is done
      },
      {
        secondClassname: 'silver',
        name: 'Silver',
        price: t('price.per.month', { price: 15 }),
        id: 'r2',
        value: 'silver', //Hardcoded value must be changed after the backend is done
      },
      {
        secondClassname: 'gold',
        name: 'Gold',
        price: t('price.per.month', { price: 25 }),
        id: 'r3',
        value: 'gold', //Hardcoded value must be changed after the backend is done
      },
    ]
    const positions = dict.positionsInCompany(langId)
    const showBanner = selectedPositionInCompany === directorId && !!(currentDirector?.userId);

    const showCaptcha = !canDo(permissions, 'captcha', 'skip')

    return (
      <form className={STYLE_BASE} onSubmit={handleSubmit}>
        {redirect && <Redirect to="/" />}
        <WidgetHeader title={t('employee.edit')} />
        <ProfileMini customUserId={+userId} />
        <div className="panel">
          <div className="panel-body">
            <div className="panel-content">
              <div className="panel-form">
                <div className="panel-content-row">
                  <div className="panel-form-row">
                    <p className="title-label">{t('employee.edit.company.role')}</p>
                  </div>
                  <div className="panel-form-row">
                    <div className="panel-description">
                      <p>{t('employee.edit.admin.can')}</p>
                      <p>{t('employee.edit.manager.can')}</p>
                    </div>
                  </div>
                  <div className="panel-form-row">
                    <div className="row">
                      <div className="col-sm-5 col-md-7 col-lg-6 col-xl-5">
                        {positions && <Field
                          name="employee.positionInCompany"
                          component={SelectField}
                          items={positions}
                          placeholder={t('employee.edit.company.role')}
                          validate={required}
                        />}
                      </div>
                      {showBanner && this.renderAtentionAlert()}
                    </div>
                  </div>
                </div>
                <div className="panel-content-row">
                  <div className="panel-form-row">
                    <p className="title-label">{t('employee.edit.position.and.contact.data')}</p>
                  </div>
                  <div className="panel-form-row">
                    <div className="panel-description">
                      <p>{t('employee.edit.you.can')}</p>
                    </div>
                  </div>
                  <div className="panel-form-row">
                    <div className="row">
                      <div className="col-sm-5 col-md-7 col-lg-6 col-xl-5">
                        <label>{t('phone')}</label>
                        <Field
                          name="phone1"
                          component={RenderPhone}
                          maxLength={phone1MaxLength}
                          placeholder="+"
                          type="text"
                          formatError={phone1}
                          onErrorChange={this.onErrorChange}
                        />
                      </div>
                      <div className="col-sm-5 col-md-7 col-lg-6 col-xl-5">
                        <label>{t('phone')}</label>
                        <Field
                          name="phone2"
                          component={RenderPhone}
                          maxLength={phone2MaxLength}
                          placeholder="+"
                          type="text"
                          formatError={phone2}
                          onErrorChange={this.onErrorChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-5 col-md-7 col-lg-6 col-xl-5">
                        <label>{t('skype')}</label>
                        <Field name="skype" component="input" type="text" maxLength={skypeMaxLength} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-5 col-md-7 col-lg-6 col-xl-5">
                        <label>{t('viber')}</label>
                        <Field name="viber" component="input" type="text" maxLength={viberMaxLength} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-5 col-md-7 col-lg-6 col-xl-5">
                        <label>{t('email')}</label>
                        <Field name="email" component="input" type="text" maxLength={emailMaxLength} validate={email} />
                        <span className="red">{email(selectedEmail)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel-content-row" style={{ display: "none"}}>
                  <div className="panel-form-row">
                    <div className="panel-form-row-header clearfix">
                      <p className="title-label fl-left">{t('employee.edit.user.status')}***</p>
                      <Link to="#" className="fl-right">
                        {t('employee.edit.more.on.statuses')}
                      </Link>
                    </div>
                  </div>
                  <div className="panel-form-row">
                    <div className="status-block">
                      <div className="row">
                        <div className="col-sm-12">
                          {statuses.map((status, index) => (
                            <div
                              className={classNames({
                                'status-col': true,
                                [status.secondClassname]: true,
                              })}
                              key={index}
                            >
                              <Field
                                type="radio"
                                name="status"
                                id={status.id}
                                component="input"
                                value={status.value}
                              />
                              <label htmlFor={status.id}>
                                <span className="name">{status.name}</span>
                                <span className="price">{status.price}</span>
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="col-sm-4">
                          <div className="status-block-input">
                            <label>{t('employee.edit.number.of.months')}</label>
                            <Field
                              name="employee.setAccountStatus"
                              component={Dropdown}
                              items={dict.getMonths()}
                              placeholder={t('employee.edit.number.of.months')}
                            // validate={required}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel-content-row">

                  {showCaptcha && (
                    <Captcha
                      verifyCallback={() => {
                        this.setState({ captchaVerified: true });
                      }}
                      expiredCallback={() => this.setState({ captchaVerified: false })}
                    />
                  )}
                  <div className="panel-content-row panel-form-btns">
                    <div className="clearfix">
                      <button type="button"
                        className="button button-orange fl-left"
                        disabled={email(selectedEmail) || !captchaVerified}
                        onClick={() => this.onSubmitForm()}>
                        {t('button.save')}
                      </button>
                      {loggedUserId !== Number(userId) && <Link to="#" className="col-red fl-right" onClick={() => this.deleteEmployee(userId)}>
                        {t('employee.edit.delete.employee')}
                      </Link>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}
