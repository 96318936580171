export const int24 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Меры по упрощению процедур, касающихся морских транспортных документов</h4><p></p>

<p></p><h4>Рекомендация N 12 Европейской экономической комиссии ООН
(Женева, июль 1993 года)</h4><p></p>

<p style="font-weight:500;">Преамбула</p><br>

<p style="text-indent:2em;">1. Рабочая группа по упрощению процедур международной торговли (РГ.4) на своей
 девятой сессии в марте 1979 года приняла Рекомендацию 12 в отношении "Мер по
 упрощению процедур, касающихся морских транспортных документов" (документ
 Trade/WP.4/INF.61).</p><br>

<p style="text-indent:2em;">2. На тридцать третьей сессии РГ.4, прошедшей в марте 1991 года, была утверждена
 предлагаемая программа по правовым вопросам (Trade/WP.4/R.697), в которой обращалось
 внимание на "ранее одобренные конкретные рекомендации", включая и вышеупомянутую
 Рекомендацию.</p>

<p style="text-indent:2em;"></p><h6>3. Отмечалось, что Рекомендация 12 преследовала цель изменить официальную и
 коммерческую практику, с тем чтобы:</h6><p></p>

<p style="text-indent:2em;">i) свести к минимуму использование оборотных транспортных документов и
 содействовать использованию в качестве альтернативы упрощенной морской
 накладной или других необоротных транспортных документов;</p><br>

<p>ii) содействовать использованию единственного оригинала транспортных
 документов;</p><br>

<p>iii) содействовать использованию бланков без текста на обороте и 
стандартных транспортных документов.</p><br>

<p style="text-indent:2em;">В результате этого удалось бы избежать проблем, возникающих в связи
 с поздним прибытием транспортных документов в пункт назначения, и
 облегчить переход от документов в бумажной форме к автоматизированной
 обработке данных (АОД), т.е. к электронному обмену данными.</p><br>

<p style="text-indent:2em;">4. Однако события, произошедшие после принятия первоначальной Рекомендации,
 привели к тому, что Совещание экспертов по процедурам и документации (ГЭ.2)
 на своей сорок третьей сессии, прошедшей в марте 1991 года, отметило 
 необходимость обновления Рекомендации 12.</p><br>

<p style="text-indent:2em;">Внимание вновь обращается на тот факт, что до принятия Рекомендации 12
 ее содержание обсуждалось в рамках РГ.4 и других международных 
 организаций, включая, в частности, Международную палату судоходства
 (МПС).</p><br>

<p style="font-weight:500;">РЕКОМЕНДАЦИЯ</p>

<p style="text-indent:2em;"></p><h6>Рабочая группа по упрощению процедур международной торговли на своей
 тридцать седьмой сессии, прошедшей в марте 1993 года, согласилась
 рекомендовать:</h6><p></p>

<p style="text-indent:2em;">1. участникам международной торговли, включая судовладельцев, грузополучателей,
 банки, страховщиков и другие стороны, заинтересованные в морской перевозке
 грузов, принять и осуществить описанные ниже меры по упрощению внешнеторговых
 процедур;</p><br>

<p style="text-indent:2em;">2. правительствам, заинтересованным международным организациям и национальным
 органам по упрощению внешнеторговых процедур принять эти меры и содействовать
 их осуществлению и информировать о действиях, предпринятых с целью выполнения
 настоящей Рекомендации.</p><br>

<p style="font-weight:500;">УЧАСТНИКИ СОРОК СЕДЬМОЙ СЕССИИ РАБОЧЕЙ ГРУППЫ</p>

<p style="text-indent:2em;"></p><h6>На сессии присутствовали представители следующих стран:</h6><p></p>

<p style="text-indent:2em;">Австрии, Бельгии, Болгарии, Венгрии, Германии, Греции, Дании, Израиля, Ирландии,
 Исландии, Испании, Канады, Мальты, Нидерландов, Норвегии, Польши, Российской 
 Федерации, Румынии, Словацкой Республики, Соединенного Королевства Великобритании 
 и Северной Ирландии, Соединенных Штатов Америки, Турции, Финляндии, Франции, Чешской
 Республики, Швейцарии и Швеции. Представители Австралии, Габона, Кореи, Нигерии, 
 Саудовской Аравии, Сенегала, Южной Африки и Японии принимали участие в работе сессии
 в соответствии со статьей 11 круга ведения Комиссии.</p>

<p style="text-indent:2em;"></p><h6>На сессии присутствовали представители Секретариатов Конференции Организации 
Объединенных Наций по торговле и развитию (ЮНКТАД), Комиссии Организации Объединенных
 Наций по праву международной торговли (ЮНСИТРАЛ) и Международного торгового центра
 ЮНКТАД / ГАТТ (МТЦ), а также представители следующих межправительственных и 
 неправительственных организаций:</h6><p></p>

<p style="text-indent:2em;">Европейского экономического сообщества (ЕЭС), Всемирного почтового союза (ВПС),
 Европейской ассоциации свободной торговли (ЕАСТ), Центрального бюро международных
 железнодорожных перевозок (ЦБМЖП), Совета таможенного сотрудничества (СТС), 
 Международной ассоциации воздушного транспорта (ИАТА), Международной ассоциации
 портов и гаваней (МАСПОГ), Международной торговой палаты (МТП), Международной 
 палаты судоходства (МПС), Международного комитета железнодорожного транспорта
 (МКЖТ), Международного союза автомобильного транспорта (МСАТ), Международной 
 организации по стандартизации (ИСО), Международного союза железных дорог (МСЖД)
 и Союза портовых администраций Северной Африки (СПАСА).</p><br>

<p style="font-weight:500;">МЕРЫ ПО УПРОЩЕНИЮ ПРОЦЕДУР, КАСАЮЩИХСЯ МОРСКИХ ТРАНСПОРТНЫХ ДОКУМЕНТОВ</p><br>

<p style="font-weight:500;">I. ИСТОРИЯ ВОПРОСА</p><br>

<p style="text-indent:2em;">1. В любой торговле, будь то внутренняя или международная, покупатель и продавец 
несут равные обязанности. Обязанность продавца - доставить товары, в покупателя
 - оплатить их.</p><br>

<p style="text-indent:2em;">2. В международной торговле факторы разделения - географического и политического,
 правового и финансового - обусловили установление такой торговой практики, которая
 увязывает платеж (с помощью открытого счета, инкассо документов или документарных 
 аккредитивов, на которые распространяются "Унифицированные правила и обычаи для 
 документарных аккредитивов" Международной торговой палаты) с "конструктивной",
 а не "фактической" доставкой.</p><br>

<p style="text-indent:2em;">3. В этой связи "конструктивная" доставка считается выполненной при передаче 
продавцом покупателю "транспортного документа", выданного независимой третьей 
стороной - перевозчиком товаров. Этот документ может иметь правовой статус, 
предусматривающий возможность передачи права на товары, или же коммерческий, 
более простой статус, предусматривающий лишь возможность передачи права контроля 
за товарами.</p><br>

<p style="text-indent:2em;">4. До того как с середины 60-х годов начали развиваться контейнерные перевозки 
товаров, в связи с чем появилась возможность "смешанных перевозок", обычным 
транспортным документом при морских перевозках был "оборотный коносамент". 
Так как он имел оборотный характер, права переходили при физической передаче 
документа. Поскольку этот документ обычно выдавался в виде комплекта, состоявшего
 из нескольких равноценных "оригиналов", подпадал под действие правовых норм, 
 регулирующих хождение документов в бумажной форме, и подкреплялся коммерческой 
 практикой, основанной именно на таких документах, то он позволял первому 
 предъявителю любого "оригинала", требовать выдачи товаров в пункте назначения.</p><br>

<p style="text-indent:2em;">5. "Оборотный документ смешанных перевозок" наряду с "оборотным коносаментом", 
также выдаваемый в виде нескольких равноценных "оригиналов", явился результатом
 естественного развития документации с появлением морских смешанных перевозок
 контейнерных грузов (с перевозкой грузов наземным, внутренним водным или воздушным
 транспортом до или после морских перевозок и / или с перевозкой на "загрузочном судне"
 до специализированного "океанского контейнеровоза", курсирующего между "региональными"
 специализированными контейнерными портами - терминалами).</p>

<p style="text-indent:2em;"></p><h6>(В правиле 2.6 Правил ЮНКТАД / НТП в отношении документов смешанных перевозок 
отмечается, что "документ смешанной перевозки означает документ, удостоверяющий
 договор смешанной перевозки и могущий быть замененным сообщениями электронного
 обмена данными в той степени, в какой это разрешается применимым законом, и 
 может быть:</h6><p></p>

<p>a) оформлен в виде оборотного документа или</p><br>

<p>b) оформлен в виде необоротного документа с указанием имени 
грузополучателя").</p><br>

<p style="text-indent:2em;">6. Признание значения этих видов транспортных документов для упрощения 
процедур международной торговли привело к тому, что РГ.4 приняла в марте
 1979 года Рекомендацию N 12 - "Меры по упрощению процедур, касающихся 
 морских транспортных документов".</p><br>

<p style="text-indent:2em;">7. Рекомендация была адресована участникам "международной торговли, включая
 судовладельцев, грузоотправителей, грузополучателей, банки, страховщиков и
 другие стороны, заинтересованные в морской перевозке грузов", и "правительствам,
 заинтересованным международным организациям и национальным органам по упрощению 
 внешнеторговых процедур" 15 июня 1979 года и была включена в документы TRADE/WP
 .4/INF.61 и TD/B/FAL./INF.61.</p>

<p style="text-indent:2em;"></p><h6>8. Основными задачами Рекомендации были:</h6><p></p>

<p style="text-indent:2em;"></p><h6>i) в отношении оборотных коносаментов:</h6><p></p>

<p>a) свести до минимума их использование без особой необходимости;</p><br>

<p>b) ограничить число выдаваемых оригиналов и копий;</p><br>

<p>c) содействовать разработке в использованию стандартной формы документации;</p><br>

<p style="text-indent:2em;">d) добиваться того, чтобы вместо "мелкого печатного текста" на обратной стороне 
на лицевой стороне бланков делалась ссылка на соответствующие документы (бланки 
без текста на обороте / документы в краткой форме);</p>

<p style="text-indent:2em;"></p><h6>ii) в отношении необоротных морских накладных - содействовать их использованию, 
с тем чтобы:</h6><p></p>

<p style="text-indent:2em;">а) избегать проблем, связанных с поздним прибытием оборотных коносаментов в пункт 
назначения, что задерживает доставку товаров (и сопряжено с дополнительными рисками
 и расходами);</p><br>

<p style="text-indent:2em;">b) содействовать внедрению "автоматизированной обработки данных" (АОД), а теперь - 
"электронного обмена данными" (ЭОД).</p><br>

<p style="text-indent:2em;">9. Рассматривая возможность обновления Рекомендации N 12, необходимо отметить 
некоторые позитивные отклики и события, произошедшие с 1979 года.</p><br>

<p style="font-weight:500;">Использование необоротных морских накладных</p><br>

<p style="text-indent:2em;">10. В торговых зонах Европы, Скандинавии, Северной Америки и некоторых частях 
Дальнего Востока наблюдалась растущая тенденция к использованию в торговле 
необоротной морской накладной.</p><br>

<p style="text-indent:2em;">11. Конференция Организации Объединенных Наций по торговле и развитию (ЮНКТАД) 
высказалась в поддержку этой тенденции как "важного шага на пути к упрощению
 внешнеторговых процедур" (Письмо в адрес Международной торговой палаты от 
 1 октября 1992 года в связи с текущим пересмотром "Унифицированных правил и 
 обычаев для документарных аккредитивов").</p>

<p style="text-indent:2em;"></p><h6>.12. В законе Соединенного Королевства о морских перевозках грузов, который вступил 
в силу 16 сентября 1992 года, необоротной морской накладной придан особый правовой 
статус. Раздел 1 (3) закона определяет морскую накладную как "Любой документ, который не является
 коносаментом, однако:</h6><p></p>

<p style="text-indent:2em;">a) является такой распиской за товары, которая содержит договор морской перевозки 
грузов или подтверждает его наличие; и</p><br>

<p>b) определяет лицо, которому перевозчик должен произвести доставку товаров в 
соответствии с этим договором".</p>

<p style="text-indent:2em;"></p><h6>(Далее в разделе 1 (5) предусмотрено также развитие ЭОД и замена в конечном итоге 
документов в бумажной форме документов электронными средствами путем включения 
следующего положения:</h6><p></p>

<p style="text-indent:2em;">"Государственный министр может с помощью предписаний предусмотреть распространение
 действия настоящего Закона на те случаи, когда системы телекоммуникационной связи
 или другие информационные технологии используются для выполнения операций, 
 соответствующих</p><br>

<p>a) выдаче документа, на который распространяется действие настоящего Закона...").</p><br>

<p style="text-indent:2em;">13. Благодаря прогрессивному подходу МТП и ее реакции на предложения ЮНКТАД, а также 
торговых, банковских и транспортных кругов в ходе нынешнего пересмотра Унифицированных
 правил и обычаев МТП для документарных аккредитивов (UCP 500) в них была включена 
 статья, конкретно посвященная использованию в аккредитивах необоротных морских 
 накладных.</p><br>

<p style="text-indent:2em;">14. Действия Международного морского комитета (ММК) привели к принятию и промульгации
 "Единых правил ММК по использованию морских накладных" (Trade/WP.4/INF.116, TD/B/FAL/
 INF.116 - от 9 июля 1991 года). За исключением некоторых юрисдикций (например, Франции)
 , грузоотправитель является единственной стороной, имеющей право давать перевозчику 
 инструкции в связи с договором перевозки: если это не запрещено применимым законодательством,
 грузоотправитель имеет право изменять наименование грузополучателя в любой момент до 
 требования грузополучателя сдать груз по его прибытии в пункт назначения. Это было 
 сочтено препятствием на пути к более широкому использованию необоротной морской
 накладной.</p>

<p style="text-indent:2em;"></p><h6>Однако Правила ММК (статья 6 "Право контроля") предусматривают, что грузоотправитель 
может окончательно отказаться от такого права и передать право контроля грузополучателю,
 зафиксировав это на морской накладной в момент ее выдачи. Один крупный контейнерный 
 перевозчик в Соединенном Королевстве уже использует следующее положение:</h6><p></p>

<p style="text-indent:2em;">"По акцептовании этой накладной банком при операции с аккредитивом (акцепт которого банк
 подтверждает перевозчику) грузоотправитель окончательно отказывается от любого права 
 изменять наименование грузополучателя этих товаров в пути".</p><br>

<p style="text-indent:2em;">(Это положение можно было бы использовать с соответствующими изменениями в тех случаях,
 например, когда в соответствии с французским законодательством имеется сторона, не 
 являющаяся грузоотправителем, которая имеет законное право изменять наименование 
 грузополучателя.)</p><br>

<p style="text-indent:2em;">Когда платеж производится по документарному аккредитиву, это положение справедливо как
 в отношении продавца, так и покупателя. Если документы не отвечают необходимым 
 требованиям, платеж не производится: банк не подтверждает акцепт перевозчику, контроль
 за товарами остается у продавца, которому платеж не производится. Если документы 
 отвечают требованиям, акцепт подтверждается перевозчику, контроль за товарами переходит 
 к покупателю и производится платеж. Применение этого положения обеспечивает безопасность
 операции и является логичным с коммерческой точки зрения.</p><br>

<p style="font-weight:500;">Использование транспортных документов без текста на обороте</p><br>

<p style="text-indent:2em;">15. В редакции 1983 года Унифицированных правил и обычаев для документарных аккредитивов 
МТП (UCP 400) статьи 25 "b"ii" и 26 "b"ii" конкретно предусматривают использование 
коносаментов и документов смешанных перевозок, в которых указаны "...некоторые или 
все условия перевозки путем ссылки на источник или документ, помимо самого транспортного
 документа (транспортный документ в краткой форме / без текста на обороте)". В нынешней 
 редакции (UCP 500) особое внимание уделяется банковскому акцепту коносамента, документа
 смешанных перевозок или необоротной морской накладной, которые "как представляется,
 включают все условия перевозки или только часть из таких условий путем ссылки на источник 
 или документ, помимо транспортного документа (транспортные документы в краткой форме / 
 без текста на обороте)".</p><br>

<p style="text-indent:2em;">В ней также подчеркивается, что "банки не изучают содержание таких условий". 
(Документ МТП 470-37/104 от 17 сентября 1992 года)</p><br>

<p style="text-indent:2em;">(Следует помнить о том, что некоторые юрисдикции требуют представить подтверждение
 того, что грузополучатель должным образом осведомлен о всех условиях, на которые 
 сделана ссылка в краткой форме. Для использования краткой формы документа в некоторых
 перевозках может потребоваться четкий акцепт со стороны грузополучателя.)</p><br>

<p style="font-weight:500;">Стандартная форма</p><br>

<p style="text-indent:2em;">16. Общее признание участниками торговли преимуществ экономии, связанных с составлением
 документов на основе формуляра - образца Организации Объединенных Наций привело к 
 значительной стандартизации формы коносаментов.</p><br>

<p style="text-indent:2em;">17. Что касается требования о том, чтобы выдавался только оригинал коносамента, то здесь
 есть свидетельства постепенного изменения отношения к этой норме. Так, например, 
 в документе UCP 400 1983 года говорится об акцепте "всего комплекта оригиналов, 
 выданных грузоотправителю, если выдается более одного оригинала...", а в нынешней 
 редакции (UCP 500), возможно, будет использована следующая формулировка: "...
 единственный оригинал коносамента или, если выдано более одного оригинала, весь
 комплект выданных документов...", т.е. будет подчеркнута предпочтительность 
 единственного оригинала или "всего комплекта 1/1". (См. документ МТП 470-37/72.)</p><br>

<p style="text-indent:2em;">(Во французском законодательстве предусмотрено наличие по крайней мере двух оригиналов,
 один из которых остается у перевозчика, а другой выдается грузоотправителю. 
 Для концепции единственного оригинала значение имеет оригинал, выданный 
 грузоотправителю.)</p><br>

<p style="font-weight:500;">Обязательные и официальные требования</p><br>

<p>(для целей валютного контроля и контроля до отгрузки)</p><br>

<p>18. Необходимо также отметить и негативную реакцию, т.е. отказ действовать 
в соответствии с Рекомендацией.</p><br>

<p style="text-indent:2em;">19. Спрос на торговые оборотные документы в целях выполнения второстепенных 
полуофициальных функций возрастает. В качестве примеров можно привести процедуры
 контроля до отгрузки и валютного контроля отгрузки и валютного контроля, а также
 таможенную очистку. Такое использование документов в административных целях 
 замедляет движение товаров и может привести к таким негативным последствиям, 
 как перегрузка портов. Это задерживает внедрение морской накладкой. Однако в 
 тех случаях, когда торговые документы необходимы для таких целей, необоротные
 документы могут быть использованы столь же эффективно, как и оборотные
 документы, в частности коносамент. Необходимо добиваться, чтобы
 административные органы учитывали этот факт.</p><br>

<p style="font-weight:500;">Инертность коммерческих кругов</p><br>

<p style="text-indent:2em;">20. Еще существуют области торговли, где по-прежнему используются традиционные,
 а сейчас уже зачастую устаревшие оборотные коносаменты, даже если товары реализуются 
 только по прибытии в пункт назначения. В этих условиях необоротная морская накладная
 является эффективной заменой оборотного коносамента. Кроме того, ее использование
 помогает избежать перегруженности портов и возможных дополнительных издержек и рисков
 для товаров в портах назначения, куда товары зачастую прибывают намного раньше документов.
 Это может также привести к увеличению расходов участников торговли, поскольку в настоящее
 время в ряде крупных портов обсуждается вопрос о возможности наложения штрафов в тех 
 случаях, когда выдача товаров задерживается ввиду позднего прибытия документов, с тем
 чтобы решить проблему перегруженности портов.</p><br>

<p style="text-indent:2em;">21. Необходимо помнить о том, что даже если удается получить гарантийные письма банков,
 позволяющие произвести выдачу товаров без представления коносамента, такие гарантийные
 письма обходятся участникам торговли достаточно дорого и серьезно отражаются на кредитах,
 которые могут быть предоставлены банком.</p><br>

<p style="font-weight:500;">Требования безопасности</p><br>

<p style="text-indent:2em;">22. Вышеприведенные факты могут косвенно способствовать формированию у торговых и 
официальных кругов ошибочного мнения о том, что необоротные транспортные документы 
не обеспечивают такую же степень безопасности, как традиционные коносаменты. С точки
 зрения торговли "ценность" транспортного документа заключается в его способности 
 обеспечивать необходимую для торговли "конструктивную доставку". Это может быть достигнуто
 и благодаря юридическим характеристикам оборотного коносамента как товарораспорядительного
 "документа", и благодаря практической возможности "передачи контроля", которую даст
 необоротная морская накладная.</p><br>

<p style="text-indent:2em;">23. С административной точки зрения как в плане выполнения таможенных требований, так
 и в плане валютного контроля оборотный коносамент и необоротная морская накладная в
 равной степени позволяют достичь желаемого результата: они обеспечивают идентификацию
 и контроль за участниками торговли, товарами и торговой операцией. (И участники торговли,
 и администрация не признают тот факт, что важен не столько вид транспортного документа - 
 или любого электронного эквивалента, который может заменить его, - сколько его подлинность.
 К сожалению, как показывает нынешняя практика, могут выдаваться и приниматься за подлинные
 фальшивые транспортные документы. Гарантией является не вид документа, а честность того, кто
 его выдает: иногда это называют коммерческим "ноу-хау".)</p><br>

<p style="font-weight:500;">АОД и ЭОД</p><br>

<p style="text-indent:2em;">24. С тех пор как была разработана первоначальная Рекомендация 12 в автоматизированной передаче
 данных произошла настоящая революция по мере все более широкого использования ЭОД (электронного
 обмена данными). В 1979 году системы некоторых грузоотправителей были разработаны таким образом,
 чтобы использовать методы АОД для сокращения потоков документации благодаря использованию 
 компьютеров и упрощения подготовки транспортных и других связанных с ними документов. Однако
 в 90-е годы ЭОД превратилась в электронную передачу информации о торговых или административных
 операциях из одного компьютера в другой с использованием согласованного стандарта для оформления
 операций или передаваемых данных. Она быстрее, эффективнее и точнее по сравнению с системами
 бумажной документации.</p><br>

<p style="font-weight:500;">ООН / ЭДИФАКТ</p><br> 

<p>(Электронный обмен данными в управлении, торговле и на транспорте Организации
 Объединенных Наций)</p><br>

<p style="text-indent:2em;">25. Эта система была разработана в качестве международного стандарта, по которому оформляются
 и передаются между самостоятельными компьютерными информационными системами сообщения ЭОД. 
 В настоящее время разработаны или находятся на различных этапах разработки порядка 160 
 сообщений. Из них более 40 связаны с движением товаров, начиная от сообщений типа 
 "транспортные документы" до сообщений, необходимых для таможенных целей в связи с 
 декларацией товаров, предназначенных для импорта, экспорта или транзита. В настоящее 
 время изучается возможность включения данных в определенные сообщения о "движении товаров"
 , что придаст им договорный статус, более близкий к статусу традиционных бумажных документов,
 которые они призваны заменить.</p><br>

<p style="font-weight:500;">Обращаемость</p><br>

<p style="text-indent:2em;">26. Однако и ЭОД, и ЭДИФАКТ связаны с определенной проблемой. Электронное сообщение не может
 иметь юридические характеристики "обращаемости", что в настоящее время связывается с 
 физическим обладанием бумажным документом. Поэтому любое изменение в использовании 
 документа, которое позволяет устранить функцию обращаемости, упрощает переход от 
 обмена данными на бумаге к ЭОД. Это подчеркивает необходимость преодоления инерции 
 традиций в торговле, о чем говорилось выше в пунктах 20 и 21.</p><br>

<p style="font-weight:500;">"Компьютерная обращаемость" </p><br>

<p>("Электронные коносаменты")</p>

<p style="text-indent:2em;"></p><h6>27. Однако необходимо помнить о том, что в некоторых видах операций, в частности 
тогда, когда товары реализуются в пути, с точки зрения торговли, возможно, 
нецелесообразно отказываться от концепции обращаемости. Для решения этой проблемы 
выдвигаются различные предложения, основанные на идее реестра, согласно которой 
оригинал коносамента может быть сдан на хранение, а права на товары передаются с 
помощью особых заверенных сообщений из такого реестра сторонам, которые заинтересованы
 в этих товарах либо непосредственно, либо на основании залога. Начальную работу в 
 этой области проделали:</h6><p></p>

<p>"Чейз Манхэттен бэнк" - система документов морской перевозки, которая в настоящее
 время не используется;</p><br>

<p style="text-indent:2em;"> ММК - "Единые правила по использованию электронных коносаментов"; (Rules for 
Electronic Bills of Lading, с пояснительными записками профессора Рамберга: L/C 
Update - April 1991 pages 21 - 31: в основе этих правил ММК лежит концепция не 
центрального реестра, а "конфиденциального ключа". Система предназначена для 
использования отдельным перевозчиком, хотя при наличии центрального реестра она
 может использоваться и группой перевозчиков.);</p><br>

<p style="text-indent:2em;"> Рабочая группа Международной ассоциации независимых владельцев танкеров - документ 
(BS/5.7.90), озаглавленный "Электронный обмен данными: коносаменты для нефтяных грузов"
 (в нем говорится о "компьютерном реестре... самостоятельной коммерческой операции, 
 которая может выполняться банком или объединением банков... Хотя наиболее вероятными 
 кандидатами считаются банки, операция может выполняться любой компанией, которая занимает 
 положение, внушающее доверие всем участникам...");</p><br>

<p style="text-indent:2em;"> БИМКО - многосторонний проект по коносаментам, основанный на "сценарии нескольких 
различных посланий, обмен которыми производится в логической последовательности и которые 
вместе помогают обеспечить выполнение функций оборотного коносамента". (В основе "проекта 
лежат сообщения ЭДИФАКТ, Правила ММК и правила, касающиеся Единого кода для обмена данными 
по сети электросвязи... Постоянный комитет БИМКО по ЭОД высказал мнение, что идея центрального 
реестра поможет решить проблемы, связанные с первоначальными вариантами Правил ММК. Если бы
 БИМКО как авторитетная и неполитическая международная организация вела реестр от имени своих 
 членов, то перевозчикам не нужно было бы заниматься разработкой дорогостоящих систем, а 
 сохранность грузов обеспечивалась бы благодаря комплексному характеру операций" - выдержка
 из "Введения к проекту БИМКО по коносаментам"); и</p><br>

<p style="text-indent:2em;"> Национальный комитет по международной торговой документации (НКМГД) - предложение 
относительно "электронного коносамента", предусматривающее, что "контролирующей стороной" 
является либо "участник поставки", либо "компания, специализирующаяся на компьютерах / связи
 и назначенная грузоотправителем" (Документ Trade/WP.4/R.710).</p><br>

<p style="text-indent:2em;">Все эти "вариации на тему" представляют собой отход от традиционного мышления и являются
 важными достижениями.</p><br>

<p style="text-indent:2em;">28. Тем не менее, хотя и центральный реестр, в который могут сдаваться на хранение оригиналы
 коносаментов, а права на товары передаются с помощью особых заверенных сообщений, и технические
 достижения позволяют получить аналогичные конечные результаты благодаря, например, электронной
 подписи и электронному индоссаменту, необходимо изучить проблемы, связанные с расходами, 
 безопасностью, эффективностью и ответственностью (в зависимости от того, является ли реестр
 частным, совместным или независимым).</p><br>

<p style="font-weight:500;">Передача транспортных документов с помощью ЭОД</p><br>

<p style="text-indent:2em;">29. Однако существуют определенные виды торговой деятельности, в которых не только приняли 
идею необоротных транспортных документов в отличие от оборотного коносамента, но и стремятся
 заменить обмен данными на бумаге (документы) электронным обменом данными (сообщениями). Можно
 , в частности, упомянуть разработку в Скандинавии "расписки в получении груза" - электронного
 эквивалента необоротной морской накладной в бумажной форме (см. K. Gronfors "Cargo Key Receipt
 and Transport Document Replacement (1982)". Можно также упомянуть закон Соединенного Королевства
 1992 года о морских перевозках грузов - см. пункт 12).</p><br>

<p style="text-indent:2em;">30. Об изменении подходов можно судить на основании последней редакции ИНКОТЕРМС МТП (публикации
 МТП 460 и 461 (90)). Традиционная ссылка на коносаменты теперь заменена ссылкой на "обычный 
 транспортный документ", и приводятся примеры "оборотного коносамента, необоротной морской 
 накладной или документа о перевозке внутренним водным транспортом", т.е. больше не выдвигается 
 требование о том, чтобы документом морской перевозки обязательно являлся оборотный коносамент.</p><br>

<p style="text-indent:2em;">31. В публикации 460 особое внимание обращено на тот факт, что "в последние годы удалось достичь
 существенного упрощения процедур, связанных с документацией. Коносаменты часто заменяются 
 необоротными документами, аналогичными тем, которые используются для других видов транспорта,
 помимо морского. Эти документы называются "морские накладные", "линейные накладные", грузовые
 квитанции или аналогично. Эти необоротные документы вполне приемлемы для использования за 
 исключением тех случаев, когда покупатель желает продать товары в пути и передать документы 
 в бумажной форме новому покупателю. Для того чтобы сделать это возможным, необходимо сохранить
 обязательство продавца представить коносамент в соответствии с условиями "стоимость и фрахт" в
 сиф. Однако, если стороны договора знают, что покупатель не собирается продавать товар в пути,
 они могут специально договориться освободить продавца от обязательства представлять коносамент
 или в качестве альтернативы они могут использовать условия под и сип, которые не предусматривают
 выдачу коносамента".</p><br>

<p style="text-indent:2em;">32. Эти пересмотренные положения ИНКОТЕРМС являются также развитием концепции "эквивалентного
 электронного сообщения" как замены традиционного документа в бумажной форме. В публикации 
 461/90 отмечается, что "решением является согласие всех заинтересованных сторон", и это 
 соответствует содержащейся в проекте БИМКО ссылке на "правила, касающиеся Единого кода для
 обмена данными по сети электросвязи", которые "устанавливают нормы для нормальной торговой 
 практики с использованием электронной техники". Таким образом, они являются "составными 
 элементами в подготовке соглашения о взаимном обмене".</p><br>

<p style="font-weight:500;">Соглашение о взаимном обмене</p><br>

<p style="text-indent:2em;">33. Этот особый вид соглашения создает правовую договорную основу для электронной передачи 
сторонами информации о соответствующем торговом контракте. Либо в самом этом соглашении, либо
 в техническом приложении или руководстве для пользователя может говориться о
 включении подробных условий договора перевозки с помощью соответствующей ссылки
 , как, например, в случае традиционных бумажных транспортных документов в краткой
 форме / без текста на обороте. Такие условия, естественно, не являются предметом 
 переговоров между грузоотправителем и перевозчиком: "мелкий печатный текст" на
 бумажном документе определяется национальным законодательством и / или
 соответствующими международными конвенциями.</p><br>

<p style="text-indent:2em;">(Точно так же как банки и страховщики помимо грузоотправителя и перевозчика могут быть
 заинтересованы в документе в бумажной форме, эти стороны могут быть также 
 заинтересованы и в равноценном электронном сообщении и соответственно в 
 соглашении о взаимном обмене.)</p><br>

<p style="font-weight:500;">Компьютерные системы перевозчиков и портов</p><br>

<p style="text-indent:2em;">34. Хотя информация, содержащаяся в традиционных бумажных документах морской 
перевозки - оборотном коносаменте или необоротной морской накладной, - может быть
 непосредственно и не связана с ним, она может представлять интерес в других 
 областях, особенно если она представляется в электронной форме.</p><br>

<p style="text-indent:2em;">35. Так, например, система ЭДИШИП, разработанная десятью крупными судоходными 
компаниями, позволяет экспортерам передавать в электронной форме информацию, 
поручения, указания по отгрузке, данные о стоимости, характере операции, 
грузоотправителе / грузополучателе, условиях торговли за исключением выдачи 
соответствующего транспортного документа или эквивалентного ему электронного сообщения. 
(См. "EDISHIP - The Practical Solution".)</p><br>

<p style="text-indent:2em;">36. Часть приведенной выше информации имеет также большое значение с точки зрения портов,
 независимо от того один перевозчик дает указания о выдаче товаров или же несколько 
 пользователей действуют одинаково. Уже осуществляется электронный обмен такой 
 информацией между перевозчиком и портом.</p><br>

<p style="text-indent:2em;">(См. Конвенцию Организации Объединенных Наций 1991 года об ответственности операторов 
транспортных терминалов в международной торговле, которая еще не ратифицирована и в 
статье 4 (3) которой допускается замена документа в бумажной форме эквивалентным 
сообщением по системе электронного обмена данными.)</p><br>

<p style="text-indent:2em;">37. Точно так же заинтересованность таможенных властей в получении подробной информации
 об операции, даже если она и не предоставляется непосредственно с помощью традиционного
 транспортного документа в бумажной форме или "эквивалентного электронного сообщения", в
 настоящее время все настоятельнее требует электронной передачи информации. Информация
 по конкретной операции может быть затем передана другим учреждениям, которые могут
 проявлять интерес к товарам, например, санитарным органам и портовым властям.</p><br>

<p style="text-indent:2em;">38. В отношении замены морской перевозки в бумажной форме документа "эквивалентным
 электронным сообщением" необходимо сделать еще одно заключительное замечание. В трех
 основных международных конвенциях, касающихся документов морских перевозок, а именно
 в Гаагской конвенции, Гаагско-Висбийском протоколе и Гамбургской конвенции, 
 предусматривается выдача документов в письменной форме, хотя в Гамбургской 
 конвенции предусматривается также подписание с помощью любых иных "электронных
 средств, если это не противоречит закону страны, в которой выдается коносамент".
 Поэтому в долгосрочной перспективе может возникнуть потребность в рассмотрении 
 этого аспекта документов морской перевозки.</p><br>

<p style="font-weight:500;">II. ЦЕЛЬ</p><br>

<p style="text-indent:2em;">39. Цель настоящей Рекомендации - упрощение, рационализация и гармонизация процедур
 и документов, используемых для подтверждения договора морской перевозки.</p><br>

<p style="font-weight:500;">III. ОБЛАСТЬ ПРИМЕНЕНИЯ</p><br>

<p style="text-indent:2em;">40. Настоящая Рекомендация применяется к транспортным документам, подтверждающим 
договор или обязательство перевезти грузы на судне, и к связанным с этими процедурами.
 Она применяется также, при необходимости, к смешанным перевозкам. Она не применяется
 к чартерам, но может применяться к коносаментам и подобным документам, составленным
 в рамках чартера.</p><br>

<p style="font-weight:500;">IV. ОПРЕДЕЛЕНИЯ</p><br>

<p>41. Для целей настоящей Рекомендации были установлены следующие определения.</p><br>
 
<p style="font-weight:500;">V. РЕКОМЕНДАЦИИ</p>

<p style="text-indent:2em;"></p><h6>42. В свете всех приведенных выше замечаний предлагается, чтобы участники торговли:</h6><p></p>

<p style="text-indent:2em;">i) оценили преимущества и содействовали использованию необоротной морской накладной
 вместо коносамента, если товары не реализуются в пути (см. пункты 10 - 14 и 20);</p><br>

<p style="text-indent:2em;">ii) оценили недостатки использования оборотного коносамента в тех случаях, когда
 это не связано с коммерческой операцией, и вытекающие отсюда недостатки, расходы
 и риски, связанные с выдачей товаров в пункте назначения по предъявлении гарантийного
 письма банка в отсутствие оригинала коносамента (см. пункт 20);</p><br>

<p style="text-indent:2em;">iii) приветствовали тенденцию со стороны перевозчиков указывать условия договора 
перевозки (мелкий печатный текст на обратной стороне коносамента) лишь путем ссылки
 (документ в краткой форме / без текста на обратной стороне), отмечая, что 
 такие условия не являются предметом переговоров, за исключением, 
 возможно, чартера, и определяются соответствующими международными 
 конвенциями (см. пункт 15);</p><br>

<p style="text-indent:2em;">iv) требовали выдачи оборотного коносамента или его электронного эквивалента 
(см. пункт 27) только в тех случаях, когда товары, к которым он относится, 
реализуются в пути, отмечая возможности, которые дает система реестров, 
упоминаемых в пункте 27, а также тот факт, что это позволяет использовать 
преимущества, которые дает ЭОД, и является достаточно привлекательным 
с точки зрения как торговли, так и администрации (см. также пункт 20);</p><br>

<p style="text-indent:2em;">v) рассмотрели целесообразность выдачи, в качестве меры по борьбе с мошенничеством, 
оборотного документа морской перевозки в бумажной форме в комплекте, 
включающим только один оригинал (1 x 1) (см. пункт 17).</p>

<p style="text-indent:2em;"></p><h6>43. В свою очередь административным органам следует:</h6><p></p>

<p style="text-indent:2em;">i) оценить возможность удовлетворения административных потребностей или 
выполнения обязательных требований (включая требования внутреннего 
законодательства и / или международных конвенций) с помощью необоротной 
морской накладной, а не оборотного коносамента (см. пункты 19 и 22);</p><br>

<p style="text-indent:2em;">ii) рассмотреть возможность разработки законодательства, которое позволит 
заменить документы морской перевозки в бумажной форме эквивалентным 
электронным сообщением (пункт 12).</p><br>

<p style="text-indent:2em;">44. Как участникам торговли, так и административным органам следует оценить 
преимущества - как в плане транспортных документов в бумажной форме, так и в 
плане "эквивалентных электронных сообщений", - связанные с выполнением Рекомендации 
РГ.4 N 8 "Методология единого идентификационного кода (ЕИК)", которая преследует 
цель упрощения процедур торговли и повышения безопасности.</p><br>

<p><a href="https://zakon.rada.gov.ua/laws/show/995_559#Text">Источник</a></p>

</div>
`