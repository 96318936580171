import { toastr } from 'react-redux-toastr'
import { stringify } from 'querystring'

import fetch from '../../components/shared/fetch'
import { getBaseProps } from '../utils'

import {
  USERS_REQUEST,
  USERS_RECEIVE,
  USER_SAVE_PROFILE_START,
  USER_SAVE_PROFILE_SUCCESS,
  USER_SAVE_PROFILE_FAILURE,
  USER_GET_PROFILE_START,
  USER_GET_PROFILE_SUCCESS,
  USER_GET_PROFILE_FAILURE,
  USER_GET_STATISTICS,
  USER_CHANGE_DATE_REGISTRATION,
} from './action-types'

// -----------------------------------------------------------------
// get Profile
// -----------------------------------------------------------------
const getProfileStart = (requestedAt = new Date()) => ({
  type: USER_GET_PROFILE_START,
  requestedAt,
})
const getProfileSuccess = payload => dispatch => {
  dispatch({ type: USER_GET_PROFILE_SUCCESS, payload })
}
const getProfileFailure = error => dispatch => {
  dispatch({ type: USER_GET_PROFILE_FAILURE, error })
}
export const getProfile = (userId, cb) => {
  if (!userId) {
    throw new Error(`Can not get profile for undefined userId`)
  }
  return async dispatch => {
    dispatch(getProfileStart())
    return fetch(`/auth/getProfile/${userId}`)
      .then(json => {
        dispatch(getProfileSuccess(json))
        cb && cb(json)
      })
      .catch(json =>
        dispatch(getProfileFailure({ statusCode: json.statusCode, message: json.message }))
      )
  }
}

// -----------------------------------------------------------------
// save Profile
// -----------------------------------------------------------------
const saveProfileStart = (requestedAt = new Date()) => ({
  type: USER_SAVE_PROFILE_START,
  requestedAt,
})
const saveProfileSuccess = data => dispatch => {
  const { message } = data
  toastr.success(message, {
    timeOut: 4000,
    transitionIn: 'bounceInDown', //'fadeIn',
    transitionOut: 'bounceOutUp', //'fadeOut',
    position: 'top-left',
    progressBar: true,
  })
  dispatch({ type: USER_SAVE_PROFILE_SUCCESS, data })
}
const saveProfileFailure = error => dispatch => {
  toastr.error(error.message)
  dispatch({ type: USER_SAVE_PROFILE_FAILURE, error })
}
export const saveProfile = (values, cb) => {
  return async (dispatch, getState) => {
    dispatch(saveProfileStart())
    const state = getState()

    return fetch('/auth/saveProfile', {
      method: 'PUT',
      body: JSON.stringify({
        ...values,
        ...getBaseProps(state),
      }),
    })
      .then(json => {
        dispatch(saveProfileSuccess(json))
        cb && cb(json)
        return { ok: true }
      })
      .catch(json =>
        dispatch(saveProfileFailure({ statusCode: json.statusCode, message: json.message }))
      )
  }
}

// *****************************************************************************
//
//      Action Creators
//
// *****************************************************************************
function requestUsers(sortCol, sortDir, page, size, filters) {
  return {
    type: USERS_REQUEST,
    sortCol,
    sortDir,
    page,
    size,
    filters,
  }
}

function receiveUsers(d) {
  return {
    type: USERS_RECEIVE,
    items: d.data,
    total: d.total,
  }
}

export const createUser = ({ item }) => {
  return () => {
    const payload = {
      item,
    }
    fetch.post('/user/add', payload).catch(() => { return true; })
  }
}

export function fetchUsers(filtersParams, sortCol, sortDir, page, pageSize) {
  return dispatch => {
    const filters = {}
    dispatch(requestUsers(sortCol, sortDir, page, pageSize, filters))

    return fetch(
      `/user?${stringify({
        sortCol,
        sortDir,
        page,
        size: pageSize,
        ...filters,
      })}`
    )
      .then(json => dispatch(receiveUsers(json)))
      .catch(err => err)
  }
}

export const getUserStatistics = type => {
  return async dispatch => {
    fetch(`/user/getStatistics/${type}`).then(response => {
      dispatch({ type: USER_GET_STATISTICS, payload: response.data })
    })
  }
}

export const userChangeDateRegistration = (userId, dateRegistration) => {
  return async dispatch => {
    return fetch('/user/changeDateRegistration', {
      method: 'POST',
      body: JSON.stringify({
        userId,
        dateRegistration,
      }),
    }).then(() => {
      toastr.success(window.t('user.date.registration.change.success'))
      return dispatch({
        type: USER_CHANGE_DATE_REGISTRATION,
        payload: { dateRegistered: dateRegistration },
      })
    })
  }
}
