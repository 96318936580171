import React, { Component } from 'react'
import { Field } from 'redux-form'
import { required } from '../../../../shared/form-validators'

import './styles.scss'
import { TemplateProps } from './types'

const STYLE_BASE = 'cargo-save-template_'

const Template: React.FunctionComponent<TemplateProps> = props => {
  const { t, handleSubmit, onCancel, submitting, invalid } = props

  return (
    <div className={`${STYLE_BASE}wrapper`}>
      <div className="panel-form-row">
        <div className="row">
          <div className="col-sm-16">
            <label>{t('template.name')}</label>
            <div>
              <Field type="text" name="name" component="input" validate={required} />
            </div>
          </div>
        </div>
      </div>
      <div className={`${STYLE_BASE}buttons`}>
        <button type="button" className="btn btn-default" onClick={onCancel}>
          {t('button.close')}
        </button>
        <button
          className="button button-orange"
          disabled={submitting || invalid}
          type="button"
          onClick={handleSubmit}
        >
          {t('button.save')}
        </button>
      </div>
    </div>
  )
}

export default Template
