import _ from 'lodash'
import React from 'react'
import PopoverWrapper from '../popover-wrapper'

export default props => {
  const {
    itemClassName,
    options,
    input,
    countLanguages,
    // meta: {
    //   touched,
    //   error,
    // },
  } = props

  const selectedValues = input.value || []
  return (
    <React.Fragment>
      {options.map(({ value, name, label, tooltip, title }, index) => (
        <div className={itemClassName} key={index} title={title}>
          <input
            type="checkbox"
            id={`${input.name}_${index}`}
            name={input.name}
            onChange={event => {
              const newValue = _.filter(selectedValues, x => x !== value)
              if (event.target.checked) {
                newValue.push(value)
              }
              countLanguages(newValue.length)
              input.onChange(newValue)
            }}
            checked={selectedValues.includes(value)}
          />
          <label htmlFor={`${input.name}_${index}`}>{label || name}</label>
          {tooltip && (
            <PopoverWrapper text={tooltip}>
              <i className="fa fa-question-circle col-blue tooltip-trigger" />
            </PopoverWrapper>
          )}
        </div>
      ))}
    </React.Fragment>
  )
}
