export const int13 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Европейское соглашение, касающееся работы экипажей транспортных средств,
производящих международные автомобильные перевозки (ЕСТР)</h4><p></p>

<p>(Женева, 1 июля 1970 г.)</p><br>

<p style="font-weight:500;">Статья 1. Определения</p><br>

<p style="font-weight:500;">Статья 2. Область применения</p><br>

<p style="font-weight:500;">Статья 3. Применение некоторых положений Соглашения к автомобильным перевозкам, совершаемым
 транспортными средствами государств, которые не являются Договаривающимися Сторонами</p><br>

<p style="font-weight:500;">Статья 4. Общие принципы</p><br>

<p style="font-weight:500;">Статья 5. Члены экипажа</p><br>

<p style="font-weight:500;">Статья 6. Продолжительность управления</p><br>

<p style="font-weight:500;">Статья 7. Перерывы</p><br>

<p style="font-weight:500;">Статья 8. Продолжительность отдыха</p><br>

<p style="font-weight:500;">Статья 9. Изъятия</p><br>

<p style="font-weight:500;">Статья 10. Контрольное устройство</p><br>

<p style="font-weight:500;">Статья 11. Контроль, осуществляемый предприятием</p><br>

<p style="font-weight:500;">Статья 12. Меры по обеспечению применения Соглашения</p><br>

<p style="font-weight:500;">Статья 13. Переходные положения</p><br>

<p style="font-weight:500;">Заключительные положения (статьи 14 - 25)</p><br>

<p style="font-weight:500;">Приложение. Контрольное устройство</p><br>

<p style="font-weight:500;">Приложение - добавление 1. Требования к конструкции, проверке, установке и инспекции</p><br>

<p style="font-weight:500;">Приложение - добавление 2. Знак и свидетельство официального утверждения</p>

<p style="text-indent:2em;"></p><h6>Договаривающиеся стороны, желая способствовать развитию и улучшению международных автомобильных
 перевозок пассажиров и грузов, будучи убеждены в необходимости повышения безопасности дорожного
 движения, регламентации некоторых условий труда на предприятиях международного автомобильного транспорта
 в соответствии с принципами международной организации труда и совместной выработки некоторых мер для
 обеспечения соблюдения такой регламентации, согласились о нижеследующем:</h6><p></p>

<p style="font-weight:500;">Статья 1</p><br>

<p>Определения</p>

<p style="text-indent:2em;"></p><h6>В настоящем Соглашении подразумеваются:</h6><p></p>

<p style="text-indent:2em;">a) под "транспортным средством" - любой автомобиль или прицеп; этот термин охватывает любой
 состав транспортных средств;</p><br>

<p style="text-indent:2em;">b) под "автомобилем" - любое механическое самоходное транспортное средство, используемое обычно
 для перевозки по дорогам людей или грузов или для буксировки по дорогам транспортных средств,
 используемых для перевозки людей или грузов; этот термин не включает сельскохозяйственные
 тракторы;</p><br>

<p style="text-indent:2em;">c) под "прицепом" - любое транспортное средство, предназначенное для его буксировки автомобилем;
 - этот термин охватывает также полуприцепы,</p><br>

<p style="text-indent:2em;">d) под "полуприцепом" - любой прицеп, предназначенный для сцепления с автомобилем таким образом,
 что часть его опирается на автомобиль и последний несет на себе значительную часть веса 
 полуприцепа и его груза;</p><br>

<p style="text-indent:2em;">e) под "составом транспортных средств" - сцепленные транспортные средства, которые участвуют в 
дорожном движении как одно целое;</p><br>

<p style="text-indent:2em;">f) под "разрешенным максимальным весом" - максимальный вес груженого транспортного средства, 
объявленный допустимым компетентным органом государства, в котором зарегистрировано транспортное
 средство;</p><br>

<p style="text-indent:2em;">g) "автомобильные перевозки" означают любое передвижение по дорогам, открытым для общего пользования, 
порожнем либо с людьми или с грузом транспортного средства, используемого для перевозки пассажиров или 
грузов;</p><br>

<p style="text-indent:2em;">h) под "международными автомобильными перевозками" - любые автомобильные перевозки с пересечением, по 
крайней мере, одной границы;</p><br>

<p style="text-indent:2em;">i) "регулярные линии" означают линии, которые обеспечивают перевозку пассажиров через конкретно 
указанные интервалы движения по установленным маршрутам с посадкой и высадкой пассажиров на заранее 
определенных остановках.</p><br>

<p style="text-indent:2em;">В правилах, регулирующих эксплуатацию линий, или в заменяющих их документах, утвержденных компетентными
 органами Договаривающихся Сторон и опубликованных перевозчиком до вступления их в силу, конкретно 
 указываются условия перевозки и, в частности, частота движения по линиям, расписания, тарифы и обязательства
 по перевозке пассажиров, если такие условия не определены законом или правилами.</p><br>

<p style="text-indent:2em;">Независимо от организации того, кто организует перевозку, линии, которые обеспечивают перевозку конкретно 
указанных категорий пассажиров, исключая других пассажиров, если такие линии эксплуатируются в соответствии
 с условиями, конкретно указанными в первом подпункте настоящего определения, считаются регулярными линиями. 
 Такие линии, в частности те, которые обеспечивают доставку рабочих к месту работы и обратно или школьников в 
 школу и обратно, далее называются "специальными регулярными линиями".</p><br>

<p style="text-indent:2em;">j) под "водителем" - любое лицо, независимо от того, работает ли оно по найму или нет, управляющее транспортным 
средством хотя бы в течение короткого периода времени или находящееся на транспортном средстве для того, чтобы 
управлять им в случае необходимости;</p>

<p style="text-indent:2em;"></p><h6>k) под "членом экипажа" - водитель или любое из следующих лиц, независимо от того, работают ли они по 
найму или нет:</h6><p></p>

<p style="text-indent:2em;">(I) сопровождающий, т.е. любое лицо, сопровождающее водителя для оказания ему помощи при выполнении некоторых
 маневров и обычно принимающее активное участие в транспортных операциях, хотя и не являющееся водителем в значении,
 указанном в подпункте "j" настоящей статьи;</p><br>

<p style="text-indent:2em;">(II) кондуктор, т.е. любое лицо, сопровождающее водителя транспортного средства, производящего перевозки пассажиров,
 в частности с целью выдачи или проверки билетов или других документов, дающих пассажирам право на проезд в этом
 транспортном средстве;</p><br>

<p>l) под "неделей" - период времени с 00.00 час. в понедельник до 24.00 час. в воскресенье;</p><br>

<p style="text-indent:2em;">m) под "отдыхом" - любой непрерывный период, продолжительностью не менее часа, в течение которого 
водитель может свободно располагать своим временем.</p><br>

<p style="font-weight:500;">Статья 2</p><br>

<p>Область применения</p><br>

<p style="text-indent:2em;">1. Настоящее Соглашение применяется на территории каждой Договаривающейся Стороны по всем международным
 автомобильным перевозкам, совершаемым любым транспортным средством, зарегистрированным на территории вышеуказанной
 Договаривающейся Стороны или на территории любой Договаривающейся Стороны.</p>

<p style="text-indent:2em;"></p><h6>2. Однако:</h6><p></p>

<p style="text-indent:2em;">a) если во время международной автомобильной перевозки один или несколько членов экипажа не покидают 
государственную территорию, в пределах которой они обычно занимаются своей производственной деятельностью,
 Договаривающаяся Сторона, в ведении которой находится эта территория, может не применять в отношении этого 
 или этих членов экипажа положения настоящего Соглашения;</p>

<p style="text-indent:2em;"></p><h6>b) если Договаривающиеся Стороны, по территории которых, производятся перевозки, не договорились об обратном,
 настоящее Соглашение не применяется к международным автомобильным перевозкам грузов, совершаемым:</h6><p></p>

<p style="text-indent:2em;">1. транспортными средствами, которые используются для перевозки грузов и разрешенный максимальный вес которых,
 включая вес прицепов или полуприцепов, не превышает 3,5 тонны;</p><br>

<p style="text-indent:2em;">2. транспортными средствами, которые используются для перевозки пассажиров и которые в силу своей конструкции
 и оборудования могут перевозить не более девяти человек, включая водителя, и предназначены для этой цели;</p><br>

<p style="text-indent:2em;">3. транспортными средствами, которые используются для перевозки пассажиров на регулярных линиях, протяженность
 которых не превышает 50 км;</p><br>

<p>4. транспортными средствами, разрешенная максимальная скорость которого не превышает 30 км в час;</p><br>

<p style="text-indent:2em;">5. транспортными средствами, которые используются вооруженными силами, органами гражданской обороны, пожарной
 службой и силами поддержания общественного порядка или которые находятся в их ведении;</p><br>

<p style="text-indent:2em;">6. транспортными средствами, которые используются службами канализации, защиты от наводнений, водо-, газо-
 и электроснабжения, для технического обслуживания и ремонта дорог, для сбора и удаления мусора, службами
 телеграфа и телефона, для перевозки почтовых отправлений, службами радио и телевидения и для 
 обнаружения радио- и телевизионных передатчиков и приемников;</p><br>

<p style="text-indent:2em;">7. транспортными средствами, которые используются в чрезвычайных обстоятельствах или в ходе спасательных
 операций;</p><br>

<p>8. специальными транспортными средствами, которые используются в медицинских целях;</p><br>

<p>9. транспортными средствами, перевозящими цирковое или ярмарочное оборудование;</p><br>

<p>10. специальными аварийными транспортными средствами;</p><br>

<p style="text-indent:2em;">11. транспортными средствами, которые проходят дорожные испытания в целях совершенствования техники,
 ремонта или обслуживания, и новыми или переоборудованными транспортными средствами, которые еще не
 сданы в эксплуатацию;</p><br>

<p style="text-indent:2em;">12. транспортными средствами, которые используются для некоммерческих перевозок грузов для личного
 пользования;</p><br>

<p style="text-indent:2em;">13. транспортными средствами, которые используются для сбора молока на фермах и возвращения на фермы
 молочной тары и молочных продуктов, предназначенных для кормления животных.</p><br>

<p style="font-weight:500;">Статья 3</p><br>

<p style="text-indent:2em;">Применение некоторых положений Соглашения к автомобильным перевозкам,
совершаемым транспортными средствами государств, которые не являются Договаривающимися Сторонами</p><br>

<p style="text-indent:2em;">1. Каждая Договаривающаяся Сторона будет применять на своей территории к международным автомобильным
 перевозкам, совершаемым любым транспортным средством, зарегистрированным на территории государства,
 не являющегося Договаривающейся Стороной настоящего Соглашения, положения не менее строгие, чем те,
 которые предусмотрены в статьях 5, 6, 7, 8, 9 и 10 настоящего Соглашения.</p><br>

<p style="text-indent:2em;">2. В случае, когда транспортное средство зарегистрировано в государстве, не являющемся Договаривающейся
 Стороной настоящего Соглашения, каждая Договаривающаяся Сторона может вместо контрольного устройства,
 отвечающего спецификациям, содержащимся в приложении к настоящему Соглашению, ограничиться требованием
 предъявить ежедневные регистрационные листки, заполненные водителем от руки.</p><br>

<p style="font-weight:500;">Статья 4</p><br>

<p>Общие принципы</p><br>

<p style="text-indent:2em;">Каждая Договаривающаяся Сторона может применять более высокие минимальные или более низкие максимальные
 требования по сравнению с теми, которые указаны в статьях 5 - 8 включительно. Тем не менее положения 
 настоящего Соглашения по-прежнему будут применяться к водителям, осуществляющим международные перевозки 
 на транспортных средствах, зарегистрированных в другом государстве, являющемся или не являющемся 
 Договаривающейся Стороной.</p><br>

<p style="font-weight:500;">Статья 5</p><br>

<p>Члены экипажа</p>

<p style="text-indent:2em;"></p><h6>1. Для водителей, занятых в перевозках грузов, установлен следующий минимальный 
возраст:</h6><p></p>

<p style="text-indent:2em;">a) для водителей транспортных средств, включая, в случае необходимости, прицепы или полуприцепы,
 разрешенной максимальный вес которых не превышает 7,5 т. - не моложе 18 лет;</p>

<p style="text-indent:2em;"></p><h6>b) для водителей других транспортных средств:</h6><p></p>

<p>не моложе 21 года или</p><br>

<p style="text-indent:2em;">не моложе 18 лет при условии, что эти лица имеют удостоверение о профессиональной пригодности,
 признаваемое одной из Договаривающихся Сторон и подтверждающее окончание ими курсов подготовки
 водителей транспортных средств, предназначенных для перевозки грузов. Договаривающиеся Стороны 
 информируют друг друга о минимальном национальном уровне подготовки и других соответствующих условиях,
 касающихся водителей, занятых международной перевозкой грузов, в соответствии с настоящим
 Соглашением.</p><br>

<p>2. Водитель, занятый перевозкой пассажиров, должен быть не моложе 21 года.</p>

<p style="text-indent:2em;"></p><h6>Водитель, занятый перевозкой пассажиров по маршрутам в радиусе свыше 50 км от обычного места
 приписки транспортного средства, должен, кроме того, отвечать одному из следующих условий:</h6><p></p>

<p style="text-indent:2em;">a) у него должен быть стаж работы не менее одного года в качестве водителя транспортных средств,
 разрешенный максимальный вес которых превышает 3,5 тонны;</p><br>

<p style="text-indent:2em;">b) у него должен быть стаж работы не менее одного года в качестве водителя транспортных средств,
 которые предназначены для пассажирских перевозок по маршрутам в радиусе 50 км от обычного места
 приписки этих транспортных средств или других типов пассажирских перевозок, не подпадающих под 
 действие настоящего Соглашения, при условии, что компетентный орган сочтет, что в течение этого 
 времени он приобрел необходимый опыт;</p><br>

<p style="text-indent:2em;">с) у него должно быть удостоверение о профессиональной пригодности, которое признается одной из 
Договаривающихся Сторон и которое подтверждает, что он окончил курсы подготовки водителей транспортных
 средств, предназначенных для перевозки пассажиров.</p><br>

<p style="font-weight:500;">Статья 6</p><br>

<p>Продолжительность управления</p><br>

<p style="text-indent:2em;">1. Продолжительность управления между любыми двумя периодами ежедневного отдыха или между ежедневным 
периодом отдыха и еженедельным периодом отдыха, именуемая ниже "ежедневная продолжительность управления",
 не должна превышать девяти часов. Она может быть увеличена дважды в течение любой одной недели до 10 
 часов.</p><br>

<p style="text-indent:2em;">После максимум шести ежедневных периодов управления водитель должен получить еженедельный период отдыха
 в соответствии с пунктом 3 статьи 8.</p><br>

<p style="text-indent:2em;">Еженедельный период отдыха может быть отложен до конца шестого дня, если общая продолжительность 
управления на протяжении шести дней не превышает максимального времени, соответствующего шести 
ежедневным периодам управления транспортным средством.</p><br>

<p style="text-indent:2em;">В случае международных пассажирских перевозок, за исключением перевозок на регулярных линиях, вместо
 термина "шести" и "шестого" во втором и третьем абзацах используются соответственно термины "двенадцати"
 и "двенадцатого".</p><br>

<p style="text-indent:2em;">2. Общая продолжительность управления на протяжении любых двух недель не должна превышать 
девяносто часов.</p><br>

<p style="font-weight:500;">Статья 7</p><br>

<p>Перерывы</p><br>

<p style="text-indent:2em;">1. После управления в течение четырех с половиной часов водитель должен сделать перерыв по крайней 
мере на сорок пять минут, если не наступает период отдыха.</p><br>

<p style="text-indent:2em;">2. Этот перерыв может быть заменен перерывами продолжительностью не менее пятнадцати минут каждый, 
распределенными на протяжении периода управления или сразу после этого периода таким образом, чтобы 
это соответствовало положениям пункта 1.</p><br>

<p style="text-indent:2em;">3. В течение этих перерывов водитель не должен выполнять никакой другой работы. Для целей настоящей 
статьи время ожидания и время, не используемое для управления и проведенное в движущемся транспортном 
средстве, на пароме или в поезде, не рассматривается в качестве "другой работы".</p><br>

<p style="text-indent:2em;">4. Перерывы, соблюдаемые на основании настоящей статьи, не могут рассматриваться в качестве ежедневных 
периодов отдыха.</p><br>

<p style="font-weight:500;">Статья 8</p><br>

<p>Продолжительность отдыха</p><br>

<p style="text-indent:2em;">1. В течение каждых двадцати четырех часов водитель должен иметь непрерывный ежедневный отдых 
продолжительностью не менее одиннадцати часов, который может быть сокращен до минимум девяти часов 
непрерывного отдыха не более трех раз в течение любой одной недели при условии, что до конца следующей 
недели в качестве компенсации водителю предоставляется отдых эквивалентной продолжительности.</p><br>

<p style="text-indent:2em;">В те дни, когда продолжительность отдыха не сокращается в соответствии с первым абзацем, он 
может быть разбит на два или три отдельных периода в течение двадцати четырех часов, один из 
которых должен составлять не менее восьми последовательных часов. В этом случае минимальная 
продолжительность отдыха увеличивается до двенадцати часов.</p><br>

<p style="text-indent:2em;">2. Если в течение каждых тридцати часов транспортным средством управляли по крайней мере два 
водителя, каждый водитель должен иметь период отдыха, продолжительностью не менее восьми 
последовательных часов.</p><br>

<p style="text-indent:2em;">3. В течение каждой недели один из периодов отдыха, упомянутых в пунктах 1 и 2, должен составлять 
в качестве еженедельного отдыха в общей сложности сорок пять последовательных часов. Этот период
 отдыха может быть сокращен до минимум тридцати шести последовательных часов, если он используется
 в обычном месте приписки транспортного средства или в месте приписки водителя, или до минимум 
 двадцати четырех последовательных часов, если он используется в любом другом месте. Любое сокращение
 продолжительности отдыха должно быть компенсировано эквивалентным временем отдыха, если оно используется 
 целиком до конца третьей недели, которая следует за данной неделей.</p><br>

<p style="text-indent:2em;">4. Еженедельный период отдыха, который начинается в течение одной недели и продолжается в течение 
следующей недели, может быть присоединен к одной из этих недель.</p><br>

<p style="text-indent:2em;">5. В случае пассажирских перевозок, к которым применяется четвертый абзац пункта 1 статьи 6, еженедельный 
период отдыха может быть перенесен на ту неделю, которая следует за неделей, за которую полагается этот отдых, 
и присоединен к еженедельному периоду отдыха этой второй недели.</p><br>

<p style="text-indent:2em;">6. Любой отдых, который используется в качестве компенсации за сокращение ежедневного и/или еженедельного 
периода отдыха должен присоединяться к другому периоду отдыха продолжительностью не менее восьми часов и 
предоставляться по просьбе заинтересованного лица в месте стоянки транспортного средства или приписки 
водителя.</p><br>

<p style="text-indent:2em;">7. Ежедневный период отдыха может использоваться на транспортном средстве, если на нем имеется спальное 
место и это транспортное средство находится на стоянке.</p>

<p style="text-indent:2em;"></p><h6>8. В отступление от положений пункта 1, в случае, если водитель, осуществляющей перевозку грузов или 
пассажиров, сопровождает транспортное средство, которое перевозится на пароме или поезде, ежедневный период 
отдыха может прерываться не более одного раза, если соблюдены следующие условия:</h6><p></p>

<p style="text-indent:2em;">та часть ежедневного периода отдыха, которая проводится на суше, должна использоваться до или после той части 
ежедневного периода отдыха, которая проводится на борту парома или в поезде;</p><br>

<p style="text-indent:2em;">период между двумя промежутками ежедневного отдыха должен быть, по возможности, короче и ни в коем случае не 
может превышать одного часа до погрузки или после выгрузки, причем таможенные формальности должны быть 
включены в операции по погрузке или выгрузке;</p><br>

<p>в течение обоих промежутков отдыха водитель должен располагать спальным местом.</p><br>

<p>Ежедневный период отдыха, прерываемый, таким образом, должен увеличиваться на два часа.</p><br>

<p style="font-weight:500;">Статья 9</p><br>

<p>Изъятия</p><br>

<p style="text-indent:2em;">Для того чтобы не ставить под угрозу безопасность дорожного движения и достичь удобного места стоянки, 
водитель может отходить от положений настоящего Соглашения в той мере, в которой это необходимо для 
обеспечения безопасности находящихся в транспортном средстве лиц, транспортного средства или находящегося 
на нем груза. Водитель должен указать характер и причину отхода от этих положений в регистрационном листке 
контрольного устройства или в своей ведомости.</p><br>

<p style="font-weight:500;">Статья 10</p><br>

<p>Контрольное устройство</p>

<p style="text-indent:2em;"></p><h6>Договаривающие Стороны предписывают установку и использование на транспортных средствах, зарегистрированных 
на их территории, контрольных устройств согласно следующим требованиям:</h6><p></p>

<p style="text-indent:2em;">a) контрольное устройство должно отвечать, в том что касается его конструкции, установки, использования и проверки,
 требованиям настоящего Соглашения и приложения к нему, которое является составной частью настоящего 
 Соглашения.</p><br>

<p style="text-indent:2em;">Контрольное устройство, которое в отношении конструкции, установки, использования и проверки отвечает правилам
 Совета ЕЭС N 3821/85 от 20 декабря 1985 года, считается отвечающим требованиям настоящей статьи;</p><br>

<p style="text-indent:2em;">b) если нормальное и надлежащее использование контрольного устройства, установленного на транспортном средстве,
 невозможно, то каждый член экипажа должен вносить от руки с использованием соответствующих графических обозначений
 сведения, соответствующие его производственной деятельности и периодам отдыха, в регистрационный листок;</p><br>

<p style="text-indent:2em;">c) в тех случаях, когда члены экипажа, находясь вне транспортного средства, не могут использовать это устройство,
 они должны от руки вписать в регистрационный листок, используя соответствующие графические обозначения, различные 
 периоды времени, относящиеся к их производственной деятельности вне транспортного средства;</p><br>

<p style="text-indent:2em;">d) необходимо, чтобы члены экипажа всегда имели при себе и могли представить для проверки регистрационные листки за
 текущую неделю и за последний день предыдущей недели, во время которого они управляли транспортным средством;</p><br>

<p style="text-indent:2em;">e) члены экипажа должны обеспечивать своевременное включение и правильную эксплуатацию контрольного устройства,
 а в случае его поломки они должны как можно скорее обеспечить его ремонт.</p><br>

<p style="text-indent:2em;">2. Работодатель выдает водителям достаточное количество регистрационных листков, имея при этом в виду их персональный
 характер, продолжительность их использования и необходимость их замены в случае повреждения или предоставления лицу,
 уполномоченному осуществлять контроль. Работодатель выдает водителям листки только установленного образца, пригодные
 для использования на том оборудовании, которое установлено на транспортном средстве.</p><br>

<p style="text-indent:2em;">3. Предприятия должны сохранять регистрационные листки, заполненные в соответствии с положениями подпунктов b),
 с) и d) пункта 1 настоящей статьи, в течение не менее 12 месяцев со дня последней записи и предъявлять их по 
 требованию контрольных органов.</p><br>

<p style="font-weight:500;">Статья 11</p><br>

<p>Контроль, осуществляемый предприятием</p><br>

<p style="text-indent:2em;">1. Предприятие должно организовать автомобильные перевозки таким образом, чтобы члены экипажа могли соблюдать 
положения настоящего Соглашения.</p><br>

<p style="text-indent:2em;">2. Оно должно постоянно контролировать время управления, часы другой работы и время отдыха, используя для
 этого все находящиеся в его распоряжений документы. В случае обнаружения нарушений настоящего Соглашения, 
 оно должно немедленно устранять их и принимать меры к недопущению их в будущем, например, путем изменения 
 графиков работы и маршрутов.</p><br>

<p style="text-indent:2em;">3. Выплаты водителям, работающим по найму, даже если они осуществляются в форме премий или надбавок к заработной
 плате, связанные с пройденным расстоянием и/или объемом перевезенных грузов, запрещаются, за исключением тех случаев,
 когда эти выплаты не ставят под угрозу безопасность дорожного движения.</p><br>

<p style="font-weight:500;">Статья 12</p><br>

<p>Меры по обеспечению применения Соглашения</p><br>

<p style="text-indent:2em;">1. Каждая Договаривающаяся сторона принимает все надлежащие меры по обеспечению соблюдения положений настоящего 
Соглашения, в частности путем проведения должного контроля на дорогах и на предприятиях. Компетентные административные
 органы Договаривающихся Сторон будут информировать друг друга об общих мерах, принятых в этих целях.</p><br>

<p style="text-indent:2em;">2. Договаривающиеся Стороны оказывают друг другу помощь в ходе применения настоящего Соглашения и проверки
 его соблюдения.</p>

<p style="text-indent:2em;"></p><h6>3. В рамках этой взаимопомощи компетентные органы Договаривающихся Сторон регулярно направляют друг другу всю
 имеющуюся информацию, которая касается:</h6><p></p>

<p style="text-indent:2em;">нарушений настоящего Соглашения, совершенных нерезидентами, и любых санкций, налагаемых за такие
 нарушения;</p><br>

<p style="text-indent:2em;">санкций налагаемых Договаривающимися Сторонами на своих резидентов за такие нарушения, совершенные на
 территории других Договаривающихся Сторон.</p><br>

<p>В случае серьезных нарушений такая информация должна включать принятые санкции.</p><br>

<p style="text-indent:2em;">4. Если результаты проверки на дороге дают основания полагать, что водитель транспортного средства,
 зарегистрированного на территории другой Договаривающейся Стороны, совершил нарушение, которое не
 может быть выявлено в связи с нехваткой необходимой информации, компетентные органы заинтересованных
 Договаривающихся Сторон оказывают друг другу помощь с целью прояснить ситуацию. В случаях, когда с
 этой целью компетентная Договаривающаяся сторона проводит проверку на территории предприятия, результаты
 этой проверки доводятся до сведения другой заинтересованной Стороны.</p><br>

<p style="font-weight:500;">Статья 13</p><br>

<p>Переходные положения</p><br>

<p style="text-indent:2em;">Положения статьи 10 - Контрольное устройство становятся обязательными для стран - Договаривающихся Сторон
 настоящего Соглашения с 24 апреля 1995 года.</p><br>

<p>Заключительные положения</p><br>

<p style="font-weight:500;">Статья 14</p><br>

<p style="text-indent:2em;">1. Настоящее Соглашение будет открыто для подписания до 31 марта 1971 года и после этой даты для присоединения
 государств - членов Европейской Экономической Комиссии и государств, допущенных к участию в работе Комиссии с
 консультативным статусом в соответствии с пунктом 8 положения о круге ведения этой Комиссии.</p><br>

<p>2. Настоящее Соглашение подлежит ратификации.</p><br>

<p style="text-indent:2em;">3. Ратификационные грамоты или акты о присоединении передаются на хранение генеральному секретарю Организации
 Объединенных Наций.</p><br>

<p style="text-indent:2em;">4. Настоящее Соглашение вступит в силу на сто восьмидесятый день после сдачи на хранение восьмого документа о
 ратификации или присоединении.</p><br>

<p style="text-indent:2em;">5. В отношении каждого государства, которое ратифицирует настоящее Соглашение или присоединится к нему после
 сдачи на хранение восьмого документа о ратификации или присоединении, упомянутого в пункте 4 настоящей статьи,
 настоящее Соглашение вступит в силу по истечении ста восьмидесяти дней после даты сдачи на хранение этим государством
 своей ратификационной грамоты или акта о присоединении.</p><br>

<p style="font-weight:500;">Статья 15</p><br>

<p style="text-indent:2em;">1. Каждая Договаривающаяся Сторона может денонсировать настоящее Соглашение путем нотификации, адресованной 
генеральному секретарю Организации Объединенных Наций.</p><br>

<p style="text-indent:2em;">2. Денонсация вступает в силу по истечении шести месяцев со дня получения Генеральным секретарем
 этой нотификации.</p><br>

<p style="font-weight:500;">Статья 16</p><br>

<p style="text-indent:2em;">Настоящее Соглашение теряет силу, если после его вступления в силу число Договаривающихся Сторон составляет 
в течение какого-либо периода последовательных двенадцати месяцев менее трех.</p><br>

<p style="font-weight:500;">Статья 17</p><br>

<p style="text-indent:2em;">1. Каждое государство может при подписании настоящего Соглашения или при сдаче на хранение своего 
документа о ратификации или присоединении или в любой момент впоследствии заявить посредством нотификации, 
адресованной генеральному секретарю Организации Объединенных Наций, что действие настоящего Соглашения будет
 распространено на всю территорию или часть территории, за внешние сношения которой оно ответственно. Настоящее
 Соглашение будет применяться на территории или на территориях, указанных в нотификации, начиная со ста восьмидесятого
 дня после получения генеральным секретарем упомянутой нотификации, или, если в этот день настоящее Соглашение еще не
 вступит в силу, со дня его вступления в силу.</p><br>

<p style="text-indent:2em;">2. Каждое государство, сделавшее в соответствии с предыдущим пунктом настоящей статьи заявление о распространении
 применения настоящего Соглашения на территорию, за внешние сношения которой оно ответственно, может в соответствии 
 со статьей 15 настоящего Соглашения денонсировать настоящее Соглашение в отношении данной территории.</p><br>

<p style="font-weight:500;">Статья 18</p><br>

<p style="text-indent:2em;">1. Всякий спор между двумя или более Договаривающимися Сторонами относительно толкования, или применения настоящего 
Соглашения по возможности разрешается путем переговоров между спорящими Сторонами.</p><br>

<p style="text-indent:2em;">2. Всякий спор, который не разрешен путем переговоров, передается на арбитраж по заявлению одной из Договаривающихся 
Сторон, между которыми возник этот спор, и передается соответственно одному арбитру или нескольким арбитрам, избранным 
по общему соглашению спорящих Сторон. Если в течение трех месяцев со дня заявления об арбитраже спорящие Стороны не придут 
к соглашению относительно выбора арбитра или арбитров, любая из этих Сторон может обратиться к генеральному секретарю 
Организации Объединенных Наций с просьбой назначить единого арбитра, которому спор передается на разрешение.</p><br>

<p style="text-indent:2em;">3. Решение арбитра или арбитров, назначенных в соответствии с предыдущим пунктом, имеет обязательную силу для 
спорящих Сторон.</p><br>

<p style="font-weight:500;">Статья 19</p><br>

<p style="text-indent:2em;">1. Каждое государство может при подписании или ратификации настоящего Соглашения или при присоединении к нему заявить,
 что оно не считает себя связанным пунктами 2 и 3 статьи 18 настоящего Соглашения. Другие Договаривающиеся Стороны не 
 будут связаны этими пунктами в отношении любой Договаривающейся Стороны, сделавшей такую оговорку.</p><br>

<p style="text-indent:2em;">2. Если в момент сдачи своего документа о ратификации или присоединении какое-либо государство сделает иную оговорку, 
чем та, которая предусмотрена в пункте 1 настоящей статьи, генеральный секретарь Организации Объединенных Наций сообщает 
об этой оговорке государствам, которые уже сдали на хранение свои документы о ратификации или присоединении и не 
денонсировали впоследствии настоящего Соглашения. Оговорка считается принятой, если в течение шести месяцев со дня 
этого сообщения ни одно из этих государств не возразит против ее допущения. В противном случае оговорка не будет принята, 
и если сделавшее оговорку государство не возьмет ее обратно, то документ о ратификации или присоединении этого государства 
не будет иметь силы. При применении настоящего пункта не будут приниматься во внимание возражения государств, присоединение 
или ратификация которых в соответствии с настоящим пунктом не будет иметь силы в связи со сделанными ими оговорками.</p><br>

<p style="text-indent:2em;">3. Каждая Договаривающаяся Сторона, оговорка которой была принята в Протоколе о подписании настоящего Соглашения, 
или которая сделала оговорку в соответствии с пунктом 1 настоящей статьи, или сделала оговорку, принятую в соответствии 
с пунктом 2 настоящей статьи, может в любой момент взять свою оговорку обратно путем нотификации, адресованной 
генеральному секретарю.</p><br>

<p style="font-weight:500;">Статья 20</p><br>

<p style="text-indent:2em;">1. После трехлетнего действия настоящего Соглашения любая Договаривающаяся Сторона может путем нотификации, 
адресованной генеральному секретарю Организации Объединенных Наций, обратиться с просьбой о созыве конференции 
с целью пересмотра настоящего Соглашения. Генеральный секретарь сообщает об этой просьбе всем Договаривающимся 
Сторонам и созывает конференцию для пересмотра Соглашения, если в течение четырехмесячного периода после его сообщения,
 по меньшей мере, одна треть Договаривающихся Сторон уведомит его о своем согласии на созыв
 такой конференции.</p><br>

<p style="text-indent:2em;">2. Если в соответствии с предыдущим пунктом созывается конференция, генеральный секретарь уведомляет об этом все
 Договаривающиеся Стороны, обращается к ним с просьбой представить ему в трехмесячный срок предложения, рассмотрение
 которых на конференции представляется им желательным. По меньшей мере, за три месяца до открытия конференции 
 генеральный секретарь препровождает всем Договаривающимся Сторонам предварительную повестку дня конференции,
 а также текст этих предложений.</p><br>

<p style="text-indent:2em;">3. Генеральный секретарь приглашает на любую конференцию, созванную согласно настоящей статье, все государства
, указанные в пункте 1 статьи 14 настоящего Соглашения.</p><br>

<p style="font-weight:500;">Статья 21</p><br>

<p style="text-indent:2em;">1. Каждая Договаривающаяся Сторона может предложить одну или несколько поправок к настоящему Соглашению. Текст
 каждой предлагаемой поправки направляется генеральному секретарю Организации Объединенных Наций, который препровождает
 его всем Договаривающимся Сторонам, а также уведомляет о нем прочие государства, указанные в пункте 1 статьи 14
 настоящего Соглашения.</p>

<p style="text-indent:2em;"></p><h6>2. В течение шести месяцев со дня препровождения генеральным секретарем проекта поправки каждая Договаривающаяся
 Сторона может сообщить генеральному секретарю:</h6><p></p>

<p>a) либо что она имеет возражения, против предложенной поправки;</p><br>

<p style="text-indent:2em;">b) либо что, несмотря на ее намерение принять поправку, в ее стране еще отсутствуют условия, необходимые для
 этого принятия.</p><br>

<p style="text-indent:2em;">3. Пока Договаривающаяся Сторона, которая направила сообщение, предусмотренное в пункте 2 "b" настоящей статьи,
 не сообщит генеральному секретарю о принятии ею поправки, она может в течение девяти месяцев со дня истечения
 шестимесячного срока, предусмотренного для сообщения, представить возражение против предложенной поправки.</p><br>

<p style="text-indent:2em;">4. Если против проекта поправки было сделано возражение в условиях, предусмотренных в пунктах 2 и 3 настоящей
 статьи, поправка считается не принятой и не имеет силы.</p>

<p style="text-indent:2em;"></p><h6>5. Если против проекта поправки не было сделано никаких возражений в условиях, предусмотренных в пунктах 2 и 3
 настоящей статьи, поправка считается принятой с момента, указанного ниже:</h6><p></p>

<p style="text-indent:2em;">a) если ни одна из Договаривающихся Сторон не препроводила сообщения, предусмотренного в пункте 2 "b" настоящей
 статьи, по истечении шестимесячного срока, указанного в пункте 2 настоящей статьи;</p>

<p style="text-indent:2em;"></p><h6>b) если, по крайней мере, одна из Договаривающихся Сторон препроводила сообщение, предусмотренное в пункте 2 "b"
 настоящей статьи, в наиболее близкую из двух следующих дат:</h6><p></p>

<p style="text-indent:2em;">даты, когда все договаривающиеся стороны, препроводившие такое сообщение, известили генерального секретаря о
 принятии ими проекта; этой датой, однако, считается истечение шестимесячного срока, указанного в пункте 2 настоящей
 статьи, если все сообщения о принятии поправки были нотифицированы до истечения этого срока;</p><br>

<p>даты истечения девятимесячного срока, указанного в пункте 3 настоящей статьи.</p><br>

<p style="text-indent:2em;">6. Каждая поправка, считающаяся принятой, вступает в силу через три месяца после даты, в которую она была сочтена
 принятой.</p><br>

<p style="text-indent:2em;">7. Генеральный секретарь извещает возможно скорее все Договаривающиеся Стороны о том, было ли сделано возражение
 против проекта поправки в соответствии с пунктом 2 "а" настоящей статьи и препроводили ли ему одна или несколько
 Договаривающихся Сторон сообщение в соответствии с пунктом 2 "b" настоящей статьи.</p><br>

<p style="text-indent:2em;">Если одна или несколько Договаривающихся Сторон препроводили такое сообщение, генеральный секретарь уведомляет
 впоследствии все Договаривающиеся Стороны о том, сделала ли Договаривающаяся Сторона или Стороны, которые препроводили
 ему это сообщение, возражение против предложенной поправки или приняли ее.</p><br>

<p style="text-indent:2em;">8. Независимо от предусматриваемой пунктами 1 - 6 настоящей статьи процедуры внесения поправок, приложение к настоящему
 Соглашению может быть изменено по соглашению между компетентными органами всех Договаривающихся Сторон; если компетентный
 орган одной из Договаривающихся Сторон заявит, что согласно ее национальному законодательству ее согласие зависят от
 получения специального разрешения или от одобрения законодательного органа, согласие компетентного органа упомянутой 
 Договаривающейся Стороны на изменение приложения будет считаться данным лишь тогда, когда этот компетентный орган 
 заявит генеральному секретарю, что требуемые полномочия или разрешения получены. В соглашении между компетентными 
 органами будет установлена дата вступления в силу измененного приложения и может быть предусмотрено, что в течение 
 переходного периода старое приложение полностью или частично остается в силе одновременно с новым приложением.</p><br>

<p style="font-weight:500;">Статья 22</p><br>

<p style="text-indent:2em;">1. B добавления 1 и 2 приложения к настоящему Соглашению могут вноситься поправки в соответствии с процедурой, 
предусмотренной в настоящей статье.</p><br>

<p style="text-indent:2em;">2. По просьбе какой-либо Договаривающейся Стороны, любое ее предложение о внесении поправок в добавления 1 и 2
 к приложению к настоящему Соглашению рассматривается Основной рабочей группой по автомобильному транспорту 
 Европейской Экономической Комиссии.</p><br>

<p style="text-indent:2em;">3. В случае одобрения поправки большинством членов, присутствующих и участвующих в голосовании, и если такое 
большинство включает большинство присутствующих и участвующих в голосовании Договаривающихся Сторон, генеральный
 секретарь направляет поправку компетентным администрациям всех Договаривающихся Сторон для ее принятия.</p><br>

<p style="text-indent:2em;">4. Поправка принимается, если в течение шестимесячного срока со дня уведомления менее одной трети компетентных 
администраций Договаривающихся Сторон уведомляют генерального секретаря о своем возражении против поправки.</p><br>

<p style="text-indent:2em;">5. Генеральный секретарь уведомляет все Договаривающиеся Стороны о любой принятой поправке, которая вступает в
 силу через три месяца со дня такого уведомления.</p><br>

<p style="font-weight:500;">Статья 23</p>

<p style="text-indent:2em;"></p><h6>Помимо уведомлений, предусмотренных в статьях 20 и 21 настоящего Соглашения, генеральный секретарь Организации 
Объединенных Наций сообщает государствам, указанным в пункте 1 статьи 14 настоящего Соглашения:</h6><p></p>

<p>a) о ратификациях и присоединениях в соответствии со статьей 14 настоящего Соглашения;</p><br>

<p>b) о датах вступления в силу настоящего Соглашения в соответствии со статьей 14 настоящего Соглашения;</p><br>

<p>c) о денонсациях в соответствии со статьей 15 настоящего Соглашения;</p><br>

<p>d) o прекращении настоящего Соглашения в соответствии со статьей 16 настоящего Соглашения;</p><br>

<p>e) об уведомлениях, полученных в соответствии со статьей 17 настоящего Соглашения;</p><br>

<p>f) о заявлениях к уведомлениях, полученных в соответствии со статьей 19 настоящего Соглашения;</p><br>

<p>g) о вступлении в силу любой поправки в соответствии со статьей 21 настоящего Соглашения.</p><br>

<p style="font-weight:500;">Статья 24</p><br>

<p style="text-indent:2em;">Протокол о подписании настоящего соглашения имеет ту же силу, действительность и срок действия, как и настоящее 
Соглашение, неотъемлемой частью которого он считается.</p><br>

<p style="font-weight:500;">Статья 25</p><br>

<p style="text-indent:2em;">После 31 марта 1971 года подлинник настоящего Соглашения будет сдан на хранение генеральному секретарю 
Организации Объединенных Наций, который препроводит надлежащим образом заверенные копии каждому из государств, 
указанных в пункте 1 статьи 14 настоящего Соглашения.</p><br>

<p style="text-indent:2em;">В удостоверение чего нижеподписавшиеся, надлежащим образом на то уполномоченные,
 подписали настоящее Соглашение.</p><br>

<p style="text-indent:2em;">Совершено в Женеве первого июля тысяча девятьсот семидесятого года в одном экземпляре на 
английском и французском языках, причем оба текста являются равно аутентичными.</p>

<p></p><h4>Приложение</h4><p></p>

<p></p><h4>Контрольное устройство</h4><p></p>

<p style="font-weight:500;">Общие положения</p>

<p></p><h4>I. Официальное утверждение типа (ст.ст. 1 - 8)</h4><p></p>

<p></p><h4>II. Установка и инспекция (ст. 9)</h4><p></p>

<p></p><h4>III. Использование оборудования (ст.ст. 10 - 11)</h4><p></p>

<p style="font-weight:500;">Общие положения</p>

<p></p><h4>I. Официальное утверждение типа</h4><p></p>

<p style="font-weight:500;">Статья 1</p><br>

<p style="text-indent:2em;">Заявки на официальное утверждение типа контрольного устройства или образца регистрационного листка с 
прилагаемыми к ним соответствующими спецификациями подаются изготовителем или его агентом одной из 
Договаривающихся Сторон. Заявки в отношении какого-либо одного типа контрольного устройства или какого-либо 
одного образца регистрационного листка не могут подаваться более чем одной Договаривающейся Стороне.</p><br>

<p style="font-weight:500;">Статья 2</p><br>

<p style="text-indent:2em;">Договаривающаяся Сторона предоставляет официальное утверждение любого типа контрольного устройства
 или любого образца регистрационного листка, которые соответствуют требованиям добавления 1 к настоящему
 приложению, при условии, что данная Договаривающаяся Сторона может проверить соответствие серийных образцов
 официально утвержденному прототипу.</p><br>

<p style="text-indent:2em;">Любые изменения или добавления к официально утвержденному образцу должны быть предметом дополнительного
 официального утверждения типа, предоставляемого той Договаривающейся Стороной, которая предоставила первоначальное
 официальное утверждение типа.</p><br>

<p style="font-weight:500;">Статья 3</p><br>

<p style="text-indent:2em;">Договаривающиеся Стороны выдают подателю заявки знак официального утверждения, который соответствует образцу,
 приведенному в добавлении 2, для каждого типа контрольного устройства или образца регистрационного листка, которые 
 они официально утвердили в соответствии со статьей 2.</p><br>

<p style="font-weight:500;">Статья 4</p><br>

<p style="text-indent:2em;">Компетентные органы Договаривающейся Стороны, которой была представлена заявка на официальное утверждение, должны
 в отношении каждого типа контрольного устройства или образца регистрационного листка, который они официально
 утверждают или отказываются утвердить, либо направить в течение одного месяца органам других Договаривающихся 
 Сторон копию свидетельства официального утверждения с приложенными к нему копиями соответствующих спецификаций, 
 либо, в соответствующем случае, уведомить эти органы об отказе в официальном утверждении; в случаях отказа они 
 должны сообщить причины своего решения.</p><br>

<p style="font-weight:500;">Статья 5</p><br>

<p style="text-indent:2em;">1. Если Договаривающаяся Сторона, которая предоставила официальной утверждение типа в соответствии со статьей 2,
 признает, что определенное контрольное устройство или регистрационные листки с нанесенным на них знаком официального
 утверждения, который она выдала, не соответствуют тому прототипу, в отношении которого она предоставила официальное
 утверждение, она должна принять необходимые меры для обеспечения того, чтобы серийные образцы соответствовали официально
 утвержденному прототипу. При необходимости принятые меры могут включать отмену официального утверждения типа.</p><br>

<p style="text-indent:2em;">2. Договаривающаяся Сторона, предоставившая официальное утверждение типа, отменяет такое официальное утверждение,
 если контрольное устройство или регистрационный листок, которые были официально утверждены, не соответствуют положениям
 настоящего приложения или добавлений к нему или если в ходе эксплуатации обнаруживается, что они имеют какой-либо 
 общий дефект, который делает их непригодными для той цели, для которой они предназначены.</p><br>

<p style="text-indent:2em;">3. Если Договаривающаяся Сторона, предоставившая официальное утверждение типа, уведомляется другой Договаривающейся
 Стороной о каком-либо из случаев, упомянутых в пунктах 1 и 2, она должна после консультации с этой последней 
 Договаривающейся Стороной также предпринять меры, изложенные в этих пунктах, при условии соблюдения положений 
 пункта 5.</p><br>

<p style="text-indent:2em;">4. Если Договаривающаяся Сторона констатирует наличие одного из случаев, упомянутых в пункте 2, она может 
запретить до последующего уведомления продажу или использование контрольного устройства или регистрационных 
листков. Это положение распространяется и на случаи, упомянутые в пункте 1, в отношении контрольного устройства
 или регистрационных листков, которые были освобождены от первоначальной проверки, если завод-изготовитель после
 соответствующего предупреждения не приводит устройство в соответствие с официально утвержденным образцом или с
 требованиями настоящего приложения.</p><br>

<p style="text-indent:2em;">В любом случае компетентные органы Договаривающихся Сторон должны уведомить друг друга в течение одного месяца
 о любой отмене официального утверждения типа или о любых других мерах, принятых в соответствии с пунктами 1, 
 2 и 3, и должны указывать причины таких действий.</p><br>

<p style="text-indent:2em;">5. Если Договаривающая Сторона, предоставившая официальное утверждение типа, оспаривает наличие случаев, которые 
указаны в пунктах 1 или 2 и о которых она была уведомлена, заинтересованные Договаривающиеся стороны должны принять
 все меры в целях урегулирования данного спора.</p><br>

<p style="font-weight:500;">Статья 6</p><br>

<p style="text-indent:2em;">1. Податель заявки на официальное утверждение типа образца регистрационного листка должен указать на своей
 заявке тип или типы контрольного устройства, на которых предусматривается использовать данный листок, и 
 предоставить соответствующее оборудование такого типа или таких типов в целях апробирования данного листка.</p><br>

<p style="text-indent:2em;">2. Компетентные органы каждой Договаривающей Стороны должны указать в свидетельстве официального утверждения 
образца регистрационного листка тип или типы контрольного устройства, на котором или которых этот образец листка
 может быть использован.</p><br>

<p style="font-weight:500;">Статья 7</p><br>

<p style="text-indent:2em;">Договаривающиеся Стороны не могут отказывать в регистрации какого-либо транспортного средства, оборудованного 
контрольным устройством, или запрещать ввод в эксплуатацию или использование такого транспортного средства по какой-либо
 из причин, связанных с тем, что данное транспортное средство оборудовано таким устройством, если на устройство нанесен
 знак официального утверждения, указанный в статье 3, и табличка с данными об установке, указанная в статье 9.</p><br>

<p style="font-weight:500;">Статья 8</p><br>

<p style="text-indent:2em;">Все принятые в соответствии с положениями настоящего приложения решения об отказе в официальном утверждении типа 
контрольного устройства или образца регистрационного листка или об отмене такого официального утверждения должны быть
 детально обоснованы. Такое решение должно быть доведено до сведения заинтересованной стороны, которая одновременно 
 должна быть проинформирована об имеющихся у нее средствах правовой защиты, предусмотренной законодательством данной 
 Договаривающейся Стороны, а также о периоде времени, в течение которого она может воспользоваться такими средствами
 правовой защиты.</p>

<p></p><h4>II. Установка и инспекция</h4><p></p>

<p style="font-weight:500;">Статья 9</p><br>

<p style="text-indent:2em;">1. Контрольное устройство может быть установлено или подвергнуто ремонту механиками или мастерскими, которые утверждаются
 компетентными органами Договаривающихся Сторон для этой цели после того, как последние, если они того пожелают, 
 ознакомятся с мнениями заинтересованных заводов-изготовителей.</p><br>

<p style="text-indent:2em;">2. Утвержденный механик или мастерская должны наносить особый знак на налагаемые ими пломбы. Компетентные органы каждой
 Договаривающейся Стороны должны вести регистрационный журнал используемых знаков.</p><br>

<p style="text-indent:2em;">3. Компетентные органы Договаривающихся Сторон должны направлять друг другу свои списки утвержденных механиков или 
мастерских, а также копии используемых знаков.</p><br>

<p style="text-indent:2em;">4. Для целей подтверждения того, что установка контрольного устройства была произведена в соответствии с требованиями
 настоящего приложения, должна устанавливаться табличка с данными об установке, предусмотренная в добавлении 1.</p>

<p></p><h4>III. Использование оборудования</h4><p></p>

<p style="font-weight:500;">Статья 10</p><br>

<p>Работодатель и водители несут ответственность за обеспечение надлежащего функционирования устройства.</p><br>

<p style="font-weight:500;">Статья 11</p><br>

<p style="text-indent:2em;">1. Водители не должны использовать загрязненные или поврежденные регистрационные листки. В этом отношении листки
 должны быть надлежащим образом защищены.</p><br>

<p style="text-indent:2em;">В случае повреждения листка с внесенными в него записями водители должны прикладывать поврежденный листок к 
дополнительному листку, используемому для его замены.</p><br>

<p style="text-indent:2em;">2. Водители должны использовать регистрационные листки каждый день, в течение которого они управляли транспортным
 средством, начиная с момента его принятия. Регистрационный листок не должен изыматься до конца ежедневного периода
 работы, если нет официального разрешения на его изъятие. Регистрационный листок не может быть использован на период
 продолжительнее того, для которого он предназначен.</p><br>

<p style="text-indent:2em;">Если в результате нахождения вне транспортного средства водитель не может использовать устройство, установленное на
 транспортном средстве, то периоды времени должны быть занесены в листок либо от руки, либо путем автоматического
 регистрирования, либо другими способами, отчетливо и без загрязнения листка.</p><br>

<p style="text-indent:2em;">Если на борту транспортного средства находится более одного водителя, то водители должны вносить необходимые пометки 
в регистрационные листки таким образом, чтобы информация, указанная в пунктах 1 - 3 главы II добавления 1, заносилась
 на регистрационный листок того водителя, который фактически управляет транспортным средством.</p><br>

<p style="text-indent:2em;">3. Конструкция контрольного устройства должна быть такой, чтобы в случае необходимости после вскрытия оборудования
 уполномоченный инспектор мог ознакомиться с записями, произведенными за девять часов, предшествующих времени проверки, 
 без необратимой деформации, порчи или загрязнения листка.</p><br>

<p style="text-indent:2em;">Кроме того, конструкция контрольного устройства должна быть такой, чтобы, не вскрывая корпус, можно было удостовериться
 в том, что запись производится.</p><br>

<p style="text-indent:2em;">4. По требованию уполномоченного инспектора водитель должен иметь возможность представить регистрационные листки за
 текущую неделю и в любом случае за последний день предшествующей недели, в течение которого он управлял
 транспортным средством.</p>

<p></p><h4>Приложение - добавление 1</h4><p></p>

<p></p><h4>Требования к конструкции, проверке, установке и инспекции</h4><p></p>

<p style="font-weight:500;">I. Определения</p><br>

<p style="font-weight:500;">II. Общие характеристики и функции контрольного устройства</p><br>

<p style="font-weight:500;">III. Требования к конструкции контрольного устройства</p><br>

<p style="font-weight:500;">А. Общие положения</p><br>

<p style="font-weight:500;">В. Визуальные приборы</p><br>

<p style="font-weight:500;">С. Регистрирующие приборы</p><br>

<p style="font-weight:500;">D. Закрывающее устройство</p><br>

<p style="font-weight:500;">Е. Маркировка</p><br>

<p style="font-weight:500;">F. Максимально допустимые отклонения (визуальные регистрирующие приборы)</p><br>

<p style="font-weight:500;">IV. Регистрационные листки</p><br>

<p style="font-weight:500;">А. Общие положения</p><br>

<p style="font-weight:500;">В. Зоны записи и их градуирование</p><br>

<p style="font-weight:500;">С. Информация, которая должна заноситься на регистрационные листки</p><br>

<p style="font-weight:500;">D. Свободное место для записей, вписываемых от руки</p><br>

<p style="font-weight:500;">V. Установка контрольного устройства</p><br>

<p style="font-weight:500;">А. Общие положения</p><br>

<p style="font-weight:500;">В. Наложение пломб</p><br>

<p style="font-weight:500;">VI. Проверки и инспекции</p><br>

<p style="font-weight:500;">1. Утверждение новых или восстановленных приборов</p><br>

<p style="font-weight:500;">2. Установка</p><br>

<p style="font-weight:500;">3. Периодические инспекции</p><br>

<p style="font-weight:500;">4. Измерение погрешностей</p><br>

<p style="font-weight:500;">I. Определения</p>

<p style="text-indent:2em;"></p><h6>В настоящем добавлении подразумеваются:</h6><p></p>

<p style="text-indent:2em;">a) под "контрольным устройством" - оборудование, предназначенное для установки на дорожных транспортных средствах
 в целях показания или регистрации в автоматическом или полуавтоматическом режиме данных о движении этих
 транспортных средств или об определенных периодах работы их водителей;</p><br>

<p style="text-indent:2em;">b) под "регистрационным листком" - листок, который предназначен для внесения в него и хранения зарегистрированных
 данных, который вводится в контрольное устройство и на котором пишущие устройства последнего ведут непрерывную
 запись информации, подлежащей регистрации;</p><br>

<p style="text-indent:2em;">c) под "постоянной контрольного устройства" - цифровая характеристика показывающая величину входного сигнала,
 необходимого для показания и регистрации пройденного расстояния в один километр; эта постоянная должна выражаться
 либо в оборотах на километр (к = .... об./км), либо в импульсах на километр (к = ... имп./км);</p><br>

<p style="text-indent:2em;">d) под "характеристическим коэффициентом транспортного средства" - цифровая характеристика, показывающая величину
 выходного сигнала, который создается тем элементом транспортного средства, который соединяет его с контрольным
 устройством (вторичный вал или ось коробки передач); когда транспортное средство проходит расстояние в один
 измеренный километр при нормальных условиях испытания (см. пункт 4 главы VI настоящего добавления). Характеристический
 коэффициент выражается либо в оборотах на километр (w = ... об./км), либо в импульсах на километр (w = ... имп./км);</p><br>

<p style="text-indent:2em;">e) под "эффективной окружностью" шин колес - среднее значение расстояний, пройденных несколькими колесами, приводящими 
в движение транспортное средство (ведущими колесами), за один полный их оборот. Измерение этих расстояний должно
 проводиться при нормальных условиях испытаний (см. пункт 4 главы VI настоящего добавления), их величина имеет 
 следующее выражение: L = ... мм.</p><br>

<p style="font-weight:500;">II. Общие характеристики и функции контрольного устройства</p>

<p style="text-indent:2em;"></p><h6>Контрольное устройство должно обеспечивать регистрацию:</h6><p></p>

<p>1. Расстояния, пройденного транспортным средством;</p><br>

<p>2. Скорости транспортного средства;</p><br>

<p>3. Продолжительности управления;</p><br>

<p>4. Других периодов работы или нахождения на рабочем месте;</p><br>

<p>5. Перерывов в работе и ежедневных периодов отдыха;</p><br>

<p>6. Вскрытия корпуса, содержащего регистрационный листок;</p><br>

<p style="text-indent:2em;">7. Для электронного контрольного устройства, представляющего собой устройство, которое функционирует на основе
 передачи электрических сигналов с датчиков расстояния и скорости, - любого перерыва, продолжительность которого
 превышает 100 миллисекунд в электрическом питании регистрирующего оборудования (за исключением освещения), в
 электрическом питании датчиков расстояния и скорости, а также любого перерыва в подаче сигнала от датчика 
 расстояния и скорости.</p><br>

<p style="text-indent:2em;">На транспортных средствах, используемых двумя водителями, контрольное устройство должно обеспечивать регистрацию
 одновременно, но четко и на двух отдельных листках данных по периодам, указанным в пунктах 3, 4 и 5.</p><br>

<p style="font-weight:500;">III. Требования к конструкции контрольного устройства</p><br>

<p style="font-weight:500;">А. Общие положения</p>

<p style="text-indent:2em;"></p><h6>1. Контрольное устройство должно включать:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) визуальные приборы, показывающие:</h6><p></p>

<p>пройденное расстояние (счетчик расстояния);</p><br>

<p>скорость (спидометр);</p><br>

<p>время (часы).</p>

<p style="text-indent:2em;"></p><h6>b) регистрирующие приборы, в том числе:</h6><p></p>

<p>прибор, регистрирующий пройденное расстояние;</p><br>

<p>прибор, регистрирующий скорость;</p><br>

<p style="text-indent:2em;">один или несколько приборов, которые регистрируют время и которые соответствуют требованиям, изложенным
 в пункте 4 части С главы III.</p>

<p style="text-indent:2em;"></p><h6>c) пишущее устройство, отдельно регистрирующее в регистрационном листке:</h6><p></p>

<p>каждое вскрытие корпуса, содержащего этот листок;</p><br>

<p style="text-indent:2em;">для электронного контрольного устройства, определенного в пункте 7 главы II, - любой перерыв, продолжительность 
которого превышает 100 миллисекунд в электрическом питании контрольного устройства (за исключением освещения) до
 повторного включения источника электрического питания;</p><br>

<p style="text-indent:2em;">для электронного контрольного устройства, определенного в пункте 7 главы II, - любой перерыв продолжительность
 которого превышает 100 миллисекунд, в электрическом питании датчика расстояния и скорости, и любой перерыв в 
 подаче сигнала от датчика расстояния и скорости.</p><br>

<p style="font-weight:500;">2. Любое включение в оборудование каких-либо устройств в дополнение к перечисленным выше не должно создавать 
помех для надлежащего функционирования обязательных устройств или для считки их показаний.</p><br>

<p style="text-indent:2em;">Контрольное устройство должно представляться на официальное утверждение полностью укомплектованным любыми 
подобными дополнительными устройствами.</p><br>

<p style="font-weight:500;">3. Материалы</p><br>

<p style="text-indent:2em;">a) все составные части контрольного устройства должны быть изготовлены из материалов, обладающих достаточной
 устойчивостью и механической прочностью, а также стабильными электрическими и магнитными характеристиками,</p><br>

<p style="text-indent:2em;">b) любое изменение в составной части контрольного устройства или в характере материалов, используемых для ее
 производства, должно до его внедрения в производство быть представлено на официальное утверждение тому органу,
 который предоставил официальное утверждение типа в отношении контрольного устройства.</p><br>

<p style="font-weight:500;">4. Измерение пройденного расстояния</p>

<p style="text-indent:2em;"></p><h6>Пройденное расстояние может быть измерено и зарегистрировано либо:</h6><p></p>

<p>при движении вперед и движении задним ходом, либо</p><br>

<p>при движении только вперед.</p><br>

<p style="text-indent:2em;">Любая регистрация движения задним ходом ни в коем случае не должна отрицательно влиять на четкость и точность
 других регистрируемых данных.</p><br>

<p style="font-weight:500;">5. Измерение скорости</p><br>

<p style="text-indent:2em;">a) диапазон измерения скорости должен соответствовать положениям, указанным в свидетельстве официального
 утверждения типа.</p><br>

<p style="text-indent:2em;">b) собственная частота и демпфирование измерительного прибора должны быть таковыми, чтобы приборы,
 показывающие и регистрирующие скорость, могли в рамках диапазона измерения реагировать на изменения
 ускорения до 2 м/сек2 в пределах допустимых погрешностей.</p><br>

<p style="font-weight:500;">6. Измерение времени (часы)</p><br>

<p style="text-indent:2em;">a) орган управления механизма запуска часов должен быть расположен внутри корпуса, содержащего
 регистрационный листок; каждое вскрытие этого корпуса должно автоматически регистрироваться 
 на регистрационном листке.</p><br>

<p style="text-indent:2em;">b) если механизм движения регистрационного листка вперед контролируется часами, то период, в 
течение которого эти часы идут правильно после полного завода, должен по крайнем мере на 10% 
превышать период регистрирования, соответствующий максимальному запасу листков, содержащемуся 
в оборудовании.</p><br>

<p style="font-weight:500;">7. Освещение и защита</p><br>

<p style="text-indent:2em;">a) визуальные приборы контрольного устройства должны быть оборудованы соответствующими
 неослепляющими приборами освещения.</p><br>

<p style="text-indent:2em;">b) для целей обеспечения нормальных условий использования все внутренние части контрольного
 устройства должны быть защищены от попадания влаги и пыли. Кроме того, они должны быть
 защищены от несанкционированного вскрытия путем использования корпусов, которые можно
 опечатывать.</p><br>

<p style="font-weight:500;">В. Визуальные приборы</p><br>

<p style="font-weight:500;">1. Индикатор пройденного расстояния (счетчик расстояния)</p><br>

<p style="text-indent:2em;">a) минимальная цена деления шкалы контрольного устройства, показывающего пройденное расстояние,
 должна составлять 0,1 километра. Цифры, показывающие гектометры, должны быть четко отличимы
 от цифр, показывающих целые километры.</p><br>

<p style="text-indent:2em;">b) цифры на шкале счетчика расстояния должны быть четко различимы, и их размер по высоте
 должен составлять не менее 4 мм для обеспечения их легкой считки.</p><br>

<p>c) счетчик расстояния должен регистрировать не менее 99 999,9 километра пробега.</p><br>

<p style="font-weight:500;">2. Индикатор скорости (спидометр)</p><br>

<p style="text-indent:2em;">a) в рамках диапазона измерения шкала скорости должна быть равномерно градуирована на деления
 по 1, 2, 5 или 10 километров в час. Цена деления шкалы скорости (расстояние между двумя
 соседними делениями) не должна превышать 10% от максимальной скорости, указанной на
 шкале.</p><br>

<p>b) участок шкалы, выходящий за пределы диапазона измерения, цифрами может не отмечаться.</p><br>

<p style="text-indent:2em;">c) длина каждого промежутка шкалы, соответствующего разнице скорости в 10 километров в час,
 должна быть не менее 10 миллиметров.</p><br>

<p style="text-indent:2em;">d) на индикаторе стрелочного типа расстояние между стрелкой и циферблатом контрольного
 устройства не должно превышать 3 миллиметра.</p><br>

<p style="font-weight:500;">3. Индикатор времени (часы)</p><br>

<p style="text-indent:2em;">Индикатор времени должен быть видимым снаружи контрольного устройства и должен
 обеспечивать четкую, устойчивую и недвусмысленную считку его показаний.</p><br>

<p style="font-weight:500;">С. Регистрирующие приборы</p><br>

<p style="font-weight:500;">1. Общие положения</p><br>

<p style="text-indent:2em;">a) на всем оборудовании, независимо от формы регистрационного листка (лента или диск),
 должен быть нанесен знак, обеспечивающий правильную установку регистрационного листка,
 с тем чтобы время, показываемое часами, и отметка времени на листке совпадали.</p><br>

<p style="text-indent:2em;">b) механизм, обеспечивающий подачу регистрационного листка, должен быть таким, чтобы
 последний двигался равномерно и мог быть без труда установлен и извлечен.</p><br>

<p style="text-indent:2em;">c) для регистрационных листков в форме диска устройство, обеспечивающее движение вперед,
 должно контролироваться часовым механизмом. В этом случае круговое движение листка должно
 быть непрерывным и равномерным, с минимальной скоростью 7 миллиметров в час, измеряемой по
 внутреннему краю кольца, обозначающего границу зоны записи скорости.</p><br>

<p style="text-indent:2em;">В оборудовании ленточного типа, где устройство, обеспечивающее движение листков вперед,
 контролируется часовым механизмом, скорость прямолинейного движения вперед должна быть
 не менее 10 миллиметров в час.</p><br>

<p style="text-indent:2em;">d) регистрация пройденного расстояния, скорости транспортного средства и любого вскрытия
 корпуса, содержащего регистрационный листок или листки, должна быть автоматической.</p><br>

<p style="font-weight:500;">2. Регистрация пройденного расстояния</p><br>

<p style="text-indent:2em;">a) каждый километр пройденного расстояния должен быть отражен на записи в виде отклонения
 по крайней мере на один миллиметр в соответствующей системе координат.</p><br>

<p style="text-indent:2em;">b) даже на скоростях, приближающихся к верхнему пределу диапазона измерения, регистрация
 расстояний должна оставаться четкой.</p><br>

<p style="font-weight:500;">3. Регистрация скорости</p><br>

<p style="text-indent:2em;">а) независимо от формы регистрационного листка, перо самописца, регистрирующего скорость,
 должно обычно двигаться по прямой линии и под прямым углом по отношению к направлению
 движения регистрационного листка.</p>

<p style="text-indent:2em;"></p><h6>Однако движение пера самописца может быть криволинейным, если соблюдаются следующие
 условия:</h6><p></p>

<p style="text-indent:2em;">след, оставляемый пером самописца, должен быть перпендикулярным к средней окружности 
(для листков в форме диска) или к оси (для листков в форме ленты) зоны, предназначенной
 для регистрации скорости;</p><br>

<p style="text-indent:2em;">соотношение между радиусом кривой следа, оставленного пером самописца, и шириной зоны,
 предназначенной для регистрации скорости, должно быть не меньше чем 2,4 к 1, независимо 
 от формы регистрационного листка;</p><br>

<p style="text-indent:2em;">градуировка шкалы времени должна проходить через зону записи в форме кривой того же радиуса,
 что и след, оставляемый пером самописца. Расстояния между делениями на шкале времени должны
 соответствовать периоду, не превышающему один час.</p><br>

<p style="text-indent:2em;">b) каждое изменение скорости на 10 километров в час должно отражаться на записи в виде
 отклонения по крайней мере на 1,5 миллиметра в соответствующей системе координат.</p><br>

<p style="font-weight:500;">4. Регистрация времени</p>

<p style="text-indent:2em;"></p><h6>a) конструкция контрольного устройства должна быть таковой, чтобы период управления
 всегда регистрировался автоматически и чтобы можно было, в случае необходимости, при
 помощи прибора включения отдельно зарегистрировать другие периоды времени, 
 например:</h6><p></p>

<p>(I) под знаком - продолжительность управления;</p><br>

<p>(II) под знаком - все другие периоды работы;</p>

<p style="text-indent:2em;"></p><h6>(III) под знаком - другие периоды нахождения на рабочем месте, а именно:</h6><p></p>

<p style="text-indent:2em;">время ожидания, т.е. период, в течение которого водители должны оставаться на своих
 местах в ожидании сигналов к началу или возобновлению движения или к выполнению 
 другой работы;</p><br>

<p>время, проведенное рядом с водителем во время движения транспортного средства;</p><br>

<p>время, проведенное на спальном месте во время движения транспортного средства;</p><br>

<p>(IV) под знаком - перерывы в работе и периоды ежедневного отдыха.</p><br>

<p>Рисунки в базе не приводятся</p><br>

<p style="text-indent:2em;">Каждая Договаривающаяся Сторона может разрешить, чтобы все периоды, указанные в подпунктах 
(II) и (III) выше, регистрировались под знаком на регистрационных листках, используемых на
 транспортных средствах, которые зарегистрированы на ее территории.</p><br>

<p style="text-indent:2em;">b) необходимо, чтобы по характеристикам следов пера самописца, их взаимному расположению и
, при необходимости, по знакам, указанным в пункте 4 а), можно было провести четкое различие
 между разными периодами времени.</p><br>

<p style="text-indent:2em;">Разные периоды времени должны отличаться друг от друга на записи толщиной соответствующих
 следов или при помощи любой другой системы, имеющей по крайней мере такую же эффективность
 с точки зрения отчетливости и легкости расшифровки записи.</p><br>

<p style="text-indent:2em;">c) на транспортных средствах, экипаж которых состоит более чем из одного водителя, записи
, указанные в пункте 4 a), должны осуществляться на двух отдельных листках, причем каждый
 листок предназначен для одного водителя. В этом случае движение отдельных листков вперед
 должно обеспечиваться либо единым механизмом, либо отдельными синхронизированными
 механизмами.</p><br>

<p style="font-weight:500;">D. Закрывающее устройство</p><br>

<p style="text-indent:2em;">1. Корпус, содержащий регистрационный листок или листки и прибор управления механизмом
 запуска часов, должен быть оборудован замком.</p><br>

<p style="text-indent:2em;">2. Каждое вскрытие корпуса, содержащего регистрационный листок или листки и прибор 
управления механизмом запуска чатов, должно автоматически регистрироваться на листке
 или листках.</p><br>

<p style="font-weight:500;">Е. Маркировка</p>

<p style="text-indent:2em;"></p><h6>1. Ha циферблате контрольного устройства должна быть нанесена следующая маркировка:</h6><p></p>

<p style="text-indent:2em;">рядом с цифрой, показываемой счетчиком расстояния, - единица измерения расстояния,
 обозначенная сокращением "км";</p><br>

<p style="text-indent:2em;">рядом со шкалой скорости - знак "км/ч";</p><br>

<p style="text-indent:2em;">диапазон измерения спидометра - в форме "V мин. ... км/ч, V макс. ... км/ч". Эта
 маркировка не нужна, если она указана на табличке описания оборудования.</p><br>

<p style="text-indent:2em;">Однако эти требования не должны распространяться на контрольные устройства,
 официально утвержденные до 10 августа 1970 года.</p>

<p style="text-indent:2em;"></p><h6>2. Табличка описания должна быть наглухо закреплена на устройстве и должна иметь
 следующую маркировку, которая должна быть видимой при установке контрольного
 устройства:</h6><p></p>

<p>название и адрес завода-изготовителя оборудования;</p><br>

<p>номер, присвоенный заводом-изготовителем, и год изготовления;</p><br>

<p>знак официального утверждения типа контрольного устройства;</p><br>

<p>постоянная оборудования в форме "k = ... об./км" или k = ... имп./км";</p><br>

<p>факультативно: диапазон измерения скорости в форме, указанной в пункте 1;</p>

<p style="text-indent:2em;"></p><h6>если влияние чувствительности прибора к углу наклона на регистрируемые данные,
 поступающие с оборудования, может выходить за пределы допустимых отклонений,
 допустимый угол, выраженный следующим образом:</h6><p></p>

<p>Рисунок в базе не приводится</p><br>

<p style="text-indent:2em;">где альфа - угол, измеренный при горизонтальном положении лицевой части (обращенной вверх)
 оборудования, для которого калибруется прибор, альфа, бета и гамма являются,
 соответственно, максимальными допустимыми верхним и нижним отклонениями от
 угла калибрования альфа.</p><br>

<p style="font-weight:500;">F. Максимально допустимые отклонения (визуальные и регистрирующие приборы)</p>

<p style="text-indent:2em;"></p><h6>1. На испытательном стенде перед установкой:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) пройденное расстояние:</h6><p></p>

<p>на 1% больше или меньше реального расстояния, если это расстояние составляет не
 менее одного километра;</p>

<p style="text-indent:2em;"></p><h6>b) скорость:</h6><p></p>

<p>на 3 км/ч больше или меньше реальной скорости;</p>

<p style="text-indent:2em;"></p><h6>c) время:</h6><p></p>

<p style="text-indent:2em;">+- две минуты в день и максимум 10 минут за семь дней в случаях, если продолжительность
 времени хода часов после их повторной заводки не меньше этого периода.</p>

<p style="text-indent:2em;"></p><h6>2. После установки:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) пройденное расстояние:</h6><p></p>

<p>на 2% больше или меньше реального расстояния, если это расстояние составляет не
 менее одного километра;</p>

<p style="text-indent:2em;"></p><h6>b) скорость:</h6><p></p>

<p>на 4 км/ч больше или меньше реальной скорости;</p>

<p style="text-indent:2em;"></p><h6>c) время:</h6><p></p>

<p>+- две минуты в день или</p><br>

<p>+- 10 минут за семь дней.</p>

<p style="text-indent:2em;"></p><h6>3. В эксплуатации:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) пройденное расстояние:</h6><p></p>

<p>на 4% больше или меньше реального расстояния, если это расстояние составляет не
 менее одного километра;</p>

<p style="text-indent:2em;"></p><h6>b) скорость:</h6><p></p>

<p>на 6 км/ч больше или меньше реальной скорости;</p>

<p style="text-indent:2em;"></p><h6>c) время:</h6><p></p>

<p>+- две минуты в день или</p><br>

<p>+- 10 минут за семь дней.</p><br>

<p style="text-indent:2em;">4. Максимально допустимые отклонения, указанные в пунктах 1, 2 и 3, действительны
 для температур в диапазоне от 0°С до 40°C, причем температура измеряется в
 непосредственной близости от оборудования.</p><br>

<p style="text-indent:2em;">5. Измерение максимально допустимых отклонений, изложенных в пунктах 2 и 3
, должно производиться в условиях, указанных в главе VI.</p><br>

<p style="font-weight:500;">IV. Регистрационные листки</p><br>

<p style="font-weight:500;">А. Общие положения</p><br>

<p style="text-indent:2em;">1. Регистрационные листки должны быть такими, чтобы они не затрудняли нормальное
 функционирование прибора и чтобы содержащиеся на них записи были нестираемыми,
 легко читаемыми и четкими.</p><br>

<p style="text-indent:2em;">Регистрационные листки должны сохранять свои размеры и все занесенные на них
 записи при нормальных условиях влажности и температуры.</p>

<p style="text-indent:2em;"></p><h6>Кроме того, каждый член экипажа должен иметь возможность заносить на листки 
без их повреждения и ухудшения четкости записей следующую информацию:</h6><p></p>

<p>a) в начале использования листка: свои фамилию и имя;</p><br>

<p>b) дату и место начала использования листка и дату и место завершения его
 использования;</p><br>

<p style="text-indent:2em;">c) регистрационный номер каждого транспортного средства, для работы на
 котором он назначен, как в начале первой поездки, зарегистрированной на
 листке, так и впоследствии, в случае смены транспортного средства в 
 ходе использования листка;</p>

<p style="text-indent:2em;"></p><h6>d) показания одометра:</h6><p></p>

<p>в начале первой поездки, зарегистрированной на листке;</p><br>

<p>в конце последней поездки, зарегистрированной на листке;</p><br>

<p style="text-indent:2em;">в случае смены транспортного средства в течение рабочего дня (показание по
 транспортному средству, для работы на котором он был назначен, и показание
 по транспортному средству, для работы на котором он будет назначен);</p><br>

<p>e) время каждой смены транспортного средства.</p><br>

<p style="text-indent:2em;">При нормальных условиях хранения записи должны сохранять свою четкость по
 крайней мере в течение одного года.</p><br>

<p style="text-indent:2em;">2. Минимальная возможная продолжительность записи на листках, независимо
 от их формы, должна составлять 24 часа.</p><br>

<p style="text-indent:2em;">Если соединяются несколько дисков для повышения непрерывной возможной 
продолжительности записи, которая может быть обеспечена без вмешательства 
персонала, соединения между различными дисками должны осуществляться таким
 образом, чтобы не было перерывов записи, или ее наложений в момент перехода
 с одного диска на другой.</p><br>

<p style="font-weight:500;">В. Зоны записи и их градуирование</p>

<p style="text-indent:2em;"></p><h6>1. Регистрационные листки должны иметь следующие зоны записи:</h6><p></p>

<p>зона, предназначенная исключительно для данных, касающихся скорости;</p><br>

<p>зона, предназначенная исключительно для данных, касающихся пройденного
 расстояния;</p><br>

<p style="text-indent:2em;">одна или несколько зон для данных, касающихся времени управления, других 
периодов работы и нахождения на рабочем месте, перерывов в работе и периодов
 отдыха водителей.</p><br>

<p style="text-indent:2em;">2. Зона записи скорости должна быть разбита на деления по 20 километров в
 час или менее. Скорость, соответствующая каждому делению на шкале, должна
 быть указана в цифровом выражении против каждого деления. Знак "км/ч" должен
 быть проставлен в этой зоне по крайней мере один раз. Последнее деление шкалы
 должно совпадать с верхний пределом диапазона измерения.</p><br>

<p style="text-indent:2em;">3. Зона записи пройденного расстояния должна быть расположена таким образом,
 чтобы можно было легко снимать показания количества пройденных километров.</p><br>

<p style="text-indent:2em;">4. Зона или зоны, предназначенные для записи периодов, указанных в пункте 1,
 должны содержать такие знаки, которые позволяют четко определять различные
 периоды времени.</p><br>

<p style="font-weight:500;">С. Информация, которая должна заноситься на регистрационные листки</p>

<p style="text-indent:2em;"></p><h6>Каждый листок должен содержать следующую информацию в печатной форме:</h6><p></p>

<p>название и адрес или фирменное название завода-изготовителя;</p><br>

<p>знак официального утверждения образца листка;</p><br>

<p style="text-indent:2em;">знак официального утверждения типа или типов контрольных устройств, на которых
 может использоваться данный листок;</p><br>

<p>верхний предел диапазона измерения скорости, указанный в километрах в час.</p><br>

<p style="text-indent:2em;">В рамках минимальных дополнительных требований на каждый листок должна
 наноситься в печатной форме шкала времени, градуированная таким образом
 , чтобы показания времени можно было снимать непосредственно с интервалами
 по 15 минут, а определение любого пятиминутного интервала не вызывало
 трудностей.</p><br>

<p style="font-weight:500;">D. Свободное место для записей, вписываемых от руки</p>

<p style="text-indent:2em;"></p><h6>На листках должно иметься такое свободное пространство, чтобы водители
 могли вписать, как минимум, следующие данные:</h6><p></p>

<p>фамилию и имя водителя;</p><br>

<p>дату и место начала использования листка и дату и место завершения его
 использования;</p><br>

<p style="text-indent:2em;">регистрационный номер или номера транспортного средства или транспортных
 средств, для работы на которых назначается водитель в период использования
 листка;</p><br>

<p style="text-indent:2em;">показания одометра транспортного средства или транспортных средств, для работы
 на которых назначается водитель в период использования листка;</p><br>

<p>время любой смены транспортных средств.</p><br>

<p style="font-weight:500;">V. Установка контрольного устройства</p><br>

<p style="font-weight:500;">А. Общие положения</p><br>

<p style="text-indent:2em;">1. Контрольное устройство должно быть установлено на транспортном средстве таким
 образом, чтобы водитель мог со своего места наблюдать за показаниями спидометра,
 счетчика расстояния и часов и чтобы при этом все части этих приборов, включая
 приводные элементы, были защищены от случайной поломки.</p><br>

<p style="text-indent:2em;">2. Необходимо, чтобы постоянная контрольного устройства могла быть приведена в
 соответствие с характеристическим коэффициентом транспортного средства при помощи
 надлежащего приспособления, так называемого адаптера.</p><br>

<p style="text-indent:2em;">Транспортные средства с двумя или более передаточными числами заднего моста должны
 быть оборудованы переключающим устройством, которое автоматически приводит эти различные
 передаточные числа в соответствие с передаточным числом контрольного устройства,
 которое было установлено на данном транспортном средстве.</p><br>

<p style="text-indent:2em;">3. После проверки контрольного устройства, проводимой после его установки, на
 транспортном средстве рядом с устройством или на самом устройстве крепится табличка
 с данными об установке таким образом, чтобы ее было хорошо видно. После каждого
 осмотра, который проводится утвержденным механиком или мастерской, когда требуется
 изменение самой установки, на месте предыдущей таблички должна быть установлена
 новая табличка.</p>

<p style="text-indent:2em;"></p><h6>На табличке должны быть указаны по крайней мере следующие данные:</h6><p></p>

<p>фамилия/название, адрес или фирменное название утвержденного механика или
 мастерской;</p><br>

<p>характеристический коэффициент транспортного средства в форме "w = ... об.
/км" или "w = ... имп./км";</p><br>

<p>эффективная окружность шин колес в форме "L = ... мм";</p><br>

<p style="text-indent:2em;">даты определения характеристического коэффициента транспортного средства и
 измерения эффективной окружности шин колес.</p><br>

<p style="font-weight:500;">В. Наложение пломб</p>

<p style="text-indent:2em;"></p><h6>Пломбы накладываются на следующие части:</h6><p></p>

<p style="text-indent:2em;">a) табличка с данными об установке за исключением тех случаев, когда она
 установлена таким образом, что ее нельзя снять без уничтожения указанной
 на ней маркировки;</p><br>

<p>b) два конца соединения между самим контрольным устройством и транспортным
 средством;</p><br>

<p>c) непосредственно адаптер и участок его включения в цепь;</p><br>

<p style="text-indent:2em;">d) переключающий механизм для транспортных средств с двумя или более
 передаточными числами ведущего моста;</p><br>

<p>e) участки, соединяющие адаптер и переключающий механизм с остальной 
частью контрольного устройства;</p><br>

<p>f) корпуса, оговоренные в пункте 7 b) части А главы III.</p><br>

<p style="text-indent:2em;">В особых случаях в ходе официального утверждения типа контрольного устройства
 может требоваться установка дополнительных пломб, в этом случае в свидетельстве
 официального утверждения должна производиться запись об установке этих пломб.</p><br>

<p style="text-indent:2em;">В чрезвычайных случаях могут быть сняты только те пломбы, которые указаны в 
подпунктах b), с) и е); в каждом отдельном случае нарушения этих пломб должно
 составляться и представляться компетентному органу письменное заявление, в
 котором указываются причины такого действия.</p><br>

<p style="font-weight:500;">VI. Проверки и инспекции</p><br>

<p style="font-weight:500;">Договаривающаяся Сторона назначает органы, которые проводят проверки и
 инспекции.</p><br>

<p style="font-weight:500;">1. Утверждение новых или восстановленных приборов</p><br>

<p style="text-indent:2em;">Каждое отдельное новое или восстановленное устройство должно быть освидетельствовано
 с точки зрения правильности его функционирования и точности его показаний и записей
 в пределах, изложенных в пункте 1 части F главы III, путем наложения пломб в 
 соответствии с пунктом f) части В главы V.</p><br>

<p style="text-indent:2em;">С этой целью Договаривающаяся Сторона может оговаривать первоначальную проверку,
 состоящую из проверки и подтверждения соответствия нового или восстановленного 
 устройства, официально утвержденному образцу и/или требованиям настоящего 
 приложения и добавлений к нему, или может делегировать полномочия по 
 освидетельствованию заводам-изготовителям или их официально уполномоченным
 агентам.</p><br>

<p style="font-weight:500;">2. Установка</p><br>

<p style="text-indent:2em;">После монтажа на транспортном средстве контрольное устройство и вся установка
 должны соответствовать положениям, касающимся максимально допустимых отклонений,
 которые изложены в пункте 2, части F главы III.</p><br>

<p style="text-indent:2em;">Инспекционные испытания должны проводиться утвержденным механиком или мастерской
 под их ответственность.</p><br>

<p style="font-weight:500;">3. Периодические инспекции</p><br>

<p style="text-indent:2em;">a) периодические инспекции контрольного устройства, установленного на транспортных
 средствах, должны проводиться не реже одного раза в два года, они могут проводиться
 одновременно с испытаниями транспортных средств на пригодность к эксплуатации 
 на дорогах.</p>

<p style="text-indent:2em;"></p><h6>Эти инспекции должны включать следующие проверки:</h6><p></p>

<p>правильность работы контрольного устройства;</p><br>

<p>наличие на контрольном устройстве знака официального утверждения типа;</p><br>

<p>наличие таблички с данными;</p><br>

<p>целостность пломб на контрольном устройстве и на других частях;</p><br>

<p>эффективная окружность шин колес.</p><br>

<p style="text-indent:2em;">b) Инспекция в целях проверки соблюдения положения пункта 3 части F главы III
 o максимально допустимых отклонениях в ходе эксплуатации должна проводиться не
 реже одного раза в шесть лет, хотя каждая Договаривающаяся Сторона может установить
 более короткие интервалы для проведения таких инспекций транспортных средств,
 зарегистрированных на ее территории. В ходе таких инспекций должна проводиться
 замена таблички с данными об установке.</p><br>

<p style="font-weight:500;">4. Измерение погрешностей</p>

<p style="text-indent:2em;"></p><h6>Измерение погрешностей при установке или в ходе эксплуатации должно проводиться
 при следующих условиях, которые должны рассматриваться как стандартные
 условия испытаний:</h6><p></p>

<p>транспортное средство в снаряженном состоянии без груза;</p><br>

<p>давление в шинах соответствует инструкциям завода-изготовителя;</p><br>

<p>износ шин в пределах разрешенных законодательством;</p><br>

<p style="text-indent:2em;">движение транспортного средства: транспортное средство, приводимое в движение
 собственным двигателем, должно двигаться прямолинейно по ровной поверхности
 со скоростью 50 +- 5 км/ч; испытание может также проводиться на соответствующем
 испытательном стенда при условии обеспечения аналогичной точности.</p>

<p></p><h4>Приложение - добавление 2</h4><p></p>

<p></p><h4>Знак и свидетельство официального утверждения</h4><p></p>

<p style="font-weight:500;">I. Знак официального утверждения</p><br>

<p style="font-weight:500;">II. Свидетельство официального утверждения</p><br>

<p style="font-weight:500;">I. Знак официального утверждения</p>

<p style="text-indent:2em;"></p><h6>1. Знак официального утверждения состоит из:</h6><p></p>

<p style="text-indent:2em;"></p><h6>прямоугольника, в котором проставлена буква "е", за которой следует указанный
ниже общепринятый отличительный номер страны, предоставившей официальное 
утверждение:</h6><p></p>

<p>Германия                - 1</p><br>                

<p>Румыния              - 19</p><br>

<p>Франция                 - 2</p><br>          

<p>Польша               - 20</p><br>

<p>Италия                  - 3</p><br>                

<p>Португалия           - 21</p><br>

<p>Нидерланды              - 4</p><br>                

<p>Российская Федерация - 22</p><br>

<p>Швеция                  - 5</p><br>                

<p>Греция               - 23</p><br>

<p>Бельгия                 - 6 </p><br>               

<p>Ирландия             - 24</p><br>

<p>Чешская Республика      - 8 </p><br>               

<p>Хорватия             - 25</p><br>

<p>Испания                 - 9 </p><br>               

<p>Словения             - 26</p><br>

<p>Югославия               - 10 </p><br>              

<p>Словакия             - 27</p><br>

<p>Соединенное Королевство - 11</p><br>               

<p>Беларусь             - 28</p><br>

<p>Австрия                 - 12</p><br>               

<p>Эстония              - 29</p><br>

<p>Люксембург              - 13</p><br>               

<p>Республика Молдова   - 30</p><br>

<p>Норвегия                - 16</p><br>               

<p>Босния и Герцеговина - 31</p><br>

<p>Дания                   - 18</p><br>               

<p>Латвия               - 32</p>

<p style="text-indent:2em;"></p><h6>Последующие порядковые номера будут присваиваться:</h6><p></p>

<p style="text-indent:2em;">I) странам - Договаривающимся Сторонам Соглашения 1958 года о принятии единообразных
 условий официального утверждения и о взаимном признании официального утверждения предметов,
 оборудования и частей механических транспортных средств - те же номера, которые были присвоены
 этим странам на основании данного Соглашения;</p><br>

<p style="text-indent:2em;">II) странам, не являющимся Договаривающимися Сторонами Соглашения 1958 года, - в хронологическом
 порядке ратификации ими этого Соглашения или в порядке их присоединения к нему и номера официального утверждения, соответствующего номеру свидетельства официального утверждения,
 который присваивается прототипу контрольного устройства, или регистрационного листка, и располагаемого
 в любой точке в непосредственной близости от этого прямоугольника.</p>

<p style="text-indent:2em;"></p><h6>Примечание: </h6><p></p>

<p style="text-indent:2em;">Для обеспечения в будущем соответствия между общепринятыми отличительными номерами,
 предусмотренными в Соглашении 1958 года, и номерами, использующимися в Соглашении ЕСТР, новым
 Договаривающимся Сторонам должен присваиваться одинаковый номер в обоих Соглашениях.</p><br>

<p style="text-indent:2em;">2. Знак официального утверждения проставляется на табличке описания каждого контрольного устройства
 и каждого регистрационного листка. Он должен быть нестираемым и всегда оставаться четким.</p><br>

<p style="text-indent:2em;">3. Размеры знака официального утверждения, показанные ниже, выражены в миллиметрах, эти
 размеры являются минимальными. Соотношения между размерами должны сохраняться.</p><br>

<p>Рисунок в базе не приводится</p><br>

<p style="font-weight:500;">II. Свидетельство официального утверждения</p><br>

<p style="text-indent:2em;">Договаривающаяся Сторона, предоставившая официальное утверждение, выдает Стороне,
 подавшей заявку, свидетельство официального утверждения, образец которого приведен
 ниже. При сообщении другим Договаривающимся Сторонам о предоставлении или,
 в соответствующих случаях, отмене официальных утверждений Договаривающаяся
 Сторона должна использовать копии этого свидетельства.</p><br>

<p style="text-indent:2em;">Европейское соглашение,касающееся работы экипажей транспортных средств,
производящих международные автомобильные перевозки (ЕСТР) (Женева, 1 июля 1970 г.)</p><br>

<p><a href="http://www.businesspravo.ru/docum/documshow_documid_36593.html">Источник</a></p>

</div>
`