export const NEW_LINE = `
`
export const DEFAULT_LANG = 'ru'
export const DEFAULT_TIMEZONE = 'E. Europe Standard Time'

export const AUTH_MODES = {
  LOGIN: 'LOGIN',
  REGISTRATION: 'REGISTRATION',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  REMIND_PASSWORD: 'REMIND_PASSWORD',
}

export const SOCKET_NEW_LOGIN_ATTEMPT_THRESHOLD = 30 //60
export const MODE_TIME_REMAINED = 'MODE_TIME_REMAINED'
export const MODE_TIME_ELAPSED = 'MODE_TIME_ELAPSED'

export const FORM_FIELD_ERROR_POSITION_TOP = 'top'
export const FORM_FIELD_ERROR_POSITION_BOTTOM = 'bottom'

export const NUMBER_OF_ROWS_TO_INSERT_AD = 10

export const LOGIN_SUSPENDED_ASKED_FOR_PERMISSION = 'LOGIN_SUSPENDED_ASKED_FOR_PERMISSION'
export const LOGIN_SUCCESSFULLY_LOGGED_IN = 'LOGIN_SUCCESSFULLY_LOGGED_IN'
export const LOGIN_PERMISSION_STATUS_ALLOWED = 'ALLOWED'
export const LOGIN_PERMISSION_STATUS_DENIED = 'DENIED'

export const ALLOWED_EXTENSIONS_IMAGE = ['jpeg', 'jpg', 'png', 'gif']

export const PROFILE_MODE_REGISTRATION = 'PROFILE_MODE_REGISTRATION'
export const PROFILE_MODE_EDIT = 'PROFILE_MODE_EDIT'

export const MENU_LINK = 'MENU_LINK'
export const MENU_DROPDOWN = 'MENU_DROPDOWN'
export const MENU_DROPDOWN_INLINE = 'MENU_DROPDOWN_INLINE'
export const MENU_DROPDOWN_ITEM = 'MENU_DROPDOWN_ITEM'
export const MENU_CHECKBOX = 'MENU_CHECKBOX'
export const MENU_CHECKBOXES = 'MENU_CHECKBOXES'
export const MENU_SWITCH = 'MENU_SWITCH'

export const PRICE_TYPE_MY_PRICE = 1 // 'my-price'
export const PRICE_TYPE_REQUEST = 2 //'request'
export const PRICE_TYPE_AGREEMENT = 3 //'agreement'

export const PRICE_RECURRING_PER_TRIP = 1 // trip
export const PRICE_RECURRING_PER_KM = 2 // km
export const PRICE_RECURRING_PER_TON = 3 // ton
export const PRICE_RECURRING_PER_HOUR = 4 // hour

export const LIST_LAYOUT_ITEM = 1
export const LIST_LAYOUT_GRID = 2

export const REVIEW_VERY_GOOD = 5
export const REVIEW_GOOD = 4
export const REVIEW_OK = 3
export const REVIEW_BAD = 2
export const REVIEW_VERY_BAD = 1

 export const COMPANY_PAYMENT_INCIDENTS = 1
 export const COMPANY_TRANSPORT_INCIDENTS = 2
 export const COMPANY_WORK_INCIDENTS = 3
 export const COMPANY_OTHERS_CLAIMS = 4

export const USER_PHOTO_URL_PLACEHOLDER = '/templates/images/content/user.jpg'

export const BANNER_BIG_SIZE = '728x90'
export const BANNER_THIN_SIZE = '468x60'
export const BANNER_WIDE_SIZE = '300x250'
export const BANNER_SMALL_SIZE = '320x100'
export const formatDDMMYYYY = 'DD.MM.YYYY'

export const CAPTCHA_SITE_KEY = '6LdyFdISAAAAABr-GfMZyzXRpGIiEQyRWRiZveCz'

export default {
  NEW_LINE,
  AUTH_MODES,

  SOCKET_NEW_LOGIN_ATTEMPT_THRESHOLD,
  
  NUMBER_OF_ROWS_TO_INSERT_AD,

  // -------------------
  // same contacts as in constants.js in api
  LOGIN_SUSPENDED_ASKED_FOR_PERMISSION,
  LOGIN_SUCCESSFULLY_LOGGED_IN,
  // -------------------

  LOGIN_PERMISSION_STATUS_ALLOWED,
  LOGIN_PERMISSION_STATUS_DENIED,
}

export const VIDEO_PROVIDERS = [
  {
    name: 'youtube',
    url: [
      /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
      // /^(?:m\.)?youtube\.com\/watch\?v=([\w-]+)/,
      // /^(?:m\.)?youtube\.com\/v\/([\w-]+)/,
      // /^youtube\.com\/embed\/([\w-]+)/,
      // /^youtu\.be\/([\w-]+)/,
    ],
    html: match => {
      const id = match[1]

      return `
        <div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">
          <iframe src="https://www.youtube.com/embed/${id}"
            style="position: absolute; width: 480px; height: 270px; top: 0; left: 0;"
            frameBorder="0" allow="autoplay; encrypted-media" allowFullscreen>
          </iframe>
        </div>`

      // <div style="position: relative;">
      //   <iframe src="https://www.youtube.com/embed/${id}"
      //     style="width: 100%; height: 100%; top: 0; left: 0;"
      //     frameBorder="0" allow="autoplay; encrypted-media" allowFullscreen>
      //   </iframe>
      // </div>`
    },
  },
  {
    name: 'dailymotion',
    url: /^(?:https?:\/\/)?(?:www\.)dailymotion\.com\/video\/(\w+)/,
    html: match => {
      const id = match[1]
      return `
        <div style="position: relative; padding-bottom: 100%; height: 0; ">
          <iframe src="https://www.dailymotion.com/embed/video/${id}"
            style="position: absolute; width: 480px; height: 270px; top: 0; left: 0;"
            frameBorder="0" allowFullscreen allow="autoplay">
          </iframe>
        </div>`
    },
  },
  {
    name: 'spotify',
    url: [
      /^(?:https?:\/\/)open\.spotify\.com\/(artist\/\w+)/,
      /^(?:https?:\/\/)open\.spotify\.com\/(album\/\w+)/,
      /^(?:https?:\/\/)open\.spotify\.com\/(track\/\w+)/,
    ],
    html: match => {
      const id = match[1]
      return `
        <div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 126%;">
          <iframe src="https://open.spotify.com/embed/${id}"
            style="position: absolute; width: 480px; height: 270px; top: 0; left: 0;"
            frameBorder="0" allowTransparency="true" allow="encrypted-media">
          </iframe>
        </div>`
    },
  },
  {
    name: 'vimeo',
    url: [
      /^(?:https?:\/\/)vimeo\.com\/(\d+)/,
      /^(?:https?:\/\/)vimeo\.com\/[^/]+\/[^/]+\/video\/(\d+)/,
      /^(?:https?:\/\/)vimeo\.com\/album\/[^/]+\/video\/(\d+)/,
      /^(?:https?:\/\/)vimeo\.com\/channels\/[^/]+\/(\d+)/,
      /^(?:https?:\/\/)vimeo\.com\/groups\/[^/]+\/videos\/(\d+)/,
      /^(?:https?:\/\/)vimeo\.com\/ondemand\/[^/]+\/(\d+)/,
      /^(?:https?:\/\/)player\.vimeo\.com\/video\/(\d+)/,
    ],
    html: match => {
      const id = match[1]
      return `
        <div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">
          <iframe src="https://player.vimeo.com/video/${id}"
            style="position: absolute; width: 480px; height: 270px; top: 0; left: 0;"
            frameBorder="0" webkitAllowFullscreen mozAllowFullscreen allowFullscreen>
          </iframe>
        </div>`
    },
  },

  {
    name: 'instagram',
    url: [
      /^(?:https?:\/\/)?(?:www\.)instagram\.com\/p\/(\w+)\/embed/,
      /^(?:https?:\/\/)?(?:www\.)instagram\.com\/p\/(\w+)/,
    ],
    html: match => {
      const id = match[1]
      return `
        <div style="position: relative; padding-bottom: 100%; max-height: 480px; padding-bottom: 56.2493%;">
          <iframe src="https://www.instagram.com/p/${id}/embed"
            style="width: 480px; height: 270px;"
            frameBorder="0" webkitAllowFullscreen mozAllowFullscreen allowFullscreen>
          </iframe>
        </div>`
    },
  },
  {
    name: 'twitter',
    url: /^(?:https?:\/\/)?(?:www\.)twitter\.com/,
  },
  {
    name: 'googleMaps',
    url: /^(?:https?:\/\/)?(?:www\.)google\.com\/maps/,
  },
  {
    name: 'flickr',
    url: /^(?:https?:\/\/)?(?:www\.)flickr\.com/,
  },
  {
    name: 'facebook',
    url: /(^(?:https?:\/\/)(?:www\.)facebook\.com\/)((.+)videos\/([0-9]+)\/)/,
    html: match => {
      const id = match[2]

      return `
        <div style="position: relative; min-height:270px">
        <iframe src="https://www.facebook.com/plugins/video.php?href=https://www.facebook.com/${id}show_text=0"
         style="border:none;overflow:hidden;width:480px;" 
         scrolling="no" frameborder="0" allowFullScreen="true"
         ></iframe>
        </div>
      `
    },
  },
  {
    name: 'play.md',
    url: [
      /^(?:https?:\/\/)play\.md\/point\/videos\/(\w+)/,
      //https://play.md/embed/3359329?title=false&autoplay=false
      /^(?:https?:\/\/)play\.md\/embed\/(\w+)\?title=(\w+)&autoplay=(\w+)/,
      /^(?:https?:\/\/)play\.md\/embed\/(\w+)\?title=(\w+)&amp;autoplay=(\w+)/,
      /^(?:https?:\/\/)play\.md\/(\w+)/,
    ],
    html: match => {
      const id = match[1]
      return `
        <div style="position: relative;">
          <iframe src="https://play.md/embed/${id}?title=false&autoplay=false"
            frameBorder="0" width="480" height="270" allowFullscreen allow="autoplay">
          </iframe>
        </div>`
    },
  },
  {
    name: 'vk.com',
    // TODO: calc hash and parse original url
    // original: https://vk.com/video-24199209_456265864
    // embedded: https://vk.com/video_ext.php?oid=-24199209&id=456265864&hash=00eb97c853ecc81c
    url: /^(?:https?:\/\/)vk\.com\/(.+)/,
    html: match => {
      const id = match[1]
      return `
        <div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">
          <iframe src="https://vk.com/${id}"
            style="position: absolute; width: 480px; height: 270px; top: 0; left: 0;"
            frameBorder="0" webkitAllowFullscreen mozAllowFullscreen allowFullscreen>
          </iframe>
        </div>`
    },
  },
]
