import { compose } from 'recompose'
import { connect } from 'react-redux'

import Component from './component'
import withTranslation from '../../globals/components/withTranslation'
import withPermissions from '../../globals/components/withPermissions'
import { IReduxStorage } from '../../store/reducers/types'

export default compose(
  withPermissions,
  withTranslation,
  connect((state: IReduxStorage) => {
    return {
      lastLogin: state.auth.profile.getIn(['data', 'lastLogin']),
      langId: state.ui.langId,
    }
  })
)(Component)
