export const int8 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>CLASIFICAREA MIJLOACELOR DE TRANSPORT</h4><p></p>

<p style="text-indent:2em;">Încă de la începuturile ei, omenirea a trebuit să se deplaseze şi să transporte cu sine bunurile deţinute. Deplasările au crescut în intensitate odată cu creşterea numărului de oameni, în timp
 ajungându-se la adevărate reţele de transport în special pentru mărfuri.</p><br>

<p style="text-indent:2em;">Odată cu progresul tehnicii, mijloacele de transport au sporit în diversitate şi complexitate, fiecare mediu (aerul, apa, pământul) fiind străbătut de o multitudine de căi şi vehicule.</p><br>

<p style="text-indent:2em;">Tehnic vorbind, vehiculul poate fi definit ca: un sistem care, deplasându-se prin rulare, alunecare sau plutire, transportă, dintr-un loc în altul, mărfuri sau persoane, folosind o cale
 de comunicaţie terestră, aeriană, acvatică.</p><br>

<p style="text-indent:2em;">Căile de transport, indiferent de natura mediului în care se află, sunt trasee amenajate (şosele, căi ferate) sau reglementate prin convenţii internaţionale, în cazul liniilor aeriene, maritime sau spaţiale.</p>

<p style="text-indent:2em;"></p><h6>1. După calea de transport pe care se deplasează, vehiculele se clasifică în:</h6><p></p>

<p>mijloace de transport terestre: trenuri, camioane, autocisterne.</p><br>

<p>mijloace de transport pe apă: vapoare, cargouri, pacheboturi.</p><br>

<p>mijloace de transport aerospaţiale: avioane, elicoptere, navete spaţiale.</p><br>

<p>mijloace de transport speciale: pe cablu: telefericul; prin conducte: metroul.</p>

<p style="text-indent:2em;"></p><h6>2. După modul cum se realizează propulsia, vehiculele pot fi împărţite astfel:</h6><p></p>

<p>mijloace de transport ce se deplasează datorită forţei unui motor: autovehicule.</p><br>

<p>mijloace de transport ce se deplasează cu ajutorul forţei umane: bicicleta, barca cu vâsle.</p><br>

<p>mijloace de transport ce se deplasează cu ajutorul forţei animale: căruţa, sania.</p>

<p style="text-indent:2em;"></p><h6>3. După destinaţia pentru care sunt folosite, vehiculele pot fi:</h6><p></p>

<p>vehicule pentru transport persoane: avioane, trenuri, autoturisme.</p><br>

<p>vehicule pentru transport mărfuri: trenuri de marfă, autocamioane, avioane.</p><br>

<p>vehicule speciale: maşini de pompieri, automacarale, tractoare.</p>

<p style="text-indent:2em;"></p><h6>4. După destinaţia până la care se efectuează transportul, vehiculele se împart în:</h6><p></p>

<p>mijloace de transport pe distanţe mari: trenuri, autocare, avioane.</p><br>

<p>mijloace de transport pe distanţe scurte: autobuze, tramvaie, metrou, autoturisme.</p><br>

<p>mijloace de transport extraterestre: rachetele, navetele spaţiale.</p><br>

<p style="text-indent:2em;">În ziua de azi, proiectarea de noi mijloace de transport trebuie să ţină seama de constrângerile impuse de protejarea mediului ambiant, atât cu privire la
 combustibilul utilizat în deplasare, cât şi materialele folosite în construcţia lor.</p><br>
 
 <p><a href="http://christianferoviarul.blogspot.com/2010/08/clasificarea-mijloacelor-de-transport.html">Sursa</a></p>



</div>
`