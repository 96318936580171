import React, { Component } from 'react'
import { Field, InjectedFormProps } from 'redux-form'
import { confirmAlert } from 'react-confirm-alert'
import { toastr } from 'react-redux-toastr'
import { match } from 'react-router'
import FlexBand from 'flexband'

import WidgetBase from '../../shared/components/widget-base'
import { required } from '../../shared/form-validators'
import Dropdown from '../../UI/dropdown'
import fetch from '../../shared/fetch'

import './style.scss'

interface IAddAdminNoticeProps extends ComponentBaseProps, InjectedFormProps {
  match: match<{ userId }>
  textCounter: number
}

interface IAddAdminNoticeState {
  showConfirm: boolean
}

const maxTitleLength = 100
const maxTextLength = 500

export default class extends Component<IAddAdminNoticeProps, IAddAdminNoticeState> {
  constructor(props) {
    super(props)

    this.state = {
      showConfirm: false,
    }
  }

  clickDelete = () => {
    const { t } = this.props
    this.setState({ showConfirm: true })
    confirmAlert({
      title: t('delete.confirm'),
      message: (
        <span>
          {t('delete.sure')}
          <br />
          {t('delete.cannot.undone')}
        </span>
      ) as any,
      buttons: [
        {
          label: t('delete'),
          onClick: () => {
            const { userId } = this.props.match.params
            this.hideConfirm()
            this.setState({ showConfirm: !this.state.showConfirm })
            fetch('/user/deleteAdminNotice', {
              method: 'DELETE',
              body: JSON.stringify({
                userId,
              }),
            }).then(response => {
              toastr.success(t('admin.notice.delete.success'))
            })
          },
        },
        {
          label: t('button.close'),
          onClick: this.hideConfirm,
        },
      ],
      onClickOutside: this.hideConfirm,
      onKeypressEscape: this.hideConfirm,
    })
  }
  hideConfirm = () => {
    this.setState({ showConfirm: false })
  }

  render() {
    const { t, handleSubmit, pristine, submitting, invalid, textCounter } = this.props

    const placementPeriod = [
      { name: t('day'), value: 1 },
      { name: t('week'), value: 7 },
      { name: t('month'), value: 30 },
      { name: t('year'), value: 365 },
      { name: t('termless'), value: 1e6 },
    ]

    return (
      <WidgetBase title={t('admin.notice.add')} className="admin-notice-add-panel">
        <form onSubmit={handleSubmit} className="panel-content-row">
          <div className="panel-form-row">
            <label>{t('title')}</label>
            <Field
              name="title"
              component="input"
              type="text"
              placeholder={t('validation.maxLength', { max: maxTitleLength })}
              maxLength={maxTitleLength}
              validate={required}
            />
          </div>
          <div className="panel-form-row">
            <label>
              {t('admin.notice')}
              <span className="col-gray length-countdown fl-right">
                <span className="count">{textCounter}</span>
                {` / `}
                <span className="length">{maxTextLength}</span>
              </span>
            </label>
            <Field
              name="text"
              component="textarea"
              placeholder={t('validation.maxLength', { max: maxTextLength })}
              maxLength={maxTextLength}
              validate={required}
            />
          </div>
          <div className="panel-form-row ">
            <FlexBand justify="space-between" alignItems="center">
              <div className="placementPeriod">
                <label>{t('ads.terms')}</label>
                <Field
                  name="placementPeriod"
                  component={Dropdown}
                  items={placementPeriod}
                  validate={required}
                  placeholder={t('choose')}
                />
              </div>
              <button
                className="button button-orange"
                type="submit"
                disabled={pristine || submitting || invalid}
              >
                {t('add')}
              </button>
            </FlexBand>
          </div>
          <div className="panel-content-row " />
          <div className="panel-form-row ">
            <a href="#" onClick={this.clickDelete}>
              {t('admin.notice.delete')}
            </a>
          </div>
        </form>
      </WidgetBase>
    )
  }
}
