import React from 'react'
import { Field } from 'redux-form'
import uuid from 'uuid/v4'

import Widget from '../shared/components/widget'
import RoutingService from '../shared/routingService'
import fetch from '../shared/fetch'
import { getUrlParamsString } from '../../store/utils'
import weights from '../../files/weights.pdf'
import dimensions from '../../files/dimensions.pdf'

import "./style.scss"

export default class CalcDistance extends React.Component {
  id = `id${uuid()}`
  routingService = new RoutingService()

  state = {
    placeFrom: '',
    placeTo: '',
    distance: 0,
    fromError: '',
    toError: '',
  }

  getCoordsByStrings = () => {
    const { from, to, langId, t } = this.props
    const queryString = getUrlParamsString({
      cityFrom: from,
      cityTo: to,
      langId,
    })

    fetch(`/common/getCoordsForDistance?${queryString}`).then(res => {
      const { placeFrom, placeTo, coordsFrom, coordsTo } = res.data
      this.setState({
        placeFrom,
        placeTo,
      })

      if (coordsFrom && coordsTo) {
        const coords = [coordsFrom.split('/').reverse(), coordsTo.split('/').reverse()]

        this.routingService.getDistance(coords).then(distance => {
          this.setState({ distance, fromError: '', toError: '' })
        })
      } else {
        const fromError = !coordsFrom ? t('place.not.found', { emptyPlace: from }) : ''
        const toError = !coordsTo ? t('place.not.found', { emptyPlace: to }) : ''

        this.setState({ fromError, toError, distance: 0 })
      }
    })
  }

  render() {
    const { t, from, to, dataMoveForMobile } = this.props
    const { placeFrom, placeTo, distance, fromError, toError } = this.state
    return (
      <Widget title={t('calc.distance')} {...{ dataMoveForMobile }}>
        <div className="form">
          <div className="input-row padding-t-r-l">
            <label htmlFor={`${this.id}1`}>{t('city.from')}</label>
            <div className="input-search">
              <Field name="from" id={`${this.id}1`} component="input" type="text" />
              <span className="icon-search">
                <i className="fa fa-search" />
              </span>
            </div>
          </div>
          {fromError && <div className="distance-result red">{fromError}</div>}
          <div className="input-row padding-t-r-l no-top-padding">
            <label htmlFor={`${this.id}2`}>{t('city.to')}</label>
            <div className="input-search">
              <Field name="to" id={`${this.id}2`} component="input" type="text" />
              <span className="icon-search">
                <i className="fa fa-search" />
              </span>
            </div>
          </div>
          {toError && <div className="distance-result red">{toError}</div>}
          {distance > 0 && (
            <div className='distance-result'>
              {t('maps.distance')} {t('from')} {placeFrom} {t('to')} {placeTo}:{' '}
              <b>
                {distance} {t('unit.distance.km')}
              </b>
            </div>
          )}

          <button
            type="button"
            disabled={!from || !to}
            className="button button-orange button-centered"
            onClick={this.getCoordsByStrings}
          >
            {t('calculate')}
          </button>

          <div className="panel-content-row">
            <div className="link">
              <a href={weights} download="weights">
                {t('home.table.truck.weights')}
              </a>
            </div>
            <div className="link">
              <a href={dimensions} download="dimensions">
                {t('home.table.truck.sizes')}
              </a>
            </div>
          </div>


        </div>
      </Widget>
    )
  }
}
