import React, { Component } from 'react'
import { Field } from 'redux-form'

import Dropdown from '../../../UI/dropdown'
import dict from '../../../shared/dictionaries'
import { Redirect } from 'react-router-dom'

interface SearchWidgetProps extends ComponentBaseProps {
  activeSearchType: SearchType
  countryFrom: number
  countryTo: number
}

interface SearchWidgetState {
  activeItemId: number
  redirectPage: string
}

interface IWidgetText {
  title: string
  text: string
}

export default class extends Component<SearchWidgetProps, SearchWidgetState> {
  items: IWidgetText[]
  intervalId: NodeJS.Timeout

  constructor(props) {
    super(props)
    this.state = {
      activeItemId: 0,
      redirectPage: '',
    }
    const { t } = this.props
    this.items = [
      {
        title: 'home.find.cargo',
        text: 'home.find.cargo.text',
      },
      {
        title: 'home.find.transport',
        text: 'home.find.transport.text',
      },
    ]
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      let activeItemId = this.state.activeItemId + 1
      if (activeItemId === this.items.length) {
        activeItemId = 0
      }
      this.setState({ activeItemId })
    }, 5000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  handleStartSearchClick = () => {
    const { countryFrom, countryTo, activeSearchType } = this.props
    let redirectPage = `/${activeSearchType}/search`

    if (countryFrom || countryTo) {
      redirectPage += '?'
    }

    if (countryFrom) {
      redirectPage += `from=${countryFrom}`

      if (countryTo) {
        redirectPage += '&'
      }
    }

    if (countryTo) {
      redirectPage += `to=${countryTo}`
    }

    this.setState({ redirectPage })
  }

  render() {
    const { t, langId, activeSearchType } = this.props
    const { activeItemId, redirectPage } = this.state
    const itemsToFind = [
      { name: t('home.cargo.transportation'), value: 'cargo' },
      { name: t('home.transportation'), value: 'transport' },
    ]
    let fromVariant: string, toVariant: string
    if (activeSearchType === 'cargo') {
      fromVariant = 'CO'
      toVariant = 'CI'
    } else {
      fromVariant = 'TO'
      toVariant = 'TI'
    }

    if (redirectPage) {
      return <Redirect to={redirectPage} />
    }

    return (
      <div className="panel panel-main">
        <div className="panel-main-header">
          <span className="ico ico-cargo" />
          <h2>{t(this.items[activeItemId].title)}</h2>
          <p>{t(this.items[activeItemId].text)}</p>
        </div>
        <div className="panel-select">
          <div className="row">
            <div className="col-lg">
              <label>{t('find')}</label>
              <Field name="what" component={Dropdown} items={itemsToFind} />
            </div>
            <div className="col-lg">
              <label>{t('from2')}</label>
              <Field
                name="from"
                component={Dropdown}
                items={dict.countries(langId, fromVariant)}
                emptyOption={t('all.countries')}
              />
            </div>
            <div className="col-lg">
              <label>{t('in')}</label>
              <Field
                name="to"
                component={Dropdown}
                items={dict.countries(langId, toVariant)}
                emptyOption={t('all.countries')}
              />
            </div>
            <div className="col-lg">
              <button
                type="button"
                className="button button-orange button-block"
                onClick={this.handleStartSearchClick}
              >
                {t('home.lucky')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
