import React, { Component } from 'react'
import fetch from '../../../../../components/shared/fetch'
import { TITLE_MAX_LENGTH, DESCRIPTION_MAX_LENGTH } from '../../forum-constants'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { canDo } from '../../../../../store/utils'
import Captcha from '../../../../captcha'

import './forum-modal-compliant.scss'

interface ModalState extends ComponentBaseProps {
  closeModal: any
  commentId: number
  captchaVerified?: boolean
  permissions?: any
}

export default class ForumAddThemeModal extends Component<ModalState> {
  state = {
    sections: [],
    postTitle: '',
    postDescription: '',
    sectionId: 0,
    error: false,
    captchaVerified: false,
  }

  componentDidMount() {
    const { permissions } = this.props
    if (canDo(permissions, 'captcha', 'skip')) {
      this.setState({ captchaVerified: true })
    }

    fetch(`/forum/getAllSections`).then(sections => {
      sections.data.forEach(({ id, by_default }) => {
        if (by_default === 1) {
          this.setState({ sectionId: id }, () => {
            this.fetchForumSections()
          })
        }
      })
    })
  }

  fetchForumSections = () => {
    fetch(`/forum/getAllSections`).then(sections => {
      this.setState({ sections: sections.data })
    })
  }

  renderSelectOptions = () => {
    const { sections } = this.state

    const renderOptions = [
      ...sections.map(({ id, title }) => ({
        value: id,
        name: title,
      })),
    ]

    return renderOptions
  }

  setTextFieldState = (stateName, e) => {
    this.setState({ [stateName]: e.target.value })
  }

  /*onSubmit = () => {
    const { closeModal } = this.props;
    const { postTitle, postDescription, sectionId } = this.state;
    const body = JSON.stringify({
        title: postTitle,
        message: postDescription,
        sectionId,
    })

    this.setState({  postTitle: '', postDescription: ''  }) 
    fetch('/forum/addTheme', {
        method: 'POST',
        body
    });
    closeModal();
  }*/

  render() {
    const { closeModal, commentId, t, permissions } = this.props
    const { postTitle, postDescription, sectionId } = this.state

    const error =
      postTitle.length > TITLE_MAX_LENGTH || postDescription.length > DESCRIPTION_MAX_LENGTH
    const errorPostTitle = postTitle.length > TITLE_MAX_LENGTH ? 'error' : ''
    const errorPostDescription = postDescription.length > DESCRIPTION_MAX_LENGTH ? 'error' : ''
    const disabled = postTitle && postDescription && sectionId && !error
    const showCaptcha = !canDo(permissions, 'captcha', 'skip')
    return (
      <div data-popup="TWE" className={`popup-overlay`}>
        <div className="popup-background" onClick={() => closeModal()}></div>
        <div className="popup popup-truck">
          <div className="fa fa-times icon-close" onClick={() => closeModal()}></div>
          <div className="popup-body">
            <div className="truck-item">
              <form className="panel">
                <div className="panel-body">
                  <div className="panel-content-row">
                    <div className="col-md-4">
                      <h5 className="title-gray">
                        {t('forum.compliant.title')}
                      </h5>
                    </div>
                    <div className="col-md-12 col-lg-17">
                      <div className="panel-form-row row">
                        <span className="label fl-left modal-title">
                          {t('forum.compliant.your.message.title')}
                        </span>
                        <span className="col-gray length-countdown fl-right">
                          <span className={classNames('count', errorPostTitle && 'error-length')}>
                            {postTitle.length}
                          </span>
                          /<span className="length">{TITLE_MAX_LENGTH}</span>
                        </span>
                        <input
                          type="text"
                          value={postTitle}
                          className={errorPostTitle}
                          onChange={e => this.setTextFieldState('postTitle', e)}
                        />
                      </div>

                      <div className="panel-form-row row">
                        <span className="label fl-left">{t('forum.your.message')}</span>
                        <span className="col-gray length-countdown fl-right">
                          <span
                            className={classNames('count', errorPostDescription && 'error-length')}
                          >
                            {postDescription.length}
                          </span>
                          /<span className="length">{DESCRIPTION_MAX_LENGTH}</span>
                        </span>
                        <textarea
                          value={postDescription}
                          placeholder={`${t('forum.no.more')} ${DESCRIPTION_MAX_LENGTH} ${t(
                            'forum.characters'
                          )}`}
                          className={errorPostDescription}
                          onChange={e => this.setTextFieldState('postDescription', e)}
                        />
                      </div>
                      <div className="label-styles">{t('forum.compliant.description')}</div>
                      <div className="panel-form-row panel-form-row-lg row">
                        <div className="col-sm-4 col-lg-30 remove-padding">
                          <button
                            type="button"
                            //onClick={this.onSubmit}
                            disabled={!disabled}
                            className="button button-orange button-block"
                          >
                            {t('forum.create')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="truck-item-bottom">
                <div className="row">
                  <div className="fl-right">
                    <span>
                      <div className="panel-content-row">
                        <i className="fa fa-question-circle col-blue tooltip-trigger"></i>
                        <Link to="/about/rules" target="_blank">
                          {t('forum.rules.compliant')}
                        </Link>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
