export const int7 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Convenția asupra circulației rutiere</h4><p></p>

<p></p><h4>CAPITOLUL I</h4><p></p>

<p style="font-weight:500;">Dispoziții generale</p><br>

<p style="font-weight:500;">ARTICOLUL 1
Definiții</p>

<p style="text-indent:2em;"></p><h6>Pentru aplicarea dispozițiilor prezentei convenții, termenii de mai jos vor avea sensul ce le este
 dat în acest articol:</h6><p></p>

<p style="text-indent:2em;">a) legislația națională a unei părți contractante cuprinde totalitatea legilor și reglementărilor
 naționale sau locale în vigoare pe teritoriul părții contractante;</p>

<p style="text-indent:2em;"></p><h6>b) un vehicul este considerat în circulație internațională pe teritoriul unui stat cînd:</h6><p></p>

<p>i) aparține unei persoane fizice sau juridice cu reședința obișnuită în afara acestui stat;</p><br>

<p>ii) nu este înmatriculat în acest stat;</p><br>

<p style="text-indent:2em;">iii) și este adus în mod temporar pe teritoriul acestui stat, oricare parte contractantă putînd
 să nu considere în "circulație internațională" un vehicul ce ar rămîne pe teritoriul său mai mult
 de un an fără o întrerupere importantă a cărei durată poate fi fixată de partea contractantă.</p><br>

<p style="text-indent:2em;">Un ansamblu de vehicule este considerat în "circulație internațională" dacă cel puțin unul dintre
 vehiculele care-l compun răspunde definiției;</p><br>

<p style="text-indent:2em;">c) localitate este spațiul ce cuprinde imobile clădite și ale cărei intrări și ieșiri sînt stabilite
 în mod special sau care este definit în orice alt mod în legislația națională;</p><br>

<p>d) drum înseamnă întreaga lățime a unei căi sau străzi deschisă circulației publice;</p><br>

<p style="text-indent:2em;">e) parte carosabilă este acea parte a drumului folosită în mod normal pentru circulația vehiculelor;
 un drum poate avea mai multe părți carosabile separate în mod distinct, una de alta, printr-o zonă
 centrală despărțitoare sau o diferență de nivel;</p><br>

<p style="text-indent:2em;">f) pe părțile carosabile unde una sau mai multe căi laterale ori una sau mai multe piste sînt rezervate
 circulației anumitor vehicule, marginea părții carosabile înseamnă, pentru ceilalți participanți la 
 circulație, marginea restului părții carosabile;</p><br>

<p style="text-indent:2em;">g) cale este oricare dintre benzile longitudinale materializate sau nu prin marcaje rutiere longitudinale,
 în care poate fi împărțită partea carosabilă, și care are o lățime suficientă pentru a permite scurgerea
 unui șir de automobile, altele decît motociclete;</p><br>

<p style="text-indent:2em;">h) intersecție este oricare încrucișare la nivel, întîlnire sau bifurcare de drumuri, inclusiv locurile
 formate de aceste încrucișări, întîlniri sau bifurcări;</p><br>

<p>i) trecere la nivel este orice încrucișare la nivel a unui drum cu o cale ferată sau de 
tramvai cu platformă proprie;</p>

<p style="text-indent:2em;"></p><h6>j) autostradă este un drum proiectat și construit în mod special pentru circulația automobilelor, 
care nu deservește proprietățile alăturate și care:</h6><p></p>

<p style="text-indent:2em;">i) cu excepția unor locuri speciale sau cu caracter temporar, are, pentru cele două sensuri de circulație, părți carosabile distincte,
 separate una de alta printr-o bandă de teren care nu este destinată circulației sau, în mod excepțional, prin alte mijloace;</p><br>

<p>ii) nu se intersectează la nivel cu un drum, cu o cale ferată, de tramvai sau destinată circulației pietonilor;</p><br>

<p>iii) și este special semnalizată ca autostradă;</p>

<p style="text-indent:2em;"></p><h6>k) un vehicul este:</h6><p></p>

<p style="text-indent:2em;">i) oprit, cînd este imobilizat pe o perioadă de timp necesară pentru a lua sau lăsa persoane
 ori de a încărca sau descărca bunuri;</p><br>

<p style="text-indent:2em;">ii) staționat, cînd este imobilizat pentru un motiv, altul decît acela de a evita un conflict cu un participant la circulație sau un obstacol ori pentru a se supune regulilor de circulație, 
iar imobilizarea nu se limitează la timpul necesar luării sau lăsării de persoane ori de bunuri.</p><br>

<p style="text-indent:2em;">Părțile contractante vor putea să considere "oprite" vehiculele imobilizate în condițiile arătate la alin. ii) de mai sus, dacă
 durata imobilizării nu depășește o limită de timp stabilită de legislația națională și să considere "staționate" vehiculele 
 imobilizate în condițiile definite la alin. i) de mai sus, dacă durata imobilizării depășește o limită de timp 
 stabilită de legislația națională;</p><br>
 
<p style="text-indent:2em;">l) bicicletă este acel vehicul care are cel puțin două roți și este acționat exclusiv de energia musculară a persoanelor care
 se găsesc pe acest vehicul, în special cu ajutorul pedalelor sau al manivelelor;</p><br>
 
<p style="text-indent:2em;">m) motoretă este acel vehicul cu două sau trei roți, acționat de un motor cu ardere internă, avînd o capacitate cilindrică de
 cel mult 50 cm3, și a cărui limită de viteză, prin construcție, nu depășește 50 km/oră. Părțile contractante pot să nu considere
 ca motorete, potrivit legislației lor naționale, vehiculele care nu au caracteristicile bicicletelor, în ce privește posibilitățile
 lor de folosire, în special caracteristica de a putea fi puse în mișcare cu ajutorul pedalelor, sau a căror viteză maximă, prin construcție,
 greutate sau anumite caracteristici ale motorului, depășesc limitele stabilite. Nimic din această definiție nu va putea fi interpretat ca
 împiedicînd părțile contractante să asimileze complet motoretele bicicletelor pentru aplicarea dispozițiilor din legislația națională asupra
 circulației rutiere;</p><br>
 
<p style="text-indent:2em;">n) motocicletă este acel vehicul cu două roți, cu sau fără ataș, echipat cu un motor cu ardere internă. Părțile contractante pot,
 în legislația națională, să asimileze motocicletelor vehiculele cu 3 roți a căror greutate în gol nu depășește 400 kg. Termenul
 motocicletă nu include motoretele; totuși, părțile contractante pot asimila motoretele motocicletelor, cu condiția să facă o
 declarație în acest scop conform paragrafului 2 al art. 54 din convenție;</p><br>
 
<p style="text-indent:2em;">o) vehicul cu motor este, cu excepția motoretelor pe teritoriul părților contractante care nu le-au asimilat motocicletelor și
 a vehiculelor care se deplasează pe șine, acel vehicul prevăzut cu un motor cu ardere internă care circulă pe drum prin
 mijloacele sale proprii;</p><br>
 
<p style="text-indent:2em;">p) automobil este acel vehicul cu motor care servește în mod normal la transportul pe drum de persoane sau bunuri ori la tractarea
 pe drum a vehiculelor folosite la transportul de persoane sau bunuri. Acest termen include troleibuzele, adică vehiculele legate
 de o linie electrică și care nu circulă pe șine. Nu include vehiculele, cum ar fi tractoarele agricole, a căror utilizare la
 transportul pe drum de persoane sau bunuri ori la tractarea pe drum a vehiculelor utilizate la transportul de persoane sau bunuri
 nu este decît accesorie;</p><br>
 
<p>q) remorcă este orice vehicul destinat a fi tractat de un vehicul cu motor;
 acest termen include semiremorca;</p><br>

<p style="text-indent:2em;">r) semiremorcă este acea remorcă destinată a fi cuplată la un automobil, în așa fel încît să se sprijine în parte pe ea însăși,
 iar o parte apreciabilă a greutății sale și a greutății încărcăturii sale să fie suportată de automobil;</p><br>
 
<p>s) remorcă ușoară este acea remorcă a cărei greutate maximă autorizată nu depășește 750 kg;</p><br>

<p>t) ansamblu de vehicule înseamnă vehicule cuplate care circulă pe drum ca o unitate;</p><br>

<p>u) vehicul articulat este ansamblul de vehicule constituit dintr-un automobil 
și o semiremorcă cuplată la acest automobil;</p><br>

<p style="text-indent:2em;">v) conducător este acea persoană care își asumă conducerea unui vehicul cu sau fură motor (inclusiv bicicletă) și care însoțește
 pe drum animale izolate sau în turmă ori animale de tracțiune, de povară sau de călărie;</p><br>
 
<p style="text-indent:2em;">w) greutate maximă autorizată este greutatea maximă a vehiculului încărcat, declarată admisibilă de autoritatea competentă
 din statul în care vehiculul este înmatriculat;</p><br>
 
<p style="text-indent:2em;">x) greutate în gol este greutatea vehiculului fără echipaj, pasageri sau încărcătură, dar cu plinul de carburant și cu 
utilajul normal de bord;</p><br>

<p>y) greutate în sarcină este greutatea efectivă a vehiculului astfel cum este încărcat,
 cu echipajul și pasagerii la bord;</p><br>

<p style="text-indent:2em;">z) sens de circulație și corespunzător sensului circulației înseamnă partea dreaptă cînd, după legislația națională, 
conducătorul unui vehicul trebuie să permită unui alt vehicul care circulă din sens contrar să treacă prin stînga sa;
 în caz contrar, înseamnă partea stîngă;</p><br>
 
<p style="text-indent:2em;">aa) obligația unui conducător de vehicul de a ceda trecerea altor vehicule înseamnă că acesta nu trebuie să-și continue
 mersul sau manevra ori să o reia dacă aceasta riscă să determine pe conducătorii altor vehicule să modifice în mod brusc
 direcția sau viteza vehiculelor lor.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 2</p><br>

<p style="font-weight:500;">Anexele convenției</p>

<p style="text-indent:2em;"></p><h6>Anexele prezentei convenții, și anume:</h6><p></p>

<p>Anexa nr. 1 - Excepții de la obligația de a admite în circulație internațională automobilele și remorcile;</p><br>

<p>Anexa nr. 2 - Numărul de înmatriculare al automobilelor și remorcilor în circulație internațională;</p><br>

<p>Anexa nr. 3 - Semnul distinctiv al automobilelor și remorcilor în circulație internațională;</p><br>

<p>Anexa nr. 4 - Elemente de identificare a automobilelor și remorcilor în circulație internațională;</p><br>

<p>Anexa nr. 5 - Condiții tehnice pentru automobile și remorci;</p><br>

<p>Anexa nr. 6 - Permisul de conducere național și</p><br>

<p>Anexa nr. 7 - Permisul de conducere internațional, fac parte integrantă din această convenție.</p><br>

<p style="font-weight:500;">ARTICOLUL 3</p><br>

<p style="font-weight:500;">Obligațiile părților contractante</p>

<p style="text-indent:2em;"></p><h6>1.a) Părțile contractante vor lua măsurile corespunzătoare pentru ca regulile de circulație în vigoare pe teritoriul lor să fie,
 în ce privește fondul lor, în concordanță cu dispozițiile cap. II al convenției. Cu condiția ca ele să nu
 fie în nici un fel contrare dispozițiilor convenției:</h6><p></p>
 
<p>i) aceste reguli pot să nu reia acele dintre prevederile care se aplică situațiilor ce nu apar pe teritoriul părților
 contractante;</p><br>

<p>ii) aceste reguli pot să conțină dispoziții neprevăzute în acest cap. II.</p><br>

<p style="text-indent:2em;">b) Prevederile prezentului paragraf nu obligă părțile contractante să prevadă sancțiuni penale pentru orice încălcare a dispozițiilor cap.
 II reluate în regulile lor de circulație.</p><br>
 
<p style="text-indent:2em;">2.a) Părțile contractante vor lua, de asemenea, măsurile corespunzătoare pentru ca regulile în vigoare pe teritoriul lor, referitoare
 la condițiile tehnice pe care trebuie să le îndeplinească automobilele și remorcile, să fie conforme cu dispozițiile anexei nr. 5 a
 convenției; aceste reguli pot conține dispoziții neprevăzute în această anexă, cu condiția de a nu fi deloc contrare principiilor de
 siguranță ce stau la baza dispozițiilor sus-arătate. În plus, ele vor lua măsurile corespunzătoare pentru ca automobilele și remorcile
 înmatriculate pe teritoriul lor să fie conforme cu dispozițiile anexei nr. 5 atunci cînd sînt în circulație
 internațională.</p><br>
 
<p style="text-indent:2em;">b) Dispozițiile prezentului paragraf nu impun nici o obligație părților contractante în ce privește regulile în vigoare pe teritoriul lor
 referitoare la condițiile tehnice pe care trebuie să le îndeplinească vehiculele cu motor care nu sînt 
 automobile în sensul convenției.</p><br>
 
<p style="text-indent:2em;">3. În afara excepțiilor prevăzute la anexa nr. 1 a convenției, părțile contractante vor fi obligate să admită pe teritoriul lor în circulație
 internațională automobilele și remorcile care îndeplinesc condițiile prevăzute în cap. III al convenției și ai căror conducători îndeplinesc
 condițiile prevăzute în cap. IV; ele vor fi obligate să recunoască și certificatele de înmatriculare eliberate conform dispozițiilor cap. III
 ca dovedind, pînă la proba contrară, că vehiculele ce fac obiectul acestor prevederi îndeplinesc condițiile prevăzute
 la cap. III.</p><br>
 
<p style="text-indent:2em;">4. Măsurile pe care le-au luat sau pe care le vor lua părțile contractante, fie unilateral, fie prin acorduri bilaterale sau multilaterale, 
pentru a admite pe teritoriul lor în circulație internațională automobile și remorci care nu îndeplinesc toate condițiile prevăzute în cap. III
 al convenției și pentru a recunoaște, în afara cazurilor prevăzute în cap. IV, valabilitatea pe teritoriul lor a permiselor eliberate de o parte
 contractantă, vor fi considerate ca fiind conforme cu obiectul convenției.</p><br>
 
<p style="text-indent:2em;">5. Părțile contractante vor fi obligate să admită în circulație internațională pe teritoriul lor bicicletele și motoretele care îndeplinesc
 condițiile tehnice prevăzute în cap. V al convenției și ai căror conducători au reședința pe teritoriul unei alte părți contractante. Nici 
 o parte contractantă nu va putea să ceară conducătorilor de biciclete sau motorete în circulație internațională să fie titulari ai unui permis
 de conducere; totuși, părțile contractante care vor face, conform paragrafului 2 al art. 54 al convenției, o declarație prin care asimilează 
 motoretele motocicletelor, vor putea cere permis de conducere conducătorilor de motorete în circulație internațională.</p><br>
 
<p style="text-indent:2em;">6. Părțile contractante se obligă, la cererea oricărei părți contractante, să comunice informațiile necesare stabilirii identității persoanei
 pe numele căreia este înmatriculat pe teritoriul lor un automobil sau o remorcă cuplată la un automobil, cînd din cererea prezentată rezultă 
 că vehiculul a fost implicat într-un accident pe teritoriul părții contractante care a adresat cererea.</p><br>
 
<p style="text-indent:2em;">7. Măsurile pe care le-au luat sau pe care le vor lua părțile contractante, fie unilateral, fie prin acorduri bilaterale sau multilaterale, 
pentru a înlesni circulația rutieră internațională prin simplificarea formalităților de vamă, de poliție sau de sănătate și alte formalități 
de același gen, precum și măsurile luate pentru a face să coincidă competențele și orele de deschidere a birourilor și posturilor vamale în 
același punct al frontierei, vor fi considerate ca fiind conforme cu obiectul convenției.</p><br>

<p style="text-indent:2em;">8. Dispozițiile paragrafelor 3, 5 și 7 ale acestui articol nu afectează dreptul oricărei părți contractante de a condiționa admiterea pe 
teritoriul său, în circulație internațională, a automobilelor, remorcilor, motoretelor și bicicletelor, precum și a conducătorilor și pasagerilor
 lor, de respectarea reglementărilor cu privire la: transporturile comerciale de călători și mărfuri, asigurarea de răspundere civilă a conducătorilor
 de autovehicule și în materie de vamă, precum și, în general, a reglementărilor din alte domenii decît circulația 
 rutieră.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 4</p><br>

<p style="font-weight:500;">Semnalizarea</p>

<p style="text-indent:2em;"></p><h6>Părțile contractante la convenție, care nu vor fi și părți contractante la Convenția asupra semnalizării rutiere, deschisă semnării la
 Viena în aceeași zi cu prezenta convenție, se obligă:</h6><p></p>

<p style="text-indent:2em;">a) ca toate indicatoarele, semnalele luminoase de circulație și marcajele rutiere, instalate pe teritoriul lor,
 să constituie un sistem unitar;</p><br>

<p style="text-indent:2em;">b) ca numărul tipurilor de indicatoare să fie limitat, iar indicatoarele să nu fie instalate decît în locuri
 unde prezența lor este socotită necesară;</p><br>

<p style="text-indent:2em;">c) ca indicatoarele de avertizare a pericolului să fie instalate la o distanță suficientă de
 obstacole, pentru a-i putea anunța din timp pe conducători; și</p>

<p style="text-indent:2em;"></p><h6>d) să interzică:</h6><p></p>

<p style="text-indent:2em;">i) de a figura pe un indicator, pe suportul lui sau pe oricare altă instalație ce servește la dirijarea circulației, orice nu are legătură cu obiectul acestui
 indicator sau instalații; totuși, cînd părțile contractante sau subdiviziunile lor autoriză o asociație fără scop lucrativ să instaleze indicatoare de orientare,
 ele pot permite ca emblema acestei asociații să figureze pe indicator sau pe suportul acestuia, cu condiția ca 
 înțelegerea semnificației indicatorului să nu fie îngreuiată;</p><br>
 
<p style="text-indent:2em;">ii) de a instala panouri, afișe, mărci sau instalații ce riscă să fie confundate cu indicatoare sau cu alte instalații ce servesc la dirijarea circulației,
 să le reducă vizibilitatea sau eficacitatea, să-i stînjenească pe participanții la circulație sau să le distragă atenția într-un mod periculos pentru 
 siguranța circulației.</p>
 
<p></p><h4>CAPITOLUL II</h4><p></p>

<p style="font-weight:500;">Reguli aplicabile circulației rutiere</p><br>

<p style="font-weight:500;">ARTICOLUL 5</p><br>

<p style="font-weight:500;">Valoarea semnalizării</p><br>

<p style="text-indent:2em;">1. Participanții la circulație trebuie să se conformeze semnificației indicatoarelor rutiere, semnalelor luminoase de circulație și
 marcajelor rutiere, chiar dacă semnificația acestora ar fi în contradicție cu alte reguli de circulație.</p><br>
 
<p style="text-indent:2em;">2. Semnificația, semnalelor luminoase de circulație prevalează asupra indicatoarelor rutiere care 
reglementează prioritatea.</p><br>

<p style="font-weight:500;">ARTICOLUL 6</p><br>

<p style="font-weight:500;">Comenzi date de agenții care dirijează circulația</p><br>

<p>1. Agenții care dirijează circulația vor fi ușor recunoscuți și vizibili de la distanță, atît noaptea cît și ziua.</p><br>

<p>2. Participanții la circulație sînt obligați să se supună imediat comenzilor date de agenții care dirijează circulația.</p>

<p style="text-indent:2em;"></p><h6>3. Se recomandă ca legislațiile naționale să prevadă că sînt considerate, în special, ca comenzi date
 de agenții care dirijează circulația:</h6><p></p>

<p style="text-indent:2em;">a) brațul ridicat vertical; acest gest înseamnă "atenție, oprire" pentru toți participanții la circulație, exceptînd pe conducătorii care nu
 s-ar mai putea opri în condiții de siguranță suficientă; mai mult, dacă acest gest este făcut într-o intersecție, el nu obligă oprirea
 conducătorilor angajați deja în intersecție;</p><br>
 
<p style="text-indent:2em;">b) brațul sau brațele întinse orizontal; acest gest înseamnă "oprire" pentru toți participanții la circulație care vin, oricare ar
 fi sensul lor de mers, din direcțiile intersectate de brațul sau de brațele întinse; după ce a făcut acest gest, agentul care dirijează
 circulația va putea coborî brațul sau brațele; pentru conducătorii care se găsesc în fața sau în spatele agentului, acest gest înseamnă,
 de asemenea, "oprire";</p><br>
 
<p style="text-indent:2em;">c) balansarea unei lumini roșii; acest gest înseamnă "oprire" pentru participanții
 la circulație spre care este îndreptată lumina.</p><br>

<p style="text-indent:2em;">4. Comenzile agenților care dirijează circulația prevalează asupra semnificației indicatoarelor rutiere, a semnalelor luminoase de 
circulație sau a marcajelor rutiere, precum și asupra regulilor de circulație.</p><br>

<p style="font-weight:500;">ARTICOLUL 7</p><br>

<p style="font-weight:500;">Reguli generale</p><br>

<p style="text-indent:2em;">1. Participanții la circulație trebuie să evite orice comportare ce ar putea constitui un pericol sau un obstacol pentru circulație,
 de a pune în pericol persoane ori de a produce o pagubă proprietăților publice sau particulare.</p><br>
 
<p style="text-indent:2em;">2. Se recomandă ca legislațiile naționale să prevadă că participanții la circulație trebuie să evite a stînjeni circulația ori să
 o facă periculoasă aruncînd, depozitînd sau abandonînd pe drum obiecte sau materii ori creînd orice alte obstacole pe drum. 
 Participanții la circulație care n-au putut evita crearea unui obstacol sau a unui pericol trebuie să ia măsurile necesare pentru
 a-l face să dispară cît mai repede posibil și, în cazul în care nu-l poate face să dispară imediat, să-i semnaleze prezența celorlalți
 participanți la circulație.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 8</p><br>

<p style="font-weight:500;">Conducători</p><br>

<p>1. Orice vehicul sau ansamblu de vehicule în mișcare trebuie să aibă un conducător.</p><br>

<p style="text-indent:2em;">2. Se recomandă ca legislațiile naționale să prevadă că animalele de povară, de tras sau de călărie și, eventual, cu excepția
 unor zone semnalizate special la intrare, animalele izolate sau în turmă, trebuie să aibă un conducător.</p><br>
 
<p>3. Fiecare conducător trebuie să aibă calitățile fizice și psihice necesare și să fie în stare fizică și mintală de a conduce.</p><br>

<p style="text-indent:2em;">4. Fiecare conducător de vehicul cu motor trebuie să aibă cunoștințele și îndemînarea necesare conducerii vehiculului; această 
dispoziție nu constituie, totuși, un impediment pentru învățarea conducerii vehiculelor, potrivit legislației naționale.</p><br>

<p>5. Fiecare conducător trebuie să aibă permanent controlul vehiculului său ori să poată însoți animalele sale.</p><br>

<p style="font-weight:500;">ARTICOLUL 9</p><br>

<p style="font-weight:500;">Turme</p><br>

<p style="text-indent:2em;">Se recomandă ca legislațiile naționale să prevadă că, cu excepția derogării acordate pentru a înlesni migrațiile, turmele
 trebuie să fie fracționate în grupuri de lungime moderată și separate unele de altele prin intervale suficient de mari 
 pentru comoditatea circulației.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 10</p><br>

<p style="font-weight:500;">Locul pe partea carosabilă</p><br>

<p style="text-indent:2em;">1. Sensul de circulație trebuie să fie același pe toate drumurile unui stat, cu excepția, dacă e cazul, a drumurilor
 care servesc exclusiv sau în primul rînd tranzitului între două alte state.</p><br>
 
<p style="text-indent:2em;">2. Animalele ce sînt însoțite pe partea carosabilă trebuie să fie menținute, pe cît posibil, cît mai aproape de
 marginea părții carosabile corespunzătoare sensului circulației.</p><br>
 
<p style="text-indent:2em;">3. Sub rezerva dispozițiilor contrare ale paragrafului 1 al art. 7, ale paragrafului 6 al art. 11 și ale altor
 dispoziții contrare din convenție, orice conducător de vehicul trebuie, atît cît îi permit împrejurările, să 
 mențină vehiculul cît mai aproape de marginea părții carosabile corespunzătoare sensului circulației. Părțile
 contractante sau subdiviziunile lor pot stabili reguli mai precise referitoare la locul pe partea carosabilă 
 al vehiculelor destinate transportului de mărfuri.</p><br>
 
<p style="text-indent:2em;">4. Cînd un drum are două sau trei părți carosabile, nici un conducător nu trebuie să meargă pe partea carosabilă 
situată pe partea opusă aceleia care corespunde sensului circulației.</p>

<p style="text-indent:2em;"></p><h6>5:</h6><p></p>

<p style="text-indent:2em;">a) Pe părțile carosabile unde circulația se face în ambele sensuri și care are cel puțin patru benzi, nici un 
conducător nu trebuie să circule pe benzile situate pe cealaltă jumătate a părții carosabile opusă sensului 
circulației.</p><br>

<p style="text-indent:2em;">b) Pe părțile carosabile unde circulația se face în ambele sensuri și care are trei benzi, nici un conducător nu 
poate circula pe banda situată la marginea părții carosabile opusă sensului circulației.</p><br>

<p style="font-weight:500;">ARTICOLUL 11</p><br>

<p style="font-weight:500;">Depășirea și circulația pe benzi</p>

<p style="text-indent:2em;"></p><h6>1:</h6><p></p>

<p>a) Depășirea trebuie să se facă pe partea opusă celei care corespunde sensului circulației.</p><br>

<p style="text-indent:2em;">b) Totuși, depășirea trebuie să se facă pe partea corespunzătoare sensului circulației în cazul cînd conducătorul 
care urmează să fie depășit, după ce a semnalizat intenția de a se îndrepta spre partea opusă celei care corespunde
 sensului circulației, a condus vehiculul sau animalele spre această parte a drumului, în scopul fie de a vira în 
 această parte pentru a circula pe alt drum sau pentru a intra într-o proprietate alăturată, fie pentru a opri pe 
 această parte.</p>
 
<p style="text-indent:2em;"></p><h6>2. Înainte de a depăși, conducătorul trebuie, sub rezerva dispozițiilor paragrafului 1 al art. 7 și ale art. 14 ale
 convenției, să se asigure că:</h6><p></p>
 
<p>a) nici un conducător care îl urmează nu a început o manevră pentru a-l depăși;</p><br>

<p>b) cel care îl precede pe aceeași bandă nu a semnalizat intenția de a depăși un altul;</p><br>

<p style="text-indent:2em;">c) banda pe care urmează să se angajeze este liberă pe o distanță suficientă pentru ca, ținînd seama de diferența
 dintre viteza vehiculului său în timpul depășirii și aceea a participanților la circulație ce urmează să fie 
 depășiți, manevra sa să nu pericliteze sau să stânjenească circulația din sens invers;</p><br>
 
<p style="text-indent:2em;">d) și că, în afara cazului cînd se angajează pe o bandă interzisă circulației din sens invers, va putea, fără a 
stînjeni pe cei depășiți, să-și reia locul prevăzut la paragraful 3 al art. 10 din convenție.</p><br>

<p style="text-indent:2em;">3. Conform dispozițiilor paragrafului 2 al acestui articol, pe părțile carosabile unde circulația se face în ambele 
sensuri, depășirea este interzisă în apropierea vîrfului unei pante și, cînd vizibilitatea este insuficientă, în curbe,
 afară de cazul cînd în aceste locuri există benzi materializate prin marcaje rutiere longitudinale, iar depășirea se 
 face fără a ieși de pe aceste benzi interzise prin marcaje circulației din sens invers.</p><br>
 
<p style="text-indent:2em;">4. În timpul depășirii, conducătorul trebuie să se îndepărteze de cel sau de cei depășiți, astfel încît să
 lase liberă o distanță laterală suficientă.</p>
 
<p style="text-indent:2em;"></p><h6>5:</h6><p></p>

<p style="text-indent:2em;">a) Pe părțile carosabile care au cel puțin două benzi rezervate circulației în sensul de mers, un conducător
 care ar vrea să efectueze o nouă manevră de depășire imediat sau la puțin timp după ce a revenit pe locul 
 prevăzut de paragraful 3 al art. 10 din convenție, poate, pentru a efectua această manevră și cu condiția de
 a se asigura că aceasta nu stînjenește în mod deosebit pe conducătorii vehiculelor mai rapide care vin din spate,
 să rămînă pe banda pe care s-a angajat la efectuarea primei depășiri.</p><br>
 
<p style="text-indent:2em;">b) Părțile contractante sau subdiviziunile lor pot totuși să nu aplice dispozițiile prezentului paragraf conducătorilor
 de biciclete, motorete, motociclete și vehicule care nu sînt automobile în sensul convenției, precum și conducătorilor
 de automobile a căror greutate maximă autorizată depășește 3.500 kg sau a căror viteză, prin construcție, nu poate depăși
 40 km pe oră.</p>
 
<p style="text-indent:2em;"></p><h6>6. Cînd sînt aplicabile dispozițiile paragrafului 5. a) al acestui articol, iar densitatea circulației este de așa natură
 încît vehiculele nu numai că ocupă toată lățimea părții carosabile rezervată sensului lor de circulație, dar circulă cu
 o viteză ce depinde de viteza vehiculului pe care-l precede pe banda pe care se află:</h6><p></p>
 
<p style="text-indent:2em;">a) sub rezerva dispozițiilor paragrafului 9 al prezentului articol, faptul că vehiculele de pe o bandă circulă mai 
repede decît vehiculele de pe altă bandă nu este considerat depășire în sensul acestui articol;</p><br>

<p style="text-indent:2em;">b) un conducător care nu se găsește pe banda cea mai apropiată de marginea părții carosabile corespunzătoare sensului
 circulației trebuie să nu schimbe banda decît pentru a se pregăti să vireze la dreapta sau la stînga ori pentru a 
 staționa, cu excepția schimbărilor de bandă efectuate, conform legislației naționale, în aplicarea prevederilor 
 paragrafului</p><br>

<p>5. b) al prezentului articol.</p><br>

<p style="text-indent:2em;">7. În circulația pe benzi prevăzută la paragrafele 5 și 6 ale acestui articol, este interzis conducătorilor, 
atunci cînd benzile sînt delimitate pe partea carosabilă prin marcaje longitudinale, să circule încălcînd aceste
 marcaje.</p>
 
<p style="text-indent:2em;"></p><h6>8. Sub rezerva dispozițiilor paragrafului 2 al prezentului articol și ale altor restricții pe care părțile
 contractante sau subdiviziunile lor le vor putea stabili în ce privește depășirea la intersecții și la trecerile
 la nivel, nici un conducător de vehicul nu trebuie să depășească un vehicul, altul decît o bicicletă cu două roți,
 o motoretă sau o motocicletă fără ataș:</h6><p></p>
 
<p style="text-indent:2em;"></p><h6>a) imediat înainte și într-o intersecție alta decît o intersecție cu sens giratoriu, în afară de:</h6><p></p>

<p>i) cazul prevăzut la paragraful 1 b) al prezentului articol;</p><br>

<p>ii) cazul în care drumul sau locul unde are loc depășirea beneficiază de prioritate la intersecție;</p><br>

<p>iii) cazul în care circulația este dirijată la intersecții de un agent de circulație sau de
 semnale luminoase de circulație;</p><br>

<p style="text-indent:2em;">b) imediat înainte și pe trecerile la nivel neprevăzute cu bariere sau semibariere, părțile contractante sau subdiviziunile 
lor putînd totuși să permită depășirea la trecerile la nivel unde circulația este dirijată prin semnale luminoase de circulație, 
cînd semnalul permite trecerea vehiculelor.</p><br>

<p style="text-indent:2em;">9. Un vehicul nu trebuie să depășească un alt vehicul care se apropie de o trecere pentru pietoni, delimitată prin marcaje 
sau semnalizată ca atare, ori care este oprit în dreptul acesteia, decît cu o viteză suficient de redusă pentru a putea opri
 dacă un pieton se află pe trecere. Nici o dispoziție din prezentul paragraf nu va fi interpretată ca împiedicînd părțile contractante
 sau subdiviziunile lor să interzică depășirea începînd de la o anumită distanță de o trecere pentru pietoni sau să impună prevederi
 mai stricte conducătorului unui vehicul care vrea să depășească un altul oprit în dreptul trecerii.</p><br>
 
<p style="text-indent:2em;">10. Un conducător care constată că un altul care îl urmează dorește să-l depășească trebuie, în afara cazului prevăzut la paragraful
 1 b) al art. 16 al convenției, să se apropie de marginea părții, carosabile, corespunzătoare sensului circulației și să nu mărească
 viteza. Dacă insuficiența lățimii, profilul sau starea părții carosabile nu permit, ținînd seama de densitatea circulației din sens
 invers, de a depăși cu ușurință și fară pericol un vehicul lent, voluminos ori obligat să respecte o limită de viteză, conducătorul
 acestui din urmă vehicul trebuie să reducă viteza și, la nevoie, să se dea la o parte de îndată ce este posibil, pentru a lăsa să
 treacă vehiculele care îl urmează.</p>
 
<p style="text-indent:2em;"></p><h6>11:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) Părțile contractante sau subdiviziunile lor pot, pe drumurile cu sens unic și pe cele unde circulația se desfășoară în ambele
 sensuri, cînd cel puțin două benzi în localități și trei benzi în afara localităților sînt rezervate circulației în același sens,
 delimitate prin marcaje longitudinale:</h6><p></p>
 
<p style="text-indent:2em;">i) să permită vehiculelor care circulă pe o bandă să depășească, pe partea corespunzătoare sensului de circulație,
 vehiculele care circulă pe altă bandă;</p><br>
 
<p style="text-indent:2em;">ii) să nu aplice dispozițiile paragrafului 3 al art. 10 din convenție,
cu excepția unor dispoziții corespunzătoare care să restrîngă posibilitatea de a schimba banda.</p><br>

<p style="text-indent:2em;">b) În cazul prevăzut la alin. a) al prezentului paragraf, modul de a conduce va fi considerat că nu constituie o 
depășire în sensul convenției; totuși, dispozițiile paragrafului 9 al prezentului articol rămîn aplicabile.</p><br>

<p style="font-weight:500;">ARTICOLUL 12</p><br>

<p style="font-weight:500;">Trecerea pe lîngă vehiculele care circulă din sens opus</p><br>

<p style="text-indent:2em;">1. Pentru a trece pe lîngă un vehicul care circulă din sens opus, orice conducător trebuie să lase liberă o distanță
 laterală suficientă și, la nevoie, să circule cît mai aproape de marginea părții carosabile corespunzătoare sensului
 circulației; dacă, procedînd astfel, mersul său înainte este împiedicat de un obstacol sau de prezența altor participanți
 la circulație, el trebuie să reducă viteza și, la nevoie, să oprească pentru a lăsa să treacă pe cel sau pe cei care vin
 din sens opus.</p><br>
 
<p style="text-indent:2em;">2. Pe drumurile de munte și pe cele cu pantă mare ce au caracteristici similare, unde trecerea pe lîngă vehicule care
 circulă din sens opus este imposibilă sau grea, obligația de a lăsa să treacă orice vehicul care urcă revine conducătorului
 de vehicul care coboară, în afară de cazul cînd prin modul cum sînt situate refugiile de-a lungul părții carosabile, pentru
 a permite vehiculelor să le folosească, este de așa natură încît, ținînd seama de viteza și poziția lor, vehiculul care urcă
 are în fața lui un refugiu, iar mersul înapoi al unuia dintre vehicule ar fi necesar dacă vehiculul ce urcă nu s-ar opri pe
 acest refugiu. În cazul în care unul dintre cele două vehicule este obligat să meargă înapoi pentru a face posibilă trecerea
 unul pe lîngă altul, această manevră va fi făcută de conducătorul vehiculului care coboară, cu excepția cazului în care manevra
 este în mod evident mai ușor de făcut de către conducătorul vehiculului care urcă. Părțile contractante sau subdiviziunile lor pot,
 pentru anumite vehicule și anumite drumuri sau sectoare ale acestora, să stabilească reguli speciale diferite de cele din acest
 paragraf.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 13</p><br>

<p style="font-weight:500;">Viteza și distanța între vehicule</p><br>

<p style="text-indent:2em;">1. Orice conducător de vehicul trebuie să rămînă, în toate împrejurările, stâpîn pe vehiculul său, în așa fel încît să poată
 face față cerințelor prudenței și să fie continuu în măsură să efectueze toate manevrele ce îi revin. El trebuie ca, potrivind
 viteza vehiculului, să țină tot timpul seama de împrejurări, în special de dispunerea locurilor, de starea drumului, de starea
 și încărcătura vehiculului său, de condițiile atmosferice și de intensitatea circulației, așa încît să poată opri vehiculul în
 limitele cîmpului său vizual din față, precum și înaintea oricărui obstacol previzibil. El trebuie să reducă viteza și, la nevoie,
 să oprească ori de cîte ori împrejurările o cer, în special atunci cînd vizibilitatea nu este bună.</p><br>
 
<p style="text-indent:2em;">2. Nici un conducător nu trebuie să stînjenească mersul normal al celorlalte vehicule circulînd, fără motiv temeinic, cu o
 viteză anormal de redusă.</p><br>
 
<p style="text-indent:2em;">3. Conducătorul unui vehicul care circulă în spatele altui vehicul trebuie să lase liberă, în spatele acestuia, o distanță
 de siguranță suficientă pentru a putea evita o lovire în caz de reducere bruscă a vitezei sau de oprire neașteptată a
 vehiculului care îl precede.</p>
 
<p style="text-indent:2em;"></p><h6>4. În afara localităților, în scopul de a se înlesni depășirile, conducătorii vehiculelor sau ai ansamblurilor de vehicule
 cu mai mult de 3.500 kg greutate maximă autorizată sau cu o lungime mai mare de 10 m trebuie, în afara cazului cînd depășesc
 sau se pregătesc să depășească, să mențină un interval între vehiculele lor și cele care le preced, în așa fel încît vehiculele
 care îi depășesc să se poată intercala fără pericol în spațiul lăsat în fața vehiculului depășit. Această dispoziție nu este
 aplicabilă atunci cînd circulația este intensă și nici atunci cînd depășirea este interzisă. În plus:</h6><p></p>
 
<p style="text-indent:2em;">a) Autoritățile competente pot dispune ca anumite convoaie de vehicule să beneficieze de derogări de la aceste prevederi sau
 să le facă inaplicabile, chiar și pe drumurile unde există două benzi pe același sens de circulație.</p><br>
 
<p style="text-indent:2em;">b) Părțile contractante sau subdiviziunile lor pot stabili cifre diferite de cele menționate în prezentul paragraf pentru
 caracteristicile vehiculelor respective.</p><br>
 
<p style="text-indent:2em;">5. Nimic din această convenție nu ar putea fi interpretat ca împiedicînd părțile contractante sau subdiviziunile lor de
 a stabili limite generale sau locale de viteză, pentru toate vehiculele sau pentru anumite categorii de vehicule, ori de
 a stabili, pe anumite drumuri sau categorii de drumuri, fie viteze minime sau maxime, ori de a stabili intervale minime
 determinate de prezența pe drum a anumitor categorii de vehicule ce prezintă un pericol deosebit datorită, mai ales,
 greutății sau încărcăturii lor.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 14</p><br>

<p style="font-weight:500;">Dispoziții generale pentru manevre</p><br>

<p style="text-indent:2em;">1. Orice conducător care vrea să execute o manevră, ca aceea de a ieși dintr-un rînd de vehicule staționate sau de a
 intra într-un asemenea rînd, a devia direcția la dreapta sau la stînga pe partea carosabilă, a vira la dreapta sau la
 stînga pentru a merge pe un alt drum ori pentru a intra într-o proprietate alăturată, trebuie să nu înceapă a executa
 această manevră decît după ce s-a asigurat că o poate face fără a constitui un pericol pentru ceilalți participanți la
 circulație care îl urmează, îl preced sau cu care urmează să se încrucișeze, ținînd seama de poziția, direcția și 
 viteza lor.</p><br>
 
<p style="text-indent:2em;">2. Orice conducător care vrea să efectueze o întoarcere sau să meargă înapoi trebuie să nu înceapă a executa această
 manevră decît după ce s-a asigurat că o poate face fără a constitui un pericol sau un obstacol pentru ceilalți participanți
 la circulație.</p><br>
 
<p style="text-indent:2em;">3. Înainte de a vira sau de a efectua o manevră ce implică o deplasare laterală, orice conducător trebuie să-și anunțe
 intenția în mod clar și din timp cu ajutorul indicatorului sau al indicatoarelor de direcție ale vehiculului ori, în lipsă,
 semnalizînd corespunzător cu brațul. Semnalizarea cu ajutorul indicatorului sau al indicatoarelor de direcție trebuie să se
 mențină pe întreaga durată a manevrei. Semnalizarea trebuie să înceteze îndată ce manevra s-a efectuat.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 15</p><br>

<p style="font-weight:500;">Dispoziții speciale referitoare la vehiculele din serviciile
regulate de transport în comun</p><br>

<p style="text-indent:2em;">Se recomandă ca legislațiile naționale să prevadă ca în localități, în scopul de a ușura circulația vehiculelor din serviciile 
regulate de transport în comun, conducătorii celorlalte vehicule, cu excepția dispozițiilor paragrafului 1 al art. 17 din
 prezenta convenție, să reducă viteza și, la nevoie, să oprească pentru a lăsa aceste vehicule de transport în comun să 
 efectueze manevra necesară repunerii în mișcare din stațiile semnalizate ca atare. Dispozițiile astfel luate de părțile
 contractante sau de subdiviziunile lor nu modifică cu nimic obligația conducătorilor de vehicule de transport în comun 
 de a lua măsurile necesare pentru a evita un accident, după ce au anunțat cu ajutorul luminilor de semnalizare a 
 direcției intenția lor de a se repune în mișcare.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 16</p><br>

<p style="font-weight:500;">Schimbarea direcției</p>

<p style="text-indent:2em;"></p><h6>1. Înainte de a vira la dreapta sau la stînga pentru a intra pe un alt drum sau într-o proprietate alăturată,
 oricare conducător trebuie, sub rezerva dispozițiilor paragrafului 1 al art. 7 și ale art. 14 din convenție:</h6><p></p>
 
<p style="text-indent:2em;">a) dacă vrea să părăsească drumul pe partea corespunzătoare sensului circulației, să se apropie cît mai mult
 posibil de marginea părții carosabile corespunzătoare acestui sens și să execute manevra într-un spațiu cît
 mai restrîns posibil;</p><br>
 
<p style="text-indent:2em;">b) dacă vrea să părăsească drumul pe cealaltă parte, cu excepția posibilității pentru părțile contractante
 sau subdiviziunile lor de a stabili prevederi diferite pentru biciclete și motorete, să se apropie cît mai
 mult de axa drumului, dacă este vorba de un drum cu circulație în ambele sensuri, sau de marginea opusă părții
 care corespunde sensului circulației, dacă este vorba de un drum cu sens unic, și, dacă vrea să se angajeze pe
 un alt drum unde circulația se face în ambele sensuri, să execute manevra în așa fel încît să intre pe acest drum
 pe partea corespunzătoare sensului circulației.</p><br>
 
<p style="text-indent:2em;">2. În timpul manevrei de schimbare a direcției de mers conducătorul trebuie, sub rezerva dispozițiilor art. 21 al convenției,
 în ce privește pietonii, să lase să treacă vehiculele ce vin din sens opus pe drumul pe care se pregătește să-l părăsească,
 precum și bicicletele și motoretele care circulă pe pistele pentru biciclete ce traversează partea carosabilă pe
 care urmează să intre.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 17</p><br>

<p style="font-weight:500;">Reducerea vitezei</p><br>

<p>1. Nici un conducător de vehicul nu trebuie să efectueze o frînare bruscă neimpusă de motive de siguranță.</p><br>

<p style="text-indent:2em;">2. Orice conducător care vrea să reducă mult viteza trebuie, în afară de cazul cînd aceasta nu este justificată de un pericol
 iminent, să se asigure în prealabil că o poate face fără pericol și stînjenire excesivă pentru ceilalți conducători. Mai mult,
 el trebuie, în afară de cazul cînd s-a asigurat că nu este urmat de nici un vehicul sau că acesta se află foarte departe, să
 semnalizeze în mod clar și din timp intenția sa făcînd un semn corespunzător cu brațul; totuși, această dispoziție nu se aplică
 în cazul în care semnalul de reducere a vitezei este dat prin aprinderea luminilor stop prevăzute la paragraful 31 al anexei nr.
 5 a convenției.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 18</p><br>

<p style="font-weight:500;">Intersecții și obligația de a ceda trecerea</p><br>

<p style="text-indent:2em;">1. Orice conducător care se apropie de o intersecție trebuie să dea dovadă de o prudență sporită, potrivită condițiilor locale.
 Conducătorul unui vehicul trebuie, în special, să conducă cu o viteză care să-i dea posibilitatea de a opri pentru a lăsa să treacă
 vehiculele ce au prioritate de trecere.</p><br>
 
<p style="text-indent:2em;">2. Orice conducător care pătrunde de pe o cărare sau drum de pămînt pe un drum care nu-i nici cărare, nici drum de pămînt, este
 obligat să cedeze trecerea vehiculelor care circulă pe acest drum. În sensul prezentului articol, termenii de cărare și drum de
 pămînt vor putea fi definiți în legislația națională.</p><br>
 
<p style="text-indent:2em;">3. Orice conducător care iese de pe o proprietate alăturată și intră pe un drum este obligat să cedeze trecerea vehiculelor
 care circulă pe acest drum.</p>
 
<p style="text-indent:2em;"></p><h6>4. Cu excepția dispozițiilor paragrafului 7 al prezentului articol:</h6><p></p>

<p style="text-indent:2em;">a) în statele unde sensul de circulație este pe dreapta, în intersecții altele decît cele care sînt prevăzute de paragraful 2 al
 acestui articol și de paragrafele 2 și 4 ale art. 25 al convenției, conducătorul unui vehicul este obligat să cedeze trecerea vehiculelor
 care vin din dreapta;</p><br>
 
<p style="text-indent:2em;">b) părțile contractante sau subdiviziunile lor pe teritoriul cărora sensul de circulație este pe stîngă sînt libere să stabilească
 cum doresc regulile de prioritate în intersecții.</p><br>
 
<p style="text-indent:2em;">5. Chiar dacă semnalele luminoase permit, un conducător nu trebuie să se angajeze într-o intersecție dacă densitatea circulației
 este atît de mare încît el riscă să rămînă imobilizat în intersecție, stînjenind sau împiedicînd astfel
 circulația transversală.</p><br>
 
<p style="text-indent:2em;">6. Orice conducător intrat într-o intersecție unde circulația este dirijată prin semnale luminoase poate părăsi intersecția fără să
 aștepte ca circulația să fie deschisă în sensul în care intenționează să meargă, cu condiția să nu stînjenească circulația celorlalți
 participanți la circulație care înaintează în sensul în care circulația este deschisă.</p><br>
 
<p style="text-indent:2em;">7. În intersecții conducătorii vehiculelor care nu se deplasează pe șine au obligația de a ceda trecerea vehiculelor care se
 deplasează pe șine.</p><br>

<p style="font-weight:500;">ARTICOLUL 19</p><br>

<p style="font-weight:500;">Treceri la nivel</p>

<p style="text-indent:2em;"></p><h6>Orice participant la circulație trebuie să dea dovadă de prudență, sporită la apropierea și la traversarea trecerilor la nivel.
 În special:</h6><p></p>
 
<p>a) orice conducător de vehicul trebuie să circule cu viteză redusă;</p><br>

<p style="text-indent:2em;">b) în afara obligației de a se supune indicației de oprire date de un semnal luminos sau acustic, nici un participant la
 circulație nu trebuie să se angajeze pe o trecere la nivel ale cărei bariere sau semibariere sînt coborîte sau pe cale de
 a fi coborîte ori ale cărei semibariere sînt în curs de ridicare;</p><br>
 
<p style="text-indent:2em;">c) dacă o trecere la nivel nu este prevăzută cu bariere, cu semibariere sau cu semnale luminoase, nici un participant la circulație
 nu trebuie să intre fără a se fi asigurat că nu se apropie nici un vehicul pe șine;</p><br>
 
<p style="text-indent:2em;">d) nici un participant la circulație nu trebuie să prelungească nejustificat traversarea trecerii la nivel; în caz de imobilizare
 forțată a unui vehicul, conducătorul lui trebuie să se străduiască să-l scoată în afara căii ferate și, în cazul în care nu poate
 să o facă, să ia imediat toate măsurile ce-i stau în putință pentru ca mecanicii vehiculelor pe șine să fie preveniți din timp de
 existența pericolului.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 20</p><br>

<p style="font-weight:500;">Dispoziții aplicabile pietonilor</p><br>

<p style="text-indent:2em;">1. Părțile contractante sau subdiviziunile lor vor putea să nu aplice dispozițiile prezentului articol decît în cazul în
 care circulația pietonilor pe partea carosabilă ar fi periculoasă sau stînjenitoare pentru circulația vehiculelor.</p>
 
<p style="text-indent:2em;"></p><h6>2. Dacă există trotuare sau acostamente practicabile pentru pietoni, aceștia trebuie să circule pe ele. Totuși, luînd
 măsurile de precauție necesare:</h6><p></p>
 
<p style="text-indent:2em;">a) pietonii care împing sau transportă obiecte stînjenitoare pot merge pe partea carosabilă dacă circulația lor pe
 trotuar sau acostament ar cauza o stînjenire importantă celorlalți pietoni;</p><br>
 
<p>b) grupurile de pietoni conduse de un responsabil și care formează un cortegiu pot circula pe partea carosabilă.</p><br>

<p style="text-indent:2em;">3. Dacă nu este posibil să se folosească trotuarele sau acostamentele ori în lipsa acestora, pietonii pot circula pe partea
 carosabilă; atunci cînd există o pistă pentru biciclete, iar densitatea circulației le permite, ei pot circula pe această
 pistă, dar fără să stînjenească trecerea bicicliștilor și a conducătorilor de motorete.</p><br>
 
<p style="text-indent:2em;">4. Atunci cînd, în aplicarea paragrafelor 2 și 3 ale prezentului articol, pietonii circulă pe partea carosabilă, ei trebuie
 să meargă cît mai aproape posibil de marginea părții carosabile.</p><br>
 
<p style="text-indent:2em;">5. Se recomandă ca legislațiile naționale să prevadă cele ce urmează: atunci cînd pietonii circulă pe partea carosabilă, ei trebuie,
 afară de cazul cînd aceasta este de natură a-i pune în pericol, să meargă pe partea opusă celei corespunzătoare sensului circulației.
 Totuși, persoanele care împing cu mîna o bicicletă, o motoretă sau o motocicletă trebuie totdeauna să circule pe partea corespunzătoare
 sensului circulației ca și grupurile de pietoni conduse de un responsabil sau care formează un cortegiu. Cu excepția cazului cînd formează
 un cortegiu, pietonii care circulă pe partea carosabilă trebuie ca, în timpul nopții sau cînd vizibilitatea este redusă, precum și ziua,
 cînd densitatea circulației o cere, să meargă pe cît posibil pe un singur șir.</p>
 
<p style="text-indent:2em;"></p><h6>6:</h6><p></p>

<p style="text-indent:2em;">a) Pietonii nu trebuie să se angajeze pe partea carosabilă pentru a o traversa decît dînd dovadă de prudență; ei
 trebuie să folosească trecerea pentru pietoni atunci cînd există în apropiere o asemenea trecere.</p>
 
<p style="text-indent:2em;"></p><h6>b) Pentru a traversa o trecere pentru pietoni semnalizată ca atare sau delimitată de marcaje pe partea carosabilă:</h6><p></p>

<p>i) dacă trecerea este prevăzută cu semnale luminoase pentru pietoni, aceștia trebuie să se supună semnificației acestora;</p><br>

<p style="text-indent:2em;">ii) dacă trecerea nu este prevăzută cu o astfel de semnalizare, dar circulația vehiculelor este dirijată prin semnale luminoase sau de
 către un agent de circulație, pietonii nu trebuie să intre pe partea carosabilă atît timp cît semnalul luminos sau gestul agentului de
 circulație permite trecerea vehiculelor;</p><br>
 
<p style="text-indent:2em;">iii) la celelalte treceri pentru pietoni, pietonii nu trebuie să intre pe partea carosabilă fără să țină seama de distanța și viteza
 vehiculelor care se apropie.</p><br>
 
<p style="text-indent:2em;">c) Pentru a traversa în afara unei treceri pentru pietoni semnalizată ca atare sau delimitată de marcaje pe partea carosabilă,
 pietonii nu trebuie să se angajeze mai înainte de a se fi asigurat că o pot face fără să stînjenească circulația
 vehiculelor.</p><br>
 
<p style="text-indent:2em;">d) Odată angajați în traversarea părții carosabile, pietonii nu trebuie să prelungească trecerea, să întîrzie sau să se oprească
 fără motiv.</p><br>
 
<p style="text-indent:2em;">7. Totuși, părțile contractante sau subdiviziunile lor pot prevedea dispoziții mai stricte pentru pietonii care traversează
 partea carosabilă.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 21</p><br>

<p style="font-weight:500;">Conduita conducătorilor față de pietoni</p>

<p style="text-indent:2em;"></p><h6>1. Cu excepția dispozițiilor paragrafului 1 al art. 7, ale paragrafului 9 al art. 11 și ale paragrafului 1 al art. 13 din convenție,
 cînd pe partea carosabilă există o trecere pentru pietoni semnalizată ca atare sau delimitată prin marcaje:</h6><p></p>
 
<p style="text-indent:2em;">a) dacă circulația vehiculelor este dirijată la această trecere de semnale luminoase sau de un agent de circulație, conducătorii
 trebuie, atunci cînd le este interzis să treacă, să oprească înainte de a se angaja pe trecere, iar atunci cînd le este permis să
 treacă, să nu împiedice sau să stînjenească trecerea pietonilor care sînt angajați pe trecere și traversează în condițiile prevăzute
 de art. 20 din convenție; dacă conducătorii virează pentru a intra pe un alt drum la începutul căruia se găsește o trecere pentru pietoni,
 ei nu trebuie s-o facă decît cu viteză redusă și chiar dacă în acest scop trebuie să oprească lăsînd să treacă pietonii angajați sau care
 se angajează pe o trecere în condițiile prevăzute de paragraful 6 al art. 20 din convenție;</p><br>
 
<p style="text-indent:2em;">b) dacă circulația vehiculelor nu este dirijată la această trecere de semnale luminoase sau de un agent de circulație, conducătorii nu
 trebuie să se apropie de această trecere decît cu viteză suficient de redusă pentru a nu pune în pericol pietonii angajați sau care
 se pregătesc să se angajeze în traversare; la nevoie, ei trebuie să oprească pentru a-i lăsa să treacă.</p><br>

<p style="text-indent:2em;">2. Conducătorii care au intenția să depășească, pe partea corespunzătoare sensului circulației, un vehicul de transport în comun oprit
 într-o stație semnalizată ca atare, trebuie să reducă viteza și, la nevoie, să oprească pentru a permite călătorilor să urce în vehicul
 sau să coboare din acesta.</p>
 
<p style="text-indent:2em;"></p><h6>3. Nici o dispoziție din prezentul articol nu va fi interpretată ca împiedicînd părțile contractante sau subdiviziunile
 lor:</h6><p></p>

<p style="text-indent:2em;"> să oblige pe conducătorii unui vehicul să oprească ori de cîte ori pietonii s-au angajat ori se angajează pe o trecere pentru pietoni
 semnalizată ca atare sau delimitată prin marcaje pe partea carosabilă, în condițiile prevăzute de art. 20 al convenției, sau</p><br>
 
<p style="text-indent:2em;"> să le interzică de a împiedica sau stînjeni mersul pietonilor care traversează o intersecție sau foarte aproape de o intersecție,
 chiar dacă în acel loc nu există o trecere pentru pietoni, semnalizată ca atare sau delimitată prin marcaje pe partea carosabilă.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 22</p><br>

<p style="font-weight:500;">Refugii pe partea carosabilă</p>

<p style="text-indent:2em;"></p><h6>Cu excepția dispozițiilor art. 10 al convenției, orice conducător poate să lase la stînga sau la dreapta lui refugiile, bornele și
 celelalte dispozitive existențe pe partea carosabilă, cu excepția următoarelor cazuri:</h6><p></p>
 
<p>a) atunci cînd un indicator impune trecerea pe una din laturile refugiului, bornei sau dispozitivului;</p><br>

<p style="text-indent:2em;">b) atunci cînd refugiul, borna sau dispozitivul se află pe axa drumului unde circulația se face în două sensuri; în acest ultim caz,
 conducătorul trebuie să lase refugiul, borna sau dispozitivul pe partea opusă aceleia care corespunde sensului circulației.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 23</p><br>

<p style="font-weight:500;">Oprirea și staționarea</p><br>

<p style="text-indent:2em;">1. În afara localităților, vehiculele și animalele trebuie să fie, pe cît posibil, oprite sau staționate în afara părții carosabile.
 Ele nu trebuie lăsate pe pistele pentru biciclete și, cu excepția cazului în care legislația națională o permite, nici pe trotuarele
 sau acostamentele amenajate pentru circulația pietonilor.</p>

<p style="text-indent:2em;"></p><h6>2:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) Animalele și vehiculele oprite sau staționate pe partea carosabilă trebuie să stea cît mai aproape posibil de marginea acesteia.
 Un conducător nu trebuie să oprească vehiculul sau să staționeze pe partea carosabilă decît pe partea care corespunde pentru el sensului
 circulației; totuși, oprirea sau staționarea este permisă și pe cealaltă parte atunci cînd nu este posibilă pe partea care corespunde
 sensului circulației ca urmare a existenței unei căi ferate. În plus, părțile contractante sau subdiviziunile lor pot:</h6><p></p>
 
<p style="text-indent:2em;">i) să nu interzică, în anumite condiții, oprirea sau staționarea pe o parte sau pe cealaltă parte, mai ales dacă indicatoare rutiere
 interzic oprirea pe partea corespunzătoare sensului circulației;</p><br>
 
<p style="text-indent:2em;">ii) pe drumurile cu sens unic, să permită oprirea și staționarea pe cealaltă parte, concomitent sau nu cu oprirea sau
 staționarea pe partea care corespunde sensului circulației;</p><br>
 
<p>iii) să permită oprirea și staționarea în mijlocul părții carosabile în locuri special indicate.</p><br>

<p style="text-indent:2em;">b) În afara unor dispoziții contrare din legislația națională, vehiculele altele decît bicicletele cu două roți,
 motoretele sau motocicletele fără ataș nu trebuie să fie oprite sau staționate pe partea carosabilă pe două rînduri.
 Vehiculele oprite sau staționate trebuie, cu excepția cazurilor în care dispunerea locurilor permite altfel, să fie
 așezate paralel cu marginea părții carosabile.</p>
 
<p style="text-indent:2em;"></p><h6>3:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) Orice oprire și staționare a unul vehicul sînt interzise pe partea carosabilă:</h6><p></p>

<p>i) pe trecerile pentru pietoni, pe cele pentru bicicliști și pe trecerile la nivel;</p><br>

<p style="text-indent:2em;">ii) pe șinele de tramvai sau de tren ori atît de aproape de aceste șine încît circulația tramvaielor sau a trenurilor 
ar putea fi împiedicată, precum și cu condiția ca părțile contractante sau subdiviziunile lor să prevadă dispoziții contrare,
 pe trotuare și pe pistele pentru biciclete;</p>

<p style="text-indent:2em;"></p><h6>b) Oprirea și staționarea unui vehicul sînt interzise în orice loc unde acestea ar constitui un pericol, în special:</h6><p></p>

<p>i) sub pasajele superioare și în tunele, cu excepția eventual a unor locuri special indicate;</p><br>

<p style="text-indent:2em;">ii) pe partea carosabilă, în apropierea vîrfurilor de pante și în curbe, cînd vizibilitatea este insuficientă,
 pentru ca depășirea vehiculului să se poată face în deplină siguranță, ținînd seama de viteza vehiculelor pe
 acel sector de drum;</p><br>
 
<p style="text-indent:2em;">iii) pe partea carosabilă în dreptul marcajului longitudinal, atunci cînd alin. b) ii) al prezentului paragraf
 nu se aplică, dacă lățimea părții carosabile între marcaj și vehicul este sub 3 m, iar marcajul interzice încălcarea
 lui de către vehiculele care circulă pe aceeași parte.</p>
 
<p style="text-indent:2em;"></p><h6>c) Staționarea unui vehicul pe partea carosabilă este interzisă:</h6><p></p>

<p style="text-indent:2em;">i) în apropiere de trecerile la nivel, de intersecții și de stațiile pentru autobuze, troleibuze și vehicule
 pe șine, pe distanțele prevăzute de legislația națională;</p><br>
 
<p>ii) în fața intrărilor carosabile ale proprietăților;</p><br>

<p style="text-indent:2em;">iii) în orice loc unde vehiculul staționat ar împiedica accesul la un alt vehicul staționat regulamentar sau
 degajarea unui asemenea vehicul;</p><br>
 
<p style="text-indent:2em;">iv) pe banda centrală a drumurilor cu trei părți carosabile și, în afara localităților, pe părțile carosabile ale
 drumurilor indicate ca prioritare de o semnalizare corespunzătoare;</p><br>
 
<p style="text-indent:2em;">v) în locurile unde vehiculul staționat ar acoperi vederii participanților la circulație indicatoarele sau semnalele
 luminoase de circulație.</p><br>
 
<p style="text-indent:2em;">4. Un conducător nu trebuie să-și părăsească vehiculul sau animalele fără să fi luat toate măsurile necesare pentru a
 evita un accident și, în cazul unui automobil, pentru a evita să fie folosit fără aprobare.</p>
 
<p style="text-indent:2em;"></p><h6>5. Se recomandă ca legislațiile naționale să prevadă că orice vehicul cu motor altul decît o motoretă sau o motocicletă
 fără ataș, precum și orice remorcă, cuplată sau nu, care, se află imobilizată pe partea carosabilă în afara unei localități,
 să fie semnalizată de la distanță, cu ajutorul unui dispozitiv corespunzător așezat în locul cel mai potrivit pentru a-i
 avertiza din timp pe ceilalți conducători care se apropie:</h6><p></p>
 
<p style="text-indent:2em;">a) atunci cînd vehiculul este imobilizat noaptea pe partea carosabilă în astfel de condiții încît conducătorii care se
 apropie nu-și pot da seama de obstacolul existent;</p><br>
 
<p>b) atunci cînd conducătorul, în alte cazuri, a fost constrîns să imobilizeze vehiculul într-un loc unde oprirea
 este interzisă.</p><br>
 
<p style="text-indent:2em;">6. Nimic din prezentul articol nu ar putea fi interpretat ca împiedicînd părțile contractante sau subdiviziunile
 lor să impună alte interdicții de staționare și oprire.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 24</p><br>

<p style="font-weight:500;">Deschiderea ușilor</p><br>

<p style="text-indent:2em;">Este interzis de a deschide ușa unui vehicul, de a o lăsa deschisă sau de a coborî din vehicul fără a se fi
 asigurat că nu poate să rezulte un pericol pentru ceilalți participanți la circulație.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 25</p><br>

<p style="font-weight:500;">Autostrăzi și drumuri cu caracter asemănător</p>

<p style="text-indent:2em;"></p><h6>1. Pe autostrăzi și, dacă legislația națională dispune astfel, pe drumurile speciale de intrare pe
 autostrăzi și de ieșire de pe autostrăzi:</h6><p></p>
 
<p style="text-indent:2em;">a) circulația este interzisă pietonilor, animalelor, bicicletelor, motoretelor, dacă acestea nu
 sînt asimilate motocicletelor, și tuturor vehiculelor altele decît automobilele și remorcile lor,
 precum și automobilelor sau remorcilor lor care, prin construcție, nu pot atinge în linie dreaptă
 o viteză fixată de legislația națională;</p>

<p style="text-indent:2em;"></p><h6>b) este interzis conducătorilor:</h6><p></p>

<p style="text-indent:2em;">i) să oprească sau să staționeze vehiculele în altă parte decît în locurile de staționare semnalizate;
 în caz de imobilizare forțată a unui vehicul, conducătorul său trebuie să se străduiască să-l ducă în
 afara părții carosabile și chiar în afara benzii de urgență, iar dacă nu o poate face, să semnalizeze
 imediat de la distanță prezența vehiculului, pentru a avertiza din timp pe ceilalți conducători care
 se apropie;</p><br>
 
<p style="text-indent:2em;">ii) să întoarcă sau să dea înapoi ori să pătrundă pe banda centrală, inclusiv pe racordurile care leagă
 între ele cele două părți carosabile.</p>
 
<p style="text-indent:2em;"></p><h6>2. Conducătorii care pătrund pe o autostradă trebuie:</h6><p></p>

<p style="text-indent:2em;">a) dacă nu există bandă de accelerație în continuarea drumului de acces, să cedeze trecerea vehiculelor
 care circulă pe autostradă;</p><br>
 
<p style="text-indent:2em;">b) dacă există bandă de accelerație, să circule pe aceasta și să intre în circulația autostrăzii respectînd
 prevederile paragrafelor 1 și 3 ale art. 14 din convenție.</p><br>
 
<p style="text-indent:2em;">3. Conducătorul care părăsește autostrada trebuie ca, din timp, să circule pe banda corespunzătoare ieșirii
 de pe autostradă și să se angajeze cît mai curînd pe banda de decelerație dacă există o astfel de bandă.</p><br>
 
<p style="text-indent:2em;">4. Pentru aplicarea paragrafelor 1, 2 și 3 ale prezentului articol sînt asimilate autostrăzilor celelalte
 drumuri rezervate circulației automobilelor semnalizate ca atare și care nu fac legătura cu proprietăți
 alăturate.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 26</p><br>

<p style="font-weight:500;">Dispoziții speciale aplicabile cortegiilor și infirmilor</p><br>

<p style="text-indent:2em;">1. Este interzis participanților la circulație să întrerupă coloanele militare, grupurile de elevi
 sub conducerea unui responsabil și celelalte cortegii.</p><br>
 
<p style="text-indent:2em;">2. Infirmii care se deplasează cu un scaun rulant mișcat de ei înșiși sau care circulă cu viteză
 redusă pot merge pe trotuare și pe acostamentele practicabile.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 27</p><br>

<p style="font-weight:500;">Dispoziții speciale aplicabile bicicliștilor,
conducătorilor de motorete și de motociclete</p><br>

<p style="text-indent:2em;">1. Cu toate dispozițiile paragrafului 3 al art. 10 din convenție, părțile contractante sau subdiviziunile
 lor pot să nu interzică bicicliștilor de a circula mai mulți unul lîngă altul.</p><br>
 
<p style="text-indent:2em;">2. Este interzis bicicliștilor să circule fără să țină ghidonul cel puțin cu o mînă, să fie remorcați
 de un alt vehicul sau de a transporta, trage sau împinge obiecte stînjenitoare pentru conducere sau
 periculoase pentru ceilalți participanți la circulație. Aceleași dispoziții sînt aplicabile conducătorilor
 de motorete și de motociclete dar, în plus, aceștia trebuie să țină ghidonul cu ambele mîini, cu excepția
 cazului în care eventual semnalizează manevra descrisă la paragraful 3 al art. 14 din convenție.</p><br>
 
<p style="text-indent:2em;">3. Este interzis bicicliștilor și conducătorilor de motorete de a transporta pasageri pe vehiculul lor; părțile
 contractante sau subdiviziunile lor pot, totuși, să permită excepții de la această dispoziție permițînd, în special,
 transportul pasagerilor pe locul sau locurile suplimentare amenajate pe bicicletă. Nu este permis motocicliștilor să
 transporte pasageri decît în ataș, în cazul în care acesta există, și pe locul suplimentar amenajat eventual în spatele
 conducătorului.</p><br>
 
<p style="text-indent:2em;">4. În cazul cînd există o pistă pentru biciclete, părțile contractante sau subdiviziunile lor pot interzice 
bicicliștilor să circule pe partea carosabilă. În acest caz, ele pot permite conducătorilor de motorete să
 circule pe pista pentru biciclete și, dacă consideră util, să le interzică să circule pe restul părții
 carosabile.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 28</p><br>

<p style="font-weight:500;">Avertismente sonore și luminoase</p>

<p style="text-indent:2em;"></p><h6>1. Pot fi utilizate avertizoarele sonore numai:</h6><p></p>

<p>a) pentru a da avertismentele necesare în vederea evitării unui accident;</p><br>

<p>b) în afara localităților, atunci cînd se avertizează un conducător care urmează să fie depășit.</p><br>

<p>Emiterea sunetelor de către avertizoarele sonore nu trebuie să se prelungească mai mult decît este necesar.</p><br>

<p style="text-indent:2em;">2. Conducătorii de automobile pot să utilizeze, de la căderea nopții și pînă la ivirea zorilor,
 avertismentele luminoase prevăzute de paragraful 5 al art. 33 din convenție în loc de avertismentele sonore.
 Ei o pot face și ziua în scopurile indicate la alin. b) al paragrafului 1 al prezentului articol,
 dacă acest mod de a proceda este mai potrivit în raport de împrejurări.</p><br>
 
<p style="text-indent:2em;">3. Părțile contractante sau subdiviziunile lor pot permite folosirea avertismentelor luminoase,
 în scopurile prevăzute la paragraful 1 b) al prezentului articol, și în localități.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 29</p><br>

<p style="font-weight:500;">Vehicule pe șine</p><br>

<p style="text-indent:2em;">1. Atunci cînd o cale ferată este situată pe partea carosabilă, oricare participant la circulație trebuie,
 la apropierea unui tramvai sau a oricărui alt vehicul pe șine, să o elibereze îndată ce este posibil, pentru
 a permite trecerea vehiculului pe șine.</p><br>
 
<p style="text-indent:2em;">2. Părțile contractante sau subdiviziunile lor pot adopta, pentru circulația pe drum a vehiculelor care se
 deplasează pe șine și pentru încrucișarea cu vehicule ce circulă din sens opus sau pentru depășirea lor,
 reguli speciale diferite de cele stabilite în prezentul capitol. Totuși, părțile contractante sau subdiviziunile
 lor nu pot să adopte dispoziții contrare celor de la paragraful 7 al art. 18 din convenție.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 30</p><br>

<p style="font-weight:500;">Încărcarea vehiculelor</p><br>

<p style="text-indent:2em;">1. Dacă pentru un vehicul este stabilită o greutate maximă autorizată, greutatea în sarcină a acestui
 vehicul nu trebuie să depășească niciodată greutatea maximă autorizată.</p>
 
<p style="text-indent:2em;"></p><h6>2. Orice încărcătură a unui vehicul trebuie să fie așezată și, la nevoie, fixată în așa fel încît
 să nu poată:</h6><p></p>
 
<p>a) pune în pericol persoane sau provoca pagube proprietăților publice ori private, în special
 să fie tîrîtă ori să cadă pe drum;</p><br>
 
<p>b) dăuna vizibilității conducătorului sau primejdui stabilitatea ori conducerea vehiculului;</p><br>

<p>c) provoca zgomot, praf sau alte inconveniente ce pot fi evitate;</p><br>

<p style="text-indent:2em;">d) masca luminile, inclusiv luminile de frînare, indicatoare de direcție, catadioptrii, numerele de înmatriculare
 și semnul distinctiv al statului de înmatriculare cu care vehiculul trebuie să fie prevăzut în condițiile convenției
 sau ale legislației naționale, ori masca semnele făcute cu brațul, conform dispozițiilor paragrafului 3 al art. 14 sau
 ale paragrafului 2 al art. 17 din convenție.</p><br>
 
<p style="text-indent:2em;">3. Accesoriile, cum ar fi cablurile, lanțurile, prelatele, ce servesc la ancorarea sau la protejarea încărcăturii,
 trebuie să fie bine strînse și solid fixate. Toate accesoriile ce servesc la protejarea încărcăturii trebuie să 
 îndeplinească condițiile prevăzute pentru încărcare la paragraful 2 al prezentului articol.</p>
 
<p style="text-indent:2em;"></p><h6>4. Încărcăturile care depășesc vehiculul spre în față, spre în spate sau pe părți trebuie să fie semnalizate vizibil
 în toate cazurile în care marginile lor riscă să nu fie observate de conducătorii celorlalte vehicule; noaptea, această
 semnalizare trebuie să fie făcută în față cu un semnal luminos alb și un dispozitiv reflectorizant alb, iar la spate
 printr-o lumină roșie și un dispozitiv reflectorizant roșu. În special, pe vehiculele cu motor:</h6><p></p>
 
<p style="text-indent:2em;">a) încărcăturile care depășesc extremitatea vehiculului cu mai mult de un metru în spate sau în față trebuie să fie
 totdeauna semnalizate;</p><br>
 
<p style="text-indent:2em;">b) încărcăturile care depășesc lateral gabaritul vehiculului în așa fel încît extremitatea lor laterală să se găsească
 la peste 0,40 m de marginea exterioară a luminii de poziție din fața vehiculului trebuie să fie semnalizate noaptea spre
 înainte ca și spre înapoi de cele a căror extremitate laterală se află la peste 0,40 m de marginea exterioară a luminii
 de poziție roșii din spate a vehiculului.</p><br>
 
<p style="text-indent:2em;">5. Nimic din paragraful 4 al prezentului articol nu ar trebui interpretat ca împiedicînd părțile contractante sau
 subdiviziunile lor de a interzice, limita sau supune unei autorizații speciale depășirile de încărcătură prevăzute
 la paragraful 4.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 31</p><br>

<p style="font-weight:500;">Conduita în caz de accident</p>

<p style="text-indent:2em;"></p><h6>1. Cu excepția dispozițiilor din legislațiile naționale în ceea ce privește obligația de a acorda ajutor răniților,
 oricare conducător sau alt participant la circulație, implicat într-un accident de circulație, trebuie:</h6><p></p>

<p>a) să oprească imediat ce este posibil fără să creeze un pericol suplimentar pentru circulație;</p><br>

<p style="text-indent:2em;">b) să se străduiască să mențină siguranța circulației la locul accidentului și, dacă o persoană a fost ucisă sau rănită
 grav în accident, să evite, în măsura în care aceasta nu afectează siguranța circulației, modificarea stării locului
 și dispariția urmelor ce pot fi utile pentru a stabili răspunderile;</p><br>
 
<p>c) dacă alte persoane implicate în accident i-o cer, să le comunice identitatea;</p><br>

<p style="text-indent:2em;">d) dacă o persoană a fost rănită sau ucisă în accident, să înștiințeze poliția și să rămînă sau să revină la locul 
accidentului pînă la sosirea acesteia, în afară de cazul în care n-a fost autorizată de aceasta să părăsească locul 
sau cînd nu ar trebui să acorde îngrijiri răniților ori să fie el însuși îngrijit.</p><br>

<p style="text-indent:2em;">2. Părțile contractante sau subdiviziunile lor pot, în legislația națională, să nu impună prevederea de la alin. 
d) al paragrafului 1 al prezentului articol, cînd n-a fost produsă nici o rănire gravă și nici una dintre persoanele 
implicate în accident nu cere ca poliția să fie înștiințată.</p><br>

<p style="font-weight:500;">ARTICOLUL 32</p><br>

<p style="font-weight:500;">Iluminarea: dispoziții generale</p><br>

<p style="text-indent:2em;">1. În sensul prezentului articol, termenul noapte înseamnă intervalul dintre căderea nopții și ivirea zorilor,
 precum și alte momente în care vizibilitatea este redusă datorită, de exemplu, ceții, căderii zăpezii, ploii
 torențiale sau trecerii prin tunel.</p>
 
<p style="text-indent:2em;"></p><h6>2. Pe timp de noapte:</h6><p></p>

<p style="text-indent:2em;">a) orice vehicul cu motor, altul decît o motocicletă sau o motoretă cu 2 roți fără ataș, care se află
 pe un drum, trebuie să aibă în față cel puțin două lumini albe sau galbene selectiv, iar în spate un
 număr cu soț de lumini roșii, conform dispozițiilor prevăzute pentru automobile la paragrafele 23 și
 24 ale anexei nr. 5; legislațiile naționale pot permite în față lumini de poziție galbene auto.
 Dispozițiile prezentului alineat se aplică și ansamblurilor formate dintr-un vehicul cu motor cu
 una sau mai multe remorci, luminile roșii trebuind să se găsească în acest caz în spatele ultimei
 remorci; remorcile cărora le sînt aplicabile dispozițiile paragrafului 30 din anexa nr. 5 a convenției
 trebuie să aibă, în față, cele două lumini albe cu care trebuia să fie prevăzute conform dispozițiilor
 acestui paragraf 30;</p><br>
 
<p style="text-indent:2em;">b) orice vehicul sau ansamblu de vehicule căruia nu i se aplică dispozițiile alin. a) al prezentului
 paragraf și care se află pe un drum trebuie să aibă cel puțin o lumină albă sau galbenă selectiv în 
 față și cel puțin o lumină roșie în spate; atunci cînd nu are decît o lumină în față sau una în spate,
 aceasta trebuie să fie situată pe axa vehiculului sau pe partea opusă aceleia care corespunde sensului
 circulației; pentru vehiculele cu tracțiune animală și cărucioare dispozitivul care emite aceste lumini
 poate fi purtat de conducător sau de un însoțitor care merge pe această parte a vehiculului.</p><br>
 
<p style="text-indent:2em;">3. Luminile prevăzute la paragraful 2 al prezentului articol trebuie să fie astfel încît să semnalizeze
 în mod efectiv vehiculul celorlalți participanți la circulație; lumina din față și cea din spate nu
 trebuie să fie emise de aceeași lampă sau de același dispozitiv decît dacă caracteristicile vehiculului,
 în special lungimea sa redusă, sînt astfel încît pot fi satisfăcute în aceste condiții.</p>

<p style="text-indent:2em;"></p><h6>4:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) Prin derogare de la dispozițiile paragrafului 2 al prezentului articol:</h6><p></p>

<p style="text-indent:2em;">i) dispozițiile acestui paragraf 2 nu se aplică vehiculelor oprite sau staționate pe un drum iluminat
 în așa fel încît acestea să fie vizibile de la o distanță suficientă;</p><br>
 
<p style="text-indent:2em;">ii) vehiculele cu motor a căror lungime și lățime nu depășesc 6 m și, respectiv, 2 m și la care nu
 este cuplat nici un vehicul vor putea, atunci cînd sînt oprite sau staționate pe un drum dintr-o
 localitate, să nu aibă decît o lumină instalată pe partea vehiculului opusă marginii părții carosabile
 unde vehiculul este oprit sau staționat; această lumină va fi albă sau galbenă auto în față și roșie
 sau galbenă auto în spate;</p><br>
 
<p style="text-indent:2em;">iii) dispozițiile alin. b) al paragrafului 2 nu se aplică bicicletelor sau motoretelor și nici
 motocicletelor fără ataș neechipate cu baterie, atunci cînd sînt oprite sau staționate într-o 
 localitate la marginea părții carosabile.</p>

<p style="text-indent:2em;"></p><h6>b) Mai mult, legislația națională poate acorda derogări de la dispozițiile prezentului articol pentru:</h6><p></p>

<p>i) vehiculele oprite sau staționate în locuri speciale în afara părții carosabile;</p><br>

<p>ii) vehiculele oprite sau staționate pe străzi unde circulația este foarte redusă.</p><br>

<p style="text-indent:2em;">5. În nici un caz un vehicul nu trebuie să aibă în față lumini, dispozitive reflectorizante sau materiale
 reflectorizante roșii, iar în spate lumini, dispozitive reflectorizante sau materiale reflectorizante albe
 sau galbene selectiv; această dispoziție nu se aplică în cazul folosirii de lumini albe sau galbene selectiv
 pentru mersul înapoi, reflectorizării cifrelor sau literelor de culoare deschisă ale plăcilor de înmatriculare
 din spate, semnelor distinctive sau altor însemne cerute de legislația națională, reflectorizării fondului deschis
 al acestor plăci sau însemne și nici luminilor roșii giratoare sau fulger ale unor vehicule prioritare.</p>

<p style="text-indent:2em;"></p><h6>6. Părțile contractante sau subdiviziunile lor pot, în măsura în care ele apreciază ca posibil fără să pericliteze
 siguranța circulației, să pună de acord legislația lor națională cu derogările de la dispozițiile prezentului
 articol pentru:</h6><p></p>
 
<p>a) vehiculele cu tracțiune animală și cărucioare;</p><br>

<p>b) vehiculele de formă sau de natură specială ori utilizate în scopuri și în condiții speciale.</p><br>

<p style="text-indent:2em;">7. Nimic din această convenție nu va putea fi interpretat ca împiedicînd legislația națională să impună grupurilor
 de pietoni conduși de un responsabil sau care formează un cortegiu, precum și conducătorilor de animale, izolate
 sau turme, de animale de tras, de cărăușie sau de călărie, de a avea, atunci cînd circulă pe partea carosabilă în
 situațiile arătate la paragraful 2 b) al prezentului articol, un dispozitiv reflectorizant sau o lumină; lumina
 reflectată sau emisă trebuie să fie în acest caz albă sau galbenă selectiv în față și roșie în spate, ori
 galbenă auto în ambele direcții.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 33</p><br>

<p style="font-weight:500;">Iluminarea: condiții de folosire a luminilor prevăzute în anexa nr. 5</p>

<p style="text-indent:2em;"></p><h6>1. Conducătorul unui vehicul echipat cu lumini de drum, cu lumini de întîlnire sau cu lumini de poziție definite
 în anexa nr. 5 a convenției trebuie să folosească aceste lumini în condițiile următoare cînd, conform art. 32 din
 convenție, vehiculul trebuie să aibă cel puțin una sau două lumini albe sau galbene selectiv în față:</h6><p></p>
 
<p style="text-indent:2em;">a) luminile de drum nu trebuie să fie aprinse nici în localități, atunci cînd drumul este suficient de iluminat
 și nici în afara localităților, atunci cînd partea carosabilă este iluminată în mod continuu, iar această iluminare
 este suficientă pentru a permite conducătorului să vadă în mod distinct pînă la o distanță suficientă, precum și
 nici atunci cînd vehiculul este oprit;</p>
 
<p style="text-indent:2em;"></p><h6>b) cu excepția posibilității ca legislația națională să permită folosirea luminilor de drum în timpul zilei sau
 cînd vizibilitatea este insuficientă datorită, de exemplu, ceții, ninsorii, ploii torențiale sau trecerii prin
 tunel, luminile de drum nu trebuie să fie aprinse ori funcționarea lor trebuie să fie schimbată în așa fel încît
 să evite orbirea cînd:</h6><p></p>
 
<p style="text-indent:2em;">i) un conducător urmează să treacă pe lîngă alt vehicul ce circulă din sens opus; luminile, în cazul în care sînt
 folosite, trebuie să fie stinse sau schimbate în așa fel încît să evite orbirea la o distanță necesară pentru ca
 conducătorul celuilalt vehicul să poată continua drumul lesne și fără pericol;</p><br>
 
<p style="text-indent:2em;">ii) un vehicul circulă în spatele altuia la o distanță mică; totuși, luminile de drum pot fi folosite, conform
 dispozițiilor paragrafului 5 al prezentului articol, pentru a semnala intenția de a depăși în condițiile prevăzute
 la art. 28 al convenției;</p><br>
 
<p style="text-indent:2em;">iii) în orice altă împrejurare cînd trebuie să nu fie orbiți ceilalți participanți la circulație ori cei care
 folosesc o apă sau o cale ferată ce se află de-a lungul drumului;</p><br>
 
<p style="text-indent:2em;">c) cu excepția dispozițiilor de la alin. d) al prezentului paragraf, luminile de întîlnire trebuie să fie
 aprinse atunci cînd folosirea luminii de drum este interzisă de dispozițiile alin. a) și b) de mai sus și pot
 fi folosite în locul luminilor de drum atunci cînd luminile de încrucișare permit conducătorului să vadă în mod
 distinct pînă la o distanță suficientă, iar celorlalți participanți la circulație să observe vehiculul de la
 o distanță suficientă;</p><br>
 
<p style="text-indent:2em;">d) luminile de poziție trebuie să fie folosite în același timp cu luminile de drum, cu luminile de încrucișare
 sau cu luminile de ceață. Ele pot fi folosite singure, cînd vehiculul este oprit sau staționat, ori cînd pe
 drumuri, altele decît autostrăzile și drumurile menționate la paragraful 4 al art. 25 din convenție, condițiile
 de iluminare sînt de așa natură încît conducătorul poate să vadă în mod distinct pînă la o distanță suficientă,
 iar ceilalți participanți la circulație să poată observa vehiculul de la o distanță suficientă.</p><br>
 
<p style="text-indent:2em;">2. Cînd un vehicul este echipat cu lumini de ceață definite la anexa nr. 5 a convenției, nu trebuie utilizate
 aceste lumini decît în caz de ceață, de ninsoare sau de ploaie torențială. Prin derogare de la dispozițiile
 paragrafului 1 c) al prezentului articol, aprinderea luminilor de ceață înlocuiește luminile de încrucișare,
 legislația națională putînd să permită în acest caz aprinderea simultană a luminilor de ceață și a luminilor
 de încrucișare.</p><br>
 
<p style="text-indent:2em;">3. Prin derogare de la dispozițiile paragrafului 2 al prezentului articol, legislația națională poate permite,
 chiar în absența ceții, a ninsorii sau a ploii torențiale, aprinderea luminilor de ceață pe drumurile înguste
 și cu numeroase curbe.</p><br>
 
<p style="text-indent:2em;">4. Nimic din această convenție nu va putea fi interpretat ca împiedicînd legislația națională să impună
 obligația de a se folosi luminile de încrucișare în localități.</p><br>
 
<p style="text-indent:2em;">5. Avertismentele luminoase prevăzute la paragraful 2 al art. 28 din convenție constau în aprinderea intermitentă,
 la intervale scurte, a luminilor de încrucișare sau în aprinderea intermitentă a luminilor de drum ori în
 aprinderea alternantă la intervale scurte a luminilor de încrucișare și a luminilor de drum.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 34</p><br>

<p style="font-weight:500;">Excepții</p><br>

<p style="text-indent:2em;">1. Îndată ce apropierea unui vehicul prioritar este anunțată prin avertismente speciale luminoase și sonore
 ale acestui vehicul, toți participanții la circulație trebuie să elibereze trecerea și, la nevoie, să oprească.</p><br>
 
<p style="text-indent:2em;">2. Legislațiile naționale pot să prevadă ca conducătorii vehiculelor prioritare să nu fie obligați, atunci cînd
 circulația lor este anunțată prin avertismente speciale ale vehiculului și cu condiția de a nu pune în pericol
 pe ceilalți participanți la circulație, să respecte în totalitate sau în parte dispozițiile prezentului cap. II,
 altele decît acelea ale paragrafului 2 al art. 6.</p><br>

<p style="text-indent:2em;">3. Legislațiile naționale pot stabili în ce măsură personalul care lucrează la construirea, repararea sau întreținerea
 drumului, inclusiv conducătorii utilajelor folosite la aceste lucrări, nu este obligat, cu excepția luării tuturor
 măsurilor de prudență necesare, să respecte în timpul lucrului dispozițiile prezentului cap. II.</p><br>
 
<p style="text-indent:2em;">4. Pentru a depăși sau a trece pe lîngă utilajele care circulă din sens opus vizate la paragraful 3 al prezentului
 articol în timp ce participă la lucrări de drum, conducătorii celorlalte vehicule pot, în măsura necesară și cu
 condiția de a lua toate măsurile de prudență, să nu respecte dispozițiile art. 11 și 12 ale convenției.</p>
 
<p></p><h4>CAPITOLUL III</h4><p></p>

<p></p><h4>Condițiile pe care trebuie să le îndeplinească automobilele și
remorcile pentru a fi admise în circulație internațională</h4><p></p>

<p style="font-weight:500;">ARTICOLUL 35</p><br>

<p style="font-weight:500;">Înmatricularea</p>

<p style="text-indent:2em;"></p><h6>1:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) Pentru a beneficia de dispozițiile convenției, orice automobil în circulație internațională și orice remorcă,
 alta decît o remorcă ușoară, cuplată la un automobil, trebuie să fie înmatriculată de o parte contractantă sau
 de una dintre subdiviziunile ei, iar conducătorul automobilului să posede un certificat valabil eliberat pentru
 a dovedi această înmatriculare, fie de către o autoritate competentă a acestei părți contractante sau de o
 subdiviziune a sa, fie, în numele părții contractante sau a subdiviziunii sale, de către o asociație pe
 care a autorizat-o în acest scop. Certificatul, denumit certificat de înmatriculare, cuprinde cel puțin:</h6><p></p>
 
<p> un număr de ordine, denumit număr de înmatriculare, a cărui compunere este prevăzută la anexa nr. 2 a convenției;</p><br>

<p> data primei înmatriculări a vehiculului;</p><br>

<p> numele complet și domiciliul titularului certificatului;</p><br>

<p> numele sau marca de fabrică a constructorului vehiculului;</p><br>

<p> numărul de ordine al sașiului (numărul de fabrică sau numărul de serie al constructorului);</p><br>

<p> dacă este vorba de un vehicul destinat transportului de mărfuri, greutatea maximă autorizată;</p><br>

<p> perioada de valabilitate, în cazul în care nu este nelimitată.</p><br>

<p>Indicațiile trecute pe certificat sînt fie numai cu caractere latine ori cursive, denumite engleze,
 fie repetate în acest mod.</p><br>
 
<p style="text-indent:2em;">b) Părțile contractante sau subdiviziunile lor pot să dispună ca pe certificatele eliberate pe teritoriul
 lor, anul de fabricație să fie trecut în locul datei primei înmatriculări.</p><br>
 
<p style="text-indent:2em;">2. Prin excepție de la dispozițiile paragrafului 1 al prezentului articol, un vehicul articulat,
 în timp ce se află în circulație internațională, va beneficia de dispozițiile convenției chiar
 dacă nu face obiectul decît al unei singure înmatriculări și al unui singur certificat pentru
 vehiculul trăgător și semiremorca ce îl compun.</p><br>
 
<p style="text-indent:2em;">3. Nimic din această convenție nu va putea fi interpretat ca limitînd dreptul părților contractante
 sau al subdiviziunilor lor de a cere, în cazul unui vehicul în circulație internațională ce nu este
 înmatriculat pe numele unei persoane care se găsește la bord, justificarea dreptului conducătorului
 la deținerea vehiculului.</p><br>
 
<p style="text-indent:2em;">4. Se recomandă ca părțile contractante care n-au înființat încă un serviciu la nivel național
 sau regional să înregistreze vehiculele aflate în circulație și să centralizeze, pentru fiecare
 vehicul, datele înscrise pe fiecare certificat de înmatriculare.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 36</p><br>

<p style="font-weight:500;">Numărul de înmatriculare</p><br>

<p style="text-indent:2em;">1. Orice automobil în circulație internațională trebuie să aibă în față și în spate un număr
 de înmatriculare; totuși, motocicletele nu sînt obligate să aibă acest număr decît în spate.</p><br>

<p style="text-indent:2em;">2. Orice remorcă înmatriculată trebuie, în circulație internațională, să aibă în spate un număr
 de înmatriculare. În cazul unui automobil care tractează una sau mai multe remorci, remorca sau
 ultima remorcă, dacă nu este înmatriculată, trebuie să poarte numărul de înmatriculare al vehiculului
 trăgător.</p><br>
 
<p style="text-indent:2em;">3. Compunerea și modalitățile de aplicare a numărului de înmatriculare prevăzute în prezentul
 articol trebuie să fie conforme cu dispozițiile anexei nr. 2 a convenției.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 37</p><br>

<p style="font-weight:500;">Semnul distinctiv al statului de înmatriculare</p><br>

<p style="text-indent:2em;">1. Orice automobil în circulație internațională trebuie să aibă în spate, în afară de numărul de înmatriculare,
 un semn distinctiv al statului în care este înmatriculat.</p><br>
 
<p style="text-indent:2em;">2. Orice remorcă cuplată la un automobil și care trebuie, conform art. 36 al convenției, să aibă la spate
 un număr de înmatriculare, trebuie să poarte și semnul distinctiv al statului care a eliberat acest număr
 de înmatriculare. Dispozițiile prezentului paragraf se aplică chiar dacă remorca este înmatriculată într-un
 stat altul decît cel de înmatriculare a automobilului la care a fost cuplată; dacă remorca nu a fost înmatriculată,
 ea trebuie să aibă la spate semnul distinctiv al statului de înmatriculare a vehiculului trăgător, cu excepția cazului
 în care circulă în acest stat.</p><br>
 
<p style="text-indent:2em;">3. Compunerea și modalitățile de aplicare a semnului distinctiv prevăzute în prezentul articol trebuie să fie
 conforme dispozițiilor anexei nr. 3 a convenției.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 38</p><br>

<p style="font-weight:500;">Elemente de identificare</p><br>

<p style="text-indent:2em;">Orice automobil și remorcă în circulație internațională trebuie să poarte elementele de identificare
 prevăzute la anexa nr. 4 a convenției.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 39</p><br>

<p style="font-weight:500;">Prevederi tehnice</p><br>

<p style="text-indent:2em;">Orice automobil, remorcă și ansamblu de vehicule în circulație internațională trebuie să îndeplinească
 condițiile din anexa nr. 5 a convenției. Ele trebuie să fie în stare bună de funcționare.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 40</p><br>

<p style="font-weight:500;">Dispoziție tranzitorie</p><br>

<p style="text-indent:2em;">Timp de 10 ani începînd cu data intrării în vigoare a prezentei convenții, conform paragrafului 1 al art. 47,
 remorcile în circulație internațională vor beneficia, oricare ar fi greutatea lor maximă autorizată, de
 dispozițiile convenției, chiar dacă nu sînt înmatriculate.</p>
 
<p></p><h4>CAPITOLUL IV</h4><p></p>

<p></p><h4>Conducătorii de automobile</h4><p></p>

<p style="font-weight:500;">ARTICOLUL 41</p><br>

<p style="font-weight:500;">Valabilitatea permiselor de conducere</p>

<p style="text-indent:2em;"></p><h6>1. Părțile contractante vor recunoaște:</h6><p></p>

<p style="text-indent:2em;">a) orice permis redactat în limba lor sau într-una din limbile lor ori, în cazul în care
 nu este redactat într-o astfel de limbă, însoțit de o traducere certificată;</p><br>
 
<p>b) orice permis național conform dispozițiilor anexei nr. 6 a convenției; sau</p><br>

<p style="text-indent:2em;">c) orice permis internațional conform dispozițiilor anexei nr. 7 a convenției,
ca valabil pentru conducerea pe teritoriul lor a unui vehicul care intră în categoriile permisului,
 cu condiția ca permisul să fie în curs de valabilitate și să fi fost eliberat de o altă parte contractantă
 sau de una dintre subdiviziunile lor ori de către o asociație autorizată în acest scop de această altă parte
 contractantă sau de una dintre subdiviziunile sale. Dispozițiile prezentului paragraf nu se aplică permiselor
 de elev conducător.</p>
 
<p style="text-indent:2em;"></p><h6>2. Cu toate dispozițiile paragrafului precedent:</h6><p></p>

<p style="text-indent:2em;">a) cînd valabilitatea permisului de conducere este subordonată, printr-o mențiune specială, portului
 de către cel interesat al unor aparate sau amenajări ale vehiculului în funcție de invaliditatea conducătorului,
 permisul nu va fi recunoscut ca valabil decît dacă aceste dispoziții sînt respectate;</p><br>
 
<p style="text-indent:2em;">b) părțile contractante pot refuza să recunoască valabilitatea pe teritoriul lor a oricărui permis de conducere
 al cărui titular nu are 18 ani împliniți;</p><br>
 
<p style="text-indent:2em;">c) părțile contractante pot refuza să recunoască valabilitatea pe teritoriul lor, pentru conducerea vehiculelor
 sau a ansamblului de vehicule din categoriile C, D și E, prevăzute la anexele nr. 6 și 7 ale convenției, a oricărui
 permis de conducere al cărui titular nu are 21 ani împliniți.</p><br>
 
<p style="text-indent:2em;">3. Părțile contractante se angajează să ia măsurile necesare pentru ca permisele naționale și internaționale de
 conducere, prevăzute la alin. a), b) și c) ale paragrafului 1 din prezentul articol, să nu fie eliberate pe 
 teritoriul lor fără o garanție temeinică a capacităților conducătorului și a aptitudinii sale fizice.</p>
 
<p style="text-indent:2em;"></p><h6>4. Pentru aplicarea paragrafului 1 și a paragrafului 2 alin. c) al prezentului articol:</h6><p></p>

<p style="text-indent:2em;">a) automobilelor din categoria B vizate de anexele nr. 6 și 7 ale acestei convenții li se poate cupla o remorcă
 ușoară; poate, de asemenea, să li se cupleze și o remorcă a cărei greutate maximă autorizată depășește 750 kg,
 dar nu depășește greutatea în gol a automobilului, dacă totalul greutăților maxime autorizate ale vehiculelor
 astfel cuplate nu depășește 3.500 kg;</p><br>
 
<p style="text-indent:2em;">b) automobilelor din categoriile C și D vizate la anexele nr. 6 și 7 ale prezentei convenții li se poate cupla
 o remorcă ușoară, fără ca ansamblul astfel constituit să înceteze a aparține categoriei C sau categoriei D.</p><br>
 
<p style="text-indent:2em;">5. Permisul internațional nu va putea fi eliberat decît deținătorului unui permis național, pentru eliberarea
 căruia vor fi îndeplinite condițiile minime stabilite de convenție. El nu va trebui să fie valabil mai mult
 decît permisul național corespunzător, al cărui număr va trebui să figureze pe permisul internațional.</p>
 
<p style="text-indent:2em;"></p><h6>6. Dispozițiile prezentului articol nu obligă părțile contractante:</h6><p></p>

<p style="text-indent:2em;">a) să recunoască valabilitatea permiselor, naționale sau internaționale, care vor fi eliberate pe teritoriul unei
 alte părți contractante persoanelor care își au reședința pe teritoriul lor în momentul acestei eliberări sau a
 căror reședință a fost transferată pe teritoriul lor după această eliberare;</p><br>
 
<p style="text-indent:2em;">b) să recunoască valabilitatea permiselor arătate mai sus care vor fi eliberate conducătorilor a căror reședință
 în momentul eliberării nu se găsea pe teritoriul unde a fost eliberat permisul sau a căror reședință a fost transferată,
 după eliberarea permisului, într-un alt teritoriu.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 42</p><br>

<p style="font-weight:500;">Suspendarea valabilității permiselor de conducere</p>

<p style="text-indent:2em;"></p><h6>1. Părțile contractante sau subdiviziunile lor pot să retragă unui conducător care comite pe teritoriul lor o infracțiune
 ce atrage după sine retragerea permisului de conducere conform legislației lor, dreptul de a folosi pe teritoriul lor
 permisul de conducere, național sau internațional, al cărui titular este. Într-un astfel de caz, autoritatea competentă
 a părții contractante sau aceea a subdiviziunilor sale care a retras dreptul de a folosi permisul va putea:</h6><p></p>
 
<p style="text-indent:2em;">a) să rețină permisul și să-l păstreze pînă la expirarea termenului pe timpul căruia dreptul de a folosi permisul este retras
 sau pînă cînd conducătorul părăsește teritoriul său, dacă această plecare intervine înainte de expirarea termenului;</p><br>
 
<p style="text-indent:2em;">b) să încunoștiințeze despre retragerea dreptului de a folosi permisul autoritatea care a eliberat sau în numele căreia a
 fost eliberat permisul;</p><br>
 
<p style="text-indent:2em;">c) dacă este vorba de un permis internațional, să aplice în locul stabilit în acest scop mențiunea că permisul nu mai este
 valabil pe teritoriul său;</p><br>
 
<p style="text-indent:2em;">d) în cazul în care n-a aplicat procedura de la alin. a) al prezentului paragraf, să completeze comunicarea menționată la
 alin. b) cerînd autorității care a eliberat permisul sau în numele căreia a fost eliberat să-l înștiințeze pe cel interesat
 de hotărîrea luată împotriva sa.</p><br>
 
<p style="text-indent:2em;">2. Părțile contractante se vor strădui să aducă la cunoștința celor interesați hotărîrile care le vor fi comunicate conform
 procedurii prevăzute la paragraful 1 alin. d) al prezentului articol.</p><br>
 
<p style="text-indent:2em;">3. Nimic din această convenție nu va putea fi interpretat ca interzicînd părților contractante sau uneia din subdiviziunile
 lor să împiedice un conducător titular al unui permis de conducere, național sau internațional, să conducă dacă este evident
 sau dovedit că starea lui nu-i permite să conducă în condiții de siguranță sau dacă dreptul de a conduce i-a fost retras în
 statul în care își are reședința.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 43</p><br>

<p style="font-weight:500;">Dispoziții tranzitorii</p><br>

<p style="text-indent:2em;">Permisele internaționale de conducere conforme prevederilor Convenției asupra circulației rutiere, încheiată la Geneva la
 19 septembrie 1949, eliberate în termen de 5 ani de la intrarea în vigoare a prezentei convenții conform paragrafului 1 al
 art. 47 al convenției, vor fi asimilate, în aplicarea art. 41 și 42 ale convenției, permiselor internaționale de conducere
 prevăzute de această convenție.</p>
 
<p></p><h4>CAPITOLUL V</h4><p></p>

<p></p><h4>Condițiile pe care trebuie să le îndeplinească bicicletele și
motoretele pentru a fi admise în circulație internațională</h4><p></p>

<p style="font-weight:500;">ARTICOLUL 44</p>

<p style="text-indent:2em;"></p><h6>1. În circulație internațională bicicletele fără motor trebuie:</h6><p></p>

<p>a) să aibă o frînă eficace;</p><br>

<p>b) să fie echipate cu un claxon care să fie auzit de la o distanță suficientă și să nu
 aibă nici un alt avertizor sonor;</p><br>
 
<p style="text-indent:2em;">c) să fie echipate cu un dispozitiv reflectorizant roșu în spate și cu dispozitive capabile să emită
 o lumină albă sau galbenă selectiv spre în față și o lumină roșie spre în spate.</p>
 
<p style="text-indent:2em;"></p><h6>2. Pe teritoriul părților contractante care, conform paragrafului 2 al art. 54 al convenției, n-au făcut
 o declarație prin care să asimileze motoretele motocicletelor, motoretele în circulație internațională
 trebuie:</h6><p></p>
 
<p>a) să aibă două frîne independente;</p><br>

<p>b) să fie echipate cu un claxon sau cu un alt avertizor sonor care poate fi auzit de la o distanță suficientă;</p><br>

<p>c) să fie echipate cu un dispozitiv de eșapament silențios eficace;</p><br>

<p style="text-indent:2em;">d) să fie echipate cu dispozitive avînd o lumină albă sau galbenă selectiv în față, precum și o lumină roșie sau
 un dispozitiv reflectorizant roșu în spate;</p><br>
 
<p>e) să poarte marca de identificare prevăzută la anexa nr. 4 a convenției.</p><br>

<p style="text-indent:2em;">3. Pe teritoriul părților contractante care, conform paragrafului 2 al art. 54 din convenție, au făcut o declarație de
 asimilare a motoretelor, motocicletelor, condițiile pe care trebuie să le îndeplinească motoretele pentru a fi admise
 în circulație internațională sînt cele stabilite pentru motociclete la anexa nr. 5 a convenției.</p>
 
<p></p><h4>CAPITOLUL VI</h4><p></p>

<p></p><h4>Dispoziții finale</h4><p></p>

<p style="font-weight:500;">ARTICOLUL 45</p><br>

<p style="text-indent:2em;">1. Prezenta convenție va fi deschisă, la sediul Organizației Națiunilor Unite din New York, semnării, pînă la 31 decembrie
 1969, de către toate statele membre ale Organizației Națiunilor Unite sau membre ale uneia dintre instituțiile sale specializate
 ori Agenției Internaționale de Energie Atomică sau părților la Statutul Curții Internaționale de Justiție și de către oricare alt
 stat invitat de Adunarea generală a Organizației Națiunilor Unite să devină parte la convenție.</p><br>
 
<p style="text-indent:2em;">2. Convenția este supusă ratificării. Instrumentele de ratificare vor fi depuse pe lîngă secretarul general al Organizației
 Națiunilor Unite.</p><br>
 
<p style="text-indent:2em;">3. Convenția va rămîne deschisă aderării oricărui stat prevăzut la paragraful 1 al prezentului articol. Instrumentele de
 aderare vor fi depuse pe lîngă secretarul general.</p><br>
 
<p style="text-indent:2em;">4. În momentul cînd va semna convenția sau va depune instrumentul de ratificare ori de aderare, fiecare stat va face
 cunoscut secretarului general semnul distinctiv pe care îl alege pentru a fi aplicat în circulație internațională pe
 vehiculele înmatriculate, conform dispozițiilor anexei nr. 3 din convenție. Printr-o altă încunoștințare adresată secretarului
 general, orice stat poate schimba semnul distinctiv pe cale l-a ales anterior.</p><br>

<p style="font-weight:500;">ARTICOLUL 46</p><br>

<p style="text-indent:2em;">1. Orice stat va putea, în momentul în care va semna sau ratifica prezenta convenție ori va adera la ea, sau în orice alt
 moment ulterior să declare, printr-o înștiințare adresată secretarului general, că convenția devine aplicabilă tuturor teritoriilor
 sau numai unora dintre acestea care asigură relațiile internaționale. Convenția va deveni aplicabilă pe teritoriul sau teritoriile
 indicate în înștiințare 30 de zile după data la care secretarul general va primi această înștiințare sau de la data intrării în vigoare
 a convenției pentru statul care adresează înștiințarea, dacă această dată este ulterioară precedentei.</p><br>
 
<p style="text-indent:2em;">2. Orice stat care face declarația prevăzută la paragraful 1 al prezentului articol va putea, la orice dată ulterioară, printr-o înștiințare
 adresată secretarului general, să declare că convenția va înceta să fie aplicabilă pe acel teritoriu, iar convenția va înceta să fie
 aplicabilă pe acel teritoriu un an după data primirii acestei înștiințări de secretarul general.</p><br>
 
<p style="text-indent:2em;">3. Orice stat care adresează o înștiințare, conform paragrafului 1 al prezentului articol, va aduce la cunoștința secretarului general
 semnul sau semnele distinctive pe care le-a ales pentru a fi aplicate în circulația internațională pe vehiculele care au fost înmatriculate
 pe teritoriul sau teritoriile lor, conform dispozițiilor anexei nr. 3 a convenției. Printr-o altă înștiințare adresată secretarului general,
 orice stat poate schimba un semn distinctiv pe care îl alesese anterior.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 47</p><br>

<p style="text-indent:2em;">1. Prezenta convenție va intra în vigoare la 12 luni după data depunerii celui de-al cincisprezecelea instrument
 de ratificare sau de aderare.</p><br>
 
<p style="text-indent:2em;">2. Pentru fiecare stat care va ratifica convenția sau va adera la ea după depunerea celui de-al cincisprezecelea instrument
 de ratificare sau de aderare, convenția va intra în vigoare la 12 luni de la data depunerii, de acest stat, a instrumentului
 său de ratificare sau de aderare.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 48</p><br>

<p style="text-indent:2em;">La intrarea sa în vigoare prezenta convenție va abroga și înlocui, în relațiile dintre părțile contractante, Convenția internațională
 asupra circulației automobile și Convenția internațională asupra circulației rutiere, ambele semnate la Paris la 24 aprilie 1926,
 Convenția asupra reglementării circulației automobile interamericane, deschisă semnării la Washington la 15 decembrie 1943, și Convenția
 asupra circulației rutiere, deschisă semnării la Geneva la 19 septembrie 1949.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 49</p><br>

<p style="text-indent:2em;">1. După o perioadă de un an de la data intrării în vigoare a convenției, orice parte contractantă va putea să propună unul sau mai multe
 amendamente la convenție. Textul oricărei propuneri de amendament, însoțit de o expunere de motive, va fi adresat secretarului general,
 care îl va comunica tuturor părților contractante. Părțile contractante vor avea posibilitatea să anunțe, în termen de 12 luni următoare
 datei acestei comunicări: a) dacă acceptă amendamentul; b) dacă-l resping; ori c) dacă doresc să fie convocată o conferință pentru a-l
 examina. Secretarul general va transmite și textul amendamentului propus tuturor statelor vizate la paragraful 1 al art. 45 din convenție.</p>
 
<p style="text-indent:2em;"></p><h6>2:</h6><p></p>

<p style="text-indent:2em;">a) Orice propunere de amendament care va fi comunicată conform dispozițiilor paragrafului precedent va fi considerată ca acceptată dacă,
 în termenul de 12 luni sus-menționat, cel puțin o treime din părțile contractante informează secretarul general fie că resping amendamentul,
 fie că doresc convocarea unei conferințe pentru a-l examina. Secretarul general va comunica tuturor părților contractante orice acceptare sau
 respingere a amendamentului propus, precum și orice cerere de convocare a unei conferințe. Dacă numărul total al respingerilor și al cererilor
 primite în termenul de 12 luni este inferior unei treimi din numărul total al părților contractante, secretarul general va înștiința părțile
 contractante că amendamentul va intra în vigoare la 6 luni după expirarea termenului de 12 luni specificat la paragraful precedent pentru toate
 părțile contractante, cu excepția acelora care, în decursul termenului respectiv, au respins amendamentul sau au cerut convocarea unei conferințe
 pentru a-l examina.</p><br>
 
<p style="text-indent:2em;">b) Orice parte contractantă care, în decursul termenului de 12 luni, va respinge o propunere de amendament sau va cere convocarea unei conferințe
 pentru a-l examina, va putea, în orice moment după expirarea acestui termen, să înștiințeze secretarul general că acceptă amendamentul, iar
 secretarul general va comunica aceasta tuturor celorlalte părți contractante. Amendamentul va intra în vigoare, pentru părțile contractante 
 care vor anunța acceptarea, la 6 luni după ce secretarul general va primi înștiințarea lor.</p><br>
 
<p style="text-indent:2em;">3. Dacă un amendament propus n-a fost acceptat conform paragrafului 2 al prezentului articol și dacă, în termenul de 12 luni specificat la
 paragraful 1 al prezentului articol, mai puțin de jumătate din numărul total al părților contractante informează secretarul general că resping
 amendamentul propus și că cel puțin o treime din numărul total al părților contractante dar nu mai puțin de zece, îl informează că acceptă sau
 că doresc convocarea unei conferințe pentru a-l examina, secretarul general va convoca o conferință în vederea examinării amendamentului propus
 sau a oricărei alte propuneri cu care va fi sesizat în conformitate cu paragraful 4 al prezentului articol.</p><br>
 
<p style="text-indent:2em;">4. Dacă o conferință este convocată conform dispozițiilor paragrafului 3 al prezentului articol, secretarul general va invita toate statele vizate
 la paragraful 1 al art. 45 al convenției. El va cere tuturor statelor invitate la conferință să-i prezinte, cel mai tîrziu în 6 luni înainte de
 data deschiderii, orice propunere care ar dori să fie examinată de această conferință în afară de amendamentul propus și va comunica aceste propuneri,
 cu cel puțin 3 luni înainte de data deschiderii conferinței, tuturor statelor invitate la conferință.</p>
 
<p style="text-indent:2em;"></p><h6>5:</h6><p></p>

<p style="text-indent:2em;">a) Orice amendament la convenție va fi considerat acceptat dacă a fost adoptat cu o majoritate de două treimi a statelor reprezentate la conferință,
 cu condiția ca această majoritate să reprezinte cel puțin două treimi din numărul părților contractante reprezentate la conferință. Secretarul general
 va încunoștiința tuturor părților contractante despre adoptarea amendamentului și acesta va intra în vigoare la 12 luni de la data acestei înștiințări
 pentru toate părțile contractante, cu excepția acelora care, înăuntrul acestui termen, vor înștiința secretarul general că resping amendamentul.</p><br>
 
<p style="text-indent:2em;">b) Orice parte contractantă care va respinge un amendament în termenul de 12 luni va putea, în orice moment, să înștiințeze secretarul general că îl
 accepta, iar secretarul general va comunica această înștiințare tuturor celorlalte părți contractante. Amendamentul va intra în vigoare, pentru partea
 contractantă care va înștiința acceptarea sa, la 6 luni după data la care secretarul general va primi înștiințarea sau la sfîrșitul termenului de 12 luni,
 dacă data este posterioară precedentei.</p><br>
 
<p style="text-indent:2em;">6. Dacă propunerea de amendament nu se consideră acceptată, conform paragrafului 2 al prezentului articol, și dacă condițiile prescrise la paragraful 3
 al prezentului articol pentru convocarea unei conferințe nu sînt întrunite, propunerea de amendament
 va fi considerată respinsă.</p><br>

<p style="font-weight:500;">ARTICOLUL 50</p><br>

<p style="text-indent:2em;">Orice parte contractantă va putea declara nulă prezenta convenție printr-o înștiințare scrisă adresată secretarului general.
Declarația va avea efect la un an de la data la care secretarul general va primi înștiințarea.</p><br>

<p style="font-weight:500;">ARTICOLUL 51</p><br>

<p style="text-indent:2em;">Prezenta convenție va înceta de a fi în vigoare dacă numărul părților contractante este inferior lui cinci în timpul
unei perioade oarecare de 12 luni consecutive.</p><br>

<p style="font-weight:500;">ARTICOLUL 52</p><br>

<p style="text-indent:2em;">Orice diferend între două sau mai multe părți contractante privind interpretarea sau aplicarea convenției, pe care părțile
 nu ar putea să le reglementeze pe cale de tratative sau într-un alt mod, va putea fi adus, la cererea oricăreia dintre părțile
 contractante interesate, în fața Curții Internaționale de Justiție, pentru a fi rezolvat de aceasta.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 53</p><br>

<p style="text-indent:2em;">Nici o dispoziție a convenției nu va fi interpretată ca interzicînd unei părți contractante să ia măsurile corespunzătoare cu
 dispozițiile Cartei Națiunilor Unite și limitate la cerințele situației pe care le apreciază necesare pentru siguranța sa
 externă sau internă.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 54</p><br>

<p style="text-indent:2em;">1. Orice stat va putea, în momentul în care va semna convenția sau va depune instrumentul de ratificare sau de aderare, să declare
 că nu se consideră legat de art. 52 al convenției. Celelalte părți contractante nu vor fi legate de art. 52 față de oricare dintre
 părțile contractante care va face o astfel de declarație.</p><br>
 
<p style="text-indent:2em;">2. În momentul în care depune instrumentul de ratificare sau de aderare, orice stat poate să declare, prin înștiințare adresată 
secretarului general, că va asimila motoretele motocicletelor în scopul aplicării convenției (art. 1. n).</p><br>

<p>În orice moment un stat va putea ulterior, printr-o înștiințare trimisă secretarului general, să-și retragă declarația.</p><br>

<p style="text-indent:2em;">3. Declarațiile prevăzute la paragraful 2 al prezentului articol vor intra în vigoare la 6 luni după data la care secretarul general
 va primi înștiințarea sau la data la care convenția va intra în vigoare pentru statul care va face declarație dacă această dată
 este posterioară precedentei.</p><br>
 
<p style="text-indent:2em;">4. Orice modificare a unui semn distinctiv ales anterior, înștiințată conform paragrafului 4 al art. 45 sau paragrafului 3 al art. 46
 din convenție, va intra în vigoare la 3 luni după data la care secretarul general va primi înștiințarea.</p><br>
 
<p style="text-indent:2em;">5. Rezervele la prezenta convenție și la anexele sale, altele decît cele prevăzute la paragraful 1 al prezentului articol, sînt permise
 cu condiția să fie formulate în scris și, dacă au fost formulate înainte de depunerea instrumentului de ratificare sau de aderare, să
 fie confirmate prin acest instrument. Secretarul general va comunica aceste rezerve tuturor statelor prevăzute la paragraful 1 al art.
 45 din convenție.</p><br>
 
<p style="text-indent:2em;">6. Orice parte contractantă care va formula o rezervă sau va face o declarație în conformitate cu paragrafele 1 și 4 ale prezentului
 articol va putea, în orice moment, să o retragă printr-o înștiințare adresată secretarului general.</p>
 
<p style="text-indent:2em;"></p><h6>7. Orice rezervă făcută conform paragrafului 5 al prezentului articol:</h6><p></p>

<p style="text-indent:2em;">a) modifică, pentru partea contractantă care a formulat acea rezervă, dispozițiile convenției asupra cărora poartă
 rezerva, în limitele acesteia;</p><br>
 
<p style="text-indent:2em;">b) modifică aceste dispoziții în aceleași limite pentru celelalte părți contractante în ce privește relațiile lor
 cu partea contractantă care a făcut cunoscută rezerva.</p><br>
 
<p style="font-weight:500;">ARTICOLUL 55</p>

<p style="text-indent:2em;"></p><h6>În afara declarațiilor, înștiințărilor și comunicărilor prevăzute la art. 49 și 54 ale convenției, secretarul general
 va aduce la cunoștința tuturor statelor vizate la paragraful 1 al art. 45:</h6><p></p>
 
<p>a) semnăturile, ratificările și aderările, conform art. 45;</p><br>

<p>b) înștiințările și declarațiile, conform paragrafului 4 al art. 45 și art. 46;</p><br>

<p>c) datele de intrare în vigoare a convenției, în virtutea art. 47;</p><br>

<p>d) data intrării în vigoare a amendamentelor la convenție, conform paragrafelor 2 și 5 ale art. 49;</p><br>

<p>e) declararea nulității, conform art. 50;</p><br>

<p>f) abrogarea convenției, conform art. 51.</p><br>

<p style="font-weight:500;">ARTICOLUL 56</p><br>

<p style="text-indent:2em;">Originalul convenției, întocmit într-un singur exemplar în limbile engleză, chineză, spaniolă, franceză și rusă, toate
 cele cinci texte fiind la fel de valabile, va fi depus pe lîngă secretarul general al Organizației Națiunilor Unite,
 care va transmite copii certificate conforme tuturor statelor vizate la paragraful 1 al art. 45 din convenție.</p><br>
 
<p>Pentru care împuterniciții, legal autorizați de guvernele lor, au semnat convenția.</p><br>

<p>Încheiată la Viena, în a 8-a zi a lunii noiembrie 1968.</p><br>

<p style="font-weight:500;">ANEXA Nr. 1</p><br>

<p style="text-indent:2em;">1. Părțile contractante pot să nu admită în circulație internațională pe teritoriul lor automobilele, remorcile și
 ansamblurile de vehicule a căror greutate, totală sau pe osie, ori ale căror dimensiuni depășesc limitele stabilite 
 de legislația națională pentru vehiculele înmatriculate pe teritoriul lor. Părțile contractante pe teritoriul cărora
 are loc o circulație internațională de vehicule grele se vor strădui să încheie acorduri regionale care să permită,
 în circulație internațională, accesul pe drumurile regiunii, cu excepția unor drumuri de importanță redusă, a vehiculelor
 sau ansamblurilor de vehicule ale căror greutate și dimensiuni nu depășesc limitele fixate prin aceste acorduri.</p>
 
<p style="text-indent:2em;"></p><h6>2. Pentru aplicarea paragrafului 1 al prezentei anexe, nu vor fi considerate ca depășire a lățimii
 maxime autorizate proeminența:</h6><p></p>

<p>a) pneurilor în apropierea punctului lor de contact cu solul și a legăturilor cu indicatoarele de
 presiune a pneurilor;</p><br>
 
<p>b) dispozitivelor antiderapante ce vor li montate pe roți;</p><br>

<p style="text-indent:2em;">c) oglinzilor retrovizoare construite astfel încît să poată, ca urmare a unei apăsări moderate, să cedeze în ambele
 sensuri pentru a nu trece peste lățimea maximă autorizată;</p><br>
 
<p style="text-indent:2em;">d) indicatoarelor de direcție laterale și luminilor de gabarit, cu condiția ca proeminența să nu fie mai mare
 de cîțiva centimetri;</p><br>
 
<p>e) sigiliilor vamale aplicate pe încărcătură și dispozitivelor de fixare și protecție a acestor sigilii.</p>

<p style="text-indent:2em;"></p><h6>3. Părțile contractante pot să nu admită în circulație internațională pe teritoriul lor următoarele ansambluri
 de vehicule, în măsura în care legislația lor națională interzice circulația unor astfel de ansambluri:</h6><p></p>
 
<p>a) motociclete cu remorci;</p><br>

<p>b) ansambluri constituite dintr-un automobil și multe remorci;</p><br>

<p>c) vehicule articulate destinate transportului de persoane.</p><br>

<p style="text-indent:2em;">4. Părțile contractante pot să nu admită în circulație internațională pe teritoriul lor automobilele
 și remorcile care beneficiază de excepții conform paragrafului 60 al anexei nr. 5 a convenției.</p><br>
 
<p style="text-indent:2em;">5. Părțile contractante pot să nu admită în circulație internațională pe teritoriul lor motoretele și motocicletele
 al căror conducător și, dacă e cazul, pasager nu este echipat cu cască de protecție.</p><br>
 
<p style="text-indent:2em;">6. Părțile contractante pot să condiționeze admiterea în circulație internațională pe teritoriul lor a oricărui automobil,
 altul decît o motoretă sau o motocicletă cu două roți fără ataș, de prezența la bordul automobilului a unui dispozitiv,
 prevăzut la paragraful 56 al anexei nr. 5 a convenției, și destinat, în caz de oprire pe partea carosabilă, să anunțe
 pericolul pe care-l constituie vehiculul astfel oprit.</p><br>
 
<p style="text-indent:2em;">7. Părțile contractante pot să condiționeze admiterea în circulație internațională, pe anumite drumuri grele sau în
 anumite regiuni cu relief greu de pe teritoriul lor, a automobilelor a căror greutate maximă autorizată depășește 3.500
 kg, de respectarea dispozițiilor speciale impuse de legislația lor națională pentru admiterea pe aceste drumuri sau în
 aceste regiuni a vehiculelor cu aceeași greutate maximă autorizată pe care le-a înmatriculat.</p><br>
 
<p style="text-indent:2em;">8. Părțile contractante pot să nu admită în circulație internațională pe teritoriul lor orice automobil prevăzut cu lumini
 de încrucișare asimetrice atunci cînd reglarea fascicolelor nu este adaptată sensului circulației de pe teritoriul lor.</p><br>
 
<p style="text-indent:2em;">9. Părțile contractante pot să nu admită în circulație internațională pe teritoriul lor orice automobil sau remorcă atașată unui
 automobil care ar purta un semn distinctiv, altul decît acela prevăzut pentru acest vehicul la art. 37 al convenției.</p><br>

<p style="font-weight:500;">ANEXA Nr. 2</p><br>

<p style="text-indent:2em;">1. Numărul de înmatriculare prevăzut la art. 35 și 36 ale convenției trebuie să fie compus fie din cifre, fie din cifre și litere.
 Cifrele trebuie să fie arabe, iar literele cu caractere latine majuscule. Totuși, pot fi utilizate și alte cifre sau caractere,
 dar numărul de înmatriculare trebuie atunci să fie repetat în cifre arabe și în caractere latine majuscule.Comentarii expert (1)</p><br>
 
<p style="text-indent:2em;">2. Numărul de înmatriculare trebuie să fie compus și aplicat în așa fel încît să fie vizibil pe timp senin de la o distanță de
 minimum 40 m, de un observator așezat pe axa vehiculului, iar vehiculul oprit. Părțile contractante pot, pentru vehiculele pe
 care le înmatriculează, să reducă această distanță minimă de lizibilitate pentru motociclete și categoriile speciale de automobile
 cărora ar fi dificil să dea numerelor de înmatriculare dimensiuni suficiente pentru a fi lizibile de la 40 m.</p><br>
 
<p style="text-indent:2em;">3. În cazul în care numărul de înmatriculare este aplicat pe o placă specială aceasta trebuie să fie plană și fixată într-o poziție
 verticală sau aproape verticală și perpendiculară pe planul longitudinal median al vehiculului. În cazul în care numărul este
 aplicat sau scris pe vehicul, suprafața pe care este fixat sau scris trebuie să fie plană și verticală, precum și perpendiculară
 pe planul longitudinal median al vehiculului.</p><br>
 
<p style="text-indent:2em;">4. Cu excepția dispozițiilor paragrafului 5 al art. 32, placa sau suprafața pe care este fixat sau scris numărul de
 înmatriculare poate fi dintr-un material reflectorizant.</p><br>

<p style="font-weight:500;">ANEXA Nr. 3</p><br>

<p style="text-indent:2em;">1. Semnul distinctiv prevăzut la art. 37 al convenției trebuie să fie compus din una pînă la trei litere cu caractere latine
 majuscule. Literele vor avea o înălțime minimă de 0,08 m, iar scrisul o grosime de cel puțin 0,01 m. Literele vor fi scrise
 cu negru pe fond alb, avînd forma unei elipse cu axa mare orizontală.</p><br>

<p>2. Cînd semnul distinctiv nu are decît o singură literă, axa mare a elipsei poate fi verticală.</p><br>

<p style="text-indent:2em;">3. Semnul distinctiv nu trebuie să fie încorporat în numărul de înmatriculare și nici fixat în așa fel încît să poată fi
 confundat cu acesta din urmă sau să dăuneze asupra lizibilității sale.</p>
 
<p style="text-indent:2em;"></p><h6>4. Pe motociclete și pe remorcile lor, dimensiunile axelor elipsei vor fi de cel puțin 0,175 m și respectiv 0,115 m.
 Pe celelalte automobile și pe remorcile lor, dimensiunile axelor elipsei vor fi de cel puțin:</h6><p></p>
 
<p>a) 0,24 m și 0,145 m, dacă semnul distinctiv are 3 litere;</p><br>

<p>b) 0,175 m și 0,115 m, dacă semnul distinctiv are mai puțin de 3 litere.</p><br>

<p>5. Dispozițiile paragrafului 3 al anexei nr. 2 se aplică la fixarea semnului distinctiv pe vehicule.</p><br>

<p style="font-weight:500;">ANEXA Nr. 4</p>

<p style="text-indent:2em;"></p><h6>1. Elementele de identificare cuprind:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) Pentru automobile:</h6><p></p>

<p>i) numele sau marca constructorului vehiculului;</p><br>

<p>ii) pe șasiu sau, în lipsa acestuia, pe caroserie numărul de fabricație sau numărul de serie al constructorului;</p><br>

<p>iii) pe motor, numărul de fabricație al motorului, atunci cînd un astfel de număr este aplicat de constructor.</p><br>

<p>b) Pentru remorci, indicațiile menționate la alin. i) și ii) de mai sus;</p><br>

<p>c) Pentru motorete, indicarea capacității cilindrice și literele "CM".</p><br>

<p style="text-indent:2em;">2. Elementele menționate la paragraful 1 al prezentei anexe trebuie să fie plasate în locuri accesibile și ușor de citit; în plus,
 ele trebuie să fie greu de modificat sau de înlăturat. Literele și cifrele incluse în semne vor fi numai cu caractere latine sau
 cursive denumite engleze și în cifre arabe, fie repetate în acest mod.</p><br>

<p style="font-weight:500;">ANEXA Nr. 5</p><br>

<p style="text-indent:2em;">1. Părțile contractante care, conform art. 1 alin. n) al convenției, au declarat că vor să asimileze motocicletelor
 vehiculele cu 3 roți a căror greutate în gol nu depășește 400 kg, trebuie să supună acestea din urmă regulilor stabilite
 în prezenta anexă fie pentru motociclete, fie pentru celelalte automobile.</p><br>
 
<p>2. În sensul prezentei anexe, termenul remorcă nu se aplică decît remorcilor destinate a fi cuplate la un automobil.</p><br>

<p style="text-indent:2em;">3. Cu excepția dispozițiilor paragrafului 2 a) al art. 3 din convenție, orice parte contractantă poate, pentru automobilele
 pe care le înmatriculează și pentru remorcile pe care le admite în circulație potrivit legislației naționale, să impună prevederi
 care să completeze dispozițiile prezentei anexe ori să fie mai severe decît acestea.</p>
 
<p></p><h4>CAPITOLUL I</h4><p></p>

<p style="font-weight:500;">Frînarea</p>

<p style="text-indent:2em;"></p><h6>4. În sensul prezentului capitol:</h6><p></p>

<p style="text-indent:2em;">a) termenul roți ale unei osii înseamnă roțile simetrice sau aproape simetrice, în raport cu planul longitudinal
 median al vehiculului, chiar dacă nu sînt așezate pe aceeași osie (o osie tandem este socotită ca două osii);</p><br>
 
<p>b) termenul frînă de serviciu înseamnă dispozitivul utilizat în mod normal pentru a reduce viteza și
 a opri vehiculul;</p><br>
 
<p style="text-indent:2em;">c) termenul frînă de staționare înseamnă dispozitivul utilizat pentru a menține, în absența conducătorului,
 vehiculul imobil sau, în cazul unei remorci, remorca atunci cînd aceasta este decuplată;</p><br>
 
<p style="text-indent:2em;">d) termenul frînă de ajutor înseamnă dispozitivul destinat să reducă viteza și să oprească vehiculul în
 caz de defectare a frînei de serviciu.</p><br>

<p style="font-weight:500;">A. Frînarea automobilelor altele decît motocicletele</p>

<p style="text-indent:2em;"></p><h6>5. Orice automobil, altul decît o motocicletă, trebuie să fie echipat cu frîne care pot fi acționate
 cu ușurință de către conducătorul așezat la locul de conducere. Aceste frîne trebuie să asigure cele
 trei funcții ale frînării, astfel:</h6><p></p>
 
<p style="text-indent:2em;">a) o frînă de serviciu care să permită reducerea vitezei și oprirea vehiculului în mod sigur, rapid și eficace,
 indiferent de condițiile încărcăturii și de gradul de înclinare a drumului pe care circulă;</p><br>
 
<p style="text-indent:2em;">b) o frînă de staționare care să permită menținerea vehiculului imobil, oricare ar fi condițiile de încărcare,
 la o înclinare a drumului de 16%, suprafețele active ale frînei rămînînd în poziția de frînare prin intermediul
 unui dispozitiv care acționează mecanic;</p><br>
 
<p style="text-indent:2em;">c) o frînă de ajutor care să permită reducerea vitezei și oprirea vehiculului, oricare ar fi condițiile
 încărcăturii, pe o distanță rezonabilă, chiar în caz de defectare a frînei de serviciu.</p><br>
 
<p style="text-indent:2em;">6. Cu excepția dispozițiilor paragrafului 5 al prezentei anexe, dispozitivele care asigură cele trei funcții
 ale frînării (frînă de serviciu, frînă de ajutor și frînă de staționare) pot avea părți comune; combinarea
 comenzilor nu este admisă decît cu condiția să existe cel puțin două comenzi distincte.</p><br>
 
<p style="text-indent:2em;">7. Frîna de serviciu trebuie să acționeze asupra tuturor roților vehiculului; totuși, în cazul vehiculelor
 care au mai mult de două osii, roțile unei osii pot să nu fie frînate.</p><br>
 
<p style="text-indent:2em;">8. Frîna de ajutor trebuie să acționeze asupra unei roți cel puțin de fiecare parte a planului longitudinal
 median al vehiculului; aceeași dispoziție se aplică frînei de staționare.</p><br>
 
<p style="text-indent:2em;">9. Frîna de serviciu și frîna de staționare trebuie să acționeze asupra suprafețelor de frînare conectate la
 roți în mod permanent, prin intermediul unor piese suficient de rezistente.</p>
 
<p style="text-indent:2em;"></p><h6>10. Nici o suprafață frînată nu trebuie să fie decuplată de roți. Totuși, o astfel de decuplare este admisă
 pentru anumite suprafețe frînate, cu condiția:</h6><p></p>
 
<p>a) să fie doar momentană, ca de exemplu în timpul schimbării vitezelor;</p><br>

<p>b) atît timp cît acționează asupra frînei de staționare, să nu fie posibilă decuplarea fără
 intervenția conducătorului, și</p><br>
 
<p style="text-indent:2em;">c) atît timp cît acționează asupra frînei de serviciu sau de ajutor, acțiunea de frînare continuă să se poată exercita
 cu eficacitatea prescrisă la paragraful 5 al acestei anexe.</p><br>

<p style="font-weight:500;">B. Frînarea remorcilor</p>

<p style="text-indent:2em;"></p><h6>11. Cu excepția dispozițiilor paragrafului 17 c) al prezentei anexe, orice remorcă alta decît o remorcă ușoară
 trebuie să fie prevăzută cu frîne, și anume:</h6><p></p>
 
<p style="text-indent:2em;">a) o frînă de serviciu care să permită reducerea vitezei și oprirea sigură, rapidă și eficace, oricare ar fi
 condițiile încărcăturii și gradul de înclinare a drumului pe care circulă;</p><br>
 
<p style="text-indent:2em;">b) o frînă de staționare care să permită menținerea vehiculului imobil, oricare ar fi condițiile încărcăturii,
 pe o înclinare de 16%, suprafețele active ale frînei rămînînd menținute în poziție de frînare prin intermediul
 unui dispozitiv cu acțiune pur mecanică. Prezenta dispoziție nu se aplică remorcilor care nu pot fi decuplate
 de vehiculul trăgător fără ajutorul uneltelor, cu condiția ca cerințele referitoare la frîna de staționare să
 fie respectate pentru ansamblul de vehicule.</p><br>
 
<p>12. Dispozitivele care asigură cele două funcții ale frînării (serviciu și staționare) pot avea părți comune.</p><br>

<p>13. Frîna de serviciu trebuie să acționeze asupra tuturor roților remorcii.</p><br>

<p style="text-indent:2em;">14. Frîna de serviciu trebuie să poată fi pusă în funcțiune prin comanda frînei de serviciu a vehiculului trăgător;
 totuși, dacă greutatea maximă autorizată a remorcii nu depășește 3.500 kg, frîna poate fi concepută pentru a nu fi
 pusă în acțiune, în timpul mersului, decît prin simpla apropiere a remorcii de vehiculul trăgător (frînare prin inerție).</p><br>
 
<p style="text-indent:2em;">15. Frîna de serviciu și frîna de staționare trebuie să acționeze asupra suprafețelor frînate conectate la roți în
 mod permanent prin intermediul unor piese suficient de rezistente.</p><br>
 
<p style="text-indent:2em;">16. Dispozitivele de frînare trebuie să fie astfel încît să asigure oprirea automată a remorcii în caz de rupere
 a dispozitivului de cuplare în timpul mersului. Totuși, această dispoziție nu se aplică remorcilor cu o osie sau
 cu două osii cu distanță între ele de mai puțin de 1 m, cu condiția ca greutatea lor maximă autorizată să nu depășească
 1.500 kg și, cu excepția semiremorcilor, să fie prevăzute, pe lîngă un dispozitiv de cuplare, cu o legătură secundară
 prevăzută la paragraful 58 al acestei anexe.</p><br>

<p style="font-weight:500;">C. Frînarea ansamblurilor de vehicule</p>

<p style="text-indent:2em;"></p><h6>17. În afara dispozițiilor de la lit. A și B ale prezentului capitol referitoare la vehiculele izolate (automobil
 și remorci), prevederile de mai jos se vor aplica ansamblurilor acestor vehicule:</h6><p></p>
 
<p>a) dispozitivele de frînare montate pe fiecare din vehiculele care compun ansamblul trebuie să fie corespunzătoare;</p><br>

<p style="text-indent:2em;">b) acțiunea frînei de serviciu trebuie să fie repartizată corespunzător și sincronizată între vehiculele care
 compun ansamblul;</p><br>
 
<p style="text-indent:2em;">c) greutatea maximă autorizată a unei remorci neprevăzută cu frînă de serviciu nu trebuie să depășească jumătatea
 totalului greutății în gol a vehiculului trăgător și a greutății conducătorului.</p><br>

<p style="font-weight:500;">D. Frînarea motocicletelor</p>

<p style="text-indent:2em;"></p><h6>18:</h6><p></p>

<p style="text-indent:2em;">a) Orice motocicletă trebuie să fie prevăzută cu două dispozitive de frînare, care să acționeze unul cel puțin
 asupra roții sau roților din spate, iar celălalt cel puțin asupra roții sau roților din față; dacă unei motociclete
 i se adaugă un ataș, frînarea roții atașului nu este cerută. Aceste dispozitive de frînare trebuie să permită reducerea
 vitezei și oprirea motocicletei într-un mod sigur, rapid și eficace, oricare ar fi condițiile sale de încărcare și
 declivitatea drumului pe care circulă.</p><br>
 
<p style="text-indent:2em;">b) În afara dispozitivelor prevăzute la alin. a) al prezentului paragraf, motocicletele cu trei roți simetrice
 în raport cu planul longitudinal median al vehiculului trebuie să fie prevăzute cu o frîna de staționare care
 să corespundă condițiilor de la alin. b) al paragrafului 5 al acestei anexe.</p>
 
<p></p><h4>CAPITOLUL II</h4><p></p>

<p style="font-weight:500;">Lumini și dispozitive reflectorizante</p>

<p style="text-indent:2em;"></p><h6>19. În sensul prezentului capitol, termenul:</h6><p></p>

<p>lumina de drum este lumina vehiculului care servește la iluminarea drumului pe o mare distanță în fața vehiculului;</p><br>

<p style="text-indent:2em;">lumina de întîlnire este lumina vehiculului care servește la iluminarea drumului în față, fără să orbească sau să
 stînjenească pe conducătorii care vin din sens invers, precum și pe ceilalți participanți la circulație;</p><br>
 
<p>lumina de poziție față este lumina care servește la indicarea prezenței și a lățimii acestui vehicul văzut din față;</p><br>

<p>lumina de poziție spate este lumina care servește la indicarea prezenței și a lățimii acestui vehicul văzut din spate;</p><br>

<p style="text-indent:2em;">lumina de frînare este lumina care servește a semnala celorlalți participanți la circulație ce se găsesc în spatele acestui
 vehicul ca conducătorul său acționează frîna de serviciu;</p><br>
 
<p style="text-indent:2em;">lumina de ceață este lumina care servește la îmbunătățirea iluminării drumului în caz de ceață, ninsoare, furtună sau
 nor de praf;</p><br>

<p style="text-indent:2em;">lumina de mers înapoi este lumina care servește la iluminarea drumului în spatele acestui vehicul și la avertizarea celorlalți
 participanți la circulație că vehiculul merge înapoi sau este pe punctul de a merge înapoi;</p><br>
 
<p style="text-indent:2em;">lumina indicatoare de direcție este lumina care servește a indica celorlalți participanți la circulație că conducătorul are
 intenția să schimbe direcția spre dreapta sau spre stînga;</p><br>
 
<p style="text-indent:2em;">catodioptru este un dispozitiv care servește la indicarea prezenței unui vehicul prin reflectarea luminii care emană de la o
 sursă luminoasă independentă de acest vehicul, observatorul fiind așezat în apropierea acelei surse luminoase;</p><br>
 
<p style="text-indent:2em;">suprafața iluminată este pentru lumini suprafața vizibilă de ieșire a luminii emise, iar pentru catadioptri suprafața
 vizibil reflectorizantă.</p><br>
 
<p style="text-indent:2em;">20. Culorile luminilor prevăzute în prezentul capitol trebuie să fie, pe cît posibil, conforme definițiilor date
 în suplimentul la această anexă.</p><br>
 
<p style="text-indent:2em;">21. Cu excepția motocicletelor, orice automobil care depășește în linie dreaptă viteza de 40 km/h trebuie să fie
 echipat în față cu un număr pereche de lumini de drum albe sau galbene selectiv capabile să ilumineze, în mod eficace,
 drumul, noaptea, pe timp senin, pe o distanță de cel puțin 100 m în fața vehiculului. Limitele exterioare ale suprafeței
 iluminate de luminile de drum nu trebuie în nici un caz să fie situate mai aproape de extremitatea lățimii vehiculului
 decît marginile exterioare ale suprafeței iluminate de luminile de întîlnire.</p><br>
 
<p style="text-indent:2em;">22. Cu excepția motocicletelor, orice automobil care depășește în linie dreaptă viteza de 10 km/h trebuie să fie echipat
 în față cu două lumini de întîlnire albe sau galbene selectiv capabile să ilumineze, în mod eficace, drumul, noaptea,
 pe timp senin, pe o distanță de cel puțin 40 m în fața vehiculului. De fiecare parte, punctul suprafeței iluminate cel
 mai îndepărtat de planul longitudinal median al vehiculului nu trebuie să se afle la mai mult de 0,40 m de extremitatea
 lățimii vehiculului. Un automobil nu trebuie să fie prevăzut cu mai mult de două lumini de întîlnire. Acestea trebuie să
 fie reglate pentru a fi conforme definiției de la paragraful 19 al acestei anexe.</p><br>
 
<p style="text-indent:2em;">23. Orice automobil, altul decît o motocicletă fără ataș, trebuie să fie echipat în față cu două lumini de poziție albe;
 totuși, se admite galbenul selectiv pentru luminile de poziție din față înglobate în luminile de drum sau în luminile de
 întîlnire care emit fascicule de lumină galben selectiv. Aceste lumini de poziție, atunci cînd sînt singurele lumini aprinse
 în față, trebuie să fie vizibile, noaptea, pe timp senin, de la o distanță de cel puțin 300 m, fără a orbi sau stînjeni pe
 ceilalți participanți la circulație. De fiecare parte, punctul suprafeței iluminate cel mai îndepărtat de planul longitudinal
 median al vehiculului nu trebuie să se găsească la mai mult de 0,40 m de marginea vehiculului.</p>
 
<p style="text-indent:2em;"></p><h6>24:</h6><p></p>

<p style="text-indent:2em;">a) Orice automobil, altul decît o motocicletă cu două roți fără ataș, trebuie să fie echipat la spate cu un număr
 pereche de lumini de poziție roșii vizibile noaptea, pe timp senin, de la o distanță de cel puțin 300 m, fără a
 orbi sau stînjeni pe ceilalți participanți la circulație. De fiecare parte, punctul suprafeței iluminate cel mai
 îndepărtat de planul longitudinal median al vehiculului nu trebuie să se găsească la mai mult de 0,40 m de marginea
 vehiculului.</p><br>
 
<p style="text-indent:2em;">b) Orice remorcă trebuie să fie echipată la spate cu un număr pereche de lumini de poziție roșii vizibile noaptea,
 pe timp senin, de la o distanță de cel puțin 300 m, fără a orbi sau stînjeni pe ceilalți participanți la circulație.
 De fiecare parte, punctul suprafeței iluminate cel mai îndepărtat de planul longitudinal median al remorcii nu trebuie
 să se găsească la mai mult de 0,40 m de marginea remorcii. Totuși remorcile a căror lățime nu depășește 0,80 m pot să
 nu fie echipate decît cu o singură lumină dacă sînt cuplate la o motocicletă cu două roți fără ataș.</p><br>
 
<p style="text-indent:2em;">25. Orice automobil sau remorcă ce poartă la spate un număr de înmatriculare trebuie să fie echipat cu un dispozitiv
 de iluminare a acestui număr astfel încît, cînd este iluminat de dispozitiv, să fie lizibil noaptea, pe timp senin,
 vehiculul fiind oprit, de la o distanță de 20 m din spatele vehiculului; orice parte contractantă poate să reducă
 această distanță minimă de lizibilitate pe timpul nopții în aceeași proporție și pentru aceleași vehicule pentru
 care va reduce, conform paragrafului 2 al anexei nr. 2 a convenției, distanța minimă de lizibilitate pe timp de zi.</p><br>
 
<p style="text-indent:2em;">26. La orice automobil (inclusiv motocicletele) și la orice ansamblu constituit dintr-un vehicul automobil și
 una sau mai multe remorci, legăturile electrice trebuie să fie astfel încît luminile de drum, de întîlnire,
 de ceață, de poziție din față și dispozitivul prevăzut la paragraful 25, să nu poată fi puse în funcțiune decît
 atunci cînd luminile de poziție ale automobilului sau ale ansamblului de vehicule situate cel mai în spate, sînt
 aprinse.</p><br>
 
<p style="text-indent:2em;">Totuși, această condiție nu este obligatorie pentru luminile de drum sau pentru luminile de întîlnire atunci
 cînd sînt folosite pentru a da avertismente luminoase conform paragrafului 5 al art. 33 al convenției. Mai mult,
 legăturile electrice trebuie să fie în așa fel realizate încît luminile de poziție din față să fie totdeauna aprinse
 atunci cînd cele de întîlnire, de drum sau de ceață sînt aprinse.</p><br>

<p style="text-indent:2em;">27. Orice automobil, altul decît o motocicletă cu două roți fără ataș, trebuie să fie echipat la spate cu cel puțin doi
 catadioptri roșii a căror formă să nu fie triunghiulară. De fiecare parte, punctul suprafeței luminoase cel mai îndepărtat
 al planului longitudinal median al vehiculului nu trebuie să se găsească la mai mult de 0,40 m de marginea vehiculului.
 Catadioptrii trebuie să fie vizibili pentru conducătorul unui vehicul noaptea, pe timp senin, de la o distanță de cel puțin
 150 m, atunci cînd sînt iluminați de luminile de drum ale acestui vehicul.</p><br>
 
<p style="text-indent:2em;">28. Orice remorcă trebuie să fie prevăzută la spate cu cel puțin, doi catadioptri roșii. Aceștia trebuie să aibă forma
 unui triunghi echilateral cu un vîrf în sus și o latură orizontală și ale căror laturi să aibă cel puțin 0,15 m și cel
 mult 0,20 m; nici o lumină de semnalizare nu trebuie să fie instalată în interiorul triunghiului. Acești catadioptri
 trebuie să corespundă condiției de vizibilitate fixată la paragraful 27 de mai sus. De fiecare parte, punctul suprafeței
 luminoase cel mai îndepărtat al planului longitudinal median al remorcii nu trebuie să se găsească la mai mult de 0,40 m
 de marginea remorcii. Totuși, remorcile a căror lățime nu depășește 0,80 m pot să nu fie prevăzute decît cu un singur
 catadioptru dacă sînt cuplate la o motocicleta fură ataș.</p><br>
 
<p style="text-indent:2em;">29. Orice remorcă trebuie să fie prevăzută în față cu doi catadioptri albi a căror formă să nu fie triunghiulară.
 Ei trebuie să îndeplinească condițiile de amplasare și vizibilitate stabilite la paragraful 27 de mai sus.</p><br>
 
<p style="text-indent:2em;">30. O remorcă cu lățimea de peste 1,60 m trebuie să fie prevăzută în față cu două lumini de poziție albe. Luminile
 de poziție trebuie să fie plasate cît mai aproape posibil de marginea remorcii și în orice caz în așa fel încît
 punctul suprafeței luminoase cel mai îndepărtat de planul longitudinal median al remorcii să nu se găsească la
 mai mult de 0,15 m de aceste margini.</p><br>
 
<p style="text-indent:2em;">31. Cu excepția motocicletelor cu două roți, cu sau fără ataș, orice automobil care depășește în linie dreaptă
 viteza de 25 km/h trebuie să fie prevăzut în spate cu două lumini de frînare de culoare roșie a căror intensitate
 luminoasă să fie net superioară aceleia a luminilor de poziție din spate. Aceeași dispoziție se aplică oricărei
 remorci ce constituie ultimul vehicul al unui ansamblu de vehicule; totuși, nici o lumină de frînare nu este necesară
 în cazul remorcilor mici ale căror dimensiuni sînt astfel încît permit luminilor de frînare ale vehiculului trăgător
 să fie vizibile.</p>
 
<p style="text-indent:2em;"></p><h6>32. Cu excepția posibilității pentru părțile contractante care, conform paragrafului 2 al art. 54 al convenției, vor
 declara că asimilează motoretele motocicletelor, de a scuti motoretele în total sau în parte de aceste obligații:</h6><p></p>
 
<p style="text-indent:2em;">a) orice motocicletă cu sau fără ataș trebuie să fie prevăzută cu o lumină de întîlnire care să corespundă condițiilor
 referitoare la culoare și vizibilitate stabilite la paragraful 22 de mai sus;</p><br>
 
<p style="text-indent:2em;">b) orice motocicletă cu sau fără ataș, care depășește în linie dreaptă viteza de 40 km/h, trebuie să fie echipată, în
 afară de lumina de întîlnire, cu cel puțin o lumină de drum care să corespundă dispozițiilor de culoare și vizibilitate
 stabilite la paragraful 21 de mai sus. Dacă această motocicletă are mai multe lumini de drum, acestea trebuie să fie
 situate cît mai aproape posibil una de alta;</p><br>
 
<p style="text-indent:2em;">c) o motocicletă cu două roți, cu sau fără ataș, nu trebuie să fie echipată cu mai mult de o lumină de întîlnire și
 cu mai mult de două lumini de drum.</p><br>
 
<p style="text-indent:2em;">33. Orice motocicletă cu două roți fără ataș poate fi prevăzută în față cu una sau două lumini de poziție care să
 îndeplinească condițiile de culoare și vizibilitate stabilite la paragraful 23 de mai sus. Dacă această motocicletă
 are două lumini de poziție în față, acestea trebuie să fie situate cît mai aproape posibil una de alta. O motocicletă
 cu două roți fără ataș nu trebuie să aibă mai mult de două lumini de poziție în față.</p><br>
 
<p style="text-indent:2em;">34. Orice motocicletă fără ataș trebuie să fie echipată la spate cu o lumină de poziție care să corespundă condițiilor
 de culoare și vizibilitate stabilite la paragraful 24 a) de mai sus.</p><br>
 
<p style="text-indent:2em;">35. Orice motocicletă fără ataș trebuie să fie prevăzută la spate cu un catadioptru care să satisfacă condițiile de
 culoare și vizibilitate stabilite la paragraful 27 de mai sus.</p><br>
 
<p style="text-indent:2em;">36. Cu excepția posibilității pentru părțile contractante care, conform paragrafului 2 al art. 54 al convenției, vor
 declara că asimilează motoretele motocicletelor, de a scuti de această obligație motoretele cu sau fără ataș, orice
 motocicletă cu sau fără ataș trebuie să fie prevăzută cu o lumină de frînare care să satisfacă dispozițiile paragrafului
 31 de mai sus.</p><br>
 
<p style="text-indent:2em;">37. Cu excepția dispozițiilor relative la lumini și dispozitive prevăzute pentru motocicletele fără ataș, orice ataș
 cuplat la o motocicletă cu două roți trebuie să fie echipat în față cu o lumină de poziție care să satisfacă condițiile
 de culoare și vizibilitate stabilite la paragraful 23 de mai sus, iar în spate cu o lumină de poziție care să satisfacă
 condițiile de culoare și vizibilitate stabilite la paragraful 24 a) de mai sus și cu un catadioptru care să corespundă
 condițiilor de culoare și vizibilitate prevăzute la paragraful 27 de mai sus. Legăturile electrice trebuie să fie astfel
 încît luminile de poziție din față și din spate ale atașului să se aprindă în același timp cu lumina de poziție din spate
 a motocicletei. În orice caz, un ataș nu trebuie să fie prevăzut nici cu lumină de drum și nici cu lumină de întîlnire.</p><br>
 
<p style="text-indent:2em;">38. Automobilele cu trei roți simetrice în raport de planul longitudinal median al vehiculului, asimilate motocicletelor
 în aplicarea alin. n) al art. 1 al convenției, trebuie să fie echipate cu dispozitivele prevăzute la art. 21, 22, 23, 24 a),
 27 și 31 de mai sus. Totuși, atunci cînd lățimea unui astfel de vehicul nu depășește 1,30 m, sînt suficiente doar o lumină de
 drum și o lumină de întîlnire. Dispozițiile referitoare la distanța suprafețelor luminoase în raport cu lățimea vehiculului nu
 sînt aplicabile în acest caz.</p><br>
 
<p style="text-indent:2em;">39. Orice automobil, cu excepția acelora ai căror conducători pot semnaliza cu brațul schimbările de direcție vizibile din
 toate direcțiile de către participanții la circulație, trebuie să fie echipat cu lumini indicatoare de direcție fixe, cu
 lumina intermitentă galbenă auto, dispuse în număr egal pe vehicul și vizibile ziua și noaptea de participanții la circulație
 interesați de mișcarea vehiculului. Cadența intermitenței luminii trebuie să fie de 90 pe minut cu o toleranță de ±30.</p><br>
 
<p style="text-indent:2em;">40. Dacă sînt instalate lumini de ceață pe un automobil altul decît o motocicletă cu sau fără ataș, ele trebuie să fie albe 
sau galbene selectiv, în număr de două și situate astfel încît nici un punct al suprafeței lor luminoase să nu se găsească mai
 sus de punctul cel mai înalt al suprafeței luminoase a luminilor de întîlnire și ca, de fiecare parte, punctul suprafeței luminoase
 cel mai îndepărtat de planul longitudinal median al vehiculului să nu se găsească la mai mult de 0,40 m de extremitatea lățimii 
 vehiculului.</p><br>
 
<p style="text-indent:2em;">41. Nici o lumină de mers înapoi nu trebuie să orbească sau să stînjenească pe ceilalți participanți la circulație. Dacă
 o astfel de lumină este instalată pe un automobil, ea trebuie să emită o lumină albă, galbenă auto sau galbenă selectiv.
 Comanda de aprindere a acestei lumini trebuie să fie astfel încît să nu se poată aprinde decît atunci cînd a fost pus în 
 funcțiune dispozitivul de mers înapoi.</p><br>
 
<p style="text-indent:2em;">42. Nici o lumină, alta decît luminile indicatoare de direcție, montată pe un automobil sau remorcă, nu trebuie să fie 
intermitentă, cu excepția acelora care sînt folosite, conform legislației naționale a părților contractante, pentru a 
semnaliza vehiculele sau ansamblurile de vehicule care nu sînt obligate să respecte regulile generale de circulație sau
 a căror prezență pe drum impune celorlalți participanți la circulație precauții deosebite, cum sînt vehiculele prioritare,
 convoaiele de vehicule, vehiculele agabaritice și vehiculele sau mașinile destinate construcției sau întreținerii drumurilor.
 Totuși, părțile contractante pot permite sau dispune ca anumite lumini, altele decît cele de culoare roșie, să fie intermitente
 în total sau în parte pentru a semnaliza pericolul deosebit pe care-l prezintă pentru moment vehiculul.</p>
 
<p style="text-indent:2em;"></p><h6>43. În aplicarea dispozițiilor prezentei anexe, va fi considerat:</h6><p></p>

<p style="text-indent:2em;">a) ca o singură lumină orice combinație a două sau a mai multor lumini, identice sau nu, dar avînd aceeași funcție și aceeași culoare,
 ale căror proiecții ale suprafețelor luminoase pe un plan vertical perpendicular pe planul longitudinal median al vehiculului ocupă
 cel puțin 50% din suprafața celui mai mic dreptunghi circumscris proiecțiilor suprafețelor luminoase arătate mai sus;</p><br>
 
<p style="text-indent:2em;">b) ca două sau ca un număr pereche de lumini, o singură suprafață luminoasă avînd forma unei benzi atunci cînd aceasta este situată
 simetric în raport cu planul longitudinal median al vehiculului și cînd se află cel puțin la 0,40 m de marginea exterioară a lățimii
 vehiculului de fiecare parte a acesteia, avînd o lungime minimă de 0,80 m. Iluminarea unei astfel de suprafețe trebuie să fie
 asigurată de cel puțin două surse luminoase situate cît mai aproape de extremitățile sale. Suprafața luminoasă poate consta dintr-un
 ansamblu de elemente suprapuse astfel încît proiecțiile diverselor suprafețe luminoase elementare pe un plan vertical perpendicular
 pe planul longitudinal median al vehiculului să ocupe cel puțin 50% din suprafața celui mai mic dreptunghi circumscris proiecțiilor
 suprafețelor luminoase elementare arătate mai sus.</p><br>
 
<p style="text-indent:2em;">44. Pe un același vehicul luminile care au aceeași funcție și sînt orientate spre aceeași direcție, trebuie să fie
 de aceeași culoare. Luminile și catadioptrii care sînt în număr pereche trebuie să fie situate simetric în raport
 cu planul longitudinal median al vehiculului, cu excepția vehiculelor a căror formă exterioară este asimetrică.
 Luminile fiecărei perechi trebuie să aibă aproape aceeași intensitate.</p><br>
 
<p style="text-indent:2em;">45. Luminile de natură diferită și, cu excepția dispozițiilor celorlalte paragrafe ale prezentului capitol,
 luminile și catadioptrii, pot fi grupate sau înglobate în același dispozitiv, cu condiția ca fiecare din aceste
 lumini și catadioptri să corespundă dispozițiilor prezentei anexe care le sînt aplicabile.</p>
 
<p></p><h4>CAPITOLUL III</h4><p></p>

<p style="font-weight:500;">Alte dispoziții</p><br>

<p style="font-weight:500;">Aparat de direcție</p><br>

<p style="text-indent:2em;">46. Orice automobil trebuie să fie echipat cu un aparat de direcție robust care să permită conducătorului
 să schimbe cu ușurință, repede și în siguranță direcția vehiculului său.</p><br>
 
<p style="font-weight:500;">Oglindă retrovizoare</p><br>

<p style="text-indent:2em;">47. Orice automobil altul decît o motocicletă cu două roți cu sau fără ataș trebuie să fie echipat cu una
 sau mai multe oglinzi retrovizoare; numărul, dimensiunile și modul de dispunere a acestor oglinzi trebuie
 să permită conducătorului să vadă circulația din spatele vehiculului său.</p><br>
 
<p style="font-weight:500;">Avertizor sonor</p><br>

<p style="text-indent:2em;">48. Orice automobil trebuie să fie prevăzut cu cel puțin un avertizor sonor care să aibă o putere
 suficientă. Sunetul emis de el trebuie să fie continuu, uniform și nu strident. Vehiculele prioritare
 și cele din serviciul public de transport al pasagerilor pot să aibă avertizoare sonore suplimentare
 ce nu sînt supuse acestor cerințe.</p><br>
 
<p style="font-weight:500;">Ștergător de parbriz</p><br>

<p style="text-indent:2em;">49. Orice automobil prevăzut cu un parbriz de dimensiuni și formă prin care conducătorul poate
 din locul său de conducere să vadă în față drumul prin elementele transparente ale acestuia,
 trebuie să fie prevăzut cu cel puțin un ștergător de parbriz, eficace și robust, situat într-o
 poziție corespunzătoare și a cărei funcționare să nu solicite intervenția continuă a
 conducătorului.</p><br>
 
<p style="font-weight:500;">Spălător de parbriz</p><br>

<p style="text-indent:2em;">50. Orice automobil supus obligației de a avea cel puțin un ștergător de parbriz trebuie să fie
 prevăzut și cu un spălător de parbriz.</p><br>
 
<p style="font-weight:500;">Parbriz și geamuri</p>

<p style="text-indent:2em;"></p><h6>51. La orice automobil și remorcă:</h6><p></p>

<p style="text-indent:2em;">a) materialele transparente care constituie elementele exterioare ale pereților vehiculului,
 inclusiv parbrizul, sau peretele interior despărțitor, trebuie să fie în așa fel încît, în 
 caz de spargere, pericolul leziunilor corporale să fie cît mai redus posibil;</p><br>
 
<p style="text-indent:2em;">b) geamurile parbrizului trebuie să fie dintr-un material a cărui transparență să nu altereze
 și să nu deformeze în mod apreciabil obiectele văzute prin el. iar În caz de spargere conducătorul
 să poată vedea suficient drumul.</p><br>
 
<p style="font-weight:500;">Dispozitivul de mers înapoi</p><br>

<p style="text-indent:2em;">52. Orice automobil trebuie să fie prevăzut cu un dispozitiv de mers înapoi care să poată
 fi manevrat din locul conducătorului. Acest dispozitiv nu este obligatoriu pe motocicletele
 și automobilele cu trei roți simetrice în raport cu planul longitudinal median al vehiculului
 decît dacă greutatea lor maximă autorizată depășește 400 kg.</p><br>
 
<p style="font-weight:500;">Amortizorul de zgomot</p><br>

<p style="text-indent:2em;">53. Fiecare motor cu ardere internă al unui automobil trebuie să fie prevăzut cu un
 dispozitiv de eșapare silențios eficace; acest dispozitiv trebuie să fie astfel încît
 să nu poată fi făcut fără efect de către conducător de la locul său de conducere.</p><br>
 
<p style="font-weight:500;">Pneuri</p><br>

<p style="text-indent:2em;">54. Roțile automobilelor și ale remorcilor trebuie să fie echipate cu pneuri, iar starea
 acestora trebuie să asigure securitatea. Inclusiv aderența, chiar cînd partea carosabilă
 este umedă. Totuși, prezenta dispoziție nu ar putea să împiedice părțile contractante să
 permită utilizarea de dispozitive care să dea rezultate cel puțin echivalente acelora obținute
 cu pneuri.</p><br>
 
<p style="font-weight:500;">Indicator de viteză</p><br>

<p style="text-indent:2em;">55. Orice automobil care depășește în linie dreaptă viteza de 40 km/h trebuie să fie prevăzut
 cu un indicator de viteză, fiecare parte contractantă putînd să dispenseze de această obligație
 anumite categorii de motociclete și alte vehicule ușoare.</p><br>
 
<p style="font-weight:500;">Dispozitiv de semnalizare la bordul automobilelor</p>

<p style="text-indent:2em;"></p><h6>56. Dispozitivul prevăzut la paragraful 5 al art. 23 și la paragraful
 6 al anexei nr. 1 din convenție trebuie să fie:</h6><p></p>
 
<p style="text-indent:2em;">a) ori un panou constînd dintr-un triunghi echilateral cu o latură de cel puțin
 0,40 m, avînd marginile roșii de cel puțin 0,05 m, centrul fiind gol sau de culoare
 deschisă; marginile roșii trebuie să fie iluminate prin transparență sau prevăzute
 cu o bandă reflectorizantă; panoul trebuie să fie astfel încît să poată fi așezat
 într-o poziție verticală stabilă;</p><br>
 
<p>b) ori un alt dispozitiv la fel de eficace prevăzut de legislația țării
 unde a fost înmatriculat vehiculul.</p><br>
 
<p style="font-weight:500;">Dispozitiv antifurt</p><br>

<p style="text-indent:2em;">57. Orice automobil trebuie să fie prevăzut cu un dispozitiv antifurt care să permită,
 din momentul în care vehiculul este lăsat în staționare, punerea în pană sau blocarea
 unui organ esențial al vehiculului.</p><br>
 
<p style="font-weight:500;">Dispozitiv de cuplare a remorcilor ușoare</p><br>

<p style="text-indent:2em;">58. Cu excepția semiremorcilor, remorcile care nu sînt echipate cu frînă automată
 prevăzută la paragraful 16 al acestei anexe trebuie să fie echipate, în afara unui
 dispozitiv de cuplare, cu un dispozitiv secundar de ataș (lanț, cablu etc.) care,
 în caz de rupere a dispozitivului de cuplare, să poată împiedica cîrma să atingă
 pămîntul și să asigure o anumită direcție reziduală remorcii.</p><br>
 
<p style="font-weight:500;">Dispoziții generale</p>

<p style="text-indent:2em;"></p><h6>59:</h6><p></p>

<p style="text-indent:2em;">a) În măsura în care este posibil, organele mecanice și echipamentele automobilelor nu trebuie să
 prezinte riscul de incendiu sau explozie; ele nu trebuie să dea loc la o emanație excesivă de gaze
 nocive, de fum opac, de mirosuri sau de zgomote;</p><br>
 
<p style="text-indent:2em;">b) în măsura în care este posibil, dispozitivul de aprindere de înaltă tensiune a motorului
 automobilelor nu trebuie să dea loc la o emitere excesivă de paraziți radioelectrici;</p><br>
 
<p style="text-indent:2em;">c) orice automobil trebuie să fie construit în așa fel încît, în față, spre dreapta și spre stînga,
 cîmpul de vizibilitate al conducătorului să fie suficient pentru a-i permite să conducă în 
 siguranță;</p><br>

<p style="text-indent:2em;">d) în măsura în care este posibil, automobilele și remorcile trebuie să fie construite și echipate în așa
 fel încît în caz de accident să reducă pericolul pentru ocupanții lor și pentru ceilalți participanți la
 circulație; în special, ele nu trebuie să aibă înăuntru sau în afară ornamente sau alte obiecte care prezintă
 muchii ori proeminențe inutile, constituind un pericol pentru ocupanți și pentru ceilalți participanți 
 la circulație.</p>
 
<p></p><h4>CAPITOLUL IV</h4><p></p>

<p style="font-weight:500;">Excepții</p>

<p style="text-indent:2em;"></p><h6>60. Pe plan național, părțile contractante pot deroga, în următoarele cazuri, de la dispozițiile
 prezentei anexe:</h6><p></p>
 
<p style="text-indent:2em;">a) pentru automobilele și remorcile a căror viteză prin construcție nu depășește în linie dreaptă
 25 km/h, sau pentru care legislația națională o limitează la 25 km/h;</p><br>
 
<p style="text-indent:2em;">b) pentru vehiculele infirmilor, adică automobilele mici, special concepute și construite - nu numai
 adaptate - pentru uzul unei persoane atinse de o infirmitate sau o incapacitate fizică și care nu sînt
 utilizate în mod normal decît de acea persoană;</p><br>
 
<p>c) pentru vehiculele destinate experiențelor ce au ca scop realizarea progresului tehnic și îmbunătățirea
 siguranței;</p><br>
 
<p>d) pentru vehiculele de o formă ori de un tip special, sau care sînt utilizate în scopuri deosebite în
 condiții speciale.</p>
 
<p style="text-indent:2em;"></p><h6>61. Părțile contractante pot, de asemenea, să excepteze de la dispozițiile prezentei anexe vehiculele pe
 care le înmatriculează și care se pot angaja în circulație internațională:</h6><p></p>
 
<p style="text-indent:2em;">a) autorizînd culoarea galben auto pentru luminile de poziție prevăzute la paragrafele 23 și 30 și pentru catadioptrii
 prevăzuți la paragraful 29 ale acestei anexe;</p><br>
 
<p style="text-indent:2em;">b) autorizînd culoarea roșie pentru acele dintre luminile indicatoare de direcție prevăzute la paragraful 39 al
 acestei anexe care sînt montate la spatele vehiculului;</p><br>
 
<p style="text-indent:2em;">c) autorizînd culoarea roșie pentru acele dintre, luminile prevăzute în ultima frază a paragrafului 42 al acestei
 anexe care sînt montate la spatele vehiculului;</p><br>
 
<p style="text-indent:2em;">d) în ce privește poziția luminilor la vehiculele care au o utilizare specială și a căror formă exterioară nu
 ar permite respectarea acestor dispoziții fără să se recurgă la dispozitive de montare ce riscă să fie avariate
 sau smulse cu ușurință;</p><br>
 
<p style="text-indent:2em;">e) autorizînd prezența unui număr impar, mai mare de două lumini de drum, pe automobilele pe care le înmatriculează; și</p><br>

<p style="text-indent:2em;">f) în ce privește remorcile care servesc la transportul încărcăturilor lungi (trunchiuri de arbori, tuburi etc.) și care,
 în mers, nu sînt cuplate la un vehicul trăgător, ci sînt legate de vehiculul trăgător numai
 prin încărcătură.</p>
 
<p></p><h4>CAPITOLUL V</h4><p></p>

<p style="font-weight:500;">Dispoziții tranzitorii</p><br>

<p style="text-indent:2em;">62. Automobilele înmatriculate pentru prima dată și remorcile puse în circulație pe teritoriul uneia dintre părțile
 contractante înainte de intrarea în vigoare a convenției sau după 2 ani de la intrarea ei în vigoare nu vor fi supuse 
 dispozițiilor acestei anexe, cu condiția ca ele să satisfacă dispozițiile părților I, II și III ale anexei nr. 6 a
 Convenției din 1949 asupra circulației rutiere.</p>
 
<p style="text-indent:2em;"></p><h6>Definirea filtrelor colorate pentru obținerea culorilor prevăzute în această anexă (coordonate tricromatice):</h6><p></p>
	
<p>Roșu ...........	limita spre galben ............	y &lt;= 0,335</p><br>

<p>limita spre purpuriu ..........	z &lt;= 0,008</p><br>

<p>Alb ............	limita spre albastru ..........	x &gt;= 0,310</p><br>

<p>limita spre galben ............	x &lt;= 0,500</p><br>

<p>limita spre verde .............	y &lt;= 0,150 + 0,640 x</p><br>

<p>limita spre verde .............	y &lt;= 0,440</p><br>

<p>limita spre purpuriu ..........	y &gt;= 0,050 + 0,750 x</p><br>

<p>limita spre roșu ..............	y &gt;= 0,382</p><br>

<p>Galben auto	limita spre galben ............	y &lt;= 0,429</p><br>

<p>limita spre roșu ..............	y &gt;= 0,398</p><br>

<p>limita spre alb ...............	z &lt;= 0,007</p><br>

<p>Galben selectiv	limita spre roșu ..............	y &gt;= 0,138 + 0,580 x</p><br>

<p>limita spre verde .............	y &lt;= 1,29 x - 0,100</p><br>

<p>limita spre alb ...............	y &lt;= - x + 0,966</p><br>

<p>valoarea spectrală ............	y &lt;= - x + 0,992</p><br>

<p style="text-indent:2em;">Pentru verificarea caracteristicilor colorimetrice ale acestor filtre, se va utiliza o sursă
 luminoasă la temperatura de culoare de 2854°K [corespunzătoare iluminantului A al Comisiei
 internaționale de iluminare (C.I.E.)].</p><br>

<p style="font-weight:500;">ANEXA Nr. 6</p><br>

<p style="text-indent:2em;">1. Permisul de conducere național este fie o coală de hîrtie format A 7 (74X105 mm), fie o coală pliabilă
 în dublu (148X105 mm) sau în triplu (222x105 mm) a acestui format. El este de culoare roz.</p><br>
 
<p style="text-indent:2em;">2. Permisul este imprimat în limba sau în limbile prevăzute de autoritatea care-l emite sau autorizată
 să-l emită; totuși, el poartă, în franceză, titlul "Permis de conducere", însoțit sau nu de titlu în
 alte limbi.</p><br>
 
<p style="text-indent:2em;">3. Indicațiile scrise de mînă sau dactilografiate existente pe permis sînt fie numai cu caractere latine
 sau cursive zise engleze, fie repetate în acest mod.</p><br>
 
<p style="text-indent:2em;">4. Două din paginile permisului sînt conforme paginilor model 1 și 2, alăturate. Cu condiția ca mărimea
 rubricilor A, B, C, D și E, conform paragrafului 4 al art. 41 din convenție, literele din text sau esențialul
 din rubricile privind identitatea titularului permisului, să nu fie modificate, prezenta dispoziție va fi
 considerată ca satisfăcătoare, chiar dacă, în raport de aceste modele, li s-au adus modificări de detaliu,
 permisele de conducere naționale conforme modelului din anexa nr. 9 a Convenției asupra circulației rutiere
 de la Geneva, din 19 septembrie 1949, vor fi considerate ca satisfăcînd dispozițiile cuprinse în prezenta
 anexă.</p><br>
 
<p style="text-indent:2em;">5. Dreptul de a stabili dacă pagina model nr. 3 trebuie sau nu să facă parte din permis și dacă permisul trebuie
 sau nu să conțină indicații suplimentare, aparține legislației naționale; dacă este prevăzut un spațiu pentru
 a trece schimbările de domiciliu, acesta va fi, cu excepția permisului conform modelului din anexa nr. 9 a
 Convenției din 1949, deasupra, pe versoul paginii a 3-a a permisului.</p><br>

<p style="text-indent:2em;">1) Pe modelele pliabile în două (dacă sînt pliate în așa fel ÎncÎt coperta să nu fie o pagină
 model) și pe modelele pliabile În trei, această indicație poate figura pe copertă.</p><br>
 
<p style="text-indent:2em;">2) Se va înscrie denumirea sau semnul distinctiv al statului, așa cum este el definit conform
 anexei nr. 3 a convenției. Observația 1 de mai sus se aplică și acestei rubrici.</p><br>
 
<p>3) Pot fi scrise aici numele tatălui sau al soțului.</p><br>

<p>4) Dacă data nașterii nu este cunoscută, se va trece vîrsta aproximativă
 la data eliberării permisului.</p><br>
 
<p>5) Nu se va trece nimic, dacă locul nașterii este necunoscut.</p><br>

<p>6) În lipsă, amprenta degetului mare. Semnătura sau amprenta degetului mare,
 precum și spațiul destinat, pot fi omise.</p><br>
 
<p>7) Această rubrică este facultativă în cazul în care permisul are și o pagină
 model nr. 3.</p><br>
 
<p style="text-indent:2em;">8) Semnătura și sigiliul ori pecetea autorității care eliberează permisul sau
 al asociației împuternicită să o facă. Pe modelele pliabile în două (în așa
 fel încît coperta nu este o pagină model) și pe modelele pliabile în trei,
 sigiliul sau pecetea poate fi aplicată pe copertă.</p><br>
 
<p style="text-indent:2em;">9) Pecetea sau sigiliul autorității care a eliberat permisul și, eventual, data
 aplicării peceții sau sigiliului. Acesta va fi pus în coloana din dreapta paginii
 model nr. 2, în dreptul despărțiturilor referitoare la definirea categoriilor de
 vehicule pentru care este valabil permisul și numai în dreptul acestora; aceleași
 dispoziții se aplică și mențiunilor făcute în coloana din dreapta paginii model nr. 3,
 în ceea ce privește reînnoirile acordate.</p><br>
 
<p style="text-indent:2em;">Părțile contractante pot ca, în loc să pună sigiliul sau pecetea autorității în coloana
 din dreapta paginii model nr. 2, să înscrie într-o nouă rubrică 8 "Categorii", pe pagina
 model nr. 1, litera sau literele corespunzătoare categoriei sau categoriilor pentru care
 este valabil permisul și un asterisc pentru fiecare categorie pentru care permisul nu este
 valabil (De exemplu: "8, Categoria A, B ***").</p><br>
 
<p>10) Spațiu rezervat celorlalte categorii de vehicule stabilite de legislația națională.</p><br>

<p style="text-indent:2em;">11) Spațiu rezervat unor observații suplimentare pe care autoritățile competente ale statului
 care eliberează permisul ar dori eventual să le facă, inclusiv condițiile restrictive de
 utilizare (de ex. "Port de ochelari corectori") - "Valabil numai pentru conducerea autovehiculului
 nr. ......", sau "sub rezerva amenajării vehiculului pentru conducerea de către o persoană care nu are un picior".
 În cazul prevăzut de alineatul 2 al notei 9 de mai sus, aceste observații suplimentare vor figura, de preferință
 , pe pagina model nr. 1.</p><br>
 
<p>Alte observații pot fi consemnate pe pagini ce nu sînt pagini model.</p><br>

<p style="font-weight:500;">ANEXA Nr. 7</p><br>

<p>1. Permisul este un carnet format A 6 (148x105 mm). Coperta sa este gri; paginile interioare sînt albe.</p><br>

<p style="text-indent:2em;">2. Ambele pagini ale primei foi ale copertei sînt conforme paginilor 1 și, respectiv, 2 de mai jos; ele
 sînt imprimate în limba națională sau în cel puțin una din limbile naționale ale statului care l-a eliberat.
 La sfîrșitul paginilor interioare, două pagini suprapuse, sînt conforme modelului nr. 3 de mai jos și sînt
 imprimate în franceză. Paginile interioare care preced aceste două pagini reproduc în mai multe limbi, dintre
 care în mod obligatoriu engleza, spaniola sau rusa, prima din aceste două pagini.</p><br>
 
<p>3. Indicațiile scrise de mînă sau dactilografiate vor fi cu caractere latine sau cursive, zise engleze.</p><br>

<p style="text-indent:2em;">4. Părțile contractante care eliberează sau autoriză eliberarea permiselor de conducere internaționale ale căror,
 coperți sînt imprimate într-o limbă care nu este nici engleza, nici spaniola, nici franceza, nici rusa, vor comunica
 secretarului general al Organizației Națiunilor Unite traducerea în această limbă a textului model 3 de mai jos.</p><br>
 
<p style="font-weight:500;">Pagina model nr. 1
(fața primei foi a copertei)</p><br>

<p>1) Numele statului de eliberare și semnul distinctiv al acestei țări, definit în anexa nr. 3.</p><br>

<p style="text-indent:2em;">2) Trei ani de la data eliberării sau la data expirării valabilității permisului de conducere național,
 la acea din cele două date care este anterioară celeilalte.</p><br>
 
<p>3) Semnătura autorității sau a asociației care a eliberat permisul.</p><br>

<p>4) Pecetea sau sigiliul autorității ori asociației care a eliberat permisul.</p><br>

<p style="font-weight:500;">Pagina model nr. 2
(versoul primei foi a copertei)</p><br>

<p>Prezentul permis nu este valabil pentru circulația pe teritoriul ........1)</p><br>

<p style="text-indent:2em;">Este valabil pe teritoriile tuturor celorlalte părți contractante. Categoriile de vehicule pentru a
 căror conducere este valabil sînt specificate la sfîrșitul carnetului.2)</p><br>
 
<p style="text-indent:2em;">Prezentul permis nu afectează cu nimic obligația ca acolo unde se află titularul său să respecte
 în orice stat unde circulă legile și reglementările privind stabilirea și exercitarea unei profesii.
 Permisul încetează de a fi valabil într-un stat dacă titularul său și-a stabilit aici reședința
 obișnuită.</p><br>
 
<p>1) Se va înscrie aici denumirea părții contractante unde titularul își are reședința obișnuită.</p><br>

<p>2) Spațiu rezervat înscrierii facultative a listei statelor părți contractante.</p><br>

<p style="font-weight:500;">Model nr. 3
(pagina din stînga)</p><br>

<p>1) Numele tatălui sau soțului pot fi înscrise în acest spațiu.</p><br>

<p>2) Nu se va completa cu nimic dacă locul nașterii nu este cunoscut.</p><br>

<p>3) Dacă data nașterii nu este cunoscută, se va indica vîrsta aproximativă la data eliberării permisului.</p><br>

<p style="text-indent:2em;">4) Pecetea sau sigiliul autorității ori asociației care a eliberat permisul. Această pecete sau acest sigiliu nu
 va fi aplicat în dreptul categoriilor A, B, C, D sau E decît dacă titularul are dreptul să conducă vehicule
 din categoria respectivă.</p><br>
 
<p style="text-indent:2em;">5) De exemplu: ..... "Poartă ochelari de corecție", "Valabil doar pentru conducerea vehiculului nr. .......",
 "Sub rezerva amenajării vehiculului pentru conducerea de către o persoană care are un picior amputat".</p><br>
 
<p style="font-weight:500;">Model nr. 3
(pagina din dreapta)</p><br>

<p>6) În lipsă, amprenta degetului mare.</p><br>

<p>7) Denumirea statului.</p><br>

<p style="text-indent:2em;">8) Semnătura și sigiliul sau pecetea autorității care a anulat valabilitatea permisului pe teritoriul său.
 În cazul în care spațiile prevăzute pe prezenta pagină pentru decăderi au fost deja utilizate, decăderile
 ulterioare vor fi înscrise pe verso.</p><br>
 
 <p><a href="https://lege5.ro/Gratuit/he2danbu/conventia-asupra-circulatiei-rutiere-din-08111968">Sursa</a></p>

</div>
`