export const int9 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->

<p></p><h4>Конвенция о дорожных знаках и сигналах</h4><p></p>

<p></p><h4>(Вена, 8 ноября 1968 г.)</h4><p></p>

<p style="text-indent:2em;"></p><h6>Договаривающиеся стороны, признавая, что единообразие дорожных знаков, сигналов и обозначений и разметки дорог в
 международном плане необходимо для облегчения международного дорожного движения и повышения безопасности на дорогах, 
 согласились о нижеследующем:</h6><p></p>

<p></p><h4>Глава I</h4><p></p>

<p style="font-weight:500;">Общие положения</p><br>

<p style="font-weight:500;">Статья 1</p><br>

<p>Определения</p>

<p style="text-indent:2em;"></p><h6>При применении положений настоящей Конвенции нижеследующие термины имеют то значение, которое придается им в настоящей
 статье:</h6><p></p>

<p style="text-indent:2em;">a) термин "национальное законодательство" Договаривающейся стороны означает совокупность национальных или местных 
законов и правил, действующих на территории этой Договаривающейся стороны;</p><br>

<p style="text-indent:2em;">b) термин "населенный пункт" означает застроенную территорию, въезды на которую и выезды с которой специально 
обозначены соответствующими знаками или которая каким-либо иным образом определена в национальном 
законодательстве;</p><br>

<p>c) термин "дорога" означает всю полосу отвода любой дороги или улицы, открытой для движения;</p><br>

<p style="text-indent:2em;">d) термин "проезжая часть дороги" означает часть дороги, обычно используемую для движения транспортных средств;
 дорога может иметь несколько проезжих частей, четко разграниченных, в частности разделительной полосой, или
 находящихся на различных уровнях;</p><br>

<p style="text-indent:2em;">e) термин "полоса движения" означает любую из продольных полос, на которые может быть разделена проезжая часть
 дороги, обозначенных или не обозначенных посредством продольной разметки, но имеющих ширину, достаточную для
 движения в один ряд автомобилей, иных, чем мотоциклы;</p><br>

<p style="text-indent:2em;">f) термин "перекресток" означает любое пересечение на одном уровне, соединение или разветвление дорог, включая
 территорию, образуемую такими пересечениями, соединениями или разветвлениями;</p><br>

<p style="text-indent:2em;">g) термин "железнодорожный переезд" означает любое пересечение на одном уровне дороги с железнодорожными или
 трамвайными путями, имеющими независимое полотно;</p>

<p style="text-indent:2em;"></p><h6>h) термин "автомагистраль" означает дорогу, специально построенную и предназначенную для движения автотранспортных
 средств, которая не обслуживает придорожных владений и которая:</h6><p></p>

<p style="text-indent:2em;">i) за исключением отдельных мест или во временном порядке имеет для обоих направлений движения отдельные проезжие
 части, отделенные друг от друга разделительной полосой, не предназначенной для движения, или в исключительных 
 случаях другими средствами;</p><br>

<p style="text-indent:2em;">ii) не имеет пересечений на одном уровне ни с дорогами, ни с железнодорожными или трамвайными путями, ни с 
пешеходными дорожками;</p><br>

<p>iii) специально обозначена в качестве автомагистрали;</p>

<p style="text-indent:2em;"></p><h6>j) транспортное средство считается:</h6><p></p>

<p style="text-indent:2em;">i) "остановленным", если оно приведено в неподвижное состояние на период времени, необходимый либо для
 посадки или высадки людей, либо для погрузки или выгрузки грузов;</p><br>

<p style="text-indent:2em;">ii) "находящимся на стоянке", если оно приведено в неподвижное состояние по причине, иной, чем необходимость
 избежать столкновения с другим пользователем дороги или с каким-либо препятствием или выполнить предписания
 правил движения, и если его остановка не ограничивается временем, необходимым либо для посадки или высадки
 людей, либо для погрузки или выгрузки грузов.</p><br>

<p style="text-indent:2em;">Однако Договаривающиеся стороны могут считать "остановленными" транспортные средства, приведенные в неподвижное
 состояние в условиях, определенных выше в подпункте "ii", если длительность этого состояния не превышает предела 
 времени, установленного национальным законодательством, и могут считать "находящимися на стоянке" транспортные средства,
 приведенные в неподвижное состояние в условиях, определенных выше в подпункте "i", если длительность этого состояния превышает
 предел времени, установленный национальным законодательством;</p><br>

<p style="text-indent:2em;">j) термин "велосипед" означает любое транспортное средство, которое имеет по крайней мере два колеса и приводится в
 движение исключительно мускульной энергией лиц, находящихся на этом транспортном средстве, в частности при помощи
 педалей или рукояток;</p><br>

<p style="text-indent:2em;">к) термин "велосипед с подвесным двигателем" означает любое двухили трехколесное транспортное средство, снабженное
 двигателем внутреннего сгорания с объемом цилиндра, не превышающим 50 куб. см (3,05 куб. дюйма) и максимальная конструктивная
 скорость которого не превышает 50 км/ч (30 миль/ч); однако Доваривающиеся стороны могут не считать велосипедами с подвесным
 двигателем в соответствии со своим национальным законодательством те транспортные средства, которые не имеют характеристик
 велосипедов в отношении возможностей их использования, в частности возможности приведения их в движение при помощи педалей, 
 или которые по своей максимальной конструктивной скорости, весу или некоторым характеристикам двигателя превышают
 установленные пределы. Ни одно положение настоящего определения не должно толковаться как препятствующее Договаривающимся
 сторонам полностью приравнивать велосипеды с подвесным двигателем к велосипедам при применении предписаний своих 
 национальных законодательств, касающихся дорожного движения;</p><br>

<p style="text-indent:2em;">l) термин "мотоцикл" означает любое двухколесное транспортное средство с коляской или без нее, имеющее двигатель.
 Договаривающиеся стороны могут в своем национальном законодательстве приравнивать к мотоциклам трехколесные транспортные
 средства, вес которых без нагрузки не превышает 400 кГ (900 фунтов). Термин "мотоцикл" не включает велосипеды с подвесным 
 двигателем, однако Договаривающиеся стороны могут при условии, что они сделают соответствующее заявление согласно п. 2 ст. 
 46 настоящей Конвенции, приравнивать велосипеды с подвесным двигателем к мотоциклам при применении настоящей Конвенции;</p><br>

<p style="text-indent:2em;">m) термин "механическое транспортное средство" означает любое самоходное дорожное транспортное средство, за исключением 
велосипедов с подвесным двигателем на территории Договаривающихся сторон, которые не приравнивают их к мотоциклам, и за 
исключением рельсовых транспортных средств;</p><br>

<p style="text-indent:2em;">n) термин "автомобиль" означает механическое транспортное средство используемое обычно для перевозки по дорогам людей 
или грузов или для буксировки по дорогам транспортных средств, используемых для перевозки людей или грузов. Этот термин
 охватывает троллейбусы, т.е. нерельсовые транспортные средства, соединенные с электрическим проводом; он не охватывает
 такие транспортные средства, как сельскохозяйственные тракторы, использование которых для перевозки людей или грузов по
 дорогам или для буксировки по дорогам транспортных средств, используемых для перевозки людей или грузов, является лишь
 вспомогательной функцией;</p><br>

<p style="text-indent:2em;">o) термин "прицеп" означает любое транспортное средство, предназначенное для его буксировки механическим транспортным
 средством; этот термин охватывает также полуприцепы;</p><br>

<p style="text-indent:2em;">p) термин "полуприцеп" означает любой прицеп, предназначенный для сцепления с автомобилем таким образом, что часть его
 опирается на автомобиль, и последний несет на себе значительную часть веса полуприцепа и его груза;</p><br>

<p style="text-indent:2em;">g) термин "водитель" ("погонщик") означает всякое лицо, управляющее транспортным средством, автомобилем и т.д. (включая
 велосипеды) или ведущее по дороге скот, стада, упряжных, вьючных или верховых животных;</p><br>

<p style="text-indent:2em;">r) термин "разрешенный максимальный вес" означает максимальный вес груженого транспортного средства, объявленный 
допустимым компетентными органами государства, в котором зарегистрировано транспортное средство;</p><br>

<p style="text-indent:2em;">s) термин "вес с нагрузкой" означает фактический вес груженого транспортного средства, экипажа и пассажиров;</p><br>

<p style="text-indent:2em;">t) термины "направление движения" и "соответствующий направлению движения" означают правую сторону, если в соответствии
 с действующим национальным законодательством водитель транспортного средства должен при встрече с другим транспортным
 средством пропустить его слева; в противном случае эти термины означают левую сторону;</p><br>

<p style="text-indent:2em;">u) предписание для водителя "уступить дорогу" другим транспортным средствам означает, что водитель не должен продолжать
 или возобновлять движение или осуществлять маневр, если это может вынудить водителей других транспортных средств
 внезапно изменить направление движения или скорость.</p><br>

<p style="font-weight:500;">Статья 2</p><br>

<p>Приложения к Конвенции</p><br>

<p>Нижеследующие приложения к настоящей Конвенции являются ее неотъемлемой частью.</p><br>

<p style="text-indent:2em;">Приложение 1. Предупреждающие знаки, за исключением знаков, устанавливаемых вблизи от перекрестков или
 железнодорожных переездов.</p><br>

<p style="text-indent:2em;">Приложение 2. Знаки, регулирующие преимущественное право проезда на перекрестках, предупреждающие знаки,
 устанавливаемые вблизи от перекрестков, и знаки, регулирующие преимущественное право проезда на узких
 участках дорог.</p><br>

<p>Приложение 3. Знаки, относящиеся к железнодорожным переездам.</p><br>

<p style="text-indent:2em;">Приложение 4. Знаки, означающие обязательное предписание, за исключением знаков, относящихся к
 преимущественному праву проезда, остановке и стоянке.</p><br>

<p>Приложение 5. Указательные знаки, за исключением знаков, относящихся к стоянке.</p><br>

<p>Приложение 6. Знаки относящиеся к остановке и стоянке.</p><br>

<p>Приложение 7. Дополнительные таблички.</p><br>

<p>Приложение 8. Разметка дорог.</p><br>

<p style="text-indent:2em;">Приложение 9. Цветное изображение сигнальных знаков, обозначений и табличек, о которых идет речь
 в приложениях 1 - 7.</p><br>

<p>Обязательства Договаривающихся сторон</p><br>

<p style="font-weight:500;">Статья 3</p>

<p style="text-indent:2em;"></p><h6>1. a) Договаривающиеся стороны настоящей Конвенции принимают установленную в ней систему дорожных знаков
 и сигналов и разметки дорог и обязуются провести ее в жизнь в возможно короткий срок. С этой целью:</h6><p></p>

<p style="text-indent:2em;">i) когда настоящая Конвенция определяет сигнальный знак, обозначение или разметку для указания предписания
 или для сообщения пользователям дороги определенной информации, Договаривающиеся стороны обязуются с 
 соблюдением сроков, предусмотренных в пп. 2 и 3 настоящей статьи, не применять другого сигнального знака,
 другого обозначения или другой разметки для указания этого предписания или для сообщения этой информации;</p><br>

<p style="text-indent:2em;">ii) когда настоящая Конвенция не предусматривает сигнального знака, обозначения или разметки для указания 
предписания или для сообщения пользователям дороги определенной информации, Договаривающиеся стороны могут 
пользоваться в этих целях избранным ими сигнальным знаком, обозначением или разметкой при условии, что этот 
сигнальный знак, обозначение или разметка не предусмотрены уже Конвенцией для указаний, имеющих иное значение
, и при условии, что они соответствуют установленной ею системе.</p><br>

<p style="text-indent:2em;">b) для улучшения контроля за движением и в связи с необходимостью проведения опытов, прежде чем предлагать 
поправки к настоящей Конвенции, Договаривающиеся стороны могут в виде эксперимента и во временном порядке 
допускать на некоторых участках дорог отступления от положений настоящей Конвенции.</p><br>

<p style="text-indent:2em;">2. Договаривающиеся стороны обязуются заменить или дополнить не позже, чем через четыре года со дня 
вступления в силу настоящей Конвенции, на своей территории все сигнальные знаки, обозначения, приспособления
 и разметки, которые при внешнем соответствии сигнальным знакам, обозначением, приспособлениям и разметкам системы,
 установленной настоящей Конвенцией, могли бы давать указания, имеющие иное значение, чем то, которое придано данным
 сигнальным знакам, обозначениям, приспособлениям и разметкам в настоящей Конвенции.</p><br>

<p style="text-indent:2em;">3. Договаривающиеся стороны обязуются заменить в течение пятнадцати лет со дня вступления в силу настоящей 
Конвенции на своей территории все сигнальные знаки, обозначения, приспособления и разметки, не соответствующие 
системе, установленной настоящей Конвенцией. В течение этого периода, для того, чтобы пользователи дороги привыкли
 к системе, установленной настоящей Конвенцией, можно будет наряду с сигнальными знаками и обозначениями, 
 предусмотренными настоящей Конвенцией, пользоваться прежними сигнальными знаками и обозначениями.</p><br>

<p style="text-indent:2em;">4. Ни одно положение настоящей Конвенции не может толковаться как возлагающее на Договаривающиеся стороны 
обязательство принять все типы сигнальных знаков, определенные в настоящей Конвенции. Напротив, Договаривающиеся
 стороны ограничат необходимым минимумом число сигнальных знаков и разметки, которые они примут.</p><br>

<p style="font-weight:500;">Статья 4</p>

<p style="text-indent:2em;"></p><h6>Договаривающиеся стороны обязуются принять меры, чтобы было запрещено:</h6><p></p>

<p style="text-indent:2em;">а) помещать на знаке, на его опоре или на любом другом приспособлении, предназначенном для регулирования движения
, что-либо, что не имеет отношения к этому сигнальному знаку или приспособлению; однако, если Договаривающиеся 
стороны или их территориальные подразделения разрешают какой-либо ассоциации, не имеющей целью получение 
материальной выгоды, устанавливать указательные знаки, они могут допустить, чтобы эмблема этой ассоциации 
обозначалась на этом знаке или на его опоре при условии, что это не затруднит его понимания;</p><br>

<p style="text-indent:2em;">b) помещать плакаты, афиши, производить разметку и устанавливать приспособления, которые либо могут быть 
приняты за сигнальные знаки или другие приспособления для регулирования движения, либо могут уменьшать их 
видимость или эффективность, либо ослеплять пользователей дороги или отвлекать их внимание, создавая тем
 самым опасность для дорожного движения.</p>

<p></p><h4>Глава II</h4><p></p>

<p></p><h4>Дорожные знаки и сигналы</h4><p></p>

<p style="font-weight:500;">Статья 5</p>

<p style="text-indent:2em;"></p><h6>1. В системе, предписанной настоящей Конвенцией, различаются следующие 
категории сигнальных знаков:</h6><p></p>

<p style="text-indent:2em;">а) знаки, предупреждающие об опасности: эти знаки имеют целью предупредить пользователей дороги о 
существовании опасности на дорогах и о характере этой опасности;</p>

<p style="text-indent:2em;"></p><h6>b) знаки, означающие обязательное предписание: эти знаки имеют целью уведомить пользователей дороги об 
обязательствах, ограничениях и специальных запрещениях, которые они должны соблюдать; они 
подразделяются на:</h6><p></p>

<p>i) знаки преимущественного права проезда;</p><br>

<p>ii) знаки запрещающие или ограничивающие;</p><br>

<p>iii) знаки предписывающие;</p>

<p style="text-indent:2em;"></p><h6>с) указательные знаки: эти знаки имеют целью давать пользователям дороги по пути их следования необходимую 
информацию или другие полезные указания; они подразделяются на:</h6><p></p>

<p>i) предварительные указатели направлений;</p><br>

<p>ii) указатели направлений;</p><br>

<p>iii) маршрутные марки;</p><br>

<p>iv) указатели наименований;</p><br>

<p>v) подтверждающие знаки;</p><br>

<p>vi) другие знаки, дающие водителям транспортных средств полезные указания;</p><br>

<p>vii) другие знаки, обозначающие объекты, которые могут быть полезны для пользователей дороги.</p>

<p style="text-indent:2em;"></p><h6>2. В тех случаях, когда настоящая Конвенция предоставляет возможность выбора между несколькими знаками 
или обозначениями:</h6><p></p>

<p style="text-indent:2em;">а) Договаривающиеся стороны обязуются применять на всей своей территории лишь один знак или одно 
обозначение;</p><br>

<p style="text-indent:2em;">b) Договаривающиеся стороны должны попытаться достигнуть соглашения на региональном уровне, с тем чтобы 
был выбран тот же знак или то же обозначение;</p><br>

<p style="text-indent:2em;">c) положения п. 3 ст. 3 настоящей Конвенции применимы к сигнальным знакам и обозначениям, которые не 
были выбраны.</p><br>

<p style="font-weight:500;">Статья 6</p><br>

<p style="text-indent:2em;">1. Знаки устанавливаются таким образом, чтобы их легко и своевременно могли распознавать водители, для 
которых они предназначены. Обычно они устанавливаются на стороне дороги, соответствующей направлению движения;
 однако они могут быть помещены или повторены над проезжей частью дороги. Любой знак, установленный на стороне
 дороги, соответствующей направлению движения, должен быть повторен над проезжей частью или на противоположной
 стороне дороги, если местные условия таковы, что этот знак может не быть вовремя замечен водителями транспортных
 средств, для которых он предназначен.</p><br>

<p style="text-indent:2em;">2. Действие каждого знака распространяется на всю ширину проезжей части открытой для движения дороги, для тех 
водителей, для которых он предназначен. Однако действие знака может быть ограничено одной или несколькими полосами 
движения, разграниченными продольной разметкой на проезжей части.</p><br>

<p style="text-indent:2em;">3. Когда, по мнению компетентных органов, тот или иной знак, помещенный на обочине дороги с раздельными проезжими
 частями, является неэффективным, он может устанавливаться на разделительной полосе, и в этом случае его можно не
 повторять на обочине.</p>

<p style="text-indent:2em;"></p><h6>4. Рекомендуется предусмотреть в национальном законодательстве, что:</h6><p></p>

<p style="text-indent:2em;">a) знаки устанавливаются таким образом, чтобы не мешать движению транспортных средств по проезжей части, а знаки,
 установленные на обочинах, должны в возможно меньшей мере мешать движению пешеходов. Расстояние между кромкой 
 уровня дороги с той стороны, где установлен знак, и нижним краем знака должно быть, по возможности, более или менее
 одинаковым для знаков одной и той же категории на одной и той же дороге;</p><br>

<p style="text-indent:2em;">b) размеры знаков должны быть такими, чтобы знак был хорошо виден издали и легко различим при приближении к нему; 
с оговоркой относительно положений подпункта "с" настоящего пункта эти размеры устанавливаются с учетом обычной 
скорости транспортных средств;</p><br>

<p style="text-indent:2em;">c) размеры предупреждающих знаков и знаков, означающих обязательное предписание, должны быть стандартизированы 
на территории каждой Договаривающейся стороны. Как правило, для каждого вида сигнальных знаков устанавливается 
четыре категории размеров: малые, нормальные, большие и очень большие размеры. Знаки малого размера должны 
устанавливаться в тех случаях, когда условия не позволяют применять знаки нормального размера или когда возможно
 движение лишь на малой скорости. Они могут также устанавливаться для повторения предшествующего знака. Знаки большого
 размера должны устанавливаться на очень широких дорогах со скоростным движением. Знаки очень большого размера должны
 устанавливаться на дорогах со сверхскоростным движением, в частности, на автомагистралях.</p><br>

<p style="font-weight:500;">Статья 7</p><br>

<p style="text-indent:2em;">1. Рекомендуется предусмотреть в национальном законодательстве следующее: для того чтобы дорожные знаки, в частности
 предупреждающие знаки и знаки, означающие обязательное предписание, за исключением знаков, регламентирующих остановку
 или стоянку на освещенных улицах населенных пунктов, были лучше видны и могли легче читаться ночью, следует освещать 
 их или пользоваться светоотражающими материалами или приспособлениями, при условии, что это не приводит к ослеплению
 пользователей дороги.</p><br>

<p style="text-indent:2em;">2. Ни одно положение настоящей Конвенции не воспрещает использовать для оповещения или предупреждения, относящегося 
только к некоторым часам или некоторым дням, знаки, которые видны лишь тогда, когда действует содержащаяся 
в них информация.</p><br>

<p style="font-weight:500;">Статья 8</p><br>

<p style="text-indent:2em;">1. Для облегчения понимания знаков в международном плане система дорожных знаков и сигналов, установленная 
настоящей Конвенцией, основана на формах и цветах, характерных для каждой категории знаков, а также во всех
 случаях, когда это представляется возможным, на использовании графических обозначений, а не надписей. Если
 Договаривающиеся стороны считают необходимым внести в предусмотренные обозначения какие-либо изменения, эти
 изменения не должны менять основные характеристики сигнальных знаков.</p><br>

<p style="text-indent:2em;">2. Договаривающиеся стороны, желающие принять в соответствии с положениями подпункта "a" и "ii" п. 1 ст. 3
 настоящей Конвенции сигнальный знак или обозначение, не предусмотренные Конвенцией, должны попытаться достигнуть
 договоренности в реальном плане относительно этого нового сигнального знака или обозначения.</p><br>

<p style="text-indent:2em;">3. Ни одно положение настоящей Конвенции не запрещает добавлять в целях облегчения толкования сигнальных
 знаков надпись на прямоугольной табличке, помещаемой под сигнальным знаком, или внутри прямоугольного щитка,
 содержащего сигнальный знак; такая надпись может помещаться также на самом знаке в тех случаях, когда это не
 затрудняет понимания знака теми водителями, которые не могут понять надпись.</p><br>

<p style="text-indent:2em;">4. В тех случаях, когда компетентные органы считают полезным уточнить значение сигнального знака или
 обозначения, либо ограничить действие знаков, означающих обязательное предписание, некоторыми категориями
 пользователей дороги или некоторыми периодами времени, и когда необходимые указания не могут даваться
 дополнительным знаком или цифрами в условиях, определенных в приложениях к настоящей Конвенции, на
 прямоугольной табличке под знаком помещается надпись, причем это не исключает возможности замены или
 дополнения надписей одним или несколькими графическими обозначениями, помещаемыми на той же табличке.</p><br>

<p style="text-indent:2em;">5. Указанные в пп. 3 и 4 настоящей статьи надписи выполняются на национальном языке или на одном или
 нескольких национальных языках, и, кроме того, если соответствующая Договаривающаяся сторона сочтет
 это необходимым, на других языках, в частности на официальных языках Организации Объединенных Наций.</p><br>

<p style="font-weight:500;">Предупреждающие знаки</p><br>

<p style="font-weight:500;">Статья 9</p><br>

<p style="text-indent:2em;">1. В разделе А приложения 1 к настоящей Конвенции указаны образцы предупреждающих знаков, а в разделе 
В этого приложения указаны обозначения, помещаемые на этих сигнальных знаках, а также приведены
 некоторые предписания, касающиеся использовании этих знаков. Однако знаки и обозначения, предупреждающие
 о приближению к перекрестку, описаны в приложении 2 к настоящей Конвенции, а обозначения, предупреждающие
 о приближении к железнодорожному переезду, описаны в приложении 3. В соответствии с п. 2 ст. 46 настоящей
 Конвенции каждое государство уведомляет Генерального Секретаря о том, какой образец для предупреждающих
 знаков оно избрало: А (с индекс. a) или А (с индекс. b).</p><br>

<p style="text-indent:2em;">2. Предупреждающие знаки не должны повторяться без необходимости, но такие знаки следует устанавливать
 для обозначения возможных дорожных опасностей, которые трудно своевременно обнаружить водителю, 
 соблюдающему необходимую осторожность.</p><br>

<p style="text-indent:2em;">3. Предупреждающие знаки должны устанавливаться на таком расстоянии от опасного участка, которое обеспечивает
 их максимальную эффективность как днем, так и ночью, с учетом условий дороги и движения и, в частности, 
 обычной скорости движения транспортных средств и расстояния, с которого виден знак.</p><br>

<p style="text-indent:2em;">4. Расстояние между сигнальным знаком и началом опасного участка может указываться на дополнительной табличке
 образца 1 приложения 7 к настоящей Конвенции, которая помещается в соответствии с положениями указанного приложения;
 это указание должно даваться тогда, когда расстояние между знаком и началом опасного участка не может быть 
 определено водителями и является иным, чем они могут нормально ожидать.</p><br>

<p style="text-indent:2em;">5. Предупреждающие знаки могут повторяться в частности, на автомагистралях и приравненных к ним дорогах. Если
 предупреждающие знаки повторяются, расстояние между знаком и началом опасного участка должно указываться в 
 соответствии с положениями п. 4 настоящей статьи. Однако в отношении предупреждающих знаков, устанавливаемых
 перед разводными мостами и железнодорожными переездами, Договаривающиеся стороны могут применять вместо 
 положений настоящего пункта положения п. 3 ст. 35 или п. 5 раздела В приложения 1 к настоящей Конвенции.</p><br>

<p style="text-indent:2em;">6. Если предупреждающий знак устанавливается для указания опасности на довольно длинном отрезке дороги
 (например, ряд опасных поворотов, отрезок проезжей части дороги в плохом состоянии) и если считается 
 желательным указать длину этого отрезка, соответствующее указание дается на дополнительной табличке 
 образца 2 приложения 7 к настоящей Конвенции, которая помещается в соответствии с положениями 
 указанного приложения.</p><br>

<p>Знаки, означающие обязательное предписание, за исключением знаков, относящихся к остановке 
или стоянке</p><br>

<p style="font-weight:500;">Статья 10</p><br>

<p>Знаки преимущественного права проезда</p><br>

<p style="text-indent:2em;">1. Сигнальными знаками, имеющими целью доводить до сведения пользователей дороги особые правила 
преимущественного проезда на перекрестках, являются знаки В,1; В,2; В,3 и В,4, описанные в разделе
 А приложения 2 к настоящей Конвенции. Сигнальными знаками, имеющими целью доводить до сведения
 пользователей дороги правило преимущественного проезда на узких участках дорог, являются знаки
 В,5 и В,6, описанные в разделе С приложения 2.</p><br>

<p style="text-indent:2em;">2. Сигнальный знак В,1 "Пересечение с главной дорогой" применяется для указания того, что на перекрестке,
 на котором установлен этот сигнальный знак, водители должны уступить дорогу транспортным средствам,
 движущимся по дороге, к которой они приближаются.</p><br>

<p style="text-indent:2em;">3. Сигнальный знак В,2 "Проезд без остановки запрещен" применятся для указания того, что перед выездом
 на перекресток, на котором установлен этот сигнальный знак, водители должны остановиться и уступить
 дорогу транспортным средствам, движущимся по дороге, к которой они приближаются. В соответствии с п
 . 2 ст. 46 настоящей Конвенции каждое государство уведомляет Генерального Секретаря о том, какой образец
 знака "Проезд без остановки запрещен" оно избрало: В,2 (с индекс. a) или В,2 (с индекс. b).</p><br>

<p style="text-indent:2em;">4. Сигнальный знак В,2 может устанавливаться вне перекрестка тогда, когда компетентные органы считают
 необходимым указать водителям, что они должны останавливаться при подъезде к знаку и продолжать 
 движение лишь после того, как убедятся, что могут делать это, не создавая опасности.</p><br>

<p style="text-indent:2em;">5. Знаки В,1 и В,2 устанавливаются в непосредственной близости от перекрестка, по мере возможности в
 том месте, где транспортные средства должны останавливаться или за которое они не должны выезжать, 
 не уступив предварительно дорогу другим транспортным средствам.</p><br>

<p style="text-indent:2em;">6. Знак А с обозначением А,23 или А,24 может использоваться в качестве знака предварительной сигнализации,
 который устанавливается соответственно перед знаками В,1 или В,2. Однако в государствах, применяющих 
 знаки А (с индекс. a) в качестве предупреждающих знаков, предупреждающими знаками, которые устанавливаются
 перед знаком В,1 или В,2, могут быть те же знаки, к которым добавлена дополнительная табличка образца 1,
 упомянутого в приложении 7 к настоящей Конвенции.</p><br>

<p style="text-indent:2em;">7. Знак В,3 "Главная дорога" применяется для указания пользователям дороги, что на пересечениях этой
 главной дороги с другими дорогами водители транспортных средств, движущихся по этим другим дорогам или
 выезжающих с них, обязаны уступать дорогу транспортным средствам, движущимся по главной дороге. Этот знак
 может устанавливаться в начале дороги и повторяться после каждого пересечения; кроме того, он может
 устанавливаться до пересечения или на нем. Если на дороге установлен сигнальный знак В,3, сигнальный
 знак В,4 "Конец главной дороги" должен устанавливаться вблизи того места, где дорога перестает 
 пользоваться приоритетом по отношению к другим дорогам. Знак В,4 может повторяться один или несколько
 раз до места прекращения приоритета; в таком случае знак или знаки, установленные перед этим местом,
 дополняются табличкой образца 1 приложения 7.</p><br>

<p style="text-indent:2em;">8. Если для указания приближения к перекрестку на дороге устанавливается предупреждающий знак с одним
 из обозначений А 22 или если на перекрестке дорога является главной дорогой, обозначенной в качестве
 таковой сигнальными знаками В,3 в соответствии с положениями п. 7 настоящей статьи, на всех других 
 дорогах должен устанавливаться на перекрестке знак В,1 или знак В,2; однако установка знаков В,1 или
 В,2 не является обязательной на таких дорогах, как проселочные и грунтовые, при движении по которым 
 водители, даже при отсутствии этих знаков, должны уступать дорогу на перекрестке. Знак В,2 должен
 устанавливаться лишь в том случае, если компетентные органы считают необходимым предписать водителям
 остановку транспортного средства, в частности, ввиду того, что водителям этих транспортных средств 
 плохо видны расположенные с той или другой стороны перекрестка участки дороги, к которой они 
 приближаются.</p><br>

<p style="font-weight:500;">Статья 11</p><br>

<p>Запрещающие и ограничивающие знаки</p><br>

<p style="text-indent:2em;">Запрещающие и ограничивающие знаки, за исключением знаков, относящихся к остановке или стоянке, 
описаны с указанием их значения в разделе А приложения 4 к настоящей Конвенции. В этом разделе 
описаны также знаки, означающие конец этих запрещений и ограничений или одного из них.</p><br>

<p style="font-weight:500;">Статья 12</p><br>

<p>Предписывающие знаки</p><br>

<p>1. Предписывающие знаки описаны с указанием их значения в разделе В приложения 4.</p><br>

<p style="font-weight:500;">Статья 13</p><br>

<p>Общие предписания, относящиеся к сигнальным знакам, описанным в приложении 4 к настоящей 
Конвенции</p><br>

<p style="text-indent:2em;">1. Запрещающие, ограничивающие и предписывающие знаки устанавливаются в непосредственной близости
 от места, где вступает в силу предписание, ограничение или запрещение и могут повторяться, если 
 компетентные органы считают это необходимым. Однако в том случае, когда компетентные органы сочтут
 это желательным в связи с плохой видимостью или для того, чтобы заранее предупредить пользователей
 дороги, эти знаки могут устанавливаться на соответствующем расстоянии перед тем местом, где вступает
 в силу предписание, ограничение или запрещение. Под знаками, установленными перед местом, где вступает
 в силу предписание, ограничение или запрещение, помещается дополнительная табличка, соответствующая 
 образцу 1 приложения 7.</p><br>

<p style="text-indent:2em;">2. Знаки, означающие обязательное предписание, установленные рядом с указателем наименования
 населенного пункта или непосредственно после него, означают, что эти предписания применяются
 во всем населенном пункте, за исключением случаев, когда другие знаки, установленные на 
 некоторых участках дорог населенного пункта, содержат иное предписание.</p><br>

<p>Указательные знаки, за исключением знаков, относящихся к стоянке</p><br>

<p style="font-weight:500;">Статья 14</p><br>

<p style="text-indent:2em;">1. В приложении 5 к настоящей Конвенции описаны знаки, дающие полезные указания пользователям 
дороги, за исключением знаков, относящихся к стоянке, или приведены образцы таких знаков; в этом
 приложении содержатся также некоторые предписания относительно их использования.</p><br>

<p style="text-indent:2em;">2. Надписи на указательных знаках "i" - "v", перечисленных в подпункте "с" п. 1 ст. 5, в странах,
 не пользующихся латинским алфавитом, наносятся как на национальном языке, так и латинскими буквами, 
 передающими, по мере возможности, транслитерацию и произношение слов на национальном языке.</p><br>

<p style="text-indent:2em;">3. В странах, не пользующихся латинским алфавитом, слова, написанные латинскими буквами, могут
 помещаться как на знаке, содержащем текст на национальном языке, так и на дополнительном 
 знаке.</p><br>

<p>4. На знаке должны наноситься надписи не более, чем на двух языках.</p><br>

<p style="font-weight:500;">Статья 15</p><br>

<p>Предварительные указатели направлений</p><br>

<p style="text-indent:2em;">Предварительные указатели направлений устанавливаются на таком расстоянии от перекрестка, которое
 обеспечивает их максимальную эффективность как днем, так и ночью, с учетом условий дороги и движения 
 и, в частности, обычной скорости транспортных средств и расстояния, с которого виден этот знак; это
 расстояние может не превышать 50 м (55 ярдов) в населенных пунктах, но на автомагистралях и на дорогах
 со скоростным движением оно должно составлять не менее 500 м (550 ярдов). Эти знаки могут повторяться. 
 На дополнительной табличке, помещенной под знаком, может быть указано расстояние между знаком и перекрестком;
 это расстояние может быть указано также в нижней части самого знака.</p><br>

<p style="font-weight:500;">Статья 16</p><br>

<p>Указатели направлений</p><br>

<p style="text-indent:2em;">1. На одном и том же указателе направлений могут быть обозначены названия нескольких населенных пунктов;
 в этом случае названия указываются на сигнальном знаке одно под другим. Для названия одного населенного 
 пункта можно пользоваться более крупными буквами, чем для названий других населенных пунктов, лишь в том
 случае, если данный населенный пункт является самым крупным.</p><br>

<p style="text-indent:2em;">2. Когда на знаках обозначены расстояния, соответствующие цифры должны помещаться на одном уровне с
 названием населенного пункта. На указателях направлений, имеющих форму стрелки, эти цифры должны помещаться
 между названием населенного пункта и острием стрелки; на знаках прямоугольной формы они должны помещаться 
 после названия населенного пункта.</p><br>

<p style="font-weight:500;">Статья 17</p><br>

<p>Маршрутные марки</p><br>

<p style="text-indent:2em;">Знаки, используемые для обозначения дорог либо при помощи их номера, состоящего из цифр, букв или комбинации
 цифр и букв, либо при помощи названия дорог, могут состоять из этого номера или этого названия, заключенного
 в прямоугольную или щитообразную рамку. Однако Договаривающиеся стороны, имеющие систему классификации дорог, 
 могут заменить эту рамку обозначением классификации.</p><br>

<p style="font-weight:500;">Статья 18</p><br>

<p>Указатели наименований</p><br>

<p style="text-indent:2em;">1. Указатели наименований могут использоваться для указания границы между двумя государствами или границы
 между двумя административными единицами одной и той же страны, либо названия населенного пункта, реки, перевала,
 местности и т.д.</p><br>

<p style="text-indent:2em;">2. Знаки E,9 (с индекс. a) или E,9 (с индекс. b) устанавливаются при въезде в населенный пункт; знаки E,9
 (с индекс. c) или E,9 (с индекс. d) устанавливаются при выезде из населенного пункта. Национальное
 законодательство может предусмотреть, что эти знаки указывают пользователям дороги, что от знаков E,9 
 (с индекс. a) или E,9 (с индекс. b) до знаков E,9 (с индекс. c) или E,9 (с индекс. d) применяются правила
 движения, действующие на территории данной страны в населенных пунктах, за исключением тех случаев, когда
 на некоторых участках дороги в населенном пункте иные правила предписываются другими знаками; однако знак
 B,4 должен всегда устанавливаться на главных дорогах, имеющих сигнальный знак B,3 в тех случаях, когда 
 преимущественное право проезда прекращает свое действие в населенном пункте.</p><br>

<p style="text-indent:2em;">3. Указатели наименований, дающие указания, иные, чем название населенного пункта, должны заметно отличаться,
 в частности, по цвету, от знаков E,9 (с индекс. a) - E,9 (с индекс. d).</p><br>

<p style="font-weight:500;">Статья 19</p><br>

<p>Подтверждающие знаки</p><br>

<p style="text-indent:2em;">Подтверждающие знаки имеют целью подтверждать направление дороги, когда компетентные органы считают это
 необходимым, например, при выезде из крупных населенных пунктов. На них указаны названия одного или
 нескольких населенных пунктов, как это предусмотрено в п. 1 ст. 16 настоящей Конвенции. В случае указания
 расстояний соответствующие цифры помещаются после названия населенного пункта.</p><br>

<p style="font-weight:500;">Статья 20</p><br>

<p>Знаки на пешеходных переходах</p><br>

<p style="text-indent:2em;">Знак E,11 (с индекс. a) или E,11 (в степени b) помещается на пешеходных переходах там, где компетентные 
органы считают это целесообразным.</p><br>

<p style="font-weight:500;">Статья 21</p><br>

<p>Общие предписания, касающиеся различных указательных знаков</p><br>

<p style="text-indent:2em;">1. Упомянутые в ст. 15 - 20 настоящей Конвенции указательные знаки устанавливаются там, где компетентные 
органы считают это целесообразным. Другие указательные знаки устанавливаются с учетом положений п. 1 ст. 
6 лишь там, где компетентные органы считают это необходимым; в частности, знаки F,2 - F,7 устанавливаются
 только на дорогах, на которых пункты ремонта в случае аварии, пункты заправки горючим, ночлега и питания
 расположены на большом расстоянии друг от друга.</p><br>

<p style="text-indent:2em;">2. Указательные знаки могут повторяться. Помещенная под знаком дополнительная табличка может указывать 
расстояние от знака до места, на наличие которого указывает этот знак; это расстояние может обозначаться
 также в нижней части самого знака.</p><br>

<p>Знаки, относящиеся к остановке и стоянке</p><br>

<p style="font-weight:500;">Статья 22</p><br>

<p style="text-indent:2em;">В разделе A приложения 6 к настоящей Конвенции описаны знаки, запрещающие или ограничивающие остановку 
или стоянку, а в разделе B описаны другие знаки, дающие полезные указания относительно стоянки; в этом 
приложении дается значение этих знаков, а также содержатся некоторые предписания относительно их 
использования.</p>

<p></p><h4>Глава III</h4><p></p>

<p style="font-weight:500;">Световые Дорожные сигналы</p><br>

<p style="font-weight:500;">Статья 23</p><br>

<p>Сигналы, предназначенные для регулирования движения транспортных средств</p>

<p style="text-indent:2em;"></p><h6>1. При условии соблюдения положений п. 12 настоящей статьи в качестве световых сигналов, регулирующих
 движение транспортных средств, помимо тех сигналов, которые предназначены исключительно для транспортных 
 средств общего пользования, могут использоваться только указанные ниже огни, имеющие следующее 
 значение:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) немигающие огни:</h6><p></p>

<p style="text-indent:2em;">i) зеленый огонь означает разрешение проезда; однако зеленый огонь, предназначенный для регулирования 
движения на перекрестке, не дает водителям разрешения проезда, если в направлении, в котором они намерены
 двигаться, образуется такой затор движения, при котором в случае выезда на перекресток они не смогут, 
 вероятно, освободить его при перемене фазы сигналов;</p><br>

<p style="text-indent:2em;">ii) красный огонь означает запрещение проезда; транспортные средства не должны пересекать линию 
остановки или, если не имеется линии остановки, проезжать за светофор; если светофор установлен 
посредине или на противоположной стороне перекрестка, они не должны выезжать на перекресток или на 
пешеходный переход на данном перекрестке;</p><br>

<p style="text-indent:2em;">iii) желтый огонь, который включается один или одновременно с красным огнем; когда он применяется один, 
это означает, что ни одно транспортное средство не должно пересекать линию остановки или выезжать за светофор,
 за исключением случаев, когда в момент включения огня оно находиться так близко, что уже не может остановиться
 перед линией остановки или перед светофором с учетом требований безопасности движения. Если сигнал находится 
 посредине перекрестка или за ним, желтый огонь означает, что ни одно транспортное средство не должно выезжать
 на перекресток или на пешеходный переход на данном перекрестке, за исключением случаев, когда в момент включения
 огня оно находится так близко, что уже не может остановиться до въезда на перекресток с учетом требований безопасности
 движения. Желтый огонь при одновременном включении с красным огнем означает незамедлительную смену указаний сигнала,
 но он не изменяет запрещения проезда, предписанного красным огнем;</p>

<p style="text-indent:2em;"></p><h6>b) мигающие огни:</h6><p></p>

<p style="text-indent:2em;">i) один красный мигающий огонь или два попеременно мигающих красных огня, один из которых включается, когда другой
 выключается, помещенные на одной и той же колонке на одинаковой высоте и обращенные в одну и ту же сторону, означают,
 что транспортные средства на должны пересекать линию остановки или, если не имеется линии остановки, проезжать за 
 светофор; эти огни применяются только на железнодорожных переездах и при въезде на разводные мосты или на пристани 
 судов-паромов, а также для обозначения запрещения проезда в связи с выездом на дорогу пожарных автомобилей или 
 приближением летательного аппарата, траектория которого пересекает на небольшой высоте автомобильную дорогу;</p><br>

<p style="text-indent:2em;">ii) желтый мигающий огонь или два попеременно мигающих желтых огня означают, что водители могут продолжать 
движение, но обязаны соблюдать при этом особую осторожность.</p><br>

<p style="text-indent:2em;">2. Световые сигналы трехцветной системы состоят из трех немигающих огней, а именно красного, желтого и зеленого; 
зеленый огонь должен включаться только после выключения красного и желтого огней.</p><br>

<p style="text-indent:2em;">3. Световые сигналы двухцветной системы состоят из красного и зеленого немигающих огней. Красный и зеленый 
огни не должны включаться одновременно. Световые сигналы двухцветной системы применяются лишь в виде временных
 установок с оговоркой относительного срока замены существующих приспособлений, предусмотренного п. 3 ст. 3 
 настоящей Конвенции.</p><br>

<p style="text-indent:2em;">4. Указанные в пп. 2 и 3 настоящей статьи огни трехцветной и двухцветной системы располагаются вертикально 
или горизонтально.</p><br>

<p style="text-indent:2em;">5. В тех случаях, когда огни расположены вертикально, красный огонь помещается вверху; если огни расположены 
горизонтально, красный огонь помещается на стороне, противоположной стороне, соответствующей направлению 
движения.</p><br>

<p>6. При трехцветной системе желтый огонь помещается посредине.</p><br>

<p style="text-indent:2em;">7. В сигналах трехцветной и двухцветной систем, упомянутых пп. 2 и 3 настоящей статьи, все огни должны быть 
круглыми. Упомянутые в п. 1 настоящей статьи красные мигающие огни также должны быть круглыми.</p><br>

<p style="text-indent:2em;">8. Желтый мигающий огонь может устанавливаться отдельно; в часы слабого движения этот огонь может также 
заменять огни трехцветной системы.</p><br>

<p style="text-indent:2em;">9. Когда зеленый огонь трехцветной системы имеет одну или несколько стрелок, включение этой стрелки или 
стрелок означает, что транспортные средства могут проезжать только в направлении или в направлениях, 
которые указаны стрелками. Стрелки, означающие разрешение двигаться прямо, должны быть направлены острием 
вверх.</p><br>

<p style="text-indent:2em;">10. Когда сигнал трехцветной системы дополнен одним или несколькими зелеными огнями, имеющими стрелку или 
стрелки, включение этой дополнительной стрелки или этих дополнительных стрелок означает, - независимо от того,
 какой огонь трехцветной системы в это время включен, - что транспортные средства могут продолжать движение в
 направлении или в направлениях, указанных стрелкой или стрелками; это означает также, что если транспортные
 средства находятся на полосе, которая предназначена исключительно для движения в направлении, указанном 
 стрелкой, или по которой должно осуществляться это движение, их водители должны - при условии, что они 
 уступают дорогу транспортным средствам, двигающимся в том направлении, в котором эти водители намерены 
 следовать, и при условии, что они не будут угрожать безопасности пешеходов, - следовать в указанном 
 направлении в тех случаях, когда остановка их транспортных средств препятствовала бы движению других 
 транспортных средств, находящихся позади них на той же полосе. Эти дополнительные зеленые огни должны 
 помещаться предпочтительно на одном уровне с обычным зеленым огнем.</p><br>

<p style="text-indent:2em;">11. Когда зеленые или красные огни установлены над обозначенными продольными линиями полосами движения 
дороги, имеющей несколько полос движения, красный огонь означает запрещение движения по полосе, над которой 
он установлен, а зеленый огонь означает разрешение движения по ней. Установленный таким образом красный 
огонь должен иметь форму двух перекрещивающихся наклонных полос, а зеленый огонь - форму стрелки, 
направленной вниз.</p><br>

<p style="text-indent:2em;">12. Национальное законодательство может предусмотреть установку на некоторых железнодорожных переездах 
медленно мигающего бело-лунного огня, означающего разрешение проезда.</p><br>

<p style="text-indent:2em;">13. Если световые дорожные сигналы предназначаются только для водителей велосипедов, на самом сигнале, 
если это необходимо во избежание путаницы, может быть изображен силуэт велосипеда или может использоваться 
сигнал малого размера, дополненный прямоугольной табличкой с изображением велосипеда.</p><br>

<p style="font-weight:500;">Статья 24</p><br>

<p>Сигналы, предназначенные только для пешеходов</p>

<p style="text-indent:2em;"></p><h6>1. В качестве световых сигналов, предназначенных только для пешеходов, могут использоваться лишь указанные 
ниже огни, имеющие следующее значение:</h6><p></p>

<p style="text-indent:2em;"></p><h6>a) немигающие огни:</h6><p></p>

<p>i) зеленый огонь означает разрешение движения пешеходов;</p><br>

<p style="text-indent:2em;">ii) желтый огонь означает запрещение движения пешеходов, но позволяет закончить переход тем из них, которые 
уже вышли на проезжую часть;</p><br>

<p>iii) красный огонь означает запрещение пешеходам выходить на проезжую часть;</p>

<p style="text-indent:2em;"></p><h6>b) мигающие огни:</h6><p></p>

<p style="text-indent:2em;">зеленый мигающий огонь означает, что время, в течение которого пешеходы могут переходить через проезжую часть, 
истекает, и что незамедлительно появится красный огонь.</p><br>

<p style="text-indent:2em;">2. Предназначенные для пешеходов световые сигналы должны быть предпочтительно двухцветной системы, состоящей из
 двух огней, а именно красного и зеленого; однако они могут быть трехцветной системы, включающей три огня, а 
 именно красный, желтый и зеленый. Никогда не должны включаться одновременно два огня.</p><br>

<p style="text-indent:2em;">3. Огни должны быть расположены вертикально, причем красный огонь должен быть всегда вверху, а зеленый огонь 
- всегда внизу. Красный огонь должен предпочтительно иметь форму силуэта неподвижного пешехода или неподвижных
 пешеходов, а зеленый огонь - форму силуэта движущегося пешехода или движущихся пешеходов.</p><br>

<p style="text-indent:2em;">4. Конструкция пешеходных светофоров и способ их размещения должны исключать всякую возможность их восприятия
 водителями как световых сигналов, предназначенных для регулирования движения транспортных средств.</p>

<p></p><h4>Глава IV</h4><p></p>

<p style="font-weight:500;">Разметка дорог</p><br>

<p style="font-weight:500;">Статья 25</p><br>

<p style="text-indent:2em;">Обозначения на проезжей части (разметка дорог) применяются в тех случаях, когда компетентные органы считают это
 необходимым с целью регулирования движения, предупреждения или ориентировки пользователей дороги. 
 Эти обозначения могут применяться как самостоятельно, так и в сочетании с другими дорожными сигнальными
 знаками, с тем, чтобы усилить или уточнить их указания.</p><br>

<p style="font-weight:500;">Статья 26</p><br>

<p style="text-indent:2em;">1. Продольная разметка, состоящая из сплошной линии, проведенной по проезжей части, означает, что всем 
транспортным средствам запрещается переезжать через нее или наезжать на нее; равным образом, когда разметка
 разделяет оба направления движения, она означает, что ни одно транспортное средство не должно следовать с 
 той стороны разметки, которая является для водителя противоположной краю проезжей части, соответствующей 
 направлению движения. Продольная разметка, состоящая из двух сплошных линий, имеет аналогичное значение.</p>

<p style="text-indent:2em;"></p><h6>2. a) продольная разметка, состоящая из прерывистой линии, проведенной по проезжей части дороги, не имеет 
значения запрещения, но предназначена:</h6><p></p>

<p>i) либо для разграничения полос движения, а также для разделения направления потоков движения;</p><br>

<p style="text-indent:2em;">ii) либо для предупреждения о приближении к сплошной линии и об устанавливаемом ею запрещении или о 
приближении к другому: участку, представляющему особую опасность;</p><br>

<p style="text-indent:2em;">b) отношение длины расстояния между чертами к длине черты должно быть значительно меньшим в прерывистых 
линиях, которые используются в целях, изложенных в подпункте "a"; "ii" настоящего пункта, чем в линиях, 
которые используются в целях, изложенных в подпункте "a"; "i" того же пункта.</p><br>

<p style="text-indent:2em;">3. В тех случаях, когда продольная разметка состоит из сплошной линии, нанесенной на проезжей части 
дороги рядом с прерывистой линией, водители должны руководствоваться указаниями только той линии, которая 
находится на их стороне. Это положение не препятствует водителям, совершившим разрешенный обгон, вновь 
вернуться на полосу, соответствующую направлению движения.</p><br>

<p style="text-indent:2em;">4. При применении положений настоящей статьи продольной разметкой не являются продольные линии, которые 
обозначают края проезжей части дороги, для того чтобы обеспечить их большую видимость, или которые, 
будучи соединены с поперечными линиями, обозначают на проезжей части дороги места, предназначенные 
для стоянки.</p><br>

<p style="font-weight:500;">Статья 27</p><br>

<p style="text-indent:2em;">1. Поперечная разметка, состоящая из сплошной линии или из двух параллельных сплошных линий, 
проведенных через всю ширину одной или нескольких полос движения, обозначает линию остановки транспортных 
средств, предписанной сигнальным знаком B,2 "Проезд без остановки запрещен", указанным в п. 3 ст. 10 
настоящей Конвенции. Такая разметка может также применяться для указания линии остановки, которая 
может быть предписана световым дорожным сигналом, лицом, уполномоченным регулировать дорожное движение, 
или перед железнодорожным переездом. Перед разметкой, сопровождающей сигнальный знак B,2, на проезжей 
части дороги может быть нанесено слово "Стоп".</p><br>

<p style="text-indent:2em;">2. В тех случаях, когда это технически возможно, указанная в п. 1 настоящей статьи поперечная разметка 
должна наноситься всякий раз, когда устанавливается знак B,2.</p><br>

<p style="text-indent:2em;">3. Поперечная разметка, состоящая из одной прерывистой линии или двух проведенных рядом прерывистых 
линий, нанесенная по всей ширине одной или нескольких полос движения, обозначает линию, которую 
транспортные средства не должны обычно пересекать, когда они обязаны уступать дорогу в соответствии 
со знаком B,1 "Пересечение с главной дорогой", упомянутым в п. 2 ст. 10 настоящей Конвенции. Перед этой 
разметкой для обозначения знака B,1 на проезжей части дороги может быть изображен треугольник с широкой 
каймой, одна из сторон которого параллельна разметке, а противоположная вершина направлена в сторону 
приближающихся транспортных средств.</p><br>

<p style="text-indent:2em;">4. Для обозначения пешеходных переходов предпочтительно наносить довольно широкие полосы, параллельные 
оси проезжей части дороги.</p><br>

<p style="text-indent:2em;">5. Для обозначения переездов для велосипедистов наносятся либо поперечные линии, либо другая разметка, 
которую невозможно принять за разметку, наносимую на пешеходных переходах.</p><br>

<p style="font-weight:500;">Статья 28</p><br>

<p style="text-indent:2em;">1. Другие виды разметки на проезжей части дороги, как то: стрелки, параллельные или косые линии или 
надписи, могут применяться для повторения предписаний сигнальных знаков или для того, чтобы дать 
пользователям дороги указания, которые не могут быть удовлетворительным образом даны сигнальными знаками. 
Такие виды разметки используются, в частности, для обозначения границ зон или полос стоянки, мест остановки 
автобусов или троллейбусов, на которых стоянка запрещена, а также для обозначения предварительного перестроения 
в рядах при подъезде к перекресткам. Однако, если на проезжей части, разделенной продольной разметкой на полосы 
движения, имеется стрелка, водители должны продолжать движение в направлении или в одном из направлений, указанных 
на полосе, по которой они двигаются.</p><br>

<p style="text-indent:2em;">2. При условии соблюдения положений п. 4 ст. 27 настоящей Конвенции, касающихся пешеходных переходов, разметка 
зоны проезжей части дороги или зоны, слегка выступающей над уровнем проезжей части дороги, параллельными косыми 
линиями, обведенными сплошной или прерывистой полосой, означает, что при наличии сплошной полосы транспортные 
средства не должны въезжать в эту зону, а при наличии прерывистых полос транспортные средства могут въезжать в 
эту зону только в том случае, если это совершенно безопасно или если это необходимо для въезда в поперечную лицу 
с противоположной стороны проезжей части.</p><br>

<p style="text-indent:2em;">3. Зигзагообразная линия у края проезжей части дороги означает запрещение стоянки с указанной стороны проезжей 
части по всей длине этой линии.</p><br>

<p style="font-weight:500;">Статья 29</p><br>

<p style="text-indent:2em;">1. Разметка, упомянутая в ст. 26 - 28 настоящей Конвенции, может наноситься на проезжей части дороги краской или 
любым другим способом при условии, что он является столь же эффективным.</p><br>

<p style="text-indent:2em;">2. Если разметка проезжей части дороги наносится краской, она должна быть желтого или белого цвета; однако может 
применяться синий цвет для разметки, обозначающей места разрешенной или ограниченной стоянки. Когда на территории
 Договаривающейся стороны применяются оба цвета - желтый и белый, разметка одинаковой категории должна быть 
 одинакового цвета. При применении положений настоящего пункта термин "белый" включает оттенки "серебристый"
 или "светло-серый".</p><br>

<p style="text-indent:2em;">3. При нанесении надписей, изображений и стрел, которые включены в разметку, следует учитывать необходимость
 значительного увеличения их размеров в направлении движения транспортных средств ввиду того, что эти надписи,
 изображения и стрелы видны водителю под очень небольшим углом.</p><br>

<p style="text-indent:2em;">4. Рекомендуется, чтобы дорожная разметка, предназначенная для движущихся транспортных средств, была 
светоотражающей в тех случаях, когда этого требует интенсивность движения, и при плохой освещенности или
 при полном отсутствии освещения.</p><br>

<p style="font-weight:500;">Статья 30</p><br>

<p style="text-indent:2em;">Приложение 8 к настоящей Конвенции представляет собой совокупность рекомендаций, касающихся схем или 
рисунков разметки дорог.</p>

<p></p><h4>Глава V</h4><p></p>

<p style="font-weight:500;">Прочие дорожные знаки и сигналы</p><br>

<p style="font-weight:500;">Статья 31</p><br>

<p>Сигнализация ремонтных работ</p><br>

<p style="text-indent:2em;">1. Границы участков проезжей части дороги, на которых производятся ремонтные работы, должны ясно 
обозначаться сигнальными знаками.</p>

<p style="text-indent:2em;"></p><h6>2. Когда это вызывается объемом ремонтных работ и интенсивностью движения, для обозначения границ 
участков проезжей части дороги, на которых производятся ремонтные работы, устанавливаются прерывистые 
или сплошные барьеры, окрашенные чередующимися белыми и красными, желтыми и красными, черными и белыми 
или черными и желтыми полосами, и, кроме того, ночью, если барьерам не приданы светоотражающие свойства, 
устанавливаются огни и светоотражающие приспособления. Светоотражающие приспособления и немигающие огни
 должны быть красного или темно-желтого цвета, а мигающие огни - темно-желтого цвета. Однако:</h6><p></p>

<p style="text-indent:2em;">a) огни и приспособления могут быть белого цвета, если они видны только в одном направлении движения и
 обозначают границы участка на стороне, противоположной этому направлению движения;</p><br>

<p style="text-indent:2em;">b) огни и приспособления могут быть белого или светло-желтого цвета если они обозначают границы участка,
 разделяющего оба направления движения.</p><br>

<p style="font-weight:500;">Статья 32</p><br>

<p>Разметка при помощи огней или светоотражающих приспособлений</p><br>

<p style="text-indent:2em;">Каждая Договаривающаяся сторона будет применять на всей своей территории один и тот же цвет или одну и ту
 же систему цветов для огней или светоотражающих приспособлений, применяемых для обозначения края проезжей
 части.</p><br>

<p>Железнодорожные переезды</p><br>

<p style="font-weight:500;">Статья 33</p>

<p style="text-indent:2em;"></p><h6>1. a) если у железнодорожного переезда установлена сигнализация для предупреждения о приближении поездов
 или о предстоящем закрытии шлагбаумов или полушлагбаумов, она должна состоять из мигающего красного огня
 или попеременно мигающих красных огней, как это предусмотрено в подпункте "b" п. 1 ст. 23 настоящей
 Конвенции. Однако:</h6><p></p>

<p style="text-indent:2em;">i) красные мигающие огни могут быть дополнены или заменены световым сигналом трехцветной системы красный
-желтый-зеленый, описанной в п. 2 ст. 23 настоящей Конвенции, или сигналом, в котором отсутствует зеленый
 огонь, если на дороге вблизи от железнодорожного переезда установлены другие трехцветные световые сигналы
 или если на железнодорожном переезде установлены шлагбаумы;</p><br>

<p style="text-indent:2em;">ii) на грунтовых дорогах с весьма слабым движением и на пешеходных дорожках может применяться только
 звуковой сигнал;</p><br>

<p>b) во всех случаях световая сигнализация может дополняться звуковым сигналом.</p><br>

<p style="text-indent:2em;">2. Светофоры устанавливаются на краю проезжей части дороги, соответствующей направлению движения; когда 
это требуется обстоятельствами, например: условиями видимости сигналов или интенсивностью движения, огни 
должны повторяться на противоположной стороне дороги. Однако, если в силу местных условий это считается 
предпочтительным, огни могут быть повторены на островке безопасности посредине проезжей части дороги или 
установлены над проезжей частью дороги.</p><br>

<p style="text-indent:2em;">3. В соответствии с п. 4 ст. 10 настоящей Конвенции сигнальный знак B,2 "Проезд без остановки запрещен" 
может устанавливаться у железнодорожного переезда без шлагбаумов, полушлагбаумов или световой сигнализации,
 предупреждающей о приближении поездов; если на железнодорожном переезде установлен этот знак, водители должны
 остановить свои транспортные средства у линии остановки или при отсутствии линии остановки - у знака и продолжать
 движение, лишь убедившись в том, что к переезду не приближается поезд.</p><br>

<p style="font-weight:500;">Статья 34</p><br>

<p style="text-indent:2em;">1. На железнодорожных переездах со шлагбаумами или полушлагбаумами, расположенными в шахматном порядке с каждой 
стороны железнодорожного пути, горизонтальное положение этих шлагбаумов или полушлагбаумов означает, что каждый 
пользователь дороги обязан остановиться перед ближайшим от него шлагбаумом или полушлагбаумом; движение шлагбаумов
 для занятия горизонтального положения и движение полушлагбаумов имеют то же значение.</p><br>

<p style="text-indent:2em;">2. Включение красного огня или красных огней, упомянутых в подпункте "а" п. 1 ст. 33 настоящей Конвенции, или 
подача звукового сигнала, упомянутого в том же пункте, означает также, что ни один пользователь дороги не имеет 
права пересекать линию остановки или, если не имеется линии остановки, проезжать за светофор. Включение желтого 
огня трехцветной системы, упомянутой в подпункте "a" "i" п. 1 ст. 33, означает, что ни один пользователь дороги 
не имеет права пересекать линию остановки или, если не имеется линии остановки, проезжать за светофор, за 
исключением тех случаев, когда в момент включения желтого огня транспортные средства находятся настолько близко 
к сигналу, что они не могут уже остановиться перед ним, не создавая опасности.</p><br>

<p style="font-weight:500;">Статья 35</p><br>

<p style="text-indent:2em;">1. Шлагбаумы и полушлагбаумы железнодорожных переездов должны быть ясно обозначены чередующимися красными и 
белыми, красными и желтыми, черными и белыми или желтыми и черными полосами. Однако они могут быть окрашены 
только в белый или желтый цвет при условии помещения посредине большого красного диска.</p>

<p style="text-indent:2em;"></p><h6>2. У каждого железнодорожного переезда без шлагбаумов и полушлагбаумов должен быть установлен в непосредственной 
близости от железнодорожного пути сигнальный знак В,7, описанный в приложении 3. При наличии светофора, 
предупреждающего о приближении поездов, или знака В,2 "Проезд без остановки запрещен", сигнальный знак В,7 
помещается на опоре знака В,2 или на колонке светофора. Установка сигнального знака В,7 необязательна:</h6><p></p>

<p style="text-indent:2em;">а) на пересечениях автомобильных дорог с железными дорогами, на которых железнодорожное движение осуществляется 
на очень небольшой cкoрости, а дорожное движение регулируется сопровождающим лицом железнодорожных транспортных
 средств, подающим рукой необходимые сигналы;</p><br>

<p style="text-indent:2em;">b) на пересечениях железнодорожных путей с грунтовыми дорогами весьма незначительным движением и с пешеходными
 дорожками.</p><br>

<p style="text-indent:2em;">3. Под любым предупреждающим знаком с одним из обозначений А,26 или А,27, описанных в приложении 3 к настоящей
 Конвенции, может быть установлен прямоугольный щиток с вертикальной длинной стороной и тремя косыми красными 
 полосами на белом или желтом фоне, но в этом случае примерно на одной трети и двух третях расстояния между 
 знаком и железнодорожным путем устанавливаются дополнительные знаки, состоящие из щитков идентичной формы, на 
 которых нанесены соответственно одна или две красные косые полосы на белом или желтом фоне. Эти знаки могут быть 
 повторены на противоположной стороне проезжей части дороги. Упомянутые в настоящем пункте щитки описаны в разделе 
 С приложения 3 к настоящей Конвенции.</p><br>

<p style="font-weight:500;">Статья 36</p>

<p style="text-indent:2em;"></p><h6>1. В силу особой опасности, возникающей на железнодорожных переездах, Договаривающиеся стороны обязуются 
принять меры к тому, чтобы:</h6><p></p>

<p style="text-indent:2em;"></p><h6>а) перед каждым железнодорожным переездом был установлен один из предупреждающих знаков с одним из 
обозначений А,26 или A,27; однако можно не устанавливать никакого знака:</h6><p></p>

<p>i) в особых случаях, которые могут иметь место в населенных пунктах;</p><br>

<p style="text-indent:2em;">ii) на грунтовых и проселочных дорогах, по которым движение механических транспортных средств осуществляется 
только в очень peдких случаях;</p><br>

<p style="text-indent:2em;">b) все железнодорожные переезды были оборудованы шлагбаумами или полушлагбаумами или системой сигнализации, 
предупреждающей о приближении поездов, за исключением тех случаев, когда железнодорожный путь виден пользователям 
дороги с обеих сторон данного переезда таким образом, что с учетом, в частности, максимальной скорости поездов, водитель 
дорожного транспортного средства, приближающегося к железнодорожному nepеезду с той или другой стороны, может вовремя 
остановиться до въезда на железнодорожный переезд, если покажется поезд, а также таким образом, что пользователи дороги, 
которые уже находятся на переезде в момент появления поезда, могут вовремя с него съехать; однако Договаривающиеся стороны 
могут допускать отступления от положений настоящего пункта на тех железнодорожных переездах, где скорость движения поездов
 относительно невысока или движение дорожных механических транспортных средств незначительно;</p><br>

<p style="text-indent:2em;">с) все железнодорожные переезды с шлагбаумами или полушлагбаумами, приводимыми в действие с поста, с которого шлагбаумы 
или полушлагбаумы не видны, были оборудованы одной из систем сигнализации, предупреждающих о приближении поездов и 
указанных в п. 1 ст. 33 настоящей Конвенции;</p><br>

<p style="text-indent:2em;">d) все железнодорожные переезды с шлагбаумами или полушлагбаумами, автоматически приводимыми в действие приближением 
поездов, были оборудованы одной из систем сигнализации, предупреждающих о приближении поездов и указанных в п. 1 ст. 
33 настоящей Конвенции;</p><br>

<p style="text-indent:2em;">е) шлагбаумы и полушлагбаумы покрывались для улучшения их видимости светоотражающими материалами или оборудовались 
светоотражающими приспособлениями и, в случае необходимости, освещались ночью; кроме того, чтобы на дорогах с интенсивным 
ночным автомобильным движением предупреждающие знаки, устанавливаемые перед железнодорожным переездом, покрывались 
светоотражающими материалами или оборудовались светоотражающими приспособлениями и, в случае необходимости, 
освещались ночью;</p><br>

<p style="text-indent:2em;">f) посредине проезжей части дороги вблизи от железнодорожных переездов, оборудованных полушлагбаумами, наносилась 
по мере возможности продольная разметка, запрещающая транспортным средствам, которые приближаются к железнодорожному 
переезду, выезжать на сторону проезжей части, противоположную направлению движения, или даже сооружались направляющие 
островки, разделяющие оба направления движения.</p><br>

<p style="text-indent:2em;">2. Положения настоящей статьи не применяются в случаях, предусмотренных последней фразой п. 2 ст. 35
 настоящей Конвенции.</p>

<p></p><h4>Глава VI</h4><p></p>

<p style="font-weight:500;">Заключительные положения</p><br>

<p style="font-weight:500;">Статья 37</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция открыта в Центральных Учреждениях Организации Объединенных Наций в Нью-Йорке до 
31 декабря 1969 г. для подписания всеми государствами - членами Организации Объединенных Наций или членами
 любых специализированных учреждений, или членами Международного агентства по атомной энергии, или сторонами
 Статута Международного Суда и любым другим государством, приглашенным Генеральной Ассамблеей Организации 
 Объединенных Наций стать стороной настоящей Конвенции.</p><br>

<p style="text-indent:2em;">2. Настоящая Конвенция подлежит ратификации. Ратификационные грамоты передаются на хранение Генеральному 
Секретарю Организации Объединенных Наций.</p><br>

<p style="text-indent:2em;">СССР ратифицировал настоящую Конвенцию УказомПрезидиума ВС СССР от 29 апреля 1974 г. N 5939-VIII с
 оговоркойи заявлениями</p><br>

<p style="text-indent:2em;">3. Настоящая Конвенция остается открытой для присоединения к ней любого из государств, указанных в п
. 1 настоящей статьи. Документы о присоединении передаются на хранение Генеральному Секретарю.</p><br>

<p style="font-weight:500;">Статья 38</p><br>

<p style="text-indent:2em;">1. Каждое государство может при подписании, ратификации настоящей Конвенции или при присоединении к ней,
 или в любой момент впоследствии заявить посредством нотификации, адресованной Генеральному Секретарю, что
 Конвенция становится применимой ко всем территориям или части территорий, за внешние сношения которых оно 
 ответственно. Конвенция начинает применяться на территории или на территориях, указанных в нотификации, по
 истечении тридцати дней со дня получения Генеральным Секретарем упомянутой нотификации или в момент вступления
 Конвенции в силу в отношении государства, сделавшего нотификацию, если эта дата является более поздней.</p><br>

<p style="text-indent:2em;">2. Каждое государство, делающее нотификацию, упомянутую в п. 1 настоящей статьи, должно от имени территорий,
 по поручению которых им была сделана такая нотификация, направлять нотификацию, содержащую заявления
 , предусмотренные в п. 2 ст. 46 настоящей Конвенции.</p><br>

<p style="text-indent:2em;">3. Каждое государство, сделавшее заявление в соответствии с п. 1 настоящей статьи, может в любой момент 
впоследствии заявить посредством нотификации, адресованной Генеральному Секретарю, что Конвенция перестанет 
применяться на территории, указанной в нотификации, по истечении одного года со дня получения Генеральным 
Секретарем этой нотификации.</p><br>

<p style="font-weight:500;">Статья 39</p><br>

<p style="text-indent:2em;">1. Настоящая Конвенция вступает в силу по истечении двенадцати месяцев со дня сдачи на хранение пятнадцатого
 документа о ратификации или о присоединении.</p><br>

<p style="text-indent:2em;">2. В отношении каждого государства, которое ратифицирует настоящую Конвенцию или присоединится к ней после 
сдачи на хранение пятнадцатого документа о ратификации или присоединении, Конвенция вступает в силу по истечении
 двенадцати месяцев со дня сдачи этим государством на хранение ратификационной грамоты или документа о 
 присоединении.</p><br>

<p style="font-weight:500;">Статья 40</p><br>

<p style="text-indent:2em;">С момента вступления настоящей Конвенции в силу ею отменяются и заменяются в отношениях между Договаривающимися
 сторонами Конвенция по введению единообразия в дорожные знаки и сигналы, открытая для подписания в Женеве 30
 марта 1931 г., и Протокол о дорожных знаках и сигналах, открытый для подписания в Женеве 19 сентября 1949 г.</p>

<p style="text-indent:2em;"></p><h6>Точное название упомянутого документа:</h6><p></p>

<p>Конвенцияоб унификации дорожной сигнализации (Женева, 30 марта 1931 г.)</p><br>

<p style="font-weight:500;">Статья 41</p><br>

<p style="text-indent:2em;">1. Через один год после вступления в силу настоящей Конвенции каждая Договаривающаяся сторона может предложить 
одну или несколько поправок к Конвенции. Текст любой предложенной поправки вместе с пояснительным меморандумом 
направляется Генеральному Секретарю, который препровождает его всем Договаривающимся сторонам. Договаривающиеся
 стороны имеют возможность уведомить его в двенадцатимесячный срок, начиная со дня препровождения этого текста,
 о том: а) принимают ли они поправку; b) отклоняют ли они поправку, или с) желают ли они, чтобы для рассмотрения
 этой поправки была созвана конференция. Генеральный Секретарь направляет также текст предложенной поправки всем
 другим государствам, указанным в п. 1 ст. 37 настоящей Конвенции.</p><br>

<p style="text-indent:2em;">2. а) Любая предложенная поправка, разосланная в соответствии с предыдущим пунктом, считается принятой, если в 
течение упомянутого выше двенадцатимесячного срока менее трети Договаривающихся сторон информируют Генерального
 Секретаря о том, что они отклоняют поправку или желают созыва конференции для рассмотрения этой поправки. 
 Генеральный Секретарь уведомляет все Договаривающиеся стороны о каждом принятии или отклонении предложенной 
 поправки и о любом поступлении просьбы о созыве конференции. Если в течение установленного двенадцатимесячного
 сpoкa такие отклонения и просьбы будут получены менее, чем от трети всех Договаривающихся сторон, Генеральный 
 Секретарь информирует все Договаривающиеся стороны о том, что эта поправка вступает в силу через шесть месяцев
 по истечении двенадцатимесячного срока, указанного в предыдущем пункте, для всех Договаривающихся сторон, за 
 исключением тех, которые в течение установленного срока отклонили поправку или обратились с просьбой созвать 
 конференцию для ее обсуждения;</p><br>

<p style="text-indent:2em;">b) каждая Договаривающаяся сторона, которая в течение вышеуказанного двенадцатимесячного срока отклонила предложенную
 поправку или попросила созвать конференцию для ее рассмотрения, может в любое время после истечения этого срока
 уведомить Генерального Секретаря о принятии ею поправки, и Генеральный Секретарь рассылает это уведомление всем
 остальным Договаривающимся сторонам. Поправка вступает в силу в отношении Договаривающихся сторон, направивших 
 такие уведомления о принятии, через шесть месяцев после получения их Генеральным Секретарем.</p><br>

<p style="text-indent:2em;">3. Если предложенная поправка не была принята в соответствии с п. 2 настоящей статьи и если в течение двенадцатимесячного
 срока, предусмотренного в п. 1 настоящей статьи, менее половины общего числа Договаривающихся сторон информируют
 Генерального Секретаря о том, что они отклоняют предложенную поправку, и если по крайней мере треть всех Договаривающихся
 сторон, но не менее десяти, информируют его о том, что они принимают ее или желают созыва конференции для обсуждения
 этой поправки, Генеральный Секретарь созывает конференцию для рассмотрения предложенной поправки или любого предложения,
 которое может быть ему представлено в соответствии с п. 4 настоящей статьи.</p><br>

<p style="text-indent:2em;">4. Если конференция созывается в соответствии с п. 3 настоящей статьи, Генеральный Секретарь приглашает на нее все 
государства, указанные в п. 1 ст. 37 настоящей Конвенции. Генеральный Секретарь обращается ко всем приглашенным на
 конференцию государствам с просьбой представить ему по крайней мере за шесть месяцев до открытия конференции любые 
 предложения, которые они в дополнение к предложенной поправке могут пожелать рассмотреть на конференции, и уведомляет
 об этих предложениях по крайней мере за три месяца до открытия конференции все приглашенные на конференцию 
 государства.</p><br>

<p style="text-indent:2em;">5. а) любая поправка к настоящей Конференции считается принятой, если она принимается большинством в две трети 
государств, представленных на Конференции, при условии, что это большинство включает по крайней мере две трети 
всех Договаривающихся сторон, представленных на Конференции. Генеральный Секретарь извещает все Договаривающиеся 
стороны о принятии поправки, и она вступает в силу через двенадцать месяцев со дня такого уведомления в отношении
 всех Договаривающихся сторон, за исключением тех, которые в течение этого срока сообщили Генеральному Секретарю
 об отклонении поправки;</p><br>

<p style="text-indent:2em;">b) каждая Договаривающаяся сторона, отклонившая в течение вышеуказанного двенадцатимесячного срока поправку, 
может в любое время уведомить Генерального Секретаря о принятии ею поправки, и Генеральный Секретарь извещает 
об этом все остальные Договаривающиеся стороны. Поправка вступает в силу в отношении Договаривающейся стороны,
 уведомившей о ее принятии, через шесть месяцев после получения уведомления Генеральным Секретарем или по 
 истечении вышеуказанного двенадцатимесячного срока, если этот срок истекает позднее.</p><br>

<p style="text-indent:2em;">6. Если предложенная поправка не считается принятой в соответствии с п. 2 настоящей статьи и если не выполнены
 условия созыва Конференции, предписанные в п. 3 настоящей статьи, предложенная поправка считается отклоненной.</p><br>

<p style="font-weight:500;">Статья 42</p><br>

<p style="text-indent:2em;">Каждая Договаривающаяся сторона может денонсировать настоящую Конвенцию посредством письменной нотификации, 
адресованной Генеральному Секретарю. Денонсация вступает в силу по истечении одного года со дня получения
 Генеральным Секретарем этой нотификации.</p><br>

<p style="font-weight:500;">Статья 43</p><br>

<p style="text-indent:2em;">Настоящая Конвенция теряет силу, если число Договаривающихся сторон составляет в течение какого-либо периода 
последовательных двенадцати месяцев менее пяти.</p><br>

<p style="font-weight:500;">Статья 44</p><br>

<p style="text-indent:2em;">Всякий спор между двумя или более Договаривающимися сторонами относительно толкования или применения настоящей
 Конвенции, который Стороны не смогут разрешить путем переговоров или другими средствами урегулирования, может 
 быть по просьбе любой из заинтересованных Договаривающихся сторон передан для разрешения Международному Суду.</p><br>

<p style="font-weight:500;">Статья 45</p><br>

<p style="text-indent:2em;">Никакое положение настоящей Конвенции не должно толковаться как препятствующее какой-либо из Договаривающихся
 сторон принимать совместимые с Уставом Организации Объединенных Наций и ограничиваемые создавшимся положением
 меры, которые она считает необходимыми для обеспечения своей внешней или внутренней безопасности.</p><br>

<p style="font-weight:500;">Статья 46</p><br>

<p style="text-indent:2em;">1. Каждое государство может при подписании настоящей Конвенции или при сдаче на хранение своей ратификационной 
грамоты или документа о присоединении заявить, что оно не считает себя связанным ст. 44 настоящей Конвенции. 
Другие Договаривающиеся стороны не являются связанными ст. 44 в отношении любой Договаривающейся стороны, 
сделавшей такое заявление.</p>

<p style="text-indent:2em;"></p><h6>2. а) в момент сдачи на хранение своей ратификационной грамоты или документа о присоединении каждое государство
 заявляет посредством нотификации, адресованной Генеральному Секретарю о том:</h6><p></p>

<p style="text-indent:2em;">i) какой из образцов - А (с индекс. a) или А (с индекс. b) - оно выбирает в качества предупреждающего знака 
(п. 1 ст. 9);</p><br>

<p style="text-indent:2em;">ii) какой из образцов - В,2 или В,2 (с индекс. b) - оно выбирает в качестве знака "Проезд без остановки запрещен"
 (п. 3 ст. 10) в целях применения настоящей Конвенции.</p><br>

<p style="text-indent:2em;">В любое время впоследствии каждое государство может посредством нотификации, адресованной Генеральному Секретарю,
 изменить свой выбор, заменив свое заявление другим;</p><br>

<p style="text-indent:2em;">b) в момент сдачи на хранение своей ратификационной грамоты или документа о присоединении каждое государство
 может заявить посредством нотификации, адресованной Генеральному Секретарю, что в целях применения настоящей
 Конвенции она приравнивает велосипеды с подвесным двигателем к мотоциклам (п. "l" ст. 1).</p><br>

<p style="text-indent:2em;">В любое время впоследствии каждое государство может посредством нотификации, адресованной Генеральному Секретарю,
 взять обратно свое заявление.</p><br>

<p style="text-indent:2em;">3. Предусмотренные в п. 2 настоящей статьи заявления вступают в силу через шесть месяцев после получения Генеральным
 Секретарем нотификации или в момент вступления в силу Конвенции в отношении государства, сделавшего заявление, если
 эта дата является более поздней.</p><br>

<p style="text-indent:2em;">4. Оговорки в настоящей Конвенции и к приложениям к ней, иные, чем оговорка, предусмотренная в п. 1 настоящей статьи,
 разрешаются при условии представления их в письменной форме и, если они сделаны до сдачи на хранение ратификационной
 грамоты или документа о присоединении, при условии подтверждения их в ратификационной грамоте или документе о присоединении
 . Генеральный Секретарь сообщает об указанных оговорках всем государствам, указанным в п. 1 ст. 37 настоящей Конвенции.</p><br>

<p style="text-indent:2em;">5. Каждая Договаривающаяся сторона, которая делает оговорку или заявление в соответствии с пп. 1 и 4 настоящей статьи,
 может в любой момент взять свою оговорку обратно путем нотификации, адресованной Генеральному Секретарю.</p>

<p style="text-indent:2em;"></p><h6>6. Любая оговорка, сделанная в соответствии с п. 4 настоящей статьи:</h6><p></p>

<p style="text-indent:2em;">а) изменяет для Договаривающейся стороны, сделавшей вышеуказанную оговорку, в рамках этой оговорки положения
 Конвенции, к которым она относится;</p><br>

<p style="text-indent:2em;">b) изменяет в такой же мере эти положения и для других Договаривающихся сторон в их взаимоотношениях с
 Договаривающейся стороной, сделавшей оговорку.</p><br>

<p style="font-weight:500;">Статья 47</p>

<p style="text-indent:2em;"></p><h6>Помимо заявлений, нотификаций и уведомлений, предусмотренных в ст. 41 и 46 настоящей конвенции, Генеральный 
Секретарь сообщает всем государствам, указанным в п. 1 ст. 37:</h6><p></p>

<p>а) о подписаниях, ратификациях к присоединениях к Конвенции в соответствии со ст. 37;</p><br>

<p>b) о заявлениях в соответствии со ст. 38;</p><br>

<p>с) о датах вступления в силу настоящей Конвенции в соответствии со ст. 39;</p><br>

<p>d) о дате вступления в силу поправок к настоящей Конвенции в соответствие с пп. 2 и 5 ст. 41;</p><br>

<p>е) о денонсациях в соответствии со ст. 42;</p><br>

<p>f) об утрате настоящей Конвенцией силы в соответствии со ст. 43.</p><br>

<p style="font-weight:500;">Статья 48</p><br>

<p style="text-indent:2em;">Подлинник настоящей Конвенции, составленный в одном экземпляре на английском, испанском, китайском, русском и 
французском языках, причем все пять текстов являются равно аутентичными, сдается на хранение Генеральному Секретарю
 Организации Объединенных Наций, который препровождает надлежащим образом заверенные копии всем государствам,
 указанным в п. 1 ст. 37 настоящей Конвенции.</p><br>

<p style="text-indent:2em;">В удостоверение чего нижеподписавшиеся представители, надлежащим образом на то уполномоченные своими 
правительствами, подписали настоящую Конвенцию.</p><br>

<p>Совершено в Вене месяца ноября дня восьмого тысяча девятьсот шестьдесят восьмого года.</p><br>

<p>/подписи/</p>

<p style="text-indent:2em;"></p><h6>Согласно ст. 2настоящей Конвенции нижеследующие приложения являются ее неотъемлемой частью:</h6><p></p>

<p></p><h4>Приложение 1
к Конвенциио дорожных знаках и сигналах</h4><p></p>

<p>(Вена, 8 ноября 1968 г.)</p><br>

<p style="text-indent:2em;">Предупреждающие знаки, за исключением знаков, устанавливаемых вблизи от перекрестков или железнодорожных
 переездов</p><br>

<p style="text-indent:2em;">Примечание. В отношении предупреждающих знаков, устанавливаемых вблизи от перекрестков, см. раздел В приложения 2
. В отношении предупреждающих знаков, устанавливаемых вблизи от железнодорожных переездов, см. разделы А и С 
приложения 3.</p>

<p></p><h4>Раздел А</h4><p></p>

<p style="font-weight:500;">Образцы предупреждающих знаков</p><br>

<p style="text-indent:2em;">Образцами предупреждающих знаков являются образцы А (с индекс. a) или А (с индекс. b). Образец А (с индекс. a)
 представляет собой равносторонний треугольник, одна сторона которого расположена горизонтально, а противоположная
 ей вершина обращена вверх; фон должен быть белого или желтого цвета, кайма - красного цвета. Образец А (с индекс.
 b) представляет собой квадрат, одна из диагоналей которого проходит вертикально; фон должен быть желтого цвета,
 кайма, представляющая собой узкую полоску, - черного цвета. Обозначения, имеющиеся на этих знаках, должны быть 
 - при отсутствии иных указаний, содержащихся в их описании, - черного или темно-синего цвета.</p><br>

<p style="text-indent:2em;">Для знаков А (с индекс. a) нормального размера длина стороны составляет примерно 0,9 м (3 фута); для знаков А (
с индекс. a) малого размера - не менее 0,6 м (2 фута). Для знаков А (с индекс. b) нормального размера длина 
стороны составляет примерно 0,6 м (2 фута), для знаков А малого размера - не менее 0,4 м (1 фут 4 дюйма).</p><br>

<p>В отношении выбора между образцами А (с индекс. a) и А (с индекс. b) см. п. 2 ст. 5 и п. 1 ст. 9 Конвенции.</p>

<p></p><h4>Раздел В</h4><p></p>

<p style="font-weight:500;">Обозначения предупреждающих знаков и предписания относительно использования этих знаков</p><br>

<p style="font-weight:500;">1. Опасный поворот или опасные повороты.</p>

<p style="text-indent:2em;"></p><h6>Для предупреждения о приближении к опасному повороту или к следующим один за другим опасным поворотам используется
 в зависимости от обстоятельств один из следующих знаков:</h6><p></p>

<p>A,1 (с индекс. a) поворот налево;</p><br>

<p>A,1 (с индекс. b) поворот направо;</p><br>

<p style="text-indent:2em;">A,1 (с индекс. c) двойной поворот или более двух поворотов, следующих один за другим, с первым поворотом налево;</p><br>

<p style="text-indent:2em;">A,1 (с индекс. d) двойной поворот или более двух поворотов, следующих один за другим, с первым поворотом направо.</p><br>

<p style="font-weight:500;">2. Крутой спуск.</p><br>

<p style="text-indent:2em;">Для предупреждения о приближении к спуску с большим уклоном используется обозначение А,2 (с индекс. a) образцом 
знака А (с индекс. a) и обозначение A,2 (с индекс. b) образцом знака А (с индекс. b).</p><br>

<p style="text-indent:2em;">Левая сторона обозначения А,2 (с индекс. a) занимает левый угол щитка сигнального знака, а его основание - вся 
ширину этого щитка. На обозначениях А,2 (с индекс. a) и А,2 (с индекс. b) цифра указывает уклон в процентах; это
 указание может заменяться отношением (1:10).</p><br>

<p style="text-indent:2em;">Однако Договаривающиеся Стороны могут вместо обозначения A,2 (с индекс. a) или А,2 (с индекс. b), но учитывая по
 мере возможности положения подпункта "b" п. 2 ст. 5 Конвенции, выбрать обозначение A,2 (с индекс. c), если они 
 приняли образец знака А (с индекс. a), и обозначение А,2 (с индекс. d), если они приняли образец знака А 
 (с индекс. b).</p><br>

<p style="font-weight:500;">3. Крутой подъем.</p><br>

<p style="text-indent:2em;">Для предупреждения о приближении к крутому подъему используется обозначение А,3 (с индекс. a) с образцом знака
 А (с индекс. a) и обозначение А,3 с образцом знака А (с индекс. b).</p><br>

<p style="text-indent:2em;">Правая сторона обозначения А,3 (с индекс. a) занимает правый угол щитка сигнального знака, а его основание - 
всю ширину этого щитка. На обозначениях А,3 (с индекс. a) и А,3 (с индекс. b) цифра указывает подъем в процентах;
 это указание может заменяться отношением (1:10).</p><br>

<p style="text-indent:2em;">Однако Договаривающиеся Стороны, которые выберут для крутого спуска обозначение А,2 (с индекс. c) , могут 
вместо обозначения А,3 (с индекс. a) выбрать обозначение А,3 (с индекс. c), а Договаривающиеся стороны, которые
 выберут обозначение А,2 (с индекс. d), могут вместо обозначения А,3 (с индекс. b) выбрать обозначение А,3 
 (с индекс. d).</p><br>

<p style="font-weight:500;">4. Сужение дороги.</p><br>

<p style="text-indent:2em;">Для предупреждения о приближении к сужению дороги используется обозначение А,4 (с индекс. a) или обозначение, 
указывающее более ясно конфигурацию данного места, как например, А,4 (с индекс. b).</p><br>

<p style="font-weight:500;">5. Разводной мост.</p><br>

<p>Для предупреждения о приближении к разводному мосту используется обозначение А,5.</p><br>

<p style="text-indent:2em;">Под предупреждающим знаком с обозначением А,5 может помещаться прямоугольная табличка образца А,29 (с индекс. 
a), описанного в разделе С приложения 3, но в этом случае приблизительно на одной трети и двух третях расстояния
 между знаком с обозначением А,5 и разводным мостом помещаются, таблички образцов А,29 (с индекс. b) и A29 
 (с индекс. c), описанных в указанном приложении.</p><br>

<p style="font-weight:500;">6. Выезд на набережную или на берег.</p><br>

<p>Для предупреждения о том, что дорога выходит на набережную или на берег, используется обозначение А,6.</p><br>

<p style="font-weight:500;">7. Неровная дорога.</p><br>

<p style="text-indent:2em;">Для предупреждения о приближении к выбоинам, горбатым мостам или буграм или местам, где проезжая часть 
дороги находится в плохом состоянии, используется обозначение A,7 (с индекс. a).</p><br>

<p style="text-indent:2em;">Для обозначения горбатых мостов или бугров обозначение А,7 (с индекс. a) может быть заменено обозначением 
А,7 (с индекс. b). Для обозначения выбоин обозначение A,7 (с индекс. a) может быть заменено обозначением A
,7 (с индекс. c).</p><br>

<p style="font-weight:500;">8. Скользкая дорога.</p><br>

<p style="text-indent:2em;">Для предупреждения о приближении к участку дороги, на котором скользкость может быть особенно высокой, 
используется обозначение A,8.</p><br>

<p style="font-weight:500;">9. Выброс гравия.</p><br>

<p style="text-indent:2em;">Для предупреждения о приближении к участку дороги, на котором может произойти выброс гравия, используется
 обозначение A,9 (с индекс. a) с образцом знака А (с индекс. a) и обозначение А,9 (с индекс. b) с образцом
 знака А (с индекс. b).</p><br>

<p>При левостороннем движении обозначение обращено в противоположную сторону.</p><br>

<p style="font-weight:500;">10. Падение камней.</p><br>

<p style="text-indent:2em;">Для предупреждения о приближении к участку дороги, на котором существует опасность падения камней и вызванная
 этим опасность наличия камней на дороге, используется обозначение А,10 (с индекс. a) с образцом знака А (с 
 индекс. a) и обозначение A,10 (с индекс. b) с образцом знака А (с индекс. b).</p><br>

<p>В обоих случаях правая сторона обозначения занимает правый угол щитка сигнального знака.</p><br>

<p>Обозначение может быть обращено в противоположную сторону.</p><br>

<p style="font-weight:500;">11. Пешеходный переход.</p><br>

<p style="text-indent:2em;">Для предупреждения о приближении к пешеходному переходу, обозначенному дорожной разметкой или знаком E,11
 (с индекс. a) или Е,11 используется обозначение А,11 имеющее два образца: A,11 (с индекс. a) и A,11 (с 
 индекс. b).</p><br>

<p>Обозначение может быть обращено в противоположную сторону.</p><br>

<p style="font-weight:500;">12. Дети.</p><br>

<p style="text-indent:2em;">Для предупреждения о приближении к участку дороги, часто пересекаемому детьми, как, например, выход из школы
 или с площадки для игр, используется обозначение А,12. Обозначение может быть обращено в противоположную
 сторону.</p><br>

<p style="font-weight:500;">13. Выезд велосипедистов.</p><br>

<p style="text-indent:2em;">Для предупреждения о приближении к месту, в котором велосипедисты часто выезжают на дорогу или ее пересекают
, используется обозначение А,13. Обозначение может быть обращено в противоположную сторону.</p><br>

<p style="font-weight:500;">14. Место прогона скота и появления других животных.</p>

<p style="text-indent:2em;"></p><h6>Для предупреждения о приближении к участку дороги, на котором существует опасность пересечения дороги животными
, используется обозначение, состоящее из силуэта домашнего или дикого животного чаще всего встречающегося вида,
 как, например:</h6><p></p>

<p>для домашнего животного обозначение A,14 (с индекс. a);</p><br>

<p>для дикого животного - А,14 (с индекс. b).</p><br>

<p>Обозначение может быть обращено в противоположную сторону.</p><br>

<p style="font-weight:500;">15. Ремонтные работы.</p><br>

<p style="text-indent:2em;">Для предупреждения о приближении к участку дороги, на котором производятся дорожные работы, используется
 обозначение А,15.</p><br>

<p style="font-weight:500;">16. Световая сигнализация.</p><br>

<p style="text-indent:2em;">Если считается необходимым предупредить о приближении к месту, на котором движение регулируется трехцветными
 огнями, в тех случаях, когда пользователи дороги не могут предвидеть, что они приближаются к такому месту,
 используется обозначение А,16. Имеются три образца обозначения А,16: A,16 (с индекс. a), А,16 (с индекс. b
 ) и A,16 (с индекс. c), которые соответствуют расположению огней в трехцветной системе, описанной в пп. 4
 - 6 ст. 23 настоящей Конвенции. Это обозначение включает три цвета огней, а именно цвета огней, о приближении 
 к которым оно предупреждает.</p><br>

<p style="font-weight:500;">17. Взлетно-посадочная полоса</p><br>

<p style="text-indent:2em;">Для предупреждения о приближении к участку дороги, над которым могут пролетать на небольшой высоте летательные 
аппараты, совершающие взлет или посадку на взлетно-посадочную полосу, используется обозначение А,17. Обозначение
 может быть обращено в противоположную сторону.</p><br>

<p style="font-weight:500;">18. Боковой ветер.</p><br>

<p style="text-indent:2em;">Для предупреждения о приближении к участку дороги, на котором часто дует сильный боковой ветер, используется
 обозначение А,18. Обозначение может быть обращено в противоположную сторону.</p><br>

<p style="font-weight:500;">19. Двустороннее движение.</p><br>

<p style="text-indent:2em;">Для предупреждения о приближении к участку дороги, на котором производится временно или постоянно двустороннее
 движение на одной проезжей части, в то время как на предыдущем участке оно производилось по дороге с односторонним
 движением или по дороге с несколькими проезжими частями, предназначенными для движения в одном направлении, 
 используется обозначение А,19.</p><br>

<p style="text-indent:2em;">Знак с этим обозначением должен повторяться при въезде на данный участок и, кроме того, на данном участке так часто,
 как это представляется необходимым. При левостороннем движении стрелки должны быть обращены в противоположную 
 сторону.</p><br>

<p style="font-weight:500;">20. Прочие опасности.</p><br>

<p style="text-indent:2em;">Для предупреждения о приближении к участку дороги, на котором имеется опасность, не предусмотренная обозначениями,
 указанными выше в пп. 1 - 19 или в приложениях 2 и 3, может использоваться обозначение А,20.</p><br>

<p style="text-indent:2em;">Однако Договаривающиеся Стороны могут принять графические обозначения в соответствии с положениями подпункта "a"
 "ii" п. 1 ст. 3 Конвенции.</p><br>

<p style="text-indent:2em;">Знак А,20 может применяться, в частности, для предупреждения о пересечении железнодорожного пути, на котором 
железнодорожное движение осуществляется на очень небольшой скорости, а дорожное движение регулируется сопровождающим
 лицом железнодорожных транспортных средств, подающим рукой необходимые сигналы.</p>

<p></p><h4>Приложение 2
к Конвенциио дорожных знаках и сигналах</h4><p></p>

<p>(Вена, 8 ноября 1968 г.)</p><br>

<p style="text-indent:2em;">Знаки, регулирующие преимущественное право проезда на перекрестках, предупреждающие знаки, устанавливаемые вблизи
 от перекрестков, и знаки, регулирующие преимущественное право проезда на узких участках дорог</p><br>

<p style="text-indent:2em;">Примечание. Если на перекрестке, включающем главную дорогу, направление этой последней изменяется, под знаками,
 предупреждающими о приближении к перекрестку, или под знаками, регулирующими преимущественное право проезда, установленными
 на перекрестке или перед ним, может помещаться табличка, показывающая на схеме перекрестка направление главной 
 дороги.</p>

<p></p><h4>Раздел А</h4><p></p>

<p style="font-weight:500;">Знаки, регулирующие преимущественное право проезда на перекрестках</p><br>

<p style="font-weight:500;">1. Знак "Пересечение с главной дорогой".</p><br>

<p style="text-indent:2em;">Знаком "Пересечение с главной дорогой" является знак В,1. Он имеет форму равностороннего треугольника, одна сторона
 которого расположена горизонтально, а противоположная ей вершина обращена вниз; фон должен быть белого и желтого цвета
 , а кайма красного цвета; обозначений на знаке не имеется.</p><br>

<p style="text-indent:2em;">Для знаков нормального размера длина стороны треугольника составляет примерно 0,9 м (3 фута), для знаков малого размера
 - не менее 0,6 м (2 футов).</p><br>

<p style="font-weight:500;">2. Знак "Проезд без остановки запрещен".</p>

<p style="text-indent:2em;"></p><h6>Знаком "Проезд без остановки запрещен" является знак В,2, включающий два образца:</h6><p></p>

<p style="text-indent:2em;">образец B,2 (с индекс. a) должен быть восьмиугольным с красным фоном, на котором белыми буквами на английском языке или
 на языке соответствующего государства написано слово "Стоп"; высота букв слова должна быть равна по крайней мере трети
 высоты таблички;</p><br>

<p style="text-indent:2em;">образец B,2 (с индекс. b) должен быть круглым с белым и желтым фоном и красной каймой; на нем помещен внутри знак В,1,
 без надписи и, кроме того, в верхней части большими черными или синими буквами на английском языке или на языке
 соответствующего государства написано слово "Стоп".</p><br>

<p style="text-indent:2em;">Высота знака В,2 (с индекс. a) нормальных размеров и диаметр знака В,2 нормальных размеров должны составлять примерно
 0,9 м (3 фута); соответственно высота и диаметр знаков малых размеров должны быть не менее 0,6 м (2 футов).</p><br>

<p>В отношении выбора между образцами B,2 (с индекс. a) и B,2 (с индекс. b) см. п. 2 ст. 5 и п. 3 ст. 10 Конвенции.</p><br>

<p style="font-weight:500;">3. Знак "Главная дорога".</p><br>

<p style="text-indent:2em;">Знаком "Главная дорога" является знак В,3. Он имеет форму квадрата, одна из диагоналей которого расположена вертикально;
 квадрат окружен черным ободком; посредине помещен желтый или оранжевый квадрат с черным ободком; пространство между 
 обоими квадратами должно быть белого цвета.</p><br>

<p style="text-indent:2em;">Для знаков нормального размера длина стороны квадрата составляет примерно 0,5 м (1 фут 8 дюймов), для знаков малого размера
 - не менее 0,35 м (1 фут 2 дюйма).</p><br>

<p style="font-weight:500;">4. Знак "Конец главной дороги".</p><br>

<p style="text-indent:2em;">Знаком "Конец главной дороги" является знак В,4. Этот знак состоит из изображенного выше знака В,3, к которому добавлена 
черная или серая центральная полоса, перпендикулярная нижней левой стороне и верхней правой стороне квадрата, или ряд 
черных или серых параллельных штрихов, образующих вышеуказанную полосу.</p>

<p></p><h4>Раздел В</h4><p></p>

<p style="font-weight:500;">Предупреждающие знаки, устанавливаемые вблизи от перекрестков</p><br>

<p style="font-weight:500;">1. Знаки.</p><br>

<p style="text-indent:2em;">Предупреждающие знаки, устанавливаемые вблизи от перекрестков, должны соответствовать образцам А (с индекс. a) или А 
(с индекс. b), описанным в разделе А приложения 1.</p><br>

<p style="font-weight:500;">2. Обозначения.</p>

<p style="text-indent:2em;"></p><h6>Обозначения должны быть черными или синими:</h6><p></p>

<p style="text-indent:2em;"></p><h6>а) в отношении обозначений, которые помещаются на знаке А (с индекс. a) или А (с индекс. b), различают следующие 
случаи:</h6><p></p>

<p style="text-indent:2em;">i) перекресток, преимущественное право проезда по которому определяется действующим в государстве общепринятым 
правилом преимущественного проезда; используется обозначение A,21 (с индекс. a) с образцом знака А (с индекс. a) 
и обозначение A,21 (с индекс. b) с образцом знака А (с индекс. b).</p><br>

<p style="text-indent:2em;">Обозначения A,21 (с индекс. a) и А,21 (с индекс. b) могут быть заменены обозначениями, указывающими более ясно 
характер перекрестка, как например, A,21 (с индекс. c); A,21 (с индекс. d); A,21 (с индекс. e); A,21 и А,21 (
с индекс. g);</p><br>

<p style="text-indent:2em;">ii) пересечение с дорогой, пользователи которой должны уступать дорогу; используется обозначение А,22 (с индекс.
 a).</p><br>

<p style="text-indent:2em;">Обозначение А,22 (с индекс. a) может быть заменено обозначениями, указывающими более ясно характер перекрестка, как
 например, A,22 (с индекс. b) и А,22 (с индекс. c). Эти обозначения могут применяться на дороге лишь в том случае, 
 если на дороге или на дорогах, с которыми она образует обозначенный перекресток, установлен знак В,1 или знак В,2 
 или если характер этих дорог таков (например, проселочные или грунтовые дороги), что в силу национального
 законодательства едущие по ним водители должны даже при отсутствии этих знаков уступать дорогу на перекрестке.
 Использование этих обозначений на дорогах, на которых установлен знак В,3, будет ограничиваться некоторыми 
 исключительными случаями;</p><br>

<p>iii) пересечение с дорогой, пользователям которой следует уступать дорогу.</p><br>

<p>Если на перекрестке установлен знак "Пересечение с главной дорогой" B,1, то применяется обозначение А,23.</p><br>

<p style="text-indent:2em;">Если на перекрестке установлен знак "Проезд без остановки запрещен" (B,2), применяется то из обозначений A,24 (с
 индекс. a) и А,24 (с индекс. b), которое соответствует образцу знака В,2.</p><br>

<p style="text-indent:2em;">Однако вместо сигнального знака А (с индекс. a) с его обозначениями могут применяться знаки B,1 или В,2 в 
соответствии с п. 6 ст. 10 настоящей Конвенции;</p><br>

<p style="text-indent:2em;">iv) перекресток с круговым движением: используется обозначение А,25. При левостороннем движении направление 
стрелок должно быть обратным;</p><br>

<p style="text-indent:2em;">b) в тех случаях, когда движение на перекрестке регулируется световыми сигналами, в дополнение к знакам, 
описанным в настоящем разделе В, или вместо этих знаков может помещаться знак А (с индекс. a) и А (с индекс.
 b) с обозначением А,16, описанным в разделе В приложения 1.</p>

<p></p><h4>Раздел С</h4><p></p>

<p style="font-weight:500;">Знаки, регулирующие преимущественное право проезда на узких участках дорог</p><br>

<p style="font-weight:500;">1. Знак, предоставляющий приоритет встречному транспорту.</p><br>

<p style="text-indent:2em;">Если на узком участке дороги, где встречный разъезд затруднителен или невозможен, движение регламентируется, и 
если эта регламентация состоит в предоставлении приоритета движению в одном направлении, а не в установке световых 
дорожных сигналов, поскольку водители могут ясно видеть проезд на всем его протяжении как днем, так и ночью, знак В,
5 "Приоритет встречному транспорту" устанавливается лицом к движению с той стороны проезда, где не имеется приоритета. 
Этот знак указывает запрещение въезда на узкий участок дороги до тех пор, пока нельзя проехать по этому участку, не 
вынуждая остановиться водителей встречных транспортных средств.</p><br>

<p style="text-indent:2em;">Этот знак должен быть круглым с белым или желтым фоном и красной каймой; стрелка, обозначающая направление, в котором
 предоставляется приоритет, должна быть черного цвета, а стрелка, обозначающая другое направление, - красного цвета.</p><br>

<p>В государствах с левосторонним движением расположение стрелок должно быть обратным.</p><br>

<p style="font-weight:500;">2. Знак, предоставляющий приоритет по отношению к встречному транспорту.</p><br>

<p style="text-indent:2em;">Для того чтобы предупредить водителей о том, что на узком участке дороги они пользуются приоритетом по отношению к
 встречному транспорту, используется знак В,6.</p><br>

<p style="text-indent:2em;">Этот знак имеет прямоугольную форму с голубым фоном; стрелка, направленная вверх, должна быть белого цвета, а 
другая - красного цвета,</p><br>

<p style="text-indent:2em;">При левосторонним движении расположение стрелок должно быть обратным.</p><br>

<p style="text-indent:2em;">Когда на дороге используется знак В,6, с другой стороны узкого участка дороги в обязательном порядке 
устанавливается знак В,5, предназначенный для транспортных средств, движущихся в обратном направлении.</p>

<p></p><h4>Приложение 3
к Конвенциио дорожных знаках и сигналах</h4><p></p>

<p>(Вена, 8 ноября 1968 г.)</p><br>

<p style="font-weight:500;">Знаки, относящиеся к железнодорожным переездам</p>

<p></p><h4>Раздел А</h4><p></p>

<p style="font-weight:500;">Предупреждающие знаки</p>

<p style="text-indent:2em;"></p><h6>Подлежащий установке знак является знаком А (с индекс. a) или знаком А (с индекс. b), которые описаны в разделе
 А приложения 1. В отношении обозначения, которое помещается на знаке, различают следующие случаи:</h6><p></p>

<p style="text-indent:2em;">а) для обозначения железнодорожных переездов с шлагбаумами или полушлагбаумами, расположенными в шахматном порядке
 с каждой стороны железнодорожного пути, используется обозначение A,26;</p><br>

<p style="text-indent:2em;">b) для обозначения других железнодорожных переездов используется обозначение A,27, которое включает два образца:
 А,27 (с индекс. a) и А,27 (с индекс. b);</p><br>

<p style="text-indent:2em;">с) для предупреждения о приближении к пересечению с трамвайной линией и при условии, что это пересечение не 
является железнодорожным переездом согласно определению, содержащемуся в ст. 1 Конвенции, может использоваться
 обозначение А,28.</p><br>

<p style="text-indent:2em;">Примечание. Если не считается необходимым предупредить о приближении к пересечению автомобильной дороги 
железнодорожными путями, на которых железнодорожное движение осуществляется на очень небольшой скорости, 
а дорожное движение регулируется сопровождающим лицом железнодорожных транспортных средств, подающим 
необходимые сигналы рукой, используется знак А,20, описанный в разделе В приложения 1.</p>

<p></p><h4>Раздел В</h4><p></p>

<p style="font-weight:500;">Знаки, устанавливаемые в непосредственной близости от железнодорожных переездов</p><br>

<p style="text-indent:2em;">Знак В,7, о котором говорится в п. 2 ст. 35 настоящей Конвенции, имеет три образца: В,7 (с индекс. a) 
- В,7 (с индекс. b) - В,7 (с индекс. c).</p><br>

<p style="text-indent:2em;">Образцы В,7 (с индекс. a) и В,7 (с индекс. b) имеют белый или желтый фон с красной или черной каймой;
 образец В,7 (с индекс. c) имеет белый или желтый фон с черной каймой; надписи на образце В,7 (с индекс
 . c) выполняются черными буквами. Образец B,7 (с индекс. b) используется, если путь имеет не менее чем 
 две колеи; при использовании образца B,7 (с индекс. c) дополнительная табличка применяется лишь тогда, 
 когда путь имеет не менее чем две колеи, и в этом случае на ней указывается их количество. Обычная длина 
 креста должна быть не менее 1,2 м (4 футов). При отсутствии достаточного пространства знак может изображаться
 таким образом, чтобы его концы были направлены вверх и вниз.</p>

<p></p><h4>Раздел С</h4><p></p>

<p style="font-weight:500;">Дополнительные знаки, устанавливаемые вблизи от железнодорожных переездов</p><br>

<p style="text-indent:2em;">Щитками, упомянутыми в п. 3 ст. 35, являются знаки A,29 (с индекс. a), А,29 (с индекс. b) и A,29 (с индекс.
 c). Наклон полос обращен к проезжей части.</p><br>

<p style="text-indent:2em;">Над знаками A,29 (с индекс. b) и А,29 (с индекс. c) может помещаться так же, как он должен помещаться над 
знаком A,29 (с индекс. a), знак, предупреждающий о приближении к железнодорожному переезду.</p>

<p></p><h4>Приложение 4
к Конвенциио дорожных знаках и сигналах</h4><p></p>

<p>(Вена, 8 ноября 1968 г.)</p><br>

<p style="font-weight:500;">Знаки, означающие обязательное предписание, за исключением знаков, относящихся к преимущественному праву 
проезда, остановке и стоянке</p><br>

<p style="text-indent:2em;">Примечание. В отношении знаков, регулирующих преимущественное право проезда, см. приложение 2; в 
отношении знаков, относящихся к остановке и стоянке, см. приложение 6.</p>

<p></p><h4>Раздел А</h4><p></p>

<p style="font-weight:500;">Запрещающие или ограничивающие знаки</p><br>

<p style="font-weight:500;">1. Характеристики знаков и обозначений.</p><br>

<p style="text-indent:2em;">а) запрещающие и ограничивающие знаки должны быть круглыми; их диаметр должен быть не менее 0,6 м (2 футов)
 вне населенных пунктов и не менее 0,4 м (16 дюймов) в населенных пунктах;</p><br>

<p style="text-indent:2em;">b) исключая отступления, указанные ниже в связи с описанием данного знака, запрещающие или ограничивающие 
знаки должны иметь белый или желтый фон и широкую красную кайму, а обозначения, равно как и надписи, если 
таковые имеются, должны быть черного или синего цвета; наклонные полосы, если они имеются, должны быть красного 
цвета и пересекать знаки слева вниз направо.</p><br>

<p style="font-weight:500;">2. Описание знаков.</p>

<p style="text-indent:2em;"></p><h6>a) Запрещение и ограничение движения:</h6><p></p>

<p style="text-indent:2em;">i) для указания запрещения въезда любых транспортных средств используются знак C,1 "Въезд запрещен". Имеются два
 образца этого знака: C,1 (с индекс. a) и C,1 (с индекс. b);</p><br>

<p style="text-indent:2em;">ii) для указания запрещения всякого движения транспортных средств в обоих направлениях используется знак С,2 
"Движение в обоих направлениях запрещено";</p>

<p style="text-indent:2em;"></p><h6>iii) для указания запрещения движения только некоторых категорий транспортных средств или пользователей дороги
 используется знак, обозначение которого состоит из силуэта транспортных средств или пользователей дороги, для
 которых движение запрещено. Указанные знаки С,3 (с индекс. a), С,3 (с индекс. b), С,3 (с индекс. с), С,3 (с 
 индекс. d), С,3 (с индекс. e), С,3 (с индекс. f), С,3 (с индекс. g), С,3 (с индекс. h), С,3 (с индекс. j) и 
 С,3 (с индекс. k) имеют следующие значения:</h6><p></p>

<p style="text-indent:2em;">С,3 (с индекс. a) - "Движение всех механических транспортных средств, за исключением двухколесных мотоциклов
 без коляски, запрещено"; С,3 (с индекс. b) - "Мотоциклетное движение запрещено"; С,3 (с индекс. с) - 
 "Велосипедное движение запрещено"; C,3 (с индекс. d) - "Движение велосипедов с подвесным двигателем запрещено
 "; С,3 (с индекс. e) - "Грузовое движение запрещено".</p><br>

<p style="text-indent:2em;">Указание цифры тоннажа либо светлым цветом на силуэте транспортного средства, либо, в соответствии с п. 4 ст
. 8 Конвенции, на дополнительной табличке, помещенной под знаком С,3 (с индекс. e), означает, что запрещение 
применяется лишь в том случае, когда разрешенный максимальный вес транспортного средства или состава 
транспортных средств превышает указанную цифру;</p><br>

<p style="text-indent:2em;">С,3 (с индекс. f) - "Движение любого механического транспортного средства, буксирующего прицеп, кроме 
полуприцепа или одноосного прицепа, запрещено".</p><br>

<p style="text-indent:2em;">Указание цифры тоннажа либо светлым цветом на силуэте прицепа, либо, в соответствии с п. 4 ст. 8 настоящей
 Конвенции, на дополнительной табличке, помещенной под знаком С,3, означает, что запрещение применяется лишь 
 в том случае, когда разрешенный максимальный вес прицепа превышает указанную цифру.</p>

<p style="text-indent:2em;"></p><h6>Договаривающиеся стороны могут в тех случаях, когда они сочтут это целесообразным, заменить силуэт задней 
части грузового автомобиля силуэтом задней части легкового автомобиля, а силуэт прицепа (в том виде, в каком
 он изображен) - силуэтом прицепа, который может быть сцеплен с таким автомобилем:</h6><p></p>

<p>С,3 (с индекс. g) - "Движение пешеходов запрещено";</p><br>

<p>С,3 (с индекс. h) - "Гужевое движение запрещено";</p><br>

<p>С,3 (с индекс. j) - "Движение ручных тележек запрещено";</p><br>

<p>С,3 (с индекс. k) - "Движение самоходных сельскохозяйственных транспортных средств запрещено".</p><br>

<p style="text-indent:2em;">Примечание. Договаривающиеся стороны могут решить не проводить на знаках С,3 (с индекс. a) - C,3 (с индекс
. k) наклонную красную полосу, соединяющую левый верхний квадрат с правым нижним квадратом, или, если это не
 ухудшает видимость или понимание обозначения, не прерывать полосу на уровне этого обозначения;</p><br>

<p style="text-indent:2em;">iv) для указания запрещения движения нескольких категорий транспортных средств или пользователей дороги можно
 использовать либо столько же запрещающих знаков, сколько имеется запрещенных категорий, либо запрещающий знак
 , на котором изображены силуэты различных транспортных средств или пользователей дороги, движение которых 
 запрещено. Указанные ниже знаки C,4 (с индекс. a) "Движение механических транспортных средств запрещено" и
 С,4 (с индекс. b) "Движение механических транспортных средств и гужевое движение запрещено" являются примерами
 такого знака.</p><br>

<p style="text-indent:2em;">Вне населенных пунктов нельзя устанавливать знаки, на которых имеется больше двух силуэтов, а в населенных
 пунктах нельзя устанавливать знаки, на которых имеется больше трех силуэтов;</p>

<p style="text-indent:2em;"></p><h6>v) для указания запрещения движения транспортных средств, вес или габариты которых превышают определенные 
пределы, используются следующие знаки:</h6><p></p>

<p>С,5 - "Движение транспортных средств, габаритная ширина которых превышает ... метров (...футов), запрещено"</p><br>

<p>С,6 - "Движение транспортных средств, габаритная высота которых превышает ... метров (...футов), запрещено"</p><br>

<p style="text-indent:2em;">С,7 - "Движение транспортных средств, вес которых с нагрузкой превышает ... тонн, запрещено". С,8 - "Движение
 транспортных средств с нагрузкой на ось, превышающей ... тонн, запрещено". С,9 - "Движение транспортных средств
 или составов транспортных средств длиной более ... метров (...футов), запрещено";</p><br>

<p style="text-indent:2em;">vi) для указания запрещения движения транспортных средств без сохранения между ними дистанции, указанной на знаке,
 используется знак C,10 "Запрещается движение транспортных средств без сохранения между ними дистанции по крайней 
 мере в ... метров (...ярдов)".</p><br>

<p>b) Запрещение поворота.</p><br>

<p style="text-indent:2em;">Для указания запрещения поворота (направо или налево, в зависимости от направления стрелки) используется знак C,11 
(с индекс. a) "Поворот налево запрещен" или знак С,11 (с индекс. b) "Поворот направо запрещен".</p><br>

<p>с) Запрещение разворота.</p><br>

<p>Для указания запрещения разворота используется знак C,12 "Разворот запрещен".</p>

<p style="text-indent:2em;"></p><h6>d) Запрещение обгона:</h6><p></p>

<p style="text-indent:2em;">i) для указания того, что, помимо относящихся к обгону общих предписаний, предусмотренных действующими правилами,
 запрещается обгон всех движущихся по дорогам механических транспортных средств, за исключением двухколесных 
 велосипедов с подвесным двигателем и двухколесных мотоциклов без коляски, используется знак C,13 (с индекс. a) 
 "Обгон запрещен". Имеются два образца этого знака: С,13 (с индекс. aa) и С,13 (с индекс. ab).</p><br>

<p style="text-indent:2em;">ii) для указания того, что обгон запрещен лишь грузовым транспортным средствам, разрешенный максимальный вес 
которых превышает 3,5 т (7 700 фунтов), используется знак C,13 (с индекс. b) "Грузовым транспортным средствам 
обгон запрещен". Имеются два образца этого знака: С,13 (c индекс. ba) и С,13 (с индекс. bb).</p><br>

<p style="text-indent:2em;">На дополнительной табличке, помещенной под знаком в соответствии с п. 4 ст. 8 Конвенции, может быть помещено 
указание об изменении разрешенного максимального веса транспортных средств, при превышении которого применяется 
запрещение;</p><br>

<p style="text-indent:2em;">iii) при левостороннем движении цвет автомобилей на знаках С,13 (с индекс. aa) и C,13 (с индекс. ba) следует 
соответственно изменить.</p><br>

<p>е) Ограничение скорости.</p><br>

<p style="text-indent:2em;">Для указания ограничения скорости используется знак С,14 "Максимальная скорость, ограничиваемая указанной цифрой".
 Обозначенная на знаке цифра указывает максимальную скорость в единице измерения, чаще всего используемой в данном 
 государстве для указания скорости транспортных средств. После или ниже цифры, обозначающей скорость, могут быть, 
 например, добавлены обозначения "км" (километров) или "миль".</p><br>

<p style="text-indent:2em;">Для указания ограничения скорости, применяемого только к транспортным средствам, разрешенный максимальный вес 
которых превышает определенную цифру, надпись с указанием этой цифры помещается на дополнительной табличке под 
знаком в соответствии с п. 4 ст. 8 Конвенции;</p><br>

<p>f) Запрещение подачи звукового сигнала.</p><br>

<p style="text-indent:2em;">Для указания запрещения подачи звуковых сигналов, за исключением случаев, когда это необходимо, во избежание 
дорожно-транспортных происшествий, используется знак C,15 "Подача звукового сигнала запрещена". Если этот знак
 не установлен у въезда в населенный пункт рядом с указателем наименования населенного пункта или непосредственно
 после этого знака, он должен быть дополнен табличкой образца 2, описанной в приложении 7, на которой указывается
 расстояние, на котором действует запрещение.</p><br>

<p style="text-indent:2em;">Рекомендуется не устанавливать этот знак у въезда в населенные пункты, если запрещение распространяется на все
 населенные пункты, и предусмотреть, чтобы при въезде в населенный пункт указатель наименования населенного 
 пункта указывал пользователям дороги, что, начиная с этого места, применяются правила движения, действующие
 на данной территории в населенных пунктах;</p><br>

<p>g) Проезд без остановки запрещен.</p><br>

<p style="text-indent:2em;">Для указания близости таможни, где остановка обязательна, используется знак С,16 "Проезд без остановки запрещен".
 В отступление от положений ст. 8 Конвенции на этом знаке обозначено слово "таможня", причем предпочтительно его 
 указывать на двух языках; Договаривающиеся стороны, которые будут пользоваться знаками С,16, должны попытаться
 достигнуть на региональном уровне договоренности о том, чтобы это слово было обозначено на одном и том же языке
 на всех устанавливаемых ими знаках.</p><br>

<p style="text-indent:2em;">Этот знак может использоваться также для указания других случаев запрещения проезда без остановки; при этом слово 
"таможня" заменяется другой очень краткой надписью, указывающей причину остановки.</p>

<p style="text-indent:2em;"></p><h6>h) Знаки, указывающие конец запрещений или ограничений:</h6><p></p>

<p style="text-indent:2em;">i) для указания места, где прекращается действие всех запрещений, доводимых до сведения водителей движущихся
 транспортных средств, запрещающими знаками, используется знак C,17 (с индекс. a) "Конец всех запрещений местного
 характера, относящихся к движущимся транспортным средствам". Этот знак должен быть круглым с белым или желтым фоном
 без бордюра или с узкой черной каймой и с диагональной полосой, имеющей наклон справа сверху вниз налево, которая может
 быть черного или темно-серого цвета или состоять из черных или серых параллельных линий;</p><br>

<p style="text-indent:2em;">ii) для указания места, где прекращается действие запрещения или ограничения, доведенного до сведения водителей движущихся
 транспортных средств запрещающим или ограничивающим знаком, используется знак C,l7 (с индекс. b) "Конец ограничения скорости"
 или знак С,17 (с индекс. c) "Конец запрещения обгона". Эти знаки аналогичны знаку C,17 (с индекс. a), но на них, кроме того,
 нанесено светло-серое обозначение запрещения или ограничения, действие которого прекращается.</p><br>

<p style="text-indent:2em;">В отступление от положений п. 1 ст. 6 Конвенции указанные в настоящем подпункте "h" знаки могут устанавливаться на
 обратной стороне запрещающего или ограничивающего знака, предназначенного для транспорта, идущего во встречном
 направлении.</p>

<p></p><h4>Раздел В</h4><p></p>

<p style="font-weight:500;">Предписывающие знаки</p><br>

<p style="font-weight:500;">1. Общие характеристики знаков и обозначений.</p><br>

<p style="text-indent:2em;">а) Предписывающие знаки должны быть круглыми; их диаметр должен быть не менее 0,6 м (2 футов) вне населенных пунктов
 и не менее 0,4 м (16 дюймов) в населенных пунктах. Однако знаки диаметром не менее 0,3 м (12 дюймов) могут использоваться
 в сочетании со световыми дорожными сигналами или устанавливаться на тумбах островков безопасности;</p><br>

<p style="text-indent:2em;">b) при отсутствии иных указаний, эти знаки должны быть голубого цвета, а обозначения должны быть белого или светлого цвета,
 или, как возможный вариант, знаки должны быть белого цвета с красной каймой, а обозначения должны быть черного цвета.</p><br>

<p style="font-weight:500;">2. Описание знаков.</p><br>

<p>а) Обязательное направление.</p><br>

<p style="text-indent:2em;">Для указания того, что транспортные средства должны следовать в данном направлении или только в определенных
 направлениях, используется образец D,1 (с индекс. a) знака D,1 "Обязательное направление", в котором стрелка или
 стрелки направлены в соответствующем направлении или соответствующих направлениях. Однако в отступление от положений
 п. 1 настоящего раздела вместо знака D,1 (с индекс. a) может использоваться знак D,1 (с индекс. b). Знак D,1 (с индекс. 
 b) должен быть черного цвета с белой каймой и иметь обозначение белого цвета</p><br>

<p>b) Обязательный объезд препятствия.</p><br>

<p style="text-indent:2em;">Знак D,2 "Обязательный объезд препятствия", установленный, в отступление от положений п. 1 ст. 6 Конвенции, на
 островке безопасности или перед каким-либо препятствием на дороге, указывает, что транспортные средства обязаны
 объезжать островок безопасности или препятствие со стороны, указанной стрелкой.</p><br>

<p>c) Обязательное круговое движение.</p><br>

<p style="text-indent:2em;">Знак D,3 "Обязательное круговое движение" указывает водителям, что они должны соблюдать правила, касающиеся 
кругового движения.</p><br>

<p>При левостороннем движении стрелки должны быть направлены в противоположную сторону.</p><br>

<p>d) Обязательная велосипедная дорожка.</p><br>

<p style="text-indent:2em;">Знак D,4 "Обязательная велосипедная дорожка" указывает велосипедистам, что они обязаны пользоваться
 велосипедной дорожкой, в начале которой он установлен, и указывает водителям других транспортных средств,
 что они не имеют права пользоваться этой дорожкой. Однако водители велосипедов с подвесным двигателем также
 обязаны пользоваться этой дорожкой, если это предусматривается национальным законодательством или предписывается
 дополнительной табличкой, содержащей надпись или обозначение знака С,3 (с индекс. d).</p><br>

<p>е) Обязательная дорожка для пешеходов.</p><br>

<p style="text-indent:2em;">Знак D,5 "Обязательная дорожка для пешеходов" указывает пешеходам, что они обязаны пользоваться дорожкой, в
 начале которой он установлен, и указывает другим пользователям дороги, что они не имеют права пользоваться 
 этой дорожкой.</p><br>

<p>f) Обязательная дорожка для всадников.</p><br>

<p style="text-indent:2em;">Знак D,6 "Обязательная дорожка для всадников" указывает всадникам, что они обязаны пользоваться дорожкой, в
 начале которой он установлен, и указывает другим пользователям дороги, что они не имеют права пользоваться
 этой дорожкой.</p><br>

<p>g) Обязательная минимальная скорость.</p><br>

<p style="text-indent:2em;">Знак D,7 "Обязательная минимальная скорость" указывает, что транспортные средства, движущиеся по дороге, в 
начале которой он установлен, должны двигаться по крайней мере с указанной скоростью; обозначенная на знаке цифра
 указывает эту скорость в единице измерения, чаще всего используемой в государстве для указания скорости транспортных
 средств. После цифры, обозначающей скорость, могут быть добавлены, например, обозначения "км" (километров) или 
 "миль".</p><br>

<p>h) Конец обязательной минимальной скорости.</p><br>

<p style="text-indent:2em;">Знак D,8 "Коней обязательной минимальной скорости" указывает на прекращение действия обязательной минимальной
 скорости, предписанной знаком D,7. Знак D,8 идентичен знаку D,7, но он перечеркивается наклонной красной полосой,
 проходящей справа сверху вниз налево.</p><br>

<p>i) Цепи противоскольжения обязательны.</p><br>

<p style="text-indent:2em;">Знак D,9 "Цепи противоскольжения обязательны" указывает, что транспортные средства, двигающиеся по дороге, в
 начале которой он установлен, обязаны иметь не менее чем на двух ведущих колесах цепи противоскольжения.</p>

<p></p><h4>Приложение 5
к Конвенциио дорожных знаках и сигналах</h4><p></p>

<p>(Вена, 8 ноября 1968 г.)</p><br>

<p style="font-weight:500;">Указательные знаки, за исключением знаков, относящихся к стоянке</p><br>

<p>Примечание. В отношении указательных знаков, относящихся к стоянке, см. приложение 6.</p><br>

<p style="text-indent:2em;">Общие характеристики знаков и обозначений разделов A - F (в отношении характеристик знаков и обозначений
 раздела G см. этот раздел).</p><br>

<p style="text-indent:2em;">1. Указательные знаки являются обычно прямоугольными; однако указатели направления могут иметь форму
 удлиненного, оканчивающегося стрелой, прямоугольника, длинная сторона которого расположена 
 горизонтально.</p><br>

<p style="text-indent:2em;">2. На указательных знаках нанесены белые или светлые обозначения или надписи на темном фоне или темные
 обозначения или надписи на белом или светлом фоне; красный цвет может использоваться лишь в виде 
 исключения и никогда не должен преобладать.</p>

<p></p><h4>Раздел А</h4><p></p>

<p style="font-weight:500;">Предварительные указатели направлений</p><br>

<p style="font-weight:500;">1. Общий случай.</p><br>

<p>Примеры предварительных указателей направлений E,1 (с индекс. a),</p><br>

<p>E,1 (с индекс. b) и E,1 (с индекс. c)&amp; </p>

<p style="text-indent:2em;"></p><h6>2. Особые случаи:</h6><p></p>

<p style="text-indent:2em;">а) примеры предварительных указателей направлений для дороги, ведущей в тупик: - E,2 (с индекс. 
a) и E,2 (с индекс. b);</p><br>

<p style="text-indent:2em;">b) пример предварительного указателя направлений для маршрута, по которому надлежит следовать, чтобы 
повернуть налево, если поворот налево на следующем перекрестке запрещен: E,3;</p><br>

<p style="text-indent:2em;">c) пример знака предварительного перестроения в рядах на перекрестках дорог с несколькими 
полосами движения: E,4.</p>

<p></p><h4>Раздел В</h4><p></p>

<p style="font-weight:500;">Указатели направлений</p>

<p style="text-indent:2em;"></p><h6>1. Примеры знаков, указывающих направление к населенному пункту:</h6><p></p>

<p>E,5 (с индекс. a), E,5 (с индекс. b), E,5 (с индекс. c) и E,5 (с индекс. d).</p>

<p style="text-indent:2em;"></p><h6>2. Примеры знаков, указывающих направление к аэропорту:</h6><p></p>

<p>E,6 (с индекс. a), E,6 (с индекс. b) и E,6 (с индекс. c).</p><br>

<p>3. Знак E,7 указывает направление к лагерю автотуристов.</p><br>

<p>4. Знак E,8 указывает направление к туристской базе для молодежи.</p>

<p></p><h4>Раздел С</h4><p></p>

<p style="font-weight:500;">Указатели наименований</p><br>

<p>Длинная сторона прямоугольника, образующего этот знак, расположена горизонтально.</p>

<p style="text-indent:2em;"></p><h6>1. Примеры знаков, указывающих начало населенного пункта:</h6><p></p>

<p>E,9 (с индекс. a) и E,9 (с индекс. b).</p>

<p style="text-indent:2em;"></p><h6>2. Примеры знаков, указывающих конец населенного пункта:</h6><p></p>

<p>E,9 (с индекс. c) и E,9 (с индекс. d).</p><br>

<p style="text-indent:2em;">В отступление от положений п. 1 ст. 6 Конвенции, эти знаки могут помещаться на обратной стороне 
указателей наименований населенных пунктов.</p>

<p></p><h4>Раздел D</h4><p></p>

<p style="font-weight:500;">Подтверждающие знаки</p><br>

<p>Знак E,10 является примером подтверждающего знака.</p><br>

<p style="text-indent:2em;">В отступление от положений п. 1 ст. 6 Конвенции, этот знак может помещаться на обратной стороне 
другого знака, предназначенного для транспортных средств, движущихся во встречном направлении.</p>

<p></p><h4>Раздел E</h4><p></p>

<p style="font-weight:500;">Пешеходный переход</p><br>

<p style="text-indent:2em;">Знак E,11 (с индекс. a) "Пешеходный переход" используется для указания пешеходам и водителям 
места, на котором находится пешеходный переход.</p><br>

<p style="text-indent:2em;">Щиток голубого или черного цвета с треугольником белого или желтого цвета с черным или синим 
обозначением; используется обозначение A,11.</p><br>

<p style="text-indent:2em;">Однако можно также использовать знак E,11 (с индекс. b), имеющий форму неправильного пятиугольника 
с голубым фоном и белым обозначением.</p>

<p></p><h4>Раздел F</h4><p></p>

<p style="font-weight:500;">Другие знаки, дающие водителям транспортных средств полезные указания</p><br>

<p>Эти знаки должны иметь голубой фон.</p><br>

<p style="font-weight:500;">1. Знак "Больница".</p><br>

<p style="text-indent:2em;">Этот знак используется для указания водителям транспортных средств на необходимость принятия 
мер предосторожности, требуемых близостью лечебных учреждений, и, в частности, на необходимость 
избегать по мере возможности шума. Имеются два образца этого знака% E,12 (с индекс. a) и E,12 
(с индекс. b).</p><br>

<p style="text-indent:2em;">Изображенный на знаке E,12 (с индекс. b) красный крест может быть заменен одним из обозначений,
 указанных в подпункте "а" п. 2 раздела G.</p><br>

<p style="font-weight:500;">2. Знак "Дорога с односторонним движением".</p>

<p style="text-indent:2em;"></p><h6>Когда считается необходимым подтвердить пользователям дороги, что они находятся на дороге с 
односторонним движением, могут быть установлены два различных знака "Дорога с односторонним 
движением":</h6><p></p>

<p style="text-indent:2em;">a) знак E,13 (с индекс. a), установленный более или менее перпендикулярно оси проезжей части дороги;
 щиток знака должен быть квадратным;</p><br>

<p style="text-indent:2em;">b) знак E,13 (с индекс. b), установленный почти параллельно оси проезжей части дороги; щиток знака
 представляет собой удлиненный прямоугольник, длинная сторона которого расположена горизонтально. 
 На стрелке знака E,13 (с индекс. b) могут быть нанесены на национальном языке или на одном из национальных
 языков государства слова "Одностороннее движение".</p><br>

<p style="text-indent:2em;">Знаки E,13 (с индекс. a) и E,13 (с индекс. b), могут устанавливаться независимо от того, установлены ли
 до въезда на улицу запрещающие или предписывающие знаки.</p><br>

<p style="font-weight:500;">3. Знак "Дорога ведет в тупик".</p><br>

<p>Знак E,14 "Дорога ведет в тупик", установленный при въезде на дорогу, указывает, что дорога
 ведет в тупик.</p><br>

<p style="font-weight:500;">4. Знаки, предупреждающие о въезде на автомагистраль или о выезде с автомагистрали.</p><br>

<p style="text-indent:2em;">Знак E,15 "Автомагистраль" должен устанавливаться в месте, начиная с которого применяются специальные
 правила движения по автомагистралям. Знак E,16 "Конец автомагистрали" должен устанавливаться в месте,
 где перестают применяться указанные правила.</p><br>

<p style="text-indent:2em;">Знак E,16 можно также использовать и повторять для предупреждения о приближении к концу автомагистрали;
 в нижней части каждого установленного таким образом знака указывается расстояние между знаком и концом
 автомагистрали.</p><br>

<p style="font-weight:500;">5. Знаки, предупреждающие о въезде на дорогу, на которой действуют правила движения, применимые к 
автомагистралям, или о выезде с такой дороги.</p><br>

<p style="text-indent:2em;">Знак E,17 "Автомобильная дорога" должен устанавливаться на месте, начиная с которого применяются 
специальные правила движения по дорогам, иным, чем автомагистрали, которые предназначаются для 
автомобильного движения и не обслуживают придорожных владений. На дополнительной табличке, помещенной
 под знаком E,17, может быть указано, что в отступление от правил въезд в придорожные владения 
 разрешается.</p><br>

<p style="text-indent:2em;">Знак E,18 "Конец автомобильной дороги" можно также использовать и повторять для предупреждения о
 приближении к концу автомобильной дороги; в нижней части каждого установленного таким образом знака
 указывается расстояние между знаком и концом автомобильной дороги.</p><br>

<p style="font-weight:500;">6. Знаки, обозначающие автобусную или трамвайную остановку.</p><br>

<p>E,19 "Автобусная остановка; E,20 "Трамвайная остановка".</p><br>

<p style="font-weight:500;">7. Знак "Возможность использования дороги".</p><br>

<p style="text-indent:2em;">Знак E,21 "Возможность использования дороги" устанавливается для указания того, является ли горная
 дорога, в частности при проезде через перевал, открытой или закрытой; он устанавливается при въезде
 на дорогу или на дороги, ведущие к данному проезду.</p><br>

<p>Название проезда (перевала) наносится белыми буквами. На знаке название "Furka" дано в качестве
 примера.</p><br>

<p>Таблички 1, 2 и 3 являются съемными.</p><br>

<p style="text-indent:2em;">Если проезд закрыт, применяется табличка 1 красного цвета с надписью "Закрыт"; если проезд открыт
, применяется табличка зеленого цвета с надписью "Открыт". Надписи наносятся белой краской и 
предпочтительно на нескольких языках.</p><br>

<p>Таблички 2 и 3 имеют белый фон с надписями и обозначениями черного цвета.</p><br>

<p style="text-indent:2em;">Если проезд открыт, на табличке 3 не имеется никакого указания, а на табличке 2, в зависимости от
 состояния дороги, либо нет никакого указания, либо изображен знак D,9 "Цепи противоскольжения обязательны",
 либо имеется следующее обозначение E,22, означающее "Рекомендуется применение цепей противоскольжения или 
 зимних шин". Это обозначение должно быть черного цвета.</p><br>

<p style="text-indent:2em;">Если проезд закрыт, на табличке 3 указывается название населенного пункта, до которого дорога открыта, а
 на табличке 2, в зависимости от состояния дороги, либо нанесена надпись "Открыт до", либо обозначение E
 ,22, либо знак D,9.</p>

<p></p><h4>Раздел G</h4><p></p>

<p style="font-weight:500;">Знаки, обозначающие объекты, которые могут быть полезны для пользователей дороги</p>

<p style="text-indent:2em;"></p><h6>1. Характеристики знаков и обозначений раздела:</h6><p></p>

<p style="text-indent:2em;">a) знаки F должны иметь голубой или зеленый цвет; на них изображен белый или желтый прямоугольник, на
 котором нанесено обозначение;</p><br>

<p style="text-indent:2em;">b) на голубой или зеленой полосе в нижней части знака может быть указано белыми цифрами расстояние до
 обозначенной установки или до въезда на ведущую к ней дорогу; на знаке с обозначением F,5 может быть таким
 же образом нанесена надпись "гостиница или мотель". Знаки могут также устанавливаться при въезде на дорогу,
 ведущую к установке, и в этом случае на голубой или зеленой полосе в нижней части знака может быть нанесена
 белая направляющая стрелка. Обозначение должно быть черного или темно-синего цвета, за исключением обозначений
 F,1 (с индекс. a), F,1 (с индекс. b) и F,1 (с индекс. c), которые должны быть красного цвета.</p>

<p style="text-indent:2em;"></p><h6>2. Описание обозначений:</h6><p></p>

<p>а) обозначения "Пункт медицинской помощи".</p><br>

<p style="text-indent:2em;">Для указания пунктов медицинской помощи используются обозначения, применяющиеся в соответствующих государствах
. Эти обозначения должны быть красного цвета. Примерами этих обозначений являются: F,1 (с индекс. a), F,1 
(с индекс. b) и F,1 (с индекс. c).</p>

<p style="text-indent:2em;"></p><h6>b) прочие обозначения:</h6><p></p>

<p>F,2 "Пункт технического обслуживания"; F,3 "Телефон";</p><br>

<p>F,4 "Автозаправочная станция";</p><br>

<p>F,5 "Гостиница или мотель";</p><br>

<p>F,6 "Ресторан";</p><br>

<p>F,7 "Буфет или кафетерий";</p><br>

<p>F,8 "Участок для пикника";</p><br>

<p>F,9 "Участок для начальных пунктов пешеходных маршрутов";</p><br>

<p>F,10 "Лагерь автотуристов (кемпинг)";</p><br>

<p>F,11 "Место стоянки прицепов для кемпинга";</p><br>

<p>F,12 "Участок для лагеря автотуристов и место стоянки прицепов для кемпинга";</p><br>

<p>F,13 "Туристская база для молодежи".</p>

<p></p><h4>Приложение 6
к Конвенциио дорожных знаках и сигналах</h4><p></p>

<p>(Вена, 8 ноября 1968 г.)</p><br>

<p style="font-weight:500;">Знаки, относящиеся к остановке и стоянке</p>

<p></p><h4>Раздел A</h4><p></p>

<p style="font-weight:500;">Знаки, запрещающие и ограничивающие остановку и стоянку</p><br>

<p>Общие характеристики знаков и обозначений.</p><br>

<p style="text-indent:2em;">Эти знаки должны быть круглыми; их диаметр должен быть не менее 0,6 м (2 футов) вне населенных пунктов и не менее
 0,25 м (10 дюймов) в населенных пунктах. При отсутствии в настоящем приложении иных указаний их фонд должен быть
 голубым, а кайма и наклонные полосы - красными.</p><br>

<p style="font-weight:500;">Описание знаков.</p><br>

<p style="text-indent:2em;">1. a) для указания мест, где стоянка запрещена, используется знак C,18 "Стоянка запрещена"; для указания мест,
 где остановка и стоянка запрещены, используется знак C,19 "Остановка и стоянка запрещены";</p><br>

<p style="text-indent:2em;">b) знак C,18 может быть заменен круглым знаком с красной каймой и красной поперечной полосой, на котором
 изображена черной краской на белом или желтом фоне буква P или идеограмма, применяемая в данном государстве
 для обозначения "стоянки";</p>

<p style="text-indent:2em;"></p><h6>c) надписи на дополнительной табличке, помещаемой под знаком, могут ограничивать запрещение или
 указывать, в зависимости от случая:</h6><p></p>

<p>i) дни недели или месяца или время дня, когда действует запрещение;</p><br>

<p style="text-indent:2em;">ii) время, после которого знак C,18 запрещает стоянку, или время, после которого знак С,19
 запрещает остановку и стоянку;</p><br>

<p>iii) исключения, касающиеся некоторых категорий пользователей дороги;</p><br>

<p style="text-indent:2em;">d) надписи относительно продолжительности стоянки или остановки, превышение которой запрещается
, могут помещаться не на дополнительной табличке, а в нижней части красного круга знака.</p><br>

<p style="text-indent:2em;">2. a) когда стоянка разрешена, то на одной, то на другой стороне дороги, вместо знака C,18
 используются знаки C,20 (с индекс. a) или С,20 (с индекс. b) "Попеременная стоянка";</p><br>

<p style="text-indent:2em;">b) запрещение стоянки применяется со стороны знака C,20 (с индекс. a) по нечетным дням и со стороны
 знака C,20 (с индекс. b) по четным дням, причем час замены стороны определяется национальным законодательством
 и им не обязательно должна быть полночь. Национальное законодательство может также определять периодичность
 чередования стоянки, иную, чем ежедневная; в этом случае цифры I и II на знаке заменяются датами, указывающими
 периоды чередования, например 1 - 15 и 16 - 31 для чередования с 1 и с 16 числа каждого месяца;</p><br>

<p style="text-indent:2em;">c) знак C,18 с дополнительными надписями в соответствии с положениями п. 4 ст. 8 настоящей Конвенции может
 использоваться государствами, которые не применяют знаки C,19, C,20 (с индекс. a) и C,20 (с индекс. b).</p><br>

<p style="text-indent:2em;">3. a) за исключением особых случаев, знаки устанавливаются таким образом, чтобы их диск был перпендикулярен
 оси дороги или слегка наклонен по отношению к плоскости, перпендикулярной этой оси;</p><br>

<p style="text-indent:2em;">b) все запрещения и ограничения стоянки применяются только на стороне дороги, на которой установлены знаки;</p><br>

<p style="text-indent:2em;">c) при отсутствии иных указаний, которые могут даваться: либо на дополнительной табличке, соответствующей
 образцу 2 приложения 7, на которой указывается расстояние, в пределах которого применяется запрещение, либо
 в соответствии с предписаниями, содержащимися в подпункте "е" настоящего пункта, запрещения применяются,
 начиная от знака и до ближайшего выхода дороги;</p><br>

<p style="text-indent:2em;">d) под знаком, устанавливаемым в месте, от которого начинает применяться запрещение, может помещаться 
дополнительная табличка, соответствующая образцу 3 (с индекс. a) или 4 (с индекс. a), упомянутому в приложении 7.
 Под знаками, повторяющими запрещение, может помещаться дополнительная табличка, соответствующая образцу 3 (с индекс. b)
 или 4 (с индекс. b), упомянутому в приложении 7. В месте, где прекращается запрещение, может помещаться еще один
 запрещающий знак с дополнительной табличкой, соответствующей образцу 3 (с индекс. c) и 4 (с индекс. c)
 , упомянутому в приложении 7. Таблички образца 3 помещаются параллельно оси дороги, а таблички
 образца 4 - перпендикулярно этой оси. Если на табличках образца 3 указаны расстояния,
 они указывают расстояния, на которых применяется запрещение в направлении,
 указанном стрелкой;</p><br>

<p style="text-indent:2em;">e) если действие запрещения прекращается до ближайшего выхода дороги, устанавливается знак с
 описанной выше в подпункте "d" дополнительной табличкой, на которой указывается конец запрещения.
 Однако когда запрещение применяется лишь на коротком расстоянии, может устанавливаться лишь один
 знак, состоящий: либо из круга красного цвета с указанием расстояния, в пределах которого 
 данное запрещение применяется, либо из дополнительной таблички образца 3.</p><br>

<p style="text-indent:2em;">f) в местах, оборудованных паркометрами, наличие этих паркометров означает, что стоянка является
 платной и что ее продолжительность ограничивается временем работы минутного механизма;</p><br>

<p style="text-indent:2em;">g) в зонах с ограниченной, но не платной стоянкой это ограничение стоянки может обозначаться не
 знаками C,18 с дополнительной табличкой, а синей полосой, наносимой примерно на высоте 2 м на
 расположенных вдоль дороги столбах освещения, деревьях и т.д., или полосами на бордюре проезжей
 части.</p><br>

<p style="text-indent:2em;">4. Для указания в населенных пунктах места въезда в зону, в которой продолжительность стоянки ограничена,
 независимо от того, является ли она платной или бесплатной, может устанавливаться знак C,21 "Зона
 ограниченной стоянки".</p><br>

<p>Фон этого знака, на котором помещается знак C,18 должен быть светлым.</p><br>

<p>Знак C,18 может быть заменен знаком E,23; в этом случае фон знака может быть голубым.</p><br>

<p style="text-indent:2em;">В нижней части таблички может быть изображен дополнительно стояночный диск или паркометр для указания
 условий ограничений стоянки в зоне.</p><br>

<p style="text-indent:2em;">В соответствующих случаях на самом знаке или на дополнительной табличке, помещенной под знаком C,21,
 указываются дни и часы дня, в течение которых действует ограничение, и условия этого
 ограничения.</p>

<p></p><h4>Раздел B</h4><p></p>

<p style="font-weight:500;">Знаки, дающие полезные указания, относящиеся к стоянке</p><br>

<p style="font-weight:500;">1. Знак "Место стоянки".</p><br>

<p style="text-indent:2em;">Знак E,23 "Место стоянки", который может быть установлен параллельно оси дороги, указывает места,
 где стоянка транспортных средств разрешена. Щиток имеет форму квадрата. На нем наносится буква или
 идеограмма, используемая в соответствующем государстве для указания стоянки. Этот знак имеет голубой
 фон.</p><br>

<p style="text-indent:2em;">На дополнительной табличке, помещаемой под знаком или на самом знаке, могут находиться обозначения или надписи,
 указывающие направление к месту стоянки или категории транспортных средств, для которых это место 
 предназначено; эти надписи могут также ограничивать продолжительность разрешенной 
 стоянки.</p><br>

<p style="font-weight:500;">2. Знак, указывающий выезд из зоны ограниченной стоянки.</p><br>

<p style="text-indent:2em;">Для указания в населенных пунктах мест выезда из зоны, в которой продолжительность стоянки ограничена
 и въезд в которую обозначен знаком C,21, в который включен знак C,18, используется знак E,24; этот 
 знак состоит из светлого квадрата, в который включен знак C,18 светло-серого цвета и 
 диагональная черная или темно-серая полоса, либо серые или черные параллельные линии, 
 образующие такую полосу. Если у въезда в зону устанавливаются знаки C,21, в которые 
 включен знак E,23, выезды могут обозначаться табличкой с диагональной черной или 
 темно-серой полосой, либо серыми или черными параллельными линиями, образующими 
 такую полосу, и стояночным диском на светлом фоне.</p>

<p></p><h4>Приложение 7 к Конвенциио дорожных знаках и сигналах</h4><p></p>

<p>(Вена, 8 ноября 1968 г.)</p><br>

<p style="font-weight:500;">Дополнительные таблички</p><br>

<p style="text-indent:2em;">1. Эти таблички имеют белый или желтый фон и черную, синюю или красную кайму, а расстояние или длина обозначаются
 в этом случае черными или синими цифрами, или же таблички имеют черный или синий фон и белую, 
 желтую или красную кайму, а расстояние или длина обозначаются в этом случае белыми 
 или желтыми цифрами.</p><br>

<p style="text-indent:2em;">2. a) дополнительные таблички "Образца 1" указывают расстояние между знаком и началом 
опасного участка или зоны, в которой применяются правила;</p><br>

<p style="text-indent:2em;">b) дополнительные таблички "Образца 2" указывают длину опасного участка или зоны, 
в которой применяется предписание;</p><br>

<p style="text-indent:2em;">c) дополнительные таблички помещаются под знаками. Однако, что касается предупреждающих 
знаков образца A (с индекс. b), то предусмотренные для дополнительных табличек обозначения 
могут также помещаться в нижней части знака.</p><br>

<p style="text-indent:2em;">3. Дополнительными табличками "Образца 3" и "Образца 4", касающимися запрещения или 
ограничения стоянки, являются соответственно таблички образца 3 (с индекс. a), 3 
(с индекс. b), 3 (с индекс. c), 4 (с индекс. a), 4 (с индекс. b) и 4 (с индекс. c) 
(см. п. 3 раздела А приложения 6).</p>

<p></p><h4>Приложение 8 к Конвенциио дорожных знаках и сигналах</h4><p></p>

<p>(Вена, 8 ноября 1968 г.)</p><br>

<p style="font-weight:500;">Разметка дорог</p>

<p></p><h4>Глава I</h4><p></p>

<p style="font-weight:500;">Общие положения</p><br>

<p style="text-indent:2em;">1. Разметка на проезжей части дороги (разметка дорог) должна наноситься с применением 
материалов, не вызывающих скольжения, и не должна выступать более чем на 6 мм над 
уровнем дороги. Если для разметки дороги применяются кнопки (вставки) или другие 
подобные им приспособления, они не должны выступать более чем на 1,5 см над уровнем 
дороги (или более чем на 2,5 см в тех случаях, когда применяются светоотражающие кнопки 
(вставки); их использование должно отвечать требованиям безопасности дорожного движения.</p>

<p></p><h4>Глава II</h4><p></p>

<p style="font-weight:500;">Продольная разметка</p><br>

<p style="font-weight:500;">A. Размеры обозначений.</p><br>

<p>2. Ширина сплошных и прерывистых линий при продольной разметке должна быть не менее 
0,10 м (4 дюймов).</p><br>

<p style="text-indent:2em;">3. Расстояние между двумя проведенными рядом продольными линиями (двойная линия) 
должно составлять 0,10 м (4 дюймов) до 0,18 м (7 дюймов).</p><br>

<p style="text-indent:2em;">4. Прерывистая линия состоит из черт равной длины, разделенных одинаковыми промежутками. 
При определении длины черт и промежутков между ними должна приниматься во внимание скорость 
транспортных средств на данном участке дороги или в данной зоне.</p><br>

<p style="text-indent:2em;">5. Вне населенных пунктов прерывистая линия должна состоять из черт длиной от 2 м (6 футов 
6 дюймов) до 10 м (32 футов); длина черт линии, обозначающей приближение, указанной в п. 23 
настоящего приложения, должна в 2 - 3 раза превышать длину интервалов.</p><br>

<p style="text-indent:2em;">6. В населенных пунктах длина черт и промежутки между ними должны быть меньше, чем длина черт
 и промежутков, применяемых для обозначений вне населенных пунктов. Длина черт может быть 
 уменьшена до 1 м (3 футов 4 дюймов). Однако на некоторых больших городских скоростных 
 магистралях характеристики продольной разметки могут быть теми же, что и вне населенных 
 пунктов.</p><br>

<p style="font-weight:500;">B. Обозначение полос движения.</p><br>

<p style="text-indent:2em;">7. Разметка полос движения производится либо прерывистыми, либо сплошными линиями, либо 
другими соответствующими средствами.</p><br>

<p>i) Вне населенных пунктов.</p><br>

<p style="text-indent:2em;">8. На дорогах с двусторонним движением, имеющих две полосы движения, осевая линия 
проезжей части должна обозначаться продольной разметкой. Осевая линия, как правило, 
обозначается прерывистой линией. В исключительных случаях с этой целью применяется 
сплошная линия.</p><br>

<p style="text-indent:2em;">9. На дорогах с тремя полосами движения полосы на участках с нормальной видимостью должны, 
как правило, быть указаны прерывистыми линиями. В некоторых отдельных случаях для повышения
 безопасности движения могут применяться сплошные линии или же прерывистые линии, 
 проведенные рядом со сплошными линиями.</p><br>

<p style="text-indent:2em;">10. На дорогах, имеющих более трех полос движения, разделительная линия направлений 
движения должна обозначаться сплошной линией или двойной сплошной линией, за исключением
 случаев, когда направление движения на центральных полосах может быть изменено. Кроме
 того, полосы движения должны обозначаться прерывистыми линиями (диаграммы 1a и 1b).</p><br>

<p>ii) В населенных пунктах.</p><br>

<p style="text-indent:2em;">11. В населенных пунктах рекомендации, изложенные в пп. 8 - 10 настоящего приложения,
 распространяются на улицы с двусторонним движением и на улицы с односторонним движением,
 имеющие не менее двух полос движения.</p><br>

<p style="text-indent:2em;">12. Полосы движения должны обозначаться в тех пунктах, где ширина проезжей части дороги 
ограничена вследствие наличия бордюров, островков безопасности или направляющих островков.</p><br>

<p style="text-indent:2em;">13. Около больших перекрестков (особенно регулируемых), где ширина дороги достаточна для 
движения транспортных средств в два или несколько рядов, полосы движения должны 
обозначаться согласно диаграммам 2 и 3. В этих случаях линии, разграничивающие 
полосы, могут быть дополнены стрелами (см. п. 39 настоящего приложения).</p><br>

<p style="font-weight:500;">C. Обозначение особых случаев.</p><br>

<p>i) Применение сплошных линий.</p><br>

<p style="text-indent:2em;">14. В целях повышения безопасности дорожного движения на некоторых перекрестках осевые прерывистые
 линии (диаграмма 4) следует заменять или дополнять сплошной линией (диаграммы 5 и 6).</p><br>

<p style="text-indent:2em;">15. Если возникает необходимость запретить использование части дороги, предназначенной для движения
 в обратном направлении, в тех местах, где расстояние видимости сокращено (на переломах дорог, 
 на поворотах и т.д.), или на тех участках, где проезжая часть дороги сужается или имеет 
 какие-либо другие особенности, ограничения, предписываемые для тех участков дороги, где 
 расстояние видимости ниже минимального расстояния видимости М, должны обозначаться сплошной 
 линией, нанесенной в соответствии с диаграммами 7а - 16. (2) В странах, где это соответствует 
 конструкции транспортных средств, указанная на диаграммах 7а - 10a высота на уровне глаза, 
 равная 1 м, может быть увеличена до 1,20 м.</p><br>

<p style="text-indent:2em;">16. Величина, которую следует принять для М, меняется в зависимости от характеристик дороги. На диаграммах
 7а, 7b, 8a, 8b, 8c и 8d показано нанесение линий соответственно для дорог с двумя и тремя полосами 
 движения на переломе дороги, где расстояние видимости ограничено. Эти диаграммы соответствуют 
 продольному профилю, показанному в верхней части страницы, на которой они находятся, и расстоянию 
 М, определенному в соответствии с нижеследующим п. 24: A (или D) является пунктом, где расстояние 
 видимости становится меньше М, в то время как С (или В) является пунктом, где расстояние видимости
 становится снова больше М .(3)</p><br>

<p style="text-indent:2em;">17. В тех случаях, когда отрезки АВ и CD перекрывают друг друга, т.е. в тех случаях, когда видимость 
в обоих направлениях больше расстояния видимости М до того, как будет достигнут перелом дороги, 
линии должны располагаться тем же способом, причем сплошные линии, нанесенные рядом с прерывистой 
линией, не соприкасаются, как это изображено на диаграммах 9, 10а и 10b.</p><br>

<p style="text-indent:2em;">18. Диаграммы 11а и 11b указывают обозначение линий при той же гипотезе на изогнутом участке 
дороги с двумя полосами движения при ограниченной видимости.</p><br>

<p style="text-indent:2em;">19. На дорогах с тремя полосами движения возможно применение двух методов. Они указаны на 
диаграммах 8a, 8b, 8с и 8d (или, в зависимости от случая, l0a и l0b). Диаграмма 8а или 8b (
или, в зависимости от случая, 10а) должна применяться к дорогам со значительным движением 
двухколесных транспортных средств, а диаграмма 8c и 8d (или, в зависимости от случая, 
10b) - к дорогам с движением главным образом четырехколесных транспортных средств. 
Диаграмма 11с указывает обозначение линий при той же гипотезе на изогнутом участке 
дороги с тремя полосами движения при ограниченной видимости.</p><br>

<p>20. На диаграммах 12, 13 и 14 показаны линии, обозначающие сужение проезжей части 
дороги.</p><br>

<p style="text-indent:2em;">21. На диаграммах 8а, 8b, 8c, 8d, 10а и 10b наклон косых переходных линий по 
отношению к осевой линии не должен быть больше 1/20.</p><br>

<p style="text-indent:2em;">22. На диаграммах 13 и 14, иллюстрирующих разметку при изменении ширины проезжей 
части дороги, а также на диаграммах 15, 16 и 17, указывающих препятствия, требующие 
отклонения сплошной(ых) линии(й), этот наклон линии или линий должен быть 
по преимуществу меньше 1/50 на дорогах с большой скоростью движения 
и меньше 1/20 на дорогах, где скорость движения не превышает 
50 км/ч (30 миль/ч). Кроме того, сплошным наклонным линиям должна 
предшествовать для того направления движения, к которому они 
применяются, сплошная линия, параллельная оси проезжей части 
дороги и по длине соответствующая расстоянию, проходимому 
автомобилем в 1 сек при средней скорости движения.</p><br>

<p style="text-indent:2em;">23. В случае, когда нет необходимости обозначать полосы движения прерывистыми 
линиями на обычном участке дороги, сплошной линии должна предшествовать 
обозначающая приближение линия, представляющая собой прерывистую линию 
на расстоянии, зависящем от нормальной скорости транспортных средств, 
т.е. не менее 50 м. В случае, когда полосы движения обозначаются 
прерывистыми линиями на обычном участке дороги, сплошной линии 
также должна предшествовать обозначающая приближение линия на 
расстоянии, зависящем от нормальной скорости транспортных 
средств, т.е. не менее 50 м. Разметка может дополняться 
стрелой или несколькими стрелами, предписывающими водителям 
ту полосу движения, по которой они должны следовать.</p><br>

<p>ii) Условия применения сплошных линий.</p>

<p style="text-indent:2em;"></p><h6>24. Выбор расстояния видимости, которое должно быть принято при 
установлении участков дороги, на которых сплошная линия желательна 
или нежелательна, а также определение надлежащей длины этой линии 
неизбежно является результатом компромисса. Приведенная ниже 
таблица дает рекомендуемую для М величину, соответствующую 
различным скоростям приближения (4):</h6><p></p>

<p style="text-indent:2em;">25. Для скоростей, не указанных в этой таблице, соответствующая 
величина М должна вычисляться с помощью интерполяции или 
экстраполяции.</p><br>

<p style="font-weight:500;">D. Граничные линии, обозначающие границы проезжей части дороги.</p><br>

<p style="text-indent:2em;">26. Разметка линий, обозначающих границы проезжей части дороги, 
состоит преимущественно из сплошной линии. В сочетании с этими 
линиями могут применяться плитки, кнопки или отражатели света.</p><br>

<p style="font-weight:500;">Е. Обозначение препятствий.</p><br>

27. На диаграммах 15, 16 и 17 изображены примеры разметки, 
которая должна наноситься около островков или других 
препятствий, находящихся на проезжей части дороги.<p></p><br>

<p style="font-weight:500;">F. Линии поворота.</p><br>

<p style="text-indent:2em;">28. На некоторых перекрестках желательно указывать водителям, 
как должен производиться левый поворот в странах с 
правосторонним движением или как должен производиться 
правый поворот в странах с левосторонним движением.</p>

<p></p><h4>Глава III</h4><p></p>

<p style="font-weight:500;">Поперечная разметка</p><br>

<p style="font-weight:500;">А. Общие положения.</p><br>

<p style="text-indent:2em;">29. С учетом угла зрения, под которым водителям видна разметка 
дороги, поперечные обозначения должны быть шире, чем продольные.</p><br>

<p style="font-weight:500;">В. Стоп-линии.</p><br>

<p style="text-indent:2em;">30. Минимальная ширина стоп-линии должна составлять 0,2 м 
(8 дюймов), а максимальная ширина - 0,6 м (24 дюйма). 
Рекомендуется ширина в 0,3 м (12 дюймов).</p><br>

<p style="text-indent:2em;">31. В том случае, когда эта линия применяется в сочетании 
с дорожным знаком, обозначающим остановку, стоп-линия должна 
быть нанесена таким образом, чтобы водитель, остановившийся 
непосредственно перед ней, мог, по возможности, свободно 
наблюдать движение на других подъездах к перекрестку; при 
этом должны приниматься во внимание требования, вызываемые 
движением других транспортных средств и пешеходов.</p><br>

<p style="text-indent:2em;">32. Стоп-линии могут быть дополнены продольными линиями 
(см. диаграммы 18 и 19). Они могут быть дополнены также 
словом "СТОП", обозначенным на проезжей части дороги; 
примеры такой разметки даны на диаграммах 20 и 21. Расстояние
 между верхней кромкой букв слова "СТОП" и стоп-линией должно
 составлять не менее 2 м (6 футов 7 дюймов) и не более 25 м (
 82 футов 2 дюймов).</p><br>

<p style="font-weight:500;">C. Линии, обозначающие место, где водители обязаны уступать
 дорогу.</p><br>

<p style="text-indent:2em;">33. Минимальная ширина каждой линии должна составлять 0,2 м 
(8 дюймов), а максимальная ширина - 0,6 м (24 дюйма); если имеются
 две линии, расстояние между ними должно составлять не менее 0,3 м
 (12 дюймов). Линия может быть заменена расположенными рядом на дороге
 треугольниками, вершина которых направлена в сторону водителя, который
 обязан уступить дорогу. Эти треугольники должны иметь основание не 
 менее 0,4 м (16 дюймов) и не более 0,6 м (24 дюймов) и высоту не 
 менее 0,5 м (24 дюймов) и не более 0,7 м (28 дюймов).</p><br>

<p style="text-indent:2em;">34. Поперечная разметка должна наноситься в таких же условиях,
 что и стоп-линии, упомянутые в п. 31 настоящего приложения.</p><br>

<p style="text-indent:2em;">35. Указанная в п. 34 разметка может дополняться треугольником,
 который наносится на проезжей части дороги и пример которого
 дается на диаграмме 22. Расстояние между основанием этого 
 треугольника и поперечной разметкой должно составлять от 
 2 м (6 футов 7 дюймов) до 25 м (82 футов 2 дюймов). 
 Основание этого треугольника должно быть не менее 1 м 
 (3 футов 4 дюймов); его высота должна быть в 3 раза
 больше его основания.</p><br>

<p>36. Эта поперечная разметка может дополняться продольными 
линиями.</p><br>

<p style="font-weight:500;">D. Пешеходные переходы.</p><br>

<p style="text-indent:2em;">37. Расстояние между полосами, которыми обозначен пешеходный
 переход, должно быть по меньшей мере равным ширине этих 
 полос и не превышать ее более чем в 2 раза. Общая ширина
 одной полосы и промежутка между полосами должна составлять
 от 1 м (3 футов 4 дюймов) до 1,4 м (4 футов 8 дюймов). 
 Рекомендуемая минимальная ширина пешеходных переходов должна
 быть 2,5 м (8 футов) на дорогах, где скорость ограничивается
 60 км/ч, и 4 м (13 футов) на дорогах, где допускается более
 высокая скорость или где скорость не ограничивается.</p><br>

<p style="font-weight:500;">E. Переезды для велосипедистов.</p><br>

<p style="text-indent:2em;">38. Переезды для велосипедистов должны обозначаться двойными
 прерывистыми линиями. Эти прерывистые линии должны составлять
 по преимуществу из квадратов (0,4 - 0,6 м)Х(0,4 - 0,6 м)[(16 - 24 дюйма)Х(16 - 24 дюйма)].</p><br>
 
<p style="text-indent:2em;">Расстояние между этими квадратами должно составлять 0,4 0,6
 м (16 - 24 дюйма). Ширина переезда должна быть не меньше 1,8
 м (6 футов). Употребление плиток и кнопок не рекомендуется.</p>

<p></p><h4>Глава IV</h4><p></p>

<p style="font-weight:500;">Другие виды разметки</p><br>

<p style="font-weight:500;">A. Стрелы.</p><br>

<p style="text-indent:2em;">39. На дорогах с достаточным количеством полос движения, 
позволяющих перестроение транспортных средств при приближении
 к перекрестку, полосы, которые должны быть использованы для движения,
 могут указываться при помощи стрел, наносимых на поверхность проезжей части
 дороги (диаграммы 2, 3, 19 и 23). Стрелы могут также применяться на дорогах
 с односторонним движением для подтверждения направления движения
 . Длина этих стрел должна быть не менее 2 м (6 футов 7 дюймов).
 Обозначение стрел может быть дополнено надписями на проезжей части.</p><br>

<p style="font-weight:500;">B. Параллельные косые линии.</p><br>

<p>40. На диаграммах 24 и 25 приводятся примеры зон, в которые
 транспортным средствам въезд запрещен.</p><br>

<p style="font-weight:500;">C. Надписи.</p><br>

<p style="text-indent:2em;">41. Надписи на проезжей части дороги могут применяться с целью
 регулирования движения, предупреждения или ориентировки пользователей
 дороги. Употребляемые при этом слова должны быть предпочтительно либо
 названиями населенных пунктов, либо номерами дорог, либо словами, 
 общепринятыми в международном плане (например: "stop", "bus", "taxi").</p><br>

<p style="text-indent:2em;">42. Буквы должны быть значительно вытянуты по направлению движения 
вследствие очень малого угла зрения, под которым водителям видны 
надписи (см. диаграмму 20).</p><br>

<p style="text-indent:2em;">43. В тех случаях, когда скорость приближения превышает 50 км/ч 
(30 миль/ч), минимальная длина букв должна составлять 2,5 м 
(8 футов).</p><br>

<p style="font-weight:500;">D. Обозначения, касающиеся остановки и стоянки.</p><br>

<p style="text-indent:2em;">44. Ограничения остановки и стоянки могут быть указаны разметкой на бордюрах
 или на проезжей части, границы мест стоянки могут обозначаться 
 соответствующими линиями, проведенными по поверхности проезжей 
 части дороги.</p><br>

<p style="font-weight:500;">E. Разметка на проезжей части дороги и на примыкающих к 
ней сооружениях.</p><br>

<p>i) Разметка, указывающая места, где стоянка транспортных 
средств ограничена.</p><br>

<p>45. На диаграмме 26 дается пример зигзагообразной линии.</p><br>

<p>ii) Обозначения на предметах, представляющих собой препятствие.</p><br>

<p>46. Пример обозначений на предметах, представляющих собой препятствие,
 приведен на диаграмме 27.</p><br>

<p style="text-indent:2em;">(1) В соответствующих местах отпечатанного типографским способом текста
 Конвенции могут быть изображены сигнальные знаки, обозначения и 
 таблички.</p><br>

<p style="text-indent:2em;">(2) Под указанным в настоящем пункте расстоянием видимости подразумевается
 расстояние, на котором предмет, установленный на высоте 1 м (3 футов 4 
 дюймов) над уровнем проезжей части дороги, виден находящемуся на проезжей
 части наблюдателю, глаз которого также находится на высоте 1 м (3 футов 4
 дюймов) над уровнем проезжей части.</p><br>

<p style="text-indent:2em;">(3) Разметка, изображенная на диаграммах 7, может быть заменена между А и
 D одной сплошной осевой линией без нанесения рядом прерывистой линии. Ей
 может предшествовать прерывистая осевая линия, состоящая по крайней мере
 из трех черт. Однако это упрощенное обозначение должно применяться с
 осторожностью и только в исключительных случаях, поскольку на 
 определенном расстоянии оно мешает водителю производить обгон, 
 тогда как имеется соответствующее расстояние видимости. Во 
 избежание недоразумений следует по мере возможности избегать
 применения обоих методов на одном и том же маршруте или на 
 однотипных маршрутах в одном и том же районе.</p><br>

<p style="text-indent:2em;">(4) Скорость приближения, принятая для этого расчета, - это 
скорость движения, которую не превышают 85% транспортных средств,
 или основная скорость, если она является более высокой.</p><br>
 
 <p><a href="http://docs.cntd.ru/document/1900882">Источник</a></p>

</div>
`