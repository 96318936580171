import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import _ from 'lodash'
import classnames from 'classnames'

import config from '../../../../config'
import ProfileMini from '../../../user/profile-mini'
import SliderModal from '../../../UI/slider-modal'
import Spinner from '../../../UI/spinner'
import fetch from '../../../shared/fetch'
import { canDo } from '../../../../store/utils'
import Banner from '../../../banner'
import { BANNER_THIN_SIZE } from '../../../../store/constants'
import { AdsItemProps, AdsItemState } from './types'
import { DelayTimer } from '../../ads-table/types'
import { getUrlParamsString } from '../../../../store/utils'
import ruWordNumeralEnding from '../../../shared/ruWordNumeralEnding'

import './styles.scss'

const STYLE_BASE: string = 'ads-item__'

export default class extends Component<AdsItemProps, AdsItemState> {
  timerWorker: DelayTimer

  constructor(props) {
    super(props)

    this.state = {
      itemId: +this.props.match.params.itemId,
      adsItem: {
        title: '',
        id: 0,
        text: '',
        date: '',
        views: 0,
        tree_type_name: '',
        sub_tree_type_name: '',
        tree_type_id: null,
        user_name: '',
        user_last_name: '',
        user_id: 0,
        photos: [],
        user_active_ads: 0,
        company_active_ads: 0,
        is_favourite: 0,
        company_id: null
      },
      showConfirm: false,
      isLoading: true,
      isOpenModal: false,
      initialSlide: 0,
      pageSize: 10,
      pageIndex: 0,
      isFavState: false,
      timers: []
    }


    this.timerWorker = new DelayTimer({
      timerUpdate: (timerId, timeRemaining) => {
        const { timers } = this.state
        timers[timerId] = timeRemaining
        this.setState({ timers })

      },
      timerEnd: timerId => {
        this.fetch()
      },
    })
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    this.timerWorker.cancelAllTimers()
  }

  componentDidUpdate(prevProps) {
    const { itemId: newItemId } = this.props.match.params
    const { itemId: prevItemId } = prevProps.match.params
    if (newItemId !== prevItemId) {
      this.setState({ isLoading: true })
      this.fetchItem(+newItemId)
    }
  }

  fetch = () => {
    const { itemId } = this.state
    this.fetchItem(itemId)
  }

  fetchItem = (itemId: number) => {
    const { userId, langId } = this.props
    const { pageSize, pageIndex } = this.state

    fetch(`/announcement/item/${itemId}`, {
      method: 'POST',
      body: JSON.stringify({
        adId: itemId,
        page_index: pageIndex,
        page_size: pageSize,
        active: 1,
        lang_id: langId,
        user_id: userId || 0,
    })}).then(adsItem => {
      if (adsItem.viewTimeout > 0) {
        this.timerWorker.setTimer(Number(adsItem.id), null, adsItem.viewTimeout)
      }
      this.setState({ adsItem, isLoading: false, isFavState: adsItem.is_favourite })
    })
  }

  toggleAdFavourite = (adId: number, adIsFavourite?: number): void => {
    const body = JSON.stringify({ adId, alreadyFavourite: adIsFavourite })
    fetch('/announcement/toggleFavourite', { method: 'POST', body }).then(_ => {
      this.setState({ isFavState: !this.state.isFavState })
    })
  }

  deleteAd = () => {
    const { itemId } = this.state
    const { t } = this.props
    this.setState({ showConfirm: true })
    confirmAlert({
      title: t('delete.confirm'),
      message: (
        <span>
          {t('delete.sure')}
          <br />
          {t('delete.cannot.undone')}
        </span>
      ) as any,
      buttons: [
        {
          label: t('delete'),
          onClick: () => {
            fetch(`/announcement/item/${itemId}`, { method: 'DELETE' }).then(_ =>
              this.props.history.goBack()
            )
          },
        },
        {
          label: t('button.close'),
          onClick: this.hideConfirm,
        },
      ],
      onClickOutside: this.hideConfirm,
      onKeypressEscape: this.hideConfirm,
    })
  }

  hideConfirm = () => {
    this.setState({ showConfirm: false })
  }

  printAd = () => {
    const {
      adsItem: { title, tree_type_name, sub_tree_type_name, text, date, user_name, user_last_name },
    } = this.state
    const { t } = this.props
    const printWindow = window.open('', '', 'height=500, width=500')
    printWindow.document.write('<html>')
    printWindow.document.write('<body>')
    printWindow.document.write(`${title}<br>`)
    printWindow.document.write(`${t('ads')}>${tree_type_name}`)
    if (sub_tree_type_name) printWindow.document.write(`>${sub_tree_type_name}`)
    printWindow.document.write('<br>')
    printWindow.document.write(`${t('added')}: ${date}<br>`)
    printWindow.document.write(`${t('author')}: ${user_name} ${user_last_name}<br>`)
    printWindow.document.write(`<p style="word-wrap: break-word;">${text}</p>`)
    printWindow.document.write('</body></html>')
    printWindow.document.close()
    printWindow.print()
  }

  changeViewModal = photoId => {
    this.setState({
      isOpenModal: !this.state.isOpenModal,
      initialSlide: photoId,
    })
  }

  contentTimer = (itemId) => {
    const { t, langId } = this.props
    const { timers } = this.state
    const timeRemaining = timers[itemId]?.toFixed(0)
    return (

      <div className="timeout-remaining">
        {t('time.ads.restriction.wait.first', { time: timeRemaining })}
        {` `}{ruWordNumeralEnding(
          t,
          parseInt(timeRemaining),
          'time.restriction.wait.second',
          langId
        )}
      </div>
    )
  }

  render() {
    const {
      itemId,
      adsItem,
      isLoading,
      isOpenModal,
      initialSlide,
      adsItem: {
        title,
        id,
        text,
        date,
        views,
        tree_type_name,
        sub_tree_type_name,
        tree_type_id,
        user_id: postAuthor,
        photos = [],
        user_active_ads,
        company_active_ads,
        company_id,
        is_favourite,
        viewTimeout
      },
      isFavState,
    } = this.state
    const { t, langId, permissions, userId } = this.props
    const canEdit = canDo(permissions, 'ad', 'edit') || userId === postAuthor
    const canDelete = canDo(permissions, 'ad', 'delete') || userId === postAuthor
    const filteredPhotos = photos.filter(photo => photo)

    if (isLoading) return <Spinner />
    
    return (
      <div className={`${STYLE_BASE}wrapper`}>
        {!_.isEmpty(adsItem) ? (
          <Fragment>
            <div className="item-container table-ads">
              <div className="links-wrapper">
                <Link to="/ads/list/436">{t('ads.name')}</Link> {'>'}
                <Link to={`/ads/list/${tree_type_id}`}> {tree_type_name}</Link>
                {sub_tree_type_name && (
                  <a href="#">
                    {' '}
                    {'>'} {sub_tree_type_name}
                  </a>
                )}
              </div>
              <div className="item-content">
                <div className="item-container-links-wrapper">
                  <h4 className="transportads-title col-md-14">{title}</h4>
                  <div className="icons-links col-md-2">
                    {userId && (
                      <span
                        className={classnames('favorite', isFavState && 'is-active')}
                        onClick={() => this.toggleAdFavourite(id, isFavState && is_favourite)}
                      >
                        <i className="fa fa-bookmark"></i>
                      </span>
                    )}
                    <i className="fa fa-print" onClick={this.printAd}></i>
                  </div>
                </div>
                <div className="suplimentar-information">
                  <div className="left-panel-info">
                    <span>
                      <i className="fa fa-calendar" />
                      <span> {moment(date).format('DD.MM.YY')}</span>
                      <i className="fa fa-clock-o" />
                      <span className="clock-time"> {moment(date).format('HH:mm')} </span>
                      {t('views')}: {views}
                    </span>
                  </div>
                  <div className="right-links">
                    {canEdit && (
                      <Link to={`/ads/edit/${itemId}`}>
                        <span className="edit-link">{t('edit')}</span>
                      </Link>
                    )}
                    {canDelete && (
                      <span className="delete-link" onClick={this.deleteAd}>
                        {t('delete')}
                      </span>
                    )}
                  </div>
                </div>
                {filteredPhotos.length !== 0 ? (
                  <div className="panel-content-photo" id="ads-item-photos">
                    <div className="panel-form-photo">
                      <SliderModal
                        photos={filteredPhotos}
                        isOpen={isOpenModal}
                        onExit={this.changeViewModal}
                        initialSlide={initialSlide}
                        imageFolder={`announcement/${postAuthor}`}
                      />
                      {filteredPhotos.map((photo, index) => (
                        <div className="col" key={index}>
                          <div
                            className="photo-block"
                            style={{
                              backgroundImage: `url(${(config as any).apiUrl
                                }/common/file/announcement/${postAuthor}/${photo})`,
                            }}
                            onClick={() => this.changeViewModal(index)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="item-container-description">
                  <p>{text}</p>
                </div>
              </div>
            </div>
            <Banner bannerId={576} />
            {viewTimeout > 0 ?
              this.contentTimer(id) :
              <ProfileMini customUserId={postAuthor} showFullInfo/>}

            <div className="btns-template clearfix">
              <div className="user-ads-links">
                {user_active_ads > 0 && (
                  <Link to={`/user/details/${postAuthor}/ads`}>
                    {t('ads.user.all', { number: user_active_ads })}
                  </Link>
                )}
                {company_active_ads > 0 && company_id &&  (
                  <Link to={`/company/item/${company_id}/ads`} id="company-ads-link">
                    {t('ads.company.all', { number: company_active_ads })}
                  </Link>
                )}
              </div>
              <div>
                <Link to="/ads/add-ads">
                  <button className="button button-orange fl-right" id="add">
                    {t('ads.add.own.add')}
                  </button>
                </Link>
              </div>
            </div>
          </Fragment>
        ) : (
          <h1 className="not-found-message">{t('ads.not.found.or.deleted')}</h1>
        )}
      </div>
    )
  }
}
