import fetch from '../../components/shared/fetch'
import {
  SUMMARY_GET_INTRANATIONAL_START,
  SUMMARY_GET_INTERNATIONAL_START,
  SUMMARY_GET_INTRANATIONAL,
  SUMMARY_GET_INTERNATIONAL,
  SUMMARY_CHANGE_FAVORITE,
} from './action-types'

export const getSummaryTable = payload => {
  const { userId, langId, shippingType, isLocal, isBlocked, isFavorites } = payload
  return async dispatch => {
    const type = isLocal ? SUMMARY_GET_INTRANATIONAL_START : SUMMARY_GET_INTERNATIONAL_START
    dispatch({ type })
    fetch('/summary/getTableInfo', {
      method: 'POST',
      body: JSON.stringify({
        userId,
        langId,
        shippingType,
        isLocal,
        isBlocked,
        isFavorites,
      }),
    }).then(item => {
      const type = isLocal ? SUMMARY_GET_INTRANATIONAL : SUMMARY_GET_INTERNATIONAL
      dispatch({ type, payload: item.d })
    })
  }
}

export const changeSummaryFavorite = payload => {
  const { countryId, isFavorite } = payload
  return async dispatch => {
    fetch('/summary/changeFavorite', {
      method: 'POST',
      body: JSON.stringify({
        countryId,
        isFavorite,
      }),
    }).then(() => dispatch({ type: SUMMARY_CHANGE_FAVORITE, payload: countryId }))
  }
}
