import { connect } from 'react-redux'
import { compose } from 'recompose'

import Component from './component'

import withTranslation from '../../../globals/components/withTranslation'
import withPermissions from '../../../globals/components/withPermissions'
import { loadComments, deleteComment } from '../../../store/actions/news'

export default compose(
  withTranslation,
  withPermissions,
  connect(
    state => {
      const comments = state.news.comments
      return {
        comments,
      }
    },
    {
      loadComments,
      deleteComment,
    }
  )
)(Component)
