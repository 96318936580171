import React from 'react'

import AddForm from './form'
import News from '../../news/list-mini'
import Ads from '../../ads/list-mini'
import Forum from '../../forum'
import Banner from '../../banner'
import { BANNER_THIN_SIZE } from '../../../store/constants'

const AddTransport = props => {
  return (
    <div className="row">
      <div className="col-md-10 col-xl-9">
        <AddForm {...props} />
      </div>
      <div className="col-md-6 col-xl-7">
        <News />
        <Banner bannerId={536} />
        <Ads />
        <Forum />
      </div>
    </div>
  )
}

export default AddTransport
