export const md2 = `
<div class="panel-content-row" style="text-align:justify;"><!--начало закона -->
<p></p><h4>CODUL CIVIL AL REPUBLICII MOLDOVA.</h4><p></p>

<p></p><h4>Capitolul XII</h4><p></p>

<p></p><h4>TRANSPORTUL</h4><p></p>

<p></p><h5>Secţiunea 1</h5><p></p>

<p></p><h5>Dispoziţii generale
cu privire la transport</h5><p></p>

<p style="font-weight:500;">Articolul 1411. Contractul de transport</p><br>

<p style="text-indent:2em;">(1) Prin contractul de transport, o parte (transportator) se obligă faţă de cealaltă parte (pasager sau expeditor) să o transporte împreună cu bagajele ei sau, respectiv, să transporte bunul la locul de destinaţie, iar
 cealaltă parte se obligă să plătească remuneraţia convenită.</p><br>

<p style="text-indent:2em;">(2) Transportarea gratuită a persoanei sau a bunului, cu excepţia cazului cînd este efectuată în activitatea de întreprinzător de persoana care oferă public servicii de transport, nu este guvernată de regulile prevăzute de
 prezentul capitol şi transportatorul poartă doar o obligaţie de prudenţă şi diligenţă.</p><br>

<p style="text-indent:2em;">(3) Dispozițiile prezentului capitol nu se aplică transportului care cade sub incidența Codului
 navigației maritime comerciale al Republicii Moldova.</p><br>
 
<p style="text-indent:2em;">(4) Dispozițiile legale privind prestările de servicii se aplică contractului de transport în măsura în
 care nu contravin dispozițiilor privind contractul de transport.</p><br>
 
<p style="font-weight:500;">Articolul 1412. Obligaţia de a încheia contract de transport</p><br>

<p style="text-indent:2em;">Persoana care face publică oferta de transport de persoane şi bunuri este obligată să încheie un contract
 de transport, cu excepţia cazurilor în care există un motiv serios de a refuza.</p><br>

<p style="font-weight:500;">Articolul 1413. Transportul succesiv şi transportul combinat</p><br>

<p style="text-indent:2em;">(1) Transport succesiv este transportul efectuat de mai mulţi transportatori
 care se succed utilizînd acelaşi mod de transport (vehicul).</p><br>

<p>(2) Transport combinat este transportul în care transportatorii se succed
 utilizînd diferite moduri de transport (vehicule).</p><br>

<p style="font-weight:500;">Articolul 1414. Substituirea transportatorului</p><br>

<p style="text-indent:2em;">(1) În cazul cînd transportatorul transmite, total sau parţial, executarea obligaţiilor sale, persoana
 care îl substituie este considerată parte a contractului de transport.</p><br>

<p>(2) Plata făcută de pasager sau expeditor unuia dintre
 transportatori este liberatorie.</p><br>

<p style="font-weight:500;">Articolul 1415. Termenul transportării pasagerului sau a bunului</p><br>

<p style="text-indent:2em;">(1) Transportatorul este obligat să transporte pasagerul şi bagajele sau bunul în termenele stabilite de lege sau de contract,
 iar în lipsa unor astfel de termene, intr-un termen rezonabil.</p><br>

<p>(2) Transportarea trebuie să fie efectuată pe calea cea mai scurtă şi rezonabilă.</p><br>

<p style="font-weight:500;">Articolul 1416. Taxa de transport</p><br>

<p style="text-indent:2em;">(1) Pentru transportarea pasagerului şi bagajelor sau a bunului, se plăteşte taxa de transport
 convenită prin acordul părţilor dacă legea nu prevede altfel.</p><br>

<p style="text-indent:2em;">(2) Taxa de transport se plăteşte pînă la transportarea pasagerului şi bagajului
 sau a bunului dacă legea sau contractul nu prevede altfel.</p><br>

<p>(3) Transportatorul are dreptul de retenţie asupra bagajului şi bunului pînă la achitarea taxei de transport.</p>

<p></p><h5>Secţiunea a 2-a</h5><p></p>

<p></p><h5>Transportul de persoane</h5><p></p>

<p style="font-weight:500;">Articolul 1417. Biletul (titlul de călătorie)</p><br>

<p style="text-indent:2em;">(1) Contractul de transport de persoane se confirmă (se documentează) printr-un bilet (titlu de călătorie) sau alt document care dă
 dreptul la transport. Biletul poate fi emis în format electronic.</p><br>

<p style="text-indent:2em;">(2) Biletul (titlul de călătorie) este transmisibil dacă nu s-a stipulat expres că este intransmisibil. Posibilitatea
 transmiterii încetează cel tîrziu la începutul călătoriei.</p><br>

<p style="text-indent:2em;">(3) Orice clauză care derogă de la dispozițiile prezentei secțiuni în
 detrimentul pasagerului consumator este lovită de nulitate absolută.</p><br>

<p style="font-weight:500;">Articolul 1418. Contractul de navlosire (charter)</p><br>

<p style="text-indent:2em;">(1) Contractul de navlosire (charter) este contractul de transport prin care transportatorul se obligă să transporte navlositorul sau pasagerul folosind mijlocul de transport pe care transportatorul îl acordă navlositorului sau pasagerului în folosință deplină, împreună cu operator, în scop de transport, iar navlositorul se obligă să achite taxa convenită. Contractul de navlosire
 poate fi încheiat pe termen determinat sau nedeterminat.</p><br>

<p style="text-indent:2em;">(2) Prin contract de navlosire transportatorul cere operatorului mijlocului de transport să îndeplinească indicațiile navlositorului sau ale pasagerului în măsura stipulată în contract.
 Transportatorul răspunde de îndeplinirea indicațiilor.</p><br>

<p>(3) Dispozițiile legale privind locațiunea nu se aplică contractului de navlosire.</p><br>

<p style="font-weight:500;">Articolul 1419. Întinderea obligaţiei</p><br>

<p>(1) Transportul de persoane include operaţiunile de îmbarcare, transbordare, transport şi debarcare.</p><br>

<p>(2) Transportatorul este ţinut să transporte pasagerul la destinaţie în siguranţă.</p><br>

<p style="font-weight:500;">Articolul 1420. Răspunderea transportatorului</p><br>

<p style="text-indent:2em;">(1) Transportatorul este ținut să repare prejudiciul cauzat în urma decesului pasagerului, vătămării corporale a pasagerului, distrugerii sau deteriorării bunurilor acestuia, sub rezerva dispozițiilor art. 1421, sau în urma încălcării termenului de transportare, cu excepția cazului în care acest prejudiciu este rezultatul unui impediment în afara controlului transportatorului și dacă transportatorului nu i se putea cere în mod rezonabil să evite sau să depășească impedimentul sau consecințele acestuia ori dacă este rezultatul stării de sănătate a pasagerului sau al faptei acestuia. Transportatorul este ținut să repare prejudiciul și în cazul în care acesta se datorează stării sale de sănătate,
 a prepușilor săi sau a stării ori funcționării mijlocului de transport.</p><br>

<p style="text-indent:2em;">(2) Răspunderea transportatorului nu poate fi exclusă sau limitată
 prin contract. Orice clauză contrară este lovită de nulitate absolută.</p><br>

<p style="text-indent:2em;">(3) Limitările în mărimea despăgubirilor și mărimea fixă a despăgubirilor pentru anumite categorii de încălcări sau prejudicii, inclusiv de natură extracontractuală, în transportul public de persoane sînt
 aprobate de Guvern, dacă prin lege nu se stabilesc alte limitări.</p><br>

<p style="text-indent:2em;">(4) Transportatorul răspunde, ca de propriile acțiuni sau inacțiuni, de acțiunile și inacțiunile prepușilor săi și ale oricăror alte persoane la ale căror servicii recurge pentru executarea transportului, 
dacă acești prepuși sau aceste persoane acționează în exercițiul funcției lor.</p><br>

<p style="font-weight:500;">Articolul 1421. Răspunderea transportatorului pentru bagaje</p><br>

<p style="text-indent:2em;">(1) Transportatorul răspunde pentru pierderea sau deteriorarea bagajelor care i-au fost încredințate de pasager, cu excepția cazului în care dovedește un impediment în afara controlului său și dacă lui nu i se putea cere în mod rezonabil să evite sau să depășească impedimentul sau consecințele acestuia ori a cazului
 în care dovedește viciul propriu al bunului sau vina pasagerului.</p><br>

<p style="text-indent:2em;">(2) Transportatorul nu răspunde pentru pierderea documentelor, a banilor sau a altor bunuri de mare valoare, cu excepția cazului în care i s-a declarat natura sau valoarea bunului și el a acceptat să îl transporte. Transportatorul nu este cu atît mai mult răspunzător pentru pierderea bagajelor de mînă care au rămas sub supravegherea pasagerului, cu excepția cazului în care
 ultimul demonstrează vinovăția transportatorului.</p><br>

<p style="font-weight:500;">Articolul 1422. Pretenții privind bagajele</p><br>

<p style="text-indent:2em;">(1) În cazul unei deteriorări aparente a bagajului de mînă, pasagerul, la părăsirea mijlocului de transport, trebuie să notifice transportatorul despre deteriorare. În cazul deteriorării aparente a altui bagaj, 
notificarea despre deteriorare trebuie să se facă la primirea acestuia.</p><br>

<p style="text-indent:2em;">(2) Dacă bagajul este deteriorat, însă deteriorarea nu este aparentă, notificarea despre deteriorare se face în formă textuală în termen de 7 zile de la ieșirea din mijlocul de transport sau, în cazul în
 care bagajul este primit mai tîrziu, de la primirea bagajului.</p><br>

<p style="text-indent:2em;">(3) Dacă bagajul de mînă este pierdut, pasagerul trebuie să notifice transportatorul despre pierdere la părăsirea mijlocului de transport. În cazul pierderii altui bagaj, pasagerul trebuie să notifice transportatorul despre pierdere în termen
 de 7 zile de la data în care bagajul ar fi trebuit să fie primit.</p><br>

<p style="text-indent:2em;">(4) Dacă pasagerul nu respectă dispozițiile alin. (1)-(3), se prezumă că el
 a primit întregul bagaj în stare bună.</p><br>

<p style="text-indent:2em;">(5) Pasagerul este decăzut din dreptul de a cere despăgubiri, în condițiile legii și ale contractului, pentru pierderea sau deteriorarea menționată la prezentul articol dacă nu notifică transportatorul despre pierdere sau, după caz, deteriorare în termen 
de o lună de la sosirea la locul de destinație.</p><br>

<p style="font-weight:500;">Articolul 1423. Răspunderea transportatorului în cazul transportului succesiv sau combinat de personae</p><br>

<p style="text-indent:2em;">În cazul transportului succesiv sau combinat de persoane, cel care efectuează transportul în cursul căruia s-a cauzat prejudiciul este răspunzător, cu excepţia cazului în care, printr-o stipulaţie expresă, unul dintre transportatori
 şi-a asumat răspunderea pentru întreaga călătorie.</p><br>

<p style="font-weight:500;">Articolul 1424. Răspunderea solidară a transportatorului și a transportatorului de fapt</p><br>

<p style="text-indent:2em;">(1) Dacă transportul este efectuat integral sau parțial de către o altă persoană (transportator de fapt) decît transportatorul, transportatorul de fapt poartă aceeași răspundere ca și transportatorul pentru prejudiciul rezultat din decesul pasagerului, vătămarea corporală a pasagerului, pierderea sau deteriorarea bunurilor acestuia, încălcarea termenului de transportare sau o altă neexecutare a obligațiilor rezultate din contractul de transport de persoane în decursul efectuării transportului de către transportatorul de fapt. Convenția dintre transportator și transportatorul de fapt privind extinderea răspunderii transportatorului, comparativ cu cea prevăzută de lege, se aplică
 transportatorului de fapt doar dacă el a acceptat-o în formă textuală.</p><br>

<p style="text-indent:2em;">(2) Transportatorul de fapt poate ridica aceleași excepții pe care le poate ridica transportatorul în
 temeiul contractului de transport încheiat cu pasagerul.</p><br>

<p>(3) Transportatorul și transportatorul de fapt răspund solidar față de pasager.</p><br>

<p style="font-weight:500;">Articolul 1425. Excepții de la limitarea de răspundere a transportatorului</p><br>

<p style="text-indent:2em;">(1) Transportatorul nu poate invoca dispozițiile legale care exclud sau limitează răspunderea sa ori care răstoarnă sarcina probei dacă prejudiciul 
a fost cauzat cu intenție sau din culpă gravă.</p><br>

<p style="text-indent:2em;">(2) Dispozițiile alin. (1) se aplică în mod corespunzător dacă faptele sînt săvîrșite cu intenție sau din culpă gravă de către prepușii transportatorului sau ai oricăror alte persoane la ale căror servicii acesta recurge pentru executarea transportului, dacă acești prepuși sau aceste persoane acționează în exercițiul funcției lor. În acest caz, prepușii sau alte persoane nu au dreptul de a invoca, în ceea ce privește răspunderea lor, dispozițiile legale care exclud sau limitează răspunderea
 lor ori care răstoarnă sarcina probei.</p><br>

<p style="font-weight:500;">Articolul 1426. Obligațiile pasagerilor</p><br>

<p style="text-indent:2em;">(1) În decursul efectuării transportului, pasagerii trebuie să se comporte într-un mod care nu ar pune în pericol siguranța mijlocului de transport și nu ar încălca condițiile de efectuare a transportului. Pasagerii trebuie să respecte instrucțiunile transportatorului, ale operatorului mijlocului
 de transport sau ale altei persoane competente.</p><br>

<p style="text-indent:2em;">(2) Pasagerul trebuie să-și prezinte bagajul pentru transportare într-un mod care nu ar încuraja pierderea sau deteriorarea acestuia și care nu ar cauza prejudicii transportatorului. Dacă bagajul conține lucruri periculoase, pasagerul trebuie să notifice transportatorul
 despre existența acestora și despre natura generală a pericolului.</p><br>

<p style="font-weight:500;">Articolul 1427. Răspunderea navlositorului</p><br>

<p style="text-indent:2em;">(1) Dacă un pasager sau toți pasagerii ori bagajele lor nu sînt gata, din orice motiv, de transport la momentul convenit, transportatorul poate declara rezoluțiunea contractului de navlosire sau poate începe cursa. Dacă transportatorului i se cauzează un prejudiciu din cauza faptului că un pasager sau toți pasagerii ori bagajele acestora nu sînt gata, din orice motiv, de transport la momentul convenit, navlositorul
 este ținut să despăgubească transportatorul.</p><br>

<p style="text-indent:2em;">(2) Navlositorul este ținut să despăgubească transportatorul pentru prejudiciul cauzat prin faptul că un pasager nu deține
 documentele necesare de care este responsabil navlositorul.</p><br>

<p style="text-indent:2em;">(3) În cazul transportării în temeiul contractului de navlosire, navlositorul este ținut să despăgubească transportatorul pentru prejudiciul cauzat prin îndeplinirea de către transportator a indicațiilor navlositorului sau ale unui pasager în decursul executării obligațiilor contractuale, cu excepția cazului în care operatorul mijlocului de transport 
nu se comportă în mod rezonabil la îndeplinirea indicațiilor.</p><br>

<p style="font-weight:500;">Articolul 1428. Răspunderea pasagerului</p><br>

<p style="text-indent:2em;">Pasagerul răspunde pentru prejudiciile cauzate transportatorului prin comportamentul său sau prin natura sau starea bagajelor de mînă, cu excepţia cazului în care prejudiciul s-a născut în pofida comportamentului diligent al pasagerului. Pasagerul nu poate invoca viciile sau natura propriului 
bagaj pentru a se elibera de răspundere.</p><br>

<p style="font-weight:500;">Articolul 1429. Rezoluțiunea contractului de transport de personae</p><br>

<p style="text-indent:2em;">(1) Pasagerul poate declara rezoluțiunea contractului de transport de persoane în orice moment dacă prin aceasta nu cauzează întîrzieri. El este obligat să plătească transportatorului 
despăgubirile cauzate de rezoluțiune.</p><br>

<p style="text-indent:2em;">(2) Dacă devin cunoscute împrejurări din sfera transportatorului despre care pasagerul nu putea să știe și care, dacă le cunoștea, i-ar fi dat un motiv întemeiat să nu încheie contractul de transport de persoane, pasagerul poate declara
 rezoluțiunea contractului de transport de persoane.</p><br>
 
<p style="text-indent:2em;">(3) Pasagerul poate declara rezoluțiunea contractului de transport de persoane și atunci cînd este previzibil că vor avea loc întîrzieri în raport cu timpul și durata convenită.
 În acest caz, nu se naște obligația de despăgubire.</p><br>

<p style="font-weight:500;">Articolul 1430. Dispoziții aplicabile transportului de bagaje</p><br>

<p style="text-indent:2em;">În completare la dispozițiile prezentei secțiuni, dispozițiile art. 1438, 1444, 1446, 1447, 1452 și 1455 se aplică transportului
 de bagaje dacă părțile nu au convenit altfel.</p>

<p></p><h5>Secţiunea a 3-a</h5><p></p>

<p></p><h5>Transportul de bunuri</h5><p></p>

<p>1. Încheierea și executarea contractului de transport de bunuri</p><br>

<p style="font-weight:500;">Articolul 1431. Transportul cu cîteva vehicule</p><br>

<p style="text-indent:2em;">Dacă vehiculul pe care este încărcat bunul ce se transportă străbate o porţiune de drum pe mare, pe calea ferată, pe un fluviu sau canal sau pe calea aerului, iar bunul nu este descărcat (transbordat), 
dispoziţiile prezentei secţiuni se aplică asupra întregului transport.</p><br>

<p style="font-weight:500;">Articolul 1432.  Forma contractului de transport</p><br>

<p style="text-indent:2em;">(1) Contractul de transport este constatat printr-o scrisoare de trăsură (conosament sau alt document echivalent).</p><br>

<p style="text-indent:2em;">(2) Lipsa, pierderea sau deteriorarea scrisorii de trăsură nu afectează valabilitatea contractului de transport.</p><br>

<p style="font-weight:500;">Articolul 1433.  Întocmirea scrisorii de trăsură</p><br>

<p>(1) Scrisoarea de trăsură se întocmește de expeditor dacă părțile nu au convenit altfel.</p><br>

<p style="text-indent:2em;">(2) Scrisoarea de trăsură se întocmește în cel puțin 3 exemplare care se semnează de către expeditor și transportator, aceste semnături putînd fi imprimate sau înlocuite prin ștampilele expeditorului și ale transportatorului. Primul exemplar se păstrează la expeditor, al doilea însoțește bunul, 
iar al treilea este remis transportatorului.</p><br>

<p style="text-indent:2em;">(3) Dacă bunurile sînt încărcate în mai multe vehicule sau dacă este vorba de bunuri de diferite feluri ori de bunuri repartizate în diferite loturi, atît transportatorul, cît și expeditorul poate cere întocmirea unui număr de scrisori de trăsură egal cu numărul vehiculelor utilizate sau cu
 numărul felurilor de bunuri ori al loturilor de bunuri.</p><br>

<p style="font-weight:500;">Articolul 1434.  Conținutul scrisorii de trăsură</p>

<p style="text-indent:2em;"></p><h6>(1) Scrisoarea de trăsură trebuie să conțină următoarele date:</h6><p></p>

<p>a) locul și data întocmirii;</p><br>

<p>b) numele și adresa expeditorului;</p><br>

<p>c) numele și adresa transportatorului;</p><br>

<p>d) locul și data primirii bunului și locul prevăzut pentru eliberarea acestuia;</p><br>

<p>e) numele și adresa destinatarului;</p><br>

<p style="text-indent:2em;">f) denumirea curentă a naturii bunului și felul ambalajului, iar pentru
 bunurile periculoase, denumirea lor general recunoscută;</p><br>

<p>g) numărul coletelor, marcajele speciale și numerele lor;</p><br>

<p>h) greutatea brută sau cantitatea altfel exprimată a bunurilor;</p><br>
  
<p style="text-indent:2em;">i) cheltuielile aferente transportului (taxa de transport, cheltuieli accesorii, taxele vamale și alte cheltuieli survenite
 de la încheierea contractului pînă la eliberare);</p><br>

<p>j) instrucțiunile necesare pentru formalitățile de vamă și altele, după caz.</p>

<p style="text-indent:2em;"></p><h6>(2) Dacă este cazul, scrisoarea de trăsură trebuie să conțină și următoarele indicații:</h6><p></p>
 
<p>a) interzicerea transbordării;</p><br>

<p>b) cheltuielile pe care expeditorul le ia asupra sa;</p><br>

<p>c) totalul sumelor ramburs de perceput la eliberarea bunului;</p><br>
 
<p>d) valoarea declarată a bunului și suma care reprezintă interesul special la eliberare;</p><br>
  
<p>e) instrucțiunile expeditorului către transportator cu privire la asigurarea bunului;</p><br>

<p>f) termenul convenit în care transportul trebuie să fie efectuat;</p><br>

<p>g) lista documentelor remise transportatorului.</p><br>

<p>(3) Părțile pot include în scrisoarea de trăsură orice altă indicație pe care o consideră utilă.</p><br>

<p style="font-weight:500;">Articolul 1435. Incorectitudinea datelor înscrise în scrisoarea de trăsură</p>

<p style="text-indent:2em;"></p><h6>(1) Expeditorul răspunde pentru toate cheltuielile și prejudiciul cauzat transportatorului din cauza 
inexactității sau insuficienței:</h6><p></p>

<p>a) indicațiilor menționate la art. 1434 alin. (1) lit. b), d)-h) și j);</p><br>

<p>b) indicațiilor menționate la art. 1434 alin. (2);</p><br>

<p style="text-indent:2em;">c) oricăror alte indicații sau instrucțiuni date de el pentru emiterea scrisorii de 
trăsură sau pentru a fi incluse în aceasta.</p><br>

<p style="text-indent:2em;">(2) Dacă, la cererea expeditorului, transportatorul înscrie în scrisoarea de trăsură mențiunile indicate la alin. (1), se consideră, pînă la proba contrarie,
 că acesta a acționat în numele expeditorului.</p><br>

<p style="font-weight:500;">Articolul 1436. Obligațiile transportatorului la preluarea bunului</p>

<p style="text-indent:2em;"></p><h6>(1) La preluarea bunului, transportatorul este obligat să verifice:</h6><p></p>

<p style="text-indent:2em;">a) exactitatea mențiunilor din scrisoarea de trăsură referitoare la numărul de colete,
 precum și la marcajul și numerele lor;</p><br>

<p>b) aspectul exterior al bunului și al ambalajului;</p><br>

<p>c) modul de încărcare și aranjare a bunului în vehicul.</p><br>

<p style="text-indent:2em;">(2) Dacă nu dispune de mijloace corespunzătoare pentru a verifica datele menționate la alin. (1) lit. a), transportatorul consemnează rezervele sale motivate în scrisoarea de trăsură. De asemenea, el trebuie să motiveze rezervele față de starea exterioară sau ambalajul bunului. Rezervele nu-l obligă pe 
expeditor dacă nu le-a acceptat expres în scrisoarea de trăsură.</p><br>

<p style="text-indent:2em;">(3) Expeditorul poate cere transportatorului să verifice greutatea brută sau cantitatea exprimată în alt mod a bunului, precum și conținutul coletelor. Transportatorul are dreptul la compensarea cheltuielilor de verificare. 
Rezultatul verificării se consemnează în scrisoarea de trăsură.</p><br>

<p style="font-weight:500;">Articolul 1437. Răspunderea expeditorului pentru ambalaj</p><br>

<p style="text-indent:2em;">Expeditorul răspunde față de transportator pentru prejudiciul cauzat (prin ambalarea necorespunzătoare a bunului) persoanelor, echipamentului sau instalațiilor de transport ori altor bunuri, precum și pentru toate cheltuielile generate de o astfel de ambalare, cu excepția cazului în care transportatorul nu a făcut rezerve cu privire la deficiențe,
 deși acestea erau evidente sau cunoscute de el la preluare.</p><br>

<p style="font-weight:500;">Articolul 1438. Anexele la scrisoarea de trăsură</p><br>

<p style="text-indent:2em;">(1) Expeditorul trebuie să anexeze la scrisoarea de trăsură documentele necesare trecerii prin vamă (după caz) ori altor operaţiuni similare, anterioare momentului livrării la destinaţie, sau să pună aceste documente la dispoziţia
 transportatorului cu toate indicaţiile necesare.</p><br>

<p style="text-indent:2em;">(2) Transportatorul nu este obligat să verifice dacă documentele sau indicaţiile sînt corespunzătoare şi suficiente. Expeditorul răspunde faţă de transportator pentru prejudiciul cauzat prin erori sau prin caracterul incomplet ori fals al documentelor sau al indicaţiilor
 dacă transportatorul nu poartă o parte din vinovăţie.</p><br>

<p style="text-indent:2em;">(3) Transportatorul răspunde, conform dispozițiilor legale cu privire la mandat, pentru pierderea sau utilizarea necorespunzătoare a documentelor anexate la scrisoarea de trăsură sau înmînate lui. El însă nu trebuie
 să plătească despăgubiri mai mari decît cele pentru pierderea bunului.</p><br>

<p style="font-weight:500;">Articolul 1439. Efectele semnării scrisorii de trăsură</p><br>

<p style="text-indent:2em;">(1) Scrisoarea de trăsură serveşte drept dovadă, pînă la proba contrară, a încheierii şi a cuprinsului contractului de transport,
 precum şi a preluării bunului de către transportator.</p><br>

<p style="text-indent:2em;">(2) Dacă scrisoarea de trăsură nu cuprinde rezervele motivate ale transportatorului, se prezumă, pînă la proba contrară, că bunul şi ambalajul erau, la preluare, în stare bună, iar numărul, de colete, precum 
și marcajele și numerele lor corespundeau datelor din scrisoarea de trăsură.</p><br>

<p style="font-weight:500;">Articolul 1440. Dreptul de rezoluțiune a contractului de transport de către expeditor</p><br>

<p>(1) Expeditorul are dreptul la rezoluțiunea contractului de transport în orice moment.</p><br>

<p style="text-indent:2em;">(2) Dacă expeditorul declară rezoluțiunea contractului de transport din alte motive decît o neexecutare din partea transportatorului, acesta din urmă poate cere plata taxei de transport și altor cheltuieli, cu deducerea economiei pe care transportatorul
 a obținut-o sau trebuie să o obțină în urma rezoluțiunii.</p><br>

<p style="text-indent:2em;">(3) În locul pretenției întemeiate pe dispozițiile alin. (2), transportatorul
 poate cere plata unei treimi din taxa de transport.</p><br>

<p style="text-indent:2em;">(4) Dacă bunul deja a fost încărcat înainte de rezoluțiune, transportatorul poate cere de la expeditor instrucțiuni privind operațiunile ulterioare cu bunul sau poate cere descărcarea fără întîrziere nejustificată a bunului. Dacă nu primește instrucțiuni într-un termen rezonabil, transportatorul poate lua măsurile prevăzute
 la art. 1446 pe cheltuiala expeditorului.</p><br>

<p style="text-indent:2em;">(5) Dacă, în cazul prevăzut la alin. (4), expeditorul dă instrucțiuni de descărcare a bunului, transportatorul este obligat să descarce bunul doar dacă acest fapt este posibil fără a-i împiedica activitatea curentă și fără
 a cauza pagube altor expeditori sau destinatari.</p><br>

<p style="text-indent:2em;">(6) Dacă expeditorul declară rezoluțiunea pentru o neexecutare din partea transportatorului, acesta din urmă este obligat să descarce
 bunul fără întîrziere nejustificată, pe cheltuiala sa.</p><br>

<p style="font-weight:500;">Articolul 1441. Dreptul expeditorului de a dispune de bun</p><br>

<p style="text-indent:2em;">(1) Expeditorul are dreptul să dispună de bun. El poate, în special, să ceară transportatorului să nu transporte bunul mai departe, să schimbe locul de livrare
 sau destinatarul menţionat în scrisoarea de trăsură.</p><br>

<p style="text-indent:2em;">(2) Dreptul prevăzut în prezentul articol la alin.(1) încetează în momentul în care cel de-al doilea exemplar al scrisorii de trăsură este înmînat destinatarului sau cînd acesta îşi valorifică dreptul prevăzut la art.1443 alin.(2). Din acest moment,
 transportatorul trebuie să se conformeze indicaţiilor destinatarului.</p><br>

<p style="text-indent:2em;">(3) Dreptul de dispoziţie se naşte pentru destinatar în momentul întocmirii scrisorii de trăsură în cazul în care expeditorul
 înscrie în scrisoarea de trăsură o notă în acest sens.</p><br>

<p style="text-indent:2em;">(4) Dacă destinatarul, exercitîndu-şi dreptul de dispoziţie, a dispus livrarea bunului către un terţ, acesta din urmă nu are
 dreptul de a desemna, la rîndul său, un alt destinatar.</p>

<p style="text-indent:2em;"></p><h6>(5) Exercitarea dreptului de dispoziţie se subordonează următoarelor reguli:</h6><p></p>

<p style="text-indent:2em;">a) expeditorul ori destinatarul menţionat la alin.(3) trebuie, în cazul în care vrea să-şi exercite dreptul de dispoziţie, să prezinte primul exemplar al scrisorii de trăsură, în care se înscriu noile indicaţii date transportatorului, şi să suporte prejudiciul, inclusiv cheltuielile apărute în seama
 transportatorului prin îndeplinirea acestor indicaţii;</p><br>
  
<p style="text-indent:2em;">b) îndeplinirea indicaţiilor trebuie să fie posibilă în momentul în care acestea ajung la persoana care urmează să le îndeplinească şi nu trebuie să împiedice activitatea curentă a transportatorului şi nici să cauzeze pagube altor 
expeditori sau destinatari;</p><br>

<p>c) indicaţiile nu trebuie să ducă la o fragmentare a transportării.</p><br>

<p style="text-indent:2em;">(6) Dacă nu poate îndeplini în temeiul alin.(5) lit.b) indicaţiile primite, transportatorul trebuie 
să încunoştinţeze de îndată pe cel care a dat indicaţiile.</p><br>

<p style="text-indent:2em;">(7) Transportatorul care nu îndeplineşte indicaţiile date cu respectarea dispoziţiilor din prezentul articol sau care îndeplineşte asemenea indicaţii fără a cere primul exemplar din scrisoarea de trăsură
 răspunde faţă de cel în drept pentru prejudiciul cauzat astfel.</p><br>

<p style="font-weight:500;">Articolul 1442. Scrisoarea de trăsură negociabilă</p><br>

<p style="text-indent:2em;">(1) Scrisoarea de trăsură nu este negociabilă dacă în ea nu se cuprinde o mențiune expresă în
 acest sens sau dacă legea nu prevede altfel.</p><br>

<p style="text-indent:2em;">(2) În cazul în care este negociabilă, scrisoarea de trăsură la ordin 
se transmite prin gir, iar cea la purtător – prin remitere.</p><br>

<p style="text-indent:2em;">(3) Atunci cînd scrisoarea de trăsură este la ordin sau la purtător, dreptul de proprietate asupra bunului
 se transferă prin efectul transmiterii acestei scrisori.</p><br>

<p style="text-indent:2em;">(4) Forma și efectele girurilor, anularea și înlocuirea scrisorii de trăsură sînt supuse dispozițiilor
 privitoare la cambie și biletul la ordin.</p><br>

<p style="text-indent:2em;">(5) Ultimul giratar al unui șir neîntrerupt de giruri care este posesor al titlului este considerat proprietar. Debitorul care își îndeplinește obligația rezultînd din titlu este eliberat, numai dacă nu
 a existat fraudă sau culpă gravă din partea sa.</p><br>

<p style="font-weight:500;">Articolul 1443. Drepturile destinatarului la recepţionarea bunului </p><br>

<p style="text-indent:2em;">(1) După ce bunul ajunge la locul prevăzut pentru livrare, destinatarul are dreptul să ceară transportatorului, contra unei recipise de primire, cel de-al doilea
 exemplar al scrisorii de trăsură şi predarea bunului.</p><br>

<p style="text-indent:2em;">(2) Dacă s-a constatat pierderea bunului sau dacă bunul nu a ajuns în termenul prevăzut la art.1451, destinatarul poate valorifica în nume propriu împotriva transportatorului drepturile
 ce decurg din contractul de transport.</p><br>

<p style="text-indent:2em;">(3) Destinatarul care valorifică drepturile ce-i revin în temeiul alin.(1) și (2) trebuie să plătească suma totală a plăților care rezultă din scrisoarea de trăsură. În cazul unor divergenţe sub acest aspect, transportatorul este obligat
 să livreze bunul numai dacă destinatarul acordă garanţii.</p><br>

<p style="font-weight:500;">Articolul 1444. Imposibilitatea executării contractului</p><br>

<p style="text-indent:2em;">(1) Dacă, pentru un motiv oarecare, înainte ca bunul să fi ajuns la destinație, executarea contractului în condițiile stabilite în scrisoarea de trăsură este sau devine imposibilă, transportatorul trebuie să ceară celui
 îndreptățit indicații de a dispune de bun conform art. 1441.</p><br>

<p style="text-indent:2em;">(2) Dacă împrejurările permit executarea transportului în condiții diferite de cele stabilite în scrisoarea de trăsură și nu poate obține în timp rezonabil indicații de la cel îndreptățit conform art. 1441, transportatorul trebuie să ia măsurile pe care le
 consideră cele mai bune în interesul celui îndreptățit să dispună.</p><br>

<p style="font-weight:500;">Articolul 1445. Circumstanțele care împiedică predarea bunului</p><br>

<p style="text-indent:2em;">(1) În cazul în care după sosirea bunului la locul de destinație se ivesc impedimente la eliberare, transportatorul cere instrucțiuni de la expeditor. Dacă destinatarul refuză să preia bunul, expeditorul are dreptul să
 dispună de el fără a prezenta primul exemplar al scrisorii de trăsură.</p><br>

<p style="text-indent:2em;">(2) Destinatarul poate, chiar și în cazul refuzului de a prelua bunul, să mai ceară livrarea acestuia atît timp cît transportatorul nu a primit 
o indicație contrară de la expeditor sau nu a dispus de bun.</p><br>

<p style="text-indent:2em;">(3) Dacă apare un impediment de livrare după ce destinatarul a dat indicații, în baza prerogativelor sale prevăzute la art. 1441 alin. (3), ca bunul să fie livrat unui terț, atunci, la aplicarea dispozițiilor alin. (1) și (2) din prezentul articol, 
destinatarul ia locul expeditorului, iar terțul – locul destinatarului.</p><br>

<p style="font-weight:500;">Articolul 1446. Dreptul transportatorului la compensarea cheltuielilor de îndeplinire 
 a indicaţiilor expeditorului</p><br>

<p style="text-indent:2em;">(1) Transportatorul are dreptul la compensarea cheltuielilor prilejuite de cererea şi îndeplinirea indicaţiilor dacă nu este dator, în baza
 contractului sau a legii, să suporte aceste cheltuieli.</p><br>

<p style="text-indent:2em;">(2) În cazurile menţionate la art.1444 alin.(1) şi la art.1445, transportatorul poate descărca imediat bunul pe cheltuiala celui îndreptăţit să dispună. După descărcare, transportul se consideră încheiat. Transportatorul urmează să păstreze bunul pentru persoana îndreptăţită să dispună. El poate să o încredinţeze, totuşi, unui terţ şi să răspundă, în acest caz, doar pentru diligenţa la alegerea terţului. Bunul rămîne grevat cu pretenţiile care decurg din scrisoarea
 de trăsură ca şi cu toate celelalte cheltuieli.</p><br>

<p style="text-indent:2em;">(3) Transportatorul poate organiza vînzarea bunului fără a mai aştepta indicaţii din partea celui îndreptăţit dacă este vorba de bunuri perisabile sau dacă starea bunului justifică o asemenea măsură, sau dacă costurile păstrării sînt disproporţionate faţă de valoarea bunului. El poate organiza vînzarea şi în alte cazuri dacă în interiorul unui termen corespunzător nu primeşte din partea celui îndreptăţit indicaţii contrare a căror executare
 îi poate fi pretinsă în baza principiului echităţii.</p><br>

<p style="text-indent:2em;">(4) Dacă bunul este vîndut conform dispoziţiilor prezentului articol, preţul obţinut va fi pus la dispoziţia celui îndreptăţit să dispună de bun după scăderea costurilor care au grevat bunul. Dacă aceste costuri depăşesc preţul
 obţinut, transportatorul poate pretinde diferenţa.</p><br>

<p>(5) Modul vînzării se determină conform legilor sau uzanțelor de la locul
 în care se găseşte bunul.</p><br>

<p style="font-weight:500;">Articolul 1447. Dreptul de retenţie asupra bunului</p><br>

<p style="text-indent:2em;">Transportatorul are drept de retenţie asupra bunului atîta timp cît poate dispune de bun pentru toate costurile care
 decurg din contractul de transport. </p><br>

<p>2. Răspunderea transportatorului</p><br>

<p style="font-weight:500;">Articolul 1448. Temeiul răspunderii transportatorului</p><br>

<p style="text-indent:2em;">(1) Transportatorul răspunde pentru distrugerea, pierderea totală sau parțială ori deteriorarea bunului în măsura în care distrugerea, pierderea sau deteriorarea a avut loc între momentul preluării și cel al livrării, precum și pentru 
depășirea termenului de livrare.</p><br>
  
<p style="text-indent:2em;">(2) Transportatorul răspunde, ca de propriile acțiuni sau inacțiuni, de acțiunile și inacțiunile prepușilor săi și ale oricăror alte persoane la ale căror servicii recurge pentru executarea transportului, dacă acești prepuși
 sau aceste persoane acționează în exercițiul funcției lor.</p><br>

<p style="font-weight:500;">Articolul 1449. Exonerarea de răspundere</p>

<p style="text-indent:2em;"></p><h6>(1) Transportatorul este exonerat de răspundere pentru distrugerea, pierderea, deteriorarea bunului sau depășirea
 termenului de livrare dacă:</h6><p></p>

<p>a) acestea se datorează vinovăției celui îndreptățit să dispună de bun;</p><br>

<p style="text-indent:2em;">b) acestea se datorează indicațiilor celui îndreptățit să dispună de bun, în cazul în care nu 
au fost provocate de vinovăția transportatorului;</p><br>

<p>c) acestea se datorează unui viciu propriu al bunului;</p><br>

<p style="text-indent:2em;">d) acestea se datorează circumstanțelor pe care transportatorul nu putea să le evite și
 ale căror consecințe nu le putea preveni.</p><br>

<p style="text-indent:2em;">(2) Transportatorul nu poate fi exonerat de răspundere din motivul defecțiunilor vehiculului pe care-l folosește pentru efectuarea transportului, nici al vinovăției 
locatorului sau a prepușilor acestuia din urmă.</p>
  
<p style="text-indent:2em;"></p><h6>(3) Sub rezerva dispozițiilor art. 1450 alin. (2)-(5), transportatorul este exonerat de răspundere dacă distrugerea, pierderea sau deteriorarea bunului rezultă din riscurile speciale inerente unei sau mai
 multor dintre următoarele împrejurări:</h6><p></p>

<p style="text-indent:2em;">a) utilizarea unor vehicule descoperite, fără prelată, dacă această utilizare a fost convenită 
expres și consemnată în scrisoarea de trăsură;</p><br>

<p style="text-indent:2em;">b) lipsa sau defectele ambalajului pentru bunurile expuse prin felul lor la risipire sau deteriorare, dacă aceste
 bunuri nu sînt ambalate sau sînt ambalate necorespunzător;</p><br>
 
<p style="text-indent:2em;">c) manipularea, încărcarea, stivuirea sau descărcarea au fost efectuate de expeditor, destinatar sau 
de un terț care acționează pentru expeditor ori destinatar;</p><br>

<p style="text-indent:2em;">d) natura unor bunuri expuse, datorită însăși naturii lor, fie la pierdere totală sau parțială, fie la deteriorare, în special prin spargere, rugină, deteriorare internă și spontană, uscare, curgere,
 pierdere normală ori prin acțiunea insectelor sau a rozătoarelor;</p><br>

<p>e) insuficiența sau imperfecțiunea marcajelor sau a numerelor coletelor;</p><br>

<p>f) transportarea animalelor vii.</p><br>

<p style="text-indent:2em;">(4) Dacă, în baza dispozițiilor prezentului articol, transportatorul nu răspunde de unii factori care au cauzat prejudiciul, răspunderea sa nu este angajată decît în măsura în care factorii de care el răspunde, în baza dispozițiilor 
prezentului articol, au contribuit la prejudiciu.</p><br>

<p style="font-weight:500;">Articolul 1450. Sarcina probațiunii</p><br>

<p style="text-indent:2em;">(1) Sarcina probei faptului că distrugerea, pierderea, deteriorarea bunului ori depășirea termenului de livrare se datorează uneia din împrejurările menționate 
la art.1449 alin. (1) revine transportatorului.</p><br>

<p style="text-indent:2em;">(2) În cazul în care transportatorul susține că, în funcție de împrejurări, distrugerea, pierderea sau deteriorarea bunului se putea produce din cauza unui sau mai multor riscuri menționate la art. 1449 alin. (3), se prezumă că prejudiciul s-a produs în acest fel. Cel îndreptățit să dispună de bun poate însă dovedi că prejudiciul 
nu s-a produs ori nu s-a produs exclusiv din cauza unuia dintre aceste riscuri.</p><br>

<p style="text-indent:2em;">(3) Prezumția prevăzută la alin. (2) nu operează în cazul menționat la art.1449
 alin. (3) lit. a) pentru pierderi anormale sau pentru pierderea unor colete întregi.</p><br>

<p style="text-indent:2em;">(4) La transportul cu un vehicul dotat cu instalații speciale de protecție a bunului împotriva efectelor căldurii, frigului, ale schimbărilor de temperatură sau umidității aerului, transportatorul poate invoca dispozițiile art. 1449 alin. (3) lit. d) numai dacă dovedește că a luat toate măsurile posibile, ținînd cont de circumstanțe, în vederea alegerii, întreținerii și utilizării acestor instalații speciale și că
 a respectat indicațiile speciale date în acest sens.</p><br>

<p style="text-indent:2em;">(5) Transportatorul poate invoca dispozițiile art. 1449 alin. (3) lit. f) doar dacă dovedește că a luat toate măsurile care, în mod obișnuit, ținînd cont de circumstanțe, trebuie luate și că a respectat 
indicațiile speciale pe care le-a primit.</p><br>

<p style="font-weight:500;">Articolul 1451. Încălcarea termenului de livrare a bunului</p><br>

<p style="text-indent:2em;">Termenul de livrare este încălcat atunci cînd bunul nu a fost livrat în interiorul
 termenului convenit sau, în cazul în care nu s-a convenit asupra unui termen, cînd durata efectivă 
 a transportului depășește durata care poate fi acordată în mod rezonabil unui transportator diligent,
 ținîndu-se cont de circumstanțe și, în special, în cazul unei încărcări parțiale, de timpul necesar
 pentru asamblarea unei încărcări complete în condiții normale.</p><br>

<p style="font-weight:500;">Articolul 1452. Prezumţia pierderii bunului</p><br>

<p style="text-indent:2em;">(1) Cel îndreptăţit să dispună, fără a mai aduce alte dovezi, poate considera pierdut bunul care nu a fost livrat în decursul a 30 de zile de la expirarea termenului de livrare sau, în cazul în care nu s-a convenit asupra unui astfel de termen, în decursul
 a 60 de zile de la preluarea bunului de către transportator.</p><br>

<p style="text-indent:2em;">(2) Cel îndreptăţit să dispună poate cere în scris, la primirea despăgubirii pentru bunul pierdut, să fie imediat notificat dacă bunul a fost găsit pe parcursul unui an de la plata despăgubirii. Această cerere
 trebuie confirmată în scris de către persoana obligată.</p><br>

<p style="text-indent:2em;">(3) Cel îndreptăţit să dispună poate pretinde, în decursul a 30 de zile de la primirea notificării prevăzute la alin.(2), ca bunul să-i fie livrat contra satisfacerii pretenţiilor care decurg din scrisoarea de trăsură şi restituirii despăgubirii primite, reţinîndu-se, după caz, cheltuielile de recuperare a prejudiciului. Pretenţiile sale la despăgubire pentru încălcarea termenului de livrare
 conform art.1455 şi, eventual, art.1458 rămîn neatinse.</p><br>
 
<p style="text-indent:2em;">(4) Dacă nu s-a formulat cererea prevăzută la alin.(2) sau nu s-a dat nici o indicaţie în termenul menţionat la alin.(3), sau dacă bunul a fost găsit după trecerea a mai mult de un an de la plata despăgubirii,
 transportatorul poate dispune de bun la propria discreție.</p><br>

<p style="font-weight:500;">Articolul 1453. Livrarea bunului fără preluarea rambursului</p><br>

<p style="text-indent:2em;">Dacă bunul este livrat destinatarului fără preluarea rambursului pe care, conform contractului, transportatorul trebuia să-l preia, acesta, păstrîndu-şi dreptul de regres împotriva destinatarului, este obligat să-l 
despăgubească pe expeditor pînă la valoarea rambursului.</p><br>

<p style="font-weight:500;">Articolul 1454. Regulile de expediere a bunurilor periculoase</p><br>

<p style="text-indent:2em;">(1) Expeditorul trebuie să-l informeze pe transportator, dacă îi remite bunuri periculoase, despre natura exactă a pericolului şi să-i indice măsurile de siguranţă necesare. Dacă toate acestea nu sînt consemnate în scrisoarea de trăsură, expeditorul sau destinatarul trebuie să dovedească în alt mod că transportatorul cunoştea natura exactă a pericolelor 
legate de transportul bunului.</p><br>
  
<p style="text-indent:2em;">(2) În cazul în care transportatorul nu cunoştea pericolul în condiţiile alin.(1), bunurile periculoase pot fi descărcate, distruse ori făcute inofensive de către transportator oricînd şi fără obligaţia despăgubirii. Expeditorul răspunde, în plus, pentru prejudiciul, inclusiv cheltuielile, cauzat de predarea, transportul, 
descărcarea şi distrugerea unor astfel de bunuri.</p><br>

<p style="font-weight:500;">Articolul 1455. Determinarea valorii bunului la pierderea lui și plata despăgubirilor</p><br>

<p style="text-indent:2em;">(1) Dacă, în baza dispozițiilor din prezenta secțiune, transportatorul trebuie să plătească despăgubiri pentru pierderea totală ori parțială a bunului, despăgubirea se calculează în funcție de valoarea bunului la locul
 și la momentul preluării.</p><br>

<p style="text-indent:2em;">(2) Valoarea bunului se determină în baza prețului de bursă, iar în lipsa unui asemenea preț, în baza prețului de piață, iar în lipsa unor asemenea prețuri, la prețul obișnuit 
al bunurilor cu aceleași caracteristici.</p><br>
 
<p>(3) Totuși, despăgubirea nu poate depăși 8,33 unități de calcul pe kg de greutate brută lipsă.</p><br>

<p style="text-indent:2em;">(4) În afară de plata despăgubirilor prevăzute la alin. (1), urmează a fi restituite taxele de transport, taxele vamale și alte cheltuieli de transport, și anume, în cazul pierderii totale a bunului – integral, iar în cazul pierderii parțiale – parțial.
 Alte despăgubiri pentru pierdere nu sînt datorate.</p><br>

<p style="text-indent:2em;">(5) Dacă bunul a fost livrat cu întîrziere, iar cel îndreptățit să dispună dovedește că prin aceasta a suferit un prejudiciu, transportatorul va plăti o despăgubire,
 care nu va depăși mărimea taxei de transport.</p><br>

<p style="text-indent:2em;">(6) O despăgubire mai mare nu poate fi pretinsă decît în caz de declarare a valorii bunurilor conform art. 1456 sau de declarare
 de interes special la predare conform art.1458.</p><br>

<p style="text-indent:2em;">(7) Unitatea de calcul menționată la alin. (3) reprezintă unitatea drepturilor speciale de tragere (Special Drawing Rights) 
în accepția Fondului Monetar Internațional.</p><br>

<p style="text-indent:2em;">(8) Sumele calculate conform alin. (3) se recalculează la cursul oficial al leului moldovenesc valabil la data preluării bunurilor de
 către transportator sau la oricare altă dată convenită de părți.</p><br>

<p style="font-weight:500;">Articolul 1456. Declararea valorii bunului</p><br>

<p style="text-indent:2em;">Expeditorul poate să declare în scrisoarea de trăsură, contra plății unui supliment de preț convenit, o valoare a bunului care depășește limita menționată la art. 1455 alin. (3) și, în acest caz, 
valoarea declarată înlocuiește această limită.</p><br>

<p style="font-weight:500;">Articolul 1457. Răspunderea pentru deteriorarea bunului</p><br>

<p style="text-indent:2em;">(1) La deteriorarea bunului, transportatorul poartă răspundere
 egală cu deprecierea bunului. Întinderea despăgubirii se determină conform art. 1455 alin. (1), (2) și (4).</p>

<p style="text-indent:2em;"></p><h6>(2) Totuși, despăgubirea nu poate să depășească:</h6><p></p>

<p style="text-indent:2em;">a) dacă totalul bunului este depreciat prin deteriorare, suma care ar
 fi trebuit plătită în caz de pierdere totală;</p><br>
  
<p style="text-indent:2em;">b) dacă numai o parte a bunului a fost depreciată prin deteriorare, suma care ar trebui plătită
 în caz de pierdere a părții depreciate.</p><br>

<p style="font-weight:500;">Articolul 1458. Interesul special al expeditorului</p><br>

<p style="text-indent:2em;">(1) În schimbul unui supliment la taxa de transport, expeditorul poate stabili, prin consemnare în scrisoarea de trăsură, suma care reprezintă pentru el interesul
 ca bunul să nu fie pierdut, deteriorat ori livrat cu întîrziere.</p><br>

<p style="text-indent:2em;">(2) Dacă s-a comunicat interesul special în privința livrării, se poate pretinde, independent de despăgubirea prevăzută la art. 1455, 1456 și 1457 și pînă la concurența contravalorii interesului declarat, o despăgubire egală 
cu prejudiciul suplimentar pentru care s-a făcut dovada.</p><br>

<p style="font-weight:500;">Articolul 1459. Dreptul la dobînzi al celui îndreptățit să dispună</p><br>

 <p style="text-indent:2em;">Cel îndreptățit să dispună poate pretinde pentru despăgubirea datorată dobînzi în
 mărime de 5% pe an.</p><br>
 
 <p style="text-indent:2em;">Dobînzile încep să curgă din momentul înaintării pretenției față de transportator sau, dacă nu a fost formulată o asemenea 
 pretenție, din momentul chemării lui în judecată.</p><br>

<p style="font-weight:500;">Articolul 1460. Dreptul transportatorului în cazul unor pretenţii extracontractuale</p><br>

<p style="text-indent:2em;">(1) Dacă distrugerile, pierderile, deteriorările sau întîrzierile apărute la un transport care intră sub incidenţa dispoziţiilor prezentei secţiuni duc, în conformitate cu dreptul aplicabil, la formularea unor pretenţii extracontractuale, transportatorul poate să se opună acestora prin invocarea dispoziţiilor prezentei secţiuni care exclud răspunderea sa ori care determină sau
 limitează întinderea despăgubirilor datorate.</p><br>

<p style="text-indent:2em;">(2) Dacă sînt formulate pretenţii extracontractuale pentru distrugere, pierdere, deteriorare sau întîrziere împotriva unui prepus, acesta va putea invoca, de asemenea, dispoziţiile prezentei secțiuni în măsura în care exclud răspunderea sa ori determină sau
 limitează întinderea despăgubirilor datorate.</p><br>

<p style="font-weight:500;">Articolul 1461. Interzicerea exonerării transportatorului de răspundere</p><br>

<p style="text-indent:2em;">(1) Transportatorul nu poate invoca dispozițiile din prezenta secțiune care exclud sau limitează răspunderea sa ori care răstoarnă sarcina probei dacă
 prejudiciul a fost cauzat cu intenție sau din culpă gravă.</p><br>

<p style="text-indent:2em;">(2) Dispozițiile alin. (1) se aplică în mod corespunzător dacă faptele sînt săvîrșite cu intenție sau din culpă gravă de către prepușii transportatorului sau de oricare alte persoane la ale căror servicii acesta recurge pentru executarea transportului, dacă acești prepuși sau aceste persoane acționează în exercițiul funcției lor. În acest caz, prepușii sau alte persoane nu au dreptul de a se prevala, în ceea ce privește răspunderea lor, de dispozițiile din prezenta secțiune care exclud sau 
limitează răspunderea lor ori care răstoarnă sarcina probei.</p><br>

<p>3. Pretenții și acțiuni</p><br>

<p style="font-weight:500;">Articolul 1462. Prezentarea pretențiilor</p><br>

<p style="text-indent:2em;">(1) Dacă destinatarul a primit bunul fără să fi verificat în mod corespunzător starea lui în contradictoriu cu transportatorul sau dacă, cel tîrziu la momentul livrării, în caz de pierderi ori deteriorări aparente, sau în termen de 7 zile, neluînd în considerare duminicile și sărbătorile legale, de la data livrării, în caz de pierderi sau deteriorări neaparente, nu a făcut rezerve transportatorului, indicînd natura generală a pierderii sau a deteriorării, atunci se prezumă, pînă la proba contrarie, ca a primit marfa în starea descrisă în scrisoarea de trăsură. În caz de pierderi sau de deteriorări neaparente,
 rezervele menționate mai sus trebuie să fie făcute în scris.</p><br>

<p style="text-indent:2em;">(2) Dacă destinatarul și transportatorul au verificat împreună starea bunului, dovada contrară rezultatului acestei verificări se admite doar în cazul unor pierderi sau deteriorări ce nu pot fi recunoscute la examinarea exterioară (aparentă), cu condiția că destinatarul a formulat obiecții scrise fată de transportator în decursul a 7 zile, neluînd în considerare
 duminicile și sărbătorile legale, de la data acelei verificări.</p><br>

<p style="text-indent:2em;">(3) Pentru încălcarea termenului de livrare se pot cere despăgubiri doar dacă, în termen de 21 de zile din momentul în care bunul a fost pus la dispoziția destinatarului,
 este înaintată o obiecție scrisă către transportator.</p><br>

<p style="text-indent:2em;">(4) Transportatorul și destinatarul sînt obligați să-și acorde toate înlesnirile
 corespunzătoare pentru constatările și verificările necesare.</p><br>

<p style="font-weight:500;">Articolul 1463. Termenul de prescripție în raporturile de transport</p><br>

<p style="text-indent:2em;">(1) Termenul de prescripție în raporturile de transport este de un an. În cazul 
intenției sau al culpei grave, termenul de prescripție este de 3 ani.</p>
 
<p style="text-indent:2em;"></p><h6>(2) Termenul de prescripție începe să curgă:</h6><p></p>

<p style="text-indent:2em;">a) în caz de pierdere parțială, de deteriorare a bunului sau de încălcare a termenului de
 livrare – din ziua predării bunului către destinatar;</p><br>

<p style="text-indent:2em;">b) în cazul pierderii totale – în a 30-a zi de la expirarea termenului de transportare, iar dacă acest termen nu este stabilit de părți sau de lege – în cea de-a 60-a zi
 din ziua preluării bunului de către transportator;</p><br>

<p style="text-indent:2em;">c) în toate celelalte cazuri – în ziua expirării unui termen de 3 luni de 
la data încheierii contractului de transport.</p><br>

<p style="text-indent:2em;">(3) O pretenție scrisă suspendă prescripția pînă în ziua în care transportatorul respinge pretenția în scris și restituie documentele anexate. În caz de acceptare parțială a pretenției, prescripția nu-și reia cursul decît pentru partea din pretenție care rămîne litigioasă. Dovada primirii pretenției sau a răspunsului și a restituirii documentelor cade în sarcina părții care invocă acest fapt. Pretențiile 
ulterioare care au același obiect nu suspendă prescripția.</p><br>

<p>4. Dispoziții referitoare la transportul efectuat 
de transportatori succesivi</p><br>

<p style="font-weight:500;">Articolul 1464. Răspunderea transportatorilor succesivi</p><br>

<p style="text-indent:2em;">(1) Dacă un transport care constituie obiectul unui singur contract este realizat de mai mulți transportatori succesivi, fiecare din ei răspunde 
pentru executarea întregului transport.</p><br>

<p style="text-indent:2em;">(2) Cel de-al doilea transportator și oricare transportator care urmează devin, prin preluarea bunului și a scrisorii de trăsură, părți la
 contract în măsura condițiilor stabilite în scrisoarea de trăsură.</p><br>

<p style="font-weight:500;">Articolul 1465. Preluarea bunului de la transportatorul anterior</p><br>

<p style="text-indent:2em;">(1) Transportatorul care preia bunul de la transportatorul anterior trebuie să înmîneze acestuia o confirmare de primire, datată și semnată. El trebuie să-și înscrie numele și adresa pe cel de-al doilea exemplar al scrisorii de trăsură. Dacă este cazul, va scrie rezervele sale conform art. 1436 alin. (2) pe cel de-al doilea exemplar al scrisorii
 de trăsură și pe confirmarea de primire.</p><br>

<p>(2) Relațiile dintre transportatorii succesivi sînt reglementate de dispozițiile art. 1439.</p><br>

<p style="font-weight:500;">Articolul 1466. Înaintarea pretențiilor contra transportatorilor succesivi</p><br>

<p style="text-indent:2em;">(1) Acțiunea în despăgubire pentru distrugerea, pierderea sau deteriorarea bunului ori pentru încălcarea termenului de livrare poate fi înaintată numai împotriva primului transportator, a ultimului sau a aceluia care a executat acea parte a transportului în care s-a produs evenimentul sau faptul ce a cauzat distrugerea,
 pierderea, deteriorarea sau întîrzierea.</p><br>
 
<p>(2) Una și aceeași acțiune poate fi îndreptată împotriva mai multor
 dintre acești transportatori.</p><br>

<p style="font-weight:500;">Articolul 1467. Dreptul de regres</p>

<p style="text-indent:2em;"></p><h6>Dacă a plătit despăgubiri în baza dispozițiilor prezentului capitol, transportatorul are drept de regres, pentru suma plătită, împreună cu dobînzile și cheltuielile suportate, contra transportatorilor care au participat la 
executarea contractului de transport, conform următoarelor reguli:</h6><p></p>

<p style="text-indent:2em;">a) dacă prejudiciul a fost cauzat de un transportator, acesta trebuie să suporte singur
 despăgubirile plătite de el sau de un alt transportator;</p><br>
  
<p style="text-indent:2em;">b) dacă prejudiciul a fost cauzat prin fapta a doi sau mai mulți transportatori, fiecare va plăti o sumă proporțională părții sale de răspundere. În cazul în care nu se poate stabili partea de răspundere a fiecăruia, transportatorii răspund în funcție
 de partea care le revine din taxa de transport;</p><br>

<p style="text-indent:2em;">c) dacă nu se poate stabili care dintre transportatori răspunde pentru prejudiciu, despăgubirea se repartizează între toți transportatorii în proporția
 determinată conform regulii menționate la lit. b).</p><br>

<p style="font-weight:500;">Articolul 1468. Incapacitatea de plată a unuia din transportatori</p><br>

<p style="text-indent:2em;">Dacă un transportator este în incapacitate de plată, partea care îi revine din despăgubiri şi pe care nu a plătit-o se suportă de ceilalţi transportatori proporţional
 părţii ce le revine din taxa de transport.</p><br>
 
<p style="font-weight:500;">Articolul 1469.  Excepţiile opuse în cazul acţiunii în regres</p><br>

<p style="text-indent:2em;">(1) Transportatorul împotriva căruia se exercită, în temeiul art.1467 şi 1468, dreptul de regres nu poate obiecta că transportatorul care exercită regresul a plătit celui prejudiciat fără să datoreze dacă despăgubirea a fost stabilită prin hotărîre judecătorească şi dacă transportatorul asupra căruia se exercită regresul a fost încunoştinţat în mod reglementar de procesul în curs
 avînd posibilitatea de a interveni în acest proces.</p><br>

<p style="text-indent:2em;">(2) Dispozițiile art. 1463 sînt aplicabile acțiunilor în regres între transportatori. Totuși, prescripția curge fie din ziua pronunțării unei hotărîri judecătorești definitive, prin care se fixează despăgubirile care urmează să fie plătite în virtutea dispozițiilor prezentei secțiuni, fie, în cazul în 
care o astfel de hotărîre nu există, din ziua plății efective.</p><br>

<p style="font-weight:500;">Articolul 1470. Dreptul transportatorilor de a deroga de la dispoziţiile prezentului cod</p><br>

<p style="text-indent:2em;">Transportatorii au dreptul, fără a prejudicia interesele expeditorului sau ale destinatarului, să convină între ei asupra unor reguli 
care să deroge de la prevederile art.1467 şi 1468.</p><br>

<p style="font-weight:500;">Articolul 1471. Nulitatea convențiilor</p><br>

<p style="text-indent:2em;">(1) Fără a aduce atingere dispozițiilor art. 1470, orice clauză care, în mod direct sau indirect, derogă de la dispozițiile prezentei secțiuni este lovită de nulitate absolută. Nulitatea unor asemenea clauze nu are 
drept consecință nulitatea celorlalte clauze din contract.</p><br>

<p style="text-indent:2em;">(2) Este nulă îndeosebi acea convenție prin care s-ar ceda transportatorului beneficiul asigurării 
bunului, precum și orice convenție asemănătoare.</p><br>

<p>(3) Nulă este și convenția în a cărei bază sarcina probei este răsturnată.</p><br>

<p><a href="https://www.legis.md/cautare/getResults?doc_id=122982&amp;lang=ro">Sursa</a></p> 


</div>
`